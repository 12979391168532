import * as constants from './constants'
import { KeywordRuleDefaults } from './defaults'
import * as helpers from './helpers'

function create(initial = {}) {
  return Object.assign({ ...KeywordRuleDefaults }, initial)
}

export default {
  create,
  defaults: KeywordRuleDefaults,
  ...constants,
  ...helpers,
}

import shuffle from 'lodash/shuffle'

/**
 * @typedef {Object} QuestionnaireQuestion
 * @property {string} id
 * @property {string} type
 * @property {string} style
 * @property {string} parent_questions
 * @property {boolean} is_visible
 * @property {boolean} is_require
 * @property {number} limit
 * @property {string} header
 * @property {string} placeholder
 * @property {string} description
 * @property {Array.<Object>} items
 */

/**
 * @typedef {Object} QuestionnaireQuestionSerialized
 * @property {string} id
 * @property {string} type
 * @property {string} layout
 * @property {string} parent
 * @property {boolean} visible
 * @property {boolean} required
 * @property {number} limit
 * @property {string} title
 * @property {string} placeholder
 * @property {string} description
 * @property {Array.<Object>} options
 * @property {Array.<Object>} answers
 */

/**
 * @param {QuestionnaireQuestion} question
 * @returns {QuestionnaireQuestionSerialized}
 */
export const questionAdapter = (question) => {
  if (question == null) {
    return null
  }

  const result = {
    id: question.id,
    type: question.type,
    layout: question.style,
    parent: question.parent_questions || '',
    visible: question.is_visible,
    required: question.is_require,
    title: question.header,
    description: question.description,
    placeholder: question.placeholder,
    options: question.items,
    answers: [],
  }

  // Randomize options order for business platforms question
  if (result.id === 'business_platform_e_commerce') {
    const { options } = result

    const othersIds = [
      'business_platform_e_commerce__i6',
      'business_platform_e_commerce__i7',
      'business_platform_e_commerce__i9',
    ]
    const others = options.filter((o) => othersIds.includes(o.id))
    const platforms = options.filter((o) => !others.includes(o))

    result.options = [...shuffle(platforms), ...others]
  }

  return result
}

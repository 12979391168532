import { useState, useCallback, useEffect } from 'react'
import clipboard from 'clipboard-js'
import { useAppDispatch } from 'reduxTyped'

import { useModalData } from 'apps/cms/components/Flows/components/AutomationModals/hooks/useModalData'
import { CmsAnalytics } from 'apps/cms/lib/analytics'
import { contentManagementActions } from 'apps/cms/store'
import { AutomationModalType } from 'apps/cms/store/lib/types'
import { handleCatch } from 'shared/api/lib/errors/handlers'
import { linkURL } from 'utils/url'

import { MIN_EMBED_WIDTH, MIN_EMBED_HEIGHT } from '../lib/constants'

export const useShareFlow = () => {
  const dispatch = useAppDispatch()

  const { flowId, flowSharingSettings } = useModalData({
    type: AutomationModalType.SHARE_FLOW,
  })

  const [sharingSettings, setSharingSettings] = useState(flowSharingSettings)

  const [embedWidth, setEmbedWidth] = useState(MIN_EMBED_WIDTH)
  const [embedHeight, setEmbedHeight] = useState(MIN_EMBED_HEIGHT)
  const [isLinkCopied, setIsLinkCopied] = useState(false)
  const [isCodeCopied, setIsCodeCopied] = useState(false)

  const updateSharedSettings = useCallback(
    async (event: React.ChangeEvent<HTMLInputElement>) => {
      try {
        const newSharingSettings = await dispatch(
          contentManagementActions.updateFlowSharingSettings({
            flowId: flowId,
            sharingEnabled: event.target.checked,
          }),
        ).unwrap()

        setSharingSettings((prevState) => ({
          ...prevState,
          is_shared: newSharingSettings.sharingEnabled,
          player_embed_url: newSharingSettings.player_embed_url,
          player_page_url: newSharingSettings.player_page_url,
        }))
      } catch (error) {
        handleCatch(error)
      }
    },
    [dispatch, flowId],
  )

  const updateCloneSettings = useCallback(
    async (event: React.ChangeEvent<HTMLInputElement>) => {
      try {
        const newCloneSettings = await dispatch(
          contentManagementActions.updateFlowSharingCloningSettings({
            flowId: flowId,
            cloningEnabled: event.target.checked,
          }),
        ).unwrap()

        setSharingSettings((prevState) => ({
          ...prevState,
          shared_cloning_enabled: newCloneSettings.cloningEnabled,
        }))
      } catch (error) {
        handleCatch(error)
      }
    },
    [dispatch, flowId],
  )

  const downloadAsImage = useCallback(() => {
    const url = linkURL(`/flow/exportAsPng?ns=${flowId}`)
    window.open(url, '_blank')
  }, [flowId])

  const getEmbedCode = useCallback(
    () =>
      `<iframe width="${embedWidth}" height="${embedHeight}" src="${sharingSettings.player_embed_url}" allowFullScreen></iframe>`,
    [embedHeight, embedWidth, sharingSettings.player_embed_url],
  )

  const copyLink = useCallback(async () => {
    if (!sharingSettings.player_page_url) return
    await clipboard.copy(sharingSettings.player_page_url)
    setIsLinkCopied(true)
    setIsCodeCopied(false)
  }, [sharingSettings.player_page_url])

  const copyCode = useCallback(async () => {
    await clipboard.copy(getEmbedCode())

    setIsCodeCopied(true)
    setIsLinkCopied(false)
  }, [getEmbedCode])

  const updateEmbedWidth = useCallback((value: string | number) => {
    setEmbedWidth(Number(value))
  }, [])

  const updateEmbedHeight = useCallback((value: string | number) => {
    setEmbedHeight(Number(value))
  }, [])

  const closeModal = useCallback(() => {
    dispatch(contentManagementActions.closeModal(AutomationModalType.SHARE_FLOW))
  }, [dispatch])

  useEffect(() => {
    CmsAnalytics.logOpenShareFlowModal()

    return () => {
      CmsAnalytics.logCloseShareFlowModal()
    }
  }, [])

  return {
    embedWidth,
    embedHeight,
    isLinkCopied,
    isCodeCopied,
    updateCloneSettings,
    downloadAsImage,
    getEmbedCode,
    updateSharedSettings,
    copyLink,
    copyCode,
    updateEmbedWidth,
    updateEmbedHeight,
    closeModal,
    sharingSettings,
  }
}

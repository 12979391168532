import React, { MouseEventHandler } from 'react'
import { useAppDispatch, useAppSelector } from 'reduxTyped'
import { l, LinkBtn, sanitizeURL } from '@manychat/manyui'

import { openIgPreviewModal, removeNotification } from 'common/core/actions/uiActions'
import { getUI } from 'common/core/selectors/uiSelectors'
import If from 'components/If/If'
import { analyticsService } from 'utils/services/analytics'

import { PreviewBlocksAvatar } from './PreviewBlocksAvatar'

interface BlockProps {
  title: string
  info: string
  dataTestId?: string
  isHasLeftMargin?: boolean
}

export const Block = (props: BlockProps) => {
  return (
    <div className={props.isHasLeftMargin ? 'm-b m-l-xl p-l' : 'm-b'}>
      <span className="text-subheading d-block text-black font-semibold">{props.title}</span>
      <span className="d-block">{props.info}</span>
    </div>
  )
}

interface AvatarButtonBlockProps {
  title: string
  info: string
  avatar?: string
  btn?: {
    name: string
    url?: string
  }
  dataTestId?: string
  onBtnClick?: MouseEventHandler
  children?: React.ReactNode
}

export const AvatarButtonBlock = (props: AvatarButtonBlockProps) => {
  return (
    <div data-test-id={props.dataTestId} className="d-flex m-b">
      <PreviewBlocksAvatar url={props.avatar} />
      <div style={{ width: '100%' }} className="p-a-xxs m-l-xs">
        <span className="text-subheading d-block font-semibold">{props.title}</span>
        <span className="d-block">{props.info}</span>
        <If condition={Boolean(props.children)}>
          <div>{props.children}</div>
        </If>
        <If condition={Boolean(props.btn)}>
          <LinkBtn
            className="m-t"
            label={props.btn?.name}
            fullWidth
            variant="default"
            href={props.btn?.url}
            target="_blank"
            onClick={props.onBtnClick}
          />
        </If>
      </div>
    </div>
  )
}
interface ButtonBlockProps {
  title: string
  info: string
  btn?: {
    name: string
    url: string
  }
  dataTestId?: string
  isHasLeftMargin?: boolean
}

export const ButtonBlock = (props: ButtonBlockProps) => {
  return (
    <div
      data-test-id={props.dataTestId}
      className={props.isHasLeftMargin ? 'm-b m-l-xl p-l' : 'm-b'}
    >
      <span className="text-subheading d-block text-black font-semibold">{props.title}</span>
      <span className="d-block">{props.info}</span>
      <If condition={Boolean(props.btn)}>
        <LinkBtn
          className="m-t"
          label={props.btn?.name}
          fullWidth
          variant="default"
          href={props.btn?.url}
          target="_blank"
          onClick={(event) => event.stopPropagation()}
        />
      </If>
    </div>
  )
}

interface AvatarKeywordIGBlockProps {
  title: string
  info: string
  avatar?: string
  keyword?: string
  keywordLabel?: string
  dataTestId?: string
  children?: React.ReactElement
}

export const AvatarKeywordIGBlock = ({
  title,
  info,
  avatar,
  keyword,
  keywordLabel,
  dataTestId,
  children,
}: AvatarKeywordIGBlockProps) => {
  const ui = useAppSelector(getUI)
  const dispatch = useAppDispatch()
  const showIgPreviewModal = () => {
    const notificationId = ui.notifications.find(
      (notification) => notification.source === 'previewSuccessAlert',
    )?.id

    notificationId && dispatch(removeNotification(notificationId))
    dispatch(openIgPreviewModal(keyword))

    analyticsService.sendEvent('PREVIEW_INSTAGRAM_MODAL.SHOWN', {
      isFirstTime: false,
    })
  }

  return (
    <div data-test-id={dataTestId} className="d-flex">
      <PreviewBlocksAvatar url={avatar} />
      <div style={{ width: '100%' }} className="p-a-xxs m-l-xs">
        <span className="text-subheading d-block font-semibold">{title}</span>
        <span className="d-block">{info}</span>
        <If condition={keyword}>
          <span className="d-block m-t m-b-xs">{keywordLabel}</span>
          <span className="d-block bg-default p-y-xxs text-heading-2 text-black  text-center rounded">
            {keyword}
          </span>
          {<div>{children}</div>}
          <div onClick={showIgPreviewModal} className="text-body text-primary c-pointer m-t m-b-xs">
            {l.translate('How to preview an IG Automation?')}
          </div>
        </If>
      </div>
    </div>
  )
}

interface LinkBlockProps {
  text: string
  link?: {
    url: string
    name: string
  }
  dataTestId?: string
}

export const LinkBlock = (props: LinkBlockProps) => {
  return (
    <div data-test-id={props.dataTestId} className="m-t panel panel-info p-a-xs">
      <span className="d-block text-black">{props.text}</span>
      {props.link && (
        <a
          className="text-primary"
          href={sanitizeURL(props.link.url)}
          target="_blank"
          rel="noreferrer"
        >
          {props.link.name}
        </a>
      )}
    </div>
  )
}

import { api } from 'shared/api'
import {
  GetAiBusinessContextSchema,
  SetAiBusinessContextSchema,
} from 'shared/api/requests/aiBusinessContext/schemas'

export const AiBusinessContextApi = {
  getAiBusinessContext: api.account.createGet({
    url: '/aiBusinessContext/getContent',
    schemas: GetAiBusinessContextSchema,
  }),

  setAiBusinessContext: api.account.createPost({
    url: '/aiBusinessContext/setContent',
    schemas: SetAiBusinessContextSchema,
  }),
}

import { getIsSubscriberDependentField } from 'common/fields/utils/getIsSubscriberDependentField'
import { extractVariables } from 'components/MCTextEditor/variables/helpers'
import { VirtualFlexibleAgent } from 'shared/api/requests/aiAgent/schemas'

export const getHasSubscriberFieldDependency = ({
  scenario,
}: {
  scenario: VirtualFlexibleAgent['config']['scenario']
}) => {
  return scenario.some((task) =>
    extractVariables('goal' in task.config ? task.config.goal : task.config.question).find(
      getIsSubscriberDependentField,
    ),
  )
}

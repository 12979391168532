import React from 'react'
import PropTypes from 'prop-types'

import { noop } from '../../../utils'

export default class ControlledInputWrapper extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      name: props.name || 'controlled-input',
      [props.changedProp]: props.value || props.defaultValue,
    }
  }

  static propTypes = {
    name: PropTypes.string,
    changedProp: PropTypes.string,
    onChange: PropTypes.func,
    children: PropTypes.any,
    value: PropTypes.any,
    defaultValue: PropTypes.any,
  }

  static defaultProps = {
    changedProp: 'value',
    onChange: noop,
  }

  handleChange = (event) => {
    const { target } = event
    const hasOption = Boolean(target.option)
    let value
    if (hasOption) {
      value = target.option.value
    } else {
      value = target.type === 'checkbox' ? target.checked : target.value
    }

    this.setState({ [this.props.changedProp]: value })
    this.props.onChange()
  }

  render() {
    const props = {
      name: this.state.name,
      [this.props.changedProp]: this.state[this.props.changedProp],
      onChange: this.handleChange,
    }
    return React.cloneElement(this.props.children, props)
  }
}

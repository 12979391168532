import React, { useEffect } from 'react'
import { l } from '@manychat/manyui'

import { analyticsService } from 'utils/services/analytics'
import { linkURL } from 'utils/url'

import cm from '../StaticNotifications.module.css'

export const BlockedSMSChannel = () => {
  useEffect(() => {
    analyticsService.sendEvent('NOTIFICATION_BAR.SMS_RESTRICTED.SHOWN')
  }, [])

  const handleClick = () => {
    analyticsService.sendEvent('NOTIFICATION_BAR.SMS_RESTRICTED.CTA_CLICKED')
  }

  return (
    <span>
      {l.translate(
        'SMS sending for this account is currently restricted. If you think we made a mistake <a class="{class}" href="{href}" onclick="handleClick" rel="noreferrer" target="_blank">tell us</a>',
        {
          href: linkURL('support/features/sms'),
          class: cm.more,
          handleClick,
        },
      )}
    </span>
  )
}

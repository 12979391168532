export enum FlowPickerSource {
  LIVE_CHAT = 'live_chat',
  BROADCAST = 'broadcast',
  GO_TO_BUILDER = 'go_to_builder',
  FLOW_CHART = 'flow_chart',
  CUSTOMER_CHAT = 'customer_chat',
  WIDGET_FLOW_INPUT = 'widget_flow_input',
  MAIN_MENU_ITEM = 'main_menu_item',
  SET_ANOTHER_FLOW_BTN = 'set_another_flow_btn',
  TRIGGER_NODE = 'trigger_node',
  RULES_ATTACH_FLOW = 'rules_attach_flow',
  BUTTON_NEXT_STEP_PANE = 'button_next_step_pane',
  DEFAULT_REPLY_SETTINGS = 'default_reply_settings',
  WELCOME_MESSAGE_SETTINGS = 'welcome_message_settings',
  STORY_MENTION_REPLY_SETTINGS = 'story_mention_reply_settings',
  DEFAULT_REPLY_PAGE = 'default_reply_page',
  WELCOME_MESSAGE_PAGE = 'welcome_message_page',
  STORY_MENTION_REPLY_PAGE = 'story_mention_reply_page',
  FLOW_REPLACE_COMPONENT = 'flow_replace_component',
  FLOW_REPLACE_BTN_COMPONENT = 'flow_replace_btn_component',
  FLOW_PICKER_BUTTON_COMPONENT = 'flow_picker_button_component',
  SEQUENCE_MESSAGE_FLOW_INPUT = 'sequence_message_flow_input',
  KEYWORDS_LIST = 'keywords_list',
}

export const AllowCreateFlowSources = [
  FlowPickerSource.RULES_ATTACH_FLOW,
  FlowPickerSource.FLOW_CHART,
  FlowPickerSource.GO_TO_BUILDER,
  FlowPickerSource.DEFAULT_REPLY_SETTINGS,
  FlowPickerSource.WELCOME_MESSAGE_SETTINGS,
  FlowPickerSource.STORY_MENTION_REPLY_SETTINGS,
  FlowPickerSource.MAIN_MENU_ITEM,
  FlowPickerSource.DEFAULT_REPLY_PAGE,
  FlowPickerSource.WELCOME_MESSAGE_PAGE,
  FlowPickerSource.STORY_MENTION_REPLY_PAGE,
  FlowPickerSource.SET_ANOTHER_FLOW_BTN,
]

export const CreateAndSelectUntitledFlowSources = [
  FlowPickerSource.RULES_ATTACH_FLOW,
  FlowPickerSource.FLOW_CHART,
  FlowPickerSource.GO_TO_BUILDER,
  FlowPickerSource.DEFAULT_REPLY_PAGE,
  FlowPickerSource.WELCOME_MESSAGE_PAGE,
  FlowPickerSource.STORY_MENTION_REPLY_PAGE,
  FlowPickerSource.SET_ANOTHER_FLOW_BTN,
]

export const FLOW_PICKER_ANALYTICS_EVENT = 'FLOW_PICKER'

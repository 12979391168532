import { createSlice } from '@reduxjs/toolkit'

export interface RefreshPermissionsState {
  modal: {
    isOpenRefreshPermissionsModal: boolean
  }
}

export const initialState: RefreshPermissionsState = {
  modal: {
    isOpenRefreshPermissionsModal: false,
  },
}

export const { reducer: refreshPermissions, actions: refreshPermissionsActions } = createSlice({
  name: 'refreshPermissions',
  initialState,
  reducers: {
    open: (state) => {
      state.modal.isOpenRefreshPermissionsModal = true
    },
    close: (state) => {
      state.modal.isOpenRefreshPermissionsModal = false
    },
  },
})

export const refreshPermissionsSelectors = {
  isOpenRefreshPermissionsModal: (state: RootState) =>
    state.refreshPermissions.modal.isOpenRefreshPermissionsModal,
}

import { CaptureContext } from '@sentry/types'

import {
  CSRF_TOKEN_EMPTY_EVENT_NAME,
  CSRF_TOKEN_EMPTY_FINGERPRINT,
} from 'utils/services/sentryConstantNames'

const UrlsWithoutCsrfToken = [
  /^\/analytics\/visit$/gi,
  /^\/auth\/(.)+$/gi,
  /^\/template\/[a-z0-9]+\/getData$/gi,
  /^\/businessEmail\/confirmBusinessEmail/gi,
  /^\/businessEmail\/getBusinessEmailInfo.*/gi,
  /^\/manychat\/getSharedFlow.*/gi,
  /^\/manychat\/previewState*/gi,
  /^\/appStore\/get.*/gi,
]

export const hasEventWithUrlWithoutCheckTokenOnBackend = ({
  exception,
  captureContext,
}: {
  exception?: unknown
  captureContext?: CaptureContext
}) => {
  const isObjectError = exception && typeof exception === 'object' && 'message' in exception
  const stringErrorMessage = isObjectError ? exception.message : exception

  const hasCsrfTitle = stringErrorMessage === CSRF_TOKEN_EMPTY_EVENT_NAME
  const hasCsrfFingerPrint =
    captureContext &&
    'fingerprint' in captureContext &&
    captureContext.fingerprint &&
    captureContext.fingerprint.includes(CSRF_TOKEN_EMPTY_FINGERPRINT)

  if (hasCsrfTitle || hasCsrfFingerPrint) {
    const hasExtra = captureContext && 'extra' in captureContext
    const url = hasExtra ? captureContext?.extra?.url ?? null : null
    return typeof url === 'string'
      ? UrlsWithoutCsrfToken.some((regex) => Boolean(url.match(regex)))
      : false
  }

  return false
}

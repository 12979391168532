import React from 'react'
import dayjs from 'dayjs'
import { l, sanitizeURL } from '@manychat/manyui'

import { getIceBreakersRoute } from 'apps/iceBreakers/models/helpers'
import { IntegrationsByType } from 'common/actions/integrations/common/model/constants'
import { ChannelTypeLabel } from 'common/core/constants/ChannelType'
import { KeywordPreview } from 'common/usesList/components/KeywordPreview/KeywordPreview'

import { statsEntitiesToEntityTypeMapping } from './constants'

const getLinkPart = (entity) => {
  const id = entity.id
  return {
    flows: `cms/files/${id}`,
    keywords_rules: `automation/keywords?keyword=${id}`,
    trigger_rules: `rules/${id}`,
    growth_tools: `growth-tools/widget/${id}`,
    sequences: `sequence/${id}`,
    smart_segments: `customer_segments/${id}`,
  }
}

export const formatEntitiesListResponse = (response, pageId) => {
  return formatEntitiesList(response.entities, pageId)
}

const createLink = (href, title) => {
  return (
    <a href={sanitizeURL(href)} className="text-primary" target="_blank" rel="noreferrer">
      {title}
    </a>
  )
}

const getOthers = (entities, pageId) => {
  const {
    default_reply = [],
    welcome_message = [],
    icebreakers,
    flows = [],
    story_mention_reply,
    intent_rules = [],
    external_triggers = [],
  } = entities
  const main_menu = flows.filter((flow) => flow.id.includes('mainmenu'))

  const defaultReplies = default_reply.map(
    (channel) => `${ChannelTypeLabel[channel]} Default Reply`,
  )
  const welcomeMessages = welcome_message.map(
    (channel) => `${ChannelTypeLabel[channel]} Welcome Message`,
  )
  const mainMenu = main_menu.map((flow) => {
    const mmId = flow.is_main ? 'default' : flow.id
    const url = `/${pageId}/automation/menu/${mmId}?channel=${flow.channel}`
    const labelMain = `${ChannelTypeLabel[flow.channel]} Main Menu`
    const labelDynamic = `${ChannelTypeLabel[flow.channel]} Dynamic Menu: ${flow.title}`
    return createLink(url, flow.is_main ? labelMain : labelDynamic)
  })
  const storyMentionReply =
    story_mention_reply?.map((channel) => `${ChannelTypeLabel[channel]} Story Mention Reply`) ?? []

  const intents = intent_rules.map((intent) => `AI Intent: ${intent.title}`)

  const externalTriggers = external_triggers.map((intent) => `External Trigger: ${intent.title}`)

  const iceBreakers =
    Array.isArray(icebreakers) &&
    icebreakers.length > 0 &&
    createLink(`/${pageId}${getIceBreakersRoute()}`, 'Conversation Starters')

  const others = [
    ...mainMenu,
    ...defaultReplies,
    ...welcomeMessages,
    iceBreakers,
    ...storyMentionReply,
    ...intents,
    ...externalTriggers,
  ].filter((item) => item)
  if (!others.length) {
    return null
  }

  return {
    label: l.translate('Other'),
    subItems: others,
  }
}

export const formatUsesListResponse = (entities, pageId) => {
  const flowsWithoutMainmenu = (entities.flows || []).filter(
    (flow) => !flow.id.includes('mainmenu'),
  )
  const usesList = formatEntitiesList({ ...entities, flows: flowsWithoutMainmenu }, pageId)

  const others = getOthers(entities, pageId)
  if (others) {
    usesList.push(others)
  }

  const isNoUses = usesList.length === 0

  return {
    isNoUses,
    usesList,
  }
}

const formatEntitiesList = (entities, pageId) => {
  return Object.keys(entities)
    .filter((key) => entities[key].length)
    .filter(
      (key) =>
        key !== 'icebreakers' &&
        key !== 'default_reply' &&
        key !== 'welcome_message' &&
        key !== 'story_mention_reply' &&
        key !== 'intent_rules' &&
        key !== 'external_triggers' &&
        key !== 'follow_ups',
    )
    .map((key) => {
      const value = entities[key]
      return {
        label: statsEntitiesToEntityTypeMapping[key] || IntegrationsByType[key].fullName,
        subItems: value.map((entity) => {
          const link = `/${pageId}/${getLinkPart(entity)[key]}`

          if (entity.channel && Array.isArray(entity.data)) {
            return <KeywordPreview link={link} entity={entity} />
          }

          return entity.id ? createLink(link, entity.title) : entity.title
        }),
        dependencies: value[0]?.dependencies,
      }
    })
}

export const formatDateTime = (value, dateOnly, botTimezone) => {
  if (botTimezone) {
    return dateOnly
      ? dayjs(value).format('D MMM YYYY')
      : dayjs(value).tz(botTimezone).format('D MMM YYYY, HH:mm (UTC Z)')
  }

  return dayjs(value).format(`D MMM YYYY${dateOnly ? '' : ', HH:mm (UTC Z)'}`)
}

export const prepareDateTimeWithBotTimezoneForBackend = (dateTime, botTimezone, isUnix) => {
  if (!dateTime) {
    return null
  }

  if (!botTimezone) {
    return dateTime
  }

  return dayjs(dateTime.slice(0, -1))
    .add(dayjs(dateTime.slice(0, -1)).utcOffset(), 'minutes')
    .tz(botTimezone, true)
    [isUnix ? 'unix' : 'toISOString']()
}

export const parseDateTimeWithBotTimezoneFromBackend = (dateTime, botTimezone) => {
  if (!dateTime) {
    return null
  }

  if (!botTimezone) {
    return dateTime || ''
  }

  return (
    dayjs(dateTime)
      .tz(botTimezone, false)
      .subtract(
        dayjs(dateTime).tz(botTimezone).utcOffset() ? dayjs(dateTime).utcOffset() : 0,
        'minutes',
      )
      .format('YYYY-MM-DDTHH:mm:ss') + 'Z'
  )
}

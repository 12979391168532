import { useEffect, useMemo } from 'react'
import { useAppSelector } from 'reduxTyped'
import { l } from '@manychat/manyui'

import { Option } from 'apps/cms/components/Flows/components/FlowFilters/components/FilterPicker/lib/types'
import { contentManagementSelectors } from 'apps/cms/store'

interface UseActiveOptionsParams {
  setSelectedOptions: (options: Option[]) => void
}

export const useActiveOptions = ({ setSelectedOptions }: UseActiveOptionsParams) => {
  const activeOptions = useAppSelector(contentManagementSelectors.getActiveFilterOptions)
  const activeOptionsLength = activeOptions.length

  useEffect(() => {
    if (activeOptions.length) {
      setSelectedOptions(activeOptions)
    }
  }, [activeOptions, setSelectedOptions])

  const activeFilterIcon = useMemo(() => {
    if (!activeOptions.length) return null

    return activeOptions.length === 1 && activeOptions[0].icon ? activeOptions[0].icon : null
  }, [activeOptions])

  const activeFilterLabel = useMemo(() => {
    if (activeOptions.length === 1 && activeOptions[0].label) return activeOptions[0].label

    if (activeOptions.length && activeOptions.length > 1) {
      return l.translate('{amount} Triggers selected', { amount: activeOptions?.length })
    }

    return l.translate('Any Trigger')
  }, [activeOptions])

  return { activeFilterLabel, activeFilterIcon, activeOptionsLength }
}

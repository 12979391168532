import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { appListenerMiddleware, createAppAsyncThunk } from 'reduxTyped'
import { v4 as uuid } from 'uuid'
import { l } from '@manychat/manyui'

import { AiCommunicationsActions } from 'apps/aiCommunications/store'
import { WelcomeMessageSource } from 'apps/aiCommunications/store/types'
import { FAQ_FOLDER_ID_STORAGE_FIELD, FOLDER_NAME } from 'apps/aiIntentsWizard/constants'
import { AiIntentsWizardAnalytics } from 'apps/aiIntentsWizard/lib/analytics'
import { AiIntentsWizardState, IntentItem } from 'apps/aiIntentsWizard/store/types'
import { generateFilterBody } from 'apps/aiIntentsWizard/utils'
import * as Service from 'apps/aiIntentsWizard/utils/service'
import { cmsFoldersActions } from 'apps/cms/store/folders'
import { billing, UpgradeModalVersion, UpgradeSource } from 'common/billing'
import { upgrade } from 'common/billing/actions/upgradeActions'
import { cancelUpgradeRequest } from 'common/billing/actions/upgradeRequestActions'
import { AddOnSlug } from 'common/billing/interfaces/addOnTypes'
import { alert } from 'common/core'
import { Ability } from 'common/core/constants/Ability'
import { ChannelType } from 'common/core/constants/ChannelType'
import { IThunkAction } from 'common/core/interfaces/actions'
import { getHasAbility } from 'common/core/selectors/abilitiesSelectors'
import { getConnectedChannels } from 'common/core/selectors/getConnectedChannels'
import { handleCatch } from 'shared/api/lib/errors/handlers'
import { CmsApi } from 'shared/api/requests/cms'
import { getAccountIdFromURL } from 'utils/accountId'
import localStorage from 'utils/localStorage'
import errorTrackingService from 'utils/services/errorTrackingService'

export const AiIntentsWizardInitialState: AiIntentsWizardState = {
  channel: ChannelType.INSTAGRAM,
  isLoading: false,
  isFinished: false,
  showModal: false,
  folder: '',
  intents: [],
  hasExistedIntents: {},
}

const NAMESPACE = 'aiIntentsWizard'

const { reducer, actions } = createSlice({
  initialState: AiIntentsWizardInitialState,
  name: NAMESPACE,
  reducers: {
    reset: () => AiIntentsWizardInitialState,
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload
    },
    setFinished: (state) => {
      state.isFinished = true
    },
    setFolder: (state, action: PayloadAction<string>) => {
      state.folder = action.payload
    },
    setIntents: (state, action: PayloadAction<IntentItem[]>) => {
      state.intents = action.payload
    },
    setChannel: (state, action: PayloadAction<ChannelType | null>) => {
      if (action.payload !== null) {
        state.channel = action.payload
      }
    },
    setShowModal: (state, action: PayloadAction<boolean>) => {
      state.showModal = action.payload
    },
    setExistedIntents: (
      state,
      action: PayloadAction<{ channel: ChannelType; status: boolean }>,
    ) => {
      state.hasExistedIntents = {
        ...state.hasExistedIntents,
        [action.payload.channel]: action.payload.status,
      }
    },
  },
})

const showPaywallAndSetUpPurchaseListener = (): IThunkAction => (dispatch, getState) => {
  dispatch(AiCommunicationsActions.setTriggeredBySetLive(true))
  billing.requestUpgrade({
    addOns: [AddOnSlug.AI],
    source: UpgradeSource.AI_BULK_FAQ,
    customProps: {
      [UpgradeModalVersion.UPGRADE_AI]: {
        indicativeMessage: l.translate(
          'Your AI automation will go live when you start a Pro plan with AI!',
        ),
      },
      [UpgradeModalVersion.UPGRADE_AI_FROM_TRIAL]: {
        indicativeMessage: l.translate(
          'Your AI automation will go live when you start a Pro plan with AI!',
        ),
      },
    },
  })

  const endActions = [upgrade.fulfilled.type, cancelUpgradeRequest.type]
  const publishFlowUnsubscribe = appListenerMiddleware.startListening({
    predicate: (action) => endActions.includes(action.type),
    effect: () => {
      if (getHasAbility(getState(), Ability.AI_KEYWORD)) {
        dispatch(AiIntentsWizardActions.setLive())
      }
      publishFlowUnsubscribe()
    },
  })
}

const setDefaultChannel =
  (channel?: ChannelType): IThunkAction =>
  (dispatch, getState) => {
    const state = getState()

    const connectedChannels = getConnectedChannels(state)

    if (channel && connectedChannels.includes(channel)) {
      dispatch(AiIntentsWizardActions.setChannel(channel))
      return
    }

    if (connectedChannels.includes(ChannelType.INSTAGRAM)) {
      dispatch(AiIntentsWizardActions.setChannel(ChannelType.INSTAGRAM))
    } else {
      dispatch(AiIntentsWizardActions.setChannel(connectedChannels[0]))
    }
  }

const prepareFolder = createAppAsyncThunk(
  `${NAMESPACE}/prepareFolder`,
  async (accountId: string, { dispatch }) => {
    try {
      let path = localStorage.getItem(`${FAQ_FOLDER_ID_STORAGE_FIELD}_${accountId}`)
      let isCurrentFolderExists = Boolean(path)

      const { payload: folders } = await dispatch(cmsFoldersActions.fetchFolders())

      if (path) {
        isCurrentFolderExists =
          Array.isArray(folders) && folders.some((folder) => folder.path === path)
      } else {
        const existedFolder =
          Array.isArray(folders) &&
          folders.find((folder) => folder.title === l.getString(FOLDER_NAME))
        path = existedFolder ? existedFolder.path : path
        isCurrentFolderExists = Boolean(existedFolder)
      }

      if (!path || !isCurrentFolderExists) {
        const result = await dispatch(
          cmsFoldersActions.createFolder({ path: '/', title: l.getString(FOLDER_NAME) }),
        ).unwrap()

        if (result && result.path) {
          path = result.path
        }
      }

      localStorage.setItem(`${FAQ_FOLDER_ID_STORAGE_FIELD}_${accountId}`, path)
      dispatch(AiIntentsWizardActions.setFolder(path))

      return path
    } catch (error) {
      handleCatch(error)
    }
  },
)

const setLive = createAppAsyncThunk(`${NAMESPACE}/setLive`, async (_, { dispatch, getState }) => {
  const state = getState()
  const channel = AiIntentsWizardSelectors.getChannel(state)
  const intents = AiIntentsWizardSelectors.getIntents(state)
  const accountId = getAccountIdFromURL()

  if (!accountId) {
    return
  }

  const userHasAIAbility = getHasAbility(getState(), Ability.AI_KEYWORD)

  if (!userHasAIAbility) {
    dispatch(showPaywallAndSetUpPurchaseListener())
    return
  }

  dispatch(AiIntentsWizardActions.setLoading(true))

  try {
    const { payload: path } = await dispatch(prepareFolder(accountId))
    const publishedFlows: string[] = []

    for (const intent of intents) {
      const { intentText, messageText } = intent

      const { ns: flow_ns } = await Service.createFlow(intentText, path)

      await Service.publishFlow({
        id: uuid(),
        flow_ns,
        channel,
        message: messageText,
      })

      const { intent_id } = await Service.createAiIntent({ intent: intentText, channel, flow_ns })

      await Service.updateAiIntentStatus(intent_id)

      publishedFlows.push(flow_ns)
    }

    dispatch(
      AiCommunicationsActions.tryOpenFirstAiAutomationCreationModal({
        accountId,
        source: WelcomeMessageSource.FAQ_WIZARD,
      }),
    )
    dispatch(AiIntentsWizardActions.setFinished())
    alert(l.translate('Your automation is live'), 'success')
    AiIntentsWizardAnalytics.logSetLiveSuccessfully({ flow_ns: publishedFlows })
  } catch (error) {
    AiIntentsWizardAnalytics.logSetLiveFailed()
    errorTrackingService.logError(error, {
      scope: 'ai',
      section: 'ai_intents_wizard',
    })
  } finally {
    dispatch(AiIntentsWizardActions.setLoading(false))
  }
})

const searchFlowsByChannel = createAppAsyncThunk(
  `${NAMESPACE}/searchFlowsByChannel`,
  async (channel: ChannelType, { dispatch }) => {
    try {
      const {
        data: { list },
      } = await CmsApi.searchFlows(generateFilterBody(channel))

      dispatch(AiIntentsWizardActions.setExistedIntents({ channel, status: Boolean(list.length) }))
    } catch (error) {
      handleCatch(error)
    }
  },
)

export const AiIntentsWizardActions = {
  reset: actions.reset,
  setLoading: actions.setLoading,
  setFinished: actions.setFinished,
  setChannel: actions.setChannel,
  setShowModal: actions.setShowModal,
  setIntents: actions.setIntents,
  setExistedIntents: actions.setExistedIntents,
  setFolder: actions.setFolder,
  setDefaultChannel,
  searchFlowsByChannel,
  setLive,
}

const getState = (state: RootState) => state.aiIntentsWizard

export const AiIntentsWizardSelectors = {
  getState,
  getChannel: (state: RootState) => getState(state).channel,
  getLoading: (state: RootState) => getState(state).isLoading,
  getFinished: (state: RootState) => getState(state).isFinished,
  getFolder: (state: RootState) => getState(state).folder,
  getShowModal: (state: RootState) => getState(state).showModal,
  getIntents: (state: RootState) => getState(state).intents,
  getExistedIntents: (state: RootState) => getState(state).hasExistedIntents,
}

export const AiIntentsWizardReducer = reducer

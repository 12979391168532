import { AttachmentBlockAttachmentType } from 'common/builder/blocks/blockConstants'
import { BlockType } from 'common/builder/constants/BlockType'

export const allowedReasonNodeBlocks = [
  BlockType.CARDS,
  BlockType.TEXT,
  BlockType.ATTACHMENT,
  BlockType.LIST,
]

export const allowedDefaultReplyNodeBlocks = [BlockType.TEXT, BlockType.CARD, BlockType.CARDS]

export const allowedAttachmentTypes = [
  AttachmentBlockAttachmentType.IMAGE,
  AttachmentBlockAttachmentType.VIDEO,
  AttachmentBlockAttachmentType.AUDIO,
]

export const allowedInstagramDefaultReplyBlocks = [
  BlockType.TEXT,
  BlockType.ATTACHMENT,
  BlockType.CARD,
  BlockType.CARDS,
]

export enum GenericFieldType {
  SYSTEM = 'suf',
  USER = 'cuf',
  GLOBAL = 'gaf',
}

interface AbstractGenericField {
  type: GenericFieldType
}

export interface GenericSystemField extends AbstractGenericField {
  type: GenericFieldType.SYSTEM
  field_name: string
}

export interface GenericCustomField extends AbstractGenericField {
  type: GenericFieldType.USER | GenericFieldType.GLOBAL
  field_id: number
}

/**
 * Universal field transport format (suf,cuf,gaf)
 */
export type GenericField = GenericCustomField | GenericSystemField

import { z } from 'zod'

import { ChannelType } from 'common/core/constants/ChannelType'
import { BusinessErrorSchema } from 'shared/api/common/schemas/errors'
import { VirtualFlexibleAgentSchema } from 'shared/api/requests/aiAgent/schemas'

export enum AiAgentPlaygroundExecutionContext {
  PLAYGROUND = 'playground',
  WIZARD = 'wizard',
}

const initThreadRequestSchema = z.object({
  subscriber_id: z.string().nullable(),
  agent_data: VirtualFlexibleAgentSchema,
  content_oid: z.string(),
  execution_context: z.nativeEnum(AiAgentPlaygroundExecutionContext),
  content_id: z.number().nullable().optional(),
  flow_ns: z.string().nullable().optional(),
  channel: z.nativeEnum(ChannelType).nullable(),
})

export const AiAgentPlaygroundSchemas = {
  initThread: {
    request: initThreadRequestSchema,
    query: z.undefined(),
    path: z.undefined(),
    response: z.object({
      session_id: z.string(),
      debug_info: z
        .array(
          z.object({
            field: z.string(),
            value: z.string(),
          }),
        )
        .optional(),
      subscriber_data: z
        .array(
          z.object({
            field_id: z.string(),
            value: z.string(),
          }),
        )
        .optional(),
    }),
    errors: {
      LimitExceededError: BusinessErrorSchema.extend({
        field: z.literal('limit_exceeded'),
      }),
    },
  },
  startThread: {
    request: z.object({
      agent_id: z.number().optional(),
      session_id: z.string().optional(),
      subscriber_id: z.string().nullable().optional(),
    }),
    query: z.undefined(),
    path: z.undefined(),
    response: z.unknown(),
  },
  sendEvent: {
    request: z.object({
      input: z.string(),
      session_id: z.string(),
    }),
    query: z.undefined(),
    path: z.undefined(),
    response: z.unknown(),
    errors: {
      LimitExceededError: BusinessErrorSchema.extend({
        field: z.literal('limit_exceeded'),
      }),
    },
  },
}

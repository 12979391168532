import { l } from '@manychat/manyui'

export const AiTermsLink = 'https://manychat.com/legal/ai-tos'

export const getAITermsText = l.makeTranslate(
  'By continuing, you agree with <a class="text-secondary text-underline" href="{link}" target="_blank" rel="noopener noreferrer">Manychat’s AI Supplementary Terms</a>.',
  {
    link: AiTermsLink,
  },
)

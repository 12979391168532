import React from 'react'
import notificationIcon from 'assets/img/notification_logo.png'
import { useAppSelector } from 'reduxTyped'
import { l } from '@manychat/manyui'

import { AssignEvent } from 'apps/chat/constants/LiveChatInterfaces'
import { LOCAL_STORAGE_KEY_NOTIFICATION_TAB_ID } from 'apps/chat/constants/LiveChatNotifications'
import { ChatNotificationPayload } from 'apps/chat/types/chatNotificationTypes'
import { defaultNotificationOpenCallback } from 'apps/chat/utils/callbacks/defaultNotificationOpenCallback'
import { LivechatNotificationSetting } from 'common/settings/constants/LiveChat'
import { getLivechatSettings } from 'common/settings/selectors/settingsSelectors'
import localStorage from 'utils/localStorage'
import { createBrowserNotification } from 'utils/notifications'
import { analyticsService } from 'utils/services/analytics'

import { useBrowserNotificationsMap } from './useBrowserNotificationsMap'

export const useThreadAssignedToMePushNotification = (tabID: number) => {
  const { addNotification } = useBrowserNotificationsMap(tabID)
  const livechatSettings = useAppSelector(getLivechatSettings)
  const isSettingEnabled = livechatSettings?.[LivechatNotificationSetting.NEW_ASSIGNMENT]

  const triggerThreadAssignedToMePushNotification = React.useCallback(
    (threadAssignedToMeNotificationData: AssignEvent) => {
      const userId = threadAssignedToMeNotificationData?.user_id
      const currentTabActiveForNotifications = Number(
        localStorage.getItem(LOCAL_STORAGE_KEY_NOTIFICATION_TAB_ID),
      )

      if (currentTabActiveForNotifications !== tabID || !userId || !isSettingEnabled) {
        return
      }

      analyticsService.sendEvent('LIVE_CHAT.BROWSER_PUSH_NOTIFICATION.ASSIGNED_TO_ME_THREAD')

      const notificationPayload: ChatNotificationPayload = {
        threadId: userId,
      }

      const notificationInstance = createBrowserNotification(
        l.translate('New assigned conversation'),
        {
          icon: notificationIcon,
          silent: true,
          tag: `assigned_conversation_${userId}`,
          data: notificationPayload,
        },
      )

      if (notificationInstance) {
        notificationInstance.onclick = defaultNotificationOpenCallback
        addNotification(userId, notificationInstance)
      }
    },
    [tabID, addNotification, isSettingEnabled],
  )

  return { triggerThreadAssignedToMePushNotification }
}

import {
  create,
  factory,
  evaluateDependencies,
  unaryMinusDependencies,
  unaryPlusDependencies,
  roundDependencies,
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment, @typescript-eslint/prefer-ts-expect-error
  /** @ts-ignore */
} from 'mathjs/lib/esm/number'

const add = (num1: number, num2: number) => num1 + num2
const subtract = (num1: number, num2: number) => num1 - num2
const multiply = (num1: number, num2: number) => num1 * num2
const divide = (num1: number, num2: number) => num1 / num2

const { evaluate } = create({
  evaluateDependencies,
  unaryMinusDependencies,
  unaryPlusDependencies,
  roundDependencies,
  createAdd: factory('add', [], () => add),
  createSubtract: factory('subtract', [], () => subtract),
  createMultiply: factory('multiply', [], () => multiply),
  createDivide: factory('divide', [], () => divide),
})

export { evaluate }

import React, { PureComponent } from 'react'
import cx from 'classnames'
import PropTypes from 'prop-types'
import * as Icon from '@manychat/icons'

import { noop } from '../../../../utils'
import { formatDate, parseDate, isValidDate } from '../../../../utils/date'
import * as l from '../../../../utils/localization/format'
import Calendar from '../Calendar'
import DateInput from '../DateInput'

const PRESETS = {
  today: {
    name: 'today',
    label: 'Today',
    range: () => ({
      start: formatDate(new Date()),
      end: formatDate(new Date()),
    }),
  },
  yesterday: {
    name: 'yesterday',
    label: 'Yesterday',
    range: () => {
      const date = new Date()

      date.setDate(date.getDate() - 1)

      return { start: formatDate(date), end: formatDate(date) }
    },
  },
  week: {
    name: 'week',
    label: 'Last 7 days',
    range: () => {
      const date = new Date()

      date.setDate(date.getDate() - 6)

      return { start: formatDate(date), end: formatDate(new Date()) }
    },
  },
  month: {
    name: 'month',
    label: 'Last 30 days',
    range: () => {
      const date = new Date()

      date.setDate(date.getDate() - 29)

      return { start: formatDate(date), end: formatDate(new Date()) }
    },
  },
}

const INPUT_STYLE = { maxWidth: 120, height: 32 }

const startOfMonthString = (v) => formatDate(parseDate(v)).slice(0, -2) + '01'

export default class DateRange extends PureComponent {
  static propTypes = {
    calendars: PropTypes.number,
    controls: PropTypes.node,
    minDate: PropTypes.string,
    maxDate: PropTypes.string,
    presets: PropTypes.array,
    name: PropTypes.string,
    value: PropTypes.arrayOf(PropTypes.string),
    children: PropTypes.element,
    onChange: PropTypes.func,
  }

  static defaultProps = {
    minDate: '2016-01-01',
    calendars: 2,
    presets: Object.values(PRESETS),
    onChange: noop,
  }

  static parseValue = (value) => {
    const [start, end] = Array.isArray(value)
      ? value.map((v) => (isValidDate(v) ? v : ''))
      : ['', '']
    return { start, end }
  }

  static PRESETS = PRESETS

  state = { ...DateRange.parseValue(this.props.value), viewDate: new Date() }

  startInput = React.createRef()
  endInput = React.createRef()

  componentDidUpdate = (prevProps, prevState) => {
    const { value } = this.props
    if (prevProps.value !== this.props.value) {
      this.setState(DateRange.parseValue(value))
    }
  }

  setLimits(start, end, updateShowDate) {
    const { minDate, maxDate, name, onChange } = this.props
    if (start && end && start > end) {
      ;[start, end] = [end, start]
    }
    if (start && isValidDate(minDate) && start < minDate) {
      start = formatDate(parseDate(minDate))
    }
    if (end && isValidDate(maxDate) && end > maxDate) {
      end = formatDate(parseDate(maxDate))
    }
    const newState = { start, end }
    if (updateShowDate) {
      newState.viewDate = parseDate(end) || new Date()
    }
    this.setState(newState)
    if (start && end) {
      onChange({ target: { value: [start, end], name } })
    }
  }

  setViewMonthRelative = (num) => {
    const viewDate = new Date(this.state.viewDate)
    viewDate.setMonth(viewDate.getMonth() + num)
    this.setState({ viewDate })
  }

  handlePresetChange = (e) => {
    const { minDate, maxDate, presets } = this.props
    const preset = presets.find((preset) => preset.name === e.target.dataset.id)

    if (preset) {
      const { start, end } = preset.range(minDate, maxDate)

      this.setLimits(start, end, true)
    }
  }

  handleMonthClick = (e) => {
    const date = parseDate(e.currentTarget.dataset.date)
    const start = new Date(date)
    start.setDate(1)
    const end = new Date(date)
    end.setMonth(end.getMonth() + 1)
    end.setDate(0)
    this.setLimits(formatDate(start), formatDate(end))
  }

  handleCalendarChange = (e) => {
    const { value } = e.target
    const { start: currentStart, end: currentEnd } = this.state
    const isStart = !currentStart || isValidDate(currentEnd)
    const start = isStart ? value : currentStart
    const end = isStart ? '' : value
    this.setLimits(start, end)

    this[`${isStart ? 'end' : 'start'}Input`].current.select()
  }

  handleChange = (limit, e) => {
    const { start, end } = this.state
    const limits = { start, end }
    limits[limit] = e.target.value
    this.setLimits(limits.start, limits.end, true)
  }

  handleNextMonthClick = () => this.setViewMonthRelative(1)
  handlePreviousMonthClick = () => this.setViewMonthRelative(-1)

  render() {
    const { start, end, viewDate } = this.state
    const { minDate, maxDate, controls, presets } = this.props

    var tmpMonth = parseDate(viewDate)
    var calendars = []
    for (let i = 0; i < this.props.calendars; i++) {
      const dateString = startOfMonthString(tmpMonth)
      const disabled =
        (isValidDate(minDate) && dateString < startOfMonthString(minDate)) ||
        (isValidDate(maxDate) && dateString > maxDate)

      calendars.unshift(
        <div className="col p-t-xs p-x-xxs" key={'m-' + i}>
          <div
            onClick={disabled ? undefined : this.handleMonthClick}
            data-date={dateString}
            className={cx(
              'p-y-xs text-center no-select',
              disabled ? 'text-disabled' : 'text-primary bg-hover pointer',
            )}
          >
            {`${l.month(tmpMonth)} ${l.year(tmpMonth)}`}
          </div>
          <div className="p-x-xxs p-b-xxs">
            <Calendar
              onChange={this.handleCalendarChange}
              viewDate={dateString}
              hideHeader
              minDate={minDate}
              maxDate={maxDate}
              value={[start, end]}
            />
          </div>
        </div>,
      )
      // Reset month date is needed for correct setMonth work.
      // For additional info look in last paragraph at:
      // https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Date/setMonth#Description
      tmpMonth.setDate(1)
      tmpMonth.setMonth(tmpMonth.getMonth() - 1)
    }

    return (
      <div>
        <div className="row middle gutter p-a-xs">
          <div className="col-auto">
            {presets.map((preset) => {
              return (
                <a
                  data-id={preset.name}
                  key={preset.name}
                  className="p-x-xxs p-y-xs bg-hover text-primary"
                  onClick={this.handlePresetChange}
                >
                  {preset.label}
                </a>
              )
            })}
          </div>

          <div className="col">
            <div className="row middle">
              <div className="col-auto">
                <DateInput
                  ref={this.startInput}
                  value={this.state.start}
                  onChange={this.handleChange.bind(this, 'start')}
                  style={INPUT_STYLE}
                />
              </div>
              <div className="col-auto p-x-xxs">
                <span>—</span>
              </div>
              <div className="col-auto">
                <DateInput
                  ref={this.endInput}
                  value={this.state.end}
                  onChange={this.handleChange.bind(this, 'end')}
                  style={INPUT_STYLE}
                />
              </div>
            </div>
          </div>

          <div className="col-auto">{controls}</div>
        </div>
        <div className="row b-t">
          <div
            className="col-auto p-x-xxs p-y bg-hover text-secondary text-h-primary pointer"
            onClick={this.handlePreviousMonthClick}
          >
            <Icon.ChevronLeft size={24} />
          </div>
          {calendars}
          <div
            className="col-auto p-x-xxs p-y bg-hover text-secondary text-h-primary pointer"
            onClick={this.handleNextMonthClick}
          >
            <Icon.ChevronRight size={24} />
          </div>
        </div>
      </div>
    )
  }
}

import { isLocal } from 'utils/isLocal'
import errorTrackingService from 'utils/services/errorTrackingService'
import {
  CSRF_TOKEN_EMPTY_EVENT_NAME,
  CSRF_TOKEN_EMPTY_FINGERPRINT,
} from 'utils/services/sentryConstantNames'

import { Body, RequestErrorReason, RequestOptions, ResponseType } from '../types'

import { fetch } from './fetch'
import { getEndpoint, RequestError } from './RequestError'

export const request = async <T extends Body>(
  url: string,
  options: RequestOptions = {},
): Promise<T> => {
  const { responseType = 'json', headers = {}, ...fetchOptions } = options

  if (!headers['X-Csrf-Token']) {
    errorTrackingService.trackWarning(CSRF_TOKEN_EMPTY_EVENT_NAME, {
      extra: { url, body: fetchOptions?.body ?? '' },
      fingerprint: CSRF_TOKEN_EMPTY_FINGERPRINT,
    })
  }

  const response = await execute(
    () =>
      fetch(url, {
        ...fetchOptions,
        headers: {
          ...headers,
          'X-Requested-With': 'XMLHttpRequest',
        },
      }),
    'network-failure',
    url,
  )
  if (!response.ok) {
    if (response.status === 401) {
      const hasLocal = isLocal()
      !hasLocal && window.location.assign('/login')
      return Promise.resolve({ state: true } as T)
    } else if (response.status === 451) {
      return Promise.resolve({ state: true } as T)
    } else {
      errorTrackingService.addBreadcrumb({
        category: 'fetch.response',
        data: {
          status: response.status,
          statusText: response.statusText,
        },
      })
      errorTrackingService.setTag('status', response.status)
      throw new RequestError({ reason: 'server', url })
    }
  }

  const data = await execute<T>(() => response[responseType](), 'response-type', url, responseType)
  if (dataHasError(data, responseType)) {
    errorTrackingService.addBreadcrumb({
      category: 'fetch.response',
      data: data,
    })
    throw new RequestError({ reason: 'business-logic', data, url })
  }

  return data
}

const dataHasError = (data: Body, responseType: string) =>
  responseType === 'json' && data.state !== true

const execute = async <T>(
  command: () => Promise<T>,
  errorReason: RequestErrorReason,
  url?: string,
  responseType?: ResponseType,
) => {
  try {
    return await command()
  } catch (error) {
    errorTrackingService.addBreadcrumb({
      category: 'execute method.error',
      data: { error, errorReason, url: url ? getEndpoint(url) : undefined },
    })

    throw new RequestError({
      reason: errorReason,
      data: error instanceof Error ? error.message : '',
      url,
      responseType: errorReason === 'response-type' ? responseType : undefined,
    })
  }
}

import React, { useCallback } from 'react'
import { useNavigate } from 'react-router-dom'

import {
  CustomizableBreadcrumbsButton,
  CustomizableButtonSize,
} from 'components/Breadcrumbs/components/CustomizableBreadcrumbsButton/CustomizableBreadcrumbsButton'
import { BaseBreadcrumbsSegment } from 'components/Breadcrumbs/lib/types'

export interface BreadcrumbsButtonProps<CustomSegment> {
  segment: CustomSegment
  size: CustomizableButtonSize
  disabled: boolean
  className?: string
}

export const BreadcrumbsButton = <CustomSegment extends BaseBreadcrumbsSegment>({
  segment,
  size,
  disabled,
  className,
}: BreadcrumbsButtonProps<CustomSegment>) => {
  const navigate = useNavigate()

  const openPage = useCallback(
    (event: React.MouseEvent<HTMLButtonElement>, segment: CustomSegment) => {
      if (event.ctrlKey || event.metaKey) {
        window.open(segment.path)
      } else {
        navigate(segment.path)
      }
    },
    [navigate],
  )

  return (
    <CustomizableBreadcrumbsButton
      onClick={openPage}
      className={className}
      segment={segment}
      size={size}
      disabled={disabled}
      highlight={false}
    />
  )
}

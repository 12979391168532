import React from 'react'
import { z } from 'zod'

import { BlockType } from '../../constants'
import { registry } from '../../registry'
import { ChildrenSchema, ClassNameSchema, StylesSchema } from '../../schemas'
import { BlockProps } from '../../types'
import { Slot } from '../Slot'

const BoxPropsSchema = z.object({
  className: ClassNameSchema,
  children: ChildrenSchema,
  style: StylesSchema,
})

const Component = (props: BlockProps<typeof BoxPropsSchema>) => (
  <div {...props} data-block={props.blockId} className={props.className as string} />
)

export const Box = registry.register(Component, {
  props: BoxPropsSchema,
  type: BlockType.BOX,
  previewProps: {
    children: [],
  },
  preview: () => (
    <Box className="panel panel-info" style={{ maxWidth: 'fit-content' }}>
      <Slot style={{ width: 50, height: 50 }} />
      <Slot style={{ width: 50, height: 50 }} />
    </Box>
  ),
})

import React, { ChangeEventHandler, useCallback, useState } from 'react'
import DOMPurify from 'dompurify'
import { BtnV2, Icon, l, TextArea, tints } from '@manychat/manyui'

import cm from 'common/banners/mobile/MobileBanner.module.css'

interface UserInputProps {
  goBack: () => void
  onSend: (userInput: string) => void
}

export const UserInput = ({ goBack, onSend }: UserInputProps) => {
  const [value, setValue] = useState('')

  const handleChange: ChangeEventHandler<HTMLTextAreaElement> = useCallback((e) => {
    setValue(e.target.value)
  }, [])

  const handleButtonClick = useCallback(() => {
    onSend(DOMPurify.sanitize(value))
  }, [onSend, value])

  return (
    <div className={cm.surveyContainer}>
      <Icon.ChevronLeft
        size={24}
        color={tints.neutral[2]}
        className={cm.backIcon}
        onClick={goBack}
      />
      <h1 id="improve-label">{l.translate('What can we do to improve Manychat App?')}</h1>
      <TextArea
        ariaLabelledBy="improve-label"
        onChange={handleChange}
        placeholder={l.translate('Please be direct…')}
        className="m-y-lg"
        minRows={6}
        value={value}
      />
      <BtnV2 fullWidth variant="primary" onClick={handleButtonClick} disabled={!value}>
        {l.translate('Send')}
      </BtnV2>
    </div>
  )
}

import * as API from 'constants/API'

const createPatchNotificationMiddleware = () => {
  return ({ dispatch, getState }) =>
    (next) =>
    (action) => {
      if (action == null) {
        return next(action)
      }
      if (action.item == null || !action.type || !action.entity) {
        return next(action)
      }
      if (
        !action.type.endsWith('_CREATED_NOTIFICATION') &&
        !action.type.endsWith('_UPDATED_NOTIFICATION')
      ) {
        return next(action)
      }

      const { entity } = action
      const entityDesc = API[entity]
      if (entityDesc == null) {
        return console.error(`[patchNotification middleware] Unknown entity: '${entity}'`)
      }

      if (entityDesc.import) {
        action.item = entityDesc.import(action.item)
      }

      const { idKey } = entityDesc
      if (idKey) {
        action.item.id = action.item.id || action.item[idKey]
      }

      return next(action)
    }
}

export default createPatchNotificationMiddleware

import React from 'react'

import { ThemeContext } from '../../context'
import { PhonePreviewFrame } from '../PhonePreviewFrame/PhonePreviewFrame'

import { PhonePreviewRootProps } from './interfaces'

export function PhonePreviewRoot(props: PhonePreviewRootProps) {
  return (
    <ThemeContext.Provider value={props.theme}>
      <PhonePreviewFrame>{props.children}</PhonePreviewFrame>
    </ThemeContext.Provider>
  )
}

PhonePreviewRoot.displayName = 'PhonePreview.Root'

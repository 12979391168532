const children = [
  {
    index: true,
    lazy: () => import('./iceBreakers.page'),
  },
  {
    path: ':flow_id/:mode?/:content_id?',
    lazy: () => import('./iceBreakersBuilder.page'),
  },
] as const

export const IceBreakersRoutes = [
  {
    path: ':channel?/FAQ',
    children,
  },
  {
    path: ':channel?/iceBreakers',
    children,
  },
] as const

import { PostCoveredArea, WidgetStatusType } from 'apps/growthTools/models/Widget/constants'
import { ChannelType } from 'common/core/constants/ChannelType'
import errorTrackingService from 'utils/services/errorTrackingService'

export const growthToolsSelectors = (state) => state.widgets.list

export const getFilter = (state) => growthToolsSelectors(state).filter

export const getSearchQuery = (state) => growthToolsSelectors(state).searchQuery

export const getWidgets = (state) => growthToolsSelectors(state).items

export const getSorting = (state) => growthToolsSelectors(state).sortingBy

export const getSortingOrder = (state) => growthToolsSelectors(state).sortingOrder

const getItemsList = (state) => state?.widgets?.byId

const getWidget = (state, widgetId) => getItemsList(state)?.[widgetId]

export const getById = (state, widgetId) => {
  if (!widgetId) {
    return null
  }

  const widget = getWidget(state, widgetId)
  return widget?.item ?? null
}

export const getSetupParamsById = (state, widgetId) => getById(state, widgetId)?.data

export const hasChanges = (state, nodeId) => {
  const widget = getWidget(state, nodeId)
  return widget?.hasChanges ?? false
}

export const hasUnsavedDraft = (state, triggerId) => {
  const item = getById(state, triggerId)
  if (!item) {
    const itemsList = getItemsList(state)
    errorTrackingService.trackWarning('Item is missing in widgets.byId.[triggerId]', {
      extra: {
        triggerId,
        itemsList,
      },
    })

    return false
  }

  return item.status === WidgetStatusType.INITIAL
}

export const getIsInstagramCgtWidgetWithAllPostsCoveredArea = (state, widgetId) => {
  const widget = getById(state, widgetId)

  if (widget?.channel !== ChannelType.INSTAGRAM) return false

  return widget?.data?.feed_comment_settings?.post_covered_area === PostCoveredArea.ALL_POSTS
}

import React, { Component, isValidElement, cloneElement } from 'react'
import cx from 'classnames'
import PropTypes from 'prop-types'
import { sanitizeURL } from '@manychat/manyui'

import cleanProps from '../../lib/clean-react-props'
import { deprecatedDecorator } from '../../utils/deprecated-decorator'
import { getLanguage } from '../../utils/localization/format'
// eslint-disable-next-line no-unused-vars
import BtnV2 from '../BtnV2'
import Loader from '../Loader'

import cm from './Btn.module.css'

/** Buttons allow users to take actions, and make choices, with a single click. */
/** @deprecated use {@link BtnV2} component */
class Btn extends Component {
  static propTypes = {
    /** CSS class for "button" tag. Used as wrapper. */
    className: PropTypes.string,
    /** CSS styles for "button" tag. Used as wrapper. */
    style: PropTypes.object,
    /** CSS classes for internal elements: button, label and loader. */
    theme: PropTypes.exact({
      button: PropTypes.string,
      label: PropTypes.string,
      loader: PropTypes.string,
    }),

    // Content
    /** Custom component in place of button/link. */
    component: PropTypes.element,
    /** Icon component for an icon-only button. */
    icon: PropTypes.node,
    iconLeft: PropTypes.node,
    iconRight: PropTypes.node,
    /**  Positioning text and icons around the edges of the button.  */
    spaceBetween: PropTypes.bool,
    /**
     * DISCLAIMER: By default `text-transform: capitalize` is applied to a button,
     * but it is deprecated behavior and will be removed later, so there is a
     * recommendation to pass text with correct case or use related CSS-classes.
     *
     * Alternative of a children property. Represents content of a button.
     */
    label: PropTypes.node,
    children: PropTypes.node,
    /** URL to use a button as a link element. */
    href: PropTypes.string,
    /**
     * DEPRECATED: Use {href} property instead.
     * URL to use a button as a link element.
     */
    to: PropTypes.string,
    /** HTML target attribute for a button used as a link. */
    target: PropTypes.string,

    // Colors
    primary: PropTypes.bool,
    success: PropTypes.bool,
    danger: PropTypes.bool,
    accent: PropTypes.bool,

    // Shape
    outlined: PropTypes.bool,
    /** DEPRECATED: Use {outlined} property instead. */
    inverse: PropTypes.bool,
    ghost: PropTypes.bool,

    // Size
    xs: PropTypes.bool,
    sm: PropTypes.bool,
    lg: PropTypes.bool,
    fullWidth: PropTypes.bool,
    multiline: PropTypes.bool,

    active: PropTypes.bool,
    disabled: PropTypes.bool,

    loader: PropTypes.bool,

    type: PropTypes.string,

    onClick: PropTypes.func,
  }

  static defaultProps = {
    type: 'button',
    theme: { button: '', label: '', loader: '' },
    onClick() {},
  }

  handleClick = (e) => {
    this.props.onClick && this.props.onClick(e)
  }

  render() {
    const {
      className,
      style,
      theme,
      // Content
      icon,
      iconLeft,
      iconRight,
      spaceBetween,
      label,
      children,
      loader,
      type = 'button',
      target,
      href,
      to,
      component,
      // Colors
      primary,
      success,
      danger,
      accent,
      // Shape
      outlined,
      inverse,
      ghost,
      // Size
      xs,
      sm,
      lg,
      fullWidth,
      multiline,
      // State
      active,
      disabled,
      ...other
    } = this.props

    const isEnglish = getLanguage().startsWith('en')

    const props = {
      ...other,
      disabled: disabled,
      onClick: disabled ? undefined : this.handleClick,
      className: cx(
        cm.btn,
        !(outlined || inverse) && !ghost && cm.filled,
        (outlined || inverse) && !ghost && cm.outlined,
        ghost && cm.ghost,
        primary && cm.primary,
        success && cm.success,
        danger && cm.danger,
        active && cm.active,
        accent && cm.accent,
        spaceBetween && cm.spaceBetween,
        xs && cm.xs,
        sm && cm.sm,
        lg && cm.lg,
        fullWidth && cm.fullWidth,
        multiline && cm.multiline,
        (icon || iconLeft || iconRight) && cm.icon,
        isEnglish && 'text-capitalize',
        className,
        theme.button,
      ),
      style: Object.assign({ position: 'relative' }, style),
    }

    const content = (
      <>
        {loader && (
          <div className={cx('p-absolute p-stretched', cm.loader)}>
            <Loader center size={20} theme={{ spinner: cx(cm.loaderSpinner, theme.loader) }} />
          </div>
        )}
        {iconLeft && !icon && !iconRight && iconLeft}
        {icon ? icon : <span className={cx(cm.label, theme.label)}>{children || label}</span>}
        {iconRight && !icon && !iconLeft && iconRight}
      </>
    )

    if (isValidElement(component)) {
      return cloneElement(component, { ...props, children: content })
    }

    if (href != null || to != null || target != null) {
      return (
        <a {...cleanProps(props)} href={sanitizeURL(href || to)} target={target}>
          {content}
        </a>
      )
    }

    return (
      <button {...cleanProps(props)} type={type}>
        {content}
      </button>
    )
  }
}
/** @deprecated use {@link BtnV2} component */
export default deprecatedDecorator(Btn, { replacementComponentName: 'BtnV2' })

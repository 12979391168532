import { z } from 'zod'

const UserInactivityResponseSchema = z.object({ was_in_process: z.boolean() })

export const UserInactivityDeletionSchemas = {
  confirmActivity: {
    request: z.undefined(),
    response: UserInactivityResponseSchema,
    query: z.undefined(),
    path: z.undefined(),
  },
}

export type UserInactivityResponseType = z.infer<typeof UserInactivityResponseSchema>

import { useState, useCallback } from 'react'
import { useAppDispatch, useAppSelector } from 'reduxTyped'

import { useModalData } from 'apps/cms/components/Flows/components/AutomationModals/hooks/useModalData'
import { CmsAnalytics } from 'apps/cms/lib/analytics'
import { contentManagementActions } from 'apps/cms/store'
import { AutomationModalType } from 'apps/cms/store/lib/types'
import { getCurrentAccountID } from 'common/core/selectors/appSelectors'
import { formatUsesListResponse } from 'common/usesList/utils'
import { handleCatch } from 'shared/api/lib/errors/handlers'
import { CmsApi } from 'shared/api/requests/cms'
import { useDidMountEffect } from 'utils/commonHooks'

type UsageInfo = ReturnType<typeof formatUsesListResponse> | null

export const usePermanentDeleteFlow = () => {
  const dispatch = useAppDispatch()

  const [isDeleting, setDeleting] = useState(false)
  const [usageInfo, setUsageInfo] = useState<UsageInfo>(null)

  const currentAccountId = useAppSelector(getCurrentAccountID)
  const { flowName, flowId, flowPath } = useModalData({
    type: AutomationModalType.PERMANENT_DELETE,
  })

  const closeModal = useCallback(() => {
    dispatch(contentManagementActions.closeModal(AutomationModalType.PERMANENT_DELETE))
  }, [dispatch])

  const fetchUsesList = useCallback(async () => {
    try {
      const response = await CmsApi.getUsesList({
        query: { flow_ns: flowId },
      })
      const usageInfo = formatUsesListResponse(response.data.entities, currentAccountId)
      setUsageInfo(usageInfo)
    } catch (error) {
      handleCatch(error)
    }
  }, [currentAccountId, flowId])

  const deleteFlow = useCallback(async () => {
    setDeleting(true)
    await dispatch(contentManagementActions.permanentlyRemoveFlow(flowPath))
    CmsAnalytics.logPermanentDeleteFlow()

    setDeleting(false)
    closeModal()
  }, [dispatch, flowPath, closeModal])

  useDidMountEffect(() => {
    fetchUsesList()
  })

  return {
    deleteFlow,
    closeModal,

    usageInfo,
    isDeleting,
    flowName,
  }
}

/* global localStorage:readable */

// This is localStorage wrapper with memory storage fallback for cases if localStorage is unavailable
// localStorage is unavailable in some browsers in incognito mode so memory fallback is required
import localStorageMemory from 'localstorage-memory'

export const hasLocalStorageSupport = () => {
  try {
    return 'localStorage' in window && window['localStorage'] !== null
  } catch (e) {
    return false
  }
}

// eslint-disable-next-line no-restricted-globals
const storage = hasLocalStorageSupport() ? localStorage : localStorageMemory

const getItem = (key) => {
  try {
    const value = storage.getItem(key)
    // TODO original getItem returns null if key not found; refactor all usages and implement original behaviour
    return value === null ? undefined : value
  } catch (err) {
    return undefined
  }
}

const setItem = (key, value) => {
  try {
    storage.setItem(key, value)
  } catch (err) {}
}

const removeItem = (key) => {
  try {
    storage.removeItem(key)
  } catch (err) {}
}

export default {
  getItem,
  setItem,
  removeItem,
}

import 'url-polyfill/url-polyfill.min'
import 'intersection-observer'
import '@manychat/manyui/src/assets/css/index.css'
import React from 'react'
import * as Sentry from '@sentry/react'
import { ScopeContext, SeverityLevel } from '@sentry/types'
import ReactDOM from 'react-dom/client'
import {
  useLocation,
  useNavigationType,
  createRoutesFromChildren,
  matchRoutes,
} from 'react-router-dom'

import { api } from 'app/initMCApi'
import { store } from 'app/initStore'
import {
  getCurrentAccountID,
  getAllowedLanguages,
  getUserLanguage,
  getCsrfToken,
  getLoggedIn,
} from 'common/core/selectors/appSelectors'
import { ACCOUNT_ID_REGEX, ID_REGEX } from 'constants/RegExs'
import { initApi } from 'shared/api/initApi'
import { setAccountIDGetter } from 'utils/accountId'
import { setURLFormatter } from 'utils/api/mcApi'
import { RequestError } from 'utils/api/mcApi/RequestError'
import { CustomUserCentricsEvent } from 'utils/getInfoAboutService'
import { setLoggingTasks } from 'utils/longTaskTracker/LongTaskTracker'
import { addSystemParams } from 'utils/middleware/urlUtils'
import { PageLifecycleTracker } from 'utils/pageLifeCycle'
import { PAGE_SESSION_ID } from 'utils/pageSessionId'
import { router } from 'utils/router/router'
import { getComponentByRoute } from 'utils/router/tools'
import { hasEventWithUrlWithoutCheckTokenOnBackend } from 'utils/services/errorTrackingService/helpers'
import { initializeFeatureFlagsServices } from 'utils/services/featureFlagsService'
import { newOnboardingService, initNewOnboardingService } from 'utils/services/newOnboardingService'
import { linkDomain } from 'utils/url'

import { initI18n } from '../i18n/config'

import actions from './actions'
import { initializePostRenderingLogic } from './initApp'
import { initIsMobile } from './initIsMobile'
import Root from './Root'
import logWebVitals from './utils/logWebVitals/logWebVitals'

const User = window.__INIT__?.['app.user']
const CurrentAccount = window.__INIT__?.['app.currentAccount']

const IdFromInit = User?.user_id
const PageIdInit = CurrentAccount?.page_id

const USER_ID = IdFromInit ? String(IdFromInit) : undefined
const PAGE_ID = PageIdInit ? String(PageIdInit) : undefined

const IS_LOCAL = process.env.NODE_ENV === 'development'

const replaceIdsInTransaction = (transaction: string) => transaction.replace(ID_REGEX, 'id')

export const initSentry = (event: CustomUserCentricsEvent | Event) => {
  const hasLoadError = 'detail' in event && event?.detail
  const initialScope = hasLoadError
    ? undefined
    : {
        tags: { pageId: PAGE_ID },
        user: {
          id: USER_ID,
          name: User?.name,
        },
      }

  Sentry.init({
    dsn: `https://2e97b4daccb443d4aa97ccedea318be3@o1241926.ingest.sentry.io/6396134`,
    integrations: [
      Sentry.reactRouterV6BrowserTracingIntegration({
        useEffect: React.useEffect,
        useLocation,
        useNavigationType,
        createRoutesFromChildren,
        matchRoutes,
      }),
      Sentry.thirdPartyErrorFilterIntegration({
        filterKeys: ['manychat-app'],
        behaviour: 'apply-tag-if-exclusively-contains-third-party-frames',
      }),
    ],
    ignoreErrors: [
      // These errors don't strongly affect users and occur in dependencies code
      // https://youtrack.manychat.io/issue/PLATFORM-719
      'ResizeObserver loop completed with undelivered notifications',
      // https://youtrack.manychat.io/issue/TC-1136
      'Event `CustomEvent` (type=unhandledrejection) captured as promise rejection',
    ],
    tracesSampleRate: IS_LOCAL ? 1 : 0.01,
    environment: window.__SENTRY_ENV__?.environment,
    release: window.__SENTRY_ENV__?.release,
    initialScope,
    normalizeDepth: 4,
    beforeSend: (event, hint) => {
      const { originalException: exception, captureContext } = hint

      const isEventWithUrlWithoutCheckTokenOnBackend = hasEventWithUrlWithoutCheckTokenOnBackend({
        exception,
        captureContext,
      })
      if (isEventWithUrlWithoutCheckTokenOnBackend) {
        return null
      }

      const isRequestError = exception instanceof RequestError
      if (isRequestError && !exception.online) {
        return null
      }

      if (isRequestError) {
        event.tags = {
          ...event.tags,
          request_error: true,
          error_reason: exception.reason,
        }

        if (exception.endpoint) {
          event.transaction = exception.endpoint
        }

        if (!event.fingerprint && exception.endpoint) {
          event.fingerprint = ['{{ default }}', exception.endpoint]
        }
      }

      if (event.request && event.request.url) {
        event.request.url = event.request.url.replace(ACCOUNT_ID_REGEX, '/accountId/')
      }

      if (event.transaction) {
        event.transaction = replaceIdsInTransaction(event.transaction)
      }

      event.tags = {
        ...event.tags,
        pageSessionId: PAGE_SESSION_ID,
      }
      return event
    },
    beforeSendTransaction(event) {
      if (event.transaction) {
        event.transaction = replaceIdsInTransaction(event.transaction)
      }

      return event
    },
  })

  if (window.STATIC_VERSION !== undefined) {
    Sentry.setTag('app_version', window.STATIC_VERSION)
  }

  if (hasLoadError) {
    const options: Partial<ScopeContext> = {
      extra: event.detail as unknown as SafeUnknownObject,
      fingerprint: ['usercentrics-load-error'],
      level: 'error' as SeverityLevel,
    }
    Sentry.captureException('Usercentrics load error', options)
  }
}
window.addEventListener('initializedSentry', initSentry)

initNewOnboardingService(store)

// API
setURLFormatter((url) => addSystemParams(linkDomain(url), store.getState))
setAccountIDGetter(() => getCurrentAccountID(store.getState()))

initApi({
  getCurrentAccountId: () => getCurrentAccountID(store.getState()),
  getCsrfToken: () => getCsrfToken(store.getState()),
})

// global ref
window.app = {
  store,
  actions,
  newOnboardingService,
  // Global ref to the router instance for avoiding cycle deps issues with using router/tools
  router,
}

Sentry.setTag('component', getComponentByRoute())
router.subscribe(() => Sentry.setTag('component', getComponentByRoute()))

initializeFeatureFlagsServices(store.getState())
initIsMobile()
initI18n(getAllowedLanguages(store.getState()), getUserLanguage(store.getState())).then(() => {
  const $div = document.createElement('div')
  $div.className = 'app'
  const rootEl = document.body.appendChild($div)
  ReactDOM.createRoot(rootEl).render(<Root store={store} api={api} />)

  store.dispatch(initializePostRenderingLogic())
  const isLoggedIn = getLoggedIn(store.getState())
  if (isLoggedIn) {
    const pageLifecycleTracker = new PageLifecycleTracker()
    logWebVitals(store.getState(), pageLifecycleTracker)
    setLoggingTasks(store.getState())
  }
})

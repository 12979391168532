import React from 'react'
import { RootAPI } from 'api'

import { makeCache } from 'utils/api/query/cache'
import { McClient, RequestCache } from 'utils/api/query/types'

export const McClientContext = React.createContext<McClient | null>(null)

const Provider = McClientContext.Provider

interface Props {
  api: RootAPI
  cache?: RequestCache
  children?: React.ReactNode
}

export const McClientContextProvider = ({ api, cache, children }: Props) => {
  const valueRef = React.useRef<McClient>()

  const getClient = () => {
    if (!valueRef.current) {
      const client = { cache: cache ?? makeCache(), api }
      valueRef.current = client
      // THIS IS FOR PIXI-OBJECT. PLZ DON'T TOUCH THIS!
      ;(window.app ?? {}).mcClient = client
    }
    return valueRef.current
  }

  return <Provider value={getClient()}>{children}</Provider>
}

export const useMcClient = () => {
  const clientContext = React.useContext(McClientContext)
  if (!clientContext) {
    throw new Error('Please provide Manychat client context')
  }
  return clientContext
}

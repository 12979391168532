import { v4 as uuid } from 'uuid'
import { l } from '@manychat/manyui'

import { NodeType } from 'common/builder/constants/NodeType'
import BaseNodeConfig from 'common/builder/nodes/BaseNodeConfig'
import { ICommentNode, INodeBase } from 'common/builder/nodes/nodeInterfaces'

import { ColorType, SizeType, FontSizeType } from './commentNodeConstants'

class CommentNodeConfig extends BaseNodeConfig<ICommentNode> {
  nodeType = NodeType.COMMENT

  canBeRoot = false

  getCaption = () => l.translate('Comment')
  getCaptionWithCount = (n: number) =>
    l.translate('{n, plural, one {# Comment} other {# Comments}}', { n })
  icon = 'Note'
  color = '#FFBD00'

  getDefaults = (): Omit<ICommentNode, keyof INodeBase> => ({
    noteId: uuid(),
    text: '',
    fontSize: FontSizeType.SMALL,
    nodeSize: SizeType.MEDIUM,
    color: ColorType.DEFAULT,
    timestamp: Date.now(),
  })

  isEmpty = ({ node }: { node: ICommentNode }) => {
    return !node.text
  }
}

export default new CommentNodeConfig()

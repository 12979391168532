import * as builderActions from 'common/builder/actions/builderActions'
import { openNode } from 'common/builder/actions/openNode'
import { updateBuilderState } from 'common/builder/actions/updateBuilderState'
import { getFirstNode } from 'common/builder/selectors/builder/builderStateSelectors'
import { getCGTOnboarding } from 'utils/services/newOnboardingService'
import OnboardingBase from 'utils/services/newOnboardingService/core/OnboardingBase'
import StepController from 'utils/services/newOnboardingService/core/StepController'
import StepSystem from 'utils/services/newOnboardingService/core/steps/StepSystem'
import { Store } from 'utils/services/newOnboardingService/interfaces'
import OnboardingId from 'utils/services/newOnboardingService/OnboardingId'

// eslint-disable-next-line @typescript-eslint/consistent-type-definitions
export type CommentsGrowthToolStepsMap = {
  STEP_1: StepSystem
  STEP_2: StepSystem
  STEP_3: StepSystem
  STEP_4: StepSystem
  STEP_5: StepSystem
  STEP_6: StepSystem
  STEP_7: StepSystem
  STEP_8: StepSystem
  STEP_9: StepSystem
  STEP_10: StepSystem
}

class CommentsGrowthTool extends OnboardingBase<CommentsGrowthToolStepsMap> {
  constructor(store: Store) {
    super(OnboardingId.CGT_FLOW_OVERVIEW_TOUR, () => {
      return new StepController<CommentsGrowthToolStepsMap>('STEP_1', {
        STEP_1: () =>
          new StepSystem(() => this.setStep('STEP_2'), {
            onRun: () => {
              const context = this.getContext()
              store.dispatch(builderActions.closeAllBuildersSidebars())
              store.dispatch(
                updateBuilderState(context?.builderId, {
                  sidebarHidden: false,
                  selectedNodes: {
                    startingStep: true,
                  },
                  selectedTrigger: {},
                  createTriggerInitial: {},
                  createTriggerMode: false,
                }),
              )
              getCGTOnboarding()?.steps.STEP_1?.process()
            },
          }),
        STEP_2: () => new StepSystem(() => this.setStep('STEP_3'), {}),
        STEP_3: () => new StepSystem(() => this.setStep('STEP_4'), {}),
        STEP_4: () => new StepSystem(() => this.setStep('STEP_5'), {}),
        STEP_5: () => new StepSystem(() => this.setStep('STEP_6'), {}),
        STEP_6: () => new StepSystem(() => this.setStep('STEP_7'), {}),
        STEP_7: () =>
          new StepSystem(() => this.setStep('STEP_8'), {
            onRun: () => {
              store.dispatch(builderActions.closeAllBuildersSidebars())
            },
          }),
        STEP_8: () =>
          new StepSystem(() => this.setStep('STEP_9'), {
            onRun: () => {
              const context = this.getContext()
              const builderId = context?.builderId
              if (!builderId) {
                return
              }

              const node = getFirstNode(store.getState(), builderId)

              if (!node) {
                return
              }

              store.dispatch(openNode(builderId, node.id))
              store.dispatch(
                updateBuilderState(builderId, {
                  sidebarHidden: false,
                }),
              )
            },
          }),
        STEP_9: () =>
          new StepSystem(() => this.setStep('STEP_10'), {
            onRun: () => {
              store.dispatch(builderActions.closeAllBuildersSidebars())
            },
          }),
        STEP_10: () =>
          new StepSystem(() => this.complete(), {
            onRun: () => {
              getCGTOnboarding()?.steps.STEP_10?.process()
            },
          }),
      })
    })
  }
}

export default CommentsGrowthTool

import { createContext, useContext } from 'react'

interface ToastContextProps {
  onClose: () => void
}

const ToastContext = createContext<ToastContextProps | undefined>(undefined)

export const useToast = (): ToastContextProps => {
  const context = useContext(ToastContext)
  if (!context) {
    throw new Error('useToast must be used within a ToastProvider')
  }
  return context
}

export default ToastContext

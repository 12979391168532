import { z } from 'zod'

import { BusinessErrorSchema } from 'shared/api/common/schemas/errors'

/**
 * This business error can be used to throw
 * a business error whenever a user cancels any transaction
 */
export class CancelError extends Error {
  public readonly field = 'user_cancel'

  public readonly message = 'User canceled'

  public readonly original_message = 'User canceled'
}

const CancelErrorSchema = BusinessErrorSchema.extend({
  field: z.literal('user_cancel'),
})

export const isCancelError = (error: unknown): error is CancelError =>
  CancelErrorSchema.safeParse(error).success

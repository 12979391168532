import { l } from '@manychat/manyui'

import {
  ACCOUNT_FAVORITE,
  ACCOUNT_FAVORITE_RESPONSE,
  ACCOUNT_HIDE,
  ACCOUNT_HIDE_RESPONSE,
} from 'apps/account/accountReduxActionTypes'
import { UserDisplayFlagType } from 'apps/dashboard/constants/DisplayFlagType'
import * as atypes from 'apps/profile/constants/profileReduxActionTypes'
import { getInactiveAccountIds } from 'apps/profile/selectors/profile'
import { alert } from 'common/core'
import { IAsyncThunkAction } from 'common/core/interfaces/actions'
import { handleCatch, handleError } from 'shared/api/lib/errors/handlers'
import { applyHandlerByGuard, reportUnhandledErrors } from 'shared/api/lib/errors/utils'
import { AccountApi } from 'shared/api/requests/account'
import { profileApi } from 'shared/api/requests/profile'
import { ProfileApiGuards } from 'shared/api/requests/profile/guards'
import { ProfileScopedApi } from 'shared/api/requests/profileScoped'

export const fetchPages = (): IAsyncThunkAction => {
  return async (dispatch, getState) => {
    dispatch({ type: atypes.PROFILE_PAGES_FETCH_REQUEST })

    try {
      const response = await profileApi.fetchPages()

      const state = getState()
      const inactiveAccountIds = getInactiveAccountIds(state)

      const pages = response.data.fb.accounts
      const defaultSortedPages = pages.sort((pageA, pageB) => {
        return (
          Number(inactiveAccountIds.includes(pageB.page_id)) -
          Number(inactiveAccountIds.includes(pageA.page_id))
        )
      })

      dispatch({ type: atypes.PROFILE_PAGES_FETCH_RESPONSE, pages: defaultSortedPages })
    } catch (error) {
      handleCatch(error)
    }
  }
}

export const setDisplayFlag = (
  displayFlag: UserDisplayFlagType,
  value: boolean,
): IAsyncThunkAction => {
  return async (dispatch) => {
    dispatch({
      type: atypes.PROFILE_SET_DISPLAY_FLAGS_REQUEST,
      display_flag: displayFlag,
      value,
    })

    try {
      await profileApi.setDisplayFlag({ body: { display_flag: displayFlag, value } })
    } catch (error) {
      handleCatch(error)
    }
  }
}

export const fetchInactiveAccounts = (): IAsyncThunkAction => {
  return async (dispatch) => {
    try {
      const response = await profileApi.fetchInactiveAccounts()
      dispatch({
        type: atypes.PROFILE_SET_INACTIVE_ACCOUNT_IDS,
        accountIds: response.data.account_ids,
      })
    } catch (error) {
      handleCatch(error)
      dispatch({
        type: atypes.PROFILE_SET_INACTIVE_ACCOUNT_IDS_ERROR,
      })
    }
  }
}

export const sendEmailVerification = (email: string): IAsyncThunkAction => {
  return async (dispatch) => {
    try {
      dispatch({ type: atypes.PROFILE_SEND_EMAIL_VERIFICATION_REQUEST })
      const response = await profileApi.sendEmailVerification({ body: { email } })
      dispatch({
        type: atypes.PROFILE_SEND_EMAIL_VERIFICATION_RESPONSE,
        data: response.data,
      })
      alert(l.translate('Verification email sent'), 'success')
      return true
    } catch (error) {
      dispatch({ type: atypes.PROFILE_SEND_EMAIL_VERIFICATION_ERROR })

      handleCatch(error, (responseError) => {
        let unhandledErrors = applyHandlerByGuard(
          responseError.$errors,
          ProfileApiGuards.sendEmailVerification.isEmailUsedError,
          (error) => alert(error.message, 'danger'),
        )

        unhandledErrors = applyHandlerByGuard(
          unhandledErrors,
          ProfileApiGuards.sendEmailVerification.isEmailInvalidError,
          (error) => alert(error.message, 'danger'),
        )

        unhandledErrors = applyHandlerByGuard(
          unhandledErrors,
          ProfileApiGuards.sendEmailVerification.isLimitExceededError,
          (error) => alert(error.message, 'danger'),
        )

        unhandledErrors = applyHandlerByGuard(
          unhandledErrors,
          ProfileApiGuards.sendEmailVerification.isEmailError,
          (error) => alert(error.message, 'danger'),
        )

        reportUnhandledErrors(unhandledErrors, responseError.endpoint)
      })

      return false
    }
  }
}

export const verifyEmailViaCode = (code: string): IAsyncThunkAction => {
  return async (dispatch) => {
    try {
      const response = await profileApi.verifyEmailViaCode({ body: { code } })
      dispatch({
        type: atypes.PROFILE_VERIFY_EMAIL_VIA_CODE_RESPONSE,
        data: response.data,
      })
      alert(l.translate('Thank you for verification!'), 'success')
      return true
    } catch (error) {
      handleCatch(error, (responseError) => {
        let unhandledErrors = applyHandlerByGuard(
          responseError.$errors,
          ProfileApiGuards.verifyEmailViaCode.isEmailVerifiedError,
          (error) => alert(error.message, 'danger'),
        )

        unhandledErrors = applyHandlerByGuard(
          unhandledErrors,
          ProfileApiGuards.verifyEmailViaCode.isLimitExceededError,
          (error) => alert(error.message, 'danger'),
        )

        unhandledErrors = applyHandlerByGuard(
          unhandledErrors,
          ProfileApiGuards.verifyEmailViaCode.isExpiredCodeError,
          (error) => alert(error.message, 'danger'),
        )

        unhandledErrors = applyHandlerByGuard(
          unhandledErrors,
          ProfileApiGuards.verifyEmailViaCode.isIncorrectCodeError,
          (error) => alert(error.message, 'danger'),
        )

        reportUnhandledErrors(unhandledErrors, responseError.endpoint)
      })

      return false
    }
  }
}

export const generateProfileApiToken = (): IAsyncThunkAction => {
  return async (dispatch) => {
    dispatch({
      type: atypes.PROFILE_GENERATE_API_TOKEN,
    })
    try {
      const response = await ProfileScopedApi.generate()
      return dispatch({
        type: atypes.PROFILE_GENERATE_API_TOKEN_RESPONSE,
        data: response.data,
      })
    } catch (error) {
      handleError(error)
      return dispatch({
        type: atypes.PROFILE_GENERATE_API_TOKEN_ERROR,
      })
    }
  }
}

export const deleteProfileApiToken = (): IAsyncThunkAction => {
  return async (dispatch) => {
    dispatch({
      type: atypes.PROFILE_DELETE_API_TOKEN,
    })
    try {
      await ProfileScopedApi.delete()
      return dispatch({
        type: atypes.PROFILE_DELETE_API_TOKEN_RESPONSE,
      })
    } catch (error) {
      handleError(error)
      return dispatch({
        type: atypes.PROFILE_DELETE_API_TOKEN_ERROR,
      })
    }
  }
}

export const makeAccountFavorite = (
  accountId: AccountId,
  isFavorite: boolean,
): IAsyncThunkAction => {
  return async (dispatch) => {
    dispatch({ type: ACCOUNT_FAVORITE })
    try {
      const response = await AccountApi.favorite({
        body: {
          account_id: accountId,
          is_favorite: isFavorite ? '1' : '0',
        },
      })
      dispatch({
        type: ACCOUNT_FAVORITE_RESPONSE,
        accountId,
        data: response.data,
      })
    } catch (error) {
      handleError(error, (businessError) => {
        if (AccountApi.favorite.isSetFavoriteError(businessError)) {
          businessError.handle()
          alert(businessError.message, 'danger')
        }
      })
    }
  }
}

export const makeAccountHidden = (accountId: AccountId, isHidden: boolean): IAsyncThunkAction => {
  return async (dispatch) => {
    dispatch({
      type: ACCOUNT_HIDE,
    })
    try {
      const response = await AccountApi.hide({
        body: {
          account_id: accountId,
          is_hidden: isHidden ? '1' : '0',
        },
      })

      dispatch({
        type: ACCOUNT_HIDE_RESPONSE,
        accountId,
        data: response.data,
      })
    } catch (error) {
      handleError(error, (businessError) => {
        if (AccountApi.hide.isSetHiddenError(businessError)) {
          businessError.handle()
          alert(businessError.message, 'danger')
        }
      })
    }
  }
}

import UserFieldsTypes from 'common/fields/constants/UserFieldsTypes'
import { makeLimitedListReducer, mergeToListItem } from 'utils/factory'
import { deleteFromList } from 'utils/factory/limitedList/reducers'

import * as atypes from '../constants/fieldsReduxActionTypes'

const fieldDefaults = {
  title: '',
  type: UserFieldsTypes.TEXT,
  description: '',
  folderPath: '/',
  allowedFieldTypes: [
    UserFieldsTypes.TEXT,
    UserFieldsTypes.NUMBER,
    UserFieldsTypes.DATE,
    UserFieldsTypes.DATETIME,
    UserFieldsTypes.BOOL,
    UserFieldsTypes.ARRAY,
  ],
}

const initialSearchValue = {
  query: '',
}

const listReducer = makeLimitedListReducer('userFields')

function newFieldModalReducer(state = { open: false, ...fieldDefaults }, action) {
  switch (action.type) {
    case atypes.USERFIELDS_OPEN_CREATE_MODAL: {
      const initialData = action.initialData || {}
      let allowedFieldTypes = initialData.allowedFieldTypes

      allowedFieldTypes =
        Array.isArray(allowedFieldTypes) && allowedFieldTypes.length > 0
          ? allowedFieldTypes
          : fieldDefaults.allowedFieldTypes

      return {
        ...state,
        open: true,
        builderId: initialData.builderId,
        title: fieldDefaults.title,
        type: initialData.type || allowedFieldTypes[0] || fieldDefaults.type,
        folderPath: initialData.folderPath || fieldDefaults.folderPath,
        allowedFieldTypes,
      }
    }
    case atypes.USERFIELDS_CLOSE_CREATE_MODAL:
      return { ...state, open: false, ...fieldDefaults }
    case atypes.USERFIELDS_CREATE_MODAL_CHANGE:
      return { ...state, ...action.data }
    case atypes.USERFIELDS_CREATE_PENDING:
      return { ...state, fetching: true }
    case atypes.USERFIELDS_CREATE_SUCCESS:
      return { ...state, fetching: false, open: false, ...fieldDefaults }
    case atypes.USERFIELDS_CREATE_REJECT:
      return { ...state, fetching: false }
  }
  return state
}

export default function userFieldsReducer(state, action) {
  state = listReducer(state, action)
  const createModal = newFieldModalReducer(state.createModal, action)
  if (createModal !== state.createModal) {
    state = { ...state, createModal }
  }

  const searchValue = userFieldSearchValueReducer(state.searchValue, action)
  if (!searchValue) {
    state = { ...state, searchValue: initialSearchValue }
  } else if (searchValue !== state.searchValue) {
    state = { ...state, searchValue }
  }

  switch (action.type) {
    case atypes.USERFIELDS_ARCHIVE_FIELD_NOTIFICATION: {
      return mergeToListItem(state, action.id, { status: 'archived' })
    }

    case atypes.USERFIELDS_RESTORE_FIELD_NOTIFICATION: {
      return mergeToListItem(state, action.id, { status: 'active' })
    }

    case atypes.USER_FIELDS_BULK_ARCHIVE_FIELDS_NOTIFICATION:
    case 'USERFIELDS_ARCHIVEBULK_RESPONSE': {
      const { models } = action.data
      models.forEach((model) => {
        state = mergeToListItem(state, model.field_id, { status: 'archived' })
      })
      return state
    }
    case atypes.USER_FIELDS_BULK_RESTORE_FIELDS_NOTIFICATION:
    case 'USERFIELDS_RESTOREBULK_RESPONSE': {
      const { models } = action.data
      models.forEach((model) => {
        state = mergeToListItem(state, model.field_id, { status: 'active' })
      })
      return state
    }
    case atypes.USER_FIELDS_BULK_DELETE_FIELDS_NOTIFICATION:
    case 'USERFIELDS_DELETEBULK_RESPONSE': {
      const { models } = action.data
      models.forEach((model) => {
        state = deleteFromList(state, model.field_id)
      })
      return state
    }
    case atypes.USER_FIELDS_FETCH:
    case atypes.USER_FIELDS_MOVE_TO_REQUEST: {
      return {
        ...state,
        fetching: true,
      }
    }
    case atypes.USER_FIELDS_MOVE_TO_RESPONSE: {
      return {
        ...state,
        fetching: false,
      }
    }
    case atypes.USER_FIELDS_MOVE_TO_ERROR: {
      return {
        ...state,
        fetching: false,
      }
    }
  }

  return state
}

function userFieldSearchValueReducer(state, action) {
  switch (action.type) {
    case atypes.USER_FIELDS_SEARCH_VALUE_CHANGE: {
      return { ...state, query: action.query }
    }
  }
  return state
}

export const setUserFieldsFetchRequest = () => ({
  type: atypes.USER_FIELDS_FETCH,
})

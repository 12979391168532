import dot from 'dot-prop-immutable'
import get from 'lodash/get'

import * as atypes from 'apps/templates/constants/ReduxActionTypes'
import { makeCurrentItemReducer } from 'utils/factory'

const currentItemReducer = makeCurrentItemReducer('templates')

export default function templatesReducer(state, action) {
  state = currentItemReducer(state, action)

  switch (action.type) {
    // IS_SHARED
    case 'TEMPLATES_CURRENT_SAVESHARE_REQUEST':
      state = dot.set(state, '$fetchingShare', true)
      break
    case 'TEMPLATES_CURRENT_SAVESHARE_RESPONSE':
      {
        const { is_shared, share_hash, share_link } = action.data.item
        state = dot.merge(state, 'item', { is_shared, share_hash, share_link })
        state = dot.set(state, '$fetchingShare', false)
      }
      break
    case 'TEMPLATES_CURRENT_SAVESHARE_ERROR':
      state = dot.set(state, '$fetchingShare', false)
      break
    // DESCRIPTION
    case 'TEMPLATES_CURRENT_SAVEDESCRIPTION_REQUEST':
      state = dot.set(state, '$fetchingDescription', true)
      break
    case 'TEMPLATES_CURRENT_SAVEDESCRIPTION_RESPONSE':
      state = dot.set(state, 'item.description', action.data.item.description)
      state = dot.set(state, '$fetchingDescription', false)
      break
    case 'TEMPLATES_CURRENT_SAVEDESCRIPTION_ERROR':
      state = dot.set(state, '$fetchingDescription', false)
      break
    // TITLE
    case 'TEMPLATES_CURRENT_SAVETITLE_REQUEST':
      state = dot.set(state, '$fetchingTitle', true)
      break
    case 'TEMPLATES_CURRENT_SAVETITLE_RESPONSE':
      state = dot.set(state, 'item.title', action.data.item.title)
      state = dot.set(state, '$fetchingTitle', false)
      break
    case 'TEMPLATES_CURRENT_SAVETITLE_ERROR':
      state = dot.set(state, '$fetchingTitle', false)
      break

    case atypes.TEMPLATES_NOTIFIACTIONS_UPDATE: {
      const {
        current_version, // eslint-disable-line no-unused-vars
        template_id,
        ...rest
      } = action.data

      if (template_id === get(state, 'item.template_id')) {
        rest.description = rest.description || state.item.description
        state = dot.merge(state, 'item', { ...rest })
      }

      break
    }

    case atypes.TEMPLATES_CURRENT_UPDATEAVATAR_RESPONSE:
      state = dot.merge(state, 'item.image', { avatar_url_100x100: action.avatarURL })
      break

    case atypes.TEMPLATE_FECTH_SINGLEUSE_LINK_REQUEST:
      state = dot.merge(state, 'item', { isGeneratingLink: true })
      break
    case atypes.TEMPLATE_FECTH_SINGLEUSE_LINK_RESPONSE:
      state = dot.merge(state, 'item', { ...action.data.data, isGeneratingLink: false })
      break
    case atypes.TEMPLATE_SET_SHARE_TYPE_RESPONSE:
      state = dot.merge(state, 'item', { ...action.data.item, isGeneratingLink: false })
      break
  }

  return state
}

import BaseButtonConfig from 'common/builder/buttons/BaseButtonConfig'
import { CrossChannelContentButton, IButtonBase } from 'common/builder/buttons/buttonInterfaces'
import { ButtonType } from 'common/builder/constants/ButtonType'

class CrossChannelContentButtonConfig extends BaseButtonConfig {
  buttonType = ButtonType.CROSS_CHANNEL_CONTENT

  getDefaults = (): Omit<CrossChannelContentButton, keyof IButtonBase> => ({
    targetId: null,
    channel: null,
  })
}

export default new CrossChannelContentButtonConfig()

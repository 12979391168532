export const WIDGETS_FETCH_RESPONSE = 'WIDGETS_FETCH_RESPONSE'
export const WIDGETS_CURRENT_FETCH_RESPONSE = 'WIDGETS_CURRENT_FETCH_RESPONSE'
export const WIDGETS_CURRENT_CREATE_RESPONSE = 'WIDGETS_CURRENT_CREATE_RESPONSE'
export const WIDGETS_UPDATE_STATUS = 'WIDGETS_UPDATE_STATUS'
export const WIDGETS_UPDATE_STATUS_REQUEST = 'WIDGETS_UPDATE_STATUS_REQUEST'
export const WIDGETS_UPDATE_STATUS_RESPONSE = 'WIDGETS_UPDATE_STATUS_RESPONSE'
export const WIDGETS_UPDATE_STATUS_ERROR = 'WIDGETS_UPDATE_STATUS_ERROR'
export const WIDGETS_FILTER_CHANGED = 'WIDGETS_FILTER_CHANGED'
export const WIDGETS_SEARCH_QUERY_CHANGED = 'WIDGETS_SEARCH_QUERY_CHANGED'
export const WIDGETS_SORTING_BY_CHANGED = 'WIDGETS_SORTING_BY_CHANGED'
export const WIDGETS_SORTING_ORDER_CHANGED = 'WIDGETS_SORTING_ORDER_CHANGED'
export const WIDGETS_CURRENT_SAVE_RESPONSE = 'WIDGETS_CURRENT_SAVE_RESPONSE'
export const WIDGETS_SET_SETUP_MODAL_VISIBILITY = 'WIDGETS_SET_SETUP_MODAL_VISIBILITY'
export const WIDGETS_DELETE_RESPONSE = 'WIDGETS_DELETE_RESPONSE'
export const WIDGETS_DELETED_NOTIFICATION = 'WIDGETS_DELETED_NOTIFICATION'
export const WIDGETS_CREATED_NOTIFICATION = 'WIDGETS_CREATED_NOTIFICATION'
export const WIDGETS_UPDATED_NOTIFICATION = 'WIDGETS_UPDATED_NOTIFICATION'

export const WIDGET_CHECKER_INIT = 'WIDGET_CHECKER_INIT'
export const WIDGET_CHECKER_FINISH = 'WIDGET_CHECKER_FINISH'
export const WIDGET_CHECKER_ERROR = 'WIDGET_CHECKER_ERROR'
export const WIDGET_CHECKER_CANCEL = 'WIDGET_CHECKER_CANCEL'
export const WIDGET_CHECK_PAGE_REQUEST = 'WIDGET_CHECK_PAGE_REQUEST'
export const WIDGET_CHECK_PAGE_DONE = 'WIDGET_CHECK_PAGE_DONE'

import React from 'react'

interface CheckboxMarkIconProps {
  className: string
}

export const CheckboxMarkIcon = ({ className }: CheckboxMarkIconProps) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      className={className}
    >
      <rect width="18" height="18" rx="4" stroke="none" />
      <path
        d="M3 9L7.0001 13.0008L15 5"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

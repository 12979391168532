import BaseBlockConfig from 'common/builder/blocks/BaseBlockConfig'
import { IBlockBase, IDynamicBlock } from 'common/builder/blocks/blockInterfaces'
import { BlockType } from 'common/builder/constants/BlockType'

class DynamicBlockConfig extends BaseBlockConfig {
  blockType = BlockType.DYNAMIC

  getDefaults = (): Omit<IDynamicBlock, keyof IBlockBase> => ({
    headers: {},
    method: 'post',
    payload: '',
    url: '',
  })
}

export default new DynamicBlockConfig()

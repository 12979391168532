import currencyData from './currencyData'

export const getCurrencyCode = (input: string | number): string | undefined => {
  const numberOrCode = String(input).padStart(3, '0')
  const number = parseInt(numberOrCode)
  if (isNaN(number)) {
    return numberOrCode
  }
  return currencyData.find((item) => item.number === numberOrCode)?.code
}

import { z } from 'zod'

const FlodeskMetaSchema = z.object({
  page: z.number(),
  total_pages: z.number(),
  per_page: z.number(),
  total_items: z.number(),
})

const FlodeskSegmentSchema = z.object({
  id: z.string(),
  name: z.string(),
  total_active_subscribers: z.number(),
  created_at: z.string(),
})

const FlodeskFieldSchema = z.object({
  key: z.string(),
  label: z.string(),
})

export const flodeskApiSchemas = {
  getSegments: {
    path: z.undefined(),
    query: z.undefined(),
    request: z.undefined(),
    response: z.object({
      meta: FlodeskMetaSchema,
      data: z.array(FlodeskSegmentSchema),
    }),
  },
  getCustomFields: {
    path: z.undefined(),
    query: z.undefined(),
    request: z.undefined(),
    response: z.object({
      meta: FlodeskMetaSchema,
      data: z.array(FlodeskFieldSchema),
    }),
  },
}

export type FlodeskMeta = z.infer<typeof FlodeskMetaSchema>
export type FlodeskSegment = z.infer<typeof FlodeskSegmentSchema>
export type FlodeskField = z.infer<typeof FlodeskFieldSchema>

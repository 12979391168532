import React, { MouseEvent } from 'react'
import cx from 'classnames'
import { Icon, l } from '@manychat/manyui'

import cm from './Chip.module.css'

export interface ChipProps {
  tooltip?: string
  className?: string
  fullWidth?: boolean
  multiline?: boolean
  interactive?: boolean
  onClick?(event?: MouseEvent<HTMLDivElement>): void
  onDelete?: VoidFunction
  deleteButtonTooltip?: string
  disabled?: boolean
  deletable?: boolean
  'data-test-id'?: string
  children: React.ReactNode
}

const Chip = ({
  'data-test-id': dataTestId = 'chip',
  children,
  tooltip,
  className,
  fullWidth,
  onClick,
  onDelete,
  deleteButtonTooltip,
  interactive,
  multiline,
  disabled,
  deletable,
}: ChipProps) => {
  const withDeleteButton = deletable && onDelete
  const isClickable = interactive && !disabled
  const preparedDeleteButtonTooltip = deleteButtonTooltip ?? l.translate('Delete')

  const onKeyDown = (event: React.KeyboardEvent<HTMLDivElement>) => {
    if (event.key === 'Enter' && onClick) {
      onClick()
    }
  }

  return (
    <div
      className={cx(cm.container, className, {
        [cm.fullWidth]: fullWidth,
        [cm.withDelete]: withDeleteButton,
        [cm.interactive]: isClickable,
        [cm.disabled]: disabled,
      })}
      onClick={isClickable ? onClick : undefined}
      onKeyDown={isClickable ? onKeyDown : undefined}
      data-title={tooltip}
      data-test-id={dataTestId}
      role={isClickable ? 'button' : 'none'}
      tabIndex={isClickable ? 0 : undefined}
    >
      <span className={cx(cm.wrapper, { [cm.multiline]: multiline })}>{children}</span>

      {withDeleteButton && (
        <button
          onClick={onDelete}
          className={cm.deleteButton}
          data-test-id={`${dataTestId}-delete-control`}
          data-title={preparedDeleteButtonTooltip}
          aria-label={preparedDeleteButtonTooltip}
        >
          <Icon.Close size={16} />
        </button>
      )}
    </div>
  )
}

const MemorizedChip = React.memo(Chip)

export { MemorizedChip as Chip }

import { api } from 'shared/api'
import { AiFlowGeneratorSchemas } from 'shared/api/requests/aiFlowGenerator/schemas'

export const AiFlowGeneratorApi = {
  startChat: api.account.createPost({
    url: '/aiFlowGenerator/startChat',
    schemas: AiFlowGeneratorSchemas.startChat,
  }),
  sendChatEvent: api.account.createPost({
    url: '/aiFlowGenerator/chatEvent',
    schemas: AiFlowGeneratorSchemas.sendChatEvent,
  }),
  getFlowDraft: api.account.createPost({
    url: '/aiFlowGenerator/getFlowDraft',
    schemas: AiFlowGeneratorSchemas.getFlowDraft,
  }),
  applyFlow: api.account.createPost({
    url: '/aiFlowGenerator/applyFlow',
    schemas: AiFlowGeneratorSchemas.applyFlow,
  }),
}

import {
  CardsBlockImageAspectRatioType,
  QuestionBlockReplyType,
} from 'common/builder/blocks/blockConstants'
import { BlockType } from 'common/builder/constants/BlockType'
import EntityType from 'common/builder/constants/EntityType'

export default {
  entity: EntityType.BLOCK,
  id: null,
  type: BlockType.TEXT,

  // text
  text: '',

  // attachment
  attachmentType: null,
  attachment: null,

  // extrnal_image
  image_url: null,

  // list
  topElementStyle: 'compact',

  // cards
  imageAspectRatio: CardsBlockImageAspectRatioType.HORIZONTAL,

  // card, list_item
  defaultAction: null,
  image: null,
  title: '',
  subtitle: '',

  buttons: [],
  // cards, list
  blocks: [],

  // form question
  replyType: QuestionBlockReplyType.FREE_TEXT,
  allowFreeInput: false,
  customFieldId: null,
  savePhoneToSystemField: true,
  saveEmailToSystemField: true,
  setSmsOptIn: true,
  setEmailOptIn: true,
  optInLegalText:
    'Please note that by typing in your phone number below you are agreeing to receive autodialed personal and marketing text messages to your mobile number.\n\nYour phone number must contain the country and city code and be in the following format: +12223334455. Consent is not required for purchase.',
  optInUnsubscribeText:
    'We’ll only text you useful information, special offers, news and alerts via text message.\n\nMessage and data rates may apply. Reply “STOP” by SMS to cancel.',
  validationErrorMessage: '',
  actions: [],
  skipButtonCaption: 'Skip',
  buttonCaption: '',

  // delay
  time: 3,
  showTyping: true,

  answerRetries: 4,
  questionAnswerTimeout: {
    value: 23,
    unit: 'hours',
  },

  // otn request block
  reasonId: null,

  stats: {
    clicks: 0,
    segmentId: '',
    users: 0,
    ctrDisabled: false,
  },

  settings: {},
}

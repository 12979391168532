import get from 'lodash/get'

import contentMapAdapter from 'apps/templates/models/ContentMap/adapter'

export function templateAdapter(item) {
  const { current_version } = item
  if (current_version && current_version.map) {
    const map = contentMapAdapter(current_version.map)
    current_version.map = map
    current_version.selection = map.items.reduce((res, item) => ((res[item.id] = true), res), {})
  }

  if (item?.flows_locked_status && !Array.isArray(item?.flows_locked_status)) {
    item.flowsLockedStatus = item.flows_locked_status
  }

  item.description = item.description || {
    summary: '',
    guideLink: '',
    videoGuideLink: '',
  }

  item.isProtected = item.is_protected || false
  item.isLocked = !item.is_editable || false
  item.isTracked = item.is_tracked || false

  return item
}

/**
 * @typedef {Object} InstalledTemplate
 * @property {number} installation_id
 * @property {number} template_id
 * @property {number} version_id
 * @property {string} title
 * @property {string} description
 * @property {string} author_name
 * @property {{ avatar_url_100x100: string, color: string }} template_avatar
 */

/**
 * @typedef {Object} InstalledTemplateSerialized
 * @property {number} installationId
 * @property {number} templateId
 * @property {number} versionId
 * @property {string} title
 * @property {string} description
 * @property {string} author
 * @property {{ url: string, color: string }} avatar
 */

/**
 * @param {InstalledTemplate} template
 * @returns {InstalledTemplateSerialized}
 */
export function installedTemplateAdapter(template) {
  if (template == null) {
    return template
  }

  const avatar = template.template_avatar
    ? { url: template.template_avatar.avatar_url_100x100, color: template.template_avatar.color }
    : { url: '', color: '' }

  const result = {
    installationId: template.installation_id,
    templateId: template.template_id,
    templateUrl: template.template_url,
    details: template.details,
    versionId: template.version_id,
    title: template.title,
    description: template.description || {
      summary: '',
      guideLink: '',
      videoGuideLink: '',
    },
    author: template.author_name,
    id: template.installation_id,
    avatar,
    authorUserdId: template.template_author_user_id,
    template_installation_access_data: template.template_installation_access_data,
  }

  // Fields Settings
  const idsMap = get(result, 'details.installation_settings.template_fields_installation_map', {})
  result.templateFields = (template.template_fields || []).map((f) => {
    const botFieldId = idsMap[f.template_field_id]
    return { ...f, field_id: botFieldId }
  })

  result.showWizard = result.templateFields.some((f) => f.wizard_enabled)

  if (template.map) {
    const map = contentMapAdapter(template.map)
    result.selection = map.items.reduce((res, item) => ((res[item.id] = true), res), {})
    result.map = map
  }

  return result
}

import { GTMAnalyticsEvents } from './types'

export class GTMAnalytics {
  private tagID = 'AW-795149170'

  sendConversationEvent(eventName: GTMAnalyticsEvents) {
    if (typeof window.gtag === 'function') {
      window.gtag('event', 'conversion', {
        send_to: `${this.tagID}/${eventName}`,
        value: 1.0,
        currency: 'USD',
      })
    }
  }
}

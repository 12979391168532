import { UserRoles, UserRolesNames } from 'common/userRoles/constants/UserRoles'

export const ROLES: UserRoles[] = [
  UserRoles.OWNER,
  UserRoles.ADMIN,
  UserRoles.EDITOR,
  UserRoles.AGENT,
  UserRoles.VIEWER,
]

export const RolesOptions = {
  [UserRoles.ADMIN]: {
    label: UserRolesNames[UserRoles.ADMIN],
    value: UserRoles.ADMIN,
  },
  [UserRoles.EDITOR]: {
    label: UserRolesNames[UserRoles.EDITOR],
    value: UserRoles.EDITOR,
  },
  [UserRoles.AGENT]: {
    label: UserRolesNames[UserRoles.AGENT],
    value: UserRoles.AGENT,
  },
  [UserRoles.VIEWER]: {
    label: UserRolesNames[UserRoles.VIEWER],
    value: UserRoles.VIEWER,
  },
}

import cloneDeep from 'lodash/cloneDeep'

import { ListMessageSection, ListMessageSectionOption } from 'apps/whatsApp/ListMessages/types'

export function getUpdatedListMessageSections(
  sections: ListMessageSection[],
  buttonId: string,
  changes: Partial<Omit<ListMessageSectionOption, 'type' | '_oid'>>,
) {
  const updatedSections = cloneDeep(sections)

  for (const section of updatedSections) {
    const option = section.options.find((option) => option._oid === buttonId)

    if (option !== undefined) {
      if (changes.description !== undefined) {
        option.description = changes.description
      }
      if (changes.caption !== undefined) {
        option.caption = changes.caption
      }
      if (changes._content_oid !== undefined) {
        option._content_oid = changes._content_oid
      }

      return updatedSections
    }
  }
  return null
}

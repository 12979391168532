import React from 'react'
import { generatePath, useNavigate } from 'react-router-dom'
import { useAppSelector } from 'reduxTyped'
import { Icon, l } from '@manychat/manyui'

import { setTitle } from 'common/core'
import { getUser } from 'common/core/selectors/appSelectors'
import { RoutePaths } from 'utils/router/constants'

import { MyProfilePopoverAnalytics } from '../../lib/analytics'
import {
  ICON_SIZE,
  ProfileSection,
  AdvancedSectionsConfig,
  ProfileSectionsConfig,
} from '../../lib/constants'

export const ProfileSections = ({ agencyId }: { agencyId: number | null }) => {
  const hasProAccounts = useAppSelector(getUser).has_pro_accounts

  const navigate = useNavigate()

  const advancedSections = AdvancedSectionsConfig.filter(({ id }) => {
    const hideAgencySection = id === 'agency' && !agencyId
    const hideApplicationSection = id === 'applications' && !hasProAccounts

    return hideAgencySection || hideApplicationSection ? false : true
  })

  const openSection = (route: string, label: () => string) => {
    const profileSectionLabel = l.getString(label)

    MyProfilePopoverAnalytics.logProfileSectionClick(profileSectionLabel)
    setTitle(profileSectionLabel)
    navigate(route)
  }

  const openAdvancedSection =
    ({ route, label }: ProfileSection) =>
    () => {
      const isAgencyRoute = route === RoutePaths.AGENCY_PROFILE
      const endRoute =
        isAgencyRoute && agencyId
          ? generatePath(RoutePaths.AGENCY_PROFILE, { agency_id: String(agencyId) })
          : route

      openSection(endRoute, label)
    }

  return (
    <>
      <div className="p-y-xs b-b">
        {ProfileSectionsConfig.map((section) => {
          const IconComponent = section.icon ? Icon[section.icon] : null

          return (
            <div
              key={section.id}
              className="d-flex p-y-sm p-x-md bg-hover c-pointer"
              onClick={() => openSection(section.route, section.label)}
            >
              {IconComponent && <IconComponent size={ICON_SIZE} className="flex-shrink-0 m-r-sm" />}
              <div>{l.getString(section.label)}</div>
            </div>
          )
        })}
      </div>
      <div className="p-y-xs">
        <div className="text-sm text-secondary p-y-xs p-x-md">
          {l.translate('Advanced features')}
        </div>
        {advancedSections.map((section) => {
          return (
            <div
              key={section.id}
              className="d-flex p-y-sm p-x-md bg-hover c-pointer text-sm"
              onClick={openAdvancedSection(section)}
            >
              <div>{l.getString(section.label)}</div>
            </div>
          )
        })}
      </div>
    </>
  )
}

import { l } from '@manychat/manyui'

import { NodeType } from 'common/builder/constants/NodeType'
import BaseNodeConfig from 'common/builder/nodes/BaseNodeConfig'
import { IGotoNode, INodeBase } from 'common/builder/nodes/nodeInterfaces'

class GotoNodeConfig extends BaseNodeConfig<IGotoNode> {
  nodeType = NodeType.GOTO

  canBeRoot = false

  getCaption = () => l.translate('Start Automation')
  getCaptionWithCount = (n: number) =>
    l.translate('{n, plural, one {# Start Automation} other {# Start Automations}}', { n })
  icon = 'GoToFlow'
  color = '#87B400'
  headerColor = '#ECFFB4'

  getDefaults = (): Omit<IGotoNode, keyof INodeBase> => ({ targetId: null, flowId: null })

  isEmpty = ({ node }: { node: IGotoNode }) => {
    return !node.flowId
  }
}

export default new GotoNodeConfig()

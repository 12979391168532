import React from 'react'
import cx from 'classnames'

import cm from './Loader.module.css'

export interface LoaderProps {
  /** If true, loader is positioned absolutely and centered */
  center?: boolean
  /** If true, display: "inline-block" is applied */
  inline?: boolean
  size?: number
  /** CSS class for <div> used as wrapper. */
  className?: string
  /** CSS styles for <div> used as wrapper. */
  style?: React.CSSProperties
  /** CSS classes for internal elements: wrapper, spinner. */
  theme?: {
    wrapper?: string
    spinner?: string
  }
}

/** Loader indicates some continuous process. Wherever you need to fetch data or perform action, loader helps user to understand that request is running. */
const Loader = ({
  size = 40,
  center,
  inline,
  className,
  theme = { wrapper: '', spinner: '' },
  ...props
}: LoaderProps) => {
  const style = props.style ? { ...props.style } : {}

  if (center) {
    style.position = 'absolute'
  }

  style.width = size
  style.height = size
  style.display = inline ? 'inline-block' : 'block'

  if (center) {
    style.top = '50%'
    style.marginTop = size / -2
    style.left = '50%'
    style.marginLeft = size / -2
  }

  return (
    <span className={cx(cm.spinner, className, theme.wrapper)} style={style}>
      <span className={cx('d-block', cm.doubleBounce1, theme.spinner)} />
      <span className={cx('d-block', cm.doubleBounce2, theme.spinner)} />
    </span>
  )
}

export default Loader

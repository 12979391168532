import React from 'react'
import cx from 'classnames'
import { Icon } from '@manychat/manyui'

import OptionIcon from './OptionIcon'
import { ParentOption } from './types'

import cm from './OptionParent.module.css'

const OptionParent = ({
  index,
  option,
  onClick,
  isHidden,
}: {
  index: number
  option?: ParentOption
  onClick?: (option: ParentOption) => void
  isHidden?: boolean
}) => {
  const optionParentRef = React.useRef<HTMLDivElement>(null)

  if (!option || option.localIndex !== 0 || !option.parent) {
    return null
  }

  const { parent: groupOption } = option

  const isRootLevel = !groupOption.parent

  const title = typeof groupOption.label === 'string' ? groupOption.label : ''

  const handleClick = () => {
    onClick?.(groupOption)

    const element = optionParentRef.current
    if (typeof element?.scrollIntoView === 'function') {
      setTimeout(() => element.scrollIntoView({ block: 'center', behavior: 'smooth' }), 0)
    }
  }
  return (
    <>
      <OptionParent index={index} option={groupOption} />
      {!isRootLevel && (
        <div
          ref={optionParentRef}
          className="text-h-primary justify-between p-y-xs p-x text-secondary text-sm c-pointer d-flex align-center bg-white"
          onClick={handleClick}
          title={title}
        >
          <div className="d-flex align-center flex-shrink-0">
            <OptionIcon option={groupOption} />
            <span className={cx('text-ellipsis', cm.label)}>{groupOption.label}</span>
          </div>
          {isHidden ? (
            <Icon.ChevronDown className="flex-shrink-0" />
          ) : (
            <Icon.ChevronUp className="flex-shrink-0" />
          )}
        </div>
      )}
    </>
  )
}

export default OptionParent

import React from 'react'
import cx from 'classnames'

import { CircleAvatar } from 'components/CircleAvatar'
import { PhonePreviewTheme } from 'components/PhonePreviewKit'
import { useThemeContext } from 'components/PhonePreviewKit/useThemeContext'

import emptyAvatarSvg from '../../svg/emptyAvatar.svg'

import { PhonePreviewHeaderProps } from './interface'
import backSvg from './svg/back.svg'
import dmAudioCall from './svg/dm_audio_call.svg'
import dmVideoCall from './svg/dm_video_call.svg'
import dotsSvg from './svg/dots.svg'
import flagSvg from './svg/flag.svg'

import cm from './PhonePreviewHeader.module.css'

export function PhonePreviewHeader({ title, avatarUrl }: PhonePreviewHeaderProps) {
  const theme = useThemeContext()

  return (
    <div className={cx(cm.header, cm.tiktokHeader)} data-test-id="phone-preview-header">
      <div className={cm.leftPart}>
        <img className={cm.backIcon} src={backSvg} alt="back" />
        <div className={cm.accountInfo}>
          {avatarUrl ? (
            <CircleAvatar url={avatarUrl} size={29} />
          ) : (
            <img className={cm.accountAvatar} src={emptyAvatarSvg} alt="avatar" />
          )}
          {title ? <div className={cm.accountName}>{title}</div> : null}
        </div>
      </div>
      <div className={cm.headerActions}>
        {theme === PhonePreviewTheme.TIKTOK && (
          <>
            <img src={flagSvg} alt="flag" />
            <img src={dotsSvg} alt="dots" />
          </>
        )}
        {theme === PhonePreviewTheme.INSTAGRAM && (
          <>
            <img src={dmAudioCall} width="17" height="17" alt="audio call" />
            <img src={dmVideoCall} width="17" height="17" alt="video call" />
          </>
        )}
      </div>
    </div>
  )
}

PhonePreviewHeader.displayName = 'PhonePreview.Header'

import compact from 'lodash/compact'
import every from 'lodash/every'
import get from 'lodash/get'
import isFinite from 'lodash/isFinite'
import omit from 'lodash/omit'

import {
  TAG_OPEN_ENCRYPT,
  TAG_CLOSE_ENCRYPT,
  AMP_ENCRYPT,
  REGEXP_TAG_OPEN,
  REGEXP_TAG_CLOSE,
} from 'apps/email/emailConstants'
import { AttachmentBlockAttachmentType } from 'common/builder/blocks/blockConstants'
import { isUrlButton } from 'common/builder/buttons/buttonTypeHelpers'
import Adapters from 'common/builder/constants/Adapters'
import BackendButtonType from 'common/builder/constants/BackendButtonType'
import BackendContentType from 'common/builder/constants/BackendContentType'
import BackendMessageType from 'common/builder/constants/BackendMessageType'
import BackendPreFillFieldType from 'common/builder/constants/BackendPreFillFieldType'
import { BlockType } from 'common/builder/constants/BlockType'
import { ButtonType } from 'common/builder/constants/ButtonType'
import { NodeType } from 'common/builder/constants/NodeType'
import { isVariable } from 'common/builder/constants/PhoneInputType'
import { TriggerOnOpenUse } from 'common/builder/emailBuilder/emailBuilderConstants'
import { exportQuestionReplyType } from 'common/builder/models/Block/adapters'
import {
  shouldSaveFirstNameToSystemField,
  shouldSaveLastNameToSystemField,
  shouldSavePhoneToSystemField,
  shouldSaveEmailToSystemField,
  shouldSetSMSOptIn,
  shouldSetEmailOptIn,
  shouldSavePhoneToWhatsAppIdSystemField,
} from 'common/builder/models/Block/helpers'
import { isValidSmartLinkButton } from 'common/builder/models/Button/helpers'
import nodeRegistry from 'common/builder/nodeRegistry'
import { ContentType } from 'common/builder/nodes/content/contentNodeConstants'
import { DelayType } from 'common/builder/nodes/smartDelay/delayTypes'
import { containsMcTags } from 'components/MCTextEditor/formatting/adapter'
import { containsSmartLinks } from 'components/MCTextEditor/smartLinks/adapter'
import errorTrackingService from 'utils/services/errorTrackingService'
import { accountFlags } from 'utils/services/featureFlagsService'
import { AccountFlag } from 'utils/services/featureFlagsService/constants'

import BaseExporter from './base/BaseExporter'
import { exportQuickRepliesSettings } from './parseQuickRepliesSettings'

export default class Exporter extends BaseExporter {
  constructor(parsed, userId, options = {}) {
    super(parsed)
    this.targetsMap = {}
    this.options = options
    this.userId = userId
  }

  process = () => {
    try {
      this.batch.contents = this.parsed.nodes
        .filter((node) => !nodeRegistry.get(node).isStartingStep)
        .map(this.processNode)
    } catch (err) {
      err.fingerprint = 'batch-exporter-process'
      throw err
    }

    // PATCH FOR BASIC BUILDER: drop unattached action groups
    if (this.options.dropUnattachedActions) {
      this.batch.contents = this.batch.contents.filter((c) => {
        if (c.type !== BackendContentType.ACTION_GROUP) {
          return true
        }

        return this.targetsMap[c._oid] || this.targetsMap[c.content_id]
      })
    }

    return this.batch
  }

  processNode = (node) => {
    if (node.nodeType === NodeType.CONTENT) {
      return this.processContent(node)
    }
    if (node.nodeType === NodeType.ACTION_GROUP) {
      return this.processActionGroup(node)
    }
    if (node.nodeType === NodeType.GOTO) {
      return this.processGoto(node)
    }
    if (node.nodeType === NodeType.COMMENT) {
      return this.processComment(node)
    }
    if (node.nodeType === NodeType.CONDITION) {
      return this.processMultiCondition(node)
    }
    if (node.nodeType === NodeType.SPLIT) {
      return this.processSplit(node)
    }
    if (node.nodeType === NodeType.SMART_DELAY) {
      return this.processSmartDelay(node)
    }
    if (node.nodeType === NodeType.SMS) {
      return this.processSms(node)
    }
    if (node.nodeType === NodeType.EMAIL_NEW) {
      return this.processEmailNew(node)
    }
    if (node.nodeType === NodeType.WHATS_APP) {
      return this.processWhatsApp(node)
    }
    if (node.nodeType === NodeType.INSTAGRAM) {
      return this.processInstagram(node)
    }
    if (node.nodeType === NodeType.TELEGRAM) {
      return this.processTelegram(node)
    }
    if (node.nodeType === NodeType.TIKTOK) {
      return this.processTiktok(node)
    }
    if (node.nodeType === NodeType.AI_AGENT) {
      return this.processAiAgent(node)
    }
  }

  /* Content */

  processContent = (content) => {
    if (content.type === ContentType.DEFAULT) {
      return this.processDefaultContent(content)
    }
  }

  processDefaultContent = (content) => {
    const result = this.processContentGeneral(content)
    result.type = BackendContentType.DEFAULT

    // process qr blocks
    const qrBlock = get(content, 'blocks', [])
      .map(this.findBlock)
      .find((block) => block.type === BlockType.QUICK_REPLY)

    const buttonIds = get(qrBlock, 'buttons', [])
    const buttons = buttonIds
      .map(this.findButton)
      .filter((button) => !button.$draft)
      .map((button) => this.processButton(button, qrBlock))

    const qrSettings = exportQuickRepliesSettings(get(qrBlock, 'settings', {}))

    const quickReplyHasTimeoutTarget = Boolean(qrBlock?.settings?.timeout)

    if (quickReplyHasTimeoutTarget) {
      qrSettings.timeout_target = this._processTargetId(qrBlock.targetId)
    }

    result.quick_replies = { buttons, settings: qrSettings }
    result.target = quickReplyHasTimeoutTarget ? null : this._processTargetId(content.targetId)

    // process ordinary blocks
    const ordinaryBlocks = get(content, 'blocks', [])
      .map(this.findBlock)
      .filter((block) => block.type !== BlockType.QUICK_REPLY)

    result.messages = ordinaryBlocks.map(this.processBlock)

    return result
  }

  processContentGeneral = (content) => {
    return {
      _oid: content.id || null,
      namespace: content.flow || '',
      caption: content.caption || '',
      content_id: content.contentId || null,
      removed: content.deleted || false,
      message_tag: content.messageTag || null,
      one_time_notify_reason_id: content.reasonId || null,
      private_reply: content.privateReply || null,
      $fbMessagingType: content.$fbMessagingType,
    }
  }

  /* Blocks */

  processBlock = (block, options = {}) => {
    const general = { _oid: block.id, type: block.type }
    const specific = this.processBlockSpecific(block, { nodeType: options?.nodeType })
    const result = Object.assign({}, general, specific)

    // process buttons
    const buttonsAttr = block.type === BlockType.FORM_QUESTION ? 'answer_replies' : 'keyboard'
    const buttonIds = get(block, 'buttons', [])

    const buttons = buttonIds
      .map(this.findButton)
      .filter((button) => !button.isSmartLink || isValidSmartLinkButton(block, button))
    result[buttonsAttr] = buttons.map((button) => this.processButton(button, block))

    // process blocks
    if (block.type === BlockType.LIST || block.type === BlockType.CARDS) {
      const blockIds = get(block, 'blocks', [])
      result.elements = blockIds.map(this.findBlock).map(this.processBlock)
    }

    if (block.type === BlockType.EMAIL_ROOT) {
      const blockIds = get(block, 'blocks', [])
      result.blocks = blockIds.map(this.findBlock).map(this.processBlock)
    }

    if (block.type === BlockType.FORM_QUESTION && options.nodeType === NodeType.TIKTOK) {
      result.skip_button_caption = ''
    }

    if (block.type === BlockType.WA_LIST_MESSAGE) {
      const sections = get(block, 'sections', [])
      const content = get(block, 'content', {})
      if (content?.header?.text?.trim() === '') {
        content.header = undefined
      }
      if (content?.footer?.trim() === '') {
        content.footer = undefined
      }
      result.keyboard = undefined
      result.sections = sections.map((section) => ({
        ...section,
        options: section.options.map((option) => ({
          ...option,
          description: option.description?.trim() === '' ? undefined : option.description,
        })),
      }))
      result.content = content
    }

    return result
  }

  processBlockSpecific = (block, options = {}) => {
    const result = {}

    switch (block.type) {
      case BlockType.TEXT:
        result.content = { text: block.text }
        if (options.nodeType === NodeType.TELEGRAM) {
          if (containsMcTags(block.text) || containsSmartLinks(block.text)) {
            result.content.text_type = 'MC_HTML'
          }
        }
        break
      case BlockType.ATTACHMENT:
        if (block.attachment?.type === AttachmentBlockAttachmentType.EXTERNAL_IMAGE) {
          result.content = {
            type: AttachmentBlockAttachmentType.EXTERNAL_IMAGE,
            data: {
              url: block.attachment?.image_url,
            },
          }
          break
        }

        result.content = { type: block.attachmentType, data: null }

        if (block.attachment && !block.attachment.uploadId) {
          result.content.data = block.attachment

          if ('telegramVideoType' in block) {
            result.content.data.telegram_video_type = block.telegramVideoType
          }
        }
        break
      case BlockType.LIST:
        result.top_element_style = block.topElementStyle
        break
      case BlockType.CARD:
      case BlockType.LIST_ITEM:
        result.content = { title: block.title, subtitle: block.subtitle, image: null }

        if (block.image && !block.image.uploadId) {
          result.content.image = block.image
        }
        if (block.image?.type === AttachmentBlockAttachmentType.EXTERNAL_IMAGE) {
          result.content.image = {
            type: block.image.type,
            url: block.image.image_url,
          }
        }
        if (
          block.defaultAction &&
          (block.defaultAction.type !== 'url' || block.defaultAction.url)
        ) {
          result.default_action = block.defaultAction
        } else {
          result.default_action = null
        }

        if (block.type === BlockType.CARD) {
          result.is_hidden = Boolean(block.isCardHidden)
        }

        break
      case BlockType.CARDS:
        result.image_aspect_ratio = block.imageAspectRatio
        break
      case BlockType.FORM_QUESTION:
        {
          result.content = { text: block.text }

          const [answer_type, answer_method] = exportQuestionReplyType(
            block.replyType,
            block.allowFreeInput,
          )

          result.answer_type = answer_type
          result.answer_method = answer_method

          result.adapters = []

          if (shouldSaveFirstNameToSystemField(block)) {
            result.adapters.push({
              type: Adapters.SAVE_FIRST_NAME_TO_SYSTEM_FIELD,
            })
          }

          if (shouldSaveLastNameToSystemField(block)) {
            result.adapters.push({
              type: Adapters.SAVE_LAST_NAME_TO_SYSTEM_FIELD,
            })
          }

          if (shouldSavePhoneToSystemField(block)) {
            result.adapters.push({ type: Adapters.SAVE_PHONE_TO_SYSTEM_FIELD })
          }

          if (shouldSavePhoneToWhatsAppIdSystemField(block)) {
            result.adapters.push({ type: Adapters.SAVE_PHONE_TO_WA_ID_SYSTEM_FIELD })
          }

          if (shouldSetSMSOptIn(block)) {
            result.adapters.push({ type: Adapters.SET_SMS_OPTIN })
          }

          if (shouldSaveEmailToSystemField(block)) {
            result.adapters.push({ type: Adapters.SAVE_EMAIL_TO_SYSTEM_FIELD })
          }

          if (shouldSetEmailOptIn(block)) {
            result.adapters.push({ type: Adapters.SET_EMAIL_OPTIN })
          }

          if (
            !shouldSavePhoneToSystemField(block) &&
            !shouldSaveEmailToSystemField(block) &&
            block.customFieldId
          ) {
            result.adapters.push({
              type: Adapters.SAVE_ANSWER_TO_CUSTOM_FIELD,
              field_id: block.customFieldId,
            })
          }

          result.success_target = this._processTargetId(block.targetId)
          result.validation_message = block.validationErrorMessage
          result.skip_button_caption = block.skipButtonCaption
          result.telegram_share_phone_button_caption = block.telegramSharePhoneButtonCaption
          result.button_caption = block.buttonCaption

          result.limit_failed = block.answerRetries
          result.question_answer_timeout = block.questionAnswerTimeout

          const [timeoutBlock] = get(block, 'blocks', []).map(this.findBlock)
          if (timeoutBlock) {
            result.timeout_target = this._processTargetId(timeoutBlock.targetId)
          }
        }
        break
      case BlockType.DELAY:
        result.time = parseInt(block.time)
        result.show_typing = block.showTyping
        break
      case BlockType.VARIANT:
        result.data = { color: block.color, title: block.title }
        result.percent = block.percent
        result.target = this._processTargetId(block.targetId)
        break
      case BlockType.DYNAMIC:
        if (block.buttons.length > 0) {
          const button = this.findButton(block.buttons[0])
          result.fallback = this._processTargetId(button.targetId)
        }
        result.method = block.method
        result.url = block.url
        result.payload = block.payload
        result.headers = block.headers
        break
      case BlockType.ONE_TIME_NOTIFY_REQUEST:
        result.content = {
          one_time_notify_reason_id: block.reasonId,
          text: block.title,
          image: null,
        }

        if (block.image && !block.image.uploadId) {
          result.content.image = block.image
        }
        if (block.image?.type === AttachmentBlockAttachmentType.EXTERNAL_IMAGE) {
          result.content.image = {
            type: block.image.type,
            url: block.image.image_url,
          }
        }
        break

      case BlockType.EMAIL_ROOT:
        result.settings = block.settings
        break
      case BlockType.EMAIL_TEXT:
        result.content = { text: this.exportRichText(block.text) }
        result.settings = block.settings
        break
      case BlockType.EMAIL_IMAGE:
        if (block.image && !block.image.uploadId) {
          result.content = { type: block.image.type, data: block.image }
        }
        if (block.image?.type === AttachmentBlockAttachmentType.EXTERNAL_IMAGE) {
          result.content = {
            type: block.image.type,
            data: {
              type: block.image.type,
              url: block.image.image_url,
            },
          }
        }
        result.settings = block.settings
        break
      case BlockType.EMAIL_BUTTON:
        result.content = { text: block.text }
        result.settings = block.settings
        break
      case BlockType.EMAIL_DIVIDER:
        result.settings = block.settings
        break
      case BlockType.MESSAGE_TEMPLATE:
        result.content = { name: block.name, language: block?.language }
        break
      case BlockType.WA_CATALOG_MESSAGE:
        result.content = { body: block.content.body }
        if (block.content?.footer && block.content.footer.trim() !== '') {
          result.content.footer = block.content.footer
        }
        break
    }

    // unnecessary cleanup - only for smaller batch
    if (result.content) {
      for (let key of Object.keys(result.content)) {
        if (typeof result.content[key] === 'undefined') {
          delete result.content[key]
        }
      }
    }

    return result
  }

  /* Buttons */
  processButton = (button, block = {}) => {
    let result = {
      _oid: button.id,
      type: button.type,
      caption: button.caption,
      url: button.url,
      emoji: button.emoji,
      image: button.image,
      phone: button.phone,
      is_formula: button.is_formula,
      is_fallback: button.isFallback,
      channel: button.channel,
      is_smart_link: Boolean(button.isSmartLink),
    }

    if (button.type === ButtonType.ANSWER) {
      result.value = button.customValue ? button.value : button.caption
    }

    if (button.type === ButtonType.CALL) {
      result.phone = isVariable(button.phone) ? `{{${button.phone}}}` : button.phone
    }

    if (button.type === ButtonType.BUY) {
      const priceFloat = parseFloat(button.item_price)
      const cost = isFinite(priceFloat) ? Math.round(priceFloat * 100) : null
      result.item = { formula: button.item_price, label: button.item_label, cost }
      result.user_data = {
        shipping_address: button.shipping_address,
        contact_name: button.contact_name,
        contact_phone: button.contact_phone,
        contact_email: button.contact_email,
      }
      result.prefill_data = []
      if (button.email_field_id) {
        result.prefill_data.push({
          form_field: BackendPreFillFieldType.EMAIL,
          field_id: button.email_field_id,
        })
      }
      result.success_target = this._processTargetId(button.targetId)
      result.filter = button.filter || null
    }

    if ([ButtonType.CONTENT, ButtonType.CROSS_CHANNEL_CONTENT].includes(button.type)) {
      const targetData = this._processTargetId(button.targetId)
      result = Object.assign(result, targetData)
    }

    if (isUrlButton(button)) {
      result.target = this._processTargetId(button.targetId)
      result.webview_size = button.webviewSize || null
      result.do_not_track = Boolean(button.enableWebview)
    }

    if (block.type === BlockType.ONE_TIME_NOTIFY_REQUEST) {
      result.type = BackendButtonType.OTN_NOTIFY_ME
    }

    if (button.flowId) {
      result.flow_ns = button.flowId
    }

    // unnecessary cleanup - only for smaller batch
    for (let key of Object.keys(result)) {
      if (typeof result[key] === 'undefined') {
        delete result[key]
      }
    }

    return result
  }

  /* Action group */

  processActionGroup = (actionGroup) => {
    const hasNullActions = !every(actionGroup.items, Boolean)
    if (hasNullActions) {
      errorTrackingService.trackError('null actions found during action group export', {
        extra: { actionGroup },
        fingerprint: 'null-actions-when-export',
      })
    }

    return {
      type: BackendContentType.ACTION_GROUP,
      _oid: actionGroup.id || null,
      namespace: actionGroup.flow || '',
      caption: actionGroup.caption || '',
      content_id: actionGroup.contentId || null,
      target: this._processTargetId(actionGroup.targetId),
      actions: compact(actionGroup.items || []),
      removed: actionGroup.deleted || false,
    }
  }

  /* Goto */

  processGoto = (goto) => {
    return {
      type: BackendContentType.GOTO,
      _oid: goto.id || null,
      namespace: goto.flow || '',
      caption: goto.caption || '',
      content_id: goto.contentId || null,
      removed: goto.deleted || false,
      target: { flow_ns: goto.flowId || null },
      content_target: this._processTargetId(goto.targetId),
    }
  }

  /* Comment */

  processComment = (comment) => {
    return {
      type: BackendContentType.NOTE,
      _oid: comment.id || null,
      namespace: comment.flow || '',
      caption: 'note',
      content_id: comment.contentId || null,
      removed: comment.deleted || false,
      note: {
        _oid: comment.noteId || null,
        user_id: this.userId,
        timestamp: comment.timestamp,
        text: comment.text || '',
        color: comment.color,
        font_size: comment.fontSize,
        note_size: comment.nodeSize,
      },
    }
  }

  /* MultiCondition */

  processMultiCondition = (condition) => {
    const blocks = get(condition, 'blocks', []).map(this.findBlock)

    const conditions = blocks
      .filter((block) => block.type === BlockType.CASE)
      .map((block) => ({
        filter: block.filter,
        target: this._processTargetId(block.targetId),
        _oid: block.id,
      }))

    const defaultOption = blocks.find((block) => block.type === BlockType.DEFAULT)
    return {
      type: BackendContentType.MULTI_CONDITION,
      _oid: condition.id || null,
      namespace: condition.flow || '',
      caption: condition.caption || '',
      content_id: condition.contentId || null,
      removed: condition.deleted || false,
      conditions,
      default_target: this._processTargetId(get(defaultOption, 'targetId')),
      default_target_oid: defaultOption ? defaultOption.id : null,
    }
  }

  /* Split */

  processSplit = (split) => {
    const blocks = (split.blocks || []).map(this.findBlock)

    const result = {
      type: BackendContentType.SPLIT,
      _oid: split.id || null,
      namespace: split.flow || '',
      caption: split.caption || '',
      content_id: split.contentId || null,
      removed: split.deleted || false,
      randomized: split.randomized || false,
    }

    result.variants = blocks.map(this.processBlock)
    return result
  }

  /* Smart Delay */

  processSmartDelay = (smartDelay) => {
    const [from, to] = smartDelay.sendingInterval || []

    const result = {}

    switch (smartDelay.delayType) {
      case DelayType.WAIT_UNTIL: {
        result.wait_until = smartDelay.waitUntil
        result.shift_time = undefined
        break
      }
      case DelayType.DURATION: {
        result.shift_time = {
          unit: smartDelay.delayUnits,
          value: smartDelay.delayValue,
        }
        result.wait_until = undefined
      }
    }

    return {
      type: BackendContentType.SMART_DELAY,
      _oid: smartDelay.id || null,
      namespace: smartDelay.flow || '',
      caption: smartDelay.caption || '',
      content_id: smartDelay.contentId || null,
      removed: smartDelay.deleted || false,
      target: this._processTargetId(smartDelay.targetId),
      limit_time: !smartDelay.useTimeWindow
        ? null
        : {
            from: { minutes: 0, hours: from },
            to: { minutes: to === 0 ? 59 : 0, hours: to === 0 ? 23 : to },
            weekdays: smartDelay.weekdays,
          },

      ...result,
    }
  }

  /* SMS */

  processSms = (item) => {
    const blocks = (item.blocks || []).map(this.findBlock)
    const qrBlock = blocks.find((block) => block.type === BlockType.QUICK_REPLY)
    const ordinaryBlocks = blocks.filter((block) => block.type !== BlockType.QUICK_REPLY)

    const buttonIds = get(qrBlock, 'buttons', [])
    const buttons = buttonIds
      .map(this.findButton)
      .filter((button) => !button.$draft)
      .filter((button) => !(button.isFallback && !button.targetId))
      .map((button) => this.processButton(button, qrBlock))

    const messages = ordinaryBlocks.map(this.processBlock)

    return {
      type: BackendContentType.SMS,
      _oid: item.id || null,
      namespace: item.flow || '',
      quick_replies: { buttons },
      caption: item.caption || '',
      content_id: item.contentId || null,
      removed: item.deleted || false,
      target: this._processTargetId(item.targetId),
      messages,
    }
  }

  processSmsAttachment = (block) => {
    const data = omit(block.attachment, 'type')

    const attachData = {
      type: 'image',
      data: Object.keys(data).length ? data : null,
    }

    if (block.attachment?.type === AttachmentBlockAttachmentType.EXTERNAL_IMAGE) {
      attachData.type = AttachmentBlockAttachmentType.EXTERNAL_IMAGE
      attachData.data = {
        url: block.attachment.image_url,
      }
    }
    return {
      _oid: block.id,
      type: BackendMessageType.ATTACHMENTS,
      content: {
        attachments: [attachData],
      },
    }
  }

  /* EMAIL */

  processEmailNew = (item) => {
    const blocks = (item.blocks || []).map(this.findBlock)
    const exportBlocks = blocks
      .filter((block) => block.type !== BlockType.EMAIL_TRIGGER_OPEN)
      .map(this.processBlock)

    const { preheader, subject, senderName, background_color, businessEmailId } = item.data
    const settings = {
      preheader,
      subject,
      sender_name: senderName,
      business_email_id: businessEmailId || null,
      background_color,
    }

    const trigger_on_open = { target: null, settings: { use: TriggerOnOpenUse.FIRST } }

    const triggerOpenBlock = blocks.find((block) => block.type === BlockType.EMAIL_TRIGGER_OPEN)
    if (triggerOpenBlock) {
      trigger_on_open.target = this._processTargetId(triggerOpenBlock.targetId)
      trigger_on_open.settings.use = triggerOpenBlock.triggerOnOpenUse
    }

    return {
      type: BackendContentType.EMAIL_NEW,
      _oid: item.id || null,
      namespace: item.flow || '',
      caption: item.caption || '',
      content_id: item.contentId || null,
      removed: item.deleted || false,
      target: this._processTargetId(item.targetId),
      blocks: exportBlocks,
      settings,
      trigger_on_open,
    }
  }

  /* WHATS APP */

  processWhatsApp = (item) => {
    const blocks = (item.blocks || []).map(this.findBlock)
    const qrBlock = blocks.find((block) => block.type === BlockType.QUICK_REPLY)
    const ordinaryBlocks = blocks.filter((block) => block.type !== BlockType.QUICK_REPLY)

    const buttonIds = get(qrBlock, 'buttons', [])
    const buttons = buttonIds
      .map(this.findButton)
      .filter((button) => !button.$draft)
      .filter((button) => !(button.isFallback && !button.targetId))
      .map((button) => this.processButton(button, qrBlock))

    const messages = ordinaryBlocks.map(this.processBlock)

    return {
      type: BackendContentType.WHATS_APP,
      _oid: item.id || null,
      namespace: item.flow || '',
      quick_replies: { buttons },
      caption: item.caption || '',
      content_id: item.contentId || null,
      removed: item.deleted || false,
      target: this._processTargetId(item.targetId),
      whatsapp_node_type: item.whatsAppNodeType,
      messages,
    }
  }

  /* Instagram */

  processInstagram = (item) => {
    const blocks = (item.blocks || []).map(this.findBlock)
    const qrBlock = blocks.find((block) => block.type === BlockType.QUICK_REPLY)
    const ordinaryBlocks = blocks.filter((block) => block.type !== BlockType.QUICK_REPLY)

    const buttonIds = get(qrBlock, 'buttons', [])
    const buttons = buttonIds
      .map(this.findButton)
      .filter((button) => !button.$draft)
      .filter((button) => !(button.isFallback && !button.targetId))
      .map((button) => this.processButton(button, qrBlock))

    const qrSettings = exportQuickRepliesSettings(get(qrBlock, 'settings', {}))

    const quickReplyHasTimeoutTarget = qrBlock && qrBlock.settings?.timeout

    if (quickReplyHasTimeoutTarget) {
      qrSettings.timeout_target = this._processTargetId(qrBlock.targetId)
    }

    const messages = ordinaryBlocks.map(this.processBlock)

    const defaultItemValues = {
      type: BackendContentType.INSTAGRAM,
      _oid: item.id || null,
      namespace: item.flow || '',
      quick_replies: { buttons, settings: qrSettings },
      caption: item.caption || '',
      content_id: item.contentId || null,
      removed: item.deleted || false,
      target: quickReplyHasTimeoutTarget ? null : this._processTargetId(item.targetId),
      private_reply: item.privateReply || null,
      messages,
    }

    return accountFlags.isEnabled(AccountFlag.ENABLE_IG_RECURRING_NOTIFICATION)
      ? {
          ...defaultItemValues,
          one_time_notify_reason_id: item.reasonId || null,
          $fbMessagingType: item.$fbMessagingType,
        }
      : defaultItemValues
  }

  processTelegram = (item) => {
    const blocks = (item.blocks || []).map(this.findBlock)
    const ordinaryBlocks = blocks.filter((block) => block.type !== BlockType.TELEGRAM_KEYBOARD)

    const telegramKeyboardBlock = blocks.find((block) => block.type === BlockType.TELEGRAM_KEYBOARD)
    const telegramKeyboardButtonsToExport =
      telegramKeyboardBlock?.buttonsStructure.map((rowButtons) =>
        rowButtons
          .map(this.findButton)
          .map((button) => this.processButton(button, telegramKeyboardBlock)),
      ) || []

    const messages = ordinaryBlocks.map((block) =>
      this.processBlock(block, { nodeType: NodeType.TELEGRAM }),
    )

    return {
      type: BackendContentType.TELEGRAM,
      _oid: item.id || null,
      namespace: item.flow || '',
      telegram_keyboard: { buttons: telegramKeyboardButtonsToExport },
      caption: item.caption || '',
      content_id: item.contentId || null,
      removed: item.deleted || false,
      target: this._processTargetId(item.targetId),
      messages,
    }
  }

  processTiktok = (item) => {
    const blocks = (item.blocks || []).map(this.findBlock)

    const messages = blocks.map((block) => this.processBlock(block, { nodeType: NodeType.TIKTOK }))

    return {
      type: BackendContentType.TIKTOK,
      _oid: item.id || null,
      namespace: item.flow || '',
      caption: item.caption || '',
      content_id: item.contentId || null,
      removed: item.deleted || false,
      target: this._processTargetId(item.targetId),
      messages,
    }
  }

  processAiAgent = (item) => {
    const target = this._processTargetId(item.targetId)

    return {
      type: BackendContentType.AI_AGENT,
      _oid: item.id || null,
      namespace: item.flow || '',
      caption: item.caption || '',
      content_id: item.contentId || null,
      removed: item.deleted || false,
      target,
      channel: item.channel,
      agent_data: item.agent_data ?? null,
      settings: item.settings.save_phone_as_whatsapp_id ? item.settings : null,
    }
  }

  /* Utils */

  _processTargetId = (targetId) => {
    if (!targetId) {
      return null
    }

    this.targetsMap[targetId] = true

    const targetItem = this.findNode(targetId)

    if (!targetItem && isFinite(targetId)) {
      return { content_id: targetId }
    }

    if (!targetItem) {
      return null
    }

    if (targetItem.contentId) {
      return { content_id: targetItem.contentId, _content_oid: targetItem.id }
    } else {
      return { _content_oid: targetItem.id }
    }
  }

  exportRichText = (text) => {
    return text
      .replace(/&/g, AMP_ENCRYPT)
      .replace(new RegExp(REGEXP_TAG_OPEN), TAG_OPEN_ENCRYPT)
      .replace(new RegExp(REGEXP_TAG_CLOSE), (pattern) => {
        return pattern.length === 1 ? TAG_CLOSE_ENCRYPT : pattern
      })
  }
}

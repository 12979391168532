export const TRIGGERRULES_COPY_RESPONSE = 'TRIGGERRULES_COPY_RESPONSE'
export const TRIGGERRULES_CHANGESTATUS_ERROR = 'TRIGGERRULES_CHANGESTATUS_ERROR'
export const TRIGGERRULES_CHANGESTATUS_REQUEST = 'TRIGGERRULES_CHANGESTATUS_REQUEST'
export const TRIGGERRULES_CURRENT_SAVE = 'TRIGGERRULES_CURRENT_SAVE'
export const TRIGGERRULES_CURRENT_SETTITLE_RESPONSE = 'TRIGGERRULES_CURRENT_SETTITLE_RESPONSE'
export const TRIGGERRULES_CURRENT_UPDATED_NOTIFICATION = 'TRIGGERRULES_CURRENT_UPDATED_NOTIFICATION'
export const TRIGGERRULES_CREATED_NOTIFICATION = 'TRIGGERRULES_CREATED_NOTIFICATION'
export const TRIGGERRULES_STATUS_UPDATED_NOTIFICATION = 'TRIGGERRULES_STATUS_UPDATED_NOTIFICATION'
export const TRIGGERRULES_DELETE_RESPONSE = 'TRIGGERRULES_DELETE_RESPONSE'
export const TRIGGERRULES_CURRENT_SAVE_RESPONSE = 'TRIGGERRULES_CURRENT_SAVE_RESPONSE'
export const TRIGGERRULES_RESTORE_RESPONSE = 'TRIGGERRULES_RESTORE_RESPONSE'
export const TRIGGERRULES_CREATE_REQUEST = 'TRIGGERRULES_CREATE_REQUEST'
export const TRIGGERRULES_CREATE_RESPONSE = 'TRIGGERRULES_CREATE_RESPONSE'
export const TRIGGERRULES_DELETED_NOTIFICATION = 'TRIGGERRULES_DELETED_NOTIFICATION'

import { api } from 'shared/api'

import { ZendeskSchemas } from './schemas'

export const ZendeskApi = {
  messagingSignon: api.account.createPost({
    url: '/zendesk/dashboard/messagingSign',
    schemas: ZendeskSchemas.messagingSignon,
  }),
}

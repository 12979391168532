import { parseWallet } from 'common/billing/wallet/walletAdapters'
import { initialWalletState } from 'common/billing/wallet/walletSlices'
import { appInitialState } from 'common/core/reducers'
import { settingsInitialState } from 'common/settings/reducers'
import { parseInitApp } from 'utils/parseInitApp'

export const getInitialState = () => {
  const initApp = window.__INIT__ ?? {}
  const settings = initApp.settings ?? {}
  const walletInfo = parseWallet(initApp['app.currentAccountWallet'] ?? {})
  const app = parseInitApp(initApp)

  return {
    app: { ...appInitialState, ...app },
    settings: {
      ...settingsInitialState,
      ...settings,
      publicAPIAccessToken: initApp['app.currentAccount']?.public_api_access_token,
    },
    billing: { wallet: { wallet: { ...initialWalletState, ...walletInfo } } },
  }
}

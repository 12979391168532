import { createAction } from '@reduxjs/toolkit'
import get from 'lodash/get'
import { createAppAsyncThunk } from 'reduxTyped'
import { l } from '@manychat/manyui'

import { SimpleTrialPlaceType } from 'apps/cms/lib/constants'
import { contentManagementActions } from 'apps/cms/store'
import KeywordRuleModel from 'apps/keywords/models/KeywordRule'
import keywordAdapter from 'apps/keywords/models/KeywordRule/adapter'
import { UpgradeSource } from 'common/billing'
import { handleGoProError } from 'common/billing/helpers/handleGoProError'
import { alert } from 'common/core'
import { ChannelType } from 'common/core/constants/ChannelType'
import { IAsyncThunkAction } from 'common/core/interfaces/actions'
import { handleGoProExperiment } from 'common/goProExperimentHandlers'
import { WebSocketEvents } from 'constants/WebSockets'
import { isKeywordLimitError, isProError } from 'shared/api/common/errors/proError'
import { handleCatch } from 'shared/api/lib/errors/handlers'
import { applyHandlerByGuard, reportUnhandledErrors } from 'shared/api/lib/errors/utils'
import { mapResponse } from 'shared/api/lib/mapResponse'
import { KeywordsApi } from 'shared/api/requests/keywords'
import { createListSlice } from 'shared/lib/factory/redux/createListSlice'
import { createListOperations } from 'shared/lib/factory/redux/createListSlice/lib/createListOperations'
import { createAsyncAction } from 'shared/lib/redux'
import { isRequestError } from 'utils/api/mcApi/RequestError'
import { getClientId } from 'utils/clientId'
import { analyticsService } from 'utils/services/analytics'
import { AnalyticsData } from 'utils/services/analytics/types'
import errorTrackingService from 'utils/services/errorTrackingService'
import { anotherTabNotificationsListener } from 'utils/services/notificationsService'

import { Keyword, KeywordInitial } from './keywordsInterfaces'
import { KeywordStatus } from './models/KeywordRule/constants'
import {
  clearEmptyKeywords,
  isThumbsUpKeyword,
  hasSystemKeywordNames,
} from './models/KeywordRule/helpers'

const NAMESPACE = 'keywords'

type OpenCreateKeywordWithSystemNameModalPayload = null | ChannelType

interface ExtraState {
  isShowCreating: boolean
  isKeywordWarningModalOpen: boolean
  keywordWithSystemNameModalChannelType: OpenCreateKeywordWithSystemNameModalPayload
  item: Keyword | null
}
interface SaveKeywordStatusPayload {
  id: number
  status: KeywordStatus
  options?: {
    source: string
    silent?: boolean
  }
}

const extraState: ExtraState = {
  isShowCreating: false,
  isKeywordWarningModalOpen: false,
  keywordWithSystemNameModalChannelType: null,
  item: null,
}

export const hideCreateKeywordPanel = createAction(`${NAMESPACE}/hideCreateKeywordPanel`)
export const showCreateKeywordPanel = createAction(`${NAMESPACE}/showCreateKeywordPanel`)
export const openKeywordWarningModal = createAction(`${NAMESPACE}/openKeywordWarningModal`)
export const closeKeywordWarningModal = createAction(`${NAMESPACE}/closeKeywordWarningModal`)
export const cleanupAfterTemplateDeletion = createAction<number>(
  `${NAMESPACE}/cleanupAfterTemplateDeletion`,
)
export const openCreateKeywordWithSystemNameModal =
  createAction<OpenCreateKeywordWithSystemNameModalPayload>(
    `${NAMESPACE}/openCreateKeywordWithSystemNameModal`,
  )
export const closeCreateKeywordWithSystemNameModal = createAction(
  `${NAMESPACE}/closeCreateKeywordWithSystemNameModal`,
)

export const KEYWORD_CREATION_LIMIT = 3

export const createKeywordFlow = createAppAsyncThunk(
  `${NAMESPACE}/createFlow`,
  async (ruleId: number, { dispatch, getState }) => {
    const keyword = getState().keywords.byId[ruleId]
    const flowName = KeywordRuleModel.generateFlowName(keyword)
    const fileItem = await dispatch(
      contentManagementActions.createAutomation({ name: flowName }),
    ).unwrap()

    if (fileItem) {
      return dispatch(setKeywordFlow({ ruleId, flowNs: fileItem.flow.ns }))
    }

    return null
  },
)

export const setKeywordFlow = createAppAsyncThunk(
  `${NAMESPACE}/setFlow`,
  async ({ ruleId, flowNs }: { ruleId: number; flowNs: string }, { dispatch }) => {
    try {
      await KeywordsApi.setFlow({
        body: {
          rule_id: ruleId,
          flow_ns: flowNs,
        },
      })
      await dispatch(fetchKeyword(ruleId))
      return ruleId
    } catch (error) {
      handleCatch(error)
      return false
    }
  },
)

export const reorderKeywords = createAppAsyncThunk(
  `${NAMESPACE}/sort`,
  async (ids: number[], { rejectWithValue }) => {
    try {
      const result = await KeywordsApi.sort({
        body: { ids },
      })

      const { data } = result
      return data.ids
    } catch (error) {
      handleCatch(error, (responseError) =>
        errorTrackingService.trackWarning(responseError, {
          fingerprint: 'failed-to-sort-keywords',
        }),
      )

      return rejectWithValue()
    }
  },
)

export const fetchKeyword = createAppAsyncThunk(
  `${NAMESPACE}/fetchKeyword`,
  async (id: number, { rejectWithValue }) => {
    try {
      const response = await KeywordsApi.getItem({ query: { rule_id: id } })
      return response.data.rule
    } catch (error) {
      handleCatch(error)
      return rejectWithValue()
    }
  },
)

export const updateAndSaveKeyword = (
  id: number | undefined,
  data: Keyword | KeywordInitial,
): IAsyncThunkAction => {
  return async (dispatch) => {
    if (!id) {
      return errorTrackingService.trackError(
        new Error('Keyword id is not provided, which is not expected behaviour'),
      )
    }

    data = clearEmptyKeywords(data)

    await dispatch(
      keywordsActions.updateItemFx({
        payload: { body: { ...data }, query: { client_id: getClientId() } },
        id,
      }),
    )
    alert(l.translate('Keyword saved'), 'success')
  }
}

export const saveKeywordStatus = createAsyncAction<Keyword | false, SaveKeywordStatusPayload>(
  'keywords/saveKeywordStatus',
  async ({ id, status, options }, { dispatch }) => {
    analyticsService.sendEvent('KEYWORD.ENABLE.CLICK', { status, source: options?.source })

    try {
      const response = await KeywordsApi.setStatus({
        body: {
          rule_id: id,
          status,
        },
      })
      return keywordAdapter(response.data.rule)
    } catch (error) {
      handleCatch(error, (response) => {
        let unhandledErrors = applyHandlerByGuard(response.$errors, isKeywordLimitError, () => {
          analyticsService.sendEvent('KEYWORD.ENABLE.LIMIT_ERROR', {
            status,
            source: options?.source,
          })

          handleGoProExperiment(SimpleTrialPlaceType.KEYWORDS_TRIGGERS_LIMIT)
          return false
        })

        unhandledErrors = applyHandlerByGuard(unhandledErrors, isProError, (err) => {
          dispatch(
            handleGoProError(err, {
              openGoProModal: err.error_data.go_pro,
              source: UpgradeSource.SETTINGS_TEAM,
              products: err.error_data.products,
              message: !err.error_data.go_pro ? err.message : undefined,
            }),
          )
        })

        reportUnhandledErrors(unhandledErrors, response.endpoint)
      })

      return false
    }
  },
)

export const createKeyword = (
  data: Keyword | KeywordInitial,
): IAsyncThunkAction<{ item: Keyword } | null> => {
  return async (dispatch) => {
    data = clearEmptyKeywords(data)

    const keywords = data.keyword_rules.reduce(
      (acc, rule) => [...acc, ...rule.keywords],
      [] as string[],
    )

    if (!keywords.length && !isThumbsUpKeyword(data)) {
      alert(l.translate('Please provide a keyword.'), 'warning')
      return null
    }

    if (hasSystemKeywordNames(keywords)) {
      dispatch(openCreateKeywordWithSystemNameModal(data.channel || ChannelType.FB))
      return null
    }

    try {
      const response = await dispatch(
        actions.createItemFx({
          payload: { body: data, query: { client_id: getClientId() } },
        }),
      ).unwrap()
      analyticsService.sendEvent('KEYWORD.CREATE.SUCCESS', data as unknown as AnalyticsData)

      return { item: response }
    } catch (error) {
      if (isRequestError(error) && error.reason === 'business-logic') return null

      errorTrackingService.trackWarning(error, {
        fingerprint: 'failed-to-create-keyword',
      })
      return null
    }
  }
}

const {
  reducer: keywordsReducer,
  actions,
  selectors,
} = createListSlice({
  namespace: NAMESPACE,
  storePath: NAMESPACE,

  operations: createListOperations({
    getList: mapResponse(KeywordsApi.getList, (response) => {
      return response.data.rules.map((keyword) => keywordAdapter(keyword))
    }),
    getNextList: undefined,
    getPrevList: undefined,
    createItem: mapResponse(KeywordsApi.create, (response) => keywordAdapter(response.data.rule)),
    updateItem: mapResponse(KeywordsApi.update, (response) => keywordAdapter(response.data.rule)),
    deleteItem: KeywordsApi.delete,
  }),

  getItemId: (keyword) => keyword.rule_id,

  extraReducers: (builder) => {
    builder.addCase(hideCreateKeywordPanel, (state) => {
      state.isShowCreating = false
    })
    builder.addCase(showCreateKeywordPanel, (state) => {
      state.isShowCreating = true
    })
    builder.addCase(openCreateKeywordWithSystemNameModal, (state, action) => {
      state.keywordWithSystemNameModalChannelType = action.payload
    })
    builder.addCase(closeCreateKeywordWithSystemNameModal, (state) => {
      state.keywordWithSystemNameModalChannelType = null
    })
    builder.addCase(openKeywordWarningModal, (state) => {
      state.isKeywordWarningModalOpen = true
    })
    builder.addCase(closeKeywordWarningModal, (state) => {
      state.isKeywordWarningModalOpen = false
    })
    builder.addCase(reorderKeywords.fulfilled, (state, action) => {
      state.items.sort((a, b) => {
        return action.payload.indexOf(a.rule_id) - action.payload.indexOf(b.rule_id)
      })
    })
    builder.addCase(fetchKeyword.fulfilled, (state, action) => {
      state.item = keywordAdapter(action.payload)
    })
    builder.addCase(saveKeywordStatus.fulfilled, (state, action) => {
      if (!action.payload) return
      state.byId[action.payload.rule_id] = action.payload
      state.items.forEach((item, index) => {
        if (action.payload && item.rule_id === action.payload.rule_id) {
          state.items[index] = action.payload
        }
      })
    })
    builder.addCase(cleanupAfterTemplateDeletion, (state, action) => {
      if (state.items == null) {
        return
      }
      const byId = { ...state.byId }
      delete byId[action.payload]
      return {
        ...state,
        byId,
        items: state.items.filter((i) => String(i.id) !== String(action.payload)),
      }
    })
  },

  reducers: {},
  extraState: extraState,
})

export const getIsShowCreating = (state: RootState) => state.keywords.isShowCreating
export const getIsKeywordWarningModalOpen = (state: RootState) =>
  state.keywords.isKeywordWarningModalOpen
export const hasChanges = (state: RootState, keywordId: number) => {
  const has = get(state, `${NAMESPACE}.byId.${keywordId}.hasChanges`, false)
  return has
}

const keywordsSelectors = {
  ...selectors,
  getIsShowCreating,
  getIsKeywordWarningModalOpen,
  hasChanges,
}

const keywordsActions = {
  ...actions,
  // all actions from the file:
  hideCreateKeywordPanel,
  showCreateKeywordPanel,
  openKeywordWarningModal,
  closeKeywordWarningModal,
  openCreateKeywordWithSystemNameModal,
  closeCreateKeywordWithSystemNameModal,
  fetchKeyword,
  updateAndSaveKeyword,
  saveKeywordStatus,
  reorderKeywords,
  createKeyword,
  setKeywordFlow,
  createKeywordFlow,
}

export { keywordsReducer, keywordsActions, keywordsSelectors }

anotherTabNotificationsListener.on(WebSocketEvents.KEYWORD_CREATED, (data, dispatch) => {
  dispatch(keywordsActions.appendItem({ item: keywordAdapter(data.model) }))
})
anotherTabNotificationsListener.on(WebSocketEvents.KEYWORD_UPDATED, (data, dispatch) => {
  dispatch(keywordsActions.updateItem({ id: data.model.rule_id, data: keywordAdapter(data.model) }))
})
anotherTabNotificationsListener.on(WebSocketEvents.KEYWORD_DELETED, (data, dispatch) => {
  dispatch(keywordsActions.deleteItem({ id: data.model.rule_id }))
})

import { Icon, l } from '@manychat/manyui'

import {
  IntegrationType,
  IntegrationNameByType,
} from 'common/actions/integrations/common/constants/IntegrationType'
import { AiIntegrationDescription } from 'common/actions/integrations/common/lib/copies'
import {
  createIntegration,
  createIntegrationAction,
} from 'common/actions/integrations/common/model/helpers'
import { AccountTargetPermissionsNames } from 'common/core/models/Account/constants'

export const DeepSeekActionTypes = {
  REQUEST_TO_DEEPSEEK_CHAT: 'deepseek_chat_completions_request',
}

export const DeepSeekConfig = createIntegration({
  type: IntegrationType.DEEP_SEEK,
  name: IntegrationNameByType[IntegrationType.DEEP_SEEK],
  icon: Icon.DeepSeek,
  permissionsVariable: AccountTargetPermissionsNames.DEEPSEEK_INTEGRAION,
  description: () => AiIntegrationDescription({ integrationName: 'DeepSeek' }),
  reduxActions: {},
  actions: [
    createIntegrationAction({
      type: DeepSeekActionTypes.REQUEST_TO_DEEPSEEK_CHAT,
      description: l.translate('Make request to DeepSeek.'),
      name: l.translate('Request'),
    }),
  ],
  isBeta: true,
})

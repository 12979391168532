import { FilterIcon } from 'apps/cms/components/Flows/components/FlowFilters/components/FilterPicker/lib/constants'
import { Option } from 'apps/cms/components/Flows/components/FlowFilters/components/FilterPicker/lib/types'
import { FSObjectType } from 'common/cms/models/FSObject/constants'
import { ChannelType } from 'common/core/constants/ChannelType'
import {
  FsFlowObject,
  FsFlow,
  FsObject,
  FsFolderObject,
  FsFilter,
} from 'shared/api/requests/cms/schemas'

// TODO remove after migration to new WS events for new CMS
export const parseFsFlowObject = (fsFlowObject: FsFlowObject): FsFlow => {
  return {
    easy_builder: fsFlowObject.easy_builder,
    type: fsFlowObject.type,
    path: fsFlowObject.path,
    ns: fsFlowObject.flow.ns,
    name: fsFlowObject.flow.name,
    has_published_content: fsFlowObject.flow.has_published_content,
    modified: String(fsFlowObject.modified),
    has_unpublished_changes: fsFlowObject.flow.has_unpublished_changes,
    channels_usage: {
      [ChannelType.FB]: fsFlowObject.flow.has_fb_channel,
      [ChannelType.INSTAGRAM]: fsFlowObject.flow.has_instagram_channel,
      [ChannelType.SMS]: fsFlowObject.flow.has_sms_channel,
      [ChannelType.EMAIL]: fsFlowObject.flow.has_email_channel,
      [ChannelType.TELEGRAM]: fsFlowObject.flow.has_telegram_channel,
      [ChannelType.WHATSAPP]: fsFlowObject.flow.has_whatsapp_channel,
      [ChannelType.TIKTOK]: fsFlowObject.flow.has_tiktok_channel,
    },
    preview: {
      url: fsFlowObject.preview_url,
      is_actual: fsFlowObject.preview_actual,
    },
    capabilities: {
      is_editable: fsFlowObject.is_editable,
      is_shareable: fsFlowObject.is_shareable,
      is_restorable: fsFlowObject.is_restorable,
      is_deletable: fsFlowObject.is_deletable,
      is_permanently_deletable: fsFlowObject.is_permanently_deletable,
    },
    stats: {
      sent: fsFlowObject.stats?.sent ?? 0,
      sent_unq: fsFlowObject.stats?.sent_unq ?? 0,
      clicked_unq: fsFlowObject.stats?.clicked_unq ?? null,
    },
    // TODO: we will get rid of this function
    triggers: fsFlowObject.triggers,
    sharing: {
      is_shared: fsFlowObject.flow.is_shared,
      player_embed_url: fsFlowObject.flow.player_embed_url,
      player_page_url: fsFlowObject.flow.player_page_url,
      shared_cloning_enabled: fsFlowObject.flow.shared_cloning_enabled,
    },
    template_installation_access_data: fsFlowObject.template_installation_access_data
      ? {
          has_user_access: fsFlowObject.template_installation_access_data.has_user_access ?? false,
          is_disabled: fsFlowObject.template_installation_access_data.is_disabled,
          is_locked: fsFlowObject.template_installation_access_data.is_locked,
          is_protected: fsFlowObject.template_installation_access_data.is_protected,
          is_owner: fsFlowObject.template_installation_access_data.is_owner,
        }
      : undefined,
  }
}

export const isFsFlowObject = (object: FsObject): object is FsFlowObject =>
  object.type === FSObjectType.FLOW

export const isFsFolderObject = (object: FsObject): object is FsFolderObject =>
  object.type === FSObjectType.FOLDER

export const getParentPath = (path: string) => {
  if (path === '/') return path
  const parsedPath = path.split('/').filter((entity) => entity)
  return `/${parsedPath.slice(0, parsedPath.length - 1).join('/')}`
}

export const getFilterOption = (filter: FsFilter): Option => {
  return {
    label: filter.title,
    value: filter.filters,
    key: filter.key,
    icon: filter.group ? FilterIcon[filter.group] : null,
  }
}

import { FieldGroupId, FieldSegmentType, FieldType } from '../entities/enums'
import { Field } from '../entities/field'

export type FieldFilter = (field: Field) => boolean

export const makeFilter = (predicate: FieldFilter) => (fields: Field[]) => fields.filter(predicate)

export const notArray: FieldFilter = (field) => field.type !== FieldType.ARRAY

export const typeIs =
  (types: FieldType[]): FieldFilter =>
  (field) =>
    types.includes(field.type)

export const typeNot =
  (types: FieldType[]): FieldFilter =>
  (field) =>
    !types.includes(field.type)

export const groupNot =
  (groups: FieldGroupId[]): FieldFilter =>
  (field) =>
    !groups.includes(field.group)

export const groupIs =
  (groups: FieldGroupId[]): FieldFilter =>
  (field) =>
    groups.includes(field.group)

export const filterFieldsInGroup =
  (groups: FieldGroupId[], ids: string[]): FieldFilter =>
  (field) =>
    !groups.includes(field.group) || (groups.includes(field.group) && ids.includes(field.id))

export const segmentTypeIs =
  (segmentTypes: FieldSegmentType[]): FieldFilter =>
  (field) =>
    segmentTypes.includes(field.segmentType as FieldSegmentType)

export const fieldNot =
  (ids: string[]): FieldFilter =>
  (field) =>
    !ids.includes(field.id)

export const fieldIs =
  (ids: string[]): FieldFilter =>
  (field) =>
    ids.includes(field.id)

export const ifAll =
  (...filters: FieldFilter[]): FieldFilter =>
  (field) =>
    filters.every((filter) => filter(field))

export const ifAny =
  (...filters: FieldFilter[]): FieldFilter =>
  (field) =>
    filters.some((filter) => filter(field))

import cloneDeep from 'lodash/cloneDeep'
import merge from 'lodash/merge'
import without from 'lodash/without'
import { v4 as uuid } from 'uuid'

import { IBlock, IBlockConfig, DeepPartial } from 'common/builder/blocks/blockInterfaces'
import { BlockType } from 'common/builder/constants/BlockType'
import EntityType from 'common/builder/constants/EntityType'
import errorTrackingService from 'utils/services/errorTrackingService'

export default class BaseBlockConfig implements IBlockConfig {
  blockType?: BlockType = undefined

  static BLOCK_BASE_ATTRIBUTES = ['id', 'type', 'entity']

  static BLOCK_TEMPORARY_ATTRIBUTES = ['$new']

  static BLOCK_COMMON_DEFAULTS = {
    blocks: [],
    buttons: [],
    stats: null,
  }

  getDefaults = () => ({})

  getAttributes = () => {
    const defaults = this.getDefaults()
    return [
      ...Object.keys({ ...defaults, ...BaseBlockConfig.BLOCK_COMMON_DEFAULTS }),
      ...BaseBlockConfig.BLOCK_BASE_ATTRIBUTES,
      ...BaseBlockConfig.BLOCK_TEMPORARY_ATTRIBUTES,
    ]
  }

  create = (initial: DeepPartial<IBlock> = {}) => {
    const base = {
      id: initial.id || uuid(),
      type: this.blockType,
      entity: EntityType.BLOCK,
    }

    const initialAttributes = Object.keys(initial)
    const knownAttributes = this.getAttributes()
    const unknownAttributes = without(initialAttributes, ...knownAttributes)
    if (unknownAttributes.length) {
      const attrs = unknownAttributes.join()
      const payload = { attrs, type: this.blockType }
      const errorId = 'block-create-unknown-attrs'
      errorTrackingService.trackWarningOnce('Block create unknown attrs', {
        extra: payload,
        fingerprint: errorId,
      })
      console.warn(
        `Block create: attribute "${attrs}" isn't listed in BlockConfig defaults. All block attribute must be required and have default values.`,
      )
    }

    const defaults = cloneDeep({
      ...this.getDefaults(),
      ...BaseBlockConfig.BLOCK_COMMON_DEFAULTS,
    })
    return merge(defaults, initial, base) as IBlock
  }
}

import React from 'react'
import random from 'lodash/random'
import { Skeleton } from '@manychat/manyui'

import cardCm from './FlowCompactCard.module.css'
import cm from './FlowCompactCardSkeleton.module.css'

export const FlowCompactCardSkeleton = React.memo(() => (
  <div className={cardCm.flowCompactCard}>
    <Skeleton.Image className={cardCm.flowCompactCard__placeholder} />

    <div className={cardCm.flowCompactCard__footer}>
      <Skeleton.Text minLineWidth={`${random(60, 120)}px`} className={cm.flowTitleSkeleton} />

      <Skeleton.Text className={cm.contextActionsSkeleton} />
    </div>
  </div>
))

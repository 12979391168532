import React from 'react'
import { useAppSelector } from 'reduxTyped'
import { BtnV2, l } from '@manychat/manyui'

import { contentManagementSelectors } from 'apps/cms/store'

import { useNewAutomationButton } from './hooks/useNewAutomationButton'

export const NewAutomationButton = () => {
  const isFlowCreating = useAppSelector(contentManagementSelectors.getIsFlowCreating)
  const isOutlinedNewAutomationButton = useAppSelector(
    contentManagementSelectors.getIsOutlinedNewAutomationButton,
  )
  const { createAutomation } = useNewAutomationButton()

  return (
    <BtnV2
      onClick={createAutomation}
      loading={isFlowCreating}
      leftIcon={'Plus'}
      variant={isOutlinedNewAutomationButton ? 'default' : 'primary'}
      data-test-id="create-automation-button"
    >
      {l.translate('New Automation')}
    </BtnV2>
  )
}

import { l } from '@manychat/manyui'

import { IErrorResponse } from 'common/core/interfaces/errorResponse'

export const translateResponse = (response: IErrorResponse, translate = translateError) => {
  if (response.error) {
    response.error = translate(response.error, response.error_data)
  }
  if (response.errors && Array.isArray(response.errors)) {
    response.errors = response.errors.map((error, i) =>
      typeof error !== 'string' ? error : translate(error, response.errors_data?.[i]),
    )
  }
}

const translateError = (error: string, error_data?: Record<string, string>): string =>
  l.getString(ErrorsDictionary.get(error)?.(error_data), error)

export const ErrorsDictionary = [
  l.makeTranslate('Choose the first message for Instagram channel to set up Instagram Ads'),
  l.makeTranslate(
    'Add at least one button or Quick Reply to the first message to set up Facebook Ads',
  ),
  l.makeTranslate('Add at least one Quick Reply to the first message to set up Instagram Ads'),
  l.makeTranslate(
    'Don’t use more than one content block in the first message to set up Facebook Ads',
  ),
  l.makeTranslate(
    'Don’t use more than one content block in the first message to set up Instagram Ads',
  ),
  l.makeTranslate('Choose the first message for Messenger channel to set up Facebook Ads'),
  l.makeTranslate("Agency not found or you don't have enough permissions to access it"),
  l.makeTranslate('Live Chat Agents can`t see this page'),
  l.makeTranslate('Manychat App is not Primary receiver in your Facebook page settings'),
  l.makeTranslate('Please select a post to track before activating the Growth Tool'),
  l.makeTranslate('Facebook channel is not active'),
  l.makeTranslate("Message wasn't sent"),
  l.makeTranslate('Events not found'),
  l.makeTranslate(
    'You have reached the maximum number of keywords on your current Plan. Please upgrade your PRO plan',
  ),
  l.makeTranslate('Unexpected timezone'),
  l.makeTranslate(
    'You have reached the maximum number of widgets on the current plan. Please upgrade for unlimited access.',
  ),
  l.makeTranslate('Please create Sequence Message content or attach an existing one'),
  l.makeTranslate('Welcome message not published'),
  l.makeTranslate('You exceeded your current plan Tags limitation. Please upgrade to proceed.'),
  l.makeTranslate(
    'You have reached the maximum number of users on your current Plan. Please upgrade your PRO plan',
  ),
  l.makeTranslate('Not found.'),
  l.makeTranslate('Email is already in use by another user'),
  l.makeTranslate('Exec already processed'),
  l.makeTranslate('Wrong payload parameters'),
  l.makeTranslate('The first node should be Instagram with the "Comment Reply" content type.'),
  l.makeTranslate('Caption already used'),
  l.makeTranslate('Wrong path provided'),
  l.makeTranslate('This code is incorrect.'),
  l.makeTranslate('Cannot activate empty rule'),
  l.makeTranslate('Please enter your email address in the correct format'),
  l.makeTranslate('Can not get estimation. {exception_message}'),
  l.makeTranslate('Ad image hash should be string'),
  l.makeTranslate('Something went wrong with the content'),
  l.makeTranslate('Wrong subscriber'),
  l.makeTranslate('This coupon code is already used'),
  l.makeTranslate('Cannot change reserved keyword rule'),
  l.makeTranslate('Invalid coupon provided'),
  l.makeTranslate('Please provide a keyword.'),
  l.makeTranslate('To publish Automation, remove an Action step or add an Action.'),
  l.makeTranslate("Your account wasn't upgraded to Pro"),
  l.makeTranslate(
    'Something went wrong with your card. You need to contact your card issuer for more information.',
  ),
  l.makeTranslate(
    'Something went wrong with your card. Please, try again and if it still cannot be processed you need to contact your card issuer for more information',
  ),
  l.makeTranslate('Tag with this name already exists'),
  l.makeTranslate('No users found'),
  l.makeTranslate('Post not found on Facebook'),
  l.makeTranslate('Cannot use this message type, check your Subscription Messaging Permission'),
  l.makeTranslate('Unknown URL or ID, please try another one'),
  l.makeTranslate('There are no Automations selected'),
  l.makeTranslate(
    'Something went wrong with your card. Please, contact our support for clarification.',
  ),
  l.makeTranslate(
    "You aren't able to activate the widget on the current plan. Upgrade for unlimited access.",
  ),
  l.makeTranslate('This coupon is expired'),
  l.makeTranslate('There are no Automation selected'),
  l.makeTranslate(
    'You have reached the maximum number of tags on your current Plan. Please upgrade your PRO plan',
  ),
  l.makeTranslate("Can't update notification phone because sms channel is disabled"),
  l.makeTranslate('Provided text is longer than {greeting_max_length} symbols'),
  l.makeTranslate('Invalid value format'),
  l.makeTranslate('Wrong sequence id'),
  l.makeTranslate('Wrong custom Ref parameter'),
  l.makeTranslate('This coupon code is already used on another account'),
  l.makeTranslate('PRO Account required'),
  l.makeTranslate('resource_version required'),
  l.makeTranslate("Comments Growth Tool doesn't support this type of post"),
  l.makeTranslate('Select a website URL or a reply message for the button in Email Design'),
  l.makeTranslate("Can't remove tag"),
  l.makeTranslate('Attribute account_id required'),
  l.makeTranslate('Custom Ref parameter already exists for another widget'),
  l.makeTranslate('Something went wrong'),
  l.makeTranslate('Worksheet not found'),
  l.makeTranslate('New verification code required.'),
  l.makeTranslate('Cannot delete reserved rule'),
  l.makeTranslate(
    'Permissions error, cannot set up Welcome Message! The Welcome Message is successfully saved but we cannot set it up right now.',
  ),
  l.makeTranslate('Icebreakers list is empty'),
  l.makeTranslate('Wrong tag'),
  l.makeTranslate('unsupported event type'),
  l.makeTranslate('You should pass url'),
  l.makeTranslate('Can not enable Conversation Starters.'),
  l.makeTranslate('Widget not found'),
  l.makeTranslate('Please provide a valid title'),
  l.makeTranslate('Wrong field'),
  l.makeTranslate('Your email address is already verified.'),
  l.makeTranslate('You should connect Facebook channel'),
  l.makeTranslate('Incorrect sign of attachment'),
  l.makeTranslate('Checking Result cannot be blank.'),
  l.makeTranslate(
    'Permissions error, cannot fetch your Facebook posts! Please refresh FB permissions to continue',
  ),
  l.makeTranslate("Can't add to sequence"),
  l.makeTranslate('Google authentication error'),
  l.makeTranslate('Wrong subscriber status'),
  l.makeTranslate('Only active subscribers can be assigned'),
  l.makeTranslate(
    'The phone number has an incorrect format. Please enter your phone number in the format +14692146830.',
  ),
  l.makeTranslate('Please upgrade your plan to proceed'),
  l.makeTranslate('Caption already exists'),
  l.makeTranslate('Wrong Question Field type.'),
  l.makeTranslate('Wrong request method'),
  l.makeTranslate(
    'Delay duration out of range. Please choose between 10 and 3600 seconds or switch to minutes/hours',
  ),
  l.makeTranslate('Authentication token expired'),
  l.makeTranslate('Can not publish Ad Set. {exception_message}'),
  l.makeTranslate('Smart delay block without target'),
  l.makeTranslate('The daily code sending limit has been reached'),
  l.makeTranslate(
    'You have reached the maximum number of variables on your current plan. Please upgrade your current PRO plan',
  ),
  l.makeTranslate(
    'Sorry, we cannot copy Automation right now. Please contact our friendly support to proceed.',
  ),
  l.makeTranslate(
    'Facebook page is disconnected from your Manychat account. To preview the Automation go to Settings -> Messenger and click "Reconnect"',
  ),
  l.makeTranslate('Video ID should be integer'),
  l.makeTranslate('Please publish the content before copying to another page.'),
  l.makeTranslate("Can't receive data"),
  l.makeTranslate('Url required'),
  l.makeTranslate("Can't move Automation into same path"),
  l.makeTranslate('Phone number is required'),
  l.makeTranslate('Failed to process the file'),
  l.makeTranslate(
    "You aren't able to copy selected Automation on the current plan because they contain PRO elements. Upgrade for unlimited access.",
  ),
  l.makeTranslate('Automation not found'),
  l.makeTranslate("Can't remove from sequence"),
  l.makeTranslate('Wrong thread'),
  l.makeTranslate('Installation link is invalid'),
  l.makeTranslate('Subscriber does not exist'),
  l.makeTranslate('Cloning error'),
  l.makeTranslate('Template not found'),
  l.makeTranslate('Can`t register greeting text. Please refresh page`s permissions'),
  l.makeTranslate('Empty text provided'),
  l.makeTranslate('You cannot create more than {max_seq_messages} sequence messages'),
  l.makeTranslate('Exceeded number of requests, please try again later'),
  l.makeTranslate('Invalid issue type'),
  l.makeTranslate('Something went wrong with your card. Please, try again.'),
  l.makeTranslate('Profile photo must have a resolution between 192x192 and 640x640 px'),
  l.makeTranslate(
    'The phone number has an incorrect format. Please enter the full 10 digit number in international format with the country code including \u201c+\u201d. For example: +15417543010',
  ),
  l.makeTranslate('Agency id is empty'),
  l.makeTranslate(
    'Your card number is incorrect. Please, try again using the correct card number.',
  ),
  l.makeTranslate('Wrong content provided.'),
  l.makeTranslate('Wrong widget'),
  l.makeTranslate('Can not get Ad Accounts. {exception_message}'),
  l.makeTranslate("Content is not editable so it can't be published"),
  l.makeTranslate("Main menu saved, but can't be registered"),
  l.makeTranslate('Add at least one item in Main Menu to disable User Input.'),
  l.makeTranslate('Duplicate folder title'),
  l.makeTranslate('No access to domains. Permissions error'),
  l.makeTranslate('Facebook page is not connected'),
  l.makeTranslate(
    'Sorry, we can\u2019t recognize a country for the phone number you have entered. Try adding a valid country code, i.e. \u201c+1\u201d',
  ),
  l.makeTranslate('Wrong sequence: not found'),
  l.makeTranslate("Can't get sheets. forbidden: The caller does not have permission"),
  l.makeTranslate('Url cannot be blank.'),
  l.makeTranslate('forbidden: The caller does not have permission'),
  l.makeTranslate('Channel not connected.'),
  l.makeTranslate(
    "Request access based on facebook's page permissions can't be approved without exist fb channel",
  ),
  l.makeTranslate('AutomationNs is required'),
  l.makeTranslate(
    'We couldn\u2019t update your billing data due to credit card issues. To update your billing data, go to Settings -> Billing.',
  ),
  l.makeTranslate('No access to domains. Unknown error.'),
  l.makeTranslate('No access to the attachment'),
  l.makeTranslate(
    "Due to Facebook's new privacy rules, you can't send Paid Messages delivered to and from Europe. Please, change Ad Account and try again. https://www.facebook.com/business/help/574941489951914",
  ),
  l.makeTranslate('Can not send message for subscribers without Opt-in for WhatsApp'),
  l.makeTranslate('Visitor ended this conversation.'),
  l.makeTranslate('Something goes wrong'),
  l.makeTranslate(
    'This growth tool has unpublished content. Please go to Opt-In Actions section and publish your opt-in message.',
  ),
  l.makeTranslate('Wrong Question User Field type.'),
  l.makeTranslate('Failed to process the file. File type is not supported'),
  l.makeTranslate('Address required'),
  l.makeTranslate('Too many attempts to apply coupon. Please try later'),
  l.makeTranslate('Field already archived'),
  l.makeTranslate('Can not update Conversation Starters.'),
  l.makeTranslate('Expiration must be between 10 seconds and 30 days'),
  l.makeTranslate('Name cannot be blank.'),
  l.makeTranslate('You need billing access for this account'),
  l.makeTranslate('You should connect the Facebook channel so to use this Widget'),
  l.makeTranslate('This Automation cannot be restored'),
  l.makeTranslate('Facebook error occurred. Please refresh permissions'),
  l.makeTranslate('The SMS was not sent'),
  l.makeTranslate(
    'Instagram nodes are not allowed in broadcasts to avoid ban your account by Instagram. Remove them to proceed.',
  ),
  l.makeTranslate("Can't unset custom field"),
  l.makeTranslate('Operation timed out after 10001 milliseconds with 0 bytes received'),
  l.makeTranslate('Tag with same name already exists'),
  l.makeTranslate('Url is not valid'),
  l.makeTranslate('Invalid payload json'),
  l.makeTranslate('Only one line is allowed'),
  l.makeTranslate(
    "Your Facebook admin rights are insufficient to delete an owner.\nTry relogging to Manychat and if it doesn't help, contact an admin of your Facebook page.",
  ),
  l.makeTranslate('search query failed by timeout'),
  l.makeTranslate(
    'First step and immediate subsequent step enabled via "Continue to another step" feature can only be of type "Send Message" in the Opt-In Automation for a widget with checkbox. User Input can\u2019t be used in the first Opt-In message for a widget with checkbox. Please, use at least one button in a first Opt-In message for a widget with checkbox',
  ),
  l.makeTranslate("Can't switch main menu"),
  l.makeTranslate('Ns cannot be blank.'),
  l.makeTranslate("Content required and can't be removed. External link."),
  l.makeTranslate('Integration for page not found'),
  l.makeTranslate('Page Name cannot be blank.'),
  l.makeTranslate('Access denied'),
  l.makeTranslate('user_id required, user_id must be int'),
  l.makeTranslate('Cannot force sending this post'),
  l.makeTranslate('Cloning failed.'),
  l.makeTranslate("This subscriber doesn't have Instagram optin"),
  l.makeTranslate('Timezone required'),
  l.makeTranslate('Code parameter required'),
  l.makeTranslate('You can add only one External Trigger to the Automation'),
  l.makeTranslate('Account not found'),
  l.makeTranslate('Your billing request is already processing. Please wait for a while.'),
  l.makeTranslate('Please create or select an existing content to process'),
  l.makeTranslate('Empty timezone'),
  l.makeTranslate('Wrong value for `timezone`'),
  l.makeTranslate('Shop already installed'),
  l.makeTranslate('Value required'),
  l.makeTranslate('Action was not set'),
  l.makeTranslate('Link has expired. Please try again'),
  l.makeTranslate('Identity was not set'),
  l.makeTranslate('Campaign with this code is already exist'),
  l.makeTranslate('Wrong filter'),
  l.makeTranslate('Integration was skipped after cloning'),
  l.makeTranslate('Invalid formula'),
  l.makeTranslate('Wrong or already used coupon provided.'),
  l.makeTranslate('To create a subscriber you must fill in at least one field: Phone or Email'),
  l.makeTranslate('Your card is expired. Please, try again using another card.'),
  l.makeTranslate('Cannot process external request: Invalid payload json'),
  l.makeTranslate('Wrong sending time settings limits'),
  l.makeTranslate('Binding must be set'),
  l.makeTranslate(
    'Sorry, we cannot copy account right now. Please contact our friendly support to proceed.',
  ),
  l.makeTranslate('Value field_id not specified'),
  l.makeTranslate('Tag id required'),
  l.makeTranslate('Buttons can be used in the last block only'),
  l.makeTranslate('Value not specified'),
  l.makeTranslate('Wrong hook'),
  l.makeTranslate('Can not send message for subscribers without Opt-in for SMS'),
  l.makeTranslate(
    'Sorry, for technical reasons, some segments cannot be processed, we are working on this issue',
  ),
  l.makeTranslate("Your card's CVC number is incorrect. Please, try again using the correct CVC."),
  l.makeTranslate('Content Missing'),
  l.makeTranslate('Id cannot be blank.'),
  l.makeTranslate(
    'Sorry, the phone number must start with a country code and contain from 7 to 13 digits',
  ),
  l.makeTranslate('Cannot update reason'),
  l.makeTranslate('Wrong Time Limits'),
  l.makeTranslate('Trigger rule not found'),
  l.makeTranslate('Wrong sequence message'),
  l.makeTranslate('Export must be requested before download'),
  l.makeTranslate('Time value in the left field should be fewer than in the right field'),
  l.makeTranslate('Wrong sequence'),
  l.makeTranslate(
    "You aren't able to copy this growth tool on the current plan because connected Automation has PRO elements. Upgrade for unlimited access.",
  ),
  l.makeTranslate("Page doesn't have ads permissions"),
  l.makeTranslate('Wrong Automation'),
  l.makeTranslate('Permission denied'),
  l.makeTranslate('You already have a keyword with the same value.'),
  l.makeTranslate('Visitor conversation has expired.'),
  l.makeTranslate('Field not found: {field}'),
  l.makeTranslate('Question id {question_id} does not exist'),
  l.makeTranslate('Target Automation does not exists'),
  l.makeTranslate('Buy button can use only with connected Stripe or PayPal account'),
  l.makeTranslate(
    "Cannot create Ad. Invalid parameter. You can't edit this ad because it's part of a scheduled ad set that has reached its end date. Please go to the Budget & Schedule section of the ad set and change the end date to a date in the future and then try your edits again.",
  ),
  l.makeTranslate('Integration for page is already exists'),
  l.makeTranslate('Please connect the Facebook channel to use this type of Widget'),
  l.makeTranslate('Delay time must be set'),
  l.makeTranslate('This Automation with dependencies cannot be restored'),
  l.makeTranslate('Field data is invalid'),
  l.makeTranslate('Email cannot be blank.'),
  l.makeTranslate('Can not disable Conversation Starters.'),
  l.makeTranslate(
    'Cannot publish Creative. Permissions error. Either the object you are trying to access is not visible to you or the action you are trying to take is restricted to certain account types.',
  ),
  l.makeTranslate('Upload failed'),
  l.makeTranslate(
    'The email address has an incorrect format and cannot be saved. Please enter the full address including \u201c@\u201d. For example: address@manychat.com',
  ),
  l.makeTranslate('Spreadsheet not found'),
  l.makeTranslate('Please refresh permissions to be able to fetch Facebook posts'),
  l.makeTranslate('Only PRO accounts can use smart_delay node'),
  l.makeTranslate('Google error.'),
  l.makeTranslate(
    'Legal text at the bottom of widget should be bright and legible. Your subscriber must see and understand that by subscribing he is agreeing to receive personal and marketing text messages from you.',
  ),
  l.makeTranslate('Button Title must not contain emojis'),
  l.makeTranslate('Reason does not exist'),
  l.makeTranslate('Image is too small'),
  l.makeTranslate('Invalid event filter type for event'),
  l.makeTranslate(
    'Permissions for the page had changed on facebook side. Refresh this page to see updated access requests',
  ),
  l.makeTranslate('Lookup column must be set'),
  l.makeTranslate('Account user is not Facebook admin on this page'),
  l.makeTranslate('Item value not set'),
  l.makeTranslate('Ad Set Draft Id cannot be blank.'),
  l.makeTranslate('Mapping must be set'),
  l.makeTranslate('Wrong code provided'),
  l.makeTranslate('Campaign not found'),
  l.makeTranslate('Trial connection is unavailable'),
  l.makeTranslate('Template not found.'),
  l.makeTranslate('Select an attachment for the image block in Email Design'),
  l.makeTranslate(
    "Something went wrong on the Facebook side and we can't update this LiveChat Personas. Please try again later.",
  ),
  l.makeTranslate('Time value in the left field should be fewer than in the right field.'),
  l.makeTranslate('Invalid filter data'),
  l.makeTranslate('Token cannot be blank.'),
  l.makeTranslate('Skip button text cannot consist of spaces only'),
  l.makeTranslate("You don't have ads permissions"),
  l.makeTranslate('Content migration required'),
  l.makeTranslate(
    "The website URL was not authorized by Facebook as the content does not meet Facebook's Community Standards.",
  ),
  l.makeTranslate('At least one channel must be selected in Notify Admin action'),
  l.makeTranslate('You must add at least one block'),
  l.makeTranslate('Segment not found'),
  l.makeTranslate('Can not send message for subscribers without Opt-in for Instagram'),
  l.makeTranslate('Wrong type'),
  l.makeTranslate('page_id required'),
  l.makeTranslate('Undefined reason'),
  l.makeTranslate('Wrong field value'),
  l.makeTranslate('Caption cannot be blank.'),
  l.makeTranslate('Automation is not editable'),
  l.makeTranslate('Wrong event'),
  l.makeTranslate("Can't be null"),
  l.makeTranslate('Wrong API token.'),
  l.makeTranslate('MessageText without attachment data'),
  l.makeTranslate('Too many confirmation requests. Please, try again in 24 hours.'),
  l.makeTranslate("Can't move folder into same path"),
  l.makeTranslate('Please select a valid email address'),
  l.makeTranslate('Billing is not available at the moment'),
  l.makeTranslate('Cannot remove post. Post already deleted.'),
  l.makeTranslate('Wrong user'),
  l.makeTranslate('Application settings not found'),
  l.makeTranslate('Tag not found'),
  l.makeTranslate(
    'Something went wrong with your card. Please, check your bank payment history or try again using another card.',
  ),
  l.makeTranslate('Time values in text fields should not be equal.'),
  l.makeTranslate('Folder not found'),
  l.makeTranslate('Only PRO accounts can use default node'),
  l.makeTranslate('Facebook Page ID should be connected to this account'),
  l.makeTranslate('Domain is invalid'),
  l.makeTranslate('Agency not found'),
  l.makeTranslate('Wrong zapier event'),
  l.makeTranslate(
    'Sorry, we cannot renew the subscription right now. Please contact our friendly support to proceed.',
  ),
  l.makeTranslate('Ads growth tool does not exist'),
  l.makeTranslate(
    'Sorry, we cannot process request right now. Please contact our friendly support to proceed.',
  ),
  l.makeTranslate('The application used in this Automation is not installed'),
  l.makeTranslate('Cannot unschedule this post'),
  l.makeTranslate("Account can't be set as favorite while hidden"),
  l.makeTranslate("You don't have access to interact with this contact's thread"),
  l.makeTranslate('SMS channel is disabled until approval of the registration by Twilio'),
  l.makeTranslate('You can add only one External Trigger to the flow'),
  l.makeTranslate(
    'You cannot use this ad because it is already linked to another active automation',
  ),
  l.makeTranslate('You need to connect Messenger channel first to link these ads with automation'),
  l.makeTranslate('You need to connect WhatsApp channel first to link these ads with automation'),
  l.makeTranslate('You need to choose an ad before turning on this trigger'),
  l.makeTranslate('Invalid AD promotion type'),
  l.makeTranslate('You need to connect Messenger channel first to link these ads with automation'),
  l.makeTranslate('Invalid Facebook page ID'),
  l.makeTranslate('You need to connect WhatsApp channel to automate WhatsApp AD'),
  l.makeTranslate('Invalid WhatsApp business ID'),
  l.makeTranslate('Invalid messaging app type. You can automate only Messenger and WhatsApp'),
  l.makeTranslate(
    'You cannot use this ad because it is already linked to another active automation',
  ),
  l.makeTranslate('You can update only inactive trigger'),
].reduce(
  (result, item) => result.set(item.message, item),
  new Map<string, ReturnType<typeof l.makeTranslate>>(),
)

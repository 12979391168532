import { MappedIceBreaker, IceBreakerChannelType } from 'apps/iceBreakers/constants/constants'
import { getIceBreakersConfig } from 'apps/iceBreakers/iceBreakersConfig'
import { ChannelType } from 'common/core/constants/ChannelType'
import { isIceBreakerChannel } from 'shared/api/requests/iceBreakers/lib'

export const iceBreakersSortComparator = (
  firstIceBreaker: MappedIceBreaker,
  secondIceBreaker: MappedIceBreaker,
) => firstIceBreaker.sort_id - secondIceBreaker.sort_id

export const getIceBreakersRoute = (channel: IceBreakerChannelType = ChannelType.FB) => {
  const iceBreakersConfig = getIceBreakersConfig(channel)
  return iceBreakersConfig.route
}

export const getIceBreakersHeader = (channel: IceBreakerChannelType) => {
  const iceBreakersConfig = getIceBreakersConfig(channel)
  return iceBreakersConfig.header
}

export const getTypedChannel = (channel?: string) => {
  if (!isIceBreakerChannel(channel)) return ChannelType.FB
  return getIceBreakersConfig(channel).channel
}

import React, { useEffect, useCallback, useState, useRef, useMemo } from 'react'
import { useAppSelector } from 'reduxTyped'
import { l, Loader } from '@manychat/manyui'

import { useFlexibleTriggerNameClassName } from 'apps/cms/components/Flows/components/FlowCard/components/Triggers/hooks/useFlexibleTriggerNameClassName'
import { renderTextByNodes } from 'apps/cms/components/Flows/components/FlowCard/components/Triggers/lib/renderTextByNodes'
import { contentManagementSelectors } from 'apps/cms/store'
import { StorySelectType } from 'apps/instagram/models/interfaces'
import { handleCatch } from 'shared/api/lib/errors/handlers'
import { StoryReplyWidget } from 'shared/api/requests/cms/schemas/widgets'
import { InstagramApi } from 'shared/api/requests/instagram'

import { Chip } from '../Chip/Chip'

import cm from './StoryReplyTitle.module.css'

interface StoryReplyTitleProps {
  widget: StoryReplyWidget
}

export const StoryReplyTitle = ({ widget }: StoryReplyTitleProps) => {
  const hasSearchValue = useAppSelector(contentManagementSelectors.getHasSearchValue)

  const titleRef = useRef<HTMLSpanElement | null>(null)

  const [isLoading, setIsLoading] = useState(false)
  const [storyUrl, setStoryUrl] = useState<string | null>(null)

  const fetchStoryData = useCallback(async () => {
    if (!widget.data.story_id) {
      return
    }

    setIsLoading(true)

    try {
      const {
        data: { story },
      } = await InstagramApi.getStory({
        path: { storyId: widget.data.story_id },
      })

      if (story && story.media_url) {
        setStoryUrl(story.media_url)
      }
    } catch (error) {
      handleCatch(error)
    }

    setIsLoading(false)
  }, [widget.data.story_id])

  useEffect(() => {
    if (!widget.data.story_id || !titleRef.current) {
      return
    }

    const observer = new IntersectionObserver(
      (entries, observer) => {
        if (entries[0].isIntersecting) {
          fetchStoryData()
          observer.disconnect()
        }
      },
      {
        threshold: 1.0,
      },
    )

    observer.observe(titleRef.current)
  }, [fetchStoryData, widget.data.story_id])

  const preview = useMemo(
    () => (
      <>
        {!storyUrl && isLoading && <Loader className={cm.preview} size={16} />}

        {storyUrl && <img className={cm.preview} width={16} height={16} src={storyUrl} />}
      </>
    ),
    [isLoading, storyUrl],
  )

  const keywords = useMemo(
    () => widget.data.keywords.map((keyword, index) => <Chip label={keyword} key={index} />),
    [widget.data.keywords],
  )

  const className = useFlexibleTriggerNameClassName()

  const widgetName = hasSearchValue ? renderTextByNodes(`${widget.name}  •`) : ''

  if (widget.data.story_select === StorySelectType.ALL && widget.data.keywords.length === 0) {
    return (
      <span className={className}>
        {widgetName}

        {renderTextByNodes(l.translate('User replies on any Story'))}
      </span>
    )
  }

  if (widget.data.story_select === StorySelectType.ALL && widget.data.keywords.length > 0) {
    return (
      <span className={className}>
        {widgetName}

        {renderTextByNodes(l.translate('User replies on any Story and message contains'))}

        {keywords}
      </span>
    )
  }

  if (widget.data.story_select === StorySelectType.SPECIFIC && widget.data.keywords.length === 0) {
    return (
      <span className={className} ref={titleRef}>
        {widgetName}

        {renderTextByNodes(l.translate('User replies on<l10n context="specific Story"/>'))}

        {preview}

        {renderTextByNodes(l.translate('<l10n context="User replies on"/>specific Story'))}
      </span>
    )
  }

  if (widget.data.story_select === StorySelectType.SPECIFIC && widget.data.keywords.length > 0) {
    return (
      <span className={className} ref={titleRef}>
        {widgetName}

        {renderTextByNodes(l.translate('User replies on<l10n context="specific Story"/>'))}

        {preview}

        {renderTextByNodes(
          l.translate('<l10n context="User replies on"/>specific Story and message contains'),
        )}

        {keywords}
      </span>
    )
  }

  return <span className={className}>{renderTextByNodes(widget.name)}</span>
}

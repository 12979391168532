enum BackendButtonType {
  CONTENT = 'content',
  LOCATION = 'location',
  URL = 'url',
  CTA_URL = 'cta_url', // wa url button
  SHOP_URL = 'shop_url',
  CALL = 'call',
  SHARE = 'share',
  ANSWER = 'answer',
  GOTO_CONTENT = 'goto_content',
  CONTINUE_CONTENT = 'continue_content',
  NESTED = 'nested',
  BUY = 'buy',
  FLOW = 'flow',
  OTN_NOTIFY_ME = 'otn_notify_me',
  WA_LIST_MESSAGE_BUTTON = 'wa_list_message_button',
  CROSS_CHANNEL_CONTENT = 'cross_channel_content',
}

export default BackendButtonType

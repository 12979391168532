import isEmptyLodash from 'lodash/isEmpty'
import isEqual from 'lodash/isEqual'
import pick from 'lodash/pick'
import { l } from '@manychat/manyui'

import {
  QuestionBlockReplyLabel,
  QuestionBlockReplyType,
} from 'common/builder/blocks/blockConstants'
import { IAttachmentBlock, IBlock, IQuestionBlock } from 'common/builder/blocks/blockInterfaces'
import { BlockType } from 'common/builder/constants/BlockType'
import { ButtonType } from 'common/builder/constants/ButtonType'
import { NodeType } from 'common/builder/constants/NodeType'
import {
  AttachmentButtonAllowed,
  BlockCheckEmptyAttributes,
  BlockTypeLabels,
  BlockTypesMaxNested,
  BlockTypesNestingMap,
  ButtonTypesMaxNested,
  ButtonTypesNestingMap,
} from 'common/builder/models/Block/constants'
import { FieldType } from 'common/fields/entities/enums'

import defaults from './defaults'

export const isEmailBlock = (block?: IBlock | null): boolean => {
  if (!block) {
    return false
  }

  return [
    BlockType.EMAIL_ROOT,
    BlockType.EMAIL_TEXT,
    BlockType.EMAIL_IMAGE,
    BlockType.EMAIL_BUTTON,
    BlockType.EMAIL_DIVIDER,
  ].includes(block.type)
}

export function isEmpty(item: IBlock): boolean {
  const attrs = BlockCheckEmptyAttributes[item.type]
  if (!attrs || !attrs.length) {
    return true
  }

  const itemSubset = pick(item, ...attrs)
  const defaultsSubset = pick(defaults, ...attrs)
  return isEqual(itemSubset, defaultsSubset)
}

export function isNestedBlockAllowed(item: IBlock): boolean {
  return Object.keys(BlockTypesNestingMap).includes(item.type)
}

export function isNestedBlockTypeAllowed(item: IBlock, blockType: BlockType) {
  if (!isNestedBlockAllowed(item)) {
    return false
  }
  return BlockTypesNestingMap[item.type]?.includes(blockType)
}

export function isAddBlockAllowed(block: IBlock): boolean {
  if (!isNestedBlockAllowed(block)) {
    return false
  }
  return (BlockTypesMaxNested[block.type] || 0) > (block.blocks?.length || 0)
}

export function getNodeButtonTypesNestingMap(nodeType: NodeType) {
  return { ...ButtonTypesNestingMap.default, ...(ButtonTypesNestingMap[nodeType] || {}) }
}

export function getNodeButtonTypesMaxNestingMap(nodeType: NodeType) {
  const map = { ...ButtonTypesMaxNested.default, ...(ButtonTypesMaxNested[nodeType] || {}) }

  return map
}

export function getMaxNestedButtonsCount(blockType: BlockType, nodeType: NodeType): number {
  return getNodeButtonTypesMaxNestingMap(nodeType)[blockType] || 0
}

export function isNestedButtonAllowed(item: IBlock, nodeType: NodeType): boolean {
  const nestingMap = getNodeButtonTypesNestingMap(nodeType)
  return Object.keys(nestingMap).includes(item.type)
}

export function isNestedButtonTypeAllowed(
  item: IBlock,
  buttonType: ButtonType,
  nodeType: NodeType,
): boolean {
  if (!isNestedButtonAllowed(item, nodeType)) {
    return false
  }
  if (buttonType === null) {
    return true
  }
  const nestingMap = getNodeButtonTypesNestingMap(nodeType)
  return Boolean(nestingMap[item.type]?.includes(buttonType))
}

interface Button {
  id: string
  type: ButtonType
}

export function isAddButtonAllowed(
  block: IBlock,
  nodeType: NodeType,
  buttons: { buttons?: Record<string, Button>; newButtonType?: ButtonType },
): boolean {
  if (!isNestedButtonAllowed(block, nodeType)) {
    return false
  }

  const maxNestedButtonsCount = getMaxNestedButtonsCount(block.type, nodeType)
  if (nodeType !== NodeType.WHATS_APP) {
    return maxNestedButtonsCount > block.buttons.length
  }
  if (buttons.newButtonType === ButtonType.URL) {
    return true
  }
  const waContentButtons = block.buttons.filter(
    (buttonId) => buttons.buttons?.[buttonId]?.type === ButtonType.CONTENT,
  )
  return maxNestedButtonsCount > waContentButtons.length
}

export function getLabel(block: IBlock): string {
  return l.getString(BlockTypeLabels[block.type]) || l.translate('Message')
}

export function isAttachmentButtonAllowed(block: IAttachmentBlock, nodeType: NodeType): boolean {
  const hasAttachment = block.attachment && !isEmptyLodash(block.attachment)
  if (!hasAttachment) {
    return false
  }

  if (!block.attachmentType) {
    return false
  }

  const allowedMap = AttachmentButtonAllowed[nodeType] || AttachmentButtonAllowed.default
  const isAllowed = allowedMap[block.attachmentType]

  return isAllowed
}

// ---------------------QUESTION BEGIN---------------------

export function shouldSavePhoneToSystemField(block: IQuestionBlock): boolean {
  return (
    (block.replyType === QuestionBlockReplyType.PHONE ||
      block.replyType === QuestionBlockReplyType.DEPRECATED_PHONE_FOR_SMS) &&
    block.savePhoneToSystemField
  )
}

export function shouldSavePhoneToWhatsAppIdSystemField(block: IQuestionBlock): boolean {
  return block.replyType === QuestionBlockReplyType.PHONE && block.savePhoneToWhatsAppIdSystemField
}

export function shouldSetSMSOptIn(block: IQuestionBlock): boolean {
  return shouldSavePhoneToSystemField(block) && block.setSmsOptIn
}

export function shouldSaveEmailToSystemField(block: IQuestionBlock): boolean {
  return block.replyType === QuestionBlockReplyType.EMAIL && block.saveEmailToSystemField
}

export function shouldSetEmailOptIn(block: IQuestionBlock): boolean {
  return shouldSaveEmailToSystemField(block) && block.setEmailOptIn
}

export function shouldSaveFirstNameToSystemField(block: IQuestionBlock): boolean {
  return block.replyType === QuestionBlockReplyType.FIRST_NAME
}

export function shouldSaveLastNameToSystemField(block: IQuestionBlock): boolean {
  return block.replyType === QuestionBlockReplyType.LAST_NAME
}

export function canUseQuestionReplies(block: IQuestionBlock): boolean {
  return block.replyType === QuestionBlockReplyType.MULTIPLE_CHOICE
}

export function canUseRetryMessage(block: IQuestionBlock, nodeType?: NodeType): boolean {
  const isWhatsApp = nodeType === NodeType.WHATS_APP
  const noRetryMessage =
    block.replyType === QuestionBlockReplyType.FREE_TEXT ||
    block.replyType === QuestionBlockReplyType.DATE ||
    block.replyType === QuestionBlockReplyType.DATE_TIME ||
    block.replyType === QuestionBlockReplyType.MC_LOCATION ||
    (block.replyType === QuestionBlockReplyType.MULTIPLE_CHOICE && block.allowFreeInput) ||
    (block.replyType === QuestionBlockReplyType.LOCATION && isWhatsApp)
  return !noRetryMessage
}

export function isQuestionWithWebview(block: IQuestionBlock): boolean {
  return (
    block.replyType === QuestionBlockReplyType.DATE ||
    block.replyType === QuestionBlockReplyType.DATE_TIME ||
    block.replyType === QuestionBlockReplyType.MC_LOCATION
  )
}

export function getDefaultRetryCount(nodeType: NodeType): number {
  const isSms = nodeType === NodeType.SMS

  return isSms ? 4 : 4 // Значения временно равны,хотим проветси тест, но скорее всего, по итогу будут отличаться для SMS и FB ноды
}

export function getDefaultRetryMessage(block: IQuestionBlock, nodeType: NodeType) {
  const isSms = nodeType === NodeType.SMS
  const smsAdditional = (isSms && l.translate(`\nSkip response by typing "Skip"`)) || ''

  switch (block.replyType) {
    case QuestionBlockReplyType.MULTIPLE_CHOICE:
      return l.translate('Please select one of the options below.')
    case QuestionBlockReplyType.NUMBER:
      return l.translate('Please enter a number. Use digits only.') + smsAdditional
    case QuestionBlockReplyType.EMAIL:
      return l.translate('Please enter a correct email address, e.g. me@mail.com') + smsAdditional
    case QuestionBlockReplyType.PHONE:
      return (
        l.translate(
          'Your phone number must contain the country and city code and be in the following format: +15417543010',
        ) + smsAdditional
      )
    case QuestionBlockReplyType.URL:
      return l.translate('Please enter a correct URL, e.g. manychat.com') + smsAdditional
    case QuestionBlockReplyType.FILE: {
      if (nodeType === NodeType.CONTENT) {
        return l.translate(
          'Please upload a file using Messenger (just click an icon according to the file format)',
        )
      }
      if (nodeType === NodeType.WHATS_APP) {
        return l.translate(
          'Please upload a file using WhatsApp (just click an icon according to the file format)',
        )
      }
      return l.translate('Please upload a file (just click an icon according to the file format)')
    }
    case QuestionBlockReplyType.IMAGE: {
      if (nodeType === NodeType.CONTENT) {
        return l.translate(
          'Please upload an image using Messenger (just click an icon with mountains and sun)',
        )
      }
      if (nodeType === NodeType.INSTAGRAM) {
        return l.translate(
          'Please upload an image using Instagram (just click an icon with mountains and sun)',
        )
      }
      if (nodeType === NodeType.WHATS_APP) {
        return l.translate(
          'Please upload an image using WhatsApp (click a camera icon and choose a photo)',
        )
      }
      return l.translate('Please upload an image')
    }
    case QuestionBlockReplyType.LOCATION:
      return l.translate('Please use the "Send Location" button to share your location.')
    default:
      return defaults.validationErrorMessage
  }
}

export function getAvailableCUFTypes(replyType: QuestionBlockReplyType): FieldType[] {
  switch (replyType) {
    case QuestionBlockReplyType.LOCATION:
    case QuestionBlockReplyType.FILE:
    case QuestionBlockReplyType.IMAGE:
    case QuestionBlockReplyType.EMAIL:
    case QuestionBlockReplyType.PHONE:
    case QuestionBlockReplyType.URL:
      return [FieldType.TEXT]
    case QuestionBlockReplyType.NUMBER:
      return [FieldType.TEXT, FieldType.NUMBER]
    case QuestionBlockReplyType.DATE:
      return [FieldType.DATE]
    case QuestionBlockReplyType.DATE_TIME:
      return [FieldType.DATETIME]
    default:
      return [FieldType.TEXT]
  }
}

export const getReplyTypeOptions = (nodeType: NodeType) => {
  const firstOptions = [
    QuestionBlockReplyType.FREE_TEXT,
    QuestionBlockReplyType.EMAIL,
    QuestionBlockReplyType.PHONE,
  ]
  if (nodeType === NodeType.SMS) {
    return [
      ...firstOptions,
      QuestionBlockReplyType.NUMBER,
      QuestionBlockReplyType.FIRST_NAME,
      QuestionBlockReplyType.LAST_NAME,

      QuestionBlockReplyType.URL,
    ]
      .filter((type) => type !== QuestionBlockReplyType.DEPRECATED_PHONE_FOR_SMS)
      .map((type) => ({
        value: type,
        label: l.getString(QuestionBlockReplyLabel[type]),
      }))
  }

  if (nodeType === NodeType.INSTAGRAM) {
    return [
      ...firstOptions,
      QuestionBlockReplyType.MULTIPLE_CHOICE,
      QuestionBlockReplyType.NUMBER,
      QuestionBlockReplyType.FIRST_NAME,
      QuestionBlockReplyType.LAST_NAME,
      QuestionBlockReplyType.URL,
      QuestionBlockReplyType.IMAGE,
      QuestionBlockReplyType.DATE,
      QuestionBlockReplyType.DATE_TIME,
    ]
      .filter((type) => type !== QuestionBlockReplyType.DEPRECATED_PHONE_FOR_SMS)
      .map((type) => ({
        value: type,
        label: l.getString(QuestionBlockReplyLabel[type]),
      }))
  }

  if (nodeType === NodeType.WHATS_APP) {
    const replyTypes = [
      ...firstOptions,
      QuestionBlockReplyType.NUMBER,
      QuestionBlockReplyType.URL,
      QuestionBlockReplyType.FIRST_NAME,
      QuestionBlockReplyType.LAST_NAME,
      QuestionBlockReplyType.IMAGE,
      QuestionBlockReplyType.FILE,
      QuestionBlockReplyType.LOCATION,
    ]
    const filteredReplyTypes = replyTypes.map((type) => ({
      value: type,
      label: l.getString(QuestionBlockReplyLabel[type]),
    }))

    return filteredReplyTypes
  }

  if (nodeType === NodeType.TELEGRAM) {
    return [
      ...firstOptions,
      QuestionBlockReplyType.MULTIPLE_CHOICE,
      QuestionBlockReplyType.NUMBER,
      QuestionBlockReplyType.FIRST_NAME,
      QuestionBlockReplyType.LAST_NAME,
      QuestionBlockReplyType.URL,
      QuestionBlockReplyType.DATE,
    ].map((type) => ({
      value: type,
      label: l.getString(QuestionBlockReplyLabel[type]),
    }))
  }

  if (nodeType === NodeType.TIKTOK) {
    return [
      ...firstOptions,
      QuestionBlockReplyType.NUMBER,
      QuestionBlockReplyType.FIRST_NAME,
      QuestionBlockReplyType.LAST_NAME,
      QuestionBlockReplyType.URL,
      QuestionBlockReplyType.IMAGE,
    ].map((type) => ({
      value: type,
      label: l.getString(QuestionBlockReplyLabel[type]),
    }))
  }

  return Object.values(QuestionBlockReplyType)
    .filter((type) => type !== QuestionBlockReplyType.DEPRECATED_PHONE_FOR_SMS)
    .map((type) => ({
      value: type,
      label: l.getString(QuestionBlockReplyLabel[type]),
    }))
    .filter((r) => r.label && r.value !== QuestionBlockReplyType.LOCATION)
}

// ----------------------QUESTION END----------------------

export default {
  isNestedBlockAllowed,
  isNestedBlockTypeAllowed,
  isAddBlockAllowed,
  isNestedButtonAllowed,
  isNestedButtonTypeAllowed,
  isAddButtonAllowed,
}

import React, { useEffect } from 'react'
import cx from 'classnames'
import { useAppSelector, useAppDispatch } from 'reduxTyped'
import { Icon, l } from '@manychat/manyui'

import { localStorage } from 'utils'
import { CmsAnalytics } from 'apps/cms/lib/analytics'
import { ViewDisplayMode, VIEW_DISPLAY_MODE_LS_KEY } from 'apps/cms/lib/constants'
import { contentManagementSelectors, contentManagementActions } from 'apps/cms/store'

import cm from './ViewModeSwitcher.module.css'

const ICON_SIZE = 16

export const ViewModeSwitcher = () => {
  const dispatch = useAppDispatch()

  const displayMode = useAppSelector(contentManagementSelectors.getViewDisplayMode)

  const toggleDisplayMode = () => {
    const newDisplayMode =
      displayMode === ViewDisplayMode.GRID ? ViewDisplayMode.LIST : ViewDisplayMode.GRID

    dispatch(contentManagementActions.setViewDisplayMode(newDisplayMode))
    localStorage.setItem(VIEW_DISPLAY_MODE_LS_KEY, newDisplayMode)
    CmsAnalytics.logToggleDisplayMode({ displayMode: newDisplayMode })
  }

  useEffect(() => {
    const savedValue = localStorage.getItem(VIEW_DISPLAY_MODE_LS_KEY)

    if ([ViewDisplayMode.GRID, ViewDisplayMode.LIST].includes(savedValue)) {
      dispatch(contentManagementActions.setViewDisplayMode(savedValue))
    }
  }, [dispatch])

  return (
    <button onClick={toggleDisplayMode} className={cx('text-sm', cm.viewSwitcher)}>
      {displayMode === ViewDisplayMode.GRID && <Icon.TableView size={ICON_SIZE} />}

      {displayMode === ViewDisplayMode.LIST && <Icon.MosaicView size={ICON_SIZE} />}

      <span>
        {displayMode === ViewDisplayMode.GRID && <span>{l.translate('View as list')}</span>}

        {displayMode === ViewDisplayMode.LIST && <span>{l.translate('View as grid')}</span>}
      </span>
    </button>
  )
}

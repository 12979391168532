import { createSelector } from 'reselect'

import { IFolder } from 'common/core/interfaces/folder'

export const getTagFolders = (state: RootState) => state.tagsFolders

export const getSortedTagFolders = createSelector(getTagFolders, (state) => {
  const sortFolders = (folder1: IFolder, folder2: IFolder) =>
    folder1.title.localeCompare(folder2.title)
  return state?.items?.sort(sortFolders) || []
})

export const getTagFoldersMap = createSelector(getTagFolders, (state) => state.byId || {})

export const isTagFoldersFetching = (state: RootState) => state.tagsFolders.fetching

export const getTagsSelectedIds = createSelector(getTagFolders, (state) => state.selectedIds)

export const getIsFolderNameExists = createSelector(
  [getTagFolders, (_, title: string) => title],
  (state, title) => {
    return state.items?.some((folder) => folder.title === title)
  },
)

import { useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import { useAppDispatch } from 'reduxTyped'

import { removeNotificationsOnNavigate } from 'common/core/actions/uiActions'

export function useRemoveNotificationsOnNavigate() {
  const location = useLocation()
  const dispatch = useAppDispatch()

  useEffect(() => {
    dispatch(removeNotificationsOnNavigate())
  }, [dispatch, location])
}

import { useCallback } from 'react'
import { useAppSelector } from 'reduxTyped'

import { getTelegramProfile } from 'common/profiles/profileSelectors'
import { useCommand } from 'utils/api/query/hooks/useCommand'

import { useTelegramBindingOptIn } from './useTelegramBindingOptIn'

export const useTelegramSignIn = (redirectUrl: string) => {
  const { hasProfile } = useAppSelector(getTelegramProfile)
  const { send, query } = useCommand('telegram-opt-in-url', ({ api }) => api.telegram.getOptInUrl())

  const telegramUrl = query.status === 'success' ? query.data.url : null
  const telegramCode = query.status === 'success' ? query.data.code : null
  const isLoading = query.status === 'loading'

  const getOptInUrl = useCallback(async () => {
    const response = await send()

    if (response?.status !== 'success') {
      return
    }

    return response
  }, [send])

  useTelegramBindingOptIn(hasProfile, redirectUrl, telegramCode)

  return {
    telegramUrl,
    getOptInUrl,
    isLoading,
  }
}

import React from 'react'
import { l } from '@manychat/manyui'

import { MESSAGE_TEMPLATE_PAUSING } from 'apps/whatsApp/core/constants'

import { WhatsAppMessageTemplateDisabledNotificationData } from '../NotifyInterfaces'

import cm from './common.module.css'

interface WAMessageTemplateDisabledNotificationProps {
  data: WhatsAppMessageTemplateDisabledNotificationData
}

export const WAMessageTemplateDisabledNotification = ({
  data,
}: WAMessageTemplateDisabledNotificationProps) => {
  return (
    <>
      <span className="m-r-xxs">
        {l.translate(
          `Message template <span class="{className}">{templateName}</span> has been disabled due to low quality rates.`,
          {
            templateName: data.message_template.name_human,
            className: cm.boldText,
          },
        )}
      </span>
      <a target="_blank" href={MESSAGE_TEMPLATE_PAUSING} rel="noreferrer" className={cm.link}>
        {l.translate('Learn more')}
      </a>
    </>
  )
}

import { RoutePaths } from 'utils/router/constants'
import { isPathnameMatchRoute } from 'utils/router/tools'
import { analyticsService } from 'utils/services/analytics'

const TAGS_METRICS_MAP: Record<string, Record<string, string>> = {
  SETTINGS: {
    route: RoutePaths.SETTINGS,
    event: 'SETTINGS.TAG.CREATE',
  },
  AUDIENCE: {
    route: RoutePaths.AUDIENCE_SCOPE,
    event: 'AUDIENCE.TAG.CREATE',
  },
  CHAT: {
    route: RoutePaths.CHAT,
    event: 'LIVE_CHAT.TAG.CREATE',
  },
  CMS_BUILDER: {
    route: RoutePaths.CMS_BUILDER,
    event: 'FLOW.TAG.CREATE',
  },
}

export const logTagCreation = () => {
  const currentRouteKey = Object.keys(TAGS_METRICS_MAP).find((routeKey) =>
    isPathnameMatchRoute(TAGS_METRICS_MAP[routeKey]?.route),
  )

  if (currentRouteKey && TAGS_METRICS_MAP[currentRouteKey]) {
    analyticsService.sendEvent(TAGS_METRICS_MAP[currentRouteKey].event)
  }
}

import { ReactNode } from 'react'

import { TagWithChildren } from './types'

export class TagStack {
  private stack: TagWithChildren[] = [
    { name: '', type: 'opening', input: '', attributes: [], children: [] },
  ]

  private getLastIndex() {
    return this.stack.length - 1
  }

  push(item: TagWithChildren) {
    this.stack.push(item)
  }

  pop(): TagWithChildren {
    const tag = this.stack.pop()
    if (!tag) {
      throw new Error('No tags in stack')
    }
    return tag
  }

  addChildToLastElement = (child: ReactNode) => {
    this.stack[this.getLastIndex()].children.push(child)
  }

  getLastIndexFor(name: string) {
    return this.stack.map((tag) => tag.name).lastIndexOf(name)
  }

  closeTagsAfter(index: number) {
    let currentIndex = this.getLastIndex()
    while (currentIndex > index) {
      const current = this.pop()
      const parent = this.stack[this.getLastIndex()]
      parent?.children.push(current.input, ...current.children)
      currentIndex--
    }
  }
}

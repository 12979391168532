import atypes from 'common/actions/constants/ReduxActionTypes'

const initialState = { items: null, fetching: false, error: null }

export default function zapsReducer(state = initialState, action) {
  switch (action.type) {
    case atypes.INTEGROMAT_WEBHOOKS_FETCH_REQUEST: {
      return { ...state, fetching: true, error: null }
    }
    case atypes.INTEGROMAT_WEBHOOKS_FETCH_RESPONSE: {
      const { data } = action
      return { ...state, items: data.hooks, fetching: false, error: null }
    }
    case atypes.INTEGROMAT_WEBHOOKS_FETCH_ERROR: {
      const { error } = action
      return { ...state, items: null, fetching: false, error }
    }

    case atypes.INTEGROMAT_WEBHOOK_CREATED_NOTIFICATION: {
      if (state.items == null) {
        break
      }
      const index = state.items.findIndex((i) => i.hook_id === action.webhook.hook_id)
      if (index < 0) {
        return { ...state, items: [...state.items, action.webhook] }
      }
      break
    }

    case atypes.INTEGROMAT_WEBHOOK_REMOVED_NOTIFICATION: {
      if (state.items == null) {
        break
      }
      return {
        ...state,
        items: state.items.filter((i) => String(i.hook_id) !== String(action.webhook.hook_id)),
      }
    }
  }

  return state
}

import React from 'react'
import type { FallbackProps } from 'react-error-boundary'
import { l } from '@manychat/manyui'

import { FallbackContent } from 'common/core/components/ErrorBoundary/FallbackContent'
import { linkURL } from 'utils/url'

export const Fallback = ({ resetErrorBoundary }: FallbackProps) => {
  const firstContentText = l.translate(
    'We are notified about your trouble and we’ll solve it as soon as possible.',
  )
  const secondContentText = l.translate(
    `Try to <a onclick="onClick" class="text-primary">refresh</a> this page or reopen it. If the problem stays, <a href="{href}" class="text-primary" target="_blank" rel="noreferrer" data-test-id="submit-ticket">submit a ticket</a> to our lovely support.`,
    { onClick: resetErrorBoundary, href: linkURL('/support/features') },
  )
  return (
    <FallbackContent
      firstContentText={firstContentText}
      secondContentText={secondContentText}
      hasPaddingIndent
      hasMarginIndent
    />
  )
}

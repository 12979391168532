import { api } from 'shared/api'
import { SequenceSchemas } from 'shared/api/requests/sequence/schemas'

export const SequenceApi = {
  listSequenceNames: api.account.createGet({
    url: '/sequence/list',
    schemas: SequenceSchemas.listSequenceNames,
  }),
  listSequences: api.account.createGet({
    url: '/sequence/listSequences',
    schemas: SequenceSchemas.listSequences,
  }),
  getSequence: api.account.createGet({
    url: '/sequence/getSequence',
    schemas: SequenceSchemas.getSequence,
  }),
  patchSequence: api.account.createPost({
    url: '/sequence/patchSequence',
    schemas: SequenceSchemas.patchSequence,
  }),
  createSequence: api.account.createPost({
    url: '/sequence/createSequence',
    schemas: SequenceSchemas.createSequence,
  }),
  deleteSequence: api.account.createPost({
    url: '/sequence/deleteSequence',
    schemas: SequenceSchemas.deleteSequence,
  }),
  cloneSequence: api.account.createPost({
    url: '/sequence/clone',
    schemas: SequenceSchemas.cloneSequence,
  }),
  reorderSequenceMessages: api.account.createPost({
    url: '/sequence/reorderSequenceMessages',
    schemas: SequenceSchemas.reorderSequenceMessages,
  }),
}

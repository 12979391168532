export const isEllipsisApplied = (element: HTMLElement) => element.offsetWidth < element.scrollWidth

export const isAnyHelperKeyPressed = (event: {
  metaKey: boolean
  altKey: boolean
  shiftKey: boolean
  ctrlKey: boolean
}) => {
  return event.metaKey || event.altKey || event.shiftKey || event.ctrlKey
}

import {
  AnyAction,
  // eslint-disable-next-line no-restricted-imports
  createAsyncThunk,
  createSelector,
  createSlice,
  PayloadAction,
  ThunkAction,
} from '@reduxjs/toolkit'

import { ChannelType } from 'common/core/constants/ChannelType'
import { ThunkExtra } from 'common/core/interfaces/actions'
import { getConnectedChannels } from 'common/core/selectors/getConnectedChannels'
import { FieldGroupId, FieldStatus, FieldType } from 'common/fields/entities/enums'
import { Field } from 'common/fields/entities/field'
import {
  FieldFilter,
  fieldIs,
  fieldNot,
  groupIs,
  groupNot,
  ifAll,
  ifAny,
  makeFilter,
  notArray,
  typeIs,
} from 'common/fields/linking/fieldsFilters'
import { FilterScenario } from 'common/filter/interfaces'
import { FilterField } from 'common/filter/models/AudienceFilter/constants'
import { AutoAssignmentConditionField } from 'common/settings/constants/AutoAssignmentTypes'
import { addFetchingCases } from 'utils/api/redux/reducers'

import { FieldsState, fieldsAdapter, initialState } from './fieldInterfaces'

interface FieldsThunkExtension {
  extra: ThunkExtra
}

type FieldsThunk<T> = ThunkAction<void, T, ThunkExtra, AnyAction>

let scenario: string | undefined

const loadFields = createAsyncThunk<
  Field[],
  { connectedChannels: ChannelType[]; scenario?: FilterScenario },
  FieldsThunkExtension
>('fields/load', ({ connectedChannels, scenario }, { extra }) => {
  return extra.api.fields.getAvailableFields(connectedChannels, scenario)
})

const availableFieldsSlice = createSlice({
  name: 'availableFields',
  initialState,
  reducers: {
    addFields(state, action: PayloadAction<Field[]>) {
      fieldsAdapter.addMany(state, action.payload)
    },
    upsertField(state, action: PayloadAction<Field>) {
      fieldsAdapter.upsertOne(state, action.payload)
    },
    removeField(state, action: PayloadAction<string>) {
      fieldsAdapter.removeOne(state, action.payload)
    },
  },
  extraReducers: (builder) => {
    builder.addCase(loadFields.fulfilled, (state, action: PayloadAction<Field[]>) => {
      fieldsAdapter.setAll(state, action.payload)
      state.initialized = true
    })
    addFetchingCases(builder, loadFields)
  },
})

export const makeAvailableFieldsSlice = <T>(baseSelector: (state: T) => FieldsState) => {
  const initializeAvailableFields =
    (options?: { scenario: FilterScenario }): FieldsThunk<T> =>
    async (dispatch, getState, { api }) => {
      const state = getState()
      const isTheSameScenario = options?.scenario === scenario

      if (getAvailableFieldsInitialized(state) && isTheSameScenario) {
        return
      }

      api.fields.addUpdateListener((field) => {
        if (field.status === FieldStatus.ACTIVE) {
          dispatch(availableFieldsSlice.actions.upsertField(field))
        } else {
          dispatch(availableFieldsSlice.actions.removeField(field.id))
        }
      })
      scenario = options?.scenario
      const connectedChannels = getConnectedChannels(state as unknown as RootState)
      return dispatch(loadFields({ connectedChannels, scenario: options?.scenario }))
    }

  const entitySelectors = fieldsAdapter.getSelectors(baseSelector)

  const getAvailableFieldsInitialized = (state: T): boolean => {
    return baseSelector(state).initialized
  }
  const createSelectorWithFilter = (predicate: FieldFilter) =>
    createSelector(entitySelectors.selectAll, makeFilter(predicate))

  return {
    reducer: availableFieldsSlice.reducer,
    actions: { ...availableFieldsSlice.actions, loadFields, initializeAvailableFields },
    selectors: {
      getAllFields: createSelector(entitySelectors.selectEntities, (fields) => fields),
      getApplicationsFields: createSelectorWithFilter(applicationFilter),
      getAudienceFields: createSelectorWithFilter(audienceFilter),
      getConditionFields: createSelectorWithFilter(conditionFilter),
      getCustomFieldChangedTriggerFields: createSelectorWithFilter(customFieldChangedTriggerFilter),
      getDateTimeTriggerFields: createSelectorWithFilter(dateTimeTriggerFilter),
      getFieldChangedTriggerFields: createSelectorWithFilter(fieldChangedTriggerFilter),
      getIntegrationsMappingFields: createSelectorWithFilter(integrationsMappingFilter),
      getGoogleSheetIntegrationsMappingFields: createSelectorWithFilter(
        googleSheetIntegrationsMappingFilter,
      ),
      getLiveChatFilterFields: createSelectorWithFilter(liveChatFilter),
      getPersonalizationFields: createSelectorWithFilter(personalizationFieldsFilter),
      getPostTargetingFields: createSelectorWithFilter(postTargetingFilter),
      getResponseMappingFields: createSelectorWithFilter(responseMappingFilter),
      getRuleTriggerConditionFields: createSelectorWithFilter(ruleTriggerConditionFilter),
      getAutoAssignmentRuleConditionFilter: createSelectorWithFilter(
        autoAssignmentRuleConditionFilter,
      ),
      getSetBotFieldActionFilter: createSelectorWithFilter(setBotFieldActionFilter),
      getSetUserFieldActionFilter: createSelectorWithFilter(setUserFieldActionFilter),
      getSetFieldActionFields: createSelectorWithFilter(setFieldActionFilter),
      getSetFieldBulkActionFields: createSelectorWithFilter(setFieldBulkActionFilter),
      getSmartSegmentFields: createSelectorWithFilter(smartSegmentsFilter),
      getSetPhoneFields: createSelectorWithFilter(setPhoneFieldFilter),
      getUserFields: createSelectorWithFilter(userFieldsFilter),
      getAiAgentAvailableFields: createSelectorWithFilter(aiAgentFieldsFiler),
    },
  }
}

const personalizationFieldsFilter = ifAll(
  notArray,
  groupNot([FieldGroupId.STATIC]),
  fieldNot(['messaging_window', 'phone_country_code', 'is_eu_affected']),
)

const withoutIgProFields = fieldNot([
  'is_ig_account_follower',
  'ig_followers_count',
  'is_ig_verified_user',
  'is_ig_account_follow_user',
  'ig_username',
])

const setFieldActionFilter = ifAll(notArray, groupIs([FieldGroupId.BOT, FieldGroupId.USER]))
const setUserFieldActionFilter = ifAll(notArray, groupIs([FieldGroupId.USER]))
const setBotFieldActionFilter = ifAll(notArray, groupIs([FieldGroupId.BOT]))
const setFieldBulkActionFilter = ifAll(notArray, groupIs([FieldGroupId.USER]))

const applicationFilter = ifAll(groupNot([FieldGroupId.STATIC]), withoutIgProFields)

const fieldIsEmail = ifAll(groupIs([FieldGroupId.SYSTEM]), fieldIs(['email']))
const fieldIsPhone = ifAll(groupIs([FieldGroupId.SYSTEM]), fieldIs(['phone']))

const fieldChangedTriggerFilter = ifAll(
  notArray,
  ifAny(groupIs([FieldGroupId.INTEGRATION]), fieldIsEmail, fieldIsPhone),
)

const customFieldChangedTriggerFilter = ifAll(notArray, groupIs([FieldGroupId.USER]))

const dateTimeTriggerFilter = ifAll(
  notArray,
  groupIs([FieldGroupId.USER, FieldGroupId.SYSTEM]),
  fieldNot([FilterField.LAST_INPUT_TEXT]),
)

const conditionFilter = ifAll(
  fieldNot([
    FilterField.PAGE_ID,
    FilterField.LAST_INPUT_TEXT,
    FilterField.INSTAGRAM_ACCOUNT_NAME,
    FilterField.TIKTOK_ACCOUNT_USERNAME,
    FilterField.TIKTOK_ACCOUNT_DISPLAY_NAME,
  ]),
)

const liveChatFilter = ifAll(
  notArray,
  conditionFilter,
  groupIs([FieldGroupId.STATIC, FieldGroupId.SYSTEM, FieldGroupId.USER]),
  fieldNot([
    FilterField.CURRENT_DATETIME,
    FilterField.CONVERSATION_CHANNEL,
    FilterField.OPT_IN_THROUGH_API,
    FilterField.OPTIN_EMAIL,
    FilterField.OPTIN_INSTAGRAM,
    FilterField.OPTIN_PHONE,
    FilterField.OPTIN_TELEGRAM,
    FilterField.OPTIN_WHATSAPP,
    FilterField.MESSENGER,
  ]),
)

const targetingFilter = ifAll(
  conditionFilter,
  groupNot([FieldGroupId.BOT]),
  fieldNot(['system_current_datetime', 'active_subscriber', 'conversation_channel']),
  withoutIgProFields,
)

const audienceFilter = targetingFilter
const postTargetingFilter = targetingFilter
const smartSegmentsFilter = ifAll(
  targetingFilter,
  fieldNot(['smart_segment', 'conversation_channel']),
)

const responseMappingFilter = groupIs([FieldGroupId.USER])

const ruleTriggerConditionFilter = ifAll(
  conditionFilter,
  fieldNot(['system_current_datetime', 'conversation_channel']),
)

const setPhoneFieldFilter = ifAll(
  groupNot([FieldGroupId.STATIC, FieldGroupId.INTEGRATION]),
  withoutIgProFields,
)

const userFieldsFilter = ifAll(notArray, groupIs([FieldGroupId.USER]))

const integrationsMappingFilter = ifAll(
  notArray,
  groupIs([FieldGroupId.USER, FieldGroupId.SYSTEM]),
  fieldNot([FilterField.LAST_INPUT_TEXT]),
  withoutIgProFields,
)

const googleSheetIntegrationsMappingFilter = ifAll(
  notArray,
  groupIs([FieldGroupId.SYSTEM, FieldGroupId.BOT, FieldGroupId.USER]),
  fieldNot([FilterField.LAST_INPUT_TEXT]),
)

const autoAssignmentRuleConditionFilter = ifAny(
  fieldIs(Object.values(AutoAssignmentConditionField)),
  groupIs([FieldGroupId.USER]),
)

const aiAgentFieldsFiler = ifAny(
  ifAll(groupIs([FieldGroupId.SYSTEM]), ifAny(fieldIsEmail, fieldIsPhone)),
  ifAll(
    groupIs([FieldGroupId.USER]),
    typeIs([
      FieldType.TEXT,
      FieldType.NUMBER,
      FieldType.DATE,
      FieldType.DATETIME,
      FieldType.BOOLEAN,
    ]),
  ),
)

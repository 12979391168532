import dot from 'dot-prop-immutable'
import has from 'lodash/has'
import keyBy from 'lodash/keyBy'

import PostModel from 'apps/posting/models/Post'
import { ListName } from 'apps/posting/models/Post/constants'

import * as atypes from '../constants/postingReduxActionTypes'

const initialState = {
  byId: {},
  [ListName.DRAFTS]: { limiter: null, fetched: false, total: null, loading: false, error: null },
  [ListName.SCHEDULED]: { limiter: null, fetched: false, total: null, loading: false, error: null },
  [ListName.HISTORY]: { limiter: null, fetched: false, total: null, loading: false, error: null },
}

export default function postsReducer(state = initialState, action) {
  switch (action.type) {
    case atypes.POST_LIST_LOAD_REQUEST: {
      const { listName } = action

      if (!listName) {
        return state
      }
      return dot.merge(state, listName, { loading: true, error: null })
    }
    case atypes.POST_LIST_LOAD_SUCCESS: {
      const { items, listName } = action
      state = dot.merge(state, `byId`, keyBy(items, 'id'))

      if (!listName) {
        return state
      }
      const { limiter, total } = action
      return dot.merge(state, listName, {
        loading: false,
        fetched: true,
        error: null,
        limiter,
        total,
      })
    }
    case atypes.POST_LIST_LOAD_ERROR: {
      const { listName, error } = action
      if (!listName) {
        return state
      }
      return dot.merge(state, listName, { loading: false, error })
    }
    case atypes.POST_LOAD_SUCCESS: {
      const { item } = action

      // tracking the total counter
      const exists = Boolean(state.byId[item.id])
      const listName = PostModel.getListNameByStatus(item.status)
      if (!exists && listName) {
        const total = dot.get(state, `${listName}.total`)
        state = dot.set(state, `${listName}.total`, total + 1)
      }

      return dot.merge(state, `byId`, { [item.id]: item })
    }
    case atypes.POST_UPDATE_SUCCESS: {
      const { postId, changes } = action
      const item = dot.get(state, `byId.${postId}`)

      if (!item) {
        return state
      }

      // tracking the total counter
      if (has(changes, 'status') && item.status !== changes.status) {
        const listNameBefore = PostModel.getListNameByStatus(item.status)
        const listNameAfter = PostModel.getListNameByStatus(changes.status)
        if (listNameBefore !== listNameAfter) {
          if (listNameBefore) {
            const total = dot.get(state, `${listNameBefore}.total`)
            state = dot.set(state, `${listNameBefore}.total`, total - 1)
          }
          if (listNameAfter) {
            const total = dot.get(state, `${listNameAfter}.total`)
            state = dot.set(state, `${listNameAfter}.total`, total + 1)
          }
        }
      }

      return dot.merge(state, `byId.${postId}`, changes)
    }
  }

  return state
}

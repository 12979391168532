import { ReplaceResult } from 'components/MCTextEditor/dataFormat/parse/mapReduceParser/types'
import { SliceType } from 'components/MCTextEditor/slice/types'
import { IVariableData } from 'components/MCTextEditor/types'
import { createOptionFilter } from 'utils/createOptionFilter'

import { encloseWithMarks } from '../dataFormat/parse/utils/marks'
import { ReplaceRule } from '../dataFormat/parse/utils/replace'
import { ILabelSelector } from '../MCEditorContextInterfaces'

const PREFIX = '{{'
const POSTFIX = '}}'

export const VariablePattern = `{{[^\n{}]{1,}}}`

export const VariableFilter = createOptionFilter(/[\s_:'‘’-]/)

export function parseVariableString(input: string): IVariableData | null {
  if (!input.startsWith(PREFIX) || !input.endsWith(POSTFIX)) {
    return null
  }

  input = input.slice(PREFIX.length, -POSTFIX.length)
  const [idString, ...dataItems] = input.split('|')
  const result: IVariableData = { id: idString.trim() }

  for (const item of dataItems) {
    const [nameString, valueString] = item.split(/:(.+)/)
    if (!nameString || !valueString) {
      continue
    }

    const name = nameString.trim()
    const value = valueString.trim()

    switch (name) {
      case 'fallback':
        result.fallback = value.slice(1, -1)
        break
      case 'to_json':
        result.to_json = value === 'true'
        break
      case 'format':
        result.format = value
        break
    }
  }

  return result
}

export const exportVariable = (data: IVariableData): string => {
  const { id, fallback, format, to_json } = data
  const fallbackString = typeof fallback !== 'undefined' ? `|fallback:"${fallback}"` : ''
  const formatString = typeof format === 'string' && format ? `|format:${format}` : ''
  const to_jsonString = typeof to_json !== 'undefined' ? `|to_json:${to_json}` : ''
  return `{{${id}${fallbackString}${to_jsonString}${formatString}}}`
}

export const makeVariableReplacer =
  (
    getLabelById: ILabelSelector,
    getPreviewValue?: (data: IVariableData) => string | null,
  ): ReplaceRule<ReplaceResult> =>
  (input) => {
    const data = parseVariableString(input)
    if (!data) {
      return undefined
    }
    const previewValue = getPreviewValue?.(data)
    const label = previewValue || `{${getLabelById(data.id)}}`
    return encloseWithMarks(label, SliceType.VARIABLE, data)
  }

import { createSelector } from 'reselect'

export const targetPageSelector = (state: RootState) => state.targetPage

export const botFieldsSelector = createSelector(
  targetPageSelector,
  ({ globalFields }) => globalFields.items || [],
)
export const userFieldsSelector = createSelector(
  targetPageSelector,
  ({ userFields }) => userFields.items || [],
)

export const userCustomEventsSelector = createSelector(
  targetPageSelector,
  ({ customEvents }) => customEvents.items || [],
)

import { createSlice } from '@reduxjs/toolkit'

import { HowToCreateTopicsModalSliceState } from 'common/oneTimeNotify/components/HowToCreateTopicsModal/slices/interfaces'

const initialState: HowToCreateTopicsModalSliceState = {
  isModalOpen: false,
  isNeedBack: false,
  isTemplateCta: false,
}

const HowToCreateTopicsModalSlice = createSlice({
  name: 'howToCreateTopicsModal',
  initialState,
  reducers: {
    openModal(state: HowToCreateTopicsModalSliceState) {
      state.isModalOpen = true
      state.isNeedBack = false
    },
    openModalWithBackButton(state: HowToCreateTopicsModalSliceState) {
      state.isModalOpen = true
      state.isNeedBack = true
    },
    openModalWithTemplateCta(state) {
      state.isModalOpen = true
      state.isNeedBack = false
      state.isTemplateCta = true
    },
    closeModal(state: HowToCreateTopicsModalSliceState) {
      state.isModalOpen = false
      state.isNeedBack = false
      state.isTemplateCta = false
    },
  },
})

export const { openModal, closeModal, openModalWithBackButton, openModalWithTemplateCta } =
  HowToCreateTopicsModalSlice.actions

export default HowToCreateTopicsModalSlice.reducer

export const getIsModalOpen = (state: RootState): boolean =>
  state.howToCreateTopicsModal.isModalOpen
export const getIsNeedBack = (state: RootState): boolean => state.howToCreateTopicsModal.isNeedBack
export const getIsTemplateCta = (state: RootState): boolean =>
  state.howToCreateTopicsModal.isTemplateCta

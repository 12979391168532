import { CardTrigger } from 'apps/cms/components/Flows/components/FlowCard/components/Triggers/lib/types'
import { TriggerType } from 'common/flow/constants/TriggerType'
import { linkURL } from 'utils/url'

const getCorrectTriggerIdPreffix = (trigger: CardTrigger) => {
  switch (trigger.type) {
    case TriggerType.WIDGET:
      return `widget-${trigger.id}`
    case TriggerType.RULE:
      return `rule-${trigger.id}`
    case TriggerType.AI_INTENT:
      return `ai_intent-${trigger.id}`
    case TriggerType.KEYWORD:
      return `keyword-${trigger.id}`
    case TriggerType.EXTERNAL_TRIGGER:
      return `externalTrigger-${trigger.id}`
    default:
      return trigger.id
  }
}

export const createFlowRedirectUrl = ({
  flowId,
  trigger,
  queryPath,
}: {
  flowId: string
  trigger: CardTrigger
  queryPath: string
}) => {
  const triggerIdWithPreffix = getCorrectTriggerIdPreffix(trigger)
  let url = linkURL(`/cms/files/${flowId}/edit?preset=openTrigger-${triggerIdWithPreffix}`)

  if (queryPath !== '/') {
    const pathSegments = queryPath.split('/').filter((pathSegment) => pathSegment.length > 0)

    pathSegments.push(flowId ?? '')

    url = linkURL(
      `/cms/files/${pathSegments.join('--')}/edit?preset=openTrigger-${triggerIdWithPreffix}`,
    )
  }

  return url
}

import { z } from 'zod'

import { TimeSettingsType } from 'common/sequences/components/SequenceDetail/Item/SettingsPicker/utils'
import { lightFlowSchema } from 'shared/api/common/schemas/lightFlow'

export enum WeekDays {
  MONDAY = 'monday',
  TUESDAY = 'tuesday',
  WEDNESDAY = 'wednesday',
  THURSDAY = 'thursday',
  FRIDAY = 'friday',
  SATURDAY = 'saturday',
  SUNDAY = 'sunday',
}

export const SequenceSchema = z.object({
  sequence_id: z.number(),
  ts_added: z.number(),
  ts_last_sent: z.number(),
  ts_next_sending: z.number().or(z.boolean()),
  sent_sequence_messages: z.number(),
  sequence: z.object({
    sequence_id: z.number(),
    name: z.string(),
    status: z.string(),
    subscribers: z.number(),
    messages: z.number(),
    template_installation_id: z.number().nullable(),
  }),
})

const SequenceNameResponse = z.object({
  sequence_id: z.number(),
  name: z.string(),
  ts_created: z.number(),
  ts_updated: z.number(),
})

export const SequenceStats = z
  .object({
    sent: z.number(),
    read: z.number().nullable(),
    clicked: z.number().nullable(),
    delivered: z.number().nullable(),
  })
  .nullable()

export const SequenceResponse = SequenceNameResponse.extend({
  subscribers: z.number(),
  messages: z.number(),
  template_installation_id: z.number().nullable(),
})

const SequenceListResponse = z.object({
  sequences: z.array(
    SequenceResponse.extend({
      stats: SequenceStats,
      template_installation_access_data: z
        .object({
          has_user_access: z.boolean(),
          is_locked: z.boolean(),
          is_protected: z.boolean(),
          is_disabled: z.boolean(),
          is_owner: z.boolean(),
        })
        .optional(),
    }),
  ),
})

export const SendingTimeSettings = z.object({
  type: z.nativeEnum(TimeSettingsType),
  from: z.number().nullable().optional(),
  to: z.number().nullable().optional(),
  weekdays: z.array(z.nativeEnum(WeekDays)).nullable().optional(),
})

export const SequenceMessage = z.object({
  sequence_message_id: z.number(),
  sequence_id: z.number(),
  namespace: z.string().nullable(),
  flow: lightFlowSchema.nullable(),
  content_holder: z.string(),
  sort: z.number(),
  delay_seconds: z.number(),
  status: z.string(),
  ts_created: z.number(),
  ts_updated: z.number(),
  stats: SequenceStats,
  notification: z.string(),
  sending_time_settings: SendingTimeSettings,
})

const SingleSequenceResponse = z.object({
  sequence: SequenceResponse.extend({
    sequence_messages: z.array(SequenceMessage),
  }),
})

const SequencePayload = z.object({
  client_id: z.string().optional(),
  name: z.string().optional(),
})

const PatchSequencePayload = SequencePayload.extend({
  sequence_id: z.union([z.string(), z.number()]),
})

const CloneSequencePayload = z.object({
  sequence_id: z.union([z.string(), z.number()]),
  client_id: z.string().optional(),
})

const ReorderSequenceMessagesPayload = z.object({
  sequence_id: z.union([z.string(), z.number()]),
  ids: z.array(z.number()),
})

export const SequenceSchemas = {
  listSequenceNames: {
    response: z.object({
      sequences: z.array(SequenceNameResponse),
    }),
    query: z.undefined(),
    path: z.undefined(),
  },

  listSequences: {
    response: SequenceListResponse,
    query: z.undefined(),
    path: z.undefined(),
  },

  getSequence: {
    request: z.undefined(),
    response: SingleSequenceResponse,
    query: z.object({
      sequence_id: z.string(),
    }),
    path: z.undefined(),
  },

  patchSequence: {
    request: PatchSequencePayload,
    response: SingleSequenceResponse,
    query: z.undefined(),
    path: z.undefined(),
  },

  createSequence: {
    request: SequencePayload,
    response: SingleSequenceResponse,
    query: z.undefined(),
    path: z.undefined(),
  },

  deleteSequence: {
    request: CloneSequencePayload,
    response: z.undefined(),
    query: z.undefined(),
    path: z.undefined(),
  },

  cloneSequence: {
    request: CloneSequencePayload,
    response: SingleSequenceResponse,
    query: z.undefined(),
    path: z.undefined(),
  },

  reorderSequenceMessages: {
    request: ReorderSequenceMessagesPayload,
    response: SingleSequenceResponse,
    query: z.undefined(),
    path: z.undefined(),
  },
}

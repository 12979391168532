import { generateFlowBody } from 'apps/aiIntentsWizard/utils/index'
import { ChannelType } from 'common/core/constants/ChannelType'
import * as API from 'constants/API'
import { AiIntentsApi } from 'shared/api/requests/aiIntents'
import { AiIntentStatus } from 'shared/api/requests/aiIntents/schemas'
import { CmsApi } from 'shared/api/requests/cms'
import mcApi from 'utils/api/mcApi'

export const retry = async <T>(callback: () => Promise<T>): Promise<T> => {
  let attempt = 0
  const maxRetries = 2
  while (attempt < maxRetries) {
    try {
      return await callback()
    } catch (error) {
      attempt++
    }
  }
  throw new Error('Server is unavailable')
}

export const createFlow = async (intent: string, path: string) => {
  return await retry(async () => {
    const {
      data: { flow },
    } = await CmsApi.createFlow({
      body: {
        name: `FAQ: ${intent}`,
        path,
      },
    })

    return flow
  })
}

export const publishFlow = async ({
  id,
  flow_ns,
  channel,
  message,
}: {
  id: string
  flow_ns: string
  channel: ChannelType
  message: string
}) => {
  return await retry(async () => {
    await mcApi.post(
      API.flow.endpoints.publishFlow,
      generateFlowBody({
        id,
        flow_ns,
        channel,
        message,
      }),
    )
  })
}

export const createAiIntent = async ({
  intent,
  channel,
  flow_ns,
}: {
  intent: string
  channel: ChannelType
  flow_ns: string
}) => {
  return await retry(async () => {
    const {
      data: { intent_rule },
    } = await AiIntentsApi.createAiIntent({
      body: { intent, channel, flow_ns },
    })

    return intent_rule
  })
}

export const updateAiIntentStatus = async (intent_id: number) => {
  return await retry(async () => {
    await AiIntentsApi.updateAiIntentStatus({
      body: { intent_id, status: AiIntentStatus.ACTIVE },
    })
  })
}

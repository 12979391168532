import BaseButtonConfig from 'common/builder/buttons/BaseButtonConfig'
import { IButtonBase, IAnswerButton } from 'common/builder/buttons/buttonInterfaces'
import { ButtonType } from 'common/builder/constants/ButtonType'

class AnswerButtonConfig extends BaseButtonConfig {
  buttonType = ButtonType.ANSWER

  getDefaults = (): Omit<IAnswerButton, keyof IButtonBase> => ({
    customValue: false,
    value: '',
    emoji: null,
    image: null,
  })
}

export default new AnswerButtonConfig()

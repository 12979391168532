import React, { useState } from 'react'

import { TriggerRow } from 'apps/cms/components/Flows/components/FlowCard/components/Triggers/components/TriggerRow/TriggerRow'
import { TriggersToggle } from 'apps/cms/components/Flows/components/FlowCard/components/Triggers/components/TriggersToggle/TriggersToggle'
import { FsFlow } from 'shared/api/requests/cms/schemas'

import { useTriggers } from './hooks/useTriggers/useTriggers'

import cm from './Triggers.module.css'

interface TriggersProps {
  triggers: FsFlow['triggers']
  flowId: string
  onClick: (event: React.MouseEvent<HTMLDivElement>) => void
  hideToggle?: boolean
}

export const Triggers = ({ triggers, flowId, onClick, hideToggle }: TriggersProps) => {
  const { flowTriggers, maxVisibleTriggers } = useTriggers({ triggers })

  const [allTriggersVisible, setAllTriggersVisible] = useState(false)

  if (flowTriggers.length === 0) {
    return null
  }

  return (
    <div onClick={onClick}>
      <div className={cm.triggersList}>
        {flowTriggers.map((trigger, index) => {
          if (!allTriggersVisible && index + 1 > maxVisibleTriggers) {
            return null
          }
          return (
            <TriggerRow key={index} flowId={flowId} trigger={trigger} hideToggle={hideToggle} />
          )
        })}
      </div>

      {flowTriggers.length > maxVisibleTriggers && !hideToggle && (
        <div className="m-t-xxs">
          <TriggersToggle isOpen={allTriggersVisible} onToggle={setAllTriggersVisible} />
        </div>
      )}
    </div>
  )
}

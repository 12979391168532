/* eslint-disable import/order */
import React from 'react'
import OnboardingId from 'utils/services/newOnboardingService/OnboardingId'

import CommentsGrowthToolView from 'utils/services/newOnboardingService/onboardings/SelfServe/CGT/CommentsGrowthToolView'

import { BroadcastSampleTourView } from 'utils/services/newOnboardingService/onboardings/WhatsApp/BroadcastSampleTourView'

import ImportContactTourView from './onboardings/WhatsApp/ImportContactTourView'

// SELF SERVE TOURS FOR NEW CMS
import NewCMSStoryReactionView from 'utils/services/newOnboardingService/onboardings/SelfServe/NewCMSStoryReaction/NewCMSStoryReactionView'

import NewCMSFAQView from 'utils/services/newOnboardingService/onboardings/SelfServe/NewCMSFAQ/NewCMSFAQView'

import NewCMSStoryMentionReplyView from 'utils/services/newOnboardingService/onboardings/SelfServe/NewCMSStoryMentionReply/NewCMSStoryMentionReplyView'

import FirstLiveChatThreadTourView from './onboardings/FirstLiveChatThread/FirstLiveChatThreadTourView'

import { ManyChatOnboardingIdTypeMap } from 'utils/services/newOnboardingService/ManyChatOnboardingIdType'

export const ManyChatOnboardingIdViewMap: ManyChatOnboardingIdViewMapType = {
  [OnboardingId.NEW_CMS_SELF_SERVE_STORY_REACTION_TOUR]: NewCMSStoryReactionView,
  [OnboardingId.NEW_CMS_SELF_SERVE_FAQ_TOUR]: NewCMSFAQView,
  [OnboardingId.NEW_CMS_SELF_SERVE_STORY_MENTION_REPLY_TOUR]: NewCMSStoryMentionReplyView,

  [OnboardingId.CGT_FLOW_OVERVIEW_TOUR]: CommentsGrowthToolView,
  [OnboardingId.WHATSAPP_BROADCAST_SAMPLE_TOUR]: BroadcastSampleTourView,
  [OnboardingId.WA_IMPORT_CONTACT_TOUR]: ImportContactTourView,
  [OnboardingId.LC_WA_FIRST_THREAD_TOUR]: FirstLiveChatThreadTourView,
}

type ManyChatOnboardingIdViewMapType = {
  [Key in OnboardingId]: React.FunctionComponent<{
    onb: ManyChatOnboardingIdTypeMap[Key]
  }>
}

import { NodeType } from 'common/builder/constants/NodeType'
import localStorage from 'utils/localStorage'

import { getNodesList } from './entitySelectors'

export const getFlowChartModeKey = () => 'mcht_flowchart_mode'

export const getFlowChartModeData = (defaultValue = false) => {
  let result = defaultValue
  try {
    result = JSON.parse(localStorage.getItem(getFlowChartModeKey()))
  } catch (err) {}
  return result
}

export const isFlowChartModeSaved = (flowId: string, defaultValue: boolean) => {
  const flowChartModeData = getFlowChartModeData(defaultValue)

  // backward compatibility with old mode with mappint to flowIds
  return typeof flowChartModeData === 'boolean'
    ? flowChartModeData
    : Boolean(flowChartModeData[flowId])
}

export const forceFlowChartMode = () => {
  localStorage.setItem(getFlowChartModeKey(), true)
}

export const getBuilderById = (state: RootState, builderId: string) => {
  return state.builders.byId[builderId]
}

export const isBuilderEmptyState = (state: RootState, builderId: string) => {
  const nodesList = getNodesList(state, builderId).filter(
    (node) => node.nodeType !== NodeType.STARTING_STEP && node.nodeType !== NodeType.COMMENT,
  )
  if (nodesList.length === 0) {
    return true
  }

  return false
}

export const isAiAssistantEmptyState = (state: RootState, builderId: string) => {
  const nodesList = getNodesList(state, builderId).filter(
    (node) => node.nodeType !== NodeType.STARTING_STEP && node.nodeType !== NodeType.COMMENT,
  )
  if (nodesList.length === 0) {
    return true
  }
  if (nodesList.length > 1) {
    return false
  }

  const allowedBlocksCount = 2 // IgNode have 2 blocks as initial state: QuickReplyBlock + TextBlock
  const firstNode = nodesList[0]
  if (firstNode.nodeType === NodeType.INSTAGRAM && firstNode.blocks.length <= allowedBlocksCount) {
    return true
  }

  return false
}

import { z } from 'zod'

import { UserDisplayFlagType } from 'apps/dashboard/constants/DisplayFlagType'
import { PageStatus } from 'apps/profile/models/Page/helpers'
import { AccountStatus } from 'common/core/models/Account/constants'
import { UserRoles } from 'common/userRoles'

const AccountSchema = z.object({
  avatar_url: z.string().nullable(),
  is_active_fb_administrator: z.boolean(),
  is_favorite: z.boolean(),
  is_hidden: z.boolean(),
  mac_total: z.number(),
  page_id: z.string(),
  page_name: z.string(),
  pro_status_name: z.nativeEnum(AccountStatus),
  status_name: z.nativeEnum(PageStatus),
  subscribers_active: z.number(),
  subscribers_total: z.number(),
  threads_open: z.number(),
  ts_last_seen: z.string().nullable(),
  user_role_name: z.nativeEnum(UserRoles),
  stat_slicing: z
    .object({
      date: z.string(),
      subscribers_total: z.number(),
      subscribers_active: z.number(),
    })
    .nullable(),
})

export enum ReminderType {
  REGION_ACCESSIBILITY = 'region_accessibility',
  CGT_FEATURE = 'cgt_feature',
  REF_URL_FEATURE = 'ref_url_feature',
}

export const ProfileApiSchemas = {
  setDisplayFlag: {
    path: z.undefined(),
    query: z.undefined(),
    request: z.object({
      display_flag: z.nativeEnum(UserDisplayFlagType),
      value: z.boolean(),
    }),
    response: z.undefined(),
  },
  fetchInactiveAccounts: {
    path: z.undefined(),
    query: z.undefined(),
    request: z.undefined(),
    response: z.object({
      account_ids: z.array(z.string()),
    }),
  },
  fetchPages: {
    path: z.undefined(),
    query: z.undefined(),
    request: z.undefined(),
    response: z.object({
      fb: z.object({
        accounts: z.array(AccountSchema),
      }),
    }),
  },
  sendEmailVerification: {
    path: z.undefined(),
    query: z.undefined(),
    request: z.object({
      email: z.string(),
    }),
    response: z.object({
      email: z.string().nullable(),
      email_unverified: z.string().nullable(),
    }),
  },
  verifyEmailViaCode: {
    path: z.undefined(),
    query: z.undefined(),
    request: z.object({
      code: z.string(),
    }),
    response: z.object({
      email: z.string().nullable(),
      email_unverified: z.string().nullable(),
    }),
  },
  clearRegSourceParam: {
    path: z.undefined(),
    query: z.undefined(),
    request: z.undefined(),
    response: z.undefined(),
  },
  addToWaitlistTikTokCreatorType: {
    path: z.undefined(),
    query: z.undefined(),
    request: z.object({
      full_name: z.string(),
      email: z.string(),
      reason: z.string(),
    }),
    response: z.undefined(),
  },
  addToTikTokReminderListForUpdates: {
    path: z.undefined(),
    query: z.undefined(),
    request: z.object({
      reminder_type: z.nativeEnum(ReminderType),
    }),
    response: z.undefined(),
  },
}

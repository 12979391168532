import React from 'react'
import { useAppSelector } from 'reduxTyped'

import { mapFields } from 'common/fields/api/adapter'
import { FieldGroupId } from 'common/fields/entities/enums'
import FieldPicker, { FieldPickerProps } from 'common/fields/linking/components/FieldPicker'
import {
  FieldClearEvent,
  FieldSelectEvent,
} from 'common/fields/linking/components/interfaces/fieldEvents'
import { botFieldsSelector, userFieldsSelector } from 'common/mapping/selectors/targetPageSelectors'

type AllowedGroup = FieldGroupId.BOT | FieldGroupId.USER

type TargetPageGlobalFieldsPickerProps = {
  group: AllowedGroup
  onChange: (event: FieldSelectEvent | FieldClearEvent) => void
} & Omit<FieldPickerProps, 'fields' | 'onSelect' | 'onClear'>

const selectors: Record<AllowedGroup, typeof botFieldsSelector | typeof userFieldsSelector> = {
  [FieldGroupId.BOT]: botFieldsSelector,
  [FieldGroupId.USER]: userFieldsSelector,
}

const TargetPageGlobalFieldsPicker: React.ForwardRefRenderFunction<
  HTMLInputElement,
  TargetPageGlobalFieldsPickerProps
> = ({ group, onChange, ...props }, ref) => {
  const rawFields = useAppSelector(selectors[group])
  const fields = mapFields(
    group,
    rawFields.map((rawField) => ({ ...rawField, name: rawField.id })),
  )

  return <FieldPicker ref={ref} fields={fields} onSelect={onChange} onClear={onChange} {...props} />
}

export default React.forwardRef(TargetPageGlobalFieldsPicker)

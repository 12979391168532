import React from 'react'
import cx from 'classnames'

import { TooltipContent } from './TooltipContent'

import styles from './Tooltip.module.css'
export default class extends React.Component {
  state = { show: false }

  getScrollParent = (node) => {
    if (node === null) {
      return null
    }
    return node.scrollHeight > node.clientHeight ? node : this.getScrollParent(node.parentNode)
  }

  handleParentScroll = (e) => {
    this.setState({ show: false })
  }

  componentDidMount() {
    this.setState({ show: true })
    const { target } = this.props
    this.$parent = this.getScrollParent(target)
    if (this.$parent) {
      this.$parent.addEventListener('scroll', this.handleParentScroll)
    }
  }

  componentWillUnmount() {
    if (this.$parent) {
      this.$parent.removeEventListener('scroll', this.handleParentScroll)
    }
  }

  containerRef = React.createRef()

  render() {
    const { content, style, className, width } = this.props
    return (
      <div
        ref={this.containerRef}
        style={style}
        className={cx(className, { [styles.show]: this.state.show })}
      >
        <TooltipContent content={content} width={width} />
      </div>
    )
  }
}

import React from 'react'
import { Loader } from '@manychat/manyui'

export default class AnyEnsure extends React.Component {
  render() {
    let { items, fetching, provideItemsToProps, children } = this.props

    if (items === null) {
      if (fetching) {
        return <Loader size={32} />
      }
    }
    if (provideItemsToProps) {
      const childrenProps = { items: items || [] }
      if (typeof children === 'function') {
        return <>{children(childrenProps)}</>
      }
      return React.cloneElement(children, childrenProps)
    } else {
      return this.props.children
    }
  }
}

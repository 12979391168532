import { analyticsService } from 'utils/services/analytics'

const NAMESPACE = 'SIDEBAR.MY_PROFILE_POPOVER'

export const MyProfilePopoverAnalytics = {
  logOpenPopover: () => analyticsService.sendEvent(`${NAMESPACE}.OPENED`),
  logClosePopover: () => analyticsService.sendEvent(`${NAMESPACE}.CLOSED`),
  logProfileSectionClick: (sectionLabel: string) =>
    analyticsService.sendEvent(`${NAMESPACE}.SECTION.CLICKED`, { sectionLabel }),
  logEditEmailClick: () => analyticsService.sendEvent(`${NAMESPACE}.EDIT_EMAIL.CLICKED`),
  logUpdateEmailClick: () =>
    analyticsService.sendEvent(`${NAMESPACE}.EDIT_EMAIL_MODAL.UPDATE.CLICKED`),
  logLanguageChange: (language: string) =>
    analyticsService.sendEvent(`${NAMESPACE}.LANGUAGE.CHANGED`, { language }),
  logOpenManageSignInModal: () =>
    analyticsService.sendEvent(`${NAMESPACE}.MANAGE_SIGN_IN_MODAL.OPENED`),
  logLogOutClick: () => analyticsService.sendEvent(`${NAMESPACE}.LOG_OUT.CLICKED`),
}

import { createAction } from '@reduxjs/toolkit'
import { appListenerMiddleware } from 'reduxTyped'

import { billing, UpgradeSourceType } from 'common/billing'
import { upgrade } from 'common/billing/actions/upgradeActions'
import { cancelUpgradeRequest } from 'common/billing/actions/upgradeRequestActions'
import { AddOnSlug } from 'common/billing/interfaces/addOnTypes'
import { IThunkAction } from 'common/core/interfaces/actions'
import { WebSocketEvents } from 'constants/WebSockets'
import { anotherTabNotificationsListener } from 'utils/services/notificationsService'

import { Ability } from '../constants/Ability'
import { getHasAbility } from '../selectors/abilitiesSelectors'

export const abilitiesUpdated = createAction<Ability[]>('@abilities/update')

export interface CheckAbilityOptions {
  ability: Ability
  source: UpgradeSourceType
}

/**
 * Async thunk actions that resolves with `true`
 * if account already has the ability.
 * Otherwise starts checkout process and resolves with
 * `true`/`false` if account has the ability after checkout
 * or cancelled the checkout
 */
export const checkAbility =
  ({ ability, source }: CheckAbilityOptions): IThunkAction<Promise<boolean>> =>
  (dispatch, getState) =>
    new Promise((resolve) => {
      const getHasAbilityNow = () => getHasAbility(getState(), ability)
      if (getHasAbilityNow()) {
        resolve(true)
        return
      }

      billing.requestUpgrade({
        addOns: [AddOnSlug.AI],
        source,
      })

      const endActions = [upgrade.fulfilled.type, cancelUpgradeRequest.type]
      const unsubscribe = appListenerMiddleware.startListening({
        predicate: (action) => endActions.includes(action.type),
        effect: () => {
          unsubscribe()
          resolve(getHasAbilityNow())
        },
      })
    })

interface AbilitiesUpdatedEvent {
  actor: number
  client_id: number | null
  event: WebSocketEvents.ABILITIES_UPDATED
  model: {
    abilities: Ability[]
  }
}

anotherTabNotificationsListener.on(
  WebSocketEvents.ABILITIES_UPDATED,
  (data: AbilitiesUpdatedEvent, dispatch) => {
    const { abilities } = data.model

    dispatch(abilitiesUpdated(abilities))
  },
)

import dot from 'dot-prop-immutable'
import omit from 'lodash/omit'
import { AnyAction } from 'redux'

import * as defaultReplyAtypes from 'apps/defaultReply/defaultReplyReduxActionTypes'
import * as externalTriggersAtypes from 'apps/externalTriggers/externalTriggersReduxActionTypes'
import * as gtAtypes from 'apps/growthTools/constants/gtReduxActionTypes'
import { keywordsActions } from 'apps/keywords/keywordsSlice'
import * as postingAtypes from 'apps/posting/constants/postingReduxActionTypes'
import * as smrTypes from 'apps/storyMentionReply/actionTypes'
import * as triggerRulesAtypes from 'apps/triggerRules/constants/TriggerRulesReduxActionTypes'
import * as welcomeMessageAtypes from 'apps/welcomeMessage/welcomeMessageReduxActionTypes'
import { cartReplyActionTypes } from 'apps/whatsApp/redux/cartReplySlice'
import * as builderAtypes from 'common/builder/constants/builderReduxActionTypes'
import * as atypes from 'common/cms/constants/cmsReduxActionTypes'
import * as flowAtypes from 'common/flow/constants/flowReduxActionTypes'
import { TriggerType } from 'common/flow/constants/TriggerType'
import { ExportFlowStatuses, FlowStatus } from 'common/flow/flowConstants'
import { isFullFlow } from 'common/flow/flowHelpers'
import { IFlow, IFlowTrigger } from 'common/flow/flowInterfaces'
import { ITriggerId } from 'common/flow/flowTriggersInterfaces'
import { parseFlow } from 'common/flow/models/Flow/parseFlow'
import * as sequencesAtypes from 'common/sequences/sequencesReduxActionTypes'
import { LightFlow } from 'shared/api/common/schemas/lightFlow'

export interface IFlowState {
  byId: Record<string, IFlow>
  exportFlowStatus: Record<string, ExportFlowStatuses>
  sharedFlowImportStatus: Record<string, ExportFlowStatuses>
}

const initialState: IFlowState = {
  byId: {},
  exportFlowStatus: {},
  sharedFlowImportStatus: {},
}

interface IEntityWithFlow {
  flow: null | LightFlow
}

export const addFlowsToState = (items: IEntityWithFlow[], state: IFlowState): IFlowState => {
  items.forEach((item) => (state = addFlowToState(item, state)))
  return state
}

export const addFlowToState = (item: IEntityWithFlow, state: IFlowState): IFlowState => {
  if (!item.flow) {
    return state
  }
  const flow = parseFlow(item.flow)
  return dot.merge(state, `byId.${flow.id}`, flow)
}

const isTriggerWithFlow = (
  trigger: IFlowTrigger,
): trigger is Extract<IFlowTrigger, { flowId: string }> => 'flowId' in trigger

const isTriggerWithMultipleFlows = (
  trigger: IFlowTrigger,
): trigger is Extract<IFlowTrigger, { flowIds: string[] }> => 'flowIds' in trigger

export const handleFlowTriggerUpdated = (
  updatedTrigger: IFlowTrigger,
  state: IFlowState,
): IFlowState => {
  const triggerWithMultipleFlows = isTriggerWithMultipleFlows(updatedTrigger)

  if (!isTriggerWithFlow(updatedTrigger) && !triggerWithMultipleFlows) {
    return state
  }

  Object.values(state.byId).forEach((flow) => {
    if (!isFullFlow(flow)) {
      return
    }

    const triggerIndex = flow.triggers.findIndex((t) => t.triggerId === updatedTrigger.triggerId)
    const hasUpdatedTrigger = triggerIndex !== -1
    const triggerBelongsToFlow = triggerWithMultipleFlows
      ? updatedTrigger.flowIds.includes(flow.id)
      : flow.id === updatedTrigger.flowId && updatedTrigger.flowId

    if (hasUpdatedTrigger && triggerBelongsToFlow) {
      state = dot.merge(state, `byId.${flow.id}.triggers.${triggerIndex}`, updatedTrigger)
    } else if (hasUpdatedTrigger && !triggerBelongsToFlow) {
      state = dot.delete(state, `byId.${flow.id}.triggers.${triggerIndex}`)
    } else if (!hasUpdatedTrigger && triggerBelongsToFlow) {
      state = dot.merge(state, `byId.${flow.id}.triggers`, updatedTrigger)
    }
  })

  return state
}

export const handleFlowTriggerRemoved = (triggerId: ITriggerId, state: IFlowState): IFlowState => {
  Object.values(state.byId).forEach((flow) => {
    if (!isFullFlow(flow)) {
      return
    }

    const triggerIndex = flow.triggers.findIndex((t) => t.triggerId === triggerId)
    const hasRemovedTrigger = triggerIndex !== -1

    if (hasRemovedTrigger) {
      state = dot.delete(state, `byId.${flow.id}.triggers.${triggerIndex}`)
    }
  })

  return state
}

export default function flowReducer(state = initialState, action: AnyAction) {
  switch (action.type) {
    case flowAtypes.FLOW_DATA_FETCH_REQUEST:
    case flowAtypes.FLOW_CONTENT_DATA_FETCH_REQUEST:
    case flowAtypes.FLOW_FETCH_REQUEST: {
      return dot.merge(state, `byId.${action.flowId}`, { isFetching: true })
    }
    case flowAtypes.FLOW_FETCH_RESPONSE: {
      const item = parseFlow(action.data.flow)
      return dot.merge(state, `byId.${item.id}`, { ...item, isFetching: false })
    }
    case flowAtypes.FLOW_DATA_FETCH_ERROR:
    case flowAtypes.FLOW_FETCH_ERROR: {
      if (action.error.template_installation_access_data) {
        return dot.merge(state, `byId.${action.flowId}`, {
          isFetching: false,
          name: action.error.name,
          template_installation_access_data: action.error.template_installation_access_data,
        })
      }
      return dot.merge(state, `byId.${action.flowId}`, { isFetching: false })
    }
    case flowAtypes.FLOW_DATA_FETCH_RESPONSE: {
      const item = parseFlow({ ...action.data.flow, content_holder: action.content_holder })
      return dot.merge(state, `byId.${action.flowId}`, {
        ...item,
        isFetching: false,
      })
    }
    case flowAtypes.FLOW_CONTENT_DATA_FETCH_RESPONSE: {
      const { contents, root_content_id } = action.data.flow ?? {}
      return dot.merge(state, `byId.${action.flowId}`, {
        id: action.flowId,
        name: action.flowName,
        contents,
        root_content_id,
        isFetching: false,
        path: action.flowPath,
      })
    }
    case flowAtypes.FLOW_CONTENT_DATA_FETCH_ERROR: {
      return dot.merge(state, `byId.${action.flowId}`, { isFetching: false })
    }
    case flowAtypes.FLOW_UPDATE_NAME_SUCCESS:
    case flowAtypes.FLOW_UPDATE_SHARED_SUCCESS:
    case flowAtypes.FLOW_UPDATE_SHARED_CLONING_SUCCESS:
    case flowAtypes.FLOW_RESET: {
      const { item } = action
      return dot.merge(state, `byId.${item.id}`, item)
    }
    case flowAtypes.FLOW_SET_DRAFT_SUCCESS: {
      if (state.byId?.[action.flowId]) {
        return dot.set(state, `byId.${action.flowId}.hasDraft`, true)
      }
      return state
    }
    case flowAtypes.FLOW_SET_HAS_DRAFT: {
      return dot.set(state, `byId.${action.flowId}.hasDraft`, action.data)
    }
    case flowAtypes.FLOW_FETCH_DEPENDENCIES_RESPONSE: {
      const { map } = action.data
      return dot.merge(state, `byId.${action.flowId}.dependenciesMap`, map)
    }

    // EXPORT FLOW
    case flowAtypes.FLOW_EXPORT: {
      const { flowId } = action
      return dot.set(state, ['exportFlowStatus', flowId], ExportFlowStatuses.PROCESS)
    }
    case flowAtypes.FLOW_EXPORT_ERROR: {
      const { flowId } = action
      return dot.set(state, ['exportFlowStatus', flowId], ExportFlowStatuses.FAIL)
    }
    case flowAtypes.FLOW_EXPORT_SUCCESS: {
      const { flowId } = action
      return dot.set(state, ['exportFlowStatus', flowId], ExportFlowStatuses.SUCCESS)
    }
    case flowAtypes.FLOW_EXPORT_COMPLETE: {
      const { flowId } = action
      return dot.delete(state, ['exportFlowStatus', flowId])
    }

    // SAVE FLOW
    case flowAtypes.SHARED_FLOW_IMPORT: {
      const { pageId } = action
      return dot.set(state, ['sharedFlowImportStatus', pageId], ExportFlowStatuses.PROCESS)
    }
    case flowAtypes.SHARED_FLOW_IMPORT_ERROR: {
      const { pageId } = action
      return dot.set(state, ['sharedFlowImportStatus', pageId], ExportFlowStatuses.FAIL)
    }
    case flowAtypes.SHARED_FLOW_IMPORT_SUCCESS: {
      const { pageId } = action
      return dot.set(state, ['sharedFlowImportStatus', pageId], ExportFlowStatuses.SUCCESS)
    }
    case flowAtypes.SHARED_FLOW_IMPORT_COMPLETE: {
      const { pageId } = action
      return dot.delete(state, ['sharedFlowImportStatus', pageId])
    }

    case keywordsActions.getListFx.fulfilled.type: {
      return addFlowsToState(action.payload, state)
    }

    case flowAtypes.MERGE_FLOW_ITEMS:
    case postingAtypes.POST_LIST_LOAD_SUCCESS:
    case gtAtypes.WIDGETS_FETCH_RESPONSE: {
      return addFlowsToState(action.data ? action.data.items : action.items, state)
    }

    case gtAtypes.WIDGETS_CURRENT_FETCH_RESPONSE: {
      return addFlowToState(action.data.item, state)
    }

    case keywordsActions.fetchKeyword.fulfilled.type: {
      return addFlowToState(action.payload, state)
    }

    // widgets
    case gtAtypes.WIDGETS_CURRENT_CREATE_RESPONSE:
    case gtAtypes.WIDGETS_CURRENT_SAVE_RESPONSE:
    case gtAtypes.WIDGETS_UPDATE_STATUS_RESPONSE:
    case triggerRulesAtypes.TRIGGERRULES_CREATE_RESPONSE:
    case triggerRulesAtypes.TRIGGERRULES_CURRENT_SAVE_RESPONSE:
    case externalTriggersAtypes.EXTERNALTRIGGERS_CURRENT_SAVE_RESPONSE:
    case externalTriggersAtypes.EXTERNALTRIGGERS_CHANGE_STATUS_RESPONSE:
    case externalTriggersAtypes.EXTERNALTRIGGERS_CURRENT_CREATE_RESPONSE: {
      state = handleFlowTriggerUpdated(action.data.item, state)
      return addFlowToState(action.data.item, state)
    }
    case keywordsActions.saveKeywordStatus.fulfilled.type: {
      if (!action.payload) {
        return state
      }
      state = handleFlowTriggerUpdated(action.payload, state)
      return addFlowToState(action.payload, state)
    }
    case keywordsActions.createItemFx.fulfilled.type: {
      state = handleFlowTriggerUpdated(action.payload, state)
      return addFlowToState(action.payload, state)
    }

    case flowAtypes.FLOW_TRIGGER_UPDATED:
    case gtAtypes.WIDGETS_CREATED_NOTIFICATION:
    case gtAtypes.WIDGETS_UPDATED_NOTIFICATION:
    case triggerRulesAtypes.TRIGGERRULES_CREATED_NOTIFICATION:
    case triggerRulesAtypes.TRIGGERRULES_CURRENT_UPDATED_NOTIFICATION:
    case externalTriggersAtypes.EXTERNALTRIGGERS_CREATED_NOTIFICATION:
    case externalTriggersAtypes.EXTERNALTRIGGERS_CURRENT_UPDATED_NOTIFICATION:
    case defaultReplyAtypes.DEFAULT_REPLY_UPDATED_NOTIFICATION:
    case cartReplyActionTypes.CART_REPLY_UPDATED_NOTIFICATION:
    case welcomeMessageAtypes.WELCOME_MESSAGE_UPDATED_NOTIFICATION: {
      state = handleFlowTriggerUpdated(action.item, state)
      return addFlowToState(action.item, state)
    }
    case keywordsActions.appendItem.type: {
      const addedTrigger = action.payload.item
      state = handleFlowTriggerUpdated(addedTrigger, state)
      return addFlowToState(addedTrigger, state)
    }
    case keywordsActions.updateItem.type: {
      const updatedTrigger = action.payload.data
      state = handleFlowTriggerUpdated(updatedTrigger, state)
      return addFlowToState(updatedTrigger, state)
    }

    case gtAtypes.WIDGETS_DELETE_RESPONSE:
    case gtAtypes.WIDGETS_DELETED_NOTIFICATION: {
      const triggerId: ITriggerId = `${TriggerType.WIDGET}-${action.id}`
      return handleFlowTriggerRemoved(triggerId, state)
    }

    case keywordsActions.deleteItem.type: {
      const triggerId: ITriggerId = `${TriggerType.KEYWORD}-${action.payload.id}`
      return handleFlowTriggerRemoved(triggerId, state)
    }

    case keywordsActions.deleteItemFx.fulfilled.type: {
      const triggerId: ITriggerId = `${TriggerType.KEYWORD}-${action?.meta.arg?.id}`
      return handleFlowTriggerRemoved(triggerId, state)
    }
    case flowAtypes.FLOW_TRIGGER_REMOVED: {
      const triggerId: ITriggerId = `${action.triggerType}-${action.id}` as ITriggerId
      return handleFlowTriggerRemoved(triggerId, state)
    }
    case triggerRulesAtypes.TRIGGERRULES_DELETE_RESPONSE:
    case triggerRulesAtypes.TRIGGERRULES_DELETED_NOTIFICATION: {
      const triggerId: ITriggerId = `${TriggerType.RULE}-${action.id}`
      return handleFlowTriggerRemoved(triggerId, state)
    }
    case externalTriggersAtypes.EXTERNALTRIGGERS_DELETE_RESPONSE:
    case externalTriggersAtypes.EXTERNALTRIGGERS_DELETED_NOTIFICATION: {
      const triggerId: ITriggerId = `${TriggerType.EXTERNAL_TRIGGER}-${action.id}`
      return handleFlowTriggerRemoved(triggerId, state)
    }

    case postingAtypes.POST_LOAD_SUCCESS:
    case atypes.FS_OBJECT_LOAD_SUCCESS:
    case atypes.FS_RENAME_OBJECT_SUCCESS:
    case sequencesAtypes.SEQUENCE_MESSAGE_SET_SENDING_SETTINGS_RESPONSE: {
      return addFlowToState(action.item, state)
    }
    case flowAtypes.FLOW_REMOVED: {
      const { path } = action
      return dot.set(state, `byId.${path.substr(1)}.status`, FlowStatus.DELETED)
    }
    case flowAtypes.FLOW_PERMANENTLY_REMOVED: {
      const { path } = action
      return dot.set(state, `byId`, omit(state.byId, path))
    }
    case flowAtypes.FLOW_RESTORED: {
      const { path } = action
      return dot.set(state, `byId.${path.substr(1)}.status`, FlowStatus.ACTIVE)
    }

    case defaultReplyAtypes.DEFAULT_REPLY_LOAD_RESPONSE:
    case defaultReplyAtypes.DEFAULT_REPLY_UPDATE_ENABLED_RESPONSE:
    case cartReplyActionTypes.CART_REPLY_LOAD_RESPONSE:
    case welcomeMessageAtypes.WELCOME_MESSAGE_LOAD_RESPONSE:
    case welcomeMessageAtypes.WELCOME_MESSAGE_UPDATE_ENABLED_RESPONSE: {
      return addFlowToState(action.data, state)
    }

    case `${smrTypes.STORY_MENTION_REPLY_FETCH}_RESPONSE`:
    case `${smrTypes.STORY_MENTION_REPLY_UPDATE_ENABLED}_RESPONSE`:
    case `${smrTypes.STORY_MENTION_REPLY_CHANGE_TYPE}_RESPONSE`:
    case `${smrTypes.STORY_MENTION_REPLY_SET_FLOW}_RESPONSE`: {
      state = handleFlowTriggerUpdated(action.data, state)
      return addFlowToState(action.data, state)
    }

    case sequencesAtypes.SEQUENCE_CREATE_SUCCESS:
    case sequencesAtypes.SEQUENCE_LOAD_ITEM_SUCCESS:
    case sequencesAtypes.SEQUENCE_UPDATE_SUCCESS:
    case sequencesAtypes.SEQUENCE_REORDER_MESSAGES_SUCCESS:
    case sequencesAtypes.SEQUENCE_PATCH_MESSAGE_RESPONSE:
    case sequencesAtypes.SEQUENCE_UPDATED_NOTIFICATION: {
      return addFlowsToState(action.item.sequence_messages ?? [], state)
    }

    case builderAtypes.SET_QUICK_CAMPAIGN_ID_RESPONSE: {
      return dot.merge(state, `byId.${action.flowId}.quick_campaign_data`, {
        quick_campaign_id: action.campaignId,
      })
    }
  }
  return state
}

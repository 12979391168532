export const enum AnalyticsEvents {
  BUILDER_PLUS_BUTTON_CREATE_NODE = 'BUILDER.PLUS_BUTTON.CREATE_NODE',
  BUILDER_SIDEBAR_CREATE_BLOCK = 'BUILDER.SIDEBAR.CREATE_BLOCK',
  CLICK_CONTACT_REPLY = 'BUILDER.CONTENT_NODE.DATA_COLLECTION.CONTACT_REPLY.CLICK',
  SELECT_REPLY_TYPE = 'BUILDER.CONTENT_NODE.DATA_COLLECTION.REPLY_TYPE.SELECT',
  SAVE_EMAIL = 'BUILDER.CONTENT_NODE.DATA_COLLECTION.SAVE_EMAIL',
  SET_EMAIL_OPT_IN = 'BUILDER.CONTENT_NODE.DATA_COLLECTION.SET_EMAIL_OPT_IN',
  SAVE_PHONE_AS_WA_ID = 'BUILDER.CONTENT_NODE.DATA_COLLECTION.SAVE_PHONE_AS_WA_ID',
  SAVE_PHONE_TO_SYSTEM_FIELD = 'BUILDER.CONTENT_NODE.DATA_COLLECTION.SAVE_PHONE_TO_SYSTEM_FIELD',
  SET_SMS_OPT_IN = 'BUILDER.CONTENT_NODE.DATA_COLLECTION.SET_SMS_OPT_IN',
  CHANGE_REPLY_TIMEOUT = 'BUILDER.CONTENT_NODE.DATA_COLLECTION.REPLY_TIMEOUT.CHANGE',
  SELECT_NUMBER_OF_RETRIES = 'BUILDER.CONTENT_NODE.DATA_COLLECTION.RETRIES.SELECT',
  OPEN_ACTION_ON_SUCCESS = 'BUILDER.CONTENT_NODE.DATA_COLLECTION.ACTION_ON_SUCCESS.OPEN',
  CREATE_ACTION_ON_SUCCESS = 'BUILDER.CONTENT_NODE.DATA_COLLECTION.ACTION_ON_SUCCESS.CREATE',
  REMOVE_ACTION_ON_SUCCESS = 'BUILDER.CONTENT_NODE.DATA_COLLECTION.ACTION_ON_SUCCESS.REMOVE',
  CLICK_NEXT_STEP_AFTER_TIMEOUT = 'BUILDER.CONTENT_NODE.DATA_COLLECTION.NEXT_STEP_AFTER_TIMEOUT.CLICK',
  OPEN_NEXT_STEP_AFTER_TIMEOUT = 'BUILDER.CONTENT_NODE.DATA_COLLECTION.NEXT_STEP_AFTER_TIMEOUT.OPEN',
  CREATE_NEXT_STEP_AFTER_TIMEOUT = 'BUILDER.CONTENT_NODE.DATA_COLLECTION.NEXT_STEP_AFTER_TIMEOUT.CREATE',
  REMOVE_NEXT_STEP_AFTER_TIMEOUT = 'BUILDER.CONTENT_NODE.DATA_COLLECTION.NEXT_STEP_AFTER_TIMEOUT.REMOVE',
  BUILDER_CONNECT_CHANNELS_CLICK = 'BUILDER.CONNECT_CHANNELS.CLICK',
  ACTION_PICKER_ACTION_SELECTED = 'ACTION_PICKER.ACTION_SELECTED',
  ACTION_PICKER_CATEGORY_CHANGED = 'ACTION_PICKER.CATEGORY_CHANGED',
  ACTION_PICKER_CLOSE = 'ACTION_PICKER.CLOSE',
  ACTION_PICKER_SHOW = 'ACTION_PICKER.SHOW',
  FIELD_LIST_OPTION_SELECTED = 'FIELD_LIST.OPTION_SELECTED',
}

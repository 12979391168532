import IntlMessageFormat from 'intl-messageformat'
import { TranslateData } from '@manychat/manyui'

export interface Interpolate {
  (message: string, data?: TranslateData): string
}

export const makeInterpolate = (locales: string | string[]): Interpolate => {
  const formattersMap = new Map<string, IntlMessageFormat>()
  const getFormatter = (message: string) => {
    if (!formattersMap.has(message)) {
      formattersMap.set(
        message,
        new IntlMessageFormat(message, locales, undefined, { ignoreTag: true }),
      )
    }
    return formattersMap.get(message)
  }

  return (message, data) => {
    try {
      // eslint-disable-next-line
      // @ts-expect-error
      return String(getFormatter(message)?.format(data))
    } catch (e) {
      if (e instanceof Error) console.warn(e.message)
      return message
    }
  }
}

import uniq from 'lodash/uniq'

import { NodeType } from 'common/builder/constants/NodeType'
import nodeRegistry from 'common/builder/nodeRegistry'
import { INode } from 'common/builder/nodes/nodeInterfaces'
import {
  getById,
  getRootPreInteractionZoneTargets,
} from 'common/builder/selectors/builder/builderStateSelectors'
import * as entitySelectors from 'common/builder/selectors/builder/entitySelectors'
import { isAiAgentNodeWithChannel } from 'common/builder/utils/isAiAgentNodeWithChannel'
import { Ability } from 'common/core/constants/Ability'
import { ChannelType } from 'common/core/constants/ChannelType'

/**
 * Returns the list of required abilities in the builder by it contents
 */
export const getBuilderRequiredAbilities = (state: RootState, builderId: string): Ability[] => {
  const allNodes = entitySelectors.getNodesList(state, builderId)
  const requiredAbilities: Set<Ability> = new Set()

  allNodes.forEach((node) => {
    const nodeConfig = nodeRegistry.get(node)
    if (!nodeConfig.requiredAbilities) {
      return
    }

    nodeConfig.requiredAbilities.forEach((ability) => {
      requiredAbilities.add(ability)
    })
  })

  return Array.from(requiredAbilities)
}

export const getShowErrors = (state: RootState, builderId: string) =>
  Boolean(getById(state, builderId).showErrors)

export function getServerErrorByContentId(state: RootState, builderId: string, contentId: string) {
  const builderState = getById(state, builderId)
  const serverError = builderState.serverError || {}

  return serverError.contentId === contentId ? serverError : null
}

const isAgentNodeWithProvidedChannel = (node: INode, channel: ChannelType) =>
  isAiAgentNodeWithChannel(node) && node.channel === channel

const rootChannelsChecks = {
  hasSMS: (node: INode) => node.nodeType === NodeType.SMS,
  hasEmail: (node: INode) => node.nodeType === NodeType.EMAIL_NEW,
  hasContent: (node: INode) =>
    isAgentNodeWithProvidedChannel(node, ChannelType.FB) || node.nodeType === NodeType.CONTENT,
  hasTelegram: (node: INode) =>
    isAgentNodeWithProvidedChannel(node, ChannelType.TELEGRAM) ||
    node.nodeType === NodeType.TELEGRAM,
  hasWhatsApp: (node: INode) =>
    isAgentNodeWithProvidedChannel(node, ChannelType.WHATSAPP) ||
    node.nodeType === NodeType.WHATS_APP,
  hasInstagram: (node: INode) =>
    isAgentNodeWithProvidedChannel(node, ChannelType.INSTAGRAM) ||
    node.nodeType === NodeType.INSTAGRAM,
  hasTiktok: (node: INode) =>
    isAgentNodeWithProvidedChannel(node, ChannelType.TIKTOK) || node.nodeType === NodeType.TIKTOK,
}

/**
 * Returns a Record<hasChannel, boolean> like map that indicates used channels in a flow.
 * AI Step nodes with a channel are also included because under the hood they are send as a regular
 * channel node.
 */
export const getRootChannelsInfo = (state: RootState, builderId: string) => {
  const connectedNodes = uniq(getRootPreInteractionZoneTargets(state, builderId))

  const result = {
    hasInstagram: false,
    hasContent: false,
    hasWhatsApp: false,
    hasTiktok: false,
    hasSMS: false,
    hasEmail: false,
    hasTelegram: false,
  }

  Object.entries(rootChannelsChecks).forEach(([key, guard]) => {
    const typedKey = key as keyof typeof rootChannelsChecks

    if (result[typedKey]) {
      return
    }

    result[typedKey] = connectedNodes.filter((node) => guard(node)).length > 0
  })

  const usedChannels = Object.values(result).filter(Boolean)

  return {
    ...result,
    isMixed: usedChannels.length > 1,
    isUnknown: usedChannels.length === 0,
  }
}

export const getIsSidebarVisible = (state: RootState, builderId: string) =>
  !getById(state, builderId)?.sidebarHidden

import { l } from '@manychat/manyui'

import { isWhatsAppConnected } from 'common/builder/selectors/builder/whatsAppSelectors'
import emailDisabled from 'common/core/components/ChannelItem/assets/Email.disabled.svg'
import email from 'common/core/components/ChannelItem/assets/email.svg'
import instagramDisabled from 'common/core/components/ChannelItem/assets/Instagram.disabled.svg'
import instagram from 'common/core/components/ChannelItem/assets/Instagram.svg'
import messengerDisabled from 'common/core/components/ChannelItem/assets/Messenger.disabled.svg'
import messenger from 'common/core/components/ChannelItem/assets/Messenger.svg'
import smsDisabled from 'common/core/components/ChannelItem/assets/SMS.disabled.svg'
import sms from 'common/core/components/ChannelItem/assets/SMS.svg'
import telegramDisabled from 'common/core/components/ChannelItem/assets/Telegram.disabled.svg'
import telegram from 'common/core/components/ChannelItem/assets/Telegram.svg'
import tiktokDisabled from 'common/core/components/ChannelItem/assets/TikTok.disabled.svg'
import tiktok from 'common/core/components/ChannelItem/assets/TikTok.svg'
import whatsappDisabled from 'common/core/components/ChannelItem/assets/WhatsApp.disabled.svg'
import whatsapp from 'common/core/components/ChannelItem/assets/WhatsApp.svg'
import { ChannelType } from 'common/core/constants/ChannelType'
import {
  getIsEmailNodeEnabled,
  hasConnectedInstagramChannel,
  hasConnectedSMSChannel,
  hasConnectedTelegramChannel,
  hasConnectedTiktokChannel,
  isFBChannelConnected,
} from 'common/core/selectors/channelSelectors'

export interface IChannelConfig {
  title: () => string
  label: () => string
  icon?: string
  iconDisabled: string
  description: () => string
  connectionDescription?: () => string
  isPro: boolean
  isConnectedSelector: (state: RootState) => boolean
}

export const ChannelConfig: Partial<Record<ChannelType, IChannelConfig>> = {
  [ChannelType.FB]: {
    title: l.makeTranslate('Facebook'),
    label: l.makeTranslate('Facebook'),
    icon: messenger,
    iconDisabled: messengerDisabled,
    description: l.makeTranslate(
      'Build relationships with customers through interactive and tailored content.',
    ),
    isPro: false,
    isConnectedSelector: (state) => isFBChannelConnected(state),
  },
  [ChannelType.SMS]: {
    title: l.makeTranslate('SMS'),
    label: l.makeTranslate('SMS'),
    icon: sms,
    iconDisabled: smsDisabled,
    description: l.makeTranslate('Collect phone numbers and reengage your contacts via text.'),
    isPro: true,
    isConnectedSelector: (state) => hasConnectedSMSChannel(state),
  },
  [ChannelType.EMAIL]: {
    title: l.makeTranslate('Email'),
    label: l.makeTranslate('Email'),
    icon: email,
    iconDisabled: emailDisabled,
    description: l.makeTranslate('Use Email marketing for automation and rich content campaigns.'),
    isPro: true,
    isConnectedSelector: (state) => getIsEmailNodeEnabled(state),
  },
  [ChannelType.WHATSAPP]: {
    title: l.makeTranslate('WhatsApp'),
    label: l.makeTranslate('WhatsApp'),
    icon: whatsapp,
    iconDisabled: whatsappDisabled,
    description: l.makeTranslate(
      `Use the world's most popular messaging app to chat and engage your customers.`,
    ),
    isPro: true,
    isConnectedSelector: (state) => isWhatsAppConnected(state),
  },
  [ChannelType.INSTAGRAM]: {
    title: l.makeTranslate('Instagram'),
    label: l.makeTranslate('Instagram'),
    icon: instagram,
    // Necessary for future updates, not used now
    iconDisabled: instagramDisabled,
    description: l.makeTranslate('Supercharge your Instagram marketing with messaging automation.'),
    isPro: true,
    isConnectedSelector: (state) => hasConnectedInstagramChannel(state),
  },
  [ChannelType.TELEGRAM]: {
    title: l.makeTranslate('Telegram'),
    label: l.makeTranslate('Telegram'),
    icon: telegram,
    iconDisabled: telegramDisabled,
    description: l.makeTranslate('Unleash the power of limitless Telegram messaging automation.'),
    isPro: true,
    isConnectedSelector: (state) => hasConnectedTelegramChannel(state),
  },
  [ChannelType.TIKTOK]: {
    title: l.makeTranslate('TikTok'),
    label: l.makeTranslate('TikTok'),
    icon: tiktok,
    iconDisabled: tiktokDisabled,
    description: l.makeTranslate('Elevate your marketing with TikTok’s seamless automation.'),
    isPro: true,
    isConnectedSelector: (state) => hasConnectedTiktokChannel(state),
  },
}

export const ZAP_CREATED_NOTIFICATION = 'ZAP_CREATED_NOTIFICATION'
export const ZAP_REMOVED_NOTIFICATION = 'ZAP_REMOVED_NOTIFICATION'

export const ZAPS_FETCH = 'ZAPS_FETCH'
export const ZAPS_FETCH_REQUEST = 'ZAPS_FETCH_REQUEST'
export const ZAPS_FETCH_RESPONSE = 'ZAPS_FETCH_RESPONSE'
export const ZAPS_FETCH_ERROR = 'ZAPS_FETCH_ERROR'

export const ZAPS_CREATE = 'ZAPS_CREATE'
export const ZAPS_CREATE_REQUEST = 'ZAPS_CREATE_REQUEST'
export const ZAPS_CREATE_RESPONSE = 'ZAPS_CREATE_RESPONSE'
export const ZAPS_CREATE_ERROR = 'ZAPS_CREATE_ERROR'

export const ZAPIER_HOOK_CHECK = 'ZAPIER_HOOK_CHECK'

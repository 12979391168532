import React from 'react'
import cx from 'classnames'
import PropTypes from 'prop-types'

import { CircleAvatar } from 'components/CircleAvatar'

import cm from './ProLabelAvatar.module.css'

export default class ProLabelAvatar extends React.Component {
  static propTypes = {
    free: PropTypes.bool,
    pro: PropTypes.bool,
    labelClassName: PropTypes.string,
    label: PropTypes.oneOfType([PropTypes.element, PropTypes.string]),
    className: PropTypes.string,
    /* CircleAvatar props */
    url: PropTypes.string,
    backgroundColor: PropTypes.string,
    title: PropTypes.string,
    size: PropTypes.number,
  }

  static defaultProps = {
    free: false,
    pro: false,
    labelClassName: '',
  }

  render() {
    const { free, pro, ai, className, labelClassName, label, url, backgroundColor, title, size } =
      this.props

    return (
      <div className={className}>
        <CircleAvatar url={url} backgroundColor={backgroundColor} title={title} size={size} />
        {free && <span className={cx(cm.label, cm.free, labelClassName)}>{label || 'FREE'}</span>}
        {pro && <span className={cx(cm.label, labelClassName)}>{label || 'PRO'}</span>}
        {ai && <span className={cx(cm.label, cm.ai, labelClassName)}>{label || 'AI'}</span>}
      </div>
    )
  }
}

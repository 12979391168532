import PropTypes from 'prop-types'
export const DEFAULT_LOGO_WIDTH = 32
export const DEFAULT_LOGO_HEIGHT = 32

export const PROP_TYPES = {
  value: PropTypes.string.isRequired,
  size: PropTypes.number,
  level: PropTypes.oneOf(['L', 'M', 'Q', 'H']),
  bgColor: PropTypes.string,
  fgColor: PropTypes.string,
  includeMargin: PropTypes.bool,
  img: PropTypes.object,
  removeLogoBackground: PropTypes.bool,
}

export const ERROR_CORRECT_LEVEL = {
  L: 1,
  M: 0,
  Q: 3,
  H: 2,
}

export const DEFAULT_PROPS = {
  size: 128,
  level: 'L',
  bgColor: '#FFFFFF',
  fgColor: '#000000',
  includeMargin: false,
  removeLogoBackground: false,
}

export const DEFAULT_IMG_PROPS = {
  left: 50,
  top: 50,
  width: DEFAULT_LOGO_WIDTH,
  height: DEFAULT_LOGO_HEIGHT,
  src: null,
}

export const MARGIN_SIZE = 2

// For canvas we're going to switch our drawing mode based on whether or not
// the environment supports Path2D. We only need the constructor to be
// supported, but Edge doesn't actually support the path (string) type
// argument. Luckily it also doesn't support the addPath() method. We can
// treat that as the same thing.
export const SUPPORTS_PATH2D = (function () {
  try {
    new Path2D().addPath(new Path2D())
  } catch (e) {
    return false
  }
  return true
})()

import ChatBgURL from '../../assets/img/chat-bg.svg'
import FaviconNewMessageUrl from '../../assets/img/favicons/favicon-logo-message.png'
import profileImageStubURL from '../../assets/img/fb-profile.png'
import FBLogoURL from '../../assets/img/messengers/svg/fb-logo.svg'
import NewMessageSoundURL from '../../assets/other/audio/new_message.mp3'

export const getProfileImageStubURL = () => profileImageStubURL
export const getFBLogoUrl = () => FBLogoURL
export const getNewMessageSoundURL = () => NewMessageSoundURL

export const getFaviconNewMessageURL = () => FaviconNewMessageUrl
export const getChatBgURL = () => ChatBgURL

export const TosURL = 'https://manychat.com/legal/tos'
export const PrivacyPolicyURL = 'https://manychat.com/legal/privacy'

import * as atypes from 'common/settings/SettingsReduxActionTypes'

import adminsReducer from './admins'

const initialState = {
  fetching: false,
  error: null,
  ready: false,
  items: [],
}

export default function botSettingsReducer(state = initialState, action) {
  state = { ...state, admins: adminsReducer(state.admins, action) }

  switch (action.type) {
    case atypes.SETTINGS_UPDATE_RESPONSE: {
      return { ...state, fetching: false, ready: false }
    }
  }

  return state
}

/* eslint-disable react/no-multi-comp */
import React from 'react'
import { l } from '@manychat/manyui'

import DefaultStepView from 'utils/services/newOnboardingService/view/DefaultStepView'
import ManyChatOnboardingView from 'utils/services/newOnboardingService/view/ManyChatOnboardingView'
import { PointerType } from 'utils/services/newOnboardingService/view/Pointer/pointerInterfaces'
import TooltipDefault from 'utils/services/newOnboardingService/view/Pointer/Tooltip/TooltipDefault'

import NewCMSStoryMentionReply from './NewCMSStoryMentionReply'

const TOTAL_STEPS = 3

const NewCMSStoryMentionReplyView = ({ onb }: { onb: NewCMSStoryMentionReply }) => {
  return (
    <ManyChatOnboardingView
      onboarding={onb}
      stepsView={{
        STEP_1: ({ stepId }) => (
          <DefaultStepView
            onb={onb}
            stepId={stepId}
            pointerConfig={{
              type: PointerType.DOM,
              elementId: 'nav-link-cms',

              tooltip: {
                component: (
                  <TooltipDefault
                    text={l.makeTranslate(
                      "Congrats! You’ve just set up your Story Mention Reply. Let's click here to see where it lives in the Automation tab.",
                    )}
                  />
                ),
                at: 'right',
              },
            }}
            progressConfig={{
              current: 1,
              total: TOTAL_STEPS,
            }}
          />
        ),
        STEP_2: ({ stepId }) => (
          <DefaultStepView
            onb={onb}
            stepId={stepId}
            pointerConfig={{
              type: PointerType.DOM,
              elementId: 'nav-basic',
              tooltip: {
                component: (
                  <TooltipDefault
                    text={l.makeTranslate(
                      `In the Basic Automations tab you’ll find simple automations you’ve created, as well as Story Mention Reply feature.`,
                    )}
                  />
                ),
                at: 'bottom',
              },
            }}
            progressConfig={{
              current: 2,
              total: TOTAL_STEPS,
            }}
          />
        ),
        STEP_3: ({ step, stepId }) => (
          <DefaultStepView
            onb={onb}
            stepId={stepId}
            pointerConfig={{
              type: PointerType.DOM,
              elementId: 'self-serve-story-mention-reply-tour',
              blockAreaInput: true,
              padding: 10,
              tooltip: {
                component: (
                  <TooltipDefault
                    text={l.makeTranslate(
                      'You can edit your Story Mention Reply anytime by clicking here.',
                    )}
                    onNextClick={() => step.process()}
                    btnNext={l.makeTranslate('Got it!')}
                  />
                ),
                at: 'bottom',
              },
            }}
            progressConfig={{
              current: 3,
              total: TOTAL_STEPS,
            }}
          />
        ),
      }}
    />
  )
}
export default NewCMSStoryMentionReplyView

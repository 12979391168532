// installed applications
export const APPLICATION_USES_LIST_FETCH = 'APPLICATION_USES_LIST_FETCH'
export const APPLICATION_USES_LIST_FETCH_REQUEST = 'APPLICATION_USES_LIST_FETCH_REQUEST'
export const APPLICATION_USES_LIST_FETCH_RESPONSE = 'APPLICATION_USES_LIST_FETCH_RESPONSE'
export const APPLICATION_USES_LIST_FETCH_ERROR = 'APPLICATION_USES_LIST_FETCH_ERROR'
export const APPLICATIONS_LIST_FETCH = 'APPLICATIONS_LIST_FETCH'
export const APPLICATIONS_LIST_FETCH_RESPONSE = 'APPLICATIONS_LIST_FETCH_RESPONSE'

// uninstall
export const UNINSTALL_APPLICATION = 'UNINSTALL_APPLICATION'
export const UNINSTALL_APPLICATION_REQUEST = 'UNINSTALL_APPLICATION_REQUEST'
export const UNINSTALL_APPLICATION_RESPONSE = 'UNINSTALL_APPLICATION_RESPONSE'

// settings
export const APPLICATION_SETTINGS_FORM_FETCH = 'APPLICATION_SETTINGS_FORM_FETCH'

// open update modal
export const APPLICATION_UPDATE_MODAL_OPEN = 'APPLICATION_UPDATE_MODAL_OPEN'
export const APPLICATION_UPDATE_MODAL_CLOSE = 'APPLICATION_UPDATE_MODAL_CLOSE'

// app changelog
export const APPLICATION_UPDATE_CHANGELOG_FETCH = 'APPLICATION_UPDATE_CHANGELOG_FETCH'
export const APPLICATION_UPDATE_CHANGELOG_FETCH_REQUEST =
  'APPLICATION_UPDATE_CHANGELOG_FETCH_REQUEST'
export const APPLICATION_UPDATE_CHANGELOG_FETCH_RESPONSE =
  'APPLICATION_UPDATE_CHANGELOG_FETCH_RESPONSE'

// app update
export const APPLICATION_VERSION_UPDATE = 'APPLICATION_VERSION_UPDATE'
export const APPLICATION_VERSION_UPDATE_REQUEST = 'APPLICATION_VERSION_UPDATE_REQUEST'
export const APPLICATION_VERSION_UPDATE_RESPONSE = 'APPLICATION_VERSION_UPDATE_RESPONSE'
export const APPLICATION_VERSION_UPDATE_ERROR = 'APPLICATION_VERSION_UPDATE_ERROR'
export const APPLICATION_VERSION_UPDATE_STAGE_SET = 'APPLICATION_VERSION_UPDATE_STAGE_SET'

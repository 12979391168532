import React from 'react'
import cx from 'classnames'
import { z } from 'zod'
import { Icon, palettes } from '@manychat/manyui'

import { BlockType } from '../../constants'
import { registry } from '../../registry'
import { ClassNameSchema } from '../../schemas'
import { BlockProps } from '../../types'

import cm from './Benefits.module.css'

const IconKeys = Object.keys(Icon) as Array<keyof typeof Icon> & [string, ...string[]]

const BenefitSchema = z.object({
  icon: z.enum(IconKeys),
  description: z.string(),
})

const BenefitsPropsSchema = z.object({
  className: ClassNameSchema,
  benefits: z.array(BenefitSchema),
})

const Component = ({
  blockId,
  benefits = [],
  className,
}: BlockProps<typeof BenefitsPropsSchema>) => {
  if (!benefits.length) {
    return null
  }

  return (
    <div data-block={blockId} className={cm.wrapper}>
      <ul className={cx(cm.benefits, className)}>
        {benefits.map((benefit, index) => {
          const BenefitIcon = Icon[benefit.icon]

          return (
            <li key={index} className={cm.benefit}>
              <span className={cm.benefit__icon}>
                <BenefitIcon size={32} color={palettes.semantic.primary} />
              </span>

              <p className="m-0">{benefit.description}</p>
            </li>
          )
        })}
      </ul>
    </div>
  )
}

export const Benefits = registry.register(Component, {
  props: BenefitsPropsSchema,
  type: BlockType.BENEFITS,
  previewProps: {
    benefits: [
      {
        icon: 'AiIntention',
        description:
          'Enhance response accuracy with Manychat AI — smartly detecting DM intents for optimal replies',
      },
      {
        icon: 'AiStep',
        description:
          'Let AI Step streamline your conversations, boost sales, and re-engage unresponsive leads',
      },
      {
        icon: 'MagicWand',
        description:
          'Struggle with writing? Let our AI Text Improver craft, refine, and style your messages effortlessly',
      },
      {
        icon: 'Flow',
        description:
          'Bypass complex setups and seamlessly automate flows by simply talking to AI Flow Builder',
      },
    ],
  },
})

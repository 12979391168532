import React from 'react'

import cm from './Chip.module.css'

interface ChipProps {
  label: string
}

export const Chip = ({ label }: ChipProps) => {
  return <span className={cm.chip}>{label}</span>
}

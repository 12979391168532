import React from 'react'
import { l } from '@manychat/manyui'

import cm from './DraftIndicator.module.css'

const LABEL = l.translate(
  'This Automation contains an unpublished draft. Users will only receive content from published versions.',
)

export const DraftIndicator = () => <span data-title={LABEL} className={cm.draftIndicator} />

import { GrandfatheringVersion } from '../constants/AccountBillingInfo'

export const getBillingVersion = (state: RootState) =>
  state.app.currentAccount.billing_info?.billing_version || state.billing.pro?.billing_version

export const getGrandfatheringVersion = (state: RootState) =>
  (state.app.currentAccount.billing_info?.grandfathering_version ||
    state.billing.pro?.grandfathering_version) ??
  null

export const getIsGrandfatheringEnabled = (state: RootState) =>
  Boolean(
    state.app.currentAccount.billing_info?.is_grandfathering ||
      state.billing.pro?.is_grandfathering,
  )

export const getIsFreeToFlexGrandfatheringEnabled = (state: RootState) => {
  const isGrandfathering = getIsGrandfatheringEnabled(state)
  const grandfatheringVersion = getGrandfatheringVersion(state)

  return isGrandfathering && grandfatheringVersion === GrandfatheringVersion.BASE_FREE_TO_FLEX_PRO
}

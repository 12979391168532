import { z } from 'zod'

import { BlockType } from '../../constants'
import { registry } from '../../registry'

const Component = () => null

export const Null = registry.register(Component, {
  props: z.any(),
  type: BlockType.NULL,
})

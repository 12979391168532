import React, { ReactNode } from 'react'
import cx from 'classnames'

import { CircleAvatar } from 'components/CircleAvatar'

import emptyAvatarSvg from '../../svg/emptyAvatar.svg'

import cm from './Message.module.css'

export interface IncomingMessageProps {
  variant: 'incoming'
  children: ReactNode
  avatarUrl?: string
}

export function IncomingMessage(props: Omit<IncomingMessageProps, 'variant'>) {
  return (
    <div className={cm.incomingWrapper} data-test-id="incoming-message">
      <div>
        {props.avatarUrl ? (
          <CircleAvatar url={props.avatarUrl} size={21} data-test-id="circle-avatar" />
        ) : (
          <img className={cm.avatar} src={emptyAvatarSvg} alt="avatar" data-test-id="avatar" />
        )}
      </div>
      <div className={cx(cm.message, cm.incoming)}>{props.children}</div>
    </div>
  )
}

import { z } from 'zod'

import { WidgetTypes } from 'apps/growthTools/models/Widget/constants'
import { ChannelType } from 'common/core/constants/ChannelType'
import { TriggerType } from 'common/flow/constants/TriggerType'
import {
  AnyChatMessageSchema,
  GenerationStatus,
} from 'shared/api/common/schemas/ai/ChatMessageSchemas'
import { KeywordRuleSchema } from 'shared/api/requests/keywords/schemas'

const ProtoKeywordSchema = z.object({
  trigger_type: z.literal(TriggerType.KEYWORD),
  channel: z.nativeEnum(ChannelType),
  keywords: z.array(KeywordRuleSchema).optional(),
})

const ProtoIntentSchema = z.object({
  trigger_type: z.literal(TriggerType.AI_INTENT),
  channel: z.nativeEnum(ChannelType),
  intents: z.array(z.string()).optional(),
})

const ProtoCgtSchema = z.object({
  trigger_type: z.literal(TriggerType.WIDGET),
  widget_type: z.literal(WidgetTypes.FEED_COMMENT_TRIGGER),
  channel: z.nativeEnum(ChannelType),
  caption: z.string(),
})

const ProtoStoryReplySchema = z.object({
  trigger_type: z.literal(TriggerType.WIDGET),
  widget_type: z.literal(WidgetTypes.INSTAGRAM_STORY_REPLY),
  channel: z.literal(ChannelType.INSTAGRAM),
  caption: z.string(),
})

const ProtoLiveCommentSchema = z.object({
  trigger_type: z.literal(TriggerType.WIDGET),
  widget_type: z.literal(WidgetTypes.INSTAGRAM_LIVE_COMMENT_REPLY),
  channel: z.literal(ChannelType.INSTAGRAM),
  caption: z.string(),
})

const ProtoRefUrlSchema = z.object({
  trigger_type: z.literal(TriggerType.WIDGET),
  widget_type: z.literal(WidgetTypes.MESSENGER_REF_URL),
  channel: z.union([
    z.literal(ChannelType.FB),
    z.literal(ChannelType.WHATSAPP),
    z.literal(ChannelType.TELEGRAM),
  ]),
  caption: z.string(),
})

export const ProtoTriggerSchema = z.union([
  ProtoKeywordSchema,
  ProtoIntentSchema,
  ProtoCgtSchema,
  ProtoStoryReplySchema,
  ProtoLiveCommentSchema,
  ProtoRefUrlSchema,
])

export const CreateFlowDraftSchema = {
  request: z.object({
    prompt: z.string(),
    flow_ns: z.string(),
    regenerate: z.boolean().optional(),
  }),
  query: z.undefined(),
  path: z.undefined(),
  response: z.object({
    draft: z.object({}),
    fields: z.array(z.string()),
  }),
}

export const ApplyFlowSchema = {
  request: z.object({
    session_id: z.string(),
    flow_draft_id: z.number(),
    flow_ns: z.string(),
  }),
  query: z.undefined(),
  path: z.undefined(),
  response: z.object({
    status: z.string().optional(),
  }),
}

export const SendChatMessageSchema = {
  request: z.object({
    session_id: z.string(),
    flow_ns: z.string(),
    message: z.string(),
  }),
  query: z.undefined(),
  path: z.undefined(),
  response: z.object({}),
}

export const GetFlowDraftSchema = {
  request: z.object({
    session_id: z.string(),
    flow_draft_id: z.number(),
  }),
  query: z.undefined(),
  path: z.undefined(),
  response: z
    .object({
      status: z.literal(GenerationStatus.IN_PROGRESS),
    })
    .or(
      z.object({
        status: z.literal(GenerationStatus.ERROR),
      }),
    )
    .or(
      z.object({
        status: z.literal(GenerationStatus.SUCCESS),
        flow_draft: z.object({
          draft: z.object({}),
          fields: z.array(z.string()),
          triggers: z.array(ProtoTriggerSchema),
        }),
      }),
    ),
}

export const StartChatSchema = {
  request: z.object({
    session_id: z.string(),
    flow_ns: z.string(),
  }),
  query: z.undefined(),
  path: z.undefined(),
  response: z.object({ history: z.array(AnyChatMessageSchema), session_id: z.string() }),
}

export const SendChatEventSchema = {
  request: z.object({
    session_id: z.string(),
    flow_ns: z.string(),

    input: z.string().optional(),
    button: z.string().optional(),
  }),
  query: z.undefined(),
  path: z.undefined(),
  response: z.object({ history: z.array(AnyChatMessageSchema), session_id: z.string() }),
}

export const GetChatHistorySchema = {
  request: z.object({
    session_id: z.string(),
  }),
  query: z.undefined(),
  path: z.undefined(),
  response: z.object({ history: z.array(AnyChatMessageSchema), session_id: z.string() }),
}

export type ProtoKeyword = z.infer<typeof ProtoKeywordSchema>
export type ProtoIntent = z.infer<typeof ProtoIntentSchema>
export type ProtoCgt = z.infer<typeof ProtoCgtSchema>
export type ProtoStoryReply = z.infer<typeof ProtoStoryReplySchema>
export type ProtoLiveComment = z.infer<typeof ProtoLiveCommentSchema>
export type ProtoRefUrl = z.infer<typeof ProtoRefUrlSchema>
export type ProtoTrigger = z.infer<typeof ProtoTriggerSchema>

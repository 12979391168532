import get from 'lodash/get'

export const getItems = (state) => {
  return state.userFields.items
}

export const getAllById = (state) => {
  return state.userFields.byId || {}
}

export const isUserFieldsFetching = (state) => state.userFields.fetching

export const getById = (state, id) =>
  get(
    state,
    `userFields.byId.${typeof id === 'string' ? parseInt(id.slice(id.indexOf('_') + 1)) : id}`,
  )

export const getUserFieldSearchValue = (state) => state.userFields.searchValue.query

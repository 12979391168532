import { useEffect, useState } from 'react'

import { FieldGroupId } from 'common/fields/entities/enums'
import { FieldOptionGroup } from 'common/fields/linking/hooks/useFieldsOptions'

export const useActiveCategory = (options: FieldOptionGroup[]) => {
  const firstCategoryWithOptions = options.find((option) => option?.options?.length)?.group
  const [activeCategory, setActiveCategory] = useState(firstCategoryWithOptions)
  const [clickedCategory, setClickedCategory] = useState<FieldGroupId | undefined>(undefined)
  const [activeCategoryOptions, setActiveCategoryOptions] = useState(options)

  useEffect(() => {
    const filterOptionsByCategory = (
      options: FieldOptionGroup[],
      category: FieldGroupId | undefined,
    ) => (options?.length ? options.filter((option) => option?.group === category) : [])

    const clickedCategoryOptions = clickedCategory
      ? filterOptionsByCategory(options, clickedCategory)
      : []
    const activeCategoryOptions = filterOptionsByCategory(options, activeCategory)
    const clickedCategoryHasOptions = Boolean(clickedCategoryOptions?.[0]?.options?.length)
    const category = clickedCategoryHasOptions ? clickedCategory : firstCategoryWithOptions

    setActiveCategory(category)
    setActiveCategoryOptions(activeCategoryOptions)
  }, [options, clickedCategory, activeCategory, firstCategoryWithOptions])

  return { setClickedCategory, activeCategory, activeCategoryOptions }
}

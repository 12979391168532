import React, { useContext } from 'react'
import cx from 'classnames'
import { v4 as uuid } from 'uuid'

import { FormChoiceField } from '../FormField/FormChoiceField'
import RadioGroupContext from '../RadioGroup/RadioGroup-Context'

import { RadioV2Props } from './interfaces'

import cm from './RadioV2.module.css'

/**Second version of the Radio component.**Notice that RadioV2 should not be used without RadioGroup component**
 *
 *[Figma](https://www.figma.com/file/Xf38PdCKeISWnBoh0pLFIIWf/%F0%9F%A7%B1-MC-Desktop-Components?node-id=18568%3A47056)
 */

export const RadioV2 = ({
  labelId,
  helpText,
  infoText,
  errorText,
  value,
  name,
  lozenge,
  ...props
}: RadioV2Props) => {
  const id = React.useMemo(() => props.id || `${uuid()}-radio`, [props.id])
  const {
    radioGroupName,
    radioGroupValue,
    radioGroupInvalidState,
    radioGroupDisabledState,
    onChange,
  } = useContext(RadioGroupContext) || {}
  const invalid = radioGroupInvalidState !== undefined && radioGroupInvalidState === true
  const checked = radioGroupValue === value
  const disabled = radioGroupDisabledState !== undefined ? radioGroupDisabledState : props.disabled
  const isNamed = radioGroupName !== undefined ? radioGroupName : name

  return (
    <FormChoiceField
      name={isNamed}
      type="radio"
      id={id}
      invalid={invalid}
      checked={checked}
      value={value}
      tabIndex={props.tabIndex}
      className={props.className}
      onChange={onChange}
      disabled={disabled}
      labelId={labelId}
      lozenge={lozenge}
      helpText={helpText}
      errorText={errorText}
      infoText={infoText}
      fieldClasses={{
        choice: cm.radioV2__input,
        label: 'text-body',
      }}
      {...props}
    >
      <div className={cx(cm.radioV2__container, checked && cm.checked, disabled && cm.disabled)} />
    </FormChoiceField>
  )
}
RadioV2.displayName = 'RadioV2'

import { api } from 'shared/api'
import { audienceApiSchemas } from 'shared/api/requests/audience/schemas'

export const AudienceApi = {
  createWhatsAppSubscribers: api.account.createPost({
    url: '/subscribers/createWhatsAppSubscribers',
    schemas: audienceApiSchemas.createWhatsAppSubscribers,
  }),
  details: api.account.createGet({
    url: '/subscribers/details',
    schemas: audienceApiSchemas.details,
  }),
  addTag: api.account.createGet({
    url: '/subscribers/addTag',
    schemas: audienceApiSchemas.addTag,
  }),
  removeTag: api.account.createGet({
    url: '/subscribers/removeTag',
    schemas: audienceApiSchemas.removeTag,
  }),
  addToSequence: api.account.createGet({
    url: '/subscribers/addToSequence',
    schemas: audienceApiSchemas.addToSequence,
  }),
  removeFromSequece: api.account.createGet({
    url: '/subscribers/removeFromSequence',
    schemas: audienceApiSchemas.removeFromSequence,
  }),
  unsubscribe: api.account.createGet({
    url: '/subscribers/unsubscribe',
    schemas: audienceApiSchemas.unsubscribe,
  }),
  unsubscribeFromSMS: api.account.createGet({
    url: '/subscribers/unsubscribeFromSMS',
    schemas: audienceApiSchemas.unsubscribeFromSMS,
  }),
  unsubscribeFromEmail: api.account.createGet({
    url: '/subscribers/unsubscribeFromEmail',
    schemas: audienceApiSchemas.unsubscribeFromEmail,
  }),
  unsubscribeFromTelegram: api.account.createGet({
    url: '/subscribers/unsubscribeFromTelegram',
    schemas: audienceApiSchemas.unsubscribeFromTelegram,
  }),
  unsubscribeFromWhatsApp: api.account.createGet({
    url: '/subscribers/unsubscribeFromWhatsApp',
    schemas: audienceApiSchemas.unsubscribeFromWhatsApp,
  }),
  unsubscribeFromInstagram: api.account.createGet({
    url: '/subscribers/unsubscribeFromInstagram',
    schemas: audienceApiSchemas.unsubscribeFromInstagram,
  }),
  deleteData: api.account.createPost({
    url: '/subscribers/deleteData',
    schemas: audienceApiSchemas.deleteData,
  }),
  exportData: api.account.createPost({
    url: '/subscribers/exportData',
    schemas: audienceApiSchemas.exportData,
  }),
  prepareCsvExport: api.account.createPost({
    url: '/subscribers/prepareCsvExport',
    schemas: audienceApiSchemas.prepareCsvExport,
  }),
  checkZapierHook: api.account.createGet({
    url: '/zapierHook/check',
    schemas: audienceApiSchemas.checkZapierHook,
  }),
  search: api.account.createPost({
    url: '/subscribers/search',
    schemas: audienceApiSchemas.search,
  }),
  getAccountUserSubscriber: api.account.createGet({
    url: '/subscribers/getAccountUserSubscriber',
    schemas: audienceApiSchemas.getAccountUserSubscriber,
  }),
  bulkAction: api.account.createPost({
    url: '/subscribers/bulk',
    schemas: audienceApiSchemas.bulkAction,
  }),
  blockWAContact: api.account.createPost({
    url: '/subscribers/blockWhatsAppSubscriber',
    schemas: audienceApiSchemas.blockWAContact,
  }),
}

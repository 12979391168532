import React from 'react'
import cx from 'classnames'
import { Icon, palettes } from '@manychat/manyui'

import { useSignInOptions } from '../../hooks/useSignInOptions'

import cm from './SignInOptions.module.css'

export const SignInOptions = () => {
  const { hasFBProfile, hasGoogleProfile, hasAppleProfile, hasTelegramProfile } = useSignInOptions()

  return (
    <div className="d-flex align-center">
      <Icon.Facebook
        size={16}
        className={cx('m-r-xxs', { [cm.disabled]: !hasFBProfile })}
        color={palettes.semantic.primary}
      />
      <Icon.Google size={16} className={cx('m-r-xxs', { [cm.disabled]: !hasGoogleProfile })} />
      <Icon.Apple size={16} className={cx('m-r-xxs', { [cm.disabled]: !hasAppleProfile })} />
      <Icon.Telegram
        size={16}
        className={cx({ [cm.disabled]: !hasTelegramProfile })}
        color="#0088CC"
      />
    </div>
  )
}

import { l } from '@manychat/manyui'

import { ALL_TEAM_MEMBERS_ID, USER_HAVE_NOT_ID } from 'apps/chat/constants/LiveChatInterfaces'
import { getAssignedOpenThreadCountersByUserId } from 'apps/chat/selectors/countersSelectors'
import { getHasInboxProduct, getUserId, getAvatarSmall } from 'common/core/selectors/appSelectors'

export function getAdminsMap(state: RootState) {
  return state.chat.admins.byId
}

export function getAdminById(state: RootState, adminId: number) {
  const map = getAdminsMap(state)

  return map[adminId] || null
}

export const isAdminsFetching = (state: RootState): boolean => state.chat.admins.fetching

export const getAdminsOptions = (state: RootState) => {
  const adminsMap = getAdminsMap(state)
  const idsArray = Object.keys(adminsMap)

  if (!idsArray.length) return []

  const currentUserId = getUserId(state)
  const currentUserAvatar = getAvatarSmall(state)
  const hasInbox = getHasInboxProduct(state)
  const assignedOpenThreadCountersByUserId = getAssignedOpenThreadCountersByUserId(state)

  const initialOptions = [
    {
      label: l.translate('Whole team'),
      value: ALL_TEAM_MEMBERS_ID,
    },
    {
      label: l.translate('You'),
      value: currentUserId ?? USER_HAVE_NOT_ID,
      avatar: currentUserAvatar,
      threadsCount: currentUserId ? assignedOpenThreadCountersByUserId?.[currentUserId] : undefined,
    },
  ]

  const option = idsArray
    .reduce<
      Array<{
        label: string
        value: number
        avatar?: string
        threadsCount?: number
      }>
    >((prev, cur) => {
      const admin = adminsMap[cur]
      const result = prev
      if (admin.user_id !== currentUserId && (!hasInbox || admin.is_inbox_user)) {
        result.push({
          label: admin.name,
          value: admin.user_id,
          avatar: admin.avatar,
          threadsCount: assignedOpenThreadCountersByUserId?.[admin.user_id],
        })
      }
      return result
    }, [])
    .sort((a, b) => a.label.localeCompare(b.label))

  return [...initialOptions, ...option]
}

import { BulkTypes } from 'apps/cms/lib/bulk'
import { FsQueryOrderField, FsQueryOrderType, ViewDisplayMode } from 'apps/cms/lib/constants'
import { FlowTriggerStatus, FsFilter } from 'shared/api/requests/cms/schemas'
import { analyticsService } from 'utils/services/analytics'

interface BulkParams {
  count: number
  type?: BulkTypes
}

interface SearchFlowsParams {
  search: string
  triggerFilters: Array<FsFilter['filters']>
  triggerStatus: FlowTriggerStatus | null
}

export const CmsAnalytics = {
  /* Flow Context Menu */
  logMoveFlow: () => analyticsService.sendEvent('CMS.FLOW_CONTEXT_MENU.MOVE_FLOW'),
  logCloneFlow: () => analyticsService.sendEvent('CMS.FLOW_CONTEXT_MENU.CLONE_FLOW'),
  logExportFlow: () => analyticsService.sendEvent('CMS.FLOW_CONTEXT_MENU.EXPORT_FLOW'),
  logConvertChannels: () => analyticsService.sendEvent('CMS.OTHER_ACTIONS.CONVERT'),
  logRenameFlow: () => analyticsService.sendEvent('BUILDER.OTHER_ACTIONS.RENAME'),
  logDeleteFlow: () => analyticsService.sendEvent('BUILDER.OTHER_ACTIONS.DELETE'),
  logOpenShareFlowModal: () => analyticsService.sendEvent('SHARE_FLOW_MODAL.OPENED'),
  logCloseShareFlowModal: () => analyticsService.sendEvent('SHARE_FLOW_MODAL.CLOSED'),
  logOpenExportFlowModal: () => analyticsService.sendEvent('EXPORT_FLOW_MODAL.OPENED'),
  logCloseExportFlowModal: () => analyticsService.sendEvent('EXPORT_FLOW_MODAL.CLOSED'),
  logCompleteExportFlowModal: () => analyticsService.sendEvent('EXPORT_FLOW_MODAL.COMPLETE'),
  logRestoreFlow: () => analyticsService.sendEvent('BUILDER.OTHER_ACTIONS.RESTORE'),
  logPermanentDeleteFlow: () => analyticsService.sendEvent('CMS.OTHER_ACTIONS.PERMAMENTLY_DELETE'),

  /* Folder Context Menu */
  logMoveFolder: () => analyticsService.sendEvent('CMS.FOLDER_CONTEXT_MENU.MOVE_FOLDER'),
  logRenameFolder: () => analyticsService.sendEvent('CMS.FOLDER_CONTEXT_MENU.RENAME_FOLDER'),
  logDeleteFolder: () => analyticsService.sendEvent('CMS.FOLDER_REMOVED'),
  logCloneFolder: () => analyticsService.sendEvent('CMS.FOLDER_CONTEXT_MENU.CLONE_FOLDER'),

  /* Bulk Context Menu */
  logBulkMove: ({ count }: BulkParams) =>
    analyticsService.sendEvent('CMS.BULK_CONTEXT_MENU.MOVE', { count }),
  logBulkClone: ({ count }: BulkParams) =>
    analyticsService.sendEvent('CMS.BULK_CONTEXT_MENU.CLONE', { count }),
  logBulkDelete: ({ count, type }: BulkParams) =>
    analyticsService.sendEvent('CMS.BULK_DELETE.FLOWS_DELETED', { count, type }),
  logBulkRestore: ({ count }: BulkParams) =>
    analyticsService.sendEvent('CMS.BULK_RESTORE.FLOWS_RESTORE', { count }),

  /* Flow Filters */
  logSearchFlows: ({ search, triggerFilters, triggerStatus }: SearchFlowsParams) =>
    analyticsService.sendEvent('CMS.FLOW_FILTERS.SEARCH_FLOWS', {
      search,
      triggerFilters,
      triggerStatus,
    }),
  logClearAllFilters: () => analyticsService.sendEvent('CMS.FLOW_FILTERS.CLEAR_ALL_FILTERS'),

  /* Drag and Drop */
  logMoveFolderDnD: () => analyticsService.sendEvent('CMS.DND.MOVE_FOLDER_TO_FOLDER'),
  logMoveFlowDnD: () => analyticsService.sendEvent('CMS.DND.MOVE_FLOW_TO_FOLDER'),
  logBulkMoveFlowsDnD: ({ count }: BulkParams) =>
    analyticsService.sendEvent('CMS.DND.BULK.MOVE_FLOWS_TO_FOLDER', { count }),
  logMoveFolderToTrashDnD: () => analyticsService.sendEvent('CMS.DND.MOVE_FOLDER_TO_TRASH'),
  logMoveFlowToTrashDnD: () => analyticsService.sendEvent('CMS.DND.MOVE_FLOW_TO_TRASH'),
  logBulkMoveFlowsToTrashDnD: ({ count }: BulkParams) =>
    analyticsService.sendEvent('CMS.DND.BULK.MOVE_FLOWS_TO_TRASH', { count }),

  /* Others */
  logCreateAutomation: () => analyticsService.sendEvent('FLOW_TAB.NEW_FLOW.CLICKED'),
  logCreateAutomationModalSelectFlow: () =>
    analyticsService.sendEvent('MODAL_SELECT_FLOW.NEW_FLOW.CLICKED'),
  logCreateFolder: () => analyticsService.sendEvent('CMS.CREATE_FOLDER'),
  logCreateFolderWithContent: () => analyticsService.sendEvent('CMS.CREATE_FOLDER_WITH_CONTENT'),
  logOpenFolder: ({ id }: { id: number }) => analyticsService.sendEvent('CMS.OPEN_FOLDER', { id }),
  logOpenTrash: () => analyticsService.sendEvent('CMS.OPEN_TRASH'),
  logToggleDisplayMode: ({ displayMode }: { displayMode: ViewDisplayMode }) =>
    analyticsService.sendEvent('CMS.TOGGLE_DISPLAY_MODE', { displayMode: displayMode }),
  logSortFlows: ({ field, order }: { field: FsQueryOrderField; order: FsQueryOrderType }) =>
    analyticsService.sendEvent('CMS.SORT_FLOWS', { field, order }),
  logToggleTrigger: ({ value }: { value: boolean }) =>
    analyticsService.sendEvent('CMS.FLOW_CARD.TOGGLE_TRIGGER', { value }),
}

import React, { useEffect } from 'react'
import cx from 'classnames'
import { useAppDispatch, useAppSelector } from 'reduxTyped'
import { Icon, l } from '@manychat/manyui'

import { startVersionChecker } from 'common/core/actions/appActions'
import { getActualAppVersion } from 'common/core/selectors/appSelectors'
import { useDidMountEffect } from 'utils/commonHooks'
import localStorage from 'utils/localStorage'

import cm from './NotifyReloadBar.module.css'

const getIsNeedUpdate = (actualAppVersion: string) => {
  const currentAppVersion = window.STATIC_VERSION

  const actualIsValid = actualAppVersion && isFinite(parseInt(actualAppVersion))
  const currentIsValid = currentAppVersion && isFinite(currentAppVersion)

  return actualIsValid && currentIsValid && parseInt(actualAppVersion) > currentAppVersion
}

export const NotifyReloadBar = () => {
  const dispatch = useAppDispatch()
  const defaultBarHeight = 35
  const actualAppVersion = useAppSelector(getActualAppVersion)
  const [isClosed, setIsClosed] = React.useState(false)
  const isShowNotifyBar = getIsNeedUpdate(actualAppVersion)
  const isDisableNotifyBar = localStorage.getItem('disable_notify_bar')
  const isHideNotifyBar = !isShowNotifyBar || isClosed || isDisableNotifyBar

  const handleCloseClick = () => {
    setIsClosed(true)
  }

  const handleReloadClick = (event: React.MouseEvent<HTMLAnchorElement>): void => {
    event.preventDefault()

    window.location.reload()
  }

  const setBarHeight = (height: number) => {
    document.documentElement.style.setProperty('--notifications-reloadBar-height', `${height}px`)
  }

  useDidMountEffect(() => {
    dispatch(startVersionChecker())
  })

  useEffect(() => {
    const setBarHeightToZero = () => setBarHeight(0)
    const setBarHeightToDefault = () => setBarHeight(defaultBarHeight)

    isHideNotifyBar ? setBarHeightToZero() : setBarHeightToDefault()

    return setBarHeightToZero
  }, [isHideNotifyBar])

  if (isHideNotifyBar) {
    return null
  }

  return (
    <div className={cx(cm.wrapper, 'text-muted b-b text-center bg-white text-sm')}>
      <Icon.Notification size={16} className="m-r-xs" style={{ verticalAlign: 'text-bottom' }} />
      <span>
        {l.translate(
          'Manychat has been updated. Please, save your work and <a class="text-primary" onclick="handleReloadClick">reload</a> the page.',
          { handleReloadClick },
        )}
      </span>
      <Icon.Close onClick={handleCloseClick} className={cm.close} />
    </div>
  )
}

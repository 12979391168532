import React, { useState, useCallback, useEffect, useRef } from 'react'
import cx from 'classnames'
import { useAppDispatch } from 'reduxTyped'

import cm from 'apps/cms/components/Flows/components/CmsBreadcrumbs/components/BreadcrumbsButton/BreadcrumbsButton.module.css'
import { EMPTY_SEGMENT_LABEL } from 'apps/cms/components/Flows/components/CmsBreadcrumbs/lib/constants'
import { CmsBreadcrumbsSegment } from 'apps/cms/components/Flows/components/CmsBreadcrumbs/lib/types'
import { contentManagementActions } from 'apps/cms/store'
import { AutosizeInput } from 'components/AutosizeInput'

interface BreadcrumbsFolderNameInputProps {
  segment: CmsBreadcrumbsSegment
  hideInput: () => void
  setIsLoading: (value: boolean) => void
  onNameChange: (value: string) => void
}

export const BreadcrumbsFolderNameInput = ({
  segment,
  hideInput,
  setIsLoading,
  onNameChange,
}: BreadcrumbsFolderNameInputProps) => {
  const dispatch = useAppDispatch()

  const [newFolderName, setNewFolderName] = useState(segment.label)
  const valueWasInitialized = useRef(false)

  useEffect(() => {
    if (!valueWasInitialized.current && segment.label !== EMPTY_SEGMENT_LABEL) {
      setNewFolderName(segment.label)
      valueWasInitialized.current = true
    }
  }, [segment.label])

  useEffect(() => {
    onNameChange(newFolderName)
  }, [onNameChange, newFolderName])

  const updateFolderName = useCallback(async () => {
    if (newFolderName === segment.label) {
      hideInput()
      return
    }

    setIsLoading(true)

    hideInput()

    const result = await dispatch(
      contentManagementActions.renameFolder({
        path: segment.fsQueryPath,
        title: newFolderName,
      }),
    ).unwrap()

    if (!result) {
      setNewFolderName(segment.label)
    }

    setIsLoading(false)
  }, [dispatch, hideInput, segment.fsQueryPath, segment.label, setIsLoading, newFolderName])

  const onChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    setNewFolderName(event.target.value)
  }, [])

  const onKeydown = useCallback(
    (event: React.KeyboardEvent<HTMLInputElement>) => {
      if (event.keyCode === 27) {
        hideInput()
        setNewFolderName(segment.label)
      }

      if (event.keyCode === 13) {
        updateFolderName()
      }
    },
    [hideInput, segment.label, updateFolderName],
  )

  return (
    <AutosizeInput
      autoFocus
      inputClassName={cx('text-heading-2', cm.input)}
      value={newFolderName}
      onChange={onChange}
      onBlur={updateFolderName}
      onKeyDown={onKeydown}
    />
  )
}

import { useAppSelector } from 'reduxTyped'

import { ChannelStatus } from 'common/core/interfaces/channelStatuses'
import {
  getFBChannel,
  getInstagramChannel,
  getTikTokChannel,
  getTikTokChannelUsRegionRestricted,
} from 'common/core/selectors/channelSelectors'
import { isTestEnv } from 'utils/e2e'

export const useAccountPermissions = () => {
  const fbChannel = useAppSelector(getFBChannel)
  const igChannel = useAppSelector(getInstagramChannel)
  const ttChannel = useAppSelector(getTikTokChannel)
  const isTikTokChannelUsRegionRestricted = useAppSelector(getTikTokChannelUsRegionRestricted)

  const hasFbErrorStatus = fbChannel?.status === ChannelStatus.ERROR && !isTestEnv()
  const hasIgErrorStatus = igChannel?.status === ChannelStatus.ERROR && !isTestEnv()
  const hasTiktokErrorStatus =
    ttChannel?.status === ChannelStatus.ERROR && !isTikTokChannelUsRegionRestricted && !isTestEnv()

  const isNewIGApi = igChannel?.is_new_ig_api

  return {
    hasIgErrorStatus,
    hasFbErrorStatus,
    hasTiktokErrorStatus,
    isNewIGApi,
  }
}

import dot from 'dot-prop-immutable'
import each from 'lodash/each'
import last from 'lodash/last'

import { Action } from 'common/actions/models/Action/action'
import { parseSetFieldValue, exportSetFieldValue } from 'common/actions/models/Action/adapter'
import { ActionTypes } from 'common/actions/models/Action/constants'
import { IBlock, IBlockImage } from 'common/builder/blocks/blockInterfaces'
import { BlockType } from 'common/builder/constants/BlockType'
import { NodeType } from 'common/builder/constants/NodeType'
import {
  IBuilderFlowCampaignRelatedData,
  IBuilderFlowCampaignsBlock,
  IBuilderFlowCampaignsNode,
  IBuilderFlowCampaignsTrigger,
} from 'common/builder/models/FlowCampaign/flowCampaignInterfaces'
import { UserFieldsMutatingOperationSet } from 'common/fields/constants/UserFieldsTypes'
import { IFilterGroup } from 'common/filter/interfaces'
import { TriggerType } from 'common/flow/constants/TriggerType'
import { replaceVariables } from 'components/MCTextEditor/variables/helpers'

export const replaceBlockRelatedData = (
  block: IBuilderFlowCampaignsBlock,
  { userFieldMap, tagsMap, attachmentsMap }: IBuilderFlowCampaignRelatedData,
) => {
  const resultBlock = { ...block } as IBlock

  if (block.type === BlockType.FORM_QUESTION) {
    if (!block.customFieldId) {
      return block
    }
    const customFieldId = userFieldMap[block.customFieldId]?.field_id
    return { ...block, customFieldId }
  }

  if (block.type === BlockType.CASE) {
    if (!block.filter) {
      return block
    }

    const groups = block.filter.groups.map((group: IFilterGroup) => {
      const items = group.items.map((item) => {
        if (item.type === 'cuf' && item.field) {
          const origFieldId = parseFieldId(item.field)
          const fieldId = origFieldId ? userFieldMap[origFieldId]?.field_id : null
          const field = formatFieldId(fieldId)
          return { ...item, field }
        }
        if (item.type === 'tag' && item.value) {
          const value = tagsMap[item.value as number]?.tag_id
          return { ...item, value }
        }
        return item
      })
      return { ...group, items }
    })
    const filter = { ...block.filter, groups }
    return { ...block, filter }
  }

  if (block.type === BlockType.ATTACHMENT) {
    if (!block.attachment) {
      return block
    }
    const attachment = attachmentsMap[block.attachment.id]
    return { ...block, attachment }
  }

  const variablesReplaceMap: Record<string, string> = {}
  each(userFieldMap, (cuf, id) => {
    variablesReplaceMap[`cuf_${id}`] = `cuf_${cuf.field_id}`
  })
  if (resultBlock.type === BlockType.CARD) {
    if (block.image && resultBlock.image) {
      resultBlock.image = attachmentsMap[block.image.id] as IBlockImage
    }
    if (resultBlock.title) {
      resultBlock.title = replaceVariables(resultBlock.title, variablesReplaceMap)
    }
    if (resultBlock.subtitle) {
      resultBlock.subtitle = replaceVariables(resultBlock.subtitle, variablesReplaceMap)
    }
  }

  if (resultBlock.type === BlockType.TEXT) {
    if (resultBlock.text) {
      resultBlock.text = replaceVariables(resultBlock.text, variablesReplaceMap)
    }
  }

  return resultBlock
}

export const replaceNodeRelatedData = (
  node: IBuilderFlowCampaignsNode,
  { userFieldMap, tagsMap }: IBuilderFlowCampaignRelatedData,
) => {
  if (node.nodeType === NodeType.ACTION_GROUP) {
    const items = node.items?.map((item: Action) => {
      if (
        (item.type === ActionTypes.ADD_TAG || item.type === ActionTypes.REMOVE_TAG) &&
        item.tag_id
      ) {
        const tag_id = tagsMap[item.tag_id]?.tag_id
        return { ...item, tag_id }
      }

      /**
       * The id of a cuf inside quick campaign can be different from the created
       * cuf. So, we have to manually update the cuf id in the node.
       */
      if (
        (item.type === ActionTypes.SET_CUF || item.type === ActionTypes.UNSET_CUF) &&
        item.field_id
      ) {
        const field_id = userFieldMap[item.field_id]?.field_id

        /**
         * CUF value for increasing/decreasing number has the following format:
         * {{cuf_123}}+1.
         * We have to update a cuf id inside value to make it consistent with
         * field_id.
         */
        if (UserFieldsMutatingOperationSet.includes(item.operation)) {
          const exportedFieldValueWithCorrectId = exportSetFieldValue({
            ...item,
            field_id,
            value: parseSetFieldValue(item),
          })

          return {
            ...item,
            field_id,
            value: exportedFieldValueWithCorrectId,
          }
        }

        return { ...item, field_id }
      }
      return item
    })
    return { ...node, items }
  }
  return node
}

export const replaceTriggerRelatedData = (
  trigger: IBuilderFlowCampaignsTrigger,
  { userFieldMap }: IBuilderFlowCampaignRelatedData,
) => {
  if (trigger.nodeType === TriggerType.RULE) {
    const triggers = trigger.triggers.map((item) => {
      const fieldToReplace = item.data?.event_filter?.field
      if (!fieldToReplace) {
        return item
      }

      const filedId = parseFieldId(fieldToReplace)
      const userFieldId = filedId ? userFieldMap[filedId]?.field_id : null
      const field = formatFieldId(userFieldId)
      return dot.set(item, 'data.event_filter.field', field)
    })
    return { ...trigger, triggers }
  }
  return trigger
}

export const parseFieldId = (fieldId: string | number) => {
  if (typeof fieldId === 'number') {
    return fieldId
  }
  const parsedFieldId = last(fieldId.split('_'))
  return parsedFieldId ? parseInt(parsedFieldId) : null
}

export const formatFieldId = (fieldId?: number | null) => {
  return fieldId ? `cuf_${fieldId}` : null
}

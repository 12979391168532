import { ISystemKeywordsState } from 'apps/systemKeywords/systemKeywordsInterfaces'
import { IActionWithPayload } from 'common/core/interfaces/actions'
import * as actionTypes from 'common/settings/SettingsReduxActionTypes'

export const getInitialSystemKeywordsState = (): ISystemKeywordsState => ({
  id: null,
  name: '',
  isFetched: false,
  fetching: false,
})

const initialState = getInitialSystemKeywordsState()

export function systemKeywordsReducer(state = initialState, action: IActionWithPayload) {
  switch (action.type) {
    case `${actionTypes.SYSTEM_KEYWORDS_SUBSCRIBE}_REQUEST`:
    case `${actionTypes.SYSTEM_KEYWORDS_UNSUBSCRIBE}_REQUEST`:
      return { ...state, fetching: true }
    case `${actionTypes.SYSTEM_KEYWORDS_SUBSCRIBE}_RESPONSE`:
    case `${actionTypes.SYSTEM_KEYWORDS_UNSUBSCRIBE}_RESPONSE`: {
      const item = action.data
      return { ...state, ...item, isFetched: true, fetching: false }
    }
  }
  return state
}

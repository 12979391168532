import get from 'lodash/get'

import { Initial } from './constants'

export const DEPRECATED_makeLimitedListSelectors = (storePath) => {
  const selectors = Object.keys(Initial).reduce((result, item) => {
    if (item === 'byId') {
      item = 'map'
    }
    const methodName = `get${item.charAt(0).toUpperCase() + item.slice(1)}`
    result[methodName] = (state) => get(state, storePath)[item]
    return result
  }, {})

  return { ...selectors, getById: (state, id) => selectors.getMap(state)[id] }
}

import React from 'react'
import cx from 'classnames'
import { useAppDispatch } from 'reduxTyped'
import { Icon, l } from '@manychat/manyui'

import { makeAccountFavorite } from 'apps/profile/actions/profileTypedActions'

import cm from './FavoriteButton.module.css'

interface FavoriteButtonProps {
  accountId: AccountId
  isFavorite: boolean
  isHidden: boolean
  className?: string
  iconSize?: number
}

const FavoriteButton = ({
  accountId,
  isFavorite,
  isHidden,
  className,
  iconSize,
}: FavoriteButtonProps) => {
  const dispatch = useAppDispatch()

  const handleClick = (e: React.MouseEvent) => {
    // propagation is stopped for ability to use this component inside clickable blocks (in sidebar)
    e.stopPropagation()
    dispatch(makeAccountFavorite(accountId, !isFavorite))
  }

  const IconComponent = isFavorite ? Icon.Unpin : Icon.Pin
  const title = React.useMemo(() => {
    if (isHidden) {
      return l.translate('Please, unhide this account if you want to pin it')
    }
    if (isFavorite) {
      return l.translate('Unpin account from the sidebar')
    }
    return l.translate('Pin account in the sidebar')
  }, [isHidden, isFavorite])

  return (
    <button
      type="button"
      disabled={isHidden}
      className={cx(
        cm.button,
        isHidden ? cm.disabledbutton : cm.enabledButton,
        'p-y-xs p-x',
        className,
      )}
      onClick={handleClick}
      data-title={title}
    >
      <IconComponent className={cm.icon} size={iconSize || 16} />
    </button>
  )
}

export default FavoriteButton

import OnboardingBase from 'utils/services/newOnboardingService/core/OnboardingBase'
import StepController from 'utils/services/newOnboardingService/core/StepController'
import StepSystem from 'utils/services/newOnboardingService/core/steps/StepSystem'
import OnboardingId from 'utils/services/newOnboardingService/OnboardingId'

export type FirstLiveChatThreadTourSteps = {
  STEP_1: StepSystem
  STEP_2: StepSystem
  STEP_3: StepSystem
  STEP_4: StepSystem
  STEP_5: StepSystem
  STEP_6: StepSystem
}

export default class FirstLiveChatThreadTour extends OnboardingBase<FirstLiveChatThreadTourSteps> {
  constructor() {
    super(
      OnboardingId.LC_WA_FIRST_THREAD_TOUR,
      () =>
        new StepController<FirstLiveChatThreadTourSteps>('STEP_1', {
          STEP_1: () => new StepSystem(() => this.setStep('STEP_2')),
          STEP_2: () => new StepSystem(() => this.setStep('STEP_3')),
          STEP_3: () => new StepSystem(() => this.setStep('STEP_4')),
          STEP_4: () => new StepSystem(() => this.setStep('STEP_5')),
          STEP_5: () => new StepSystem(() => this.setStep('STEP_6')),
          STEP_6: () => new StepSystem(() => this.complete()),
        }),
    )
  }
}

import React from 'react'
import { l } from '@manychat/manyui'

export const Message = (
  <div className="text-center p-y-xs">{l.translate('Going to leave without saving?')}</div>
)

const WIDTH = 460

const BaseConfirmOptions = {
  width: WIDTH,
  confirmLabel: l.translate('Leave'),
  cancelLabel: l.translate('Keep Editing'),
  noContentPadding: true,
  swapButtonsBehavior: true,
}

export const TriggerConfirmOptions = {
  ...BaseConfirmOptions,
}

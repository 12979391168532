import { l } from '@manychat/manyui'

import { TriggerType } from 'common/flow/constants/TriggerType'
import { parseLightFlow } from 'common/flow/models/Flow/parseLightFlow'
import { WelcomeMessage } from 'shared/api/requests/welcomeMessage/schemas'

import { IWelcomeMessageTrigger } from '../welcomeMessageInterfaces'

const welcomeMessageAdapter = (item: WelcomeMessage): IWelcomeMessageTrigger => {
  const { channel, content_holder, enabled, flow } = item
  const flowId = flow?.ns ?? null
  const caption = l.translate('Welcome Message')

  return {
    id: content_holder,
    triggerType: TriggerType.WELCOME_MESSAGE,
    triggerId: `${TriggerType.WELCOME_MESSAGE}-${channel}`,

    channel,
    enabled,
    ts_created: 0,
    caption,
    welcomeMessageId: content_holder,
    contentHolder: content_holder,
    chId: content_holder,
    flowId,
    clicked_unq: 0,
    content_holder: '',
    flow: flow ? parseLightFlow(flow) : null,
  }
}

export default welcomeMessageAdapter

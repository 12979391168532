import { GenericFieldType } from 'common/fields/types'
import { genericFieldConverter } from 'common/fields/utils/GenericFieldConverter'

const subscriberDependentFieldTypes = [GenericFieldType.SYSTEM, GenericFieldType.USER]

export const getIsSubscriberDependentField = (rawVariable: string): boolean => {
  const parsedVariable = genericFieldConverter.fromString(rawVariable)

  return parsedVariable !== null && subscriberDependentFieldTypes.includes(parsedVariable.type)
}

import { createSelector } from 'reselect'

import { ConversationFilterType } from 'apps/chat/constants/LiveChatInterfaces'
import { getUserId } from 'common/core/selectors/appSelectors'

export const getUsersCounters = (state: RootState) => state.chat.counters.users

export const getUnreadThreadsState = (state: RootState) => state.chat.counters.unreadThreads

export const getIsFetchingCounters = (state: RootState) => state.chat.counters.isFetching
export const getIsThreadsAssignmentChanged = (state: RootState) =>
  state.chat.counters.isThreadsAssignmentChanged

export const getAssignedOpenThreadCountersByUserId = createSelector(
  [getUsersCounters],
  (usersCounters) => {
    if (!usersCounters) return null

    const userCountersById: Record<number, number> = {}

    usersCounters.forEach(
      (user) => (userCountersById[user.user_id] = user.thread_counters.user.assigned_open),
    )

    return userCountersById
  },
)

export const getUnassignedOpenThreadsCounter = createSelector(
  [getUsersCounters, getUserId],
  (usersCounters, userID) => {
    if (!usersCounters) return null

    const currentUserCounters = usersCounters?.find((user) => userID === user.user_id)

    return currentUserCounters?.thread_counters?.account?.unassigned_open || 0
  },
)

export const getOpenThreadsCountAssignedToCurrentUser = createSelector(
  [getAssignedOpenThreadCountersByUserId, getUserId],
  (assignedOpenThreadsCountById, userId) => {
    return (userId && assignedOpenThreadsCountById?.[userId]) || 0
  },
)

export const getThreadsCounterForTab = createSelector(
  [getUnassignedOpenThreadsCounter, getOpenThreadsCountAssignedToCurrentUser],
  (unassignedOpenCounter, assignedToMeCurrentCounter) => {
    return assignedToMeCurrentCounter || unassignedOpenCounter || 0
  },
)

export const getAbsoluteNumberOfThreads = createSelector(
  [getUsersCounters, getUserId],
  (usersCounters, userId) => {
    const currentUserCounters = usersCounters?.find((user) => user.user_id === userId)

    return currentUserCounters?.thread_counters.account.all || 0
  },
)

export const getCurrentUserThreadCounters = createSelector(
  [getUsersCounters, getUserId],
  (usersCounters, currentUserId) => {
    return usersCounters?.find((userCounters) => userCounters.user_id === currentUserId) || null
  },
)

export const getThreadsCountersByConversationFilter = createSelector(
  [getCurrentUserThreadCounters],
  (currentUserCounters) => {
    if (!currentUserCounters) return null

    const {
      user,
      account: { all_open, assigned_open, unassigned_open },
    } = currentUserCounters.thread_counters

    return {
      [ConversationFilterType.ALL]: all_open,
      [ConversationFilterType.TEAM]: assigned_open,
      [ConversationFilterType.UNASSIGNED]: unassigned_open,
      [ConversationFilterType.YOU]: user.assigned_open,
    }
  },
)

export const GroupId = {
  GENERALS: 'generals',
  GROWTH_TOOLS: 'growth_tools',
  KEYWORDS: 'keywords',
  INTENTS: 'intents',
  SEQUENCES: 'sequences',
  FLOWS: 'flows',
  TRIGGERS: 'triggers',
  EXTERNAL_TRIGGERS: 'external_triggers',
}

export const GROUPS = [
  { id: GroupId.GENERALS, name: 'General' },
  { id: GroupId.GROWTH_TOOLS, name: 'Growth Tools' },
  { id: GroupId.KEYWORDS, name: 'Keywords' },
  { id: GroupId.INTENTS, name: 'Intents' },
  { id: GroupId.SEQUENCES, name: 'Sequences' },
  { id: GroupId.FLOWS, name: 'Flows' },
  { id: GroupId.TRIGGERS, name: 'Rules' },
  { id: GroupId.EXTERNAL_TRIGGERS, name: 'External Triggers' },
]

export const EXTRA_GROUPS = [
  { id: 'tags', name: 'Tags' },
  { id: 'zapier_events', name: 'Zapier Events' },
  { id: 'custom_fields', name: 'Custom User Fields' },
]

export const ALL_GROUPS = [...GROUPS, ...EXTRA_GROUPS]

export const GROUP_LABELS_MAP = ALL_GROUPS.reduce((res, g) => {
  res[g.id] = g.name
  return res
}, {})

export const LIMIT_COUNTERS_MAP = {
  max_sequences_num: 'sequences',
  max_tags_num: 'tags',
  max_customFields_num: 'custom_fields',
}

import debugConstructor from 'debug'

import Exporter from './Exporter'
import Parser from './Parser'
import { getBatchRootContentId } from './utils'

const debug = debugConstructor('batch')

/**
 * New Batch format
 * @typedef {object} Batch
 * @property {number|string} root_content
 * @property {array} contents
 *
 * @example
 * batch = { root_content: 420, contents: [content1, content2] }
 */

/**
 * Old Batch format
 * @typedef {array} OldBatch
 *
 * @example
 * batch = [rootContent, content1, content2]
 */

/**
 * @typedef {object} Parsed
 * @property {array} nodes
 * @property {array} blocks
 * @property {array} buttons
 * @property {array} urls
 */

/**
 * @param {Batch|OldBatch} batch
 * @param {object} options - { nodeIdMap, isPartialPatchParse }
 * @returns {Parsed|array}
 */
export const parseBatch = (batch, options = {}) => {
  const parser = new Parser(batch, options)
  const parsed = parser.process()
  parsed.rootId = getBatchRootContentId(batch, options)
  debug('parseBatch', { batch, parsed })
  return parsed
}

/**
 * @param {Parsed} parsed
 * @returns {Batch}
 */
export const exportBatch = (parsed, userId, options = {}) => {
  const exporter = new Exporter(parsed, userId, options)
  const batch = exporter.process()
  debug('exportBatch', { parsed, batch })
  return batch
}

export default {
  parseBatch,
  exportBatch,
}

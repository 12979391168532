import { Schema } from 'zod'

import { CreateFactorySchemaType } from 'shared/api/lib/types'
import { isLocal } from 'utils/isLocal'
import errorTrackingService from 'utils/services/errorTrackingService'

export const validateZodSchema = <Data, RequestSchema extends Schema<Data>>(
  schema: RequestSchema,
  data: Data,
  url: string,
  schemaType: CreateFactorySchemaType,
) => {
  return schema.safeParseAsync(data).then((result) => {
    if (!result.success) {
      if (isLocal()) {
        console.groupCollapsed(
          `%cZodError: ${schemaType} schema error in ${url}`,
          'color: white; background-color: #eb5038; padding: 2px 4px; border-radius: 4px;',
        )
        console.log(result.error.issues)
        console.groupEnd()
      }

      errorTrackingService.trackZodError(result.error, {
        schemaType,
        url,
      })
    }
  })
}

import { LegacyNewGlobalField } from 'common/fields/store/types'
import { NewGlobalField } from 'shared/api/requests/globalFields/schemas'
import { RoutePaths } from 'utils/router/constants'
import { isPathnameMatchRoute } from 'utils/router/tools'
import { analyticsService } from 'utils/services/analytics'

const GLOBAL_FIELDS_METRICS_MAP: Record<string, Record<string, string>> = {
  SETTINGS: {
    route: RoutePaths.SETTINGS,
    event: 'SETTINGS.BOT_FIELD.CREATE',
  },
  AUDIENCE: {
    route: RoutePaths.AUDIENCE_SCOPE,
    event: 'AUDIENCE.BOT_FIELD.CREATE',
  },
  CHAT: {
    route: RoutePaths.CHAT,
    event: 'LIVE_CHAT.BOT_FIELD.CREATE',
  },
  CMS_BUILDER: {
    route: RoutePaths.CMS_BUILDER,
    event: 'FLOW.BOT_FIELD.CREATE',
  },
}

export const logFieldCreation = () => {
  const currentRouteKey = Object.keys(GLOBAL_FIELDS_METRICS_MAP).find((routeKey) =>
    isPathnameMatchRoute(GLOBAL_FIELDS_METRICS_MAP[routeKey]?.route),
  )

  if (currentRouteKey && GLOBAL_FIELDS_METRICS_MAP[currentRouteKey]) {
    analyticsService.sendEvent(GLOBAL_FIELDS_METRICS_MAP[currentRouteKey].event)
  }
}

export const exportNewGlobalField = (field: LegacyNewGlobalField): NewGlobalField => ({
  type: field.type,
  path: field.folderPath,
  caption: field.title,
  value: field.value === '' ? null : field.value,
  description: field.description,
})

export const MENU_LIST_FETCH = 'MENU_LIST_FETCH'
export const MENU_LIST_FETCH_REQUEST = 'MENU_LIST_FETCH_REQUEST'
export const MENU_LIST_FETCH_RESPONSE = 'MENU_LIST_FETCH_RESPONSE'
export const MENU_LIST_FETCH_ERROR = 'MENU_LIST_FETCH_ERROR'

export const MENU_CREATE = 'MENU_CREATE'
export const MENU_CREATE_REQUEST = 'MENU_CREATE_REQUEST'
export const MENU_CREATE_RESPONSE = 'MENU_CREATE_RESPONSE'
export const MENU_CREATE_ERROR = 'MENU_CREATE_ERROR'

export const MENU_DELETE = 'MENU_DELETE'
export const MENU_DELETE_REQUEST = 'MENU_DELETE_REQUEST'
export const MENU_DELETE_RESPONSE = 'MENU_DELETE_RESPONSE'
export const MENU_DELETE_ERROR = 'MENU_DELETE_ERROR'

export const MENU_RENAME = 'MENU_RENAME'
export const MENU_RENAME_REQUEST = 'MENU_RENAME_REQUEST'
export const MENU_RENAME_RESPONSE = 'MENU_RENAME_RESPONSE'
export const MENU_RENAME_ERROR = 'MENU_RENAME_ERROR'

export const MENU_DELETE_NOTIFICATION = 'MENU_DELETE_NOTIFICATION'
export const MENU_CREATE_NOTIFICATION = 'MENU_CREATE_NOTIFICATION'

export const MENU_LOAD = 'MENU_LOAD'
export const MENU_LOAD_REQUEST = 'MENU_LOAD_REQUEST'
export const MENU_LOAD_RESPONSE = 'MENU_LOAD_RESPONSE'
export const MENU_LOAD_ERROR = 'MENU_LOAD_ERROR'

export const MENU_PUBLISH = 'MENU_PUBLISH'
export const MENU_PUBLISH_REQUEST = 'MENU_PUBLISH_REQUEST'
export const MENU_PUBLISH_RESPONSE = 'MENU_PUBLISH_RESPONSE'
export const MENU_PUBLISH_ERROR = 'MENU_PUBLISH_ERROR'

export const MENU_DISCARD_DRAFT = 'MENU_DISCARD_DRAFT'
export const MENU_DISCARD_DRAFT_REQUEST = 'MENU_DISCARD_DRAFT_REQUEST'
export const MENU_DISCARD_DRAFT_RESPONSE = 'MENU_DISCARD_DRAFT_RESPONSE'
export const MENU_DISCARD_DRAFT_ERROR = 'MENU_DISCARD_DRAFT_ERROR'

export const MENU_SWITCH_ENABLED = 'MENU_SWITCH_ENABLED'
export const MENU_SWITCH_ENABLED_REQUEST = 'MENU_SWITCH_ENABLED_REQUEST'
export const MENU_SWITCH_ENABLED_RESPONSE = 'MENU_SWITCH_ENABLED_RESPONSE'
export const MENU_SWITCH_ENABLED_ERROR = 'MENU_SWITCH_ENABLED_ERROR'

export const MENU_SET_SERVER_ERROR = 'MENU_SET_SERVER_ERROR'

export const MENU_AUTO_SAVE_REQUEST = 'MENU_AUTO_SAVE_REQUEST'
export const MENU_AUTO_SAVE_SUCCESS = 'MENU_AUTO_SAVE_SUCCESS'
export const MENU_AUTO_SAVE_ERROR = 'MENU_AUTO_SAVE_ERROR'

export const MENU_ITEM_SELECT = 'MENU_ITEM_SELECT'
export const MENU_SHOW_ERRORS = 'MENU_SHOW_ERRORS'

export const MENU_ITEM_CREATE = 'MENU_ITEM_CREATE'
export const MENU_ITEM_REMOVE = 'MENU_ITEM_REMOVE'
export const MENU_ITEM_UPDATE = 'MENU_ITEM_UPDATE'
export const MENU_ITEM_LINK_FLOW = 'MENU_ITEM_LINK_FLOW'
export const MENU_ITEM_UNLINK_FLOW = 'MENU_ITEM_UNLINK_FLOW'

export const MENU_REORDER_MENU_ITEM_ROOT = 'MENU_REORDER_MENU_ITEM_ROOT'

export const MENU_SET_NAME = 'MENU_SET_NAME'
export const MENU_SET_NAME_RESPONSE = 'MENU_SET_NAME_RESPONSE'

export const MENU_NOT_PUBLISHED_FOR_DISABLED_CHANNEL = 'MENU_NOT_PUBLISHED_FOR_DISABLED_CHANNEL'

export enum EntityType {
  FLOW = 'flow',
  KEYWORD = 'keyword',
  MENU = 'main_menu_flow', // menu item on map
  RULE = 'trigger_rule',
  GROWTH_TOOL = 'growth_tool',
  SEQUENCE = 'sequence',
  WELCOME_MESSAGE = 'wm',
  DEFAULT_REPLY = 'dr',
  MAIN_MENU = 'mm', // menu source in geteral start
  NOTE = 'note',
  GROUP = 'frame',
  TEMPLATE_INSTALLATION = 'template_installation',
  GENERAL_SETTINGS = 'general',
  TEMPLATE = 'template',
  ADS_GT = 'ads_growth_tool',
  PORTAL_TO = 'portal',
  TAG = 'tag',
}

import React, { useCallback, useEffect, useRef } from 'react'
import cx from 'classnames'
import { LayersUtils } from '@manychat/manyui'

import { getBlockingElementArea } from 'utils/services/newOnboardingService/view/Pointer/pointerHelpers'
import { IArea } from 'utils/services/newOnboardingService/view/Pointer/pointerInterfaces'

import SelectionArea from './SelectionArea'

import cm from './PointerBlockArea.module.css'

const BORDER_WIDTH = 3

const getStyle = (top: number, left: number, width: number, height: number) => ({
  top: `${top}px`,
  left: `${left}px`,
  width: `${width}px`,
  height: `${height}px`,
})

const isClickInsideArea = (x: number, y: number, rect: DOMRect) => {
  return x > rect.left && x < rect.right && y > rect.top && y < rect.bottom
}

const PointerBlockArea = ({
  area,
  padding = 0,
  className,
  blockAreaInput = false,
  onClickOutside,
  stepId,
}: {
  area: IArea | undefined
  padding?: number
  blockAreaInput?: boolean
  className?: { selectionArea?: string; blockingArea?: string }
  stepId: string
  onClickOutside?: () => void
}) => {
  const wholeScreenArea = { left: 0, top: 0, width: window.innerWidth, height: window.innerHeight }
  const isValidAreaSize = area && area?.width > 0 && area?.height > 0
  const areaRef = useRef<HTMLDivElement>(null)
  const areaEl = areaRef.current

  const blockingList =
    area && isValidAreaSize
      ? getBlockingElementArea(
          area,
          {
            width: window.innerWidth,
            height: window.innerHeight,
          },
          padding - BORDER_WIDTH,
        )
      : [wholeScreenArea]

  const handleLayerClick = useCallback(
    (e: MouseEvent & { stopPropagationToLayers: () => void }) => {
      if (
        areaEl &&
        !blockAreaInput &&
        isClickInsideArea(e.clientX, e.clientY, areaEl.getBoundingClientRect())
      ) {
        return
      }

      e.stopPropagationToLayers()
    },
    [areaEl, blockAreaInput],
  )

  useEffect(() => {
    LayersUtils.eventsManager.setBeforeClickHandler(handleLayerClick)
    return () => {
      LayersUtils.eventsManager.setBeforeClickHandler(null)
    }
  }, [handleLayerClick])

  return (
    <div className={cm.root} data-test-id="onboarding-pointer-block-area">
      {blockingList.map((blockArea, i) => (
        <div
          key={i}
          className={cx('p-absolute', className?.blockingArea)}
          style={getStyle(blockArea.top, blockArea.left, blockArea.width, blockArea.height)}
          onClick={onClickOutside}
        />
      ))}

      {area && isValidAreaSize && (
        <div
          ref={areaRef}
          className={cx(
            'p-absolute rounded',
            { [cm.unblockInput]: !blockAreaInput },
            className?.selectionArea,
          )}
          style={getStyle(
            area.top - padding,
            area.left - padding,
            area.width + padding * 2,
            area.height + padding * 2,
          )}
        >
          <SelectionArea
            width={area.width + padding * 2}
            height={area.height + padding * 2}
            borderWidth={BORDER_WIDTH}
            borderRadius={4}
            leftColor="#6047EC"
            rightColor="#9B51E0"
            stepId={stepId}
          />
        </div>
      )}
    </div>
  )
}

export default PointerBlockArea

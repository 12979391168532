import { l } from '@manychat/manyui'

import { ValueLabel } from 'common/core/interfaces/actions'
import { Price } from 'shared/api/requests/billing/schemas'

interface ValueLabelWithDisabled<T> extends ValueLabel<T> {
  disabled?: boolean
}

export const getDailyLimitOptions = (
  refillAmount: number,
  options: Array<ValueLabel<number>>,
): Array<ValueLabelWithDisabled<number>> =>
  options.map((option) => (option.value >= refillAmount ? option : { ...option, disabled: true }))

export const getAddFundsOptions = (
  dailyLimit: number,
  options: Array<ValueLabel<number>>,
): Array<ValueLabelWithDisabled<number>> =>
  options.map((option) => (option.value > dailyLimit ? { ...option, disabled: true } : option))

export const parseOptionsToValueLabel = (options: Price[]): Array<ValueLabel<number>> =>
  options.map((option) => ({
    label: l.currency(option.amount, option.currency, 0),
    value: option.amount,
  }))

export const getFirstOptionsValue = (arr: Price[], fallback: number) =>
  arr.length > 0 ? arr[0].amount : fallback

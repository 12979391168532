import { Request } from 'utils/api/query/types'

export const makeRequest = <T>(id: string): Request<T> => ({ id, status: 'idle' })

export const setLoading = <T>(request: Request<T>): Request<T> => ({
  ...request,
  status: 'loading',
})

export const setData = <T>(request: Request<T>, data: T): Request<T> => ({
  ...request,
  status: 'success',
  data,
})

export const setError = <T>(request: Request<T>, error: Error): Request<T> => ({
  ...request,
  status: 'error',
  error,
})

export const sendRequest = async <T>(
  requestFn: () => Promise<T>,
  request: Request<T>,
  setRequest: (request: Request<T>) => void,
) => {
  if (request.status === 'loading') {
    return
  }

  const loadingRequest = setLoading(request)
  setRequest(loadingRequest)

  try {
    const data = await requestFn()
    const successRequest = setData(loadingRequest, data)
    setRequest(successRequest)

    return successRequest
  } catch (error) {
    const errorRequest = setError(loadingRequest, error as Error)
    setRequest(errorRequest)

    return errorRequest
  }
}

import React from 'react'
import cx from 'classnames'

import { getTooltipPosition } from 'utils/services/newOnboardingService/view/Pointer/pointerHelpers'
import { IArea, ITooltip } from 'utils/services/newOnboardingService/view/Pointer/pointerInterfaces'

import cm from './Tooltip.module.css'

const classPositionMap: Record<'top' | 'bottom' | 'left' | 'right', string> = {
  top: cm.atTop,
  bottom: cm.atBottom,
  left: cm.atLeft,
  right: cm.atRight,
}

const Tooltip = ({
  area,
  tooltip,
  padding = 0,
  stepId,
}: {
  area: IArea
  tooltip: ITooltip
  padding?: number
  stepId?: string
}) => {
  const pos = getTooltipPosition(area, padding, tooltip.at)
  const posClass = classPositionMap[tooltip.at]
  const { areaShift } = tooltip

  return (
    <div
      key={stepId}
      data-test-id="onboarding-tooltip"
      className={cx(cm.root, posClass, 'd-flex flex-col')}
      style={{
        top: areaShift?.top ? `${pos.top + areaShift?.top}px` : `${pos.top}px`,
        left: areaShift?.left ? `${pos.left + areaShift?.left}px` : `${pos.left}px`,
      }}
    >
      {tooltip.component}
    </div>
  )
}

export default Tooltip

import { createSlice } from '@reduxjs/toolkit'

import * as atypes from 'common/actions/integrations/common/constants/IntegrationsReduxActionTypes'

export interface HotmartIntegrationState {
  isConnecting: boolean
  isDisconnecting: boolean
  showConnectionModal: boolean
}

const initialState: HotmartIntegrationState = {
  isConnecting: false,
  isDisconnecting: false,
  showConnectionModal: false,
}

export const { reducer: hotmartReducer, actions: hotmartIntegrationActions } = createSlice({
  name: 'hotmart',
  initialState,
  reducers: {
    showModal: (state) => {
      state.showConnectionModal = true
    },
    hideModal: (state) => {
      state.showConnectionModal = false
    },
  },
  extraReducers: (builder) => {
    builder.addCase(atypes.INTEGRATION_CONNECT_BY_TOKEN_REQUEST, (state) => {
      state.isConnecting = true
    })
    builder.addCase(atypes.INTEGRATION_CONNECT_BY_TOKEN_ERROR, (state) => {
      state.isConnecting = false
    })
    builder.addCase(atypes.INTEGRATION_CONNECT_BY_TOKEN_RESPONSE, (state) => {
      state.isConnecting = false
    })
    builder.addCase(atypes.INTEGRATION_DISCONNECT_REQUEST, (state) => {
      state.isConnecting = true
    })
    builder.addCase(atypes.INTEGRATION_DISCONNECT_RESPONSE, (state) => {
      state.isConnecting = false
    })
  },
})

export const hotmartSelectors = {
  getIntegrationData: (state: RootState) => state.integrations.hotmart,
}

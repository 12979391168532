import * as externalTriggersSelectors from 'apps/externalTriggers/externalTriggersSelectors'
import * as growthToolsSelectors from 'apps/growthTools/selectors/growthToolsSelectors'
import { keywordsSelectors } from 'apps/keywords/keywordsSlice'
import * as triggerRulesSelectors from 'apps/triggerRules/selectors/triggerRulesSelectors'
import { TriggerType } from 'common/flow/constants/TriggerType'
import { IFlowTrigger } from 'common/flow/flowInterfaces'

export function hasChanges(state: RootState, trigger: IFlowTrigger) {
  if (trigger.triggerType === TriggerType.RULE) {
    return triggerRulesSelectors.hasChanges(state, trigger.id)
  }
  if (trigger.triggerType === TriggerType.KEYWORD) {
    return keywordsSelectors.hasChanges(state, trigger.id)
  }
  if (trigger.triggerType === TriggerType.WIDGET) {
    return (
      growthToolsSelectors.hasChanges(state, trigger.id) ||
      growthToolsSelectors.hasUnsavedDraft(state, trigger.id)
    )
  }
  if (trigger.triggerType === TriggerType.EXTERNAL_TRIGGER) {
    return (
      externalTriggersSelectors.hasChanges(state, trigger.id) ||
      externalTriggersSelectors.hasUnsavedDraft(state, trigger.id)
    )
  }

  return false
}

import React from 'react'
import ErrorCorrectLevel from 'qr.js/lib/ErrorCorrectLevel'
import QRCodeImpl from 'qr.js/lib/QRCode'

import {
  DEFAULT_PROPS,
  MARGIN_SIZE,
  PROP_TYPES,
  DEFAULT_LOGO_WIDTH,
  DEFAULT_LOGO_HEIGHT,
} from './constants'
import { convertStr, drawQrOnCanvas, uploadImageAsBase64 } from './helpers'

export default class QRCodeCanvas extends React.Component {
  constructor(props) {
    super(props)

    this._canvas = undefined
  }

  static propTypes = PROP_TYPES
  static defaultProps = DEFAULT_PROPS

  componentDidMount() {
    this.update()
  }

  componentDidUpdate() {
    this.update()
  }

  update() {
    const { value, size, level, bgColor, fgColor, includeMargin, img, removeLogoBackground } =
      this.props
    const logo = {
      src: null,
      width: DEFAULT_LOGO_WIDTH,
      height: DEFAULT_LOGO_HEIGHT,
    }

    const qrcode = new QRCodeImpl(-1, ErrorCorrectLevel[level])
    qrcode.addData(convertStr(value))
    qrcode.make()

    const hasFile = img && img.img_big
    const isEmptyImage = img === null

    //draw canvas with image
    if (hasFile) {
      uploadImageAsBase64(img.img_big).then((result) => {
        logo.src = result
        return drawQrOnCanvas(
          qrcode,
          this._canvas,
          size,
          includeMargin ? MARGIN_SIZE : 0,
          bgColor,
          fgColor,
          logo,
          removeLogoBackground,
        )
      })
    }

    //redraw canvas without image
    if (isEmptyImage) {
      logo.src = null
      drawQrOnCanvas(
        qrcode,
        this._canvas,
        size,
        includeMargin ? MARGIN_SIZE : 0,
        bgColor,
        fgColor,
        logo,
        removeLogoBackground,
      )
    }
  }

  handleCanvasRef = (ref) => {
    this._canvas = ref
  }

  render() {
    const { size, style, ...otherProps } = this.props
    const canvasStyle = { height: size, width: size, ...style }
    return (
      <canvas
        style={canvasStyle}
        height={size}
        width={size}
        ref={this.handleCanvasRef}
        {...otherProps}
      />
    )
  }
}

import { sendEntryToDataWarehouse } from 'utils/longTaskTracker/helpers'
import { LongTaskEvent, InitialApplicationState } from 'utils/longTaskTracker/interfaces'

export class LongTaskState {
  public longTasks: LongTaskEvent[] = []
  public startLogging = false
  public timeout: NodeJS.Timeout | null = null

  private readonly userId: number
  private readonly userAgent: string
  private readonly environment: string | null
  private readonly release: string | null
  private readonly version: number | null

  constructor(initialApplicationState: InitialApplicationState) {
    const { userId, environment, release, version, userAgent } = initialApplicationState
    this.userId = userId
    this.userAgent = userAgent
    this.environment = environment
    this.release = release
    this.version = version
  }

  public getInitialApplicationState() {
    return {
      userId: this.userId,
      userAgent: this.userAgent,
      environment: this.environment,
      release: this.release,
      version: this.version,
    }
  }

  public push(entry: LongTaskEvent) {
    this.longTasks.push(entry)
  }

  public setLongTasks = (longTasks: LongTaskEvent[]) => {
    this.longTasks = longTasks
  }

  public setStartLogging = (startLogging: boolean) => {
    this.startLogging = startLogging
  }

  public setTimeout = (timeout: NodeJS.Timeout | null) => {
    this.timeout = timeout
  }
}

export class LongTaskListener {
  private state: LongTaskState
  private readonly timeoutInterval = 10000

  constructor(state: LongTaskState) {
    this.state = state
  }

  public listenTaskChanges(entry: LongTaskEvent) {
    this.state.push(entry)

    if (!this.state.startLogging) {
      this.state.setStartLogging(true)

      const timeout = setTimeout(() => {
        const initialApplicationState = this.state.getInitialApplicationState()
        sendEntryToDataWarehouse(this.state.longTasks, initialApplicationState)

        this.state.setLongTasks([])
        this.state.setStartLogging(false)
      }, this.timeoutInterval)

      this.state.setTimeout(timeout)
    }
  }

  public clearListener() {
    if (this.state.timeout) {
      clearTimeout(this.state.timeout)
    }
    this.state.setLongTasks([])
    this.state.setStartLogging(false)
    this.state.setTimeout(null)
  }
}

export enum RegistrationSection {
  USER_SETUP = 'userSetup',
  CHANNEL_CONNECTION = 'channelConnection',
  CHANNEL_CONNECTION_SUCCESS = 'channelConnectionSuccess',
  SUCCESS = 'success',
}

export enum RegistrationAccountType {
  EXISTING = 'existing',
  NEW = 'new',
}

export enum AvailableForRegistrationChannel {
  INSTAGRAM = 'instagram',
  INSTAGRAM_META_BUSINESS = 'instagramMeta',
  TELEGRAM = 'telegram',
  FACEBOOK = 'facebook',
  WHATSAPP = 'whatsapp',
  TIKTOK = 'tiktok',
}

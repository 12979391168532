import React from 'react'
import cx from 'classnames'
import PropTypes from 'prop-types'

const ROOT_CLASSNAME = 'manyui-root'

export default class Provider extends React.Component {
  static propTypes = {
    children: PropTypes.any,
    className: PropTypes.string,
    style: PropTypes.object,
    withoutContainer: PropTypes.bool,
  }

  static defaultProps = {
    withoutContainer: false,
  }

  componentDidMount() {
    if (this.props.withoutContainer) {
      document.body.classList.add(ROOT_CLASSNAME)
    }
  }

  componentWillUnmount() {
    if (this.props.withoutContainer) {
      document.body.classList.remove(ROOT_CLASSNAME)
    }
  }

  render() {
    const { children, className, style, withoutContainer } = this.props

    if (withoutContainer) {
      return children
    }

    return (
      <div className={cx(ROOT_CLASSNAME, className)} style={style}>
        {children}
      </div>
    )
  }
}

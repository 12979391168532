import { contentManagementActions } from 'apps/cms/store'
import * as atypes from 'apps/storyMentionReply/actionTypes'
import { parseStoryMentionReply } from 'apps/storyMentionReply/parseStoryMentionReply'
import { MappedStoryMentionReply } from 'apps/storyMentionReply/storyMentionReplyInterfaces'
import { ChannelType } from 'common/core/constants/ChannelType'
import { IAsyncThunkAction } from 'common/core/interfaces/actions'
import { handleCatch } from 'shared/api/lib/errors/handlers'
import { StoryMentionReplyApi } from 'shared/api/requests/storyMentionReply'
import { navigatePollyfill } from 'utils/router/tools'
import { accountNotificationsListener } from 'utils/services/notificationsService'
import { linkURL } from 'utils/url'

export const loadStoryMentionReply = (): IAsyncThunkAction => {
  return async (dispatch) => {
    try {
      dispatch({
        type: `${atypes.STORY_MENTION_REPLY_FETCH}_REQUEST`,
      })

      const response = await StoryMentionReplyApi.getData()

      dispatch({
        type: `${atypes.STORY_MENTION_REPLY_FETCH}_RESPONSE`,
        data: parseStoryMentionReply(response.data),
      })
    } catch (error) {
      handleCatch(error)
    }
  }
}

export const updateStoryMentionReplyEnabled = (enabled: boolean): IAsyncThunkAction => {
  return async (dispatch) => {
    try {
      const { data } = await StoryMentionReplyApi.switch({
        query: { story_mention_enabled: enabled },
      })

      dispatch({
        type: `${atypes.STORY_MENTION_REPLY_UPDATE_ENABLED}_RESPONSE`,
        data: parseStoryMentionReply(data),
      })
    } catch (error) {
      handleCatch(error)
    }
  }
}

export const updateStoryMentionReplyAutoLike = (enabled: boolean): IAsyncThunkAction => {
  return async (dispatch) => {
    try {
      const { data } = await StoryMentionReplyApi.switchAutoLike({
        body: {
          story_mention_auto_like_enabled: enabled,
        },
      })

      dispatch({ type: atypes.STORY_MENTION_AUTO_LIKE, data: parseStoryMentionReply(data.data) })
    } catch (error) {
      handleCatch(error)
    }
  }
}

export const updateStoryMentionReplyDelay = (delay: number): IAsyncThunkAction => {
  return async (dispatch) => {
    try {
      const { data } = await StoryMentionReplyApi.updateDelay({
        body: { delay },
      })

      dispatch({ type: atypes.STORY_MENTION_DELAY, data: parseStoryMentionReply(data.data) })
    } catch (error) {
      handleCatch(error)
    }
  }
}

export const changeStoryMentionReplyType = (
  channel: ChannelType = ChannelType.FB,
  type: string,
): IAsyncThunkAction => {
  return async (dispatch) => {
    try {
      const response = await StoryMentionReplyApi.changeType({
        body: {
          channel,
          type,
        },
      })

      dispatch({
        type: `${atypes.STORY_MENTION_REPLY_CHANGE_TYPE}_RESPONSE`,
        data: parseStoryMentionReply(response.data),
      })
    } catch (error) {
      handleCatch(error)
    }
  }
}

export const setStoryMentionReplyFlow = (
  flow_ns?: string | null,
): IAsyncThunkAction<MappedStoryMentionReply | undefined> => {
  return async (dispatch) => {
    let result: MappedStoryMentionReply | undefined

    try {
      const { data } = await StoryMentionReplyApi.replaceFlow({
        body: { flow_ns },
      })

      result = parseStoryMentionReply(data)

      dispatch({
        type: `${atypes.STORY_MENTION_REPLY_SET_FLOW}_RESPONSE`,
        data: result,
      })
    } catch (error) {
      handleCatch(error)
    }

    return result
  }
}

export const createStoryMentionReplyFlow = (): IAsyncThunkAction<{ flow: { ns: string } }> => {
  return async (dispatch) => {
    const fileSystem = await dispatch(
      contentManagementActions.createAutomation({
        name: 'Story Mention Reply',
      }),
    ).unwrap()

    return dispatch(setStoryMentionReplyFlow(fileSystem?.flow?.ns)) as unknown as Promise<{
      flow: { ns: string }
    }>
  }
}

export const createStoryMentionReplyFlowAndOpenIt = (): IAsyncThunkAction => {
  return async (dispatch) => {
    const { flow } = await dispatch(createStoryMentionReplyFlow())

    navigatePollyfill(linkURL(`/cms/files/${flow.ns}`))
  }
}

export const createStoryMentionReplyQuickCampaign = (
  quickCampaignId: number,
  flowName = 'Story Mention Reply',
): IAsyncThunkAction<{ flow: { ns: string } }> => {
  return async (dispatch) => {
    const fileSystem = await dispatch(
      contentManagementActions.createAutomation({
        name: flowName,
        quickCampaignId,
      }),
    ).unwrap()

    return dispatch(setStoryMentionReplyFlow(fileSystem?.flow?.ns)) as unknown as Promise<{
      flow: { ns: string }
    }>
  }
}

export const removeStoryMentionReplyFlow = (): IAsyncThunkAction => {
  return async (dispatch) => {
    try {
      const { data } = await StoryMentionReplyApi.removeFlow()

      dispatch({
        type: `${atypes.STORY_MENTION_REPLY_REMOVE_FLOW}_RESPONSE`,
        data: parseStoryMentionReply(data),
      })
    } catch (error) {
      handleCatch(error)
    }
  }
}

accountNotificationsListener.on('story_mention_updated', (data, dispatch) => {
  dispatch({
    type: `${atypes.STORY_MENTION_REPLY_FETCH}_RESPONSE`,
    data: parseStoryMentionReply(data.model),
  })
})

import dot from 'dot-prop-immutable'
import clone from 'lodash/clone'
import { l } from '@manychat/manyui'

import { SELECTION_TYPE } from 'common/cms/constants/CMSSelectionType'
import { FSObjectType } from 'common/cms/models/FSObject/constants'
import { FSQueryDefaults } from 'common/cms/models/FSQuery/constants'

import * as atypes from '../constants/cmsReduxActionTypes'

const botContentFolder = {
  path: '/',
  type: FSObjectType.FOLDER,
  title: l.makeTranslate('Automations'),
}

const initialState = {
  fileSystem: {
    byPath: {
      [botContentFolder.path]: botContentFolder,
    },
    listings: {},
  },
  flowPicker: {
    fsQuery: clone(FSQueryDefaults),
    selectedFlowId: null,
  },
  displayMode: null,
  selectionType: SELECTION_TYPE.INCLUDE,
  selectedIds: {},
  lastInteractedId: null,
  availableFilters: null,
}

export default function cmsReducer(state = initialState, action) {
  switch (action.type) {
    // FILE SYSTEM OBJECT ACTIONS
    case atypes.FS_OBJECT_LOAD_SUCCESS:
    case atypes.FS_RENAME_OBJECT_SUCCESS: {
      const { item } = action
      return dot.merge(state, `fileSystem.byPath.${item.path}`, item)
    }
  }

  return state
}

export function updateByPathObject(byPath = {}, paths = [], items = []) {
  const filteredByPath = Object.entries(byPath).reduce(removePreviousPaths, {})
  const itemsByPath = items.reduce(fromEntriesByPath, {})
  return { ...filteredByPath, ...itemsByPath }

  function removePreviousPaths(byPath, [path, value]) {
    if (!paths.includes(path)) {
      byPath[path] = value
    }
    return byPath
  }

  function fromEntriesByPath(itemsByPath, item) {
    itemsByPath[item.path] = item
    return itemsByPath
  }
}

import { l } from '@manychat/manyui'

import { IBlock } from 'common/builder/blocks/blockInterfaces'
import { BlockType } from 'common/builder/constants/BlockType'
import { NodeType } from 'common/builder/constants/NodeType'
import BlockModel from 'common/builder/models/Block'
import BaseNodeConfig from 'common/builder/nodes/BaseNodeConfig'
import { INodeBase, ITikTokNode } from 'common/builder/nodes/nodeInterfaces'

class TiktokNodeConfig extends BaseNodeConfig<ITikTokNode> {
  nodeType = NodeType.TIKTOK

  isPro = false

  getCaption = () => l.translate('Send Message')
  getToolbarCaption = () => l.translate('TikTok')
  getCaptionWithCount = (n: number) =>
    l.translate('{n, plural, one {# TikTok Message} other {# TikTok Messages}}', { n })
  getNextStepCaption = () => l.translate('TikTok')

  icon = 'TikTokChannel'
  color = '#8492A6'
  headerColor = '#DCEEFF'
  stepsWhiteList = [
    NodeType.TIKTOK,
    NodeType.ACTION_GROUP,
    NodeType.CONDITION,
    NodeType.SPLIT,
    NodeType.SMART_DELAY,
    NodeType.GOTO,
  ]

  ALLOWED_BLOCK_TYPES = [
    BlockType.TEXT,
    BlockType.ATTACHMENT,
    BlockType.FORM_QUESTION,
    BlockType.DELAY,
  ]

  ALLOWED_BLOCK_MAX_COUNT = 10

  getDefaults = (): Omit<ITikTokNode, keyof INodeBase> => ({
    targetId: null,
    reasonId: null,
    type: 'default',
  })

  getInitialBlocks = () => {
    return [{ type: BlockType.TEXT as const }]
  }

  isEmpty = ({ node, blocks }: { node: ITikTokNode; blocks: IBlock[] }) => {
    if (node.targetId) {
      return false
    }
    return this.areBlocksEmpty(blocks)
  }

  _isEmptyBlock = (block: IBlock) => {
    return [BlockType.TEXT].includes(block.type) && BlockModel.isEmpty(block)
  }
}

export default new TiktokNodeConfig()

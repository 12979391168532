import { palettes } from '@manychat/manyui'

import BaseBlockConfig from 'common/builder/blocks/BaseBlockConfig'
import { IBlockBase, IEmailRootBlock } from 'common/builder/blocks/blockInterfaces'
import { BlockType } from 'common/builder/constants/BlockType'
import { EmailStyleType } from 'common/builder/nodes/emailNew/emailNodeConstants'

class EmailRootBlockConfig extends BaseBlockConfig {
  blockType = BlockType.EMAIL_ROOT

  getDefaults = (): Omit<IEmailRootBlock, keyof IBlockBase> => ({
    settings: {
      padding_side: { type: EmailStyleType.CUSTOM, value: 32 },
      background_color: { type: EmailStyleType.CUSTOM, value: palettes.typography.contentBG },
    },
  })
}

export default new EmailRootBlockConfig()

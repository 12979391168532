import { useEffect, useRef } from 'react'

import { accountNotificationsListener } from 'utils/services/notificationsService'

const TELEGRAM_BINDING_OPT_IN_EVENT = 'telegram_binding_opt_in_event'

const onBindReady = (optInCode: string, redirectUrl: string) => () => {
  window.location.assign(`/auth/binding/telegram/${optInCode}?return=${redirectUrl}`)
}

export const useTelegramBindingOptIn = (
  hasProfile: boolean,
  redirectUrl: string,
  optInCode: string | null,
) => {
  const onBindReadyRef = useRef<(() => void) | null>(null)

  useEffect(() => {
    if (hasProfile || !optInCode) {
      return
    }

    if (onBindReadyRef.current === null) {
      onBindReadyRef.current = onBindReady(optInCode, redirectUrl)
      accountNotificationsListener.on(TELEGRAM_BINDING_OPT_IN_EVENT, onBindReadyRef.current)
    }

    return () => {
      if (onBindReadyRef.current !== null) {
        accountNotificationsListener.removeListener(
          TELEGRAM_BINDING_OPT_IN_EVENT,
          onBindReadyRef.current,
        )
        onBindReadyRef.current = null
      }
    }
  }, [hasProfile, optInCode, redirectUrl])
}

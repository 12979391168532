import React from 'react'
import cx from 'classnames'
import PropTypes from 'prop-types'
import * as Icon from '@manychat/icons'

import cleanProps from '../../../lib/clean-react-props'
import { noop } from '../../../utils'
import { deprecatedDecorator } from '../../../utils/deprecated-decorator'
import { Hotkeys } from '../../../utils/hotkeys'
// eslint-disable-next-line no-unused-vars
import NumberInputV2 from '../NumberInputV2'

import cm from './NumberInput.module.css'

const NON_NUMBERS = /\D/g
const NEGATIVE_SIGN = '-'
const DECIMAL_DELIMITER = '.'

/** @deprecated use {@link NumberInputV2} component */
class NumberInput extends React.Component {
  constructor(props) {
    super(props)

    this.state = { shaking: false }

    this.hotkeyHandlers = [
      { matcher: 'ArrowUp', handler: this.handleIncValue },
      { matcher: 'ArrowDown', handler: this.handleDecValue },
    ]
  }

  static propTypes = {
    name: PropTypes.string,
    className: PropTypes.string,
    inputClassName: PropTypes.string,
    invalid: PropTypes.bool,
    defaultValue: PropTypes.number,
    disabled: PropTypes.bool,
    required: PropTypes.bool,
    minValue: PropTypes.number,
    maxValue: PropTypes.number,
    onBlur: PropTypes.func,
    integer: PropTypes.bool,
    positive: PropTypes.bool,
    maxSymbols: PropTypes.number,
    theme: PropTypes.object,
    hideArrows: PropTypes.bool,
    onChange: PropTypes.func,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  }

  static defaultProps = {
    className: '',
    inputClassName: '',
    theme: {},
    maxSymbols: 16,
    invalid: false,
    disabled: false,
    onBlur: noop,
  }

  state = {
    shaking: false,
  }

  blur = () => this.input && this.input.blur()
  focus = () => this.input && this.input.focus()
  select = () => this.input && (this.input.select ? this.input.select() : this.input.focus())

  shake = () => {
    this.setState({ shaking: false }, () => {
      setTimeout(() => this.setState({ shaking: true }))
    })
  }

  /**
   * @param {string} value
   * @returns {string}
   */
  normalizeValue = (value) => {
    const { minValue, maxSymbols, integer, required } = this.props

    value = `${value}`

    if (maxSymbols && value.length > maxSymbols) {
      value = value.slice(0, maxSymbols)
    }

    const result =
      (value.indexOf(NEGATIVE_SIGN) >= 0 ? NEGATIVE_SIGN : '') +
      value
        .split(DECIMAL_DELIMITER, 2)
        .map((numPart) => numPart.replace(NON_NUMBERS, ''))
        .slice(0, integer ? 1 : undefined)
        .join(DECIMAL_DELIMITER)

    if (!result) {
      return required ? `${minValue || 0}` : result
    }

    return result
  }

  /**
   * @param {string} value
   * @returns {string}
   */
  fixRange = (value) => {
    const { minValue, maxValue, defaultValue, positive } = this.props

    let numValue = Number(value)

    if (isNaN(numValue)) {
      numValue = 0
    }

    if (maxValue != null) {
      value = `${Math.min(numValue, maxValue)}`
    }

    if (minValue != null) {
      value = `${Math.max(numValue, minValue)}`
    }

    if (positive && value[0] === NEGATIVE_SIGN) {
      value = value.slice(1)
    }

    if (!Number.isFinite(numValue)) {
      value = `${defaultValue || 0}`
    }

    return value
  }

  /** @param {string} input */
  handleInputChange = (input) => {
    const { value: prevValue, name, onChange } = this.props
    let value = this.fixRange(this.normalizeValue(input))

    if (value.length === prevValue.length) {
      this.shake()
    }

    onChange({ target: { value, name } })
  }

  /** @param {number} step */
  handleIncrementalChange = (step) => {
    const { value: prevValue, positive, name, onChange } = this.props
    let value = this.normalizeValue(this.props.value)

    let [integerPart, decimalPart = ''] = value.split(DECIMAL_DELIMITER)

    integerPart = Number(integerPart)

    if (isNaN(integerPart)) {
      integerPart = 0
    }

    if (decimalPart) {
      decimalPart = `.${decimalPart}`
    }

    if (!(positive && step < 0 && integerPart === 0)) {
      value = this.fixRange(`${integerPart + step}${decimalPart}`)
    }

    if (value.length === prevValue.length) {
      this.shake()
    }

    onChange({ target: { value, name } })
  }

  handleIncValue = (e) => {
    e.preventDefault()

    this.handleIncrementalChange(1)
  }

  handleDecValue = (e) => {
    e.preventDefault()

    this.handleIncrementalChange(-1)
  }

  handleChange = (e) => {
    this.handleInputChange(e.target.value)
  }

  handleBlur = (e) => {
    this.handleInputChange(e.target.value)
    this.props.onBlur(e)
  }

  handleInputRef = (el) => (this.input = el)

  render() {
    const {
      className,
      inputClassName,
      invalid,
      onChange, //eslint-disable-line no-unused-vars
      theme,
      disabled,
      required, //eslint-disable-line no-unused-vars
      minValue, //eslint-disable-line no-unused-vars
      maxValue, //eslint-disable-line no-unused-vars
      maxSymbols, //eslint-disable-line no-unused-vars
      positive, //eslint-disable-line no-unused-vars
      integer, //eslint-disable-line no-unused-vars
      hideArrows,
      ...other
    } = this.props

    const { shaking } = this.state
    const rootCn = cx(cm.wrapper, theme.wrapper, className, { [cm.inputShake]: shaking })

    return (
      <Hotkeys handlers={this.hotkeyHandlers} elementProps={{ className: rootCn }}>
        <input
          {...cleanProps(other)}
          ref={this.handleInputRef}
          onChange={this.handleChange}
          onBlur={this.handleBlur}
          className={cx(cm.input, theme.input, inputClassName, { [cm.invalid]: invalid })}
          disabled={disabled}
        />
        {!hideArrows && (
          <div className={cx(cm.toolbar, theme.toolbar, { [cm.disabled]: disabled })}>
            <Icon.ArrowDropUp
              className={cx(cm.arrow, theme.arrow)}
              onMouseDown={disabled ? null : this.handleIncValue}
            />
            <Icon.ArrowDropDown
              className={cx(cm.arrow, theme.arrow)}
              onMouseDown={disabled ? null : this.handleDecValue}
            />
          </div>
        )}
      </Hotkeys>
    )
  }
}

export default deprecatedDecorator(NumberInput, { replacementComponentName: 'NumberInputV2' })

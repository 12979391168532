import * as atypes from 'apps/triggerRules/constants/TriggerRulesReduxActionTypes'
import { TriggerRuleStatusType } from 'apps/triggerRules/models/TriggerRule/constants'
import { ITriggerRule } from 'apps/triggerRules/triggerRulesInterfaces'
import { IAsyncThunkActionWithReturnValue } from 'common/core/interfaces/actions'
import mcApi from 'utils/api/mcApi/mcApi'

export const activateRuleWithoutValidation =
  (rule: ITriggerRule): IAsyncThunkActionWithReturnValue<boolean> =>
  async (dispatch) => {
    try {
      dispatch({ type: atypes.TRIGGERRULES_CHANGESTATUS_REQUEST, action: { id: rule } })
      await mcApi.post('/:currentAccountID/triggerRule/changeStatus', {
        body: { rule_id: rule.id, status: TriggerRuleStatusType.ACTIVE },
      })
      return true
    } catch (error) {
      dispatch({ type: atypes.TRIGGERRULES_CHANGESTATUS_ERROR, action: { id: rule } })
      return false
    }
  }

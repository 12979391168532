import { createContext, useContext, useMemo } from 'react'
import { z, ZodSchema } from 'zod'

import { InvalidContextError } from '../errors'
import { TSFixMe, ViewContext } from '../types'

export const ViewContextContext = createContext<ViewContext>({} as TSFixMe)

const parse = <Schema extends ZodSchema>(schema: Schema, value: unknown): z.infer<Schema> => {
  try {
    schema.parse(value)

    return value as z.infer<typeof schema>
  } catch (error) {
    throw new InvalidContextError(error)
  }
}

export const useViewContext = <Schema extends ZodSchema>(schema: Schema): z.infer<Schema> => {
  const value = useContext(ViewContextContext)

  return useMemo(() => parse(schema, value), [schema, value])
}

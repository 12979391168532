import has from 'lodash/has'
import isEqual from 'lodash/isEqual'
import omitBy from 'lodash/omitBy'

export const omitDefaultValues = <T extends Record<string, unknown>>(item: T, itemDefaults: T) => {
  return omitBy(
    item,
    (val, key: keyof T) => has(itemDefaults, key) && isEqual(val, itemDefaults[key]),
  ) as Partial<T>
}

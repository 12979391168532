import { WelcomeMessageSource } from 'apps/aiCommunications/store/types'
import { analyticsService } from 'utils/services/analytics'

export const AiWelcomeMessageAnalytics = {
  logModalOpened: (data: { source: WelcomeMessageSource }) =>
    analyticsService.sendEvent('AI_WELCOME_MESSAGE.OPENED', data),

  logModalClosed: (data: { source: WelcomeMessageSource }) =>
    analyticsService.sendEvent('AI_WELCOME_MESSAGE.CLOSED', data),

  logAiStepWizardOpened: (data: { source: WelcomeMessageSource }) =>
    analyticsService.sendEvent('AI_WELCOME_MESSAGE.AI_STEP_WIZARD_OPENED', data),

  logAiIntentsWizardOpened: (data: { source: WelcomeMessageSource }) =>
    analyticsService.sendEvent('AI_WELCOME_MESSAGE.AI_INTENTS_WIZARD_OPENED', data),
}

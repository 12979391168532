let accountIDGetter = () => ''

export const setAccountIDGetter = (getter: () => string) => (accountIDGetter = getter)

export const getAccountID = () => accountIDGetter()

export const getAccountIdFromURL = (): string | null => {
  let result = null
  const routeAccountId = getAccountID()
  const pathname = routeAccountId ? `/${routeAccountId}` : window.location.pathname
  if (pathname.startsWith('/fb')) {
    const accountId = pathname.split('/')?.[1]
    if (accountId) {
      result = accountId.replace(/[^\d]/g, '')
    }
  }
  return result
}

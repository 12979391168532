import React from 'react'
import cx from 'classnames'
import { Skeleton } from '@manychat/manyui'

import cm from './ProductList.module.css'

export const ProductSkeleton = () => {
  return (
    <li className={cx('panel panel-white p-y-md p-x-md m-0', cm.productCard)}>
      <section>
        <Skeleton.Text height={32} />

        <Skeleton.Text height={20} lines={3} className="m-t-sm" />

        <Skeleton.Text height={20} lines={2} className="m-t-sm" />
      </section>

      <div className="d-flex flex-col align-end">
        <Skeleton.Text height={32} />
      </div>
    </li>
  )
}

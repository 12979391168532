import { z } from 'zod'

import BackendContentType from 'common/builder/constants/BackendContentType'

const FlowContentsSchema = z.array(
  z.object({
    type: z.nativeEnum(BackendContentType),
    content_id: z.number(),
    caption: z.string(),
    namespace: z.string(),
    deleted: z.boolean(),
    ts_created: z.number(),
    ts_updated: z.number(),
    data: z.object({}),
  }),
)

export const FlowApiSchemas = {
  getFlowContentData: {
    query: z.object({
      ns: z.string(),
    }),
    path: z.undefined(),
    response: z.object({
      flow: z.object({
        contents: FlowContentsSchema,
        root_content_id: z.number().nullable(),
        ns: z.string(),
        page_id: z.string(),
      }),
    }),
  },
}

import { createAction } from '@reduxjs/toolkit'
import merge from 'lodash/merge'
import { createAppAsyncThunk } from 'reduxTyped'
import { l } from '@manychat/manyui'

import { UpgradeSource } from 'common/billing'
import { handleGoProError } from 'common/billing/helpers/handleGoProError'
import { alert } from 'common/core'
import { SettingsTabType } from 'common/settings/constants/SettingsTabType'
import { getTagsCountOnAccount } from 'common/tags/actions/getTagsCountOnAccount'
import { ExtraState } from 'common/tags/store/constants'
import { logTagCreation } from 'common/tags/store/lib'
import { TagsExtraState } from 'common/tags/store/types'
import { handleCatch } from 'shared/api/lib/errors/handlers'
import { applyHandlerByGuard, reportUnhandledErrors } from 'shared/api/lib/errors/utils'
import { mapResponse } from 'shared/api/lib/mapResponse'
import { TagsApi } from 'shared/api/requests/tags'
import { TagsApiGuards } from 'shared/api/requests/tags/guards'
import { Tag, TagType } from 'shared/api/requests/tags/schemas'
import { createListSlice } from 'shared/lib/factory/redux/createListSlice'
import { createListOperations } from 'shared/lib/factory/redux/createListSlice/lib/createListOperations'
import { getClientId } from 'utils/clientId'
import { getQuery, navigatePollyfill } from 'utils/router/tools'
import { anotherTabNotificationsListener } from 'utils/services/notificationsService'
import { linkURL } from 'utils/url'

const namespace = 'tags'

const openCreateTagModal = createAction<{
  tagName?: string
  source: TagsExtraState['createTagModal']['source']
}>(`${namespace}/openCreateTagModal`)
const closeCreateTagModal = createAction(`${namespace}/closeCreateTagModal`)
const setLastCreatedTag = createAction<Tag>(`${namespace}/setLastCreatedTag`)

const setSearchValue = createAction<string>(`${namespace}/setSearchValue`)

const setIsSettingsTab = createAction<boolean>(`${namespace}/setIsSettingsTab`)

const {
  reducer,
  actions: baseActions,
  selectors: baseSelectors,
  initialState: TagsInitialState,
} = createListSlice({
  namespace: 'tags',
  storePath: 'tags',

  operations: createListOperations({
    getList: mapResponse(TagsApi.getList, (responseData) => responseData.data.tags),
    getNextList: undefined,
    getPrevList: undefined,
    createItem: mapResponse(TagsApi.create, (responseData) => responseData.data.tag),
    deleteItem: mapResponse(TagsApi.delete, (responseData) => responseData.data),
    updateItem: mapResponse(TagsApi.update, (responseData) => responseData.data.tag),
  }),

  getItemId: (tag) => tag.tag_id,

  extraState: ExtraState,
  reducers: {},

  extraReducers: (builder) => {
    builder.addCase(openCreateTagModal, (state, action) => {
      state.createTagModal.isOpen = true
      state.createTagModal.source = action.payload.source
      state.createTagModal.tagName = action.payload.tagName ?? ''
    })

    builder.addCase(closeCreateTagModal, (state) => {
      state.createTagModal = ExtraState.createTagModal
    })

    builder.addCase(setLastCreatedTag, (state, action) => {
      state.createTagModal.lastCreatedTag = action.payload
    })

    builder.addCase(setSearchValue, (state, action) => {
      state.search.value = action.payload
    })

    builder.addCase(setIsSettingsTab, (state, action) => {
      state.isSettingsTab = action.payload
    })
  },
})

export const TagsReducer = reducer
export { TagsInitialState }

export type TagsState = ReturnType<typeof reducer>

const MAX_TAGS_COUNT_FOR_FREE_ACCOUNT = 10

const createFx = createAppAsyncThunk(
  `${namespace}/createFx`,
  async (
    { tagName, path, silent }: { tagName: string; path?: string; silent?: boolean },
    { dispatch, getState },
  ): Promise<Tag | null> => {
    logTagCreation()

    const tagFolderPath = path ? path : '/'
    const { path: currentPath = '/' } = getQuery()

    const ignoreCreatedItem =
      TagsSelectors.getIsSettingsTab(getState() as RootState) && currentPath !== tagFolderPath

    try {
      const tag = await dispatch(
        baseActions.createItemFx({
          payload: {
            query: { tag_name: tagName, path: path ? path : '/', client_id: getClientId() },
          },
          effectConfig: {
            createdItemAction: ignoreCreatedItem ? 'ignore' : 'prepend',
          },
        }),
      ).unwrap()

      dispatch(setLastCreatedTag(tag))

      if (!silent) {
        alert(l.translate('Tag Created!'), 'success')
      }

      return tag
    } catch (error) {
      if (silent) {
        return null
      }

      handleCatch(error, (responseError) => {
        let unhandledErrors = applyHandlerByGuard(
          responseError.$errors,
          TagsApiGuards.create.isProError,
          async (error) => {
            await dispatch(getTagsCountOnAccount())

            const itemsCount = getState().tagsFolders.totalTagsInAccount
            const message = l.translate(
              "You've reached the limit of tags ({itemsCount}/{maxTagsCount}) on the current plan. Upgrade for unlimited access.",
              { itemsCount, maxTagsCount: MAX_TAGS_COUNT_FOR_FREE_ACCOUNT },
            )

            dispatch(
              handleGoProError(error, {
                message,
                modalTitle: l.translate('Create unlimited tags with Manychat Pro'),
                source: UpgradeSource.TAGS,
                products: error.error_data.products,
              }),
            )
          },
        )

        unhandledErrors = applyHandlerByGuard(
          unhandledErrors,
          TagsApiGuards.create.isUsedTagNameError,
          (error) => {
            alert(error.message, 'danger')
          },
        )

        reportUnhandledErrors(unhandledErrors, responseError.endpoint)
      })
    }

    return null
  },
)

const ensureTagFx = createAppAsyncThunk(
  `${namespace}/ensureTagFx`,
  ({ tagName, silent }: { tagName: string; silent?: boolean }, { dispatch, getState }) => {
    const state = getState()

    const tag = TagsSelectors.getByName(state, tagName)

    if (tag) {
      return tag
    }

    return dispatch(createFx({ tagName, silent })).unwrap()
  },
)

const getListFx = createAppAsyncThunk(
  `${namespace}/getTagsListFx`,
  async (payload: Parameters<typeof baseActions.getListFx>[0] | void, { dispatch }) => {
    try {
      await dispatch(
        baseActions.getListFx(merge({ payload: { query: { type: TagType.USER } } }, payload)),
      ).unwrap()
    } catch (error) {
      handleCatch(error, (responseError) => {
        const unhandledErrors = applyHandlerByGuard(
          responseError.$errors,
          TagsApiGuards.getList.isFolderNotFoundError,
          () => {
            alert(
              l.translate(
                'You were redirected to the root folder because the folder you had opened was not found.',
              ),
            )
            navigatePollyfill(linkURL(`/settings?path=/#${SettingsTabType.TAGS}`))
          },
        )

        reportUnhandledErrors(unhandledErrors, responseError.endpoint)
      })
    }
  },
)

const deleteFx = createAppAsyncThunk(
  `${namespace}/deleteFx`,
  async ({ id }: { id: number }, { dispatch }) => {
    try {
      dispatch(baseActions.deleteItemFx({ id: id, payload: { query: { tag_id: id } } })).unwrap()

      alert(l.translate('Tag deleted!'), 'success')
    } catch (error) {
      handleCatch(error)
    }
  },
)

const bulkDeleteFx = createAppAsyncThunk(
  `${namespace}/bulkDeleteFx`,
  async ({ ids }: { ids: number[] }, { dispatch }) => {
    try {
      await TagsApi.bulkDelete({ body: { ids } })

      dispatch(baseActions.bulkDeleteItems({ ids }))

      alert(l.translate('Tags deleted!'), 'success')
    } catch (error) {
      handleCatch(error)
    }
  },
)

const moveFx = createAppAsyncThunk(
  `${namespace}/moveFx`,
  async ({ tagId, folderId }: { tagId: number; folderId: number }, { dispatch }) => {
    try {
      await TagsApi.moveToFolder({ body: { tag_id: tagId, folder_id: folderId } })

      dispatch(baseActions.deleteItem({ id: tagId }))

      alert(l.translate('Tag moved to folder!'), 'success')
    } catch (error) {
      handleCatch(error)
    }
  },
)

const bulkMoveFx = createAppAsyncThunk(
  `${namespace}/bulkMoveFx`,
  async ({ tagIds, folderId }: { tagIds: number[]; folderId: number }, { dispatch }) => {
    try {
      await TagsApi.moveToFolderBulk({ body: { ids: tagIds, folder_id: folderId } })

      dispatch(
        baseActions.bulkDeleteItems({
          ids: tagIds,
        }),
      )

      alert(l.translate('Tags moved to folder!'), 'success')
    } catch (error) {
      handleCatch(error)
    }
  },
)

export const TagsActions = {
  ...baseActions,
  createFx,
  getListFx,
  deleteFx,
  bulkDeleteFx,
  ensureTagFx,
  moveFx,
  bulkMoveFx,

  openCreateTagModal,
  closeCreateTagModal,
  setSearchValue,
  setIsSettingsTab,
}

export const TagsSelectors = {
  ...baseSelectors,

  getIsCreateTagModalOpen: (state: RootState) => state.tags.createTagModal.isOpen,
  getCreateTagModalTagName: (state: RootState) => state.tags.createTagModal.tagName,
  getSearchValue: (state: RootState) => state.tags.search.value,
  getIsSettingsTab: (state: RootState) => state.tags.isSettingsTab,
  getByName: (state: RootState, name: string) =>
    state.tags.items.find((tag) => tag.tag_name === name),
  getById: (state: RootState, id: number) => state.tags.items.find((tag) => tag.tag_id === id),

  getCreateTagModal: (state: RootState) => state.tags.createTagModal,
}

anotherTabNotificationsListener.on('tag_created', (data: { model: Tag }, dispatch, getState) => {
  const state = getState() as RootState

  if (!TagsSelectors.getIsSettingsTab(state)) {
    dispatch(baseActions.appendItem({ item: data.model }))
    return
  }

  const searchValue = TagsSelectors.getSearchValue(state)

  if (searchValue) {
    dispatch(
      TagsActions.getListFx({
        payload: { query: { type: TagType.USER, query: searchValue } },
        effectConfig: { forceRequest: true },
      }),
    )
    return
  }

  const { path } = getQuery()

  const currentFolder = path ? Number(path.replace('/', '')) : 0

  if (currentFolder === data.model.folder_id) {
    dispatch(baseActions.appendItem({ item: data.model }))
  }
})

anotherTabNotificationsListener.on('tag_deleted', (data: { id: number }, dispatch) => {
  dispatch(baseActions.deleteItem({ id: data.id }))
})

anotherTabNotificationsListener.on('tags_deleted', (data: { models: Tag[] }, dispatch) => {
  dispatch(
    baseActions.bulkDeleteItems({
      ids: data.models.map((tag) => tag.tag_id),
    }),
  )
})

anotherTabNotificationsListener.on('tag_updated', (data: { model: Tag }, dispatch, getState) => {
  const searchValue = TagsSelectors.getSearchValue(getState() as RootState)

  if (searchValue) {
    dispatch(
      TagsActions.getListFx({
        payload: { query: { type: TagType.USER, query: searchValue } },
        effectConfig: { forceRequest: true },
      }),
    )
    return
  }

  dispatch(
    baseActions.updateItem({
      id: data.model.tag_id,
      data: { tag_name: data.model.tag_name },
    }),
  )
})

anotherTabNotificationsListener.on('tag_moved', (data: { model: Tag }, dispatch, getState) => {
  const state = getState() as RootState

  if (!TagsSelectors.getIsSettingsTab(state)) {
    dispatch(baseActions.updateItem({ id: data.model.tag_id, rewrite: true, data: data.model }))
    return
  }

  const { path } = getQuery()

  const currentFolder = path ? Number(path.replace('/', '')) : 0

  if (currentFolder === data.model.folder_id) {
    dispatch(baseActions.appendItem({ item: data.model }))
  } else {
    dispatch(baseActions.deleteItem({ id: data.model.tag_id }))
  }
})

anotherTabNotificationsListener.on(
  'tags_moved_bulk',
  (data: { models: Tag[] }, dispatch, getState) => {
    const state = getState() as RootState

    if (!TagsSelectors.getIsSettingsTab(state)) {
      return
    }

    const { path } = getQuery()

    const currentFolder = path ? Number(path.replace('/', '')) : 0
    const targetFolderId = data.models[0].folder_id

    if (currentFolder === targetFolderId) {
      dispatch(baseActions.bulkAppendItems({ items: data.models }))
    } else {
      dispatch(baseActions.bulkDeleteItems({ ids: data.models.map((tag) => tag.tag_id) }))
    }
  },
)

import React from 'react'
import cx from 'classnames'
import PropTypes from 'prop-types'

import { getIsMobile } from '../../../utils'

import Body from './Body'
import { AT_MAPPING, ARROW_ORIGINS, ARROW_MARGINS, ARROW_AXIS, ARROW_OPPOSITES } from './constants'
import Footer from './Footer'
import Header from './Header'
import PopoverBase from './PopoverBase'
import { PopoverContextProvider } from './PopoverContext'

import cm from './Popover.module.css'

/**
 * Popovers are useful to create a contextual interface helpers.
 * It's cool to move settings of some elements in a popover to keep main UI clean and simple.
 */
export default class Popover extends React.PureComponent {
  static Header = Header
  static Footer = Footer
  static Body = Body
  static propTypes = {
    /**
     * Snap element for a popover. Can be HTML element or React element
     */
    anchor: PropTypes.any,
    /**
     * Anchor snap point.
     */
    anchorOrigin: PropTypes.string,
    /**
     * Adds popover arrow.
     * Can be bool when used with 'at' prop, otherwise should be
     * arrow position string
     */
    arrow: PropTypes.oneOfType([
      PropTypes.bool,
      PropTypes.oneOf([
        'top',
        'bottom',
        'left',
        'right',
        'top-left',
        'top-right',
        'bottom-left',
        'bottom-right',
      ]),
    ]),
    at: PropTypes.oneOf([
      'top',
      'bottom',
      'left',
      'right',
      'top-left',
      'top-right',
      'bottom-left',
      'bottom-right',
    ]),
    /**
     * Specifies ability to auto position popover when it goes offscreen
     * It's possible to set this prop separately for 'x' and 'y' axis
     * E.g. 'none auto' will prevent 'x' auto positioning
     * Use 'flip' and 'shift' values to specify certain auto position mechanism
     */
    autoPosition: PropTypes.string,
    className: PropTypes.string,
    formWithPage: PropTypes.bool,
    theme: PropTypes.shape({
      base: PropTypes.string,
      content: PropTypes.string,
    }),
    children: PropTypes.any,
    title: PropTypes.string,
    fitTargetWidth: PropTypes.bool,
    fullscreenOnMobile: PropTypes.bool,
    onRequestClose: PropTypes.func,
    open: PropTypes.bool,
    popoverOrigin: PropTypes.string,
    useLayerForClickAway: PropTypes.bool,
    layerStyle: PropTypes.object,
    /**
     * Custom handler for click away. Return true if you want to avoid default handler
     * (event: React.MouseEvent<HTMLElement>) => boolean
     */
    onClickAway: PropTypes.func,
  }

  static defaultProps = {
    theme: { base: '', content: '' },
    layerStyle: null,
    onClickAway: null,
  }

  state = {}

  getArrowData = (arrow) => {
    const axis = ARROW_AXIS[arrow]
    const axisData = this.state[`${axis}Data`] || {}
    const arrowDirection = axisData.position === 'flip' ? ARROW_OPPOSITES[arrow] : arrow
    return {
      arrowDirection,
      arrowOrigin: `center ${arrowDirection}`,
      arrowHidden: axisData.position === 'shift',
    }
  }

  getOrigins = (at, anchorOrigin, popoverOrigin) => ({
    anchorOrigin: this.props.anchorOrigin || (AT_MAPPING[at] || {}).anchorOrigin,
    popoverOrigin: this.props.popoverOrigin || (AT_MAPPING[at] || {}).popoverOrigin,
  })

  handleAxisAutoPositon = (axis, data) => this.setState({ [`${axis}Data`]: data })

  render() {
    const {
      anchor,
      anchorOrigin: ao,
      arrow: propsArrow,
      at,
      children,
      className,
      formWithPages,
      theme,
      fullscreenOnMobile,
      open,
      popoverOrigin: po,
      // eslint-disable-next-line no-unused-vars
      title,
      onRequestClose,
      ...rest
    } = this.props

    const { anchorOrigin, popoverOrigin } = this.getOrigins(at, ao, po)

    const isMobile = getIsMobile()
    const fullscreen = Boolean(fullscreenOnMobile && isMobile)

    const arrow = at && propsArrow === true ? at : propsArrow
    const withArrow = Boolean(arrow) && !fullscreen
    const { arrowDirection, arrowOrigin, arrowHidden } = this.getArrowData(arrow)

    const cn = cx(
      'bg-white d-flex flex-col',
      fullscreen ? 'm-0 o-hidden' : 'b-a rounded shadow',
      className,
      theme.content,
      withArrow && ARROW_MARGINS[arrowDirection],
      fullscreen && cm.fullscreen,
      formWithPages && cm.formWithPages,
    )
    return (
      <PopoverContextProvider value={{ onRequestClose, fullscreen }}>
        <PopoverBase
          {...rest}
          className={theme.base}
          anchor={anchor}
          anchorOrigin={anchorOrigin}
          fullscreen={fullscreen}
          open={open}
          onAxisAutoPositon={this.handleAxisAutoPositon}
          popoverOrigin={popoverOrigin}
          onRequestClose={onRequestClose}
        >
          <div className={cn}>{children}</div>
        </PopoverBase>
        {withArrow && (
          <PopoverBase
            open={open}
            anchor={anchor}
            anchorOrigin={arrowOrigin || anchorOrigin}
            popoverOrigin={ARROW_ORIGINS[arrowDirection]}
            autoPosition={false}
            useLayerForClickAway={false}
            className="p-events-none"
          >
            <div className={cx(cm.arrow, cm[arrowDirection], arrowHidden && 'd-none')} />
          </PopoverBase>
        )}
      </PopoverContextProvider>
    )
  }
}

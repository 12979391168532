import filter from 'lodash/filter'
import get from 'lodash/get'
import last from 'lodash/last'
import sum from 'lodash/sum'

import { SmsMessageType } from 'apps/sms/smsInterfaces'
import { BlockType } from 'common/builder/constants/BlockType'
import {
  MULTIMEDIA_MESSAGE_MAX_ATTACHMENTS_COUNT,
  MULTIMEDIA_MESSAGE_MAX_ATTACHMENTS_SIZE,
} from 'common/builder/nodes/sms/smsNodeConstants'

export const formatBytesToMbString = (bytes, options = {}) => {
  return String(Math.floor((bytes / 1024 / 1024) * 10) / 10)
}

export const buildMessagesFromBlocks = (blocks) => {
  const messages = []
  blocks.forEach((block) => {
    const lastMessage = last(messages)
    if (lastMessage && canMessageAddBlock(lastMessage, block)) {
      lastMessage.blocks = [...lastMessage.blocks, block]
      return
    }
    if (block.type === BlockType.TEXT || block.type === BlockType.FORM_QUESTION) {
      messages.push({
        type: SmsMessageType.TEXT_MESSAGE,
        blocks: [block],
      })
      return
    }
    if (block.type === BlockType.ATTACHMENT) {
      messages.push({
        type: SmsMessageType.MULTIMEDIA_MESSAGE,
        blocks: [block],
      })
      return
    }
  })
  return messages.map(formatMessage)
}

const canMessageAddBlock = (message, block) => {
  // text message
  // - can contain only one text block
  if (message.type === SmsMessageType.TEXT_MESSAGE) {
    return false
  }

  // multimedia message
  // - can contain up to 10 attachments + one optional text block
  // - attachments file size must be <= 4.95 mb
  const lastBlock = last(message.blocks)
  if (lastBlock && lastBlock.type === BlockType.TEXT) {
    return false
  }
  const nextBlocks = [...message.blocks, block]
  const nextAttachmentBlocks = filter(nextBlocks, { type: BlockType.ATTACHMENT })
  if (nextAttachmentBlocks.length > MULTIMEDIA_MESSAGE_MAX_ATTACHMENTS_COUNT) {
    return false
  }
  if (sum(nextBlocks.map(getAttachmentSize)) >= MULTIMEDIA_MESSAGE_MAX_ATTACHMENTS_SIZE) {
    return false
  }
  return true
}

const formatMessage = (message) => {
  const formatted = {
    type: message.type,
    blocks: message.blocks.map((b) => b.id),
    info: {},
  }
  if (message.type === SmsMessageType.MULTIMEDIA_MESSAGE) {
    const attachments = filter(message.blocks, { type: BlockType.ATTACHMENT })
    formatted.info.attachmentsCount = attachments.length
    formatted.info.attachmentsSize = sum(attachments.map(getAttachmentSize))
  }
  return formatted
}

const getAttachmentSize = (block) => {
  return get(block, 'attachment.image_size.size', 0)
}

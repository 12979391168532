import get from 'lodash/get'
import includes from 'lodash/includes'
import uniq from 'lodash/uniq'
import without from 'lodash/without'
import { l } from '@manychat/manyui'

import { NotifyAdminAction } from 'common/actions/models/Action/action'
import { NotifyAdminsSendChannels } from 'common/actions/models/Action/constants'
import { IAdminInterface, TelegramNotificationUser } from 'common/settings/interfaces'

interface IGetNotifyOptions {
  emailInsteadOfMessengerEnabled?: boolean
}

export const getNotifyAllByChannels = (
  action: NotifyAdminAction,
  options: IGetNotifyOptions = {},
) => {
  const { send_to = [], all_send_by = [] } = action

  // messenger
  let notifyAllByMessengerDisabled = false
  const sendAllIncludesMessenger = includes(all_send_by, NotifyAdminsSendChannels.MESSENGER)
  let notifyAllByMessenger = sendAllIncludesMessenger
  const messengerEnabledForSpecificAdmin = send_to.some((s) =>
    s.send_by.includes(NotifyAdminsSendChannels.MESSENGER),
  )
  let isMessengerNotificationsSelected =
    sendAllIncludesMessenger || messengerEnabledForSpecificAdmin

  if (options.emailInsteadOfMessengerEnabled) {
    notifyAllByMessenger = false
    notifyAllByMessengerDisabled = true
    isMessengerNotificationsSelected = false
  }

  // email
  const sendAllIncludesEmail = includes(all_send_by, NotifyAdminsSendChannels.EMAIL)
  let notifyAllByEmail = sendAllIncludesEmail
  const emailEnabledForSpecificAdmin = send_to.some((s) =>
    s.send_by.includes(NotifyAdminsSendChannels.EMAIL),
  )
  let isEmailNotificationsSelected = sendAllIncludesEmail || emailEnabledForSpecificAdmin
  if (options.emailInsteadOfMessengerEnabled) {
    notifyAllByEmail = sendAllIncludesEmail || sendAllIncludesMessenger
    isEmailNotificationsSelected =
      sendAllIncludesEmail ||
      emailEnabledForSpecificAdmin ||
      sendAllIncludesMessenger ||
      messengerEnabledForSpecificAdmin
  }

  // sms
  const sendAllIncludesSms = includes(all_send_by, NotifyAdminsSendChannels.SMS)
  const notifyAllBySMS = sendAllIncludesSms
  const smsEnabledForSpecificAdmin = send_to.some((s) =>
    s.send_by.includes(NotifyAdminsSendChannels.SMS),
  )
  const isSMSNotificationsSelected = sendAllIncludesSms || smsEnabledForSpecificAdmin

  // telegram
  const sendAllIncludesTelegram = includes(all_send_by, NotifyAdminsSendChannels.TELEGRAM)
  const notifyAllByTelegram = sendAllIncludesTelegram
  const telegramEnabledForSpecificAdmin = send_to.some((s) =>
    s.send_by.includes(NotifyAdminsSendChannels.TELEGRAM),
  )
  const isTelegramNotificationsSelected = sendAllIncludesTelegram || telegramEnabledForSpecificAdmin

  return {
    sendAllIncludesMessenger,
    sendAllIncludesSms,
    sendAllIncludesEmail,
    sendAllIncludesTelegram,

    notifyAllByMessenger,
    notifyAllByMessengerDisabled,
    notifyAllBySMS,
    notifyAllByEmail,
    notifyAllByTelegram,

    isEmailNotificationsSelected,
    isMessengerNotificationsSelected,
    isSMSNotificationsSelected,
    isTelegramNotificationsSelected,
  }
}

export const getSendLinkToLiveChat = (action: NotifyAdminAction) => {
  const { options } = action
  return options?.send_link_to_live_chat === undefined ? true : options.send_link_to_live_chat
}

export const getAdminNotifyByChannels = (
  action: NotifyAdminAction,
  admin: IAdminInterface,
  options: IGetNotifyOptions = {},
) => {
  const { send_to = [] } = action
  const sendToUser = send_to.find((_a) => _a.user_id === admin.user_id)
  const { send_by = [] } = sendToUser || {}

  const {
    sendAllIncludesMessenger,
    sendAllIncludesEmail,
    sendAllIncludesSms,
    sendAllIncludesTelegram,
  } = getNotifyAllByChannels(action, options)

  const sendByIncludesMessenger = send_by.includes(NotifyAdminsSendChannels.MESSENGER)
  let messengerChecked = sendByIncludesMessenger || sendAllIncludesMessenger
  let messengerDisabled = sendAllIncludesMessenger
  if (options.emailInsteadOfMessengerEnabled) {
    messengerChecked = false
    messengerDisabled = true
  }

  const sendByIncludesEmail = send_by.includes(NotifyAdminsSendChannels.EMAIL)
  let emailChecked = sendByIncludesEmail || sendAllIncludesEmail
  let emailDisabled = sendAllIncludesEmail
  if (options.emailInsteadOfMessengerEnabled) {
    emailChecked =
      sendByIncludesEmail ||
      sendAllIncludesEmail ||
      sendByIncludesMessenger ||
      sendAllIncludesMessenger
    emailDisabled = sendAllIncludesEmail || sendAllIncludesMessenger
  }

  const sendByIncludesSms = send_by.includes(NotifyAdminsSendChannels.SMS)
  const smsChecked = sendByIncludesSms || sendAllIncludesSms
  const smsDisabled = sendAllIncludesSms

  const sendByIncludesTelegram = send_by.includes(NotifyAdminsSendChannels.TELEGRAM)
  const telegramChecked = sendByIncludesTelegram || sendAllIncludesTelegram
  const telegramDisabled = sendAllIncludesTelegram

  return {
    messengerChecked,
    messengerDisabled,

    emailChecked,
    emailDisabled,

    smsChecked,
    smsDisabled,

    telegramChecked,
    telegramDisabled,
  }
}

const TooltipMessage = {
  getNoOptIn: l.makeTranslate(
    "Admin didn't subscribe to messenger notification for this bot. Tell them to check this in Settings→Notifications",
  ),
  getTurnedOff: l.makeTranslate(
    'Admin turned off this type of notification for this bot. Tell them to check this in Settings→Notifications',
  ),
  getSmsTurnedOff: l.makeTranslate(
    'Please, enable SMS notifications and add your phone number to receive SMS notifications. It can be set in Settings → Notifications',
  ),
  getTelegramNoOptIn: l.makeTranslate(
    `Admin didn't subscribe to Telegram notification for this bot. Tell them to check this in Settings→Notifications`,
  ),
}

export const getMessengerNotificationError = (
  messengerChecked: boolean,
  notifySettings: string[],
  isOptedIn: boolean,
) => {
  if (!isOptedIn && messengerChecked) {
    return TooltipMessage.getNoOptIn()
  }

  if (messengerChecked && !notifySettings.includes(NotifyAdminsSendChannels.MESSENGER)) {
    return TooltipMessage.getTurnedOff()
  }

  return null
}

export const getEmailNotificationError = (
  emailChecked: boolean,
  notifySettings: string[],
  options: IGetNotifyOptions = {},
) => {
  const isEmailIncluded = notifySettings.includes(NotifyAdminsSendChannels.EMAIL)
  let isEmailEnabled = isEmailIncluded
  if (options.emailInsteadOfMessengerEnabled) {
    const isMessengerIncluded = notifySettings.includes(NotifyAdminsSendChannels.MESSENGER)
    isEmailEnabled = isEmailIncluded || isMessengerIncluded
  }
  if (emailChecked && !isEmailEnabled) {
    return TooltipMessage.getTurnedOff()
  }

  return null
}

export const getSMSNotificationError = (
  smsChecked: boolean,
  notifySettings: string[],
  notificationPhone: string,
) => {
  if (smsChecked && !notifySettings.includes(NotifyAdminsSendChannels.SMS)) {
    return TooltipMessage.getSmsTurnedOff()
  }

  if (smsChecked && !notificationPhone) {
    return TooltipMessage.getSmsTurnedOff()
  }

  return null
}

export const getTelegramNotificationError = (
  telegramChecked: boolean,
  notifySettings: string[],
  telegramNotificationUser: TelegramNotificationUser | null,
) => {
  if (!telegramChecked) {
    return null
  }

  if (!notifySettings.includes(NotifyAdminsSendChannels.TELEGRAM)) {
    return TooltipMessage.getTurnedOff()
  }

  if (!telegramNotificationUser) {
    return TooltipMessage.getTelegramNoOptIn()
  }

  return null
}

export const toggleChannelToSendToAllBy = (
  action: NotifyAdminAction,
  channel: NotifyAdminsSendChannels,
  checked: boolean,
  options: IGetNotifyOptions = {},
) => {
  const value = get(action, 'all_send_by', [])
  let all_send_by = checked ? uniq([...value, channel]) : without(value, channel)
  if (options.emailInsteadOfMessengerEnabled) {
    const isDisablingEmail = !checked && channel === NotifyAdminsSendChannels.EMAIL
    if (isDisablingEmail) {
      all_send_by = without(all_send_by, NotifyAdminsSendChannels.MESSENGER)
    }
  }

  return { all_send_by }
}

export const toggleChannelSendTo = (
  action: NotifyAdminAction,
  channel: NotifyAdminsSendChannels,
  user_id: number,
  checked: boolean,
  options: IGetNotifyOptions = {},
) => {
  const send_to = action.send_to.slice()
  let index = send_to.findIndex((a) => a.user_id === user_id)

  let recipient
  if (index >= 0) {
    recipient = send_to[index]
  } else {
    recipient = { user_id, send_by: [] }
    send_to.push(recipient)
    index = send_to.length - 1
  }

  const { send_by } = recipient
  recipient.send_by = checked ? uniq([...send_by, channel]) : without(send_by, channel)
  if (options.emailInsteadOfMessengerEnabled) {
    const isDisablingEmail = !checked && channel === NotifyAdminsSendChannels.EMAIL
    if (isDisablingEmail) {
      recipient.send_by = without(recipient.send_by, NotifyAdminsSendChannels.MESSENGER)
    }
  }

  if (recipient.send_by.length === 0) {
    send_to.splice(index, 1)
  }
  return { send_to }
}

import React from 'react'
import cx from 'classnames'
import { useAppSelector } from 'reduxTyped'

import { CreateFirstAutomation } from 'apps/cms/components/Flows/components/CreateFirstAutomation/CreateFirstAutomation'
import { FlowCard } from 'apps/cms/components/Flows/components/FlowCard/FlowCard'
import { FlowCardSkeleton } from 'apps/cms/components/Flows/components/FlowCard/FlowCardSkeleton'
import { FlowCompactCard } from 'apps/cms/components/Flows/components/FlowCompactCard/FlowCompactCard'
import { FlowCompactCardSkeleton } from 'apps/cms/components/Flows/components/FlowCompactCard/FlowCompactCardSkeleton'
import { FlowPinnedCard } from 'apps/cms/components/Flows/components/FlowPinnedCard/FlowPinnedCard'
import { FlowsEmptyState } from 'apps/cms/components/Flows/components/FlowsEmptyState/FlowsEmptyState'
import { ViewDisplayMode } from 'apps/cms/lib/constants'
import { contentManagementSelectors } from 'apps/cms/store'
import { IFullPinnedFlow } from 'common/flow/flowInterfaces'
import { usePermission, PermissionTargets } from 'common/userRoles'

import cm from './FlowsList.module.css'

export const FlowsList = React.memo(function FlowsList() {
  const userCanEditFlow = usePermission(PermissionTargets.FLOW_EDITING)
  const flowIds = useAppSelector(contentManagementSelectors.getFlowIds)
  const flowPinned = useAppSelector(contentManagementSelectors.getPinnedFlow)
  const flowsState = useAppSelector(contentManagementSelectors.getFlowsState)
  const displayMode = useAppSelector(contentManagementSelectors.getViewDisplayMode)
  const { showBulkActions } = useAppSelector(contentManagementSelectors.getUi)
  const allowSelectFlows = showBulkActions && userCanEditFlow
  const isTrashFolder = useAppSelector(contentManagementSelectors.getIsTrashFolder)

  const hasFlowPinned = flowsState.isLoaded && flowPinned && displayMode === ViewDisplayMode.LIST

  const renderFlowPinnedCard = () => {
    if (hasFlowPinned) {
      return (
        <FlowPinnedCard
          allowSelect={allowSelectFlows}
          flowId={flowPinned?.ns}
          flow={flowPinned as IFullPinnedFlow}
        />
      )
    }

    return null
  }

  return (
    <>
      <div
        className={cx({
          [cm.flows_listView]: displayMode === ViewDisplayMode.LIST,
          [cm.flows_gridView]: displayMode === ViewDisplayMode.GRID,
        })}
      >
        {flowsState.isLoading &&
          Array.from({ length: 12 }, (_, index) => {
            if (displayMode === ViewDisplayMode.LIST) {
              return <FlowCardSkeleton key={index} allowSelect={allowSelectFlows} />
            }

            return <FlowCompactCardSkeleton key={index} />
          })}
        {renderFlowPinnedCard()}

        {flowsState.isLoaded &&
          flowIds.length > 0 &&
          flowIds.map((flowId) => {
            if (displayMode === ViewDisplayMode.LIST) {
              return <FlowCard allowSelect={allowSelectFlows} key={flowId} flowId={flowId} />
            }

            return <FlowCompactCard key={flowId} flowId={flowId} />
          })}
      </div>

      {flowsState.isLoaded &&
        !flowPinned &&
        (flowsState.accountHasFlows || (!flowsState.accountHasFlows && isTrashFolder)) &&
        flowIds.length === 0 && <FlowsEmptyState />}

      {flowsState.isLoaded && !flowsState.accountHasFlows && !isTrashFolder && (
        <CreateFirstAutomation />
      )}
    </>
  )
})

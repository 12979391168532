import { useState, useCallback, useEffect } from 'react'
import { useAppDispatch, useAppSelector } from 'reduxTyped'

import { CmsAnalytics } from 'apps/cms/lib/analytics'
import { contentManagementActions } from 'apps/cms/store'
import { getCurrentAccountID } from 'common/core/selectors/appSelectors'
import { formatUsesListResponse } from 'common/usesList/utils'
import { handleCatch } from 'shared/api/lib/errors/handlers'
import { CmsApi } from 'shared/api/requests/cms'

type UsageInfo = ReturnType<typeof formatUsesListResponse> | null

type UseDeleteFlowModal = (props: {
  flowId: string
  flowPath: string
  isOpen: boolean
  closeModal: () => void
}) => {
  usageInfo: UsageInfo
  deleteFlow: () => Promise<void>
  isLoading: boolean
}

export const useDeleteFlowModal: UseDeleteFlowModal = ({
  flowId,
  flowPath,
  isOpen,
  closeModal,
}) => {
  const dispatch = useAppDispatch()

  const currentAccountId = useAppSelector(getCurrentAccountID)

  const [usageInfo, setUsageInfo] = useState<UsageInfo>(null)
  const [isLoading, setIsLoading] = useState(false)

  const fetchUsesList = useCallback(async () => {
    try {
      const response = await CmsApi.getUsesList({
        query: { flow_ns: flowId },
      })
      const usageInfo = formatUsesListResponse(response.data.entities, currentAccountId)
      setUsageInfo(usageInfo)
    } catch (error) {
      handleCatch(error)
    }
  }, [currentAccountId, flowId])

  useEffect(() => {
    if (isOpen) {
      fetchUsesList()
    } else {
      setUsageInfo(null)
    }
  }, [fetchUsesList, isOpen])

  const deleteFlow = useCallback(async () => {
    setIsLoading(true)
    await dispatch(contentManagementActions.deleteFlowByPath(flowPath))
    CmsAnalytics.logDeleteFlow()
    setIsLoading(false)
    closeModal()
  }, [closeModal, dispatch, flowPath])

  return {
    usageInfo,
    deleteFlow,
    isLoading,
  }
}

import { ConfirmationLayout } from 'common/core/components/EmailConfirmationPage/ConfirmationLayout'
import { getHandle } from 'utils/router/utils/getHandle'

export const AgencyRoutes = [
  {
    path: 'agency',
    handle: getHandle('agency'),
    children: [
      {
        path: ':agency_id?/profile',
        lazy: () => import('apps/profile/pages/dashboard.page'),
      },
      {
        path: 'signon',
        lazy: () => import('./signon.page'),
      },
      {
        path: 'confirm',
        Component: ConfirmationLayout,
        children: [
          {
            path: ':confirmation_hash',
            lazy: () => import('./emailConfirmation.page'),
          },
        ],
      },
    ],
  },
] as const

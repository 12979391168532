const stop = (e) => e.stopPropagation()

const events = {
  onClick: stop,
  onContextMenu: stop,
  onDoubleClick: stop,
  onDrag: stop,
  onDragEnd: stop,
  onDragEnter: stop,
  onDragExit: stop,
  onDragLeave: stop,
  onDragOver: stop,
  onDragStart: stop,
  onDrop: stop,
  onMouseDown: stop,
  onMouseEnter: stop,
  onMouseLeave: stop,
  onMouseMove: stop,
  onMouseOver: stop,
  onMouseOut: stop,
  onMouseUp: stop,

  onKeyDown: stop,
  onKeyPress: stop,
  onKeyUp: stop,

  onFocus: stop,
  onBlur: stop,

  onChange: stop,
  onInput: stop,
  onInvalid: stop,
  onSubmit: stop,
}

export const withHandler = (handler, allowPropagationEvents) => {
  return Object.keys(events).reduce((result, eventName) => {
    if (allowPropagationEvents.includes(eventName)) {
      return result
    }

    return (result[eventName] = handler.bind(undefined, eventName)), result
  }, {})
}

export default events

import { z } from 'zod'

import {
  IgPageStatus,
  AddIgStatusType,
  ConnectIgErrorCode,
} from 'apps/addNewAccount/addNewAccountInterfaces'
import {
  FbPageStatus,
  AddFbStatusType,
} from 'apps/addNewAccount/FacebookMessenger/addFbAccountInterfaces'
import { ProductType } from 'common/core/interfaces/products'
import { ProStatus } from 'common/core/models/Account/constants'

const IgPageSchema = z.object({
  id: z.string(),
  name: z.string(),
  status: z.nativeEnum(IgPageStatus),
  identifier: z.string(),
  account_owner: z.object({ name: z.string() }).nullable(),
  instagram_business_account_id: z.string(),
  instagram_business_account_name: z.string().nullable(),
  instagram_business_account_profile_picture_url: z.string().nullable(),
  instagram_business_account_username: z.string(),
  allow_to_connect: z.boolean(),
  follower_count: z.number(),
  pro_status: z.nativeEnum(ProStatus).nullable(),
})

const NoIgPermissionsSchema = z.object({
  type: z.union([
    z.literal(AddIgStatusType.NO_FB_PERMISSIONS),
    z.literal(AddIgStatusType.NO_IG_PERMISSIONS),
  ]),
  data: z.object({ redirect_url: z.string() }),
})

const SelectIgPageSchema = z.object({
  type: z.literal(AddIgStatusType.SELECT_PAGE),
  data: z.object({
    pages: z.array(IgPageSchema),
    signInUrlInstagram: z.string().nullable(),
  }),
})

const InstagramListStatusSchema = z.union([SelectIgPageSchema, NoIgPermissionsSchema])

const AccountProductsStateSchema = z.object({
  has_inbox: z.boolean(),
  has_automation: z.boolean(),
})

const FbPageSchema = z.object({
  id: z.string(),
  name: z.string(),
  status: z.nativeEnum(FbPageStatus),
  identifier: z.string(),
  ava: z.string(),
  account_owner: z.object({ name: z.string() }).nullable(),
})

const NoFbPermissionsSchema = z.object({
  type: z.literal(AddFbStatusType.NO_FB_PERMISSIONS),
  data: z.object({ redirect_url: z.string() }),
})

const FbAccountUserNotFoundSchema = z.object({
  type: z.literal(AddFbStatusType.ACCOUNT_USER_NOT_FOUND),
  data: z.object({ redirect_url: z.string() }),
})

const FbAccountAlreadyConnectedSchema = z.object({
  type: z.literal(AddFbStatusType.ALREADY_CONNECTED),
  data: z.object({ redirect_url: z.string() }),
})

const FbAccountNotFoundSchema = z.object({
  type: z.literal(AddFbStatusType.ACCOUNT_NOT_FOUND),
  data: z.object({ redirect_url: z.string() }),
})

const FbQuestionnaireNotPassedSchema = z.object({
  type: z.literal(AddFbStatusType.QUESTIONNAIRE_NOT_PASSED),
  data: z.object({ redirect_url: z.string() }),
})

const FbRedirectSchema = z.object({
  type: z.literal(AddFbStatusType.REDIRECT),
  data: z.object({ redirect_url: z.string() }),
})

const SelectFbPageSchema = z.object({
  type: z.literal(AddFbStatusType.SELECT_PAGE),
  data: z.object({
    pages: z.array(FbPageSchema),
  }),
})

const FacebookListStatusWithRedirectSchema = z.union([
  NoFbPermissionsSchema,
  FbAccountUserNotFoundSchema,
  FbAccountAlreadyConnectedSchema,
  FbAccountNotFoundSchema,
  FbQuestionnaireNotPassedSchema,
  FbRedirectSchema,
])

const FacebookListStatusSchema = z.union([
  SelectFbPageSchema,
  NoFbPermissionsSchema,
  FbAccountUserNotFoundSchema,
  FbAccountAlreadyConnectedSchema,
  FbAccountNotFoundSchema,
  FbQuestionnaireNotPassedSchema,
  FbRedirectSchema,
])

export enum TikTokConnectionType {
  FORBIDDEN_REGION = 'forbidden_region',
  FORBIDDEN_ROLE = 'forbidden_role',
  CONNECTION_AVAILABLE = 'connection_available',
}

const TikTokForbiddenRegionSchema = z.object({
  type: z.literal(TikTokConnectionType.FORBIDDEN_REGION),
  data: z.null(),
})

const TikTokForbiddenRoleSchema = z.object({
  type: z.literal(TikTokConnectionType.FORBIDDEN_ROLE),
  data: z.null(),
})

const TikTokAvailableConnectionSchema = z.object({
  type: z.literal(TikTokConnectionType.CONNECTION_AVAILABLE),
  data: z.object({
    auth_url: z.string(),
  }),
})

const TikTokAuthDataSchema = z.union([
  TikTokForbiddenRegionSchema,
  TikTokForbiddenRoleSchema,
  TikTokAvailableConnectionSchema,
])

const ConnectTgAccountResponseSchema = z.object({
  bot_name: z.string(),
  account_id: z.string(),
})

export const AuthApiSchemas = {
  getInstagramConnection: {
    query: z.object({ account_id: z.string().nullable() }),
    response: z.object({
      authUrl: z.string(),
    }),
    path: z.undefined(),
  },
  getMetaInstagramConnection: {
    query: z.object({ account_id: z.string().nullable() }),
    response: InstagramListStatusSchema,
    path: z.undefined(),
  },
  validateInstagramPage: {
    query: z.object({ fb_page_id: z.string() }),
    response: z.object({
      is_valid: z.boolean(),
      error_code: z.nativeEnum(ConnectIgErrorCode).nullable(),
    }),
    path: z.undefined(),
  },
  requestAccountAccess: {
    request: z.union([
      z.object({ fb_page_id: z.string() }),
      z.object({ ig_fb_page_id: z.string() }),
      z.object({ ig_business_id: z.string() }),
      z.object({ tt_business_id: z.string() }),
    ]),
    query: z.undefined(),
    response: z.undefined(),
    path: z.undefined(),
  },
  addIgAccount: {
    request: z.object({ page_id: z.string(), account_id: z.string().nullable() }),
    response: z.object({ account_id: z.number() }),
    query: z.undefined(),
    path: z.undefined(),
  },
  updateProducts: {
    request: z.object({
      account_id: z.string(),
      products: z.array(z.nativeEnum(ProductType)),
    }),
    response: z.object({
      products: AccountProductsStateSchema,
    }),
    query: z.undefined(),
    path: z.undefined(),
  },
  getFbPagesList: {
    query: z.object({ account_id: z.string().or(z.number()).nullable().optional() }),
    response: FacebookListStatusSchema,
    path: z.undefined(),
  },
  addFbAccount: {
    request: z.object({ page_id: z.string(), account_id: z.string().or(z.number()).nullable() }),
    response: z.object({ account_id: z.string() }),
    query: z.undefined(),
    path: z.undefined(),
  },
  addTgAccount: {
    request: z.object({ token: z.string(), account_id: z.string().or(z.number()).nullable() }),
    response: ConnectTgAccountResponseSchema,
    query: z.undefined(),
    path: z.undefined(),
  },
  getTiktokConnection: {
    request: z.undefined(),
    response: TikTokAuthDataSchema,
    query: z.object({ account_id: z.string().or(z.number()).nullable() }),
    path: z.undefined(),
  },
}

export type IgPage = z.infer<typeof IgPageSchema>
export type InstagramListStatus = z.infer<typeof InstagramListStatusSchema>
export type InstagramListNoPermissionsStatus = z.infer<typeof NoIgPermissionsSchema>
export type InstagramListPagesAvailableStatus = z.infer<typeof SelectIgPageSchema>

export type InstagramPageValidationResponse = z.infer<
  typeof AuthApiSchemas.validateInstagramPage.response
>

export type AccountProductsState = z.infer<typeof AccountProductsStateSchema>

export type FbPage = z.infer<typeof FbPageSchema>
export type FacebookListStatus = z.infer<typeof FacebookListStatusSchema>
export type FacebookListPagesAvailableStatus = z.infer<typeof SelectFbPageSchema>
export type NoFbPermissions = z.infer<typeof NoFbPermissionsSchema>
export type FacebookListStatusWithRedirect = z.infer<typeof FacebookListStatusWithRedirectSchema>

export type TikTokAuthData = z.infer<typeof TikTokAuthDataSchema>

export type ConnectTgAccountResponse = z.infer<typeof ConnectTgAccountResponseSchema>

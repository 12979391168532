import { navigatePollyfill } from 'utils/router/tools'
import { linkURL } from 'utils/url'

export const navigateToThread = (threadId: string) => {
  const link = linkURL(`chat/${threadId}`)

  if (window.location.pathname === link) {
    return
  }

  navigatePollyfill(link)
}

import { CommentReplyIgNodeProperties } from 'common/builder/constants/MessageTypeIgNodeProperties'
import { IInstagramNode } from 'common/builder/nodes/nodeInterfaces'

/**
 * Returns new node with preselected "Comment reply" messaging type
 */
export const convertToIgCommentReplyNode = (node: IInstagramNode): IInstagramNode => ({
  ...node,
  ...CommentReplyIgNodeProperties,
})

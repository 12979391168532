import omit from 'lodash/omit'

import {
  CartProduct,
  CartProductAddOn,
  CartProductPlan,
  CartProductsData,
  CartSlug,
  Discount,
  DiscountType,
  DiscountUnit,
} from 'common/billing/billingInterfaces'
import { AddOnSlug } from 'common/billing/interfaces/addOnTypes'
import { ProProduct } from 'common/core/interfaces/products'
import { CouponInfo } from 'shared/api/requests/billing/schemas'

export const isCartPlan = (product: CartProduct): product is CartProductPlan =>
  product.type === 'plan'

export const isCartAddOn = (product: CartProduct): product is CartProductAddOn =>
  product.type === 'addOn'

export const groupCartByType = (cart: CartProduct[]): [ProProduct[], AddOnSlug[]] =>
  cart.reduce<[ProProduct[], AddOnSlug[]]>(
    ([plans, addOns], cartItem) => {
      if (isCartPlan(cartItem)) {
        plans.push(cartItem.slug)
      }
      if (isCartAddOn(cartItem)) {
        addOns.push(cartItem.slug)
      }

      return [plans, addOns]
    },
    [[], []],
  )

export const getCartUndiscountedPrice = (cart: CartProduct[]): number =>
  cart.reduce((sum, product) => sum + product.price, 0)

export const applyCouponForProduct = <Product extends CartProduct>(
  product: Product,
  coupon?: CouponInfo,
): Product => {
  if (!coupon) {
    return product
  }

  if (isCartAddOn(product) && !(coupon.addons || []).includes(product.slug)) {
    return product
  }

  if (isCartPlan(product) && !(coupon.products || []).includes(product.slug)) {
    return product
  }

  const discountedPrice = (product.price * (100 - coupon.percentage_discount_amount_value)) / 100

  return {
    ...product,
    discount: {
      type: DiscountType.COUPON,
      periodUnit: coupon.type,
      period: coupon.period,
      discountUnit: DiscountUnit.PERCENT,
      discount: coupon.percentage_discount_amount_value,
      price: discountedPrice,
    },
  }
}

export const applyDiscountToCart = (
  cart: CartProductsData,
  discount: Discount,
): CartProductsData => {
  const applyDiscountForProduct = <Product extends CartProduct>(product: Product): Product => {
    const discountedPrice = (product.price * (100 - discount.discount)) / 100

    return {
      ...product,
      discount: {
        ...omit(discount, 'addOns', 'plans'),
        price: discountedPrice,
      },
    }
  }

  return {
    plans: cart.plans.map(applyDiscountForProduct),
    addOns: cart.addOns.map(applyDiscountForProduct),
  }
}

export const extractCartSlugs = (cart: CartProductsData): CartSlug => {
  return {
    plans: cart.plans.map((plan) => plan.slug),
    addOns: cart.addOns.map((addOn) => addOn.slug),
  }
}

import React from 'react'
import cx from 'classnames'
import { l } from '@manychat/manyui'

import cm from '../common.module.css'

const ARTICLE_URL =
  'https://help.manychat.com/hc/en-us/articles/14580897414300-Conversions-API-CAPI-integration'

const FbCapiReconnectFailNotification = () => {
  return (
    <>
      <span className="m-r-xxs">
        {l.translate(
          'Your Facebook Conversions API (CAPI) integration has been disconnected. To keep your data flowing, please reconnect it manually.',
        )}
      </span>
      <a target="_blank" href={ARTICLE_URL} rel="noreferrer" className={cx('m-l-auto', cm.link)}>
        {l.translate('Follow the instruction')}
      </a>
    </>
  )
}

export default FbCapiReconnectFailNotification

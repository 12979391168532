import React from 'react'

import {
  getRuns,
  getWidgetStatistics,
} from 'apps/cms/components/Flows/components/FlowCard/components/Triggers/hooks/useTriggers/lib/statistics'
import { AiIntentTitle } from 'apps/cms/pages/ContentManagementSystem/components/Automations/components/FlowCard/components/Triggers/components/AiIntentTitle/AiIntentTitle'
import { ChannelIcon } from 'common/core/constants/ChannelType'
import { TriggerType } from 'common/flow/constants/TriggerType'
import { AiIntentStatus } from 'shared/api/requests/aiIntents/schemas'
import { IntentTrigger } from 'shared/api/requests/cms/schemas'

export const getIntentTrigger = (intent: IntentTrigger) => {
  const IntentIcon = ChannelIcon[intent.channel]

  return {
    title: <AiIntentTitle trigger={intent} />,
    icon: IntentIcon ? <IntentIcon size={16} /> : null,
    disabled: intent.status !== AiIntentStatus.ACTIVE,
    runs: getRuns(intent.runs),
    ctr: 'n/a',
    id: intent.intent_id,
    type: TriggerType.AI_INTENT,
    status: intent.status,
    created: intent.ts_created,
    impressions: getWidgetStatistics(null),
    optIns: getWidgetStatistics(null),
  }
}

import dot from 'dot-prop-immutable'

import * as atypes from 'common/events/eventsReduxActionTypes'

const initialState = {
  list: [],
}

export default function eventsReducer(state = initialState, action) {
  switch (action.type) {
    case atypes.FETCH_EVENTS_RESPONSE: {
      const { list } = action.data
      return dot.set(state, 'list', list)
    }

    case atypes.UPDATE_EVENTS_LIST: {
      const { event_id, list } = action.data
      const filteredList = list.filter((event) => event.event_id !== event_id)
      return dot.set(state, 'list', filteredList)
    }
  }
  return state
}

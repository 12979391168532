import { TriggerType } from 'common/flow/constants/TriggerType'
import { StoryMentionReply } from 'shared/api/requests/storyMentionReply/schemas'

import { MappedStoryMentionReply as StoryMentionReplyMapped } from './storyMentionReplyInterfaces'

export const parseStoryMentionReply = (item: StoryMentionReply): StoryMentionReplyMapped => {
  const { enabled, type, content_holder, channel, flow, auto_like_enabled, delay } = item
  const { clicked_unq, ctr, runs } = item
  const id = content_holder
  const ts_created = 0
  const flowId = flow?.ns ?? null

  return {
    id,
    triggerType: TriggerType.STORY_MENTION_REPLY,
    triggerId: `${TriggerType.STORY_MENTION_REPLY}-${id}`,
    autoLikeEnabled: auto_like_enabled,
    delay,
    channel,
    enabled,
    caption: 'Story Mention Reply',
    ts_created,
    storyMentionReplyId: id,
    chId: 'ig_story_mention',
    type,
    flowId,
    // @ts-expect-error schema for flow isn't ready
    flow,
    clicked_unq: clicked_unq ?? null,
    ctr: ctr ?? null,
    runs: runs ?? null,
  }
}

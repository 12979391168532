import React from 'react'
//https://github.com/zpao/qrcode.react

import { DEFAULT_PROPS } from './constants'
import QRCodeCanvas from './QRCodeCanvas'
import QRCodeSVG from './QRCodeSVG'

export const QRCode = (props) => {
  const {
    renderAs = 'canvas',
    bgColor = DEFAULT_PROPS.bgColor,
    fgColor = DEFAULT_PROPS.fgColor,
    id,
    includeMargin = DEFAULT_PROPS.includeMargin,
    size = DEFAULT_PROPS.size,
    value,
    img,
    removeLogoBackground = DEFAULT_PROPS.removeLogoBackground,
  } = props
  const Component = renderAs === 'svg' ? QRCodeSVG : QRCodeCanvas
  const hasLogo = img !== null

  return (
    <Component
      id={id}
      img={img}
      size={size}
      level={hasLogo ? 'H' : 'L'}
      value={value}
      bgColor={bgColor}
      fgColor={fgColor}
      includeMargin={includeMargin}
      removeLogoBackground={removeLogoBackground}
    />
  )
}

import OnboardingBase from 'utils/services/newOnboardingService/core/OnboardingBase'
import StepController from 'utils/services/newOnboardingService/core/StepController'
import StepSystem from 'utils/services/newOnboardingService/core/steps/StepSystem'
import OnboardingId from 'utils/services/newOnboardingService/OnboardingId'
import { waitUntilElementMount } from 'utils/services/newOnboardingService/view/Pointer/pointerHelpers'

export type BroadcastSampleTourSteps = {
  STEP_1: StepSystem
  STEP_2: StepSystem
  STEP_3: StepSystem
  STEP_4: StepSystem
  STEP_5: StepSystem
  STEP_6: StepSystem
  STEP_7: StepSystem
}

export class BroadcastSampleTour extends OnboardingBase<BroadcastSampleTourSteps> {
  constructor() {
    super(
      OnboardingId.WHATSAPP_BROADCAST_SAMPLE_TOUR,
      () =>
        new StepController<BroadcastSampleTourSteps>('STEP_1', {
          STEP_1: () => new StepSystem(() => this.setStep('STEP_2')),
          STEP_2: () =>
            new StepSystem(() => this.setStep('STEP_3'), {
              onRun: () => {
                waitUntilElementMount('[data-onboarding-id="sidebar-template-categories"]', (el) =>
                  el.scrollIntoView(),
                )
              },
            }),
          STEP_3: () => new StepSystem(() => this.setStep('STEP_4')),
          STEP_4: () => new StepSystem(() => this.setStep('STEP_5')),
          STEP_5: () => new StepSystem(() => this.setStep('STEP_6')),
          STEP_6: () => new StepSystem(() => this.setStep('STEP_7')),
          STEP_7: () => new StepSystem(() => this.complete()),
        }),
    )
  }
}

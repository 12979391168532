import { z } from 'zod'

import { isProError } from 'shared/api/common/errors/proError'
import { BusinessErrorSchema } from 'shared/api/common/schemas/errors'
import { BusinessError } from 'shared/api/lib/errors/business'
import {
  isFilterItemValueNotSetError,
  isProAccountRequiredError,
  isSegmentNotFoundError,
  isSequenceNotFoundError,
  isTagNotFoundError,
  isUserIdNotFoundError,
  isWidgetNotFoundError,
  isWrongTimezoneValueError,
} from 'shared/api/requests/audience/audienceGuards'

enum WABlockContactMetaErrors {
  BLOCK_LIST_LIMIT = 'The blocklist limit of 64k is reached',
  TEMPORARY_ERROR = 'An error occurred while blocking the contact. Try again later.',
  SELF_BLOCK = 'Unable to block yourself',
  OUTSIDE_24_HOURS = 'The contact is not registered in WhatsApp, or they contacted your business more than 24-hours ago',
}

interface DuplicateWaSubscriberError extends BusinessError {
  field: 'wa_subscribers_data'
}

const isDuplicateWaSubscriberError = (
  error: BusinessError,
): error is DuplicateWaSubscriberError => {
  return error.field === 'wa_subscribers_data'
}

const SubscriberNotFoundErrorSchema = BusinessErrorSchema.extend({
  field: z.literal('user_id'),
})

type SubscriberNotFoundError = z.infer<typeof SubscriberNotFoundErrorSchema>

const isSubscriberNotFoundError = (error: unknown): error is SubscriberNotFoundError =>
  SubscriberNotFoundErrorSchema.safeParse(error).success

const WABlockContactSubscriberErrorSchema = BusinessErrorSchema.extend({
  field: z.literal('subscriber_id'),
})

type WABlockContactSubscriberError = z.infer<typeof WABlockContactSubscriberErrorSchema>

const isWABlockContactSubscriberError = (error: unknown): error is WABlockContactSubscriberError =>
  WABlockContactSubscriberErrorSchema.safeParse(error).success

const WABlockContactAccountErrorSchema = BusinessErrorSchema.extend({
  field: z.literal('account_id'),
})

type WABlockContactAccountError = z.infer<typeof WABlockContactAccountErrorSchema>

const isWABlockContactAccountError = (error: unknown): error is WABlockContactAccountError =>
  WABlockContactAccountErrorSchema.safeParse(error).success

const WABlockContactMetaErrorSchema = BusinessErrorSchema.extend({
  original_message: z.nativeEnum(WABlockContactMetaErrors),
})

type WABlockContactMetaError = z.infer<typeof WABlockContactMetaErrorSchema>

const isWABlockContactMetaError = (error: unknown): error is WABlockContactMetaError =>
  WABlockContactMetaErrorSchema.safeParse(error).success

export const audienceApiGuard = {
  prepareCsvExport: {
    isProError,
  },
  createWhatsAppSubscribers: {
    isDuplicateWaSubscriberError,
  },
  details: {
    isSubscriberNotFoundError,
  },
  search: {
    isFilterItemValueNotSetError,
    isWrongTimezoneValueError,
    isProAccountRequiredError,
    isTagNotFoundError,
    isUserIdNotFoundError,
    isWidgetNotFoundError,
    isSegmentNotFoundError,
    isSequenceNotFoundError,
  },
  blockWAContact: {
    isWABlockContactSubscriberError,
    isWABlockContactAccountError,
    isWABlockContactMetaError,
  },
}

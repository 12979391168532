import React, { useCallback, useEffect, useState } from 'react'
import cx from 'classnames'
import { useAppDispatch, useAppSelector } from 'reduxTyped'
import { l, Skeleton, CheckboxV2 } from '@manychat/manyui'

import { SELECTION_TYPE } from 'common/cms/constants/CMSSelectionType'
import { getCurrentAccountID } from 'common/core/selectors/appSelectors'
import { BulkFormattedItem, SubItemBulkStructure } from 'common/core/usesListInterfaces'
import { formatUsesListResponse } from 'common/usesList/utils'
import { Accordion, AccordionItem } from 'components/Accordion/Accordion'
import Tip from 'components/Tip'
import { BulkUsesListResponse } from 'shared/api/requests/cms/schemas'

import cm from './BulkUsesList.module.css'

interface BulkUsesListProps {
  onBulkGetUsesList: () => Promise<BulkUsesListResponse>
  onToggleFlowSelection: (id: string) => void
  selectionType: SELECTION_TYPE
  selectedIds: Record<string, boolean>
  isTrashFolder?: boolean
}

const BulkUsesList = (props: BulkUsesListProps): JSX.Element | null => {
  const { onBulkGetUsesList, onToggleFlowSelection, selectedIds } = props

  const [usesList, setUsesList] = useState<BulkUsesListResponse['entities'] | null>(null)
  const [isFetching, setIsFetching] = useState(false)

  const dispatch = useAppDispatch()
  const accountId = useAppSelector(getCurrentAccountID)

  useEffect(() => {
    const getUsesList = async () => {
      setIsFetching(true)
      const result = await onBulkGetUsesList()
      setIsFetching(false)
      setUsesList(result?.entities ?? null)
    }

    getUsesList()
    // eslint-disable-next-line
  }, [dispatch])

  const renderChild = useCallback((subItem: SubItemBulkStructure) => {
    const isBulkStructureObject = subItem && typeof subItem === 'object' && 'label' in subItem

    if (isBulkStructureObject) {
      return (
        <div className="m-l-md" key={subItem.label}>
          <p className="text-sm m-b-xs m-t-0">{subItem.label}</p>
          {Array.isArray(subItem.subItems) &&
            subItem.subItems.map((item: JSX.Element, i) => (
              <p className="text-sm m-b-xs m-t-0 text-primary" key={i}>
                <span className="nowrap o-hidden text-ellipsis">{item}</span>
              </p>
            ))}
        </div>
      )
    }

    return null
  }, [])

  if (isFetching) {
    return <Skeleton.Text lines={4} height={22} className="m-t-md" />
  }

  if (!usesList) {
    return null
  }

  const bulkUsesList = usesList.map((e) => {
    const { entities, model } = e

    const subItems = formatUsesListResponse(entities, accountId).usesList
    return { label: model.title, id: model.id, subItems }
  })

  const entityWithSubItems = bulkUsesList.find((entity) => entity.subItems.length)

  const getRelations = (subItems: SubItemBulkStructure[]): string => {
    if (!subItems.length) {
      return l.translate('Relations 0')
    }

    const relations = subItems.reduce((prev, cur) => {
      const isBulkStructureObject = cur && typeof cur === 'object' && 'subItems' in cur
      if (isBulkStructureObject) {
        prev.push(`${cur.subItems.length} ${cur.label}`)
      }
      return prev
    }, [] as string[])

    if (!relations.length) {
      return l.translate('Relations 0')
    }

    return `${l.translate('Relations:')} ${relations.join(', ')}`
  }

  const renderItem = (item: AccordionItem<SubItemBulkStructure>) => {
    return (
      <div className="row align-center">
        <div className="m-r">
          <CheckboxV2
            ariaLabelledBy="bulk-use-list-item"
            checked={Boolean(item.id && selectedIds[item.id])}
            onChange={() => onToggleFlowSelection(item?.id ?? '')}
            onClick={(event) => event.stopPropagation()}
          />
        </div>
        <div>
          <p
            className="o-hidden text-ellipsis text-nowrap"
            style={{ width: '280px' }}
            id="bulk-use-list-item"
          >
            {item.label}
          </p>
          <p className="text-secondary text-sm">{getRelations(item?.subItems ?? [])}</p>
        </div>
      </div>
    )
  }

  const renderTip = (): JSX.Element => {
    return (
      <Tip
        className="m-t text-left"
        text={
          props.isTrashFolder
            ? l.translate(
                'These Automations are used in your automations. It will be fully removed from your bot and can’t be restored. Triggers within this Automation will be also deleted.',
              )
            : l.translate(
                'These Automations are used in your automations. Please note, all Triggers within these Automations will be also removed to the trash folder. You will be able to restore them together with Automations.',
              )
        }
        rounded
        information
      />
    )
  }

  if (!entityWithSubItems) {
    return <p>{l.translate('These Automations are not used in your automations.')}</p>
  }

  return (
    <div>
      {renderTip()}
      <div className="m-y panel panel-white panel-shadow text-left">
        {bulkUsesList.map((entity: BulkFormattedItem, index) => {
          return (
            <div key={index} className={cm.separator}>
              <Accordion
                renderItem={renderItem}
                renderChild={renderChild}
                items={[entity]}
                showEmpty={true}
                labelClassName={cx(cm.label, { [cm.disabled]: !entity.subItems.length })}
              />
            </div>
          )
        })}
      </div>
    </div>
  )
}

export default BulkUsesList

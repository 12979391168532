import { Icon, l } from '@manychat/manyui'
import { blocks } from '@manychat/paywall'

interface AiPaywallProps {
  benefits?: Array<{
    icon: keyof typeof Icon
    description: string
  }>
  videoSrc?: string
  videoBackgroundSrc?: string
}

const defaultProps: Required<AiPaywallProps> = {
  benefits: [
    {
      icon: 'AiIntention',
      description: l.translate(
        'Enhance response accuracy with Manychat AI — smartly detecting DM intents for optimal replies',
      ),
    },
    {
      icon: 'AiStep',
      description: l.translate(
        'Let AI Step streamline your conversations, boost sales, and re-engage unresponsive leads',
      ),
    },
    {
      icon: 'MagicWand',
      description: l.translate(
        'Struggle with writing? Let our AI Text Improver craft, refine, and style your messages effortlessly',
      ),
    },
    {
      icon: 'Flow',
      description: l.translate(
        'Bypass complex setups and seamlessly automate flows by simply talking to AI Flow Builder',
      ),
    },
  ],
  videoSrc:
    'https://manychat-storage.s3.eu-central-1.amazonaws.com/manychat/ca/2024/07/29/ed044ffa221b4a47ef01a6f82b88013c/ai_video_relevant.mp4',
  videoBackgroundSrc: '/assets/assets/videoBackground-7PDZG7KC.svg',
}

export function ai(props: AiPaywallProps = {}) {
  const { benefits, videoSrc, videoBackgroundSrc } = { ...defaultProps, ...props }

  return blocks.ModalView.create({
    style: { padding: 0 },
    className: 'p-0',
    width: 1200,
    children: [
      blocks.Row.create({
        style: { gridTemplateColumns: '1fr 1fr', gap: 0 },
        mobileWrap: true,
        children: [
          blocks.Box.create({
            children: [
              blocks.Box.create({
                className: {
                  default: 'd-flex flex-row justify-between p-t-md p-x-lg p-b gap-sm',
                  sm: 'd-flex flex-row justify-between p-x p-y gap-sm',
                },
                children: [
                  blocks.Box.create({
                    children: [
                      blocks.Text.create({
                        text: 'Manychat AI',
                        className: 'text-display-sm m-b-xxs',
                      }),
                      blocks.Text.create({
                        text: 'Smarter Automations.\nBetter Conversations.',
                        className: 'text-secondary m-0',
                        style: { whiteSpace: 'pre' },
                      }),
                    ],
                  }),
                  blocks.Box.create({
                    children: [
                      blocks.Text.create({
                        as: 'b',
                        text: '$29',
                        className: 'text-display-md m-0',
                      }),
                      blocks.Text.create({
                        as: 'span',
                        text: '/mo',
                      }),
                      blocks.Text.create({
                        className: 'text-disabled text-sm',
                        text: 'fixed price',
                        style: { marginTop: 'calc(-1* var(--spacers-xxs))' },
                      }),
                    ],
                  }),
                ],
              }),
              blocks.VerticalVideo.create({
                className: { default: 'd-none', sm: 'sm' },
                src: videoSrc,
                backgroundImg: videoBackgroundSrc,
              }),
              blocks.Box.create({
                className: { default: 'p-t-0 p-x-lg p-b-xl', sm: 'p-x p-y' },
                children: [
                  blocks.Hr.create({
                    text: 'What’s included in the AI add-on?',
                    className: 'm-b-md',
                  }),
                  blocks.Benefits.create({
                    className: 'd-flex flex-col gap-md',
                    benefits,
                  }),
                ],
              }),
              blocks.Box.create({
                className: { default: 'p-t p-x-lg p-b-md', sm: 'p-x p-y' },
                children: [blocks.OrderSummarySmall.create({})],
              }),
            ],
          }),
          blocks.VerticalVideo.create({
            className: { default: '', sm: 'd-none' },
            src: videoSrc,
            backgroundImg: videoBackgroundSrc,
          }),
        ],
      }),
    ],
  })
}

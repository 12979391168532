import get from 'lodash/get'
import omit from 'lodash/omit'
import uniq from 'lodash/uniq'
import without from 'lodash/without'
import { Reducer } from 'redux'

import * as aTypes from 'apps/menu/constants/ReduxActionTypes'
import { updateStateChannel, getInitialMenuState } from 'apps/menu/menuHelpers'
import { MenuState } from 'apps/menu/menuInterfaces'
import { parseMenu } from 'apps/menu/models/MenuItem/adapter'
import { menuListReducer } from 'apps/menu/reducers/menuList'
import { ChannelType } from 'common/core/constants/ChannelType'

export const menuReducer: Reducer<MenuState> = (
  state = getInitialMenuState(),
  action,
): MenuState => {
  const list = menuListReducer(state.list, action)
  if (state.list !== list) {
    state = { ...state, list }
  }

  const channel: ChannelType = action.channel

  switch (action.type) {
    case aTypes.MENU_LOAD_REQUEST: {
      return updateStateChannel(state, channel, {
        isBusy: true,
        isFetched: false,
        errorOnLoad: null,
      })
    }
    case aTypes.MENU_PUBLISH_REQUEST:
    case aTypes.MENU_DISCARD_DRAFT_REQUEST: {
      return updateStateChannel(state, channel, {
        isBusy: true,
      })
    }

    case aTypes.MENU_LOAD_ERROR: {
      return updateStateChannel(state, channel, {
        errorOnLoad: { menuId: action.menuId, channel: action.channel },
        isBusy: false,
      })
    }
    case aTypes.MENU_PUBLISH_ERROR:
    case aTypes.MENU_DISCARD_DRAFT_ERROR: {
      return updateStateChannel(state, channel, {
        isBusy: false,
      })
    }

    case aTypes.MENU_SWITCH_ENABLED_REQUEST: {
      return updateStateChannel(state, channel, {
        enabled: null,
      })
    }

    case aTypes.MENU_SWITCH_ENABLED_ERROR: {
      return updateStateChannel(state, channel, {
        enabled: !action.enabled,
        isFetched: true,
      })
    }

    case aTypes.MENU_NOT_PUBLISHED_FOR_DISABLED_CHANNEL: {
      return updateStateChannel(state, channel, {
        isBusy: false,
      })
    }

    case aTypes.MENU_LOAD_RESPONSE:
    case aTypes.MENU_PUBLISH_RESPONSE:
    case aTypes.MENU_DISCARD_DRAFT_RESPONSE:
    case aTypes.MENU_SWITCH_ENABLED_RESPONSE: {
      const menuData = action.data.mainmenu

      if (!menuData.flow_ns) {
        return updateStateChannel(state, channel, {
          errorOnLoad: { menuId: action.menuId, channel: action.channel },
          isBusy: false,
        })
      }

      const publishedMenuData = get(menuData, 'data') || {}
      const draftMenuData = get(menuData, 'draft')
      const parsedDraft = parseMenu(draftMenuData || publishedMenuData)
      const parsedPublished = parseMenu(publishedMenuData, {
        parseStats: true,
      })

      const result = updateStateChannel(state, channel, {
        flowNs: menuData.flow_ns || action.data.flow_ns,
        name: menuData.name,
        draft: parsedDraft,
        published: parsedPublished,
        enabled: Boolean(menuData.enabled),
        isFetched: true,
        isBusy: false,
        isMain: Boolean(menuData.is_main),
        version: menuData.version,
      })

      if (action.type === aTypes.MENU_DISCARD_DRAFT_RESPONSE) {
        result[channel].lastSaved = null
        result[channel].saveError = null
        result[channel].lastChanged = null
      }

      return result
    }

    case aTypes.MENU_SET_NAME_RESPONSE: {
      const { menuId, name } = action
      if (state[channel].flowNs !== menuId) {
        return state
      }

      return updateStateChannel(state, channel, {
        name,
      })
    }

    case aTypes.MENU_AUTO_SAVE_REQUEST: {
      return updateStateChannel(state, channel, {
        isAutoSaving: true,
      })
    }
    case aTypes.MENU_AUTO_SAVE_SUCCESS: {
      return updateStateChannel(state, channel, {
        lastSaved: Date.now(),
        saveError: null,
        isAutoSaving: false,
      })
    }
    case aTypes.MENU_AUTO_SAVE_ERROR: {
      return updateStateChannel(state, channel, {
        saveError: action.saveError,
        isAutoSaving: false,
      })
    }

    case aTypes.MENU_ITEM_SELECT: {
      return updateStateChannel(state, channel, {
        serverError: null,
        selectedId: action.menuItemId,
      })
    }
    case aTypes.MENU_ITEM_CREATE: {
      const { menuItem } = action
      return updateStateChannel(state, channel, {
        draft: {
          byId: {
            ...state[channel].draft.byId,
            [menuItem.id]: menuItem,
          },
          nested: uniq([...state[channel].draft.nested, menuItem.id]),
        },
        showErrors: false,
        serverError: null,
        lastChanged: Date.now(),
      })
    }
    case aTypes.MENU_ITEM_REMOVE: {
      const { menuItemId } = action

      const result = updateStateChannel(state, channel, {
        draft: {
          nested: without(state[channel].draft.nested, menuItemId),
          byId: omit(state[channel].draft.byId, menuItemId),
        },
        showErrors: false,
        serverError: null,
        lastChanged: Date.now(),
      })

      // Nested menuItems is deprecated!
      Object.values(result[channel].draft.byId).forEach((menuItem) => {
        if (menuItem.nested.includes(menuItemId)) {
          result[channel].draft.byId[menuItem.id] = {
            ...menuItem,
            nested: without(menuItem.nested, menuItemId),
          }
        }
      })

      return result
    }
    case aTypes.MENU_ITEM_UPDATE: {
      const { menuItemId, changes } = action
      if (changes == null || !state[channel].draft.byId[menuItemId]) {
        return state
      }

      return updateStateChannel(state, channel, {
        draft: {
          ...state[channel].draft,
          byId: {
            ...state[channel].draft.byId,
            [menuItemId]: {
              ...state[channel].draft.byId[menuItemId],
              ...changes,
            },
          },
        },
        showErrors: false,
        serverError: null,
        lastChanged: Date.now(),
      })
    }
    case aTypes.MENU_ITEM_LINK_FLOW: {
      const { menuItemId, flowId } = action
      if (!state[channel].draft.byId[menuItemId]) {
        return state
      }

      return updateStateChannel(state, channel, {
        draft: {
          ...state[channel].draft,
          byId: {
            ...state[channel].draft.byId,
            [menuItemId]: {
              ...state[channel].draft.byId[menuItemId],
              flowId,
            },
          },
        },
        showErrors: false,
        serverError: null,
        lastChanged: Date.now(),
      })
    }
    case aTypes.MENU_ITEM_UNLINK_FLOW: {
      const { menuItemId } = action
      if (!state[channel].draft.byId[menuItemId]) {
        return state
      }

      return updateStateChannel(state, channel, {
        draft: {
          ...state[channel].draft,
          byId: {
            ...state[channel].draft.byId,
            [menuItemId]: {
              ...state[channel].draft.byId[menuItemId],
              flowId: null,
            },
          },
        },
        showErrors: false,
        serverError: null,
        lastChanged: Date.now(),
      })
    }
    case aTypes.MENU_REORDER_MENU_ITEM_ROOT: {
      const { order } = action
      return updateStateChannel(state, channel, {
        draft: {
          ...state[channel].draft,
          nested: order,
        },
        showErrors: false,
        serverError: null,
        lastChanged: Date.now(),
      })
    }

    case aTypes.MENU_SET_SERVER_ERROR: {
      const { menuItemId, message } = action
      return updateStateChannel(state, channel, {
        showErrors: true,
        serverError: {
          menuItemId,
          message,
        },
      })
    }
    case aTypes.MENU_SHOW_ERRORS: {
      return updateStateChannel(state, channel, {
        showErrors: true,
      })
    }
  }

  return state
}

import { createSelector } from 'reselect'

import { WalletType } from 'common/billing/wallet/interfaces/walletType'
import { DEFAULT_PAYMENT_AMOUNT } from 'common/billing/wallet/walletConstants'

import {
  WalletRefillOptions,
  WalletState,
  WalletTransaction,
  WalletUsageSummary,
} from './walletInterfaces'

export const getWalletState = (state: RootState): WalletState => state.billing.wallet

export const hasWallet = (state: RootState): boolean => state.billing.wallet.wallet.hasWallet

export const getAutoRefillStatus = (state: RootState): boolean =>
  getWalletState(state).wallet.autoRefill.isEnabled

export const getUsageSummary = (state: RootState): WalletUsageSummary[] =>
  state.billing.wallet.usageHistory.summary

export const getBalance = (state: RootState): number => getWalletState(state).wallet.balance

export const getIsCreditWallet = (state: RootState): boolean =>
  getWalletState(state).wallet.walletType === WalletType.CREDIT

export const getUsageTransactions = (state: RootState): WalletTransaction[] =>
  state.billing.wallet.usageHistory.transactions

export const getHasOlderTransactions = (state: RootState): boolean =>
  state.billing.wallet.usageHistory.hasOlderTransactions

/**
 * Selector to get state of active non-credit wallet with any balance.
 */
export const getIsNotCreditWalletActive = createSelector(
  [hasWallet, getBalance, getIsCreditWallet],
  (hasWallet, balance, isCredit) => {
    return hasWallet && balance > 0 && !isCredit
  },
)

export const getDefaultAmount = (state: RootState): number => {
  const options = state.billing.wallet.wallet.options

  if (options && options.amountOptions && options.amountOptions.length > 0) {
    return options.amountOptions[0].amount
  }

  return DEFAULT_PAYMENT_AMOUNT
}

export const getWalletRefillOptions = (state: RootState): WalletRefillOptions => {
  const options = state.billing.wallet.wallet.options

  return {
    triggerAutorefillAmountOptions: options?.triggerAutorefillAmountOptions ?? [],
    dailyLimitOptions: options?.dailyLimitOptions ?? [],
    amountOptions: options?.amountOptions ?? [],
  }
}

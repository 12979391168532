import React from 'react'
import { Icon, l } from '@manychat/manyui'

import {
  IntegrationType,
  IntegrationNameByType,
} from 'common/actions/integrations/common/constants/IntegrationType'
import { AccountTargetPermissionsNames } from 'common/core/models/Account/names'

import { createIntegration } from '../../common/model/helpers'

export const HotmartConfig = createIntegration({
  type: IntegrationType.HOTMART,
  name: IntegrationNameByType[IntegrationType.HOTMART],
  icon: Icon.Hotmart,
  permissionsVariable: AccountTargetPermissionsNames.HOTMART_INTEGRATION,
  description: () => (
    <div>
      {l.translate(
        'Hotmart empowers digital entrepreneurs with a leading online platform for selling and promoting digital products.',
      )}
    </div>
  ),
  reduxActions: {},
})

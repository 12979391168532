import { l } from '@manychat/manyui'

export enum ConditionTypes {
  ANY = 'any_message',
  EQUALS = 'equals',
  CONTAINS = 'contains',
  WORD_MATCH = 'word_match',
  STARTS = 'starts',
  THUMBS_UP = 'thumbs_up',
  DOES_NOT_CONTAIN = 'not_contains',
  GROUP = 'group',
  /** Special type for convertation Keyword to AI Intent */
  IS_ABOUT = 'is_about',
}

export const ConditionTypeLabels: Record<ConditionTypes, () => string> = {
  [ConditionTypes.ANY]: l.makeTranslate('Any Message or reaction'),
  [ConditionTypes.EQUALS]: l.makeTranslate('Message is'),
  [ConditionTypes.CONTAINS]: l.makeTranslate('Message contains'),
  [ConditionTypes.WORD_MATCH]: l.makeTranslate('Message contains whole word'),
  [ConditionTypes.STARTS]: l.makeTranslate('Message begins with'),
  [ConditionTypes.THUMBS_UP]: l.makeTranslate('Message is thumbs up'),
  [ConditionTypes.DOES_NOT_CONTAIN]: l.makeTranslate("Message doesn't contain"),
  [ConditionTypes.GROUP]: l.makeTranslate('GROUP'),
  [ConditionTypes.IS_ABOUT]: l.makeTranslate('Message is about'),
}

export const ConditionOptionAnyMessage = {
  value: ConditionTypes.ANY,
  label: ConditionTypeLabels[ConditionTypes.ANY],
}

export const ConditionOptions = [
  { value: ConditionTypes.EQUALS, label: ConditionTypeLabels[ConditionTypes.EQUALS] },
  {
    value: ConditionTypes.CONTAINS,
    label: ConditionTypeLabels[ConditionTypes.CONTAINS],
  },
  {
    value: ConditionTypes.WORD_MATCH,
    label: ConditionTypeLabels[ConditionTypes.WORD_MATCH],
  },
  { value: ConditionTypes.STARTS, label: ConditionTypeLabels[ConditionTypes.STARTS] },
  {
    value: ConditionTypes.THUMBS_UP,
    label: ConditionTypeLabels[ConditionTypes.THUMBS_UP],
  },
  {
    value: ConditionTypes.DOES_NOT_CONTAIN,
    label: ConditionTypeLabels[ConditionTypes.DOES_NOT_CONTAIN],
  },
  {
    value: ConditionTypes.IS_ABOUT,
    label: ConditionTypeLabels[ConditionTypes.IS_ABOUT],
  },
]

const LiveCommentsProhibitedConditions = [
  ConditionTypes.THUMBS_UP,
  ConditionTypes.DOES_NOT_CONTAIN,
  ConditionTypes.IS_ABOUT,
]

export const InstagramStoryConditionOptions = [
  ConditionOptionAnyMessage,
  ...ConditionOptions.filter((option) => !LiveCommentsProhibitedConditions.includes(option.value)),
]

export const THUMBS_UP_IMAGELINK =
  'https://manybot-files.s3.eu-central-1.amazonaws.com/201163470649892/ca/2018/08/10/9937cce7161c28c033fbdbf288256c43/851557_369239266556155_759568595_n'

export enum KeywordStatus {
  LIVE = 'live',
  DRAFT = 'draft',
  TRASH = 'trash',
}

export const SystemKeywordNames = {
  START: 'start',
  STOP: 'stop',
  SUBSCRIBE: 'subscribe',
  UNSUBSCRIBE: 'unsubscribe',
}

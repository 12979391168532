import { AccountStatus } from 'common/core/models/Account/constants'
import { UserRoles } from 'common/userRoles'

export const ACCOUNT_STATUS_WEIGHT: Record<AccountStatus | 'vippro', 0 | 1> = {
  [AccountStatus.FREE]: 0,
  [AccountStatus.EXPIRED]: 0,
  [AccountStatus.EXPIRED2020]: 0,
  [AccountStatus.FREE2020]: 0,
  [AccountStatus.PRO]: 1,
  [AccountStatus.PRO2020]: 1,
  [AccountStatus.PRO_TRIAL]: 1,
  [AccountStatus.PRO_TRIAL2020]: 1,
  [AccountStatus.FLEX_PRO]: 1,
  [AccountStatus.FLEX_FREE]: 0,
  [AccountStatus.FLEX_EXPIRED]: 0,
  [AccountStatus.FLEX_TRIAL]: 1,
  [AccountStatus.VIP_PRO]: 1,
  [AccountStatus.FLEX_PREMIUM]: 1,
}

export enum PageStatus {
  ACTIVE = 'active',
  BANNED = 'banned',
  DELETED = 'deleted',
  TEMPLATE = 'template',
}

export interface Account {
  accountStatus: AccountStatus
  activeSubscribers: number
  avatar: string | null
  id: string
  isFBAdmin: boolean
  isFavorite: boolean
  isHidden: boolean
  macTotalCount: number
  name: string
  openThreads: number
  pageStatus: PageStatus
  totalSubscribers: number
  userRole: UserRoles
}

type SortingMethod = (ascendant: boolean, account1: Account, account2: Account) => 1 | -1 | 0

export const sorting: Record<string, SortingMethod> = {
  name: (ascendant: boolean, account1: { name: string }, account2: { name: string }) => {
    const name1 = account1.name.toUpperCase()
    const name2 = account2.name.toUpperCase()

    if (name1 < name2) {
      return ascendant ? -1 : 1
    }

    if (name1 > name2) {
      return ascendant ? 1 : -1
    }

    return 0
  },
  accountStatus: (ascendant, account1, account2) => {
    const statusWeight1 = ACCOUNT_STATUS_WEIGHT[account1.accountStatus]
    const statusWeight2 = ACCOUNT_STATUS_WEIGHT[account2.accountStatus]

    if (statusWeight1 < statusWeight2) {
      return ascendant ? -1 : 1
    }

    if (statusWeight1 > statusWeight2) {
      return ascendant ? 1 : -1
    }

    return 0
  },
  userRole: (ascendant, account1, account2) => {
    const userRole1 = account1.userRole.toUpperCase()
    const userRole2 = account2.userRole.toUpperCase()

    if (userRole1 < userRole2) {
      return ascendant ? -1 : 1
    }

    if (userRole1 > userRole2) {
      return ascendant ? 1 : -1
    }

    return 0
  },
  openThreads: (ascendant, account1, account2) => {
    if (account1.openThreads < account2.openThreads) {
      return ascendant ? -1 : 1
    }

    if (account1.openThreads > account2.openThreads) {
      return ascendant ? 1 : -1
    }

    return 0
  },
  activeSubscribers: (ascendant, account1, account2) => {
    if (account1.activeSubscribers < account2.activeSubscribers) {
      return ascendant ? -1 : 1
    }

    if (account1.activeSubscribers > account2.activeSubscribers) {
      return ascendant ? 1 : -1
    }

    return 0
  },
  macTotalCount: (ascendant, account1, account2) => {
    if (account1.macTotalCount < account2.macTotalCount) {
      return ascendant ? -1 : 1
    }

    if (account1.macTotalCount > account2.macTotalCount) {
      return ascendant ? 1 : -1
    }

    return 0
  },
  isFavorite: (ascendant, account1, account2) => {
    if (account1.isFavorite === account2.isFavorite) {
      return 0
    }

    const comparedAccount = ascendant ? account1 : account2
    return comparedAccount.isFavorite ? 1 : -1
  },
  isHidden: (ascendant, account1, account2) => {
    if (account1.isHidden === account2.isHidden) {
      return 0
    }

    const comparedAccount = ascendant ? account1 : account2
    return comparedAccount.isHidden ? 1 : -1
  },
}

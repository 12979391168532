import { useState, useCallback } from 'react'
import { useAppSelector, useAppDispatch } from 'reduxTyped'

import { useModalData } from 'apps/cms/components/Flows/components/AutomationModals/hooks/useModalData'
import { FsValidationMessage } from 'apps/cms/lib/validation'
import { contentManagementActions, contentManagementSelectors } from 'apps/cms/store'
import { AutomationModalType } from 'apps/cms/store/lib/types'
import { alert } from 'common/core'

import { FormValues } from '../lib/types'

export const useRenameFolderModal = () => {
  const dispatch = useAppDispatch()

  const [isLoading, setIsLoading] = useState(false)
  const folders = useAppSelector(contentManagementSelectors.getFoldersByCurrentQueryPath)
  const { folderPath, folderName } = useModalData({ type: AutomationModalType.RENAME_FOLDER })

  const closeModal = useCallback(() => {
    dispatch(contentManagementActions.closeModal(AutomationModalType.RENAME_FOLDER))
  }, [dispatch])

  const renameFolder = useCallback(
    async ({ name }: FormValues) => {
      const folderWithSameName = folders.find(
        (listFolder) => listFolder.title === name && listFolder.path !== folderPath,
      )

      if (folderWithSameName) {
        return alert(FsValidationMessage.getFolderWithThisNameExistsMessage(), 'danger')
      }

      setIsLoading(true)
      await dispatch(contentManagementActions.renameFolder({ path: folderPath, title: name }))
      setIsLoading(false)

      closeModal()
    },
    [closeModal, dispatch, folderPath, folders],
  )

  return {
    isLoading,
    folderName,
    renameFolder,
    closeModal,
  }
}

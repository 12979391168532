import { api } from 'shared/api'
import { KeywordsSchemas } from 'shared/api/requests/keywords/schemas'

export const KeywordsApi = {
  setStatus: api.account.createPost({
    url: '/keywords/setStatus',
    schemas: KeywordsSchemas.setStatus,
  }),
  getList: api.account.createGet({
    url: '/keywords/list',
    schemas: KeywordsSchemas.getList,
  }),
  create: api.account.createPost({
    url: '/keywords/create',
    schemas: KeywordsSchemas.create,
  }),
  delete: api.account.createGet({
    url: '/keywords/delete',
    schemas: KeywordsSchemas.delete,
  }),
  update: api.account.createPost({
    url: '/keywords/update',
    schemas: KeywordsSchemas.update,
  }),
  sort: api.account.createPost({
    url: '/keywords/sort',
    schemas: KeywordsSchemas.sort,
  }),
  getItem: api.account.createGet({
    url: '/keywords/get',
    schemas: KeywordsSchemas.getItem,
  }),
  setFlow: api.account.createPost({
    url: '/keywords/setFlow',
    schemas: KeywordsSchemas.setFlow,
  }),
}

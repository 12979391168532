import React from 'react'
import { useAppSelector } from 'reduxTyped'
import { l } from '@manychat/manyui'

import { contentManagementSelectors } from 'apps/cms/store'

import cm from './FlowsEmptyState.module.css'

export const FlowsEmptyState = () => {
  const isSearchMode = useAppSelector(contentManagementSelectors.getIsSearchMode)
  return (
    <div className={cm.flowsEmptyState}>
      {isSearchMode ? l.translate('No Automations found') : l.translate('There is nothing here')}
    </div>
  )
}

import { Reducer } from 'redux'

import { ChannelType } from 'common/core/constants/ChannelType'
import { IceBreakerStatus } from 'shared/api/requests/iceBreakers/schemas'

import {
  getInitialIceBreakersChannelState,
  IceBreakerError,
  IceBreakersChannels,
  IceBreakersState,
  MappedIceBreaker,
} from '../constants/constants'
import * as actionTypes from '../constants/iceBreakersReduxActionTypes'
import { createIceBreaker } from '../models/adapter'

export const getInitialState = (): IceBreakersState =>
  IceBreakersChannels.reduce<IceBreakersState>((state, channel) => {
    state[channel] = getInitialIceBreakersChannelState(channel)

    return state
  }, {})

export const iceBreakersReducer: Reducer<IceBreakersState> = (
  state = getInitialState(),
  action,
) => {
  const channel = action.channel ?? ChannelType.FB
  const channelState = state[channel]

  switch (action.type) {
    case actionTypes.ICE_BREAKER_CREATE: {
      const { channel } = action
      const items = [
        ...channelState.items,
        createIceBreaker({ channel }, channelState.items.length),
      ]

      return { ...state, [channel]: { ...channelState, items } }
    }

    case actionTypes.ICE_BREAKER_UPDATE: {
      return {
        ...state,
        [channel]: {
          ...channelState,
          items: channelState.items.map((item) =>
            item.id === action.id ? { ...item, ...action.data } : item,
          ),
        },
      }
    }

    case actionTypes.ICE_BREAKERS_REORDER: {
      return {
        ...state,
        [channel]: {
          ...channelState,
          items: action.data,
        },
      }
    }

    case actionTypes.ICE_BREAKER_DELETE: {
      return {
        ...state,
        [channel]: {
          ...channelState,
          items: channelState.items.filter((item) => item.id !== action.id),
        },
      }
    }

    case actionTypes.ICE_BREAKERS_DELETE_BY_ORIGINAL_ID: {
      const { icebreakersIds } = action
      return {
        ...state,
        [channel]: {
          ...channelState,
          items: channelState.items.map((item) => {
            const isDelete = icebreakersIds.includes(item.icebreaker_id)
            if (isDelete) {
              return { ...item, status: IceBreakerStatus.DELETED }
            }
            return item
          }),
        },
      }
    }

    case actionTypes.ICE_BREAKERS_FETCH_REQUEST:
      return {
        ...state,
        [channel]: { ...channelState, fetching: true },
      }

    case actionTypes.ICE_BREAKERS_FETCH_RESPONSE: {
      const { data } = action
      const isPublished = data.some(
        (iceBreaker: MappedIceBreaker) => iceBreaker.status === IceBreakerStatus.PUBLISHED,
      )

      return {
        ...state,
        [channel]: {
          ...channelState,
          fetching: false,
          fetched: true,
          items: data,
          publishedItems: isPublished ? data : null,
        },
      }
    }
    case actionTypes.ICE_BREAKERS_RESET_FETCHED_FOR_ALL_CHANNELS: {
      let newState: IceBreakersState = { ...state }
      for (const channel of IceBreakersChannels) {
        newState = {
          ...newState,
          [channel]: {
            ...newState[channel],
            fetched: false,
          },
        }
      }
      return newState
    }
    case actionTypes.ICE_BREAKERS_FETCH_ERROR:
      return { ...state, [channel]: { ...channelState, fetching: false } }

    case actionTypes.ICE_BREAKERS_PUBLISH_REQUEST:
      return { ...state, [channel]: { ...channelState, publishing: true } }

    case actionTypes.ICE_BREAKERS_PUBLISH_ERROR:
      return { ...state, [channel]: { ...channelState, publishing: false } }

    case actionTypes.ICE_BREAKERS_PUBLISH_RESPONSE: {
      return {
        ...state,
        [channel]: {
          ...channelState,
          publishing: false,
          items: action.data,
          publishedItems: action.data,
        },
      }
    }

    case actionTypes.ICE_BREAKERS_SWITCH_ENABLED_REQUEST:
      return { ...state, [channel]: { ...channelState, switching: true } }

    case actionTypes.ICE_BREAKERS_SWITCH_ENABLED_ERROR:
      return { ...state, [channel]: { ...channelState, switching: false } }

    case actionTypes.ICE_BREAKERS_SWITCH_ENABLED_RESPONSE:
      return { ...state, [channel]: { ...channelState, switching: false } }

    case actionTypes.ICE_BREAKERS_VALIDATION: {
      const errorIds: string[] = action.errors?.map((error: IceBreakerError) => error.id) || []
      const newItems = channelState.items.map((item) => {
        if (errorIds.includes(item.id)) {
          return {
            ...item,
            isValidated: true,
          }
        }
        return item
      })

      return {
        ...state,
        [channel]: { ...channelState, items: newItems, validationCompleted: true },
      }
    }

    case actionTypes.ICE_BREAKERS_SET_INVALID_ID:
      return { ...state, [channel]: { ...channelState, invalidItem: action.data } }
  }

  return state
}

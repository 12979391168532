import { l } from '@manychat/manyui'

import { createListMessageSection } from 'apps/whatsApp/helpers/createListMessageSection'
import BaseBlockConfig from 'common/builder/blocks/BaseBlockConfig'
import { IBlockBase, IListMessageBlock } from 'common/builder/blocks/blockInterfaces'
import { BlockType } from 'common/builder/constants/BlockType'

class WaListMessageBlockConfig extends BaseBlockConfig {
  blockType = BlockType.WA_LIST_MESSAGE

  getDefaults = (): Omit<IListMessageBlock, keyof IBlockBase> => ({
    content: { body: '', button: l.translate('Button Title') },
    sections: [createListMessageSection()],
  })
}

export default new WaListMessageBlockConfig()

import { api } from 'shared/api'

import { WalletSchemas } from './schemas'

export const WalletApi = {
  fetchWallet: api.account.createGet({
    url: '/wallet/fetchWallet',
    schemas: WalletSchemas.fetchWallet,
  }),
  setAutoRefill: api.account.createPost({
    url: '/wallet/setAutoRefill',
    schemas: WalletSchemas.setAutoRefill,
  }),
  refillWallet: api.account.createPost({
    url: '/wallet/refillWallet',
    schemas: WalletSchemas.refillWallet,
  }),
  fetchUsageSummary: api.account.createGet({
    url: '/wallet/summaryReport?from=:from&to=:to',
    schemas: WalletSchemas.fetchUsageSummary,
  }),
  fetchTransactions: api.account.createGet({
    url: '/wallet/report?from=:from&to=:to&limit=:limit&last_operation_id=:lastOperationId',
    schemas: WalletSchemas.fetchTransactions,
  }),
}

import React, { useCallback, useEffect, useState } from 'react'
import debounce from 'lodash/debounce'

import flowChartApp from 'common/builder/components/chart/FlowChart/app'
import { analyticsService } from 'utils/services/analytics'
import { AnalyticsLeaveModalFrom } from 'utils/services/newOnboardingService/analytics'
import OnboardingLeaveModal from 'utils/services/newOnboardingService/view/LeaveModal/OnboardingLeaveModal'
import PointerBlockArea from 'utils/services/newOnboardingService/view/Pointer/PointerBlockArea/PointerBlockArea'
import {
  getFlowChartPointerNode,
  getPointerArea,
} from 'utils/services/newOnboardingService/view/Pointer/pointerHelpers'
import {
  useDomUpdates,
  useFlowChartUpdates,
  usePulseAnim,
  useScreenResize,
  useScreenScroll,
} from 'utils/services/newOnboardingService/view/Pointer/pointerHooks'
import {
  IArea,
  IPointer,
  PointerType,
} from 'utils/services/newOnboardingService/view/Pointer/pointerInterfaces'
import Tooltip from 'utils/services/newOnboardingService/view/Pointer/Tooltip/Tooltip'

const DOUBLE_CLICK_TIME = 1.2

const Pointer = ({
  pointerConfig,
  onboardingId,
  stepId,
  onLeave,
}: {
  pointerConfig: IPointer
  onboardingId: string
  stepId: string
  onLeave: () => void
}) => {
  const pointerType = pointerConfig?.type

  const [resizeAnimClass, pulseAnimClass, pulseIt] = usePulseAnim()
  const [lastClickOutsideTime, setLastClickOutsideTime] = useState(0)
  const [isLeaveModalOpen, setLeaveModalOpen] = useState(false)

  const [area, setArea] = useState<IArea | undefined>(undefined)
  const updateArea = useCallback((pointerConfig: IPointer | undefined) => {
    const area = pointerConfig ? getPointerArea(pointerConfig) : undefined
    setArea(area)
  }, [])

  const flowChartMoveTo = useCallback((pointerConfig: IPointer | undefined) => {
    if (!pointerConfig || pointerConfig.type !== PointerType.FLOW_CHART || !pointerConfig.moveTo) {
      return
    }
    const node = getFlowChartPointerNode(pointerConfig)
    if (node) {
      setTimeout(flowChartApp.moveTo, 0, node.id)
    }
  }, [])

  useEffect(() => {
    updateArea(pointerConfig)
    flowChartMoveTo(pointerConfig)
  }, [pointerConfig, updateArea, flowChartMoveTo])

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleFlowChartUpdate = useCallback(
    debounce(() => {
      updateArea(pointerConfig)
    }, 10),
    [pointerConfig, updateArea],
  )

  useFlowChartUpdates(handleFlowChartUpdate, pointerType === PointerType.FLOW_CHART)

  useDomUpdates(
    useCallback(() => updateArea(pointerConfig), [updateArea, pointerConfig]),
    pointerConfig !== undefined,
  )

  useScreenResize(
    useCallback(() => updateArea(pointerConfig), [updateArea, pointerConfig]),
    pointerConfig !== undefined,
  )

  useScreenScroll(
    useCallback(() => updateArea(pointerConfig), [updateArea, pointerConfig]),
    pointerConfig !== undefined,
  )

  const sendAnalyticsModalEvent = useCallback(
    (name: string) => {
      analyticsService.sendEvent(name, {
        onboardingId,
        stepId,
        from: AnalyticsLeaveModalFrom.POINTER,
      })
    },
    [onboardingId, stepId],
  )

  const closeLeaveModal = useCallback(() => {
    sendAnalyticsModalEvent('MC_ONBOARDING.LEAVE_MODAL.CLOSE')

    setLeaveModalOpen(false)
  }, [sendAnalyticsModalEvent])

  const handleLeave = useCallback(() => {
    sendAnalyticsModalEvent('MC_ONBOARDING.LEAVE_MODAL.LEAVE')

    onLeave()
  }, [sendAnalyticsModalEvent, onLeave])

  const handleClickOutside = () => {
    const currentTime = Date.now()
    const clickOutsideDiff = 1000
    const lastClickDiff = (currentTime - lastClickOutsideTime) / clickOutsideDiff
    if (lastClickDiff < DOUBLE_CLICK_TIME) {
      sendAnalyticsModalEvent('MC_ONBOARDING.LEAVE_MODAL.SHOW')

      setLeaveModalOpen(true)
    } else {
      pulseIt()
    }

    setLastClickOutsideTime(currentTime)
  }

  if (!pointerConfig) {
    return null
  }

  const padding = pointerConfig.padding || 0

  return (
    <>
      <OnboardingLeaveModal
        open={isLeaveModalOpen}
        onClose={closeLeaveModal}
        onLeave={handleLeave}
      />
      {pointerConfig.tooltip && area && (
        <Tooltip stepId={stepId} area={area} tooltip={pointerConfig.tooltip} padding={padding} />
      )}
      <PointerBlockArea
        area={area}
        padding={padding}
        blockAreaInput={pointerConfig.blockAreaInput}
        className={{ selectionArea: resizeAnimClass, blockingArea: pulseAnimClass }}
        onClickOutside={handleClickOutside}
        stepId={stepId}
      />
    </>
  )
}

export default Pointer

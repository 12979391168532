import dot from 'dot-prop-immutable'

import * as atypes from 'apps/chat/constants/LiveChatReduxActionTypes'
import { makeLimitedListReducer } from 'utils/factory'

const initialState = {
  activeMessageId: null,
}

const listReducer = makeLimitedListReducer('liveChatSearch', {
  initialState,
  storePath: 'chat.search',
})

export default function searchReducer(state, action) {
  state = listReducer(state, action)

  switch (action.type) {
    case atypes.LIVE_CHAT_SEARCH_RESULTS_CLICK: {
      const { messageId } = action
      return dot.set(state, 'activeMessageId', messageId)
    }
    case atypes.LIVE_CHAT_RESET_ACTIVE_MESSAGE: {
      return dot.set(state, 'activeMessageId', null)
    }
  }
  return state
}

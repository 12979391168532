import { makeLimitedListReducer } from 'utils/factory'

import * as atypes from '../answersReduxActionTypes'

const listReducer = makeLimitedListReducer('answers')

const initialStatus = {
  done: false,
  progress: 0,
  result: null,
  signature: null,
}

const initialState = {
  isExportingData: false,
  status: null,
}

export default function answersReducer(state = initialState, action) {
  state = listReducer(state, action)

  switch (action.type) {
    case atypes.FORMS_ANSWERS_PREPARE_EXPORT_REQUEST: {
      return { ...state, isExportingData: true, status: initialStatus }
    }
    case atypes.FORMS_ANSWERS_PREPARE_EXPORT_RESPONSE:
    case atypes.FORMS_ANSWERS_PREPARE_EXPORT_ERROR: {
      const { async_status } = action.data
      if (state.status && state.status.done) {
        return state
      }
      return { ...state, isExportingData: false, status: async_status }
    }

    case atypes.FORM_ANSWERS_UPDATE_EXPORTING_STATUS: {
      const { status } = action.data
      return { ...state, isExportingData: false, status }
    }
  }

  return state
}

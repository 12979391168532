import { Reducer } from 'redux'

import * as atypes from 'common/actions/integrations/common/constants/IntegrationsReduxActionTypes'

export const integrationsLoadersReducer: Reducer<Record<string, boolean>> = (
  state = {},
  action,
) => {
  switch (action.type) {
    case atypes.INTEGRATION_FETCH_REQUEST: {
      return { ...state, [action.integrationType]: true }
    }
    case atypes.INTEGRATION_FETCH_RESPONSE: {
      return { ...state, [action.integrationType]: false }
    }
    default:
      return state
  }
}

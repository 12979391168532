import React from 'react'
import findIndex from 'lodash/findIndex'
import get from 'lodash/get'
import isArray from 'lodash/isArray'
import isObject from 'lodash/isObject'
import isString from 'lodash/isString'
import { v4 as uuid } from 'uuid'
import { l, BtnV2 } from '@manychat/manyui'

import * as atypes from 'apps/triggerRules/constants/TriggerRulesReduxActionTypes'
import {
  TriggerRuleStatusType,
  TriggerRuleType,
} from 'apps/triggerRules/models/TriggerRule/constants'
import { getActionListErrors } from 'common/actions/models/Action/validation'
import { alert } from 'common/core'
import * as appSelectors from 'common/core/selectors/appSelectors'
import { getCustomEventsItems } from 'common/events/selectors/eventSelectors'
import * as userFieldsSelectors from 'common/fields/selectors/userFieldsSelectors'
import { TriggerType } from 'common/flow/constants/TriggerType'
import { isRequestError } from 'utils/api/mcApi/RequestError'
import { makeLimitedListActions, makeItemsDetailsActions } from 'utils/factory'
import { navigatePollyfill, isPathnameInclude } from 'utils/router/tools'
import { analyticsService } from 'utils/services/analytics'
import { anotherTabNotificationsListener } from 'utils/services/notificationsService'
import { linkURL } from 'utils/url'

const baseListActions = makeLimitedListActions('triggerRules', { storePath: 'triggerRules.list' })
export const fetchTriggerRules = baseListActions.fetch

const itemDetailsActions = makeItemsDetailsActions('triggerRules')
export const fetchTriggerRule = itemDetailsActions.fetch
export const updateTriggerRule = itemDetailsActions.update
export const initTriggerRule = itemDetailsActions.init

export const setTriggerRuleTitle = (rule_id, title) => {
  return async (dispatch, getState) => {
    const data = await dispatch(itemDetailsActions.setTitle(rule_id, { rule_id, title }))
    alert(l.translate(`Renamed!`), 'success')
    return data
  }
}

export const createTriggerRuleBase = baseListActions.create

export const createTriggerRule = (name) => {
  return async (dispatch, getState) => {
    const state = getState()
    const { items } = state.triggerRules.list
    const title = name || `${l.translate('New Rule')}${items ? ` #${items.length + 1}` : ''}`
    return dispatch(baseListActions.create({ title }))
  }
}

export const createTriggerRuleWithName = (ruleName) => {
  return async (dispatch) => {
    const title = `${ruleName}`
    return dispatch(baseListActions.create({ title }))
  }
}

export const createTriggerRuleAndOpen = (name) => {
  return async (dispatch) => {
    const resp = await dispatch(createTriggerRule(name))
    navigatePollyfill(linkURL(`/rules/${resp.item.rule_id}`))
  }
}

export const cloneTriggerRule = (rule_id) => {
  return async (dispatch, getState) => {
    const data = await dispatch(baseListActions.copy(null, { rule_id }))
    alert(l.translate('Rule was successfully duplicated!'), 'info', {
      content: (
        <BtnV2
          size="small"
          fullWidth
          className="m-t"
          data-test-id={`rules-alert-open-copy-btn`}
          onClick={navigatePollyfill(linkURL(`/rules/${data.item.rule_id}`))}
        >
          {l.translate('Open the copy')}
        </BtnV2>
      ),
    })
    return data
  }
}

export const deleteTriggerRule = (rule_id) => {
  return async (dispatch, getState) => {
    const rules = getState().triggerRules
    const data = await dispatch(baseListActions.delete(undefined, { rule_id }))

    const rule = rules.list.byId[rule_id] || rules.byId[rule_id]
    const ruleName = rule?.title
    if (ruleName) {
      alert(l.translate('Rule {ruleName} was deleted.', { ruleName }), 'info')
    }
    return data
  }
}

export const deleteTriggerRuleAndClose = (rule_id) => {
  return async (dispatch, getState) => {
    const data = await dispatch(deleteTriggerRule(rule_id))
    navigatePollyfill(linkURL(`/cms#rules`))
    return data
  }
}

export const setTriggerRuleStatusAndSave = (rule_id, status, options = {}) => {
  return async (dispatch, getState) => {
    const rules = getState().triggerRules
    const ruleData = rules.list.byId[rule_id] || rules.byId[rule_id]

    analyticsService.sendEvent('TRIGGER_RULES.ENABLE.CLICK', { status, source: options.source })

    if (ruleData) {
      const { hasChanges, item } = ruleData
      if (status === TriggerRuleStatusType.ACTIVE && Boolean(item) && hasChanges) {
        const isSaved = await dispatch(saveTriggerRule(rule_id, true))
        if (!isSaved) {
          return false
        }
      }
    }
    return dispatch(setTriggerRuleStatus(rule_id, status))
  }
}

export const setTriggerRuleStatus = (rule_id, status) => {
  return async (dispatch, getState) => {
    const asyncHash = uuid()
    try {
      await dispatch(baseListActions.changeStatus(undefined, { rule_id, status, async: asyncHash }))

      analyticsService.sendEvent('TRIGGER.STATUS.SWITCHED', {
        isEnabled: status === TriggerRuleStatusType.ACTIVE,
        triggerId: `${TriggerType.RULE}-${rule_id}`,
      })
    } catch (error) {
      if (isRequestError(error) && error.reason === 'business-logic') {
        return
      }

      const message = l.translate(
        'Something went wrong on the server, the status of the rule cannot be changed',
      )
      alert(message, 'danger')
    }

    return true
  }
}

export const triggerRuleCreatedNotification = (data) => baseListActions.createdNotification(data)

export const triggerRuleCopiedNotification = (data) => {
  return async (dispatch, getState) => {
    const rule = getState().triggerRules.byId[data.rule_id]

    if (!rule) {
      dispatch(baseListActions.createdNotification(data))
    }
  }
}

export const setTriggerRuleStatusNotification = (statusData) => {
  return (dispatch) => {
    dispatch(itemDetailsActions.updatedNotification(statusData.result))
    dispatch({ type: atypes.TRIGGERRULES_STATUS_UPDATED_NOTIFICATION, statusData })
  }
}
export const setTriggerRuleTitleNotification = (data) =>
  itemDetailsActions.updatedNotification(data)
export const triggerRuleUpdatedNotification = (data) => itemDetailsActions.updatedNotification(data)
export const triggerRuleDeletedNotification = (id) => {
  return async (dispatch, getState) => {
    if (isPathnameInclude(linkURL(`rules/${id}`))) {
      navigatePollyfill(linkURL('cms#rules'))
    }
    return dispatch(baseListActions.deletedNotification(id))
  }
}

export const saveTriggerRule = (ruleId, willActiveAfter, options = {}) => {
  return async (dispatch, getState) => {
    const state = getState()
    const { item } = state.triggerRules.byId[ruleId]

    if (!item.actions.length) {
      alert(l.translate('At least 1 action must be set'), 'danger')
      return false
    }

    if (!item.triggers.length) {
      alert(l.translate('At least 1 trigger must be set'), 'danger')
      return false
    }

    const userFields = userFieldsSelectors.getItems(state)
    const customEvents = getCustomEventsItems(state)
    const actionErrors = getActionListErrors(item.actions, { userFields, customEvents })
    if (actionErrors.length) {
      const error = actionErrors[0]
      alert(error.message, 'danger')
      return false
    }

    const invalidTrigger = item.triggers.find((trigger, index) => {
      if (trigger.type === TriggerRuleType.DATE_TIME_EVENT && !trigger.data?.event_filter?.type) {
        alert(l.translate('Trigger {n}: user field not set', { n: index + 1 }), 'danger')
        return true
      }
    })
    if (invalidTrigger) {
      return false
    }

    let result
    try {
      result = await dispatch({
        type: atypes.TRIGGERRULES_CURRENT_SAVE,
        id: item.id,
        $endpoint: [`triggerRules.save`, null, { trigger_rule: item, rule_id: item.rule_id }],
        $error: () => {},
      })
    } catch (err) {
      const triggerErrorMap =
        get(err, 'response.errors.triggers') || get(err, 'response.errors.trigger')
      if (isString(triggerErrorMap)) {
        alert(triggerErrorMap, 'danger')
        return false
      }
      if (isObject(triggerErrorMap)) {
        const triggerId = Object.keys(triggerErrorMap)[0]
        const index = findIndex(item.triggers, (trigger) => {
          return trigger._oid === triggerId || get(trigger, 'data.event_filter._oid') === triggerId
        })
        if (index >= 0) {
          const message = isArray(triggerErrorMap[triggerId])
            ? triggerErrorMap[triggerId][0]
            : triggerErrorMap[triggerId]
          alert(l.translate('Trigger {n}: {message}', { n: index + 1, message }), 'danger')
          return false
        }
      }
      const actions = err?.data?.errors?.actions
      if (isString(actions)) {
        alert(actions, 'danger')
        return false
      }
      alert(err, 'danger')
      return false
    }

    if (result.need_activate) {
      await dispatch(setTriggerRuleStatus(item.rule_id, TriggerRuleStatusType.ACTIVE))
    }
    const isPro = appSelectors.getCurrentAccount(getState()).isPro
    const isNotActive =
      get(getState(), `triggerRules.byId.${ruleId}.item.status`) !== TriggerRuleStatusType.ACTIVE

    if (!options.hideSuccessAlert) {
      if (!result.need_activate && isNotActive && !willActiveAfter && isPro) {
        alert(l.translate('Rule was successfully saved!'), 'success')
      } else {
        alert(l.translate('Saved!'), 'success')
      }
    }

    return true
  }
}

anotherTabNotificationsListener.on('trigger_rule_status_update_action', (data, dispatch) => {
  dispatch(setTriggerRuleStatusNotification(data.status))
})

anotherTabNotificationsListener.on('trigger_rule_created', (data, dispatch) => {
  dispatch(triggerRuleCreatedNotification(data.model))
})

anotherTabNotificationsListener.on('trigger_rule_copied', (data, dispatch) => {
  dispatch(triggerRuleCopiedNotification(data.model))
})

anotherTabNotificationsListener.on('trigger_rule_title_set', (data, dispatch) => {
  dispatch(setTriggerRuleTitleNotification({ setTitle: true, ...data.model }))
})

anotherTabNotificationsListener.on('trigger_rule_updated', (data, dispatch) => {
  dispatch(triggerRuleUpdatedNotification(data.model.trigger_rule))
})

anotherTabNotificationsListener.on('trigger_rule_deleted', (data, dispatch) => {
  dispatch(triggerRuleDeletedNotification(data.model.rule_id))
})

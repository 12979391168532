import PropTypes from 'prop-types'

import { ITemplateAccessData } from 'apps/templates/constants/templatesInterfaces'

export const TemplateAccessDataPropTypes = PropTypes.shape({
  is_owner: PropTypes.bool.isRequired,
  is_locked: PropTypes.bool.isRequired,
  is_protected: PropTypes.bool.isRequired,
  is_disabled: PropTypes.bool,
})

export const DefaultTemplateAccessData: ITemplateAccessData = {
  is_owner: true,
  is_disabled: false,
  is_locked: false,
  is_protected: false,
}

import { combineReducers } from 'redux'

import { makeLimitedListReducer } from 'utils/factory'

import * as atypes from '../mappingReduxActionTypes'

function otnsMapReducer(state = {}, action) {
  switch (action.type) {
    case atypes.TARGET_PAGE_UPDATE_OTNS_MAP: {
      const { data } = action
      return { ...state, ...data }
    }
    case atypes.TARGET_PAGE_RESET_MAPS: {
      return {}
    }
  }
  return state
}

function tagsMapReducer(state = {}, action) {
  switch (action.type) {
    case atypes.TARGET_PAGE_UPDATE_TAGS_MAP: {
      const { data } = action
      return { ...state, ...data }
    }
    case atypes.TARGET_PAGE_RESET_MAPS: {
      return {}
    }
  }
  return state
}

function cufsMapReducer(state = {}, action) {
  switch (action.type) {
    case atypes.TARGET_PAGE_UPDATE_CUFS_MAP: {
      const { data } = action
      return { ...state, ...data }
    }
    case atypes.TARGET_PAGE_RESET_MAPS: {
      return {}
    }
  }
  return state
}

function gafsMapReducer(state = {}, action) {
  switch (action.type) {
    case atypes.TARGET_PAGE_UPDATE_GAFS_MAP: {
      const { data } = action
      return { ...state, ...data }
    }
    case atypes.TARGET_PAGE_RESET_MAPS: {
      return {}
    }
  }
  return state
}

function customEventsMapReducer(state = {}, action) {
  switch (action.type) {
    case atypes.TARGET_PAGE_UPDATE_CUSTOM_EVENTS_MAP: {
      const { data } = action
      return { ...state, ...data }
    }
    case atypes.TARGET_PAGE_RESET_MAPS: {
      return {}
    }
  }
  return state
}

export default combineReducers({
  userFields: makeLimitedListReducer('targetPageUserFields'),
  tags: makeLimitedListReducer('targetPageTags'),
  globalFields: makeLimitedListReducer('targetPageGlobalFields'),
  otns: makeLimitedListReducer('targetPageOtns'),
  customEvents: makeLimitedListReducer('targetPageCustomEvents'),
  tagsMap: tagsMapReducer,
  cufsMap: cufsMapReducer,
  gafsMap: gafsMapReducer,
  otnsMap: otnsMapReducer,
  customEventsMap: customEventsMapReducer,
})

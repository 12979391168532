import React from 'react'
import cx from 'classnames'
import { useAppSelector } from 'reduxTyped'
import { l } from '@manychat/manyui'

import { billing, UpgradeSource } from 'common/billing'
import * as appSelectors from 'common/core/selectors/appSelectors'
import { useDidMountEffect } from 'utils/commonHooks'
import { analyticsService } from 'utils/services/analytics'

import cm from '../StaticNotifications.module.css'

export const AfterExpiredPeriodNotification = () => {
  const daysBeforeBlocked = useAppSelector(appSelectors.getDaysBeforeBlocked)

  useDidMountEffect(() => {
    analyticsService.sendEvent('NOTIFICATION_BAR.AFTER_EXPIRED_PERIOD.SHOWN')
  })

  const handleClick = () => {
    analyticsService.sendEvent('NOTIFICATION_BAR.AFTER_EXPIRED_PERIOD.CLICKED')
    billing.requestUpgrade({ source: UpgradeSource.AFTER_EXPIRED_PERIOD_NOTIFY_BAR })
  }

  const text =
    daysBeforeBlocked !== null && daysBeforeBlocked > 0
      ? l.translate(
          "We couldn't process your last payment for Pro. Your account only has access to Free plan features for {daysBeforeBlocked} more days.",
          { daysBeforeBlocked },
        )
      : l.translate(
          "We couldn't process your last payment for Pro. Your access to Free plan features expires today.",
        )

  return (
    <>
      <span>{text}</span>
      <a target="_blank" className={cx(cm.more, 'm-l-xs')} onClick={handleClick}>
        {l.translate('Upgrade to Pro')}
      </a>
    </>
  )
}

import Centrifuge from 'centrifuge'

import { setInitialWallet } from 'common/billing/wallet/walletActions'
import * as appActions from 'common/core/actions/appActions'
import { bulkActionStatsUpdate } from 'common/core/bulkActions'
import { IAsyncThunkAction, IThunkAction } from 'common/core/interfaces/actions'
import { getCurrentAccount, getUser } from 'common/core/selectors/appSelectors'
import * as settingsActions from 'common/settings/actions/settingsActions'
import { setInitialSettings } from 'common/settings/actions/settingsActions'
import { getAccountIdFromURL } from 'utils/accountId'
import { trackLastClickedElement } from 'utils/misc'
import {
  accountNotificationsListener,
  anotherTabNotificationsListener,
  wsService,
} from 'utils/services/notificationsService'
import { initializeNotificationsService } from 'utils/services/notificationsService/notificationsService'

import * as initApi from './initAppAPI'

/**
 * DISCLAIMER: This action use only on prod
 */
export const updateAppData = (accountId: AccountId) => {
  const fetchData: IAsyncThunkAction = async (dispatch) => {
    if (!accountId) {
      console.warn('Manychat is running in non account-scope environment')
    }

    const data = await initApi.getAppData(accountId)
    if (!data) {
      return
    }

    const walletInfo = data['app.currentAccountWallet'] ?? {}
    await dispatch(setInitialWallet(walletInfo))
    dispatch(setInitialSettings(data))

    return dispatch(appActions.setAppData(data))
  }

  return fetchData
}

export const initializePostRenderingLogic = (): IThunkAction => (dispatch, getState) => {
  const state = getState()
  const accountScopeParams = getCurrentAccount(state)?.notify
  const userScopeParams = getUser(state)?.notify
  wsService.setStore(dispatch, getState)
  initializeNotificationsService(wsService, Centrifuge, accountScopeParams, userScopeParams)

  accountNotificationsListener.on('test', (data) => {
    console.log(data)
  })
  anotherTabNotificationsListener.on('bulk_action_status', (data) => {
    dispatch(bulkActionStatsUpdate(data))
  })
  anotherTabNotificationsListener.on('subscribers_list_export_status', (data) => {
    dispatch(bulkActionStatsUpdate(data))
  })

  trackLastClickedElement()

  const accountId = getAccountIdFromURL()

  if (accountId) dispatch(settingsActions.getMessagingFeatureReview())
}

import { l } from '@manychat/manyui'

import { alert } from 'common/core'
import { IAsyncThunkActionWithReturnValue, IThunkAction } from 'common/core/interfaces/actions'
import { CreateFolderResponse } from 'common/core/interfaces/folder'
import { FIELD_ROOT_FOLDER_ID } from 'common/fields/constants'
import { getIsFolderNameExists } from 'common/fields/selectors/userFieldFoldersSelectors'
import * as API from 'constants/API'
import { makeLimitedListActions } from 'utils/factory'
import { anotherTabNotificationsListener } from 'utils/services/notificationsService'

import { fetchUserFieldsWithParams, moveToFolderBulkUserField } from './userFieldActions'

const FOLDER_ENTITY_CUFS = 20
const ROOT_PATH = '/'

const baseListActions = makeLimitedListActions('userFieldFolders')

export const fieldFoldersCreatedNotification = baseListActions.createdNotification
export const fieldFoldersUpdatedNotification = baseListActions.updatedNotification
export const fieldFoldersDeletedNotification = baseListActions.deletedNotification

export const fetchUserFieldFolders = () =>
  baseListActions.fetch({}, { entity: FOLDER_ENTITY_CUFS, path: ROOT_PATH })

export const createUserFieldFolder =
  (title: string): IThunkAction =>
  async (dispatch, getState) => {
    const isFolderNameExists = getIsFolderNameExists(getState(), title)

    if (isFolderNameExists) {
      alert(l.translate('Folder with this name already exists'), 'danger')
      return
    }

    const response = await dispatch(
      baseListActions.create({ title, entity: FOLDER_ENTITY_CUFS, path: ROOT_PATH }),
    )
    alert(l.translate('Folder created'), 'success')

    return response
  }

export const createFolderAndMoveFields = (title: string, fieldIds: number[]): IThunkAction => {
  return async (dispatch, getState) => {
    const isFolderNameExists = getIsFolderNameExists(getState(), title)

    if (isFolderNameExists) {
      alert(l.translate('Folder with this name already exists'), 'danger')
      return
    }

    const { folder } = (await dispatch(
      createUserFieldFolder(title),
    )) as unknown as CreateFolderResponse
    await dispatch(moveToFolderBulkUserField(fieldIds, folder.folder_id, FIELD_ROOT_FOLDER_ID))
  }
}

export const removeUserFieldFolder =
  (
    folderId: number,
    options: {
      path: string
      delete_content: boolean
    },
  ): IThunkAction =>
  async (dispatch) => {
    await dispatch(baseListActions.delete(folderId, options))

    dispatch(fetchUserFieldsWithParams(true, { path: '/' }))

    alert(l.translate('Folder deleted!'), 'success')
  }

export const updateUserFieldFolderName =
  (folderId: number, path: string, title: string): IAsyncThunkActionWithReturnValue<boolean> =>
  async (dispatch, getState) => {
    const isFolderNameExists = getIsFolderNameExists(getState(), title)

    if (isFolderNameExists) {
      alert(l.translate('Folder with this name already exists'), 'danger')
      return false
    }
    await dispatch({
      type: 'USER_FIELD_FOLDERS_UPDATE_NAME',
      $fetch: [
        API.userFieldFolders.endpoints.rename[0],
        {
          method: 'POST',
          body: JSON.stringify({ path, title }),
          headers: { 'Content-Type': 'application/json' },
        },
      ],
    })
    dispatch(baseListActions.update(folderId, { title }))
    alert(l.translate('Folder renamed!'), 'success')
    return true
  }

anotherTabNotificationsListener.on('folder_created', (data, dispatch) => {
  if (data.folder.entity === FOLDER_ENTITY_CUFS) {
    dispatch(fieldFoldersCreatedNotification(data.folder))
  }
})

anotherTabNotificationsListener.on('folder_renamed', (data, dispatch) => {
  if (data.folder.entity === FOLDER_ENTITY_CUFS) {
    dispatch(fieldFoldersUpdatedNotification(data.folder))
  }
})

anotherTabNotificationsListener.on('folder_deleted', (data, dispatch) => {
  if (data.folder) {
    if (data.folder.entity === FOLDER_ENTITY_CUFS) {
      dispatch(fieldFoldersDeletedNotification(data.folder.folder_id))
    }
  }
})

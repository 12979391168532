import { DeepPartial } from 'utility-types'
import { z } from 'zod'

import { ChannelStatus } from 'common/core/interfaces/channelStatuses'
import { AVAILABLE_CURRENCIES } from 'common/settings/interfaces'
import { FilterSchema } from 'shared/api/common/schemas/filters'
import { defaultReplySchema } from 'shared/api/requests/defaultReply/schemas'

export enum RequestPhoneStatus {
  SENT_CODE = 'sent_code',
  CONNECTED = 'connected',
  ERROR = 'error',
}
export enum VerifyPhoneStatus {
  WRONG_CODE = 'wrong_code',
  CONNECTED = 'connected',
  ERROR = 'error',
}

export type RequestCodeResponse = z.infer<typeof whatsAppApiSchemas.requestCode.response>
export type VerifyCodeStatusResponse = z.infer<typeof whatsAppApiSchemas.verifyCode.response>

export enum WaMessageTemplateStatus {
  PENDING = 'PENDING',
  REJECTED = 'REJECTED',
  APPROVED = 'APPROVED',
  DRAFT = 'DRAFT',
  DELETED = 'DELETED',
  PAUSED = 'PAUSED',
  DISABLED = 'DISABLED',
}

export enum WaMessageTemplateHeaderType {
  IMAGE = 'IMAGE',
  VIDEO = 'VIDEO',
  TEXT = 'TEXT',
  DOCUMENT = 'DOCUMENT',
}

export enum WaMessageTemplateCategoryId {
  ACCOUNT_UPDATE = 'ACCOUNT_UPDATE',
  PAYMENT_UPDATE = 'PAYMENT_UPDATE',
  PERSONAL_FINANCE_UPDATE = 'PERSONAL_FINANCE_UPDATE',
  SHIPPING_UPDATE = 'SHIPPING_UPDATE',
  RESERVATION_UPDATE = 'RESERVATION_UPDATE',
  ISSUE_RESOLUTION = 'ISSUE_RESOLUTION',
  APPOINTMENT_UPDATE = 'APPOINTMENT_UPDATE',
  TRANSPORTATION_UPDATE = 'TRANSPORTATION_UPDATE',
  TICKET_UPDATE = 'TICKET_UPDATE',
  ALERT_UPDATE = 'ALERT_UPDATE',
  AUTO_REPLY = 'AUTO_REPLY',
  // WhatsApp API V16
  AUTHENTICATION = 'AUTHENTICATION',
  MARKETING = 'MARKETING',
  UTILITY = 'UTILITY',
}

export enum WaMessageTemplateButtonType {
  QuickReply = 'QUICK_REPLY',
  Url = 'URL',
}

export enum WaMessageTemplateRejectionReason {
  ABUSIVE_CONTENT = 'ABUSIVE_CONTENT',
  INCORRECT_CATEGORY = 'INCORRECT_CATEGORY',
  INVALID_FORMAT = 'INVALID_FORMAT',
  PROMOTIONAL = 'PROMOTIONAL',
  SCAM = 'SCAM',
  TAG_CONTENT_MISMATCH = 'TAG_CONTENT_MISMATCH',
  NONE = 'NONE',
}

export enum WaMessageTemplatePauseTitle {
  PAUSE_FOR_3_HOURS = 'first',
  PAUSE_FOR_6_HOURS = 'second',
}

export enum RegistrationFlowStep {
  NONE = 'none',
  SIGN_UP_PENDING = 'sign_up_pending',
  VERIFICATION_PENDING = 'verification_pending',
  ACTIVE = 'active',
}

export enum WaAccountReviewStatus {
  APPROVED = 'APPROVED',
  PENDING = 'PENDING',
  REJECTED = 'REJECTED',
}

export enum WaBusinessVerificationStatus {
  MORE_INFORMATION_REQUESTED = 'MORE_INFORMATION_REQUESTED',
  BUSINESS_VERIFIED = 'BUSINESS_VERIFIED',
  BUSINESS_REJECTED = 'BUSINESS_REJECTED',
  BUSINESS_REVOKED = 'BUSINESS_REVOKED',
  VERIFIED = 'verified',
  REJECTED = 'rejected',
  PENDING = 'pending',
  REVOKED = 'revoked',
  NOT_VERIFIED = 'not_verified',
  PENDING_SUBMISSION = 'pending_submission',
  PENDING_NEED_MORE_INFO = 'pending_need_more_info',
}

export enum WaMessagingLimitTier {
  TIER_50 = 'TIER_50',
  TIER_250 = 'TIER_250',
  TIER_1K = 'TIER_1K',
  TIER_10K = 'TIER_10K',
  TIER_100K = 'TIER_100K',
  TIER_UNLIMITED = 'TIER_UNLIMITED',
}

export enum CreditLineStatus {
  SHARED = 'SHARED',
  NOT_SHARED = 'NOT_SHARED',
  PRO_REQUIRED = 'PRO_REQUIRED',
  CANNOT_BE_SHARED = 'CANNOT_BE_SHARED',
}

export enum WaAccountNameDecision {
  APPROVED = 'APPROVED',
  DEFERRED = 'DEFERRED',
  PENDING = 'PENDING',
  REJECTED = 'REJECTED',
}

export enum WaBroadcastCategory {
  AUTHENTICATION = 'AUTHENTICATION',
  MARKETING = 'MARKETING',
  UTILITY = 'UTILITY',
}

export enum WaBroadcastFilterOperator {
  AND = 'AND',
  IS = 'IS',
  OR = 'OR',
}

export enum WaMetaPhoneStatus {
  PENDING = 'PENDING',
  DELETED = 'DELETED',
  MIGRATED = 'MIGRATED',
  BANNED = 'BANNED',
  RESTRICTED = 'RESTRICTED',
  RATE_LIMITED = 'RATE_LIMITED',
  FLAGGED = 'FLAGGED',
  CONNECTED = 'CONNECTED',
  DISCONNECTED = 'DISCONNECTED',
  UNKNOWN = 'UNKNOWN',
  UNVERIFIED = 'UNVERIFIED',
}

export enum WaBusinessIndustry {
  AUTO = 'Automotive',
  BEAUTY = 'Beauty, Spa and Salon',
  APPAREL = 'Clothing and Apparel',
  EDU = 'Education',
  ENTERTAIN = 'Entertainment',
  ONLINE_GAMBLING = 'Online Gambling & Gaming',
  PHYSICAL_GAMBLING = 'Non-Online Gambling & Gaming (E.g. Brick and mortar)',
  EVENT_PLAN = 'Event Planning and Service',
  FINANCE = 'Finance and Banking',
  GROCERY = 'Food and Grocery',
  ALCOHOL = 'Alcoholic Beverages',
  GOVT = 'Public Service',
  HOTEL = 'Hotel and Lodging',
  HEALTH = 'Medical and Health',
  OTC_DRUGS = 'Over-the-Counter Drugs',
  NONPROFIT = 'Non-profit',
  PROF_SERVICES = 'Professional Services',
  RETAIL = 'Shopping and Retail',
  TRAVEL = 'Travel and Transportation',
  RESTAURANT = 'Restaurant',
  OTHER = 'Other',
}

const messageTemplateCategory = z.object({
  id: z.nativeEnum(WaMessageTemplateCategoryId),
  title: z.string(),
})

const messageTemplateLanguage = z.object({
  label: z.string(),
  value: z.string(),
})

const messageTemplateLanguagesList = z.array(messageTemplateLanguage)

const messageTemplateButton = z.object({
  type: z.nativeEnum(WaMessageTemplateButtonType),
  text: z.string(),
  url: z.string().optional(),
  variable: z.string().optional(),
})

const baseMessageTemplateHeaderAttachment = z.object({
  url: z.string(),
  format: z.string().optional(),
  name: z.string().optional(),
  // in backend data base type is sometimes string and sometimes number
  // even if its not convienient, we have to handle both cases
  caid: z.number().optional().or(z.string().optional()),
})

const singleMessageTemplateHeader = z.discriminatedUnion('type', [
  z.object({
    type: z.literal(WaMessageTemplateHeaderType.TEXT),
    text: z.string(),
  }),
  baseMessageTemplateHeaderAttachment.extend({
    type: z.literal(WaMessageTemplateHeaderType.DOCUMENT),
  }),
  baseMessageTemplateHeaderAttachment.extend({
    type: z.literal(WaMessageTemplateHeaderType.IMAGE),
  }),
  baseMessageTemplateHeaderAttachment.extend({
    type: z.literal(WaMessageTemplateHeaderType.VIDEO),
  }),
])

const messageTemplateHeader = z.union([
  singleMessageTemplateHeader,
  z.array(singleMessageTemplateHeader),
])

export const messageTemplate = z.object({
  account_id: z.number(),
  template_id: z.number(),
  language_code: z.string(),
  name: z.string(),
  name_human: z.string(),
  status: z.nativeEnum(WaMessageTemplateStatus),
  category: z.nativeEnum(WaMessageTemplateCategoryId),
  header: messageTemplateHeader,
  body: z.string(),
  footer: z.string().optional(),
  buttons: z.array(messageTemplateButton),
  ts_created: z.number(),
  ts_updated: z.number(),
  ts_deleted: z.number().nullable(),
  is_default: z.boolean(),
  rejected_reason: z.nativeEnum(WaMessageTemplateRejectionReason).nullable(),
  pause_title: z.nativeEnum(WaMessageTemplatePauseTitle).optional().nullable(),
  pause_time: z.number().optional().nullable(),
  unpause_time: z.number().optional().nullable(),
})

const messageTemplateDraft = messageTemplate.partial().extend({
  example: z.record(z.string(), z.string()).optional(),
})

const messageTemplatePack = z.array(messageTemplate)

export enum WaCoexistenceImportContactsPeriod {
  ONE_DAY = 1,
  THREE_MONTHS = 90,
  SIX_MONTHS = 180,
}

export enum WaCoexistenceImportContactsState {
  PENDING = 'pending',
  COMPLETED = 'completed',
  FAILED = 'failed',
}

export const channelData = z.object({
  account_review_status: z.nativeEnum(WaAccountReviewStatus).nullish(),
  bought_phone: z
    .object({
      phone_number: z.string(),
      friendly_number: z.string().optional(), // without country code
      country_code: z.string(), // US, RU, etc
    })
    .nullable(),
  business_account_name: z.string().optional(),
  business_id: z.string().optional(),
  business_verification_status: z.nativeEnum(WaBusinessVerificationStatus).nullish(),
  country_code: z.string().optional(),
  default_reply: defaultReplySchema.nullable().optional(),
  has_credit_line: z.boolean().nullish(),
  has_issue_with_credit_line: z.boolean().nullish(),
  icebreakers_whatsapp_enabled: z.boolean().optional(),
  is_banned: z.boolean().optional(),
  is_cbp_enabled: z.boolean().optional(),
  is_channel_enabled: z.boolean().optional(),
  is_coexistence_used: z.boolean().optional(),
  is_exceeded_free_limits: z.boolean().optional(),
  coexistence_import_contacts_state: z
    .nativeEnum(WaCoexistenceImportContactsState)
    .nullable()
    .optional(),
  coexistence_import_contacts_period: z
    .nativeEnum(WaCoexistenceImportContactsPeriod)
    .nullable()
    .optional(),
  messaging_limit_tier: z.nativeEnum(WaMessagingLimitTier).nullish(),
  meta_phone_status: z.nativeEnum(WaMetaPhoneStatus).nullish(),
  new_business_account_name: z.string().nullish(),
  new_business_account_name_decision: z.nativeEnum(WaAccountNameDecision).nullish(),
  new_business_account_name_expires_at: z.number().nullish(),
  phone_number: z.string().optional(),
  photo: z.string().nullish(),
  settings: z
    .object({
      address: z.string().optional(),
      description: z.string().optional(),
      email: z.string().optional(),
      vertical: z.nativeEnum(WaBusinessIndustry).optional(),
      websites: z.array(z.string()).optional(),
    })
    .optional()
    .or(z.tuple([])),

  // registration flow "step" during registration
  state: z.nativeEnum(RegistrationFlowStep).optional(),
  // actual status of the channel
  status: z.nativeEnum(ChannelStatus).optional(),
  ts_channel_connected: z.string().nullable().optional(),
  wa_user_disconnect_channel: z.boolean().optional(),
  waba_id: z.number().nullish(),

  verification: z
    .object({
      expired_at: z.string(),
      url: z.string(),
    })
    .optional(),
})

const healthWidgetData = channelData.pick({
  business_verification_status: true,
  messaging_limit_tier: true,
  account_review_status: true,
  meta_phone_status: true,
})

const whatsAppBusinessProfileSchema = z.object({
  field: z.string(),
  value: z.union([z.string(), z.array(z.string())]),
})

export const whatsAppApiSchemas = {
  verifyCode: {
    path: z.undefined(),
    query: z.undefined(),
    request: z.object({
      code: z.string(),
    }),
    response: z.object({
      result: z.nativeEnum(VerifyPhoneStatus),
      error: z.string().optional(),
    }),
  },
  disconnectChannel: {
    path: z.undefined(),
    query: z.undefined(),
    request: z.undefined(),
    response: z.undefined(),
  },
  requestCode: {
    path: z.undefined(),
    query: z.undefined(),
    request: z.object({
      method: z.string(),
    }),
    response: z.object({
      error: z.string().optional(),
      result: z.nativeEnum(RequestPhoneStatus),
    }),
  },
  coexistenceImportContacts: {
    path: z.undefined(),
    query: z.undefined(),
    request: z.object({
      coexistence_import_contacts_period: z.nativeEnum(WaCoexistenceImportContactsPeriod),
    }),
    response: z.undefined(),
  },
  fetchMessageTemplateCategories: {
    path: z.undefined(),
    query: z.undefined(),
    request: z.undefined(),
    response: z.object({
      categories: z.array(messageTemplateCategory),
    }),
  },
  listMessageTemplateLanguages: {
    path: z.undefined(),
    query: z.undefined(),
    request: z.undefined(),
    response: z.object({
      values: messageTemplateLanguagesList,
    }),
  },
  getMessageTemplatePack: {
    path: z.undefined(),
    query: z.object({
      name: z.string(),
    }),
    request: z.undefined(),
    response: z.object({
      message_template: messageTemplatePack,
    }),
  },
  getBicLimits: {
    path: z.undefined(),
    query: z.undefined(),
    request: z.undefined(),
    response: z.object({
      conversation_count: z.number(),
      messaging_limit_tier: z.nativeEnum(WaMessagingLimitTier).nullable(),
    }),
  },
  estimateBroadcastCost: {
    path: z.undefined(),
    query: z.undefined(),
    request: z.object({
      filter: FilterSchema,
      category: z.nativeEnum(WaMessageTemplateCategoryId),
    }),
    response: z.object({
      cost: z.number().nonnegative(),
      currency: z.nativeEnum(AVAILABLE_CURRENCIES),
      currency_symbol: z.string(),
      us_numbers_count: z.number().optional(),
    }),
  },
  listMessageTemplatesPacks: {
    path: z.undefined(),
    query: z.undefined(),
    request: z.undefined(),
    response: z.object({
      message_templates: z.record(z.string(), messageTemplatePack).or(z.tuple([])),
    }),
  },
  createMessageTemplate: {
    path: z.undefined(),
    query: z.undefined(),
    request: messageTemplateDraft,
    response: z.object({
      message_template: messageTemplate,
    }),
  },
  editMessageTemplate: {
    path: z.undefined(),
    query: z.undefined(),
    request: z.object({
      id: messageTemplate.shape.template_id,
      header: messageTemplate.shape.header.optional(),
      body: messageTemplate.shape.body.optional(),
      footer: messageTemplate.shape.footer.optional(),
      example: messageTemplateDraft.shape.example.optional(),
      buttons: messageTemplate.shape.buttons.optional(),
      category: messageTemplate.shape.category.optional(),
    }),
    response: z.object({
      message_template: messageTemplate,
    }),
  },
  deleteMessageTemplates: {
    path: z.undefined(),
    query: z.undefined(),
    request: z.object({
      names: z.array(z.string()),
    }),
    response: z.object({
      templates_deleted: z.array(z.string()),
    }),
  },
  updateDefaultMessageTemplate: {
    path: z.undefined(),
    query: z.undefined(),
    request: z.object({
      name: z.string(),
      language: z.string(),
    }),
    response: z.undefined(),
  },
  updateMessageTemplateVariables: {
    path: z.undefined(),
    query: z.undefined(),
    request: z.object({
      id: z.number(),
      variables: z.record(z.string(), z.record(z.string(), z.string())),
    }),
    response: z.object({
      message_template: messageTemplate,
    }),
  },
  getChannelData: {
    path: z.undefined(),
    query: z.undefined(),
    request: z.undefined(),
    response: z.object({
      whatsapp_channel: channelData,
    }),
  },
  refreshHealthWidgetData: {
    path: z.undefined(),
    query: z.undefined(),
    request: z.undefined(),
    response: healthWidgetData,
  },
  needsPermissionRefresh: {
    path: z.undefined(),
    query: z.undefined(),
    request: z.undefined(),
    response: z.object({
      is_need_refresh: z.boolean(),
    }),
  },
  clearNewBusinessAccountNameData: {
    path: z.undefined(),
    query: z.undefined(),
    request: z.undefined(),
    response: z.undefined(),
  },
  confirmNewBusinessAccountName: {
    path: z.undefined(),
    query: z.undefined(),
    request: z.undefined(),
    response: z.undefined(),
  },
  setCatalogVisibility: {
    path: z.undefined(),
    query: z.undefined(),
    request: z.object({
      value: z.boolean(),
    }),
    response: z.undefined(),
  },
  getWhatsAppCommerceSettings: {
    path: z.undefined(),
    query: z.undefined(),
    request: z.undefined(),
    response: z.object({
      is_cart_enabled: z.boolean(),
      is_catalog_visible: z.boolean(),
    }),
  },
  fixBrokenCarts: {
    path: z.undefined(),
    query: z.undefined(),
    request: z.undefined(),
    response: z.undefined(),
  },
  getAdAccountsList: {
    path: z.undefined(),
    query: z.undefined(),
    request: z.undefined(),
    response: z.object({
      list: z.array(
        z.object({
          id: z.string(),
          name: z.string(),
        }),
      ),
    }),
  },
  getAdsList: {
    path: z.undefined(),
    query: z.object({ ad_account_id: z.string() }),
    request: z.undefined(),
    response: z.object({
      list: z.array(
        z.object({
          id: z.string(),
          name: z.string(),
          flow_ns: z.string().nullish(),
        }),
      ),
    }),
  },
  getStats: {
    path: z.undefined(),
    query: z.undefined(),
    request: z.undefined(),
    response: z.object({
      stats: z.object({
        total_count: z.number(),
        total_cost: z.number(),
        free_period_end: z.string().nullable(),
      }),
    }),
  },
  setWhatsAppBusinessProfile: {
    path: z.undefined(),
    query: z.undefined(),
    request: whatsAppBusinessProfileSchema,
    response: z.object({
      whatsapp_channel: channelData,
    }),
  },
  setWhatsAppProfilePhoto: {
    path: z.undefined(),
    query: z.undefined(),
    request: z.instanceof(FormData),
    response: z.object({
      whatsapp_channel: channelData,
    }),
  },
}

export type WaGetAdAccountsListResponse = z.infer<
  typeof whatsAppApiSchemas.getAdAccountsList.response
>
export type WaGetAdsListResponse = z.infer<typeof whatsAppApiSchemas.getAdsList.response>

export type WaDeleteMessageTemplatesRequest = z.infer<
  typeof whatsAppApiSchemas.deleteMessageTemplates.request
>
export type WaUpdateDefaultMessageTemplateRequest = z.infer<
  typeof whatsAppApiSchemas.updateDefaultMessageTemplate.request
>
export type WaEditMessageTemplateRequest = DeepPartial<
  z.infer<typeof whatsAppApiSchemas.editMessageTemplate.request>
>
export type WaEditMessageTemplateRequestFull = z.infer<
  typeof whatsAppApiSchemas.editMessageTemplate.request
>
export type WaUpdateMessageTemplateVariablesRequest = z.infer<
  typeof whatsAppApiSchemas.updateMessageTemplateVariables.request
>

export type WaMessageTemplateSingleHeader = z.infer<typeof singleMessageTemplateHeader>
export type WaMessageTemplate = z.infer<typeof messageTemplate>
export type WaMessageTemplateDraft = DeepPartial<z.infer<typeof messageTemplateDraft>>
export type WaMessageTemplateButton = z.infer<typeof messageTemplateButton>
export type WaMessageTemplatePack = z.infer<typeof messageTemplatePack>
export type WaMessageTemplatesList = Record<string, WaMessageTemplatePack>
export type WaMessageTemplateCategory = z.infer<typeof messageTemplateCategory>
export type WaMessageTemplateCategories = WaMessageTemplateCategory[]
export type WaMessageTemplateLanguage = z.infer<typeof messageTemplateLanguage>
export type WaMessageTemplateHeader = z.infer<typeof messageTemplateHeader>
export type WaMessageTemplateHeaderTypeAttachment =
  | Extract<
      WaMessageTemplateHeader,
      {
        type: WaMessageTemplateHeaderType.VIDEO
      }
    >
  | Extract<
      WaMessageTemplateHeader,
      {
        type: WaMessageTemplateHeaderType.DOCUMENT
      }
    >
  | Extract<
      WaMessageTemplateHeader,
      {
        type: WaMessageTemplateHeaderType.IMAGE
      }
    >

export type WhatsAppChannelState = WhatsAppUnconnectedChannelState | WhatsAppConnectedState
export type WhatsAppConnectedState = Partial<WhatsAppChannelData> & WhatsAppChannelStoreSpecifics

export type WhatsAppUnconnectedChannelState = {
  state: ChannelStatus.NONE
  bought_phone: null
}

// data that we get from the backend during parsing __INIT__ or from the getChannelData request
export type WhatsAppChannelData = z.infer<typeof channelData>

// frontend fields that we don't get from the backend and use for internal purposes
export interface WhatsAppChannelStoreSpecifics {
  loading?: boolean
  error?: boolean
  isConfirmNewNameError?: boolean
}

export type WhatsAppHealthWidgetData = z.infer<typeof healthWidgetData>

export type WhatsAppBroadcastingBicLimits = z.infer<typeof whatsAppApiSchemas.getBicLimits.response>
export type WhatsAppBroadcastingCostEstimation = z.infer<
  typeof whatsAppApiSchemas.estimateBroadcastCost.response
>
export type WhatsAppCommerceSettings = z.infer<
  typeof whatsAppApiSchemas.getWhatsAppCommerceSettings.response
>

export type WhatsAppCatalogNeedsPermissionRefresh = z.infer<
  typeof whatsAppApiSchemas.needsPermissionRefresh.response
>

export type WhatsAppFixBrokenCarts = z.infer<typeof whatsAppApiSchemas.fixBrokenCarts.response>

export type WaBusinessProfileRequest = z.infer<typeof whatsAppBusinessProfileSchema>
export type WaBusinessProfileResponse = z.infer<
  typeof whatsAppApiSchemas.setWhatsAppBusinessProfile.response
>
export type WaProfilePhotoResponse = z.infer<
  typeof whatsAppApiSchemas.setWhatsAppProfilePhoto.response
>

import { SimpleTrialPlaceType } from 'apps/cms/lib/constants'

import { DEFAULT_HANDLERS } from './defaultHandlers'
import { TRIAL_INSTAGRAM_HANDLERS, TRIAL_EASY_BUILDER_HANDLERS } from './trialInstagramHandlers'
import { HandlersParams } from './types'

export const ErrorGoProReasons = {
  WIDGETS_LIMIT_REACHED: 'widgets_limit_reached',
  // Special type of triggers ex: Instagram message
  KEYWORDS_LIMIT_REACHED: 'keywords_limit_reached',
  STORY_REPLY_TRIGGER_LIMIT_REACHED: 'story_reply_trigger_limit_reached',
} as const

export function handleGoProExperiment(
  place: SimpleTrialPlaceType,
  params: HandlersParams = {},
  fromEasyBuilder?: boolean,
) {
  const trialIgHandler =
    fromEasyBuilder && place in TRIAL_EASY_BUILDER_HANDLERS
      ? TRIAL_EASY_BUILDER_HANDLERS[place]
      : TRIAL_INSTAGRAM_HANDLERS[place]

  if (trialIgHandler) {
    trialIgHandler({ ...params, fromEasyBuilder })
  } else {
    DEFAULT_HANDLERS[place](params)
  }
}

import { toAiIntent } from 'apps/aiAssistant/model/toAiIntent'
import { toRefUrlWidget } from 'apps/aiAssistant/model/toRefUrlWidget'
import { WidgetTypes } from 'apps/growthTools/models/Widget/constants'
import { ChannelType } from 'common/core/constants/ChannelType'
import { TriggerType } from 'common/flow/constants/TriggerType'
import { IFlowTrigger } from 'common/flow/flowInterfaces'
import { ProtoTrigger } from 'shared/api/requests/aiAssistant/schemas'
import errorTrackingService from 'utils/services/errorTrackingService'

import { toFeedCommentWidget } from './toFeedCommentWidget'
import { toInstagramLiveCommentReplyWidget } from './toInstagramLiveCommentReplyWidget'
import { toInstagramStoryReplyWidget } from './toInstagramStoryReplyWidget'
import { toKeyword } from './toKeyword'

const ConvertersMapping: Array<{
  canConvert(trigger: ProtoTrigger): boolean
  convert(trigger: ProtoTrigger): IFlowTrigger
}> = [
  {
    canConvert: (trigger: ProtoTrigger) => {
      return trigger.trigger_type === TriggerType.KEYWORD
    },
    convert: toKeyword,
  },
  {
    canConvert: (trigger: ProtoTrigger) => {
      return trigger.trigger_type === TriggerType.AI_INTENT
    },
    convert: toAiIntent,
  },
  {
    canConvert: (trigger: ProtoTrigger) => {
      return (
        trigger.trigger_type === TriggerType.WIDGET &&
        trigger.widget_type === WidgetTypes.FEED_COMMENT_TRIGGER
      )
    },
    convert: toFeedCommentWidget,
  },
  {
    canConvert: (trigger: ProtoTrigger) => {
      return (
        trigger.channel === ChannelType.INSTAGRAM &&
        trigger.trigger_type === TriggerType.WIDGET &&
        trigger.widget_type === WidgetTypes.INSTAGRAM_STORY_REPLY
      )
    },
    convert: toInstagramStoryReplyWidget,
  },
  {
    canConvert: (trigger: ProtoTrigger) => {
      return (
        trigger.channel === ChannelType.INSTAGRAM &&
        trigger.trigger_type === TriggerType.WIDGET &&
        trigger.widget_type === WidgetTypes.INSTAGRAM_LIVE_COMMENT_REPLY
      )
    },
    convert: toInstagramLiveCommentReplyWidget,
  },
  {
    canConvert: (trigger: ProtoTrigger) => {
      return (
        [ChannelType.FB, ChannelType.WHATSAPP, ChannelType.TELEGRAM] &&
        trigger.trigger_type === TriggerType.WIDGET &&
        trigger.widget_type === WidgetTypes.MESSENGER_REF_URL
      )
    },
    convert: toRefUrlWidget,
  },
]

export const parseBackendProtoTriggers = (protoTriggers: ProtoTrigger[]): IFlowTrigger[] => {
  const triggers: IFlowTrigger[] = []
  const invalidTriggers: ProtoTrigger[] = []

  protoTriggers.forEach((protoTrigger) => {
    const converter = ConvertersMapping.find((converter) => converter.canConvert(protoTrigger))

    if (converter) {
      triggers.push(converter.convert(protoTrigger))
    } else {
      invalidTriggers.push(protoTrigger)
    }
  })

  if (invalidTriggers.length > 0) {
    errorTrackingService.logError('AiFlowGenerator: Unsupported triggers', {
      scope: 'ai',
      section: 'ai_flow_generator',
      extra: {
        triggers: invalidTriggers,
      },
    })
  }

  return triggers
}

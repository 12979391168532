// eslint-disable-next-line no-restricted-imports
import { AsyncThunk, AsyncThunkAction, createAsyncThunk } from '@reduxjs/toolkit'

import { ApiThunkConfig } from 'common/core/interfaces/actions'

import { Body, RequestPayload } from '../types'

type ApiThunk<T, A> = {
  (arg: A): ApiThunkAction<T, A>
} & AsyncThunk<T, A, ApiThunkConfig> & { url: string }

export type ApiThunkAction<T, A> = AsyncThunkAction<T, A, ApiThunkConfig> & {
  typePrefix: string
  arg: A
}

export const makeGetActionCreator = <T extends Body>(
  actionType: string,
  url: string,
): ApiThunk<T, RequestPayload> => {
  return makeActionCreator(actionType, url, 'get')
}

export const makePostActionCreator = <T extends Body>(
  actionType: string,
  url: string,
): ApiThunk<T, RequestPayload> => {
  return makeActionCreator(actionType, url, 'post')
}

const makeActionCreator = <T extends Body>(
  actionType: string,
  url: string,
  method: 'get' | 'post',
): ApiThunk<T, RequestPayload> => {
  const asyncThunk = createAsyncThunk<T, RequestPayload, ApiThunkConfig>(
    actionType,
    async (payload, { extra }) => {
      try {
        return await extra.api[method]<T>(url, payload)
      } catch (err) {
        if (err instanceof Error) {
          if (payload.onError) {
            payload.onError(err)
          } else {
            extra.alert(err.message, 'danger')
          }
        }
        throw err
      }
    },
  )

  return createApiThunk<T, RequestPayload>(actionType, url, asyncThunk)
}

const createApiThunk = <T, A>(
  actionType: string,
  url: string,
  asyncThunk: AsyncThunk<T, A, ApiThunkConfig>,
): ApiThunk<T, A> => {
  return Object.assign(
    (arg: A): ApiThunkAction<T, A> => {
      return Object.assign(asyncThunk(arg), { typePrefix: actionType, arg })
    },
    { url },
    asyncThunk,
  )
}

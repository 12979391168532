import * as atypes from '../constants/supportReduxActionTypes'
import { warningsAdapter } from '../models/adapter'

const initialState = {
  warnings: [],
}

export default function warningsReducer(state = initialState, action) {
  switch (action.type) {
    case atypes.SUPPORT_WARNINGS_FETCH_RESPONSE: {
      return {
        ...state,
        warnings: [...warningsAdapter(action.data)],
      }
    }
  }

  return state
}

import assert from 'assert'

import camelCase from 'lodash/camelCase'
import upperFirst from 'lodash/upperFirst'
import values from 'lodash/values'

import AttachmentBlockConfig from 'common/builder/blocks/attachment/AttachmentBlockConfig'
import { IBlock, IBlockConfig } from 'common/builder/blocks/blockInterfaces'
import CardBlockConfig from 'common/builder/blocks/card/CardBlockConfig'
import CardsBlockConfig from 'common/builder/blocks/cards/CardsBlockConfig'
import CaseBlockConfig from 'common/builder/blocks/case/CaseBlockConfig'
import DefaultBlockConfig from 'common/builder/blocks/default/DefaultBlockConfig'
import DelayBlockConfig from 'common/builder/blocks/delay/DelayBlockConfig'
import DynamicBlockConfig from 'common/builder/blocks/dynamic/DynamicBlockConfig'
import EmailButtonBlockConfig from 'common/builder/blocks/emailButton/EmailButtonBlockConfig'
import EmailDividerBlockConfig from 'common/builder/blocks/emailDivider/EmailDividerBlockConfig'
import EmailImageBlockConfig from 'common/builder/blocks/emailImage/EmailImageBlockConfig'
import EmailRootBlockConfig from 'common/builder/blocks/emailRoot/EmailRootBlockConfig'
import EmailTextBlockConfig from 'common/builder/blocks/emailText/EmailTextBlockConfig'
import EmailTriggerOpenBlockConfig from 'common/builder/blocks/emailTriggerOpen/EmailTriggerOpenBlockConfig'
import ListBlockConfig from 'common/builder/blocks/list/ListBlockConfig'
import ListItemBlockConfig from 'common/builder/blocks/listItem/ListItemBlockConfig'
import OtnRequestBlockConfig from 'common/builder/blocks/otnRequest/OtnRequestBlockConfig'
import QuestionBlockConfig from 'common/builder/blocks/question/QuestionBlockConfig'
import QuestionTimeoutBlockConfig from 'common/builder/blocks/questionTimeout/QuestionTimeoutBlockConfig'
import QuickReplyBlockConfig from 'common/builder/blocks/quickReply/QuickReplyBlockConfig'
import TelegramKeyboardBlockConfig from 'common/builder/blocks/telegramKeyboard/TelegramKeyboardBlockConfig'
import TemplateBlockConfig from 'common/builder/blocks/template/TemplateBlockConfig'
import TextBlockConfig from 'common/builder/blocks/text/TextBlockConfig'
import VariantBlockConfig from 'common/builder/blocks/variant/VariantBlockConfig'
import WaCatalogMessageBlockConfig from 'common/builder/blocks/waCatalogMessage/waCatalogMessageBlockConfig'
import WaListMessageBlockConfig from 'common/builder/blocks/waListMessage/waListMessageBlockConfig'
import { BlockType } from 'common/builder/constants/BlockType'

const configMap: Record<string, IBlockConfig> = {
  AttachmentBlockConfig,
  CardBlockConfig,
  CardsBlockConfig,
  CaseBlockConfig,
  DefaultBlockConfig,
  DelayBlockConfig,
  DynamicBlockConfig,
  EmailButtonBlockConfig,
  EmailDividerBlockConfig,
  EmailImageBlockConfig,
  EmailRootBlockConfig,
  EmailTextBlockConfig,
  EmailTriggerOpenBlockConfig,
  ListBlockConfig,
  ListItemBlockConfig,
  WaListMessageBlockConfig,
  WaCatalogMessageBlockConfig,
  OtnRequestBlockConfig,
  QuestionBlockConfig,
  QuestionTimeoutBlockConfig,
  QuickReplyBlockConfig,
  TelegramKeyboardBlockConfig,
  TemplateBlockConfig,
  TextBlockConfig,
  VariantBlockConfig,
}

const get = (block: IBlock) => {
  assert(block, 'blockRegistry.get: block is required')
  return getByType(block.type)
}

const getByType = (blockType: BlockType): IBlockConfig => {
  assert(blockType, 'blockRegistry.getByType: blockType is required')
  assert(values(BlockType).includes(blockType), `blockInterfaces: unknown blockType ${blockType}`)

  const configName = `${upperFirst(camelCase(blockType))}BlockConfig`
  return configMap[configName]
}

export default {
  get,
  getByType,
}

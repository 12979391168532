import { api } from 'shared/api'
import { widgetsSchemas } from 'shared/api/requests/widgets/schemas'

export const widgetsApi = {
  setDraftStatus: api.account.createPost({
    url: '/growth-tools/setDraftStatus',
    schemas: widgetsSchemas.setDraftStatus,
  }),
  widgetCheck: api.account.createPost({
    url: '/growth-tools/check',
    schemas: widgetsSchemas.widgetCheck,
  }),
  setFlow: api.account.createPost({
    url: '/growth-tools/setFlow',
    schemas: widgetsSchemas.setFlow,
  }),
}

import { z } from 'zod'

import { WalletTransactionType, WalletType } from 'common/billing/wallet/interfaces/walletType'
import { PriceSchema } from 'shared/api/requests/billing/schemas'

const FetchWalletSchema = z.object({
  balance: z.number().optional().nullable(),
  credit_balance: z.number().optional().nullable(),
  wallet_type: z.nativeEnum(WalletType),
  auto_refill: z.object({
    is_enabled: z.boolean(),
    refill_amount: z.number().optional().nullable(),
    trigger_amount: z.number().optional().nullable(),
    daily_limit: z.number().optional().nullable(),
  }),
  options: z.object({
    trigger_autorefill_amount_options: z.array(PriceSchema),
    daily_limit_options: z.array(PriceSchema),
    amount_options: z.array(PriceSchema),
  }),
})

const ExportedAutoRefillSchema = z.object({
  enable: z.boolean(),
  amount: z.number(),
  trigger_autorefill_amount: z.number(),
  daily_limit: z.number(),
})

const RefillSchema = z.object({
  amount: z.number().gte(0),
})

const UsageSummarySchema = z.object({
  type: z.nativeEnum(WalletTransactionType),
  count: z.number(),
  amount: z.number(),
})

const DateStringSchema = z.string().refine((dateStr) => !Number.isNaN(new Date(dateStr)), {
  message: 'not a valid date string',
})

const WalletTransactionSchema = z.object({
  wallet_operation_id: z.number(),
  type: z.nativeEnum(WalletTransactionType),
  /**
   * 'Auto' | 'Live Chat' | 'Flow “{FlowName}”'
   */
  source: z.string().nullable(),
  cost: z.number().gte(0),
  subscriber: z.string().nullable(),
  created_at: z.string(),
})

export const WalletSchemas = {
  fetchWallet: {
    path: z.undefined(),
    query: z.undefined(),
    request: z.undefined(),
    response: FetchWalletSchema,
  },
  setAutoRefill: {
    path: z.undefined(),
    query: z.undefined(),
    request: ExportedAutoRefillSchema,
    response: FetchWalletSchema.partial().optional(),
  },
  refillWallet: {
    path: z.undefined(),
    query: z.undefined(),
    request: RefillSchema,
    response: z
      .object({
        balance: z.number(),
      })
      .optional(),
  },
  fetchUsageSummary: {
    path: z.undefined(),
    query: z.object({
      from: DateStringSchema,
      to: DateStringSchema,
    }),
    request: z.undefined(),
    response: z.object({
      summary: z.array(UsageSummarySchema),
    }),
  },
  fetchTransactions: {
    path: z.undefined(),
    query: z.object({
      from: z.string(),
      to: z.string(),
      lastOperationId: z.number().nullable(),
      limit: z.number().gte(1),
    }),
    request: z.undefined(),
    response: z.object({
      transactions: z.array(WalletTransactionSchema),
      has_more: z.boolean(),
    }),
  },
}

export type FetchWalletResponse = z.infer<typeof FetchWalletSchema>
export type ExportedAutoRefill = z.infer<typeof ExportedAutoRefillSchema>
export type WalletUsageSummary = z.infer<typeof UsageSummarySchema>
export type WalletTransaction = z.infer<typeof WalletTransactionSchema>

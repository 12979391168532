import { createSelector, ParametricSelector } from '@reduxjs/toolkit'
import get from 'lodash/get'
import isEqual from 'lodash/isEqual'
import { createStructuredSelector, Selector } from 'reselect'
import { l } from '@manychat/manyui'
import { PlanSlug } from '@manychat/paywall'

import { getTotal as getTotalContactsCount } from 'common/audience/selectors/audienceSelectors'
import { BillingActions } from 'common/billing/constants/BillingActions'
import { SubscriptionAction } from 'common/billing/constants/SubscriptionAction'
import { getTierLimitState } from 'common/billing/helpers/tierLimit'
import { UpgradeSource, UpgradeSourceType } from 'common/billing/interfaces'
import { AddOnSlug, AddOnStatus } from 'common/billing/interfaces/addOnTypes'
import { TierData } from 'common/billing/interfaces/tiers'
import { getIsNotCreditWalletActive } from 'common/billing/wallet/walletSelectors'
import { formatUpgradeSource } from 'common/builder/utils/upgradeEventUtils'
import { ChannelStatus } from 'common/core/interfaces/channelStatuses'
import { ProductType, ProProduct } from 'common/core/interfaces/products'
import { ISidebarAccount } from 'common/core/interfaces/sideBarAccount'
import * as AccountModel from 'common/core/models/Account/helpers'
import {
  getAccountFlags,
  getAsyncBillingActions,
  getBillingAddressFlags,
  getCurrentAccount,
  getCurrentAccountUser,
  getInAppCardAttachmentFlags,
  getIsPro,
  IExtendedCurrentAccount,
  isAccountBlocked,
  isTrialAvailable,
} from 'common/core/selectors/appSelectors'
import {
  getWhatsAppChannel,
  isWhatsAppChannelConnected,
} from 'common/core/selectors/channelSelectors'
import { AddOn, PriceData, Products } from 'shared/api/requests/billing/schemas'
import { EmailBillingStats, SmsBillingStats } from 'shared/api/requests/settings/stats/schemas'
import { DefaultModalState } from 'shared/lib/factory/redux/createModalSlice'
import { AccountFlag } from 'utils/services/featureFlagsService/constants'

import {
  Billing,
  BillingState,
  CartProductAddOn,
  CartProductPlan,
  CartProductsData,
  Discount,
  DiscountType,
  DiscountUnit,
  ProInfo,
  ProInfoBase,
  StripeCheckout,
  UpgradeRequest,
} from '../billingInterfaces'
import { PRO_MODAL_PRODUCTS, PRODUCT_NAME_MAP, UpgradeModalVersion } from '../constants'
import { BillingVersion, ManychatLegalEntity } from '../constants/AccountBillingInfo'
import { BillingCurrency } from '../constants/BillingCurrency'
import {
  BillingExperiment,
  experimentFlagMap,
  ExperimentGroup,
} from '../constants/BillingExperiment'
import { applyCouponForProduct, applyDiscountToCart, extractCartSlugs } from '../helpers/cart'
import { getTierByContacts } from '../helpers/tiers'

export const getRootBillingState = (state: RootState) => state.billing

export const getBillingState = (state: RootState): BillingState => state.billing.state

export const getBillingProInfo = (state: RootState): ProInfo => state.billing.pro

export const getUpgradeRequest = (state: RootState): UpgradeRequest => state.billing.upgradeRequest

export const getCancelSubscriptionModalState = (state: RootState) =>
  state.billing.cancelSubscriptionModal.isOpen

export const getDisableProductModal = (state: RootState): Billing['disableProductModal'] =>
  state.billing.disableProductModal

export const getSmsStats = createSelector(
  [getRootBillingState],
  (billing): SmsBillingStats => billing.smsStats,
)

export const getEmailStats = createSelector(
  [getRootBillingState],
  (billing): EmailBillingStats => billing.emailStats,
)

export const getProSettings = createSelector([getRootBillingState], (billing) => billing.pro)

export const getStripeCheckoutData = (state: RootState): StripeCheckout =>
  state.billing.stripeCheckout

export const getPaymentCardRenewalModalState = (state: RootState) =>
  state.billing.paymentCardRenewalModal

export const getIsCouponAllowed = createSelector(
  getBillingProInfo,
  ({ billing_allow_coupon: billingAllowCoupon, coupon_info: couponInfo }) => {
    return billingAllowCoupon && !couponInfo
  },
)

export const getProModalProducts = (state: RootState): ProProduct[] => {
  const products = state.billing.upgradeRequest.products || []

  return products.filter((product) =>
    PRO_MODAL_PRODUCTS.includes(product as ProProduct),
  ) as ProProduct[]
}

export const getProProducts = createSelector(getBillingProInfo, ({ products }): Products | null => {
  if (!products) {
    return null
  }

  return products
})

export const getAutomationProductPrice = createSelector(getProProducts, (products) => {
  if (!products) {
    return null
  }
  return products.automation.price
})

export const getActiveProProducts = createSelector(getProProducts, (products): ProProduct[] => {
  if (!products) {
    return []
  }
  return (Object.keys(products) as ProProduct[]).filter((name) => products[name].active)
})

export type SubscriptionInfo =
  | (ProInfoBase & {
      isPro: boolean
      isFree: boolean
      isTrial: boolean
      isUkraineReliefProgram: boolean
      isExpired: boolean
      isPendingCancellation: boolean
    })
  | Record<string, never>

export const getSubscriptionInfo = createSelector(
  [getBillingProInfo, getAccountFlags],
  (pro, flags): SubscriptionInfo => {
    const { status, billing_action, plan, billing_version: billingVersion } = pro

    if (!status) {
      return {}
    }

    const isPro = AccountModel.isProOrTrialStatus(status)
    const isTrialStatus = AccountModel.isTrialStatus(status)

    const isUkraineReliefProgram =
      Boolean(flags?.[AccountFlag.UKRAINE_2022_SUPPORT]) &&
      billingVersion === BillingVersion.FLEX &&
      plan === 'total_monthly'

    return {
      ...(pro as ProInfoBase),
      isPro,
      isFree: AccountModel.isFreeStatus(status),
      isTrial: isTrialStatus,
      isUkraineReliefProgram,
      isExpired: AccountModel.isExpiredStatus(status),
      isPendingCancellation: isPro && billing_action === BillingActions.BACK_TO_PRO,
    }
  },
)

export const getPeriodEnds = createSelector(
  [getSubscriptionInfo],
  (subscriptionInfo) => subscriptionInfo.period_ends,
)

export const getHasBillingAccess = createSelector(
  [getCurrentAccountUser],
  (user) => user?.has_billing_access || false,
)

export const getPageAndHasBillingAccess = (state: RootState) => {
  const page: IExtendedCurrentAccount | ISidebarAccount | null = getCurrentAccount(state)
  const hasBillingAccess = get(state, 'app.currentAccountUser.has_billing_access', false)

  return { page, hasBillingAccess }
}

export const getSubscriptionAction = createSelector(
  [getCurrentAccount, getAsyncBillingActions, isTrialAvailable],
  (currentAccount, asyncNextActions, trialAvailable): SubscriptionAction => {
    if (asyncNextActions?.action_type || asyncNextActions?.next_action_redirect) {
      return SubscriptionAction.CONTINUE_CHECKOUT
    }

    if (trialAvailable) {
      return SubscriptionAction.ACTIVATE_TRIAL
    }

    if (AccountModel.isExpired(currentAccount)) {
      return SubscriptionAction.RENEW
    }

    if (currentAccount.products?.has_automation && currentAccount.products?.has_inbox) {
      return SubscriptionAction.NONE
    }

    if (AccountModel.isProOrTrial(currentAccount)) {
      return SubscriptionAction.CHANGE_PLAN
    }

    return SubscriptionAction.UPGRADE
  },
)

export const getTierLimitModalState = createSelector(
  (state: RootState): DefaultModalState => state.billing.tierLimitModal,
  (tierLimitModalState) => tierLimitModalState,
)

export const getBlockedProModalState = (state: RootState): boolean =>
  state.billing.blockedProModal.isOpen

/**
 * We should not interrupt user with blocked state (modal)
 * in case user is in checkout process
 */
export const getIsOpenBlockedProModal = createSelector(
  [getBlockedProModalState, getSubscriptionAction],
  (isOpen, subscriptionAction) =>
    isOpen && subscriptionAction !== SubscriptionAction.CONTINUE_CHECKOUT,
)

export const getIsShownBlockedBanner = createSelector(
  [isAccountBlocked, getSubscriptionAction],
  (isBlocked, subscriptionAction) =>
    isBlocked && subscriptionAction !== SubscriptionAction.CONTINUE_CHECKOUT,
)

export const getIsSubscriptionActionEqual = createSelector(
  [
    getSubscriptionAction,
    (state: RootState, requestedStatus: SubscriptionAction) => requestedStatus,
  ],
  (currentStatus, requestedStatus) => currentStatus === requestedStatus,
)

const defaultCaption = l.translate('Upgrade to Pro')
const captionMap: Record<SubscriptionAction, string> = {
  [SubscriptionAction.CONTINUE_CHECKOUT]: l.translate('Verify Payment'),
  [SubscriptionAction.RENEW]: l.translate('Renew Subscription'),
  [SubscriptionAction.ACTIVATE_TRIAL]: l.translate('Activate Pro Trial'),
  [SubscriptionAction.UPGRADE]: defaultCaption,
  [SubscriptionAction.CHANGE_PLAN]: defaultCaption,
  [SubscriptionAction.NONE]: '',
}

export const getUpgradeCaption: ParametricSelector<RootState, string | void, string> =
  createSelector([getSubscriptionAction], (subscriptionAction): string => {
    return captionMap[subscriptionAction]
  })

export const getIsBillingNextActionModalOpen = createSelector(
  [getRootBillingState],
  (billing): boolean => billing.nextActionModal.isOpen,
)

export const getArePaymentsEnabled = createSelector([getCurrentAccount], (account) =>
  Boolean(account.payments_enabled),
)

export const getTiersState = createSelector([getRootBillingState], (billing) => billing.tiers)

export const getTiersData = createSelector([getTiersState], (tiersState) => tiersState.tiers)

export const getCurrentTier = createSelector(
  [getTiersData, getTotalContactsCount],
  (tiers, contacts) => getTierByContacts(tiers, contacts),
)

export const getNextTier = createSelector(
  [getTiersData, getCurrentTier],
  (tiers, currentTier): TierData | null => {
    const currentIndex = tiers.findIndex((tier) => tier === currentTier)
    if (currentIndex === -1 || currentIndex === tiers.length - 1) {
      return null
    }
    return tiers[currentIndex + 1]
  },
)

export const getIsInboxEnabled = createSelector([getAccountFlags], (flags) =>
  Boolean(flags?.[AccountFlag.ENABLE_INBOX]),
)

export const getAvailableProProducts = createSelector([getIsInboxEnabled], (inboxEnabled) =>
  PRO_MODAL_PRODUCTS.filter((product) => product !== ProductType.INBOX || inboxEnabled),
)

export const getAddOnsData = createSelector(
  [getBillingProInfo],
  ({ addons_data }): AddOn[] => addons_data || [],
)

export const getCancelAddOnModalRoot = createSelector(
  [getRootBillingState],
  (billing) => billing.cancelAddOnModal,
)

export const getTotalMonthlyPrice = createSelector(
  [getProProducts, getActiveProProducts, getAddOnsData],
  (products, activeProducts, addOns) => {
    if (!products) {
      return 0
    }

    const totalForAddOns = addOns
      .filter((addOn) => addOn.status === AddOnStatus.ACTIVE)
      .reduce((total, addOn) => total + addOn.price, 0)
    const currentTotal =
      activeProducts.reduce((total, name) => total + products[name].price, 0) + totalForAddOns

    return currentTotal
  },
)

export const getCancelAddOnModalState = createSelector(
  [getCancelAddOnModalRoot, getAddOnsData],
  (cancelAddOnModal, addOns) => {
    const addOn = addOns.find((addOn) => addOn.slug === cancelAddOnModal.addOnSlug)

    if (cancelAddOnModal.isOpen && addOn) {
      return {
        isOpen: true,
        loading: cancelAddOnModal.loading,
        addOn,
        isInterviewModalOpen: cancelAddOnModal.isInterviewModalOpen,
      } as const
    }

    return {
      isOpen: false,
      loading: cancelAddOnModal.loading,
      addOn: undefined,
      isInterviewModalOpen: cancelAddOnModal.isInterviewModalOpen,
    } as const
  },
)

export const getPlanPrice = createSelector(
  [(state: RootState) => getProProducts(state), (_: RootState, product: ProProduct) => product],
  (products, product) => {
    if (!products) {
      return 0
    }

    return products[product].price
  },
)

export const getAddOnCartData = createSelector([getAddOnsData], (addOns): CartProductAddOn[] =>
  addOns
    .filter((addOn) => addOn.status !== AddOnStatus.ACTIVE)
    .map<CartProductAddOn>((addOn) => ({
      type: 'addOn',
      name: addOn.name,
      price: addOn.price,
      fullPrice: addOn?.full_price,
      slug: addOn.slug,
      requiredPlan: ProductType.AUTOMATION,
      order: 5,
    })),
)

export const getCartProductsUndiscounted = createSelector(
  [getUpgradeRequest, getProProducts, getAddOnCartData, getIsInboxEnabled],
  (proModalState, proProducts, addOns, inboxEnabled): CartProductsData => {
    if (!proProducts) {
      return { plans: [], addOns: [] }
    }

    const automation: CartProductPlan = {
      type: 'plan',
      slug: ProductType.AUTOMATION,
      name: PRODUCT_NAME_MAP[ProductType.AUTOMATION],
      price: proProducts.automation.price,
      dynamicPrice: true,
      order: 0,
    }
    const inbox: CartProductPlan = {
      type: 'plan',
      slug: ProductType.INBOX,
      name: PRODUCT_NAME_MAP[ProductType.INBOX],
      price: proProducts.inbox.price,
      dynamicPrice: true,
      order: 10,
    }
    const { strictRequest } = proModalState

    // Inbox-only use case
    const requestedPlans =
      proModalState.products.length || strictRequest ? proModalState.products : PRO_MODAL_PRODUCTS
    if (isEqual(requestedPlans, [ProductType.INBOX]) && inboxEnabled) {
      return { plans: [inbox], addOns: [] }
    }

    // Add-ons only case. Only plans that are not active and required by add-ons
    if (proModalState.addOns?.length) {
      const addOnSlugs = proModalState.addOns
      const requestedAddOns = addOns.filter((addOn) => addOnSlugs.includes(addOn.slug))
      const planSlugs = new Set(requestedAddOns.map((addOn) => addOn.requiredPlan))
      const requestedPlans = [automation, inbox].filter(
        (plan) => planSlugs.has(plan.slug) && !proProducts[plan.slug]?.active,
      )

      return {
        plans: requestedPlans,
        addOns: requestedAddOns,
      }
    }

    const plans = [automation, inboxEnabled && inbox].filter(
      (plan) => plan && !proProducts[plan.slug].active && requestedPlans.includes(plan.slug),
    ) as CartProductPlan[]

    // Add-ons from `UpgradeRequest` or any add-on
    const requestedAddOns =
      proModalState.addOns?.length || strictRequest
        ? proModalState.addOns
        : Object.values(AddOnSlug)

    return {
      plans,
      addOns: addOns.filter((addOn) => requestedAddOns.includes(addOn.slug)),
    }
  },
)

export const getAppliedDiscount = createSelector([getBillingProInfo], (info): Discount | null => {
  const coupon = info.coupon

  if (coupon) {
    return {
      type: DiscountType.COUPON,
      period: coupon.period,
      periodUnit: coupon.type,
      discount: coupon.percentage_discount_amount_value,
      discountUnit: DiscountUnit.PERCENT,
      plans: coupon.products || [],
      addOns: coupon.addons || [],
      coupon,
    }
  }

  return null
})

export const getCartProducts = createSelector(
  [getCartProductsUndiscounted, getAppliedDiscount],
  (cart, discount) => {
    if (discount) {
      return applyDiscountToCart(cart, discount)
    }

    return cart
  },
)

export const getDiscountedCartTotal = createSelector(
  [
    getCartProducts,
    getAppliedDiscount,
    (_: RootState, products: string[]) => products,
    (_: RootState, __: string[], addOns: string[]) => addOns,
  ],
  (cart, discount, products, addOns): number => {
    const filteredPlans = cart.plans.filter((plan) => products.includes(plan.slug))
    const filteredAddOns = cart.addOns.filter((addOn) => addOns.includes(addOn.slug))

    const filteredCart: CartProductsData = {
      plans: filteredPlans,
      addOns: filteredAddOns,
    }
    const discountedCart = discount ? applyDiscountToCart(filteredCart, discount) : filteredCart

    return [...discountedCart.plans, ...discountedCart.addOns].reduce(
      (sum, product) => sum + (product.discount?.price ?? product.price),
      0,
    )
  },
)

export const getHasAutomation = createSelector([getProProducts], (proProducts) =>
  Boolean(proProducts?.automation?.active),
)

export const getProductAutomation = createSelector(
  [getProProducts],
  (products) => products?.automation,
)

export const getIsLoadingProducts = createSelector([getProProducts], (proProducts) => !proProducts)

export const getActiveAddOns = createSelector([getAddOnsData], (addOns): AddOnSlug[] =>
  addOns.reduce<AddOnSlug[]>((slugs, addOn) => {
    if (addOn.status === AddOnStatus.ACTIVE) {
      slugs.push(addOn.slug)
    }
    return slugs
  }, []),
)

/**
 * Checks if the account is already participating in any experiment.
 *
 * This function iterates over all possible experiment flags and checks if any of them
 * are present in the current account's flags.
 *
 * @param {RootState} state - The current state of the application.
 * @returns {boolean} - `true` if the account is in any experiment, otherwise `false`.
 */
export const getIsAlreadyInAnyExperiment = (state: RootState): boolean => {
  const flags = getAccountFlags(state)
  const allExperimentFlags = Object.values(experimentFlagMap).flat()

  return allExperimentFlags.some((flag) => Boolean(flags[flag]))
}

/**
 * Checks if an experiment already exists for the given state and experiment.
 *
 * This function determines if any of the flags associated with the given experiment
 * are present in the current account's flags. Additionally, it checks if the trial
 * is available.
 */
export const getIsAlreadyInExperiment = (
  state: RootState,
  experiment: BillingExperiment,
): boolean => {
  const flags = getAccountFlags(state)
  const experimentFlags = experimentFlagMap[experiment]
  return experimentFlags.some((flag) => Boolean(flags[flag]))
}
/**
 * Add here any conditions that have to be met for given experiment
 * in format of state selector
 */
export const getExperimentConditionsMet: Selector<
  RootState,
  Record<BillingExperiment, boolean>
> = createStructuredSelector({
  [BillingExperiment.UPGRADE_PAGE]: (state) =>
    isTrialAvailable(state) && getIsAlreadyInExperiment(state, BillingExperiment.UPGRADE_PAGE),
  [BillingExperiment.TIER_NOTIFICATION]: getIsPro,
})

export const getActiveExperiment: Selector<RootState, BillingExperiment | null> = (
  state: RootState,
) => {
  const experiment = getUpgradeRequest(state).experiment
  if (!experiment) {
    return null
  }

  if (!getExperimentConditionsMet(state)[experiment]) {
    return null
  }

  return experiment
}

export const getActiveAGroupExperiment: Selector<RootState, BillingExperiment | null> =
  createSelector([getActiveExperiment, getAccountFlags], (experiment, flags) => {
    if (!experiment) {
      return null
    }

    const [experimentFlag] = experimentFlagMap[experiment] || []
    if (!flags?.[experimentFlag]) {
      return null
    }

    return experiment
  })

const isAiOnlyCart = (cart: CartProductsData): boolean => {
  const cartSlugs = extractCartSlugs(cart)

  return isEqual(cartSlugs, {
    plans: [],
    addOns: [AddOnSlug.AI],
  })
}

export const getIsAiOnlyCart = createSelector([getCartProducts], (cart): boolean =>
  Boolean(isAiOnlyCart(cart)),
)

export const getUpgradeModalVersionConditions = createSelector(
  [isTrialAvailable, getIsAiOnlyCart, (state) => getSubscriptionInfo(state).isTrial],
  (trialAvailable, isAiOnlyCart, isTrial): Record<UpgradeModalVersion, boolean> => ({
    [UpgradeModalVersion.UPGRADE_PAGE]: false,
    [UpgradeModalVersion.SIMPLE_TRIAL]: trialAvailable,
    [UpgradeModalVersion.UPGRADE_AI]: isAiOnlyCart && !isTrial,
    [UpgradeModalVersion.UPGRADE_AI_FROM_TRIAL]: isAiOnlyCart && isTrial,
    [UpgradeModalVersion.UPGRADE]: true,
    [UpgradeModalVersion.PAYWALL]: true,
  }),
)

export const getUpgradeModalVersion = createSelector(
  [
    getCartProducts,
    getUpgradeRequest,
    getUpgradeModalVersionConditions,
    getActiveAGroupExperiment,
    getSubscriptionInfo,
  ],
  (cart, request, variantConditions, experiment, subscriptionInfo): UpgradeModalVersion => {
    if (isAiOnlyCart(cart)) {
      if (subscriptionInfo.isTrial) {
        return UpgradeModalVersion.UPGRADE_AI_FROM_TRIAL
      }
      return UpgradeModalVersion.UPGRADE_AI
    }

    if (request.variant && variantConditions[request.variant]) {
      return request.variant
    }

    if (experiment) {
      const experimentFlagVersionMap: Partial<Record<BillingExperiment, UpgradeModalVersion>> = {
        [BillingExperiment.UPGRADE_PAGE]: UpgradeModalVersion.UPGRADE_PAGE,
      }
      const modalVersionByExperiment = experimentFlagVersionMap[experiment]
      if (modalVersionByExperiment) {
        return modalVersionByExperiment
      }
    }

    return UpgradeModalVersion.PAYWALL
  },
)

export const getActiveExperimentHash: Selector<RootState, string | undefined> = createSelector(
  [getActiveExperiment, getAccountFlags],
  (experiment, flags) => {
    if (!experiment) {
      return undefined
    }

    const [flagA, flagB] = experimentFlagMap[experiment] || []
    if (flags?.[flagA]) {
      return `${experiment}_a`
    }
    if (flags?.[flagB]) {
      return `${experiment}_b`
    }

    return undefined
  },
)

export const getModalAnalyticsData = createSelector(
  [
    getUpgradeRequest,
    getCartProducts,
    getSubscriptionAction,
    getUpgradeModalVersion,
    getActiveExperimentHash,
  ],
  (upgradeRequest, products, subscriptionAction, modalVersion, experiment) => {
    return {
      source: formatUpgradeSource(upgradeRequest.source),
      products: products.plans.map((plan) => plan.slug),
      addOns: products.addOns.map((addOn) => addOn.slug),
      proNodes: upgradeRequest.proNodes || [],
      uuid: upgradeRequest.uuid,
      modalVersion,
      subscriptionAction,
      experiment,
    }
  },
)

export const getUpgradeRequestSource = createSelector(
  [getUpgradeRequest],
  (request) => request.source,
)

export const getShouldUseCheckoutFlow = createSelector(
  getInAppCardAttachmentFlags,
  getBillingAddressFlags,
  getUpgradeRequest,
  (inAppCardAttachmentExperiment, billingAddressExperiment, request) => {
    const VALID_EXPERIMENT_UPGRADE_SOURCES: UpgradeSourceType[] = [
      UpgradeSource.NOTIFICATION_BAR_PRO_TRIAL_EXPERIMENT,
      UpgradeSource.SIDEBAR,
      UpgradeSource.EXPIRED_NOTIFY_BAR,
    ]

    return (
      (inAppCardAttachmentExperiment.isEnabled || billingAddressExperiment.isEnabled) &&
      VALID_EXPERIMENT_UPGRADE_SOURCES.includes(request.source)
    )
  },
)

export const getCheckoutFlowExperimentSource = createSelector(
  [getUpgradeRequestSource, getInAppCardAttachmentFlags, getBillingAddressFlags],
  (source, inAppCardAttachmentExperiment, billingAddressExperiment) => {
    let experimentSource

    if (inAppCardAttachmentExperiment.isEnabled) {
      experimentSource = `${UpgradeSource.IN_APP_CARD_ATTACHMENT_EXPERIMENT}.${source}`
    }
    if (billingAddressExperiment.isEnabled) {
      experimentSource = `${UpgradeSource.BILLING_ADDRESS_ONLY_US_EXPERIMENT}.${source}`
    }

    return experimentSource
  },
)

/**
 * Method, that received billing experiment and return the group, that an account belongs to
 * If account doesn't have experiment, method returns undefined
 */
export const getExperimentGroup = createSelector(
  [
    getAccountFlags,
    (state: RootState, experiment: BillingExperiment) => experiment,
    (state: RootState, experiment: BillingExperiment) =>
      getExperimentConditionsMet(state, experiment)[experiment],
  ],
  (accountFlags, experiment, condition): ExperimentGroup | undefined => {
    if (!experiment || !experimentFlagMap[experiment] || !condition) {
      return undefined
    }

    const [groupA, groupB] = experimentFlagMap[experiment]
    if (accountFlags[groupA]) {
      return ExperimentGroup.A
    }

    if (accountFlags[groupB]) {
      return ExperimentGroup.B
    }

    return undefined
  },
)

/**
 * Selector to get the automation product tier from the billing pro info.
 */
export const getAutomationProductTier = createSelector(
  [getProductAutomation],
  (automation) => automation?.plan_subscribers,
)

/**
 * Returns amount of contacts that the user pays for.
 * The tier is calculated according to this number of contacts.
 */
export const getBillableContacts = createSelector(
  [getProductAutomation],
  (automation): number => automation?.current_contacts_count ?? 0,
)

/**
 * Selector to get the tier limit notification state based on the automation product tier and current contacts count.
 */
export const getShouldShowTierLimitNotification = createSelector(
  [getAutomationProductTier, getBillableContacts],
  (plan_subscribers, current_contacts_count) => {
    return getTierLimitState(plan_subscribers, current_contacts_count)
  },
)
/**
 * Selector to determine if the tier limit notification should be shown, considering experiments.
 *
 * This selector combines the results of multiple selectors and additional logic to determine
 * if the tier limit notification should be displayed. It takes into account the experiment group,
 * whether the user is already in any experiment, and if the user is already in the tier notification experiment.
 *
 * @param {RootState} state - The current state of the application.
 * @returns {boolean} - `true` if the tier limit notification should be shown, otherwise `false`.
 */
export const getShouldShowTierLimitNotificationWithExperiment = createSelector(
  [getExperimentGroup, getShouldShowTierLimitNotification],
  (group, shouldShowByLimit) => {
    return group === ExperimentGroup.A && shouldShowByLimit
  },
)

/**
 * Returns amount and currency for price data:
 * Price for inbox additional seat
 * Price for e-mail channel for 1 sent e-mail
 * Price for WhatsApp paid conversations
 * Price for 1 sms sent, SMS channel
 */
const defaultPriceData: PriceData = {
  seat: { amount: 0, currency: BillingCurrency.USD },
  email: { amount: 0, currency: BillingCurrency.USD },
  sms: { amount: 0, currency: BillingCurrency.USD },
  wa: { amount: 0, currency: BillingCurrency.USD },
}
export const getPriceData = createSelector(
  [getBillingProInfo],
  ({ price_data }): PriceData => price_data ?? defaultPriceData,
)

/**
 * This selector retrieves the billing currency of the account.
 */
export const getBillingCurrency = (state: Pick<RootState, 'app'>): BillingCurrency =>
  state.app.currentAccount.billing_info?.currency ?? BillingCurrency.USD

/**
 *  This selector retrieves the fetching of billing pro settings.
 */
export const getIsLoadingProInfo = createSelector(
  [getBillingProInfo, getBillingState],
  (pro, billingState) => {
    const { fetchingPro } = billingState
    return fetchingPro || !Object.keys(pro).length
  },
)

/**
 * Selector to get the localized total price for active products and addOns.
 * Used in the billing payment details.
 */
export const getLocalizedTotalPaymentDetailsPrice = createSelector(
  [getBillingProInfo, getAddOnsData, getBillingCurrency],
  (pro, addOnsData, currency) => {
    const products = Object.fromEntries(
      Object.entries(pro.products || {}).filter(([, product]) => product.active),
    )
    const addOns = addOnsData.filter((addOn) => addOn.status === AddOnStatus.ACTIVE)
    const totalPaymentDetailsPrice =
      Object.values(products).reduce((total, product) => total + product.price, 0) +
      addOns.reduce((total, addOn) => total + addOn.price, 0)
    return l.currency(totalPaymentDetailsPrice ?? 0, currency, 0)
  },
)

/**
 * Selector to get the localized total price for active products and addOns.
 * Used in the billing payment details.
 */
export const getLocalizedTotalPaymentDetailsPriceDiscounted = createSelector(
  [getBillingProInfo, getAddOnsData, getBillingCurrency],
  (pro, addOnsData, currency) => {
    const appliedCoupon = pro.coupon_info
    if (!appliedCoupon) {
      return null
    }

    const activePlans = Object.entries(pro.products || {}).filter(([, product]) => product.active)
    const plans: CartProductPlan[] = activePlans.map(([slug, plan]) => ({
      type: 'plan',
      price: plan.price,
      slug: slug as ProProduct,
      name: PRODUCT_NAME_MAP[slug as ProProduct],
    }))
    const addOns: CartProductAddOn[] = addOnsData
      .filter((addOn) => addOn.status === AddOnStatus.ACTIVE)
      .map((addOn) => ({
        type: 'addOn',
        price: addOn.price,
        slug: addOn.slug,
        name: addOn.name,
      }))

    const totalDiscountedAmount = [...plans, ...addOns]
      .map((product) => applyCouponForProduct(product, appliedCoupon))
      .map((product) => product.discount?.price ?? product.price)
      .reduce((total, productPrice) => total + productPrice, 0)

    const precision = Number.isInteger(totalDiscountedAmount) ? 0 : 2

    return l.currency(totalDiscountedAmount ?? 0, currency, precision)
  },
)

/**
 * Selector to get the state of showing the wallet ONLY if there are active wallet, email channel, or WhatsApp channel enabled
 */
export const getIsShowWallet = createSelector(
  [getCurrentAccount, getIsNotCreditWalletActive, getWhatsAppChannel],
  (account, isWalletActive, whatsAppChannel) => {
    const isEmailChannelActive = account.email_channel?.status === ChannelStatus.ACTIVE

    let isWhatsAppChannelActive = false
    if (isWhatsAppChannelConnected(whatsAppChannel)) {
      isWhatsAppChannelActive = whatsAppChannel.status === ChannelStatus.ACTIVE
    }

    return isWalletActive || isEmailChannelActive || isWhatsAppChannelActive
  },
)

export const getAccountTaxNumber = createSelector(
  [getCurrentAccount],
  (account) => account.billing_info?.tax_number,
)

export const getManychatLegalEntity = createSelector(
  [getCurrentAccount],
  (account) => account.billing_info?.legal_entity,
)

export const getShouldRequestTaxNumber = createSelector(
  [getManychatLegalEntity, getIsPro, (state: RootState, force?: boolean) => force],
  (legalEntity, isPro, force = false) =>
    legalEntity === ManychatLegalEntity.Brazil && (!isPro || force),
)

export const getTaxFormShown = createSelector(
  getRootBillingState,
  (billing) => billing.taxForm.isOpen,
)

export const getStripePublishableKey = (state: Pick<RootState, 'app'>) =>
  state.app.currentAccount.billing_info?.stripe?.publishable_key ?? ''

export const getIsCartFulfilled = createSelector(
  getActiveProProducts,
  getActiveAddOns,
  (_: RootState, products?: ProductType[]) => products,
  (_: RootState, __?: ProductType[], addOns?: AddOnSlug[]) => addOns,
  (activeProducts, activeAddOns, requestedProducts, requestedAddOns) => {
    const hasRequestedProducts = Boolean(requestedProducts && requestedProducts.length > 0)
    const hasRequestedAddOns = Boolean(requestedAddOns && requestedAddOns.length > 0)

    if (!hasRequestedProducts && !hasRequestedAddOns) {
      return activeProducts.length > 0
    }

    const isProductsFulfilled = (requestedProducts || []).every((product) =>
      activeProducts.includes(product as PlanSlug.INBOX | PlanSlug.AUTOMATION),
    )

    const isAddOnsFulfilled = (requestedAddOns || []).every((addOn) => activeAddOns.includes(addOn))

    return isProductsFulfilled && isAddOnsFulfilled
  },
)

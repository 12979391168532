export const EMAIL_SETTINGS_SET_REPLY_TO = 'EMAIL_SETTINGS_SET_REPLY_TO'
export const EMAIL_SETTINGS_SET_REPLY_TO_RESPONSE = 'EMAIL_SETTINGS_SET_REPLY_TO_RESPONSE'

export const EMAIL_SETTINGS_SET_SENDER_NAME = 'EMAIL_SETTINGS_SET_SENDER_NAME'
export const EMAIL_SETTINGS_SET_SENDER_NAME_RESPONSE = 'EMAIL_SETTINGS_SET_SENDER_NAME_RESPONSE'

// business domain
export const SET_CONNECT_BUSINESS_EMAIL_STEP = 'SET_CONNECT_BUSINESS_EMAIL_STEP'
export const EMAIL_SETTINGS_GET_BUSINESS_EMAIL_INFO = 'EMAIL_SETTINGS_GET_BUSINESS_EMAIL_INFO'
export const EMAIL_SETTINGS_GET_BUSINESS_EMAILS = 'EMAIL_SETTINGS_GET_BUSINESS_EMAILS'
export const EMAIL_SETTINGS_GET_BUSINESS_EMAILS_REQUEST =
  'EMAIL_SETTINGS_GET_BUSINESS_EMAILS_REQUEST'
export const EMAIL_SETTINGS_GET_BUSINESS_EMAILS_RESPONSE =
  'EMAIL_SETTINGS_GET_BUSINESS_EMAILS_RESPONSE'
export const EMAIL_SETTINGS_GET_BUSINESS_EMAILS_ERROR = 'EMAIL_SETTINGS_GET_BUSINESS_EMAILS_ERROR'
export const EMAIL_SETTINGS_ADD_BUSINESS_EMAIL = 'EMAIL_SETTINGS_ADD_BUSINESS_EMAIL'
export const EMAIL_SETTINGS_ADD_BUSINESS_EMAIL_RESPONSE =
  'EMAIL_SETTINGS_ADD_BUSINESS_EMAIL_RESPONSE'
export const EMAIL_SETTINGS_UPDATE_BUSINESS_EMAIL = 'EMAIL_SETTINGS_UPDATE_BUSINESS_EMAIL'
export const EMAIL_SETTINGS_UPDATE_BUSINESS_EMAIL_RESPONSE =
  'EMAIL_SETTINGS_UPDATE_BUSINESS_EMAIL_RESPONSE'
export const EMAIL_SETTINGS_VERIFY_BUSINESS_EMAIL = 'EMAIL_SETTINGS_VERIFY_BUSINESS_EMAIL'
export const EMAIL_SETTINGS_VERIFY_BUSINESS_EMAIL_RESPONSE =
  'EMAIL_SETTINGS_VERIFY_BUSINESS_EMAIL_RESPONSE'
export const EMAIL_SETTINGS_REMOVE_BUSINESS_EMAIL = 'EMAIL_SETTINGS_REMOVE_BUSINESS_EMAIL'
export const EMAIL_SETTINGS_REMOVE_BUSINESS_EMAIL_RESPONSE =
  'EMAIL_SETTINGS_REMOVE_BUSINESS_EMAIL_RESPONSE'
export const EMAIL_SETTINGS_ACTIVATE_BUSINESS_DOMAIN = 'EMAIL_SETTINGS_ACTIVATE_BUSINESS_DOMAIN'
export const EMAIL_SETTINGS_ACTIVATE_BUSINESS_DOMAIN_RESPONSE =
  'EMAIL_SETTINGS_ACTIVATE_BUSINESS_DOMAIN_RESPONSE'
export const EMAIL_SETTINGS_ACTIVATE_BUSINESS_EMAIL = 'EMAIL_SETTINGS_ACTIVATE_BUSINESS_EMAIL'
export const EMAIL_SETTINGS_ACTIVATE_BUSINESS_EMAIL_RESPONSE =
  'EMAIL_SETTINGS_ACTIVATE_BUSINESS_EMAIL_RESPONSE'
export const EMAIL_SETTINGS_CONFIRM_BUSINESS_EMAIL = 'EMAIL_SETTINGS_CONFIRM_BUSINESS_EMAIL'
export const EMAIL_SETTINGS_RESEND_VERIFY_BUSINESS_EMAIL =
  'EMAIL_SETTINGS_RESEND_VERIFY_BUSINESS_EMAIL'
export const EMAIL_SETTINGS_VERIFY_BUSINESS_DOMAIN = 'EMAIL_SETTINGS_VERIFY_BUSINESS_DOMAIN'
export const EMAIL_SETTINGS_VERIFY_BUSINESS_DOMAIN_RESPONSE =
  'EMAIL_SETTINGS_VERIFY_BUSINESS_DOMAIN_RESPONSE'

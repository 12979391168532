import React, { useEffect } from 'react'
import { useAppDispatch, useAppSelector } from 'reduxTyped'
import { l, BtnV2 } from '@manychat/manyui'

import { AiFaqFolderBanner } from 'apps/aiIntentsWizard/components/AiFaqFolderBanner/AiFaqFolderBanner'
import { AutomationModals } from 'apps/cms/components/Flows/components/AutomationModals/AutomationModals'
import { BulkActionsButton } from 'apps/cms/components/Flows/components/BulkActionsButton/BulkActionsButton'
import { ContentHeader } from 'apps/cms/components/Flows/components/ContentHeader/ContentHeader'
import { CustomDragLayer } from 'apps/cms/components/Flows/components/CustomDragLayer/CustomDragLayer'
import { TrashButton } from 'apps/cms/components/Flows/components/FlowFilters/components/TrashButton/TrashButton'
import { FlowFilters } from 'apps/cms/components/Flows/components/FlowFilters/FlowFilters'
import { FlowsList } from 'apps/cms/components/Flows/components/FlowsList/FlowsList'
import { FoldersList } from 'apps/cms/components/Flows/components/FoldersList/FoldersList'
import { ListHeading } from 'apps/cms/components/Flows/components/ListHeading/ListHeading'
import { ViewModeSwitcher } from 'apps/cms/components/Flows/components/ViewModeSwitcher/ViewModeSwitcher'
import { ViewDisplayMode } from 'apps/cms/lib/constants'
import { contentManagementActions, contentManagementSelectors } from 'apps/cms/store'
import { usePermission, PermissionTargets } from 'common/userRoles'

import cm from './Flows.module.css'

export const Flows = () => {
  const isInitialized = useAppSelector(contentManagementSelectors.getInitialized)
  const dispatch = useAppDispatch()

  const displayMode = useAppSelector(contentManagementSelectors.getViewDisplayMode)

  const userCanEditFlow = usePermission(PermissionTargets.FLOW_EDITING)
  const fsQuery = useAppSelector(contentManagementSelectors.getQuery)

  const flowIds = useAppSelector(contentManagementSelectors.getFlowIds)
  const flowsState = useAppSelector(contentManagementSelectors.getFlowsState)
  const isTrashFolder = useAppSelector(contentManagementSelectors.getIsTrashFolder)

  const isAnyFilterApplied = useAppSelector(contentManagementSelectors.getAnyFilterApplied)
  const hasQuerySearch = useAppSelector(contentManagementSelectors.getHasQuerySearch)
  const { showBulkActions, showFolders, showModeSwitcher, showTrash } = useAppSelector(
    contentManagementSelectors.getUi,
  )
  const allowSelectFlows = showBulkActions && userCanEditFlow

  useEffect(() => {
    if (isInitialized) {
      dispatch(contentManagementActions.fetchFlows())
    }
  }, [dispatch, fsQuery, isInitialized])

  useEffect(() => {
    dispatch(contentManagementActions.fetchFolders())
  }, [dispatch])

  useEffect(() => {
    return () => {
      dispatch(contentManagementActions.resetState())
    }
  }, [dispatch])

  if (!isInitialized) {
    return null
  }

  return (
    <>
      <div className={cm.automations}>
        <div className={cm.variablesRoot}>
          <ContentHeader />

          <AiFaqFolderBanner currentFolder={fsQuery.path} />

          {!isTrashFolder && (
            <div className="m-b-md">
              <FlowFilters />
            </div>
          )}

          {!isTrashFolder && !isAnyFilterApplied && !hasQuerySearch && showFolders && (
            <FoldersList />
          )}

          {!isTrashFolder && showTrash && (
            <div className="d-flex justify-end m-b-lg">
              <TrashButton />
            </div>
          )}

          {(flowsState.isLoading || (flowsState.isLoaded && flowIds.length > 0)) &&
            (allowSelectFlows || showModeSwitcher) && (
              <div className={cm.listHeader}>
                <div className={cm.listControl}>
                  {allowSelectFlows ? <BulkActionsButton /> : <div />}

                  {showModeSwitcher && <ViewModeSwitcher />}
                </div>

                {displayMode === ViewDisplayMode.LIST && <ListHeading />}
              </div>
            )}

          <FlowsList />

          {flowsState.limiter !== null && (
            <div className="d-flex justify-center m-t-lg">
              <BtnV2
                onClick={() => {
                  dispatch(contentManagementActions.fetchFlowsNextPage())
                }}
                loading={flowsState.isLoading}
              >
                {l.translate('Load more')}
              </BtnV2>
            </div>
          )}

          <AutomationModals />
        </div>
      </div>

      <CustomDragLayer />
    </>
  )
}

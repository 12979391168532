import dot from 'dot-prop-immutable'

import * as atypes from 'apps/templates/constants/ReduxActionTypes'
import { makeCurrentItemReducer } from 'utils/factory'

const currentItem = makeCurrentItemReducer('installedTemplates')

export default function currentItemReducer(state, action) {
  state = currentItem(state, action)
  switch (action.type) {
    case atypes.INSTALLED_TEMPLATE_EDIT_PROTECTION_RESPONSE: {
      const { is_template_installation_protected } = action.data.data

      return dot.set(
        state,
        `item.template_installation_access_data.is_protected`,
        is_template_installation_protected,
      )
    }
  }
  return state
}

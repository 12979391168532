import { l } from '@manychat/manyui'

import { Country, WhatsAppEMSUAPI } from 'apps/whatsApp/core/constants'
import { alert } from 'common/core'
import { AVAILABLE_CURRENCIES } from 'common/settings/interfaces'
import { allCountries } from 'components/PhoneInput/country_data'
import { isRequestError } from 'utils/api/mcApi/RequestError'
import { Body, McAPI } from 'utils/api/types'
import errorTrackingService from 'utils/services/errorTrackingService'

type PhoneCountriesResponse = {
  // currency should be in the response, but since the BRL payments solution is still in progress it may not be present
  countries?: Array<{ country_code: string; price: number; currency?: AVAILABLE_CURRENCIES }>
} & Body

type ChoosePhoneCountryResponse = {
  phone_number: string
} & Body

type CreateAccountResponse = {
  account_id: AccountId
} & Body

type WAIsEmSUAvailable = {
  available: boolean
  freshdesk_enabled: boolean
} & Body

const makeWhatsAppEmSUAPI = (api: McAPI, getAccountID: () => string): WhatsAppEMSUAPI => {
  return {
    async connectWhatsAppChannelWithToken(token: string) {
      const url = getAccountID()
        ? '/:currentAccountID/whatsApp/connectWhatsAppChannel'
        : '/whatsAppUser/connectWhatsAppChannel'
      await api.post<Body>(url, { body: { token } })
    },
    async connectWhatsAppChannelWithPhone(businessId: string, phoneId: string) {
      const url = getAccountID()
        ? '/:currentAccountID/whatsApp/associateAccountWithBusiness'
        : '/whatsAppUser/associateAccountWithBusiness'
      await api.post(url, { body: { business_id: businessId, phone_number_id: phoneId } })
    },
    async getIsEmSUAvailable() {
      const result = await api.get<WAIsEmSUAvailable>('/whatsAppUser/checkConnectionAvailability')
      return {
        available: result.available,
        freshdeskEnabled: result.freshdesk_enabled,
      }
    },
    async getPhoneCountries() {
      const result = await api.get<PhoneCountriesResponse>(
        '/:currentAccountID/whatsApp/getPhoneCountries',
      )

      return (result.countries ?? [])
        .map((country) => {
          const { country_code: countryCode, price, currency } = country
          const code = countryCode.toLowerCase()
          const countryDesc = (
            allCountries as unknown as Array<{
              iso2: string
              name: string
              dialCode: string
            }>
          ).find(({ iso2 }) => code === iso2)

          if (!countryDesc) {
            errorTrackingService.trackError(`Unknown country "${countryCode}"`, {
              extra: country,
              fingerprint: 'wa-getting-phone-countries-error',
            })
            return
          }

          return {
            value: countryCode,
            code: countryDesc.dialCode,
            name: countryDesc.name,
            price,
            currency,
          }
        })
        .filter((c) => c) as Country[]
    },
    async buyPhoneNumber(countryCode: string) {
      const result = await api.post<ChoosePhoneCountryResponse>(
        '/:currentAccountID/whatsApp/choosePhoneCountry',
        {
          body: { country_code: countryCode },
        },
      )
      return result?.phone_number ?? ''
    },
    async createAccount() {
      try {
        const result = await api.post<CreateAccountResponse>('/whatsAppUser/createAccount')
        return { accountId: result.account_id }
      } catch (err) {
        if (isRequestError(err) && err.response) {
          const message = Array.isArray(err.response.errors)
            ? err.response.errors.join('. ')
            : err.response.error
          return { accountId: '', error: message }
        } else {
          const message = l.translate('Something went wrong on the server. Please try again.')
          alert(message, 'danger')
          errorTrackingService.trackError(err, {
            fingerprint: 'addWaAccount.twilio_btn_click.create_account',
          })
          return { accountId: '', error: message }
        }
      }
    },
    async emsuStarted() {
      await api.post('/:currentAccountID/whatsApp/emsuStarted')
    },
  }
}

export default makeWhatsAppEmSUAPI

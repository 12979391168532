const UserFieldsTypes = {
  TEXT: 'text',
  NUMBER: 'number',
  DATE: 'date',
  DATETIME: 'datetime',
  BOOL: 'boolean',
  ARRAY: 'array',
}

export type UserFieldType = typeof UserFieldsTypes[keyof typeof UserFieldsTypes]

export const NEW_SUBSCRIBER_VARIABLES = ['last_seen', 'gender', 'locale', 'language']

export const UserFieldsNumberOperations = {
  SET: '=',
  INCREASE: '+',
  DECREASE: '-',
  CUSTOM: '+-',
}

export const UserFieldsDateOperations = {
  SET: '=',
  NOW: 'now',
}

export const UserFieldsMutatingOperationSet = [
  UserFieldsNumberOperations.INCREASE,
  UserFieldsNumberOperations.DECREASE,
]

export const MAX_TEXT_LENGTH = 4000
export const MAX_NUMBER_LENGTH = 30

export const UserFieldsNumberOperationTypes = [
  {
    value: UserFieldsNumberOperations.SET,
    label: 'Set the number to',
  },
  {
    value: UserFieldsNumberOperations.INCREASE,
    label: 'Increase the number by',
  },
  {
    value: UserFieldsNumberOperations.DECREASE,
    label: 'Decrease the number by',
  },
  {
    value: UserFieldsNumberOperations.CUSTOM,
    label: 'Use Custom Formula',
  },
]

export default UserFieldsTypes

export const UserFieldsDateFormula = {
  NOW: 'nowDate()',
}

export const UserFieldsDateTimeFormula = {
  NOW: 'nowDateTime()',
}

export const UserFieldsDateOperationsSet = {
  SET_SPECIFIC_DATE: 'set_specific_date',
  SET_DATE_OF_THE_ACTION: 'set_date_of_the_action',
  SET_TO_DATE_CUF: 'set_to_date_cuf',
}

export const UserFieldsDateTimeOperationsSet = {
  SET_SPECIFIC_DATE_TIME: 'set_specific_date_time',
  SET_DATE_TIME_OF_THE_ACTION: 'set_date_time_of_the_action',
  SET_TO_DATE_TIME_CUF: 'set_to_date_time_cuf',
}

export const OperationToTypeMapping = {
  [UserFieldsDateOperationsSet.SET_SPECIFIC_DATE]: UserFieldsDateOperations.SET,
  [UserFieldsDateOperationsSet.SET_TO_DATE_CUF]: UserFieldsDateOperations.SET,
  [UserFieldsDateOperationsSet.SET_DATE_OF_THE_ACTION]: UserFieldsDateOperations.NOW,
}

export const DateTimeOperationToTypeMapping = {
  [UserFieldsDateTimeOperationsSet.SET_SPECIFIC_DATE_TIME]: UserFieldsDateOperations.SET,
  [UserFieldsDateTimeOperationsSet.SET_TO_DATE_TIME_CUF]: UserFieldsDateOperations.SET,
  [UserFieldsDateTimeOperationsSet.SET_DATE_TIME_OF_THE_ACTION]: UserFieldsDateOperations.NOW,
}

export const UserFieldsDateOperationOptions = [
  {
    value: UserFieldsDateOperationsSet.SET_SPECIFIC_DATE,
    label: 'Set to specific date',
  },
  {
    value: UserFieldsDateOperationsSet.SET_TO_DATE_CUF,
    label: 'Set to custom field value',
  },
  {
    value: UserFieldsDateOperationsSet.SET_DATE_OF_THE_ACTION,
    label: 'Set to the date of the action',
  },
]

export const UserFieldsDateTimeOperationOptions = [
  {
    value: UserFieldsDateTimeOperationsSet.SET_SPECIFIC_DATE_TIME,
    label: 'Set to specific date/time',
  },
  {
    value: UserFieldsDateTimeOperationsSet.SET_DATE_TIME_OF_THE_ACTION,
    label: 'Set to the date/time of the action',
  },
  {
    value: UserFieldsDateTimeOperationsSet.SET_TO_DATE_TIME_CUF,
    label: 'Set to custom field value',
  },
]

import { Icon, l } from '@manychat/manyui'

import {
  IntegrationNameByType,
  IntegrationType,
} from 'common/actions/integrations/common/constants/IntegrationType'
import { createIntegration } from 'common/actions/integrations/common/model/helpers'
import { AccountTargetPermissionsNames } from 'common/core/models/Account/names'

export const TikTokAdsConfig = createIntegration({
  type: IntegrationType.TIKTOK_ADS,
  name: IntegrationNameByType[IntegrationType.TIKTOK_ADS],
  icon: Icon.TikTok,
  permissionsVariable: AccountTargetPermissionsNames.TIKTOK_ADS_INTEGRATION,
  description: () =>
    l.translate(
      `Optimize your ad targeting with seamless Manychat integration.
       <br /><br />
       Use clicks in TikTok Instant Messaging Ads as a trigger to start automation in WhatsApp or Facebook Messenger.`,
    ),
  reduxActions: {},
  createHref: true,
  actions: [],
  isBeta: true,
})

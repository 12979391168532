import debugConstructor from 'debug'

import { delay } from 'utils'
import builderSelectors from 'common/builder/selectors/builder'
import * as appAtypes from 'common/core/constants/appReduxActionTypes'
import * as API from 'constants/API'

const debug = debugConstructor('optInActions')

/**
 * @module optInActions
 * для определения isOptedIn используется запрос `settings/isOptedIn`
 * эти экшены работы с optIn работают кооректно в общем случае,
 * но могут неправильно работать при онбординге
 */

export const checkboxOptIn = (optInData) => {
  return async (dispatch, getState) => {
    debug('checkboxOptIn', optInData)
    if (!window.FB) {
      return
    }

    window.FB.AppEvents.logEvent('MessengerCheckboxUserConfirmation', null, {
      app_id: `${optInData.appId}`,
      page_id: optInData.pageId,
      ref: optInData.dataRef,
      user_ref: optInData.userRef,
    })
  }
}

const requests = {}
export const waitOptedIn = (requestId, callback) => {
  return async (dispatch, getState, context) => {
    debug('waitOptedIn:try')
    const req = dispatch({
      $fetch: API.settings.endpoints.getIsOptedIn,
    })
    requests[requestId] = req
    const data = await req

    if (data.is_opted_in && !data.update_last_interaction_required) {
      debug('waitOptedIn:success')
      dispatch(dropOptInRequired())
      return callback()
    }

    await delay(3000)
    if (requests[requestId]) {
      dispatch(waitOptedIn(requestId, callback))
    }
  }
}

export const applyOptInRequired = (payload, updateLastInteractionRequired = false) => {
  return {
    type: appAtypes.APP_APPLY_OPTIN_REQUIRED,
    payload,
    updateLastInteractionRequired,
  }
}

export const dropOptInRequired = () => {
  return {
    type: appAtypes.APP_DROP_OPTIN_REQUIRED,
  }
}

export const isOptedIn = () => {
  return async (dispatch, getState, context) => {
    const data = await dispatch({
      $fetch: API.settings.endpoints.getIsOptedIn,
    })
    return data.is_opted_in
  }
}

export const isOptedInData = () => {
  return async (dispatch, getState, context) => {
    const data = await dispatch({
      $fetch: API.settings.endpoints.getIsOptedIn,
    })
    return data
  }
}

export const applySmsEmailOptInRequired = (
  smsOptInRequired,
  emailOptInRequired,
  defaultEmail = '',
  subscriberId,
) => {
  return {
    type: appAtypes.APP_APPLY_SMS_EMAIL_OPTIN_REQUIRED,
    smsOptInRequired,
    emailOptInRequired,
    defaultEmail,
    subscriberId,
  }
}

export const dropSmsEmailOptInRequired = () => {
  return {
    type: appAtypes.APP_DROP_SMS_EMAIL_OPTIN_REQUIRED,
  }
}

export const setSmsEmailOptInErrors = (smsErrors, emailErrors, subscriberErrors) => {
  return {
    type: appAtypes.APP_SET_SMS_EMAIL_OPTIN_ERRORS,
    smsErrors,
    emailErrors,
    subscriberErrors,
  }
}
export const dropSmsEmailOptInErrors = () => {
  return {
    type: appAtypes.APP_DROP_SMS_EMAIL_OPTIN_ERRORS,
  }
}

export const smsEmailOptIn = (phoneNumber, email) => {
  return async (dispatch) => {
    try {
      await dispatch({
        type: appAtypes.SUBSCRIBER_ADMIN_CREATE_OR_UPDATE,
        $fetch: [
          '/:currentAccountID/subscribers/subscriberAdminCreateOrUpdate',
          {
            method: 'POST',
            body: JSON.stringify({
              ...(phoneNumber !== null ? { phone_number: phoneNumber } : {}),
              ...(email !== null ? { email } : {}),
            }),
            headers: { 'Content-Type': 'application/json' },
          },
        ],
        $error: (err) => {
          dispatch(
            setSmsEmailOptInErrors(
              err.errors?.phone_number || [],
              err.errors?.email || [],
              err.errors?.subscriber_exist || [],
            ),
          )
        },
      })

      return true
    } catch (err) {
      return false
    }
  }
}

export const applyWhatsAppOptInRequired = (link, updateLastInteractionRequired = false) => {
  return {
    type: appAtypes.APP_APPLY_WHATSAPP_OPTIN_REQUIRED,
    link,
    updateLastInteractionRequired,
  }
}

export const dropWhatsAppOptInRequired = () => {
  return {
    type: appAtypes.APP_DROP_WHATSAPP_OPTIN_REQUIRED,
  }
}

export const applyTelegramOptInRequired = (link, botName) => {
  return {
    type: appAtypes.APP_APPLY_TELEGRAM_OPTIN_REQUIRED,
    link,
    botName,
  }
}

export const dropTelegramOptInRequired = () => {
  return {
    type: appAtypes.APP_DROP_TELEGRAM_OPTIN_REQUIRED,
  }
}

export const fetchPreviewRefURL = (builderId) => {
  return (dispatch, getState) => {
    const state = getState()
    const batch = builderSelectors.builderState.getBatch(state, builderId)

    dispatch({
      type: appAtypes.FETCH_PREVIEW_REF_URL,
      $fetch: [
        API.content.endpoints.getPreviewRefURL,
        {
          method: 'POST',
          body: JSON.stringify({
            batch,
            batch_version: 'v2',
          }),
          headers: { 'Content-Type': 'application/json' },
        },
      ],
    })
  }
}

import { BusinessError } from 'shared/api/lib/errors/business'

interface EmailUsedError extends BusinessError {
  field: 'email_used'
}

const isEmailUsedError = (error: BusinessError): error is EmailUsedError =>
  error.field === 'email_used'

interface EmailInvalidError extends BusinessError {
  field: 'email_invalid'
}

const isEmailInvalidError = (error: BusinessError): error is EmailInvalidError =>
  error.field === 'email_invalid'

interface EmailVerifiedError extends BusinessError {
  field: 'email_verified'
}

const isEmailVerifiedError = (error: BusinessError): error is EmailVerifiedError =>
  error.field === 'email_verified'

interface LimitExceededError extends BusinessError {
  field: 'limit_exceeded'
}

const isLimitExceededError = (error: BusinessError): error is LimitExceededError =>
  error.field === 'limit_exceeded'

interface ExpiredCodeError extends BusinessError {
  field: 'expired_code'
}

const isExpiredCodeError = (error: BusinessError): error is ExpiredCodeError =>
  error.field === 'expired_code'

interface IncorrectCodeError extends BusinessError {
  field: 'incorrect_code'
}

const isIncorrectCodeError = (error: BusinessError): error is IncorrectCodeError =>
  error.field === 'incorrect_code'

interface EmailError extends BusinessError {
  field: 'email'
}

const isEmailError = (error: BusinessError): error is EmailError => error.field === 'email'

export const ProfileApiGuards = {
  sendEmailVerification: {
    isEmailUsedError,
    isEmailInvalidError,
    isLimitExceededError,
    isEmailError,
  },
  verifyEmailViaCode: {
    isEmailVerifiedError,
    isLimitExceededError,
    isExpiredCodeError,
    isIncorrectCodeError,
  },
  addToWaitlistTikTokCreatorType: {
    isEmailError,
  },
}

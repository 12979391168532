import { createSelector } from 'reselect'

import { IFolder } from 'common/core/interfaces/folder'

export const getUserFieldFoldersState = (state: RootState) => state.userFieldFolders

export const getUserFieldFolders = (state: RootState) => {
  const sortFolders = (a: IFolder, b: IFolder) => a.title.localeCompare(b.title)

  return state.userFieldFolders.items ? state.userFieldFolders.items.sort(sortFolders) : []
}

export const getUserFieldFoldersMap = (state: RootState) => {
  return state.userFieldFolders.byId || {}
}

export const isUserFieldFoldersFetching = (state: RootState) => state.userFieldFolders.fetching

export const getIsFolderNameExists = createSelector(
  [getUserFieldFoldersState, (_, title: string) => title],
  (state, title) => {
    return state.items?.some((folder) => folder.title === title)
  },
)

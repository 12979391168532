import * as atypes from '../smReduxActionTypes'

const initialState = {
  buttonIsLoading: false,
  isSuccess: false,
  adAccounts: {
    selectedAdAccountById: '',
    selectedAdAccountCurrency: 'USD',
    selectedAdAccountOffset: 100,
    list: [],
  },
  adCampaigns: {
    createdAdCampaign: {},
    list: [],
  },
  adSets: {
    customAudienceData: {},
    createdAdSet: {},
    draft: {},
    list: [],
  },
  ads: {
    createdAd: {},
    list: [],
  },
  builder: {},
  tableData: {
    lists: {},
    tableIsLoading: false,
  },

  totalSubscribers: null,
}

export default function sponsoredMessagesReducer(state = initialState, action) {
  switch (action.type) {
    case atypes.SM_LOAD_AD_ACCOUNTS: {
      const { adAccounts } = action
      const businessAdAccounts = adAccounts.filter((account) => account.business)

      return {
        ...state,
        adAccounts: {
          ...state.adAccounts,
          selectedAdAccountById: businessAdAccounts.length && businessAdAccounts[0].account_id,
          list: adAccounts,
        },
      }
    }
    case atypes.SM_GET_AD_ACCOUNT_CURRENCY: {
      const { currency, offset } = action

      return {
        ...state,
        adAccounts: {
          ...state.adAccounts,
          selectedAdAccountCurrency: currency,
          selectedAdAccountOffset: offset,
        },
      }
    }
    case atypes.SM_SELECT_AD_ACCOUNT: {
      const { selectedAdAccountById } = action

      return {
        ...state,
        adAccounts: {
          ...state.adAccounts,
          selectedAdAccountById,
        },
      }
    }

    case atypes.SM_LOAD_AD_CAMPAIGNS: {
      const { adCampaigns } = action

      return {
        ...state,
        adCampaigns: {
          ...state.adCampaigns,
          list: adCampaigns,
        },
      }
    }
    case atypes.SM_CREATE_AD_CAMPAIGN: {
      const { createdAdCampaign } = action

      return {
        ...state,
        adCampaigns: {
          ...state.adCampaigns,
          createdAdCampaign,
        },
      }
    }

    case atypes.SM_GET_CUSTOM_AUDIENCE:
    case atypes.SM_CREATE_CUSTOM_AUDIENCE: {
      const { customAudienceData } = action

      return {
        ...state,
        adSets: {
          ...state.adSets,
          customAudienceData,
        },
      }
    }

    case atypes.SM_LOAD_AD_SETS: {
      const { adSets } = action

      return {
        ...state,
        adSets: {
          ...state.adSets,
          list: adSets,
        },
      }
    }
    case atypes.SM_CREATE_AD_SET: {
      const { createdAdSet } = action

      return {
        ...state,
        adSets: {
          ...state.adSets,
          draft: createdAdSet,
        },
      }
    }
    case atypes.SM_PUBLISH_AD_SET: {
      const { publishedAdSet } = action

      return {
        ...state,
        adSets: {
          ...state.adSets,
          createdAdSet: publishedAdSet.ad_set,
        },
      }
    }
    case atypes.SM_FETCH_AD_SET:
    case atypes.SM_FETCH_AD_SET_DRAFT: {
      const { fetchedAdSet } = action

      return {
        ...state,
        adSets: {
          ...state.adSets,
          createdAdSet: fetchedAdSet.ad_set,
          draft: fetchedAdSet.ad_set_draft,
        },
      }
    }
    case atypes.SM_UPDATE_AD_SET: {
      const { updatedAdSet } = action

      return {
        ...state,
        adSets: {
          ...state.adSets,
          draft: updatedAdSet.data,
        },
      }
    }

    case atypes.SM_CREATE_AD: {
      const { createdAd } = action

      return {
        ...state,
        ads: {
          ...state.ads,
          createdAd,
        },
      }
    }
    case atypes.SM_SAVE_AD_DRAFT:
    case atypes.SM_FETCH_AD_DRAFT: {
      const { data } = action

      return {
        ...state,
        ads: {
          ...state.ads,
          createdAd: data,
        },
      }
    }
    case atypes.SM_UPDATE_AD_NAME: {
      const { title } = action

      return {
        ...state,
        ads: {
          ...state.ads,
          createdAd: {
            ...state.ads.createdAd,
            name: title,
          },
        },
      }
    }

    case atypes.SM_INIT_BUILDER: {
      const { builder } = action
      return {
        ...state,
        builder,
      }
    }

    case atypes.SM_LOAD_TABLE_DATA_START: {
      return {
        ...state,
        tableData: {
          ...state.tableData,
          tableIsLoading: true,
        },
        adSets: {
          customAudienceData: {},
          createdAdSet: {},
          draft: {},
          list: [],
        },
        ads: {
          createdAd: {},
          list: [],
        },
        builder: {},
      }
    }
    case atypes.SM_LOAD_TABLE_DATA_STOP: {
      return {
        ...state,
        tableData: {
          ...state.tableData,
          tableIsLoading: false,
        },
      }
    }
    case atypes.SM_LOAD_TABLE_DATA: {
      const { tableData } = action
      return {
        ...state,
        tableData: {
          ...state.tableData,
          lists: tableData,
        },
      }
    }

    case atypes.SM_CREATE_SUCCESS: {
      return {
        ...state,
        isSuccess: true,
      }
    }
    case atypes.SM_CREATE_FINISH: {
      return {
        ...state,
        isSuccess: false,
      }
    }
    case atypes.SM_START: {
      return {
        ...state,
        buttonIsLoading: true,
      }
    }
    case atypes.SM_STOP: {
      return {
        ...state,
        buttonIsLoading: false,
      }
    }
    case atypes.SM_FETCH_TOTAL_SUBSCRIBERS_RESPONSE: {
      return {
        ...state,
        totalSubscribers: action.data.total,
      }
    }
  }

  return state
}

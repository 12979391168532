import React from 'react'
import cx from 'classnames'
import { useAppDispatch } from 'reduxTyped'
import { l } from '@manychat/manyui'

import { showIgChangeAccountErrorModal } from 'common/core/actions/uiActions'

import cm from '../StaticNotifications.module.css'

export const IgChangeAccountTypeErrorNotification = () => {
  const dispatch = useAppDispatch()
  const text = l.translate(
    'The page connection with Instagram was lost or the linked Instagram is not professional account.',
  )

  const handleClick = () => {
    dispatch(showIgChangeAccountErrorModal())
  }

  return (
    <>
      <span>{text}</span>
      <a target="_blank" className={cx(cm.more, 'm-l-xs')} onClick={handleClick}>
        {l.translate('Learn more')}
      </a>
    </>
  )
}

export enum AutoAssignmentRuleStatus {
  ENABLED = 1,
  DISABLED = 2,
}

export enum AutoAssignmentConditionType {
  SUF = 'suf',
  CUF = 'cuf',
  TAG = 'tag',
  WIDGET = 'widget',
  ADS_GROWTH_TOOL = 'ads_growth_tool',
}

export enum AutoAssignmentConditionField {
  CHANNEL = 'thread_last_channel',
  TAG = 'tag',
  WIDGET = 'widget',
  ADS_GROWTH_TOOL = 'ads_growth_tool',
}

export enum AutoAssignmentConditionOperator {
  IS = 'IS',
  IS_NOT = 'IS_NOT',
  IN = 'IN',
  NOT_IN = 'NOT IN',
}

export enum AutoAssignmentFilterOperator {
  AND = 'AND',
  OR = 'OR',
}

export enum AutoAssignmentActionType {
  ACTION_ASSIGN_TO_GROUP = 'assign_to_group',
  ACTION_ASSIGN_TO_AGENT = 'assign_to_agent',
}

export enum AutoAssignmentTriggerType {
  TRIGGER_CONVERSATION_TO_ASSIGN = 'conversation_to_assign',
}

import * as atypes from 'apps/agency/constants/AgencyReduxActionTypes'
import * as API from 'constants/API'

export function requestConnectPageToAgency() {
  return {
    type: atypes.AGENCY_CONNECT_PAGE,
    $fetch: API.agency.endpoints.requestConnectPageToAgency,
  }
}

export const confirmEmail = (code) => ({
  type: atypes.AGENCY_CONFIRM_EMAIL,
  $fetch: [
    API.agency.endpoints.clickConfirm,
    {
      method: 'POST',
      body: JSON.stringify({
        code,
      }),
      headers: { 'Content-Type': 'application/json' },
    },
  ],
  $error: () => {},
})

export const getStatus = (agencyId) => {
  return async (dispatch) => {
    const response = await dispatch({
      type: atypes.AGENCY_GET_STATUS,
      $fetch: API.agency.endpoints.getStatus.replace(':agencyId', agencyId),
      $error: () => {},
    })
    return response
  }
}

// TODO make async
export const getAchievements = (agencyId) => ({
  type: atypes.AGENCY_GET_ACHIEVEMENTS,
  $fetch: API.agency.endpoints.getAchievements.replace(':agencyId', agencyId),
  $error: () => {},
})

export const getBenefitsLinks = (agencyId) => ({
  type: atypes.AGENCY_GET_BENEFITS_LINKS,
  $fetch: API.agency.endpoints.getBenefitsLinks.replace(':agencyId', agencyId),
  $error: () => {},
})

export const sendVerification = (agencyId, email) => ({
  type: atypes.AGENCY_SEND_VERIFICATION,
  $fetch: [
    API.agency.endpoints.sendVerification.replace(':agency_id', agencyId),
    {
      method: 'POST',
      body: JSON.stringify({
        email,
      }),
      headers: { 'Content-Type': 'application/json' },
    },
  ],
})

export const updateCommissionsFilter = (date) => {
  return {
    type: atypes.AGENCY_UPDATE_FILTER,
    data: {
      startDate: date.startDate,
      endDate: date.endDate,
    },
  }
}

export const fetchFilteredCommisions = (agencyId, startDate, endDate) => {
  return (dispatch) => {
    dispatch(updateCommissionsFilter({ startDate, endDate }))
    dispatch(fetchPagesWithCommissions(agencyId, startDate, endDate))
  }
}

export const fetchPagesWithCommissions = (agencyId, startDate, endDate) => {
  return {
    type: atypes.AGENCY_FETCH_PAGES_WTIH_COMMISSIONS,
    $fetch: API.agency.endpoints.getPageListWithCommission
      .replace(':agency_id', agencyId)
      .replace(':start_date', startDate)
      .replace(':end_date', endDate),
    $error: () => {},
  }
}

export const fetchCommissionsByPage = (agencyId, pageId, startDate, endDate) => {
  return {
    type: atypes.AGENCY_FETCH_COMMISSIONS_BY_PAGE,
    $fetch: API.agency.endpoints.getCommissionByPage
      .replace(':agency_id', agencyId)
      .replace(':page_id', pageId)
      .replace(':start_date', startDate)
      .replace(':end_date', endDate),
    $error: () => {},
  }
}

import { useEffect } from 'react'

import { useWootric } from 'utils/services/wootric/wootric'

export const WootricService = () => {
  const { isReady, sendWootricEvent } = useWootric()

  useEffect(() => {
    if (isReady) {
      sendWootricEvent('run')
    }
  }, [isReady, sendWootricEvent])

  return null
}

const TAG_VIOLATION_MARKERS = [
  'account_update',
  'confirmed_event_update',
  'post_purchase_update',
  '17.8.1.b',
  '8.8.1.b',
  'policies:As',
  'policies:This',
  '8.9.a',
]

export const isTagViolation = (reasonText: string) => {
  if (typeof reasonText !== 'string') {
    return false
  }
  const text = reasonText.toLowerCase()
  return TAG_VIOLATION_MARKERS.some((marker) => text.indexOf(marker) !== -1)
}

import { builderCreateNode } from 'common/builder/actions/builderCreateNode'
import { NodeType } from 'common/builder/constants/NodeType'
import { INode } from 'common/builder/nodes/nodeInterfaces'
import { IThunkAction } from 'common/core/interfaces/actions'

export const createWhatsAppNodePreset = (
  builderId: string,
  options: Record<string, unknown>,
): IThunkAction => {
  return async (dispatch) => {
    const other: Pick<INode, 'blocks'> = { blocks: [] }

    const node = dispatch(
      builderCreateNode(
        builderId,
        {
          nodeType: NodeType.WHATS_APP,
          whatsAppNodeType: 'template',
          ...other,
        },
        options,
      ),
    )

    return node
  }
}

import { createModalSlice } from 'shared/lib/factory/redux/createModalSlice'

const initialState = {
  isOpen: true,
}
export const blockedProModalSlice = createModalSlice({
  name: 'blockedProModal',
  initialState,
  reducers: {},
})

export const blockedProModalActions = blockedProModalSlice.actions

export enum RecurringNotificationWizardSteps {
  CLOSED = 'closed',
  OVERVIEW = 'overview',
  CREATE_FOLLOW_UP = 'create_follow_up',
  COLLECTING_OPTIN = 'collecting_optin',
  SUCCESS_STEP = 'SUCCESS_STEP',
}

export type ProgressBarStep =
  | RecurringNotificationWizardSteps.OVERVIEW
  | RecurringNotificationWizardSteps.COLLECTING_OPTIN
  | RecurringNotificationWizardSteps.SUCCESS_STEP

export const MAX_FOLLOW_UP_MESSAGE_LENGTH = 350

import { Icon, l } from '@manychat/manyui'

import {
  IntegrationType,
  IntegrationNameByType,
} from 'common/actions/integrations/common/constants/IntegrationType'
import { AiIntegrationDescription } from 'common/actions/integrations/common/lib/copies'
import {
  createIntegration,
  createIntegrationAction,
} from 'common/actions/integrations/common/model/helpers'
import { AccountTargetPermissionsNames } from 'common/core/models/Account/constants'

export const ClaudeActionTypes = {
  REQUEST_TO_CLAUDE_CHAT: 'claude_messages_request',
}

export const ClaudeConfig = createIntegration({
  type: IntegrationType.CLAUDE,
  name: IntegrationNameByType[IntegrationType.CLAUDE],
  icon: Icon.Claude,
  permissionsVariable: AccountTargetPermissionsNames.CLAUDE_INTEGRAION,
  description: () => AiIntegrationDescription({ integrationName: 'Claude' }),
  reduxActions: {},
  actions: [
    createIntegrationAction({
      type: ClaudeActionTypes.REQUEST_TO_CLAUDE_CHAT,
      description: l.translate('Make request to Claude.'),
      name: l.translate('Request'),
    }),
  ],
  isBeta: true,
})

export enum AddFbStatusType {
  SELECT_PAGE = 'list',
  NO_FB_PERMISSIONS = 'facebook_permissions',
  REDIRECT = 'redirect',
  QUESTIONNAIRE_NOT_PASSED = 'questionnaire_not_passed',
  ACCOUNT_USER_NOT_FOUND = 'account_user_not_found',
  ALREADY_CONNECTED = 'fb_page_already_connected_to_this_account',
  ACCOUNT_NOT_FOUND = 'account_not_found',
}

export enum FbPageStatus {
  CONNECTED = 'connected',
  NOT_CONNECTED = 'not_connected',
  REQUEST_ACCESS_PENDING = 'request_access_pending',
  CAN_REQUEST_ACCESS = 'can_request_access',
  JOIN = 'join',
  NOT_ALLOWED_TO_CONNECT = 'not_allowed_connect',
  NOT_ALLOWED_TO_JOIN = 'not_allowed_join',
  MISSING_TOKEN = 'missing_token',
}

import { createModalSlice } from 'shared/lib/factory/redux/createModalSlice'

const initialState = {
  isOpen: false,
}
export const tierLimitModalSlice = createModalSlice({
  name: 'tierLimitModal',
  initialState,
  reducers: {},
})

export const tierLimitModalActions = tierLimitModalSlice.actions

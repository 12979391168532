import EmailIsNotSuspendedNotification from 'common/core/components/NotifyBar/EmailNotifications/EmailIsNotSuspendedNotification/EmailIsNotSuspendedNotification'
import EmailSuspensionDueToTOSNotification from 'common/core/components/NotifyBar/EmailNotifications/EmailSuspensionDueToTOSNotification/EmailSuspensionDueToTOSNotification'
import { NotificationBarType } from 'common/core/constants/NotificationBarType'

import {
  NotificationRemoveType,
  EmailNotification,
  EmailReadyNotification,
  EmailNotificationType,
} from '../NotifyInterfaces'

const getRender = (message: EmailNotification['message']): EmailReadyNotification['render'] => {
  switch (message) {
    case EmailNotificationType.SENDING_WAS_UN_SUSPENDED:
      return EmailIsNotSuspendedNotification
    case EmailNotificationType.SENDING_WAS_SUSPENDED_DUE_TO_TOS:
      return EmailSuspensionDueToTOSNotification
  }
}

const getMessageType = (message: EmailNotification['message']): NotificationBarType => {
  switch (message) {
    case EmailNotificationType.SENDING_WAS_UN_SUSPENDED:
      return NotificationBarType.NORMAL
    case EmailNotificationType.SENDING_WAS_SUSPENDED_DUE_TO_TOS:
      return NotificationBarType.ERROR
  }
}

export const parseEmailNotification = (notification: EmailNotification): EmailReadyNotification => {
  return {
    ...notification,
    type: getMessageType(notification.message),
    removeType: NotificationRemoveType.ALWAYS,
    render: getRender(notification.message),
  }
}

import { l, palettes, tints } from '@manychat/manyui'

import { NodeType } from 'common/builder/constants/NodeType'
import nodeRegistry from 'common/builder/nodeRegistry'
import { ChannelType } from 'common/core/constants/ChannelType'

// NEXT STEP CONSTANTS
export enum NextStepType {
  CONTENT = 'content',
  SMS = 'sms',
  EMAIL_NEW = 'email_new',
  INSTAGRAM = 'instagram',
  TIKTOK = 'tiktok',
  WHATS_APP = 'whatsApp',
  TELEGRAM = 'telegram',
  ACTION_GROUP = 'actionGroup',
  GOTO = 'goto',
  CONDITION = 'condition',
  SPLIT = 'split',
  SMART_DELAY = 'smartDelay',
  COMMENT = 'comment',
  CROSS_CHANNEL_CONTENT = 'cross_channel_content',
  AI_AGENT = 'agent',

  URL = 'url', // ButtonType.URL
  CTA_URL = 'cta_url', // Whatsapp URL Button
  CALL = 'call',
  SHARE = 'share',
  ANSWER = 'answer',
  BUY = 'buy',
  ACTIONS = 'actions', // todo можно выпилить
  FLOW = 'flow', // Deprecated

  OPEN_IN_MESSENGER = 'open_in_messenger',
  OPEN_IN_INSTAGRAM = 'open_in_instagram',
  CONNECT_CHANNELS = 'connect_channels',
}

export const NextStepTypeIcons: Partial<Record<NextStepType, string>> = {
  [NextStepType.CONTENT]: 'MessengerChannel',
  [NextStepType.SMS]: 'SmsChannel',
  [NextStepType.EMAIL_NEW]: 'EmailChannel',
  [NextStepType.WHATS_APP]: 'WhatsAppChannel',
  [NextStepType.INSTAGRAM]: 'InstagramChannel',
  [NextStepType.TIKTOK]: 'TikTokChannel',
  [NextStepType.TELEGRAM]: 'TelegramChannel',
  [NextStepType.CALL]: 'OrphanCall',
  [NextStepType.SHARE]: 'Share',
  [NextStepType.URL]: 'Link',
  [NextStepType.CTA_URL]: 'Link',
  [NextStepType.BUY]: 'OrphanBuyButton',
  [NextStepType.GOTO]: 'GoToFlow',
  [NextStepType.ACTION_GROUP]: 'OrphanAction',
  [NextStepType.CONDITION]: 'Condition',
  [NextStepType.SPLIT]: 'OrphanSplit',
  [NextStepType.FLOW]: 'GoToFlow',
  [NextStepType.SMART_DELAY]: 'Time',
  [NextStepType.COMMENT]: 'Note',
  [NextStepType.OPEN_IN_MESSENGER]: 'MessengerChannel',
  [NextStepType.OPEN_IN_INSTAGRAM]: 'InstagramChannel',
  [NextStepType.CONNECT_CHANNELS]: 'Plus',
  [NextStepType.AI_AGENT]: 'AiStep',
}

export const NextStepTypeNames: Partial<Record<NextStepType, () => string>> = {
  [NextStepType.CONTENT]: l.makeTranslate('Messenger'),
  [NextStepType.SMS]: l.makeTranslate('SMS'),
  [NextStepType.EMAIL_NEW]: l.makeTranslate('Email'),
  [NextStepType.WHATS_APP]: l.makeTranslate('WhatsApp'),
  [NextStepType.INSTAGRAM]: l.makeTranslate('Instagram'),
  [NextStepType.TIKTOK]: l.makeTranslate('TikTok'),
  [NextStepType.TELEGRAM]: l.makeTranslate('Telegram'),
  [NextStepType.URL]: l.makeTranslate('Open website'),
  [NextStepType.CTA_URL]: l.makeTranslate('Open website'),
  [NextStepType.CALL]: l.makeTranslate('Call number'),
  [NextStepType.SHARE]: l.makeTranslate('Share this card'),
  [NextStepType.BUY]: l.makeTranslate('Buy Button'),
  [NextStepType.GOTO]: l.makeTranslate('Start another Automation'),
  [NextStepType.ACTION_GROUP]: l.makeTranslate('Perform Actions'),
  [NextStepType.CONDITION]: l.makeTranslate('Condition'),
  [NextStepType.SPLIT]: l.makeTranslate('Randomizer'),
  [NextStepType.FLOW]: l.makeTranslate('Go To Automation'),
  [NextStepType.SMART_DELAY]: l.makeTranslate('Smart Delay'),
  [NextStepType.COMMENT]: l.makeTranslate('Comment'),
  [NextStepType.OPEN_IN_MESSENGER]: l.makeTranslate('Open Messenger'),
  [NextStepType.OPEN_IN_INSTAGRAM]: l.makeTranslate('Open Instagram'),
  [NextStepType.CONNECT_CHANNELS]: l.makeTranslate('Channel'),
  [NextStepType.AI_AGENT]: l.makeTranslate('AI Step'),
}

export const NextStepTypeColors: Record<NextStepType | string, string> = {
  [NextStepType.CONTENT]: nodeRegistry.getByType(NodeType.CONTENT).color,
  [NextStepType.SMS]: nodeRegistry.getByType(NodeType.SMS).color,
  [NextStepType.EMAIL_NEW]: nodeRegistry.getByType(NodeType.EMAIL_NEW).color,
  [NextStepType.WHATS_APP]: nodeRegistry.getByType(NodeType.WHATS_APP).color,
  [NextStepType.INSTAGRAM]: nodeRegistry.getByType(NodeType.INSTAGRAM).color,
  [NextStepType.TIKTOK]: nodeRegistry.getByType(NodeType.TIKTOK).color,
  [NextStepType.TELEGRAM]: nodeRegistry.getByType(NodeType.TELEGRAM).color,
  [NextStepType.CALL]: palettes.semantic.primary,
  [NextStepType.SHARE]: palettes.semantic.primary,
  [NextStepType.URL]: palettes.semantic.primary,
  [NextStepType.CTA_URL]: palettes.semantic.primary,
  [NextStepType.BUY]: palettes.semantic.primary,
  [NextStepType.GOTO]: nodeRegistry.getByType(NodeType.GOTO).color,
  [NextStepType.ACTION_GROUP]: nodeRegistry.getByType(NodeType.ACTION_GROUP).color,
  [NextStepType.CONDITION]: nodeRegistry.getByType(NodeType.CONDITION).color,
  [NextStepType.SPLIT]: nodeRegistry.getByType(NodeType.SPLIT).color,
  [NextStepType.FLOW]: nodeRegistry.getByType(NodeType.GOTO).color,
  [NextStepType.SMART_DELAY]: nodeRegistry.getByType(NodeType.SMART_DELAY).color,
  [NextStepType.COMMENT]: nodeRegistry.getByType(NodeType.COMMENT).color,
  [NextStepType.OPEN_IN_MESSENGER]: palettes.semantic.primary,
  [NextStepType.OPEN_IN_INSTAGRAM]: nodeRegistry.getByType(NodeType.INSTAGRAM).color,
  [NextStepType.CONNECT_CHANNELS]: tints.neutral[0],
  [NextStepType.AI_AGENT]: nodeRegistry.getByType(NodeType.AI_AGENT).color,
  menu: 'orange',
}

export const NextStepProType = [
  NodeType.CONDITION,
  NodeType.SPLIT,
  NodeType.SMART_DELAY,
  NodeType.EMAIL_NEW,
  NodeType.SMS,
  NextStepType.BUY,
  NodeType.WHATS_APP,
]

export const NextStepCrossChannelConfig = {
  [ChannelType.INSTAGRAM]: {
    color: nodeRegistry.getByType(NodeType.INSTAGRAM).color,
    icon: 'InstagramChannel',
    name: l.makeTranslate('Open Instagram'),
  },
  [ChannelType.FB]: {
    color: palettes.semantic.primary,
    icon: 'MessengerChannel',
    name: l.makeTranslate('Open Messenger'),
  },
}

import isEmpty from 'lodash/isEmpty'

import { WalletType } from 'common/billing/wallet/interfaces/walletType'

import {
  FetchWalletResponse,
  ExportedAutoRefill,
  Wallet,
  AutoRefill,
  WalletRefillOptions,
} from './walletInterfaces'

export const parseWalletRefillOptions = (
  data: Partial<FetchWalletResponse['options']> = {},
): WalletRefillOptions => {
  const { trigger_autorefill_amount_options, daily_limit_options, amount_options } = data

  return {
    triggerAutorefillAmountOptions: trigger_autorefill_amount_options || [],
    dailyLimitOptions: daily_limit_options || [],
    amountOptions: amount_options || [],
  }
}

export const parseAutoRefill = (
  data: Partial<FetchWalletResponse['auto_refill']> = {},
): AutoRefill => {
  const { is_enabled, refill_amount, trigger_amount, daily_limit } = data

  return {
    isEnabled: is_enabled || false,
    refillAmount: refill_amount || 0,
    triggerAmount: trigger_amount || 0,
    dailyLimit: daily_limit || 0,
  }
}

export const parseWallet = (data: Partial<FetchWalletResponse> = {}): Wallet => {
  return {
    hasWallet: !isEmpty(data),
    balance: data?.balance ?? 0,
    creditBalance: data?.credit_balance ?? 0,
    walletType: data?.wallet_type ?? WalletType.DEBIT,
    autoRefill: parseAutoRefill(data?.auto_refill ?? {}),
    options: parseWalletRefillOptions(data?.options ?? {}),
  }
}

export const exportAutoRefill = (options: AutoRefill): ExportedAutoRefill => ({
  enable: options.isEnabled,
  amount: options.refillAmount,
  trigger_autorefill_amount: options.triggerAmount,
  daily_limit: options.dailyLimit,
})

export const exportRefill = (amount: number): { amount: number } => ({ amount })

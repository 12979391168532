import { api } from 'shared/api'
import { salesforceApiSchemas } from 'shared/api/requests/integrations/salesforce/schemas'

export const salesforceApi = {
  setAccountLinkField: api.account.createPost({
    url: '/integration/salesForce/setAccountLinkField',
    schemas: salesforceApiSchemas.setAccountLinkField,
  }),
  clearAccountLinkField: api.account.createPost({
    url: '/integration/salesForce/clearAccountLinkField',
    schemas: salesforceApiSchemas.clearAccountLinkField,
  }),
  getContactFields: api.account.createGet({
    url: '/integration/salesForce/getContactFields',
    schemas: salesforceApiSchemas.getContactFields,
  }),
  exportLivechatHistory: api.account.createPost({
    url: '/integration/salesForce/exportLivechat',
    schemas: salesforceApiSchemas.exportLiveChatHistory,
  }),
}

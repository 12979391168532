import * as atypes from 'common/actions/integrations/common/constants/IntegrationsReduxActionTypes'

const initialState = {
  identityField: {
    value: null,
    option: null,
  },
  isDisconnecting: false,
  isLoadingLists: false,
  lists: [],
  fields: [],
  selectedList: null,
  selectedFields: [],
  hasEmptyField: false,
}

const EMPTY_FIELD_MODEL = {
  key: null,
  manyChatField: {
    value: null,
  },
}

export default function integrationsReducer(state = initialState, action) {
  const { data } = action

  switch (action.type) {
    case atypes.INTEGRATION_RESET: {
      return {
        ...state,
        ...initialState,
      }
    }

    case atypes.KLAVIYO_SET_IDENTITY_FIELD: {
      return { ...state, identityField: data }
    }

    case atypes.KLAVIYO_SET_FIELD_EMPTINESS: {
      return { ...state, hasEmptyField: data }
    }

    case atypes.KLAVIYO_SET_CONTACT_LIST: {
      return { ...state, selectedList: data }
    }

    case atypes.KLAVIYO_SET_CONTACT_FIELDS: {
      return { ...state, selectedFields: data }
    }

    case atypes.KLAVIYO_GET_LISTS_REQUEST: {
      return { ...state, isLoadingLists: true }
    }

    case atypes.KLAVIYO_GET_LISTS_RESPONSE: {
      const { lists } = action.data
      return { ...state, lists, isLoadingLists: false }
    }
    case atypes.KLAVIYO_GET_LISTS_ERROR: {
      return { ...state, isLoadingLists: false }
    }

    case atypes.KLAVIYO_GET_FIELDS_RESPONSE: {
      const { fields } = action.data
      return { ...state, fields }
    }

    case atypes.KLAVIYO_ADD_FIELD: {
      return {
        ...state,
        selectedFields: [...state.selectedFields, EMPTY_FIELD_MODEL],
      }
    }

    case atypes.INTEGRATION_CONNECT_BY_TOKEN_REQUEST: {
      return { ...state, isConnecting: true }
    }

    case atypes.INTEGRATION_CONNECT_BY_TOKEN_ERROR:
    case atypes.INTEGRATION_CONNECT_BY_TOKEN_RESPONSE: {
      return { ...state, isConnecting: false }
    }

    case atypes.INTEGRATION_DISCONNECT_REQUEST: {
      return { ...state, isDisconnecting: true }
    }
    case atypes.INTEGRATION_DISCONNECT_RESPONSE: {
      return { ...state, isDisconnecting: false }
    }
  }

  return state
}

import React, { useCallback, useState } from 'react'
import cx from 'classnames'
import { useDrop } from 'react-dnd'
import { useAppDispatch, useAppSelector } from 'reduxTyped'
import { Icon, palettes } from '@manychat/manyui'

import { BreadcrumbsFolderNameInput } from 'apps/cms/components/Flows/components/CmsBreadcrumbs/components/BreadcrumbsFolderNameInput/BreadcrumbsFolderNameInput'
import { CmsBreadcrumbsSegment } from 'apps/cms/components/Flows/components/CmsBreadcrumbs/lib/types'
import { useMoveActions } from 'apps/cms/hooks/useMoveActions'
import animationsCm from 'apps/cms/lib/styles/animations.module.css'
import { CmsDragSource, FlowDragItem, FolderDragItem } from 'apps/cms/lib/types'
import { contentManagementSelectors, contentManagementActions } from 'apps/cms/store'
import { BreadcrumbsButtonProps } from 'components/Breadcrumbs/components/BreadcrumbsButton/BreadcrumbsButton'
import { CustomizableBreadcrumbsButton } from 'components/Breadcrumbs/components/CustomizableBreadcrumbsButton/CustomizableBreadcrumbsButton'

import cm from './BreadcrumbsButton.module.css'

type Props = BreadcrumbsButtonProps<CmsBreadcrumbsSegment>

export const BreadcrumbsButton = ({ segment, disabled, className, size }: Props) => {
  const dispatch = useAppDispatch()

  const selectedFlowIds = useAppSelector(contentManagementSelectors.getSelectedFlowIds)

  const { moveFolderToTargetFolder, moveFlowsToTargetFolder } = useMoveActions({
    targetFolderPath: segment.fsQueryPath,
  })

  const [{ isDragging, highlightDrop }, connectDropTarget] = useDrop({
    accept: [CmsDragSource.FLOW_CARD, CmsDragSource.FOLDER_CARD],
    collect: (monitor) => ({
      isDragging: !disabled && monitor.isOver(),
      highlightDrop: !disabled && Boolean(monitor.getItem()),
    }),
    canDrop: () => !disabled,
    drop: (item: FlowDragItem | FolderDragItem) => {
      if (item.type === CmsDragSource.FOLDER_CARD) {
        moveFolderToTargetFolder(item.path)
        return
      }

      if (selectedFlowIds.length > 1) {
        moveFlowsToTargetFolder(selectedFlowIds)
      } else {
        moveFlowsToTargetFolder([item.path])
      }
    },
  })

  const [isEditMode, setIsEditMode] = useState(false)

  const { isEditable } = segment

  const openFolder = useCallback(
    (event: React.MouseEvent<HTMLButtonElement>, segment: CmsBreadcrumbsSegment) => {
      if (isEditable) {
        setIsEditMode(true)
        return
      }

      if (event.ctrlKey || event.metaKey) {
        window.open(segment.path)
      } else {
        dispatch(
          contentManagementActions.updateQuery({
            path: segment.fsQueryPath,
          }),
        )
      }
    },
    [dispatch, isEditable],
  )

  const [newFolderName, setNewFolderName] = useState(segment.label)
  const [isLoading, setIsLoading] = useState(false)

  const hideInput = useCallback(() => {
    setIsEditMode(false)
  }, [])

  if (isEditMode) {
    return (
      <BreadcrumbsFolderNameInput
        segment={segment}
        hideInput={hideInput}
        setIsLoading={setIsLoading}
        onNameChange={setNewFolderName}
      />
    )
  }

  const children = isEditable ? (
    <>
      <span>{isLoading ? newFolderName : segment.label}</span>

      <Icon.Edit className="m-l-xxs" color={palettes.typography.secondary} size={16} />
    </>
  ) : null

  return (
    <CustomizableBreadcrumbsButton
      className={cx(
        className,
        highlightDrop && animationsCm.cmsDragTargetAnimation,
        isEditable && cm.editableButton,
      )}
      segment={segment}
      onClick={openFolder}
      size={size}
      highlight={isDragging}
      buttonRef={connectDropTarget}
      disabled={isEditable ? false : disabled}
    >
      {children}
    </CustomizableBreadcrumbsButton>
  )
}

import * as variablesHelpers from 'components/MCTextEditor/variables/helpers'
import {
  WaMessageTemplateDraft,
  WaMessageTemplateHeaderType,
} from 'shared/api/requests/whatsApp/schemas'

export enum WaMessageTemplatesSections {
  HEADER = 'Header',
  BODY = 'Body',
  BUTTON = 'URL Button',
}

interface ReturnValue {
  variables: string[]
  variablesMap: Map<WaMessageTemplatesSections, string[]>
}

export const extractVariablesFromMessageTemplate = (
  messageTemplate: WaMessageTemplateDraft | null,
): ReturnValue => {
  if (!messageTemplate) return { variables: [], variablesMap: new Map() }

  const { body = '', buttons, header } = messageTemplate
  const bodyVariables = variablesHelpers.extractVariables(body)
  const result = new Set<string>(bodyVariables)
  const variablesMap = new Map<WaMessageTemplatesSections, string[]>()

  if (
    !Array.isArray(header) &&
    header &&
    header?.type === WaMessageTemplateHeaderType.TEXT &&
    header.text
  ) {
    const headerVariables = variablesHelpers.extractVariables(header.text)
    headerVariables.forEach((variable) => result.add(variable))
    if (headerVariables.length) variablesMap.set(WaMessageTemplatesSections.HEADER, headerVariables)
  }

  if (bodyVariables.length) variablesMap.set(WaMessageTemplatesSections.BODY, bodyVariables)

  const urlButton = buttons?.find((button) => button.type === 'URL')

  if (urlButton?.type === 'URL' && urlButton.variable) {
    if (urlButton.variable.length)
      variablesMap.set(WaMessageTemplatesSections.BUTTON, [urlButton.variable])

    result.add(urlButton?.variable)
  }

  return { variables: Array.from(result), variablesMap }
}

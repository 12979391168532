import { l } from '@manychat/manyui'

import { alert } from 'common/core'
import { settingsApi } from 'shared/api/requests/settings'
import { createAsyncAction } from 'shared/lib/redux'

export const getPublicAPIToken = createAsyncAction('settings/getPublicAPIToken', async () => {
  const response = await settingsApi.publicApi.getPublicAPIToken()

  alert(l.translate('Token generated'), 'success')

  return response.data
})

export const deletePublicAPIToken = createAsyncAction('settings/deletePublicAPIToken', async () => {
  const response = await settingsApi.publicApi.deletePublicAPIToken()

  alert(l.translate('Token deleted'), 'success')

  return response.data
})

import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import { NotificationReasonActions, NotificationReasonSelectors } from 'common/oneTimeNotify/store'

class EnsureOneTimeNotifyReasonsController extends Component {
  componentDidMount() {
    this.props.NotificationReasonActions.getTopicsListFx({ active_only: false })
  }

  render() {
    const { items, isLoading, loader, provide } = this.props
    if (items === null) {
      if (isLoading && loader) {
        return loader
      }
      return null
    }

    const { children } = this.props
    if (provide) {
      const childrenProps = { oneTimeNotifyReasons: items }

      if (typeof children === 'function') {
        return <>{children(childrenProps)}</>
      }
      return React.cloneElement(children, childrenProps)
    } else if (children) {
      return children
    }
    return null
  }
}

export default connect(
  (state) => {
    return {
      items: NotificationReasonSelectors.getList(state),
      isLoading: NotificationReasonSelectors.getIsListLoading(state),
    }
  },
  (dispatch) => ({
    NotificationReasonActions: bindActionCreators(NotificationReasonActions, dispatch),
  }),
)(EnsureOneTimeNotifyReasonsController)

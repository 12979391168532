import React, { useRef } from 'react'
import cx from 'classnames'
import { v4 as uuid } from 'uuid'

import cleanProps from '../../../lib/clean-react-props'

import { FormFieldLabelContainer } from './FormFieldLabelContainer'
import { FormFieldTextBelow } from './FormFieldTextBelow'
import { FormChoiceFieldProps, FormChoiceFieldPrivateProps } from './interfaces'

import cm from './FormChoiceField.module.css'

type Props = FormChoiceFieldProps<HTMLInputElement> & FormChoiceFieldPrivateProps

export const FormChoiceField = ({
  fieldClasses,
  type: type,
  invalid,
  id,
  labelId,
  label,
  fullWidth,
  labelClassName,
  ...props
}: Props) => {
  const ref = useRef<HTMLInputElement>(null)
  const hasLabel = label || props.infoText || props.lozenge
  const inputClasses = cx(
    cm.choice,
    invalid && cm.choice__invalid,
    fieldClasses !== undefined && fieldClasses.choice,
  )

  const fieldLabelId = React.useMemo(() => {
    return labelId || `${uuid()}-label`
  }, [labelId])

  const fieldInputId = React.useMemo(() => id || `${uuid()}-input`, [id])

  return (
    <div
      className={cx(
        cm.choiceContainer,
        {
          [cm.choiceContainerFullWidth]: fullWidth,
        },
        props.className,
      )}
    >
      <input
        {...cleanProps(props, [], [])}
        type={type}
        name={props.name}
        checked={props.checked}
        id={fieldInputId}
        form={props.form}
        onChange={props.onChange}
        tabIndex={props.tabIndex}
        className={inputClasses}
        aria-invalid={invalid}
        aria-checked={props.checked}
        aria-label={props.ariaLabel}
        aria-labelledby={props.ariaLabelledBy}
        ref={ref}
      />
      {props.children}
      {hasLabel && (
        <div
          className={cx(
            cm.formFieldWrapper,
            hasLabel && cm.formFieldWrapper__withLabel,
            fieldClasses !== undefined && fieldClasses.containerWithLabel,
          )}
        >
          {label && (
            <FormFieldLabelContainer
              inputId={fieldInputId}
              label={label}
              id={fieldLabelId}
              infoText={props.infoText}
              lozenge={props.lozenge}
              infoTextWidth={props.infoTextWidth}
              disabled={props.disabled}
              fieldClasses={fieldClasses}
              labelClassName={labelClassName}
            />
          )}
          <FormFieldTextBelow
            helpText={props.helpText}
            errorText={props.errorText}
            disabled={props.disabled}
            showError={invalid}
            fieldClasses={fieldClasses}
          />
        </div>
      )}
    </div>
  )
}

import { ResponseData } from 'initAppAPI'
import { l } from '@manychat/manyui'

import {
  AccountDisplayFlag,
  AccountUserDisplayFlagType,
} from 'apps/dashboard/constants/DisplayFlagType'
import { getChannelData } from 'apps/whatsApp/redux/waChannelSlice'
import { alert } from 'common/core'
import * as UIActions from 'common/core/actions/uiActions'
import {
  AccountProStatusBlockedData,
  AccountUpdatedData,
  AccountFbPolicyNotificationNewData,
  StandardWebSocketData,
  AvatarResizeCoordinates,
  AsyncBillingPaymentSocket,
} from 'common/core/appInterfaces'
import {
  NotificationRemoveType,
  NotificationItem,
  StaticNotificationId,
} from 'common/core/components/NotifyBar/NotifyInterfaces'
import { UPDATE_SKIP_PREVIEW_ONBOARDING_MODAL } from 'common/core/constants/appReduxActionTypes'
import * as atypes from 'common/core/constants/appReduxActionTypes'
import { ChannelType } from 'common/core/constants/ChannelType'
import { IActionWithPayload, IAsyncThunkAction, IThunkAction } from 'common/core/interfaces/actions'
import { AsyncActionType } from 'common/core/interfaces/deprecatedAPI'
import { PersonaAvatar } from 'common/core/interfaces/persona'
import { ThreadsStatuses } from 'common/core/interfaces/threads'
import { AccountStatus } from 'common/core/models/Account/constants'
import * as settingsActionTypes from 'common/settings/SettingsReduxActionTypes'
import { settingsRemoveNotification } from 'constants/API'
import * as API from 'constants/API'
import { WebSocketEvents } from 'constants/WebSockets'
import { handleError } from 'shared/api/lib/errors/handlers'
import { AccountApi } from 'shared/api/requests/account'
import { isLocal } from 'utils/isLocal'
import { analyticsService } from 'utils/services/analytics'
import errorTrackingService from 'utils/services/errorTrackingService'
import {
  anotherTabNotificationsListener,
  userNotificationsListener,
  accountNotificationsListener,
} from 'utils/services/notificationsService'
import { expandURL } from 'utils/url'

import { getUserId, getCurrentAccount } from '../selectors/appSelectors'

const checkVersionInterval = 10 * 60 * 1000
let checkVersionStarted = false

export const setProStatusBlocked = (data: AccountProStatusBlockedData) => ({
  type: atypes.SET_PRO_STATUS_BLOCKED,
  payload: data,
})

export const resetCurrentAccount = () => ({
  type: atypes.APP_RESET_CURRENT_ACCOUNT,
})

export const setProStatusUnblocked = (pro_status: AccountStatus) => ({
  type: atypes.SET_PRO_STATUS_UNBLOCKED,
  payload: pro_status,
})

export const setAppData = (initialData: Partial<ResponseData>): IActionWithPayload => ({
  type: atypes.APP_UPDATE,
  data: initialData,
})

export const updateThreadsStats = (data: ThreadsStatuses): IActionWithPayload => ({
  type: atypes.APP_UPDATE_THREADS_STATS,
  data,
})

export const updateConnectedIntegrations = (
  data: AccountUpdatedData['model'],
): IActionWithPayload => ({
  type: atypes.APP_UPDATE_CONNECTED_INTEGRATIONS,
  data,
})

export const updateCurrentAccountId = (accountId: AccountId) => ({
  type: atypes.APP_UPDATE_CURRENT_ACCOUNT_ID,
  accountId,
})

export const fetchExtendedAccountData = (): IAsyncThunkAction => async (dispatch) =>
  dispatch({
    type: atypes.APP_LOAD_EXTENDED_ACCOUNT_DATA_SUCCESS,
    $fetch: API.settings.endpoints.getPageExtendedData,
  })

export const ensureExtendedAccountData = (): IThunkAction => (dispatch, getState) => {
  if (getCurrentAccount(getState()).isExtendedDataLoaded) {
    return
  }

  dispatch(fetchExtendedAccountData())
}

export const startVersionChecker = (): IThunkAction => (dispatch) => {
  if (isLocal() || checkVersionStarted) {
    return
  }
  const versionFileEndpoint = '/sv.txt'

  const _checkVersion = async () => {
    try {
      await dispatch({
        type: atypes.STATIC_VERSION_FETCH,
        $fetch: [versionFileEndpoint, { credentials: 'same-origin' }],
        $responseType: 'text',
        $success: () => setTimeout(_checkVersion, checkVersionInterval),
        $error: () => setTimeout(_checkVersion, checkVersionInterval),
      })
    } catch (error) {
      errorTrackingService.trackError(error)
    }
  }

  checkVersionStarted = true
  _checkVersion()
}

export const setNotifications = (notifications: NotificationItem[]) => ({
  type: settingsActionTypes.SETTINGS_NOTIFICATIONS_SET,
  payload: notifications,
})

export const removeNotification =
  ({ notification_id, removeType }: NotificationItem): IThunkAction =>
  (dispatch) => {
    if (notification_id !== undefined) {
      analyticsService.sendEvent(`NOTIFICATION_BAR.CLOSED`, {
        notification_id,
      })
    }

    if (removeType === NotificationRemoveType.NEVER) return

    if (removeType === NotificationRemoveType.TEMPORARY) {
      return dispatch({
        type: settingsActionTypes.SETTINGS_NOTIFICATION_TEMPORARY_REMOVE,
        notification_id,
      })
    }

    return dispatch({
      type: settingsActionTypes.SETTINGS_NOTIFICATION_REMOVE,
      $fetch: expandURL(settingsRemoveNotification, { notification_id }),
      notification_id,
    })
  }

export const removeNotificationFromClosedList = (notificationId: StaticNotificationId | number) => {
  return {
    type: settingsActionTypes.SETTINGS_NOTIFICATION_REMOVE_FROM_CLOSED_LIST,
    notificationId,
  }
}

export const readAllPolicyEnforcementNotifications = (): AsyncActionType => ({
  type: atypes.READ_ALL_POLICY_ENFORCEMENT_NOTIFICATIONS_FETCH,
  $fetch: [
    API.settings.endpoints.readAllPolicyEnforcementNotifications,
    {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
    },
  ],
})

export const handleNewPolicyEnforcementNotifications = (
  data: AccountFbPolicyNotificationNewData,
) => ({
  type: atypes.NEW_POLICY_ENFORCEMENT_NOTIFICATION_NOTIFICATION,
  item: data.model,
})

export const handleReadPolicyEnforcementNotifications =
  (data: StandardWebSocketData): IThunkAction =>
  (dispatch, getState) => {
    if (getUserId(getState()) !== data.model.user_id) {
      return
    }
    dispatch({
      type: atypes.READ_POLICY_ENFORCEMENT_NOTIFICATION_NOTIFICATION,
    })
  }

export const handleHighlightLogsOnFbPolicyEnforcementNotification = () => ({
  type: atypes.CURRENT_ACCOUNT_USER_PROFILE_SET_DISPLAY_FLAGS_RESPONSE,
  display_flag: AccountUserDisplayFlagType.HIGHLIGHT_LOGS_ON_FB_POLICY_ENFORCEMENT,
  value: true,
})

export const setRnCreatedFlag = (channel: ChannelType) => ({
  type: atypes.ACCOUNT_SET_DISPLAY_FLAG_RESPONSE,
  display_flag:
    channel === ChannelType.FB
      ? AccountDisplayFlag.RN_DEFAULT_FLOW_CREATED
      : AccountDisplayFlag.IG_RN_DEFAULT_FLOW_CREATED,
  value: true,
})

export const forceResetStateForTesting = () => ({ type: atypes.FORCE_UPDATE_STATE })

// Persona API
// TODO: Images should be uploaded in single request and without use of /:currentAccountID/content/upload
export const uploadPersonaAvatar =
  (avatars: PersonaAvatar): IAsyncThunkAction =>
  async (dispatch) => {
    const formData = new FormData()

    if (avatars.sourceAvatar) {
      formData.set(String(0), avatars.sourceAvatar)

      await dispatch({
        type: settingsActionTypes.UPLOAD_PERSONA_SOURCE_AVATAR,
        $fetch: [API.uploadAttachment, { method: 'POST', body: formData }],
      })
    }

    if (avatars.croppedAvatar) {
      formData.set(String(0), avatars.croppedAvatar)

      await dispatch({
        type: settingsActionTypes.UPLOAD_PERSONA_CROPPED_AVATAR,
        $fetch: [API.uploadAttachment, { method: 'POST', body: formData }],
      })
    }
  }

export const savePersona = (
  name: string,
  srcAvatar: AvatarResizeCoordinates,
  croppedAvatar: string,
  avatarCropData: string,
): AsyncActionType => ({
  type: settingsActionTypes.SAVE_PERSONA,
  $fetch: [
    API.settings.endpoints.savePersona,
    {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        persona_api_avatar_data: {
          original_avatar_url: srcAvatar,
          resize_avatar_url: croppedAvatar,
          avatar_resize_coordinates: avatarCropData,
        },
        persona_api_name: name,
      }),
    },
  ],
  $error: (err) => alert(err, 'danger'),
  $success: () => alert(l.translate('LiveChat settings are saved'), 'success'),
})

//This request get 302 and redirect to /addFbAccount?account_id=***
export const connectFBChannel = (options = { returnURL: '' }): AsyncActionType => ({
  type: atypes.CONNECT_FB_CHANNEL,
  $fetch: [
    API.account.endpoints.connectFBChannel,
    {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(options.returnURL ? { return_url: options.returnURL } : {}),
    },
  ],
})

export const getListSuspectedFbPolicyOffenders = (): AsyncActionType => ({
  type: atypes.LIST_SUSPECTED_FB_POLICY_OFFENDERS_FETCH,
  $fetch: [
    API.accountHealth.endpoints.listSuspectedFbPolicyOffenders,
    {
      method: 'GET',
    },
  ],
})

export const updateSkipPreviewModal = (status: boolean) => ({
  type: UPDATE_SKIP_PREVIEW_ONBOARDING_MODAL,
  previewOnboardingModalStatus: status,
})

export const setAccountDisplayFlag = (
  display_flag: AccountDisplayFlag,
  value: boolean,
): IAsyncThunkAction => {
  return async (dispatch) => {
    dispatch({
      type: atypes.ACCOUNT_SET_DISPLAY_FLAG,
    })
    try {
      await AccountApi.setDisplayFlag({
        body: {
          display_flag,
          value,
        },
      })

      return dispatch({
        type: atypes.ACCOUNT_SET_DISPLAY_FLAG_RESPONSE,
        display_flag,
        value,
      })
    } catch (error) {
      handleError(error)
      return dispatch({
        type: atypes.ACCOUNT_SET_DISPLAY_FLAG_ERROR,
      })
    }
  }
}

export const updateAsyncBillingActionStatus =
  (data: AsyncBillingPaymentSocket): IThunkAction =>
  (dispatch, getState) => {
    const { user_id: userId, status, invoice_confirmation_url } = data.model

    const state = getState()
    const currentUserId = getUserId(state)

    if (currentUserId !== userId) return

    dispatch({
      type: atypes.CURRENT_ACCOUNT_UPDATE_ASYNC_BILLING_ACTIONS,
      status,
      url: invoice_confirmation_url,
    })
  }

anotherTabNotificationsListener.on('account_updated', (data: AccountUpdatedData, dispatch) => {
  if (data.model?.threads_statuses) {
    dispatch(updateThreadsStats(data.model.threads_statuses))
  }
  if (data.model.connected_integrations) {
    dispatch(updateConnectedIntegrations(data.model))
  }
})

anotherTabNotificationsListener.on(
  'account_pro_status_blocked',
  (data: AccountProStatusBlockedData, dispatch) => {
    dispatch(setProStatusBlocked(data))
  },
)

anotherTabNotificationsListener.on('account_pro_status_unblocked', ({ pro_status }, dispatch) => {
  if (pro_status && pro_status !== 'null') dispatch(setProStatusUnblocked(pro_status))
})

anotherTabNotificationsListener.on(
  'account_fb_policy_enforcement_notification_new',
  (data: AccountFbPolicyNotificationNewData, dispatch) => {
    dispatch(handleNewPolicyEnforcementNotifications(data))
  },
)

anotherTabNotificationsListener.on(
  'account_fb_policy_enforcement_notification_read',
  (data: StandardWebSocketData, dispatch) => {
    dispatch(handleReadPolicyEnforcementNotifications(data))
  },
)
anotherTabNotificationsListener.on(
  'highlight_logs_on_fb_policy_enforcement',
  (data: StandardWebSocketData, dispatch) => {
    dispatch(handleHighlightLogsOnFbPolicyEnforcementNotification())
  },
)

anotherTabNotificationsListener.on('rn_flow_created', (data, dispatch) => {
  dispatch(setRnCreatedFlag(data.model.channel))
})

anotherTabNotificationsListener.on(WebSocketEvents.FACEBOOK_MAIN_MENU_UNINSTALLATION_FAILED, () => {
  alert(
    l.translate("Your page connection is lost, and changes to Main Menu can't be saved"),
    'warning',
    { isTemporary: false },
  )
})

// TODO: Event don't send from backend, it will need to remove
userNotificationsListener.on('is_show_ig_change_account_error_notification', (data, dispatch) => {
  if (data.result) {
    dispatch(removeNotificationFromClosedList(StaticNotificationId.IG_CHANGE_ACCOUNT_TYPE_ERROR))
  }
  dispatch(UIActions.IGChangeAccountError(data.result))
})

accountNotificationsListener.on(WebSocketEvents.INVOICE_NEXT_ACTION_REQUIRED, (data, dispatch) => {
  dispatch(updateAsyncBillingActionStatus(data))
})

accountNotificationsListener.on(
  WebSocketEvents.WA_CHANNEL_DISCONNECT_COMPLETED,
  async (_, dispatch) => {
    const { payload: channelData } = await dispatch(getChannelData())
    dispatch({ type: atypes.CURRENT_ACCOUNT_UPDATE_WHATSAPP_CHANNEL, data: channelData })
  },
)

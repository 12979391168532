export enum NodeType {
  CONTENT = 'content',
  SMS = 'sms',
  EMAIL_NEW = 'email_new',
  WHATS_APP = 'whatsApp',
  INSTAGRAM = 'instagram',
  TELEGRAM = 'telegram',
  TIKTOK = 'tiktok',
  ACTION_GROUP = 'actionGroup',
  GOTO = 'goto',
  SMART_DELAY = 'smartDelay',
  CONDITION = 'condition',
  SPLIT = 'split',
  COMMENT = 'comment',
  AI_AGENT = 'agent',
  //CONTAINER
  STARTING_STEP = 'startingStep',
}

export const ChannelNodeTypes = [
  NodeType.INSTAGRAM,
  NodeType.WHATS_APP,
  NodeType.CONTENT,
  NodeType.TELEGRAM,
  NodeType.SMS,
  NodeType.EMAIL_NEW,
  NodeType.TIKTOK,
]

export const NonChannelNodeTypes = [
  NodeType.ACTION_GROUP,
  NodeType.GOTO,
  NodeType.SMART_DELAY,
  NodeType.CONDITION,
  NodeType.SPLIT,
  NodeType.AI_AGENT,
]

export type WhatsAppNodeType = 'content' | 'template'

export const CHOOSE_FIRST_STEP_NODE = 'chooseFirstStep'

import { l } from '@manychat/manyui'

export enum SettingsTabType {
  APPS = 'apps',
  AUTO_ASSIGNMENT = 'autoAssignment',
  BILLING = 'billing',
  CATALOG = 'catalog',
  CHAT = 'chat',
  CUSTOM_FIELDS = 'customFields',
  DISPLAY_PREFERENCES = 'displayPreferences',
  EMAIL = 'email',
  EMPTY = '',
  EVENTS = 'events',
  FB_POLICY_ENFORCEMENT_LOGS = 'fb_policy_enforcement_logs',
  GENERAL = 'general',
  WEBSITE_WIDGETS = 'websiteWidgets',
  GROUPS = 'groups',
  HANDOVER = 'handover',
  INSTAGRAM = 'instagram',
  INTEGRATIONS = 'integrations',
  LOGS = 'logs',
  MESSENGER = 'messenger',
  NOTIFICATIONS = 'notifications',
  PAYMENT = 'payment',
  PIXEL = 'pixel',
  PUBLIC_API = 'publicAPI',
  SMS = 'sms',
  TAGS = 'tags',
  TEAM_MEMBERS = 'teamMembers',
  TELEGRAM = 'telegram',
  TEMPLATES = 'templates',
  WHATS_APP = 'whatsApp',
  TIKTOK = 'tiktok',
  AI_CONTEXT = 'ai_context',
}

export const SettingsTabTypeLabel = {
  [SettingsTabType.GROUPS]: l.makeTranslate('Groups'),
  [SettingsTabType.AUTO_ASSIGNMENT]: l.makeTranslate('Auto-Assignment'),
}

export enum SettingsTabsGroupType {
  AUTOMATION = 'automation',
  CHANNELS = 'channels',
  EXTENSIONS = 'extensions',
  MAIN = 'main',
  INBOX = 'inbox',
}

export const SettingsTabsGroupLabel: Record<SettingsTabsGroupType, () => string> = {
  [SettingsTabsGroupType.AUTOMATION]: l.makeTranslate('Automation'),
  [SettingsTabsGroupType.CHANNELS]: l.makeTranslate('Channels'),
  [SettingsTabsGroupType.EXTENSIONS]: l.makeTranslate('Extensions'),
  [SettingsTabsGroupType.MAIN]: l.makeTranslate('Main'),
  [SettingsTabsGroupType.INBOX]: l.makeTranslate('Inbox'),
}

import React from 'react'
import cx from 'classnames'
import { useAppSelector } from 'reduxTyped'

import { FolderCard } from 'apps/cms/components/Flows/components/FolderCard/FolderCard'
import { FolderCardSkeleton } from 'apps/cms/components/Flows/components/FolderCard/FolderCardSkeleton'
import { NewFolderButton } from 'apps/cms/components/Flows/components/NewFolderButton/NewFolderButton'
import { contentManagementSelectors } from 'apps/cms/store'
import { usePermission, PermissionTargets } from 'common/userRoles'

import cm from './FoldersList.module.css'

export const FoldersList = React.memo(function FoldersList() {
  const userCanEditFlow = usePermission(PermissionTargets.FLOW_EDITING)
  const { allowCreateFolder } = useAppSelector(contentManagementSelectors.getUi)

  const folderPaths = useAppSelector(contentManagementSelectors.getFolderPathsByCurrentQueryPath)
  const { isLoading, isLoaded } = useAppSelector(contentManagementSelectors.getFoldersState)

  const isEmptyList = isLoaded && folderPaths.length === 0
  const canCreateFolder = userCanEditFlow && allowCreateFolder

  return (
    <div className={cx(cm.foldersList, { 'm-b-0': isEmptyList && !canCreateFolder })}>
      {isLoading && Array.from({ length: 4 }, (_, index) => <FolderCardSkeleton key={index} />)}

      {folderPaths.map((path) => (
        <FolderCard key={path} path={path} />
      ))}

      {isLoaded && canCreateFolder && <NewFolderButton />}
    </div>
  )
})

import { IFlowTrigger } from 'common/flow/flowInterfaces'
import { AiFlowDraft } from 'shared/api/requests/aiFlowGenerator/schemas'

export enum AssistantMessageAuthor {
  ASSISTANT = 'assistant',
  USER = 'user',
}

export enum AssistantMessageType {
  TEXT = 'text',
}

export interface AssistantTextMessage {
  id: string
  author: AssistantMessageAuthor
  step_id: string
  ts: number
  type: AssistantMessageType.TEXT
  data: {
    text: string
    buttons: string[]
  }
}

export type AssistantChatMessage = AssistantTextMessage

export enum QueueTask {
  WAIT_FOR_INITIALIZATION = 'wait_for_initialization',
  WAIT_FOR_MESSAGE = 'wait_for_message',
  WAIT_FOR_GENERATION = 'wait_for_generation',
}

export enum AssistantMode {
  SHOWCASE = 'showcase',
  CHAT = 'chat',
  REVIEW = 'review',
  RATE = 'rate',
}

type AiFlowDraftData = Omit<AiFlowDraft, 'triggers'> & { triggers: IFlowTrigger[] }

export interface NewAiAssistantState {
  mode: AssistantMode
  sessionId: string | null
  messages: AssistantTextMessage[]
  flowId: string | null
  draft: {
    id: number | null
    data: AiFlowDraftData | null
  }
  tasksQueue: QueueTask[]
  actionableMessageIds: string[]
  isGenerationFailed: boolean
}

export enum AiAssistantSocketEvent {
  MESSAGE_RECEIVED = 'ai_flow_generator_message',
  STATE_CHANGED = 'ai_flow_generator_state',
}

export enum AiAssistantState {
  FLOW_GENERATION_STARTED = 'flow_generation_started',
  FLOW_GENERATION_COMPLETED = 'flow_generation_completed',
  FLOW_GENERATION_FAILED = 'flow_generation_failed',
}

interface GenerationStartMessage {
  state: AiAssistantState.FLOW_GENERATION_STARTED
  session_id: string
}

interface GenerationCompleteMessage {
  state: AiAssistantState.FLOW_GENERATION_COMPLETED
  session_id: string
  flow_draft_id: number
}

interface GenerationFailMessage {
  state: AiAssistantState.FLOW_GENERATION_FAILED
  session_id: string
}

export type GenerationStateMessage =
  | GenerationStartMessage
  | GenerationCompleteMessage
  | GenerationFailMessage

import { l, TranslateData } from '@manychat/manyui'

import {
  WA_LIST_MESSAGE_MAX_BODY_LENGTH,
  WA_LIST_MESSAGE_MAX_BUTTON_LENGTH,
  WA_LIST_MESSAGE_MAX_DESCRIPTION_LENGTH,
  WA_LIST_MESSAGE_MAX_FOOTER_LENGTH,
  WA_LIST_MESSAGE_MAX_HEADER_LENGTH,
  WA_LIST_MESSAGE_MAX_OPTIONS,
  WA_LIST_MESSAGE_MAX_SECTIONS,
} from 'apps/whatsApp/constants/listMessageConstants'
import { IListMessageBlock } from 'common/builder/blocks/blockInterfaces'
import { WaListMessageButton } from 'common/builder/buttons/buttonInterfaces'
import ErrorMessages from 'common/builder/constants/Validation'

export interface ValidationError {
  prop: string
  message: string
  originalMessage: string
}

export function getWhatsAppListMessageButtonErrors(button: WaListMessageButton): ValidationError[] {
  const errors: ValidationError[] = []

  if (button.description && button.description.length > WA_LIST_MESSAGE_MAX_DESCRIPTION_LENGTH) {
    const errorMessage = ErrorMessages.WA_FIELD_MAX_LENGTH(
      "Option's description",
      WA_LIST_MESSAGE_MAX_DESCRIPTION_LENGTH,
    )
    errors.push(createValidationError('description', errorMessage))
  }

  if (button.targetId === null) {
    const errorMessage = ErrorMessages.WA_LIST_MESSAGE_OPTIONS_NO_TARGET
    errors.push(createValidationError('target', errorMessage))
  }

  return errors
}

export function getWhatsAppListMessageBlockErrors(block: IListMessageBlock): ValidationError[] {
  const errors: ValidationError[] = []

  if (
    block.content?.header?.text !== undefined &&
    block.content.header.text.length > WA_LIST_MESSAGE_MAX_HEADER_LENGTH
  ) {
    const errorMessage = ErrorMessages.WA_FIELD_MAX_LENGTH(
      'Header',
      WA_LIST_MESSAGE_MAX_HEADER_LENGTH,
    )
    errors.push(createValidationError('header', errorMessage))
  }

  if (!block.content.body || block.content.body?.trim() === '') {
    const errorMessage = ErrorMessages.WA_FIELD_EMPTY('Message body')
    errors.push(createValidationError('body', errorMessage))
  } else if (block.content.body.length > WA_LIST_MESSAGE_MAX_BODY_LENGTH) {
    const errorMessage = ErrorMessages.WA_FIELD_MAX_LENGTH(
      'Message body',
      WA_LIST_MESSAGE_MAX_BODY_LENGTH,
    )
    errors.push(createValidationError('body', errorMessage))
  }

  if (block.content?.footer && block.content.footer.length > WA_LIST_MESSAGE_MAX_FOOTER_LENGTH) {
    const errorMessage = ErrorMessages.WA_FIELD_MAX_LENGTH(
      'Footer',
      WA_LIST_MESSAGE_MAX_FOOTER_LENGTH,
    )
    errors.push(createValidationError('footer', errorMessage))
  }

  if (!block.content?.button || block.content?.button?.trim() === '') {
    const errorMessage = ErrorMessages.WA_FIELD_EMPTY('Button title')
    errors.push(createValidationError('button', errorMessage))
  } else if (block.content.button.length > WA_LIST_MESSAGE_MAX_BUTTON_LENGTH) {
    const errorMessage = ErrorMessages.WA_FIELD_MAX_LENGTH(
      'Button title',
      WA_LIST_MESSAGE_MAX_BUTTON_LENGTH,
    )
    errors.push(createValidationError('button', errorMessage))
  }

  if (!block.sections || block.sections.length === 0) {
    const errorMessage = ErrorMessages.WA_FIELD_EMPTY('Sections')
    errors.push(createValidationError('sections', errorMessage))
  } else {
    const sectionTitles = block.sections.map((section) => section.title || '')
    const isSomeTitleEmpty = sectionTitles.some((title) => title.trim() === '')

    if (isSomeTitleEmpty) {
      const errorMessage = ErrorMessages.WA_FIELD_EMPTY('Section titles')
      errors.push(createValidationError('sectionTitle', errorMessage))
    }

    if (block.sections.length > WA_LIST_MESSAGE_MAX_SECTIONS) {
      const errorMessage = ErrorMessages.WA_LIST_MESSAGE_SECTIONS_MAX_LENGTH(
        WA_LIST_MESSAGE_MAX_SECTIONS,
      )
      errors.push(createValidationError('sections', errorMessage))
    }

    const optionsLength = block.sections.map((sections) => sections.options.length)
    const isSectionHavingTooManyOptions = optionsLength.some(
      (optionLength) => optionLength > WA_LIST_MESSAGE_MAX_OPTIONS,
    )
    const isSectionEmpty = optionsLength.some((optionLength) => optionLength === 0)

    if (isSectionEmpty) {
      const errorMessage = ErrorMessages.WA_FIELD_EMPTY('Section options')
      errors.push(createValidationError('options', errorMessage))
    }
    if (isSectionHavingTooManyOptions) {
      const errorMessage = ErrorMessages.WA_LIST_MESSAGE_OPTIONS_MAX_LENGTH(
        WA_LIST_MESSAGE_MAX_OPTIONS,
      )
      errors.push(createValidationError('options', errorMessage))
    }
  }

  return errors
}

function createValidationError(
  prop: string,
  error: {
    (data?: TranslateData | undefined): string
    message: string
  },
): ValidationError {
  return {
    prop,
    message: l.getString(error),
    originalMessage: error.message,
  }
}

import { z } from 'zod'

import { ExternalTriggerStatusType } from 'apps/externalTriggers/externalTriggerConstants'
import { externalTriggerSchema } from 'shared/api/requests/cms/schemas/fsCommonSchema'

export const ExternalTriggerSchemas = {
  changeStatus: {
    response: z.object({
      external_trigger: externalTriggerSchema,
    }),
    request: z.object({
      external_trigger_id: z.number(),
      status: z.nativeEnum(ExternalTriggerStatusType),
    }),
    query: z.undefined(),
    path: z.undefined(),
  },
}

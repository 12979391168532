import { mergeData, setCurrentItem } from 'utils/factory'
import { makeCurrentItemReducer } from 'utils/factory/currentItem'

import * as atypes from '../smartSegmentsReduxActionTypes'

const currentItemReducer = makeCurrentItemReducer('smartSegments')

export default function currentSmartSegmentReducer(state, action) {
  state = currentItemReducer(state, action)
  switch (action.type) {
    case atypes.SMART_SEGMENTS_RENAME_SEGMENT_RESPONSE: {
      const {
        item: { title },
      } = action.data
      const item = mergeData(state.item, { title })
      return { ...state, item }
    }
    case atypes.SMARTSEGMENTS_CURRENT_UPDATEFILTER_RESPONSE: {
      const { item } = action.data
      return setCurrentItem(state, item)
    }
  }
  return state
}

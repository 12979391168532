import { getCookie, setCookie } from 'utils/cookie'
import errorTrackingService from 'utils/services/errorTrackingService'

export const getLangFromCookie = () => {
  const shortLang = getCookie('lang')
  if (!shortLang) {
    return undefined
  }

  const shortToLongMap: Record<string, string> = {
    en: 'en-US',
    pt: 'pt-BR',
    es: 'es-MX',
    ach: 'ach-UG',
  }

  const langLong: string = shortToLongMap[shortLang]

  if (!langLong) {
    errorTrackingService.trackError(`Unknown language to get[${shortLang}]`)
  }

  return langLong
}

export const setLangToCookie = (lang: string) => {
  const langToShortMap: Record<string, string> = {
    'en-US': 'en',
    'pt-BR': 'pt',
    'es-MX': 'es',
    'ach-UG': 'ach',
  }

  const langShort: string = langToShortMap[lang]

  if (langShort) {
    setCookie('lang', langShort)
  } else {
    errorTrackingService.trackError(`Unknown language to set[${lang}]`)
  }
}

import React, { useCallback, useRef, useState } from 'react'
import cx from 'classnames'
import { useAppDispatch, useAppSelector } from 'reduxTyped'
import { Popover, Scrollbars, Icon, l } from '@manychat/manyui'

import { TranslatedStatusFilterLabels } from 'apps/cms/pages/ContentManagementSystem/lib/filterLabels'
import { contentManagementActions, contentManagementSelectors } from 'apps/cms/store'
import { FlowTriggerStatus, FsTriggerStatusFilter } from 'shared/api/requests/cms/schemas'

import cm from './StatusPicker.module.css'

export const StatusPicker = () => {
  const dispatch = useAppDispatch()

  const { triggerStatusFilters } = useAppSelector(contentManagementSelectors.getFilters)
  const activeStatusFilter = useAppSelector(contentManagementSelectors.getActiveStatusFilter)
  const activeStatusFilterTitle = activeStatusFilter?.title ?? l.translate('Any Trigger states')
  const isActiveStatusChosen =
    activeStatusFilter?.filters.trigger_status === FlowTriggerStatus.ACTIVE

  const [isMenuOpened, setIsMenuOpened] = useState(false)
  const buttonRef = useRef<HTMLButtonElement | null>(null)

  const closeMenu = useCallback(() => {
    setIsMenuOpened(false)
  }, [])

  const selectFilter = ({ trigger_status }: FsTriggerStatusFilter['filters']) => {
    dispatch(
      contentManagementActions.updateQuery({
        triggerStatus: trigger_status === FlowTriggerStatus.DEFAULT ? null : trigger_status,
      }),
    )

    closeMenu()
  }

  return (
    <>
      <button
        ref={buttonRef}
        onClick={() => setIsMenuOpened(true)}
        className={cm.statusPickerAnchor}
      >
        <span className={cm.statusPickerAnchor__content}>
          <span
            className={cx('text-ellipsis', {
              'text-black': isActiveStatusChosen,
            })}
          >
            {activeStatusFilterTitle}
          </span>
        </span>

        <Icon.ChevronDown className="flex-shrink-0" size={20} />
      </button>

      <Popover
        at="bottom"
        popoverOrigin="top left"
        anchorOrigin="bottom left"
        anchor={buttonRef.current}
        open={isMenuOpened}
        onRequestClose={closeMenu}
        fitTargetWidth
        className={cm.popover}
      >
        <Scrollbars autoHeight autoHeightMin={72} autoHide={false}>
          <ul className={cm.statusList}>
            {triggerStatusFilters.map(({ filters, title }) => {
              const translatedTitle = l.getString(TranslatedStatusFilterLabels[title]) ?? title
              return (
                <li key={title} title={translatedTitle}>
                  <button
                    onClick={() => selectFilter(filters)}
                    className={cx(
                      cm.statusList__item,
                      activeStatusFilterTitle === title && cm.statusList__item_selected,
                    )}
                  >
                    <span className="text-ellipsis">{translatedTitle}</span>
                  </button>
                </li>
              )
            })}
          </ul>
        </Scrollbars>
      </Popover>
    </>
  )
}

import { useDrop, useDrag } from 'react-dnd'
import { getEmptyImage } from 'react-dnd-html5-backend'
import { useAppSelector } from 'reduxTyped'

import { CmsDragSource, FolderDragItem, FlowDragItem } from 'apps/cms/lib/types'
import { contentManagementSelectors } from 'apps/cms/store'
import { usePermission, PermissionTargets } from 'common/userRoles'
import { FsFolderObject } from 'shared/api/requests/cms/schemas'
import { useDidMountEffect } from 'utils/commonHooks'

export const useDnd = ({
  folder,
  onFolderDrop,
  onFlowsDrop,
}: {
  folder: FsFolderObject
  onFolderDrop: (path: string) => void
  onFlowsDrop: (paths: string[]) => void
}) => {
  const userCanEditFlow = usePermission(PermissionTargets.FLOW_EDITING)
  const selectedFlowIds = useAppSelector(contentManagementSelectors.getSelectedFlowIds)
  const { allowDragAndDrop } = useAppSelector(contentManagementSelectors.getUi)

  const [{ isDragging, highlightDrop }, connectDropTarget] = useDrop({
    accept: [CmsDragSource.FLOW_CARD, CmsDragSource.FOLDER_CARD],
    collect: (monitor) => {
      const draggingItem: FolderDragItem | FlowDragItem | null = monitor.getItem()

      let isSameFolder = false

      if (
        draggingItem &&
        draggingItem.type === CmsDragSource.FOLDER_CARD &&
        draggingItem.id === folder.folder_id
      ) {
        isSameFolder = true
      }

      return {
        isDragging: !isSameFolder && monitor.isOver(),
        highlightDrop: Boolean(draggingItem) && !isSameFolder,
      }
    },
    drop: (item: FlowDragItem | FolderDragItem) => {
      if (item.type === CmsDragSource.FOLDER_CARD) {
        if (item.id === folder.folder_id) {
          return
        }

        onFolderDrop(item.path)
        return
      }

      if (selectedFlowIds.length > 1) {
        onFlowsDrop(selectedFlowIds)
      } else {
        onFlowsDrop([item.path])
      }
    },
  })

  const [, connectDragSource, connectDragPreview] = useDrag({
    item: {
      type: CmsDragSource.FOLDER_CARD,
      path: folder.path,
      id: folder.folder_id,
      name: folder.title,
    } as FolderDragItem,
    type: CmsDragSource.FOLDER_CARD,
    canDrag: () => userCanEditFlow && allowDragAndDrop,
  })

  useDidMountEffect(() => {
    connectDragPreview(getEmptyImage(), { captureDraggingState: true, anchorX: 1, anchorY: 1 })
  })

  return {
    isDragging,
    highlightDrop,
    connectDropTarget,
    connectDragSource,
  }
}

export const TAG_CREATED_NOTIFICATION = 'TAG_CREATED_NOTIFICATION'
export const TAG_REMOVED_NOTIFICATION = 'TAG_REMOVED_NOTIFICATION'

export const TAGS_FETCH = 'TAGS_FETCH'
export const TAGS_FETCH_REQUEST = 'TAGS_FETCH_REQUEST'
export const TAGS_FETCH_RESPONSE = 'TAGS_FETCH_RESPONSE'
export const TAGS_FETCH_ERROR = 'TAGS_FETCH_ERROR'

export const TAGS_CREATE = 'TAGS_CREATE'
export const TAGS_CREATE_REQUEST = 'TAGS_CREATE_REQUEST'
export const TAGS_CREATE_RESPONSE = 'TAGS_CREATE_RESPONSE'
export const TAGS_CREATE_ERROR = 'TAGS_CREATE_ERROR'
export const TAGS_GET_ENTITIES_LIST = 'TAGS_GET_ENTITIES_LIST'
export const TAGS_BULK_DELETE_RESPONSE = 'TAGS_BULKDELETE_RESPONSE'
export const TAGS_BULK_DELETE_FIELDS_NOTIFICATION = 'TAGS_BULK_DELETE_FIELDS_NOTIFICATION'

export const TAGS_CREATE_MODAL_OPEN = 'TAGS_CREATE_MODAL_OPEN'
export const TAGS_CREATE_MODAL_CLOSE = 'TAGS_CREATE_MODAL_CLOSE'

export const TAGS_MOVE_TO_REQUEST = 'TAGS_MOVE_TO_REQUEST'
export const TAGS_MOVE_TO_RESPONSE = 'TAGS_MOVE_TO_RESPONSE'
export const TAGS_MOVE_TO_ERROR = 'TAGS_MOVE_TO_ERROR'

export const TAGS_SEARCH_VALUE_CHANGE = 'TAGS_SEARCH_VALUE_CHANGE'

export const TAGS_FETCH_TOTAL_COUNT_REQUEST = 'TAGS_FETCH_TOTAL_COUNT_REQUEST'
export const TAGS_FETCH_TOTAL_COUNT_RESPONSE = 'TAGS_FETCH_TOTAL_COUNT_RESPONSE'
export const TAGS_FETCH_TOTAL_COUNT_ERROR = 'TAGS_FETCH_TOTAL_COUNT_ERROR'

export const TAGS_SET_SELECTED_IDS = 'TAGS_SET_SELECTED_IDS'
export const TAGS_RESET_SELECTED_IDS = 'TAGS_RESET_SELECTED_IDS'

import { BulkUsesListResponse, UsesList } from 'shared/api/requests/cms/schemas'

export const mergeEntities = (data: BulkUsesListResponse) => {
  const result: UsesList = {
    default_reply: [],
    flows: [],
    follow_ups: [],
    welcome_message: [],
    growth_tools: [],
    icebreakers: [],
    integrations: [],
    keywords_rules: [],
    sequences: [],
    story_mention_reply: [],
    trigger_rules: [],
    intent_rules: [],
    external_triggers: [],
  }

  data.entities.forEach((entity) => {
    Object.entries(entity.entities).forEach(([key, value]) => {
      if (result[key as keyof UsesList]) {
        result[key as keyof UsesList]?.push(...value)
      }
    })
  })

  return result
}

import {
  CHATGPT_TOKENS_MIN,
  CHATGPT_TOKENS_MAX,
  CHATGPT_TEMPERATURE_MIN,
  CHATGPT_TEMPERATURE_MAX,
} from './requestToChatGPTConstants'

export const isMaxTokensInvalid = (value: unknown) => {
  return (
    typeof value !== 'number' ||
    Number.isNaN(value) ||
    value < CHATGPT_TOKENS_MIN ||
    value > CHATGPT_TOKENS_MAX
  )
}

export const isTemperatureInvalid = (value: unknown) => {
  return (
    typeof value !== 'number' ||
    Number.isNaN(value) ||
    value < CHATGPT_TEMPERATURE_MIN ||
    value > CHATGPT_TEMPERATURE_MAX
  )
}

import without from 'lodash/without'

import { linkBlock } from 'common/builder/actions/linkBlock'
import { reorderBlocks } from 'common/builder/actions/reorderBlocks'
import nodeRegistry from 'common/builder/nodeRegistry'
import {
  getNodeById,
  getBlocks,
  getBlockById,
} from 'common/builder/selectors/builder/entitySelectors'

export const moveBlock = (builderId, nodeId, dragId, hoverId, options = {}) => {
  return (dispatch, getState) => {
    const state = getState()

    const node = getNodeById(state, builderId, nodeId)
    const blocks = getBlocks(state, builderId, nodeId)
    const currentOrder = blocks.map((block) => block.id)

    // dragId is moved from toolbar
    const isDragIdExistsInCurrentOrder = currentOrder.includes(dragId)
    if (!isDragIdExistsInCurrentOrder) {
      const dragBlock = getBlockById(state, builderId, dragId)

      if (!nodeRegistry.get(node).isBlockTypeAllowed({ node, block: dragBlock })) {
        return
      }

      if (!nodeRegistry.get(node).isAddBlockAllowed({ node, block: dragBlock })) {
        return
      }

      dispatch(linkBlock(builderId, nodeId, dragId, { relink: true }))
    }

    // put dragId block in place of hoverId block
    const hoverIndex = currentOrder.indexOf(hoverId) + (options.insertNewAfter ? 1 : 0)
    const order = without(currentOrder, dragId)
    order.splice(hoverIndex, 0, dragId)

    if (!options.noCommit) {
      dispatch(reorderBlocks(builderId, nodeId, order))
    }
    return order
  }
}

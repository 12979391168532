import { Children, createElement } from 'react'
import { z } from 'zod'

import { BlockType } from '../../constants'
import { registry } from '../../registry'
import { ChildrenSchema, ClassNameSchema, StylesSchema } from '../../schemas'
import { BlockProps } from '../../types'

const TextPropsSchema = z.object({
  className: ClassNameSchema,
  // z.object here is for translations w/ html inside like
  // l.translate('<b>bold</b> text')
  text: z.string().or(z.object({})),
  style: StylesSchema,
  as: z.string().optional(),
  children: ChildrenSchema.optional(),
})

const Component = ({
  className,
  text,
  style,
  as = 'p',
  children,
  blockId,
}: BlockProps<typeof TextPropsSchema>) => {
  const mixedChildren = [text as string, ...Children.toArray(children)]

  return createElement(as, { className, style, ['data-block']: blockId }, mixedChildren)
}

export const Text = registry.register(Component, {
  props: TextPropsSchema,
  type: BlockType.TEXT,
  previewProps: {
    text: 'Lorem ipsum dolar',
  },
})

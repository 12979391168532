import React, { ReactNode } from 'react'

import { getCurrentTime } from './lib/utils'
import batterySvg from './svg/battery.svg'
import cellularSvg from './svg/cellular.svg'
import wifiSvg from './svg/wifi.svg'

import cm from './PhonePreviewFrame.module.css'

interface PhonePreviewFrameProps {
  children: ReactNode
}

export function PhonePreviewFrame({ children }: PhonePreviewFrameProps) {
  return (
    <div className={cm.container} data-test-id="phone-preview">
      <div className={cm.deviceFrame}>
        <div className={cm.deviceScreen}>
          <div className={cm.deviceHeader}>
            <div className={cm.deviceTime}>{getCurrentTime()}</div>
            <div className={cm.deviceSpeaker} />
            <div className={cm.deviceStatusBar}>
              <img src={cellularSvg} alt="cellular" />
              <img src={wifiSvg} alt="wifi" />
              <img src={batterySvg} alt="battery" />
            </div>
          </div>
          {children}
        </div>
      </div>
    </div>
  )
}

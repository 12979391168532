import actionTypes from 'apps/applicationInstallation/constants/ReduxActionTypes'
import { IActionWithPayload } from 'common/core/interfaces/actions'

import { WizardSteps } from '../constants/constants'

const initialState: WizardSteps = WizardSteps.description

const stepReducer = (
  state = initialState,
  action: IActionWithPayload<WizardSteps>,
): WizardSteps => {
  if (action.type === actionTypes.APPLICATION_INSTALL_SET_STEP) {
    return action.data
  }

  return state
}

export default stepReducer

import { api } from 'shared/api'
import { AiIntentsSchemas } from 'shared/api/requests/aiIntents/schemas'

export const AiIntentsApi = {
  getAiIntent: api.account.createGet({
    url: '/intent/get',
    schemas: AiIntentsSchemas.get,
  }),
  createAiIntent: api.account.createPost({
    url: '/intent/create',
    schemas: AiIntentsSchemas.create,
  }),
  deleteAiIntent: api.account.createPost({
    url: '/intent/delete',
    schemas: AiIntentsSchemas.delete,
  }),
  updateAiIntentStatus: api.account.createPost({
    url: '/intent/updateStatus',
    schemas: AiIntentsSchemas.updateStatus,
  }),
  updateAiIntent: api.account.createPost({
    url: '/intent/updateIntent',
    schemas: AiIntentsSchemas.update,
  }),
}

import { useState, useCallback } from 'react'

interface IUseTelegramQrModalConfig {
  onOpen?: () => void
  onClose?: () => void
}

export const useTelegramQrModal = ({ onOpen, onClose }: IUseTelegramQrModalConfig = {}) => {
  const [isModalVisible, setIsModalVisible] = useState(false)

  const openModal = useCallback(() => {
    onOpen?.()
    setIsModalVisible(true)
  }, [onOpen])

  const closeModal = useCallback(() => {
    onClose?.()
    setIsModalVisible(false)
  }, [onClose])

  return {
    isTelegramQrModalVisible: isModalVisible,
    openModal,
    closeModal,
  }
}

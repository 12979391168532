import React, { useCallback } from 'react'
import cx from 'classnames'
import { useAppSelector } from 'reduxTyped'
import { Popover, l, Link } from '@manychat/manyui'

import EmailVerificationModal from 'common/core/components/EmailVerification/EmailVerificationModal'
import ManageProfilesModal from 'common/core/components/ManageProfilesModal'
import { getUser } from 'common/core/selectors/appSelectors'
import { CircleAvatar } from 'components/CircleAvatar'
import { ExternalPaths } from 'utils/router/constants'

import { EditEmailModal } from './components/EditEmailModal/EditEmailModal'
import { LanguageSelect } from './components/LanguageSelect/LanguageSelect'
import { ProfileSections } from './components/ProfileSections/ProfileSections'
import { SignInOptions } from './components/SignInOptions/SignInOptions'
import { VerifyEmailWarning } from './components/VerifyEmailWarning/VerifyEmailWarning'
import { useAgencyData } from './hooks/useAgencyData'
import { MyProfilePopoverAnalytics } from './lib/analytics'

import cm from './MyProfileLinkPopover.module.css'

interface MyProfileLinkPopoverProps {
  onClose: () => void
  anchor: HTMLElement | null
  isOpen: boolean
}

export const MyProfileLinkPopover = ({ onClose, anchor, isOpen }: MyProfileLinkPopoverProps) => {
  const [isManageProfilesModalOpen, setIsManageProfilesModalOpen] = React.useState(false)
  const [isEditEmailModalOpen, setIsEditEmailModalOpen] = React.useState(false)
  const [isVerificationModalOpen, setIsVerificationModalOpen] = React.useState(false)
  const isPopoverShown = isOpen && Boolean(anchor)
  const { email, email_unverified, full_name, avatar_large } = useAppSelector(getUser)
  const userEmail = email_unverified || email
  const { agencyId } = useAgencyData()

  const handleEditEmailClick = useCallback(() => {
    MyProfilePopoverAnalytics.logEditEmailClick()
    onClose()
    setIsEditEmailModalOpen(true)
  }, [onClose])

  const closeEditEmailModal = useCallback(() => {
    setIsEditEmailModalOpen(false)
  }, [])

  const openManageProfilesModal = useCallback(() => {
    MyProfilePopoverAnalytics.logOpenManageSignInModal()
    onClose()
    setIsManageProfilesModalOpen(true)
  }, [onClose])

  const closeManageProfilesModal = useCallback(() => {
    setIsManageProfilesModalOpen(false)
  }, [])

  const openVerificationModal = useCallback(() => {
    onClose()
    setIsVerificationModalOpen(true)
  }, [onClose])

  const closeVerificationModal = useCallback(() => {
    setIsVerificationModalOpen(false)
  }, [])

  const handleLogOutClick = () => {
    MyProfilePopoverAnalytics.logLogOutClick()
    onClose()
    window.location.href = ExternalPaths.LOGOUT
  }

  return (
    <>
      <Popover
        className={cm.wrapper}
        anchorOrigin="right center"
        popoverOrigin="left 90%"
        arrow="right"
        open={isPopoverShown}
        anchor={anchor}
        onRequestClose={onClose}
      >
        <div id="my-profile-link-container">
          <div className="d-flex p-x-md p-t-md p-b-xs">
            <CircleAvatar className="m-r bg-default" size={56} url={avatar_large} />
            <div>
              <div title={full_name} className={cx(cm.fullName, 'text-heading-2 text-ellipsis')}>
                {full_name}
              </div>
              {userEmail && (
                <Link
                  contentColor="subtle"
                  variant="small"
                  rightIcon="Edit"
                  className={cm.emailText}
                  underline
                  title={userEmail}
                  onClick={handleEditEmailClick}
                >
                  {userEmail}
                </Link>
              )}
            </div>
          </div>
          <VerifyEmailWarning onOpenModal={openVerificationModal} />
          <ProfileSections agencyId={agencyId} />
        </div>
        <LanguageSelect />
        <div className="p-y-xs">
          <div
            className="d-flex p-x-md p-y-sm align-center justify-between bg-hover text-sm c-pointer"
            onClick={openManageProfilesModal}
          >
            <span className="m-r-sm">{l.translate('Add sign-in options')}</span>
            <SignInOptions />
          </div>
          <a
            onClick={handleLogOutClick}
            className="d-flex p-x-md p-y-sm bg-hover text-sm c-pointer"
          >
            {l.translate('Log out')}
          </a>
        </div>
      </Popover>
      {userEmail && (
        <>
          <EditEmailModal
            email={userEmail}
            isOpen={isEditEmailModalOpen}
            onClose={closeEditEmailModal}
            onUpdate={openVerificationModal}
          />
        </>
      )}
      <ManageProfilesModal
        handleClose={closeManageProfilesModal}
        isOpen={isManageProfilesModalOpen}
      />
      <EmailVerificationModal open={isVerificationModalOpen} onClose={closeVerificationModal} />
    </>
  )
}

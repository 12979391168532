import dot from 'dot-prop-immutable'
import keyBy from 'lodash/keyBy'
import omitBy from 'lodash/omitBy'

import * as atypes from 'apps/ads/constants/AdsReduxActionTypes'
import {
  parseCampaign,
  parseAdSetDraft,
  parseAdSet,
  parseAdgt,
  parseAd,
} from 'apps/ads/models/AdData/adapter'

const initialState = {
  growthTools: {},
  ads: {},
  adSetDrafts: {},
  adSets: {},
  accounts: {},
  campaigns: {},
}

export default function adsDataReducer(state = initialState, action) {
  switch (action.type) {
    case atypes.AD_TABLE_DATA_FETCH_RESPONSE:
    case atypes.AD_FETCH_ALL_ACCOUNTS_RESPONSE:
    case atypes.AD_FETCH_ALL_CAMPAIGNS_RESPONSE:
    case atypes.AD_FETCH_ALL_AD_SETS_RESPONSE:
    case atypes.AD_TABLE_CAMPAIGN_TREE_FETCH_RESPONSE: {
      if (action.growthTools) {
        state.growthTools = { ...state.growthTools, ...keyBy(action.growthTools, 'id') }
      }
      if (action.ads) {
        state.ads = { ...state.ads, ...keyBy(action.ads, 'id') }
      }
      if (action.adSetDrafts) {
        state.adSetDrafts = { ...state.adSetDrafts, ...keyBy(action.adSetDrafts, 'id') }
      }
      if (action.adSets) {
        state.adSets = { ...state.adSets, ...keyBy(action.adSets, 'id') }
      }
      if (action.accounts) {
        state.accounts = { ...state.accounts, ...keyBy(action.accounts, 'id') }
      }
      if (action.campaigns) {
        state.campaigns = { ...state.campaigns, ...keyBy(action.campaigns, 'id') }
      }
      return state
    }

    // CAMPAIGN
    case atypes.CAMPAIGN_SAVE_RESPONSE:
    case atypes.CAMPAIGN_CREATE_RESPONSE:
    case atypes.CAMPAIGN_UPDATE_STATUS_RESPONSE: {
      const campaign = parseCampaign(action.data.campaign)
      state.campaigns = { ...state.campaigns, [campaign.id]: campaign }
      return state
    }
    case atypes.CAMPAIGN_DELETE_RESPONSE: {
      const { campaignId } = action
      return {
        ...state,
        campaigns: omitBy(state.campaigns, (item) => item.id === campaignId),
        adSets: omitBy(state.adSets, (item) => item.campaignId === campaignId),
        adSetDrafts: omitBy(state.adSetDrafts, (item) => item.campaignId === campaignId),
        ads: omitBy(state.ads, (item) => item.campaignId === campaignId),
        growthTools: omitBy(state.growthTools, (item) => item.campaignId === campaignId),
      }
    }

    // ADSET
    case atypes.ADSET_SAVE_RESPONSE: {
      const adSetDraft = state.adSetDrafts[action.draftId]
      if (adSetDraft) {
        return dot.set(state, `adSetDrafts.${adSetDraft.id}.draft`, action.draft)
      }
      return state
    }
    case atypes.ADSET_PUBLISH_RESPONSE:
    case atypes.ADSET_DISCARD_DRAFT_RESPONSE:
    case atypes.ADSET_CREATE_RESPONSE:
    case atypes.ADSET_FETCH_RESPONSE: {
      if (action.data.data) {
        const adSetDraft = parseAdSetDraft(action.data.data)
        state = dot.set(state, `adSetDrafts.${adSetDraft.id}`, adSetDraft)
      }
      if (action.data.ad_set) {
        const adSet = parseAdSet(action.data.ad_set)
        state = dot.set(state, `adSets.${adSet.id}`, adSet)
      }
      return state
    }
    case atypes.ADSET_DELETE_RESPONSE: {
      const { draftId, adSetId } = action
      return {
        ...state,
        adSets: omitBy(state.adSets, (item) => item.id === adSetId),
        adSetDrafts: omitBy(state.adSetDrafts, (item) => item.id === draftId),
        ads: omitBy(state.ads, (item) => item.adSetId === adSetId),
        growthTools: omitBy(state.growthTools, (item) => item.adSetId === adSetId),
      }
    }
    case atypes.ADSET_UPDATE_STATUS_RESPONSE: {
      const adSets = state.adSets[action.adSetId]
      if (adSets) {
        return dot.set(state, `adSets.${action.adSetId}.status`, action.status)
      }
      return state
    }

    // GROWTH TOOL
    case atypes.AD_CONSTRUCTOR_FETCH_RESPONSE:
    case atypes.AD_FETCH_RESPONSE:
    case atypes.AD_CREATE_NOTIFICATION: {
      const adgt = parseAdgt(action.data.data)
      state.growthTools = { ...state.growthTools, [adgt.id]: adgt }
      return state
    }
    case atypes.AD_CONSTRUCTOR_DISCARD_CHANGES_RESPONSE:
    case atypes.AD_CONSTRUCTOR_PUBLISH_RESPONSE: {
      const adgt = parseAdgt(action.adgt)
      state.growthTools = { ...state.growthTools, [adgt.id]: adgt }
      const ad = parseAd(action.ad)
      state.ads = { ...state.ads, [ad.id]: ad }
      return state
    }
    case atypes.AD_CONSTRUCTOR_AUTO_SAVE_SUCCESS: {
      const adgt = state.growthTools[action.adgtId]
      if (adgt) {
        return dot.set(state, `growthTools.${adgt.id}.draft`, action.draft)
      }
      return state
    }
    case atypes.AD_DELETE_RESPONSE: {
      const { adId, adgtId } = action
      return {
        ...state,
        ads: omitBy(state.ads, (item) => item.id === adId),
        growthTools: omitBy(state.growthTools, (item) => item.id === adgtId),
      }
    }
    case atypes.AD_UPDATE_STATUS_RESPONSE: {
      const ad = parseAd(action.data.ad)
      state.ads = { ...state.ads, [ad.id]: ad }
      return state
    }
    case atypes.AD_CONSTRUCTOR_UPDATE_TITLE_RESPONSE: {
      const adgt = state.growthTools[action.adgtId]
      if (adgt) {
        return dot.set(state, `growthTools.${adgt.id}.name`, action.title)
      }
      return state
    }
  }

  return state
}

import React, { useCallback, useMemo } from 'react'
import cx from 'classnames'
import { z } from 'zod'
import { l, Link, Skeleton } from '@manychat/manyui'

import { BlockType } from '../../constants'
import { useBillingInfo, useDefaultContext } from '../../hooks'
import { registry } from '../../registry'

import cm from './TiersPreview.module.css'

function pickKNearest<T>(array: T[], startIdx: number, k: number): T[] {
  const result: T[] = []
  let left = startIdx
  let right = startIdx + 1
  const isValidIdx = (idx: number) => 0 <= idx && idx <= array.length - 1

  while (result.length < k && (isValidIdx(left) || isValidIdx(right))) {
    if (isValidIdx(left)) {
      result.unshift(array[left])
    }
    if (isValidIdx(right)) {
      result.push(array[right])
    }

    left--
    right++
  }

  return result
}

const TiersPreviewPropsSchema = z.object({
  numToPreview: z.number().positive().optional(),
})

const Component = ({ numToPreview = 3 }: z.infer<typeof TiersPreviewPropsSchema>) => {
  const {
    tiers: { all, current },
    currency,
  } = useBillingInfo()
  const { showTiers } = useDefaultContext()

  const getTierKey = useCallback((tier: typeof current | null, index: number) => {
    if (!tier) {
      return `null-${index}`
    }

    return `${tier.min}-${tier.max}`
  }, [])

  const tiersToPreview = useMemo(
    () => pickKNearest(all, current ? all.indexOf(current) : 0, numToPreview),
    [all, current, numToPreview],
  )
  const skeletonData = useMemo(() => new Array(numToPreview).fill(null), [numToPreview])

  return (
    <>
      <ul
        className={cx(
          cm.root,
          'd-flex flex-col gap-sm background-neutral b-a-0 rounded p-x-md p-y m-t m-b-md bg-default',
        )}
      >
        <div>
          <p className="text-strong">{l.translate('Each month')}</p>
          <p className="text-secondary m-t-xxs">
            {l.translate('Price based on the number of contacts you have:')}
          </p>
        </div>
        {[...tiersToPreview, ...skeletonData]
          .slice(0, numToPreview)
          .map((tier: typeof current | null, index) => (
            <li key={getTierKey(tier, index)} className={cx('d-flex flex-row justify-between')}>
              <div>
                {tier ? (
                  l.translate('{min} – {max}', {
                    min: tier.min,
                    max: tier.max,
                  })
                ) : (
                  <Skeleton.Text height={20} lines={1} />
                )}
              </div>

              {tier ? (
                <p className="m-t-0">
                  {l.translate('{price}/month', {
                    price: l.currency(tier.price, currency, 0),
                  })}
                </p>
              ) : (
                <Skeleton.Text height={20} lines={1} />
              )}
            </li>
          ))}

        <Link onClick={() => showTiers()} className={cx('p-y-0 m-r-auto')}>
          {l.translate('See All Tiers')}
        </Link>
      </ul>
    </>
  )
}

export const TiersPreview = registry.register(Component, {
  props: TiersPreviewPropsSchema,
  type: BlockType.TIERS_PREVIEW,
})

import React from 'react'
import QRCodeImpl from 'qr.js/lib/QRCode'

import { DEFAULT_PROPS, ERROR_CORRECT_LEVEL, MARGIN_SIZE, PROP_TYPES } from './constants'
import { convertStr, generatePath } from './helpers'

export default class QRCodeSVG extends React.PureComponent {
  static propTypes = PROP_TYPES

  static defaultProps = DEFAULT_PROPS

  render() {
    const { value, size, level, bgColor, fgColor, includeMargin, ...otherProps } = this.props

    // We'll use type===-1 to force QRCode to automatically pick the best type
    const qrcode = new QRCodeImpl(-1, ERROR_CORRECT_LEVEL[level])
    qrcode.addData(convertStr(value))
    qrcode.make()

    const cells = qrcode.modules
    if (cells === null) {
      return null
    }

    const margin = includeMargin ? MARGIN_SIZE : 0

    // Drawing strategy: instead of a rect per module, we're going to create a
    // single path for the dark modules and layer that on top of a light rect,
    // for a total of 2 DOM nodes. We pay a bit more in string concat but that's
    // way faster than DOM ops.
    // For level 1, 441 nodes -> 2
    // For level 40, 31329 -> 2
    const fgPath = generatePath(cells, margin)

    const numCells = cells.length + margin * 2

    return (
      <svg
        shapeRendering="crispEdges"
        height={size}
        width={size}
        viewBox={`0 0 ${numCells} ${numCells}`}
        {...otherProps}
      >
        <path fill={bgColor} d={`M0,0 h${numCells}v${numCells}H0z`} />
        <path fill={fgColor} d={fgPath} />
      </svg>
    )
  }
}

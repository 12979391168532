import debugConstructor from 'debug'
import omit from 'lodash/omit'
import { v4 as uuid } from 'uuid'

import {
  UserFieldsNumberOperations,
  UserFieldsMutatingOperationSet,
  UserFieldsDateOperationsSet,
  UserFieldsDateTimeOperationsSet,
  OperationToTypeMapping,
  DateTimeOperationToTypeMapping,
  UserFieldsDateOperations,
} from 'common/fields/constants/UserFieldsTypes'
import { UserFieldDateTypes } from 'common/filter/models/AudienceFilter/constants'

import {
  IntegrationActionTypeMap,
  INTEGRATION_REQUEST,
  IntegrationActionTypes,
  ActionTypes,
} from './constants'
import {
  isIntegrationRequest,
  isDateOnly,
  isDateTime,
  isDateFormula,
  isDateTimeFormula,
  isFormulaContainsOffset,
  isDateTimeOfTheAction,
  isDateOfTheAction,
} from './helpers'

const debug = debugConstructor('actions')

export const parseActions = (actions = []) => {
  const parsed = actions.map(parseActionItem)
  debug('parseActions', actions, '=>', parsed)
  return parsed
}

export const exportActions = (actions = []) => {
  const exported = actions.map(exportActionItem)
  debug('exportActions', actions, '=>', exported)
  return exported
}

export const parseActionItem = (action) => {
  const { _oid, ...rest } = action

  action = { ...rest, _oid, id: _oid || uuid() }

  if (action.type === INTEGRATION_REQUEST) {
    const type = IntegrationActionTypeMap[action.integration]
    return { ...action, type }
  }
  if (action.type === ActionTypes.SET_CUF || action.type === ActionTypes.SET_GAF) {
    const actionWithParseValue = { ...action, value: parseSetFieldValue(action) }
    return {
      ...actionWithParseValue,
      operation: parseSetFieldOperation(actionWithParseValue),
    }
  }
  if (action.type === ActionTypes.SEND_EVENT_TO_CAPI) {
    if (Array.isArray(action?.custom_data)) {
      return { ...action, custom_data: {} }
    }
  }
  return action
}

export const exportActionItem = (action) => {
  const { id, ...rest } = action
  action = { ...rest, _oid: id || uuid() }

  if (isIntegrationRequest(action.type)) {
    const integration = IntegrationActionTypes[action.type]
    return { ...action, type: INTEGRATION_REQUEST, integration }
  }

  if (action.type === ActionTypes.APPS_REQUEST) {
    return { ...action, type: ActionTypes.APPS_REQUEST }
  }

  if (action.type === ActionTypes.SET_GAF || action.type === ActionTypes.SET_CUF) {
    let result = {
      ...action,
      value: exportSetFieldValue(action),
    }

    const isFormula = [
      UserFieldsDateOperationsSet.SET_DATE_OF_THE_ACTION,
      UserFieldsDateOperationsSet.SET_TO_DATE_CUF,
      UserFieldsDateTimeOperationsSet.SET_DATE_TIME_OF_THE_ACTION,
      UserFieldsDateTimeOperationsSet.SET_TO_DATE_TIME_CUF,
    ].includes(action.operation)

    if (isFormula) {
      result = { ...result, [UserFieldDateTypes.FORMULA]: true }
    } else {
      result = omit(result, UserFieldDateTypes.FORMULA)
    }

    const isDateOperation = Object.values(UserFieldsDateOperationsSet).includes(action.operation)
    if (isDateOperation) {
      result = { ...result, operation: OperationToTypeMapping[action.operation] }
    }

    const isDateTimeOperation = Object.values(UserFieldsDateTimeOperationsSet).includes(
      action.operation,
    )
    if (isDateTimeOperation) {
      result = { ...result, operation: DateTimeOperationToTypeMapping[action.operation] }
    }

    if (isFormulaContainsOffset(result.value)) {
      result = { ...result, operation: UserFieldsDateOperations.SET }
    }

    return result
  }
  return action
}

export const parseSetFieldOperation = (action) => {
  const isSetCUFAction =
    action.value &&
    action.operation &&
    [ActionTypes.SET_CUF, ActionTypes.SET_GAF].includes(action.type)

  if (isSetCUFAction) {
    if (isDateOfTheAction(action.value)) {
      return UserFieldsDateOperationsSet.SET_DATE_OF_THE_ACTION
    }

    if (isDateFormula(action.value)) {
      if (isFormulaContainsOffset(action.value) && isDateOfTheAction(action.value)) {
        return UserFieldsDateOperationsSet.SET_DATE_OF_THE_ACTION
      }

      return UserFieldsDateOperationsSet.SET_TO_DATE_CUF
    }

    const isDateInput = isDateOnly(action.value)
    if (isDateInput) {
      return UserFieldsDateOperationsSet.SET_SPECIFIC_DATE
    }

    if (isDateTimeOfTheAction(action.value)) {
      return UserFieldsDateTimeOperationsSet.SET_DATE_TIME_OF_THE_ACTION
    }

    if (isDateTimeFormula(action.value)) {
      if (isFormulaContainsOffset(action.value) && isDateTimeOfTheAction(action.value)) {
        return UserFieldsDateTimeOperationsSet.SET_DATE_TIME_OF_THE_ACTION
      }
      return UserFieldsDateTimeOperationsSet.SET_TO_DATE_TIME_CUF
    }

    const isDateTimeInput = isDateTime(action.value)
    if (isDateTimeInput) {
      return UserFieldsDateTimeOperationsSet.SET_SPECIFIC_DATE_TIME
    }
  }

  if (action.operation) {
    return action.operation
  }

  const isJustANumber = !isNaN(action.value)
  if (isJustANumber) {
    return UserFieldsNumberOperations.SET
  }

  const res = /^{{[\w-]+}}([+\-])(\d*(\.\d*)?)$/.exec(action.value)
  if (res !== null) {
    const [, operation] = res
    return operation
  }
  return UserFieldsNumberOperations.CUSTOM
}

export const parseSetFieldValue = (action) => {
  if (UserFieldsMutatingOperationSet.includes(action.operation)) {
    const res = /^{{[\w-]+}}([+\-])(\d*(\.\d*)?)$/.exec(action.value)
    if (res !== null) {
      const [, , value] = res
      return value
    }
  }

  const valueIsDefined = action.value !== undefined || action.value !== null
  return valueIsDefined ? String(action.value) : ''
}

export const exportSetFieldValue = (action) => {
  const { field_id, operation } = action

  if (!action.value) {
    return ''
  }

  const field_type = action.type === ActionTypes.SET_CUF ? 'cuf' : 'gaf'

  if (UserFieldsMutatingOperationSet.includes(operation)) {
    return `{{${field_type}_${field_id}}}${operation}${action.value}`
  }
  return action.value
}

export enum UsersValueRecommendationType {
  Comments = 'comments',
  StoryReplies = 'story_replies',
  StoryMentions = 'story_mentions',
  DirectMessages = 'direct',
}

export interface UsersValueRecommendationWithCampaignId {
  type:
    | UsersValueRecommendationType.Comments
    | UsersValueRecommendationType.StoryReplies
    | UsersValueRecommendationType.DirectMessages
  quick_campaign_id: number
}

export interface UsersValueRecommendationStoryMentions {
  type: UsersValueRecommendationType.StoryMentions
  quick_campaign_id: null
}

export type UsersValueRecommendation =
  | UsersValueRecommendationWithCampaignId
  | UsersValueRecommendationStoryMentions

export interface UsersValuePeriodData {
  comments: number
  commentsAutomated: number
  commentsAutomatedPercent: number
  storyReplies: number
  storyRepliesAutomated: number
  storyRepliesAutomatedPercent: number
  storyMentions: number
  storyMentionsAutomated: number
  storyMentionsAutomatedPercent: number
  directMessages: number
  directMessagesAutomated: number
  directMessagesAutomatedPercent: number
  total: number
  totalAutomated: number
  totalAutomatedPercent: number

  recommendations?: UsersValueRecommendation[]
}

import React, { useCallback } from 'react'
import cx from 'classnames'
import { z } from 'zod'
import { BtnV2, Icon, LinkBtn } from '@manychat/manyui'

import { BlockType } from '../../constants'
import { useRenderer } from '../../context'
import { registry } from '../../registry'
import { ChildrenSchema, ClassNameSchema } from '../../schemas'
import { IdSchema } from '../../utils/schemas'

const iconKeys = Object.keys(Icon) as Array<keyof typeof Icon> & [keyof typeof Icon]

const ButtonPropsSchema = z.object({
  className: ClassNameSchema,
  viewId: IdSchema.optional(),
  children: ChildrenSchema.optional(),
  label: z.string(),
  variant: z
    .enum([
      'primary',
      'default',
      'success',
      'danger',
      'danger-light',
      'ghost-primary',
      'ghost-light',
      'ghost-dark',
    ])
    .optional(),
  link: z.string().optional(),
  onlyIcon: z.enum(iconKeys).optional(),
  size: z.enum(['default', 'large', 'small']).optional(),
})

const Component = ({
  label,
  variant,
  className,
  link,
  viewId,
  size,
  ...rest
}: z.infer<typeof ButtonPropsSchema>) => {
  const { goToNode } = useRenderer()
  const onClick = useCallback(() => {
    if (viewId) {
      goToNode(viewId)
    }
  }, [viewId, goToNode])

  if (link) {
    return (
      <LinkBtn {...rest} className={cx(className)} label={label} variant={variant} href={link} />
    )
  }

  return (
    <BtnV2
      {...rest}
      size={size}
      variant={variant}
      onClick={onClick}
      className={cx(className)}
      label={label}
    />
  )
}

export const Button = registry.register(Component, {
  props: ButtonPropsSchema,
  type: BlockType.BUTTON,
  previewProps: {
    label: 'Button',
  },
})

import { Icon, IconComponent, l } from '@manychat/manyui'

export enum TriggerRuleStatusType {
  ACTIVE = 'active',
  INACTIVE = 'inactive',
  TRASH = 'trash',
}

export enum TriggerRuleFrequencyType {
  ONCE = 'once',
  MULTIPLE = 'multiple',
}

export const TriggerRuleFrequencyTypeLabels: Record<TriggerRuleFrequencyType, () => string> = {
  [TriggerRuleFrequencyType.ONCE]: l.makeTranslate('once per contact'),
  [TriggerRuleFrequencyType.MULTIPLE]: l.makeTranslate('any number of times'),
}

export enum TriggerRuleType {
  INTERNAL_EVENT = 'internal_event',
  DATE_TIME_EVENT = 'datetime_event',
}

export enum TriggerEventType {
  DATE_TIME = 'datetime',
  LOG_CONVERSION_EVENT = 'custom_event_triggered',
  TAG_ADDED = 'add_tag',
  TAG_REMOVED = 'remove_tag',
  SEQUENCE_SUBSCRIBED = 'subscribe_sequence',
  SEQUENCE_UNSUBSCRIBED = 'unsubscribe_sequence',
  CUF_CHANGED = 'change_field_value',
  SUF_CHANGED = 'change_system_field_value',
  INTEGRATION_FIELD_CHANGED = 'change_integration_field_value',
  BOT_SUBSCRIBED = 'user_created',
}

export const TriggerEventTypeLabels: Record<TriggerEventType, () => string> = {
  [TriggerEventType.BOT_SUBSCRIBED]: l.makeTranslate('New contact'),
  [TriggerEventType.TAG_ADDED]: l.makeTranslate('Tag applied'),
  [TriggerEventType.TAG_REMOVED]: l.makeTranslate('Tag removed'),
  [TriggerEventType.SEQUENCE_SUBSCRIBED]: l.makeTranslate('Subscribed to a sequence'),
  [TriggerEventType.SEQUENCE_UNSUBSCRIBED]: l.makeTranslate('Unsubscribed from a sequence'),
  [TriggerEventType.CUF_CHANGED]: l.makeTranslate('Custom field value changed'),
  [TriggerEventType.SUF_CHANGED]: l.makeTranslate('System field value changed'),
  [TriggerEventType.INTEGRATION_FIELD_CHANGED]: l.makeTranslate('System field value changed'),
  [TriggerEventType.DATE_TIME]: l.makeTranslate('Date/Time based trigger'),
  [TriggerEventType.LOG_CONVERSION_EVENT]: l.makeTranslate('Log Conversion Event'),
}

export const TriggerEventTypeHelpText = {
  [TriggerEventType.DATE_TIME]: l.makeTranslate(
    'This Trigger type is based on Date/Date&Time stored in Custom Fields and system fields (ex. date of subscription or last interaction) and uses contact’s time zone. Check our Help Center articles for more information.',
  ),
}

export const TriggerEventTypeIcons: Record<TriggerEventType, IconComponent> = {
  [TriggerEventType.BOT_SUBSCRIBED]: Icon.TriggerNewSubscriber,
  [TriggerEventType.TAG_ADDED]: Icon.AddTag,
  [TriggerEventType.TAG_REMOVED]: Icon.DeleteTag,
  [TriggerEventType.SEQUENCE_SUBSCRIBED]: Icon.SequencesAdd,
  [TriggerEventType.SEQUENCE_UNSUBSCRIBED]: Icon.SequencesRemove,
  [TriggerEventType.CUF_CHANGED]: Icon.CustomField,
  [TriggerEventType.SUF_CHANGED]: Icon.SystemField,
  [TriggerEventType.INTEGRATION_FIELD_CHANGED]: Icon.SystemField,
  [TriggerEventType.DATE_TIME]: Icon.Time,
  [TriggerEventType.LOG_CONVERSION_EVENT]: Icon.Analytics,
}

export const TriggerEventOptions = Object.values(TriggerEventType).filter(
  (type) => type !== TriggerEventType.INTEGRATION_FIELD_CHANGED,
)

export enum DateTimeTriggerOperators {
  BEFORE = 'DATETIME_INTERVAL_BEFORE',
}

export enum SubscribeToBotTriggerOperators {
  UNKNOWN = 'IS_UNKNOWN',
}

export enum TriggerUnitType {
  SECONDS = 'seconds',
  MINUTE = 'minutes',
  HOURS = 'hours',
  DAYS = 'days',
  MONTHS = 'months',
}

export const TriggerUnitTypeLabels: Record<TriggerUnitType, () => string> = {
  [TriggerUnitType.SECONDS]: l.makeTranslate('Seconds'),
  [TriggerUnitType.MINUTE]: l.makeTranslate('Minutes'),
  [TriggerUnitType.HOURS]: l.makeTranslate('Hours'),
  [TriggerUnitType.DAYS]: l.makeTranslate('Days'),
  [TriggerUnitType.MONTHS]: l.makeTranslate('Months'),
}

import React, { useEffect, useCallback, useRef, useState } from 'react'
import cx from 'classnames'
import { TextInputV2, l } from '@manychat/manyui'

import { useIsChanged } from 'utils/commonHooks'

import cm from './CodeInput.module.css'

const EMPTY = '-'
export const getDefaultCode = (count: number): string => EMPTY.repeat(count)

export const exportCode = (code: string) => {
  return code.replace(/[^\d]/g, '')
}

export const pasteCode = (count: number, code: string, text: string, id: number): string => {
  const numbersOnly = text.replace(/([^\d]*)/g, '')
  if (!numbersOnly.length) {
    return code
  }

  let newCode = ''
  for (let i = 0; i < count; i++) {
    const shiftedId = i - id
    if (i >= id && shiftedId < numbersOnly.length) {
      newCode += numbersOnly.charAt(shiftedId)
    } else {
      newCode += code.charAt(i)
    }
  }

  return newCode
}

interface CodeInputProps {
  count: number
  invalid?: boolean
  disabled?: boolean
  onCodeFill?: (code: string) => void
  onCodeChange?: (code: string) => void
  className?: string
}

const CodeInput = ({
  count,
  onCodeFill,
  onCodeChange,
  disabled,
  invalid,
  className,
}: CodeInputProps) => {
  const [code, setCode] = useState(getDefaultCode(count))
  const inputsRefs = useRef<HTMLInputElement[]>([])
  const isCodeChanged = useIsChanged(code)

  useEffect(() => {
    if (!isCodeChanged) {
      return
    }

    const codeToExport = exportCode(code)
    onCodeChange?.(codeToExport)
    if (!code.includes(EMPTY)) {
      onCodeFill?.(codeToExport)
    }
  }, [code, isCodeChanged, onCodeChange, onCodeFill])

  const handleInputPaste = useCallback(
    (e: ClipboardEvent) => {
      const input = e.target as HTMLInputElement
      const id = Number(input?.name)

      if (e.target !== inputsRefs.current[id]) {
        return
      }

      e.preventDefault()

      const text = e.clipboardData?.getData('Text') || ''
      const newCode = pasteCode(count, code, text, id)
      setCode(newCode)

      input?.blur()
    },
    [count, code],
  )

  useEffect(() => {
    document.addEventListener('paste', handleInputPaste)
    return () => {
      document.removeEventListener('paste', handleInputPaste)
    }
  }, [handleInputPaste])

  const setRef = useCallback((id: number, el: HTMLInputElement) => {
    inputsRefs.current[id] = el
  }, [])

  const focusInput = useCallback(
    (id: number) => {
      if (id < 0 || id >= count) {
        return
      }

      const input = inputsRefs.current[id]
      input?.focus()
      input?.setSelectionRange(input?.value?.length, input?.value?.length)
    },
    [count],
  )

  const handleKeyDown = useCallback(
    (e: React.KeyboardEvent<HTMLInputElement>) => {
      const input = e.target as HTMLInputElement
      const inputId = Number(input?.name)

      if (e.key === 'Delete' || ((e.ctrlKey || e.metaKey) && e.key === 'v')) {
        return
      }

      if (e.key === 'Backspace') {
        if (!input?.value) {
          focusInput(inputId - 1)
        }

        return
      }

      if (e.key === 'Tab') {
        focusInput(inputId + 1)
        e.preventDefault()

        return
      }

      if (e.key === 'ArrowRight') {
        if (input?.selectionStart === input?.value?.length) {
          focusInput(inputId + 1)
        }

        return
      }
      if (e.key === 'ArrowLeft') {
        if (input?.selectionStart === 0) {
          focusInput(inputId - 1)
          e.preventDefault()
        }

        return
      }
    },
    [focusInput],
  )

  const handleChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const REG = /^\d$/ // Numbers only
      if (e.target.value && !REG.test(e.target.value)) {
        e.preventDefault()
        return
      }

      const id = Number(e.target.name)
      setCode(code.substr(0, id) + (e.target.value || EMPTY) + code.substr(id + 1))

      if (!e.target.value) {
        return
      }

      e.target.blur()
      focusInput(Number(e.target.name) + 1)
    },
    [code, focusInput],
  )

  const renderCodeInput = useCallback(
    (id: number) => {
      const char = code.charAt(id)
      const value = char === EMPTY ? '' : char
      return (
        <TextInputV2
          className={cx(cm.codeInput, { ['m-l-xs']: id > 0 })}
          inputRef={setRef.bind(null, id)}
          key={id}
          name={String(id)}
          maxLength={1}
          onKeyDown={handleKeyDown}
          onChange={handleChange}
          value={value}
          disabled={disabled}
          invalid={invalid}
          ariaLabel={l.translate('Code')}
          data-test-id="code-input"
        />
      )
    },
    [code, handleKeyDown, handleChange, disabled, invalid, setRef],
  )

  const inputs = []
  for (let i = 0; i < count; i++) {
    inputs.push(renderCodeInput(i))
  }

  return <div className={cx('d-inline-flex flex-row', className)}>{inputs}</div>
}

export default CodeInput

import { useMemo } from 'react'
import { generatePath, matchRoutes, useLocation } from 'react-router-dom'

import { redirectMap } from '../redirects'
import { generateQuery } from '../utils/helpers'

const ACCOUNT_ID_REGEXP = /^fb\d+$/

export const useRedirects = () => {
  const location = useLocation()

  return useMemo(() => {
    const query = generateQuery(location.search ?? '')
    const redirectMatches = matchRoutes(redirectMap, location)

    if (!redirectMatches) {
      return null
    }

    if (redirectMatches.length > 1) {
      console.error('Unexpected several redirect matches. There should be just one match')
      return null
    }

    const match = redirectMatches[0]
    const accountIdParam = match.params.acc_id
    const isCorrectRedirectMatch = accountIdParam ? ACCOUNT_ID_REGEXP.test(accountIdParam) : true

    if (isCorrectRedirectMatch) {
      const to = match.route.to
      const redirectPath = typeof to === 'function' ? to(match.params, query) : to
      let url = generatePath(redirectPath, match.params)

      if (match.route.withSearchParams && location.search) {
        url += location.search
      }

      return url
    }

    return null
  }, [location])
}

import React, { ReactNode } from 'react'

import cm from './Overlay.module.css'

export interface OverlayProps {
  children: ReactNode
}

export function Overlay({ children }: OverlayProps) {
  return (
    <div className={cm.overlay}>
      <div className={cm.content}>{children}</div>
    </div>
  )
}

import debugConstructor from 'debug'
import hashSum from 'hash-sum'

import { mergeData } from './../mergeData'

const debug = debugConstructor('utils.currentItem.reducers')

// Refactoring needed
export const setCurrentItem = (state, item) => {
  debug('Warn! Need setCurrentItem refactoring')

  return { ...state, item, fetching: false, hash: hashSum(item), hasChanges: false }
}

export const makeCurrentItemReducer = (entityName, options = {}) => {
  const prefix = entityName.toUpperCase()
  const initialState = { item: null, fetching: false, hash: null, hasChanges: false, error: null }

  return function reducer(state = initialState, action) {
    switch (action.type) {
      case `${prefix}_CURRENT_INIT`: {
        const { data } = action
        return { ...state, item: data, hasChanges: false }
      }
      case `${prefix}_CURRENT_FETCH_REQUEST`:
      case `${prefix}_CURRENT_CREATE_REQUEST`:
      case `${prefix}_CURRENT_SAVE_REQUEST`: {
        return { ...state, fetching: true, error: null }
      }
      case `${prefix}_CURRENT_FETCH_ERROR`:
      case `${prefix}_CURRENT_CREATE_ERROR`:
      case `${prefix}_CURRENT_SAVE_ERROR`: {
        const { error } = action
        return { ...state, fetching: false, error }
      }
      case `${prefix}_CURRENT_FETCH_RESPONSE`:
      case `${prefix}_CURRENT_CREATE_RESPONSE`:
      case `${prefix}_CURRENT_SAVE_RESPONSE`: {
        const { data } = action
        let { item } = data
        if (options.import) {
          item = options.import(item)
        }
        return setCurrentItem(state, item)
      }
      case `${prefix}_CURRENT_UPDATE`: {
        let { item } = state
        if (item == null) {
          return state
        }

        const { data, path, replace } = action
        item = mergeData(item, data, path, replace)

        return { ...state, item, hasChanges: state.hash !== hashSum(item) }
      }
      case `${prefix}_CURRENT_UPDATED_NOTIFICATION`: {
        let { item: updatedItem } = action
        if (state.item == null || state.item.id !== updatedItem.id) {
          break
        }

        if (options.import) {
          updatedItem = options.import(updatedItem)
        }

        const hash = hashSum(updatedItem)
        const item = { ...state.item, ...updatedItem }
        state = {
          ...state,
          hasChanges: hash !== hashSum(item),
          hash,
          item,
        }
      }
    }
    return state
  }
}

import React from 'react'

import { useIsAnyTabActive } from 'apps/chat/hooks/useIsAnyTabActive'

export const useBrowserNotificationsMap = (tabID: number) => {
  const notificationsList = React.useRef<Record<string, Notification>>({})
  const { isAnyTabActiveState } = useIsAnyTabActive(tabID)

  React.useEffect(() => {
    if (isAnyTabActiveState) {
      Object.values(notificationsList.current).forEach((notification) => notification.close())
      notificationsList.current = {}
    }
  }, [isAnyTabActiveState])

  const addNotification = React.useCallback((key: string, value: Notification) => {
    notificationsList.current[key] = value
  }, [])

  return { addNotification }
}

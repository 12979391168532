import { z, ZodSchema } from 'zod'

import { AddOnSlug, PlanSlug } from '../constants'

import {
  DeNormalizedSerializedBlockWithChildrenSchema,
  SerializedBlockWithChildrenSchema,
} from './schemas'

export const makeGuardFromSchema =
  <Schema extends ZodSchema>(schema: Schema) =>
  (value: unknown): value is z.infer<Schema> => {
    return schema.safeParse(value).success
  }

export const isNestedBlock = makeGuardFromSchema(SerializedBlockWithChildrenSchema)

export const isDeNormalizedNestedBlock = makeGuardFromSchema(
  DeNormalizedSerializedBlockWithChildrenSchema,
)

export const isPlanSlug = (value: unknown): value is PlanSlug => {
  return Object.values(PlanSlug).includes(value as PlanSlug)
}

export const isAddOnSlug = (value: unknown): value is AddOnSlug => {
  return Object.values(AddOnSlug).includes(value as AddOnSlug)
}

import { api } from 'shared/api'
import { UserFieldsSchemas } from 'shared/api/requests/customFields/schemas'

export const UserFieldsApi = {
  moveToFolder: api.account.createPost({
    url: '/customFields/moveToFolder',
    schemas: UserFieldsSchemas.moveToFolder,
  }),
  moveToFolderBulk: api.account.createPost({
    url: '/customFields/moveToFolderBulk',
    schemas: UserFieldsSchemas.moveToFolderBulk,
  }),
  getFolderContentCount: api.account.createGet({
    url: '/customFields/getFolderContentCount',
    schemas: UserFieldsSchemas.getFolderContentCount,
  }),
}

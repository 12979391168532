import { FetchStatus } from 'apps/sms/smsInterfaces'
import { getSMSChannel } from 'common/core/selectors/channelSelectors'
import { SmsPhoneData } from 'shared/api/requests/sms/schemas'

export const getSmsFetchStatus = (state: RootState): FetchStatus => state.sms.fetchStatus

export const isSmsChannelNeverConnected = (state: RootState): boolean => {
  return !getSMSChannel(state).sms_has_phones
}

export const getAllPhones = (state: RootState): SmsPhoneData[] => state.sms.phones

export const isNewConnectModalOpen = (state: RootState): boolean => state.sms.isNewConnectModalOpen

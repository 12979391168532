import { DeepPartial } from 'utility-types'

import {
  WaMessageTemplateHeader,
  WaMessageTemplateHeaderType,
  WaMessageTemplateHeaderTypeAttachment,
} from 'shared/api/requests/whatsApp/schemas'

import { MessageTemplateAttachment, MessageTemplateHeader } from '../core/constants'

export const getIsAttachmentMessageTemplateHeader = (
  header?: DeepPartial<WaMessageTemplateHeader> | DeepPartial<MessageTemplateHeader>,
): header is WaMessageTemplateHeaderTypeAttachment => {
  return Boolean(
    !Array.isArray(header) &&
      header?.type &&
      [
        WaMessageTemplateHeaderType.DOCUMENT,
        WaMessageTemplateHeaderType.VIDEO,
        WaMessageTemplateHeaderType.IMAGE,
        MessageTemplateAttachment.DOCUMENT,
        MessageTemplateAttachment.VIDEO,
        MessageTemplateAttachment.IMAGE,
      ].includes(header.type),
  )
}

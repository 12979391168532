import React from 'react'
import { Icon, l, Link } from '@manychat/manyui'

import {
  IntegrationType,
  IntegrationNameByType,
} from 'common/actions/integrations/common/constants/IntegrationType'
import {
  createIntegration,
  createIntegrationAction,
} from 'common/actions/integrations/common/model/helpers'
import * as convertkitActions from 'common/actions/integrations/ConvertKit/actions/convertkitActions'
import { KnowledgeBaseLink } from 'common/core/components/SupportLinks'
import { AccountTargetPermissionsNames } from 'common/core/models/Account/constants'
import { analyticsService } from 'utils/services/analytics'

export const CONVERTKIT_URL = 'https://app.convertkit.com/account/edit'

export const ConvertKitActionTypes = {
  ADD_TO_FORM: 'subscribers.add_to_form',
  ADD_TO_SEQUENCE: 'subscribers.add_to_sequence',
  ADD_TAG: 'subscribers.add_tag',
  GET_DATA: 'subscribers.get_data',
}

const handleSupportLinkClick = () => {
  analyticsService.sendEvent('SUPPORT.LINK_SETTINGS_INTEGRATIONS_CONVERT_KIT_CLICK', {
    url: window.location.pathname,
  })
}

export const ConvertKitConfig = createIntegration({
  type: IntegrationType.CONVERT_KIT,
  name: IntegrationNameByType[IntegrationType.CONVERT_KIT],
  icon: Icon.ConvertKit,
  permissionsVariable: AccountTargetPermissionsNames.CONVERT_KIT_INTEGRATION,
  description: () => (
    <span>
      {l.translate(
        `ConvertKit is an email marketing platform for professional bloggers. You'll need to get API Secret from your <a target="_blank" class="text-primary pointer" href="{CONVERTKIT_URL}" rel="noreferrer">ConvertKit account</a> to setup integration.`,
        { CONVERTKIT_URL },
      )}{' '}
      <Link
        href={KnowledgeBaseLink.CONVERTKIT_INTEGRATION}
        target="_blank"
        onClick={handleSupportLinkClick}
        rel="noreferrer"
      >
        {l.translate('Learn more')}
      </Link>
    </span>
  ),

  reduxActions: convertkitActions,
  actions: [
    createIntegrationAction({
      type: ConvertKitActionTypes.ADD_TO_FORM,
      description: l.translate(
        "Subscribe an email address to one of your forms and update ConvertKit contact's custom fields",
      ),
      name: l.translate('Add Contact to Form'),
    }),
    createIntegrationAction({
      type: ConvertKitActionTypes.ADD_TO_SEQUENCE,
      description: l.translate(
        "Subscribe an email address to one of your sequences and update ConvertKit contact's custom fields",
      ),
      name: l.translate('Add Contact to Sequence'),
    }),
    createIntegrationAction({
      type: ConvertKitActionTypes.ADD_TAG,
      description: l.translate(
        'Subscribe an email address to a tag to have that tag applied to the contact with that email address.',
      ),
      name: l.translate('Add Tag'),
    }),
    createIntegrationAction({
      type: ConvertKitActionTypes.GET_DATA,
      description: l.translate(
        "Returns data for a single contact by email address and process it to contact's custom fields.",
      ),
      name: l.translate("Get Contact's Data"),
    }),
  ],
})

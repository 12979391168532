import { l } from '@manychat/manyui'

import { KeywordRule } from 'apps/keywords/keywordsInterfaces'
import { getKeywordPreviewNodes } from 'apps/keywords/lib/getKeywordPreviewNodes'

export const getKeywordStringPreview = (keywordRules: KeywordRule[]) =>
  getKeywordPreviewNodes(keywordRules)
    .reduce<string>((accumulator, node, index) => {
      if (Array.isArray(node)) {
        return `${accumulator}${node.join(', ')}`
      } else {
        const value = index === 0 ? l.getString(node) : l.getString(node).toLowerCase()

        return `${accumulator} ${value} `
      }
    }, '')
    .trim()
    .replace(/\s\s/g, ' ')

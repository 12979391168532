import { ILocalizationConfig } from './types'

export const defaultTranslate = (message: string, data: Record<string, unknown> = {}) => {
  let result = message ? String(message) : ''
  for (const key in data) {
    result = result.replace(new RegExp(`{ {0,1}${key} {0,1}}`, 'g'), String(data[key]))
  }
  return result
}

export const LocalizationConfig: ILocalizationConfig = {
  allowedLanguages: [],
  language: 'en-US',
  locale: 'en',
  translate: defaultTranslate,
}

export default function initLocalization({
  allowedLanguages: availableLanguages,
  language,
  locale,
  translate,
}: ILocalizationConfig) {
  LocalizationConfig.allowedLanguages = availableLanguages
  LocalizationConfig.language = language
  LocalizationConfig.locale = locale
  LocalizationConfig.translate = translate
}

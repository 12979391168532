//FETCH
export const FETCH_SNIPPET = 'FETCH_SNIPPET'
export const FETCH_SNIPPET_RESPONSE = 'FETCH_SNIPPET_RESPONSE'
//CREATE
export const CREATE_SNIPPET = 'CREATE_SNIPPET'
export const CREATE_SNIPPET_RESPONSE = 'CREATE_SNIPPET_RESPONSE'
//EDIT
export const EDIT_SNIPPET = 'EDIT_SNIPPET'
export const EDIT_SNIPPET_RESPONSE = 'EDIT_SNIPPET_RESPONSE'
//DELETE
export const DELETE_SNIPPET = 'DELETE_SNIPPET'
export const DELETE_SNIPPET_RESPONSE = 'DELETE_SNIPPET_RESPONSE'

export const UPDATE_LIVECHAT_FILTER = 'UPDATE_LIVECHAT_FILTER'

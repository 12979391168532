import isFinite from 'lodash/isFinite'

import errorTrackingService from 'utils/services/errorTrackingService'

/**
 * Returns borderBoxSize for the passed ResizeObserverEntry or contentRect size (for older browsers)
 */
export const getResizeObserverEntrySize = (
  entry: ResizeObserverEntry,
): null | { width: number; height: number } => {
  if (!entry) {
    errorTrackingService.trackError(
      new Error(`Invalid argument: expected ResizeObserverEntry, passed: ${typeof entry}`),
    )
    return null
  }

  let width: number | null = null
  let height: number | null = null

  if ('borderBoxSize' in entry && entry.borderBoxSize[0]) {
    width = entry.borderBoxSize[0].inlineSize
    height = entry.borderBoxSize[0].blockSize
  } else if ('contentRect' in entry) {
    // Fallback for Safari 15
    width = entry.contentRect.width
    height = entry.contentRect.height
  }

  if (width !== null && isFinite(width) && height !== null && isFinite(height)) {
    return {
      width,
      height,
    }
  }

  errorTrackingService.trackError(new Error('Unable to get ResizeObserverEntry size'))

  return null
}

import { z } from 'zod'

import { DisplayPreferences } from 'common/settings/constants/DisplayPreferences'
import { PauseAutomationFormat } from 'common/settings/constants/PauseAutomationFormat'
import {
  EmailAddressErrorSchema,
  PayPalErrorSchema,
  ProErrorSchema,
} from 'shared/api/common/schemas/errors'

import { ConversionsApiChannelSchema } from './ConversionsApiChannelSchema'
import { ConversionsApiValueSchema } from './ConversionsApiValueSchema'
import { EmailSettingSchema } from './EmailSettingSchema'
import { GetPublicAPITokenResponseSchema } from './GetPublicAPITokenResponseSchema'
import { PayPalConfigSchema } from './PayPalConfigSchema'
import { ProtocolDataTypeSchema } from './ProtocolDataTypeSchema'
import { SettingsSchema } from './SettingsSchema'
import { ThreadRoutingTypeSchema } from './ThreadRoutingTypeSchema'
import { WhitelistDomainSchema } from './WhiteDomainSchema'

export { DisplayPreferences, PauseAutomationFormat }

export const settingsApiSchemas = {
  changeAutomationPauseInitTime: {
    query: z.undefined(),
    path: z.undefined(),
    request: z.object({
      second_interval: z.number(),
      format: z.nativeEnum(PauseAutomationFormat),
    }),
    response: z.undefined(),
  },
  setDisplayPreferences: {
    query: z.undefined(),
    path: z.undefined(),
    response: z.undefined(),
    request: z.object({
      setting: z.nativeEnum(DisplayPreferences),
      value: z.boolean(),
    }),
  },
  toggleInstagramChannel: {
    query: z.undefined(),
    path: z.undefined(),
    response: z.undefined(),
    request: z.object({
      value: z.boolean(),
    }),
  },
  transferOwnership: {
    path: z.undefined(),
    query: z.undefined(),
    request: z.object({
      user_id: z.number(),
    }),
    response: z.unknown(),
  },
  getWhitelistingDomains: {
    path: z.undefined(),
    query: z.undefined(),
    request: z.undefined(),
    response: z.object({
      whitelist: z.array(WhitelistDomainSchema),
    }),
  },
  addWhitelistingDomain: {
    path: z.undefined(),
    query: z.undefined(),
    request: z.object({
      url: z.string(),
    }),
    response: z.object({
      whitelist: z.array(WhitelistDomainSchema),
    }),
  },
  deleteWhitelistingDomain: {
    path: z.undefined(),
    query: z.undefined(),
    request: z.object({
      url: z.string(),
    }),
    response: z.object({
      whitelist: z.array(WhitelistDomainSchema),
    }),
  },
  leaveAccount: {
    path: z.undefined(),
    query: z.undefined(),
    request: z.undefined(),
    response: z.undefined(),
  },
  setConversionsApiCreds: {
    path: z.undefined(),
    query: z.undefined(),
    request: z.object({
      channel: ConversionsApiChannelSchema,
      dataset_id: z.string(),
      dataset_token: z.string(),
    }),
    response: z.unknown(),
  },
  disconnectConversionsApiCreds: {
    path: z.undefined(),
    query: z.undefined(),
    request: z.object({
      channel: ConversionsApiChannelSchema,
    }),
    response: z.unknown(),
  },
  setAutosendEventsToCapi: {
    path: z.undefined(),
    query: z.undefined(),
    request: z.object({
      channel: ConversionsApiChannelSchema,
      state: z.boolean(),
    }),
    response: z.unknown(),
  },
  getCapiEventActionValues: {
    path: z.undefined(),
    query: z.undefined(),
    request: z.undefined(),
    response: z.object({
      currencies: z.array(z.record(z.string(), z.string())),
      event_types: z.array(z.string()),
    }),
  },
  getThreadRoutingData: {
    path: z.undefined(),
    query: z.undefined(),
    request: z.undefined(),
    response: z.object({
      thread_routing_type: ThreadRoutingTypeSchema,
      protocol_data: ProtocolDataTypeSchema.or(ProtocolDataTypeSchema.array()),
    }),
  },
  connectStripe: {
    path: z.undefined(),
    query: z.undefined(),
    request: z.undefined(),
    response: z.object({
      redirect: z.string().url(),
    }),
  },
  toggleTikTokChannel: {
    query: z.undefined(),
    path: z.undefined(),
    response: z.undefined(),
    request: z.object({
      value: z.boolean(),
    }),
  },
  disconnectStripe: {
    path: z.undefined(),
    query: z.undefined(),
    request: z.undefined(),
    response: z.object({
      settings: SettingsSchema,
    }),
  },
  setCurrency: {
    path: z.undefined(),
    query: z.undefined(),
    request: z.object({
      currency: z.string(),
    }),
    response: z.object({
      settings: SettingsSchema,
    }),
  },
  setPaymentNotifySettings: {
    path: z.undefined(),
    query: z.undefined(),
    request: z.object({
      types: z.array(z.string()),
    }),
    response: z.object({
      settings: SettingsSchema,
    }),
  },
  setStripeChargeEmailNotification: {
    path: z.undefined(),
    query: z.undefined(),
    request: z.object({
      notify: z.boolean(),
    }),
    response: z.object({
      settings: SettingsSchema,
    }),
  },
  getHandoverReceivers: {
    path: z.undefined(),
    query: z.undefined(),
    request: z.undefined(),
    response: z.object({
      receivers: z.array(
        z.object({
          id: z.string(),
          name: z.string(),
        }),
      ),
    }),
  },
  setLiveChatReceiver: {
    path: z.undefined(),
    query: z.undefined(),
    request: z.object({
      app_id: z.string(),
    }),
    response: z.undefined(),
  },
  connectPaypal: {
    path: z.undefined(),
    query: z.undefined(),
    request: PayPalConfigSchema,
    response: z.object({
      settings: SettingsSchema,
    }),
    errors: {
      PayPalDefaultError: PayPalErrorSchema,
    },
  },
  disconnectPaypal: {
    path: z.undefined(),
    query: z.undefined(),
    request: z.undefined(),
    response: z.object({
      settings: SettingsSchema,
    }),
  },
  switchEmailChannel: {
    path: z.undefined(),
    query: z.undefined(),
    request: z.object({
      value: z.boolean(),
    }),
    response: z.unknown(),
    errors: {
      GoProError: ProErrorSchema,
    },
  },
  setEmailSettings: {
    path: z.undefined(),
    query: z.undefined(),
    request: z.object({
      company_name: z.string(),
      address: z.string(),
    }),
    response: EmailSettingSchema,
    errors: {
      GoProError: ProErrorSchema,
      AddressBlankError: EmailAddressErrorSchema,
    },
  },
  showEmailBranding: {
    path: z.undefined(),
    query: z.undefined(),
    request: z.object({
      value: z.boolean(),
    }),
    response: EmailSettingSchema,
    errors: {
      GoProError: ProErrorSchema,
    },
  },
  getPublicAPIToken: {
    path: z.undefined(),
    query: z.undefined(),
    request: z.undefined(),
    response: GetPublicAPITokenResponseSchema,
  },
  deletePublicAPIToken: {
    path: z.undefined(),
    query: z.undefined(),
    request: z.undefined(),
    response: z.undefined(),
  },
  fetchSettings: {
    path: z.undefined(),
    query: z.undefined(),
    request: z.undefined(),
    response: z.object({
      settings: SettingsSchema,
    }),
  },
}

export type ConversionsApiChannel = z.infer<typeof ConversionsApiChannelSchema>
export type ConversionsApiValue = z.infer<typeof ConversionsApiValueSchema>
export type ThreadRoutingType = z.infer<typeof ThreadRoutingTypeSchema>
export type SettingsType = z.infer<typeof SettingsSchema>
export type PayPalConfig = z.infer<typeof PayPalConfigSchema>

import { createSelector } from 'reselect'

import { Ability } from '../constants/Ability'

import { getCurrentAccount } from './appSelectors'

type AbilityMap = Partial<Record<Ability, boolean>>

const emptyAbilities: readonly Ability[] = []

export const getAbilitiesRaw = createSelector(
  [getCurrentAccount],
  (currentAccount): Readonly<Ability[]> => currentAccount?.abilities || emptyAbilities,
)

export const getAbilities = createSelector([getAbilitiesRaw], (abilitiesRaw): AbilityMap => {
  return abilitiesRaw.reduce<AbilityMap>((map, ability) => {
    map[ability] = true

    return map
  }, {})
})

/**
 * Returns `true` if current account has requested ability
 *
 * @example
 * const hasSomeAbility = getHasAbility(state, Abilities.SOME_ABILITY)
 */
export const getHasAbility = createSelector(
  [getAbilities, (state: RootState, ability: Ability) => ability],
  (abilities, abilityName): boolean => Boolean(abilities?.[abilityName]),
)

/**
 * Accepts list of abilities that are needed to be checked.
 * Returns diff between connected abilities and passed abilities
 *
 * @example
 * userAbilities = [Ability.AI_KEYWORD]
 *
 * getUnavailableAbilities(state, [Ability.AI_KEYWORD, Ability.AI_AGENT]) // will return [Ability.AI_AGENT]
 */
export const getUnavailableAbilities = createSelector(
  [getAbilities, (state: RootState, abilitiesToCheck: Ability[]) => abilitiesToCheck],
  (abilities, abilitiesToCheck): Ability[] => {
    const unavailableAbilities = new Set<Ability>()
    abilitiesToCheck.forEach((ability) => {
      const isAvailable = Boolean(abilities?.[ability])
      if (!isAvailable) {
        unavailableAbilities.add(ability)
      }
    })

    return Array.from(unavailableAbilities)
  },
)

import { RnListName } from 'apps/dashboard/components/RnLearning/components/FirstRecurringNotificationOnboarding/Components/FirstRecurringNotificationModal/components/RecurringNotificationWizard/constants'
import { MAX_FOLLOW_UP_MESSAGE_LENGTH } from 'apps/dashboard/components/RnLearning/components/FirstRecurringNotificationOnboarding/Components/FirstRecurringNotificationModal/constants'
import { changeFieldIdToFieldCaption } from 'apps/dashboard/components/RnLearning/utils/changeFieldIdToFieldCaption'
import { ChannelType } from 'common/core/constants/ChannelType'
import { Field } from 'common/fields/entities/field'

export const getDisplayedText = (inputText: string, fields: Field[]) => {
  const text = inputText.slice(0, MAX_FOLLOW_UP_MESSAGE_LENGTH)
  return changeFieldIdToFieldCaption(text, fields)
}

export const ChannelToRnListNameMap = {
  [ChannelType.FB]: RnListName.MESSENGER_LIST,
  [ChannelType.INSTAGRAM]: RnListName.INSTAGRAM_DM_LIST,
}

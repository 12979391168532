import { ValueLabel } from 'common/core/interfaces/actions'

export enum SnippetTypeMap {
  TEXT = 10,
}

export enum SnippetSharingType {
  SHARED = 'shared',
  PRIVATE = 'private',
}

export const SnippetTypeOptions: ValueLabel<SnippetTypeMap>[] = [
  {
    label: 'Text',
    value: SnippetTypeMap.TEXT,
  },
]

export enum LivechatNotificationSetting {
  NEW_MESSAGE_FROM_ASSIGNED = 'lc_push_notify_new_message_from_assigned',
  NEW_MESSAGE_FROM_UNASSIGNED = 'lc_push_notify_new_message_from_unassigned',
  NEW_ASSIGNMENT = 'lc_push_notify_assignment',
  NEW_NOTES_MENTION = 'lc_push_notify_mention',
}

import * as atypes from 'apps/agency/constants/AgencyReduxActionTypes'
import {
  parseAgencyStatus,
  parseAgencyAchievments,
  parseBenefitsLinks,
} from 'apps/agency/models/Agency/adapter'
import { AGENCY_LEVELS } from 'apps/agency/models/Agency/constants'

const initialState = {
  currentStatus: AGENCY_LEVELS.applicant,
  achievements: null,
  benefitsLinks: null,
}

export default function statusReducer(state = initialState, action) {
  switch (action.type) {
    case atypes.AGENCY_GET_STATUS_RESPONSE:
      return { ...state, currentStatus: parseAgencyStatus(action.data) }

    case atypes.AGENCY_GET_ACHIEVEMENTS_RESPONSE:
      return {
        ...state,
        achievements: parseAgencyAchievments(action.data),
      }

    case atypes.AGENCY_GET_BENEFITS_LINKS_RESPONSE:
      return {
        ...state,
        benefitsLinks: parseBenefitsLinks(action.data),
      }
  }
  return state
}

import find from 'lodash/find'
import flatten from 'lodash/flatten'
import values from 'lodash/values'
import { createSelector, ParametricSelector } from 'reselect'

import { NodeType } from 'common/builder'
import { IBlock } from 'common/builder/blocks/blockInterfaces'
import { IEntity } from 'common/builder/builderInterfaces'
import { IButton } from 'common/builder/buttons/buttonInterfaces'
import EntityType from 'common/builder/constants/EntityType'
import { INode } from 'common/builder/nodes/nodeInterfaces'

export const getNodesMap = (state: RootState, builderId: string): Record<string, INode> =>
  state.builders.byId[builderId]?.nodes?.byId

export const getButtonsMap = (state: RootState, builderId: string): Record<string, IButton> =>
  state.builders.byId[builderId]?.buttons?.byId

export const getBlocksMap = (state: RootState, builderId: string): Record<string, IBlock> =>
  state.builders.byId[builderId]?.blocks?.byId

export const getEntitiesMap: ParametricSelector<
  RootState,
  string,
  Record<string, IEntity>
> = createSelector(
  [getNodesMap, getButtonsMap, getBlocksMap],
  (nodesMap, buttonsMap, blocksMap) => ({
    ...nodesMap,
    ...buttonsMap,
    ...blocksMap,
  }),
)

export const getBlocksList = (state: RootState, builderId: string): IBlock[] =>
  values(getBlocksMap(state, builderId))

export const getButtonsList = (state: RootState, builderId: string): IButton[] =>
  values(getButtonsMap(state, builderId))

export const getNodesList: ParametricSelector<RootState, string, INode[]> = createSelector(
  [getNodesMap],
  (nodesMap) => values(nodesMap).filter((node) => node.deleted !== true),
)

export const getAllNodesList: ParametricSelector<RootState, string, INode[]> = createSelector(
  [getNodesMap],
  (nodesMap) => values(nodesMap).filter((node) => node.deleted !== true && !node.isStartingStep),
)

export const getNodesListByType = createSelector(
  [
    (state: RootState, builderId: string) => getNodesList(state, builderId),
    (state: RootState, builderId: string, nodeType: NodeType) => nodeType,
  ],
  (nodes, nodeType) => {
    if (!nodeType) {
      return []
    }

    return nodes.filter((node) => node.nodeType === nodeType)
  },
)

export const getById = (
  state: RootState,
  builderId: string,
  entityId: string | number,
): undefined | IEntity => {
  if (typeof entityId === 'number') {
    return getNodeById(state, builderId, entityId)
  }
  return getEntitiesMap(state, builderId)[entityId]
}

export const getBlockById = (
  state: RootState,
  builderId: string,
  blockId: string,
): undefined | IBlock => getBlocksMap(state, builderId)[blockId]

export const getButtonById = (
  state: RootState,
  builderId: string,
  buttonId: string,
): undefined | IButton => getButtonsMap(state, builderId)?.[buttonId]

export const getNodeById = (
  state: RootState,
  builderId: string,
  nodeId: string | number,
): undefined | INode => {
  const nodesMap = getNodesMap(state, builderId)
  if (typeof nodeId === 'string') {
    return nodesMap[nodeId]
  }
  if (typeof nodeId === 'number') {
    return find(nodesMap, (node) => node.contentId === nodeId)
  }
  return
}

export const getParentBlock = (
  state: RootState,
  builderId: string,
  entityId: string,
): undefined | IBlock => {
  const entity = getById(state, builderId, entityId)
  if (!entity || entity.entity === EntityType.NODE) {
    return
  }

  return getBlocksList(state, builderId).find((item) => {
    return entity.entity === EntityType.BLOCK
      ? item.blocks?.includes(entityId)
      : item.buttons?.includes(entityId)
  })
}

export const getRootParentBlock = (
  state: RootState,
  builderId: string,
  entityId: string,
  isReturnItself = false,
): undefined | IBlock => {
  const entity = getById(state, builderId, entityId)
  if (!entity) {
    return
  }

  const parentBlock = getParentBlock(state, builderId, entityId)
  if (parentBlock) {
    return getRootParentBlock(state, builderId, parentBlock.id, true)
  }
  if (!isReturnItself || entity.entity !== EntityType.BLOCK) {
    return
  }
  return entity
}

export const getParentNode = (
  state: RootState,
  builderId: string,
  entityId: string,
): undefined | INode => {
  const entity = getById(state, builderId, entityId)
  if (!entity) {
    return
  }

  if (entity.entity === EntityType.NODE) {
    return entity
  }

  const parentBlock = getParentBlock(state, builderId, entityId)
  if (parentBlock) {
    return getParentNode(state, builderId, parentBlock.id)
  }

  const nodes = getNodesList(state, builderId)
  return nodes.find((n) => n.blocks?.includes(entityId))
}

export const getBlocks = (
  state: RootState,
  builderId: string,
  entityId: string,
  options: { includeNested?: boolean } = {},
): IBlock[] => {
  const entity = getById(state, builderId, entityId)
  if (!entity || entity.entity === EntityType.BUTTON) {
    return []
  }
  const blocks = (entity.blocks ?? [])
    .map((blockId) => getById(state, builderId, blockId))
    .filter((b) => b?.entity === EntityType.BLOCK) as IBlock[]

  if (!options.includeNested) {
    return blocks
  }

  const entityBlocksChildBlocks = flatten(
    blocks.map((block) => getBlocks(state, builderId, block.id, options)),
  )
  return [...blocks, ...entityBlocksChildBlocks]
}

export const getButtons = <T extends IButton>(
  state: RootState,
  builderId: string,
  entityId: string,
  options: { includeNested?: boolean } = {},
): T[] => {
  const entity = getById(state, builderId, entityId)
  if (!entity) {
    return []
  }

  if (entity.entity === EntityType.NODE) {
    if (!options.includeNested) {
      return []
    }
    return flatten(
      (entity.blocks ?? []).map((blockId) =>
        getButtons(state, builderId, blockId, { includeNested: true }),
      ),
    )
  }

  if (entity.entity === EntityType.BLOCK) {
    const buttons = (entity.buttons ?? [])
      .map((buttonId) => getById(state, builderId, buttonId))
      .filter((b) => b?.entity === EntityType.BUTTON) as T[]

    if (!options.includeNested) {
      return buttons
    }

    const blocks = getBlocks(state, builderId, entityId, { includeNested: true })
    const entityBlocksChildButtons = flatten(
      blocks.map((block) => getButtons<T>(state, builderId, block.id)),
    )
    return [...buttons, ...entityBlocksChildButtons]
  }

  return []
}

export const getAllRelated = <T extends IEntity = IEntity>(
  state: RootState,
  builderId: string,
  entityId: string,
): [T, { blocks: IBlock[]; buttons: IButton[] }] => {
  const entity = getById(state, builderId, entityId) as T
  const blocks = getBlocks(state, builderId, entityId, { includeNested: true })
  const buttons = getButtons(state, builderId, entityId, { includeNested: true })
  return [entity, { blocks, buttons }]
}

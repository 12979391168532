import { IGetSystemFlowsResult } from 'apps/systemKeywords/systemKeywordsInterfaces'
import { ChannelType } from 'common/core/constants/ChannelType'
import * as API from 'constants/API'
import { McAPI } from 'utils/api/types'

export class SystemKeywordsAPI {
  private api: McAPI

  constructor(api: McAPI) {
    this.api = api
  }

  async getSystemFlows(channel: ChannelType): Promise<IGetSystemFlowsResult> {
    return this.api.post<IGetSystemFlowsResult>(API.systemKeywords.endpoints.getSystemFlows, {
      body: { channel },
    })
  }
}

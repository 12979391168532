import { isMobile } from 'utils'
import { ExtendedNetworkInformation } from 'utils/logWebVitals/logWebVitals'
import { analyticsService } from 'utils/services/analytics'

export enum StripeMetricsContext {
  GET_REDIRECT_URL = 'stripe_get_redirect_url',
  REDIRECT = 'stripe_redirect',
}

export const sendStripeMetric = (context: StripeMetricsContext) => {
  const connection: ExtendedNetworkInformation =
    // eslint-disable-next-line
    // @ts-expect-error
    navigator.connection || navigator?.mozConnection || navigator?.webkitConnection

  analyticsService.sendEvent('METRICS.CUSTOM.COUNT', {
    context,
    scope: isMobile ? 'mobile' : 'desktop',
    internet_speed: connection?.effectiveType ?? 'unknown',
    value: 1,
  })
}

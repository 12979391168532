export enum WalletType {
  CREDIT = 2,
  DEBIT = 1,
}

export enum WalletTransactionType {
  CHARGE_FOR_EMAIL = 'Email',
  CHARGE_FOR_MMS = 'Multimedia Message outbound',
  CHARGE_FOR_MMS_INBOUND = 'Multimedia Message inbound',
  CHARGE_FOR_SMS = 'Text Message outbound',
  CHARGE_FOR_SMS_INBOUND = 'Text Message inbound',
  CHARGE_FOR_WA_AI_INTENT_RECOGNITION = 'AI Intent message recognition',
  CHARGE_FOR_WA_AUTHENTICATION_CONVERSATION = 'WhatsApp Authentication Conversation',
  CHARGE_FOR_WA_INBOUND_CONVERSATION = 'WhatsApp Inbound Conversation',
  CHARGE_FOR_WA_MARKETING_CONVERSATION = 'WhatsApp Marketing Conversation',
  CHARGE_FOR_WA_MESSAGE_TEMPLATE = 'WhatsApp Message Template',
  CHARGE_FOR_WA_OUTBOUND_CONVERSATION = 'WhatsApp Outbound Conversation',
  CHARGE_FOR_WA_SERVICE_CONVERSATION = 'WhatsApp Service Conversation',
  CHARGE_FOR_WA_TWILIO_PHONE = 'WhatsApp Phone Number',
  CHARGE_FOR_WA_TWILIO_PHONE_MONTHLY = 'Monthly charge WhatsApp Phone Number',
  CHARGE_FOR_WA_UTILITY_CONVERSATION = 'WhatsApp Utility Conversation',
  MAKE_WALLET_CREDIT = 'Make wallet credit',
  REFUND = 'Refund',
  REFUND_TO_WALLET = 'Refund Wallet Operation To Wallet',
  REPAY_CREDIT_BALANCE = 'Repay credit balance',
  TOP_UP_AUTO = 'Refill',
  TOP_UP_BY_USER = 'Refill',
  UPDATE_CREDIT_BALANCE = 'Update credit balance',
}

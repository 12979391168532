import get from 'lodash/get'
import { l } from '@manychat/manyui'

import ErrorMessages from 'common/actions/constants/Validation'
import { ChatGPTActionTypes } from 'common/actions/integrations/ChatGPT/model/constants'
import {
  isMaxTokensInvalid,
  isTemperatureInvalid,
} from 'common/actions/integrations/ChatGPT/model/requestToChatGPTHelpers'
import { ClaudeActionTypes } from 'common/actions/integrations/Claude/lib/config'
import { ConvertKitActionTypes } from 'common/actions/integrations/ConvertKit/model/constants'
import { DeepSeekActionTypes } from 'common/actions/integrations/DeepSeek/lib/config'
import { GoogleSheetsActionTypes } from 'common/actions/integrations/GoogleSheets/model/constants'
import { HubSpotActionTypes } from 'common/actions/integrations/HubSpot/model/constants'
import { MailChimpActionTypes } from 'common/actions/integrations/MailChimp/model/constants'
import { SalesforceActionTypes } from 'common/actions/integrations/Salesforce/model/constants'
import { ActionTypes } from 'common/actions/models/Action/constants'
import UserFieldsTypes from 'common/fields/constants/UserFieldsTypes'
import { getFormulaErrors } from 'common/fields/models/CustomField/validation'

import { parseActionItem } from './adapter'

export const getActionErrors = (action, data = {}) => {
  action = parseActionItem(action)

  const userFields = data.userFields || []
  const customEvents = data.customEvents || []

  let errors = []
  switch (action.type) {
    case ActionTypes.ADD_TAG:
    case ActionTypes.REMOVE_TAG:
      if (!action.tag_id) {
        errors.push({ prop: 'tag_id', message: ErrorMessages.ACTION_TAG_REQUIRED })
      }
      break
    case ActionTypes.FIRE_CUSTOM_EVENT:
      if (!action.event_id) {
        errors.push({ prop: 'event_id', message: ErrorMessages.ACTION_FIRE_EVENT_TITLE_REQUIRED })
      }
      // eslint-disable-next-line no-case-declarations
      const customEvent = customEvents.find((event) => event.event_id === action.event_id)
      if (get(customEvent, 'meta_type', null) !== 0) {
        const actionCost = action.cost
        if (typeof actionCost === 'string') {
          const cost = Number(actionCost)
          const formulaErrors = getFormulaErrors(actionCost)
          if ((!cost && formulaErrors.length) || actionCost.length === 0) {
            errors.push({ prop: 'cost', message: ErrorMessages.ACTION_FIRE_EVENT_COST_REQUIRED })
          }
          errors = [...errors, ...formulaErrors]
        } else if (!actionCost) {
          errors.push({ prop: 'cost', message: ErrorMessages.ACTION_FIRE_EVENT_COST_REQUIRED })
        }
      }
      break
    case ActionTypes.SUBSCRIBE:
    case ActionTypes.UNSUBSCRIBE:
      if (!action.sequence_id) {
        errors.push({ prop: 'sequence_id', message: ErrorMessages.ACTION_SEQUENCE_REQUIRED })
      }
      break
    case ActionTypes.SET_CUF:
    case ActionTypes.SET_GAF:
      if (!action.field_id) {
        errors.push({ prop: 'field_id', message: ErrorMessages.ACTION_SET_CUF_FIELD_REQUIRED })
      } else if (!action.value) {
        errors.push({ prop: 'value', message: ErrorMessages.ACTION_SET_CUF_VALUE_REQUIRED })
      } else if (
        userFields &&
        (userFields.find((cuf) => cuf.field_id === action.field_id) || {}).type ===
          UserFieldsTypes.NUMBER
      ) {
        if (!action.value) {
          return errors
        }

        const formulaErrors = getFormulaErrors(action.value)
        errors = [...errors, ...formulaErrors]
      }
      break
    case ActionTypes.UNSET_CUF:
      if (!action.field_id) {
        errors.push({ prop: 'field_id', message: ErrorMessages.ACTION_UNSET_CUF_FIELD_REQUIRED })
      }
      break

    case ActionTypes.EXTERNAL_REQUEST:
      if (!action.url) {
        errors.push({ prop: 'url', message: ErrorMessages.ACTION_EXTERNAL_REQUEST_URL_REQUIRED })
      }
      break
    case ActionTypes.HUBSPOT_INTEGRATION: {
      if (!action.action) {
        errors.push({ prop: 'field_id', message: ErrorMessages.ACTION_HUBSPOT_NOT_SET })
      } else if (
        action.action === HubSpotActionTypes.SUBMIT_FORM &&
        !get(action.data, 'form_guid')
      ) {
        errors.push({ prop: 'field_id', message: ErrorMessages.ACTION_HUBSPOT_FORM_REQUIRED })
      } else if (
        action.action === HubSpotActionTypes.CREATE_UPDATE_CONTACT ||
        action.action === HubSpotActionTypes.GET_CONTACT
      ) {
        const { type, field_id } = get(action, 'data.identity', {})
        if ((type === 'cuf' || !type) && !field_id) {
          errors.push({
            prop: 'field_id',
            message: ErrorMessages.ACTION_HUBSPOT_IDENTITY_FIELD_NOT_SET,
          })
        }
      }
      break
    }
    case ActionTypes.SALESFORCE_INTEGRATION: {
      if (!action.action) {
        errors.push({ prop: 'field_id', message: ErrorMessages.ACTION_SALESFORCE_NOT_SET })
      } else if (
        action.action === SalesforceActionTypes.CREATE_UPDATE_CONTACT ||
        action.action === SalesforceActionTypes.GET_CONTACT
      ) {
        const { type, field_id } = get(action, 'data.identity', {})
        if ((type === 'cuf' || !type) && !field_id) {
          errors.push({
            prop: 'field_id',
            message: ErrorMessages.ACTION_SALESFORCE_IDENTITY_FIELD_NOT_SET,
          })
        }
      }
      break
    }
    case ActionTypes.CONVERTKIT_INTEGRATION: {
      if (!action.action) {
        errors.push({ prop: 'field_id', message: ErrorMessages.ACTION_CONVERTKIT_NOT_SET })
      } else if (
        action.action === ConvertKitActionTypes.ADD_TO_FORM &&
        !get(action.data, 'form_id')
      ) {
        errors.push({ prop: 'field_id', message: ErrorMessages.ACTION_CONVERTKIT_FORM_REQUIRED })
      } else if (
        action.action === ConvertKitActionTypes.ADD_TO_SEQUENCE &&
        !get(action.data, 'sequence_id')
      ) {
        errors.push({
          prop: 'field_id',
          message: ErrorMessages.ACTION_CONVERTKIT_SEQUENCE_REQUIRED,
        })
      } else if (action.action === ConvertKitActionTypes.ADD_TAG && !get(action.data, 'tag_id')) {
        errors.push({ prop: 'field_id', message: ErrorMessages.ACTION_CONVERTKIT_TAG_REQUIRED })
      } else {
        const { type, field_id } = get(action, 'data.identity', {})
        if ((type === 'cuf' || !type) && !field_id) {
          errors.push({
            prop: 'field_id',
            message: ErrorMessages.ACTION_CONVERTKIT_IDENTITY_FIELD_NOT_SET,
          })
        }
      }
      break
    }
    case ActionTypes.CLAUDE_INTEGRATION: {
      if (action.action === ClaudeActionTypes.REQUEST_TO_CLAUDE_CHAT && !action.data) {
        errors.push({
          prop: 'field_id',
          message: ErrorMessages.ACTION_CLAUDE_EMPTY,
        })
      }
      break
    }
    case ActionTypes.DEEPSEEK_INTEGRATION: {
      if (action.action === DeepSeekActionTypes.REQUEST_TO_DEEPSEEK_CHAT && !action.data) {
        errors.push({
          prop: 'field_id',
          message: ErrorMessages.ACTION_DEEP_SEEK_EMPTY,
        })
      }
      break
    }
    case ActionTypes.CHATGPT_INTEGRATION: {
      if (!action.action) {
        errors.push({ prop: 'field_id', message: ErrorMessages.ACTION_CHATGPT_NOT_SET })
      } else if (action.action === ChatGPTActionTypes.REQUEST_TO_CHATGPT) {
        const maxTokens = Number(get(action.data, 'max_tokens'))
        const temperature = Number(get(action.data, 'temperature'))

        if (!get(action.data, 'prompt')) {
          errors.push({
            prop: 'prompt',
            message: ErrorMessages.ACTION_CHATGPT_REQUEST_PROMPT_REQUIRED,
          })
        } else if (!get(action.data, 'field_id')) {
          errors.push({
            prop: 'field_id',
            message: ErrorMessages.ACTION_CHATGPT_REQUEST_FIELD_REQUIRED,
          })
        } else if (!get(action.data, 'engine')) {
          errors.push({
            prop: 'engine',
            message: ErrorMessages.ACTION_CHATGPT_REQUEST_ENGINE_REQUIRED,
          })
        } else if (isMaxTokensInvalid(maxTokens)) {
          errors.push({
            prop: 'max_tokens',
            message: ErrorMessages.ACTION_CHATGPT_REQUEST_MAX_TOKENS_REQUIRED,
          })
        } else if (isTemperatureInvalid(temperature)) {
          errors.push({
            prop: 'temperature',
            message: ErrorMessages.ACTION_CHATGPT_REQUEST_TEMPERATURE_REQUIRED,
          })
        }
      }
      break
    }
    case ActionTypes.ACTIVE_CAMPAIGN_INTEGRATION: {
      if (!action.action) {
        errors.push({ prop: 'field_id', message: ErrorMessages.ACTION_ACTIVE_CAMPAIGN_NOT_SET })
      } else {
        const { type, field_id } = get(action, 'data.identity', {})
        if ((type === 'cuf' || !type) && !field_id) {
          errors.push({
            prop: 'field_id',
            message: ErrorMessages.ACTION_ACTIVE_CAMPAIGN_IDENTITY_FIELD_NOT_SET,
          })
        }
      }
      break
    }
    case ActionTypes.KLAVIYO: {
      if (!action.action) {
        errors.push({ prop: 'field_id', message: ErrorMessages.ACTION_KLAVIO_NOT_SET })
      } else {
        const { type, field_id } = get(action, 'data.identity', {})
        if ((type === 'cuf' || !type) && !field_id) {
          errors.push({
            prop: 'field_id',
            message: ErrorMessages.ACTION_KLAVIO_IDENTITY_FIELD_NOT_SET,
          })
        }
      }
      break
    }
    case ActionTypes.GOOGLE_SHEETS_INTEGRATION: {
      if (!action.action) {
        errors.push({ prop: 'field_id', message: ErrorMessages.ACTION_GOOGLE_SHEET_NOT_SET })
      } else if (action.action === GoogleSheetsActionTypes.ADD_ROW) {
        if (!get(action.data, 'spreadsheet')) {
          errors.push({
            prop: 'spreadsheet',
            message: ErrorMessages.ACTION_GOOGLE_SHEET_SPREADSHEET_NOT_SET,
          })
        } else if (!get(action.data, 'sheet')) {
          errors.push({ prop: 'sheet', message: ErrorMessages.ACTION_GOOGLE_SHEET_SHEET_NOT_SET })
        } else if (!get(action.data, 'binding', []).length) {
          errors.push({
            prop: 'binding',
            message: ErrorMessages.ACTION_GOOGLE_SHEET_BINDING_NOT_SET,
          })
        }
      }
      break
    }
    case ActionTypes.MAILCHIMP_INTEGRATION: {
      if (!action.action) {
        errors.push({ prop: 'field_id', message: ErrorMessages.ACTION_MAILCHIMP_NOT_SET })
      } else if (
        action.action === MailChimpActionTypes.ADD_SUBSCRIBER &&
        !get(action.data, 'list_id')
      ) {
        errors.push({ prop: 'field_id', message: ErrorMessages.ACTION_MAILCHIMP_LIST_REQUIRED })
      } else {
        const { type, field_id } = get(action, 'data.identity', {})
        if ((type === 'cuf' || !type) && !field_id) {
          errors.push({
            prop: 'field_id',
            message: ErrorMessages.ACTION_MAILCHIMP_IDENTITY_FIELD_NOT_SET,
          })
        }
      }
      break
    }
    case ActionTypes.START_FLOW: {
      if (!action.flow_ns) {
        errors.push({ prop: 'flow_ns', message: ErrorMessages.ACTION_START_FLOW_FLOW_REQUIRED })
      }
      break
    }
    case ActionTypes.ASSIGN_CONVERSATION: {
      if (!action.user_id && !action.group_id) {
        errors.push({
          prop: 'user_id',
          message: data.assignConversationsToGroups
            ? ErrorMessages.ACTION_ASSIGN_CONVERSATION_USER_OR_GROUP_REQUIRED
            : ErrorMessages.ACTION_ASSIGN_CONVERSATION_USER_REQUIRED,
        })
      }
      break
    }
    case ActionTypes.SET_MAIN_MENU:
      if (!action.main_menu_flow_ns) {
        errors.push({ prop: 'flow_ns', message: ErrorMessages.ACTION_SET_MAIN_MENU_NS_REQUIRED })
      }
      break
    case ActionTypes.CUSTOM_AUDIENCE_USER: {
      if (
        !action.user_id ||
        !action.ad_account_id ||
        !action.custom_audience_id ||
        !action.action
      ) {
        errors.push({
          prop: 'ad_account_id',
          message: ErrorMessages.ACTION_CUSTOM_AUDIENCE_USER_NOT_SET,
        })
      }
      break
    }
  }
  return errors
}

export const getActionListErrors = (actions, data) => {
  return actions.reduce((acc, action, actionIndex) => {
    const errors = getActionErrors(action, data).map((error) => ({
      ...error,
      actionIndex,
      message: `Action ${actionIndex + 1}: ${l.getString(error.message)}`,
    }))
    return [...acc, ...errors]
  }, [])
}

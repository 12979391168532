import React, { useCallback, useMemo, useState } from 'react'
import { useAppDispatch, useAppSelector } from 'reduxTyped'
import { Modal, l } from '@manychat/manyui'

import { customMoveToSuccessAlert } from 'apps/cms/components/Flows/components/AutomationModals/utils/customMoveToSuccessAlert'
import { FoldersStructure } from 'apps/cms/components/FolderStructure/FoldersStructure'
import { CmsAnalytics } from 'apps/cms/lib/analytics'
import { contentManagementActions, contentManagementSelectors } from 'apps/cms/store'
import { AutomationModalType } from 'apps/cms/store/lib/types'

import cm from './BulkMoveToModal.module.css'

export const BulkMoveToModal = () => {
  const [isMoving, setMoving] = useState(false)
  const dispatch = useAppDispatch()
  const selectedFlows = useAppSelector(contentManagementSelectors.getSelectedFlow)
  const currentPath = useAppSelector(contentManagementSelectors.getQueryPath)
  const flowsSelectedAmount = selectedFlows.length

  const [activeFolder, setActiveFolder] = useState('')

  const handleClose = useCallback(() => {
    dispatch(contentManagementActions.closeModal(AutomationModalType.BULK_MOVE_TO))
  }, [dispatch])

  const handleFolderClick = useCallback((folderPath: string) => setActiveFolder(folderPath), [])

  const handleMove = useCallback(async () => {
    setMoving(true)
    await dispatch(
      contentManagementActions.bulkMoveTo({
        pathMoveTo: activeFolder,
        customSuccessAlert: customMoveToSuccessAlert,
      }),
    )
    CmsAnalytics.logBulkMove({ count: flowsSelectedAmount })
    setMoving(false)
    handleClose()
  }, [activeFolder, dispatch, handleClose, flowsSelectedAmount])

  const modalTitle = useMemo(() => {
    return l.translate('Move {count, plural, one {# item} other {# items}} to folder', {
      count: flowsSelectedAmount,
    })
  }, [flowsSelectedAmount])

  const buttons = useMemo(
    () => [
      {
        onClick: handleClose,
        label: l.translate('Close'),
        disabled: isMoving,
        ['data-test-id']: 'move-folder-modal-close-btn',
      },
      {
        variant: 'primary',
        loading: isMoving,
        disabled: !activeFolder,
        onClick: handleMove,
        label: l.translate('Move'),
        ['data-test-id']: 'move-folder-modal-create-btn',
      },
    ],
    [activeFolder, handleClose, handleMove, isMoving],
  )

  return (
    <Modal
      width={460}
      title={<div className="p-y-xs">{modalTitle}</div>}
      open
      onRequestClose={handleClose}
      buttons={buttons}
    >
      <div className={cm.folders}>
        <FoldersStructure
          onSelect={handleFolderClick}
          disabledFolder={currentPath}
          disabledTooltip={l.translate("You can't move a flow in the same folder.")}
        />
      </div>
    </Modal>
  )
}

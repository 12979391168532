import { ITemplateAccessData, ITemplateEntity } from 'apps/templates/constants/templatesInterfaces'
import { IFlow } from 'common/flow/flowInterfaces'

export enum StatusesBits {
  OWNER = 1,
  DISABLED = 2,
  LOCKED = 4,
  PROTECTED = 8,
  TRACKED = 16,
}

export const convertFlagsToNumber = (accessData: ITemplateAccessData): number => {
  const FlagsBitMap: Record<string, StatusesBits> = {
    is_owner: StatusesBits.OWNER,
    is_disabled: StatusesBits.DISABLED,
    is_locked: StatusesBits.LOCKED,
    is_protected: StatusesBits.PROTECTED,
    is_tracked: StatusesBits.TRACKED,
  }

  return Object.keys(accessData).reduce(
    (acc, key) => (accessData[key] && FlagsBitMap[key] ? FlagsBitMap[key] + acc : acc),
    0,
  )
}

type accessCheck = (accessData: ITemplateAccessData) => boolean
type entityCheck = (accessData: ITemplateEntity | IFlow) => boolean

const makeStatusCheck = (func: accessCheck) => (entity: ITemplateEntity | IFlow) => {
  const accessData = entity?.template_installation_access_data
  return accessData ? func(accessData) && !accessData?.is_owner : false
}

const getIsProtected: accessCheck = (accessData) => Boolean(accessData?.is_protected)

export const getIsEntityProtected: entityCheck = makeStatusCheck(getIsProtected)

export const getIsEntityDisabled: entityCheck = makeStatusCheck((accessData) => {
  return Boolean(getIsProtected(accessData) && accessData?.is_disabled)
})

export const getIsEntityBlocked: entityCheck = makeStatusCheck((accessData) => {
  return Boolean(getIsProtected(accessData) && (accessData?.is_disabled || accessData?.is_locked))
})

import isEqual from 'lodash/isEqual'

import { Keyword, KeywordInitial, KeywordRule } from 'apps/keywords/keywordsInterfaces'
import { getKeywordStringPreview } from 'apps/keywords/lib/getKeywordStringPreview'
import {
  ConditionTypeLabels,
  ConditionTypes,
  SystemKeywordNames,
} from 'apps/keywords/models/KeywordRule/constants'

export const isSystem = (keyword: Keyword) => {
  return Boolean(keyword.is_reserved)
}

export const isOriginalSystemKeyword = (keyword: Keyword) => {
  return isOriginalOptInKeyword(keyword) || isOriginalOptOutKeyword(keyword)
}

export const isOriginalOptInKeyword = (keyword: Keyword) => {
  return (
    isSystem(keyword) &&
    keyword.keyword_rules[0] &&
    keyword.keyword_rules[0].condition === ConditionTypes.EQUALS &&
    isEqual(keyword.keyword_rules[0].keywords, [
      SystemKeywordNames.START,
      SystemKeywordNames.SUBSCRIBE,
    ])
  )
}

export const isOriginalOptOutKeyword = (keyword: Keyword) => {
  return (
    isSystem(keyword) &&
    keyword.keyword_rules[0] &&
    keyword.keyword_rules[0].condition === ConditionTypes.EQUALS &&
    isEqual(keyword.keyword_rules[0].keywords, [
      SystemKeywordNames.STOP,
      SystemKeywordNames.UNSUBSCRIBE,
    ])
  )
}

export const isInitial = (keyword: Keyword | KeywordInitial): keyword is KeywordInitial =>
  !keyword.rule_id

export const isSaved = (keyword: Keyword) => Boolean(keyword.rule_id)

export const isDraft = (keyword: Keyword) => {
  return keyword.status === 'draft'
}

export const generateFlowName = (keyword: Keyword) => getKeywordStringPreview(keyword.keyword_rules)

export const getConditionLabel = (value: ConditionTypes, isLowerCase?: boolean) => {
  const conditionTypeValue = value === ConditionTypes.THUMBS_UP ? ConditionTypes.EQUALS : value
  const unformattedLabel = ConditionTypeLabels[conditionTypeValue]?.() || ''

  return isLowerCase ? unformattedLabel.toLowerCase() : unformattedLabel
}

export const hasSystemKeywordNames = (keywords: string[]) => {
  return keywords.some((keyword) =>
    Object.values(SystemKeywordNames).includes(keyword.toLowerCase()),
  )
}

export const clearEmptyKeywords = (data: Keyword | KeywordInitial): Keyword | KeywordInitial => {
  const rules: KeywordRule[] = []

  data.keyword_rules.forEach((rule) => {
    const keywords = rule.keywords.filter((keyword) => keyword.trim().length)

    if (keywords.length || rule.condition === ConditionTypes.THUMBS_UP) {
      rules.push({ ...rule, keywords })
    }
  })

  return { ...data, keyword_rules: rules }
}

export const isThumbsUpKeyword = (data: Keyword | KeywordInitial): boolean => {
  return (
    data.keyword_rules.length === 1 && data.keyword_rules[0].condition === ConditionTypes.THUMBS_UP
  )
}

import cloneDeep from 'lodash/cloneDeep'
import get from 'lodash/get'

import { Keyword } from 'apps/keywords/keywordsInterfaces'
import { getKeywordStringPreview } from 'apps/keywords/lib/getKeywordStringPreview'
import { TriggerType } from 'common/flow/constants/TriggerType'
import { Keyword as KeywordBasedOnSchema } from 'shared/api/requests/keywords/schemas'

import { KeywordStatus } from './constants'

const parseKeyword = (item: KeywordBasedOnSchema): Keyword => {
  const keyword = cloneDeep(item) as Keyword

  keyword.id = item.rule_id
  keyword.triggerType = TriggerType.KEYWORD
  keyword.triggerId = `${TriggerType.KEYWORD}-${keyword.id}`

  keyword.flowId = get(item, 'flow.ns', null)
  keyword.chId = get(item, 'content_holder', null)
  keyword.enabled = item.status === KeywordStatus.LIVE

  keyword.caption = getKeywordStringPreview(item.keyword_rules)

  return keyword
}

export default parseKeyword

import { z } from 'zod'

export const LiveChatUserSettingsSchema = z.object({
  lc_notify_sound: z.boolean(),
  lc_notify_assignment: z.boolean(),
  lc_notify_mention: z.boolean(),
  lc_notify_new_message_from_assigned: z.boolean(),
  lc_notify_new_message_from_unassigned: z.boolean(),
  lc_push_notify_new_message_from_assigned: z.boolean(),
  lc_push_notify_new_message_from_unassigned: z.boolean(),
  lc_push_notify_assignment: z.boolean(),
  lc_push_notify_mention: z.boolean(),
})

import React from 'react'

import { ManyChatOnboarding } from 'utils/services/newOnboardingService/ManyChatOnboardingIdType'
import OnboardingModal, {
  IModalConfig,
} from 'utils/services/newOnboardingService/view/OnboardingModal/OnboardingModal'
import OnboardingProgress, {
  ProgressConfig,
} from 'utils/services/newOnboardingService/view/OnboardingProgress/OnboardingProgress'
import Pointer from 'utils/services/newOnboardingService/view/Pointer/Pointer'
import { IPointer } from 'utils/services/newOnboardingService/view/Pointer/pointerInterfaces'

const DefaultStepView = ({
  pointerConfig,
  progressConfig,
  modalConfig,
  onb,
  stepId,
}: {
  pointerConfig?: IPointer
  progressConfig?: ProgressConfig
  modalConfig?: IModalConfig
  onb: ManyChatOnboarding
  stepId: string
}) => {
  const onboardingId = onb.id
  const handleSkip = () => {
    onb.skip()
  }

  let pointer = null
  if (pointerConfig) {
    pointer = (
      <Pointer
        pointerConfig={pointerConfig}
        onboardingId={onboardingId}
        stepId={stepId}
        onLeave={handleSkip}
      />
    )
  }

  let progress = null
  if (progressConfig) {
    progress = (
      <OnboardingProgress
        progressConfig={progressConfig}
        onboardingId={onboardingId}
        stepId={stepId}
        onSkip={handleSkip}
      />
    )
  }

  let modal = null
  if (modalConfig) {
    modal = <OnboardingModal modalConfig={modalConfig} />
  }

  return (
    <>
      {pointer}
      {progress}
      {modal}
    </>
  )
}

export default DefaultStepView

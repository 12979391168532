export const NODE_PLACEHOLDER_ID = 'nodeplaceholder'
export const NODE_BADGE_ID = 'nodebadge'
export const NODE_CLEAR_BUTTON_ID = 'nodeclearbutton'
export const NODE_OPEN_FLOW_BUTTON_ID = 'nodeopenflowbutton'
export const NODE_CREATE_TRIGGER_BUTTON_ID = 'nodecreatetriggerbutton'
export const NODE_TRIGGER_BUTTON_PREFIX = 'triggerButton'
export const NODE_STATS_TABLE_ITEM = 'nodestatstableitem'
export const BUILDER_CANVAS_ID = 'flow-builder-canvas'

export const AI_AGENT_DESCRIPTION_MAX_LENGTH = 100

import { StripeCheckoutTypes } from 'common/billing/constants/stripeCheckoutTypes'
import { dataLayer, gtmAnalytics, GTMAnalyticsEvents } from 'utils/services/analytics'

interface SendStripeCheckoutAnalytics {
  checkoutType: StripeCheckoutTypes
  amount: number
  currentPageId: string | null
  userId: number | null
}

export const sendStripeCheckoutAnalytics = ({
  checkoutType,
  currentPageId,
  userId,
  amount,
}: SendStripeCheckoutAnalytics) => {
  dataLayer.push({
    event: 'UPGRADED_TO_PRO',
    PAGE_ID: currentPageId || null,
    USER_ID: userId || null,
    SOURCE: checkoutType,
    AMOUNT: amount,
  })

  if (checkoutType === StripeCheckoutTypes.TRIAL) {
    gtmAnalytics.sendConversationEvent(GTMAnalyticsEvents.TRIAL_CONNECTION)
  }
}

import { z } from 'zod'

export const UserFieldSchema = z.object({
  field_id: z.number(),
  type: z.string(),
  segment_type: z.string(),
  caption: z.string(),
  description: z.string().nullable(),
  status: z.string(),
  folder_id: z.number(),
  ts_created: z.string().optional(),
})

export const UserFieldsSchemas = {
  moveToFolder: {
    response: z.object({
      field: UserFieldSchema,
    }),
    request: z.object({
      field_id: z.number(),
      folder_id: z.number(),
    }),
    query: z.undefined(),
    path: z.undefined(),
  },
  moveToFolderBulk: {
    response: z.object({
      models: z.array(UserFieldSchema),
    }),
    request: z.object({
      ids: z.array(z.number()),
      folder_id: z.number(),
    }),
    query: z.undefined(),
    path: z.undefined(),
  },
  getFolderContentCount: {
    response: z.object({
      fields_count: z.number(),
    }),
    query: z
      .object({
        path: z.string().optional(),
        active_only: z.string().optional(),
      })
      .optional(),
    path: z.undefined(),
  },
}

import TemplateInstallationSteps from 'apps/templates/constants/TemplateInstallationSteps'
import { getHandle } from 'utils/router/utils/getHandle'

export const TemplatesRoutes = [
  {
    path: 'templates/:template_id',
    handle: getHandle('templates'),
    children: [
      {
        path: 'choose',
        lazy: () => import('./installationWizard/Step2.page'),
      },
      {
        path: `install/:page_id/${TemplateInstallationSteps.STEP3}`,
        lazy: () => import('./installationWizard/Step3.page'),
      },
    ],
  },
  {
    path: 'template/:template_hash',
    handle: getHandle('templates'),
    children: [
      {
        path: TemplateInstallationSteps.STEP1,
        lazy: () => import('./installationWizard/Step1.page'),
      },
      {
        path: TemplateInstallationSteps.STEP2,
        lazy: () => import('./installationWizard/Step2.page'),
      },
      {
        path: 'install/:page_id',
        children: [
          {
            path: TemplateInstallationSteps.STEP3,
            lazy: () => import('./installationWizard/Step3.page'),
          },
          {
            path: `${TemplateInstallationSteps.STEP4}/:installation_id`,
            lazy: () => import('./installationWizard/Step4.page'),
          },
        ],
      },
      {
        path: TemplateInstallationSteps.STEP3,
        lazy: () => import('./installationWizard/Step3.page'),
      },
    ],
  },
] as const

export const TemplatesProfileScopeRoutes = [
  {
    path: 'templates',
    handle: getHandle('templates'),
    children: [
      {
        path: 'create/:page_id?',
        lazy: () => import('./createTemplate.page'),
      },
      {
        path: ':template_id',
        children: [
          {
            index: true,
            lazy: () => import('./templateItem.page'),
          },
          {
            path: 'update',
            lazy: () => import('./updateTemplate.page'),
          },
        ],
      },
    ],
  },
] as const

export const TemplatesSettingsScopeRoutes = [
  {
    path: 'templates/:template_id',
    handle: getHandle('templates'),
    alias: 'settingTemplateItem',
    lazy: () => import('./installedTemplate.page'),
  },
] as const

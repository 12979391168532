export function pick<Obj extends object, Key extends keyof Obj>(obj: Obj, keys: Key[]) {
  const copy: Partial<Obj> = {}

  for (const key of keys) {
    if (key in obj) {
      copy[key] = obj[key]
    }
  }

  return copy as {
    [K in Key]: Obj[K]
  }
}

import { AddOnSlug } from 'common/billing/interfaces/addOnTypes'
import { ProductType, ProProduct } from 'common/core/interfaces/products'

import { CartProduct } from '../billingInterfaces'

export const isProProduct = (value: unknown): value is ProProduct => {
  return ([ProductType.AUTOMATION, ProductType.INBOX] as string[]).includes(value as string)
}

export const isAddOnSlug = (value: unknown): value is AddOnSlug => {
  return Object.values(AddOnSlug).includes(value as AddOnSlug)
}

export const isCartProductType = (value: unknown): value is CartProduct['type'] => {
  return ['addOn', 'plan'].includes(value as string)
}

import React, { useCallback } from 'react'
import { useNavigate } from 'react-router-dom'
import { useAppDispatch, useAppSelector } from 'reduxTyped'
import { l } from '@manychat/manyui'

import { useModalData } from 'apps/cms/components/Flows/components/AutomationModals/hooks/useModalData'
import { CmsAnalytics } from 'apps/cms/lib/analytics'
import { contentManagementSelectors, contentManagementActions } from 'apps/cms/store'
import { AutomationModalType } from 'apps/cms/store/lib/types'
import { alert } from 'common/core'
import { ChannelType } from 'common/core/constants/ChannelType'
import { FlowChannel } from 'common/flow/interfaces/channels'
import { handleCatch } from 'shared/api/lib/errors/handlers'
import { CmsApi } from 'shared/api/requests/cms'
import { linkURL } from 'utils/url'

type ConversionMap = Partial<Record<ChannelType, ChannelType>>

export const useConvertFlowChannels = () => {
  const dispatch = useAppDispatch()

  const navigate = useNavigate()

  const { flowNs } = useModalData({ type: AutomationModalType.CONVERT_FLOW_CHANNELS })

  const flowUsedChannels = useAppSelector((state) =>
    contentManagementSelectors.getFlowUsedChannelsList(state, flowNs),
  )

  const [conversionMap, setConversionMap] = React.useState<ConversionMap>({})

  const updateConversionMap = useCallback(
    (sourceChannel: FlowChannel, targetChannel: FlowChannel) => {
      setConversionMap((prevState) => ({
        ...prevState,
        [sourceChannel]: targetChannel,
      }))
    },
    [],
  )

  const closeModal = useCallback(() => {
    dispatch(contentManagementActions.closeModal(AutomationModalType.CONVERT_FLOW_CHANNELS))
  }, [dispatch])

  const convertChannels = useCallback(async () => {
    CmsAnalytics.logConvertChannels()

    try {
      const { data } = await CmsApi.convertFlow({
        body: {
          flow_ns: flowNs,
          convert_channels: conversionMap,
        },
      })

      alert(l.translate('Automation converted'), 'success', {
        icon: 'CheckCircle',
        source: l.translate('There might be a loss of content, please check it'),
      })

      navigate(linkURL(`/cms/files/${data.fs_object.flow.ns}`))
    } catch (error) {
      handleCatch(error)
    }

    closeModal()
  }, [closeModal, flowNs, conversionMap, navigate])

  return {
    flowUsedChannels,
    convertChannels,
    updateConversionMap,
    conversionMap,
    closeModal,
  }
}

import assert from 'assert'

import camelCase from 'lodash/camelCase'
import values from 'lodash/values'

import { NodeType } from 'common/builder/constants/NodeType'
import actionGroupConfig from 'common/builder/nodes/actionGroup/ActionGroupNodeConfig'
import commentConfig from 'common/builder/nodes/comment/CommentNodeConfig'
import conditionConfig from 'common/builder/nodes/condition/ConditionNodeConfig'
import contentConfig from 'common/builder/nodes/content/ContentNodeConfig'
import emailNewConfig from 'common/builder/nodes/emailNew/EmailNewNodeConfig'
import gotoConfig from 'common/builder/nodes/goto/GotoNodeConfig'
import instagramConfig from 'common/builder/nodes/instagram/InstagramNodeConfig'
import { INode, INodeConfig } from 'common/builder/nodes/nodeInterfaces'
import smartDelayConfig from 'common/builder/nodes/smartDelay/SmartDelayNodeConfig'
import smsConfig from 'common/builder/nodes/sms/SmsNodeConfig'
import splitConfig from 'common/builder/nodes/split/SplitNodeConfig'
import startingStepConfig from 'common/builder/nodes/startingStep/StartingStepNodeConfig'
import telegramConfig from 'common/builder/nodes/telegram/TelegramNodeConfig'
import tiktokConfig from 'common/builder/nodes/tiktok/TiktokNodeConfig'
import whatsAppConfig from 'common/builder/nodes/whatsApp/WhatsAppNodeConfig'

import { aiAgentNodeConfig } from './nodes/agent/AiAgentNodeConfig'

type Configs =
  | typeof actionGroupConfig
  | typeof commentConfig
  | typeof conditionConfig
  | typeof contentConfig
  | typeof emailNewConfig
  | typeof gotoConfig
  | typeof instagramConfig
  | typeof telegramConfig
  | typeof smartDelayConfig
  | typeof smsConfig
  | typeof splitConfig
  | typeof startingStepConfig
  | typeof whatsAppConfig
  | typeof aiAgentNodeConfig
  | typeof tiktokConfig

const configMap: Record<string, Configs> = {
  actionGroupConfig,
  commentConfig,
  conditionConfig,
  contentConfig,
  emailNewConfig,
  gotoConfig,
  instagramConfig,
  telegramConfig,
  smartDelayConfig,
  smsConfig,
  splitConfig,
  startingStepConfig,
  whatsAppConfig,
  agentConfig: aiAgentNodeConfig,
  tiktokConfig,
}

const get = (node: INode): INodeConfig => {
  assert(node, 'nodeRegistry.get: node is required')
  return getByType(node.nodeType)
}

const getByType = (nodeType: NodeType): INodeConfig => {
  assert(nodeType, 'nodeRegistry.getByType: nodeType is required')
  assert(values(NodeType).includes(nodeType), `nodeRegistry: unknown nodeType ${nodeType}`)

  const configName = `${camelCase(nodeType)}Config`
  return configMap[configName] as INodeConfig
}

export default {
  get,
  getByType,
}

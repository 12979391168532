import * as atypes from 'apps/chat/constants/LiveChatReduxActionTypes'
import { IActionWithPayload } from 'common/core/interfaces/actions'

import { CountersState } from '../models/chatInterfaces'

const initialState: CountersState = {
  unreadThreads: null,
  users: null,
  isFetching: false,
  isThreadsAssignmentChanged: false,
}

export default function chatSettingsReducer(state = initialState, action: IActionWithPayload) {
  switch (action.type) {
    case atypes.THREADS_ASSIGN:
    case atypes.THREADS_UNASSIGN:
    case atypes.THREADS_UPDATED_NOTIFICATION: {
      return {
        ...state,
        isThreadsAssignmentChanged: true,
      }
    }

    case atypes.FETCH_THREAD_COUNTERS_REQUEST: {
      return {
        ...state,
        isFetching: true,
        isThreadsAssignmentChanged: false,
      }
    }
    case atypes.FETCH_THREAD_COUNTERS_RESPONSE: {
      return {
        ...state,
        users: action.data.users,
        unreadThreads: action.data.has_unread_threads,
        isFetching: false,
      }
    }
    case atypes.FETCH_THREAD_COUNTERS_ERROR: {
      return {
        ...state,
        isFetching: false,
      }
    }

    case atypes.UPDATE_USER_COUNTERS: {
      const {
        data: { user_id, account_id, thread_counters },
      } = action
      const updatedUsers = [...(state.users || [])]
      const userCountersIndex = updatedUsers.findIndex((user) => user.user_id === user_id)
      if (userCountersIndex === -1) return state

      updatedUsers.splice(userCountersIndex, 1, { account_id, user_id, thread_counters })

      return {
        ...state,
        users: updatedUsers,
      }
    }

    case atypes.UPDATE_UNREAD_THREADS: {
      const { data } = action
      const { unassigned, on_me } = data

      return {
        ...state,
        unreadThreads: { unassigned, on_me },
      }
    }
  }
  return state
}

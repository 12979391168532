import { z } from 'zod'

import { FilterOperator } from 'common/filter/filterConstants'

export const FilterGroupItemSchema = z.object({
  type: z.string().optional(),
  field: z.string().optional(),
  operator: z.string().optional(),
  value: z.number().or(z.string()).nullish(),
})

export const FilterGroupSchema = z.object({
  operator: z.nativeEnum(FilterOperator),
  items: z.array(FilterGroupItemSchema),
})

export const FilterSchema = z.object({
  operator: z.nativeEnum(FilterOperator),
  groups: z.array(FilterGroupSchema),
})

import get from 'lodash/get'
import { l } from '@manychat/manyui'

import { FB_REPLY_LIMIT } from 'apps/growthTools/constants/Validation'
import { PostCoveredArea } from 'apps/growthTools/models/Widget/constants'
import { ChannelType } from 'common/core/constants/ChannelType'
import { AccountTargetPermissionsNames } from 'common/core/models/Account/constants'
import { FieldType } from 'common/fields/entities/enums'

const IMAGE_OVERLAY_CLASSNAME = 'landingOverlayColor'

import waUrlExampleImage from '../../../../../../assets/img/wa_url_example.png'

import * as helpers from './../helpers'
import * as WidgetTypes from './WidgetTypes'

const checkboxNoteLabel = {
  display: 'tip',
  hideLabel: true,
  title: l.makeTranslate('Please Note!'),
  useInnerHTML: true,
  defaultValue: `Variables (for example "First name" variable) are not supported in the initial message of Opt-in message in widgets
                 with Checkbox plugin. But after a user becomes your subscribed contact, variables will work correctly.`,
  hidden: (widget) => !helpers.isCheckbox(widget),
}

const defaultActions = {
  opt_in_message: {
    label: l.makeTranslate('Opt-In Automation'),
    display: 'flowPicker',
  },
  noteLabel: {
    ...checkboxNoteLabel,
  },
}

export default {
  [WidgetTypes.MESSENGER_REF_URL]: {
    title: l.makeTranslate('Ref URL'),
    desc: {
      [ChannelType.FB]: l.makeTranslate(
        'A URL with a ref-code that opens your bot’s page in Messenger. Useful when you want to track traffic sources or have a separate welcome Automation for different audiences.',
      ),
      [ChannelType.WHATSAPP]: l.makeTranslate(
        'A URL with a ref-code that opens your bot’s page in WhatsApp. Useful when you want to track traffic sources or have a separate welcome Automation for different audiences.',
      ),
      [ChannelType.TELEGRAM]: l.makeTranslate(
        'A URL with a ref-code that opens your bot’s page in Telegram. Useful when you want to track traffic sources or have a separate welcome Automation for different audiences.',
      ),
      [ChannelType.INSTAGRAM]: l.makeTranslate(
        'A URL with a ref-code that opens your bot’s page in Instagram. Useful when you want to track traffic sources or have a separate welcome Automation for different audiences.',
      ),
    },
    permissionsVariable: {
      [ChannelType.FB]: AccountTargetPermissionsNames.GT_REF_URL_FB,
      [ChannelType.WHATSAPP]: AccountTargetPermissionsNames.GT_REF_URL_WHATSAPP,
      [ChannelType.TELEGRAM]: AccountTargetPermissionsNames.GT_REF_URL_TELEGRAM,
      [ChannelType.INSTAGRAM]: AccountTargetPermissionsNames.GT_REF_URL_INSTAGRAM,
    },
    group: 'other',
    settings: {
      [ChannelType.FB]: {
        actions: defaultActions,
        setup: {
          refUrl: {
            display: 'setup',
            label: l.makeTranslate('Your Messenger Ref URL'),
            copyEncodedUrl: true,
          },
          ref: {
            display: 'textInput',
            label: l.makeTranslate('Custom Ref parameter'),
          },
          ref_payload_field_id: {
            display: 'userFieldPicker',
            allowedTypes: [FieldType.TEXT],
            enableClear: true,
            label: l.makeTranslate('Save Payload to a Custom User Field'),
            labelTooltip: l.makeTranslate(
              'You can upgrade your Ref URL with specific affiliate’s ID, username or anything else. It will be saved in selected Custom User Field after contact is opted-in. To add variables in ref URL use -- at the end of the link, e.g. "m.me/123?ref=ref--PAYLOAD"',
            ),
          },
        },
      },
      [ChannelType.WHATSAPP]: {
        actions: defaultActions,
        setup: {
          ref: {
            display: 'textInput',
            label: l.makeTranslate("Contact's first message"),
            placeholder: l.makeTranslate('Text'),
            className: 'm-b-xs',
            maxLength: 250,
          },
          description: {
            display: 'text',
            className: 'm-b-sm',
            textClassName: 'text-sm',
            hideLabel: true,
            defaultValue: l.makeTranslate(
              "This text will be prefilled as customer's first message to the bot in their WhatsApp application.",
            ),
          },
          example: {
            display: 'example',
            defaultValue: null,
            imageUrl: waUrlExampleImage,
            hideLabel: true,
            accordionTitle: l.makeTranslate('How user sees it'),
          },
          refUrl: {
            display: 'setup',
            label: l.makeTranslate('Your WhatsApp URL'),
            copyEncodedUrl: true,
            saveWidgetText: l.makeTranslate('Save trigger to get new URL'),
          },
          code: {
            display: 'setup',
            hideLabel: true,
            className: 'm-t',
          },
        },
      },
      [ChannelType.TELEGRAM]: {
        actions: defaultActions,
        setup: {
          refUrl: {
            display: 'setup',
            label: l.makeTranslate('Your Telegram Ref URL'),
          },
          ref_payload_field_id: {
            display: 'userFieldPicker',
            allowedTypes: [FieldType.TEXT],
            enableClear: true,
            label: l.makeTranslate('Save Payload to a Custom User Field'),
            labelTooltip: l.makeTranslate(
              'You can upgrade your Ref URL with specific affiliate’s ID, username or anything else. It will be saved in selected Custom User Field after contact is opted-in. To add variables in ref URL use -- at the end of the link, e.g. "t.me/?start=ref--PAYLOAD"',
            ),
          },
        },
      },
    },
  },
  [WidgetTypes.FEED_COMMENT]: {
    isHidden: true,
    title: l.makeTranslate('Facebook Comments'),
    desc: l.makeTranslate('This Comments Trigger will be removed soon'),
    group: 'other',
    permissionsVariable: {
      [ChannelType.FB]: AccountTargetPermissionsNames.GT_CGT,
    },
    settings: {
      [ChannelType.FB]: {
        feed_comment_settings: {
          post_covered_area: {
            display: 'fbPostCoveredArea',
            label: l.makeTranslate('This Growth Tool will work with'),
            labelTooltip: l.makeTranslate(
              'You can set up a global Growth Tool for all posts or choose one specific  post.',
            ),
            defaultValue: PostCoveredArea.SPECIFIC_POST,
            options: [
              { value: PostCoveredArea.SPECIFIC_POST, label: l.makeTranslate('A specific post') },
              { value: PostCoveredArea.ALL_POSTS, label: l.makeTranslate('All posts') },
            ],
            disabled: (widget) => widget.status === 'active',
          },
          post_id: {
            display: 'fbPostPicker',
            label: l.makeTranslate('Facebook post for comment tracking'),
            disabled: (widget) =>
              widget.status === 'active' ||
              get(widget.data.feed_comment_settings, 'post_covered_area') ===
                PostCoveredArea.ALL_POSTS,
          },
          post_data: {
            display: 'none',
          },
          track_root_comment_only: {
            display: 'toggle',
            label: l.makeTranslate('Track first-level comments only'),
            labelTooltip: l.makeTranslate(
              'This option will disable the growth tool for second-level comments (i.e. replies).',
            ),
            defaultValue: true,
          },
          reply_delay: {
            display: 'measuredValue',
            label: l.makeTranslate('When contact leaves a comment, send message after'),
            defaultOption: 0,
            options: [
              { units: 'immediately', label: l.makeTranslate('Immediately'), disableValue: true },
              {
                units: 'seconds',
                label: l.makeTranslate('Seconds'),
                defaultValue: 10,
                minValue: 0,
                maxValue: 60,
              },
              {
                units: 'minutes',
                label: l.makeTranslate('Minutes'),
                defaultValue: 10,
                minValue: 1,
                maxValue: 60,
              },
            ],
          },
          exclude_keywords_array: {
            display: 'textInput',
            label: l.makeTranslate('Exclude comments with these keywords'),
            placeholder: l.makeTranslate('Text'),
            labelTooltip: l.makeTranslate('Comma-separated list'),
          },
          include_keywords_array: {
            display: 'textInput',
            label: l.makeTranslate('Only trigger for comments with these keywords'),
            placeholder: l.makeTranslate('Text'),
            labelTooltip: l.makeTranslate('Comma-separated list'),
          },
        },
        feed_comment_welcome: {
          welcome_content: {
            label: l.makeTranslate('Reply in Messenger'),
            display: 'simpleBuilder',
            hideLabel: true,
          },
        },
        actions: {
          opt_in_status: {
            display: 'radio',
            label: l.makeTranslate('Select what happens if contact types'),
            options: [
              {
                value: 'do_not_send',
                label: l.makeTranslate('Don’t send the opt-in message'),
                tooltip:
                  'Use this option in case you don’t want to send the Opt-In Message after a contact reply (e.g. you want to process the replies manually) or if you want to use global keywords to respond.',
              },
              {
                value: 'send_to_everyone',
                label: l.makeTranslate('Send to everyone who replies'),
                tooltip:
                  'Send the Opt-In Message to any contact who replies to the auto-response message.',
              },
              {
                value: 'send_when_contain_keyword',
                label: l.makeTranslate('Send only to users who reply with a keyword'),
                tooltip:
                  'Send the Opt-In Message only to users who reply with any of the keywords provided below. These keywords will be triggered for this exact growth tool only.',
              },
            ],
          },
          opt_in_keywords: {
            display: 'textInput',
            label: () => '',
            placeholder: l.makeTranslate('Enter keywords here, separated by commas'),
            hidden: (widget) => widget.data.actions.opt_in_status !== 'send_when_contain_keyword',
          },
          opt_in_message: {
            label: l.makeTranslate('Opt-In Automation'),
            display: 'flowPicker',
            hidden: (widget) =>
              widget.data.actions.opt_in_status === 'do_not_send' ||
              widget.data.actions.opt_in_status == null,
          },
          matching: {
            label: l.makeTranslate(
              'New! Now you can send an Opt-In Message to any contact that replies to the Auto-response message (no need for a global keyword). Select the “Send to everyone who replies” option and all users who respond to the Auto-response will get an Opt-In Message.',
            ),
            defaultValue: `You can also use local keywords to trigger the Opt-In Message. Select the option “Send only to users who reply with a keyword” and only contacts who reply with the provided keywords will get the Opt-In Message. These keywords can’t be triggered outside this growth tool, so you can actually use the same local keywords for different growth tools (e.g. now you can reuse the word “ok” or “yes” to trigger the Opt-In Messages in different growth tools).`,
            display: 'text',
            className: 'm-b',
            hidden: true,
          },
          matching_italic: {
            hideLabel: true,
            defaultValue: `Please note that due to technical limitations we cannot match exactly 100% of new contacts. All unmatched ones will become contacts but won’t get an Opt-In Message.`,
            display: 'text',
            className: 'text-i-c',
            hidden: true,
          },
          warningLabel: {
            display: 'tip',
            hideLabel: true,
            style: { marginTop: '32px' },
            title: l.makeTranslate('Be Careful! This is an experimental feature.'),
            defaultValue: `Due to technical limitations of Facebook platform, Manychat can't in some cases identify a user as a contact who came through the exact Comments GT.\n Those users still become your contacts, but won't receive the Opt-In Message and won't be shown as opted in through this Growth Tool.\n For this reason, please keep in mind that your conversation rate can be higher than displayed.`,
            hidden: (widget) => widget.data.actions.opt_in_status === 'do_not_send',
          },
        },
      },
    },
  },
  [WidgetTypes.FEED_COMMENT_TRIGGER]: {
    title: l.makeTranslate('Comments 2.0'),
    desc: {
      [ChannelType.FB]: l.makeTranslate(
        'Convert users to contacts, launch A/B tests, and create more complex automations for users who comment on your page’s Facebook posts',
      ),
      [ChannelType.INSTAGRAM]: l.makeTranslate(
        'Convert users to contacts, launch A/B tests, and create more complex automations for users who comment on your page’s Instagram posts',
      ),
    },
    permissionsVariable: {
      [ChannelType.FB]: AccountTargetPermissionsNames.GT_CGT_2,
      //TODO: Check permissions
      [ChannelType.INSTAGRAM]: AccountTargetPermissionsNames.GT_CGT_2,
    },
    group: 'other',
    settings: {
      [ChannelType.FB]: {
        feed_comment_settings: {
          post_covered_area: {
            display: 'fbPostCoveredArea',
            label: l.makeTranslate('This Growth Tool will work with'),
            labelTooltip: l.makeTranslate(
              'You can set up a global Growth Tool for all posts or choose one specific  post.',
            ),
            defaultValue: PostCoveredArea.SPECIFIC_POST,
            options: [
              {
                value: PostCoveredArea.SPECIFIC_POST,
                label: l.makeTranslate('A specific post'),
              },
              { value: PostCoveredArea.ALL_POSTS, label: l.makeTranslate('All posts') },
            ],
            disabled: (widget) => widget.status === 'active',
          },
          post_id: {
            display: 'fbPostPicker',
            label: l.makeTranslate('Facebook post for comment tracking'),
            disabled: (widget) =>
              widget.status === 'active' ||
              get(widget.data.feed_comment_settings, 'post_covered_area') ===
                PostCoveredArea.ALL_POSTS,
          },
          post_data: {
            display: 'none',
          },
          track_root_comment_only: {
            display: 'toggle',
            label: l.makeTranslate('Track first-level comments only'),
            labelTooltip: l.makeTranslate(
              'This option will disable the growth tool for second-level comments (i.e. replies).',
            ),
            defaultValue: true,
          },
          exclude_keywords: {
            display: 'textInput',
            label: l.makeTranslate('Exclude comments with these keywords'),
            placeholder: l.makeTranslate('Text'),
            labelTooltip: l.makeTranslate('Comma-separated list'),
          },
          include_keywords: {
            display: 'textInput',
            label: l.makeTranslate('Only trigger for comments with these keywords'),
            placeholder: l.makeTranslate('Text'),
            labelTooltip: l.makeTranslate('Comma-separated list'),
          },
        },
        feed_comment_welcome: {
          public_reply_messages: {
            display: 'fbMultipleCommentsReply',
            label: l.makeTranslate('Reply in Comments'),
            placeholder: l.makeTranslate('Enter text to reply to the contact comment on the post'),
            limit: FB_REPLY_LIMIT,
            minRows: 2,
            maxRows: 2,
            labelTooltip: l.makeTranslate(
              "Add one or multiple replies to automatically respond to users' comments under this post",
            ),
            value: (widget) => widget.data.feed_comment_welcome.public_reply_messages,
          },
          like_user_comment: {
            display: 'toggle',
            label: l.makeTranslate('Like comments'),
            defaultValue: false,
            labelTooltip: l.makeTranslate(
              'Automatically like users’ first replies in comments under this post',
            ),
          },
        },
        actions: {
          opt_in_message: {
            label: l.makeTranslate('Opt-In Automation'),
            display: 'flowPicker',
          },
        },
      },
      [ChannelType.INSTAGRAM]: {
        feed_comment_settings: {
          post_covered_area: {
            display: 'fbPostCoveredArea',
            label: l.makeTranslate('This Growth Tool will work with'),
            labelTooltip: l.makeTranslate(
              'You can set up a global Growth Tool for all publication or choose one specific publication.',
            ),
            defaultValue: PostCoveredArea.SPECIFIC_POST,
            options: [
              {
                value: PostCoveredArea.SPECIFIC_POST,
                label: l.makeTranslate('a specific post or reel'),
              },
              { value: PostCoveredArea.NEXT_POST, label: l.makeTranslate('next post or reel') },
              { value: PostCoveredArea.ALL_POSTS, label: l.makeTranslate('any post or reel') },
            ],
            disabled: (widget) => widget.status === 'active',
            channel: ChannelType.INSTAGRAM,
          },
          post_id: {
            display: 'fbPostPicker',
            label: l.makeTranslate('Instagram publication for comment tracking'),
            disabled: (widget) => widget.status === 'active',
            hidden: (widget) =>
              [PostCoveredArea.ALL_POSTS, PostCoveredArea.NEXT_POST].includes(
                get(widget.data.feed_comment_settings, 'post_covered_area'),
              ),
            channel: ChannelType.INSTAGRAM,
          },
          post_data: {
            display: 'none',
          },
          exclude_keywords_array: {
            display: 'textInput',
            label: l.makeTranslate('Exclude comments with these keywords'),
            placeholder: l.makeTranslate('Text'),
            labelTooltip: l.makeTranslate('Comma-separated list'),
          },
          include_keywords_array: {
            display: 'textInput',
            label: l.makeTranslate('Only trigger for comments with these keywords'),
            placeholder: l.makeTranslate('Text'),
            labelTooltip: l.makeTranslate('Comma-separated list'),
          },
        },
        feed_comment_welcome: {
          public_reply_messages: {
            display: 'igMultipleCommentsReply',
            label: l.makeTranslate('Reply in Comments'),
            placeholder: l.makeTranslate(
              'Enter text to reply to the contact comment on the publication',
            ),
            limit: FB_REPLY_LIMIT,
            minRows: 2,
            maxRows: 2,
            labelTooltip: l.makeTranslate(
              "Add one or multiple replies to automatically respond to users' comments under this publication",
            ),
            value: (widget) => widget.data.feed_comment_welcome.public_reply_messages,
          },
        },
        actions: {
          opt_in_message: {
            label: l.makeTranslate('Opt-In Automation'),
            display: 'flowPicker',
          },
        },
      },
    },
  },
  [WidgetTypes.ADS_JSON]: {
    proModalTitle: l.makeTranslate('Ad-to-subscriber in one click'),
    title: l.makeTranslate('Facebook Ads JSON'),
    desc: l.makeTranslate(
      'Facebook Ads JSON enables you to start Messenger conversations with users who click on a Facebook ad.',
    ),
    group: 'other',
    permissionsVariable: {
      [ChannelType.FB]: AccountTargetPermissionsNames.GT_FB_ADS_JSON,
    },
    settings: {
      [ChannelType.FB]: {
        actions: {
          opt_in_message: {
            label: l.makeTranslate('Opt-In Automation'),
            display: 'flowPicker',
          },
          hidden: {
            display: 'text',
            label: () => '',
            defaultValue:
              'A person will get into your list only if he presses a button in your message. So, keep in mind that the first message should contain a button.',
          },
        },
        setup: {
          info: {
            display: 'text',
            label: () => '',
            useInnerHTML: true,
            translatedDefaultValue: [
              l.makeTranslate(
                `1. Edit the content and publish the Automation. After it is published the JSON code based on opt-in message’s content will appear below. Copy this code.`,
              ),
              l.makeTranslate(
                `2. Go to <a class="text-primary" href="https://www.facebook.com/adsmanager/manage/ads" target="_blank" rel="noopener noreferrer">Ads Manager</a> (Advanced Setup) and replace the default JSON Code with the code copied.`,
              ),
              l.makeTranslate(
                `3. Make sure you attached at least one button or Quick Reply to the first message block.`,
              ),
            ],
            defaultValue: `1. Edit the content and publish the Automation. After it is published the JSON code based on opt-in message’s content will appear below. Copy this code.

              2. Go to <a class="text-primary" href="https://www.facebook.com/adsmanager/manage/ads" target="_blank" rel="noopener noreferrer">Ads Manager</a> (Advanced Setup) and replace the default JSON Code with the code copied.

              3. Make sure you attached at least one button or Quick Reply to the first message block.`,
          },
          learnMore: {
            display: 'jsonAdsLearnMore',
            label: l.makeTranslate('Learn more about how it works quickly 👇'),
          },
          refUrl: {
            display: 'setup',
            labelTooltip: l.makeTranslate(
              'Generated JSON code depends on the first item in your Opt-In Message. Every time you change it, you also need to generate the new JSON.\n You can use the following JSON code only for the current Facebook page.',
            ),
            label: l.makeTranslate('Your Facebook Ads JSON Code'),
          },
        },
      },
    },
  },
  [WidgetTypes.ADS]: {
    proModalTitle: l.makeTranslate('Ad-to-subscriber in one click'),
    title: l.makeTranslate('Ads'),
    desc: l.makeTranslate(
      'Ads JSON enables you to initiate conversations with users who click on an ad.',
    ),
    group: 'other',
    permissionsVariable: {
      [ChannelType.FB]: AccountTargetPermissionsNames.GT_FB_ADS_JSON,
      [ChannelType.INSTAGRAM]: AccountTargetPermissionsNames.GT_IG_ADS_JSON,
    },
    settings: {
      [ChannelType.FB]: {},
      [ChannelType.IG]: {},
    },
  },
  [WidgetTypes.FACEBOOK_SHOP]: {
    title: l.makeTranslate('Facebook Shop'),
    desc: () => '',
    permissionsVariable: {
      [ChannelType.FB]: AccountTargetPermissionsNames.GT_FACEBOOK_SHOP,
    },
    settings: {
      [ChannelType.FB]: {
        actions: {
          opt_in_message: {
            label: l.makeTranslate('Opt-In Automation'),
            display: 'flowPicker',
          },
          hidden: {
            display: 'text',
            label: () => '',
            defaultValue:
              'Your contact will receive this Automation after someone messages you from your Facebook Shop',
          },
        },
      },
    },
  },
  [WidgetTypes.MESSENGER_CODE]: {
    proModalTitle: l.makeTranslate('Share a link to your bot without typing'),
    title: l.makeTranslate('QR Code'),
    desc: l.makeTranslate(
      `QR Codes can be scanned to instantly link the contact to your bot, no typing needed. They're great for sticking on fliers, ads, or anywhere in the real world where you want people to try your bot.`,
    ),
    group: 'other',
    permissionsVariable: {
      [ChannelType.FB]: AccountTargetPermissionsNames.GT_QR_CODE,
    },
    settings: {
      [ChannelType.FB]: {
        actions: {
          opt_in_message: {
            label: l.makeTranslate('Opt-In Automation'),
            display: 'flowPicker',
          },
          hidden: {
            display: 'text',
            label: () => '',
            defaultValue:
              'Your new contact will receive this message after the code is scanned with the Messenger app',
          },
        },
        messenger_code_settings: {
          size: {
            display: 'numberInput',
            label: l.makeTranslate('Image size (100-2000px)'),
            defaultValue: 200,
            minValue: 100,
            maxValue: 2000,
          },
          image: {
            label: l.makeTranslate('Logo'),
            display: 'imagePicker',
            className: IMAGE_OVERLAY_CLASSNAME,
          },
          removeLogoBackground: {
            label: l.makeTranslate('Remove Background Behind Logo'),
            display: 'toggle',
            defaultValue: false,
          },
          colors: {
            translatedLabel: l.makeTranslate('Colors'),
            display: 'colors',
            colors: {
              bgColor: {
                label: l.makeTranslate('Background color'),
                defaultValue: '#fff',
              },
              fgColor: {
                label: l.makeTranslate('Foreground color'),
                defaultValue: '#000',
              },
            },
          },
          code: {
            display: 'setup',
            label: l.makeTranslate('Your QR Code'),
          },
        },
      },
    },
  },
  [WidgetTypes.INSTAGRAM_STORY_REPLY]: {
    title: l.makeTranslate('Instagram Story Reply'),
    desc: l.makeTranslate(`Instagram Story Reply`),
    group: 'other',
    permissionsVariable: {},
    settings: {},
    isHidden: true,
  },
  [WidgetTypes.INSTAGRAM_LIVE_COMMENT_REPLY]: {
    title: l.makeTranslate('Instagram Live Comments'),
    desc: l.makeTranslate(`Instagram Live Comments`),
    group: 'other',
    permissionsVariable: {},
    settings: {},
    isHidden: true,
  },
}

import { getInitialCollectOptInChannelState } from 'apps/collectOptIns/collectOptInsConstants'
import { CollectOptInChannelState } from 'apps/collectOptIns/collectOptInsInterfaces'
import { ChannelType } from 'common/core/constants/ChannelType'

export const getByChannel =
  (channel: ChannelType): ((state: RootState) => CollectOptInChannelState) =>
  (state: RootState) =>
    state.collectOptIns[channel] || getInitialCollectOptInChannelState()

export const getFbRnFlowData = getByChannel(ChannelType.FB)

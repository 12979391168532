import { l } from '@manychat/manyui'

import {
  CapiPriceValidationErrors,
  MAX_PRICE,
  MIN_PRICE,
  CONVERSION_TYPES_WITH_PRICE,
} from 'common/actions/components/ActionItem/SendCapiEventAction/constants'
import {
  CapiActionErrors,
  Action,
} from 'common/actions/components/ActionItem/SendCapiEventAction/types'
import ErrorMessages from 'common/builder/constants/Validation'

export const addCapiValidationErrors = (
  actionGroupErrors: CapiActionErrors,
  capiEventAction: Action,
  capiEventActionIndex: number,
) => {
  const isMonetaryType = capiEventAction.conversion_type
    ? CONVERSION_TYPES_WITH_PRICE.includes(capiEventAction.conversion_type)
    : false

  if (isMonetaryType) {
    let prop
    if (!capiEventAction.custom_data?.value && capiEventAction.custom_data?.value !== 0) {
      prop = CapiPriceValidationErrors.MISSING_PRICE
    } else if (capiEventAction.custom_data?.value) {
      if (capiEventAction.custom_data?.value < MIN_PRICE) {
        prop = CapiPriceValidationErrors.PRICE_IS_NEGATIVE
      } else if (capiEventAction.custom_data?.value >= MAX_PRICE) {
        prop = CapiPriceValidationErrors.PRICE_EXCEEDS_MAX
      }
    }

    if (prop) {
      actionGroupErrors.push({
        prop,
        message: l.getString(ErrorMessages.ACTION_CAPI_EVENT_FIELDS_REQUIRED),
        actionIndex: capiEventActionIndex,
      })
    }

    if (!capiEventAction.custom_data?.currency) {
      actionGroupErrors.push({
        prop: 'currency',
        message: l.getString(ErrorMessages.ACTION_CAPI_EVENT_FIELDS_REQUIRED),
        actionIndex: capiEventActionIndex,
      })
    }
  }

  if (!capiEventAction.conversion_type) {
    actionGroupErrors.push({
      prop: 'conversion_type',
      message: l.getString(ErrorMessages.ACTION_CAPI_EVENT_FIELDS_REQUIRED),
      actionIndex: capiEventActionIndex,
    })
  }

  if (!capiEventAction.channel) {
    actionGroupErrors.push({
      prop: 'channel',
      message: l.getString(ErrorMessages.ACTION_CAPI_EVENT_FIELDS_REQUIRED),
      actionIndex: capiEventActionIndex,
    })
  }
}

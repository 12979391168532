import { Icon } from '@manychat/manyui'

export const AGENCY_FIELDS = {
  id: 'agency_id',
  name: 'name',
  emailType: 'email_type',
  email: 'email',
  description: 'description',
  websiteUrl: 'website_url',
  logoUrl: 'logo_url',
  logoFileId: 'logo_file_id',
  placeId: 'place_id',
  country: 'country',
  city: 'city',
  config: 'config',
  normalized_country: 'normalized_country',
  normalized_city: 'normalized_city',
  industries: 'industries',
}

export const COMMISSIONS_PAGE_FIELDS = {
  pageId: 'page_id',
  name: 'name',
  avatar_small: 'ava_small',
  avatar_big: 'ava_big',
  create_ts: 'ts_added',
  subscribe_ts: 'subscription_ts_created',
  isPro: 'pro_status_name',
}

export const COMMISSIONS_STATS_FIELDS = {
  pageId: 'page_id',
  commissionsCount: 'count_commission',
  lastCommissionTS: 'date_commission_last',
  lastCommissionValue: 'commission_in_cents_last',
  totalCommissionValue: 'sum_commission_in_cents',
}

export const AGENCY_CONFIG_FIELDS = { quizPassPercent: 'correct_answer_percent_for_pass_quiz' }

export const EMAIL_VALUES = {
  profile: 'profile',
  agency: 'agency',
}

export const emailRadioOptions = [
  { value: EMAIL_VALUES.profile, label: 'Use current profile email' },
  { value: EMAIL_VALUES.agency, label: 'Use a separate email' },
]

export const emailRadioProfile = [
  { value: EMAIL_VALUES.profile, label: 'Use current profile email' },
]

export const emailRadioAgency = [{ value: EMAIL_VALUES.agency, label: 'Use a separate email' }]

export const AGENCY_LEVELS = {
  applicant: 'applicant',
  partner: 'partner',
  expert: 'expert',
}

export const AGENCY_LEVELS_LABELS = {
  [AGENCY_LEVELS.applicant]: 'Applicant',
  [AGENCY_LEVELS.partner]: 'Agency Partner',
  [AGENCY_LEVELS.expert]: 'Messenger Marketing Expert',
}

export const AGENCY_STEPS = {
  questionnaire: 'questionnaire',
  entryQuiz: 'entryQuiz',
  submitBot: 'submitBot',
  verifyEmail: 'verifyEmail',
  expertQuiz: 'expertQuiz',
  submitProBots: 'submitProBots',
  addLogo: 'addLogo',
  verifySkills: 'verifySkills',
  pagesWithMac: 'pagesWithMac',
}

export const STEPS_BY_LEVEL = {
  [AGENCY_LEVELS.applicant]: [
    AGENCY_STEPS.questionnaire,
    AGENCY_STEPS.verifyEmail,
    AGENCY_STEPS.entryQuiz,
    AGENCY_STEPS.submitBot,
  ],
  [AGENCY_LEVELS.partner]: [
    AGENCY_STEPS.expertQuiz,
    AGENCY_STEPS.addLogo,
    // AGENCY_STEPS.submitProBots,
    // AGENCY_STEPS.verifySkills,
    AGENCY_STEPS.pagesWithMac,
  ],
  [AGENCY_LEVELS.expert]: [],
}

export const AGENCY_BENEFITS = {
  revenueShare: 'revenueShare',
  privateCommunity: 'privateCommunity',
  trainingMaterials: 'trainingMaterials',
  partnerBadge: 'partnerBadge',
  agencyListing: 'agencyListing',
  prioritySupport: 'prioritySupport',
  expertBadge: 'expertBadge',
}

export const BENEFITS_BY_LEVEL = {
  [AGENCY_LEVELS.applicant]: [],
  [AGENCY_LEVELS.partner]: [
    AGENCY_BENEFITS.revenueShare,
    AGENCY_BENEFITS.privateCommunity,
    AGENCY_BENEFITS.trainingMaterials,
    AGENCY_BENEFITS.prioritySupport,
    AGENCY_BENEFITS.partnerBadge,
  ],
  [AGENCY_LEVELS.expert]: [AGENCY_BENEFITS.agencyListing, AGENCY_BENEFITS.expertBadge],
}

export const AGENCY_BENEFITS_LABELS = {
  [AGENCY_BENEFITS.revenueShare]: {
    title: 'Revenue Share',
    subtitle: 'Earn 20% commission on client referrals in perpetuity',
  },
  [AGENCY_BENEFITS.privateCommunity]: {
    title: 'Private Agency Community',
    subtitle: 'Join a community of agency peers',
  },
  [AGENCY_BENEFITS.trainingMaterials]: {
    title: 'Sales & Training Materials',
    subtitle: 'Get the tools you need to grow',
  },
  [AGENCY_BENEFITS.partnerBadge]: {
    title: '“Agency Partner” Badge',
    subtitle: 'Demonstrate status for clients',
  },
  [AGENCY_BENEFITS.agencyListing]: {
    title: 'Agency Listing',
    subtitle: "We'll send you new clients",
  },
  [AGENCY_BENEFITS.prioritySupport]: {
    title: 'Priority Support',
    subtitle: 'Be first in line for support',
  },
  [AGENCY_BENEFITS.expertBadge]: {
    title: '“Messenger Marketing Expert” Badge',
    subtitle: 'Demonstrate status for clients',
  },
}

export const BENEFITS_TEXT = {
  [AGENCY_BENEFITS.revenueShare]: {
    title: AGENCY_BENEFITS_LABELS[AGENCY_BENEFITS.revenueShare].title,
    text: 'At Manychat, we understand that our agency partners work hard to educate clients on the benefits of Messenger Marketing and to help those clients achieve their goals. As a reward for bringing new paid clients to Manychat platform, we pay agency partners 20% of the monthly recurring revenue that we receive from the referred client for as long as the client remains a Pro contact of Manychat.',
  },
  [AGENCY_BENEFITS.privateCommunity]: {
    title: AGENCY_BENEFITS_LABELS[AGENCY_BENEFITS.privateCommunity].title,
    text: "All agencies within the Manychat Agency Program have access to a private community where they can ask questions directly to Manychat employees, share best practices, and learn from peers. Participation in the community requires following community guidelines regarding appropriate behavior, SPAM, and overtly selling one's own services. ",
  },
  [AGENCY_BENEFITS.trainingMaterials]: {
    title: AGENCY_BENEFITS_LABELS[AGENCY_BENEFITS.trainingMaterials].title,
    text: 'Optimize the way you work with your clients.',
  },
  [AGENCY_BENEFITS.partnerBadge]: {
    title: 'Co-Marketing',
    text: 'As a member in good standing of the Manychat Agency Program, agency partners can use the Manychat Agency Partner badge and Messenger Marketing Expert badge (for agencies that are Messenger Marketing Experts) on their website or as part of their marketing efforts. Beyond these two specific badges, participation in the program does not allow agencies to use the Manychat logo, name, or brand in ways that are not explicitly pre-approved within the guidelines of the Manychat Agency Program.',
  },
  [AGENCY_BENEFITS.expertBadge]: {
    title: 'Co-Marketing',
    text: 'As a member in good standing of the Manychat Agency Program, agency partners can use the Manychat Agency Partner badge and Messenger Marketing Expert badge (for agencies that are Messenger Marketing Experts) on their website or as part of their marketing efforts. Beyond these two specific badges, participation in the program does not allow agencies to use the Manychat logo, name, or brand in ways that are not explicitly pre-approved within the guidelines of the Manychat Agency Program.',
  },
  [AGENCY_BENEFITS.agencyListing]: {
    title: AGENCY_BENEFITS_LABELS[AGENCY_BENEFITS.agencyListing].title,
    text: 'Messenger Marketing Experts will have their agencies listed in our agency directory on Manychat.com. Prospective customers will be able to see a brief profile and contact information for each agency in the directory. If you wish to NOT have your agency listed in the directory please let us know at agency@manychat.com.',
  },
  [AGENCY_BENEFITS.prioritySupport]: {
    title: AGENCY_BENEFITS_LABELS[AGENCY_BENEFITS.prioritySupport].title,
    text: 'Agency partners that are also Messenger Marketing Experts receive access to priority support. Priority support tickets are handled first by the Manychat support team. While we do not guarantee SLAs for priority support, we expect to respond to tickets within 24 hours and resolve them as fast as possible. As soon as you are eligible, all your new tickets submitted via normal support channels will automatically be routed to the priority queue.',
  },
}

export const STEPS_LABELS = {
  [AGENCY_STEPS.questionnaire]: {
    title: 'Filled Agency Questionnaire',
    subtitle: 'Tell us about your agency',
  },
  [AGENCY_STEPS.verifyEmail]: {
    title: 'Verify your email',
    subtitle: 'Follow the verification link in your inbox at :email',
  },
  [AGENCY_STEPS.entryQuiz]: {
    title: 'Pass the entry quiz (>:percent%)',
    subtitle: 'Successfully complete the entry quiz within our learning management system',
  },
  [AGENCY_STEPS.submitBot]: {
    title: 'Connect a Pro account with 50 contacts',
    subtitle:
      'Demonstrate that you are the admin of at least 1 Pro account that has over 50 contacts',
  },
  [AGENCY_STEPS.watchCourse]: {
    title: 'Watch the Manychat course',
    subtitle:
      'Watch the Manychat free video course within 30 days of registering to become a partner',
  },
  [AGENCY_STEPS.expertQuiz]: {
    title: 'Pass the expert quiz (>:percent%)',
    subtitle: 'Pass the Messenger Marketing Expert quiz',
  },
  [AGENCY_STEPS.submitProBots]: {
    title: 'Connect 5 Pro bots with 50+ contacts',
    subtitle: 'Demonstrate that you are an admin of 5 Pro bots with at least 50+ contacts each',
  },
  [AGENCY_STEPS.addLogo]: {
    title: 'Add your logo',
    subtitle: 'This will be displayed in our directory',
  },
  [AGENCY_STEPS.verifySkills]: {
    title: 'Verify your bot-building skills',
    subtitle:
      "Build a bot to our specification and submit for review or submit a similar prior bot you've built",
  },
  [AGENCY_STEPS.pagesWithMac]: {
    title: 'Connect :current/:required  Pro bots with 100 MAC each',
    subtitle:
      'Demonstrate that you are an admin of 3 Pro bots with 100 Monthly Active Conversation',
    tooltipText:
      'MAC - Monthly Active Conversations. N MAC means that N contacts interacted with your bot during the last month, that is wrote something or clicked on any buttons of your messages',
  },
}

export const IconByAgencyBenefits = {
  [AGENCY_BENEFITS.revenueShare]: Icon.OrphanBuyButton,
  [AGENCY_BENEFITS.privateCommunity]: Icon.LiveChat,
  [AGENCY_BENEFITS.trainingMaterials]: Icon.OrphanFullText,
  [AGENCY_BENEFITS.prioritySupport]: Icon.Help,
  [AGENCY_BENEFITS.expertBadge]: Icon.TemplateTextMedia,
  [AGENCY_BENEFITS.partnerBadge]: Icon.Id,
  [AGENCY_BENEFITS.agencyListing]: Icon.CheckCircle,
}

export const BENEFITS_TEXTS = {
  [AGENCY_LEVELS.partner]: {
    title: 'Your Partner Qualification Status',
    subtitle:
      "In order to ensure the highest quality of agency support for Manychat's customers, we ask all agencies entering the program to meet some basic criteria.",
  },
  [AGENCY_LEVELS.expert]: {
    title: 'Your Messenger Marketing Expert Qualification Status',
    subtitle:
      'Agencies that prove they have a high degree of understanding and proficiency with Messenger Marketing strategies can qualify to become a Messenger Marketing Expert.',
  },
}

export const AGENCY_FILTER = {
  name: 'name',
  industry: 'industry',
  country: 'country',
}

export const CONTACT_FORM_FIELD = {
  name: 'name',
  email: 'email',
  country: 'country',
  industry: 'industry',
  taskDescription: 'taskDescription',
  link: 'link',
  termsOfUseChecked: 'termsOfUseChecked',
  normalizedCountry: 'normalizedCountry',
}

export const getMatchedFormServices = {
  AUTOMATED: 'Automated Funnels',
  ADS: 'Facebook Ads',
  CAMPAIGNS: 'SMS and Email campaigns',
  ADVANCED: 'Advanced Integrations',
  ANALYTICS: 'Analytics',
  CONSULTING: 'Consulting / Education',
}

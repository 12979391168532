import { UserDisplayFlagType } from 'apps/dashboard/constants/DisplayFlagType'
import { RegSource } from 'apps/registration/constants'
import { AllowedLanguages } from 'common/core/interfaces/allowedLanguages'
import { Notify } from 'common/core/interfaces/notify'
import { UserActivity } from 'common/core/interfaces/userActivity'

export interface IUserBase {
  avatar_large?: string
  avatar_small?: string
  full_name?: string
}

export interface InitialUser {
  user_id: number
  avatar_large: string
  avatar_small: string
  name: string
  first_name: string
  last_name: string
  full_name: string
  ts_created: number
  created: string
  email: null | string
  email_unverified: string | null
  can_resend_email_verification_code: boolean
  is_verification_code_sent: boolean
  timezone: string
  language: string | null
  has_pro_accounts: boolean
  has_quick_campaigns_access: boolean
  locale_settings: null
  allowedLanguages: AllowedLanguages
  allowedLocaleSettings: null
  skip_preview_onboarding_modal: boolean
  flags: Record<string, boolean>
  notify?: Notify
  user_api_token: string | null
  has_pages_dashboards: boolean
  is_tiktok_restricted_by_region: boolean
}

export interface User extends InitialUser {
  home_tab?: boolean
  agency_data?: {
    agency_id: number | null
  }
  is_relogin?: boolean
  interface_data: {
    display_flags?: Record<UserDisplayFlagType, boolean>
  }
  isVerifyingEmail?: boolean
  activity?: UserActivity
  reg_source: RegSource | null
}

export enum UserType {
  ADMIN = 'admin',
  INVITED_USER = 'invited_user',
}

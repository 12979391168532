import { isValidElement } from 'react'
import debugConstructor from 'debug'
import delay from 'lodash/delay'
import isString from 'lodash/isString'
import { v4 as uuid } from 'uuid'
import { l } from '@manychat/manyui'

import { freezeBody } from 'utils'
import { IThunkAction } from 'common/core/interfaces/actions'
import { TAlertText } from 'common/core/interfaces/alert'
import { IErrorResponse } from 'common/core/interfaces/errorResponse'
import * as uiSelectors from 'common/core/selectors/uiSelectors'
import { SettingsTabType } from 'common/settings/constants/SettingsTabType'
import { sendHealthMetric, getMetricPathname } from 'utils/healthMetrics'
import localStorage from 'utils/localStorage'
import { getComponentByRoute } from 'utils/router/tools'
import { getDeserializationData } from 'utils/serializing/getDeserializationData'
import { getSerializationData } from 'utils/serializing/renderHelper'

import { ChannelType, ConnectChannelModalSource } from '../constants/ChannelType'
import * as atypes from '../constants/UIReduxActionTypes'
import {
  UI_CLOSE_FIRST_TIKTOK_AUTOMATION_FLOW_POPUP,
  UI_OPEN_FIRST_TIKTOK_AUTOMATION_FLOW_POPUP,
} from '../constants/UIReduxActionTypes'

const debug = debugConstructor('alert')

const NOTIFICATION_ANIMATION_DELAY = 500

const messageToString = (message: IErrorResponse | UnsafeAnyObject = {}): string => {
  let errors = []

  if (message.error) {
    errors = [message.error]
  } else if (Array.isArray(message.errors)) {
    errors = message.errors
  } else {
    errors = Object.values(message.error || message.errors || {}).filter(
      (value) => typeof value === 'string' && value.length > 0,
    )
  }

  if (errors.length > 0) {
    return errors.join('. ')
  }

  return l.translate('Something went wrong')
}

const getContent = (message: string | IErrorResponse | UnsafeAnyObject) => {
  const hasValidString = isString(message)
  const stringMsg = hasValidString ? message : messageToString(message)
  const deserializationMessage = getDeserializationData(stringMsg, {
    hasSaveHref: true,
    hasRemoveTags: false,
    hasTrim: true,
  })
  return getSerializationData(deserializationMessage)
}

export const openLightbox = (images: string | string[]) => ({
  type: atypes.UI_OPEN_LIGHTBOX,
  items: Array.isArray(images) ? images : [images],
})

export const closeLightbox = () => ({
  type: atypes.UI_CLOSE_LIGHTBOX,
})

export const openConnectChannelModal = (
  channels = [ChannelType.FB, ChannelType.SMS, ChannelType.EMAIL],
  source?: ConnectChannelModalSource,
) => ({
  type: atypes.UI_OPEN_CONNECT_CHANNEL_MODAL,
  channels,
  source,
})

export const setCGTNotAllowedModal = (action = 'close') => ({
  type: atypes.UI_SET_CGT_NOT_ALLOWED_MODAL_STATUS,
  statusOfCGTNotAllowedModal: action,
})

export const openCompactSettingsModal = (
  settingsTab: SettingsTabType | ChannelType,
  allowedSettingsTabs: SettingsTabType[] = [],
) => ({
  type: atypes.UI_OPEN_COMPACT_SETTINGS_MODAL,
  settingsTab: settingsTab,
  allowedSettingsTabs,
})

export const closeCompactSettingsModal = () => ({
  type: atypes.UI_CLOSE_COMPACT_SETTINGS_MODAL,
  settingsTab: null,
})

export const openIgPreviewModal = (keyword?: string) => ({
  type: atypes.UI_OPEN_PREVIEW_MODAL,
  keyword,
})

export const closeIgPreviewModal = () => ({
  type: atypes.UI_CLOSE_PREVIEW_MODAL,
})

export const closeConnectChannelModal = () => ({
  type: atypes.UI_CLOSE_CONNECT_CHANNEL_MODAL,
})

export const openFirstAutomationCreationModal = () => ({
  type: atypes.UI_OPEN_FIRST_AUTOMATION_CREATION_MODAL,
})

export const closeFirstAutomationCreationModal = () => ({
  type: atypes.UI_CLOSE_FIRST_AUTOMATION_CREATION_MODAL,
})

export const openWaCoexistenceContactImportModal = () => ({
  type: atypes.UI_OPEN_WA_COEXISTENCE_CONTACT_IMPORT_MODAL,
})

export const closeWaCoexistenceContactImportModal = () => ({
  type: atypes.UI_CLOSE_WA_COEXISTENCE_CONTACT_IMPORT_MODAL,
})

export const openFirstTikTokAutomationCreationModal = () => ({
  type: atypes.UI_OPEN_FIRST_TIKTOK_AUTOMATION_CREATION_MODAL,
})

export const closeFirstTikTokAutomationCreationModal = () => ({
  type: atypes.UI_CLOSE_FIRST_TIKTOK_AUTOMATION_CREATION_MODAL,
})

export const setFirstTikTokAutomationSession = () => ({
  type: atypes.UI_SET_FIRST_TIKTOK_AUTOMATION_SESSION,
})

export const unsetFirstTikTokAutomationSession = () => ({
  type: atypes.UI_UNSET_FIRST_TIKTOK_AUTOMATION_SESSION,
})

export const setFirstTikTokAutomationShowFeedback = () => ({
  type: atypes.UI_SET_FIRST_TIKTOK_AUTOMATION_SHOW_FEEDBACK,
})

export const unsetFirstTikTokAutomationShowFeedback = () => ({
  type: atypes.UI_UNSET_FIRST_TIKTOK_AUTOMATION_SHOW_FEEDBACK,
})

export const openFirstTikTokAutomationFlowPopup = () => ({
  type: atypes.UI_OPEN_FIRST_TIKTOK_AUTOMATION_FLOW_POPUP,
})

export const unsetFirstTikTokAutomationFlowPopup = () => ({
  type: atypes.UI_CLOSE_FIRST_TIKTOK_AUTOMATION_FLOW_POPUP,
})

export const resetFirstTikTokAutomation = () => ({
  type: atypes.UI_RESET_FIRST_TIKTOK_AUTOMATION,
})

export const openSidebar = () => {
  freezeBody(true, 'sidebar')
  localStorage.setItem('mcht_sidebar_hide_state', false)
  return {
    type: atypes.UI_OPEN_SIDEBAR,
  }
}

export const closeSidebar = () => {
  freezeBody(false, 'sidebar')
  localStorage.setItem('mcht_sidebar_hide_state', true)
  return {
    type: atypes.UI_CLOSE_SIDEBAR,
  }
}

export const toggleSidebar = (): IThunkAction => {
  return (dispatch, getState) => {
    if (getState().ui.isSidebarOpened) {
      return dispatch(closeSidebar())
    }
    return dispatch(openSidebar())
  }
}

export const toggleSidebarFolding = (): IThunkAction => {
  return (dispatch, getState) => {
    const isSidebarFolded = getState().ui.isSidebarFolded
    localStorage.setItem('mcht_sidebar_folded', isSidebarFolded ? '' : '!')

    dispatch({
      type: atypes.UI_TOGGLE_SIDEBAR_FOLDING,
    })
  }
}

export const addNotification = (
  message: TAlertText = {},
  type = 'info',
  options: {
    isTemporary?: boolean
    shouldRemoveOnNavigate?: boolean
    source?: string
    icon?: string
    content?: React.ReactNode
  } = {},
): IThunkAction => {
  return (dispatch) => {
    const hasValidElement = isValidElement(message)
    const hasValidString = isString(message)

    let text
    if (hasValidString) {
      text = getContent(message)
    } else if (hasValidElement) {
      text = message
    } else {
      debug(message, `uiActions.addNotification called with non string message`)
      text = getContent(message)
    }

    const id = uuid()
    const item = {
      id,
      text,
      type,
      isTemporary: options.isTemporary,
      source: options.source,
      icon: options.icon,
      content: options.content,
      shouldRemoveOnNavigate: options.shouldRemoveOnNavigate,
    }

    const hasDangerAlert = type === 'danger'
    if (hasDangerAlert) {
      const pathname = getMetricPathname()
      sendHealthMetric('alert_event', { url: pathname, component: getComponentByRoute() })
    }

    dispatch({ type: atypes.UI_ADD_NOTIFICATION, item })

    return id
  }
}

/**  delete all notifications marked with a flag `shouldRemoveOnNavigate` */
export const removeNotificationsOnNavigate = (): IThunkAction => {
  return (dispatch, getState) => {
    const state = getState()

    state.ui.notifications
      .filter(({ shouldRemoveOnNavigate }) => shouldRemoveOnNavigate)
      .forEach(({ id }) => dispatch(removeNotification(id)))
  }
}

export const removeNotification = (id: string): IThunkAction => {
  return (dispatch, getState) => {
    const state = getState()

    const item = uiSelectors.getNotificationById(state, id)
    if (!item) {
      return
    }

    delay(() => dispatch(deleteNotification(id)), NOTIFICATION_ANIMATION_DELAY)
  }
}

export const notifyAdminMigrationShowModal = () => ({
  type: atypes.UI_NOTIFY_ADMIN_MIGRATION_SHOW_MODAL,
})
export const notifyAdminMigrationHideModal = () => ({
  type: atypes.UI_NOTIFY_ADMIN_MIGRATION_HIDE_MODAL,
})

export const deleteNotification = (id: string): IThunkAction => {
  return (dispatch, getState) => {
    const state = getState()

    const item = uiSelectors.getNotificationById(state, id)
    if (!item) {
      return
    }

    dispatch({
      type: atypes.UI_DELETE_NOTIFICATION,
      id,
    })
  }
}

export const showIgChangeAccountErrorModal = () => ({
  type: atypes.UI_SHOW_IG_CHANGE_ACCOUNT_ERROR_MODAL,
})

export const hideIgChangeAccountErrorModal = () => ({
  type: atypes.UI_CLOSE_IG_CHANGE_ACCOUNT_ERROR_MODAL,
})

export const IGChangeAccountError = (isIgChangeAccountError: boolean) => ({
  type: atypes.UI_IG_CHANGE_ACCOUNT_ERROR,
  isIgChangeAccountError,
})

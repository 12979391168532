import * as atypes from 'apps/templates/constants/ReduxActionTypes'
import { Statuses } from 'apps/templates/constants/TemplateInstallations'
import { makeLimitedListReducer, mergeToListItem } from 'utils/factory'

export const installationsReducer = makeLimitedListReducer('templateInstallations')

export function installationsListReducer(state, action) {
  state = installationsReducer(state, action)
  switch (action.type) {
    case atypes.TEMPLATEINSTALLATIONS_FETCH_RESPONSE:
      return { ...state, totalInstallations: action.data.template_installations_count }
    case atypes.TEMPLATEINSTALLATIONS_DEACTIVATE_REQUEST: {
      return mergeToListItem(state, action.id, { status: Statuses.DISABLED })
    }
    case atypes.TEMPLATEINSTALLATIONS_ACTIVATEFORPAGE_REQUEST: {
      return mergeToListItem(state, action.id, { status: Statuses.ACTIVE })
    }
  }

  return state
}

import { useState, useCallback } from 'react'
import { useAppSelector, useAppDispatch } from 'reduxTyped'

import { CmsAnalytics } from 'apps/cms/lib/analytics'
import { FsValidationMessage } from 'apps/cms/lib/validation'
import { contentManagementSelectors, contentManagementActions } from 'apps/cms/store'
import { AutomationModalType } from 'apps/cms/store/lib/types'
import { alert } from 'common/core'

import { FormValues } from '../lib/types'

export const useCreateFolderModal = () => {
  const dispatch = useAppDispatch()

  const { path } = useAppSelector(contentManagementSelectors.getQuery)
  const folders = useAppSelector(contentManagementSelectors.getFoldersByCurrentQueryPath)

  const [isLoading, setIsLoading] = useState(false)

  const closeModal = useCallback(() => {
    dispatch(contentManagementActions.closeModal(AutomationModalType.CREATE_FOLDER))
  }, [dispatch])

  const createFolder = useCallback(
    async ({ name }: FormValues) => {
      const folderWithSameName = folders.find((folder) => folder.title === name)

      if (folderWithSameName) {
        return alert(FsValidationMessage.getFolderWithThisNameExistsMessage(), 'danger')
      }

      setIsLoading(true)

      await dispatch(contentManagementActions.createFolder({ title: name, path }))
      CmsAnalytics.logCreateFolder()

      setIsLoading(false)

      closeModal()
    },
    [closeModal, dispatch, folders, path],
  )

  return {
    createFolder,
    closeModal,
    isLoading,
  }
}

import { Icon, l } from '@manychat/manyui'
import { TextSource } from '@manychat/manyui/src/utils/localization/format'

import { WelcomeMessageSource } from 'apps/aiCommunications/store/types'

export const WelcomeMessageFlagName = 'ai_welcome_message_showed'

export enum FeatureType {
  STEP = 'Step',
  INTENT = 'Intent',
}

export interface Feature {
  type: FeatureType
  icon: Icon.Icon
  title: TextSource
  description: TextSource
  appearsOn: WelcomeMessageSource[]
}

export const aiFeatures: Feature[] = [
  {
    type: FeatureType.INTENT,
    icon: Icon.AiIntention,
    title: l.makeTranslate('Use AI to answer all your FAQs'),
    description: l.makeTranslate('AI will reply to questions you get asked all the time'),
    appearsOn: [WelcomeMessageSource.BILLING, WelcomeMessageSource.FLOW_BUILDER],
  },
  {
    type: FeatureType.STEP,
    icon: Icon.AiStep,
    title: l.makeTranslate('Automate conversations with AI'),
    description: l.makeTranslate(
      'Get AI to collect your follower’s info, share details or tell it how to reply ',
    ),
    appearsOn: [WelcomeMessageSource.BILLING, WelcomeMessageSource.FAQ_WIZARD],
  },
]

import { l } from '@manychat/manyui'

import * as blockHelpers from 'common/builder/blocks/blockHelpers'
import { IBlock } from 'common/builder/blocks/blockInterfaces'
import { BlockType } from 'common/builder/constants/BlockType'
import { NodeType } from 'common/builder/constants/NodeType'
import BlockModel from 'common/builder/models/Block'
import BaseNodeConfig from 'common/builder/nodes/BaseNodeConfig'
import { INodeBase, ISMSNode } from 'common/builder/nodes/nodeInterfaces'

class SmsNodeConfig extends BaseNodeConfig<ISMSNode> {
  nodeType = NodeType.SMS

  isPro = true

  getCaption = () => l.translate('Send Message')
  getToolbarCaption = () => l.translate('SMS')
  getCaptionWithCount = (n: number) => l.translate('{n, plural, one {# SMS} other {# SMS}}', { n })
  getNextStepCaption = () => l.translate('SMS')

  icon = 'SmsChannel'
  color = '#8492A6'
  headerColor = '#ecf8d5'

  ALLOWED_BLOCK_TYPES = [
    BlockType.TEXT,
    BlockType.QUICK_REPLY,
    BlockType.ATTACHMENT,
    BlockType.FORM_QUESTION,
  ]

  ALLOWED_BLOCK_MAX_COUNT = 50

  getDefaults = (): Omit<ISMSNode, keyof INodeBase> => ({
    targetId: null,
  })

  getInitialBlocks = (node: ISMSNode) => {
    return [
      { type: BlockType.TEXT as const },
      { type: BlockType.QUICK_REPLY as const, id: blockHelpers.createQRBlockId(node.id) },
    ]
  }

  isEmpty = ({ node, blocks }: { node: ISMSNode; blocks: IBlock[] }) => {
    if (node.targetId) {
      return false
    }
    return this.areBlocksEmpty(blocks)
  }

  _isEmptyBlock = (block: IBlock) => {
    return (
      [BlockType.TEXT, BlockType.QUICK_REPLY, BlockType.ATTACHMENT].includes(block.type) &&
      BlockModel.isEmpty(block)
    )
  }
}

export default new SmsNodeConfig()

import { CONTENT_PARSED } from 'common/builder/constants/builderReduxActionTypes'
import { NodeType } from 'common/builder/constants/NodeType'
import builderSelectors from 'common/builder/selectors/builder'
import { getNodeById } from 'common/builder/selectors/builder/entitySelectors'

/**
 * fills the store with data, accepts data in parsed format
 *
 * @param {string} builderId
 * @param {Parsed} parsed
 */
export const mergeParsed = (builderId, parsed) => {
  return (dispatch, getState) => {
    const state = getState()

    const builderState = builderSelectors.builderState.getById(state, builderId)
    if (!builderState) {
      return
    }

    // there are 2 cases when content is generated on server
    // - grow tools are created immediately with ready-made content
    // - sequences are created with a pack of sequence messages, each of which has ready-made content
    //
    //  right now backend generates OIDs for all these contents
    //  but all contents that were made earlier do not have OIDs
    //
    // and this is a problem for such content that does not have an oid
    // you are trying to apply a saved copy,
    // copy is applied, but it is not visible because flowAdapter generates a new oid for it
    //
    // since such content has a contentId, we can search for the contentId in our store and restore the oid

    function fixClientId(parsedContent) {
      if (parsedContent.contentId && parsedContent.nodeType === NodeType.CONTENT) {
        const existingContent = getNodeById(state, builderId, parsedContent.contentId)
        if (existingContent && existingContent.id !== parsedContent.id) {
          console.log(`parseBatch: oid fixed for contentId=${parsedContent.contentId}`)
          return {
            ...parsedContent,
            id: existingContent.id,
          }
        }
      }
      return parsedContent
    }

    if (parsed.nodes) {
      parsed.nodes = parsed.nodes.map(fixClientId)
    }

    dispatch({
      type: CONTENT_PARSED,
      builderId,
      parsed,
    })
  }
}

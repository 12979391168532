import { z } from 'zod'

export const ClassNameSchema = z
  .string()
  .or(
    z.object({
      default: z.string(),
      sm: z.string().optional(),
      md: z.string().optional(),
      lg: z.string().optional(),
      xl: z.string().optional(),
    }),
  )
  .optional()

export const PropsWithClassNameSchema = z.object({
  className: ClassNameSchema,
})

export const isPropsWithClassName = (
  value: unknown,
): value is z.infer<typeof PropsWithClassNameSchema> => {
  return PropsWithClassNameSchema.safeParse(value).success
}

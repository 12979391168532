import { l } from '@manychat/manyui'

import { FilterField } from 'common/filter/models/AudienceFilter/constants'

export const getTooltipTexts = (type: string) => {
  switch (type) {
    case FilterField.ONE_TIME_NOTIFICATION:
    case FilterField.IG_RECURRING_NOTIFICATION:
      return l.translate('That filter shows those to whom you can send a message right now')

    case FilterField.ONE_TIME_NOTIFICATION_OPTIN:
    case FilterField.IG_RECURRING_NOTIFICATION_OPTIN:
      return l.translate('Contacts that allowed you to message them.')

    default:
      return
  }
}

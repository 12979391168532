import { analyticsService } from 'utils/services/analytics'

/**
 * Randomly returns `true` or `false`
 * `true` stands for A group, `false` – for B
 */
export const getExperimentGroup = (): boolean => {
  if (!window.crypto) {
    analyticsService.sendEvent('BILLING.EXPERIMENT.MATH_RANDOM')

    return Math.random() <= 0.5
  }

  analyticsService.sendEvent('BILLING.EXPERIMENT.CRYPTO')

  const intArray = new Uint8Array(1)
  const [randomInt] = window.crypto.getRandomValues(intArray)

  return randomInt <= 127
}

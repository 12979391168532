import { TierData } from '../interfaces/tiers'

export const getTierByContacts = (tiers: TierData[], contacts: number): TierData => {
  let left = 0
  let right = tiers.length - 1

  while (left <= right) {
    const mid = Math.floor((right + left) / 2)
    const tier = tiers[mid]
    if (tier.max < contacts) {
      left = mid + 1
      continue
    }
    if (tier.min > contacts) {
      right = mid - 1
      continue
    }

    return tier
  }

  return tiers[right]
}

export interface IArea {
  left: number
  top: number
  width: number
  height: number
}
export interface IAreaShift {
  left?: number
  top?: number
  width?: number
  height?: number
}

export interface ITooltipAreaShift {
  left?: number
  top?: number
}
export interface ITooltip {
  at: 'top' | 'bottom' | 'left' | 'right'
  areaShift?: ITooltipAreaShift
  component: React.ReactNode
}

export type IPointer = IDomPointer | IAreaPointer | IFlowChartPointer
export enum PointerType {
  DOM = 'DOM',
  AREA = 'AREA',
  FLOW_CHART = 'FLOW_CHART',
}
interface IBasePointer {
  tooltip?: ITooltip
  padding?: number
  areaShift?: IAreaShift
  blockAreaInput?: boolean
}
export interface IFlowChartPointer extends IBasePointer {
  type: PointerType.FLOW_CHART
  elementId?: string
  name?: string
  moveTo?: boolean
  elementPositionOnCanvas?: FlowChartPointerElementPositionType
  elementIndex?: number
}
export interface IDomPointer extends IBasePointer {
  type: PointerType.DOM
  elementId: string
  elementIndex?: number
}
export interface IAreaPointer extends IBasePointer {
  type: PointerType.AREA
  area: IArea
}
export enum FlowChartPointerElementPositionType {
  TOP_LEFT = 'TOP_LEFT',
}

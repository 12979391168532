import { Reducer } from 'redux'

import * as actionTypes from 'apps/menu/constants/ReduxActionTypes'
import { IMenuListState, FetchStatus, IMenu } from 'apps/menu/menuInterfaces'

const initialState = {
  items: [],
  fetchStatus: FetchStatus.UNSENT,
  createStatus: FetchStatus.UNSENT,
}
// "flow_ns": "mainmenu20200911160639_480901"
export const menuListReducer: Reducer<IMenuListState> = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.MENU_LIST_FETCH_REQUEST:
      return { ...state, fetchStatus: FetchStatus.FETCHING }
    case actionTypes.MENU_LIST_FETCH_RESPONSE:
      return { ...state, fetchStatus: FetchStatus.SUCCESS, items: action.data }
    case actionTypes.MENU_LIST_FETCH_ERROR:
      return { ...state, fetchStatus: FetchStatus.ERROR }

    case actionTypes.MENU_CREATE_REQUEST:
      return { ...state, createStatus: FetchStatus.FETCHING }
    case actionTypes.MENU_CREATE_NOTIFICATION:
    case actionTypes.MENU_CREATE_RESPONSE: {
      if (state.items.find((item) => item.id === action.data?.id)) {
        return { ...state }
      }
      return { ...state, createStatus: FetchStatus.SUCCESS, items: [...state.items, action.data] }
    }
    case actionTypes.MENU_CREATE_ERROR:
      return { ...state, createStatus: FetchStatus.ERROR }

    case actionTypes.MENU_DELETE_NOTIFICATION:
    case actionTypes.MENU_DELETE_RESPONSE:
      return { ...state, items: state.items.filter((item) => item.id !== action.data) }

    case actionTypes.MENU_RENAME_RESPONSE: {
      const { name, id } = action
      const updatedItems = state.items.map((item: IMenu) => {
        if (item.id === id) {
          return { ...item, name }
        }
        return item
      })
      return { ...state, items: updatedItems }
    }
  }
  return state
}

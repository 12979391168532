import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'

import { noop, pad } from '../../../../utils'
import {
  isValidTime,
  isValidDate,
  parseDate,
  isMonthFirst,
  formatTime,
  formatDate,
} from '../../../../utils/date'
import TextInputV2 from '../../TextInputV2'

const MONTH_FIRST_REG = /^(0?[1-9]|1[0-2])\/(0?[1-9]|1\d|2\d|3[01])\/([12]\d{3})/
const DAY_FIRST_REG = /^(0?[1-9]|1\d|2\d|3[01])\/(0?[1-9]|1[0-2])\/([12]\d{3})/
const TIME_REG = /([012]?\d):([0-5]\d)$/

const typingHelper = (type, value, prevValue, selectionStart) => {
  value = value.replace(/[-.]/g, '/')

  // User is typing new symbols
  if (value.length > prevValue.length && value.length === selectionStart) {
    const { withDate, withTime } = typeDesc(type)
    if (withDate) {
      const dateParts = value.split('/')
      // User has typed full day or month
      if (dateParts.length <= 2 && dateParts[dateParts.length - 1].length === 2) {
        value += '/'
      }
      // User typed full date
      if (dateParts.length === 3 && dateParts[dateParts.length - 1].length === 4) {
        value += ' '
      }
    }
    if (withTime) {
      // User typed hours
      const time = withDate ? value.split(' ')[1] : value
      if (time && ((time.length === 1 && +time > 2) || time.length === 2)) {
        value += ':'
      }
    }

    // Remove delimiter duplicates
    const end = value.slice(-2)
    if (end === '//' || end === '  ' || end === '::') {
      value = value.slice(0, -1)
    }
  }
  return value
}

const typeDesc = (type) => ({ withDate: type.includes('date'), withTime: type.includes('time') })

class DateInput extends PureComponent {
  static propTypes = {
    inputRef: PropTypes.any,
    minDate: PropTypes.string,
    maxDate: PropTypes.string,
    onChange: PropTypes.func,
    onBlur: PropTypes.func,
    placeholder: PropTypes.string,
    type: PropTypes.oneOf(['datetime', 'date', 'time']),
  }

  static defaultProps = {
    onChange: noop,
    onBlur: noop,
    type: 'date',
  }

  static getPlaceholder = (props) => {
    if (props.placeholder !== undefined) {
      return props.placeholder
    }
    const { withDate, withTime } = typeDesc(props.type)
    const date = withDate && (isMonthFirst() ? 'MM/DD/YYYY' : 'DD/MM/YYYY')
    const time = withTime && 'hh:mm'
    return [date, time].filter((s) => s).join(' ')
  }

  static format = (value, type) => {
    const { withDate, withTime } = typeDesc(type)
    let res = ''
    const date = parseDate(value)
    if (withDate) {
      if (date == null) {
        return ''
      }
      const monthFirst = isMonthFirst()
      const month = pad(date.getMonth() + 1)
      const day = pad(date.getDate())
      res = `${monthFirst ? month : day}/${monthFirst ? day : month}/${date.getFullYear()}`
    }
    if (withTime) {
      if (withDate) {
        return `${res} ${formatTime(date)}`
      }
      res = isValidTime(value) ? value : ''
    }
    return res
  }

  static parse = (value, type) => {
    const { withDate, withTime } = typeDesc(type)
    let date = null
    if (withDate) {
      const monthFirst = isMonthFirst()
      const regExp = monthFirst ? MONTH_FIRST_REG : DAY_FIRST_REG
      const matches = value.match(regExp)
      if (matches == null) {
        return null
      }
      const day = matches[monthFirst ? 2 : 1]
      const month = matches[monthFirst ? 1 : 2]
      const year = matches[3]
      date = new Date(+year, month - 1, +day)
    }

    if (withTime) {
      const timeMatches = value.match(TIME_REG)
      if (timeMatches == null) {
        return null
      }
      const [, hour, minute] = timeMatches
      if (withDate) {
        date.setHours(+hour, +minute)
      } else {
        return formatTime({ hour, minute })
      }
    }
    return withTime ? date.toISOString() : formatDate(date)
  }

  state = { text: DateInput.format(this.props.value, this.props.type) }

  componentDidUpdate = (prevProps, prevState) => {
    const { value, type } = this.props

    if (prevProps.type !== type) {
      return this.setState({ text: DateInput.format(value, type) })
    }
    if (prevProps.value !== value) {
      const stateText = DateInput.format(DateInput.parse(this.state.text, type), type)
      const valueText = DateInput.format(value, type)
      if (stateText === valueText) {
        return
      }
      this.setState({ text: DateInput.format(value, type) })
    }
  }

  reset = () => this.setState({ text: DateInput.format(this.props.value, this.props.type) })

  handleChange = (e) => {
    const { name, type, onChange, minDate, maxDate } = this.props

    const text = typingHelper(type, e.target.value, this.state.text, e.target.selectionStart)
    this.setState({ text }, () => {
      const value = DateInput.parse(text, type)
      if (value) {
        if (
          (isValidDate(minDate) && value < minDate) ||
          (isValidDate(maxDate) && value > maxDate)
        ) {
          return
        }
        onChange({ target: { value, name } })
      }
    })
  }

  handleBlur = (e) => (this.reset(), this.props.onBlur(e))

  render() {
    const { text } = this.state
    const { inputRef, minDate, maxDate, placeholder, withTime, ...rest } = this.props // eslint-disable-line no-unused-vars

    return (
      <TextInputV2
        {...rest}
        type="text"
        inputRef={inputRef}
        value={text}
        onChange={this.handleChange}
        onBlur={this.handleBlur}
        placeholder={DateInput.getPlaceholder(this.props)}
      />
    )
  }
}

const DateInputRefWrapper = React.forwardRef((props, ref) => (
  <DateInput {...props} inputRef={ref} />
))
DateInputRefWrapper.displayName = 'DateInput'

export default DateInputRefWrapper

import filterLodash from 'lodash/filter'
import get from 'lodash/get'
import has from 'lodash/has'
import isEqual from 'lodash/isEqual'
import pickBy from 'lodash/pickBy'
import values from 'lodash/values'

import { NodeType } from 'common/builder/constants/NodeType'
import { getBatchHashMap } from 'common/builder/models/Batch/utils'
import { isWhatsAppConnected } from 'common/builder/selectors/builder/whatsAppSelectors'
import { ChannelType } from 'common/core/constants/ChannelType'
import {
  getIsEmailNodeEnabled,
  getIsInstagramChannelEnabled,
  getTikTokChannelEnabled,
  hasConnectedInstagramChannel,
  hasConnectedSMSChannel,
  hasConnectedTelegramChannel,
  hasConnectedTiktokChannel,
  isFBChannelConnected,
  isFBChannelDisabled,
} from 'common/core/selectors/channelSelectors'
import * as flowSelectors from 'common/flow/selectors/flowSelectors'

import * as builderStateSelectors from './builder/builderStateSelectors'

export const getById = (state, managerId) => {
  return state.manager.byId[managerId]
}

export function filter(state, predicate) {
  return filterLodash(values(state.manager.byId), predicate)
}

export const getFlow = (state, managerId) => {
  const manager = getById(state, managerId)
  const flowId = get(manager, 'flowId')
  return flowSelectors.getById(state, flowId)
}

export const getIsViewer = (state, managerId) => {
  const manager = getById(state, managerId)
  return get(manager, 'viewerMode', false)
}

export const getBuilder = (state, managerId) => {
  const manager = getById(state, managerId)
  const builderId = get(manager, 'builderId')
  return builderStateSelectors.getById(state, builderId)
}

export function getBuilderContentHashMap(state, managerId) {
  const builder = getBuilder(state, managerId)
  const batch = builderStateSelectors.getBatch(state, builder.id, { useClientRootId: true })
  return getBatchHashMap(batch)
}

export function getBuilderChanges(state, managerId) {
  const manager = getById(state, managerId)
  const builderId = manager.builderId

  // determine the minimal content patch
  const batch = builderStateSelectors.getBatch(state, builderId, { useClientRootId: true })
  const contentHashMap = getBatchHashMap(batch)
  const changedContentsMap = pickBy(
    contentHashMap,
    (hash, contentId) => manager?.lastContentHashMap?.[contentId] !== hash,
  )

  const contents = batch.contents.filter((content) => changedContentsMap[content._oid])

  const patch = {}
  if (contents.length) {
    patch.contents = contents
  }

  const rootId = batch.root_content
  if (rootId !== manager.lastRootId) {
    // backend doesn't support null as root_content yet, so use empty string for now
    const EMPTY_ROOT_ID_PLACEHOLDER = ''
    patch.root_content = rootId || EMPTY_ROOT_ID_PLACEHOLDER
  }

  // determine the minimal coordinates patch
  const builder = builderStateSelectors.getById(state, builderId)
  const coordinates = builder.coordinates
  const coordinatesPatch = pickBy(coordinates, (coords, nodeId) => {
    return (
      !has(manager.lastCoordinates, nodeId) || !isEqual(coords, manager.lastCoordinates[nodeId])
    )
  })

  return {
    patch,
    coordinatesPatch,

    batch,

    contentHashMap,
    rootId,
    coordinates,
  }
}

export function getChannelListWithEnabledInfo(state) {
  return [
    {
      type: ChannelType.INSTAGRAM,
      nodeType: NodeType.INSTAGRAM,
      connected: hasConnectedInstagramChannel(state),
      enabled: getIsInstagramChannelEnabled(state),
    },
    {
      type: ChannelType.WHATSAPP,
      nodeType: NodeType.WHATS_APP,
      connected: isWhatsAppConnected(state),
      enabled: true,
    },
    {
      type: ChannelType.FB,
      nodeType: NodeType.CONTENT,
      connected: isFBChannelConnected(state),
      enabled: !isFBChannelDisabled(state),
    },
    {
      type: ChannelType.TELEGRAM,
      nodeType: NodeType.TELEGRAM,
      connected: hasConnectedTelegramChannel(state),
      enabled: true,
    },
    {
      type: ChannelType.TIKTOK,
      nodeType: NodeType.TIKTOK,
      connected: hasConnectedTiktokChannel(state),
      enabled: getTikTokChannelEnabled(state),
    },
    {
      type: ChannelType.SMS,
      nodeType: NodeType.SMS,
      connected: hasConnectedSMSChannel(state),
      enabled: true,
    },
    {
      type: ChannelType.EMAIL,
      nodeType: NodeType.EMAIL_NEW,
      connected: getIsEmailNodeEnabled(state),
      enabled: true,
    },
  ]
}

export const getConnectedChannels = (state) =>
  getChannelListWithEnabledInfo(state).filter((o) => o.connected)

export function getFirstEnableInitialNodeType(state) {
  return getChannelListWithEnabledInfo(state).find((e) => e.enabled && e.connected)?.nodeType
}

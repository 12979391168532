import { createSlice, isAnyOf } from '@reduxjs/toolkit'

import { cancelSubscriptionWithRefund } from '../actions/billingActions'
import { PaymentCardRenewalModalState } from '../billingInterfaces'

export const initialState: Readonly<PaymentCardRenewalModalState> = {
  isOpen: false,
  isSendRenewalCardRequest: false,
}

export const paymentCardRenewalModalSlice = createSlice({
  name: 'paymentCardRenewalModal',
  initialState,
  reducers: {
    open: (state) => {
      state.isOpen = true
    },
    close: (state) => {
      state.isOpen = false
    },
  },
  extraReducers: (builder) => {
    builder
      .addMatcher(cancelSubscriptionWithRefund.pending.match, (state) => {
        state.isSendRenewalCardRequest = true
      })
      .addMatcher(
        isAnyOf(
          cancelSubscriptionWithRefund.rejected.match,
          cancelSubscriptionWithRefund.fulfilled.match,
        ),
        (state) => {
          state.isSendRenewalCardRequest = false
          state.isOpen = false
        },
      )
  },
})

import * as atypes from 'common/actions/integrations/common/constants/IntegrationsReduxActionTypes'
import * as API from 'constants/API'

const endpoints = API.integrations.endpoints.hubspot

export const setAccountLinkField = ({ field_id, field_name }) => ({
  type: atypes.HUBSPOT_SET_ACCOUNT_LINK_FIELD,
  $fetch: [
    endpoints.setAccountLinkField,
    {
      method: 'POST',
      body: JSON.stringify({
        field_id,
        field_name,
      }),
      headers: { 'Content-Type': 'application/json' },
    },
  ],
})

export const clearAccountLinkField = () => ({
  type: atypes.HUBSPOT_CLEAR_ACCOUNT_LINK_FIELD,
  $fetch: endpoints.clearAccountLinkField,
})

export const fetchForms = () => ({
  $fetch: endpoints.getForms,
  type: atypes.HUBSPOT_FORMS_FETCH,
})

export const fetchContactFields = () => ({
  $fetch: endpoints.getContactFields,
  type: atypes.HUBSPOT_CONTACT_FIELDS_FETCH,
})

export const refreshFields = (formId) => ({
  $fetch: endpoints.getFormFields.replace(':form_id', formId),
  type: atypes.HUBSPOT_FORM_FIELDS_REFRESH,
})

export const getFormById = (formId) => ({
  $fetch: endpoints.getFormById.replace(':form_id', formId),
  type: atypes.HUBSPOT_FORM_BY_ID_FETCH,
})

import { LayoutWithSidebar } from 'utils/router/components/LayoutWithSidebar/LayoutWithSidebar'
import { getHandle } from 'utils/router/utils/getHandle'

export const ChatRoutes = [
  {
    // :info Works only for mobile view
    path: 'chat/:thread_user_id?/:info?',
    alias: 'liveChat',
    handle: getHandle('live_chat'),
    Component: LayoutWithSidebar,
    children: [
      {
        index: true,
        lazy: () => import('./chat.page'),
      },
    ],
  },
] as const

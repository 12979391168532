// Agency
export const AGENCY_CONNECT_PAGE = 'AGENCY_CONNECT_PAGE'
export const AGENCY_CONFIRM_EMAIL = 'AGENCY_CONFIRM_EMAIL'
export const AGENCY_CONFIRM_EMAIL_REQUEST = 'AGENCY_CONFIRM_EMAIL_REQUEST'
export const AGENCY_CONFIRM_EMAIL_RESPONSE = 'AGENCY_CONFIRM_EMAIL_RESPONSE'
export const AGENCY_CONFIRM_EMAIL_ERROR = 'AGENCY_CONFIRM_EMAIL_ERROR'
export const AGENCY_CONNECT_PAGE_RESPONSE = 'AGENCY_CONNECT_PAGE_RESPONSE'
export const AGENCY_SIGNON_FETCH = 'AGENCY_SIGNON_FETCH'
export const AGENCY_SIGNON_FETCH_RESPONSE = 'AGENCY_SIGNON_FETCH_RESPONSE'
export const AGENCY_SIGNON_CREATE = 'AGENCY_SIGNON_CREATE'
export const AGENCY_SIGNON_CREATE_RESPONSE = 'AGENCY_SIGNON_CREATE_RESPONSE'
export const AGENCY_SIGNON_UPDATE = 'AGENCY_SIGNON_UPDATE'
export const AGENCY_SIGNON_UPDATE_RESPONSE = 'AGENCY_SIGNON_UPDATE_RESPONSE'
export const AGENCY_SIGNON_FIELDS_UPDATE = 'AGENCY_SIGNON_FIELDS_UPDATE'
export const AGENCY_SIGNON_UPLOAD_LOGO = 'AGENCY_SIGNON_UPLOAD_LOGO'
export const AGENCY_SIGNON_UPLOAD_LOGO_RESPONSE = 'AGENCY_SIGNON_UPLOAD_LOGO_RESPONSE'
export const AGENCY_SIGNON_SEND_EMAIL = 'AGENCY_SIGNON_SEND_EMAIL'
export const AGENCY_SIGNON_CONNECT_PAGE = 'AGENCY_SIGNON_CONNECT_PAGE'
export const AGENCY_SIGNON_CONNECT_PAGE_RESPONSE = 'AGENCY_SIGNON_CONNECT_PAGE_RESPONSE'
export const AGENCY_LISTING_FETCH_FILTERS = 'AGENCY_LISTING_FETCH_FILTERS'
export const AGENCY_LISTING_FETCH_FILTERS_REQUEST = 'AGENCY_LISTING_FETCH_FILTERS_REQUEST'
export const AGENCY_LISTING_FETCH_FILTERS_RESPONSE = 'AGENCY_LISTING_FETCH_FILTERS_RESPONSE'
export const AGENCY_LISTING_FETCH_FILTERS_ERROR = 'AGENCY_LISTING_FETCH_FILTERS_ERROR'

export const AGENCY_FETCH_PAGES_WTIH_COMMISSIONS = 'AGENCY_FETCH_PAGES_WTIH_COMMISSIONS'
export const AGENCY_FETCH_PAGES_WTIH_COMMISSIONS_REQUEST =
  'AGENCY_FETCH_PAGES_WTIH_COMMISSIONS_REQUEST'
export const AGENCY_FETCH_PAGES_WTIH_COMMISSIONS_RESPONSE =
  'AGENCY_FETCH_PAGES_WTIH_COMMISSIONS_RESPONSE'
export const AGENCY_FETCH_PAGES_WTIH_COMMISSIONS_ERROR = 'AGENCY_FETCH_PAGES_WTIH_COMMISSIONS_ERROR'

export const AGENCY_FETCH_COMMISSIONS_BY_PAGE = 'AGENCY_FETCH_COMMISSIONS_BY_PAGE'
export const AGENCY_FETCH_COMMISSIONS_BY_PAGE_RESPONSE = 'AGENCY_FETCH_COMMISSIONS_BY_PAGE_RESPONSE'
export const AGENCY_UPDATE_FILTER = 'AGENCY_UPDATE_FILTER'

export const AGENCY_GET_STATUS = 'AGENCY_GET_STATUS'
export const AGENCY_GET_STATUS_RESPONSE = 'AGENCY_GET_STATUS_RESPONSE'
export const AGENCY_GET_STATUS_ERROR = 'AGENCY_GET_STATUS_ERROR'
export const AGENCY_GET_ACHIEVEMENTS = 'AGENCY_GET_ACHIEVEMENTS'
export const AGENCY_GET_ACHIEVEMENTS_RESPONSE = 'AGENCY_GET_ACHIEVEMENTS_RESPONSE'
export const AGENCY_GET_ACHIEVEMENTS_ERROR = 'AGENCY_GET_ACHIEVEMENTS_ERROR'
export const AGENCY_GET_BENEFITS_LINKS = 'AGENCY_GET_BENEFITS_LINKS'
export const AGENCY_GET_BENEFITS_LINKS_RESPONSE = 'AGENCY_GET_BENEFITS_LINKS_RESPONSE'
export const AGENCY_GET_BENEFITS_LINKS_ERROR = 'AGENCY_GET_BENEFITS_LINKS_ERROR'
export const AGENCY_SEND_VERIFICATION = 'AGENCY_SEND_VERIFICATION'

export const AGENCY_SEND_MESSAGE = 'AGENCY_SEND_MESSAGE'
export const AGENCY_SEND_MESSAGE_RESPONSE = 'AGENCY_SEND_MESSAGE_RESPONSE'
export const AGENCY_SEND_MESSAGE_ERROR = 'AGENCY_SEND_MESSAGE_ERROR'

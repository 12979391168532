import * as Sentry from '@sentry/react'
import { ScopeContext, SeverityLevel } from '@sentry/types'

import { UnhandledBusinessError } from 'shared/api/lib/errors/business'
import { getInfoAboutService } from 'utils/getInfoAboutService'
import { CustomZodError } from 'utils/services/errorTrackingService/customZodError'
import type { SentryEnvironment } from 'utils/services/errorTrackingService/interfaces/SentryEnvironment'

import { getControllerFromUrl } from './getControllerFromUrl'
import { ErrorTracking } from './interfaces/types'
import { mergeZodIssues } from './mergeZodIssues'

type CaptureContext = Partial<ScopeContext>

export const USERCENTRICS_SENTRY_NAME = 'Sentry'

export class SentryService implements ErrorTracking.Service {
  private trackedFingerprints: Record<string, boolean> = {}
  public isProvideSentry: boolean
  private readonly isSentryEnv: boolean

  constructor(sentryEnv: SentryEnvironment | null) {
    const usercentricsServicesInfo = getInfoAboutService(USERCENTRICS_SENTRY_NAME)

    this.isSentryEnv = Boolean(sentryEnv)
    this.isProvideSentry = usercentricsServicesInfo[USERCENTRICS_SENTRY_NAME]?.status ?? false
  }

  private createContext = (
    level: SeverityLevel,
    options?: ErrorTracking.MethodOptions,
  ): CaptureContext => {
    const { fingerprint, extra, tags = {} } = options || {}
    const captureContext: CaptureContext = {
      level,
      tags: {
        trackingService: true,
        ...tags,
      },
    }

    if (extra) {
      captureContext.extra = extra
    }

    if (fingerprint) {
      captureContext.fingerprint = [fingerprint]
    }

    return captureContext
  }

  private trackIssue =
    (level: SeverityLevel): ErrorTracking.DeprecatedMethod =>
    (issue, options) => {
      if (!this.isProvideSentry || !this.isSentryEnv) {
        return
      }

      const exception = typeof issue === 'string' ? new Error(issue) : issue

      Sentry.captureException(exception, this.createContext(level, options))
    }

  private logIssue =
    (level: SeverityLevel): ErrorTracking.Method =>
    (issue, { scope, section, tags, ...props }) => {
      if (!this.isProvideSentry || !this.isSentryEnv) {
        return
      }

      const exception = typeof issue === 'string' ? new Error(issue) : issue

      Sentry.captureException(
        exception,
        this.createContext(level, {
          ...props,
          tags: {
            ...tags,
            scope,
            section,
          },
        }),
      )
    }

  trackError = this.trackIssue('error')

  trackWarning = this.trackIssue('warning')

  trackCritical = this.trackIssue('fatal')

  trackWarningOnce: ErrorTracking.OnceMethod = (issue, options) => {
    if (!this.isProvideSentry || !this.isSentryEnv) {
      return
    }

    if (this.trackedFingerprints[options.fingerprint]) {
      return
    }

    this.trackWarning(issue, options)
    this.trackedFingerprints[options.fingerprint] = true
  }

  logWarning = this.logIssue('warning')

  logError = this.logIssue('error')

  logCritical = this.logIssue('fatal')

  logWarningOnce: ErrorTracking.OnceMethodNew = (issue, options) => {
    if (!this.isProvideSentry || !this.isSentryEnv) {
      return
    }

    if (this.trackedFingerprints[options.fingerprint]) {
      return
    }

    this.logWarning(issue, options)
    this.trackedFingerprints[options.fingerprint] = true
  }

  addBreadcrumb: ErrorTracking.BreadcrumbMethod = (breadcrumb) => {
    if (!this.isProvideSentry || !this.isSentryEnv) {
      return
    }
    Sentry.addBreadcrumb(breadcrumb)
  }

  setTag: ErrorTracking.SetTagMethod = (tag, value) => {
    if (!this.isProvideSentry || !this.isSentryEnv) {
      return
    }
    Sentry.setTag(tag, value)
  }

  trackZodError: ErrorTracking.ZodMethod = (error, options) => {
    if (!this.isProvideSentry || !this.isSentryEnv) {
      return
    }

    const { issues: zodIssues } = error
    const { schemaType, url } = options
    const name = `${schemaType}:${url}`

    const customError = new CustomZodError(error)

    Sentry.captureException(customError, (scope) => {
      scope.setExtras({ schemaType })
      const controller = getControllerFromUrl(url)
      scope.setTag('backend_controller', controller || 'no controller')
      scope.setTag('zod_error', true)

      mergeZodIssues(zodIssues).forEach((issue, id: number) => {
        scope.addBreadcrumb({
          category: `Error #${id + 1}`,
          type: 'error',
          level: 'error',
          data: issue,
        })
      })

      scope.addEventProcessor((event) => {
        event.transaction = name
        event.fingerprint = [JSON.stringify(customError.issues, null, 2), name]

        return event
      })

      return scope
    })
  }

  trackUnhandledErrors: ErrorTracking.UnhandledMethod = (errors, endpoint) => {
    if (!this.isProvideSentry || !this.isSentryEnv) {
      return
    }

    const transactionName = endpoint || 'no endpoint'

    errors.forEach((error) => {
      Sentry.captureException(new UnhandledBusinessError(error, endpoint), (scope) => {
        scope.setTag('unhandled_business_error', true)

        const controller = getControllerFromUrl(transactionName)

        if (controller) {
          scope.setTag('backend_controller', controller)
        }

        scope.addEventProcessor((event) => {
          event.transaction = transactionName
          event.fingerprint = [transactionName, error.original_message]

          return event
        })

        scope.addBreadcrumb({
          type: 'error',
          level: 'error',
          data: error,
        })

        return scope
      })
    })
  }

  init: ErrorTracking.InitMethod = (error) => {
    const eventInitializedSentry = error
      ? new CustomEvent('initializedSentry', { detail: error })
      : new Event('initializedSentry')
    window.dispatchEvent(eventInitializedSentry)
  }

  refreshPermissions: ErrorTracking.RefreshMethod = (error) => {
    if (error) {
      this.isProvideSentry = true
      this.init(error)
      return
    }

    const usercentricsServicesInfo = getInfoAboutService(USERCENTRICS_SENTRY_NAME)
    this.isProvideSentry = usercentricsServicesInfo[USERCENTRICS_SENTRY_NAME]?.status ?? false

    if (this.isProvideSentry && this.isSentryEnv) {
      this.init()
    }
  }
}

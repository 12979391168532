/**
 * Extracts the maximum tier contact from the plan subscribers string.
 * Returns 0 if the input is invalid.
 *
 * @param {string | undefined} plan_subscribers - The maximum number of contacts allowed for the tier, in the format "min-max".
 * @returns {number} - The maximum tier contact or 0 if the input is invalid.
 */
export const getMaxTierContact = (plan_subscribers: string | undefined): number => {
  if (!plan_subscribers || !plan_subscribers.includes('-')) {
    return 0
  }
  const parts = plan_subscribers.split('-')
  const maxContact = parseInt(parts[1].trim(), 10)
  return isNaN(maxContact) ? 0 : maxContact
}

/**
 * Determines if the current contacts count is within the tier limit.
 *
 * @param {string | undefined} plan_subscribers - The maximum number of contacts allowed for the tier, in the format "min-max".
 * @param {number | undefined} current_contacts_count - The current number of contacts.
 * @returns {boolean} - Returns true if the current contacts count is within 90% to 100% of the max tier contact, otherwise false.
 */
export const getTierLimitState = (
  plan_subscribers: string | undefined,
  current_contacts_count: number | undefined,
): boolean => {
  if (!plan_subscribers || !current_contacts_count || !plan_subscribers.includes('-')) return false

  const maxTierContact = getMaxTierContact(plan_subscribers)
  return current_contacts_count >= 0.9 * maxTierContact && current_contacts_count <= maxTierContact
}

import BaseBlockConfig from 'common/builder/blocks/BaseBlockConfig'
import { IBlockBase, IOtnRequestBlock } from 'common/builder/blocks/blockInterfaces'
import { BlockType } from 'common/builder/constants/BlockType'

class OtnRequestBlockConfig extends BaseBlockConfig {
  blockType = BlockType.ONE_TIME_NOTIFY_REQUEST

  getDefaults = (): Omit<IOtnRequestBlock, keyof IBlockBase> => ({
    title: '',
    reasonId: null,
    image: null,
    subtitle: '',
  })
}

export default new OtnRequestBlockConfig()

import { matchPath, To, RouterNavigateOptions } from '@remix-run/router'

import { webViewRoutes } from '../constants'
import { BusinessComponent } from '../types'
import { generateQuery } from '../utils/helpers'

import { getRouter } from './getRouter'

/**
 * TODO TC-668
 *  Temporary solution for utils.
 * We should find better solution navigating out of functional components. react.router is private method of the library
 */
//
export const navigatePollyfill = (to: To | null | number, options?: RouterNavigateOptions) =>
  typeof to === 'number' ? getRouter()?.navigate(to) : getRouter()?.navigate(to, options)

/**
 * TODO TC-668
 * Temporary solution for class components when we are using navigating in the lifecycle methods.
 * We should investigate why router.navigate doesn't work properly in the class components lifecycle
 */
export const navigatePollyfillForLifecycleMethods = (
  to: To | null | number,
  options?: RouterNavigateOptions,
): Promise<void> => {
  return new Promise((resolve, reject) => {
    setTimeout(async () => {
      try {
        await navigatePollyfill(to, options)
        resolve()
      } catch (error) {
        reject(error)
      }
    })
  })
}

/**
 * IMPORTANT! Use this selector only for actions, selectors, utils or Class components.
 * For Functional components use `useSearchParams from `react-router-dom`instead
 */
export const getQuery = (): StringMapType => {
  const search = getSearch()
  return generateQuery(search)
}

/**
 * IMPORTANT! Use this selector only for actions, selectors, utils or Class components.
 * For Functional components use `useParams` from `react-router-dom`
 */
export function getParams(): StringMapType {
  const { matches } = getRouter()?.state ?? {}
  const params: Record<string, string> = {}
  if (!matches) {
    return params
  }

  const match = matches[matches.length - 1]
  if (match) {
    const items = match.params
    Object.keys(items).forEach((key) => {
      if (items[key] !== undefined && key !== '*') {
        params[key] = items[key] as string
      }
    })
  }
  return params
}

export const getLocation = () => getRouter()?.state?.location ?? window.location

/**
 * IMPORTANT! Use this selector only for actions, selectors, utils or Class components.
 * For Functional components use `useRoutePageId` from `utils/router/tools`
 */
export const getPageId = (): string | null => {
  return getParams().acc_id || null
}

export const getSearch = () => getRouter()?.state?.location?.search ?? ''

export const getHash = () => getRouter()?.state?.location?.hash ?? ''

export const getPathname = () => getRouter()?.state?.location?.pathname ?? ''

export const isPathnameMatchRoute = (pattern: string) => {
  const { pathname } = getLocation()

  if (!pattern) {
    return false
  }

  return Boolean(matchPath({ path: pattern }, pathname))
}

export const isPathnameInclude = (value: string) => {
  const { pathname } = getLocation()

  if (!value) {
    return false
  }

  return pathname.includes(value)
}

export const getComponentByRoute = (): BusinessComponent => {
  const matches = getRouter()?.state?.matches
  let result: BusinessComponent = 'other'

  if (!matches) {
    return result
  }

  matches.forEach((match) => {
    const component = match.route.handle?.component

    if (component) {
      result = component
    }
  })

  return result
}

export const isWebViewRoute = () => webViewRoutes.some((route) => isPathnameMatchRoute(route))

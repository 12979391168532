import { ISlice, ITextProcessor, IProcessorState, IRange } from './processor/constants'

export interface IEditorContext {
  processor: ITextProcessor
  input: HTMLElement | null
  insert: (value: string, selectionOffset?: number) => void
  undo: () => void
  redo: () => void
  edit: (state: IProcessorState) => void
}

export enum ExportFormat {
  Clipboard = 'clipboard',
}

export interface ISliceComponentProps extends IEditorContext {
  slice: ISlice
  isSelected?: boolean
  children?: React.ReactNode
}

export type SliceComponent = React.FunctionComponent<ISliceComponentProps>

export type InputElement = HTMLDivElement | HTMLTextAreaElement

export type EditorInputProps = Omit<
  React.InputHTMLAttributes<InputElement>,
  'onSelect' | 'onChange'
> & {
  contentRef: React.MutableRefObject<HTMLElement | null>
  multiline?: boolean
  noWrap?: boolean
  onChange: (value: string, selectedIndex: number) => void
  onSelect: (start: number, end: number) => void
  onScrollLeftChanged?: (value: number) => void
  rows?: number
  selection: IRange
}

import {
  MESSENGER_REF_URL,
  ADS_JSON,
  ADS,
} from 'apps/growthTools/components/WidgetEditor/constants/WidgetTypes'
import { ChannelType } from 'common/core/constants/ChannelType'
import { getWidgetCodeLayout } from 'constants/Widget'
import { analyticsService } from 'utils/services/analytics'

export function isCheckbox(widget) {
  return widget?.data?.main?.buttonType === 'checkbox'
}

export function isMessengerRefUrl(widget) {
  return MESSENGER_REF_URL === widget.widget_type && widget.channel === ChannelType.FB
}

export function isIGRefUrl(widget) {
  return MESSENGER_REF_URL === widget.widget_type && widget.channel === ChannelType.INSTAGRAM
}

export function isWhatsAppRefUrl(widget) {
  return MESSENGER_REF_URL === widget.widget_type && widget.channel === ChannelType.WHATSAPP
}

export function isTelegramRefUrl(widget) {
  return MESSENGER_REF_URL === widget.widget_type && widget.channel === ChannelType.TELEGRAM
}

export function isAdsJSON(widget) {
  return ADS_JSON === widget.widget_type || ADS === widget.widget_type
}

export function isOther(widget) {
  return MESSENGER_REF_URL === widget.widget_type
}

export function getSnippet(widget, widgetHasChanges) {
  if (isAdsJSON(widget)) {
    return JSON.stringify(widget.json_data, null, 2)
  }
  if (isMessengerRefUrl(widget)) {
    return widgetHasChanges ? null : widget.ref_url || widget.messenger_ref_url
  }
  if (isIGRefUrl(widget)) {
    return widgetHasChanges ? null : widget.ref_url
  }
  if (isWhatsAppRefUrl(widget)) {
    return widgetHasChanges ? null : widget.ref_url
  }
  if (isTelegramRefUrl(widget)) {
    return widgetHasChanges ? null : widget.ref_url
  }
  return getWidgetCodeLayout(widget.script_url).join('\n')
}

export function copyWidgetClickedEvent(widget) {
  if (!widget) {
    return
  }

  analyticsService.sendEvent('COPY_BUTTON.CLICKED', {
    widgetId: widget.id,
    channel: widget.channel,
  })
}

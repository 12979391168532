import React, { useEffect } from 'react'
import cx from 'classnames'
import { useNavigate } from 'react-router-dom'
import { l } from '@manychat/manyui'

import { analyticsService } from 'utils/services/analytics'
import { linkURL } from 'utils/url'

import cm from '../StaticNotifications.module.css'

export const PayNowNotification = () => {
  const navigate = useNavigate()

  useEffect(() => {
    analyticsService.sendEvent('NOTIFICATION_BAR.PAY_NOW.SHOWN')
  }, [])

  const handleClick = () => {
    analyticsService.sendEvent('NOTIFICATION_BAR.PAY_NOW.CLICKED')
    navigate(linkURL(`/settings#billing`))
  }

  const text = l.translate("We couldn't process your last payment. Try again to stay on Pro.")

  return (
    <>
      <span>{text}</span>
      <a target="_blank" className={cx(cm.more, 'm-l-xs')} onClick={handleClick}>
        {l.translate('Retry payment')}
      </a>
    </>
  )
}

import React, { useRef } from 'react'
import cx from 'classnames'
import { Popover } from '@manychat/manyui'

import {
  CustomizableBreadcrumbsFolderButtonProps,
  CustomizableBreadcrumbsFolderButton,
} from 'components/Breadcrumbs/components/CustomizableBreadcrumbsFolderButton/CustomizableBreadcrumbsFolderButton'
import { AllowPropagationEvents } from 'components/Breadcrumbs/lib/constants'
import { BaseBreadcrumbsSegment } from 'components/Breadcrumbs/lib/types'

import cm from './CustomizableBreadcrumbsMenu.module.css'

interface CustomizableBreadcrumbsMenuProps<CustomSegment extends BaseBreadcrumbsSegment> {
  segments: CustomSegment[]
  isOpen: boolean
  openMenu: () => void
  closeMenu: () => void
  anchorRef?:
    | React.MutableRefObject<HTMLButtonElement | null>
    | React.RefCallback<HTMLButtonElement | null>
  onButtonClick: (event: React.MouseEvent<HTMLButtonElement>, segment: CustomSegment) => void
  renderFolderButton?: (
    props: CustomizableBreadcrumbsFolderButtonProps<CustomSegment>,
  ) => React.JSX.Element
}

export const CustomizableBreadcrumbsMenu = <CustomSegment extends BaseBreadcrumbsSegment>({
  segments,
  isOpen,
  openMenu,
  closeMenu,
  onButtonClick,
  anchorRef,
  renderFolderButton,
}: CustomizableBreadcrumbsMenuProps<CustomSegment>) => {
  const buttonRef = useRef<HTMLButtonElement | null>(null)

  const getFolderButton = (props: CustomizableBreadcrumbsFolderButtonProps<CustomSegment>) => {
    return renderFolderButton ? (
      renderFolderButton(props)
    ) : (
      <CustomizableBreadcrumbsFolderButton {...props} />
    )
  }

  return (
    <>
      <button
        ref={(element) => {
          buttonRef.current = element

          if (anchorRef) {
            if (typeof anchorRef === 'function') {
              anchorRef(element)
            } else {
              anchorRef.current = element
            }
          }
        }}
        onClick={openMenu}
        className={cm.anchor}
      >
        ...
      </button>

      <Popover
        at="bottom"
        popoverOrigin="top left"
        anchorOrigin="bottom left"
        anchor={buttonRef.current}
        open={isOpen}
        onRequestClose={closeMenu}
        className={cm.popover}
        useLayerForClickAway={false}
        allowPropagationEvents={AllowPropagationEvents}
      >
        <ul className={cm.hiddenSegmentsList}>
          {segments.map((segment, index) => (
            <li key={segment.path} className={cx(index !== 0 && 'p-x-xs')}>
              {getFolderButton({
                segment,
                onClick: onButtonClick,
                showIcon: index === 0,
              })}
            </li>
          ))}
        </ul>
      </Popover>
    </>
  )
}

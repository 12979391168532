import { extent } from 'd3-array'
import { scaleLinear } from 'd3-scale'
import dot from 'dot-prop-immutable'
import get from 'lodash/get'
import isEmpty from 'lodash/isEmpty'

import { setWidgetFlow } from 'apps/growthTools/actions/widgetActionsTyped'
import getDefaultWidgetSettings from 'apps/growthTools/components/WidgetEditor/constants/getDefaultSettings'
import { GrowthToolsTabType } from 'apps/growthTools/constants/GrowthToolsTabType'
import WidgetModel from 'apps/growthTools/models/Widget'
import { STAT_N_A } from 'apps/growthTools/models/Widget/constants'
import * as commonAtypes from 'common/core/constants/ReduxActionTypes'
import {
  makeLimitedListReducer,
  makeDetailsReducer,
  mergeToListItem,
  setCurrentItem,
} from 'utils/factory'

import * as atypes from '../constants/gtReduxActionTypes'

const listInitialState = {
  filter: null,
  searchQuery: '',
  sortingBy: null,
  sortingOrder: null,
}

const limitedListReducer = makeLimitedListReducer('widgets')
const detailsReducer = makeDetailsReducer('widgets')

const defaultWidgetSettings = getDefaultWidgetSettings()

function widgetsListReducer(state, action) {
  const isInit = state === undefined

  // We handle updated notification only in FlowBuilder. Handling here is unnecessary and can cause bugs
  if (!isInit && action.type === 'WIDGETS_UPDATED_NOTIFICATION') {
    return state
  }

  state = limitedListReducer(state, action)

  if (isInit) {
    state = { ...state, ...listInitialState }
  }

  switch (action.type) {
    case atypes.WIDGETS_FETCH_RESPONSE: {
      const $domains = {}
      if (Array.isArray(state.items) && state.items.length > 0) {
        const firstWidget = state.items[0]
        for (let statName of Object.keys(firstWidget.stats)) {
          if (statName[0] === '$') {
            const statValues = state.items
              .map((w) => w.stats[statName])
              .filter((v) => v !== STAT_N_A)
            const domain = extent(statValues)
            const scale = scaleLinear().domain(domain).range(['#DC9C41', '#2FC216']) //.interpolate(interpolateHsl)
            $domains[`${statName}`] = scale
          }
        }
      }

      return {
        ...state,
        templateInstallations: action.data.template_installations || [],
        $domains,
        max_free_widgets: action.data.max_free_widgets,
        is_need_to_migrate: action.data.is_need_to_migrate,
      }
    }

    // Navigation
    case atypes.WIDGETS_FILTER_CHANGED: {
      const { filter } = action
      return { ...state, filter }
    }
    case atypes.WIDGETS_SEARCH_QUERY_CHANGED: {
      const { searchQuery } = action
      return { ...state, searchQuery }
    }
    case atypes.WIDGETS_SORTING_BY_CHANGED: {
      const { sortingBy } = action
      return { ...state, sortingBy }
    }
    case atypes.WIDGETS_SORTING_ORDER_CHANGED: {
      const { sortingOrder } = action
      return { ...state, sortingOrder }
    }

    case atypes.WIDGETS_CURRENT_FETCH_RESPONSE:
    case atypes.WIDGETS_CURRENT_SAVE_RESPONSE: {
      if (action.data && action.data.item) {
        const widget = { ...action.data.item }
        // Fix for highly rare not fully investigated case when backend returns empty array for
        // 'feed_comment_settings' in widget.data.
        // Bugs: 51286, 53492
        const commentSettings = widget.data[GrowthToolsTabType.COMMENT_SETTINGS]
        if (Boolean(commentSettings) && isEmpty(commentSettings)) {
          widget.data[GrowthToolsTabType.COMMENT_SETTINGS] =
            defaultWidgetSettings[widget.widget_type][GrowthToolsTabType.COMMENT_SETTINGS]
        }

        return mergeToListItem(state, widget.id, widget)
      }

      return state
    }
  }

  return state
}

function widgetReducer(state, action) {
  state = detailsReducer(state, action)

  switch (action.type) {
    // Activation
    case atypes.WIDGETS_UPDATE_STATUS_REQUEST: {
      const widgetData = state.byId[action.id]
      if (widgetData && widgetData.activationError != null) {
        return dot.delete(state, `byId.${action.id}.activationError`)
      }
      state = dot.set(state, `byId.${action.id}.fetching`, true)
      return state
    }
    case atypes.WIDGETS_UPDATE_STATUS_RESPONSE: {
      const widget = action.data.item
      state = mergeToListItem(state, action.id, widget)
      const widgetData = state.byId[action.id]
      if (widgetData) {
        state = dot.set(state, `byId.${action.id}`, setCurrentItem(widgetData, widget))
      }
      state = dot.set(state, `byId.${action.id}.fetching`, false)
      return state
    }
    case atypes.WIDGETS_UPDATE_STATUS_ERROR: {
      const { error, id } = action
      state = dot.set(state, `byId.${id}.fetching`, false)
      return dot.set(state, `byId.${id}.activationError`, error)
    }

    // Flow replace
    case setWidgetFlow.fulfilled.type: {
      const { payload, meta } = action
      if (state.byId[meta.widget_id]) {
        const item = WidgetModel.adapter(payload.widget)
        const { flowId, flow } = item
        return dot.merge(state, `byId.${meta.widget_id}.item`, { flowId, flow })
      }
      return state
    }

    // Manage attachments upload
    case commonAtypes.ATTACHMENT_UPLOAD_RESPONSE: {
      const { widgetId, tab, prop, data, uploadId } = action

      const _uploadId = get(state, `byId.${widgetId}.item.data.${tab}.${prop}.uploadId`)
      if (_uploadId === uploadId) {
        return dot.set(state, `byId.${widgetId}.item.data.${tab}.${prop}`, data.attachment)
      }
      return state
    }
    case commonAtypes.ATTACHMENT_UPLOAD_ERROR: {
      const { widgetId, tab, prop, uploadId } = action

      const _uploadId = get(state, `byId.${widgetId}.item.data.${tab}.${prop}.uploadId`)
      if (_uploadId === uploadId) {
        return dot.delete(state, `byId.${widgetId}.item.data.${tab}.${prop}`)
      }
      return state
    }
  }

  return state
}

export default function widgetsReducer(state, action) {
  state = widgetReducer(state, action)
  const list = widgetsListReducer(state.list, action)
  if (list !== state.list) {
    state = { ...state, list }
  }
  switch (action.type) {
    case atypes.WIDGETS_SET_SETUP_MODAL_VISIBILITY: {
      return {
        ...state,
        isSetupModalOpen: action.isOpen,
        setupModalOkAction: action.okAction,
      }
    }
  }
  return state
}

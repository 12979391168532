import { settingsApi } from 'shared/api/requests/settings'
import { EmailBillingStats, SmsBillingStats } from 'shared/api/requests/settings/stats/schemas'
import { createAsyncAction } from 'shared/lib/redux'

interface FetchEmailStatsResult {
  stats: EmailBillingStats
}

export const fetchEmailStats = createAsyncAction<FetchEmailStatsResult, void>(
  'settings/fetchEmailStats',
  async () => {
    const response = await settingsApi.stats.fetchEmailBillingStats()

    return response.data
  },
)

interface FetchSmsStatsResult {
  stats: SmsBillingStats
}

export const fetchSMSStats = createAsyncAction<FetchSmsStatsResult, void>(
  'settings/fetchSMSStats',
  async () => {
    const response = await settingsApi.stats.fetchSmsBillingStats()

    return response.data
  },
)

import { createSelector } from '@reduxjs/toolkit'

import { AudienceImportState } from 'common/audience/audienceInterfaces'
import { ISubscriber } from 'common/audience/components/UserModal/interfaces'
import { SELECTION_TYPE } from 'common/audience/models/Subscriber'
import { getCurrentAccount } from 'common/core/selectors/appSelectors'
import {
  getIsEmailNodeEnabled,
  hasConnectedSMSChannel,
  hasConnectedTelegramChannel,
  hasConnectedWhatsAppChannel,
} from 'common/core/selectors/channelSelectors'
import { FilterField } from 'common/filter/models/AudienceFilter/constants'
import { Filter } from 'shared/api/requests/broadcasting/schemas'

export const getAudience = (state: RootState) => state.audience || {}

export const getAudienceImport = (state: RootState): AudienceImportState =>
  getAudience(state).import

export const getTotal = (state: RootState) => getAudience(state).total || 0

export const getCurrentUser = (state: RootState) =>
  getAudience(state).current.item || ({} as ISubscriber)

export const getCurrentUserIsFetching = (state: RootState) =>
  getAudience(state).current.fetching || false

export const getAudienceFilter = (state: RootState) => getAudience(state).filter as Filter

export const getAudienceSearchQuery = (state: RootState) => getAudience(state).searchQuery

export const getSelectionType = (state: RootState) => getAudience(state).selectionType

export const getSelectedIds = (state: RootState) => getAudience(state).selectedIds

export const getSelected = (state: RootState) => {
  const total = getTotal(state)
  const selectedCount = Object.keys(getSelectedIds(state)).length
  const exclude = getSelectionType(state) === SELECTION_TYPE.EXCLUDE
  return exclude ? total - selectedCount : selectedCount
}

export const getUserModalId = (state: RootState) => getAudience(state).showSubscriberModal

export const getSelectedFields = createSelector(getAudienceImport, ({ headers, headersMap }) =>
  headers.filter((headerKey) => headersMap[headerKey]?.value),
)

export const selectIsNextStepDisabled = createSelector(
  [getAudienceImport, getSelectedFields],
  ({ file, step, isCheckedAgreement }, selectedFields) => {
    if (step === 'upload') {
      return file === null || !isCheckedAgreement
    }
    if (step === 'mapping') {
      return !selectedFields.length
    }
    return false
  },
)

export const getAvailableSystemFieldNames = (state: RootState) =>
  state.filter.fields?.system?.map((field) => field.name) || []

export const isChannelsStepEnabled = (state: RootState) => {
  const currentAccountState = getCurrentAccount(state)
  if (!currentAccountState) return false

  const { can_import_whatsapp_subscribers, can_import_sms_subscribers } = currentAccountState

  return can_import_whatsapp_subscribers && can_import_sms_subscribers
}

export const selectIsWASelected = createSelector(
  getAudienceImport,
  ({ headersMap, selectedExtraChannels }) =>
    Object.values(headersMap).some(({ value }) => value === FilterField.WA_ID) ||
    selectedExtraChannels.includes('wa_id'),
)

export const isImportButtonShownSelector = (state: RootState) => {
  const hasConnectedTelegram = hasConnectedTelegramChannel(state)
  const hasConnectedWhatsApp = hasConnectedWhatsAppChannel(state)
  const hasConnectedSms = hasConnectedSMSChannel(state)
  const hasConnectedEmail = getIsEmailNodeEnabled(state)

  return hasConnectedTelegram || hasConnectedWhatsApp || hasConnectedSms || hasConnectedEmail
}

export const isImportContactsAvailableSelector = (state: RootState) => {
  const currentAccountState = getCurrentAccount(state)

  const {
    can_import_telegram_subscribers,
    can_import_whatsapp_subscribers,
    can_import_sms_subscribers,
    can_import_email_subscribers,
  } = currentAccountState

  return (
    can_import_telegram_subscribers ||
    can_import_whatsapp_subscribers ||
    can_import_sms_subscribers ||
    can_import_email_subscribers
  )
}

export const getSelection = createSelector(
  getAudienceSearchQuery,
  getAudienceFilter,
  getSelectionType,
  getSelectedIds,
  (q, filter, selection_type, selectedIdsMap) => ({
    q,
    filter,
    selection_type,
    ids: Object.keys(selectedIdsMap),
  }),
)

import { AnyAction } from 'redux'

import * as actionTypes from 'apps/instagram/constants/instagramReduxActionTypes'
import { IInstagramState } from 'apps/instagram/models/interfaces'

export const initialState = {
  accounts: null,
  accountsFetching: false,
  toggleChannelFetching: false,
  instagramPostsFetching: false,
}

export function instagramReducer(state: IInstagramState = initialState, action: AnyAction) {
  switch (action.type) {
    case `${actionTypes.GET_INSTAGRAM_ACCOUNTS}_REQUEST`:
      return { ...state, accountsFetching: true }
    case `${actionTypes.GET_INSTAGRAM_ACCOUNTS}_RESPONSE`:
      return { ...state, accountsFetching: false, accounts: action.data }
    case `${actionTypes.GET_INSTAGRAM_ACCOUNTS}_ERROR`:
      return { ...state, accountsFetching: false }

    case actionTypes.TOGGLE_INSTAGRAM_CHANNEL_REQUEST:
      return { ...state, toggleChannelFetching: true }
    case actionTypes.TOGGLE_INSTAGRAM_CHANNEL_RESPONSE:
      return { ...state, toggleChannelFetching: false }
    case actionTypes.TOGGLE_INSTAGRAM_CHANNEL_ERROR:
      return { ...state, toggleChannelFetching: false }
  }
  return state
}

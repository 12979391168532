import React, { useCallback } from 'react'
import { ErrorBoundary as ReactErrorBoundary } from 'react-error-boundary'

import { ErrorBoundaryProps } from 'common/core/components/ErrorBoundary/ErrorBoundaryProps'
import { Fallback } from 'common/core/components/ErrorBoundary/Fallback'
import { getInfoAboutService } from 'utils/getInfoAboutService'
import { getMetricPathname, sendHealthMetric } from 'utils/healthMetrics'
import { getComponentByRoute } from 'utils/router/tools'
import errorTrackingService from 'utils/services/errorTrackingService'
import { CodebaseScope } from 'utils/services/errorTrackingService/aggregation'
import { USERCENTRICS_SENTRY_NAME } from 'utils/services/errorTrackingService/sentryService'

export const ErrorBoundary = <Scope extends CodebaseScope>({
  children,
  scope,
  resetKeys,
  fallbackComponent = Fallback,
  ...props
}: ErrorBoundaryProps<Scope>) => {
  const section = 'section' in props ? props.section : undefined
  const metricPathname = getMetricPathname()

  const handleReset = useCallback(() => {
    window.location.reload()
  }, [])

  const logError = useCallback(
    (error: Error) => {
      errorTrackingService.trackError(error, {
        tags: {
          ErrorBoundary: true,
          scope,
          section,
        },
      })

      const usercentricsServicesInfo = getInfoAboutService(USERCENTRICS_SENTRY_NAME)

      sendHealthMetric('error_boundary_event', {
        url: metricPathname,
        scope,
        component: getComponentByRoute(),
        section: section || '',
        withVendorShareData: usercentricsServicesInfo[USERCENTRICS_SENTRY_NAME]?.status ?? false,
      })
    },
    [metricPathname, scope, section],
  )

  return (
    <ReactErrorBoundary
      FallbackComponent={fallbackComponent}
      onError={logError}
      onReset={handleReset}
      resetKeys={resetKeys}
    >
      {children}
    </ReactErrorBoundary>
  )
}

import React, { useEffect } from 'react'
import { useAppSelector } from 'reduxTyped'
import { Modal, TextInputV2, l } from '@manychat/manyui'

import { isNewConnectModalOpen } from 'apps/sms/selectors/smsSelectors'
import { analyticsService } from 'utils/services/analytics'

import { ConnectTwilio } from './ConnectTwilio'
import { useSmsConnectionData } from './useSmsConnectionData'

const SMSConnectionModalNew = () => {
  const isModalOpen = useAppSelector(isNewConnectModalOpen)

  useEffect(() => {
    if (isModalOpen) {
      analyticsService.sendEvent('SMS_NEW_PARADIGM.OPEN_MODAL')
    }
  }, [isModalOpen])

  const {
    handleChangePhone,
    handleChangeToken,
    phone,
    token,
    sid,
    handleChangeSid,
    handleCloseModal,
    handleConnect,
    isFormOpen,
    loading,
    handleOpenModal,
  } = useSmsConnectionData()

  const footerButtons = [
    {
      label: l.translate('Cancel'),
      disabled: loading,
      onClick: handleCloseModal,
    },
    {
      label: l.translate('Connect'),
      variant: 'primary',
      disabled: loading,
      loading: loading,
      onClick: isFormOpen ? handleConnect : handleOpenModal,
    },
  ]

  return (
    <Modal
      open={isModalOpen}
      onRequestClose={handleCloseModal}
      title={isFormOpen && l.translate('Connect SMS Channel')}
      buttons={footerButtons}
      noContentPadding
      width={610}
    >
      {isFormOpen ? (
        <div className="p-a-lg" data-test-id="twilio-form-content">
          <TextInputV2
            id="sms-connect-sid"
            value={sid}
            onChange={handleChangeSid}
            label={l.translate('Twillio Account SID')}
            className="m-t-md m-b-xs"
          />

          <TextInputV2
            className="m-t-md m-b-xs"
            id="sms-connect-token"
            value={token}
            onChange={handleChangeToken}
            label={l.translate('Twillio Auth Token')}
          />

          <TextInputV2
            value={phone}
            name="sms-connect-phone"
            onChange={handleChangePhone}
            placeholder={l.translate('Enter phone')}
            label={l.translate('Twillio Phone Number')}
            className="m-t-md m-b-xs"
          />
        </div>
      ) : (
        <ConnectTwilio />
      )}
    </Modal>
  )
}

export default SMSConnectionModalNew

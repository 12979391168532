import React from 'react'

import { PreviewBlocksAvatar } from './PreviewBlocksAvatar'

interface AvatarKeywordTTBlockProps {
  title: string
  info: string
  avatar?: string
  keyword?: string
  keywordLabel?: string
  dataTestId?: string
  children: React.ReactNode
}

export function AvatarKeywordTTBlock(props: AvatarKeywordTTBlockProps) {
  const { title, info, avatar, keyword, keywordLabel, dataTestId, children } = props

  return (
    <div data-test-id={dataTestId} className="d-flex">
      <PreviewBlocksAvatar url={avatar} />
      <div style={{ width: '100%' }} className="p-a-xxs m-l-xs">
        <span className="text-subheading d-block font-semibold">{title}</span>
        <span className="d-block">{info}</span>
        {keyword && (
          <>
            <span className="d-block m-t m-b-xs">{keywordLabel}</span>
            <span className="d-block bg-default p-y-xxs text-heading-2 text-black text-center rounded">
              {keyword}
            </span>
            <div>{children}</div>
          </>
        )}
      </div>
    </div>
  )
}

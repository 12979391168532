import React, { useCallback } from 'react'
import cx from 'classnames'
import { useAppDispatch } from 'reduxTyped'
import { Modal, l, BtnV2, Loader, Icon, palettes } from '@manychat/manyui'

import { useModalData } from 'apps/cms/components/Flows/components/AutomationModals/hooks/useModalData'
import { contentManagementActions } from 'apps/cms/store'
import { AutomationModalType } from 'apps/cms/store/lib/types'
import UsesInAutomationList from 'components/UsesInAutomationList/UsesInAutomationList'

import { useDeleteFlowModal } from './hooks/useDeleteFlowModal'

export const DeleteFlowModal = () => {
  const { flowPath, flowNs, flowName } = useModalData({
    type: AutomationModalType.DELETE_FLOW as const,
  })

  const dispatch = useAppDispatch()

  const handleClose = useCallback(() => {
    dispatch(contentManagementActions.closeModal(AutomationModalType.DELETE_FLOW))
  }, [dispatch])

  const { usageInfo, deleteFlow, isLoading } = useDeleteFlowModal({
    flowId: flowNs,
    flowPath: flowPath,
    isOpen: true,
    closeModal: handleClose,
  })

  return (
    <Modal
      width={460}
      title={<div className="p-y-xs">{l.translate('Delete Automation?')}</div>}
      open
      onRequestClose={handleClose}
      buttons={[
        <BtnV2 onClick={handleClose} className="m-y-xs">
          {l.translate('Cancel')}
        </BtnV2>,
        <BtnV2 onClick={deleteFlow} loading={isLoading} variant="primary" className="m-y-xs">
          {l.translate('Delete')}
        </BtnV2>,
      ]}
    >
      <div className="p-x p-y-md">
        {usageInfo && (
          <div className={cx(!usageInfo.isNoUses && 'm-b-md')}>
            {l.translate('You are going to delete')} <b className="word-break-all">{flowName}</b>.
          </div>
        )}

        {usageInfo && !usageInfo.isNoUses && (
          <>
            <div className="panel panel-info d-flex align-start p-a-md">
              <Icon.Info
                className="flex-shrink-0 m-r"
                size={24}
                color={palettes.semantic.primary}
              />

              <p className="m-0">
                {l.translate(
                  'When you delete Automation, any associated Triggers will be moved to the trash folder as well. You can restore both the Automation and its Triggers from the trash folder.',
                )}
              </p>
            </div>

            <UsesInAutomationList className="m-t-md" uses={usageInfo} />
          </>
        )}

        {!usageInfo && <Loader size={24} />}
      </div>
    </Modal>
  )
}

import { alert } from 'common/core'
import { settingsApi } from 'shared/api/requests/settings'
import { PayPalConfig } from 'shared/api/requests/settings/schemas'
import { createAsyncAction } from 'shared/lib/redux'

export const connectPaypal = createAsyncAction(
  'settings/connectPaypal',
  async (payload: PayPalConfig) => {
    const response = await settingsApi.payments.connectPaypal({
      body: payload,
    })

    return response.data
  },
  {
    onBusinessError: (error) => {
      if (settingsApi.payments.connectPaypal.isPayPalDefaultError(error)) {
        error.handle()
        alert(error.original_message, 'danger')
      }
    },
  },
)

export const disconnectPaypal = createAsyncAction('settings/disconnectPaypal', async () => {
  const response = await settingsApi.payments.disconnectPaypal()

  return response.data
})

import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import { ProProduct } from 'common/core/interfaces/products'

export interface DisableProductModalState {
  isOpen: boolean
  product: ProProduct | null
}

export const initialState: Readonly<DisableProductModalState> = {
  isOpen: false,
  product: null,
}

export const disableProductModalSlice = createSlice({
  name: 'disableProductModal',
  initialState,
  reducers: {
    open: (state, action: PayloadAction<ProProduct>) => {
      state.isOpen = true
      state.product = action.payload
    },
    close: (state) => {
      state.isOpen = false
      state.product = null
    },
  },
})

export const disableProductModalActions = disableProductModalSlice.actions

import { getString } from '../../../utils/localization/format'
import {
  InitialMainOptionType,
  LabelKey,
  InitialSubOptionType,
  MainOptionType,
  SubOptionType,
} from '../SelectWrapper/interfaces'

import {
  OptionFilterType,
  GeneralQueryType,
  SelectedOption,
  HelpedUtilsExtraOptionsType,
  InitialAutocompleteMainOptionType,
  NewOptionType,
} from './interfaces'

export const defaultOptionFilter: OptionFilterType = (label, query) =>
  !query || getString(label).toLowerCase().indexOf(query.toLowerCase()) >= 0

export const getLabelByValue = <MainOption extends InitialMainOptionType, SubOption>({
  value,
  options,
  labelKey,
}: {
  value: GeneralQueryType
  options: MainOption[]
  labelKey: LabelKey
}): GeneralQueryType => {
  if (!value) {
    return ''
  }

  const isOptions = options.length > 0

  if (!isOptions) {
    return value
  }

  const allOptions = options.reduce((result, option) => {
    'options' in option && Array.isArray(option.options)
      ? result.push(...option.options)
      : result.push(option)
    return result
  }, [] as Array<MainOption | SubOption>)

  const option = allOptions.find((option) => {
    const isValue = typeof option === 'object' && option !== null && 'value' in option

    if (!isValue) {
      return false
    }

    return String(option?.value) === String(value)
  }) as InitialSubOptionType | InitialMainOptionType | null

  if (!option) {
    return value
  }

  return option?.[labelKey] ?? option?.label ?? ''
}

export const addNewOption = <MainOption extends InitialAutocompleteMainOptionType, SubOption>({
  options,
  query,
  parentOptions,
  extraOptions,
}: {
  options: Array<SelectedOption<MainOption, SubOption>>
  query: string
  parentOptions?: MainOptionType<MainOption>
  extraOptions?: HelpedUtilsExtraOptionsType
}): NewOptionType | null => {
  const conditions = parentOptions ?? extraOptions
  const allowNewParent = conditions?.allowNew
  const allowNewDuplicateParent = conditions?.allowNewDuplicate
  const forceNewParent = conditions?.forceNew
  const newLabelParent = conditions?.newLabel ?? ''
  const name = conditions?.name ?? ''
  const group = conditions?.group ?? ''

  if (!allowNewParent) {
    return null
  }

  if (!query && !forceNewParent) {
    return null
  }

  const isDuplicate = options.some((option) => {
    const isLabel = 'label' in option
    if (!isLabel) {
      return false
    }
    return option.label === query
  })

  if (isDuplicate && !allowNewDuplicateParent) {
    return null
  }

  return {
    label: newLabelParent.replace('%value%', String(query)),
    data: query,
    value: `create_new_${group || name}`,
    group,
    isNew: true,
  }
}

export const getOptions = <MainOption extends InitialAutocompleteMainOptionType, SubOption>({
  options,
  query,
  labelKey,
  optionFilter,
  extraOptions = {},
}: {
  options: MainOption[]
  query: string
  labelKey: LabelKey
  optionFilter: OptionFilterType
  extraOptions?: HelpedUtilsExtraOptionsType
}): Array<SelectedOption<MainOption, SubOption>> => {
  const result = options.reduce((res, option) => {
    const isGroup = 'options' in option && Array.isArray(option.options)
    const currentOption: MainOptionType<MainOption> = {
      ...option,
      label: option[labelKey] ?? '',
      isGroup,
    }

    if ('options' in currentOption && Array.isArray(currentOption.options)) {
      res.push(currentOption)

      const children = currentOption.options.reduce((result, option) => {
        const label = option?.[labelKey]

        if (!label) return result

        const isValidOption = optionFilter(label, query)
        if (isValidOption) {
          result.push({ ...option, label, isSubOption: true })
        }
        return result
      }, [] as Array<SubOptionType<SubOption>>)

      const addBtnOption = addNewOption<MainOption, SubOption>({
        options: children,
        query,
        parentOptions: currentOption,
      })
      addBtnOption && res.push(addBtnOption)
      res.push(...children)
    } else {
      const label = currentOption.label

      if (!label) {
        return res
      }

      if (optionFilter(label, query)) {
        res.push({ ...currentOption, isSubOption: true })
      }
    }
    return res
  }, [] as Array<SelectedOption<MainOption, SubOption>>)

  const addBtnOption = addNewOption<MainOption, SubOption>({ options: result, query, extraOptions })
  addBtnOption && result.push(addBtnOption)

  return result
}

import { l } from '@manychat/manyui'

import { SimpleTrialPlaceType } from 'apps/cms/lib/constants'
import { AppLanguage } from 'apps/locale/lib/constants'
import { billing, UpgradeModalVersion, UpgradeSource, UpgradeSourceType } from 'common/billing'
import { SimpleTrialProps } from 'common/billing/components/UpgradeView/variants/SimpleTrial/types'
import { BlockType } from 'common/builder/constants/BlockType'

import allPostsEbEn from './assets/all-posts-eb-en.png'
import allPostsEbEs from './assets/all-posts-eb-es.png'
import allPostsEbPt from './assets/all-posts-eb-pt.png'
import allPostsEn from './assets/all-posts-en.png'
import allPostsEs from './assets/all-posts-es.png'
import allPostsPt from './assets/all-posts-pt.png'
import collectEmailsEbEn from './assets/collect-emails-eb-en.png'
import collectEmailsEbEs from './assets/collect-emails-eb-es.png'
import collectEmailsEbPt from './assets/collect-emails-eb-pt.png'
import conditionEbEn from './assets/condition-eb-en.png'
import conditionEbEs from './assets/condition-eb-es.png'
import conditionEbPt from './assets/condition-eb-pt.png'
import conditionEn from './assets/condition-en.png'
import conditionEs from './assets/condition-es.png'
import conditionPt from './assets/condition-pt.png'
import followUpEbEn from './assets/follow-up-eb-en.png'
import followUpEbEs from './assets/follow-up-eb-es.png'
import followUpEbPt from './assets/follow-up-eb-pt.png'
import followUpEn from './assets/follow-up-en.png'
import followUpEs from './assets/follow-up-es.png'
import followUpPt from './assets/follow-up-pt.png'
import igLimitEn from './assets/ig-limit-en.png'
import igLimitEs from './assets/ig-limit-es.png'
import igLimitPt from './assets/ig-limit-pt.png'
import likeRepliesEn from './assets/like-replies-en.png'
import likeRepliesEs from './assets/like-replies-es.png'
import likeRepliesPt from './assets/like-replies-pt.png'
import nextPostEbEn from './assets/next-post-eb-en.png'
import nextPostEbEs from './assets/next-post-eb-es.png'
import nextPostEbPt from './assets/next-post-eb-pt.png'
import igStoryReplyEn from './assets/story-reply-en.png'
import igStoryReplyEs from './assets/story-reply-es.png'
import igStoryReplyPt from './assets/story-reply-pt.png'
import userInputEn from './assets/user-input-en.png'
import userInputEs from './assets/user-input-es.png'
import userInputPt from './assets/user-input-pt.png'
import { Handlers, HandlersParams } from './types'

interface RequestUpgradeConfig {
  source: UpgradeSourceType
  title: string
  text: string
  benefits?: SimpleTrialProps['benefits']
  proNodes?: HandlersParams['proNodes']
  hasImage?: HandlersParams['hasImage']
  images: {
    [AppLanguage.ES]: string
    [AppLanguage.PT]: string
    default: string
  }
  showSeeAll?: boolean
  wideImage?: boolean
}

function billingRequestUpgrade(config: RequestUpgradeConfig) {
  const language = l.getLanguage()
  const renderImg = config.images[language as keyof typeof config.images] || config.images.default

  const props = {
    title: config.title,
    text: config.text,
    ctaBtnLabel: l.translate('Try Pro free for 14 days'),
    image: config.hasImage === false ? undefined : { src: renderImg },
    showSeeAll: config.showSeeAll,
    wideImage: config.wideImage,
  }

  if (config.benefits) Object.assign(props, { benefits: config.benefits })

  billing.requestUpgrade({
    source: config.source,
    variant: UpgradeModalVersion.SIMPLE_TRIAL,
    proNodes: config.proNodes,
    customProps: {
      [UpgradeModalVersion.SIMPLE_TRIAL]: props,
    },
  })
}

const getAutoLikeAndDelay = (source?: UpgradeSource) =>
  billingRequestUpgrade({
    source: source ?? UpgradeSource.DISABLE_BRANDING,
    title: l.translate(
      '<b class="text-accent">Time your responses</b> to story replies with our Pro plan',
    ),
    text: l.translate(
      'Make conversations with your audience feel natural! Upgrade to Pro to schedule your automatic likes and answers to your story replies.',
    ),
    images: {
      [AppLanguage.ES]: igStoryReplyEs,
      [AppLanguage.PT]: igStoryReplyPt,
      default: igStoryReplyEn,
    },
  })

const getLimits = (source?: UpgradeSource) =>
  billingRequestUpgrade({
    source: source ?? UpgradeSource.DISABLE_BRANDING,
    title: l.translate(
      '<b class="text-accent">Run as many automations as you like</b> with our Pro plan',
    ),
    text: l.translate(
      'Your audience wants to feel important but its hard to reply to everyone. We get it. With our Pro plan, you can create all the automations you need across all channels and build your community easily!',
    ),
    images: {
      [AppLanguage.ES]: igLimitEs,
      [AppLanguage.PT]: igLimitPt,
      default: igLimitEn,
    },
  })

const USER_INPUT_SOURCE: UpgradeSourceType = `builder_blocks_${BlockType.FORM_QUESTION}`

export const TRIAL_INSTAGRAM_HANDLERS: Partial<Handlers> = {
  [SimpleTrialPlaceType.USER_INPUT]: ({ hasImage }) =>
    billingRequestUpgrade({
      source: USER_INPUT_SOURCE,
      title: l.translate(`<b class="text-accent">Collect emails</b> in DMs with our Pro plan`),
      text: l.translate(`
      When someone comments or replies to your story, Pro gives you the option to ask for their email address in your response.
      <br /><br /><br />
      Forget about landing pages, you’ll build an email list seamlessly just using DMs!`),
      images: {
        [AppLanguage.ES]: userInputEs,
        [AppLanguage.PT]: userInputPt,
        default: userInputEn,
      },
      benefits: [
        l.translate('Launch as many automations as you like'),
        l.translate('Ask users to follow you before sending links '),
        l.translate('Follow up if there’s no response'),
      ],
      hasImage,
    }),
  [SimpleTrialPlaceType.IG_CGT_ALL_POSTS]: ({ hasImage }) =>
    billingRequestUpgrade({
      source: UpgradeSource.IG_CGT_ALL_POSTS,
      title: l.translate(
        'Auto-reply to <b class="text-accent">all your posts and reels</b> with our Pro plan',
      ),
      text: l.translate(
        'When people comment with a specific word on any of your posts or reels, they’ll get a DM with Pro. You can also auto-reply to their comments publicly!',
      ),
      images: {
        [AppLanguage.ES]: allPostsEs,
        [AppLanguage.PT]: allPostsPt,
        default: allPostsEn,
      },
      hasImage,
    }),
  [SimpleTrialPlaceType.IG_CGT_NEXT_POST_OR_REEL]: ({ hasImage }) =>
    billingRequestUpgrade({
      source: UpgradeSource.NEXT_POST_OR_REEL,
      title: l.translate(
        '<b class="text-accent">Automate DMs and comment replies for future posts</b> with our Pro plan',
      ),
      text: l.translate(
        `Create auto replies for your next post or reel before you even share it on Insta!
        <br /><br /><br />
        Get that comment section on fire with Pro.`,
      ),
      images: {
        [AppLanguage.ES]: '',
        [AppLanguage.PT]: '',
        default: '',
      },
      benefits: [
        l.translate('Launch as many automations as you like'),
        l.translate('Ask users to follow you before sending links '),
        l.translate('Collect emails through customizable DMs'),
      ],
      hasImage,
    }),

  [SimpleTrialPlaceType.AUTO_LIKE_TRIGGER]: () =>
    getAutoLikeAndDelay(UpgradeSource.TRIGGER_STORY_REPLY_AUTO_LIKE),
  [SimpleTrialPlaceType.INSTAGRAM_DELAY]: () =>
    getAutoLikeAndDelay(UpgradeSource.TRIGGER_STORY_REPLY_DELAY_ANSWER),
  [SimpleTrialPlaceType.WIDGETS_LIMIT]: () => getLimits(UpgradeSource.WIDGETS_TRIGGER_LIMIT),
  [SimpleTrialPlaceType.KEYWORDS_TRIGGERS_LIMIT]: () => {
    getLimits(UpgradeSource.KEYWORD_LIMIT)
  },
  [SimpleTrialPlaceType.STORY_REPLY_TRIGGER_LIMIT]: () =>
    getLimits(UpgradeSource.STORY_REPLY_TRIGGERS_LIMIT),
  // nodes section
  [SimpleTrialPlaceType.CONDITION_IG_ACCOUNT_FOLLOWER]: ({ proNodes, hasImage }) =>
    billingRequestUpgrade({
      source: UpgradeSource.BUILDER_NODES,
      title: l.translate(
        '<b class="text-accent">Convert your audience into followers</b> with our Pro plan',
      ),
      text: l.translate(`
        Do people engage with your Instagram posts even if they don’t follow your account?
        <br /><br /><br />
        With Pro you can automate a follow request before you DM your links, discounts or prizes. You can even write the request in your own words!`),
      images: {
        [AppLanguage.ES]: conditionEs,
        [AppLanguage.PT]: conditionPt,
        default: conditionEn,
      },
      benefits: [
        l.translate('Launch as many automations as you like'),
        l.translate('Follow up if there’s no response'),
        l.translate('Collect emails through customizable DMs'),
      ],
      proNodes,
      hasImage,
    }),
  [SimpleTrialPlaceType.FOLLOW_UP]: ({ proNodes, hasImage }) =>
    billingRequestUpgrade({
      source: UpgradeSource.NODE_SMART_DELAY,
      title: l.translate(
        `Waiting for people to tap the link?
        <b class="text-accent">Send follow-up DMs</b> with our Pro plan`,
      ),
      text: l.translate(
        `If your audience doesn’t tap the link you send, slide back into their DMs to remind them with Pro.`,
      ),
      images: {
        [AppLanguage.ES]: followUpEs,
        [AppLanguage.PT]: followUpPt,
        default: followUpEn,
      },
      benefits: [
        l.translate('Launch as many automations as you like'),
        l.translate('Ask users to follow you before sending links'),
        l.translate('Collect emails across all channels through personalized DMs'),
      ],
      proNodes,
      hasImage,
    }),
} as const

const easyBuilderCommonProps = {
  hasImage: true,
  wideImage: true,
  showSeeAll: false,
} as const

const easyBuilderBenefits = {
  followUp: () =>
    ({
      Icon: 'Notification',
      title: l.translate('Send a follow up'),
      subtitle: l.translate('Re-engage leads who haven’t clicked your link yet'),
    } as const),
  growFollowers: () =>
    ({
      Icon: 'UserAdd',
      title: l.translate('Grow your followers'),
      subtitle: l.translate('Automatically detect if someone isn’t following you'),
    } as const),
  collectEmails: () =>
    ({
      Icon: 'SponsoredMessages',
      title: l.translate('Collect emails'),
      subtitle: l.translate('Stay connected beyond social media'),
    } as const),
  unlimitedAutomations: () =>
    ({
      Icon: 'AddCircle',
      title: l.translate('Launch unlimited automations to grow and monetize '),
      subtitle: l.translate('Scale your business with limitless automation'),
    } as const),
}

export const TRIAL_EASY_BUILDER_HANDLERS: Partial<Handlers> = {
  [SimpleTrialPlaceType.USER_INPUT]: () =>
    billingRequestUpgrade({
      source: UpgradeSource.EB_ASK_FOR_MAILS_IN_DM,
      title: l.translate(`Collect emails in DMs`),
      text: l.translate(`
      Collect email addresses directly through DMs.<br /><br />Pro automatically validates and saves emails for you, helping you build newsletters, waitlists, or generate leads with ease.`),
      images: {
        [AppLanguage.ES]: collectEmailsEbEs,
        [AppLanguage.PT]: collectEmailsEbPt,
        default: collectEmailsEbEn,
      },
      benefits: [
        easyBuilderBenefits.followUp(),
        easyBuilderBenefits.growFollowers(),
        easyBuilderBenefits.unlimitedAutomations(),
      ],
      ...easyBuilderCommonProps,
    }),
  [SimpleTrialPlaceType.IG_CGT_ALL_POSTS]: () =>
    billingRequestUpgrade({
      source: UpgradeSource.EB_IG_CGT_ALL_POSTS,
      title: l.translate('Auto-DM on any post or reel'),
      text: l.translate('When people comment on any of your posts or reels, they’ll get a DM.'),
      benefits: [
        easyBuilderBenefits.followUp(),
        easyBuilderBenefits.growFollowers(),
        easyBuilderBenefits.collectEmails(),
      ],
      images: {
        [AppLanguage.ES]: allPostsEbEs,
        [AppLanguage.PT]: allPostsEbPt,
        default: allPostsEbEn,
      },
      ...easyBuilderCommonProps,
    }),
  [SimpleTrialPlaceType.IG_CGT_NEXT_POST_OR_REEL]: () =>
    billingRequestUpgrade({
      source: UpgradeSource.EB_NEXT_POST_OR_REEL,
      title: l.translate('Auto-DM on next post or reel'),
      text: l.translate('When people comment on your next post or reel, they’ll get a DM.'),
      images: {
        [AppLanguage.ES]: nextPostEbEs,
        [AppLanguage.PT]: nextPostEbPt,
        default: nextPostEbEn,
      },
      benefits: [
        easyBuilderBenefits.followUp(),
        easyBuilderBenefits.growFollowers(),
        easyBuilderBenefits.collectEmails(),
      ],
      ...easyBuilderCommonProps,
    }),
  [SimpleTrialPlaceType.CONDITION_IG_ACCOUNT_FOLLOWER]: ({ proNodes }) =>
    billingRequestUpgrade({
      source: UpgradeSource.EB_ASK_FOR_FOLLOW,
      title: l.translate('Grow your followers'),
      text: l.translate(`
        Automatically detect if someone isn’t following you and send a personalized request to follow. <br /><br />Once they follow, instantly share exclusive content or offers reserved for your followers!`),
      images: {
        [AppLanguage.ES]: conditionEbEs,
        [AppLanguage.PT]: conditionEbPt,
        default: conditionEbEn,
      },
      benefits: [
        easyBuilderBenefits.followUp(),
        easyBuilderBenefits.collectEmails(),
        easyBuilderBenefits.unlimitedAutomations(),
      ],
      proNodes,
      ...easyBuilderCommonProps,
    }),
  [SimpleTrialPlaceType.FOLLOW_UP]: ({ proNodes }) =>
    billingRequestUpgrade({
      source: UpgradeSource.EB_FOLLOW_UP,
      title: l.translate('Send a follow up'),
      text: l.translate(
        `Re-engage your audience with automated follow-up messages.<br /><br />If someone doesn’t tap your link, Pro sends a gentle reminder to keep them interested and boost clicks.`,
      ),
      images: {
        [AppLanguage.ES]: followUpEbEs,
        [AppLanguage.PT]: followUpEbPt,
        default: followUpEbEn,
      },
      benefits: [
        easyBuilderBenefits.followUp(),
        easyBuilderBenefits.collectEmails(),
        easyBuilderBenefits.unlimitedAutomations(),
      ],
      proNodes,
      ...easyBuilderCommonProps,
    }),
  [SimpleTrialPlaceType.AUTOMATICALLY_LIKE_REPLIES]: ({ proNodes }) =>
    billingRequestUpgrade({
      source: UpgradeSource.EB_AUTOMATICALLY_LIKE_REPLIES,
      title: l.translate('Automatically like story replies'),
      text: l.translate(
        `Save time and stay engaged by automatically liking replies to your stories. A simple way to make your audience feel noticed and valued.`,
      ),
      images: {
        [AppLanguage.ES]: likeRepliesEs,
        [AppLanguage.PT]: likeRepliesPt,
        default: likeRepliesEn,
      },
      benefits: [
        easyBuilderBenefits.followUp(),
        easyBuilderBenefits.growFollowers(),
        easyBuilderBenefits.collectEmails(),
      ],
      proNodes,
      ...easyBuilderCommonProps,
    }),
} as const

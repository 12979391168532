import { IContentNode, INode } from 'common/builder/nodes/nodeInterfaces'
import { getNodeById } from 'common/builder/selectors/builder/entitySelectors'

export const getAllChainingContents = (
  state: RootState,
  builderId: string,
  contentId: string | null,
  contents: INode[] = [],
): INode[] => {
  if (!contentId) {
    return contents
  }

  const contentItem = getNodeById(state, builderId, contentId)

  if (!contentItem) {
    return contents
  }

  const isCycled = contents.map((content) => content.id).includes(contentId)

  if (isCycled) {
    return contents
  }

  contents.push(contentItem)

  return getAllChainingContents(state, builderId, (contentItem as IContentNode).targetId, contents)
}

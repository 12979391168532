export const TEST_EXTERNAL_REQUEST = 'TEST_EXTERNAL_REQUEST'
export const TEST_EXTERNAL_REQUEST_REQUEST = 'TEST_EXTERNAL_REQUEST_REQUEST'
export const TEST_EXTERNAL_REQUEST_RESPONSE = 'TEST_EXTERNAL_REQUEST_RESPONSE'
export const TEST_EXTERNAL_REQUEST_ERROR = 'TEST_EXTERNAL_REQUEST_ERROR'

export const GET_USER_VARIABLES = 'GET_USER_VARIABLES'
export const GET_USER_VARIABLES_REQUEST = 'GET_USER_VARIABLES_REQUEST'
export const GET_USER_VARIABLES_RESPONSE = 'GET_USER_VARIABLES_RESPONSE'
export const GET_USER_VARIABLES_ERROR = 'GET_USER_VARIABLES_ERROR'
export const GET_USER_VARIABLES_RESET = 'GET_USER_VARIABLES_RESET'

export const EXTERNAL_REQUEST_UPDATE_URL = 'EXTERNAL_REQUEST_UPDATE_URL'
export const EXTERNAL_REQUEST_UPDATE_METHOD = 'EXTERNAL_REQUEST_UPDATE_METHOD'
export const EXTERNAL_REQUEST_UPDATE_PAYLOAD = 'EXTERNAL_REQUEST_UPDATE_PAYLOAD'
export const EXTERNAL_REQUEST_UPDATE_HEADERS = 'EXTERNAL_REQUEST_UPDATE_HEADERS'
export const EXTERNAL_REQUEST_UPDATE_MAPPING = 'EXTERNAL_REQUEST_UPDATE_MAPPING'

export const EXTERNAL_REQUEST_RESET = 'EXTERNAL_REQUEST_RESET'

import React from 'react'
import cx from 'classnames'
import { Skeleton } from '@manychat/manyui'

import cm from './FlowFiltersSkeleton.module.css'

export const FlowFiltersSkeleton = React.memo(() => (
  <>
    <Skeleton.Text className={cx(cm.skeleton, 'd-flex align-center p-x-xs panel panel-white')} />

    <Skeleton.Text
      className={cx(cm.skeleton, 'd-flex m-t-0 align-center p-x-xs panel panel-white')}
    />

    <Skeleton.Text
      className={cx(cm.skeleton, 'd-flex m-t-0 align-center p-x-xs panel panel-white')}
    />
  </>
))

import React from 'react'
import cx from 'classnames'
import { l, Icon } from '@manychat/manyui'

import { ITemplateAccessData } from 'apps/templates/constants/templatesInterfaces'
import { DefaultTemplateAccessData } from 'apps/templates/models/Template/PropTypes'
import { convertFlagsToNumber, StatusesBits } from 'apps/templates/models/Template/TemplateEntity'

const { OWNER, DISABLED, LOCKED, PROTECTED } = StatusesBits

const TextBitMap = {
  [PROTECTED | DISABLED]: l.makeTranslate(
    'This element is disabled. Please reach out to the Template owner to turn it on',
  ),
  [PROTECTED | DISABLED | OWNER]: l.makeTranslate(
    'This element is disabled. Its contents can be managed only by the Template owner (you)',
  ),
  [PROTECTED]: l.makeTranslate(
    'This item is a part of the protected Template which means it cannot be shared, copied to another page, or included into a new template',
  ),
  [PROTECTED | LOCKED]: l.makeTranslate(
    'This item is a part of a locked Template which means it is locked from viewing and editing by the Template owner',
  ),
  [PROTECTED | OWNER]: l.makeTranslate(
    'This item is a part of the protected Template which means it can be shared, copied to another page, or included into a new template only by the Template owner (you)',
  ),
  [PROTECTED | OWNER | LOCKED]: l.makeTranslate(
    'This item is a part of a locked Template which means its contents can be managed only by the Template owner (you)',
  ),
}

interface Props {
  accessData?: ITemplateAccessData
  className?: string
  showTitle?: boolean
}

const ProtectionStatus = ({
  accessData = DefaultTemplateAccessData,
  className = '',
  showTitle = true,
}: Props) => {
  if (!accessData || !accessData.is_protected) return <div style={{ width: '21px' }} />

  const accessDataAsANumber = convertFlagsToNumber(accessData)
  const IconComponent = accessData.is_locked ? Icon.Protect : Icon.Unprotect

  return (
    <IconComponent
      className={cx(className, 'flex-shrink-0', {
        'text-danger': accessData.is_disabled,
        'text-success': accessData.is_owner,
      })}
      size={24}
      data-title={showTitle ? l.getString(TextBitMap[accessDataAsANumber]) : undefined}
    />
  )
}

export default ProtectionStatus

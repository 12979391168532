export const CUSTOM_EVENTS_CREATE_REQUEST = 'CUSTOM_EVENTS_CREATE_REQUEST'
export const CUSTOM_EVENTS_CREATE_RESPONSE = 'CUSTOM_EVENTS_CREATE_RESPONSE'
export const CUSTOM_EVENTS_CREATE_ERROR = 'CUSTOM_EVENTS_CREATE_ERROR'

export const EVENTS_OPEN_CREATE_MODAL = 'EVENTS_OPEN_CREATE_MODAL'
export const EVENTS_CLOSE_CREATE_MODAL = 'EVENTS_CLOSE_CREATE_MODAL'
export const EVENTS_CREATE_MODAL_CHANGE = 'EVENTS_CREATE_MODAL_CHANGE'
export const EVENTS_CREATE_REQUEST = 'EVENTS_CREATE_REQUEST'
export const EVENTS_CREATE_RESPONSE = 'EVENTS_CREATE_RESPONSE'
export const EVENTS_CREATE_ERROR = 'EVENTS_CREATE_ERROR'

export const FETCH_EVENTS = 'FETCH_EVENTS'
export const UPDATE_EVENT = 'UPDATE_EVENT'
export const FETCH_EVENTS_RESPONSE = 'FETCH_EVENTS_RESPONSE'
export const ARCHIVE_EVENT = 'ARCHIVE_EVENT'
export const UPDATE_EVENTS_LIST = 'UPDATE_EVENTS_LIST'
export const EVENTS_GET_ENTITIES_LIST = 'EVENTS_GET_ENTITIES_LIST'
export const EVENTS_GET_AVAILABLE_CURRENCIES = 'EVENTS_GET_AVAILABLE_CURRENCIES'
export const EVENTS_GET_AVAILABLE_CURRENCIES_RESPONSE = 'EVENTS_GET_AVAILABLE_CURRENCIES_RESPONSE'

import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { createAppAsyncThunk } from 'reduxTyped'

import { WhatsAppBroadcastingState } from 'apps/whatsApp/interfaces/whatsAppBroadCast'
import { IFilter } from 'common/filter/interfaces'
import { handleCatch } from 'shared/api/lib/errors/handlers'
import { WhatsAppApi } from 'shared/api/requests/whatsApp'
import {
  WaMessageTemplateCategoryId,
  WhatsAppBroadcastingBicLimits,
  WhatsAppBroadcastingCostEstimation,
} from 'shared/api/requests/whatsApp/schemas'

export const getBicLimits = createAppAsyncThunk<WhatsAppBroadcastingBicLimits | null>(
  'whatsapp/bicLimits',
  async () => {
    try {
      const result = await WhatsAppApi.getBicLimits()
      return result.data
    } catch (error) {
      handleCatch(error)
      return null
    }
  },
)

export const estimateBroadcastCost = createAppAsyncThunk<
  WhatsAppBroadcastingCostEstimation | null,
  { category: WaMessageTemplateCategoryId; filter: IFilter }
>('whatsapp/estimateBroadcastCost', async ({ category, filter }) => {
  try {
    const result = await WhatsAppApi.estimateBroadcastCost({ body: { category, filter } })
    return result.data
  } catch (error) {
    handleCatch(error)
    return null
  }
})

const initialState: WhatsAppBroadcastingState = {
  bicLimits: {
    data: null,
    loading: false,
  },
  costEstimation: {
    data: null,
    loading: false,
    isComplexBroadcast: false,
  },
}

export const broadcastingSlice = createSlice({
  name: 'broadcasting',
  initialState: initialState,
  reducers: {
    setIsCostEstimationPending: (state, action: PayloadAction<boolean>) => {
      state.costEstimation.loading = action.payload
    },
    resetCostEstimation: (state) => {
      state.costEstimation.data = null
    },
    setIsComplexBroadcast: (state) => {
      state.costEstimation.isComplexBroadcast = true
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getBicLimits.pending, (state) => {
      state.bicLimits.loading = true
    })
    builder.addCase(getBicLimits.fulfilled, (state, action) => {
      state.bicLimits.loading = false
      state.bicLimits.data = action.payload
    }),
      builder.addCase(estimateBroadcastCost.pending, (state) => {
        state.costEstimation.loading = true
        state.costEstimation.isComplexBroadcast = false
      })
    builder.addCase(estimateBroadcastCost.fulfilled, (state, action) => {
      state.costEstimation.loading = false
      state.costEstimation.isComplexBroadcast = false
      state.costEstimation.data = action.payload
    })
  },
})

export const { setIsCostEstimationPending, resetCostEstimation, setIsComplexBroadcast } =
  broadcastingSlice.actions

export const getWaBroadcastingBicLimitsDataSelector = (state: RootState) =>
  state.whatsApp.broadcasting.bicLimits.data
export const getIsWaBroadcastingBicLimitsLoadingSelector = (state: RootState) =>
  state.whatsApp.broadcasting.bicLimits.loading
export const getWaBroadcastingCostEstimationSelector = (state: RootState) =>
  state.whatsApp.broadcasting.costEstimation.data
export const getWaBroadcastingCostEstimationIsComplex = (state: RootState) =>
  state.whatsApp.broadcasting.costEstimation.isComplexBroadcast
export const getIsWaBroadcastingCostEstimationLoadingSelector = (state: RootState) =>
  state.whatsApp.broadcasting.costEstimation.loading

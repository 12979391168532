import { isAddOnSlug, isProProduct } from 'common/billing/helpers/typeguards'
import { isUpgradeSource } from 'common/billing/interfaces'
import { RoutePaths } from 'utils/router/constants'
import { isPathnameMatchRoute } from 'utils/router/tools'
import { getURLParam } from 'utils/url'

export const getPageInfo = () => {
  const returnParam = getURLParam('return')
  const returnSuccessParam = getURLParam('returnSuccess')
  const sourceParam = getURLParam('source') ?? ''
  const productQueryParam = getURLParam('products') ?? ''
  const addOnsQueryParam = getURLParam('addOns') ?? ''
  const productsParam = productQueryParam.split(',').filter(isProProduct)
  const addOnsParam = addOnsQueryParam.split(',').filter(isAddOnSlug)
  const titleParam = getURLParam('title')

  return {
    isOnUpgradePage: isPathnameMatchRoute(RoutePaths.UPGRADE_TO_PRO),
    returnParam,
    returnSuccessParam,
    sourceParam: isUpgradeSource(sourceParam) ? sourceParam : null,
    productsParam: productsParam.length ? productsParam : null,
    addOnsParam: addOnsParam.length ? addOnsParam : null,
    titleParam: titleParam ? decodeURIComponent(titleParam) : null,
  }
}

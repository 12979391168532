import dot from 'dot-prop-immutable'
import get from 'lodash/get'

import { POST } from 'common/actions/constants/ExternalRequestModalTabTypes'

import * as atypes from '../constants/devtoolsReduxActionTypes'

const initial = {
  fetching: false,
  error: null,
  data: null,
}

const fetching = {
  fetching: true,
  error: null,
  data: null,
}

const successResponse = ({ data }) => ({
  fetching: false,
  error: null,
  data,
})

const initialState = {
  externalRequest: {
    method: POST,
    url: '',
    preview: {
      ...initial,
    },
    ...initial,
    payload: null,
    optIn: {
      required: null,
      payload: null,
    },
    userVariables: {
      ...initial,
    },
    mapping: null,
  },
}

export default function devToolsReducer(state = initialState, action) {
  switch (action.type) {
    case atypes.TEST_EXTERNAL_REQUEST_REQUEST: {
      return dot.merge(state, 'externalRequest', {
        ...fetching,
      })
    }

    case atypes.TEST_EXTERNAL_REQUEST_RESPONSE: {
      state = dot.set(state, 'externalRequest.optIn', initialState.externalRequest.optIn)
      return dot.merge(state, 'externalRequest', successResponse(action))
    }
    case atypes.TEST_EXTERNAL_REQUEST_ERROR: {
      state = dot.set(state, 'externalRequest.fetching', false)
      if (get(action, 'error.errors.optin_required')) {
        return dot.set(state, 'externalRequest.optIn', {
          required: true,
          payload: action.error.errors.payload,
        })
      }
      return dot.merge(state, 'externalRequest', {
        error: action.error.error,
        optIn: initialState.externalRequest.optIn,
      })
    }

    case atypes.EXTERNAL_REQUEST_UPDATE_URL: {
      return dot.set(state, 'externalRequest.url', action.data)
    }

    case atypes.EXTERNAL_REQUEST_UPDATE_METHOD: {
      return dot.set(state, 'externalRequest.method', action.data)
    }

    case atypes.EXTERNAL_REQUEST_UPDATE_PAYLOAD: {
      return dot.set(state, 'externalRequest.payload', action.data)
    }

    case atypes.EXTERNAL_REQUEST_UPDATE_HEADERS: {
      return dot.set(state, 'externalRequest.headers', action.data)
    }

    case atypes.EXTERNAL_REQUEST_UPDATE_MAPPING: {
      return dot.set(state, 'externalRequest.mapping', action.data)
    }

    case atypes.EXTERNAL_REQUEST_RESET: {
      return dot.set(state, 'externalRequest', {
        ...initialState.externalRequest,
        userVariables: state.externalRequest.userVariables,
      })
    }

    case atypes.GET_USER_VARIABLES_REQUEST: {
      return dot.merge(state, 'externalRequest.userVariables', {
        ...fetching,
      })
    }

    case atypes.GET_USER_VARIABLES_RESPONSE: {
      state = dot.set(state, 'externalRequest.optIn', initialState.externalRequest.optIn)
      return dot.merge(state, 'externalRequest.userVariables', successResponse(action))
    }

    case atypes.GET_USER_VARIABLES_RESET: {
      state = dot.set(state, 'externalRequest.optIn', { ...initialState.externalRequest.optIn })
      return dot.merge(state, 'externalRequest.userVariables', { ...initial })
    }

    case atypes.GET_USER_VARIABLES_ERROR: {
      state = dot.set(state, 'externalRequest.userVariables.fetching', false)
      if (get(action, 'error.errors.optin_required')) {
        return dot.set(state, 'externalRequest.optIn', {
          required: true,
          payload: action.error.errors.payload,
        })
      }
      state = dot.merge(state, 'externalRequest', { optIn: initialState.externalRequest.optIn })
      return dot.merge(state, 'externalRequest.userVariables', { error: action.error.errors })
    }
  }

  return state
}

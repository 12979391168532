import { useAppDispatch } from 'reduxTyped'

import { useMoveActions } from 'apps/cms/hooks/useMoveActions'
import { CmsAnalytics } from 'apps/cms/lib/analytics'
import { contentManagementActions } from 'apps/cms/store'
import { getNewCMSSelfServeStoryReactionOnboarding } from 'utils/services/newOnboardingService'
import { linkURL } from 'utils/url'

export const useFolder = ({ path, id }: { path: string; id: number }) => {
  const dispatch = useAppDispatch()

  const { moveFolderToTargetFolder, moveFlowsToTargetFolder } = useMoveActions({
    targetFolderPath: path,
  })

  const openFolder = (
    event: React.KeyboardEvent<HTMLButtonElement> | React.MouseEvent<HTMLButtonElement>,
  ) => {
    getNewCMSSelfServeStoryReactionOnboarding()?.steps.STEP_3?.process()

    CmsAnalytics.logOpenFolder({ id })
    if (event.ctrlKey || event.metaKey) {
      window.open(linkURL(`/cms?path=${path}`))
    } else {
      dispatch(
        contentManagementActions.updateQuery({
          path,
        }),
      )
    }
  }

  return {
    moveFolderToTargetFolder,
    moveFlowsToTargetFolder,
    openFolder,
  }
}

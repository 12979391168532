import { combineReducers } from 'redux'

import draft from './draft'
import currentInstalled from './installedTemplate'
import current from './template'
import installation from './templateInstallation'
import { installationsListReducer } from './templateInstallationsList'
import { templatesReducer, installedTemplatesReducer } from './templates'

export default combineReducers({
  draft,
  list: templatesReducer,
  installed: installedTemplatesReducer,
  currentInstalled,
  current,
  installation,
  templateInstallations: installationsListReducer,
})

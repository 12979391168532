import React, { ReactNode } from 'react'
import cx from 'classnames'

import { useThemeContext } from '../../useThemeContext'

import cm from './MainMenuButton.module.css'

interface ChatButtonProps {
  children: ReactNode
}

export function MainMenuButton(props: ChatButtonProps) {
  const theme = useThemeContext()

  return (
    <div className={cx(cm.button, cm[theme])} data-test-id="main-menu-button">
      {props.children}
    </div>
  )
}

MainMenuButton.displayName = 'MainMenu.Button'

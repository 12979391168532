import React from 'react'
import { Navigate, useRouteError } from 'react-router-dom'

import { getMetricPathname, sendHealthMetric } from 'utils/healthMetrics'
import { getComponentByRoute } from 'utils/router/tools'
import { useRedirects } from 'utils/router/utils/hooks'
import errorTrackingService from 'utils/services/errorTrackingService'

export const RouterErrorBoundary = () => {
  const error = useRouteError()
  const redirectUrl = useRedirects()
  const pathname = getMetricPathname()

  if (redirectUrl) {
    return <Navigate to={redirectUrl} />
  }

  if (error) {
    errorTrackingService.trackError(error, { tags: { ErrorBoundary: true } })
    sendHealthMetric('error_boundary_event', {
      url: pathname,
      scope: 'router',
      component: getComponentByRoute(),
    })
  }

  return null
}

export const VAR_REGEX =
  /{{\s*(?!coupon[.][\w_]+[.]campaign_)([\w_.]*)\s*(\|\s*(((fallback)\s*:\s*"([^"]*)")\s*,?\s*)*)?(\|\s*to_json\s*:\s*true)*\s*(\|format:((number|date|datetime|time)\([^\)]*\)))?}}/g
export const EMPTY_VALUE_IN_BRACKETS_REGEX = /\(\s*\)|(?:\/round)\(\s*\)/g
export const ALL_VALUES_REGEX =
  /({{\s*(?!coupon[.][\w_]+[.]campaign_)([\w_.]*)\s*(\|\s*(((fallback)\s*:\s*"([^"]*)")\s*,?\s*)*)?(\|\s*to_json\s*:\s*true)*\s*(\|format:((number|date|datetime|time)\([^\)]*\)))?}})|(\d+(\.\d+)?)/g
export const CORE_MATH_SYMBOLS_REGEX = /[\+\-\/\*]/g
export const FUNCTION_REGEX = /round/g
export const SPLIT_REGEX = new RegExp('({{[^\\n{}]{1,}}}|<&[^\\n<&]+&>)', 'g')
export const MENTION_REGEX = /(@)(\d+)\(([^)]+)\)/g
export const FORBIDDEN_SYMBOLS_REGEX = /[^\d\.\+\-\/\*\)\(\s]/g
export const BODY_LINK_SHORTENER_REGEX = /<<url:[0-9a-fA-F\-]+\|[^>]+>>/g
export const URL_REGEX =
  /https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,}/
export const URL_REGEXP_ORIGIN =
  /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,20}(:[0-9]{1,5})?(\/.*)?$/
export const URL_RULE_REGEX = /(www|https?:+[^\s]+[\w])/
export const ID_REGEX = /[^\/]*\d+[^\/]*/g
export const ACCOUNT_ID_REGEX = /\/fb\d+\//g
export const URL_RULE_REGEX_WITHOUT_CAPTURING = /(?:www|https?:+[^\s]+[\w/])/
export const INLINE_BUTTON_REGEX = /<<inline-button-anchor:([0-9a-zA-Z\-]+[^>]*)>>/g
export const EMAIL_REGEXP = /^([a-zA-Z0-9_\-\.]+)@([a-zA-Z0-9_\-\.]+)\.([a-zA-Z]{2,20})$/
export const EMAIL_REGEXP_IN_TEXT = /([a-zA-Z0-9_\-\.]+)@([a-zA-Z0-9_\-\.]+)\.([a-zA-Z]{2,20})/

// If you want to change it, don't forget sync it with VAR_REGEX!
export const COUPON_REGX = /{{\s*coupon.([\w-_]+).campaign_([\w-_]*)\s*}}/g

import React, {
  PropsWithChildren,
  useRef,
  useEffect,
  RefObject,
  ComponentPropsWithoutRef,
} from 'react'

import { useRefCallback } from '../commonHooks'

import { HotkeyHandlerDeclaration } from './types'

type HotkeysProps = PropsWithChildren<{
  handlers: HotkeyHandlerDeclaration[]
  enabled?: boolean
  /**
   * Props to be passed to the wrapping element
   * You might pass a ref object for the element if you want
   */
  elementProps?: ComponentPropsWithoutRef<'div'> & {
    ref?: RefObject<HTMLDivElement>
    [key: `data-${string}`]: unknown
  }
  /**
   * Element to attach the event listeners to
   * @example
   * document
   */
  attach?: HTMLElement | Document
}>

/**
 * @example
 * // Simple matcher
 * <Hotkeys handlers={[{ matcher: 'Escape', handler }]}>{content}</Hotkeys>
 * @example
 * // Matcher with function
 * <Hotkeys handlers={[{ matcher: matchers.flowResetState, handler }]}>{content}</Hotkeys>
 * @example
 * // Attach listeners to the document
 * <Hotkeys handlers={handlers} attach={document}>{content}</Hotkeys>
 * @example
 * // Send props to the wrapping element
 * <Hotkeys handlers={handlers} elementProps={{ className: 'p-x-md' }}>{content}</Hotkeys>
 */
export const Hotkeys = (props: HotkeysProps) => {
  const { children, handlers, enabled = true, elementProps, attach } = props
  const fallbackRef = useRef<HTMLDivElement>(null)
  const elementRef = elementProps?.ref ?? fallbackRef

  const handleKey = useRefCallback((event: KeyboardEvent) => {
    if (!enabled) {
      return
    }

    for (const declaration of handlers) {
      const { eventTypes = ['keydown'] as string[], matcher, handler } = declaration

      if (!eventTypes.includes(event.type)) {
        continue
      }

      if (typeof matcher !== 'string' && matcher(event)) {
        handler(event)
        return
      }

      if (matcher === event.key) {
        handler(event)
        return
      }
    }
  })

  useEffect(() => {
    const root = attach ?? elementRef.current
    const keyHandler = handleKey as EventListener

    root?.addEventListener('keydown', keyHandler)
    root?.addEventListener('keyup', keyHandler)

    return () => {
      root?.removeEventListener('keydown', keyHandler)
      root?.removeEventListener('keyup', keyHandler)
    }
  }, [handleKey, elementRef, attach])

  return (
    <div tabIndex={-1} {...elementProps} ref={elementRef}>
      {children}
    </div>
  )
}

import { l } from '@manychat/manyui'

import { SimpleTrialPlaceType } from 'apps/cms/lib/constants'
import { KEYWORD_CREATION_LIMIT } from 'apps/keywords/keywordsSlice'
import { billing, UpgradeSource, UpgradeSourceType } from 'common/billing'
import { BlockType } from 'common/builder/constants/BlockType'

import { Handlers } from './types'

const USER_INPUT_SOURCE: UpgradeSourceType = `builder_blocks_${BlockType.FORM_QUESTION}`

const callProAlertForLimit = () =>
  billing.proAlert({
    message: l.translate(
      'You can not activate the widget on the Free plan. Upgrade to Pro for unlimited access.',
    ),
    source: UpgradeSource.DISABLE_BRANDING,
  })

export const DEFAULT_HANDLERS: Handlers = {
  [SimpleTrialPlaceType.USER_INPUT]: () =>
    billing.proAlert({
      source: USER_INPUT_SOURCE,
      message: l.translate('Upgrade your plan to publish Automation with User Input block'),
      title: l.translate('Unlock user input block with Manychat Pro'),
    }),
  [SimpleTrialPlaceType.IG_CGT_ALL_POSTS]: () =>
    billing.proAlert({
      source: UpgradeSource.ALL_POSTS,
      message: l.translate(
        "You aren't able to use All Posts option on the current plan. Upgrade for unlimited access.",
      ),
    }),
  [SimpleTrialPlaceType.IG_CGT_NEXT_POST_OR_REEL]: () =>
    billing.proAlert({
      source: UpgradeSource.NEXT_POST_OR_REEL,
      message: l.translate(
        "You aren't able to use Next Post or Reel option on the current plan. Upgrade for unlimited access.",
      ),
    }),
  [SimpleTrialPlaceType.AUTOMATICALLY_LIKE_REPLIES]: () =>
    billing.proAlert({
      source: UpgradeSource.EB_AUTOMATICALLY_LIKE_REPLIES,
      message: l.translate(
        "You aren't able to use Automatically Like Replies option on the current plan. Upgrade for unlimited access.",
      ),
    }),
  [SimpleTrialPlaceType.AUTO_LIKE_TRIGGER]: () =>
    billing.proAlert({
      message: l.translate(
        `You aren't able to activate this feature on the current plan. Upgrade for unlimited access.`,
      ),
      title: l.translate('Unlock the ability to Auto Like replies and reactions with Manychat Pro'),
      source: UpgradeSource.DISABLE_BRANDING,
    }),
  [SimpleTrialPlaceType.INSTAGRAM_DELAY]: () =>
    billing.proAlert({
      message: l.translate(
        `You aren't able to activate this feature on the current plan. Upgrade for unlimited access.`,
      ),
      title: l.translate('Unlock the ability to delay automation with Manychat Pro'),
      source: UpgradeSource.DISABLE_BRANDING,
    }),
  [SimpleTrialPlaceType.WIDGETS_LIMIT]: callProAlertForLimit,
  [SimpleTrialPlaceType.KEYWORDS_TRIGGERS_LIMIT]: () =>
    billing.proAlert({
      message: l.translate(
        `You've reached your limit of active custom Keywords ({KEYWORD_CREATION_LIMIT}) on the current plan. Upgrade your plan to get unlimited active custom Keywords.`,
        {
          KEYWORD_CREATION_LIMIT,
        },
      ),
      source: UpgradeSource.KEYWORD_LIMIT,
    }),
  [SimpleTrialPlaceType.STORY_REPLY_TRIGGER_LIMIT]: callProAlertForLimit,
  // nodes section
  [SimpleTrialPlaceType.CONDITION_IG_ACCOUNT_FOLLOWER]: ({ proNodes }) =>
    billing.proAlert({
      source: UpgradeSource.BUILDER_NODES,
      message: l.translate(
        "You aren't able to activate this feature on the current plan. Upgrade for unlimited access.",
      ),
      title: l.translate('Unlock condition block with Manychat Pro'),
      proNodes,
    }),
  [SimpleTrialPlaceType.FOLLOW_UP]: ({ proNodes }) =>
    billing.proAlert({
      source: UpgradeSource.BUILDER_NODES,
      message: l.translate(
        "You aren't able to activate this feature on the current plan. Upgrade for unlimited access.",
      ),
      title: l.translate('Unlock smart delay block with Manychat Pro'),
      proNodes,
    }),
}

import { useEffect, useRef } from 'react'
import { useAppSelector } from 'reduxTyped'

import { FieldGroupId } from 'common/fields/entities/enums'
import { Field } from 'common/fields/entities/field'
import { GlobalFieldCreationSource } from 'common/fields/store/constants'
import { GlobalFieldsSelectors } from 'common/fields/store/globalFields'
import { GlobalFieldSource } from 'common/fields/store/types'
import { useIsChanged, useId } from 'utils/commonHooks'

export const useCreatedGlobalFieldLinking = ({
  onFieldCreate,
}: {
  onFieldCreate: (id: string, createdField?: Field) => void
}) => {
  const id = useId()
  const globalFieldModalSource: GlobalFieldSource = `${GlobalFieldCreationSource.FIELD_PICKER}_${id}`

  const { lastCreatedField, source } = useAppSelector(GlobalFieldsSelectors.getCreateFieldModal)

  const lastSelectedGlobalField = useRef<number | null>(null)

  const lastCreatedFieldChanged = useIsChanged(lastCreatedField)

  useEffect(() => {
    if (
      source &&
      source === globalFieldModalSource &&
      lastCreatedFieldChanged &&
      lastCreatedField &&
      lastSelectedGlobalField.current !== lastCreatedField.field_id
    ) {
      lastSelectedGlobalField.current = lastCreatedField.field_id

      // @ts-expect-error we need to migrate user fields
      onFieldCreate(String(lastCreatedField.field_id), {
        ...lastCreatedField,
        id: `${FieldGroupId.BOT}_${lastCreatedField.field_id}`,
        group: FieldGroupId.BOT,
      })
    }
  }, [globalFieldModalSource, onFieldCreate, lastCreatedField, lastCreatedFieldChanged, source])

  return {
    globalFieldModalSource,
  }
}

import React from 'react'

import { getProfileImageStubURL } from 'utils/assets'

interface AvatarProps {
  url?: string
}

const SIZE = 48

export function PreviewBlocksAvatar(props: AvatarProps) {
  const defaultUrl = getProfileImageStubURL()
  const [url, setUrl] = React.useState(props.url || defaultUrl)

  const handleError = () => {
    if (url !== defaultUrl) setUrl(defaultUrl)
  }

  return (
    <img onError={handleError} src={url} className="rounded-circle" width={SIZE} height={SIZE} />
  )
}

import { ProProduct, ProductType } from 'common/core/interfaces/products'

export const PRICING_LINK = 'https://manychat.com/pricing'

export const CARDS_BRANDS: StringMapType = { Visa: 'Visa', MasterCard: 'MasterCard' }
export const TRIAL_PERIOD_IN_DAYS = 14

// These strings do not require translations
export const PRODUCT_NAME_MAP: Record<ProProduct, string> = {
  [ProductType.INBOX]: 'Inbox',
  [ProductType.AUTOMATION]: 'Marketing Automation',
}

export const PRO_MODAL_TYPE = 'PRO'
export const REGISTRATION_STEP_TYPE = 'REGISTRATION_STEP'

export const PRO_MODAL_PRODUCTS: ProProduct[] = [ProductType.INBOX, ProductType.AUTOMATION]

export enum UpgradeModalVersion {
  UPGRADE = 'UPGRADE',
  UPGRADE_AI = 'UPGRADE_AI',
  UPGRADE_PAGE = 'UPGRADE_PAGE',
  SIMPLE_TRIAL = 'SIMPLE_TRIAL',
  PAYWALL = 'PAYWALL',
  UPGRADE_AI_FROM_TRIAL = 'UPGRADE_AI_FROM_TRIAL',
}

export const HOW_IS_PRICING_CALCULATED_LINK =
  'https://help.manychat.com/hc/en-us/articles/14281408754716-How-is-the-price-calculated-Understanding-your-invoice'

export const FSQueryRootPath = '/'

export enum FSObjectType {
  FOLDER = 'folder',
  FLOW = 'flow',
  CONTENT = 'content',
  SMART_FOLDER = 'smartfolder',
}

export enum FSSmartFolders {
  WELCOME_MESSAGE = '/smart_welcome',
  DEFAULT_REPLY = '/smart_default',
  MAIN_MENU = '/smart_mainmenu',
  KEYWORDS = '/smart_keywords',
  SEQUENCE = '/smart_sequences',
  OPTIN = '/smart_optins',
  RULES = '/smart_trigger_rules',
  TRASH = '/smart_trash',
  SMS = '/smart_sms_channel',
  EMAIL = '/smart_email_channel',
  MESSENGER = '/smart_messenger_channel',
  WHATS_APP = '/smart_whatsapp_channel',
  INSTAGRAM = '/smart_instagram_channel',
  TELEGRAM = '/smart_telegram_channel',
  // content type
  ANY_MESSAGE_TAG = '/smart_any_tag',
  ANY_CONTENT_TYPE = '/smart_any_content_type',
  WITHOUT_CONTENT_TYPE = '/smart_without_content_type',
  POST_PURCHASE_UPDATE = '/smart_post_purchase_update',
  CONFIRMED_EVENT_UPDATE = '/smart_message_tag_confirmed_event_update',
  ACCOUNT_UPDATE = '/smart_account_update',
  ONE_TIME_NOTIFICATIONS = '/smart_otn',
  FB_COMMENTS = '/smart_comment_reply',
  OTHER = '/smart_custom_other',
}

export interface FSQuery {
  q?: string
  path: FSSmartFolders | typeof FSQueryRootPath | string
  folderOnly?: boolean
  order?: string
  sort?: string
  place?: string
}

import { Attribute } from './types'

export const attributesToProps = (attributesList: Attribute[], data?: Record<string, unknown>) => {
  const result: Record<string, unknown> = {}
  for (const attribute of attributesList) {
    const name = getPropName(attribute.name)
    const value = getPropValue(attribute, data)

    if (value) result[name] = value
  }

  if (result.target) result.rel = 'noopener noreferrer'

  return result
}

const getPropName = (attributeName: string) => {
  if (attributeName === 'class') return 'className'
  if (attributeName === 'onclick') return 'onClick'

  return attributeName
}

function getPropValue(attribute: Attribute, data: Record<string, unknown> | undefined) {
  if (attribute.name === 'onclick') return data?.[attribute.value]
  if (attribute.name === 'style') return parseStyle(attribute.value)

  return attribute.value
}

const dashAndCharRegExp = /-[a-z]{1}/
const removeDashAndUppercase = (s: string) => s.replace('-', '').toUpperCase()
const getStylePropName = (input: string) =>
  input.trim().replace(dashAndCharRegExp, removeDashAndUppercase)

function parseStyle(input: string) {
  if (!input.trim()) {
    return ''
  }
  const result: Record<string, string> = {}
  input
    .split(';')
    .filter((s) => s)
    .forEach((s) => {
      const [nameString, valueString] = s.split(':')
      const name = getStylePropName(nameString)
      if (name) result[name] = valueString.trim()
    })
  return result
}

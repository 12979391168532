import React from 'react'
import cx from 'classnames'

import { AutosizeInputProps } from './types'
import { useAutosizeInput } from './useAutosizeInput'

import cm from './AutosizeInput.module.css'

/**
 * A modified copy of the library @see https://github.com/JedWatson/react-input-autosize/blob/master/src/AutosizeInput.js
 *
 * We had to refactor because of React 18
 */
export const AutosizeInput = (props: AutosizeInputProps) => {
  const { inputRef, sizerRef, placeHolderSizerRef, sizerValue, wrapperStyle, inputProps } =
    useAutosizeInput(props)

  return (
    <div className={props.className} style={wrapperStyle}>
      <input {...inputProps} ref={inputRef} className={cx(cm.input, props.inputClassName)} />
      <div ref={sizerRef} className={cm.sizer}>
        {sizerValue}
      </div>
      {props.placeholder ? (
        <div ref={placeHolderSizerRef} className={cm.sizer}>
          {props.placeholder}
        </div>
      ) : null}
    </div>
  )
}

import { ChannelType } from 'common/core/constants/ChannelType'

export const AvailableTemplateChannels = [
  ChannelType.INSTAGRAM,
  ChannelType.TIKTOK,
  ChannelType.WHATSAPP,
  ChannelType.FB,
  ChannelType.TELEGRAM,
]

export const CGT_TEMPLATE_ID_ENG = 184
export const CGT_TEMPLATE_ID_ES = 185
export const CGT_TEMPLATE_ID_PT = 201
export const CGT_TEMPLATE_ID_RU = 186

export const CGTTemplatesIds = [CGT_TEMPLATE_ID_ENG, CGT_TEMPLATE_ID_ES, CGT_TEMPLATE_ID_RU]

export const STORY_REPLY_TEMPLATE_ID_ENG = 187
export const STORY_REPLY_TEMPLATE_ID_ES = 188
export const STORY_REPLY_TEMPLATE_ID_PT = 202
export const STORY_REPLY_TEMPLATE_ID_RU = 189

export const SHOW_OFF_YOUR_SITE_NEW_VISITOR_ID_ENG = 30

export const StoryReplyTemplatesIds = [
  STORY_REPLY_TEMPLATE_ID_ENG,
  STORY_REPLY_TEMPLATE_ID_ES,
  STORY_REPLY_TEMPLATE_ID_RU,
]

export const BEST_TEMPLATES_COUNT = 3

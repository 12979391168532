import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { bindActionCreators } from 'redux'

import * as targetPageMappingActions from 'common/mapping/actions/targetPageMappingActions'
import TargetPageEntitiesMapping from 'common/mapping/components/TargetPageEntitiesMapping'
import WithTargetPageEntities from 'common/mapping/controllers/WithTargetPageEntities'

class TargetPageEntitiesMappingComponent extends React.Component {
  state = {
    cufsAutoMap: {},
    tagsAutoMap: {},
    eventsAutoMap: {},
    gafsAutoMap: {},
    otnsAutoMap: {},
  }

  componentDidMount() {
    this.props.onRef && this.props.onRef(this)
    this.setAutoMaps()
  }

  componentWillUnmount() {
    this.props.onRef && this.props.onRef(undefined)
  }

  componentDidUpdate(prevProps) {
    const { pageId, targetPageMappingActions } = this.props

    const isTargetPageUpdated = prevProps.pageId !== pageId

    if (isTargetPageUpdated) {
      return targetPageMappingActions.resetMaps()
    }

    if (
      (!prevProps.entities.custom_events.length && this.props.entities.custom_events.length) ||
      (!prevProps.targetPageCustomEvents.length && this.props.targetPageCustomEvents.length) ||
      isTargetPageUpdated
    ) {
      this.setEventsAutoMap()
    }

    if (
      (!prevProps.entities.otns.length && this.props.entities.otns.length) ||
      (!prevProps.targetPageOtns.length && this.props.targetPageOtns.length) ||
      isTargetPageUpdated
    ) {
      this.setOTNsAutoMap()
    }

    if (
      (!prevProps.entities.tags.length && this.props.entities.tags.length) ||
      (!prevProps.targetPageTags.length && this.props.targetPageTags.length) ||
      isTargetPageUpdated
    ) {
      this.setTagsAutoMap()
    }

    if (
      (!prevProps.entities.custom_fields.length && this.props.entities.custom_fields.length) ||
      (!prevProps.targetPageCufs.length && this.props.targetPageCufs.length) ||
      isTargetPageUpdated
    ) {
      this.setCUFsAutoMap()
    }

    if (
      (!prevProps.entities.global_fields.length && this.props.entities.global_fields.length) ||
      (!prevProps.targetPageGafs.length && this.props.targetPageGafs.length) ||
      isTargetPageUpdated
    ) {
      this.setGFsAutoMap()
    }
  }

  setAutoMaps = () => {
    this.setEventsAutoMap()
    this.setTagsAutoMap()
    this.setCUFsAutoMap()
    this.setGFsAutoMap()
    this.setOTNsAutoMap()
  }

  setEventsAutoMap = () => {
    const { entities, targetPageCustomEvents, targetPageMappingActions } = this.props

    const automap = this.createAutoMap(entities.custom_events, targetPageCustomEvents, {
      id: 'event_id',
      name: 'title',
    })

    targetPageMappingActions.updateCustomEventsMap(automap)
    this.setState({ eventsAutoMap: automap })
  }

  setTagsAutoMap = () => {
    const { entities, targetPageTags, targetPageMappingActions } = this.props

    const automap = this.createAutoMap(entities.tags, targetPageTags, {
      id: 'tag_id',
      name: 'tag_name',
    })

    targetPageMappingActions.updateTagsMap(automap)
    this.setState({ tagsAutoMap: automap })
  }

  setOTNsAutoMap = () => {
    const { entities, targetPageOtns, targetPageMappingActions } = this.props

    const otns = entities.otns.map((otn) => ({ ...otn, name: otn.request_title }))

    const automap = this.createAutoMap(otns, targetPageOtns, {
      id: 'reason_id',
      name: 'request_title',
      moreParam: 'channel',
    })

    targetPageMappingActions.updateOtnsMap(automap)
    this.setState({ tagsAutoMap: automap })
  }

  setCUFsAutoMap = () => {
    const { entities, targetPageCufs, targetPageMappingActions } = this.props
    const automap = this.createAutoMap(entities.custom_fields, targetPageCufs, {
      id: 'field_id',
      name: 'caption',
    })

    targetPageMappingActions.updateCUFsMap(automap)
    this.setState({ cufsAutoMap: automap })
  }

  setGFsAutoMap = () => {
    const { entities, targetPageGafs, targetPageMappingActions } = this.props
    const automap = this.createAutoMap(entities.global_fields, targetPageGafs, {
      id: 'field_id',
      name: 'caption',
    })

    targetPageMappingActions.updateGAFsMap(automap)
    this.setState({ gafsAutoMap: automap })
  }

  createAutoMap = (currentItems, targetItems, currentItemKeys) => {
    return currentItems.reduce((result, t) => {
      const match = targetItems.find(
        (ti) =>
          ti[currentItemKeys.name] === t.name &&
          ti.type === t.type &&
          ti[currentItemKeys.moreParam] === t[currentItemKeys.moreParam],
      )

      if (match) {
        result[t.id] = match[currentItemKeys.id]
      } else {
        result[t.id] = null
      }
      return result
    }, {})
  }

  clearMap = (map) => {
    return Object.keys(map).reduce((result, key) => {
      if (map[key]) {
        result[key] = map[key]
      }
      return result
    }, {})
  }

  render() {
    return <TargetPageEntitiesMapping isInitiallyOpen {...this.props} />
  }
}

const TargetPageEntitiesMappingController = connect(null, (dispatch) => ({
  targetPageMappingActions: bindActionCreators(targetPageMappingActions, dispatch),
}))(TargetPageEntitiesMappingComponent)

TargetPageEntitiesMappingController.propTypes = {
  flowId: PropTypes.string,
}

export default WithTargetPageEntities(TargetPageEntitiesMappingController)

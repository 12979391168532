export enum HandoverAppRoleType {
  PRIMARY = 'primary',
  SECONDARY = 'secondary',
  SHARE = 'share_attribution',
  NONE = 'none',
  UNKNOWN = 'unknown',
}

export const HandoverAppRoleLabel: Record<HandoverAppRoleType, string> = {
  [HandoverAppRoleType.PRIMARY]: 'Primary',
  [HandoverAppRoleType.SECONDARY]: 'Secondary',
  [HandoverAppRoleType.SHARE]: 'Share attribution',
  [HandoverAppRoleType.NONE]: 'None',
  [HandoverAppRoleType.UNKNOWN]: 'Unknown',
}

import orderBy from 'lodash/orderBy'
import { createSelector } from 'reselect'

import { ReasonValue } from 'common/builder/components/builder/GenericBuilder/NodeBuilder/ContentNodeContentTypeSelector/types'
import { ChannelForRn, ChannelType } from 'common/core/constants/ChannelType'
import {
  DEFAULT_TOPIC_CAPTION,
  PRIORITY_REASON_SORT_ORDER,
  ReasonStatuses,
  reasonTypeLabels,
  ReasonTypes,
} from 'common/oneTimeNotify/constants/notifyReasonConstants'
import { getChannel } from 'common/recurringNotifications/slice'
import { NotificationReason } from 'shared/api/requests/notificationReason/schemas'

export interface IReasonPreparedForAutocomplete {
  label: string
  value: number
  reason: NotificationReason
}
export interface IGroupReasons {
  label: string
  allowNew: boolean
  newLabel: string
  value: string
  group: string
  options: Array<{ label: string; value: number; group: ReasonTypes }>
}

interface MoreSubscribersReasonData {
  id?: number
  value: number
}

const makeReasonsObject = (value: ReasonTypes): IGroupReasons => ({
  label: reasonTypeLabels[value],
  allowNew: true,
  group: value,
  newLabel: `+ new ${reasonTypeLabels[value]}`,
  value,
  options: [],
})

export const getReasonsListGroupedForAutocomplete = (state: RootState): IGroupReasons[] => {
  const reasonsObjects = {
    [ReasonTypes.ONE_TIME]: makeReasonsObject(ReasonTypes.ONE_TIME),
    [ReasonTypes.DAILY]: makeReasonsObject(ReasonTypes.DAILY),
    [ReasonTypes.WEEKLY]: makeReasonsObject(ReasonTypes.WEEKLY),
    [ReasonTypes.MONTHLY]: makeReasonsObject(ReasonTypes.MONTHLY),
  }

  const reasonsList = getReasonsList(state)
  reasonsList?.forEach((reason) => {
    reasonsObjects[reason.type].options.push({
      label: reason.caption,
      value: reason.reason_id,
      group: reason.type,
    })
  })

  return Object.values(reasonsObjects)
}

export const getFirstAvailableReason = (
  state: RootState,
  channel?: ChannelType | null,
): NotificationReason | null => {
  const preparedReasonsList = getReasonsListByStatus(ReasonStatuses.ACTIVE)(state).filter(
    (reason) => (channel ? reason.channel === channel : true),
  )

  return preparedReasonsList.length === 1 ? preparedReasonsList[0] : null
}

export const getDefaultActiveReason = createSelector(
  [
    (state: RootState) => state.notificationReason.items,
    (state: RootState, channel: ChannelType) => channel,
  ],
  (reasonList, channel: ChannelType): NotificationReason | null => {
    const activeReasonsList = (reasonList ?? [])
      .filter((reason) => (channel ? reason.channel === channel : true))
      .filter((reason) => reason.status === ReasonStatuses.ACTIVE)

    if (activeReasonsList.length === 0) {
      return null
    }

    if (activeReasonsList.length === 1) {
      return activeReasonsList[0]
    }

    return orderBy(activeReasonsList, 'subscribers_count', 'desc')[0]
  },
)

export const getReasonsListForAutocomplete = (
  state: RootState,
  channel?: ChannelType | null,
): IReasonPreparedForAutocomplete[] => {
  return getReasonsListByStatus(ReasonStatuses.ACTIVE)(state)
    .filter((reason) => (channel ? reason.channel === channel : true))
    .sort(
      (reason1, reason2) =>
        PRIORITY_REASON_SORT_ORDER[reason1.type] - PRIORITY_REASON_SORT_ORDER[reason2.type],
    )
    .map((reason) => ({
      label: reason.caption,
      value: reason.reason_id,
      reason: reason,
    }))
}

export const getTargetPageReasonsListForAutocomplete = createSelector(
  [
    (state: RootState) => state.targetPage.otns.items,
    (_state: RootState, channel: ChannelType | null) => channel,
  ],
  (otns, channel) => {
    if (!otns) return []
    return otns
      .filter((reason) => (channel ? reason.channel === channel : true))
      .sort(
        (reason1: NotificationReason, reason2: NotificationReason) =>
          PRIORITY_REASON_SORT_ORDER[reason1.type] - PRIORITY_REASON_SORT_ORDER[reason2.type],
      )
      .map((reason: NotificationReason) => ({
        label: reason.caption,
        value: reason.reason_id,
        reason: reason,
      }))
  },
)

export const getTargetReasonsList = (state: RootState) => state.targetPage.otns.items

export const getReasonsList = (state: RootState): NotificationReason[] =>
  state.notificationReason.items

export const getReasonById =
  (id: ReasonValue) =>
  (state: RootState): NotificationReason | null => {
    if (id && typeof id === 'number') {
      return state.notificationReason.byId[id] ?? null
    } else {
      return null
    }
  }

export const getIsListReason = (state: RootState, id: ReasonValue): boolean => {
  const reason = getReasonById(id)(state)

  return reason?.type !== ReasonTypes.ONE_TIME
}

export const getDefaultReason = (state: RootState, channel?: ChannelType | null) => {
  const selectedChannel = channel ?? getChannel(state) ?? ChannelType.FB
  return getReasonsList(state).find(
    (value) => value.caption === DEFAULT_TOPIC_CAPTION && value.channel === selectedChannel,
  )
}

export const getDefaultReasonId = (state: RootState): number | null =>
  getDefaultReason(state)?.reason_id ?? null

export const getReasonWithMoreUsage = (
  state: RootState,
  channel?: ChannelType | null,
): NotificationReason | null => {
  const selectedChannel = channel ?? ChannelType.FB
  const reasons = getReasonsList(state).filter(
    ({ type, channel }) => type !== ReasonTypes.ONE_TIME && channel === selectedChannel,
  )
  const hasReasons = reasons.length > 0
  if (!hasReasons) {
    return null
  }
  return reasons.reduce((acc, curr) =>
    (acc.unique_usage_count ?? 0) > (curr.unique_usage_count ?? 0) ? acc : curr,
  )
}

export const getReasonWithMoreSubscribers = (
  state: RootState,
  channel?: ChannelType | null,
): MoreSubscribersReasonData | null => {
  const selectedChannel = channel ?? ChannelType.FB
  const activeReasons = getReasonsListByStatus(ReasonStatuses.ACTIVE)(state)
  const loading = getIsFetchingNotifications(state)

  const hasReasons = !loading && activeReasons.length > 0
  const initialReason = { value: 0 }
  const actualReason = activeReasons.reduce((prev, cur) => {
    const isMaxCountFbReason =
      cur.type !== ReasonTypes.ONE_TIME &&
      cur.channel === selectedChannel &&
      cur.subscribers_count > prev.value
    return isMaxCountFbReason ? { id: cur.reason_id, value: cur.subscribers_count } : prev
  }, initialReason as MoreSubscribersReasonData)
  return hasReasons ? actualReason : null
}

export const getReasonsListByStatus =
  (status: ReasonStatuses) =>
  (state: RootState): NotificationReason[] =>
    state.notificationReason.items.filter((r) => r.status === status)

export const getActiveReasons = (state: RootState) =>
  getReasonsListByStatus(ReasonStatuses.ACTIVE)(state)

export const getTypeById = (state: RootState, id?: number | null): ReasonTypes | null => {
  if (!id) return null

  return state.notificationReason.byId[id]?.type || null
}

export const getIsFetchingNotifications = (state: RootState) =>
  state.notificationReason.listState.isLoading

export const getNotificationReasonById = (
  state: RootState,
  id: number | null,
): NotificationReason | null => {
  if (!id) return null

  return state.notificationReason.byId[id] || null
}

export const getActiveReasonCountByChannel = (
  state: RootState,
  channel: ChannelType | null,
): number => {
  if (!channel) return 0
  const filteredActiveReasons = getReasonsListByStatus(ReasonStatuses.ACTIVE)(state).filter(
    (reason) => reason.channel === channel,
  )

  return filteredActiveReasons.length || 0
}

export const getReasonsByChannel = (
  state: RootState,
  channel: ChannelForRn,
): NotificationReason[] => getReasonsList(state).filter((reason) => reason.channel === channel)

import { ValueLabel } from 'common/core/interfaces/actions'
import { VAR_REGEX } from 'constants/RegExs'

export enum PhoneInputType {
  VARIABLE = 'VARIABLE',
  SPECIFIC = 'SPECIFIC',
}

export const PhoneInputTypeOptions: ValueLabel<PhoneInputType>[] = [
  {
    value: PhoneInputType.SPECIFIC,
    label: 'Specific phone',
  },
  {
    value: PhoneInputType.VARIABLE,
    label: 'Custom field phone',
  },
]

export const getPhoneInputType = (
  is_formula: boolean,
): PhoneInputType.SPECIFIC | PhoneInputType.VARIABLE => {
  return is_formula ? PhoneInputType.VARIABLE : PhoneInputType.SPECIFIC
}

export const getIsFormula = (phoneInputType: PhoneInputType): boolean => {
  return phoneInputType === PhoneInputType.VARIABLE
}

export const isVariable = (phoneInput: string): boolean => {
  if (!phoneInput) {
    return false
  }
  return ['gaf', 'cuf'].some((variableType) => phoneInput.includes(variableType))
}

export const convertToVariable = (input: string): string => {
  return input && isVariable(input) && !input.match(VAR_REGEX) ? `{{${input}}}` : input
}

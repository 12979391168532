import isArray from 'lodash/isArray'
import pick from 'lodash/pick'
import values from 'lodash/values'
export const getById = (state, sequenceId) => {
  return state.sequence.byId[sequenceId]
}

export const getList = (state) => {
  return values(state.sequence.byId)
}

export const getListMeta = (state) => {
  return pick(state.sequence, 'fetching', 'error', 'listFetched')
}

export const getSequences = (state) => {
  return {
    items: getList(state),
    ...getListMeta(state),
  }
}

export const getSequenceMessageById = (state, sequenceId, sequenceMessageId) => {
  const sequence = getById(state, sequenceId)
  // in some cases sequence.messages can be a number of total messages
  // https://manychat.myjetbrains.com/youtrack/issue/MC-1712
  if (!sequence || !isArray(sequence.messages)) {
    return null
  }

  // eslint-disable-next-line eqeqeq
  return sequence.messages.find((sequenceMessage) => sequenceMessage.id == sequenceMessageId)
}

import React from 'react'

import { MappedIceBreaker } from 'apps/iceBreakers/constants/constants'
import { Chat, PhonePreview, PhonePreviewTheme } from 'components/PhonePreviewKit'

export interface InstagramPreviewProps {
  iceBreakers: readonly MappedIceBreaker[]
  avatar: string
  name: string
}

export const InstagramPreview = ({ iceBreakers, avatar, name }: InstagramPreviewProps) => {
  return (
    <PhonePreview.Root theme={PhonePreviewTheme.INSTAGRAM}>
      <PhonePreview.Header title={name} avatarUrl={avatar} />
      <Chat.Root>
        <Chat.UserInfo avatarUrl={avatar} title={name} />
        <Chat.MessageList place="bottom">
          {iceBreakers
            .filter((iceBreaker) => iceBreaker.caption)
            .map((iceBreaker) => (
              <Chat.Button key={iceBreaker.id} label={iceBreaker.caption} />
            ))}
        </Chat.MessageList>
      </Chat.Root>
      <PhonePreview.MessageComposer />
    </PhonePreview.Root>
  )
}

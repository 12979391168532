import find from 'lodash/find'
import startsWith from 'lodash/startsWith'

export enum Namespace {
  SUBSCRIBE = 'system_subscribe',
  UNSUBSCRIBE = 'system_unsubscribe',

  WELCOME_MESSAGE = 'welcome',
  DEFAULT_REPLY = 'default',
  MAIN_MENU = 'mainmenu',
  /* eslint-disable-next-line @typescript-eslint/no-duplicate-enum-values */
  SYSTEM_SUBSCRIBE = 'system_subscribe',
  /* eslint-disable-next-line @typescript-eslint/no-duplicate-enum-values */
  SYSTEM_UNSUBSCRIBE = 'system_unsubscribe',
  SEQUENCE_MESSAGE = 'seqmessage',
  WIDGET = 'widget',
  POST = 'post',
  KEYWORD = 'keyword',

  SIMPLE = 'simple',
}

export const getBaseNamespace = (flowId: string): Namespace | undefined => {
  return find(Namespace, (value) => startsWith(flowId, value))
}

import { combineReducers } from 'redux'

import fields from './filterFields'
import values from './filterFieldValues'
export { default as segmentsReducer } from './segments'

export const filterReducer = combineReducers({
  fields,
  values,
})

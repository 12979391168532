export enum LiveChatLocalStorageIds {
  FILTERS_PANEL_EXPANDED = 'mcht_livechat_filters_panel_expanded',
  CONVERSATIONS_FILTER = 'mcht_livechat_conversations_filter',
  TEAM_MEMBER_FILTER = 'mcht_livechat_team_member_filter',
  CLOSED_SNIPPETS_SHORTCUT = 'mc_closed_snippets_shortcut',
  COLLAPSED_SIDEBAR = 'mc_collapsed_sidebar',
  ANY_MESSAGE_STARTS_CONVERSATION_SETTING_SCREEN_HIDDEN = 'mc_any_message_starts_conversation_screen_hidden',
  AUTO_ASSIGNMENT_THREAD_SCREEN_HIDDEN = 'mc_auto_assignment_thread_screen_hidden',
  AUTO_ASSIGNMENT_ASSIGN_DROPDOWN_BANNER_HIDDEN = 'mc_auto_assignment_assign_dropdown_banner_hidden',
  LIVE_CHAT_FIRST_THREAD_ONBOARDING_COMPLETED = 'mc_live_chat_first_thread_onboarding_completed',
}

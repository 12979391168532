import { l } from '@manychat/manyui'

import { SECONDS_IN_HOUR } from 'components/DelayPicker/constants'

import { SocketBulkActionsTypes } from './LiveChatInterfaces'

export const ThreadSuccessBulkText = {
  [SocketBulkActionsTypes.ASSIGN]: l.makeTranslate('Conversations have been assigned.'),
  [SocketBulkActionsTypes.UNASSIGN]: l.makeTranslate('Conversations have been unassigned.'),
  [SocketBulkActionsTypes.CLOSE]: l.makeTranslate('Conversations have been closed.'),
  [SocketBulkActionsTypes.REOPEN]: l.makeTranslate('Conversations have been reopened.'),
}

export enum ThreadMessageType {
  MSG_IN = 'msgin',
  MSG_OUT = 'msgout',
}

export const DelayAutomationsOptions = [
  { label: l.makeTranslate('30 minutes'), value: SECONDS_IN_HOUR / 2 },
  { label: l.makeTranslate('1 hour'), value: SECONDS_IN_HOUR },
  { label: l.makeTranslate('3 hours'), value: SECONDS_IN_HOUR * 3 },
  { label: l.makeTranslate('6 hours'), value: SECONDS_IN_HOUR * 6 },
  { label: l.makeTranslate('12 hours'), value: SECONDS_IN_HOUR * 12 },
  { label: l.makeTranslate('1 day'), value: SECONDS_IN_HOUR * 24 },
]

export enum AutomationsStatus {
  WORKING = 'working',
  PAUSED = 'paused',
  PAUSED_FOREVER = 'paused-forever',
}

import dayjs from 'dayjs'
import duration from 'dayjs/plugin/duration'
import dot from 'dot-prop-immutable'
import isString from 'lodash/isString'
import padStart from 'lodash/padStart'
import startsWith from 'lodash/startsWith'
import { v4 as uuid } from 'uuid'

import { ISegmentItem } from 'common/core/interfaces/segmentItem'
import { FieldSegmentType, FieldType } from 'common/fields/entities/enums'
import { FilterOperator } from 'common/filter/filterConstants'
import {
  BetweenDatesConditionValue,
  DefaultConditionData,
  FilterCondition,
  ConditionField,
  FilterConditionOperator,
  FilterError,
  IFilter,
  IFilterGroupItem,
} from 'common/filter/interfaces'
import getDefaultFilter from 'common/filter/models/AudienceFilter/defaults'
import { WEEKDAYS } from 'constants/constants'
import errorTrackingService from 'utils/services/errorTrackingService'

import {
  FieldTypeDefaultOperators,
  FieldTypeOperator,
  FieldTypesOperators,
  FilterField,
} from './constants'

dayjs.extend(duration)

export const hasConditions = (filter: IFilter<FilterCondition> | null | undefined): boolean => {
  if (!filter) return false
  return filter.groups && filter.groups.some((group) => group.items && group.items.length > 0)
}

export const getDefaultConditionData = (
  field: ConditionField['name'],
  type: ConditionField['type'],
  operator?: FilterConditionOperator,
): Partial<DefaultConditionData> => {
  if (type !== FieldType.SYSTEM_CURRENT_DATETIME) {
    return {}
  }
  if (operator === FieldTypeOperator.AFTER || operator === FieldTypeOperator.BEFORE) {
    return { value: null, offset: null }
  }
  if (operator === FieldTypeOperator.BETWEEN) {
    return {
      value: {
        from: { time: '00:00' },
        to: { time: '00:00' },
        weekdays: WEEKDAYS,
      },
      offset: null,
    }
  }
  return {}
}

const getDefaultOperator = (field: ConditionField): FilterConditionOperator | undefined => {
  return FieldTypeDefaultOperators[field.type]?.value || FieldTypesOperators[field.type]?.[0]?.value
}

export const createCondition = (
  field: ConditionField,
  operator: FilterConditionOperator | null,
  value: IFilterGroupItem['value'] | null = null,
  options: { isBuilder?: boolean } = {},
): FilterCondition => {
  const defaultOperator = getDefaultOperator(field)

  let condition: FilterCondition = {
    _oid: uuid(),
    type: field.segment_type,
    field: field.name,
    operator: operator || defaultOperator,
  }

  condition = {
    ...condition,
    ...getDefaultConditionData(condition.field, field.type, condition.operator),
  }

  const isTextCuf =
    field.type === FieldType.SYSTEM_TEXT && field.segment_type === FieldSegmentType.CUF
  const isTextSuf =
    field.type === FieldType.SYSTEM_TEXT && field.segment_type === FieldSegmentType.SUF

  if (options.isBuilder && (isTextCuf || isTextSuf)) {
    condition.case_sensitive = false
  }

  condition.value = value

  return condition
}

export const pushCondition = (
  filter: IFilter<FilterCondition>,
  groupIndex: number,
  condition: FilterCondition,
): IFilter<FilterCondition> => {
  return dot.merge(filter, `groups.${groupIndex}.items`, condition)
}

export const removeCondition = (
  filter: IFilter<FilterCondition>,
  groupIndex: number,
  conditionIndex: number,
): IFilter<FilterCondition> => {
  const hasNegativeValues = groupIndex < 0 || conditionIndex < 0

  if (hasNegativeValues) {
    errorTrackingService.trackWarning('removeCondition function got negative values', {
      extra: {
        filter,
        groupIndex,
        conditionIndex,
      },
      fingerprint: 'audience-filter-remove-condition-helper',
    })

    return filter
  }

  return dot.delete(filter, `groups.${groupIndex}.items.${conditionIndex}`)
}

export const calculateTimeForTimezone = (time: string, timeZone: string): string => {
  const isAdd = startsWith(timeZone, '+')
  const timeZoneSplit = timeZone.replace('+', '').replace('-', '').split(':')

  const hoursToAdd = parseInt(timeZoneSplit[0])
  const minutesToAdd = timeZoneSplit[1] ? parseInt(timeZoneSplit[1]) : 0

  const [timeHours, timeMinutes] = time.split(':')
  const duration = dayjs.duration({
    hour: timeHours,
    minute: timeMinutes,
  })

  if (isAdd) {
    const nextTime = duration.add(hoursToAdd, 'hour').add(minutesToAdd, 'minute')
    return `${padStart(nextTime.hours(), 2, '0')}:${padStart(nextTime.minutes(), 2, '0')}`
  }

  const nextTime = duration.subtract(hoursToAdd, 'hour').subtract(minutesToAdd, 'minute')
  const hours = nextTime.hours() < 0 ? 24 + nextTime.hours() : nextTime.hours()
  const minutes = nextTime.minutes() < 0 ? 60 + nextTime.minutes() : nextTime.minutes()

  return `${padStart(`${hours}`, 2, '0')}:${padStart(`${minutes}`, 2, '0')}`
}

export const initFilterWithConditions = (
  conditions: IFilterGroupItem[],
): IFilter<FilterCondition> => {
  return {
    operator: FilterOperator.AND,
    groups: [
      {
        operator: FilterOperator.AND,
        items: conditions.map((condition) => ({
          _oid: uuid(),
          ...condition,
        })),
      },
    ],
  }
}

export const getFilterErrors = (filter: IFilter<FilterCondition>): FilterError[] => {
  const errors: FilterError[] = []
  if (!filter) {
    return errors
  }
  filter.groups.forEach((group) =>
    group.items.forEach((item) => {
      if (
        item.field === FilterField.CURRENT_DATETIME &&
        item.operator === FieldTypeOperator.BETWEEN
      ) {
        if (isSystemCurrentDateTimeValue(item.value)) {
          const from = item.value.from.time
          const to = item.value.to.time
          if (isString(from) && isString(to)) {
            const fromTime = dayjs(from, 'h:mm')
            const toTime = dayjs(to, 'h:mm')
            if (fromTime.isSameOrAfter(toTime)) {
              errors.push({
                prop: 'filter',
                message: 'Time value in the left field should be fewer than in the right field',
              })
            }
          }
        }
      }
    }),
  )
  return errors
}

export const createConditionFromSegment = (segment: ISegmentItem): FilterCondition => {
  return createCondition(
    { name: segment.field, type: FieldType.ENUM, segment_type: segment.type as FieldSegmentType },
    FieldTypeOperator.IS,
    segment.value,
  )
}

export const createFilterFromSegment = (segment: ISegmentItem): IFilter<FilterCondition> => {
  const condition = createConditionFromSegment(segment)
  return pushCondition(getDefaultFilter(), 0, condition)
}

export const isFilterContainSmartSegment = (
  filter: IFilter<FilterCondition> | null | undefined,
): boolean =>
  hasConditions(filter) &&
  (filter?.groups || []).some((group) =>
    (group.items || []).some((item) => item.type === FieldSegmentType.SMART_SEGMENT),
  )

export function isSystemCurrentDateTimeValue(
  value: FilterCondition['value'] | number[],
): value is BetweenDatesConditionValue {
  return (
    typeof value === 'object' &&
    !Array.isArray(value) &&
    Boolean(value?.from) &&
    Boolean(value?.to) &&
    Boolean(value?.weekdays)
  )
}

import { TagType } from './types'

const Tags = new Set([
  'a',
  'b',
  'u',
  'br',
  'code',
  'em',
  'h1',
  'h2',
  'h3',
  'h4',
  'h5',
  'h6',
  'i',
  'p',
  'span',
  'strong',
  'abbr',
])

const SelfClosingTags = new Set(['br'])

export const isTagAllowed = (type: TagType, tagName: string) => {
  if (type === 'self-closing') return SelfClosingTags.has(tagName)

  return Tags.has(tagName)
}

const AllowedAttributes = new Set(['class', 'name', 'style'])
const LinkAllowedAttributes = new Set([...AllowedAttributes, 'target', 'href', 'onclick'])

export const makeIsAttributeAllowed = (tagName: string) => (attributeName: string) => {
  if (tagName === 'a') return LinkAllowedAttributes.has(attributeName)

  return AllowedAttributes.has(attributeName)
}

import get from 'lodash/get'
import { Reducer } from 'redux'

import { ChannelType } from 'common/core/constants/ChannelType'

import {
  getInitialWelcomeMessageChannelState,
  WelcomeMessageChannels,
} from '../welcomeMessageConstants'
import { WelcomeMessageState } from '../welcomeMessageInterfaces'
import * as atypes from '../welcomeMessageReduxActionTypes'

export const getInitialState = (): WelcomeMessageState =>
  WelcomeMessageChannels.reduce<WelcomeMessageState>((state, channel) => {
    state[channel] = getInitialWelcomeMessageChannelState(channel)

    return state
  }, {})

export const welcomeMessageReducer: Reducer<WelcomeMessageState> = (
  state = getInitialState(),
  action,
) => {
  const channel = action.channel ?? ChannelType.FB
  const channelState = state[channel]

  switch (action.type) {
    case atypes.WELCOME_MESSAGE_UPDATE_ENABLED_REQUEST: {
      return { ...state, [channel]: { ...channelState, updating: true } }
    }
    case atypes.WELCOME_MESSAGE_UPDATE_ENABLED_ERROR: {
      return { ...state, [channel]: { ...channelState, updating: false } }
    }
    case atypes.WELCOME_MESSAGE_UPDATE_ENABLED_RESPONSE: {
      return {
        ...state,
        [channel]: { ...channelState, enabled: action.data.enabled, updating: false },
      }
    }

    case atypes.WELCOME_MESSAGE_REMOVE_FLOW_RESPONSE: {
      return {
        ...state,
        [channel]: { ...channelState, flowId: null, name: '' },
      }
    }

    case atypes.UNSET_WELCOME_MESSAGE_RESPONSE: {
      const changes = {
        enabled: false,
        flowId: null,
        name: '',
        chId: null,
        isFetched: true,
      }

      return { ...state, [channel]: { ...channelState, ...changes } }
    }

    case atypes.WELCOME_MESSAGE_LOAD_REQUEST: {
      return {
        ...state,
        [channel]: { ...channelState, fetching: true },
      }
    }

    case atypes.WELCOME_MESSAGE_LOAD_RESPONSE:
    case atypes.WELCOME_MESSAGE_SET_FLOW_RESPONSE:
    case atypes.WELCOME_MESSAGE_UPDATE_BY_CHANNEL: {
      const changes = {
        enabled: action.data.enabled,
        flowId: get(action.data, 'flow.ns', null),
        name: get(action.data, 'flow.name', ''),
        chId: get(action.data, 'content_holder'),
        isFetched: true,
        fetching: false,
      }
      return { ...state, [channel]: { ...channelState, ...changes } }
    }

    case atypes.WELCOME_MESSAGE_LOAD_ERROR: {
      return { ...state, [channel]: { ...channelState, fetching: false } }
    }
  }
  return state
}

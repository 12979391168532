import assert from 'assert'

import { UNLINK_BLOCK_AND_BUTTON } from 'common/builder/constants/builderReduxActionTypes'

/**
 * @param builderId
 * @param blockId
 * @param targetId
 */
export const unlinkButton = (builderId, blockId, targetId) => {
  assert(builderId, `unlinkButton: builderId is required param`)
  assert(blockId, `unlinkButton: blockId is required param`)
  assert(targetId, `unlinkButton: targetId is required param`)

  return {
    type: UNLINK_BLOCK_AND_BUTTON,
    builderId,
    blockId,
    targetId,
  }
}

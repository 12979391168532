import { AiAgentAvailableChannels } from 'common/ai/constants/AiAgentAvailableChannels'
import { NodeType } from 'common/builder'
import { AiAgentNodeType } from 'common/builder/nodes/nodeInterfaces'
import { getById } from 'common/builder/selectors/builder/builderStateSelectors'
import {
  getOnlyUsedChannelNodeType,
  getNodeChannel,
  getFirstChannelTypeBySourceChain,
} from 'common/builder/selectors/builder/nodeSelectors'
import { ChannelType } from 'common/core/constants/ChannelType'
import { getConnectedChannels } from 'common/core/selectors/getConnectedChannels'
import { getTriggersOnlyUsedChannel } from 'common/flow/selectors/flowSelectors'

const NodeWithHintsToChannelMap: Record<string, ChannelType> = {
  [NodeType.INSTAGRAM]: ChannelType.INSTAGRAM,
  [NodeType.WHATS_APP]: ChannelType.WHATSAPP,
  [NodeType.CONTENT]: ChannelType.FB,
  [NodeType.TELEGRAM]: ChannelType.TELEGRAM,
  [NodeType.TIKTOK]: ChannelType.TIKTOK,
}

const NodesWithHints = Object.keys(NodeWithHintsToChannelMap) as NodeType[]

export const getChannelFromConnectedChannels = (state: RootState) => {
  const connectedChannels = getConnectedChannels(state)
  const connectedAiAgentChannels = connectedChannels.filter((channel) =>
    AiAgentAvailableChannels.includes(channel),
  )

  return connectedAiAgentChannels.length === 1 ? connectedAiAgentChannels[0] : null
}

export const getChannelFromNodesInChain = (
  state: RootState,
  builderId: string,
  sourceId: string | null,
) => {
  if (!sourceId) {
    return null
  }

  const sourceNodeChannel = getNodeChannel(state, builderId, sourceId)

  if (sourceNodeChannel && AiAgentAvailableChannels.includes(sourceNodeChannel)) {
    return sourceNodeChannel
  }

  const channelType = getFirstChannelTypeBySourceChain(state, builderId, sourceId)

  return channelType && AiAgentAvailableChannels.includes(channelType) ? channelType : null
}

export const getChannelFromTriggers = (state: RootState, builderId: string) => {
  const builder = getById(state, builderId)

  if (!builder) {
    return null
  }

  const triggersOnlyUsedChannel = getTriggersOnlyUsedChannel(state, builder.flow)

  return triggersOnlyUsedChannel && AiAgentAvailableChannels.includes(triggersOnlyUsedChannel)
    ? triggersOnlyUsedChannel
    : null
}

export const getChannelFromNodes = (state: RootState, builderId: string) => {
  const nodesOnlyUsedChannel = getOnlyUsedChannelNodeType(state, builderId)

  return nodesOnlyUsedChannel && NodesWithHints.includes(nodesOnlyUsedChannel)
    ? NodeWithHintsToChannelMap[nodesOnlyUsedChannel]
    : null
}

export function convertToAiAgentWithPreselectedChannel({
  node,
  state,
  builderId,
  sourceId,
}: {
  node: AiAgentNodeType
  state: RootState
  builderId: string
  sourceId: string | null
}): AiAgentNodeType {
  const channel =
    getChannelFromConnectedChannels(state) ||
    getChannelFromNodesInChain(state, builderId, sourceId) ||
    getChannelFromTriggers(state, builderId) ||
    getChannelFromNodes(state, builderId)

  if (channel) {
    return {
      ...node,
      channel,
    }
  }

  return node
}

import { LayoutWithSidebar } from 'utils/router/components/LayoutWithSidebar/LayoutWithSidebar'
import { getHandle } from 'utils/router/utils/getHandle'

export const CmsRoutes = [
  {
    path: 'cms',
    Component: LayoutWithSidebar,
    alias: 'cms',
    query: ['path', 'field', 'order', 'search', 'triggerFilters', 'triggerStatus'],
    children: [
      {
        index: true,
        lazy: () => import('apps/cms/pages/ContentManagementSystem/ContentManagementSystem'),
      },
      {
        path: 'files/:fso_path/:mode?/:content_id?',
        alias: 'cmsFiles',
        handle: getHandle('flow'),
        lazy: () => import('./cms-builder.page'),
      },
      {
        path: 'ai-step-automation',
        alias: 'aiStepWizard',
        lazy: () => import('common/cms/pages/ai-step-wizard.page'),
      },
      {
        path: '#rules',
        lazy: () => import('apps/triggerRules/pages/rulesList.page'),
      },
      {
        path: 'easy-builder/:flow_id?',
        alias: 'easyBuilder',
        query: ['folder_id', 'campaign_type', 'source'],
        lazy: () => import('common/cms/pages/easy-builder.page'),
      },
      {
        path: 'recommendations',
        alias: 'recommendations',
        query: ['channel'],
        lazy: () => import('apps/recomendations/recommendations.page'),
      },
    ],
  },
  {
    path: 'rules/:rule_id',
    Component: LayoutWithSidebar,
    alias: 'ruleItem',
    children: [
      {
        index: true,
        lazy: () => import('apps/triggerRules/pages/ruleDetail.page'),
      },
    ],
  },
] as const

import { ActionReducerMapBuilder, AnyAction } from '@reduxjs/toolkit'

export const addFetchingCases = <S extends { fetching: boolean }>(
  builder: ActionReducerMapBuilder<S>,
  actionCreator: { typePrefix: string; pending: { type: string } },
) => {
  builder.addMatcher(
    (action: AnyAction): action is AnyAction => action.type.startsWith(actionCreator.typePrefix),
    (state, action) => {
      state.fetching = action.type === actionCreator.pending.type
    },
  )
}

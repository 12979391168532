import get from 'lodash/get'

import { getCustomActions } from './../getCustomActions'

export const makeItemsDetailsActions = (entity, options = {}) => {
  const prefix = `${entity.toUpperCase()}_CURRENT`
  const storePath = options.storePath || `${entity}`

  const actions = {
    init: (id, data) => ({
      type: `${prefix}_INIT`,
      id,
      data,
    }),
    fetch: (id) => {
      return (dispatch, getState) => {
        const item = get(getState(), `${storePath}.byId.${id}.item`)
        if (item) {
          return
        }

        dispatch({
          type: `${prefix}_FETCH`,
          $endpoint: [`${entity}.fetchItem`, { id }],
          $error: get(options, '$error', null),
          id,
        })
      }
    },
    update: (id, data, path, replace) => ({
      type: `${prefix}_UPDATE`,
      id,
      data,
      path,
      replace,
    }),
    create: (initial, urlParams, onError) => ({
      type: `${prefix}_CREATE`,
      $endpoint: [`${entity}.create`, urlParams, initial],
      $error: onError,
    }),
    save: (id, options = {}) => {
      return (dispatch, getState) => {
        const { item } = get(getState(), `${storePath}.byId.${id}`)

        return dispatch({
          type: `${prefix}_SAVE`,
          $endpoint: [`${entity}.save`, null, item],
          $error: get(options, '$error', null),
          id,
        })
      }
    },
    updatedNotification: (item) => ({
      type: `${prefix}_UPDATED_NOTIFICATION`,
      entity,
      id: item.id,
      item,
    }),
  }

  const customActions = getCustomActions(entity, prefix)

  return { ...actions, ...customActions }
}

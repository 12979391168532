export const monthFirst = ['en', 'en-us', 'es-us']
export const sundayFirst = [
  'en',
  'ar-dz',
  'ar-kw',
  'ar-sa',
  'bn',
  'bo',
  'en-ca',
  'en-il',
  'en-us',
  'es-us',
  'fr-ca',
  'gu',
  'he',
  'hi',
  'ja',
  'kn',
  'ko',
  'lo',
  'ml',
  'mn',
  'mr',
  'ne',
  'pa-in',
  'pt-br',
  'si',
  'ta',
  'te',
  'th',
  'zh-hk',
  'zh-tw',
]

import { useMcClientAPI } from 'utils/api/query/hooks/useMcClientAPI'
import { useRequestCache } from 'utils/api/query/hooks/useRequestCache'
import { sendRequest } from 'utils/api/query/request'
import { CacheKey, Request, QueryFn } from 'utils/api/query/types'

interface UseQueryResult<T> {
  query: Request<T>
  reload: () => void
}

export const useQuery = <T>(key: CacheKey, request: QueryFn<T>): UseQueryResult<T> => {
  const [getQuery, setQuery] = useRequestCache<T>(key)
  const api = useMcClientAPI()
  const reload = () => sendRequest(() => request({ api, key }), getQuery(), setQuery)

  getQuery().status === 'idle' && reload()

  return { query: getQuery(), reload }
}

import React from 'react'

import { IFlatOption } from './types'

interface IconProps {
  option: Pick<IFlatOption, 'icon'>
}

const wrapperStyle = { width: 24 }

const OptionIcon = ({ option }: IconProps) => {
  const { icon } = option
  if (!icon) return null

  const Component = icon.component

  return (
    <div className="d-flex align-center flex-shrink-0" style={wrapperStyle}>
      <Component color={icon.color} size={icon.size} />
    </div>
  )
}

export default OptionIcon

import { ConfirmationLayout } from 'common/core/components/EmailConfirmationPage/ConfirmationLayout'

export const IntegrationsRoutes = [
  {
    path: 'integration',
    Component: ConfirmationLayout,
    children: [
      {
        path: 'connectConfirm',
        query: ['type'],
        lazy: () => import('./pages/connectConfirm.page'),
      },
    ],
  },
] as const

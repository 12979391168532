import { GenericCustomFieldSchema, GenericSystemFieldSchema } from 'common/fields/schemas'
import {
  GenericCustomField,
  GenericField,
  GenericFieldType,
  GenericSystemField,
} from 'common/fields/types'
import errorTrackingService from 'utils/services/errorTrackingService'

class GenericFieldConverter {
  private genericCustomFieldsPrefixes = [GenericFieldType.GLOBAL, GenericFieldType.USER]

  private isGenericCustomField(field: unknown): field is GenericCustomField {
    return GenericCustomFieldSchema.safeParse(field).success
  }

  private isGenericSystemField(field: unknown): field is GenericSystemField {
    return GenericSystemFieldSchema.safeParse(field).success
  }

  private trackConvertationError(field: unknown) {
    errorTrackingService.trackError(`Unable to convert field`, {
      extra: {
        field,
      },
    })
  }

  private trackParsingError(field: unknown) {
    errorTrackingService.trackError(`Unable to parse field`, {
      extra: {
        field,
      },
    })
  }

  /**
   * Converts GenericField type to a strig in format "cuf_123", "user_name", "gaf_123"
   */
  toString = (field: GenericField | unknown): string | null => {
    if (!field) {
      return null
    }

    if (this.isGenericSystemField(field)) {
      return field.field_name.toString()
    }

    if (this.isGenericCustomField(field)) {
      return `${field.type}_${field.field_id}`
    }

    this.trackConvertationError(field)
    return null
  }

  /**
   * Parse GenericField from strings like "cuf_123", "user_name", "gaf_123"
   */
  fromString = (rawField: string): GenericField | null => {
    if (!rawField) {
      return null
    }

    if (typeof rawField !== 'string') {
      this.trackParsingError(rawField)
      return null
    }

    const isSystemField = !this.genericCustomFieldsPrefixes.some((prefix) =>
      rawField.startsWith(prefix),
    )

    if (isSystemField) {
      return {
        type: GenericFieldType.SYSTEM,
        field_name: rawField,
      }
    }

    const parts = rawField.split('_')

    if (parts.length !== 2) {
      this.trackParsingError(rawField)
      return null
    }

    const fieldPrefix = parts[0] as GenericCustomField['type']
    const fieldId = parts[1] ? Number(parts[1]) : NaN

    if (isNaN(fieldId)) {
      this.trackParsingError(rawField)
      return null
    }

    return {
      type: fieldPrefix,
      field_id: fieldId,
    }
  }
}

export const genericFieldConverter = new GenericFieldConverter()

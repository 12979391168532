import React, { useCallback, useMemo, useState } from 'react'
import { useAppDispatch, useAppSelector } from 'reduxTyped'
import { l, Modal } from '@manychat/manyui'

import { useModalData } from 'apps/cms/components/Flows/components/AutomationModals/hooks/useModalData'
import { contentManagementActions, contentManagementSelectors } from 'apps/cms/store'
import { AutomationModalType } from 'apps/cms/store/lib/types'
import { SELECTION_TYPE } from 'common/cms/constants/CMSSelectionType'
import { FSSmartFolders } from 'common/cms/models/FSObject/constants'
import { PermissionTargets, usePermission } from 'common/userRoles'
import BulkUsesList from 'components/BulkUsesList/BulkUsesList'
import { CmsApi } from 'shared/api/requests/cms'

export const BulkDeleteFlowsModal = () => {
  const { isPermanent } = useModalData({ type: AutomationModalType.BULK_DELETE })

  const [isDeleteLoading, setDeleteLoading] = useState(false)
  const selectedFlows = useAppSelector(contentManagementSelectors.getSelectedFlowIds)
  const path = useAppSelector(contentManagementSelectors.getQueryPath)
  const dispatch = useAppDispatch()

  const handleClose = useCallback(() => {
    dispatch(contentManagementActions.closeModal(AutomationModalType.BULK_DELETE))
  }, [dispatch])

  const flowsSelectedAmount = selectedFlows.length

  const handleToggleFlowSelection = useCallback(
    (flowId: string) => {
      dispatch(contentManagementActions.toggleFlowSelection(flowId))
    },
    [dispatch],
  )

  const isTrashFolder = path === FSSmartFolders.TRASH

  const usesData = useMemo(
    () => ({
      path,
      selection_type: SELECTION_TYPE.INCLUDE,
      flow_nses: selectedFlows,
    }),
    [path, selectedFlows],
  )

  const selectedIds = useMemo(() => {
    return selectedFlows.reduce<Record<string, boolean>>((ids, id) => {
      ids[id] = true
      return ids
    }, {})
  }, [selectedFlows])

  const handleBulkGetUsesList = useCallback(async () => {
    const response = await CmsApi.getBulkUsesList({ body: usesData })
    return response.data
  }, [usesData])

  const handleBulkDelete = useCallback(async () => {
    setDeleteLoading(true)
    await dispatch(contentManagementActions.bulkDeleteFlows())
    handleClose()
    setDeleteLoading(false)
  }, [dispatch, handleClose])

  const getModalButtons = () => {
    return [
      {
        label: l.translate('Cancel'),
        onClick: handleClose,
        className: 'm-y-xs',
        variant: 'default',
        disabled: isDeleteLoading,
      },
      {
        label: l.translate('Delete'),
        onClick: handleBulkDelete,
        className: 'm-y-xs',
        variant: 'danger',
        loading: isDeleteLoading,
        disabled: flowsSelectedAmount === 0,
      },
    ]
  }

  const isAllowed = usePermission(PermissionTargets.FLOW_USES)

  const title = isPermanent
    ? l.translate('Permanently delete Automations')
    : l.translate('Delete Automations')

  const description = isPermanent
    ? l.translate(
        'You are going to permanently delete <b>{n, plural, one {# automation} other {# automations}}</b>',
        {
          n: flowsSelectedAmount,
        },
      )
    : l.translate(
        'You are going to delete <b>{n, plural, one {# automation} other {# automations}}</b>',
        {
          n: flowsSelectedAmount,
        },
      )

  return (
    <Modal
      width={500}
      open
      title={<div className="p-y-xs">{title}</div>}
      onRequestClose={handleClose}
      buttons={getModalButtons()}
      scrollable
    >
      <div className="text-center p-a">
        {description}

        {isAllowed && (
          <BulkUsesList
            isTrashFolder={isTrashFolder}
            onBulkGetUsesList={handleBulkGetUsesList}
            onToggleFlowSelection={handleToggleFlowSelection}
            selectedIds={selectedIds}
            selectionType={SELECTION_TYPE.INCLUDE}
          />
        )}
      </div>
    </Modal>
  )
}

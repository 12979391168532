import { ChannelConfig } from 'common/core/constants/ChannelConfig'
import { ChannelType, ChannelTypeLabel } from 'common/core/constants/ChannelType'

export const ConvertableChannels = [
  {
    title: ChannelTypeLabel[ChannelType.FB],
    value: ChannelType.FB,
    img: ChannelConfig[ChannelType.FB]?.icon,
  },
  {
    title: ChannelTypeLabel[ChannelType.INSTAGRAM],
    value: ChannelType.INSTAGRAM,
    img: ChannelConfig[ChannelType.INSTAGRAM]?.icon,
  },
  {
    title: ChannelTypeLabel[ChannelType.SMS],
    value: ChannelType.SMS,
    img: ChannelConfig[ChannelType.SMS]?.icon,
  },
  {
    title: ChannelTypeLabel[ChannelType.WHATSAPP],
    value: ChannelType.WHATSAPP,
    img: ChannelConfig[ChannelType.WHATSAPP]?.icon,
  },
  {
    title: ChannelTypeLabel[ChannelType.TELEGRAM],
    value: ChannelType.TELEGRAM,
    img: ChannelConfig[ChannelType.TELEGRAM]?.icon,
  },
  {
    title: ChannelTypeLabel[ChannelType.TIKTOK],
    value: ChannelType.TIKTOK,
    img: ChannelConfig[ChannelType.TIKTOK]?.icon,
  },
]

import { useCallback, useMemo } from 'react'
import { useAppDispatch, useAppSelector } from 'reduxTyped'

import {
  getIsWaChannelDataLoadingSelector,
  getChannelData,
  getIsWaChannelDataErrorSelector,
} from 'apps/whatsApp/redux/waChannelSlice'
import { getWhatsAppChannel } from 'common/builder/selectors/builder/whatsAppSelectors'
import {
  hasConnectedWhatsAppChannel,
  isWhatsAppChannelActive,
  isWhatsAppChannelBanned,
  whatsAppChannelHasCreditLineIssue,
} from 'common/core/selectors/channelSelectors'

/**
 * @deprecated after TA-455. Please use `waChannel` slice directly.
 */
export const useWhatsAppChannel = () => {
  const dispatch = useAppDispatch()
  const channel = useAppSelector(getWhatsAppChannel)
  const isConnected = useAppSelector(hasConnectedWhatsAppChannel)
  const isActive = useAppSelector(isWhatsAppChannelActive)
  const isBanned = useAppSelector(isWhatsAppChannelBanned)
  const hasIssueWithCreditLine = useAppSelector(whatsAppChannelHasCreditLineIssue)
  const errorLoadingWaChannel = useAppSelector(getIsWaChannelDataErrorSelector)
  const isLoadingWaChannel = useAppSelector(getIsWaChannelDataLoadingSelector)

  const status: 'idle' | 'error' | 'loading' | 'success' = useMemo(() => {
    if (channel === null) return 'idle'
    if (errorLoadingWaChannel) return 'error'
    if (isLoadingWaChannel) return 'loading'
    return 'success'
  }, [channel, errorLoadingWaChannel, isLoadingWaChannel])

  const getChannelDataWrapper = useCallback(() => {
    dispatch(getChannelData())
  }, [dispatch])

  return {
    channel,
    isConnected,
    isActive,
    isBanned,
    reload: getChannelDataWrapper,
    status,
    hasIssueWithCreditLine,
  }
}

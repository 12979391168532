import blockRegistry from 'common/builder/blockRegistry'
import buttonRegistry from 'common/builder/buttonRegistry'
import nodeRegistry from 'common/builder/nodeRegistry'

export default class BaseParser {
  constructor(batch) {
    this.batch = batch
    this.parsed = {
      nodes: [],
      blocks: [],
      buttons: [],
      urls: [],
    }
  }

  process = () => {
    throw new Error(`not implemented`)
  }

  addNode = (initial) => {
    const item = nodeRegistry.get(initial).create(initial)
    this.parsed.nodes.push(item)
    return item
  }

  addBlock = (initial) => {
    const item = blockRegistry.get(initial).create(initial)
    this.parsed.blocks.push(item)
    return item
  }

  addButton = (initial) => {
    const item = buttonRegistry.get(initial).create(initial)
    this.parsed.buttons.push(item)
    return item
  }
}

import React from 'react'
import cx from 'classnames'
import { useAppDispatch } from 'reduxTyped'
import { l, Icon } from '@manychat/manyui'

import { useDnd } from 'apps/cms/components/Flows/components/FolderCard/hooks/useDnd'
import { useTrashMoveActions } from 'apps/cms/hooks/useTrashMoveActions'
import { CmsAnalytics } from 'apps/cms/lib/analytics'
import animationsCm from 'apps/cms/lib/styles/animations.module.css'
import { contentManagementActions } from 'apps/cms/store'
import { FSObjectType, FSSmartFolders } from 'common/cms/models/FSObject/constants'
import { FsFolderObject } from 'shared/api/requests/cms/schemas'
import { linkURL } from 'utils/url'

import cm from './TrashButton.module.css'

const TrashFolder = {
  type: FSObjectType.FOLDER,
  folder_id: -1,
  path: FSSmartFolders.TRASH,
  title: l.translate('Trash'),
  entity: 10,
  created: 0,
  modified: 0,
} as FsFolderObject

export const TrashButton = React.memo(function TrashButton() {
  const dispatch = useAppDispatch()

  const { moveFlowsToTrash, moveFolderToTrash } = useTrashMoveActions()

  const { isDragging, highlightDrop, connectDropTarget } = useDnd({
    folder: TrashFolder,
    onFolderDrop: moveFolderToTrash,
    onFlowsDrop: moveFlowsToTrash,
  })

  const openTrashFolder = (event: React.MouseEvent<HTMLButtonElement>) => {
    CmsAnalytics.logOpenTrash()
    if (event.metaKey || event.ctrlKey) {
      window.open(linkURL(`/cms?path=${FSSmartFolders.TRASH}`))
    } else {
      dispatch(
        contentManagementActions.updateQuery({
          path: FSSmartFolders.TRASH,
          search: '',
          triggerFilters: [],
          triggerStatus: null,
        }),
      )
    }
  }

  return (
    <button
      ref={(element) => {
        connectDropTarget(element)
      }}
      onClick={openTrashFolder}
      className={cx(
        cm.trashButton,
        highlightDrop && animationsCm.cmsDragTargetAnimation,
        isDragging && cm.trashButton_highlight,
      )}
    >
      <Icon.Delete size={16} />

      {l.translate('Trash')}
    </button>
  )
})

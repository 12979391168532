import debugConstructor from 'debug'

import { setCookie, getCookie } from 'utils/cookie'
import { getInfoAboutService } from 'utils/getInfoAboutService'

const debug = debugConstructor('analytics')

const FB_IMG_SIZE = 1
export const USERCENTRICS_FACEBOOK_PIXEL_NAME = 'Facebook Pixel'

interface DuplicateFbq {
  callMethod: {
    apply: (duplicateFbq: unknown, args?: unknown) => void
  }
  queue: {
    push: (args?: unknown) => void
  }
}

export default class FacebookPixelService {
  public isProvideFacebookPixel: boolean
  public userId: string | null
  public isPushEventsFromGTMCookie = false

  constructor() {
    const { isProvideFacebookPixel } = this.getServicesProvidedStatus()
    this.isProvideFacebookPixel = isProvideFacebookPixel

    const User = window.__INIT__?.['app.user']
    const IdFromInit = User?.user_id
    this.userId = IdFromInit ? String(IdFromInit) : null

    if (this.isProvideFacebookPixel && !window.fbq) {
      this.loadFacebookPixel()
    }
  }

  public push = (data: UnsafeAnyObject, callback?: () => void) => {
    if (!this.isProvideFacebookPixel) {
      return
    }

    if (window.fbq) window.fbq('track', data.event, data)
    if (!callback) return

    const eventTimeout = (data.eventTimeout || 2000) + 500

    setTimeout(() => {
      debug('dataLayer.push', 'eventCallback called')
      callback()
    }, eventTimeout)
  }

  public pushEventsFromGTMCookie = () => {
    if (!this.isProvideFacebookPixel) {
      return
    }

    const gtm = getCookie('gtm_cookie')
    if (!gtm) return

    setCookie('gtm_cookie', '', -1)
    let event

    try {
      event = JSON.parse(decodeURIComponent(gtm))
    } catch (err) {
      debug('gtm_event parse error', err)
    }

    if (event) this.push(event)
  }

  private getServicesProvidedStatus = () => {
    const usercentricsServicesInfo = getInfoAboutService(USERCENTRICS_FACEBOOK_PIXEL_NAME)
    const isProvideFacebookPixel =
      usercentricsServicesInfo[USERCENTRICS_FACEBOOK_PIXEL_NAME]?.status ?? false
    return { isProvideFacebookPixel }
  }

  private handleOnLoad = () => {
    if (window.fbq) {
      window.fbq('dataProcessingOptions', ['LDU'], 0, 0)
      window.fbq('init', '444325236063408', { uid: this.userId })
      window.fbq('track', 'PageView')

      this.isPushEventsFromGTMCookie = true
      this.pushEventsFromGTMCookie()
    }
  }

  public refreshPermissions = () => {
    const { isProvideFacebookPixel } = this.getServicesProvidedStatus()
    this.isProvideFacebookPixel = isProvideFacebookPixel

    if (this.isProvideFacebookPixel && !window.fbq) {
      this.loadFacebookPixel()
    }
  }

  private loadFacebookPixel = () => {
    // documentation - https://developers.facebook.com/docs/meta-pixel/get-started
    if (window.fbq) {
      if (!this.isPushEventsFromGTMCookie) {
        this.isPushEventsFromGTMCookie = true
        this.loadFacebookPixel()
      }
      return
    }

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-expect-error
    const duplicateFbq: DuplicateFbq = (window.fbq = function () {
      if (duplicateFbq.callMethod) {
        // eslint-disable-next-line prefer-spread,prefer-rest-params
        duplicateFbq.callMethod.apply(duplicateFbq, arguments)
      } else {
        // eslint-disable-next-line prefer-rest-params
        duplicateFbq.queue.push(arguments)
      }
    })

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-expect-error
    if (!window._fbq) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-expect-error
      window._fbq = duplicateFbq
    }
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-expect-error
    duplicateFbq.push = duplicateFbq
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-expect-error
    duplicateFbq.loaded = true
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-expect-error
    duplicateFbq.version = '2.0'
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-expect-error
    duplicateFbq.queue = []

    const script = document.createElement('script')
    script.async = true
    script.src = 'https://connect.facebook.net/en_US/fbevents.js'
    script.onload = this.handleOnLoad
    document.head.appendChild(script)

    const noscript = document.createElement('noscript')
    const img = document.createElement('img')
    img.height = FB_IMG_SIZE
    img.width = FB_IMG_SIZE
    img.src = `https://www.facebook.com/tr?id=${this.userId}&ev=PageView&noscript=1`
    noscript.appendChild(img)
    document.head.appendChild(noscript)
  }
}

import { l } from '@manychat/manyui'

import { ChannelType } from 'common/core/constants/ChannelType'
import { TriggerType } from 'common/flow/constants/TriggerType'
import { parseLightFlow } from 'common/flow/models/Flow/parseLightFlow'
import { CartReply, CartReplyTriggerMapped } from 'shared/api/requests/cartReply/schemas'

export const cartReplyAdapter = (item: CartReply): CartReplyTriggerMapped => {
  const { content_holder, enabled, type, flow } = item
  const flowId = flow?.ns ?? null

  return {
    id: content_holder,
    triggerType: TriggerType.CART_REPLY,
    triggerId: `${TriggerType.CART_REPLY}-wa`,
    enabled,
    ctr: null,
    runs: 0,
    type,
    ts_created: 0,
    caption: l.translate('Cart Reply'),
    channel: ChannelType.WHATSAPP,
    contentHolder: content_holder,
    chId: content_holder,
    flowId,
    clicked_unq: 0,
    content_holder: '',
    flow: flow ? parseLightFlow(flow) : null,
  }
}

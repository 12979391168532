import BaseBlockConfig from 'common/builder/blocks/BaseBlockConfig'
import { IBlockBase, IDefaultBlock } from 'common/builder/blocks/blockInterfaces'
import { BlockType } from 'common/builder/constants/BlockType'

class DefaultBlockConfig extends BaseBlockConfig {
  blockType = BlockType.DEFAULT

  getDefaults = (): Omit<IDefaultBlock, keyof IBlockBase> => ({
    targetId: null,
  })
}

export default new DefaultBlockConfig()

import { ZENDESK_HELP_CENTER_URL } from './constants'

export enum Forms {
  HELP_OPTIONS = 'HELP_OPTIONS',
  CANT_SIGN_IN = 'CANT_SIGN_IN',
  BILLING_ISSUES = 'BILLING_ISSUES',
  PRIVACY_AND_SECURITY = 'PRIVACY_AND_SECURITY',
  PRODUCT = 'PRODUCT',
}

interface QueryParam {
  name: string
  value: string
}

interface SupportTicketLink {
  path: string
  query?: QueryParam[]
}

type FormLinks = Record<Forms, SupportTicketLink>

const TicketFormMapper: FormLinks = {
  HELP_OPTIONS: {
    path: `/login`,
    query: [{ name: `return_to`, value: `${ZENDESK_HELP_CENTER_URL}/hc/p/get_support_page` }],
  },
  CANT_SIGN_IN: {
    path: `/hc/requests/new`,
    query: [{ name: `ticket_form_id`, value: `12734509953820` }],
  },
  BILLING_ISSUES: {
    path: `/hc/requests/new`,
    query: [{ name: `ticket_form_id`, value: `11962708022300` }],
  },
  PRIVACY_AND_SECURITY: {
    path: `/hc/requests/new`,
    query: [{ name: `ticket_form_id`, value: `13833780511772` }],
  },
  PRODUCT: {
    path: `/hc/requests/new`,
    query: [{ name: `ticket_form_id`, value: `11756694468892` }],
  },
}

const getSupportTicketLink = (ticket: Forms = Forms.HELP_OPTIONS) => {
  const link = TicketFormMapper[ticket]

  const url = new URL(ZENDESK_HELP_CENTER_URL)

  url.pathname = link.path

  if (link.query) {
    link.query.forEach((param) => {
      url.searchParams.append(param.name, param.value)
    })
  }

  return url.href
}

export const SupportTicketLink: Record<Forms, string> = Object.keys(TicketFormMapper).reduce(
  (acc: Record<Forms, string>, key) => {
    acc[key as Forms] = getSupportTicketLink(key as Forms)
    return acc
  },
  {} as Record<Forms, string>,
)

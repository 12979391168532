import React from 'react'

import { IconProps } from '../interfaces'

export const IncrementalIcon = ({ className }: IconProps) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="8"
      height="5"
      viewBox="0 0 8 5"
      className={className}
      aria-hidden="true"
    >
      <path d="M2.86863 1.13137C3.26465 0.735353 3.46265 0.537345 3.69098 0.463157C3.89183 0.397899 4.10817 0.397899 4.30902 0.463157C4.53735 0.537345 4.73535 0.735354 5.13137 1.13137L6.26863 2.26863C7.12538 3.12538 7.55376 3.55376 7.5827 3.92154C7.60782 4.24066 7.47864 4.55251 7.23523 4.76041C6.95471 5 6.34889 5 5.13726 5H2.86274C1.65111 5 1.04529 5 0.764766 4.76041C0.521356 4.55251 0.392181 4.24066 0.417296 3.92154C0.446241 3.55376 0.874618 3.12538 1.73137 2.26863L2.86863 1.13137Z" />
    </svg>
  )
}

import { z } from 'zod'

export enum AiAgentType {
  FLEXIBLE = 'flexible',
}

export enum FlexibleAgentStepType {
  CUSTOM = 'custom_goal',
  COLLECT_INFO = 'collect_info',
}

const AgentCustomStepSchema = z.object({
  id: z.string(),
  type: z.literal(FlexibleAgentStepType.CUSTOM),
  config: z.object({
    goal: z.string(),
  }),
})

const AgentCollectInfoStepSchema = z.object({
  id: z.string(),
  type: z.literal(FlexibleAgentStepType.COLLECT_INFO),
  config: z.object({
    question: z.string(),
    field_id: z.string(),
  }),
})

export const GenerateSchema = {
  request: z.object({
    agent: z.object({
      goal: z.string(),
      context: z.string(),
    }),
    session_id: z.string(),
    node_id: z.string(),
  }),
  query: z.undefined(),
  path: z.undefined(),
  response: z.unknown(),
}

export const VirtualFlexibleAgentSchema = z.object({
  type: z.literal(AiAgentType.FLEXIBLE),
  config: z.object({
    goal: z.string(),
    context: z.string(),
    scenario: z.array(z.union([AgentCustomStepSchema, AgentCollectInfoStepSchema])),
  }),
})

export type FlexibleAgentCustomStep = z.infer<typeof AgentCustomStepSchema>

export type FlexibleAgentCollectInfoStep = z.infer<typeof AgentCollectInfoStepSchema>

export type AnyFlexibleAgentScenarioStep = FlexibleAgentCustomStep | FlexibleAgentCollectInfoStep

export type VirtualFlexibleAgent = z.infer<typeof VirtualFlexibleAgentSchema>

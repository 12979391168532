import React from 'react'
import cx from 'classnames'

import { CustomFieldIconByType } from 'common/audience/components/CUFToSFModal/Step/Initial/Initial'
import { ReasonTypes } from 'common/oneTimeNotify/constants/notifyReasonConstants'

import cm from './MappingField.module.css'

interface MappingFieldProps {
  label: string
  description?: string
  type?: string
  frequency?: ReasonTypes
}

export const MappingField = ({ label, description, type, frequency }: MappingFieldProps) => {
  const CustomFieldIcon = type ? CustomFieldIconByType[type] : null
  return (
    <div
      className={cx(cm.field, 'rounded row middle p-l-xs')}
      data-title={description || undefined}
    >
      {CustomFieldIcon && (
        <div className="col-auto m-r-xxs d-flex">
          <CustomFieldIcon />
        </div>
      )}
      <div className={cx('text-ellipsis', cm.fieldLabel)}>{label}</div>

      {frequency && (
        <div className="m-l-auto text-capitalize p-r-xs text-secondary">{frequency}</div>
      )}
    </div>
  )
}

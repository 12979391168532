import { ChannelStatus } from 'common/core/interfaces/channelStatuses'

export interface IInstagramState {
  accounts: IInstagramAccount[] | null
  accountsFetching: boolean
  toggleChannelFetching: boolean
  instagramPostsFetching: boolean
}

export interface IInstagramAccount {
  userName: string
  id: string
  profilePicture: string
  followersCount: number
}

export interface IInstagramChannel {
  status: ChannelStatus
  is_channel_connected: boolean
  ts_channel_connected: string | null
  icebreakers_instagram_enabled: boolean
  show_telegram_banner_for_ig_accounts: boolean
  is_ig_summer_promo_activated: boolean
  is_channel_enabled: boolean
  business_id: number
  username: string
  avatar: string
  can_use_ig_auto_reply: boolean
  ig_rn_broadcast_onboarding_banner_active: boolean
  is_new_ig_api: boolean
  is_ig_page_unlinked: boolean
}

export enum INSTAGRAM_POST_MEDIA_TYPE {
  VIDEO = 'VIDEO',
  IMAGE = 'IMAGE',
  CAROUSEL_ALBUM = 'CAROUSEL_ALBUM',
}

export enum INSTAGRAM_POST_MEDIA_PRODUCT_TYPE {
  AD = 'AD',
  FEED = 'FEED',
  IGTV = 'IGTV',
  STORY = 'STORY',
  REELS = 'REELS',
}

export enum StorySelectType {
  ALL = 'all',
  SPECIFIC = 'specific',
}

export type IInstagramStory = IInstagramImageStory | IInstagramVideoStory

interface IInstagramBasicStory {
  id: string
  media_url: string
  permalink: string
  timestamp: string
}

interface IInstagramImageStory extends IInstagramBasicStory {
  media_type: INSTAGRAM_POST_MEDIA_TYPE.IMAGE
}

interface IInstagramVideoStory extends IInstagramBasicStory {
  media_type: INSTAGRAM_POST_MEDIA_TYPE.VIDEO
  thumbnail_url: string
}

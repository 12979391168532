import React from 'react'
import { z } from 'zod'

import { BlockType } from '../../constants'
import { registry } from '../../registry'
import { ClassNameSchema, StylesSchema } from '../../schemas'
import { BlockProps } from '../../types'

const ImagePropsSchema = z.object({
  src: z.string(),
  className: ClassNameSchema,
  style: StylesSchema,
})

const Component = ({ blockId, className, src, style }: BlockProps<typeof ImagePropsSchema>) => (
  <img data-block={blockId} className={className as string} src={src} style={style} />
)

export const Image = registry.register(Component, {
  props: ImagePropsSchema,
  type: BlockType.IMAGE,
  previewProps: {
    src: 'https://media.istockphoto.com/id/2028169571/de/foto/eine-leinwand-aus-rosa-und-goldenen-cirruswolken-bei-sonnenuntergang.jpg?s=1024x1024&w=is&k=20&c=uY7StANVYhjB7LiM8e2wXS7TLZrgZI26xxKfzp_9ee0=',
    style: {
      width: 300,
      height: 'auto',
    },
  },
})

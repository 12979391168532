import React, { useMemo } from 'react'
import cn from 'classnames'
import { z } from 'zod'
import { BtnV2, Modal } from '@manychat/manyui'

import { BlockType } from '../../constants'
import { useEditMode } from '../../context'
import { useDefaultContext, useMediaQuery } from '../../hooks'
import { registry } from '../../registry'
import { ChildrenSchema, ClassNameSchema } from '../../schemas'
import { BlockProps } from '../../types'

import cm from './ModalView.module.css'

const ModalViewPropsSchema = z.object({
  className: ClassNameSchema,
  children: ChildrenSchema,
  width: z.number().optional(),
  showCloseBtn: z.boolean().optional(),
})

const Component = ({
  blockId,
  className = 'p-x-lg p-y-lg',
  children,
  width = 1151,
  showCloseBtn,
}: BlockProps<typeof ModalViewPropsSchema>) => {
  const { close } = useDefaultContext()
  const isMobileWidth = useMediaQuery('(max-width: 833px)')
  const showInnerCloseBtn = isMobileWidth && showCloseBtn
  const isEditMode = useEditMode()
  const allowPropagationEvents = useMemo(() => (isEditMode ? ['onClick'] : []), [isEditMode])

  return (
    <Modal
      className={cn(cm.modal, className)}
      overlayClassName={cn(isEditMode && cm.edit)}
      noContentPadding
      width={width}
      open
      onRequestClose={close}
      disableCloseButton={showInnerCloseBtn}
      data-block={blockId}
      allowPropagationEvents={allowPropagationEvents}
    >
      {children}

      {showInnerCloseBtn && (
        <BtnV2
          onlyIcon="Close"
          size="large"
          className={cm.closeBtn}
          onClick={() => close()}
          data-test-id="close"
        />
      )}
    </Modal>
  )
}

export const ModalView = registry.register(Component, {
  props: ModalViewPropsSchema,
  type: BlockType.MODAL_VIEW,
  previewProps: {
    children: [],
  },
})

import { useCallback } from 'react'
import { useAppDispatch } from 'reduxTyped'

import { CmsAnalytics } from 'apps/cms/lib/analytics'
import { contentManagementActions } from 'apps/cms/store'

export const useMoveActions = ({ targetFolderPath }: { targetFolderPath: string }) => {
  const dispatch = useAppDispatch()

  const moveFolderToTargetFolder = useCallback(
    (path: string) => {
      dispatch(
        contentManagementActions.moveFolder({
          path: path,
          pathToMove: targetFolderPath,
        }),
      )
      CmsAnalytics.logMoveFolderDnD()
    },
    [dispatch, targetFolderPath],
  )

  const moveFlowsToTargetFolder = useCallback(
    (flowPaths: string[]) => {
      if (flowPaths.length === 1) {
        dispatch(
          contentManagementActions.moveFlow({ path: flowPaths[0], pathToMove: targetFolderPath }),
        )
        CmsAnalytics.logMoveFlowDnD()
      } else {
        dispatch(contentManagementActions.bulkMoveTo({ pathMoveTo: targetFolderPath }))
        CmsAnalytics.logBulkMoveFlowsDnD({ count: flowPaths.length })
      }
    },
    [dispatch, targetFolderPath],
  )

  return {
    moveFolderToTargetFolder,
    moveFlowsToTargetFolder,
  }
}

import { l } from '@manychat/manyui'

import { MakeTranslate } from '../../../../i18n/format/types'

export enum BulkTypes {
  DELETE = 'move_to_trash',
  PERMANENTLY_DELETE = 'permanently_delete',
  RESTORE = 'restore',
}

export const BulkOperation: Record<string, MakeTranslate<string>> = {
  [BulkTypes.DELETE]: l.makeTranslate('deleted'),
  [BulkTypes.RESTORE]: l.makeTranslate('restored'),
  [BulkTypes.PERMANENTLY_DELETE]: l.makeTranslate('permanently deleted'),
}

import React from 'react'

import { Field } from 'common/fields/entities/field'
import { FieldList, FieldListProps } from 'common/fields/linking/components/FieldList'
import FieldPickerInput from 'common/fields/linking/components/FieldPickerInput'
import {
  FieldSelectEvent,
  FieldClearEvent,
} from 'common/fields/linking/components/interfaces/fieldEvents'
import { useCreatedGlobalFieldLinking } from 'common/fields/linking/hooks/useCreatedGlobalFieldLinking'
import { useCreatedTagLinking } from 'common/fields/linking/hooks/useCreatedTagLinking'
import { usePickerStyle } from 'common/fields/linking/hooks/usePickerStyle'
import { TagCreationSource } from 'common/tags/store/types'
import { useAutocomplete } from 'components/AutocompleteElements/hooks/useAutocomplete'
import { useFocus } from 'components/AutocompleteElements/hooks/useFocus'
import InputPopover from 'components/AutocompleteElements/InputPopover'
import { AllowNewField } from 'components/AutocompleteElements/OptionsList/OptionListConfig'
import { useForwardedRef } from 'utils/commonHooks'

import { getField, getFieldLabel } from './utils'

export type FieldPickerProps = {
  value: string
  invalid?: boolean
  minHeight?: number
  maxHeight?: number
  onClear?: (event: FieldClearEvent) => void
  onSelect: (event: FieldSelectEvent) => void
  wrapperClassName?: string
  showIcon?: boolean
  allowNew?: AllowNewField
  ariaLabel: string
  helpText?: string
  iconClass?: string
} & Omit<
  React.HTMLProps<HTMLInputElement>,
  'onSelect' | 'onChange' | 'value' | 'inputRef' | 'label' | 'defaultValue'
> &
  Pick<FieldListProps, 'allowedTypes' | 'fields' | 'optionsEnhancer'>

type ChangeEvent = React.ChangeEvent<HTMLInputElement>

const FieldPicker: React.ForwardRefRenderFunction<HTMLInputElement, FieldPickerProps> = (
  {
    allowedTypes,
    allowNew,
    fields,
    invalid,
    name,
    maxHeight,
    minHeight,
    onSelect,
    value,
    onClear,
    onFocus,
    onBlur,
    optionsEnhancer,
    showIcon,
    ariaLabel,
    helpText,
    ...inputProps
  },
  ref,
) => {
  const fieldsRef = React.useRef(fields)
  const [open, setOpen] = React.useState(false)
  const [inputRef, inputRefHandler] = useForwardedRef(ref)
  const [focus, handleFocus, handleBlur] = useFocus(onFocus, onBlur)
  const field = React.useMemo(() => getField(fields, value), [fields, value])
  const { query, text, setText } = useAutocomplete(getFieldLabel(field), focus)
  const { hidePickerCategories } = usePickerStyle(fields)

  React.useEffect(() => setOpen(focus), [focus, query])

  // Ref used to provide actual fields in select callback after new field created in modal
  fieldsRef.current = fields

  const handleSelect = React.useCallback(
    (id: string, createdField?: Field) => {
      const field = getField(fieldsRef.current, id) ?? createdField
      inputRef.current?.blur()
      if (field) {
        onSelect({ target: { value: id, field, name } })
        setText(getFieldLabel(field))
      }
    },
    [inputRef, name, onSelect, setText],
  )

  const { globalFieldModalSource } = useCreatedGlobalFieldLinking({
    onFieldCreate: handleSelect,
  })

  const { tagModalSource } = useCreatedTagLinking({
    creationSource: TagCreationSource.TAGS_PICKER,
    onTagCreate: handleSelect,
  })

  return (
    <>
      <FieldPickerInput
        {...inputProps}
        ref={inputRefHandler}
        fieldType={field?.type}
        invalid={invalid}
        name={name}
        onBlur={handleBlur}
        onChange={(e: ChangeEvent) => setText(e.target.value)}
        onClear={value ? onClear : undefined}
        onFocus={handleFocus}
        value={text}
        showIcon={showIcon}
        ariaLabel={ariaLabel}
        helpText={helpText}
      />
      <InputPopover input={inputRef.current} open={open} useInputWidth={hidePickerCategories}>
        <FieldList
          allowedTypes={allowedTypes}
          allowNew={allowNew}
          fields={fields}
          onSelect={handleSelect}
          query={query}
          target={inputRef.current}
          optionsEnhancer={optionsEnhancer}
          minHeight={minHeight}
          maxHeight={maxHeight}
          hideCategories={hidePickerCategories}
          showIcon={showIcon}
          createGlobalFieldSource={globalFieldModalSource}
          createTagSource={tagModalSource}
        />
      </InputPopover>
    </>
  )
}

export default React.forwardRef(FieldPicker)

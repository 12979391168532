import get from 'lodash/get'

import * as atypes from 'apps/ads/constants/AdsReduxActionTypes'

const initialState = {
  createModal: {
    // common
    type: null,

    // custom
    name: '',
    filter: null,
    accountId: '',

    // lookalike
    locations: [],
    ratio: 1,
    sourceId: null,

    // state
    isReady: false,
    isCreating: false,
  },
  byAccount: {
    // [accountId]: { list: [], loading: false },
  },
  countriesSearch: {},
  accountsByUserId: {
    // [userId]: {
    //    [accountId]: 'name',
    // }
  },
  customAudiencesByUserId: {
    // [userId]: {
    //    [caId]: 'name',
    // }
  },
  llaPagesByBusiness: {
    // [businessId]: { list: [], loading: false },
  },
}

export default function customAudienceReducer(state = initialState, action) {
  switch (action.type) {
    case atypes.CUSTOM_AUDIENCE_CREATE_MODAL_RESET: {
      return {
        ...state,
        createModal: { ...initialState.createModal, accountId: state.createModal.accountId },
      }
    }
    case atypes.CUSTOM_AUDIENCE_CREATE_MODAL_UPDATE: {
      return { ...state, createModal: { ...state.createModal, ...action.changes } }
    }
    case atypes.CUSTOM_AUDIENCE_CREATE: {
      return { ...state, createModal: { ...state.createModal, isCreating: true } }
    }
    case atypes.CUSTOM_AUDIENCE_CREATE_SUCCESS: {
      return { ...state, createModal: { ...state.createModal, isReady: true, isCreating: false } }
    }
    case atypes.CUSTOM_AUDIENCE_CHECK: {
      return { ...state, createModal: { ...state.createModal, isChecking: true } }
    }
    case atypes.CUSTOM_AUDIENCE_CHECK_SUCCESS: {
      return { ...state, createModal: { ...state.createModal, isChecking: false } }
    }
    case atypes.CUSTOM_AUDIENCE_CREATE_ERROR: {
      return { ...state, createModal: { ...state.createModal, isCreating: false } }
    }
    case atypes.CUSTOM_AUDIENCES_FETCH_REQUEST: {
      return {
        ...state,
        byAccount: {
          ...state.byAccount,
          [action.accountId]: {
            ...(state.byAccount[action.accountId] || {}),
            loading: true,
          },
        },
      }
    }
    case atypes.CUSTOM_AUDIENCES_FETCH_RESPONSE: {
      return {
        ...state,
        byAccount: {
          ...state.byAccount,
          [action.accountId]: {
            list: get(action, 'data.data', []),
            loading: false,
          },
        },
      }
    }
    case atypes.LLA_SOURCE_PAGES_FETCH_REQUEST: {
      return {
        ...state,
        llaPagesByBusiness: {
          ...state.llaPagesByBusiness,
          [action.businessId]: {
            ...(state.llaPagesByBusiness[action.businessId] || {}),
            loading: true,
          },
        },
      }
    }
    case atypes.LLA_SOURCE_PAGES_FETCH_RESPONSE: {
      return {
        ...state,
        llaPagesByBusiness: {
          ...state.llaPagesByBusiness,
          [action.businessId]: {
            list: get(action, 'data.pages', []),
            loading: false,
          },
        },
      }
    }

    case atypes.CUSTOM_AUDIENCE_SEARCH_COUNTRIES_RESPONSE: {
      state.countriesSearch = {
        ...state.countriesSearch,
        [`${action.query}`]: action.data.data,
      }
      return state
    }
    case atypes.CUSTOM_AUDIENCE_FETCH_USER_ACTION_NAMES_RESPONSE: {
      state.accountsByUserId = {
        ...state.accountsByUserId,
        [action.userId]: {
          ...(state.accountsByUserId[action.userId] || {}),
          [action.accountId]: action.data.ad_account_name,
        },
      }
      state.customAudiencesByUserId = {
        ...state.customAudiencesByUserId,
        [action.userId]: {
          ...(state.customAudiencesByUserId[action.userId] || {}),
          [action.customAudienceId]: action.data.custom_audience_name,
        },
      }
      return state
    }
  }

  return state
}

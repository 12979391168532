export enum WelcomeMessageSource {
  FLOW_BUILDER = 'FLOW_BUILDER',
  FAQ_WIZARD = 'FAQ_WIZARD',
  BILLING = 'BILLING',
}

export interface AiCommunicationsState {
  triggeredBySetLive: boolean
  triggeredByPurchase: boolean
  isWelcomeMessageVisible: boolean
  welcomeMessageSource: WelcomeMessageSource
}

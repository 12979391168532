import React from 'react'
import { l } from '@manychat/manyui'

export const BusinessAccountDeleted = () => {
  return (
    <span>
      {l.translate('WhatsApp channel disconnected due to deleted WhatsApp Business account')}
    </span>
  )
}

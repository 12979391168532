import { l } from '@manychat/manyui'

import { NodeType } from 'common/builder/constants/NodeType'
import BaseNodeConfig from 'common/builder/nodes/BaseNodeConfig'
import { INodeBase, IStartingStepNode } from 'common/builder/nodes/nodeInterfaces'

class StartingStepNodeConfig extends BaseNodeConfig<IStartingStepNode> {
  nodeType = NodeType.STARTING_STEP

  isStartingStep = true

  getCaption = () => l.translate('When...')
  color = '#E1FAEC'
  headerColor = '#E1FAEC'

  getDefaults = (): Omit<IStartingStepNode, keyof INodeBase> => ({
    onboardingElementId: 'starting_step',
  })
}

export default new StartingStepNodeConfig()

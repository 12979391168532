import { createReducer } from '@reduxjs/toolkit'

import { IButton, IButtonImage } from 'common/builder/buttons/buttonInterfaces'
import * as ActionTypes from 'common/builder/constants/builderReduxActionTypes'
import * as commonATypes from 'common/core/constants/ReduxActionTypes'

interface ButtonsState {
  byId: Record<string, IButton>
}

const initialState: ButtonsState = { byId: {} }

interface CreateButtonAction {
  type: typeof ActionTypes.CREATE_BUTTON
  buiderId: string
  item: IButton
  blockId?: string
}

interface RemoveButtonAction {
  type: typeof ActionTypes.REMOVE_BUTTON
  buttonId: string
}

interface InstallBuilderSnapshotAction {
  type: typeof ActionTypes.INSTALL_BUILDER_SNAPSHOT
  targetSnapshot: {
    buttons: ButtonsState
  }
}

interface UpdateButtonAction {
  type: typeof ActionTypes.UPDATE_BUTTON
  buttonId: string
  changes: Partial<IButton>
}

interface ContentParsedAction {
  type: typeof ActionTypes.CONTENT_PARSED
  parsed: {
    buttons: IButton[]
  }
}

interface AttachmentUploadResponseAction {
  type: typeof commonATypes.ATTACHMENT_UPLOAD_RESPONSE
  buttonId: string
  data: {
    attachment: IButtonImage
  }
  uploadId: string
}

interface AttachmentUploadErrorAction {
  type: typeof commonATypes.ATTACHMENT_UPLOAD_ERROR
  buttonId: string
  uploadId: string
}

const buttonsReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(ActionTypes.CREATE_BUTTON, (state: ButtonsState, action: CreateButtonAction) => {
      state.byId[action.item.id] = action.item
    })
    .addCase(ActionTypes.REMOVE_BUTTON, (state: ButtonsState, action: RemoveButtonAction) => {
      delete state.byId[action.buttonId]
    })
    .addCase(
      ActionTypes.INSTALL_BUILDER_SNAPSHOT,
      (state: ButtonsState, action: InstallBuilderSnapshotAction) => {
        state.byId = action.targetSnapshot.buttons.byId
      },
    )
    .addCase(ActionTypes.UPDATE_BUTTON, (state: ButtonsState, action: UpdateButtonAction) => {
      const button = state.byId[action.buttonId]
      if (button) {
        Object.assign(button, action.changes)
      }
    })
    .addCase(ActionTypes.CONTENT_PARSED, (state: ButtonsState, action: ContentParsedAction) => {
      action.parsed.buttons.forEach((button) => {
        state.byId[button.id] = button
      })
    })
    .addCase(
      commonATypes.ATTACHMENT_UPLOAD_RESPONSE,
      (state: ButtonsState, action: AttachmentUploadResponseAction) => {
        const { buttonId, data, uploadId } = action
        setButtonImage(state.byId[buttonId], uploadId, data.attachment)
      },
    )
    .addCase(
      commonATypes.ATTACHMENT_UPLOAD_ERROR,
      (state: ButtonsState, action: AttachmentUploadErrorAction) => {
        const { buttonId, uploadId } = action
        setButtonImage(state.byId[buttonId], uploadId, null)
      },
    )
    .addCase(ActionTypes.CLEAR_BUILDER_AND_RESET, (state) => {
      state.byId = {}
    })
})

export default buttonsReducer

function setButtonImage(button: IButton | undefined, uploadId: string, image: IButtonImage) {
  if (!button || !('image' in button) || !button.image) {
    return
  }
  if ('uploadId' in button.image && button.image.uploadId === uploadId) {
    button.image = image
  }
}

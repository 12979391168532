import React, { ReactNode, useMemo } from 'react'
import { useAppSelector } from 'reduxTyped'

import { billing } from 'common/billing/billingService/billingService'
import { BillingContext, BillingContextValue } from 'common/billing/contexts/BillingContext'
import { getCurrentAccountID } from 'common/core/selectors/appSelectors'

import { BillingModals } from './BillingModals'

interface BillingProviderProps {
  children?: ReactNode | undefined
}

export const BillingProvider = ({ children }: BillingProviderProps) => {
  const billingContextValue: BillingContextValue = useMemo(() => ({ billing }), [])
  const accountId = useAppSelector(getCurrentAccountID)

  return (
    <BillingContext.Provider value={billingContextValue}>
      {/* User is in an account scope check */}
      {accountId && <BillingModals />}

      {children}
    </BillingContext.Provider>
  )
}

import { FeedCommentTriggerWidget } from 'apps/growthTools/growthToolsInterfaces'
import { BaseWidget } from 'apps/growthTools/models/Widget/baseWidget'
import { PostCoveredArea, WidgetTypes } from 'apps/growthTools/models/Widget/constants'
import { TriggerType } from 'common/flow/constants/TriggerType'
import { ITriggerId } from 'common/flow/flowTriggersInterfaces'
import { ProtoCgt } from 'shared/api/requests/aiAssistant/schemas'

export const toFeedCommentWidget = (trigger: ProtoCgt): FeedCommentTriggerWidget => {
  const id = 3
  const triggerId: ITriggerId = `${TriggerType.WIDGET}-${id}`

  return {
    ...BaseWidget,
    widget_id: id,
    widget_type: WidgetTypes.FEED_COMMENT_TRIGGER,
    channel: trigger.channel,
    data: {
      feed_comment_settings: {
        all_post_ids: [],
        post_id: '',
        post_covered_area: PostCoveredArea.ALL_POSTS,
        track_root_comment_only: false,
        exclude_keywords_array: [],
        include_keywords_array: [],
      },
    },
    id,
    triggerType: TriggerType.WIDGET,
    triggerId: triggerId,
    caption: trigger.caption || '',
  }
}

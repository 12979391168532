import React from 'react'
import cx from 'classnames'
import { Highlight } from '@manychat/manyui'

import { EMPTY_KEY } from './constants'

import cm from './Autocomplete.module.css'

export default class Item extends React.Component {
  shouldComponentUpdate(nextProps) {
    const optLabel =
      this.props.opt._displayLabel || this.props.opt[this.props.labelKey] || this.props.opt
    const nextOptLabel =
      nextProps.opt._displayLabel || nextProps.opt[nextProps.labelKey] || nextProps.opt

    return (
      nextProps.q !== this.props.q ||
      nextProps.focused !== this.props.focused ||
      nextProps.index !== this.props.index ||
      nextProps._refreshKey !== this.props._refreshKey ||
      nextOptLabel !== optLabel
    )
  }

  handleItemMouseDown = (e) => e.preventDefault()

  render() {
    const { renderItem, Item, labelKey, theme, q, opt, focused, options, index, valueKey } =
      this.props

    let item = null

    if (renderItem) {
      item = renderItem(opt, index, focused, this.props, options)
    } else if (Item) {
      item = <Item {...{ opt, index, focused, options }} />
    } else if (opt._displayLabel || opt[labelKey]) {
      item = (
        <Highlight matchClass={theme.highlight || ''} search={q || ''} className={opt.className}>
          {opt._displayLabel || opt[labelKey]}
        </Highlight>
      )
    } else if (React.isValidElement(opt)) {
      item = (
        <Highlight matchClass={theme.highlight || ''} search={q || ''} className={opt.className}>
          {opt}
        </Highlight>
      )
    } else {
      return null
    }

    const focusedClassName = theme.focused || cm.focused
    const emptyClassName = theme.empty || cm.empty

    return (
      <div
        className={cx('p-x p-y-xs dropdown-item', cm.dropdownItem, theme.item, {
          [focusedClassName]: focused === index,
          [cm.dropdownItemNative]: focused === null,
          [emptyClassName]: opt[valueKey] === EMPTY_KEY,
        })}
        onMouseDown={this.handleItemMouseDown}
        onClick={this.props.onSelect}
        onMouseOver={this.props.onHover}
        key={index}
        data-index={index}
      >
        {item}
      </div>
    )
  }
}

import React from 'react'
import cx from 'classnames'
import { Modal, l, BtnV2, ToggleV2, sanitizeURL, CheckboxV2, NumberInputV2 } from '@manychat/manyui'

import { useShareFlow } from './hooks/useShareFlow'
import {
  MAX_EMBED_WIDTH,
  MIN_EMBED_HEIGHT,
  MAX_EMBED_HEIGHT,
  MIN_EMBED_WIDTH,
} from './lib/constants'

import cm from './ShareFlowModal.module.css'

const numberInputProps = {
  maxSymbols: 4,
  showArrows: true,
}

export const ShareFlowModal = () => {
  const {
    sharingSettings,
    embedWidth,
    embedHeight,
    isLinkCopied,
    isCodeCopied,
    updateCloneSettings,
    downloadAsImage,
    getEmbedCode,
    updateSharedSettings,
    copyLink,
    copyCode,
    updateEmbedWidth,
    updateEmbedHeight,
    closeModal,
  } = useShareFlow()

  return (
    <Modal
      width={460}
      open
      title={<div className="p-y-xs">{l.translate('Share Automation')}</div>}
      onRequestClose={closeModal}
      noContentPadding
    >
      <div className="p-x-md p-y">
        <div className="text-subheading">{l.translate('Export as PNG')}</div>

        <BtnV2
          variant="primary"
          fullWidth
          size="large"
          onClick={downloadAsImage}
          className="m-t-xs"
        >
          {l.translate('Download PNG')}
        </BtnV2>
      </div>

      <div className={cx({ [cm.dark]: !sharingSettings.is_shared }, 'p-a-md b-a')}>
        <div className="m-b text-subheading">{l.translate('Link Sharing & Embedding')}</div>

        <ToggleV2
          label={l.translate('Enable Sharing')}
          value={sharingSettings.is_shared}
          checked={sharingSettings.is_shared}
          onChange={updateSharedSettings}
        />
      </div>

      {sharingSettings.is_shared && (
        <div className="p-x-md p-b-md">
          <div>
            <label className="form-label">{l.translate('Link')}</label>

            <a
              className={cx('text-primary d-block', cm.link)}
              target="_blank"
              href={sanitizeURL(sharingSettings.player_page_url ?? '')}
              rel="noreferrer"
            >
              {sharingSettings.player_page_url}
            </a>

            <a
              onClick={copyLink}
              className={cx(
                cm.copyLink,
                'text-subheading m-t-xs m-b-md',
                isLinkCopied ? 'text-success' : 'text-primary',
              )}
            >
              {isLinkCopied ? l.translate('Copied!') : l.translate('Copy to Clipboard')}
            </a>

            <label className="form-label">{l.translate('Embed Code')}</label>

            <div className={cm.inputs}>
              <NumberInputV2
                value={embedWidth}
                min={MIN_EMBED_WIDTH}
                max={MAX_EMBED_WIDTH}
                onChange={updateEmbedWidth}
                invalid={embedWidth > MAX_EMBED_WIDTH || embedWidth < MIN_EMBED_WIDTH}
                ariaLabel={l.translate('Embed Width')}
                upAriaLabel={l.translate('Increase embed width')}
                downAriaLabel={l.translate('Decrease embed width')}
                {...numberInputProps}
              />

              <div>X</div>

              <NumberInputV2
                value={embedHeight}
                min={MIN_EMBED_HEIGHT}
                max={MAX_EMBED_HEIGHT}
                onChange={updateEmbedHeight}
                invalid={embedHeight > MAX_EMBED_HEIGHT || embedHeight < MIN_EMBED_HEIGHT}
                ariaLabel={l.translate('Embed Height')}
                upAriaLabel={l.translate('Increase embed height')}
                downAriaLabel={l.translate('Decrease embed height')}
                {...numberInputProps}
              />
            </div>

            <div className={cx(cm.code, 'm-t m-b-md p-a-xs')}>
              <div>{getEmbedCode()}</div>

              <a
                onClick={copyCode}
                className={cx(
                  cm.copyLink,
                  'text-subheading',
                  isCodeCopied ? 'text-success' : 'text-primary',
                )}
              >
                {isCodeCopied ? l.translate('Copied!') : l.translate('Copy to Clipboard')}
              </a>
            </div>

            <CheckboxV2
              onChange={updateCloneSettings}
              label={l.translate('Allow viewers to clone this Automation')}
              checked={sharingSettings.shared_cloning_enabled ?? false}
            />
          </div>
        </div>
      )}
    </Modal>
  )
}

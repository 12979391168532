import { getWindow } from '../../../../utils'

const handlersMap = {
  click: [],
  esckeydown: [],
}
let mousedownEvent = null

let beforeClickHandler = null
const setBeforeClickHandler = (handler) => {
  beforeClickHandler = handler
}

const getHandlers = (event) => {
  const handlers = handlersMap[event]
  if (!handlers) {
    throw new Error('layers manager error: invalid event')
  }
  return handlers
}

const addEventListener = (event, handler) => {
  const handlers = getHandlers(event)
  if (handlers.some((h) => h === handler)) {
    return
  }

  handlers.unshift(handler)
}

const removeEventListener = (event, handler) => {
  const handlers = getHandlers(event)

  let currentIndex = handlers.findIndex((h) => h === handler)
  if (currentIndex >= 0) {
    handlers.splice(currentIndex, 1)
  }
}

const mainClickHandler = (e) => {
  const clickHandlers = getHandlers('click')
  const handlersCopy = clickHandlers.slice()

  let stopped = false
  e.stopPropagationToLayers = () => (stopped = true)

  if (beforeClickHandler) {
    beforeClickHandler(e)

    if (stopped) {
      return
    }
  }

  // eslint-disable-next-line no-unused-vars
  for (let h of handlersCopy) {
    if (clickHandlers.indexOf(h) >= 0) {
      h(e, mousedownEvent)
    }
    if (stopped) {
      return
    }
  }
}

const mousedownHandler = (e) => {
  mousedownEvent = e
}

const touchStartHandler = (e) => {
  // Ensure that mousedownEvent won't be null on touch screens
  mousedownHandler(e)
  mainClickHandler(e)
}

const escHandler = (e) => {
  const escHandlers = getHandlers('esckeydown')
  const handlersCopy = escHandlers.slice()

  let stopped = false
  e.stopPropagationToLayers = () => (stopped = true)
  // eslint-disable-next-line no-unused-vars
  for (let h of handlersCopy) {
    if (escHandlers.indexOf(h) >= 0) {
      h(e)
    }
    if (stopped) {
      return
    }
  }
}

const window = getWindow()

window.addEventListener('touchstart', touchStartHandler, true)
window.addEventListener('click', mainClickHandler, true)
window.addEventListener('mousedown', mousedownHandler, true)

window.addEventListener(
  'keydown',
  (e) => {
    if (e.key === 'Escape' || e.keyCode === 27) {
      escHandler(e)
    }
  },
  true,
)

export default {
  addEventListener,
  removeEventListener,
  setBeforeClickHandler,
}

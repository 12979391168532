import React from 'react'
import cx from 'classnames'

import cleanProps from '../../../lib/clean-react-props'
import { deprecatedDecorator } from '../../../utils/deprecated-decorator'
import Loader from '../../Loader'
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import ToggleV2 from '../ToggleV2'

import cm from './Toggle.module.css'

export interface ToggleProps {
  label?: string | React.ReactNode
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void
  /**
   * It will control active state if it was passed as a boolean type and "checked" property was left undefined.
   * Needed for backward compatibility with Toggle usage specifics in app.
   */
  value?: string | number | boolean
  id?: string
  checked?: boolean
  name?: string
  invalid?: boolean
  disabled?: boolean
  loader?: boolean
  /** Show ON/OFF labels on Toggle itself. */
  showOnOff?: boolean
  leftLabel?: string | React.ReactNode
  'data-test-id'?: string
  md?: boolean
  /** CSS class for <label> used as wrapper. */
  className?: string
  /** CSS styles for <label> used as wrapper. */
  style?: React.CSSProperties
  /** CSS classes for internal elements: wrapper, checkbox, loader, label, leftLabel. */
  theme?: {
    wrapper?: string
    leftLabel?: string
    checkbox?: string
    loader?: string
    label?: string
  }
}

/** Toggles are used when you need to provide ON/OFF option to user. */
/** @deprecated use {@link ToggleV2} component */
const Toggle = (props: ToggleProps) => {
  const {
    checked = props.value !== undefined && Boolean(props.value),
    disabled,
    invalid,
    loader,
    md,
    showOnOff,
    label,
    leftLabel,
    className,
    style,
    theme = {},
    ...other
  } = props

  return (
    <label
      className={cx(cm.wrapper, 'row middle nowrap', className, md && cm.md, theme.wrapper)}
      style={style}
    >
      {leftLabel && (
        <span className={cx(cm.label, 'm-r-xs text-body', theme.leftLabel)}>{leftLabel}</span>
      )}
      <input
        type="checkbox"
        checked={checked}
        disabled={disabled || loader}
        {...cleanProps(other, [], [])}
        className={cx(cm.checkbox, 'p-absolute', theme.checkbox)}
      />
      <span className={cx(cm.toggle, invalid && cm.invalid, 'p-relative d-inline-block')}>
        {showOnOff && (checked ? 'ON' : 'OFF')}
        {loader && <Loader center className={cx(cm.loader, theme.loader)} />}
      </span>
      {label && <span className={cx(cm.label, 'm-l-xs text-body', theme.label)}>{label}</span>}
    </label>
  )
}

/** @deprecated use {@link ToggleV2} component */
export default deprecatedDecorator(Toggle, { replacementComponentName: 'ToggleV2' })

import { WidgetTypeNumeric, WidgetTypes } from 'apps/growthTools/models/Widget/constants'
import { ChannelType } from 'common/core/constants/ChannelType'
import { FsTriggerModel } from 'shared/api/requests/cms/schemas'

import { triggerType, isWidget, isIntent } from './triggerType'
import { FiltersFromQuery } from './types'

export const isTtChannel = (channel?: ChannelType | null) => channel === ChannelType.TIKTOK

export const isTtKeyword = (query: FiltersFromQuery) =>
  isTtChannel(query.channel) && triggerType(query.trigger_model) && !query.widget_type

export const isTtIntent = (query: FiltersFromQuery) =>
  isTtChannel(query.channel) && isIntent(query.trigger_model) && !query.widget_type

export const isTtRefUrl = (query: FiltersFromQuery) =>
  isTtChannel(query.channel) &&
  isWidget(query.trigger_model) &&
  query.widget_type === WidgetTypeNumeric.MESSENGER_REF_URL

export const isAllTtTriggers = (query: FiltersFromQuery) =>
  isTtChannel(query.channel) && !query.trigger_model && !query.widget_type

export const isTriggerMatchesTtFilter = ({
  widgetType,
  filter,
  triggerModel,
  triggerChannel,
}: {
  widgetType: WidgetTypes | null
  filter: FiltersFromQuery
  triggerModel: FsTriggerModel
  triggerChannel: ChannelType | null
}) =>
  triggerChannel === ChannelType.TIKTOK &&
  (isAllTtTriggers(filter) ||
    (triggerModel === FsTriggerModel.KEYWORD && isTtKeyword(filter)) ||
    (triggerModel === FsTriggerModel.INTENT && isTtIntent(filter)) ||
    (widgetType === WidgetTypes.MESSENGER_REF_URL && isTtRefUrl(filter)))

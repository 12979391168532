import { useMemo } from 'react'
import { useAppSelector } from 'reduxTyped'
import { l } from '@manychat/manyui'

import {
  BREADCRUMBS_ROOT_NAME,
  EMPTY_SEGMENT_LABEL,
} from 'apps/cms/components/Flows/components/CmsBreadcrumbs/lib/constants'
import { CmsBreadcrumbsSegment } from 'apps/cms/components/Flows/components/CmsBreadcrumbs/lib/types'
import { getPathItems } from 'apps/cms/lib/query'
import { contentManagementSelectors } from 'apps/cms/store'
import { FSSmartFolders } from 'common/cms/models/FSObject/constants'
import { usePermission, PermissionTargets } from 'common/userRoles'
import { linkURL } from 'utils/url'

export const useSegments = () => {
  const userCanEditFlow = usePermission(PermissionTargets.FLOW_EDITING)

  const path = useAppSelector(contentManagementSelectors.getQueryPath)
  const allFolders = useAppSelector(contentManagementSelectors.getFolders)
  const { customRootName } = useAppSelector(contentManagementSelectors.getUi)
  const isTrashFolder = useAppSelector(contentManagementSelectors.getIsTrashFolder)

  const segments = useMemo(() => {
    let currentPath = ''

    const result: CmsBreadcrumbsSegment[] = [
      {
        label: customRootName ?? BREADCRUMBS_ROOT_NAME,
        fsQueryPath: '/',
        path: linkURL('/cms?path=/'),
        isEditable: false,
      },
    ]

    if (isTrashFolder) {
      result.push({
        label: l.translate('Trash'),
        fsQueryPath: FSSmartFolders.TRASH,
        path: linkURL(`/cms?path=${FSSmartFolders.TRASH}`),
        isEditable: false,
      })

      return result
    }

    getPathItems(path).forEach((folderId, index, { length }) => {
      const folder = allFolders.find((folder) => folder.folder_id === Number(folderId))

      currentPath += `/${folderId}`

      result.push({
        label: folder?.title ?? EMPTY_SEGMENT_LABEL,
        path: linkURL(`/cms?path=${currentPath}`),
        fsQueryPath: currentPath,
        isEditable: userCanEditFlow && index === length - 1,
      })
    })

    return result
  }, [allFolders, customRootName, isTrashFolder, path, userCanEditFlow])

  return segments
}

import getSymbolFromCurrency from 'currency-symbol-map'
import get from 'lodash/get'

// here value is a string and already has the offset applied, for example "4.5" or "540"
export const formatFacebookPrice = (value, curr) => {
  const currency = get(curr, 'currency') || 'USD'
  const offset = get(curr, 'offset') || 100

  const parsedValue = parseFloat(value)
  if (!isFinite(parsedValue)) {
    return ''
  }

  if (typeof Intl === 'object' && typeof Intl.NumberFormat === 'function') {
    const formatter = new Intl.NumberFormat(undefined, {
      style: 'currency',
      currency,
      minimumFractionDigits: Number(offset) === 100 ? 2 : 0,
    })
    return formatter.format(parsedValue)
  }
  return `${getSymbolFromCurrency(currency) || ''}${formatPriceBasedOffset(parsedValue, curr)}`
}

export const formatPriceBasedOffset = (value, curr) => {
  const offset = get(curr, 'offset') || 100
  if (offset === 100) {
    return isFinite(parseInt(value)) ? value.toFixed(2) : 0
  }
  return value
}

import z from 'zod'
import { l } from '@manychat/manyui'

import { AiAgentType, FlexibleAgentStepType } from 'shared/api/requests/aiAgent/schemas'

export const FlexAgentScenarioRules = {
  minCount: 1,
  maxCount: 10,
}

const GOAL_MAX_LENGTH = 512
const TASK_MAX_LENGTH = 512
const CONTEXT_MAX_LENGTH = 10000

const taskSchema = z
  .string()
  .min(1, l.translate('Enter a task to save'))
  .max(
    TASK_MAX_LENGTH,
    l.translate('Task must be {count} characters or fewer', { count: TASK_MAX_LENGTH }),
  )

const AgentCustomStepSchema = z.object({
  id: z.string(),
  type: z.literal(FlexibleAgentStepType.CUSTOM),
  config: z.object({
    goal: taskSchema,
  }),
})

const getAgentCollectInfoStepSchema = (fields: string[]) =>
  z.object({
    id: z.string(),
    type: z.literal(FlexibleAgentStepType.COLLECT_INFO),
    config: z.object({
      question: taskSchema,
      field_id: z
        .string()
        .min(1, l.translate('Select user field to save'))
        .refine((value) => fields.includes(value), l.translate('Invalid variable')),
    }),
  })

const getAgentScenarioStepValdiationSchema = (fields: string[]) =>
  z.union([AgentCustomStepSchema, getAgentCollectInfoStepSchema(fields)])

export const getAgentConfigValidationSchema = (fields: string[]) =>
  z.object({
    goal: z
      .string()
      .min(1, l.translate('Enter a goal to save'))
      .max(
        GOAL_MAX_LENGTH,
        l.translate('Goal must be {count} characters or fewer', { count: GOAL_MAX_LENGTH }),
      ),
    context: z
      .string()
      .min(1, l.translate('Fill in the context to save'))
      .max(
        CONTEXT_MAX_LENGTH,
        l.translate('Context must be {count} characters or fewer', { count: CONTEXT_MAX_LENGTH }),
      ),
    scenario: z
      .array(getAgentScenarioStepValdiationSchema(fields))
      .min(FlexAgentScenarioRules.minCount)
      .max(FlexAgentScenarioRules.maxCount),
  })

export const getAgentValidationSchema = (fields: string[]) =>
  z.object({
    type: z.literal(AiAgentType.FLEXIBLE),
    config: getAgentConfigValidationSchema(fields),
  })

export type AgentFormValues = z.infer<ReturnType<typeof getAgentConfigValidationSchema>>
export type AgentFormScenarioStep = z.infer<ReturnType<typeof getAgentScenarioStepValdiationSchema>>
export type AgentFormCustomStep = Extract<
  AgentFormScenarioStep,
  { type: FlexibleAgentStepType.CUSTOM }
>
export type AgentFormCollectInfoStep = Extract<
  AgentFormScenarioStep,
  { type: FlexibleAgentStepType.COLLECT_INFO }
>

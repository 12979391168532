import dot from 'dot-prop-immutable'

import { parseMenu } from 'apps/menu/models/MenuItem/adapter'
import * as atypes from 'apps/templates/constants/ReduxActionTypes'

const initialState = {
  generalElementsConflicts: {
    wm: true,
    dr: true,
    mm: true,
    gtext: true,
    igdr: true,
    sm: true,
    ib: true,
    igib: true,
    igmm: true,
  },
  signature: null,
  isInstalling: false,
  isInstallingComplete: false,
  installationId: null,
  generalElements: {
    wm: null,
    mm: null,
    dr: null,
    gtext: null,
    igdr: null,
    sm: null,
    ib: null,
    igib: null,
    igmm: null,
  },
  settings: {},
}

export default function templateInstallationReducer(state = initialState, action) {
  switch (action.type) {
    case atypes.RESOLVE_CONFLICT: {
      const { data } = action
      const generalElementsConflicts = { ...state.generalElementsConflicts, ...data }
      return { ...state, generalElementsConflicts }
    }
    case atypes.SET_SETTINGS: {
      const { data } = action
      return { ...state, settings: { ...state.settings, ...data } }
    }
    case atypes.BOT_TEMPLATE_FETCH_RESPONSE: {
      const { template_data, user_info } = action.data
      return { ...state, template_data, user_info }
    }
    case atypes.BOT_TEMPLATE_GENERAL_ELEMENT_FETCH_RESPONSE: {
      const { data } = action.data
      if (action.element === 'mm') {
        const parsed = parseMenu(data)
        return dot.set(state, 'generalElements.mm', parsed)
      }
      return { ...state, generalElements: { ...state.generalElements, [action.element]: data } }
    }
    case 'STORETEMPLATES_CURRENT_FETCH_RESPONSE': {
      const { item } = action.data

      return {
        ...state,
        template_data: { ...item, current_version: item.internal_template_data.current_version },
        user_info: { name: item.authors },
      }
    }
    case atypes.BOT_TEMPLATE_FETCH_ERROR: {
      return { ...state, error: action.error }
    }
    case atypes.BOT_TEMPLATE_INSTALL_REQUEST: {
      return { ...state, isInstalling: true, signature: action.signature }
    }
    case atypes.BOT_TEMPLATE_INSTALL_RESPONSE: {
      const { data } = action
      return { ...state, signature: data.async }
    }
    case atypes.BOT_TEMPLATE_INSTALL_ERROR: {
      return { ...state, isInstalling: false }
    }
    case atypes.TEMPLATES_INSTALLATION_STATUS_UPDATE: {
      const { done, result } = action.data
      if (done) {
        return {
          ...state,
          isInstalling: false,
          isInstallingComplete: true,
          installationId: result.template_installation_id,
        }
      }
      return state
    }
    case atypes.TEMPLATES_INSTALLATION_RESET: {
      return initialState
    }
  }

  return state
}

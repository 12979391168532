import { BusinessErrorSchema } from 'shared/api/common/schemas/errors'

import { ResponseError } from './responseError'
import type { BusinessError } from './types'

export const isResponseError = (error: unknown): error is ResponseError =>
  error instanceof ResponseError

export const isBusinessError = (error: unknown): error is BusinessError =>
  BusinessErrorSchema.safeParse(error).success

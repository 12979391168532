import React, { Component } from 'react'
import cx from 'classnames'
import PropTypes from 'prop-types'
import { Scrollbars, Icon } from '@manychat/manyui'

import cm from './PopoverPage.module.css'

class PopoverPage extends Component {
  static propTypes = {
    open: PropTypes.bool,
    onOpen: PropTypes.func,
    onRequestClose: PropTypes.func,
  }

  handleTransitionEnd = (e) => {
    const { open, onOpen } = this.props
    if (e.target === this.page && open && onOpen) {
      onOpen(e)
    }
  }

  handlePageRef = (el) => (this.page = el)

  render() {
    const { open, title } = this.props
    const { onRequestClose } = this.props
    return (
      <div className={cx(cm.wrapper, { [cm.open]: open })}>
        <div
          ref={this.handlePageRef}
          onTransitionEnd={this.handleTransitionEnd}
          className={cm.page}
        >
          {title && (
            <div className={cm.header}>
              <a onClick={onRequestClose} className={cm.backLink}>
                <Icon.ChevronLeft size={36} />
              </a>
              <div className="text-subheading">{title}</div>
            </div>
          )}

          <div className={cm.body}>
            <Scrollbars>{this.props.children}</Scrollbars>
          </div>
        </div>
      </div>
    )
  }
}

export default PopoverPage

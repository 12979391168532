import { Action } from 'redux'

import actionTypes from 'common/actions/constants/ReduxActionTypes'
import { SETTINGS_GROUP_FIELD_NAME } from 'common/actions/integrations/Applications/constants'
import {
  IActionNodeApplicationState,
  IActionContent,
  IActionField,
  IApplicationTriggers,
} from 'common/actions/integrations/Applications/interfaces'
import { IActionWithPayload } from 'common/core/interfaces/actions'

const initialState: IActionNodeApplicationState = {
  selectedApplicationId: null,
  selectedItemOid: null,
  selectedAction: null,
  selectedActionName: null,
  selectedActionSource: null,
  actionFetching: false,
  actionFields: [],
  actionMapping: [],
  actionSources: {},
  triggers: [],
}

interface IActionWithActionData extends Action {
  applicationId: number
  _oid: string
  actionName?: string
  selectedActionSource: IActionNodeApplicationState['selectedActionSource']
}

interface IActionWithApplicationAction extends Action {
  data: { action: IActionContent }
}

interface IActionWithApplicationsTriggers extends Action {
  data: { apps: IApplicationTriggers[] }
}

interface IActionWithAFields extends Action {
  fields: IActionField[]
  mapping: IActionField[]
}

interface IActionWithSourceName extends Action {
  applicationId: number
  sourceName: string
}

interface IActionWithFieldName extends Action {
  fieldName: string
}

export default function (
  state: IActionNodeApplicationState = initialState,
  action:
    | Action
    | IActionWithActionData
    | IActionWithApplicationAction
    | IActionWithAFields
    | IActionWithApplicationsTriggers
    | IActionWithSourceName
    | IActionWithPayload
    | IActionWithFieldName,
): IActionNodeApplicationState {
  switch (action.type) {
    case actionTypes.APPLICATIONS_TRIGGERS_FETCH:
      state = {
        ...state,
        triggers: [],
      }
      break
    case actionTypes.APPLICATIONS_TRIGGERS_FETCH_RESPONSE:
      if ('data' in action && 'apps' in action.data) {
        state = {
          ...state,
          triggers: action.data.apps,
        }
      }
      break
    case actionTypes.APPLICATION_ACTION_SELECT_APPLICATION:
      if ('applicationId' in action && '_oid' in action) {
        state = {
          ...state,
          selectedApplicationId: action.applicationId,
          selectedItemOid: action._oid,
          selectedActionName: action.actionName ?? null,
          selectedActionSource: action.selectedActionSource,
        }
      }
      break
    case actionTypes.APPLICATION_ACTION_DESELECT_APPLICATION:
      state = {
        ...state,
        selectedAction: null,
        selectedApplicationId: null,
        selectedItemOid: null,
        selectedActionName: null,
        actionFields: [],
        actionMapping: [],
      }
      break
    case actionTypes.APPLICATION_SELECTED_ACTION_FETCH_REQUEST:
      state = { ...state, selectedAction: null, actionFetching: true }
      break
    case actionTypes.APPLICATION_SELECTED_ACTION_FETCH_RESPONSE:
      if ('data' in action && 'action' in action.data) {
        state = {
          ...state,
          selectedAction: action.data.action,
          selectedActionName: action.data.action.name,
          actionFetching: false,
        }
      }
      break
    case actionTypes.APPLICATION_DESELECT_ACTION:
      state = { ...state, selectedAction: null, selectedActionName: null }
      break
    case actionTypes.APPLICATION_ACTION_FIELDS_SET:
      if ('fields' in action) {
        state = {
          ...state,
          actionFields: action.fields,
          actionMapping: action.mapping,
        }
      }
      break
    case actionTypes.APPLICATION_ACTION_FIELD_UPDATE:
      if ('fields' in action && action.fields.length) {
        const [field] = action.fields
        const { actionFields } = state
        const index = actionFields.findIndex((el) => el.field_name === field.field_name)
        if (index !== -1) {
          state = {
            ...state,
            actionFields: [
              ...actionFields.slice(0, index),
              { ...actionFields[index], value: field.value },
              ...actionFields.slice(index + 1),
            ],
          }
        }
      }
      if ('mapping' in action && action.mapping.length) {
        const [mapping] = action.mapping
        const { actionMapping } = state
        const index = actionMapping.findIndex((el) => el.field_name === mapping.field_name)
        if (index !== -1) {
          state = {
            ...state,
            actionMapping: [
              ...actionMapping.slice(0, index),
              { ...actionMapping[index], value: mapping.value },
              ...actionMapping.slice(index + 1),
            ],
          }
        }
      }
      break
    case actionTypes.APPLICATION_ACTION_SOURCES_RESET: {
      const sources = { ...state.actionSources }
      for (const sourceName of Object.keys(sources)) {
        if (sourceName === SETTINGS_GROUP_FIELD_NAME) {
          continue
        }

        sources[sourceName] = { ...sources[sourceName], reset: true }
      }

      const actionFields = state.actionFields.map(
        ({ title, field_name, displayType, value, default: defaultValue, isValid }) => {
          if (displayType === 'select') {
            value = field_name !== SETTINGS_GROUP_FIELD_NAME ? undefined : value
          }
          return {
            title,
            field_name,
            displayType,
            value,
            default: defaultValue,
            isValid,
          }
        },
      )

      state = {
        ...state,
        actionFields,
        actionSources: sources,
      }
      break
    }
    case actionTypes.APPLICATION_ACTION_SOURCES_FETCH_REQUEST:
      if ('applicationId' in action && action.applicationId !== state.selectedApplicationId) {
        break
      }

      if ('sourceName' in action) {
        state = {
          ...state,
          actionSources: {
            ...state.actionSources,
            [action.sourceName]: { items: [], fetching: true, reset: false },
          },
        }
      }
      break
    case actionTypes.APPLICATION_ACTION_SOURCES_FETCH_ERROR:
      if ('applicationId' in action && action.applicationId !== state.selectedApplicationId) {
        break
      }

      if ('sourceName' in action) {
        state = {
          ...state,
          actionSources: {
            ...state.actionSources,
            [action.sourceName]: { ...state.actionSources[action.sourceName], fetching: false },
          },
        }
      }
      break
    case actionTypes.APPLICATION_ACTION_SOURCES_FETCH_RESPONSE:
      if ('applicationId' in action && action.applicationId !== state.selectedApplicationId) {
        break
      }

      if ('sourceName' in action && 'data' in action) {
        state = {
          ...state,
          actionSources: {
            ...state.actionSources,
            [action.sourceName]: { fetching: false, items: action.data.source },
          },
        }
      }
      break
    case actionTypes.APPLICATION_ACTION_FIELDS_VALIDATE:
      state = {
        ...state,
        actionFields: state.actionFields.map((field) => ({
          ...field,
          isValid: field.default !== undefined || field.value !== undefined,
        })),
      }

      break

    case actionTypes.APPLICATION_ACTION_FIELD_VALIDATE:
      if ('fieldName' in action) {
        const { actionFields } = state
        const index = actionFields.findIndex((el) => el.field_name === action.fieldName)
        if (index !== -1) {
          const field = actionFields[index]
          state = {
            ...state,
            actionFields: [
              ...actionFields.slice(0, index),
              {
                ...actionFields[index],
                isValid: field.default !== undefined || field.value !== undefined,
              },
              ...actionFields.slice(index + 1),
            ],
          }
        }
      }

      break
  }

  return state
}

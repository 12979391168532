import { parseIntegrationFields } from 'common/filter/models/AudienceFilter/adapter'

import * as atypes from '../filterReduxActionTypes'

const initialState = {
  static: null,
  custom: null,
  system: null,
  global: null,
  fetching: false,
  error: null,
  requestId: null,
}

export default function filterFieldsReducer(state = initialState, action) {
  switch (action.type) {
    case atypes.FILTER_FIELDS_FETCH_REQUEST: {
      return { ...state, fetching: true, error: null, requestId: action.$requestId }
    }
    case atypes.FILTER_FIELDS_FETCH_RESPONSE: {
      const { data, $requestId } = action
      if ($requestId !== state.requestId) {
        return state
      }

      return {
        ...state,
        static: data.static,
        custom: data.custom,
        system: data.system,
        global: data.global,
        integration: parseIntegrationFields(data.integration),
        fetching: false,
        error: null,
      }
    }
    case atypes.FILTER_FIELDS_FETCH_ERROR: {
      const { error, $requestId } = action
      if ($requestId !== state.requestId) {
        return state
      }

      return {
        ...state,
        static: null,
        custom: null,
        system: null,
        global: null,
        fetching: false,
        error,
      }
    }
  }

  return state
}

import React, { useEffect, useRef } from 'react'
import cx from 'classnames'

import Lozenge, { LozengeProps } from '../Lozenge'

import { ListItemProps } from './interfaces'

import cm from './ListItem.module.css'

export const ListItem = ({
  lozenge,
  children,
  className,
  focused,
  label,
  leftContent,
  onBlur,
  onClick,
  onFocus,
  rightContent,
  role,
  selected,
  size = 'default',
  tabIndex,
  variant,
  ...props
}: ListItemProps) => {
  const listItemClasses = cx(
    cm.listItem,
    {
      [cm.selected]: selected,
      [cm.large]: size === 'large',
      [cm.danger]: variant === 'danger',
    },
    className,
  )

  const ref = useRef<HTMLLIElement>(null)

  useEffect(() => {
    if (focused) {
      ref.current?.focus()
    }
  }, [focused])

  return (
    <li
      className={listItemClasses}
      onClick={onClick}
      onFocus={onFocus}
      onBlur={onBlur}
      role={role}
      tabIndex={focused ? 0 : tabIndex}
      aria-selected={selected}
      ref={ref}
      {...props}
    >
      {leftContent && <div className={cm.leftContent}>{leftContent}</div>}
      {(label || children) && (
        <div className={cm.listItemContent}>
          <span className={cm.listItemText}>{label ? label : children}</span>
          {lozenge && <Lozenge type={lozenge as LozengeProps['type']} />}
        </div>
      )}
      {rightContent && <div className={cm.rightContent}>{rightContent}</div>}
    </li>
  )
}

import { useCallback, useRef } from 'react'

import { useMcClientAPI } from 'utils/api/query/hooks/useMcClientAPI'
import { useRequestCache } from 'utils/api/query/hooks/useRequestCache'
import { sendRequest } from 'utils/api/query/request'
import { CacheKey, CommandFn, Request } from 'utils/api/query/types'

interface UseCommandResult<A, R> {
  query: Request<R>
  send: (arg: A) => Promise<Request<R> | undefined>
}

export const useCommand = <A = void, R = void>(
  key: CacheKey,
  request: CommandFn<A, R>,
): UseCommandResult<A, R> => {
  const requestRef = useRef(request)
  const [getQuery, setQuery] = useRequestCache<R>(key)
  const api = useMcClientAPI()

  const send = useCallback(
    (arg: A) => sendRequest(() => requestRef.current({ api, arg, key }), getQuery(), setQuery),
    [api, getQuery, key, setQuery],
  )

  return { query: getQuery(), send }
}

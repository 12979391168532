import dayjs from 'dayjs'
import duration from 'dayjs/plugin/duration'
import timezone from 'dayjs/plugin/timezone'
import utc from 'dayjs/plugin/utc'

dayjs.extend(duration)
dayjs.extend(timezone)
dayjs.extend(utc)

const USER_TIMEZONE = Intl.DateTimeFormat().resolvedOptions().timeZone

export const getCoexistenceContactImportDeadline = (channelConnectedDatetime: string) => {
  const channelCreatedAt = dayjs.utc(channelConnectedDatetime)
  const deadline = channelCreatedAt.add(24, 'hours')
  const remainingTime = dayjs.duration(deadline.diff(dayjs()))
  const hoursLeft = Math.max(0, Math.floor(remainingTime.asHours()) || 0)
  const deadlineWithTimezone = deadline.tz(USER_TIMEZONE).format('MMMM D, HH:mm z')

  return { hoursLeft, deadline, deadlineWithTimezone }
}

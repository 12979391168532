import clone from 'lodash/clone'
import set from 'lodash/set'

import { IBackendWidget, ILegacyWidget, IWidget } from 'apps/growthTools/growthToolsInterfaces'
import { TriggerType } from 'common/flow/constants/TriggerType'
import { calculateCTR } from 'utils/stats'

import { STAT_N_A, WidgetStatusType } from './constants'
import { getWidgetDataInitializer } from './initializers'

const parseWidget = (w: IBackendWidget): IWidget => {
  const widget = clone(w) as IWidget

  widget.id = w.widget_id
  widget.triggerType = TriggerType.WIDGET
  widget.triggerId = `${TriggerType.WIDGET}-${widget.id}`

  widget.flowId = w.flow?.ns ?? null
  widget.chId = w.content_holder ?? null
  widget.caption = w.name
  widget.enabled = w.status === WidgetStatusType.ACTIVE

  // Fix for back-end bug
  if (w.data == null || Array.isArray(w.data)) {
    widget.data = {}
  }

  const d = w.data as ILegacyWidget['data']

  // Support for legacy 'hideOnMobile' true/false flag
  if (d?.setup?.hideOnMobile === true) {
    set(widget.data, 'setup.hideOnMobile', 'desktop')
  }

  widget.$version =
    d.img_small || d.img_big || d.size || d.color || d.lang || d.title || d.text || d.caid ? 1 : 2

  const defaultBackendStats = {
    impressions: 0,
    optins: 0,
    optin_message: {
      sent: 0,
      delivered: 0,
      read: 0,
      clicked: 0,
    },
  }
  widget.stats = {
    ...(w.stats ?? defaultBackendStats),
    $conversion: STAT_N_A,
    $opened: STAT_N_A,
    $clicked: STAT_N_A,
  }

  if (w.stats?.impressions) {
    widget.stats.$conversion = (w.stats.optins / w.stats.impressions) * 100

    const delivered = w.stats.optin_message && w.stats.optin_message.delivered
    if (delivered) {
      widget.stats.$opened = Math.round((w.stats.optin_message.read / delivered) * 100)
      widget.stats.$clicked = Math.round(calculateCTR(w.stats.optin_message.clicked, delivered))
    }
  }

  if (!w?.widget_type) {
    return widget
  }

  const initializeWidgetData = getWidgetDataInitializer(w.widget_type)
  return initializeWidgetData(w.data, widget)
}

export default parseWidget

import { useCallback, useMemo, useState, useEffect } from 'react'
import { FcWidgetInitProps, FcWidgetUserProps } from 'customTypes/globals'
import { useAppSelector } from 'reduxTyped'

import {
  getIsPro,
  getIsVipPro,
  getPageId,
  getUserEmail,
  getUserFirstName,
  getUserId,
  getUserLastName,
} from 'common/core/selectors/appSelectors'
import mcApi from 'utils/api/mcApi'
import { useUsercentrics } from 'utils/commonHooks'
import { RegistrationPaths } from 'utils/router/constants'
import { isPathnameMatchRoute } from 'utils/router/tools'
import { analyticsService } from 'utils/services/analytics'

interface FreshChatHook {
  loadFreshChat: (id?: string) => void
  destroyFreshChat: () => void
  openFreshChat: () => void
  closeFreshChat: () => void
  hideCustomChatButton: boolean
  isReady: boolean
  isOpen: boolean
}

const FRESHCHAT_SCRIPT = 'https://wchat.freshchat.com/js/widget.js'
const SCRIPT_ID = 'fresh-chat-widget'
export const USERCENTRICS_FRESHCHAT_NAME = 'Freshchat'

export const useFreshChat = ({
  isFreshdeskEnabled = false,
}: {
  isFreshdeskEnabled?: boolean
}): FreshChatHook => {
  const isWhatsAppPage = isPathnameMatchRoute(RegistrationPaths.WA_CONNECTION)

  const { usercentricsServicesInfo } = useUsercentrics(USERCENTRICS_FRESHCHAT_NAME)
  const isProvidedFreshchat = usercentricsServicesInfo[USERCENTRICS_FRESHCHAT_NAME]?.status ?? false
  const isActiveBackendFlag = isWhatsAppPage
    ? isFreshdeskEnabled
    : Boolean(window.freshchat_settings?.useFreshChat)
  const [isOpen, setIsOpen] = useState(false)
  const [isFcChat, setIsFcChat] = useState(Boolean(window.fcWidget))
  const [isReadyWidget, setIsReadyWidget] = useState(
    (window.fcWidget?.isInitialized() && window.fcWidget?.isLoaded()) ?? false,
  )

  const firstName = useAppSelector(getUserFirstName)
  const lastName = useAppSelector(getUserLastName)
  const email = useAppSelector(getUserEmail)
  const currentUserId = useAppSelector(getUserId)
  const pageId = useAppSelector(getPageId)
  const isPro = useAppSelector(getIsPro)
  const isVipPro = useAppSelector(getIsVipPro)
  const restoreId = window.freshchat_settings?.restoreId

  const isMainCredentials = isProvidedFreshchat && isActiveBackendFlag
  const isLoadedFreshChat = !(isMainCredentials && !isFcChat)
  const isInitializedFreshChat = isMainCredentials && isFcChat && !isReadyWidget
  const isReady = isMainCredentials && isFcChat && isReadyWidget
  const hideCustomChatButton = !isReady || isWhatsAppPage

  const analyticsName = isWhatsAppPage ? 'SIGN_IN.WA_CONNECT_FAILED' : 'UNKNOWN'

  const freshChatInitProperties: FcWidgetInitProps = useMemo(() => {
    return {
      token: '90376f02-0e9d-4d55-a4f2-989ed64816bc',
      host: 'https://wchat.freshchat.com',
      tags: isWhatsAppPage ? ['whatsapp_connection'] : undefined,
      externalId: isWhatsAppPage ? undefined : currentUserId,
      config: isWhatsAppPage ? undefined : { headerProperty: { hideChatButton: true } },
      restoreId: isWhatsAppPage ? undefined : restoreId,
    }
  }, [currentUserId, isWhatsAppPage, restoreId])

  const freshchatUserProperties: FcWidgetUserProps = useMemo(() => {
    return {
      firstName,
      lastName,
      email,
      'User ID': currentUserId,
      'Is VIP': isVipPro ? 'Yes' : 'No',
      'Pro Account': isPro ? 'Yes' : 'No',
      'Page ID': pageId,
    }
  }, [currentUserId, email, firstName, isPro, isVipPro, lastName, pageId])

  const openFreshChat = useCallback(() => {
    if (!isReady) {
      return
    }

    const fcWidget = window?.fcWidget
    if (fcWidget && fcWidget.isInitialized() && fcWidget.isLoaded() && !fcWidget.isOpen()) {
      fcWidget.open()

      if (isWhatsAppPage) {
        analyticsService.sendEvent(`${analyticsName}.FRESH_CHAT.OPENED_WITH_LINK`)
      }
    }
  }, [analyticsName, isReady, isWhatsAppPage])

  const closeFreshChat = useCallback(() => {
    if (!isReady) {
      return
    }

    const fcWidget = window?.fcWidget
    if (fcWidget && fcWidget.isInitialized() && fcWidget.isLoaded() && fcWidget.isOpen()) {
      fcWidget.hide()

      if (isWhatsAppPage) {
        analyticsService.sendEvent(`${analyticsName}.FRESH_CHAT.CLOSED_WITH_LINK`)
      }
    }
  }, [analyticsName, isReady, isWhatsAppPage])

  const handleChangeExistFcChatStatus = useCallback(
    () => !isFcChat && setIsFcChat(true),
    [isFcChat],
  )

  const handleWidgetOpen = useCallback(() => {
    analyticsService.sendEvent(`${analyticsName}.FRESH_CHAT.OPENED`)
    setIsOpen(true)
  }, [analyticsName])

  const handleWidgetClose = useCallback(() => {
    analyticsService.sendEvent(`${analyticsName}.FRESH_CHAT.CLOSED`)
    setIsOpen(false)
  }, [analyticsName])

  const handleUserCreate = useCallback(async (response: TSFixMe) => {
    const status = response && response.status
    const data = response && response.data

    if (status === 200 && data?.restoreId) {
      await mcApi.post('/support/updateFreshchatRestoreId', {
        body: {
          restore_id: data.restoreId,
        },
      })
    }
  }, [])

  const handleWidgetLoad = useCallback(() => {
    setIsReadyWidget(true)
    analyticsService.sendEvent(`${analyticsName}.FRESH_CHAT.LOADED`)

    window.fcWidget?.on('widget:opened', handleWidgetOpen)
    window.fcWidget?.on('widget:closed', handleWidgetClose)

    if (!isWhatsAppPage) {
      window.fcWidget?.user.get((response) => {
        const status = response && response.status
        window.fcWidget?.user.setProperties(freshchatUserProperties)

        if (status !== 200) {
          window.fcWidget?.on('user:created', handleUserCreate)
        }
      })
    }
  }, [
    analyticsName,
    freshchatUserProperties,
    handleUserCreate,
    handleWidgetClose,
    handleWidgetOpen,
    isWhatsAppPage,
  ])

  const initializeFreshChat = useCallback(() => {
    const fcWidget = window.fcWidget
    if (!fcWidget) {
      return
    }

    fcWidget.on('widget:loaded', handleWidgetLoad)
    fcWidget.init(freshChatInitProperties)
  }, [freshChatInitProperties, handleWidgetLoad])

  const destroyFreshChat = useCallback(() => {
    window.fcWidget?.off('widget:loaded', handleWidgetLoad)
    window.fcWidget?.off('widget:opened', handleWidgetOpen)
    window.fcWidget?.off('widget:closed', handleWidgetClose)
    window.fcWidget?.off('user:created', handleUserCreate)
    window.fcWidget?.destroy()
    setIsReadyWidget(false)
  }, [handleUserCreate, handleWidgetClose, handleWidgetLoad, handleWidgetOpen])

  const loadFreshChat = useCallback(() => {
    if (document.getElementById(SCRIPT_ID)) {
      handleChangeExistFcChatStatus()
      return
    }

    if (isLoadedFreshChat) {
      return
    }

    const element = document.createElement('script')
    element.id = SCRIPT_ID
    element.async = true
    element.src = FRESHCHAT_SCRIPT
    element.onload = handleChangeExistFcChatStatus
    document.head.appendChild(element)
  }, [handleChangeExistFcChatStatus, isLoadedFreshChat])

  useEffect(() => {
    if (!isInitializedFreshChat) {
      return
    }
    initializeFreshChat()
  }, [initializeFreshChat, isInitializedFreshChat])

  useEffect(() => {
    loadFreshChat()
    return () => destroyFreshChat()
  }, [destroyFreshChat, loadFreshChat])

  return {
    loadFreshChat,
    destroyFreshChat,
    openFreshChat,
    closeFreshChat,
    hideCustomChatButton,
    isReady,
    isOpen,
  }
}

import { z } from 'zod'

import { ChannelType } from 'common/core/constants/ChannelType'
import { lightFlowSchema } from 'shared/api/common/schemas/lightFlow'

export enum DefaultReplySourceType {
  STORY = 'story',
  DIRECT = 'direct',
}

export enum DefaultReplyType {
  EVERY_TIME = 'every_time',
  ONCE_PER_24_HOURS = 'once_per_24_hours',
}

export const defaultReplySchema = z.object({
  channel: z.nativeEnum(ChannelType),
  content_holder: z.string(),
  enabled: z.boolean(),
  flow: lightFlowSchema.nullable().optional(),
  sources: z
    .object({
      direct: z.boolean(),
      story: z.boolean(),
    })
    .nullable(),
  type: z.nativeEnum(DefaultReplyType),
})

export const defaultReplyApiSchemas = {
  getData: {
    query: z.object({
      channel: z.nativeEnum(ChannelType),
    }),
    path: z.undefined(),
    response: defaultReplySchema,
  },
  switch: {
    query: z.object({
      channel: z.nativeEnum(ChannelType),
      default_enabled: z.number(),
    }),
    path: z.undefined(),
    response: defaultReplySchema,
  },
  changeType: {
    query: z.undefined(),
    path: z.undefined(),
    request: z.object({
      channel: z.nativeEnum(ChannelType),
      type: z.nativeEnum(DefaultReplyType),
    }),
    response: defaultReplySchema,
  },
  replaceFlow: {
    query: z.undefined(),
    path: z.undefined(),
    request: z.object({
      channel: z.nativeEnum(ChannelType),
      flow_ns: z.string().nullable().optional(),
    }),
    response: defaultReplySchema,
  },
  changeSource: {
    query: z.undefined(),
    path: z.undefined(),
    request: z.object({
      channel: z.nativeEnum(ChannelType),
      sources: z.object({
        direct: z.boolean(),
        story: z.boolean(),
      }),
    }),
    response: defaultReplySchema,
  },
  removeFlow: {
    query: z.undefined(),
    path: z.undefined(),
    request: z.object({
      channel: z.nativeEnum(ChannelType),
    }),
    response: defaultReplySchema,
  },
}

export type DefaultReply = z.infer<typeof defaultReplySchema>

import { ChatNotification } from '../types/chatNotificationTypes'

export const isChatNotification = (notification: unknown): notification is ChatNotification => {
  return (
    typeof notification === 'object' &&
    notification !== null &&
    'data' in notification &&
    typeof notification.data === 'object' &&
    notification.data !== null &&
    'threadId' in notification.data &&
    typeof notification.data.threadId === 'string'
  )
}

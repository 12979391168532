import { z } from 'zod'

export const dashboardApiSchema = {
  fullStats: {
    response: z.object({
      stats: z.object({
        graph_active_subscribers: z.record(z.string(), z.number()),
        graph_new_subscribers: z.record(z.string(), z.number()),
        graph_subscribers_gross: z.record(z.string(), z.number()),
        graph_subscribers_net: z.record(z.string(), z.number()),
        graph_total_subscribers: z.record(z.string(), z.number()),
        graph_unsubscribes: z.record(z.string(), z.number()),

        subscribers_active: z.number(),
        subscribers_email_active: z.number(),
        subscribers_email_total: z.number(),
        subscribers_fb_active: z.number(),
        subscribers_fb_total: z.number(),
        subscribers_ig_active: z.number(),
        subscribers_ig_total: z.number(),
        subscribers_sms_active: z.number(),
        subscribers_sms_total: z.number(),
        subscribers_tg_active: z.number(),
        subscribers_tg_total: z.number(),
        subscribers_total: z.number(),
        subscribers_wa_active: z.number(),
        subscribers_wa_total: z.number(),
        subscribers_tt_active: z.number(),
        subscribers_tt_total: z.number(),
      }),
    }),
    path: z.undefined(),
    query: z.object({
      date_start: z.string(),
      date_end: z.string(),
      timestamp: z.string(),
      current_time_offset: z.number(),
    }),
  },
  subscribersGenderStats: {
    response: z.object({
      values: z.array(
        z.object({
          active: z.boolean(),
          count: z.number(),
          field: z.string(),
          label: z.string(),
          value: z.string(),
        }),
      ),
    }),
    path: z.undefined(),
    query: z.undefined(),
  },
  subscribersLanguageStats: {
    response: z.object({
      values: z.array(
        z.object({
          active: z.boolean(),
          count: z.number(),
          field: z.string(),
          label: z.string(),
          value: z.string(),
        }),
      ),
    }),
    path: z.undefined(),
    query: z.undefined(),
  },
  subscribersRecentList: {
    response: z.object({
      values: z.array(
        z.object({
          sid: z.number(),
          full_name: z.string(),
          avatar: z.string().or(z.null()),
        }),
      ),
    }),
    path: z.undefined(),
    query: z.undefined(),
  },

  clearPagesMessagingPermError: {
    response: z.undefined(),
    request: z.undefined(),
    path: z.undefined(),
    query: z.undefined(),
  },

  getEntitiesCount: {
    response: z.object({
      entities_count: z.object({
        flow: z.number(),
        growth_tool: z.number(),
        sequence: z.number(),
        ads: z.number(),
        trigger_rule: z.number(),
        keyword: z.number(),
        main_menu_flow: z.number(),
      }),
    }),
    path: z.undefined(),
    query: z.undefined(),
  },

  messageTagSending: {
    response: z.object({
      analytics_date: z.string(),
      content_sent: z.number(),
      content_sent_without_tag: z.number(),
      content_sent_other_without_tag: z.number(),
      content_sent_otn_without_token: z.number(),
    }),
    path: z.undefined(),
    query: z.undefined(),
  },

  messageTagSendingFlows: {
    response: z.object({
      analytics_date: z.string(),
      flows: z.array(
        z.object({
          flow_ns: z.string(),
          content_sent: z.number(),
          content_sent_without_tag: z.number(),
          content_sent_other_without_tag: z.number(),
          content_sent_otn_without_token: z.number(),
          name: z.string(),
          ts_updated: z.string(),
        }),
      ),
    }),
    path: z.undefined(),
    query: z.undefined(),
  },

  economicStats: {
    response: z.object({
      total_earned_list: z.record(z.string(), z.number()).or(z.array(z.string())),
      arppu_list: z.record(z.string(), z.number()).or(z.array(z.string())),
      apc_list: z.record(z.string(), z.number()).or(z.array(z.string())),
      buyers_count_list: z.record(z.string(), z.number()).or(z.array(z.string())),
      payments_count_list: z.record(z.string(), z.number()).or(z.array(z.string())),
    }),
    path: z.undefined(),
    query: z.undefined(),
  },
}

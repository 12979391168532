import React, { useState, useCallback, useEffect, useRef } from 'react'
import cx from 'classnames'
import * as Icon from '@manychat/icons'

import * as l from '../.././utils/localization/format'
import BtnV2 from '../BtnV2'

import { ToastProps, IconNames } from './interfaces'
import ToastContext from './Toast-Context'

import cm from './Toast.module.css'

export const TOAST_DISMISS_DELAY_MS = 3200
export const MOUSE_OUT_DISMISS_DELAY_MS = 1000
export const CLOSE_TOAST_DELAY_MS = 100

export const Toast = ({
  variant = 'success',
  isTemporary = true,
  onClose,
  title,
  description,
  icon,
  id,
  content,
  ...props
}: ToastProps) => {
  const [isClosing, setIsClosing] = useState(false)
  const [remainingTime, setRemainingTime] = useState<number | null>(TOAST_DISMISS_DELAY_MS)
  const [isPaused, setIsPaused] = useState(false)
  const [isTemporaryOpen, setIsTemporaryOpen] = useState(isTemporary)
  const timerId = useRef<NodeJS.Timeout | null>(null)
  const IconComponent = icon ? Icon[icon as IconNames] : null
  // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
  const toastAnimationStyle: React.CSSProperties = {
    '--remaining-duration': isTemporaryOpen && `${remainingTime}ms`,
  } as React.CSSProperties

  const handleClose = useCallback(() => {
    if (!isClosing) {
      setIsClosing(true)
      setTimeout(() => {
        onClose(id)
      }, CLOSE_TOAST_DELAY_MS)
    }
  }, [isClosing, onClose, id])

  const startTimer = useCallback(
    (duration: number) => {
      if (timerId.current) {
        clearTimeout(timerId.current)
      }
      timerId.current = setTimeout(handleClose, duration)
      setRemainingTime(duration)
    },
    [handleClose],
  )

  useEffect(() => {
    if (isTemporaryOpen && !isPaused && remainingTime !== null) {
      startTimer(remainingTime)
    }
    return () => {
      if (timerId.current) {
        clearTimeout(timerId.current)
      }
      return
    }

    /** Excluding `startTimer` from the dependency array to prevent the effect from re-running whenever `startTimer` changes. This is
     * necessary because including `startTimer`could cause unnecessary re-executions of effect, leading to multiple timers being set.*/
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isTemporaryOpen, isPaused, remainingTime])

  const handleMouseOver = useCallback(() => {
    if (isClosing) return
    if (!isTemporaryOpen) return
    if (timerId.current) {
      clearTimeout(timerId.current)
    }
    setIsPaused(true)
    setRemainingTime(null)
  }, [isClosing, isTemporaryOpen])

  const handleMouseOut = useCallback(() => {
    if (isClosing) return
    if (!isTemporaryOpen) return
    setIsPaused(false)
    startTimer(MOUSE_OUT_DISMISS_DELAY_MS)
  }, [isClosing, startTimer, isTemporaryOpen])

  const handleClick = useCallback(() => {
    setIsTemporaryOpen(true)
    startTimer(CLOSE_TOAST_DELAY_MS)
    handleClose()
  }, [handleClose, startTimer])

  const toastContent = (
    <>
      {title && <span className="text-body">{title}</span>}
      {description && <span className="text-body">{description}</span>}
      {content && <div className="align-start d-inline-flex flex-col">{content}</div>}
    </>
  )

  const toastClassName = cx(cm.toast, {
    [cm.success]: variant === 'success',
    [cm.danger]: variant === 'danger',
    [cm.info]: variant === 'info',
  })

  return (
    <ToastContext.Provider value={{ onClose: handleClose }}>
      <div
        id={id}
        role="alert"
        data-test-id="alert"
        className={toastClassName}
        onMouseEnter={handleMouseOver}
        onMouseLeave={handleMouseOut}
        style={toastAnimationStyle}
        {...props}
      >
        {IconComponent && (
          <span className={cm.icon}>
            <IconComponent size={20} />
          </span>
        )}
        <div className={cm.contentContainer}>{toastContent}</div>
        <BtnV2
          variant="ghost-dark"
          data-test-id="alert-close"
          ariaLabel={l.translate('Close')}
          onClick={handleClick}
          onlyIcon="Close"
        />
      </div>
    </ToastContext.Provider>
  )
}

import { BusinessError } from 'shared/api/lib/errors/business'

interface UnknownError extends BusinessError {
  field: 'unknown_error'
}

interface ChannelNotConnectedError extends BusinessError {
  field: 'channel_not_connected'
}

interface FlowNotExistsError extends BusinessError {
  field: 'flow_not_exist'
  error_data: {
    ice_breaker_id: number
  }
}

const isChannelNotConnectedError = (error: BusinessError): error is ChannelNotConnectedError =>
  error.field === 'channel_not_connected'

const isUnknownError = (error: BusinessError): error is UnknownError =>
  error.field === 'unknown_error'

const isFlowNotExistsError = (error: BusinessError): error is FlowNotExistsError =>
  error.field === 'flow_not_exist'

export const iceBreakerApiGuard = {
  publish: {
    isChannelNotConnectedError,
    isUnknownError,
    isFlowNotExistsError,
  },
  setAvailable: {
    isChannelNotConnectedError,
    isUnknownError,
  },
}

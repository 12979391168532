import React, { useCallback } from 'react'
import cx from 'classnames'
import { useAppSelector, useAppDispatch } from 'reduxTyped'
import { CheckboxV2, l, Lozenge } from '@manychat/manyui'

import { DraftIndicator } from 'apps/cms/components/DraftIndicator/DraftIndicator'
import { FlowContextMenu } from 'apps/cms/components/FlowContextMenu/FlowContextMenu'
import { Modified } from 'apps/cms/components/Flows/components/FlowCard/components/Modified/Modified'
import { Triggers } from 'apps/cms/components/Flows/components/FlowCard/components/Triggers/Triggers'
import { useFlowCard } from 'apps/cms/components/Flows/hooks/useFlowCard'
import { useFlowCardDrag } from 'apps/cms/components/Flows/hooks/useFlowCardDrag'
import { FlowCardClickAction } from 'apps/cms/pages/ContentManagementSystem/lib/types'
import { contentManagementSelectors, contentManagementActions } from 'apps/cms/store'
import ProtectionStatus from 'apps/templates/components/ProtectionStatus'
import { EasyBuilderFlowStatus } from 'shared/api/requests/easyBuilder/schemas'

import { getCtrValue, getRunsValue } from './lib/statistics'

import cm from './FlowCard.module.css'

interface FlowCardProps {
  flowId: string
  allowSelect: boolean
}

export const FlowCard = React.memo(function FlowCard({ flowId, allowSelect }: FlowCardProps) {
  const dispatch = useAppDispatch()

  const isFlowSelected = useAppSelector((state) =>
    contentManagementSelectors.getIsFlowSelected(state, flowId),
  )
  const isAnyFilterApplied = useAppSelector(contentManagementSelectors.getAnyFilterApplied)
  const {
    showFlowContextMenu,
    disabledFlowCardMessage,
    allowHoverFlowCard,
    allowHighlightActiveFlowCard,
    lastSelectedFlowId,
    flowCardClickAction,
  } = useAppSelector(contentManagementSelectors.getUi)
  const isFlowCardActive = lastSelectedFlowId === flowId && allowHighlightActiveFlowCard

  const { flow, handleFlowCardClick, userCanEditFlow, isCardDisabled, isEasyBuilderFlow, cardUrl } =
    useFlowCard({ flowId })

  const toggleFlowSelection = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      let shiftIsPressed = false

      if ('shiftKey' in event.nativeEvent) {
        shiftIsPressed = event.nativeEvent.shiftKey as boolean
      }

      if (shiftIsPressed) {
        dispatch(contentManagementActions.selectFlowsInRange(flowId))
        return
      }

      dispatch(contentManagementActions.toggleFlowSelection(flowId))
    },
    [dispatch, flowId],
  )

  const { connectDragSource } = useFlowCardDrag({ flow })

  if (!flow) {
    return null
  }

  const statisticsColumnClassName = cx(
    cm.card__statistics,
    !isAnyFilterApplied && cm.card__statistics_accented,
  )

  const easyBuilderBadgeType =
    flow.easy_builder?.status === EasyBuilderFlowStatus.ACTIVE ? 'live' : 'stopped'

  return (
    <article
      ref={connectDragSource}
      className={cx(cm.card, {
        [cm.card_withoutContextMenu]: !userCanEditFlow || !showFlowContextMenu,
        [cm.card_disabled]: isCardDisabled,
        [cm.card_withHover]: allowHoverFlowCard,
        [cm.card_active]: isFlowCardActive,
      })}
      data-onboarding-id="fs-object-flow"
      data-title-at="top"
      data-title={isCardDisabled && disabledFlowCardMessage ? disabledFlowCardMessage : null}
      data-test-id="flow-card"
    >
      {flowCardClickAction === FlowCardClickAction.OPEN_FLOW && (
        <a onClick={handleFlowCardClick} href={cardUrl} className={cm.card__mainActionButton} />
      )}

      {flowCardClickAction === FlowCardClickAction.SAVE_FLOW_ID_TO_STATE && (
        <button onClick={handleFlowCardClick} type="button" className={cm.card__mainActionButton} />
      )}

      {allowSelect && (
        <span className={cm.card__checkboxWrapper}>
          <CheckboxV2
            className={cm.card__interactableElement}
            checked={isFlowSelected}
            onChange={toggleFlowSelection}
            ariaLabel={l.translate('Select flow')}
          />
        </span>
      )}

      <div className={cm.card__body}>
        <div className={cm.card__content}>
          <div className={cx(cm.card__title, 'text-ellipsis')}>
            {!isEasyBuilderFlow && flow.has_unpublished_changes && <DraftIndicator />}
            {isEasyBuilderFlow && <Lozenge size="large" type={easyBuilderBadgeType} />}

            <h1
              className={cx(
                cm.card__flowName,
                'text-ellipsis',
                'text-center',
                !isAnyFilterApplied && cm.card__flowName_accented,
              )}
            >
              {flow.name}
            </h1>
          </div>

          <div className={cm.card__triggerToggle} />

          <div className={cx(statisticsColumnClassName, cm.card__hiddenOnMobile)}>
            {getRunsValue(flow.stats.sent)}
          </div>

          <div className={cx(statisticsColumnClassName, cm.card__hiddenOnMobile)}>
            {getCtrValue(flow.stats)}
          </div>

          <div className={cx('text-ellipsis')}>
            <Modified modified={flow.modified} />
          </div>

          <div className={cm.card__contextMenuColumn}>
            {userCanEditFlow && showFlowContextMenu && (
              <FlowContextMenu
                flowPath={flow.path}
                anchorClassName={cx(cm.card__interactableElement, cm.card__contextMenuButton)}
                anchorOpenedClassName={cm.card__contextMenuButton_opened}
                isEasyBuilderFlow={isEasyBuilderFlow}
              />
            )}

            {!isEasyBuilderFlow && flow.template_installation_access_data && (
              <ProtectionStatus
                className={cx(cm.card__interactableElement, cm.card__protectionStatus)}
                accessData={flow.template_installation_access_data}
              />
            )}
          </div>

          <div className={cx('text-sm', cm.card__mobileStatistics)}>
            <div className={cm.card__mobileStatisticsRow}>
              <span className="text-ellipsis">{l.translate('Runs')}</span>

              <span className={cx('flex-shrink-0', statisticsColumnClassName)}>
                {getRunsValue(flow.stats.sent)}
              </span>
            </div>

            <div className={cm.card__mobileStatisticsRow}>
              <span className="text-ellipsis">CTR</span>

              <span className={cx('flex-shrink-0', statisticsColumnClassName)}>
                {getCtrValue(flow.stats)}
              </span>
            </div>
          </div>
        </div>

        <Triggers
          triggers={flow.triggers}
          onClick={handleFlowCardClick}
          flowId={flowId}
          hideToggle={isEasyBuilderFlow}
        />
      </div>
    </article>
  )
})

import { FBMessagingType } from 'common/builder/constants/facebookMessaging'
import { INode } from 'common/builder/nodes/nodeInterfaces'

import { isInstagramNode } from './isInstagramNode'

export const isNodeIgCommentReplyType = (node: INode): boolean => {
  if (
    isInstagramNode(node) &&
    node.$fbMessagingType === FBMessagingType.COMMENT_REPLY &&
    node.privateReply === 'private_reply'
  ) {
    return true
  }

  return false
}

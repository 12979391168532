import dayjs from 'dayjs'
import { l } from '@manychat/manyui'

import { extractVariablesFromMessageTemplate } from 'apps/whatsApp/helpers/extractVariablesFromMessageTemplate'
import {
  WaMessageTemplateButtonType,
  WaMessageTemplateDraft,
  WaMessageTemplateHeaderType,
  WaMessageTemplateStatus,
} from 'shared/api/requests/whatsApp/schemas'

import {
  WA_MESSAGE_TEMPLATE_MAX_BODY_LENGTH,
  WA_MESSAGE_TEMPLATE_MAX_BUTTON_LENGTH,
  WA_MESSAGE_TEMPLATE_MAX_FOOTER_LENGTH,
  WA_MESSAGE_TEMPLATE_MAX_HEADER_LENGTH,
  WA_MESSAGE_TEMPLATE_MAX_NAME_LENGTH,
} from '../constants/messageTemplateConstants'

export interface MessageTemplateErrors {
  humanName?: string[]
  header?: string[]
  body?: string[]
  footer?: string[]
  buttons?: string[]
  template?: string[]
  example?: string[]
}

const ONE_DAY_IN_MILLIS = 1000 * 60 * 60 * 24

export const validateMessageTemplate = (
  messageTemplate: WaMessageTemplateDraft,
  { humanName }: { humanName?: string },
): MessageTemplateErrors => {
  const errors: MessageTemplateErrors = {}

  if (
    messageTemplate?.status !== undefined &&
    messageTemplate?.ts_updated !== undefined &&
    messageTemplate?.ts_created !== undefined
  ) {
    const templateErrors = getTemplateManagementErrors(
      messageTemplate.status,
      messageTemplate.ts_created,
      messageTemplate.ts_updated,
    )

    if (templateErrors.length > 0) {
      errors.template = templateErrors
    }
  }

  if (humanName !== undefined) {
    const nameErrors = getNameErrors(humanName)

    if (nameErrors.length > 0) {
      errors.humanName = nameErrors
    }
  }

  if (messageTemplate?.body !== undefined) {
    const bodyErrors = getBodyErrors(messageTemplate.body)

    if (bodyErrors.length > 0) {
      errors.body = bodyErrors
    }
  }

  if (messageTemplate?.footer !== undefined) {
    const footerErrors = getFooterErrors(messageTemplate.footer)

    if (footerErrors.length > 0) {
      errors.footer = footerErrors
    }
  }

  if (
    !Array.isArray(messageTemplate.header) &&
    messageTemplate.header?.type === WaMessageTemplateHeaderType.TEXT &&
    messageTemplate.header.text !== undefined
  ) {
    const headerErrors = getHeaderErrors(messageTemplate.header.text)

    if (headerErrors.length > 0) {
      errors.header = headerErrors
    }
  }

  if (Array.isArray(messageTemplate?.buttons)) {
    const buttonsText = messageTemplate.buttons.map((button) => button?.text || '')
    const buttonErrors = getButtonErrors(buttonsText)

    if (
      messageTemplate?.buttons.length === 1 &&
      messageTemplate?.buttons[0].type === WaMessageTemplateButtonType.Url
    ) {
      const urlButtonErrors = getButtonUrlErrors(messageTemplate?.buttons[0].url)
      buttonErrors.push(...urlButtonErrors)
    }

    if (buttonErrors.length > 0) {
      errors.buttons = buttonErrors
    }
  }

  const { variables } = extractVariablesFromMessageTemplate(messageTemplate)
  if (variables.length > 0) {
    const variableExampleErrors = getVariableExampleErrors(variables, messageTemplate?.example)

    if (variableExampleErrors.length > 0) {
      errors.example = variableExampleErrors
    }
  }

  return errors
}

function getTemplateManagementErrors(
  status: WaMessageTemplateStatus,
  tsCreated: number,
  tsUpdated: number,
) {
  const errors: string[] = []

  const isApprovalRecent = tsUpdated - tsCreated < ONE_DAY_IN_MILLIS
  const isUpdateTooRecent = Date.now() - tsUpdated < ONE_DAY_IN_MILLIS

  if (status === WaMessageTemplateStatus.APPROVED && !isApprovalRecent && isUpdateTooRecent) {
    const editUnblockTime = dayjs(tsUpdated).add(1, 'day')
    const editUnblockTimeDiff = editUnblockTime.diff(Date.now(), 'hour')

    if (editUnblockTimeDiff < 1) {
      const editUnblockTimeDiffMinutes = editUnblockTime.diff(Date.now(), 'minute')

      errors.push(
        l.translate(
          'Active message templates can only be updated once every 24h. Please try again in {minutes, plural, one {# minute} other {# minutes}}.',
          { minutes: editUnblockTimeDiffMinutes },
        ),
      )
    } else {
      errors.push(
        l.translate(
          'Active message templates can only be updated once every 24h. Please try again in {hours, plural, one {# hour} other {# hours}}.',
          { hours: editUnblockTimeDiff },
        ),
      )
    }
  }

  return errors
}

function getNameErrors(name: string) {
  const errors: string[] = []
  const isNameValid = name.match(/[\p{Letter}\p{Mark}\p{Number}]+/gu)

  if (!isNameValid) {
    errors.push(
      l.translate('Template name invalid. Please enter at least one latin character or number.'),
    )
  }

  if (name.length > WA_MESSAGE_TEMPLATE_MAX_NAME_LENGTH) {
    errors.push(
      l.translate("Template name can't be longer than {maxLength} characters.", {
        maxLength: WA_MESSAGE_TEMPLATE_MAX_NAME_LENGTH,
      }),
    )
  }

  return errors
}

function getHeaderErrors(header: string) {
  const errors: string[] = []

  if (header.length > WA_MESSAGE_TEMPLATE_MAX_HEADER_LENGTH) {
    errors.push(
      l.translate("Header text can't be longer than {maxLength} characters.", {
        maxLength: WA_MESSAGE_TEMPLATE_MAX_HEADER_LENGTH,
      }),
    )
  }

  if (containsUnsupportedCharacters(header)) {
    errors.push(
      l.translate(
        "Header text doesn't support emojis, new lines or the following characters: ` * ~.",
      ),
    )
  }

  return errors
}

function getFooterErrors(footer: string) {
  const errors: string[] = []

  if (containsUnsupportedCharacters(footer)) {
    errors.push(
      l.translate(
        "Footer text doesn't support emojis, new lines or the following characters: ` * ~",
      ),
    )
  }

  if (footer.length > WA_MESSAGE_TEMPLATE_MAX_FOOTER_LENGTH) {
    errors.push(
      l.translate("Footer text can't be longer than {maxLength} characters.", {
        maxLength: WA_MESSAGE_TEMPLATE_MAX_FOOTER_LENGTH,
      }),
    )
  }

  return errors
}

function getBodyErrors(body: string) {
  const errors: string[] = []

  const bodyWithoutVars = body.replace(/{{.*?}}/g, '')

  // Double line break refers to a two-empty-line separator between pieces of content, therefore we have to match 3 line breaks
  const isBodyWithDoubleLineBreaks = body.match(/((?:\\n){3}|\n{3})/) !== null

  if (bodyWithoutVars.trim().length === 0) {
    errors.push(l.translate("Message body can't be empty or contain only variables."))
  }

  if (isBodyWithDoubleLineBreaks) {
    errors.push(l.translate("Message body can't have two consecutive line breaks."))
  }

  if (body.length > WA_MESSAGE_TEMPLATE_MAX_BODY_LENGTH) {
    errors.push(
      l.translate("Body text can't be longer than {maxLength} characters.", {
        maxLength: WA_MESSAGE_TEMPLATE_MAX_BODY_LENGTH,
      }),
    )
  }

  return errors
}

function getButtonErrors(buttons: string[]) {
  const errors: string[] = []

  const templateContainsInvalidButtons = buttons.some(
    (button) => button.length > WA_MESSAGE_TEMPLATE_MAX_BUTTON_LENGTH,
  )

  if (templateContainsInvalidButtons) {
    errors.push(
      l.translate("Button text label can't be longer than {maxLength} characters.", {
        maxLength: WA_MESSAGE_TEMPLATE_MAX_BUTTON_LENGTH,
      }),
    )
  }

  if (containsUnsupportedCharacters(buttons.join(''))) {
    errors.push(
      l.translate(
        "Button's text doesn't support emojis, new lines or the following characters: ` * ~",
      ),
    )
  }

  const buttonsUnique = new Set(buttons.map((button) => button.trim()))

  if (buttons.length !== buttonsUnique.size) {
    errors.push(l.translate("Buttons can't have the same text label."))
  }

  return errors
}

function getButtonUrlErrors(url: string | undefined) {
  const errors: string[] = []

  if (url === undefined) {
    errors.push(l.translate('The button URL field is required.'))
  } else {
    const waDirectLinkInButton = url.includes('https://wa.me')

    if (waDirectLinkInButton) {
      errors.push(l.translate("Button text labels can't contain WhatsApp direct links."))
    }

    if (!isUrlValid(url)) {
      errors.push(l.translate('Button URL is not valid.'))
    }
  }

  return errors
}

function getVariableExampleErrors(
  variables: string[],
  examples: Record<string, string | undefined> | undefined,
) {
  const errors: string[] = []

  if (examples === undefined || variables.some((variable) => !examples[variable])) {
    errors.push(l.translate('All variables must have a sample.'))
  }

  return errors
}

function containsUnsupportedCharacters(str: string) {
  const regexEmojiAndUnsupportedChars = /[\p{Emoji_Presentation}*~`\n]/gu
  return str.match(regexEmojiAndUnsupportedChars) !== null
}

function isUrlValid(url: string) {
  try {
    const newUrl = new URL(url)
    return newUrl.protocol === 'http:' || newUrl.protocol === 'https:'
  } catch (err) {
    return false
  }
}

import { AutosavedWidgetTypes } from 'apps/growthTools/models/Widget/constants'
import { IFlowTrigger } from 'common/flow/flowInterfaces'

export const isAutosavedTriggerType = (trigger: IFlowTrigger) => {
  if (
    'widget_type' in trigger &&
    trigger.widget_type &&
    AutosavedWidgetTypes.includes(trigger.widget_type)
  ) {
    return true
  }
  return false
}

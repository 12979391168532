export default class ApplicationLanguage {
  private userLanguage: string

  constructor(userLanguage: string) {
    this.userLanguage = userLanguage
  }

  get() {
    return this.getFromEnv('MC_LNG') || this.getFromStorage('MC_LNG') || this.userLanguage
  }

  protected getFromEnv(key: string): string | undefined {
    return process.env[key]
  }

  protected getFromStorage(key: string): string | undefined | null {
    let storageLanguage = undefined
    try {
      storageLanguage = window.localStorage?.getItem(key)
    } catch (err) {
      console.warn('Local storage is not available')
    }

    return storageLanguage
  }
}

export const getApplicationLanguage = (userLanguage: string) =>
  new ApplicationLanguage(userLanguage).get()

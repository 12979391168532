import React from 'react'
import cx from 'classnames'
import PropTypes from 'prop-types'
import { Icon } from '@manychat/manyui'

import cm from './Tip.module.css'

/**
 * Tip component
 *
 * @example
 * <Tip
 *  text="Some text"
 *  title="Some title"
 *  content={<div>Some content</div>}
 * />
 *
 * @param {string} className
 * @param {string} text
 * @param {object} content
 * @param {string} title
 * @param {object} style
 * @param {bool} closable
 * @param {bool} rounded
 * @param {bool} useInnerHTML
 * @param {bool} warning
 * @param {bool} onlyItem
 * @param {bool} hasIcon
 * @param {string} titleClassName
 * @param {string} contentClassName
 * @param {string} closeIconClassName
 * @param {function} onClose
 * @return {React.Component}

 */

export default class Tip extends React.Component {
  static propTypes = {
    className: PropTypes.string,
    text: PropTypes.string,
    content: PropTypes.object,
    title: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
    style: PropTypes.object,
    closable: PropTypes.bool,
    rounded: PropTypes.bool,
    useInnerHTML: PropTypes.bool,
    warning: PropTypes.bool,
    onlyItem: PropTypes.bool,
    hasIcon: PropTypes.bool,
  }

  static defaultProps = {
    text: '',
    content: null,
    onClose: () => {},
    useInnerHTML: false,
    warning: false,
    onlyItem: false,
    hasIcon: true,
  }

  setText = (text) => ({ __html: text })

  render() {
    const {
      text,
      title,
      content,
      style,
      rounded,
      closable,
      onClose,
      contentClassName,
      closeIconClassName,
      useInnerHTML,
      warning,
      hasIcon,
      onlyItem,
      titleClassName,
      ...other
    } = this.props

    const className = cx(cm.wrapper, this.props.className, {
      [cm.rounded]: rounded,
      [cm.closable]: closable,
      [cm.warning]: warning,
      'm-t-0': onlyItem,
    })

    const contentCn = cx(cm.content, { 'm-t-xs': Boolean(title) })

    return (
      <div {...other} style={style} className={className}>
        <div className={cx(cm.flexContent)}>
          {hasIcon && (
            <div>
              {warning ? (
                <Icon.Warning size={24} className={cm.icon} />
              ) : (
                <Icon.Info size={24} className={cm.icon} />
              )}
            </div>
          )}

          <div className={cx(cm.rightColumn, contentClassName)}>
            {title && (
              <strong className={titleClassName ? titleClassName : cm.title}>{title}</strong>
            )}
            {text && (
              <>
                {useInnerHTML ? (
                  <div className={contentCn} dangerouslySetInnerHTML={this.setText(text)} />
                ) : (
                  <div className={contentCn}>
                    {text.split('\n').map((text, i) => (
                      <div className={cx(cm.contentItem)} key={i}>
                        {text}
                      </div>
                    ))}
                  </div>
                )}
              </>
            )}
            {content}
          </div>
        </div>
        {closable && (
          <div className={cx(cm.close, closeIconClassName)} onClick={onClose}>
            <Icon.Close size={24} />
          </div>
        )}
      </div>
    )
  }
}

import debugConstructor from 'debug'

import { AnalyticsData } from 'utils/services/analytics/types'

const debug = debugConstructor('analytics')

const CUSTOM_EVENT_FIELDS = ['eventCategory', 'eventAction', 'eventLabel']

export interface IGA {
  event: (eventName: string, data?: AnalyticsData | null) => void
}

export default class GAnalytics implements IGA {
  private getGA: () => ((event: string, payload: SafeUnknownObject) => void) | undefined

  constructor(getGA: () => ((event: string, payload: SafeUnknownObject) => void) | undefined) {
    this.getGA = getGA
  }

  private sendEvent = (hitType = 'event', fields: SafeUnknownObject) => {
    if (typeof this.getGA() === 'undefined') {
      return
    }

    return new Promise<void>((resolve) => {
      this.getGA()?.('send', {
        hitType,
        ...fields,
        hitCallback: () => resolve(),
        hitCallbackFail: () => debug('Unable to send Google Analytics event'),
      })
    })
  }

  event = async (eventName: string, data: AnalyticsData | null = null) => {
    await this.sendEvent('event', {
      ...eventName.split('.').reduce((fields, value, index) => {
        fields[CUSTOM_EVENT_FIELDS[index]] = value
          .toLowerCase()
          .split('_')
          .map((substr) => `${substr[0].toUpperCase()}${substr.slice(1)}`)
          .join('')

        return fields
      }, {} as Record<string, string>),
      ...data,
    })
  }
}

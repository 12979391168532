import { createSlice } from '@reduxjs/toolkit'

import { TrialState } from './trialInterfaces'

const initialState: TrialState = {
  isOpen: false,
  source: '',
}

const trialSlice = createSlice({
  name: 'trial',
  initialState,
  reducers: {
    toggleModal(state) {
      state.isOpen = !state.isOpen
    },
    openModal(state, action) {
      state.isOpen = true
      state.source = action.payload
    },
  },
})

export const { toggleModal, openModal: openTrialModal } = trialSlice.actions

export const { reducer } = trialSlice

export const getIsOpen = (state: RootState) => state.trial.isOpen
export const getSource = (state: RootState) => state.trial.source

import { AgencySignOn, Industries } from 'apps/agency/agencyInterfaces'
import {
  STEPS_BY_LEVEL,
  AGENCY_LEVELS,
  AGENCY_LEVELS_LABELS,
} from 'apps/agency/models/Agency/constants'

const getSignOn = (state: RootState): AgencySignOn | undefined => state.agency.signon

export const getSignOnAgencyId = (state: RootState): number | null =>
  getSignOn(state)?.agency_id ?? null

export const isAgencyProgressExist = (state: RootState) => {
  const { agency } = state
  const { status } = agency
  const { achievements } = status

  return (
    getSignOnAgencyId(state) &&
    status.currentStatus !== AGENCY_LEVELS.expert &&
    achievements &&
    Object.keys(achievements).length
  )
}
export const getAgencyNextStatusProgress = (state: RootState) => {
  if (!isAgencyProgressExist(state)) {
    return 0
  }

  const { agency } = state
  const { status } = agency
  const { achievements, currentStatus } = status

  const currentSteps = STEPS_BY_LEVEL[currentStatus]
  return (
    currentSteps
      .map((step) =>
        // eslint-disable-next-line
        // @ts-expect-error
        typeof achievements[step] === 'object' ? achievements[step].passed : achievements[step],
      )
      .reduce((prev, next) => Number(prev) + Number(next), 0) / currentSteps.length
  )
}

export const getAgencyNextStatus = (state: RootState) => {
  const { agency } = state
  const { status } = agency
  const { currentStatus } = status

  if (currentStatus === AGENCY_LEVELS.applicant) {
    return AGENCY_LEVELS.partner
  }
  if (currentStatus === AGENCY_LEVELS.partner) {
    return AGENCY_LEVELS.expert
  }
  return currentStatus
}

export const getAgencyProgressData = (state: RootState) => {
  if (isAgencyProgressExist(state))
    return {
      progress: getAgencyNextStatusProgress(state),
      nextStatus: getAgencyNextStatus(state),
      nextStatusLabel: AGENCY_LEVELS_LABELS[getAgencyNextStatus(state)],
    }

  return null
}

export const getFilterIndustries = (state: RootState): Industries =>
  state.agency?.listing?.filtersData?.industries ?? {}

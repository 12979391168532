export const WELCOME_MESSAGE_LOAD_REQUEST = 'WELCOME_MESSAGE_LOAD_REQUEST'
export const WELCOME_MESSAGE_LOAD_RESPONSE = 'WELCOME_MESSAGE_LOAD_RESPONSE'
export const WELCOME_MESSAGE_LOAD_ERROR = 'WELCOME_MESSAGE_LOAD_ERROR'

export const WELCOME_MESSAGE_UPDATE_ENABLED_REQUEST = 'WELCOME_MESSAGE_UPDATE_ENABLED_REQUEST'
export const WELCOME_MESSAGE_UPDATE_ENABLED_RESPONSE = 'WELCOME_MESSAGE_UPDATE_ENABLED_RESPONSE'
export const WELCOME_MESSAGE_UPDATE_ENABLED_ERROR = 'WELCOME_MESSAGE_UPDATE_ENABLED_ERROR'

export const WELCOME_MESSAGE_REMOVE_FLOW_RESPONSE = 'WELCOME_MESSAGE_REMOVE_FLOW_RESPONSE'

export const WELCOME_MESSAGE_SET_FLOW_RESPONSE = 'WELCOME_MESSAGE_SET_FLOW_RESPONSE'

export const UNSET_WELCOME_MESSAGE_RESPONSE = 'UNSET_WELCOME_MESSAGE_RESPONSE'

export const WELCOME_MESSAGE_UPDATED_NOTIFICATION = 'WELCOME_MESSAGE_UPDATED_NOTIFICATION'
export const WELCOME_MESSAGE_UPDATE_BY_CHANNEL = 'WELCOME_MESSAGE_UPDATE_BY_CHANNEL'

import isArray from 'lodash/isArray'
import omit from 'lodash/omit'

import blockRegistry from 'common/builder/blockRegistry'
import * as blockHelpers from 'common/builder/blocks/blockHelpers'
import buttonRegistry from 'common/builder/buttonRegistry'
import { BlockType } from 'common/builder/constants/BlockType'
import EntityType from 'common/builder/constants/EntityType'
import { FBMessagingType } from 'common/builder/constants/facebookMessaging'
import { NodeType } from 'common/builder/index'
import nodeRegistry from 'common/builder/nodeRegistry'

import { getSmartLinkIdsInText } from '../Button/helpers'

/**
 * Клонирование любого объекта в билдере сводится к нескольким простым опреациям:
 * - сделать копии всех объектов с заменой уникальных свойств (id, contentId, flow)
 * - восстановить целостность внутренних ссылок (blocks, buttons)
 * внешние ссылки (targetId) не трогаем, это выходит за рамки нашей ответственности
 *
 * @param element
 * @param related
 */
export const copyElement = (element, related) => {
  const elementInitial = omit(element, ['id', 'contentId', 'flow'])
  const elementCopy = createEntity(element, elementInitial)

  const blockIdMap = {}
  const clonedBlocks = (related.blocks || []).map((block) => {
    const blockInitial = JSON.parse(JSON.stringify(omit(block, ['id'])))

    if (
      blockInitial.type === BlockType.QUICK_REPLY ||
      blockInitial.type === BlockType.TELEGRAM_KEYBOARD
    ) {
      blockInitial.id = blockHelpers.createQRBlockId(elementCopy.id)
    }

    const clonedBlock = blockRegistry.get(blockInitial).create(blockInitial)
    blockIdMap[block.id] = clonedBlock.id
    return clonedBlock
  })

  const buttonIdMap = {}
  const clonedButtons = (related.buttons || []).map((button) => {
    const buttonInitial = JSON.parse(JSON.stringify(omit(button, ['id'])))
    const clonedButton = buttonRegistry.get(buttonInitial).create(buttonInitial)
    buttonIdMap[button.id] = clonedButton.id
    return clonedButton
  })

  if (isArray(elementCopy.blocks)) {
    elementCopy.blocks = elementCopy.blocks.map((id) => blockIdMap[id])
  }

  if (isArray(elementCopy.buttons)) {
    elementCopy.buttons = elementCopy.buttons.map((id) => buttonIdMap[id])
  }

  if (elementInitial?.privateReply === 'private_reply') {
    elementCopy.$fbMessagingType = FBMessagingType.COMMENT_REPLY
  }

  if (elementInitial?.nodeType === NodeType.AI_AGENT) {
    elementCopy.agentId = null
  }

  ;[elementCopy, ...clonedBlocks].forEach((block) => {
    if (typeof block.text !== 'string') {
      return
    }

    block.text = cloneText(block.text, buttonIdMap)
  })

  clonedBlocks.forEach((clonedBlock) => {
    clonedBlock.blocks = clonedBlock.blocks.map((id) => blockIdMap[id])
    clonedBlock.buttons = clonedBlock.buttons.map((id) => buttonIdMap[id])

    if (clonedBlock.type === BlockType.TELEGRAM_KEYBOARD) {
      clonedBlock.buttonsStructure = clonedBlock.buttonsStructure.map((buttons) =>
        buttons.map((id) => buttonIdMap[id]),
      )
    }

    if (clonedBlock.type === BlockType.WA_LIST_MESSAGE) {
      clonedBlock.sections = clonedBlock.sections?.map((section) => {
        section.options = section.options.map((option) => {
          option._oid = buttonIdMap[option._oid]
          return option
        })
        return section
      })
    }
  })

  const parsed = {
    blocks: clonedBlocks,
    buttons: clonedButtons,
  }

  const parsedName = `${elementCopy.entity}s`
  if (isArray(parsed[parsedName])) {
    parsed[parsedName].push(elementCopy)
  } else {
    parsed[parsedName] = [elementCopy]
  }

  return [elementCopy, parsed, { buttonIdMap, blockIdMap }]
}

const createEntity = (element, initial) => {
  if (element.entity === EntityType.BLOCK) {
    return blockRegistry.get(element).create(initial)
  }
  if (element.entity === EntityType.BUTTON) {
    return buttonRegistry.get(element).create(initial)
  }
  if (element.entity === EntityType.NODE) {
    return nodeRegistry.get(element).create(initial)
  }
  throw new Error(`createEntity: unknown entity "${element.entity}"`)
}

const cloneText = (text, buttonIdMap) => {
  const inlineButtonIds = getSmartLinkIdsInText(text)
  inlineButtonIds.forEach((buttonId) => {
    const clonedButtonId = buttonIdMap[buttonId]
    if (!clonedButtonId) {
      return
    }

    text = text.replace(buttonId, clonedButtonId)
  })

  return text
}

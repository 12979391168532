import clone from 'lodash/clone'
import values from 'lodash/values'

import { IBackendTriggerRule, ITriggerRule } from 'apps/triggerRules/triggerRulesInterfaces'
import { ActionTypes } from 'common/actions'
import { TriggerType } from 'common/flow/constants/TriggerType'

import { TriggerRuleStatusType, TriggerEventType } from './constants'
import createTrigger from './createTrigger'
import defaults from './defaults'

const parseTriggerRule = (item: IBackendTriggerRule): ITriggerRule => {
  const triggerRule = clone(item) as ITriggerRule

  triggerRule.id = item.rule_id
  triggerRule.triggerType = TriggerType.RULE
  triggerRule.triggerId = `${TriggerType.RULE}-${triggerRule.id}`

  triggerRule.caption = item.title
  triggerRule.actions = item.actions ?? []

  const flowIds = triggerRule.actions
    .map((item) => (item.type === ActionTypes.START_FLOW ? item.flow_ns || '' : ''))
    .filter((ns) => ns)

  triggerRule.flowId = flowIds[0] || null
  triggerRule.flowIds = flowIds
  triggerRule.chId = item.content_holder ?? null
  triggerRule.triggers = item.triggers ?? []
  triggerRule.frequency_settings = item.frequency_settings || defaults.frequency_settings
  triggerRule.enabled = item.status === TriggerRuleStatusType.ACTIVE

  const hasTriggers = Boolean(triggerRule.triggers.length)
  const initialTriggerEventType = item.draft?.initial_trigger_event_type
  const isInitialTriggerEventTypeValid =
    initialTriggerEventType && values(TriggerEventType).includes(initialTriggerEventType)

  if (!hasTriggers && initialTriggerEventType && isInitialTriggerEventTypeValid) {
    triggerRule.triggers = [createTrigger(initialTriggerEventType)]
    triggerRule.$initializedWithDraftTrigger = true
  }

  return triggerRule
}

export default parseTriggerRule

import { l, tints } from '@manychat/manyui'

export interface AnalyticsChartOption {
  label: string
  value: number
}

export enum AnalyticsTab {
  RESPONSE_OVERVIEW = 'responseOverview',
  TEAMS_EFFICIENCY = 'teamsEfficiency',
  TOTAL_CONVERSATIONS = 'totalConversations',
}

export enum ChartType {
  AVERAGE_CLOSURE_TIME = 'Average closure time',
  AVERAGE_FIRST_RESPONSE_TIME = 'Average first response time',
  CONVERSATIONS_BY_TEAM_MEMBERS = 'Total Conversations',
  RESPONSE_RATE = 'Response rate',
  TOTAL_CONVERSATIONS = 'Conversations in Dynamics',
}

export enum ConversationStatus {
  ASSIGNED = 'Assigned',
  CLOSED = 'Closed',
}

export enum TeamEfficiencyChartChannel {
  FACEBOOK = 'facebook',
  INSTAGRAM = 'instagram',
  WHATSAPP = 'whatsapp',
  TELEGRAM = 'telegram',
  TIKTOK = 'tiktok',
  ALL = 'all_channels',
}

export const TooltipTextColorByConversationStatus = {
  [ConversationStatus.CLOSED]: tints.green[3],
  [ConversationStatus.ASSIGNED]: tints.blue[3],
}

export const TooltipTimeLabelByConversationStatus = {
  [ConversationStatus.CLOSED]: l.translate('Closed'),
  [ConversationStatus.ASSIGNED]: l.translate('Assigned'),
}

export const TooltipDayLabelByConversationStatus = {
  [ConversationStatus.CLOSED]: l.translate('Closed on this day'),
  [ConversationStatus.ASSIGNED]: l.translate('Assigned on this day'),
}

interface TooltipEntryData {
  key: string
  value: number | string
  color: string
}

export interface TooltipEntry {
  title: string
  date?: string
  data: TooltipEntryData[]
}

export interface ChartData {
  x: string
  y: number
  title: string
  date?: string
  data: TooltipEntryData[]
}

export interface VerticalChartData {
  x: number
  y: string
  title: string
  date?: string
  data: TooltipEntryData[]
}

import React from 'react'
import { z } from 'zod'

import { BlockType } from '../../constants'
import { registry } from '../../registry'
import { StylesSchema } from '../../schemas'
import { BlockProps } from '../../types'

import imgLeft from './assets/head1.svg'
import imgRight from './assets/head2.svg'

import cm from './DefaultHeader.module.css'

const DefaultHeaderPropsSchema = z.object({
  text: z.string(),
  style: StylesSchema,
})

const Component = ({ blockId, text, style }: BlockProps<typeof DefaultHeaderPropsSchema>) => (
  <header data-block={blockId} className={cm.header} style={style}>
    <img className={cm.headerImg} src={imgLeft} height={114} width={146} />
    <h1 className="text-display-sm text-center m-t-0">{text}</h1>
    <img className={cm.headerImg} src={imgRight} height={114} width={146} />
  </header>
)

export const DefaultHeader = registry.register(Component, {
  props: DefaultHeaderPropsSchema,
  type: BlockType.DEFAULT_HEADER,
  previewProps: {
    text: 'Upgrade to Manychat Pro',
  },
})

export enum SubscriptionAction {
  /**
   * Offer trial if possible
   */
  ACTIVATE_TRIAL = 'activate_trial',

  /**
   * No expired subscription and no incomplete subscription checkout process. Offer upgrade.
   */
  UPGRADE = 'upgrade',

  /**
   * Has expired subscription. Offer renew then
   */
  RENEW = 'renew',

  /**
   * Started subscription checkout process and should complete it: verify payment, etc.
   */
  CONTINUE_CHECKOUT = 'continue_checkout',

  /**
   * Offer user to change/upgrade subscription level in case there is already any subscription plan
   */
  CHANGE_PLAN = 'change_plan',

  /**
   * User already has max subscription. Nothing to do
   */
  NONE = 'none',
}

import { z } from 'zod'

import { ConditionTypes } from 'apps/keywords/models/KeywordRule/constants'
import { ChannelType } from 'common/core/constants/ChannelType'

export const usedEntitiesListSchema = z.object({
  flows: z.array(
    z.object({
      title: z.string(),
      captions: z.array(z.any()),
      type: z.string(),
      channel: z.nativeEnum(ChannelType).optional(),
      is_main: z.boolean().optional(),
      id: z.string(),
    }),
  ),
  trigger_rules: z.array(z.object({ title: z.string(), id: z.number() })),
  keywords_rules: z.array(
    z.object({
      title: z.string(),
      data: z.array(
        z.object({
          keywords: z.array(z.string()),
          condition: z.nativeEnum(ConditionTypes),
        }),
      ),
      channel: z.nativeEnum(ChannelType),
      id: z.number(),
    }),
  ),
  integrations: z.array(z.any()),
  growth_tools: z.array(
    z.object({
      id: z.number(),
      title: z.string(),
    }),
  ),
  sequences: z.array(
    z.object({
      title: z.string(),
      id: z.number(),
    }),
  ),
  welcome_message: z.array(z.nativeEnum(ChannelType)),
  default_reply: z.array(z.nativeEnum(ChannelType)),
  follow_ups: z.array(z.nativeEnum(ChannelType)),
  story_mention_reply: z.array(z.nativeEnum(ChannelType)),
  icebreakers: z
    .array(
      z.object({
        title: z.string(),
        id: z.number(),
      }),
    )
    .optional(),
  intent_rules: z.array(
    z.object({
      title: z.string(),
      id: z.number(),
    }),
  ),
  external_triggers: z.array(
    z.object({
      title: z.string(),
      id: z.number(),
    }),
  ),
})

const usedEntityModelSchema = z.object({
  id: z.string(),
  title: z.string(),
})

const getFolderUsesListResponseSchema = z.object({
  entities: z.array(
    z.object({
      entities: usedEntitiesListSchema,
      model: usedEntityModelSchema,
    }),
  ),
})

export const cmsApiSchemas = {
  getUsesList: {
    response: z.object({
      entities: usedEntitiesListSchema,
    }),
    path: z.undefined(),
    query: z.object({
      flow_ns: z.string(),
    }),
  },
  delete: {
    query: z.object({
      path: z.string(),
      client_id: z.string(),
      delete_content: z.boolean().optional(),
    }),
    response: z.object({
      path: z.string(),
      flows_were_moved: z.boolean().optional(),
    }),
    path: z.undefined(),
  },
  getFolderUsesList: {
    query: z.object({
      path: z.string(),
    }),
    response: z.object({
      entities: z.array(
        z.object({
          entities: usedEntitiesListSchema,
          model: usedEntityModelSchema,
        }),
      ),
    }),
    path: z.undefined(),
  },
}

export type UsesList = z.infer<typeof usedEntitiesListSchema>
export type GetFolderUsesListResponse = z.infer<typeof getFolderUsesListResponseSchema>

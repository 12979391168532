import React from 'react'

interface IProps {
  quantity?: number
  children: React.ReactNode
}

const SkeletonMultiplier = ({ quantity = 4, children }: IProps) => (
  <>
    {new Array(quantity).fill({}).map((_, i) => (
      <React.Fragment key={i}>{children}</React.Fragment>
    ))}
  </>
)

export default SkeletonMultiplier
SkeletonMultiplier.displayName = 'Skeleton.Multiplier'

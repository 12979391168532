import { api } from 'shared/api'
import { storyMentionReplySchemas } from 'shared/api/requests/storyMentionReply/schemas'

export const StoryMentionReplyApi = {
  getData: api.account.createGet({
    url: '/storyMentionReply/getData',
    schemas: storyMentionReplySchemas.getData,
  }),
  switch: api.account.createGet({
    url: '/storyMentionReply/switch',
    schemas: storyMentionReplySchemas.switch,
  }),
  switchAutoLike: api.account.createPost({
    url: '/storyMentionReply/switchAutoLike',
    schemas: storyMentionReplySchemas.switchAutoLike,
  }),
  updateDelay: api.account.createPost({
    url: '/storyMentionReply/updateDelay',
    schemas: storyMentionReplySchemas.updateDelay,
  }),
  changeType: api.account.createPost({
    url: '/storyMentionReply/changeType',
    schemas: storyMentionReplySchemas.changeType,
  }),
  replaceFlow: api.account.createPost({
    url: '/storyMentionReply/replaceFlow',
    schemas: storyMentionReplySchemas.replaceFlow,
  }),
  removeFlow: api.account.createPost({
    url: '/storyMentionReply/removeFlow',
    schemas: storyMentionReplySchemas.removeFlow,
  }),
}

export const ICE_BREAKERS_FETCH = 'ICE_BREAKERS_FETCH'
export const ICE_BREAKERS_FETCH_REQUEST = 'ICE_BREAKERS_FETCH_REQUEST'
export const ICE_BREAKERS_FETCH_RESPONSE = 'ICE_BREAKERS_FETCH_RESPONSE'
export const ICE_BREAKERS_FETCH_ERROR = 'ICE_BREAKERS_FETCH_ERROR'
export const ICE_BREAKERS_PUBLISH = 'ICE_BREAKERS_PUBLISH'
export const ICE_BREAKERS_PUBLISH_REQUEST = 'ICE_BREAKERS_PUBLISH_REQUEST'
export const ICE_BREAKERS_PUBLISH_RESPONSE = 'ICE_BREAKERS_PUBLISH_RESPONSE'
export const ICE_BREAKERS_PUBLISH_ERROR = 'ICE_BREAKERS_PUBLISH_ERROR'
export const ICE_BREAKERS_SWITCH_ENABLED = 'ICE_BREAKERS_SWITCH_ENABLED'
export const ICE_BREAKERS_SWITCH_ENABLED_REQUEST = 'ICE_BREAKERS_SWITCH_ENABLED_REQUEST'
export const ICE_BREAKERS_SWITCH_ENABLED_RESPONSE = 'ICE_BREAKERS_SWITCH_ENABLED_RESPONSE'
export const ICE_BREAKERS_SWITCH_ENABLED_ERROR = 'ICE_BREAKERS_SWITCH_ENABLED_ERROR'
export const ICE_BREAKERS_SWITCH_ENABLED_NOTIFICATION = 'ICE_BREAKERS_SWITCH_ENABLED_NOTIFICATION'
export const ICE_BREAKERS_VALIDATION = 'ICE_BREAKERS_VALIDATION'
export const ICE_BREAKER_CREATE = 'ICE_BREAKER_CREATE'
export const ICE_BREAKER_UPDATE = 'ICE_BREAKER_UPDATE'
export const ICE_BREAKERS_REORDER = 'ICE_BREAKERS_REORDER'
export const ICE_BREAKER_DELETE = 'ICE_BREAKER_DELETE'
export const ICE_BREAKERS_SET_INVALID_ID = 'ICE_BREAKERS_SET_INVALID_ID'
export const ICE_BREAKERS_RESET_FETCHED_FOR_ALL_CHANNELS =
  'ICE_BREAKERS_RESET_FETCHED_FOR_ALL_CHANNELS'

export const ICE_BREAKERS_DELETE_BY_ORIGINAL_ID = 'ICE_BREAKERS_DELETE_BY_ORIGINAL_ID'

import React from 'react'
import { l } from '@manychat/manyui'

import { useFlexibleTriggerNameClassName } from 'apps/cms/components/Flows/components/FlowCard/components/Triggers/hooks/useFlexibleTriggerNameClassName'
import { renderTextByNodes } from 'apps/cms/components/Flows/components/FlowCard/components/Triggers/lib/renderTextByNodes'
import { TriggerEventType } from 'apps/triggerRules/models/TriggerRule/constants'
import { TriggersDictionary } from 'shared/lib/triggers/constants/dictionary'

interface RuleTitleProps {
  title: string
  ruleEventType: TriggerEventType
}

export const RuleTitle = ({ title, ruleEventType }: RuleTitleProps) => {
  const isLegacyRule = [
    TriggerEventType.LOG_CONVERSION_EVENT,
    TriggerEventType.INTEGRATION_FIELD_CHANGED,
  ].includes(ruleEventType)

  const className = useFlexibleTriggerNameClassName()

  return (
    <span className={className}>
      {isLegacyRule
        ? renderTextByNodes(title)
        : renderTextByNodes(
            l.getString(
              TriggersDictionary.contactEvents[
                ruleEventType as keyof typeof TriggersDictionary.contactEvents
              ].description,
            ),
          )}
    </span>
  )
}

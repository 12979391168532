import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import { IntegrationType } from '../constants/IntegrationType'

export interface IntegrationsConfirm {
  type: IntegrationType | null
}

export const IntegrationsConfirmInitialState: IntegrationsConfirm = {
  type: null,
}

const NAMESPACE = 'integrations/confirm'

export const integrationsConfirmSlice = createSlice({
  name: NAMESPACE,
  initialState: IntegrationsConfirmInitialState,
  reducers: {
    setType: (state, action: PayloadAction<IntegrationType | null>) => {
      state.type = action.payload
    },
  },
})

export const integrationsConfirmActions = integrationsConfirmSlice.actions
export const integrationsConfirmReducer = integrationsConfirmSlice.reducer

import React from 'react'

// eslint-disable-next-line import/order
import { safeReactLazy } from 'utils/safeReactLazy'
// eslint-disable-next-line import/order
import { isWebViewRoute } from 'utils/router/tools'

//
// ─── GLOBAL MODALS ──────────────────────────────────────────────────────────────
//

const AccountErrorModal = safeReactLazy(() => import('common/core/controllers/AccountErrorModal'))
const CreateUserFieldModal = safeReactLazy(
  () => import('common/fields/controllers/CreateUserFieldModal'),
)
const CreateGlobalFieldModal = safeReactLazy(
  () => import('common/fields/controllers/CreateGlobalFieldModal'),
)
const UpgradeView = safeReactLazy(() => import('common/billing/components/UpgradeView'))

const CreateTagModal = safeReactLazy(
  () => import('common/tags/controllers/TagsTable/components/CreateTagModal/CreateTagModal'),
)
const BotTimezoneModal = safeReactLazy(
  () => import('common/settings/components/BotTimezoneModal/BotTimeZoneModal'),
)
const ConnectChannelModalController = safeReactLazy(
  () => import('common/core/controllers/ConnectChannelModal'),
)
const CreateCustomEventModalController = safeReactLazy(
  () => import('common/events/controllers/CreateCustomEventModal'),
)

const WalletModals = safeReactLazy(() => import('common/billing/wallet/components/WalletModals'))
const BlockedAccountModal = safeReactLazy(
  () => import('apps/account/components/BlockedAccountModal'),
)
const FBPolicyEnforcementModalController = safeReactLazy(
  () => import('common/core/controllers/FBPolicyEnforcementModal'),
)
const NotifyAdminMigrationModal = safeReactLazy(
  () =>
    import(
      'common/settings/components/SettingsPanel/NotifyAdminMigration/NotifyAdminMigrationModal'
    ),
)
const BusinessDomainModals = safeReactLazy(
  () => import('apps/email/components/BusinessDomain/BusinessDomainModals'),
)
const BlockedProModal = safeReactLazy(
  () => import('common/billing/components/BlockedProModal/BlockedProModal'),
)
const CGTNotAllowedModal = safeReactLazy(
  () => import('apps/addNewAccount/Instagram/modals/CGTNotAllowedModal'),
)

const PaymentCardRenewalModal = safeReactLazy(
  () => import('common/billing/components/PaymentCardRenewalModal/PaymentCardRenewalModal'),
)
const CreateKeywordWithSystemNameModal = safeReactLazy(
  () => import('apps/keywords/components/CreateKeywordWithSystemNameModal'),
)
const IgPreviewModal = safeReactLazy(
  () => import('common/builder/components/manager/flow/IgPreviewModal/IgPreviewModal'),
)
const PayNowModal = safeReactLazy(() => import('common/billing/components/PayNowModal/PayNowModal'))

const HowToCreateTopicsModal = safeReactLazy(
  () => import('common/oneTimeNotify/components/HowToCreateTopicsModal/HowToCreateTopicsModal'),
)
const InstagramTopicsModal = safeReactLazy(
  () => import('common/oneTimeNotify/components/InstagramTopicsModal/InstagramTopicsModal'),
)
const KeywordsWarningModal = safeReactLazy(
  () => import('apps/keywords/components/KeywordWarningModal'),
)
const IgChangeAccountTypeErrorModal = safeReactLazy(
  () =>
    import(
      'common/settings/components/IgChangeAccountTypeErrorModal/IgChangeAccountTypeErrorModal'
    ),
)
const RecurringNotificationWizard = safeReactLazy(() =>
  import(
    'apps/dashboard/components/RnLearning/components/FirstRecurringNotificationOnboarding/Components/FirstRecurringNotificationModal/components/RecurringNotificationWizard/RecurringNotificationWizard'
  ).then((module) => ({ default: module.RecurringNotificationWizard })),
)

const IGRNReleaseModal = safeReactLazy(() => import('common/modals/IGRNReleaseModal'))

const UkraineReliefProgramModal = safeReactLazy(
  () => import('common/billing/components/UkraineReliefProgramModal/UkraineReliefProgramModal'),
)

const BillingNextActionModal = safeReactLazy(
  () => import('common/billing/components/BillingNextActionModal'),
)

const RefreshPermissionModal = safeReactLazy(
  () => import('common/core/components/RefreshPermissionModal/RefreshPermissionModal'),
)

const CancelAddOnModal = safeReactLazy(() => import('common/billing/components/CancelAddOnModal'))

const AiInterviewInvitation = safeReactLazy(
  () => import('common/billing/components/CancelAddOnModal/AiInterviewInvitation'),
)

const TierLimitModal = safeReactLazy(() => import('common/billing/components/TierLimitModal'))

const CompactSettingsModal = safeReactLazy(
  () => import('common/core/controllers/CompactSettingsModal'),
)

const AiCommunications = safeReactLazy(() => import('apps/aiCommunications'))
const AiIntentsGenerator = safeReactLazy(() => import('apps/aiIntentsWizard'))

const WaCoexistenceContactImportModal = safeReactLazy(
  () =>
    import(
      'apps/whatsApp/components/WaCoexistenceContactImportModal/WaCoexistenceContactImportModal'
    ),
)

import { useAppSelector } from 'reduxTyped'

import { Notifications } from 'apps/chat/controllers/notifications/Notifications'
import { FlowPickerModal } from 'apps/flowPicker/components/FlowPickerModal/FlowPickerModal'
import SMSConnectionModalNew from 'apps/sms/components/connectionModal/SMSConnectionModalNew'
import EmailVerificationController from 'common/core/components/EmailVerification/EmailVerificationController'
import { getCurrentAccountID } from 'common/core/selectors/appSelectors'
import AvailableFieldsInitializer from 'common/fields/components/AvailableFieldsInitializer'

export const ModalsList = () => {
  const accountId = useAppSelector(getCurrentAccountID)

  if (!accountId || isWebViewRoute()) {
    return null
  }

  return (
    <React.Suspense fallback={null}>
      <IGRNReleaseModal />
      <UkraineReliefProgramModal />
      <EmailVerificationController />
      <Notifications />
      <AccountErrorModal />
      <CreateUserFieldModal />
      <CreateGlobalFieldModal />
      <UpgradeView />
      <IgPreviewModal />
      <CreateTagModal />
      <BlockedProModal />
      <PaymentCardRenewalModal />
      <PayNowModal />
      <ConnectChannelModalController />
      <CompactSettingsModal />
      <CreateCustomEventModalController />
      <SMSConnectionModalNew />
      <BusinessDomainModals />
      <HowToCreateTopicsModal />
      <BlockedAccountModal />
      <NotifyAdminMigrationModal />
      <CreateKeywordWithSystemNameModal />
      <KeywordsWarningModal />
      <InstagramTopicsModal />
      <IgChangeAccountTypeErrorModal />
      <RecurringNotificationWizard />
      <BotTimezoneModal />
      <WalletModals />
      <CGTNotAllowedModal />
      <FBPolicyEnforcementModalController />
      <AvailableFieldsInitializer />
      <FlowPickerModal />
      <BillingNextActionModal />
      <RefreshPermissionModal />
      <CancelAddOnModal />
      <AiInterviewInvitation />
      <TierLimitModal />
      <AiCommunications />
      <AiIntentsGenerator />
      <WaCoexistenceContactImportModal />
    </React.Suspense>
  )
}

import { z } from 'zod'

export const GetAiBusinessContextSchema = {
  request: z.undefined(),
  query: z.undefined(),
  path: z.undefined(),
  response: z.object({
    state: z.boolean(),
    content: z.string(),
  }),
}

export const SetAiBusinessContextSchema = {
  request: z.object({
    content: z.string(),
  }),
  query: z.undefined(),
  path: z.undefined(),
  response: z.object({
    state: z.boolean(),
  }),
}

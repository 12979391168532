import { l } from '@manychat/manyui'

import { FLOW_PICKER_ANALYTICS_EVENT } from 'apps/flowPicker/lib/constants'
import { alert } from 'common/core'
import { analyticsService } from 'utils/services/analytics'
import errorTrackingService from 'utils/services/errorTrackingService'

export const catchSimulatorErrors = (error: unknown) => {
  alert(l.translate('Something went wrong'), 'danger')
  errorTrackingService.trackWarning(error, { fingerprint: 'simulator-cannot-play-flow' })
  analyticsService.sendEvent(`${FLOW_PICKER_ANALYTICS_EVENT}.FLOW_SIMULATOR.ERROR_CANT_PLAY_FLOW`)
}

import { v4 as uuid } from 'uuid'

import { FsQueryOrderField, FsQueryOrderType } from 'apps/cms/lib/constants'
import BackendContentType from 'common/builder/constants/BackendContentType'
import { ChannelType } from 'common/core/constants/ChannelType'
import { FlowTriggerStatus, FsTriggerModel } from 'shared/api/requests/cms/schemas'
import { getClientId } from 'utils/clientId'

const ChannelTypeToBackendContentTypeMap = {
  [ChannelType.INSTAGRAM]: BackendContentType.INSTAGRAM,
  [ChannelType.WHATSAPP]: BackendContentType.WHATS_APP,
  [ChannelType.FB]: BackendContentType.DEFAULT,
  [ChannelType.TELEGRAM]: BackendContentType.TELEGRAM,
  [ChannelType.SMS]: BackendContentType.SMS,
  [ChannelType.EMAIL]: BackendContentType.EMAIL_NEW,
  [ChannelType.TIKTOK]: BackendContentType.TIKTOK,
  [ChannelType.GUEST]: BackendContentType.DEFAULT,
}

export const generateFlowBody = ({
  id,
  flow_ns,
  channel,
  message,
}: {
  id: string
  flow_ns: string
  channel: ChannelType
  message: string
}) => {
  return {
    body: {
      ns: flow_ns,
      batch: {
        contents: [
          {
            type: ChannelTypeToBackendContentTypeMap[channel],
            _oid: id,
            namespace: flow_ns,
            quick_replies: {
              buttons: [],
              settings: {
                validation_message: null,
                skip_button_caption: null,
                limit_failed: null,
                timeout: null,
              },
            },
            caption: 'Send Message',
            content_id: null,
            removed: false,
            target: null,
            private_reply: null,
            messages: [
              {
                _oid: uuid(),
                type: 'text',
                content: {
                  text: message,
                },
                keyboard: [],
              },
            ],
          },
        ],
        root_content: id,
      },
      coordinates: {
        startingStep: {
          x: -890,
          y: 0,
        },
        chooseFirstStep: {
          x: 0,
          y: 0,
        },
        [id]: {
          x: 0,
          y: 0,
        },
      },
      client_id: getClientId(),
    },
  }
}

export const generateFilterBody = (channel: ChannelType) => ({
  body: {
    path: '/',
    q: '',
    field: FsQueryOrderField.MODIFIED,
    order: FsQueryOrderType.DESC,
    trigger_filters: [
      {
        trigger_model: FsTriggerModel.INTENT,
        channel,
      },
    ],
    trigger_status: FlowTriggerStatus.ACTIVE,
    limiter: null,
  },
})

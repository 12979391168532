import { Account } from 'apps/profile/models/Page/helpers'

export interface SidebarOption {
  fbPage: Account
  value: number | string
  label: string
}

interface SidebarAlternativeOption {
  options: SidebarOption[]
  label: string
}

export type SidebarAccountOption = SidebarOption | SidebarAlternativeOption

export const createAccountsOptions = (accounts: Account[]): SidebarAccountOption[] => {
  const favoriteAccounts = accounts.filter((fbPage) => fbPage.isFavorite)
  const otherAccounts = accounts.filter((fbPage) => !fbPage.isFavorite)
  if (favoriteAccounts.length === 0) {
    return accounts.map((fbPage) => ({ fbPage, value: fbPage.id, label: fbPage.name }))
  }

  return [
    {
      label: 'Pinned Accounts',
      options: favoriteAccounts.map((fbPage) => ({
        fbPage,
        value: fbPage.id,
        label: fbPage.name,
      })),
    },
    {
      label: 'Other',
      options: otherAccounts.map((fbPage) => ({
        fbPage,
        value: fbPage.id,
        label: fbPage.name,
      })),
    },
  ]
}

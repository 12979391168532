import OnboardingBase from 'utils/services/newOnboardingService/core/OnboardingBase'
import StepController from 'utils/services/newOnboardingService/core/StepController'
import StepSystem from 'utils/services/newOnboardingService/core/steps/StepSystem'
import OnboardingId from 'utils/services/newOnboardingService/OnboardingId'

export type ImportContactTourSteps = {
  STEP_1: StepSystem
  STEP_2: StepSystem
  STEP_3: StepSystem
}

export default class ImportContactTour extends OnboardingBase<ImportContactTourSteps> {
  constructor() {
    super(
      OnboardingId.WA_IMPORT_CONTACT_TOUR,
      () =>
        new StepController<ImportContactTourSteps>('STEP_1', {
          STEP_1: () => new StepSystem(() => this.setStep('STEP_2')),
          STEP_2: () => new StepSystem(() => this.setStep('STEP_3')),
          STEP_3: () => new StepSystem(() => this.complete()),
        }),
    )
  }
}

import * as atypes from 'apps/ads/constants/AdsReduxActionTypes'

const initialState = {
  adId: null,
  constructorId: null,
  isFetched: false,
  isInitializing: false,
  isPublishing: false,
  isSendingPreview: false,

  lastSaved: null,
  lastChanged: null,
  saveError: null,

  builderId: null,

  draft: null, // raw draft data
  published: null, // raw published data
  data: null, // parsed current data

  name: null,
  adAccountId: null,
  adSetId: null,
  campaignId: null,

  isUploadingImage: false,
  isUploadingVideo: false,
  isLoadingVideoThumbnails: false,
  isLoadingVideoCustomThumbnails: false,
}

export default function adConstructorReducer(state = initialState, action) {
  switch (action.type) {
    case atypes.AD_CONSTRUCTOR_INIT: {
      const { adId, constructorId } = action
      return { ...state, adId, constructorId, isInitializing: true }
    }

    case atypes.AD_CONSTRUCTOR_INIT_SUCCESS: {
      const { type, ...data } = action // eslint-disable-line no-unused-vars
      return { ...state, ...data, isFetched: true, isInitializing: false }
    }

    case atypes.AD_CONSTRUCTOR_INIT_ERROR: {
      return { ...state, isInitializing: false }
    }

    case atypes.AD_CONSTRUCTOR_DESTROY: {
      return { ...initialState }
    }

    case atypes.AD_CONSTRUCTOR_UPDATE_DATA: {
      return { ...state, data: { ...state.data, ...action.changes }, lastChanged: Date.now() }
    }

    case atypes.AD_CONSTRUCTOR_UPDATE_META: {
      return { ...state, ...action.changes }
    }

    case atypes.AD_CONSTRUCTOR_DISCARD_CHANGES_RESPONSE: {
      const { type, ...data } = action // eslint-disable-line no-unused-vars
      return {
        ...state,
        ...data,
        lastSaved: null,
        lastChanged: null,
        saveError: null,
      }
    }

    case atypes.AD_CONSTRUCTOR_UPDATE_TITLE_RESPONSE: {
      return { ...state, name: action.title }
    }

    case atypes.AD_CONSTRUCTOR_PUBLISH_REQUEST: {
      return { ...state, isPublishing: true }
    }

    case atypes.AD_CONSTRUCTOR_PUBLISH_RESPONSE: {
      return {
        ...state,
        draft: null,
        published: action.data,
        isPublishing: false,
        lastSaved: action.lastSaved,
        lastChanged: null,
        saveError: null,
      }
    }

    case atypes.AD_CONSTRUCTOR_PUBLISH_ERROR: {
      return { ...state, isPublishing: false }
    }

    case atypes.AD_CONSTRUCTOR_AUTO_SAVE_SUCCESS: {
      return { ...state, draft: action.draft, lastSaved: action.lastSaved, saveError: null }
    }

    case atypes.AD_CONSTRUCTOR_AUTO_SAVE_ERROR: {
      return { ...state, saveError: action.saveError }
    }

    case atypes.AD_CONSTRUCTOR_SEND_PREVIEW_REQUEST: {
      return { ...state, isSendingPreview: true }
    }

    case atypes.AD_CONSTRUCTOR_SEND_PREVIEW_RESPONSE: {
      return { ...state, isSendingPreview: false }
    }
  }

  return state
}

import dayjs from 'dayjs'

import * as atypes from 'apps/agency/constants/AgencyReduxActionTypes'

import { parseCommissionPage, parseCommissionStats } from '../models/Agency/adapter'

const initialState = {
  pages: [],
  pagesStats: [],
  commissionsByPage: [],
  filters: {
    startDate: dayjs().add(-6, 'month').format('YYYY-MM-DD'),
    endDate: dayjs().format('YYYY-MM-DD'),
  },
  fetchingPages: false,
}

export default function commissionsReducer(state = initialState, action) {
  switch (action.type) {
    case atypes.AGENCY_FETCH_PAGES_WTIH_COMMISSIONS_REQUEST: {
      return { ...state, fetchingPages: true }
    }

    case atypes.AGENCY_FETCH_PAGES_WTIH_COMMISSIONS_ERROR: {
      return { ...state, fetchingPages: false }
    }

    case atypes.AGENCY_FETCH_PAGES_WTIH_COMMISSIONS_RESPONSE: {
      const { pages, commissions_stats } = action.data
      return {
        ...state,
        pages: parseCommissionPage(pages),
        pagesStats: parseCommissionStats(commissions_stats),
        fetchingPages: false,
      }
    }

    case atypes.AGENCY_FETCH_COMMISSIONS_BY_PAGE_RESPONSE: {
      const { commissions } = action.data
      return {
        ...state,
        commissionsByPage: [...state.commissionsByPage, ...commissions],
      }
    }
    case atypes.AGENCY_UPDATE_FILTER: {
      const { startDate, endDate } = action.data
      return {
        ...state,
        filters: {
          startDate,
          endDate,
        },
      }
    }
  }

  return state
}

import React from 'react'
import { useAppSelector } from 'reduxTyped'
import { l } from '@manychat/manyui'

import { CmsBreadcrumbs } from 'apps/cms/components/Flows/components/CmsBreadcrumbs/CmsBreadcrumbs'
import { BREADCRUMBS_ROOT_NAME } from 'apps/cms/components/Flows/components/CmsBreadcrumbs/lib/constants'
import { NewAutomationButton } from 'apps/cms/components/NewAutomationButton/NewAutomationButton'
import { contentManagementSelectors } from 'apps/cms/store'
import { usePermission, PermissionTargets } from 'common/userRoles'

import cm from './ContentHeader.module.css'

export const ContentHeader = React.memo(function ContentHeader() {
  const isTrashFolder = useAppSelector(contentManagementSelectors.getIsTrashFolder)
  const userCanEditFlow = usePermission(PermissionTargets.FLOW_EDITING)
  const isSearchOrFilterApplied = useAppSelector(
    contentManagementSelectors.getIsSearchOrFilterApplied,
  )

  const { allowCreateFlow } = useAppSelector(contentManagementSelectors.getUi)

  return (
    <div className={cm.contentHeader} data-test-id="automation-content-header">
      <div className={cm.contentHeader__breadcrumbs}>
        {isSearchOrFilterApplied ? (
          <div className="text-heading-2">
            {l.translate('Search in {BREADCRUMBS_ROOT_NAME}', { BREADCRUMBS_ROOT_NAME })}
          </div>
        ) : (
          <CmsBreadcrumbs />
        )}
      </div>

      {userCanEditFlow && allowCreateFlow && !isTrashFolder && (
        <div className={cm.contentHeader__newAutomationButton}>
          <NewAutomationButton />
        </div>
      )}
    </div>
  )
})

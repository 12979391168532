import { api } from 'shared/api'
import { GlobalFieldsSchemas } from 'shared/api/requests/globalFields/schemas'

export const GlobalFieldsApi = {
  getList: api.account.createGet({
    url: '/globalFields/list',
    schemas: GlobalFieldsSchemas.getList,
  }),
  update: api.account.createPost({
    url: '/globalFields/update',
    schemas: GlobalFieldsSchemas.update,
  }),
  archive: api.account.createPost({
    url: '/globalFields/archive',
    schemas: GlobalFieldsSchemas.archive,
  }),
  restore: api.account.createPost({
    url: '/globalFields/restore',
    schemas: GlobalFieldsSchemas.restore,
  }),
  delete: api.account.createPost({
    url: '/globalFields/delete',
    schemas: GlobalFieldsSchemas.delete,
  }),
  create: api.account.createPost({
    url: '/globalFields/create',
    schemas: GlobalFieldsSchemas.create,
  }),
  changeValue: api.account.createPost({
    url: '/globalFields/changeValue',
    schemas: GlobalFieldsSchemas.changeValue,
  }),
  archiveBulk: api.account.createPost({
    url: '/globalFields/archiveBulk',
    schemas: GlobalFieldsSchemas.archiveBulk,
  }),
  deleteBulk: api.account.createPost({
    url: '/globalFields/deleteBulk',
    schemas: GlobalFieldsSchemas.deleteBulk,
  }),
  restoreBulk: api.account.createPost({
    url: '/globalFields/restoreBulk',
    schemas: GlobalFieldsSchemas.restoreBulk,
  }),
  getUsesList: api.account.createGet({
    url: '/globalFields/getUsesList',
    schemas: GlobalFieldsSchemas.getUsesList,
  }),
  moveToFolder: api.account.createPost({
    url: '/globalFields/moveToFolder',
    schemas: GlobalFieldsSchemas.moveToFolder,
  }),
  moveToFolderBulk: api.account.createPost({
    url: '/globalFields/moveToFolderBulk',
    schemas: GlobalFieldsSchemas.moveToFolderBulk,
  }),
  getFolderContentCount: api.account.createGet({
    url: '/globalFields/getFolderContentCount',
    schemas: GlobalFieldsSchemas.getFolderContentCount,
  }),
}

export const PROFILE_VERIFY_EMAIL_VIA_CODE = 'PROFILE_VERIFY_EMAIL_VIA_CODE'
export const PROFILE_VERIFY_EMAIL_VIA_CODE_RESPONSE = 'PROFILE_VERIFY_EMAIL_VIA_CODE_RESPONSE'
export const PROFILE_CHECK_EMAIL_VERIFY = 'PROFILE_CHECK_EMAIL_VERIFY'
export const PROFILE_CHECK_EMAIL_VERIFY_RESPONSE = 'PROFILE_CHECK_EMAIL_VERIFY_RESPONSE'
export const PROFILE_SEND_EMAIL_VERIFICATION = 'PROFILE_SEND_EMAIL_VERIFICATION'
export const PROFILE_SEND_EMAIL_VERIFICATION_REQUEST = 'PROFILE_SEND_EMAIL_VERIFICATION_REQUEST'
export const PROFILE_SEND_EMAIL_VERIFICATION_RESPONSE = 'PROFILE_SEND_EMAIL_VERIFICATION_RESPONSE'
export const PROFILE_SEND_EMAIL_VERIFICATION_ERROR = 'PROFILE_SEND_EMAIL_VERIFICATION_ERROR'
export const PROFILE_VERIFY_EMAIL = 'PROFILE_VERIFY_EMAIL'
export const PROFILE_VERIFY_EMAIL_REQUEST = 'PROFILE_VERIFY_EMAIL_REQUEST'
export const PROFILE_VERIFY_EMAIL_RESPONSE = 'PROFILE_VERIFY_EMAIL_RESPONSE'
export const PROFILE_VERIFY_EMAIL_ERROR = 'PROFILE_VERIFY_EMAIL_ERROR'
export const PROFILE_CONFIRM_EMAIL = 'PROFILE_CONFIRM_EMAIL'
export const PROFILE_CONFIRM_EMAIL_REQUEST = 'PROFILE_CONFIRM_EMAIL_REQUEST'
export const PROFILE_CONFIRM_EMAIL_RESPONSE = 'PROFILE_CONFIRM_EMAIL_RESPONSE'
export const PROFILE_CONFIRM_EMAIL_ERROR = 'PROFILE_CONFIRM_EMAIL_ERROR'
export const PROFILE_PAGES_FETCH_REQUEST = 'PROFILE_PAGES_FETCH_REQUEST'
export const PROFILE_PAGES_FETCH_RESPONSE = 'PROFILE_PAGES_FETCH_RESPONSE'
export const PROFILE_PAGES_SORT = 'PROFILE_PAGES_SORT'
export const PROFILE_PAGES_SEARCH = 'PROFILE_PAGES_SEARCH'
export const PROFILE_SET_TIMEZONE = 'PROFILE_SET_TIMEZONE'
export const PROFILE_SET_TIMEZONE_RESPONSE = 'PROFILE_SET_TIMEZONE_RESPONSE'
export const PROFILE_GENERATE_API_TOKEN = 'PROFILE_GENERATE_API_TOKEN'
export const PROFILE_GENERATE_API_TOKEN_RESPONSE = 'PROFILE_GENERATE_API_TOKEN_RESPONSE'
export const PROFILE_GENERATE_API_TOKEN_ERROR = 'PROFILE_GENERATE_API_TOKEN_ERROR'
export const PROFILE_DELETE_API_TOKEN = 'PROFILE_DELETE_API_TOKEN'
export const PROFILE_DELETE_API_TOKEN_RESPONSE = 'PROFILE_DELETE_API_TOKEN_RESPONSE'
export const PROFILE_DELETE_API_TOKEN_ERROR = 'PROFILE_DELETE_API_TOKEN_ERROR'
export const PROFILE_SET_USER_MENU_VISIBILITY = 'PROFILE_SET_USER_MENU_VISIBILITY'
export const PROFILE_FETCH_MESSAGE_TAG_ANALYTICS = 'PROFILE_FETCH_MESSAGE_TAG_ANALYTICS'
export const PROFILE_FETCH_MESSAGE_TAG_ANALYTICS_REQUEST =
  'PROFILE_FETCH_MESSAGE_TAG_ANALYTICS_REQUEST'
export const PROFILE_FETCH_MESSAGE_TAG_ANALYTICS_RESPONSE =
  'PROFILE_FETCH_MESSAGE_TAG_ANALYTICS_RESPONSE'
export const PROFILE_SET_DISPLAY_FLAGS = 'PROFILE_SET_DISPLAY_FLAGS'
export const PROFILE_SET_DISPLAY_FLAGS_RESPONSE = 'PROFILE_SET_DISPLAY_FLAGS_RESPONSE'
export const PROFILE_SET_DISPLAY_FLAGS_REQUEST = 'PROFILE_SET_DISPLAY_FLAGS_REQUEST'
export const PROFILE_SET_DISPLAY_FLAGS_ERROR = 'PROFILE_SET_DISPLAY_FLAGS_ERROR'

export const CURRENT_ACCOUNT_USER_PROFILE_SET_DISPLAY_FLAGS =
  'CURRENT_ACCOUNT_USER_PROFILE_SET_DISPLAY_FLAGS'
export const CURRENT_ACCOUNT_USER_PROFILE_SET_DISPLAY_FLAGS_RESPONSE =
  'CURRENT_ACCOUNT_USER_PROFILE_SET_DISPLAY_FLAGS_RESPONSE'
export const CURRENT_ACCOUNT_USER_PROFILE_SET_DISPLAY_FLAGS_REQUEST =
  'CURRENT_ACCOUNT_USER_PROFILE_SET_DISPLAY_FLAGS_REQUEST'
export const CURRENT_ACCOUNT_USER_PROFILE_SET_DISPLAY_FLAGS_ERROR =
  'CURRENT_ACCOUNT_USER_PROFILE_SET_DISPLAY_FLAGS_ERROR'

export const PROFILE_SET_SKIP_PREVIEW_ONBOARDING_MODAL = 'PROFILE_SET_SKIP_PREVIEW_ONBOARDING_MODAL'
export const PROFILE_SET_INACTIVE_ACCOUNT_IDS = 'PROFILE_SET_INACTIVE_ACCOUNT_IDS'
export const PROFILE_SET_INACTIVE_ACCOUNT_IDS_ERROR = 'PROFILE_SET_INACTIVE_ACCOUNT_IDS_ERROR'

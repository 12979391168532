import { api } from 'shared/api'

import { growthToolsSchemas } from './schemas'

export const growthToolsApi = {
  lastActiveFeedCommentWithNextPost: api.account.createGet({
    url: '/growth-tools/lastActiveFeedCommentWithNextPost',
    schemas: growthToolsSchemas.lastActiveFeedCommentWithNextPost,
  }),
  promotableFBPosts: api.account.createGet({
    url: '/growth-tools/getPromotablePosts',
    schemas: growthToolsSchemas.widgetFBPosts,
  }),
  scheduledFBPosts: api.account.createGet({
    url: '/growth-tools/getScheduledPosts',
    schemas: growthToolsSchemas.widgetFBPosts,
  }),
  fbPosts: api.account.createGet({
    url: '/growth-tools/getPosts',
    schemas: growthToolsSchemas.widgetGetFBPosts,
  }),
  searchFbPosts: api.account.createGet({
    url: '/growth-tools/searchPagePost',
    schemas: growthToolsSchemas.searchFBPost,
  }),
  logCheckingResult: api.account.createPost({
    url: '/growth-tools/logCheckingResult',
    schemas: growthToolsSchemas.logCheckingResult,
  }),
}

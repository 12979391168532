import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { l } from '@manychat/manyui'

import { AiAgentWsEvent } from 'apps/aiAgentGenerator/lib/constants'
import { alert } from 'common/core'
import { AiAgentApi } from 'shared/api/requests/aiAgent'
import { createAsyncAction } from 'shared/lib/redux'
import { anotherTabNotificationsListener } from 'utils/services/notificationsService'

import { AiAgentGeneratorState, AiGeneratedAgentDraft } from './types'

const NAMESPACE = 'aiAgentGenerator'

export const AiAgentGeneratorInitialState: AiAgentGeneratorState = {
  isGenerating: false,
  draft: null,
  sessionId: null,
}

const generateFx = createAsyncAction(
  `${NAMESPACE}/generateFx`,
  async (props: { goal: string; context: string; nodeId: string }, { getState }) => {
    const sessionId = getState().aiAgentGenerator.sessionId as string

    return AiAgentApi.generate({
      body: {
        agent: {
          goal: props.goal,
          context: props.context,
        },
        session_id: sessionId,
        node_id: props.nodeId,
      },
    })
  },
  {
    condition: (_, { getState }) => Boolean(getState().aiAgentGenerator.sessionId),
  },
)

const AiAgentGeneratorSlice = createSlice({
  name: NAMESPACE,
  initialState: AiAgentGeneratorInitialState,
  reducers: {
    draftGenerated: (
      state: AiAgentGeneratorState,
      action: PayloadAction<AiGeneratedAgentDraft>,
    ) => {
      state.draft = action.payload
      state.isGenerating = false
    },

    draftGenerationFailed: (state) => {
      state.isGenerating = false
    },

    generationSessionInitialised: (state, action: PayloadAction<string>) => {
      state.sessionId = action.payload
    },

    generatedDraftReset: (state) => {
      state.draft = null
      state.isGenerating = false
      state.sessionId = null
    },
  },
  extraReducers: (builder) => {
    builder.addCase(generateFx.pending, (state) => {
      state.isGenerating = true
    })
    builder.addCase(generateFx.rejected, (state) => {
      state.isGenerating = false
    })
  },
})

export const AiAgentGeneratorReducer = AiAgentGeneratorSlice.reducer

export const AiAgentGeneratorActions = {
  generateFx,

  draftGenerated: AiAgentGeneratorSlice.actions.draftGenerated,
  generatedDraftReset: AiAgentGeneratorSlice.actions.generatedDraftReset,
  generationSessionInitialised: AiAgentGeneratorSlice.actions.generationSessionInitialised,
}

const getState = (state: RootState) => state.aiAgentGenerator

export const AiAgentGeneratorSelectors = {
  getState,
  getGeneratorSessionId: (state: RootState) => getState(state).sessionId,
  getIsDraftGenerating: (state: RootState) => getState(state).isGenerating,
  getGeneratedAgentDraft: (state: RootState) => getState(state).draft,
}

anotherTabNotificationsListener.on(
  AiAgentWsEvent.GENERATED,
  (data: { model: AiGeneratedAgentDraft; session_id: string }, dispatch, getState) => {
    if (data.session_id === (getState() as RootState).aiAgentGenerator.sessionId) {
      dispatch(AiAgentGeneratorSlice.actions.draftGenerated(data.model))
    }
  },
)

anotherTabNotificationsListener.on(
  AiAgentWsEvent.GENERATION_FAILED,
  (data: { session_id: string }, dispatch, getState) => {
    if (data.session_id === (getState() as RootState).aiAgentGenerator.sessionId) {
      alert(
        l.translate('AI Step generation failed unexpectedly. Try regenerating to continue.'),
        'danger',
      )
      dispatch(AiAgentGeneratorSlice.actions.draftGenerationFailed())
    }
  },
)

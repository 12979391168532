import {
  AsyncThunkOptions,
  // eslint-disable-next-line no-restricted-imports
  createAsyncThunk,
} from '@reduxjs/toolkit'
import { GetThunkAPI } from '@reduxjs/toolkit/dist/createAsyncThunk'
import { AppThunkApiConfig } from 'reduxTyped'

import { alert } from 'common/core'
import { BusinessErrorWithHandle, handleError } from 'shared/api/lib/errors/handlers'

type ThunkAPI = GetThunkAPI<AppThunkApiConfig>

export interface CreateAsyncActionOptions<ThunkArg> {
  onBusinessError?: (error: BusinessErrorWithHandle, payload: ThunkArg, thunkApi: ThunkAPI) => void
  onError?: (error: unknown, payload: ThunkArg, thunkApi: ThunkAPI) => void | boolean
  autoHandleByGuards?: Array<(error: BusinessErrorWithHandle) => boolean>
}

/**
 *
 * @param typePrefix  Name of the action
 * @param actionCreator Standard action creator(ref. createAsyncThunk)
 * @param options Standard options for createAsyncThunk + additional fields(more info below)
 * @param options.onBusinessError  Callback for reacting to BusinessError that occurs in the action
 * @param options.onError  Callback for reacting to any errors that occur in the action. If you don't want to pass this error for the internal  handleError function, return true
 * @param options.autoHandleByGuards Array of typeguards for automatically handling those kinds errors inside the createAsyncAction
 *
 * @returns result from actionCreator | reject with BusinessError | reject with null if the error isn't BusinessError type
 */
export const createAsyncAction = <Returned, ThunkArg = void>(
  typePrefix: string,
  actionCreator: (args: ThunkArg, thunkAPI: ThunkAPI) => Promise<Returned> | Returned,
  options?: CreateAsyncActionOptions<ThunkArg> & AsyncThunkOptions<ThunkArg, AppThunkApiConfig>,
) => {
  const { onBusinessError, onError, autoHandleByGuards, ...restOptions } = options ?? {}

  return createAsyncThunk<Returned, ThunkArg, AppThunkApiConfig>(
    typePrefix,
    async (args, thunkAPI) => {
      const { rejectWithValue } = thunkAPI
      const handleBusinessError = (error: BusinessErrorWithHandle) => {
        const isGuardMatch = (autoHandleByGuards ?? []).some((guard) => guard(error))

        if (isGuardMatch) {
          error.handle()
          alert(error.message, 'danger')
          throw rejectWithValue(error)
        }

        if (onBusinessError) {
          onBusinessError(error, args, thunkAPI)
        }

        throw rejectWithValue(error)
      }

      try {
        return await actionCreator(args, thunkAPI)
      } catch (err) {
        const handled = onError ? onError(err, args, thunkAPI) : false

        if (!handled) {
          handleError(err, handleBusinessError)
        }

        return rejectWithValue()
      }
    },
    restOptions,
  )
}

import get from 'lodash/get'

import { getCustomActions } from './../getCustomActions'

export const makeCurrentItemActions = (entity, options = {}) => {
  const prefix = `${entity.toUpperCase()}_CURRENT`
  const storePath = options.storePath || `${entity}.current`

  const actions = {
    init: (data) => ({
      type: `${prefix}_INIT`,
      data,
    }),
    fetch: (id) => ({
      type: `${prefix}_FETCH`,
      $endpoint: [`${entity}.fetchItem`, { id }],
      id,
    }),
    update: (data, path, replace) => ({
      type: `${prefix}_UPDATE`,
      data,
      path,
      replace,
    }),
    create: (initial, urlParams) => {
      return (dispatch, getState) => {
        const item = initial || get(getState(), storePath).item

        return dispatch({
          type: `${prefix}_CREATE`,
          $endpoint: [`${entity}.create`, urlParams, item],
        })
      }
    },
    save: () => {
      return (dispatch, getState) => {
        const { item } = get(getState(), storePath)

        return dispatch({
          type: `${prefix}_SAVE`,
          $endpoint: [`${entity}.save`, null, item],
        })
      }
    },
    updatedNotification: (item) => ({
      type: `${prefix}_UPDATED_NOTIFICATION`,
      entity,
      item,
    }),
  }

  const customActions = getCustomActions(entity, prefix)

  return { ...actions, ...customActions }
}

import React from 'react'
import { useAppDispatch } from 'reduxTyped'
import { BtnV2, l, useToast } from '@manychat/manyui'

import { contentManagementActions } from 'apps/cms/store'

interface MoveToAlertProps {
  pathTo: string
}

export const MoveToAlert = ({ pathTo }: MoveToAlertProps) => {
  const dispatch = useAppDispatch()
  const { onClose } = useToast()

  const handleClick = () => {
    dispatch(
      contentManagementActions.updateQuery({
        path: pathTo,
      }),
    )
    onClose()
  }

  return (
    <BtnV2 onClick={handleClick} fullWidth className="m-t">
      {l.translate('Open Folder')}
    </BtnV2>
  )
}

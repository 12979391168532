import * as actionTypes from 'apps/chat/constants/LiveChatReduxActionTypes'
import { ChatState } from 'apps/chat/models/chatInterfaces'
import { IActionWithPayload } from 'common/core/interfaces/actions'
import { ScheduledMessage } from 'shared/api/requests/scheduledMessages/schemas'

const initialState: ChatState['scheduledMessages'] = null

function getUpdatedMessagesList(
  messages: ChatState['scheduledMessages'],
  message: ScheduledMessage,
) {
  if (!messages) {
    return [message]
  }

  const messageID = message.scheduled_message_id
  const messageIndex = messages.findIndex((message) => message.scheduled_message_id === messageID)
  const updatedMessages = [...messages]

  if (messageIndex !== -1) {
    updatedMessages.splice(messageIndex, 1, message)
  } else {
    updatedMessages.push(message)
  }

  return updatedMessages
}

export function chatScheduledMessagesReducer(
  state: ChatState['scheduledMessages'] = initialState,
  action: IActionWithPayload,
) {
  const messages = state || []

  switch (action.type) {
    case actionTypes.SCHEDULED_MESSAGES_LIST_CLEAR:
      return null

    case actionTypes.SCHEDULED_MESSAGES_GET_LIST_SUCCESS:
      return action.scheduledMessages

    case actionTypes.SCHEDULED_MESSAGES_CREATE_SUCCESS:
      return [...messages, action.scheduledMessage]

    case actionTypes.SCHEDULED_MESSAGES_UPDATE_SUCCESS:
      return getUpdatedMessagesList(messages, action.scheduledMessage)

    case actionTypes.SCHEDULED_MESSAGES_DELETE_SUCCESS:
      return [...messages].filter(
        (message) => message.scheduled_message_id !== action.scheduledMessageID,
      )

    default:
      return messages
  }
}

import { api } from 'shared/api'
import {
  ApplyFlowSchema,
  CreateFlowDraftSchema,
  GetChatHistorySchema,
  GetFlowDraftSchema,
  SendChatEventSchema,
  SendChatMessageSchema,
  StartChatSchema,
} from 'shared/api/requests/aiAssistant/schemas'

export const AiAssistantApi = {
  createFlowDraft: api.account.createPost({
    url: '/aIAssistant/createFlowDraft',
    schemas: CreateFlowDraftSchema,
  }),

  applyFlow: api.account.createPost({
    url: '/aIAssistant/applyFlow',
    schemas: ApplyFlowSchema,
  }),

  sendChatMessage: api.account.createPost({
    url: '/aIAssistant/sendChatMessage',
    schemas: SendChatMessageSchema,
  }),

  getFlowDraft: api.account.createPost({
    url: '/aIAssistant/getFlowDraft',
    schemas: GetFlowDraftSchema,
  }),

  startChat: api.account.createPost({
    url: '/aIAssistant/startChat',
    schemas: StartChatSchema,
  }),

  sendChatEvent: api.account.createPost({
    url: '/aIAssistant/chatEvent',
    schemas: SendChatEventSchema,
  }),

  getChatHistory: api.account.createPost({
    url: '/aIAssistant/getHistory',
    schemas: GetChatHistorySchema,
  }),
}

import { z } from 'zod'

import { KeywordsLimitErrorSchema, ProErrorSchema } from '../schemas/errors'

export type ProError = z.infer<typeof ProErrorSchema>
export type KeywordLimitError = z.infer<typeof KeywordsLimitErrorSchema>

export const isProError = (error: unknown): error is ProError =>
  ProErrorSchema.safeParse(error).success

export const isKeywordLimitError = (error: unknown): error is KeywordLimitError =>
  KeywordsLimitErrorSchema.safeParse(error).success

import React from 'react'
import { useAppDispatch, useAppSelector } from 'reduxTyped'
import { l } from '@manychat/manyui'

import {
  DocumentVisibilityState,
  PageLifecycleState,
} from 'apps/chat/constants/LiveChatNotifications'
import { useLatestNotificationState } from 'apps/chat/hooks/useLatestNotificationState'
import { getThreadsCounterForTab } from 'apps/chat/selectors/countersSelectors'
import { refreshTitle } from 'common/core/actions/pageActions'
import { usePageVisibilityState } from 'common/hooks/usePageVisibilityState'
import { getFaviconNewMessageURL } from 'utils/assets'
import { getPageLifecycleState } from 'utils/getPageLifecycleState'
import { analyticsService } from 'utils/services/analytics'

enum TabState {
  DEFAULT = 'default',
  NEW_MESSAGE_NOTIFICATION = 'newMessageNotification',
}

export const useLivechatTabTitleNotification = () => {
  const dispatch = useAppDispatch()
  const visibilityState = usePageVisibilityState()
  const { wasLatestNotificationRead, setWasLatestNotificationRead } = useLatestNotificationState()

  const tabState = React.useRef(TabState.DEFAULT)
  const standardFaviconUrl = React.useRef('')
  const faviconDomNodeRef = React.useRef<HTMLLinkElement | null>(null)
  const tabBlinkingInterval = React.useRef<null | ReturnType<typeof setTimeout>>(null)

  const tabTitleCounter = useAppSelector(getThreadsCounterForTab)
  const tabTitle = useAppSelector((state) => state.app.title)

  React.useEffect(() => {
    const faviconDomNode = document.querySelector<HTMLLinkElement>("link[rel*='icon']")

    faviconDomNodeRef.current = faviconDomNode
    standardFaviconUrl.current = faviconDomNode?.href || ''
  }, [])

  const triggerTabTitleNotification = React.useCallback(
    (userName?: string | null) => {
      const defaultStateTitle = tabTitleCounter ? `(${tabTitleCounter}) ${tabTitle}` : tabTitle
      const newMessageStateTitle = userName
        ? l.translate('{userName} sent a new message', { userName })
        : l.translate('New message')

      setWasLatestNotificationRead(false)
      analyticsService.sendEvent('LIVE_CHAT.NOTIFICATION.TAB_TITLE_TRIGGERED')

      if (getPageLifecycleState() !== PageLifecycleState.ACTIVE && !tabBlinkingInterval.current) {
        if (faviconDomNodeRef.current) {
          faviconDomNodeRef.current.href = getFaviconNewMessageURL()
        }

        tabBlinkingInterval.current = setInterval(() => {
          document.title =
            tabState.current === TabState.DEFAULT ? defaultStateTitle : newMessageStateTitle

          tabState.current =
            tabState.current === TabState.DEFAULT
              ? TabState.NEW_MESSAGE_NOTIFICATION
              : TabState.DEFAULT
        }, 1500)
      }
    },
    [tabTitleCounter, setWasLatestNotificationRead, tabTitle],
  )

  const resetTabState = React.useCallback(() => {
    tabBlinkingInterval?.current && clearInterval(tabBlinkingInterval.current)
    tabBlinkingInterval.current = null

    if (faviconDomNodeRef.current) {
      faviconDomNodeRef.current.href = standardFaviconUrl.current
    }

    dispatch(refreshTitle())
  }, [dispatch])

  React.useEffect(() => {
    if (wasLatestNotificationRead || visibilityState === DocumentVisibilityState.VISIBLE) {
      resetTabState()
      setWasLatestNotificationRead(true)
    }
  }, [visibilityState, resetTabState, wasLatestNotificationRead, setWasLatestNotificationRead])

  return { triggerTabTitleNotification, tabBlinkingInterval }
}

/* eslint-disable @typescript-eslint/no-extra-semi */

import {
  createAction,
  // eslint-disable-next-line no-restricted-imports
  createAsyncThunk,
  isAnyOf,
  createSlice,
  Draft,
  SliceCaseReducers,
} from '@reduxjs/toolkit'
import { ValidateSliceCaseReducers } from '@reduxjs/toolkit/src/createSlice'
import { ActionReducerMapBuilder } from '@reduxjs/toolkit/src/mapBuilders'
import { NoInfer } from '@reduxjs/toolkit/src/tsHelpers'
import hash from 'hash-sum'
import get from 'lodash/get'
import merge from 'lodash/merge'
import { DeepPartial } from 'utility-types'

import { Prettify } from 'shared/types/core'

enum ItemStateType {
  UPDATING = 'updating',
  DELETING = 'deleting',
}

type CreatedItemAction = 'append' | 'prepend' | 'ignore'

type ItemState = ItemStateType | undefined

type UnknownPropertiesLimiterItem = Record<string | number, unknown>

export interface InitialItemWithLimiter extends UnknownPropertiesLimiterItem {
  limiter?: number | null
  later_limiter?: number | null
}

interface ListState<Item, Id extends string | number> extends UnknownPropertiesLimiterItem {
  byId: Record<Id, Item>
  items: Item[]
  listState: {
    isLoading: boolean
    isLoaded: boolean
    isUpdating: boolean
  }
  listRequestsInfo: {
    lastRequestId: string
    lastRequestPayloadHash: string
  }
  limiter: number | null
  laterLimiter: number | null
  listEarlyLimiter: number | null
  listLaterLimiter: number | null
  fetchArgs: UnsafeAnyObject | null
  hasDropItems: boolean
  itemState: Record<Id, ItemState>
}

const getWrongPathMessage = (path: string) => `There is no reducer by '${path}' path`

export const NEW_LIST_ITEM_ID = '$new'

export const createListSlice = <
  Item,
  ItemWithLimiter extends InitialItemWithLimiter,
  GetListArgs,
  GetNextListArgs,
  GetPrevListArgs,
  CreateItemArgs,
  UpdateItemArgs,
  DeleteItemArgs,
  DeleteItemResult,
  Id extends string | number,
  ExtraState,
  CaseReducers extends SliceCaseReducers<ListState<Item, Id> & ExtraState>,
  Reducers extends ValidateSliceCaseReducers<ListState<Item, Id> & ExtraState, CaseReducers>,
  ExtraReducers extends (
    builder: ActionReducerMapBuilder<NoInfer<ListState<Item, Id> & ExtraState>>,
  ) => void,
>(options: {
  /**
   * The actual path for the reducer in the store.
   */
  storePath: string
  /**
   * The prefix that is used for all actions in their names.
   */
  namespace: string

  listKey?: string

  operations: {
    /**
     * A function that returns a list of items.
     */
    getList?: (args: GetListArgs) => Promise<ItemWithLimiter | Item[]>
    /**
     * A function that returns the next list of items in the queue.
     */
    getNextList?: (args: GetNextListArgs) => Promise<ItemWithLimiter | Item[]>
    /**
     * A function that returns the previous list of items in the queue.
     */
    getPrevList?: (args: GetPrevListArgs) => Promise<ItemWithLimiter | Item[]>
    /**
     * A function that deletes an item.
     */
    deleteItem?: (args: DeleteItemArgs) => Promise<DeleteItemResult>
    /**
     * A function that creates an item and returns it.
     */
    createItem?: (args: CreateItemArgs) => Promise<Item>
    /**
     * A function that updates an item and returns the new version of it.
     */
    updateItem?: (args: UpdateItemArgs) => Promise<Item>
  }
  /**
   * A function that returns the id of an item.
   */
  getItemId: (item: Item | Draft<Item>) => Id
  /**
   * **Description**
   *
   * An object with an extra state. Provide an empty object if you don't need it.
   *
   * You should use it in the same way as you provide `initialState` to `createSlice`.
   *
   * **Example**
   * ```
   * interface ExtraState {
   *   lastCreationTimestamp: null | null
   * }
   *
   * export const slice = createListSlice({
   *   extraState: {
   *     lastCreationTimestamp: null,
   *   } as ExtraState,
   * })
   * ```
   */
  extraState: ExtraState
  /**
   * **Description**
   *
   * An object with redux-thunk reducers. Provide an empty object if you don't need it.
   *
   * You should use it in the same way as you provide `reducers` to `createSlice`.
   *
   * **Example**
   * ```
   * import { PayloadAction } from '@reduxjs/toolkit'
   *
   * interface ExtraState {
   *   lastCreationTimestamp: null | null
   * }
   *
   * export const slice = createListSlice({
   *   extraState: {
   *     lastCreationTimestamp: null,
   *   } as ExtraState,
   *   reducers: {
   *     updateTimestamp(state, action: PayloadAction<number>) {
   *       state.lastCreationTimestamp = action.payload
   *     }
   *   },
   * })
   *
   * store.dispatch(slice.actions.updateTimestamp(1))
   * ```
   */
  reducers: Reducers
  /**
   * **Description**
   *
   * A callback that is needed in situations when you need to add more builder cases.
   *
   * You should use it in the same way as you provide `extraReducers` to `createSlice`.
   *
   * **Example**
   * ```
   * const replaceAllItemsWithRandomOnes = createAsyncThunk('namespace/replaceAllItemsWithRandomOnes', () => {
   *   const response = pseudoRequest()
   *   return response.data.items
   * })
   *
   * export const slice = createListSlice({
   *   extraState: {},
   *   reducers: {},
   *   extraReducers(builder) {
   *     builder.addCase(replaceAllItemsWithRandomOnes.fulfilled, (state, action) => {
   *       state.items = action.payload
   *     })
   *   }
   * })
   * ```
   */
  extraReducers?: ExtraReducers
}) => {
  type State = ListState<Item, Id> & ExtraState
  type ById = State['byId']

  const prefix = `@${options.namespace}List`

  const resetState = createAction(`${prefix}/reset`)
  const updateFetchArgsHash = createAction<string>(`${prefix}/updateFetchArgsHash`)
  const updateFetchArgs = createAction<GetListArgs>(`${prefix}/updateFetchArgs`)
  const updateDropItemsStatus = createAction<boolean>(`${prefix}/updateDropItemsStatus`)
  const updateLimiter = createAction<null | number>(`${prefix}/updateLimiter`)
  const updateLastRequestId = createAction<string>(`${prefix}/updateLastRequestId`)
  const updateListState = createAction<Partial<State['listState']>>(`${prefix}/updateListState`)
  /**
   * Deletes an item from the state.
   */
  const deleteItem = createAction<{ id: Id }>(`${prefix}/deleteItem`)
  const bulkDeleteItems = createAction<{ ids: Id[] }>(`${prefix}/bulkDeleteItems`)
  const appendItem = createAction<{ item: Item }>(`${prefix}/appendItem`)
  const bulkAppendItems = createAction<{ items: Item[] }>(`${prefix}/bulkAppendItems`)
  const prependItem = createAction<{ item: Item }>(`${prefix}/prependItem`)
  const dropList = createAction(`${prefix}/dropList`)
  /**
   * We need a union type because when a developer wants to rewrite an item in the state,
   * they should provide the whole item.
   */
  const updateItem = createAction<
    | {
        id: Id
        data: DeepPartial<Item> | Item
      }
    | {
        id: Id
        data: Item
        rewrite: true
      }
  >(`${prefix}/updateItem`)
  const bulkUpdateItems = createAction<
    | {
        data: Array<{ id: Id; item: DeepPartial<Item> | Item }>
      }
    | {
        data: Array<{ id: Id; item: Item }>
        rewrite: true
      }
  >(`${prefix}/bulkUpdateItems`)
  const updateItemState = createAction<{ id: Id; state: ItemState | null }>(
    `${prefix}/updateItemState`,
  )

  interface EffectConfig {
    forceRequest?: boolean
  }

  type FetchListFxArguments<T> = Extract<T, void> extends never
    ? { payload: T; effectConfig?: Prettify<EffectConfig> }
    : { payload?: T; effectConfig?: Prettify<EffectConfig> } | void

  /**
   * Calls the "getList" function, and if it succeeds, saves its result to the state.
   *
   * **Notes**:
   * - It doesn't provide any data to the `getList` function by default.
   * - You should use `payload` field to send necessary data as payload of the `getList` function.
   * - It does nothing when payload data wasn't changed since the last request.
   * Use `{ effectConfig: { forceRequest: true } }` to send a request without comparing the hash of the payload.
   */
  const getListFx = createAsyncThunk(
    `${prefix}/fetch`,
    async (
      args: FetchListFxArguments<GetListArgs> | undefined,
      { dispatch, getState, requestId, rejectWithValue },
    ) => {
      if (!options.operations.getList) {
        return
      }

      const state: State | undefined = get(getState(), options.storePath)
      if (!state) {
        throw new Error(getWrongPathMessage(options.storePath))
      }

      const payloadHash: string = hash(args?.payload)
      const hasDifferenceHash = payloadHash !== state.listRequestsInfo.lastRequestPayloadHash
      const hasForceRequest = args?.effectConfig?.forceRequest ?? false
      const hasDropItems = hasDifferenceHash || hasForceRequest

      if (!hasDropItems) {
        if (state.listState.isLoading || state.listState.isLoaded) {
          if (state.limiter !== null) {
            return console.log(
              `[${prefix}/fetch] please use "fetchNext" method to retrieve next pages`,
            )
          }
          return
        }
      }

      dispatch(
        updateListState({
          isLoading: true,
          isUpdating: state.listState.isLoaded,
        }),
      )
      dispatch(updateLimiter(hasDropItems ? null : state.limiter))
      dispatch(updateDropItemsStatus(hasDropItems))
      dispatch(updateFetchArgs(args?.payload))
      dispatch(updateFetchArgsHash(payloadHash))
      dispatch(updateLastRequestId(requestId))

      try {
        return await options.operations.getList(args?.payload as GetListArgs)
      } catch (error) {
        throw rejectWithValue(error)
      }
    },
  )

  const getNextListFx = createAsyncThunk(
    `${prefix}/fetchNext`,
    async (
      args: FetchListFxArguments<GetNextListArgs> | undefined,
      { dispatch, getState, requestId, rejectWithValue },
    ) => {
      if (!options.operations.getNextList) {
        return
      }

      const state: State | undefined = get(getState(), options.storePath)
      if (!state) {
        throw new Error(getWrongPathMessage(options.storePath))
      }

      const hasLoading = state.listState.isLoading
      if (hasLoading) {
        return
      }

      const hasEmpty = state.items && Array.isArray(state.items) && state.items.length === 0
      const hasLoaded = state.listState.isLoaded
      if (!hasLoaded && hasEmpty) {
        return console.log(`[${prefix}/fetchNext] please use "fetch" method to retrieve first page`)
      }

      if (state.listEarlyLimiter === null) {
        return console.log(
          `[${prefix}/fetchNext] listEarlyLimiter is null - cannot fetch next page`,
        )
      }

      if (!state.listEarlyLimiter) {
        return console.log(`[${prefix}/fetchNext] listEarlyLimiter is not defined`)
      }

      const payloadHash: string = hash(args?.payload)

      dispatch(
        updateListState({
          isLoading: true,
          isUpdating: state.listState.isLoaded,
        }),
      )
      dispatch(updateFetchArgsHash(payloadHash))
      dispatch(updateLastRequestId(requestId))

      try {
        const { fetchArgs, listEarlyLimiter } = state
        const query = { limiter: listEarlyLimiter, limiter_type: 'early' }
        const { payload } = merge(args, { payload: fetchArgs }, { payload: { query } })

        return await options.operations.getNextList(payload as GetNextListArgs)
      } catch (error) {
        throw rejectWithValue(error)
      }
    },
  )

  const getPrevListFx = createAsyncThunk(
    `${prefix}/fetchPrev`,
    async (
      args: FetchListFxArguments<GetPrevListArgs> | undefined,
      { dispatch, getState, requestId, rejectWithValue },
    ) => {
      if (!options.operations.getPrevList) {
        return
      }

      const state: State | undefined = get(getState(), options.storePath)
      if (!state) {
        throw new Error(getWrongPathMessage(options.storePath))
      }

      const hasLoading = state.listState.isLoading
      if (hasLoading) {
        return
      }

      const hasEmpty = state.items && Array.isArray(state.items) && state.items.length === 0
      const hasLoaded = state.listState.isLoaded
      if (!hasLoaded && hasEmpty) {
        return console.log(`[${prefix}/fetchPrev] please use "fetch" method to retrieve first page`)
      }

      if (state.listLaterLimiter === null) {
        return console.log(
          `[${prefix}/fetchPrev] listLaterLimiter is null - cannot fetch next page`,
        )
      }

      if (!state.listLaterLimiter) {
        return console.log(`[${prefix}/fetchPrev] listLaterLimiter is not defined`)
      }

      const payloadHash: string = hash(args?.payload)

      dispatch(
        updateListState({
          isLoading: true,
          isUpdating: state.listState.isLoaded,
        }),
      )
      dispatch(updateFetchArgsHash(payloadHash))
      dispatch(updateLastRequestId(requestId))

      try {
        const { fetchArgs, listLaterLimiter } = state
        const query = { limiter: listLaterLimiter, limiter_type: 'later' }
        const { payload } = merge(args, { payload: fetchArgs }, { payload: { query } })

        return await options.operations.getPrevList(payload as GetPrevListArgs)
      } catch (error) {
        throw rejectWithValue(error)
      }
    },
  )

  type DeleteItemFxArguments = Extract<DeleteItemArgs, void> extends never
    ? { payload: DeleteItemArgs; id: Id }
    : { payload?: DeleteItemArgs; id: Id }

  /**
   * Calls the `deleteItem` function with payload, and if it succeeds,
   * removes the item from the state by the provided id.
   *
   * **Notes**:
   * - It doesn't provide any data to the `deleteItem` function by default.
   * - You should use `payload` field to send necessary data as payload of the `deleteItem` function.
   */
  const deleteItemFx = createAsyncThunk(
    `${prefix}/delete`,
    async (args: DeleteItemFxArguments, { dispatch, getState, rejectWithValue }) => {
      if (!options.operations.deleteItem) {
        throw new Error('Operation deleteItem was not provided')
      }

      const state: State | undefined = get(getState(), options.storePath)

      if (!state) {
        throw new Error(getWrongPathMessage(options.storePath))
      }

      dispatch(updateItemState({ id: args.id, state: ItemStateType.DELETING }))

      try {
        const result = await options.operations.deleteItem(args.payload as DeleteItemArgs)

        dispatch(deleteItem({ id: args.id }))

        return result
        /**
         * An error should be rethrown to support `thunk.rejected` mechanism and catch errors
         * on the place of call.
         */
      } catch (error) {
        throw rejectWithValue(error)
      } finally {
        dispatch(updateItemState({ id: args.id, state: null }))
      }
    },
  )

  interface CreateItemEffectConfig {
    createdItemAction?: CreatedItemAction
  }

  type CreateItemFxArgs = Extract<CreateItemArgs, void> extends never
    ? { payload: CreateItemArgs; effectConfig?: Prettify<CreateItemEffectConfig> }
    : { payload?: CreateItemArgs; effectConfig?: Prettify<CreateItemEffectConfig> } | void

  /**
   * Calls the `createItem` function with payload, and if it succeeds,
   * saves a result from it to the state.
   *
   * **Notes**:
   * - It doesn't provide any data to the `createItem` function by default.
   * - You should use `payload` field to send necessary data as payload of the `createItem` function.
   */
  const createItemFx = createAsyncThunk(
    `${prefix}/create`,
    async (args: CreateItemFxArgs, { getState, dispatch, rejectWithValue }) => {
      if (!options.operations.createItem) {
        throw new Error('Operation createItem was not provided')
      }

      const state: State | undefined = get(getState(), options.storePath)

      if (!state) {
        throw new Error(getWrongPathMessage(options.storePath))
      }

      dispatch(updateItemState({ id: NEW_LIST_ITEM_ID as Id, state: ItemStateType.UPDATING }))

      try {
        const item = await options.operations.createItem(args?.payload as CreateItemArgs)

        const action: CreatedItemAction = get(args, 'effectConfig.createdItemAction', 'append')

        if (action === 'append') {
          dispatch(appendItem({ item }))
        } else if (action === 'prepend') {
          dispatch(prependItem({ item }))
        }

        return item as Item
        /**
         * An error should be rethrown to support `thunk.rejected` mechanism and catch errors
         * on the place of call.
         */
      } catch (error) {
        throw rejectWithValue(error)
      } finally {
        dispatch(updateItemState({ id: NEW_LIST_ITEM_ID as Id, state: null }))
      }
    },
  )

  type UpdateItemFxArgs =
    | { id: Id; payload: UpdateItemArgs }
    | {
        id: Id
        getUpdateItemArgs: (item: Item) => UpdateItemArgs
      }

  /**
   * Calls the `updateItem` function with payload, and if it succeeds,
   * saves a result from it to the state.
   *
   * **Notes**:
   * - It doesn't provide any data to `updateItem` function by default.
   * - If you have access to the item's state on the place of calling,
   *   you should use `payload` field to send necessary data as payload
   *   of `updateItem` function.
   * ```
   * const useSomeData = () => {
   *  const item = useAppSelector(...)
   *  const dispatch = useAppDispatch()
   *
   *  const do = () => {
   *    createdSlice.actions.updateItemFx({
   *      id: item.id,
   *      payload: mapItem(item),
   *    })
   *  }
   * }
   * ```
   * - If you don't have access to the item's state on the place of calling,
   *   you should use the `getUpdateItemArgs` function, which is called with
   *   an item from the store.
   * ```
   * export const slice = createListSlice({
   *  extraState: {},
   *  reducers: {},
   * })
   *
   * store.dispatch(slice.updateItemFx({
   *   id: '1',
   *   getUpdateItemArgs: (itemFromTheState) => ({ item: exportItem(itemFromTheState) }),
   * }))
   *   ```
   */
  const updateItemFx = createAsyncThunk(
    `${prefix}/save`,
    async (args: UpdateItemFxArgs, { getState, dispatch, rejectWithValue }) => {
      if (!options.operations.updateItem) {
        throw new Error('Operation updateItem was not provided')
      }

      const state: State | undefined = get(getState(), options.storePath)

      if (!state) {
        throw new Error(getWrongPathMessage(options.storePath))
      }

      dispatch(updateItemState({ id: args.id, state: ItemStateType.UPDATING }))

      try {
        let item: Item

        if ('payload' in args) {
          item = await options.operations.updateItem(args.payload)
        } else {
          const requestArgs = args.getUpdateItemArgs(state.byId[args.id])

          item = await options.operations.updateItem(requestArgs)
        }

        dispatch(
          updateItem({
            id: args.id,
            data: item,
            rewrite: true,
          }),
        )

        return item
        /**
         * An error should be rethrown to support `thunk.rejected` mechanism and catch errors
         * on the place of call.
         */
      } catch (error) {
        throw rejectWithValue(error)
      } finally {
        dispatch(updateItemState({ id: args.id, state: null }))
      }
    },
  )

  const initialState: State = {
    byId: {} as State['byId'],
    items: [],
    listState: {
      isLoading: false,
      isLoaded: false,
      isUpdating: false,
    },
    listRequestsInfo: {
      lastRequestId: '',
      lastRequestPayloadHash: '',
    },
    limiter: null,
    laterLimiter: null,
    listEarlyLimiter: null,
    listLaterLimiter: null,
    fetchArgs: null,
    hasDropItems: false,
    itemState: {} as State['itemState'],
    ...options.extraState,
  }

  const completeFetch = (state: Draft<NoInfer<State>>) => {
    state.listState.isLoading = false
    state.listState.isLoaded = true
    state.listState.isUpdating = false
    return state
  }

  const slice = createSlice<State, CaseReducers>({
    name: options.namespace,
    initialState,
    reducers: options.reducers,
    extraReducers: (builder) => {
      builder.addCase('APP_UPDATE_CURRENT_ACCOUNT_ID', () => initialState)

      builder.addCase(dropList, () => initialState)

      builder.addCase(resetState, () => ({ ...initialState, ...options.extraState }))

      builder.addCase(updateLimiter, (state, action) => {
        state.limiter = action.payload
      })

      builder.addCase(updateFetchArgsHash, (state, action) => {
        state.listRequestsInfo.lastRequestPayloadHash = action.payload
      })

      builder.addCase(updateFetchArgs, (state, action) => {
        state.fetchArgs = action.payload
      })

      builder.addCase(updateDropItemsStatus, (state, action) => {
        state.hasDropItems = action.payload
      })

      builder.addCase(updateLastRequestId, (state, action) => {
        state.listRequestsInfo.lastRequestId = action.payload
      })

      builder.addCase(deleteItem, (state, action) => {
        const hasArray = state.items && Array.isArray(state.items)
        if (!hasArray) {
          return
        }

        state.items = state.items.filter((item) => options.getItemId(item) !== action.payload.id)

        delete (state.byId as Record<Id, Item>)[action.payload.id]
      })

      builder.addCase(bulkDeleteItems, (state, action) => {
        if (state.items === null) {
          return
        }

        state.items = state.items.filter((item) => {
          const existingItemId = action.payload.ids.find(
            (itemIdToDelete) => itemIdToDelete === options.getItemId(item),
          )

          if (existingItemId) {
            delete (state.byId as Record<Id, Item>)[existingItemId]
          }

          return !existingItemId
        })
      })

      builder.addCase(appendItem, (state, action) => {
        const hasArray = state.items && Array.isArray(state.items)
        if (!hasArray) {
          return
        }

        const item = { ...action.payload.item, id: options.getItemId(action.payload.item) }
        state.items.push(item as Draft<Item>)
        ;(state.byId as Record<Id, Item>)[options.getItemId(item)] = item
      })

      builder.addCase(prependItem, (state, action) => {
        const hasArray = state.items && Array.isArray(state.items)
        if (!hasArray) {
          return
        }

        const item = { ...action.payload.item, id: options.getItemId(action.payload.item) }
        state.items.unshift(item as Draft<Item>)
        ;(state.byId as Record<Id, Item>)[options.getItemId(item)] = item
      })

      builder.addCase(bulkAppendItems, (state, action) => {
        if (state.items === null) {
          return
        }

        state.items = [...state.items, ...action.payload.items] as Array<Draft<Item>>
        action.payload.items.forEach((item) => {
          ;(state.byId as Record<Id, Item>)[options.getItemId(item)] = item
        })
      })

      builder.addCase(updateItemState, (state, action) => {
        if (action.payload.state === null) {
          delete (state.itemState as Record<Id, ItemState>)[action.payload.id]
          return
        }

        if (action.payload.state === ItemStateType.UPDATING) {
          ;(state.itemState as Record<Id, ItemState>)[action.payload.id] = ItemStateType.UPDATING
          return
        }

        if (action.payload.state === ItemStateType.DELETING) {
          ;(state.itemState as Record<Id, ItemState>)[action.payload.id] = ItemStateType.DELETING
          return
        }
      })

      builder.addCase(updateItem, (state, action) => {
        const hasArray = state.items && Array.isArray(state.items)
        if (!hasArray) {
          return
        }

        if ((state.byId as Record<Id, Item>)[action.payload.id] === undefined) {
          return
        }

        let updatedItem: Item

        if ('rewrite' in action.payload && action.payload.rewrite) {
          updatedItem = { ...action.payload.data, id: options.getItemId(action.payload.data) }
        } else {
          updatedItem = merge((state.byId as Record<Id, Item>)[action.payload.id], {
            ...action.payload.data,
            id: options.getItemId(action.payload.data as Draft<Item>),
          })
        }

        ;(state.byId as Record<Id, Item>)[action.payload.id] = updatedItem

        state.items = state.items.map((item) => {
          if (options.getItemId(item) === action.payload.id) {
            return { ...updatedItem, id: options.getItemId(updatedItem) }
          } else {
            return item
          }
        }) as Array<Draft<Item>>
      })

      builder.addCase(bulkUpdateItems, (state, action) => {
        if (state.items === null) {
          return
        }

        const updatedItems = state.items.map((itemFromState) => {
          const itemToUpdate = action.payload.data.find(
            (newItem) => newItem.id === options.getItemId(itemFromState),
          )

          if (!itemToUpdate) {
            return itemFromState
          }

          if ('rewrite' in action.payload && action.payload.rewrite) {
            return itemToUpdate.item
          } else {
            return merge((state.byId as Record<Id, Item>)[itemToUpdate.id], itemToUpdate.item)
          }
        }) as Array<Draft<Item>>

        state.items = updatedItems

        state.byId = updatedItems.reduce((accumulator, item) => {
          accumulator[options.getItemId(item)] = item as ById[Id]

          return accumulator
        }, {} as ById) as Draft<ById>
      })

      builder.addCase(updateListState, (state, action) => {
        state.listState = { ...state.listState, ...action.payload }
      })

      options.extraReducers?.(builder)

      builder.addMatcher(
        isAnyOf(
          getListFx.rejected.match,
          getNextListFx.rejected.match,
          getPrevListFx.rejected.match,
        ),
        (state) => completeFetch(state),
      )

      builder.addMatcher(
        isAnyOf(
          getListFx.fulfilled.match,
          getNextListFx.fulfilled.match,
          getPrevListFx.fulfilled.match,
        ),
        (state, action) => {
          if (action.meta.requestId !== state.listRequestsInfo.lastRequestId || !action.payload) {
            completeFetch(state)
            return
          }

          const hasStructureWithLimiter = !Array.isArray(action.payload)
          if (hasStructureWithLimiter) {
            const itemsMap = action.payload as Draft<ItemWithLimiter>

            if (!options.listKey) {
              completeFetch(state)
              return
            }

            const { hasDropItems } = state

            const onceRegex = new RegExp(/\/fetch\//gm)
            const nextRegex = new RegExp(/\/fetchNext\//gm)
            const prevRegex = new RegExp(/\/fetchPrev\//gm)
            const isOnceFetching = onceRegex.test(action.type)
            const isNextFetching = nextRegex.test(action.type)
            const isPrevFetching = prevRegex.test(action.type)

            const {
              [options.listKey]: list,
              limiter = null,
              later_limiter: laterLimiter = null,
              ...rest
            } = itemsMap

            let itemsList = list as Array<Draft<Item>>
            itemsList = itemsList.map((item) => {
              const uniqueItem = options.getItemId(item)
              if (uniqueItem) {
                return { ...item, id: uniqueItem }
              }
              return item
            })

            if (isOnceFetching) {
              state.items = hasDropItems ? itemsList : [...state.items, ...itemsList]
            } else if (isPrevFetching) {
              state.items = [...itemsList.reverse(), ...state.items]
            } else if (isNextFetching) {
              state.items = [...state.items, ...itemsList]
            }

            const updatedById = isOnceFetching && hasDropItems ? {} : state.byId
            state.byId = itemsList.reduce((accumulator, item) => {
              accumulator[options.getItemId(item)] = item as ById[Id]
              return accumulator
            }, updatedById as ById) as Draft<ById>

            state.limiter = limiter
            state.laterLimiter = laterLimiter

            const hasNullLimiter = laterLimiter === null && limiter === null
            if (isOnceFetching) {
              state.listEarlyLimiter = limiter
              state.listLaterLimiter = laterLimiter
            } else if (isPrevFetching) {
              state.listLaterLimiter = hasNullLimiter ? null : limiter
            } else if (isNextFetching) {
              state.listEarlyLimiter = hasNullLimiter ? null : limiter
            }

            for (const key in rest) {
              state[key] = rest[key]
            }

            completeFetch(state)
            return
          }

          const itemsList = action.payload as Array<Draft<Item>>
          state.items = itemsList.map((item) => {
            const uniqueItem = options.getItemId(item)
            if (uniqueItem) {
              return { ...item, id: uniqueItem }
            }
            return item
          })

          state.byId = itemsList.reduce((accumulator, item) => {
            accumulator[options.getItemId(item)] = item as ById[Id]
            return accumulator
          }, {} as ById) as Draft<ById>

          completeFetch(state)
        },
      )
    },
  })

  const getState = (state: unknown) => get(state, options.storePath) as State

  const getListState = (state: unknown) => getState(state).listState

  const getItemState = (state: unknown, itemId: Id) => getState(state).itemState[itemId]

  const getIsItemDeleting = (state: unknown, itemId: Id) =>
    getItemState(state, itemId) === ItemStateType.DELETING

  const getIsItemUpdating = (state: unknown, itemId: Id) =>
    getItemState(state, itemId) === ItemStateType.UPDATING

  const selectors = {
    getState,
    getList: (state: unknown) => getState(state).items,
    getMap: (state: unknown) => getState(state).byId,
    getItemById: (state: unknown, id: Id) => getState(state).byId[id],
    getLimiter: (state: unknown) => getState(state).limiter,
    getLaterLimiter: (state: unknown) => getState(state).laterLimiter,
    getListEarlyLimiter: (state: unknown) => getState(state).listEarlyLimiter,
    getListLaterLimiter: (state: unknown) => getState(state).listLaterLimiter,
    getListState,
    getIsListLoading: (state: unknown) => getListState(state).isLoading,
    getIsListLoaded: (state: unknown) => getListState(state).isLoaded,
    getIsListUpdating: (state: unknown) => getListState(state).isUpdating,
    getItemState,
    getIsItemDeleting,
    getIsItemUpdating,
    getIsNewItemCreating: (state: unknown) => getIsItemUpdating(state, NEW_LIST_ITEM_ID as Id),
    getIsItemProcessingAction: (state: unknown, itemId: Id) =>
      getIsItemDeleting(state, itemId) || getIsItemUpdating(state, itemId),
  }

  return {
    reducer: slice.reducer,

    actions: {
      ...slice.actions,
      getListFx,
      getNextListFx,
      getPrevListFx,
      dropList,
      deleteItemFx,
      createItemFx,
      updateItemFx,
      resetState,
      updateItem,
      deleteItem,
      appendItem,
      bulkAppendItems,
      prependItem,
      bulkUpdateItems,
      bulkDeleteItems,
      updateListState,
      updateItemState,
    },

    selectors,

    initialState,
  }
}

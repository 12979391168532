import React from 'react'

export const useAutocomplete = (label: string, focus?: boolean) => {
  const queryRef = React.useRef('')
  const [text, internalSetText] = React.useState(label)

  React.useEffect(() => {
    queryRef.current = ''
    internalSetText(label)
  }, [label, focus])

  const setText = (value: string) => {
    queryRef.current = value === label ? '' : value
    internalSetText(value)
  }

  return {
    query: queryRef.current,
    text,
    setText,
  }
}

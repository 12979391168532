import BaseBlockConfig from 'common/builder/blocks/BaseBlockConfig'
import {
  EmailStyleAlignmentPreset,
  EmailStyleImageSizePreset,
} from 'common/builder/blocks/blockConstants'
import { IBlockBase, IEmailImageBlock } from 'common/builder/blocks/blockInterfaces'
import { BlockType } from 'common/builder/constants/BlockType'
import { EmailStyleType } from 'common/builder/nodes/emailNew/emailNodeConstants'

class EmailImageBlockConfig extends BaseBlockConfig {
  blockType = BlockType.EMAIL_IMAGE

  getDefaults = (): Omit<IEmailImageBlock, keyof IBlockBase> => ({
    image: null,
    settings: {
      image_size: { type: EmailStyleType.PRESET, value: EmailStyleImageSizePreset.LARGE },
      border_radius: { type: EmailStyleType.CUSTOM, value: 0 },
      alignment: { type: EmailStyleType.PRESET, value: EmailStyleAlignmentPreset.CENTER },
      padding_top: { type: EmailStyleType.CUSTOM, value: 12 },
      padding_bottom: { type: EmailStyleType.CUSTOM, value: 12 },
      block_background_color: { type: EmailStyleType.CUSTOM, value: '' },
    },
  })
}

export default new EmailImageBlockConfig()

import { updateNode } from 'common/builder/actions/updateNode'
import {
  CommentReplyIgNodeProperties,
  Within24IgNodeProperties,
} from 'common/builder/constants/MessageTypeIgNodeProperties'
import { getFirstNode } from 'common/builder/selectors/builder/builderStateSelectors'
import { isNodeIgCommentReplyType } from 'common/builder/utils/isNodeIgCommentReplyType'
import { isNodePassesIgCommentReplyLimitations } from 'common/builder/utils/isNodePassesIgCommentReplyLimitations'
import { IThunkAction } from 'common/core/interfaces/actions'
import { getFlowWidgetTriggers, hasInstagramCGT } from 'common/flow/selectors/flowSelectors'
import { isInstagramCgt } from 'common/flow/utils/isInstagramCgt'

import { updateSourceTarget } from './sourceActions'

export function setFirstIgNodeAsCommentReplyIfPossible(builderId: string): IThunkAction {
  return (dispatch, getState) => {
    const state = getState()
    const builder = state.builders.byId[builderId]
    const firstNode = getFirstNode(state, builderId)

    if (!firstNode) {
      return
    }

    const nodeIsPassesCommentReplyLimits = isNodePassesIgCommentReplyLimitations({
      node: firstNode,
      builderId,
      state,
    })
    const hasIgCgtOnFlow = hasInstagramCGT(state, builder.state.flow)
    const nodeIsNotCommentReply = !isNodeIgCommentReplyType(firstNode)

    if (nodeIsPassesCommentReplyLimits && hasIgCgtOnFlow && nodeIsNotCommentReply) {
      dispatch(updateNode(builderId, firstNode.id, CommentReplyIgNodeProperties))
      dispatch(updateSourceTarget(builderId, firstNode.id, null))
    }
  }
}

export function setFirstIgNodeAsWithin24IfPossible(builderId: string): IThunkAction {
  return (dispatch, getState) => {
    const state = getState()
    const builder = state.builders.byId[builderId]
    const firstNode = getFirstNode(state, builderId)
    const triggers = getFlowWidgetTriggers(state, builder.state.flow)

    const igCommentTriggers = triggers.filter(isInstagramCgt)

    if (!firstNode) {
      return
    }

    const nodeIsCommentReply = isNodeIgCommentReplyType(firstNode)

    if (igCommentTriggers.length === 1 && nodeIsCommentReply) {
      dispatch(updateNode(builderId, firstNode.id, Within24IgNodeProperties))
    }
  }
}

import errorTrackingService from 'utils/services/errorTrackingService'

const FALLBACK_NEXT_PATH = '/'

export function sanitizeNextPath(nextPath: string) {
  try {
    const decodedUrl = decodeURIComponent(nextPath)
    return /^\/(?!\/)/.test(decodedUrl) ? decodedUrl : FALLBACK_NEXT_PATH
  } catch (error) {
    errorTrackingService.logError(error, {
      scope: 'root',
      section: null,
      fingerprint: 'sanitize_next_path',
    })
    return FALLBACK_NEXT_PATH
  }
}

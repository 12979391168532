import errorTrackingService from 'utils/services/errorTrackingService'

export interface ILanguageNavigator {
  languages?: readonly string[]
  userLanguage?: string
  language?: string
  browserLanguage?: string
}

export default class LanguageDetector {
  get() {
    return this.getFromNavigator()
  }

  private getFromNavigator(): string | undefined {
    const navigator = this.getNavigator()

    if (navigator.languages?.length) {
      return navigator.languages[0]
    }

    return navigator.userLanguage || navigator.language || navigator.browserLanguage
  }

  protected getNavigator(): ILanguageNavigator {
    return window.navigator
  }
}

const detectBrowserLanguage = () => new LanguageDetector().get()

const FALLBACK_LOCALE = 'en-US'

export const detectLocaleFor = (language: string, detectLanguage = detectBrowserLanguage) => {
  let locale = FALLBACK_LOCALE
  const detected = detectLanguage()
  if (detected && detected.slice(0, 2) === language.slice(0, 2)) {
    locale = detected
  } else {
    locale = language
  }

  try {
    if (Intl.NumberFormat.supportedLocalesOf(locale).length > 0) {
      return locale
    }
  } catch (error) {
    errorTrackingService.trackError(new Error('Unable to detect user locale'), {
      fingerprint: 'detected_language_locale',
      extra: { detected, language, locale },
    })
  }

  return FALLBACK_LOCALE
}

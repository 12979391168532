import { combineReducers } from 'redux'

import account from './account'
import accountCurrency from './accountCurrency'
import adConstructor from './adConstructor'
import data from './adsData'
import currentAdSet from './adSet'
import allAccount from './allAccount'
import currentCampaign from './campaign'
import customAudience from './customAudience'
import table from './table'
import targeting from './targeting'
import wizard from './wizard'

export const adsReducer = combineReducers({
  data,
  table,
  wizard,
  adConstructor,
  targeting,
  currentCampaign,
  currentAdSet,
  accountCurrency,
  customAudience,
  account,
  allAccount,
})

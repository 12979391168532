import React, { useEffect, useState } from 'react'
import { useAppSelector } from 'reduxTyped'

import EmailVerificationModal from 'common/core/components/EmailVerification/EmailVerificationModal'
import { getUserEmail, getUserEmailUnverified } from 'common/core/selectors/appSelectors'
import { isPathnameInclude } from 'utils/router/tools'
import { UserFlag } from 'utils/services/featureFlagsService/constants'
import { useUserReleaseToggle } from 'utils/services/featureFlagsService/releaseTogglesHooks'
import { newOnboardingService } from 'utils/services/newOnboardingService'

const EmailVerificationController = () => {
  const isBlacklistPath = isPathnameInclude('/questionnaire')

  const userEmail = useAppSelector(getUserEmail)
  const userEmailUnverified = useAppSelector(getUserEmailUnverified)

  const hasUnverifiedEmail = Boolean(userEmailUnverified)
  const hasNoEmail = Boolean(!userEmail && !userEmailUnverified)
  const needVerification = hasUnverifiedEmail || hasNoEmail
  const isNewUser = useUserReleaseToggle(UserFlag.MANDATORY_EMAIL_VERIFICATION)
  const showModal = !isBlacklistPath && needVerification && isNewUser
  const [isOpen, setIsOpen] = useState(showModal)

  useEffect(() => {
    if (showModal && !isOpen && !newOnboardingService.isAnyOnboardingRunning()) {
      setIsOpen(true)
    }
  }, [showModal, isOpen])

  useEffect(() => {
    if (isOpen && !needVerification) {
      setIsOpen(false)
    }
  }, [needVerification, isOpen])

  const handleClose = () => {
    setIsOpen(false)
  }

  return <EmailVerificationModal open={isOpen} onClose={handleClose} unskipable />
}

export default EmailVerificationController

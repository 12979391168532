import { VAR_REGEX } from 'constants/RegExs'

export const extractVariables = (text: string): string[] => {
  const result = new Set<string>()
  let match
  const regExp = new RegExp(VAR_REGEX.source, 'g')
  while ((match = regExp.exec(text)) !== null) {
    result.add(match[1])
  }
  return Array.from(result)
}

export const replaceVariables = (text: string, replaceMap: Record<string, string>): string => {
  let resultText = text
  let match
  const regExp = new RegExp(VAR_REGEX.source, 'g')
  while ((match = regExp.exec(text)) !== null) {
    const variableString = match[0]
    const variableName = match[1]

    const newVariableName = replaceMap[variableName]
    if (newVariableName) {
      const nextVariableString = variableString.replace(variableName, newVariableName)
      resultText = resultText.replace(variableString, nextVariableString)
    }
  }
  return resultText
}

import * as atypes from 'apps/chat/constants/LiveChatReduxActionTypes'

const initialState = {
  isAllChannelsHistoryModalOpen: false,
}
export default function chatSettingsReducer(state = initialState, action) {
  switch (action.type) {
    case atypes.LIVE_CHAT_SHOW_ALL_CHANNELS_HISTORY_MODAL: {
      return { ...state, isAllChannelsHistoryModalOpen: true }
    }
    case atypes.LIVE_CHAT_CLOSE_ALL_CHANNELS_HISTORY_MODAL: {
      return { ...state, isAllChannelsHistoryModalOpen: false }
    }
  }
  return state
}

interface CustomStorageEventDetails {
  key: string
  value: string
}

/*
 * @see https://github.com/testing-library/dom-testing-library/issues/438
 * in tests dispatch custom event with type 'storage'
 */
export function getSafeStorageEvent(event: StorageEvent | CustomEvent<CustomStorageEventDetails>) {
  let value = ''
  let key = ''

  if (event.constructor === CustomEvent) {
    key = event.detail.key
    value = event.detail.value
  } else if (event.constructor === StorageEvent && event.key && event.newValue) {
    key = event.key
    value = event.newValue
  }

  return {
    key,
    value,
  }
}

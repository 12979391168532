import React, { useState, useEffect, forwardRef, ChangeEvent } from 'react'
import cx from 'classnames'
import TextAreaAutoSize from 'react-textarea-autosize'
import { v4 as uuid } from 'uuid'

import cleanProps from '../../../lib/clean-react-props'
import { FormFieldLabelContainer, FormFieldTextBelow } from '../FormField'

import { TextAreaProps } from './interfaces'

import cm from './TextArea.module.css'
const MIN_ROWS = 3
const MAX_ROWS = 12

const TextAreaAutoSizeProps = [
  'inputRef',
  'maxRows',
  'minRows',
  'onHeightChange',
  'cacheMeasurements',
]

/** Text areas let users enter and edit multiline text. */
const TextArea = forwardRef<HTMLTextAreaElement, TextAreaProps>(
  (
    {
      autoFocus,
      lozenge,
      children,
      labelClassName,
      className,
      disabled,
      errorText,
      helpText,
      id,
      infoText,
      infoTextWidth,
      variant = 'default',
      invalid,
      label,
      labelId,
      minRows = MIN_ROWS,
      maxRows = MAX_ROWS,
      onChange,
      onInput,
      onFocus,
      onBlur,
      onKeyDown,
      onKeyUp,
      required,
      value,
      ariaLabel,
      ariaLabelledBy,
      ...otherProps
    },
    ref,
  ) => {
    const [innerValue, setInnerValue] = useState(value)
    const textAreaId = React.useMemo(() => id || `${uuid()}-input`, [id])

    useEffect(() => {
      setInnerValue(value)
    }, [value])

    const handleOnChange = (event: ChangeEvent<HTMLTextAreaElement>) => {
      setInnerValue(event.target.value)
      onChange?.(event)
    }

    const inputClasses = cx(cm.input, invalid && cm.invalid, {
      [cm.filled]: variant === 'filled',
      [cm.transparent]: variant === 'transparent',
    })

    return (
      <div className={cx(cm.textAreaContainer, className)}>
        {(label || children) && (
          <FormFieldLabelContainer
            label={label}
            inputId={textAreaId}
            id={labelId}
            infoText={infoText}
            lozenge={lozenge}
            infoTextWidth={infoTextWidth}
            required={required}
            disabled={disabled}
            fieldClasses={{ label: cx(disabled && cm.label__disabled, 'text-sm') }}
            labelClassName={labelClassName}
          >
            {children}
          </FormFieldLabelContainer>
        )}
        <TextAreaAutoSize
          {...cleanProps(otherProps, [], TextAreaAutoSizeProps)}
          id={textAreaId}
          ref={ref}
          aria-label={ariaLabel}
          aria-labelledby={ariaLabelledBy}
          minRows={minRows}
          maxRows={maxRows}
          value={innerValue}
          onKeyDown={onKeyDown}
          disabled={disabled}
          onChange={handleOnChange}
          onInput={onInput}
          onFocus={onFocus}
          onBlur={onBlur}
          onKeyUp={onKeyUp}
          autoFocus={autoFocus}
          className={inputClasses}
        />
        <FormFieldTextBelow
          helpText={helpText}
          errorText={errorText}
          showError={invalid}
          disabled={disabled}
        />
      </div>
    )
  },
)

TextArea.displayName = 'TextArea'

export default TextArea

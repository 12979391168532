import { PathParams } from 'shared/api/lib/types'

export const replacePathParams = (url: string, params: Exclude<PathParams, undefined>) => {
  const unusedParams: string[] = []

  const processedUrl = Object.entries(params).reduce((accumulator, [key, value]) => {
    const regExp = new RegExp(`{${key}}`)

    if (regExp.test(accumulator)) {
      return accumulator.replace(new RegExp(`{${key}}`), value.toString())
    } else {
      unusedParams.push(key)
      return accumulator
    }
  }, url)

  if (unusedParams.length > 0) {
    throw new Error(`Unused params in "${url}": ${unusedParams.join(', ')}`)
  }

  const pattern = new RegExp(/{.+?}/g)
  const unusedParamsInUrl = processedUrl.match(pattern)

  if (unusedParamsInUrl) {
    throw new Error(
      `Unused url params in "${url}": ${unusedParamsInUrl.join(', ').replace(/[{|}]/g, '')}`,
    )
  }

  const emptyPattern = new RegExp(/{}/)

  if (emptyPattern.test(processedUrl)) {
    throw new Error(`Empty brackets in "${url}"`)
  }

  return processedUrl
}

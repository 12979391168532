import { Icon, l } from '@manychat/manyui'

import {
  IntegrationType,
  IntegrationNameByType,
} from 'common/actions/integrations/common/constants/IntegrationType'
import {
  createIntegration,
  createIntegrationAction,
} from 'common/actions/integrations/common/model/helpers'
import * as googleSheetsActions from 'common/actions/integrations/GoogleSheets/actions/googleSheetsActions'
import { KnowledgeBaseLink } from 'common/core/components/SupportLinks'
import { AccountTargetPermissionsNames } from 'common/core/models/Account/constants'
import { analyticsService } from 'utils/services/analytics'

export const GoogleSheetsActionTypes = {
  ADD_ROW: 'insert_row',
  SEARCH_ROW: 'search_row',
  UPDATE_ROW: 'update_row',
}

const handleSupportLinkClick = () => {
  analyticsService.sendEvent('SUPPORT.LINK_SETTINGS_INTEGRATIONS_GOOGLE_SHEET_CLICK', {
    url: window.location.pathname,
  })
}

export const GoogleSheetsConfig = createIntegration({
  type: IntegrationType.GOOGLE_SHEETS,
  name: IntegrationNameByType[IntegrationType.GOOGLE_SHEETS],
  icon: Icon.GoogleSheets,
  permissionsVariable: AccountTargetPermissionsNames.GOOGLE_SHEETS_INTEGRATION,
  description: () =>
    l.translate(
      'The integration provides you with an ability to save customers data from Manychat bot to Google Sheets. <a href="{link}" target="_blank" class="text-primary pointer" onclick="handleSupportLinkClick" rel="noreferrer" >Learn more</a>',
      { handleSupportLinkClick, link: KnowledgeBaseLink.GOOGLE_SHEETS_INTEGRATION },
    ),
  isBeta: false,
  createHref: true,
  reduxActions: googleSheetsActions,
  actions: [
    createIntegrationAction({
      type: GoogleSheetsActionTypes.ADD_ROW,
      description: l.translate('Send Manychat data to Google Sheets.'),
      name: l.translate('Insert Row'),
    }),
    createIntegrationAction({
      type: GoogleSheetsActionTypes.SEARCH_ROW,
      description: l.translate('Return Google Sheets data to Manychat.'),
      name: l.translate('Get Row By Value'),
    }),
    createIntegrationAction({
      type: GoogleSheetsActionTypes.UPDATE_ROW,
      description: l.translate('Update Google Sheets with Manychat data.'),
      name: l.translate('Update Row'),
    }),
  ],
})

const GAPS = 'gaps'
const EMPTY = 'empty'

export const errorTypes = {
  GAPS,
  EMPTY,
}

export const ExcludedSystemFields = {
  MESSAGING_WINDOW: 'messaging_window',
}

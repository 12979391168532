import React from 'react'
import cx from 'classnames'
import { BtnV2, Modal, l, palettes, Loader, Icon } from '@manychat/manyui'

import { useModalData } from 'apps/cms/components/Flows/components/AutomationModals/hooks/useModalData'
import { AutomationModalType } from 'apps/cms/store/lib/types'
import UsesInAutomationList from 'components/UsesInAutomationList/UsesInAutomationList'

import { useDeleteFolderModal } from './hooks/useDeleteFolderModal'

export const DeleteFolderModal = () => {
  const { folderPath, folderName } = useModalData({ type: AutomationModalType.DELETE_FOLDER })

  const { isLoading, usageInfo, removeFolder, closeModal } = useDeleteFolderModal({
    path: folderPath,
  })

  return (
    <Modal
      width={460}
      title={<div className="p-y-xs">{l.translate('Delete Folder?')}</div>}
      open
      onRequestClose={closeModal}
      buttons={[
        <BtnV2 onClick={closeModal} loading={isLoading} className="m-y-xs">
          {l.translate('Cancel')}
        </BtnV2>,
        <BtnV2 onClick={removeFolder} loading={isLoading} variant="primary" className="m-y-xs">
          {l.translate('Delete')}
        </BtnV2>,
      ]}
    >
      <div className="p-x p-y-md">
        {usageInfo && (
          <div className={cx(!usageInfo.isNoUses && 'm-b-md')}>
            {l.translate('You are going to delete')} <b className="word-break-all">{folderName}</b>.
          </div>
        )}

        {usageInfo && !usageInfo.isNoUses && (
          <>
            <div className="panel panel-info d-flex align-start p-a-md">
              <Icon.Info
                className="flex-shrink-0 m-r"
                size={24}
                color={palettes.semantic.primary}
              />

              <p className="m-0">
                {l.translate(
                  'When you delete a Folder, all Automations and associated Triggers inside it are moved to the trash folder. You can restore both the Automation and its Triggers from there.',
                )}
              </p>
            </div>

            <UsesInAutomationList className="m-t-md" uses={usageInfo} />
          </>
        )}

        {!usageInfo && <Loader size={24} />}
      </div>
    </Modal>
  )
}

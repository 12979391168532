import React, { useCallback, useMemo, useState } from 'react'
import { useAppDispatch, useAppSelector } from 'reduxTyped'
import { Modal, l } from '@manychat/manyui'

import { useModalData } from 'apps/cms/components/Flows/components/AutomationModals/hooks/useModalData'
import { MoveToAlert } from 'apps/cms/components/Flows/components/MoveToAlert/MoveToAlert'
import { FoldersStructure } from 'apps/cms/components/FolderStructure/FoldersStructure'
import { CmsAnalytics } from 'apps/cms/lib/analytics'
import { contentManagementActions, contentManagementSelectors } from 'apps/cms/store'
import { AutomationModalType } from 'apps/cms/store/lib/types'
import { FSObjectType } from 'common/cms/models/FSObject/constants'
import { alert } from 'common/core'

import cm from './MoveToModal.module.css'

export const MoveToModal = () => {
  const fsObject = useModalData({ type: AutomationModalType.MOVE_TO })
  const [isMoving, setMoving] = useState(false)
  const dispatch = useAppDispatch()
  const currentPath = useAppSelector(contentManagementSelectors.getQueryPath)

  const isFolder = useMemo(() => fsObject.type === FSObjectType.FOLDER, [fsObject])

  const [activeFolder, setActiveFolder] = useState('')

  const handleClose = useCallback(() => {
    dispatch(contentManagementActions.closeModal(AutomationModalType.MOVE_TO))
  }, [dispatch])

  const handleFolderClick = useCallback((folderPath: string) => setActiveFolder(folderPath), [])

  const handleMove = useCallback(async () => {
    setMoving(true)

    const params = {
      path: fsObject.path,
      pathToMove: activeFolder,
      customSuccessAlert: (text: string, pathToMove: string) =>
        alert(text, 'info', {
          icon: 'CheckCircle',
          content: <MoveToAlert pathTo={pathToMove} />,
        }),
    }

    if (isFolder) {
      await dispatch(contentManagementActions.moveFolder(params))
      CmsAnalytics.logMoveFolder()
    } else {
      await dispatch(contentManagementActions.moveFlow(params))
      CmsAnalytics.logMoveFlow()
    }

    setMoving(false)
    handleClose()
  }, [activeFolder, dispatch, fsObject.path, handleClose, isFolder])

  const buttons = useMemo(
    () => [
      {
        onClick: handleClose,
        label: l.translate('Close'),
        disabled: isMoving,
        ['data-test-id']: 'move-folder-modal-close-btn',
      },
      {
        variant: 'primary',
        loading: isMoving,
        disabled: !activeFolder,
        onClick: handleMove,
        label: l.translate('Move'),
        ['data-test-id']: 'move-folder-modal-create-btn',
      },
    ],
    [activeFolder, handleClose, handleMove, isMoving],
  )

  const disabledTooltip = useMemo(() => {
    return isFolder
      ? l.translate("You can't move a folder into itself.")
      : l.translate("You can't move a flow in the same folder.")
  }, [isFolder])

  return (
    <Modal
      width={460}
      title={
        <div className="p-y-xs word-break-all">
          {l.translate('Move "{name}" to folder', {
            name: fsObject.name,
          })}
        </div>
      }
      open
      onRequestClose={handleClose}
      buttons={buttons}
    >
      <div className={cm.folders}>
        <FoldersStructure
          onSelect={handleFolderClick}
          disabledTooltip={disabledTooltip}
          disabledFolder={currentPath}
        />
      </div>
    </Modal>
  )
}

import React from 'react'
import cx from 'classnames'
import { l, Modal } from '@manychat/manyui'

import { useWalletUI } from 'common/billing/wallet/walletHooks'
import { ModalTypes } from 'common/billing/wallet/walletInterfaces'
import { WhatsAppTwilioChargeErrorNotificationData } from 'common/core/components/NotifyBar/NotifyInterfaces'
import cm from 'common/core/components/NotifyBar/StaticNotifications/StaticNotifications.module.css'
import { useToggle } from 'common/hooks/useToggle'
import { analyticsService } from 'utils/services/analytics'

interface WATwilioChargeErrorNotificationProps {
  data: WhatsAppTwilioChargeErrorNotificationData
}

const WATwilioChargeErrorNotification = ({ data }: WATwilioChargeErrorNotificationProps) => {
  const { date = '', refill_amount = null, currency = '' } = data
  const { state: informationModalState, toggle: toggleInformationModal } = useToggle()
  const [{ openModal }, toggleWalletModal] = useWalletUI()

  const handleClickRefill = () => {
    analyticsService.sendEvent('NOTIFICATION_BAR.WA_TWILIO_CHARGE_ERROR.LOW_BALANCE.CLICKED')
    toggleWalletModal(ModalTypes.REFILL)
  }

  const handleClickReadMore = () => {
    analyticsService.sendEvent('NOTIFICATION_BAR.WA_TWILIO_CHARGE_ERROR.READ_MORE.CLICK')
    toggleInformationModal()
  }

  const getText = () => {
    if (refill_amount && currency && date) {
      return l.translate(
        'Please refill your Wallet balance with at least {refillAmount} by {date}.',
        {
          date: l.date(date),
          refillAmount: l.currency(refill_amount, currency),
        },
      )
    } else if (refill_amount && currency) {
      return l.translate('Please refill your Wallet balance with at least {refillAmount}.', {
        refillAmount: l.currency(refill_amount, currency),
      })
    } else if (date) {
      return l.translate('Please refill your Wallet balance by {date}.', {
        date: l.date(date),
      })
    }
    return l.translate('Please refill your Wallet balance.')
  }

  const getNotificationText = () => {
    return date
      ? l.translate(
          'Please <a class="{class}" onclick="clickRefill">refill your Wallet balance</a> before {date}.',
          {
            class: cx(cm.more, 'c-pointer'),
            clickRefill: handleClickRefill,
            date: l.date(date),
          },
        )
      : l.translate(
          'Please <a class="{class}" onclick="clickRefill">refill your Wallet balance</a>.',
          {
            class: cx(cm.more, 'c-pointer'),
            clickRefill: handleClickRefill,
          },
        )
  }

  const buttons = [
    {
      variant: 'primary',
      onClick: handleClickRefill,
      label: l.translate('Refill your Wallet balance'),
    },
  ]

  const showInformationModal = openModal === null && informationModalState !== 'off'
  const modalWindowBodyText = `${l.translate(
    'Your monthly payment for Manychat’s number has failed.',
  )} ${getText()} ${l.translate(
    'Otherwise, you will lose your phone number and your channel will be disconnected.',
  )}`

  return (
    <>
      <span>
        {l.translate('Your WhatsApp number will be disconnected soon. ')}
        {getNotificationText()}{' '}
        <span
          className={cx(cm.more, 'c-pointer')}
          onClick={handleClickReadMore}
          data-test-id="twilio-notification-read-more"
        >
          {l.translate('Read more')}
        </span>
      </span>
      <Modal
        title={l.translate('Your WhatsApp number will be disconnected soon')}
        width={600}
        open={showInformationModal}
        onRequestClose={toggleInformationModal}
        buttons={buttons}
      >
        <p className="p-a-xs">{modalWindowBodyText}</p>
      </Modal>
    </>
  )
}

export default WATwilioChargeErrorNotification

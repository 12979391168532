import React from 'react'
import { useDragLayer } from 'react-dnd'

import { FlowDragLayer } from 'apps/cms/components/Flows/components/CustomDragLayer/components/FlowDragLayer/FlowDragLayer'
import { FolderDragLayer } from 'apps/cms/components/Flows/components/CustomDragLayer/components/FolderDragLayer/FolderDragLayer'
import { CmsDragSource, FlowDragItem, FolderDragItem } from 'apps/cms/lib/types'

export const CustomDragLayer = React.memo(function CustomDragLayer() {
  const { isDragging, item, clientOffset } = useDragLayer((monitor) => ({
    item: monitor.getItem() as { type: string } | FlowDragItem | FolderDragItem,
    itemType: monitor.getItemType(),
    clientOffset: monitor.getClientOffset(),
    isDragging: monitor.isDragging(),
  }))

  if (!isDragging || !clientOffset) {
    return null
  }

  if (item.type === CmsDragSource.FLOW_CARD) {
    return <FlowDragLayer path={(item as FlowDragItem).path} offset={clientOffset} />
  }

  if (item.type === CmsDragSource.FOLDER_CARD) {
    return <FolderDragLayer path={(item as FolderDragItem).path} offset={clientOffset} />
  }

  return null
})

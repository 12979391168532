import { createSlice } from '@reduxjs/toolkit'
import { createAppAsyncThunk } from 'reduxTyped'

import { handleCatch } from 'shared/api/lib/errors/handlers'
import { WhatsAppApi } from 'shared/api/requests/whatsApp'

import { WhatsAppChannelStats } from '../interfaces/whatsAppChannel'

const initialState: WhatsAppChannelStats = {
  totalCount: null,
  totalCost: null,
  freePeriodEnd: null,
}

export const getStats = createAppAsyncThunk('whatsapp/getStats', async () => {
  try {
    const response = await WhatsAppApi.getStats()
    return response.data
  } catch (error) {
    handleCatch(error)
    return null
  }
})

export const statsSlice = createSlice({
  name: 'stats',
  initialState: initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getStats.fulfilled, (state, action) => {
      if (action.payload) {
        state.totalCount = action.payload.stats.total_count
        state.totalCost = action.payload.stats.total_cost
        state.freePeriodEnd = action.payload.stats.free_period_end
      }
    })
    builder.addCase(getStats.rejected, (state) => {
      state.totalCount = 0
      state.totalCost = 0
      state.freePeriodEnd = null
    })
  },
})

export const getWaChannelStatsSelector = (state: RootState) => state.whatsApp.stats

import { Action, combineReducers } from 'redux'

import * as actionTypes from 'apps/applications/constants/InstalledApplicationsReduxActionTypes'
import {
  UpdateStage,
  IChangeLogRecord,
  IChangeLogState,
} from 'common/actions/integrations/Applications/interfaces'

interface IActionWithApplicationId extends Action {
  applicationId: number
}

interface IActionWithDataChangelog extends Action {
  $requestId: number
  data?: {
    changelog: IChangeLogRecord[]
  }
}

const stageReducer = (
  state = UpdateStage.Changelog,
  action: Action | (Action & { stage: UpdateStage }),
): UpdateStage => {
  switch (action.type) {
    case actionTypes.APPLICATION_VERSION_UPDATE_STAGE_SET:
      if ('stage' in action) {
        state = action.stage
      }
      break
    case actionTypes.APPLICATION_UPDATE_MODAL_CLOSE:
      state = UpdateStage.Changelog
  }

  return state
}

const changelogReducer = (
  state: IChangeLogState = { requestId: null, changelogs: [] },
  action: Action | IActionWithDataChangelog,
): IChangeLogState => {
  switch (action.type) {
    case actionTypes.APPLICATION_UPDATE_CHANGELOG_FETCH_REQUEST:
      if ('$requestId' in action) {
        state = { requestId: action.$requestId, changelogs: [] }
      }
      break
    case actionTypes.APPLICATION_UPDATE_CHANGELOG_FETCH_RESPONSE:
      if ('data' in action && action.data?.changelog && state.requestId === action.$requestId) {
        state = { requestId: null, changelogs: action.data.changelog }
      }
      break
    case actionTypes.APPLICATION_UPDATE_MODAL_CLOSE:
      state = { requestId: null, changelogs: [] }
      break
  }
  return state
}

const applicationIdReducer = (
  state: number | null = null,
  action: Action | IActionWithApplicationId,
): number | null => {
  switch (action.type) {
    case actionTypes.APPLICATION_UPDATE_MODAL_OPEN:
      if ('applicationId' in action) {
        state = action.applicationId
      }
      break
    case actionTypes.APPLICATION_UPDATE_MODAL_CLOSE:
      state = null
      break
  }

  return state
}

const updateFetchingReducer = (state = false, action: Action): boolean => {
  switch (action.type) {
    case actionTypes.APPLICATION_VERSION_UPDATE_REQUEST:
      state = true
      break
    case actionTypes.APPLICATION_VERSION_UPDATE_RESPONSE:
    case actionTypes.APPLICATION_VERSION_UPDATE_ERROR:
      state = false
      break
  }

  return state
}

export default combineReducers({
  applicationId: applicationIdReducer,
  changelog: changelogReducer,
  stage: stageReducer,
  updateFetching: updateFetchingReducer,
})

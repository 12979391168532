export enum AccountTargetPermissionsNames {
  ACTIVE_CAMPAIGN_INTEGRATION = 'ACTIVE_CAMPAIGN_INTEGRATION',
  AI_INTENTS = 'AI_INTENTS',
  BROADCAST_EMAIL = 'BROADCAST_EMAIL',
  BROADCAST_FB = 'BROADCAST_FB',
  BROADCAST_INSTAGRAM = 'BROADCAST_INSTAGRAM',
  BROADCAST_MULTIPLE = 'BROADCAST_MULTIPLE',
  BROADCAST_SMS = 'BROADCAST_SMS',
  BROADCAST_TELEGRAM = 'BROADCAST_TELEGRAM',
  BROADCAST_WHATSAPP = 'BROADCAST_WHATSAPP',
  CHATGPT_INTEGRATION = 'CHATGPT_INTEGRATION',
  CLAUDE_INTEGRAION = 'CLAUDE_INTEGRATION',
  DEEPSEEK_INTEGRAION = 'DEEPSEEK_INTEGRATION',
  CONVERT_KIT_INTEGRATION = 'CONVERT_KIT_INTEGRATION',
  EMAIL = 'EMAIL',
  FACEBOOK_ADS = 'FACEBOOK_ADS',
  GOOGLE_SHEETS_INTEGRATION = 'GOOGLE_SHEETS_INTEGRATION',
  GT_CGT = 'GT_CGT',
  GT_CGT_2 = 'GT_CGT_2',
  GT_FACEBOOK_SHOP = 'GT_FACEBOOK_SHOP',
  GT_FB_ADS_JSON = 'GT_FB_ADS_JSON',
  GT_IG_ADS_JSON = 'GT_IG_ADS_JSON',
  GT_QR_CODE = 'GT_QR_CODE',
  GT_REF_URL_FB = 'GT_REF_URL',
  GT_REF_URL_INSTAGRAM = 'GT_REF_URL_INSTAGRAM',
  GT_REF_URL_TELEGRAM = 'GT_REF_URL_TELEGRAM',
  GT_REF_URL_WHATSAPP = 'GT_REF_URL_WHATSAPP',
  HOTMART_INTEGRATION = 'HOTMART_INTEGRATION',
  HUB_SPOT_INTEGRATION = 'HUB_SPOT_INTEGRATION',
  KLAVIYO_INTEGRATION = 'KLAVIYO_INTEGRATION',
  LIVE_CHAT = 'LIVE_CHAT',
  MAIL_CHIMP_INTEGRATION = 'MAIL_CHIMP_INTEGRATION',
  MESSAGE_TAGS_MIGRATION_TO_PRO_BANNER = 'MESSAGE_TAGS_MIGRATION_TO_PRO_BANNER',
  MESSAGE_TAGS_MIGRATION_TO_PRO_CONTENTTYPE_PICKER_WARNING = 'MESSAGE_TAGS_MIGRATION_TO_PRO_CONTENTTYPE_PICKER_WARNING',
  MESSAGE_TAGS_MIGRATION_TO_PRO_LIVECHAT_INPUT = 'MESSAGE_TAGS_MIGRATION_TO_PRO_LIVECHAT_INPUT',
  MESSAGE_TAGS_MIGRATION_TO_PRO_NOTIFICATION_BAR = 'MESSAGE_TAGS_MIGRATION_TO_PRO_NOTIFICATION_BAR',
  PAID_MESSAGES = 'PAID_MESSAGES',
  PAYPAL_INTEGRATION = 'PAYPAL_INTEGRATION',
  SALESFORCE_INTEGRATION = 'SALESFORCE_INTEGRATION',
  SMS = 'SMS',
  STRIPE_INTEGRATION = 'STRIPE_INTEGRATION',
  SUBSCRIBER_LIMIT_200_BANNER = 'SUBSCRIBER_LIMIT_200_BANNER',
  YOKASSA_INTEGRATION = 'YOKASSA_INTEGRATION',
  FLODESK_INTEGRATION = 'FLODESK_INTEGRATION',
  TIKTOK_ADS_INTEGRATION = 'TIKTOK_ADS_INTEGRATION',
}

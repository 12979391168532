import assert from 'assert'

import { getInstagramWizardReasonId } from 'apps/posting/slices/BroadcastInstagramWizardSlice'
import { getReasonId, getReasonMessageTag } from 'apps/posting/slices/BroadcastMessengerWizardSlice'
import { builderCreateNode } from 'common/builder/actions/builderCreateNode'
import { createBlock } from 'common/builder/actions/createBlock'
import { createLinkedBlock } from 'common/builder/actions/createLinkedBlock'
import * as flowCampaignActions from 'common/builder/actions/flowCampaignActions'
import { linkBlock } from 'common/builder/actions/linkBlock'
import { pasteNodes } from 'common/builder/actions/pasteNodes'
import { createAiStepNodePreset } from 'common/builder/actions/presets/aiStepPreset'
import { createWhatsAppNodePreset } from 'common/builder/actions/presets/whatsAppPreset'
import { updateBlock } from 'common/builder/actions/updateBlock'
import { updateBuilderState } from 'common/builder/actions/updateBuilderState'
import { updateCoordinates } from 'common/builder/actions/updateCoordinates'
import * as blockHelpers from 'common/builder/blocks/blockHelpers'
import flowChartApp from 'common/builder/components/chart/FlowChart/app'
import { BlockType } from 'common/builder/constants/BlockType'
import * as ActionTypes from 'common/builder/constants/builderReduxActionTypes'
import { FBMessagingType } from 'common/builder/constants/facebookMessaging'
import InitialNodesPresetType from 'common/builder/constants/InitialNodesPresetType'
import { NodeType } from 'common/builder/constants/NodeType'
import builderSelectors from 'common/builder/selectors/builder'
import * as conditionSelectors from 'common/builder/selectors/builder/conditionSelectors'
import * as entitySelectors from 'common/builder/selectors/builder/entitySelectors'
import { ChannelType } from 'common/core/constants/ChannelType'
import { initFilterWithConditions } from 'common/filter/models/AudienceFilter/helpers'
import { ReasonRequestType } from 'common/oneTimeNotify/constants/notifyReasonConstants'
import { getDefaultActiveReason } from 'common/oneTimeNotify/selectors/notifyReasonSelectors'
import { NotificationReasonActions } from 'common/oneTimeNotify/store'
import { analyticsService, flowBuilderEvents } from 'utils/services/analytics'
import errorTrackingService from 'utils/services/errorTrackingService'

import * as blockActions from './blockActions'
import * as builderStateActions from './builderStateActions'
import * as sourceActions from './sourceActions'

/**
 * @param builderId
 * @param nodeId
 */
export function restoreNode(builderId, nodeId) {
  return async (dispatch, getState) => {
    const state = getState()

    assert(builderId, `restoreNode: builderId is required param`)
    assert(nodeId, `restoreNode: nodetId is required param`)

    const node = entitySelectors.getNodeById(state, builderId, nodeId)
    assert(node, `restoreNode: node with id "${nodeId}" doesn't exist`)

    return dispatch({
      type: ActionTypes.RESTORE_NODE,
      builderId,
      nodeId,
    })
  }
}

/**
 * @param {string} builderId
 * @param {array} nodeIds
 * @return {object} nodeCopyData
 */
export function copyNodes(builderId, nodeIds) {
  return (dispatch, getState) => {
    analyticsService.logFlowBuilderEvent(flowBuilderEvents.COPY_NODE)

    const state = getState()
    const offsets = flowChartApp.getNodesOffsets(nodeIds)
    const nodesData = nodeIds.map((nodeId) => {
      const [node, related] = entitySelectors.getAllRelated(state, builderId, nodeId)
      return { node, related }
    })
    return { nodeIds, nodesData, offsets }
  }
}

export const cloneNodes = (builderId, nodeIds, options) => {
  return (dispatch, getState) => {
    const copyData = dispatch(copyNodes(builderId, nodeIds))

    return dispatch(pasteNodes(builderId, copyData, { ...options, isSourcePageSame: true }))
  }
}

const createRnChannelNodePreset = (builderId, options, channel) => async (dispatch, getState) => {
  const initial = {
    nodeType: channel === ChannelType.FB ? NodeType.CONTENT : NodeType.INSTAGRAM,
    $fbMessagingType: FBMessagingType.OUTSIDE_24_HOURS,
  }

  await dispatch(
    NotificationReasonActions.getTopicsListFx({
      active_only: false,
      channel,
      notification_type: ReasonRequestType.RECURING,
    }),
  )

  const reason = getDefaultActiveReason(getState(), channel)

  if (reason) {
    initial.reasonId = reason.reason_id
  }

  return dispatch(builderCreateNode(builderId, initial, options))
}

export const createInitialNodesPreset = (builderId, nodesPreset) => {
  return (dispatch, getState) => {
    dispatch(builderStateActions.setChanged(builderId))
    const options = { preventOpen: true }

    if (nodesPreset === InitialNodesPresetType.EMAIL_NEW) {
      return dispatch(builderCreateNode(builderId, { nodeType: NodeType.EMAIL_NEW }, options))
    }

    if (nodesPreset === InitialNodesPresetType.MESSENGER) {
      return dispatch(createRnChannelNodePreset(builderId, options, ChannelType.FB))
    }

    if (nodesPreset === InitialNodesPresetType.MESSENGER_WIZARD) {
      const reasonId = getReasonId(getState())
      const reasonMessageTag = getReasonMessageTag(getState())

      const initial = {
        nodeType: NodeType.CONTENT,
        $fbMessagingType: FBMessagingType.OUTSIDE_24_HOURS,
      }
      reasonId && (initial.reasonId = reasonId)
      reasonMessageTag && (initial.messageTag = reasonMessageTag)

      return dispatch(builderCreateNode(builderId, initial, options))
    }

    if (nodesPreset === InitialNodesPresetType.INSTAGRAM_WIZARD) {
      const reasonId = getInstagramWizardReasonId(getState())

      const initial = {
        nodeType: NodeType.INSTAGRAM,
        $fbMessagingType: FBMessagingType.OUTSIDE_24_HOURS,
      }
      reasonId && (initial.reasonId = reasonId)

      return dispatch(builderCreateNode(builderId, initial, options))
    }

    if (nodesPreset === InitialNodesPresetType.SMS) {
      return dispatch(builderCreateNode(builderId, { nodeType: NodeType.SMS }, options))
    }

    if (nodesPreset === InitialNodesPresetType.INSTAGRAM) {
      return dispatch(createRnChannelNodePreset(builderId, options, ChannelType.INSTAGRAM))
    }

    if (nodesPreset === InitialNodesPresetType.MULTI_CHANNEL) {
      return dispatch(createMultichannelNodesPreset(builderId))
    }
    if (nodesPreset === InitialNodesPresetType.TELEGRAM) {
      return dispatch(builderCreateNode(builderId, { nodeType: NodeType.TELEGRAM }, options))
    }
    if (nodesPreset === InitialNodesPresetType.WHATSAPP) {
      return dispatch(createWhatsAppNodePreset(builderId, options))
    }

    if (nodesPreset && nodesPreset.startsWith('campaign-')) {
      const campaignId = parseInt(nodesPreset.replace('campaign-', ''))
      return dispatch(flowCampaignActions.fetchAndInitFlowCampaign(builderId, campaignId))
    }

    if (nodesPreset && nodesPreset.startsWith('openTrigger-')) {
      const triggerId = nodesPreset.replace('openTrigger-', '')

      return dispatch(
        updateBuilderState(builderId, {
          sidebarHidden: false,
          selectedNodes: {},
          selectedTrigger: triggerId,
          createTriggerInitial: {},
          createTriggerMode: false,
        }),
      )
    }

    if (nodesPreset === 'aiStep') {
      return dispatch(createAiStepNodePreset({ builderId }))
    }

    return dispatch(builderCreateNode(builderId, { nodeType: NodeType.CONTENT }, options))
  }
}

export function createMultichannelNodesPreset(builderId) {
  return (dispatch, getState) => {
    const options = { preventOpen: true }

    const text =
      'Messages connected to a condition will be sent only to contacts who meet it. If a contact meets more than one condition, they will be sent the message connected to the highest condition on the list.\n\n' +
      'In this example an email will be sent to contacts who are outside Messenger’s 24 hour window and an SMS will be sent to those who cannot be reached by email.'
    const commentNode = dispatch(
      builderCreateNode(builderId, { nodeType: NodeType.COMMENT, text }, options),
    )
    dispatch(updateCoordinates(builderId, { [commentNode.id]: { x: -700, y: 200 } }))

    const conditionNode = dispatch(
      builderCreateNode(builderId, { nodeType: NodeType.CONDITION }, options),
    )

    const blocks = conditionSelectors.getConditionBlocks(getState(), builderId, conditionNode.id)
    const contentCaseBlock = blocks[0]
    const contentNode = dispatch(
      builderCreateNode(builderId, { nodeType: NodeType.CONTENT }, options),
    )
    dispatch(sourceActions.updateSourceTarget(builderId, contentCaseBlock.id, contentNode.id))

    const contentCaseBlockChanges = {
      filter: initFilterWithConditions([
        {
          type: 'suf',
          field: 'messaging_window',
          operator: 'CASE',
          value: 'recent interaction (< 24 hrs)',
        },
      ]),
    }
    dispatch(updateBlock(builderId, contentCaseBlock.id, contentCaseBlockChanges))

    const emailNodeType = NodeType.EMAIL_NEW
    const emailNode = dispatch(builderCreateNode(builderId, { nodeType: emailNodeType }, options))
    const emailCaseBlock = dispatch(createBlock(builderId, { type: BlockType.CASE }))
    dispatch(linkBlock(builderId, conditionNode.id, emailCaseBlock.id))
    dispatch(sourceActions.updateSourceTarget(builderId, emailCaseBlock.id, emailNode.id))
    const emailCaseBlockChanges = {
      filter: initFilterWithConditions([
        {
          type: 'suf',
          field: 'optin_email',
          operator: 'TRUE',
        },
        {
          type: 'suf',
          field: 'email',
          operator: 'HAS_VALUE',
        },
      ]),
    }
    dispatch(updateBlock(builderId, emailCaseBlock.id, emailCaseBlockChanges))

    const smsNode = dispatch(builderCreateNode(builderId, { nodeType: NodeType.SMS }, options))
    const smsCaseBlock = dispatch(createBlock(builderId, { type: BlockType.CASE }))
    dispatch(linkBlock(builderId, conditionNode.id, smsCaseBlock.id))
    dispatch(sourceActions.updateSourceTarget(builderId, smsCaseBlock.id, smsNode.id))
    const smsCaseBlockChanges = {
      filter: initFilterWithConditions([
        {
          type: 'suf',
          field: 'optin_phone',
          operator: 'TRUE',
        },
        { type: 'suf', field: 'phone', operator: 'HAS_VALUE' },
      ]),
    }
    dispatch(updateBlock(builderId, smsCaseBlock.id, smsCaseBlockChanges))

    return conditionNode
  }
}

export function cloneLinkedBlock(builderId, blockId) {
  return (dispatch, getState) => {
    const state = getState()
    const clonedBlock = dispatch(blockActions.cloneBlock(builderId, blockId))

    const parentNode = entitySelectors.getParentNode(state, builderId, blockId)
    const originalBlockIndex = parentNode.blocks.indexOf(blockId)
    const index = originalBlockIndex + 1

    dispatch(linkBlock(builderId, parentNode.id, clonedBlock.id, { index }))
  }
}

/**
 * @param builderId
 * @param contentId
 * @param initial
 */
export function createNodeNear(builderId, contentId, initial) {
  return (dispatch, getState) => {
    const state = getState()
    const node = entitySelectors.getNodeById(state, builderId, contentId)
    const builderState = builderSelectors.state.getById(state, builderId)
    const position = builderState.coordinates[node.id] ?? { x: 0, y: 0 }
    const newPosition = { x: position.x + 100, y: position.y + 100 }
    dispatch(
      builderCreateNode(
        builderId,
        { ...initial, nodeType: node.nodeType },
        { position: newPosition },
      ),
    )
  }
}

/**
 * @param builderId
 * @param nodeId
 */
export function ensureQuickReplyBlockCreated(builderId, nodeId) {
  return (dispatch, getState) => {
    const state = getState()
    const node = entitySelectors.getNodeById(state, builderId, nodeId)
    const qrBlock = builderSelectors.node.getQuickReplyBlock(state, builderId, nodeId)

    if (qrBlock) {
      return
    }

    errorTrackingService.trackWarningOnce('Ensure QR block', {
      extra: { node },
      fingerprint: 'ensure-qr-block',
    })

    const initial = {
      id: blockHelpers.createQRBlockId(node.id),
      type: BlockType.QUICK_REPLY,
    }
    dispatch(createLinkedBlock(builderId, nodeId, initial))
  }
}

import { TextStyle } from '@pixi/text'
import { skipHello } from '@pixi/utils'
import { scaleLinear } from 'd3-scale'

skipHello()

const EM = 2

export const highDPI = window.devicePixelRatio > 1

export const SIDEBAR_WIDTH = 400
export const SIDEBAR_CLOSE_WIDTH = 60

const SCALE_MIN = 0.05,
  SCALE_MAX = 1

export const SCALE_DEFAULT = 0.45

export const appScaleExtent = [SCALE_MIN, SCALE_MAX]

export const flowPreviewAlphaScale = scaleLinear()
  .domain([SCALE_MIN, 0.35, 0.45, SCALE_MAX])
  .range([1, 1, 0, 0])

export const botMapConnectorWidthScale = scaleLinear()
  .domain([SCALE_MIN, 0.3, SCALE_MAX])
  .range([3 * EM, 1.5 * EM, EM])

export const connectorWidthScale = scaleLinear()
  .domain([SCALE_MIN, 0.3, SCALE_MAX])
  .range([6 * EM, 2 * EM, EM])

export const nodeFrameWidthScale = scaleLinear()
  .domain([SCALE_MIN, 0.3, SCALE_MAX])
  .range([5 * EM, 2 * EM, EM])
// .range([6 * EM, 2 * EM, 1.5 * EM])

export const DBL_CLICK_TIME = 300

export const CALCULATE_WIDTH_INTERVAL = 250

export const MIN_DRAG_DELTA = 4
export const MAX_DRAG_BINDING_DISTANCE = 15

export const MAX_EXPORT_IMAGE_WIDTH = 4096
export const MAX_EXPORT_IMAGE_HEIGHT = 2160

export const STATES = {
  NORMAL: 'NORMAL',
  PANNING: 'PANNING',
  DRAGGING: 'DRAGGING',
  SELECTING_FRAME: 'SELECTING_FRAME',
  DRAGGING_START: 'DRAGGING_START',
  CREATING_NEW_CONNECTOR: 'CREATING_NEW_CONNECTOR',
  OVERLAY_EDITOR: 'OVERLAY_EDITOR',
}

export const icons = {
  SPLIT: 'ABSplit',
}

export const colors = {
  // Fix for Linux, it renders full white with opacity as black in PIXI v5
  WHITE: 0xfefefe,
  BORDER: 0xe0e6ed,
  PRIMARY: 0x0084ff,
  SUCCESS: 0x25d172,
  PURPLE: 0x6a3eeb,
  DANGER: 0xff6249,
  PURPLE_LIGHT: 0x7e5bef,
  GREY: 0x8492a6,
  GRAY: 0x3c4858,
  LIGHT_GRAY: 0xf1f0f0,

  TRIGGER_BG: 0xbcf2ff,
  TRIGGER_STROKE: 0x38d7ff,

  NODE_STROKE: 0xd5dde5,
  BOTMAP_NODE_STROKE: 0xe0e6ed,

  CONTENT_HEADLINE: 0xe0e6ed,
  CONTENT_HEADLINE_STROKE: 0xd5dde5,

  AG_HEADLINE: 0xfff7e2,
  AG_HEADLINE_STROKE: 0xffd96f,
  AG_CONNECTOR_STROKE: 0xffd96f,
  AG_CONNECTOR_PORT: 0xffbd00,

  CONNECTOR: 0x8492a6,
  CONNECTOR_TAIL_ALPHA: 0.2,

  CONDITION_TRUE: 0x0dd491,
  CONDITION_FALSE: 0xfb9797,

  SPLIT_HEADLINE: 0xe7e1fc,
  SPLIT_STROKE: 0xb8a5f6,

  DYNAMIC_CONTENT_STROKE: 0xb9a3ff,
  DYNAMIC_CONTENT_BG: 0xe8e0ff,

  SMS_HEADLINE: 0xecf8d5,
  SMS_HEADLINE_STROKE: 0xd5dde5,

  EMAIL_HEADLINE: 0xe7f3ff,
  EMAIL_HEADLINE_STROKE: 0xd5dde5,
}

const sizes = {
  FONT_STYLE_SM: 10 * EM,
  FONT_STYLE_MD: 12 * EM,
  FONT_STYLE_LG: 16 * EM,
  FONT_STYLE_XL: 32 * EM,

  STROKE_WIDTH: 1.5 * EM,

  HALF_STROKE_WIDTH: 0.75 * EM,

  BOT_MAP_POSITION_RIGHT_MARGIN: 30 * EM,

  STARTING_STEP_NODE_WIDTH: 295 * EM, // Target: 590
  CONTENT_WIDTH: 200 * EM, // without padding
  CONTENT_PADDING: 30 * EM,
  CONTENT_POSITION_RIGHT_MARGIN: 150 * EM,
  CONTENT_RADIUS: 4 * EM,
  IMG_SMALL_SIZE: (200 * EM) / 3.5,

  // Email
  EMAIL_WIDTH: 230 * EM,
  EMAIL_BLOCK_MARGIN: 5 * EM,
  EMAIL_TEXT_MIN_HEIGHT: 160,
  EMAIL_STROKE_WIDTH: 0.5 * EM,

  EMAIL_BTN_HEIGHT: 40 * EM,
  EMAIL_BTN_FRAME: 200,
  EMAIL_BTN_WIDTH: 360,
  EMAIL_BTN_RADIUS: 16,

  BLOCK_MARGIN: 20 * EM,
  BLOCK_RADIUS: 10 * EM,

  TITLE_FZ: 13 * EM,
  TEXT_FZ: 12 * EM,
  TEXT_FZ_BG: 13 * EM,
  TEXT_PADDING: 4 * EM,

  BTN_HEIGHT: 30 * EM,
  BTN_QR_HEIGHT: 21 * EM,
  BTN_QR_STROKE_WIDTH: EM,
  BTN_FZ: 12 * EM,
  BTN_FZ_SM: 8 * EM,
  BTN_RADIUS: 10 * EM,
  BTN_DOT_RADIUS: 5 * EM,
  BTN_DOT_MARGIN: 3 * EM,
  BTN_TYPE_WIDTH: 15 * EM,

  ACTION_GROUP_WIDTH: 160 * EM, // without padding
  ACTION_GROUP_PADDING: 15 * EM,
  ACTION_GROUP_FZ: 10 * EM,
  ACTION_GROUP_ICON_MARGIN: 10 * EM,
  ACTION_ITEM_MARGIN: 6 * EM,
  ACTION_ITEM_PADDING_BOTTOM: 6 * EM,

  START_WIDTH: 135 * EM,
  START_HEIGHT: 28 * EM,
  START_ARROW_HEIGHT: 3 * EM,
  START_BORDER_RADIUS: 3 * EM,

  CARD_MARGIN: 20 * EM,
  CARD_PADDING: 10 * EM,
  CARD_URL_ICON_PADDING: 7 * EM,
  CARD_IMAGE_HEIGHT: 100 * EM,
  CARD_SQUARE_IMAGE_HEIGHT: 200 * EM,
  CARD_SUBTITLE_MARGIN: 5 * EM,
  CARD_SUBTITLE_HEIGHT: 70 * EM,

  LIST_BTN_RADIUS: 4 * EM,

  FILE_HEIGHT: 60 * EM,
  FILE_PLACEHOLDER_PADDING: 10 * EM,
  FILE_IMAGE_PLACEHOLDER_PADDING: 30 * EM,

  CONNECTOR_TARGET_VERTICAL_MARGIN: 20 * EM,

  CREATE_NODE_MENU_WIDTH: 120 * EM,
  CREATE_NODE_MENU_HEIGHT: 30 * EM,

  NODE_BG_ALPHA: 0.9,
  NODE_PLACEHOLDER_HEIGHT: 55 * EM,

  PLACEHOLDER_TEXT_MARGIN: 7 * EM,
  PLACEHOLDER_TOP_PADDING: 5 * EM,

  TEXT_BLOCK_MIN_HEIGHT: 115,
  DYNAMIC_CONTENT_BLOCK_MIN_HEIGHT: 50 * EM,
  VARIANT_BLOCK_HEIGHT: 65,

  STATS_PADDING_Y: 4 * EM,
  STATS_FS: 9 * EM,
  STATS_DIVIDER_WIDTH: EM,
  HEADLINE_HEIGHT: 30 * EM,

  FREE_INPUT_TEXT_OFFSET_X: 18,
  FREE_INPUT_TEXT_OFFSET_Y: 9 / 1.8,
  FREE_INPUT_ICON_OFFSET_X: 22,
  FREE_INPUT_ICON_OFFSET_Y: 7,
  FREE_INPUT_ICON_PADDING: 20,

  GOTO_WIDTH: 160 * EM, // without padding
  GOTO_PADDING: 15 * EM,
  GOTO_PADDING_X: 30 * EM,
  GOTO_MARGIN: 6 * EM,

  COMMENT_WIDTH: 200 * EM, // without padding
  COMMENT_WIDTH_SMALL: 100 * EM, // without padding
  COMMENT_WIDTH_WIDE: 400 * EM, // without padding
  COMMENT_SHADOW_SHIFT: 15,
  COMMENT_PADDING: 15 * EM,
  COMMENT_PADDING_X: 30 * EM,
  COMMENT_MARGIN: 6 * EM,

  SPLIT_WIDTH: 90 * EM, // without padding
  SPLIT_PADDING: 15 * EM,
  SPLIT_PADDING_X: 30 * EM,
  SPLIT_MARGIN: 6 * EM,

  ANSWERS_BTN_HEIGHT: 9 * EM,
  ANSWERS_BTN_PADDING_X: 5 * EM,
  ANSWERS_BTN_PADDING_Y: 5 * EM,
  BTN_PADDING_SM: 5 * EM,
  BTN_PADDING_MD: 10 * EM,
  BTN_PADDING_LG: 15 * EM,

  QUICK_REPLY_MARGIN: 5 * EM,
  CONTENT_CHAINING_LABEL_WIDTH: 60 * EM,

  CONDITION_WIDTH: 180 * EM,
  CONDITION_MIN_HEIGHT: 40 * EM,
  CONDITION_BTN_WIDTH: 40 * EM,
  MULTI_CONDITION_BTN_WIDTH: 20 * EM,
  CONDITION_PADDING: 15 * EM,

  SMART_DELAY_WIDTH: 160 * EM, // without padding
  SMART_DELAY_PADDING: 15 * EM,
  SMART_DELAY_PADDING_X: 30 * EM,
  SMART_DELAY_MARGIN: 6 * EM,

  TRIGGER_TYPE_MARGIN: 12 * EM,
}

export const START_ID = '$start$'

const fontFamily = ['InterVariable', 'Helvetica Neue', 'Helvetica', 'Arial', 'sans-serif']

export const StartTextStyle = new TextStyle({
  fontFamily,
  fontSize: 14 * EM,
  fill: 0xffffff,
  letterSpacing: 1,
})

export const HeadlineTextStyle = new TextStyle({
  fontFamily: fontFamily,
  fontSize: 11 * EM,
  wordWrap: true,
  breakWords: true,
})

export const AttributeLabelStyle = new TextStyle({
  fontFamily: fontFamily,
  fontSize: 11 * EM,
  fill: colors.GREY,
})

export const AttributeTextStyle = new TextStyle({
  fontFamily: 'Arial',
  fontSize: 9 * EM,
  wordWrap: true,
  breakWords: true,
})

export const ActionTitleTextStyle = new TextStyle({
  fontFamily: 'Arial',
  fontSize: 9 * EM,
  fill: colors.GREY,
})

export const ActionDescTextStyle = new TextStyle({
  fontFamily: 'Arial',
  fontSize: 9 * EM,
})

export const CardTitleTextStyle = new TextStyle({
  fontFamily: 'Arial',
  fontSize: 10 * EM,
  wordWrap: true,
  breakWords: true,
  wordWrapWidth: sizes.CONTENT_WIDTH - sizes.CARD_PADDING * 2,
  width: sizes.CONTENT_WIDTH - sizes.CARD_PADDING * 2,
})

export const CardSubtitleTextStyle = new TextStyle({
  fontFamily: 'Arial',
  fontSize: 9 * EM,
  fill: 0x888888,
  wordWrap: true,
  breakWords: true,
  wordWrapWidth: sizes.CONTENT_WIDTH - sizes.CARD_PADDING * 2,
  width: sizes.CONTENT_WIDTH - sizes.CARD_PADDING * 2,
})

export const ListTitleTextStyle = new TextStyle({
  fontFamily: 'Arial',
  fontSize: 10 * EM,
  wordWrap: true,
  breakWords: true,
  wordWrapWidth: sizes.CONTENT_WIDTH - sizes.CARD_PADDING * 2 - sizes.IMG_SMALL_SIZE,
  width: sizes.CONTENT_WIDTH - sizes.CARD_PADDING * 2 - sizes.IMG_SMALL_SIZE,
})

export const ListSubtitleTextStyle = new TextStyle({
  fontFamily: 'Arial',
  fontSize: 9 * EM,
  fill: 0x888888,
  wordWrap: true,
  breakWords: true,
  wordWrapWidth: sizes.CONTENT_WIDTH - sizes.CARD_PADDING * 3 - sizes.IMG_SMALL_SIZE,
  width: sizes.CONTENT_WIDTH - sizes.CARD_PADDING * 2 - sizes.IMG_SMALL_SIZE,
})

export const ListTitleTextStyleLarge = new TextStyle({
  ...ListTitleTextStyle,
  wordWrapWidth: sizes.CONTENT_WIDTH - sizes.CARD_PADDING * 2,
  width: sizes.CONTENT_WIDTH - sizes.CARD_PADDING * 2,
  fill: 0xffffff,
  dropShadow: true,
  dropShadowBlur: 3,
  dropShadowDistance: 1,
})

export const ListSubtitleTextStyleLarge = new TextStyle({
  ...ListSubtitleTextStyle,
  wordWrapWidth: sizes.CONTENT_WIDTH - sizes.CARD_PADDING * 2,
  width: sizes.CONTENT_WIDTH - sizes.CARD_PADDING * 2,
  fill: 0xffffff,
  dropShadow: true,
  dropShadowBlur: 3,
  dropShadowDistance: 1,
})

export const ContentOverlayTextStyle = new TextStyle({
  fontFamily: 'Arial',
  fontSize: 18 * EM,
  align: 'center',
  wordWrap: true,
  wordWrapWidth: sizes.CONTENT_WIDTH,
  fill: 0x999999,
})

export const WaitingForReplyTextStyle = new TextStyle({
  fontFamily: 'Arial',
  fontSize: 9 * EM,
  fontStyle: 'italic',
  wordWrap: true,
  wordWrapWidth: sizes.CONTENT_WIDTH,
  fill: colors.GREY,
})

export const FreeInputTextStyle = new TextStyle({
  fontFamily: 'Arial',
  fontSize: 9 * EM,
  wordWrap: true,
  wordWrapWidth: sizes.CONTENT_WIDTH,
  fill: colors.PURPLE,
})

export const BtnTextStyle = new TextStyle({
  fontFamily: 'Arial',
  fontSize: sizes.BTN_FZ,
  wordWrap: false,
  fill: colors.PRIMARY,
})

export const CreateContentTextStyle = new TextStyle({
  fontFamily: 'Arial',
  fontSize: sizes.BTN_FZ,
  align: 'center',
  fill: colors.PRIMARY,
})

export const PlaceholderTextStyle = new TextStyle({
  fontFamily: 'Arial',
  fontSize: sizes.BTN_FZ,
  lineHeight: sizes.BTN_FZ * 1.3,
  align: 'center',
  fill: colors.CONNECTOR,
})

export const ChipTextStyle = new TextStyle({
  fontFamily: 'Arial',
  fontSize: 12 * EM,
  lineHeight: 12 * EM,
  fill: 0x2c323a,
})

export const StatsTitleTextStyle = new TextStyle({
  ...WaitingForReplyTextStyle,
  fontStyle: 'normal',
  fontSize: 9 * EM,
})

export const StatsValueTextStyle = new TextStyle({
  fontFamily: 'Arial',
  fontSize: 15 * EM,
  fill: colors.PRIMARY,
})

export const StatsHoverValueTextStyle = new TextStyle({
  fontFamily: 'Arial',
  fontSize: 15 * EM,
  fill: colors.PRIMARY,
})

export const AnswerButtonTextStyle = new TextStyle({
  fill: colors.WHITE,
  fontFamily: 'Arial',
  fontSize: 18,
  lineHeight: 1,
})

export const ContentChainingLabelTextStyle = new TextStyle({
  fontFamily,
  fontSize: 12 * EM,
  lineHeight: 16 * EM,
  fill: colors.CONNECTOR,
})
export const CONTENT_CHAINING_LABEL = 'Automatically continue'

export const ConditionTextStyle = new TextStyle({
  fontFamily: fontFamily,
  fontSize: 11 * EM,
  wordWrap: true,
  breakWords: true,
})

export const TriggerIconTextStyle = new TextStyle({
  fontFamily: 'Arial',
  fontSize: 12 * EM,
  lineHeight: 16 * EM,
  align: 'center',
  fill: 0x2c323a,
})

export const FBAllPostsLabelTextStyle = new TextStyle({
  fontFamily,
  fontSize: 10 * EM,
  lineHeight: 16 * EM,
  fill: 0xc1c9d6,
})

export default sizes

import cloneDeep from 'lodash/cloneDeep'
import { v4 as uuid } from 'uuid'

import { IPost } from 'apps/posting/postingInterfaces'

import adapter from './adapter'
import * as constants from './constants'
import defaults from './defaults'
import exporter from './exporter'
import * as helpers from './helpers'

function create(initial: { id?: number } = {}): IPost {
  const id = initial.id || uuid()
  return Object.assign(cloneDeep(defaults), initial, { id })
}

export default {
  create,
  defaults,
  adapter,
  exporter,
  ...helpers,
  ...constants,
}

import assert from 'assert'

import get from 'lodash/get'

import blockRegistry from 'common/builder/blockRegistry'
import { CREATE_BLOCK } from 'common/builder/constants/builderReduxActionTypes'
import BlockModel from 'common/builder/models/Block'
import { getButtonById, getBlockById } from 'common/builder/selectors/builder/entitySelectors'

/**
 * @param builderId
 * @param initial
 */
export const createBlock = (builderId, initial) => {
  return (dispatch, getState) => {
    const state = getState()

    assert(builderId, `createBlock: builderId is required param`)

    if (get(initial, 'id')) {
      const item = getBlockById(state, builderId, initial.id)
      assert(!item, `createBlock: block with id "${initial.id}" already exists`)
    }

    const { type } = initial
    assert(type, 'createBlock: type is required')

    const item = blockRegistry.get(initial).create(initial)

    if (item.blocks.length) {
      assert(
        BlockModel.isNestedBlockAllowed(item),
        `createBlock: block type "${item.type}" doesn't allow to link block`,
      )

      item.blocks.forEach((blockId) => {
        const targetItem = getBlockById(state, builderId, blockId)
        assert(
          BlockModel.isNestedBlockTypeAllowed(item, targetItem.type),
          `createBlock: block type "${item.type}" doesn't allow to link block with type ${targetItem.type}`,
        )
      })
    }

    if (BlockModel.BlockTypesMinNested[item.type]) {
      const msg = `createBlock: block with type "${item.type}" should have at least ${
        BlockModel.BlockTypesMinNested[item.type]
      } blocks`
      assert(item.blocks.length >= BlockModel.BlockTypesMinNested[item.type], msg)
    }

    if (BlockModel.BlockTypesMaxNested[item.type]) {
      const msg = `createBlock: block with type "${item.type}" may contain not more than ${
        BlockModel.BlockTypesMaxNested[item.type]
      } blocks`
      assert(item.blocks.length <= BlockModel.BlockTypesMaxNested[item.type], msg)
    }

    if (item.blocks.length) {
      item.blocks.forEach((blockId) => {
        const item = getBlockById(state, builderId, blockId)
        assert(item, `createBlock: specified in item.blocks block doesn't exist`)
      })
    }

    if (item.buttons.length) {
      item.buttons.forEach((buttonId) => {
        const item = getButtonById(state, builderId, buttonId)
        assert(item, `createBlock: specified in item.buttons button doesn't exist`)
      })
    }

    dispatch({
      type: CREATE_BLOCK,
      builderId,
      item,
    })
    return item
  }
}

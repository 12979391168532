import { IActionWithPayload } from 'common/core/interfaces/actions'
import { EntityType } from 'constants/EntityType'

import * as atypes from '../constants/dashboardReduxActionTypes'
import { DangerFlowsStats, DashboardEntitiesCount, DashboardState } from '../dashboardInterfaces'

const defaultState: DashboardState = {
  dangerFlows: {
    isShow: false,
    stats: {} as DangerFlowsStats,
    flowListData: {},
  },
  homeTabEvents: {
    items: [],
    isLoaded: false,
  },
  entitiesCount: {} as DashboardEntitiesCount,
  jobsQuestionnaireAnswers: {},
  totalEarnedList: {},
  ARPPUList: {},
  APCList: {},
  buyersCountList: {},
  salesCountList: {},
  channel: null,
  activeTab: null,
}

export default function dashboardReducer(state = defaultState, action: IActionWithPayload) {
  switch (action.type) {
    case atypes.DASHBOARD_FULLSTATS_RESPONSE: {
      return {
        ...state,
        stats: action.data.stats,
      }
    }
    case atypes.DASHBOARD_SUBSCRIBERSLANGUAGESTATS_RESPONSE: {
      return {
        ...state,
        languageStats: action.data,
      }
    }
    case atypes.DASHBOARD_SUBSCRIBERSGENDERSTATS_RESPONSE: {
      return {
        ...state,
        genderStats: action.data,
      }
    }
    case atypes.DASHBOARD_SUBSCRIBERSRECENTLIST_RESPONSE: {
      return {
        ...state,
        subscribers: action.data,
      }
    }

    case atypes.DASHBOARD_FETCH_ENTITIES_COUNT_RESPONSE: {
      const { entities_count } = action.data
      return {
        ...state,
        entitiesCount: {
          ...entities_count,
          [EntityType.ADS_GT]: entities_count.ads,
        },
      }
    }

    case atypes.DASHBOARD_FETCH_ENTITIES_COUNT_ERROR: {
      return {
        ...state,
        entitiesCount: {},
      }
    }
    case atypes.DASHBOARD_FETCH_DANGER_FLOWS_DATA: {
      return {
        ...state,
        dangerFlows: {
          ...state.dangerFlows,
          stats: action.data,
        },
      }
    }
    case atypes.DASHBOARD_FETCH_DANGER_FLOWS_LIST: {
      return {
        ...state,
        dangerFlows: {
          ...state.dangerFlows,
          flowListData: action.data,
        },
      }
    }
    case atypes.DASHBOARD_SHOW_DANGER_FLOWS_DATA: {
      return {
        ...state,
        dangerFlows: {
          ...state.dangerFlows,
          isShow: true,
        },
      }
    }
    case atypes.DASHBOARD_FETCH_EARNED_LIST_RESPONSE: {
      const { total_earned_list, arppu_list, apc_list, buyers_count_list, payments_count_list } =
        action.data

      return {
        ...state,
        totalEarnedList: total_earned_list,
        ARPPUList: arppu_list,
        APCList: apc_list,
        buyersCountList: buyers_count_list,
        salesCountList: payments_count_list,
      }
    }

    case atypes.HOME_TAB_GET_EVENTS_RESPONSE:
    case atypes.HOME_TAB_ADD_EVENTS_RESPONSE: {
      const { events } = action.data

      return {
        ...state,
        homeTabEvents: {
          ...state.homeTabEvents,
          items: events,
          isLoaded: true,
        },
      }
    }
    case atypes.HOME_TAB_CHANGE_CHANNEL: {
      const { channel } = action.data

      return {
        ...state,
        channel,
      }
    }

    case atypes.HOME_TAB_ACTIVE_TAB: {
      const { activeTab } = action.data

      return {
        ...state,
        activeTab,
      }
    }

    case atypes.GET_JOBS_QUESTIONNAIRE_ANSWERS_RESPONSE: {
      const { answers: jobsQuestionnaireAnswers } = action.data

      if (!jobsQuestionnaireAnswers) {
        return state
      }

      return {
        ...state,
        jobsQuestionnaireAnswers,
      }
    }
  }

  return state
}

export function parseLocalStorageValue(lsValue?: string) {
  if (typeof lsValue === 'undefined') {
    return null
  }

  let parsedValue

  try {
    parsedValue = JSON.parse(lsValue)
  } catch {
    parsedValue = lsValue
  }

  return parsedValue
}

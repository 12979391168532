import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { Loader } from '@manychat/manyui'

import EnsureTargetPageTags from 'common/mapping/controllers/EnsureTargetPageTags'
import TagPicker from 'common/tags/components/TagPicker'

import cm from './TargetPageTagPicker.module.css'

class TagPickerController extends React.Component {
  static propsTypes = {
    excluded: PropTypes.array,
  }

  static defaultProps = {
    tags: [],
    excluded: [],
  }

  handleRef = (ref) => {
    this.input = ref
  }

  focus = () => {
    if (this.input) {
      this.input.focus()
    }
  }

  blur = () => {
    if (this.input) {
      this.input.blur()
    }
  }

  render() {
    const { excluded, tags, pageId } = this.props

    let _tags = tags

    if (excluded.length) {
      _tags = (tags || []).filter((tag) => {
        return !excluded.map((excluded) => excluded.tag_id).includes(tag.tag_id)
      })
    }

    return (
      <EnsureTargetPageTags pageId={pageId} loader={<Loader size={32} />}>
        <TagPicker
          ref={this.handleRef}
          {...this.props}
          tags={_tags}
          className="p-r-lg"
          theme={{ itemsWrapper: cm.itemsWrapper }}
        />
      </EnsureTargetPageTags>
    )
  }
}

const tagPickerController = connect(
  (state) => ({
    tags: state.targetPage.tags.items || [],
  }),
  null,
  null,
  { forwardRef: true },
)(TagPickerController)

export default tagPickerController

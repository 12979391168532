import { ChannelType } from 'common/core/constants/ChannelType'

export enum TriggerCategoryType {
  INSTAGRAM = 'instagram',
  MESSENGER = 'messenger',
  WHATSAPP = 'whatsapp',
  TELEGRAM = 'telegram',
  SMS = 'sms',
  EMAIL = 'email',
  CONTACT_EVENTS = 'contact_events',
  HOTMART = 'hotmart',
  TIKTOK = 'tiktok',
  TIKTOK_ADS = 'tiktok_ads',
}

export const ChannelTypeToTriggerTypeMap = {
  [ChannelType.INSTAGRAM]: TriggerCategoryType.INSTAGRAM,
  [ChannelType.FB]: TriggerCategoryType.MESSENGER,
  [ChannelType.WHATSAPP]: TriggerCategoryType.WHATSAPP,
  [ChannelType.TELEGRAM]: TriggerCategoryType.TELEGRAM,
  [ChannelType.SMS]: TriggerCategoryType.SMS,
  [ChannelType.EMAIL]: TriggerCategoryType.EMAIL,
  [ChannelType.TIKTOK]: TriggerCategoryType.TIKTOK,
}

import { makeLimitedListReducer } from 'utils/factory'

// export default makeLimitedListReducer('storeTemplates')

// const defaultTemplateData = {
//   title: "Don't delete this template!",
//   subtitle: 'Template for UI Tests',
//   description: "Don't delte!",
//   authors: 'manychat',
//   instruction: '',
//   cover_url:
//     'https://manybot-thumbnails.s3.eu-central-1.amazonaws.com/manychat/tpl/big_3051f0f96bd1ade7b02d89be704b8afb.jpg',
//   video_url: null,
//   live_demo_url: '',
//   screenshots: [],
//   integrations: [],
//   categories: [],
//   pro_status: 0,
//   internal_template_data: [],
//   ts_version_updated: 1551180761,
// }

// function createMocked(count = 20) {
//   const arr = new Array(count).fill({})
//   console.log(arr, arr.length)
//   arr.forEach((item, index) => {
//     arr[index] = { ...defaultTemplateData, template_id: index }
//     return arr[index]
//   })

//   return arr
// }

// const mockedTemplates = createMocked()

const limitedListReducer = makeLimitedListReducer('storeTemplates')

export default function templatesStorereduces(state = {}, action) {
  state = limitedListReducer(state, action)

  // switch (action.type) {
  //   case 'STORETEMPLATES_FETCH_RESPONSE': {
  //     return {
  //       ...state,
  //       items: [...state.items, ...mockedTemplates],
  //     }
  //   }
  // }

  return state
}

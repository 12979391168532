import React from 'react'
import debugConstructor from 'debug'
import { l, LinkBtn } from '@manychat/manyui'

import * as dashboardActions from 'apps/dashboard/actions/dashboardActions'
import * as widgetsActions from 'apps/growthTools/actions/widgetsActions'
import { resetFetchedStatusForAllIceBreakers } from 'apps/iceBreakers/actions/iceBreakersActions'
import { cleanupAfterTemplateDeletion } from 'apps/keywords/keywordsSlice'
import * as atypes from 'apps/templates/constants/ReduxActionTypes'
import { getSignature } from 'apps/templates/selectors/templateInstallationSelectors'
import * as triggerRuleActions from 'apps/triggerRules/actions/triggerRuleActions'
import { alert } from 'common/core'
import { addNotification } from 'common/core/actions/uiActions'
import { SupportTicketLink } from 'common/core/components/SupportLinks'
import * as userFieldsActions from 'common/fields/actions/userFieldsActions'
import { GlobalFieldsActions } from 'common/fields/store/globalFields'
import { flowPermanentlyRemoved } from 'common/flow/actions/flowActions'
import * as sequenceActions from 'common/sequences/actions/sequenceActions'
import { TagsActions } from 'common/tags/store'
import { templates as API } from 'constants/API'
import { makeLimitedListActions, makeCurrentItemActions } from 'utils/factory'
import {
  anotherTabNotificationsListener,
  userNotificationsListener,
} from 'utils/services/notificationsService'

const debug = debugConstructor('templates')

const baseListActions = makeLimitedListActions('templates', { storePath: 'templates.list' })
export const dropTemplates = baseListActions.drop
export const fetchTemplates = baseListActions.fetch

const baseItemActions = makeCurrentItemActions('templates', { storePath: 'templates.current' })
export const fetchTemplate = baseItemActions.fetch
export const saveTitle = baseItemActions.saveTitle
export const saveShare = baseItemActions.saveShare

export const saveDescription = (url, options) => {
  const { description, ...rest } = options
  description.guideLink = description.guideLink ? description.guideLink.trim() : ''
  description.videoGuideLink = description.videoGuideLink ? description.videoGuideLink.trim() : ''

  return baseItemActions.saveDescription(url, {
    description,
    ...rest,
  })
}

export const deleteTemplate = (id) => baseListActions.delete(id, { template_id: id })

export const deleteTemplateNotification = (id) => {
  return baseListActions.deletedNotification(id)
}

export const createTemplate = (data) => {
  return baseListActions.create(data)
}

export const createTemplateNotification = (data) => baseListActions.createdNotification(data)

export const updateTemplate = (data) => {
  return baseItemActions.saveContent(null, data)
}

// returning whole template object on every change is too expensive for backend,
// so here is a custom action with a respective reducer in templates.js
export const updateTemplateNotification = (data) => ({
  type: atypes.TEMPLATES_NOTIFIACTIONS_UPDATE,
  data,
})

export const updateTemplateStructureNotification = (data) =>
  baseItemActions.updatedNotification(data)

export const uploadTemplateAvatar = (avatar) => {
  const formData = new FormData()

  formData.append('image100x100', avatar)

  return (dispatch) => {
    return dispatch({
      type: atypes.TEMPLATE_UPLOAD_AVATAR,
      $fetch: [API.endpoints.uploadAvatar, { method: 'POST', body: formData }],
    })
  }
}

export const updateTemplateAvatar = baseItemActions.updateAvatar

const baseInstalledTemplatesListActions = makeLimitedListActions('installedTemplates', {
  storePath: 'templates.installed',
})
export const getInstalledTemplates = baseInstalledTemplatesListActions.fetch
const baseInstalledTemplatesItemActions = makeCurrentItemActions('installedTemplates', {
  storePath: 'templates.currentInstalled',
})

export const deleteInstalledTemplateNotification = (id) => {
  return baseInstalledTemplatesListActions.deletedNotification(id)
}

export const forceFetchInstalledTemplates = () => {
  return (dispatch, getState) => {
    const state = getState().templates
    if (state.currentInstalled.item) {
      const installationId = state.currentInstalled.item.installationId

      if (
        state.installed.byId[installationId] &&
        state.currentInstalled.item.isProtected !== state.installed.byId[installationId].isProtected
      ) {
        const fakeHash = String(Math.random() * 100)
        dispatch(getInstalledTemplates(fakeHash))
      } else {
        dispatch(getInstalledTemplates())
      }
    } else {
      dispatch(getInstalledTemplates())
    }
  }
}

export const fetchInstalledTemplate = baseInstalledTemplatesItemActions.fetch
/**
 * @param {number} installationId
 * @param {Object} deleteOptions
 * @return {Function}
 */
export const deleteInstalledTemplate = (installationId, deleteOptions = {}) => {
  return async (dispatch, getState) => {
    try {
      await dispatch({
        type: atypes.DELETE_INSTALLED_TEMPLATE,
        $fetch: [
          API.endpoints.deleteInstalled,
          {
            method: 'POST',
            body: JSON.stringify({
              template_installation_id: installationId,
              delete_subscribers_data: deleteOptions.subscribersData,
              delete_main_menu: deleteOptions.mainMenu,
              delete_ice_breakers: deleteOptions.icebreakers,
            }),
            headers: {
              'Content-Type': 'application/json',
            },
          },
        ],
        $import: (data) => ({ ...data, installationId }),
        $success: () => {
          alert(l.translate('Template deleting is in progress'), 'success')
        },
        $error: () => {
          alert('An error ocurred during Template uninstall.', 'danger', {
            content: (
              <span>
                Please,&nbsp;
                <LinkBtn
                  target="_blank"
                  href={SupportTicketLink.HELP_OPTIONS}
                  className="text-underline text-default m-t"
                  rel="noreferrer"
                >
                  contact support
                </LinkBtn>
                &nbsp;to resolve this situation.
              </span>
            ),
          })
        },
      })
      dispatch(resetFetchedStatusForAllIceBreakers())
      dispatch(dashboardActions.fetchEntitiesCount())
    } catch (err) {
      debug(err)
    }
  }
}

export const updateTemplateInstallationStatus = (data) => ({
  type: atypes.TEMPLATES_INSTALLATION_STATUS_UPDATE,
  data,
})

export const fetchSingleuseLink = (template_id) => ({
  type: atypes.TEMPLATE_FECTH_SINGLEUSE_LINK,
  $fetch: [
    API.endpoints.fetchSingleuseLink,
    {
      method: 'POST',
      body: JSON.stringify({
        template_id,
      }),
      headers: { 'Content-Type': 'application/json' },
    },
  ],
})

export const setShareType = (template_id, shareType) => ({
  type: atypes.TEMPLATE_SET_SHARE_TYPE,
  $fetch: [
    API.endpoints.setShareType,
    {
      method: 'POST',
      body: JSON.stringify({
        share_type: shareType,
        template_id,
      }),
      headers: { 'Content-Type': 'application/json' },
    },
  ],
})

export const editInstalledTemplateProtection = (status, pageId, installationId) => ({
  type: atypes.INSTALLED_TEMPLATE_EDIT_PROTECTION,
  $fetch: [
    API.endpoints.editInstalledTemplateProtection,
    {
      method: 'POST',
      body: JSON.stringify({
        is_protected: status,
        page_id: pageId,
        template_installation_id: installationId,
      }),
      headers: { 'Content-Type': 'application/json' },
    },
  ],
})

userNotificationsListener.on('template_created', (data, dispatch) => {
  dispatch(createTemplateNotification(data.model))
})
userNotificationsListener.on('template_updated', (data, dispatch) => {
  if (data.model_id) {
    return dispatch(deleteTemplateNotification(data.model_id))
  }
  dispatch(updateTemplateNotification(data.model))
})
userNotificationsListener.on('template_version_updated', (data, dispatch) => {
  dispatch(updateTemplateStructureNotification(data.model))
})
userNotificationsListener.on('template_installation_status', (data, dispatch, getState) => {
  if (data.action_sign === getSignature(getState())) {
    if (!data.errors && data.progress === 0) {
      dispatch(addNotification(l.translate('Template installation is in progress'), 'success'))
    }

    if (!data.errors && data.progress === 100) {
      dispatch(addNotification(l.translate('Template installation is done'), 'success'))
    }

    if (data.errors) {
      dispatch(addNotification(data.errors, 'danger'))
    }

    dispatch(updateTemplateInstallationStatus(data))
  }
})

anotherTabNotificationsListener.on('template_uninstall', (data, dispatch) => {
  alert(l.translate('Template was uninstalled successfully'), 'success')
  const { entities } = data.model
  dispatch(deleteInstalledTemplateNotification(data.id))
  const deleteEntities = (items = [], action) => {
    if (items.length) {
      items.forEach((item) => {
        dispatch(action(Object.values(item)[0]))
      })
    }
  }
  deleteEntities(entities.custom_fields, userFieldsActions.fieldDeletedNotification)
  deleteEntities(entities.growth_tools, widgetsActions.widgetDeletedNotification)
  deleteEntities(entities.keywords, cleanupAfterTemplateDeletion)
  deleteEntities(entities.trigger_rules, triggerRuleActions.triggerRuleDeletedNotification)
  deleteEntities(entities.sequences, sequenceActions.sequenceRemovedNotification)
  dispatch(
    TagsActions.bulkDeleteItems({
      ids: (entities.tags ?? []).map((tag) => tag.tag_id),
    }),
  )
  dispatch(
    GlobalFieldsActions.bulkDeleteItems({
      ids: (entities.global_fields ?? []).map((field) => field.field_id),
    }),
  )
  deleteEntities(entities.flows, (ns) => flowPermanentlyRemoved(ns))
})

anotherTabNotificationsListener.on('template_uninstall_error', () => {
  alert(l.translate('An error ocurred during Template uninstall'), 'danger')
})

export const getItem = (key) => {
  try {
    return sessionStorage.getItem(key)
  } catch (err) {
    return null
  }
}

export const removeItem = (key) => {
  try {
    sessionStorage.removeItem(key)
  } catch (err) {}
}

export const setItem = (key, value) => {
  try {
    sessionStorage.setItem(key, value)
  } catch (err) {}
}

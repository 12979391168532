import React, { ReactHTML, MouseEvent, useMemo } from 'react'
import cx from 'classnames'

interface RowProps {
  Component?: keyof ReactHTML
  className?: string
  gutter?: number | boolean
  middle?: boolean
  nowrap?: boolean
  onClick?: (e: MouseEvent) => void
  children?: React.ReactNode
}

const Row = ({
  children,
  middle,
  gutter,
  nowrap,
  className,
  Component = 'div',
  ...rest
}: RowProps) => {
  const cls = useMemo(() => {
    const classNames = ['row']
    if (middle) {
      classNames.push('middle')
    }
    if (gutter) {
      classNames.push(gutter === 2 ? 'gutter-2' : 'gutter')
    }
    if (nowrap) {
      classNames.push('nowrap')
    }

    return classNames
  }, [middle, gutter, nowrap])

  return (
    <Component className={cx(cls, className)} {...rest}>
      {children}
    </Component>
  )
}

export default Row

import { useMemo } from 'react'
import { RouteObject, useNavigate, NavigateOptions } from 'react-router-dom'

import { makeRoutePath } from './makeRoutePath'
import { PatchedRouteObject, MakeObjectPath, FlattenTree } from './types'

export const makeRouteObjects = (
  routes?: readonly PatchedRouteObject[],
): RouteObject[] | undefined => {
  if (!routes?.length) {
    return undefined
  }

  return routes.map((route) => {
    const { children, ...rest } = route

    if (rest.index) {
      return rest
    }

    if ('query' in rest) {
      delete rest.query
    }

    if ('alias' in rest) {
      delete rest.alias
    }

    const madeChildren = makeRouteObjects(children)

    if (madeChildren) {
      return {
        ...rest,
        children: madeChildren,
      }
    }

    return { ...rest }
  })
}

export const makeRoutePathObjects = <T extends readonly PatchedRouteObject[]>(
  routes: T,
  parentPath?: string,
): MakeObjectPath<FlattenTree<T>> => {
  const aliases = new Set<string>()

  return routes.reduce<MakeObjectPath<FlattenTree<T>>>((acc, route) => {
    if (route.index) {
      return acc
    }

    const { children, query, path, alias } = route
    const fullPath = parentPath && parentPath !== '/' ? `${parentPath}/${path}` : path

    if (alias) {
      if (aliases.has(alias)) {
        throw new Error(`Duplicate alias: ${alias} in ${fullPath}`)
      }

      aliases.add(alias)
      acc = {
        ...acc,
        [alias]: { ...makeRoutePath({ path: path === '/' ? '' : fullPath, query }), alias },
      }
    }

    if (children) {
      acc = { ...acc, ...makeRoutePathObjects(children, fullPath) }
    }

    return acc
  }, {})
}

export const makeRoutes = <T extends readonly PatchedRouteObject[]>(tree: T) => ({
  routeObjects: makeRouteObjects(tree) || [],
  routes: makeRoutePathObjects(tree),
})

export const makeUseTypedNavigate =
  <T extends Record<string, { getLink: (params?: TSFixMe) => string }>>(routes: T) =>
  () => {
    type ResultObject = {
      [K in keyof T]: undefined extends Parameters<T[K]['getLink']>[0]
        ? (params?: Parameters<T[K]['getLink']>[0], options?: NavigateOptions) => void
        : (params: Parameters<T[K]['getLink']>[0], options?: NavigateOptions) => void
    }
    type Accumulator = Record<string, (params: unknown, options?: NavigateOptions) => void>

    const navigate = useNavigate()
    const result = useMemo(
      () =>
        Object.keys(routes).reduce<Accumulator>(
          (acc, key) => ({
            ...acc,
            [key]: (params, options) =>
              options
                ? navigate(routes[key].getLink(params), options)
                : navigate(routes[key].getLink(params)),
          }),
          {},
        ) as ResultObject,
      [navigate],
    )

    return result
  }

export const makeUseTypedParams = <T extends Record<string, { useTypedParams: () => unknown }>>(
  routes: T,
) => {
  type ResultObject = {
    [K in keyof T]: T[K]['useTypedParams']
  }

  return Object.keys(routes).reduce(
    (acc, key) => ({
      ...acc,
      [key]: () => routes[key].useTypedParams(),
    }),
    {},
  ) as ResultObject
}

export const makeRouteLinkBuilder = <
  T extends Record<string, { getLink: (params?: TSFixMe) => string }>,
>(
  routes: T,
) => {
  type ResultObject = {
    [K in keyof T]: T[K]['getLink']
  }

  return Object.keys(routes).reduce(
    (acc, key) => ({
      ...acc,
      [key]: routes[key].getLink,
    }),
    {},
  ) as ResultObject
}

import assert from 'assert'

import times from 'lodash/times'
import without from 'lodash/without'

import { createBlock } from 'common/builder/actions/createBlock'
import { createLinkedBlock as createNodeLinkedBlock } from 'common/builder/actions/createLinkedBlock'
import { linkBlock } from 'common/builder/actions/linkBlock'
import { moveBlock } from 'common/builder/actions/moveBlock'
import { reorderBlocks } from 'common/builder/actions/reorderBlocks'
import { setBusy } from 'common/builder/actions/setBusy'
import { BlockType } from 'common/builder/constants/BlockType'
import { BlockTypesMinNested } from 'common/builder/models/Block/constants'
import builderSelectors from 'common/builder/selectors/builder'
import * as contentSelectors from 'common/builder/selectors/builder/contentSelectors'
import * as entitySelectors from 'common/builder/selectors/builder/entitySelectors'
import hotjarService from 'utils/services/hotjar'

import * as blockActions from './blockActions'
import * as builderAsyncActions from './builderAsyncActions'

/**
 * Возвращает контент, если контент не загружен локально, скачивает с сервера
 * @param builderId
 * @param contentId
 * @param options - {force, silent}
 */
export function ensureContent(builderId, contentId, options = {}) {
  return async (dispatch, getState) => {
    const state = getState()

    if (options.force) {
      return dispatch(fetchContent(builderId, contentId, options))
    }

    const contentItem = entitySelectors.getNodeById(state, builderId, contentId)

    if (contentItem) {
      return contentItem
    }

    return dispatch(fetchContent(builderId, contentId, options))
  }
}

/**
 * @param builderId
 * @param contentId
 * @param options - {silent}
 */
export function fetchContent(builderId, contentId, options = {}) {
  return async (dispatch, getState) => {
    if (!options.silent) {
      dispatch(setBusy(builderId, true))
    }

    try {
      await dispatch(builderAsyncActions.provideContent(builderId, contentId))
      if (!options.silent) {
        dispatch(setBusy(builderId, false))
      }
      return entitySelectors.getNodeById(getState(), builderId, contentId)
    } catch (error) {
      if (!options.silent) {
        dispatch(setBusy(builderId, false))
      }
      return null
    }
  }
}

/**
 * Загружает все не загруженные внешние контенты
 * @param builderId
 */
export function ensureForeignContents(builderId) {
  return async (dispatch, getState) => {
    const state = getState()

    const linkedContentIds = contentSelectors.getConnectedContentIds(state, builderId)
    const promises = linkedContentIds.map((contentId) => {
      return dispatch(ensureContent(builderId, contentId, { silent: true }))
    })

    return window.Promise.all(promises)
  }
}

/**
 * @param builderId
 * @param contentId
 * @param options - {initialItems}
 */
function createLinkedCardsBlock(builderId, contentId, options = {}) {
  return (dispatch) => {
    const initialItems = options.initialItems || BlockTypesMinNested[BlockType.CARDS]

    const blocks = times(initialItems, () =>
      dispatch(createBlock(builderId, { type: BlockType.CARD })),
    )

    const initial = {
      type: BlockType.CARDS,
      blocks: blocks.map((block) => block.id),
    }

    if (options.imageAspectRatio) {
      initial.imageAspectRatio = options.imageAspectRatio
    }

    if (options.noLink) {
      return dispatch(createBlock(builderId, initial))
    }
    return dispatch(createNodeLinkedBlock(builderId, contentId, initial))
  }
}

function createLinkedOneTimeNotifyRequestBlock(builderId, contentId, options = {}) {
  return (dispatch) => {
    if (options.noLink) {
      return dispatch(blockActions.createOneTimeNotifyRequestBlock(builderId))
    }
    const block = dispatch(blockActions.createOneTimeNotifyRequestBlock(builderId))
    dispatch(linkBlock(builderId, contentId, block.id))
    return block
  }
}

/**
 * @param builderId
 * @param contentId
 * @param options - {initialItems}
 */
function createLinkedListBlock(builderId, contentId, options = {}) {
  return (dispatch) => {
    const initialItems = options.initialItems || BlockTypesMinNested[BlockType.LIST]

    const blocks = times(initialItems, () =>
      dispatch(createBlock(builderId, { type: BlockType.LIST_ITEM })),
    )

    const initial = {
      type: BlockType.LIST,
      blocks: blocks.map((block) => block.id),
    }
    if (options.noLink) {
      return dispatch(createBlock(builderId, initial))
    }
    return dispatch(createNodeLinkedBlock(builderId, contentId, initial))
  }
}

/**
 * @param builderId
 * @param contentId
 * @param options - {initialItems}
 */
function createQuestionTimeoutBlock(builderId, contentId, options = {}) {
  return (dispatch) => {
    const block = dispatch(createBlock(builderId, { type: BlockType.FORM_QUESTION_TIMEOUT }))

    const initial = {
      type: BlockType.FORM_QUESTION,
      blocks: [block.id],
    }
    if (options.noLink) {
      return dispatch(createBlock(builderId, initial))
    }
    return dispatch(createNodeLinkedBlock(builderId, contentId, initial))
  }
}

/**
 * @param builderId
 * @param contentId
 * @param dragId
 * @param hoverId
 * @param options
 */
export function moveContentBlock(builderId, contentId, dragId, hoverId, options = {}) {
  return (dispatch) => {
    const order = dispatch(
      moveBlock(builderId, contentId, dragId, hoverId, {
        ...options,
        noCommit: true,
      }),
    )
    dispatch(reorderOrdinaryBlocks(builderId, contentId, order))
  }
}

/**
 * @param builderId
 * @param contentId
 * @param order
 */
export function reorderOrdinaryBlocks(builderId, contentId, order) {
  return (dispatch, getState) => {
    const state = getState()

    assert(builderId, `reorderOrdinaryBlocks: builderId is required param`)
    assert(contentId, `reorderOrdinaryBlocks: contentId is required param`)
    assert(order, `reorderOrdinaryBlocks: order is required param`)

    const qrBlock = builderSelectors.node.getQuickReplyBlock(state, builderId, contentId)
    const orderWithoutQRBlock = without(order, qrBlock?.id)
    const ids = qrBlock ? [...orderWithoutQRBlock, qrBlock.id] : [...orderWithoutQRBlock]
    return dispatch(reorderBlocks(builderId, contentId, ids))
  }
}

export const createLinkedBlock = (builderId, contentId, initial, { noLink = false }) => {
  return (dispatch) => {
    const { type } = initial
    if (type === BlockType.ONE_TIME_NOTIFY_REQUEST) {
      hotjarService.hotjarTrigger('otn_request_created', ['otn'])

      return dispatch(
        createLinkedOneTimeNotifyRequestBlock(builderId, contentId, {
          noLink,
        }),
      )
    }

    if (type === BlockType.CARD || type === BlockType.CARDS) {
      return dispatch(
        createLinkedCardsBlock(builderId, contentId, {
          // eslint-disable-next-line eqeqeq
          initialItems: type == BlockType.CARD ? 1 : 2,
          noLink,
          ...initial,
        }),
      )
    }

    if (type === BlockType.LIST) {
      return dispatch(
        createLinkedListBlock(builderId, contentId, {
          initialItems: 2,
          noLink,
        }),
      )
    }

    if (type === BlockType.FORM_QUESTION) {
      return dispatch(createQuestionTimeoutBlock(builderId, contentId, { noLink }))
    }

    if (noLink) {
      return dispatch(createBlock(builderId, initial))
    }
    return dispatch(createNodeLinkedBlock(builderId, contentId, initial))
  }
}

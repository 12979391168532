import {
  ALL_TEAM_MEMBERS_ID,
  ConversationFilterType,
  LiveChatThread,
} from 'apps/chat/constants/LiveChatInterfaces'
import { ThreadStatus } from 'apps/chat/interfaces/statuses'

interface AssignmentType {
  user_id: number
  name: string
  avatar: string
}

export const filterBulkThreads = (
  threads: LiveChatThread[],
  conversationsFilter: ConversationFilterType,
  teamMemberFilter: number,
  threadStatusesByStatusFilterAndFolder: ThreadStatus[],
  userId: number,
) => {
  return (threads || [])
    .filter((thread) => threadStatusesByStatusFilterAndFolder.includes(thread.lc_thread_status))
    .filter((thread) =>
      checkEqualThreadAssignByFolder(
        thread.assignment,
        conversationsFilter,
        teamMemberFilter,
        userId,
      ),
    )
}

export const checkEqualThreadAssignByFolder = (
  threadAssignment: AssignmentType | null,
  conversationsFilter: ConversationFilterType,
  teamMemberFilter: number,
  userId: number,
) => {
  switch (conversationsFilter) {
    case ConversationFilterType.ALL:
      return true
    case ConversationFilterType.UNASSIGNED:
      return threadAssignment === null
    case ConversationFilterType.TEAM:
      if (teamMemberFilter === ALL_TEAM_MEMBERS_ID) {
        return threadAssignment !== null
      }
      return threadAssignment?.user_id === teamMemberFilter
    case ConversationFilterType.YOU:
      return threadAssignment?.user_id === userId
    default:
      return false
  }
}

import { useState, useCallback } from 'react'

export type ToggleState = 'on' | 'off'

export const useToggle = () => {
  const [state, setState] = useState<ToggleState>('off')

  const toggle = useCallback(
    () => setState((prevState) => (prevState === 'off' ? 'on' : 'off')),
    [],
  )

  return { state: state, toggle }
}

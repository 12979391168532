import { ReplaceResult } from 'components/MCTextEditor/dataFormat/parse/mapReduceParser/types'

import { makeCloseMark, makeOpenMark } from '../dataFormat/parse/utils/marks'
import { ReplaceRule } from '../dataFormat/parse/utils/replace'

import { tagConfigs } from './constants'

export const openTagPattern = `<mc-[a-zA-Z-].*?>`
export const closeTagPattern = `<\/mc-[a-zA-Z-]+>`

const OPEN_PREFIX = '<'
const CLOSE_PREFIX = '</'
const POSTFIX = '>'

interface ParseTagResult {
  name: string
  attributes?: Record<string, string>
}

const parseAnyTagString = (input: string, close?: boolean): ParseTagResult | null => {
  if (!input.endsWith(POSTFIX)) {
    return null
  }
  const prefix = close ? CLOSE_PREFIX : OPEN_PREFIX
  for (const config of tagConfigs) {
    if (!input.startsWith(`${prefix}${config.tagName}`)) {
      continue
    }
    const attributes = close ? undefined : parseTagAttributes(input)
    return { name: config.sliceType, attributes: attributes || undefined }
  }
  return null
}

export const parseOpenTagString = (input: string): ParseTagResult | null => {
  return parseAnyTagString(input)
}

export const parseCloseTagString = (input: string): ParseTagResult | null => {
  return parseAnyTagString(input, true)
}

export const replaceOpenTag: ReplaceRule<ReplaceResult> = (input) => {
  const tag = parseOpenTagString(input)
  if (!tag) {
    return undefined
  }
  return makeOpenMark(tag.name, tag.attributes)
}

export const replaceCloseTag: ReplaceRule<ReplaceResult> = (input) => {
  const tag = parseCloseTagString(input)
  if (!tag) {
    return undefined
  }
  return makeCloseMark(tag.name)
}

export const parseTagAttributes = (tagString: string): Record<string, string> | null => {
  // Inside attributes not supported chars: <>"

  const TAG_OPEN_REGEX = /<([^\/ >]+).*?>/
  const [tagOpen, tagName] = TAG_OPEN_REGEX.exec(tagString) || []

  if (!tagOpen || !tagName) {
    return null
  }

  const attributesString = tagOpen
    .substr(0, tagOpen.length - 1)
    .replace(`<${tagName}`, '')
    .trim()

  if (!attributesString.length) {
    return {}
  }

  const attributes: Record<string, string> = {}

  const attrs = attributesString.split(/"\s+/)
  attrs.forEach((attr) => {
    if (!attr.endsWith('"')) {
      attr += '"'
    }
    const ATTR_REGEX = /([^\s]+)="(.+)"/
    const [, attrName, attrValue] = ATTR_REGEX.exec(attr) || []

    attributes[attrName] = attrValue
  })

  return attributes
}

export const clearTextFromTags = (text: string): string => {
  const reg = new RegExp(`${openTagPattern}|${closeTagPattern}`, 'g')

  return text.replace(reg, '')
}

export const containsMcTags = (text: string): boolean => {
  const reg = new RegExp(`${openTagPattern}|${closeTagPattern}`, 'g')

  return reg.test(text)
}

import { useState, useCallback, useEffect } from 'react'
import { useAppDispatch, useAppSelector } from 'reduxTyped'

import { CmsAnalytics } from 'apps/cms/lib/analytics'
import { contentManagementActions } from 'apps/cms/store'
import { AutomationModalType } from 'apps/cms/store/lib/types'
import { getCurrentAccountID } from 'common/core/selectors/appSelectors'
import { formatUsesListResponse } from 'common/usesList/utils'
import { CmsApi } from 'shared/api/requests/cms'

import { mergeEntities } from '../lib/mergeEntities'

type UsageInfo = ReturnType<typeof formatUsesListResponse> | null

export const useDeleteFolderModal = ({ path }: { path: string }) => {
  const dispatch = useAppDispatch()

  const currentAccountId = useAppSelector(getCurrentAccountID)

  const [isLoading, setIsLoading] = useState(false)
  const [usageInfo, setUsageInfo] = useState<UsageInfo | null>(null)

  const fetchUsesList = useCallback(async () => {
    const response = await CmsApi.getFolderUsesList({ query: { path: path } })

    setUsageInfo(formatUsesListResponse(mergeEntities(response.data), currentAccountId))
  }, [currentAccountId, path])

  const closeModal = useCallback(() => {
    dispatch(contentManagementActions.closeModal(AutomationModalType.DELETE_FOLDER))
  }, [dispatch])

  const removeFolder = useCallback(async () => {
    setIsLoading(true)

    await dispatch(contentManagementActions.deleteFolder({ path }))
    CmsAnalytics.logDeleteFolder()

    setIsLoading(false)

    closeModal()
  }, [closeModal, dispatch, path])

  useEffect(() => {
    fetchUsesList()
  }, [fetchUsesList])

  return {
    usageInfo,
    removeFolder,
    isLoading,
    closeModal,
  }
}

import React from 'react'
import { BtnV2, Modal, l, Loader } from '@manychat/manyui'

import { usePermanentDeleteFlow } from 'apps/cms/components/Flows/components/AutomationModals/modals/PermanentDeleteFlowModal/usePermanentDeleteFlow'
import UsesInAutomationList from 'components/UsesInAutomationList'

export const PermanentDeleteFlowModal = () => {
  const { closeModal, deleteFlow, isDeleting, usageInfo, flowName } = usePermanentDeleteFlow()

  return (
    <Modal
      width={500}
      open
      title={<div className="p-y-xs">{l.translate('Delete Automation')}</div>}
      buttons={[
        <BtnV2 disabled={isDeleting} className="m-y-xs" onClick={closeModal}>
          {l.translate('Cancel')}
        </BtnV2>,
        <BtnV2 variant="danger" loading={isDeleting} className="m-y-xs" onClick={deleteFlow}>
          {l.translate('Delete Now')}
        </BtnV2>,
      ]}
      onRequestClose={closeModal}
    >
      <div className="p-x-md p-t m-b text-left">
        {usageInfo && !usageInfo.isNoUses && (
          <>
            <div className="m-b-xxs m-t-xxs">
              {l.translate(
                'You are going to delete <strong>{name}</strong>. It will be fully removed from your bot and can’t be restored. Triggers within this Automation will be also deleted.',
                { name: flowName },
              )}
            </div>
            <div className="m-b-xxs">
              {l.translate('The Automation is used in following automations')}:
            </div>

            <div className="m-t m-b">
              <UsesInAutomationList uses={usageInfo} />
            </div>
          </>
        )}

        {usageInfo && usageInfo.isNoUses && (
          <div className="text-center">
            {l.translate(
              'You are going to delete <strong>{name}</strong>. It will be fully removed from your bot and can’t be restored.',
              { name: flowName },
            )}
          </div>
        )}
        {!usageInfo && <Loader size={20} />}
      </div>
    </Modal>
  )
}

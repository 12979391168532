import React from 'react'
import cx from 'classnames'
import random from 'lodash/random'
import { Skeleton } from '@manychat/manyui'

import cardCm from './FlowCard.module.css'
import cm from './FlowCardSkeleton.module.css'

interface FlowCardSkeletonProps {
  allowSelect: boolean
}

export const FlowCardSkeleton = React.memo(({ allowSelect }: FlowCardSkeletonProps) => {
  return (
    <div className={cardCm.card}>
      {allowSelect && (
        <div>
          <Skeleton.Text className={cx(cm.text, cm.checkboxSkeleton)} />
        </div>
      )}

      <div className={cardCm.card__body}>
        <div className={cx(cardCm.card__content)}>
          <div className={cm.titleColumn}>
            <Skeleton.Text minLineWidth={`${random(80, 120)}px`} className={cm.text} />
          </div>

          <div />

          <div className={cx(cardCm.card__hiddenOnMobile, cm.runsColumn)}>
            <Skeleton.Text className={cx(cm.text, cm.runsSkeleton)} />
          </div>

          <div className={cx(cardCm.card__hiddenOnMobile, cm.ctrColumn)}>
            <Skeleton.Text className={cx(cm.text, cm.ctrSkeleton)} />
          </div>

          <div className={cx(cardCm.card__hiddenOnMobile, cm.titleColumn)}>
            <Skeleton.Text minLineWidth={`${random(60, 80)}px`} className={cx(cm.text)} />
          </div>

          <div className={cm.contextMenuColumn}>
            <Skeleton.Text className={cx(cm.text, cm.contextMenuSkeleton)} />
          </div>
        </div>
      </div>
    </div>
  )
})

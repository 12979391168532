import React from 'react'

import { WABrokenMessageTemplateIsInUse } from 'common/core/components/NotifyBar/WhatsAppNotifications/WABrokenMessageTemplateIsInUse/WABrokenMessageTemplateIsInUse'
import WADisabledForInactivityNotification from 'common/core/components/NotifyBar/WhatsAppNotifications/WADisabledForInactivityNotification/WADisabledForInactivityNotification'
import WASuccessfulVerificationNotification from 'common/core/components/NotifyBar/WhatsAppNotifications/WASuccessfulVerificationNotification/WASuccessfulVerificationNotification'
import WASuccessSandboxActivated from 'common/core/components/NotifyBar/WhatsAppNotifications/WASuccessSandboxActivated/WASuccessSandboxActivated'
import WATierLimitNotification from 'common/core/components/NotifyBar/WhatsAppNotifications/WATierLimitNotification/WATierLimitNotification'
import WATurnedOffNotification from 'common/core/components/NotifyBar/WhatsAppNotifications/WATurnedOffNotification/WATurnedOffNotification'
import WATwilioChargeErrorNotification from 'common/core/components/NotifyBar/WhatsAppNotifications/WATwilioChargeErrorNotification/WATwilioChargeErrorNotification'
import WAUpgradeToProNotification from 'common/core/components/NotifyBar/WhatsAppNotifications/WAUpgradeToProNotification/WAUpgradeToProNotification'
import WAVerificationDeclinedNotification from 'common/core/components/NotifyBar/WhatsAppNotifications/WAVerificationDeclinedNotification/WAVerificationDeclinedNotification'
import WAViolatedNotification from 'common/core/components/NotifyBar/WhatsAppNotifications/WAViolatedNotification/WAViolatedNotification'
import { NotificationBarType } from 'common/core/constants/NotificationBarType'

import {
  NotificationRemoveType,
  WhatsAppBrokenMessageTemplateNotification,
  WhatsAppMessageTemplateDisabledNotification,
  WhatsAppMessageTemplatePausedNotification,
  WhatsAppNotification,
  WhatsAppNotificationType,
  WhatsAppReadyNotification,
  WhatsAppTwilioChargeErrorNotification,
} from '../NotifyInterfaces'

import { BrokenMessageTemplate as WABrokenMessageTemplateNotification } from './BrokenMessageTemplate/BrokenMessageTemplate'
import { BusinessAccountDeleted } from './BusinessAccountDeleted'
import { PhoneNumberRemoved } from './PhoneNumberRemoved'
import { WABannedNotification } from './WABannedNotification'
import { WACoexistenceContactImportCompleted } from './WACoexistenceContactImport/WACoexistenceContactImportCompleted'
import { WACoexistenceContactImportFailed } from './WACoexistenceContactImport/WACoexistenceContactImportFailed'
import { WACoexistenceContactImportPending } from './WACoexistenceContactImport/WACoexistenceContactImportPending'
import { WAFlaggedNotification } from './WAFlaggedNotification'
import { WAMessageTemplateCategoryChange } from './WAMessageTemplateCategoryChange'
import { WAMessageTemplateDisabledNotification } from './WAMessageTemplateDisabledNotification'
import { WAMessageTemplatePausedNotification } from './WAMessageTemplatePausedNotification'
import { WAReinstatedNotification } from './WAReinstatedNotification'

function isWhatsAppTwilioChargeErrorNotification(
  notification: WhatsAppNotification,
  message: WhatsAppNotification['message'],
): notification is WhatsAppTwilioChargeErrorNotification {
  return notification.message === message
}

function isWhatsAppBrokenMessageTemplateNotification(
  notification: WhatsAppNotification,
  message: WhatsAppNotification['message'],
): notification is WhatsAppBrokenMessageTemplateNotification {
  return notification.message === message
}

function isWhatsAppMessageTemplatePausedNotification(
  notification: WhatsAppNotification,
  message: WhatsAppNotification['message'],
): notification is WhatsAppMessageTemplatePausedNotification {
  return notification.message === message
}

function isWhatsAppMessageTemplateDisabledNotification(
  notification: WhatsAppNotification,
  message: WhatsAppNotification['message'],
): notification is WhatsAppMessageTemplateDisabledNotification {
  return notification.message === message
}

export type FilteredWhatsAppNotificationType = Exclude<
  WhatsAppNotificationType,
  | WhatsAppNotificationType.ERROR_TWILIO_CHARGE
  | WhatsAppNotificationType.BROKEN_MESSAGE_TEMPLATE
  | WhatsAppNotificationType.MESSAGE_TEMPLATE_PAUSED
  | WhatsAppNotificationType.MESSAGE_TEMPLATE_DISABLED
>

const getRender = (
  message: FilteredWhatsAppNotificationType,
): WhatsAppReadyNotification['render'] => {
  switch (message) {
    case WhatsAppNotificationType.ACTIVATED:
      return WAUpgradeToProNotification
    case WhatsAppNotificationType.BANNED:
      return WABannedNotification
    case WhatsAppNotificationType.VIOLATED:
      return WAViolatedNotification
    case WhatsAppNotificationType.FLAGGED:
      return WAFlaggedNotification
    case WhatsAppNotificationType.TIER_LIMIT_EXCEED:
      return WATierLimitNotification
    case WhatsAppNotificationType.SUCCESS_SANDBOX_ACTIVATED:
      return WASuccessSandboxActivated
    case WhatsAppNotificationType.DECLINED:
      return WAVerificationDeclinedNotification
    case WhatsAppNotificationType.VERIFIED:
      return WASuccessfulVerificationNotification
    case WhatsAppNotificationType.STOPPED:
      return WADisabledForInactivityNotification
    case WhatsAppNotificationType.TURNED_OFF:
      return WATurnedOffNotification
    case WhatsAppNotificationType.REINSTATED:
      return WAReinstatedNotification
    case WhatsAppNotificationType.PHONE_NUMBER_REMOVED:
      return PhoneNumberRemoved
    case WhatsAppNotificationType.BUSINESS_ACCOUNT_DELETED:
      return BusinessAccountDeleted
    case WhatsAppNotificationType.BROKEN_MESSAGE_TEMPLATE_IS_IN_USE:
      return WABrokenMessageTemplateIsInUse
    case WhatsAppNotificationType.MESSAGE_TEMPLATE_CATEGORY_CHANGED:
      return WAMessageTemplateCategoryChange
    case WhatsAppNotificationType.COEXISTENCE_CONTACT_IMPORT_PENDING:
      return WACoexistenceContactImportPending
    case WhatsAppNotificationType.COEXISTENCE_CONTACT_IMPORT_SUCCESS:
      return WACoexistenceContactImportCompleted
    case WhatsAppNotificationType.COEXISTENCE_CONTACT_IMPORT_ERROR:
      return WACoexistenceContactImportFailed
  }
}

const getMessageType = (message: WhatsAppNotification['message']): NotificationBarType => {
  switch (message) {
    case WhatsAppNotificationType.SUCCESS_SANDBOX_ACTIVATED:
    case WhatsAppNotificationType.TURNED_OFF:
    case WhatsAppNotificationType.VERIFIED:
    case WhatsAppNotificationType.PHONE_NUMBER_REMOVED:
    case WhatsAppNotificationType.BUSINESS_ACCOUNT_DELETED:
    case WhatsAppNotificationType.REINSTATED:
    case WhatsAppNotificationType.COEXISTENCE_CONTACT_IMPORT_SUCCESS:
      return NotificationBarType.SUCCESS
    case WhatsAppNotificationType.STOPPED:
    case WhatsAppNotificationType.BANNED:
    case WhatsAppNotificationType.VIOLATED:
    case WhatsAppNotificationType.FLAGGED:
    case WhatsAppNotificationType.DECLINED:
    case WhatsAppNotificationType.COEXISTENCE_CONTACT_IMPORT_ERROR:
      return NotificationBarType.ERROR
    case WhatsAppNotificationType.ACTIVATED:
    case WhatsAppNotificationType.TIER_LIMIT_EXCEED:
    case WhatsAppNotificationType.ERROR_TWILIO_CHARGE:
    case WhatsAppNotificationType.BROKEN_MESSAGE_TEMPLATE:
    case WhatsAppNotificationType.BROKEN_MESSAGE_TEMPLATE_IS_IN_USE:
    case WhatsAppNotificationType.MESSAGE_TEMPLATE_CATEGORY_CHANGED:
    case WhatsAppNotificationType.MESSAGE_TEMPLATE_PAUSED:
    case WhatsAppNotificationType.MESSAGE_TEMPLATE_DISABLED:
      return NotificationBarType.WARNING
    case WhatsAppNotificationType.COEXISTENCE_CONTACT_IMPORT_PENDING:
      return NotificationBarType.INFO
  }
}

export const parseWANotification = (
  notification: WhatsAppNotification,
): WhatsAppReadyNotification => {
  const whatsAppReadyNotificationBase = {
    ...notification,
    type: getMessageType(notification.message),
    removeType: NotificationRemoveType.ALWAYS,
  }

  if (
    isWhatsAppTwilioChargeErrorNotification(
      whatsAppReadyNotificationBase,
      WhatsAppNotificationType.ERROR_TWILIO_CHARGE,
    )
  ) {
    return {
      ...whatsAppReadyNotificationBase,
      render: () => <WATwilioChargeErrorNotification data={whatsAppReadyNotificationBase.data} />,
    }
  }

  if (
    isWhatsAppBrokenMessageTemplateNotification(
      whatsAppReadyNotificationBase,
      WhatsAppNotificationType.BROKEN_MESSAGE_TEMPLATE,
    )
  ) {
    return {
      ...whatsAppReadyNotificationBase,
      render: () => (
        <WABrokenMessageTemplateNotification data={whatsAppReadyNotificationBase.data} />
      ),
    }
  }

  if (
    isWhatsAppMessageTemplatePausedNotification(
      whatsAppReadyNotificationBase,
      WhatsAppNotificationType.MESSAGE_TEMPLATE_PAUSED,
    )
  ) {
    return {
      ...whatsAppReadyNotificationBase,
      render: () => (
        <WAMessageTemplatePausedNotification data={whatsAppReadyNotificationBase.data} />
      ),
    }
  }

  if (
    isWhatsAppMessageTemplateDisabledNotification(
      whatsAppReadyNotificationBase,
      WhatsAppNotificationType.MESSAGE_TEMPLATE_DISABLED,
    )
  ) {
    return {
      ...whatsAppReadyNotificationBase,
      render: () => (
        <WAMessageTemplateDisabledNotification data={whatsAppReadyNotificationBase.data} />
      ),
    }
  }

  return {
    ...whatsAppReadyNotificationBase,
    render: getRender(whatsAppReadyNotificationBase.message as FilteredWhatsAppNotificationType),
  }
}

import { sundayFirst, monthFirst } from '../constants/date'

import { getLocale } from './localization/format'

import { pad } from './index'

const timeOnly = /^([0-2]\d):([0-5]\d)$/ // (:[0-5]\d(\.\d{1,3})?)?
const iso8601 =
  /^\d{4}-[01]\d-[0-3]\dT[0-2]\d:[0-5]\d:[0-5]\d(\.\d{1,3})?(Z|[+-][012]\d:?[0-5]\d)?$/
const iso8601DateOnly = /^\d{4}-[01]\d-[0-3]\d$/

export const isDate = (date) => !!date && date instanceof Date

export const isValidDateTime = (v) => iso8601.test(v)

export const isValidDate = (v) => iso8601.test(v) || iso8601DateOnly.test(v)

export const isValidTime = (v) => timeOnly.test(v)

/**
 * Parser for Date's and iso8601 strings. Differs to Date constructor in 2 cases:
 * - Strings like 2000/01/01 parsed in local time, not UTC
 * - Non-iso8601 strings will return null, not Invalid Date
 * @param {*} v
 */
export const parseDate = (v) => {
  if (iso8601DateOnly.test(v)) {
    const data = v.split('-')
    return new Date(+data[0], data[1] - 1, +data[2])
  }
  if (isDate(v) || iso8601.test(v) || typeof v === 'number') {
    return new Date(v)
  }
  return null
}

export const formatDate = (v) => {
  if (isDate(v)) {
    return `${v.getFullYear()}-${pad(v.getMonth() + 1)}-${pad(v.getDate())}`
  }
  if (v != null && v.year != null && v.month != null && v.day) {
    return `${v.year}-${pad(v.month)}-${pad(v.day)}`
  }
  return ''
}

export const parseTime = (v) => {
  if (typeof v === 'string') {
    const matches = v.match(timeOnly)
    if (matches != null) {
      return { hour: +matches[1], minute: +matches[2] }
    }
  }
  return { hour: null, minute: null }
}

export const formatTime = (v) => {
  if (isDate(v)) {
    return `${pad(v.getHours())}:${pad(v.getMinutes())}`
  }
  if (v != null && v.hour != null && v.minute != null) {
    return `${pad(v.hour)}:${pad(v.minute)}`
  }
  return ''
}

export const isSameDay = (d1, d2) => formatDate(d1) === formatDate(d2)

export const isAfter = (d1, d2) => formatDate(d1) > formatDate(d2)

export const isBefore = (d1, d2) => formatDate(d1) < formatDate(d2)

export const isSundayFirst = () => sundayFirst.includes(getLocale().toLowerCase())
export const isMonthFirst = () => monthFirst.includes(getLocale().toLowerCase())

export const applyTime = (d1, d2) => {
  if (isDate(d2)) {
    const time = new Date(d2)
    d1.setHours(time.getHours(), time.getMinutes(), time.getSeconds())
  }
  return d1
}

const MONTH_IN_MS = 1000 * 60 * 60 * 24 * 31
export const isPeriodMoreThanMonth = (d1, d2) => {
  const periodStart = new Date(d1)
  const periodEnd = new Date(d2)

  const diff = Math.abs(periodEnd - periodStart)

  return diff >= MONTH_IN_MS
}

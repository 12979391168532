import React, { ChangeEvent, useMemo, useState } from 'react'
import { l, Loader } from '@manychat/manyui'

import { useUserLanguage } from 'apps/locale/hooks/useUserLanguage'
import { Select } from 'components/forms/Select/Select'

import { MyProfilePopoverAnalytics } from '../../lib/analytics'

export const LanguageSelect = () => {
  const { language, allowedLanguages, isLoading, setLanguage } = useUserLanguage()
  const [userLanguage, setUserLanguage] = useState(language)

  const options = useMemo(() => {
    return allowedLanguages.map((allowedLanguage) => ({
      label: l.getLanguageName(allowedLanguage) ?? '',
      value: allowedLanguage,
    }))
  }, [allowedLanguages])

  const handleLanguageChange = (event: ChangeEvent<HTMLSelectElement>) => {
    MyProfilePopoverAnalytics.logLanguageChange(event.target.value)
    setLanguage(event.target.value)
    setUserLanguage(event.target.value)
  }

  return (
    <div className="d-flex p-x-md p-y align-center b-y">
      <span className="text-sm text-secondary m-r-sm">{l.translate('Language')}:</span>
      {isLoading ? (
        <Loader size={36} />
      ) : (
        <Select
          selectClassName="text-capitalize"
          className="flex-grow-1"
          name="languageSelect"
          value={userLanguage}
          onChange={handleLanguageChange}
          options={options}
        />
      )}
    </div>
  )
}

import find from 'lodash/find'
import get from 'lodash/get'
import { l } from '@manychat/manyui'

import { fetchSubscriber } from 'common/audience/actions/audienceActionsTyped'
import { billing, UpgradeSource } from 'common/billing'
import { handleGoProError } from 'common/billing/helpers/handleGoProError'
import { alert } from 'common/core'
import { ProductType } from 'common/core/interfaces/products'
import { getCurrentAccount } from 'common/core/selectors/appSelectors'
import * as userFieldsSelectors from 'common/fields/selectors/userFieldsSelectors'
import { formatEntitiesListResponse } from 'common/usesList/utils'
import * as API from 'constants/API'
import { isRequestError } from 'utils/api/mcApi/RequestError'
import { makeLimitedListActions } from 'utils/factory'
import { RoutePaths } from 'utils/router/constants'
import { getPageId, getQuery, isPathnameMatchRoute } from 'utils/router/tools'
import { analyticsService } from 'utils/services/analytics'
import errorTrackingService from 'utils/services/errorTrackingService'
import { anotherTabNotificationsListener } from 'utils/services/notificationsService'

import * as atypes from '../constants/fieldsReduxActionTypes'

import { fetchUserFieldsWithParams } from './userFieldActions'

const USER_FIELDS_METRICS_MAP = {
  SETTINGS: {
    route: RoutePaths.SETTINGS,
    event: 'SETTINGS.CUSTOM_FIELD.CREATE',
  },
  AUDIENCE: {
    route: RoutePaths.AUDIENCE_SCOPE,
    event: 'AUDIENCE.CUSTOM_FIELD.CREATE',
  },
  CHAT: {
    route: RoutePaths.CHAT,
    event: 'LIVE_CHAT.CUSTOM_FIELD.CREATE',
  },
  CMS_BUILDER: {
    route: RoutePaths.CMS_BUILDER,
    event: 'FLOW.CUSTOM_FIELD.CREATE',
  },
}

const baseListActions = makeLimitedListActions('userFields')

export const fetchUserFields = (includeArchived) =>
  baseListActions.fetch({ active_only: !includeArchived })

export const fetchUserFieldsWithData = (params) => baseListActions.fetch(params)

export const fieldCreatedNotification = baseListActions.createdNotification
export const fieldUpdatedNotification = baseListActions.updatedNotification
export const fieldDeletedNotification = baseListActions.deletedNotification
export const fieldUpdate = baseListActions.update

let newFieldPromise
const rechargeNewFieldPromise = () =>
  new Promise((resolve, reject) => (newFieldPromise = { resolve, reject }))
newFieldPromise = rechargeNewFieldPromise()

export const createField = () => {
  return async (dispatch, getState, { api }) => {
    try {
      const { title: caption, type, description, folderPath } = getState().userFields.createModal
      const data = { caption, type, description, path: folderPath }

      dispatch({ type: atypes.USERFIELDS_CREATE_PENDING })
      const field = await api.fields.createUserField(data)
      dispatch({ type: atypes.USERFIELDS_CREATE_SUCCESS })

      newFieldPromise.resolve(field)

      const currentRouteKey = Object.keys(USER_FIELDS_METRICS_MAP).find((routeKey) => {
        const pattern = USER_FIELDS_METRICS_MAP[routeKey].route
        return isPathnameMatchRoute(pattern)
      })

      if (USER_FIELDS_METRICS_MAP[currentRouteKey]) {
        analyticsService.sendEvent(USER_FIELDS_METRICS_MAP[currentRouteKey].event)
      }

      const { path } = getQuery() ?? '/'
      const searchInputValue = userFieldsSelectors.getUserFieldSearchValue(getState())
      const fetchParams = searchInputValue ? { query: searchInputValue } : { path: path }

      dispatch(fetchUserFieldsWithParams(true, fetchParams))

      analyticsService.sendEvent('SESSION_QUALITY.SAVE.BOT')

      alert(l.translate('Field Created!'), 'success')
    } catch (err) {
      dispatch({ type: atypes.USERFIELDS_CREATE_REJECT })
      newFieldPromise.resolve(null)

      if (!isRequestError(err)) {
        alert(err.message, 'danger')

        errorTrackingService.trackError(err.message)
        return
      }

      if (err.response?.go_pro) {
        const message = Array.isArray(err.response.errors)
          ? err.response.errors?.join(', ')
          : l.translate(
              "You aren't able to activate this feature on the current plan. Upgrade for unlimited access.",
            )

        dispatch(
          handleGoProError(err, {
            message,
            modalTitle: l.translate('Create unlimited number of variables with Manychat Pro'),
            source: UpgradeSource.USER_FIELDS,
            products: err.products,
          }),
        )

        return
      }

      alert(err.response.errors.join('. '), 'danger')
    }
  }
}

export const ensureField = ({ caption, type, description }, options = {}) => {
  return async (dispatch, getState) => {
    const items = userFieldsSelectors.getItems(getState())
    const item = find(items, { caption, type })
    if (item) {
      return item
    }
    try {
      const data = await dispatch(
        baseListActions.create({ caption, type, description }, null, {
          $error: options.silentErrors ? () => {} : undefined,
        }),
      )
      return data.item
    } catch (err) {
      if (!options.silentErrors) {
        throw err
      }
    }
  }
}

export const saveField = (field) => {
  return async (dispatch) => {
    await dispatch(baseListActions.save(field.id, undefined, field))
    alert(l.translate('Field updated'), 'success')
  }
}

export const archiveField = (field) => {
  return async (dispatch, getState) => {
    await dispatch(baseListActions.archive(field.field_id))
    const searchInputValue = getState()?.userFields?.searchValue?.query
    if (searchInputValue) {
      dispatch(fetchUserFieldsWithParams(true, { query: searchInputValue }))
    }
    alert(l.translate('Field archived'), 'success')
  }
}

export const restoreField = (id) => {
  return (dispatch, getState) => {
    if (!canCreateField(getState())) {
      return
    }
    dispatch(baseListActions.restore({ id }))
    alert(l.translate('Field restored'), 'success')
  }
}

export const deleteField = (id) => {
  return async (dispatch) => {
    try {
      await dispatch(baseListActions.delete(undefined, { field_id: id }, { id }))
      alert(l.translate('Field deleted'), 'success')
    } catch {
      alert(l.translate('Failed to delete field'), 'danger')
    }
  }
}

export const archiveFieldNotification = (id) => ({
  type: atypes.USERFIELDS_ARCHIVE_FIELD_NOTIFICATION,
  id,
})

export const restoreFieldNotification = (id) => ({
  type: atypes.USERFIELDS_RESTORE_FIELD_NOTIFICATION,
  id,
})

export const openCreateFieldModal = (initialData) => {
  return (dispatch, getState) => {
    if (!canCreateField(getState())) {
      return Promise.resolve(null)
    }
    dispatch({ type: atypes.USERFIELDS_OPEN_CREATE_MODAL, initialData })
    return rechargeNewFieldPromise()
  }
}
export const closeCreateFieldModal = () => ({ type: atypes.USERFIELDS_CLOSE_CREATE_MODAL })

export const createFieldModalChange = (data) => {
  return {
    type: atypes.USERFIELDS_CREATE_MODAL_CHANGE,
    data,
  }
}

function canCreateField(state) {
  const { app, userFields } = state
  const max = get(app, 'freeVersionLimit.max_customFields_num', 3)

  if (
    getCurrentAccount(state).isPro ||
    (userFields.items || []).filter((f) => f.status === 'active').length < max
  ) {
    return true
  } else {
    const message = l.translate(
      'You have reached the maximum number of active custom user fields on the current plan ({max}/{max}). Upgrade for unlimited access.',
      { max },
    )
    const title = l.translate('Create unlimited number of user fields with Manychat Pro')
    const source = UpgradeSource.USER_FIELDS
    billing.proAlert({
      message,
      title,
      source,
      products: [ProductType.INBOX, ProductType.AUTOMATION],
    })
  }
  return false
}

export const setUserFieldValue = (data) => {
  return async (dispatch, getState) => {
    analyticsService.sendEvent('LIVE_CHAT.CHANGE_CUF')
    const field = getState().userFields.items.find((f) => f.id === data.field_id)
    try {
      await dispatch(baseListActions.setSubscriberField(undefined, data, { ...data, field }))
    } catch (error) {
      if (isRequestError(error) && error.reason === 'business-logic') return
      errorTrackingService.trackError(error, { fingerprint: 'set-user-field' })
    }
  }
}

export const setSystemFieldValue = (data) => {
  return async (dispatch, getState, { api }) => {
    try {
      const { field_id, user_id, value } = data
      dispatch({ type: atypes.USERFIELDS_SETSYSTEMFIELD_REQUEST })

      const result = await api.post(API.userFields.endpoints.setSystemField, {
        body: { field: field_id, user_id, value },
      })

      if (result.state) {
        dispatch({
          type: atypes.USERFIELDS_SETSYSTEMFIELD_RESPONSE,
          user_id,
          field: result.field,
          value: result.value,
        })
        dispatch(fetchSubscriber(user_id))
      }
    } catch (err) {
      dispatch({ type: atypes.USERFIELDS_SETSYSTEMFIELD_ERROR })
      if (err.response?.errors?.length) {
        const concatenatedErrors = err.response.errors.join('. ')
        alert(concatenatedErrors, 'danger')
      } else {
        alert(err.response?.error || err, 'danger')
      }
    }
  }
}

export const getEntitiesList = (field_id) => {
  return async (dispatch) => {
    // eslint-disable-next-line
    try {
      const pageId = getPageId()
      const response = await dispatch({
        type: atypes.USERFIELDS_GET_ENTITIES_LIST,
        $endpoint: ['userFields.getEntitiesList', { path: pageId, field_id }],
      })

      return formatEntitiesListResponse(response, pageId)
    } catch (e) {
      throw e
    }
  }
}

export const bulkFieldsArchive = (ids) => {
  return async (dispatch) => {
    try {
      await dispatch(baseListActions.archiveBulk(undefined, { ids }))
      alert(l.translate('Fields archived'), 'success')
    } catch {
      alert(l.translate('Failed to archive fields'), 'danger')
    }
  }
}

export const bulkFieldsDelete = (ids) => {
  return async (dispatch) => {
    try {
      await dispatch(baseListActions.deleteBulk(undefined, { ids }))
      alert(l.translate('Fields deleted'), 'success')
    } catch {
      alert(l.translate('Failed to delete fields'), 'danger')
    }
  }
}

export const bulkFieldsRestore = (ids) => {
  return async (dispatch) => {
    try {
      await dispatch(baseListActions.restoreBulk(undefined, { ids }))
      alert(l.translate('Fields restored'), 'success')
    } catch {
      alert(l.translate('Failed to restore fields'), 'danger')
    }
  }
}

export const bulkFieldsArchiveNotificationHandler = (models) => ({
  type: atypes.USER_FIELDS_BULK_ARCHIVE_FIELDS_NOTIFICATION,
  data: { models },
})

export const bulkFieldsRestoreNotificationHandler = (models) => ({
  type: atypes.USER_FIELDS_BULK_RESTORE_FIELDS_NOTIFICATION,
  data: { models },
})

export const bulkFieldsDeleteNotificationHandler = (models) => ({
  type: atypes.USER_FIELDS_BULK_DELETE_FIELDS_NOTIFICATION,
  data: { models },
})

export const setUserFieldSearchValue = (query) => ({
  type: atypes.USER_FIELDS_SEARCH_VALUE_CHANGE,
  query,
})

anotherTabNotificationsListener.on('cuf_archived', (data, dispatch) => {
  dispatch(archiveFieldNotification(data.model.field_id))
})

anotherTabNotificationsListener.on('cuf_restored', (data, dispatch) => {
  dispatch(restoreFieldNotification(data.model.field_id))
})

anotherTabNotificationsListener.on('cuf_created', (data, dispatch, getState) => {
  socketsActionForUserFields(dispatch, getState)
})

anotherTabNotificationsListener.on('cufs_moved', (data, dispatch, getState) => {
  socketsActionForUserFields(dispatch, getState)
})

anotherTabNotificationsListener.on('cufs_moved_bulk', (data, dispatch, getState) => {
  socketsActionForUserFields(dispatch, getState)
})

anotherTabNotificationsListener.on('cuf_updated', (data, dispatch) => {
  dispatch(fieldUpdatedNotification(data.model))
})

anotherTabNotificationsListener.on('cuf_deleted', (data, dispatch) => {
  dispatch(fieldDeletedNotification(data.model.field_id))
})

anotherTabNotificationsListener.on('cufs_archived', (data, dispatch) => {
  dispatch(bulkFieldsArchiveNotificationHandler(data.models))
})

anotherTabNotificationsListener.on('cufs_restored', (data, dispatch) => {
  dispatch(bulkFieldsRestoreNotificationHandler(data.models))
})

anotherTabNotificationsListener.on('cufs_deleted', (data, dispatch) => {
  dispatch(bulkFieldsDeleteNotificationHandler(data.models))
})

const socketsActionForUserFields = (dispatch) => {
  const { path } = getQuery()
  if (!path) {
    dispatch(fetchUserFieldsWithParams(true, { path: '/' }))
  } else {
    dispatch(fetchUserFieldsWithParams(false, { path }))
  }
}

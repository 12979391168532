import { getThreadsCounterForTab } from 'apps/chat/selectors/countersSelectors'
import * as appAtypes from 'common/core/constants/appReduxActionTypes'
import { navigatePollyfill } from 'utils/router/tools'

export const setTitle = (title) => {
  return (dispatch, getState) => {
    const state = getState()
    const threadsCounter = getThreadsCounterForTab(state)
    const tabTitle = threadsCounter ? `(${threadsCounter}) ${title}` : title

    if (getState().app.title !== title) {
      dispatch({ type: appAtypes.APP_SET_TITLE, title })
    }

    document.title = tabTitle
  }
}

export const refreshTitle = () => {
  return (dispatch, getState) => {
    const title = getState().app.title
    dispatch(setTitle(title))
  }
}

export const setPageTab = (tab) => {
  return (dispatch) => {
    const { pathname, search } = window.location
    const url = tab ? `${pathname}${search}#${tab}` : pathname
    dispatch({ type: appAtypes.APP_SET_ACTIVE_TAB, tab })
    return navigatePollyfill(url)
  }
}

import React from 'react'
import { zodResolver } from '@hookform/resolvers/zod'
import { useForm } from 'react-hook-form'
import { z } from 'zod'
import { BtnV2, l, Modal, TextInputV2 } from '@manychat/manyui'

import { useCreateFolderWithContentModal } from 'apps/cms/components/Flows/components/AutomationModals/modals/CreateFolderWithContentModal/hooks/useCreateFolderWithContentModal'
import { FsValidationSchema } from 'apps/cms/lib/validation'

import { FormValues } from './lib/types'

const validationSchema = z.object({
  name: FsValidationSchema.folderName,
})

export const CreateFolderWithContentModal = () => {
  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useForm<FormValues>({
    defaultValues: {
      name: '',
    },
    resolver: zodResolver(validationSchema),
  })

  const { closeModal, createFolderWithContent, flowIds } = useCreateFolderWithContentModal()

  const { ref, ...field } = register('name')

  return (
    <Modal
      open
      onRequestClose={closeModal}
      title={<div className="p-y-xs">{l.translate('Create Folder')}</div>}
      width={460}
      buttons={[
        <BtnV2 onClick={closeModal} className="m-y-xs">
          {l.translate('Cancel')}
        </BtnV2>,
        <BtnV2
          loading={isSubmitting}
          className="m-y-xs"
          variant="danger"
          type="submit"
          form="cms-create-folder-with-content"
        >
          {l.translate('Create')}
        </BtnV2>,
      ]}
    >
      <div className="p-x p-y-md">
        <form onSubmit={handleSubmit(createFolderWithContent)} id="cms-create-folder-with-content">
          <TextInputV2
            {...field}
            inputRef={ref}
            label={l.translate('Folder name')}
            autoFocus
            autoComplete="off"
            placeholder={l.translate('Enter folder name')}
            helpText={l.translate(
              'Folder will be created with {automationsCount, plural, one {# automation} other {# automations}} inside it.',
              {
                automationsCount: flowIds.length,
              },
            )}
            errorText={errors.name?.message}
            invalid={Boolean(errors.name?.message)}
          />
        </form>
      </div>
    </Modal>
  )
}

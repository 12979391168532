import { isProError } from 'shared/api/common/errors/proError'
import { BusinessError } from 'shared/api/lib/errors/business'

interface UrlInvalidError extends BusinessError {
  field: 'url'
}

const isUrlInvalidError = (error: BusinessError): error is UrlInvalidError => error.field === 'url'

export const settingsApiGuard = {
  sms: {
    isProError,
  },
  requestAccess: {
    isProError,
  },
  growthTools: {
    isUrlInvalidError,
  },
}

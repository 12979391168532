import { ITagTextColorSliceData } from 'components/MCTextEditor/formatting/interfaces/tagTextColorSliceData'
import { SliceType } from 'components/MCTextEditor/slice/types'
import { ISlice } from 'components/RichTextEditor/processor/constants'
import { SliceComponent } from 'components/RichTextEditor/types'

import TagBold from './components/TagBold'
import TagItalic from './components/TagItalic'
import TagTextColor from './components/TagTextColor'
import TagUnderline from './components/TagUnderline'

export interface ITagConfig {
  tagName: string
  sliceComponent: SliceComponent
  sliceType: SliceType
  exportSlice: (text: string, slice: ISlice) => string
}

export const tagBoldConfig: ITagConfig = {
  tagName: 'mc-bold',
  sliceComponent: TagBold,
  sliceType: SliceType.BOLD,
  exportSlice: (text) => `<mc-bold>${text}</mc-bold>`,
}

export const tagItalicConfig: ITagConfig = {
  tagName: 'mc-italic',
  sliceComponent: TagItalic,
  sliceType: SliceType.ITALIC,
  exportSlice: (text) => `<mc-italic>${text}</mc-italic>`,
}

export const tagUnderlineConfig: ITagConfig = {
  tagName: 'mc-underline',
  sliceComponent: TagUnderline,
  sliceType: SliceType.UNDERLINE,
  exportSlice: (text) => `<mc-underline>${text}</mc-underline>`,
}

export const tagTextColorConfig: ITagConfig = {
  tagName: 'mc-color',
  sliceComponent: TagTextColor,
  sliceType: SliceType.TEXT_COLOR,
  exportSlice: (text, slice) => {
    const data = slice.data as ITagTextColorSliceData
    return `<mc-color color="${data.color}">${text}</mc-color>`
  },
}

export const tagConfigs = [tagBoldConfig, tagItalicConfig, tagUnderlineConfig, tagTextColorConfig]

export const getTagConfigBySliceType = (
  type: SliceType | string | undefined | null,
): ITagConfig | null => {
  if (!type) {
    return null
  }

  return tagConfigs.find((c) => c.sliceType === type) || null
}

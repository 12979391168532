import React from 'react'
import { l } from '@manychat/manyui'

export const WACoexistenceContactImportPending = () => {
  return (
    <span>
      {l.translate(
        'Contacts are importing from WhatsApp Business App. It may take some time. You will be notified when it is complete.',
      )}
    </span>
  )
}

import { z } from 'zod'

import { channelData } from 'shared/api/requests/whatsApp/schemas'

const ResponseWhatsAppSettings = z.object({
  whatsapp_channel: channelData,
})

const ResponseWhatsAppBusinessProfile = z.object({
  data: z
    .object({
      whatsapp_channel: channelData,
    })
    .optional(),
})

export const WhatsAppSettingsApiSchemas = {
  setWhatsAppProfilePhoto: {
    query: z.undefined(),
    path: z.undefined(),
    response: ResponseWhatsAppSettings,
    request: z.object({ photo: z.custom<File>() }),
  },
  setWhatsAppBusinessProfile: {
    query: z.undefined(),
    path: z.undefined(),
    response: ResponseWhatsAppBusinessProfile,
    request: z.object({
      field: z.string(),
      value: z.union([z.string(), z.array(z.string())]).nullable(),
    }),
  },
}

export type WhatsAppBusinessProfileResponse = z.infer<typeof ResponseWhatsAppBusinessProfile>

import { l } from '@manychat/manyui'

export const GrowthToolsTabType = {
  MAIN: 'main',
  SUBMITTED: 'submitted',
  ACTIONS: 'actions',
  CHANNELS: 'channels',
  OPEN_STATE: 'appearance',
  SETUP: 'setup',
  COMMENT_SETTINGS: 'feed_comment_settings',
  COMMENT_WELCOME: 'feed_comment_welcome',
  MESSENGER_CODE_SETTINGS: 'messenger_code_settings',
}

export const GrowthToolsTabOptions = [
  { value: GrowthToolsTabType.MAIN, label: l.makeTranslate('Initial State') },
  { value: GrowthToolsTabType.OPEN_STATE, label: l.makeTranslate('Open State') },
  { value: GrowthToolsTabType.SUBMITTED, label: l.makeTranslate('Submitted State') },
  { value: GrowthToolsTabType.COMMENT_SETTINGS, label: l.makeTranslate('Settings') },
  { value: GrowthToolsTabType.COMMENT_WELCOME, label: l.makeTranslate('Auto-Response') },
  { value: GrowthToolsTabType.CHANNELS, label: l.makeTranslate('Channels') },
  {
    value: GrowthToolsTabType.ACTIONS,
    label: l.makeTranslate('Opt-In Actions'),
    ['data-test-id']: 'gt-tab-actions',
  },
  {
    value: GrowthToolsTabType.SETUP,
    label: l.makeTranslate('Setup'),
    ['data-test-id']: 'gt-tab-setup',
  },
  { value: GrowthToolsTabType.MESSENGER_CODE_SETTINGS, label: l.makeTranslate('QR Code') },
]

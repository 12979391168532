import { combineReducers } from 'redux'

import { accountReducer as account } from 'apps/account/reducers'
import newAccount from 'apps/addNewAccount/reducers'
import { adsReducer as ads } from 'apps/ads/reducers'
import { agencyReducer as agency } from 'apps/agency/reducers'
import { AiAgentGeneratorReducer } from 'apps/aiAgentGenerator/store'
import { AiAgentPlaygroundReducer } from 'apps/aiAgentPlayground/store'
import aiAssistant from 'apps/aiAssistant/redux/aiAssistantSlice'
import { NewAiAssistantReducer } from 'apps/aiAssistantNew/store'
import { AiBusinessContextReducer } from 'apps/aiBusinessContext/store'
import { AiCommunicationsReducer } from 'apps/aiCommunications/store'
import aiIntents from 'apps/aiIntents/redux/aiIntentsSlice'
import { AiIntentsWizardReducer } from 'apps/aiIntentsWizard/store'
import { AiStepStatsReducer } from 'apps/aiStepStats/store'
import { liveChatStatisticsSlice } from 'apps/analytics/redux/liveChatStatisticsSlice'
import applications from 'apps/applications/reducers'
import { chatReducer as chat } from 'apps/chat/reducers'
import { contentManagementSystemReducer } from 'apps/cms/store'
import { collectOptInsReducer as collectOptIns } from 'apps/collectOptIns/reducers'
import { metricsReducer as metricsDashboard } from 'apps/dashboard/components/Metrics/reducers'
import { dashboardReducer as dashboard } from 'apps/dashboard/reducers'
import { IgUsersValueReducer } from 'apps/dashboard/reducers/igUsersValue'
import { defaultReplyReducer as defaultReply } from 'apps/defaultReply/reducers'
import email from 'apps/email/reducers/email'
import { externalTriggersReducer as externalTriggers } from 'apps/externalTriggers/reducers'
import { FlowPickerReducer } from 'apps/flowPicker/store'
import { flowPlayerReducer as flowPlayer } from 'apps/flowPlayer/reducers/flowPlayer'
import {
  widgetsReducer as widgets,
  widgetCheckerReducer as widgetChecker,
} from 'apps/growthTools/reducers'
import { iceBreakersReducer as iceBreakers } from 'apps/iceBreakers/reducers/iceBreakersReducer'
import { instagramReducer as instagram } from 'apps/instagram/reducers/instagramReducer'
import onboardingReducer from 'apps/instagramOnboarding/reducers/onboardingReducer'
import { keywordsReducer as keywords } from 'apps/keywords/keywordsSlice'
import { menuReducer as menu } from 'apps/menu/reducers/menuReducer'
import broadcastModals from 'apps/posting/pages/BroadcastBuilder/components/SimplifiedBroadCast/slices/modalsSlice'
import { postsReducer as posts } from 'apps/posting/reducers'
import broadcastInstagramWizard from 'apps/posting/slices/BroadcastInstagramWizardSlice'
import broadcastMessengerWizard from 'apps/posting/slices/BroadcastMessengerWizardSlice'
import { profileReducer as profile } from 'apps/profile/reducers'
import { questionnaireReducer as questionnaire } from 'apps/questionnaire/reducers'
import newQuestionnaire from 'apps/questionnaire/reducers/newQuestionnaireSlice'
import { FlowForSimulatorReducer } from 'apps/quickCampaigns/selectTemplateModal/store/flowForSimulatorSlice'
import selectTemplateModal from 'apps/quickCampaigns/selectTemplateModal/store/selectTemplateModalSlice'
import { QuickCampaignsReducer } from 'apps/quickCampaigns/store/quickCampaignsSlice'
import sms from 'apps/sms/reducers/sms'
import { sponsoredMessagesReducer as sm } from 'apps/sponsoredMessages/reducers'
import { storyMentionReplyReducer as storyMention } from 'apps/storyMentionReply/reducers'
import { supportReducer as support } from 'apps/support/reducers'
import { systemKeywordsReducer as systemKeywords } from 'apps/systemKeywords/systemKeywordsReducer'
import { templatesReducer as templates } from 'apps/templates/reducers'
import { templateStoreReducer as templateStore } from 'apps/templateStore/reducers'
import { reducer as triggerPicker } from 'apps/triggerPicker/redux/triggerPicker'
import { triggerRulesReducer as triggerRules } from 'apps/triggerRules/reducers'
import { welcomeMessageReducer as welcomeMessage } from 'apps/welcomeMessage/reducers/welcomeMessageReducer'
import { whatsappReducer as whatsApp } from 'apps/whatsApp/redux/whatsAppReducer'
import {
  integrationsReducer as integrations,
  integromatWebhooksReducer as integromatWebhooks,
  devToolsReducer as devTools,
} from 'common/actions/reducers'
import { answersReducer as answers, formsReducer as forms } from 'common/answers/reducers'
import {
  audienceReducer as audience,
  subscribersReducer as subscribers,
} from 'common/audience/reducers'
import { billingReducer as billing } from 'common/billing/reducers'
import { reducer as trial } from 'common/billing/redux/trial'
import { buildersReducer as builders, managerReducer as manager } from 'common/builder/reducers'
import { cmsReducer as cms } from 'common/cms/reducers'
import bulkActions from 'common/core/bulkActions'
import { appReducer as app, uiReducer as ui } from 'common/core/reducers'
import { refreshPermissions } from 'common/core/reducers/refreshPermissions'
import { customEventsReducer as customEvents } from 'common/events/reducers'
import { availableFieldsReducer as availableFields } from 'common/fields/linking/accountAvailableFields'
import {
  userFieldsReducer as userFields,
  userFieldFoldersReducer as userFieldFolders,
  globalFieldFoldersReducer as globalFieldFolders,
} from 'common/fields/reducers'
import { GlobalFieldsReducer } from 'common/fields/store/globalFields'
import { filterReducer as filter, segmentsReducer as segments } from 'common/filter/reducers'
import flow from 'common/flow/reducers/flow'
import { groupsReducer as groups } from 'common/groups/reducers/groups'
import { targetPageReducer as targetPage } from 'common/mapping/reducers'
import howToCreateTopicsModal from 'common/oneTimeNotify/components/HowToCreateTopicsModal/slices/HowToCreateTopicsModalSlice'
import instagramTopicsModal from 'common/oneTimeNotify/components/InstagramTopicsModal/slices/InstagramTopicsModal'
import { NotificationReasonReducer } from 'common/oneTimeNotify/store'
import recurringNotifications from 'common/recurringNotifications/slice'
import { sequencesReducer as sequence } from 'common/sequences/reducers'
import {
  settingsReducer as settings,
  settingsAutoAssignmentReducer,
  ordersReducer as orders,
  botSettingsReducer as botSettings,
  devLogsReducer as devLogs,
  eventsReducer as events,
} from 'common/settings/reducers'
import smartSegments from 'common/smartSegments/reducers'
import { tagsFolderReducer as tagsFolders } from 'common/tags/reducers'
import { TagsReducer } from 'common/tags/store'
import {
  userRolesReducer as users,
  invitationReducer as invitation,
  accessRequestsReducer as accessRequests,
} from 'common/userRoles/reducers'
import { zapierReducer as zaps } from 'common/zapier/reducers'

export default combineReducers({
  accessRequests,
  account,
  ads,
  agency,
  aiAgentGenerator: AiAgentGeneratorReducer,
  aiAgentPlayground: AiAgentPlaygroundReducer,
  answers,
  app,
  applications,
  audience,
  availableFields,
  newAiAssistant: NewAiAssistantReducer,
  aiBusinessContext: AiBusinessContextReducer,
  aiCommunications: AiCommunicationsReducer,
  aiIntentsWizard: AiIntentsWizardReducer,
  aiStepStats: AiStepStatsReducer,
  billing,
  botSettings,
  broadcastMessengerWizard,
  broadcastInstagramWizard,
  builders,
  bulkActions,
  chat,
  cms,
  contentManagementSystem: contentManagementSystemReducer,
  customEvents,
  dashboard,
  defaultReply,
  collectOptIns,
  devLogs,
  devTools,
  email,
  events,
  externalTriggers,
  filter,
  howToCreateTopicsModal,
  instagramTopicsModal,
  flow,
  quickCampaigns: QuickCampaignsReducer,
  flowForSimulator: FlowForSimulatorReducer,
  flowPicker: FlowPickerReducer,
  flowPlayer,
  forms,
  globalFields: GlobalFieldsReducer,
  groups,
  iceBreakers,
  instagram,
  integrations,
  integromatWebhooks,
  invitation,
  keywords,
  manager,
  mcOnboarding: onboardingReducer,
  menu,
  metricsDashboard,
  newAccount,
  notificationReason: NotificationReasonReducer,
  orders,
  posts,
  profile,
  questionnaire,
  recurringNotifications,
  segments,
  selectTemplateModal,
  sequence,
  settings,
  settingsAutoAssignment: settingsAutoAssignmentReducer,
  sm,
  smartSegments,
  sms,
  storyMention,
  subscribers,
  support,
  systemKeywords,
  tags: TagsReducer,
  tagsFolders,
  targetPage,
  templateStore,
  templates,
  trial,
  triggerPicker,
  triggerRules,
  ui,
  userFieldFolders,
  globalFieldFolders,
  userFields,
  users,
  welcomeMessage,
  whatsApp,
  widgetChecker,
  widgets,
  zaps,
  aiAssistant,
  aiIntents,
  newQuestionnaire,
  broadcastModals,
  igUsersValue: IgUsersValueReducer,
  refreshPermissions,
  liveChatStatistics: liveChatStatisticsSlice.reducer,
})

import each from 'lodash/each'
import filter from 'lodash/filter'
import groupBy from 'lodash/groupBy'
import isEmpty from 'lodash/isEmpty'
import keyBy from 'lodash/keyBy'
import mapValues from 'lodash/mapValues'
import valuesLodash from 'lodash/values'

import * as atypes from 'apps/ads/constants/AdsReduxActionTypes'

const initialState = {
  data: {
    country: {},
    region: {},
    city: {},
    zip: {},
    place: {},
    custom_location: {},
    geo_market: {},
    electoral_district: {},
    country_group: {},
  },
  search: {},
  locales: {},
  detailedTargetingData: {},
  detailedTargetingSearch: {},
}

export default function adTargetingReducer(state = initialState, action) {
  switch (action.type) {
    case atypes.ADSET_TARGETING_SEARCH_LOCATIONS_RESPONSE: {
      const items = filter(action.data.data, (item) => Object.keys(state.data).includes(item.type))
      const byType = groupBy(items, 'type')
      state.data = mapValues(state.data, (itemsByType, type) => ({
        ...itemsByType,
        ...keyBy(byType[type] || {}, 'key'),
      }))
      state.search = {
        ...state.search,
        [`${action.query}`]: items.map((item) => [item.key, item.type]),
      }
      return state
    }
    case atypes.ADSET_TARGETING_FETCH_LOCATIONS_META_RESPONSE: {
      const byType = {}
      each(action.data.data[0], (items) => {
        if (isEmpty(items)) {
          return
        }
        const values = valuesLodash(items)
        const type = values[0].type
        byType[type] = values
      })

      state.data = mapValues(state.data, (itemsByType, type) => ({
        ...itemsByType,
        ...keyBy(byType[type] || {}, 'key'),
      }))

      return state
    }
    case atypes.ADSET_TARGETING_FETCH_LOCALES_RESPONSE: {
      const locales = keyBy(action.data.data, 'key')
      return { ...state, locales }
    }
    case atypes.ADSET_TARGETING_SEARCH_DETAILED_TARGETING_RESPONSE: {
      const detailedTargetingData = keyBy(action.data.data, 'id')
      return {
        ...state,
        detailedTargetingData: { ...state.detailedTargetingData, ...detailedTargetingData },
        detailedTargetingSearch: {
          ...state.detailedTargetingSearch,
          [`${action.query}`]: action.data.data.map((item) => item.id),
        },
      }
    }
  }

  return state
}

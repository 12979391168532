const query =
  '(touch-enabled),(-webkit-touch-enabled),(-moz-touch-enabled),(-o-touch-enabled),(-ms-touch-enabled),(heartz)'

function detectTouch() {
  try {
    if (
      'ontouchstart' in window ||
      navigator.maxTouchPoints > 0 ||
      // eslint-disable-next-line no-undef
      (window.DocumentTouch && document instanceof DocumentTouch)
    ) {
      return true
    }
    // include the 'heartz' as a way to have a non matching MQ to help terminate the join
    // https://git.io/vznFH
    return window.matchMedia(query).matches
  } catch (error) {
    return false
  }
}

const isTouch = detectTouch()
window.$isTouch = isTouch
export default isTouch

import get from 'lodash/get'

import * as API from 'constants/API'
import { expandURL } from 'utils/url'

const importItem = (state, item, idKey, importFn) => {
  item = { ...item }
  if (importFn) {
    item = importFn(item, state)
  }
  item.id = item.id || item[idKey]
  return item
}

const createFetchEndpointMiddleware = () => {
  return ({ dispatch, getState }) =>
    (next) =>
    (action) => {
      if (action == null || action.$endpoint == null || action.$endpoint.processed) {
        return next(action)
      }

      // Normalizing action endpoint
      if (typeof action.$endpoint === 'string') {
        action.$endpoint = {
          name: action.$endpoint,
        }
      }
      if (Array.isArray(action.$endpoint)) {
        const [name, urlParams, body] = action.$endpoint
        action.$endpoint = { name, urlParams, body }
      }

      const { $endpoint } = action
      $endpoint.processed = true
      $endpoint.urlParams = $endpoint.urlParams || {}

      const [entity, entityAction] = $endpoint.name.split('.')
      if (!entity || !entityAction) {
        return console.error(
          `[fetchEndpoint middleware] Invalid endpoint: '${action.$endpoint}', must be like 'entity.action'`,
        )
      }

      const entityDesc = API[entity]
      if (entityDesc == null) {
        return console.error(`[fetchEndpoint middleware] Unknown entity: '${entity}'`)
      }

      const endpointDesc = get(entityDesc, `endpoints.${entityAction}`)

      if (endpointDesc) {
        // Applying URL params
        let request = expandURL(endpointDesc, $endpoint.urlParams)

        if ($endpoint.body) {
          let [url, options] = Array.isArray(request) ? request : [request, {}]

          options = {
            ...options,
            method: 'POST',
            body:
              typeof $endpoint.body === 'string' ? $endpoint.body : JSON.stringify($endpoint.body),
            headers: { 'Content-Type': 'application/json' },
          }
          request = [url, options]
        }

        action.$fetch = request

        const { idKey, itemKey, listKey } = entityDesc

        action.$import = (data, state) => {
          if (get(data, itemKey)) {
            data.item = importItem(state, get(data, itemKey), idKey, entityDesc.import)
          }
          if (get(data, listKey)) {
            data.items = get(data, listKey).map((item) =>
              importItem(state, item, idKey, entityDesc.import),
            )
          }
          return data
        }

        return next(action)
      } else {
        console.error(
          `[fetchEndpoint middleware] Unknown entity action: '${entityAction}'. Entity: '${entity}'`,
        )
      }
    }
}

export default createFetchEndpointMiddleware

import React from 'react'
import cx from 'classnames'
import PropTypes from 'prop-types'

import ArrowCol from '../ArrowCol'
import { MappingField } from '../MappingField/MappingField'

import cm from './EntitiesMapping.module.css'

export default class EntitiesMapping extends React.Component {
  static propTypes = {
    currentEntities: PropTypes.array.isRequired,
    caption: PropTypes.string.isRequired,
    picker: PropTypes.any.isRequired,
    onSet: PropTypes.func.isRequired,
    entitiesMap: PropTypes.object.isRequired,
    pickerProps: PropTypes.object,
    className: PropTypes.string,
    entity: PropTypes.object,
    mappingField: PropTypes.func,
  }

  static defaultProps = {
    pickerProps: {},
    className: '',
    entity: { id: 'id', name: 'name' },
  }

  render() {
    const {
      currentEntities,
      caption,
      entitiesMap,
      onSet,
      entity,
      picker: Picker,
      pickerProps,
      className,
      mappingField,
    } = this.props

    return (
      <div className={className}>
        <div className="m-t m-b text-subheading">{caption}</div>

        {currentEntities.map((en, index) => {
          const value = entitiesMap[en[entity.id]]
          return (
            <div
              key={en.id ?? index}
              className="row m-b-lg sm-m-b justify-center sm-justify-start align-center"
            >
              <div className="sm-col col-11">
                {mappingField ? (
                  mappingField({ en, entity })
                ) : (
                  <MappingField
                    label={en[entity.name]}
                    description={en.description}
                    type={en.type}
                  />
                )}
              </div>
              <div className={cx(cm.arrow, 'col-1 sm-d-flex sm-justify-center')}>
                <ArrowCol active={Boolean(value)} />
              </div>
              <div className="sm-col col-12 m-t-xs sm-m-t-0">
                <Picker
                  onChange={onSet.bind(this, en)}
                  value={value}
                  allowedTypes={en.type && [en.type]}
                  enableClear
                  disableCreate
                  forceNew={false}
                  currentEntity={en}
                  {...pickerProps}
                />
              </div>
            </div>
          )
        })}
      </div>
    )
  }
}

import { z } from 'zod'

import { WidgetStatusType } from 'apps/growthTools/models/Widget/constants'
import { lightFlowSchema } from 'shared/api/common/schemas/lightFlow'

export const widgetOptInMessageSchema = {
  sent: z.number(),
  delivered: z.number().nullable(),
  read: z.number().nullable(),
  clicked: z.number().nullable(),
}

export const widgetFullSchema = {
  widget_id: z.number(),
  page_id: z.string(),
  type: z.number(),
  widget_type: z.string(),
  status: z.nativeEnum(WidgetStatusType),
  name: z.string(),
  data: z.object({}),
  ts_created: z.number(),
  ts_updated: z.number(),
  namespace: z.string().nullable(),
  sequence_id: z.number().nullable(),
  flow: lightFlowSchema.nullable(),
  content_holder: z.string(),
  stats: z.object({
    impressions: z.number(),
    optins: z.number(),
    optin_message: z.object(widgetOptInMessageSchema).nullable(),
  }),
  template_installation_id: z.number().nullable(),
  channel: z.string(),
  lesson: z.string().nullable(),
  quick_campaign: z.string().nullable(),
  quick_campaign_id: z.number().nullish(),
  landing_url: z.string().optional(),
  ref_url: z.string().optional(),
  bar_code_url: z.string().optional(),
  messenger_ref_url: z.string().optional(),
  chat_ref: z.string().optional(),
  script_url: z.string().optional(),
  tag: z.object({}).nullish(),
  sequence: z.object({}).nullish(),
  link: z.string().optional(),
  json_data: z.object({}).nullish(),
  json_error: z.string().nullish(),
  widget_error: z.string().nullish(),
}

export const widgetsSchemas = {
  setDraftStatus: {
    response: z.unknown(),
    request: z.object({
      widget_id: z.number(),
      status: z.nativeEnum(WidgetStatusType),
    }),
    query: z.undefined(),
    path: z.undefined(),
  },

  widgetCheck: {
    path: z.undefined(),
    response: z.undefined(),
    query: z.undefined(),
    request: z.object({
      widget_id: z.number(),
      url: z.string(),
      async: z.string(),
    }),
  },

  setFlow: {
    path: z.undefined(),
    query: z.undefined(),
    request: z.object({
      widget_id: z.number(),
      flow_ns: z.string(),
    }),
    response: z.object({
      widget: z.object(widgetFullSchema).optional(),
    }),
  },
}

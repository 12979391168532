import React from 'react'
import { BtnV2, Modal, l } from '@manychat/manyui'

import { useApplyRecommendation } from 'apps/cms/components/Flows/components/AutomationModals/modals/ApplyRecommendationModal/useApplyRecommendation'

import { RecommendationTypes } from './constants'

export const ApplyRecommendationModal = () => {
  const { closeModal, isApplying, applyRecommendationToFlow, recommendationType } =
    useApplyRecommendation()

  let title, description, buttonTitle

  switch (recommendationType) {
    case RecommendationTypes.COMMENTS:
      title = l.translate('Set trigger to All Post')
      description = l.translate(
        'The automation will start every time people will comment your keyword on all posts or reel',
      )
      buttonTitle = l.translate('Confirm')
      break
    case RecommendationTypes.DIRECT_MESSAGE:
      title = l.translate('Add Direct Message Trigger')
      description = l.translate(
        'The automation will also start when people will DM the keyword of this automation',
      )
      buttonTitle = l.translate('Add')
      break
    case RecommendationTypes.STORY_REPLY:
    default:
      title = l.translate('Add Story Reply Trigger')
      description = l.translate(
        'The automation will also start when people will reply to your story with the keyword of this automation',
      )
      buttonTitle = l.translate('Add')
      break
  }

  return (
    <Modal
      width={460}
      open
      disableCloseButton
      title={<div className="p-y-xs">{title}</div>}
      buttons={[
        <BtnV2 disabled={isApplying} className="m-y-xs" onClick={closeModal}>
          {l.translate('Cancel')}
        </BtnV2>,
        <BtnV2
          onClick={applyRecommendationToFlow}
          variant="primary"
          loading={isApplying}
          className="m-y-xs"
        >
          {buttonTitle}
        </BtnV2>,
      ]}
      onRequestClose={closeModal}
    >
      <div className={'p-x-md m-y text-center text-secondary'}>{description}</div>
    </Modal>
  )
}

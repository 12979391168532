import React from 'react'

import { LOCAL_STORAGE_KEY_WAS_LATEST_NOTIFICATION_READ } from 'apps/chat/constants/LiveChatNotifications'
import { getSafeStorageEvent } from 'utils/getSafeStorageEvent'
import localStorage from 'utils/localStorage'

export const useLatestNotificationState = () => {
  const [wasLatestNotificationRead, setWasLatestNotificationRead] = React.useState(true)

  const setLocalStorageValue = React.useCallback((value: boolean) => {
    setWasLatestNotificationRead(value)
    localStorage.setItem(LOCAL_STORAGE_KEY_WAS_LATEST_NOTIFICATION_READ, String(value))
  }, [])

  const handleLocalStorageFieldChanged = React.useCallback(
    (event: StorageEvent | CustomEvent) => {
      const { key, value } = getSafeStorageEvent(event)

      if (key === LOCAL_STORAGE_KEY_WAS_LATEST_NOTIFICATION_READ) {
        setWasLatestNotificationRead(value === 'true')
      }
    },
    [setWasLatestNotificationRead],
  )

  React.useEffect(() => {
    localStorage.setItem(LOCAL_STORAGE_KEY_WAS_LATEST_NOTIFICATION_READ, 'true')
    window.addEventListener('storage', handleLocalStorageFieldChanged)

    return () => {
      window.removeEventListener('storage', handleLocalStorageFieldChanged)
    }
  }, [handleLocalStorageFieldChanged])

  return {
    wasLatestNotificationRead,
    setWasLatestNotificationRead: setLocalStorageValue,
  }
}

import React from 'react'
import cx from 'classnames'
import { BtnV2, l } from '@manychat/manyui'

import If from 'components/If/If'

import cm from './TooltipDefault.module.css'

const TooltipDefault = ({
  text,
  btnNext,
  onNextClick,
  className,
}: {
  text: () => string
  btnNext?: () => string
  onNextClick?: () => void
  className?: string
}) => {
  const handleNext = () => {
    if (onNextClick) {
      onNextClick()
    }
  }

  return (
    <div className={cx('rounded d-flex flex-col p-a m-a-xs text-white', cm.root, className)}>
      <span className={cx('d-block', cm.content)}>{l.getString(text)}</span>
      <If condition={l.getString(btnNext)}>
        <BtnV2 label={l.getString(btnNext)} className="m-t align-self-end" onClick={handleNext} />
      </If>
    </div>
  )
}

export default TooltipDefault

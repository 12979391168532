import { l } from '@manychat/manyui'

import { CreateWaMessageTemplateState } from 'apps/posting/pages/BroadcastBuilder/interfaces/whatsAppMessageTemplate'
import { EmSUEvent } from 'apps/whatsApp/emsu/constants'
import { WhatsAppBroadcastingState } from 'apps/whatsApp/interfaces/whatsAppBroadCast'
import { WhatsAppBroadcastOnboardingState } from 'apps/whatsApp/interfaces/whatsAppBroadCastOnboarding'
import { WhatsAppCartReplyState } from 'apps/whatsApp/interfaces/whatsAppCartReply'
import { WhatsAppCatalogState } from 'apps/whatsApp/interfaces/whatsAppCatalog'
import { WhatsAppChannelStats } from 'apps/whatsApp/interfaces/whatsAppChannel'
import { WhatsAppCoexistenceState } from 'apps/whatsApp/interfaces/whatsAppCoexistence'
import { WhatsAppCurrentMessageTemplateState } from 'apps/whatsApp/interfaces/whatsAppCurrentMessageTemplate'
import { WhatsappMessageTemplatesState } from 'apps/whatsApp/interfaces/whatsAppMessageTemplates'
import { AVAILABLE_CURRENCIES } from 'common/settings/interfaces'
import {
  WaMessageTemplateCategories,
  WaMessageTemplateHeaderType,
  WaMessageTemplatePauseTitle,
} from 'shared/api/requests/whatsApp/schemas'

export enum MessageTemplateCategory {
  ACCOUNT_UPDATE = 'ACCOUNT_UPDATE',
  PAYMENT_UPDATE = 'PAYMENT_UPDATE',
  PERSONAL_FINANCE_UPDATE = 'PERSONAL_FINANCE_UPDATE',
  SHIPPING_UPDATE = 'SHIPPING_UPDATE',
  RESERVATION_UPDATE = 'RESERVATION_UPDATE',
  ISSUE_RESOLUTION = 'ISSUE_RESOLUTION',
  APPOINTMENT_UPDATE = 'APPOINTMENT_UPDATE',
  TRANSPORTATION_UPDATE = 'TRANSPORTATION_UPDATE',
  TICKET_UPDATE = 'TICKET_UPDATE',
  ALERT_UPDATE = 'ALERT_UPDATE',
  AUTO_REPLY = 'AUTO_REPLY',
  // WhatsApp API V16
  AUTHENTICATION = 'AUTHENTICATION',
  MARKETING = 'MARKETING',
  UTILITY = 'UTILITY',
}

export const MessageTemplateCategoryLabels: Record<MessageTemplateCategory, string> = {
  [MessageTemplateCategory.ACCOUNT_UPDATE]: 'Account Update',
  [MessageTemplateCategory.PAYMENT_UPDATE]: 'Payment Update',
  [MessageTemplateCategory.PERSONAL_FINANCE_UPDATE]: 'Personal Finance Update',
  [MessageTemplateCategory.SHIPPING_UPDATE]: 'Shipping Update',
  [MessageTemplateCategory.RESERVATION_UPDATE]: 'Reservation Update',
  [MessageTemplateCategory.ISSUE_RESOLUTION]: 'Issue Resolution',
  [MessageTemplateCategory.APPOINTMENT_UPDATE]: 'Appointment Update',
  [MessageTemplateCategory.TRANSPORTATION_UPDATE]: 'Transportation Update',
  [MessageTemplateCategory.TICKET_UPDATE]: 'Ticket Update',
  [MessageTemplateCategory.ALERT_UPDATE]: 'Alert Update',
  [MessageTemplateCategory.AUTO_REPLY]: 'Auto-Reply',
  [MessageTemplateCategory.AUTHENTICATION]: 'Authentication',
  [MessageTemplateCategory.MARKETING]: 'Marketing',
  [MessageTemplateCategory.UTILITY]: 'Utility',
}

export const WaMessageTemplateHeaderTypeLabels: Record<
  WaMessageTemplateHeaderType,
  ReturnType<typeof l.makeTranslate>
> = {
  [WaMessageTemplateHeaderType.TEXT]: l.makeTranslate('Text'),
  [WaMessageTemplateHeaderType.VIDEO]: l.makeTranslate('Video'),
  [WaMessageTemplateHeaderType.IMAGE]: l.makeTranslate('Image'),
  [WaMessageTemplateHeaderType.DOCUMENT]: l.makeTranslate('File'),
}

export enum MessageTemplateRejectionReason {
  ABUSIVE_CONTENT = 'ABUSIVE_CONTENT',
  INCORRECT_CATEGORY = 'INCORRECT_CATEGORY',
  INVALID_FORMAT = 'INVALID_FORMAT',
  NONE = 'NONE',
  PROMOTIONAL = 'PROMOTIONAL',
  SCAM = 'SCAM',
  TAG_CONTENT_MISMATCH = 'TAG_CONTENT_MISMATCH',
}

export const GUIDELINES_URL =
  'https://developers.facebook.com/docs/whatsapp/message-templates/guidelines#common-rejection-reasons'
export const MESSAGE_TEMPLATE_PAUSING =
  'https://developers.facebook.com/docs/whatsapp/message-templates/guidelines/#template-pausing'

export const MessageTemplateRejectionReasonErrorText = {
  [MessageTemplateRejectionReason.ABUSIVE_CONTENT]: l.makeTranslate(
    'The message template contains potentially abusive or threatening content.',
  ),
  [MessageTemplateRejectionReason.INCORRECT_CATEGORY]: l.makeTranslate(
    'The message template contains content that does not match the category you selected.',
  ),
  [MessageTemplateRejectionReason.INVALID_FORMAT]: l.makeTranslate(
    'The message template contains content of an invalid format.',
  ),
  [MessageTemplateRejectionReason.PROMOTIONAL]: l.makeTranslate(
    'The message template contains content that violates WhatsApp’s Commerce Policy.',
  ),
  [MessageTemplateRejectionReason.SCAM]: l.makeTranslate(
    'The message template contains content that violates the WhatsApp’s Business Policy.',
  ),
  [MessageTemplateRejectionReason.TAG_CONTENT_MISMATCH]: l.makeTranslate(
    'The message template contains content of an invalid format.',
  ),
}

export const MESSAGE_TEMPLATE_PAUSED_STATUS_WARNING_TITLE = l.makeTranslate(
  'Template paused due to negative feedback or low reads',
)

export const messageTemplatePausedStatusWithPauseTitleWarningTitle = (
  pauseTitle: WaMessageTemplatePauseTitle,
) =>
  l.makeTranslate('Template paused for {hours} hours due to negative feedback or low reads', {
    hours: pauseTitle === WaMessageTemplatePauseTitle.PAUSE_FOR_3_HOURS ? 3 : 6,
  })

export const MESSAGE_TEMPLATE_DISABLED_STATUS_WARNING_TITLE = l.makeTranslate(
  'Template disabled due to consistently low quality',
)

export const MESSAGE_TEMPLATE_PAUSED_STATUS_WARNING_TEXT = l.makeTranslate(
  'It will automatically unpause. To improve quality and feedback, update the content or adjust targeting, then resubmit for review.',
)

export const messageTemplatePausedStatusWithPauseTitleWarningText = (unpauseTime: number) =>
  l.makeTranslate(
    'It will automatically unpause after {time}. To improve quality and feedback, update the content or adjust targeting, then resubmit for review.',
    {
      time: l.dateTime(unpauseTime * 1000),
    },
  )

export const MESSAGE_TEMPLATE_DISABLED_STATUS_WARNING_TEXT = l.makeTranslate(
  "You cannot send it again. It's possible to appeal the status with WhatsApp Manager.",
)

export enum MessageTemplateStatus {
  PENDING = 'PENDING',
  APPROVED = 'APPROVED',
  REJECTED = 'REJECTED',
}

interface MessageTemplateQuickReplyButton {
  type: 'QUICK_REPLY'
  text: string
}

export interface MessageTemplateActionButton {
  type: 'URL'
  text: string
  url: string
  variable?: string
}

export type MessageTemplateButton = MessageTemplateQuickReplyButton | MessageTemplateActionButton
export type ButtonType = 'QUICK_REPLY' | 'URL' | null

export enum MessageTemplateAttachment {
  TEXT = 'TEXT',
  IMAGE = 'IMAGE',
  VIDEO = 'VIDEO',
  DOCUMENT = 'DOCUMENT',
}

interface MessageTemplateHeaderText {
  type: MessageTemplateAttachment.TEXT
  text?: string
}

export interface MessageTemplateHeaderAttachment {
  type: Exclude<MessageTemplateAttachment, MessageTemplateAttachment.TEXT>
  content?: File
  name?: string
  caid?: number
  url?: string
  format?: string
}

export type MessageTemplateHeader = MessageTemplateHeaderText | MessageTemplateHeaderAttachment

export interface MessageTemplateLegacyModel {
  body: string
  buttonType: ButtonType
  buttons: MessageTemplateButton[]
  category?: MessageTemplateCategory
  draft?: boolean
  example?: Record<string, string>
  footer?: string
  header?: MessageTemplateHeader
  humanName: string
  languageCode: string
  name?: string
  rejectedReason: MessageTemplateRejectionReason | null
  status?: MessageTemplateStatus
}

export interface MessageTemplate extends MessageTemplateLegacyModel {
  name: string
  isDefault: boolean
}

export interface CurrentMessageTemplateState {
  item: MessageTemplateLegacyModel | null
  fetching: boolean
  showValidateModal: boolean
  messageTemplateCategories: WaMessageTemplateCategories | null
}

export interface EmSUState {
  event?: EmSUEvent
  phoneNumber?: string
  phoneNumberWithoutCountryCode?: string
  country: string
  isBuyNumberInProgress?: boolean
  isUpdatingStateNewAccount?: boolean
  isBlockedButtonsChoiceNumber?: boolean
  isCodeWaiting?: boolean
}

export interface DisconnectChannelState {
  isConfirmationReceived: boolean
  conditionsDisconnectingAccount: boolean[]
}

export interface WhatsAppState {
  templates: {
    current: CurrentMessageTemplateState
  }
  emsu: EmSUState
  disconnectChannel: DisconnectChannelState
  messageTemplates: WhatsappMessageTemplatesState
  currentMessageTemplate: WhatsAppCurrentMessageTemplateState
  createMessageTemplateOnBroadcast: CreateWaMessageTemplateState
  broadcastOnboarding: WhatsAppBroadcastOnboardingState
  stats: WhatsAppChannelStats
  broadcasting: WhatsAppBroadcastingState
  catalog: WhatsAppCatalogState
  cartReply: WhatsAppCartReplyState
  coexistence: WhatsAppCoexistenceState
}

export type WhatsAppChannelState = 'none' | 'sign_up_pending' | 'verification_pending' | 'active'

export type ReconnectPhoneStatus = 'init' | 'connected' | 'verifying'

export interface TemplateLanguage {
  label: string
  value: string
}

export interface Country {
  value: string
  code: string
  name: string
  price: number
  // currency should be in the getPhoneCountries response, but since the BRL payments solution is still in progress it may not be present
  currency?: AVAILABLE_CURRENCIES
}

export interface AvailableStates {
  available: boolean
  freshdeskEnabled: boolean
}

export interface CreateAccountStates {
  accountId: AccountId
  error?: string
}

export interface WhatsAppEMSUAPI {
  buyPhoneNumber(countryCode: string): Promise<string>

  connectWhatsAppChannelWithPhone(businessId: string, phoneId: string): Promise<void>

  connectWhatsAppChannelWithToken(token: string): Promise<void>

  emsuStarted(): Promise<void>

  getIsEmSUAvailable(): Promise<AvailableStates>

  getPhoneCountries(): Promise<Country[]>

  createAccount(): Promise<CreateAccountStates>
}

import { ResponseData } from 'initAppAPI'

import { appInitialState } from 'common/core/reducers'

export const parseInitApp = (data: Partial<ResponseData>): Partial<typeof appInitialState> => {
  const abilitiesKey = 'app.currentAccount.abilities'
  const walletKey = 'app.currentAccountWallet'

  const app: Partial<typeof appInitialState> = Object.entries(data)
    .filter(([key]) => key.startsWith('app.') && ![walletKey, abilitiesKey].includes(key))
    .reduce((result, [key, value]) => {
      result[key.replace('app.', '')] = value
      return result
    }, {} as Record<string, unknown>)

  if (app.currentAccount && Array.isArray(data[abilitiesKey])) {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-expect-error
    app.currentAccount.abilities = data[abilitiesKey]
  }

  app.accountsCount = data['data.accounts_count'] ?? 0

  return app
}

import * as actionTypes from 'apps/storyMentionReply/actionTypes'
import { getInitialStoryMentionReplyState } from 'apps/storyMentionReply/constants'
import { IActionWithPayload } from 'common/core/interfaces/actions'

const initialState = getInitialStoryMentionReplyState()

export function storyMentionReplyReducer(state = initialState, action: IActionWithPayload) {
  switch (action.type) {
    case `${actionTypes.STORY_MENTION_REPLY_FETCH}_REQUEST`:
      return { ...state, fetching: true }
    case actionTypes.STORY_MENTION_AUTO_LIKE:
    case actionTypes.STORY_MENTION_DELAY:
    case `${actionTypes.STORY_MENTION_REPLY_FETCH}_RESPONSE`:
    case `${actionTypes.STORY_MENTION_REPLY_SET_FLOW}_RESPONSE`:
    case `${actionTypes.STORY_MENTION_REPLY_REMOVE_FLOW}_RESPONSE`:
    case `${actionTypes.STORY_MENTION_REPLY_UPDATE_ENABLED}_RESPONSE`: {
      const item = action.data
      return { ...state, ...item, isFetched: true, fetching: false }
    }
  }
  return state
}

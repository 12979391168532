import AdsCustomAudienceNotification from 'common/core/components/NotifyBar/FaceBookNotifications/FbAdsCustomAudienceNotification/AdsCustomAudienceNotification'
import FbCapiReconnectFailNotification from 'common/core/components/NotifyBar/FaceBookNotifications/FbCapiReconnectFailNotification/FbCapiReconnectFailNotification'
import FBChannelTurnedOffNotification from 'common/core/components/NotifyBar/FaceBookNotifications/FBChannelTurnedOffNotification/FBChannelTurnedOffNotification'
import FBInsufficientRightsToReloadAccountNotification from 'common/core/components/NotifyBar/FaceBookNotifications/FBInsufficientRightsToReloadAccountNotification/FBInsufficientRightsToReloadAccountNotification'
import FBPageConnectionWasLostNotification from 'common/core/components/NotifyBar/FaceBookNotifications/FBPageConnectionWasLostNotification/FBPageConnectionWasLostNotification'
import FBPageConnectionWasSuccessfullyReloadedNotification from 'common/core/components/NotifyBar/FaceBookNotifications/FBPageConnectionWasSuccessfullyReloadedNotification/FBPageConnectionWasSuccessfullyReloadedNotification'
import { NotificationBarType } from 'common/core/constants/NotificationBarType'

import {
  NotificationRemoveType,
  FacebookNotification,
  FacebookReadyNotification,
  FacebookNotificationType,
} from '../NotifyInterfaces'

const getRender = (
  message: FacebookNotification['message'],
): FacebookReadyNotification['render'] => {
  switch (message) {
    case FacebookNotificationType.TURNED_OFF:
      return FBChannelTurnedOffNotification
    case FacebookNotificationType.INSUFFICIENT_RIGHTS_TO_RELOAD_ACCOUNT:
      return FBInsufficientRightsToReloadAccountNotification
    case FacebookNotificationType.PAGE_CONNECTION_LOST:
      return FBPageConnectionWasLostNotification
    case FacebookNotificationType.PAGE_CONNECTION_WAS_SUCCESSFULLY_RELOADED:
      return FBPageConnectionWasSuccessfullyReloadedNotification
    case FacebookNotificationType.ADS_CUSTOM_AUDIENCE_DEPRECATED:
      return AdsCustomAudienceNotification
    case FacebookNotificationType.CAPI_RECONNECT_FAIL:
      return FbCapiReconnectFailNotification
  }
}

const getMessageType = (message: FacebookNotification['message']): NotificationBarType => {
  switch (message) {
    case FacebookNotificationType.PAGE_CONNECTION_WAS_SUCCESSFULLY_RELOADED:
    case FacebookNotificationType.TURNED_OFF:
      return NotificationBarType.SUCCESS
    case FacebookNotificationType.PAGE_CONNECTION_LOST:
    case FacebookNotificationType.INSUFFICIENT_RIGHTS_TO_RELOAD_ACCOUNT:
      return NotificationBarType.ERROR
    case FacebookNotificationType.ADS_CUSTOM_AUDIENCE_DEPRECATED:
    case FacebookNotificationType.CAPI_RECONNECT_FAIL:
      return NotificationBarType.WARNING
  }
}

export const parseFBNotification = (
  notification: FacebookNotification,
): FacebookReadyNotification => {
  return {
    ...notification,
    type: getMessageType(notification.message),
    removeType: NotificationRemoveType.ALWAYS,
    render: getRender(notification.message),
  }
}

import { analyticsService } from 'utils/services/analytics'

export enum AiAssistantOutsideOpenSource {
  TEMPLATES_MODAL_BUTTON = 'templates_modal_button',
}

export const AiAssistantAnalytics = {
  logOpenFromOutside: (data: { source: AiAssistantOutsideOpenSource; url: string }) =>
    analyticsService.sendEvent('AI_ASSISTANT.OPENED_FROM_OUTSIDE', data),
  logOpen: (data: { flowId: string; sessionId: string }) =>
    analyticsService.sendEvent('AI_ASSISTANT.OPEN', data),
  logBackToChat: (data: { flowId: string; sessionId: string }) =>
    analyticsService.sendEvent('AI_ASSISTANT.BACK_TO_CHAT', data),
  logRestartThread: (data: { flowId: string; sessionId: string }) =>
    analyticsService.sendEvent('AI_ASSISTANT.RESTART_THREAD', data),
  logClose: (data: { flowId: string; sessionId: string; feedback?: string }) =>
    analyticsService.sendEvent('AI_ASSISTANT.CLOSE', data),
  logBannerClick: (data: { URL: string }) =>
    analyticsService.sendEvent('AI_ASSISTANT.SELECT_TEMPLATE_MODAL_AI_BANNER.CLICK', data),
}

let scrollbarWidth = false

export function getInnerHeight(el) {
  const { paddingTop, paddingBottom } = getComputedStyle(el)
  return el.clientHeight - parseFloat(paddingTop) - parseFloat(paddingBottom)
}

export function getInnerWidth(el) {
  const { paddingLeft, paddingRight } = getComputedStyle(el)
  return el.clientWidth - parseFloat(paddingLeft) - parseFloat(paddingRight)
}

export function getScrollbarWidth() {
  if (scrollbarWidth !== false) return scrollbarWidth
  if (typeof document === 'undefined') return 0

  const div = document.createElement('div')
  div.style.width = '100px'
  div.style.height = '100px'
  div.style.position = 'absolute'
  div.style.top = '-9999px'
  div.style.overflow = 'scroll'
  div.style.MsOverflowStyle = 'scrollbar'
  document.body.appendChild(div)
  scrollbarWidth = div.offsetWidth - div.clientWidth
  document.body.removeChild(div)
  return scrollbarWidth
}

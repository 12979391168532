import capitalize from 'lodash/capitalize'
import get from 'lodash/get'

import { getUnit, formatUnit } from 'components/DelayPicker/utils'
import { calculateCTR } from 'utils/stats'

export function getStats(item) {
  const sent = get(item, 'stats.sent', 0)
  const read = get(item, 'stats.read', 0)
  const clicked = get(item, 'stats.clicked', 0)
  const delivered = get(item, 'stats.delivered') || 0

  const rr = Number(delivered) === 0 ? 0 : ((read / delivered) * 100).toFixed(1)
  const ctr = calculateCTR(clicked, delivered)
  const { subscribers, messagesTotal } = item

  return {
    rr,
    ctr,
    sent,
    read,
    delivered,
    clicked,
    subscribers,
    messages: messagesTotal,
  }
}

const IMMEDIATELY = 'immediately'

export const getMessageLabel = (item) => {
  const delay = item.delay_seconds || 0
  const { type, from, to, weekdays } = item.sending_time_settings

  const unitLabel = formatUnit(delay)
  const items = [unitLabel]

  if (type === 'timelimit') {
    items.push(getTimeLabel(from, to))
    items.push(`, ${getWeekdaysLabel(weekdays)}`)
  }

  const preposition = getUnit(delay) === IMMEDIATELY ? '' : 'After '
  return `${preposition} ${items.filter(Boolean).join(', ')}`
}

const getTimeLabel = (from, to) => {
  if (from === undefined || to === undefined || (from === 0 && to === 24)) {
    return ''
  }
  return `${from}:00-${to}:00`
}

const getWeekdaysLabel = (weekdays = []) => {
  return weekdays.length === 7
    ? 'Any Day'
    : weekdays.map((day) => capitalize(day).substr(0, 3)).join(', ')
}

import { v4 as uuid } from 'uuid'

import {
  ITriggerRuleBotSubscribedTrigger,
  ITriggerRuleDateTimeTrigger,
  ITriggerRuleLogConversionEventTrigger,
  ITriggerRuleTrigger,
} from 'apps/triggerRules/triggerRulesInterfaces'

import {
  TriggerRuleType,
  TriggerEventType,
  DateTimeTriggerOperators,
  TriggerUnitType,
  SubscribeToBotTriggerOperators,
} from './constants'

export default function createTrigger(event_type: TriggerEventType): ITriggerRuleTrigger {
  if (event_type === TriggerEventType.DATE_TIME) {
    const item: ITriggerRuleDateTimeTrigger = {
      _oid: uuid(),
      type: TriggerRuleType.DATE_TIME_EVENT,
      filter: null,
      data: {
        event_type,
        event_filter: {
          _oid: uuid(),
          field: null,
          operator: DateTimeTriggerOperators.BEFORE,
          type: null,
          unit: TriggerUnitType.DAYS,
          value: 1,
          is_formula: null,
        },
        at_time: null,
        repeat: null,
      },
    }
    return item
  }

  if (event_type === TriggerEventType.BOT_SUBSCRIBED) {
    const item: ITriggerRuleBotSubscribedTrigger = {
      _oid: uuid(),
      type: TriggerRuleType.INTERNAL_EVENT,
      filter: null,
      data: {
        event_type,
        event_filter: {
          _oid: uuid(),
          type: 'subscribed',
          operator: SubscribeToBotTriggerOperators.UNKNOWN,
          is_formula: null,
        },
      },
    }
    return item
  }

  if (event_type === TriggerEventType.LOG_CONVERSION_EVENT) {
    const item: ITriggerRuleLogConversionEventTrigger = {
      _oid: uuid(),
      type: TriggerRuleType.INTERNAL_EVENT,
      filter: null,
      data: {
        event_type,
        event_filter: {
          _oid: uuid(),
          type: 'custom_event',
          operator: 'IS',
          field: 'custom_event',
          is_formula: null,
        },
      },
    }
    return item
  }

  const item = {
    _oid: uuid(),
    type: TriggerRuleType.INTERNAL_EVENT,
    filter: null,
    data: {
      event_type,
      event_filter: {
        _oid: uuid(),
      },
    },
  }

  return item as ITriggerRuleTrigger
}

import { DefaultReplyChannelState } from 'apps/defaultReply/defaultReplyInterfaces'
import { ChannelType } from 'common/core/constants/ChannelType'

export const DefaultReplyChannels: ChannelType[] = [
  ChannelType.FB,
  ChannelType.INSTAGRAM,
  ChannelType.WHATSAPP,
  ChannelType.TELEGRAM,
  ChannelType.TIKTOK,
]

export const getInitialDefaultReplyChannelState = (): DefaultReplyChannelState => ({
  flowId: null,
  name: '',
  chId: null,
  sendType: '',
  enabled: null,
  fetching: false,
  isFetched: false,
  sources: null,
})

export const DEFAULT_REPLY_ROUTE = '/automation/default'

import assert from 'assert'

import { UNLINK_BLOCK_AND_BLOCK } from 'common/builder/constants/builderReduxActionTypes'
import BlockModel from 'common/builder/models/Block'
import { getBlockById } from 'common/builder/selectors/builder/entitySelectors'

/**
 * @param builderId
 * @param blockId
 * @param targetId
 */
export const unlinkBlock = (builderId, blockId, targetId) => {
  return (dispatch, getState) => {
    const state = getState()
    assert(builderId, `unlinkBlock: builderId is required param`)
    assert(blockId, `unlinkBlock: blockId is required param`)
    assert(targetId, `unlinkBlock: targetId is required param`)

    const item = getBlockById(state, builderId, blockId)
    assert(item, `unlinkBlock: block doesn't exist`)

    if (BlockModel.BlockTypesMinNested[item.type]) {
      const msg = `unlinkBlock: block with type "${item.type}" should have at least ${
        BlockModel.BlockTypesMinNested[item.type]
      } blocks`
      assert(item.blocks.length - 1 >= BlockModel.BlockTypesMinNested[item.type], msg)
    }

    dispatch({
      type: UNLINK_BLOCK_AND_BLOCK,
      builderId,
      blockId,
      targetId,
    })
  }
}

import EventEmitter from 'events'

import { makeRequest } from 'utils/api/query/request'

import { CacheKey, KeyPart, Primitive, Request, RequestCache } from './types'

type UpdateListener<T> = (query: Request<T>) => void

export const makeCache = (): RequestCache => {
  const map = new Map<string, Request>()
  const updateEmitter = new EventEmitter()
  return {
    get: <T>(key: CacheKey) =>
      withId(key, (id) => (map.get(id) as Request<T>) || makeRequest<T>(id)),
    set: <T>(query: Request<T>) => {
      map.set(query.id, query)
      updateEmitter.emit(query.id, query)
    },
    addUpdateListener: <T>(key: CacheKey, listener: UpdateListener<T>) => {
      updateEmitter.addListener(getCacheId(key), listener)
      return () => {
        updateEmitter.removeListener(getCacheId(key), listener)
      }
    },
  }
}

const withId = <T>(input: CacheKey, fn: (key: string) => T): T => fn(getCacheId(input))

export const getCacheId = (input: CacheKey): string =>
  ensureArray(input).map(keyPartMapper).join('')

const ensureArray = (input: CacheKey): KeyPart[] => (Array.isArray(input) ? input : [input])

const keyPartMapper = (input: KeyPart): string =>
  typeof input === 'object' ? stringifyObject(input) : String(input)

const stringifyObject = (input: Record<string, Primitive>) =>
  `{${Object.keys(input)
    .sort()
    .map((k) => `${k}:${input[k]}`)
    .join(';')}}`

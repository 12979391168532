import React, { useEffect } from 'react'
import cx from 'classnames'
import { useAppSelector } from 'reduxTyped'
import { l } from '@manychat/manyui'

import { billing, UpgradeSource } from 'common/billing'
import { getUpgradeCaption } from 'common/billing/selectors/billingSelectors'
import { analyticsService } from 'utils/services/analytics'

import cm from '../StaticNotifications.module.css'

export const WAFreePeriodEndNotification = () => {
  const upgradeCaption = useAppSelector(getUpgradeCaption)

  useEffect(() => {
    analyticsService.sendEvent('NOTIFICATION_BAR.WA_FREE_PERIOD_END.SHOWN')
  }, [])

  const handleClick = () => {
    analyticsService.sendEvent('WA_FREE_PERIOD_END_BANNER.UPGRADE_TO_PRO.CLICKED')
    billing.requestUpgrade({ source: UpgradeSource.WA_FREE_PERIOD_END })
  }

  return (
    <>
      <span>
        {l.translate(
          'Your Account reached WhatsApp Channel free period limit: one month of usage.',
        )}
      </span>
      <a target="_blank" className={cx(cm.more, 'm-l-xs')} onClick={handleClick}>
        {upgradeCaption}
      </a>
    </>
  )
}

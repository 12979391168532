import {
  IExternalTriggerContext,
  IExternalTriggerContextField,
} from 'apps/externalTriggers/externalTriggerInterfaces'
import { mapFields } from 'common/fields/api/adapter'
import { FieldGroupId } from 'common/fields/entities/enums'
import { Field } from 'common/fields/entities/field'
import { getFlowContext } from 'common/flow/selectors/flowSelectors'

export const getFlowContextFields = (state: RootState, flowId: string): Field[] => {
  const flowContext = getFlowContext(state, flowId) as IExternalTriggerContext[]
  return flowContext.reduce<Field[]>(contextFieldsReducer, [])
}

const contextFieldsReducer = (result: Field[], context: IExternalTriggerContext) => [
  ...result,
  ...mapFields(FieldGroupId.CONTEXT, context.fields.map(makeContextFieldMapper(context.name))),
]

const makeContextFieldMapper =
  (contextName: string) => (contextField: IExternalTriggerContextField) => ({
    ...contextField,
    name: `${contextField.prefix || ''}${contextField.name}`,
    context_name: contextName,
  })

import {
  AppLocalContext,
  ExternalAppContext,
  ExternalTriggerStatusType,
} from 'apps/externalTriggers/externalTriggerConstants'
import {
  IBackendExternalTrigger,
  IBackendExternalTriggerContext,
  IExternalTrigger,
  IExternalTriggerContext,
} from 'apps/externalTriggers/externalTriggerInterfaces'
import { IInstalledApplication } from 'common/actions/integrations/Applications/interfaces'
import { CONTEXT_FIELD_PREFIX, LOCAL_CONTEXT_FIELD_PREFIX } from 'common/fields/constants/FieldType'
import { TriggerType } from 'common/flow/constants/TriggerType'

export const contextAdapter = (
  baseContext: IBackendExternalTriggerContext[],
  contextName?: string,
): IExternalTriggerContext[] => {
  if (!baseContext.length) {
    return []
  }

  const actualContext: IBackendExternalTriggerContext[] = []
  const localContext = {
    caption: contextName ?? '',
    description: '',
    name: '',
    type: 'localContext',
    fields: [] as IBackendExternalTriggerContext[],
  }

  baseContext.forEach((context) => {
    if (['text', 'number', 'date', 'datetime', 'boolean'].includes(context.type)) {
      localContext.fields.push(context)
    } else if (context.fields) {
      actualContext.push(context)
    }
  })

  if (localContext.fields.length) {
    actualContext.push(localContext)
  }

  return actualContext.map((contextGroup) => ({
    name: contextGroup.caption,
    fields: (contextGroup.fields ?? []).map(({ caption, description, name, type }) => ({
      caption,
      description,
      type,
      segment_type: contextGroup.type === 'localContext' ? AppLocalContext : ExternalAppContext,
      name,
      prefix:
        contextGroup.type === 'localContext' ? LOCAL_CONTEXT_FIELD_PREFIX : CONTEXT_FIELD_PREFIX,
    })),
  }))
}

export const parseExternalTriggerContext = (
  context: IBackendExternalTriggerContext[],
  externalTriggerTypeName: string,
  application: null | IInstalledApplication,
): IExternalTriggerContext[] => {
  let contextName = externalTriggerTypeName
  if (application) {
    contextName = `${application.title} | ${contextName}`
  }

  return contextAdapter(context, contextName)
}

const parseExternalTrigger = (item: IBackendExternalTrigger): IExternalTrigger => {
  const {
    app_id: appId,
    flow_ns: flowId,
    external_trigger_id,
    title: caption,
    data = null,
    trigger_key: externalTriggerType,
    trigger_key_name: externalTriggerTypeName,
    context_example: contextExample,
    status: statusCode,
    status_title: statusType,
    previous_flow_ns,
    ts_created,
    template_installation_id,
    context,
    was_activated_once,
  } = item

  const enabled = statusType === ExternalTriggerStatusType.ACTIVE
  return {
    id: external_trigger_id,
    triggerType: TriggerType.EXTERNAL_TRIGGER,
    triggerId: `${TriggerType.EXTERNAL_TRIGGER}-${external_trigger_id}`,

    appId,
    flowId,
    external_trigger_id,
    caption,
    data,
    externalTriggerType,
    externalTriggerTypeName,
    context,
    contextExample,
    statusCode,
    statusType,
    template_installation_id,
    enabled,
    ts_created,
    previous_flow_ns,
    was_activated_once,
  }
}

export default parseExternalTrigger

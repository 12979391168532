import { initLocalization, TranslateData } from '@manychat/manyui'

import { localStorage } from 'utils'
import { safeDynamicImport } from 'utils/safeDynamicImport'

import { getApplicationLanguage } from './appLanguage'
import { detectLocaleFor } from './detectLanguage'
import { convertHTMLToReact } from './format'
import { makeInterpolate } from './interpolate'
import { pseudoTranslate } from './pseudoTranslate'
import { makeTranslate } from './translate'

const TRANSLATION_LOAD_ERROR_HANDLER_PARAMS = {
  fingerprint: 'load-translation',
  errorMessage: 'Translation load error',
  retriesCount: 1,
}

const defaultLanguage = 'en-US'
const resources: Record<string, () => Promise<Record<'default', Record<string, string>>>> = {
  [defaultLanguage]: () => import('./en/translation.json'),
  'pt-BR': () =>
    safeDynamicImport(() => import('./pt-BR/translation.json'), {
      alertMessage: 'Erro ao carregar a interface em português, atualize a página',
      ...TRANSLATION_LOAD_ERROR_HANDLER_PARAMS,
    }),
  'es-MX': () =>
    safeDynamicImport(() => import('./es-MX/translation.json'), {
      alertMessage: 'Error al cargar la interfaz en español, actualice la página',
      ...TRANSLATION_LOAD_ERROR_HANDLER_PARAMS,
    }),
  'ach-UG': () =>
    safeDynamicImport(
      () => import('./ach-UG/translation.json'),
      TRANSLATION_LOAD_ERROR_HANDLER_PARAMS,
    ),
}

export const initI18n = async (allowedLanguages: string[], userLanguage: string | undefined) => {
  const language = getApplicationLanguage(userLanguage || defaultLanguage)
  const usePseudoTranslation = Boolean(localStorage.getItem('pseudo-translation'))
  const localTranslations = resources[language]
  const locale = detectLocaleFor(language)
  const translationsList = []

  if (localTranslations && language !== defaultLanguage) {
    try {
      const local = await localTranslations()
      translationsList.unshift(local.default)
    } catch (error) {
      // do nothing, UI alert is shown in the failed import handler
      // for that case we just use English (keys from translations JSON)
    }
  }

  const translate = usePseudoTranslation ? pseudoTranslate : makeTranslate(translationsList)
  const interpolate = makeInterpolate('en-US')

  initLocalization({
    allowedLanguages,
    language,
    locale,
    translate: (message: string, data?: TranslateData) =>
      convertHTMLToReact(interpolate(translate(message), data), data) as string,
  })

  document.body.classList.add(language)
}

import { useMemo } from 'react'
import { useAppSelector } from 'reduxTyped'
import { IconNames } from '@manychat/manyui/src/components/BtnV2/interfaces'

import { ChannelType } from 'common/core/constants/ChannelType'
import { useAccountPermissions } from 'common/core/hooks/useAccountPermissions'
import { useGetPermissionHref } from 'common/core/hooks/useGetPermissionHref'
import {
  hasConnectedInstagramChannel,
  hasConnectedTiktokChannel as hasConnectedTTChannel,
  isFBChannelConnected,
} from 'common/core/selectors/channelSelectors'

export interface RefreshPermissionChannel {
  channel: ChannelType
  hasConnected: boolean
  hasError: boolean
  icon: IconNames
  isAvailableIGRefresh?: boolean
  href: string
}

// Returns a list of channels that are available for refreshing permissions
// If isNeedToFilterByError is true, only channels with errors will be returned - it is used in AccountErrorModal
// If isNeedToFilterByError is false, only channels with connections will be returned - it is used in RefreshPermissionModal
export const useAvailableChannelRefreshPermissions = (
  isNeedToFilterByError = false,
): RefreshPermissionChannel[] => {
  const hasConnectedFBChannel = useAppSelector(isFBChannelConnected)
  const hasConnectedIGChannel = useAppSelector(hasConnectedInstagramChannel)
  const hasConnectedTiktokChannel = useAppSelector(hasConnectedTTChannel)

  const { isNewIGApi, hasFbErrorStatus, hasIgErrorStatus, hasTiktokErrorStatus } =
    useAccountPermissions()

  const { getPermissionHref } = useGetPermissionHref()

  // if user has old IG API we need refresh only FB permissions
  // if user has new IG API we need refresh FB and IG permissions separately
  const isAvailableIGRefreshWithFb = useMemo(() => {
    if (!hasConnectedIGChannel || isNewIGApi) return false

    return hasConnectedFBChannel
  }, [hasConnectedFBChannel, hasConnectedIGChannel, isNewIGApi])

  const isAvailableIGRefreshWithFbByError = useMemo(() => {
    if (isNewIGApi) return false

    return hasIgErrorStatus && hasFbErrorStatus
  }, [hasFbErrorStatus, hasIgErrorStatus, isNewIGApi])

  const channelsAvailableForRefresh: RefreshPermissionChannel[] = [
    {
      channel: ChannelType.FB,
      hasConnected: hasConnectedFBChannel,
      hasError: hasFbErrorStatus,
      icon: 'MessengerChannel',
      isAvailableIGRefresh: !isNeedToFilterByError
        ? isAvailableIGRefreshWithFb
        : isAvailableIGRefreshWithFbByError,
      href: getPermissionHref(ChannelType.FB),
    },
    {
      channel: ChannelType.INSTAGRAM,
      hasConnected: isAvailableIGRefreshWithFb ? false : hasConnectedIGChannel,
      hasError: isAvailableIGRefreshWithFbByError ? false : hasIgErrorStatus,
      icon: 'InstagramChannel',
      href: getPermissionHref(ChannelType.INSTAGRAM),
    },
    {
      channel: ChannelType.TIKTOK,
      icon: 'TikTokChannel',
      hasConnected: hasConnectedTiktokChannel,
      hasError: hasTiktokErrorStatus,
      href: getPermissionHref(ChannelType.TIKTOK),
    },
  ]

  const filterBy = isNeedToFilterByError ? 'hasError' : 'hasConnected'

  return channelsAvailableForRefresh.filter((channel) => channel[filterBy])
}

export function createBrowserNotification(title: string, options?: NotificationOptions) {
  try {
    return new window.Notification(title, options)
  } catch {
    // we don't want to track these errors since user is not affected by them
    // non-persistent notifications behave differently across mobile and desktop
    // if notifications feature will be expanded, it is better to use service worker
    // @see: https://stackoverflow.com/questions/29774836/failed-to-construct-notification-illegal-constructor
  }

  return undefined
}

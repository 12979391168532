import { l } from '@manychat/manyui'

import { AddOnSlug } from 'common/billing/interfaces/addOnTypes'
import { alert } from 'common/core'
import { billingFlexApi } from 'shared/api/requests/billingFlex'
import { createAsyncAction } from 'shared/lib/redux'

export const cancelAddOn = createAsyncAction('billing/cancelAddOn', async (slug: AddOnSlug) => {
  await billingFlexApi.manageSubscription({
    body: {
      addons: {
        [slug]: false,
      },
    },
  })

  alert(l.translate('You’ve unsubscribed successfully'), 'success')

  return slug
})

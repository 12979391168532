import { AxiosRequestConfig, AxiosInstance } from 'axios'
import { OmitByValue, Optional } from 'utility-types'
import { Schema, z, ZodUndefined, ZodOptional } from 'zod'

import { replacePathParams } from 'shared/api/lib/methods/replacePathParams'
import { validateZodSchema } from 'shared/api/lib/methods/validateZodSchema'
import { PathParams, QueryParams, CreateGetOptions, Guards } from 'shared/api/lib/types'
import { Prettify } from 'shared/types/core'

import { getGuardsByErrors } from './getGuardsByErrors'

type RequestConfig = Omit<AxiosRequestConfig, 'params' | 'data'>

interface Options<Path extends Schema, Query extends Schema> {
  config?: RequestConfig
  path: z.infer<Path>
  query: z.infer<Query>
}

export const createGetFactory = (instance: AxiosInstance) => {
  function createGet<
    Response extends Schema,
    Path extends Schema<PathParams>,
    Query extends Schema<QueryParams>,
    Errors,
  >({ url, schemas }: CreateGetOptions<Response, Path, Query, Errors>) {
    type RequestOptions = Options<Path, Query>
    type OptionalType = ZodOptional<Schema> | ZodUndefined

    type PossibleOptionalFields = [
      Path extends OptionalType ? 'path' : never,
      Query extends OptionalType ? 'query' : never,
    ][number]

    type OptionalOptions = Optional<RequestOptions, PossibleOptionalFields>

    type OptionsWithoutOnlyUndefinedFields = Prettify<OmitByValue<OptionalOptions, undefined>>

    type Argument = Pick<RequestOptions, 'config'> extends Omit<
      RequestOptions,
      PossibleOptionalFields
    >
      ? OptionsWithoutOnlyUndefinedFields | void
      : OptionsWithoutOnlyUndefinedFields

    type PartialOptions = Partial<RequestOptions>

    const fetcher = async (options: Argument) => {
      const partialOptions = (options ?? {}) as PartialOptions
      validateZodSchema(schemas.path, partialOptions.path, url, 'path')
      validateZodSchema(schemas.query, partialOptions.query, url, 'query')

      const processedUrl = partialOptions.path ? replacePathParams(url, partialOptions.path) : url

      let config: AxiosRequestConfig = {}

      if (partialOptions.config) {
        config = { ...partialOptions.config }
      }

      if (partialOptions.query) {
        config = { ...config, params: partialOptions.query }
      }

      const response = await instance.get<z.infer<Response>>(processedUrl, config)

      validateZodSchema(schemas.response, response.data, url, 'response')

      return response
    }

    if (schemas.errors) {
      const guards = getGuardsByErrors(schemas.errors)
      Object.assign(fetcher, guards)
    }

    return fetcher as typeof fetcher & Guards<Errors>
  }

  return createGet
}

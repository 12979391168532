import { ValueLabel } from '../interfaces/actions'

export enum TimezoneType {
  ALL = 'all',
  TIME_TRAVEL = 'timetravel',
  TIME_LIMIT = 'timelimit',
}

export const TimezoneTypeLabel: Record<TimezoneType, string> = {
  [TimezoneType.ALL]: 'Send all at the same time',
  [TimezoneType.TIME_TRAVEL]: 'Time travel',
  [TimezoneType.TIME_LIMIT]: 'Limit sending time',
}

export const TimezoneTypeOptions: Array<ValueLabel<TimezoneType>> = [
  { value: TimezoneType.ALL, label: TimezoneTypeLabel[TimezoneType.ALL] },
  { value: TimezoneType.TIME_TRAVEL, label: TimezoneTypeLabel[TimezoneType.TIME_TRAVEL] },
  { value: TimezoneType.TIME_LIMIT, label: TimezoneTypeLabel[TimezoneType.TIME_LIMIT] },
]

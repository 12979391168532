import BaseButtonConfig from 'common/builder/buttons/BaseButtonConfig'
import { IButtonBase, IContentButton } from 'common/builder/buttons/buttonInterfaces'
import { ButtonType } from 'common/builder/constants/ButtonType'

class ContentButtonConfig extends BaseButtonConfig {
  buttonType = ButtonType.CONTENT

  getDefaults = (): Omit<IContentButton, keyof IButtonBase> => ({
    isSmartLink: false,
    targetId: null,
    isFallback: false,
    emoji: null,
    image: null,
    isNotificationRequest: null,
  })
}

export default new ContentButtonConfig()

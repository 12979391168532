// Integrations
export const INTEGRATION_FETCH = 'INTEGRATION_FETCH'
export const INTEGRATION_FETCH_REQUEST = 'INTEGRATION_FETCH_REQUEST'
export const INTEGRATION_FETCH_RESPONSE = 'INTEGRATION_FETCH_RESPONSE'

export const INTEGRATION_UPDATED_NOTIFICATION = 'INTEGRATION_UPDATED_NOTIFICATION'

export const INTEGRATION_DISCONNECT = 'INTEGRATION_DISCONNECT'
export const INTEGRATION_DISCONNECT_REQUEST = 'INTEGRATION_DISCONNECT_REQUEST'
export const INTEGRATION_DISCONNECT_RESPONSE = 'INTEGRATION_DISCONNECT_RESPONSE'
export const INTEGRATION_DISCONNECT_ERROR = 'INTEGRATION_DISCONNECT_ERROR'

export const INTEGRATION_CONNECT_BY_TOKEN = 'INTEGRATION_CONNECT_BY_TOKEN'
export const INTEGRATION_CONNECT_BY_TOKEN_REQUEST = 'INTEGRATION_CONNECT_BY_TOKEN_REQUEST'
export const INTEGRATION_CONNECT_BY_TOKEN_RESPONSE = 'INTEGRATION_CONNECT_BY_TOKEN_RESPONSE'
export const INTEGRATION_CONNECT_BY_TOKEN_ERROR = 'INTEGRATION_CONNECT_BY_TOKEN_ERROR'

export const HUBSPOT_SET_ACCOUNT_LINK_FIELD = 'HUBSPOT_SET_ACCOUNT_LINK_FIELD'
export const HUBSPOT_SET_ACCOUNT_LINK_FIELD_RESPONSE = 'HUBSPOT_SET_ACCOUNT_LINK_FIELD_RESPONSE'

export const HUBSPOT_CLEAR_ACCOUNT_LINK_FIELD = 'HUBSPOT_CLEAR_ACCOUNT_LINK_FIELD'
export const HUBSPOT_CLEAR_ACCOUNT_LINK_FIELD_RESPONSE = 'HUBSPOT_CLEAR_ACCOUNT_LINK_FIELD_RESPONSE'
export const HUBSPOT_FORMS_FETCH = 'HUBSPOT_FORMS_FETCH'
export const HUBSPOT_FORMS_FETCH_REQUEST = 'HUBSPOT_FORMS_FETCH_REQUEST'
export const HUBSPOT_FORMS_FETCH_RESPONSE = 'HUBSPOT_FORMS_FETCH_RESPONSE'
export const HUBSPOT_FORMS_FETCH_ERROR = 'HUBSPOT_FORMS_FETCH_ERROR'
export const HUBSPOT_FORM_BY_ID_FETCH = 'HUBSPOT_FORM_BY_ID_FETCH'
export const HUBSPOT_FORM_BY_ID_FETCH_REQUEST = 'HUBSPOT_FORM_BY_ID_FETCH_REQUEST'
export const HUBSPOT_FORM_BY_ID_FETCH_RESPONSE = 'HUBSPOT_FORM_BY_ID_FETCH_RESPONSE'
export const HUBSPOT_FORM_BY_ID_FETCH_ERROR = 'HUBSPOT_FORM_BY_ID_FETCH_ERROR'
export const HUBSPOT_CONTACT_FIELDS_FETCH = 'HUBSPOT_CONTACT_FIELDS_FETCH'
export const HUBSPOT_CONTACT_FIELDS_FETCH_REQUEST = 'HUBSPOT_CONTACT_FIELDS_FETCH_REQUEST'
export const HUBSPOT_CONTACT_FIELDS_FETCH_RESPONSE = 'HUBSPOT_CONTACT_FIELDS_FETCH_RESPONSE'
export const HUBSPOT_CONTACT_FIELDS_FETCH_ERROR = 'HUBSPOT_CONTACT_FIELDS_FETCH_ERROR'

export const HUBSPOT_SET_FORM = 'HUBSPOT_SET_FORM'
export const HUBSPOT_SET_FORM_BINDINGS = 'HUBSPOT_SET_FORM_BINDINGS'
export const HUBSPOT_SET_CONTACT_MAPPINGS = 'HUBSPOT_SET_CONTACT_MAPPINGS'
export const HUBSPOT_SET_CUF_IN_MAPPINGS = 'HUBSPOT_SET_CUF_IN_MAPPINGS'
export const HUBSPOT_SET_PROPERTY_IN_BINDINGS = 'HUBSPOT_SET_PROPERTY_IN_BINDINGS'
export const HUBSPOT_ADD_CUF_PROPERTY_PAIR = 'HUBSPOT_ADD_CUF_PROPERTY_PAIR'
export const HUBSPOT_SET_CUF_PROPERTY_BINDINGS = 'HUBSPOT_SET_CUF_PROPERTY_BINDINGS'
export const HUBSPOT_DELETE_CUF_PROPERTY_PAIR = 'HUBSPOT_DELETE_CUF_PROPERTY_PAIR'
export const HUBSPOT_SET_IDENTITY_FIELD = 'HUBSPOT_SET_IDENTITY_FIELD'
export const HUBSPOT_FORM_FIELDS_REFRESH = 'HUBSPOT_FORM_FIELDS_REFRESH'
export const HUBSPOT_FORM_FIELDS_REFRESH_REQUEST = 'HUBSPOT_FORM_FIELDS_REFRESH_REQUEST'
export const HUBSPOT_FORM_FIELDS_REFRESH_RESPONSE = 'HUBSPOT_FORM_FIELDS_REFRESH_RESPONSE'
export const HUBSPOT_FORM_FIELDS_REFRESH_ERROR = 'HUBSPOT_FORM_FIELDS_REFRESH_ERROR'

export const SALESFORCE_SET_ACCOUNT_LINK_FIELD = 'SALESFORCE_SET_ACCOUNT_LINK_FIELD'
export const SALESFORCE_CLEAR_ACCOUNT_LINK_FIELD = 'SALESFORCE_CLEAR_ACCOUNT_LINK_FIELD'

export const SALESFORCE_CONTACT_FIELDS_FETCH = 'SALESFORCE_CONTACT_FIELDS_FETCH'
export const SALESFORCE_CONTACT_FIELDS_FETCH_RESPONSE = 'SALESFORCE_CONTACT_FIELDS_FETCH_RESPONSE'

export const SALESFORCE_EXPORT_LIVECHAT_HISTORY = 'SALESFORCE_EXPORT_LIVECHAT_HISTORY'

export const SALESFORCE_SET_CONTACT_MAPPINGS = 'SALESFORCE_SET_CONTACT_MAPPINGS'
export const SALESFORCE_SET_CUF_IN_MAPPINGS = 'SALESFORCE_SET_CUF_IN_MAPPINGS'
export const SALESFORCE_SET_PROPERTY_IN_BINDINGS = 'SALESFORCE_SET_PROPERTY_IN_BINDINGS'
export const SALESFORCE_ADD_CUF_PROPERTY_PAIR = 'SALESFORCE_ADD_CUF_PROPERTY_PAIR'
export const SALESFORCE_SET_CUF_PROPERTY_BINDINGS = 'SALESFORCE_SET_CUF_PROPERTY_BINDINGS'
export const SALESFORCE_DELETE_CUF_PROPERTY_PAIR = 'SALESFORCE_DELETE_CUF_PROPERTY_PAIR'
export const SALESFORCE_SET_IDENTITY_FIELD = 'SALESFORCE_SET_IDENTITY_FIELD'
export const SALESFORCE_SET_SEARCH_FIELD = 'SALESFORCE_SET_SEARCH_FIELD'
export const SALESFORCE_SET_SEARCH_VALUE = 'SALESFORCE_SET_SEARCH_VALUE'

export const CONVERTKIT_SET_ACCOUNT_LINK_FIELD = 'CONVERTKIT_SET_ACCOUNT_LINK_FIELD'
export const CONVERTKIT_SET_ACCOUNT_LINK_FIELD_RESPONSE =
  'CONVERTKIT_SET_ACCOUNT_LINK_FIELD_RESPONSE'
export const CONVERTKIT_CLEAR_ACCOUNT_LINK_FIELD = 'CONVERTKIT_CLEAR_ACCOUNT_LINK_FIELD'
export const CONVERTKIT_CLEAR_ACCOUNT_LINK_FIELD_RESPONSE =
  'CONVERTKIT_CLEAR_ACCOUNT_LINK_FIELD_RESPONSE'
export const CONVERTKIT_FORMS_FETCH = 'CONVERTKIT_FORMS_FETCH'
export const CONVERTKIT_FORMS_FETCH_REQUEST = 'CONVERTKIT_FORMS_FETCH_REQUEST'
export const CONVERTKIT_FORMS_FETCH_RESPONSE = 'CONVERTKIT_FORMS_FETCH_RESPONSE'
export const CONVERTKIT_FORMS_FETCH_ERROR = 'CONVERTKIT_FORMS_FETCH_ERROR'
export const CONVERTKIT_SET_FORM = 'CONVERTKIT_SET_FORM'
export const CONVERTKIT_SET_BINDINGS = 'CONVERTKIT_SET_BINDINGS'
export const CONVERTKIT_SET_MAPPINGS = 'CONVERTKIT_SET_MAPPINGS'
export const CONVERTKIT_SEQUENCES_FETCH = 'CONVERTKIT_SEQUENCES_FETCH'
export const CONVERTKIT_SEQUENCES_FETCH_REQUEST = 'CONVERTKIT_SEQUENCES_FETCH_REQUEST'
export const CONVERTKIT_SEQUENCES_FETCH_RESPONSE = 'CONVERTKIT_SEQUENCES_FETCH_RESPONSE'
export const CONVERTKIT_SEQUENCES_FETCH_ERROR = 'CONVERTKIT_SEQUENCES_FETCH_ERROR'
export const CONVERTKIT_SET_SEQUENCE = 'CONVERTKIT_SET_SEQUENCE'
export const CONVERTKIT_TAGS_FETCH = 'CONVERTKIT_TAGS_FETCH'
export const CONVERTKIT_TAGS_FETCH_REQUEST = 'CONVERTKIT_TAGS_FETCH_REQUEST'
export const CONVERTKIT_TAGS_FETCH_RESPONSE = 'CONVERTKIT_TAGS_FETCH_RESPONSE'
export const CONVERTKIT_TAGS_FETCH_ERROR = 'CONVERTKIT_TAGS_FETCH_ERROR'
export const CONVERTKIT_SET_TAG = 'CONVERTKIT_SET_TAG'
export const CONVERTKIT_SET_IDENTITY_FIELD = 'CONVERTKIT_SET_IDENTITY_FIELD'
export const CONVERTKIT_FIELDS_FETCH = 'CONVERTKIT_FIELDS_FETCH'
export const CONVERTKIT_FIELDS_FETCH_REQUEST = 'CONVERTKIT_FIELDS_FETCH_REQUEST'
export const CONVERTKIT_FIELDS_FETCH_RESPONSE = 'CONVERTKIT_FIELDS_FETCH_RESPONSE'
export const CONVERTKIT_FIELDS_FETCH_ERROR = 'CONVERTKIT_FIELDS_FETCH_ERROR'
export const INTEGRATION_RESET = 'INTEGRATION_RESET'

export const GOOGLE_SHEETS_SPREAD_SHEETS_FETCH = 'GOOGLE_SHEETS_SPREAD_SHEETS_FETCH'
export const GOOGLE_SHEETS_SPREAD_SHEETS_FETCH_REQUEST = 'GOOGLE_SHEETS_SPREAD_SHEETS_FETCH_REQUEST'
export const GOOGLE_SHEETS_SPREAD_SHEETS_FETCH_RESPONSE =
  'GOOGLE_SHEETS_SPREAD_SHEETS_FETCH_RESPONSE'
export const GOOGLE_SHEETS_SPREAD_SHEETS_FETCH_ERROR = 'GOOGLE_SHEETS_SPREAD_SHEETS_FETCH_ERROR'

export const GOOGLE_SHEETS_WORK_SHEETS_FETCH = 'GOOGLE_SHEETS_WORK_SHEETS_FETCH'
export const GOOGLE_SHEETS_WORK_SHEETS_FETCH_REQUEST = 'GOOGLE_SHEETS_WORK_SHEETS_FETCH_REQUEST'
export const GOOGLE_SHEETS_WORK_SHEETS_FETCH_RESPONSE = 'GOOGLE_SHEETS_WORK_SHEETS_FETCH_RESPONSE'
export const GOOGLE_SHEETS_WORK_SHEETS_FETCH_ERROR = 'GOOGLE_SHEETS_WORK_SHEETS_FETCH_ERROR'

export const GOOGLE_SHEETS_COLUMN_HEADERS_FETCH = 'GOOGLE_SHEETS_COLUMN_HEADERS_FETCH'
export const GOOGLE_SHEETS_COLUMN_HEADERS_FETCH_REQUEST =
  'GOOGLE_SHEETS_COLUMN_HEADERS_FETCH_REQUEST'
export const GOOGLE_SHEETS_COLUMN_HEADERS_FETCH_RESPONSE =
  'GOOGLE_SHEETS_COLUMN_HEADERS_FETCH_RESPONSE'
export const GOOGLE_SHEETS_COLUMN_HEADERS_FETCH_ERROR = 'GOOGLE_SHEETS_COLUMN_HEADERS_FETCH_ERROR'

export const GOOGLE_SHEETS_SET_CURRENT_SHEET = 'GOOGLE_SHEETS_SET_CURRENT_SHEET'
export const GOOGLE_SHEETS_SET_CURRENT_WORK_SHEET = 'GOOGLE_SHEETS_SET_CURRENT_WORK_SHEET'
export const GOOGLE_SHEETS_SET_BINDING = 'GOOGLE_SHEETS_SET_BINDING'
export const GOOGLE_SHEETS_SET_MAPPING = 'GOOGLE_SHEETS_SET_MAPPING'
export const GOOGLE_SHEETS_UPDATE_BINDING = 'GOOGLE_SHEETS_UPDATE_BINDING'
export const GOOGLE_SHEETS_DELETE_BINDING = 'GOOGLE_SHEETS_DELETE_BINDING'
export const GOOGLE_SHEETS_UPDATE_MAPPING = 'GOOGLE_SHEETS_UPDATE_MAPPING'
export const GOOGLE_SHEETS_DELETE_MAPPING = 'GOOGLE_SHEETS_DELETE_MAPPING'

export const GOOGLE_SHEETS_SET_LINK = 'GOOGLE_SHEETS_SET_LINK'
export const GOOGLE_SHEETS_RESET_SHEET = 'GOOGLE_SHEETS_RESET_SHEET'
export const GOOGLE_SHEETS_RESET_SPREADSHEET = 'GOOGLE_SHEETS_RESET_SPREADSHEET'

export const GOOGLE_SHEETS_SET_COLUMN = 'GOOGLE_SHEETS_SET_COLUMN'
export const GOOGLE_SHEETS_SET_LOOKUP_VALUE = 'GOOGLE_SHEETS_SET_LOOKUP_VALUE'

export const MAILCHIMP_SET_ACCOUNT_LINK_FIELD = 'MAILCHIMP_SET_ACCOUNT_LINK_FIELD'
export const MAILCHIMP_SET_ACCOUNT_LINK_FIELD_RESPONSE = 'MAILCHIMP_SET_ACCOUNT_LINK_FIELD_RESPONSE'
export const MAILCHIMP_CLEAR_ACCOUNT_LINK_FIELD = 'MAILCHIMP_CLEAR_ACCOUNT_LINK_FIELD'
export const MAILCHIMP_CLEAR_ACCOUNT_LINK_FIELD_RESPONSE =
  'MAILCHIMP_CLEAR_ACCOUNT_LINK_FIELD_RESPONSE'
export const MAILCHIMP_LISTS_FETCH = 'MAILCHIMP_LISTS_FETCH'
export const MAILCHIMP_LISTS_FETCH_REQUEST = 'MAILCHIMP_LISTS_FETCH_REQUEST'
export const MAILCHIMP_LISTS_FETCH_RESPONSE = 'MAILCHIMP_LISTS_FETCH_RESPONSE'
export const MAILCHIMP_LISTS_FETCH_ERROR = 'MAILCHIMP_LISTS_FETCH_ERROR'
export const MAILCHIMP_SET_LIST = 'MAILCHIMP_SET_LIST'
export const MAILCHIMP_SET_IDENTITY_FIELD = 'MAILCHIMP_SET_IDENTITY_FIELD'

// ActiveCampaign
export const ACTIVE_CAMPAIGN_SET_IDENTITY_FIELD = 'ACTIVE_CAMPAIGN_SET_IDENTITY_FIELD'

export const ACTIVE_CAMPAIGN_LISTS_FETCH = 'ACTIVE_CAMPAIGN_LISTS_FETCH'
export const ACTIVE_CAMPAIGN_LISTS_FETCH_REQUEST = 'ACTIVE_CAMPAIGN_LISTS_FETCH_REQUEST'
export const ACTIVE_CAMPAIGN_LISTS_FETCH_RESPONSE = 'ACTIVE_CAMPAIGN_LISTS_FETCH_RESPONSE'
export const ACTIVE_CAMPAIGN_LISTS_FETCH_ERROR = 'ACTIVE_CAMPAIGN_LISTS_FETCH_ERROR'

export const ACTIVE_CAMPAIGN_SET_LIST = 'ACTIVE_CAMPAIGN_SET_LIST'

export const ACTIVE_CAMPAIGN_SET_FIELD_EMPTINESS = 'ACTIVE_CAMPAIGN_SET_FIELD_EMPTINESS'

export const ACTIVE_CAMPAIGN_FIELDS_FETCH = 'ACTIVE_CAMPAIGN_FIELDS_FETCH'

// Klaviyo
export const KLAVIYO_SET_IDENTITY_FIELD = 'KLAVIYO_SET_IDENTITY_FIELD'
export const KLAVIYO_SET_CONTACT_FIELDS = 'KLAVIYO_SET_CONTACT_FIELDS'
export const KLAVIYO_SET_CONTACT_LIST = 'KLAVIYO_SET_CONTACT_LIST'

export const KLAVIYO_ADD_FIELD = 'KLAVIYO_ADD_FIELD'
export const KLAVIYO_SET_VISIBLE_FIELDS = 'KLAVIYO_SET_VISIBLE_FIELDS'

export const KLAVIYO_GET_LISTS = 'KLAVIYO_GET_LISTS'
export const KLAVIYO_GET_LISTS_REQUEST = 'KLAVIYO_GET_LISTS_REQUEST'
export const KLAVIYO_GET_LISTS_RESPONSE = 'KLAVIYO_GET_LISTS_RESPONSE'
export const KLAVIYO_GET_LISTS_ERROR = 'KLAVIYO_GET_LISTS_ERROR'

export const KLAVIYO_GET_FIELDS = 'KLAVIYO_GET_FIELDS'
export const KLAVIYO_GET_FIELDS_REQUEST = 'KLAVIYO_GET_FIELDS_REQUEST'
export const KLAVIYO_GET_FIELDS_RESPONSE = 'KLAVIYO_GET_FIELDS_RESPONSE'
export const KLAVIYO_GET_FIELDS_ERROR = 'KLAVIYO_GET_FIELDS_ERROR'

export const KLAVIYO_SET_FIELD_EMPTINESS = 'KLAVIYO_SET_FIELD_EMPTINESS'

import { useAppSelector } from 'reduxTyped'

import { getBillingCurrency } from 'common/billing/selectors/billingSelectors'

/**
 * ## Usage
 * This hook provides the billing currency used for financial connection between user and Manychat.
 *
 * @example
 * ```
 * const currency = useBillingCurrency()
 * console.log(`Billing currency: ${currency}`) // Output: Billing currency: USD
 * ```
 */

export const useBillingCurrency = () => {
  return useAppSelector(getBillingCurrency)
}

import React from 'react'
import cx from 'classnames'
import PropTypes from 'prop-types'
import * as Icon from '@manychat/icons'

import { noop, getWindow, getIsMobile } from '../../../utils'
import BtnV2 from '../../BtnV2'
import Scrollbars from '../../Scrollbars'
import RenderToLayer from '../RenderToLayer'

import { CLOSE_REASONS, MODAL_IGNORE_ATTRIBUTE } from './constants'

import cm from './Modal.module.css'

/**
 * Modals communicate information via a secondary window and allow the user
 * to maintain the context of a particular task. Use Modals sparingly because they interrupt user workflow.
 *
 *
 *
 * There are three sections in Modal component. Top section is __Header__ , it is controlled by `title` and `headerButtons` props.
 * __Body__ can be enabled by passing children to component. __Footer__ contains buttons only.
 *
 * [Figma](https://www.figma.com/design/Xf38PdCKeISWnBoh0pLFIIWf/%F0%9F%A7%B1-MC-Desktop-Components?node-id=20497-65059&p=f&m=dev)
 */
export default class Modal extends React.Component {
  static propTypes = {
    /** Array of props map which will be applied to Btn component. Custom components can be used in this array too. */
    buttons: PropTypes.array,
    /** The content to be displayed inside the modal. */
    children: PropTypes.any,
    /** An optional CSS class to apply additional styles or customize the component's appearance. */
    className: PropTypes.string,
    /** CSS class for the overlay. */
    overlayClassName: PropTypes.string,
    /** If true, the modal cannot be closed. */
    disableClose: PropTypes.bool,
    /** If true, the modal cannot be closed with the escape key. */
    disableEscapeClose: PropTypes.bool,
    /** If true, the modal cannot be closed by clicking on the overlay. */
    disableOverlayClose: PropTypes.bool,
    /** If true, the close button will not be displayed. */
    disableCloseButton: PropTypes.bool,
    /** Array of buttons to be displayed in the header. */
    headerButtons: PropTypes.array,
    /** The component to be used for the modal. */
    modalComponent: PropTypes.string,
    /** If true, the modal is in confirm mode. Header will have different styles */
    isConfirm: PropTypes.bool,
    /** If true, the content will not have padding. */
    noContentPadding: PropTypes.bool,
    /** Callback function to be called when the modal is closed. */
    onClose: PropTypes.func,
    /** Callback function to be called when the modal is opened. */
    onOpen: PropTypes.func,
    /** Callback function to be called when a request to close the modal is made. */
    onRequestClose: PropTypes.func,
    /** If true, the modal is open. */
    open: PropTypes.bool,
    /** If true, the modal content is scrollable. */
    scrollable: PropTypes.bool,
    /** The title of the modal. */
    title: PropTypes.any,
    /** The width of the modal. For example: 460, 640, 900, 1200, 100%. By default modal width is 640px. */
    width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    /** Array of events that are allowed to propagate. */
    allowPropagationEvents: PropTypes.array,
    /** Data test id for the closer button */
    'closer-data-test-id': PropTypes.string,
  }

  static defaultProps = {
    buttons: [],
    headerButtons: [],
    modalComponent: 'div',
    onRequestClose: noop,
    onClose: noop,
    onOpen: noop,
  }

  state = { open: false, containerWidth: 0, containerNode: null }

  handleRef = (node) => {
    if (node !== null) {
      this.setState({ containerNode: node })
    }
  }

  static getDerivedStateFromProps(nextProps) {
    if ('open' in nextProps) {
      const { open } = nextProps
      return { open }
    }
    return null
  }

  componentDidMount() {
    window.addEventListener('resize', this.handleResize, true)
  }

  handleResize = () => {
    if (this.state.containerNode) {
      this.setState({ containerWidth: this.state.containerNode.clientWidth })
    }
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize, true)
  }

  componentDidUpdate(_, prevState) {
    if (this.state.open !== !prevState.open) {
      this.props[`on${this.state.open ? 'Open' : 'Close'}`]()
    }
    if (this.state.containerNode !== prevState.containerNode) {
      this.handleResize()
    }
  }

  close = (reason, e) => {
    if (this.props.disableClose) {
      return
    }

    if ('open' in this.props) {
      this.props.onRequestClose(reason, e)
    } else {
      this.setState({ open: false })
    }
  }

  open = () => this.setState({ open: true })

  handleClickAway = (e) => {
    if (e.target.closest(`[${MODAL_IGNORE_ATTRIBUTE}="true"]`)) {
      return
    }

    !this.props.disableOverlayClose && this.close(CLOSE_REASONS.CLICK_AWAY, e)
  }

  handleEscapeKeyDown = (e) => !this.props.disableEscapeClose && this.close(CLOSE_REASONS.ESC, e)

  render() {
    const { open, containerWidth } = this.state
    const {
      buttons,
      children,
      className,
      overlayClassName,
      disableClose,
      disableCloseButton,
      headerButtons,
      isConfirm,
      noContentPadding,
      scrollable,
      title,
      width,
      allowPropagationEvents,
      'closer-data-test-id': closerDataTestId,
    } = this.props

    const hasHeaderButtons = headerButtons.length > 0
    const hasHeader = Boolean(title || hasHeaderButtons)
    const hasCloseBtn = !disableClose && !disableCloseButton
    const hasButtons = buttons.length > 0
    const offsetY = hasButtons ? 160 : 112
    const body = children ? (
      <div className={cx({ 'p-x p-y-xs': !noContentPadding })}>{children}</div>
    ) : null
    const isMobile = getIsMobile()

    return (
      <RenderToLayer
        className={cx('relative', cm.overlay, overlayClassName)}
        render={this.renderModal}
        open={Boolean(open)}
        onEscapeKeyDown={this.handleEscapeKeyDown}
        onClickAway={this.handleClickAway}
        allowPropagationEvents={allowPropagationEvents}
      >
        {hasCloseBtn && (
          <Icon.Close
            size={32}
            onClick={this.close}
            className={cm.close}
            data-test-id={closerDataTestId}
            style={{ right: `calc(50% - ${containerWidth / 2}px - var(--spacers-xxs))` }}
          />
        )}
        <div
          ref={this.handleRef}
          className={cx(isMobile ? cm.mobile : cm.modal, className)}
          style={{ width }}
          tabIndex={-1}
        >
          {hasHeader && (
            <div className={cx('p-y-xs p-x row center')}>
              <div className={'text-heading-2 col-auto'}>{title}</div>
              {hasHeaderButtons && <div className="col row end">{headerButtons}</div>}
            </div>
          )}

          {body && (
            <div className={cx(hasHeader && !isConfirm && 'b-t')}>
              {scrollable ? (
                <Scrollbars autoHeight autoHeightMax={getWindow().innerHeight - offsetY}>
                  {body}
                </Scrollbars>
              ) : (
                body
              )}
            </div>
          )}

          {hasButtons && (
            <div className={cx('p-y-xs p-x b-t row', buttons.length > 1 ? 'between' : 'end')}>
              {buttons.map((button, i) =>
                React.isValidElement(button) ? (
                  React.cloneElement(button, { key: button.key ?? i })
                ) : (
                  <BtnV2 {...button} key={i} />
                ),
              )}
            </div>
          )}
        </div>
      </RenderToLayer>
    )
  }
}

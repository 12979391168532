import { createAction } from '@reduxjs/toolkit'

import { namespace } from 'apps/cms/store/lib/constants'
import { AutomationModalType, CmsModalsState } from 'apps/cms/store/lib/types'

const openModal = createAction<{
  type: AutomationModalType
  data: CmsModalsState[AutomationModalType]['data']
}>(`${namespace}/openModal`)

const closeModal = createAction<AutomationModalType>(`${namespace}/closeModal`)

export const cmsModalsActions = {
  openModal,
  closeModal,
}

const getState = (state: RootState) => state.contentManagementSystem.modals

export const cmsModalsSelectors = {
  getModals: getState,
}

import React, { useCallback } from 'react'
import cx from 'classnames'
import { useDrop } from 'react-dnd'
import { useAppSelector } from 'reduxTyped'
import { l, Icon } from '@manychat/manyui'

import { useModalActions } from 'apps/cms/components/Flows/components/AutomationModals/hooks/useModalActions'
import animationsCm from 'apps/cms/lib/styles/animations.module.css'
import { CmsDragSource, FlowDragItem } from 'apps/cms/lib/types'
import { contentManagementSelectors } from 'apps/cms/store'
import { AutomationModalType } from 'apps/cms/store/lib/types'

import cm from './NewFolderButton.module.css'

export const NewFolderButton = () => {
  const { openModal } = useModalActions()

  const openCreateFolderModal = useCallback(() => {
    openModal({
      type: AutomationModalType.CREATE_FOLDER,
      data: null,
    })
  }, [openModal])

  const openCreateFolderWithContentModal = useCallback(
    (flowIds: string[]) => {
      openModal({
        type: AutomationModalType.CREATE_FOLDER_WITH_CONTENT,
        data: { flowIds },
      })
    },
    [openModal],
  )

  const selectedFlowIds = useAppSelector(contentManagementSelectors.getSelectedFlowIds)

  const [{ isDragging, highlightDrop }, dropTarget] = useDrop({
    accept: CmsDragSource.FLOW_CARD,
    collect: (monitor) => ({
      isDragging: monitor.isOver(),
      highlightDrop: Boolean(monitor.getItem()),
    }),
    drop: (item: FlowDragItem) => {
      const flowIds = selectedFlowIds.length > 0 ? selectedFlowIds : [item.path]

      openCreateFolderWithContentModal(flowIds)
    },
  })

  return (
    <button
      onClick={openCreateFolderModal}
      className={cx(
        cm.newFolderButton,
        highlightDrop && animationsCm.cmsDragTargetAnimation,
        isDragging && cm.newFolderButton_highlight,
      )}
      type="button"
      ref={dropTarget}
    >
      <Icon.Plus />

      {l.translate('New Folder')}
    </button>
  )
}

import dot from 'dot-prop-immutable'

export const mergeData = (item, data, path = [], replace) => {
  if (data === undefined) {
    // Deleting data if undefined, cause 'undefined' is not valid JSON type
    return dot.delete(item, path)
  } else if (!replace && typeof data === 'object' && data !== null && !Array.isArray(data)) {
    // using dot.merge only for objects, cause it uses concat for arrays and not works with primitive types
    return dot.merge(item, path, data)
  } else {
    return dot.set(item, path, data)
  }
}

import { useAppSelector } from 'reduxTyped'

import { contentManagementSelectors } from 'apps/cms/store'
import { FsFolderObject } from 'shared/api/requests/cms/schemas'

export const useFolderByPath = (path: string): FsFolderObject => {
  const folder = useAppSelector((state) => contentManagementSelectors.getFolderByPath(state, path))

  if (!folder) {
    throw new Error('Folder not found')
  }

  return folder
}

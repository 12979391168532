import React from 'react'
import { l, Icon } from '@manychat/manyui'

import { IntentTrigger } from 'shared/api/requests/cms/schemas'

import cm from './AiIntentTitle.module.css'

interface AiIntentTitleProps {
  trigger: IntentTrigger
}

export const AiIntentTitle = (props: AiIntentTitleProps) => (
  <div className={cm.wrapper}>
    <span>{l.translate('Message is about')}</span>

    <span className={cm.intent} title={props.trigger.intent}>
      <Icon.Ai size={12} />
      <span className="o-hidden text-ellipsis">{props.trigger.intent}</span>
    </span>
  </div>
)

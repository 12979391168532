import { LayoutWithSidebar } from 'utils/router/components/LayoutWithSidebar/LayoutWithSidebar'
import { getHandle } from 'utils/router/utils/getHandle'

export const AudienceRoutes = [
  {
    path: 'subscribers/:user_id?/merge?/:secondary_id?',
    handle: getHandle('contacts'),
    Component: LayoutWithSidebar,
    children: [
      {
        index: true,
        lazy: () => import('./audience.page'),
      },
    ],
  },
] as const

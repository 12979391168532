import React, { useState } from 'react'
import cx from 'classnames'
import { l } from '@manychat/manyui'

import { isTestEnv } from 'utils/e2e'
import { isLocal } from 'utils/isLocal'

import cm from './CgtTitleImg.module.css'

interface CgtTitleImgProps {
  url: string
}

// @param url is part of Manychat api, we need to proxy it for the local environment
const getUrl = (url: string) => {
  return isLocal() || isTestEnv() ? '/api' + url : url
}

export function CgtTitleImg({ url }: CgtTitleImgProps) {
  const [isLoaded, setIsLoaded] = useState(true)

  const handleLoad = () => {
    setIsLoaded(false)
  }

  return (
    <div className={cm.imageContainer}>
      {url && (
        <img
          alt={l.translate('Instagram image preview')}
          src={getUrl(url)}
          className={cx('m-a-0', { [cm.hidden]: isLoaded })}
          onLoad={handleLoad}
          height={16}
          width={16}
        />
      )}
    </div>
  )
}

import { appListenerMiddleware } from 'reduxTyped'
import { l } from '@manychat/manyui'

import { dropThreadsList } from 'apps/chat/actions/threadsActions'
import { ActionTypes } from 'common/actions'
import { fetchSubscribers } from 'common/audience/actions/audienceActionsTyped'
import { MIGRATE_CUF_TO_SF } from 'common/audience/constants/BulkActionTypes'
import { getSelection } from 'common/audience/selectors/audienceSelectors'
import { alert } from 'common/core'
import { AudienceApi } from 'shared/api/requests/audience'
import {
  AudienceBulkActionPayload,
  AudienceBulkActionType,
} from 'shared/api/requests/audience/schemas'
import { createAsyncAction } from 'shared/lib/redux'
import { makeLimitedListActions } from 'utils/factory'
import { analyticsService } from 'utils/services/analytics'

const baseListActions = makeLimitedListActions('audience')

interface BulkActionPayload extends AudienceBulkActionPayload {
  forceListUpdate?: boolean
  tagName?: string
}

export const bulkActionRequest = createAsyncAction(
  'audience/bulkActionRequest',
  async (data: BulkActionPayload) => {
    const { forceListUpdate: _force, tagName: _tagName, ...body } = data

    const response = await AudienceApi.bulkAction({
      body,
    })

    return response.data
  },
  {
    autoHandleByGuards: [
      AudienceApi.bulkAction.isUserNotFoundError,
      AudienceApi.bulkAction.isUserNotFoundError,
      AudienceApi.bulkAction.isBulkInProgressError,
      AudienceApi.bulkAction.isWrongSequenceError,
      AudienceApi.bulkAction.isWrongFieldError,
      AudienceApi.bulkAction.isWrongTagError,
    ],
  },
)

interface ApplySubscriberListTagPayload {
  query: Partial<BulkActionPayload>
  tagId: number
  tagName: string
}

export const applySubscriberListTag =
  (payload: ApplySubscriberListTagPayload) => (dispatch: AppDispatch) => {
    const { query, tagId, tagName } = payload

    return dispatch(
      bulkActionRequest({
        filter: null,
        ...query,
        selection_type: 'exclude',
        ids: [],
        props: { tag_id: tagId },
        tag_id: tagId,
        type: ActionTypes.ADD_TAG,
        tagName,
      }),
    )
  }

interface MigrateCUFToSFPayload {
  cufId: string
  sfName: string
  overwrite?: boolean
  tags?: Array<{ value: string }>
  uuid: string
}

export const migrateCUFToSF =
  (payload: MigrateCUFToSFPayload) => (dispatch: AppDispatch, getState: () => AppState) => {
    const { cufId, sfName, overwrite = false, tags = [], uuid } = payload
    const selection = getSelection(getState())

    return dispatch(
      bulkActionRequest({
        ...selection,
        type: MIGRATE_CUF_TO_SF,
        field_id: cufId.replace('cuf_', ''),
        suf: sfName,
        overwrite,
        tags: tags.map((tag) => tag.value),
        async: uuid,
      }),
    )
  }

interface PerformBulkActionPayload extends Partial<BulkActionPayload> {
  type: BulkActionPayload['type']
  uuid?: string
  forceListUpdate?: boolean
}

export const bulkAction =
  (payload: PerformBulkActionPayload) => (dispatch: AppDispatch, getState: () => AppState) => {
    const { forceListUpdate = true, uuid, ...action } = payload
    const selection = getSelection(getState())
    const body = {
      ...selection,
      ...action,
      forceListUpdate,
    }
    if (uuid) {
      Object.assign(body, { async: uuid })
    }

    return dispatch(bulkActionRequest(body))
  }

const automationBulkActions: AudienceBulkActionType[] = [
  ActionTypes.PAUSE_AUTOMATION_FOREVER,
  ActionTypes.RESUME_AUTOMATION,
]

appListenerMiddleware.startListening({
  matcher: bulkActionRequest.fulfilled.match,
  effect: (action, { dispatch }) => {
    const bulkActionType = action.meta.arg.type
    analyticsService.sendEvent('AUDIENCE.BULK_ACTION.SUCCESS', { action: bulkActionType })

    if (!action.payload.async) {
      alert(l.translate('Done!'), 'success')
    }

    if (action.meta.arg.forceListUpdate) {
      dispatch(baseListActions.drop())
      dispatch(fetchSubscribers())

      if (automationBulkActions.includes(bulkActionType)) {
        dispatch(dropThreadsList())
      }
    }
  },
})

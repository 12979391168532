import { z } from 'zod'

import { AutoAssignmentType } from 'common/settings/constants/AutoAssignmentType'
import { PauseAutomationFormat } from 'common/settings/constants/PauseAutomationFormat'

export const LiveChatAccountSettingsSchema = z.object({
  pause_automation_format: z.nativeEnum(PauseAutomationFormat).optional(),
  assign_on_previous_agent_when_reopen: z.boolean(),
  auto_assign_type: z.nativeEnum(AutoAssignmentType).nullable(),
})

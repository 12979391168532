import { createSelector } from 'reselect'

import {
  getCurrentAccount,
  getCurrentAccountUser,
  getUserId,
} from 'common/core/selectors/appSelectors'
import { ROLES } from 'common/settings/components/SettingsPanel/TeamMembersSection/constants'
import {
  UserRolesPermissions,
  UserRoles,
  PermissionTargets,
  PermissionsMap,
} from 'common/userRoles/constants/UserRoles'

export {
  PermissionTargets,
  UserRoles,
  UserRolesNames,
  UserRolesDescriptions,
  UserRolesPermissions,
} from 'common/userRoles/constants/UserRoles'

export const getUsers = (state: RootState) => state.users

export const getAccountUsers = (state: RootState) => state?.users?.items

export const getIsUsersFetching = (state: RootState) => state.users?.isFetching

export const getCurrentUser = (state: RootState) => {
  const currentUserId = getUserId(state)
  const users = getAccountUsers(state)

  return users?.find((user) => user.user_id === currentUserId) || null
}

export const getCurrentInboxUser = createSelector([getUsers], (users) => users.currentInboxUser)

export const getIsFetchingCheckboxStatus = createSelector(
  [getCurrentInboxUser],
  (inboxUser) => inboxUser?.isFetchingCheckboxStatus,
)

export const getUserIsFacebookAdmin = (state: RootState) =>
  Boolean(getCurrentAccountUser(state)?.has_facebook_tasks_to_administer_page)

export const getUserRole = (state: RootState): UserRoles => {
  return getCurrentAccountUser(state)?.user_role || UserRoles.ADMIN
}

export const getIsInboxUser = (state: RootState): boolean => {
  return getCurrentAccountUser(state)?.is_inbox_user
}

export const getPermissions = (state: RootState): PermissionsMap => {
  return UserRolesPermissions[getUserRole(state)]
}

export const getPermission = (state: RootState, target: PermissionTargets): boolean => {
  return getPermissions(state)[target]
}

export const getCanFetchJobQuestionnaire = (state: RootState) =>
  getPermission(state, PermissionTargets.JOB_QUESTIONNAIRE_VIEW)

export const getCanFetchWallet = (state: RootState) => {
  const hasPermission = getPermission(state, PermissionTargets.CAN_FETCH_WALLET)
  const hasBillingAccess = getCurrentAccountUser(state).has_billing_access

  return hasPermission && hasBillingAccess
}

export const getUsersInOrder = (state: RootState) => {
  const users = getAccountUsers(state)

  if (!users) {
    return []
  }

  const modifyUsers = users.map((user) => ({
    ...user,
    user_role: user.is_creator ? UserRoles.OWNER : user.user_role,
  }))

  return ROLES.map((role) =>
    modifyUsers
      .filter((user) => user.user_role === role)
      .sort((userA, userB) => {
        if (userA.is_inbox_user && !userB.is_inbox_user) {
          return -1
        }

        if (!userA.is_inbox_user && userB.is_inbox_user) {
          return 1
        }

        if (userA.has_billing_access && !userB.has_billing_access) {
          return -1
        }

        if (!userA.has_billing_access && userB.has_billing_access) {
          return 1
        }

        return 0
      }),
  )
}

export const getHasAccessToPixelSettings = (state: RootState) => getCurrentAccount(state).isPro

import { useCallback } from 'react'
import { useAppSelector } from 'reduxTyped'

import { useTelegramQrModal } from 'apps/telegram/components/TelegramQrModal/useTelegramQrModal'
import { useTelegramSignIn } from 'apps/telegram/hooks/useTelegramSignIn'
import {
  getAppleProfile,
  getFBProfile,
  getGoogleProfile,
  getTelegramProfile,
} from 'common/profiles/profileSelectors'
import { RoutePaths } from 'utils/router/constants'

export const useSignInOptions = () => {
  const fbProfile = useAppSelector(getFBProfile)
  const googleProfile = useAppSelector(getGoogleProfile)
  const appleProfile = useAppSelector(getAppleProfile)
  const telegramProfile = useAppSelector(getTelegramProfile)

  const { hasProfile: hasFBProfile } = fbProfile
  const { hasProfile: hasGoogleProfile } = googleProfile
  const { hasProfile: hasAppleProfile } = appleProfile
  const { hasProfile: hasTelegramProfile } = telegramProfile

  const { getOptInUrl, isLoading: isTelegramConnecting } = useTelegramSignIn(RoutePaths.HOMETAB)
  const { openModal: OpenTgModal } = useTelegramQrModal()

  const handleTelegramConnect = useCallback(async () => {
    const response = await getOptInUrl()
    if (response) {
      OpenTgModal()
      window.location.assign(response.data.url)
    }
  }, [getOptInUrl, OpenTgModal])

  return {
    fbProfile,
    googleProfile,
    appleProfile,
    telegramProfile,
    hasFBProfile,
    hasGoogleProfile,
    hasAppleProfile,
    hasTelegramProfile,
    handleTelegramConnect,
    isTelegramConnecting,
  }
}

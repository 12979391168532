import React from 'react'
import { useAppSelector } from 'reduxTyped'
import { Loader } from '@manychat/manyui'

import { ChannelType } from 'common/core/constants/ChannelType'
import EnsureOneTimeNotifyReasonsController from 'common/oneTimeNotify/components/EnsureOneTimeNotifyReasonsController'
import OneTimeNotifyPicker from 'common/oneTimeNotify/components/OneTimeNotifyReasonsPickerController/OneTimeNotifyPicker/OneTimeNotifyPicker'
import {
  getTargetReasonsList,
  getTargetPageReasonsListForAutocomplete,
} from 'common/oneTimeNotify/selectors/notifyReasonSelectors'

interface TargetPageOtnPickerProps {
  value: number | null
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void
  disabled: boolean
  allowCreate: boolean
  channel: ChannelType | null
  placeholder?: string
}

export const TargetPageOtnPicker = (props: TargetPageOtnPickerProps) => {
  const notificationReasonPrepared = useAppSelector((state) =>
    getTargetPageReasonsListForAutocomplete(state, props.channel),
  )

  const notificationReasons = useAppSelector(getTargetReasonsList)

  return (
    <EnsureOneTimeNotifyReasonsController loader={<Loader size={32} />}>
      <OneTimeNotifyPicker
        {...props}
        notificationReasons={notificationReasons}
        notificationReasonPrepared={notificationReasonPrepared}
      />
    </EnsureOneTimeNotifyReasonsController>
  )
}

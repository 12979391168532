import React from 'react'
import { Icon } from '@manychat/manyui'

import { BaseTitle } from 'apps/cms/components/Flows/components/FlowCard/components/Triggers/components/BaseTitle/BaseTitle'
import {
  getRuns,
  getWidgetStatistics,
} from 'apps/cms/components/Flows/components/FlowCard/components/Triggers/hooks/useTriggers/lib/statistics'
import { TriggerType } from 'common/flow/constants/TriggerType'

export const getAnotherFlowTrigger = (anotherFlowTrigger: { title: string }) => ({
  title: <BaseTitle text={anotherFlowTrigger.title} />,
  icon: <Icon.GoToFlow size={16} className="text-secondary" />,
  disabled: false,
  runs: getRuns(null),
  ctr: 'n/a',
  created: 0,
  status: undefined,
  id: null,
  type: TriggerType.ANOTHER_FLOW,
  impressions: getWidgetStatistics(null),
  optIns: getWidgetStatistics(null),
})

import React, { useCallback } from 'react'
import cx from 'classnames'
import { l } from '@manychat/manyui'

import { FilterPickerCheckbox } from 'apps/cms/components/Flows/components/FlowFilters/components/FilterPicker/FilterPickerCheckbox/FilterPickerCheckbox'
import { Option } from 'apps/cms/components/Flows/components/FlowFilters/components/FilterPicker/lib/types'
import { TranslatedFilterLabels } from 'apps/cms/pages/ContentManagementSystem/lib/filterLabels'

import cm from './FilterPickerItem.module.css'

interface FilterPickerItemProps {
  option: Option
  selectedOptions: Option[]
  setSelectedOptions: React.Dispatch<React.SetStateAction<Option[]>>
}

export const FilterPickerItem = ({
  option,
  selectedOptions,
  setSelectedOptions,
}: FilterPickerItemProps) => {
  const { label, icon: ItemIcon, key } = option
  const translatedLabel = l.getString(TranslatedFilterLabels[label]) ?? label

  const toggleOption = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>, option: Option) => {
      setSelectedOptions((prevSelectedOptions) => {
        if (event.target.checked) {
          return [...prevSelectedOptions, option]
        }

        return prevSelectedOptions.filter((prevOption) => prevOption.key !== option.key)
      })
    },
    [setSelectedOptions],
  )

  const isOptionChecked = selectedOptions.some(
    (selectedOption) => selectedOption.key === option.key,
  )

  return (
    <label
      title={translatedLabel}
      htmlFor={key}
      className={cx(cm.filterItem, {
        [cm.filterItem_checked]: isOptionChecked,
      })}
    >
      <div className={cx('d-flex align-center', cm.filterItem__content)}>
        {ItemIcon && <ItemIcon size={16} className={cm.filterItem__icon} />}
        <span className="text-ellipsis">{translatedLabel}</span>
      </div>

      <FilterPickerCheckbox
        id={key}
        onChange={(event) => toggleOption(event, option)}
        checked={isOptionChecked}
      />
    </label>
  )
}

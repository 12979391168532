import React from 'react'
import { Link } from 'react-router-dom'
import { l } from '@manychat/manyui'

import { WHATSAPP_PRICING_URL } from 'common/billing/wallet/components/constants'
import { linkURL } from 'utils/url'

import cm from './common.module.css'

export const WAMessageTemplateCategoryChange = () => {
  return (
    <div>
      {l.translate(
        `The category of one or more Message Templates was updated by Meta.
      This might influence <a href="{priceUrl}" target="_blank" rel="noreferrer" class="{class}">the price</a> of linked broadcasts. `,
        {
          priceUrl: WHATSAPP_PRICING_URL,
          class: cm.link,
        },
      )}
      <Link to={linkURL('/settings#whatsApp/templates')} className={cm.link}>
        See all Message Templates
      </Link>
    </div>
  )
}

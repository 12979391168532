import { l } from '@manychat/manyui'

import { IWidget } from 'apps/growthTools/growthToolsInterfaces'
import { WidgetStatusType } from 'apps/growthTools/models/Widget/constants'
import { alert } from 'common/core'
import { IAsyncThunkAction, IAsyncThunkActionWithReturnValue } from 'common/core/interfaces/actions'
import { IFullFlow } from 'common/flow/flowInterfaces'
import { MappedLightFlow } from 'shared/api/common/schemas/lightFlow'
import { growthToolsApi } from 'shared/api/requests/growthTools'
import { widgetsApi } from 'shared/api/requests/widgets'
import { createAsyncAction } from 'shared/lib/redux'
import { isRequestError } from 'utils/api/mcApi/RequestError'
import { makeItemsDetailsActions } from 'utils/factory'
import errorTrackingService from 'utils/services/errorTrackingService'

import * as atypes from '../constants/gtReduxActionTypes'

const baseItemActions = makeItemsDetailsActions('widgets')

export const createWidgetItemAction = baseItemActions.create

export const activateWidgetWithoutValidation =
  ({ widgetId }: { widgetId: number }): IAsyncThunkActionWithReturnValue<boolean> =>
  async (dispatch) => {
    try {
      await dispatch({
        type: atypes.WIDGETS_UPDATE_STATUS,
        $endpoint: ['widgets.saveStatus', { id: widgetId, status: WidgetStatusType.ACTIVE }],
        id: widgetId,
        $error: () => {},
      })
      return true
    } catch (error) {
      return false
    }
  }

export const createWidgetBase = (
  widget: Partial<IFullFlow | MappedLightFlow>,
): IAsyncThunkAction<{ item: { triggerId: string } } | null> => {
  return async (dispatch) => {
    try {
      return (await dispatch(createWidgetItemAction(widget, null, () => {}))) as unknown as {
        item: IWidget
      }
    } catch (error) {
      if (isRequestError(error) && error.reason === 'business-logic') {
        const alertText = Array.isArray(error.response?.errors)
          ? error.response?.errors.join('. ')
          : error.response?.error

        alert(alertText, 'danger')
        return null
      }

      alert(l.translate('Something went wrong'), 'danger')
      errorTrackingService.trackWarning(error, {
        fingerprint: 'failed-to-create-widget',
      })
      return null
    }
  }
}

export const fetchPromotableFbPosts = createAsyncAction(
  'growth-tools/fetchPromotableFBPosts',
  async () => (await growthToolsApi.promotableFBPosts()).data,
)

export const fetchScheduledFbPosts = createAsyncAction(
  'growth-tools/fetchScheduledFbPosts',
  async () => (await growthToolsApi.scheduledFBPosts()).data,
)

export const fetchFbPosts = createAsyncAction(
  'growth-tools/fetchFbPosts',
  async (next?: string) =>
    (await growthToolsApi.fbPosts(next ? { query: { next_page: next } } : undefined)).data,
)

export const searchFbPost = createAsyncAction(
  'growth-tools/searchFbPost',
  async (searchQuery: string) =>
    (await growthToolsApi.searchFbPosts({ query: { search_query: searchQuery } })).data,
)

export const logCheckingResult = createAsyncAction(
  'growth-tools/logCheckingResult',
  async ({ logId, messages }: { logId: number; messages: string[] }) =>
    await growthToolsApi.logCheckingResult({ body: { log_id: logId, messages } }),
)

export const widgetCheck = createAsyncAction(
  'growth-tools/widgetCheck',
  async ({ widgetId, url, async }: { widgetId: number; url: string; async: string }) =>
    await widgetsApi.widgetCheck({ body: { widget_id: widgetId, url, async } }),
)

export const setWidgetFlow = createAsyncAction(
  'growth-tools/setFlow',
  async ({ widgetId, flowNs }: { widgetId: number; flowNs: string }) =>
    (await widgetsApi.setFlow({ body: { widget_id: widgetId, flow_ns: flowNs } })).data,
)

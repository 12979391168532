import React from 'react'
import cx from 'classnames'
import { Icon, BtnV2, l, palettes } from '@manychat/manyui'

import {
  IAppleProfile,
  IFBProfile,
  IGoogleProfile,
  ITelegramProfile,
} from 'common/profiles/profileInterfaces'
import { Profile } from 'common/profiles/profileSelectors'
import { openAuthWindow, ProfileAuthURLs } from 'common/profiles/utils'
import { getProfileImageStubURL } from 'utils/assets'
import { RoutePaths } from 'utils/router/constants'
import { analyticsService } from 'utils/services/analytics'

import { ProfileTypes } from '../constants'

import cm from './ProfileCard.module.css'

export interface IProfileCardProps {
  title: string
  profileType: ProfileTypes
  profile: Profile<IFBProfile | IGoogleProfile | IAppleProfile | ITelegramProfile>
  withBottomBorder?: boolean
  handleTelegramConnect?: () => void
  isTelegramConnecting?: boolean
}

const isTelegramProfile = (
  profile: IProfileCardProps['profile'],
): profile is Profile<ITelegramProfile> => 'username' in profile

const authURLs = {
  [ProfileTypes.FB]: ProfileAuthURLs.FB,
  [ProfileTypes.GOOGLE]: ProfileAuthURLs.GOOGLE,
  [ProfileTypes.APPLE]: ProfileAuthURLs.APPLE,
}

const icons = {
  [ProfileTypes.FB]: (
    <Icon.Facebook
      className="m-r-xs"
      data-test-id="profile-icon"
      color={palettes.semantic.primary}
    />
  ),
  [ProfileTypes.GOOGLE]: <Icon.Google className="m-r-xs" data-test-id="profile-icon" />,
  [ProfileTypes.APPLE]: <Icon.Apple className="m-r-xs" data-test-id="profile-icon" />,
  [ProfileTypes.TELEGRAM]: (
    <Icon.Telegram className="m-r-xs" data-test-id="profile-icon" color="#0088cc" />
  ),
}

const ProfileCard = ({
  profile,
  title,
  profileType,
  withBottomBorder = true,
  handleTelegramConnect,
  isTelegramConnecting,
}: IProfileCardProps) => {
  const { hasProfile, first_name: firstName, last_name: lastName, avatar } = profile

  const handleConnectClick = () => {
    analyticsService.sendEvent('MANAGE_SIGN_IN_MODAL.BTN_CONNECT.CLICK', { profileType })

    if (profileType === ProfileTypes.TELEGRAM) {
      handleTelegramConnect?.()
      analyticsService.sendEvent('APP.PROFILE.MANAGE_SIGN_IN_MODAL.BTN_CONNECT_TELEGRAM.CLICK')
    } else {
      openAuthWindow(authURLs[profileType], RoutePaths.HOMETAB)
    }
  }

  const profileDescription = isTelegramProfile(profile)
    ? `@${profile.username}`
    : profile.profile_email

  const initials = lastName ? `${firstName} ${lastName}` : firstName

  return (
    <div
      className={cx({ 'b-b': withBottomBorder }, 'align-center d-flex justify-between p-y')}
      data-test-id={`${profileType}-profile-card`}
    >
      <div className={cx('align-center d-flex', cm.firstColumnWrapper)}>
        {icons[profileType]}
        <span data-test-id="profile-title">{title}</span>
      </div>
      <div className={cx(cm.userInfo, 'd-flex align-center')}>
        {hasProfile ? (
          <>
            {avatar ? (
              <img className={cx(cm.avatar, 'm-r-xs')} data-test-id="profile-avatar" src={avatar} />
            ) : (
              <img
                className={cx(cm.avatar, 'm-r-xs')}
                data-test-id="profile-avatar-placeholder"
                src={getProfileImageStubURL()}
              />
            )}

            <div>
              <div data-test-id="profile-initials">{initials}</div>
              {profileDescription && (
                <div className="text-sm text-secondary" data-test-id="profile-description">
                  {profileDescription}
                </div>
              )}
            </div>
          </>
        ) : (
          <span className="m-l-lg" data-test-id="profile-not-connected">
            {l.translate('Not connected')}
          </span>
        )}
      </div>

      {hasProfile ? (
        <BtnV2
          className={cm.button}
          leftIcon="Tick"
          label={l.translate('Connected')}
          data-test-id="profile-connected-button"
        />
      ) : (
        <BtnV2
          onClick={handleConnectClick}
          data-test-id="profile-connect-button"
          variant="primary"
          label={l.translate('Connect')}
          className={cm.button}
          loading={isTelegramConnecting}
        />
      )}
    </div>
  )
}

export default ProfileCard

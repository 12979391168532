import React, { ReactNode, useState } from 'react'
import cx from 'classnames'
import { Icon } from '@manychat/manyui'

import cm from './Accordion.module.css'

export interface AccordionItem<SubItem> {
  id?: string
  label: string
  subItems: SubItem[]
  defaultOpen?: boolean
}

interface AccordionProps<SubItem> {
  items: Array<AccordionItem<SubItem>>
  className?: string
  childClassName?: string
  childrenClassName?: string
  itemClassName?: string
  labelClassName?: string
  renderChild?: (child: SubItem, item?: AccordionItem<SubItem>) => React.ReactNode
  renderItem?: (item: AccordionItem<SubItem>, isOpen: boolean) => React.ReactNode
  onItemClick?: (label: string) => void
  isUni?: boolean
  showEmpty?: boolean
  singleOpen?: boolean
}

export const Accordion = <SubItem,>({
  items = [],
  className,
  childClassName,
  childrenClassName,
  itemClassName,
  labelClassName,
  renderChild = (subItem) => subItem as unknown as ReactNode,
  renderItem = (item) => `${item?.subItems?.length ?? 0} ${item.label}`,
  onItemClick,
  isUni = false,
  showEmpty = false,
  singleOpen = false,
}: AccordionProps<SubItem>) => {
  const [openItems, setOpenItems] = useState(items.filter((i) => i.defaultOpen).map((i) => i.label))

  const handleToggle = (item: AccordionItem<SubItem>) => {
    const { label, subItems } = item

    if (subItems && subItems.length === 0) return

    if (openItems.includes(label)) {
      setOpenItems(openItems.filter((o) => o !== label))
      return
    }

    if (singleOpen) {
      setOpenItems([label])
    } else {
      setOpenItems([...openItems, label])
    }

    if (onItemClick) {
      onItemClick(label)
    }
  }

  const renderAccordionUni = () => (
    <div className={className} data-test-id="uni-accordion">
      {items.map((item, i) => {
        const isOpen = openItems.includes(item.label)
        const hasItems = item?.subItems?.length ?? false

        return (
          (hasItems || showEmpty) && (
            <div className={itemClassName} key={i} data-test-id="uni-accordion-item">
              <div className={labelClassName} onClick={() => handleToggle(item)}>
                {renderItem(item, isOpen)}
              </div>

              {isOpen && (
                <div className={childrenClassName}>
                  {hasItems &&
                    item.subItems?.map((subItem, i) => {
                      const child = renderChild(subItem, item)
                      if (!child) {
                        return null
                      }

                      return (
                        <div className={childClassName} key={i}>
                          {child}
                        </div>
                      )
                    })}
                </div>
              )}
            </div>
          )
        )
      })}
    </div>
  )

  const renderAccordionDefault = () => (
    <div className={className} data-test-id="default-accordion">
      {items.map((item, i) => {
        const isOpen = openItems.includes(item.label)
        const hasItems = item?.subItems?.length ?? false

        return (
          (hasItems || showEmpty) && (
            <div className={cm.item} key={i} data-test-id="default-accordion-item">
              <div
                className={cx(
                  cm.label,
                  labelClassName,
                  'row gutter middle p-a text-subheading pointer',
                )}
                onClick={() => handleToggle(item)}
              >
                <div className="col">{renderItem(item, isOpen)}</div>

                <div className="col-auto">
                  <Icon.ChevronDown className={cx(cm.icon, { [cm.rotate]: isOpen })} size={32} />
                </div>
              </div>

              <div className={cx(cm.subItems, childrenClassName, 'p-x', { [cm.show]: isOpen })}>
                {hasItems &&
                  item.subItems?.map((subItem, i) => (
                    <div className={cx(childClassName, 'p-l m-b')} key={i}>
                      {renderChild(subItem, item)}
                    </div>
                  ))}
              </div>
            </div>
          )
        )
      })}
    </div>
  )

  return isUni ? renderAccordionUni() : renderAccordionDefault()
}

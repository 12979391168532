import React, { useState } from 'react'
import cx from 'classnames'
import { Icon } from '@manychat/manyui'

import cm from './SimpleAccordion.module.css'

interface SimpleAccordionProps {
  title: string
  subtitle?: React.ReactNode
  children: React.ReactNode
  className?: string
}

const SimpleAccordion = ({ className, title, subtitle, children }: SimpleAccordionProps) => {
  const [isOpen, setOpen] = useState(false)

  return (
    <div className={cx(className, 'p-a-md m-t-md m-b panel panel-white panel-shadow text-left')}>
      <div>
        <div className={cx('row middle pointer', { 'b-b m-b p-b': isOpen })}>
          <div className="col p-l-0">
            <div className="text-h-primary text-subheading" onClick={() => setOpen(!isOpen)}>
              {title}
            </div>
            {subtitle}
          </div>
          <div className="col-auto">
            <Icon.ChevronDown
              onClick={() => setOpen(!isOpen)}
              size={24}
              className={cx(cm.icon, 'text-secondary text-h-primary', { [cm.rotate]: isOpen })}
            />
          </div>
        </div>
      </div>
      <div className={cx('o-hidden', { 'd-none': !isOpen })}>{children}</div>
    </div>
  )
}

export default SimpleAccordion

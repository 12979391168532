import { Field } from 'common/fields/entities/field'

export const changeFieldIdToFieldCaption = (inputText: string, fields: Field[]) => {
  let text = inputText
  const fieldIds = text.match(/(?:\{{).+?(?:\}})/gm)
  if (!fieldIds) {
    return text
  }
  fieldIds.forEach((reasonId: string) => {
    const fieldCaption = fields.find(
      (field) => field.id === reasonId.slice(2, reasonId.length - 2),
    )?.caption
    text = fieldCaption ? text.replace(`${reasonId}`, fieldCaption) : text
  })
  return text
}

import BaseButtonConfig from 'common/builder/buttons/BaseButtonConfig'
import { IButtonBase, IFlowButton } from 'common/builder/buttons/buttonInterfaces'
import { ButtonType } from 'common/builder/constants/ButtonType'

class FlowButtonConfig extends BaseButtonConfig {
  buttonType = ButtonType.FLOW

  getDefaults = (): Omit<IFlowButton, keyof IButtonBase> => ({
    flowId: null,
    emoji: null,
    image: null,
  })
}

export default new FlowButtonConfig()

import isPlainObject from 'lodash/isPlainObject'
import { l } from '@manychat/manyui'

import { translateResponse } from 'utils/backendErrors'

interface ErrorInfo {
  error?: TSFixMe
  errors?: string[] | Record<string, unknown>
}

export const translateErrorInfo = (errorInfo?: ErrorInfo | null) => {
  let forTranslate = null

  if (errorInfo?.errors) {
    if (Array.isArray(errorInfo.errors)) {
      forTranslate = { errors: errorInfo.errors }
    } else if (isPlainObject(errorInfo.errors)) {
      const errors = Object.values(errorInfo.errors).filter(
        (item) => typeof item === 'string',
      ) as string[]
      forTranslate = { errors }
    }
  } else if (typeof errorInfo?.error === 'string') {
    forTranslate = { error: errorInfo.error }
  }

  if (!forTranslate) {
    return { error: l.translate('Something went wrong') }
  }

  translateResponse(forTranslate)

  return forTranslate
}

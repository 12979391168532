import * as atypes from 'apps/ads/constants/AdsReduxActionTypes'
import { parseDevicePlatforms } from 'apps/ads/models/AdSet/adapter'
import { makeCurrentItemReducer } from 'utils/factory'

const currentAdSetItemReducer = makeCurrentItemReducer('adSets')

const initialState = {
  isInitializing: false,
  isEstimateLoading: false,
  isPublishing: false,
  isCreating: false,
  estimate: {},
  lastSaved: null,
  lastChanged: null,
  interactionType: null,
  item: null,
  platformsData: {},
}

export default function adSetReducer(state = initialState, action) {
  state = currentAdSetItemReducer(state, action)

  switch (action.type) {
    case atypes.ADSETS_CURRENT_UPDATE: {
      return { ...state, lastChanged: Date.now() }
    }
    case atypes.ADSET_SAVE_RESPONSE: {
      return { ...state, lastSaved: action.lastSaved }
    }

    case atypes.ADSET_DROP: {
      return initialState
    }
    case atypes.ADSET_INIT: {
      return { ...state, isInitializing: true }
    }
    case atypes.ADSET_INIT_SUCCESS:
    case atypes.ADSET_INIT_ERROR: {
      return { ...state, isInitializing: false }
    }
    case atypes.ADSET_ESTIMATE_REQUEST: {
      return { ...state, isEstimateLoading: true }
    }
    case atypes.ADSET_ESTIMATE_RESPONSE: {
      return { ...state, isEstimateLoading: false, estimate: action.data.estimate }
    }
    case atypes.ADSET_ESTIMATE_ERROR: {
      return { ...state, estimate: null, isEstimateLoading: false }
    }
    case atypes.ADSET_PUBLISH_REQUEST: {
      return { ...state, isPublishing: true }
    }
    case atypes.ADSET_PUBLISH_RESPONSE: {
      return { ...state, isPublishing: false }
    }
    case atypes.ADSET_PUBLISH_ERROR: {
      return { ...state, isPublishing: false }
    }
    case atypes.ADSET_CREATE_REQUEST: {
      return { ...state, isCreating: true }
    }
    case atypes.ADSET_CREATE_RESPONSE: {
      return { ...state, isCreating: false }
    }
    case atypes.ADSET_CREATE_ERROR: {
      return { ...state, isCreating: false }
    }
    case atypes.ADSET_PLATFORMS_FETCH_RESPONSE: {
      const { data, deviceType } = action
      return {
        ...state,
        platformsData: {
          ...state.platformsData,
          [deviceType]: parseDevicePlatforms(deviceType, data),
        },
      }
    }
    case atypes.ADSET_INIT_INTERACTION_TYPE:
    case atypes.ADSET_UPDATE_INTERACTION_TYPE: {
      return { ...state, interactionType: action.interactionType }
    }
  }

  return state
}

import { Keyword } from 'apps/keywords/keywordsInterfaces'
import { KeywordStatus } from 'apps/keywords/models/KeywordRule/constants'
import { TriggerType } from 'common/flow/constants/TriggerType'
import { ITriggerId } from 'common/flow/flowTriggersInterfaces'
import { ProtoKeyword } from 'shared/api/requests/aiAssistant/schemas'

export const toKeyword = (trigger: ProtoKeyword): Keyword => {
  const id = 0
  const triggerId: ITriggerId = `${TriggerType.KEYWORD}-${id}`

  return {
    id,
    triggerType: TriggerType.KEYWORD,
    triggerId: triggerId,
    flowId: '',
    chId: null,
    enabled: true,
    caption: '',
    content_holder: '',
    hasChanges: false,
    hash: '',
    is_reserved: false,
    namespace: '',
    sort: 0,
    $deleting: false,
    fetching: false,
    rule_id: 0,
    flow: null,
    status: KeywordStatus.LIVE,
    template_installation_id: null,
    channel: trigger.channel,
    ts_created: 0,
    ts_updated: 0,
    keyword_rules: trigger.keywords || [],
  }
}

import React from 'react'
import cx from 'classnames'
import * as Icon from '@manychat/icons'

import { IconNames, LinkProps } from './interfaces'

import cm from './Link.module.css'

export const Link = ({
  href,
  rel = 'noreferrer',
  variant = 'body',
  contentColor = 'default',
  id,
  className,
  children,
  leftIcon,
  rightIcon,
  underline = false,
  ...props
}: LinkProps) => {
  const LeftIconComponent = leftIcon ? Icon[leftIcon as IconNames] : null
  const RightIconComponent = rightIcon ? Icon[rightIcon as IconNames] : null

  const linkClasses = cx(
    cm.link,
    {
      [cm.subheading]: variant === 'subheading',
      [cm.body]: variant === 'body',
      [cm.bodyAccented]: variant === 'body-accented',
      [cm.small]: variant === 'small',
      [cm.white]: contentColor === 'white',
      [cm.subtle]: contentColor === 'subtle',
      [cm.underline]: underline,
    },
    className,
  )

  return (
    <a href={href} rel={rel} id={id} className={linkClasses} {...props}>
      {LeftIconComponent && <LeftIconComponent className={cm.icon} />}
      <span className={cm.linkText}>{children}</span>
      {RightIconComponent && <RightIconComponent className={cm.icon} />}
    </a>
  )
}

import flatten from 'lodash/flatten'
import sortBy from 'lodash/sortBy'
import values from 'lodash/values'
import { createSelector } from 'reselect'
import { l } from '@manychat/manyui'

import { AiIntentTrigger } from 'apps/aiIntents/interfaces/interfaces'
import { parseExternalTriggerContext } from 'apps/externalTriggers/externalTriggerAdapter'
import { IExternalTrigger } from 'apps/externalTriggers/externalTriggerInterfaces'
import {
  FeedCommentTriggerWidgetData,
  IAdsJSONWidget,
  IBackendWidgetLight,
  IWidget,
} from 'apps/growthTools/growthToolsInterfaces'
import { PostCoveredArea, WidgetTypes } from 'apps/growthTools/models/Widget/constants'
import { isSystem } from 'apps/keywords/models/KeywordRule/helpers'
import { TriggerRuleStatusType } from 'apps/triggerRules/models/TriggerRule/constants'
import { ITriggerRule } from 'apps/triggerRules/triggerRulesInterfaces'
import { NodeType } from 'common/builder/constants/NodeType'
import { ChannelType } from 'common/core/constants/ChannelType'
import { getApplicationById } from 'common/core/selectors/appSelectors'
import { TriggerType } from 'common/flow/constants/TriggerType'
import { FlowChannelTypeKeys } from 'common/flow/flowConstants'
import { isFullFlow } from 'common/flow/flowHelpers'
import { IFlow, FlowContentHolderOption, IFlowTrigger, IFullFlow } from 'common/flow/flowInterfaces'
import { ITriggerId } from 'common/flow/flowTriggersInterfaces'
import { FlowChannels } from 'common/flow/interfaces/channels'
import { isInstagramCgt } from 'common/flow/utils/isInstagramCgt'

export const getById = (state: RootState, flowId?: string | null): IFlow | undefined => {
  if (!flowId) {
    return
  }
  return state.flow.byId[flowId]
}

export const getList = (state: RootState) => {
  return values(state.flow.byId)
}

export const getFlowContext = (state: RootState, flowId: string) => {
  const externalTriggers = getFlowExternalTriggers(state, flowId)
  const contexts = externalTriggers.map((trigger: IExternalTrigger) => {
    const application = getApplicationById(trigger.appId)(state)
    return parseExternalTriggerContext(
      trigger.context,
      trigger.externalTriggerTypeName,
      application,
    )
  })
  return flatten(contexts)
}

export const getFlowExternalTriggers = (state: RootState, flowId: string): IExternalTrigger[] => {
  return getFlowTriggers(state, flowId).filter(
    (t) => t.triggerType === TriggerType.EXTERNAL_TRIGGER,
  ) as IExternalTrigger[]
}

export const getIsFlowPublished = (state: RootState, flowId: string) =>
  Boolean(getById(state, flowId)?.has_published_content)

export const getFlowTriggers = (state: RootState, flowId: string): IFlowTrigger[] => {
  const flow = getById(state, flowId)
  if (!isFullFlow(flow)) {
    return []
  }

  return flow.triggers
}

export const getTriggersOnlyUsedChannel = (
  state: RootState,
  flowId: string,
): ChannelType | null => {
  const triggersWithChannel = getFlowTriggers(state, flowId).filter(
    (trigger) => 'channel' in trigger && trigger.channel,
  )

  if (triggersWithChannel.length === 0) {
    return null
  }

  let result = null

  for (const trigger of triggersWithChannel) {
    if (result !== null && result !== (trigger as { channel: ChannelType }).channel) {
      return null
    }

    result = (trigger as { channel: ChannelType }).channel
  }

  return result
}

export type ActivatableTrigger = IWidget | ITriggerRule | AiIntentTrigger | IExternalTrigger

export const preActivatableTriggersTypes = [
  TriggerType.WIDGET,
  TriggerType.RULE,
  TriggerType.AI_INTENT,
  TriggerType.EXTERNAL_TRIGGER,
]

export const getPreActivatableTriggers = (
  state: RootState,
  flowId: string,
): ActivatableTrigger[] => {
  const triggers = getFlowTriggers(state, flowId)

  return triggers.filter((trigger): trigger is ActivatableTrigger => {
    let isPreActivatableTrigger =
      preActivatableTriggersTypes.includes(trigger.triggerType) &&
      'was_activated_once' in trigger &&
      !trigger.was_activated_once &&
      !trigger.enabled

    if (
      trigger.triggerType === TriggerType.RULE &&
      trigger.status !== TriggerRuleStatusType.INACTIVE
    ) {
      isPreActivatableTrigger = false
    }

    return isPreActivatableTrigger
  })
}

export const getSortedFlowTriggers = (state: RootState, flowId: string): IFlowTrigger[] => {
  return sortBy(getFlowTriggers(state, flowId), (trigger) => trigger.ts_created)
}

export const getFlowWidgetTriggers = (state: RootState, flowId: string): IWidget[] => {
  return getFlowTriggers(state, flowId).filter(
    (t) => t.triggerType === TriggerType.WIDGET,
  ) as IWidget[]
}

export const hasSystemKeywordTrigger = (state: RootState, flowId?: string): boolean => {
  if (!flowId) {
    return false
  }

  const triggers = getFlowTriggers(state, flowId)
  return triggers.some((t) => t.triggerType === TriggerType.KEYWORD && isSystem(t))
}

export const getJsonWidget = (state: RootState, flowId: string): IAdsJSONWidget | undefined => {
  const widgets = getFlowWidgetTriggers(state, flowId)
  return widgets.find((widget) => widget.widget_type === WidgetTypes.ADS_JSON) as IAdsJSONWidget
}

export const getFlowWidgetsByType = (state: RootState, flowId: string, type: WidgetTypes) => {
  return getFlowWidgetTriggers(state, flowId).filter((widget) => widget.widget_type === type)
}

export const getNewCGTList = (state: RootState, flowId: string): IWidget[] => {
  return getFlowWidgetTriggers(state, flowId).filter(
    (t) =>
      t.widget_type === WidgetTypes.FEED_COMMENT_TRIGGER ||
      t.widget_type === WidgetTypes.INSTAGRAM_LIVE_COMMENT_REPLY,
  )
}

export const hasInstagramCGT = (state: RootState, flowId: string): boolean => {
  const cgtTriggers = getFlowWidgetTriggers(state, flowId).filter(isInstagramCgt)

  return Boolean(cgtTriggers.length)
}

export const isStatByJSONAdsTrigger = (state: RootState, flowId: string): boolean => {
  const flow = getById(state, flowId)
  if (!isFullFlow(flow)) {
    return false
  }
  if (!flow.chId) {
    return false
  }
  const contentHolderTrigger = flow.triggers.find((t) => 'chId' in t && t.chId === flow.chId)
  return Boolean(
    contentHolderTrigger &&
      'widget_type' in contentHolderTrigger &&
      (contentHolderTrigger.widget_type === WidgetTypes.ADS_JSON ||
        contentHolderTrigger.widget_type === WidgetTypes.ADS),
  )
}

const TriggerLabels = {
  ALL_TRIGGERS: l.makeTranslate('All triggers'),
  MAIN_MENU_ITEM: l.makeTranslate('Main menu item'),
}

const getDefaultOption = () => ({
  value: null,
  label: TriggerLabels.ALL_TRIGGERS(),
  trigger: null,
  selected: false,
})

export const getFlowContentHolderOptions = createSelector(
  [
    (state: RootState, flowId: string) => getById(state, flowId) as IFullFlow,
    (state: RootState, flowId: string) => getSortedFlowTriggers(state, flowId),
  ],
  (flow, flowTriggers) => {
    const contentHolderTriggers = flowTriggers.filter(
      (trigger) => 'chId' in trigger && trigger.chId,
    )

    const contentHolderOptions: FlowContentHolderOption[] = contentHolderTriggers.map(
      (trigger) => ({
        label: trigger.caption,
        value: 'chId' in trigger && trigger.chId ? trigger.chId : null,
        trigger: trigger,
        selected: false,
      }),
    )

    const options: FlowContentHolderOption[] = [getDefaultOption(), ...contentHolderOptions]

    const currentContentHolderOption = contentHolderOptions.find(
      (option) => option.value === flow.chId,
    )

    if (flow.chId && !currentContentHolderOption) {
      const unknownCurrentOption = {
        value: flow.chId,
        label: flow.chId?.startsWith('mainmenu') ? TriggerLabels.MAIN_MENU_ITEM() : flow.chId,
        trigger: null,
        selected: false,
      }
      options.push(unknownCurrentOption)
    }

    return options.map((option) =>
      option.value === flow.chId ? { ...option, selected: true } : option,
    )
  },
)

export const getFlowTriggerByTriggerId = (
  state: RootState,
  flowId: string,
  triggerId: ITriggerId,
): IFlowTrigger | null => {
  return getFlowTriggers(state, flowId).find((t) => t.triggerId === triggerId) ?? null
}

export const getFlowContentChannels = (state: RootState, flowId: string) => {
  const flow = getById(state, flowId)

  return flow
    ? FlowChannels.filter((flowChannel) => {
        const key = FlowChannelTypeKeys[flowChannel]

        // eslint-disable-next-line
        // @ts-expect-error
        return flow[key]
      })
    : []
}

export const getFlowPrimaryNodeType = (state: RootState, flowId: string): NodeType | null => {
  const flowTriggers = getFlowTriggers(state, flowId)
  const trigger = flowTriggers.find((t) =>
    [
      TriggerType.KEYWORD,
      TriggerType.AI_INTENT,
      TriggerType.DEFAULT_REPLY,
      TriggerType.CART_REPLY,
      TriggerType.WELCOME_MESSAGE,
      TriggerType.STORY_MENTION_REPLY,
    ].includes(t.triggerType),
  )
  if (!trigger || !('channel' in trigger)) {
    return null
  }
  if (trigger.channel === ChannelType.INSTAGRAM) {
    return NodeType.INSTAGRAM
  }
  if (trigger.channel === ChannelType.WHATSAPP) {
    return NodeType.WHATS_APP
  }
  if (trigger.channel === ChannelType.SMS) {
    return NodeType.SMS
  }
  if (trigger.channel === ChannelType.TELEGRAM) {
    return NodeType.TELEGRAM
  }
  if (trigger.channel === ChannelType.FB) {
    return NodeType.CONTENT
  }
  if (trigger.channel === ChannelType.TIKTOK) {
    return NodeType.TIKTOK
  }

  return null
}

export const getIsInstagramCgtTriggerWithAllPostsCoveredArea = (
  state: RootState,
  flowId: string,
  widgetId: number,
) => {
  const flow = getById(state, flowId) as IFullFlow

  if (!flow?.triggers) return false

  const trigger = (flow.triggers as IBackendWidgetLight[]).find(
    ({ widget_id }) => widgetId === widget_id,
  )

  if (
    !trigger ||
    !trigger.data ||
    trigger.channel !== ChannelType.INSTAGRAM ||
    trigger.widget_type !== WidgetTypes.FEED_COMMENT_TRIGGER
  ) {
    return false
  }

  const postCoveredArea = (trigger.data as FeedCommentTriggerWidgetData)?.feed_comment_settings
    ?.post_covered_area

  if (!postCoveredArea) return false

  return postCoveredArea === PostCoveredArea.ALL_POSTS
}

export const getQuickCampaignIdByFlowId = (state: RootState, flowId: string) => {
  const flow = getById(state, flowId)

  return flow?.quick_campaign_data?.quick_campaign_id
}

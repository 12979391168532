export const SCRIPT_ID = 'extvisit-js'
export const LEGACY_CLIENT_ID_REGEX = /(?:(?:^|.*;\s*)clientId\s*\=\s*([^;]*).*$)|^.*$/
export const STORED_CLIENT_ID_REGEX = /(?:(?:^|.*;\s*)mchtClientId\s*\=\s*([^;]*).*$)|^.*$/
export const REPLACED_VALUE = '$1'

const BASE_URI = 'https://manychat.com'
const PIXEL_PATH = '/analytics/png'
const WHO_AMI_PATH = '/analytics/whoami'

export const getWhoAmiUrl = ({ cookieLegacyClientId }: { cookieLegacyClientId: string }) => {
  const whoAmiURI = new URL(BASE_URI + WHO_AMI_PATH)
  whoAmiURI.searchParams.set('location', location.href)
  whoAmiURI.searchParams.set('referer', document.referrer)
  whoAmiURI.searchParams.set('legacyClientId', cookieLegacyClientId)
  whoAmiURI.searchParams.set('ts', String(new Date().getTime()))
  return whoAmiURI
}

export const getPixelPathUrl = ({
  url,
  clientId,
  cookieLegacyClientId,
  currentPath,
}: {
  url?: URL
  clientId: string
  cookieLegacyClientId: string
  currentPath?: string
}) => {
  const pixelPathURI = url ?? new URL(BASE_URI + PIXEL_PATH)

  pixelPathURI.searchParams.set('visitorId', clientId)
  pixelPathURI.searchParams.set('location', currentPath ?? location.href)
  pixelPathURI.searchParams.set('ts', String(new Date().getTime()))
  pixelPathURI.searchParams.set('legacyClientId', cookieLegacyClientId)

  if (!url) {
    pixelPathURI.searchParams.set('referer', document.referrer)
  }

  return pixelPathURI
}

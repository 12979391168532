import { IMessengerRefURLWidget } from 'apps/growthTools/growthToolsInterfaces'
import { BaseWidget } from 'apps/growthTools/models/Widget/baseWidget'
import { WidgetTypes } from 'apps/growthTools/models/Widget/constants'
import { TriggerType } from 'common/flow/constants/TriggerType'
import { ITriggerId } from 'common/flow/flowTriggersInterfaces'
import { ProtoRefUrl } from 'shared/api/requests/aiAssistant/schemas'

export const toRefUrlWidget = (trigger: ProtoRefUrl): IMessengerRefURLWidget => {
  const id = 5
  const triggerId: ITriggerId = `${TriggerType.WIDGET}-${id}`

  return {
    ...BaseWidget,
    widget_id: id,
    widget_type: WidgetTypes.MESSENGER_REF_URL,
    channel: trigger.channel,
    data: {
      setup: {
        ref: '',
      },
    },
    id,
    triggerType: TriggerType.WIDGET,
    triggerId: triggerId,
    caption: trigger.caption || '',
  }
}

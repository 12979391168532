import { MappedIceBreaker } from 'apps/iceBreakers/constants/constants'
import { IceBreakerStatus, PublishReadyIceBreaker } from 'shared/api/requests/iceBreakers/schemas'

export const exportItem = (item: MappedIceBreaker, index: number): PublishReadyIceBreaker => {
  return {
    icebreaker_id: item.icebreaker_id || null,
    caption: item.caption || '',
    sort_id: item.sort_id || index,
    status: exportIceBreakerStatus(item.status),
    flow_ns: item.flow?.id,
  }
}

export const exportItems = (data: readonly MappedIceBreaker[]): PublishReadyIceBreaker[] => {
  if (Array.isArray(data)) {
    return data.map(exportItem)
  }
  return []
}

export const exportIceBreakerStatus = (status: IceBreakerStatus) => {
  if (status === IceBreakerStatus.DELETED) {
    return IceBreakerStatus.DELETED
  }
  return IceBreakerStatus.ACTIVE
}

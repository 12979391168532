import { z } from 'zod'

import { AddOnSlug, PlanSlug } from '../constants'

import { ProductAddOnSchema, ProductPlanSchema, ProductSchema } from './products'

export const CartSchema = z.object({
  /**
   * List of products that are offered for purchase.
   */
  offer: z.array(ProductSchema),
  /**
   * List of products that are selected for purchase,
   */
  cart: z.array(ProductSchema),
  canUpgradeWithTrial: z.boolean(),
  totalPrice: z.number(),

  // methods
  toggleProduct: z
    .function()
    .args(
      // z.object({
      //   type: z.enum(['plan', 'addOn' as const]),
      //   slug: z.nativeEnum(PlanSlug).or(z.nativeEnum(AddOnSlug)),
      // }),
      ProductPlanSchema.pick({ slug: true, type: true }).or(
        ProductAddOnSchema.pick({ slug: true, type: true }),
      ),
    )
    .returns(z.void()),
  upgrade: z.function().args(z.void()).returns(z.promise(z.void())),
  activateTrial: z.function().args(z.void()).returns(z.promise(z.void())),
  showCouponInput: z
    .function()
    .args(
      z.object({
        products: z.array(z.nativeEnum(PlanSlug)),
        addOns: z.array(z.nativeEnum(AddOnSlug)),
      }),
    )
    .returns(z.void()),
})

export type Cart = z.infer<typeof CartSchema>

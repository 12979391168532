export enum ContentManagementSystemSocketEvents {
  FS_FLOW_DELETED = 'fs_flow_deleted',
  FS_FLOW_RESTORED = 'fs_flow_restored',
  FS_FLOW_PERMANENTLY_DELETED = 'fs_flow_permanently_deleted',
  FS_FLOW_RENAMED = 'fs_flow_renamed',
  FS_FLOW_CREATED = 'fs_flow_created',
  FS_FLOW_MOVED = 'fs_flow_moved',

  FOLDER_CREATED = 'folder_created',
  FOLDER_DELETED = 'folder_deleted',
  FOLDER_RENAMED = 'folder_renamed',
  FOLDER_MOVED = 'folder_moved',

  EB_FLOW_CREATED = 'eb_flow_created',
}

export const namespace = 'contentManagementSystem'

export enum SliceType {
  SMART_LINK = 'smart_link',
  VARIABLE = 'variable',
  VARIABLE_INPUT = 'variable_input',
  LINK = 'link',
  URL = 'url',

  BOLD = 'bold',
  ITALIC = 'italic',
  UNDERLINE = 'underline',
  TEXT_COLOR = 'text_color',
}

export const DEFAULT_REPLY_LOAD = 'DEFAULT_REPLY_LOAD'
export const DEFAULT_REPLY_LOAD_REQUEST = 'DEFAULT_REPLY_LOAD_REQUEST'
export const DEFAULT_REPLY_LOAD_RESPONSE = 'DEFAULT_REPLY_LOAD_RESPONSE'
export const DEFAULT_REPLY_LOAD_ERROR = 'DEFAULT_REPLY_LOAD_ERROR'

export const DEFAULT_REPLY_UPDATE_ENABLED = 'DEFAULT_REPLY_UPDATE_ENABLED'
export const DEFAULT_REPLY_UPDATE_ENABLED_REQUEST = 'DEFAULT_REPLY_UPDATE_ENABLED_REQUEST'
export const DEFAULT_REPLY_UPDATE_ENABLED_RESPONSE = 'DEFAULT_REPLY_UPDATE_ENABLED_RESPONSE'
export const DEFAULT_REPLY_UPDATE_ENABLED_ERROR = 'DEFAULT_REPLY_UPDATE_ENABLED_ERROR'

export const DEFAULT_REPLY_SET_FLOW = 'DEFAULT_REPLY_SET_FLOW'
export const DEFAULT_REPLY_SET_FLOW_REQUEST = 'DEFAULT_REPLY_SET_FLOW_REQUEST'
export const DEFAULT_REPLY_SET_FLOW_RESPONSE = 'DEFAULT_REPLY_SET_FLOW_RESPONSE'
export const DEFAULT_REPLY_CHANGE_TYPE = 'DEFAULT_REPLY_CHANGE_TYPE'
export const DEFAULT_REPLY_CHANGE_SOURCES = 'DEFAULT_REPLY_CHANGE_SOURCES'
export const DEFAULT_REPLY_CHANGE_SOURCES_RESPONSE = 'DEFAULT_REPLY_CHANGE_SOURCES_RESPONSE'
export const DEFAULT_REPLY_CHANGE_TYPE_RESPONSE = 'DEFAULT_REPLY_CHANGE_TYPE_RESPONSE'
export const DEFAULT_REPLY_UPDATED_NOTIFICATION = 'DEFAULT_REPLY_UPDATED_NOTIFICATION'
export const DEFAULT_REPLY_REMOVE_FLOW = 'DEFAULT_REPLY_REMOVE_FLOW'
export const DEFAULT_REPLY_REMOVE_FLOW_RESPONSE = 'DEFAULT_REPLY_REMOVE_FLOW_RESPONSE'
export const DEFAULT_REPLY_GET_ALL_DATA = 'DEFAULT_REPLY_ALL_LOAD'
export const DEFAULT_REPLY_GET_ALL_DATA_RESPONSE = 'DEFAULT_REPLY_ALL_LOAD_RESPONSE'
export const DEFAULT_REPLY_UPDATE_BY_CHANNEL = 'DEFAULT_REPLY_UPDATE_BY_CHANNEL'

enum AttachmentDestination {
  DEFAULT = 'default',
  MMS = 'mms',
  TELEGRAM = 'tg',
  TELEGRAM_FILE = 'tg_file',
  WHATSAPP = 'wa',
  WHATSAPP_FILE = 'wa_file',
  TG_VIDEO_NOTE = 'tg_video_note',
}

export default AttachmentDestination

enum InitialNodesPresetType {
  MESSENGER = 'messenger',
  MESSENGER_WIZARD = 'messenger_wizard',
  EMAIL = 'email',
  EMAIL_NEW = 'email_new',
  SMS = 'sms',
  TELEGRAM = 'telegram',
  INSTAGRAM = 'instagram',
  INSTAGRAM_WIZARD = 'instagram_wizard',
  WHATSAPP = 'whatsapp',
  MULTI_CHANNEL = 'multichannel',
}

export default InitialNodesPresetType

import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'

import { userCustomEventsSelector } from '../../selectors/targetPageSelectors'

import { CustomEventPicker } from './CustomEventPicker'

import cm from './TargetCustomEventPicker.module.css'

class TargetPageCustomEventPicker extends React.Component {
  static propsTypes = {
    currentEntity: PropTypes.shape({
      meta_type: PropTypes.number.isRequired,
    }),
  }

  static defaultProps = {
    customEvents: [],
  }

  handleRef = (ref) => {
    this.input = ref
  }

  focus = () => {
    if (this.input) {
      this.input.focus()
    }
  }

  blur = () => {
    if (this.input) {
      this.input.blur()
    }
  }

  render() {
    const { currentEntity, customEvents } = this.props

    let _customEvents = customEvents

    if (currentEntity) {
      _customEvents = _customEvents.filter((customEvent) => {
        return customEvent.meta_type === currentEntity.meta_type
      })
    }

    return (
      <CustomEventPicker
        ref={this.handleRef}
        {...this.props}
        customEvents={_customEvents}
        className="p-r-lg"
        theme={{ itemsWrapper: cm.itemsWrapper }}
      />
    )
  }
}

export const TargetPageCustomEventPickerController = connect(
  (state) => {
    return {
      customEvents: userCustomEventsSelector(state),
    }
  },
  null,
  null,
  { forwardRef: true },
)(TargetPageCustomEventPicker)

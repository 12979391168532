import { l } from '@manychat/manyui'

import * as blockHelpers from 'common/builder/blocks/blockHelpers'
import { IBlock } from 'common/builder/blocks/blockInterfaces'
import { BlockType } from 'common/builder/constants/BlockType'
import { NodeType } from 'common/builder/constants/NodeType'
import BlockModel from 'common/builder/models/Block'
import BaseNodeConfig from 'common/builder/nodes/BaseNodeConfig'
import { IInstagramNode, INodeBase } from 'common/builder/nodes/nodeInterfaces'

class InstagramNodeConfig extends BaseNodeConfig<IInstagramNode> {
  nodeType = NodeType.INSTAGRAM

  isPro = false

  getCaption = () => l.translate('Send Message')
  getToolbarCaption = () => l.translate('Instagram')
  getCaptionWithCount = (n: number) =>
    l.translate('{n, plural, one {# Instagram Message} other {# Instagram Messages}}', { n })
  getNextStepCaption = () => l.translate('Instagram')

  icon = 'InstagramChannel'
  color = '#8492A6'
  headerColor = '#F2D4F7'
  stepsWhiteList = [
    NodeType.INSTAGRAM,
    NodeType.ACTION_GROUP,
    NodeType.CONDITION,
    NodeType.SPLIT,
    NodeType.SMART_DELAY,
    NodeType.GOTO,
  ]

  ALLOWED_BLOCK_TYPES = [
    BlockType.TEXT,
    BlockType.ATTACHMENT,
    BlockType.QUICK_REPLY,
    BlockType.FORM_QUESTION,
    BlockType.DELAY,
    BlockType.CARD,
    BlockType.CARDS,
    BlockType.DYNAMIC,
    BlockType.ONE_TIME_NOTIFY_REQUEST,
  ]

  ALLOWED_BLOCK_MAX_COUNT = 101

  getDefaults = (): Omit<IInstagramNode, keyof INodeBase> => ({
    targetId: null,
    reasonId: null,
    messageTag: null,
    privateReply: null,
    onboardingElementId: 'content_node',
    type: 'default',
    $fbMessagingType: undefined,
  })

  getInitialBlocks = (node: IInstagramNode) => {
    return [
      { type: BlockType.TEXT as const },
      { type: BlockType.QUICK_REPLY as const, id: blockHelpers.createQRBlockId(node.id) },
    ]
  }

  isEmpty = ({ node, blocks }: { node: IInstagramNode; blocks: IBlock[] }) => {
    if (node.targetId) {
      return false
    }
    return this.areBlocksEmpty(blocks)
  }

  _isEmptyBlock = (block: IBlock) => {
    return [BlockType.TEXT].includes(block.type) && BlockModel.isEmpty(block)
  }
}

export default new InstagramNodeConfig()

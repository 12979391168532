import React, { useCallback, useMemo, useState } from 'react'
import cx from 'classnames'
import { BtnV2, l, LinkBtn } from '@manychat/manyui'

import { getAITermsText } from '../../constants'
import { useBillingInfo, useCart } from '../../hooks'
import { isProductAddOn, isProductPlan, Product } from '../../schemas'

import cartImg from './assets/cart.svg'
import { Total } from './Total'

import cm from './OrderSummary.module.css'

interface OrderProps {
  cart: Product[]
  footerText: string
  upgradeBtnText: string
}

export const Order = ({ footerText, upgradeBtnText, cart }: OrderProps) => {
  const { isPro, currency } = useBillingInfo()
  const { canUpgradeWithTrial, upgrade, activateTrial, showCouponInput } = useCart()
  const [loading, setLoading] = useState(false)
  const isEmpty = !cart.length
  const planSlugs = useMemo(() => cart.filter(isProductPlan).map((product) => product.slug), [cart])
  const addOnSlugs = useMemo(() => cart.filter(isProductAddOn).map((addOn) => addOn.slug), [cart])
  const showCoupons = Boolean(planSlugs.length)
  const undiscountedPrice = useMemo(
    () => cart.reduce((undiscounted, product) => undiscounted + product.price, 0),
    [cart],
  )

  const handleUpgrade = useCallback(() => {
    const handler = canUpgradeWithTrial ? activateTrial : upgrade
    const result = handler()

    if (result instanceof Promise) {
      setLoading(true)
      result.then(() => {
        setLoading(false)
      })
    }
  }, [canUpgradeWithTrial, upgrade, activateTrial])

  if (isEmpty) {
    return (
      <div
        className="d-flex flex-col align-center justify-center p-t-xl"
        data-test-id="order-empty"
      >
        <img src={cartImg} width={150} height={150} />
        <p className="text-secondary text-center">
          {l.translate('Choose a product to see the order summary')}
        </p>
      </div>
    )
  }

  return (
    <>
      <ul className={cx(cm.orderList, 'm-x-0 p-l-0')} data-test-id="order-list">
        {cart.map((cartItem, idx) => (
          <li
            key={JSON.stringify(cartItem)}
            className={cm.orderListItem}
            data-test-id={`order-product-${idx}`}
          >
            <p>{cartItem.name}</p>
            <b>{l.currency(cartItem.price, currency, 0)}</b>
          </li>
        ))}

        {canUpgradeWithTrial ? (
          <>
            <li className={cm.orderListItem} data-test-id={`order-trial`}>
              <b>{l.translate('14-Day free Trial')}</b>
              <b>{l.currency(0, currency, 0)}</b>
            </li>
            <li className={cm.orderListItem} data-test-id={`order-after-trial`}>
              <b>{l.translate('After trial period ends')}</b>
              <b>{l.currency(undiscountedPrice, currency, 0)}/mo</b>
            </li>
          </>
        ) : null}
      </ul>

      <footer className={cx(cm.footer, 'd-flex flex-col')}>
        <Total cart={cart} isPro={isPro} />

        <BtnV2
          data-test-id={canUpgradeWithTrial ? 'trial-btn' : 'cta-btn'}
          variant="primary"
          loading={loading}
          onClick={handleUpgrade}
        >
          {upgradeBtnText}
        </BtnV2>

        {showCoupons ? (
          <li className={cx(cm.orderListItem, cm.couponContainer)}>
            <LinkBtn
              fullWidth
              onClick={(event) => {
                event.preventDefault()
                showCouponInput({
                  products: planSlugs,
                  addOns: addOnSlugs,
                })
              }}
            >
              {l.translate('Apply Coupon')}
            </LinkBtn>
          </li>
        ) : null}

        <span className={cx('text-secondary text-sm')}>{footerText}</span>

        {addOnSlugs.length ? (
          <span className="text-secondary text-sm">{getAITermsText()}</span>
        ) : null}
      </footer>
    </>
  )
}

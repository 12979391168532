import { createContext, useContext } from 'react'
import { noop } from '@manychat/manyui'

import { Id } from '../types'

export interface RendererContextType {
  goToNode: (videId: Id) => void
}

export const RendererContext = createContext<RendererContextType>({
  goToNode: noop,
})

export const useRenderer = () => useContext(RendererContext)

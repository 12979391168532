import { LiveChatNoteTypes } from 'apps/chat/constants/LiveChatNoteTypes'
import { ThreadStatus } from 'apps/chat/interfaces/statuses'
import { ChatMessageType } from 'apps/chat/types/chatMessageTypeList'
import { StatusType } from 'common/audience/models/Subscriber'
import { ChannelType } from 'common/core/constants/ChannelType'
import { SnippetSharingType } from 'common/settings/constants/LiveChat'
export const ALL_TEAM_MEMBERS_ID = -1
export const USER_HAVE_NOT_ID = -2
export const DEFAULT_THREAD_LIMIT = 15
export const MAX_NUMBER_OF_HANDOVER_RETRIES = 2

export interface LiveChatSnippet {
  content: string
  snippet_id: number
  title: string
  type_title: SnippetSharingType
}

export interface LiveChatChannel {
  enabled: boolean
  opt_in: boolean
  type: ChannelType
  status: string
}

export enum StatusFilterType {
  OPEN = 'open',
  CLOSED = 'closed',
  ALL = 'all',
}

export enum FilterTypes {
  STATUS = 'status',
  TAGS_AND_CUFS = 'tags_and_cufs',
}

export enum Filter {
  CONVERSATIONS = 'conversations',
  TEAM_MEMBER = 'team_member',
  CHANNEL = 'channel',
}

export enum ThreadsSortingTypes {
  NEWEST = 'newest',
  OLDEST = 'oldest',
}

export enum ConversationFilterType {
  ALL = 'all',
  UNASSIGNED = 'unassigned',
  TEAM = 'team',
  YOU = 'you',
}

export interface ThreadAssignment {
  user_id: number
  name: string
  avatar: string
}

export enum ChatThreadUserGender {
  MALE = 'male',
  FEMALE = 'female',
}

export enum ChatMessageDeliveryStatus {
  READ = 'read',
  SENT = 'sent',
}

export interface ChatThreadUser {
  user_id: string
  wa_id: string | null
  ig_id: string | null
  tt_user_id: string | null
  source_app_id: number | null

  ts_added: number
  raw_ts_added: number
  ts_subscribed: number
  ts_automation_paused_until: number | null
  ts_automation_paused_forever: boolean | null

  name: string | null
  title: string | null
  avatar: string | null
  gender: ChatThreadUserGender | null
  phone: string | null
  phone_country_code: string | null
  email: string | null
  optin_source: number | null
  ig_username: string | null
  tg_username: string | null
  tt_username: string | null
  is_us_wa_number?: boolean

  // check fields with backend - not all appear in swagger
  status: StatusType
  fb_status?: StatusType
  tg_status?: StatusType
  ig_status?: StatusType
  wa_status?: StatusType
  tt_status?: StatusType

  optin_phone: boolean | null
  optin_email: boolean | null
  optin_ig: boolean | null
  optin_tg: boolean | null
  optin_fb?: boolean | null
  optin_wa: boolean | null
  optin_tt: boolean | null
  has_question_context: boolean

  // check these fields with backend
  tg_importing?: boolean | null
  phone_us_state?: boolean | null
}

export interface LiveChatThread<ChatLastEvent = UnsafeAnyObject> {
  timestamp: number

  id: string
  page_id: string
  user_id: string
  sort_id: number

  lc_thread_status: ThreadStatus
  /** is last message read by subscriber */
  lc_thread_read: boolean | null
  /** is last message read by user */
  lc_thread_user_read: boolean | null

  /** 24-hour window */
  messaging_is_limited: boolean

  last_lc_read_by_user_event_id: number | null
  last_interaction_fb: string
  last_lc_event_channel: string | null
  last_lc_event: ChatLastEvent | null

  user: ChatThreadUser
  assignment: ThreadAssignment | null
  channels: LiveChatChannel[]
  notes?: LiveChatNote[]
  score_rating?: number
}

export interface PauseAutomationsData {
  id: string
  pausedUntil?: ChatThreadUser['ts_automation_paused_until']
  isPausedForever?: ChatThreadUser['ts_automation_paused_forever']
}

export interface LiveChatNote {
  message_id: string
  model: {
    _oid: string
    messages?: NoteMessage[]
    sender?: Sender
    mentions?: number[]
  }
  page_id: string
  sort_id: number
  timestamp: number
  type: LiveChatNoteTypes
  user_id: number
}

export interface ThreadScore {
  scoringThreshold?: number
  scoreRating?: number
  isScoringThread: boolean
}

export interface NoteMessage {
  _oid: string
  type: 'text' | 'attachment'
  content: {
    text?: string
    type?: string
    data?: NoteImage | NoteFile
  }
}

export interface BaseSocketEvent<EventModel> {
  message_id: string
  page_id: string
  type: ChatMessageType
  user_id: string
  sort_id: number
  timestamp: number
  model: EventModel
}

export type MsginEvent = BaseSocketEvent<{
  type: string
  text: string
}>

export type AssignEvent = BaseSocketEvent<{
  from_user_id: null
  from_user_name: null
  to_user_id: number
  to_user_name: string
}>

export type UserThreadNewEvent = BaseSocketEvent<{
  before: string
}>

export type UnassignEvent = BaseSocketEvent<{
  from_user_id: number
  from_user_name: string
  to_user_id: null
  to_user_name: null
}>

export interface NoteImage {
  caid: number
  image_size: { width: number; height: number; size: number }
  img_big: string
  img_original: string
  img_small: string
  sign: string
  title: string
  type: 'image'
}

export interface NoteFile {
  caid: number
  file: string
  mime: string
  sign: string
  title: string
  type: 'file'
}

export interface Sender {
  user_id: number
  user_name: string
  avatar: string
  profile_id?: number
}

export enum ChannelStatusTypes {
  OK = 'ok',
  NO_OPT_IN = 'noOptIn',
  TIME_OUT = 'timedOut',
  MESSAGESLIMIT = 'messagesLimit',
}

export const OptInByChannelFilter: Record<ChannelType, string | null> = {
  [ChannelType.INSTAGRAM]: 'optin_instagram',
  [ChannelType.FB]: 'messenger',
  [ChannelType.WHATSAPP]: 'optin_whatsapp',
  [ChannelType.EMAIL]: 'optin_email',
  [ChannelType.SMS]: 'optin_phone',
  [ChannelType.TELEGRAM]: 'optin_telegram',
  [ChannelType.TIKTOK]: 'optin_tiktok',
  [ChannelType.GUEST]: null,
}

export enum LiveChatBulkActionTypes {
  ASSIGN_THREADS = 'assign_user_threads',
  UNASSIGN_THREADS = 'unassign_user_threads',
  CLOSE_THREADS = 'close_user_threads',
  OPEN_THREADS = 'open_user_threads',
}

export enum SocketBulkActionsTypes {
  ASSIGN = 'assign',
  UNASSIGN = 'unassign',
  CLOSE = 'close',
  REOPEN = 'reopen',
}

export enum SelectionType {
  EXCLUDE = 'exclude',
  INCLUDE = 'include',
}

export enum HandoverErrorType {
  PRIMARY_THREAD_OWNERSHIP = 'primary_thread_ownership_error',
  SECONDARY_THREAD_OWNERSHIP = 'secondary_thread_ownership_error',
}

export enum ThreadControlErrorType {
  CR_FB = 'conversation_routing_meta_owner_error',
  CR_THIRD_PARTY = 'conversation_routing_other_app_owner_error',
  CR_UNKNOWN = 'conversation_routing_unknown_error',
  CR_HUMAN_TAG_UNAVAILABLE = 'conversation_routing_human_tag_unavailable_error',
  HANDOVER_PRIMARY_THREAD_OWNERSHIP = 'primary_thread_ownership_error',
  HANDOVER_SECONDARY_THREAD_OWNERSHIP = 'secondary_thread_ownership_error',
}

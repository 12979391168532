import React from 'react'
import cx from 'classnames'
import { useAppSelector } from 'reduxTyped'
import { l } from '@manychat/manyui'

import { contentManagementSelectors } from 'apps/cms/store'

import { FlowPickerPreviewContent } from './FlowPickerPreviewContent'

import cm from './FlowPickerPreview.module.css'

export const FlowPickerPreview = () => {
  const lastSelectedFlowId = useAppSelector(contentManagementSelectors.getLastSelectedFlowId)

  return (
    <div
      className={cx(
        'flex-shrink-0 flex-col align-center justify-center o-hidden p-relative',
        cm.wrapper,
        { [cm.flowSelected]: lastSelectedFlowId },
      )}
    >
      {lastSelectedFlowId ? (
        <FlowPickerPreviewContent lastSelectedFlowId={lastSelectedFlowId} />
      ) : (
        <div className="text-center">
          <div className="text-subheading m-b-xxs">{l.translate('Preview Automation')}</div>
          <div className="text-secondary">
            {l.translate('Select any Automation with a published version to see a preview')}
          </div>
        </div>
      )}
    </div>
  )
}

import { ThreadStatus } from 'apps/chat/interfaces/statuses'

import { ConversationFilterType, StatusFilterType } from './LiveChatInterfaces'

export const ThreadStatusesByStatusFilterAndFolder: Record<
  ConversationFilterType,
  Record<StatusFilterType, ThreadStatus[]>
> = {
  [ConversationFilterType.UNASSIGNED]: {
    [StatusFilterType.OPEN]: [ThreadStatus.NEW],
    [StatusFilterType.CLOSED]: [ThreadStatus.CLOSED],
    [StatusFilterType.ALL]: [ThreadStatus.NEW, ThreadStatus.CLOSED],
  },
  [ConversationFilterType.YOU]: {
    [StatusFilterType.OPEN]: [ThreadStatus.OPENED],
    [StatusFilterType.CLOSED]: [ThreadStatus.CLOSED],
    [StatusFilterType.ALL]: [ThreadStatus.OPENED, ThreadStatus.CLOSED],
  },
  [ConversationFilterType.TEAM]: {
    [StatusFilterType.OPEN]: [ThreadStatus.OPENED],
    [StatusFilterType.CLOSED]: [ThreadStatus.CLOSED],
    [StatusFilterType.ALL]: [ThreadStatus.OPENED, ThreadStatus.CLOSED],
  },
  [ConversationFilterType.ALL]: {
    [StatusFilterType.OPEN]: [ThreadStatus.OPENED, ThreadStatus.NEW],
    [StatusFilterType.CLOSED]: [ThreadStatus.CLOSED],
    [StatusFilterType.ALL]: [ThreadStatus.OPENED, ThreadStatus.CLOSED, ThreadStatus.NEW],
  },
}

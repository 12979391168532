import { updateThreadStatus } from 'apps/chat/actions/threadsActions'
import {
  ALL_TEAM_MEMBERS_ID,
  AssignEvent,
  LiveChatThread,
  UserThreadNewEvent,
  ChatThreadUser,
} from 'apps/chat/constants/LiveChatInterfaces'
import * as actionTypes from 'apps/chat/constants/LiveChatReduxActionTypes'
import { ThreadStatus } from 'apps/chat/interfaces/statuses'
import { setThreadAssignedToMe, setThreadUnassigned } from 'apps/chat/redux/notificationsSlice'
import {
  getTeamMemberFilter,
  getThreads,
  getThreadStatusesByStatusFilterAndFolder,
} from 'apps/chat/selectors/threadSelectors'
import { ChatMessageType } from 'apps/chat/types/chatMessageTypeList'
import { IThunkAction } from 'common/core/interfaces/actions'
import { getUserId } from 'common/core/selectors/appSelectors'
import { navigatePollyfill } from 'utils/router/tools'
import { linkURL } from 'utils/url'

export const handleThreadAssignedEvent = (socketData?: AssignEvent): IThunkAction => {
  return (dispatch, getState) => {
    const isSuitableEvent =
      socketData?.type &&
      [ChatMessageType.ASSIGN, ChatMessageType.REASSIGN].includes(socketData.type)

    if (!socketData?.model?.to_user_id || !isSuitableEvent) return

    const state = getState()
    const currentUserId = getUserId(state)
    const assignedUserId = socketData?.model?.to_user_id

    if (currentUserId === assignedUserId) {
      dispatch(setThreadAssignedToMe(Object.assign({}, socketData)))
    }
  }
}

export const handleUserThreadNewEvent = (socketData?: UserThreadNewEvent): IThunkAction => {
  return (dispatch) => {
    if (socketData?.type !== ChatMessageType.USER_THREAD_NEW || !socketData) return

    if (socketData?.model?.before === 'closed') {
      dispatch(setThreadUnassigned(Object.assign({}, socketData)))
      dispatch({ type: actionTypes.THREADS_INCREASE_TOTAL_THREADS_COUNT })
    }
  }
}

export const handleUpdateSubscriberInfo = (socketData?: ChatThreadUser): IThunkAction => {
  return (dispatch) => {
    if (!socketData || !socketData?.user_id) return

    return dispatch({
      type: actionTypes.THREADS_UPDATE_SUBSCRIBER_INFO,
      threadId: socketData?.user_id,
      user: socketData,
    })
  }
}

export function getNextThreadId(
  threads: LiveChatThread[],
  currentThreadId: LiveChatThread['id'],
  initialThreadIndex: number,
) {
  const threadIndex = threads.findIndex((thread) => thread.id === currentThreadId)
  const wasThreadFilteredOut = threadIndex === -1
  let nextThreadIndex

  if (wasThreadFilteredOut) {
    nextThreadIndex = Math.min(initialThreadIndex, threads.length)
  } else if (initialThreadIndex === threads.length - 1) {
    nextThreadIndex = initialThreadIndex - 1
  } else {
    nextThreadIndex = initialThreadIndex + 1
  }

  const safeNextThreadIndex = Math.min(nextThreadIndex, threads.length)

  return threads[safeNextThreadIndex]?.id
}

export const updateThreadStatusAndSelectNextThread = (
  threadId: string,
  status: ThreadStatus,
): IThunkAction => {
  return async (dispatch, getState) => {
    const state = getState()

    const teamMembersFilter = getTeamMemberFilter(state)
    const threads = getThreads(state) || []
    const threadsStatuses = getThreadStatusesByStatusFilterAndFolder(state)
    const initialThreadIndex = threads.findIndex((thread) => thread.id === threadId)

    const response = await dispatch(updateThreadStatus(threadId, status))
    if (!response) return

    let filteredThreads = threads

    if (!threadsStatuses.includes(status)) {
      filteredThreads = filteredThreads.filter((thread) => thread.id !== threadId)
    }

    if (teamMembersFilter && teamMembersFilter !== ALL_TEAM_MEMBERS_ID) {
      filteredThreads = filteredThreads.filter(
        (thread) => thread.assignment?.user_id === teamMembersFilter,
      )
    }

    filteredThreads = filteredThreads.filter((thread) =>
      threadsStatuses.includes(thread.lc_thread_status),
    )

    if (filteredThreads.length) {
      const nextThreadId = getNextThreadId(threads, threadId, initialThreadIndex)

      if (nextThreadId) {
        navigatePollyfill(linkURL(`/chat/${nextThreadId}`))
      }
    }
  }
}

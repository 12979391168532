import { DateUtils } from '@manychat/manyui'

export const getBaseDateRange = () => {
  const start = new Date()
  start.setDate(start.getDate() - 14)

  const end = new Date()

  return [DateUtils.formatDate(start), DateUtils.formatDate(end)]
}

import { createSelector } from '@reduxjs/toolkit'

import { IBusinessEmail } from '../emailInterfaces'

export const getBusinessEmails = createSelector(
  (state: RootState) => state.email,
  (email) => email.businessEmails ?? [],
)

export const getBusinessEmail = createSelector(
  [getBusinessEmails, (_state: RootState, emailId: number | string | null) => emailId],
  (businessEmails, emailId): null | IBusinessEmail => {
    if (businessEmails.length === 0) return null

    return businessEmails.find((email) => email.id === emailId || email.email === emailId) || null
  },
)

export const getDefaultBusinessEmail = (state: RootState) => getBusinessEmail(state, null)

export const getPreferredBusinessEmail = createSelector(
  [getBusinessEmails, getDefaultBusinessEmail],
  (businessEmails, defaultBusinessEmail) => {
    if (businessEmails.length === 0) return null

    return businessEmails.find((email) => email.is_default) || defaultBusinessEmail
  },
)

export const getBusinessEmailsFetchStatus = (state: RootState) =>
  state.email.businessEmailsFetchStatus

import { l } from '@manychat/manyui'

import * as accountAtypes from 'apps/account/accountReduxActionTypes'
import * as dashboardSelectors from 'apps/dashboard/selectors/dashboardSelectors'
import { alert } from 'common/core'
import { updateSkipPreviewModal } from 'common/core/actions/appActions'
import * as API from 'constants/API'
import localStorage from 'utils/localStorage'
import errorTrackingService from 'utils/services/errorTrackingService'

import * as atypes from '../constants/profileReduxActionTypes'

// Magic for fix failed tests
const endpoints = API?.profile?.endpoints

export const setSkipPreviewOnboardingModal = (status) => async (dispatch) => {
  return await dispatch({
    type: atypes.PROFILE_SET_SKIP_PREVIEW_ONBOARDING_MODAL,
    $fetch: [
      endpoints.setSkipPreviewOnboardingModal,
      {
        method: 'POST',
        body: JSON.stringify({
          skip_preview_onboarding_modal: status,
        }),
        headers: {
          'Content-Type': 'application/json',
        },
      },
    ],
    $success: () => dispatch(updateSkipPreviewModal(status)),
  })
}

export const sendVerification = (email) => ({
  type: atypes.PROFILE_VERIFY_EMAIL,
  $fetch: [
    endpoints.sendVerification,
    {
      method: 'POST',
      body: JSON.stringify({
        email,
      }),
      headers: {
        'Content-Type': 'application/json',
      },
    },
  ],
  $success: () => alert(l.translate('Verification link sent'), 'success'),
})

export const sendEmailVerification = (email) => ({
  type: atypes.PROFILE_SEND_EMAIL_VERIFICATION,
  $fetch: [
    endpoints.sendEmailVerification,
    {
      method: 'POST',
      body: JSON.stringify({
        email,
      }),
      headers: {
        'Content-Type': 'application/json',
      },
    },
  ],
  $success: () => alert(l.translate('Verification email sent'), 'success'),
})

export const verifyEmailViaCode = (code) => ({
  type: atypes.PROFILE_VERIFY_EMAIL_VIA_CODE,
  $fetch: [
    endpoints.verifyEmailViaCode,
    {
      method: 'POST',
      body: JSON.stringify({
        code,
      }),
      headers: {
        'Content-Type': 'application/json',
      },
    },
  ],
  $success: () => alert(l.translate('Thank you for verification!'), 'success'),
})

export const checkHasUnverifiedEmail = () => {
  return async (dispatch) => {
    const result = await dispatch({
      type: atypes.PROFILE_CHECK_EMAIL_VERIFY,
      $fetch: [
        endpoints.checkVerification,
        {
          method: 'GET',
          headers: { 'Content-Type': 'application/json' },
        },
      ],
    })

    return Boolean(result?.email_unverified || (!result?.email_unverified && !result?.email))
  }
}

export const confirmEmail = (code) => ({
  type: atypes.PROFILE_CONFIRM_EMAIL,
  $fetch: [
    endpoints.clickConfirm,
    {
      method: 'POST',
      body: JSON.stringify({
        code,
      }),
      headers: { 'Content-Type': 'application/json' },
    },
  ],
  $error: () => {},
})

export function sortPages(sortFunction, reset) {
  return {
    type: atypes.PROFILE_PAGES_SORT,
    data: { sortFunction, reset },
  }
}

export function searchPages(query) {
  return {
    type: atypes.PROFILE_PAGES_SEARCH,
    data: { query },
  }
}

export const setProfileTimezone = (timezone) => {
  return async (dispatch) => {
    try {
      await dispatch({
        type: atypes.PROFILE_SET_TIMEZONE,
        $fetch: [
          API.profile.endpoints.setTimezone,
          {
            method: 'POST',
            body: JSON.stringify({ timezone }),
            headers: { 'Content-Type': 'application/json' },
          },
        ],
        $error: () => {},
      })
    } catch (error) {
      errorTrackingService.trackError(error, {
        extra: { timezone },
        fingerprint: 'setting_bot_timezone',
      })
    }
  }
}

export const setDisplayFlag = (display_flag, value) => ({
  type: atypes.PROFILE_SET_DISPLAY_FLAGS,
  $fetch: [
    API.profile.endpoints.setDisplayFlag,
    {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ display_flag, value }),
    },
  ],
  display_flag,
  value,
})

export const setDisplayFlagIfNeeded = (display_flag, value) => {
  return (dispatch, getState) => {
    const currentValue = dashboardSelectors.getIsDisplayFlagEnabled(getState(), display_flag)
    if (currentValue === value) {
      return
    }
    return dispatch(setDisplayFlag(display_flag, value))
  }
}

export const setCurrentAccountUserDisplayFlag = (display_flag, value) => ({
  type: atypes.CURRENT_ACCOUNT_USER_PROFILE_SET_DISPLAY_FLAGS,
  $fetch: [
    API.accountUser.endpoints.setDisplayFlag,
    {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ display_flag, value }),
    },
  ],
  display_flag,
  value,
})

export const setCurrentAccountUserDisplayFlagIfNeeded = (display_flag, value) => {
  return (dispatch, getState) => {
    const currentValue = dashboardSelectors.getIsCurrentAccountUserDisplayFlagEnabled(
      getState(),
      display_flag,
    )
    if (currentValue === value) {
      return
    }
    return dispatch(setCurrentAccountUserDisplayFlag(display_flag, value))
  }
}

export const fetchMessageTagAnalytics = () => {
  return async (dispatch, getState) => {
    let mockResponse = null
    try {
      mockResponse = JSON.parse(localStorage.getItem('health_flow_mock_data'))
    } catch (e) {}

    await dispatch({
      type: atypes.PROFILE_FETCH_MESSAGE_TAG_ANALYTICS,
      $fetch: [endpoints.fetchMessageTagAnalytics],
      mockResponse,
    })
    return getState().profile.messageTagAnalytics
  }
}

export const setUserMenuVisibility = (opened, options = {}) => {
  return (dispatch) => {
    if (options.scrollTop) {
      if (window.pageYOffset === 0) {
        return dispatch({ type: atypes.PROFILE_SET_USER_MENU_VISIBILITY, opened })
      }

      const scrollHandler = () => {
        if (window.pageYOffset === 0) {
          window.removeEventListener('scroll', scrollHandler)
          dispatch({ type: atypes.PROFILE_SET_USER_MENU_VISIBILITY, opened })
        }
      }

      window.addEventListener('scroll', scrollHandler)
      window.scrollTo({
        top: 0,
        behavior: 'smooth',
      })
    }
    return dispatch({ type: atypes.PROFILE_SET_USER_MENU_VISIBILITY, opened })
  }
}

export const fetchAllAccounts = () => {
  return {
    type: accountAtypes.FETCH_ALL_ACCOUNTS,
    $fetch: endpoints.fetchAllAccounts,
  }
}

export const fetchAccount = (account_id) => {
  return {
    type: accountAtypes.FETCH_ACCOUNT,
    $fetch: endpoints.fetchAccount.replace(':account_id', account_id),
  }
}

import React from 'react'
import * as Icon from '@manychat/icons'

export type IconSizes = 'default' | 'small' | 'large'
export type IconNames = keyof typeof Icon

export interface ActionProps {
  /**The unique identifier of the element. */
  id?: string
  /**Text to be displayed inside the component.*/
  label?: string
  /**Determines the size of the element.*/
  size?: IconSizes
  /**Specifies the button's appearance variant. Each variant changes the button's color and style to signify different actions or states.*/
  variant?:
    | 'primary'
    | 'default'
    | 'success'
    | 'danger'
    | 'danger-light'
    | 'ghost-primary'
    | 'ghost-light'
    | 'ghost-dark'
  /**If true, the element is disabled and cannot be interacted with.*/
  disabled?: boolean
  /**If true, the button will take up the full width of its parent container.*/
  fullWidth?: boolean
  /**If true, indicates that the element is performing an action and cannot be interacted with until the action is complete.*/
  loading?: boolean
  /** This prop adds an icon to the left of the text. Use the name of the icon. **Notice, that full list of icons you can find at the Icon page**. */
  leftIcon?: IconNames | null
  /** This prop adds an icon to the right of the text. Use the name of the icon. **Notice, that full list of icons you can find at the Icon page**. */
  rightIcon?: IconNames | null
  /** This prop displays an icon without text. Use the name of the icon. **Notice, that full list of icons you can find at the Icon page**. */
  onlyIcon?: IconNames | null
  /**The content inside the element, which can be a simple string or more complex React components.*/
  children?: string | React.ReactNode
  /** Custom class names to pass to the component .*/
  className?: string
  /** Element id to describe the button accordingly */
  ariaLabelledBy?: string
  /**Provides an accessible label for the element that is used by screen readers. This is especially important for icon-only buttons or when additional context is necessary for accessibility.*/
  ariaLabel?: string
  /**The tabIndex attribute specifies the tab order of an element. The tab order specifies the order in which elements will receive focus when navigating with the Tab key.*/
  tabIndex?: number
}

export interface BtnV2Props extends ActionProps {
  /**The button's type. */
  type?: 'button' | 'submit' | 'reset'
  /**The ariaExpanded attribute is set on an element to indicate if a control is expanded or collapsed*/
  ariaExpanded?: boolean
  /**Specifies one or more forms the button belongs to. The value of this attribute must be the id attribute of a <form> element in the same document.*/
  form?: string
  /** On Button Click callback.*/
  onClick?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void
  /** On Button Blur callback*/
  onBlur?: (event: React.FocusEvent<HTMLButtonElement, Element>) => void
  /** On Button Focus callback*/
  onFocus?: (event: React.FocusEvent<HTMLButtonElement, Element>) => void
  /** On Button Mouse Down callback*/
  onMouseDown?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void
}

export const sizeMapping: Record<IconSizes, number> = {
  default: 20,
  small: 16,
  large: 24,
}

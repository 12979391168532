const ua = window.navigator.userAgent.toLowerCase()
const isFirefox = ua.indexOf('firefox') >= 0

export const NO_BREAK_SPACE = ' '
export const ZERO_WIDTH_SPACE = '​'
export const ZERO_WIDTH_NON_JOINER = '‌'
export const ZERO_WIDTH_JOINER = '‍'
export const WORD_JOINER = '⁠'
export const THIN_SPACE = ' '
export const HAIR_SPACE = ' '
export const INDEX_CHAR = isFirefox ? WORD_JOINER : ZERO_WIDTH_NON_JOINER

export const SAMPLE_TEXT_1 =
  'Collect {{Coffees_to_get_free_one}} bonus points to grab your FREE cup of coffee! 😉'
export const SAMPLE_TEXT_3 =
  'https://codersblock.com/blog/highlight_text_inside_a/{{ManyChat_API_Token}}/post/'
export const SAMPLE_TEXT_5 = `Hello, {{Pal}}👋\nPlease follow the link: <<url:81c111cb-2905-493a-9a51-e1e93ce7b958|https://manychat.com/fb2236448366585823/cms/files/{{first_name}}/seqmessage20181120102356_147681/edit>>`
export const SAMPLE_TEXT_6 = `Hello, {{Pal}}👋\n<<url:81c111cb-2905-493a-9a51-e1e93ce7b958|https://manychat.com/fb2236448366585823/{{first_name}}/cms/files/seqmessage20181120102356_147681/edit/fs>>`

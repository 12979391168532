import { createBlock } from 'common/builder/actions/createBlock'
import { linkBlock } from 'common/builder/actions/linkBlock'

export const createLinkedBlock = (builderId, nodeId, initial, linkOptions = {}) => {
  return (dispatch) => {
    const blockItem = dispatch(createBlock(builderId, initial))
    dispatch(linkBlock(builderId, nodeId, blockItem.id, linkOptions))
    return blockItem
  }
}

import React from 'react'
import cx from 'classnames'
import { l } from '@manychat/manyui'

import { renderTextByNodes } from 'apps/cms/components/Flows/components/FlowCard/components/Triggers/lib/renderTextByNodes'
import { KeywordRule } from 'apps/keywords/keywordsInterfaces'
import { getKeywordPreviewNodes } from 'apps/keywords/lib/getKeywordPreviewNodes'
import { ConditionTypes, ConditionTypeLabels } from 'apps/keywords/models/KeywordRule/constants'

import cm from './KeywordPreview.module.css'

interface KeywordPreviewProps {
  keywordRules: KeywordRule[]
  wrapperClassName?: string
  keywordsClassName?: string
  mergeKeywordsFromOneGroup?: boolean
  wrapDescription?: boolean
}

export const KeywordPreview = ({
  keywordRules,
  wrapperClassName,
  keywordsClassName,
  mergeKeywordsFromOneGroup = true,
  wrapDescription = false,
}: KeywordPreviewProps) => {
  if (keywordRules.length === 1 && keywordRules[0].condition === ConditionTypes.THUMBS_UP) {
    return (
      <span className={cx(cm.wrapper, cm.wrapper_withThumbsUp, wrapperClassName)}>
        <span>{l.getString(ConditionTypeLabels[ConditionTypes.THUMBS_UP])}</span>
      </span>
    )
  }

  return (
    <span className={cx(cm.wrapper, wrapperClassName)}>
      {getKeywordPreviewNodes(keywordRules).map((node, index) => {
        if (Array.isArray(node)) {
          if (!mergeKeywordsFromOneGroup) {
            return node.map((keyword, keywordIndex) => (
              <span className={cx(cm.keywords, keywordsClassName)} key={keywordIndex}>
                {keyword}
              </span>
            ))
          }

          return (
            <span className={cx(cm.keywords, keywordsClassName)} key={index}>
              {node.join(', ')}
            </span>
          )
        } else {
          const value = index === 0 ? l.getString(node) : l.getString(node).toLowerCase()

          if (wrapDescription) {
            return renderTextByNodes(value)
          }

          return ` ${value} `
        }
      })}
    </span>
  )
}

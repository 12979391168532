import { useCallback, useMemo, useState, useEffect, ChangeEvent } from 'react'
import debounce from 'lodash/debounce'
import { useAppDispatch, useAppSelector } from 'reduxTyped'

import { contentManagementActions, contentManagementSelectors } from 'apps/cms/store'

const DEBOUNCE_TIME = 300

export const useFlowSearch = () => {
  const [searchInput, setSearchInput] = useState('')

  const search = useAppSelector(contentManagementSelectors.getQuerySearch)
  const { isLoaded } = useAppSelector(contentManagementSelectors.getFilters)
  const isRootFolder = useAppSelector(contentManagementSelectors.getIsRootFolder)
  const dispatch = useAppDispatch()

  const debouncedChangeHandler = useMemo(
    () =>
      debounce((searchInput) => {
        dispatch(contentManagementActions.updateQuery({ search: searchInput }))
      }, DEBOUNCE_TIME),
    [dispatch],
  )

  const handleChangeSearch = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      const value = event.target.value
      setSearchInput(value)
      debouncedChangeHandler(value)
    },
    [debouncedChangeHandler],
  )

  const handleResetSearch = useCallback(() => {
    setSearchInput('')
    debouncedChangeHandler.cancel()
    dispatch(contentManagementActions.updateQuery({ search: '' }))
  }, [debouncedChangeHandler, dispatch])

  useEffect(() => {
    if (isLoaded && search) {
      setSearchInput(search)
    }
  }, [isLoaded, search])

  return {
    searchInput,
    handleChangeSearch,
    handleResetSearch,
    isRootFolder,
  }
}

import { l } from '@manychat/manyui'

import { BlockType } from 'common/builder/constants/BlockType'
import { NodeType } from 'common/builder/constants/NodeType'
import BaseNodeConfig from 'common/builder/nodes/BaseNodeConfig'
import { EmailStyleType } from 'common/builder/nodes/emailNew/emailNodeConstants'
import { IEmailNode, INodeBase } from 'common/builder/nodes/nodeInterfaces'

class EmailNodeNewConfig extends BaseNodeConfig<IEmailNode> {
  nodeType = NodeType.EMAIL_NEW

  isPro = true

  getCaption = () => l.translate('Send Message')
  getToolbarCaption = () => l.translate('Email')
  getCaptionWithCount = (n: number) =>
    l.translate('{n, plural, one {# Email} other {# Emails}}', { n })
  getNextStepCaption = () => l.translate('Email')

  icon = 'EmailChannel'
  color = '#8492A6'
  headerColor = '#E0D7FB'

  ALLOWED_BLOCK_TYPES = [BlockType.EMAIL_ROOT]
  ALLOWED_BLOCK_MAX_COUNT = 50

  getDefaults = (): Omit<IEmailNode, keyof INodeBase> => ({
    targetId: null,
    data: {
      preheader: '',
      subject: '',
      senderName: '',
      businessEmailId: null,

      background_color: { type: EmailStyleType.CUSTOM, value: '#F6F7F9' },
    },

    $isAdditionalAutomation: false,
  })

  getInitialBlocks = () => {
    return [
      { type: BlockType.EMAIL_ROOT as const },
      { type: BlockType.EMAIL_TRIGGER_OPEN as const },
    ]
  }

  isEmpty = () => false
}

export default new EmailNodeNewConfig()

import { createSlice } from '@reduxjs/toolkit'

import { InstagramTopicsModalSliceState } from 'common/oneTimeNotify/components/InstagramTopicsModal/slices/interfaces'

const initialState: InstagramTopicsModalSliceState = {
  isModalOpen: false,
  isNeedBack: false,
}

const InstagramTopicsModalSlice = createSlice({
  name: 'instagramTopicsModal',
  initialState,
  reducers: {
    openModal(state: InstagramTopicsModalSliceState) {
      state.isModalOpen = true
      state.isNeedBack = false
    },
    openModalWithBackButton(state: InstagramTopicsModalSliceState) {
      state.isModalOpen = true
      state.isNeedBack = true
    },

    closeModal(state: InstagramTopicsModalSliceState) {
      state.isModalOpen = false
      state.isNeedBack = false
    },
  },
})

export const { openModal, closeModal, openModalWithBackButton } = InstagramTopicsModalSlice.actions

export default InstagramTopicsModalSlice.reducer

export const getIsModalOpen = (state: RootState): boolean => state.instagramTopicsModal.isModalOpen
export const getIsNeedBack = (state: RootState): boolean => state.instagramTopicsModal.isNeedBack

import { z } from 'zod'

import { ChannelType } from 'common/core/constants/ChannelType'
import {
  ReasonTypes,
  ReasonStatuses,
  ReasonRequestType,
} from 'common/oneTimeNotify/constants/notifyReasonConstants'

const notificationReasonSchema = z.object({
  reason_id: z.number(),
  caption: z.string(),
  type: z.nativeEnum(ReasonTypes),
  channel: z.nativeEnum(ChannelType),
  description: z.string().nullable(),
  status: z.nativeEnum(ReasonStatuses),
  request_title: z.string().nullable(),
  // todo reuse types from uploader when they will be ready
  request_image: z.object({ caid: z.number(), img_big: z.string() }).nullable(),
  subscribers_count: z.number(),
  rn_optin_link: z.string().nullable(),
  unique_usage_count: z.number().optional(),
})

const notificationReasonCreateObjectSchema = z.object({
  caption: z.string(),
  channel: z.nativeEnum(ChannelType),
  context_flow_ns: z.string().optional(),
  request_title: z.string(),
  type: z.nativeEnum(ReasonTypes).nullable(),
  description: z.string().optional(),
})

export const NotificationReasonSchemas = {
  getList: {
    query: z.object({
      active_only: z.boolean(),
      channel: z.nativeEnum(ChannelType).optional(),
      notification_type: z.nativeEnum(ReasonRequestType).optional(),
    }),
    response: z.object({
      reasons: z.array(notificationReasonSchema),
    }),
    path: z.undefined(),
  },
  create: {
    query: z.object({
      client_id: z.string(),
    }),
    request: notificationReasonCreateObjectSchema,
    response: z.object({
      reason: notificationReasonSchema,
    }),
    path: z.undefined(),
  },
  archive: {
    query: z.undefined(),
    request: z.object({
      reason_id: z.number(),
    }),
    response: z.undefined(),
    path: z.undefined(),
  },
  delete: {
    query: z.undefined(),
    request: z.object({
      reason_id: z.number(),
    }),
    response: z.undefined(),
    path: z.undefined(),
  },
  restore: {
    query: z.undefined(),
    request: z.object({
      reason_id: z.number(),
    }),
    response: z.undefined(),
    path: z.undefined(),
  },
}

export type NotificationReasonGetListPayload = z.infer<
  typeof NotificationReasonSchemas.getList.query
>
export type NotificationReason = z.infer<typeof notificationReasonSchema>
export type NotificationReasonCreateObject = z.infer<typeof notificationReasonCreateObjectSchema>

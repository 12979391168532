import { z } from 'zod'
import { l } from '@manychat/manyui'

export const FsValidationMessage = {
  getRequiredMessage: (fieldName: string) =>
    l.translate('{fieldName} is required', {
      fieldName,
    }),
  getMaxLengthMessage: (fieldName: string, maxSymbols: number) =>
    l.translate('{fieldName} should not contain more than {maxSymbols} symbols', {
      fieldName,
      maxSymbols,
    }),
  getFolderWithThisNameExistsMessage: () => l.translate('Folder with this name already exists'),
  getTargetFolderHasFolderWithThisNameMessage: () =>
    l.translate('Target Folder already has a folder with this name'),
}

export const FsValidationSchema = {
  folderName: z
    .string()
    .min(1, FsValidationMessage.getRequiredMessage(l.translate('Folder name')))
    .max(500, FsValidationMessage.getMaxLengthMessage(l.translate('Folder name'), 500)),
}

import React from 'react'

import { freezeBody } from 'utils'

import { NEW_KEY, EMPTY_KEY } from './constants'
import defaultProps from './defaultProps'
import Dropdown from './Dropdown'

let ID = 0

/** @deprecated use Autocomplete from @manychat/manyui */
export default class AutocompleteWrapper extends React.Component {
  static defaultProps = Object.assign({}, defaultProps, {
    forceTarget: false,
    fitTargetWidth: true,
    canAutoPosition: false,
    onRequestClose() {},
  })

  constructor(props, context) {
    super(props, context)
    this.state = {
      q: props.q || '',
      selected: null,
      focused: props.forceTarget ? 0 : null,
      placeholder: '',
      show: props.show,
    }

    this.id = `dropdown-wrapper-${++ID}`

    if (props.show !== undefined) {
      this._isControlled = true
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.q !== undefined && nextProps.q !== this.state.q) {
      this.setState({ q: nextProps.q })
    }

    if (nextProps.value !== this.state.value) {
      this.setState({ value: nextProps.value })
    }

    if (this._isControlled && nextProps.show !== this.state.show) {
      this.setState({ show: nextProps.show })
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.show !== this.state.show) {
      if (this.state.show) {
        this.props.onShow()
      } else {
        this.props.onHide()
      }
    }
  }

  hide = () => {
    this.setState(
      {
        focused: 0,
        show: this._isControlled ? this.props.show : false,
      },
      () => {
        freezeBody(this._isControlled ? this.props.show : false, this.id)
      },
    )
  }

  show = (e) => {
    e && e.preventDefault()
    if (this.props.disabled) {
      return false
    }
    this.setState({ show: this._isControlled ? this.props.show : true })
    freezeBody(this._isControlled ? this.props.show : true, this.id)
  }

  select = (selected) => {
    if (selected) {
      if (selected[this.props.valueKey] === NEW_KEY) {
        this.props.onCreate(selected)

        if (this.props.closeOnCreate) {
          this.hide()
        }
        return
      }

      if (selected[this.props.valueKey] === EMPTY_KEY) {
        this.props.onSelectEmpty(selected)
        return
      }

      this.setState({ selected })
      this.props.onSelect(selected)

      if (this.props.closeOnSelect) {
        this.hide()
      }
    } else {
      const items = this.getFiltered()
      const opt = this.getFiltered()[this.state.focused % items.length]
      opt && this.select(opt)
    }
  }

  onKeydown = (event) => {
    const { focused } = this.state
    const hotkeys = ['ArrowUp', 'ArrowDown', 'Enter', 'Escape']

    if (!hotkeys.includes(event.key)) {
      return
    }

    event.preventDefault()
    event.stopPropagation()

    switch (event.key) {
      case 'ArrowUp':
        this.setState({ focused: (focused || 1) - 1 })
        break
      case 'ArrowDown':
        if (this.show() !== false) {
          this.setState({ focused: (focused === null ? -1 : focused) + 1 })
        }
        break
      case 'Enter':
        this.props.onEnter && this.props.onEnter(this.getShow())
        this.select()
        break
      case 'Escape':
        this.hide()
        break
    }
  }

  handleRequestClose = (reason, e) => {
    if (reason === 'clickAnchor') {
      return
    }

    if (reason === 'clickAway') {
      this.hide()
    }

    if (reason === 'esc') {
      this.props.onRequestClose(reason, e)
    }

    if (reason !== 'offScreen') {
      e.stopPropagationToLayers()
    }
  }

  changeIndex = (index) => {
    this.setState({ focused: index })
  }

  getFiltered() {
    const { filter, labelKey, allowNew, forceNew, labelEmpty, valueKey, paginateResults } =
      this.props
    const { q } = this.state
    let newDuplicate = false

    const filtered = this.props.options.filter((opt) => {
      if (filter) {
        return filter(opt, q)
      } else {
        if (!q) {
          return opt
        }

        const label = opt[labelKey] || (typeof opt === 'string' && opt) || ''

        if (label.toLowerCase().indexOf((q || '').toLowerCase()) > -1) {
          if (label === q) {
            newDuplicate = true
          }
          return true
        }
      }
    })

    if (q && !newDuplicate && (forceNew || (!filtered.length && allowNew))) {
      filtered.push({
        _displayLabel: this.props.labelNew.split('%value%').join(q),
        _isNew: true,
        [labelKey]: q,
        [valueKey]: NEW_KEY,
      })
    }

    if (!filtered.length && !allowNew && labelEmpty) {
      filtered.push({ [labelKey]: this.props.labelEmpty, [valueKey]: EMPTY_KEY })
    }

    return paginateResults ? filtered.slice(0, paginateResults) : filtered
  }

  getFocused = () => {
    const { focused } = this.state
    const filteredOptions = this.getFiltered()
    let _focused = Math.abs(focused % filteredOptions.length)
    if (!this.props.forceTarget) {
      _focused = focused === null ? -1 : _focused
    }
    return _focused
  }

  getShow = () => {
    if (!this.props.hidePopoverNoMatch) {
      return this.state.show
    }
    return this.getFiltered().length ? this.state.show : false
  }

  render() {
    return (
      <div onKeyDown={this.onKeydown}>
        {this.props.children}
        <Dropdown
          {...this.props}
          onChangeIndex={this.changeIndex}
          onSelect={this.select}
          activeIndex={this.getFocused()}
          target={this.props.target}
          customButton={this.props.customButton}
          show={this.getShow()}
          onRequestClose={this.handleRequestClose}
          options={this.getFiltered()}
        />
      </div>
    )
  }
}

import BaseBlockConfig from 'common/builder/blocks/BaseBlockConfig'
import { IBlockBase, IQuickReplyBlock } from 'common/builder/blocks/blockInterfaces'
import { BlockType } from 'common/builder/constants/BlockType'

class QuickReplyBlockConfig extends BaseBlockConfig {
  blockType = BlockType.QUICK_REPLY

  getDefaults = (): Omit<IQuickReplyBlock, keyof IBlockBase> => ({
    targetId: null,
    settings: {},
  })
}

export default new QuickReplyBlockConfig()

import { z } from 'zod'

import { BusinessErrorSchema } from 'shared/api/common/schemas/errors'

export const AccountApiSchemas = {
  favorite: {
    path: z.undefined(),
    query: z.undefined(),
    request: z.object({
      account_id: z.string(),
      is_favorite: z.string(),
    }),
    response: z.object({
      is_favorite: z.boolean(),
    }),
    errors: {
      SetFavoriteError: BusinessErrorSchema.extend({
        field: z.literal('is_favorite'),
      }),
    },
  },
  hide: {
    path: z.undefined(),
    query: z.undefined(),
    request: z.object({
      account_id: z.string(),
      is_hidden: z.string(),
    }),
    response: z.object({
      is_hidden: z.boolean(),
    }),
    errors: {
      SetHiddenError: BusinessErrorSchema.extend({
        field: z.literal('is_hidden'),
      }),
    },
  },
  connectFBChannel: {
    path: z.undefined(),
    query: z.undefined(),
    request: z.object({
      return_url: z.string().optional(),
    }),
    response: z.undefined(),
  },
  setDisplayFlag: {
    path: z.undefined(),
    query: z.undefined(),
    request: z.object({
      display_flag: z.string(),
      value: z.boolean(),
    }),
    response: z.undefined(),
  },
  getDisplayFlag: {
    query: z.object({ display_flag: z.string() }),
    path: z.undefined(),
    response: z.record(z.string(), z.boolean().nullable()),
  },
}

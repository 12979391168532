import React from 'react'
import cx from 'classnames'
import PropTypes from 'prop-types'
import { l, Icon } from '@manychat/manyui'

import { FieldGroupId } from 'common/fields/entities/enums'
import EntitiesMapping from 'common/mapping/components/EntitiesMapping'
import { EntitiesNTNMapping } from 'common/mapping/components/EntitiesNTNMapping/EntitiesNTNMapping'
import { MappingField } from 'common/mapping/components/MappingField/MappingField'
import TargetPageFieldPicker from 'common/mapping/components/TargetPageFieldPicker'
import { TargetPageCustomEventPickerController } from 'common/mapping/controllers/TargetPageCustomEventPicker/TargetPageCustomEventPickerController'
import TargetPageTagPicker from 'common/mapping/controllers/TargetPageTagPicker'
import { getCurrencyCode } from 'utils/formatCurrency'

import cm from './TargetPageEntitiesMapping.module.css'

export default class TargetPageEntitiesMapping extends React.Component {
  static propTypes = {
    customEventsMap: PropTypes.object.isRequired,
    tagsMap: PropTypes.object.isRequired,
    otnsMap: PropTypes.object.isRequired,
    cufsMap: PropTypes.object.isRequired,
    gafsMap: PropTypes.object.isRequired,
    targetPageCufs: PropTypes.array.isRequired,
    targetPageTags: PropTypes.array.isRequired,
    targetPageOtns: PropTypes.array.isRequired,
    targetPageGafs: PropTypes.array.isRequired,
    pageId: PropTypes.string.isRequired,
    onCustomEventSelect: PropTypes.func.isRequired,
    onOtnSelect: PropTypes.func.isRequired,
    onTagSelect: PropTypes.func.isRequired,
    onCUFSelect: PropTypes.func.isRequired,
    onGAFSelect: PropTypes.func.isRequired,
    entities: PropTypes.object.isRequired,
    isOpen: PropTypes.bool,
  }

  static defaultProps = {
    isInitiallyOpen: false,
  }

  state = {
    isMappingPanelOpen: this.props.isInitiallyOpen,
    hasTagsChanges: false,
    hasCUFsChanges: false,
  }

  toggleMapping = () =>
    this.setState({
      isMappingPanelOpen: !this.state.isMappingPanelOpen,
    })

  render() {
    const {
      customEventsMap,
      otnsMap,
      tagsMap,
      cufsMap,
      gafsMap,
      pageId,
      entities,
      className,
      targetPageCufs,
      targetPageTags,
      targetPageGafs,
      targetPageOtns,
      onCustomEventSelect,
      onOtnSelect,
      onCUFSelect,
      onTagSelect,
      onGAFSelect,
    } = this.props

    const { isMappingPanelOpen } = this.state

    return (
      Boolean(
        (targetPageTags.length && entities.tags && entities.tags.length) ||
          (targetPageOtns.length && entities.otns && entities.otns.length) ||
          (targetPageCufs.length && entities.custom_fields && entities.custom_fields.length) ||
          (targetPageGafs.length && entities.global_fields && entities.global_fields.length),
      ) && (
        <div className={className}>
          <div className={'row middle pointer'} onClick={this.toggleMapping}>
            <div className="col-10 text-subheading">
              {l.translate(
                'You can replace template entities (e.g. CUFs or Subscription Lists) with the same type of content',
              )}
            </div>
            <div className="col-auto m-l-auto">
              <Icon.ChevronDown
                size={32}
                className={cx('d-block m-r-xs', cm.rotatingIcon, {
                  [cm.rotate]: isMappingPanelOpen,
                })}
              />
            </div>
          </div>
          <div
            className={cx(cm.entitiesMapping, {
              [cm.show]: isMappingPanelOpen,
            })}
          >
            {entities.custom_fields &&
              Boolean(entities.custom_fields.length) &&
              Boolean(targetPageCufs.length) && (
                <EntitiesMapping
                  currentEntities={entities.custom_fields}
                  entitiesMap={cufsMap}
                  caption={l.translate('Users Fields')}
                  onSet={onCUFSelect}
                  picker={TargetPageFieldPicker}
                  pickerProps={{ pageId, group: FieldGroupId.USER }}
                  className="m-t-lg"
                />
              )}
            {entities.global_fields &&
              Boolean(entities.global_fields.length) &&
              Boolean(targetPageGafs.length) && (
                <EntitiesMapping
                  currentEntities={entities.global_fields}
                  entitiesMap={gafsMap}
                  onSet={onGAFSelect}
                  caption={l.translate('Bot Fields')}
                  picker={TargetPageFieldPicker}
                  pickerProps={{ pageId, group: FieldGroupId.BOT }}
                  className="m-t-lg"
                />
              )}
            {entities.tags && Boolean(entities.tags.length) && Boolean(targetPageTags.length) && (
              <EntitiesMapping
                currentEntities={entities.tags}
                entitiesMap={tagsMap}
                onSet={onTagSelect}
                caption={l.translate('Tags')}
                picker={TargetPageTagPicker}
                pickerProps={{ pageId }}
                className="m-t-lg"
              />
            )}
            {entities.custom_events && Boolean(entities.custom_events.length) && (
              <EntitiesMapping
                currentEntities={entities.custom_events}
                entitiesMap={customEventsMap}
                onSet={onCustomEventSelect}
                caption={l.translate('Conversion Events')}
                picker={TargetPageCustomEventPickerController}
                pickerProps={{ pageId }}
                className="m-t-lg"
                mappingField={({ en, entity }) => (
                  <MappingField
                    label={
                      en.meta_type
                        ? `(${getCurrencyCode(en.meta_type)}) ${en[entity.name]}`
                        : en[entity.name]
                    }
                    description={en.description}
                    type={en.type}
                  />
                )}
              />
            )}
            {entities.otns && Boolean(entities.otns.length) && Boolean(targetPageOtns.length) && (
              <EntitiesNTNMapping
                currentEntities={entities.otns}
                caption={l.translate('Subscription Lists ')}
                entitiesMap={otnsMap}
                onSet={onOtnSelect}
                className="m-t-lg"
              />
            )}
          </div>
        </div>
      )
    )
  }
}

import React from 'react'

import { IncomingMessage, IncomingMessageProps } from './IncomingMessage'
import { OutgoingMessage, OutgoingMessageProps } from './OutgoingMessage'

type MessageProps = OutgoingMessageProps | IncomingMessageProps

export function ChatMessage(props: MessageProps) {
  if (props.variant === 'outgoing') {
    return <OutgoingMessage>{props.children}</OutgoingMessage>
  }

  if (props.variant === 'incoming') {
    return <IncomingMessage avatarUrl={props.avatarUrl}>{props.children}</IncomingMessage>
  }

  return null
}

ChatMessage.displayName = 'Chat.Message'

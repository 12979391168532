import { useCallback, useEffect } from 'react'
import { useAppDispatch } from 'reduxTyped'

import { CoexistenceContactImportStatus } from 'apps/whatsApp/interfaces/whatsAppCoexistence'
import { setCoexistenceContactImportStatus } from 'apps/whatsApp/redux/coexistenceSlice'
import { WebSocketEvents } from 'constants/WebSockets'
import { accountNotificationsListener } from 'utils/services/notificationsService'

interface HistoryContactImportData {
  model: {
    progress: number
  }
}

export const useWaHistoryContactImport = () => {
  const dispatch = useAppDispatch()

  const setProgress = useCallback(
    (data: HistoryContactImportData) => {
      if (data.model.progress >= 100) {
        dispatch(setCoexistenceContactImportStatus(CoexistenceContactImportStatus.FINISHED))
      } else if (data.model.progress > 0) {
        dispatch(setCoexistenceContactImportStatus(CoexistenceContactImportStatus.ACTIVE))
      }
    },
    [dispatch],
  )

  useEffect(() => {
    accountNotificationsListener.on(WebSocketEvents.WA_HISTORY_CONTACT_IMPORT_PROGRESS, setProgress)

    return () =>
      accountNotificationsListener.removeListener(
        WebSocketEvents.WA_HISTORY_CONTACT_IMPORT_PROGRESS,
        setProgress,
      )
  }, [setProgress])
}

import React, { useCallback } from 'react'
import { zodResolver } from '@hookform/resolvers/zod'
import { useForm } from 'react-hook-form'
import { z } from 'zod'
import { Modal, l, BtnV2, TextInputV2 } from '@manychat/manyui'

import { useRenameFolderModal } from 'apps/cms/components/Flows/components/AutomationModals/modals/RenameFolderModal/hooks/useRenameFolderModal'
import { CmsAnalytics } from 'apps/cms/lib/analytics'
import { FsValidationSchema } from 'apps/cms/lib/validation'

import { FormValues } from './lib/types'

const validationSchema = z.object({
  name: FsValidationSchema.folderName,
})

export const RenameFolderModal = () => {
  const { isLoading, renameFolder, folderName, closeModal } = useRenameFolderModal()

  const {
    register,
    handleSubmit,
    formState: { errors, isDirty },
  } = useForm<FormValues>({
    defaultValues: {
      name: folderName,
    },
    resolver: zodResolver(validationSchema),
  })

  const { ref, ...field } = register('name')

  const handleSuccessSubmit = useCallback(
    (formValues: FormValues) => {
      if (!isDirty) {
        closeModal()
      }

      renameFolder(formValues)
      CmsAnalytics.logRenameFolder()
    },
    [closeModal, isDirty, renameFolder],
  )

  return (
    <Modal
      open={true}
      width={460}
      title={<div className="p-y-xs">{l.translate('Rename Folder')}</div>}
      buttons={[
        <BtnV2 onClick={closeModal} className="m-y-xs">
          {l.translate('Cancel')}
        </BtnV2>,
        <BtnV2
          loading={isLoading}
          className="m-y-xs"
          variant="primary"
          type="submit"
          form="cms-rename-folder"
        >
          {l.translate('Rename')}
        </BtnV2>,
      ]}
      onRequestClose={closeModal}
    >
      <div className="p-x p-y-md">
        <form id="cms-rename-folder" onSubmit={handleSubmit(handleSuccessSubmit)}>
          <TextInputV2
            {...field}
            inputRef={ref}
            label={l.translate('Folder name')}
            autoFocus
            autoComplete="off"
            placeholder={l.translate('Enter folder name')}
            errorText={errors.name?.message}
            invalid={Boolean(errors.name?.message)}
          />
        </form>
      </div>
    </Modal>
  )
}

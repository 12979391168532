export const BUTTON = 'button'
export const MESSENGER_REF_URL = 'messenger_ref_url'
// ADS_JSON is deprecated, use ADS instead
/** @deprecated */
export const ADS_JSON = 'ads_json'
export const ADS = 'ads'
export const FEED_COMMENT = 'feed_comment'
export const FEED_COMMENT_TRIGGER = 'feed_comment_trigger'
export const MESSENGER_CODE = 'messenger_code'
export const FACEBOOK_SHOP = 'facebook_shops'
export const INSTAGRAM_STORY_REPLY = 'instagram_story_reply'
export const INSTAGRAM_LIVE_COMMENT_REPLY = 'instagram_live_comment_reply'

export const notEditableTypes = [
  MESSENGER_REF_URL,
  ADS_JSON,
  ADS,
  FEED_COMMENT,
  FEED_COMMENT_TRIGGER,
  MESSENGER_CODE,
  FACEBOOK_SHOP,
]

import React from 'react'
import { Icon } from '@manychat/manyui'

import { BaseTitle } from 'apps/cms/components/Flows/components/FlowCard/components/Triggers/components/BaseTitle/BaseTitle'
import { CgtTitle } from 'apps/cms/components/Flows/components/FlowCard/components/Triggers/components/CgtTitle/CgtTitle'
import { LiveCommentsTitle } from 'apps/cms/components/Flows/components/FlowCard/components/Triggers/components/LiveCommentsTitle/LiveCommentsTitle'
import { RefUrlTitle } from 'apps/cms/components/Flows/components/FlowCard/components/Triggers/components/RefUrlTitle/RefUrlTitle'
import { StoryReplyTitle } from 'apps/cms/components/Flows/components/FlowCard/components/Triggers/components/StoryReplyTitle/StoryReplyTitle'
import {
  getRuns,
  getCtr,
  getWidgetStatistics,
} from 'apps/cms/components/Flows/components/FlowCard/components/Triggers/hooks/useTriggers/lib/statistics'
import { WidgetTypes, WidgetStatusType } from 'apps/growthTools/models/Widget/constants'
import { ChannelIcon } from 'common/core/constants/ChannelType'
import { TriggerType } from 'common/flow/constants/TriggerType'
import {
  Widget,
  LiveCommentsWidget,
  StoryReplyWidget,
  CgtWidget,
} from 'shared/api/requests/cms/schemas/widgets'
import { calculateCTR } from 'utils/stats'

export const getWidgetTrigger = (widget: Widget) => {
  const TriggerIcon = ChannelIcon[widget.channel]

  let title: React.ReactNode = <BaseTitle text={widget.name} />

  if (widget.widget_type === WidgetTypes.MESSENGER_REF_URL) {
    title = <RefUrlTitle />
  }

  if (widget.widget_type === WidgetTypes.INSTAGRAM_LIVE_COMMENT_REPLY) {
    title = <LiveCommentsTitle widget={widget as LiveCommentsWidget} />
  }

  if (widget.widget_type === WidgetTypes.INSTAGRAM_STORY_REPLY) {
    title = <StoryReplyTitle widget={widget as StoryReplyWidget} />
  }

  if (widget.widget_type === WidgetTypes.FEED_COMMENT_TRIGGER) {
    title = <CgtTitle widget={widget as CgtWidget} />
  }

  return {
    icon: TriggerIcon ? (
      <TriggerIcon size={16} className="text-secondary" />
    ) : (
      <Icon.Bot size={16} />
    ),
    title: title,
    runs: getRuns(widget.runs),
    ctr: widget.ctr
      ? getCtr(calculateCTR(widget.ctr.optins, widget.ctr.impressions))
      : getCtr(null),
    id: widget.widget_id,
    type: TriggerType.WIDGET,
    disabled: widget.status !== WidgetStatusType.ACTIVE,
    created: widget.ts_created,
    status: widget.status,
    impressions: getWidgetStatistics(widget.ctr?.impressions),
    optIns: getWidgetStatistics(widget.ctr?.optins),
  }
}

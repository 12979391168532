import { createSlice } from '@reduxjs/toolkit'
import { createAppAsyncThunk } from 'reduxTyped'

import * as atypes from 'common/actions/integrations/common/constants/IntegrationsReduxActionTypes'
import { handleCatch } from 'shared/api/lib/errors/handlers'
import { flodeskApi } from 'shared/api/requests/integrations/flodesk'
import { FlodeskField, FlodeskSegment } from 'shared/api/requests/integrations/flodesk/schemas'

const namespace = 'flodesk'

export interface FlodeskIntegrationState {
  isConnecting: boolean
  currentSegments: FlodeskSegment[]
  segments: FlodeskSegment[] | null
  fields: FlodeskField[] | null
  bindings: Record<string, string | number>
  mappings: Record<string, string | number>
  isFetchingSegments: boolean
  isFetchingFields: boolean
}

const getSegments = createAppAsyncThunk(`${namespace}/getSegments`, async () => {
  try {
    const response = await flodeskApi.getSegments()

    return response.data.data
  } catch (error) {
    handleCatch(error)
    return null
  }
})

const getFields = createAppAsyncThunk(`${namespace}/getFields`, async () => {
  try {
    const response = await flodeskApi.getCustomFields()

    return response.data.data
  } catch (error) {
    handleCatch(error)
    return null
  }
})

const initialState: FlodeskIntegrationState = {
  isConnecting: false,
  currentSegments: [],
  segments: null,
  fields: null,
  bindings: {},
  mappings: {},
  isFetchingSegments: false,
  isFetchingFields: false,
}

export const { reducer: flodeskReducer, actions } = createSlice({
  name: 'flodesk',
  initialState,
  reducers: {
    setBindings: (state, action) => {
      state.bindings = { ...state.bindings, ...action.payload }
    },
    clearBindings: (state) => {
      state.bindings = {}
    },
    setCurrentSegments: (state, action) => {
      state.currentSegments = action.payload
    },
    clearState: () => initialState,
  },
  extraReducers: (builder) => {
    builder.addCase(atypes.INTEGRATION_CONNECT_BY_TOKEN_REQUEST, (state) => {
      state.isConnecting = true
    })
    builder.addCase(atypes.INTEGRATION_CONNECT_BY_TOKEN_ERROR, (state) => {
      state.isConnecting = false
    })
    builder.addCase(atypes.INTEGRATION_CONNECT_BY_TOKEN_RESPONSE, (state) => {
      state.isConnecting = false
    })
    builder.addCase(atypes.INTEGRATION_DISCONNECT_REQUEST, (state) => {
      state.isConnecting = true
    })
    builder.addCase(atypes.INTEGRATION_DISCONNECT_RESPONSE, (state) => {
      state.isConnecting = false
    })

    builder.addCase(getSegments.pending, (state) => {
      state.isFetchingSegments = true
    })

    builder.addCase(getSegments.fulfilled, (state, action) => {
      state.segments = action.payload
      state.isFetchingSegments = false
    })

    builder.addCase(getFields.pending, (state) => {
      state.isFetchingFields = true
    })

    builder.addCase(getFields.fulfilled, (state, action) => {
      state.fields = action.payload
      state.isFetchingFields = false
    })
  },
})

export const flodeskActions = {
  getSegments,
  getFields,
  ...actions,
}

import BaseBlockConfig from 'common/builder/blocks/BaseBlockConfig'
import { VariantBlockTitles, VariantBlockColors } from 'common/builder/blocks/blockConstants'
import { IBlockBase, IVariantBlock } from 'common/builder/blocks/blockInterfaces'
import { BlockType } from 'common/builder/constants/BlockType'

class VariantBlockConfig extends BaseBlockConfig {
  blockType = BlockType.VARIANT

  getDefaults = (): Omit<IVariantBlock, keyof IBlockBase> => ({
    targetId: null,
    percent: 50,
    color: VariantBlockColors[VariantBlockTitles.A],
    title: VariantBlockTitles.A,
  })
}

export default new VariantBlockConfig()

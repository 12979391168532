import { l } from '@manychat/manyui'

import { FsFlowObject } from 'shared/api/requests/cms/schemas'
import { calculateCTR } from 'utils/stats'

export const getRunsValue = (sent: number | null): string => {
  if (sent === null) {
    return l.translate('n/a')
  }

  return l.number(sent)
}

export const getCtrValue = (stats: FsFlowObject['stats']): string => {
  if (stats.clicked_unq === null || stats.sent_unq === null) {
    return l.translate('n/a')
  }

  if (stats.sent === 0) {
    return l.translate('n/a')
  }

  return l.percent(calculateCTR(stats.clicked_unq, stats.sent_unq))
}

import isEqual from 'lodash/isEqual'
import { createAppAsyncThunk } from 'reduxTyped'
import { createSelector } from 'reselect'

import { isIgCgt } from 'apps/cms/components/Flows/components/FlowCard/components/Triggers/hooks/useTriggers/lib/filtersMatching/instagram'
import { Option } from 'apps/cms/components/Flows/components/FlowFilters/components/FilterPicker/lib/types'
import { namespace } from 'apps/cms/store/lib/constants'
import { getFilterOption } from 'apps/cms/store/lib/helper'
import { cmsQuerySelectors } from 'apps/cms/store/query'
import { handleCatch } from 'shared/api/lib/errors/handlers'
import { CmsApi } from 'shared/api/requests/cms'
import { FsTriggerStatusFilter } from 'shared/api/requests/cms/schemas'

const fetchFilters = createAppAsyncThunk(`${namespace}/fetchFilters`, async () => {
  try {
    const response = await CmsApi.getFilters()

    return response.data
  } catch (error) {
    handleCatch(error)

    return {
      trigger_type: [],
      trigger_status: [],
    }
  }
})

export const cmsFiltersActions = {
  fetchFilters,
}

const getState = (state: RootState) => state.contentManagementSystem.filters

export const cmsFiltersSelectors = {
  getFilters: (state: RootState) => getState(state),

  getActiveFilterOptions: createSelector(
    [
      (state: RootState) => getState(state).triggerTypeFilters,
      cmsQuerySelectors.getTriggerFiltersQuery,
    ],
    (filters, triggerFilters): Option[] => {
      if (!filters.length || !triggerFilters) return []

      const activeFilters = filters.filter((backendFilter) =>
        triggerFilters.some((triggerFilter) => {
          if (isIgCgt(triggerFilter) && isIgCgt(backendFilter.filters)) {
            return true
          }

          return isEqual(triggerFilter, backendFilter.filters)
        }),
      )

      return activeFilters.map((filter) => getFilterOption(filter))
    },
  ),

  getActiveStatusFilter: createSelector(
    [(state: RootState) => getState(state).triggerStatusFilters, cmsQuerySelectors.getQuery],
    (filters, query): FsTriggerStatusFilter | null => {
      if (!filters.length || !query.triggerStatus) return null
      const { triggerStatus } = query

      const activeStatusFilter = filters.find(
        ({ filters }) => filters.trigger_status === triggerStatus,
      )

      return activeStatusFilter || null
    },
  ),
}

import compact from 'lodash/compact'
import flatten from 'lodash/flatten'
import isNumber from 'lodash/isNumber'

import { NodeType } from 'common/builder/constants/NodeType'
import * as entitySelectors from 'common/builder/selectors/builder/entitySelectors'

import * as builderStateSelectors from './builderStateSelectors'

export const getLinkedContentId = (state: RootState, builderId: string, buttonId: string) => {
  const buttonItem = entitySelectors.getButtonById(state, builderId, buttonId)

  if (!buttonItem || !('targetId' in buttonItem) || !buttonItem.targetId) {
    return null
  }

  const visited: string[] = []
  // Find last linked content node recursively
  const getLinkedContent = (item: {
    targetId?: null | string
    id: string
  }): null | string | number => {
    if (item) {
      if (visited.includes(item.id)) {
        return null
      }
      visited.push(item.id)
    }

    if (!('targetId' in item)) {
      return null
    }

    const targetItem = entitySelectors.getNodeById(state, builderId, item.targetId ?? '')
    if (!targetItem) {
      if (isNumber(item.targetId)) {
        return item.targetId
      }
      return null
    }

    const targetType = targetItem.nodeType
    if (targetType === NodeType.CONTENT) {
      return targetItem.id
    }

    if (!('targetId' in targetItem) || !targetItem.targetId) {
      return null
    }

    return getLinkedContent(targetItem)
  }

  return getLinkedContent(buttonItem)
}

// Returns a list of content IDs that are connected to any of the non-foreign contents
export const getConnectedContentIds = (state: RootState, builderId: string) => {
  const contents = entitySelectors.getNodesListByType(state, builderId, NodeType.CONTENT)
  const builder = builderStateSelectors.getById(state, builderId)
  const nonForeignContents = contents.filter((content) => builder.flow === content.flow)
  const contentButtonsList = nonForeignContents.map((content) =>
    entitySelectors.getButtons(state, builderId, content.id, { includeNested: true }),
  )
  const contentButtons = flatten(contentButtonsList)
  const connectedContentIds = contentButtons.map((button) => {
    return getLinkedContentId(state, builderId, button.id)
  })
  return compact(connectedContentIds)
}

import React from 'react'
import cx from 'classnames'
import { z } from 'zod'
import { BtnV2, l } from '@manychat/manyui'

import { BlockType } from '../../constants'
import { useBillingInfo, useCart, useDefaultContext } from '../../hooks'
import { registry } from '../../registry'
import { ClassNameSchema, isProductAddOn, isProductPlan } from '../../schemas'
import { BlockProps } from '../../types'

import cm from './SummaryFooter.module.css'

const SummaryFooterPropsSchema = z.object({
  className: ClassNameSchema,
  containerClassName: ClassNameSchema,
})

const Component = ({
  className,
  blockId,
  containerClassName,
}: BlockProps<typeof SummaryFooterPropsSchema>) => {
  const { activateTrial } = useCart()
  const { cart } = useDefaultContext()
  const { currency } = useBillingInfo()

  return (
    <footer className={cx(cm.summaryFooter, containerClassName)} data-block={blockId}>
      <div className={cm.fader} />

      <div className={cx(cm.summaryFooterContainer, className)}>
        <div className="d-flex flex-row justify-between p-b-xs align-center bg-white">
          <p className="text-heading-2 m-t-0">{l.translate("Today's total")}</p>
          <h2 className="text-display-md m-t-0">{l.currency(cart.totalPrice, currency, 0)}</h2>
        </div>
        <div
          className={cx(
            'd-flex flex-row justify-between align-center p-b-lg bg-white',
            cm.summaryButtons,
          )}
        >
          <BtnV2
            variant="primary"
            label={l.translate('Start free trial')}
            onClick={() => activateTrial()}
            data-test-id="upgrade"
          />
          <BtnV2
            variant="ghost-primary"
            label={l.translate('Apply coupon')}
            onClick={() =>
              cart.showCouponInput({
                products: cart.cart.filter(isProductPlan).map((product) => product.slug),
                addOns: cart.cart.filter(isProductAddOn).map((product) => product.slug),
              })
            }
          />
        </div>
      </div>
    </footer>
  )
}

export const SummaryFooter = registry.register(Component, {
  props: SummaryFooterPropsSchema,
  type: BlockType.SUMMARY_FOOTER,
  previewProps: {
    containerClassName: 'p-relative',
  },
})

import { ZodError, ZodIssue } from 'zod'

import { mergeZodIssues } from 'utils/services/errorTrackingService/mergeZodIssues'

export class CustomZodError extends Error {
  issues: ZodIssue[]
  name: string

  constructor(zodError: ZodError) {
    const mergedIssues = mergeZodIssues(zodError.issues)

    super(JSON.stringify(mergedIssues, null, 2))

    this.issues = mergedIssues
    this.name = zodError.name
  }
}

import every from 'lodash/every'
import { createStructuredSelector, ParametricSelector } from 'reselect'

import { IBlock } from 'common/builder/blocks/blockInterfaces'
import { BlockType } from 'common/builder/constants/BlockType'
import { ButtonType } from 'common/builder/constants/ButtonType'
import * as emailBuilderSelectors from 'common/builder/emailBuilder/selectors/emailBuilderSelectors'
import BlockModel from 'common/builder/models/Block'
import { getSmartLinkIdsInText } from 'common/builder/models/Button/helpers'
import { INode } from 'common/builder/nodes/nodeInterfaces'
import * as entitySelectors from 'common/builder/selectors/builder/entitySelectors'

import * as builderStateSelectors from './builderStateSelectors'

export const hasDescendant = (
  state: RootState,
  builderId: string,
  blockId: string,
  descendantId: string,
) => {
  // Is blockId a parent of descendantId?
  // We go up from descendantId through the parents
  // If we find blockId, then yes

  let lastItem = entitySelectors.getBlockById(state, builderId, descendantId)
  while (lastItem) {
    if (lastItem.id === blockId) {
      return true
    }
    lastItem = entitySelectors.getParentBlock(state, builderId, lastItem.id)
  }
  return false
}

// An empty block is considered a block whose
// - main attributes do not differ from the defaults
// - attributes of nested blocks do not differ from the defaults
export const isBlockEmpty = (state: RootState, builderId: string, blockId: string) => {
  const item = entitySelectors.getBlockById(state, builderId, blockId)
  const isEmpty = item === undefined ? true : BlockModel.isEmpty(item)

  if (!isEmpty) {
    return isEmpty
  }

  if (item === undefined || !BlockModel.isNestedBlockAllowed(item)) {
    return isEmpty
  }

  const blocks = entitySelectors.getBlocks(state, builderId, blockId)
  return every(blocks, (block) => BlockModel.isEmpty(block))
}

const getBlockErrors = (state: RootState, builderId: string, blockId: string) =>
  builderStateSelectors.getValidationErrors(state, builderId, blockId)

export const getBlockInfo: ParametricSelector<
  RootState,
  string,
  {
    block: IBlock | undefined
    errors: []
    parentNode: INode | undefined
    parentBlock: IBlock | undefined
  }
> = createStructuredSelector({
  block: entitySelectors.getBlockById,
  parentBlock: entitySelectors.getParentBlock,
  parentNode: entitySelectors.getParentNode,
  errors: getBlockErrors,
})

export const getEmailLinks = (state: RootState, builderId: string, nodeId: string) => {
  const rootBlock = emailBuilderSelectors.getRootBlock(state, builderId, nodeId)
  if (!rootBlock) {
    return []
  }

  const blocks = entitySelectors.getBlocks(state, builderId, rootBlock.id)

  const linksList = blocks
    .filter((block) => block.buttons.length || block.type === BlockType.EMAIL_BUTTON)
    .map((block) => {
      let buttons: string[] = []
      if (block.type === BlockType.EMAIL_TEXT) {
        buttons = getSmartLinkIdsInText(block.text)
      } else if (block.type === BlockType.EMAIL_BUTTON || block.type === BlockType.EMAIL_IMAGE) {
        buttons = [block.buttons[0]]
      }

      return { blockId: block.id, type: block.type, buttons }
    })

  return linksList
}

export const getIsAnyUnconnectedButtonsInBlock = (
  state: RootState,
  builderId: string,
  block: IBlock | null,
) => {
  if (!block) return false

  const { buttons } = block
  const buttonsInBlock = buttons.map((buttonId) =>
    entitySelectors.getButtonById(state, builderId, buttonId),
  )

  return buttonsInBlock.some((button) => button?.type === ButtonType.CONTENT && !button?.targetId)
}

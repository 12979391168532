import React from 'react'
import cn from 'classnames'
import { useNavigate } from 'react-router-dom'
import { Modal, l } from '@manychat/manyui'

import { linkURL } from 'utils/url'

import { WhatsAppBrokenMessageTemplateNotificationData } from '../../NotifyInterfaces'

import cm from './BrokenMessageTemplate.module.css'

export const BrokenMessageTemplate = ({
  data,
}: {
  data: WhatsAppBrokenMessageTemplateNotificationData
}) => {
  const navigate = useNavigate()
  const [isOpen, setIsOpen] = React.useState(false)
  const {
    message_template_name,
    message_template_language_code,
    bot_template_name,
    bot_template_id,
  } = data

  const messageTemplateURL = linkURL(
    `settings/whatsapp/messageTemplates/view/${message_template_name}?language_code=${message_template_language_code}`,
  )
  const botTemplateURL = linkURL(`profile/templates/${bot_template_id}`, true)

  const handleBotTemplateClick = () => {
    navigate(botTemplateURL)
    setIsOpen(false)
  }
  const handleMessageTemplateClick = () => {
    navigate(messageTemplateURL)
    setIsOpen(false)
  }

  return (
    <>
      <span>
        {l.translate(
          `Bot Template "{bot_template_name}" cannot be installed because it contains non-valid content.`,
          {
            bot_template_name,
          },
        )}
      </span>
      <a onClick={() => setIsOpen(true)} className={cn(cm.more, 'c-pointer m-l-xs')}>
        {l.translate('Learn how to fix it.')}
      </a>
      <Modal
        title={l.translate('Bot Template Installation Failure')}
        onRequestClose={() => setIsOpen(false)}
        open={isOpen}
      >
        <div className="p-a-xs">
          <p>
            {l.translate(
              `You're seeing this because someone recently tried to install your Account Template called "<a class="c-pointer text-primary" onclick="handleBotTemplateClick">{bot_template_name}</a>" and could not do so because of the following invalid content in`,
              { bot_template_name, handleBotTemplateClick },
            )}
            {` `}
            <a onClick={handleMessageTemplateClick} className="c-pointer text-primary">
              {l.translate('WhatsApp Message Template')} {message_template_name}
            </a>
          </p>
          <p>{l.translate(`To resolve it, please follow these steps:`)}</p>
          <ol>
            <li>
              {l.translate(
                `Go to "<a class="c-pointer text-primary" onclick="handleMessageTemplateClick">{message_template_name}</a>" Message Template and make necessary edits to fix the problems (update variables, add variable examples, etc.)`,
                { message_template_name, handleMessageTemplateClick },
              )}
            </li>
            <li>{l.translate(`Save changes by sending it for review.`)}</li>
            <li>
              {l.translate(`Create a NEW bot template containing the same content as the old one.`)}
            </li>
            <li>
              {l.translate(
                `Since the original "{bot_template_name}" template will remain corrupted, we recommend updating its description with the link to the NEW bot template.`,
                { bot_template_name },
              )}
            </li>
          </ol>
        </div>
      </Modal>
    </>
  )
}

import z from 'zod'

import { GenericFieldType } from './types'

export const GenericCustomFieldSchema = z.object({
  type: z.literal(GenericFieldType.USER).or(z.literal(GenericFieldType.GLOBAL)),
  field_id: z.number(),
})

export const GenericSystemFieldSchema = z.object({
  type: z.literal(GenericFieldType.SYSTEM),
  field_name: z.string(),
})

import assert from 'assert'

import has from 'lodash/has'
import keys from 'lodash/keys'

import { updateNode as update } from 'common/builder/actions/nodeActions'
import nodeRegistry from 'common/builder/nodeRegistry'
import { getNodeById } from 'common/builder/selectors/builder/entitySelectors'
import errorTrackingService from 'utils/services/errorTrackingService'

/**
 * @param builderId
 * @param nodeId
 * @param changes
 */
export const updateNode = (builderId, nodeId, changes) => {
  return (dispatch, getState) => {
    assert(builderId, `updateNode: builderId is required param`)
    assert(nodeId, `updateNode: nodeId is required param`)
    assert(!has(changes, 'id'), `updateNode: attribute "id" cannot be changed`)
    assert(!has(changes, 'targetId'), `updateNode: attribute "targetId" cannot be changed`)

    const node = getNodeById(getState(), builderId, nodeId)
    const attributeNames = nodeRegistry.get(node).getAttributes()
    keys(changes).forEach((attr) => {
      if (!attributeNames.includes(attr)) {
        console.warn(`updateNode: attribute "${attr}" isn't listed in NodeConfig defaults`)
        const payload = { attr, nodeType: node.nodeType }
        errorTrackingService.trackWarningOnce('Node unknown attrs', {
          extra: payload,
          fingerprint: 'node-unknown-attrs',
        })
      }
    })
    dispatch(update(builderId, nodeId, changes))
  }
}

import { createAction, createReducer } from '@reduxjs/toolkit'

import { IBulkActionNotificationState, IBulkActionNotification } from './bulkActionsInterfaces'

export const bulkActionStatsUpdate = createAction<IBulkActionNotification | null>(
  'BULK_ACTION_STATUS_UPDATE',
)

const initialState: IBulkActionNotificationState = {
  status: null,
}

const bulkActionsReducer = createReducer(initialState, (builder) => {
  builder.addCase(bulkActionStatsUpdate, (state, action) => {
    state.status = action.payload
  })
})

export const getBulkActionStatus = (state: RootState): IBulkActionNotification | null => {
  return state.bulkActions.status
}

export const getIsBulkCompleted = (state: RootState): boolean => {
  const bulkActionStatus = getBulkActionStatus(state)

  return bulkActionStatus !== null && bulkActionStatus.done
}

export const getIsBulkInProgress = (state: RootState): boolean => {
  const bulkActionStatus = getBulkActionStatus(state)
  const bulkProgress = bulkActionStatus?.progress || null

  return Boolean(bulkActionStatus) && typeof bulkProgress === 'number' && !bulkActionStatus?.done
}

export default bulkActionsReducer

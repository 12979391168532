import has from 'lodash/has'
import isEmpty from 'lodash/isEmpty'

import aiIntentTriggerAdapter from 'apps/aiIntents/model/adapter'
import defaultReplyAdapter from 'apps/defaultReply/models/defaultReplyAdapter'
import externalTriggerAdapter from 'apps/externalTriggers/externalTriggerAdapter'
import widgetAdapter from 'apps/growthTools/models/Widget/adapter'
import keywordAdapter from 'apps/keywords/models/KeywordRule/adapter'
import { parseStoryMentionReply } from 'apps/storyMentionReply/parseStoryMentionReply'
import ruleAdapter from 'apps/triggerRules/models/TriggerRule/adapter'
import welcomeMessageAdapter from 'apps/welcomeMessage/models/welcomeMessageAdapter'
import { cartReplyAdapter } from 'apps/whatsApp/adapters/cartReplyAdapter'
import { TriggerType } from 'common/flow/constants/TriggerType'
import { isBackendFullFlow } from 'common/flow/flowBackendHelpers'
import {
  IBackendFullFlow,
  IDraftBatch,
  IFlowContentCoordinates,
  BackendMainMenuTrigger,
} from 'common/flow/flowBackendInterfaces'
import { anotherFlowAdapter, iceBreakerAdapter, parseMainManu } from 'common/flow/flowHelpers'
import { IFlow, IFullFlow } from 'common/flow/flowInterfaces'
import { parseLightFlow } from 'common/flow/models/Flow/parseLightFlow'
import { LightFlow } from 'shared/api/common/schemas/lightFlow'

export const parseFlow = (data: LightFlow | IBackendFullFlow): IFlow => {
  const flow = parseLightFlow(data)

  if (!isBackendFullFlow(data)) {
    return flow
  }

  const widgets = (data.triggers?.widgets ?? []).map(widgetAdapter)
  const keywords = (data.triggers?.keywords ?? []).map(keywordAdapter)
  const aiIntents = (data.triggers?.intents ?? []).map(aiIntentTriggerAdapter)
  const rules = (data.triggers?.trigger_rules ?? []).map(ruleAdapter)
  const defaultReply = (data.triggers?.default_replies ?? []).map(defaultReplyAdapter)
  const cartReply = (data.triggers?.cart_replies ?? []).map(cartReplyAdapter)
  const welcomeMessages = (data.triggers?.welcome_messages ?? []).map(welcomeMessageAdapter)
  const externalTriggers = (data.external_triggers ?? []).map(externalTriggerAdapter)
  const storyMentionReply = (data.triggers?.story_mentions ?? []).map(parseStoryMentionReply)
  const conversationsStarter = (data.triggers?.conversation_starters ?? []).map(iceBreakerAdapter)

  const anotherFlowTriggers = data.triggers?.another_flow ?? []

  const anotherFlowDefaultType = anotherFlowTriggers
    .filter((flowTrigger) => flowTrigger.type === 'default')
    .map(anotherFlowAdapter)

  const anotherFlowMainMenuType = anotherFlowTriggers.filter(
    (flowTrigger): flowTrigger is BackendMainMenuTrigger =>
      flowTrigger.type === TriggerType.MAIN_MENU,
  )
  const mainMenuItems = parseMainManu(anotherFlowMainMenuType)

  const draftBatch = (isEmpty(data.draft_batch) ? {} : data.draft_batch) as IDraftBatch
  let draftContentId = undefined
  if (has(draftBatch, 'root_content')) {
    const EMPTY_ROOT_ID_PLACEHOLDER = ''
    draftContentId =
      draftBatch.root_content === EMPTY_ROOT_ID_PLACEHOLDER ? null : draftBatch.root_content
  }

  const fullFlow: IFullFlow = {
    ...flow,
    chId: data.content_holder ?? null,
    isFullDataLoaded: true,

    // published
    contentId: data.root_content_id ?? null,
    contents: data.contents ?? [],
    coordinates: (isEmpty(data.coordinates) ? {} : data.coordinates) as IFlowContentCoordinates,

    // published triggers
    triggers: [
      ...widgets,
      ...keywords,
      ...aiIntents,
      ...rules,
      ...externalTriggers,
      ...conversationsStarter,
      ...mainMenuItems,
      ...storyMentionReply,
      ...anotherFlowDefaultType,
      ...defaultReply,
      ...cartReply,
      ...welcomeMessages,
    ],

    // draft changes
    draftContentId,
    draftContents: isEmpty(draftBatch.contents) || !draftBatch.contents ? [] : draftBatch.contents,
    draftCoordinates: (isEmpty(data.draft_coordinates)
      ? {}
      : data.draft_coordinates) as IFlowContentCoordinates,

    stats: data.stats ?? {
      sent_total: 0,
      sent_unq: 0,
      delivered_total: 0,
      delivered_unq: 0,
      read_total: 0,
      read_unq: 0,
      clicked_total: 0,
      clicked_unq: 0,
      earned: null,
      earned_list: [],
      events: [],
    },
    flow_stats: data.flow_stats ?? null,
  }

  return fullFlow
}

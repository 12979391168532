import { useCallback, useEffect, useState } from 'react'
import cx from 'classnames'

import app from 'common/builder/components/chart/FlowChart/app'
import errorTrackingService from 'utils/services/errorTrackingService'

import cm from './PointerBlockArea/PointerBlockArea.module.css'

export const useDomUpdates = (callback: () => void, enabled = true) => {
  useEffect(() => {
    if (!enabled) {
      return
    }

    const observer = new MutationObserver(() => {
      callback()
    })

    observer.observe(document.body, {
      childList: true,
      subtree: true,
    })

    document.addEventListener('animationend', callback, false) // standard + firefox
    document.addEventListener('MSAnimationEnd', callback, false) // IE
    document.addEventListener('webkitAnimationEnd', callback, false)

    return () => {
      if (!observer) errorTrackingService.trackError('[35201]: observer is not presented')

      observer?.disconnect()

      document.removeEventListener('animationend', callback, false) // standard + firefox
      document.removeEventListener('MSAnimationEnd', callback, false) // IE
      document.removeEventListener('webkitAnimationEnd', callback, false)
    }
  }, [enabled, callback])
}

export const useFlowChartUpdates = (callback: () => void, enabled = true) => {
  useEffect(() => {
    if (!enabled) {
      return
    }

    app.on('willRender', callback)

    return () => {
      app.removeListener('willRender', callback)
    }
  }, [callback, enabled])
}

export const useScreenResize = (callback: () => void, enabled = true) => {
  useEffect(() => {
    if (!enabled) {
      return
    }

    window.addEventListener('resize', callback, true)
    return () => {
      window.removeEventListener('resize', callback, true)
    }
  }, [callback, enabled])
}

export const useScreenScroll = (callback: () => void, enabled = true) => {
  useEffect(() => {
    if (!enabled) {
      return
    }

    window.addEventListener('scroll', callback, true)
    return () => {
      window.removeEventListener('scroll', callback, true)
    }
  }, [callback, enabled])
}

export const usePulseAnim = (): [string, string, () => void] => {
  const [pulsing, setPulse] = useState(false)

  const areaResizeClass = cx({ [cm.resize]: pulsing })
  const areaPulseClass = cx({ [cm.pulse]: pulsing })

  const pulseIt = useCallback(() => {
    setPulse(true)
    setTimeout(() => setPulse(false), 1100)
  }, [])

  return [areaResizeClass, areaPulseClass, pulseIt]
}

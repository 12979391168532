import { ChannelType } from 'common/core/constants/ChannelType'

import { FieldGroupId, FieldStatus, FieldType, FieldSegmentType } from './enums'

export interface Field {
  id: string
  type: FieldType
  group: FieldGroupId
  segmentType?: FieldSegmentType
  status?: FieldStatus
  caption: string
  channel?: ChannelType
  description?: string
  folderId?: number
  integrationId?: string
  contextName?: string
  part?: 'Year' | 'Month' | 'Day' | 'Time' | 'Time Zone'
  count?: number
}

export const FullContactDataField: Field = {
  id: 'subscriber_data',
  group: FieldGroupId.SYSTEM,
  caption: 'Full Contact Data',
  type: FieldType.TEXT,
}

export const FACEBOOK_NATIVE_FIELDS: Field[] = [
  { id: 'first_name', caption: 'First Name', type: FieldType.TEXT, group: FieldGroupId.SYSTEM },
  { id: 'last_name', caption: 'Last Name', type: FieldType.TEXT, group: FieldGroupId.SYSTEM },
  { id: 'full_name', caption: 'Full Name', type: FieldType.TEXT, group: FieldGroupId.SYSTEM },
]

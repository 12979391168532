import React, { useRef, useState } from 'react'
import cx from 'classnames'
import { z } from 'zod'
import { Lozenge, Icon, l } from '@manychat/manyui'

import { BlockType } from '../../constants'
import { useDefaultContext } from '../../hooks'
import { registry } from '../../registry'
import { ClassNameSchema } from '../../schemas'
import { BlockProps } from '../../types'

import cm from './VerticalVideo.module.css'

const VerticalVideoPropsSchema = z.object({
  className: ClassNameSchema,
  src: z.string().url(),
  backgroundImg: z.string(),
})

const Component = ({
  className,
  src,
  backgroundImg,
  blockId,
}: BlockProps<typeof VerticalVideoPropsSchema>) => {
  const { sendEvent } = useDefaultContext()
  const [isVideoVisible, setIsVideoVisible] = useState(false)
  const videoRef = useRef<HTMLVideoElement>(null)

  const playVideo = () => {
    setIsVideoVisible(true)

    sendEvent('BILLING.UPGRADE_MODAL.AI_VIDEO.PLAY')
    videoRef.current?.play()
  }

  return (
    <div className={cx(cm.video, className)} data-block={blockId}>
      {!isVideoVisible && (
        <div className={cm.videoPreview}>
          <div className={cm.videoPreview__content}>
            <Lozenge type="ai" size="large" className={cm.videoPreview__badge} />
            <button
              onClick={playVideo}
              aria-label={l.translate('Watch a short video to learn more about AI Features.')}
              className={cm.videoPreview__playButton}
            >
              <Icon.Play size={36} />
            </button>

            <p className={cm.videoPreview__description}>
              {l.translate(
                '<l10n context="Manychat AI is untranslatable"/>Manychat AI in 2 minutes',
              )}
            </p>
          </div>

          <img className={cm.videoPreview__backgroundIllustration} src={backgroundImg} alt="" />
        </div>
      )}

      <video
        ref={videoRef}
        src={src}
        className={cm.videoPlayer}
        aria-hidden={!isVideoVisible}
        controls={isVideoVisible}
      />
    </div>
  )
}

export const VerticalVideo = registry.register(Component, {
  props: VerticalVideoPropsSchema,
  type: BlockType.VERTICAL_VIDEO,
  previewProps: {
    src: 'https://manychat-storage.s3.eu-central-1.amazonaws.com/manychat/ca/2024/07/29/ed044ffa221b4a47ef01a6f82b88013c/ai_video_relevant.mp4',
    backgroundImg: '/assets/assets/videoBackground-7PDZG7KC.svg',
  },
})

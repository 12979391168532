import { Icon, l, IconComponent } from '@manychat/manyui'

import { IAttachmentBlock } from 'common/builder/blocks/blockInterfaces'
import { NodeType } from 'common/builder/constants/NodeType'

export enum AttachmentBlockAttachmentType {
  FILE = 'file',
  AUDIO = 'audio',
  VIDEO = 'video',
  IMAGE = 'image',
  GIF = 'gif',
  EXTERNAL_IMAGE = 'remote_image',
}

export enum TelegramVideoBlockType {
  CIRCLE = 'circle',
  REGULAR = 'regular',
}

export const AttachmentBlockAttachmentIconsMap: Partial<
  Record<AttachmentBlockAttachmentType | TelegramVideoBlockType, IconComponent>
> = {
  [AttachmentBlockAttachmentType.FILE]: Icon.Attach,
  [AttachmentBlockAttachmentType.AUDIO]: Icon.Audio,
  [AttachmentBlockAttachmentType.VIDEO]: Icon.Video,
  [AttachmentBlockAttachmentType.IMAGE]: Icon.Image,
  [TelegramVideoBlockType.CIRCLE]: Icon.VideoCircleTg,
}

const FallbackAttachmentIcon = Icon.Attach

const IconAttachmentMapping: Array<{
  predicate(entity: IAttachmentBlock): boolean
  icon: Icon.Icon
}> = [
  {
    predicate: (entity: IAttachmentBlock) =>
      entity.attachmentType === AttachmentBlockAttachmentType.AUDIO,
    icon: Icon.Audio,
  },
  {
    predicate: (entity: IAttachmentBlock) =>
      entity.attachmentType === AttachmentBlockAttachmentType.IMAGE,
    icon: Icon.Image,
  },
  {
    predicate: (entity: IAttachmentBlock) =>
      entity.telegramVideoType === TelegramVideoBlockType.CIRCLE,
    icon: Icon.VideoCircleTg,
  },
  {
    predicate: (entity: IAttachmentBlock) =>
      entity.attachmentType === AttachmentBlockAttachmentType.VIDEO,
    icon: Icon.Video,
  },
]

export const getAttachmentIcon = (entity?: IAttachmentBlock) =>
  (entity && IconAttachmentMapping.find(({ predicate }) => predicate(entity))?.icon) ||
  FallbackAttachmentIcon

export const AttachmentBlockAttachmentPlaceholder: Record<string, () => string> = {
  [AttachmentBlockAttachmentType.FILE]: l.makeTranslate('File'),
  FILE: l.makeTranslate('File'),
  [AttachmentBlockAttachmentType.AUDIO]: l.makeTranslate('Audio'),
  AUDIO: l.makeTranslate('Audio'),
  [AttachmentBlockAttachmentType.VIDEO]: l.makeTranslate('Video'),
  VIDEO: l.makeTranslate('Video'),
  [AttachmentBlockAttachmentType.IMAGE]: l.makeTranslate('Image'),
  IMAGE: l.makeTranslate('Image'),
}
export const AttachmentBlockAttachmentAcceptDefault: Record<string, string | null> = {
  [AttachmentBlockAttachmentType.FILE]: null,
  FILE: null,
  [AttachmentBlockAttachmentType.AUDIO]: 'audio/*',
  AUDIO: 'audio/*',
  [AttachmentBlockAttachmentType.VIDEO]: 'video/mp4,video/x-m4v,video/*',
  VIDEO: 'video/mp4,video/x-m4v,video/*',
  [AttachmentBlockAttachmentType.IMAGE]: 'image/*',
  IMAGE: 'image/*',
}

export const AttachmentBlockAttachmentAcceptSpecific: Partial<
  Record<NodeType, Record<string, string | null>>
> = {
  [NodeType.TELEGRAM]: {
    ...AttachmentBlockAttachmentAcceptDefault,
    [AttachmentBlockAttachmentType.VIDEO]: 'video/mp4,video/x-m4v,.mov',
    VIDEO: 'video/mp4,video/x-m4v,.mov',
    VIDEO_MESSAGES: 'video/mp4,video/x-m4v',
    [AttachmentBlockAttachmentType.AUDIO]: 'audio/mpeg,audio/m4a,audio/ogg,.ogg,.m4a,.mp3',
    [AttachmentBlockAttachmentType.FILE]:
      'application/pdf,.pdf,application/zip,.zip,image/gif,.gif',
  },
  [NodeType.WHATS_APP]: {
    [AttachmentBlockAttachmentType.VIDEO]: 'video/mp4,video/3gpp,.mp4,.3gpp',
    [AttachmentBlockAttachmentType.AUDIO]: 'audio/aac,audio/ogg,audio/mpeg,.ogg,.mp3,.aac',
    [AttachmentBlockAttachmentType.FILE]: 'application/pdf,application/zip,.pdf,.zip',
    [AttachmentBlockAttachmentType.IMAGE]: 'image/jpeg,image/png,image/gif,.jpeg,.jpg,.png,.gif',
  },
}

export const AttachmentSizeMap: Partial<Record<NodeType, Record<string, number>>> = {
  [NodeType.WHATS_APP]: {
    [AttachmentBlockAttachmentType.IMAGE]: 5 * 1024 * 1024,
    [AttachmentBlockAttachmentType.VIDEO]: 14 * 1024 * 1024,
    [AttachmentBlockAttachmentType.AUDIO]: 14 * 1024 * 1024,
    [AttachmentBlockAttachmentType.FILE]: 14 * 1024 * 1024,
  },
}

export enum ListBlockTopElementStyle {
  COMPACT = 'compact',
  LARGE = 'large',
}

export enum CardsBlockImageAspectRatioType {
  SQUARE = 'square',
  HORIZONTAL = 'horizontal',
}

export enum QuestionBlockReplyType {
  FREE_TEXT = 'free_text',
  EMAIL = 'email',
  PHONE = 'phone',
  MULTIPLE_CHOICE = 'multiple_choice',
  NUMBER = 'number',
  FIRST_NAME = 'first_name',
  LAST_NAME = 'last_name',
  URL = 'url',
  FILE = 'file',
  IMAGE = 'image',
  LOCATION = 'location',
  MC_LOCATION = 'mc_location',
  DATE = 'date',
  DATE_TIME = 'datetime',
  DEPRECATED_PHONE_FOR_SMS = 'phone_for_sms',
}

export const QuestionBlockReplyLabel: Record<QuestionBlockReplyType, () => string> = {
  [QuestionBlockReplyType.FREE_TEXT]: l.makeTranslate('Text'),
  [QuestionBlockReplyType.MULTIPLE_CHOICE]: l.makeTranslate('Multiple Choice'),
  [QuestionBlockReplyType.NUMBER]: l.makeTranslate('Number'),
  [QuestionBlockReplyType.FIRST_NAME]: l.makeTranslate('First name'),
  [QuestionBlockReplyType.LAST_NAME]: l.makeTranslate('Last name'),
  [QuestionBlockReplyType.EMAIL]: l.makeTranslate('Email'),
  [QuestionBlockReplyType.PHONE]: l.makeTranslate('Phone'),
  [QuestionBlockReplyType.URL]: l.makeTranslate('URL'),
  [QuestionBlockReplyType.FILE]: l.makeTranslate('File'),
  [QuestionBlockReplyType.IMAGE]: l.makeTranslate('Image'),
  [QuestionBlockReplyType.LOCATION]: l.makeTranslate('Location'),
  [QuestionBlockReplyType.MC_LOCATION]: l.makeTranslate('Location'),
  [QuestionBlockReplyType.DATE]: l.makeTranslate('Date'),
  [QuestionBlockReplyType.DATE_TIME]: l.makeTranslate('Date and time'),
  [QuestionBlockReplyType.DEPRECATED_PHONE_FOR_SMS]: () => '',
}

export const QuiestionBlockDefaultReplyTypeText: Partial<
  Record<QuestionBlockReplyType, () => string>
> = {
  [QuestionBlockReplyType.EMAIL]: l.makeTranslate(
    'Before you continue, please send your email so we can contact you later',
  ),
  [QuestionBlockReplyType.PHONE]: l.makeTranslate(
    'Please send your phone number with a country code so we can contact you later',
  ),
  //
}

export enum VariantBlockTitles {
  A = 'A',
  B = 'B',
  C = 'C',
  D = 'D',
  E = 'E',
  F = 'F',
}

export const VariantBlockTitleSequence = [
  VariantBlockTitles.A,
  VariantBlockTitles.B,
  VariantBlockTitles.C,
  VariantBlockTitles.D,
  VariantBlockTitles.E,
  VariantBlockTitles.F,
]

export const VariantBlockColors = {
  [VariantBlockTitles.A]: '0175E1',
  [VariantBlockTitles.B]: '00D7AA',
  [VariantBlockTitles.C]: 'CA62DF',
  [VariantBlockTitles.D]: 'EF9202',
  [VariantBlockTitles.E]: '00B2E4',
  [VariantBlockTitles.F]: 'ACDA02',
}

export enum EmailStyleFontTypePreset {
  H1 = 'h1',
  H2 = 'h2',
  REGULAR = 'regular',
  SMALL = 'small',
}

export enum EmailStyleAlignmentPreset {
  LEFT = 'left',
  RIGHT = 'right',
  CENTER = 'center',
  JUSTIFY = 'justify',
}

export const EmailStyleAlignmentMap: Record<EmailStyleAlignmentPreset, string | null> = {
  [EmailStyleAlignmentPreset.LEFT]: 'justify-start',
  [EmailStyleAlignmentPreset.CENTER]: 'justify-center',
  [EmailStyleAlignmentPreset.RIGHT]: 'justify-end',
  [EmailStyleAlignmentPreset.JUSTIFY]: null,
}

export enum EmailStyleImageSizePreset {
  SMALL = 'small',
  MEDIUM = 'medium',
  LARGE = 'large',
  FULL = 'full',
  ORIGINAL = 'original',
}

export enum EmailStyleSpaceSizePreset {
  SMALL = 'small',
  MEDIUM = 'medium',
  LARGE = 'large',
}

export const EmailStyleSpaceSizeMap = {
  [EmailStyleSpaceSizePreset.SMALL]: 6,
  [EmailStyleSpaceSizePreset.MEDIUM]: 12,
  [EmailStyleSpaceSizePreset.LARGE]: 16,
}

export enum QuestionBlockTimeoutUnitType {
  SECONDS = 'seconds',
  MINUTE = 'minutes',
  HOURS = 'hours',
  MONTHS = 'months',
}

import { LayoutWithSidebar } from 'utils/router/components/LayoutWithSidebar/LayoutWithSidebar'

export const PostingRoutes = [
  {
    path: 'posting',
    Component: LayoutWithSidebar,
    children: [
      {
        index: true,
        lazy: () => import('./broadcasts.page'),
      },

      {
        path: 'drafts',
        children: [
          {
            index: true,
            lazy: () => import('./drafts.page'),
          },
          {
            path: ':post_id/:mode?/:content_id?',
            lazy: () => import('./BroadcastBuilder/BroadcastBuilder.page'),
          },
        ],
      },
      {
        path: 'history',
        children: [
          {
            index: true,
            lazy: () => import('./history.page'),
          },
          {
            path: ':post_id/:mode?/:content_id?',
            lazy: () => import('./BroadcastBuilder/BroadcastBuilder.page'),
          },
        ],
      },
      {
        path: 'scheduled',
        children: [
          {
            index: true,
            lazy: () => import('./scheduled.page'),
          },
          {
            path: ':post_id/:mode?/:content_id?',
            lazy: () => import('./BroadcastBuilder/BroadcastBuilder.page'),
          },
        ],
      },
      {
        path: 'scheduled-success',
        lazy: () => import('./scheduled-success.page'),
      },
      {
        path: 'sent-success',
        lazy: () => import('./sent-success.page'),
      },
    ],
  },
] as const

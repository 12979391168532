import React from 'react'

import { IconProps } from '../interfaces'

export const DecrementalIcon = ({ className }: IconProps) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="8"
      height="5"
      viewBox="0 0 8 5"
      className={className}
      aria-hidden="true"
    >
      <path d="M2.86863 3.86863C3.26465 4.26465 3.46265 4.46265 3.69098 4.53684C3.89183 4.6021 4.10817 4.6021 4.30902 4.53684C4.53735 4.46265 4.73535 4.26465 5.13137 3.86863L6.26863 2.73137C7.12538 1.87462 7.55376 1.44624 7.5827 1.07846C7.60782 0.759341 7.47864 0.447485 7.23523 0.239594C6.95471 0 6.34889 0 5.13726 0H2.86274C1.65111 0 1.04529 0 0.764766 0.239594C0.521356 0.447485 0.392181 0.759341 0.417296 1.07846C0.446241 1.44624 0.874618 1.87462 1.73137 2.73137L2.86863 3.86863Z" />
    </svg>
  )
}

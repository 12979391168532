import React, { useState } from 'react'
import { useAppDispatch } from 'reduxTyped'

import { connectSmsByTwilio, closeModalConnectSms } from 'apps/sms/actions/smsActions'
import { analyticsService } from 'utils/services/analytics'

export const useSmsConnectionData = () => {
  const dispatch = useAppDispatch()
  const [isFormOpen, setOpenForm] = useState(false)

  const [loading, setLoading] = useState(false)
  const [token, setTokenValue] = useState('')
  const [phone, setPhoneValue] = useState('')
  const [sid, setSidValue] = useState('')

  const handleChangeToken = (e: React.ChangeEvent<HTMLInputElement>) => {
    const {
      target: { value },
    } = e

    setTokenValue(value)
  }

  const handleChangePhone = (e: React.ChangeEvent<HTMLInputElement>) => {
    const {
      target: { value },
    } = e

    const phone = value[0] === '+' ? value : `+${value}`

    setPhoneValue(phone)
  }

  const handleChangeSid = (e: React.ChangeEvent<HTMLInputElement>) => {
    const {
      target: { value },
    } = e

    setSidValue(value)
  }

  const handleCloseModal = () => {
    if (isFormOpen) {
      analyticsService.sendEvent('SMS_NEW_PARADIGM.CONNECT_MODAL.CLOSE')
    } else {
      analyticsService.sendEvent('SMS_NEW_PARADIGM.INFO_MODAL.CLOSE')
    }

    setOpenForm(false)
    dispatch(closeModalConnectSms())
  }

  const handleOpenModal = () => {
    analyticsService.sendEvent('SMS_NEW_PARADIGM.CONNECT_MODAL.OPEN')
    setOpenForm(true)
  }

  const handleConnect = async () => {
    setLoading(true)
    try {
      await dispatch(connectSmsByTwilio(sid, token, phone))
      analyticsService.sendEvent('SMS_NEW_PARADIGM.CONNECT_MODAL.TRY_CONNECT')
      window.location.reload()
    } catch (_err) {}
    setLoading(false)
  }

  return {
    handleChangePhone,
    handleChangeToken,
    handleChangeSid,
    sid,
    phone,
    token,
    handleCloseModal,
    handleConnect,
    isFormOpen,
    loading,
    handleOpenModal,
  }
}

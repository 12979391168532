import { SmsPhoneData } from 'shared/api/requests/sms/schemas'

export enum SmsMessageType {
  TEXT_MESSAGE = 'text_message',
  MULTIMEDIA_MESSAGE = 'multimedia_message',
}

export enum FetchStatus {
  NOT_FETCHED = 'not_fetched',
  FETCHING = 'fetching',
  FETCHED = 'fetched',
}

export interface SmsState {
  fetchStatus: FetchStatus
  phones: SmsPhoneData[]
  isNewConnectModalOpen: boolean
}

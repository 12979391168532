import React, { ReactNode } from 'react'
import cx from 'classnames'

import cm from './ChatMessageList.module.css'

interface MessageListProps {
  children: ReactNode
  place?: 'top' | 'bottom'
}

export function ChatMessageList({ place, children }: MessageListProps) {
  return (
    <div
      className={cx(cm.list, { [cm.positionBottom]: place === 'bottom' })}
      data-test-id="chat-message-list"
    >
      {children}
    </div>
  )
}

ChatMessageList.displayName = 'Chat.MessageList'

import React, { useMemo } from 'react'
import cx from 'classnames'
import { Icon } from '@manychat/manyui'

import cm from './Channels.module.css'

const ChannelIcon = {
  fb: Icon.MessengerChannel,
  sms: Icon.SmsChannel,
  email: Icon.EmailChannel,
  guest: Icon.GuestChatChannel,
  whatsapp: Icon.WhatsAppChannel,
  instagram: Icon.InstagramChannel,
  telegram: Icon.TelegramChannel,
  tiktok: Icon.TikTokChannel,
} as const

type Channel = keyof typeof ChannelIcon

const isChannel = (value: unknown): value is Channel =>
  Object.keys(ChannelIcon).includes(value as string)

interface ChannelsProps {
  className?: string
  channels?: string[]
}

export const Channels = ({ channels = Object.keys(ChannelIcon), className }: ChannelsProps) => {
  const safeChannels = useMemo(() => [...new Set(channels)].filter(isChannel), [channels])

  return (
    <div className={cx(cm.container, 'd-flex flex-row', className)}>
      {safeChannels
        .map((channel) => [channel, ChannelIcon[channel]] as const)
        .map(([channel, Icon]) => (
          <Icon key={channel} size={16} />
        ))}
    </div>
  )
}

import React from 'react'
import cx from 'classnames'
import { l, Icon } from '@manychat/manyui'

import cm from './TriggersToggle.module.css'

interface TriggersToggleProps {
  isOpen: boolean
  onToggle: (isOpen: boolean) => void
}

export const TriggersToggle = ({ isOpen, onToggle }: TriggersToggleProps) => {
  const onClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation()
    onToggle(!isOpen)
  }

  return (
    <button onClick={onClick} className={cm.triggersToggle}>
      <span className="text-sm">{isOpen ? l.translate('Hide All') : l.translate('Show All')}</span>

      <Icon.ChevronDown
        className={cx(cm.triggersToggle__icon, isOpen && cm.triggersToggle__icon_rotated)}
        size={16}
      />
    </button>
  )
}

export enum TikTokAdAppType {
  MESSENGER = 'MESSENGER',
  WHATSAPP = 'WHATSAPP',
  WEBSITE = 'WEBSITE',
  IM_URL = 'IM_URL',
}

export enum TikTokAdPromotionType {
  SOCIAL_MEDIA_APP = 'LEAD_GEN_CLICK_TO_SOCIAL_MEDIA_APP_MESSAGE',
  TT_DIRECT = 'LEAD_GEN_CLICK_TO_TT_DIRECT_MESSAGE',
}

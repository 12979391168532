let apple_phone = /iPhone/i,
  apple_ipod = /iPod/i,
  apple_tablet = /iPad/i,
  android_phone = /(?=.*\bAndroid\b)(?=.*\bMobile\b)/i, // Match 'Android' AND 'Mobile'
  android_tablet = /Android/i,
  amazon_phone = /(?=.*\bAndroid\b)(?=.*\bSD4930UR\b)/i,
  amazon_tablet =
    /(?=.*\bAndroid\b)(?=.*\b(?:KFOT|KFTT|KFJWI|KFJWA|KFSOWI|KFTHWI|KFTHWA|KFAPWI|KFAPWA|KFARWI|KFASWI|KFSAWI|KFSAWA)\b)/i,
  windows_phone = /IEMobile/i,
  windows_tablet = /(?=.*\bWindows\b)(?=.*\bARM\b)/i, // Match 'Windows' AND 'ARM'
  other_blackberry = /BlackBerry/i,
  other_blackberry_10 = /BB10/i,
  other_opera = /Opera Mini/i,
  other_chrome = /(CriOS|Chrome)(?=.*\bMobile\b)/i,
  other_firefox = /(?=.*\bFirefox\b)(?=.*\bMobile\b)/i, // Match 'Firefox' AND 'Mobile'
  manyChat_android_app = /ManyChat Version/i,
  manyChat_iOS_app = /MC.ManyChat/i,
  seven_inch = new RegExp(
    '(?:' + // Non-capturing group
      'Nexus 7' + // Nexus 7
      '|' + // OR
      'BNTV250' + // B&N Nook Tablet 7 inch
      '|' + // OR
      'Kindle Fire' + // Kindle Fire
      '|' + // OR
      'Silk' + // Kindle Fire, Silk Accelerated
      '|' + // OR
      'GT-P1000' + // Galaxy Tab 7 inch
      ')', // End non-capturing group

    'i',
  ) // Case-insensitive matching

const match = function (regex, userAgent) {
  return regex.test(userAgent)
}

const IsMobileClass = function (userAgent) {
  let ua = userAgent || window.navigator.userAgent

  // Facebook mobile app's integrated browser adds a bunch of strings that
  // match everything. Strip it out if it exists.
  let tmp = ua.split('[FBAN')
  if (typeof tmp[1] !== 'undefined') {
    ua = tmp[0]
  }

  // Twitter mobile app's integrated browser on iPad adds a "Twitter for
  // iPhone" string. Same probable happens on other tablet platforms.
  // This will confuse detection so strip it out if it exists.
  tmp = ua.split('Twitter')
  if (typeof tmp[1] !== 'undefined') {
    ua = tmp[0]
  }

  this.apple = {
    phone: match(apple_phone, ua),
    ipod: match(apple_ipod, ua),
    tablet: !match(apple_phone, ua) && match(apple_tablet, ua),
    device: match(apple_phone, ua) || match(apple_ipod, ua) || match(apple_tablet, ua),
  }
  this.amazon = {
    phone: match(amazon_phone, ua),
    tablet: !match(amazon_phone, ua) && match(amazon_tablet, ua),
    device: match(amazon_phone, ua) || match(amazon_tablet, ua),
  }
  this.android = {
    phone: match(amazon_phone, ua) || match(android_phone, ua),
    tablet:
      !match(amazon_phone, ua) &&
      !match(android_phone, ua) &&
      (match(amazon_tablet, ua) || match(android_tablet, ua)),
    device:
      match(amazon_phone, ua) ||
      match(amazon_tablet, ua) ||
      match(android_phone, ua) ||
      match(android_tablet, ua),
  }
  this.windows = {
    phone: match(windows_phone, ua),
    tablet: match(windows_tablet, ua),
    device: match(windows_phone, ua) || match(windows_tablet, ua),
  }
  this.other = {
    blackberry: match(other_blackberry, ua),
    blackberry10: match(other_blackberry_10, ua),
    opera: match(other_opera, ua),
    firefox: match(other_firefox, ua),
    chrome: match(other_chrome, ua),
    device:
      match(other_blackberry, ua) ||
      match(other_blackberry_10, ua) ||
      match(other_opera, ua) ||
      match(other_firefox, ua) ||
      match(other_chrome, ua),
  }
  this.seven_inch = match(seven_inch, ua)
  this.isManyChatApp = match(manyChat_android_app, ua) || match(manyChat_iOS_app, ua)
  this.any =
    this.apple.device ||
    this.android.device ||
    this.windows.device ||
    this.other.device ||
    this.seven_inch

  // excludes 'other' devices and ipods, targeting touchscreen phones
  this.phone = this.apple.phone || this.android.phone || this.windows.phone

  // excludes 7 inch devices, classifying as phone or tablet is left to the user
  this.tablet = this.apple.tablet || this.android.tablet || this.windows.tablet

  if (typeof window === 'undefined') {
    return this
  }
}

const isMobile = new IsMobileClass()

export default isMobile.any
export const isAndroid = isMobile.android.device
export const isApple = isMobile.apple.device
export const isManyChatApp = isMobile.isManyChatApp

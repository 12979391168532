import dot from 'dot-prop-immutable'
import get from 'lodash/get'

import { makeDetailsReducer } from 'utils/factory'

import * as atypes from '../externalTriggersReduxActionTypes'

const detailsReducer = makeDetailsReducer('externalTriggers')

function externalTriggersDetailsReducer(state, action) {
  state = detailsReducer(state, action)

  switch (action.type) {
    case atypes.EXTERNALTRIGGERS_DELETE_RESPONSE: {
      if (get(state, `byId.${action.id}`)) {
        return dot.merge(state, `byId.${action.id}`, { item: null, isDeleted: true })
      }
      return state
    }
    case atypes.EXTERNALTRIGGERS_CHANGE_STATUS_REQUEST: {
      if (get(state, `byId.${action.id}.item`)) {
        return dot.merge(state, `byId.${action.id}.item`, {
          isPendingStatusChange: true,
        })
      }
      return state
    }
    case atypes.EXTERNALTRIGGERS_CHANGE_STATUS_RESPONSE: {
      if (get(state, `byId.${action.id}.item`)) {
        return dot.merge(state, `byId.${action.id}`, {
          item: { ...action.data.item, isPendingStatusChange: false },
        })
      }
      return state
    }
    case atypes.EXTERNALTRIGGERS_CHANGE_STATUS_ERROR: {
      if (get(state, `byId.${action.id}.item`)) {
        return dot.merge(state, `byId.${action.id}.item`, { isPendingStatusChange: false })
      }
      return state
    }

    case atypes.EXTERNALTRIGGERS_CURRENT_UPDATED_NOTIFICATION: {
      const { item } = action
      const { external_trigger_id: id, setTitle, caption, title } = item

      if (state.byId[id]?.item) {
        const newState = setTitle ? { caption, title } : item
        return dot.merge(state, `byId.${id}.item`, newState)
      }

      return state
    }
  }

  return state
}

export default function externalTriggersReducer(state, action) {
  state = externalTriggersDetailsReducer(state, action)

  return state
}

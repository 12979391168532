import { useCallback } from 'react'
import { useAppDispatch } from 'reduxTyped'

import { contentManagementActions } from 'apps/cms/store'
import { AutomationModalType, CmsModalsState } from 'apps/cms/store/lib/types'

export const useModalActions = () => {
  const dispatch = useAppDispatch()

  const openModal = useCallback(
    <ModalType extends AutomationModalType>({
      type,
      data,
    }: {
      type: ModalType
      data: CmsModalsState[AutomationModalType]['data']
    }) => {
      dispatch(
        contentManagementActions.openModal({
          type: type,
          data,
        }),
      )
    },
    [dispatch],
  )

  return { openModal }
}

import * as atypes from 'common/audience/constants/AudienceReduxActionTypes'
import { SELECTION_TYPE, StatusType } from 'common/audience/models/Subscriber'
import { FilterField } from 'common/filter/models/AudienceFilter/constants'
import { makeLimitedListReducer, mergeToListItem, deleteFromList } from 'utils/factory'

import { bulkActionRequest } from '../actions/bulkActions'
import { MIGRATE_CUF_TO_SF } from '../constants/BulkActionTypes'

import subscriberReducer from './subscriber'

const listReducer = makeLimitedListReducer('audience')

const defaultImportState = {
  file: null,
  hasImportError: false,
  headers: [],
  headersMap: {},
  importProgress: 0,
  isShowImportContactsModal: false,
  isCheckedAgreement: false,
  isImporting: false,
  defaultChannel: null,
  selectedExtraChannels: [],
  items: [],
  step: 'upload',
  tags: [],
  totalCountFailed: 0,
  totalCountImported: 0,
  totalCountMerged: 0,
}
const uploadState = {
  filter: null,
  searchQuery: '',
  selectionType: SELECTION_TYPE.INCLUDE,
  selectedIds: {},
  byId: {},
  isExportingData: false,
  isExportingComplete: false,
  exportSignature: null,
  import: defaultImportState,
  isShowCreateSubscriberModal: false,
  hasMigrationError: false,
  isShowMigrateCUFToSFModal: false,
  isCreatingSubscriber: false,
  isSubscriberCreated: false,
  createSubscriberValidationErrors: {
    email: [],
    phoneNumber: [],
    waId: [],
  },
}

export default function audienceReducer(state = uploadState, action) {
  state = listReducer(state, action)

  let current = subscriberReducer(state.current, action)
  if (current !== state.current) {
    state = { ...state, current }
  }

  // List actions
  switch (action.type) {
    case bulkActionRequest.rejected.type: {
      const bulkActionType = action.meta.arg.type
      if (bulkActionType === MIGRATE_CUF_TO_SF) {
        return { ...state, hasMigrationError: true }
      }

      return state
    }

    case atypes.AUDIENCE_SHOW_SUBSCRIBER_MODAL:
      return { ...state, showSubscriberModal: action.id }
    case atypes.AUDIENCE_HIDE_SUBSCRIBER_MODAL:
      return {
        ...state,
        showSubscriberModal: false,
        current: { item: null, hash: null, errors: null, fetching: false, hasChanges: false },
      }

    case atypes.AUDIENCE_SEARCH_QUERY_CHANGED:
      return { ...state, searchQuery: action.searchQuery }
    case atypes.AUDIENCE_FILTER_CHANGED:
      return { ...state, filter: action.filter || null }

    case atypes.AUDIENCE_SELECTION_TYPE_CHANGED: {
      const { selectionType } = action
      return { ...state, selectionType, selectedIds: {} }
    }
    case atypes.AUDIENCE_TOGGLE_SUBSCRIBER_CHECKED: {
      const { id } = action
      const selectedIds = { ...state.selectedIds }
      if (selectedIds[id]) {
        delete selectedIds[id]
      } else {
        selectedIds[id] = true
      }
      return { ...state, selectedIds }
    }
    case atypes.AUDIENCE_EXPORT_SUBSCRIBER_DATA_REQUEST: {
      return { ...state, isExportingData: true }
    }
    case atypes.AUDIENCE_EXPORT_SUBSCRIBER_DATA_RESPONSE: {
      const { done, signature } = action.data.async_status
      if (done) {
        return { ...state, isExportingData: false, isExportingComplete: true }
      }
      return { ...state, exportSignature: signature }
    }
    case atypes.AUDIENCE_EXPORT_SUBSCRIBER_DATA_ERROR: {
      return { ...state, isExportingData: false }
    }

    case atypes.AUDIENCE_UPDATE_EXPORTING_STATUS: {
      const { done, signature } = action.data.status
      if (done && signature === state.exportSignature) {
        return { ...state, isExportingData: false, isExportingComplete: true }
      }
      return state
    }

    case atypes.AUDIENCE_UPDATE_CURRENT_SUBSCRIBER: {
      const { subscriber } = action

      return {
        ...state,
        current: {
          ...state.current,
          item: {
            ...state.current.item,
            ...subscriber,
          },
        },
      }
    }

    case atypes.AUDIENCE_RESET_DOWNLOAD_CONFIRM: {
      return { ...state, isExportingData: false, isExportingComplete: false, exportSignature: null }
    }

    case atypes.AUDIENCE_DELETE_SUBSCRIBER_DATA_RESPONSE: {
      const { subscriber, isSubscribersNewDeletionEnabled } = action.data

      if (isSubscribersNewDeletionEnabled) {
        state = deleteFromList(state, subscriber.user_id)
      } else {
        state = mergeToListItem(state, subscriber.user_id, subscriber, true)
      }

      return { ...state, showSubscriberModal: false }
    }

    case atypes.AUDIENCE_BULK_DELETE_SUBSCRIBER_DATA: {
      const { subscribers, isCurrentUserOpen } = action.data
      let updatedState = { ...state }
      let updatedSelectedIds = { ...state.selectedIds }

      subscribers.forEach((subscriberId) => {
        updatedState = deleteFromList(updatedState, subscriberId)
        delete updatedSelectedIds[subscriberId]
      })
      updatedState.total = updatedState.total - subscribers.length

      if (isCurrentUserOpen) {
        return {
          ...updatedState,
          current: { item: null, hash: null, errors: null, fetching: false, hasChanges: false },
          showSubscriberModal: false,
        }
      }

      return { ...updatedState, selectedIds: updatedSelectedIds }
    }

    case atypes.AUDIENCE_UNSUBSCRIBE_RESPONSE: {
      if (state.byId[action.id]) {
        return mergeToListItem(state, action.id, { status: StatusType.UNSUBSCRIBED })
      } else {
        return state
      }
    }

    case atypes.AUDIENCE_UPDATE_PAUSE_AUTOMATION: {
      if (state.byId[action.id]) {
        return mergeToListItem(state, action.id, action.subscriber)
      } else {
        return state
      }
    }

    case atypes.AUDIENCE_SHOW_IMPORT_CONTACTS_MODAL:
      return { ...state, import: { ...state.import, isShowImportContactsModal: true } }

    case atypes.AUDIENCE_CLOSE_IMPORT_CONTACTS_MODAL: {
      return { ...state, import: defaultImportState }
    }
    case atypes.AUDIENCE_IMPORT_SUBSCRIBERS_ERROR: {
      return { ...state, import: { ...state.import, hasImportError: true, isImporting: false } }
    }
    case atypes.AUDIENCE_IMPORT_SUBSCRIBERS_REQUEST: {
      return { ...state, import: { ...state.import, isImporting: true } }
    }

    case atypes.AUDIENCE_SHOW_MIGRATE_CUF_TO_SF_MODAL:
      return { ...state, isShowMigrateCUFToSFModal: true }

    case atypes.AUDIENCE_CLOSE_MIGRATE_CUF_TO_SF_MODAL:
      return { ...state, isShowMigrateCUFToSFModal: false }

    case atypes.AUDIENCE_UNSUBSCRIBEFROMSMS_RESPONSE: {
      if (state.byId[action.id]) {
        return mergeToListItem(state, action.id, { optin_phone: false })
      } else {
        return state
      }
    }
    case atypes.AUDIENCE_UNSUBSCRIBEFROMEMAIL_RESPONSE: {
      if (state.byId[action.id]) {
        return mergeToListItem(state, action.id, { optin_email: false })
      } else {
        return state
      }
    }

    case atypes.AUDIENCE_UNSUBSCRIBEFROMWHATSAPP_RESPONSE: {
      if (state.byId[action.id]) {
        return mergeToListItem(state, action.id, { optin_wa: false })
      } else {
        return state
      }
    }

    case atypes.AUDIENCE_UNSUBSCRIBEFROMINSTAGRAM_RESPONSE: {
      if (state.byId[action.id]) {
        return mergeToListItem(state, action.id, { optin_ig: false })
      } else {
        return state
      }
    }
    case atypes.AUDIENCE_SET_IMPORT_FILE_DATA: {
      return {
        ...state,
        import: {
          ...state.import,
          ...action.payload,
        },
      }
    }
    case atypes.AUDIENCE_SET_IMPORT_HEADERS_MAP: {
      return {
        ...state,
        import: {
          ...state.import,
          headersMap: {
            ...state.import.headersMap,
            ...action.payload,
          },
        },
      }
    }
    case atypes.AUDIENCE_IMPORT_GO_NEXT: {
      const isChannelsStepEnabled = action.payload
      const { step, headersMap, defaultChannel, selectedExtraChannels } = state.import
      let nextStep = step
      let newDefaultChannel = defaultChannel
      let newSelectedExtraChannels = selectedExtraChannels

      switch (step) {
        case 'upload': {
          nextStep = 'mapping'
          break
        }
        case 'mapping': {
          const isPhoneOrWaIdMapped = Object.values(headersMap).some(
            ({ value }) => value === FilterField.PHONE || value === FilterField.WA_ID,
          )

          if (!isChannelsStepEnabled || !isPhoneOrWaIdMapped) {
            nextStep = 'review'
            break
          }

          newSelectedExtraChannels = []
          Object.values(headersMap).forEach(({ value }) => {
            if (value === FilterField.PHONE) {
              newDefaultChannel = FilterField.PHONE
              newSelectedExtraChannels.push(FilterField.PHONE)
              return
            }
            if (value === FilterField.WA_ID) {
              newDefaultChannel = FilterField.WA_ID
              newSelectedExtraChannels.push(FilterField.WA_ID)
            }
          })
          nextStep = 'channels'
          break
        }
        case 'channels': {
          nextStep = 'review'
          break
        }
        case 'review': {
          nextStep = 'processing'
          break
        }
        case 'processing': {
          nextStep = 'confirmation'
          break
        }
      }

      return {
        ...state,
        import: {
          ...state.import,
          step: nextStep,
          defaultChannel: newDefaultChannel,
          selectedExtraChannels: newSelectedExtraChannels,
        },
      }
    }
    case atypes.AUDIENCE_IMPORT_GO_PREV: {
      const isChannelsStepEnabled = action.payload

      const { step, headersMap } = state.import
      const isPhoneOrWaIdMapped = Object.values(headersMap).some(
        ({ value }) => value === FilterField.PHONE || value === FilterField.WA_ID,
      )
      let prevStep = step
      switch (step) {
        case 'mapping': {
          prevStep = 'upload'
          break
        }
        case 'channels': {
          prevStep = 'mapping'
          break
        }
        case 'review': {
          prevStep = isChannelsStepEnabled && isPhoneOrWaIdMapped ? 'channels' : 'mapping'
          break
        }
      }

      return {
        ...state,
        import: {
          ...state.import,
          step: prevStep,
        },
      }
    }
    case atypes.AUDIENCE_TOGGLE_EXTRA_CHANNEL: {
      const { payload } = action
      const { selectedExtraChannels } = state.import

      return {
        ...state,
        import: {
          ...state.import,
          selectedExtraChannels: selectedExtraChannels.includes(payload)
            ? selectedExtraChannels.filter((channelName) => channelName !== payload)
            : [...selectedExtraChannels, payload],
        },
      }
    }
    case atypes.AUDIENCE_SET_IMPORT_TAG: {
      return {
        ...state,
        import: {
          ...state.import,
          tags: [...state.import.tags, action.payload],
        },
      }
    }
    case atypes.AUDIENCE_DELETE_IMPORT_TAG: {
      const { payload: tagId } = action

      return {
        ...state,
        import: {
          ...state.import,
          tags: state.import.tags.filter((tag) => tag.value !== tagId),
        },
      }
    }
    case atypes.AUDIENCE_TOGGLE_IMPORT_AGREEMENT: {
      return {
        ...state,
        import: {
          ...state.import,
          isCheckedAgreement: !state.import.isCheckedAgreement,
        },
      }
    }
    case atypes.AUDIENCE_IMPORT_UPDATE_STATUS: {
      const { data } = action
      const { progress, done, totalCountFailed, totalCountImported, totalCountMerged } = data
      let { step } = state.import
      const importProgress = done ? 0 : progress / 100
      const hasCurrentImport = importProgress > 0 && importProgress < 1

      if (step !== 'processing' && hasCurrentImport) {
        step = 'processing'
      }
      if (step === 'processing' && done) {
        step = 'confirmation'
      }

      return {
        ...state,
        import: {
          ...state.import,
          step,
          importProgress,
          isImporting: !done,
          totalCountFailed,
          totalCountImported,
          totalCountMerged,
        },
      }
    }
    case atypes.AUDIENCE_SHOW_CREATE_SUBSCRIBER_MODAL: {
      return { ...state, isShowCreateSubscriberModal: true, isSubscriberCreated: false }
    }
    case atypes.AUDIENCE_CLOSE_CREATE_SUBSCRIBER_MODAL: {
      return { ...state, isShowCreateSubscriberModal: false, isSubscriberCreated: false }
    }
    case atypes.AUDIENCE_CREATE_SUBSCRIBER_REQUEST: {
      return { ...state, isCreatingSubscriber: true, isSubscriberCreated: false }
    }
    case atypes.AUDIENCE_CREATE_SUBSCRIBER_RESPONSE: {
      return { ...state, isCreatingSubscriber: false, isSubscriberCreated: true }
    }
    case atypes.AUDIENCE_CREATE_SUBSCRIBER_ERROR: {
      return { ...state, isCreatingSubscriber: false, isSubscriberCreated: false }
    }
    case atypes.AUDIENCE_CREATE_SUBSCRIBER_SET_VALIDATION_ERRORS: {
      const {
        errors: { email, phoneNumber, waId },
      } = action

      return {
        ...state,
        createSubscriberValidationErrors: {
          email,
          phoneNumber,
          waId,
        },
      }
    }
  }

  return state
}

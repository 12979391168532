export default {
  onSelect: () => {},
  onShow: () => {},
  onHide: () => {},
  onCreate: () => {},
  onSelectEmpty: () => {},
  options: [],

  labelKey: 'label',
  valueKey: 'value',
  enableClear: false,
  labelEmpty: 'Nothing found',
  labelNew: '+ New',

  forceNew: false,
  allowNew: true,

  closeOnCreate: true,
  closeOnSelect: true,

  filter: null,

  Item: null,
  renderItem: null,

  theme: {},
  cleanUpEmpty: false,

  value: '',

  customButton: null,
}

export const propTypes = {}

import { AiStepNodeStats } from 'shared/api/requests/aiStepStats/schemas'

interface AiStepStatsData {
  data: AiStepNodeStats
  loaded: boolean
}

export enum NodeStatsState {
  EMPTY = 'EMPTY',
  LOADED = 'LOADED',
  LOADING = 'LOADING',
}

export interface AiStepStatsState {
  nodes: Record<string, Record<string, AiStepStatsData>>
  state: Record<string, Record<string, NodeStatsState>>
}

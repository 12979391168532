import React, { useMemo, useCallback, useEffect } from 'react'
import { bindActionCreators } from 'redux'
import { useAppSelector, useAppDispatch } from 'reduxTyped'

import { useModalData } from 'apps/cms/components/Flows/components/AutomationModals/hooks/useModalData'
import { contentManagementActions } from 'apps/cms/store'
import { AutomationModalType } from 'apps/cms/store/lib/types'
import * as profileActions from 'apps/profile/actions/profileActions'
import OriginalExportFlowModal from 'common/builder/components/manager/flow/ExportFlowModal'
import { getAccountsWithoutCurrent } from 'common/core/selectors/appSelectors'
import { exportFlow, exportFlowComplete } from 'common/flow/actions/flowActions'
import * as targetPageMappingActions from 'common/mapping/actions/targetPageMappingActions'

export const ExportFlowModal = () => {
  const dispatch = useAppDispatch()

  const { flowId, flowName } = useModalData({ type: AutomationModalType.EXPORT_FLOW_MODAL })

  const accounts = useAppSelector(getAccountsWithoutCurrent)
  // @ts-expect-error todo, for now flow doesn't have correct typings and it's hard to solve without refactoring
  const step = useAppSelector((state) => state.flow.exportFlowStatus[flowId])
  const otnsMap = useAppSelector((state) => state.targetPage.otnsMap)
  const tagsMap = useAppSelector((state) => state.targetPage.tagsMap)
  const cufsMap = useAppSelector((state) => state.targetPage.cufsMap)
  const gafsMap = useAppSelector((state) => state.targetPage.gafsMap)
  const customEventsMap = useAppSelector((state) => state.targetPage.customEventsMap)

  const actions = useMemo(
    () => ({
      ...bindActionCreators(
        {
          onExport: exportFlow,
          onComplete: exportFlowComplete,
          fetchAccounts: profileActions.fetchAllAccounts,
          ...targetPageMappingActions,
        },
        dispatch,
      ),
    }),
    [dispatch],
  )

  useEffect(() => {
    dispatch(profileActions.fetchAllAccounts())
  }, [dispatch])

  const flowForModal = useMemo(
    () => ({
      id: flowId,
      name: flowName,
    }),
    [flowName, flowId],
  )

  const handleClose = useCallback(() => {
    dispatch(contentManagementActions.closeModal(AutomationModalType.EXPORT_FLOW_MODAL))
  }, [dispatch])

  return (
    <OriginalExportFlowModal
      isOpen
      onClose={handleClose}
      flow={flowForModal}
      accounts={accounts}
      step={step}
      otnsMap={otnsMap}
      tagsMap={tagsMap}
      cufsMap={cufsMap}
      gafsMap={gafsMap}
      customEventsMap={customEventsMap}
      flowId={flowId}
      {...actions}
    />
  )
}

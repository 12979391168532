import dayjs from 'dayjs'
import endsWith from 'lodash/endsWith'
import findKey from 'lodash/findKey'
import get from 'lodash/get'
import includes from 'lodash/includes'

import { MessagingTypeLabel } from 'apps/posting/constants/MessagingType'
import { NotificationTypeLabel } from 'common/core/constants/NotificationType'
import { TimezoneType, TimezoneTypeLabel } from 'common/core/constants/TimezoneType'
import { calculateCTR } from 'utils/stats'

import { ListName, ListNameStatusMap, Status } from './constants'

export const isHistory = (post) => {
  return includes(ListNameStatusMap[ListName.HISTORY], post.status)
}

export const isScheduled = (post) => {
  return includes(ListNameStatusMap[ListName.SCHEDULED], post.status)
}

export const isDraft = (post) => {
  return includes(ListNameStatusMap[ListName.DRAFTS], post.status)
}

export const isDeleted = (post) => {
  return post.status === Status.DELETED
}

export const getBroadcastLabel = (post) => {
  if (!post.scheduled && !post.updated) {
    return 'Broadcast'
  }
  const scheduled = dayjs((post.scheduled || post.updated) * 1000)
  if (scheduled > dayjs()) {
    return 'Scheduled Broadcast'
  }
  return 'Broadcast'
}

export const getScheduledLabel = (post) => {
  if (post.status === Status.CANCELLED) {
    return 'Cancelled on'
  }
  if (!post.scheduled && !post.updated) {
    return 'Sent on'
  }
  const scheduled = dayjs((post.scheduled || post.updated) * 1000)
  if (scheduled > dayjs()) {
    return 'Scheduled for'
  }
  return 'Sent on'
}

export const getScheduledFormatted = (post) => {
  if (!post.scheduled && !post.updated) {
    return ''
  }
  return dayjs((post.scheduled || post.updated) * 1000).format('D MMM YYYY HH:mm')
}

export const getTimezoneFormatted = (post) => {
  if (!post.timezone && !post.timezone.type) {
    return ''
  }

  const label = TimezoneTypeLabel[post.timezone.type]
  if (post.timezone.type === TimezoneType.TIME_LIMIT) {
    return `${label}: from ${post.timezone.from} to ${post.timezone.to}`
  }
  return label
}

export const getMessagingTypeFormatted = (post) => {
  return MessagingTypeLabel[post.messagingType]
}

export const getNotificationFormatted = (post) => {
  const label = NotificationTypeLabel[post.notification]
  if (endsWith(label, ' notification') || endsWith(label, ' notifications')) {
    return label
  }
  return `${label} notification`
}

export const getPreview = (post) => {
  const preview = get(post, 'flow.name') || post.preview || 'Draft message'
  return preview.length > 24 ? preview.substr(0, 21) + '...' : preview
}

export const getUpdated = (post) => {
  return dayjs(post.updated * 1000)
}

export const hasFilter = (post) => {
  return post.filter != null && Array.isArray(post.filter.groups) && post.filter.groups.length > 0
}

export function getStats(post) {
  const sent = get(post, 'stats.sent', 0)
  const read = get(post, 'stats.read', 0)
  const clicked = get(post, 'stats.clicked', 0)
  const delivered = get(post, 'stats.delivered', 0)

  let rr = delivered === 0 ? 0 : ((read / delivered) * 100).toFixed(2)
  let deliveredPercent = sent === 0 ? 0 : ((delivered / sent) * 100).toFixed(2)
  let clickedPercent = delivered === 0 ? 0 : ((clicked / delivered) * 100).toFixed(2)
  const ctr = calculateCTR(clicked, delivered)

  return {
    rr,
    deliveredPercent,
    clickedPercent,
    ctr,
    sent,
    read,
    delivered,
    clicked,
  }
}

export function getSendingStats(post) {
  const sendingStats = get(post, 'sendingStats')
  if (!sendingStats) {
    return null
  }
  const { messagesProcessed, messagesTotal, messagesBlocked } = sendingStats
  const sendingPercent = Math.round((messagesProcessed / messagesTotal) * 100)
  const formatted = `${messagesProcessed}/${messagesTotal} (${sendingPercent}%)`

  return { messagesProcessed, messagesTotal, sendingPercent, formatted, messagesBlocked }
}

export function getETAFormatted(post) {
  const sendingStats = get(post, 'sendingStats')
  if (!sendingStats) {
    return null
  }

  const { etaSeconds, etaStartingPoint } = sendingStats
  const deadline = etaStartingPoint + etaSeconds * 1000
  const now = Date.now()

  if (deadline < now) {
    return null
  }

  // eta - the number of milliseconds until the end of sending
  const eta = deadline - now

  if (eta >= 3600 * 24 * 1000) {
    return 'More than a day'
  }

  const m = dayjs.utc(eta)
  if (eta >= 3600 * 1000) {
    return m.format('HH:mm:ss')
  } else if (eta >= 60 * 1000) {
    return m.format('mm [min], ss [sec]')
  }
  return null
}

export const getListNameByStatus = (status) => {
  return findKey(ListNameStatusMap, (statuses) => statuses.includes(status))
}

export const isBroadcast = (flowId) => flowId.indexOf('post') > -1

import React from 'react'
import cx from 'classnames'

import { FormFieldTextBelowProps } from './interfaces'

import cm from './FormField.module.css'

type Props = FormFieldTextBelowProps

export const FormFieldTextBelow = ({
  helpText,
  errorText,
  disabled,
  showError,
  fieldClasses,
}: Props) => {
  const errorTextClass = showError ? cm.helpText__invalid : ''

  return (
    <>
      {(helpText || errorText) && (
        <small
          className={cx(
            cm.helpText,
            errorTextClass,
            fieldClasses?.helpText,
            disabled && cm.disabled,
          )}
        >
          {showError ? errorText : helpText}
        </small>
      )}
    </>
  )
}

import { createSlice, isAnyOf, AsyncThunk } from '@reduxjs/toolkit'
import { AppThunkApiConfig } from 'reduxTyped'

import { backToPro, cancelSubscription, fetchPro, switchProduct } from '../actions/billingActions'
import { getStripeCheckoutURL } from '../actions/stripeCheckoutActions'
import { upgrade } from '../actions/upgradeActions'
import { BillingState } from '../billingInterfaces'

const initialState: Readonly<BillingState> = {
  fetchingUpgrade: false,
  fetchingUpdateCardDetails: false,
  fetchingCancelSubscription: false,
  fetchingBackToPro: false,
  fetchingPro: false,
  isStripeCheckoutReady: true,
  fetchingSwitchProduct: false,
}

export const billingStateSlice = createSlice({
  name: 'state',
  initialState: initialState as BillingState,
  reducers: {},
  extraReducers: (builder) => {
    const bindLoading = <Result, Options>(
      thunk: AsyncThunk<Result, Options, AppThunkApiConfig>,
      key: keyof BillingState,
    ) => {
      builder
        .addMatcher(thunk.pending.match, (state) => {
          state[key] = true
        })
        .addMatcher(isAnyOf(thunk.fulfilled.match, thunk.rejected.match), (state) => {
          state[key] = false
        })
    }

    bindLoading(getStripeCheckoutURL, 'isStripeCheckoutReady')
    bindLoading(cancelSubscription, 'fetchingCancelSubscription')
    bindLoading(backToPro, 'fetchingBackToPro')
    bindLoading(fetchPro, 'fetchingPro')
    bindLoading(switchProduct, 'fetchingSwitchProduct')
    bindLoading(upgrade, 'fetchingUpgrade')
  },
})

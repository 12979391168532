import { api } from 'shared/api'

import { InstagramSchemas } from './schemas'

export const InstagramApi = {
  getPosts: api.account.createGet({
    url: '/growth-tools/getInstagramPosts',
    schemas: InstagramSchemas.getPosts,
  }),
  getPostById: api.account.createGet({
    url: '/growth-tools/getInstagramPostById',
    schemas: InstagramSchemas.getPostById,
  }),
  getStory: api.account.createGet({
    url: '/instagram/stories/{storyId}',
    schemas: InstagramSchemas.getStory,
  }),
}

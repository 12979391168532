import { AnyAction } from 'redux'

import * as atypes from 'common/actions/integrations/common/constants/IntegrationsReduxActionTypes'
import { IntegrationType } from 'common/actions/integrations/common/constants/IntegrationType'
import { SalesforceState } from 'common/actions/integrations/Salesforce/model/constants'

const initialState: SalesforceState = {
  contactProperties: null,
  identityField: {
    value: null,
    option: null,
  },
  isDisconnecting: false,
  isFetchingContactFields: false,
  mappings: null,
  userFieldPropertiesMappings: [{ contactProperty: '', userField: '' }],
  salesforceSearchField: '',
  manychatSearchValue: '',
  account_id: '',
  account_name: '',
  type: IntegrationType.SALESFORCE,
  status: '',
  linking_settings: null,
}

export default function integrationsReducer(
  state = initialState,
  action: AnyAction,
): SalesforceState {
  switch (action.type) {
    case atypes.SALESFORCE_CLEAR_ACCOUNT_LINK_FIELD:
    case atypes.SALESFORCE_SET_ACCOUNT_LINK_FIELD: {
      return { ...state, ...action.data.salesforce }
    }

    case atypes.INTEGRATION_DISCONNECT_REQUEST: {
      return { ...state, isDisconnecting: true }
    }
    case atypes.INTEGRATION_DISCONNECT_RESPONSE: {
      return { ...state, isDisconnecting: false }
    }

    case atypes.INTEGRATION_RESET: {
      return { ...state, ...initialState }
    }

    case atypes.SALESFORCE_CONTACT_FIELDS_FETCH: {
      return { ...state, isFetchingContactFields: true }
    }

    case atypes.SALESFORCE_CONTACT_FIELDS_FETCH_RESPONSE: {
      const { data } = action
      return { ...state, contactProperties: data.fields, isFetchingContactFields: false }
    }

    case atypes.SALESFORCE_SET_CONTACT_MAPPINGS: {
      const { data } = action
      return { ...state, mappings: { ...state.mappings, ...data } }
    }

    case atypes.SALESFORCE_SET_CUF_IN_MAPPINGS: {
      const { data } = action

      const updatedMappings = [...state.userFieldPropertiesMappings]
      updatedMappings[data.index] = {
        ...state.userFieldPropertiesMappings[data.index],
        userField: data.value || '',
      }

      return {
        ...state,
        userFieldPropertiesMappings: updatedMappings,
      }
    }

    case atypes.SALESFORCE_SET_PROPERTY_IN_BINDINGS: {
      const { data } = action

      const updatedMappings = [...state.userFieldPropertiesMappings]
      updatedMappings[data.index] = {
        ...state.userFieldPropertiesMappings[data.index],
        contactProperty: data.value || '',
      }
      return {
        ...state,
        userFieldPropertiesMappings: updatedMappings,
      }
    }

    case atypes.SALESFORCE_ADD_CUF_PROPERTY_PAIR: {
      return {
        ...state,
        userFieldPropertiesMappings: [
          ...state.userFieldPropertiesMappings,
          { contactProperty: '', userField: '' },
        ],
      }
    }

    case atypes.SALESFORCE_DELETE_CUF_PROPERTY_PAIR: {
      const { data } = action
      const newMappings = [...state.userFieldPropertiesMappings]
      newMappings.splice(data.index, 1)

      return {
        ...state,
        userFieldPropertiesMappings: newMappings,
      }
    }

    case atypes.SALESFORCE_SET_CUF_PROPERTY_BINDINGS: {
      const { data } = action

      return {
        ...state,
        userFieldPropertiesMappings: data.length ? data : initialState.userFieldPropertiesMappings,
      }
    }

    case atypes.SALESFORCE_SET_IDENTITY_FIELD: {
      const { data } = action

      return { ...state, identityField: data }
    }

    case atypes.SALESFORCE_SET_SEARCH_FIELD: {
      const { data } = action

      return { ...state, salesforceSearchField: data }
    }

    case atypes.SALESFORCE_SET_SEARCH_VALUE: {
      const { data } = action

      return { ...state, manychatSearchValue: data }
    }
  }

  return state
}

import { getIsAttachmentMessageTemplateHeader } from 'apps/whatsApp/helpers/getIsAttachmentMessageTemplateHeader'
import {
  WaMessageTemplateDraft,
  WaMessageTemplateHeaderType,
} from 'shared/api/requests/whatsApp/schemas'

export function isMessageTemplateHeaderValid(header: WaMessageTemplateDraft['header']): boolean {
  if (!Array.isArray(header) && header?.type === WaMessageTemplateHeaderType.TEXT) {
    return !isEmptyOrUndefined(header.text)
  } else if (getIsAttachmentMessageTemplateHeader(header)) {
    return !isEmptyOrUndefined(header.url)
  } else {
    return false
  }
}

const isEmptyOrUndefined = (text: string | undefined) => text === undefined || text.trim() === ''

import dot from 'dot-prop-immutable'

import type { ILimitedList } from 'utils/factory/limitedList/interfaces'
import type { ILimitedListAction } from 'utils/factory/limitedList/interfaces/limitedListAction'
import { mergeData } from 'utils/factory/mergeData'

import { Initial } from './constants'

export const makeLimitedListInitialState = (initial = {}): ILimitedList => ({
  ...Initial,
  ...initial,
})

export const addToList = (
  state: ILimitedList,
  id: number,
  data: unknown,
  unshift?: boolean,
): ILimitedList => {
  if (state.items == null) {
    return state
  }

  const res = { ...state, items: [...state.items] }
  unshift ? res.items.unshift(data) : res.items.push(data)
  res.byId = { ...state.byId, [id]: data }
  return res
}

export const deleteFromList = (state: ILimitedList, id: number): ILimitedList => {
  if (state.items == null) {
    return state
  }
  const item = state.byId[id]
  const index = state.items.findIndex((i) => i === item)
  if (index < 0) {
    return state
  }

  const res = dot.delete(state, `items.${index}`)
  res.byId = {
    ...state.byId,
  }
  // TODO: investigate while refactoring fabrics
  // eslint-disable-next-line
  // @ts-expect-error
  delete res[id]
  return res
}

export const mergeToListItem = (
  state: ILimitedList,
  id: number,
  data: unknown,
  path?: string[],
  replace?: string,
): ILimitedList => {
  if (state.items == null) {
    return state
  }
  let item = state.byId[id]
  const index = state.items.findIndex((i) => i === item)
  if (index < 0) {
    return state
  }

  item = mergeData(item, data, path, replace)
  const res = dot.set(state, `items.${index}`, item)
  res.byId = { ...state.byId, [id]: res.items?.[index] }
  return res
}

export const deleteFromListItem = (state: ILimitedList, id: number, prop: string): ILimitedList => {
  if (state.items == null) {
    return state
  }
  const item = state.byId[id]
  const index = state.items.findIndex((i) => i === item)
  if (index < 0) {
    return state
  }

  const res = dot.delete(state, `items.${index}.${prop}`)
  res.byId = { ...state.byId, [id]: res.items?.[index] }
  return res
}

export const makeLimitedListReducer = <ItemInterface>(
  entityName: string,
  options: {
    initialState?: ILimitedList<ItemInterface>
    isDeleteOnArchive?: boolean
    unshift?: boolean
    insertOnUpdate?: boolean
  } = {},
): ((state: ILimitedList, action: ILimitedListAction) => ILimitedList<ItemInterface>) => {
  const initialState = makeLimitedListInitialState()
  if (options.initialState) {
    Object.assign(initialState, options.initialState)
  }
  const prefix = entityName.toUpperCase()
  const requestRegExp = new RegExp(`${prefix}_(\\S+)_REQUEST`)
  const successRegExp = new RegExp(`${prefix}_(\\S+)_RESPONSE`)
  const errorRegExp = new RegExp(`${prefix}_(\\S+)_ERROR`)

  return function reducer(
    state = initialState,
    action: ILimitedListAction,
  ): ILimitedList<ItemInterface> {
    switch (action.type) {
      case 'APP_UPDATE_CURRENT_ACCOUNT_ID':
      case `${prefix}_DROP_LIST`: {
        return { ...state, ...makeLimitedListInitialState() }
      }
      case `${prefix}_FETCH_REQUEST`: {
        const { fetchArgs, fetchArgsHash, $requestId, dropItems } = action
        const limiter = dropItems ? null : state.limiter
        return {
          ...state,
          fetching: true,
          limiter,
          error: null,
          fetchRequestId: $requestId,
          fetchArgs: fetchArgs || state.fetchArgs,
          fetchArgsHash: fetchArgsHash || state.fetchArgsHash,
        }
      }
      case `${prefix}_FETCH_RESPONSE`: {
        const { data, $requestId, dropItems, limiterType } = action

        // We should not process fetch responses received after we started handling other request
        if ($requestId !== state.fetchRequestId) {
          return state
        }

        let { items } = data
        const { limiter, total: responseTotal, later_limiter: laterLimiter } = data

        if (!Array.isArray(items)) {
          throw new Error(
            `items not an array, maybe you forgot to define 'listKey' property in entity config?`,
          )
        }

        const byId = dropItems ? {} : { ...state.byId }
        for (const item of items) {
          byId[item.id] = item
        }

        const isFetchLaterMessages = limiterType === 'later'
        const isFetchEarlyMessages = limiterType === 'early'

        if (Array.isArray(state.items)) {
          if (dropItems) {
            items = [...items]
          } else if (isFetchLaterMessages) {
            items = [...[...items].reverse(), ...state.items]
          } else if (isFetchEarlyMessages) {
            items = [...state.items, ...items]
          } else {
            items = [...state.items, ...items]
          }
        }

        // Backend sends correct 'total' only for first page, so we need to reuse it for next
        const total = state.limiter ? state.total : responseTotal

        const result = {
          ...state,
          byId,
          items,
          fetching: false,
          error: null,
          total,
          limiter,
          laterLimiter,
        }

        if (limiterType === undefined) {
          return { ...result, listEarlyLimiter: limiter, listLaterLimiter: laterLimiter }
        }

        if (isFetchLaterMessages) {
          if (laterLimiter === null && limiter === null) {
            //All Messages loaded
            return { ...result, listLaterLimiter: null }
          }

          return { ...result, listLaterLimiter: limiter }
        }

        if (isFetchEarlyMessages) {
          if (laterLimiter === null && limiter === null) {
            //All Messages loaded
            return { ...result, listEarlyLimiter: null }
          }

          return { ...result, listEarlyLimiter: limiter }
        }

        return { ...result }
      }
      case `${prefix}_FETCH_ERROR`: {
        const { error } = action
        return { ...state, fetching: false, error }
      }

      case `${prefix}_DELETE_REQUEST`: {
        return mergeToListItem(state, action.id, { $deleting: true })
      }
      case `${prefix}_DELETE_RESPONSE`: {
        return deleteFromList(state, action.id)
      }
      case `${prefix}_DELETE_ERROR`: {
        return deleteFromListItem(state, action.id, '$deleting')
      }

      case `${prefix}_ARCHIVE_REQUEST`: {
        return options.isDeleteOnArchive
          ? mergeToListItem(state, action.id, { $deleting: true })
          : state
      }
      case `${prefix}_ARCHIVE_RESPONSE`: {
        return options.isDeleteOnArchive ? deleteFromList(state, action.id) : state
      }
      case `${prefix}_ARCHIVE_ERROR`: {
        return options.isDeleteOnArchive ? deleteFromListItem(state, action.id, '$deleting') : state
      }

      case `${prefix}_CREATE_REQUEST`: {
        // const { initial, $requestId } = action
        // initial.$requestId = initial.$requestId || $requestId
        // initial.id = initial.id || Date.now()
        // return addToList(state, initial.id, initial)
        return state
      }
      case `${prefix}_CREATE_RESPONSE`: {
        const { data, $requestId } = action
        const { item } = data
        const unshift = options.unshift ?? false
        item.$requestId = $requestId

        const exists = Boolean(state.byId[item.id])
        if (exists) {
          return mergeToListItem(state, item.id, item)
        }

        return addToList(state, item.id, item, unshift)
      }
      case `${prefix}_CREATE_ERROR`: {
        // const { $requestId } = action
        // const item = state.items.find(t => t.$requestId === $requestId)
        // return deleteFromList(state, item.id)
        return state
      }

      case `${prefix}_SAVE_REQUEST`: {
        return mergeToListItem(state, action.id, { [`$saveFetching`]: true })
      }
      case `${prefix}_SAVE_RESPONSE`: {
        const { data, id } = action
        const { item } = data
        state = deleteFromListItem(state, id, `$saveFetching`)
        return mergeToListItem(state, id, item)
      }
      case `${prefix}_SAVE_ERROR`: {
        return deleteFromListItem(state, action.id, `$saveFetching`)
      }

      case `${prefix}_UPDATE`: {
        const { id, data, path, replace } = action
        return mergeToListItem(state, id, data, path, replace)
      }

      case `${prefix}_CREATED_NOTIFICATION`: {
        if (state.items == null) {
          break
        }
        const { item } = action

        const index = state.items.findIndex((i) => i.id === item.id)
        if (index < 0) {
          const byId = { ...state.byId }
          byId[item.id] = item
          return { ...state, byId, items: [item, ...state.items] }
        }
        break
      }
      case `${prefix}_UPDATED_NOTIFICATION`: {
        if (state.items == null) {
          break
        }
        const { item } = action

        const exists = Boolean(state.byId[item.id])
        if (exists) {
          return mergeToListItem(state, item.id, item)
        } else if (options.insertOnUpdate) {
          return addToList(state, item.id, item)
        }

        return state
      }
      case `${prefix}_DELETED_NOTIFICATION`: {
        if (state.items == null) {
          break
        }
        const byId = { ...state.byId }
        delete byId[action.id]
        return {
          ...state,
          byId,
          items: state.items.filter((i) => String(i.id) !== String(action.id)),
        }
      }
    }

    if (action.id && !action.type.startsWith(`${prefix}_CURRENT`)) {
      const requestMatch = action.type.match(requestRegExp)
      if (Array.isArray(requestMatch) && requestMatch[1]) {
        const requestAction = requestMatch[1].toLowerCase()
        return mergeToListItem(state, action.id, { [`$${requestAction}Fetching`]: true })
      }
      const responseMatch = action.type.match(successRegExp)
      if (Array.isArray(responseMatch) && responseMatch[1]) {
        const responseAction = responseMatch[1].toLowerCase()
        state = deleteFromListItem(state, action.id, `$${responseAction}Fetching`)

        const { data } = action
        if (data && data.item) {
          return mergeToListItem(state, data.item.id, data.item)
        }
        return state
      }
      const errorMatch = action.type.match(errorRegExp)
      if (Array.isArray(errorMatch) && errorMatch[1]) {
        const errorAction = errorMatch[1].toLowerCase()
        return deleteFromListItem(state, action.id, `$${errorAction}Fetching`)
      }
    }
    return state
  }
}

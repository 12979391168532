import { createSlice } from '@reduxjs/toolkit'
import { createAppAsyncThunk } from 'reduxTyped'
import { l } from '@manychat/manyui'

import { WhatsAppCatalogState } from 'apps/whatsApp/interfaces/whatsAppCatalog'
import { alert } from 'common/core'
import { handleCatch } from 'shared/api/lib/errors/handlers'
import { WhatsAppApi } from 'shared/api/requests/whatsApp'
import {
  WhatsAppCatalogNeedsPermissionRefresh,
  WhatsAppCommerceSettings,
  WhatsAppFixBrokenCarts,
} from 'shared/api/requests/whatsApp/schemas'

export const setCatalogVisibility = createAppAsyncThunk<null, { isVisible: boolean }>(
  'whatsapp/setCatalogVisibility',
  async ({ isVisible }) => {
    try {
      await WhatsAppApi.setCatalogVisibility({ body: { value: isVisible } })
      return null
    } catch (error) {
      handleCatch(error)
      return null
    }
  },
)

export const getWhatsAppCommerceSettings = createAppAsyncThunk<WhatsAppCommerceSettings | null>(
  'whatsapp/getWhatsAppCommerceSettings',
  async () => {
    try {
      const result = await WhatsAppApi.getWhatsAppCommerceSettings()
      return result.data
    } catch (error) {
      handleCatch(error)
      return null
    }
  },
)

export const getNeedsPermissionRefresh =
  createAppAsyncThunk<WhatsAppCatalogNeedsPermissionRefresh | null>(
    'whatsapp/needsPermissionRefresh',
    async () => {
      try {
        const result = await WhatsAppApi.needsPermissionRefresh()
        return result.data
      } catch (error) {
        handleCatch(error)
        return null
      }
    },
  )

export const fixBrokenCarts = createAppAsyncThunk<WhatsAppFixBrokenCarts | null>(
  'carts/fixBrokenCarts',
  async () => {
    try {
      const result = await WhatsAppApi.fixBrokenCarts()
      alert(l.translate('Carts are updated'), 'success')
      return result.data
    } catch (error) {
      handleCatch(error)
      return null
    }
  },
)

const initialState: WhatsAppCatalogState = {
  settings: {
    data: null,
    loading: false,
  },
  permissions: {
    isNeedRefresh: false,
  },
  fixBrokenCarts: {
    loading: false,
    result: null,
  },
}

export const catalogSlice = createSlice({
  name: 'catalog',
  initialState: initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getWhatsAppCommerceSettings.pending, (state) => {
      state.settings.loading = true
    })
    builder.addCase(getWhatsAppCommerceSettings.fulfilled, (state, action) => {
      state.settings.loading = false
      if (action.payload !== null) {
        state.settings.data = action.payload
      }
    })
    builder.addCase(getNeedsPermissionRefresh.fulfilled, (state, action) => {
      if (action.payload !== null) {
        state.permissions.isNeedRefresh = action.payload.is_need_refresh
      }
    })
    builder.addCase(fixBrokenCarts.pending, (state) => {
      state.fixBrokenCarts.loading = true
    })
    builder.addCase(fixBrokenCarts.fulfilled, (state, action) => {
      state.fixBrokenCarts.loading = false
      if (action.payload !== null) {
        state.fixBrokenCarts.result = action.payload
      }
    })
  },
})

export const getWaCatalogSettingsDataSelector = (state: RootState) =>
  state.whatsApp.catalog.settings.data
export const getWaCatalogSettingsIsLoadingSelector = (state: RootState) =>
  state.whatsApp.catalog.settings.loading
export const getWaCatalogPermissionsNeedRefresh = (state: RootState) =>
  state.whatsApp.catalog.permissions.isNeedRefresh
export const getWaBrokenCartsIsLoadingSelector = (state: RootState) =>
  state.whatsApp.catalog.fixBrokenCarts.loading

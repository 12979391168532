/* eslint-disable import/order */
import React, { useMemo } from 'react'
// Common extend for dayjs
import { Provider } from 'react-redux'
import dayjs from 'dayjs'
import advancedFormat from 'dayjs/plugin/advancedFormat'
import duration from 'dayjs/plugin/duration'
import localizedFormat from 'dayjs/plugin/localizedFormat'
import objectSupport from 'dayjs/plugin/objectSupport'
import relativeTime from 'dayjs/plugin/relativeTime'
import timezone from 'dayjs/plugin/timezone'
import utc from 'dayjs/plugin/utc'

dayjs.extend(objectSupport)
dayjs.extend(relativeTime)
dayjs.extend(utc)
dayjs.extend(timezone)
dayjs.extend(duration)
dayjs.extend(localizedFormat)
dayjs.extend(advancedFormat)
import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'
import { RouterProvider } from 'react-router-dom'
import { Store } from 'redux'
import { Provider as UIProvider, Loader } from '@manychat/manyui'
import { TunnelProvider } from '@manychat/react-tunnels'

import { ErrorBoundary } from 'common/core/components/ErrorBoundary'
import AppConfirm from 'common/core/controllers/AppConfirm'
import LightboxController from 'common/core/controllers/Lightbox'
import Toasts from 'common/core/controllers/Toasts'
import TooltipManager from 'common/core/controllers/TooltipManager'
import { McClientContextProvider } from 'utils/api/query/McClientContext'
import { router } from 'utils/router/router'
import { ReleaseTogglesContextProvider } from 'utils/services/featureFlagsService/releaseTogglesContext'
import LiveSupportService from 'utils/services/LiveSupportService'

import NewOnboardingServiceRoot from 'utils/services/newOnboardingService/NewOnboardingServiceRoot'
import { VisitorService } from 'utils/services/visitor/VisitorService'
import { WootricService } from 'utils/services/wootric/WootricService'

import CrowdinInContext from '../i18n/components/CrowdinInContext'
import { TranslationService } from '../i18n/components/TranslationService'
import { RootAPI } from 'api'
import { getLocation } from 'utils/router/tools'

const Root = ({ api, store }: { api: RootAPI; store: Store }) => {
  const { pathname } = getLocation()
  const errorBoundaryResetKeys = useMemo(() => [pathname], [pathname])

  return (
    <Provider store={store}>
      <CrowdinInContext />
      <VisitorService />
      <WootricService />
      <TranslationService />
      <LiveSupportService />
      <ErrorBoundary scope="root" section={null} resetKeys={errorBoundaryResetKeys}>
        <McClientContextProvider api={api}>
          <React.Suspense fallback={<Loader center />}>
            <TunnelProvider>
              <DndProvider backend={HTML5Backend}>
                <div className="react-root">
                  <UIProvider withoutContainer>
                    <span
                      id="language"
                      style={{
                        position: 'absolute',
                        zIndex: 9999,
                        opacity: 0,
                        width: 0,
                        height: 0,
                        boxSizing: 'border-box',
                      }}
                    >
                      language
                    </span>
                    <NewOnboardingServiceRoot />
                    <ReleaseTogglesContextProvider>
                      <RouterProvider router={router} />
                      <AppConfirm />
                      <LightboxController />
                      <TooltipManager />
                      <Toasts />
                    </ReleaseTogglesContextProvider>
                  </UIProvider>
                </div>
              </DndProvider>
            </TunnelProvider>
          </React.Suspense>
        </McClientContextProvider>
      </ErrorBoundary>
    </Provider>
  )
}

export default Root

import { DeepPartial } from 'utility-types'
import { v4 as uuid } from 'uuid'

import {
  AssistantMessageAuthor,
  AssistantMessageType,
  AssistantTextMessage,
} from 'apps/aiAssistantNew/store/types'

export const createTextMessage = (
  overrides: DeepPartial<AssistantTextMessage>,
): AssistantTextMessage => ({
  type: AssistantMessageType.TEXT,
  author: AssistantMessageAuthor.USER,
  id: uuid(),
  step_id: '',
  ts: Date.now(),
  ...overrides,
  data: {
    text: overrides.data?.text ?? '',
    buttons: overrides.data?.buttons ? (overrides.data?.buttons as string[]) : [],
  },
})

import get from 'lodash/get'
import { createStructuredSelector, ParametricSelector } from 'reselect'
import { l } from '@manychat/manyui'

import { IButton } from 'common/builder/buttons/buttonInterfaces'
import { BlockType } from 'common/builder/constants/BlockType'
import { NodeType } from 'common/builder/constants/NodeType'
import { getEmailLinkCaption } from 'common/builder/emailBuilder/selectors/emailBuilderSelectors'
import ButtonModel from 'common/builder/models/Button'
import { INode } from 'common/builder/nodes/nodeInterfaces'
import * as entitySelectors from 'common/builder/selectors/builder/entitySelectors'
import { NOTIFY_BUTTON_LABELS } from 'common/oneTimeNotify/constants/notifyReasonConstants'
import { getTypeById } from 'common/oneTimeNotify/selectors/notifyReasonSelectors'
import { formatCTR } from 'utils/stats'

import { getSmsLinkCaption } from './smsSelectors'
import { getWhatsAppLinkCaption } from './whatsAppSelectors'

export const getButtonErrors = (state: RootState, builderId: string, buttonId: string) =>
  get(state, ['builders', 'byId', builderId, 'state', 'errors', buttonId], [])

export const getButtonInfo: ParametricSelector<
  RootState,
  string,
  { button: IButton | undefined; errors: []; parentNode: INode | undefined }
> = createStructuredSelector({
  button: entitySelectors.getButtonById,
  errors: getButtonErrors,
  parentNode: entitySelectors.getParentNode,
})

export const getButtonCaption = (
  state: RootState,
  builderId: string,
  buttonId: string,
  options: Partial<{ smartLink: boolean }> = {},
) => {
  const defaultOptions = { smartLink: false }
  options = { ...defaultOptions, ...options }

  if (options?.smartLink) {
    const parentNode = entitySelectors.getParentNode(state, builderId, buttonId)
    if (parentNode?.nodeType === NodeType.SMS) {
      return getSmsLinkCaption(state, builderId, buttonId)
    }
    if (parentNode?.nodeType === NodeType.EMAIL_NEW) {
      return getEmailLinkCaption(state, builderId, buttonId)
    }
    if (parentNode?.nodeType === NodeType.WHATS_APP) {
      return getWhatsAppLinkCaption(state, builderId, buttonId)
    }
  }

  const button = entitySelectors.getButtonById(state, builderId, buttonId)
  return ButtonModel.getCaption(button, '...')
}

export const getOTNButtonCaption = (state: RootState, builderId: string, buttonId: string) => {
  const parentBlock = entitySelectors.getParentBlock(state, builderId, buttonId)
  if (!(parentBlock?.type === BlockType.ONE_TIME_NOTIFY_REQUEST && parentBlock.reasonId)) {
    return null
  }

  const buttonType = getTypeById(state, parentBlock.reasonId)
  if (!buttonType) {
    return null
  }

  return l.getString(NOTIFY_BUTTON_LABELS[buttonType])
}

export const getButtonCTR = (state: RootState, builderId: string, buttonId: string) => {
  const button = entitySelectors.getButtonById(state, builderId, buttonId)
  if (!button) {
    return {
      value: -1,
      formatted: 'N/A',
    }
  }

  const node = entitySelectors.getParentNode(state, builderId, buttonId)
  if (!node) {
    return {
      value: -1,
      formatted: 'N/A',
    }
  }

  const { ctr } = ButtonModel.getStats(button, node)
  const formatted = formatCTR(ctr)

  return { value: ctr, formatted }
}

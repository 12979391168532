import { isBillingAccessError } from 'shared/api/common/errors/billingAccessError'
import { isCardError } from 'shared/api/common/errors/cardError'
import { BusinessError } from 'shared/api/lib/errors/business'

export type { CardError } from 'shared/api/common/errors/cardError'

export interface WalletError extends BusinessError {
  field: 'wallet_error'
}

const isWalletError = (error: BusinessError): error is WalletError => error.field === 'wallet_error'

export const WalletApiGuards = {
  isCardError,
  isWalletError,
  isBillingAccessError,
}

export enum AddIgStatusType {
  SELECT_PAGE = 'list',
  NO_FB_PERMISSIONS = 'facebook_permissions',
  NO_IG_PERMISSIONS = 'instagram_permissions',
}

export enum IgPageStatus {
  CONNECTED = 'connected',
  NOT_CONNECTED = 'not_connected',
  REQUEST_ACCESS_PENDING = 'request_access_pending',
  CAN_REQUEST_ACCESS = 'can_request_access',
  MISSING_TOKEN = 'missing_token',
  JOIN = 'join',
}

export enum ConnectIgErrorCode {
  NOT_BUSINESS_ACCOUNT = 1,
  DISABLED_MESSAGES = 2,
}
export const ConnectIgErrorCodeAnalyticsMap: Record<ConnectIgErrorCode, string> = {
  [ConnectIgErrorCode.NOT_BUSINESS_ACCOUNT]: 'NOT_BUSINESS_ACCOUNT',
  [ConnectIgErrorCode.DISABLED_MESSAGES]: 'DISABLED_MESSAGES',
}

export interface HandleStartEmbeddedSignUpProps {
  isCoexistenceActive: boolean
}

export type HandleStartEmbeddedSignUp = (props: HandleStartEmbeddedSignUpProps) => void

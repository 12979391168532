import { l } from '@manychat/manyui'

const ErrorMessages = {
  ACTION_SET_CUF_INVALID_EXPRESSION: l.makeTranslate(
    'Please write a valid mathematical expression',
  ),
  ACTION_SET_CUF_INVALID_OPERATOR: l.makeTranslate(
    'Currently we only support "+", "-", "*", "/" operators and round() function',
  ),
  ACTION_SET_CUF_INVALID_VARIABLE: l.makeTranslate(
    'Please use a valid variable in mathematical expression',
  ),
  ACTION_SET_COUNT_INVALID_BRACKETS: l.makeTranslate(
    'Please check the count of parentheses in your expression.',
  ),
  ACTION_SET_EMPTY_CONTENT_OF_BRACKETS_INVALID_VARIABLE: l.makeTranslate(
    'Please write value in the parentheses.',
  ),
  ACTION_SET_MATH_SYMBOLS_INVALID_EXPRESSION: l.makeTranslate(
    'Please check the correctness of the mathematical signs.',
  ),
}

export default ErrorMessages

import filter from 'lodash/filter'
import get from 'lodash/get'
import last from 'lodash/last'
import some from 'lodash/some'
import uniq from 'lodash/uniq'
import values from 'lodash/values'

import { TriggerCategoryType } from 'common/builder/components/builder/TriggerPicker/lib/triggerCategoryType'
import { BlockType } from 'common/builder/constants/BlockType'
import { MessageTagsByProStatus } from 'common/builder/constants/facebookMessaging'
import { NodeType } from 'common/builder/constants/NodeType'
import batchAdapter from 'common/builder/models/Batch/adapter'
import * as entitySelectors from 'common/builder/selectors/builder/entitySelectors'
import { getAllChainingContents } from 'common/builder/selectors/builder/getAllChainingContents'
import { getConnectedNodes } from 'common/builder/selectors/builder/getConnectedNodes'
import { ChannelType } from 'common/core/constants/ChannelType'
import { getUserId } from 'common/core/selectors/appSelectors'

import * as nodeRelationSelectors from './nodeRelationSelectors'

export function getById(state, builderId) {
  return get(state, ['builders', 'byId', builderId, 'state'])
}

export function gethighlightedNodeId(state, builderId) {
  const builderState = getById(state, builderId)
  return builderState.highlightedNodeId
}

export function getSelectionMap(state, builderId) {
  const builderState = getById(state, builderId)
  if (!builderState) {
    return null
  }
  const { selectedNodes } = builderState
  return selectedNodes
}

export function getSelectedBlock(state, builderId) {
  const builderState = getById(state, builderId)
  if (builderState) {
    const { selectedBlockId = null } = builderState
    if (selectedBlockId) {
      return entitySelectors.getBlockById(state, builderId, selectedBlockId)
    }
  }

  return null
}

export function getSelectedButton(state, builderId) {
  const builderState = getById(state, builderId)
  const selectedButtonId = builderState.selectedButtonId
  return entitySelectors.getButtonById(state, builderId, selectedButtonId)
}

export function getHighlightedBlock(state, builderId) {
  const builderState = getById(state, builderId)
  const { highlightBlockId = null } = builderState
  if (highlightBlockId) {
    return entitySelectors.getBlockById(state, builderId, highlightBlockId)
  }

  return null
}

export function getSelectionList(state, builderId) {
  const selection = getSelectionMap(state, builderId)
  return Object.keys(selection || {})
}

export function getSelectedNodes(state, builderId) {
  const selectedIds = getSelectionList(state, builderId)
  return selectedIds.map((nodeId) => entitySelectors.getNodeById(state, builderId, nodeId))
}

export function getCurrentNodeId(state, builderId) {
  const selectedIds = getSelectionList(state, builderId)
  return selectedIds.length === 1 ? selectedIds[0] : null
}

export function getCurrentNode(state, builderId) {
  const currentNodeId = getCurrentNodeId(state, builderId)
  if (currentNodeId == null) {
    return null
  }
  return entitySelectors.getNodeById(state, builderId, currentNodeId)
}

/**
 * DEPRECATED - use 'getCurrentNodeId'
 */
export function getCurrentContentId(state, builderId) {
  const builderState = getById(state, builderId)
  if (!builderState) {
    return null
  }
  const lastHistoryItem = last(builderState.contentHistory)
  return lastHistoryItem ? lastHistoryItem.contentId : builderState.rootId
}

/**
 * DEPRECATED - use 'getCurrentNode'
 */
export function getCurrentContent(state, builderId) {
  const currentContentId = getCurrentContentId(state, builderId)
  return entitySelectors.getNodeById(state, builderId, currentContentId)
}

export function getRootNodeId(state, builderId) {
  const builderState = getById(state, builderId)
  if (!builderState) {
    return null
  }
  return builderState.rootId
}

export function getRootNode(state, builderId) {
  const nodeId = getRootNodeId(state, builderId)
  return entitySelectors.getNodeById(state, builderId, nodeId)
}

export function getValidationErrors(state, builderId, elementId) {
  const builderState = getById(state, builderId)
  let errors = get(builderState, ['errors', elementId], [])
  const serverError = builderState.serverError || {}
  if (
    serverError.blockId === elementId ||
    serverError.buttonId === elementId ||
    serverError.contentId === elementId
  ) {
    errors = [serverError, ...errors]
  }
  return errors
}

/**
 * Returns batch of contents that are not blocked for editing
 * @param state
 * @param builderId
 * @param {Object} options
 * @param {Boolean=} options.useClientRootId - use client Id as rootId
 * @param {Boolean=} options.skipNamespaceCheck - skip filtering content by namespace
 * @param {Object=} options.exportOptions
 * @param {String=} options.emailRootId
 */
export function getBatch(state, builderId, options = {}) {
  const builder = getById(state, builderId)
  const allContents = getExportedContentList(state, builderId, options.exportOptions)

  /**
   * Filter out contents not from builder's namespace
   */
  const contents = allContents.filter((content) => {
    if (options.skipNamespaceCheck) {
      return true
    }
    const foreign = content.namespace !== builder.flow
    if (!foreign) {
      return true
    }
    return false
  })

  let root_content = null
  const rootContent = options.emailRootId
    ? entitySelectors.getNodeById(state, builderId, options.emailRootId)
    : getRootNode(state, builderId)
  if (rootContent) {
    if (options.useClientRootId || !rootContent.contentId) {
      root_content = rootContent.id
    } else {
      root_content = rootContent.contentId
    }
  }

  if (options.emailRootId) {
    const content = contents.find((c) => c.content_id === root_content || c.id === root_content)
    if (content?.target) {
      content.target = null
    }
  }

  return {
    contents,
    root_content,
  }
}

/**
 * Возвращает список всех контентов загруженных в builderState
 * - в этот список могут попасть контенты которые были прилинкованы и потом отлинкованы от кнопки
 */
export function getExportedContentList(state, builderId, exportOptions) {
  const nodes = entitySelectors.getNodesMap(state, builderId)
  const blocksMap = entitySelectors.getBlocksMap(state, builderId)
  const buttonsMap = entitySelectors.getButtonsMap(state, builderId)
  const userId = getUserId(state)

  const builderData = {
    nodes: values(nodes),
    blocks: values(blocksMap),
    buttons: values(buttonsMap),
  }
  const batch = batchAdapter.exportBatch(builderData, userId, exportOptions)
  return batch.contents
}

/**
 * Возвращает список всех контентов связанных с рутовым контентом
 */
export function getRootConnectedNodes(state, builderId) {
  const nodeId = getRootNodeId(state, builderId)
  if (!nodeId) {
    return []
  }
  return getConnectedNodes(state, builderId, nodeId)
}

export const getChannelsList = (state, builderId) => {
  const nodeTypesChannels = {
    [NodeType.CONTENT]: ChannelType.FB,
    [NodeType.SMS]: ChannelType.SMS,
    [NodeType.EMAIL_NEW]: ChannelType.EMAIL,
    [NodeType.WHATS_APP]: ChannelType.WHATSAPP,
    [NodeType.INSTAGRAM]: ChannelType.INSTAGRAM,
    [NodeType.TELEGRAM]: ChannelType.TELEGRAM,
  }
  const nodeTypes = Object.keys(nodeTypesChannels)

  return uniq(getRootConnectedNodes(state, builderId).map((node) => node.nodeType))
    .filter((nodeType) => nodeTypes.includes(nodeType))
    .map((nodeType) => nodeTypesChannels[nodeType])
}

export function hasOneTimeNotifyBlock(state, builderId) {
  const nodes = getRootConnectedNodes(state, builderId)
  const contentNodes = filter(nodes, { nodeType: NodeType.CONTENT })
  const nodeWithOTN = contentNodes.find((node) => {
    const blocks = entitySelectors.getBlocks(state, builderId, node.id)
    return some(blocks, { type: BlockType.ONE_TIME_NOTIFY_REQUEST })
  })
  return Boolean(nodeWithOTN)
}

export function getFirstNode(state, builderId) {
  const nodes = getRootConnectedNodes(state, builderId)

  return nodes[0]
}

export function getFirstNodeReasonId(state, builderId) {
  return getFirstNode(state, builderId)?.reasonId
}

export function getFirstNodeMessagingWindow(state, builderId) {
  return getFirstNode(state, builderId)?.$fbMessagingType
}

export function hasContentWithReason(state, builderId) {
  const nodes = getRootConnectedNodes(state, builderId)
  const contentNodesWithReason = filter(
    nodes,
    (node) => node.nodeType === NodeType.CONTENT && Boolean(node.reasonId),
  )
  return Boolean(contentNodesWithReason.length)
}

export function hasPROMessageTag(state, builderId) {
  const nodesList = entitySelectors.getNodesList(state, builderId)

  return nodesList.some((node) => MessageTagsByProStatus[node.messageTag])
}

export function getRootContentsWithChaining(state, builderId) {
  const rootNodeId = getRootNodeId(state, builderId)
  return getAllChainingContents(state, builderId, rootNodeId)
}

export function hasFlowButtonsInRootNode(state, builderId) {
  const nodeId = getRootNodeId(state, builderId)
  const buttons = entitySelectors.getButtons(state, builderId, nodeId, { includeNested: true })
  const flowButtons = filter(buttons, { type: 'flow' })
  return Boolean(flowButtons.length)
}

export function getRootPreInteractionZoneTargets(state, builderId) {
  const rootNode = getRootNode(state, builderId)
  if (!rootNode) {
    return []
  }
  const targets = nodeRelationSelectors.getAllPreInteractionZoneTargets(
    state,
    builderId,
    rootNode.id,
    { ignoreTargetsAfterGotoNode: true },
  )
  return [rootNode, ...targets]
}

export function getRootPreInteractionZoneIgnoreConditionTargets(state, builderId) {
  const rootNode = getRootNode(state, builderId)
  if (!rootNode) {
    return []
  }
  const targets = nodeRelationSelectors.getAllPreInteractionZoneTargets(
    state,
    builderId,
    rootNode.id,
    { ignoreTargetsAfterGotoNode: true, ignoreTargetsAfterConditionNode: true },
  )
  return [rootNode, ...targets]
}

export const makeIsPersonalizationDisabled = (builderId, nodeId) => (state) => {
  const builderState = getById(state, builderId)
  if (!builderState) {
    return false
  }

  const rootId = getRootNodeId(state, builderId)
  const disableContentPersonalization = Boolean(builderState.disableContentPersonalization)
  const disableRootContentPersonalization =
    Boolean(builderState.disableRootContentPersonalization) && rootId === nodeId

  return disableContentPersonalization || disableRootContentPersonalization
}

export const getTriggerCategoryByRootNode = (state, builderId) => {
  const rootNode = getRootNode(state, builderId)

  const categoryMap = {
    [NodeType.CONTENT]: TriggerCategoryType.MESSENGER,
    [NodeType.INSTAGRAM]: TriggerCategoryType.INSTAGRAM,
    [NodeType.WHATS_APP]: TriggerCategoryType.WHATSAPP,
    [NodeType.SMS]: TriggerCategoryType.SMS,
    [NodeType.TELEGRAM]: TriggerCategoryType.TELEGRAM,
    [NodeType.TIKTOK]: TriggerCategoryType.TIKTOK,
  }

  return categoryMap[rootNode?.nodeType]
}

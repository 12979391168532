import { api } from 'shared/api'
import { SequenceMessageSchemas } from 'shared/api/requests/sequenceMessage/schemas'

export const SequenceMessageApi = {
  setSendingSettings: api.account.createPost({
    url: '/sequenceMessage/setSendingSettings',
    schemas: SequenceMessageSchemas.setSendingSettings,
  }),
  create: api.account.createPost({
    url: '/sequenceMessage/create',
    schemas: SequenceMessageSchemas.create,
  }),
  delete: api.account.createGet({
    url: '/sequenceMessage/delete',
    schemas: SequenceMessageSchemas.delete,
  }),
  patch: api.account.createPost({
    url: '/sequenceMessage/patch',
    schemas: SequenceMessageSchemas.patch,
  }),
  setFlow: api.account.createPost({
    url: '/sequenceMessage/setFlow',
    schemas: SequenceMessageSchemas.setFlow,
  }),
}

import { createContext } from 'react'

import { BillingServiceInterface } from 'common/billing/billingService/interfaces'

export interface BillingContextValue {
  /**
   * Singleton with common billing methods.
   */
  billing: BillingServiceInterface
}

export const BillingContext = createContext<BillingContextValue | null>(null)

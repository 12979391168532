import { createAppAsyncThunk } from 'reduxTyped'
import { l } from '@manychat/manyui'

import { alert } from 'common/core'
import { ChannelType } from 'common/core/constants/ChannelType'
import { ReasonStatuses } from 'common/oneTimeNotify/constants/notifyReasonConstants'
import { WebSocketEvents } from 'constants/WebSockets'
import { handleCatch } from 'shared/api/lib/errors/handlers'
import { mapResponse } from 'shared/api/lib/mapResponse'
import { NotificationReasonApi } from 'shared/api/requests/notificationReason'
import {
  NotificationReason,
  NotificationReasonGetListPayload,
} from 'shared/api/requests/notificationReason/schemas'
import { createListSlice } from 'shared/lib/factory/redux/createListSlice'
import { createListOperations } from 'shared/lib/factory/redux/createListSlice/lib/createListOperations'
import { anotherTabNotificationsListener } from 'utils/services/notificationsService'

const namespace = 'notificationReason'

const {
  reducer: NotificationReasonReducer,
  actions: baseListActions,
  selectors: NotificationReasonSelectors,
  initialState: NotificationReasonInitialState,
} = createListSlice({
  storePath: 'notificationReason',

  namespace,

  operations: createListOperations({
    getList: mapResponse(NotificationReasonApi.getList, (response) => response.data.reasons),
    getNextList: undefined,
    getPrevList: undefined,
    createItem: mapResponse(NotificationReasonApi.create, (response) => response.data.reason),
    deleteItem: NotificationReasonApi.delete,
    updateItem: undefined,
  }),

  getItemId: (item) => item.reason_id,

  extraState: {},
  reducers: {},
})

const getTopicsListFx = createAppAsyncThunk(
  `${namespace}/getTopicsListFx`,
  (payload: NotificationReasonGetListPayload, { dispatch }) => {
    if (!payload.channel || [ChannelType.FB, ChannelType.INSTAGRAM].includes(payload.channel)) {
      dispatch(
        baseListActions.getListFx({
          payload: {
            query: payload,
          },
        }),
      )
    }
  },
)

const archiveTopicFx = createAppAsyncThunk(
  `${namespace}/archiveItemFx`,
  async ({ reasonId }: { reasonId: number }, { dispatch }) => {
    try {
      await NotificationReasonApi.archive({ body: { reason_id: reasonId } })

      dispatch(
        baseListActions.updateItem({ id: reasonId, data: { status: ReasonStatuses.ARCHIVE } }),
      )

      alert(l.translate('Topic archived!'), 'success')
    } catch (error) {
      handleCatch(error)
    }
  },
)

const restoreTopicFx = createAppAsyncThunk(
  `${namespace}/archiveItemFx`,
  async ({ reasonId }: { reasonId: number }, { dispatch }) => {
    try {
      await NotificationReasonApi.restore({ body: { reason_id: reasonId } })

      dispatch(
        baseListActions.updateItem({ id: reasonId, data: { status: ReasonStatuses.ACTIVE } }),
      )

      alert(l.translate('Topic restored!'), 'success')
    } catch (error) {
      handleCatch(error)
    }
  },
)

const NotificationReasonActions = {
  ...baseListActions,
  getTopicsListFx,
  archiveTopicFx,
  restoreTopicFx,
}

export {
  NotificationReasonActions,
  NotificationReasonSelectors,
  NotificationReasonReducer,
  NotificationReasonInitialState,
}

export type NotificationReasonState = ReturnType<typeof NotificationReasonReducer>

anotherTabNotificationsListener.on(
  WebSocketEvents.NOTIFICATION_REASON_CREATED,
  (data: { model: NotificationReason }, dispatch) => {
    dispatch(baseListActions.appendItem({ item: data.model }))
  },
)

anotherTabNotificationsListener.on(
  WebSocketEvents.NOTIFICATION_REASON_ARCHIVED,
  (data: { model: NotificationReason }, dispatch) => {
    dispatch(
      baseListActions.updateItem({
        id: data.model.reason_id,
        data: { status: ReasonStatuses.ARCHIVE },
      }),
    )
  },
)

anotherTabNotificationsListener.on(
  WebSocketEvents.NOTIFICATION_REASON_RESTORED,
  (data: { model: NotificationReason }, dispatch) => {
    dispatch(
      baseListActions.updateItem({
        id: data.model.reason_id,
        data: { status: ReasonStatuses.ACTIVE },
      }),
    )
  },
)

anotherTabNotificationsListener.on(
  WebSocketEvents.NOTIFICATION_REASON_DELETED,
  (data: { model: NotificationReason }, dispatch) => {
    dispatch(baseListActions.deleteItem({ id: data.model.reason_id }))
  },
)

anotherTabNotificationsListener.on(
  WebSocketEvents.FB_RN_TOPIC_OPTED_IN,
  (data: { model: NotificationReason }, dispatch) => {
    dispatch(
      baseListActions.updateItem({ id: data.model.reason_id, rewrite: true, data: data.model }),
    )
  },
)

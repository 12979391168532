import { useEffect, useState } from 'react'
import { useAppSelector } from 'reduxTyped'

import { getUser } from 'common/core/selectors/appSelectors'
import { ZendeskApi } from 'shared/api/requests/zendesk'

import { ASSETS_URL, SCRIPT_ID, ZENDESK_KEY, PAGE_FIELD_ID } from './constants'
import { ZendeskApiArgs, ZendeskApiPayload } from './zendesk'

interface Api {
  (args: ZendeskApiArgs): void
}

interface ZendeskHookProps {
  pageId?: string
  onLoaded?: () => void
}

const canUseDom = () => {
  return Boolean(typeof window !== 'undefined' && window.document && window.document.createElement)
}

const api = ({ type, command, payload }: ZendeskApiArgs) => {
  if (!canUseDom()) {
    return
  }

  const { zE } = window

  if (!zE) {
    return
  }

  zE(type, command, payload)
}

export const useMessengerApi = ({
  onLoaded,
  pageId,
}: ZendeskHookProps): {
  messengerApi: Api
  isWidgetOpen: boolean
  isChatEnabled: boolean
} => {
  const user = useAppSelector(getUser)
  const [isWidgetOpen, setIsWidgetOpen] = useState(false)

  const isChatEnabled = Boolean(window.zendesk_chat_settings?.hasZendeskChat)

  useEffect(() => {
    if (!isChatEnabled || window?.document?.getElementById(SCRIPT_ID)) {
      return
    }

    const script = document.createElement('script')
    script.id = SCRIPT_ID
    script.src = `${ASSETS_URL}?key=${ZENDESK_KEY}`
    script.async = true
    script.defer = true

    script.onload = () => {
      api({
        type: 'messenger',
        command: 'loginUser',
        payload: async (login) => {
          const response = await ZendeskApi.messagingSignon({
            body: {
              external_id: user.user_id,
            },
          })

          if (login && response?.data?.token) {
            login(response.data.token)
          }

          api({ type: 'messenger:on', command: 'open', payload: () => setIsWidgetOpen(true) })

          api({ type: 'messenger:on', command: 'close', payload: () => setIsWidgetOpen(false) })

          if (pageId) {
            api({
              type: 'messenger:set',
              command: 'conversationFields',
              payload: [{ id: PAGE_FIELD_ID, value: pageId }] as unknown as ZendeskApiPayload,
            })
          }

          if (onLoaded) {
            onLoaded()
          }
        },
      })
    }

    document.body.appendChild(script)

    return () => {
      document.body.removeChild(script)
      delete window.zE
    }
  }, [onLoaded, user.user_id, isChatEnabled, pageId])

  return { messengerApi: api, isWidgetOpen, isChatEnabled }
}

import * as atypes from 'apps/applications/constants/ReduxActionTypes'
import { makeCurrentItemReducer } from 'utils/factory'

const currentItemReducer = makeCurrentItemReducer('applications')

export default function currentApplicationReducer(state, action) {
  state = currentItemReducer(state, action)

  switch (action.type) {
    case atypes.APPLICATION_UPDATE_REQUEST:
      return { ...state, updating: true }

    case atypes.APPLICATION_ACCOUNTS_FETCH_REQUEST:
      return { ...state, fetching: true }

    case atypes.APPLICATION_INSTALL_FETCH_REQUEST:
    case atypes.APPLICATION_UNINSTALL_FETCH_REQUEST:
      return {
        ...state,
        accounts: state.accounts.map((account) => {
          if (account.account_id === action.accountId) {
            return { ...account, fetching: true }
          }
          return account
        }),
      }

    case atypes.APPLICATIONS_CURRENT_FETCH_REQUEST:
    case atypes.APPLICATION_CREATE_RESPONSE:
      return { ...state, errors: [] }

    case atypes.APPLICATION_UPDATE_RESPONSE: {
      return { ...state, updating: false, errors: [], item: action.data?.app }
    }
    case atypes.APPLICATION_ACCOUNTS_FETCH_RESPONSE:
      return { ...state, fetching: false, accounts: action.data?.accounts }

    case atypes.CHANGE_INSTALL_STATUS:
      return {
        ...state,
        accounts: state.accounts.map((account) => {
          if (account.account_id === action.accountId) {
            return { ...account, app_status: !account.app_status }
          }
          return account
        }),
      }

    case atypes.APPLICATION_INSTALL_FETCH_RESPONSE:
    case atypes.APPLICATION_UNINSTALL_FETCH_RESPONSE:
    case atypes.APPLICATION_INSTALL_FETCH_ERROR:
    case atypes.APPLICATION_UNINSTALL_FETCH_ERROR:
      return {
        ...state,
        accounts: state.accounts.map((account) => {
          if (account.account_id === action.accountId) {
            return { ...account, fetching: false }
          }
          return account
        }),
      }

    case atypes.APPLICATION_CREATE_ERROR:
    case atypes.APPLICATION_UPDATE_ERROR: {
      const errors = (action.error.errors[0]?.message ? action.error.errors : [])
        .sort((a, b) => {
          if (a.path === b.path) {
            return 0
          }
          return a.path > b.path ? 1 : -1
        })
        .reduce((acc, cur) => {
          const last = acc[acc.length - 1]
          if (last && last.value === cur.value && last.path === cur.path) {
            last.message += ` ${cur.message}`
            return acc
          }
          acc.push({ ...cur })
          return acc
        }, [])

      return {
        ...state,
        updating: false,
        errors,
      }
    }
    case atypes.APPLICATION_ACCOUNTS_FETCH_ERROR:
      return { ...state, fetching: false }
    case atypes.APPLICATION_ERRORS_CLEAN:
      return { ...state, errors: [] }
  }

  return state
}

import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import {
  WhatsAppCoexistenceState,
  CoexistenceContactImportStatus,
} from 'apps/whatsApp/interfaces/whatsAppCoexistence'

const initialState: WhatsAppCoexistenceState = {
  contactImportStatus: CoexistenceContactImportStatus.INACTIVE,
}

export const coexistenceSlice = createSlice({
  name: 'coexistence',
  initialState,
  reducers: {
    setCoexistenceContactImportStatus: (
      state,
      action: PayloadAction<CoexistenceContactImportStatus>,
    ) => {
      state.contactImportStatus = action.payload
    },
  },
})

export const { setCoexistenceContactImportStatus } = coexistenceSlice.actions

export const getWhatsAppCoexistenceContactImportStatus = (state: RootState) => {
  return state.whatsApp.coexistence.contactImportStatus
}

import omit from 'lodash/omit'
import { ZodIssue, ZodInvalidTypeIssue, ZodIssueCode, ZodIssueOptionalMessage } from 'zod'

type IssuePath = string

export const mergeZodIssues = (issues: ZodIssue[]): ZodIssue[] => {
  const result: ZodIssue[] = []

  const mergeableIssues: Array<ZodIssue & { messagesCollection: Set<string> }> = []
  const mergeableIssueIndexesMap: Record<IssuePath, number> = {}

  issues.forEach((issue) => {
    if (!isMergeableIssue(issue) || !issue.message) {
      result.push(issue)
      return
    }

    const issuePath = replaceUniqueIssuePathIndex(issue.path)
    const issuePathAsString = issuePath.join('.')
    const issueIndex = mergeableIssueIndexesMap[issuePathAsString]

    if (!mergeableIssues[issueIndex]) {
      mergeableIssues.push({
        ...issue,
        path: issuePath,
        messagesCollection: new Set([issue.message]),
      })

      mergeableIssueIndexesMap[issuePathAsString] = mergeableIssues.length - 1
    } else {
      const seenIssue = mergeableIssues[issueIndex]

      if (seenIssue.messagesCollection instanceof Set) {
        seenIssue.messagesCollection.add(issue.message)
      }
    }
  })

  mergeableIssues.forEach((issue) => {
    const message = [...(issue.messagesCollection as Set<string>)].join('. ')

    if (issue.messagesCollection.size === 1) {
      const mergedIssue = omit(issue, 'messagesCollection') as ZodIssue

      result.push({
        ...mergedIssue,
        message,
      })
    } else {
      const customIssue = omit(issue, 'expected', 'received', 'messagesCollection') as ZodIssue

      result.push({
        ...customIssue,
        message,
      })
    }
  })

  return result
}

const isMergeableIssue = (
  issue: ZodIssueOptionalMessage | ZodIssue,
): issue is ZodInvalidTypeIssue => issue.code === ZodIssueCode.invalid_type

const replaceUniqueIssuePathIndex = (path: Array<string | number>) =>
  path.map((field) => {
    if (typeof field === 'number') {
      return '[number]'
    }

    return field
  })

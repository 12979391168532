const URL_SOME_PROTOCOL_REGEX = new RegExp(
  /(\b(https?|ftp|file):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/,
  `gid`,
)

export const removeTags = (text) => {
  const htmlTagRegex = /(<[^>]+>)/g
  return text.replace(htmlTagRegex, '')
}

export const splitForUrlWithSomeProtocol = (string) => {
  if (!string) {
    return []
  }

  const result = []
  let specialSymbol = URL_SOME_PROTOCOL_REGEX.exec(string)
  let startIndex = 0

  while (specialSymbol !== null) {
    const [startPos, endPos] = specialSymbol.indices[0]
    const element = string.substring(startIndex, startPos)
    const regexSymbol = string.substring(startPos, endPos)

    element && result.push(element)
    result.push(regexSymbol)

    startIndex = endPos
    specialSymbol = URL_SOME_PROTOCOL_REGEX.exec(string)

    if (specialSymbol == null) {
      const lastElement = string.substring(startIndex, string.length)
      lastElement && result.push(lastElement)
    }
  }

  if (result.length) {
    return result
  }

  return [string]
}

export const getDeserializationDataOfTextWithLinks = (text, options) => {
  const { hasSaveHref, hasRemoveTags, hasTrim } = options
  const result = []
  let formattedText = text

  if (hasRemoveTags) {
    formattedText = removeTags(formattedText)
  }

  if (hasTrim) {
    formattedText = formattedText.trim()
  }

  if (!hasSaveHref) {
    result.push({ text: formattedText })
    return result
  }

  const formattedTextArray = splitForUrlWithSomeProtocol(formattedText)

  formattedTextArray.forEach((text) => {
    const hasText = Boolean(text)

    if (!hasText) {
      return
    }

    const hasLink = URL_SOME_PROTOCOL_REGEX.test(text)
    if (hasLink) {
      result.push({ link: text })
      return
    }

    result.push({ text })
  })
  return result
}

export const getDeserializationData = (
  str,
  options = { hasSaveHref: false, hasRemoveTags: false, hasTrim: false },
) => {
  if (!str) {
    return str
  }

  const result = []
  const stringWithoutSpace = str.replace(/&nbsp;/gm, ' ')
  const regex = new RegExp(/(\r\n|\r|\n|<\/?br ?\/?>)/, 'gd')
  let specialSymbol = regex.exec(stringWithoutSpace)
  let startIndex = 0

  while (specialSymbol !== null) {
    const [startPos, endPos] = specialSymbol.indices[0]
    const element = stringWithoutSpace.substring(startIndex, startPos)
    const row = getDeserializationDataOfTextWithLinks(element, options)
    result.push(row)

    startIndex = endPos
    specialSymbol = regex.exec(stringWithoutSpace)

    if (specialSymbol == null) {
      const lastElement = stringWithoutSpace.substring(startIndex, stringWithoutSpace.length)
      const row = getDeserializationDataOfTextWithLinks(lastElement, options)
      result.push(row)
    }
  }

  if (result.length) {
    return result
  }

  result.push(getDeserializationDataOfTextWithLinks(stringWithoutSpace, options))
  return result
}

import { z } from 'zod'

import { BusinessErrorSchema } from 'shared/api/common/schemas/errors'
import { BusinessError } from 'shared/api/lib/errors/business'

interface UsedGlobalFieldNameError extends BusinessError {
  field: 'caption' | 'description'
}

const UsedGlobalFieldNameValueErrorSchema = BusinessErrorSchema.extend({
  field: z.literal('value'),
})

type UsedGlobalFieldNameValueError = z.infer<typeof UsedGlobalFieldNameValueErrorSchema>

const isUsedGlobalFieldNameError = (error: BusinessError): error is UsedGlobalFieldNameError =>
  error.field === 'caption' || error.field === 'description'

const isUsedGlobalFieldNameValueError = (error: unknown): error is UsedGlobalFieldNameValueError =>
  UsedGlobalFieldNameValueErrorSchema.safeParse(error).success

export const GlobalFieldsApiGuards = {
  update: {
    isUsedGlobalFieldNameError,
    isUsedGlobalFieldNameValueError,
  },
}

import flatten from 'lodash/flatten'
import omit from 'lodash/omit'
import pick from 'lodash/pick'
import uniq from 'lodash/uniq'
import uniqBy from 'lodash/uniqBy'
import { v4 as uuid } from 'uuid'

import { WidgetTypes } from 'apps/growthTools/models/Widget/constants'
import { ITriggerRuleTrigger } from 'apps/triggerRules/triggerRulesInterfaces'
import { Action } from 'common/actions/models/Action/action'
import { ActionTypes } from 'common/actions/models/Action/constants'
import blockRegistry from 'common/builder/blockRegistry'
import { IBlock } from 'common/builder/blocks/blockInterfaces'
import buttonRegistry from 'common/builder/buttonRegistry'
import { IButton } from 'common/builder/buttons/buttonInterfaces'
import { BlockType } from 'common/builder/constants/BlockType'
import { NodeType } from 'common/builder/constants/NodeType'
import {
  IBuilderAttachmentData,
  IBuilderFlowCampaignNodesData,
  IBuilderFlowCampaignPreset,
  IBuilderFlowCampaignsAttachment,
  IBuilderFlowCampaignsBlock,
  IBuilderFlowCampaignsCoordinates,
  IBuilderFlowCampaignsCUF,
  IBuilderFlowCampaignsNode,
  IBuilderFlowCampaignsTag,
  IBuilderFlowCampaignsTrigger,
} from 'common/builder/models/FlowCampaign/flowCampaignInterfaces'
import { INode } from 'common/builder/nodes/nodeInterfaces'
import * as builderStateSelectors from 'common/builder/selectors/builder/builderStateSelectors'
import * as entitySelectors from 'common/builder/selectors/builder/entitySelectors'
import * as userFieldsSelectors from 'common/fields/selectors/userFieldsSelectors'
import { IFilterGroup } from 'common/filter/interfaces'
import { TriggerType } from 'common/flow/constants/TriggerType'
import * as flowSelectors from 'common/flow/selectors/flowSelectors'
import { TagsSelectors } from 'common/tags/store'
import * as variablesHelpers from 'components/MCTextEditor/variables/helpers'
import { omitDefaultValues } from 'utils/omitDefaultValues'

const SUPPORTED_TRIGGER_TYPES = [
  TriggerType.WIDGET,
  TriggerType.KEYWORD,
  TriggerType.RULE,
  TriggerType.EXTERNAL_TRIGGER,
  TriggerType.AI_INTENT,
]

const NODE_ATTRS_WHITELIST = [
  'id',
  'entity',
  'caption',
  'nodeType',
  'targetId',
  'blocks',
  'items',
  'noteId',
  'text',
  'fontSize',
  'nodeSize',
  'color',
  'reasonId',
  'messageTag',
  'privateReply',
  'subject',
  'preheader',
  'delayValue',
  'delayUnits',
  'delayType',
  'waitUntil',
  'useTimeWindow',
  'sendingInterval',
  'weekdays',
  'randomized',
  'channel',
  'agent_data',
]

export const exportFlowCampaign = (
  state: RootState,
  builderId: string,
): IBuilderFlowCampaignPreset => {
  const builderState = builderStateSelectors.getById(state, builderId)

  const supportedTriggers = flowSelectors
    .getFlowTriggers(state, builderState.flow)
    .filter((t) => SUPPORTED_TRIGGER_TYPES.includes(t.triggerType))

  const tags: IBuilderFlowCampaignsTag[] = []
  const cufs: IBuilderFlowCampaignsCUF[] = []
  const attachments: IBuilderFlowCampaignsAttachment[] = []
  const triggers: IBuilderFlowCampaignsTrigger[] = []

  supportedTriggers.forEach((trigger) => {
    if (trigger.triggerType === TriggerType.WIDGET) {
      const { caption, channel, widget_type, data } = trigger
      const initialTrigger: IBuilderFlowCampaignsTrigger = {
        nodeType: TriggerType.WIDGET,
        caption,
        widget_type,
        channel,
        init_widget_data: data,
      }

      if (widget_type === WidgetTypes.FEED_COMMENT_TRIGGER) {
        const { feed_comment_settings, feed_comment_welcome } = data ?? {}

        triggers.push({
          ...initialTrigger,
          feed_comment_settings,
          feed_comment_welcome,
        })
      } else {
        triggers.push(initialTrigger)
      }
    } else if (trigger.triggerType === TriggerType.KEYWORD) {
      const { caption, channel, keyword_rules } = trigger
      triggers.push({
        nodeType: TriggerType.KEYWORD,
        caption,
        keyword_rules,
        channel,
      })
    } else if (trigger.triggerType === TriggerType.AI_INTENT) {
      triggers.push({
        nodeType: TriggerType.AI_INTENT,
        channel: trigger.channel,
        intent: trigger.intent,
      })
    } else if (trigger.triggerType === TriggerType.EXTERNAL_TRIGGER) {
      const { externalTriggerType, data } = trigger
      triggers.push({
        nodeType: TriggerType.EXTERNAL_TRIGGER,
        trigger_key: externalTriggerType,
        app_id: trigger.appId,
        data,
      })
    } else if (trigger.triggerType === TriggerType.RULE) {
      const { caption } = trigger
      triggers.push({ nodeType: TriggerType.RULE, caption, triggers: trigger.triggers })

      if (trigger.triggers) {
        trigger.triggers.forEach((trigger: ITriggerRuleTrigger) => {
          const eventFilter = trigger.data?.event_filter
          if (!eventFilter || !('field' in eventFilter)) {
            return
          }
          const cuf = userFieldsSelectors.getById(state, eventFilter.field)
          if (cuf) {
            cufs.push({
              id: cuf.field_id,
              caption: cuf.caption,
              type: cuf.type,
              description: cuf.description,
            })
          }
        })
      }
    }
  })

  const nodes = entitySelectors
    .getNodesList(state, builderId)
    .filter((n) => n.nodeType !== NodeType.STARTING_STEP)
  const nodesDataOrig = nodes.map((n) =>
    entitySelectors.getAllRelated<INode>(state, builderId, n.id),
  )

  const nodesData: IBuilderFlowCampaignNodesData[] = nodesDataOrig.map(([nodeOrig, related]) => {
    const blocks = related.blocks.map((block) => {
      const blockDefaults = blockRegistry.getByType(block.type).getDefaults()
      const blockWithoutStats = omit(block, 'stats', 'reasonId') as IBlock
      const flowCampaignBlock = omitDefaultValues(
        blockWithoutStats,
        blockDefaults,
      ) as IBuilderFlowCampaignsBlock

      if (block.type === BlockType.FORM_QUESTION) {
        const cuf = userFieldsSelectors.getById(state, block.customFieldId)
        if (cuf) {
          cufs.push({
            id: cuf.field_id,
            caption: cuf.caption,
            type: cuf.type,
            description: cuf.description,
          })
        }
      }

      if (block.type === BlockType.CASE) {
        if (block.filter) {
          block.filter.groups.forEach((group: IFilterGroup) => {
            group.items.forEach((item) => {
              if (item.type === 'cuf') {
                const cuf = userFieldsSelectors.getById(state, item.field)
                if (cuf) {
                  cufs.push({
                    id: cuf.field_id,
                    caption: cuf.caption,
                    type: cuf.type,
                    description: cuf.description,
                  })
                }
              }
              if (item.type === 'tag') {
                const tag = TagsSelectors.getItemById(state, item.value as number)
                if (tag) {
                  tags.push({ id: tag.tag_id, name: tag.tag_name })
                }
              }
            })
          })
        }
      }

      if (block.type === BlockType.ATTACHMENT) {
        if (block.attachment) {
          const id = uuid()
          attachments.push({ id, data: block.attachment as IBuilderAttachmentData })
          flowCampaignBlock.attachment = { id }
        }
      }

      let blockVariables: string[] = []
      if (block.type === BlockType.TEXT) {
        if (block.text) {
          const variables = variablesHelpers.extractVariables(block.text)
          blockVariables = [...blockVariables, ...variables]
        }
      }
      if (block.type === BlockType.CARD) {
        if (block.subtitle) {
          const variables = variablesHelpers.extractVariables(block.subtitle)
          blockVariables = [...blockVariables, ...variables]
        }
        if (block.title) {
          const variables = variablesHelpers.extractVariables(block.title)
          blockVariables = [...blockVariables, ...variables]
        }
        if (block.image) {
          const id = uuid()
          attachments.push({ id, data: block.image as IBuilderAttachmentData })
          flowCampaignBlock.image = { id }
        }
      }

      blockVariables.forEach((variable) => {
        const cuf = userFieldsSelectors.getById(state, variable)
        if (cuf) {
          cufs.push({
            id: cuf.field_id,
            caption: cuf.caption,
            type: cuf.type,
            description: cuf.description,
          })
        }
      })

      return flowCampaignBlock
    })
    const buttons = related.buttons.map((button) => {
      const buttonDefaults = buttonRegistry.getByType(button.type).getDefaults()
      const buttonWithoutStats = omit(button, 'stats') as IButton
      return omitDefaultValues(buttonWithoutStats, buttonDefaults)
    })
    const node = pick(nodeOrig, NODE_ATTRS_WHITELIST) as IBuilderFlowCampaignsNode

    if (node.nodeType === NodeType.ACTION_GROUP) {
      node.items?.forEach((item: Action) => {
        if (item.type === ActionTypes.ADD_TAG || item.type === ActionTypes.REMOVE_TAG) {
          const tag = TagsSelectors.getItemById(state, item.tag_id as number)
          if (!tag) {
            return
          }
          tags.push({ id: item.tag_id ?? 0, name: tag.tag_name })
        }
        if (item.type === ActionTypes.SET_CUF || item.type === ActionTypes.UNSET_CUF) {
          const cuf = userFieldsSelectors.getById(state, item.field_id)
          if (!cuf) {
            return
          }
          cufs.push({
            id: cuf.field_id,
            caption: cuf.caption,
            type: cuf.type,
            description: cuf.description,
          })
        }
      })

      node.items = node.items?.map((item: Action) => {
        if (item.type === ActionTypes.NOTIFY_ADMINS) {
          const uniqSendBy = uniq(flatten(item.send_to.map((sendTo) => sendTo.send_by)))
          const all_send_by = uniq([...item.all_send_by, ...uniqSendBy])
          return {
            ...item,
            send_to: [],
            all_send_by,
          }
        }
        return item
      })
    }

    return {
      related: {
        blocks,
        buttons,
      },
      node,
    }
  })

  const { coordinates: coords, rootId } = builderStateSelectors.getById(state, builderId)
  const coordinates: IBuilderFlowCampaignsCoordinates = pick(
    coords,
    ...['startingStep', ...nodes.map((n) => n.id)],
  )

  return {
    nodesData,
    coordinates,
    rootId,
    tags: uniqBy(tags, 'id'),
    cufs: uniqBy(cufs, 'id'),
    attachments,
    triggers,
  }
}

import React from 'react'
import cn from 'classnames'
import { z } from 'zod'

import { BlockType } from '../../constants'
import { registry } from '../../registry'
import { ChildrenSchema, ClassNameSchema, StylesSchema } from '../../schemas'
import { BlockProps } from '../../types'
import { Slot } from '../Slot'

const ColumnPropsSchema = z.object({
  className: ClassNameSchema,
  children: ChildrenSchema,
  style: StylesSchema,
})

const Component = ({
  blockId,
  className,
  children,
  ...rest
}: BlockProps<typeof ColumnPropsSchema>) => (
  <div data-block={blockId} className={cn('d-flex flex-col gap', className)} {...rest}>
    {children}
  </div>
)

export const Column = registry.register(Component, {
  props: ColumnPropsSchema,
  type: BlockType.COLUMN,
  previewProps: {
    children: [],
  },
  preview: () => (
    <Column className="panel panel-info" style={{ maxWidth: 'fit-content' }}>
      <Slot style={{ width: 50, height: 50 }} />
      <Slot style={{ width: 50, height: 50 }} />
    </Column>
  ),
})

import React from 'react'
import { l, LinkBtn } from '@manychat/manyui'

import { useViewContext } from '../../context'

import { ProductTableLegacyContextSchema } from './ProductTableLegacyContextSchema'

export const TierListBtn = (): JSX.Element => {
  const { showTiers } = useViewContext(ProductTableLegacyContextSchema)

  const handleLinkClick = () => {
    showTiers()
  }

  return <LinkBtn label={l.translate('Tier based')} onClick={handleLinkClick} />
}

import React, { useCallback, useEffect, useMemo, useRef } from 'react'
import { useAppSelector } from 'reduxTyped'
import { l, useToast } from '@manychat/manyui'

import GoProBtn from 'common/billing/components/GoProBtn'
import BillingTypes from 'common/billing/constants/billingTypes'
import { SubscriptionAction } from 'common/billing/constants/SubscriptionAction'
import { RequestUpgradePayload } from 'common/billing/interfaces'
import { getSubscriptionAction } from 'common/billing/selectors/billingSelectors'
import { getCurrentAccount, getIsPro } from 'common/core/selectors/appSelectors'

export interface ProAlertProps extends RequestUpgradePayload {
  message: string
  /*
   * Custom button label for pro alert
   */
  buttonLabel?: string
  onUpgrade: (value: boolean) => void
}

const ProAlert = (props: ProAlertProps) => {
  const { message: messageProp, buttonLabel, onUpgrade, ...request } = props
  const { onClose } = useToast()
  const isPro = useAppSelector(getIsPro)
  const { billing_type } = useAppSelector(getCurrentAccount)
  const isLegacyPro = isPro && billing_type !== BillingTypes.STRIPE
  const subscriptionAction = useAppSelector(getSubscriptionAction)
  const wasUpgradeClicked = useRef(false)

  const message = useMemo(() => {
    if (isLegacyPro) {
      return l.translate('Contact your Customer Success Manager to get access to this feature.')
    }

    if (subscriptionAction === SubscriptionAction.CONTINUE_CHECKOUT) {
      return l.translate(
        'Verify your bank payment to maintain your PRO subscription and continue using this feature',
      )
    }

    return messageProp
  }, [messageProp, subscriptionAction, isLegacyPro])

  const handleUpgradeClick = () => {
    wasUpgradeClicked.current = true
    onClose()
  }

  const handleClose = useCallback(() => {
    if (!wasUpgradeClicked.current) {
      onUpgrade?.(false)
    }
  }, [onUpgrade])

  useEffect(() => {
    return () => {
      if (!wasUpgradeClicked.current) {
        handleClose()
      }
    }
  }, [handleClose])

  return (
    <>
      {message}
      <GoProBtn
        request={request}
        forceShow={!isLegacyPro}
        id="pro_alert_upgrade_to_pro_btn"
        forceLabel={Boolean(buttonLabel)}
        label={buttonLabel}
        size="small"
        fullWidth
        className="m-t"
        onClick={handleUpgradeClick}
        onUpgrade={onUpgrade}
      />
    </>
  )
}

export default ProAlert

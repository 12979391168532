import React from 'react'
import { l } from '@manychat/manyui'

export const WaCoexistenceContactImportActiveNotification = () => {
  return (
    <span>
      {l.translate(
        "Contact import from WhatsApp Business App has started. It may take some time. We will notify you when it's completed",
      )}
    </span>
  )
}

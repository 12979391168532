import get from 'lodash/get'

import { BlockType } from 'common/builder/constants/BlockType'
import { NodeType } from 'common/builder/constants/NodeType'

import * as entitySelectors from './entitySelectors'

const PRIVATE_REPLY = 'private_reply'

export const parseErrorPath = (state, builderId, errPath) => {
  let errPathArray = errPath.split('.')
  const nodeId = errPathArray.shift()
  let node = entitySelectors.getNodeById(state, builderId, nodeId)

  if (!node) {
    return null
  }

  if (node.nodeType === NodeType.CONTENT || node.nodeType === NodeType.INSTAGRAM) {
    if (errPathArray[0] === PRIVATE_REPLY) {
      return { contentId: node.id, blockId: PRIVATE_REPLY, prop: PRIVATE_REPLY }
    }

    const block = getErrorBlock(state, builderId, errPathArray, node)
    if (block) {
      // console.log('>>> Block: ', block.id, block.type)
      // console.log('>>> path:', errPathArray.join('.'))
      if (!errPathArray.length) {
        return { contentId: node.id, blockId: block.id }
      }
      if (block.type === BlockType.QUICK_REPLY || errPathArray[0] === 'keyboard') {
        if (errPathArray[0] === 'keyboard') {
          errPathArray.shift()
        }
        const button = getErrorButton(state, builderId, errPathArray, block)
        if (button) {
          return { contentId: node.id, buttonId: button.id }
        }
      } else {
        const prop = getErrorProp(errPathArray)
        if (prop) {
          return { contentId: node.id, blockId: block.id, prop }
        }
      }
    } else {
      return { contentId: node.id, blockId: null }
    }
  }

  return { contentId: node.id, blockId: null }
}

const getErrorBlock = (state, builderId, errPathArray, parent) => {
  const blockPath = errPathArray.shift()
  const blocks = parent.blocks.map((blockId) =>
    entitySelectors.getBlockById(state, builderId, blockId),
  )

  if (blockPath === 'qr') {
    return blocks.find((block) => block.type === BlockType.QUICK_REPLY)
  }

  // The server does not know that we store the quick reply block in the same list as other blocks
  // Therefore, to ensure the indices of the blocks match, we need to use the list of "ordinary blocks"
  const ordinaryBlocks = blocks.filter((block) => block.type !== BlockType.QUICK_REPLY)
  const block = get(ordinaryBlocks, blockPath)

  if (block && errPathArray[0] === 'elements') {
    errPathArray.shift()
    return getErrorBlock(state, builderId, errPathArray, block)
  }
  return block
}

const getErrorButton = (state, builderId, errPathArray, parent) => {
  let buttonPath = errPathArray.shift()
  for (let i = 0; i < parent.buttons.length; i++) {
    if (buttonPath === String(i)) {
      return entitySelectors.getButtonById(state, builderId, parent.buttons[i])
    }
  }
  return null
}

const getErrorProp = (errPathArray) => {
  if (errPathArray.length === 2) {
    if (errPathArray[0] === 'actions') {
      return 'targetId'
    }
    if (errPathArray[0] === 'adapters') {
      return 'customFieldId'
    }
  }
  const path = errPathArray.join('.')
  switch (path) {
    case 'content.text':
      return 'text'
    case 'content.data':
      return 'attachment'
    case 'top_element_style':
      return 'topElementStyle'
    case 'content.title':
      return 'title'
    case 'content.subtitle':
      return 'subtitle'
    case 'content.image':
      return 'image'
    case 'content.default_action':
      return 'defaultAction'
  }
  return null
}

import { l } from '@manychat/manyui'

import { NodeType } from 'common/builder/constants/NodeType'
import BaseNodeConfig from 'common/builder/nodes/BaseNodeConfig'
import { IActionGroupNode, INodeBase } from 'common/builder/nodes/nodeInterfaces'

class ActionGroupNodeConfig extends BaseNodeConfig<IActionGroupNode> {
  nodeType = NodeType.ACTION_GROUP

  getCaption = () => l.translate('Actions')
  getCaptionWithCount = (n: number) =>
    l.translate('{n, plural, one {# Action Group} other {# Action Groups}}', { n })
  icon = 'OrphanAction'
  color = '#FF9900'
  headerColor = '#fdf0d0'

  getDefaults = (): Omit<IActionGroupNode, keyof INodeBase> => ({
    targetId: null,
    items: [],
    orderedActionItemIds: [],
    onboardingElementId: 'action_node',
  })

  isEmpty = ({ node }: { node: IActionGroupNode }) => {
    if (!node.items.length && node.targetId === null) {
      return true
    }
    return false
  }
}

export default new ActionGroupNodeConfig()

import React from 'react'
import { connect } from 'react-redux'
import cx from 'classnames'
import PropTypes from 'prop-types'
import { Popover, l } from '@manychat/manyui'

import InstagramImage from 'common/core/components/ChannelItem/assets/Instagram.svg'
import MessengerImage from 'common/core/components/ChannelItem/assets/Messenger.svg'
import SMSImage from 'common/core/components/ChannelItem/assets/SMS.svg'
import TelegramImage from 'common/core/components/ChannelItem/assets/Telegram.svg'
import TikTokImage from 'common/core/components/ChannelItem/assets/TikTok.svg'
import WhatsAppImage from 'common/core/components/ChannelItem/assets/WhatsApp.svg'
import {
  hasConnectedInstagramChannel,
  hasConnectedSMSChannel,
  hasConnectedTelegramChannel,
  hasConnectedTiktokChannel,
  hasConnectedWhatsAppChannel as hasConnectedWhatsAppChannelSelector,
  isFBChannelConnected,
} from 'common/core/selectors/channelSelectors'
import { PopoverList } from 'components/MuiPopover'

import cm from './ChannelPicker.module.css'

class DropdownChannelPicker extends React.Component {
  static propTypes = {
    readOnly: PropTypes.bool,
    value: PropTypes.string,
    onChange: PropTypes.func,
    hasConnectedWhatsAppChannel: PropTypes.bool,
    hasConnectedTelegramChannel: PropTypes.bool,
    hasConnectedSMSChannel: PropTypes.bool,
    hasConnectedFBChannel: PropTypes.bool,
    hasConnectedInstagramChannel: PropTypes.bool,
    hasConnectedTiktokChannel: PropTypes.bool,
    isNewKeywordInterface: PropTypes.bool,
    withTitle: PropTypes.bool,
    channelsProp: PropTypes.array,
    whitelistChannels: PropTypes.array,
  }

  static defaultProps = {
    readOnly: false,
    onChange: () => {},
    whitelistChannels: [],
  }

  state = {
    anchor: null,
    open: false,
  }

  handleRef = (ref) => (this.anchor = ref)

  selectChannel = (channel) => {
    this.props.onChange(channel)

    this.closeChannelPicker()
  }

  openChannelPicker = () => {
    const { readOnly } = this.props

    if (readOnly) {
      return
    }

    this.setState({ open: true })
  }

  closeChannelPicker = () => {
    this.setState({
      open: false,
    })
  }

  getChannels = (selectedChannel) => {
    const {
      hasConnectedFBChannel,
      hasConnectedSMSChannel,
      hasConnectedWhatsAppChannel,
      hasConnectedTelegramChannel,
      hasConnectedInstagramChannel,
      hasConnectedTiktokChannel,
      channelsProp,
      whitelistChannels,
    } = this.props
    if (channelsProp) {
      return channelsProp
    }

    const channels = []

    if (hasConnectedInstagramChannel || selectedChannel === 'instagram') {
      channels.push({
        title: l.translate('Instagram'),
        value: 'instagram',
        img: InstagramImage,
      })
    }

    if (hasConnectedTiktokChannel || selectedChannel === 'tiktok') {
      channels.push({
        title: l.translate('TikTok'),
        value: 'tiktok',
        img: TikTokImage,
      })
    }

    if (hasConnectedWhatsAppChannel || selectedChannel === 'whatsapp') {
      channels.push({
        title: l.translate('WhatsApp'),
        value: 'whatsapp',
        img: WhatsAppImage,
      })
    }

    if (hasConnectedFBChannel || selectedChannel === 'facebook') {
      channels.push({
        title: l.translate('Messenger'),
        value: 'facebook',
        img: MessengerImage,
      })
    }

    if (hasConnectedSMSChannel || selectedChannel === 'sms') {
      channels.push({
        title: l.translate('SMS'),
        value: 'sms',
        img: SMSImage,
      })
    }

    if (hasConnectedTelegramChannel || selectedChannel === 'telegram') {
      channels.push({
        title: l.translate('Telegram'),
        value: 'telegram',
        img: TelegramImage,
      })
    }

    if (whitelistChannels?.length) {
      return channels.filter((channel) => whitelistChannels.includes(channel.value))
    }

    return channels
  }

  getChannelData = () => {
    const { value } = this.props
    const channels = this.getChannels(value)
    return channels.find((channel) => channel.value === value)
  }

  render() {
    const { open } = this.state
    const { readOnly, isNewKeywordInterface, withTitle } = this.props
    const channels = this.getChannels()
    const selectedChannel = this.getChannelData()
    const popoverCm = readOnly ? cm.popoverItemReadOnly : cm.popoverItem

    return (
      <div>
        <span ref={this.handleRef}>
          {selectedChannel ? (
            <div
              className={cx(popoverCm, {
                [cm.popoverNew]: isNewKeywordInterface,
                [cm.withTitleItem]: withTitle,
              })}
              onClick={this.openChannelPicker}
            >
              <img
                src={selectedChannel?.img}
                alt={selectedChannel?.title}
                width={16}
                height={16}
                className={cx({ 'm-r-xs': withTitle })}
                onClick={this.openChannelPicker}
              />
              {withTitle ? selectedChannel?.title : null}
            </div>
          ) : (
            <a
              className={cx('l-h', cm.button)}
              onClick={this.openChannelPicker}
              data-onboarding-id="channel-picker-select-channel"
            >
              <span>{l.translate('Select channel')}</span>
            </a>
          )}
        </span>
        <Popover open={open} anchor={this.anchor} onRequestClose={this.closeChannelPicker}>
          <PopoverList>
            {channels.length ? (
              channels.map((channel) => (
                <div
                  key={channel.value}
                  onClick={() => this.selectChannel(channel.value)}
                  className={cm.popoverItem}
                  data-onboarding-id={`channel-picker-select-channel-label['${channel.value}']`}
                >
                  <img
                    src={channel.img}
                    alt={channel.title}
                    width={16}
                    height={16}
                    className="m-r-xs"
                  />
                  <span>{channel.title}</span>
                </div>
              ))
            ) : (
              <div className="p-a text-disabled text-center" style={{ maxWidth: 162 }}>
                {l.translate('There is no other channels')}
              </div>
            )}
          </PopoverList>
        </Popover>
      </div>
    )
  }
}

export default connect((state) => ({
  hasConnectedFBChannel: isFBChannelConnected(state),
  hasConnectedSMSChannel: hasConnectedSMSChannel(state),
  hasConnectedWhatsAppChannel: hasConnectedWhatsAppChannelSelector(state),
  hasConnectedTelegramChannel: hasConnectedTelegramChannel(state),
  hasConnectedInstagramChannel: hasConnectedInstagramChannel(state),
  hasConnectedTiktokChannel: hasConnectedTiktokChannel(state),
}))(DropdownChannelPicker)

import { IProductItem } from 'apps/chat/components/ProductCatalog/interfaces'
import { IUploadedImage } from 'common/builder/entityInterfaces'
import { ChannelType } from 'common/core/constants/ChannelType'
import { formatUrl } from 'utils/api/mcApi/mcApi'
import { request } from 'utils/api/mcApi/request'
import { Body, McAPI } from 'utils/api/types'

export interface IUploadedResponse {
  state: boolean
  attachment: IUploadedImage
}

export interface ISearchProductResponse {
  state: boolean
  products: IProductItem[]
}

export interface ICreateProductResponse {
  state: boolean
  product: IProductItem
}

interface IProductCatalogAPI {
  getProducts: () => Promise<IProductItem[]>
  searchProducts: (q: string) => Promise<IProductItem[]>
  createProduct: (form: Partial<IProductItem>) => Promise<boolean>
  updateProduct: (form: Partial<IProductItem>) => Promise<boolean>
  deleteProduct: (id: number) => Promise<boolean>
  uploadFile: (file: File) => Promise<IUploadedImage>
  sendProduct: (params: {
    subscriber_id: string
    product_id: number
    channel: ChannelType
  }) => Promise<boolean>
}

export const makeProductCatalogAPI = (api: McAPI): IProductCatalogAPI => ({
  async getProducts() {
    const response = await api.get<ISearchProductResponse & Body>(
      '/:currentAccountID/productCatalog/getAll',
    )

    return response.products
  },
  async searchProducts(q: string) {
    const response = await api.get<ISearchProductResponse & Body>(
      `/:currentAccountID/productCatalog/search?q=${q}`,
    )

    return response.products
  },
  async createProduct(form) {
    const response = await api.post<ICreateProductResponse & Body>(
      '/:currentAccountID/productCatalog/create',
      {
        body: form,
      },
    )
    return response.state
  },
  async updateProduct(form) {
    const response = await api.post<ICreateProductResponse & Body>(
      '/:currentAccountID/productCatalog/update',
      {
        body: form,
      },
    )
    return response.state
  },
  async deleteProduct(product_id) {
    const response = await api.post<Body>('/:currentAccountID/productCatalog/delete', {
      body: { product_id },
    })
    return response.state
  },
  async sendProduct(form) {
    const response = await api.post<ICreateProductResponse & Body>(
      '/:currentAccountID/im/sendProduct',
      {
        body: form,
      },
    )
    return response.state
  },
  async uploadFile(file: File) {
    const body = new FormData()
    body.append('0', file)

    const { url, headers } = formatUrl('/:currentAccountID/content/upload')
    const response = await request<IUploadedResponse>(url, {
      method: 'post',
      body,
      headers,
    })

    return response.attachment
  },
})

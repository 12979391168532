import { l } from '@manychat/manyui'

import { MakeTranslate } from '../../../../i18n/format/types'

import { FieldGroupId } from './enums'

export interface FieldGroup {
  id: FieldGroupId
  caption: MakeTranslate<string> | string
}

// Static fields used only in filters
export const StaticFields: FieldGroup = {
  id: FieldGroupId.STATIC,
  caption: l.makeTranslate('General Filters'),
}

export const SystemFields: FieldGroup = {
  id: FieldGroupId.SYSTEM,
  caption: l.makeTranslate('System Fields'),
}

export const CustomUserFields: FieldGroup = {
  id: FieldGroupId.USER,
  caption: l.makeTranslate('Custom User Fields'),
}

export const CustomBotFields: FieldGroup = {
  id: FieldGroupId.BOT,
  caption: l.makeTranslate('Custom Bot Fields'),
}

export const RecommendedFields: FieldGroup = {
  id: FieldGroupId.RECOMMENDED,
  caption: l.makeTranslate('Recommended'),
}

export const IntegrationFields: FieldGroup = {
  id: FieldGroupId.INTEGRATION,
  caption: l.makeTranslate('Integration User Fields'),
}

export const ContextFields: FieldGroup = {
  id: FieldGroupId.CONTEXT,
  caption: l.makeTranslate('Local Context Fields'),
}

export const TagFields: FieldGroup = {
  id: FieldGroupId.TAG,
  caption: l.makeTranslate('Tags'),
}

export const FormatDateFields: FieldGroup = {
  id: FieldGroupId.FORMAT_DATE,
  caption: '',
}

import { createSlice } from '@reduxjs/toolkit'

import { fetchEmailStats } from 'common/settings/actions/statsActions'
import { EmailBillingStats } from 'shared/api/requests/settings/stats/schemas'

export const initialState: Readonly<EmailBillingStats> = {
  total_count: 0,
  total_cost: 0,
  charge_package_size: 0,
  stats_for_days: 0,
  free_started: {
    total: 0,
    send: 0,
  },
  free_package: {
    total: 0,
    send: 0,
    expire_at: '',
  },
  daily_limit_used: 0,
  daily_limit_max: 0,
}

export const emailStatsSlice = createSlice({
  name: 'emailStats',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchEmailStats.fulfilled, (state, action) => ({
      ...state,
      ...action.payload.stats,
    }))
  },
})

import { isLocal } from 'utils/isLocal'
import { PAGE_SESSION_ID } from 'utils/pageSessionId'
import errorTrackingService from 'utils/services/errorTrackingService'
import { linkDomain } from 'utils/url'

export const sendHealthMetric = async (
  metric: string,
  context: Record<string, string | boolean>,
) => {
  if (isLocal()) {
    return
  }

  try {
    await fetch(linkDomain('/analytics/logHealth'), {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        data: { metric, uid: PAGE_SESSION_ID, context },
      }),
    })
  } catch (error) {
    errorTrackingService.trackError(error, { fingerprint: 'send-health-metric-error' })
  }
}

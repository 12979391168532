import React, { useCallback } from 'react'

import { useMcClient } from 'utils/api/query/McClientContext'
import { CacheKey, Request } from 'utils/api/query/types'

type UseRequestCacheResult<T> = [() => Request<T>, (request: Request<T>) => void]

export const useRequestCache = <T>(id: CacheKey): UseRequestCacheResult<T> => {
  const [, setState] = React.useState(false)
  const { cache } = useMcClient()

  React.useEffect(() => cache.addUpdateListener(id, () => setState((state) => !state)), [id, cache])

  const getQuery = useCallback(() => cache.get<T>(id), [cache, id])

  const setQuery = useCallback(
    (request: Request<T>) => {
      if (request.id !== id) {
        throw new Error('Cannot set query with another id')
      }
      cache.set(request)
    },
    [cache, id],
  )

  return [getQuery, setQuery]
}

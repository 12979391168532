import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import cloneDeep from 'lodash/cloneDeep'
import isEqual from 'lodash/isEqual'
import omitBy from 'lodash/omitBy'
import { createAppAsyncThunk } from 'reduxTyped'
import { createSelector } from 'reselect'

import { WaTemplateUiMode } from 'apps/whatsApp/constants/messageTemplateConstants'
import { extractVariablesFromMessageTemplate } from 'apps/whatsApp/helpers/extractVariablesFromMessageTemplate'
import { isMessageTemplateHeaderValid } from 'apps/whatsApp/helpers/isMessageTemplateHeaderValid'
import { sanitizeMessageTemplateDraft } from 'apps/whatsApp/helpers/sanitizeMessageTemplateDraft'
import { validateMessageTemplate } from 'apps/whatsApp/helpers/validateMessageTemplate'
import { WhatsAppCurrentMessageTemplateState } from 'apps/whatsApp/interfaces/whatsAppCurrentMessageTemplate'
import { hasWhatsAppProErrorStatus } from 'common/builder/selectors/builder/whatsAppSelectors'
import { getUserRole, UserRoles } from 'common/userRoles'
import { handleCatch } from 'shared/api/lib/errors/handlers'
import { WhatsAppApi } from 'shared/api/requests/whatsApp'
import { createOrEditTemplateErrorHandler } from 'shared/api/requests/whatsApp/errorHandlers'
import {
  WaEditMessageTemplateRequest,
  WaEditMessageTemplateRequestFull,
  WaMessageTemplate,
  WaMessageTemplateCategoryId,
  WaMessageTemplateDraft,
  WaMessageTemplatePack,
  WaMessageTemplateStatus,
  WaUpdateDefaultMessageTemplateRequest,
  WaUpdateMessageTemplateVariablesRequest,
} from 'shared/api/requests/whatsApp/schemas'

export const initialState: WhatsAppCurrentMessageTemplateState = {
  loading: false,
  storedTemplates: {},
  drafts: {},
  activeLanguage: null,
  defaultLanguage: null,
  category: null,
  humanName: null,
}

export const getTemplate = createAppAsyncThunk<WaMessageTemplatePack | null, string>(
  'whatsapp/currentMessageTemplate/get',
  async (name) => {
    try {
      const result = await WhatsAppApi.getMessageTemplatePack({ query: { name } })

      return result.data.message_template
    } catch (error) {
      handleCatch(error)
      return null
    }
  },
)
export const createMessageTemplate = createAppAsyncThunk<WaMessageTemplate | null>(
  'whatsapp/currentMessageTemplate/create',
  async (_, { getState }) => {
    const payload = getCurrentTemplateCreatePayload(getState())

    try {
      const result = await WhatsAppApi.createMessageTemplate({
        body: payload as WaMessageTemplate,
      })
      return result.data.message_template
    } catch (error) {
      handleCatch(error, createOrEditTemplateErrorHandler)
      return null
    }
  },
)

export const editMessageTemplate = createAppAsyncThunk<WaMessageTemplate | null>(
  'whatsapp/currentMessageTemplate/edit',
  async (_, { getState }) => {
    const payload = getCurrentTemplateEditPayload(getState())

    try {
      const result = await WhatsAppApi.editMessageTemplate({
        body: payload as WaEditMessageTemplateRequestFull,
      })
      return result.data.message_template
    } catch (error) {
      handleCatch(error, createOrEditTemplateErrorHandler)
      return null
    }
  },
)

export const updateMessageTemplateVariables = createAppAsyncThunk<
  WaMessageTemplate | null,
  WaUpdateMessageTemplateVariablesRequest
>('whatsapp/currentMessageTemplate/updateMessageTemplateVariables', async ({ id, variables }) => {
  try {
    const result = await WhatsAppApi.updateMessageTemplateVariables({
      body: { id, variables },
    })
    return result.data.message_template
  } catch (error) {
    handleCatch(error)
    return null
  }
})

export const updateDefaultMessageTemplate = createAppAsyncThunk<string | null, string>(
  'whatsapp/currentMessageTemplate/updateDefault',
  async (language, { getState }) => {
    const name = getCurrentTemplateDraftSelector(getState())?.name
    const defaultLanguage = getCurrentTemplateDefaultLanguage(getState())

    if (!name || language === defaultLanguage) return null

    const body: WaUpdateDefaultMessageTemplateRequest = { name, language }

    try {
      await WhatsAppApi.updateDefaultMessageTemplate({ body })
      return language
    } catch (error) {
      handleCatch(error)
      return null
    }
  },
)

export const currentMessageTemplateSlice = createSlice({
  name: 'whatsapp/currentMessageTemplate',
  initialState,
  reducers: {
    setHumanName: (state, action: PayloadAction<string>) => {
      state.humanName = action.payload || null

      if (state.activeLanguage && state.drafts[state.activeLanguage]) {
        state.drafts[state.activeLanguage].name_human = action.payload
      }
    },
    setCategory: (state, action: PayloadAction<WaMessageTemplateCategoryId>) => {
      state.category = action.payload

      if (state.activeLanguage && state.drafts[state.activeLanguage]) {
        state.drafts[state.activeLanguage].category = action.payload
      }
    },
    setActiveLanguage: (state, action: PayloadAction<string | null>) => {
      state.activeLanguage = action.payload
    },
    updateTemplateDraft: (state, action: PayloadAction<WaMessageTemplateDraft>) => {
      if (!state.activeLanguage || !state.drafts[state.activeLanguage]) return

      state.drafts[state.activeLanguage] = sanitizeMessageTemplateDraft(action.payload)
    },
    addLanguage: (state, action: PayloadAction<string>) => {
      state.drafts[action.payload] = { language_code: action.payload }

      if (state.category) {
        state.drafts[action.payload].category = state.category
      }

      if (state.humanName) {
        state.drafts[action.payload].name_human = state.humanName
      }

      state.activeLanguage = action.payload
    },
    deleteLanguage: (state, action: PayloadAction<string>) => {
      if (!state.drafts[action.payload]) return

      delete state.drafts[action.payload]
    },
    clearCurrentTemplate: () => {
      return initialState
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getTemplate.pending, (state) => {
      state.loading = true
    })

    builder.addCase(getTemplate.fulfilled, (state, action) => {
      if (!action.payload || action.payload.length === 0) {
        state.storedTemplates = {}
        state.drafts = {}
      } else {
        const templatesByLanguage = action.payload.reduce((acc, template) => {
          acc[template.language_code] = template
          return acc
        }, {} as Record<string, WaMessageTemplate>)

        state.storedTemplates = templatesByLanguage
        state.drafts = templatesByLanguage

        const defaultTemplate = action.payload.find((template) => template.is_default)
        state.defaultLanguage = defaultTemplate
          ? defaultTemplate.language_code
          : action.payload[0].language_code

        state.humanName = action.payload[0].name_human
        state.category = action.payload[0].category
      }

      state.loading = false
    })

    const updateTemplateReducer = (
      state: WhatsAppCurrentMessageTemplateState,
      action: PayloadAction<WaMessageTemplate | null>,
    ) => {
      state.loading = false

      const updatedTemplate = action.payload
      if (!updatedTemplate) return

      const currentData = state.storedTemplates?.[updatedTemplate.language_code]
      if (!currentData) return

      state.storedTemplates[updatedTemplate.language_code] = cloneDeep(updatedTemplate)
      state.drafts[updatedTemplate.language_code] = cloneDeep(updatedTemplate)
    }
    builder.addCase(editMessageTemplate.fulfilled, updateTemplateReducer)
    builder.addCase(updateMessageTemplateVariables.fulfilled, updateTemplateReducer)

    builder.addCase(createMessageTemplate.fulfilled, (state, action) => {
      if (!action.payload) return

      state.storedTemplates[action.payload.language_code] = cloneDeep(action.payload)
      state.drafts[action.payload.language_code] = cloneDeep(action.payload)
    })

    builder.addCase(updateDefaultMessageTemplate.fulfilled, (state, action) => {
      if (action.payload === null) return

      state.defaultLanguage = action.payload
    })
  },
})

export const getCurrentTemplateActiveLanguage = (state: RootState) =>
  state.whatsApp.currentMessageTemplate.activeLanguage

export const getCurrentTemplateDefaultLanguage = (state: RootState) =>
  state.whatsApp.currentMessageTemplate.defaultLanguage

export const getCurrentTemplateHumanName = (state: RootState) =>
  state.whatsApp.currentMessageTemplate.humanName

export const getCurrentTemplateCategory = (state: RootState) =>
  state.whatsApp.currentMessageTemplate.category

export const getCurrentTemplateByLanguageSelector = (state: RootState) =>
  state.whatsApp.currentMessageTemplate.storedTemplates

export const getCurrentTemplateSelector = createSelector(
  (state: RootState) => state.whatsApp.currentMessageTemplate.storedTemplates,
  (state: RootState) => state.whatsApp.currentMessageTemplate.activeLanguage,
  (storedTemplates, activeLanguage) => {
    if (!storedTemplates || !activeLanguage) {
      return null
    }
    return storedTemplates[activeLanguage]
  },
)

export const getCurrentTemplateDraftsByLanguageSelector = (state: RootState) =>
  state.whatsApp.currentMessageTemplate.drafts

export const getCurrentTemplateDraftSelector = createSelector(
  (state: RootState) => state.whatsApp.currentMessageTemplate.drafts,
  (state: RootState) => state.whatsApp.currentMessageTemplate.activeLanguage,
  (drafts, activeLanguage): WaMessageTemplateDraft | null => {
    if (!drafts || !activeLanguage) {
      return null
    }
    return drafts[activeLanguage]
  },
)

export const getCurrentTemplateIsSendToReviewBlocked = createSelector(
  getCurrentTemplateDraftSelector,
  (state: RootState) => state.whatsApp.currentMessageTemplate.humanName,
  (state: RootState) => state.whatsApp.currentMessageTemplate.category,
  getUserRole,
  (template, humanName, category, userRole) => {
    if (!template) return true

    const { variables } = extractVariablesFromMessageTemplate(template)

    const areVariablesExamplesMissing = variables.some((variable) => !template?.example?.[variable])
    const hasPermissions = userRole === UserRoles.ADMIN || userRole === UserRoles.EDITOR

    return Boolean(
      !category || !humanName || !template?.body || areVariablesExamplesMissing || !hasPermissions,
    )
  },
)

export const getCurrentTemplateErrors = createSelector(
  getCurrentTemplateDraftSelector,
  (state: RootState) => state.whatsApp.currentMessageTemplate.humanName,
  (draft, humanName) => {
    if (!draft) return {}

    return validateMessageTemplate(draft, { humanName: humanName || undefined })
  },
)

export const getCurrentTemplateErrorsFlat = createSelector(getCurrentTemplateErrors, (errors) => {
  if (Object.keys(errors).length > 0) {
    return Object.values(errors).flat(1)
  }
  return []
})

export const getCurrentTemplateIsAllDataValid = createSelector(
  getCurrentTemplateErrors,
  (errors) => {
    return Object.keys(errors).length === 0
  },
)

export const getCurrentTemplateUiMode = createSelector(
  [getCurrentTemplateSelector, getCurrentTemplateDraftSelector, hasWhatsAppProErrorStatus],
  (template, draft, isWhatsAppProErrorStatus) => {
    if (isWhatsAppProErrorStatus) return WaTemplateUiMode.VIEW_UNEDITABLE
    if (!draft?.status) return WaTemplateUiMode.CREATE
    if (
      draft.status === WaMessageTemplateStatus.PENDING ||
      draft.status === WaMessageTemplateStatus.DISABLED
    )
      return WaTemplateUiMode.VIEW_UNEDITABLE

    const isActiveLanguageTemplateHasUnsavedChanges = !isEqual(
      omitBy(template, (value) => value === '' || value === undefined),
      omitBy(draft, (value) => value === '' || value === undefined),
    )

    if (isActiveLanguageTemplateHasUnsavedChanges) return WaTemplateUiMode.EDIT

    return WaTemplateUiMode.VIEW_EDITABLE
  },
)

const getCurrentTemplateCommonPayload = createSelector(getCurrentTemplateDraftSelector, (draft) => {
  if (!draft) return {}
  const header =
    draft.header && isMessageTemplateHeaderValid(draft.header) ? draft.header : undefined
  const filteredButtons = draft.buttons?.filter((button) => Boolean(button.text))
  const buttons = filteredButtons && filteredButtons.length > 0 ? filteredButtons : undefined
  const example =
    draft?.example !== undefined && Object.keys(draft.example).length > 0
      ? draft.example
      : undefined

  return {
    header,
    buttons,
    example,
  }
})

const getCurrentTemplateCreatePayload = createSelector(
  [
    getCurrentTemplateDraftSelector,
    getCurrentTemplateCommonPayload,
    getCurrentTemplateDefaultLanguage,
    getCurrentTemplateActiveLanguage,
    getCurrentTemplateCategory,
    getCurrentTemplateHumanName,
  ],
  (
    draft,
    payload,
    defaultLanguage,
    activeLanguage,
    category,
    humanName,
  ): WaMessageTemplateDraft | null => {
    if (!draft) return null

    return {
      ...draft,
      ...payload,
      is_default: defaultLanguage === activeLanguage,
      category: category || undefined,
      name_human: humanName || undefined,
    }
  },
)

const getCurrentTemplateEditPayload = createSelector(
  [getCurrentTemplateDraftSelector, getCurrentTemplateCommonPayload, getCurrentTemplateCategory],
  (draft, payload, category): WaEditMessageTemplateRequest | null => {
    if (!draft) return null

    return {
      ...payload,
      id: draft.template_id,
      body: draft?.body,
      footer: draft?.footer,
      category: category || undefined,
    }
  },
)

export const getCurrentTemplateIsLoadingSelector = (state: RootState) =>
  state.whatsApp.currentMessageTemplate.loading

export const getCurrentTemplateIsEmpty = createSelector(
  (state: RootState) => state.whatsApp.currentMessageTemplate.drafts,
  (drafts) => Object.keys(drafts).length === 0,
)

export const getCurrentTemplateHasMoreThanOneLanguage = createSelector(
  (state: RootState) => state.whatsApp.currentMessageTemplate.storedTemplates,
  (storedTemplates) => Object.keys(storedTemplates).length > 1,
)

export const {
  setHumanName,
  setCategory,
  setActiveLanguage,
  updateTemplateDraft,
  addLanguage,
  deleteLanguage,
  clearCurrentTemplate,
} = currentMessageTemplateSlice.actions

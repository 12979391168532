import { createSlice } from '@reduxjs/toolkit'
import { createAppAsyncThunk } from 'reduxTyped'

import { handleCatch } from 'shared/api/lib/errors/handlers'
import { BillingApi } from 'shared/api/requests/billing'
import { BillingInfo } from 'shared/api/requests/billing/schemas'

export interface BillingInfoState {
  fetchingInvoicesSending: boolean
  fetchingSave: boolean
  isModalOpened: boolean
}

export const initialState: BillingInfoState = {
  isModalOpened: false,
  fetchingSave: false,
  fetchingInvoicesSending: false,
}

const switchInvoicesSending = createAppAsyncThunk<BillingInfo, boolean>(
  'BILLING_INFO_SWITCH_INVOICES_SENDING',
  async (enabled, thunkApi) => {
    try {
      const response = await BillingApi.switchInvoicesSending({
        body: {
          invoice_sending_enabled: enabled,
        },
      })

      return thunkApi.fulfillWithValue(response.data)
    } catch (error) {
      handleCatch(error)

      return thunkApi.rejectWithValue()
    }
  },
)

const saveBillingInfo = createAppAsyncThunk<BillingInfo, UnsafeAnyObject>(
  'BILLING_INFO_SAVE',
  async (data, thunkApi) => {
    try {
      const response = await BillingApi.saveBillingInfo({
        body: data,
      })

      return thunkApi.fulfillWithValue(response.data)
    } catch (error) {
      handleCatch(error)

      return thunkApi.rejectWithValue()
    }
  },
)

export const billingInfoSlice = createSlice({
  name: 'info',
  initialState,
  reducers: {
    openBillingInfoModal: (state) => {
      state.isModalOpened = true
    },
    closeBillingInfoModal: (state) => {
      state.isModalOpened = false
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(switchInvoicesSending.pending, (state) => {
        state.fetchingInvoicesSending = true
      })
      .addCase(switchInvoicesSending.fulfilled, (state) => {
        state.fetchingInvoicesSending = false
      })
      .addCase(switchInvoicesSending.rejected, (state) => {
        state.fetchingInvoicesSending = false
      })
      .addCase(saveBillingInfo.pending, (state) => {
        state.fetchingSave = true
      })
      .addCase(saveBillingInfo.fulfilled, (state) => {
        state.fetchingSave = false
        state.isModalOpened = false
      })
      .addCase(saveBillingInfo.rejected, (state) => {
        state.fetchingSave = false
      })
  },
})

export const billingInfoActions = {
  ...billingInfoSlice.actions,
  switchInvoicesSending,
  saveBillingInfo,
}

import { hasInstagramCGT } from 'common/flow/selectors/flowSelectors'

import { INode } from '../nodes/nodeInterfaces'
import { isBuilderEmptyState } from '../selectors/builder/commonSelectors'

import { isInstagramNode } from './isInstagramNode'

export const isConvertableToNewIgCommentReplyNode = ({
  node,
  flowId,
  builderId,
  state,
}: {
  node: INode
  flowId: string
  builderId: string
  state: RootState
}): boolean => {
  if (
    !isInstagramNode(node) ||
    !hasInstagramCGT(state, flowId) ||
    !isBuilderEmptyState(state, builderId)
  ) {
    return false
  }

  return true
}

import {
  ActionReducerMapBuilder,
  combineReducers,
  createSelector,
  createSlice,
  PayloadAction,
} from '@reduxjs/toolkit'
import { l } from '@manychat/manyui'

import { IApplicationsState } from 'common/actions/integrations/Applications/interfaces'
import { IThunkAction } from 'common/core/interfaces/actions'
import { getCurrentAccountID } from 'common/core/selectors/appSelectors'
import {
  IGroupSettingsItem,
  ISettingsItem,
} from 'common/settings/components/SettingsPanel/AppSection/installedApplications/interfaces'
import { applications, installedApplications } from 'constants/API'
import { makeGetActionCreator, makePostActionCreator } from 'utils/api/redux/actions'
import { addFetchingCases } from 'utils/api/redux/reducers'
import { Body } from 'utils/api/types'

type State = IApplicationsState['settings']

export const updateSettingsAction = makePostActionCreator<Body>(
  'application/page/settings/update',
  applications.endpoints.updateSettings,
)

export const updateSettings =
  (
    applicationId: number,
    settings: StringMapType,
    groupSettings: Array<{ group_id: string; title: string; settings: StringMapType }>,
  ): IThunkAction<Promise<void>> =>
  async (dispatch, getState, extra) => {
    const accountId = getCurrentAccountID(getState()).replace('fb', '')
    const body = {
      app_id: applicationId,
      account_id: accountId,
      settings,
      group_settings: groupSettings,
    }
    const result = await dispatch(
      updateSettingsAction({
        body,
        onError: () =>
          extra.alert(
            l.translate('Something went wrong. Please check settings and try to save again'),
            'danger',
          ),
      }),
    )
    if (updateSettingsAction.fulfilled.match(result)) {
      extra.alert(l.translate('Settings saved successfully!'), 'success')
    }
  }

const updateSlice = createSlice({
  name: 'application/page/settings/update',
  initialState: { fetching: false, status: 'unknown' },
  reducers: {
    resetUpdateStatus: (state) => {
      state.status = 'unknown'
    },
  },
  extraReducers: (builder) => {
    builder.addCase(updateSettingsAction.fulfilled, (state) => {
      state.status = 'success'
    })
    builder.addCase(updateSettingsAction.rejected, (state) => {
      state.status = 'error'
    })
    addFetchingCases(builder, updateSettingsAction)
  },
})

export const { reducer: updateReducer } = updateSlice
export const { resetUpdateStatus } = updateSlice.actions

export const getUpdateFetching = createSelector(
  (state: { applications: { settings: Pick<State, 'update'> } }) =>
    state.applications.settings.update,
  ({ fetching }) => fetching,
)

export const getUpdateStatus = createSelector(
  (state: { applications: { settings: Pick<State, 'update'> } }) =>
    state.applications.settings.update,
  ({ status }) => status,
)

interface FetchSettingsResult {
  appKey: string | null
  group_settings: IGroupSettingsItem[]
  hasRequest: boolean
  settings: ISettingsItem[]
}

export const fetchSettingsAction = makeGetActionCreator<FetchSettingsResult & Body>(
  'application/page/settings/fetch',
  installedApplications.endpoints.fetchSettingsForm,
)

export const fetchSettings =
  (applicationId: number) => async (dispatch: AppDispatch, getState: () => RootState) => {
    const accountId = getCurrentAccountID(getState()).replace('fb', '')
    return dispatch(
      fetchSettingsAction({
        params: {
          accountId,
          applicationId: String(applicationId),
        },
      }),
    )
  }

type DataState = State['data']

const initialState: DataState = {
  appKey: '',
  fetching: false,
  groupSettings: [] as IGroupSettingsItem[],
  hasRequest: false,
  settings: [] as ISettingsItem[],
}

const updateSettingsFieldReducer = (
  state: DataState,
  action: PayloadAction<{ name: string; value: string; groupId?: string }>,
) => {
  const { name, value, groupId } = action.payload
  const settings = groupId
    ? state.groupSettings.find((group) => group.group_id === groupId)?.settings
    : state.settings
  const settingsItem = (settings ?? []).find((item) => item.name === name)
  if (settingsItem) {
    settingsItem.value = value
  }
}

const deleteSettingsGroupReducer = (state: DataState) => {
  if (state.groupSettings.length === 1) {
    return
  }
  const groupIndex = state.groupSettings.findIndex(
    (group) => group.group_id === state.deleteGroupId,
  )
  if (groupIndex !== -1) {
    state.groupSettings.splice(groupIndex, 1)
  }
}

const setDeleteGroupIdReducer = (state: DataState, action: PayloadAction<string>) => {
  state.deleteGroupId = action.payload
}

const clearDeleteGroupIdReducer = (state: DataState) => {
  delete state.deleteGroupId
}

const updateSettingsGroupTitleReducer = (
  state: DataState,
  action: PayloadAction<{ groupId: string; title: string }>,
) => {
  const { groupId, title } = action.payload
  const settingsGroup = state.groupSettings.find((group) => group.group_id === groupId)
  if (settingsGroup) {
    settingsGroup.title = title
  }
}

const createGroupId = () => {
  return new Date().getTime() + '_'
}

const addSettingsGroupReducer = (state: DataState) => {
  const newSettingsGroup = {
    title: `Group ${state.groupSettings.length + 1}`,
    group_id: createGroupId(),
    settings: state.groupSettings[0].settings.map(({ name, title }) => ({
      name,
      title,
      value: '',
    })),
  }

  state.groupSettings.push(newSettingsGroup)
}

const addFetchSettingsCases = (builder: ActionReducerMapBuilder<DataState>) => {
  builder.addCase(fetchSettingsAction.pending, (state) => {
    state.settings = []
    state.groupSettings = []
    state.appKey = ''
    state.hasRequest = false
  })

  builder.addCase(fetchSettingsAction.fulfilled, (state, action) => {
    state.settings = action.payload.settings
    state.groupSettings = (action.payload.group_settings ?? []).map(
      ({ title, group_id, settings }) => ({
        title: title || 'Group 1',
        group_id: group_id ?? createGroupId(),
        settings,
      }),
    )
    state.appKey = action.payload.appKey ?? ''
    state.hasRequest = action.payload.hasRequest
  })
}

const dataSettingsSlice = createSlice({
  name: 'application/page/settings',
  initialState,
  reducers: {
    addSettingsGroup: addSettingsGroupReducer,
    clearDeleteGroupId: clearDeleteGroupIdReducer,
    deleteSettingsGroup: deleteSettingsGroupReducer,
    setDeleteGroupId: setDeleteGroupIdReducer,
    updateSettingsField: updateSettingsFieldReducer,
    updateSettingsGroupTitle: updateSettingsGroupTitleReducer,
  },
  extraReducers: (builder) => {
    addFetchSettingsCases(builder)
    addFetchingCases(builder, fetchSettingsAction)
  },
})

export const {
  addSettingsGroup,
  clearDeleteGroupId,
  deleteSettingsGroup,
  setDeleteGroupId,
  updateSettingsField,
  updateSettingsGroupTitle,
} = dataSettingsSlice.actions

export const { reducer: dataReducer } = dataSettingsSlice

export const getApplicationSettings = createSelector(
  (state: { applications: { settings: Pick<State, 'data'> } }) => state.applications.settings.data,
  (data) => data,
)

export const reducer = combineReducers({
  data: dataReducer,
  update: updateReducer,
})

export const LOCAL_STORAGE_KEY_NOTIFICATION_TAB_ID = 'MC_NOTIFICATION_TAB_ID'
export const LOCAL_STORAGE_KEY_WAS_LATEST_NOTIFICATION_READ = 'MC_WAS_LATEST_NOTIFICATION_READ'
export const LOCAL_STORAGE_KEY_TABS_STATE_MAP = 'MC_TABS_STATE_MAP'
export const LOCAL_STORAGE_KEY_PERMISSION_MODAL_SHOWN = 'MC_LIVECHAT_PERMISSION_MODAL_SHOWN'

export enum PageLifecycleState {
  HIDDEN = 'hidden',
  PASSIVE = 'passive',
  ACTIVE = 'active',
}

export enum DocumentVisibilityState {
  VISIBLE = 'visible',
  HIDDEN = 'hidden',
}

export enum WindowNotificationPermission {
  DEFAULT = 'default',
  GRANTED = 'granted',
  DENIED = 'denied',
}

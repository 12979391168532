import { IActionWithPayload } from 'common/core/interfaces/actions'
import * as atypes from 'common/userRoles/constants/UserRolesReduxActionTypes'

import { InvitationState, InvitationStates } from '../userRolesInterfaces'

const initialState: InvitationState = {
  invitationData: null,
}

export default function invitationReducer(
  state = initialState,
  action: IActionWithPayload,
): InvitationState {
  switch (action.type) {
    case atypes.INVITATION_FETCH_RESPONSE: {
      return { ...state, invitationData: action.data.data || null }
    }
    case atypes.INVITATION_ACCEPT_RESPONSE: {
      const { optin_payload, optin_required } = action.data
      if (!optin_required) {
        return state
      }
      return {
        ...state,
        invitationData: {
          ...state.invitationData,
          optin_payload,
          state: InvitationStates.ACCEPTED,
        },
      }
    }
  }

  return state
}

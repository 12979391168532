import React from 'react'
import cx from 'classnames'

import cm from './BulletListItem.module.css'

const BulletListItem = ({
  n,
  className,
  counterClassName,
  children,
}: {
  n: number
  className?: string
  counterClassName?: string
  children?: React.ReactNode
}) => {
  return (
    <div className={cx('d-flex align-start', className)}>
      <span
        className={cx(
          'm-r d-flex align-center justify-center text-heading-2 flex-shrink-0',
          cm.bullet,
          counterClassName,
        )}
      >
        {n}
      </span>
      <div className="m-t-xxs">{children}</div>
    </div>
  )
}

export default BulletListItem

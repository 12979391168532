import { l } from '@manychat/manyui'

import InitialNodesPresetType from 'common/builder/constants/InitialNodesPresetType'
import { AccountTargetPermissionsNames } from 'common/core/models/Account/constants'

import InstagramIcon from '../../../../../assets/img/posting/Instagram.svg'
import EmailIcon from '../../../../../assets/img/posting/Pro_Email.svg'
import MessengerIcon from '../../../../../assets/img/posting/Pro_Messenger.svg'
import SmsIcon from '../../../../../assets/img/posting/Pro_SMS.svg'
import TelegramIcon from '../../../../../assets/img/posting/Pro_Telegram.svg'
import WhatsAppIcon from '../../../../../assets/img/posting/Pro_WhatsApp.svg'

export enum Status {
  DRAFT = 'draft',
  SCHEDULED = 'scheduled',
  PUBLISHED = 'published',
  SENDING = 'sending',
  DELETED = 'deleted',
  CANCELLED = 'cancelled',
  AWAIT = 'await',
  NEED_ACTION = 'need_action',
}

export enum ListName {
  DRAFTS = 'drafts',
  SCHEDULED = 'scheduled',
  HISTORY = 'history',
}

export const ListNameStatusMap = {
  [ListName.DRAFTS]: [Status.DRAFT, Status.NEED_ACTION],
  [ListName.SCHEDULED]: [Status.SCHEDULED, Status.AWAIT],
  [ListName.HISTORY]: [Status.PUBLISHED, Status.SENDING, Status.CANCELLED],
}

export interface BroadcastType {
  img: string
  name: () => string
  info: () => string
  type: InitialNodesPresetType
  permissionsVariable: AccountTargetPermissionsNames
}

export const BroadcastTypeList: BroadcastType[] = [
  {
    img: MessengerIcon,
    name: l.makeTranslate('Messenger'),
    info: l.makeTranslate('Connect with your Messenger contacts for FREE'),
    type: InitialNodesPresetType.MESSENGER,
    permissionsVariable: AccountTargetPermissionsNames.BROADCAST_FB,
  },
  {
    img: WhatsAppIcon,
    name: l.makeTranslate('WhatsApp'),
    info: l.makeTranslate('Reach WhatsApp’s 2 billion users globally'),
    type: InitialNodesPresetType.WHATSAPP,
    permissionsVariable: AccountTargetPermissionsNames.BROADCAST_WHATSAPP,
  },
  {
    img: InstagramIcon,
    name: l.makeTranslate('Instagram'),
    info: l.makeTranslate('Connect with your Instagram contacts for FREE'),
    type: InitialNodesPresetType.INSTAGRAM,
    permissionsVariable: AccountTargetPermissionsNames.BROADCAST_INSTAGRAM,
  },
  {
    img: TelegramIcon,
    name: l.makeTranslate('Telegram'),
    info: l.makeTranslate('Connect with your Telegram contacts without limits'),
    type: InitialNodesPresetType.TELEGRAM,
    permissionsVariable: AccountTargetPermissionsNames.BROADCAST_TELEGRAM,
  },
  {
    img: SmsIcon,
    name: l.makeTranslate('SMS'),
    info: l.makeTranslate('Text anyone who shared their phone and opted for SMS'),
    type: InitialNodesPresetType.SMS,
    permissionsVariable: AccountTargetPermissionsNames.BROADCAST_SMS,
  },
  {
    img: EmailIcon,
    name: l.makeTranslate('Email'),
    info: l.makeTranslate('Reach people who opted for it and provided Email address'),
    type: InitialNodesPresetType.EMAIL_NEW,
    permissionsVariable: AccountTargetPermissionsNames.BROADCAST_EMAIL,
  },
]

export const BroadcastTablesContent = {
  [ListName.SCHEDULED]: {
    title: l.makeTranslate('Scheduled'),
    url: '/posting/scheduled',
    checkPermissions: false,
    skipEmpty: true,
  },
  [ListName.DRAFTS]: {
    title: l.makeTranslate('Drafts'),
    url: '/posting/drafts',
    checkPermissions: true,
    skipEmpty: true,
  },
  [ListName.HISTORY]: {
    title: l.makeTranslate('History'),
    url: '/posting/history',
    checkPermissions: false,
    skipEmpty: false,
  },
}

import React from 'react'

export const CloseIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      aria-hidden="true"
    >
      <path d="M5 15L15 5M5 5L15 15" stroke="#5A677D" strokeWidth="1.25" strokeLinecap="round" />
    </svg>
  )
}

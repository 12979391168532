import {
  IFlatOption,
  IOption,
  ParentOption,
} from 'components/AutocompleteElements/OptionsList/types'

export const flattenOptions = (
  options: IOption[],
  result: IFlatOption[] = [],
  parent?: ParentOption,
): IFlatOption[] => {
  for (let i = 0; i < options.length; i++) {
    const option = { ...options[i], localIndex: i, parent }
    const childOptions = option.options
    if (childOptions) {
      flattenOptions(childOptions, result, option)
    } else {
      result.push(option)
    }
  }
  return result
}

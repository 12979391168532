import get from 'lodash/get'

// must be relative inport @see widget/scripts/buildDefaults
import { ChannelType } from 'common/core/constants/ChannelType'

import { GrowthToolsTabOptions } from './../../../constants/GrowthToolsTabType'
import WidgetTypeConfig from './WidgetTypeConfig'

export const DEFAULT_VALUES = {
  colors: (propDesc) => {
    if (propDesc == null) {
      return {}
    }
    return Object.keys(propDesc.colors).reduce((res, colorName) => {
      res[colorName] = propDesc.colors[colorName].defaultValue
      return res
    }, {})
  },
  slider: () => {},
  measuredValue: (propDesc) => {
    if (propDesc == null || !propDesc.options || !propDesc.options[propDesc.defaultOption || 0]) {
      return [null, '']
    }
    const option = propDesc.options[propDesc.defaultOption || 0]
    return [option.units, `${option.defaultValue || 1}`]
  },
  textArea: () => [],
  textInput: () => '',
  numberInput: () => '',
  richTextInput: () => '',
  simpleBuilder: () => '',
  toggle: () => false,
  toggleWithLabel: () => false,
  imagePicker: () => {},
  checkbox: () => {},
  checkboxWithTooltip: () => {},
  radio: () => {},
  react: () => {},
  select: () => {},
  text: () => {},
  visibilityHeader: () => {},
  setup: () => {},
  userFieldPicker: () => '',
  fbMultipleCommentsReply: () => {},
  igMultipleCommentsReply: () => {},
  fbPostPicker: () => {},
  overlay: () => {},
  none: () => {},
  flowPicker: () => {},
  tip: () => {},
  hr: () => {},
  button: () => {},
  example: () => {},
  refPayloadInstructions: () => {},
  jsonAdsLearnMore: () => {},
  textAreaString: () => {},
  divider: () => {},
}

export default function getDefaultSettings(channel = ChannelType.FB) {
  const base = GrowthToolsTabOptions.reduce((prev, current) => {
    prev[current.value] = {}
    return prev
  }, {})
  const res = {}

  for (let typeName of Object.keys(WidgetTypeConfig)) {
    const type = JSON.parse(JSON.stringify(base))

    for (let tabName of Object.keys(type)) {
      const tabDesc = get(WidgetTypeConfig, `${typeName}.settings.${channel}.${tabName}`)

      if (tabDesc) {
        for (let propName of Object.keys(tabDesc)) {
          const propDesc = tabDesc[propName]

          type[tabName][propName] =
            propDesc.defaultValue || DEFAULT_VALUES[propDesc.display](propDesc)
        }
      }
    }
    res[typeName] = type
  }
  return res
}

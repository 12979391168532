import { IAccountBase } from 'common/core/interfaces/accountBase'
import { IUserBase } from 'common/core/interfaces/user'
import { UserRoles } from 'common/userRoles/constants/UserRoles'

export interface User {
  allowed_roles: UserRoles[]
  assigned_role: null | string
  avatar: string
  has_billing_access: boolean
  has_facebook_tasks_to_administer_page: boolean
  has_notify_email: boolean
  isThreadsLimitChanging?: boolean
  is_billing_access_modifiable: boolean
  is_creator: boolean
  is_inbox_user: boolean
  is_inbox_user_modifiable: boolean
  is_notified_by_email: boolean
  is_notified_by_messenger: boolean
  is_notified_by_sms: boolean
  is_notified_by_telegram: boolean
  is_opted_in: boolean
  is_removable: boolean
  is_role_updatable: boolean
  lc_auto_assign_enabled: boolean
  name: string
  threads_limit: null | typeof USER_THREADS_UNLIMITED | number
  user_id: number
  user_role: UserRoles
  user_type: string
}

export const USER_THREADS_UNLIMITED = -1
export const DEFAULT_USER_THREADS_LIMIT = 3

export interface CanCreateInviteLinkResponse {
  need_show_warning?: boolean
  can_create_user: boolean
}

export interface UsersState {
  inviteLink: null | string
  items: null | User[]
  isFetching: boolean
  canCreateInvite: CanCreateInviteLinkResponse
  currentInboxUser: {
    userId: null | User['user_id']
    userName: User['name']
    isFetchingInboxUserStatus: boolean
    isFetchingCheckboxStatus: boolean
    isAddPaidUserModalVisible: boolean
    isDeletePaidUserModalVisible: boolean
  }
}

export enum InvitationStates {
  ACCEPTED = 'accepted',
  EXISTS = 'user_exists',
  VALID = 'valid',
}

export interface InvitationState {
  invitationData: InvitationData | null
}

interface InvitationDataAccount extends IAccountBase {
  can_accept?: boolean
}

export interface InvitationData {
  account?: InvitationDataAccount
  user?: IUserBase
  state: InvitationStates
  optin_payload?: string
  role?: UserRoles
  current_role?: UserRoles
}

export interface AccessRequestsUser {
  user_id: number
  name: string
  avatar: string
  is_agency: boolean
}

export enum AccessRequestDecision {
  APPROVING,
  APPROVED,
  DECLINING,
  DECLINED,
}
export interface AccessRequestsState {
  ui: {
    processingUsers: Record<number, AccessRequestDecision>
  }
  users: null | AccessRequestsUser[]
}

import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import { BroadcastInstagramWizardState } from 'apps/posting/slices/interfaces'

const initialState = {
  isModalOpen: false,
  reasonId: null,
} as BroadcastInstagramWizardState

const broadcastInstagramWizardSlice = createSlice({
  name: 'brodcastInstagramWizard',
  initialState,
  reducers: {
    setReasonId(state, action: PayloadAction<number | null>) {
      state.reasonId = action.payload
    },
    resetState(state) {
      state.reasonId = null
      state.step = 1
      state.isModalOpen = false
    },
  },
})

export const { setReasonId, resetState } = broadcastInstagramWizardSlice.actions
export default broadcastInstagramWizardSlice.reducer

export const getStep = (state: RootState) => state.broadcastInstagramWizard.step

export const getInstagramWizardReasonId = (state: RootState) =>
  state.broadcastInstagramWizard.reasonId

export const FILTER_FIELDS_FETCH = 'FILTER_FIELDS_FETCH'
export const FILTER_FIELDS_FETCH_REQUEST = 'FILTER_FIELDS_FETCH_REQUEST'
export const FILTER_FIELDS_FETCH_RESPONSE = 'FILTER_FIELDS_FETCH_RESPONSE'
export const FILTER_FIELDS_FETCH_ERROR = 'FILTER_FIELDS_FETCH_ERROR'

export const FILTER_FIELD_VALUES_FETCH = 'FILTER_FIELD_VALUES_FETCH'
export const FILTER_FIELD_VALUES_FETCH_REQUEST = 'FILTER_FIELD_VALUES_FETCH_REQUEST'
export const FILTER_FIELD_VALUES_FETCH_RESPONSE = 'FILTER_FIELD_VALUES_FETCH_RESPONSE'
export const FILTER_FIELD_VALUES_FETCH_ERROR = 'FILTER_FIELD_VALUES_FETCH_ERROR'

//segments
export const SEGMENT_COUNTER_CHANGED = 'SEGMENT_COUNTER_CHANGED'

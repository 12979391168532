export const WEEKDAYS = [
  'monday',
  'tuesday',
  'wednesday',
  'thursday',
  'friday',
  'saturday',
  'sunday',
]

export interface IMinimalChangeEvent {
  target: {
    name?: string
    value: string
  }
}

export interface IMinimalChangeEventHandler {
  (event: IMinimalChangeEvent): void
}

export const CGT_REFRESH_PERMISSIONS_SUCCESS = 'cgt-refresh-permissions-success'

export const MANYCHAT_SUPPORT_URL = 'https://manychat.com/support'

export const MESSENGER_LIST_DOCUMENTATION_URL =
  'https://help.manychat.com/hc/en-us/articles/14281158573852-Messenger-List'

export const MANYCHAT_COMMUNITY_NEW_IDEA_URL = 'https://community.manychat.com/topic/new?type=idea'

import React from 'react'
import cx from 'classnames'
import { l } from '@manychat/manyui'

import { KnowledgeBaseLink } from 'common/core/components/SupportLinks'
import { analyticsService } from 'utils/services/analytics'

import cm from '../StaticNotifications.module.css'

export const WaCreditLineIssueNotification = () => {
  const handleAnalyticsEvent = () => {
    analyticsService.sendEvent('WA_CREDIT_LINE_ISSUE_BANNER.CLICKED', {
      source: 'Static notification',
    })
  }

  return (
    <>
      <span>
        {l.translate(
          "You cannot send WhatsApp Message Templates and Broadcasts because Manychat's Credit Line was not shared.",
        )}
      </span>
      <a
        href={KnowledgeBaseLink.WA_CREDIT_LINE}
        target="_blank"
        rel="noreferrer"
        onClick={handleAnalyticsEvent}
        className={cx(cm.more, 'm-l-xs')}
      >
        {l.translate('Learn more')}
      </a>
    </>
  )
}

import { createAction } from '@reduxjs/toolkit'
import omit from 'lodash/omit'
import { createAppAsyncThunk } from 'reduxTyped'
import { l } from '@manychat/manyui'

import { alert } from 'common/core'
import { FieldStatuses } from 'common/fields/constants/FieldType'
import UserFieldsTypes from 'common/fields/constants/UserFieldsTypes'
import { logFieldCreation, exportNewGlobalField } from 'common/fields/store/lib'
import { GlobalFieldSource, ExtraState, LegacyNewGlobalField } from 'common/fields/store/types'
import { handleCatch } from 'shared/api/lib/errors/handlers'
import { applyHandlerByGuard, reportUnhandledErrors } from 'shared/api/lib/errors/utils'
import { mapResponse } from 'shared/api/lib/mapResponse'
import { GlobalFieldsApi } from 'shared/api/requests/globalFields'
import { GlobalFieldsApiGuards } from 'shared/api/requests/globalFields/guards'
import { GlobalField } from 'shared/api/requests/globalFields/schemas'
import { createListSlice } from 'shared/lib/factory/redux/createListSlice'
import { createListOperations } from 'shared/lib/factory/redux/createListSlice/lib/createListOperations'
import { getClientId } from 'utils/clientId'
import { getQuery } from 'utils/router/tools'
import { anotherTabNotificationsListener } from 'utils/services/notificationsService'

const newEmptyField = {
  title: '',
  type: UserFieldsTypes.TEXT,
  description: '',
  value: '',
  folderPath: '/',
  allowedFieldTypes: [
    UserFieldsTypes.TEXT,
    UserFieldsTypes.NUMBER,
    UserFieldsTypes.DATE,
    UserFieldsTypes.DATETIME,
    UserFieldsTypes.BOOL,
    UserFieldsTypes.ARRAY,
  ],
}

const extraState: ExtraState = {
  search: {
    value: '',
  },
  createFieldModal: {
    isOpen: false,
    fieldData: newEmptyField,
    isCreating: false,
    lastCreatedField: null as GlobalField | null,
    source: null as GlobalFieldSource | null,
  },
  isSettingsTab: false,
}

const namespace = 'globalFields'

const updateSearchValue = createAction<string>(`${namespace}/updateSearchValue`)

const openCreateFieldModal = createAction<{
  source: GlobalFieldSource
  initialFieldData: Partial<LegacyNewGlobalField> | undefined
}>(`${namespace}/openCreateFieldModal`)
const closeCreateFieldModal = createAction(`${namespace}/closeCreateFieldModal`)
const changeFieldDraft = createAction<Partial<LegacyNewGlobalField>>(
  `${namespace}/changeFieldDraft`,
)
const updateCreatingState = createAction<boolean>(`${namespace}/updateCreatingState`)
const setLastCreatedField = createAction<GlobalField | null>(`${namespace}/setLastCreatedField`)
const setIsSettingsTab = createAction<boolean>(`${namespace}/setIsSettingsTab`)

const {
  reducer,
  actions: baseActions,
  selectors,
} = createListSlice({
  namespace: 'globalFields',
  storePath: 'globalFields',

  operations: createListOperations({
    getList: mapResponse(GlobalFieldsApi.getList, (response) => response.data.fields),
    getNextList: undefined,
    getPrevList: undefined,
    updateItem: mapResponse(GlobalFieldsApi.update, (response) => response.data.field),
    createItem: mapResponse(GlobalFieldsApi.create, (response) => response.data.field),
    deleteItem: mapResponse(GlobalFieldsApi.delete, (response) => response.data),
  }),

  getItemId: (globalField) => globalField.field_id,

  reducers: {},
  extraState: extraState,

  extraReducers: (builder) => {
    builder.addCase(updateSearchValue, (state, action) => {
      state.search.value = action.payload
    })

    builder.addCase(openCreateFieldModal, (state, action) => {
      state.createFieldModal.isOpen = true
      state.createFieldModal.source = action.payload.source
      state.createFieldModal.fieldData = { ...newEmptyField, ...action.payload.initialFieldData }
    })

    builder.addCase(closeCreateFieldModal, (state) => {
      state.createFieldModal = extraState.createFieldModal
    })

    builder.addCase(updateCreatingState, (state, action) => {
      state.createFieldModal.isCreating = action.payload
    })

    builder.addCase(changeFieldDraft, (state, action) => {
      const fieldDataWithoutTitle = omit(action.payload, ['title'])

      state.createFieldModal.fieldData = {
        ...state.createFieldModal.fieldData,
        title: action.payload.title ?? state.createFieldModal.fieldData.title,
        ...fieldDataWithoutTitle,
      }

      if ('type' in action.payload) {
        state.createFieldModal.fieldData.value = action.payload.value || ''
      }
    })

    builder.addCase(setLastCreatedField, (state, action) => {
      state.createFieldModal.lastCreatedField = action.payload
    })

    builder.addCase(setIsSettingsTab, (state, action) => {
      state.isSettingsTab = action.payload
    })
  },
})

export const GlobalFieldsSelectors = {
  ...selectors,
  getSearchValue: (state: RootState) => state.globalFields.search.value,
  getCreateFieldModal: (state: RootState) => state.globalFields.createFieldModal,
  getIsSettingsTab: (state: RootState) => state.globalFields.isSettingsTab,
}

const createFx = createAppAsyncThunk(`${namespace}/createFx`, async (_, { dispatch, getState }) => {
  const modalState = GlobalFieldsSelectors.getCreateFieldModal(getState())

  dispatch(updateCreatingState(true))

  try {
    const field = await dispatch(
      baseActions.createItemFx({
        payload: {
          body: exportNewGlobalField(modalState.fieldData),
          query: { client_id: getClientId() },
        },
      }),
    ).unwrap()

    dispatch(setLastCreatedField(field))
    dispatch(closeCreateFieldModal())

    logFieldCreation()

    alert(l.translate('Field Created!'), 'success')
  } catch (error) {
    console.log('error', error)
    handleCatch(error)
  }

  dispatch(updateCreatingState(false))
})

const archiveFx = createAppAsyncThunk(
  `${namespace}/archiveFx`,
  async (fieldId: number, { dispatch, getState }) => {
    try {
      await GlobalFieldsApi.archive({ body: { field_id: fieldId } })

      dispatch(
        baseActions.updateItem({
          id: fieldId,
          data: { status: FieldStatuses.ARCHIVED },
        }),
      )

      alert(l.translate('Field archived'), 'success')
    } catch (error) {
      handleCatch(error)
    }

    const searchValue = GlobalFieldsSelectors.getSearchValue(getState())

    if (searchValue) {
      dispatch(
        GlobalFieldsActions.getListFx({
          payload: { query: { active_only: false, query: searchValue } },
        }),
      )
    }
  },
)

const restoreFx = createAppAsyncThunk(
  `${namespace}/restoreFx`,
  async (fieldId: number, { dispatch }) => {
    try {
      await GlobalFieldsApi.restore({ body: { field_id: fieldId } })

      dispatch(baseActions.updateItem({ id: fieldId, data: { status: FieldStatuses.ACTIVE } }))

      alert(l.translate('Field restored'), 'success')
    } catch (error) {
      handleCatch(error)
    }
  },
)

const changeValueFx = createAppAsyncThunk(
  `${namespace}/changeValueFx`,
  async ({ fieldId, value }: { fieldId: number; value: GlobalField['value'] }, { dispatch }) => {
    try {
      await GlobalFieldsApi.changeValue({ body: { field_id: fieldId, value } })
      dispatch(baseActions.updateItem({ id: fieldId, data: { value } }))
    } catch (error) {
      handleCatch(error, (responseError) => {
        const unhandledErrors = applyHandlerByGuard(
          responseError.$errors,
          GlobalFieldsApiGuards.update.isUsedGlobalFieldNameValueError,
          (error) => alert(error.message, 'danger'),
        )

        reportUnhandledErrors(unhandledErrors, responseError.endpoint)
      })
    }
  },
)

const bulkArchiveFx = createAppAsyncThunk(
  `${namespace}/deleteBulkFx`,
  async (fieldsIds: number[]) => {
    try {
      const response = await GlobalFieldsApi.archiveBulk({ body: { ids: fieldsIds } })

      baseActions.bulkUpdateItems({
        rewrite: true,
        data: response.data.models.map((field) => ({
          id: field.field_id,
          item: field,
        })),
      })

      alert(l.translate('Fields archived'), 'success')
    } catch (error) {
      handleCatch(error)
    }
  },
)

const bulkDeleteFx = createAppAsyncThunk(
  `${namespace}/deleteBulkFx`,
  async (fieldsIds: number[]) => {
    try {
      await GlobalFieldsApi.deleteBulk({ body: { ids: fieldsIds } })

      alert(l.translate('Fields deleted'), 'success')
    } catch (error) {
      handleCatch(error)
    }
  },
)

const bulkRestoreFx = createAppAsyncThunk(
  `${namespace}/restoreBulkFx`,
  async (fieldsIds: number[]) => {
    try {
      await GlobalFieldsApi.restoreBulk({ body: { ids: fieldsIds } })

      alert(l.translate('Fields restored'), 'success')
    } catch (error) {
      handleCatch(error)
    }
  },
)

const moveFx = createAppAsyncThunk(
  `${namespace}/moveFx`,
  async ({ field, folderId }: { field: GlobalField; folderId: number }, { dispatch }) => {
    try {
      await GlobalFieldsApi.moveToFolder({
        body: {
          field_id: field.field_id,
          folder_id: folderId,
        },
      })

      alert(l.translate('Field moved to folder!'), 'success')

      dispatch(baseActions.deleteItem({ id: field.field_id }))
    } catch (error) {
      handleCatch(error)
    }
  },
)

const bulkMoveFx = createAppAsyncThunk(
  `${namespace}/bulkMoveFx`,
  async ({ fieldIds, folderId }: { fieldIds: number[]; folderId: number }, { dispatch }) => {
    try {
      await GlobalFieldsApi.moveToFolderBulk({
        body: {
          ids: fieldIds,
          folder_id: folderId,
        },
      })

      alert(l.translate('Fields moved to folder!'), 'success')

      dispatch(baseActions.bulkDeleteItems({ ids: fieldIds }))
    } catch (error) {
      handleCatch(error)
    }
  },
)

export const GlobalFieldsActions = {
  ...baseActions,
  createFx,
  archiveFx,
  restoreFx,
  changeValueFx,
  bulkArchiveFx,
  bulkDeleteFx,
  bulkRestoreFx,
  moveFx,
  bulkMoveFx,

  updateSearchValue,

  openCreateFieldModal,
  closeCreateFieldModal,
  setLastCreatedField,
  changeFieldDraft,

  setIsSettingsTab,
}

export type GlobalFieldsState = ReturnType<typeof reducer>

export const GlobalFieldsReducer = reducer

anotherTabNotificationsListener.on(
  'global_field_archived',
  (data: { model: GlobalField }, dispatch) => {
    dispatch(
      baseActions.updateItem({ id: data.model.field_id, data: { status: FieldStatuses.ARCHIVED } }),
    )
  },
)

anotherTabNotificationsListener.on(
  'global_field_restored',
  (data: { model: GlobalField }, dispatch) => {
    dispatch(
      baseActions.updateItem({ id: data.model.field_id, data: { status: FieldStatuses.ACTIVE } }),
    )
  },
)

anotherTabNotificationsListener.on(
  'global_field_deleted',
  (data: { model: GlobalField }, dispatch) => {
    dispatch(baseActions.deleteItem({ id: data.model.field_id }))
  },
)

anotherTabNotificationsListener.on(
  'global_field_created',
  (data: { model: GlobalField }, dispatch, getState) => {
    const state = getState() as RootState

    if (!GlobalFieldsSelectors.getIsSettingsTab(state)) {
      dispatch(baseActions.appendItem({ item: data.model }))
      return
    }

    const { path } = getQuery()

    const currentFolder = path ? Number(path.replace('/', '')) : 0

    if (currentFolder === data.model.folder_id) {
      dispatch(baseActions.appendItem({ item: data.model }))
    }
  },
)

anotherTabNotificationsListener.on(
  'global_field_updated',
  (data: { model: GlobalField }, dispatch) => {
    dispatch(baseActions.updateItem({ id: data.model.field_id, rewrite: true, data: data.model }))
  },
)

anotherTabNotificationsListener.on(
  'global_fields_deleted',
  (data: { models: GlobalField[] }, dispatch) => {
    dispatch(
      baseActions.bulkDeleteItems({ ids: data.models.map((globalField) => globalField.field_id) }),
    )
  },
)

anotherTabNotificationsListener.on(
  'global_fields_restored',
  (data: { models: GlobalField[] }, dispatch) => {
    dispatch(
      baseActions.bulkUpdateItems({
        rewrite: true,
        data: data.models.map((globalField) => ({
          id: globalField.field_id,
          item: globalField,
        })),
      }),
    )
  },
)

anotherTabNotificationsListener.on(
  'global_fields_archived',
  (data: { models: GlobalField[] }, dispatch) => {
    dispatch(
      baseActions.bulkUpdateItems({
        rewrite: true,
        data: data.models.map((globalField) => ({ id: globalField.field_id, item: globalField })),
      }),
    )
  },
)

anotherTabNotificationsListener.on(
  'global_fields_moved',
  (data: { model: GlobalField }, dispatch, getState) => {
    const state = getState() as RootState

    if (!GlobalFieldsSelectors.getIsSettingsTab(state)) {
      dispatch(baseActions.updateItem({ id: data.model.field_id, rewrite: true, data: data.model }))
      return
    }

    const { path } = getQuery()

    const currentFolder = path ? Number(path.replace('/', '')) : 0

    if (currentFolder === data.model.folder_id) {
      dispatch(baseActions.appendItem({ item: data.model }))
    } else {
      dispatch(baseActions.deleteItem({ id: data.model.field_id }))
    }
  },
)

anotherTabNotificationsListener.on(
  'global_fields_moved_bulk',
  (data: { models: GlobalField[] }, dispatch, getState) => {
    const state = getState() as RootState

    if (!GlobalFieldsSelectors.getIsSettingsTab(state)) {
      dispatch(
        baseActions.bulkUpdateItems({
          rewrite: true,
          data: [],
        }),
      )
      return
    }

    const { path } = getQuery()

    const currentFolder = path ? Number(path.replace('/', '')) : 0
    const targetFolderId = data.models[0].folder_id

    if (currentFolder === targetFolderId) {
      dispatch(
        baseActions.bulkAppendItems({
          items: data.models,
        }),
      )
    } else {
      dispatch(
        baseActions.bulkDeleteItems({
          ids: data.models.map((globalField) => globalField.field_id),
        }),
      )
    }
  },
)

import { Overwrite } from 'utility-types'

import { AiAgentAvailableChannels } from 'common/ai/constants/AiAgentAvailableChannels'
import { INode, AiAgentNodeType } from 'common/builder/nodes/nodeInterfaces'
import { isAiAgentNode } from 'common/builder/utils/isAiAgentNode'
import { ChannelType } from 'common/core/constants/ChannelType'

export const isAiAgentNodeWithChannel = (
  node: INode | null,
): node is Overwrite<AiAgentNodeType, { channel: NonNullable<AiAgentNodeType['channel']> }> =>
  isAiAgentNode(node) &&
  Boolean(node.channel) &&
  AiAgentAvailableChannels.includes(node.channel as ChannelType)

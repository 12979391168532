import React from 'react'
import cx from 'classnames'
import { Icon } from '@manychat/manyui'

import cm from './Select.module.css'

type OptionBase = React.OptionHTMLAttributes<HTMLOptionElement>

type Option =
  | (OptionBase & { value: string | number })
  | (OptionBase & { value?: undefined; key: string | number })

interface SelectProps extends React.SelectHTMLAttributes<HTMLSelectElement> {
  name?: string
  options?: Option[]
  style?: React.CSSProperties
  className?: string
  primary?: boolean
  invalid?: boolean
  selectClassName?: string
  hideArrow?: boolean
}

export const Select = React.forwardRef<HTMLSelectElement, SelectProps>(
  (props: SelectProps, ref) => {
    const {
      name,
      value,
      options = [],
      style,
      className,
      primary,
      invalid,
      selectClassName,
      hideArrow,
      ...other
    } = props

    return (
      <div className={cx(cm.wrapper, className)} style={style}>
        <select
          id={`${name}-input`}
          ref={ref}
          value={value || 'default'}
          className={cx(cm.select, 'rounded', selectClassName, {
            [cm.primary]: primary,
            [cm.invalid]: invalid,
            'text-secondary': !value,
          })}
          name={name}
          {...other}
        >
          {options.map((item) => (
            <option
              value={item.value}
              key={item.value ?? item.key}
              disabled={item.disabled}
              hidden={item.hidden}
            >
              {item.label}
            </option>
          ))}
        </select>
        {!hideArrow && <Icon.ArrowDropDown className={cm.arrow} size={16} />}
      </div>
    )
  },
)

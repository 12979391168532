import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import sortBy from 'lodash/sortBy'
import { createAppAsyncThunk } from 'reduxTyped'

import { shuffleVisibleQuestionItemsById } from 'apps/questionnaire/helpers'
import { QuestionnaireState } from 'apps/questionnaire/pages/PageQuestionnaire/interfaces'
import { createAnswers } from 'apps/questionnaire/pages/PageQuestionnaire/utils/createAnswers'
import { handleCatch } from 'shared/api/lib/errors/handlers'
import { questionnaireApi } from 'shared/api/requests/questionnaire'
import {
  AnswerItem,
  CommonQuestionType,
  Question,
  QuestionnaireType,
} from 'shared/api/requests/questionnaire/schemas'

const initialState: QuestionnaireState = {
  type: null,
  activeQuestionId: null,
  prevQuestionIds: [],
  questions: [],
  answers: [],
  subQuestions: [],
  loading: false,
  isSubmitting: false,
}

export const getQuestions = createAppAsyncThunk<Question[], QuestionnaireType>(
  'newQuestionnaire/questions',
  async (type, { dispatch }) => {
    try {
      const response = await questionnaireApi.getPageQuestions({
        query: { type },
      })
      const sortedQuestions = sortBy(response.data.questions, ['position'])

      dispatch(setActiveQuestionId(sortedQuestions[0].id))

      return shuffleVisibleQuestionItemsById(response.data.questions, [
        CommonQuestionType.COMPANY_NEEDS__BUSINESS_TYPE,
        CommonQuestionType.CLIENT_NEEDS__BUSINESS_TYPE,
        CommonQuestionType.PERSONAL_USE__BUSINESS_TYPE,
      ])
    } catch (error) {
      handleCatch(error)
      return []
    }
  },
)

export const sendAnswers = createAppAsyncThunk(
  'newQuestionnaire/sendAnswers',
  async (_arg, { getState }) => {
    const state = getState()

    const { answers, type } = state.newQuestionnaire

    if (!type) return

    try {
      await questionnaireApi.savePageAnswers({
        body: {
          answer: answers,
          type,
        },
      })

      return true
    } catch (error) {
      handleCatch(error)
      return false
    }
  },
)

export interface SetAnswersItem extends AnswerItem {
  next_question_id?: CommonQuestionType | null
}

export const setAnswers = createAppAsyncThunk(
  'newQuestionnaire/setAnswers',
  async (
    {
      items,
      type,
    }: {
      items: SetAnswersItem[]
      type: CommonQuestionType
    },
    { dispatch, getState },
  ) => {
    dispatch(addAnswers({ id: type, answers: items }))

    const state = getState()
    const activeQuestionId = getActiveQuestionId(state)
    const prevQuestionIds = getPrevQuestionIds(state)

    if (activeQuestionId) {
      dispatch(setPrevQuestionIds([...prevQuestionIds, activeQuestionId]))
    }

    if (items[0].next_question_id) {
      dispatch(setActiveQuestionId(items[0].next_question_id))
    }
  },
)

export const goBack = createAppAsyncThunk(
  'newQuestionnaire/goBack',
  async (_args, { dispatch, getState }) => {
    const state = getState()
    const activeQuestionId = getActiveQuestionId(state)
    const prevQuestionIds = getPrevQuestionIds(state)

    const newPrevQuestionIds = [...prevQuestionIds]
    const lastPrevQuestionId = newPrevQuestionIds.pop()

    if (lastPrevQuestionId && activeQuestionId) {
      dispatch(clearAnswers(activeQuestionId))
      dispatch(setPrevQuestionIds(newPrevQuestionIds))
      dispatch(setActiveQuestionId(lastPrevQuestionId))
    }
  },
)

const newQuestionnaireSlice = createSlice({
  name: 'newQuestionnaire',
  initialState,
  reducers: {
    setActiveQuestionId: (state, action: PayloadAction<CommonQuestionType | null>) => {
      state.activeQuestionId = action.payload
    },
    setPrevQuestionIds: (state, action: PayloadAction<CommonQuestionType[]>) => {
      state.prevQuestionIds = action.payload
    },
    addAnswers: (
      state,
      action: PayloadAction<{ id: CommonQuestionType; answers: AnswerItem[] }>,
    ) => {
      state.answers = state.answers.map((answer) => {
        if (answer.question_id === action.payload.id) {
          return {
            ...answer,
            answers: action.payload.answers,
          }
        }
        return answer
      })
    },
    clearAnswers: (state, action: PayloadAction<CommonQuestionType>) => {
      state.answers = state.answers.map((answer) => {
        if (answer.question_id === action.payload) {
          return {
            ...answer,
            answers: [],
          }
        }
        return answer
      })
    },
    setInitialState: () => initialState,
  },
  extraReducers: (builder) => {
    builder.addCase(getQuestions.pending, (state, action) => {
      state.loading = true
      state.type = action.meta.arg
    })
    builder.addCase(getQuestions.fulfilled, (state, action) => {
      state.loading = false
      state.questions = action.payload
      state.answers = createAnswers(action.payload)
    })
    builder.addCase(sendAnswers.pending, (state) => {
      state.isSubmitting = true
    })
    builder.addCase(sendAnswers.fulfilled, (state) => {
      state.isSubmitting = false
    })
  },
})

export const {
  setActiveQuestionId,
  setPrevQuestionIds,
  addAnswers,
  clearAnswers,
  setInitialState,
} = newQuestionnaireSlice.actions

export const getActiveQuestionId = (state: RootState) => state.newQuestionnaire.activeQuestionId

export const getPrevQuestionIds = (state: RootState) => state.newQuestionnaire.prevQuestionIds

export const getQuestionnaireLoading = (state: RootState) => state.newQuestionnaire.loading

export const getQuestionnaireAnswers = (state: RootState) => state.newQuestionnaire.answers

export const getActiveQuestionAnswers = (state: RootState) => {
  const activeQuestionId = getActiveQuestionId(state)
  const answers = getQuestionnaireAnswers(state)
  return answers.find((answer) => answer.question_id === activeQuestionId)
}

export const getQuestionnaireType = (state: RootState) => state.newQuestionnaire.type

export const getQuestionnaireCommonQuestionById = (
  state: RootState,
  type: CommonQuestionType | null,
) => state.newQuestionnaire.questions.find((question) => question.id === type)

export const getQuestionnaireSubmitting = (state: RootState) => state.newQuestionnaire.isSubmitting

export default newQuestionnaireSlice.reducer

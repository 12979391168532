export function getCurrentTime() {
  const now = new Date()
  return now
    .toLocaleTimeString('en-US', {
      hour: 'numeric',
      minute: '2-digit',
      hour12: true,
    })
    .split(' ')[0]
}

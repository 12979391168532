export enum WebSocketEvents {
  INSTAGRAM_MESSAGE_DELETED = 'lc_ig_message_deleted',
  INSTAGRAM_MESSAGE_REACTED = 'lc_ig_message_reacted',
  INSTAGRAM_MESSAGE_UNREACTED = 'lc_ig_message_unreacted',
  FB_RN_TOPIC_OPTED_IN = 'fb_rn_topic_opted_in',
  FACEBOOK_MAIN_MENU_UNINSTALLATION_FAILED = 'facebook_main_menu_uninstallation_failed',

  NOTIFICATION_REASON_CREATED = 'one_time_notify_reason_created',
  NOTIFICATION_REASON_DELETED = 'one_time_notify_reason_deleted',
  NOTIFICATION_REASON_ARCHIVED = 'one_time_notify_reason_archived',
  NOTIFICATION_REASON_RESTORED = 'one_time_notify_reason_restored',

  INVOICE_NEXT_ACTION_REQUIRED = 'invoice_next_action_required',
  SUBSCRIPTION_UPDATED = 'subscription_updated',

  WA_CART_UPDATED = 'wa_cart_updated',
  WA_HISTORY_CONTACT_IMPORT_PROGRESS = 'wa_history_contact_import_progress',
  WA_CHANNEL_DISCONNECT_COMPLETED = 'whatsapp_channel_disconnect_completed',

  LC_MESSAGE_FAILED = 'lc_message_failed',

  ABILITIES_UPDATED = 'abilities_updated',
  FILE_CONVERSION_SUCCEEDED = 'file_conversion_succeeded',
  FILE_CONVERSION_FAILED = 'file_conversion_failed',

  CAPI_SETTINGS_UPDATED = 'capi_settings_updated',
  KEYWORD_CREATED = 'keyword_created',
  KEYWORD_UPDATED = 'keyword_updated',
  KEYWORD_DELETED = 'keyword_deleted',
  AI_INTENT_CREATED = 'ai_intent_created',
  AI_INTENT_UPDATED = 'ai_intent_updated',
  AI_INTENT_DELETED = 'ai_intent_deleted',
}

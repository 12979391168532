export enum FieldStatus {
  ACTIVE = 'active',
  ARCHIVED = 'archived',
  DELETED = 'deleted',
}

export enum FieldType {
  ARRAY = 'array',
  COLLECTION = 'collection',
  BOOLEAN = 'boolean',
  DATE = 'date',
  DATETIME = 'datetime',
  ENUM = 'enum',
  NUMBER = 'number',
  SET = 'set',
  TEXT = 'text',
  TAG = 'tag',
  // SYSTEM
  SYSTEM_BOOLEAN = 'system_boolean',
  SYSTEM_TEXT = 'system_text',
  SYSTEM_TAG = 'system_tag',
  SYSTEM_NUMBER = 'system_number',
  SYSTEM_DATE = 'system_date',
  SYSTEM_DATETIME = 'system_datetime',
  SYSTEM_SWITCH = 'system_switch',
  SYSTEM_CURRENT_DATETIME = 'system_current_datetime',
  SYSTEM_ENUM = 'system_enum',
}

export enum FieldGroupId {
  RECOMMENDED = 'recommended',
  USER = 'cuf',
  BOT = 'gaf',
  SYSTEM = 'suf',
  STATIC = 'static',
  INTEGRATION = 'integration',
  CONTEXT = 'lcf',
  FORMAT_DATE = 'format_date',
  TAG = 'tag',
}

export enum FieldSegmentType {
  CUF = 'cuf',
  GAF = 'gaf',
  SUF = 'suf',
  TAG = 'tag',
  SEQUENCE = 'sequence',
  WIDGET = 'widget',
  SMART_SEGMENT = 'smart_segment',
  ADS_GROWTH_TOOL = 'ads_growth_tool',
  SYSTEM_CURRENT_DATETIME = 'system_current_datetime',
  ONE_TIME_NOTIFICATION = 'one_time_notification',
}

import React, { useEffect, useRef } from 'react'
import cx from 'classnames'
import clipboard from 'clipboard-js'
import { l, Modal } from '@manychat/manyui'

import cm from 'apps/telegram/components/TelegramQrModal/TelegramQrModal.module.css'
import { alert } from 'common/core'
import BarCodeGenerator from 'components/BarCodeGenerator'
import { analyticsService } from 'utils/services/analytics'

interface ITelegramQrModalProps {
  isVisible: boolean
  onClose: () => void
  url: string | null
  origin: string
  title?: string
}

export const TelegramQrModal = ({
  isVisible,
  onClose,
  url,
  origin,
  title,
}: ITelegramQrModalProps) => {
  const isFirstRender = useRef(true)
  useEffect(() => {
    if (isFirstRender.current) {
      isFirstRender.current = false
      return
    }

    const currentEvent = isVisible ? 'OPEN' : 'CLOSE'
    analyticsService.sendEvent(`TELEGRAM_QR_MODAL.${currentEvent}`, { origin })
  }, [isVisible, origin])

  if (url === null) {
    return null
  }

  const handleCopyButtonClick = async (event: React.MouseEvent<HTMLAnchorElement>) => {
    event.preventDefault()

    analyticsService.sendEvent('TELEGRAM_QR_MODAL.BTN_COPY.CLICK', { origin })

    await clipboard.copy(url)
    alert(l.translate('Copied'), 'success')
  }

  return (
    <Modal
      title={title ?? l.translate('Is Telegram opened?')}
      buttons={[
        {
          label: l.translate('Close'),
          onClick: onClose,
        },
      ]}
      open={isVisible}
      onRequestClose={() => onClose()}
    >
      <div className="p-y-md p-x-xs">
        <p className="m-b-md">
          {l.translate(
            "If you don’t have Telegram app on this device, please scan QR-code with camera on your phone with installed Telegram app. When Telegram chat is opened, click 'Start'. ",
          )}
        </p>

        <div
          className={cx(cm.qrWrapper, 'rounded', 'p-y-xl', 'm-b-md', 'd-flex', 'justify-center')}
        >
          <div className={cx(cm.qrFrame, 'p-y-lg', 'bg-white')}>
            <BarCodeGenerator
              id="telegram-opt-in-qr-code"
              value={url}
              fgColor="#161930"
              size={136}
              hasFrame={false}
            />
          </div>
        </div>

        <a onClick={handleCopyButtonClick} className="text-primary">
          {l.translate('Copy bot link')}
        </a>
      </div>
    </Modal>
  )
}

import { combineReducers } from 'redux'

import { reducer as ukraineReliefProgram } from '../components/UkraineReliefProgramModal/ukraineReliefProgramSlice'
import wallet from '../wallet/walletSlices'

import { billingInfoSlice } from './billingInfo'
import { billingProSlice } from './billingPro'
import { billingStateSlice } from './billingState'
import { blockedProModalSlice } from './blockedProModal'
import { cancelAddOnModalSlice } from './cancelAddOnModal'
import { cancelSubscriptionModalSlice } from './cancelSubscriptionModal'
import { chargesSlice } from './charges'
import { disableProductModalSlice } from './disableProductModal'
import { emailStatsSlice } from './emailStats'
import { invoicesSlice } from './invoices'
import { nextActionModalSlice } from './nextActionModal'
import { paymentCardRenewalModalSlice } from './paymentCardRenewalModal'
import { smsStatsSlice } from './smsStats'
import { stripeCheckoutSlice } from './stripeCheckout'
import { taxFormSlice } from './taxForm'
import { tierLimitModalSlice } from './tierLimitModal'
import { tiersSlice } from './tiers'
import { upgradeRequestSlice } from './upgradeRequest'

export default combineReducers({
  invoices: invoicesSlice.reducer,
  charges: chargesSlice.reducer,
  [upgradeRequestSlice.name]: upgradeRequestSlice.reducer,
  wallet,
  [stripeCheckoutSlice.name]: stripeCheckoutSlice.reducer,
  ukraineReliefProgram,
  [disableProductModalSlice.name]: disableProductModalSlice.reducer,
  [nextActionModalSlice.name]: nextActionModalSlice.reducer,
  [blockedProModalSlice.name]: blockedProModalSlice.reducer,
  [cancelSubscriptionModalSlice.name]: cancelSubscriptionModalSlice.reducer,
  [tiersSlice.name]: tiersSlice.reducer,
  [billingInfoSlice.name]: billingInfoSlice.reducer,
  [cancelAddOnModalSlice.name]: cancelAddOnModalSlice.reducer,
  [smsStatsSlice.name]: smsStatsSlice.reducer,
  [emailStatsSlice.name]: emailStatsSlice.reducer,
  [billingProSlice.name]: billingProSlice.reducer,
  [paymentCardRenewalModalSlice.name]: paymentCardRenewalModalSlice.reducer,
  [billingStateSlice.name]: billingStateSlice.reducer,
  [tierLimitModalSlice.name]: tierLimitModalSlice.reducer,
  [taxFormSlice.name]: taxFormSlice.reducer,
})

import get from 'lodash/get'

import { makeLimitedListReducer } from 'utils/factory'

import * as atypes from '../SettingsReduxActionTypes'

const listReducer = makeLimitedListReducer('orders')

const initialState = {
  ordersInfoByStatus: null,
  successedOrdersInfo: null,
}

export default function ordersReducer(state = initialState, action) {
  state = listReducer(state, action)
  switch (action.type) {
    case atypes.ORDERS_FETCH_RESPONSE: {
      const ordersInfoByStatus = get(action, 'data.orders_info_by_status', null)
      const successedOrdersInfo = get(action, 'data.successed_orders_info_by_currency', null)
      return { ...state, ordersInfoByStatus, successedOrdersInfo }
    }
  }
  return state
}

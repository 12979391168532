import { IBusinessDomain, IBusinessEmail, IEmailState } from 'apps/email/emailInterfaces'
import { FetchStatus } from 'apps/sms/smsInterfaces'
import { IActionWithPayload } from 'common/core/interfaces/actions'

import * as atypes from '../emailReduxActionTypes'

export const initialState: IEmailState = {
  businessEmails: [],
  businessEmailsFetchStatus: FetchStatus.NOT_FETCHED,
  connectBusinessEmailStep: null,
  connectBusinessEmailId: null,
}

export default (state: IEmailState = initialState, action: IActionWithPayload): IEmailState => {
  switch (action.type) {
    case atypes.EMAIL_SETTINGS_GET_BUSINESS_EMAILS_REQUEST: {
      return { ...state, businessEmailsFetchStatus: FetchStatus.FETCHING }
    }
    case atypes.EMAIL_SETTINGS_GET_BUSINESS_EMAILS_ERROR: {
      return { ...state, businessEmailsFetchStatus: FetchStatus.NOT_FETCHED }
    }
    case atypes.EMAIL_SETTINGS_GET_BUSINESS_EMAILS_RESPONSE: {
      const businessEmails = action.data.emails

      return { ...state, businessEmails, businessEmailsFetchStatus: FetchStatus.FETCHED }
    }
    case atypes.EMAIL_SETTINGS_REMOVE_BUSINESS_EMAIL_RESPONSE: {
      const removedBusinessEmail = state.businessEmails.find((e) => e.email === action.email)
      let businessEmails = state.businessEmails.filter((e) => e.email !== action.email)
      if (removedBusinessEmail?.is_default) {
        businessEmails = setPreferredEmail(businessEmails, null)
      }

      return { ...state, businessEmails }
    }

    case atypes.EMAIL_SETTINGS_UPDATE_BUSINESS_EMAIL_RESPONSE:
    case atypes.EMAIL_SETTINGS_ADD_BUSINESS_EMAIL_RESPONSE: {
      const businessEmail = action.data?.email
      let businessEmails = [
        ...state.businessEmails.filter((e) => e.email !== businessEmail.email),
        businessEmail,
      ]
      if (businessEmail?.is_default) {
        businessEmails = setPreferredEmail(businessEmails, businessEmail?.id)
      }

      return {
        ...state,
        businessEmails,
      }
    }

    case atypes.EMAIL_SETTINGS_VERIFY_BUSINESS_DOMAIN_RESPONSE: {
      const domain = action.data.domain as IBusinessDomain
      const businessEmails = state.businessEmails.map((e) =>
        e.domain.id === domain.id ? { ...e, domain } : e,
      )
      return { ...state, businessEmails }
    }

    case atypes.SET_CONNECT_BUSINESS_EMAIL_STEP: {
      return {
        ...state,
        connectBusinessEmailStep: action.step,
        connectBusinessEmailId: action.emailId,
      }
    }
  }

  return state
}

const setPreferredEmail = (
  businessEmails: IBusinessEmail[],
  businessEmailId: number | null,
): IBusinessEmail[] => {
  return businessEmails.map((businessEmail) => {
    const isPreferred = businessEmail.id === businessEmailId
    return { ...businessEmail, is_default: isPreferred }
  })
}

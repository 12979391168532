import { l } from '@manychat/manyui'

import * as blockHelpers from 'common/builder/blocks/blockHelpers'
import { IBlock } from 'common/builder/blocks/blockInterfaces'
import { BlockType } from 'common/builder/constants/BlockType'
import { NodeType } from 'common/builder/constants/NodeType'
import BlockModel from 'common/builder/models/Block'
import BaseNodeConfig from 'common/builder/nodes/BaseNodeConfig'
import { INodeBase, ITelegramNode } from 'common/builder/nodes/nodeInterfaces'

class TelegramNodeConfig extends BaseNodeConfig<ITelegramNode> {
  nodeType = NodeType.TELEGRAM

  isPro = false

  getCaption = () => l.translate('Send Message')
  getToolbarCaption = () => l.translate('Telegram')
  getCaptionWithCount = (n: number) =>
    l.translate('{n, plural, one {# Telegram Message} other {# Telegram Messages}}', { n })
  getNextStepCaption = () => l.translate('Telegram')

  icon = 'TelegramChannel'
  color = '#8492A6'
  headerColor = '#DCEEFF'
  stepsWhiteList = [
    NodeType.TELEGRAM,
    NodeType.ACTION_GROUP,
    NodeType.CONDITION,
    NodeType.SPLIT,
    NodeType.SMART_DELAY,
    NodeType.GOTO,
  ]

  ALLOWED_BLOCK_TYPES = [
    BlockType.TEXT,
    BlockType.ATTACHMENT,
    BlockType.FORM_QUESTION,
    BlockType.DELAY,
    BlockType.DYNAMIC,
    BlockType.TELEGRAM_KEYBOARD,
  ]

  ALLOWED_BLOCK_MAX_COUNT = 101

  getDefaults = (): Omit<ITelegramNode, keyof INodeBase> => ({
    targetId: null,
    reasonId: null,
    type: 'default',
  })

  getInitialBlocks = (node: ITelegramNode) => {
    return [
      { type: BlockType.TEXT as const },
      {
        type: BlockType.TELEGRAM_KEYBOARD as const,
        id: blockHelpers.createQRBlockId(node.id),
      },
    ]
  }

  isEmpty = ({ node, blocks }: { node: ITelegramNode; blocks: IBlock[] }) => {
    if (node.targetId) {
      return false
    }
    return this.areBlocksEmpty(blocks)
  }

  _isEmptyBlock = (block: IBlock) => {
    return [BlockType.TEXT].includes(block.type) && BlockModel.isEmpty(block)
  }
}

export default new TelegramNodeConfig()

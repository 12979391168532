import * as liveChatActions from 'apps/chat/constants/LiveChatReduxActionTypes'
import { alert } from 'common/core'
import { request } from 'utils/api/mcApi/request'
import { linkDomain } from 'utils/url'

import { translateErrorInfo } from './translateErrorInfo'
import { addSystemParams } from './urlUtils'

const actionKeys = [
  'type',
  '$fetch',
  '$responseType',
  '$success',
  '$error',
  '$import',
  '$requestId',
  'data',
  'error',
]

const createFetchMiddleware = (config) => {
  config = Object.assign({ options: {} }, config)

  return (store) => (next) => (action) => {
    if (action == null || action.$fetch == null) {
      return next(action)
    }
    return performFetch(store, config, action)
  }
}

async function performFetch({ dispatch, getState }, config, action) {
  const { type, $fetch, $responseType = 'json', $requestId: requestId } = action
  const { $success, $error, $import } = action
  const extra = {}
  const $requestId = requestId || Date.now()

  for (let key of Object.keys(action)) {
    if (actionKeys.indexOf(key) >= 0) {
      continue
    }
    extra[key] = action[key]
  }
  let url, options
  if (Array.isArray($fetch)) {
    ;[url, options] = $fetch
  } else {
    url = $fetch
  }

  const { url: completedUrl, headers } = addSystemParams(linkDomain(url), getState, options)

  const fetchOptions = { ...config.options, ...options, responseType: $responseType }
  const hasHeaders = Boolean(fetchOptions.headers)
  fetchOptions.headers = {
    ...(hasHeaders ? fetchOptions.headers : {}),
    ...headers,
  }

  if (type) {
    dispatch({ ...extra, type: `${type}_REQUEST`, $requestId })
  }

  try {
    let data = await request(completedUrl, fetchOptions)

    // Import
    if (typeof $import === 'function') {
      try {
        data = $import(data, getState())
      } catch (err) {
        console.error(`Error while $import operation for ${type} action:`, err)
      }
    }

    // Success handler
    if (typeof $success === 'function') {
      setTimeout(() => {
        $success(data)
      })
    }

    try {
      if (type) {
        dispatch({ ...extra, type: `${type}_RESPONSE`, $requestId, data })
      }
    } catch (err) {
      console.error(`Error while dispatching ${type}_RESPONSE action:`, err)
    }

    return data
  } catch (error) {
    const errorInfo = error.response || error.message

    if (typeof $error === 'function') {
      setTimeout(() => {
        const errorData = error?.data?.go_pro ? { error: errorInfo, go_pro: true } : errorInfo
        $error(errorData)
      })
    } else {
      alert(translateErrorInfo(errorInfo), 'danger')
    }

    try {
      if (type) {
        dispatch({ ...extra, type: `${type}_ERROR`, $requestId, error: errorInfo })
        if (type === 'ATTACHMENT_UPLOAD') {
          dispatch({ type: liveChatActions.RESET_THREAD_LOADING })
        }
      }
    } catch (err) {
      console.error('Error while dispatching fetch ERROR action:', err)
    }

    throw error
  }
}

export default createFetchMiddleware

import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import { ChannelStatus } from 'common/core/interfaces/channelStatuses'
import { TelegramChannel } from 'shared/api/requests/telegram/schemas'

const initialState: TelegramChannel = {
  status: ChannelStatus.NONE,
  is_channel_enabled: false,
  bot_name: '',
  bot_username: '',
  is_channel_connected: false,
  ts_channel_connected: null,
}

const telegramChannelSlice = createSlice({
  name: 'telegramChannel',
  initialState,
  reducers: {
    setEnabled: (state, action: PayloadAction<boolean>) => {
      state.is_channel_enabled = action.payload
      return state
    },
  },
})

export const { reducer } = telegramChannelSlice
export const { setEnabled: setTelegramEnabled } = telegramChannelSlice.actions

import { createContext, useContext } from 'react'

import { BlockType } from '../constants'
import { MissingBlockError } from '../errors'
import { Id, SerializedBlock, SerializedView } from '../types'

const defaultView: SerializedView = {
  root: '1',
  blocks: {
    '1': {
      id: '1',
      props: {
        text: 'Hello world',
      },
      type: BlockType.TEXT,
    },
  },
}

export const SerializedViewContext = createContext<SerializedView>(defaultView)

export function useSerializedView(): SerializedView

export function useSerializedView(blockId: Id): SerializedBlock

export function useSerializedView(blockId?: Id) {
  const view = useContext(SerializedViewContext)

  if (blockId) {
    const serializedBlock = view.blocks[blockId]
    if (!serializedBlock) {
      throw new MissingBlockError(blockId)
    }

    return serializedBlock
  }

  return view
}

import React from 'react'
import cx from 'classnames'
import { l, Modal } from '@manychat/manyui'

import { KnowledgeBaseLink } from 'common/core/components/SupportLinks'

import cm from './InformationWABAModal.module.css'

const InformationWABAModal = ({
  openModal,
  isLC,
  onRequestClose,
}: {
  openModal: boolean
  isLC?: boolean
  onRequestClose: () => void
}) => {
  const text = l.translate(
    'If your Business needs to send Broadcasts, respond to contacts in LiveChat or send automated Follow-ups outside of 24-hour messaging window you need to start the verification. To do so go to your Facebook Business Manager → Settings → Security Centre and click “Start Verification”.',
  )

  return (
    <Modal
      title={l.translate('Unverified Experience')}
      width={600}
      open={openModal}
      onRequestClose={onRequestClose}
    >
      <p className="p-a">
        {isLC
          ? l.translate(
              'While your WhatsApp Business Account is not <a class="text-primary" href="{href}" target="_blank" rel="noopener noreferrer">verified by Facebook</a>, you can\'t send Message Templates to your Contacts.',
              {
                href: KnowledgeBaseLink.CHECK_WHATSAPP_READY,
              },
            )
          : l.translate(
              'While your WhatsApp Business Account is not <a class="text-primary" href="{href}" target="_blank" rel="noopener noreferrer">verified by Facebook</a>, you can',
              {
                href: KnowledgeBaseLink.CHECK_WHATSAPP_READY,
              },
            )}

        {isLC && <span className="d-block m-t-xs">{text}</span>}

        {isLC ? (
          <span className="d-block m-t-xs">{l.translate('Unverified mode allows you to')}:</span>
        ) : (
          ':'
        )}

        <ul className={cx(cm.list, 'p-l-md')}>
          <li>
            {l.translate(
              'Respond to unlimited contact-initiated conversations (within 24-hour messaging windows).',
            )}
          </li>
          <li>
            {l.translate('Register up to two (2) phone numbers per one WhatsApp Business Account.')}
          </li>
        </ul>

        <span>{!isLC && text}</span>
      </p>
    </Modal>
  )
}

export default InformationWABAModal

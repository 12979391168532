import React from 'react'
import { useNavigate } from 'react-router-dom'
import { l } from '@manychat/manyui'

import { linkURL } from 'utils/url'

import cm from './common.module.css'

export const WABannedNotification = () => {
  const navigate = useNavigate()

  const handleGoToWASettingsPage = () => navigate(linkURL('/settings#whatsApp/account'))

  return (
    <div>
      {l.translate('Your WhatsApp account has been banned by Meta. WhatsApp channel is disabled.')}{' '}
      <a onClick={handleGoToWASettingsPage} className={cm.link}>
        {l.translate('Learn More')}
      </a>
    </div>
  )
}

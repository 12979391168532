import BaseBlockConfig from 'common/builder/blocks/BaseBlockConfig'
import { IBlockBase, IWaCatalogBlock } from 'common/builder/blocks/blockInterfaces'
import { BlockType } from 'common/builder/constants/BlockType'

class WaCatalogMessageBlockConfig extends BaseBlockConfig {
  blockType = BlockType.WA_CATALOG_MESSAGE

  getDefaults = (): Omit<IWaCatalogBlock, keyof IBlockBase> => ({
    content: {
      body: '',
    },
  })
}

export default new WaCatalogMessageBlockConfig()

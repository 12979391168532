import React, { ReactElement } from 'react'
import cx from 'classnames'
import { Popover, l, Icon } from '@manychat/manyui'

import { HelpRefreshPermissionButton } from 'common/core/components/HelpRefreshPermissionButton/HelpRefreshPermissionButton'
import { PrivacyPolicyURL, TosURL } from 'utils/assets'
import { analyticsService } from 'utils/services/analytics'

import { KnowledgeBaseLink } from '../SupportLinks'

import cm from './HelpPopover.module.css'

interface IHelpPopoverProps {
  open: boolean
  anchor?: HTMLElement | ReactElement
  pageId: string
  onRequestClose: () => void
}

const HelpPopover = ({ open, anchor, pageId, onRequestClose }: IHelpPopoverProps) => {
  const handleRefreshPermissionsClick = () => {
    onRequestClose()
    analyticsService.sendEvent('HELP.REFRESH_PERMISSIONS.CLICK')
  }

  const linkClickHandlers = {
    helpCenter: () => analyticsService.sendEvent('HELP.HELP_CENTER.CLICK'),
    changeLog: () => analyticsService.sendEvent('HELP.CHANGELOG.CLICK'),
    joinCommunity: () => analyticsService.sendEvent('HELP.COMMUNITY.CLICK'),
    blog: () => analyticsService.sendEvent('HELP.BLOG.CLICK'),
    hireAnAgency: () => analyticsService.sendEvent('HELP.AGENCY.CLICK'),
    termsOfService: () => analyticsService.sendEvent('HELP.TERMS.CLICK'),
    privacyPolicy: () => analyticsService.sendEvent('HELP.PRIVACY_POLICY.CLICK'),
    privacySettings: async () => {
      await window?.__ucCmp?.showSecondLayer()
      analyticsService.sendEvent('HELP.PRIVACY_SETTINGS.CLICK')
    },
  }

  return (
    <Popover
      open={open && anchor != null}
      anchor={anchor}
      at="right"
      arrow
      onRequestClose={onRequestClose}
      useLayerForClickAway={false}
    >
      <div className={cx(cm.wrapper, 'rounded')}>
        <div className="p-x-md p-b-xs p-t text-subheading">{l.translate('Help')}</div>
        <div className="p-b-xs">
          <a
            className="row middle p-x-md p-y-xs bg-hover"
            target="_blank"
            href={KnowledgeBaseLink.LOGIN}
            onClick={linkClickHandlers.helpCenter}
            rel="noreferrer"
          >
            <Icon.Help size={24} className="m-r" />
            <span>{l.translate('Help Center')}</span>
          </a>
          <a
            className="row middle p-x-md p-y-xs bg-hover"
            target="_blank"
            href="https://community.manychat.com/"
            onClick={linkClickHandlers.joinCommunity}
            rel="noreferrer"
          >
            <Icon.Community size={24} className="m-r" />
            <span>{l.translate('Ask the Community')}</span>
          </a>
          <a
            className="row middle p-x-md p-y-xs bg-hover"
            target="_blank"
            href="https://www.manychat.com/agency/listing"
            onClick={linkClickHandlers.hireAnAgency}
            rel="noreferrer"
          >
            <Icon.TemplateTextMedia size={24} className="m-r" />
            <span>{l.translate('Hire an Agency')}</span>
          </a>
          <a
            className="row middle p-x-md p-y-xs bg-hover"
            target="_blank"
            href="https://community.manychat.com/ideas?sort=votes"
            onClick={linkClickHandlers.joinCommunity}
            rel="noreferrer"
          >
            <Icon.Creator size={24} className="m-r" />
            <span>{l.translate('Share an Idea')}</span>
          </a>
          <a
            className="row middle p-x-md p-y-xs bg-hover"
            target="_blank"
            href="https://community.manychat.com/product-updates"
            onClick={linkClickHandlers.changeLog}
            rel="noreferrer"
          >
            <Icon.PublicChangelog size={24} className="m-r" />
            <span>{l.translate('Manychat Changelog')}</span>
          </a>
          <a
            className="row middle p-x-md p-y-xs bg-hover"
            target="_blank"
            href="https://manychat.com/blog/"
            onClick={linkClickHandlers.blog}
            rel="noreferrer"
          >
            <Icon.LiveChat size={24} className="m-r" />
            <span>{l.translate('Manychat Blog')}</span>
          </a>
        </div>

        {pageId && <HelpRefreshPermissionButton onClick={handleRefreshPermissionsClick} />}
        <div className="p-y-xs">
          <a
            className="d-flex p-x-md p-y-sm bg-hover text-sm"
            target="_blank"
            href={TosURL}
            onClick={linkClickHandlers.termsOfService}
            rel="noreferrer"
          >
            {l.translate('Terms of service')}
          </a>
          <a
            className="d-flex p-x-md p-y-sm bg-hover text-sm"
            target="_blank"
            href={PrivacyPolicyURL}
            onClick={linkClickHandlers.privacyPolicy}
            rel="noreferrer"
          >
            {l.translate('Privacy policy')}
          </a>
          <span
            className="d-flex p-x-md p-y-sm bg-hover text-sm c-pointer"
            onClick={linkClickHandlers.privacySettings}
          >
            {l.translate('Privacy settings')}
          </span>
        </div>
      </div>
    </Popover>
  )
}

export default HelpPopover

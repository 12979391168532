import React from 'react'
import cx from 'classnames'
import { useAppSelector } from 'reduxTyped'
import { l } from '@manychat/manyui'

import { billing, UpgradeSource } from 'common/billing'
import { getUpgradeCaption } from 'common/billing/selectors/billingSelectors'
import { getBlockedReasonsMessage } from 'common/core/selectors/appSelectors'
import { useDidMountEffect } from 'utils/commonHooks'
import { analyticsService } from 'utils/services/analytics'

import cm from '../StaticNotifications.module.css'

export const BlockedAccountNotification = () => {
  const upgradeCaption = useAppSelector(getUpgradeCaption)
  const blockedReasonsMessage = useAppSelector(getBlockedReasonsMessage)

  useDidMountEffect(() => {
    analyticsService.sendEvent('NOTIFICATION_BAR.BLOCKED_PRO.SHOWN')
  })

  const handleClick = () => {
    analyticsService.sendEvent('BLOCKED_BANNER.UPGRADE.CLICKED')
    billing.requestUpgrade({ source: UpgradeSource.CONTACTS_LIMIT_ALERT })
  }

  const description = l.translate(
    `You've reached your limit of {blockedReasonsMessage} on your Free account.`,
    { blockedReasonsMessage },
  )

  return (
    <>
      <span>{description}</span>
      <a target="_blank" className={cx(cm.more, 'm-l-xs')} onClick={handleClick}>
        {upgradeCaption}
      </a>
    </>
  )
}

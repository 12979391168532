import { QuestionBlockReplyType } from 'common/builder/blocks/blockConstants'
import BackendQuestionMessageAnswerMethod from 'common/builder/constants/BackendQuestionMessageAnswerMethod'
import BackendQuestionMessageAnswerType from 'common/builder/constants/BackendQuestionMessageAnswerType'

export const parseQuestionReplyType = (answer_type, answer_method) => {
  switch (answer_type) {
    case BackendQuestionMessageAnswerType.TEXT:
      {
        switch (answer_method) {
          case BackendQuestionMessageAnswerMethod.FREE_INPUT:
            return [QuestionBlockReplyType.FREE_TEXT, false]
          case BackendQuestionMessageAnswerMethod.PREDEFINED:
            return [QuestionBlockReplyType.MULTIPLE_CHOICE, false]
          case BackendQuestionMessageAnswerMethod.BOTH:
            return [QuestionBlockReplyType.MULTIPLE_CHOICE, true]
        }
      }
      break
    case BackendQuestionMessageAnswerType.NUMBER:
      {
        switch (answer_method) {
          case BackendQuestionMessageAnswerMethod.FREE_INPUT:
            return [QuestionBlockReplyType.NUMBER, false]
          case BackendQuestionMessageAnswerMethod.PREDEFINED:
            return [QuestionBlockReplyType.MULTIPLE_CHOICE, false]
          case BackendQuestionMessageAnswerMethod.BOTH:
            return [QuestionBlockReplyType.MULTIPLE_CHOICE, true]
        }
      }
      break
    case BackendQuestionMessageAnswerType.FIRST_NAME:
      return [QuestionBlockReplyType.FIRST_NAME, false]
    case BackendQuestionMessageAnswerType.LAST_NAME:
      return [QuestionBlockReplyType.LAST_NAME, false]
    case BackendQuestionMessageAnswerType.EMAIL:
      return [QuestionBlockReplyType.EMAIL, false]
    case BackendQuestionMessageAnswerType.PHONE:
      return [QuestionBlockReplyType.PHONE, false]
    case BackendQuestionMessageAnswerType.PHONE_FOR_SMS:
      //backward compatibility for removed type PHONE_FOR_SMS
      return [QuestionBlockReplyType.PHONE, false]
    case BackendQuestionMessageAnswerType.URL:
      return [QuestionBlockReplyType.URL, false]
    case BackendQuestionMessageAnswerType.FILE:
      return [QuestionBlockReplyType.FILE, false]
    case BackendQuestionMessageAnswerType.IMAGE:
      return [QuestionBlockReplyType.IMAGE, false]
    case BackendQuestionMessageAnswerType.LOCATION:
      return [QuestionBlockReplyType.LOCATION, false]
    case BackendQuestionMessageAnswerType.MC_LOCATION:
      return [QuestionBlockReplyType.MC_LOCATION, false]
    case BackendQuestionMessageAnswerType.DATE_TIME:
      return [QuestionBlockReplyType.DATE_TIME, false]
    case BackendQuestionMessageAnswerType.DATE:
      return [QuestionBlockReplyType.DATE, false]
  }

  return [QuestionBlockReplyType.FREE_TEXT, false]
}

export const exportQuestionReplyType = (replyType, allowFreeInput) => {
  switch (replyType) {
    case QuestionBlockReplyType.FREE_TEXT:
      return [BackendQuestionMessageAnswerType.TEXT, BackendQuestionMessageAnswerMethod.FREE_INPUT]
    case QuestionBlockReplyType.MULTIPLE_CHOICE:
      if (allowFreeInput) {
        return [BackendQuestionMessageAnswerType.TEXT, BackendQuestionMessageAnswerMethod.BOTH]
      }
      return [BackendQuestionMessageAnswerType.TEXT, BackendQuestionMessageAnswerMethod.PREDEFINED]
    case QuestionBlockReplyType.NUMBER:
      return [
        BackendQuestionMessageAnswerType.NUMBER,
        BackendQuestionMessageAnswerMethod.FREE_INPUT,
      ]
    case QuestionBlockReplyType.FIRST_NAME:
      return [
        BackendQuestionMessageAnswerType.FIRST_NAME,
        BackendQuestionMessageAnswerMethod.FREE_INPUT,
      ]
    case QuestionBlockReplyType.LAST_NAME:
      return [
        BackendQuestionMessageAnswerType.LAST_NAME,
        BackendQuestionMessageAnswerMethod.FREE_INPUT,
      ]
    case QuestionBlockReplyType.EMAIL:
      return [BackendQuestionMessageAnswerType.EMAIL, BackendQuestionMessageAnswerMethod.FREE_INPUT]
    case QuestionBlockReplyType.PHONE:
      return [BackendQuestionMessageAnswerType.PHONE, BackendQuestionMessageAnswerMethod.FREE_INPUT]
    case QuestionBlockReplyType.DEPRECATED_PHONE_FOR_SMS:
      //backward compatibility for removed type PHONE_FOR_SMS
      return [BackendQuestionMessageAnswerType.PHONE, BackendQuestionMessageAnswerMethod.FREE_INPUT]
    case QuestionBlockReplyType.URL:
      return [BackendQuestionMessageAnswerType.URL, BackendQuestionMessageAnswerMethod.FREE_INPUT]
    case QuestionBlockReplyType.FILE:
      return [BackendQuestionMessageAnswerType.FILE, BackendQuestionMessageAnswerMethod.FREE_INPUT]
    case QuestionBlockReplyType.IMAGE:
      return [BackendQuestionMessageAnswerType.IMAGE, BackendQuestionMessageAnswerMethod.FREE_INPUT]
    case QuestionBlockReplyType.LOCATION:
      return [
        BackendQuestionMessageAnswerType.LOCATION,
        BackendQuestionMessageAnswerMethod.FREE_INPUT,
      ]
    case QuestionBlockReplyType.DATE_TIME:
      return [BackendQuestionMessageAnswerType.DATE_TIME, BackendQuestionMessageAnswerMethod.API]
    case QuestionBlockReplyType.DATE:
      return [BackendQuestionMessageAnswerType.DATE, BackendQuestionMessageAnswerMethod.API]
    case QuestionBlockReplyType.MC_LOCATION:
      return [BackendQuestionMessageAnswerType.MC_LOCATION, BackendQuestionMessageAnswerMethod.API]
  }

  return [BackendQuestionMessageAnswerType.TEXT, BackendQuestionMessageAnswerMethod.FREE_INPUT]
}

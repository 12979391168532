import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import { IgUsersValuePeriod } from 'apps/dashboard/constants/IgUsersValueConstants'
import { IgUsersValueState } from 'apps/dashboard/interfaces/igUsersValueState'
import { HomeTabApi } from 'shared/api/requests/homeTab'
import { IgUsersValueSchemaType } from 'shared/api/requests/homeTab/schemas'
import { createAsyncAction } from 'shared/lib/redux'

export const InitialState: IgUsersValueState = {
  data: null,
  isLoading: false,
  isLoaded: false,
  activePeriod: IgUsersValuePeriod.DAY,
}

const namespace = 'igUsersValue'

const fetchIgUsersValueData = createAsyncAction<IgUsersValueSchemaType, void>(
  `${namespace}/fetchIgUsersValueData`,
  async () => {
    const response = await HomeTabApi.getIgUsersValueData()

    return response.data.data
  },
)

export const { actions, reducer: IgUsersValueReducer } = createSlice({
  name: namespace,
  initialState: InitialState,
  reducers: {
    resetState: () => InitialState,

    setActivePeriod: (state, action: PayloadAction<IgUsersValuePeriod>) => {
      state.activePeriod = action.payload
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchIgUsersValueData.pending, (state) => {
      state.isLoading = true
    })
    builder.addCase(fetchIgUsersValueData.fulfilled, (state, action) => {
      state.isLoading = false
      state.isLoaded = true
      state.data = action.payload

      state.activePeriod = action.payload?.default_active_period ?? IgUsersValuePeriod.DAY
    })
    builder.addCase(fetchIgUsersValueData.rejected, (state) => {
      state.isLoading = false
      state.isLoaded = false
    })
  },
})

export const IgUsersValueActions = {
  ...actions,
  fetchIgUsersValueData,
}

import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import * as UserFieldsActions from 'common/fields/actions/userFieldsActions'
import * as FiltersActions from 'common/filter/actions/filtersActions'

class FieldsController extends React.Component {
  componentDidMount() {
    const { scenario, filtersActions, userFieldsActions } = this.props

    filtersActions.fetchFilterFields({ scenario })
    userFieldsActions.fetchUserFields()
  }

  onCreate = (e) => {
    this.props.userFieldsActions.openCreateFieldModal({ title: e.target.value })
  }

  render() {
    const { fetching, loader } = this.props
    const { static: staticFields, custom: customFields, system: systemFields } = this.props.fields
    const hasFields = staticFields !== null && systemFields !== null && customFields !== null

    if (hasFields) {
      return fetching && loader
        ? loader
        : this.props.children({ staticFields, customFields, systemFields, onCreate: this.onCreate })
    }

    return null
  }
}

export default connect(
  (state) => ({ fields: state.filter.fields }),
  (dispatch) => ({
    filtersActions: bindActionCreators(FiltersActions, dispatch),
    userFieldsActions: bindActionCreators(UserFieldsActions, dispatch),
  }),
)(FieldsController)

import { navigatePollyfill } from 'utils/router/tools'
import OnboardingBase from 'utils/services/newOnboardingService/core/OnboardingBase'
import StepController from 'utils/services/newOnboardingService/core/StepController'
import StepSystem from 'utils/services/newOnboardingService/core/steps/StepSystem'
import OnboardingId from 'utils/services/newOnboardingService/OnboardingId'
import { waitUntilElementMount } from 'utils/services/newOnboardingService/view/Pointer/pointerHelpers'
import { linkURL } from 'utils/url'

// eslint-disable-next-line @typescript-eslint/consistent-type-definitions
export type SelfServeStoryReactionStepsMap = {
  STEP_1: StepSystem
  STEP_2: StepSystem
  STEP_3: StepSystem
  STEP_4: StepSystem
}

class NewCMSStoryReaction extends OnboardingBase<SelfServeStoryReactionStepsMap> {
  constructor() {
    super(OnboardingId.NEW_CMS_SELF_SERVE_STORY_REACTION_TOUR, () => {
      return new StepController<SelfServeStoryReactionStepsMap>('STEP_1', {
        STEP_1: () =>
          new StepSystem(() => this.setStep('STEP_2'), {
            onRun: () => {
              navigatePollyfill(linkURL(`/dashboard`))
            },
          }),
        STEP_2: () => new StepSystem(() => this.setStep('STEP_3'), {}),
        STEP_3: () =>
          new StepSystem(() => this.setStep('STEP_4'), {
            onRun: () => {
              const context = this.getContext()
              waitUntilElementMount(`[data-onboarding-id="folder-${context?.folderId}"]`, (el) => {
                el.scrollIntoView({ block: 'center' })
              })
            },
          }),
        STEP_4: () =>
          new StepSystem(() => this.complete(), {
            onRun: () => {
              waitUntilElementMount('[data-onboarding-id="fs-object-flow"]', (el) => {
                el.scrollIntoView({ block: 'center' })
              })
            },
          }),
      })
    })
  }
}

export default NewCMSStoryReaction

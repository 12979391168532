import { ChannelType } from 'common/core/constants/ChannelType'
import { analyticsService } from 'utils/services/analytics'

export const AiIntentsWizardAnalytics = {
  logExitPollSubmitted: (data: { comment: string }) =>
    analyticsService.sendEvent('AI_INTENTS_WIZARD.EXIT_POLL.SUBMIT', data),
  logTryToSetLive: (data: { intentsQuantity: number; channel: ChannelType }) =>
    analyticsService.sendEvent('AI_INTENTS_WIZARD.TRY_TO_SET_LIVE', data),
  logSetLiveSuccessfully: (data: { flow_ns: string[] }) =>
    analyticsService.sendEvent('AI_INTENTS_WIZARD.SET_LIVE_SUCCESSFULLY', data),
  logSetLiveFailed: () => analyticsService.sendEvent('AI_INTENTS_WIZARD.SET_LIVE_ERROR'),
  logOpenInFolder: () => analyticsService.sendEvent('AI_INTENTS_WIZARD.OPEN_MODAL_IN_FOLDER'),
}

import { useEffect, MutableRefObject } from 'react'
import { useReferredValue } from '@manychat/manyui'

import { useUsercentrics } from 'utils/commonHooks'
import localStorage from 'utils/localStorage'
import { USERCENTRICS_MC_NAME } from 'utils/services/analytics/AnalyticsService'
import {
  SCRIPT_ID,
  LEGACY_CLIENT_ID_REGEX,
  STORED_CLIENT_ID_REGEX,
  REPLACED_VALUE,
  getWhoAmiUrl,
  getPixelPathUrl,
} from 'utils/services/visitor/utils'

export const useVisitorJs = () => {
  const observerRef: MutableRefObject<MutationObserver | null> = useReferredValue(null)
  const { usercentricsServicesInfo } = useUsercentrics(USERCENTRICS_MC_NAME)
  const isVisitorJsProvided = usercentricsServicesInfo[USERCENTRICS_MC_NAME]?.status ?? false

  const startLegacyVisitorLogic = () => {
    const lsStoredClientId = localStorage.getItem('mchtClientId') || ''
    const cookieStoredClientId = document.cookie.replace(STORED_CLIENT_ID_REGEX, REPLACED_VALUE)
    const cookieLegacyClientId = document.cookie.replace(LEGACY_CLIENT_ID_REGEX, REPLACED_VALUE)
    const rootDomain = document.location.hostname.split('.').slice(-2).join('.')
    const cookieAppendix = ';domain=.' + rootDomain + ';Max-Age=' + 3600 * 24 * 365

    if (lsStoredClientId && !cookieStoredClientId) {
      document.cookie = 'mchtClientId=' + lsStoredClientId + cookieAppendix
    }

    if (!lsStoredClientId && cookieStoredClientId) {
      localStorage.setItem('mchtClientId', cookieStoredClientId)
    }

    let clientId = cookieStoredClientId || lsStoredClientId

    const url = getPixelPathUrl({ clientId, cookieLegacyClientId })

    const img = document.createElement('img')
    if (!clientId) {
      img.onload = () => {
        if (clientId) return // Because it can be saved already

        const xhr = new XMLHttpRequest()
        xhr.addEventListener('load', function () {
          clientId = this.responseText
          document.cookie = 'mchtClientId=' + clientId + cookieAppendix
          localStorage.setItem('mchtClientId', clientId)
        })

        const whoAmiURI = getWhoAmiUrl({ cookieLegacyClientId })
        xhr.open('GET', whoAmiURI.toString(), true)
        xhr.withCredentials = true
        xhr.send(null)
      }
    }
    img.src = url.toString()

    let currentPath = location.href
    const watcher = () => {
      if (location.href !== currentPath) {
        currentPath = location.href
        const cookieLegacyClientId = document.cookie.replace(LEGACY_CLIENT_ID_REGEX, REPLACED_VALUE)
        const modifiedURL = getPixelPathUrl({ url, clientId, cookieLegacyClientId, currentPath })
        img.src = modifiedURL.toString()
      }
    }

    return { watcher }
  }

  useEffect(() => {
    if (!isVisitorJsProvided || document.getElementById(SCRIPT_ID)) {
      return
    }

    const { watcher } = startLegacyVisitorLogic()

    window.addEventListener('hashchange', watcher)
    const observer = new MutationObserver(watcher)
    observer.observe(document, {
      attributes: true,
      childList: true,
      subtree: true,
      characterData: true,
    })

    observerRef.current = observer

    return () => {
      window.removeEventListener('hashchange', watcher)

      if (observerRef.current) {
        observerRef.current.disconnect()
        observerRef.current = null
      }
    }
  }, [isVisitorJsProvided, observerRef])
}

import { ChannelType } from 'common/core/constants/ChannelType'
import { getInfoAboutService } from 'utils/getInfoAboutService'
import { USERCENTRICS_HOTJAR_NAME, DEBUG } from 'utils/services/hotjar/constants'
import { loadHotjar } from 'utils/services/hotjar/helpers'

export class HotjarService {
  public isProvideHotjar: boolean
  public isInitializedHotjar: boolean

  constructor() {
    const { isProvideHotjar } = this.getServicesProvidedStatus()
    this.isProvideHotjar = isProvideHotjar

    const isHotjar = Boolean(window.hj)
    this.isInitializedHotjar = isHotjar

    if (isProvideHotjar && !isHotjar) {
      this.loadScript()
    }
  }

  private handleOnLoad = () => {
    this.isInitializedHotjar = Boolean(window.hj)
  }

  private loadScript = () => loadHotjar(this.handleOnLoad)

  private getServicesProvidedStatus = () => {
    const usercentricsServicesInfo = getInfoAboutService(USERCENTRICS_HOTJAR_NAME)
    const isProvideHotjar = usercentricsServicesInfo[USERCENTRICS_HOTJAR_NAME]?.status ?? false
    return { isProvideHotjar }
  }

  public hotjarEvent = (event: string, channel?: ChannelType) => {
    if (!this.isProvideHotjar) {
      return
    }

    const hj = window.hj
    DEBUG('hotjarEvent', event, channel, hj)
    if (!hj) {
      return
    }

    if (event) {
      if (channel) {
        const channelPrefixMap = {
          [ChannelType.FB]: 'fb',
          [ChannelType.INSTAGRAM]: 'ig',
          [ChannelType.WHATSAPP]: 'wa',
          [ChannelType.EMAIL]: 'em',
          [ChannelType.GUEST]: 'guest',
          [ChannelType.SMS]: 'sms',
          [ChannelType.TELEGRAM]: 'tg',
          [ChannelType.TIKTOK]: 'tt',
        }
        hj('event', `${channelPrefixMap[channel]}_${event}`)
      }
      hj('event', event)
    }
  }

  public hotjarTrigger = (triggerId: string | null, tags?: string[]) => {
    if (!this.isProvideHotjar) {
      return
    }

    const hj = window.hj
    DEBUG('hotjarTrigger', triggerId, tags, hj)
    if (!hj) {
      return
    }

    if (triggerId) {
      hj('trigger', triggerId)
    }

    if (Array.isArray(tags) && tags.length) {
      hj('tagRecording', tags)
    }
  }

  public hotjarIdentify = (userId: number, data: SafeUnknownObject) => {
    if (!this.isProvideHotjar) {
      return
    }

    const hj = window.hj
    if (!hj) {
      return
    }

    hj('identify', userId, data)
  }

  public refreshPermissions = () => {
    const { isProvideHotjar } = this.getServicesProvidedStatus()
    this.isProvideHotjar = isProvideHotjar
    this.isInitializedHotjar = Boolean(window.hj)

    if (isProvideHotjar && !this.isInitializedHotjar) {
      this.loadScript()
    }
  }
}

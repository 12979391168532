import React from 'react'

type Label = string | JSX.Element

const withProBadge = (label: Label): Label => {
  return (
    <>
      {label}
      <span className="m-l-xs pro-label"></span>
    </>
  )
}

export default withProBadge

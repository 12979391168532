import React from 'react'
import cx from 'classnames'

import AccountModel from 'common/core/models/Account'
import { AccountStatus } from 'common/core/models/Account/constants'
import ProLabelAvatar from 'components/ProLabelAvatar'
import { ProLabelAvatarProps } from 'components/ProLabelAvatar/type'
import { getProfileImageStubURL } from 'utils/assets'

import cm from './AccountAvatar.module.css'

interface AccountAvatarProps {
  accountStatus?: AccountStatus
  avatarColor?: string
  avatarUrl?: string | null
  className?: string
  labelClassName?: string
  size: number
  title?: string
}

export const AccountAvatar = ({
  accountStatus,
  avatarColor,
  avatarUrl,
  className,
  labelClassName,
  size,
  title,
}: AccountAvatarProps) => {
  const otherProps: ProLabelAvatarProps = {
    label: '',
  }

  if (accountStatus) {
    const isPro = AccountModel.isProOrTrialStatus(accountStatus)
    const isTrial = AccountModel.isTrialStatus(accountStatus)

    otherProps[isPro ? 'pro' : 'free'] = true
    if (isTrial) {
      otherProps.label = 'TRIAL'
    }
  }

  return (
    <ProLabelAvatar
      size={size}
      url={avatarUrl || getProfileImageStubURL()}
      backgroundColor={avatarColor}
      title={title}
      className={cx(cm.wrapper, className)}
      labelClassName={labelClassName}
      {...otherProps}
    />
  )
}

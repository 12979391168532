import * as atypes from '../flowPlayerReduxActionTypes'

const initialState = { data: null, fetching: false, error: null }

export function flowPlayerReducer(state = initialState, action) {
  switch (action.type) {
    case atypes.FLOWPLAYER_FLOW_DATA_FETCH_REQUEST: {
      return { ...state, fetching: true, error: null }
    }
    case atypes.FLOWPLAYER_FLOW_DATA_FETCH_RESPONSE: {
      const { data } = action
      return { ...state, data, fetching: false, error: null }
    }
    case atypes.FLOWPLAYER_FLOW_DATA_FETCH_ERROR: {
      const { error } = action
      return { ...state, data: null, fetching: false, error }
    }
  }

  return state
}

import { api } from 'shared/api'

import { LiveChatStatisticsSchemas } from './schemas'

export const LiveChatStatisticsApi = {
  getAdmins: api.account.createGet({
    url: '/im/admins',
    schemas: LiveChatStatisticsSchemas.getAdmins,
  }),
  getTotalConversationsChartStats: api.account.createPost({
    url: '/liveChatStatistics/conversationsDynamics',
    schemas: LiveChatStatisticsSchemas.getTotalConversationsChartStats,
  }),
  getConversationsByTeamMembersChartStats: api.account.createPost({
    url: '/liveChatStatistics/totalConversationsBreakdown',
    schemas: LiveChatStatisticsSchemas.getConversationsByTeamMembersChartStats,
  }),
  getAverageResponseTime: api.account.createPost({
    url: '/liveChatStatistics/averageResponseTime',
    schemas: LiveChatStatisticsSchemas.getAverageResponseTime,
  }),
  getAverageClosureTime: api.account.createPost({
    url: '/liveChatStatistics/averageClosureTime',
    schemas: LiveChatStatisticsSchemas.getAverageClosureTime,
  }),
  getResponseRate: api.account.createPost({
    url: '/liveChatStatistics/responseRate',
    schemas: LiveChatStatisticsSchemas.getResponseRate,
  }),
}

import { makeLimitedListReducer, makeCurrentItemReducer, setCurrentItem } from 'utils/factory'

import * as atypes from '../answersReduxActionTypes'

const listReducer = makeLimitedListReducer('forms')
const currentItemReducer = makeCurrentItemReducer('forms')

export default function formsReducer(state, action) {
  state = listReducer(state, action)
  state = currentItemReducer(state, action)

  switch (action.type) {
    case atypes.FORMS_CURRENT_FETCH_RESPONSE: {
      const item = {
        ...action.data.item,
        answers: action.data.answers,
        questions: action.data.questions,
      }
      return setCurrentItem(state, item)
    }
  }

  return state
}

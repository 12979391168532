import { Icon, l } from '@manychat/manyui'

import * as chatGPTActions from 'common/actions/integrations/ChatGPT/actions/chatGPTActions'
import {
  IntegrationType,
  IntegrationNameByType,
} from 'common/actions/integrations/common/constants/IntegrationType'
import { AiIntegrationDescription } from 'common/actions/integrations/common/lib/copies'
import {
  createIntegration,
  createIntegrationAction,
} from 'common/actions/integrations/common/model/helpers'
import { AccountTargetPermissionsNames } from 'common/core/models/Account/constants'

export const ChatGPTActionTypes = {
  REQUEST_TO_CHATGPT: 'chatgpt_request',
  REQUEST_TO_CHATGPT_CHAT: 'chatgpt_chat_request',
}

export const ChatGPTConfig = createIntegration({
  type: IntegrationType.CHATGPT,
  name: IntegrationNameByType[IntegrationType.CHATGPT],
  icon: Icon.ChatGpt,
  permissionsVariable: AccountTargetPermissionsNames.CHATGPT_INTEGRATION,
  description: () => AiIntegrationDescription({ integrationName: 'ChatGPT' }),
  reduxActions: chatGPTActions,
  actions: [
    createIntegrationAction({
      type: ChatGPTActionTypes.REQUEST_TO_CHATGPT,
      description: l.translate('Make request to ChatGPT'),
      name: l.translate('Request (legacy)'),
    }),
    createIntegrationAction({
      type: ChatGPTActionTypes.REQUEST_TO_CHATGPT_CHAT,
      description: l.translate('Make request to ChatGPT'),
      name: l.translate('Request'),
    }),
  ],
})

// Custom User Fields
export const USERFIELDS_CREATE = 'USERFIELDS_CREATE'
export const USERFIELDS_CREATE_PENDING = 'USERFIELDS_CREATE_PENDING'
export const USERFIELDS_CREATE_SUCCESS = 'USERFIELDS_CREATE_SUCCESS'
export const USERFIELDS_CREATE_REJECT = 'USERFIELDS_CREATE_REJECT'

export const USERFIELDS_OPEN_CREATE_MODAL = 'USERFIELDS_OPEN_CREATE_MODAL'
export const USERFIELDS_CLOSE_CREATE_MODAL = 'USERFIELDS_CLOSE_CREATE_MODAL'
export const USERFIELDS_CREATE_MODAL_CHANGE = 'USERFIELDS_CREATE_MODAL_CHANGE'

export const USERFIELDS_ARCHIVE_FIELD_NOTIFICATION = 'USERFIELDS_ARCHIVE_FIELD_NOTIFICATION'
export const USERFIELDS_RESTORE_FIELD_NOTIFICATION = 'USERFIELDS_RESTORE_FIELD_NOTIFICATION'
export const USER_FIELDS_BULK_ARCHIVE_FIELDS_NOTIFICATION =
  'USER_FIELDS_BULK_ARCHIVE_FIELDS_NOTIFICATION'
export const USER_FIELDS_BULK_RESTORE_FIELDS_NOTIFICATION =
  'USER_FIELDS_BULK_RESTORE_FIELDS_NOTIFICATION'
export const USER_FIELDS_BULK_DELETE_FIELDS_NOTIFICATION =
  'USER_FIELDS_BULK_DELETE_FIELDS_NOTIFICATION'

export const USERFIELDS_SETSUBSCRIBERFIELD_REQUEST = 'USERFIELDS_SETSUBSCRIBERFIELD_REQUEST'
export const USERFIELDS_SETSUBSCRIBERFIELD_RESPONSE = 'USERFIELDS_SETSUBSCRIBERFIELD_RESPONSE'
export const USERFIELDS_SETSUBSCRIBERFIELD_ERROR = 'USERFIELDS_SETSUBSCRIBERFIELD_ERROR'

export const USERFIELDS_GET_ENTITIES_LIST = 'USERFIELDS_GET_ENTITIES_LIST'

// System Fields
export const USERFIELDS_SETSYSTEMFIELD_REQUEST = 'USERFIELDS_SETSYSTEMFIELD_REQUEST'
export const USERFIELDS_SETSYSTEMFIELD_RESPONSE = 'USERFIELDS_SETSYSTEMFIELD_RESPONSE'
export const USERFIELDS_SETSYSTEMFIELD_ERROR = 'USERFIELDS_SETSYSTEMFIELD_ERROR'

export const USER_FIELDS_MOVE_TO_REQUEST = 'USER_FIELDS_MOVE_TO_REQUEST'
export const USER_FIELDS_MOVE_TO_RESPONSE = 'USER_FIELDS_MOVE_TO_RESPONSE'
export const USER_FIELDS_MOVE_TO_ERROR = 'USER_FIELDS_MOVE_TO_ERROR'

export const USER_FIELDS_MOVE_TO_BULK_REQUEST = 'USER_FIELDS_MOVE_TO_BULK_REQUEST'
export const USER_FIELDS_MOVE_TO_BULK_RESPONSE = 'USER_FIELDS_MOVE_TO_BULK_RESPONSE'
export const USER_FIELDS_MOVE_TO_BULK_ERROR = 'USER_FIELDS_MOVE_TO_BULK_ERROR'

export const USER_FIELDS_SEARCH_VALUE_CHANGE = 'USER_FIELDS_SEARCH_VALUE_CHANGE'

export const USER_FIELDS_FETCH = 'USER_FIELDS_FETCH'

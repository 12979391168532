export const FIELD_LIST_MAX_HEIGHT = 350
export const FIELD_LIST_MIN_HEIGHT = 280
export const FIELD_LIST_WIDTH = 280
export const FIELD_LIST_NEW_OPTION_HEIGHT = 45
export const FIELD_LIST_MAX_SCROLLABLE_HEIGHT = FIELD_LIST_MAX_HEIGHT - FIELD_LIST_NEW_OPTION_HEIGHT
export const FIELD_LIST_MIN_WIDTH = 220
export const FIELD_PICKER_WIDTH = 480

export const FIELD_ROOT_FOLDER_ID = 0

export const FIELD_CAPTION_MAX_LENGTH = 50
export const FIELD_DESCRIPTION_MAX_LENGTH = 320

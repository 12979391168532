import { l } from '@manychat/manyui'

import {
  SECONDS_IN_MINUTE,
  SECONDS_IN_HOUR,
  SECONDS_IN_DAY,
  Unit,
} from 'components/DelayPicker/constants'

export const formatDelay = (delay: number) => {
  if (delay === 0) {
    return 0
  }

  if (delay >= SECONDS_IN_DAY && delay % SECONDS_IN_DAY === 0) {
    return parseInt((delay / SECONDS_IN_DAY).toFixed(), 10)
  }

  if (delay >= SECONDS_IN_DAY && delay % SECONDS_IN_DAY !== 0) {
    return parseInt((delay / SECONDS_IN_HOUR).toFixed(), 10)
  }

  if (delay >= SECONDS_IN_HOUR && delay % SECONDS_IN_HOUR === 0) {
    return parseInt((delay / SECONDS_IN_HOUR).toFixed(), 10)
  }

  if (delay >= SECONDS_IN_HOUR && delay % SECONDS_IN_HOUR !== 0) {
    return parseInt((delay / SECONDS_IN_MINUTE).toFixed(), 10)
  }

  return parseInt((delay / SECONDS_IN_MINUTE).toFixed(), 10)
}

export const getUnit = (delay: number) => {
  if (delay === 0) {
    return Unit.IMMEDIATELY
  }

  if (delay >= SECONDS_IN_DAY && delay % SECONDS_IN_DAY === 0) {
    return Unit.DAYS
  }

  if (delay >= SECONDS_IN_DAY && delay % SECONDS_IN_DAY !== 0) {
    return Unit.HOURS
  }

  if (delay >= SECONDS_IN_HOUR && delay % SECONDS_IN_HOUR === 0) {
    return Unit.HOURS
  }

  if (delay >= SECONDS_IN_HOUR && delay % SECONDS_IN_HOUR !== 0) {
    return Unit.MINUTES
  }

  return Unit.MINUTES
}

export const formatUnit = (delay: number) => {
  const unit = getUnit(delay)
  if (unit === Unit.IMMEDIATELY) return l.translate('Immediately')

  const formattedDelay = formatDelay(delay)
  const unitLabels = {
    [Unit.MINUTES]: l.translate(
      '{formattedDelay, plural, one {# minute} few {# minutes} many {# minutes} other {# minutes}}',
      {
        formattedDelay,
      },
    ),
    [Unit.HOURS]: l.translate(
      '{formattedDelay, plural, one {# hour} few {# hours} many {# hours} other {# hours}}',
      {
        formattedDelay,
      },
    ),
    [Unit.DAYS]: l.translate(
      '{formattedDelay, plural, one {# day} few {# days} many {# days} other {# days}}',
      {
        formattedDelay,
      },
    ),
  }

  return unitLabels[unit]
}

export const unitValueToSeconds = (unit: Unit, value: number) => {
  switch (unit) {
    case Unit.IMMEDIATELY:
      return 0
    case Unit.DAYS:
      return value * SECONDS_IN_DAY
    case Unit.HOURS:
      return value * SECONDS_IN_HOUR
    default:
      return value * SECONDS_IN_MINUTE
  }
}

export enum BlockType {
  TEXT = 'text',
  COLUMN = 'column',
  ROW = 'row',
  IMAGE = 'image',
  TEST = 'test',
  DEFAULT_HEADER = 'default_header',
  MODAL_VIEW = 'modal_view',
  BENEFITS = 'benefits',
  PRODUCT_LIST = 'product_list',
  ORDER_SUMMARY = 'order_summary',
  PRODUCT_TABLE_LEGACY = 'product_table_legacy',
  BOX = 'box',
  HR = 'hr',
  VERTICAL_VIDEO = 'vertical_video',
  ORDER_SUMMARY_SMALL = 'order_summary_small',
  TICK_LIST = 'tick_list',
  BUTTON = 'button',
  TIMELINE = 'timeline',
  TIERS_PREVIEW = 'tiers_preview',
  SUMMARY_FOOTER = 'summary_footer',
  NULL = 'null',
}

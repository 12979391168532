import memoize from 'memoize-one'

export const DEFAULT_URL = '#'
const INVALID_URL_CHARS_REG_EXP = /[<>\\]/gi
const JAVASCRIPT_PROTOCOL_REG_EXP = /^javascript:/i

interface Options {
  isReturnDecodedUrl?: boolean
}

export const getDecodedString = (encodedString: string) => {
  let decodedResult = encodedString
  let hasError = false

  const getResult = (encodedString: string): string => {
    try {
      if (hasError) {
        return decodedResult
      }
      const interimDecodedResult = decodeURIComponent(encodedString)
      if (interimDecodedResult && decodedResult !== interimDecodedResult) {
        decodedResult = interimDecodedResult
        return getResult(decodedResult)
      }
      return decodedResult
    } catch (error) {
      hasError = true
      return decodedResult
    }
  }

  return getResult(encodedString)
}

export const getEncodedQuery = (queryString: string) => {
  if (!queryString || !queryString.startsWith('?')) {
    return ''
  }

  const params = queryString.slice(1).split('&')
  const encodedParams = params.map((param) => {
    const [key, value] = param.split('=')
    if (value) {
      return key ? `${key}=${encodeURIComponent(value)}` : encodeURIComponent(param)
    }
    return param
  })

  return `?${encodedParams.join('&')}`
}

export const getEncodedHash = (hashString: string) => {
  if (!hashString || !hashString.startsWith('#')) {
    return ''
  }

  const pureHashContent = hashString.substring(1)
  return `#${encodeURIComponent(pureHashContent)}`
}

export const sanitizeURL = memoize(
  (url: string | undefined, options?: Options): string | undefined => {
    if (url === undefined) return undefined

    if (!url) return DEFAULT_URL

    const trimmedURL = url.trim()
    const preEncodedURL = encodeURIComponent(trimmedURL)
    const decodedURL = decodeURIComponent(preEncodedURL)

    if (
      INVALID_URL_CHARS_REG_EXP.test(decodedURL) ||
      JAVASCRIPT_PROTOCOL_REG_EXP.test(decodedURL)
    ) {
      INVALID_URL_CHARS_REG_EXP.lastIndex = 0
      JAVASCRIPT_PROTOCOL_REG_EXP.lastIndex = 0
      return DEFAULT_URL
    }

    try {
      const { origin, pathname, href, search, hash } = new URL(decodedURL, window.location.origin)

      if (options?.isReturnDecodedUrl) {
        return href
      }

      const decodedSearch = getDecodedString(search)
      const decodedHash = getDecodedString(hash)
      const encodedQuery = getEncodedQuery(decodedSearch)
      const encodedHash = getEncodedHash(decodedHash)
      return `${origin}${pathname}${encodedQuery}${encodedHash}`
    } catch (error) {
      return DEFAULT_URL
    }
  },
)

import { z } from 'zod'

import { usedEntitiesListSchema } from 'shared/api/requests/cms/schemas/usedEntitiesList'

export enum TagType {
  USER = 'user',
}

export const TagSchema = z.object({
  tag_id: z.number(),
  page_id: z.number(),
  tag_normalized: z.string(),
  tag_name: z.string(),
  ts_created: z.number(),
  ts_updated: z.number(),
  active: z.boolean(),
  folder_id: z.number(),
})

export const TagsSchemas = {
  getList: {
    response: z.object({
      tags: z.array(TagSchema),
    }),
    query: z.object({
      path: z.string().optional(),
      type: z.nativeEnum(TagType),
      query: z.string().optional(),
    }),
    path: z.undefined(),
  },
  create: {
    response: z.object({
      tag: TagSchema,
    }),
    query: z.object({
      tag_name: z.string(),
      path: z.string(),
      client_id: z.string(),
    }),
    path: z.undefined(),
  },
  update: {
    request: TagSchema,
    response: z.object({
      tag: TagSchema,
    }),
    query: z.undefined(),
    path: z.undefined(),
  },
  delete: {
    response: z.undefined(),
    query: z.object({
      tag_id: z.number(),
    }),
    path: z.undefined(),
  },
  bulkDelete: {
    request: z.object({ ids: z.array(z.number()) }),
    response: z.object({
      models: z.array(TagSchema),
    }),
    query: z.undefined(),
    path: z.undefined(),
  },
  moveToFolder: {
    response: z.object({
      tag: TagSchema,
    }),
    request: z.object({
      tag_id: z.number(),
      folder_id: z.number(),
    }),
    query: z.undefined(),
    path: z.undefined(),
  },
  moveToFolderBulk: {
    response: z.object({
      models: z.array(TagSchema),
    }),
    request: z.object({
      ids: z.array(z.number()),
      folder_id: z.number(),
    }),
    query: z.undefined(),
    path: z.undefined(),
  },
  getFolderContentCount: {
    response: z.object({
      tags_count: z.number(),
    }),
    query: z
      .object({
        path: z.string().optional(),
        type: z.nativeEnum(TagType).optional(),
      })
      .optional(),
    path: z.undefined(),
  },
  getUsesList: {
    response: z.object({
      entities: usedEntitiesListSchema,
    }),
    query: z.object({
      tag_id: z.number(),
    }),
    path: z.undefined(),
  },
}

export type Tag = z.infer<typeof TagSchema>

import { l } from '@manychat/manyui'

export const SURVEY_LOCAL_STORAGE_ID = 'mc_mobile_web_survey_shown'
export const SURVEY_CLOSE_DELAY_MS = 3000

export enum MobileAppReason {
  PROBLEM_WITH_INSTALLATION = 'problem_with_installation',
  FEATURE = 'feature',
  MOBILE_WEB = 'mobile_web',
  ANOTHER = 'another',
}

export enum RequestedFeature {
  DASHBOARD = 'dashboard',
  GROWTH_TOOLS = 'growth_tools',
  BROADCAST = 'broadcast',
  ADS = 'ads',
  ANOTHER = 'another',
}

export enum Question {
  REASON = 'reason',
  FEATURE_REQUEST = 'feature_request',
}

export const SurveyTextsByQuestion = {
  [Question.REASON]: {
    title: l.makeTranslate('Just another five-ten seconds of your time'),
    description: l.makeTranslate(
      'Please, tell us how we could change your attitude towards the mobile application.',
    ),
    answers: [
      {
        label: l.makeTranslate('Problem with installation'),
        value: MobileAppReason.PROBLEM_WITH_INSTALLATION,
      },
      {
        label: l.makeTranslate("The app doesn't have a feature..."),
        value: MobileAppReason.FEATURE,
        nextQuestion: Question.FEATURE_REQUEST,
      },

      { label: l.makeTranslate('I like mobile web version'), value: MobileAppReason.MOBILE_WEB },
      {
        label: l.makeTranslate('Another...'),
        value: MobileAppReason.ANOTHER,
        isUserInputAvailable: true,
      },
    ],
  },
  [Question.FEATURE_REQUEST]: {
    title: l.makeTranslate("The app doesn't have a feature..."),
    description: '',
    answers: [
      {
        label: l.makeTranslate('Dashboard'),
        value: RequestedFeature.DASHBOARD,
      },
      {
        label: l.makeTranslate('Growth tools'),
        value: RequestedFeature.GROWTH_TOOLS,
      },

      { label: l.makeTranslate('Broadcast'), value: RequestedFeature.BROADCAST },
      { label: l.makeTranslate('Ads'), value: RequestedFeature.ADS },
      {
        label: l.makeTranslate('Another...'),
        value: RequestedFeature.ANOTHER,
        isUserInputAvailable: true,
      },
    ],
  },
}

import React from 'react'
import { l } from '@manychat/manyui'

import { BroadcastSampleTour } from 'utils/services/newOnboardingService/onboardings/WhatsApp/BroadcastSampleTour'
import DefaultStepView from 'utils/services/newOnboardingService/view/DefaultStepView'
import ManyChatOnboardingView from 'utils/services/newOnboardingService/view/ManyChatOnboardingView'
import { PointerType } from 'utils/services/newOnboardingService/view/Pointer/pointerInterfaces'
import TooltipDefault from 'utils/services/newOnboardingService/view/Pointer/Tooltip/TooltipDefault'

interface BroadcastSampleTourViewProps {
  onb: BroadcastSampleTour
}

const TOTAL_STEPS = 7

export const BroadcastSampleTourView = ({ onb }: BroadcastSampleTourViewProps) => (
  <ManyChatOnboardingView
    onboarding={onb}
    stepsView={{
      STEP_1: ({ stepId, step }) => (
        <DefaultStepView
          onb={onb}
          stepId={stepId}
          progressConfig={{ current: 1, total: TOTAL_STEPS, loader: false }}
          pointerConfig={{
            type: PointerType.DOM,
            elementId: 'sidebar-content-container',
            blockAreaInput: true,
            tooltip: {
              component: (
                <TooltipDefault
                  onNextClick={() => step.process()}
                  btnNext={l.makeTranslate('Next')}
                  text={l.makeTranslate(
                    'Welcome to WhatsApp broadcasting! <br /><br />WhatsApp templates are pre-approved messages that you must use to send a Broadcast. Meta usually approves these templates within a few minutes, but approval can take up to 24 hours. <br /><br />Once approved, broadcasts can be sent immediately.',
                  )}
                />
              ),
              at: 'right',
            },
          }}
        />
      ),
      STEP_2: ({ stepId, step }) => (
        <DefaultStepView
          onb={onb}
          stepId={stepId}
          progressConfig={{ current: 2, total: TOTAL_STEPS, loader: false }}
          pointerConfig={{
            type: PointerType.DOM,
            elementId: 'sidebar-template-categories',
            blockAreaInput: true,
            padding: 12,
            tooltip: {
              component: (
                <TooltipDefault
                  onNextClick={() => step.process()}
                  btnNext={l.makeTranslate('Next')}
                  text={l.makeTranslate(
                    "Select the category and language that match your message to pass Meta's review successfully.",
                  )}
                />
              ),
              at: 'right',
            },
          }}
        />
      ),
      STEP_3: ({ stepId }) => (
        <DefaultStepView
          onb={onb}
          stepId={stepId}
          progressConfig={{ current: 3, total: TOTAL_STEPS, loader: false }}
          pointerConfig={{
            type: PointerType.DOM,
            elementId: 'accordion-label-wrapper-target-audience',
            tooltip: {
              component: (
                <TooltipDefault
                  text={l.makeTranslate(
                    'Click "Target Audience" to determine who you send your broadcast to.',
                  )}
                />
              ),
              at: 'top',
            },
          }}
        />
      ),
      STEP_4: ({ stepId, step }) => (
        <DefaultStepView
          onb={onb}
          stepId={stepId}
          progressConfig={{ current: 4, total: TOTAL_STEPS, loader: false }}
          pointerConfig={{
            type: PointerType.DOM,
            elementId: 'target-audience-contacts',
            blockAreaInput: true,
            padding: 8,
            tooltip: {
              component: (
                <TooltipDefault
                  onNextClick={() => step.process()}
                  btnNext={l.makeTranslate('Next')}
                  text={l.makeTranslate(
                    'Use Conditions to narrow down your Audience. Personalized broadcasts drive more sales.',
                  )}
                />
              ),
              at: 'bottom',
            },
          }}
        />
      ),
      STEP_5: ({ stepId }) => (
        <DefaultStepView
          onb={onb}
          stepId={stepId}
          progressConfig={{ current: 5, total: TOTAL_STEPS, loader: false }}
          pointerConfig={{
            type: PointerType.DOM,
            elementId: 'accordion-label-wrapper-content',
            areaShift: { width: -240 },
            tooltip: {
              component: (
                <TooltipDefault
                  text={l.makeTranslate(
                    'Click "Content" tab to go back and compose your first message.',
                  )}
                />
              ),
              at: 'bottom',
            },
          }}
        />
      ),
      STEP_6: ({ stepId, step }) => (
        <DefaultStepView
          onb={onb}
          stepId={stepId}
          progressConfig={{ current: 6, total: TOTAL_STEPS, loader: false }}
          pointerConfig={{
            type: PointerType.DOM,
            elementId: 'publish-button',
            blockAreaInput: true,
            tooltip: {
              component: (
                <TooltipDefault
                  onNextClick={() => step.process()}
                  btnNext={l.makeTranslate('Next')}
                  text={l.makeTranslate(
                    'After composing your message, click "Send After Approval". Once WhatsApp approves your message, they will broadcast it.',
                  )}
                />
              ),
              at: 'left',
              areaShift: { top: 64 },
            },
          }}
        />
      ),
      STEP_7: ({ stepId, step }) => (
        <DefaultStepView
          onb={onb}
          stepId={stepId}
          progressConfig={{ current: 7, total: TOTAL_STEPS, loader: false }}
          pointerConfig={{
            type: PointerType.DOM,
            elementId: 'sidebar-template-content',
            blockAreaInput: true,
            padding: 12,
            tooltip: {
              component: (
                <TooltipDefault
                  onNextClick={() => step.process()}
                  btnNext={l.makeTranslate('Got it')}
                  text={l.makeTranslate(
                    'Start by composing your first message from scratch or with the help of AI.',
                  )}
                />
              ),
              at: 'right',
            },
          }}
        />
      ),
    }}
  />
)

import dayjs from 'dayjs'
import get from 'lodash/get'
import isNumber from 'lodash/isNumber'
import isString from 'lodash/isString'
import map from 'lodash/map'
import { l } from '@manychat/manyui'

import { formatFacebookPrice } from './utils'

export enum AdCampaignStatusType {
  ACTIVE = 'ACTIVE',
  PAUSED = 'PAUSED',
  DELETED = 'DELETED',
  ARCHIVED = 'ARCHIVED',
}

export enum StatusType {
  ACTIVE = 'ACTIVE',
  PAUSED = 'PAUSED',
  DELETED = 'DELETED',
  ARCHIVED = 'ARCHIVED',
}

export enum EffectiveStatusType {
  ACTIVE = 'ACTIVE',
  PAUSED = 'PAUSED',
  DELETED = 'DELETED',
  PENDING_REVIEW = 'PENDING_REVIEW',
  DISAPPROVED = 'DISAPPROVED',
  PREAPPROVED = 'PREAPPROVED',
  PENDING_BILLING_INFO = 'PENDING_BILLING_INFO',
  CAMPAIGN_PAUSED = 'CAMPAIGN_PAUSED',
  ARCHIVED = 'ARCHIVED',
  ADSET_PAUSED = 'ADSET_PAUSED',
  IN_PROGRESS = 'IN_PROGRESS',
}

export const AdStatusType = {
  DRAFT: 'DRAFT',
}

export const DisplayMode = {
  LIST: 'list',
  HIERARCHY: 'hierarchy',
}

export const ConfigDefaults = {
  displayMode: DisplayMode.LIST,
  hideColumns: [],
  filter: null,
  gtOnly: false,
}

export const ConfigLocalStorageKey = 'mcht_ad_table_config'

export const StatsColumnType = {
  RESULTS: 'results',
  REACH: 'reach',
  IMPRESSIONS: 'impressions',
  CPC: 'cpc',
  AMOUNT: 'amount',
  ENDS: 'ends',
  MC_OPTIN: 'mc_optin',
  MC_OPTIN_EXISTING: 'mc_optin_existing',
  MC_OPTIN_NEW: 'mc_optin_new',
  PURCHASES: 'purchases',
}

export const StatsColumns = {
  [StatsColumnType.IMPRESSIONS]: {
    label: 'Impressions',
    width: 110,
    render: (ad: UnsafeAnyObject) => get(ad, 'fbStats.impressions', '—'),
    tooltip:
      "The number of times your ad was displayed on contact's screen across your audience. If the ad was shown twice to the same contact it accounts for 2 impressions. This metric is counted by Facebook.",
  },
  [StatsColumnType.REACH]: {
    label: 'Reach',
    width: 100,
    render: (ad: UnsafeAnyObject) => get(ad, 'fbStats.reach', '—'),
    tooltip:
      'The number of unique users who saw your ad at least once.\n' +
      'This metric is estimated by Facebook.',
  },
  [StatsColumnType.RESULTS]: {
    label: 'Clicks',
    width: 90,
    render: (ad: UnsafeAnyObject) => get(ad, 'fbStats.unique_clicks', '—'),
    tooltip: 'The number of clicks on your ads. This number is counted by Facebook.',
  },
  [StatsColumnType.MC_OPTIN]: {
    mcstats: true,
    label: 'Opt-Ins',
    width: 100,
    render: (ad: UnsafeAnyObject) => get(ad, 'mcStats.optins', 'n/a'),
    tooltip:
      'The number of people who interacted with your bot after clicking on an ad. Mind that opening messenger thread, reads, and clicks on buttons that are external URLs are not counted as opt-ins.',
  },
  [StatsColumnType.AMOUNT]: {
    label: 'Amount Spent',
    width: 150,
    render: (ad: UnsafeAnyObject, curr: UnsafeAnyObject) => {
      const spend = get(ad, 'fbStats.spend')
      if (!isString(spend)) {
        return '—'
      }
      return formatFacebookPrice(spend, curr)
    },
    tooltip:
      "The estimated total amount of money you've spent on your ad during its schedule. This metric is estimated by Facebook.",
  },
  [StatsColumnType.CPC]: {
    mcstats: true,
    label: 'Cost per Opt-in',
    width: 150,
    render: (ad: UnsafeAnyObject, curr: UnsafeAnyObject) => {
      const spend = get(ad, 'fbStats.spend')
      const optIns = get(ad, 'mcStats.optins')

      if (!isString(spend) || !isNumber(optIns)) {
        return '—'
      }
      const cpc = optIns === 0 ? 0 : parseInt(spend) / optIns
      return formatFacebookPrice(cpc, curr)
    },
    tooltip:
      'The average cost per successful interaction with your bot after receiving ad message. This metric is calculated as Amount Spent divided by Opt-ins.',
  },

  [StatsColumnType.MC_OPTIN_NEW]: {
    mcstats: true,
    label: 'New Contacts',
    width: 120,
    render: (ad: UnsafeAnyObject) => get(ad, 'mcStats.optins_new', 'n/a'),
    tooltip: 'The number of new contacts obtained after successful opt-in through an ad message.',
  },
  [StatsColumnType.MC_OPTIN_EXISTING]: {
    mcstats: true,
    label: 'Old Contacts',
    width: 120,
    render: (ad: UnsafeAnyObject) => get(ad, 'mcStats.optins_existing', 'n/a'),
    tooltip: 'The number of people who opted-in being your contacts already.',
  },
  [StatsColumnType.ENDS]: {
    label: 'Ends',
    width: 200,
    render: (ad: UnsafeAnyObject) => {
      const ends = get(ad, 'fbStats.end_time')
      if (!ends) {
        return '-'
      }
      return l.date(dayjs.utc(ends).toDate())
    },
    tooltip: 'The date your campaign is scheduled to stop running.',
    tooltipAt: 'left',
  },
  // [StatsColumnType.PURCHASES]: {
  //   mcstats: true,
  //   label: 'Purchases',
  //   width: 100,
  // },
}

export const StatsColumnsList = map(StatsColumns, (data, id) => ({ ...data, id }))

import { McAPI, Params, RequestPayload, RequestOptions, Body } from '../types'

import { request } from './request'

const get = async <T extends Body>(url: string, payload?: RequestPayload): Promise<T> => {
  const { url: completedUrl, headers } = formatUrl(addParams(url, payload?.params))
  return request<T>(completedUrl, { headers })
}

const post = async <T extends Body>(url: string, payload?: RequestPayload): Promise<T> => {
  const { url: completedUrl, headers } = formatUrl(addParams(url, payload?.params))
  const requestInit: RequestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json', ...headers },
    credentials: payload?.credentials || 'same-origin',
    keepalive: payload?.keepalive || false,
  }

  if (payload?.body) {
    if (payload.body instanceof FormData) {
      requestInit.body = payload.body
      delete requestInit.headers
    } else {
      requestInit.body = JSON.stringify(payload.body)
    }
  }

  return request<T>(completedUrl, requestInit)
}

const api: McAPI = { get, post }

export default api

export const setURLFormatter = (formatter: typeof formatUrl) => {
  formatUrl = formatter
}

export const resetURLFormatter = () => {
  formatUrl = defaultFormatter
}
const defaultFormatter = (url: string): { url: string; headers: Record<string, string> } => {
  const headers: Record<string, string> = {
    'X-Frontend-Bundle': window.STATIC_VERSION ? String(window.STATIC_VERSION) : 'Empty',
  }

  const csrfToken = window.__INIT__?.['app.csrf_token']
  if (csrfToken) {
    headers['X-Csrf-Token'] = csrfToken
  }

  return {
    url,
    headers,
  }
}
export let formatUrl = defaultFormatter

const addParams = (url: string, params?: Params): string => {
  if (!params) {
    return url
  }
  for (const key of Object.keys(params)) {
    url = url.replace(`:${key}`, params[key])
  }
  return url
}

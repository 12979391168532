import get from 'lodash/get'

import * as atypes from 'common/actions/integrations/common/constants/IntegrationsReduxActionTypes'

const initialState = {}

function integrationsReducer(state = initialState, action) {
  switch (action.type) {
    case atypes.INTEGRATION_FETCH_RESPONSE:
    case atypes.INTEGRATION_UPDATED_NOTIFICATION: {
      const { integration, statistics } = action.data

      const permission = action.data.permission
      const permissions = permission ? permission : undefined

      return { ...state, ...integration, permissions, statistics }
    }
  }
  return state
}

export default function makeIntegrationsReducer(reducer, integration) {
  return (state, action) => {
    if (get(action, 'data.integration.type') === integration) {
      state = integrationsReducer(state, action)
    }
    return reducer(state, action)
  }
}

import {
  InvalidContextError,
  InvalidPropsError,
  InvalidViewError,
  MissingBlockError,
  TreeCycleError,
} from '../errors'
import { Registry } from '../registry'
import { DefaultContextSchema } from '../schemas'
import { Id, SerializedView, ViewContext } from '../types'

import { isNestedBlock } from './guards'
import { SerializedViewSchema } from './schemas'

const checkContext = <Ctx extends ViewContext>(
  view: SerializedView,
  registry: Registry,
  context: Ctx,
) => {
  try {
    DefaultContextSchema.parse(context)

    for (const serializedBlock of Object.values(view.blocks)) {
      const Block = registry.resolve(serializedBlock.type)

      if (Block.context) {
        Block.context.parse(context)
      }
    }
  } catch (error) {
    throw new InvalidContextError(error)
  }
}

const checkCycles = (view: SerializedView) => {
  const visited = new Set<Id>()
  const stack: Id[] = [view.root]

  while (stack.length) {
    const id = stack.pop() as Id
    const serializedBlock = view.blocks[id]
    if (!serializedBlock) {
      throw new MissingBlockError(id)
    }

    if (visited.has(id)) {
      throw new TreeCycleError(id)
    }

    visited.add(id)
    if (isNestedBlock(serializedBlock)) {
      stack.push(...serializedBlock.props.children)
    }
  }
}

const checkProps = (view: SerializedView, registry: Registry) => {
  for (const serializedBlock of Object.values(view.blocks)) {
    const Block = registry.resolve(serializedBlock.type)
    const arePropsValid = Block.props.safeParse(serializedBlock.props).success
    if (!arePropsValid) {
      throw new InvalidPropsError(serializedBlock.id)
    }
  }
}

const checkSchema = (view: SerializedView) => {
  try {
    SerializedViewSchema.parse(view)
  } catch (error) {
    throw new InvalidViewError(String(error))
  }
}

export const validate = <Ctx extends ViewContext>(
  view: SerializedView,
  registry: Registry,
  context: Ctx,
) => {
  checkSchema(view)
  checkCycles(view)
  checkContext(view, registry, context)
  checkProps(view, registry)
}

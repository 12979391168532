import React, { useContext, useState } from 'react'
import cx from 'classnames'
import { Icon } from '@manychat/manyui'

import { FSQueryRootPath } from 'common/cms/models/FSQuery/constants'
import { FsFolderObject } from 'shared/api/requests/cms/schemas'

import { FoldersContext } from './FoldersContext'
import { getChildrenFolders } from './helpers'

import cm from './Folder.module.css'

export const Folder = ({ folder }: { folder: FsFolderObject }) => {
  const { setActiveFolderId, activeFolder, folders, disabledFolder, disabledTooltip } =
    useContext(FoldersContext)

  const [isOpenFolder, setOpenFolder] = useState(folder.path === FSQueryRootPath)
  const childrenFolders = getChildrenFolders(folders, folder.path)
  const hasChildrenFolders = childrenFolders.length > 0
  const isDisabledFolder = folder.path === disabledFolder

  const toggleFolder = () => {
    setOpenFolder((prevState) => !prevState)
    if (!isDisabledFolder) {
      setActiveFolderId(folder.path)
    }
  }

  return (
    <>
      <li
        role="button"
        className={cx(
          cm.folder,
          'c-pointer d-flex align-center m-b-xs b-a rounded p-x-sm p-y-sm p-relative',
          activeFolder === folder.path && cm.folderActive,
        )}
        data-title-width={170}
        data-title={isDisabledFolder && disabledTooltip ? disabledTooltip : null}
        onClick={toggleFolder}
      >
        {hasChildrenFolders && !isOpenFolder && <Icon.ChevronRight className={cm.folderNested} />}
        {hasChildrenFolders && isOpenFolder && <Icon.ChevronDown className={cm.folderNested} />}
        <Icon.Folder className="text-primary flex-shrink-0 m-r-xs" size={20} />
        <span className="text-ellipsis">{folder.title}</span>
      </li>
      {isOpenFolder && hasChildrenFolders && (
        <ul>
          {childrenFolders.map((folder) => (
            <Folder key={folder.folder_id} folder={folder} />
          ))}
        </ul>
      )}
    </>
  )
}

import React from 'react'
import { tints } from '@manychat/manyui'

interface CircularProgressProps {
  progress?: number
  size?: number
  strokeWidth?: number
  activeColor?: string
}

const CircularProgress = ({
  progress = 0,
  size = 40,
  strokeWidth = 5,
  activeColor = tints.neutral[4],
}: CircularProgressProps) => {
  const radius = (size - strokeWidth) / 2
  const circumference = 2 * Math.PI * radius
  const normalizedProgress = Math.min(Math.max(progress, 0), 100)
  const offset = circumference * (1 - normalizedProgress / 100)

  return (
    <svg width={size} height={size} viewBox={`0 0 ${size} ${size}`}>
      <circle
        cx={size / 2}
        cy={size / 2}
        r={radius}
        stroke={tints.neutral[4]}
        strokeWidth={strokeWidth}
        fill="none"
      />
      <circle
        cx={size / 2}
        cy={size / 2}
        r={radius}
        stroke={activeColor}
        strokeWidth={strokeWidth}
        fill="none"
        strokeDasharray={circumference}
        strokeDashoffset={offset}
        strokeLinecap="butt"
        transform={`rotate(-90 ${size / 2} ${size / 2})`}
      />
    </svg>
  )
}

export default CircularProgress

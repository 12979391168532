import cx from 'classnames'
import { useAppSelector } from 'reduxTyped'

import sharedCm from 'apps/cms/components/Flows/components/FlowCard/components/Triggers/lib/sharedStyles.module.css'
import { contentManagementSelectors } from 'apps/cms/store'

export const useFlexibleTriggerNameClassName = () => {
  const isSearchMode = useAppSelector(contentManagementSelectors.getIsSearchMode)

  return cx(sharedCm.flexibleTriggerName, isSearchMode && sharedCm.flexibleTriggerName_opened)
}

import { z } from 'zod'

import { ChannelType } from 'common/core/constants/ChannelType'
import { lightFlowSchema } from 'shared/api/common/schemas/lightFlow'

export enum IceBreakerStatus {
  ACTIVE = 1,
  DISABLED = 2,
  PUBLISHED = 3,
  DELETED = -1,
}

export const iceBreakerSchema = z.object({
  icebreaker_id: z.number().nullable(),
  caption: z.string(),
  sort_id: z.number(),
  chns: z.string(),
  status: z.nativeEnum(IceBreakerStatus),
  flow: lightFlowSchema.nullable(),
  triggered: z.number(),
  channel: z.string().optional(),
  template_installation_id: z.number().nullable(),
})

export const iceBreakerChannelSchema = z.union([
  z.literal(ChannelType.INSTAGRAM),
  z.literal(ChannelType.FB),
  z.literal(ChannelType.WHATSAPP),
])

export const iceBreakersResponse = z.object({
  ice_breakers: z.array(iceBreakerSchema),
})

export const publishReadyIceBreakerSchema = z.object({
  icebreaker_id: z.number().nullable(),
  caption: z.string(),
  sort_id: z.number(),
  status: z.nativeEnum(IceBreakerStatus),
  flow_ns: z.string().optional(),
})

export const iceBreakersSchemas = {
  get: {
    response: iceBreakersResponse,
    query: z.object({
      channel: iceBreakerChannelSchema,
    }),
    path: z.undefined(),
  },

  publish: {
    request: z.object({
      ice_breakers: z.array(publishReadyIceBreakerSchema),
      channel: iceBreakerChannelSchema,
    }),
    response: iceBreakersResponse,
    query: z.undefined(),
    path: z.undefined(),
  },

  setAvailable: {
    request: z.object({
      is_available: z.boolean(),
      channel: iceBreakerChannelSchema,
    }),
    response: z.object({
      ice_breakers: z.array(iceBreakerSchema),
      is_available: z.boolean(),
    }),
    query: z.undefined(),
    path: z.undefined(),
  },
}

export type IceBreaker = z.infer<typeof iceBreakerSchema>
export type IceBreakerChannel = z.infer<typeof iceBreakerChannelSchema>
export type PublishReadyIceBreaker = z.infer<typeof publishReadyIceBreakerSchema>

export type IceBreakersResponse = z.infer<typeof iceBreakersResponse>

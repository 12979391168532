import { z } from 'zod'

import { ChannelType } from 'common/core/constants/ChannelType'

export enum AiIntentStatus {
  ACTIVE = 'active',
  DISABLED = 'disabled',
  DELETED = 'deleted',
}

export const AiIntentSchema = z.object({
  intent_id: z.number(),

  status: z.nativeEnum(AiIntentStatus),
  title: z.string().or(z.null()),
  intent: z.string(),

  channel: z.nativeEnum(ChannelType),

  content_holder: z.string(),
  flow_ns: z.string().or(z.null()),
  flow: z.object({}).or(z.null()),

  ts_created: z.number(),
  ts_updated: z.number(),

  was_activated_once: z.boolean(),
  stats: z
    .object({
      flow_runs: z.number(),
    })
    .optional(),
})

export type AiIntent = z.infer<typeof AiIntentSchema>

export const AiIntentsSchemas = {
  create: {
    request: z.object({
      intent: z.string(),
      channel: z.nativeEnum(ChannelType),
      flow_ns: z.string().or(z.null()).optional(),
    }),
    response: z.object({
      intent_rule: AiIntentSchema,
    }),
    query: z
      .object({
        client_id: z.string().optional(),
      })
      .optional(),
    path: z.undefined(),
  },
  get: {
    query: z.object({
      intent_id: z.number(),
      client_id: z.string().optional(),
    }),
    response: z.object({
      intent_rule: AiIntentSchema,
    }),
    request: z.undefined(),
    path: z.undefined(),
  },
  delete: {
    request: z.object({ intent_id: z.number() }),
    response: z.undefined(),
    query: z
      .object({
        client_id: z.string().optional(),
      })
      .optional(),
    path: z.undefined(),
  },
  update: {
    request: z.object({
      intent_id: z.number(),
      intent: z.string(),
      flow_ns: z.string().optional(),
    }),
    response: z.object({
      intent_rule: AiIntentSchema,
    }),
    query: z
      .object({
        client_id: z.string().optional(),
      })
      .optional(),
    path: z.undefined(),
  },
  updateStatus: {
    request: z.object({
      intent_id: z.number(),
      status: z.nativeEnum(AiIntentStatus),
    }),
    response: z.object({
      intent_rule: AiIntentSchema,
    }),
    query: z
      .object({
        client_id: z.string().optional(),
      })
      .optional(),
    path: z.undefined(),
  },
}

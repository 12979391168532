import { LiveChatSnippet } from 'apps/chat/constants/LiveChatInterfaces'
import { UserType } from 'common/core/interfaces/user'
import { UserRoles } from 'common/userRoles'
import { AutoAssignmentRule } from 'shared/api/requests/autoAssignment/schemas'
import { SettingsType } from 'shared/api/requests/settings/schemas'

export { AvailableCurrencies as AVAILABLE_CURRENCIES } from 'common/settings/constants/AvailableCurrencies'
export { ConversionsApiStatus } from 'common/settings/constants/ConversionsApiStatus'
export { AutoAssignmentType } from 'common/settings/constants/AutoAssignmentType'

export interface IBotSettingsInterface {
  admins: { items: IAdminInterface[] }
  error: string | null
  fetching: boolean
  ready: boolean
}

export interface IAdminInterface {
  assigned_threads_count: number
  avatar: string
  is_opted_in: boolean
  mentioned_threads_count: number
  name: string
  notify_settings: string[]
  user_id: number
  user_role: UserRoles
  user_type: UserType
  is_inbox_available: boolean
  is_inbox_user?: boolean
  has_notify_email: boolean
  id: number
}

export interface TelegramNotificationUser {
  id: number
  name: string
  username: string | null
  avatar: string | null
}

export interface SettingsState {
  locales: null
  timezones: null
  handoverReceivers: null
  whitelist: WhitelistDomain[]
  isWhitelistLoading: boolean
  isTimezonesLoading: boolean
  whitelistingError?: string
  exportBotStatus: UnsafeAnyObject
  liveChatSnippets: LiveChatSnippet[]
  optInPayload: null
  fetching: boolean
  policy_enforcement: {
    logs: []
    fetching: boolean
  }
  item: SettingsType
  publicAPIAccessToken?: string
}

export enum LiveChatAccountSettings {
  ASSIGN_ON_PREVIOUS_AGENT_WHEN_OPEN = 'assign_on_previous_agent_when_reopen',
  AUTO_ASSIGN_TYPE = 'auto_assign_type',
}

export interface WhitelistDomain {
  fb_verified?: boolean
  domain: string
  deletable: boolean
}

export interface IStripeInterface {
  id: 'string'
  object: string
  business_profile: {
    mcc: string
    name: string
    support_address?: string
    support_email?: string
    support_phone?: string
    support_url?: string
    url?: string
  }
  statement_descriptor: string
  capabilities: []
  charges_enabled: boolean
  controller: {
    is_controller: boolean
    type: string
  }
  country: string
  default_currency: string
  details_submitted: boolean
  email: string
  external_accounts: {
    object: string
    data: []
    has_more: boolean
    total_count: number
    url: string
  }
  future_requirements: {
    alternatives: []
    current_deadline: string
    currently_due: []
    disabled_reason: string
    errors: []
    eventually_due: []
    past_due: []
    pending_verification: []
  }
  metadata: []
  payouts_enabled: boolean
  requirements: {
    alternatives: []
    current_deadline: string
    currently_due: string[]
    disabled_reason: string
    errors: []
    eventually_due: string[]
    past_due: []
    pending_verification: []
  }
  settings: {
    bacs_debit_payments: []
    branding: {
      icon: string
      logo: string
      primary_color: string
      secondary_color: string
    }
    card_issuing: {
      tos_acceptance: {
        date: string
        ip: string
      }
    }
    card_payments: {
      statement_descriptor_prefix: string
    }
    dashboard: {
      display_name: string
      timezone: string
    }
    payments: {
      statement_descriptor: string
      statement_descriptor_kana: string
      statement_descriptor_kanji: string
    }
    payouts: {
      debit_negative_balances: boolean
      schedule: {
        delay_days: number
        interval: string
      }
      statement_descriptor: string
    }
    sepa_debit_payments: []
  }
  type: string
}

export interface SettingsAutoAssignmentState {
  rules: AutoAssignmentRule[] | null
  loading: boolean
  error: boolean
}

export enum TaxTypes {
  generalTaxSystem = 'general_tax_system',
  simplifiedIncome = 'simplified_income',
  simplifiedIncomeWithCostsDeducted = 'simplified_income_with_costs_deducted',
  envd = 'envd',
  esn = 'esn',
  patent = 'patent',
  kz12 = 'kz_12',
}

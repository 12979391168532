import { l } from '@manychat/manyui'

import { IBlock } from 'common/builder/blocks/blockInterfaces'
import { BlockType } from 'common/builder/constants/BlockType'
import { NodeType } from 'common/builder/constants/NodeType'
import BlockModel from 'common/builder/models/Block'
import BaseNodeConfig from 'common/builder/nodes/BaseNodeConfig'
import { INodeBase, IWhatsAppNode } from 'common/builder/nodes/nodeInterfaces'

class WhatsAppNodeConfig extends BaseNodeConfig<IWhatsAppNode> {
  nodeType = NodeType.WHATS_APP

  isPro = true

  getCaption = () => l.translate('Send Message')
  getToolbarCaption = () => l.translate('WhatsApp')
  getCaptionWithCount = (n: number) =>
    l.translate('{n, plural, one {# WhatsApp Message} other {# WhatsApp Messages}}', { n })
  getNextStepCaption = () => l.translate('WhatsApp')

  icon = 'WhatsAppChannel'
  color = '#8492A6'
  headerColor = '#E6F9ED'

  ALLOWED_BLOCK_TYPES = [
    BlockType.TEXT,
    BlockType.QUICK_REPLY,
    BlockType.FORM_QUESTION,
    BlockType.ATTACHMENT,
    BlockType.MESSAGE_TEMPLATE,
    BlockType.DELAY,
    BlockType.WA_LIST_MESSAGE,
    BlockType.WA_CATALOG_MESSAGE,
  ]

  ALLOWED_BLOCK_MAX_COUNT = 101

  getDefaults = (): Omit<IWhatsAppNode, keyof INodeBase> => ({
    whatsAppNodeType: 'content',
    targetId: null,
    reasonId: null,
    messageTag: null,
    privateReply: null,
    type: 'default',
  })

  getInitialBlocks = (node: IWhatsAppNode) =>
    node.whatsAppNodeType === 'content' ? [{ type: BlockType.TEXT as const }] : []

  isEmpty = ({ node, blocks }: { node: IWhatsAppNode; blocks: IBlock[] }) => {
    if (node.targetId) {
      return false
    }
    return this.areBlocksEmpty(blocks)
  }

  _isEmptyBlock = (block: IBlock) => {
    return [BlockType.TEXT].includes(block.type) && BlockModel.isEmpty(block)
  }
}

export default new WhatsAppNodeConfig()

import { billing } from 'common/billing/billingService'
import { ProAlertProps } from 'common/billing/components/ProAlert'
import { RequestUpgradePayload, UpgradeSourceType } from 'common/billing/interfaces'
import { IThunkAction } from 'common/core/interfaces/actions'
import { IErrorResponse } from 'common/core/interfaces/errorResponse'
import { BusinessError } from 'shared/api/lib/errors/business'
import { RequestError } from 'utils/api/mcApi/RequestError'

export interface OldProAlertParams {
  message?: string
  modalTitle?: string
  source: UpgradeSourceType
  proNodes?: ProAlertProps['proNodes']
  products?: IErrorResponse['products']
  addons?: IErrorResponse['addons']
}

interface GoProErrorHandlerParams extends OldProAlertParams, RequestUpgradePayload {
  openGoProModal?: boolean
}

export const handleGoProError = (
  error: RequestError | IErrorResponse | BusinessError,
  params: GoProErrorHandlerParams,
): IThunkAction => {
  return () => {
    const {
      message,
      source,
      products,
      proNodes,
      openGoProModal,
      addons,
      modalTitle = '',
      ...rest
    } = params

    if (openGoProModal) {
      return billing.requestUpgrade({
        ...rest,
        source,
        products,
        addOns: addons,
        proNodes,
        title: modalTitle,
      })
    }

    if (message) {
      return billing.proAlert({
        ...rest,
        message,
        title: modalTitle,
        source,
        products,
        proNodes,
        addOns: addons,
      })
    }

    return Promise.resolve(false)
  }
}

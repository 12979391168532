import { AccountTargetPermissionsNames } from 'common/core/models/Account/names'

export default {
  about: 'Hello, moto!',
  allow_api: true,
  ava_big:
    'https://s3.eu-central-1.amazonaws.com/manybot-thumbnails/322217448138095/ava/big_e2382ed545047937014ce4291bd9bc3b.jpg',
  ava_small:
    'https://s3.eu-central-1.amazonaws.com/manybot-thumbnails/322217448138095/ava/small_e2382ed545047937014ce4291bd9bc3b.jpg',
  channel: 'FB',
  description: '',
  enable_zapier: true,
  has_subscription_permission: true,
  id: 'fb322217448138095',
  link: 'https://m.me/ManySportEquipment',
  notify: {
    channel: '322217448138095_dev_0a5467400b285b397eed',
    server: 'https://notify.app.manychat.io:8001',
    user: '7',
    timestamp: '1503398557',
    token: '0965cd4d45fbe63f7b7458f2214d6b1e75c43b09c80f31bfff688e221b0e057c',
  },
  page_id: '322217448138095',
  page_link: 'https://facebook.com/ManySportEquipment',
  page_username: 'ManySportEquipment',
  pro_status: 'pro',
  status: 'active',
  title: 'ManySport Equipment inc',
  mainmenu_version: 'new',

  // extended
  category: 'Local Business',
  likes: 0,
}

export const DefaultAccountTargetPermissions: Partial<
  Record<AccountTargetPermissionsNames, boolean>
> = {
  [AccountTargetPermissionsNames.ACTIVE_CAMPAIGN_INTEGRATION]: false,
  [AccountTargetPermissionsNames.AI_INTENTS]: false,
  [AccountTargetPermissionsNames.BROADCAST_EMAIL]: false,
  [AccountTargetPermissionsNames.BROADCAST_FB]: false,
  [AccountTargetPermissionsNames.BROADCAST_INSTAGRAM]: false,
  [AccountTargetPermissionsNames.BROADCAST_MULTIPLE]: false,
  [AccountTargetPermissionsNames.BROADCAST_SMS]: false,
  [AccountTargetPermissionsNames.BROADCAST_TELEGRAM]: false,
  [AccountTargetPermissionsNames.BROADCAST_WHATSAPP]: false,
  [AccountTargetPermissionsNames.CHATGPT_INTEGRATION]: false,
  [AccountTargetPermissionsNames.CLAUDE_INTEGRAION]: false,
  [AccountTargetPermissionsNames.DEEPSEEK_INTEGRAION]: false,
  [AccountTargetPermissionsNames.CONVERT_KIT_INTEGRATION]: false,
  [AccountTargetPermissionsNames.EMAIL]: false,
  [AccountTargetPermissionsNames.FACEBOOK_ADS]: false,
  [AccountTargetPermissionsNames.GOOGLE_SHEETS_INTEGRATION]: false,
  [AccountTargetPermissionsNames.GT_CGT]: false,
  [AccountTargetPermissionsNames.GT_CGT_2]: false,
  [AccountTargetPermissionsNames.GT_FACEBOOK_SHOP]: false,
  [AccountTargetPermissionsNames.GT_FB_ADS_JSON]: false,
  [AccountTargetPermissionsNames.GT_IG_ADS_JSON]: false,
  [AccountTargetPermissionsNames.GT_QR_CODE]: false,
  [AccountTargetPermissionsNames.GT_REF_URL_FB]: false,
  [AccountTargetPermissionsNames.GT_REF_URL_INSTAGRAM]: false,
  [AccountTargetPermissionsNames.GT_REF_URL_WHATSAPP]: false,
  [AccountTargetPermissionsNames.HOTMART_INTEGRATION]: false,
  [AccountTargetPermissionsNames.HUB_SPOT_INTEGRATION]: false,
  [AccountTargetPermissionsNames.TIKTOK_ADS_INTEGRATION]: false,
  [AccountTargetPermissionsNames.KLAVIYO_INTEGRATION]: false,
  [AccountTargetPermissionsNames.LIVE_CHAT]: false,
  [AccountTargetPermissionsNames.MAIL_CHIMP_INTEGRATION]: false,
  [AccountTargetPermissionsNames.MESSAGE_TAGS_MIGRATION_TO_PRO_LIVECHAT_INPUT]: false,
  [AccountTargetPermissionsNames.PAID_MESSAGES]: false,
  [AccountTargetPermissionsNames.PAYPAL_INTEGRATION]: false,
  [AccountTargetPermissionsNames.SALESFORCE_INTEGRATION]: false,
  [AccountTargetPermissionsNames.SMS]: false,
  [AccountTargetPermissionsNames.STRIPE_INTEGRATION]: false,
  [AccountTargetPermissionsNames.SUBSCRIBER_LIMIT_200_BANNER]: false,
  [AccountTargetPermissionsNames.YOKASSA_INTEGRATION]: false,
  [AccountTargetPermissionsNames.FLODESK_INTEGRATION]: false,
}

import { api } from 'shared/api'

import { ProfileScopedApiSchemas } from './schemas'

export const ProfileScopedApi = {
  generate: api.base.createPost({
    url: '/user_api/token/generate',
    schemas: ProfileScopedApiSchemas.generate,
  }),

  delete: api.base.createPost({
    url: '/user_api/token/delete',
    schemas: ProfileScopedApiSchemas.delete,
  }),
}

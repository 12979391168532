import { ChannelType } from 'common/core/constants/ChannelType'
import { analyticsService } from 'utils/services/analytics'
import errorTrackingService from 'utils/services/errorTrackingService'

const SIDEBAR = 'sidebar'

export const formatUpgradeSource = (source = '') => {
  if (!source || typeof source !== 'string') {
    return ''
  }

  if (source.toLowerCase().includes(ChannelType.SMS)) {
    return ChannelType.SMS
  }

  if (source.toLowerCase().includes(ChannelType.EMAIL)) {
    return ChannelType.EMAIL
  }
  return source
}

export const isSourceUsedForContext = (source: string) => {
  const sources = [ChannelType.SMS, ChannelType.EMAIL, SIDEBAR]

  return sources.includes(source)
}

export const sendPostUpgradeAnalytics = (source: string) => {
  try {
    const formattedSource = formatUpgradeSource(source)
    const isUsedForContext = isSourceUsedForContext(formattedSource)
    const context = `${window.location.pathname}${window.location.hash}${
      isUsedForContext ? `/${formattedSource}` : ''
    }`
    analyticsService.sendEvent('SESSION_QUALITY.CLICK.UPGRADE3', { context })
  } catch (err) {
    errorTrackingService.trackError(err, {
      extra: { source: 'billing actions, upgradeEventUtils' },
      fingerprint: 'sendPostUpgradeAnalytics',
    })
  }
}

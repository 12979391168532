import { Icon, l } from '@manychat/manyui'

import {
  IntegrationType,
  IntegrationNameByType,
} from 'common/actions/integrations/common/constants/IntegrationType'
import {
  createIntegration,
  createIntegrationAction,
} from 'common/actions/integrations/common/model/helpers'
import * as klaviyoActions from 'common/actions/integrations/Klaviyo/actions/klaviyoIntegrationActions'
import { KnowledgeBaseLink } from 'common/core/components/SupportLinks'
import { AccountTargetPermissionsNames } from 'common/core/models/Account/constants'
import { SUF } from 'common/fields/constants/FieldType'
import { FilterField } from 'common/filter/models/AudienceFilter/constants'

export const KlaviyoActionTypes = {
  ADD_CONTACT: 'subscribers.add_to_list',
}

export const KlaviyoFields = {
  FIRST_NAME: '$first_name',
  LAST_NAME: '$last_name',
  PHONE_NUMBER: '$phone_number',
  TIMEZONE: '$timezone',
}

export const KlaviyoDefaultSelectedFields = [
  {
    key: KlaviyoFields.FIRST_NAME,
    manyChatField: {
      value: FilterField.FIRST_NAME,
      segment_type: SUF,
    },
  },
  {
    key: KlaviyoFields.LAST_NAME,
    manyChatField: {
      value: FilterField.LAST_NAME,
      segment_type: SUF,
    },
  },
  {
    key: KlaviyoFields.PHONE_NUMBER,
    manyChatField: {
      value: FilterField.PHONE,
      segment_type: SUF,
    },
  },
  {
    key: KlaviyoFields.TIMEZONE,
    manyChatField: {
      value: FilterField.TIMEZONE,
      segment_type: SUF,
    },
  },
]

export const KlaviyoConfig = createIntegration({
  type: IntegrationType.KLAVIYO,
  name: IntegrationNameByType[IntegrationType.KLAVIYO],
  icon: Icon.Klaviyo,
  permissionsVariable: AccountTargetPermissionsNames.KLAVIYO_INTEGRATION,
  isBeta: true,
  description: () =>
    l.translate(
      'The integration provides you with an ability to save customers data from Manychat to Klaviyo. Create a contact if it doesn’t exist in Klaviyo already, or update it with Contact’s Custom Fields if it does. <a href="{link}" target="_blank" class="text-primary pointer" rel="noreferrer" >Learn more</a>',
      { link: KnowledgeBaseLink.KLAVIYO_INTEGRATION },
    ),
  reduxActions: klaviyoActions,
  actions: [
    createIntegrationAction({
      type: KlaviyoActionTypes.ADD_CONTACT,
      description: l.translate(
        "Create a new contact if it doesn't already exist in Klaviyo or update an existing one with contacts' Custom Fields. An existing contact will be identified by the email address.",
      ),
      name: 'Create or update a Contact in a list',
    }),
  ],
})

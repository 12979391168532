import React from 'react'
import cx from 'classnames'

import FormFieldGroup from '../FormField'

import { RadioGroupProps } from './interfaces'
import RadioContext from './RadioGroup-Context'

import cm from './RadioGroup.module.css'

/**
 * RadioGroup component is a wrapper for RadioV2 components which can be used to group them together.
 *
 *  The RadioGroup component automatically expands to 100% of the width of its parent element. By adjusting the width of the parent component, you can effectively control the width of the RadioGroup.
 *
 *  **Notice that RadioV2 should not be used without RadioGroup component**.
 *
 * [Figma](https://www.figma.com/file/Xf38PdCKeISWnBoh0pLFIIWf/%F0%9F%A7%B1-MC-Desktop-Components?node-id=27021%3A18846)
 */

export const RadioGroup = ({
  children,
  invalid = false,
  disabled = false,
  value = '',
  title,
  orientation = 'vertical',
  onChange,
  name,
  ...props
}: RadioGroupProps) => {
  const handleChange = React.useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      if (disabled) {
        return
      } else if (onChange) {
        onChange(event.target.value)
      }
    },
    [onChange, disabled],
  )

  const radioGroupClasses = cx(cm.radioGroup, {
    [cm.horizontal]: orientation === 'horizontal',
    [cm.vertical]: orientation === 'vertical',
  })

  const radioContextValue = React.useMemo(
    () => ({
      radioGroupValue: value,
      radioGroupInvalidState: invalid,
      onChange: handleChange,
      radioGroupDisabledState: disabled,
      radioGroupName: name,
    }),
    [value, invalid, handleChange, disabled, name],
  )

  return (
    <RadioContext.Provider value={radioContextValue}>
      <FormFieldGroup
        id={props.id}
        title={title}
        tabIndex={props.tabIndex}
        className={cx(cm.formFieldWidth, props.className)}
      >
        <fieldset
          name={name}
          form={props.form}
          className={cx(cm.fieldSet)}
          role="radiogroup"
          aria-labelledby={props.ariaLabelledBy}
          aria-disabled={disabled}
          disabled={disabled}
          onBlur={props.onBlur}
          onFocus={props.onFocus}
        >
          <legend title={title} className={cx(cm.legend)} />
          <div className={radioGroupClasses}>{children}</div>
        </fieldset>
      </FormFieldGroup>
    </RadioContext.Provider>
  )
}

import React, { useCallback, useEffect, useMemo, useRef } from 'react'
import cx from 'classnames'
import { v4 as uuid } from 'uuid'
import * as Icon from '@manychat/icons'

import { mergeRefs } from '../../../utils/mergeRefs'
import { FormTextField } from '../FormField/FormTextField'

import { CloseIcon } from './icons/CloseIcon'
import { SearchInputProps } from './interfaces'

import cm from './SearchInput.module.css'

/**
 * The SearchInput component.
 *
 * [Figma](https://www.figma.com/design/Xf38PdCKeISWnBoh0pLFIIWf/%F0%9F%A7%B1-MC-Desktop-Components?node-id=18669-48763&m=dev)
 */
export const SearchInput = ({
  id: outerId,
  inputRef: forwardedRef,
  disabled = false,
  invalid = false,
  value,
  onChange,
  onFocus,
  onBlur,
  className,
  searchIconLabel,
  defaultValue,
  clearButtonLabel,
  onClear,
  lozenge,
  variant,
  ...props
}: SearchInputProps) => {
  const id = useMemo(() => outerId || `${uuid()}-inputSearch`, [outerId])
  const internalRef = useRef<HTMLInputElement>(null)
  const inputRef = mergeRefs<HTMLInputElement>([forwardedRef, internalRef])

  const handleClear = useCallback(() => {
    if (disabled) {
      return
    }
    if (onClear) {
      onClear()
    }
    if (onChange !== undefined) {
      const inputTarget = Object.assign({}, internalRef?.current, { value: '' })
      const clearedEvt = { target: inputTarget, type: 'change' } as const

      onChange(clearedEvt as React.ChangeEvent<HTMLInputElement>)
    }
  }, [disabled, onClear, onChange, internalRef])

  const handleKeyDown = useCallback(
    (event: KeyboardEvent) => {
      if (event.key === 'Escape') {
        handleClear()
      }
    },
    [handleClear],
  )

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (disabled) {
      return
    }
    if (onChange) {
      onChange(event)
    }
  }

  useEffect(() => {
    const inputElement = internalRef?.current
    if (inputElement) {
      inputElement.addEventListener('keydown', handleKeyDown)
    }

    return () => {
      if (inputElement) {
        inputElement.removeEventListener('keydown', handleKeyDown)
      }
    }
  }, [handleKeyDown, internalRef])

  const isClearButtonVisible = !disabled && value

  return (
    <FormTextField
      {...props}
      id={id}
      role="searchbox"
      type="search"
      ref={inputRef}
      value={value}
      variant={variant}
      onChange={handleChange}
      disabled={disabled}
      invalid={invalid}
      defaultValue={defaultValue}
      lozenge={lozenge}
      className={className}
      onFocus={onFocus}
      onBlur={onBlur}
      fieldClasses={{ input: cm.searchInput, label: 'text-sm' }}
    >
      <span className={cm['icon-container']}>
        <Icon.Search size={20} className={cm.icon} aria-label={searchIconLabel} />
      </span>
      {isClearButtonVisible && (
        <button
          aria-label={clearButtonLabel}
          onClick={handleClear}
          className={cx(cm['icon-button'], cm.button, (!value || disabled) && cm.hidden)}
        >
          <CloseIcon />
        </button>
      )}
    </FormTextField>
  )
}

export const mapResponse = <RequestArgs, RequestResponse, Result>(
  request: (arg: RequestArgs) => RequestResponse,
  mapper: (responseData: Awaited<RequestResponse>) => Result,
) => {
  return async (arg: RequestArgs) => {
    const response = await request(arg)

    return mapper(response)
  }
}

import React, { useState } from 'react'
import cx from 'classnames'
import { useAppDispatch, useAppSelector } from 'reduxTyped'
import { Popover, BtnV2, Icon, l, palettes, TextInputV2, LinkBtn } from '@manychat/manyui'

import * as profileActions from 'apps/profile/actions/profileTypedActions'
import { TelegramQrModal } from 'apps/telegram/components/TelegramQrModal/TelegramQrModal'
import { useTelegramQrModal } from 'apps/telegram/components/TelegramQrModal/useTelegramQrModal'
import { useTelegramSignIn } from 'apps/telegram/hooks/useTelegramSignIn'
import { alert } from 'common/core'
import EmailVerificationModal from 'common/core/components/EmailVerification/EmailVerificationModal'
import { getUser } from 'common/core/selectors/appSelectors'
import {
  getAppleProfile,
  getFBProfile,
  getGoogleProfile,
  getTelegramProfile,
} from 'common/profiles/profileSelectors'
import { CircleAvatar } from 'components/CircleAvatar'
import { ExternalPaths, RoutePaths } from 'utils/router/constants'

import ManageProfilesModal from '../ManageProfilesModal/'

import cm from './MyProfilePopover.module.css'

interface IProps {
  onRequestClose: () => void
  anchor: HTMLElement | null
  open: boolean
}

const MyProfilePopover = ({ onRequestClose, anchor, open }: IProps) => {
  const {
    email,
    full_name,
    can_resend_email_verification_code,
    email_unverified,
    is_verification_code_sent,
    avatar_large,
    isVerifyingEmail,
    has_pages_dashboards,
  } = useAppSelector(getUser)

  const [isVerificationModalOpen, setIsVerificationModalOpen] = useState(false)

  const [currentEmail, setCurrentEmail] = useState(email)
  const [isChanging, setIsChanging] = useState(false)

  const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setCurrentEmail(e.target.value)
  }

  const dispatch = useAppDispatch()

  const handleSubmit = async (e: React.ChangeEvent<HTMLFormElement>) => {
    e.preventDefault()
    if (isChanging && currentEmail) {
      try {
        await dispatch(profileActions.sendEmailVerification(currentEmail))

        setIsChanging(false)
        setIsVerificationModalOpen(true)
      } catch (err) {}
    } else {
      setIsChanging(true)
      setCurrentEmail('')
    }
  }

  const handleSendLink = () => {
    if (!can_resend_email_verification_code) {
      return alert(
        l.translate('Too many confirmation requests. Please try again in 24 hours'),
        'danger',
      )
    }

    if (!email_unverified) return
    dispatch(profileActions.sendEmailVerification(email_unverified))

    setIsVerificationModalOpen(true)
  }

  const handleCancelClick = () => setIsChanging(false)
  const unverified = Boolean(email_unverified)

  const fbProfile = useAppSelector(getFBProfile)
  const googleProfile = useAppSelector(getGoogleProfile)
  const appleProfile = useAppSelector(getAppleProfile)
  const telegramProfile = useAppSelector(getTelegramProfile)

  const { hasProfile: hasFBProfile } = fbProfile
  const { hasProfile: hasGoogleProfile } = googleProfile
  const { hasProfile: hasAppleProfile } = appleProfile
  const { hasProfile: hasTelegramProfile } = telegramProfile

  const [isManageProfilesModalOpen, setIsManageProfilesModalOpen] = useState<boolean>(false)

  const profileEmail = isChanging ? currentEmail : email_unverified || email

  const { telegramUrl } = useTelegramSignIn(RoutePaths.HOMETAB)
  const { isTelegramQrModalVisible, closeModal } = useTelegramQrModal()

  return (
    <>
      <EmailVerificationModal
        open={isVerificationModalOpen}
        onClose={() => setIsVerificationModalOpen(false)}
      />
      <TelegramQrModal
        isVisible={isTelegramQrModalVisible}
        onClose={closeModal}
        url={telegramUrl}
        origin={'PROFILE'}
      />
      <Popover
        anchorOrigin="right center"
        popoverOrigin="left 90%"
        arrow="right"
        open={open && anchor != null}
        anchor={anchor}
        onRequestClose={onRequestClose}
      >
        <div className={'p-l p-y-xs b-b text-subheading d-flex justify-between align-center'}>
          <span>
            {has_pages_dashboards ? l.translate('My Profile') : l.translate('My Account')}
          </span>
          <LinkBtn
            data-test-id="profile-logout-link"
            href={ExternalPaths.LOGOUT}
            leftIcon="Logout"
            size="large"
          >
            {l.translate('Log out')}
          </LinkBtn>
        </div>
        <div className={cx(cm.wrapper, 'p-a-md')}>
          <CircleAvatar className="m-y-2 m-auto" size={80} url={avatar_large} />

          <TextInputV2 label={l.translate('Name')} className="m-b" value={full_name} disabled />

          <div data-test-id="verified-status-title">
            <span className="text-sm">{l.translate('Email')} </span>
            {!isChanging && (
              <span className={`text-sm ${unverified ? 'text-warning' : 'text-success'}`}>
                ({unverified ? l.translate('Not verified') : l.translate('Verified')})
              </span>
            )}
          </div>
          <form onSubmit={handleSubmit} className={'d-flex m-t-xs'}>
            <TextInputV2
              ariaLabel={l.translate('Profile email')}
              value={profileEmail || ''}
              disabled={!isChanging}
              data-test-id="profile-email-input"
              onChange={handleEmailChange}
            />

            {isChanging ? (
              <BtnV2
                variant="primary"
                type="submit"
                className={cx(cm.btn, 'm-l-xs')}
                disabled={!email?.trim() || isVerifyingEmail}
                data-test-id="profile-save-btn"
                loading={isVerifyingEmail}
              >
                {l.translate('Save')}
              </BtnV2>
            ) : (
              <BtnV2
                variant="primary"
                type="submit"
                data-test-id="profile-change-btn"
                className={cx(cm.btn, 'm-l-xs')}
              >
                {l.translate('Edit')}
              </BtnV2>
            )}
          </form>

          {isChanging && (
            <div className={'text-secondary m-t'}>
              <span>
                {l.translate('Enter your email and press Save')}
                <br />
                <BtnV2
                  data-test-id="profile-cancel-link"
                  onClick={handleCancelClick}
                  variant="ghost-primary"
                  className="m-t-xs"
                >
                  {l.translate('Cancel')}
                </BtnV2>
              </span>
            </div>
          )}

          <div
            data-test-id="verified-status-footer"
            className={cx('text-secondary', { 'm-t': unverified })}
          >
            {!isChanging && unverified && (
              <>
                {is_verification_code_sent ? (
                  <span>
                    {l.translate('Follow the verification link in your inbox')}
                    <br />
                    {isVerifyingEmail ? (
                      <span>{l.translate('Sending...')}</span>
                    ) : (
                      <a
                        data-test-id="profile-resend-link"
                        onClick={handleSendLink}
                        className={'text-primary'}
                      >
                        {l.translate('Resend Link')}
                      </a>
                    )}
                  </span>
                ) : (
                  <span>
                    {l.translate('Your email is not verified.')}{' '}
                    {isVerifyingEmail ? (
                      <span>{l.translate('Sending...')}</span>
                    ) : (
                      <a
                        data-test-id="profile-send-link"
                        onClick={handleSendLink}
                        className={'text-primary'}
                      >
                        {l.translate('Send Verification Link')}
                      </a>
                    )}
                  </span>
                )}
              </>
            )}
          </div>

          <div className="align-center b-t d-flex flex-col m-t-lg p-t-md">
            <div>
              <Icon.Facebook
                className={cx(cm.icon, { [cm.disabled]: !hasFBProfile })}
                data-test-id="icon-fb-profile"
                color={palettes.semantic.primary}
              />
              <Icon.Google
                className={cx(cm.icon, { [cm.disabled]: !hasGoogleProfile })}
                data-test-id="icon-google-profile"
              />
              <Icon.Apple
                className={cx(cm.icon, { [cm.disabled]: !hasAppleProfile })}
                data-test-id="icon-apple-profile"
              />
              <Icon.Telegram
                className={cx(cm.icon, { [cm.disabled]: !hasTelegramProfile })}
                data-test-id="icon-telegram-profile"
                color="#0088CC"
              />
            </div>
            <BtnV2
              className="m-t"
              onClick={() => setIsManageProfilesModalOpen(true)}
              data-test-id="manage-signin-link"
              variant="ghost-primary"
            >
              {l.translate('Manage sign-in options')}
            </BtnV2>

            <ManageProfilesModal
              handleClose={() => setIsManageProfilesModalOpen(false)}
              isOpen={isManageProfilesModalOpen}
            />
          </div>
        </div>
      </Popover>
    </>
  )
}

export default MyProfilePopover

import { Dispatch } from 'redux'
import { l } from '@manychat/manyui'

import externalTriggerAdapter from 'apps/externalTriggers/externalTriggerAdapter'
import { ExternalTriggerStatusType } from 'apps/externalTriggers/externalTriggerConstants'
import { IBackendExternalTrigger } from 'apps/externalTriggers/externalTriggerInterfaces'
import * as externalTriggersSelectors from 'apps/externalTriggers/externalTriggersSelectors'
import { alert } from 'common/core'
import {
  IAsyncThunkActionWithReturnValue,
  IThunkAction,
  IAsyncThunkAction,
} from 'common/core/interfaces/actions'
import { TriggerType } from 'common/flow/constants/TriggerType'
import { makeItemsDetailsActions } from 'utils/factory'
import { analyticsService } from 'utils/services/analytics'
import { anotherTabNotificationsListener } from 'utils/services/notificationsService'

import * as atypes from './externalTriggersReduxActionTypes'

const itemDetailsActions = makeItemsDetailsActions('externalTriggers')
export const createExternalTrigger = itemDetailsActions.create
export const fetchExternalTrigger = itemDetailsActions.fetch
export const updateExternalTrigger = itemDetailsActions.update
export const initExternalTrigger = itemDetailsActions.init

export const saveExternalTrigger = (externalTriggerId: number): IAsyncThunkAction<boolean> => {
  return async (dispatch, getState) => {
    const externalTrigger = externalTriggersSelectors.getItemById(getState(), externalTriggerId)

    try {
      await dispatch({
        type: atypes.EXTERNALTRIGGERS_CURRENT_SAVE,
        id: externalTriggerId,
        $endpoint: [
          `externalTriggers.save`,
          null,
          { data: externalTrigger.data, external_trigger_id: externalTriggerId },
        ],
      })

      return true
    } catch (error) {
      return false
    }
  }
}

export const deleteExternalTrigger = (externalTriggerId: number): IThunkAction => {
  return (dispatch) => {
    dispatch({
      type: atypes.EXTERNALTRIGGERS_DELETE,
      id: externalTriggerId,
      $endpoint: [`externalTriggers.delete`, null, { external_trigger_id: externalTriggerId }],
    })
  }
}

export const setExternalTriggerStatusAndSave = (
  externalTriggerId: number,
  status: ExternalTriggerStatusType,
  options: { source?: string } = {},
): IAsyncThunkActionWithReturnValue<boolean> => {
  return async (dispatch, getState) => {
    const hasChanges = externalTriggersSelectors.hasChanges(getState(), externalTriggerId)

    analyticsService.sendEvent('EXTERNAL_TRIGGER.ENABLE.CLICK', { status, source: options.source })

    if (hasChanges) {
      await dispatch(saveExternalTrigger(externalTriggerId))
    }

    return dispatch(setExternalTriggerStatus(externalTriggerId, status))
  }
}

export const setExternalTriggerStatus = (
  externalTriggerId: number,
  status: ExternalTriggerStatusType,
  options: { doNotLogStatusChange?: boolean; silent?: boolean } = {},
): IAsyncThunkAction<boolean> => {
  return async (dispatch) => {
    try {
      await dispatch({
        type: atypes.EXTERNALTRIGGERS_CHANGE_STATUS,
        id: externalTriggerId,
        $endpoint: [
          `externalTriggers.changeStatus`,
          null,
          { external_trigger_id: externalTriggerId, status },
        ],
        $error: options.silent ? () => {} : undefined,
      })

      if (!options.doNotLogStatusChange) {
        analyticsService.sendEvent('TRIGGER.STATUS.SWITCHED', {
          isEnabled: status === ExternalTriggerStatusType.ACTIVE,
          triggerId: `${TriggerType.EXTERNAL_TRIGGER}-${externalTriggerId}`,
        })
      }

      return true
    } catch (error) {
      return false
    }
  }
}

export const setExternalTriggerTitle = (externalTriggerId: number, title: string): IThunkAction => {
  return async (dispatch) => {
    const data = await dispatch(
      // eslint-disable-next-line
      // @ts-expect-error
      itemDetailsActions.setTitle(externalTriggerId, {
        external_trigger_id: externalTriggerId,
        title,
      }),
    )

    alert(l.translate('Renamed!'), 'success')

    return data
  }
}

export const externalTriggerCreatedNotification = (data: IBackendExternalTrigger) => {
  const item = externalTriggerAdapter(data)
  return {
    type: atypes.EXTERNALTRIGGERS_CREATED_NOTIFICATION,
    item,
  }
}

export const externalTriggerDeletedNotification = (id: string) => {
  return { type: atypes.EXTERNALTRIGGERS_DELETED_NOTIFICATION, id }
}

export const externalTriggerUpdateNotification = (data: UnsafeAnyObject) =>
  itemDetailsActions.updatedNotification(data)

export const externalTriggerChangeStatusNotification = (data: UnsafeAnyObject) =>
  itemDetailsActions.updatedNotification(data)

export const externalTriggerSetTitleNotification = (data: UnsafeAnyObject) =>
  itemDetailsActions.updatedNotification(data)

anotherTabNotificationsListener.on(
  'external_trigger_created',
  (data: UnsafeAnyObject, dispatch: Dispatch) => {
    dispatch(externalTriggerCreatedNotification(data.model))
  },
)
anotherTabNotificationsListener.on(
  'external_trigger_deleted',
  (data: UnsafeAnyObject, dispatch: Dispatch) => {
    dispatch(externalTriggerDeletedNotification(data.model.external_trigger_id))
  },
)
anotherTabNotificationsListener.on(
  'external_trigger_title_set',
  (data: UnsafeAnyObject, dispatch: Dispatch) => {
    dispatch(externalTriggerSetTitleNotification({ setTitle: true, ...data.model }))
  },
)
anotherTabNotificationsListener.on(
  'external_trigger_status_updated',
  (data: UnsafeAnyObject, dispatch: Dispatch) => {
    dispatch(externalTriggerChangeStatusNotification(data.model))
  },
)
anotherTabNotificationsListener.on(
  'external_trigger_updated',
  (data: UnsafeAnyObject, dispatch: Dispatch) => {
    dispatch(externalTriggerUpdateNotification(data.model))
  },
)

import React from 'react'

const Footer = ({ children, className }: { className: string; children?: React.ReactNode }) => {
  return (
    <div className={`d-flex flex-shrink-0 p-x-md p-y align-center b-t ${className || ''}`}>
      {children}
    </div>
  )
}

export default Footer

import React, { useCallback, useState } from 'react'
import { useAppSelector, useAppDispatch } from 'reduxTyped'
import { l, SearchInput } from '@manychat/manyui'

import { ClearButton } from 'apps/cms/components/Flows/components/FlowFilters/components/ClearButton/ClearButton'
import { FilterPicker } from 'apps/cms/components/Flows/components/FlowFilters/components/FilterPicker/FilterPicker'
import { Option } from 'apps/cms/components/Flows/components/FlowFilters/components/FilterPicker/lib/types'
import { StatusPicker } from 'apps/cms/components/Flows/components/FlowFilters/components/StatusPicker/StatusPicker'
import { FlowFiltersSkeleton } from 'apps/cms/components/Flows/components/FlowFilters/FlowFiltersSkeleton'
import { useFlowSearch } from 'apps/cms/components/Flows/components/FlowFilters/hooks/useFlowSearch'
import { contentManagementSelectors, contentManagementActions } from 'apps/cms/store'
import { useDidMountEffect } from 'utils/commonHooks'

import cm from './FlowFilters.module.css'

export const FlowFilters = React.memo(function FlowFilters() {
  const dispatch = useAppDispatch()

  const [selectedOptions, setSelectedOptions] = useState<Option[]>([])
  const isSearchMode = useAppSelector(contentManagementSelectors.getIsSearchMode)
  const { searchInput, handleChangeSearch, handleResetSearch } = useFlowSearch()
  const { isLoaded, triggerTypeFilters, triggerStatusFilters } = useAppSelector(
    contentManagementSelectors.getFilters,
  )
  const { focusSearchOnMount } = useAppSelector(contentManagementSelectors.getUi)

  const clearFilters = useCallback(() => {
    handleResetSearch()
    setSelectedOptions([])
  }, [handleResetSearch])

  useDidMountEffect(() => {
    dispatch(contentManagementActions.fetchFilters())
  })

  return (
    <div className={cm.wrapper}>
      <div className={cm.filters}>
        {isLoaded && (
          <>
            <div className={cm.searchInput}>
              <SearchInput
                value={searchInput}
                placeholder={l.translate('Search all Automations')}
                ariaLabel={l.translate('Search all Automations')}
                onChange={handleChangeSearch}
                onClear={handleResetSearch}
                autoFocus={focusSearchOnMount}
                data-test-id="cms-automations-search-input"
              />
            </div>

            {triggerTypeFilters.length > 0 && (
              <FilterPicker
                selectedOptions={selectedOptions}
                setSelectedOptions={setSelectedOptions}
              />
            )}

            <div className={cm.statusAndClear}>
              {triggerStatusFilters.length > 0 && <StatusPicker />}

              {isSearchMode && <ClearButton onClear={clearFilters} />}
            </div>
          </>
        )}

        {!isLoaded && <FlowFiltersSkeleton />}
      </div>
    </div>
  )
})

import React from 'react'
import uniq from 'lodash/uniq'
import { l, tints } from '@manychat/manyui'

import {
  UserFieldsNumberOperations,
  UserFieldsDateOperationsSet,
  UserFieldsDateTimeOperationsSet,
} from 'common/fields/constants/UserFieldsTypes'

import { parseActionItem } from './adapter'
import {
  ActionTypeNames,
  ActionTypeIcons,
  NotifyAdminsSendChannels,
  NotifyAdminsChannelsNames,
} from './constants'
import { getNotifyAllByChannels } from './notifyAdminHelpers'

export const getActionTypeName = (type, names = ActionTypeNames) => {
  return l.getString(names[type], l.translate('Unknown Action'))
}

export const getActionName = (action, options = {}) => {
  if (!options.raw) {
    action = parseActionItem(action)
  }

  if (action.app_id) {
    return (
      options.installedApplications?.find((app) => app.app_id === action.app_id)?.title ??
      l.translate('App not installed')
    )
  }

  return getActionTypeName(action.type)
}

export const getActionIcon = (action, options = {}) => {
  if (!options.raw) {
    action = parseActionItem(action)
  }

  const { type } = action
  if (type === 'apps_request' && options.installedApplications) {
    const avatarUrl = options.installedApplications.find((app) => app.app_id === action.app_id)
      ?.avatar?.url
    if (avatarUrl) {
      return (
        <img
          src={avatarUrl}
          alt="App Avatar"
          className="m-r-xxs rounded-circle"
          style={{ width: 20, height: 20 }}
        />
      )
    }
  }

  const ActionIcon = ActionTypeIcons[type]
  return ActionIcon ? <ActionIcon size={20} color={tints.yellow[3]} className={'m-r-xs'} /> : null
}

const operationMap = new Map([
  [UserFieldsNumberOperations.SET, l.makeTranslate('Set')],
  [UserFieldsNumberOperations.CUSTOM, l.makeTranslate('Set')],
  [UserFieldsNumberOperations.INCREASE, l.makeTranslate('Increase')],
  [UserFieldsNumberOperations.DECREASE, l.makeTranslate('Decrease')],
])

export const getCustomEventsActionLabels = (action) => {
  const verb = l.getString(operationMap.get(action.operation))
  return [verb, l.translate('to')]
}

export const getCUFActionLabels = (action) => {
  const verb = l.getString(operationMap.get(action.operation))
  const actionsWithToPreposition = [
    UserFieldsNumberOperations.SET,
    UserFieldsNumberOperations.CUSTOM,
    UserFieldsDateOperationsSet.SET_DATE_OF_THE_ACTION,
    UserFieldsDateOperationsSet.SET_TO_DATE_CUF,
    UserFieldsDateTimeOperationsSet.SET_DATE_TIME_OF_THE_ACTION,
    UserFieldsDateTimeOperationsSet.SET_TO_DATE_TIME_CUF,
  ]

  const isFromArrayWithToPreposition = actionsWithToPreposition.includes(action.operation)

  const preposition = isFromArrayWithToPreposition ? l.translate('to') : l.translate('by')
  return [verb, preposition]
}

export const getNotifyAdminsLabels = (action, { admins, emailInsteadOfMessengerEnabled }) => {
  const sendToAllBy = action.all_send_by || []
  const sendTo = action.send_to || []

  const sendToCount = sendToAllBy.length ? admins.length : sendTo.length

  const adminLabel = l.translate('{n, plural, one {# person} other {# people}}', { n: sendToCount })

  const {
    isEmailNotificationsSelected,
    isMessengerNotificationsSelected,
    isSMSNotificationsSelected,
    isTelegramNotificationsSelected,
  } = getNotifyAllByChannels(action, { emailInsteadOfMessengerEnabled })

  const sendBy = []
  if (isMessengerNotificationsSelected) {
    sendBy.push(NotifyAdminsSendChannels.MESSENGER)
  }
  if (isSMSNotificationsSelected) {
    sendBy.push(NotifyAdminsSendChannels.SMS)
  }
  if (isEmailNotificationsSelected) {
    sendBy.push(NotifyAdminsSendChannels.EMAIL)
  }
  if (isTelegramNotificationsSelected) {
    sendBy.push(NotifyAdminsSendChannels.TELEGRAM)
  }

  const channelLabel = uniq(sendBy)
    .map((c) => NotifyAdminsChannelsNames[c])
    .join(' & ')

  return [adminLabel, channelLabel]
}

import { l } from '@manychat/manyui'

import * as atypes from 'apps/agency/constants/AgencyReduxActionTypes'
import { exportAgency } from 'apps/agency/models/Agency/adapter'
import { AGENCY_FIELDS } from 'apps/agency/models/Agency/constants'
import { alert } from 'common/core'
import * as API from 'constants/API'

const { endpoints } = API.agency_signon

export const fetch = () => {
  return async (dispatch, getState) => {
    const response = await dispatch({
      type: atypes.AGENCY_SIGNON_FETCH,
      $fetch: endpoints.fetch,
      $error: () => {},
      user: getState().app.user,
    })
    return response
  }
}

export function create() {
  return (dispatch, getState) => {
    return dispatch({
      type: atypes.AGENCY_SIGNON_CREATE,
      $fetch: [
        endpoints.create,
        {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
        },
      ],
      user: getState().app.user,
    })
  }
}

export function updateProfile(fields) {
  return async (dispatch, getState) => {
    return dispatch({
      type: atypes.AGENCY_SIGNON_UPDATE,
      $fetch: [
        endpoints.update,
        {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify(exportAgency(fields)),
        },
      ],

      $success: () => {
        alert(l.translate('Changes were saved'), 'success')
      },
      $error: () => {},
      user: getState().app.user,
    })
  }
}

export function updateFields(fields) {
  return {
    type: atypes.AGENCY_SIGNON_FIELDS_UPDATE,
    data: fields,
  }
}

export function uploadLogo(logo) {
  return (dispatch, getState) => {
    const formData = new FormData()

    formData.append(AGENCY_FIELDS.id, getState().agency.signon[AGENCY_FIELDS.id])
    formData.append('logo', logo)

    return dispatch({
      type: atypes.AGENCY_SIGNON_UPLOAD_LOGO,
      $fetch: [endpoints.uploadLogo, { method: 'POST', body: formData }],
    })
  }
}

export function sendEmail(id, type) {
  return async (dispatch) => {
    // const agencyId = getState().agency.signon[FIELDS.id]
    return dispatch({
      type: atypes.AGENCY_SIGNON_SEND_EMAIL,
      $fetch: [
        endpoints.sendEmail,
        {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({
            [AGENCY_FIELDS.id]: id,
            email_type: type,
          }),
        },
      ],
      $success: (email_type) => {
        email_type === 'application'
          ? alert(l.translate('Well! Agency questionnaire is filled.'), 'success')
          : () => {}
      },
      $error: () => {},
    })
  }
}

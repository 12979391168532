import * as atypes from 'common/actions/integrations/common/constants/IntegrationsReduxActionTypes'
import * as API from 'constants/API'

const endpoints = API.integrations.endpoints.convertkit

export const setAccountLinkField = ({ field_id, field_name }) => ({
  type: atypes.CONVERTKIT_SET_ACCOUNT_LINK_FIELD,
  $fetch: [
    endpoints.setAccountLinkField,
    {
      method: 'POST',
      body: JSON.stringify({
        field_id,
        field_name,
      }),
      headers: { 'Content-Type': 'application/json' },
    },
  ],
})

export const clearAccountLinkField = () => ({
  type: atypes.CONVERTKIT_CLEAR_ACCOUNT_LINK_FIELD,
  $fetch: endpoints.clearAccountLinkField,
})

export const fetchForms = () => ({
  $fetch: endpoints.getForms,
  type: atypes.CONVERTKIT_FORMS_FETCH,
})

export const fetchSequences = () => ({
  $fetch: endpoints.getSequences,
  type: atypes.CONVERTKIT_SEQUENCES_FETCH,
})

export const fetchTags = () => ({
  $fetch: endpoints.getTags,
  type: atypes.CONVERTKIT_TAGS_FETCH,
})

export const fetchConvertKitFields = () => ({
  $fetch: endpoints.getFields,
  type: atypes.CONVERTKIT_FIELDS_FETCH,
})

import React, { createContext } from 'react'
import { useAppSelector } from 'reduxTyped'

import { getUser, getCurrentAccount } from 'common/core/selectors/appSelectors'

import { ReleaseToggles } from './releaseTogglesTypes'

const defaultValue: ReleaseToggles = {
  user: {},
  account: {},
}

export const ReleaseTogglesContext = createContext(defaultValue)

export const ReleaseTogglesContextProvider = ({ children }: { children: React.ReactNode }) => {
  const toggles: ReleaseToggles = {
    user: useAppSelector(getUser).flags,
    account: useAppSelector(getCurrentAccount)?.flags || {},
  }

  return <ReleaseTogglesContext.Provider value={toggles}>{children}</ReleaseTogglesContext.Provider>
}

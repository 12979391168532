import { createSlice, isAnyOf, isRejected, combineReducers } from '@reduxjs/toolkit'

import { WalletType } from 'common/billing/wallet/interfaces/walletType'

import {
  configureAutoRefill,
  fetchTransactions,
  fetchUsageSummary,
  fetchWallet,
  refillWallet,
  setInitialWallet,
  toggleRefillModal,
} from './walletActions'
import { parseWallet, parseAutoRefill } from './walletAdapters'
import { Wallet, WalletNetwork, WalletUI, WalletUsageHistory } from './walletInterfaces'

export const initialWalletState: Readonly<Wallet> = {
  hasWallet: false,
  balance: 0,
  creditBalance: 0,
  walletType: WalletType.DEBIT,
  autoRefill: {
    isEnabled: false,
    refillAmount: 0,
    triggerAmount: 0,
    dailyLimit: 0,
  },
  options: {
    triggerAutorefillAmountOptions: [],
    dailyLimitOptions: [],
    amountOptions: [],
  },
}

const walletSlice = createSlice({
  name: 'wallet',
  initialState: initialWalletState as Wallet,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addMatcher(
        isAnyOf(setInitialWallet.match, fetchWallet.fulfilled.match),
        (state, action) => ({
          ...state,
          ...parseWallet(action.payload || {}),
        }),
      )
      .addMatcher(refillWallet.fulfilled.match, (state, action) => {
        state.balance = action.payload?.balance || 0
      })
      .addMatcher(configureAutoRefill.fulfilled.match, (state, action) => {
        state.autoRefill = parseAutoRefill(action?.payload?.auto_refill)
        state.balance = action.payload?.balance || 0
      })
  },
})

const initialNetworkState: Readonly<WalletNetwork> = {
  isFetching: false,
  fetchError: false,
  isTransactionInProgress: false,
}

const networkSlice = createSlice({
  name: 'network',
  initialState: initialNetworkState as WalletNetwork,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addMatcher(fetchWallet.pending.match, (state) => {
        state.isFetching = true
      })
      .addMatcher(
        isAnyOf(fetchWallet.fulfilled.match, fetchWallet.rejected.match),
        (state, action) => {
          state.isFetching = false
          state.fetchError = isRejected(action)
        },
      )
      .addMatcher(
        isAnyOf(
          configureAutoRefill.rejected.match,
          refillWallet.rejected.match,
          configureAutoRefill.fulfilled.match,
          refillWallet.fulfilled.match,
        ),
        (state) => {
          state.isTransactionInProgress = false
        },
      )
      .addMatcher(
        isAnyOf(configureAutoRefill.pending.match, refillWallet.pending.match),
        (state) => {
          state.isTransactionInProgress = true
        },
      )
  },
})

const initialRefillModal: Readonly<WalletUI> = {
  openModal: null,
  successStep: false,
}

const uiSlice = createSlice({
  name: 'ui',
  initialState: initialRefillModal as WalletUI,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addMatcher(toggleRefillModal.match, (state, action) => {
        state.openModal = action.payload === state.openModal ? null : action.payload
        state.successStep = false
      })
      .addMatcher(
        isAnyOf(configureAutoRefill.fulfilled.match, refillWallet.fulfilled.match),
        (state) => {
          state.successStep = true
        },
      )
  },
})

const initialUsageHistoryState: Readonly<WalletUsageHistory> = {
  summary: [],
  transactions: [],
  hasOlderTransactions: false,
}

const usageHistorySlice = createSlice({
  name: 'usageHistory',
  initialState: initialUsageHistoryState as WalletUsageHistory,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addMatcher(fetchTransactions.fulfilled.match, (state, action) => {
        const isFirstPage = typeof action.meta.arg.lastOperationId !== 'number'
        const nextTransactions = isFirstPage
          ? action.payload.transactions
          : [...state.transactions, ...action.payload.transactions]

        state.transactions = nextTransactions
        state.hasOlderTransactions = action.payload.has_more
      })
      .addMatcher(fetchUsageSummary.fulfilled.match, (state, action) => {
        state.summary = action.payload.summary
      })
  },
})

export default combineReducers({
  wallet: walletSlice.reducer,
  network: networkSlice.reducer,
  ui: uiSlice.reducer,
  usageHistory: usageHistorySlice.reducer,
})

export const walletSlicesList = {
  walletSlice,
  networkSlice,
  uiSlice,
  usageHistorySlice,
}

import cloneDeep from 'lodash/cloneDeep'
import get from 'lodash/get'

import { localStorage } from 'utils'
import { LiveChatHideAutomationKey } from 'apps/chat/constants/LiveChatHideAutomationsKey'
import * as atypes from 'apps/chat/constants/LiveChatReduxActionTypes'
import * as commonATypes from 'common/core/constants/ReduxActionTypes'
import { makeLimitedListReducer, addToList, mergeToListItem } from 'utils/factory'

const getInitialHideAutomation = () => {
  try {
    const rawValue = localStorage.getItem(LiveChatHideAutomationKey)
    if (rawValue) {
      return rawValue === 'true'
    }
  } catch {
    // do nothing and return fallback
  }

  return false
}

const initialState = {
  hideAutomation: getInitialHideAutomation(),
}

const listReducer = makeLimitedListReducer('messages', { initialState })

export default function messagesReducer(state, action) {
  if (action.type === atypes.MESSAGES_CREATED_NOTIFICATION) {
    const { item } = action
    const oid = get(item, 'model._oid', null)
    const items = state.items || []
    const currentItem = items.find((i) => i._oid === oid)
    if (currentItem) {
      return mergeToListItem(state, currentItem.id, { ...item, id: currentItem.id })
    }
  }

  state = listReducer(state, action)

  switch (action.type) {
    case atypes.MESSAGES_SEND_REQUEST:
    case atypes.MESSAGES_SENDSMS_REQUEST:
    case atypes.MESSAGES_SENDWHATSAPPMESSAGE_REQUEST:
    case atypes.MESSAGES_SEND_INSTAGRAM_REQUEST:
    case atypes.MESSAGES_SEND_TELEGRAM_REQUEST:
    case atypes.MESSAGES_SEND_TIKTOK_REQUEST:
    case atypes.MESSAGES_CREATENOTE_REQUEST: {
      const { message } = action
      if (state.byId[message.id]) {
        return mergeToListItem(state, message.id, {
          ...message,
          id: message.id,
          sending: true,
          sendError: false,
        })
      }

      const res = { ...message, sending: true }
      return addToList(state, res.id, res, true)
    }
    case atypes.MESSAGES_SEND_RESPONSE:
    case atypes.MESSAGES_SENDSMS_RESPONSE:
    case atypes.MESSAGES_SENDWHATSAPPMESSAGE_RESPONSE:
    case atypes.MESSAGES_SEND_INSTAGRAM_RESPONSE:
    case atypes.MESSAGES_SEND_TELEGRAM_RESPONSE:
    case atypes.MESSAGES_SEND_TIKTOK_RESPONSE:
    case atypes.MESSAGES_CREATENOTE_RESPONSE: {
      const { messageId, data } = action
      const message = data.items[0]
      return mergeToListItem(state, messageId, { ...message, id: messageId, sending: false })
    }
    case atypes.MESSAGES_DELIVERY_FAILED: {
      const { _mid, error_code } = action
      const items = state.items || []
      let itemIndex = -1
      let messageIndex = -1

      loop: for (let i = 0; i < items.length; i++) {
        const messages = items[i]?.model?.messages || []

        for (let j = 0; j < messages.length; j++) {
          if (messages[j]._mid === _mid) {
            itemIndex = i
            messageIndex = j
            break loop
          }
        }
      }
      // If it is not found then the LC_MESSAGE_FAILED websocket event happened before the lc_sent websocket event
      // So the error_code will be set by the lc_sent event instead
      if (itemIndex === -1) {
        return state
      }
      const newState = cloneDeep(state)
      newState.items[itemIndex].model.messages[messageIndex].error_code = error_code
      return newState
    }
    case atypes.MESSAGES_SEND_ERROR:
    case atypes.MESSAGES_SENDSMS_ERROR:
    case atypes.MESSAGES_SEND_INSTAGRAM_ERROR:
    case atypes.MESSAGES_SEND_TELEGRAM_ERROR:
    case atypes.MESSAGES_CREATENOTE_ERROR:
    case atypes.MESSAGES_SEND_TIKTOK_ERROR:
    case atypes.MESSAGES_SENDWHATSAPPMESSAGE_ERROR: {
      return mergeToListItem(state, action.messageId, { sending: false, sendError: true })
    }
    case atypes.MESSAGES_TOGGLE_HIDE_AUTOMATION: {
      const hideAutomation = !state.hideAutomation
      return { ...state, hideAutomation }
    }

    case commonATypes.ATTACHMENT_UPLOAD_REQUEST: {
      const { message } = action
      if (!message) {
        return state
      }

      const res = { ...message, sending: true }
      return addToList(state, res.id, res, true)
    }
    case commonATypes.ATTACHMENT_UPLOAD_ERROR: {
      const { message } = action
      if (!message) {
        return state
      }

      return mergeToListItem(state, message.id, { sending: false, sendError: true })
    }

    case atypes.MESSAGES_TOGGLE_SCROLL_DISABILITY: {
      return { ...state, scrollDisabled: action.disableScroll }
    }
  }

  return state
}

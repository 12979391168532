import { useCallback, useEffect, useRef, useState } from 'react'
import { useAppDispatch, useAppSelector } from 'reduxTyped'

import { contentManagementSelectors } from 'apps/cms/store'
import { catchSimulatorErrors } from 'apps/flowPicker/helpers/catchSimulatorErrors'
import { FLOW_PICKER_ANALYTICS_EVENT } from 'apps/flowPicker/lib/constants'
import { prepareSimulatorData } from 'apps/quickCampaigns/selectTemplateModal/components/PreviewTemplate/prepareSimulatorData'
import { getCurrentAccount } from 'common/core/selectors/appSelectors'
import { fetchFlowContentData } from 'common/flow/actions/flowActions'
import { IFullFlow } from 'common/flow/flowInterfaces'
import { getById } from 'common/flow/selectors/flowSelectors'
import { analyticsService } from 'utils/services/analytics'

export const useFlowPickerPreview = (lastSelectedFlowId: string) => {
  const dispatch = useAppDispatch()
  const phoneContainerRef = useRef<HTMLDivElement>(null)
  const simulatorRef = useRef<ReturnType<typeof window.MC_FLOWSIMULATOR.createSimulator>>()
  const [isSimulatorThrownError, setIsSimulatorThrownError] = useState(false)

  const cmsFlow = useAppSelector((state) =>
    contentManagementSelectors.getFlowById(state, lastSelectedFlowId),
  )
  const selectedFlowName = cmsFlow?.name
  const selectedFlowPath = cmsFlow?.path
  const hasPublishedContent = Boolean(cmsFlow?.has_published_content)

  const flow = useAppSelector((state) => getById(state, lastSelectedFlowId)) as IFullFlow
  const isFetching = flow?.isFetching
  const isFlowDataAvailable = Boolean(
    !isFetching && flow?.contents?.length && flow?.root_content_id,
  )

  const { title: name, ava_big: avatarUrl } = useAppSelector(getCurrentAccount)

  const handleRestartSimulator = useCallback(() => {
    if (!simulatorRef.current) return
    simulatorRef.current.restart()
    analyticsService.sendEvent(`${FLOW_PICKER_ANALYTICS_EVENT}.FLOW_SIMULATOR.RESTART`)
  }, [])

  const resetSimulator = useCallback(() => {
    if (simulatorRef.current && phoneContainerRef.current) {
      simulatorRef.current = undefined
      phoneContainerRef.current.querySelector('.flow-simulator')?.remove()
    }
  }, [])

  useEffect(() => {
    if (
      lastSelectedFlowId &&
      !isFlowDataAvailable &&
      hasPublishedContent &&
      selectedFlowName &&
      selectedFlowPath
    ) {
      dispatch(fetchFlowContentData(lastSelectedFlowId, selectedFlowName, selectedFlowPath))
    }
  }, [
    lastSelectedFlowId,
    dispatch,
    isFlowDataAvailable,
    hasPublishedContent,
    selectedFlowName,
    selectedFlowPath,
  ])

  useEffect(() => {
    const phoneContainer = phoneContainerRef.current
    if (!phoneContainer || !isFlowDataAvailable || isFetching) return

    try {
      simulatorRef.current = window.MC_FLOWSIMULATOR.createSimulator(
        phoneContainer,
        prepareSimulatorData(flow),
        { avatarUrl, name, simpleMode: true },
      )
      analyticsService.sendEvent(`${FLOW_PICKER_ANALYTICS_EVENT}.FLOW_SIMULATOR.SHOW`)
    } catch (error) {
      resetSimulator()
      setIsSimulatorThrownError(true)
      catchSimulatorErrors(error)
    }

    return () => {
      resetSimulator()
    }
  }, [isFlowDataAvailable, isFetching, flow, avatarUrl, name, resetSimulator, dispatch])

  return {
    phoneContainerRef,
    isFetching,
    handleRestartSimulator,
    isFlowDataAvailable,
    lastSelectedFlowId,
    isSimulatorThrownError,
  }
}

import { isChatNotification } from 'apps/chat/typeguards/isChatNotification'
import { navigateToThread } from 'apps/chat/utils/navigation/navigateToThread'
import { analyticsService } from 'utils/services/analytics'

export const defaultNotificationOpenCallback = (event: Event) => {
  window.focus()

  analyticsService.sendEvent('LIVE_CHAT.BROWSER_PUSH_NOTIFICATION.OPEN')

  const notification = event.target

  if (isChatNotification(notification)) {
    navigateToThread(notification.data.threadId)
  }
}

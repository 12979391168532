import {
  ActionCategoryType,
  ApplicationCategoryType,
} from 'common/actions/components/ActionPickerModal/lib/types'
import { Action } from 'common/actions/models/Action/action'

export enum IntegrationModalType {
  INTEGRATION = 'integration',
  APPLICATION = 'application',
  ACTION_PICKER = 'action_picker',
}

export interface IntegrationModalPayload {
  type: IntegrationModalType
  data: IntegrationModalsState[IntegrationModalType]['data']
}

export interface IntegrationModalsState {
  [IntegrationModalType.INTEGRATION]: {
    isOpen: boolean
    data: { action: Action } | null
  }
  [IntegrationModalType.APPLICATION]: {
    isOpen: boolean
    data: { action: Action } | null
  }
  [IntegrationModalType.ACTION_PICKER]: {
    isOpen: boolean
    data: {
      action?: Action
      selectedCategory: ActionCategoryType | ApplicationCategoryType | null
    } | null
  }
}

export enum CancellationReasonType {
  PLATFORM_SWITCH = 'platform_switch',
  BAD_EXP = 'bad_exp',
  REMOVED_MSG_CODE = 'removed_msg_code',
  REMOVED_OTHER = 'removed_other',
  REMOVED_SB = 'removed_sb',
  MAR4 = 'mar4',
  WRONG_PRODUCT = 'wrong_product',
  OTHER_MAIN = 'other_main',
  TOO_EXPENSIVE = 'too_expensive',
  CLIENT_CANCELLED = 'client_cancelled',
  BAD_PRODUCT = 'bad_product',
  BAD_UNDERSTANDING = 'bad_understanding',
  BAD_RESULT = 'bad_result',
  PAGE_SWITCH = 'page_switch',
  PROJECT_COMPLETE = 'project_complete',
  SUBSCRIPTION_PAUSE = 'subscription_pause',
  PAGE_PROBLEMS = 'page_problems',
  OTHER = 'other',
  FB_EU_RESTRICTIONS = 'fb_eu_restrictions',
  DIDNT_GET_RESULTS = 'didnt_get_results',
  NOT_ENOUGH_ROI = 'not_enough_roi',
  TOOK_TOO_MUCH_TIME = 'took_too_much_time',
  NOT_ENOUGH_TIME = 'not_enough_time',
  NOT_FIT_BUSINESS_NEEDS = 'not_fit_business_needs',
  NOT_MEET_EXPECTATIONS = 'not_meet_expectations',
  TOO_MANY_CONTACTS_BILLED = 'too_many_contacts_billed',
  DIDNT_GET_RESULTS_OTHER = 'didnt_get_results_other',
  COST = 'cost',
  PRICE_TOO_HIGH = 'price_too_high',
  NOT_ENOUGH_VALUE = 'not_enough_value',
  ANOTHER_BILLING_APPROACH = 'another_billing_approach',
  NEEDED_MORE_FEATURES = 'needed_more_features',
  PRICING_UNCLEAR = 'pricing_unclear',
  COSTS_INCREASED = 'costs_increased',
  FORCED_TO_DELETE_CONTACTS = 'forced_to_delete_contacts',
  PRICE_UPGRADES = 'price_upgrades',
  COST_OTHER = 'cost_other',
  SWITCHED_TOOL = 'switched_tool',
  BETTER_FEATURES = 'better_features_other',
  MORE_AFFORDABLE = 'more_affordable_other',
  BETTER_INTEGRATION = 'better_integration_other',
  BETTER_SUPPORT = 'better_support_other',
  MORE_AI_AUTOMATION = 'more_ai_automation_other',
  SWITCHED_TOOL_OTHER = 'switched_tool_other',
  TOO_COMPLICATED = 'too_complicated',
  AUTOMATION_CONFUSING = 'automation_confusing',
  INTEGRATION_ISSUES = 'integration_issues',
  INTERFACE_DIFFICULT = 'interface_difficult',
  MANUAL_WORK_REQUIRED = 'manual_work_required',
  NEEDED_MORE_TEMPLATES = 'needed_more_templates',
  TOO_COMPLICATED_OTHER = 'too_complicated_other',
  SUPPORT_ISSUES = 'support_issues',
  TECHNICAL_PROBLEMS = 'technical_problems',
  SUPPORT_NOT_HELPFUL = 'support_not_helpful',
  SUPPORT_SLOW = 'support_slow',
  DOCUMENTATION_LACKING = 'documentation_lacking',
  BILLING_ISSUES = 'billing_issues',
  ONGOING_ISSUES = 'ongoing_issues',
  SUPPORT_ISSUES_OTHER = 'support_issues_other',
  NEEDS_CHANGED = 'needs_changed',
  NO_LONGER_NEEDED = 'no_longer_needed',
  BUSINESS_CHANGED = 'business_changed',
  BUSINESS_NOT_SUPPORTED = 'business_not_supported',
  NOT_SUITABLE_FOR_BUSINESS = 'not_suitable_for_business',
  MARKETING_STRATEGY_SHIFTED = 'marketing_strategy_shifted',
  LOW_CUSTOMER_ENGAGEMENT = 'low_customer_engagement',
  NEEDS_CHANGED_OTHER = 'needs_changed_other',
  CLIENT_NO_LONGER_NEEDS = 'client_no_longer_needs',
  CLIENT_DECIDED_TO_CANCEL = 'client_decided_to_cancel',
  CLIENT_SWITCHED_TOOL = 'client_switched_tool',
  CLIENT_NO_LONGER_NEEDED = 'client_no_longer_needed',
  CLIENT_BUDGET_CHANGED = 'client_budget_changed',
  CLIENT_NO_LONGER_NEEDS_OTHER = 'client_no_longer_needs_other',
  SWITCHED_MANYCHAT_ACCOUNT = 'switched_manychat_account',
}

import { FieldGroupId, FieldType } from 'common/fields/entities/enums'
import { Field } from 'common/fields/entities/field'
import { Tag } from 'shared/api/requests/tags/schemas'

export const makeTagField = (tag: Tag): Field => ({
  id: `tag_${tag.tag_id}`,
  caption: tag.tag_name,
  type: FieldType.TAG,
  group: FieldGroupId.TAG,
  folderId: tag.folder_id,
})

import BaseBlockConfig from 'common/builder/blocks/BaseBlockConfig'
import { IBlockBase, ITemplateBlock } from 'common/builder/blocks/blockInterfaces'
import { BlockType } from 'common/builder/constants/BlockType'

class TemplateBlockConfig extends BaseBlockConfig {
  blockType = BlockType.MESSAGE_TEMPLATE

  getDefaults = (): Omit<ITemplateBlock, keyof IBlockBase> => ({
    name: null,
    language: null,
  })
}

export default new TemplateBlockConfig()

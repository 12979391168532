import { createSlice } from '@reduxjs/toolkit'
import { createAppAsyncThunk } from 'reduxTyped'
import { createSelector } from 'reselect'

import { TeamEfficiencyChartChannel } from 'apps/analytics/constants/AnalyticsInterfaces'
import {
  AverageTimeChartData,
  LiveChatStatisticsState,
  ResponseRateChartData,
} from 'apps/analytics/types/liveChatStatistics'
import { handleError } from 'shared/api/lib/errors/handlers'
import { LiveChatStatisticsApi } from 'shared/api/requests/liveChatStatistics'
import {
  ConversationRequest,
  GetAverageTimeResponse,
  GetConversationsByTeamMembersChartStatsResponse,
  GetResponseRateResponse,
  GetTotalConversationsChartStatsResponse,
  LiveChatAdmin,
  ResponseRateRequest,
  TeamEfficiencyRequest,
} from 'shared/api/requests/liveChatStatistics/schemas'

export const initialState: LiveChatStatisticsState = {
  admins: [],
  isLoadingAdmins: false,
  totalConversationsChart: {
    dateRange: {},
    total: {
      assigned: 0,
      done: 0,
    },
  },
  conversationsByTeamMembersChart: {
    agents: [],
    total: {
      assigned: 0,
      done: 0,
    },
  },
  averageResponseTimeChart: {
    maxValues: {} as Record<TeamEfficiencyChartChannel, number | null>,
    dateRange: {},
  },
  averageClosureTimeChart: {
    maxValues: {} as Record<TeamEfficiencyChartChannel, number | null>,
    dateRange: {},
  },
  responseRateChart: {
    maxValues: {} as Record<TeamEfficiencyChartChannel, number | null>,
    dateRange: {},
  },
}

export const getAdmins = createAppAsyncThunk<LiveChatAdmin[]>(
  'liveChatStatistics/getAdmins',
  async () => {
    try {
      const result = await LiveChatStatisticsApi.getAdmins()
      return result.data.admins
    } catch (error) {
      handleError(error)
      return []
    }
  },
)

export const getTotalConversationsChartStats = createAppAsyncThunk<
  GetTotalConversationsChartStatsResponse | null,
  ConversationRequest
>('liveChatStatistics/getTotalConversationsChartStats', async (params) => {
  try {
    const result = await LiveChatStatisticsApi.getTotalConversationsChartStats({ body: params })

    if (Array.isArray(result.data.data)) return null
    return result.data.data
  } catch (error) {
    handleError(error)
    return null
  }
})

export const getConversationsByTeamMembersChartStats = createAppAsyncThunk<
  GetConversationsByTeamMembersChartStatsResponse | null,
  ConversationRequest
>('liveChatStatistics/getConversationsByTeamMembersChartStats', async (params) => {
  try {
    const result = await LiveChatStatisticsApi.getConversationsByTeamMembersChartStats({
      body: params,
    })

    if (Array.isArray(result.data.data)) return null

    return result.data.data
  } catch (error) {
    handleError(error)
    return null
  }
})

export const getAverageResponseTime = createAppAsyncThunk<
  GetAverageTimeResponse | null,
  TeamEfficiencyRequest
>('liveChatStatistics/getAverageResponseTime', async (params) => {
  try {
    const result = await LiveChatStatisticsApi.getAverageResponseTime({
      body: params,
    })

    if (Array.isArray(result.data.data)) return null

    return result.data.data
  } catch (error) {
    handleError(error)
    return null
  }
})

export const getAverageClosureTime = createAppAsyncThunk<
  GetAverageTimeResponse | null,
  TeamEfficiencyRequest
>('liveChatStatistics/getAverageClosureTime', async (params) => {
  try {
    const result = await LiveChatStatisticsApi.getAverageClosureTime({
      body: params,
    })

    if (Array.isArray(result.data.data)) return null

    return result.data.data
  } catch (error) {
    handleError(error)
    return null
  }
})

export const getResponseRate = createAppAsyncThunk<
  GetResponseRateResponse | null,
  ResponseRateRequest
>('liveChatStatistics/getResponseRate', async (params) => {
  try {
    const result = await LiveChatStatisticsApi.getResponseRate({
      body: params,
    })

    if (Array.isArray(result.data.data)) return null

    return result.data.data
  } catch (error) {
    handleError(error)
    return null
  }
})

export const liveChatStatisticsSlice = createSlice({
  name: 'liveChatStatistics',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getAdmins.pending, (state) => {
      state.isLoadingAdmins = true
    })
    builder.addCase(getAdmins.fulfilled, (state, action) => {
      state.admins = action.payload
      state.isLoadingAdmins = false
    })

    builder.addCase(getTotalConversationsChartStats.fulfilled, (state, action) => {
      if (action.payload === null || Array.isArray(action.payload.date_range)) return

      state.totalConversationsChart.total = action.payload.total
      state.totalConversationsChart.dateRange = action.payload.date_range
    })

    builder.addCase(getConversationsByTeamMembersChartStats.fulfilled, (state, action) => {
      if (action.payload === null) return

      state.conversationsByTeamMembersChart.total = action.payload.total
      state.conversationsByTeamMembersChart.agents = action.payload.agents
    })

    // Record types are forced to Partial when declaring the zod type. Therefore, we have to force the type back when retrieving the data
    builder.addCase(getAverageResponseTime.fulfilled, (state, action) => {
      if (action.payload === null || Array.isArray(action.payload.date_range)) return

      state.averageResponseTimeChart.maxValues = action.payload
        .max_values as AverageTimeChartData['maxValues']
      state.averageResponseTimeChart.dateRange = action.payload
        .date_range as AverageTimeChartData['dateRange']
    })

    builder.addCase(getAverageClosureTime.fulfilled, (state, action) => {
      if (action.payload === null || Array.isArray(action.payload.date_range)) return

      state.averageClosureTimeChart.maxValues = action.payload
        .max_values as AverageTimeChartData['maxValues']
      state.averageClosureTimeChart.dateRange = action.payload
        .date_range as AverageTimeChartData['dateRange']
    })

    builder.addCase(getResponseRate.fulfilled, (state, action) => {
      if (action.payload === null || Array.isArray(action.payload.date_range)) return

      state.responseRateChart.maxValues = action.payload
        .max_values as ResponseRateChartData['maxValues']
      state.responseRateChart.dateRange = action.payload
        .date_range as ResponseRateChartData['dateRange']
    })
  },
})

export const getAdminsSelector = createSelector(
  (state: RootState) => state.liveChatStatistics.admins,
  (admins) =>
    admins.map((admin) => ({
      value: admin.user_id,
      label: admin.name || '',
    })),
)

export const getAdminIdsSelector = createSelector(
  (state: RootState) => state.liveChatStatistics.admins,
  (admins) => admins.map((admin) => admin.user_id),
)

export const getTotalConversationsChartDateRangeSelector = (state: RootState) => {
  return state.liveChatStatistics.totalConversationsChart.dateRange
}

export const getTotalConversationsChartTotalSelector = (state: RootState) => {
  return state.liveChatStatistics.totalConversationsChart.total
}

export const getConversationsByTeamMembersChartTotalSelector = (state: RootState) => {
  return state.liveChatStatistics.conversationsByTeamMembersChart.total
}

export const getConversationsByTeamMembersChartAgentsSelector = (state: RootState) => {
  return state.liveChatStatistics.conversationsByTeamMembersChart.agents
}

export const getAverageResponseTimeChartMaxValuesSelector = (state: RootState) => {
  return state.liveChatStatistics.averageResponseTimeChart.maxValues
}

export const getAverageResponseTimeChartDateRangeSelector = (state: RootState) => {
  return state.liveChatStatistics.averageResponseTimeChart.dateRange
}

export const getAverageClosureTimeChartMaxValuesSelector = (state: RootState) => {
  return state.liveChatStatistics.averageClosureTimeChart.maxValues
}

export const getAverageClosureTimeChartDateRangeSelector = (state: RootState) => {
  return state.liveChatStatistics.averageClosureTimeChart.dateRange
}

export const getResponseRateChartMaxValuesSelector = (state: RootState) => {
  return state.liveChatStatistics.responseRateChart.maxValues
}

export const getResponseRateChartDateRangeSelector = (state: RootState) => {
  return state.liveChatStatistics.responseRateChart.dateRange
}

export const getAdminsIsLoadingSelector = (state: RootState) => {
  return state.liveChatStatistics.isLoadingAdmins
}

import React from 'react'

import { useFlexibleTriggerNameClassName } from 'apps/cms/components/Flows/components/FlowCard/components/Triggers/hooks/useFlexibleTriggerNameClassName'
import { renderTextByNodes } from 'apps/cms/components/Flows/components/FlowCard/components/Triggers/lib/renderTextByNodes'

interface BaseTitleProps {
  text: string
}

export const BaseTitle = ({ text }: BaseTitleProps) => {
  const className = useFlexibleTriggerNameClassName()

  return <span className={className}>{renderTextByNodes(text)}</span>
}

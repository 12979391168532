import { Body, McAPI } from 'utils/api/types'

import { SignUpUrl, OptIntUrl } from './telegramInterfaces'

const endpoints = {
  disconnect: '/:currentAccountID/telegram/disconnect',
  refreshToken: '/:currentAccountID/telegram/updateToken',
  toggleEnabled: '/:currentAccountID/telegram/toggle',
  getSignUpUrl: '/auth/getTelegramAuthRefUrl',
  getOptInUrl: '/auth/getTelegramBindingRefUrl',
  getNotifyAdminOptInUrl: '/:currentAccountID/telegram/notifyAdmin/getOptInUrl',
  notifyAdminOptOut: '/:currentAccountID/telegram/notifyAdmin/optOut',
}

export class TelegramAPI {
  private api: McAPI

  constructor(api: McAPI) {
    this.api = api
  }

  async getSignUpUrl() {
    const currentQuery = window.location.search
    return await this.api.get<Body & SignUpUrl>(endpoints.getSignUpUrl + currentQuery)
  }

  getOptInUrl() {
    return this.api.get<Body & OptIntUrl>(endpoints.getOptInUrl)
  }

  getNotifyAdminOptInUrl() {
    return this.api.get<Body & OptIntUrl>(endpoints.getNotifyAdminOptInUrl)
  }

  notifyAdminOptOut() {
    return this.api.post(endpoints.notifyAdminOptOut)
  }
}

import { ChannelType } from 'common/core/constants/ChannelType'
import { parseAvailableFields, createParseField } from 'common/fields/api/adapter'
import { BackendAvailableFieldsMap, BackendFieldData } from 'common/fields/api/types'
import { FilterScenario } from 'common/filter/interfaces'
import { Body, McAPI } from 'utils/api/types'
import { INotificationsListener } from 'utils/services/notificationsService/notificationsServiceTypes'

import { FieldGroupId } from '../entities/enums'
import { Field } from '../entities/field'

import { IFieldsAPI } from './iFieldsAPI'

const availableFieldsURL = '/:currentAccountID/subscribers/availableFields?allow_user_id=true'

const createFieldUrl = {
  [FieldGroupId.USER]: '/:currentAccountID/customFields/create',
  [FieldGroupId.BOT]: '/:currentAccountID/globalFields/create',
}

const CUF_WS_EVENTS = [
  'cuf_created',
  'cuf_updated',
  'cuf_archived',
  'cuf_restored',
  'cuf_deleted',
  'cufs_moved',
]
const CUF_BULK_WS_EVENTS = ['cufs_moved_bulk', 'cufs_archived', 'cufs_deleted', 'cufs_created']
const GAF_WS_EVENTS = [
  'global_field_created',
  'global_field_updated',
  'global_field_archived',
  'global_field_restored',
  'global_field_deleted',
]
const GAF_BULK_WS_EVENTS = ['global_fields_archived', 'global_fields_deleted']

type AvailableFieldsResponse = Body & BackendAvailableFieldsMap

type CreateFieldResponse = Body & {
  field: SafeUnknownObject
}

type UpdateListener = (field: Field) => void

export default class FieldsAPI implements IFieldsAPI {
  private api: McAPI
  private updateListener: UpdateListener = () => {}
  private fixSystemFields: boolean

  constructor(
    mcAPI: McAPI,
    accountNotificationsListener: INotificationsListener,
    fixSystemFields = true,
  ) {
    this.api = mcAPI
    this.fixSystemFields = fixSystemFields

    accountNotificationsListener.on(CUF_WS_EVENTS, ({ model }) => {
      this.emitUpdate(FieldGroupId.USER, model)
    })

    accountNotificationsListener.on(CUF_BULK_WS_EVENTS, ({ models }) => {
      models.forEach((model: SafeUnknownObject) => this.emitUpdate(FieldGroupId.USER, model))
    })

    accountNotificationsListener.on(GAF_WS_EVENTS, ({ model }) => {
      this.emitUpdate(FieldGroupId.BOT, model)
    })

    accountNotificationsListener.on(GAF_BULK_WS_EVENTS, ({ models }) => {
      models.forEach((model: SafeUnknownObject) => this.emitUpdate(FieldGroupId.BOT, model))
    })
  }

  getAvailableFields = async (
    connectedChannels: ChannelType[],
    scenario?: FilterScenario,
  ): Promise<Field[]> => {
    let url = availableFieldsURL

    if (scenario) {
      url += `&scenario=${scenario}`
    }

    const response = await this.api.get<AvailableFieldsResponse>(url)
    const parsedFields = parseAvailableFields(response, this.fixSystemFields)

    return filterFieldsWithConnectedChannels(parsedFields, connectedChannels)
  }

  createUserField = (fieldData: BackendFieldData): Promise<Field> => {
    return this.createField(fieldData, FieldGroupId.USER)
  }

  createBotField = (fieldData: BackendFieldData): Promise<Field> => {
    return this.createField(fieldData, FieldGroupId.BOT)
  }

  addUpdateListener = (listener: UpdateListener) => {
    this.updateListener = listener
  }

  private createField = async (
    body: BackendFieldData,
    group: FieldGroupId.USER | FieldGroupId.BOT,
  ) => {
    const { field } = await this.api.post<CreateFieldResponse>(createFieldUrl[group], { body })
    this.emitUpdate(group, field)
    return this.parseField(group, field)
  }

  private parseField = (groupId: FieldGroupId, model: SafeUnknownObject) => {
    const rawField = { ...model, name: `${groupId}_${model.field_id}` }
    return createParseField(groupId)(rawField)
  }

  private emitUpdate = (groupId: FieldGroupId, model: SafeUnknownObject) => {
    this.updateListener(this.parseField(groupId, model))
  }
}

const filterFieldsWithConnectedChannels = (fields: Field[], connectedChannels: ChannelType[]) =>
  fields.filter(({ channel }: Field) => (channel ? connectedChannels.includes(channel) : true))

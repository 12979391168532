import React from 'react'
import cx from 'classnames'
import { Icon } from '@manychat/manyui'

import { Col } from 'components/Grid/index'

import cm from './ArrowCol.module.css'

class ArrowCol extends React.Component {
  render() {
    const { reversed, active } = this.props

    return (
      <Col width={50} className={cx(cm.arrowWrap)}>
        <Icon.OrphanLongArrow
          className={cx(cm.arrow, { [cm.reversed]: reversed, [cm.active]: active })}
          size={24}
        />
      </Col>
    )
  }
}

export default ArrowCol

import { LayoutWithSidebar } from 'utils/router/components/LayoutWithSidebar/LayoutWithSidebar'
import { getHandle } from 'utils/router/utils/getHandle'

export const AnalyticsRoutes = [
  {
    path: 'analytics',
    handle: getHandle('analytics'),
    Component: LayoutWithSidebar,
    children: [
      {
        index: true,
        lazy: () => import('./charts.page'),
      },
    ],
  },
] as const

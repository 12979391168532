import memoize from 'memoize-one'
import { l } from '@manychat/manyui'

import { IBuilderValidationError } from 'common/builder/builderInterfaces'
import ErrorMessagesBuilder from 'common/builder/constants/Validation'
import { MinimumCurrenciesPayments } from 'common/builder/models/Button/constants'
import PaymentCurrencies from 'common/core/constants/PaymentCurrencies'
import ErrorMessages from 'common/fields/constants/Validation'
import {
  VAR_REGEX,
  EMPTY_VALUE_IN_BRACKETS_REGEX,
  FORBIDDEN_SYMBOLS_REGEX,
  FUNCTION_REGEX,
  CORE_MATH_SYMBOLS_REGEX,
  ALL_VALUES_REGEX,
} from 'constants/RegExs'
import { evaluate } from 'utils/mathJsMinify'

interface IValidationError {
  message: string
  originalMessage: string
}

export const getFormulaErrors = (
  formula?: string | number | null,
  paymentCurrency?: PaymentCurrencies,
): Array<IBuilderValidationError | IValidationError> => {
  let stringFormula = formula ? formula.toString() : ''
  const errors: Array<IBuilderValidationError | IValidationError> = []

  /**
   * With current validation, this is the only way to support the second argument for
   * round function without rewriting the whole validation. prepareRoundFunctions just removes
   * the second argument with a comma when the second argument is integer number.
   */
  stringFormula = prepareRoundFunctions(stringFormula)

  if (hasForbiddenSymbols(stringFormula)) {
    errors.push({
      message: l.getString(ErrorMessages.ACTION_SET_CUF_INVALID_OPERATOR),
      originalMessage: ErrorMessages.ACTION_SET_CUF_INVALID_OPERATOR.message,
    })
  }

  if (hasInvalidVariable(stringFormula)) {
    errors.push({
      message: l.getString(ErrorMessages.ACTION_SET_CUF_INVALID_VARIABLE),
      originalMessage: ErrorMessages.ACTION_SET_CUF_INVALID_VARIABLE.message,
    })
  }

  if (errors.length > 0) {
    return errors
  }

  if (!hasValidCountBrackets(stringFormula)) {
    errors.push({
      message: l.getString(ErrorMessages.ACTION_SET_COUNT_INVALID_BRACKETS),
      originalMessage: ErrorMessages.ACTION_SET_COUNT_INVALID_BRACKETS.message,
    })
    return errors
  }

  if (hasEmptyValueInBrackets(stringFormula)) {
    errors.push({
      message: l.getString(ErrorMessages.ACTION_SET_EMPTY_CONTENT_OF_BRACKETS_INVALID_VARIABLE),
      originalMessage: ErrorMessages.ACTION_SET_EMPTY_CONTENT_OF_BRACKETS_INVALID_VARIABLE.message,
    })
    return errors
  }

  const countMathSymbols = getCountMathSymbols(stringFormula)
  const countValues = getCountAllValues(stringFormula)
  const hasValidExpression = countValues - 1 === countMathSymbols

  if (!hasValidExpression) {
    errors.push({
      message: l.getString(ErrorMessages.ACTION_SET_MATH_SYMBOLS_INVALID_EXPRESSION),
      originalMessage: ErrorMessages.ACTION_SET_MATH_SYMBOLS_INVALID_EXPRESSION.message,
    })
    return errors
  }

  const { isValid, value } = getValidExpression(stringFormula)
  if (!isValid) {
    errors.push({
      message: l.getString(ErrorMessages.ACTION_SET_CUF_INVALID_EXPRESSION),
      originalMessage: ErrorMessages.ACTION_SET_CUF_INVALID_EXPRESSION.message,
    })
    return errors
  }

  if (!paymentCurrency) {
    return errors
  }

  const isValidPrice = isValid && isFinite(value)

  if (!isValidPrice) {
    errors.push({
      prop: 'item_price',
      message: l.getString(ErrorMessagesBuilder.BUTTON_ITEM_PRICE_INVALID),
      originalMessage: ErrorMessagesBuilder.BUTTON_ITEM_PRICE_INVALID.message,
    })
    return errors
  }

  const minPrice = MinimumCurrenciesPayments[paymentCurrency]
  const isCUFInPrice = hasCufInPrice(stringFormula)
  const isLessMin = value < minPrice

  if (!isCUFInPrice && isLessMin) {
    errors.push({
      prop: 'item_price',
      message: l.getString(ErrorMessagesBuilder.BUTTON_ITEM_PRICE_SMALL(minPrice, paymentCurrency)),
      originalMessage: ErrorMessagesBuilder.BUTTON_ITEM_PRICE_SMALL(minPrice, paymentCurrency)
        .message,
    })
  }

  return errors
}

export const prepareRoundFunctions = (formula: string) =>
  formula.replace(
    /round\((?<firstArgument>.+),\s*\d\)/g,
    (value, firstArgument) => `round(${firstArgument})`,
  )

export const replaceVariables = (formula: string) => formula.replace(VAR_REGEX, '1')

export const hasCufInPrice = (formula: string) => {
  VAR_REGEX.lastIndex = 0
  return VAR_REGEX.test(formula)
}

export const hasValidCountBrackets = (formula: string) => {
  const stack = []

  for (let index = 0; index < formula.length; index++) {
    if (formula[index] === '(') {
      stack.push('(')
    } else if (formula[index] === ')') {
      if (stack.length === 0) {
        return false
      }
      stack.pop()
    }
  }

  return stack.length === 0
}

export const hasEmptyValueInBrackets = (formula: string) => {
  EMPTY_VALUE_IN_BRACKETS_REGEX.lastIndex = 0
  return EMPTY_VALUE_IN_BRACKETS_REGEX.test(formula)
}

export const getCountMathSymbols = (formula: string) =>
  formula.match(CORE_MATH_SYMBOLS_REGEX)?.length ?? 0

export const getCountAllValues = (formula: string) => formula.match(ALL_VALUES_REGEX)?.length ?? 0

export const replaceExpressionFunctions = (formula: string) => formula.replace(FUNCTION_REGEX, '')

export const hasForbiddenSymbols = (formula: string) => {
  const expressionWithoutFunctions = replaceExpressionFunctions(formula)
  const expressionWithoutVariables = replaceVariables(expressionWithoutFunctions)
  FORBIDDEN_SYMBOLS_REGEX.lastIndex = 0
  return FORBIDDEN_SYMBOLS_REGEX.test(expressionWithoutVariables)
}

export const hasInvalidVariable = (formula: string) => formula.indexOf('{{|fallback:"0"}}') >= 0

export const getValidExpression = memoize((formula: string) => {
  try {
    const value = evaluate(replaceVariables(formula))
    return { isValid: true, value }
  } catch (err) {
    return { isValid: false }
  }
})

import React from 'react'
import { l } from '@manychat/manyui'

import { MESSAGE_TEMPLATE_PAUSING } from 'apps/whatsApp/core/constants'
import { WaMessageTemplatePauseTitle } from 'shared/api/requests/whatsApp/schemas'

import { WhatsAppMessageTemplatePausedNotificationData } from '../NotifyInterfaces'

import cm from './common.module.css'

interface WAMessageTemplatePausedNotificationProps {
  data: WhatsAppMessageTemplatePausedNotificationData
}

export const WAMessageTemplatePausedNotification = ({
  data,
}: WAMessageTemplatePausedNotificationProps) => {
  const { message_template, pause_title } = data
  const templateName = message_template.name_human
  return (
    <>
      <span className="m-r-xxs">
        {pause_title
          ? l.translate(
              `Message template <span class="{className}">{templateName}</span> has been paused for {hours} hours due to low performance metrics. Active broadcasts using this template have been stopped.`,
              {
                templateName,
                hours: pause_title === WaMessageTemplatePauseTitle.PAUSE_FOR_3_HOURS ? 3 : 6,
                className: cm.boldText,
              },
            )
          : l.translate(
              `Message template <span class="{className}">{templateName}</span> has been paused due to low performance metrics. Active broadcasts using this template have been stopped.`,
              {
                templateName,
                className: cm.boldText,
              },
            )}
      </span>
      <a target="_blank" href={MESSAGE_TEMPLATE_PAUSING} rel="noreferrer" className={cm.link}>
        {l.translate('Learn more')}
      </a>
    </>
  )
}

import React from 'react'
import { useAppDispatch } from 'reduxTyped'
import { l } from '@manychat/manyui'

import { CmsAnalytics } from 'apps/cms/lib/analytics'
import { contentManagementActions } from 'apps/cms/store'

import cm from './ClearButton.module.css'

interface ClearButtonProps {
  onClear: () => void
}

export const ClearButton = ({ onClear }: ClearButtonProps) => {
  const dispatch = useAppDispatch()

  const clearAllFilters = () => {
    CmsAnalytics.logClearAllFilters()

    onClear()
    dispatch(
      contentManagementActions.updateQuery({
        triggerFilters: [],
        triggerStatus: null,
      }),
    )
  }
  return (
    <button onClick={clearAllFilters} className={cm.clearButton}>
      {l.translate('Clear')}
    </button>
  )
}

import { NodeTypeToChannelMap } from 'common/builder/constants/NodeTypeToChannelTypeMap'
import { INode } from 'common/builder/nodes/nodeInterfaces'
import { isAiAgentNodeWithChannel } from 'common/builder/utils/isAiAgentNodeWithChannel'
import { ChannelType } from 'common/core/constants/ChannelType'

export const extractNodeChannel = (node: INode): ChannelType | null => {
  if (isAiAgentNodeWithChannel(node)) {
    return node.channel
  }

  return NodeTypeToChannelMap[node.nodeType] ? NodeTypeToChannelMap[node.nodeType] : null
}

// @see https://developer.chrome.com/blog/page-lifecycle-api/#observing-page-lifecycle-states-in-code
import { PageLifecycleState } from 'apps/chat/constants/LiveChatNotifications'

export const getPageLifecycleState = (): PageLifecycleState | null => {
  if (!document) {
    return null
  }

  if (document.visibilityState === 'hidden') {
    return PageLifecycleState.HIDDEN
  }
  if (document.hasFocus()) {
    return PageLifecycleState.ACTIVE
  }
  return PageLifecycleState.PASSIVE
}

import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { BtnV2, Modal, l } from '@manychat/manyui'

import { CmsAnalytics } from 'apps/cms/lib/analytics'
import { checkDisabledForUserRole } from 'common/core/components/AccountSelect/AccountSelect'
import { ExportFlowStatuses } from 'common/flow/flowConstants'
import { ExportSuccess, ExportFail, ExportProcess } from 'components/ExportModalSteps'
import { analyticsService } from 'utils/services/analytics'

import ExportDialog from '../ExportDialog'

import cm from './ExportFlowModal.module.css'

export default class ExportFlowModal extends Component {
  static propTypes = {
    flowId: PropTypes.string,
    flow: PropTypes.object,
    accounts: PropTypes.array,
    onComplete: PropTypes.func,
    onExport: PropTypes.func,
    onClose: PropTypes.func,
    isOpen: PropTypes.bool,
    exportEventName: PropTypes.string,
  }

  state = { destAccountId: null }

  componentDidMount() {
    this.setDefaultDestAccountId()
  }

  componentDidUpdate(prevProps) {
    const { step } = this.props
    const willClose = prevProps.isOpen && !this.props.isOpen
    if (willClose) {
      this.setDefaultDestAccountId()
      this.props.resetMaps()
      CmsAnalytics.logCloseExportFlowModal()
    }

    if (!prevProps.isOpen && this.props.isOpen) {
      this.props.fetchAccounts()
      CmsAnalytics.logOpenExportFlowModal()
    }

    if (prevProps.accounts.length !== this.props.accounts.length) {
      this.setDefaultDestAccountId()
    }

    if (prevProps.step !== ExportFlowStatuses.SUCCESS && step === ExportFlowStatuses.SUCCESS) {
      CmsAnalytics.logCompleteExportFlowModal()
    }
  }

  setDefaultDestAccountId() {
    const account = this.props.accounts.find((account) => !checkDisabledForUserRole(account))
    this.setState({ destAccountId: account ? account.id : null })
  }

  handleSelect = (account) => {
    this.setState({ destAccountId: account.id })
    this.props.resetMaps()
  }

  handleCustomEventSelect = (customEvent, e) => {
    const { value } = e.target
    this.props.updateCustomEventsMap({ [customEvent.id]: value })
  }

  handleTagSelect = (flowTag, e) => {
    const { value } = e.target
    this.props.updateTagsMap({ [flowTag.id]: value })
  }

  handleCUFSelect = (flowUserField, e) => {
    const { field } = e.target
    this.props.updateCUFsMap({ [flowUserField.id]: field ? field.id : null })
  }

  handleGAFSelect = (flowGlobalField, e) => {
    const { field } = e.target
    this.props.updateGAFsMap({ [flowGlobalField.id]: field ? field.id : null })
  }

  handleOtnSelect = (flowOtnField, e) => {
    const { value } = e.target
    this.props.updateOtnsMap({ [flowOtnField.id]: value })
  }

  handleSubmit = (event) => {
    event.preventDefault()
    const {
      onExport,
      flowId,
      tagsMap,
      cufsMap,
      gafsMap,
      otnsMap,
      customEventsMap,
      exportEventName,
    } = this.props
    const { destAccountId } = this.state

    if (exportEventName) {
      analyticsService.sendEvent(exportEventName)
    } else {
      CmsAnalytics.logExportFlow()
    }

    onExport(flowId, destAccountId, {
      custom_events: customEventsMap,
      custom_fields: cufsMap,
      tags: tagsMap,
      otn_reasons: otnsMap,
      global_fields: gafsMap,
    })
  }

  handleComplete = () => {
    const { onComplete, flow } = this.props

    onComplete(flow.id)
  }

  handleClose = () => {
    const { onClose, step } = this.props
    if (step !== ExportFlowStatuses.PROCESS) {
      onClose()
    }

    if (step === ExportFlowStatuses.SUCCESS || step === ExportFlowStatuses.FAIL) {
      this.handleComplete()
    }
  }

  render() {
    const { isOpen, step, accounts, flow } = this.props
    const { destAccountId } = this.state
    const isFirstStep = !step
    const hasAccounts = accounts.length
    const disableClose = step === ExportFlowStatuses.PROCESS

    return (
      <Modal
        width={680}
        open={isOpen}
        className={cm.modal}
        title={<div className="p-y-xs">{l.translate('Copy Automation to Another Account')}</div>}
        onRequestClose={this.handleClose}
        disableClose={disableClose}
        disableOverlayClose={disableClose}
        disableCloseButton={disableClose}
      >
        {hasAccounts && destAccountId ? (
          <ExportDialog
            destAccountId={destAccountId}
            accounts={accounts}
            onCustomEventSelect={this.handleCustomEventSelect}
            onTagSelect={this.handleTagSelect}
            onCUFSelect={this.handleCUFSelect}
            onGAFSelect={this.handleGAFSelect}
            onOtnSelect={this.handleOtnSelect}
            onSelect={this.handleSelect}
            onSubmit={this.handleSubmit}
            title={flow.name}
            style={{ visibility: isFirstStep ? 'visible' : 'hidden' }}
            isOpen={isOpen}
            flowId={flow.id}
          />
        ) : (
          <div className="p-a-md">
            <p className="m-b">
              {l.translate('Sorry, but you have no connected pages to export to')}
            </p>
            <BtnV2 fullWidth size="large" onClick={this.handleClose}>
              {l.translate('Close')}
            </BtnV2>
          </div>
        )}
        {step === ExportFlowStatuses.PROCESS && (
          <ExportProcess processName={l.translate('Copying')} />
        )}
        {step === ExportFlowStatuses.SUCCESS && (
          <ExportSuccess processName={l.translate('Copying')} />
        )}
        {step === ExportFlowStatuses.FAIL && (
          <ExportFail processName={l.translate('Copying')} onRetry={this.handleComplete} />
        )}
      </Modal>
    )
  }
}

import React from 'react'
import cx from 'classnames'
import { v4 as uuid } from 'uuid'
import { TextInputV2, TextInputV2Props } from '@manychat/manyui'

import cm from './TextInputWithIcon.module.css'

type Icon = JSX.Element | null | boolean

type TextInputWithIconProps = Omit<React.HTMLProps<HTMLInputElement>, 'ref'> & {
  autocomplete?: boolean
  icon?: Icon
  invalid?: boolean
  rightIcon?: Icon
  wrapperClassName?: string
  iconClass?: string
}

const wrapperStyle: React.CSSProperties = { position: 'relative' }
type Props = TextInputV2Props & TextInputWithIconProps

const TextInputWithIcon: React.ForwardRefRenderFunction<HTMLInputElement, Props> = (
  { className, icon, id, rightIcon, wrapperClassName, iconClass, ...inputProps },
  ref: React.Ref<HTMLInputElement>,
) => {
  const inputId = React.useMemo(() => id || uuid(), [id])

  return (
    <div className={wrapperClassName} style={wrapperStyle}>
      {icon && (
        <label htmlFor={inputId} className={`${cm.leftIcon} d-flex align-center ${iconClass}`}>
          {icon}
        </label>
      )}
      <TextInputV2
        inputRef={ref}
        className={cx(icon && cm['input-leftIcon'], rightIcon && cm['input-rightIcon'], className)}
        id={inputId}
        {...inputProps}
      />
      {rightIcon && (
        <label htmlFor={inputId} className={`${cm.rightIcon} d-flex align-center ${iconClass}`}>
          {rightIcon}
        </label>
      )}
    </div>
  )
}

export default React.forwardRef(TextInputWithIcon)

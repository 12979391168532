export enum UserDisplayFlagType {
  HIDE_HOME_TAB_JOIN_AGENCY_PROGRAM_BANNER = 'hide_home_tab_join_agency_program_banner',
  HIDE_QUICK_CAMPAIGNS_SECTION_FLOW = 'hide_quick_campaigns_section_flow',
  IG_ONBOARDING_CALL_PASSED = 'ig_onboarding_call_passed',
  TIKTOK_CREATORS_WAITLIST_FORM_ADDED_TO_WAITLIST = 'tiktok_creators_waitlist_form_added_to_waitlist',
  TIKTOK_FIRST_SET_LIVE_MODAL_SHOWN = 'tiktok_first_set_live_modal_shown',
  TIKTOK_REGION_ACCESSIBILITY_REMINDER_ADDED_TO_WAITLIST = 'tiktok_region_accessibility_reminder_added_to_waitlist',
  TIKTOK_NEW_BADGE_WAS_SHOWN = 'tiktok_new_badge_was_shown',
  TIKTOK_FEATURE_REMINDER_CGT_ADDED_TO_WAITLIST = 'tiktok_feature_reminder_cgt_added_to_waitlist',
  TIKTOK_FEATURE_REMINDER_REF_URL_ADDED_TO_WAITLIST = 'tiktok_feature_reminder_ref_url_added_to_waitlist',
}

export enum AccountUserDisplayFlagType {
  HIGHLIGHT_LOGS_ON_FB_POLICY_ENFORCEMENT = 'highlight_logs_on_fb_policy_enforcement',
}

export enum AccountDisplayFlag {
  SHOW_MESSAGE_TAGS_ACTIVATION_BUTTON = 'show_message_tags_activation_button',
  RN_DEFAULT_FLOW_CREATED = 'rn_default_flow_created',
  IG_RN_DEFAULT_FLOW_CREATED = 'ig_rn_default_flow_created',
  RN_WIZARD_FINISHED = 'rn_wizard_finished',
  IG_RN_WIZARD_FINISHED = 'ig_rn_wizard_finished',
  IG_ONBOARDING_CALL = 'ig_onboarding_call',
  GENERATED_HOME_TAB_TEMPLATES = 'generated_home_tab_templates',
  SHOW_CGT_TEMPLATES_ON_HOME_TAB = 'show_cgt_templates_on_home_tab',
  SHOW_DM_TEMPLATES_ON_HOME_TAB = 'show_dm_templates_on_home_tab',
  SHOW_STORY_TEMPLATES_ON_HOME_TAB = 'show_story_templates_on_home_tab',
  SHOW_LIVE_TEMPLATES_ON_HOME_TAB = 'show_live_templates_on_home_tab',
}

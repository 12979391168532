import React from 'react'
import cx from 'classnames'
import { l } from '@manychat/manyui'

import { PhonePreviewTheme } from 'components/PhonePreviewKit'
import { useThemeContext } from 'components/PhonePreviewKit/useThemeContext'

import cameraSvg from './svg/camera_filled.svg'
import dmAddSvg from './svg/dm_add.svg'
import dmBrandedSvg from './svg/dm_branded.svg'
import dmPictureSvg from './svg/dm_picture.svg'
import planeSvg from './svg/plane.svg'

import cm from './PhonePreviewMessageComposer.module.css'

export function PhonePreviewMessageComposer() {
  const theme = useThemeContext()

  return (
    <div className={cx(cm.inputContainer, cm[theme])} data-test-id="message-composer">
      <div className={cm.messageInput}>
        <span className={cm.placeholder}>{l.translate('Message...')}</span>
        {theme === PhonePreviewTheme.INSTAGRAM && (
          <div className={cx(cm.roundButton, cm.photoButton)}>
            <img width="16" height="14" src={cameraSvg} alt="camera" />
          </div>
        )}
        {theme === PhonePreviewTheme.INSTAGRAM && (
          <div className={cm.actions}>
            <img src={dmPictureSvg} width="18" height="18" alt="picture" />
            <img src={dmBrandedSvg} width="18" height="18" alt="branded" />
            <img src={dmAddSvg} width="18" height="18" alt="add" />
          </div>
        )}
        {theme === PhonePreviewTheme.TIKTOK && (
          <div className={cx(cm.roundButton, cm.sendButton)}>
            <img src={planeSvg} alt="send" />
          </div>
        )}
      </div>
    </div>
  )
}

PhonePreviewMessageComposer.displayName = 'PhonePreview.MessageComposer'

import { createSelector } from 'reselect'

import { Integrations } from 'common/actions/integrations/common/model/constants'
import { getCurrentAccount } from 'common/core/selectors/appSelectors'
import { AVAILABLE_CURRENCIES, SettingsState, WhitelistDomain } from 'common/settings/interfaces'
import {
  type ConversionsApiChannel,
  DisplayPreferences,
} from 'shared/api/requests/settings/schemas'

export const getSnippetById = (state: RootState, id?: number) => {
  if (!id) {
    return undefined
  }
  return state.settings.liveChatSnippets.find((snippet) => snippet.snippet_id === id)
}

export const getSettingsItem = createSelector(
  (state: RootState) => state.settings?.item,
  (item) => item ?? ({} as SettingsState['item']),
)

export const getConversionsApi = createSelector(getSettingsItem, (item) => item.conversions_api)

export const getConversionApiByChannel = createSelector(
  getConversionsApi,
  (_: unknown, channel: ConversionsApiChannel) => channel,
  (conversationApi, channel) => {
    if (conversationApi === null) return null

    return conversationApi[channel]
  },
)

export const isFlowTemplatesEnabled = (state: RootState): boolean =>
  !(getSettingsItem(state).skip_flow_templates_modal ?? false)

export const getIsFetchingSettings = (state: RootState) => Boolean(state.settings?.fetching)

export const getPolicyEnforcement = (state: RootState) => state.settings?.policy_enforcement

export function isPersonaUsed(state: RootState) {
  return getSettingsItem(state).use_persona
}

export function greetingText(state: RootState) {
  return getSettingsItem(state).greeting_text || ''
}

export function notificationPhone(state: RootState) {
  return getSettingsItem(state).current_user?.notification_phone || ''
}

export function getTelegramNotificationUser(state: RootState) {
  return getSettingsItem(state).current_user?.notification_telegram
}

export function isSMSSwitchError(state: RootState) {
  return getSettingsItem(state)?.isSMSSwitchError || false
}

export function isEmailSwitchError(state: RootState) {
  return getSettingsItem(state)?.isEmailSwitchError || false
}

export function getSelectedSMSBodyLinkShortener(state: RootState) {
  return getSettingsItem(state)?.body_url_shortener_selected
}

export const getLiveChatAccountSettings = (state: RootState) =>
  getSettingsItem(state)?.livechat_account_settings

export const getLivechatSettings = (state: RootState) =>
  getSettingsItem(state)?.current_user?.livechat_settings

export function getMobileNotifyAdminPushEnabled(state: RootState) {
  return state.settings.item.current_user.mobile_notify_admin_push_enabled
}

export function isSMSBodyLinkShortenerDisabled(state: RootState) {
  const selected = getSelectedSMSBodyLinkShortener(state)
  return selected?.includes('disabled')
}

export const getWhitelist = (state: RootState): WhitelistDomain[] | [] => {
  return state.settings.whitelist
}

export const getIsAnyFBUnverifiedDomain = (state: RootState): boolean => {
  const whitelist = getWhitelist(state)
  return whitelist.some(
    (domain) => Object.prototype.hasOwnProperty.call(domain, 'fb_verified') && !domain.fb_verified,
  )
}
export const getIsWhitelistLoading = (state: RootState): boolean =>
  state.settings.isWhitelistLoading

export const getIsWhitelistError = (state: RootState): boolean =>
  Boolean(state.settings.whitelistingError)

export const getNotifyEnabledChannels = (state: RootState): string[] =>
  state.settings.item.current_user.notify_admin

export const getNotifyAdminCheckedByChannels = (state: RootState) => {
  const currentAccount = getCurrentAccount(state)

  const enabledChannels: string[] = getNotifyEnabledChannels(state)

  const enabledChannelsIncludesMessenger = enabledChannels.includes('messenger')
  const enabledChannelsIncludesEmail = enabledChannels.includes('email')

  let messengerChecked = enabledChannelsIncludesMessenger
  let emailChecked = enabledChannelsIncludesEmail
  let messengerDisabled = false
  if (currentAccount.suspend_notify_admin_by_messenger) {
    messengerDisabled = true
    messengerChecked = false
    emailChecked = enabledChannelsIncludesEmail || enabledChannelsIncludesMessenger
  }

  return {
    messengerChecked,
    messengerDisabled,
    emailChecked,
    smsChecked: enabledChannels.includes('sms'),
    telegramChecked: enabledChannels.includes('telegram'),
  }
}

export const getCardCountry = (state: RootState): string | null =>
  state?.settings?.item?.billing_info?.card_country

// thats data setted by backend when last payment is failed
export const getIsNeedToShowPayNow = (state: RootState): boolean =>
  Boolean(state.app.currentAccount.billing_info?.need_to_show_pay_now)

export const getIsAutoAssignmentEnabled = (state: RootState) =>
  Boolean(getLiveChatAccountSettings(state)?.auto_assign_type)

export const getJustConnectedIntegrationType = (state: RootState) =>
  state.integrations.confirm.type ?? null

export const getDisplayPreferences = createSelector(getSettingsItem, (settingItem) => {
  return settingItem.display_preferences
})

export const getDisplayPreferenceByName = createSelector(
  (state: RootState, name: DisplayPreferences) => ({
    displayPreferences: getDisplayPreferences(state),
    name,
  }),
  ({ displayPreferences, name }) => {
    if (!displayPreferences) return null
    return displayPreferences[name]
  },
)

type IHandoverReceivers = Array<{
  id: string
  name: string
}> | null

export const getHandoverReceivers = (state: RootState): IHandoverReceivers =>
  state.settings.handoverReceivers

export const getSettingsPayments = createSelector(getSettingsItem, (item) => item?.payments)

/**
 * This selector retrieves the currency set by the user for their account settings.
 * It extracts the currency from the user's payment settings, as specified in their
 * account preferences. This currency is intended for user-specific purposes and
 * may differ from the "billable currency" used for transactions or invoicing.
 */
export const getCurrency = createSelector(
  getSettingsItem,
  (item): AVAILABLE_CURRENCIES => item?.payments?.currency,
)

export const getAllowedIntegrationsTypes = createSelector(
  getCurrentAccount,
  (account) => account.allowed_integrations,
)

export const getAllowedIntegrations = createSelector(
  getAllowedIntegrationsTypes,
  (allowedIntegrationsTypes) =>
    Integrations.filter((integration) => allowedIntegrationsTypes.includes(integration.type)),
)

export const getPublicAPIAccessToken = (state: RootState) => state.settings.publicAPIAccessToken

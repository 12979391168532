import React from 'react'
import cx from 'classnames'

import { useFlexibleTriggerNameClassName } from 'apps/cms/components/Flows/components/FlowCard/components/Triggers/hooks/useFlexibleTriggerNameClassName'
import { KeywordPreview } from 'apps/keywords/components/KeywordPreview/KeywordPreview'
import { Keyword } from 'shared/api/requests/keywords/schemas'

import cm from './KeywordTitle.module.css'

interface KeywordTitleProps {
  keyword: Keyword
}

export const KeywordTitle = ({ keyword }: KeywordTitleProps) => {
  const className = useFlexibleTriggerNameClassName()

  return (
    <KeywordPreview
      mergeKeywordsFromOneGroup={false}
      keywordRules={keyword.keyword_rules}
      wrapperClassName={cx(cm.keywordsWrapper, className)}
      keywordsClassName={cm.keyword}
      wrapDescription
    />
  )
}

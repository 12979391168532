import { l } from '@manychat/manyui'

export enum ExternalTriggerType {
  HOTMART_ABANDONED_CART = 'hotmart_abandoned_cart',
  HOTMART_POST_PURCHASE_CHANGE_STATUS = 'hotmart_purchase_change_status',
  TIKTOK_ADS_CTX = 'tiktok_ads_ctx',
}

export enum ExternalTriggerStatusType {
  INITIAL = 'initial',
  ACTIVE = 'active',
  INACTIVE = 'inactive',
  DELETED = 'deleted',
}

export const ExternalAppContext = 'app'
export const AppLocalContext = 'lcf'

export enum HotmartPostPurchaseStatus {
  PURCHASE_COMPLETE = 'PURCHASE_COMPLETE',
  PURCHASE_APPROVED = 'PURCHASE_APPROVED',
  PURCHASE_EXPIRED = 'PURCHASE_EXPIRED',
  PURCHASE_PROTEST = 'PURCHASE_PROTEST',
  PURCHASE_DELAYED = 'PURCHASE_DELAYED',
  PURCHASE_REFUNDED = 'PURCHASE_REFUNDED',
  PURCHASE_CHARGEBACK = 'PURCHASE_CHARGEBACK',
  PURCHASE_BILLET_PRINTED = 'PURCHASE_BILLET_PRINTED',
  PURCHASE_CANCELED = 'PURCHASE_CANCELED',
}

export const HotmartPostPurchaseStatusLabels: Record<HotmartPostPurchaseStatus, string> = {
  [HotmartPostPurchaseStatus.PURCHASE_COMPLETE]: l.translate('Purchase completed'),
  [HotmartPostPurchaseStatus.PURCHASE_APPROVED]: l.translate('Purchase approved'),
  [HotmartPostPurchaseStatus.PURCHASE_EXPIRED]: l.translate('Purchase expired'),
  [HotmartPostPurchaseStatus.PURCHASE_PROTEST]: l.translate('Refund requested'),
  [HotmartPostPurchaseStatus.PURCHASE_DELAYED]: l.translate('Purchase overdue'),
  [HotmartPostPurchaseStatus.PURCHASE_REFUNDED]: l.translate('Purchase refunded'),
  [HotmartPostPurchaseStatus.PURCHASE_CHARGEBACK]: l.translate('Purchase chargeback'),
  [HotmartPostPurchaseStatus.PURCHASE_BILLET_PRINTED]: l.translate('Purchase awaits payment'),
  [HotmartPostPurchaseStatus.PURCHASE_CANCELED]: l.translate('Purchase canceled'),
}

export const DefaultHotmartPostPurchaseStatus = HotmartPostPurchaseStatus.PURCHASE_COMPLETE

import { ILimitedList } from 'utils/factory/limitedList/interfaces'

export const Initial: ILimitedList = {
  byId: {},
  items: null,
  fetching: false,
  limiter: null,
  laterLimiter: null,
  error: null,
  fetchArgsHash: '',
  fetchRequestId: null,
  listLaterLimiter: null,
  listEarlyLimiter: null,
}

export const FSQuerySortType = {
  TITLE: 'title',
  CREATED: 'created',
  MODIFIED: 'modified',
}

export const FSQueryOrderType = {
  ASC: 'asc',
  DESC: 'desc',
}

export const FSQueryPlaceType = {
  CMS: 'cms',
  PICKER: 'picker',
}

export const FSQueryRootPath = '/'

export const FSQueryDefaults = {
  // browse
  path: FSQueryRootPath,
  sort: FSQuerySortType.MODIFIED,
  order: FSQueryOrderType.DESC,
  folderOnly: false,

  // search
  // q: null,
  // place: null,

  // special
  // id: null,
}

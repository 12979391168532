import React, { ReactNode } from 'react'

const escapeStringRegexp = (string: string) => {
  // Escape characters with special meaning either inside or outside character sets.
  // Use a simple backslash escape when it’s always valid, and a `\xnn` escape when the simpler form would be disallowed by Unicode patterns’ stricter grammar.
  return string.replace(/[|\\{}()[\]^$+*?.]/g, '\\$&').replace(/-/g, '\\x2d')
}

const removeDiacritics = (str: string, blacklist?: string) => {
  if (!String.prototype.normalize) {
    // Fall back to original string
    return str
  }

  if (!blacklist) {
    // No blacklist, just remove all
    return str.normalize('NFD').replace(/[\u0300-\u036f]/g, '')
  } else {
    const blacklistChars = blacklist.split('')

    // Remove all diacritics that are not a part of a blacklisted character
    // First char cannot be a diacritic
    return str.normalize('NFD').replace(/.[\u0300-\u036f]+/g, (m) => {
      return blacklistChars.indexOf(m.normalize()) > -1 ? m.normalize() : m[0]
    })
  }
}

const getMatchBoundaries = (subject: string, search: RegExp) => {
  const matches = search.exec(subject)
  if (matches) {
    return {
      first: matches.index,
      last: matches.index + matches[0].length,
    }
  }

  return null
}

const getSearch = (
  search: string | number | RegExp | boolean,
  caseSensitive: boolean,
  ignoreDiacritics: boolean,
  diacriticsBlacklist: string,
) => {
  if (search instanceof RegExp) {
    return search
  }

  let flags = ''
  if (!caseSensitive) {
    flags += 'i'
  }

  let _search = search
  if (typeof search === 'string') {
    _search = escapeStringRegexp(search)
  }

  if (ignoreDiacritics) {
    _search = removeDiacritics(`${_search}`, diacriticsBlacklist)
  }

  return new RegExp(`${_search}`, flags)
}

export type HighlighterProps = {
  search: string | number | RegExp | boolean
  caseSensitive?: boolean
  ignoreDiacritics?: boolean
  diacriticsBlacklist?: string
  matchElement?: string
  matchClass?: string
  matchStyle?: Record<string, string>
  children?: ReactNode
  className?: string
}

let count = 0

const Highlighter = ({
  search,
  caseSensitive = false,
  ignoreDiacritics = false,
  diacriticsBlacklist = '',
  matchElement = 'mark',
  matchClass = 'highlight',
  matchStyle = {},
  children,
  ...rest
}: HighlighterProps) => {
  const renderPlain = (string: string) => {
    count = count + 1
    return React.createElement('span', { key: count }, string)
  }

  const renderHighlight = (string: string) => {
    count = count + 1
    return React.createElement(
      matchElement,
      {
        key: count,
        className: matchClass,
        style: matchStyle,
      },
      string,
    )
  }

  const highlightChildren = (subject: string, search: RegExp) => {
    const _children = []
    let remaining = subject

    while (remaining) {
      const remainingCleaned = ignoreDiacritics
        ? removeDiacritics(remaining, diacriticsBlacklist)
        : remaining

      if (!search.test(remainingCleaned)) {
        _children.push(renderPlain(remaining))
        return _children
      }

      const boundaries = getMatchBoundaries(remainingCleaned, search)

      if (boundaries?.first === 0 && boundaries.last === 0) {
        // Regex zero-width match
        return _children
      }

      // Capture the string that leads up to a match...
      const nonMatch = remaining.slice(0, boundaries?.first)
      if (nonMatch) {
        _children.push(renderPlain(nonMatch))
      }

      // Now, capture the matching string...
      const match = remaining.slice(boundaries?.first, boundaries?.last)
      if (match) {
        _children.push(renderHighlight(match))
      }

      // And if there's anything left over, recursively run this method again.
      remaining = remaining.slice(boundaries?.last)
    }

    return _children
  }

  const renderElement = (subject: ReactNode | string) => {
    const isScalar = /string|number|boolean/.test(typeof children)
    const hasSearch = typeof search !== 'undefined' && search

    if (isScalar && hasSearch) {
      const _search = getSearch(search, caseSensitive, ignoreDiacritics, diacriticsBlacklist)

      return highlightChildren(`${subject}`, _search)
    }

    return children
  }

  return React.createElement('span', rest, renderElement(children))
}

export default Highlighter

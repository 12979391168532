import dot from 'dot-prop-immutable'

import * as atypes from 'common/actions/integrations/common/constants/IntegrationsReduxActionTypes'
import { HubSpotConfig } from 'common/actions/integrations/HubSpot/model/constants'

const initialState = {
  identityField: {
    value: null,
    option: null,
  },
  currentForm: '',
  forms: null,
  contactProperties: null,
  bindings: {},
  mappings: {},
  userFieldPropertiesMappings: [{ contactProperty: '', userField: '' }],
  isFetchingForms: false,
  isFetchingContactFields: false,
  isDisconnecting: false,
}

export default function integrationsReducer(state = initialState, action) {
  switch (action.type) {
    case atypes.HUBSPOT_CLEAR_ACCOUNT_LINK_FIELD_RESPONSE:
    case atypes.HUBSPOT_SET_ACCOUNT_LINK_FIELD_RESPONSE: {
      return dot.set(state, HubSpotConfig.type, action.data.hubspot)
    }
    case atypes.HUBSPOT_FORM_BY_ID_FETCH_REQUEST:
    case atypes.HUBSPOT_FORMS_FETCH_REQUEST: {
      return { ...state, isFetchingForms: true }
    }

    case atypes.HUBSPOT_FORM_BY_ID_FETCH_ERROR:
    case atypes.HUBSPOT_FORMS_FETCH_ERROR: {
      return { ...state, isFetchingForms: false }
    }

    case atypes.HUBSPOT_FORMS_FETCH_RESPONSE: {
      const { data } = action
      return { ...state, forms: data.forms, isFetchingForms: false }
    }

    case atypes.HUBSPOT_FORM_BY_ID_FETCH_RESPONSE: {
      const { data } = action

      return { ...state, currentForm: data.form, isFetchingForms: false }
    }

    case atypes.HUBSPOT_CONTACT_FIELDS_FETCH_REQUEST: {
      return { ...state, isFetchingContactFields: true }
    }

    case atypes.HUBSPOT_CONTACT_FIELDS_FETCH_RESPONSE: {
      const { data } = action
      return { ...state, contactProperties: data.fields, isFetchingContactFields: false }
    }

    case atypes.HUBSPOT_SET_FORM: {
      const { data } = action
      return { ...state, currentForm: data }
    }

    case atypes.HUBSPOT_SET_FORM_BINDINGS: {
      const { data } = action
      return { ...state, bindings: { ...state.bindings, ...data } }
    }

    case atypes.HUBSPOT_SET_CONTACT_MAPPINGS: {
      const { data } = action
      return { ...state, mappings: { ...state.mappings, ...data } }
    }

    case atypes.HUBSPOT_SET_CUF_IN_MAPPINGS: {
      const { data } = action

      return {
        ...state,
        userFieldPropertiesMappings: Object.assign([], state.userFieldPropertiesMappings, {
          [data.index]: {
            ...state.userFieldPropertiesMappings[data.index],
            userField: data.value || '',
          },
        }),
      }
    }

    case atypes.HUBSPOT_SET_PROPERTY_IN_BINDINGS: {
      const { data } = action
      return {
        ...state,
        userFieldPropertiesMappings: Object.assign([], state.userFieldPropertiesMappings, {
          [data.index]: {
            ...state.userFieldPropertiesMappings[data.index],
            contactProperty: data.value || '',
          },
        }),
      }
    }

    case atypes.HUBSPOT_ADD_CUF_PROPERTY_PAIR: {
      return {
        ...state,
        userFieldPropertiesMappings: [
          ...state.userFieldPropertiesMappings,
          { contactProperty: '', userField: '' },
        ],
      }
    }

    case atypes.HUBSPOT_DELETE_CUF_PROPERTY_PAIR: {
      const { data } = action
      const newMappings = [...state.userFieldPropertiesMappings]
      newMappings.splice(data.index, 1)
      return {
        ...state,
        userFieldPropertiesMappings: newMappings,
      }
    }

    case atypes.HUBSPOT_SET_CUF_PROPERTY_BINDINGS: {
      const { data } = action

      return {
        ...state,
        userFieldPropertiesMappings: data.length ? data : initialState.userFieldPropertiesMappings,
      }
    }

    case atypes.HUBSPOT_SET_IDENTITY_FIELD: {
      const { data } = action

      return {
        ...state,
        identityField: data,
      }
    }

    case atypes.INTEGRATION_RESET: {
      return {
        ...state,
        ...initialState,
      }
    }

    case atypes.HUBSPOT_FORM_FIELDS_REFRESH_RESPONSE: {
      const { data } = action
      return {
        ...state,
        currentForm: { ...state.currentForm, fields: data.fields },
      }
    }

    case atypes.INTEGRATION_DISCONNECT_REQUEST: {
      return { ...state, isDisconnecting: true }
    }
    case atypes.INTEGRATION_DISCONNECT_RESPONSE: {
      return { ...state, isDisconnecting: false }
    }
  }

  return state
}

import { MessagingType } from 'apps/posting/constants/MessagingType'
import { NotificationType } from 'common/core/constants/NotificationType'
import { TimezoneType } from 'common/core/constants/TimezoneType'

import { Status } from './constants'

export default {
  id: null,
  dependencies: null,
  contentId: null,
  flowId: null,
  chId: null,
  preview: '',

  status: Status.DRAFT,
  updated: null, // todo переименовать, это не время последнего обновления а кокойто магический timestamp
  ts_schedule: null,

  filter: null,
  messagingType: MessagingType.SUBSCRIPTION,
  notification: NotificationType.REGULAR,
  schedule: null,
  timezone: {
    type: TimezoneType.ALL,
  },

  stats: null, // {clicked: 0,delivered: 0,read: 0,sent: 0,}
  sendingStats: null, //{messagesProcessed, messagesTotal, etaSeconds}
  messageTag: null,
  $recipients: 0,
  $recipientsQuery: {},
  $forceSendingInProgress: false,
  $interruptSending: false,
}

import { ZENDESK_HELP_CENTER_URL } from './constants'

export enum Links {
  HOME = 'HOME',
  LOGIN = 'LOGIN',
  MAIN_MENU = 'MAIN_MENU',
  DEV_PROGRAM_QUICK_START = 'DEV_PROGRAM_QUICK_START',
  DEV_EXTERNAL_REQUIEST = 'DEV_EXTERNAL_REQUIEST',
  CANT_ADD_PAGE = 'CANT_ADD_PAGE',
  BROADCASTING = 'BROADCASTING',
  BROADCASTING_PAID_MESSAGES = 'BROADCASTING_PAID_MESSAGES',
  LIVE_CHAT = 'LIVE_CHAT',
  FLOW_CONVERTER = 'FLOW_CONVERTER',
  FLOW_BUILDER = 'FLOW_BUILDER',
  BUY_BUTTON = 'BUY_BUTTON',
  RECIEVE_PAYMENT_BUY_BUTTON = 'RECIEVE_PAYMENT_BUY_BUTTON',
  MANYCHAT_PIXEL = 'MANYCHAT_PIXEL',
  GET_FIRST_SUBSCRIBERS = 'GET_FIRST_SUBSCRIBERS',
  SETTING_UP_A_BUSINESS_FROM_ADDRESS = 'SETTING_UP_A_BUSINESS_FROM_ADDRESS',
  WARMING_YOUR_SENDING_REPUTATION = 'WARMING_YOUR_SENDING_REPUTATION',
  WEBSITES_AUTHORIZATION = 'WEBSITES_AUTHORIZATION',
  BOT_TIME_ZONE = 'BOT_TIME_ZONE',
  CHECK_WHATSAPP_READY = 'CHECK_WHATSAPP_READY',
  TEAM_MEMBERS_GROUPS = 'TEAM_MEMBERS_GROUPS',
  UNDERSTANDING_INVOICE = 'UNDERSTANDING_INVOICE',
  MANYCHAT_API = 'MANYCHAT_API',
  USER_ROLES = 'USER_ROLES',
  KLAVIYO_INTEGRATION = 'KLAVIYO_INTEGRATION',
  ACTIVECAMPAIGN_INTEGRATION = 'ACTIVECAMPAIGN_INTEGRATION',
  CONVERTKIT_INTEGRATION = 'CONVERTKIT_INTEGRATION',
  HUBSPOT_INTEGRATION = 'HUBSPOT_INTEGRATION',
  MAILCHIMP_INTEGRATION = 'MAILCHIMP_INTEGRATION',
  PAYPAL_INTEGRATION = 'PAYPAL_INTEGRATION',
  GOOGLE_SHEETS_INTEGRATION = 'GOOGLE_SHEETS_INTEGRATION',
  WHATSAPP_LIMITS = 'WHATSAPP_LIMITS',
  SENDING_SMS = 'SENDING_SMS',
  SENDING_EMAIL = 'SENDING_EMAIL',
  FB_BLOCKED = 'FB_BLOCKED',
  CONVERSATIONS_AUTO_ASSIGNMENT = 'CONVERSATIONS_AUTO_ASSIGNMENT',
  HOW_TO_USE_KEYWORDS = 'HOW_TO_USE_KEYWORDS',
  HOW_TO_INSTALL_TEMPLATE = 'HOW_TO_INSTALL_TEMPLATE',
  IMPORT_CONTACTS = 'IMPORT_CONTACTS',
  CREATING_SEQUENCE = 'CREATING_SEQUENCE',
  WA_CONVERSATION_PRICING = 'WA_CONVERSATION_PRICING',
  WA_MESSAGE_TEMPLATES = 'WA_MESSAGE_TEMPLATES',
  TRIGGERS_ACTION_SYSTEM = 'TRIGGERS_ACTION_SYSTEM',
  IG_CANT_CONNECT = 'IG_CANT_CONNECT',
  IG_REF_URL = 'IG_REF_URL',
  IG_ADS_HOW_TO = 'IG_ADS_HOW_TO',
  FB_COMMENTS_GROWTH_TOOL = 'FB_COMMENTS_GROWTH_TOOL',
  FB_COMMENTS_TRIGGER_2 = 'FB_COMMENTS_TRIGGER_2',
  FB_ADS_HOW_TO = 'FB_ADS_HOW_TO',
  HANDOVER_PROTOCOL = 'HANDOVER_PROTOCOL',
  WA_CREDIT_LINE = 'WA_CREDIT_LINE',
  DISABLE_FACEBOOK = 'DISABLE_FACEBOOK',
  DISABLE_INSTAGRAM = 'DISABLE_INSTAGRAM',
  DISABLE_TIKTOK = 'DISABLE_TIKTOK',
  CHANNELS = 'CHANNELS',
  MANAGE_ACCOUNT = 'MANAGE_ACCOUNT',
  CLICK_TO_WHATSAPP_ADS_TRIGGER = 'CLICK_TO_WHATSAPP_ADS_TRIGGER',
  TT_ADS_INTEGRATION = 'TT_ADS_INTEGRATION',
  HOW_TO_CONNECT_WHATSAPP = 'HOW_TO_CONNECT_WHATSAPP',
  WAYS_TO_CONNECT_WHATSAPP = 'WAYS_TO_CONNECT_WHATSAPP',
  CONNECT_WA_WITH_COEXISTENCE = 'CONNECT_WA_WITH_COEXISTENCE',
}

type LinkMapperType = {
  [key in Links]: string
}

const LinkMapper: LinkMapperType = {
  HOME: `/hc`,
  LOGIN: `/login`,
  MAIN_MENU: `/hc/articles/14281211388444`,
  DEV_PROGRAM_QUICK_START: `/hc/articles/14281299586972`,
  DEV_EXTERNAL_REQUIEST: `/hc/articles/14281285374364`,
  CANT_ADD_PAGE: `/hc/articles/14281438557468`,
  BROADCASTING: `/hc/articles/14281228205212`,
  BROADCASTING_PAID_MESSAGES: `/hc/articles/14281213065628`,
  LIVE_CHAT: `/hc/articles/14281070478748-Live-Chat`,
  FLOW_CONVERTER: `/hc/articles/14281167702428`,
  FLOW_BUILDER: `/hc/articles/14281166306332`,
  BUY_BUTTON: `/hc/articles/14281189285276`,
  MANYCHAT_PIXEL: `/hc/articles/14281244168476`,
  GET_FIRST_SUBSCRIBERS: `/hc/articles/14281069537820`,
  SETTING_UP_A_BUSINESS_FROM_ADDRESS: `/hc/articles/14281219827100`,
  WARMING_YOUR_SENDING_REPUTATION: `/hc/articles/14281174115740`,
  WEBSITES_AUTHORIZATION: `/hc/articles/14281412896668`,
  BOT_TIME_ZONE: `/hc/articles/14281213783068`,
  CHECK_WHATSAPP_READY: `/hc/articles/14959925356572-User-Guide-How-to-Check-if-Your-WhatsApp-Account-is-Ready-to-Use`,
  TEAM_MEMBERS_GROUPS: `/hc/articles/14281101610524`,
  UNDERSTANDING_INVOICE: `/hc/articles/14281408754716`,
  DISABLE_FACEBOOK: `/hc/articles/14281480335772-How-to-remove-a-Facebook-page-from-Manychat`,
  DISABLE_INSTAGRAM: '/hc/articles/15582498909724-How-to-remove-an-Instagram-account-from-Manychat',
  CHANNELS: '/hc/categories/13556929063068-Channels',
  MANYCHAT_API: `/hc/articles/14959510331420-API-Manychat`,
  USER_ROLES: `/hc/articles/14281172274460`,
  RECIEVE_PAYMENT_BUY_BUTTON: `/hc/articles/14281173246492`,
  KLAVIYO_INTEGRATION: `/hc/articles/14281297264924`,
  ACTIVECAMPAIGN_INTEGRATION: `/hc/articles/14281265996700`,
  CONVERTKIT_INTEGRATION: `/hc/articles/14281282083100`,
  HUBSPOT_INTEGRATION: `/hc/articles/14281295968668`,
  MAILCHIMP_INTEGRATION: `/hc/articles/14281265259676`,
  PAYPAL_INTEGRATION: `/hc/articles/14281229720476`,
  GOOGLE_SHEETS_INTEGRATION: `/hc/articles/14281248811292`,
  WHATSAPP_LIMITS: `/hc/articles/14281346817308`,
  SENDING_SMS: `/hc/articles/14281249064092`,
  SENDING_EMAIL: `/hc/articles/14281215436188`,
  FB_BLOCKED: `/hc/articles/14281439783324`,
  CONVERSATIONS_AUTO_ASSIGNMENT: `/hc/articles/14281072676764`,
  HOW_TO_USE_KEYWORDS: `/hc/articles/14281211785884`,
  HOW_TO_INSTALL_TEMPLATE: `/hc/articles/14281251748508`,
  IMPORT_CONTACTS: `/hc/articles/14281086370588`,
  CREATING_SEQUENCE: `/hc/articles/14281202572316`,
  WA_CONVERSATION_PRICING: `/hc/articles/14281379879196`,
  WA_MESSAGE_TEMPLATES: `/hc/articles/14281326740124`,
  TRIGGERS_ACTION_SYSTEM: `/hc/articles/14281170185628`,
  IG_CANT_CONNECT: `/hc/articles/14281274192284`,
  IG_REF_URL: `/hc/articles/14281276006684`,
  IG_ADS_HOW_TO: `/hc/articles/14281321942428`,
  FB_COMMENTS_GROWTH_TOOL: `/hc/articles/14281343933340`,
  FB_COMMENTS_TRIGGER_2: `/hc/articles/14281386567836`,
  FB_ADS_HOW_TO: `/hc/articles/14281372007196`,
  HANDOVER_PROTOCOL: `/hc/articles/14281188830748`,
  WA_CREDIT_LINE: `hc/articles/16339428975900-WhatsApp-Credit-Line-troubleshooting`,
  MANAGE_ACCOUNT: `hc/articles/14281100962460-How-to-sign-up-sign-in-manage-your-Manychat-accounts`,
  CLICK_TO_WHATSAPP_ADS_TRIGGER: `hc/articles/17154181720476-WhatsApp-Ads-Trigger`,
  DISABLE_TIKTOK: `/hc/articles/18237526613148`,
  TT_ADS_INTEGRATION: `/hc/articles/18488562322076`,
  HOW_TO_CONNECT_WHATSAPP: `/hc/articles/14959925356572-How-to-connect-your-WhatsApp-account-to-Manychat`,
  WAYS_TO_CONNECT_WHATSAPP: `hc/en-us/articles/19247843644060-How-to-connect-WhatsApp-to-Manychat-choosing-the-best-method`,
  CONNECT_WA_WITH_COEXISTENCE: `hc/articles/19006109300508-Connect-with-Coexistence-Use-both-WhatsApp-Business-App-and-Manychat`,
}

const getKnowledgeBaseLink = (page?: Links): string => {
  const link = page ? LinkMapper[page] : null
  const url = new URL(ZENDESK_HELP_CENTER_URL)

  if (!link) {
    return url.href
  }

  url.pathname = link

  return url.href
}

const KnowledgeBaseLink: Record<Links, string> = Object.keys(LinkMapper).reduce(
  (acc: Record<Links, string>, key) => {
    acc[key as Links] = getKnowledgeBaseLink(key as Links)
    return acc
  },
  {} as Record<Links, string>,
)

export default KnowledgeBaseLink

import { ProProduct, ProductType } from 'common/core/interfaces/products'
import { CouponInfo, DiscountPeriod } from 'shared/api/requests/billing/schemas'

import { AddOnSlug } from './addOnTypes'

export enum DiscountType {
  COUPON = 'coupon',
  INTERNAL_COUPON = 'internal_coupon',
}

export enum DiscountUnit {
  PERCENT = 'percent',
}

interface DiscountBase {
  /**
   * Period unit: days, months, years, etc.
   */
  periodUnit: DiscountPeriod
  /**
   * Amount of period units
   */
  period: number
  /**
   * Units with wich discount is applied: percents
   * or amount of currency units
   */
  discountUnit: DiscountUnit
  /**
   * Discount value: number of percents or amount
   * of currency units
   */
  discount: number

  /**
   * Discounted plans
   */
  plans: ProProduct[]

  /**
   * Discounted add-ons
   */
  addOns: AddOnSlug[]
}

interface CouponDiscount extends DiscountBase {
  type: DiscountType.COUPON
  coupon: CouponInfo
}

interface InternalCouponDiscount extends DiscountBase {
  type: DiscountType.INTERNAL_COUPON
}

export type Discount = CouponDiscount | InternalCouponDiscount

export interface ProductDiscount {
  /**
   * Applied discount type: coupons
   */
  type: DiscountType
  /**
   * Period unit: days, months, years, etc.
   */
  periodUnit: DiscountPeriod
  /**
   * Amount of period units
   */
  period: number
  /**
   * Units with wich discount is applied: percents
   * or amount of currency units
   */
  discountUnit: DiscountUnit
  /**
   * Discount value: number of percents or amount
   * of currency units
   */
  discount: number
  /**
   * Discounted price
   */
  price: number
}

export interface CartProductBase {
  isInCart?: boolean
  disabled?: boolean
  name: string
  price: number
  dynamicPrice?: boolean
  order?: number
  fullPrice?: number
  discount?: ProductDiscount
}

export interface CartProductPlan extends CartProductBase {
  type: 'plan'
  slug: ProProduct
}

export interface CartProductAddOn extends CartProductBase {
  type: 'addOn'
  slug: AddOnSlug
  requiredPlan?: ProProduct
}

/**
 * Cart can contain two product types: plans (`inbox`, `automation`) and add-ons (`ai_addon`)
 */
export type CartProduct = CartProductPlan | CartProductAddOn

export type CartProductType = CartProduct['type']

export interface CartSlug {
  plans: ProductType[]
  addOns: AddOnSlug[]
}

export interface CartProductsData {
  plans: CartProductPlan[]
  addOns: CartProductAddOn[]
}

import { z } from 'zod'

export const lastActiveFeedCommentWithNextPostWidget = z.object({
  flow_ns: z.string().or(z.number()),
  widget_id: z.string().or(z.number()),
})

export const widgetFbPost = {
  id: z.string(),
  promotable_id: z.string(),
  status_type: z.string().optional(),
  message: z.string().optional(),
  permalink_url: z.string(),
  created_time: z.string(),
  is_eligible_for_promotion: z.boolean(),
  is_hidden: z.boolean(),
  is_published: z.boolean(),
  picture: z.string().optional(),
  full_picture: z.string().optional(),
}

export const widgetFbPostsSchema = {
  path: z.undefined(),
  query: z.undefined(),
  request: z.undefined(),
  response: z.object({
    posts: z.array(z.object(widgetFbPost)),
    next_page: z.string().nullable(),
  }),
}

export const growthToolsSchemas = {
  lastActiveFeedCommentWithNextPost: {
    path: z.undefined(),
    query: z.undefined(),
    request: z.undefined(),
    response: z.object({
      widget: lastActiveFeedCommentWithNextPostWidget.nullable(),
    }),
  },

  searchFBPost: {
    path: z.undefined(),
    query: z.object({ search_query: z.string() }),
    request: z.undefined(),
    response: z.object({
      post: z.object(widgetFbPost),
    }),
  },

  widgetFBPosts: widgetFbPostsSchema,
  widgetGetFBPosts: {
    ...widgetFbPostsSchema,
    query: z
      .object({
        next_page: z.string(),
      })
      .optional(),
  },

  logCheckingResult: {
    path: z.undefined(),
    response: z.undefined(),
    query: z.undefined(),
    request: z.object({
      log_id: z.number(),
      messages: z.array(z.string()),
    }),
  },
}

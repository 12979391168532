import { z } from 'zod'

import { IntegrationType } from 'common/actions/integrations/common/constants/IntegrationType'

export enum SalesforceFieldType {
  ADDRESS = 'address',
  ANY = 'anyType',
  BASE64 = 'base64',
  BOOLEAN = 'boolean',
  BYTE = 'byte',
  CALCULATED = 'calculated',
  COMBOBOX = 'combobox',
  CURRENCY = 'currency',
  DATE = 'date',
  DATETIME = 'datetime',
  DOUBLE = 'double',
  EMAIL = 'email',
  ID = 'id',
  INT = 'int',
  LONG = 'long',
  PHONE = 'phone',
  PICKLIST = 'picklist',
  REFERENCE = 'reference',
  STRING = 'string',
  TEXTAREA = 'textarea',
  TIME = 'time',
  URL = 'url',
}

const integrationIdentityFieldSchema = z.object({
  field_id: z.string().nullable(),
  field_name: z.string().nullable(),
})

const integrationDataSchema = z.object({
  account_id: z.string(),
  account_name: z.string(),
  linking_settings: z
    .object({
      email_custom_field_id: z.number().nullable(),
      email_custom_field_name: z.string().nullable(),
      email_custom_field_type: z.string().nullable(),
    })
    .nullable(),
  status: z.string(),
  type: z.nativeEnum(IntegrationType),
})

const contactFieldSchema = z.object({
  name: z.string(),
  label: z.string(),
  type: z.nativeEnum(SalesforceFieldType),
  unique: z.boolean(),
})

export const salesforceApiSchemas = {
  setAccountLinkField: {
    path: z.undefined(),
    query: z.undefined(),
    request: integrationIdentityFieldSchema,
    response: z.object({
      salesforce: integrationDataSchema,
    }),
  },
  clearAccountLinkField: {
    path: z.undefined(),
    query: z.undefined(),
    request: z.undefined(),
    response: z.object({
      salesforce: integrationDataSchema,
    }),
  },
  getContactFields: {
    path: z.undefined(),
    query: z.undefined(),
    request: z.undefined(),
    response: z.object({
      fields: z.array(contactFieldSchema),
    }),
  },
  exportLiveChatHistory: {
    path: z.undefined(),
    query: z.undefined(),
    request: z.object({
      integration: z.string(),
      subscriber_id: z.string(),
    }),
    response: z.undefined(),
  },
}

export type IdentityField = z.infer<typeof integrationIdentityFieldSchema>
export type IntegrationData = z.infer<typeof integrationDataSchema>
export type ContactField = z.infer<typeof contactFieldSchema>

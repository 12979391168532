import { Field } from 'common/fields/entities/field'

export const getFieldIconName = (fieldType: string) =>
  fieldType.replace('system_', '').replace('gaf_', '')

export const getHaveMoreThatOneGroup = (fields: Field[]) =>
  new Set(fields.map((field) => field.group)).size > 1

export interface Deprecation {
  pattern: RegExp
  getCaption: (id: string) => string
}

/**
 * List of deprecated integration variables/fields matchers.
 */
export const DeprecatedFields: Deprecation[] = [
  // shopify
  {
    pattern: /{?{?integration_user_field\.shopify\..*}?}?/,
    getCaption: () => `Shopify (deprecated)`,
  },
  //shopify coupons
  {
    pattern: /{?{?coupon\.[^\n{}]{1,}}?}?/,
    getCaption: () => `Coupon (deprecated)`,
  },
]

import { ConfirmationLayout } from 'common/core/components/EmailConfirmationPage/ConfirmationLayout'

export const BusinessEmailConfirmationRoutes = [
  {
    path: 'businessEmail',
    Component: ConfirmationLayout,
    children: [
      {
        path: 'confirm/:confirmation_hash',
        lazy: () => import('./businessEmailConfirmation.page'),
      },
    ],
  },
] as const

import React from 'react'

interface CheckboxBackgroundIconProps {
  className: string
}

export const CheckboxBackgroundIcon = ({ className }: CheckboxBackgroundIconProps) => {
  return (
    <svg viewBox="0 0 24 24" width={24} height={24} className={className}>
      <path d="M19,3H5C3.9,3,3,3.9,3,5v14c0,1.1,0.9,2,2,2h14c1.1,0,2-0.9,2-2V5C21,3.9,20.1,3,19,3z" />
    </svg>
  )
}

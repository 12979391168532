import dot from 'dot-prop-immutable'
import find from 'lodash/find'
import keyBy from 'lodash/keyBy'
import uniq from 'lodash/uniq'
import without from 'lodash/without'

import * as accountAtypes from 'apps/account/accountReduxActionTypes'
import * as AdsATypes from 'apps/ads/constants/AdsReduxActionTypes'
import * as agencyATypes from 'apps/agency/constants/AgencyReduxActionTypes'
import * as iceBreakersAtypes from 'apps/iceBreakers/constants/iceBreakersReduxActionTypes'
import * as instagramAtypes from 'apps/instagram/constants/instagramReduxActionTypes'
import * as profileAtypes from 'apps/profile/constants/profileReduxActionTypes'
import * as smsAtypes from 'apps/sms/smsReduxActionTypes'
import { reducer as telegramReducer } from 'apps/telegram/redux/telegramSlice'
import * as tiktokAtypes from 'apps/tiktok/constants/tiktokReduxActionTypes'
import { reducer as waChannelReducer } from 'apps/whatsApp/redux/waChannelSlice'
import * as integrationsAtypes from 'common/actions/integrations/common/constants/IntegrationsReduxActionTypes'
import {
  cancelSubscription,
  cancelSubscriptionWithRefund,
  fetchPro,
  proStatusUpdate,
} from 'common/billing/actions/billingActions'
import { setExperiment, setupExperiment } from 'common/billing/actions/experimentActions'
import { manageSubscription } from 'common/billing/actions/upgradeActions'
import { experimentFlags } from 'common/billing/constants/BillingExperiment'
import * as appAtypes from 'common/core/constants/appReduxActionTypes'
import { AccountStatus } from 'common/core/models/Account/constants'
import { reducer as userActivityReducer } from 'common/core/reducers/confirmUserActivitySlice'
import { setCurrency } from 'common/settings/actions/settingsActionsTyped'
import installedApplicationsReducer from 'common/settings/components/SettingsPanel/AppSection/installedApplications/reducers/installedApplications'
import * as settingsActionTypes from 'common/settings/SettingsReduxActionTypes'
import { billingFlexApi } from 'shared/api/requests/billingFlex'
import { makeLimitedListReducer } from 'utils/factory'
import { parseInitApp } from 'utils/parseInitApp'

import { abilitiesUpdated } from '../actions/abilitiesActions'
import { ChannelType } from '../constants/ChannelType'

const InitialCurrentAccountState = {
  installed_applications: [],
  is_ig_summer_promo_activated: false,
  fb_channel: {},
  instagram_channel: {},
  sms_channel: {},
  email_channel: {},
  products: {
    has_inbox: false,
    has_automation: false,
  },
  abilities: [],
}

export const initialState = {
  currentAccount: InitialCurrentAccountState,
  actualAppVersion: '',
  notifications: [],
  closedNotificationIds: [],
  accounts: [],
  accountsCount: 0,
  activeTab: null,
  title: '',
  optIn: {
    subscriberId: null,
    [ChannelType.FB]: {
      optInRequired: false,
      payload: null,
      updateLastInteractionRequired: false,
    },
    [ChannelType.SMS]: {
      optInRequired: false,
      errors: [],
      subscriberErrors: [], // ISubscriberError[]
    },
    [ChannelType.EMAIL]: {
      optInRequired: false,
      errors: [],
      subscriberErrors: [], // ISubscriberError[]
      defaultEmail: '',
    },
    [ChannelType.WHATSAPP]: {
      optInRequired: false,
      link: null,
      updateLastInteractionRequired: false,
    },
  },
  fbsdk: {
    loaded: false,
    locale: null,
  },
  userID: null,
  user: {
    isVerifyingEmail: false,
  },
}

const pagesReducer = makeLimitedListReducer('pages')

export default function appReducer(state = initialState, action) {
  const pages = pagesReducer(state.pages, action)
  if (pages !== state.pages) {
    state = { ...state, pages }
  }

  const installed_applications = installedApplicationsReducer(
    state.currentAccount.installed_applications,
    action,
  )
  if (state.currentAccount.installed_applications !== installed_applications) {
    state = {
      ...state,
      currentAccount: {
        ...state.currentAccount,
        installed_applications,
      },
    }
  }

  switch (action.type) {
    case 'SET_PRO_STATUS_BLOCKED': {
      const { subscribers_total, reasons, limits } = action.payload

      return dot.merge(state, 'currentAccount', {
        is_blocked: true,
        subscribers_total,
        block_reasons: reasons,
        block_limits: limits,
      })
    }

    case cancelSubscriptionWithRefund.fulfilled.type: {
      return dot.merge(state, 'currentAccount', {
        is_blocked: false,
        pro_status: AccountStatus.EXPIRED2020,
      })
    }

    case 'SET_PRO_STATUS_UNBLOCKED': {
      return dot.merge(state, 'currentAccount', {
        is_blocked: false,
        pro_status: action.payload || AccountStatus.FREE2020,
      })
    }

    case appAtypes.APP_UPDATE: {
      if (!action?.data) {
        return { ...state }
      }

      const app = parseInitApp(action.data)

      return { ...state, ...app }
    }

    case appAtypes.APP_UPDATE_CURRENT_ACCOUNT_ID: {
      return {
        ...state,
        currentAccount: {
          ...state.currentAccount,
          id: action.accountId,
        },
      }
    }

    case appAtypes.APP_UPDATE_CURRENT_ACCOUNT: {
      return dot.merge(state, 'currentAccount', action.changes)
    }

    case appAtypes.APP_RESET_CURRENT_ACCOUNT: {
      return {
        ...state,
        currentAccount: InitialCurrentAccountState,
      }
    }

    case appAtypes.APP_LOAD_EXTENDED_ACCOUNT_DATA_SUCCESS_RESPONSE: {
      const updatedCurrentAccount = action?.data?.data
      if (!updatedCurrentAccount) return state

      return dot.merge(state, 'currentAccount', {
        ...updatedCurrentAccount,
        isExtendedDataLoaded: true,
      })
    }

    case appAtypes.APP_UPDATE_THREADS_STATS: {
      return dot.merge(state, 'currentAccount.threads_statuses', action.data)
    }

    case appAtypes.APP_APPLY_OPTIN_REQUIRED: {
      return dot.merge(state, `optIn.${ChannelType.FB}`, {
        optInRequired: true,
        payload: action.payload,
        updateLastInteractionRequired: Boolean(action.updateLastInteractionRequired),
      })
    }

    case appAtypes.APP_DROP_OPTIN_REQUIRED: {
      return dot.merge(state, `optIn.${ChannelType.FB}`, {
        optInRequired: false,
        payload: null,
        updateLastInteractionRequired: false,
      })
    }

    case appAtypes.APP_APPLY_SMS_EMAIL_OPTIN_REQUIRED: {
      state = dot.merge(state, `optIn`, {
        subscriberId: action.subscriberId,
      })

      state = dot.merge(state, `optIn.${ChannelType.SMS}`, {
        optInRequired: Boolean(action.smsOptInRequired),
      })

      state = dot.merge(state, `optIn.${ChannelType.EMAIL}`, {
        optInRequired: Boolean(action.emailOptInRequired),
        defaultEmail: action.defaultEmail || '',
      })

      return state
    }

    case appAtypes.APP_DROP_SMS_EMAIL_OPTIN_REQUIRED: {
      state = dot.merge(state, `optIn`, {
        subscriberId: null,
      })

      state = dot.merge(state, `optIn.${ChannelType.SMS}`, {
        optInRequired: false,
      })
      state = dot.merge(state, `optIn.${ChannelType.EMAIL}`, {
        optInRequired: false,
        defaultEmail: '',
      })

      return state
    }

    case appAtypes.APP_SET_SMS_EMAIL_OPTIN_ERRORS: {
      if (action.subscriberErrors) {
        const ChannelTypeByErrorType = {
          phone_number: ChannelType.SMS,
          email: ChannelType.EMAIL,
        }

        const subscriberErrorsByChannel = { [ChannelType.SMS]: [], [ChannelType.EMAIL]: [] }

        action.subscriberErrors.forEach((error) => {
          subscriberErrorsByChannel[ChannelTypeByErrorType[error.type]].push({
            subscriberId: error.id,
            channel: ChannelTypeByErrorType[error.type],
          })
        })

        // If a channel doesn't has subscriberErrors, it will be reset, it's ok
        state = dot.merge(state, `optIn.${ChannelType.EMAIL}`, {
          subscriberErrors: subscriberErrorsByChannel[ChannelType.EMAIL],
        })
        state = dot.merge(state, `optIn.${ChannelType.SMS}`, {
          subscriberErrors: subscriberErrorsByChannel[ChannelType.SMS],
        })
      }

      if (action.smsErrors) {
        state = dot.merge(state, `optIn.${ChannelType.SMS}`, {
          errors: action.smsErrors,
        })
      }
      if (action.emailErrors) {
        state = dot.merge(state, `optIn.${ChannelType.EMAIL}`, {
          errors: action.emailErrors,
        })
      }

      return state
    }

    case appAtypes.APP_DROP_SMS_EMAIL_OPTIN_ERRORS: {
      state = dot.merge(state, `optIn.${ChannelType.SMS}`, {
        errors: [],
        subscriberErrors: [],
      })
      state = dot.merge(state, `optIn.${ChannelType.EMAIL}`, {
        errors: [],
        subscriberErrors: [],
      })

      return state
    }

    case appAtypes.APP_APPLY_WHATSAPP_OPTIN_REQUIRED: {
      return dot.merge(state, `optIn.${ChannelType.WHATSAPP}`, {
        optInRequired: true,
        link: action.link,
        updateLastInteractionRequired: Boolean(action.updateLastInteractionRequired),
      })
    }

    case appAtypes.APP_DROP_WHATSAPP_OPTIN_REQUIRED: {
      return dot.merge(state, `optIn.${ChannelType.WHATSAPP}`, {
        optInRequired: false,
        link: null,
        updateLastInteractionRequired: false,
      })
    }

    case appAtypes.APP_APPLY_TELEGRAM_OPTIN_REQUIRED: {
      return dot.merge(state, `optIn.${ChannelType.TELEGRAM}`, {
        optInRequired: true,
        link: action.link,
        botName: action.botName,
      })
    }

    case appAtypes.APP_DROP_TELEGRAM_OPTIN_REQUIRED: {
      return dot.merge(state, `optIn.${ChannelType.TELEGRAM}`, {
        optInRequired: false,
        link: null,
        botName: null,
      })
    }

    case appAtypes.APP_SET_ACTIVE_TAB: {
      return { ...state, activeTab: action.tab }
    }

    case appAtypes.APP_SET_TITLE: {
      return { ...state, title: action.title }
    }

    case appAtypes.APP_LOAD_FBSDK_SUCCESS: {
      return { ...state, fbsdk: { loaded: true, locale: action.locale } }
    }

    case appAtypes.APP_SET_BOT_TIMEZONE: {
      return dot.set(state, 'currentAccount.timezone', action.data.timezone)
    }

    case appAtypes.APP_SET_PAGE_NAME: {
      return dot.set(state, 'currentAccount.title', action.data.page_name)
    }

    case appAtypes.APP_RESET_BOT_TIMEZONE: {
      return dot.set(state, 'currentAccount.timezone', null)
    }

    case appAtypes.APP_LOAD_FBSDK_REQUEST: {
      return { ...state, fbsdk: initialState.fbsdk }
    }

    case appAtypes.STATIC_VERSION_FETCH_RESPONSE: {
      const { data } = action
      return { ...state, actualAppVersion: data }
    }

    case settingsActionTypes.SETTINGS_NOTIFICATION_REMOVE_REQUEST: {
      const { notification_id } = action
      return {
        ...state,
        notifications: without(
          state.notifications,
          find(
            state.notifications,
            (notification) => String(notification.notification_id) === String(notification_id),
          ),
        ),
      }
    }

    case settingsActionTypes.SETTINGS_NOTIFICATION_TEMPORARY_REMOVE: {
      return {
        ...state,
        closedNotificationIds: uniq([...state.closedNotificationIds, action.notification_id]),
      }
    }

    case settingsActionTypes.SETTINGS_NOTIFICATION_REMOVE_FROM_CLOSED_LIST: {
      const { notificationId } = action
      const resultArrayNotifications = state.closedNotificationIds.filter(
        (el) => notificationId !== el,
      )

      return {
        ...state,
        closedNotificationIds: resultArrayNotifications,
      }
    }

    case settingsActionTypes.SETTINGS_NOTIFICATIONS_SET: {
      return { ...state, notifications: action.payload }
    }

    case appAtypes.READ_POLICY_ENFORCEMENT_NOTIFICATION_NOTIFICATION:
    case appAtypes.READ_ALL_POLICY_ENFORCEMENT_NOTIFICATIONS_FETCH_RESPONSE: {
      return dot.set(state, 'currentAccountUser.fbPolicyEnforcementNotifications', [])
    }

    case appAtypes.NEW_POLICY_ENFORCEMENT_NOTIFICATION_NOTIFICATION: {
      return dot.merge(state, 'currentAccountUser.fbPolicyEnforcementNotifications', [action.item])
    }
    case appAtypes.LIST_SUSPECTED_FB_POLICY_OFFENDERS_FETCH_REQUEST: {
      return dot.merge(state, 'currentAccount.suspectedFbPolicyOffenders', {
        fetching: true,
      })
    }

    case appAtypes.LIST_SUSPECTED_FB_POLICY_OFFENDERS_FETCH_RESPONSE: {
      return dot.merge(state, 'currentAccount.suspectedFbPolicyOffenders', {
        fetching: false,
        data: action.data.list,
      })
    }
    case appAtypes.LIST_SUSPECTED_FB_POLICY_OFFENDERS_FETCH_ERROR: {
      return dot.merge(state, 'currentAccountUser.suspectedFbPolicyOffenders', {
        fetching: false,
      })
    }

    case settingsActionTypes.SETTINGS_UPDATE_RESPONSE: {
      const { data } = action
      return { ...state, paymentCurrency: data.settings.payments.currency }
    }

    case appAtypes.SAVE_USER_QUESTIONNAIRE: {
      const { data } = action
      return dot.merge(state, 'currentAccountUser.questionnaires', {
        [data.type]: data.status,
      })
    }

    case settingsActionTypes.MESSAGING_FEATURE_REVIEW_RESPONSE: {
      const features = keyBy(action.data.features, 'feature_name')
      state = dot.set(
        state,
        'currentAccount.subscription_permission_status',
        features.subscription_messaging?.status,
      )
      state = dot.set(
        state,
        'currentAccount.one_time_notify_status',
        features.one_time_notif?.status,
      )
      return state //
    }

    case settingsActionTypes.SETTINGS_DEVLOGS_UPDATE_LAST_READ: {
      return dot.set(state, 'currentAccount.last_devlog_critical_message_ts', action.data.timestamp)
    }

    case settingsActionTypes.SETTINGS_DEVLOGS_MARK_AS_READ_RESPONSE: {
      return dot.merge(state, 'currentAccountUser', action.data.accountUser)
    }

    case integrationsAtypes.INTEGRATION_DISCONNECT_RESPONSE:
    case appAtypes.APP_UPDATE_CONNECTED_INTEGRATIONS: {
      return dot.set(
        state,
        'currentAccount.connected_integrations',
        action.data.connected_integrations,
      )
    }

    case smsAtypes.SMS_ENABLE_CHANNEL_RESPONSE: {
      return dot.set(state, 'currentAccount.sms_channel.sms_channel_connected', true)
    }
    case smsAtypes.SMS_DISABLE_CHANNEL_RESPONSE: {
      return dot.set(state, 'currentAccount.sms_channel.sms_channel_connected', false)
    }

    case profileAtypes.PROFILE_VERIFY_EMAIL_REQUEST:
    case profileAtypes.PROFILE_SEND_EMAIL_VERIFICATION_REQUEST: {
      return { ...state, user: { ...state.user, isVerifyingEmail: true } }
    }

    case profileAtypes.PROFILE_VERIFY_EMAIL_RESPONSE:
    case profileAtypes.PROFILE_SEND_EMAIL_VERIFICATION_RESPONSE: {
      const { data: user } = action
      return { ...state, user: { ...state.user, ...user, isVerifyingEmail: false } }
    }

    case profileAtypes.PROFILE_VERIFY_EMAIL_ERROR:
    case profileAtypes.PROFILE_SEND_EMAIL_VERIFICATION_ERROR: {
      return { ...state, user: { ...state.user, isVerifyingEmail: false } }
    }

    case profileAtypes.PROFILE_VERIFY_EMAIL_VIA_CODE_RESPONSE:
    case profileAtypes.PROFILE_CHECK_EMAIL_VERIFY_RESPONSE: {
      const { email, email_unverified } = action.data
      return { ...state, user: { ...state.user, email, email_unverified } }
    }

    case AdsATypes.ADS_PAGE_ENABLE_RESPONSE: {
      return { ...state, currentAccount: { ...state.currentAccount, is_ads_enabled: true } }
    }

    case agencyATypes.AGENCY_CONNECT_PAGE_RESPONSE: {
      return {
        ...state,
        currentAccount: {
          ...state.currentAccount,
          agency_connect_active: true,
        },
      }
    }

    case profileAtypes.PROFILE_SET_TIMEZONE_RESPONSE: {
      const {
        data: { timezone },
      } = action
      return dot.set(state, 'user.timezone', timezone)
    }

    case profileAtypes.PROFILE_SET_DISPLAY_FLAGS_RESPONSE:
    case profileAtypes.PROFILE_SET_DISPLAY_FLAGS_REQUEST: {
      const { display_flag, value } = action

      if (state.user?.interface_data?.display_flags) {
        return dot.merge(state, 'user.interface_data.display_flags', {
          [display_flag]: value,
        })
      }

      return dot.merge(state, 'user.interface_data', {
        display_flags: {
          [display_flag]: value,
        },
      })
    }

    case profileAtypes.CURRENT_ACCOUNT_USER_PROFILE_SET_DISPLAY_FLAGS_RESPONSE:
    case profileAtypes.CURRENT_ACCOUNT_USER_PROFILE_SET_DISPLAY_FLAGS_REQUEST: {
      const { display_flag, value } = action

      if (state.currentAccountUser?.interface_data?.display_flags) {
        return dot.merge(state, 'currentAccountUser.interface_data.display_flags', {
          [display_flag]: value,
        })
      }

      return dot.merge(state, 'currentAccountUser.interface_data', {
        display_flags: {
          [display_flag]: value,
        },
      })
    }

    // Persona API
    case settingsActionTypes.UPLOAD_PERSONA_SOURCE_AVATAR_RESPONSE:
    case settingsActionTypes.UPLOAD_PERSONA_CROPPED_AVATAR_RESPONSE: {
      const personaAPI = dot.get(state, 'currentAccountUser.persona_api', {})

      if (!personaAPI.persona_api_avatar_data) {
        personaAPI.persona_api_avatar_data = {}
      }

      personaAPI.persona_api_avatar_data[
        action.type === settingsActionTypes.UPLOAD_PERSONA_SOURCE_AVATAR_RESPONSE
          ? 'original_avatar_url'
          : 'resize_avatar_url'
      ] = action.data.attachment.img_big

      return {
        ...state,
        currentAccountUser: {
          ...state.currentAccountUser,
          persona_api: personaAPI,
        },
      }
    }
    case settingsActionTypes.SETTINGS_UPDATE_CURRENT_USER_ROLE: {
      return dot.set(state, 'currentAccountUser.user_role', action.role)
    }
    case settingsActionTypes.SETTINGS_UPDATE_CURRENT_USER_BILLING_ACCESS: {
      return dot.set(state, 'currentAccountUser.has_billing_access', action.hasBillingAccess)
    }
    case settingsActionTypes.SAVE_PERSONA_RESPONSE: {
      return dot.set(state, 'currentAccountUser.persona_api', action.data.persona_api)
    }
    case profileAtypes.PROFILE_GENERATE_API_TOKEN_RESPONSE: {
      return dot.set(state, 'user.user_api_token', action.data.token)
    }
    case profileAtypes.PROFILE_DELETE_API_TOKEN_RESPONSE: {
      return dot.set(state, 'user.user_api_token', '')
    }

    case accountAtypes.FETCH_ACCOUNT_RESPONSE: {
      const hasAlreadyFetched = state.accounts.some((acc) => acc.id === action.data.account.id)

      if (hasAlreadyFetched) {
        return state
      }

      return { ...state, accounts: [...state.accounts, action.data.account] }
    }
    case accountAtypes.FETCH_ALL_ACCOUNTS_RESPONSE: {
      return { ...state, accounts: action.data.accounts }
    }

    case appAtypes.FETCH_PREVIEW_REF_URL_RESPONSE: {
      return dot.merge(state, `optIn.${ChannelType.FB}`, {
        previewRefURL: action.data.preview_ref,
      })
    }

    case appAtypes.UPDATE_SKIP_PREVIEW_ONBOARDING_MODAL: {
      return {
        ...state,
        user: { ...state.user, skip_preview_onboarding_modal: action.previewOnboardingModalStatus },
      }
    }

    case appAtypes.CURRENT_ACCOUNT_UPDATE_ASYNC_BILLING_ACTIONS: {
      const { status, url } = action

      return {
        ...state,
        currentAccount: {
          ...state.currentAccount,
          billing_next_action_required: status
            ? {
                redirect_to_url: {
                  next_action_redirect: url,
                  action_type:
                    state.currentAccount.billing_next_action_required?.redirect_to_url?.action_type,
                },
              }
            : null,
        },
      }
    }

    // Ice Breakers
    case iceBreakersAtypes.ICE_BREAKERS_SWITCH_ENABLED_NOTIFICATION:
    case iceBreakersAtypes.ICE_BREAKERS_SWITCH_ENABLED_RESPONSE: {
      const iceBreakers = action.data.ice_breakers || []
      const { channel } = action
      // Not sure why we need to check the channel twice
      const hasInstagramIceBreakers = iceBreakers.some((ib) => ib.channel === ChannelType.INSTAGRAM)
      const hasWhatsAppIceBreakers = iceBreakers.some((ib) => ib.channel === ChannelType.WHATSAPP)
      if (hasInstagramIceBreakers || channel === ChannelType.INSTAGRAM) {
        return dot.set(
          state,
          'currentAccount.instagram_channel.icebreakers_instagram_enabled',
          action.data.is_available,
        )
      } else if (hasWhatsAppIceBreakers || channel === ChannelType.WHATSAPP) {
        return dot.set(
          state,
          'currentAccount.whatsapp_channel.icebreakers_whatsapp_enabled',
          action.data.is_available,
        )
      }

      return dot.set(
        state,
        'currentAccount.fb_channel.icebreakers_enabled',
        action.data.is_available,
      )
    }

    case appAtypes.CURRENT_ACCOUNT_UPDATE_WHATSAPP_CHANNEL: {
      return dot.set(state, 'currentAccount.whatsapp_channel', action.data)
    }

    case settingsActionTypes.FETCH_PRICING_LIMIT_INFO_RESPONSE: {
      return dot.merge(state, 'currentAccount', action.data)
    }

    case instagramAtypes.TOGGLE_INSTAGRAM_CHANNEL_RESPONSE:
      return dot.set(state, 'currentAccount.instagram_channel.is_channel_enabled', action.value)
    case `${instagramAtypes.CONNECT_INSTAGRAM_ACCOUNT}_RESPONSE`: {
      return {
        ...state,
        currentAccount: {
          ...state.currentAccount,
          instagram_channel: {
            ...state.currentAccount.instagram_channel,
            is_channel_connected: true,
            is_channel_enabled: true,
            business_id: action.id,
          },
        },
      }
    }

    case tiktokAtypes.TOGGLE_TIKTOK_CHANNEL_RESPONSE:
      return dot.set(state, 'currentAccount.tiktok_channel.is_channel_enabled', action.value)

    case cancelSubscription.fulfilled.type: {
      if (state.currentAccount.instagram_channel) {
        state = dot.set(
          state,
          'currentAccount.instagram_channel.is_ig_summer_promo_activated',
          false,
        )
      }
      return dot.set(state, 'currentAccount.is_ig_summer_promo_activated', false)
    }

    case appAtypes.ACCOUNT_SET_DISPLAY_FLAG_RESPONSE: {
      const { display_flag, value } = action

      if (state.currentAccount?.interface_data?.display_flags) {
        return dot.merge(state, 'currentAccount.interface_data.display_flags', {
          [display_flag]: value,
        })
      }

      return dot.merge(state, 'currentAccount.interface_data', {
        display_flags: {
          [display_flag]: value,
        },
      })
    }

    case proStatusUpdate.type: {
      return dot.set(state, 'currentAccount.pro_status', action.payload)
    }

    case fetchPro.fulfilled.type: {
      return dot.set(state, 'currentAccount.pro_status', action.payload?.pro.status)
    }

    case abilitiesUpdated.type: {
      return dot.set(state, 'currentAccount.abilities', action.payload)
    }
    case manageSubscription.fulfilled.type: {
      return dot.set(state, 'currentAccount.abilities', action.payload.abilities)
    }
    case manageSubscription.rejected.type: {
      const businessError = action.payload
      if (billingFlexApi.manageSubscription.isManageSubscriptionError(businessError)) {
        const { abilities } = businessError?.error_data || {}
        if (abilities) {
          return dot.set(state, 'currentAccount.abilities', abilities)
        }
      }

      return state
    }
    case setExperiment.type: {
      const currentFlags = dot.get(state, 'currentAccount.flags') || {}
      const isAlreadyInExperiment = experimentFlags.some((flag) => currentFlags[flag])
      if (isAlreadyInExperiment) {
        return state
      }

      const flag = action.payload
      if (!experimentFlags.includes(flag)) {
        return state
      }

      return dot.set(state, ['currentAccount', 'flags', flag], true)
    }
    case setupExperiment.fulfilled.type: {
      const currentFlags = dot.get(state, 'currentAccount.flags') || {}
      const { flags } = action.payload
      const nextFlags = {
        ...currentFlags,
        ...flags,
      }

      return dot.set(state, 'currentAccount.flags', nextFlags)
    }
    case setCurrency.fulfilled.type: {
      const currency = action.payload.settings.payments.currency

      return dot.set(state, 'paymentCurrency', currency)
    }
  }

  state = accountFavoriteReducer(state, action)
  state = whatsAppChannelReducer(state, action)
  state = telegramChannelReducer(state, action)
  state = confirmUserActivityReducer(state, action)

  return state
}

const whatsAppChannelReducer = (state, action) => {
  const result = waChannelReducer(state.currentAccount.whatsapp_channel, action)
  if (result === state.currentAccount.whatsapp_channel) {
    return state
  }

  return {
    ...state,
    currentAccount: {
      ...state.currentAccount,
      whatsapp_channel: waChannelReducer(state.currentAccount.whatsapp_channel, action),
    },
  }
}

const telegramChannelReducer = (state, action) => {
  const result = telegramReducer(state.currentAccount.telegram_channel, action)
  if (result === state.currentAccount.telegram_channel) {
    return state
  }

  return {
    ...state,
    currentAccount: {
      ...state.currentAccount,
      telegram_channel: result,
    },
  }
}

const accountFavoriteReducer = (state, action) => {
  switch (action.type) {
    case accountAtypes.ACCOUNT_FAVORITE_RESPONSE: {
      const index = state.accounts.findIndex((account) => account.page_id === action.accountId)
      if (index > -1) {
        state = dot.set(state, `accounts.${index}.is_favorite`, action.data?.is_favorite)
      }
      break
    }
    case accountAtypes.ACCOUNT_HIDE_RESPONSE: {
      const index = state.accounts.findIndex((account) => account.page_id === action.accountId)
      if (index > -1) {
        state = dot.set(state, `accounts.${index}.is_hidden`, action.data?.is_hidden)
      }
      break
    }
  }

  return state
}

const confirmUserActivityReducer = (state, action) => {
  const result = userActivityReducer(state.user.activity, action)
  if (result === state.user.activity) {
    return state
  }

  return {
    ...state,
    user: {
      ...state.user,
      activity: userActivityReducer(state.user.activity, action),
    },
  }
}

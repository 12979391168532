import BaseButtonConfig from 'common/builder/buttons/BaseButtonConfig'
import { IButtonBase, IBuyButton } from 'common/builder/buttons/buttonInterfaces'
import { ButtonType } from 'common/builder/constants/ButtonType'

class BuyButtonConfig extends BaseButtonConfig {
  buttonType = ButtonType.BUY

  getDefaults = (): Omit<IBuyButton, keyof IButtonBase> => ({
    item_price: '0.00',
    item_label: '',
    email_field_id: null,
    shipping_address: false,
    contact_name: false,
    contact_phone: false,
    contact_email: true,
    targetId: null,
    filter: null,
  })
}

export default new BuyButtonConfig()

import React, { MouseEventHandler, useEffect, useRef, useState } from 'react'
import cx from 'classnames'
import { useAppDispatch, useAppSelector } from 'reduxTyped'
import { Icon } from '@manychat/manyui'

import { isMobile as isMobileDevice } from 'utils'
import { getThreadsCounterForTab } from 'apps/chat/selectors/countersSelectors'
import { toggleSidebar } from 'common/core/actions/uiActions'
import { useSidebarAccountList } from 'common/core/coreHooks'
import { getCurrentAccount } from 'common/core/selectors/appSelectors'
import { getIsSidebarHidden } from 'common/core/selectors/uiSelectors'
import { analyticsService } from 'utils/services/analytics'

import { Layout } from '../Layout/Layout'

import { Sidebar } from './Sidebar/Sidebar'

import cm from './LayoutWithSidebar.module.css'

export const LayoutWithSidebar = () => {
  const dispatch = useAppDispatch()
  const account = useAppSelector(getCurrentAccount)

  const [fbPagesOpen, setFbPagesState] = useState(false)
  const isSidebarHidden = useAppSelector(getIsSidebarHidden)

  const [isRightTimeAnimate, setTimeState] = useState(!isSidebarHidden || isMobileDevice)
  const { isFetching, accountList } = useSidebarAccountList(fbPagesOpen)
  const threadsCounter = useAppSelector(getThreadsCounterForTab)
  const timer = useRef<ReturnType<typeof setTimeout>>()

  useEffect(
    () => () => {
      const timerId = timer.current

      if (timerId) {
        clearTimeout(timerId)
      }
    },
    [],
  )

  const handleToggleSidebar: MouseEventHandler = (event) => {
    event.stopPropagation()

    analyticsService.sendEvent(isSidebarHidden ? 'SIDEBAR.OPEN' : 'SIDEBAR.HIDE', {
      windowWidth: window.innerWidth,
    })

    dispatch(toggleSidebar())

    if (!isSidebarHidden) {
      setTimeState(false)
    } else {
      timer.current = setTimeout(() => {
        setTimeState(true)
      }, 300)
    }
  }

  const handleSidebarBackdropClick: React.MouseEventHandler<HTMLDivElement> = (event) => {
    const isBackdropClick = event.target === event.currentTarget
    if (isBackdropClick && isMobileDevice) {
      dispatch(toggleSidebar())
    }
  }

  return (
    <Layout>
      <div
        className={cx(cm.sidebar, {
          [cm.sidebarHidden]: isSidebarHidden && !fbPagesOpen,
          [cm.open]: !isSidebarHidden,
        })}
        onClick={handleSidebarBackdropClick}
      >
        <Sidebar
          isFetching={isFetching}
          isOpen={!isSidebarHidden}
          accID={account.id}
          accounts={accountList}
          openedThreads={threadsCounter}
          isSidebarHidden={isSidebarHidden && !fbPagesOpen}
          isRightTimeAnimate={isRightTimeAnimate}
          fbPagesOpen={fbPagesOpen}
          setFbPagesState={setFbPagesState}
        />
      </div>

      {!isMobileDevice && (
        <div
          className={cx(
            cm.hideButton,
            { [cm.hideButtonClose]: isSidebarHidden && !fbPagesOpen },
            'c-pointer text-secondary',
          )}
          onClick={handleToggleSidebar}
        >
          {isSidebarHidden ? <Icon.DoubleChevrotForward /> : <Icon.DoubleChevrotBack />}
        </div>
      )}
    </Layout>
  )
}

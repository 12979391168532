import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import { fetchFlowDependencies } from 'common/flow/actions/flowActions'
import * as flowSelectors from 'common/flow/selectors/flowSelectors'
import TargetPageEntitiesMapping from 'common/mapping/controllers/TargetPageEntitiesMapping'
import { parseEntities } from 'common/mapping/models/Map/adapter'

class ExportFlowEntitiesMappingComponent extends React.Component {
  componentDidMount() {
    this.props.fetchFlowDependencies(this.props.flowId)
  }
  render() {
    return <TargetPageEntitiesMapping {...this.props} />
  }
}

/**
 * @typedef {Object} ExportFlowEntitiesMappingControllerProps
 * @property {string} [flowId] - The ID of the flow to export.
 *
 * @param {ExportFlowEntitiesMappingControllerProps} props
 * @returns {JSX.Element}
 */
const ExportFlowEntitiesMappingController = connect(
  (state, props) => {
    const { flowId } = props
    const flow = flowSelectors.getById(state, flowId) || {}

    return {
      entities: parseEntities(flow.dependenciesMap) || {},
      mappingSourceEntityName: 'flow',
      tagsMap: state.targetPage.tagsMap,
      cufsMap: state.targetPage.cufsMap,
      gafsMap: state.targetPage.gafsMap,
      otnsMap: state.targetPage.otnsMap,
      customEventsMap: state.targetPage.customEventsMap,
    }
  },
  (dispatch, props) =>
    bindActionCreators(
      {
        fetchFlowDependencies,
      },
      dispatch,
    ),
)(ExportFlowEntitiesMappingComponent)

export default ExportFlowEntitiesMappingController

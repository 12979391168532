import { INodeStats } from 'common/builder/nodes/nodeInterfaces'
import { IFlowContent } from 'common/flow/flowBackendInterfaces'
import { FlowContentType } from 'common/flow/flowConstants'
import { calculateCTR } from 'utils/stats'

const parseContentStats = (content: IFlowContent): INodeStats => {
  const stats = {
    clickedSegmentId: `content:clicked_${content.content_id}|`,
    clicksUsers: 0,
    deliveredSegmentId: `content:delivered_${content.content_id}|`,
    deliveredUniqueUsers: 0,
    readSegmentId: `content:read_${content.content_id}|`,
    readUniqueUsers: 0,
    sentSegmentId: `content:sent_${content.content_id}|`,
    sentTotal: 0,
    sentUsers: 0,

    clicksTotal: 0,
    clicksUnique: 0,
    deliveredTotal: 0,
    deliveredUnique: 0,
    readTotal: 0,
    readUnique: 0,
    sentUnique: 0,

    dr: '0',
    rr: '0',
    ctr: 0,
    ctrBySent: 0,
    deliveredTR: '0',
    readTR: '0',
    clicksTR: '0',
    deliveredUR: '0',
    readUR: '0',
    clicksUR: '0',

    // email only
    bounced: 0,
    spamReport: 0,
    unsubscribed: 0,
    bouncedSegmentId: `content:bounced_${content.content_id}|`,
    spamReportSegmentId: `content:spamreport_${content.content_id}|`,
    unsubscribedSegmentId: `content:unsubscribed_${content.content_id}|`,

    // smart delay only
    waitersTotal: 0,
    waitersUnique: 0,
    waitersSegmentId: `content:held_${content.content_id}|`,

    // user input
    hasUserInputStats: false,
    questionsCount: 0,
    filledAnswersCount: 0,
    respondentsTotal: 0,
    respondentsUnique: 0,
    respondentsTR: '0',
    respondentUR: '0',

    // agent only
    finishedTotal: 0,
    finishedUnique: 0,
    startedTotal: 0,
    startedUnique: 0,
  }

  if (
    content.type !== FlowContentType.ACTION_GROUP &&
    content.type !== FlowContentType.GOTO &&
    content.type !== FlowContentType.NOTE &&
    content.type !== FlowContentType.MAIN_MENU &&
    content.type !== FlowContentType.AGENT
  ) {
    stats.clickedSegmentId = content.clicked_segment_id ?? stats.clickedSegmentId
    stats.clicksUsers = content.clicks_users ?? stats.clicksUsers
    stats.deliveredSegmentId = content.delivered_segment_id ?? stats.deliveredSegmentId
    stats.deliveredUniqueUsers = content.delivered_unique_users ?? stats.deliveredUniqueUsers
    stats.readSegmentId = content.read_segment_id ?? stats.readSegmentId
    stats.readUniqueUsers = content.read_unique_users ?? stats.readUniqueUsers
    stats.sentSegmentId = content.sent_segment_id ?? stats.sentSegmentId
    stats.sentTotal = content.sent_total ?? stats.sentTotal
    stats.sentUsers = content.sent_users ?? stats.sentUsers
    stats.questionsCount = content.questions_count ?? stats.questionsCount
    stats.filledAnswersCount = content.filled_answers_count ?? stats.filledAnswersCount
  }

  if (content.type === FlowContentType.AGENT) {
    stats.finishedTotal = content.stats?.agent?.finished_total ?? 0
    stats.finishedUnique = content.stats?.agent?.finished_unq ?? 0
    stats.startedTotal = content.stats?.agent?.started_total ?? 0
    stats.startedUnique = content.stats?.agent?.started_unq ?? 0
  }

  if (content.type === FlowContentType.INSTAGRAM) {
    stats.clicksUsers = content.stats?.clicked_unq ?? stats.clicksUsers
    stats.sentUsers = content.stats?.sent_unq ?? stats.sentUsers
  }

  if (content.type === FlowContentType.EMAIL) {
    stats.bounced = content.bounced ?? stats.bounced
    stats.spamReport = content.spam_report ?? stats.spamReport
    stats.unsubscribed = content.unsubscribed ?? stats.unsubscribed
    stats.bouncedSegmentId = content.bounced_segment_id ?? stats.bouncedSegmentId
    stats.spamReportSegmentId = content.spam_report_segment_id ?? stats.spamReportSegmentId
    stats.unsubscribedSegmentId = content.unsubscribed_segment_id ?? stats.unsubscribedSegmentId
  }

  if (content.type === FlowContentType.SMART_DELAY) {
    stats.waitersTotal = content.waiters_total ?? stats.waitersTotal
    stats.waitersUnique = content.waiters_unique ?? stats.waitersUnique
  }

  const { sentUsers, deliveredUniqueUsers, readUniqueUsers, clicksUsers } = stats
  stats.dr = sentUsers === 0 ? '0' : ((deliveredUniqueUsers / sentUsers) * 100).toFixed(1)
  stats.rr =
    deliveredUniqueUsers === 0 ? '0' : ((readUniqueUsers / deliveredUniqueUsers) * 100).toFixed(1)
  stats.ctr = calculateCTR(clicksUsers, deliveredUniqueUsers)

  // advanced stats
  stats.clicksTotal = content.stats?.clicked_total ?? stats.clicksTotal
  stats.clicksUnique = content.stats?.clicked_unq ?? stats.clicksUnique
  stats.deliveredTotal = content.stats?.delivered_total ?? stats.deliveredTotal
  stats.deliveredUnique = content.stats?.delivered_unq ?? stats.deliveredUnique
  stats.readTotal = content.stats?.read_total ?? stats.readTotal
  stats.readUnique = content.stats?.read_unq ?? stats.readUnique
  stats.sentTotal = content.stats?.sent_total ?? stats.sentTotal
  stats.sentUnique = content.stats?.sent_unq ?? stats.sentUnique

  stats.ctrBySent = calculateCTR(clicksUsers, stats.sentUnique)

  const { sentTotal, deliveredTotal, readTotal, clicksTotal } = stats
  stats.deliveredTR =
    sentTotal === 0 ? '0' : Math.round((deliveredTotal / sentTotal) * 100).toString()
  stats.readTR = sentTotal === 0 ? '0' : Math.round((readTotal / sentTotal) * 100).toString()
  stats.clicksTR = sentTotal === 0 ? '0' : Math.round((clicksTotal / sentTotal) * 100).toString()

  const { sentUnique, deliveredUnique, readUnique, clicksUnique } = stats
  stats.deliveredUR =
    sentUnique === 0 ? '0' : Math.round((deliveredUnique / sentUnique) * 100).toString()
  stats.readUR = sentUnique === 0 ? '0' : Math.round((readUnique / sentUnique) * 100).toString()
  stats.clicksUR = sentUnique === 0 ? '0' : Math.round((clicksUnique / sentUnique) * 100).toString()

  if (
    content.stats?.respondents_total !== undefined &&
    content.stats?.respondents_unq !== undefined
  ) {
    stats.hasUserInputStats = true
    stats.respondentsTotal = content.stats?.respondents_total
    stats.respondentsUnique = content.stats?.respondents_unq

    const { respondentsTotal, respondentsUnique } = stats
    stats.respondentsTR =
      sentTotal === 0 ? '0' : Math.round((respondentsTotal / sentTotal) * 100).toString()
    stats.respondentUR =
      sentUnique === 0 ? '0' : Math.round((respondentsUnique / sentUnique) * 100).toString()
  }

  return stats
}

export default parseContentStats

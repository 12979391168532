import { l } from '@manychat/manyui'

import { ChannelType } from 'common/core/constants/ChannelType'
import { IceBreaker } from 'shared/api/requests/iceBreakers/schemas'

export type IceBreakerChannelType = ChannelType.FB | ChannelType.INSTAGRAM | ChannelType.WHATSAPP

export interface IceBreakerError {
  id: string
  message: ReturnType<typeof l.makeTranslate>
}

export type MappedIceBreakerFlow =
  | (NonNullable<IceBreaker['flow']> & {
      id: string
    })
  | null

export interface MappedIceBreaker extends IceBreaker {
  id: string
  flow: MappedIceBreakerFlow
  isValidated: boolean
}

export interface IceBreakersChannelState {
  items: readonly MappedIceBreaker[]
  publishedItems: readonly MappedIceBreaker[] | null
  fetching: boolean
  publishing: boolean
  switching: boolean
  validationCompleted: boolean
  fetched: boolean
  invalidItem: number | null
  channel: IceBreakerChannelType
}

export type IceBreakersState = Record<ChannelType | string, IceBreakersChannelState>

export interface IceBreakersPartialRootState {
  iceBreakers: IceBreakersState
}

export const MAX_ITEMS_COUNT = 4

export const ICE_BREAKERS_ROUTE = '/automation/FAQ'

export const ICE_BREAKER_QUESTION_MAX_LENGTH = 80

export const INSTAGRAM_ICE_BREAKERS_ROUTE = '/automation/instagram/FAQ'

export const WHATSAPP_ICE_BREAKERS_ROUTE = '/automation/whatsapp/iceBreakers'

export interface IceBreakersQuery {
  channel?: IceBreakerChannelType
}

export interface IceBreakerItemProps {
  id: string
  isLast?: boolean
  index: number
  moveIceBreaker: (dragIndex: number, hoverIndex: number) => void
  channel: IceBreakerChannelType
  iceBreakerId: number | null
}

export const IceBreakersChannels: IceBreakerChannelType[] = [
  ChannelType.FB,
  ChannelType.INSTAGRAM,
  ChannelType.WHATSAPP,
]

export const getInitialIceBreakersChannelState = (
  channel: IceBreakerChannelType,
): IceBreakersChannelState => ({
  items: [],
  publishedItems: null,
  fetching: false,
  publishing: false,
  switching: false,
  validationCompleted: false,
  fetched: false,
  invalidItem: null,
  channel,
})

import { IAsyncThunkAction } from 'common/core/interfaces/actions'
import {
  TAGS_FETCH_TOTAL_COUNT_REQUEST,
  TAGS_FETCH_TOTAL_COUNT_RESPONSE,
  TAGS_FETCH_TOTAL_COUNT_ERROR,
} from 'common/tags/tagsReduxActionTypes'
import { handleCatch } from 'shared/api/lib/errors/handlers'
import { TagsApi } from 'shared/api/requests/tags'

export const getTagsCountOnAccount = (): IAsyncThunkAction => async (dispatch) => {
  try {
    dispatch({ type: TAGS_FETCH_TOTAL_COUNT_REQUEST })

    const { data } = await TagsApi.getFolderContentCount()
    dispatch({ type: TAGS_FETCH_TOTAL_COUNT_RESPONSE, data: data.tags_count })
  } catch (error) {
    dispatch({ type: TAGS_FETCH_TOTAL_COUNT_ERROR })
    handleCatch(error)
  }
}

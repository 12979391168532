import React, { useState } from 'react'
import { useAppDispatch, useAppSelector } from 'reduxTyped'
import { l, Link, Modal, RadioV2, RadioGroup, Skeleton } from '@manychat/manyui'

import { localStorage } from 'utils'
import { getChannelData } from 'apps/whatsApp/redux/waChannelSlice'
import { useBillingCurrency } from 'common/billing/hooks/useBillingCurrency'
import { getAutomationProductPrice } from 'common/billing/selectors/billingSelectors'
import {
  getWhatsAppCoexistenceContactImportDeadline,
  isWaCoexistenceContactImportCommunicationAvailable,
} from 'common/builder/selectors/builder/whatsAppSelectors'
import { alert } from 'common/core'
import { setNotifications } from 'common/core/actions/appActions'
import {
  closeWaCoexistenceContactImportModal,
  openWaCoexistenceContactImportModal,
} from 'common/core/actions/uiActions'
import { getIsWaCoexistenceContactImportModalOpen } from 'common/core/selectors/uiSelectors'
import { handleCatch } from 'shared/api/lib/errors/handlers'
import { reportUnhandledErrors, applyHandlerByGuard } from 'shared/api/lib/errors/utils'
import { WhatsAppApi } from 'shared/api/requests/whatsApp'
import { whatsappGuards } from 'shared/api/requests/whatsApp/guards'
import { WaCoexistenceImportContactsPeriod } from 'shared/api/requests/whatsApp/schemas'
import { getAccountIdFromURL } from 'utils/accountId'
import { useMcClientAPI } from 'utils/api/query/hooks/useMcClientAPI'
import { useDidMountEffect } from 'utils/commonHooks'
import { analyticsService } from 'utils/services/analytics'
import { AccountFlag } from 'utils/services/featureFlagsService/constants'
import { useAccountReleaseToggle } from 'utils/services/featureFlagsService/releaseTogglesHooks'

import { WaCoexistenceContactImportLocalStorageIds } from './WaCoexistenceContactImportLocalStorageIds'

const IMPORT_OPTIONS = [
  {
    label: l.translate('6 months (180 days)'),
    value: WaCoexistenceImportContactsPeriod.SIX_MONTHS,
  },
  {
    label: l.translate('3 months (90 days)'),
    value: WaCoexistenceImportContactsPeriod.THREE_MONTHS,
  },
  {
    label: l.translate('24 hours'),
    value: WaCoexistenceImportContactsPeriod.ONE_DAY,
  },
]

const WaCoexistenceContactImportModal = () => {
  const dispatch = useAppDispatch()

  const [importPeriod, setImportPeriod] = useState(String(IMPORT_OPTIONS[0].value))
  const [isLoading, setIsLoading] = useState(false)

  const isOpen = useAppSelector(getIsWaCoexistenceContactImportModalOpen)
  const automationPrice = useAppSelector(getAutomationProductPrice)
  const shouldBeImported = useAppSelector(isWaCoexistenceContactImportCommunicationAvailable)
  const { hoursLeft, deadlineWithTimezone } = useAppSelector(
    getWhatsAppCoexistenceContactImportDeadline,
  )

  const api = useMcClientAPI()
  const currency = useBillingCurrency()
  const isContactImportEnabled = useAccountReleaseToggle(
    AccountFlag.ENABLE_WA_COEXISTENCE_CONTACTS_IMPORT,
  )

  useDidMountEffect(() => {
    const accountId = getAccountIdFromURL()
    if (!accountId) return

    const accountMap = localStorage.getItem(
      WaCoexistenceContactImportLocalStorageIds.WA_COEXISTENCE_CONTACT_IMPORT_MODAL_WAS_SHOWN,
    )
    const accounts = accountMap ? JSON.parse(accountMap) : {}
    const modalWasShown = accounts?.[accountId] || false

    if (isOpen || !shouldBeImported || modalWasShown || !isContactImportEnabled) return

    dispatch(openWaCoexistenceContactImportModal())
    accounts[accountId] = 'true'
    localStorage.setItem(
      WaCoexistenceContactImportLocalStorageIds.WA_COEXISTENCE_CONTACT_IMPORT_MODAL_WAS_SHOWN,
      JSON.stringify(accounts),
    )
  })

  if (!shouldBeImported || !isContactImportEnabled) return null

  const reloadNotifications = async () => {
    try {
      const data = await api.notifications.getNotifications()
      dispatch(setNotifications(data))
    } catch (error) {
      handleCatch(error)
    }
  }

  const handleImport = async () => {
    setIsLoading(true)

    try {
      const selectedPeriod = Number(importPeriod)
      await WhatsAppApi.coexistenceImportContacts({
        body: { coexistence_import_contacts_period: selectedPeriod },
      })
      await dispatch(getChannelData())
      analyticsService.sendEvent('WA_COEXISTENCE_CONTACT_IMPORT_MODAL.IMPORT.CLICK', {
        importPeriod: selectedPeriod,
      })
    } catch (error) {
      handleCatch(error, (responseError) => {
        const unhandledErrors = applyHandlerByGuard(
          responseError.$errors,
          whatsappGuards.coexistenceImportContacts.isCoexistenceContactImportError,
          (error) => {
            const errorMessage = error?.message
            alert(errorMessage, 'danger')
          },
        )

        reportUnhandledErrors(unhandledErrors, responseError.endpoint)
      })
    } finally {
      setIsLoading(false)
      dispatch(closeWaCoexistenceContactImportModal())
      reloadNotifications()
    }
  }

  const handleDoNotImport = async () => {
    dispatch(closeWaCoexistenceContactImportModal())
    analyticsService.sendEvent('WA_COEXISTENCE_CONTACT_IMPORT_MODAL.DO_NOT_IMPORT.CLICK')
  }

  const handleViewPrices = () => {
    analyticsService.sendEvent('WA_COEXISTENCE_CONTACT_IMPORT_MODAL.VIEW_PRICES.CLICK')
  }

  const buttons = [
    {
      label: l.translate('Decide later'),
      className: 'm-l-xs',
      variant: 'default',
      loading: isLoading,
      disabled: isLoading,
      onClick: handleDoNotImport,
    },
    {
      label: l.translate('Import now'),
      className: 'm-r-xs',
      variant: 'primary',
      loading: isLoading,
      disabled: isLoading,
      onClick: handleImport,
    },
  ]

  return (
    <Modal
      disableCloseButton
      width="640px"
      open={isOpen}
      buttons={buttons}
      title={l.translate('Import WhatsApp Business App contacts')}
      onRequestClose={handleDoNotImport}
    >
      <div className="p-a-xs">
        <p>
          {l.translate(
            'You have <strong>{hoursLeft} hours until {deadline}</strong> to import contacts from recent chats on the WhatsApp Business App to Manychat. We recommend importing them now so you keep your user base intact.',
            { hoursLeft, deadline: deadlineWithTimezone },
          )}
        </p>
        <div className="m-t-sm">
          {automationPrice ? (
            <div>
              <span>
                {l.translate(
                  'Your monthly subscription cost will be based on your total number of contacts, with plans <strong>starting at {price}/month for up to 500 contacts.</strong>',
                  {
                    price: l.currency(automationPrice, currency, 0),
                  },
                )}
              </span>{' '}
              <Link target="_blank" href="https://manychat.com/pricing" onClick={handleViewPrices}>
                {l.translate('View prices')}
              </Link>
            </div>
          ) : (
            <Skeleton.Text lines={2} />
          )}
        </div>
        <RadioGroup
          title={l.translate('Transfer contacts from the last:')}
          ariaLabel={l.translate('Transfer contacts from the last')}
          id="transfer-contacts-radio-group"
          name="transfer-contacts-radio-group"
          className="p-t-sm"
          value={importPeriod}
          onChange={setImportPeriod}
        >
          {IMPORT_OPTIONS.map(({ label, value }) => (
            <RadioV2 key={value} data-test-id={String(value)} label={label} value={String(value)} />
          ))}
        </RadioGroup>
      </div>
    </Modal>
  )
}

export default WaCoexistenceContactImportModal

import { ChannelType } from 'common/core/constants/ChannelType'

import { WelcomeMessageChannelState } from './welcomeMessageInterfaces'

export const WelcomeMessageChannels: ChannelType[] = [
  ChannelType.FB,
  ChannelType.TELEGRAM,
  ChannelType.WHATSAPP,
]

export const getInitialWelcomeMessageChannelState = (
  channel: ChannelType,
): WelcomeMessageChannelState => ({
  flowId: null,
  name: '',
  chId: null,
  enabled: false,
  isFetched: false,
  fetching: false,
  updating: false,
  channel,
})

export const WELCOME_MESSAGE_ROUTE = '/automation/welcome'

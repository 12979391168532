import { BillingCurrency } from './BillingCurrency'

export enum BillingVersion {
  BASE = 1,
  FLEX = 2,
}

export enum GrandfatheringVersion {
  INBOX = 0,
  BASE_FREE_TO_FLEX_PRO = 1,
  BASE_PRO_TO_FLEX_PRO = 2,
}

export enum ManychatLegalEntity {
  Brazil = 'br',
  UnitedStates = 'us',
}

export interface StripeInfo {
  publishable_key: string
}

export interface AccountBillingInfo {
  billing_version: BillingVersion
  card_country: string | null
  company_name: string | null
  details: string | null
  grandfathering_version: GrandfatheringVersion | null
  invoice_sending_enabled: boolean
  is_grandfathering: boolean
  need_to_show_pay_now: boolean
  currency: BillingCurrency
  legal_entity: ManychatLegalEntity
  stripe: StripeInfo
  tax_number?: string
}

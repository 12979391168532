import React from 'react'

type Handler = React.FocusEventHandler

export const useFocus = (onFocus?: Handler, onBlur?: Handler): [boolean, Handler, Handler] => {
  const [focus, setFocus] = React.useState(false)

  const handleFocus = React.useCallback(
    (event: React.FocusEvent) => {
      setFocus(true)
      onFocus?.(event)
    },
    [setFocus, onFocus],
  )

  const handleBlur = React.useCallback(
    (event: React.FocusEvent) => {
      setFocus(false)
      onBlur?.(event)
    },
    [setFocus, onBlur],
  )

  return [focus, handleFocus, handleBlur]
}

// https://github.com/danxexe/sms-counter
const GSM_7_BIT_CHARS =
  '@£$¥èéùìòÇ\\nØø\\rÅåΔ_ΦΓΛΩΠΨΣΘΞÆæßÉ !\\"#¤%&\'()*+,-./0123456789:;<=>?¡ABCDEFGHIJKLMNOPQRSTUVWXYZÄÖÑÜ§¿abcdefghijklmnopqrstuvwxyzäöñüà'
const GSM_7_BIT_EX_CHAR = '\\^{}\\\\\\[~\\]|€'

const GSM_7_BIT_REG_EXP = RegExp('^[' + GSM_7_BIT_CHARS + ']*$')
const GSM_7_BIT_EX_REG_EXP = RegExp('^[' + GSM_7_BIT_CHARS + GSM_7_BIT_EX_CHAR + ']*$')
const GSM_7_BIT_EX_ONLY_REG_EXP = RegExp('^[\\' + GSM_7_BIT_EX_CHAR + ']*$')

export enum Encodings {
  GSM_7BIT = 'GSM_7BIT',
  GSM_7BIT_EX = 'GSM_7BIT_EX',
  UTF16 = 'UTF16',
}

const MESSAGE_LENGTH = {
  [Encodings.GSM_7BIT]: 160,
  [Encodings.GSM_7BIT_EX]: 160,
  [Encodings.UTF16]: 70,
}

const MULTI_MESSAGE_LENGTH = {
  [Encodings.GSM_7BIT]: 153,
  [Encodings.GSM_7BIT_EX]: 153,
  [Encodings.UTF16]: 67,
}

export interface IResult {
  encoding: Encodings
  length: number
  charsPerMessage: number
  remaining: number
  messages: number
}

export const smsCounter = (text: string): IResult => {
  const encoding = detectEncoding(text)
  const length =
    encoding === Encodings.GSM_7BIT_EX ? text.length + countGsm7bitEx(text) : text.length

  const charsPerMessage =
    length > MESSAGE_LENGTH[encoding] ? MULTI_MESSAGE_LENGTH[encoding] : MESSAGE_LENGTH[encoding]

  const messages = Math.ceil(length / charsPerMessage)
  const remaining = charsPerMessage * messages - length

  return {
    length,
    messages,
    encoding,
    charsPerMessage,
    remaining: remaining === 0 && messages === 0 ? charsPerMessage : remaining,
  }
}

const detectEncoding = (text: string): Encodings => {
  if (text.match(GSM_7_BIT_REG_EXP)) return Encodings.GSM_7BIT
  if (text.match(GSM_7_BIT_EX_REG_EXP)) return Encodings.GSM_7BIT_EX
  return Encodings.UTF16
}

const countGsm7bitEx = (text: string): number =>
  text.split('').filter((char) => char.match(GSM_7_BIT_EX_ONLY_REG_EXP) !== null).length

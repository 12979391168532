import { LayoutWithSidebar } from 'utils/router/components/LayoutWithSidebar/LayoutWithSidebar'
import { getHandle } from 'utils/router/utils/getHandle'

export const AdsRoutes = [
  {
    path: 'ads',
    Component: LayoutWithSidebar,
    handle: getHandle('ads'),
    children: [
      {
        index: true,
        lazy: () => import('./AdTable.page'),
      },
      {
        path: 'settings',
        lazy: () => import('./AdSettings.page'),
      },
      {
        path: 'ad/:ad_id',
        lazy: () => import('./AdConstructor.page'),
      },
      {
        path: 'adset/:ad_set_id',
        lazy: () => import('./AdSet.page'),
      },
      {
        path: 'view/:ad_id',
        lazy: () => import('./AdView.page'),
      },
      {
        path: 'campaign/:campaign_id',
        lazy: () => import('./Campaign.page'),
      },
      {
        path: 'new',
        children: [
          {
            index: true,
            lazy: () => import('./AdWizardAccount.page'),
          },
        ],
      },
    ],
  },
] as const

export const AdsRoutesNewAccount = [
  {
    path: 'ads/new/:ad_account_id',
    Component: LayoutWithSidebar,
    handle: getHandle('ads'),
    children: [
      {
        path: 'select',
        lazy: () => import('./AdWizardCampaign.page'),
      },
      {
        path: ':campaign_id',
        children: [
          {
            index: true,
            lazy: () => import('./AdWizardCampaign.page'),
          },
          {
            path: 'select',
            lazy: () => import('./AdWizardAdSet.page'),
          },
          {
            path: ':ad_set_id',
            children: [
              {
                index: true,
                lazy: () => import('./AdWizardAdSet.page'),
              },
              {
                path: ':ad_id',
                children: [
                  {
                    index: true,
                    lazy: () => import('./AdWizardAdConstructor.page'),
                  },
                  {
                    path: 'success',
                    lazy: () => import('./AdWizardFinish.page'),
                  },
                ],
              },
            ],
          },
        ],
      },
    ],
  },
] as const

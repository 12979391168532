import { l } from '@manychat/manyui'

export enum WidgetTypes {
  BUTTON = 'button',
  MESSENGER_REF_URL = 'messenger_ref_url',
  // ADS_JSON is deprecated, use ADS instead
  /* deprecated */
  ADS_JSON = 'ads_json',
  ADS = 'ads',
  FEED_COMMENT = 'feed_comment',
  FEED_COMMENT_TRIGGER = 'feed_comment_trigger',
  MESSENGER_CODE = 'messenger_code',
  FACEBOOK_SHOP = 'facebook_shops',
  INSTAGRAM_STORY_REPLY = 'instagram_story_reply',
  INSTAGRAM_LIVE_COMMENT_REPLY = 'instagram_live_comment_reply',
  CTWA_TRIGGER = 'ctwa',
}

export enum WidgetTypeNumeric {
  BUTTON = 1,
  MESSENGER_REF_URL = 8,
  ADS_JSON = 9,
  FEED_COMMENT = 10,
  MESSENGER_CODE = 11,
  FEED_COMMENT_TRIGGER = 15,
  FACEBOOK_SHOP = 16,
  INSTAGRAM_STORY_REPLY = 19,
  INSTAGRAM_LIVE_COMMENT_REPLY = 20,
  ADS = 21,
}

export const STAT_N_A = 'n/a'

export enum WidgetStatusType {
  ACTIVE = 'active',
  DRAFT = 'draft',
  ARCHIVED = 'archived',
  TRASH = 'trash',
  INITIAL = 'initial',
}

export const GetWidgetBuilderId: Partial<Record<WidgetTypes, (id: string) => string>> = {
  [WidgetTypes.FEED_COMMENT]: (id: string) => `simple-builder-widget-${id}`,
}

export enum PostCoveredArea {
  ALL_POSTS = 'all_posts',
  SPECIFIC_POST = 'specific_post',
  NEXT_POST = 'next_post',
}

export enum StoryCoveredArea {
  ALL_STORIES = 'all',
  SPECIFIC_STORY = 'specific',
}

export const InstagramPostCoveredAreaLabels = {
  [PostCoveredArea.ALL_POSTS]: l.makeTranslate('any post or reel'),
  [PostCoveredArea.SPECIFIC_POST]: l.makeTranslate('a specific post or reel'),
  [PostCoveredArea.NEXT_POST]: l.makeTranslate('next post or reel'),
}

export const FacebookPostCoveredAreaLabels = {
  [PostCoveredArea.ALL_POSTS]: l.makeTranslate('any post'),
  [PostCoveredArea.SPECIFIC_POST]: l.makeTranslate('specific post'),
  [PostCoveredArea.NEXT_POST]: l.makeTranslate('next post'),
}

// these triggers do not need to ensure saving, they autosave
export const AutosavedWidgetTypes = [WidgetTypes.INSTAGRAM_STORY_REPLY]

import { createElement as createReactElement, Fragment, ReactNode } from 'react'

import { parseTag } from './parseTag'
import { attributesToProps } from './props'
import { TagStack } from './TagStack'
import { Tag } from './types'

const TagRegExp = /(<\/?[a-z0-9]+[^<]*>)/

export const convertHTMLToReact = (input: string, data?: Record<string, unknown>) => {
  const tagsAndStrings = input.split(TagRegExp).filter((s) => s && !s.startsWith('<l10n'))
  const parts = tagsAndStrings.map((s) => parseTag(s) || s)

  if (parts.every(isString)) return parts.join('')

  const createElement = makeCreateElement(data)

  return reduceToElementTree(parts, createElement)
}

const reduceToElementTree = (
  input: Array<string | Tag>,
  createElement: ReturnType<typeof makeCreateElement>,
) => {
  const stack = new TagStack()

  input.forEach((part: string | Tag) => {
    if (typeof part === 'string') {
      return stack.addChildToLastElement(part)
    }

    if (part.type === 'self-closing') {
      return stack.addChildToLastElement(createElement(part))
    }

    if (part.type === 'closing') {
      const openTagIndex = stack.getLastIndexFor(part.name)
      if (openTagIndex >= 0) {
        stack.closeTagsAfter(openTagIndex)
        stack.addChildToLastElement(createElement(stack.pop()))
      } else {
        stack.addChildToLastElement(part.input)
      }
    }

    if (part.type === 'opening') {
      stack.push({ ...part, children: [] })
    }
  })

  stack.closeTagsAfter(0)

  const children = stack.pop().children
  if (children.length === 1) {
    return children[0]
  }

  return createReactElement(Fragment, undefined, children)
}

const makeCreateElement = (data?: Record<string, unknown>) => {
  let index = 0
  type ElementData = Tag & { children?: ReactNode[] }

  return ({ name, attributes, children }: ElementData) => {
    const props = { key: ++index, ...attributesToProps(attributes, data) }
    return createReactElement(name, props, children)
  }
}

function isString(input: unknown): input is string {
  return typeof input === 'string'
}

import { StripeCheckoutTypes } from 'common/billing/constants/stripeCheckoutTypes'
import { sendStripeCheckoutAnalytics } from 'common/billing/helpers/sendStripeCheckoutAnalytics'
import { AddOnSlug } from 'common/billing/interfaces/addOnTypes'
import {
  getDiscountedCartTotal,
  getStripeCheckoutData,
  getUpgradeRequestSource,
} from 'common/billing/selectors/billingSelectors'
import { sendPostUpgradeAnalytics } from 'common/builder/utils/upgradeEventUtils'
import { ProProduct } from 'common/core/interfaces/products'
import { getUserId, getPageId } from 'common/core/selectors/appSelectors'
import { createAsyncAction } from 'shared/lib/redux'

interface AnalyticsPayload {
  products?: ProProduct[]
  addOns?: AddOnSlug[]
  checkoutType: StripeCheckoutTypes.TRIAL | StripeCheckoutTypes.UPGRADE_TO_PRO
}

export const sendUpgradeAnalytics = createAsyncAction<void, AnalyticsPayload>(
  'billing/sendUpgradeAnalytics',
  async ({ products = [], addOns = [], checkoutType }, { getState }) => {
    const state = getState()

    const source = getUpgradeRequestSource(state)
    const amount =
      checkoutType === StripeCheckoutTypes.TRIAL
        ? 0
        : getDiscountedCartTotal(state, products, addOns)
    const currentPageId = getPageId(state)
    const userId = getUserId(state)
    const { isUpgradeToPro } = getStripeCheckoutData(state)

    sendPostUpgradeAnalytics(source)
    if (isUpgradeToPro) {
      sendStripeCheckoutAnalytics({
        checkoutType,
        currentPageId,
        userId,
        amount,
      })
    }
  },
)

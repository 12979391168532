import * as atypes from 'common/actions/integrations/common/constants/IntegrationsReduxActionTypes'
import * as API from 'constants/API'

export const getLists = () => ({
  type: atypes.KLAVIYO_GET_LISTS,
  $fetch: API.integrations.endpoints.klaviyo.getLists,
})

export const getFields = () => ({
  type: atypes.KLAVIYO_GET_FIELDS,
  $fetch: API.integrations.endpoints.klaviyo.getFields,
})

import React from 'react'
import { l } from '@manychat/manyui'

import cm from '../common.module.css'

const WAViolatedNotification = () => {
  return (
    <div>
      {l.translate(
        'Your WhatsApp account is locked due to policy violation. You can file an appeal in Meta Business Manager.',
      )}{' '}
      <a
        href="https://www.facebook.com/business/help/692706745267064"
        rel="noopener noreferrer"
        target="_blank"
        className={cm.link}
      >
        {l.translate('Learn More')}
      </a>
    </div>
  )
}

export default WAViolatedNotification

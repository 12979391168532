import * as atypes from 'common/userRoles/constants/UserRolesReduxActionTypes'

import {
  AccessRequestsState,
  AccessRequestsUser,
  AccessRequestDecision,
} from '../userRolesInterfaces'

const InitialState: AccessRequestsState = {
  ui: {
    processingUsers: {},
  },
  users: null,
}

export type ExpectedActions =
  | {
      type: 'ACCESS_REQUESTS_FETCH_RESPONSE'
      data?: { request_access_users: AccessRequestsUser[]; state: boolean }
    }
  | {
      type: 'ACCESS_REQUESTS_APPROVE_RESPONSE'
      data?: { user: AccessRequestsUser[]; state: boolean }
    }
  | {
      type: 'ACCESS_REQUESTS_SET_STATUS'
      userId: number
      status: AccessRequestDecision
    }
  | {
      type: 'ACCESS_REQUESTS_DROP_STATUS'
      userId: number
    }

export default function accessRequestsReducer(
  state: AccessRequestsState = InitialState,
  action: ExpectedActions,
): AccessRequestsState {
  switch (action.type) {
    case atypes.ACCESS_REQUESTS_FETCH_RESPONSE: {
      return {
        ...state,
        ui: {
          processingUsers: {},
        },
        users: action.data?.request_access_users || null,
      }
    }
    case atypes.ACCESS_REQUESTS_SET_STATUS: {
      return withDecisionAboutUser(state, action.userId, action.status)
    }
    case atypes.ACCESS_REQUESTS_DROP_STATUS: {
      return withDroppedDecisionAboutUser(state, action.userId)
    }
  }

  return state
}

const withDecisionAboutUser = (
  state: AccessRequestsState,
  userId: number,
  decision: AccessRequestDecision,
): AccessRequestsState => {
  return {
    ...state,
    ui: {
      processingUsers: {
        ...state.ui.processingUsers,
        [userId]: decision,
      },
    },
  }
}

const withDroppedDecisionAboutUser = (
  state: AccessRequestsState,
  userId: number,
): AccessRequestsState => {
  const filteredProcessingUsers = { ...state.ui.processingUsers }
  delete filteredProcessingUsers[userId]

  return {
    ...state,
    ui: {
      processingUsers: filteredProcessingUsers,
    },
  }
}

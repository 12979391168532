import React from 'react'
import { l } from '@manychat/manyui'

export const TikTokUsRegionRestrictedNotification = () => {
  return (
    <span>
      {l.translate('TikTok automations are disabled for you due to regional limitations')}
    </span>
  )
}

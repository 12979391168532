import { z } from 'zod'

import { ChannelType } from 'common/core/constants/ChannelType'
import { TriggerType } from 'common/flow/constants/TriggerType'
import { ITriggerId } from 'common/flow/flowTriggersInterfaces'
import { lightFlowSchema } from 'shared/api/common/schemas/lightFlow'

export enum CartReplyType {
  WHEN_AUTOMATION_ACTIVE = 'when_automation_active',
  EVERY_TIME = 'every_time',
}

export const cartReplySchema = z.object({
  content_holder: z.string(),
  enabled: z.boolean(),
  flow: lightFlowSchema.nullable().optional(),
  type: z.nativeEnum(CartReplyType),
  runs: z.number().nullable().optional(),
  clicked_unq: z.number().nullable().optional(),
  ctr: z.any().optional(),
})

export const CartReplyApi = {
  getData: {
    query: z.undefined(),
    path: z.undefined(),
    request: z.undefined(),
    response: cartReplySchema,
  },
  switch: {
    query: z.object({ cart_enabled: z.boolean() }),
    path: z.undefined(),
    request: z.undefined(),
    response: cartReplySchema,
  },
  changeType: {
    query: z.undefined(),
    path: z.undefined(),
    request: z.object({ type: z.nativeEnum(CartReplyType) }),
    response: cartReplySchema,
  },
  replaceFlow: {
    query: z.undefined(),
    path: z.undefined(),
    request: z.object({ flow_ns: z.string() }),
    response: cartReplySchema,
  },
  removeFlow: {
    query: z.undefined(),
    path: z.undefined(),
    request: z.undefined(),
    response: cartReplySchema,
  },
}

export type CartReply = z.infer<typeof cartReplySchema>

export interface CartReplyTriggerMapped extends CartReply {
  channel: ChannelType.WHATSAPP
  caption: string
  triggerId: ITriggerId
  triggerType: TriggerType.CART_REPLY
  ts_created: number
  contentHolder: CartReply['content_holder']
  id: CartReply['content_holder']
  chId: CartReply['content_holder']
  flowId: string | null
}

export interface CartReplyUpdateSocketEvent {
  actor: number
  client_id: unknown
  event: 'cart_reply_updated'
  model: CartReply
}

import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import { BroadcastMessengerWizardState } from 'apps/posting/slices/interfaces'
import { MessageTagType } from 'common/builder/constants/facebookMessaging'

const initialState = {
  reasonId: null,
  messageTag: null,
} as BroadcastMessengerWizardState

const broadcastMessengerWizardSlice = createSlice({
  name: 'brodcastMessengerWizard',
  initialState,
  reducers: {
    setReasonId(state, action: PayloadAction<number | null>) {
      state.reasonId = action.payload
    },
    setReasonMessageTag(state, action: PayloadAction<MessageTagType | null>) {
      state.messageTag = action.payload
    },
    resetState(state) {
      state.messageTag = null
      state.reasonId = null
    },
  },
})

export const { setReasonId, setReasonMessageTag, resetState } =
  broadcastMessengerWizardSlice.actions
export default broadcastMessengerWizardSlice.reducer

export const getReasonId = (state: RootState) => state.broadcastMessengerWizard.reasonId
export const getReasonMessageTag = (state: RootState) => state.broadcastMessengerWizard.messageTag

import { l } from '@manychat/manyui'

import { contentManagementActions } from 'apps/cms/store'
import { ContentManagementSystemSocketEvents } from 'apps/cms/store/lib/constants'
import { mapSocketFsFlowDeleted } from 'apps/cms/store/lib/lib'
import { SocketFsFlow, SocketFsFlowDeleted } from 'apps/cms/store/lib/types'
import ConvertAlert from 'common/cms/controllers/ConvertFlowModal/ConvertAlert'
import FSPath from 'common/cms/models/FSPath'
import { alert } from 'common/core'
import { IAsyncThunkAction, IThunkAction } from 'common/core/interfaces/actions'
import * as atypes from 'common/flow/constants/flowReduxActionTypes'
import { IBackendFullFlow } from 'common/flow/flowBackendInterfaces'
import { convertFlowChannelsAdapter } from 'common/flow/flowHelpers'
import { ConvertChannelRecord, IFlow } from 'common/flow/flowInterfaces'
import { parseFlow } from 'common/flow/models/Flow/parseFlow'
import * as flowSelectors from 'common/flow/selectors/flowSelectors'
import * as API from 'constants/API'
import { LightFlow } from 'shared/api/common/schemas/lightFlow'
import { handleCatch } from 'shared/api/lib/errors/handlers'
import { CmsApi } from 'shared/api/requests/cms'
import { FsFlowBaseObject } from 'shared/api/requests/cms/schemas'
import { FlowApi } from 'shared/api/requests/flow'
import { isRequestError } from 'utils/api/mcApi/RequestError'
import { navigatePollyfill } from 'utils/router/tools'
import errorTrackingService from 'utils/services/errorTrackingService'
import {
  anotherTabNotificationsListener,
  accountNotificationsListener,
} from 'utils/services/notificationsService'
import { expandURL, linkURL } from 'utils/url'

import * as ActionTypes from '../constants/flowReduxActionTypes'

const fetchPromiseMap: Record<string, Promise<IFlow | undefined> | null> = {}

export function ensureFlow(flowId?: string): IAsyncThunkAction<IFlow | undefined> {
  return async (dispatch, getState) => {
    if (!flowId) {
      return
    }

    const item = flowSelectors.getById(getState(), flowId)
    if (item) {
      return item
    }

    let promise = fetchPromiseMap[flowId]
    if (!promise) {
      promise = dispatch(fetchFlow(flowId)) as Promise<IFlow | undefined>
      fetchPromiseMap[flowId] = promise
    }

    try {
      const flow = await promise
      fetchPromiseMap[flowId] = null
      return flow
    } catch (err) {
      fetchPromiseMap[flowId] = null
      throw err
    }
  }
}

export function fetchFlow(flowId: string): IAsyncThunkAction<IFlow | undefined> {
  return async (dispatch, getState) => {
    if (!flowId) {
      return
    }

    try {
      await dispatch({
        type: ActionTypes.FLOW_FETCH,
        $fetch: expandURL(API.flow.endpoints.getFlow, {
          ns: flowId,
          content_holder: '',
        }),
        $error: () => {},
        flowId,
      })
    } catch (error) {
      if (isRequestError(error) && error.reason === 'business-logic') return
      errorTrackingService.trackError(error, { fingerprint: 'fetch-flow' })
    }

    return flowSelectors.getById(getState(), flowId)
  }
}

export const fetchFullFlow = (
  flowId: string,
  options: { chId: null | string },
): IAsyncThunkAction<IFlow | undefined> => {
  return async (dispatch, getState) => {
    const content_holder = options.chId
    try {
      await dispatch({
        $fetch: expandURL(API.flow.endpoints.getFlowData, {
          ns: flowId,
          // for retrieving aggregated stats content holder should be omitted, not empty, and not null
          ...(content_holder ? { content_holder } : {}),
        }),
        type: atypes.FLOW_DATA_FETCH,
        flowId,
        content_holder,
        $error: () => {},
      })
      return flowSelectors.getById(getState(), flowId)
    } catch (error) {
      if (isRequestError(error) && error.reason === 'business-logic' && error.response) {
        const alertText = Array.isArray(error.response.errors)
          ? error.response.errors.join('. ')
          : error.response.error

        alert(alertText, 'danger')
        return
      }

      alert(l.translate('Something went wrong on the server. Please try again.'), 'danger')
      errorTrackingService.trackError(error)
      return
    }
  }
}

export const fetchFlowContentData = (
  flowId: string,
  flowName: string,
  flowPath: string,
): IAsyncThunkAction => {
  return async (dispatch) => {
    dispatch({ type: atypes.FLOW_CONTENT_DATA_FETCH_REQUEST, flowId })

    try {
      const { data } = await FlowApi.getFlowContentData({ query: { ns: flowId } })

      dispatch({ type: atypes.FLOW_CONTENT_DATA_FETCH_RESPONSE, data, flowId, flowName, flowPath })
    } catch (error) {
      dispatch({ type: atypes.FLOW_CONTENT_DATA_FETCH_ERROR, flowId })
      dispatch(contentManagementActions.setLastSelectedFlowId(null))
      handleCatch(error)
    }
  }
}

export function updateFlowName(flowId: string, name: string): IAsyncThunkAction {
  return async (dispatch) => {
    // eslint-disable-next-line
    // @ts-expect-error
    const { flow } = await dispatch({
      $fetch: [
        API.flow.endpoints.setFlowName,
        {
          method: 'POST',
          body: JSON.stringify({
            ns: flowId,
            name,
          }),
          headers: { 'Content-Type': 'application/json' },
        },
      ],
    })
    const item = parseFlow(flow)
    dispatch({
      type: ActionTypes.FLOW_UPDATE_NAME_SUCCESS,
      item,
    })
    return item
  }
}

export function updateFlowShared(
  flowId: string,
  shared: boolean,
): IAsyncThunkAction<{ player_embed_url: string | null; player_page_url: string | null }> {
  return async (dispatch) => {
    // eslint-disable-next-line
    // @ts-expect-error
    const { flow } = await dispatch({
      $fetch: [
        API.flow.endpoints.setFlowShared,
        {
          method: 'POST',
          body: JSON.stringify({
            ns: flowId,
            shared,
          }),
          headers: { 'Content-Type': 'application/json' },
        },
      ],
    })
    const item = parseFlow(flow)
    dispatch({
      type: ActionTypes.FLOW_UPDATE_SHARED_SUCCESS,
      item,
    })
    return item
  }
}

export function updateFlowSharedCloning(
  flowId: string,
  shared: boolean,
): IAsyncThunkAction<{ player_embed_url: string | null; player_page_url: string | null }> {
  return async (dispatch) => {
    // eslint-disable-next-line
    // @ts-expect-error
    const { flow } = await dispatch({
      $fetch: [
        API.flow.endpoints.setFlowSharedCloning,
        {
          method: 'POST',
          body: JSON.stringify({
            ns: flowId,
            shared_cloning_enabled: shared,
          }),
          headers: { 'Content-Type': 'application/json' },
        },
      ],
    })
    const item = parseFlow(flow)
    dispatch({
      type: ActionTypes.FLOW_UPDATE_SHARED_CLONING_SUCCESS,
      item,
    })
    return item
  }
}

export function resetFlow(flowData: LightFlow | IBackendFullFlow): IThunkAction {
  return (dispatch) => {
    const item = parseFlow(flowData)
    dispatch({
      type: ActionTypes.FLOW_RESET,
      item,
    })
    return item
  }
}

export const setFlowHasDraft = (flowId: string, hasDraft: boolean) => ({
  type: ActionTypes.FLOW_SET_HAS_DRAFT,
  flowId,
  data: hasDraft,
})

export const setDraftSuccess = (flowId: string) => ({
  type: ActionTypes.FLOW_SET_DRAFT_SUCCESS,
  flowId,
})

export const exportFlow = (
  flowId: string,
  destAccountId: string,
  mappingSettings = {},
): IAsyncThunkAction => {
  return async (dispatch) => {
    dispatch({ type: ActionTypes.FLOW_EXPORT, flowId })
    try {
      const integerDestAccountId = Number(destAccountId.replace('fb', ''))

      // eslint-disable-next-line
      // @ts-expect-error
      const { state } = await dispatch({
        $fetch: [
          API.cms.endpoints.exportFlow,
          {
            method: 'POST',
            body: JSON.stringify({
              flow_ns: flowId,
              target_account_id: integerDestAccountId,
              mapping_settings: mappingSettings,
            }),
            headers: { 'Content-Type': 'application/json' },
          },
        ],
      })

      if (!state) {
        dispatch(exportFlowError(flowId))
        return
      }

      dispatch({ type: ActionTypes.FLOW_EXPORT_SUCCESS, flowId })
    } catch (error) {
      dispatch(exportFlowError(flowId))
      console.trace(error)
    }
  }
}

export const exportFlowComplete = (flowId: string) => ({
  type: ActionTypes.FLOW_EXPORT_COMPLETE,
  flowId,
})

export const exportFlowError = (flowId: string) => {
  return {
    type: ActionTypes.FLOW_EXPORT_ERROR,
    flowId,
  }
}

export const fetchFlowDependencies = (flowId: string) => ({
  type: ActionTypes.FLOW_FETCH_DEPENDENCIES,
  $fetch: API.cms.endpoints.fetchFlowDependencies.replace(':flowId', flowId),
  flowId,
})

export const importSharedFlow = (pageId: string, shareHash?: string): IAsyncThunkAction => {
  return async (dispatch) => {
    dispatch({
      type: ActionTypes.SHARED_FLOW_IMPORT,
      pageId,
    })
    try {
      // eslint-disable-next-line
      // @ts-expect-error
      const { state, flow, path } = await dispatch({
        $fetch: [
          API.cms.endpoints.importSharedFlow.replace(':pageId', pageId),
          {
            method: 'POST',
            body: JSON.stringify({
              share_hash: shareHash,
            }),
            headers: { 'Content-Type': 'application/json' },
          },
        ],
      })
      dispatch(resetFlow(flow))
      if (!state) {
        dispatch({
          type: ActionTypes.SHARED_FLOW_IMPORT_ERROR,
          pageId,
        })
        return
      }

      dispatch({
        type: ActionTypes.SHARED_FLOW_IMPORT_SUCCESS,
        pageId,
      })

      return { flow, path }
    } catch (error) {
      dispatch({
        type: ActionTypes.SHARED_FLOW_IMPORT_ERROR,
        pageId,
      })
      console.trace(error)
    }
    return null
  }
}

export const convertFlowContentChannels = (
  flowNs: string,
  channels: Partial<ConvertChannelRecord>,
): IAsyncThunkAction => {
  return async (dispatch) => {
    const requestBody = convertFlowChannelsAdapter(flowNs, channels)

    try {
      const { data } = await CmsApi.convertFlow({
        body: requestBody,
      })

      dispatch(redirectToConvertedFlow(data.fs_object))

      alert(ConvertAlert, 'success')
    } catch (error) {
      handleCatch(error)
    }
  }
}

export const redirectToConvertedFlow = (FSObject: FsFlowBaseObject): IThunkAction => {
  return () => {
    const path = FSPath.getPathURL(FSObject.path)
    navigatePollyfill(path)
  }
}

anotherTabNotificationsListener.on(
  'flow_preview_updated',
  (data: { flow: LightFlow }, dispatch) => {
    dispatch(resetFlow(data.flow))
  },
)

accountNotificationsListener.on(
  ContentManagementSystemSocketEvents.FS_FLOW_DELETED,
  (data: { flow: SocketFsFlow }, dispatch) => {
    dispatch({
      type: atypes.FLOW_REMOVED,
      path: data.flow.path,
    })
  },
)

accountNotificationsListener.on(
  ContentManagementSystemSocketEvents.FS_FLOW_RESTORED,
  (data: { flow: SocketFsFlow }, dispatch) => {
    dispatch({
      type: atypes.FLOW_RESTORED,
      path: data.flow.path,
    })
  },
)

export const flowPermanentlyRemoved = (path: string): IThunkAction => {
  return (dispatch) => {
    if (window.location.pathname.endsWith(path)) {
      navigatePollyfill(linkURL('cms'))
    }

    dispatch({
      type: atypes.FLOW_PERMANENTLY_REMOVED,
      path,
    })
  }
}

accountNotificationsListener.on(
  ContentManagementSystemSocketEvents.FS_FLOW_PERMANENTLY_DELETED,
  (data: { flow: SocketFsFlowDeleted }, dispatch) => {
    dispatch(flowPermanentlyRemoved(mapSocketFsFlowDeleted(data.flow).ns))
  },
)

import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import * as Icon from '@manychat/icons'

import { getWindow } from '../../../../utils'
import { isValidDate, parseDate, formatDate } from '../../../../utils/date'
import * as l from '../../../../utils/localization/format'
import BtnV2 from '../../../BtnV2'
import Dropdown from '../../../layers/Dropdown'
import DateRange from '../DateRange'

import cm from './DateRangePicker.module.css'

const getCalendarsCount = () => {
  const window = getWindow()
  if (window.innerWidth < 600) {
    return 1
  }
  return window.innerWidth < 1000 ? 2 : 3
}

export default class DateRangePicker extends PureComponent {
  static propTypes = {
    value: PropTypes.arrayOf(PropTypes.string),
    onChange: PropTypes.func,
    onClick: PropTypes.func,
    presets: PropTypes.array,
  }

  static PRESETS = DateRange.PRESETS

  state = {
    value: this.props.value,
    calendars: this.props.calendars || getCalendarsCount(),
  }

  dropdown = React.createRef()

  componentDidUpdate = (prevProps, prevState) => {
    const { value } = this.props
    if (prevProps.value !== value) {
      this.setState({ value })
    }
  }

  handleChange = (e) => this.setState({ value: e.target.value })

  handleApply = (e) => {
    this.dropdown.current.close()
    const { name, onChange } = this.props
    onChange({ target: { value: this.state.value, name } })
  }

  handleClose = (e) => this.dropdown.current.close()

  renderAchror = ({ open }) => {
    const { value } = this.props

    let summaryText = 'Select date range'
    if (Array.isArray(value) && isValidDate(value[0]) && isValidDate(value[1])) {
      const isSameYear = value[0].slice(0, 4) === value[1].slice(0, 4)
      const isSameMonth = value[0].slice(5, 7) === value[1].slice(5, 7)
      const start = parseDate(value[0])
      const end = parseDate(value[1])
      summaryText = `${l.date(start, isSameYear, isSameMonth)} – ${l.date(end)}`
      if (value[0] === value[1]) {
        const date = new Date()
        if (value[0] === formatDate(date)) {
          summaryText = `Today (${l.date(start, true)})`
        }
        date.setDate(date.getDate() - 1)
        if (value[0] === formatDate(date)) {
          summaryText = `Yesterday (${l.date(start, true)})`
        }
      }
    }

    return (
      <div
        className={`p-relative p-y-xs p-l-xs p-r-xl b-a rounded pointer ${open ? cm.active : ''}`}
        style={{ color: 'initial' }}
      >
        {summaryText}
        <Icon.Calendar size={24} className={cm.icon} />
      </div>
    )
  }

  renderControls = () => {
    const { value } = this.state
    const [start, end] = value || []
    return (
      <React.Fragment>
        <a onClick={this.handleClose} className="p-a-xs m-r-xs">
          Close
        </a>
        <BtnV2 variant="primary" onClick={this.handleApply} disabled={!start || !end}>
          Apply
        </BtnV2>
      </React.Fragment>
    )
  }

  render() {
    const { value } = this.state
    const { value: propsValue, onChange, onClick, ...rest } = this.props // eslint-disable-line no-unused-vars

    return (
      <Dropdown
        ref={this.dropdown}
        renderAnchor={this.renderAchror}
        anchorOrigin="bottom left"
        popoverOrigin="top left"
        arrow={false}
        className="m-t-xxs"
        onClickAnchor={onClick}
      >
        <DateRange
          {...rest}
          calendars={3}
          controls={this.renderControls()}
          value={value}
          onChange={this.handleChange}
        />
      </Dropdown>
    )
  }
}

import { IAudienceMergeCompare, IConflictResolve } from 'common/audience/audienceInterfaces'
import { ISubscriber } from 'common/audience/components/UserModal/interfaces'
import { Body, McAPI } from 'utils/api/types'

interface ISubscribersSearchResponse {
  users: ISubscriber[]
  limiter: number | null
  total: number | null
  state: boolean
}

interface ISubscribersDetailsResponse {
  user: ISubscriber
  state: boolean
}

export interface IContactMergeCompareResponse {
  state: boolean
  compare: { result: IAudienceMergeCompare; conflict: IConflictResolve }
  subscribers: {
    target: ISubscriber
    source: ISubscriber
  }
}

interface IAudienceAPI {
  searchSubscribers: (q: string) => Promise<ISubscriber[]>
  getDetailsSubscribers: (user_id: string) => Promise<ISubscriber>
  getContactMergeCompare: (
    userId: string,
    secondaryId: string,
    conflictResolve?: IConflictResolve,
  ) => Promise<IContactMergeCompareResponse>
  mergeContacts: (
    userId: string,
    secondaryId: string,
    conflictResolve?: IConflictResolve | null,
  ) => Promise<boolean>
}

export const makeMergeSubscribersAPI = (api: McAPI): IAudienceAPI => ({
  async searchSubscribers(q: string) {
    const response = await api.post<ISubscribersSearchResponse & Body>(
      '/:currentAccountID/subscribers/search',
      { body: { q } },
    )

    return response.users
  },
  async getDetailsSubscribers(userId: string) {
    const response = await api.get<ISubscribersDetailsResponse & Body>(
      '/:currentAccountID/subscribers/details?user_id=:id'.replace(':id', userId),
    )

    return response.user
  },
  async getContactMergeCompare(userId, secondaryId, conflictResolve) {
    const response = await api.post<IContactMergeCompareResponse & Body>(
      '/:currentAccountID/contactMerge/compare',
      {
        body: {
          target_id: userId,
          source_id: secondaryId,
          conflict_resolve: conflictResolve,
        },
      },
    )

    return response
  },
  async mergeContacts(userId, secondaryId, conflictResolve) {
    const response = await api.post<{ state: true } & Body>(
      '/:currentAccountID/contactMerge/merge',
      {
        body: { target_id: userId, source_id: secondaryId, conflict_resolve: conflictResolve },
      },
    )

    return response.state
  },
})

import mapValues from 'lodash/mapValues'

import { DefaultAccountTargetPermissions } from 'common/core/models/Account/defaults'
import { AccountTargetPermissionsNames } from 'common/core/models/Account/names'

export { AccountTargetPermissionsNames } from 'common/core/models/Account/names'

export enum AccountStatus {
  PRO = 'pro',
  PRO2020 = '2020_pro',
  EXPIRED = 'expired',
  EXPIRED2020 = '2020_expired',
  FREE = 'free',
  FREE2020 = '2020_free',
  PRO_TRIAL = 'trialpro',
  PRO_TRIAL2020 = '2020_trialpro',
  FLEX_PRO = 'flex_pro',
  FLEX_FREE = 'flex_free',
  FLEX_EXPIRED = 'flex_expired',
  FLEX_TRIAL = 'flex_trial',
  FLEX_PREMIUM = 'flex_premium',
  VIP_PRO = 'vippro',
}

export enum ProStatus {
  EXPIRED = -1,
  FREE = 0,
  PRO = 1,
  VIPPRO = 2,
  TRIAL_PRO = 3,
  EXPIRED_2020 = -11,
  FREE_2020 = 10,
  PRO_2020 = 11,
  TRIAL_PRO_2020 = 14,
  FLEX_PRO = 15,
  FLEX_TRIAL = 16,
  FLEX_EXPIRED = 17,
  FLEX_FREE = 18,
  FLEX_TRIAL_EXPIRED = 19,
  FLEX_TRIAL_CANCELLED = 20,
}

const FREE = {
  ...DefaultAccountTargetPermissions,
  [AccountTargetPermissionsNames.GOOGLE_SHEETS_INTEGRATION]: true,
  [AccountTargetPermissionsNames.MAIL_CHIMP_INTEGRATION]: true,
  [AccountTargetPermissionsNames.HUB_SPOT_INTEGRATION]: true,
  [AccountTargetPermissionsNames.TIKTOK_ADS_INTEGRATION]: true,
  [AccountTargetPermissionsNames.ACTIVE_CAMPAIGN_INTEGRATION]: true,
  [AccountTargetPermissionsNames.CONVERT_KIT_INTEGRATION]: true,
  [AccountTargetPermissionsNames.CHATGPT_INTEGRATION]: true,
  [AccountTargetPermissionsNames.CLAUDE_INTEGRAION]: true,
  [AccountTargetPermissionsNames.DEEPSEEK_INTEGRAION]: true,
  [AccountTargetPermissionsNames.HOTMART_INTEGRATION]: true,
  [AccountTargetPermissionsNames.SALESFORCE_INTEGRATION]: true,
  [AccountTargetPermissionsNames.KLAVIYO_INTEGRATION]: true,
  [AccountTargetPermissionsNames.PAYPAL_INTEGRATION]: true,
  [AccountTargetPermissionsNames.FLODESK_INTEGRATION]: true,
  [AccountTargetPermissionsNames.STRIPE_INTEGRATION]: true,
  [AccountTargetPermissionsNames.GT_QR_CODE]: true,
  [AccountTargetPermissionsNames.GT_REF_URL_FB]: true,
  [AccountTargetPermissionsNames.GT_CGT]: true,
  [AccountTargetPermissionsNames.GT_CGT_2]: true,
  [AccountTargetPermissionsNames.LIVE_CHAT]: true,
  [AccountTargetPermissionsNames.FACEBOOK_ADS]: true,
  [AccountTargetPermissionsNames.PAID_MESSAGES]: true,
  [AccountTargetPermissionsNames.BROADCAST_FB]: true,
  [AccountTargetPermissionsNames.BROADCAST_INSTAGRAM]: true,
  [AccountTargetPermissionsNames.MESSAGE_TAGS_MIGRATION_TO_PRO_BANNER]: true,
  [AccountTargetPermissionsNames.MESSAGE_TAGS_MIGRATION_TO_PRO_NOTIFICATION_BAR]: true,
  [AccountTargetPermissionsNames.MESSAGE_TAGS_MIGRATION_TO_PRO_CONTENTTYPE_PICKER_WARNING]: true,
}

const FREE2020 = {
  ...FREE,
  [AccountTargetPermissionsNames.FACEBOOK_ADS]: false,
  [AccountTargetPermissionsNames.PAID_MESSAGES]: false,
  [AccountTargetPermissionsNames.SUBSCRIBER_LIMIT_200_BANNER]: true,
}

const TargetPermissionsTrue = mapValues(DefaultAccountTargetPermissions, () => true)

export const AccountTargetPermissions: Record<
  AccountStatus,
  Partial<Record<AccountTargetPermissionsNames, boolean>>
> = {
  [AccountStatus.PRO2020]: TargetPermissionsTrue,
  [AccountStatus.FREE2020]: FREE2020,
  [AccountStatus.EXPIRED2020]: FREE2020,
  [AccountStatus.PRO]: TargetPermissionsTrue,
  [AccountStatus.PRO_TRIAL]: TargetPermissionsTrue,
  [AccountStatus.PRO_TRIAL2020]: TargetPermissionsTrue,
  [AccountStatus.VIP_PRO]: TargetPermissionsTrue,
  [AccountStatus.FREE]: FREE,
  [AccountStatus.EXPIRED]: FREE,
  [AccountStatus.FLEX_PRO]: TargetPermissionsTrue,
  [AccountStatus.FLEX_FREE]: FREE,
  [AccountStatus.FLEX_EXPIRED]: FREE,
  [AccountStatus.FLEX_TRIAL]: TargetPermissionsTrue,
  [AccountStatus.FLEX_PREMIUM]: TargetPermissionsTrue,
}

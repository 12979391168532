import { NodeType } from 'common/builder/constants/NodeType'
import { AiAgentNodeType } from 'common/builder/nodes/nodeInterfaces'

export function isAiAgentNode(node: unknown): node is AiAgentNodeType {
  return (
    typeof node === 'object' &&
    node !== null &&
    'nodeType' in node &&
    node.nodeType === NodeType.AI_AGENT
  )
}

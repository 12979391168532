import PropTypes from 'prop-types'

import { ActiveCampaignConfig } from 'common/actions/integrations/ActiveCampaign/model/constants'
import { ChatGPTConfig } from 'common/actions/integrations/ChatGPT/model/constants'
import { ConvertKitConfig } from 'common/actions/integrations/ConvertKit/model/constants'
import { FlodeskConfig } from 'common/actions/integrations/Flodesk/model/constants'
import { GoogleSheetsConfig } from 'common/actions/integrations/GoogleSheets/model/constants'
import { HotmartConfig } from 'common/actions/integrations/Hotmart/lib/config'
import { HubSpotConfig } from 'common/actions/integrations/HubSpot/model/constants'
import { KlaviyoConfig } from 'common/actions/integrations/Klaviyo/model/constants'
import { MailChimpConfig } from 'common/actions/integrations/MailChimp/model/constants'

import { ClaudeConfig } from '../../Claude/lib/config'
import { DeepSeekConfig } from '../../DeepSeek/lib/config'
import { SalesforceConfig } from '../../Salesforce/model/constants'
import { TikTokAdsConfig } from '../../TikTokAds/model/constants'

export const Integrations = [
  TikTokAdsConfig,
  FlodeskConfig,
  GoogleSheetsConfig,
  HotmartConfig,
  ChatGPTConfig,
  ClaudeConfig,
  DeepSeekConfig,
  KlaviyoConfig,
  ActiveCampaignConfig,
  MailChimpConfig,
  HubSpotConfig,
  SalesforceConfig,
  ConvertKitConfig,
]

export const IntegrationsByType = Integrations.reduce((map, integration) => {
  map[integration.type] = integration
  return map
}, {})

export const IntegrationPropTypes = {
  integration: PropTypes.shape({
    name: PropTypes.string.isRequired,
    icon: PropTypes.func.isRequired,
    type: PropTypes.string.isRequired,
    fullName: PropTypes.string.isRequired,
    description: PropTypes.any.isRequired,
    actions: PropTypes.array.isRequired,
    title: PropTypes.string,
    isBeta: PropTypes.bool.isRequired,
    logo: PropTypes.string,
    href: PropTypes.string,
  }).isRequired,
}

import React from 'react'
import { useAppSelector } from 'reduxTyped'
import { l } from '@manychat/manyui'

import { ChannelType } from 'common/core/constants/ChannelType'
import { getPageNameByChannel } from 'common/core/selectors/channelSelectors'

export const InstagramWrongPageReloadPermissions = () => {
  const userName = useAppSelector((state) => getPageNameByChannel(state, ChannelType.INSTAGRAM))
  return (
    <span>
      {l.translate('Wrong account selected. Use account {userName} to refresh permissions.', {
        userName,
      })}
    </span>
  )
}

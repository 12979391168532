/* eslint-disable no-restricted-imports */
import { TypedUseSelectorHook, useDispatch, useSelector, useStore } from 'react-redux'
import {
  createAsyncThunk,
  createListenerMiddleware,
  ListenerMiddlewareInstance,
} from '@reduxjs/toolkit'

import type { ThunkExtra } from 'common/core/interfaces/actions'
import { BusinessError } from 'shared/api/lib/errors/business'

export const useAppDispatch: () => AppDispatch = useDispatch
export const useAppSelector: TypedUseSelectorHook<AppState> = useSelector
export const useAppStore: () => AppStore = useStore

export interface AppThunkApiConfig {
  state: AppState
  dispatch: AppDispatch
  rejectValue: BusinessError | void
  extra: ThunkExtra
}

export const createAppAsyncThunk = createAsyncThunk.withTypes<AppThunkApiConfig>()

type ListenerMiddlewareApi = unknown

/**
 * Do not use this listener directly. There are two constants with the same reference
 * just to not to create cycle for TypeScript. This constant is used only for `store`
 * initialization. Use `appListenerMiddleware` instead.
 */
export const reduxListenerMiddleware = createListenerMiddleware()

export const appListenerMiddleware = reduxListenerMiddleware as ListenerMiddlewareInstance<
  AppState,
  AppDispatch,
  ListenerMiddlewareApi
>

import React from 'react'

import { KeywordTitle } from 'apps/cms/components/Flows/components/FlowCard/components/Triggers/components/KeywordTitle/KeywordTitle'
import { KeywordStatus } from 'apps/keywords/models/KeywordRule/constants'
import { ChannelIcon } from 'common/core/constants/ChannelType'
import { TriggerType } from 'common/flow/constants/TriggerType'
import { Keyword } from 'shared/api/requests/keywords/schemas'

import { getRuns, getCtr, getWidgetStatistics } from '../statistics'

export const getKeywordTrigger = (keyword: Keyword) => {
  const Icon = ChannelIcon[keyword.channel]

  return {
    icon: <Icon size={16} />,
    id: keyword.rule_id,
    type: TriggerType.KEYWORD,
    title: <KeywordTitle keyword={keyword} />,
    runs: getRuns(keyword.runs),
    ctr: getCtr(null),
    status: keyword.status,
    disabled: keyword.status === KeywordStatus.DRAFT,
    created: keyword.ts_created,
    impressions: getWidgetStatistics(null),
    optIns: getWidgetStatistics(null),
  }
}

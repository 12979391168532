import { useAppSelector } from 'reduxTyped'

import { AutomationModalType, CmsModalsState } from 'apps/cms/store/lib/types'

export const useModalData = <ModalType extends AutomationModalType>({
  type,
}: {
  type: ModalType
}) => {
  const modalState = useAppSelector((state) => state.contentManagementSystem.modals[type].data)

  if (modalState === null) {
    throw new Error(`${type} modal expects to have non nullable state when it is opened.`)
  }

  type Data = Pick<CmsModalsState, ModalType>[ModalType]['data']

  return modalState as NonNullable<Data>
}

import PaymentCurrencies from 'common/core/constants/PaymentCurrencies'

export const ButtonMaxCaptionLength = 20
export const HyperlinkMaxCaptionLength = 300

/** @see https://stripe.com/docs/currencies#minimum-and-maximum-charge-amounts */
export const MinimumCurrenciesPayments: Record<PaymentCurrencies, number> = {
  [PaymentCurrencies.USD]: 0.5,
  [PaymentCurrencies.EUR]: 0.5,
  [PaymentCurrencies.GBP]: 0.3,
  [PaymentCurrencies.CAD]: 0.5,
  [PaymentCurrencies.AUD]: 0.5,
  [PaymentCurrencies.BRL]: 0.5,
  [PaymentCurrencies.HKD]: 4.0,
  [PaymentCurrencies.JPY]: 50.0,
  [PaymentCurrencies.NZD]: 0.5,
  [PaymentCurrencies.MXN]: 10.0,
  [PaymentCurrencies.CHF]: 0.5,
  [PaymentCurrencies.DKK]: 2.5,
  [PaymentCurrencies.NOK]: 3.0,
  [PaymentCurrencies.SGD]: 0.5,
  [PaymentCurrencies.SEK]: 3.0,
  [PaymentCurrencies.RUB]: 1.0,
}

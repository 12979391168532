import React, { useRef, useState } from 'react'
import cx from 'classnames'
import { useAppSelector } from 'reduxTyped'

import MyProfilePopover from 'common/core/components/MyProfilePopover'
import { getUser } from 'common/core/selectors/appSelectors'
import { CircleAvatar } from 'components/CircleAvatar'
import { Col, Row } from 'components/Grid'

import { MyProfilePopoverAnalytics } from './MyProfileLinkPopover/lib/analytics'
import { MyProfileLinkPopover } from './MyProfileLinkPopover/MyProfileLinkPopover'

import cm from './MyProfileLink.module.css'

export interface MyProfileLinkProps {
  label: string
  open?: boolean
  isSidebarHidden?: boolean
  isRightTimeAnimate?: boolean
  onOpen?(): void
  onClose?(): void
}

export function MyProfileLink(props: MyProfileLinkProps) {
  const { label, open = false, isSidebarHidden, isRightTimeAnimate, onOpen, onClose } = props
  const [showPopover, setShowPopover] = useState(false)
  const [showLinkPopover, setShowLinkPopover] = useState(false)
  const rootRef = useRef<HTMLDivElement>(null)
  const profile = useAppSelector(getUser)
  const hasProfileDashboard = profile.has_pages_dashboards

  const handleLinkClick = () => {
    MyProfilePopoverAnalytics.logOpenPopover()
    setShowLinkPopover(true)
  }

  const hideLinkPopover = () => {
    if (showLinkPopover) {
      MyProfilePopoverAnalytics.logClosePopover()
    }
    setShowLinkPopover(false)
  }

  const handleShowPopover = () => {
    setShowPopover(true)
    onOpen?.()
  }

  const handleHidePopover = () => {
    setShowPopover(false)
    onClose?.()
  }

  if (hasProfileDashboard) {
    return (
      <div
        ref={isSidebarHidden ? rootRef : null}
        onClick={handleLinkClick}
        className={cx(cm.barLink, 'row align-center c-pointer', {
          'text-success': showLinkPopover,
        })}
      >
        <div className={cx(cm.icon, 'row justify-center')}>
          <CircleAvatar size={24} url={profile?.avatar_small} />
        </div>
        {!isSidebarHidden && isRightTimeAnimate && (
          <span className={cx(cm.navText)} ref={rootRef}>
            {label}
          </span>
        )}

        <MyProfileLinkPopover
          isOpen={showLinkPopover}
          anchor={rootRef.current || null}
          onClose={hideLinkPopover}
        />
      </div>
    )
  }

  return (
    <div
      className={cx(showPopover ? 'text-success' : 'text-secondary', 'text-h-success pointer')}
      onClick={handleShowPopover}
    >
      <Row middle className={cm.barLink}>
        <Col width={64} className="text-center">
          <CircleAvatar className="m-y-2 m-auto" size={24} url={profile?.avatar_small} />
        </Col>
        {!isSidebarHidden && isRightTimeAnimate && (
          <Col className="p-r-xs">
            <span data-test-id="navigation-profile-link" ref={rootRef} className={cm.navText}>
              {label}
            </span>
          </Col>
        )}
      </Row>

      <MyProfilePopover
        anchor={rootRef.current || null}
        open={(open || showPopover) && rootRef.current !== null}
        onRequestClose={handleHidePopover}
      />
    </div>
  )
}

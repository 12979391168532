export const getCustomEventsObject = (state) => {
  return state.customEvents || {}
}

export const getCustomEventsItems = (state) => {
  const customEventsObject = getCustomEventsObject(state)

  return customEventsObject.items
}

export const getNewEventObject = (state) => {
  const createModal = getCustomEventsObject(state).createModal
  return {
    ...createModal,
    availableCurrencies: undefined,
  }
}

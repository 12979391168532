import React, { useCallback } from 'react'
import cx from 'classnames'
import { l } from '@manychat/manyui'

import { getRightPositionOfCustomButton } from './helpers'
import { useFreshChat } from './useFreshChat'

import cm from './FreshChatService.module.css'

export const FreshChatService = () => {
  const { hideCustomChatButton, isOpen, openFreshChat, closeFreshChat } = useFreshChat({})

  const handleToggle = useCallback(() => {
    isOpen ? closeFreshChat() : openFreshChat()
  }, [closeFreshChat, isOpen, openFreshChat])

  if (hideCustomChatButton) {
    return null
  }

  const rightPositionOfCustomButton = getRightPositionOfCustomButton()

  return (
    <input
      className={cx(cm.fdChatBubble, 'p-fixed text-center pointer', {
        'd-none': isOpen,
        'd-block': !isOpen,
      })}
      style={{ right: rightPositionOfCustomButton }}
      id="fd-chat-bubble"
      type="button"
      value={l.translate('Live Support')}
      onClick={handleToggle}
    />
  )
}

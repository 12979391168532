import { PayloadAction, createEntityAdapter, createSlice } from '@reduxjs/toolkit'

import { AiIntentTrigger, AiIntentsState } from 'apps/aiIntents/interfaces/interfaces'
import aiIntentTriggerAdapter from 'apps/aiIntents/model/adapter'
import { ChannelType } from 'common/core/constants/ChannelType'
import {
  IAsyncThunkActionWithReturnValue,
  IThunkAction,
  IAsyncThunkAction,
} from 'common/core/interfaces/actions'
import * as flowAtypes from 'common/flow/constants/flowReduxActionTypes'
import { TriggerType } from 'common/flow/constants/TriggerType'
import { getById } from 'common/flow/selectors/flowSelectors'
import { WebSocketEvents } from 'constants/WebSockets'
import { handleCatch } from 'shared/api/lib/errors/handlers'
import { AiIntentsApi } from 'shared/api/requests/aiIntents'
import { AiIntent, AiIntentStatus } from 'shared/api/requests/aiIntents/schemas'
import { getClientId } from 'utils/clientId'
import { analyticsService } from 'utils/services/analytics'
import { anotherTabNotificationsListener } from 'utils/services/notificationsService'

export const AiIntentsAdapter = createEntityAdapter<AiIntent>({
  selectId: (aiIntent) => aiIntent.intent_id,
})

export const InitialState: AiIntentsState = {
  list: AiIntentsAdapter.getInitialState(),
}

const AiIntentsSlice = createSlice({
  name: 'ai_intents',
  initialState: InitialState,
  reducers: {
    resetState: () => InitialState,

    removeOne: (state, action: PayloadAction<number>) => {
      AiIntentsAdapter.removeOne(state.list, action.payload)
    },

    upsertOne: (state, action: PayloadAction<AiIntent>) => {
      AiIntentsAdapter.upsertOne(state.list, action.payload)
    },
  },
})

export default AiIntentsSlice.reducer

const { upsertOne, removeOne, resetState } = AiIntentsSlice.actions

export const AiIntentsSelectors = {
  getById: (state: RootState, id: number) =>
    AiIntentsAdapter.getSelectors().selectById(state.aiIntents.list, id),
}

const create = (
  intent: string,
  channel: ChannelType,
  flow_ns: string | null,
): IAsyncThunkActionWithReturnValue<AiIntent | null> => {
  return async (dispatch) => {
    let response
    try {
      response = await AiIntentsApi.createAiIntent({
        body: { intent, channel, flow_ns },
        query: { client_id: getClientId() },
      })
    } catch (error) {
      handleCatch(error)
      return null
    }

    const triggerItem = aiIntentTriggerAdapter(response.data.intent_rule)
    dispatch({ type: flowAtypes.FLOW_TRIGGER_UPDATED, item: triggerItem })

    dispatch(upsertOne(response.data.intent_rule))

    return response.data.intent_rule
  }
}

const createTrigger = (
  intent: string,
  channel: ChannelType,
  flow_ns: string | null,
): IAsyncThunkActionWithReturnValue<{ item: AiIntentTrigger } | null> => {
  return async (dispatch, getState) => {
    const aiIntent = await dispatch(create(intent, channel, flow_ns))

    if (!aiIntent) {
      return null
    }

    const result = { item: aiIntentTriggerAdapter(aiIntent) }

    const flow = getById(getState(), flow_ns)

    if (!flow || !flow.has_published_content) {
      return result
    }

    // Try to activate trigger when flow has published content and do nothing if an error appears
    const isActivated = await dispatch(
      updateStatus(result.item.id, AiIntentStatus.ACTIVE, {
        silent: true,
        doNotLogStatusChange: true,
      }),
    )

    analyticsService.sendEvent('TRIGGER_PREACTIVATION_RESULT_ON_SAVE', {
      isActivated,
      triggerId: result.item.triggerId,
    })

    return result
  }
}

const fetchById = (intent_id: number): IThunkAction => {
  return async (dispatch) => {
    let response
    try {
      response = await AiIntentsApi.getAiIntent({ query: { intent_id, client_id: getClientId() } })
    } catch (error) {
      handleCatch(error)
      return
    }

    const triggerItem = aiIntentTriggerAdapter(response.data.intent_rule)
    dispatch({ type: flowAtypes.FLOW_TRIGGER_UPDATED, item: triggerItem })

    dispatch(upsertOne(response.data.intent_rule))
  }
}

const remove = (intent_id: number): IThunkAction => {
  return async (dispatch) => {
    try {
      await AiIntentsApi.deleteAiIntent({
        body: { intent_id },
        query: { client_id: getClientId() },
      })
    } catch (error) {
      handleCatch(error)
      return
    }

    dispatch({
      type: flowAtypes.FLOW_TRIGGER_REMOVED,
      triggerType: TriggerType.AI_INTENT,
      id: intent_id,
    })

    dispatch(removeOne(intent_id))
  }
}

const updateStatus = (
  intent_id: number,
  status: AiIntentStatus,
  options: { silent?: boolean; doNotLogStatusChange?: boolean } = {},
): IAsyncThunkActionWithReturnValue<boolean> => {
  return async (dispatch) => {
    let triggerItem
    let response

    try {
      response = await AiIntentsApi.updateAiIntentStatus({
        body: { intent_id, status },
        query: { client_id: getClientId() },
      })
      triggerItem = aiIntentTriggerAdapter(response.data.intent_rule)

      if (!options.doNotLogStatusChange) {
        analyticsService.sendEvent('TRIGGER.STATUS.SWITCHED', {
          isEnabled: status === AiIntentStatus.ACTIVE,
          triggerId: triggerItem.triggerId,
        })
      }
    } catch (error) {
      if (!options.silent) {
        handleCatch(error)
      }
      return false
    }

    dispatch({ type: flowAtypes.FLOW_TRIGGER_UPDATED, item: triggerItem })

    dispatch(upsertOne(response.data.intent_rule))
    return true
  }
}

const update = ({
  intentId,
  intent,
  flowId,
}: {
  intentId: number
  intent: string
  flowId?: string
}): IAsyncThunkAction<boolean> => {
  return async (dispatch) => {
    let response
    try {
      response = await AiIntentsApi.updateAiIntent({
        body: { intent_id: intentId, intent, flow_ns: flowId },
        query: { client_id: getClientId() },
      })
    } catch (error) {
      handleCatch(error)
      return false
    }

    const triggerItem = aiIntentTriggerAdapter(response.data.intent_rule)
    dispatch({ type: flowAtypes.FLOW_TRIGGER_UPDATED, item: triggerItem })

    dispatch(upsertOne(response.data.intent_rule))

    return true
  }
}

export const AiIntentsActions = {
  fetchById,
  create,
  createTrigger,
  remove,
  updateStatus,
  update,
  resetState,
}

anotherTabNotificationsListener.on(WebSocketEvents.AI_INTENT_CREATED, (data, dispatch) => {
  dispatch({ type: flowAtypes.FLOW_TRIGGER_UPDATED, item: aiIntentTriggerAdapter(data.model) })
})
anotherTabNotificationsListener.on(WebSocketEvents.AI_INTENT_UPDATED, (data, dispatch) => {
  dispatch({ type: flowAtypes.FLOW_TRIGGER_UPDATED, item: aiIntentTriggerAdapter(data.model) })
})
anotherTabNotificationsListener.on(WebSocketEvents.AI_INTENT_DELETED, (data, dispatch) => {
  dispatch({
    type: flowAtypes.FLOW_TRIGGER_REMOVED,
    triggerType: TriggerType.AI_INTENT,
    id: data.model.intent_id,
  })
})

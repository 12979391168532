import { api } from 'shared/api'

import { GenerateSchema } from './schemas'

export const AiAgentApi = {
  generate: api.account.createPost({
    url: '/aIAgent/generate',
    schemas: GenerateSchema,
  }),
}

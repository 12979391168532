import dot from 'dot-prop-immutable'

import { makeLimitedListReducer, mergeToListItem } from 'utils/factory'

import * as atypes from '../filterReduxActionTypes'

const listReducer = makeLimitedListReducer('segments')

export default function segmentsReducer(state, action) {
  switch (action.type) {
    case atypes.SEGMENT_COUNTER_CHANGED: {
      const {
        data: { id, delta },
      } = action
      const segment = dot.get(state, `byId.${id}`)

      if (segment) {
        const count = dot.get(segment, `count`) + delta
        return mergeToListItem(state, id, { count })
      }
    }
  }

  return listReducer(state, action)
}

import React from 'react'
import cx from 'classnames'
import { useAppSelector } from 'reduxTyped'
import { l } from '@manychat/manyui'

import InformationWABAModal from 'apps/whatsApp/components/InformationWABAModal/InformationWABAModal'
import cm from 'common/core/components/NotifyBar/StaticNotifications/StaticNotifications.module.css'
import { getConnectedWhatsAppChannel } from 'common/core/selectors/channelSelectors'
import { useToggle } from 'common/hooks/useToggle'
import { RegistrationFlowStep } from 'shared/api/requests/whatsApp/schemas'
import { analyticsService } from 'utils/services/analytics'

const WASuccessSandboxActivated = () => {
  const channel = useAppSelector(getConnectedWhatsAppChannel)
  const { state, toggle } = useToggle()

  const isVerificationProgress = Boolean(
    channel?.verification || channel?.state === RegistrationFlowStep.VERIFICATION_PENDING,
  )

  const showVerificationModal = isVerificationProgress && state !== 'off'

  const handleClickLearnMore = () => {
    analyticsService.sendEvent('NOTIFICATION_BAR.WA_SUCCESS_SANDBOX_ACTIVATED.LEARN_MORE.CLICK')
    toggle()
  }

  return (
    <>
      <span>
        {l.translate(
          'Your WhatsApp channel is activated. Verify your WhatsApp Business to enable outbound messaging.',
        )}
      </span>
      <span
        onClick={handleClickLearnMore}
        className={cx(cm.more, 'c-pointer m-l-xs')}
        data-test-id="learn_more_link_wa_success_sandbox_activated"
      >
        {l.translate('Learn more')}
      </span>
      <InformationWABAModal openModal={showVerificationModal} onRequestClose={toggle} />
    </>
  )
}

export default WASuccessSandboxActivated

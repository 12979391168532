import { z } from 'zod'

import { BusinessErrorSchema } from 'shared/api/common/schemas/errors'

/**
 * This business error can be used to throw
 * a business error whenever a Stripe transaction fails
 */
export class StripeError extends Error {
  public readonly field = 'stripe_error'

  public readonly message = 'Stripe transaction failed'

  public readonly original_message = 'Stripe transaction failed'
}

const StripeErrorSchema = BusinessErrorSchema.extend({
  field: z.literal('stripe_error'),
})

export const isStripeError = (error: unknown): error is StripeError =>
  StripeErrorSchema.safeParse(error).success

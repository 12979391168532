import React, { useEffect } from 'react'
import cx from 'classnames'
import { useAppDispatch, useAppSelector } from 'reduxTyped'
import { Modal, l } from '@manychat/manyui'

import { isMobile } from 'utils'
import { Flows } from 'apps/cms/components/Flows/Flows'
import { FlowCardClickAction } from 'apps/cms/pages/ContentManagementSystem/lib/types'
import { contentManagementActions } from 'apps/cms/store'
import { FlowPickerPreview } from 'apps/flowPicker/components/FlowPickerPreview/FlowPickerPreview'
import { FlowPickerAnalytics } from 'apps/flowPicker/lib/analytics'
import { AllowCreateFlowSources } from 'apps/flowPicker/lib/constants'
import { FlowPickerSelectors, FlowPickerActions } from 'apps/flowPicker/store'

import cm from './FlowPickerModal.module.css'

export const FlowPickerModal = () => {
  const dispatch = useAppDispatch()

  const isOpen = useAppSelector(FlowPickerSelectors.getIsFlowPickerModalOpen)
  const flowPickerSource = useAppSelector(FlowPickerSelectors.getFlowPickerSource)
  const allowCreateFlow = AllowCreateFlowSources.some((source) =>
    flowPickerSource?.includes(source),
  )

  useEffect(() => {
    if (isOpen) {
      FlowPickerAnalytics.logOpenFlowPickerModal()
      dispatch(contentManagementActions.setIsInitialized(true))

      dispatch(
        contentManagementActions.initUi({
          flowCardClickAction: FlowCardClickAction.SAVE_FLOW_ID_TO_STATE,
          allowCreateFlow,
          allowCreateFolder: false,
          allowDragAndDrop: false,
          showBulkActions: false,
          showFlowContextMenu: false,
          showFolderContextMenu: false,
          showTrash: false,
          disableFlowCard: true,
          disableFlowCreation: true,
          showFlowCardActivationToggle: false,
          isOutlinedNewAutomationButton: true,
          disabledFlowCardMessage: !isMobile
            ? l.translate(
                "This Automation doesn't have a live version. Set it live before choosing.",
              )
            : null,
          customRootName: l.translate('Pick Automation'),
          allowHoverFlowCard: true,
          allowHighlightActiveFlowCard: true,
          focusSearchOnMount: true,
        }),
      )
    }

    return () => {
      dispatch(contentManagementActions.resetUi())
    }
  }, [isOpen, dispatch, allowCreateFlow])

  const handleCloseModal = () => {
    FlowPickerAnalytics.logCloseFlowPickerModal()
    dispatch(FlowPickerActions.closeModal())
  }

  if (!isOpen) {
    return null
  }

  return (
    <Modal
      open={isOpen}
      onRequestClose={handleCloseModal}
      className={cx(cm.modal, 'bg-default')}
      noContentPadding
    >
      <div className={cx('d-flex', cm.wrapper)}>
        <div className={cm.flows}>
          <div className="p-t-md md-p-x-lg p-x p-b-md">
            <Flows />
          </div>
        </div>
        <FlowPickerPreview />
      </div>
    </Modal>
  )
}

import { getInfoAboutService, initializeUsercentricsListeners } from 'utils/getInfoAboutService'
import { UserFlag } from 'utils/services/featureFlagsService/constants'

export const USERCENTRICS_INTERCOM_NAME = 'Intercom'

export class IntercomService {
  public isProvideIntercom: boolean
  public isInitializeIntercom: boolean

  constructor() {
    const { isProvideIntercom } = this.getServicesProvidedStatus()
    const isIntercomDisabled = this.getIsIntercomDisabled()

    this.isProvideIntercom = isProvideIntercom
    this.isInitializeIntercom = Boolean(window.Intercom)

    if (isProvideIntercom && !isIntercomDisabled) {
      this.loadIntercom()
    }
  }

  private handleLoad = () => {
    this.isInitializeIntercom = Boolean(window.Intercom)
  }

  private loadIntercom = () => {
    const appId = window?.intercomSettings?.app_id ?? null
    if (!appId) {
      return
    }

    const intercom = window.Intercom
    if (typeof intercom === 'function') {
      intercom('reattach_activator')
      intercom('update', window.intercomSettings)
      this.isInitializeIntercom = true
      return
    }

    const intercomMock = function () {
      // eslint-disable-next-line prefer-rest-params
      intercomMock.c(arguments)
    }
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-expect-error
    intercomMock.q = []
    intercomMock.c = function (args: unknown) {
      intercomMock.q.push(args)
    }
    window.Intercom = intercomMock

    const script = document.createElement('script')

    script.src = 'https://widget.intercom.io/widget/' + appId
    script.async = true
    script.onload = this.handleLoad
    document.head.appendChild(script)
  }

  public intercomBoot = () => {
    if (!this.isProvideIntercom || !window.Intercom) {
      return
    }
    window.Intercom('boot')
  }

  public intercomShutdown = () => {
    if (!this.isProvideIntercom || !window.Intercom) {
      return
    }
    window.Intercom('shutdown')
  }

  private getServicesProvidedStatus = () => {
    const usercentricsServicesInfo = getInfoAboutService(USERCENTRICS_INTERCOM_NAME)
    const isProvideIntercom = usercentricsServicesInfo[USERCENTRICS_INTERCOM_NAME]?.status ?? false
    return { isProvideIntercom }
  }

  private getIsIntercomDisabled = () => {
    const userFlags = window.__INIT__?.['app.user']?.flags
    const isDisableIntercomFlagEnabled = userFlags?.[UserFlag.ENABLE_APP_INTERCOM_REMOVE]

    return isDisableIntercomFlagEnabled
  }

  public refreshPermissions = () => {
    const { isProvideIntercom } = this.getServicesProvidedStatus()
    const isIntercomDisabled = this.getIsIntercomDisabled()

    this.isProvideIntercom = isProvideIntercom
    this.isInitializeIntercom = Boolean(window.Intercom)

    if (isProvideIntercom && !this.isInitializeIntercom && !isIntercomDisabled) {
      this.loadIntercom()
    }
  }
}

const intercomService = new IntercomService()
initializeUsercentricsListeners(intercomService.refreshPermissions)

export default intercomService

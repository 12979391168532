import { api } from 'shared/api'
import { WelcomeMessageApiSchemas } from 'shared/api/requests/welcomeMessage/schemas'

export const WelcomeMessageApi = {
  getData: api.account.createGet({
    url: '/welcomeMessage/getData',
    schemas: WelcomeMessageApiSchemas.getData,
  }),
  switch: api.account.createGet({
    url: '/welcomeMessage/switch',
    schemas: WelcomeMessageApiSchemas.switch,
  }),
  replaceFlow: api.account.createPost({
    url: '/welcomeMessage/replaceFlow',
    schemas: WelcomeMessageApiSchemas.replaceFlow,
  }),
  register: api.account.createGet({
    url: '/welcomeMessage/register',
    schemas: WelcomeMessageApiSchemas.register,
  }),
  removeFlow: api.account.createPost({
    url: '/welcomeMessage/removeFlow',
    schemas: WelcomeMessageApiSchemas.removeFlow,
  }),
}

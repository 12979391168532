export enum BlockType {
  // content blocks
  TEXT = 'text',
  ATTACHMENT = 'attachment',
  CARDS = 'cards',
  CARD = 'card',
  LIST = 'list',
  LIST_ITEM = 'list_item',
  QUICK_REPLY = 'quick_reply',
  TELEGRAM_KEYBOARD = 'telegram_keyboard',
  FORM_QUESTION = 'question',
  FORM_QUESTION_TIMEOUT = 'question_timeout',
  DELAY = 'delay',
  DYNAMIC = 'dynamic',
  ONE_TIME_NOTIFY_REQUEST = 'otn_request',

  // WhatsApp
  MESSAGE_TEMPLATE = 'template',
  WA_LIST_MESSAGE = 'wa_list_message',
  WA_CATALOG_MESSAGE = 'wa_catalog_message',

  // multi condition blocks
  CASE = 'case',
  DEFAULT = 'default',

  // split block
  VARIANT = 'variant',

  // EmailNew
  EMAIL_ROOT = 'email_root',
  EMAIL_TEXT = 'email_text',
  EMAIL_IMAGE = 'email_image',
  EMAIL_BUTTON = 'email_button',
  EMAIL_DIVIDER = 'email_divider',

  EMAIL_TRIGGER_OPEN = 'email_trigger_open',
}

import { AnyAction } from 'redux'
import { z, ZodError, ZodIssue } from 'zod'

import { SETTINGS_CURRENT_FETCH_RESPONSE } from 'common/settings/SettingsReduxActionTypes'
import { fetchBotSettings } from 'constants/API'
import { SettingsSchema } from 'shared/api/requests/settings/schemas/SettingsSchema'
import errorTrackingService from 'utils/services/errorTrackingService'

/**
 * Schema for actions that include settings in the payload.
 */
export const ActionWithSettingsSchema = z.object({
  type: z.string(),
  payload: z.object({
    settings: SettingsSchema,
  }),
})

/**
 * Type inferred from ActionWithSettingsSchema.
 */
export type ActionWithSettings = z.infer<typeof ActionWithSettingsSchema>

/**
 * Type guard to check if an action matches the ActionWithSettings schema.
 *
 * @param action - The action to check.
 * @returns True if the action matches the schema, false otherwise.
 */
export const settingsActionMatcher = (action: AnyAction): action is ActionWithSettings => {
  if (
    action?.type !== SETTINGS_CURRENT_FETCH_RESPONSE ||
    !action?.payload?.settings ||
    typeof action.payload.settings !== 'object'
  ) {
    return false
  }

  try {
    ActionWithSettingsSchema.parse(action)

    return true
  } catch (error) {
    const zodError = new ZodError([error as ZodIssue])
    errorTrackingService.trackZodError(zodError, {
      url: fetchBotSettings,
      schemaType: 'errors',
    })
  }

  return false
}

/**
 * Schema for legacy actions that include settings in the data.
 */
export const ActionWithSettingsLegacySchema = z.object({
  type: z.string(),
  data: z.object({
    settings: SettingsSchema,
  }),
})

/**
 * Type inferred from ActionWithSettingsLegacySchema.
 */
export type ActionWithSettingsLegacy = z.infer<typeof ActionWithSettingsLegacySchema>

/**
 * Type guard to check if an action matches the ActionWithSettingsLegacy schema.
 *
 * @param action - The action to check.
 * @returns True if the action matches the schema, false otherwise.
 */
export const settingsActionMatcherLegacy = (
  action: AnyAction,
): action is ActionWithSettingsLegacy => {
  if (
    action?.type !== SETTINGS_CURRENT_FETCH_RESPONSE ||
    !action?.data?.settings ||
    typeof action.data.settings !== 'object'
  ) {
    return false
  }

  try {
    ActionWithSettingsLegacySchema.parse(action)

    return true
  } catch (error) {
    const zodError = new ZodError([error as ZodIssue])
    errorTrackingService.trackZodError(zodError, {
      url: fetchBotSettings,
      schemaType: 'errors',
    })
  }

  return false
}

export const FETCH_PRICING_LIMIT_INFO = 'FETCH_PRICING_LIMIT_INFO'
export const FETCH_PRICING_LIMIT_INFO_RESPONSE = 'FETCH_PRICING_LIMIT_INFO_RESPONSE'

export const SETTINGS_UPDATE = 'SETTINGS_UPDATE'
export const SETTINGS_UPDATE_RESPONSE = 'SETTINGS_UPDATE_RESPONSE'

export const SETTINGS_CURRENT_SETWIDGETLOCALE_RESPONSE = 'SETTINGS_CURRENT_SETWIDGETLOCALE_RESPONSE'
export const SETTINGS_CURRENT_GETLISTLOCALES_RESPONSE = 'SETTINGS_CURRENT_GETLISTLOCALES_RESPONSE'

export const LIVECHAT_SETTINGS_UPDATE = 'LIVECHAT_SETTINGS_UPDATE'
export const LIVECHAT_SETTINGS_UPDATE_RESPONSE = 'LIVECHAT_SETTINGS_UPDATE_RESPONSE'

export const LIVECHAT_ACCOUNT_SETTINGS_UPDATE = 'LIVECHAT_ACCOUNT_SETTINGS_UPDATE'
export const LIVECHAT_ACCOUNT_SETTINGS_UPDATE_RESPONSE = 'LIVECHAT_ACCOUNT_SETTINGS_UPDATE_RESPONSE'

export const SETTINGS_HANDOVER_UPDATED_NOTIFICATION = 'SETTINGS_HANDOVER_UPDATED_NOTIFICATION'
export const SETTINGS_CURRENT_GETWHITELISTINGDOMAINS_REQUEST =
  'SETTINGS_CURRENT_GETWHITELISTINGDOMAINS_REQUEST'
export const SETTINGS_CURRENT_GETWHITELISTINGDOMAINS_RESPONSE =
  'SETTINGS_CURRENT_GETWHITELISTINGDOMAINS_RESPONSE'
export const SETTINGS_CURRENT_ADDWHITELISTINGDOMAIN_REQUEST =
  'SETTINGS_CURRENT_ADDWHITELISTINGDOMAIN_REQUEST'
export const SETTINGS_CURRENT_ADDWHITELISTINGDOMAIN_ERROR =
  'SETTINGS_CURRENT_ADDWHITELISTINGDOMAIN_ERROR'
export const SETTINGS_CURRENT_ADDWHITELISTINGDOMAIN_RESPONSE =
  'SETTINGS_CURRENT_ADDWHITELISTINGDOMAIN_RESPONSE'
export const SETTINGS_CURRENT_DELETEWHITELISTINGDOMAIN_REQUEST =
  'SETTINGS_CURRENT_DELETEWHITELISTINGDOMAIN_REQUEST'
export const SETTINGS_CURRENT_DELETEWHITELISTINGDOMAIN_ERROR =
  'SETTINGS_CURRENT_DELETEWHITELISTINGDOMAIN_ERROR'
export const SETTINGS_CURRENT_DELETEWHITELISTINGDOMAIN_RESPONSE =
  'SETTINGS_CURRENT_DELETEWHITELISTINGDOMAIN_RESPONSE'
export const SETTINGS_SET_DELETED_SUBSCRIBERS_VISIBILITY =
  'SETTINGS_SET_DELETED_SUBSCRIBERS_VISIBILITY'
export const SETTINGS_SET_DELETED_SUBSCRIBERS_VISIBILITY_RESPONSE =
  'SETTINGS_SET_DELETED_SUBSCRIBERS_VISIBILITY_RESPONSE'

export const SETTINGS_GET_TIMEZONES_LIST = 'SETTINGS_GET_TIMEZONES_LIST'
export const SETTINGS_GET_TIMEZONES_LIST_REQUEST = 'SETTINGS_GET_TIMEZONES_LIST_REQUEST'
export const SETTINGS_GET_TIMEZONES_LIST_ERROR = 'SETTINGS_GET_TIMEZONES_LIST_ERROR'
export const SETTINGS_GET_TIMEZONES_LIST_RESPONSE = 'SETTINGS_GET_TIMEZONES_LIST_RESPONSE'

export const SETTINGS_SET_BOT_TIMEZONE = 'SETTINGS_SET_BOT_TIMEZONE'
export const SETTINGS_SET_BOT_TIMEZONE_REQUEST = 'SETTINGS_SET_BOT_TIMEZONE_REQUEST'
export const SETTINGS_SET_BOT_TIMEZONE_ERROR = 'SETTINGS_SET_BOT_TIMEZONE_ERROR'
export const SETTINGS_SET_BOT_TIMEZONE_RESPONSE = 'SETTINGS_SET_BOT_TIMEZONE_RESPONSE'

export const SUSPEND_NOTIFY_ADMIN_BY_MESSENGER = 'SUSPEND_NOTIFY_ADMIN_BY_MESSENGER'
export const SUSPEND_NOTIFY_ADMIN_BY_MESSENGER_RESPONSE =
  'SUSPEND_NOTIFY_ADMIN_BY_MESSENGER_RESPONSE'
export const SUSPEND_NOTIFY_ADMIN_BY_MESSENGER_ERROR = 'SUSPEND_NOTIFY_ADMIN_BY_MESSENGER_ERROR'

export const SUSPEND_NOTIFY_ADMIN_BY_MESSENGER_NOTIFICATION =
  'SUSPEND_NOTIFY_ADMIN_BY_MESSENGER_NOTIFICATION'

export const SET_MOBILE_NOTIFY_ADMIN_PUSH_ENABLED = 'SET_MOBILE_NOTIFY_ADMIN_PUSH_ENABLED'
export const SET_MOBILE_NOTIFY_ADMIN_PUSH_ENABLED_REQUEST =
  'SET_MOBILE_NOTIFY_ADMIN_PUSH_ENABLED_REQUEST'
export const SET_MOBILE_NOTIFY_ADMIN_PUSH_ENABLED_RESPONSE =
  'SET_MOBILE_NOTIFY_ADMIN_PUSH_ENABLED_RESPONSE'
export const SET_MOBILE_NOTIFY_ADMIN_PUSH_ENABLED_ERROR =
  'SET_MOBILE_NOTIFY_ADMIN_PUSH_ENABLED_ERROR'

export const SETTINGS_SET_PAGE_NAME = 'SETTINGS_SET_PAGE_NAME'

export const SETTINGS_GENERATE_DISCONNECT_URL = 'SETTINGS_GENERATE_DISCONNECT_URL'
export const SETTINGS_GENERATE_DISCONNECT_URL_RESPONSE = 'SETTINGS_GENERATE_DISCONNECT_URL_RESPONSE'
export const SETTINGS_SWITCH_FACEBOOK_CHANNEL = 'SETTINGS_SWITCH_FACEBOOK_CHANNEL'

export const SETTINGS_FETCH_DISCONNECT_URL = 'SETTINGS_FETCH_DISCONNECT_URL'
export const SETTINGS_FETCH_DISCONNECT_URL_RESPONSE = 'SETTINGS_FETCH_DISCONNECT_URL_RESPONSE'

// Persona API
export const SETTINGS_USE_PERSONA = 'SETTINGS_USE_PERSONA'
export const SETTINGS_USE_PERSONA_RESPONSE = 'SETTINGS_USE_PERSONA_RESPONSE'
// TODO: Persona avatar images should be uploaded with one action
// export const UPLOAD_PERSONA_AVATAR = 'UPLOAD_PERSONA_AVATAR'
export const UPLOAD_PERSONA_SOURCE_AVATAR = 'UPLOAD_PERSONA_SOURCE_AVATAR'
export const UPLOAD_PERSONA_SOURCE_AVATAR_RESPONSE = 'UPLOAD_PERSONA_SOURCE_AVATAR_RESPONSE'
export const UPLOAD_PERSONA_CROPPED_AVATAR = 'UPLOAD_PERSONA_CROPPED_AVATAR'
export const UPLOAD_PERSONA_CROPPED_AVATAR_RESPONSE = 'UPLOAD_PERSONA_CROPPED_AVATAR_RESPONSE'
export const SAVE_PERSONA = 'SAVE_PERSONA'
export const SAVE_PERSONA_RESPONSE = 'SAVE_PERSONA_RESPONSE'

export const SETTINGS_CURRENT_FETCH_REQUEST = 'SETTINGS_CURRENT_FETCH_REQUEST'
export const SETTINGS_CURRENT_FETCH_RESPONSE = 'SETTINGS_CURRENT_FETCH_RESPONSE'
export const SETTINGS_CURRENT_FETCH_ERROR = 'SETTINGS_CURRENT_FETCH_ERROR'

export const SETTINGS_DEVLOGS_MARK_AS_READ = 'SETTINGS_DEVLOGS_MARK_AS_READ'
export const SETTINGS_DEVLOGS_MARK_AS_READ_REQUEST = 'SETTINGS_DEVLOGS_MARK_AS_READ_REQUEST'
export const SETTINGS_DEVLOGS_MARK_AS_READ_RESPONSE = 'SETTINGS_DEVLOGS_MARK_AS_READ_RESPONSE'
export const SETTINGS_DEVLOGS_MARK_AS_READ_ERROR = 'SETTINGS_DEVLOGS_MARK_AS_READ_ERROR'

export const SETTINGS_DEVLOGS_UPDATE_LAST_READ = 'SETTINGS_DEVLOGS_UPDATE_LAST_READ'

export const SETTINGS_NOTIFICATION_REMOVE = 'SETTINGS_NOTIFICATION_REMOVE'
export const SETTINGS_NOTIFICATION_REMOVE_REQUEST = 'SETTINGS_NOTIFICATION_REMOVE_REQUEST'
export const SETTINGS_NOTIFICATION_REMOVE_RESPONSE = 'SETTINGS_NOTIFICATION_REMOVE_RESPONSE'
export const SETTINGS_NOTIFICATION_REMOVE_ERROR = 'SETTINGS_NOTIFICATION_REMOVE_ERROR'
export const SETTINGS_NOTIFICATION_TEMPORARY_REMOVE = 'SETTINGS_NOTIFICATION_TEMPORARY_REMOVE'
export const SETTINGS_NOTIFICATION_REMOVE_FROM_CLOSED_LIST =
  'SETTINGS_NOTIFICATION_REMOVE_FROM_CLOSED_LIST'
export const SETTINGS_NOTIFICATIONS_SET = 'SETTINGS_NOTIFICATIONS_SET'

export const SETTINGS_NOTIFICATIONS_FETCH = 'SETTINGS_NOTIFICATIONS_FETCH'
export const SETTINGS_NOTIFICATIONS_FETCH_REQUEST = 'SETTINGS_NOTIFICATIONS_FETCH_REQUEST'
export const SETTINGS_NOTIFICATIONS_FETCH_RESPONSE = 'SETTINGS_NOTIFICATIONS_FETCH_RESPONSE'
export const SETTINGS_NOTIFICATIONS_FETCH_ERROR = 'SETTINGS_NOTIFICATIONS_FETCH_ERROR'

export const SETTINGS_UPDATE_LC_THREADS_FILTER = 'SETTINGS_UPDATE_LC_THREADS_FILTER'

export const SETTINGS_DEVLOGS_FETCH_POLICY_ENFORCEMENT = 'SETTINGS_DEVLOGS_FETCH_POLICY_ENFORCEMENT'
export const SETTINGS_DEVLOGS_FETCH_POLICY_ENFORCEMENT_REQUEST =
  'SETTINGS_DEVLOGS_FETCH_POLICY_ENFORCEMENT_REQUEST'
export const SETTINGS_DEVLOGS_FETCH_POLICY_ENFORCEMENT_RESPONSE =
  'SETTINGS_DEVLOGS_FETCH_POLICY_ENFORCEMENT_RESPONSE'
export const SETTINGS_DEVLOGS_FETCH_POLICY_ENFORCEMENT_ERROR =
  'SETTINGS_DEVLOGS_FETCH_POLICY_ENFORCEMENT_ERROR'

export const SETTINGS_UPDATE_CURRENT_USER_ROLE = 'SETTINGS_UPDATE_CURRENT_USER_ROLE'
export const SETTINGS_UPDATE_CURRENT_USER_BILLING_ACCESS =
  'SETTINGS_UPDATE_CURRENT_USER_BILLING_ACCESS'

export const BOT_SETTINGS_FETCH = 'BOT_SETTINGS_FETCH'
export const BOT_SETTINGS_FETCH_REQUEST = 'BOT_SETTINGS_FETCH_REQUEST'
export const BOT_SETTINGS_FETCH_RESPONSE = 'BOT_SETTINGS_FETCH_RESPONSE'
export const BOT_SETTINGS_FETCH_ERROR = 'BOT_SETTINGS_FETCH_ERROR'

export const MESSAGING_FEATURE_REVIEW = 'MESSAGING_FEATURE_REVIEW'
export const MESSAGING_FEATURE_REVIEW_RESPONSE = 'MESSAGING_FEATURE_REVIEW_RESPONSE'

// settings: export bot
export const EXPORT_BOT = 'EXPORT_BOT'
export const EXPORT_BOT_REQUEST = 'EXPORT_BOT_REQUEST'
export const EXPORT_BOT_RESPONSE = 'EXPORT_BOT_RESPONSE'
export const EXPORT_BOT_ERROR = 'EXPORT_BOT_ERROR'
export const EXPORT_BOT_COMPLETE = 'EXPORT_BOT_COMPLETE'

//orders
export const ORDERS_FETCH_RESPONSE = 'ORDERS_FETCH_RESPONSE'

// WhatsApp settings
export const UPDATE_WHATSAPP_BUSINESS_SETTINGS = 'UPDATE_WHATSAPP_BUSINESS_SETTINGS'
export const UPDATE_WHATSAPP_BUSINESS_SETTINGS_RESPONSE =
  'UPDATE_WHATSAPP_BUSINESS_SETTINGS_RESPONSE'

// WhatsApp profile photo
export const UPLOAD_WHATSAPP_BUSINESS_AVATAR = 'UPLOAD_WHATSAPP_BUSINESS_AVATAR'
export const UPLOAD_WHATSAPP_BUSINESS_AVATAR_RESPONSE = 'UPLOAD_WHATSAPP_BUSINESS_AVATAR_RESPONSE'

export const SET_SKIP_FLOW_TEMPLATES = 'SET_SKIP_FLOW_TEMPLATES'
export const SET_SKIP_FLOW_TEMPLATES_REQUEST = 'SET_SKIP_FLOW_TEMPLATES_REQUEST'

// System keywords
export const SYSTEM_KEYWORDS_SUBSCRIBE = 'SYSTEM_KEYWORDS_SUBSCRIBE'
export const SYSTEM_KEYWORDS_SUBSCRIBE_RESPONSE = 'SYSTEM_KEYWORDS_SUBSCRIBE_RESPONSE'
export const SYSTEM_KEYWORDS_UNSUBSCRIBE = 'SYSTEM_KEYWORDS_UNSUBSCRIBE'
export const SYSTEM_KEYWORDS_UNSUBSCRIBE_RESPONSE = 'SYSTEM_KEYWORDS_UNSUBSCRIBE_RESPONSE'

export const SET_AUTOMATION_PAUSE_TIME = 'SET_AUTOMATION_PAUSE_TIME'

export const SET_DISPLAY_PREFERENCE = 'SET_DISPLAY_PREFERENCE'
export const SET_DISPLAY_PREFERENCES = 'SET_DISPLAY_PREFERENCES'
export const SET_INITIAL_SETTINGS = 'SET_INITIAL_SETTINGS'

export const TRANSFER_OWNERSHIP = 'TRANSFER_OWNERSHIP'

export const SET_CONVERSION_API_BY_CHANNEL = 'SET_CONVERSION_API_BY_CHANNEL'

import React, { useMemo } from 'react'
import cx from 'classnames'

import CheckboxGroupContext from '../CheckboxGroup/CheckboxGroup-Context'
import { FormChoiceField } from '../FormField/FormChoiceField'

import { CheckedIcon } from './icons/CheckedIcon'
import { IndeterminateIcon } from './icons/IndeterminateIcon'
import { CheckboxV2Props } from './interfaces'

import cm from './CheckboxV2.module.css'
/**Second version of the Checkbox component
 *
 *[Figma](https://www.figma.com/file/Xf38PdCKeISWnBoh0pLFIIWf/%F0%9F%A7%B1-MC-Desktop-Components?type=design&node-id=18575-48035&mode=design&t=SHla2EuBPM68Q2Eo-0)
 */
export const CheckboxV2 = ({
  labelId,
  checked = false,
  disabled = false,
  infoText,
  errorText,
  className,
  fullWidth,
  orientation = 'right',
  invalid = false,
  indeterminate = false,
  id,
  lozenge,
  helpText,
  value,
  onChange,
  ...props
}: CheckboxV2Props) => {
  const {
    values: selectedGroupValues,
    handleChange: handleOnGroupValueChange,
    disabled: isGroupDisabled,
  } = React.useContext(CheckboxGroupContext)

  const isChecked = useMemo(() => {
    // if group context is present, the checkbox value is managed by checkbox group
    if (selectedGroupValues !== undefined) {
      if (value) {
        return selectedGroupValues.includes(String(value))
      }
    }
    return checked
  }, [checked, selectedGroupValues, value])

  const isDisabled = disabled || isGroupDisabled

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    value && handleOnGroupValueChange?.(String(value), event)
    onChange?.(event)
  }

  return (
    <FormChoiceField
      id={id}
      value={value}
      type="checkbox"
      checked={isChecked}
      form={props.form}
      onChange={isDisabled ? undefined : handleChange}
      invalid={invalid}
      disabled={isDisabled}
      labelId={labelId}
      lozenge={lozenge}
      infoText={infoText}
      infoTextWidth={props.infoTextWidth}
      errorText={errorText}
      helpText={helpText}
      tabIndex={props.tabIndex}
      data-indeterminate={indeterminate}
      className={className}
      fieldClasses={{
        choice: cx(cm.checkboxV2__input, {
          [cm.inputOrientationLeft]: orientation === 'left',
        }),
        label: cx(cm.checkboxV2__label, 'text-body'),
        containerWithLabel: cx({
          [cm.labelRight]: orientation === 'right',
          [cm.labelLeft]: orientation === 'left',
        }),
      }}
      fullWidth={fullWidth}
      {...props}
    >
      <div
        className={cx(
          cm.checkboxV2__iconContainer,
          (isChecked || indeterminate) && cm.checked,
          isDisabled && cm.disabled,
        )}
      >
        {isChecked && !indeterminate && <CheckedIcon className={cx(cm.checkboxV2__icon)} />}
        {indeterminate && <IndeterminateIcon className={cx(cm.checkboxV2__icon)} />}
      </div>
    </FormChoiceField>
  )
}
CheckboxV2.displayName = 'CheckboxV2'

import { palettes, tints } from '@manychat/manyui'

import BaseBlockConfig from 'common/builder/blocks/BaseBlockConfig'
import { EmailStyleAlignmentPreset } from 'common/builder/blocks/blockConstants'
import { IBlockBase, IEmailButtonBlock } from 'common/builder/blocks/blockInterfaces'
import { BlockType } from 'common/builder/constants/BlockType'
import { EmailStyleType } from 'common/builder/nodes/emailNew/emailNodeConstants'

class EmailButtonBlockConfig extends BaseBlockConfig {
  blockType = BlockType.EMAIL_BUTTON

  getDefaults = (): Omit<IEmailButtonBlock, keyof IBlockBase> => ({
    text: 'Button',
    settings: {
      background_color: { type: EmailStyleType.CUSTOM, value: palettes.semantic.primary },
      text_color: { type: EmailStyleType.CUSTOM, value: tints.neutral[7] },
      border_radius: { type: EmailStyleType.CUSTOM, value: 6 },
      alignment: { type: EmailStyleType.PRESET, value: EmailStyleAlignmentPreset.CENTER },
      padding_top: { type: EmailStyleType.CUSTOM, value: 12 },
      padding_bottom: { type: EmailStyleType.CUSTOM, value: 12 },
      block_background_color: { type: EmailStyleType.CUSTOM, value: '' },
    },
  })
}

export default new EmailButtonBlockConfig()

import React from 'react'
import cx from 'classnames'

import { LozengeProps } from './interfaces'

import cm from './Lozenge.module.css'

export const LozengeLabelByType: Record<LozengeProps['type'], string> = {
  pro: 'PRO',
  beta: 'BETA',
  free: 'FREE',
  ai: 'AI',
  live: 'LIVE',
  stopped: 'STOPPED',
  popular: 'POPULAR',
  new: 'NEW',
}

/**A Lozenge component displays status information
 *
 * [Figma](https://www.figma.com/file/Xf38PdCKeISWnBoh0pLFIIWf/🧱-MC-Desktop-Components?node-id=18786-51090&mode=dev) */

export const Lozenge = ({ type, size = 'medium', id, className, ariaLabel }: LozengeProps) => {
  const lozengeClass = cx(
    cm.lozenge,
    {
      [cm.medium]: size === 'medium',
      [cm.large]: size === 'large',
      [cm.small]: size === 'small',
      [cm.free]: type === 'free',
      [cm.pro]: type === 'pro',
      [cm.beta]: type === 'beta',
      [cm.ai]: type === 'ai',
      [cm.live]: type === 'live',
      [cm.stopped]: type === 'stopped',
      [cm.popular]: type === 'popular',
      [cm.new]: type === 'new',
    },
    className,
  )

  return (
    <span
      className={lozengeClass}
      id={id}
      role="status"
      aria-label={ariaLabel || LozengeLabelByType[type]}
    >
      {LozengeLabelByType[type]}
    </span>
  )
}

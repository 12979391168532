import React, { Component } from 'react'
import cx from 'classnames'

import cm from './Label.module.css'

interface LabelComponentProps {
  className?: string
  style?: React.CSSProperties
  children: React.ReactNode
  labelDeleteClassName?: string
  /**
   * Shows delete button if onDelete callback is passed
   */
  onDelete?(): void
  /**
   * Delete button title
   */
  title?: string

  /**
   * Styles
   */

  danger?: boolean
  success?: boolean
  warning?: boolean
  primary?: boolean
  accent?: boolean
  noBg?: boolean

  /**
   * Sizes
   */

  lg?: boolean
  sm?: boolean
  xs?: boolean
  up?: boolean
}

export default class LabelComponent extends Component<LabelComponentProps> {
  render() {
    const {
      className,
      style,
      children,
      labelDeleteClassName,
      danger,
      success,
      warning,
      primary,
      accent,
      noBg,
      lg,
      sm,
      xs,
      up,
      title,
      onDelete,
      ...other
    } = this.props

    const cn = cx(cm.label, className, {
      [cm.noBg]: noBg,
      [cm.lg]: lg,
      [cm.sm]: sm,
      [cm.xs]: xs,
      [cm.up]: up,
      [cm.danger]: danger,
      [cm.success]: success,
      [cm.warning]: warning,
      [cm.primary]: primary,
      [cm.accent]: accent,
    })

    return (
      <span {...other} className={cn} style={style}>
        {children}

        {Boolean(onDelete) && (
          <span
            data-title={title || 'Delete'}
            className={cx(cm.labelDeleteButton, labelDeleteClassName)}
            onClick={onDelete}
          >
            &times;
          </span>
        )}
      </span>
    )
  }
}

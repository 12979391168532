import find from 'lodash/find'
import uniqBy from 'lodash/uniqBy'
import without from 'lodash/without'

import { IBlock } from 'common/builder/blocks/blockInterfaces'
import { NodeType } from 'common/builder/constants/NodeType'
import { INode } from 'common/builder/nodes/nodeInterfaces'
import * as entitySelectors from 'common/builder/selectors/builder/entitySelectors'

/**
 * Return all target nodes which are in the pre interaction zone i.e before user interaction
 * @param state
 * @param builderId
 * @param nodeId
 * @param options
 * @param options.ignoreTargetsAfterConditionNode - enables special mode when targets after Condition node are ignored
 * @param options.ignoreTargetsAfterGotoNode - enables special mode when targets after Goto node are ignored
 * @param options.shouldIgnoreBlock - function that allow to ignore block target processing
 * @param visitedNodes
 */
export function getAllPreInteractionZoneTargets(
  state: RootState,
  builderId: string,
  nodeId: string,
  options: Partial<{
    ignoreTargetsAfterConditionNode: boolean
    ignoreTargetsAfterGotoNode: boolean
    isHasCgtTrigger: boolean
    shouldIgnoreBlock: (block: IBlock) => boolean
  }> = {},
  visitedNodes: INode[] = [],
): INode[] {
  if (find(visitedNodes, { id: nodeId })) {
    return []
  }

  let allPreInteractionZoneTargets: INode[] = []

  const nodePreInteractionZoneTargets = getPreInteractionZoneTargets(
    state,
    builderId,
    nodeId,
    options,
  )
  allPreInteractionZoneTargets = [...allPreInteractionZoneTargets, ...nodePreInteractionZoneTargets]

  const node = entitySelectors.getNodeById(state, builderId, nodeId)
  if (node) visitedNodes.push(node)

  if (node?.nodeType === NodeType.CONTENT && options.isHasCgtTrigger) {
    return uniqBy([...allPreInteractionZoneTargets, ...nodePreInteractionZoneTargets], 'id')
  }

  nodePreInteractionZoneTargets.forEach((node) => {
    const nodePreInteractionZoneTargets = getAllPreInteractionZoneTargets(
      state,
      builderId,
      node.id,
      options,
      visitedNodes,
    )
    allPreInteractionZoneTargets = [
      ...allPreInteractionZoneTargets,
      ...nodePreInteractionZoneTargets,
    ]
  })

  return uniqBy([...allPreInteractionZoneTargets, ...nodePreInteractionZoneTargets], 'id')
}

export function getPreInteractionZoneTargets(
  state: RootState,
  builderId: string,
  nodeId: string,
  options: Partial<{
    ignoreTargetsAfterConditionNode: boolean
    ignoreTargetsAfterGotoNode: boolean
    isHasCgtTrigger: boolean
    shouldIgnoreBlock: (block: IBlock) => boolean
  }> = {},
) {
  const node = entitySelectors.getNodeById(state, builderId, nodeId)
  const preInteractionZoneTargets: INode[] = []

  let nodeTypesWithPreInteractionZoneTargets = [
    NodeType.CONTENT,
    NodeType.SMS,
    NodeType.EMAIL_NEW,
    NodeType.ACTION_GROUP,
    NodeType.SMART_DELAY,
    NodeType.GOTO,
    NodeType.TELEGRAM,
    NodeType.WHATS_APP,
    NodeType.INSTAGRAM,
    NodeType.AI_AGENT,
  ]

  if (options.ignoreTargetsAfterGotoNode) {
    nodeTypesWithPreInteractionZoneTargets = without(
      nodeTypesWithPreInteractionZoneTargets,
      NodeType.GOTO,
    )
  }

  if (!node) return []

  if (nodeTypesWithPreInteractionZoneTargets.includes(node.nodeType) && 'targetId' in node) {
    const targetId = node.targetId ?? ''
    const chainingNode = entitySelectors.getNodeById(state, builderId, targetId)
    if (chainingNode && !(options.isHasCgtTrigger && node.nodeType === NodeType.CONTENT)) {
      preInteractionZoneTargets.push(chainingNode)
    }
  }

  if ([NodeType.SPLIT, NodeType.CONDITION].includes(node.nodeType)) {
    let blocks: IBlock[] = []
    if (node.nodeType === NodeType.CONDITION && !options.ignoreTargetsAfterConditionNode) {
      blocks = entitySelectors.getBlocks(state, builderId, nodeId, { includeNested: true })
    }
    if (node.nodeType === NodeType.SPLIT) {
      blocks = entitySelectors.getBlocks(state, builderId, nodeId, { includeNested: true })
    }
    blocks.map((block) => {
      if (options.shouldIgnoreBlock && options.shouldIgnoreBlock(block)) {
        return
      }
      if (!('targetId' in block)) {
        return
      }
      const targetId = block.targetId ?? ''
      const blockTargetNode = entitySelectors.getNodeById(state, builderId, targetId)
      if (blockTargetNode) {
        preInteractionZoneTargets.push(blockTargetNode)
      }
    })
  }

  return preInteractionZoneTargets
}

import { UPDATE_BUILDER_STATE } from 'common/builder/constants/builderReduxActionTypes'

/**
 * @param builderId
 * @param changes
 */
export const updateBuilderState = (builderId, changes) => {
  return {
    type: UPDATE_BUILDER_STATE,
    builderId,
    changes,
  }
}

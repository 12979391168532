import React from 'react'
import { useAppDispatch, useAppSelector } from 'reduxTyped'
import { l } from '@manychat/manyui'

import WaCoexistenceContactImportModal from 'apps/whatsApp/components/WaCoexistenceContactImportModal/WaCoexistenceContactImportModal'
import { getWhatsAppCoexistenceContactImportDeadline } from 'common/builder/selectors/builder/whatsAppSelectors'
import { openWaCoexistenceContactImportModal } from 'common/core/actions/uiActions'
import { analyticsService } from 'utils/services/analytics'
import { AccountFlag } from 'utils/services/featureFlagsService/constants'
import { useAccountReleaseToggle } from 'utils/services/featureFlagsService/releaseTogglesHooks'

import cm from '../StaticNotifications.module.css'

export const WaCoexistenceContactImportAvailableNotification = () => {
  const dispatch = useAppDispatch()

  const { hoursLeft, deadlineWithTimezone } = useAppSelector(
    getWhatsAppCoexistenceContactImportDeadline,
  )
  const isContactImportEnabled = useAccountReleaseToggle(
    AccountFlag.ENABLE_WA_COEXISTENCE_CONTACTS_IMPORT,
  )

  const handleShowMore = () => {
    dispatch(openWaCoexistenceContactImportModal())
    analyticsService.sendEvent(
      'NOTIFICATION_BAR.WA_COEXISTENCE_CONTACTS_NEED_TO_BE_IMPORTED.SHOW_MORE.CLICK',
    )
  }

  if (!isContactImportEnabled) return null

  return (
    <div>
      <span>
        {l.translate(
          'You have {hoursLeft} hours until {deadline} to import contacts from your WhatsApp Business App to continue engaging with them on Manychat.',
          { hoursLeft, deadline: deadlineWithTimezone },
        )}
      </span>{' '}
      <span className={cm.readMore} onClick={handleShowMore}>
        {l.translate('Show more')}
      </span>
      <WaCoexistenceContactImportModal />
    </div>
  )
}

import * as atypes from 'apps/agency/constants/AgencyReduxActionTypes'
import { parseAgency, parseAgencyConfig } from 'apps/agency/models/Agency/adapter'
import { AGENCY } from 'apps/agency/models/Agency/defaults'

import { AGENCY_FIELDS } from '../models/Agency/constants'

const initialState = { ...AGENCY, config: null }

export default function signonReducer(state = initialState, action) {
  switch (action.type) {
    case atypes.AGENCY_SIGNON_FETCH_RESPONSE: {
      const agency = action.data.agency
      return {
        ...state,
        ...(agency ? parseAgency(agency, action.user) || AGENCY : AGENCY),
        [AGENCY_FIELDS.config]: parseAgencyConfig(action.data.config),
      }
    }

    case atypes.AGENCY_SIGNON_UPDATE_RESPONSE: {
      return {
        ...state,
        ...parseAgency(action.data.agency, action.user),
      }
    }

    case atypes.AGENCY_SIGNON_CREATE_RESPONSE: {
      return {
        ...state,
        ...parseAgency(action.data.agency, action.user),
        [AGENCY_FIELDS.config]: parseAgencyConfig(action.data.config),
      }
    }

    case atypes.AGENCY_SIGNON_FIELDS_UPDATE: {
      return { ...state, ...action.data }
    }

    default: {
      break
    }
  }

  return state
}

import dot from 'dot-prop-immutable'

import * as atypes from 'common/builder/constants/builderReduxActionTypes'

export interface ISnapshot {
  state: UnsafeAnyObject
  nodes: UnsafeAnyObject
  blocks: UnsafeAnyObject
  buttons: UnsafeAnyObject
  urls: UnsafeAnyObject
}

interface IActon {
  type: string
  snapshot: ISnapshot
  snapshotIndex: number
}

export const DEFAULT_STATE = {
  snapshotList: [],
  snapshotIndex: 0,
  isSnapshotInProgress: false,
}

export default (state = DEFAULT_STATE, action: IActon) => {
  switch (action.type) {
    case atypes.ADD_BUILDER_SNAPSHOT: {
      const { snapshot } = action
      return dot.set(state, `snapshotList`, [snapshot, ...state.snapshotList])
    }
    case atypes.START_INSTALL_BUILDER_SNAPSHOT: {
      return dot.set(state, `isSnapshotInProgress`, true)
    }
    case atypes.STOP_INSTALL_BUILDER_SNAPSHOT: {
      return dot.set(state, `isSnapshotInProgress`, false)
    }
    case atypes.INSTALL_BUILDER_SNAPSHOT: {
      const { snapshotIndex } = action
      return dot.set(state, `snapshotIndex`, snapshotIndex)
    }
    case atypes.REMOVE_LIMITED_BUILDER_SNAPSHOT: {
      const snapshotList = state.snapshotList
      return dot.set(state, `snapshotList`, snapshotList.slice(0, snapshotList.length - 1))
    }
    case atypes.REMOVE_ALL_BUILDER_SNAPSHOTS: {
      return DEFAULT_STATE
    }
    case atypes.REMOVE_NEWEST_BUILDER_SNAPSHOTS: {
      const { snapshotList, snapshotIndex } = state
      const stateWithNewList = dot.set(
        state,
        `snapshotList`,
        snapshotList.slice(snapshotIndex + 1, snapshotList.length),
      )
      return dot.set(stateWithNewList, `snapshotIndex`, 0)
    }
    case atypes.REMOVE_LAST_GENERATED_BUILDER_SNAPSHOT: {
      const snapshotList = state.snapshotList
      return dot.set(state, `snapshotList`, snapshotList.slice(1, snapshotList.length))
    }
    default:
      return state
  }
}

import { makeCurrentItemReducer } from './../currentItem'

export const makeDetailsReducer = (entityName, options = {}) => {
  const prefix = entityName.toUpperCase()
  const itemReducer = makeCurrentItemReducer(entityName, options)
  const initialState = { byId: {} }

  return function detailsReducer(state = initialState, action) {
    if (action.id && action.type.indexOf(`${prefix}_CURRENT`) === 0) {
      const { id } = action
      const itemData = itemReducer(state.byId[id], action)
      if (state.byId[id] !== itemData) {
        state = { ...state, byId: { ...state.byId, [id]: itemData } }
      }
    }
    return state
  }
}

import React, { ElementType } from 'react'
import cx from 'classnames'
import { Dropdown, l } from '@manychat/manyui'

import { isMobile as isMobileDevice } from 'utils'
import {
  newNav,
  isPathActive,
  NavigationRoute,
  NavigationRouteHideParams,
} from 'common/core/router'
import { analyticsService } from 'utils/services/analytics'

import { BarLink, BarLinkProps } from './BarLink/BarLink'
import { HelpBarLink, HelpBarLinkProps } from './HelpBarLink/HelpBarLink'
import { MyProfileLink, MyProfileLinkProps } from './MyProfileLink/MyProfileLink'

import styles from './Nav.module.css'

interface NavProps extends NavigationRouteHideParams {
  isMobileWidth: boolean
  isSidebarFolded: boolean
  isSidebarHidden: boolean
  isRightTimeAnimate: boolean
  pageId: string
  hasNotifications: boolean
  hasPolicyEnforcementNotifications: boolean
  hasUnreadMessages?: boolean
  hasCompletedRNOnBoarding: boolean
  openedThreads: number
  hideSidebar: () => void
}

const PopoverProps = {
  arrow: true,
  at: 'right',
}

const bottomNav = [
  {
    label: l.makeTranslate('My Profile'),
    icon: 'smile',
    Component: MyProfileLink,
    testId: 'profile-popover',
  },
  { label: l.makeTranslate('Help'), icon: 'help', Component: HelpBarLink, testId: 'help-popover' },
]

export function Nav(props: NavProps) {
  const {
    hasNotifications,
    hasPolicyEnforcementNotifications,
    hasUnreadMessages,
    hasCompletedRNOnBoarding,
    pageId,
    isSidebarFolded,
    isSidebarHidden,
    isRightTimeAnimate,
    isMobileWidth,
    hideSidebar,
  } = props

  const handleClick = (data: BarLinkProps) => {
    if (data.label === 'Home') {
      analyticsService.sendEvent(`SIDEBAR.HOME_TAB.CLICKED`)
    }

    if ((isMobileDevice && !data.navGroup) || isMobileWidth) {
      hideSidebar()
    }
  }

  const renderNav = (
    items: NavigationRoute[] | typeof bottomNav,
    baseUrl = '',
    parent?: NavigationRoute,
    isNavGroupItem?: boolean,
  ) => {
    return items.map((item, i) => {
      if ('hide' in item && item.hide && item.hide(props)) {
        return null
      }
      const showNotifications =
        'showNotifications' in item &&
        item.showNotifications &&
        (hasNotifications || hasPolicyEnforcementNotifications)

      let url = baseUrl + ('url' in item ? item.url : '')
      if (showNotifications) {
        if (hasPolicyEnforcementNotifications) {
          url = baseUrl + '/settings#fb_policy_enforcement_logs'
        }
        if (hasNotifications) {
          url = baseUrl + '/settings#logs'
        }
      }

      let active = false
      if ('isActive' in item && typeof item.isActive === 'function') {
        active = item.isActive()
      } else if (!url && 'items' in item && Array.isArray(item.items)) {
        for (const child of item.items) {
          if (isPathActive(url + child.url)) {
            active = true
          }
        }
      } else {
        active = isPathActive(url)
      }
      const cn =
        !isSidebarHidden &&
        'navGroup' in item &&
        item.navGroup &&
        parent == null &&
        active &&
        cx(styles.activeRootNode, 'shadow-y')
      const badgeValue = 'badge' in item && item.badge ? item.badge({ ...props }) : null
      const iconValue = typeof item.icon === 'function' ? item.icon({ ...props }) : item.icon
      // @ts-expect-error don't know how to fix
      const labelValue = typeof item.label === 'function' ? item.label(props) : item.label

      const componentProps: BarLinkProps & HelpBarLinkProps & MyProfileLinkProps = {
        ...item,
        label: labelValue,
        icon: iconValue,
        url: url,
        active: active,
        badge: badgeValue || undefined,
        pageId: pageId,
        hasUnreadMessages: hasUnreadMessages,
        hasCompletedRNOnBoarding: hasCompletedRNOnBoarding,
        showNotifications: showNotifications,
        isSidebarFolded: isSidebarFolded,
        isSidebarHidden: isSidebarHidden,
        isRightTimeAnimate: isRightTimeAnimate,
        isNavGroupItem: isNavGroupItem,
        onClick: handleClick,
      }

      const Component: ElementType =
        'Component' in item && item.Component ? item.Component : BarLink

      const component = <Component {...componentProps} />

      let content = (
        <>
          {component}
          {'navGroup' in item && item.navGroup && item.items && (
            <ul>{renderNav(item.items, baseUrl + item.url, item)}</ul>
          )}
        </>
      )

      if (isSidebarHidden && !isNavGroupItem) {
        content = (
          <Dropdown
            label="Dropdown"
            openOnHover
            closeTimeout={30}
            popoverProps={PopoverProps}
            renderAnchor={() => <div>{component}</div>}
          >
            <div className="p-a-xs font-semibold">{labelValue}</div>
          </Dropdown>
        )
      }

      if ('navGroup' in item && item.navGroup && item.items && isSidebarHidden) {
        content = (
          <Dropdown
            label="Dropdown"
            openOnHover
            closeTimeout={30}
            popoverProps={PopoverProps}
            renderAnchor={() => <div>{component}</div>}
          >
            <div className="p-r-md p-t-md">
              <div className={cx('p-l-md  font-semibold', { ['text-success']: active })}>
                {labelValue}
              </div>
              <ul className="p-l-md">{renderNav(item.items, baseUrl + item.url, item, true)}</ul>
            </div>
          </Dropdown>
        )
      }

      return (
        <li
          key={('url' in item ? item.url : '') + '-' + i}
          className={cx(cn)}
          data-test-id={item.testId}
          data-onboarding-id={'onboardingId' in item ? item.onboardingId : undefined}
        >
          {content}
        </li>
      )
    })
  }

  return (
    <nav className={cx('p-b-xs p-t', styles.wrapper)}>
      <ul className={styles.main}>{renderNav(newNav)}</ul>

      <ul>{renderNav(bottomNav)}</ul>
    </nav>
  )
}

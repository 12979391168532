import { z } from 'zod'

import { FSObjectType } from 'common/cms/models/FSObject/constants'
import { lightFlowSchema } from 'shared/api/common/schemas/lightFlow'
import {
  ruleTriggerSchema,
  externalTriggerSchema,
  CmsIntentSchema,
} from 'shared/api/requests/cms/schemas/fsCommonSchema'
import { defaultReplySchema } from 'shared/api/requests/defaultReply/schemas'
import { EasyBuilderFlowStatus } from 'shared/api/requests/easyBuilder/schemas'
import { storyMentionReplySchema } from 'shared/api/requests/storyMentionReply/schemas'

import { cartReplySchema } from '../../cartReply/schemas'
import { KeywordSchema } from '../../keywords/schemas'

import { WidgetsSchema } from './widgets'

export const fsFlowObjectBaseSchema = z.object({
  easy_builder: z
    .object({
      status: z.nativeEnum(EasyBuilderFlowStatus),
    })
    .nullish(),
  type: z.literal(FSObjectType.FLOW),
  path: z.string(),
  title: z.string(),
  preview_url: z.string().nullable(),
  preview_actual: z.boolean(),
  folder_id: z.number(),
  created: z.number(),
  modified: z.number().or(z.string()),
  deleted: z.number().nullable(),
  flow: lightFlowSchema,
  template_installation_id: z.number().nullable(),
  template_installation_access_data: z
    .object({
      has_user_access: z.boolean().optional(),
      is_locked: z.boolean(),
      is_protected: z.boolean(),
      is_disabled: z.boolean(),
      is_owner: z.boolean(),
    })
    .optional(),
  is_editable: z.boolean(),
  is_moveable: z.boolean(),
  is_deletable: z.boolean(),
  is_restorable: z.boolean(),
  is_exportable: z.boolean(),
  is_shareable: z.boolean(),
  is_permanently_deletable: z.boolean(),
  is_readonly: z.boolean(),
  triggers: z.object({
    widgets: WidgetsSchema,
    keywords: z.array(KeywordSchema),
    trigger_rules: z.array(ruleTriggerSchema),
    external_triggers: z.array(externalTriggerSchema),
    another_flow: z.array(
      z.object({
        title: z.string(),
      }),
    ),
    default_replies: z.array(defaultReplySchema),
    cart_replies: z.array(cartReplySchema),
    story_mentions: z.array(storyMentionReplySchema),
    welcome_messages: z.array(z.unknown()),
    follow_ups: z.array(z.unknown()),
    conversation_starters: z.array(z.unknown()),
    intents: z.array(CmsIntentSchema),
  }),
})

import { useMemo } from 'react'
import { useAppSelector } from 'reduxTyped'

import { ChannelType } from 'common/core/constants/ChannelType'
import {
  getIsEmailNodeEnabled,
  hasConnectedInstagramChannel,
  hasConnectedSMSChannel,
  hasConnectedTelegramChannel,
  hasConnectedTiktokChannel,
  hasConnectedWhatsAppChannel,
  isFBChannelConnected,
} from 'common/core/selectors/channelSelectors'

export const useChannelsList = () => {
  const hasConnectedFB = useAppSelector(isFBChannelConnected)
  const hasConnectedSms = useAppSelector(hasConnectedSMSChannel)
  const hasConnectedEmail = useAppSelector(getIsEmailNodeEnabled)

  const hasConnectedInstagram = useAppSelector(hasConnectedInstagramChannel)
  const hasConnectedTelegram = useAppSelector(hasConnectedTelegramChannel)
  const hasConnectedWhatsApp = useAppSelector(hasConnectedWhatsAppChannel)
  const hasConnectedTikTok = useAppSelector(hasConnectedTiktokChannel)

  return useMemo(
    () => [
      { channel: ChannelType.INSTAGRAM, connected: hasConnectedInstagram },
      { channel: ChannelType.TIKTOK, connected: hasConnectedTikTok },
      { channel: ChannelType.WHATSAPP, connected: hasConnectedWhatsApp },
      { channel: ChannelType.FB, connected: hasConnectedFB },
      { channel: ChannelType.SMS, connected: hasConnectedSms },
      { channel: ChannelType.EMAIL, connected: hasConnectedEmail },
      { channel: ChannelType.TELEGRAM, connected: hasConnectedTelegram },
    ],
    [
      hasConnectedEmail,
      hasConnectedFB,
      hasConnectedInstagram,
      hasConnectedSms,
      hasConnectedTelegram,
      hasConnectedTikTok,
      hasConnectedWhatsApp,
    ],
  )
}

export const useConnectedChannelsList = () => {
  const channels = useChannelsList()

  return useMemo(() => channels.filter((item) => item.connected), [channels])
}

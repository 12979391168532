import { v4 as uuid } from 'uuid'
import { l } from '@manychat/manyui'

import {
  ActionTypes,
  IntegrationActionTypes,
  NotifyAdminsSendChannels,
} from 'common/actions/models/Action/constants'
import {
  UserFieldsDateFormula,
  UserFieldsDateTimeFormula,
} from 'common/fields/constants/UserFieldsTypes'
import { dateOffsetUnits } from 'common/filter/models/AudienceFilter/adapter'
import { OffsetOperationsTypes } from 'common/filter/models/AudienceFilter/constants'

export const createAction = ({ type, app_id, integrationActionType }) => {
  const res = {
    type,
    id: uuid(),
  }

  if (integrationActionType) {
    res.action = integrationActionType
  }

  switch (type) {
    case ActionTypes.NOTIFY_ADMINS:
      res.text = `{{full_name|fallback:"${l.translate('Contact')}"}} ${l.translate(
        'just did something you wanted to know about',
      )}.`
      res.send_to = []
      res.all_send_by = [NotifyAdminsSendChannels.EMAIL]
      res.options = { send_link_to_live_chat: true }

      break
    case ActionTypes.SET_SMS_OPTIN:
      res.optin = true
      break
    case ActionTypes.SET_EMAIL_OPTIN:
      res.optin = true
      break
    case ActionTypes.APPS_REQUEST:
      res.app_id = app_id
      break
  }
  return res
}

export function isIntegrationRequest(item) {
  return Boolean(IntegrationActionTypes[item])
}

export function isDateOnly(date) {
  const dateOnlyRegex = /^\d{4}-[01]\d-[0-3]\d$/
  return dateOnlyRegex.test(date)
}

export function isDateTime(dateTime) {
  const dateTimeRegex =
    /^\d{4}-[01]\d-[0-3]\dT[0-2]\d:[0-5]\d:[0-5]\d(\.\d{1,3})?(Z|[+-][012]\d:?[0-5]\d)?$/
  return dateTimeRegex.test(dateTime)
}

export function isDateFormula(formula = '') {
  const regex = /\bcreateDate\(/
  return regex.test(formula)
}

export function isDateTimeFormula(formula = '') {
  const regex = /\bcreateDateTime\(/
  return regex.test(formula)
}

export function isDateOfTheAction(formula = '') {
  return formula.includes(UserFieldsDateFormula.NOW)
}

export function isDateTimeOfTheAction(formula = '') {
  return formula.includes(UserFieldsDateTimeFormula.NOW)
}

export function parseOffsetFormula(formula = '') {
  const parsingResult = dateTimeFormulaValueMask.exec(formula)

  if (parsingResult === null) return []

  const [, , , , operation, operationValue, operationUnit] = parsingResult
  dateTimeFormulaValueMask.lastIndex = 0

  const operationValueIsValid = operationValue !== null && operationValue !== undefined
  if (
    operationValueIsValid &&
    [OffsetOperationsTypes.PLUS, OffsetOperationsTypes.MINUS].includes(operation) &&
    [dateOffsetUnits.days, dateOffsetUnits.hours, dateOffsetUnits.minutes].includes(operationUnit)
  ) {
    return [operation, operationValue, operationUnit]
  }

  return []
}

export function isFormulaContainsOffset(formula = '') {
  const parsedFormula = parseOffsetFormula(formula)

  return parsedFormula.length === 3
}

export const dateTimeFormulaValueMask =
  /(.*)\(({{.*}}|nowDate\(\)|nowDateTime\(\))(, "([-+])", (\d*), "(\w*)")?\)/

import debounce from 'lodash/debounce'
import { l } from '@manychat/manyui'

import { alert } from 'common/core'
import errorTrackingService from 'utils/services/errorTrackingService'

const SECOND = 1000
const RETRIES_DELAY_FACTOR = 2
const DEFAULT_RETRIES_COUNT = 3
const UI_ALERT_DELAY = 10 * SECOND
const HANDLE_ERRORS_DEBOUNCE_TIME = 3 * SECOND
const CHROME_ERROR_MESSAGE = 'Failed to fetch dynamically imported module: '
const DEFAULT_ALERT_MESSAGE = l.translate('Some resources failed to load, please refresh the page')

interface ErrorHandlerParams {
  isAfterRetries: boolean
  fingerprint?: string
  alertMessage?: string
  errorMessage?: string
}

const errorsList: unknown[] = []

const handleError = debounce(
  ({ isAfterRetries, fingerprint, alertMessage, errorMessage }: ErrorHandlerParams) => {
    const defaultFingerprint = isAfterRetries
      ? 'failed-to-fetch-dynamically-imported-module-after-retries'
      : 'failed-to-fetch-dynamically-imported-module-no-retries'
    const defaultErrorMessage = isAfterRetries
      ? 'Failed to fetch dynamically imported module after retries'
      : 'Failed to fetch dynamically imported module with no retries'

    alert(alertMessage || DEFAULT_ALERT_MESSAGE, 'info')

    errorTrackingService.trackWarning(errorMessage || defaultErrorMessage, {
      fingerprint: fingerprint || defaultFingerprint,
      extra: { errorsList },
    })

    errorsList.length = 0
  },
  HANDLE_ERRORS_DEBOUNCE_TIME,
)

/**
 * Safe method for dynamic imports
 * - prevents app from crashing if the import was unsuccessful
 * - performs retries if possible
 * - fallbacks to specified module
 */
export async function safeDynamicImport<ModuleType>(
  importer: () => Promise<ModuleType>,
  params: {
    fallback?: ModuleType
    fingerprint?: string
    alertMessage?: string
    retriesCount?: number
    errorMessage?: string
  },
): Promise<ModuleType> {
  const {
    fallback,
    fingerprint,
    alertMessage,
    errorMessage,
    retriesCount = DEFAULT_RETRIES_COUNT,
  } = params

  try {
    return await importer()
  } catch (error: unknown) {
    const hasUrlInErrorMessage =
      error instanceof Error && error.message?.includes(CHROME_ERROR_MESSAGE)

    // this assumes that the exception will contain this specific text with the url of the module
    // out of all modern browsers, only chrome has URL in the thrown error
    // for all other browsers, fallback to empty component
    if (!hasUrlInErrorMessage) {
      errorsList.push(error)
      handleError({ isAfterRetries: false, fingerprint, alertMessage, errorMessage })

      if (fallback) return fallback
      throw error
    }

    for (let i = 1; i <= retriesCount; i++) {
      const timeout = SECOND * RETRIES_DELAY_FACTOR ** i

      await new Promise((resolve) => setTimeout(resolve, timeout))

      try {
        const moduleUrl = new URL(error.message.replace(CHROME_ERROR_MESSAGE, '').trim())
        // add timestamp for cache busting, otherwise we will get failed import for retries
        moduleUrl.searchParams.set('timestamp', String(Date.now()))
        // TODO TC-1368 Fix safe dynamic import wrapper issue
        return await import(/* @vite-ignore */ moduleUrl.href)
      } catch {
        // do nothing
      }
    }

    errorsList.push(error)
    handleError({ isAfterRetries: true, fingerprint, alertMessage, errorMessage })

    if (fallback) return fallback
    throw error
  }
}

import React from 'react'
import cx from 'classnames'
import { v4 as uuid } from 'uuid'

import cleanProps from '../../../lib/clean-react-props'
import { deprecatedDecorator } from '../../../utils/deprecated-decorator'
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import CheckboxV2 from '../CheckboxV2'

import { CheckboxBackgroundIcon } from './CheckboxBackgroundIcon'
import { CheckboxMarkIcon } from './CheckboxMark'
import { CheckboxUncheckedIcon } from './CheckboxUncheckedIcon'
import { CheckboxProps } from './interfaces'

import cm from './Checkbox.module.css'

/**
 * Checkboxes are used when there is a list of options and the user may select multiple options, including all or none.
 * @deprecated use {@link CheckboxV2} component
 */
const Checkbox = (props: CheckboxProps) => {
  const id = props.id || `${uuid()}-checkbox`
  const checkedDefaultValue = props.value !== undefined && Boolean(props.value)

  const {
    checked = checkedDefaultValue,
    disabled,
    invalid,
    white,
    style,
    label,
    labelId,
    className,
    ...other
  } = props

  return (
    <label
      style={style}
      id={labelId}
      className={cx(
        cm.wrapper,
        className,
        !label && cm.withoutLabel,
        white && cm.white,
        disabled && cm.disabled,
        invalid && cm.invalid,
        'text-body',
      )}
      htmlFor={id}
    >
      <input
        {...cleanProps(other, [], [])}
        type="checkbox"
        checked={checked}
        disabled={disabled}
        id={id}
      />
      <CheckboxMarkIcon className={cx(cm.icon, cm.iconActive)} />
      <CheckboxBackgroundIcon className={cx(cm.icon, cm.iconBg)} />
      <CheckboxUncheckedIcon className={cx(cm.icon, cm.iconBorder)} />
      {label}
    </label>
  )
}

/** @deprecated use {@link CheckboxV2} component */
export default deprecatedDecorator(Checkbox, {
  replacementComponentName: 'CheckboxV2',
})

import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import * as targetPageCustomEventsActions from 'common/mapping/actions/targetPageCustomEventsActions'
import * as targetPageGlobalFieldsActions from 'common/mapping/actions/targetPageGlobalFieldsActions'
import * as targetPageOtnsActions from 'common/mapping/actions/targetPageOtnsActions'
import * as targetPageTagsActions from 'common/mapping/actions/targetPageTagsActions'
import * as targetPageUserFieldsActions from 'common/mapping/actions/targetPageUserFieldsActions'

const WithTargetPageEntities = (Component) => {
  class WithTargetPageEntitiesController extends React.Component {
    componentDidMount() {
      this.props.targetPageCustomEventsActions.fetchTargetPageCustomEvents({
        pageId: this.props.pageId,
      })
      this.props.targetPageTagsActions.fetchTargetPageTags({ pageId: this.props.pageId })
      this.props.targetPageOtnsActions.fetchTargetPageOtns({ pageId: this.props.pageId })
      this.props.targetPageUserFieldsActions.fetchTargetUserFields(this.props.pageId)
      this.props.targetPageGlobalFieldsActions.fetchTargetGlobalFields(this.props.pageId)
    }

    componentDidUpdate(prevProps) {
      if (prevProps.pageId && prevProps.pageId !== this.props.pageId) {
        this.props.targetPageCustomEventsActions.dropTargetPageCustomEvents()
        this.props.targetPageCustomEventsActions.fetchTargetPageCustomEvents({
          pageId: this.props.pageId,
        })
        this.props.targetPageOtnsActions.dropTargetPageOtns()
        this.props.targetPageOtnsActions.fetchTargetPageOtns({ pageId: this.props.pageId })
        this.props.targetPageTagsActions.dropTargetPageTags()
        this.props.targetPageTagsActions.fetchTargetPageTags({ pageId: this.props.pageId })
        this.props.targetPageUserFieldsActions.dropTargetUserFields()
        this.props.targetPageUserFieldsActions.fetchTargetUserFields(this.props.pageId)
        this.props.targetPageGlobalFieldsActions.dropTargetGlobalFields()
        this.props.targetPageGlobalFieldsActions.fetchTargetGlobalFields(this.props.pageId)
      }
    }

    render() {
      return <Component {...this.props} />
    }
  }

  return connect(
    (state) => ({
      targetPageTags: state.targetPage.tags.items || [],
      targetPageCufs: state.targetPage.userFields.items || [],
      targetPageGafs: state.targetPage.globalFields.items || [],
      targetPageOtns: state.targetPage.otns.items || [],
      targetPageCustomEvents: state.targetPage.customEvents.items || [],
      fetching: state.targetPage.tags.fetching,
    }),
    (dispatch) => ({
      targetPageCustomEventsActions: bindActionCreators(targetPageCustomEventsActions, dispatch),
      targetPageTagsActions: bindActionCreators(targetPageTagsActions, dispatch),
      targetPageOtnsActions: bindActionCreators(targetPageOtnsActions, dispatch),
      targetPageUserFieldsActions: bindActionCreators(targetPageUserFieldsActions, dispatch),
      targetPageGlobalFieldsActions: bindActionCreators(targetPageGlobalFieldsActions, dispatch),
    }),
  )(WithTargetPageEntitiesController)
}

export default WithTargetPageEntities

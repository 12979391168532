import React, { forwardRef } from 'react'
import { Loader, l } from '@manychat/manyui'

import { FieldGroupId, FieldType } from 'common/fields/entities/enums'
import FieldPicker, { FieldPickerProps } from 'common/fields/linking/components/FieldPicker'
import { useTagPicker } from 'common/tags/components/TagPicker/useTagPicker'
import { Tag } from 'common/tags/type'

type TagPickerProps = {
  onChange: (event: { target: { value: number; name: string } }) => void
  disabled?: boolean
  value?: string
  excluded?: Array<Partial<Tag>>
} & Omit<FieldPickerProps, 'fields' | 'onSelect' | 'value' | 'disabled' | 'ariaLabel'>

type TagPickerComponentFunction = React.ForwardRefRenderFunction<HTMLInputElement, TagPickerProps>

const TagPicker: TagPickerComponentFunction = (
  { onChange, value, excluded, ...pickerProps },
  ref,
) => {
  const { isFetching, tagFields, handleChange, selectedValue } = useTagPicker({
    onChange,
    value,
    excluded,
  })

  if (isFetching) {
    return <Loader size={32} />
  }

  return (
    <FieldPicker
      {...pickerProps}
      ref={ref}
      fields={tagFields}
      onSelect={handleChange}
      allowedTypes={[FieldType.TAG]}
      allowNew={FieldGroupId.TAG}
      value={selectedValue}
      showIcon={false}
      ariaLabel={l.translate('Tag picker')}
    />
  )
}

export const TagsPicker = forwardRef(TagPicker)

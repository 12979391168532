import { analyticsService } from 'utils/services/analytics'
import hotjarService from 'utils/services/hotjar/index'
import { NewOnboardingService } from 'utils/services/newOnboardingService/NewOnboardingService'

export enum AnalyticsLeaveModalFrom {
  POINTER = 'pointer',
  BTN_PROGRESS_PANEL = 'btn_progress_panel',
}

export enum AnalyticsInstagramCampaignsFrom {
  HOME_TAB = 'home_tab',
  IG_CAMPAIGNS_SHOWCASE_MODAL = 'ig_campaigns_showcase_modal',
}

let stepPassData: { onboardingId: string; stepId: string; stepStartTime: number }
const getStepPassingTime = (onboardingId: string, stepId: string) => {
  if (stepPassData?.onboardingId !== onboardingId || stepPassData?.stepId !== stepId) {
    return -1
  }

  const timeNow = Date.now()
  return (timeNow - stepPassData.stepStartTime) / 1000
}

export const trackNewOnboardingAnalytics = (newOnboardingService: NewOnboardingService) => {
  newOnboardingService.onLifeCycle({
    onRun: (onboarding) => {
      analyticsService.sendEvent('MC_ONBOARDING.RUN', { onboardingId: onboarding.id })

      hotjarService.hotjarTrigger('onboarding_run', ['mc_onboarding', onboarding.id])

      stepPassData = { onboardingId: '', stepId: '', stepStartTime: 0 }
    },
    onComplete: (onboarding) => {
      analyticsService.sendEvent('MC_ONBOARDING.COMPLETE', { onboardingId: onboarding.id })
    },
    onSkip: (onboarding) => {
      analyticsService.sendEvent('MC_ONBOARDING.SKIP', { onboardingId: onboarding.id })
    },
    onStepChange: (onboarding, stepId) => {
      if (stepPassData.onboardingId && stepPassData.stepId) {
        analyticsService.sendEvent('MC_ONBOARDING.STEP_COMPLETE', {
          onboardingId: onboarding.id,
          stepId: stepPassData.stepId,
          passingTime: getStepPassingTime(onboarding.id, stepPassData.stepId),
        })
      }
      stepPassData = { onboardingId: onboarding.id, stepId, stepStartTime: Date.now() }
    },
  })
}

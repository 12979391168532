import { ChannelType } from 'common/core/constants/ChannelType'

export const MenuChannels = [ChannelType.FB, ChannelType.INSTAGRAM]

export interface IMenuItem {
  nested: string[]
  id: string
  caption: string
  flowId: string | null
  type: MenuItemType
  url?: string
  actions?: UnsafeAnyObject
  triggered?: number
}

export interface IMenuChannelState {
  channel: ChannelType

  name: string | null

  enabled: boolean | null
  version: string | null

  isMain: boolean

  isFetched: boolean
  isBusy: boolean
  errorOnLoad: { menuId: string; channel: ChannelType } | null
  isAutoSaving: boolean
  selectedId: string | null
  showErrors: boolean
  serverError: null | {
    menuItemId: string
    message: string
  }

  lastSaved: number | null
  lastChanged: number | null
  saveError: null | string

  flowNs: string | null

  draft: {
    byId: Record<string, IMenuItem>
    nested: string[]
  }
  published: {
    byId: Record<string, IMenuItem>
    nested: string[]
  }
}

export enum FetchStatus {
  UNSENT,
  FETCHING,
  SUCCESS,
  ERROR,
}

export enum MenuItemType {
  FLOW = 'flow',
  URL = 'url',
}

export interface IMenu {
  id: string
  name: string
  subscribers?: number | null
  draft: UnsafeAnyObject | null
}

export interface IMenuListState {
  items: IMenu[]
  fetchStatus: FetchStatus
  createStatus: FetchStatus
}

export type MenuState = Record<ChannelType, IMenuChannelState> & {
  list: IMenuListState
}

import React, { useCallback, useEffect, useState, useMemo } from 'react'
import cx from 'classnames'
import { DropdownPicker, l } from '@manychat/manyui'

import { AccountAvatar } from 'common/core/components/AccountAvatar/AccountAvatar'
import { ISidebarAccount } from 'common/core/interfaces/sideBarAccount'
import { UserRoles } from 'common/userRoles'
import { Row, Col } from 'components/Grid'

import cm from './AccountSelect.module.css'

interface AccountSelectProps {
  'data-test-id': string
  className: string
  current: ISidebarAccount
  onSelect: (data: ISidebarAccount) => void
  options: ISidebarAccount[]
  withPlaceholder: boolean
}

export const checkDisabledForUserRole = (account: ISidebarAccount | null) => {
  if (!account) {
    return false
  }
  return [UserRoles.AGENT, UserRoles.VIEWER].includes(account?.user?.actual_role)
}

export const AccountSelect = ({
  'data-test-id': dataTestId,
  className,
  current,
  onSelect,
  options,
  withPlaceholder,
}: AccountSelectProps) => {
  const [currentOption, setCurrentOption] = useState<ISidebarAccount | null>(null)

  const filteredOptions = useMemo(
    () =>
      currentOption
        ? options?.filter((option: ISidebarAccount) => option.page_id !== currentOption.page_id)
        : options,
    [options, currentOption],
  )
  const disabled = !filteredOptions?.length

  useEffect(() => {
    if (current) {
      setCurrentOption(current)
    }
  }, [current])

  const renderAccount = useCallback((page: ISidebarAccount | null) => {
    const isDisabled = checkDisabledForUserRole(page)
    const dataTitle = isDisabled
      ? `html#${l.translate(
          'You have insufficient rights to copy the bot’s content. You must be an Editor or Administrator of this page.',
        )}`
      : null

    return (
      <Row
        className={cx('align-center text-left p-y-xs p-x', { [cm.disabledOption]: isDisabled })}
        data-test-id={`fb-page-select-${page ? page.id : 'new'}`}
        data-title={dataTitle}
        data-title-at="top"
      >
        <Col width={44}>
          <AccountAvatar
            accountStatus={page?.pro_status}
            avatarColor={page?.default_avatar_color}
            avatarUrl={page?.ava_big || page?.ava_small}
            title={page?.fb_channel?.fb_page_name}
            size={32}
          />
        </Col>

        <Col className="p-x-xs">{page ? page.title : l.translate('Choose an account')}</Col>
      </Row>
    )
  }, [])

  const renderAnchor = useCallback(
    ({ open }: { open: boolean }) => {
      return (
        <div
          role="button"
          aria-pressed={open}
          className={cx('b-a select-page-dropdown', {
            [cm.disabledOption]: disabled,
            'c-pointer text-primary': !disabled,
          })}
          data-test-id={dataTestId || 'fb-page-select'}
        >
          {renderAccount(currentOption)}
        </div>
      )
    },
    [currentOption, disabled, renderAccount, dataTestId],
  )

  const handleSelect = useCallback(
    (data: ISidebarAccount) => {
      const isDisabled = checkDisabledForUserRole(data)

      if (isDisabled) {
        return
      }

      setCurrentOption(data)
      onSelect(data)
    },
    [onSelect],
  )

  if (!withPlaceholder && !currentOption) {
    return null
  }

  return (
    <div className={className}>
      <DropdownPicker
        disabled={disabled}
        options={options}
        renderAnchor={renderAnchor}
        renderOption={renderAccount}
        onSelect={handleSelect}
      />
    </div>
  )
}

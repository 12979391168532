import dot from 'dot-prop-immutable'

import * as atypes from 'apps/ads/constants/AdsReduxActionTypes'

const initialState = {
  accounts: [],
  campaignsByAccountId: {},
  adSetsByCampaignId: {},
}

export default function wizardReducer(state = initialState, action) {
  switch (action.type) {
    case atypes.AD_FETCH_ALL_ACCOUNTS_RESPONSE: {
      return dot.set(
        state,
        'accounts',
        action.accounts.map((item) => item.id),
      )
    }
    case atypes.AD_FETCH_ALL_CAMPAIGNS_RESPONSE: {
      return dot.set(
        state,
        `campaignsByAccountId.${action.accountId}`,
        action.campaigns.map((item) => item.id),
      )
    }
    case atypes.AD_FETCH_ALL_AD_SETS_RESPONSE: {
      return dot.set(
        state,
        `adSetsByCampaignId.${action.campaignId}`,
        action.adSets.map((item) => item.id),
      )
    }
  }

  return state
}

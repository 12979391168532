import React from 'react'

import { getDeserializationData } from 'utils/serializing/getDeserializationData'
import { getSerializationData } from 'utils/serializing/renderHelper'

const NotificationContent = ({ content }: { content: React.FunctionComponent | string }) => {
  if (React.isValidElement(content)) {
    return content
  }

  const deserializationContent = getDeserializationData(content, {
    hasSaveHref: true,
    hasRemoveTags: true,
    hasTrim: true,
  })
  return <span>{getSerializationData(deserializationContent)}</span>
}

export default NotificationContent

import * as atypes from 'apps/chat/constants/LiveChatReduxActionTypes'
import { alert } from 'common/core'
import { IAsyncThunkAction, IThunkAction } from 'common/core/interfaces/actions'
import { getPageId } from 'common/core/selectors/appSelectors'
import * as API from 'constants/API'
import mcApi from 'utils/api/mcApi'
import { userNotificationsListener } from 'utils/services/notificationsService'

import { UnreadThreads, UserCounters } from '../models/chatInterfaces'
import { getIsFetchingCounters, getUsersCounters } from '../selectors/countersSelectors'

export const updateUserCounters = (data: UserCounters): IThunkAction => {
  return (dispatch, getState) => {
    const state = getState()
    const currentAccountId = state?.app?.currentAccount?.page_id
    const eventAccountId = data?.account_id.toString()

    if (eventAccountId && currentAccountId && eventAccountId === currentAccountId) {
      dispatch({ type: atypes.UPDATE_USER_COUNTERS, data })
    }
  }
}

export const updateUnreadThreads = (data: UnreadThreads): IThunkAction => {
  return (dispatch, getState) => {
    const state = getState()
    const pageId = getPageId(state)

    if (String(data.account_id) === String(pageId)) {
      dispatch({ type: atypes.UPDATE_UNREAD_THREADS, data })
    }
  }
}

export const fetchThreadCounters = (): IAsyncThunkAction => {
  return async (dispatch) => {
    dispatch({ type: atypes.FETCH_THREAD_COUNTERS_REQUEST })
    try {
      const data = await mcApi.get(API.threads.endpoints.fetchThreadCounters)
      if (data) {
        dispatch({
          type: atypes.FETCH_THREAD_COUNTERS_RESPONSE,
          data,
        })
      }
    } catch (e) {
      dispatch({ type: atypes.FETCH_THREAD_COUNTERS_ERROR })
      // eslint-disable-next-line
      // @ts-expect-error
      alert(e.error, 'danger')
    }
  }
}

export const ensureThreadCounters = (): IAsyncThunkAction => {
  return async (dispatch, getState) => {
    const state = getState()
    if (getIsFetchingCounters(state) || getUsersCounters(state)) return

    return dispatch(fetchThreadCounters())
  }
}

userNotificationsListener.on('account_livechat_counters_updated', (data, dispatch) => {
  dispatch(updateUserCounters(data.model))
})

userNotificationsListener.on('account_livechat_has_unread_thread', (data, dispatch) => {
  dispatch(updateUnreadThreads(data.model))
})

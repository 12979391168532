import { l } from '@manychat/manyui'

import { VariantBlockColors, VariantBlockTitles } from 'common/builder/blocks/blockConstants'
import { IBlock } from 'common/builder/blocks/blockInterfaces'
import { BlockType } from 'common/builder/constants/BlockType'
import { NodeType } from 'common/builder/constants/NodeType'
import BaseNodeConfig from 'common/builder/nodes/BaseNodeConfig'
import { INodeBase, ISplitNode } from 'common/builder/nodes/nodeInterfaces'

class SplitNodeConfig extends BaseNodeConfig<ISplitNode> {
  nodeType = NodeType.SPLIT

  isPro = true

  getCaption = () => l.translate('Randomizer')
  getCaptionWithCount = (n: number) =>
    l.translate('{n, plural, one {# Randomizer} other {# Randomizers}}', { n })
  icon = 'OrphanSplit'
  color = '#8B55F7'
  headerColor = '#e0d7fb'

  ALLOWED_BLOCK_TYPES = [BlockType.VARIANT]

  ALLOWED_BLOCK_MAX_COUNT = 6
  ALLOWED_BLOCK_MIN_COUNT = 2

  getDefaults = (): Omit<ISplitNode, keyof INodeBase> => ({
    targetId: null,
    randomized: false,
  })

  getInitialBlocks = () => {
    return [
      {
        type: BlockType.VARIANT as const,
        title: VariantBlockTitles.A,
        color: VariantBlockColors[VariantBlockTitles.A],
        percent: 50,
      },
      {
        type: BlockType.VARIANT as const,
        title: VariantBlockTitles.B,
        color: VariantBlockColors[VariantBlockTitles.B],
        percent: 50,
      },
    ]
  }

  isEmpty = ({ blocks }: { node: ISplitNode; blocks: IBlock[] }) => {
    return blocks.length === 2 && this.areBlocksEmpty(blocks)
  }

  _isEmptyBlock = (block: IBlock) => {
    const isVariant = block.type === BlockType.VARIANT
    const isEitherAOrB =
      ('title' in block && block.title === VariantBlockTitles.A) ||
      ('title' in block && block.title === VariantBlockTitles.B)
    return isVariant && isEitherAOrB && block.percent === 50 && !block.targetId
  }
}

export default new SplitNodeConfig()

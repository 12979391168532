import { SmsMessageType } from 'apps/sms/smsInterfaces'
import { ButtonType } from 'common/builder/constants/ButtonType'
import { NodeType } from 'common/builder/constants/NodeType'
import * as smsNodeHelpers from 'common/builder/nodes/sms/smsNodeHelpers'
import builderSelectors from 'common/builder/selectors/builder'
import * as entitySelectors from 'common/builder/selectors/builder/entitySelectors'
import { getCurrentAccount } from 'common/core/selectors/appSelectors'
import {
  getSelectedSMSBodyLinkShortener,
  isSMSBodyLinkShortenerDisabled,
} from 'common/settings/selectors/settingsSelectors'
import { parseSmartLinkString } from 'components/MCTextEditor/smartLinks/adapter'
import { parseURL } from 'components/MCTextEditor/url/adapter'
import { BODY_LINK_SHORTENER_REGEX, INLINE_BUTTON_REGEX, VAR_REGEX } from 'constants/RegExs'
import { formatToShortenedLink } from 'utils/misc'
import { smsCounter } from 'utils/smsCounter'

export const getSmsLinkCaption = (state: RootState, builderId: string, buttonId: string) => {
  const button = entitySelectors.getButtonById(state, builderId, buttonId)
  if (!button) {
    return ''
  }

  const isShortenerDisabled = isSMSBodyLinkShortenerDisabled(state)
  const shortener = getSelectedSMSBodyLinkShortener(state)

  if (button.type === ButtonType.URL) {
    if (isShortenerDisabled) {
      return button.url || 'Enter URL'
    }
    return formatToShortenedLink(shortener)
  }

  const currentAccountLink = getCurrentAccount(state)?.fb_channel?.link
  return `${currentAccountLink}?ref=XXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXX-_-_XXXXXXXXXXXXXXXX`
}

export const getSmsText = (state: RootState, builderId: string, blockId: string) => {
  const messageBlock = entitySelectors.getBlockById(state, builderId, blockId)
  if (!messageBlock || !('text' in messageBlock)) {
    return ''
  }

  return messageBlock.text
}

export const getSmsMessages = (state: RootState, builderId: string, nodeId: string) => {
  const node = entitySelectors.getNodeById(state, builderId, nodeId)

  if (!(node && node.nodeType === NodeType.SMS)) {
    return []
  }

  const blocks = builderSelectors.node.getOrdinaryBlocks(state, builderId, nodeId)
  return smsNodeHelpers.buildMessagesFromBlocks(blocks)
}

export const getSmsMessagesQuantity = (state: RootState, builderId: string, nodeId: string) => {
  const messages = getSmsMessages(state, builderId, nodeId)

  const textMessages = messages.filter((m) => m.type === SmsMessageType.TEXT_MESSAGE)
  const multimediaMessages = messages.filter((m) => m.type === SmsMessageType.MULTIMEDIA_MESSAGE)

  const multimediaMessagesCount = multimediaMessages.length
  const textMessagesCount = textMessages.reduce(
    (count, textMessage) =>
      count + getTextMessagesCountFromBlock(state, builderId, textMessage.blocks[0]),
    0,
  )

  return { textMessagesCount, multimediaMessagesCount }
}

export const getTextMessagesCountFromBlock = (
  state: RootState,
  builderId: string,
  blockId: string,
) => {
  const smsText = getSmsText(state, builderId, blockId)
  return getTextMessagesCount(state, builderId, smsText)
}

export const getTextMessagesCount = (state: RootState, builderId: string, smsText: string) => {
  const shortener = getSelectedSMSBodyLinkShortener(state)
  const isShortenerDisabled = isSMSBodyLinkShortenerDisabled(state)

  smsText = smsText.replace(BODY_LINK_SHORTENER_REGEX, (el) => {
    const parsed = parseURL(el)
    return isShortenerDisabled && parsed ? parsed[1] : formatToShortenedLink(shortener)
  })

  smsText = smsText.replace(INLINE_BUTTON_REGEX, (el) => {
    const id = parseSmartLinkString(el)
    return id ? getSmsLinkCaption(state, builderId, id) : ''
  })

  const variables = smsText.match(VAR_REGEX) || []
  variables.forEach((variable) => {
    smsText = smsText.replace(variable, '')
  })

  return Math.max(smsCounter(smsText).messages, 1)
}

import { Stripe } from '@stripe/stripe-js'
import { l } from '@manychat/manyui'

import { confirmIntent } from 'common/billing/actions/stripeCheckoutActions'
import { alert } from 'common/core'
import { billingFlexApi } from 'shared/api/requests/billingFlex'
import {
  StripeCheckoutSuccessSubscribeClientFlowPayload,
  StripeConfirmedIntentSchema,
} from 'shared/api/requests/billingFlex/schemas'
import { createAsyncAction } from 'shared/lib/redux'

export const checkoutSuccessSubscribeCustomCheckout = createAsyncAction<
  StripeConfirmedIntentSchema | undefined,
  StripeCheckoutSuccessSubscribeClientFlowPayload & { stripe: Stripe }
>('billingFlex/checkoutSuccessSubscribeCustomCheckout', async (payload) => {
  const response = await billingFlexApi.checkoutSuccessSubscribeCustomCheckout({
    body: {
      setup_intent_id: payload.setup_intent_id,
      products: payload.products,
      addons: payload.addons,
      is_trial_request: payload.is_trial_request,
      is_upgrade_to_pro: payload.is_upgrade_to_pro,
    },
  })

  let confirmResult

  if (response.data && response.data.confirm) {
    const { type, client_secret } = response.data.confirm

    confirmResult = await confirmIntent({
      stripe: payload.stripe,
      type,
      client_secret,
    })
  }

  alert(l.translate('Successfully updated'), 'success')
  return confirmResult
})

import { mapResponse } from 'shared/api/lib/mapResponse'
import { BillingApi } from 'shared/api/requests/billing'
import { Charge } from 'shared/api/requests/billing/schemas'
import { createListSlice } from 'shared/lib/factory/redux/createListSlice'
import { createListOperations } from 'shared/lib/factory/redux/createListSlice/lib/createListOperations'

const namespace = 'charges'

export const chargesSlice = createListSlice({
  storePath: 'billing.charges',

  namespace,

  operations: createListOperations({
    getList: mapResponse(BillingApi.listCharges, (response) => response.data.charges),
    getNextList: undefined,
    getPrevList: undefined,
    createItem: undefined,
    deleteItem: undefined,
    updateItem: undefined,
  }),

  getItemId: (item: Charge) => item.charge_id,

  extraState: {},
  reducers: {},
})

export const { actions: chargesActions, selectors: chargesSelectors } = chargesSlice

export type ChargesListState = typeof chargesSlice.initialState

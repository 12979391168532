import * as atypes from 'apps/account/accountReduxActionTypes'

const initialState = {
  name: '',
}

export default function accountDraftReducer(state = initialState, action) {
  switch (action.type) {
    case atypes.ACCOUNT_NAME_UPDATE: {
      const { name } = action
      return Object.assign(state, { name })
    }
  }

  return state
}

import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import { ChatStateField } from 'apps/chat/models/chatInterfaces'

const initialState: ChatStateField = {
  isSnippetsListOpened: false,
  isEnableNotificationsModalOpened: false,
  isScheduleMessageModalOpened: false,
  wasMessageScheduled: false,
  scheduledMessageText: null,
}

const stateSlice = createSlice({
  name: 'chat/state',
  initialState,
  reducers: {
    openSnippetsList(state) {
      state.isSnippetsListOpened = true
    },
    closeSnippetsList(state) {
      state.isSnippetsListOpened = false
    },
    openEnableNotificationsModal(state) {
      state.isEnableNotificationsModalOpened = true
    },
    closeEnableNotificationsModal(state) {
      state.isEnableNotificationsModalOpened = false
    },
    openScheduleMessageModal(state, action: PayloadAction<string>) {
      state.isScheduleMessageModalOpened = true
      state.scheduledMessageText = action.payload
    },
    closeScheduleMessageModal(state) {
      state.isScheduleMessageModalOpened = false
      state.scheduledMessageText = null
    },
    setWasMessageScheduled(state) {
      state.wasMessageScheduled = true
    },
    unsetWasMessageScheduled(state) {
      state.wasMessageScheduled = false
    },
  },
})

export const {
  openSnippetsList,
  closeSnippetsList,
  openEnableNotificationsModal,
  closeEnableNotificationsModal,
  openScheduleMessageModal,
  closeScheduleMessageModal,
  setWasMessageScheduled,
  unsetWasMessageScheduled,
} = stateSlice.actions

export const getIsEnableNotificationsModalOpened = (state: RootState) =>
  state?.chat?.state?.isEnableNotificationsModalOpened

export const getIsScheduleMessageModalOpened = (state: RootState) =>
  state?.chat?.state?.isScheduleMessageModalOpened

export const getScheduledMessageText = (state: RootState) =>
  state?.chat?.state?.scheduledMessageText

export const getWasMessageScheduled = (state: RootState) => state?.chat?.state?.wasMessageScheduled

export const { reducer } = stateSlice

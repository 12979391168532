import { extractNextPathSearchParams } from 'common/authUrlUtils/authUrlUtils'
import { NEXT_PATH_SEARCH_PARAM } from 'utils/router/constants'
import { sanitizeNextPath } from 'utils/router/tools/sanitizeNextPath'
import { addURLParam, linkDomain } from 'utils/url'

const POPUP_WIDTH = 520
const POPUP_HEIGHT = 520

const processParentMessage = (listenSource: Window) => (event: MessageEvent) => {
  const { data, source: loginWindow } = event
  if (event.source !== listenSource) {
    return
  }

  const source = loginWindow as Window

  if (data?.redirectUrl) {
    source && 'close' in source && source.close()
    const redirectUrlObj = new URL(data.redirectUrl)
    const nextPath = extractNextPathSearchParams(window.location.search)

    if (nextPath) {
      const nextPathParam = sanitizeNextPath(nextPath)
      redirectUrlObj.searchParams.set(NEXT_PATH_SEARCH_PARAM, nextPathParam)
    }
    window.location.assign(redirectUrlObj.toString())
  }
}

export enum ProfileAuthURLs {
  FB = '/auth/fb',
  GOOGLE = '/auth/google',
  APPLE = '/auth/apple',
}

export const openAuthWindow = (
  url: ProfileAuthURLs,
  returnURL?: string,
  isSignIn?: boolean,
  isFacebookAuth?: boolean,
): void => {
  let exactUrl = linkDomain(url)

  if (returnURL) {
    exactUrl = addURLParam(exactUrl, 'return', encodeURIComponent(returnURL))
  }

  if (isSignIn || isFacebookAuth) {
    exactUrl = addURLParam(exactUrl, 'is_signup', encodeURIComponent(isFacebookAuth === true))
  }

  const left = screen.width / 2 - POPUP_WIDTH / 2
  const top = screen.height / 2 - POPUP_HEIGHT / 2
  const params = `width=${POPUP_WIDTH},height=${POPUP_HEIGHT},left=${left},top=${top}`

  const authWindow = window.open(exactUrl, '_blank', params)

  if (authWindow !== null) {
    window.addEventListener('message', processParentMessage(authWindow))

    const loginURL =
      authWindow?.outerWidth <= POPUP_WIDTH
        ? addURLParam(addURLParam(exactUrl, 'popup', 'true'), 'js', 'true')
        : exactUrl

    authWindow.location.href = loginURL
  }
}

import { markOnboardingCompleted } from 'apps/instagramOnboarding/actions/onboardingActions'
import intercomService from 'utils/intercomService'
import { trackNewOnboardingAnalytics } from 'utils/services/newOnboardingService/analytics'
import { IOnboardingRunContext, Store } from 'utils/services/newOnboardingService/interfaces'
import { ManyChatOnboarding } from 'utils/services/newOnboardingService/ManyChatOnboardingIdType'
import { NewOnboardingService } from 'utils/services/newOnboardingService/NewOnboardingService'
import OnboardingId from 'utils/services/newOnboardingService/OnboardingId'
import CGT from 'utils/services/newOnboardingService/onboardings/SelfServe/CGT/CommentsGrowthTool'
import NewCMSFAQ from 'utils/services/newOnboardingService/onboardings/SelfServe/NewCMSFAQ/NewCMSFAQ'
import NewCMSStoryMentionReply from 'utils/services/newOnboardingService/onboardings/SelfServe/NewCMSStoryMentionReply/NewCMSStoryMentionReply'
import NewCMSStoryReaction from 'utils/services/newOnboardingService/onboardings/SelfServe/NewCMSStoryReaction/NewCMSStoryReaction'
import { BroadcastSampleTour } from 'utils/services/newOnboardingService/onboardings/WhatsApp/BroadcastSampleTour'
import ImportContactTour from 'utils/services/newOnboardingService/onboardings/WhatsApp/ImportContactTour'

import FirstLiveChatThreadTour from './onboardings/FirstLiveChatThread/FirstLiveChatThreadTour'

export const newOnboardingService: NewOnboardingService = new NewOnboardingService()

export const initNewOnboardingService = (store: Store) => {
  newOnboardingService.init({
    [OnboardingId.CGT_FLOW_OVERVIEW_TOUR]: () => new CGT(store),
    [OnboardingId.WHATSAPP_BROADCAST_SAMPLE_TOUR]: () => new BroadcastSampleTour(),
    [OnboardingId.WA_IMPORT_CONTACT_TOUR]: () => new ImportContactTour(),
    [OnboardingId.NEW_CMS_SELF_SERVE_STORY_REACTION_TOUR]: () => new NewCMSStoryReaction(),
    [OnboardingId.NEW_CMS_SELF_SERVE_FAQ_TOUR]: () => new NewCMSFAQ(),
    [OnboardingId.NEW_CMS_SELF_SERVE_STORY_MENTION_REPLY_TOUR]: () => new NewCMSStoryMentionReply(),
    [OnboardingId.LC_WA_FIRST_THREAD_TOUR]: () => new FirstLiveChatThreadTour(),
  })

  trackNewOnboardingAnalytics(newOnboardingService)

  newOnboardingService.onLifeCycle({
    onComplete: (onboarding: ManyChatOnboarding) => {
      store.dispatch(markOnboardingCompleted(onboarding.id, 'account'))
    },
  })

  newOnboardingService.onLifeCycle({
    onRun: intercomService.intercomShutdown,
    onComplete: intercomService.intercomBoot,
    onSkip: intercomService.intercomBoot,
  })
}

// Temp hack while two version of onboarding services exist
export const runUniOnboarding = (onboardingId: string, context?: IOnboardingRunContext) => {
  newOnboardingService.run(onboardingId as OnboardingId, context)
}

export const getCGTOnboarding = () =>
  newOnboardingService.getOnboarding(OnboardingId.CGT_FLOW_OVERVIEW_TOUR)

export const getWhatsAppBroadcastSampleOnboarding = () =>
  newOnboardingService.getOnboarding(OnboardingId.WHATSAPP_BROADCAST_SAMPLE_TOUR)

export const getWaImportContactOnboarding = () =>
  newOnboardingService.getOnboarding(OnboardingId.WA_IMPORT_CONTACT_TOUR)

export const getFirstLiveChatThreadOnboarding = () =>
  newOnboardingService.getOnboarding(OnboardingId.LC_WA_FIRST_THREAD_TOUR)

export const getNewCMSSelfServeStoryReactionOnboarding = () =>
  newOnboardingService.getOnboarding(OnboardingId.NEW_CMS_SELF_SERVE_STORY_REACTION_TOUR)

export const getNewCMSSelfServeFAQOnboarding = () =>
  newOnboardingService.getOnboarding(OnboardingId.NEW_CMS_SELF_SERVE_FAQ_TOUR)

export const getNewCMSSelfServeStoryMentionOnboarding = () =>
  newOnboardingService.getOnboarding(OnboardingId.NEW_CMS_SELF_SERVE_STORY_MENTION_REPLY_TOUR)

import assert from 'assert'

import React from 'react'
import { l, BtnV2 } from '@manychat/manyui'

import { AiIntentTrigger } from 'apps/aiIntents/interfaces/interfaces'
import { updateBuilderState } from 'common/builder/actions/updateBuilderState'
import * as ActionTypes from 'common/builder/constants/builderReduxActionTypes'
import builderSelectors from 'common/builder/selectors/builder'
import * as builderStateSelectors from 'common/builder/selectors/builder/builderStateSelectors'
import { isWidget } from 'common/builder/utils/isWidget'
import { alert } from 'common/core'
import { IAsyncThunkAction, IThunkAction } from 'common/core/interfaces/actions'
import * as flowTriggersActions from 'common/flow/actions/flowTriggersActions'
import { TriggerType } from 'common/flow/constants/TriggerType'
import { IFlowTrigger } from 'common/flow/flowInterfaces'
import { ITriggerId } from 'common/flow/flowTriggersInterfaces'
import * as flowSelectors from 'common/flow/selectors/flowSelectors'
import { isInstagramCgt } from 'common/flow/utils/isInstagramCgt'
import * as API from 'constants/API'
import { navigatePollyfill } from 'utils/router/tools'
import { analyticsService } from 'utils/services/analytics'
import errorTrackingService from 'utils/services/errorTrackingService'
import { linkURL } from 'utils/url'

import { setFirstIgNodeAsCommentReplyIfPossible } from './builderNodeTypedActions'
import * as builderStateActions from './builderStateActions'

export function createTrigger(
  builderId: string,
  triggerType: TriggerType,
  initial: Partial<IFlowTrigger> = {},
  options: Partial<{
    showSuccessToast: boolean
    preventOpen: boolean
    addHomeTabParams: boolean
    keepCurrentSidebarOpenStatus: boolean
  }> = {
    showSuccessToast: false,
    preventOpen: false,
    keepCurrentSidebarOpenStatus: false,
    addHomeTabParams: false,
  },
): IAsyncThunkAction {
  return async (dispatch, getState) => {
    assert(builderId, `createTrigger: builderId is required param`)
    assert(triggerType, 'createTrigger: triggerType is required')

    if (triggerType === TriggerType.AI_INTENT) {
      analyticsService.sendEvent(`FLOW.AI_INTENT_CREATED`, {
        intent: (initial as Partial<AiIntentTrigger>).intent,
      })
    } else {
      analyticsService.sendEvent(`FLOW.${triggerType.toUpperCase()}_CREATED`)
    }

    const state = getState()
    const builderState = builderSelectors.builderState.getById(state, builderId)

    dispatch(updateBuilderState(builderId, { fetchingCreateTrigger: true }))

    let item
    try {
      const result = await dispatch(
        flowTriggersActions.createTrigger(builderState.flow, triggerType, initial, options),
      )
      item = result?.item
      if (options.showSuccessToast && item) alert(l.translate('Keyword trigger created'), 'success')
      // eslint-disable-next-line
      // @ts-expect-error
    } catch (err: UnsafeAnyObject) {
      errorTrackingService.trackWarning(err, {
        fingerprint: 'failed-to-create-trigger',
      })
      return dispatch(updateBuilderState(builderId, { fetchingCreateTrigger: false }))
    }

    dispatch(
      updateBuilderState(builderId, {
        fetchingCreateTrigger: false,
        ...(!options.keepCurrentSidebarOpenStatus && {
          sidebarHidden: Boolean(options.preventOpen),
        }),
      }),
    )

    if (item && !options.preventOpen) {
      dispatch(builderStateActions.openTrigger(builderId, item.triggerId))
    }

    if (isWidget(item) && isInstagramCgt(item)) {
      dispatch(setFirstIgNodeAsCommentReplyIfPossible(builderId))
    }

    return item
  }
}

interface IMoveTriggerParams {
  flow_ns: string
  target_flow_ns: string
  type: TriggerType
  entity_id: number | string
  is_duplicate: boolean
}

export function moveTrigger({
  flow_ns,
  target_flow_ns,
  type,
  entity_id,
  is_duplicate,
}: IMoveTriggerParams): IThunkAction {
  return (dispatch, getState) => {
    const isDuplicatedInsideFlow = flow_ns === target_flow_ns
    const targetFlow = flowSelectors.getById(getState(), target_flow_ns)

    return dispatch({
      type: ActionTypes.BUILDER_MOVE_TRIGGER,
      $fetch: [
        API.cms.endpoints.moveTrigger,
        {
          method: 'POST',
          body: JSON.stringify({
            flow_ns,
            target_flow_ns,
            type,
            entity_id,
            is_duplicate,
          }),
          headers: { 'Content-Type': 'application/json' },
        },
      ],
      $success: () =>
        alert(
          isDuplicatedInsideFlow
            ? 'Trigger has been duplicated'
            : `Trigger has been ${is_duplicate ? 'duplicated' : 'moved'} to "${
                targetFlow?.name
              }" flow`,
          'success',
          {
            content: (
              <BtnV2
                className="text-underline m-t"
                onClick={() => navigatePollyfill(linkURL(`/cms/files/${targetFlow?.id}`))}
              >
                Open this flow
              </BtnV2>
            ),
          },
        ),
    })
  }
}

export function setTriggerEnabled(
  builderId: string,
  triggerId: ITriggerId,
  enabled: boolean,
): IAsyncThunkAction {
  return async (dispatch, getState) => {
    analyticsService.sendEvent('BUILDER.STARTING_STEP_NODE_SIDEBAR.ENABLE_TRIGGER.CLICK', {
      enabled,
    })
    const state = getState()
    const builderState = builderStateSelectors.getById(state, builderId)
    const { flow: flowId } = builderState

    await dispatch(
      flowTriggersActions.setTriggerEnabled(flowId, triggerId, enabled, {
        source: 'builderStartingStep',
      }),
    )
  }
}

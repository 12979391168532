import { z } from 'zod'

import { BusinessErrorSchema } from 'shared/api/common/schemas/errors'
import { BusinessError } from 'shared/api/lib/errors/business'

export interface TrialUnavailableError extends BusinessError {
  field: 'trial_unavailable'
}

const isTrialUnavailableError = (error: BusinessError): error is TrialUnavailableError =>
  error.field === 'trial_unavailable'

const ForbiddenAccountLegalEntityErrorSchema = BusinessErrorSchema.extend({
  field: z.literal('forbidden_account_legal_entity'),
})

const TaxNumberInvalidFormatErrorSchema = BusinessErrorSchema.extend({
  field: z.literal('tax_number_invalid_format'),
})

const makeGuard =
  <Schema extends z.ZodSchema>(schema: Schema) =>
  (value: BusinessError): value is z.infer<Schema> => {
    return schema.safeParse(value).success
  }

export const BillingFlexApiGuards = {
  isTrialUnavailableError,
  isTaxNumberInvalidFormatError: makeGuard(TaxNumberInvalidFormatErrorSchema),
  isForbiddenAccountLegalEntityError: makeGuard(ForbiddenAccountLegalEntityErrorSchema),
}

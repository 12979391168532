import assert from 'assert'

import get from 'lodash/get'

import { openNode } from 'common/builder/actions/openNode'
import { NodeType } from 'common/builder/constants/NodeType'
import builderSelectors from 'common/builder/selectors/builder'
import { getNodeById, getAllRelated } from 'common/builder/selectors/builder/entitySelectors'
import { analyticsService, flowBuilderEvents } from 'utils/services/analytics'

import { removeBlockForce } from './blockActions'
import { removeButtonForce } from './buttonActions'
import { removeNode } from './nodeActions'

/**
 * @param builderId
 * @param nodeId
 */
export function deleteNode(builderId, nodeId) {
  return (dispatch, getState) => {
    const state = getState()

    assert(builderId, `deleteNode: builderId is required param`)
    assert(nodeId, `deleteNode: nodeId is required param`)

    const node = getNodeById(state, builderId, nodeId)
    assert(node, `deleteNode: node with id "${nodeId}" doesn't exist`)

    analyticsService.logFlowBuilderEvent(flowBuilderEvents.DELETE_NODE)

    const activeNodeId = builderSelectors.builderState.getCurrentNodeId(state, builderId)

    if (activeNodeId === nodeId) {
      const rootId = builderSelectors.builderState.getRootNodeId(state, builderId)
      const nodeIdToOpen = rootId && nodeId !== rootId ? rootId : NodeType.STARTING_STEP
      dispatch(openNode(builderId, nodeIdToOpen, { dropHistory: true }))
    }

    const builderState = builderSelectors.builderState.getById(state, builderId)
    const foreign = builderState.flow !== node.flow

    const [, related] = getAllRelated(state, builderId, nodeId)
    get(related, 'buttons', []).forEach((button) =>
      dispatch(removeButtonForce(builderId, button.id)),
    )
    get(related, 'blocks', []).forEach((block) => dispatch(removeBlockForce(builderId, block.id)))

    dispatch(removeNode(builderId, nodeId, foreign))
  }
}

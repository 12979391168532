import * as atypes from '../mappingReduxActionTypes'

export const updateOtnsMap = (data) => ({
  type: atypes.TARGET_PAGE_UPDATE_OTNS_MAP,
  data,
})

export const updateTagsMap = (data) => (dispatch, getState) => {
  dispatch({
    type: atypes.TARGET_PAGE_UPDATE_TAGS_MAP,
    data,
  })
}

export const updateCUFsMap = (data) => (dispatch, getState) => {
  dispatch({
    type: atypes.TARGET_PAGE_UPDATE_CUFS_MAP,
    data,
  })
}

export const updateGAFsMap = (data) => (dispatch, getState) => {
  dispatch({
    type: atypes.TARGET_PAGE_UPDATE_GAFS_MAP,
    data,
  })
}

export const updateCustomEventsMap = (data) => (dispatch) => {
  dispatch({
    type: atypes.TARGET_PAGE_UPDATE_CUSTOM_EVENTS_MAP,
    data,
  })
}

export const resetMaps = () => (dispatch, getState) => {
  dispatch({
    type: atypes.TARGET_PAGE_RESET_MAPS,
  })
}

import dot from 'dot-prop-immutable'
import each from 'lodash/each'

import * as atypes from 'common/builder/constants/builderReduxActionTypes'

const initialState = { byId: {} }

function sourcesReducers(state = initialState, action) {
  switch (action.type) {
    case atypes.UPDATE_SOURCE_TARGET: {
      const { sourceId, targetId } = action
      const sourceItem = state.byId[sourceId]

      if (!sourceItem) {
        return state
      }

      return dot.set(state, `byId.${sourceId}.targetId`, targetId)
    }

    case atypes.REMOVE_NODE: {
      const { content, nodeId } = action

      each(state.byId, (sourceItem, sourceId) => {
        const { targetId } = sourceItem
        if (!targetId) {
          return
        }

        if (targetId === nodeId || (content && String(targetId) === String(content.contentId))) {
          state = dot.set(state, `byId.${sourceId}.targetId`, null)
        }
      })
      return state
    }
  }

  return state
}

export default function makeSourcesReducer(reducer) {
  return (state, action) => {
    state = sourcesReducers(state, action)
    return reducer(state, action)
  }
}

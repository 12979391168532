import { api } from 'shared/api'
import { TagsSchemas } from 'shared/api/requests/tags/schemas'

export const TagsApi = {
  getList: api.account.createGet({
    url: '/tags/list',
    schemas: TagsSchemas.getList,
  }),
  create: api.account.createGet({
    url: '/tags/create',
    schemas: TagsSchemas.create,
  }),
  update: api.account.createPost({
    url: '/tags/update',
    schemas: TagsSchemas.update,
  }),
  delete: api.account.createGet({
    url: '/tags/delete',
    schemas: TagsSchemas.delete,
  }),
  bulkDelete: api.account.createPost({
    url: '/tags/deleteBulk',
    schemas: TagsSchemas.bulkDelete,
  }),
  moveToFolder: api.account.createPost({
    url: '/tags/moveToFolder',
    schemas: TagsSchemas.moveToFolder,
  }),
  moveToFolderBulk: api.account.createPost({
    url: '/tags/moveToFolderBulk',
    schemas: TagsSchemas.moveToFolderBulk,
  }),
  getFolderContentCount: api.account.createGet({
    url: '/tags/getFolderContentCount',
    schemas: TagsSchemas.getFolderContentCount,
  }),
  getUsesList: api.account.createGet({
    url: '/tags/getUsesList',
    schemas: TagsSchemas.getUsesList,
  }),
}

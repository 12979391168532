import React, { Suspense } from 'react'
import { useAppDispatch } from 'reduxTyped'

import { redirectToStripe } from 'common/billing/actions/stripeCheckoutActions'
import {
  AsyncComponentPropsWithManageSubscription,
  RequestCredentialsResult,
} from 'common/billing/components/AsyncStripeClientCredentialsView/types'
import { promiseRender } from 'common/billing/helpers/promiseRender'
import { safeReactLazy } from 'utils/safeReactLazy'
import errorTrackingService from 'utils/services/errorTrackingService'

import { StripeErrorBoundary } from './StripeErrorBoundary'

const StripeClientCredentialsView = safeReactLazy(() => import('./StripeClientCredentialsView'))

export const [requestCredentials, AsyncStripeClientCredentialsView] = promiseRender<
  RequestCredentialsResult,
  AsyncComponentPropsWithManageSubscription<RequestCredentialsResult>
>((props: AsyncComponentPropsWithManageSubscription<RequestCredentialsResult>) => {
  const dispatch = useAppDispatch()
  return (
    <StripeErrorBoundary
      onError={(error) => {
        dispatch(
          redirectToStripe({
            ...props.payload,
          }),
        )
        errorTrackingService.trackError(error, {
          tags: { component: 'stripe' },
        })
      }}
    >
      <Suspense>
        <StripeClientCredentialsView {...props} />
      </Suspense>
    </StripeErrorBoundary>
  )
})

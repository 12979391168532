import React from 'react'
import { Scrollbars } from '@manychat/manyui'

export default class CustomScrollbars extends React.Component {
  scrollBarsRef = React.createRef()

  render() {
    return (
      <Scrollbars
        ref={this.scrollBarsRef}
        {...this.props}
        style={this.props.style}
        onScroll={(e) => {
          this.props.onScroll && this.props.onScroll(e, this.scrollBarsRef.current)
        }}
        onUpdate={(e) => {
          this.props.onUpdate && this.props.onUpdate(e, this.scrollBarsRef.current)
        }}
        onScrollFrame={this.handleScrollFrame}
        onScrollStart={(e) => {
          this.props.onScrollStart && this.props.onScrollStart(e, this.scrollBarsRef.current)
        }}
        onScrollStop={this.handleScrollStop}
        renderTrackHorizontal={this.renderTrackHorizontal}
        renderTrackVertical={this.renderTrackVertical}
        renderThumbHorizontal={this.renderThumbHorizontal}
        renderThumbVertical={this.renderThumbVertical}
        autoHide
        autoHideTimeout={1000}
        autoHideDuration={200}
        thumbMinSize={30}
        universal
      >
        {this.props.children}
      </Scrollbars>
    )
  }
}

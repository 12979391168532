import compact from 'lodash/compact'
import get from 'lodash/get'
import trim from 'lodash/trim'
import normalizeUrl from 'normalize-url'
import { l } from '@manychat/manyui'

import { ActionTypes } from 'common/actions/models/Action/constants'
import { getActionListErrors } from 'common/actions/models/Action/validation'
import {
  AttachmentBlockAttachmentType,
  QuestionBlockReplyType,
} from 'common/builder/blocks/blockConstants'
import { BlockType } from 'common/builder/constants/BlockType'
import { ButtonType } from 'common/builder/constants/ButtonType'
import { FBMessagingType } from 'common/builder/constants/facebookMessaging'
import { NodeType } from 'common/builder/constants/NodeType'
import { isVariable } from 'common/builder/constants/PhoneInputType'
import ErrorMessages, {
  ERROR_PANE,
  MAX_COUNT_BLOCKS_TO_SHOW_EMPTY_TEXT_ERROR,
} from 'common/builder/constants/Validation'
import {
  SMS_NODE_IMAGE_MAX_SIZE,
  TEXT_BLOCK_TEXT_MAX_LENGTH,
  TEXT_BLOCK_WITH_BUTTONS_TEXT_MAX_LENGTH,
  SIMPLE_BUILDER_TEXT_BLOCK_TEXT_MAX_LENGTH,
  INSTAGRAM_TEXT_BLOCK_TEXT_MAX_LENGTH,
  TELEGRAM_TEXT_BLOCK_TEXT_MAX_LENGTH,
  FORM_QUESTION_BLOCK_TEXT_MAX_LENGTH,
  CardMaxTitleLength,
  CardMaxSubtitleLength,
  ListMaxTitleLength,
  ListMaxSubtitleLength,
} from 'common/builder/models/Block/constants'
import {
  ButtonMaxCaptionLength,
  HyperlinkMaxCaptionLength,
} from 'common/builder/models/Button/constants'
import { isValidSmartLinkButton } from 'common/builder/models/Button/helpers'
import nodeRegistry from 'common/builder/nodeRegistry'
import {
  DefaultContentOrdinaryBlockInARowMaxCount,
  DefaultContentDelayBlockInARowMaxCount,
} from 'common/builder/nodes/content/contentNodeConstants'
import { DelayType } from 'common/builder/nodes/smartDelay/delayTypes'
import { getNodesMap } from 'common/builder/selectors/builder/entitySelectors'
import { getFlowConditionFields } from 'common/builder/selectors/builder/fieldsSelectors'
import { getCustomEventsItems } from 'common/events/selectors/eventSelectors'
import { getFormulaErrors } from 'common/fields/models/CustomField/validation'
import * as userFieldsSelectors from 'common/fields/selectors/userFieldsSelectors'
import { FilterField } from 'common/filter/models/AudienceFilter/constants'
import { hasConditions } from 'common/filter/models/AudienceFilter/helpers'
import * as segmentsSelectors from 'common/filter/selectors/segmentsSelectors'
import { ReasonStatuses } from 'common/oneTimeNotify/constants/notifyReasonConstants'
import {
  getReasonById,
  getActiveReasons,
} from 'common/oneTimeNotify/selectors/notifyReasonSelectors'
import { getTextLength } from 'components/MCTextEditor/toolbar/toggles/counterUtils'
import { accountFlags } from 'utils/services/featureFlagsService'
import { AccountFlag } from 'utils/services/featureFlagsService/constants'

import {
  WA_CATALOG_MESSAGE_MAX_BODY_LENGTH,
  WA_CATALOG_MESSAGE_MAX_FOOTER_LENGTH,
} from '../../../../apps/whatsApp/constants/catalogMessageConstants'

import * as builderStateSelectors from './builderStateSelectors'
import * as entitySelectors from './entitySelectors'
import * as nodeSelectors from './nodeSelectors'
import { addCapiValidationErrors } from './utils'
import { validationSelectorsTyped } from './validationSelectorsTyped'
import {
  getWhatsAppListMessageBlockErrors,
  getWhatsAppListMessageButtonErrors,
} from './whatsAppValidationSelectors'

const _getButtonLabel = (block, button, index) => {
  if (button.type === ButtonType.WA_LIST_MESSAGE_BUTTON) {
    return `Option ${index + 1}`
  }

  return `Button ${index + 1}`
}

const isValidPhone = (phone) => {
  if (!phone) {
    return false
  }

  if (phone && isVariable(phone)) {
    return true
  }

  phone = phone.replace(/[^0-9]/g, '')
  if (phone.length < 8 || phone.length > 15) {
    return false
  }
  if (phone.slice(0, 2) === '00') {
    return false
  }
  return true
}

export const _getButtonErrors = (button, block, paymentCurrency) => {
  const errors = []
  if (button.$draft) {
    return errors
  }

  if (button.type == null) {
    errors.push({
      prop: 'type',
      message: l.getString(ErrorMessages.BUTTON_TYPE_REQUIRED),
      originalMessage: ErrorMessages.BUTTON_TYPE_REQUIRED.message,
    })
  }

  if (
    button.type !== ButtonType.SHARE &&
    block?.type !== BlockType.EMAIL_BUTTON &&
    block?.type !== BlockType.ONE_TIME_NOTIFY_REQUEST
  ) {
    // Hyperlinks
    if (block?.type === BlockType.EMAIL_TEXT) {
      if (getTextLength(button.caption) > HyperlinkMaxCaptionLength) {
        errors.push({
          prop: 'caption',
          message: l.getString(ErrorMessages.HYPERLINK_CAPTION_MAX_LENGTH),
          originalMessage: ErrorMessages.HYPERLINK_CAPTION_MAX_LENGTH.message,
        })
      }
    } else {
      if (!button.caption) {
        errors.push({
          prop: 'caption',
          message: l.getString(ErrorMessages.BUTTON_CAPTION_REQUIRED),
          originalMessage: ErrorMessages.BUTTON_CAPTION_REQUIRED.message,
        })
      } else if (button.caption.match(/(\r\n|\n|\r)/gm, '')) {
        errors.push({
          prop: 'caption',
          message: l.getString(ErrorMessages.BUTTON_CAPTION_TITLE_LINES_ALLOWED),
          originalMessage: ErrorMessages.BUTTON_CAPTION_TITLE_LINES_ALLOWED.message,
        })
      } else if (!button.isSmartLink && getTextLength(button.caption) > ButtonMaxCaptionLength) {
        errors.push({
          prop: 'caption',
          message: l.getString(ErrorMessages.BUTTON_CAPTION_MAX_LENGTH(ButtonMaxCaptionLength)),
          originalMessage: ErrorMessages.BUTTON_CAPTION_MAX_LENGTH(ButtonMaxCaptionLength).message,
        })
      }
    }
    if (button.image && button.image.uploadId) {
      errors.push({
        prop: 'image',
        message: l.getString(ErrorMessages.BUTTON_IMAGE_UPLOADING),
        originalMessage: ErrorMessages.BUTTON_IMAGE_UPLOADING.message,
      })
    }
  }

  switch (button.type) {
    case ButtonType.CONTENT: {
      const targetError = button.targetId == null && !button.isFallback
      const isInOTNRequestBlock = block?.type === BlockType.ONE_TIME_NOTIFY_REQUEST

      if (targetError && !isInOTNRequestBlock) {
        errors.push({
          prop: 'target',
          message: l.getString(ErrorMessages.BUTTON_CONTENT_REQUIRED),
          originalMessage: ErrorMessages.BUTTON_CONTENT_REQUIRED.message,
        })
      }
      break
    }

    case ButtonType.URL:
    case ButtonType.CTA_URL:
      if (!button.url) {
        if (block.type === BlockType.EMAIL_BUTTON) {
          errors.push({
            prop: 'url',
            message: l.getString(ErrorMessages.EMAIL_BUTTON_NO_URL),
            originalMessage: ErrorMessages.EMAIL_BUTTON_NO_URL.message,
          })
        } else if (block.type === BlockType.EMAIL_TEXT) {
          errors.push({
            prop: 'url',
            message: l.getString(ErrorMessages.EMAIL_LINK_NO_URL),
            originalMessage: ErrorMessages.EMAIL_LINK_NO_URL.message,
          })
        } else if (block.type === BlockType.EMAIL_IMAGE) {
          errors.push({
            prop: 'url',
            message: l.getString(ErrorMessages.EMAIL_IMAGE_NO_URL),
            originalMessage: ErrorMessages.EMAIL_IMAGE_NO_URL.message,
          })
        } else {
          errors.push({
            prop: 'url',
            message: l.getString(ErrorMessages.BUTTON_URL_REQUIRED),
            originalMessage: ErrorMessages.BUTTON_URL_REQUIRED.message,
          })
        }
      }

      if (button.url) {
        try {
          normalizeUrl(button.url, { stripWWW: false })
        } catch (e) {
          errors.push({
            prop: 'url',
            message: l.getString(ErrorMessages.BUTTON_URL_INVALID),
            originalMessage: ErrorMessages.BUTTON_URL_INVALID.message,
          })
        }
      }

      break
    case ButtonType.CALL:
      if (!isValidPhone(button.phone)) {
        errors.push({
          prop: 'phone',
          message: l.getString(ErrorMessages.BUTTON_PHONE_INVALID),
          originalMessage: ErrorMessages.BUTTON_PHONE_INVALID.message,
        })
      }
      break
    case ButtonType.ANSWER:
      if (!button.value && button.customValue) {
        errors.push({
          prop: 'value',
          message: l.getString(ErrorMessages.BUTTON_VALUE_REQUIRED),
          originalMessage: ErrorMessages.BUTTON_VALUE_REQUIRED.message,
        })
      }
      break
    case ButtonType.BUY: {
      if (!button.item_label) {
        errors.push({
          prop: 'item_label',
          message: l.getString(ErrorMessages.BUTTON_ITEM_LABEL_REQUIRED),
          originalMessage: ErrorMessages.BUTTON_ITEM_LABEL_REQUIRED.message,
        })
      }
      const formulaErrors = getFormulaErrors(button.item_price, paymentCurrency)

      if (formulaErrors.length > 0) {
        errors.push(...formulaErrors)
      }
      break
    }
    case ButtonType.FLOW: {
      if (!button.flowId) {
        errors.push({
          prop: 'flowId',
          message: l.getString(ErrorMessages.BUTTON_FLOW_REQUIRED),
          originalMessage: ErrorMessages.BUTTON_FLOW_REQUIRED.message,
        })
      }
      break
    }
    case ButtonType.WA_LIST_MESSAGE_BUTTON: {
      errors.push(...getWhatsAppListMessageButtonErrors(button))
      break
    }
  }
  return errors
}

const _getBlockLabel = (block) => {
  switch (block.type) {
    case BlockType.CARD:
      return 'Card'
    case BlockType.LIST_ITEM:
      return 'List item'
  }
  return 'Block'
}

const _getBlockErrors = (state, builderId, blockId, contentId) => {
  const builder = builderStateSelectors.getById(state, builderId)

  const block = entitySelectors.getBlockById(state, builderId, blockId)
  const parentBlock = entitySelectors.getParentBlock(state, builderId, blockId)
  const parentNode = entitySelectors.getNodeById(state, builderId, contentId)

  const nodesMap = getNodesMap(state, builderId)
  const nodes = Object.values(nodesMap)
  const hasParentNodeOtherNodeConnection = nodes.some((node) => {
    return node.targetId === parentNode.id
  })

  let ordinaryBlocks = []
  if (
    parentNode.nodeType === NodeType.CONTENT ||
    parentNode.nodeType === NodeType.INSTAGRAM ||
    parentNode.nodeType === NodeType.TELEGRAM ||
    parentNode.nodeType === NodeType.WHATS_APP
  ) {
    ordinaryBlocks = nodeSelectors.getOrdinaryBlocks(state, builderId, contentId)
  }

  const ordinaryBlockIds = ordinaryBlocks.map((c) => c.id)
  const errors = []

  const buttons = entitySelectors.getButtons(state, builderId, blockId)
  const buyButtons = buttons.filter((btn) => btn.type === ButtonType.BUY)

  if (buyButtons.length > 1) {
    errors.push({
      prop: 'buttons',
      message: l.getString(ErrorMessages.BLOCK_MULTIPLE_BUY_BUTTONS),
      originalMessage: ErrorMessages.BLOCK_MULTIPLE_BUY_BUTTONS.message,
    })
  }

  const reason = getReasonById(block.reasonId)(state)

  switch (block.type) {
    case BlockType.TEXT:
      if (
        !block.text &&
        !hasParentNodeOtherNodeConnection &&
        parentNode?.blocks?.length === MAX_COUNT_BLOCKS_TO_SHOW_EMPTY_TEXT_ERROR
      ) {
        errors.push({
          prop: 'text',
          message: l.getString(ErrorMessages.BLOCK_WITHOUT_CONNECTION_TEXT_REQUIRED),
          originalMessage: ErrorMessages.BLOCK_WITHOUT_CONNECTION_TEXT_REQUIRED.message,
        })
      } else if (!block.text) {
        errors.push({
          prop: 'text',
          message: l.getString(ErrorMessages.BLOCK_TEXT_REQUIRED),
          originalMessage: ErrorMessages.BLOCK_TEXT_REQUIRED.message,
        })
      } else {
        let maxLength = TEXT_BLOCK_TEXT_MAX_LENGTH
        if (block.buttons.length) {
          maxLength = TEXT_BLOCK_WITH_BUTTONS_TEXT_MAX_LENGTH
        }
        if (parentNode.nodeType === NodeType.INSTAGRAM) {
          maxLength = INSTAGRAM_TEXT_BLOCK_TEXT_MAX_LENGTH
        }
        if (parentNode.nodeType === NodeType.TELEGRAM) {
          maxLength = TELEGRAM_TEXT_BLOCK_TEXT_MAX_LENGTH
        }
        if (builder.config.isSimple) {
          maxLength = SIMPLE_BUILDER_TEXT_BLOCK_TEXT_MAX_LENGTH
        }
        if (getTextLength(block.text) > maxLength) {
          errors.push({
            prop: 'text',
            message: l.getString(ErrorMessages.BLOCK_TEXT_MAX_LENGTH(maxLength)),
            originalMessage: ErrorMessages.BLOCK_TEXT_MAX_LENGTH(maxLength).message,
          })
        }
      }

      break
    case BlockType.ATTACHMENT:
      if (block.attachment == null) {
        errors.push({
          prop: 'attachment',
          message: l.getString(ErrorMessages.BLOCK_ATTACHMENT_REQUIRED),
          originalMessage: ErrorMessages.BLOCK_ATTACHMENT_REQUIRED.message,
        })
      } else if (block.attachment.uploadId) {
        errors.push({
          prop: 'attachment',
          message: l.getString(ErrorMessages.BLOCK_ATTACHMENT_UPLOADING),
          originalMessage: ErrorMessages.BLOCK_ATTACHMENT_UPLOADING.message,
        })
      } else if (
        parentNode.nodeType === NodeType.SMS &&
        block.attachmentType === AttachmentBlockAttachmentType.IMAGE &&
        get(block, 'attachment.image_size.size', 0) > SMS_NODE_IMAGE_MAX_SIZE
      ) {
        errors.push({
          prop: 'attachment',
          message: l.getString(ErrorMessages.SMS_NODE_IMAGE_TOO_BIG),
          originalMessage: ErrorMessages.SMS_NODE_IMAGE_TOO_BIG.message,
        })
      } else if (
        block.attachment.type === AttachmentBlockAttachmentType.EXTERNAL_IMAGE &&
        !block.attachment.image_url
      ) {
        errors.push({
          prop: 'image_url',
          message: l.getString(ErrorMessages.DYNAMIC_IMAGE_URL),
          originalMessage: ErrorMessages.DYNAMIC_IMAGE_URL.message,
        })
      }
      break
    case BlockType.LIST:
      if (block.blocks.length < 2) {
        errors.push({
          prop: 'blocks',
          message: l.getString(ErrorMessages.BLOCK_LIST_MIN_ITEMS),
          originalMessage: ErrorMessages.BLOCK_LIST_MIN_ITEMS.message,
        })
      }
      break
    case BlockType.LIST_ITEM:
      if (!trim(block.title)) {
        errors.push({
          prop: 'title',
          message: l.getString(ErrorMessages.BLOCK_LIST_ITEM_TITLE_REQUIRED),
          originalMessage: ErrorMessages.BLOCK_LIST_ITEM_TITLE_REQUIRED.message,
        })
      } else if (block.title.match(/(\r\n|\n|\r)/gm, '')) {
        errors.push({
          prop: 'title',
          message: l.getString(ErrorMessages.BLOCK_LIST_ITEM_TITLE_LINES_ALLOWED),
          originalMessage: ErrorMessages.BLOCK_LIST_ITEM_TITLE_LINES_ALLOWED.message,
        })
      } else if (getTextLength(block.title) > ListMaxTitleLength) {
        errors.push({
          prop: 'title',
          message: l.getString(ErrorMessages.BLOCK_LIST_ITEM_TITLE_MAX_LENGTH),
          originalMessage: ErrorMessages.BLOCK_LIST_ITEM_TITLE_MAX_LENGTH.message,
        })
      }
      if (
        !block.subtitle &&
        Object.keys(block.image || {}).length === 0 &&
        block.buttons.length === 0
      ) {
        errors.push({
          prop: 'subtitle',
          message: l.getString(ErrorMessages.BLOCK_LIST_ITEM_INFO_REQUIRED),
          originalMessage: ErrorMessages.BLOCK_LIST_ITEM_INFO_REQUIRED.message,
        })
      }
      if (block.subtitle && getTextLength(block.subtitle) > ListMaxSubtitleLength) {
        errors.push({
          prop: 'subtitle',
          message: l.getString(ErrorMessages.BLOCK_LIST_ITEM_SUBTITLE_MAX_LENGTH),
          originalMessage: ErrorMessages.BLOCK_LIST_ITEM_SUBTITLE_MAX_LENGTH.message,
        })
      }
      if (
        parentBlock.blocks[0] === blockId &&
        parentBlock.topElementStyle === 'large' &&
        Object.keys(block.image || {}).length === 0
      ) {
        errors.push({
          prop: 'text',
          message: l.getString(ErrorMessages.BLOCK_LIST_ITEM_IMAGE_REQUIRED),
          originalMessage: ErrorMessages.BLOCK_LIST_ITEM_IMAGE_REQUIRED.message,
        })
      }
      break
    case BlockType.CARD:
      if (!trim(block.title)) {
        errors.push({
          prop: 'title',
          message: l.getString(ErrorMessages.BLOCK_CARD_TITLE_REQUIRED),
          originalMessage: ErrorMessages.BLOCK_CARD_TITLE_REQUIRED.message,
        })
      } else if (getTextLength(block.title) > CardMaxTitleLength) {
        errors.push({
          prop: 'title',
          message: l.getString(ErrorMessages.BLOCK_CARD_TITLE_MAX_LENGTH),
          originalMessage: ErrorMessages.BLOCK_CARD_TITLE_MAX_LENGTH.message,
        })
      } else if (block.title.match(/(\r\n|\n|\r)/gm, '')) {
        errors.push({
          prop: 'title',
          message: l.getString(ErrorMessages.BLOCK_CARD_TITLE_LINES_ALLOWED),
          originalMessage: ErrorMessages.BLOCK_CARD_TITLE_LINES_ALLOWED.message,
        })
      }

      if (
        !trim(block.subtitle) &&
        Object.keys(block.image || {}).length === 0 &&
        block.buttons.length === 0
      ) {
        errors.push({
          prop: 'subtitle',
          message: l.getString(ErrorMessages.BLOCK_CARD_INFO_REQUIRED),
          originalMessage: ErrorMessages.BLOCK_CARD_INFO_REQUIRED.message,
        })
      }
      if (block.subtitle && getTextLength(block.subtitle) > CardMaxSubtitleLength) {
        errors.push({
          prop: 'subtitle',
          message: l.getString(ErrorMessages.BLOCK_CARD_SUBTITLE_MAX_LENGTH),
          originalMessage: ErrorMessages.BLOCK_CARD_SUBTITLE_MAX_LENGTH.message,
        })
      }
      if (
        block.image?.type === AttachmentBlockAttachmentType.EXTERNAL_IMAGE &&
        !block.image.image_url
      ) {
        errors.push({
          prop: 'image_url',
          message: l.getString(ErrorMessages.DYNAMIC_IMAGE_URL),
          originalMessage: ErrorMessages.DYNAMIC_IMAGE_URL.message,
        })
      }
      break
    case BlockType.FORM_QUESTION:
      if (!block.text) {
        errors.push({
          prop: 'text',
          message: l.getString(ErrorMessages.BLOCK_QUESTION_TEXT_REQUIRED),
          originalMessage: ErrorMessages.BLOCK_QUESTION_TEXT_REQUIRED.message,
        })
      } else if (getTextLength(block.text) > FORM_QUESTION_BLOCK_TEXT_MAX_LENGTH) {
        errors.push({
          prop: 'text',
          message: l.getString(
            ErrorMessages.BLOCK_TEXT_MAX_LENGTH(FORM_QUESTION_BLOCK_TEXT_MAX_LENGTH),
          ),
          originalMessage: ErrorMessages.BLOCK_TEXT_MAX_LENGTH(FORM_QUESTION_BLOCK_TEXT_MAX_LENGTH)
            .message,
        })
      }
      if (block.replyType === QuestionBlockReplyType.MULTIPLE_CHOICE) {
        if (block.buttons.length < 1) {
          errors.push({
            prop: 'buttons',
            message: l.getString(ErrorMessages.BLOCK_FORM_QUESTION_MIN_PREDEFINED),
            originalMessage: ErrorMessages.BLOCK_FORM_QUESTION_MIN_PREDEFINED.message,
          })
        }
      } else {
        if (block.buttons.length > 0) {
          errors.push({
            prop: 'buttons',
            message: l.getString(ErrorMessages.BLOCK_FORM_QUESTION_REPLY_TYPE_NO_PREDEFINED),
            originalMessage: ErrorMessages.BLOCK_FORM_QUESTION_REPLY_TYPE_NO_PREDEFINED.message,
          })
        }
      }
      if (
        [QuestionBlockReplyType.DATE_TIME, QuestionBlockReplyType.DATE].includes(block.replyType)
      ) {
        if (block.buttonCaption.length < 1 && parentNode.nodeType !== NodeType.TELEGRAM) {
          errors.push({
            prop: 'buttonCaption',
            message: l.getString(ErrorMessages.BLOCK_FORM_QUESTION_BUTTON_CAPTION_REQUIRED),
            originalMessage: ErrorMessages.BLOCK_FORM_QUESTION_BUTTON_CAPTION_REQUIRED.message,
          })
        }
        if (block.skipButtonCaption.length < 1) {
          errors.push({
            prop: 'skipButtonCaption',
            message: l.getString(
              ErrorMessages.BLOCK_FORM_QUESTION_BUTTON_SKIP_BUTTON_CAPTION_REQUIRED,
            ),
            originalMessage:
              ErrorMessages.BLOCK_FORM_QUESTION_BUTTON_SKIP_BUTTON_CAPTION_REQUIRED.message,
          })
        }
      }
      if (block.replyType === QuestionBlockReplyType.MC_LOCATION) {
        if (block.buttonCaption.length < 1) {
          errors.push({
            prop: 'buttonCaption',
            message: l.getString(
              ErrorMessages.BLOCK_FORM_QUESTION_MC_LOCATION_BUTTON_CAPTION_REQUIRED,
            ),
            originalMessage:
              ErrorMessages.BLOCK_FORM_QUESTION_MC_LOCATION_BUTTON_CAPTION_REQUIRED.message,
          })
        }
        if (block.skipButtonCaption.length < 1) {
          errors.push({
            prop: 'skipButtonCaption',
            message: l.getString(
              ErrorMessages.BLOCK_FORM_QUESTION_MC_LOCATION_BUTTON_SKIP_BUTTON_CAPTION_REQUIRED,
            ),
            originalMessage:
              ErrorMessages.BLOCK_FORM_QUESTION_MC_LOCATION_BUTTON_SKIP_BUTTON_CAPTION_REQUIRED
                .message,
          })
        }
      }
      break
    case BlockType.DELAY:
      if (ordinaryBlockIds[ordinaryBlockIds.length - 1] === block.id) {
        errors.push({
          prop: 'text',
          message: l.getString(ErrorMessages.BLOCK_DELAY_POSITION_ERROR),
          originalMessage: ErrorMessages.BLOCK_DELAY_POSITION_ERROR.message,
        })
      }
      break
    case BlockType.QUICK_REPLY:
      {
        if (block.buttons.length > 0) {
          const lastBlock = ordinaryBlocks.slice(-1)[0]
          if (lastBlock && lastBlock.type === BlockType.FORM_QUESTION) {
            errors.push({
              prop: 'text',
              message: l.getString(ErrorMessages.BLOCK_QR_AFTER_QUESTION),
              originalMessage: ErrorMessages.BLOCK_QR_AFTER_QUESTION.message,
            })
          }
          if (parentNode.nodeType === NodeType.INSTAGRAM && lastBlock?.type !== BlockType.TEXT) {
            errors.push({
              prop: 'text',
              message: l.getString(ErrorMessages.INSTAGRAM_BLOCK_QR_NOT_VALID),
              originalMessage: ErrorMessages.INSTAGRAM_BLOCK_QR_NOT_VALID.message,
            })
          }
          if (
            parentNode.nodeType === NodeType.INSTAGRAM &&
            lastBlock?.type === BlockType.TEXT &&
            lastBlock?.buttons.length
          ) {
            errors.push({
              prop: 'text',
              message: l.getString(ErrorMessages.INSTAGRAM_BLOCK_QR_NOT_VALID),
              originalMessage: ErrorMessages.INSTAGRAM_BLOCK_QR_NOT_VALID.message,
            })
          }
        }
      }
      break
    case BlockType.TELEGRAM_KEYBOARD:
      {
        if (block.buttons.length > 0) {
          const lastBlock = ordinaryBlocks.slice(-1)[0]
          if (lastBlock && lastBlock.type === BlockType.FORM_QUESTION) {
            errors.push({
              prop: 'text',
              message: l.getString(ErrorMessages.TELEGRAM_KEYBOARD_BLOCK_AFTER_QUESTION),
              originalMessage: ErrorMessages.TELEGRAM_KEYBOARD_BLOCK_AFTER_QUESTION.message,
            })
          }
          if (lastBlock?.buttons?.length) {
            errors.push({
              prop: 'text',
              message: l.getString(ErrorMessages.TELEGRAM_KEYBOARD_BLOCK_AFTER_BUTTONS),
              originalMessage: ErrorMessages.TELEGRAM_KEYBOARD_BLOCK_AFTER_BUTTONS.message,
            })
          }
        }
      }
      break
    case BlockType.ONE_TIME_NOTIFY_REQUEST:
      {
        if (!block.reasonId || (block.reasonId && !reason)) {
          errors.push({
            prop: 'reasonId',
            message: l.getString(ErrorMessages.BLOCK_ONE_TIME_NOTIFY_REQUEST_REASON_ID_EMPTY),
            originalMessage: ErrorMessages.BLOCK_ONE_TIME_NOTIFY_REQUEST_REASON_ID_EMPTY.message,
          })
        }
        if (reason?.status === ReasonStatuses.ARCHIVE) {
          errors.push({
            prop: 'reasonStatus',
            message: l.getString(ErrorMessages.BLOCK_ONE_TIME_NOTIFY_REQUEST_REASON_ARCHIVED),
            originalMessage: ErrorMessages.BLOCK_ONE_TIME_NOTIFY_REQUEST_REASON_ARCHIVED.message,
          })
        }
        if (!block.title) {
          errors.push({
            prop: 'title',
            message: l.getString(ErrorMessages.BLOCK_REQUEST_TITLE_REQUIRED),
            originalMessage: ErrorMessages.BLOCK_REQUEST_TITLE_REQUIRED.message,
          })
        }
      }
      break
    case BlockType.EMAIL_BUTTON:
      {
        const maxLength = 50
        if (block.text.length >= maxLength) {
          errors.push({
            prop: 'text',
            message: l.getString(ErrorMessages.BUTTON_CAPTION_MAX_LENGTH(maxLength)),
            originalMessage: ErrorMessages.BUTTON_CAPTION_MAX_LENGTH(maxLength).message,
          })
        }
      }
      break
    case BlockType.EMAIL_TEXT:
      {
        if (!block.text) {
          errors.push({
            prop: 'text',
            message: l.getString(ErrorMessages.EMAIL_TEXT_BLOCK_EMPTY),
            originalMessage: ErrorMessages.EMAIL_TEXT_BLOCK_EMPTY.message,
          })
        }
      }
      break
    case BlockType.WA_LIST_MESSAGE:
      errors.push(...getWhatsAppListMessageBlockErrors(block))
      break
    case BlockType.WA_CATALOG_MESSAGE:
      if (!block.content.body || block.content.body?.trim() === '') {
        const errorMessage = ErrorMessages.WA_FIELD_EMPTY('Message body')
        errors.push(createValidationError('body', errorMessage))
      } else if (block.content.body.length > WA_CATALOG_MESSAGE_MAX_BODY_LENGTH) {
        const errorMessage = ErrorMessages.WA_FIELD_MAX_LENGTH(
          'Message body',
          WA_CATALOG_MESSAGE_MAX_BODY_LENGTH,
        )
        errors.push(createValidationError('body', errorMessage))
      }
      if (
        block.content?.footer &&
        block.content.footer.trim().length > WA_CATALOG_MESSAGE_MAX_FOOTER_LENGTH
      ) {
        const errorMessage = ErrorMessages.WA_FIELD_MAX_LENGTH(
          'Footer',
          WA_CATALOG_MESSAGE_MAX_FOOTER_LENGTH,
        )
        errors.push(createValidationError('footer', errorMessage))
      }
      break
  }
  return errors
}

const _validateActionGroup = (state, builderId, actionGroupId) => {
  const errors = {}
  const userFields = userFieldsSelectors.getItems(state)
  const customEvents = getCustomEventsItems(state)
  const actionGroupNode = entitySelectors.getNodeById(state, builderId, actionGroupId)
  const actionGroupErrors = getActionListErrors(actionGroupNode?.items || [], {
    userFields,
    customEvents,
  })

  const capiEventActionIndex = (actionGroupNode?.items || []).findIndex(
    (action) => action.type === ActionTypes.SEND_EVENT_TO_CAPI,
  )

  if (capiEventActionIndex > -1) {
    const capiEventAction = actionGroupNode.items[capiEventActionIndex]
    addCapiValidationErrors(actionGroupErrors, capiEventAction, capiEventActionIndex)
  }

  if (actionGroupErrors.length > 0) {
    errors[actionGroupId] = actionGroupErrors
  }
  return errors
}

const _validateButton = (state, builderId, buttonId, block) => {
  const errors = {}

  const button = entitySelectors.getButtonById(state, builderId, buttonId)
  const { paymentCurrency } = state.app

  let buttonErrors = _getButtonErrors(button, block, paymentCurrency)

  if (buttonErrors.length > 0) {
    errors[buttonId] = buttonErrors
  }
  return errors
}

const _validateBlock = (state, builderId, blockId, contentId) => {
  const errors = {}

  const block = entitySelectors.getBlockById(state, builderId, blockId)
  const blockErrors = _getBlockErrors(state, builderId, blockId, contentId)

  for (let i = 0; i < block.buttons.length; i++) {
    const buttonId = block.buttons[i]
    const button = entitySelectors.getButtonById(state, builderId, buttonId)

    if (button.isSmartLink && !isValidSmartLinkButton(block, button)) {
      continue
    }

    const buttonErrors = _validateButton(state, builderId, buttonId, block)
    Object.assign(errors, buttonErrors)
    if (Object.keys(buttonErrors).length > 0) {
      blockErrors.push({
        type: 'inner',
        element: { id: buttonId, label: _getButtonLabel(block, button, i) },
        errors: buttonErrors[buttonId],
      })
    }
  }

  for (let i = 0; i < block.blocks.length; i++) {
    const childBlockId = block.blocks[i]
    const childBlockErrors = _validateBlock(state, builderId, childBlockId, contentId)
    Object.assign(errors, childBlockErrors)
    if (Object.keys(childBlockErrors).length > 0) {
      const childBlock = entitySelectors.getBlockById(state, builderId, childBlockId)
      let label = _getBlockLabel(childBlock)
      blockErrors.push({
        type: 'inner',
        element: { id: childBlockId, label: `${label} ${i + 1}` },
        errors: childBlockErrors[childBlockId],
      })
    }
  }

  if (blockErrors.length > 0) {
    errors[block.id] = blockErrors
  }
  return errors
}

const _validateCondition = (state, builderId, conditionId) => {
  let conditionErrors = []
  const errors = {}
  const blocksErrors = {}

  const conditionBlocks = entitySelectors.getBlocks(state, builderId, conditionId)
  const targets = compact(conditionBlocks.map((block) => block.targetId))
  if (!targets.length) {
    conditionErrors.push({
      prop: 'blocks',
      message: l.getString(ErrorMessages.CONDITION_TARGET_REQUIRED),
      originalMessage: ErrorMessages.CONDITION_TARGET_REQUIRED.message,
    })
  }

  conditionBlocks.forEach((block) => {
    const blockErrors = []
    const { id } = block

    const isConditionMissing = block.type === BlockType.CASE && !hasConditions(block.filter)

    if (isConditionMissing) {
      blockErrors.push({
        prop: 'blocks',
        message: l.getString(ErrorMessages.CONDITION_FILTER_REQUIRED),
        originalMessage: ErrorMessages.CONDITION_FILTER_REQUIRED.message,
      })
    }

    if (blockErrors.length) {
      blocksErrors[id] = blockErrors
    }
  })

  const formulaItems = conditionBlocks
    .map((block) =>
      (block.filter?.groups ?? []).reduce((items, formula) => items.concat(formula.items), []),
    )
    .reduce((allItems, blockItems) => allItems.concat(blockItems), [])

  const fields = getFlowConditionFields(state)
  const activeReasons = getActiveReasons(state)
  const isActiveReasonExist = (id) => activeReasons.some((reason) => reason.reason_id === id)
  const fieldExist = (id) => fields.some((f) => f.id === id)
  const pushConditionError = (message, originalMessage) =>
    conditionErrors.push({ prop: 'filter', message, originalMessage })

  for (let i = 0; i < formulaItems.length; i++) {
    const formulaItem = formulaItems[i]

    switch (formulaItem.type) {
      case 'tag':
        if (!get(segmentsSelectors.getById(state, `tag_${formulaItem.value}`), 'active', false)) {
          pushConditionError(
            l.getString(ErrorMessages.CONDITION_TAG_MISSING),
            ErrorMessages.CONDITION_TAG_MISSING.message,
          )
        }

        break
      case 'cuf':
      case 'gaf':
        if (!fieldExist(formulaItem.field)) {
          pushConditionError(
            l.getString(ErrorMessages.CONDITION_FIELD_MISSING),
            ErrorMessages.CONDITION_FIELD_MISSING.message,
          )
        }

        break
      case FilterField.IG_RECURRING_NOTIFICATION_OPTIN:
      case FilterField.ONE_TIME_NOTIFICATION:
        if (!isActiveReasonExist(formulaItem.value)) {
          pushConditionError(
            l.getString(ErrorMessages.CONDITION_TOPIC_MISSING),
            ErrorMessages.CONDITION_TOPIC_MISSING.message,
          )
        }
        break
      default:
        break
    }
  }

  if (conditionErrors.length) {
    errors[conditionId] = conditionErrors
  }

  return { ...errors, ...blocksErrors }
}

const _validateGoto = (state, builderId, gotoId) => {
  const goto = entitySelectors.getNodeById(state, builderId, gotoId)
  const errors = {}
  const gtErrors = []

  if (!goto.flowId) {
    gtErrors.push({
      prop: 'flowId',
      message: l.translate('Please select Automation'),
      originalMessage: 'Please select Automation',
    })
  }

  if (gtErrors.length) {
    errors[gotoId] = gtErrors
  }

  return errors
}

const _validateSplit = (state, builderId, splitId) => {
  const nodeConfig = nodeRegistry.getByType(NodeType.SPLIT)
  const splitErrors = []
  const errors = {}
  const variantsErrors = {}
  const variants = entitySelectors.getBlocks(state, builderId, splitId)
  let totalPercent = 0

  if (variants.length < nodeConfig.ALLOWED_BLOCK_MIN_COUNT) {
    splitErrors.push({
      prop: 'nested_blocks_count',
      message: l.getString(ErrorMessages.SPLIT_VARIANTS_MIN_ITEMS),
      originalMessage: ErrorMessages.SPLIT_VARIANTS_MIN_ITEMS.message,
    })
    splitErrors.push({
      prop: 'nested_blocks_count',
      message: l.getString(ErrorMessages.SPLIT_VARIANTS_MIN_ITEMS),
      originalMessage: ErrorMessages.SPLIT_VARIANTS_MIN_ITEMS.message,
      for: ERROR_PANE,
    })
  }

  if (variants.length > nodeConfig.ALLOWED_BLOCK_MAX_COUNT) {
    splitErrors.push({
      prop: 'nested_blocks_count',
      message: l.getString(ErrorMessages.SPLIT_VARIANTS_MIN_ITEMS),
      originalMessage: ErrorMessages.SPLIT_VARIANTS_MIN_ITEMS.message,
    })
    splitErrors.push({
      prop: 'nested_blocks_count',
      message: l.getString(ErrorMessages.SPLIT_VARIANTS_MAX_ITEMS),
      originalMessage: ErrorMessages.SPLIT_VARIANTS_MAX_ITEMS.message,
      for: ERROR_PANE,
    })
  }

  variants.forEach((variant) => {
    const variantErrors = []
    const { percent, title, id } = variant

    totalPercent += Number(percent)
    if (!variant.targetId) {
      variantErrors.push({
        type: 'target',
        element: { id, label: `Variant ${title}` },
        message: l.getString(ErrorMessages.SPLIT_TARGET_REQUIRED),
        originalMessage: ErrorMessages.SPLIT_TARGET_REQUIRED.message,
      })
    }
    if (variantErrors.length) {
      variantsErrors[id] = variantErrors
    }
  })

  if (totalPercent !== 100) {
    splitErrors.push({
      prop: 'distribution_total',
      message: l.getString(ErrorMessages.SPLIT_DISTRIBUTION_TOTAL),
      originalMessage: ErrorMessages.SPLIT_DISTRIBUTION_TOTAL.message,
    })
  }

  if (splitErrors.length) {
    errors[splitId] = splitErrors
  }

  return Object.assign({}, errors, variantsErrors)
}

export const _validateSmartDelay = (state, builderId, itemId) => {
  const item = entitySelectors.getNodeById(state, builderId, itemId)
  const smartDelayType = item.waitUntil.value ? DelayType.WAIT_UNTIL : DelayType.DURATION
  const errors = {}
  const itemErrors = []

  if (smartDelayType === DelayType.WAIT_UNTIL) {
    const currentDate = new Date()

    if (currentDate.valueOf() >= new Date(item.waitUntil.value).valueOf()) {
      itemErrors.push({
        prop: 'waitUntil',
        message: l.translate('Wait until should not be in the past'),
        originalMessage: 'Wait until should not be in the past',
      })
    }
  }

  if (smartDelayType === DelayType.DURATION) {
    if (item.delayValue == null || `${item.delayValue}`.trim().length === 0) {
      itemErrors.push({
        prop: 'delayValue',
        message: l.getString(ErrorMessages.SMART_DELAY_DURATION_VALUE_REQUIRED),
        originalMessage: ErrorMessages.SMART_DELAY_DURATION_VALUE_REQUIRED.message,
      })
    }

    if (item.delayUnits === 'seconds') {
      if (item.delayValue < 10 || item.delayValue > 3600) {
        itemErrors.push({
          prop: 'delayValue',
          message: l.translate('Delay duration in seconds must be between 10 and 3600'),
          originalMessage: 'Delay duration in seconds must be between 10 and 3600',
        })
      }
    }

    if (item.delayUnits === 'days') {
      if (item.delayValue > 365) {
        itemErrors.push({
          prop: 'delayValue',
          message: l.translate('Delay duration in days must be less than 365'),
          originalMessage: 'Delay duration in days must be less than 365',
        })
      }
    }

    if (item.delayValue !== null) {
      const formulaErrors = getFormulaErrors(item.delayValue)
      itemErrors.push(...formulaErrors)
    }
  }

  if (itemErrors.length) {
    errors[itemId] = itemErrors
  }

  return errors
}

export const _validateSms = (state, builderId, contentId) => {
  const errors = {}
  const content = entitySelectors.getNodeById(state, builderId, contentId)
  const contentErrors = []

  if (content.blocks < 1) {
    contentErrors.push({
      prop: 'blocks',
      message: l.translate('Please create at least one SMS message'),
      originalMessage: 'Please create at least one SMS message',
    })
  }

  for (let i = 0; i < content.blocks.length; i++) {
    const blockId = content.blocks[i]
    const blockErrors = _validateBlock(state, builderId, blockId, contentId)
    Object.assign(errors, blockErrors)
    if (Object.keys(blockErrors).length > 0) {
      contentErrors.push({
        type: 'inner',
        element: { id: blockId, label: `Block ${i + 1}` },
        errors: blockErrors[blockId],
      })
    }
  }

  if (contentErrors.length > 0) {
    errors[content.id] = contentErrors
  }

  return errors
}

// back _
export const _validateEmail = (state, builderId, contentId) => {
  const errors = {}
  const node = entitySelectors.getNodeById(state, builderId, contentId)
  const contentErrors = []

  if (node.nodeType === NodeType.EMAIL_NEW) {
    if (!node.data?.subject) {
      contentErrors.push({
        prop: 'email_subject',
        message: l.translate('Please add Subject'),
        originalMessage: 'Please add Subject',
      })
    }
    if (!node.data?.preheader) {
      contentErrors.push({
        prop: 'email_preheader',
        message: l.translate('Please add Preheader'),
        originalMessage: 'Please add Preheader',
      })
    }
  }

  if (node.blocks < 1) {
    contentErrors.push({
      prop: 'blocks',
      message: l.translate('Please create at least one Email block'),
      originalMessage: 'Please create at least one Email block',
    })
  }

  for (let i = 0; i < node.blocks.length; i++) {
    const blockId = node.blocks[i]
    const blockErrors = _validateBlock(state, builderId, blockId, contentId)
    Object.assign(errors, blockErrors)
    if (Object.keys(blockErrors).length > 0) {
      contentErrors.push({
        type: 'inner',
        element: { id: blockId, label: `Block ${i + 1}` },
        errors: blockErrors[blockId],
      })
    }
  }

  if (contentErrors.length > 0) {
    errors[node.id] = contentErrors
  }

  return errors
}

export const validateContent = (state, builderId, contentId, options = {}) => {
  const errors = {}
  const content = entitySelectors.getNodeById(state, builderId, contentId)
  const ordinaryBlocks = nodeSelectors.getOrdinaryBlocks(state, builderId, contentId)
  const contentErrors = []

  if (ordinaryBlocks.length < 1) {
    contentErrors.push({
      prop: 'blocks',
      message: l.translate('Please create at least one message'),
      originalMessage: 'Please create at least one message',
    })
  }

  const isBroadcastFlow = options.enableMessageTagValidation !== undefined

  if (
    content.$fbMessagingType === FBMessagingType.OUTSIDE_24_HOURS &&
    !content.messageTag &&
    !content.reasonId
  ) {
    if (!isBroadcastFlow) {
      contentErrors.push({
        prop: 'reason',
        message: l.translate(
          'If you want to send a message outside 24 hours, you have to choose Reason',
        ),
        originalMessage:
          'If you want to send a message outside 24 hours, you have to choose Reason',
      })
    }

    if (
      isBroadcastFlow &&
      options.enableMessageTagValidation &&
      options.rootPreInteractionZoneContentIds.includes(content.id)
    ) {
      contentErrors.push({
        prop: 'reason',
        message: l.translate('Please select message tag or one time notify reason'),
        originalMessage: 'Please select message tag or one time notify reason',
      })
    }
  }

  let count = 0
  let delaysInARowCount = 0

  for (let block of ordinaryBlocks) {
    if (block.type === BlockType.DELAY) {
      delaysInARowCount++
      if (delaysInARowCount > DefaultContentDelayBlockInARowMaxCount) {
        contentErrors.push({
          prop: 'blocks',
          message: l.translate(
            `You cannot use more than {DefaultContentDelayBlockInARowMaxCount} delay blocks in a row`,
            { DefaultContentDelayBlockInARowMaxCount },
          ),
          originalMessage: `You cannot use more than ${DefaultContentDelayBlockInARowMaxCount} delay blocks in a row`,
        })
        break
      }
      continue
    }
    delaysInARowCount = 0
    if (block.type === BlockType.FORM_QUESTION) {
      count = 0
      continue
    }
    count++
    if (count > DefaultContentOrdinaryBlockInARowMaxCount) {
      contentErrors.push({
        prop: 'blocks',
        message: l.translate(
          `You cannot use more than {DefaultContentOrdinaryBlockInARowMaxCount} message blocks in a row`,
          { DefaultContentOrdinaryBlockInARowMaxCount },
        ),
        originalMessage: `You cannot use more than ${DefaultContentOrdinaryBlockInARowMaxCount} message blocks in a row`,
      })
      break
    }
  }
  for (let i = 0; i < content.blocks.length; i++) {
    const blockId = content.blocks[i]
    const blockErrors = _validateBlock(state, builderId, blockId, contentId)
    Object.assign(errors, blockErrors)
    if (Object.keys(blockErrors).length > 0) {
      contentErrors.push({
        type: 'inner',
        element: { id: blockId, label: `Block ${i + 1}` },
        errors: blockErrors[blockId],
      })
    }
  }

  if (contentErrors.length > 0) {
    errors[content.id] = contentErrors
  }
  return errors
}

export const validateNode = (state, builderId, nodeId) => {
  const builder = builderStateSelectors.getById(state, builderId)
  const rootPreInteractionZoneIgnoreConditionTargets =
    builderStateSelectors.getRootPreInteractionZoneIgnoreConditionTargets(state, builderId)
  const rootPreInteractionZoneContents = rootPreInteractionZoneIgnoreConditionTargets.filter(
    (node) => node.nodeType === NodeType.CONTENT,
  )

  const enableMessageTagValidation = builder.config.isMessageTagValidationEnabled?.(state)

  const options = {
    enableMessageTagValidation,
    rootPreInteractionZoneContentIds: rootPreInteractionZoneContents.map((node) => node.id),
  }
  const instagramOptions = {
    enableMessageTagValidation: accountFlags.isEnabled(AccountFlag.ENABLE_IG_RECURRING_NOTIFICATION)
      ? enableMessageTagValidation
      : false,
    rootPreInteractionZoneContentIds: [],
  }

  const node = entitySelectors.getNodeById(state, builderId, nodeId)

  if (!node) {
    return {}
  }

  if (node.nodeType === NodeType.CONTENT) {
    return validateContent(state, builderId, nodeId, options)
  }
  if (node.nodeType === NodeType.INSTAGRAM) {
    return validateContent(state, builderId, nodeId, instagramOptions)
  }
  if (node.nodeType === NodeType.TELEGRAM) {
    return validateContent(state, builderId, nodeId, {})
  }
  if (node.nodeType === NodeType.WHATS_APP) {
    return validateContent(state, builderId, nodeId, {})
  }
  if (node.nodeType === NodeType.TIKTOK) {
    return validateContent(state, builderId, nodeId, {})
  }
  if (node.nodeType === NodeType.ACTION_GROUP) {
    return _validateActionGroup(state, builderId, nodeId)
  }
  if (node.nodeType === NodeType.CONDITION) {
    return _validateCondition(state, builderId, nodeId)
  }
  if (node.nodeType === NodeType.GOTO) {
    return _validateGoto(state, builderId, nodeId)
  }
  if (node.nodeType === NodeType.SPLIT) {
    return _validateSplit(state, builderId, nodeId)
  }
  if (node.nodeType === NodeType.SMART_DELAY) {
    return _validateSmartDelay(state, builderId, nodeId)
  }
  if (node.nodeType === NodeType.SMS) {
    return _validateSms(state, builderId, nodeId)
  }
  if (node.nodeType === NodeType.EMAIL_NEW) {
    return _validateEmail(state, builderId, nodeId)
  }
  if (node.nodeType === NodeType.AI_AGENT) {
    return validationSelectorsTyped.validateAgentNode(state, builderId, nodeId)
  }
  return {}
}

function createValidationError(prop, error) {
  return {
    prop,
    message: l.getString(error),
    originalMessage: error.message,
  }
}

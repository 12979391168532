import React, { ReactNode, useMemo, useState } from 'react'
import cx from 'classnames'
import { z } from 'zod'
import { BtnV2, Icon, l, palettes } from '@manychat/manyui'

import { BlockType } from '../../constants'
import { useViewContext } from '../../context'
import { useCart } from '../../hooks'
import { registry } from '../../registry'
import { isProductPlan } from '../../schemas'
import { BlockProps } from '../../types'
import { pick } from '../../utils/pick'

import minus from './assets/minus.svg'
import tick from './assets/tick.svg'
import { COMPARING_MAP, TABLE_COLUMNS_COUNT } from './constants'
import { ComparingProductsTableBenefit, ComparingTableBenefit } from './interfaces'
import { ProductTableLegacyContextSchema } from './ProductTableLegacyContextSchema'

import cm from './ProductTableLegacy.module.css'

const ProductTableLegacyPropsSchema = z.object({})

const Component = ({ blockId }: BlockProps<typeof ProductTableLegacyPropsSchema>) => {
  const { sendEvent } = useViewContext(ProductTableLegacyContextSchema)
  const { offer } = useCart()
  const plans = useMemo(() => offer.filter(isProductPlan), [offer])
  const planSlugs = useMemo(() => plans.map((plan) => plan.slug), [plans])

  const [isFullTableVisible, setFullTableVisibility] = useState(false)
  const tableVisiblePart = isFullTableVisible ? COMPARING_MAP : [COMPARING_MAP[0]]

  const toggleFullTableVisibility = () => {
    sendEvent(`PRO_MODAL.SHOW_ALL_FEATURES`)
    setFullTableVisibility(!isFullTableVisible)
  }

  function getIconOrText(content: ReactNode) {
    switch (content) {
      case '+':
        return <img src={tick} alt="tick" />
      case '-':
        return <img className="m-b-xxs" src={minus} alt="minus" />
      default:
        return content
    }
  }

  const renderBenefit = (
    benefit: ComparingProductsTableBenefit | ComparingTableBenefit,
    index: number,
  ) => {
    const { isSubtitle, altText, plan, free } = benefit

    let proPlanInfo

    if ('pro' in benefit) {
      proPlanInfo = (
        <>
          <td className="p-x-md p-y-xs text-center font-semibold">
            {getIconOrText(benefit.pro())}
          </td>
        </>
      )
    }

    if ('automation' in benefit) {
      const plans = pick<ComparingProductsTableBenefit, 'inbox' | 'automation'>(benefit, planSlugs)
      proPlanInfo = (
        <>
          {Object.entries(plans).map(([plan, renderBenefit]) => (
            <td key={plan} className="p-x-md p-y-xs text-center font-semibold">
              {getIconOrText(renderBenefit())}
            </td>
          ))}
        </>
      )
    }

    return (
      <tr key={`tr_benefits_${index}`}>
        <td className="p-x-md p-y-xs">
          <div
            className={cx('d-inline-block', {
              'text-subheading': isSubtitle,
            })}
            style={{ verticalAlign: 'middle' }}
          >
            {plan()}
          </div>
          {altText && (
            <Icon.Question
              style={{ verticalAlign: 'middle' }}
              className="m-l-xxs d-inline-block"
              data-title={altText()}
              color={palettes.typography.secondary}
              size={18}
            />
          )}
        </td>
        <td className="p-x-md p-y-xs text-center text-secondary d-none md-d-table-cell">
          {getIconOrText(free())}
        </td>
        {proPlanInfo}
      </tr>
    )
  }

  return (
    <table
      data-block={blockId}
      id="product_table"
      className="bg-default rounded"
      style={{ width: '100%' }}
    >
      <thead className={cx('text-heading-2', cm.productsTableHead)}>
        <tr>
          <th className="text-left p-t-lg p-x-md p-b-0">
            <div className="d-none md-d-block">{l.translate('Compare plans')}</div>
            <div className="d-block md-d-none">{l.translate('Top features')}</div>
          </th>
          <th className="p-t-lg p-x-md d-none md-d-table-cell">{l.translate('Free')}</th>

          {plans.map((product) => (
            <th key={product.slug} className="p-t-lg p-x-md">
              {product.name}
            </th>
          ))}
        </tr>
      </thead>
      <tbody className="bg-default">
        <tr>
          <td colSpan={TABLE_COLUMNS_COUNT}>
            <div className="b-b m-x-md" style={{ height: '14px' }}>
              &nbsp;
            </div>
          </td>
        </tr>
        {tableVisiblePart.map(({ feature, benefits }, i) => (
          <React.Fragment key={`tr_${i}`}>
            <tr>
              <td
                className="text-secondary text-subheading p-x-md p-b p-t-md"
                colSpan={TABLE_COLUMNS_COUNT}
              >
                {feature()}
              </td>
            </tr>
            {benefits.map(renderBenefit)}
            <tr>
              <td colSpan={TABLE_COLUMNS_COUNT}>
                <div style={{ height: '0' }} className="b-b m-x-md p-y-xs">
                  &nbsp;
                </div>
              </td>
            </tr>
          </React.Fragment>
        ))}
        <tr>
          <td colSpan={TABLE_COLUMNS_COUNT}>
            <div className="d-flex flex-wrap-reverse md-flex-wrap text-center md-text-left m-t md-m-t-0">
              <div className="col-12 md-col-6 md-p-y-md p-x-md p-b-md">
                <BtnV2 onClick={toggleFullTableVisibility} size="large">
                  {isFullTableVisible ? l.translate('Hide table') : l.translate('View full table')}
                </BtnV2>
              </div>
              <div className="col-12 md-col-6 md-p-y-md p-x-md p-b-md md-text-right" />
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  )
}

export const ProductTableLegacy = registry.register(Component, {
  props: ProductTableLegacyPropsSchema,
  context: ProductTableLegacyContextSchema,
  type: BlockType.PRODUCT_TABLE_LEGACY,
})

import { l } from '@manychat/manyui'

import { alert } from 'common/core'
import {
  BusinessError,
  isBusinessError,
  isResponseError,
  ResponseError,
} from 'shared/api/lib/errors/business'
import { reportUnhandledErrors } from 'shared/api/lib/errors/utils'
import errorTrackingService from 'utils/services/errorTrackingService'

type CatchHandler = (error: unknown, onBusinessErrors?: (errors: ResponseError) => void) => void

const errorMessage = l.makeTranslate('Something went wrong on the server. Please try again.')

export const handleCatch: CatchHandler = (error, onBusinessErrors) => {
  if (!isResponseError(error)) {
    errorTrackingService.trackError(error)
    alert(l.getString(errorMessage), 'danger')
    return
  }

  if (onBusinessErrors) {
    onBusinessErrors(error)
    return
  }

  reportUnhandledErrors(error.$errors, error.endpoint)
}

export interface BusinessErrorWithHandle extends BusinessError {
  handle: VoidFunction
}

const onExceptionByTimeout = (error: BusinessError, endpoint?: string) => () => {
  alert(error.message, 'danger')
  errorTrackingService.trackUnhandledErrors([error], endpoint)
}

const onHandle = (timerId: number) => () => {
  window.clearTimeout(timerId)
}

export const handleError = (
  err: unknown,
  onBusinessError?: (error: BusinessErrorWithHandle) => void,
) => {
  if (!isResponseError(err) && !isBusinessError(err)) {
    errorTrackingService.trackError(err)
    alert(l.translate('Something went wrong on the server. Please try again.'), 'danger')
    return
  }

  // We take the first error in the list because in most cases we have one error in response.
  const error = isResponseError(err) ? err.$errors[0] : err

  if (onBusinessError) {
    const endpoint = isResponseError(err) ? err.endpoint : undefined
    const timerId = window.setTimeout(onExceptionByTimeout(error, endpoint), 100)
    const extendedError: BusinessErrorWithHandle = {
      ...error,
      handle: onHandle(timerId),
    }

    onBusinessError(extendedError)
    return
  }

  if (isResponseError(err)) {
    reportUnhandledErrors(err.$errors, err.endpoint)
  }
}

import { ChannelType, FlowType } from 'common/core/constants/ChannelType'

export enum StrategyIds {
  WELCOME_CUSTOMERS = 'welcome_customers',
  COLLECT_PHONES_AND_EMAILS = 'collect_phones_and_emails',
  SEND_PERSONALIZED_OFFERS = 'send_personalized_offers',
  EXCLUSIVE_OFFER_FOR_FACEBOOK = 'exclusive_offer_for_facebook',
  FACEBOOK_AD_LEADS_SIMPLE = 'facebook_ad_leads_simple',
  FACEBOOK_AD_LEADS = 'facebook_ad_leads',
  PRODUCT_OFFERINGS = 'product_offerings',
  SEND_EVENT_REMINDERS = 'send_event_reminders',
  AUTOMATE_SUPPORT = 'automate_support',
  CUSTOMER_INFO_FROM_GOOGLE_SHEETS = 'customer_info_from_google_sheets',
  EASY_BUILDER_IG_CGT_ENTRY_POINT = 'easy_builder_ig_cgt_entry_point',
  EASY_BUILDER_STORY_REPLY_ENTRY_POINT = 'easy_builder_story_reply_entry_point',
  EASY_BUILDER_IG_DIRECT_MESSAGE_ENTRY_POINT = 'easy_builder_ig_direct_message_entry_point',
  AI_STEP_WIZARD = 'ai_step_wizard',
  AI_INTENTS_WIZARD = 'ai_intents_wizard',
}

export enum StartActionType {
  CONNECT_FACEBOOK = 'CONNECT_FACEBOOK',
  LINK_TO = 'LINK_TO',
  FLOW = 'FLOW',
}

export enum StrategyCategory {
  RECOMMENDED = 'RECOMMENDED',
  CATEGORY_1 = 'CATEGORY_1',
  CATEGORY_2 = 'CATEGORY_2',
}

export enum ExperienceLevel {
  Beginner = 'Beginner',
  Advanced = 'Advanced',
  Intermediate = 'Intermediate',
}

export interface IStrategy {
  id: number
  quick_campaign_id: number
  preview: string
  title: string
  description?: string
  isCompleted: boolean
  isPRO: boolean
  isAI: boolean
  videoId: string | null

  strategyId: StrategyIds
  startAction: StartActionType
  experienceLevel: ExperienceLevel | null
  linkTo?: string
  flowInfo?: {
    name: string
    preset: string
    growthToolsReserved: number | null
  }
  channels: ChannelType[]
  industries: string[]
  useCases: UseCases[]
  articles: IStrategyArticle[]
  categories: StrategyCategory[]
  sort: number
  longDescription: string | null
  howItWorks: string | null
  businessValue: string | null
  imageShowMoreUrl: string | null
  isUsed: boolean
  mediaBackground: string | null
  scoring_easy: number | null
  scoring_popular: number | null
  useIn: StrategyPlaces[]
  templateId: number | null
  useCaseId?: string | null
  isFAQ?: boolean
  backgroundColor?: string
  installationsCount?: number
  videoUrl?: string
  isPopular?: boolean
  flowType?: FlowType
}

export enum StrategyPlaces {
  FLOW_MODAL = 'flow',
  DEFAULT_REPLY = 'default_reply',
  COLLECT_OPT_INS = 'follow_ups',
  STORY_MENTION_REPLY = 'story_mention_reply',
  WELCOME_MESSAGE = 'welcome_message',
  MAIN_MENU = 'main_menu',
  CONVERSATION_STARTERS = 'conversation_starters',
  DASHBOARD = 'dashboard',
  AI_PROMOTIONS = 'ai_promotions',
  SUGGESTIONS = 'suggestions',
  IG_USERS_VALUE = 'ig_users_value',
  IG_INSIGHTS = 'ig_insights',
}

export interface IStrategyArticle {
  title: string
  description: string
  preview: string
  link: string
}

export enum UseCases {
  REDIRECT_TO_THE_WEBSITE = 'redirect_to_the_website',
  INFORM_ABOUT_PRODUCTS = 'inform_about_products',
  CAPTURE_EMAILS = 'capture_emails',
  CAPTURE_PHONE_NUMBERS = 'capture_phone_numbers',
  ANSWER_FAQ = 'answer_faq',
  SCHEDULE_MEETINGS = 'schedule_meetings',
  INCREASE_ENGAGEMENT = 'increase_engagement',
  NURTURE_LEADS = 'nurture_leads',
  TELL_CUSTOMER_ABOUT_YOUR_BUSINESS = 'tell_customer_about_your_business',
  INTERACT_WITH_YOUR_CUSTOMERS = 'interact_with_your_customers',
  CAPTURE_CUSTOMER_DATA = 'capture_customer_data',
  QUALIFY_YOUR_LEADS = 'qualify_your_leads',
  CGT = 'cgt',
  LIVES = 'lives',
  STORIES = 'stories',
  DM = 'dm',
  OLD = 'old',
  NEW_SUBSCRIBERS = 'new_subscribers',
  FOOD = 'food',
}

import { combineReducers } from 'redux'

import ActiveCampaign from 'common/actions/integrations/ActiveCampaign/reducers/activeСampaign'
import applicationsReducer from 'common/actions/integrations/Applications/reducers/applications'
import ChatGPT from 'common/actions/integrations/ChatGPT/reducers/chatgpt'
import Claude from 'common/actions/integrations/Claude/model'
import { integrationModalsReducer } from 'common/actions/integrations/common/reducers/integrationModals'
import { integrationsConfirmReducer } from 'common/actions/integrations/common/reducers/integrationsConfirmSlice'
import { integrationsLoadersReducer } from 'common/actions/integrations/common/reducers/integrationsLoadersReducer'
import ConvertKit from 'common/actions/integrations/ConvertKit/reducers/convertkit'
import DeepSeek from 'common/actions/integrations/DeepSeek/model'
import { flodeskReducer } from 'common/actions/integrations/Flodesk/reducers/flodesk'
import GoogleSheets from 'common/actions/integrations/GoogleSheets/reducers/googleSheets'
import { hotmartReducer } from 'common/actions/integrations/Hotmart/state'
import Hubspot from 'common/actions/integrations/HubSpot/reducers/hubspot'
import Klaviyo from 'common/actions/integrations/Klaviyo/reducers/klaviyo'
import MailChimp from 'common/actions/integrations/MailChimp/reducers/mailchimp'
import Salesforce from 'common/actions/integrations/Salesforce/reducers/salesforce'
import { tikTokAdsReducer } from 'common/actions/integrations/TikTokAds/store/tikTokAdsSlice'

import { IntegrationType } from '../constants/IntegrationType'

import makeIntegrationsReducer from './integrations'

export default combineReducers({
  modals: integrationModalsReducer,
  confirm: integrationsConfirmReducer,
  loaders: integrationsLoadersReducer,
  applications: applicationsReducer,
  [IntegrationType.ACTIVE_CAMPAIGN]: makeIntegrationsReducer(
    ActiveCampaign,
    IntegrationType.ACTIVE_CAMPAIGN,
  ),
  [IntegrationType.CHATGPT]: makeIntegrationsReducer(ChatGPT, IntegrationType.CHATGPT),
  [IntegrationType.CLAUDE]: makeIntegrationsReducer(Claude, IntegrationType.CLAUDE),
  [IntegrationType.DEEP_SEEK]: makeIntegrationsReducer(DeepSeek, IntegrationType.DEEP_SEEK),
  [IntegrationType.CONVERT_KIT]: makeIntegrationsReducer(ConvertKit, IntegrationType.CONVERT_KIT),
  [IntegrationType.GOOGLE_SHEETS]: makeIntegrationsReducer(
    GoogleSheets,
    IntegrationType.GOOGLE_SHEETS,
  ),
  [IntegrationType.HOTMART]: makeIntegrationsReducer(hotmartReducer, IntegrationType.HOTMART),
  [IntegrationType.HUBSPOT]: makeIntegrationsReducer(Hubspot, IntegrationType.HUBSPOT),
  [IntegrationType.KLAVIYO]: makeIntegrationsReducer(Klaviyo, IntegrationType.KLAVIYO),
  [IntegrationType.MAILCHIMP]: makeIntegrationsReducer(MailChimp, IntegrationType.MAILCHIMP),
  [IntegrationType.SALESFORCE]: makeIntegrationsReducer(Salesforce, IntegrationType.SALESFORCE),
  [IntegrationType.FLODESK]: makeIntegrationsReducer(flodeskReducer, IntegrationType.FLODESK),
  [IntegrationType.TIKTOK_ADS]: makeIntegrationsReducer(
    tikTokAdsReducer,
    IntegrationType.TIKTOK_ADS,
  ),
})

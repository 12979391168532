import { FIELD_LIST_WIDTH, FIELD_PICKER_WIDTH } from 'common/fields/constants'
import { Field } from 'common/fields/entities/field'
import { getHaveMoreThatOneGroup } from 'common/fields/helpers'

export const usePickerStyle = (fields: Field[]) => {
  const hidePickerCategories = !getHaveMoreThatOneGroup(fields)

  const pickerWidth = hidePickerCategories ? FIELD_LIST_WIDTH : FIELD_PICKER_WIDTH

  return {
    hidePickerCategories,
    pickerWidth,
  }
}

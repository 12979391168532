import { isProError } from 'shared/api/common/errors/proError'
import { BusinessError } from 'shared/api/lib/errors/business'

interface FolderNotFoundError extends BusinessError {
  field: 'path'
}

const isFolderNotFoundError = (error: BusinessError): error is FolderNotFoundError =>
  error.field === 'path'

interface UsedTagNameError extends BusinessError {
  field: 'tag_name'
}

const isUsedTagNameError = (error: BusinessError): error is UsedTagNameError =>
  error.field === 'tag_name'

export const TagsApiGuards = {
  getList: {
    isFolderNotFoundError,
  },
  create: {
    isProError: isProError,
    isUsedTagNameError,
  },
  update: {
    isUsedTagNameError,
  },
}

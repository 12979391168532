import React from 'react'
import cx from 'classnames'

import { PhonePreviewTheme } from '../../constants'
import { DragIndicator } from '../../shared/DragIndicator/DragIndicator'
import { Overlay } from '../../shared/Overlay/Overlay'
import { Scrollbar } from '../../shared/Scrollbar/Scrollbar'
import { useThemeContext } from '../../useThemeContext'

import cm from './MainMenuRoot.module.css'

export interface MainMenuRootProps {
  title?: string
  subtitle?: string
  children: React.ReactNode
}

export function MainMenuRoot({ title, subtitle, children }: MainMenuRootProps) {
  const theme = useThemeContext()

  if (theme === PhonePreviewTheme.INSTAGRAM) {
    return (
      <Overlay>
        <div className={cx(cm.mainMenu, cm[theme])} data-test-id="main-menu">
          <DragIndicator />
          <Scrollbar className={cx(cm.container, cm.scrollbar)}>
            {title && <div className={cm.title}>{title}</div>}
            {subtitle && <div className={cm.subtitle}>{subtitle}</div>}
            <div className={cx(cm.content)}>{children}</div>
          </Scrollbar>
        </div>
      </Overlay>
    )
  }

  return (
    <div className={cx(cm.mainMenu, cm[theme])} data-test-id="main-menu">
      <DragIndicator />
      <Scrollbar className={cx(cm.container, cm.scrollbar)}>
        <div className={cx(cm.content)}>{children}</div>
      </Scrollbar>
    </div>
  )
}

MainMenuRoot.displayName = 'MainMenu.Root'

import { useEffect } from 'react'

export const useViewportAdjustments = () => {
  useEffect(() => {
    if (!window.visualViewport) {
      return
    }

    const updatePropertyValue = () => {
      const value = window.visualViewport
        ? document.documentElement.clientHeight - window.visualViewport.height
        : 0

      document.documentElement.style.setProperty('--mobile-virtual-keyboard-height', `${value}px`)
    }

    window.visualViewport.addEventListener('resize', updatePropertyValue)

    return () => {
      window.visualViewport?.removeEventListener('resize', updatePropertyValue)
    }
  }, [])
}

import * as blockSelectors from './blockSelectors'
import * as builderStateSelectors from './builderStateSelectors'
import * as buttonSelectors from './buttonSelectors'
import * as commonSelectors from './commonSelectors'
import * as conditionSelectors from './conditionSelectors'
import * as contentSelectors from './contentSelectors'
import * as errorPathSelectors from './errorPathSelectors'
import * as nodeSelectors from './nodeSelectors'
import * as smsSelectors from './smsSelectors'
import * as sourceSelectors from './sourceSelectors'
import * as splitSelectors from './splitSelectors'
import * as validationSelectors from './validationSelectors'

export default {
  ...commonSelectors,
  block: blockSelectors,
  button: buttonSelectors,
  content: contentSelectors,
  builderState: builderStateSelectors,
  // builderState alias
  state: builderStateSelectors,
  validation: validationSelectors,
  condition: conditionSelectors,
  split: splitSelectors,
  errorPath: errorPathSelectors,
  node: nodeSelectors,
  source: sourceSelectors,
  sms: smsSelectors,
}

import { FlowStatus, FlowType } from 'common/flow/flowConstants'
import { MappedLightFlow, LightFlow } from 'shared/api/common/schemas/lightFlow'

export const parseLightFlow = (data: LightFlow): MappedLightFlow => {
  const flow: MappedLightFlow = {
    id: data.ns ?? '',
    name: data.name ?? '',
    ns: data.ns ?? '',
    page_id: data.page_id ?? '',
    root_content_id: data.root_content_id ?? null,
    contentId: data.root_content_id ?? null,

    hasDraft: data.has_unpublished_changes ?? false,
    has_email_channel: data.has_email_channel ?? false,
    has_fb_channel: data.has_fb_channel ?? false,
    has_sms_channel: data.has_sms_channel ?? false,
    has_instagram_channel: data.has_instagram_channel ?? false,
    has_telegram_channel: data.has_telegram_channel ?? false,
    has_unpublished_changes: data.has_unpublished_changes ?? false,
    has_whatsapp_channel: data.has_whatsapp_channel ?? false,
    has_tiktok_channel: data.has_tiktok_channel ?? false,
    status: data.status ?? FlowStatus.ACTIVE,
    type: data.type ?? FlowType.DEFAULT,

    path: data.path ?? null,

    player_embed_url: data.player_embed_url ?? null,
    player_page_url: data.player_page_url ?? null,
    preview_actual: data.preview_actual ?? false,
    preview_url: data.preview_url ?? null,
    share_hash: data.share_hash ?? null,
    is_shared: data.is_shared ?? false,
    shared_cloning_enabled: data.shared_cloning_enabled ?? null,
    is_shareable: data.is_shareable ?? false,
    is_deletable: data.is_deletable ?? false,
    is_readonly: data.is_readonly ?? false,
    is_permanently_deletable: data.is_permanently_deletable ?? false,
    is_exportable: data.is_exportable ?? false,
    is_moveable: data.is_moveable ?? false,
    is_restorable: data.is_restorable ?? false,
    is_editable: data.is_editable ?? false,
    has_published_content: data.has_published_content ?? false,
    has_content_stats: data.has_content_stats ?? false,

    quick_campaign: data.quick_campaign ?? null,
    quick_campaign_id: data.quick_campaign_id,
    quick_campaign_data: data.quick_campaign_data ?? null,
  }

  if (data.template_installation_access_data) {
    flow.template_installation_access_data = data.template_installation_access_data
  }
  return flow
}

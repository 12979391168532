import { getAccountID } from 'utils/accountId'

import { isTestEnv } from './e2e'
import { isLocal } from './isLocal'

export const getURLParam = (param: string): string | null => {
  const url = new URL(window.location.href)
  return url.searchParams.get(param)
}

export const addURLParam = (url: string, param: string, value: string): string =>
  `${url}${/\?\w+|\-=/.test(url) ? '&' : '?'}${param}=${value}`

type IURL = string | [string, { method: string }] | { (): string }

export const expandURL = (url: IURL, data: SafeUnknownObject = {}): IURL => {
  if (typeof url === 'function') {
    url = url()
  }

  if (Array.isArray(url)) {
    return [expandURL(url[0], data), ...url.slice(1)] as [string, { method: string }]
  }
  const usedKeys: string[] = []

  // Replacing params specified in the URL
  let res = url.replace(
    /([?&])([\w\_\-]+)=\:([\w\_\-]+)/gi,
    (_, prefix: string, parameter: string, dataKey: string): string => {
      if (data[dataKey] !== undefined) {
        usedKeys.push(dataKey)
        return `${prefix}${parameter}=${data[dataKey]}`
      }
      return ''
    },
  )

  // Injecting data in non-param URL parts
  res = res.replace(/\:([\w\_\-]+)/gi, (match, dataKey) => {
    if (data[dataKey] !== undefined) {
      usedKeys.push(dataKey)
      return `${data[dataKey]}`
    }
    return match
  })

  // Adding other params from data by key
  for (const dataKey of Object.keys(data).filter((k) => !usedKeys.includes(k))) {
    if (data[dataKey] !== undefined) {
      res = addURLParam(res, dataKey, String(data[dataKey]))
    }
  }
  return res
}

export const linkURL = (url = '', noPrefix = false): string =>
  (noPrefix ? `/${url}` : `/${getAccountID()}/${url}`).replace(/\/+/g, '/')

export const getApiRoot = (): string =>
  isLocal() || isTestEnv() ? `${window.location.origin}/api` : ''

export const linkDomain = (url: string): string =>
  url.startsWith('http') ? url : `${getApiRoot()}${url}`

import debugConstructor from 'debug'
import { Dispatch } from 'redux'

import errorTrackingService from 'utils/services/errorTrackingService'

import {
  Handler,
  ICentrifuge,
  IConfig,
  IWebSocketListener,
  NotificationsHandler,
  INotificationsListener,
  IWSService,
} from './notificationsServiceTypes'

const debug = debugConstructor('centrifuge')

export class WebSocketListener implements IWebSocketListener {
  private channels: string[]
  private centrifuge: ICentrifuge

  constructor(centrifuge: ICentrifuge) {
    this.centrifuge = centrifuge
    this.channels = []
  }

  subscribeToChannel = (channel: string, handler: NotificationsHandler) => {
    const subscription = this.centrifuge.subscribe(channel, handler)

    subscription.on('message', (context: unknown) => debug(`channel:message`, context))
    subscription.on('join', (context: unknown) => debug('channel:join', context))
    subscription.on('leave', (context: unknown) => debug('channel:leave', context))
    subscription.on('subscribe', (context: unknown) => debug('channel:subscribe', context))
    subscription.on('unsubscribe', (context: unknown) => debug('channel:unsubscribe', context))
    subscription.on('error', (context: unknown) => debug('channel:error', context))
    this.channels.push(channel)
  }

  connect = () => {
    if (!this.channels.length) {
      throw new Error('No channels to subscribe for notifications')
    }

    this.centrifuge.on('connect', (context: unknown) => debug('connect', context))
    this.centrifuge.on('disconnect', (context: unknown) => debug('disconnect', context))
    this.centrifuge.on('error', (context: unknown) => debug('error', context))

    this.centrifuge.connect()
  }
}

export const createNotificationsListener = (): INotificationsListener => {
  const events: Record<string, Handler[]> = {}

  const getHandlers = (event: string): Handler[] => {
    return events[event] || []
  }

  const setHandler = (event: string, handler: Handler) => {
    const eventListeners = getHandlers(event)
    eventListeners.push(handler)
    events[event] = eventListeners
  }

  const removeHandler = (event: string, handler: Handler) => {
    const eventListeners = getHandlers(event)
    const index = eventListeners.findIndex((h) => h === handler)
    if (index !== -1) {
      eventListeners.splice(index, 1)
    } else {
      errorTrackingService.trackError(
        new Error(
          "Error trying to remove listener. Likely the handle has been reevaluated and cannot be found or it has been called when it wasn't necessary.",
        ),
        { extra: { event } },
      )
    }
  }

  return {
    on: (event, handler) => {
      const events = Array.isArray(event) ? event : [event]
      events.forEach((event) => setHandler(event, handler))
    },
    emit: (data, _dispatch, _getState) => {
      const handlers = getHandlers(data.event)
      handlers.forEach((handler) => handler(data, _dispatch, _getState))
    },
    removeListener: (event, handler) => {
      const events = Array.isArray(event) ? event : [event]
      events.forEach((event) => removeHandler(event, handler))
    },
  }
}

export const createAnotherTabNotificationsListener = (
  getClientId: () => string | null,
): INotificationsListener => {
  const notificationsListener = createNotificationsListener()

  return {
    on: notificationsListener.on,
    removeListener: notificationsListener.removeListener,
    emit: (data, dispatch, getState) => {
      if (data.client_id && data.client_id === getClientId()) {
        return
      }
      return notificationsListener.emit(data, dispatch, getState)
    },
  }
}

export const createWSService = (): IWSService => {
  const listeners: INotificationsListener[] = []
  let _dispatch: Dispatch | ((arg: SafeUnknownObject) => void) = (arg: SafeUnknownObject) => arg
  let _getState: () => Partial<RootState> = () => ({})

  return {
    setStore: (dispatch, getState) => {
      _dispatch = dispatch
      _getState = getState
    },
    addListener: (listener) => {
      listeners.push(listener)
    },
    handleNotifications: ({ data }) => {
      listeners.forEach((listener) => {
        // eslint-disable-next-line
        // @ts-expect-error
        listener.emit(data, _dispatch, _getState)
      })
    },
  }
}

interface ICentrifugeConstructor {
  new (conf: SafeUnknownObject): ICentrifuge
}

export const initializeNotificationsService = (
  wsService: IWSService,
  Centrifuge: ICentrifugeConstructor,
  accountScopeParams?: IConfig,
  userScopeParams?: IConfig,
) => {
  if (userScopeParams === undefined) {
    return console.warn('No centrifuge settings')
  }

  const { server, user, timestamp, token } = userScopeParams

  const centrifuge = new Centrifuge({
    url: server,
    user,
    timestamp,
    token,
    debug: debug.enabled,
  })

  const wsListener = new WebSocketListener(centrifuge)

  // TODO get parameters from a dedicated field when backend supports it
  if (accountScopeParams && 'channel' in accountScopeParams) {
    wsListener.subscribeToChannel(accountScopeParams.channel, wsService.handleNotifications)
  }
  if ('channel' in userScopeParams) {
    wsListener.subscribeToChannel(userScopeParams.channel, wsService.handleNotifications)
  }

  wsListener.connect()
}

import { InstagramConnectionWasSuccessfullyReloadedNotification } from 'common/core/components/NotifyBar/InstagramNotifications/InstagramConnectionWasSuccessfullyReloadedNotification'
import { InstagramWrongPageReloadPermissions } from 'common/core/components/NotifyBar/InstagramNotifications/InstagramWrongPageReloadPermissions'
import { NotificationBarType } from 'common/core/constants/NotificationBarType'

import {
  InstagramNotification,
  InstagramNotificationType,
  InstagramReadyNotification,
  NotificationRemoveType,
} from '../NotifyInterfaces'

const getRender = (
  message: InstagramNotification['message'],
): InstagramReadyNotification['render'] => {
  switch (message) {
    case InstagramNotificationType.INSTAGRAM_PAGE_CONNECTION_WAS_SUCCESSFULLY_RELOADED:
      return InstagramConnectionWasSuccessfullyReloadedNotification
    case InstagramNotificationType.INSTAGRAM_WRONG_PAGE_TO_RELOAD_PERMISSIONS:
      return InstagramWrongPageReloadPermissions
  }
}

const getMessageType = (message: InstagramNotification['message']): NotificationBarType => {
  switch (message) {
    case InstagramNotificationType.INSTAGRAM_PAGE_CONNECTION_WAS_SUCCESSFULLY_RELOADED:
      return NotificationBarType.SUCCESS
    case InstagramNotificationType.INSTAGRAM_WRONG_PAGE_TO_RELOAD_PERMISSIONS:
      return NotificationBarType.ERROR
  }
}

export const parseInstagramNotification = (
  notification: InstagramNotification,
): InstagramReadyNotification => {
  return {
    ...notification,
    type: getMessageType(notification.message),
    removeType: NotificationRemoveType.ALWAYS,
    render: getRender(notification.message),
  }
}

import BaseBlockConfig from 'common/builder/blocks/BaseBlockConfig'
import { IBlockBase, ICaseBlock } from 'common/builder/blocks/blockInterfaces'
import { BlockType } from 'common/builder/constants/BlockType'

class CaseBlockConfig extends BaseBlockConfig {
  blockType = BlockType.CASE

  getDefaults = (): Omit<ICaseBlock, keyof IBlockBase> => ({
    filter: null,
    targetId: null,
  })
}

export default new CaseBlockConfig()

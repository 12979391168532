export const SEQUENCE_CREATED_NOTIFICATION = 'SEQUENCE_CREATED_NOTIFICATION'
export const SEQUENCE_REMOVED_NOTIFICATION = 'SEQUENCE_REMOVED_NOTIFICATION'
export const SEQUENCE_UPDATED_NOTIFICATION = 'SEQUENCE_UPDATED_NOTIFICATION'

export const SEQUENCE_LIST_LOAD_REQUEST = 'SEQUENCE_LIST_LOAD_REQUEST'
export const SEQUENCE_LIST_LOAD_SUCCESS = 'SEQUENCE_LIST_LOAD_SUCCESS'
export const SEQUENCE_LIST_LOAD_ERROR = 'SEQUENCE_LIST_LOAD_ERROR'
export const SEQUENCE_LOAD_ITEM_SUCCESS = 'SEQUENCE_LOAD_ITEM_SUCCESS'
export const SEQUENCE_CREATE_SUCCESS = 'SEQUENCE_CREATE_SUCCESS'
export const SEQUENCE_REMOVE_SUCCESS = 'SEQUENCE_REMOVE_SUCCESS'
export const SEQUENCE_UPDATE_SUCCESS = 'SEQUENCE_UPDATE_SUCCESS'
export const SEQUENCE_CLONE_SUCCESS = 'SEQUENCE_CLONE_SUCCESS'
export const SEQUENCE_REORDER_MESSAGES_SUCCESS = 'SEQUENCE_REORDER_MESSAGES_SUCCESS'
export const SEQUENCE_OPEN_CREATE_DIALOG = 'SEQUENCE_OPEN_CREATE_DIALOG'
export const SEQUENCE_CLOSE_CREATE_DIALOG = 'SEQUENCE_CLOSE_CREATE_DIALOG'
export const SEQUENCE_LIST_DROP = 'SEQUENCE_LIST_DROP'

export const SEQUENCE_MESSAGE_SET_FLOW_REQUEST = 'SEQUENCE_MESSAGE_SET_FLOW_REQUEST'
export const SEQUENCE_MESSAGE_SET_FLOW_RESPONSE = 'SEQUENCE_MESSAGE_SET_FLOW_RESPONSE'
export const SEQUENCE_MESSAGE_SET_FLOW_ERROR = 'SEQUENCE_MESSAGE_SET_FLOW_ERROR'

export const SEQUENCE_PATCH_MESSAGE_REQUEST = 'SEQUENCE_PATCH_MESSAGE_REQUEST'
export const SEQUENCE_PATCH_MESSAGE_RESPONSE = 'SEQUENCE_PATCH_MESSAGE_RESPONSE'
export const SEQUENCE_PATCH_MESSAGE_ERROR = 'SEQUENCE_PATCH_MESSAGE_ERROR'

export const SEQUENCE_REMOVE_MESSAGE_REQUEST = 'SEQUENCE_REMOVE_MESSAGE_REQUEST'
export const SEQUENCE_REMOVE_MESSAGE_RESPONSE = 'SEQUENCE_REMOVE_MESSAGE_RESPONSE'
export const SEQUENCE_REMOVE_MESSAGE_ERROR = 'SEQUENCE_REMOVE_MESSAGE_ERROR'

export const SEQUENCE_CREATE_MESSAGE_REQUEST = 'SEQUENCE_CREATE_MESSAGE_REQUEST'
export const SEQUENCE_CREATE_MESSAGE_RESPONSE = 'SEQUENCE_CREATE_MESSAGE_RESPONSE'
export const SEQUENCE_CREATE_MESSAGE_ERROR = 'SEQUENCE_CREATE_MESSAGE_ERROR'

export const SEQUENCE_MESSAGE_SET_SENDING_SETTINGS_REQUEST =
  'SEQUENCE_MESSAGE_SET_SENDING_SETTINGS_REQUEST'
export const SEQUENCE_MESSAGE_SET_SENDING_SETTINGS_RESPONSE =
  'SEQUENCE_MESSAGE_SET_SENDING_SETTINGS_RESPONSE'
export const SEQUENCE_MESSAGE_SET_SENDING_SETTINGS_ERROR =
  'SEQUENCE_MESSAGE_SET_SENDING_SETTINGS_ERROR'

import { appListenerMiddleware } from 'reduxTyped'

import { alert } from 'common/core'
import { billingFlexApi } from 'shared/api/requests/billingFlex'
import { BillingFlexApiGuards } from 'shared/api/requests/billingFlex/guards'
import { createAsyncAction } from 'shared/lib/redux'

import { taxFormSlice } from '../redux/taxForm'
import { getShouldRequestTaxNumber } from '../selectors/billingSelectors'

export const updateTaxNumber = createAsyncAction(
  'billingFlex/updateTaxNumber',
  async (taxNumber: string) => {
    await billingFlexApi.updateTaxNumber({
      body: {
        tax_number: taxNumber,
      },
    })
  },
  {
    onBusinessError(error) {
      if (BillingFlexApiGuards.isTaxNumberInvalidFormatError(error)) {
        error.handle()

        return error
      }

      if (BillingFlexApiGuards.isForbiddenAccountLegalEntityError(error)) {
        error.handle()

        alert(error.original_message, 'danger')
      }

      return null
    },
  },
)

export const requestTaxNumber = createAsyncAction<boolean, boolean>(
  'taxForm/request',
  async (force = false, { dispatch, getState }) => {
    const state = getState()
    const shouldRequestTaxNumber = getShouldRequestTaxNumber(state, force)

    if (!shouldRequestTaxNumber) {
      return true
    }

    dispatch(taxFormSlice.actions.open())

    return new Promise<boolean>((resolve) => {
      const endActions = [updateTaxNumber.fulfilled.type, taxFormSlice.actions.close.type]

      const unsubscribe = appListenerMiddleware.startListening({
        predicate: (action) => endActions.includes(action.type),
        effect: (action) => {
          unsubscribe()
          resolve(updateTaxNumber.fulfilled.match(action))
        },
      })
    })
  },
)

import React, { useEffect } from 'react'

import { useNotifications } from './hooks/useNotifications'
import Notification, { NOTIFICATION_HEIGHT } from './Notification'

const NotifyBar = () => {
  const { notifications, closeNotification } = useNotifications()

  useEffect(() => {
    document.documentElement.style.setProperty(
      '--notifications-bar-height',
      `${notifications.length * NOTIFICATION_HEIGHT}px`,
    )
    return () => document.documentElement.style.setProperty('--notifications-bar-height', '0')
  }, [notifications.length])

  return (
    <div>
      {notifications.map((notification) => (
        <Notification
          onClose={closeNotification}
          key={notification.notification_id}
          notification={notification}
        />
      ))}
    </div>
  )
}

export default NotifyBar

import BaseBlockConfig from 'common/builder/blocks/BaseBlockConfig'
import {
  QuestionBlockReplyType,
  QuestionBlockTimeoutUnitType,
} from 'common/builder/blocks/blockConstants'
import { IBlockBase, IQuestionBlock } from 'common/builder/blocks/blockInterfaces'
import { BlockType } from 'common/builder/constants/BlockType'

class QuestionBlockConfig extends BaseBlockConfig {
  blockType = BlockType.FORM_QUESTION

  getDefaults = (): Omit<IQuestionBlock, keyof IBlockBase> => ({
    text: '',
    replyType: QuestionBlockReplyType.FREE_TEXT,
    allowFreeInput: false,
    customFieldId: null,
    savePhoneToSystemField: true,
    saveEmailToSystemField: true,
    savePhoneToWhatsAppIdSystemField: true,
    setSmsOptIn: true,
    setEmailOptIn: true,
    validationErrorMessage: '',
    skipButtonCaption: 'Skip',
    buttonCaption: '',
    targetId: null,
    answerRetries: 4,
    telegramSharePhoneButtonCaption: '',
    questionAnswerTimeout: {
      value: 30,
      unit: QuestionBlockTimeoutUnitType.MINUTE,
    },
  })
}

export default new QuestionBlockConfig()

import { widgetCheck } from 'apps/growthTools/actions/widgetActionsTyped'

import * as atypes from '../constants/gtReduxActionTypes'

export const initialState = {
  widgetId: null,
  url: '',
  loading: false,
  ready: false,
  messages: null,
  async: null,
  level: null,
  err: null,
  status: null,
  spec: null,
}

export default function widgetCheckerReducer(state = initialState, action) {
  switch (action.type) {
    case atypes.WIDGET_CHECKER_INIT: {
      const { url, widgetId } = action
      return { ...state, ...initialState, url, widgetId, loading: true }
    }
    case atypes.WIDGET_CHECKER_FINISH: {
      const { messages, status } = action
      return { ...state, messages, status, loading: false, ready: true }
    }
    case atypes.WIDGET_CHECKER_ERROR: {
      const { err } = action
      return { ...state, err, loading: false, ready: true }
    }
    case widgetCheck.pending.type: {
      const { async } = action.meta.arg
      return { ...state, async }
    }
    case atypes.WIDGET_CHECK_PAGE_DONE: {
      const { spec } = action
      return { ...state, spec }
    }
    case atypes.WIDGET_CHECKER_CANCEL: {
      return { ...initialState }
    }
  }

  return state
}

import { navigatePollyfill } from 'utils/router/tools'
import OnboardingBase from 'utils/services/newOnboardingService/core/OnboardingBase'
import StepController from 'utils/services/newOnboardingService/core/StepController'
import StepSystem from 'utils/services/newOnboardingService/core/steps/StepSystem'
import OnboardingId from 'utils/services/newOnboardingService/OnboardingId'
import { waitUntilElementMount } from 'utils/services/newOnboardingService/view/Pointer/pointerHelpers'
import { linkURL } from 'utils/url'

// eslint-disable-next-line @typescript-eslint/consistent-type-definitions
export type NewCMSSelfServeFAQStepsMap = {
  STEP_1: StepSystem
  STEP_2: StepSystem
  STEP_3: StepSystem
  STEP_4: StepSystem
  STEP_5: StepSystem
  STEP_6: StepSystem
  STEP_7: StepSystem
  STEP_8: StepSystem
  STEP_9: StepSystem
  STEP_10: StepSystem
}

class NewCMSFAQ extends OnboardingBase<NewCMSSelfServeFAQStepsMap> {
  constructor() {
    super(OnboardingId.NEW_CMS_SELF_SERVE_FAQ_TOUR, () => {
      return new StepController<NewCMSSelfServeFAQStepsMap>('STEP_1', {
        STEP_1: () =>
          new StepSystem(() => this.setStep('STEP_2'), {
            onRun: () => {
              navigatePollyfill(linkURL(`/dashboard`))
            },
          }),
        STEP_2: () =>
          new StepSystem(() => this.setStep('STEP_3'), {
            onRun: () => {
              waitUntilElementMount('[data-onboarding-id="nav-basic"]', (el) => {
                el.scrollIntoView({ block: 'center' })
              })
            },
          }),
        STEP_3: () =>
          new StepSystem(() => this.setStep('STEP_4'), {
            onRun: () => {
              waitUntilElementMount(
                '[data-onboarding-id="self-serve-conversation-starters-tour"]',
                (el) => {
                  el.scrollIntoView({ block: 'center' })
                },
              )
            },
          }),
        STEP_4: () =>
          new StepSystem(() => this.setStep('STEP_5'), {
            onRun: () => {
              navigatePollyfill(linkURL(`/automation/instagram/FAQ`))
              waitUntilElementMount('[data-onboarding-id="conversation-starter-item"]', (el) => {
                el.scrollIntoView({ block: 'center' })
              })
            },
          }),
        STEP_5: () => new StepSystem(() => this.setStep('STEP_6'), {}),
        STEP_6: () =>
          new StepSystem(() => this.setStep('STEP_7'), {
            onRun: () => {
              waitUntilElementMount('[data-onboarding-id="btn-edit-flow"]', (el) => {
                el.scrollIntoView({ block: 'center' })
              })
            },
          }),
        STEP_7: () => new StepSystem(() => this.setStep('STEP_8'), {}),
        STEP_8: () => new StepSystem(() => this.setStep('STEP_9'), {}),
        STEP_9: () => new StepSystem(() => this.setStep('STEP_10'), {}),
        STEP_10: () => new StepSystem(() => this.complete(), {}),
      })
    })
  }
}

export default NewCMSFAQ

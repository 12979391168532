import { l } from '@manychat/manyui'

export enum StatusFilterLabel {
  ANY_TRIGGER_STATES = 'Any Trigger states',
  HAS_ACTIVE_TRIGGERS = 'Has active Triggers',
}

export enum TriggerFilterLabel {
  POST_OR_REEL_COMMENTS = 'Post or Reel Comments',
  STORY_REPLY = 'Story Reply',
  DM_KEYWORD = 'DM Keyword',
  REF_URL = 'Ref URL',
  ADS = 'Ads',
  ADS_JSON = 'Ads JSON',
  DATE_TIME_TRIGGER = 'Date/Time based trigger',
  ANY_TRIGGER_STATES = 'Any Trigger states',
  HAS_ACTIVE_TRIGGERS = 'Has active Triggers',
  NEW_CONTACT = 'New contact',
  TAG_APPLIED = 'Tag applied',
  TAG_REMOVED = 'Tag removed',
  SUBSCRIBED_SEQUENCE = 'Subscribed to a sequence',
  UNSUBSCRIBED_SEQUENCE = 'Unsubscribed from a sequence',
  CUSTOM_FIELD_CHANGED = 'Custom field value changed',
  SYSTEM_FIELD_CHANGED = 'System field value changed',
  LOG_CONVERSION_EVENT = 'Log Conversion Event',
  MESSENGER_OPTIN_BANNER = 'Messenger Opt-In Banner',
  MODAL = 'Modal',
  MESSENGER_SHOP_MESSAGE = 'Messenger Shop Message',
  BARCODE = 'Barcode',
  FACEBOOK_COMMENTS_GT = 'Facebook Comments (GT)',
  COMMENTS = 'Comments',
  QR_CODE = 'QR Code',
  LIVE_COMMENTS = 'Live Comments',
  EXTERNAL_TRIGGER = 'External Trigger',
  DM_INTENTION = 'DM Intention',
  MESSENGER_REF_URL = 'Messenger Ref URL',
  WHATSAPP_URL = 'WhatsApp URL',
  TELEGRAM_REF_URL = 'Telegram Ref URL',
  INSTAGRAM_REF_URL = 'Instagram Ref URL',
  FACEBOOK_COMMENTS = 'Facebook Comments',
  FACEBOOK_ADS = 'Facebook Ads',
  INSTAGRAM_ADS = 'Instagram Ads',
  CLICK_TO_WHATSAPP_AD = 'Click to WhatsApp Ad',
}

export const TranslatedStatusFilterLabels = {
  [StatusFilterLabel.ANY_TRIGGER_STATES]: l.makeTranslate('Any Trigger states'),
  [StatusFilterLabel.HAS_ACTIVE_TRIGGERS]: l.makeTranslate('Has active Triggers'),
}

export const TranslatedFilterLabels = {
  [TriggerFilterLabel.POST_OR_REEL_COMMENTS]: l.makeTranslate('Post or Reel Comments'),
  [TriggerFilterLabel.STORY_REPLY]: l.makeTranslate('Story Reply'),
  [TriggerFilterLabel.DM_KEYWORD]: l.makeTranslate('DM Keyword'),
  [TriggerFilterLabel.REF_URL]: l.makeTranslate('Ref URL'),
  [TriggerFilterLabel.ADS]: l.makeTranslate('Ads'),
  [TriggerFilterLabel.ADS_JSON]: l.makeTranslate('Ads JSON'),
  [TriggerFilterLabel.DATE_TIME_TRIGGER]: l.makeTranslate('Date/Time based trigger'),
  [TriggerFilterLabel.ANY_TRIGGER_STATES]: l.makeTranslate('Any Trigger states'),
  [TriggerFilterLabel.HAS_ACTIVE_TRIGGERS]: l.makeTranslate('Has active Triggers'),
  [TriggerFilterLabel.NEW_CONTACT]: l.makeTranslate('New contact'),
  [TriggerFilterLabel.TAG_APPLIED]: l.makeTranslate('Tag applied'),
  [TriggerFilterLabel.TAG_REMOVED]: l.makeTranslate('Tag removed'),
  [TriggerFilterLabel.SUBSCRIBED_SEQUENCE]: l.makeTranslate('Subscribed to a sequence'),
  [TriggerFilterLabel.UNSUBSCRIBED_SEQUENCE]: l.makeTranslate('Unsubscribed from a sequence'),
  [TriggerFilterLabel.CUSTOM_FIELD_CHANGED]: l.makeTranslate('Custom field value changed'),
  [TriggerFilterLabel.SYSTEM_FIELD_CHANGED]: l.makeTranslate('System field value changed'),
  [TriggerFilterLabel.LOG_CONVERSION_EVENT]: l.makeTranslate('Log Conversion Event'),
  [TriggerFilterLabel.MESSENGER_OPTIN_BANNER]: l.makeTranslate('Messenger Opt-In Banner'),
  [TriggerFilterLabel.MODAL]: l.makeTranslate('Modal'),
  [TriggerFilterLabel.MESSENGER_SHOP_MESSAGE]: l.makeTranslate('Messenger Shop Message'),
  [TriggerFilterLabel.BARCODE]: l.makeTranslate('Barcode'),
  [TriggerFilterLabel.FACEBOOK_COMMENTS_GT]: l.makeTranslate('Facebook Comments (GT)'),
  [TriggerFilterLabel.COMMENTS]: l.makeTranslate('Comments'),
  [TriggerFilterLabel.QR_CODE]: l.makeTranslate('QR Code'),
  [TriggerFilterLabel.LIVE_COMMENTS]: l.makeTranslate('Live Comments'),
  [TriggerFilterLabel.EXTERNAL_TRIGGER]: l.makeTranslate('External Trigger'),
  [TriggerFilterLabel.DM_INTENTION]: l.makeTranslate('DM Intention'),
  [TriggerFilterLabel.MESSENGER_REF_URL]: l.makeTranslate('Messenger Ref URL'),
  [TriggerFilterLabel.WHATSAPP_URL]: l.makeTranslate('WhatsApp URL'),
  [TriggerFilterLabel.TELEGRAM_REF_URL]: l.makeTranslate('Telegram Ref URL'),
  [TriggerFilterLabel.INSTAGRAM_REF_URL]: l.makeTranslate('Instagram Ref URL'),
  [TriggerFilterLabel.FACEBOOK_COMMENTS]: l.makeTranslate('Facebook Comments'),
  [TriggerFilterLabel.FACEBOOK_ADS]: l.makeTranslate('Facebook Ads'),
  [TriggerFilterLabel.INSTAGRAM_ADS]: l.makeTranslate('Instagram Ads'),
  [TriggerFilterLabel.CLICK_TO_WHATSAPP_AD]: l.makeTranslate('Click to WhatsApp Ad'),
}

import { api } from 'shared/api'
import { cmsApiSchemas } from 'shared/api/requests/cms/schemas'

export const CmsApi = {
  getUsesList: api.account.createGet({
    url: '/cms/getUsesList',
    schemas: cmsApiSchemas.getUsesList,
  }),
  delete: api.account.createGet({
    url: '/cms/delete',
    schemas: cmsApiSchemas.delete,
  }),
  getFolderUsesList: api.account.createGet({
    url: '/cms/getFolderUsesList',
    schemas: cmsApiSchemas.getFolderUsesList,
  }),
  getFolders: api.account.createGet({
    url: '/cms/getFolders',
    schemas: cmsApiSchemas.getFolders,
  }),
  createFolder: api.account.createPost({
    url: '/cms/createFolder',
    schemas: cmsApiSchemas.createFolder,
  }),
  renameObject: api.account.createPost({
    url: '/cms/rename',
    schemas: cmsApiSchemas.renameObject,
  }),
  permanentlyDelete: api.account.createGet({
    url: '/cms/permanentlyDelete',
    schemas: cmsApiSchemas.permanentlyDelete,
  }),
  restore: api.account.createGet({
    url: '/cms/restore',
    schemas: cmsApiSchemas.restore,
  }),
  bulkMove: api.account.createPost({
    url: '/cms/bulkMove',
    schemas: cmsApiSchemas.bulkMove,
  }),
  bulkClone: api.account.createPost({
    url: '/cms/bulkClone',
    schemas: cmsApiSchemas.bulkClone,
  }),
  clone: api.account.createGet({
    url: '/cms/clone',
    schemas: cmsApiSchemas.clone,
  }),
  getBulkUsesList: api.account.createPost({
    url: '/cms/bulkGetUsesList',
    schemas: cmsApiSchemas.getBulkUsesList,
  }),
  convertFlow: api.account.createPost({
    url: '/cms/convert',
    schemas: cmsApiSchemas.convertFlow,
  }),
  createFlow: api.account.createPost({
    url: '/cms/createFlow',
    schemas: cmsApiSchemas.createFlow,
  }),
  getFlows: api.account.createGet({
    url: '/cms/getFlows',
    schemas: cmsApiSchemas.getFlows,
  }),
  bulk: api.account.createPost({
    url: '/cms/bulk',
    schemas: cmsApiSchemas.bulk,
  }),
  move: api.account.createGet({
    url: '/cms/move',
    schemas: cmsApiSchemas.move,
  }),
  getFilters: api.account.createGet({
    url: '/cms/getAvailableFilters',
    schemas: cmsApiSchemas.getFilters,
  }),
  searchFlows: api.account.createPost({
    url: '/cms/searchFlowsNew',
    schemas: cmsApiSchemas.searchFlows,
  }),
  applyRecommendation: api.account.createPost({
    url: '/cms/applyRecommendation',
    schemas: cmsApiSchemas.applyRecommendation,
  }),
}

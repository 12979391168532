import dot from 'dot-prop-immutable'
import { Action } from 'redux'

import { ManagerValue } from 'common/builder/builderInterfaces'
import * as actionTypes from 'common/builder/constants/builderReduxActionTypes'
import { IManager } from 'common/core/interfaces/manager'

const initialState: IManager = { byId: {} }

type ManagerAction = Partial<
  {
    item: ManagerValue
    managerId: string
    changes: ManagerValue
    lastSaved: ManagerValue['lastSaved']
    contentHashMap: ManagerValue['lastContentHashMap']
    rootId: ManagerValue['lastRootId']
    coordinates: ManagerValue['lastCoordinates']
    saveError: ManagerValue['saveError']
  } & Action
>

export default function managerReducer(state = initialState, action: ManagerAction) {
  switch (action.type) {
    case actionTypes.CREATE_MANAGER_STATE: {
      const { item } = action
      if (item) {
        return dot.set(state, `byId.${item.id}`, item)
      }
      return state
    }
    case actionTypes.REMOVE_MANAGER_STATE: {
      const { managerId } = action
      return dot.delete(state, `byId.${managerId}`)
    }
    case actionTypes.UPDATE_MANAGER_STATE: {
      const { managerId, changes } = action
      if (managerId && changes) {
        return dot.merge(state, `byId.${managerId}`, changes)
      }
      return state
    }
    case actionTypes.MANAGER_AUTO_SAVE_REQUEST: {
      const { managerId } = action
      if (managerId && state.byId?.[managerId]) {
        return dot.merge(state, `byId.${managerId}`, { isAutoSaving: true })
      }
      return state
    }
    case actionTypes.MANAGER_AUTO_SAVE_SUCCESS: {
      const { managerId, lastSaved, contentHashMap, rootId, coordinates } = action
      if (!dot.get(state, `byId.${managerId}`)) {
        return state
      }
      const changes = {
        lastSaved,
        isAutoSaving: false,
        lastContentHashMap: contentHashMap,
        lastRootId: rootId,
        lastCoordinates: coordinates,
        saveError: null,
      }
      return dot.merge(state, `byId.${managerId}`, changes)
    }
    case actionTypes.MANAGER_AUTO_SAVE_ERROR: {
      const { managerId, saveError } = action
      if (!dot.get(state, `byId.${managerId}`)) {
        return state
      }
      return dot.merge(state, `byId.${managerId}`, { isAutoSaving: false, saveError })
    }
    case actionTypes.RESET_MANAGER_LAST_SAVED: {
      const { managerId } = action
      if (!dot.get(state, `byId.${managerId}`)) {
        return state
      }
      return dot.merge(state, `byId.${managerId}`, { lastSaved: null })
    }
    case actionTypes.UPDATE_MANAGER_LAST_CONTENT_HASH_MAP: {
      const { managerId, contentHashMap } = action
      return dot.set(state, `byId.${managerId}.lastContentHashMap`, contentHashMap)
    }
    case actionTypes.UPDATE_MANAGER_LAST_ROOT_ID: {
      const { managerId, rootId } = action
      return dot.set(state, `byId.${managerId}.lastRootId`, rootId)
    }
    case actionTypes.UPDATE_MANAGER_LAST_COORDINATES: {
      const { managerId, coordinates } = action
      return dot.set(state, `byId.${managerId}.lastCoordinates`, coordinates)
    }
  }
  return state
}

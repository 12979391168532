import { allowedInstagramDefaultReplyBlocks } from 'common/builder/components/builder/GenericBuilder/NodeBuilder/ContentNodeContentTypeSelector/constants'
import { INode } from 'common/builder/nodes/nodeInterfaces'
import { getOrdinaryBlocks } from 'common/builder/selectors/builder/nodeSelectors'

import { isInstagramNode } from './isInstagramNode'

export const isNodePassesIgCommentReplyLimitations = ({
  node,
  builderId,
  state,
}: {
  node: INode | undefined | null
  builderId: string
  state: RootState
}) => {
  if (!node || !isInstagramNode(node)) {
    return false
  }

  if (node.targetId !== null) {
    return false
  }

  const blocks = getOrdinaryBlocks(state, builderId, node.id)

  if (
    blocks.length > 1 ||
    blocks.some((block) => !allowedInstagramDefaultReplyBlocks.includes(block.type))
  ) {
    return false
  }

  return true
}

import React, { useCallback, useState } from 'react'
import cx from 'classnames'
import { BtnV2, CheckboxV2, l, sanitizeURL } from '@manychat/manyui'

import If from 'components/If/If'
import { analyticsService } from 'utils/services/analytics'

import cm from './OnboardingModal.module.css'

export interface IModalConfig {
  img: string
  title: () => string
  text: () => string
  btnLabel: () => string
  checkbox?: () => string
  problemLink?: { url: string; analyticsEvent: string }
  onNext?: () => void
}

const OnboardingModal = ({ modalConfig }: { modalConfig: IModalConfig }) => {
  const { img, title, text, btnLabel, checkbox, problemLink, onNext } = modalConfig

  const [isChecked, setChecked] = useState(false)

  const handleNext = useCallback(() => {
    onNext?.()
  }, [onNext])

  const isBtnEnabled = isChecked || !l.getString(checkbox)

  return (
    <div
      data-test-id="onboarding-modal"
      className={cx(cm.root, 'p-fixed d-flex align-center justify-center')}
    >
      <div
        className={cx(
          cm.wrapper,
          'bg-white rounded p-x-lg p-t-md p-b-lg d-flex flex-col align-center text-center',
        )}
      >
        <img src={img} />
        <h1 className="m-0">{l.getString(title)}</h1>
        <span className="text-secondary d-block m-t-xxs">{l.getString(text)}</span>
        <If condition={l.getString(checkbox)}>
          <CheckboxV2
            className="m-t-lg"
            checked={isChecked}
            onChange={() => setChecked(!isChecked)}
            label={l.getString(checkbox)}
          />
        </If>
        <BtnV2
          className="m-t"
          variant="primary"
          label={l.getString(btnLabel)}
          onClick={handleNext}
          disabled={!isBtnEnabled}
        />
        {problemLink && (
          <a
            href={sanitizeURL(problemLink.url)}
            className="text-primary m-t-lg"
            target="_blank"
            onClick={() => analyticsService.sendEvent(problemLink?.analyticsEvent || '')}
            rel="noreferrer"
          >
            {l.translate('I have a problem')}
          </a>
        )}
      </div>
    </div>
  )
}

export default OnboardingModal

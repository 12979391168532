import { OnboardingScope } from 'apps/instagramOnboarding/onboardingInterfaces'
import * as atypes from 'apps/instagramOnboarding/onboardingReduxActionTypes'
import * as API from 'utils/services/newOnboardingService/onboardings/onboardingAPI'

export const fetchCompletedOnboardings = () => ({
  type: atypes.GET_COMPLETED_ONBOARDINGS,
  $fetch: [
    API.endpoints.getCompletedTours,
    {
      method: 'GET',
      headers: { 'Content-Type': 'application/json' },
    },
  ],
})

export const markOnboardingCompleted = (onboardingId: string, scope: OnboardingScope) => ({
  type: atypes.MARK_ONBOARDING_COMPLETED,
  onboardingId,
  scope,
  $fetch: [
    API.endpoints.markTourCompleted,
    {
      method: 'POST',
      body: JSON.stringify({ tour_id: onboardingId, scope }),
      headers: { 'Content-Type': 'application/json' },
    },
  ],
})

import { Layout } from 'utils/router/components/Layout/Layout'

export const WebViewRoutes = [
  {
    path: 'webview/:flow_ns',
    Component: Layout,
    children: [
      {
        index: true,
        lazy: () => import('./flowMobileWebView.page'),
      },
    ],
  },
  {
    path: 'webview-page',
    Component: Layout,
    children: [
      {
        path: 'questionnaire',
        alias: 'webViewQuestionnaire',
        query: ['allowClose'],
        lazy: () => import('./questionnaire.page'),
      },
    ],
  },
] as const

import get from 'lodash/get'

import { ExternalTriggerStatusType } from 'apps/externalTriggers/externalTriggerConstants'

export const getById = (state: RootState, id: string | number) => {
  return get(state, `externalTriggers.byId.${id}`, null)
}

export const getItemById = (state: RootState, id: string | number) => {
  return get(getById(state, id), 'item', null)
}

export const hasChanges = (state: RootState, id: string | number) => {
  const externalTrigger = getById(state, id)
  return Boolean(externalTrigger && externalTrigger.hasChanges)
}

export const hasUnsavedDraft = (state: RootState, id: string | number) => {
  const trigger = getItemById(state, id)

  return trigger?.statusType === ExternalTriggerStatusType.INITIAL
}

export const getStatus = (state: RootState, id: string | number) => {
  const externalTrigger = getItemById(state, id) || {}
  return externalTrigger.statusType === ExternalTriggerStatusType.ACTIVE
    ? ExternalTriggerStatusType.ACTIVE
    : ExternalTriggerStatusType.INACTIVE
}

import React, { forwardRef, HTMLProps } from 'react'
import cx from 'classnames'
import { v4 as uuid } from 'uuid'

import cleanProps from '../../../lib/clean-react-props'

import { FormFieldLabelContainer } from './FormFieldLabelContainer'
import { FormFieldTextBelow } from './FormFieldTextBelow'
import { FormTextFieldProps, FormTextFieldPrivateProps } from './interfaces'

import cm from './FormTextField.module.css'

const DEFAULT_INFOTEXT_WIDTH = 200

type Props = FormTextFieldProps<HTMLInputElement> & FormTextFieldPrivateProps
export const FormTextField = forwardRef<
  HTMLInputElement,
  Props & Omit<HTMLProps<HTMLInputElement>, 'ref'>
>(
  (
    {
      variant = 'default',
      fieldClasses = {},
      defaultValue,
      id,
      type,
      name,
      value,
      required,
      invalid,
      disabled,
      label,
      ariaLabel,
      ariaLabelledBy,
      labelId,
      placeholder,
      helpText,
      errorText,
      infoText,
      infoTextWidth = DEFAULT_INFOTEXT_WIDTH,
      className,
      labelClassName,
      children,
      onKeyDown,
      onBlur,
      ...props
    },
    inputRef,
  ) => {
    const fieldLabelId = React.useMemo(() => labelId || `${uuid()}-label`, [labelId])
    const fieldInputId = React.useMemo(() => id || `${uuid()}-input`, [id])
    const hasLabel = label || props.lozenge || infoText || required
    const hasTextBelow = helpText || errorText
    const hasAdditionalText = hasLabel || hasTextBelow

    const inputClasses = cx(cm.input, invalid && cm['input--invalid'], fieldClasses?.input, {
      [cm.transparent]: variant === 'transparent',
      [cm.filled]: variant === 'filled',
    })

    fieldClasses.label = cx(
      fieldClasses?.label,
      hasLabel && cm['text-field__label'],
      disabled && cm['text-field__label--disabled'],
    )
    fieldClasses.helpText = cx(fieldClasses?.helpText, hasTextBelow && cm['text-field__help-text'])

    return (
      <div className={cx(cm.textContainer, hasAdditionalText && cm.textContainerBlock, className)}>
        {label && (
          <FormFieldLabelContainer
            inputId={fieldInputId}
            label={label}
            id={fieldLabelId}
            infoText={infoText}
            lozenge={props.lozenge}
            infoTextWidth={infoTextWidth}
            required={required}
            disabled={disabled}
            fieldClasses={fieldClasses}
            labelClassName={labelClassName}
          />
        )}
        <div className={cx(cm.inputContainer, { 'm-t-xs': hasLabel, 'm-b-xs': hasTextBelow })}>
          <input
            ref={inputRef}
            defaultValue={defaultValue}
            type={type}
            id={fieldInputId}
            name={name}
            value={value}
            placeholder={placeholder}
            onChange={props.onChange}
            onKeyDown={onKeyDown}
            onBlur={onBlur}
            tabIndex={props.tabIndex}
            required={required}
            disabled={disabled}
            className={inputClasses}
            aria-invalid={invalid}
            aria-disabled={disabled}
            /** if label is provided, id is inherited from label. Otherwise the user has to provide it */
            aria-labelledby={label ? fieldLabelId : ariaLabelledBy}
            aria-label={ariaLabel}
            {...cleanProps(props, [], [])}
          />
          {children}
        </div>
        <FormFieldTextBelow
          helpText={helpText}
          errorText={errorText}
          showError={invalid}
          disabled={disabled}
        />
      </div>
    )
  },
)

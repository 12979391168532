import React from 'react'

import cm from './Skeleton.module.css'

interface IProps {
  width?: number
  height?: number
  className?: string
}

const SkeletonImage = ({ width, height, className = '' }: IProps) => (
  <div className={`${cm.skeleton} ${className}`} style={{ width, height }} />
)

export default SkeletonImage
SkeletonImage.displayName = 'Skeleton.Image'

import { z } from 'zod'

import { ChannelType } from 'common/core/constants/ChannelType'
import { lightFlowSchema } from 'shared/api/common/schemas/lightFlow'

export const WelcomeMessageSchema = z.object({
  channel: z.nativeEnum(ChannelType),
  content_holder: z.string(),
  enabled: z.boolean(),
  flow: lightFlowSchema.nullable(),
})

export const WelcomeMessageApiSchemas = {
  getData: {
    query: z.object({
      channel: z.nativeEnum(ChannelType),
    }),
    path: z.undefined(),
    response: WelcomeMessageSchema,
  },
  switch: {
    query: z.object({
      channel: z.nativeEnum(ChannelType),
      welcome_enabled: z.number(),
    }),
    path: z.undefined(),
    response: WelcomeMessageSchema,
  },
  replaceFlow: {
    query: z.undefined(),
    path: z.undefined(),
    request: z.object({
      channel: z.nativeEnum(ChannelType),
      flow_ns: z.string().nullable().optional(),
    }),
    response: WelcomeMessageSchema,
  },
  register: {
    response: z.undefined(),
    path: z.undefined(),
    query: z.undefined(),
  },
  removeFlow: {
    query: z.undefined(),
    path: z.undefined(),
    request: z.object({
      channel: z.nativeEnum(ChannelType),
    }),
    response: WelcomeMessageSchema,
  },
}

export type WelcomeMessage = z.infer<typeof WelcomeMessageSchema>

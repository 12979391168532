import * as atypes from 'common/settings/SettingsReduxActionTypes'

export const adminsInitialState = { items: null, fetching: false, error: null }

export default function adminsReducer(state = adminsInitialState, action) {
  switch (action.type) {
    case atypes.BOT_SETTINGS_FETCH_REQUEST: {
      return { ...state, fetching: true, error: null }
    }
    case atypes.BOT_SETTINGS_FETCH_RESPONSE: {
      const { data } = action
      return { ...state, items: data.settings.admins, fetching: false, error: null, ready: true }
    }
    case atypes.BOT_SETTINGS_FETCH_ERROR: {
      return { ...state, items: null, fetching: false, ready: false }
    }
  }

  return state
}

import { getCurrentAccount } from 'common/core/selectors/appSelectors'
import localStorage from 'utils/localStorage'

import { FeatureFlagsService } from './featureFlagsService'

/** @deprecated */
export const accountFlags = new FeatureFlagsService(localStorage)

export const initializeFeatureFlagsServices = (state: RootState) => {
  accountFlags.setFlags(getCurrentAccount(state).flags)
}

import React, {
  ReactNode,
  MouseEventHandler,
  HTMLProps,
  useState,
  useEffect,
  ElementType,
} from 'react'
import cx from 'classnames'
import { Link, LinkProps } from 'react-router-dom'

import { NavigationRoute } from 'common/core/router'
import { IfHasPermission, PermissionTargets } from 'common/userRoles'
import { Col, Row } from 'components/Grid'
import Label from 'components/Label'
import { linkURL } from 'utils/url'

import cm from './BarLink.module.css'

export interface BarLinkProps
  extends Pick<
    NavigationRoute,
    'url' | 'icon' | 'items' | 'testId' | 'navGroup' | 'isBeta' | 'showNotifications'
  > {
  label: string
  badge?: string
  href?: string
  target?: string
  active?: boolean
  isSidebarFolded?: boolean
  hasUnreadMessages?: boolean
  hasCompletedRNOnBoarding?: boolean
  onClick(props: BarLinkProps): void
  children?: ReactNode
  isSidebarHidden?: boolean
  isNavGroupItem?: boolean
}

export function BarLink(props: BarLinkProps) {
  const {
    url,
    href,
    target = '_blank',
    label,
    icon,
    badge,
    items,
    testId,
    navGroup,
    active,
    isBeta,
    isSidebarFolded,
    hasUnreadMessages,
    hasCompletedRNOnBoarding,
    children,
    isSidebarHidden,
    isNavGroupItem,
    showNotifications,
    onClick = () => {},
  } = props

  const [hasNotification, setHasNotification] = useState(showNotifications)

  const handleSettingsClick: MouseEventHandler<HTMLAnchorElement> = (event) => {
    event.preventDefault()
    setHasNotification(false)
    onClick(props)
  }

  const handleClick = () => {
    onClick(props)
  }

  let Component: ElementType = Link

  const componentProps: Partial<
    HTMLProps<HTMLAnchorElement> & LinkProps & Record<`data-${string}`, string>
  > = {}

  if (href) {
    Component = 'a'
    componentProps.href = url
    componentProps.target = target
  } else {
    componentProps.to = linkURL(url)
    if (navGroup && Array.isArray(items)) {
      componentProps.to = linkURL(url + items[0].url)
    }
  }

  componentProps.onClick = handleClick

  if (testId) {
    componentProps['data-test-id'] = `nav-${navGroup ? 'group' : 'link'}-${testId}`
    componentProps['data-onboarding-id'] = `nav-${navGroup ? 'group' : 'link'}-${testId}`
  }

  if (label === 'Settings' && hasNotification) {
    componentProps.onClick = handleSettingsClick
  }

  const isChat = testId === 'chat'
  const isBroadCasts = label === 'Broadcasting'
  const messagesCount = badge && Number(badge) > 99 ? '99+' : badge
  const cn = cx(cm.link, {
    [cm.active]: active,
    [cm.groupOpen]: active,
    [cm.group]: navGroup,
    [cm.hideSidebarGroup]: isSidebarHidden && isNavGroupItem,
    [cm.activeHideSidebarItem]: active && isSidebarHidden && isNavGroupItem,
  })

  useEffect(() => {
    setHasNotification(showNotifications)
  }, [showNotifications])

  return (
    <Component {...componentProps} className={cn}>
      <Row middle className={cm.container}>
        {!isNavGroupItem && (
          <Col width={64} className={`text-center ${cm.icon}`}>
            {icon && React.isValidElement(icon) && icon}
          </Col>
        )}

        {(!isSidebarHidden || isNavGroupItem) && (
          <Col className="p-r-xs">
            <span className={cx(cm.navText)}>
              {label}
              {children}
              {isBeta && (
                <Label
                  sm
                  className={cx(cm.betaLabel, 'm-l-xs', cm.movableNotification, {
                    [cm.minified]: isSidebarFolded,
                  })}
                >
                  BETA
                </Label>
              )}
            </span>
          </Col>
        )}

        {Boolean(messagesCount) && !isSidebarHidden && (
          <span
            className={cx(
              cm.badge,
              cm.movableNotification,
              cm.movableShowBarNotification,
              'p-l-xs p-r-xs text-sm p-t-xxs p-b-xxs text-sm',
              {
                [cm.minified]: isSidebarFolded,
              },
            )}
          >
            {messagesCount}
          </span>
        )}

        {hasUnreadMessages && isSidebarHidden && isChat && (
          <span
            className={cx(
              cm.circle,
              cm.movableNotification,
              cm.movableHideBarNotification,
              'd-inline-block  rounded-circle m-b-xs',
              {
                [cm.minified]: isSidebarFolded,
              },
            )}
          />
        )}

        <IfHasPermission target={PermissionTargets.LOGS}>
          {hasNotification ? (
            <div
              className={cx(cm.hasNotificationsLabel, cm.movableNotification, {
                [cm.minified]: isSidebarFolded || isSidebarHidden,
              })}
            />
          ) : null}
        </IfHasPermission>

        {isBroadCasts && hasCompletedRNOnBoarding && (
          <div
            className={cx(
              cm.hasNotificationsLabel,
              cm.movableNotification,
              cm.hasCompletedBroadcast,
              {
                [cm.minified]: isSidebarFolded || isSidebarHidden,
              },
            )}
          />
        )}
      </Row>
    </Component>
  )
}

export const EntityLabel = {
  FLOW: 'Automations',
  KEYWORD: 'Keywords',
  MENU: 'Menu',
  RULE: 'Trigger Rules',
  GROWTH_TOOL: 'Growth Tools',
  SEQUENCE: 'Sequences',
  WELCOME_MESSAGE: 'Welcome Message',
  DEFAULT_REPLY: 'DefaultReply',
  FOLLOW_UP: 'Follow up to collect subscribers',
  ADS_GT: 'Ads',
  INTEGRATIONS: 'Integrations',
  SMART_SEGMENT: 'Segments',
}

export const statsEntitiesToEntityTypeMapping = {
  default_reply: EntityLabel.DEFAULT_REPLY,
  collectOptIns: EntityLabel.FOLLOW_UP,
  flows: EntityLabel.FLOW,
  growth_tools: EntityLabel.GROWTH_TOOL,
  keywords_rules: EntityLabel.KEYWORD,
  sequences: EntityLabel.SEQUENCE,
  welcome_message: EntityLabel.WELCOME_MESSAGE,
  trigger_rules: EntityLabel.RULE,
  menu: EntityLabel.MENU,
  ads: EntityLabel.ADS_GT,
  integrations: EntityLabel.INTEGRATIONS,
  smart_segments: EntityLabel.SMART_SEGMENT,
}

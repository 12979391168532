import { AxiosError, AxiosResponse } from 'axios'

import type { BusinessError, ErrorEndpoint } from './types'

export const getMessage = (errors: BusinessError[]) => {
  return errors.reduce((message, error) => `${message}${message ? '|' : ''}${error.message}`, '')
}

export class ResponseError extends AxiosError {
  $errors: BusinessError[]
  endpoint: ErrorEndpoint

  constructor(response: AxiosResponse<{ $errors: BusinessError[] }>) {
    const message = getMessage(response.data.$errors)

    super(message, AxiosError.ERR_BAD_RESPONSE, response.config, response.request, response)
    this.name = 'ResponseError'
    this.$errors = response.data.$errors
    this.endpoint = response.config.url
  }
}

export class InvalidErrorResponseError extends AxiosError {
  endpoint: ErrorEndpoint

  constructor(response: AxiosResponse<{ $errors: BusinessError[] }>) {
    super(
      'Server responded with state=false but response does not contain $errors field',
      AxiosError.ERR_BAD_RESPONSE,
      response.config,
      response.request,
      response,
    )
    this.name = 'InvalidErrorResponseError'
    this.endpoint = response.config.url
  }
}

export class UnhandledBusinessError extends Error {
  error: BusinessError
  endpoint: ErrorEndpoint

  constructor(error: BusinessError, endpoint: ErrorEndpoint) {
    super(error.original_message)

    this.name = 'UnhandledBusinessError'
    this.error = error
    this.endpoint = endpoint
  }
}

import React from 'react'

interface IfProps {
  condition: boolean | number | string | [] | null | undefined
  children?: React.ReactNode
}

/** @deprecated use `condition && <Component .../>` instead */
const If = ({ condition, children }: IfProps) => {
  if (!condition) return null

  return <>{children}</>
}

export default If

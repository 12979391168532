import { l } from '@manychat/manyui'

// Ratio of users who click on a specific link to the number of total delivered users
export function calculateCTR(clicks = 0, uniqueUsers = 0): number {
  return uniqueUsers === 0 ? 0 : (clicks / uniqueUsers) * 100
}

export function calculateConversion(rootSentUnique = 0, sentUnique = 0): number {
  return rootSentUnique === 0 || sentUnique === 0
    ? 0
    : Math.floor((sentUnique / rootSentUnique) * 100)
}

// "[CTR_value]%" or "N/A" if CTR > 100
export function formatCTR(ctr?: number | null, decimalPrecision = 0): string {
  return ctr != null && ctr <= 100 ? l.percent(ctr, decimalPrecision) : 'N/A'
}

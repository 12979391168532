import debugConstructor from 'debug'

import mcApi from 'utils/api/mcApi/mcApi'
import { getCookie } from 'utils/cookie'
import { getIsProductionHost } from 'utils/getIsProductionHost'
import { AnalyticsData } from 'utils/services/analytics/types'
import { ErrorTracking } from 'utils/services/errorTrackingService/interfaces/types'
import { linkDomain } from 'utils/url'

const debug = debugConstructor('analytics')

export enum flowBuilderEvents {
  OPEN_SIDEBAR = 1,
  DESELECT_ALL = 2,
  STARTING_STEP_CLICK = 3,
  GOTO_BASIC_BUILDER = 4,
  SELECT_NODE = 5,
  SELECT_BLOCK = 6,
  SELECT_BUTTON = 7,
  CLICK_NODE_PLACEHOLDER = 8,
  CLICK_NODE_TITLE = 9,
  GOTO_ANOTHER_FLOW = 10,
  COPY_NODE = 11,
  DELETE_NODE = 12,
  NEW_CONNECTOR_CREATION_START = 13,
  CONNECTOR_CREATED = 14,
  ONNECTOR_CREATION_CANCELED = 15,
  CHANGE_CONNECTOR_START = 16,
  DELETE_CONNECTOR = 17,
  CONNECTOR_CHANGE_CANCELLED = 18,
  DRAG_AND_DROP_NODE = 19,
  DRAG_AND_DROP_STARTING_STEP = 20,
  MOVE_CANVAS = 21,
  OPEN_CREATE_MENU = 22,
  CLOSE_CREATE_MENU = 23,
  CREATE_NODE_FROM_MENU = 24,
  SCALE_UP = 25,
  SCALE_DOWN = 26,
  AUTO_ARRANGE = 27,
  SCALE_UP_WHEEL = 28,
  SCALE_DOWN_WHEEL = 29,
  SWITCH_TO_V2 = 30,
  SWITCH_TO_V1 = 31,
  STATS_SOURCE_CLICK = 32,
  STATS_SOURCE_SELECTED = 33,
  GOTO_EDIT_MODE = 34,
  NODE_SELECTION = 35,
  MULTIPLE_SELECTION = 36,
  COPY_SELECTION = 37,
  PASTE_BUFFER = 38,
  OPEN_FLOW_BUILDER = 39,
}

export const EVENTS_MAP: Record<string, string> = {
  ANSWERED_AGENCY: 'Answered agency',
  CONNECTS_SMS: 'Connects sms',
  CONNECTS_EMAIL: 'Connects email',
  CLICK_LISTING_CARD: 'Clicks listing card',
  CLICK_LISTING_CONTACT: 'Clicks listing contact',
  CLICK_LISTING_MATCHING: 'Clicks listing matching',
  CLICK_LISTING_SEND_MESSAGE: 'Clicks listing send message',
  CLICK_LISTING_SEND_BRIEF: 'Clicks listing send brief',
  IDENTIFIED_AS_BUSINESS: 'Identified as business',
}

export const EXTERNAL_ANALYTICS_TYPES_MAP = {
  PIXEL: 'pixel',
  GA: 'ga',
}

export interface IMCA {
  event: (eventName: string, eventCallTime: number, data?: AnalyticsData | null) => Promise<void>
  loggedoutUserEvent: (eventName: string, eventCallTime: number) => Promise<void>
  logFlowBuilderEvent: (eventName: flowBuilderEvents, data?: SafeUnknownObject) => Promise<void>
  externalEvent: (eventName: string, userId: number) => void
  externalAnalytics: (userId: number | null) => Promise<void>
}

export default class MCAnalytics implements IMCA {
  private errorTrackingService: ErrorTracking.Service
  private getAccountId: () => string | null
  private fbq?: (arg1: string, arg2: string) => void

  constructor(
    getAccountId: () => string | null,
    errorTrackingService: ErrorTracking.Service,
    fbq?: (arg1: string, arg2: string) => void,
  ) {
    this.errorTrackingService = errorTrackingService
    this.getAccountId = getAccountId
    this.fbq = fbq
  }

  private getUrl = (accountID: string | null, baseURL = '/analytics/event'): string => {
    const url = accountID ? '/fb' + accountID + baseURL : baseURL
    return linkDomain(url)
  }

  event = async (
    eventName: string,
    eventCallTime: number,
    data: AnalyticsData | null = null,
  ): Promise<void> => {
    try {
      const url = this.getUrl(this.getAccountId())
      await mcApi.post(url, {
        body: {
          event: `APP.${eventName}`,
          data,
          event_call_time: eventCallTime,
          event_send_time: Date.now(),
        },
        keepalive: true,
      })
    } catch (err) {
      debug(`Error while sending analytics event, ${err}`)
    }
  }

  loggedoutUserEvent = async (eventName: string, eventCallTime: number): Promise<void> => {
    try {
      const url = '/analytics/visit'
      await mcApi.post(url, {
        body: {
          url: `${window.location}#${eventName}`,
          visitor_id: getCookie('visitorId'),
          utm: { utm_source: '', utm_content: '', utm_campaign: '', utm_term: '', utm_medium: '' },
          referrer: '',
          event_call_time: eventCallTime,
          event_send_time: Date.now(),
        },
        keepalive: true,
      })
    } catch (err) {
      debug(`Error while sending analytics to /visit, ${err}`)
    }
  }

  logFlowBuilderEvent = async (
    event: flowBuilderEvents,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    payload: SafeUnknownObject = {},
  ) => {
    //disable logger. Code remains for future use
    return
  }

  externalEvent = async (eventName: string, userId: number): Promise<void> => {
    try {
      await mcApi.post('/analytics/external/ProcessingEvent', {
        body: { userId, event: eventName },
        keepalive: true,
      })
    } catch (err) {
      this.errorTrackingService.trackError(err, {
        extra: {
          userId,
          event: eventName,
          reason: 'failed to push an externalEvent',
        },
      })
    }
  }

  externalAnalytics = async (userId: number | null) => {
    if (!userId) return

    let result
    try {
      result = await mcApi.post<{
        state: boolean
        events: Array<{ event_name: string; event_id: string }>
      }>(linkDomain('/analytics/external/getEvents'), {
        body: { type: EXTERNAL_ANALYTICS_TYPES_MAP.PIXEL },
        params: { credentials: 'include' },
        keepalive: true,
      })
    } catch (err) {
      this.errorTrackingService.trackError(err, {
        fingerprint: 'external-analytics-get-events',
        extra: {
          userId,
          reason: 'failed to get external analytics Events',
        },
      })
      return
    }
    const data = result

    if (!data.state) {
      return
    }

    const isProd = getIsProductionHost()
    const eventsIds: string[] = []
    if (isProd) {
      data.events?.forEach((item) => {
        try {
          if (EVENTS_MAP[item.event_name]) {
            this.fbq?.('track', EVENTS_MAP[item.event_name])
          }
          eventsIds.push(item.event_id)
        } catch (err) {
          this.errorTrackingService.trackError(err, {
            extra: {
              userId,
              reason: 'failed to Send external event',
            },
          })
        }
      })
    }
    try {
      await fetch(linkDomain('/analytics/external/updateEventsStatus'), {
        method: 'POST',
        body: JSON.stringify({
          type: EXTERNAL_ANALYTICS_TYPES_MAP.PIXEL,
          eventsIds,
        }),
        credentials: 'include',
        headers: { 'Content-Type': 'application/json' },
        keepalive: true,
      })
    } catch (err) {
      /**
       * We don't have to do anything with this error because fetch doesn't throw an error and an
       * error can appear here only because of ad blockers.
       */
    }
  }
}

import React, { useRef, useState } from 'react'
import cx from 'classnames'
import { Icon as ManyUIIcon } from '@manychat/manyui'

import HelpPopover from 'common/core/components/HelpPopover'
import { Col, Row } from 'components/Grid'
import { analyticsService } from 'utils/services/analytics'

import cm from './HelpBarLink.module.css'

export interface HelpBarLinkProps {
  label: string
  pageId: string
  isSidebarHidden: boolean
  isRightTimeAnimate: boolean
}

export function HelpBarLink(props: HelpBarLinkProps) {
  const { label, pageId, isSidebarHidden, isRightTimeAnimate } = props
  const [showPopover, setShowPopover] = useState(false)
  const rootRef = useRef<HTMLDivElement>(null)

  const togglePopover = () => {
    analyticsService.sendEvent('HELP.CLICK')

    const hasOpenUcModal = (window.__ucCmp?.cmpView?.currentView ?? 'none') !== 'none'
    if (hasOpenUcModal) {
      return
    }

    setShowPopover(!showPopover)
  }

  return (
    <div
      ref={isSidebarHidden ? rootRef : null}
      className={cx(showPopover ? 'text-success' : 'text-secondary', 'text-h-success pointer')}
      onClick={togglePopover}
    >
      <Row middle className={cm.barLink}>
        <Col width={64} className={`text-center ${cm.icon}`}>
          <ManyUIIcon.Question />
        </Col>

        {!isSidebarHidden && isRightTimeAnimate && (
          <Col className="p-r-xs ">
            <span ref={rootRef} className={cm.navText}>
              {label}
            </span>
          </Col>
        )}
      </Row>

      <HelpPopover
        open={showPopover}
        anchor={rootRef.current || undefined}
        pageId={pageId}
        onRequestClose={togglePopover}
      />
    </div>
  )
}

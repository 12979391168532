import { KeywordInitial } from 'apps/keywords/keywordsInterfaces'
import { ConditionTypes } from 'apps/keywords/models/KeywordRule/constants'

export const KeywordRuleDefaults: KeywordInitial = {
  sort: -1,
  fetching: false,
  rule_id: undefined,
  id: undefined,
  channel: undefined,
  keyword_rules: [{ condition: ConditionTypes.CONTAINS, keywords: [] }],
}

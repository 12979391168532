import React, { useMemo } from 'react'
import cx from 'classnames'
import { z } from 'zod'
import { l, Loader } from '@manychat/manyui'

import { BlockType } from '../../constants'
import { useBillingInfo, useCart, useDefaultContext } from '../../hooks'
import { registry } from '../../registry'
import { ClassNameSchema, isProductAddOn } from '../../schemas'
import { BlockProps } from '../../types'

import { Order } from './Order'
import { OrderSummaryContextSchema } from './OrderSummaryContextSchema'

import cm from './OrderSummary.module.css'

const OrderSummaryPropsSchema = z.object({
  className: ClassNameSchema,
  style: z.object({}).optional(),
})

const Component = ({ blockId, className, style }: BlockProps<typeof OrderSummaryPropsSchema>) => {
  const { loading } = useDefaultContext()
  const { cart, canUpgradeWithTrial } = useCart()
  const { isPro, isTrial, isTrialAvailable } = useBillingInfo()
  const addOnInOrder = useMemo(() => cart.find(isProductAddOn), [cart])
  const btnLabel =
    isTrialAvailable && !addOnInOrder ? l.translate('Start Pro for Free') : l.translate('Subscribe')

  const footerText = useMemo(() => {
    if (isTrialAvailable) {
      if (!canUpgradeWithTrial && addOnInOrder) {
        return l.translate(
          '{addOn} is not available with the free trial. You\'ll be billed immediately if you click "{btnLabel}". You can cancel your plan at any time.',
          { addOn: addOnInOrder.name, btnLabel },
        )
      }

      return l.translate(
        'If you choose the Trial option, we will not bill you immediately. A recurring monthly charge will begin after the trial period. You can cancel at any time during your trial.',
      )
    }

    if (isPro && !(isTrial && addOnInOrder)) {
      return l.translate(
        "When you grab a power up or a subscription halfway through the month, you only pay for the days you actually use them. We calculate the cost day by day, so you're not charged for the whole month upfront.",
      )
    }

    return l.translate(
      'You\'ll be billed immediately if you click "{btnLabel}". You can cancel your plan at any time.',
      { btnLabel },
    )
  }, [isTrialAvailable, addOnInOrder, isPro, isTrial, btnLabel, canUpgradeWithTrial])

  return (
    <div data-block={blockId} className={cx(cm.container, className)} style={style}>
      <h2>{l.translate('Order Summary')}</h2>

      <hr className="m-y" />

      <div>
        {loading ? (
          <Loader />
        ) : (
          <Order cart={cart} footerText={footerText} upgradeBtnText={btnLabel} />
        )}
      </div>
    </div>
  )
}

export const OrderSummary = registry.register(Component, {
  props: OrderSummaryPropsSchema,
  context: OrderSummaryContextSchema,
  type: BlockType.ORDER_SUMMARY,
})

export const AXIS_STYLE_PROP = {
  x: 'left',
  y: 'top',
}

export const ARROW_ORIGINS = {
  top: 'bottom center',
  bottom: 'top center',
  left: 'right center',
  right: 'left center',
}

export const ARROW_MARGINS = {
  top: 'm-b-xs',
  bottom: 'm-t-xs',
  left: 'm-r-xs',
  right: 'm-l-xs',
}

export const ARROW_AXIS = {
  top: 'y',
  bottom: 'y',
  left: 'x',
  right: 'x',
}

export const ARROW_OPPOSITES = {
  top: 'bottom',
  bottom: 'top',
  left: 'right',
  right: 'left',
}

export const AT_MAPPING = {
  top: {
    anchorOrigin: 'center top',
    popoverOrigin: 'center bottom',
  },
  bottom: {
    anchorOrigin: 'center bottom',
    popoverOrigin: 'center top',
  },
  left: {
    anchorOrigin: 'left center',
    popoverOrigin: 'right center',
  },
  right: {
    anchorOrigin: 'right center',
    popoverOrigin: 'left center',
  },
  'top-left': {
    anchorOrigin: 'left top',
    popoverOrigin: 'left bottom',
  },
  'top-right': {
    anchorOrigin: 'right top',
    popoverOrigin: 'right bottom',
  },
  'bottom-left': {
    anchorOrigin: 'left bottom',
    popoverOrigin: 'left top',
  },
  'bottom-right': {
    anchorOrigin: 'right bottom',
    popoverOrigin: 'right top',
  },
}

export const AutoPositionType = {
  NONE: 'none',
  AUTO: 'auto',
  FLIP: 'flip',
  SHIFT: 'shift',
  KEEP_AT_SCREEN: 'keep_at_screen',
}

export const AutoPositionTypes = [
  AutoPositionType.NONE,
  AutoPositionType.AUTO,
  AutoPositionType.FLIP,
  AutoPositionType.SHIFT,
  AutoPositionType.KEEP_AT_SCREEN,
]

import dot from 'dot-prop-immutable'

import * as liveChatATypes from 'apps/chat/constants/LiveChatReduxActionTypes'
import * as audienceATypes from 'common/audience/constants/AudienceReduxActionTypes'
import { StatusType } from 'common/audience/models/Subscriber'
import * as fieldsAtypes from 'common/fields/constants/fieldsReduxActionTypes'
import { makeCurrentItemReducer } from 'utils/factory'

const currentItemReducer = makeCurrentItemReducer('audience')

export default function subscriberReducer(state, action) {
  state = currentItemReducer(state, action)

  if (state.item) {
    if (state.item.id === action.user_id) {
      switch (action.type) {
        // CUFS
        case fieldsAtypes.USERFIELDS_SETSUBSCRIBERFIELD_RESPONSE: {
          const { field_id, value, field } = action
          const index = state.item.fields.findIndex((t) => String(t.field_id) === String(field_id))
          if (index >= 0) {
            return dot.set(state, `item.fields.${index}.data.value`, value)
          } else {
            return dot.merge(state, `item.fields`, {
              field_id,
              data: {
                value,
              },
              field,
            })
          }
        }
        // System Fields
        case fieldsAtypes.USERFIELDS_SETSYSTEMFIELD_RESPONSE: {
          const { field, value } = action
          return dot.set(state, `item.${field}`, value)
        }
      }
    }

    if (state.item.id === action.id) {
      switch (action.type) {
        // TAGS
        case audienceATypes.AUDIENCE_ADDTAG_REQUEST:
          return dot.set(state, 'item.tags', [action.tag, ...state.item.tags])
        case audienceATypes.AUDIENCE_ADDTAG_ERROR: {
          const index = state.item.tags.findIndex((t) => t.tag_id === action.tag.tag_id)
          if (index >= 0) {
            return dot.delete(state, `item.tags.${index}`)
          }
          return state
        }
        case audienceATypes.AUDIENCE_DELETETAG_REQUEST: {
          const index = state.item.tags.findIndex((t) => t.tag_id === action.tagId)
          return dot.merge(state, `item.tags.${index}`, { $deleting: true })
        }
        case audienceATypes.AUDIENCE_DELETETAG_RESPONSE: {
          const index = state.item.tags.findIndex((t) => t.tag_id === action.tagId)
          return dot.delete(state, `item.tags.${index}`)
        }
        case audienceATypes.AUDIENCE_DELETETAG_ERROR: {
          const index = state.item.tags.findIndex((t) => t.tag_id === action.tagId)
          return dot.delete(state, `item.tags.${index}.$deleting`)
        }

        // SEQUENCES
        case audienceATypes.AUDIENCE_ADDSEQUENCE_REQUEST: {
          return dot.set(state, 'item.sequences', [action.sequence, ...state.item.sequences])
        }
        case audienceATypes.AUDIENCE_ADDSEQUENCE_ERROR: {
          const index = state.item.sequences.findIndex(
            (t) => t.sequence_id === action.sequence.sequence_id,
          )
          if (index >= 0) {
            return dot.delete(state, `item.sequences.${index}`)
          }
          return state
        }

        case audienceATypes.AUDIENCE_DELETESEQUENCE_REQUEST: {
          const index = state.item.sequences.findIndex((t) => t.sequence_id === action.sequenceId)
          return dot.merge(state, `item.sequences.${index}`, { $deleting: true })
        }
        case audienceATypes.AUDIENCE_DELETESEQUENCE_RESPONSE: {
          const index = state.item.sequences.findIndex((t) => t.sequence_id === action.sequenceId)
          return dot.delete(state, `item.sequences.${index}`)
        }
        case audienceATypes.AUDIENCE_DELETESEQUENCE_ERROR: {
          const index = state.item.sequences.findIndex((t) => t.sequence_id === action.sequenceId)
          return dot.delete(state, `item.sequences.${index}.$deleting`)
        }

        case audienceATypes.AUDIENCE_UNSUBSCRIBE_RESPONSE: {
          return dot.set(state, `item.status`, StatusType.UNSUBSCRIBED)
        }

        case audienceATypes.AUDIENCE_UNSUBSCRIBEFROMSMS_RESPONSE: {
          return dot.set(state, `item.optin_phone`, false)
        }
        case audienceATypes.AUDIENCE_UNSUBSCRIBEFROMEMAIL_RESPONSE: {
          return dot.set(state, `item.optin_email`, false)
        }
        case audienceATypes.AUDIENCE_UNSUBSCRIBEFROMWHATSAPP_RESPONSE: {
          return dot.set(state, `item.optin_wa`, false)
        }
        case audienceATypes.AUDIENCE_UNSUBSCRIBEFROMINSTAGRAM_RESPONSE: {
          return dot.set(state, `item.optin_ig`, false)
        }

        case liveChatATypes.THREAD_USER_READ: {
          const ts = Date.now() / 1000
          const ts_ig_last_read = action.data.ts_ig_last_read ?? ts
          const ts_tt_last_read = action.data.ts_tt_last_read ?? ts
          const ts_last_read = action.data.ts_last_read ?? ts
          return dot.merge(state, `item`, { ts_ig_last_read, ts_last_read, ts_tt_last_read })
        }
      }
    }
  }

  return state
}

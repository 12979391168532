import first from 'lodash/first'
import last from 'lodash/last'
import some from 'lodash/some'
import startsWith from 'lodash/startsWith'
import tail from 'lodash/tail'
import take from 'lodash/take'
import values from 'lodash/values'

import { FSSmartFolders } from 'common/cms/models/FSObject/constants'
import { linkURL } from 'utils/url'

export const serializePath = (path = '') => {
  return path.replace(/^\//, '').replace(/\//g, '--')
}

export const deserializePath = (path = '') => {
  return '/' + path.replace(/--/g, '/')
}

export const getPathURL = (path = '') => linkURL(getPath(path))
export const getPath = (path = '') => `/cms/files/${serializePath(path)}`

export const getLastPart = (path = '') => {
  const parsed = path.split('/')
  return last(parsed)
}

/**
 * @param path
 * @example
 *  splitPath('/smart/41/flow_id') -> ['/', '/smart', '/smart/41', '/smart/41/flow_id']
 */
export const splitPath = (path = '') => {
  if (!path || !startsWith(path, '/')) {
    return []
  }

  if (path === '/') {
    return ['/']
  }

  const parsed = tail(path.split('/'))
  const paths = parsed.map((part, index) => `/${take(parsed, index + 1).join('/')}`)

  // here is a tricky point - smart folders are not located in the root /
  if (values(FSSmartFolders).includes(first(paths))) {
    return paths
  }

  return ['/', ...paths]
}

/**
 * @param path
 * @example
 *  getParentPath('/smart/41/flow_id') -> '/smart/41'
 */
export const getParentPath = (path = '') => {
  const paths = splitPath(path)
  const ancestors = take(paths, paths.length - 1)
  return last(ancestors)
}

/**
 * @param path
 */
export const isInsideSmartFolder = (path = '') => {
  return some(FSSmartFolders, (folder) => startsWith(path, folder))
}

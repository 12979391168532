export const INVITATION_ACCEPT = 'INVITATION_ACCEPT'
export const INVITATION_ACCEPT_RESPONSE = 'INVITATION_ACCEPT_RESPONSE'
export const INVITATION_FETCH = 'INVITATION_FETCH'
export const INVITATION_FETCH_REQUEST = 'INVITATION_FETCH_REQUEST'
export const INVITATION_FETCH_RESPONSE = 'INVITATION_FETCH_RESPONSE'

export const ACCESS_REQUESTS_FETCH = 'ACCESS_REQUESTS_FETCH'
export const ACCESS_REQUESTS_FETCH_RESPONSE = 'ACCESS_REQUESTS_FETCH_RESPONSE'

export const ACCESS_REQUESTS_APPROVE = 'ACCESS_REQUESTS_APPROVE'
export const ACCESS_REQUESTS_APPROVE_RESPONSE = 'ACCESS_REQUESTS_APPROVE_RESPONSE'
export const ACCESS_REQUESTS_APPROVE_ERROR = 'ACCESS_REQUESTS_APPROVE_ERROR'

export const ACCESS_REQUESTS_SET_STATUS = 'ACCESS_REQUESTS_SET_STATUS'
export const ACCESS_REQUESTS_DROP_STATUS = 'ACCESS_REQUESTS_DROP_STATUS'

export const ACCESS_REQUESTS_DECLINE = 'ACCESS_REQUESTS_DECLINE'
export const ACCESS_REQUESTS_DECLINE_REQUEST = 'ACCESS_REQUESTS_DECLINE_REQUEST'
export const ACCESS_REQUESTS_DECLINE_RESPONSE = 'ACCESS_REQUESTS_DECLINE_RESPONSE'

export const USER_ROLES_FETCH_USERS_REQUEST = 'USER_ROLES_FETCH_USERS_REQUEST'
export const USER_ROLES_FETCH_USERS_RESPONSE = 'USER_ROLES_FETCH_USERS_RESPONSE'
export const USER_ROLES_FETCH_USERS_ERROR = 'USER_ROLES_FETCH_USERS_ERROR'
export const USER_ROLES_UPDATE_ROLE_REQUEST = 'USER_ROLES_UPDATE_ROLE_REQUEST'
export const USER_ROLES_SET_BILLING_ACCESS = 'USER_ROLES_SET_BILLING_ACCESS'
export const USER_ROLES_SET_BILLING_ACCESS_REQUEST = 'USER_ROLES_SET_BILLING_ACCESS_REQUEST'
export const USER_ROLES_GENERATE_INVITE_LINK = 'USER_ROLES_GENERATE_INVITE_LINK'
export const USER_ROLES_GENERATE_INVITE_LINK_RESPONSE = 'USER_ROLES_GENERATE_INVITE_LINK_RESPONSE'
export const USER_ROLES_RESET_INVITE_LINK = 'USER_ROLES_RESET_INVITE_LINK'

export const CAN_CREATE_INVITE_LINK = 'CAN_CREATE_INVITE_LINK'
export const CAN_CREATE_INVITE_LINK_REQUEST = 'CAN_CREATE_INVITE_LINK_REQUEST'
export const CAN_CREATE_INVITE_LINK_RESPONSE = 'CAN_CREATE_INVITE_LINK_RESPONSE'
export const CAN_CREATE_INVITE_LINK_ERROR = 'CAN_CREATE_INVITE_LINK_ERROR'

export const CAN_SET_INBOX_USER = 'CAN_SET_INBOX_USER'
export const CAN_SET_INBOX_USER_REQUEST = 'CAN_SET_INBOX_USER_REQUEST'
export const CAN_SET_INBOX_USER_RESPONSE = 'CAN_SET_INBOX_USER_RESPONSE'
export const CAN_SET_INBOX_USER_ERROR = 'CAN_SET_INBOX_USER_ERROR'

export const SET_ADD_PAID_USER_MODAL_VISIBILITY = 'SET_ADD_PAID_USER_MODAL_VISIBILITY'
export const SET_DELETE_PAID_USER_MODAL_VISIBILITY = 'SET_DELETE_PAID_USER_MODAL_VISIBILITY'

export const USER_ROLES_SET_INBOX_USER = 'USER_ROLES_SET_INBOX_USER'
export const USER_ROLES_SET_INBOX_USER_REQUEST = 'USER_ROLES_SET_INBOX_USER_REQUEST'
export const USER_ROLES_SET_INBOX_USER_RESPONSE = 'USER_ROLES_SET_INBOX_USER_RESPONSE'
export const USER_ROLES_SET_INBOX_USER_ERROR = 'USER_ROLES_SET_INBOX_USER_ERROR'

export const USER_ROLES_SET_CONVERSATIONS_LIMIT = 'USER_ROLES_SET_CONVERSATIONS_LIMIT'
export const USER_ROLES_SET_CONVERSATIONS_LIMIT_REQUEST =
  'USER_ROLES_SET_CONVERSATIONS_LIMIT_REQUEST'
export const USER_ROLES_SET_CONVERSATIONS_LIMIT_RESPONSE =
  'USER_ROLES_SET_CONVERSATIONS_LIMIT_RESPONSE'
export const USER_ROLES_SET_CONVERSATIONS_LIMIT_ERROR = 'USER_ROLES_SET_CONVERSATIONS_LIMIT_ERROR'

export const USER_ROLES_SET_AUTO_ASSIGNMENT_ENABLED = 'USER_ROLES_SET_AUTO_ASSIGNMENT_ENABLED'
export const USER_ROLES_SET_AUTO_ASSIGNMENT_ENABLED_RESPONSE =
  'USER_ROLES_SET_AUTO_ASSIGNMENT_ENABLED_RESPONSE'

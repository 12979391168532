export const AUTO_ASSIGNMENT_RULES_GET_LIST = 'AUTO_ASSIGNMENT_RULES_GET_LIST'
export const AUTO_ASSIGNMENT_RULES_GET_LIST_ERROR = 'AUTO_ASSIGNMENT_RULES_GET_LIST_ERROR'
export const AUTO_ASSIGNMENT_RULES_GET_LIST_SUCCESS = 'AUTO_ASSIGNMENT_RULES_GET_LIST_SUCCESS'

export const AUTO_ASSIGNMENT_RULE_GET_SUCCESS = 'AUTO_ASSIGNMENT_RULE_GET_SUCCESS'

export const AUTO_ASSIGNMENT_RULE_CREATE_SUCCESS = 'AUTO_ASSIGNMENT_RULE_CREATE_SUCCESS'

export const AUTO_ASSIGNMENT_RULE_UPDATE_SUCCESS = 'AUTO_ASSIGNMENT_RULE_UPDATE_SUCCESS'

export const AUTO_ASSIGNMENT_RULE_SET_STATUS_SUCCESS = 'AUTO_ASSIGNMENT_RULE_SET_STATUS_SUCCESS'

export const AUTO_ASSIGNMENT_RULE_SET_TITLE_SUCCESS = 'AUTO_ASSIGNMENT_RULE_SET_TITLE_SUCCESS'

export const AUTO_ASSIGNMENT_RULE_DELETE_SUCCESS = 'AUTO_ASSIGNMENT_RULE_DELETE_SUCCESS'

export const AUTO_ASSIGNMENT_RULE_DUPLICATE_SUCCESS = 'AUTO_ASSIGNMENT_RULE_DUPLICATE_SUCCESS'

export const AUTO_ASSIGNMENT_RULES_SORT_SUCCESS = 'AUTO_ASSIGNMENT_RULES_SORT_SUCCESS'

import { PayloadAction, createSelector, createSlice } from '@reduxjs/toolkit'
import { createAppAsyncThunk } from 'reduxTyped'

import { CreateWaMessageTemplateState } from 'apps/posting/pages/BroadcastBuilder/interfaces/whatsAppMessageTemplate'
import { validateMessageTemplate } from 'apps/whatsApp/helpers/validateMessageTemplate'
import { handleCatch } from 'shared/api/lib/errors/handlers'
import { WhatsAppApi } from 'shared/api/requests/whatsApp'
import { createOrEditTemplateErrorHandler } from 'shared/api/requests/whatsApp/errorHandlers'
import {
  WaMessageTemplate,
  WaMessageTemplateButton,
  WaMessageTemplateCategoryId,
  WaMessageTemplateDraft,
  WaMessageTemplateHeaderType,
  WaMessageTemplateHeaderTypeAttachment,
} from 'shared/api/requests/whatsApp/schemas'

export const createWaMessageTemplateInitialState: CreateWaMessageTemplateState = {
  attachment: null,
  header: null,
  body: null,
  footer: null,
  buttons: [],
  example: null,
  category: null,
  languageCode: null,
  isLoading: false,
  humanName: 'Broadcast Template default name',
}

export const createMessageTemplate = createAppAsyncThunk<
  WaMessageTemplate | null,
  WaMessageTemplateDraft
>('createWaMessageTemplate/create', async (template) => {
  try {
    const result = await WhatsAppApi.createMessageTemplate({
      body: template as WaMessageTemplate,
    })
    return result.data.message_template
  } catch (error) {
    handleCatch(error, createOrEditTemplateErrorHandler)
    return null
  }
})

const createWaMessageTemplateSlice = createSlice({
  name: 'createWaMessageTemplate',
  initialState: createWaMessageTemplateInitialState,
  reducers: {
    setAttachment(
      state,
      action: PayloadAction<Partial<WaMessageTemplateHeaderTypeAttachment> | null>,
    ) {
      state.attachment = action.payload
    },
    setHeader(state, action: PayloadAction<string>) {
      state.header = action.payload
    },
    setBody(state, action: PayloadAction<string>) {
      state.body = action.payload
    },
    setFooter(state, action: PayloadAction<string>) {
      state.footer = action.payload
    },
    setExample(state, action: PayloadAction<Record<string, string>>) {
      state.example = action.payload
    },
    setButtons(state, action: PayloadAction<WaMessageTemplateButton[]>) {
      state.buttons = action.payload
    },
    setCategory(state, action: PayloadAction<WaMessageTemplateCategoryId>) {
      state.category = action.payload
    },
    setLanguageCode(state, action: PayloadAction<string>) {
      state.languageCode = action.payload
    },
    setHumanName(state, action: PayloadAction<string>) {
      state.humanName = action.payload
    },
    resetState(state) {
      state = createWaMessageTemplateInitialState
      return state
    },
    setIsLoading(state, action: PayloadAction<boolean>) {
      state.isLoading = action.payload
    },
  },
})

export const {
  resetState,
  setAttachment,
  setBody,
  setButtons,
  setCategory,
  setLanguageCode,
  setIsLoading,
  setHumanName,
  setHeader,
  setFooter,
  setExample,
} = createWaMessageTemplateSlice.actions

const getCreateWaMessageTemplateState = (state: RootState) =>
  state.whatsApp.createMessageTemplateOnBroadcast

export const getAttachment = createSelector(
  getCreateWaMessageTemplateState,
  (state) => state.attachment,
)

export const getHeader = createSelector(getCreateWaMessageTemplateState, (state) => state.header)
export const getBody = createSelector(getCreateWaMessageTemplateState, (state) => state.body)
export const getFooter = createSelector(getCreateWaMessageTemplateState, (state) => state.footer)
export const getExamples = createSelector(getCreateWaMessageTemplateState, (state) => state.example)

export const getButtons = createSelector(getCreateWaMessageTemplateState, (state) => state.buttons)

export const getCategory = createSelector(
  getCreateWaMessageTemplateState,
  (state) => state.category,
)

export const getIsLoading = createSelector(
  getCreateWaMessageTemplateState,
  (state) => state.isLoading,
)

export const getLanguageCode = createSelector(
  getCreateWaMessageTemplateState,
  (state) => state.languageCode,
)

export const getHumanName = createSelector(
  getCreateWaMessageTemplateState,
  (state) => state.humanName,
)

export const getMessageTemplateDraft = createSelector(
  getHeader,
  getBody,
  getFooter,
  getButtons,
  getExamples,
  getAttachment,
  (header, body, footer, buttons, examples, attachment) => {
    const messageTemplateDraft: WaMessageTemplateDraft = {
      body: body || '',
      buttons,
    }

    if (attachment) {
      messageTemplateDraft.header = attachment
    }

    if (header) {
      messageTemplateDraft.header = {
        type: WaMessageTemplateHeaderType.TEXT,
        text: header,
      }
    }

    if (footer) {
      messageTemplateDraft.footer = footer
    }

    if (examples !== null && Object.keys(examples).length > 0) {
      messageTemplateDraft.example = examples
    }

    return messageTemplateDraft
  },
)

export const getErrors = createSelector(
  getMessageTemplateDraft,
  getCreateWaMessageTemplateState,
  (messageTemplateDraft, state) => {
    return validateMessageTemplate(messageTemplateDraft, { humanName: state.humanName })
  },
)

export const getIsMessageTemplateValid = createSelector(
  getCreateWaMessageTemplateState,
  getErrors,
  (state, errors) =>
    Object.keys(errors).length === 0 && state.category !== null && state.languageCode !== null,
)

export default createWaMessageTemplateSlice.reducer

import React, { useEffect } from 'react'
import cx from 'classnames'
import { useAppSelector } from 'reduxTyped'
import { l } from '@manychat/manyui'

import { billing, UpgradeSource } from 'common/billing'
import { getUpgradeCaption } from 'common/billing/selectors/billingSelectors'
import { analyticsService } from 'utils/services/analytics'

import cm from '../StaticNotifications.module.css'

export const ExpiredAccountNotification = () => {
  useEffect(() => {
    analyticsService.sendEvent('NOTIFICATION_BAR.EXPIRED.SHOWN')
  }, [])

  const handleClick = () => {
    analyticsService.sendEvent('NOTIFICATION_BAR.EXPIRED.CLICKED')
    billing.requestUpgrade({ source: UpgradeSource.EXPIRED_NOTIFY_BAR })
  }

  const text = l.translate(
    'Your Pro subscription has expired and your account is now in the Free plan. Upgrade to continue automation with Pro features.',
  )

  const upgradeCaption = useAppSelector(getUpgradeCaption)

  return (
    <>
      <span>{text}</span>
      <a target="_blank" className={cx(cm.more, 'm-l-xs')} onClick={handleClick}>
        {upgradeCaption}
      </a>
    </>
  )
}

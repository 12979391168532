// audience
export const AUDIENCE_SHOW_MIGRATE_CUF_TO_SF_MODAL = 'AUDIENCE_SHOW_MIGRATE_CUF_TO_SF_MODAL'
export const AUDIENCE_CLOSE_MIGRATE_CUF_TO_SF_MODAL = 'AUDIENCE_CLOSE_MIGRATE_CUF_TO_SF_MODAL'

export const AUDIENCE_CREATE_SUBSCRIBER = 'AUDIENCE_CREATE_SUBSCRIBER'
export const AUDIENCE_CREATE_SUBSCRIBER_ERROR = 'AUDIENCE_CREATE_SUBSCRIBER_ERROR'
export const AUDIENCE_CREATE_SUBSCRIBER_REQUEST = 'AUDIENCE_CREATE_SUBSCRIBER_REQUEST'
export const AUDIENCE_CREATE_SUBSCRIBER_RESPONSE = 'AUDIENCE_CREATE_SUBSCRIBER_RESPONSE'
export const AUDIENCE_CREATE_SUBSCRIBER_SET_VALIDATION_ERRORS =
  'AUDIENCE_CREATE_SUBSCRIBER_SET_VALIDATION_ERRORS'

export const AUDIENCE_SET_IMPORT_FILE_DATA = 'AUDIENCE_SET_IMPORT_FILE_DATA'
export const AUDIENCE_TOGGLE_EXTRA_CHANNEL = 'AUDIENCE_TOGGLE_EXTRA_CHANNEL'
export const AUDIENCE_SET_IMPORT_HEADERS_MAP = 'AUDIENCE_SET_IMPORT_HEADERS_MAP'
export const AUDIENCE_IMPORT_GO_NEXT = 'AUDIENCE_IMPORT_GO_NEXT'
export const AUDIENCE_IMPORT_GO_PREV = 'AUDIENCE_IMPORT_GO_PREV'
export const AUDIENCE_SET_IMPORT_TAG = 'AUDIENCE_SET_IMPORT_TAGS'
export const AUDIENCE_DELETE_IMPORT_TAG = 'AUDIENCE_DELETE_IMPORT_TAG'
export const AUDIENCE_TOGGLE_IMPORT_AGREEMENT = 'AUDIENCE_TOGGLE_IMPORT_AGREEMENT'
export const AUDIENCE_IMPORT_SUBSCRIBERS = 'AUDIENCE_IMPORT_SUBSCRIBERS'
export const AUDIENCE_IMPORT_SUBSCRIBERS_REQUEST = 'AUDIENCE_IMPORT_SUBSCRIBERS_REQUEST'
export const AUDIENCE_IMPORT_SUBSCRIBERS_RESPONSE = 'AUDIENCE_IMPORT_SUBSCRIBERS_RESPONSE'
export const AUDIENCE_IMPORT_SUBSCRIBERS_ERROR = 'AUDIENCE_IMPORT_SUBSCRIBERS_ERROR'
export const AUDIENCE_IMPORT_UPDATE_STATUS = 'AUDIENCE_IMPORT_UPDATE_STATUS'
export const AUDIENCE_SHOW_IMPORT_CONTACTS_MODAL = 'AUDIENCE_SHOW_IMPORT_CONTACTS_MODAL'
export const AUDIENCE_CLOSE_IMPORT_CONTACTS_MODAL = 'AUDIENCE_CLOSE_IMPORT_CONTACTS_MODAL'
export const AUDIENCE_SHOW_SUBSCRIBER_MODAL = 'AUDIENCE_SHOW_SUBSCRIBER_MODAL'
export const AUDIENCE_HIDE_SUBSCRIBER_MODAL = 'AUDIENCE_HIDE_SUBSCRIBER_MODAL'
export const AUDIENCE_SHOW_CREATE_SUBSCRIBER_MODAL = 'AUDIENCE_SHOW_CREATE_SUBSCRIBER_MODAL'
export const AUDIENCE_CLOSE_CREATE_SUBSCRIBER_MODAL = 'AUDIENCE_CLOSE_CREATE_SUBSCRIBER_MODAL'

export const AUDIENCE_UPDATE_CURRENT_SUBSCRIBER = 'AUDIENCE_UPDATE_CURRENT_SUBSCRIBER'
export const AUDIENCE_UPDATE_PAUSE_AUTOMATION = 'AUDIENCE_UPDATE_PAUSE_AUTOMATION'

export const AUDIENCE_SEARCH_QUERY_CHANGED = 'AUDIENCE_SEARCH_QUERY_CHANGED'
export const AUDIENCE_FILTER_CHANGED = 'AUDIENCE_FILTER_CHANGED'
export const AUDIENCE_SELECTION_TYPE_CHANGED = 'AUDIENCE_SELECTION_TYPE_CHANGED'
export const AUDIENCE_TOGGLE_SUBSCRIBER_CHECKED = 'AUDIENCE_TOGGLE_SUBSCRIBER_CHECKED'

export const AUDIENCE_ADDTAG_REQUEST = 'AUDIENCE_ADDTAG_REQUEST'
export const AUDIENCE_ADDTAG_ERROR = 'AUDIENCE_ADDTAG_ERROR'
export const AUDIENCE_DELETETAG_REQUEST = 'AUDIENCE_DELETETAG_REQUEST'
export const AUDIENCE_DELETETAG_RESPONSE = 'AUDIENCE_DELETETAG_RESPONSE'
export const AUDIENCE_DELETETAG_ERROR = 'AUDIENCE_DELETETAG_ERROR'

export const AUDIENCE_ADDSEQUENCE_REQUEST = 'AUDIENCE_ADDSEQUENCE_REQUEST'
export const AUDIENCE_ADDSEQUENCE_ERROR = 'AUDIENCE_ADDSEQUENCE_ERROR'
export const AUDIENCE_DELETESEQUENCE_REQUEST = 'AUDIENCE_DELETESEQUENCE_REQUEST'
export const AUDIENCE_DELETESEQUENCE_RESPONSE = 'AUDIENCE_DELETESEQUENCE_RESPONSE'
export const AUDIENCE_DELETESEQUENCE_ERROR = 'AUDIENCE_DELETESEQUENCE_ERROR'

export const AUDIENCE_UNSUBSCRIBE_RESPONSE = 'AUDIENCE_UNSUBSCRIBE_RESPONSE'
export const AUDIENCE_UNSUBSCRIBEFROMSMS_RESPONSE = 'AUDIENCE_UNSUBSCRIBEFROMSMS_RESPONSE'
export const AUDIENCE_UNSUBSCRIBEFROMEMAIL_RESPONSE = 'AUDIENCE_UNSUBSCRIBEFROMEMAIL_RESPONSE'
export const AUDIENCE_UNSUBSCRIBEFROMWHATSAPP_RESPONSE = 'AUDIENCE_UNSUBSCRIBEFROMWHATSAPP_RESPONSE'
export const AUDIENCE_UNSUBSCRIBEFROMINSTAGRAM_RESPONSE =
  'AUDIENCE_UNSUBSCRIBEFROMINSTAGRAM_RESPONSE'

export const AUDIENCE_BULK_ACTION_PROCESSING = 'AUDIENCE_BULK_ACTION_PROCESSING'

export const AUDIENCE_DELETE_SUBSCRIBER_DATA = 'AUDIENCE_DELETE_SUBSCRIBER_DATA'
export const AUDIENCE_DELETE_SUBSCRIBER_DATA_REQUEST = 'AUDIENCE_DELETE_SUBSCRIBER_DATA_REQUEST'
export const AUDIENCE_DELETE_SUBSCRIBER_DATA_RESPONSE = 'AUDIENCE_DELETE_SUBSCRIBER_DATA_RESPONSE'
export const AUDIENCE_BULK_DELETE_SUBSCRIBER_DATA = 'AUDIENCE_BULK_DELETE_SUBSCRIBER_DATA'
export const AUDIENCE_DELETE_SUBSCRIBER_DATA_ERROR = 'AUDIENCE_DELETE_SUBSCRIBER_DATA_ERROR'
export const AUDIENCE_EXPORT_SUBSCRIBER_DATA = 'AUDIENCE_EXPORT_SUBSCRIBER_DATA'
export const AUDIENCE_EXPORT_SUBSCRIBER_DATA_REQUEST = 'AUDIENCE_EXPORT_SUBSCRIBER_DATA_REQUEST'
export const AUDIENCE_EXPORT_SUBSCRIBER_DATA_RESPONSE = 'AUDIENCE_EXPORT_SUBSCRIBER_DATA_RESPONSE'
export const AUDIENCE_EXPORT_SUBSCRIBER_DATA_ERROR = 'AUDIENCE_EXPORT_SUBSCRIBER_DATA_ERROR'
export const AUDIENCE_UPDATE_EXPORTING_STATUS = 'AUDIENCE_UPDATE_EXPORTING_STATUS'
export const AUDIENCE_RESET_DOWNLOAD_CONFIRM = 'AUDIENCE_RESET_DOWNLOAD_CONFIRM'

// subscriber list
export const SUBSCRIBERS_DROP_LIST = 'SUBSCRIBERS_DROP_LIST'

export const AUDIENCE_FETCH_RESPONSE = 'AUDIENCE_FETCH_RESPONSE'
export const AUDIENCE_FETCH_REQUEST = 'AUDIENCE_FETCH_REQUEST'
export const AUDIENCE_FETCH_ERROR = 'AUDIENCE_FETCH_ERROR'

import isEmpty from 'lodash/isEmpty'
export const hasDraft = (item) => {
  if (!item || !item.draftId) {
    return false
  }
  return !isEmpty(item.draft) || !item.adSetId
}

export const isFlexibleSpecItemIsNotEmpty = (item) => {
  if (item) {
    return (item.interests && item.interests.length) || (item.behaviors && item.behaviors.length)
  }

  return null
}

import React from 'react'
import { useAppDispatch } from 'reduxTyped'
import { BtnV2, l } from '@manychat/manyui'

import { useAvailableChannelRefreshPermissions } from 'common/core/hooks/useAvailableChannelRefreshPermissions'
import { refreshPermissionsActions } from 'common/core/reducers/refreshPermissions'

export const HelpRefreshPermissionButton = ({ onClick }: { onClick: () => void }) => {
  const channelsAvailableForRefresh = useAvailableChannelRefreshPermissions()

  const isNeedToShowButton = channelsAvailableForRefresh.length > 0
  const isOnlyOneAvailable = channelsAvailableForRefresh.length === 1

  const dispatch = useAppDispatch()

  const handleRefreshPermissionsClick = () => {
    if (isOnlyOneAvailable) {
      const { href } = channelsAvailableForRefresh[0]

      window.open(href, '_self')
    } else {
      dispatch(refreshPermissionsActions.open())
    }

    onClick()
  }

  if (!isNeedToShowButton) {
    return null
  }

  return (
    <div className="text-center p-x-md p-y b-y">
      <div className="m-b-xs text-sm text-secondary">
        {l.translate('Facing issues with Automations?')}
      </div>
      <BtnV2
        variant="default"
        className="section-btn"
        onClick={handleRefreshPermissionsClick}
        fullWidth
      >
        {l.translate('Refresh Permissions')}
      </BtnV2>
    </div>
  )
}

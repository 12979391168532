import { l } from '@manychat/manyui'

import * as blockHelpers from 'common/builder/blocks/blockHelpers'
import { IBlock } from 'common/builder/blocks/blockInterfaces'
import { BlockType } from 'common/builder/constants/BlockType'
import { FBMessagingType } from 'common/builder/constants/facebookMessaging'
import { NodeType } from 'common/builder/constants/NodeType'
import BlockModel from 'common/builder/models/Block'
import BaseNodeConfig from 'common/builder/nodes/BaseNodeConfig'
import { IContentNode, INodeBase } from 'common/builder/nodes/nodeInterfaces'

class ContentNodeConfig extends BaseNodeConfig<IContentNode> {
  nodeType = NodeType.CONTENT

  getCaption = () => l.translate('Send Message')
  getToolbarCaption = () => l.translate('Messenger')
  getCaptionWithCount = (n: number) =>
    l.translate('{n, plural, one {# Facebook Message} other {# Facebook Messages}}', { n })
  getNextStepCaption = () => l.translate('Messenger')

  icon = 'MessengerChannel'
  color = '#8492A6'
  headerColor = '#e1e5ea'

  ALLOWED_BLOCK_TYPES = [
    BlockType.TEXT,
    BlockType.ATTACHMENT,
    BlockType.CARDS,
    BlockType.LIST,
    BlockType.QUICK_REPLY,
    BlockType.FORM_QUESTION,
    BlockType.DELAY,
    BlockType.DYNAMIC,
    BlockType.ONE_TIME_NOTIFY_REQUEST,
  ]

  ALLOWED_BLOCK_MAX_COUNT = 101

  getDefaults = (): Omit<IContentNode, keyof INodeBase> => ({
    targetId: null,
    reasonId: null,
    messageTag: null,
    privateReply: null,
    onboardingElementId: 'content_node',
    type: 'default',
    $fbMessagingType: FBMessagingType.INSIDE_24_HOURS,
  })

  getInitialBlocks = (node: IContentNode) => {
    return [
      { type: BlockType.TEXT as const },
      { type: BlockType.QUICK_REPLY as const, id: blockHelpers.createQRBlockId(node.id) },
    ]
  }

  isEmpty = ({ node, blocks }: { node: IContentNode; blocks: IBlock[] }) => {
    if (node.messageTag || node.targetId) {
      return false
    }
    return this.areBlocksEmpty(blocks)
  }

  _isEmptyBlock = (block: IBlock) => {
    return [BlockType.TEXT, BlockType.QUICK_REPLY].includes(block.type) && BlockModel.isEmpty(block)
  }
}

export default new ContentNodeConfig()

import cloneDeep from 'lodash/cloneDeep'
import { v4 as uuid } from 'uuid'

import * as constants from './constants'
import defaults from './defaults'
import * as helpers from './helpers'

function create(initial = {}) {
  const id = initial.id || uuid()
  return Object.assign(cloneDeep(defaults), initial, { id })
}

export default {
  create,
  defaults,
  ...helpers,
  ...constants,
}

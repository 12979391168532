import assert from 'assert'

import intersection from 'lodash/intersection'

import { updateNode } from 'common/builder/actions/updateNode'
import { getNodeById } from 'common/builder/selectors/builder/entitySelectors'

export const reorderBlocks = (builderId, nodeId, order) => {
  return (dispatch, getState) => {
    const state = getState()

    assert(builderId, `reorderBlocks: builderId is required param`)
    assert(nodeId, `reorderBlocks: nodeId is required param`)
    assert(order, `reorderBlocks: order is required param`)

    const item = getNodeById(state, builderId, nodeId)
    assert(item, `reorderBlocks: email doesn't exist`)

    const blocksOrder = intersection(order, item.blocks)
    assert(blocksOrder.length === item.blocks.length, `reorderBlocks: missing ids in order param`)

    const changes = {
      blocks: order,
    }
    return dispatch(updateNode(builderId, nodeId, changes))
  }
}

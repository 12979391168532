import React, { useRef, useState } from 'react'
import cx from 'classnames'
import result from 'lodash/result'
import { l, Modal, Icon as Icons } from '@manychat/manyui'

import { withErrorBoundary } from 'common/core/components/ErrorBoundary'
import {
  NotificationItem,
  NotificationRemoveType,
} from 'common/core/components/NotifyBar/NotifyInterfaces'
import { NotificationBarType } from 'common/core/constants/NotificationBarType'
import { useToggle } from 'common/hooks/useToggle'
import If from 'components/If'
import errorTrackingService from 'utils/services/errorTrackingService'

import NotificationContent from './NotificationContent'

import cm from './Notification.module.css'

const ICON_TYPES: Record<NotificationBarType, React.ElementType> = {
  [NotificationBarType.WARNING]: Icons.Warning,
  [NotificationBarType.ERROR]: Icons.Warning,
  [NotificationBarType.SUCCESS]: Icons.CheckCircle,
  [NotificationBarType.NORMAL]: Icons.Info,
  [NotificationBarType.INFO]: Icons.Info,
  [NotificationBarType.DEFAULT]: Icons.Info,
}

const CLASSES = {
  [NotificationBarType.SUCCESS]: 'bg-success',
  [NotificationBarType.ERROR]: 'bg-danger',
  [NotificationBarType.WARNING]: 'bg-warning',
  [NotificationBarType.INFO]: 'bg-info',
  [NotificationBarType.NORMAL]: 'bg-info',
  [NotificationBarType.DEFAULT]: 'bg-info',
}

const STYLES = {
  [NotificationBarType.WARNING]: {
    color: 'black',
  },
  [NotificationBarType.ERROR]: {},
  [NotificationBarType.SUCCESS]: {},
  [NotificationBarType.INFO]: {},
  [NotificationBarType.DEFAULT]: {},
  [NotificationBarType.NORMAL]: {},
}

export const NOTIFICATION_HEIGHT = 51

interface NotifyBar {
  notification: NotificationItem
  onClose: (notification: NotificationItem) => void
}

const Notification = ({ notification, onClose }: NotifyBar) => {
  const ref = useRef<HTMLDivElement | null>(null)
  const { state, toggle: toggleModal } = useToggle()
  const [isOverflow, setIsOverflow] = useState(false)
  const { type: notificationType = NotificationBarType.DEFAULT } = notification
  const content = result(notification, 'render', null) ?? result(notification, 'message', '')
  const isValidElement = React.isValidElement(content)
  const CustomIcon = notification?.customIconName ? Icons[notification?.customIconName] : null
  const NotificationIcon = CustomIcon ?? ICON_TYPES[notificationType]

  React.useEffect(() => {
    const content = ref.current?.children?.[0]
    setIsOverflow(Boolean(content && content.scrollWidth > content.clientWidth))
  }, [ref])

  if (!isValidElement && !content) {
    errorTrackingService.trackWarningOnce(new Error(`Error displaying notification`), {
      extra: { notification },
      fingerprint: 'error-display-notification',
    })
    return null
  }

  const isRemovable = notification.removeType !== NotificationRemoveType.NEVER
  const isShownReadMore = isOverflow && !notification.isWithoutReadMore

  const handleClose = () => onClose(notification)

  return (
    <>
      <div className={cx(cm.wrapper, 'd-flex align-center p-a-xs p-l')}>
        <span
          className={cx(
            cm.iconWrapper,
            CLASSES[notificationType],
            'flex-shrink-0 align-center justify-center d-flex m-r-xs rounded-circle',
          )}
        >
          {NotificationIcon && <NotificationIcon size={16} style={STYLES[notificationType]} />}
        </span>
        <div ref={ref} className={cx(cm.content, 'd-flex align-center flex-grow-1 text-body')}>
          <NotificationContent content={content} />
        </div>
        <If condition={isShownReadMore}>
          <a className={cm.more} onClick={toggleModal}>
            {l.translate('Read More')}
          </a>
        </If>
        <If condition={isRemovable}>
          <Icons.Close size={24} onClick={handleClose} className={cx(cm.close, 'm-x-sm m-y-xxs')} />
        </If>
      </div>

      <Modal title="Notification Details" open={state === 'on'} onRequestClose={toggleModal}>
        <div className="p-x-xl p-b-xl p-t-md" style={{ lineHeight: '24px' }}>
          <NotificationContent content={content} />
        </div>
      </Modal>
    </>
  )
}

export default withErrorBoundary(Notification, {
  scope: 'layout',
  section: 'notifications',
  fallbackComponent: () => null,
})

import { BusinessError } from 'shared/api/lib/errors/business'

interface IsStillUpToDateError extends BusinessError {
  field: 'still_actual'
}

interface CannotConfirmNewNameError extends BusinessError {
  field: 'new_business_account_name'
}

interface IsCloudApiReconnectError extends BusinessError {}

interface CoexistenceContactImportError extends BusinessError {
  field: 'coexistence_import_contacts_period'
}

const isCoexistenceContactImportError = (
  error: BusinessError,
): error is CoexistenceContactImportError => {
  return error.field === 'coexistence_import_contacts_period'
}

export const IsStillUpToDateError = (error: BusinessError): error is IsStillUpToDateError =>
  error.field === 'still_actual'

export const IsCannotConfirmNewNameError = (
  error: BusinessError,
): error is CannotConfirmNewNameError => error.field === 'new_business_account_name'

export const IsCloudApiReconnectError = (error: BusinessError): error is IsCloudApiReconnectError =>
  error.message === 'Cloud API reconnect currently disabled'

export const whatsappGuards = {
  refreshHealthWidgetData: {
    IsStillUpToDateError,
  },
  confirmNewBusinessAccountName: {
    IsCannotConfirmNewNameError,
  },
  verifyCode: {
    IsCloudApiReconnectError,
  },
  coexistenceImportContacts: {
    isCoexistenceContactImportError,
  },
}

import { z } from 'zod'

const SmsBillingStatsSchema = z.object({
  sms_send_count: z.number(),
  sms_receive_count: z.number(),
  mms_send_count: z.number(),
  mms_receive_count: z.number(),
})

const EmailBillingStatsSchema = z.object({
  total_count: z.number(),
  total_cost: z.number(),
  charge_package_size: z.number(),
  stats_for_days: z.number(),
  free_started: z.object({
    total: z.number(),
    send: z.number(),
  }),
  free_package: z.object({
    total: z.number(),
    send: z.number(),
    expire_at: z.string(),
  }),
  daily_limit_used: z.number(),
  daily_limit_max: z.number(),
})

export const statsSchemas = {
  fetchSmsBillingStats: {
    query: z.undefined(),
    path: z.undefined(),
    request: z.undefined(),
    response: z.object({
      stats: SmsBillingStatsSchema,
    }),
  },
  fetchEmailBillingStats: {
    query: z.undefined(),
    path: z.undefined(),
    request: z.undefined(),
    response: z.object({
      stats: EmailBillingStatsSchema,
    }),
  },
}

export type SmsBillingStats = z.infer<typeof SmsBillingStatsSchema>
export type EmailBillingStats = z.infer<typeof EmailBillingStatsSchema>

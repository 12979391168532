import { AccountDisplayFlag } from 'apps/dashboard/constants/DisplayFlagType'
import { AutoRefill } from 'common/billing/wallet/walletInterfaces'
import {
  FacebookPolicyNotification,
  NotificationDirtyItem,
} from 'common/core/components/NotifyBar/NotifyInterfaces'
import { Ability } from 'common/core/constants/Ability'
import PaymentCurrencies from 'common/core/constants/PaymentCurrencies'
import { AccountUser } from 'common/core/interfaces/accountUser'
import { AllowedLanguages } from 'common/core/interfaces/allowedLanguages'
import { ICurrentAccount } from 'common/core/interfaces/currentAccount'
import { FreeVersionLimit } from 'common/core/interfaces/freeVersionLimit'
import { InitialUser } from 'common/core/interfaces/user'
import { IFBProfile, IGoogleProfile, ITelegramProfile } from 'common/profiles/profileInterfaces'
import mcApi from 'utils/api/mcApi/mcApi'
import { handleCatchError } from 'utils/handleCatchError'

export interface ResponseData {
  'app.FBAPP_ID': string
  'app.STRIPE_KEY': string
  'app.STRIPE_ADS_KEY': string
  'app.GOOGLE_PLACES_API_KEY': string
  'app.freeVersionLimit': FreeVersionLimit
  'app.allowedLanguages': AllowedLanguages
  'app.allowedLocaleSettings': null
  'app.csrf_token'?: string
  'app.user': InitialUser & {
    interface_data: {
      display_flags?: Record<AccountDisplayFlag, boolean>
    }
  }
  'data.accounts_count'?: number
  'data.templates.items': SafeUnknownObject[]
  'app.fbProfile'?: IFBProfile
  'app.googleProfile'?: IGoogleProfile
  'app.telegramProfile'?: ITelegramProfile
  'app.currentAccount': ICurrentAccount
  'app.currentAccountWallet': { balance: number; autoRefill: AutoRefill } | null
  'app.currentAccountUser': AccountUser
  'app.paymentCurrency': PaymentCurrencies
  'app.currentAccount.notifications': NotificationDirtyItem[]
  'app.currentAccount.abilities': Ability[]
  'app.currentAccountUser.fbPolicyEnforcementNotifications': FacebookPolicyNotification[]
  'app.currentTextVariables': Record<string, string | null>
  settings: Record<string, unknown>
}

// This request use only on production
export const getAppData = async (accountId: AccountId): Promise<ResponseData> => {
  try {
    return await mcApi.get<{ state: boolean } & ResponseData>(`/${accountId}/dashboard/getData`)
  } catch (error) {
    handleCatchError(error)
    throw error
  }
}

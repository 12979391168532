import dot from 'dot-prop-immutable'

import * as atypes from '../sequencesReduxActionTypes'

const initialState = {
  byId: {},
  fetching: false,
  listFetched: false,
  error: null,
  maxFreeSequences: null,
  isCreateDialogOpen: false,
  fetchArgsHash: null,
}

export default function sequencesReducer(state = initialState, action) {
  switch (action.type) {
    case atypes.SEQUENCE_LIST_LOAD_REQUEST: {
      return { ...state, fetching: true, fetchArgsHash: action.fetchArgsHash, error: null }
    }
    case atypes.SEQUENCE_LIST_DROP: {
      return { ...state, byId: {}, fetchArgsHash: null }
    }
    case atypes.SEQUENCE_LIST_LOAD_SUCCESS: {
      action.items.forEach((item) => {
        // sequences can be loaded in
        // - full representation (with all messages)
        // - only the basic information
        // therefore, it is important to carefully merge each item here
        // so that loading basic information does not overwrite the full representation
        state = dot.merge(state, ['byId', item.id], item)
      })
      return {
        ...state,
        fetching: false,
        error: null,
        listFetched: true,
        maxFreeSequences: action.maxFreeSequences,
        templateInstallations: action.templateInstallations,
        fetchArgsHash: action.fetchArgsHash,
      }
    }
    case atypes.SEQUENCE_LIST_LOAD_ERROR: {
      const { error } = action
      return { ...state, fetching: false, error }
    }
    case atypes.SEQUENCE_CREATE_SUCCESS:
    case atypes.SEQUENCE_CLONE_SUCCESS:
    case atypes.SEQUENCE_LOAD_ITEM_SUCCESS:
    case atypes.SEQUENCE_UPDATE_SUCCESS:
    case atypes.SEQUENCE_REORDER_MESSAGES_SUCCESS:
    case atypes.SEQUENCE_CREATE_MESSAGE_RESPONSE:
    case atypes.SEQUENCE_PATCH_MESSAGE_RESPONSE:
    case atypes.SEQUENCE_CREATED_NOTIFICATION:
    case atypes.SEQUENCE_UPDATED_NOTIFICATION: {
      const { item } = action
      return dot.merge(state, ['byId', item.id], item)
    }
    case atypes.SEQUENCE_REMOVE_SUCCESS:
    case atypes.SEQUENCE_REMOVED_NOTIFICATION: {
      return dot.delete(state, ['byId', action.sequenceId])
    }
    case atypes.SEQUENCE_REMOVE_MESSAGE_RESPONSE: {
      const item = dot.get(state, ['byId', action.sequenceId])
      const messages = item.messages.filter(
        (sequenceMessage) => sequenceMessage.id !== action.sequenceMessageId,
      )
      const messagesTotal = messages.length
      state = dot.set(state, ['byId', action.sequenceId, 'messagesTotal'], messagesTotal)
      return dot.set(state, ['byId', action.sequenceId, 'messages'], messages)
    }
    case atypes.SEQUENCE_MESSAGE_SET_FLOW_RESPONSE: {
      const item = dot.get(state, ['byId', action.sequenceId])
      const messages = item.messages.map((sequenceMessage) => {
        if (sequenceMessage.id !== action.sequenceMessageId) {
          return sequenceMessage
        }
        return action.item
      })
      return dot.set(state, ['byId', action.sequenceId, 'messages'], messages)
    }
    case atypes.SEQUENCE_OPEN_CREATE_DIALOG: {
      return { ...state, isCreateDialogOpen: true }
    }
    case atypes.SEQUENCE_CLOSE_CREATE_DIALOG: {
      return { ...state, isCreateDialogOpen: false }
    }
    case atypes.SEQUENCE_MESSAGE_SET_SENDING_SETTINGS_RESPONSE: {
      const { item } = action
      const sequence = dot.get(state, ['byId', item.sequenceId])
      const messages = sequence.messages.map((sequenceMessage) =>
        sequenceMessage.id !== item.sequence_message_id ? sequenceMessage : item,
      )
      return dot.set(state, ['byId', item.sequenceId, 'messages'], messages)
    }
  }

  return state
}

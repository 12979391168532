import { FSQuery } from 'common/cms/models/FSObject/constants'
import { FsItem } from 'common/cms/types'

export const getByPath = (state: RootState, path: FSQuery['path']) =>
  state.cms.fileSystem.byPath?.[path]

export const getByFlowId = (state: RootState, flowId: string) => {
  return (Object.values(state.cms.fileSystem.byPath ?? {}) as FsItem[]).reduce<
    Record<string, FsItem>
  >((acc, item) => {
    if ('flowId' in item && item.flowId) {
      acc[item.flowId] = item
    }
    return acc
  }, {})[flowId]
}

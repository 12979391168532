import React from 'react'
import cx from 'classnames'
import { l } from '@manychat/manyui'

import { useRoutePageId } from 'utils/router/tools'
import { useMessengerApi } from 'utils/services/zendeskService/useMessengerApi'

import { getRightPositionOfCustomButton } from '../freshchat/helpers'

import cm from './ZendeskMessagingWidget.module.css'

const ZendeskMessagingWidget = () => {
  const pageId = useRoutePageId()
  const { messengerApi, isWidgetOpen, isChatEnabled } = useMessengerApi({ pageId })

  const rightPositionOfCustomButton = getRightPositionOfCustomButton()

  if (!isChatEnabled) {
    return null
  }

  return (
    <button
      className={cx(cm.messagingWidgetTrigger, 'p-fixed text-center pointer', {
        'd-none': isWidgetOpen,
        'd-block': !isWidgetOpen,
      })}
      style={{ right: rightPositionOfCustomButton }}
      onClick={() => {
        messengerApi({
          type: 'messenger',
          command: 'open',
        })
      }}
    >
      {l.translate('Live Support')}
    </button>
  )
}

export default ZendeskMessagingWidget

import React from 'react'
import cx from 'classnames'
import { useAppSelector } from 'reduxTyped'
import { Icon } from '@manychat/manyui'

import { FolderContextMenu } from 'apps/cms/components/Flows/components/FolderCard/component/FolderContextMenu/FolderContextMenu'
import { useDnd } from 'apps/cms/components/Flows/components/FolderCard/hooks/useDnd'
import { useFolder } from 'apps/cms/components/Flows/components/FolderCard/hooks/useFolder'
import { useFolderByPath } from 'apps/cms/components/Flows/components/FolderCard/hooks/useFolderByPath'
import animationsCm from 'apps/cms/lib/styles/animations.module.css'
import { contentManagementSelectors } from 'apps/cms/store'
import { usePermission, PermissionTargets } from 'common/userRoles'

import cm from './FolderCard.module.css'

interface FolderCardProps {
  path: string
}

export const FolderCard = React.memo(function FolderCard({ path }: FolderCardProps) {
  const userCanEditFlow = usePermission(PermissionTargets.FLOW_EDITING)
  const { showFolderContextMenu } = useAppSelector(contentManagementSelectors.getUi)

  const folder = useFolderByPath(path)

  const { moveFolderToTargetFolder, moveFlowsToTargetFolder, openFolder } = useFolder({
    path: folder.path,
    id: folder.folder_id,
  })

  const { isDragging, highlightDrop, connectDropTarget, connectDragSource } = useDnd({
    folder,
    onFolderDrop: moveFolderToTargetFolder,
    onFlowsDrop: moveFlowsToTargetFolder,
  })

  return (
    <article
      ref={(element) => {
        connectDragSource(element)
        connectDropTarget(element)
      }}
      data-onboarding-id={`folder-${folder.folder_id}`}
      className={cx(
        cm.folderCard,
        highlightDrop && animationsCm.cmsDragTargetAnimation,
        isDragging && cm.folderCard_highlight,
      )}
    >
      <button onClick={openFolder} className={cm.folderCard__mainActionButton} type="button" />

      <Icon.Folder className={cm.folderCard__icon} />

      <h1 className={cx(cm.folderCard__folderName, 'text-body text-ellipsis')}>{folder.title}</h1>

      {userCanEditFlow && showFolderContextMenu && (
        <FolderContextMenu
          folder={folder}
          anchorClassName={cm.folderCard__contextMenuAnchor}
          anchorOpenedClassName={cm.folderCard__contextMenuAnchor_opened}
        />
      )}
    </article>
  )
})

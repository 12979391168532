import { EventEmitter } from 'eventemitter3'

import OnboardingStatus from 'utils/services/newOnboardingService/core/OnboardingStatus'
import StepController from 'utils/services/newOnboardingService/core/StepController'
import { IOnboardingRunContext } from 'utils/services/newOnboardingService/interfaces'
import OnboardingId from 'utils/services/newOnboardingService/OnboardingId'

class OnboardingBase<T extends Record<string, unknown>> extends EventEmitter {
  readonly id
  readonly steps

  private status
  private stepController

  private context?: IOnboardingRunContext

  constructor(id: OnboardingId, createStepController: () => StepController<T>) {
    super()

    this.id = id

    this.status = new OnboardingStatus()

    this.stepController = createStepController()
    this.stepController.onStepChange = this.handleStepChange

    this.steps = this.stepController.steps
  }

  private handleStepChange = (stepId: keyof T | undefined, step: T[keyof T] | undefined) => {
    this.emit('STEP_CHANGE', this, stepId, step)
  }

  getStatus = () => {
    return this.status.getStatus()
  }

  run = (context?: IOnboardingRunContext) => {
    this.context = context

    this.status.run()
    this.emit('RUN', this)
    this.stepController.run()
  }

  complete = () => {
    this.status.complete()
    this.stepController.complete()
    this.emit('COMPLETE', this)
  }

  skip = () => {
    this.status.skip()
    this.stepController.complete()
    this.emit('SKIP', this)
  }

  setStep(nextStepId: keyof T | undefined) {
    this.stepController.setStep(nextStepId)
  }

  getCurrentStepId() {
    return this.stepController.getCurrentStepId()
  }

  getCurrentStep() {
    return this.stepController.getCurrentStep()
  }

  getContext = () => {
    return this.context
  }
}

export default OnboardingBase

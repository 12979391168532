import { z } from 'zod'

import { DisplayPreferences } from 'common/settings/constants/DisplayPreferences'
import { BillingInfoSchema, BillingProSettingsSchema } from 'shared/api/requests/billing/schemas'

import { ConversionsApiSchema } from './ConversionsApiSchema'
import { CurrentUserSchema } from './CurrentUserSchema'
import { HandoverSchema } from './HandoverSchema'
import { LiveChatAccountSettingsSchema } from './LiveChatAccountSettingsSchema'
import { PaymentsSchema } from './PaymentsSchema'

export const SettingsSchema = z.object({
  billing_info: BillingInfoSchema,
  sms_opt_out_text: z.string(),
  body_url_shortener_selected: z.string(),
  body_url_shorteners_available: z.array(z.string()),
  isEmailSwitchError: z.boolean().optional(),
  isSMSSwitchError: z.boolean().optional(),
  greeting_text: z.string(),
  use_persona: z.boolean(),
  pro: BillingProSettingsSchema,
  disable_user_input: z.boolean().optional(),
  gate_status: z.string(),
  lc_threads_filter_enabled: z.boolean().optional(),
  current_user: CurrentUserSchema,
  livechat_account_settings: LiveChatAccountSettingsSchema,
  payments: PaymentsSchema,
  skip_flow_templates_modal: z.boolean().optional(),
  handover: HandoverSchema.optional(),
  automation_pause_seconds: z.number(),
  is_intercom_chat_visible: z.boolean(),
  audience_only_active: z.boolean(),
  display_preferences: z.record(z.nativeEnum(DisplayPreferences), z.boolean()),
  conversions_api: ConversionsApiSchema,
  widget_locale: z.string().optional(),
})

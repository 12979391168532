import { l } from '@manychat/manyui'

import { getAgentValidationSchema } from 'apps/aiAgentEditor/schema/interfaces'
import { AiAgentNodeType } from 'common/builder/nodes/nodeInterfaces'
import { getNodeById } from 'common/builder/selectors/builder/entitySelectors'
import { getAiAgentAvailableFields } from 'common/fields/linking/accountAvailableFields'

const validateAgentNode = (state: RootState, builderId: string, nodeId: string) => {
  const node = getNodeById(state, builderId, nodeId) as AiAgentNodeType
  const fields = getAiAgentAvailableFields(state)

  const schema = getAgentValidationSchema(fields.map(({ id }) => id))

  const isAgentValid = schema.safeParse(node.agent_data).success

  if (!isAgentValid) {
    return {
      [node.id]: [
        {
          prop: 'agent',
          message: l.translate('Complete AI Step to set live'),
          originalMessage: 'Complete AI Step to set live',
        },
      ],
    }
  }

  if (!node.channel) {
    return {
      [node.id]: [
        {
          prop: 'channel',
          message: l.translate('Please select a channel'),
          originalMessage: 'Please select a channel',
        },
      ],
    }
  }

  return {}
}

export const validationSelectorsTyped = {
  validateAgentNode,
}

import React, { useEffect, useState } from 'react'

import { newOnboardingService } from 'utils/services/newOnboardingService'
import { ManyChatOnboarding } from 'utils/services/newOnboardingService/ManyChatOnboardingIdType'
import { ManyChatOnboardingIdViewMap } from 'utils/services/newOnboardingService/ManyChatOnboardingMap'

const NewOnboardingServiceRoot = () => {
  const [onb, setOnb] = useState<null | ManyChatOnboarding>(null)

  const handleOnboardingRun = (onb: ManyChatOnboarding) => setOnb(onb)
  const handleOnboardingComplete = () => setOnb(null)
  const handleOnboardingSkip = () => setOnb(null)

  useEffect(() => {
    const clearListeners = newOnboardingService.onLifeCycle({
      onRun: handleOnboardingRun,
      onComplete: handleOnboardingComplete,
      onSkip: handleOnboardingSkip,
    })
    return clearListeners
  }, [])

  if (!onb) {
    return null
  }

  const OnboardingView = ManyChatOnboardingIdViewMap[onb.id]
  // eslint-disable-next-line
  // @ts-expect-error
  return <OnboardingView onb={onb} />
}

export default NewOnboardingServiceRoot

export const pseudoTranslate = (input: string) => {
  let openBraceCount = 0
  let openTagCount = 0
  return input
    .split('')
    .map((char) => {
      if (char === '{') openBraceCount++
      if (char === '<') openTagCount++
      if (char === '}') openBraceCount--
      if (char === '>') openTagCount--
      if (openBraceCount === 1 || openTagCount > 0) return char
      return preset.get(char) || char
    })
    .join('')
}

const preset = new Map<string, string>(
  Object.entries({
    a: 'э',
    b: 'б',
    c: 'ц',
    d: 'д',
    e: 'е',
    f: 'ф',
    g: 'г',
    h: 'х',
    i: 'и',
    j: 'дж',
    k: 'к',
    l: 'л',
    m: 'м',
    n: 'н',
    o: 'оу',
    p: 'п',
    q: 'ку',
    r: 'р',
    s: 'с',
    t: 'т',
    u: 'ю',
    v: 'ви',
    w: 'в',
    x: 'кс',
    y: 'йе',
    z: 'з',
  }),
)

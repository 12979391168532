/**
 * Enumeration of "abilities": string keys that
 * stand for account's permission, e.g. "account is able to ...".
 *
 * @see `useAbility`
 * @see `getHasAbility`
 */
export enum Ability {
  AI_KEYWORD = 'ai_keyword',
  AI_AGENT = 'ai_agent',
}

import { l } from '@manychat/manyui'

export const BODY = 'body'
export const RESPONSE = 'preview'
export const HEADERS = 'headers'
export const MAPPING = 'mapping'

export const GET = 'get'
export const POST = 'post'
export const PUT = 'put'
export const DELETE = 'delete'

export const TabOptions = [
  { value: HEADERS, getLabel: l.makeTranslate('Headers') },
  { value: BODY, getLabel: l.makeTranslate('Body') },
  { value: RESPONSE, getLabel: l.makeTranslate('Response') },
  { value: MAPPING, getLabel: l.makeTranslate('Response mapping') },
]

export const RequestTypeOptions = [
  { value: POST, label: 'POST' },
  { value: GET, label: 'GET' },
  { value: PUT, label: 'PUT' },
  { value: DELETE, label: 'DELETE' },
]

export const Entity = {
  DYNAMIC: 'dynamic_block',
  ACTION: 'external_request_action',
}

import React from 'react'
import { Icon } from '@manychat/manyui'

import { BaseTitle } from 'apps/cms/components/Flows/components/FlowCard/components/Triggers/components/BaseTitle/BaseTitle'
import { TikTokAdsTitle } from 'apps/cms/components/Flows/components/FlowCard/components/Triggers/components/TikTokAdsTitle/TikTokAdsTitle'
import {
  getRuns,
  getWidgetStatistics,
} from 'apps/cms/components/Flows/components/FlowCard/components/Triggers/hooks/useTriggers/lib/statistics'
import {
  ExternalTriggerStatusType,
  ExternalTriggerType,
} from 'apps/externalTriggers/externalTriggerConstants'
import { HotmartConfig } from 'common/actions/integrations/Hotmart/lib/config'
import { TikTokAdsConfig } from 'common/actions/integrations/TikTokAds/model/constants'
import { TriggerType } from 'common/flow/constants/TriggerType'
import { ExternalTrigger } from 'shared/api/requests/cms/schemas'

const getTriggerIcon = (triggerType: ExternalTriggerType) => {
  switch (triggerType) {
    case ExternalTriggerType.HOTMART_ABANDONED_CART:
    case ExternalTriggerType.HOTMART_POST_PURCHASE_CHANGE_STATUS:
      return <HotmartConfig.icon size={16} />
    case ExternalTriggerType.TIKTOK_ADS_CTX:
      return <TikTokAdsConfig.icon size={16} />
    default:
      return <Icon.OrphanIntegration size={16} />
  }
}

export const getExternalTrigger = (externalTrigger: ExternalTrigger) => {
  let title: React.ReactNode = <BaseTitle text={externalTrigger.title} />

  if (externalTrigger.trigger_key === ExternalTriggerType.TIKTOK_ADS_CTX) {
    title = <TikTokAdsTitle externalTrigger={externalTrigger} />
  }

  return {
    title,
    icon: getTriggerIcon(externalTrigger.trigger_key),
    disabled: externalTrigger.status_title !== ExternalTriggerStatusType.ACTIVE,
    runs: getRuns(externalTrigger.runs),
    ctr: 'n/a',
    id: externalTrigger.external_trigger_id,
    type: TriggerType.EXTERNAL_TRIGGER,
    status: externalTrigger.status_title,
    created: externalTrigger.ts_created,
    impressions: getWidgetStatistics(null),
    optIns: getWidgetStatistics(null),
  }
}

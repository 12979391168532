import React from 'react'
import { Icon } from '@manychat/manyui'

import UpdateIntegrationAlert from 'common/actions/integrations/common/components/UpdateIntegrationAlert'
import { IntegrationType } from 'common/actions/integrations/common/constants/IntegrationType'
import { alert } from 'common/core'
import { AccountTargetPermissionsNames } from 'common/core/models/Account/names'
import * as API from 'constants/API'
import { AccountFlag, UserFlag } from 'utils/services/featureFlagsService/constants'

interface IntegrationDraft {
  actions?: IntegrationAction[]
  createHref?: boolean
  description: string | (() => string | JSX.Element)
  featureFlag?: AccountFlag | UserFlag
  fullName?: string
  icon: Icon.Icon
  isBeta?: boolean
  logo?: string | null
  name: string
  permissionsVariable: AccountTargetPermissionsNames
  reduxActions: UnsafeAnyObject
  type: IntegrationType
}

export type Integration = Omit<IntegrationDraft, 'createDraft'> & {
  href: string
}

export interface IntegrationAction {
  type: string
  description: string
  name: string
  featureFlag?: AccountFlag | UserFlag
}

export const createIntegration = ({
  type,
  name,
  fullName,
  icon,
  isBeta = false,
  createHref = false,
  description,
  reduxActions,
  featureFlag,
  actions = [],
  permissionsVariable,
}: IntegrationDraft): Integration => {
  return {
    type,
    name,
    actions,
    description,
    featureFlag,
    fullName: fullName || name,
    href: createHref ? API.integrations.endpoints.connectIntegration.replace(':type', type) : '',
    icon,
    isBeta,
    permissionsVariable,
    reduxActions,
  }
}

export const createIntegrationAction = ({
  type,
  description,
  name,
  featureFlag,
}: IntegrationAction) => {
  return {
    type,
    description,
    name,
    featureFlag,
  }
}

export const getIntegrationAction = (integration: Integration, actionType: string) => {
  return integration.actions?.find((action) => action.type === actionType)
}

export const updateIntegrationAlert = (message: string, integration: IntegrationType) => {
  return alert(message, undefined, {
    icon: 'Warning',
    content: <UpdateIntegrationAlert integration={integration} />,
    isTemporary: false,
  })
}

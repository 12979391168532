export enum UserFlag {
  DISABLE_MOBILE_BANNER_EXPERIMENT = 'disable_mobile_banner_experiment',
  ENABLE_AGENCY_PARTNER_STACK = 'enable_agency_partner_stack',
  ENABLE_MOBILE_BANNER_EXPERIMENT = 'enable_mobile_banner_experiment',
  IS_ADS_FB_CHILD_BM_ENABLED = 'is_ads_fb_child_bm_enabled',
  MANDATORY_EMAIL_VERIFICATION = 'mandatory_email_verification',
  ENABLE_APP_INTERCOM_REMOVE = 'enable_app_intercom_remove',
  ENABLE_AI_INTERVIEWS_GENERAL = 'enable_ai_interviews_general',
  ENABLE_AI_INTERVIEWS_HOME_TAB = 'enable_ai_interviews_home_tab',
  ENABLE_USER_PUBLIC_WA_COEXISTENCE = 'enable_user_public_wa_coexistence',
}

export enum AccountFlag {
  ENABLE_WA_COEXISTENCE = 'enable_wa_coexistence',
  ENABLE_FACELIFTING_FOR_CONFERENCE = 'enable_facelifting_for_conference',
  ENABLE_IG_RECURRING_NOTIFICATION = 'enable_ig_recurring_notification',
  ENABLE_INBOX = 'enable_inbox',
  ENABLE_INDIAN_CUSTOMERS_3DS_RESUBSCRIPTION = 'enable_indian_customers_3ds_resubscription',
  ENABLE_LC_STATISTICS = 'enable_lc_statistics',
  ENABLE_WEBVIEW = 'enable_webview',
  FETCH_STORIES_FROM_CDN = 'fetch_stories_from_cdn',
  UKRAINE_2022_SUPPORT = 'ukraine_2022_support',
  UKRAINE_2022_SUPPORT_SHOW_MODAL = 'ukraine_2022_support_show_modal',
  WA_CLOUD_API_RECONNECT_ALLOWED = 'wa_cloud_api_reconnect_allowed',
  ENABLE_DM_LIST_IMPROVEMENTS = 'enable_dm_list_improvements',
  ENABLE_IG_CROSS_CHANNEL_BUTTON = 'enable_ig_cross_channel_button',
  ENABLE_WA_CATALOG = 'enable_wa_catalog',
  NOT_CHECKED_FOR_IMPORT_UNIFIED_SUBSCRIBERS = 'not_checked_for_import_unified_subscribers',
  ENABLE_GOOGLE_SHEETS_ACTION_CONFIG_FALLBACK = 'google_sheets_action_config_fallback',
  ENABLE_HIGHLIGHT_INSTRUMENT = 'enable_highlight_instrument',
  UPGRADE_PAGE_GROUP_A = 'upgrade_bb_a',
  UPGRADE_PAGE_GROUP_B = 'upgrade_bb_b',
  TIER_NOTIFICATION_ENABLE = 'enable_90_tier_notification',
  TIER_NOTIFICATION_DISABLE = 'disable_90_tier_notification',
  ENABLE_EASY_BUILDER_STORY_REPLY = 'enable_easy_builder_story_reply',
  DISABLE_EASY_BUILDER_STORY_REPLY = 'disable_easy_builder_story_reply',
  ENABLE_EASY_BUILDER_STORY_REPLY_SEQUEL = 'enable_easy_builder_story_reply_sequel',
  DISABLE_EASY_BUILDER_STORY_REPLY_SEQUEL = 'disable_easy_builder_story_reply_sequel',
  ENABLE_EASY_BUILDER_VS_QUICK = 'enable_easy_builder_vs_quick',
  DISABLE_EASY_BUILDER_VS_QUICK = 'disable_easy_builder_vs_quick',
  ENABLE_AI_BUSINESS_CONTEXT = 'enable_ai_business_context',
  ENABLE_HIGHLIGHT_FOR_TIKTOK = 'enable_highlight_for_tiktok',
  ENABLE_AI_PAYWALL = 'enable_ai_paywall',
  IG_TRIAL_ACTIVATION_TEST_1 = 'ig_trial_activation_test_1',
  IG_TRIAL_ACTIVATION_TEST_2 = 'ig_trial_activation_test_2',
  IG_TRIAL_ACTIVATION_CONTROL = 'ig_trial_activation_control',
  ENABLE_TIKTOK_ADS_TRIGGER = 'enable_tiktok_ads_trigger',
  ENABLE_IN_APP_CARD_ATTACHMENT = 'enable_in_app_card_attachment',
  DISABLE_IN_APP_CARD_ATTACHMENT = 'disable_in_app_card_attachment',
  ENABLE_AI_STEP_STATS = 'enable_ai_step_content_stat_display',
  ENABLE_WA_COEXISTENCE_CONTACTS_IMPORT = 'enable_wa_coexistence_contacts_import',
  ENABLE_WA_BLOCK_CONTACT = 'enable_wa_block_contact',
  ENABLE_BILLING_ADDRESS_ONLY_US = 'enable_billing_address_only_us',
  DISABLE_BILLING_ADDRESS_ONLY_US = 'disable_billing_address_only_us',
}

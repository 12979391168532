import React, { useEffect } from 'react'
import cx from 'classnames'
import { useAppDispatch } from 'reduxTyped'
import { l } from '@manychat/manyui'

import { paymentCardRenewalModalSlice } from 'common/billing/redux/paymentCardRenewalModal'
import { analyticsService } from 'utils/services/analytics'

import cm from '../StaticNotifications.module.css'

export const PaymentCardRenewalNotification = () => {
  const dispatch = useAppDispatch()

  useEffect(() => {
    analyticsService.sendEvent('NOTIFICATION_BAR.PAYMENT_CARD_RENEVAL.SHOWN')
  }, [])

  const handleClick = () => {
    analyticsService.sendEvent('NOTIFICATION_BAR.PAYMENT_CARD_RENEVAL.CLICKED')
    dispatch(paymentCardRenewalModalSlice.actions.open())
  }

  const text = l.translate(
    'Due to new legislation in India we need you to re-register your pro subscription.',
  )

  return (
    <>
      <span>{text}</span>
      <a target="_blank" className={cx(cm.more, 'm-l-xs')} onClick={handleClick}>
        {l.translate('Re-register')}
      </a>
    </>
  )
}

import { alert } from 'common/core'
import { ID_REGEX } from 'constants/RegExs'
import { BusinessError, ErrorEndpoint } from 'shared/api/lib/errors/business'
import { sendHealthMetric } from 'utils/healthMetrics'
import errorTrackingService from 'utils/services/errorTrackingService'

export const applyHandlerByGuard = <
  TypeGuard extends (error: BusinessError) => boolean,
  ErrorType extends TypeGuard extends (
    error: BusinessError,
  ) => error is Extract<infer U, BusinessError>
    ? U
    : never,
>(
  errors: BusinessError[],
  typeGuard: TypeGuard,
  handler: (error: ErrorType) => void,
) =>
  errors.filter((error) => {
    if (typeGuard(error)) {
      handler(error as Extract<ErrorType, BusinessError>)
      return false
    } else {
      return true
    }
  })

export const reportUnhandledErrors = (errors: BusinessError[], endpoint: ErrorEndpoint = '') => {
  errors.forEach((businessError) => {
    alert(businessError.message, 'danger')

    // TODO PLATFORM-1045 Remove `?? ''` when BE will handle undefined values
    sendHealthMetric('unhandled_business_error', {
      endpoint: endpoint.replace(ID_REGEX, ':id'),
      errorField: businessError.field ?? '',
    })
  })

  if (errors.length > 0) {
    errorTrackingService.trackUnhandledErrors(errors, endpoint)
  }
}

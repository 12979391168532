import React, { useCallback, useEffect, useRef, useState } from 'react'
import cx from 'classnames'
import { useNavigate } from 'react-router-dom'
import { useAppDispatch, useAppSelector } from 'reduxTyped'
import { l, Autocomplete, Skeleton, SelectedOption, TextInputV2 } from '@manychat/manyui'

import { isMobile } from 'utils'
import { getUnreadThreadsState } from 'apps/chat/selectors/countersSelectors'
import { MIN_SUBSCRIBERS_FOR_BROADCAST_PROMOTION } from 'apps/dashboard/components/HomeTab/HomeTabBlockBanners/constants'
import { AccountUserDisplayFlagType } from 'apps/dashboard/constants/DisplayFlagType'
import { getIsCurrentAccountUserDisplayFlagEnabled } from 'apps/dashboard/selectors/dashboardSelectors'
import { Account } from 'apps/profile/models/Page/helpers'
import { CHANNEL_CONNECTION } from 'apps/registration/utils/registrationPaths'
import { BillingExperiment, RequestUpgradePayload, UpgradeSource } from 'common/billing'
import GoProBtn from 'common/billing/components/GoProBtn'
import * as uiActions from 'common/core/actions/uiActions'
import ContactsLimit from 'common/core/components/ContactsLimit'
import { ChannelType } from 'common/core/constants/ChannelType'
import { ProductType } from 'common/core/interfaces/products'
import AccountModel from 'common/core/models/Account'
import {
  getCurrentAccount,
  isAccountBlocked,
  getCurrentAccountProducts,
  getHasAnyBroadcasts,
  isTrialAvailable,
} from 'common/core/selectors/appSelectors'
import {
  getHasAnyStatusFBChannel,
  getIsInstagramOnlyEnabledNoEmailNoSms,
  getIsSmsOrEmailEnabled,
  getIsTiktokOnlyEnabled,
  hasConnectedInstagramChannel,
  isFBChannelConnected,
} from 'common/core/selectors/channelSelectors'
import { getIsAccountAdsEnabled } from 'common/core/selectors/currentAccountSelectors'
import { getUI } from 'common/core/selectors/uiSelectors'
import { getReasonWithMoreSubscribers } from 'common/oneTimeNotify/selectors/notifyReasonSelectors'
import * as devLogSelectors from 'common/settings/selectors/devLogSelectors'
import { getIsNeedToShowPayNow } from 'common/settings/selectors/settingsSelectors'
import { getUserRole, IfHasPermission, PermissionTargets } from 'common/userRoles'
import If from 'components/If'
import { ManyChatLogo } from 'components/ManyChatLogo/ManyChatLogo'
import Scrollbar from 'components/Scrollbar'
import { useMediaQuery } from 'utils/commonHooks'
import localStorage from 'utils/localStorage'
import { AccountFlag } from 'utils/services/featureFlagsService/constants'
import { useAccountReleaseToggle } from 'utils/services/featureFlagsService/releaseTogglesHooks'
import { linkURL } from 'utils/url'

import { AccountOption } from './AccountOption/AccountOption'
import { createAccountsOptions, SidebarAccountOption, SidebarOption } from './createAccountsOptions'
import { FancyInput } from './FancyInput'
import { Nav } from './Nav'

import cm from './Sidebar.module.css'

interface SideBarProps {
  setFbPagesState: (state: boolean) => void
  isOpen: boolean
  fbPagesOpen: boolean
  isSidebarHidden: boolean
  isRightTimeAnimate: boolean
  accID: string
  isFetching: boolean
  openedThreads: number
  accounts: Account[]
}

export const Sidebar = ({
  accID,
  accounts,
  fbPagesOpen,
  isFetching,
  isOpen,
  isRightTimeAnimate,
  isSidebarHidden,
  openedThreads,
  setFbPagesState,
}: SideBarProps) => {
  const { isSidebarFolded } = useAppSelector(getUI)

  const [prevFoldingState] = useState(isSidebarFolded)

  const enableIGRecurringNotification = useAccountReleaseToggle(
    AccountFlag.ENABLE_IG_RECURRING_NOTIFICATION,
  )
  const hasTrial = useAppSelector(isTrialAvailable)

  const hasNotBroadcasts = !useAppSelector(getHasAnyBroadcasts)

  const account = useAppSelector(getCurrentAccount)
  const isBlocked = useAppSelector(isAccountBlocked)
  const hasNotifications = useAppSelector(devLogSelectors.hasUnreadNotifications)
  const hasPolicyEnforcementNotifications = useAppSelector((state) =>
    getIsCurrentAccountUserDisplayFlagEnabled(
      state,
      AccountUserDisplayFlagType.HIGHLIGHT_LOGS_ON_FB_POLICY_ENFORCEMENT,
    ),
  )
  const hasConnectedFBChannel = useAppSelector(isFBChannelConnected)
  const hasAnyStatusFBChannel = useAppSelector(getHasAnyStatusFBChannel)
  const isInstagramOnlyEnabled = useAppSelector(getIsInstagramOnlyEnabledNoEmailNoSms)
  const isTiktokOnlyEnabled = useAppSelector(getIsTiktokOnlyEnabled)
  const isSMSorEmailEnabled = useAppSelector(getIsSmsOrEmailEnabled)
  const hasConnectedIGChannel = useAppSelector(hasConnectedInstagramChannel)
  const unreadThreadsState = useAppSelector(getUnreadThreadsState)
  const hasUnreadUnassignedMessages = unreadThreadsState?.unassigned
  const hasUnreadUserMessages = unreadThreadsState?.on_me
  const isContactsLimitBanner = AccountModel.isFree(account) || isBlocked

  const hasIGRn = enableIGRecurringNotification && hasConnectedIGChannel
  const channelForCalculation = hasIGRn ? ChannelType.INSTAGRAM : ChannelType.FB

  const topicWithMoreSubscribers = useAppSelector((state) =>
    getReasonWithMoreSubscribers(state, channelForCalculation),
  )
  const maxTopicSubscribersCount = topicWithMoreSubscribers?.value ?? 0
  const hasEnoughOptIns = maxTopicSubscribersCount > MIN_SUBSCRIBERS_FOR_BROADCAST_PROMOTION

  const hasCompletedRNOnBoarding =
    (hasConnectedFBChannel || hasIGRn) && hasNotBroadcasts && hasEnoughOptIns

  const fbPage = useRef(null)
  const dispatch = useAppDispatch()
  const navigate = useNavigate()

  useEffect(() => {
    if (fbPagesOpen && fbPage && !isMobile) {
      // eslint-disable-next-line
      // @ts-expect-error
      fbPage?.current?.focus?.()
    }
  }, [fbPagesOpen])

  const handleCurrentPageClick = () => {
    setFbPagesState(!fbPagesOpen)
  }

  const handleAccountSelect = useCallback(
    (id: string, isMetaKey: boolean) => {
      localStorage.removeItem(`mcht_dshbrd_tab_${id}`)
      if (isMetaKey) {
        window.open(`/fb${id}/dashboard`)
      } else {
        if (prevFoldingState) {
          dispatch(uiActions.toggleSidebarFolding())
        }

        location.assign(`/fb${id}/dashboard`)
      }
    },
    [dispatch, prevFoldingState],
  )

  const renderOption = useCallback(
    (option: SelectedOption<SidebarAccountOption, SidebarOption>) => {
      const hasNew = 'isNew' in option && option.isNew

      if (hasNew) {
        return (
          <AccountOption
            isSidebarHidden={isSidebarHidden}
            createNew={option.isNew}
            data-test-id="fb-page"
            onClick={(event) => {
              event.stopPropagation()
              window.location.href = CHANNEL_CONNECTION
            }}
          />
        )
      }

      const hasSubOption = 'isSubOption' in option && option.isSubOption
      if (!hasSubOption) {
        return null
      }

      const { accountStatus, avatar, name, isFavorite, isHidden, id } = option.fbPage || {}
      return (
        <AccountOption
          accountId={id}
          accountName={name}
          accountStatus={accountStatus}
          avatarUrl={avatar}
          data-test-id="fb-page"
          isFavoriteAccount={isFavorite}
          isHiddenAccount={isHidden}
          isSidebarHidden={isSidebarHidden}
          onClick={(event: React.MouseEvent<HTMLDivElement>) => {
            if (!id) return

            event.preventDefault()
            handleAccountSelect(id, Boolean(event.metaKey))
            event.stopPropagation()
          }}
        />
      )
    },
    [handleAccountSelect, isSidebarHidden],
  )

  const handleFBPageAdd = () => {
    navigate(CHANNEL_CONNECTION)
  }

  const handleClickLogo = (event: React.MouseEvent<HTMLDivElement>) => {
    if (event.metaKey) {
      window.open('/')
    } else {
      navigate(linkURL('/dashboard'))
    }
  }

  const hideSidebar = useCallback(() => {
    dispatch(uiActions.closeSidebar())
  }, [dispatch])
  const filteredAccounts = accounts.filter((fbPage) => !accID.includes(fbPage.id))
  const options = createAccountsOptions(filteredAccounts)

  const isPRO = AccountModel.isProOrTrial(account)
  const isRetryPayment = useAppSelector(getIsNeedToShowPayNow)
  const allowAnalyticsTabFlag = useAccountReleaseToggle(AccountFlag.ENABLE_LC_STATISTICS)

  const isAdsEnabled = useAppSelector(getIsAccountAdsEnabled)
  const connectedProducts = useAppSelector(getCurrentAccountProducts)
  const userRole = useAppSelector(getUserRole)

  const className = cx(cm.wrap, {
    [cm.isOpen]: isOpen,
  })

  const isMobileWidth = useMediaQuery('(max-width: 768px)')
  const request: RequestUpgradePayload = {
    source: UpgradeSource.SIDEBAR,
    experiment: BillingExperiment.UPGRADE_PAGE,
    products: [ProductType.INBOX, ProductType.AUTOMATION],
  }

  return (
    <div data-test-id={'sidebar'} className={className}>
      <div
        className={cx(cm.sidebar, {
          [cm.sidebarHidden]: isSidebarHidden && !fbPagesOpen,
        })}
      >
        <div
          className={cx(cm.brand, 'c-pointer d-flex align-center justify-center')}
          onClick={handleClickLogo}
        >
          <ManyChatLogo className={cm.brandLogo} small={isSidebarHidden} />
        </div>

        <div className={cm.currentPage}>
          <AccountOption
            isSidebarFolded={isSidebarFolded}
            className={cm.currentFbPage}
            current
            currentOpen={fbPagesOpen}
            onClick={handleCurrentPageClick}
            data-test-id="fb-page-current"
            isSidebarHidden={isSidebarHidden}
            isRightTimeAnimate={isRightTimeAnimate}
            accountId={account.id}
            accountStatus={account.pro_status}
            avatarUrl={account.ava_big || account.ava_small}
            accountName={account.title}
          />
        </div>
        <div className={cm.content}>
          <div className={cx(cm.navWrapper, { [cm.open]: !fbPagesOpen })}>
            <Scrollbar>
              <Nav
                isPRO={isPRO}
                connectedProducts={connectedProducts}
                allowAnalyticsTabFlag={allowAnalyticsTabFlag}
                userRole={userRole}
                openedThreads={openedThreads}
                pageId={account.page_id}
                hasNotifications={hasNotifications}
                hasPolicyEnforcementNotifications={hasPolicyEnforcementNotifications}
                isSidebarFolded={isSidebarFolded}
                isSidebarHidden={isSidebarHidden}
                isRightTimeAnimate={isRightTimeAnimate}
                isInstagramOnlyEnabled={isInstagramOnlyEnabled}
                isTiktokOnlyEnabled={isTiktokOnlyEnabled}
                hasAnyStatusFBChannel={hasAnyStatusFBChannel}
                hasCompletedRNOnBoarding={hasCompletedRNOnBoarding}
                hasUnreadMessages={hasUnreadUnassignedMessages || hasUnreadUserMessages}
                isSMSorEmailEnabled={isSMSorEmailEnabled}
                enableIGRecurringNotification={enableIGRecurringNotification}
                hideSidebar={hideSidebar}
                isAdsEnabled={isAdsEnabled}
                isMobileWidth={isMobileWidth}
              />
            </Scrollbar>
          </div>
          <div className={cx(cm.fbPagesWrapper, { [cm.open]: fbPagesOpen })}>
            {isFetching ? (
              <>
                <div className="p-a-xs b-b">
                  <TextInputV2 disabled ariaLabel={l.translate('Disabled account option field')} />
                </div>
                <Skeleton.Multiplier>
                  <div className="p-a d-flex align-center">
                    <Skeleton.Avatar className="m-r" /> <Skeleton.Text />
                  </div>
                </Skeleton.Multiplier>
              </>
            ) : (
              <Autocomplete<SidebarAccountOption, SidebarOption>
                ariaLabel="Account options"
                ref={fbPage}
                noPortal
                forceNew
                allowNew
                noClearOnEmpty
                options={options}
                onCreate={handleFBPageAdd}
                customInput={<FancyInput />}
                renderOption={renderOption}
              />
            )}
          </div>
        </div>
        <If condition={!isPRO}>
          <div
            className={cx(cm.footer, 'p-y-xs', {
              'b-t': isContactsLimitBanner,
            })}
          >
            <If condition={isContactsLimitBanner}>
              <ContactsLimit
                isSidebarHidden={isSidebarHidden}
                fbPagesOpen={fbPagesOpen}
                isRightTimeAnimate={isRightTimeAnimate}
              />
            </If>
            <IfHasPermission target={PermissionTargets.SETTINGS_BILLING}>
              <div
                className={cx(cm.proBtn, {
                  'p-x-xs': isSidebarFolded && !isSidebarHidden,
                  'p-x': !isSidebarFolded && !isSidebarHidden,
                  'p-x-xxs': isSidebarHidden,
                })}
              >
                {!isRetryPayment && (
                  <GoProBtn
                    request={request}
                    label={isSidebarHidden ? 'PRO' : undefined}
                    size="small"
                    className={cx('rounded', {
                      'p-x-xs': isSidebarFolded && !isSidebarHidden,
                      'p-x-xxs': isSidebarHidden,
                    })}
                    variant={hasTrial ? 'success' : 'primary'}
                    fullWidth
                  />
                )}
              </div>
            </IfHasPermission>
          </div>
        </If>
      </div>
    </div>
  )
}

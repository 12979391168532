export const CHAT_THREADS_STATUS_FILTER_CHANGED = 'CHAT_THREADS_STATUS_FILTER_CHANGED'
export const CHAT_THREADS_ASSIGNED_FILTER_CHANGED = 'CHAT_THREADS_ASSIGNED_FILTER_CHANGED'
export const CHAT_THREADS_TAGS_AND_CUFS_FILTER_CHANGED = 'CHAT_THREADS_TAGS_AND_CUFS_FILTER_CHANGED'
export const CHAT_THREADS_SET_CURRENT = 'CHAT_THREADS_SET_CURRENT'
export const CHAT_THREADS_SORTING_CHANGED = 'CHAT_THREADS_SORTING_CHANGED'
export const CHAT_THREAD_TOGGLE_CHECKED = 'CHAT_THREAD_TOGGLE_CHECKED'
export const CHAT_THREADS_CLEAR_SELECTION = 'CHAT_THREADS_CLEAR_SELECTION'
export const CHAT_THREADS_SELECTION_TYPE_CHANGED = 'CHAT_THREADS_SELECTION_TYPE_CHANGED'

export const THREADS_SETPAUSEFOREVER_REQUEST = 'THREADS_SETPAUSEFOREVER_REQUEST'
export const THREADS_SETPAUSEFOREVER_RESPONSE = 'THREADS_SETPAUSEFOREVER_RESPONSE'
export const THREADS_SETPAUSEFOREVER_ERROR = 'THREADS_SETPAUSEFOREVER_ERROR'
export const THREADS_SETPAUSE_REQUEST = 'THREADS_SETPAUSE_REQUEST'
export const THREADS_SETPAUSE_RESPONSE = 'THREADS_SETPAUSE_RESPONSE'
export const THREADS_SETPAUSE_ERROR = 'THREADS_SETPAUSE_ERROR'
export const THREADS_CLEARPAUSE_REQUEST = 'THREADS_CLEARPAUSE_REQUEST'
export const THREADS_CLEARPAUSE_RESPONSE = 'THREADS_CLEARPAUSE_RESPONSE'
export const THREADS_CLEARPAUSE_ERROR = 'THREADS_CLEARPAUSE_ERROR'
export const THREADS_UPDATE_SUBSCRIBER_INFO = 'THREADS_UPDATE_SUBSCRIBER_INFO'

export const THREADS_INCREASE_TOTAL_THREADS_COUNT = 'THREADS_INCREASE_TOTAL_THREADS_COUNT'
export const THREADS_DECREASE_TOTAL_THREADS_COUNT = 'THREADS_DECREASE_TOTAL_THREADS_COUNT'

export const THREADS_CLOSE_ALL = 'THREADS_CLOSE_ALL'
export const THREADS_OPEN_ALL = 'THREADS_OPEN_ALL'
export const THREADS_ASSIGN = 'THREADS_ASSIGN'
export const THREAD_USER_READ = 'THREAD_USER_READ'
export const THREADS_UNASSIGN = 'THREADS_UNASSIGN'
export const THREADS_SET_ASSIGNEE = 'THREADS_SET_ASSIGNEE'
export const THREADS_FETCH = 'THREADS_FETCH'
export const THREADS_FETCH_RESPONSE = 'THREADS_FETCH_RESPONSE'
export const THREADS_CURRENT_FETCH_RESPONSE = 'THREADS_CURRENT_FETCH_RESPONSE'
export const THREADS_ADD_PRELOAD_IMAGE = 'THREADS_ADD_PRELOAD_IMAGE'
export const THREADS_DELETE_PRELOAD_IMAGE = 'THREADS_DELETE_PRELOAD_IMAGE'
export const THREADS_RESET_IMAGES = 'THREADS_RESET_IMAGES'
export const THREADS_SEND_REQUEST = 'THREADS_SEND_REQUEST'
export const THREADS_SEND_ERROR = 'THREADS_SEND_ERROR'
export const THREADS_SEND_RESPONSE = 'THREADS_SEND_RESPONSE'
export const FETCH_THREAD_COUNTERS = 'FETCH_THREAD_COUNTERS'
export const FETCH_THREAD_COUNTERS_REQUEST = 'FETCH_THREAD_COUNTERS_REQUEST'
export const FETCH_THREAD_COUNTERS_RESPONSE = 'FETCH_THREAD_COUNTERS_RESPONSE'
export const FETCH_THREAD_COUNTERS_ERROR = 'FETCH_THREAD_COUNTERS_ERROR'
export const THREADS_UPDATESTATUS_ERROR = 'THREADS_UPDATESTATUS_ERROR'
export const THREADS_SETASSIGNMENT_ERROR = 'THREADS_SETASSIGNMENT_ERROR'
export const THREADS_UPDATED_NOTIFICATION = 'THREADS_UPDATED_NOTIFICATION'
export const THREADS_CURRENT_CHECKSATISFIESFILTER_RESPONSE =
  'THREADS_CURRENT_CHECKSATISFIESFILTER_RESPONSE'
export const THREADS_CURRENT_CHECKSATISFIESFILTER_REQUEST =
  'THREADS_CURRENT_CHECKSATISFIESFILTER_REQUEST'
export const THREADS_CURRENT_CHECKSATISFIESFILTER_ERROR =
  'THREADS_CURRENT_CHECKSATISFIESFILTER_ERROR'

export const MESSAGES_FETCH_ERROR = 'MESSAGES_FETCH_ERROR'

export const MESSAGES_SEND_REQUEST = 'MESSAGES_SEND_REQUEST'
export const MESSAGES_SEND_RESPONSE = 'MESSAGES_SEND_RESPONSE'
export const MESSAGES_SEND_ERROR = 'MESSAGES_SEND_ERROR'

export const MESSAGES_DELIVERY_FAILED = 'MESSAGES_DELIVERY_FAILED'

export const MESSAGES_CREATENOTE_REQUEST = 'MESSAGES_CREATENOTE_REQUEST'
export const MESSAGES_CREATENOTE_RESPONSE = 'MESSAGES_CREATENOTE_RESPONSE'
export const MESSAGES_CREATENOTE_ERROR = 'MESSAGES_CREATENOTE_ERROR'

export const MESSAGES_SENDSMS_REQUEST = 'MESSAGES_SENDSMS_REQUEST'
export const MESSAGES_SENDSMS_RESPONSE = 'MESSAGES_SENDSMS_RESPONSE'
export const MESSAGES_SENDSMS_ERROR = 'MESSAGES_SENDSMS_ERROR'

export const MESSAGES_SEND_INSTAGRAM_REQUEST = 'MESSAGES_SENDINSTAGRAM_REQUEST'
export const MESSAGES_SEND_INSTAGRAM_RESPONSE = 'MESSAGES_SENDINSTAGRAM_RESPONSE'
export const MESSAGES_SEND_INSTAGRAM_ERROR = 'MESSAGES_SENDINSTAGRAM_ERROR'

export const MESSAGES_SEND_TELEGRAM_REQUEST = 'MESSAGES_SENDTELEGRAM_REQUEST'
export const MESSAGES_SEND_TELEGRAM_RESPONSE = 'MESSAGES_SENDTELEGRAM_RESPONSE'
export const MESSAGES_SEND_TELEGRAM_ERROR = 'MESSAGES_SENDTELEGRAM_ERROR'

export const MESSAGES_SEND_TIKTOK_REQUEST = 'MESSAGES_SENDTIKTOK_REQUEST'
export const MESSAGES_SEND_TIKTOK_RESPONSE = 'MESSAGES_SENDTIKTOK_RESPONSE'
export const MESSAGES_SEND_TIKTOK_ERROR = 'MESSAGES_SENDTIKTOK_ERROR'

export const THREADS_INSTAGRAM_STORY_FETCH = 'THREADS_INSTAGRAM_STORY_FETCH'

export const MESSAGES_TOGGLE_HIDE_AUTOMATION = 'MESSAGES_TOGGLE_HIDE_AUTOMATION'

export const MESSAGES_CREATED_NOTIFICATION = 'MESSAGES_CREATED_NOTIFICATION'

export const LIVE_CHAT_SWITCH_CHANNEL = 'LIVE_CHAT_SWITCH_CHANNEL'

export const LIVE_CHAT_SHOW_ALL_CHANNELS_HISTORY_MODAL = 'LIVE_CHAT_SHOW_ALL_CHANNELS_HISTORY_MODAL'
export const LIVE_CHAT_CLOSE_ALL_CHANNELS_HISTORY_MODAL =
  'LIVE_CHAT_CLOSE_ALL_CHANNELS_HISTORY_MODAL'

export const LIVE_CHAT_SEARCH_RESULTS_CLICK = 'LIVE_CHAT_SEARCH_RESULTS_CLICK'
export const LIVE_CHAT_RESET_ACTIVE_MESSAGE = 'LIVE_CHAT_RESET_ACTIVE_MESSAGE'
export const LIVECHATSEARCH_FETCH_RESPONSE = 'LIVECHATSEARCH_FETCH_RESPONSE'

export const MESSAGES_SENDWHATSAPPMESSAGE_REQUEST = 'MESSAGES_SENDWHATSAPPMESSAGE_REQUEST'
export const MESSAGES_SENDWHATSAPPMESSAGE_RESPONSE = 'MESSAGES_SENDWHATSAPPMESSAGE_RESPONSE'
export const MESSAGES_SENDWHATSAPPMESSAGE_ERROR = 'MESSAGES_SENDWHATSAPPMESSAGE_ERROR'

export const MESSAGES_TOGGLE_SCROLL_DISABILITY = 'MESSAGES_TOGGLE_SCROLL_DISABILITY'

export const ADMIN_COUNTER_CHANGED = 'ADMIN_COUNTER_CHANGED'
export const DELETE_INSTAGRAM_MESSAGE = 'DELETE_INSTAGRAM_MESSAGE'
export const REACT_TO_INSTAGRAM_MESSAGE = 'REACT_TO_INSTAGRAM_MESSAGE'
export const UNREACT_TO_INSTAGRAM_MESSAGE = 'UNREACT_TO_INSTAGRAM_MESSAGE'

export const UPDATE_CONVERSATIONS_FILTER = 'UPDATE_CONVERSATIONS_FILTER'
export const UPDATE_CHANNELS_FILTER = 'UPDATE_CHANNELS_FILTER'
export const UPDATE_TEAM_MEMBERS_FILTER = 'UPDATE_TEAM_MEMBERS_FILTER'

export const UPDATE_USER_COUNTERS = 'UPDATE_USER_COUNTERS'
export const UPDATE_UNREAD_THREADS = 'UPDATE_UNREAD_THREADS'

export const OPEN_SNIPPETS_LIST = 'OPEN_SNIPPETS_LIST'
export const CLOSE_SNIPPETS_LIST = 'CLOSE_SNIPPETS_LIST'

export const THREAD_TAKE_THREAD_CONTROL = 'THREAD_TAKE_THREAD_CONTROL'
export const THREAD_TAKE_THREAD_CONTROL_RESPONSE = 'THREAD_TAKE_THREAD_CONTROL_RESPONSE'
export const THREAD_RESET_HANDOVER_ERROR = 'THREAD_RESET_HANDOVER_ERROR'

export const THREAD_RESET_CONVERSATION_ROUTING_ERROR = 'THREAD_RESET_CONVERSATION_ROUTING_ERROR'

export const SCHEDULED_MESSAGES_GET_LIST_SUCCESS = 'SCHEDULED_MESSAGES_GET_LIST_SUCCESS'
export const SCHEDULED_MESSAGES_CREATE_SUCCESS = 'SCHEDULED_MESSAGES_CREATE_SUCCESS'
export const SCHEDULED_MESSAGES_UPDATE_SUCCESS = 'SCHEDULED_MESSAGES_UPDATE_SUCCESS'
export const SCHEDULED_MESSAGES_DELETE_SUCCESS = 'SCHEDULED_MESSAGES_DELETE_SUCCESS'
export const SCHEDULED_MESSAGES_LIST_CLEAR = 'SCHEDULED_MESSAGES_LIST_CLEAR'

export const ADD_VOICE_CONVERSION_PENDING_STATE = 'ADD_VOICE_CONVERSION_PENDING_STATE'
export const REMOVE_VOICE_CONVERSION_PENDING_STATE = 'REMOVE_VOICE_CONVERSION_PENDING_STATE'

export const RESET_THREAD_LOADING = 'RESET_THREAD_LOADING'

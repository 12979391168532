export enum ChatGPTEngineType {
  TURBO_3_5 = 'gpt-3.5-turbo',
  DAVINCI_3 = 'text-davinci-003',
  DAVINCI_2 = 'text-davinci-002',
  CURIE_1 = 'text-curie-001',
  BABBAGE_1 = 'text-babbage-001',
  ADA_1 = 'text-ada-001',
}

export interface ChatGptEngineOption {
  value: ChatGPTEngineType
  label: string
  disabled: boolean
}

export const ChatGPTEngineOptions: ChatGptEngineOption[] = [
  {
    value: ChatGPTEngineType.TURBO_3_5,
    label: 'GPT 3.5 Turbo',
    disabled: false,
  },
  {
    value: ChatGPTEngineType.DAVINCI_3,
    label: 'Davinci v3',
    disabled: true,
  },
  {
    value: ChatGPTEngineType.DAVINCI_2,
    label: 'Davinci v2',
    disabled: true,
  },
  {
    value: ChatGPTEngineType.CURIE_1,
    label: 'Curie v1',
    disabled: true,
  },
  {
    value: ChatGPTEngineType.BABBAGE_1,
    label: 'Babbage v1',
    disabled: true,
  },
  {
    value: ChatGPTEngineType.ADA_1,
    label: 'Ada v1',
    disabled: true,
  },
]

export const CHATGPT_TOKENS_MIN = 1
export const CHATGPT_TOKENS_MAX = 2048

export const CHATGPT_TEMPERATURE_MIN = 0
export const CHATGPT_TEMPERATURE_MAX = 2

export const ChatGPTRequestDefaults = {
  ENGINE: ChatGPTEngineType.TURBO_3_5,
  MAX_TOKENS: 200,
  TEMPERATURE: 0.5,
}

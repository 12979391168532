import {
  IBlockFile,
  IBlockImage,
  IBlockRemoteImage,
  IBlock,
  IAttachmentBlock,
  ITextBlock,
} from 'common/builder/blocks/blockInterfaces'
import { BlockType } from 'common/builder/constants/BlockType'
import { NodeType } from 'common/builder/constants/NodeType'
import {
  TEXT_BLOCK_TEXT_MAX_LENGTH,
  TEXT_BLOCK_WITH_BUTTONS_TEXT_MAX_LENGTH,
  SIMPLE_BUILDER_TEXT_BLOCK_TEXT_MAX_LENGTH,
  SMS_NODE_TEXT_BLOCK_MAX_SEGMENTS,
  INSTAGRAM_TEXT_BLOCK_TEXT_MAX_LENGTH,
  TELEGRAM_TEXT_BLOCK_TEXT_MAX_LENGTH,
  ADDITIONAL_OUTSIDE_24_TEXT_BLOCK_MAX_LENGTH,
} from 'common/builder/models/Block/constants'
import { smsCounter } from 'utils/smsCounter'

import { FBMessagingType } from '../constants/facebookMessaging'
import { INode } from '../nodes/nodeInterfaces'

import {
  AttachmentBlockAttachmentAcceptSpecific,
  AttachmentBlockAttachmentAcceptDefault,
  VariantBlockTitleSequence,
  VariantBlockTitles,
  AttachmentSizeMap,
} from './blockConstants'

export const createQRBlockId = (parentContentId: string): string => {
  return `CCCCCCCC${parentContentId.slice(8)}`
}

export const isRemoteImage = (
  attachment: IBlockImage | IBlockFile,
): attachment is IBlockRemoteImage => attachment?.type === 'remote_image'

export const getAttachmentsAcceptMap = (nodeType?: NodeType) => {
  const specific = nodeType && AttachmentBlockAttachmentAcceptSpecific[nodeType]
  if (specific) {
    return specific
  }

  return AttachmentBlockAttachmentAcceptDefault
}

export const getBlockAttachmentAccept = (attachmentType: string, nodeType?: NodeType) => {
  return getAttachmentsAcceptMap(nodeType)[attachmentType]
}

export const getMaxSizeForAttachment = (attachmentType: string, nodeType?: NodeType) => {
  if (!nodeType) {
    return
  }

  return AttachmentSizeMap[nodeType]?.[attachmentType]
}

export const isAttachmentBlock = (block: IBlock): block is IAttachmentBlock =>
  block.type === BlockType.ATTACHMENT

export const getNodeTypeForTextBlock = (parentNode: INode) => {
  const insideSMSNode = parentNode?.nodeType === NodeType.SMS
  const insideWhatsAppNode = parentNode?.nodeType === NodeType.WHATS_APP
  const insideInstagramNode = parentNode?.nodeType === NodeType.INSTAGRAM
  const insideTelegramNode = parentNode?.nodeType === NodeType.TELEGRAM

  return {
    insideInstagramNode,
    insideWhatsAppNode,
    insideTelegramNode,
    insideSMSNode,
  }
}

export const getLimitForTextBlock = ({
  parentNode,
  block,
  isSimple,
  getBlocks,
}: {
  parentNode: INode
  block: ITextBlock
  isSimple: boolean
  getBlocks: () => IBlock[]
}) => {
  const { insideInstagramNode, insideTelegramNode, insideSMSNode } =
    getNodeTypeForTextBlock(parentNode)

  let limit = TEXT_BLOCK_TEXT_MAX_LENGTH

  if (block.buttons.length) {
    limit = TEXT_BLOCK_WITH_BUTTONS_TEXT_MAX_LENGTH
  }

  if (insideSMSNode) {
    const { charsPerMessage } = smsCounter(block.text)
    limit = charsPerMessage * SMS_NODE_TEXT_BLOCK_MAX_SEGMENTS
  }
  if (insideInstagramNode) {
    limit = block.buttons.length
      ? TEXT_BLOCK_WITH_BUTTONS_TEXT_MAX_LENGTH
      : INSTAGRAM_TEXT_BLOCK_TEXT_MAX_LENGTH
  }
  if (insideTelegramNode) {
    limit = TELEGRAM_TEXT_BLOCK_TEXT_MAX_LENGTH
  }
  if (isSimple) {
    limit = SIMPLE_BUILDER_TEXT_BLOCK_TEXT_MAX_LENGTH
  }

  if (
    '$fbMessagingType' in parentNode &&
    parentNode.$fbMessagingType === FBMessagingType.OUTSIDE_24_HOURS &&
    block.type === BlockType.TEXT
  ) {
    const blocks = getBlocks()
    const blockIndex = blocks.findIndex((blockProps) => blockProps.id === block.id)

    if (blockIndex > 0) {
      limit = ADDITIONAL_OUTSIDE_24_TEXT_BLOCK_MAX_LENGTH
    }
  }

  return limit
}

export const getVariantBlockTitleByIndex = (index: number): VariantBlockTitles => {
  return VariantBlockTitleSequence[index % VariantBlockTitleSequence.length]
}

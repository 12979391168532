import React from 'react'
import { Confirm } from '@manychat/manyui'

import { registerConfirm } from 'common/core'

export default class AppConfirmController extends React.Component {
  componentDidMount = () => {
    registerConfirm((message, options = {}) => {
      const defaults = { width: 540 }
      return this.confirmRef.current
        ?.open?.(message, { ...defaults, ...options })
        .then((result) => {
          this.confirmRef.current?.close?.()
          return result
        })
    })
  }

  componentWillUnmount = () => {
    registerConfirm(null)
  }

  confirmRef = React.createRef()

  render() {
    return <Confirm ref={this.confirmRef} inverse />
  }
}

import React from 'react'
import cx from 'classnames'
import * as Icon from '@manychat/icons'

import cleanProps from '../../../lib/clean-react-props'
import Loader from '../../Loader'
import cm from '../BtnV2.module.css'
import { ActionProps, IconNames, sizeMapping } from '../interfaces'

import { CustomComponentProps, AnchorLinkBtnProps } from './interfaces'

type LinkBtnProps<T extends React.ElementType> = T extends 'a'
  ? AnchorLinkBtnProps
  : CustomComponentProps<T> & ActionProps

export const LinkBtn = <T extends React.ElementType = 'a'>({
  size = 'default',
  variant = 'ghost-primary',
  loading = false,
  leftIcon,
  fullWidth = false,
  rightIcon,
  onlyIcon,
  className,
  label,
  component: CustomComponent = 'a',
  ...props
}: LinkBtnProps<T>) => {
  const LeftIconComponent = leftIcon ? Icon[leftIcon as IconNames] : null
  const RightIconComponent = rightIcon ? Icon[rightIcon as IconNames] : null
  const OnlyIconComponent = onlyIcon ? Icon[onlyIcon as IconNames] : null
  const iconToUse = onlyIcon || leftIcon || rightIcon
  const iconSize = sizeMapping[size]

  const linkClasses = cx(
    cm.btnV2,
    {
      [cm.primary]: variant === 'primary',
      [cm.default]: variant === 'default',
      [cm.success]: variant === 'success',
      [cm.danger]: variant === 'danger',
      [cm.dangerLight]: variant === 'danger-light',
      [cm.ghostPrimary]: variant === 'ghost-primary',
      [cm.ghostLight]: variant === 'ghost-light',
      [cm.ghostDark]: variant === 'ghost-dark',
      [cm.defaultSize]: size === 'default',
      [cm.small]: size === 'small',
      [cm.large]: size === 'large' && !iconToUse,
      [cm.largeWithIcon]: size === 'large' && iconToUse,
      [cm.fullWidth]: fullWidth,
      [cm.loading]: loading,
      [cm.onlyIcon]: onlyIcon,
      [cm.leftIcon]: leftIcon,
      [cm.rightIcon]: rightIcon,
    },
    className,
  )

  const content = (
    <>
      {OnlyIconComponent && <OnlyIconComponent size={iconSize} />}
      {loading && (
        <span className={cx('p-stretched', cm.loader)}>
          <Loader center size={iconSize} theme={{ spinner: cx(cm.spinner) }} />
        </span>
      )}
      {!OnlyIconComponent && LeftIconComponent && <LeftIconComponent size={iconSize} />}
      {!OnlyIconComponent && label && <span className={cm.label}>{label}</span>}
      {!OnlyIconComponent && !label && (
        <span className={cx(cm.label, cm.capitalize)}>{props.children}</span>
      )}
      {!OnlyIconComponent && RightIconComponent && <RightIconComponent size={iconSize} />}
    </>
  )

  if (CustomComponent === 'a') {
    const { disabled, ...anchorProps } = props as LinkBtnProps<'a'>

    const handleClick = (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
      if (disabled || loading) {
        event.preventDefault()
        return
      }
      anchorProps.onClick?.(event)
    }

    return (
      <a
        {...cleanProps(anchorProps, [], [])}
        aria-label={anchorProps.ariaLabel}
        aria-describedby={anchorProps.ariaLabelledBy}
        aria-disabled={disabled}
        tabIndex={anchorProps.tabIndex}
        className={linkClasses}
        onClick={handleClick}
      >
        {content}
      </a>
    )
  }

  const componentProps = {
    ...props,
    className: linkClasses,
  }

  return <CustomComponent {...componentProps}>{content}</CustomComponent>
}

import React, { useMemo } from 'react'
import cx from 'classnames'
import { z } from 'zod'
import { Icon, l } from '@manychat/manyui'

import { BlockType } from '../../constants'
import { registry } from '../../registry'
import { ClassNameSchema } from '../../schemas'

import { Channels } from './Channels'

const TickListPropsSchema = z.object({
  title: z.string().optional(),
  text: z.string().optional(),
  className: ClassNameSchema,
  items: z.array(z.string()).optional(),
})

const Component = (props: z.infer<typeof TickListPropsSchema>) => {
  const defaultItems = useMemo(
    () => [
      l.translate('Launch as many automations as you like'),
      l.translate('Ask users to follow you before sending links'),
      <p className="d-flex align-center">
        {l.translate('Collect emails in DMs with customizable templates')}{' '}
        <Channels
          className="m-l-xxs"
          channels={['instagram', 'whatsapp', 'email', 'fb', 'telegram']}
        />
      </p>,
    ],
    [],
  )

  const items = props.items || defaultItems

  return (
    <ul className={cx('p-l-0 m-t-sm m-b-0', props.className)}>
      {items.map((benefit, index) => (
        <li className={cx('p-0 m-t-sm d-flex flex-row gap-xxs')} key={index}>
          <Icon.Tick size={20} style={{ minWidth: 20 }} />{' '}
          <p data-test-id={`benefit-${index}`} className="m-t-0 flex-1">
            {benefit}
          </p>
        </li>
      ))}
    </ul>
  )
}

export const TickList = registry.register(Component, {
  props: TickListPropsSchema,
  type: BlockType.TICK_LIST,
})

import React from 'react'

import { FreshChatService } from './freshchat/FreshChatService'
import ZendeskMessagingWidget from './zendeskService/ZendeskMessagingWidget'

const LiveSupportService = () => {
  if (window.zendesk_chat_settings?.hasZendeskChat) {
    return <ZendeskMessagingWidget />
  }

  return <FreshChatService />
}

export default LiveSupportService

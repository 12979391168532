import React from 'react'

type PopoverContextValue = { onRequestClose: (reason: string) => void; fullscreen: boolean }

export const PopoverContext = React.createContext<PopoverContextValue>({
  fullscreen: false,
  onRequestClose: () => {},
})

export const PopoverContextProvider = PopoverContext.Provider

import React from 'react'
import cx from 'classnames'
import { useAppSelector } from 'reduxTyped'
import { Icon } from '@manychat/manyui'

import { DraftIndicator } from 'apps/cms/components/DraftIndicator/DraftIndicator'
import { FlowContextMenu } from 'apps/cms/components/FlowContextMenu/FlowContextMenu'
import { useFlowCard } from 'apps/cms/components/Flows/hooks/useFlowCard'
import { useFlowCardDrag } from 'apps/cms/components/Flows/hooks/useFlowCardDrag'
import { getBuilderUrl } from 'apps/cms/lib/getBuilderUrl'
import { FlowCardClickAction } from 'apps/cms/pages/ContentManagementSystem/lib/types'
import { contentManagementSelectors } from 'apps/cms/store'
import ProtectionStatus from 'apps/templates/components/ProtectionStatus'

import cm from './FlowCompactCard.module.css'

interface FlowCompactCardProps {
  flowId: string
}

export const FlowCompactCard = ({ flowId }: FlowCompactCardProps) => {
  const { flow, handleFlowCardClick, userCanEditFlow, accessData, isCardDisabled } = useFlowCard({
    flowId,
  })
  const {
    showFlowContextMenu,
    allowHoverFlowCard,
    allowHighlightActiveFlowCard,
    lastSelectedFlowId,
    flowCardClickAction,
  } = useAppSelector(contentManagementSelectors.getUi)
  const isFlowCardActive = lastSelectedFlowId === flowId && allowHighlightActiveFlowCard

  const { connectDragSource } = useFlowCardDrag({ flow })

  const isFlowProtected =
    accessData?.is_protected && (accessData?.is_disabled || accessData?.is_locked)

  if (!flow) {
    return null
  }

  return (
    <article
      ref={connectDragSource}
      className={cx(cm.flowCompactCard, {
        [cm.card_disabled]: isCardDisabled,
        [cm.card_withHover]: allowHoverFlowCard,
        [cm.card_active]: isFlowCardActive,
      })}
    >
      {flowCardClickAction === FlowCardClickAction.OPEN_FLOW && (
        <a
          onClick={handleFlowCardClick}
          href={getBuilderUrl(flow.path)}
          className={cm.flowCompactCard__mainActionButton}
        />
      )}

      {flowCardClickAction === FlowCardClickAction.SAVE_FLOW_ID_TO_STATE && (
        <button
          onClick={handleFlowCardClick}
          type="button"
          className={cm.flowCompactCard__mainActionButton}
        />
      )}

      {!isFlowProtected && flow.preview.is_actual && flow.preview.url && (
        <img className={cm.flowCompactCard__preview} src={flow.preview.url} />
      )}

      {!isFlowProtected && !flow.preview.is_actual && (
        <div className={cm.flowCompactCard__placeholder}>
          <Icon.Flow />
        </div>
      )}

      {isFlowProtected && (
        <div className={cm.flowCompactCard__placeholder}>
          <Icon.Protect />
        </div>
      )}

      <div className={cm.flowCompactCard__footer}>
        <div className={cm.flowCompactCard__title}>
          {flow.has_unpublished_changes && <DraftIndicator />}

          <h1 className={cx(cm.flowCompactCard__flowName, 'text-ellipsis')}>{flow.name}</h1>
        </div>

        <div className={cm.flowCompactCard__actions}>
          {flow.template_installation_access_data && (
            <ProtectionStatus
              className={cm.flowCompactCard__interactableElement}
              accessData={flow.template_installation_access_data}
            />
          )}

          {userCanEditFlow && showFlowContextMenu && (
            <FlowContextMenu
              anchorClassName={cm.flowCompactCard__interactableElement}
              flowPath={flow.path}
            />
          )}
        </div>
      </div>
    </article>
  )
}

import hash from 'hash-sum'
import clone from 'lodash/clone'
import compact from 'lodash/compact'
import first from 'lodash/first'
import get from 'lodash/get'
import isArray from 'lodash/isArray'
import isPlainObject from 'lodash/isPlainObject'
import reduce from 'lodash/reduce'
import uniq from 'lodash/uniq'
import { v4 as uuid } from 'uuid'

import BackendContentType from 'common/builder/constants/BackendContentType'
import BackendMessageType from 'common/builder/constants/BackendMessageType'
import { ButtonType } from 'common/builder/constants/ButtonType'
import { findWhileTextChanges } from 'components/MCTextEditor/shared/matcher'
import { exportSmartLink } from 'components/MCTextEditor/smartLinks/adapter'
import { URLPattern, parseURL } from 'components/MCTextEditor/url/adapter'
import { URL_REGEX } from 'constants/RegExs'

export const normalizeContentData = (content) => {
  let res = { ...(content.content || content) }

  if (res.data) {
    res._oid = res.data._oid
    res.messages = res.data.messages
    res.keyboard = res.data.keyboard
    res.quick_replies = res.data.quick_replies
    res.telegram_keyboard = res.data.telegram_keyboard
    res.target = res.data.target
    res.message_tag = res.data.message_tag
    res.one_time_notify_reason_id = res.data.one_time_notify_reason_id
    res.private_reply = res.data.private_reply

    // email
    res.blocks = res.data.blocks
    res.subject = res.data.subject
    res.preheader = res.data.preheader
    res.trigger_on_open = res.data.trigger_on_open

    // email_new
    res.settings = res.data.settings

    // action group
    res.actions = res.data.actions

    // condition
    res.conditions = res.data.conditions
    res.default_target = res.data.default_target
    res.default_target_oid = res.data.default_target_oid

    // TODO: old condition, remove soon
    res.targets = res.data.targets
    res.filter = res.data.filter

    // split
    res.variants = res.data.variants
    res.randomized = res.data.randomized

    // goto target
    res.content_target = res.data.content_target

    // smart delay
    res.shift_time = res.data.shift_time
    res.limit_time = res.data.limit_time
    res.wait_until = res.data.wait_until

    // comment
    res.note = res.data.note

    // ai agent
    res.channel = res.data.channel
    res.agent_data = res.data.agent_data
    res.settings = res.data.settings
  }

  if (content.namespace) {
    res.namespace = content.namespace
  }
  return res
}

export const isSameContent = (content1, content2) => {
  const c1 = normalizeContentData(content1)
  const c2 = normalizeContentData(content2)

  return c1.content_id === c2.content_id || c1._oid === c2._oid
}

export const getBatchRootContentId = (batch, options = {}) => {
  const isNewVersionBatch = isPlainObject(batch)

  const batchContents = isNewVersionBatch ? batch.contents || [] : batch

  if (isNewVersionBatch) {
    if (options.isPartialPatchParse && !('root_content' in batch)) {
      return undefined
    }
    if (!batch.root_content) {
      return null
    }
    const rootContent = batchContents
      .map(normalizeContentData)
      .find((c) => c.content_id === batch.root_content || c._oid === batch.root_content)

    if (!rootContent) {
      return batch.root_content
    }
    return rootContent._oid
  }

  const firstContent = first(batchContents)
  return firstContent ? normalizeContentData(firstContent)._oid : null
}

// convert batch and data
// todo use in parser
export const normalizeBatch = (batch) => {
  const isOldVersion = isArray(batch)
  if (isOldVersion) {
    // convert to new version
    const contents = clone(batch)
    batch = { root_content: null, contents }
  }

  if (!batch.contents) {
    batch.contents = []
  }

  batch.contents = batch.contents.map(normalizeContentData)
  return batch
}

export const createInitialBatch = (initContent) => {
  const _oid = uuid()
  const content = { ...initContent, _oid }

  return {
    root_content: _oid,
    contents: [content],
  }
}

// returns content_ids of all contents not present in the batch among the contents of the root namespace
// TODO: test coverage
export const getMissingExternalTargetIds = (batch, namespace) => {
  batch = normalizeBatch(batch)

  const currentFlowContents = batch.contents.filter((content) => content.namespace === namespace)

  const messages = reduce(
    currentFlowContents,
    (result, content) => {
      const messages = get(content, 'messages') || []
      return result.concat(messages)
    },
    [],
  )

  const elements = reduce(
    messages,
    (result, content) => {
      const elements = get(content, 'elements') || []
      return result.concat(elements)
    },
    [],
  )

  const quickReplies = currentFlowContents.map((content) => get(content, 'quick_replies'))

  const blockLike = compact([...messages, ...elements, ...quickReplies])

  const buttons = reduce(
    blockLike,
    (result, block) => {
      const keyboard = get(block, 'keyboard') || []
      const buttons = get(block, 'buttons') || []
      const answer_replies = get(block, 'answer_replies') || []
      return [...result, ...keyboard, ...buttons, ...answer_replies]
    },
    [],
  )

  // content.target.content_id - action group content_id
  // message.success_target.content_id - question block target_id
  // button.target.content_id - url button content_id
  // button.content_id - button content_id
  // qr.button.content_id - qr content_id
  const actionGroupContentTargetIds = currentFlowContents
    .filter((content) => content.type === BackendContentType.ACTION_GROUP)
    .map((content) => get(content, 'target.content_id'))

  const questionMessageTargetIds = messages
    .filter((message) => message.type === BackendMessageType.QUESTION)
    .map((content) => get(content, 'success_target.content_id'))

  const urlButtonTargetIds = buttons.map((content) => get(content, 'target.content_id'))

  const buttonTargetIds = buttons.map((content) => get(content, 'content_id'))

  const externalContentIds = compact([
    ...actionGroupContentTargetIds,
    ...questionMessageTargetIds,
    ...urlButtonTargetIds,
    ...buttonTargetIds,
  ])

  const existingContentIds = currentFlowContents.map((content) => content.content_id)

  const missingExternalContentIds = externalContentIds.filter(
    (contentId) => !existingContentIds.includes(contentId),
  )

  return uniq(missingExternalContentIds)
}

export const getBatchHashMap = (batch) => {
  const hashMap = {}
  for (const content of batch.contents) {
    hashMap[content._oid] = hash(content)
  }
  return hashMap
}

export const parseBodyMessageAndAdaptToTrackableLinkFormat = (textMessage, _oid) => {
  if (typeof textMessage !== 'string') {
    return null
  }
  const withNoTrackableLinkSpecialSymbolsRegExp = /(?!.*(>>))/
  const matcher = new RegExp(
    `${withNoTrackableLinkSpecialSymbolsRegExp.source}(${URL_REGEX.source})`,
    'gi',
  )
  return textMessage.replace(matcher, (link) => formatToTrackableLink(link, _oid || uuid()))
}

export const formatToTrackableLink = (link, id) => {
  return link && id ? `<<url:${id}|${link}>>` : null
}

export const migrateSmsTextBlockContentToSmartLinks = (text, buttons) => {
  const urlButtons = []

  findWhileTextChanges(
    () => text,
    URLPattern,
    (index, urlString) => {
      const [id, url] = parseURL(urlString)
      text = text.slice(0, index) + exportSmartLink(id) + text.slice(index + urlString.length)
      urlButtons.push({ id, type: ButtonType.URL, url })
    },
  )

  for (const id of buttons) {
    const link = exportSmartLink(id)
    if (!text.includes(link)) {
      text += ` ${link}`
    }
  }
  return [text, urlButtons]
}

export const getSearchedContentOid = (idsObject, contentId) => {
  const uniqueNodeId = String(contentId)
  const keys = Object.keys(idsObject)
  const searchedKey = keys.find((id) => {
    const value = idsObject[id]
    if (!value) {
      return false
    }
    return String(value) === uniqueNodeId
  })
  return searchedKey ?? null
}

import React from 'react'
import { useAppSelector } from 'reduxTyped'
import { l, Modal } from '@manychat/manyui'

import { getWhatsAppCoexistenceContactImportState } from 'common/builder/selectors/builder/whatsAppSelectors'
import { useToggle } from 'common/hooks/useToggle'
import BulletListItem from 'components/BulletListItem/BulletListItem'
import { WaCoexistenceImportContactsState } from 'shared/api/requests/whatsApp/schemas'
import { analyticsService } from 'utils/services/analytics'

import cm from '../common.module.css'

export const WACoexistenceContactImportFailed = () => {
  const importState = useAppSelector(getWhatsAppCoexistenceContactImportState)
  const { state, toggle } = useToggle()

  const isModalOpen = state !== 'off'
  const isChatPermissionsError = importState === WaCoexistenceImportContactsState.FAILED

  const regularErrorPoints = [
    l.translate('Open Manychat Settings and <strong>remove</strong> your WhatsApp account.'),
    l.translate('Go to WhatsApp Business App Settings and <strong>disconnect</strong> Manychat.'),
    l.translate('Wait at least <strong>1 hour</strong> before reconnecting to Manychat.'),
    l.translate('Head back to Manychat Settings and <strong>reconnect</strong> WhatsApp.'),
    l.translate(
      '<strong>Connect</strong> WhatsApp Business App to Manychat again and <strong>import</strong> your contacts once more.',
    ),
  ]
  const chatPermissionsErrorPoints = [
    l.translate('Open Manychat Settings and <strong>remove</strong> your WhatsApp account.'),
    l.translate('Go to WhatsApp Business App Settings and <strong>disconnect</strong> Manychat.'),
    l.translate('Wait at least <strong>1 hour</strong> before reconnecting to Manychat.'),
    l.translate('Head back to Manychat Settings and <strong>reconnect</strong> WhatsApp.'),
    l.translate(
      '<strong>Select Share chats</strong> while connecting to be able to import contacts from the WhatsApp Business App.',
    ),
    l.translate(
      '<strong>Connect</strong> WhatsApp Business App to Manychat again and <strong>import</strong> your contacts once more.',
    ),
  ]

  const points = isChatPermissionsError ? chatPermissionsErrorPoints : regularErrorPoints

  const handleLearnMore = () => {
    toggle()
    analyticsService.sendEvent(
      'NOTIFICATION_BAR.WA_COEXISTENCE_CONTACT_IMPORT_FAILED.LEARN_MORE.CLICK',
    )
  }

  return (
    <div>
      <span>{l.translate('Contact import from WhatsApp Business App failed.')}</span>{' '}
      <span className={cm.readMore} onClick={handleLearnMore}>
        {l.translate('Learn more')}
      </span>
      <Modal
        open={isModalOpen}
        width="460px"
        title={l.translate('Your contacts didn’t import properly')}
        onRequestClose={toggle}
      >
        <div className="p-a-xs">
          <p>
            {isChatPermissionsError
              ? l.translate(
                  'Due to missing permissions for shared chats, contacts were not imported correctly, follow steps below to try again.',
                )
              : l.translate(
                  'We’re not sure what happened, but you can follow these steps to try again.',
                )}
          </p>
          <ul className="p-0 m-b-md">
            {points.map((point, index) => (
              <li key={index} className="m-t d-flex align-center">
                <BulletListItem
                  n={index + 1}
                  className="align-center"
                  counterClassName="text-subheading"
                >
                  {point}
                </BulletListItem>
              </li>
            ))}
          </ul>
          <p>
            {l.translate(
              'If that doesn’t work, you can import your contacts with a CSV file instead.',
            )}
          </p>
        </div>
      </Modal>
    </div>
  )
}

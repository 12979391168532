import { l } from '@manychat/manyui'

import { alert } from 'common/core'
import { IAsyncThunkActionWithReturnValue, IThunkAction } from 'common/core/interfaces/actions'
import { CreateFolderResponse } from 'common/core/interfaces/folder'
import { getIsFolderNameExists } from 'common/tags/selectors/tagFoldersSelectors'
import { TagsActions } from 'common/tags/store'
import * as API from 'constants/API'
import { TagType } from 'shared/api/requests/tags/schemas'
import { makeLimitedListActions } from 'utils/factory'
import { handleCatchError } from 'utils/handleCatchError'
import { anotherTabNotificationsListener } from 'utils/services/notificationsService'

import * as atypes from '../tagsReduxActionTypes'

const FOLDER_ENTITY_TAGS = 40
const ROOT_PATH = '/'

const baseListActions = makeLimitedListActions('tagsFolders')

export const tagsFoldersCreatedNotification = baseListActions.createdNotification
export const tagsFoldersUpdatedNotification = baseListActions.updatedNotification
export const tagsFoldersDeletedNotification = baseListActions.deletedNotification

export const fetchTagsFolders = () =>
  baseListActions.fetch({}, { entity: FOLDER_ENTITY_TAGS, path: ROOT_PATH })

export const createTagsFolder =
  (title: string): IThunkAction =>
  async (dispatch, getState) => {
    const isFolderNameExists = getIsFolderNameExists(getState(), title)

    if (isFolderNameExists) {
      alert(l.translate('Folder with this name already exists'), 'danger')
      return
    }

    const response = await dispatch(
      baseListActions.create({ title, entity: FOLDER_ENTITY_TAGS, path: ROOT_PATH }),
    )
    alert(l.translate('Folder created'), 'success')

    return response
  }

export const createFolderAndMoveTags = (title: string, tagIds: number[]): IThunkAction => {
  return async (dispatch, getState) => {
    const isFolderNameExists = getIsFolderNameExists(getState(), title)

    if (isFolderNameExists) {
      alert(l.translate('Folder with this name already exists'), 'danger')
      return
    }

    const { folder } = (await dispatch(createTagsFolder(title))) as unknown as CreateFolderResponse
    await dispatch(TagsActions.bulkMoveFx({ tagIds, folderId: folder.folder_id }))
  }
}

export const removeTagsFolder =
  (
    folderId: number,
    options: {
      path: string
      delete_content: boolean
    },
  ): IThunkAction =>
  async (dispatch) => {
    await dispatch(baseListActions.delete(folderId, options))
    if (!options.delete_content) {
      dispatch(
        TagsActions.getListFx({
          payload: { query: { path: '/', type: TagType.USER } },
          effectConfig: { forceRequest: true },
        }),
      )
    }
    alert(l.translate('Folder deleted!'), 'success')
  }

export const updateTagsFolderName =
  (folderId: number, path: string, title: string): IAsyncThunkActionWithReturnValue<boolean> =>
  async (dispatch, getState, { api }) => {
    const isFolderNameExists = getIsFolderNameExists(getState(), title)

    if (isFolderNameExists) {
      alert(l.translate('Folder with this name already exists'), 'danger')
      return false
    }

    try {
      await api.post(API.tagsFolders.endpoints.rename, { body: { path, title } })

      dispatch(baseListActions.update(folderId, { title }))
      alert(l.translate('Folder renamed!'), 'success')

      return true
    } catch (error) {
      handleCatchError(error)

      return false
    }
  }

export const setSelectedTagsIds = (tagsIds: number[]) => ({
  type: atypes.TAGS_SET_SELECTED_IDS,
  payload: tagsIds,
})

export const resetSelectedTagsIds = () => ({
  type: atypes.TAGS_RESET_SELECTED_IDS,
})

anotherTabNotificationsListener.on('folder_created', (data, dispatch) => {
  if (data.folder.entity === FOLDER_ENTITY_TAGS) {
    dispatch(tagsFoldersCreatedNotification(data.folder))
  }
})

anotherTabNotificationsListener.on('folder_renamed', (data, dispatch) => {
  if (data.folder.entity === FOLDER_ENTITY_TAGS) {
    dispatch(tagsFoldersUpdatedNotification(data.folder))
  }
})

anotherTabNotificationsListener.on('folder_deleted', (data, dispatch) => {
  if (data.folder) {
    if (data.folder.entity === FOLDER_ENTITY_TAGS) {
      dispatch(tagsFoldersDeletedNotification(data.folder.folder_id))
    }
  }
})

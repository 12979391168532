import { l } from '@manychat/manyui'

import { alert } from 'common/core'

export const warnAccessDenied = () =>
  alert(l.translate('This Item is Locked'), undefined, {
    icon: 'Protect',
    source: l.translate(
      'This item is a part of a locked Template which means it is locked from viewing and editing by the Template owner',
    ),
  })

export const warnDisabled = () =>
  alert(l.translate('This Item is Disabled'), 'danger', {
    icon: 'Protect',
    source: l.translate('Please reach out to the Template owner to turn it on'),
  })

export const openInstalledTemplatePage = (pageId: string, installationId: number) => {
  const url = `/${pageId}/settings/templates/${installationId}`

  window.location.replace(url)
}

import { LayoutWithSidebar } from 'utils/router/components/LayoutWithSidebar/LayoutWithSidebar'
import { getHandle } from 'utils/router/utils/getHandle'

export const BuilderRoutes = [
  {
    path: 'flow/:flow_id/:mode?/:content_id?',
    handle: getHandle('flow'),
    Component: LayoutWithSidebar,
    children: [
      {
        index: true,
        lazy: () => import('./generic-builder.page'),
      },
    ],
  },
] as const

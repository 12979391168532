import { z } from 'zod'

import UserFieldsTypes from 'common/fields/constants/UserFieldsTypes'

/** ENUMS */

export enum MessageSource {
  AI_ASSISTANT = 'assistant',
  USER = 'user',
}

export enum MessageType {
  // AiAssistant messages
  TEXT = 'text',
  INPUT = 'input', // SHOW INPUT BAR
  BUTTONS = 'buttons', // SHOW BUTTONS
  GENERATION_STATUS = 'generation_status',
  WAITING = 'waiting',
  FIELD_GROUP = 'field_group',
  SUBSCRIBER_DATA = 'subscriber_data',

  // User messages
  USER_INPUT = 'user_input',
  BUTTON_CLICK = 'button_click',
}

export enum GenerationStatus {
  IN_PROGRESS = 'generating',
  SUCCESS = 'done',
  ERROR = 'error',
}

/** SCHEMAS */

export const BaseMessageSchema = z.object({
  id: z.string(),
  author: z.nativeEnum(MessageSource),
  ts: z.number(),
})

export const TextMessageSchema = BaseMessageSchema.and(
  z.object({
    type: z.literal(MessageType.TEXT),
    data: z.object({
      text: z.string(),
      buttons: z.optional(z.array(z.object({ title: z.string(), url: z.string() }))),
    }),
  }),
)

export const InputMessageSchema = BaseMessageSchema.and(
  z.object({
    type: z.literal(MessageType.INPUT),
    data: z.object({
      text: z.string(),
      placeholder: z.string().optional(),
      buttons: z.optional(z.array(z.object({ title: z.string(), url: z.string() }))),
    }),
  }),
)

export const ButtonsMessageSchema = BaseMessageSchema.and(
  z.object({
    type: z.literal(MessageType.BUTTONS),
    data: z.object({
      text: z.string(),
      buttons: z.array(z.object({ id: z.string(), label: z.string() })),
    }),
  }),
)

export const GenerationStatusMessageSchema = BaseMessageSchema.and(
  z.object({
    type: z.literal(MessageType.GENERATION_STATUS),
    data: z.object({ status: z.nativeEnum(GenerationStatus), draft_id: z.number().optional() }),
  }),
)

export const UserInputMessageSchema = BaseMessageSchema.and(
  z.object({
    type: z.literal(MessageType.USER_INPUT),
    data: z.object({ input: z.string() }),
  }),
)

export const ButtonClickMessageSchema = BaseMessageSchema.and(
  z.object({
    type: z.literal(MessageType.BUTTON_CLICK),
    data: z.object({ button_id: z.string(), button_label: z.string() }),
  }),
)

export const WaitingMessageSchema = BaseMessageSchema.and(
  z.object({
    type: z.literal(MessageType.WAITING),
    data: z.unknown(),
  }),
)

export const UserFieldSchema = z.object({
  field: z.string(),
  type: z.nativeEnum(UserFieldsTypes),
  value: z.union([z.string(), z.boolean(), z.number()]),
})

export const SystemFieldGroupMessageSchema = BaseMessageSchema.and(
  z.object({
    type: z.literal(MessageType.FIELD_GROUP),
    data: z.object({
      text: z.string(),
      fields: z.array(UserFieldSchema),
    }),
  }),
)

export const SubscriberDataMessageSchema = BaseMessageSchema.extend({
  type: z.literal(MessageType.SUBSCRIBER_DATA),
  data: z.object({
    fields: z.array(
      z.object({
        field_id: z.string(),
        value: z.union([z.string(), z.boolean(), z.number()]),
      }),
    ),
  }),
})

export const AnyChatMessageSchema = z.union([
  TextMessageSchema,
  InputMessageSchema,
  ButtonsMessageSchema,
  GenerationStatusMessageSchema,
  UserInputMessageSchema,
  ButtonClickMessageSchema,
  WaitingMessageSchema,
  SystemFieldGroupMessageSchema,
  SubscriberDataMessageSchema,
])

export const ChatMessageWithData = z.union([
  TextMessageSchema,
  InputMessageSchema,
  ButtonsMessageSchema,
  GenerationStatusMessageSchema,
  UserInputMessageSchema,
  ButtonClickMessageSchema,
  SystemFieldGroupMessageSchema,
  SubscriberDataMessageSchema,
])

/** INFERED TYPES */

export type AnyChatMessage = z.infer<typeof AnyChatMessageSchema>
export type ChatMessageWithData = z.infer<typeof ChatMessageWithData>
export type TextMessage = z.infer<typeof TextMessageSchema>
export type InputMessage = z.infer<typeof InputMessageSchema>
export type ButtonsMessage = z.infer<typeof ButtonsMessageSchema>
export type GenerationStatusMessage = z.infer<typeof GenerationStatusMessageSchema>
export type UserInputMessage = z.infer<typeof UserInputMessageSchema>
export type ButtonClickMessage = z.infer<typeof ButtonClickMessageSchema>
export type WaitingMessage = z.infer<typeof WaitingMessageSchema>
export type SystemFieldGroupMessage = z.infer<typeof SystemFieldGroupMessageSchema>
export type UserField = z.infer<typeof UserFieldSchema>
export type SubscriberDataMessage = z.infer<typeof SubscriberDataMessageSchema>
export type UnknownMessage = Partial<{ type: string } & z.infer<typeof BaseMessageSchema>>

import React from 'react'
import cx from 'classnames'
import endsWith from 'lodash/endsWith'

/**
 * @typedef {Object} BlockProps
 * @property {string|number} [width] - The width of the block, can be a string (percentage) or a number (pixels).
 * @property {React.ReactNode|React.ReactNode[]} [children] - The children elements to be rendered inside the block.
 * @property {string} [className] - Additional class names to apply to the block.
 * @property {React.ElementType} [Component='div'] - The component type to render as the block.
 * @property {number} [xs] - The column size for extra small screens.
 * @property {number} [sm] - The column size for small screens.
 * @property {number} [md] - The column size for medium screens.
 * @property {number} [lg] - The column size for large screens.
 * @property {number} [xl] - The column size for extra large screens.
 * @property {string|boolean} [auto] - Whether the column should auto size.
 * @property {boolean} [grow] - Whether the column should grow to fill available space.
 * @property {boolean} [xsFirst] - Whether the column should be first on extra small screens.
 *
 * Block component to render a responsive grid block.
 *
 * @param {BlockProps} props
 * @returns {JSX.Element}
 */
const Block = (props) => {
  let { auto, ...otherConcreteProps } = props
  const {
    children,
    className,
    width,
    grow,
    xsFirst,
    xs,
    sm,
    md,
    lg,
    xl,
    Component = 'div',
    ...rest
  } = otherConcreteProps

  const cls = [className]
  if (xs === +xs) {
    cls.push(`col-${xs}`)
    auto = false
  }
  if (sm === +sm) {
    cls.push(`sm-col-${sm}`)
    auto = false
  }
  if (md === +md) {
    cls.push(`md-col-${md}`)
    auto = false
  }
  if (lg === +lg) {
    cls.push(`lg-col-${lg}`)
    auto = false
  }
  if (xl === +xl) {
    cls.push(`xl-col-${xl}`)
    auto = false
  }

  if (auto) {
    if (sm) {
      cls.push(`sm-col-auto`)
    }
    if (md) {
      cls.push(`md-col-auto`)
    }
    if (lg) {
      cls.push(`lg-col-auto`)
    }
    if (xl) {
      cls.push(`xl-col-auto`)
    }
    if (!xs && !sm && !md && !lg && !xl) {
      cls.push('col-auto')
    }
  } else {
    cls.push('col')
  }

  if (grow) {
    if (sm) {
      cls.push(`sm-flex-grow`)
    }
    if (md) {
      cls.push(`md-flex-grow`)
    }
    if (lg) {
      cls.push(`lg-flex-grow`)
    }
    if (xl) {
      cls.push(`xl-flex-grow`)
    }
    if (!xs && !sm && !md && !lg && !xl) {
      cls.push('flex-grow')
    }
  }

  if (xsFirst) {
    cls.push('first-xxs')
  }

  // Firefox-specific max-width hack was applied to fix situations when width of children nodes is greater than
  // width of the column itself.
  const style = {}
  if (width === +width) {
    style.flex = `0 0 ${width}px`
    style.width = width
    style.maxWidth = width
  } else if (endsWith(width, '%')) {
    style.flex = `0 0 ${width}`
    style.width = width
    style.maxWidth = width
  }

  return (
    <Component className={cx(cls)} style={style} {...rest}>
      {children}
    </Component>
  )
}

export default Block

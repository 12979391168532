import React from 'react'
import { l } from '@manychat/manyui'
import { MakeTranslateReturnValue } from '@manychat/manyui/src/utils/localization/format'

import {
  ICE_BREAKERS_ROUTE,
  INSTAGRAM_ICE_BREAKERS_ROUTE,
  WHATSAPP_ICE_BREAKERS_ROUTE,
  IceBreakerChannelType,
} from 'apps/iceBreakers/constants/constants'
import {
  getIsEnabled,
  getIsInstagramEnabled,
  getIsWhatsAppEnabled,
} from 'apps/iceBreakers/selectors/iceBreakersSelectors'
import { NodeType } from 'common/builder'
import { ChannelType } from 'common/core/constants/ChannelType'
import { IBreadcrumbsItem } from 'common/core/interfaces/breadcrumbsItem'
import {
  InstagramPreviewProps,
  InstagramPreview,
} from 'common/settings/components/InstagramPreview/InstagramPreview'
import {
  MessengerIcebreakersPreview,
  MessengerIcebreakersPreviewProps,
} from 'common/settings/components/MessengerIcebreakersPreview/MessengerIcebreakersPreview'
import { InstagramSettingsItem } from 'common/settings/components/SettingsPanel/InstagramSection/breadcrumbs'
import { SettingsItem as MessengerSettingsItem } from 'common/settings/components/SettingsPanel/MessengerSection/breadcrumbs'
import { SettingsItem as WhatsAppSettingsItem } from 'common/settings/components/SettingsPanel/WhatsAppSection/breadcrumbs'
import {
  WhatsAppPreview,
  WhatsAppPreviewProps,
} from 'common/settings/components/WhatsAppPreview/WhatsAppPreview'

type IceBreakersMobileAppPreviewProps =
  | InstagramPreviewProps
  | WhatsAppPreviewProps
  | MessengerIcebreakersPreviewProps

interface IceBreakerChannelConfig<
  C extends IceBreakerChannelType,
  T extends IceBreakersMobileAppPreviewProps,
> {
  channel: C
  route: string
  header: MakeTranslateReturnValue
  flowManagerBuilderOptions: Partial<{ initialNodeType: NodeType }>
  breadcrumbsSettingsItem: IBreadcrumbsItem
  isEnabled: (state: RootState) => boolean
  MobilePreview: React.ComponentType<T>
}

type InstagramIceBreakerConfig = IceBreakerChannelConfig<
  ChannelType.INSTAGRAM,
  InstagramPreviewProps
>
type WhatsAppIceBreakerConfig = IceBreakerChannelConfig<ChannelType.WHATSAPP, WhatsAppPreviewProps>
type MessengerIceBreakerConfig = IceBreakerChannelConfig<
  ChannelType.FB,
  MessengerIcebreakersPreviewProps
>

interface IceBreakerChannels {
  [ChannelType.INSTAGRAM]: InstagramIceBreakerConfig
  [ChannelType.WHATSAPP]: WhatsAppIceBreakerConfig
  [ChannelType.FB]: MessengerIceBreakerConfig
}

const IceBreakersConfig: IceBreakerChannels = {
  [ChannelType.INSTAGRAM]: {
    channel: ChannelType.INSTAGRAM,
    route: INSTAGRAM_ICE_BREAKERS_ROUTE,
    header: l.makeTranslate('Conversation Starters'),
    flowManagerBuilderOptions: { initialNodeType: NodeType.INSTAGRAM },
    breadcrumbsSettingsItem: InstagramSettingsItem,
    isEnabled: (state) => getIsInstagramEnabled(state),
    MobilePreview: InstagramPreview,
  },
  [ChannelType.WHATSAPP]: {
    channel: ChannelType.WHATSAPP,
    route: WHATSAPP_ICE_BREAKERS_ROUTE,
    header: l.makeTranslate('Ice Breakers'),
    flowManagerBuilderOptions: { initialNodeType: NodeType.WHATS_APP },
    breadcrumbsSettingsItem: WhatsAppSettingsItem,
    isEnabled: (state) => getIsWhatsAppEnabled(state),
    MobilePreview: WhatsAppPreview,
  },
  [ChannelType.FB]: {
    channel: ChannelType.FB,
    route: ICE_BREAKERS_ROUTE,
    header: l.makeTranslate('Conversation Starters'),
    flowManagerBuilderOptions: {},
    breadcrumbsSettingsItem: MessengerSettingsItem,
    isEnabled: (state) => getIsEnabled(state),
    MobilePreview: MessengerIcebreakersPreview,
  },
}

export function getIceBreakersConfig<T extends IceBreakerChannelType>(
  channel: T,
): IceBreakerChannels[T] {
  return IceBreakersConfig[channel] ?? IceBreakersConfig[ChannelType.FB]
}

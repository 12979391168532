import { l } from '@manychat/manyui'

import { billing, UpgradeSource } from 'common/billing'
import { Ability } from 'common/core/constants/Ability'

export const showLimitsExceededError = () => {
  billing.proAlert({
    message: l.translate('Subscribe to Manychat AI to test AI Step without daily limits'),
    buttonLabel: l.translate('Subscribe'),
    source: UpgradeSource.AI_PLAYGROUND_LIMIT_ERROR,
    abilities: [Ability.AI_AGENT],
  })
}

import React from 'react'
import cx from 'classnames'
import { Icon as IconType } from '@manychat/icons'

import cm from './ToolBtn.module.css'

const ToolBtn = ({
  Icon,
  className,
  onClick,
  tooltip,
}: {
  Icon: IconType
  className?: string
  onClick?: () => void
  tooltip?: string
}) => {
  const tooltipProps = tooltip ? { 'data-title': tooltip, 'data-title-at': 'left' } : {}

  return (
    <div className={cx(cm.btn, className)} onClick={onClick} {...tooltipProps}>
      <Icon size={20} />
    </div>
  )
}

export default ToolBtn

import React from 'react'
import cx from 'classnames'
import range from 'lodash/range'
import PropTypes from 'prop-types'

import palettes from '../../../constants/palettes'
import cleanProps from '../../../lib/clean-react-props'

import cm from './Slider.module.css'

/**
 * @param {number} value
 * @param {number} min
 * @param {number} max
 * @param {number} step
 * @return {number}
 */
export function calcRunnableTrack(value, min, max) {
  if (value < min) {
    value = min
  }
  if (value > max) {
    value = max
  }
  return ((value - min) * 100) / (max - min)
}

export default class Slider extends React.PureComponent {
  constructor(props) {
    super(props)

    this.state = {
      runnableTrack: calcRunnableTrack(props.value, props.min, props.max),
      pressed: false,
    }
  }

  static propTypes = {
    max: PropTypes.number,
    min: PropTypes.number,
    value: PropTypes.number,
    color: PropTypes.string,
    onChange: PropTypes.func,
    onMouseDown: PropTypes.func,
    onMouseUp: PropTypes.func,
    step: PropTypes.number,
    showSteps: PropTypes.bool,
  }

  static defaultProps = {
    max: 100,
    min: 0,
    value: 0,
    color: palettes.semantic.primary,
    step: 1,
    showSteps: false,
    onChange() {},
    onMouseDown() {},
    onMouseUp() {},
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.value !== this.props.value) {
      this.setState({
        runnableTrack: calcRunnableTrack(nextProps.value, this.props.min, this.props.max),
      })
    }
  }

  handleChange = (e) => {
    const { min, max, onChange } = this.props
    const { value } = e.target

    this.setState({
      runnableTrack: calcRunnableTrack(value, min, max),
    })

    onChange(e)
  }

  handleMouseDown = (e) => {
    this.setState({ pressed: true })
    this.props.onMouseDown(e)
  }

  handleMouseUp = (e) => {
    this.setState({ pressed: false })
    this.props.onMouseUp(e)
  }

  render() {
    const { min, max, step, showSteps, value, color, disabled, ...rest } = this.props
    const { runnableTrack, pressed } = this.state

    return (
      <div className={cx(cm.wrap, disabled && cm.disabled)}>
        <span
          className={cm.runnableTrack}
          style={{ width: `${runnableTrack}%`, backgroundColor: color }}
        />
        <input
          {...cleanProps(rest)}
          style={{ color }}
          type="range"
          min={min}
          max={max}
          step={step}
          value={value}
          disabled={disabled}
          onChange={disabled ? undefined : this.handleChange}
          onMouseDown={disabled ? undefined : this.handleMouseDown}
          onMouseUp={this.handleMouseUp}
          className={cx(cm.slider, { [cm.pressed]: pressed, [cm.stepped]: showSteps })}
        />
        {showSteps && (
          <div className={cx(cm.steps, 'd-flex', 'p-absolute')}>
            {range(min, max + step / max, step).map((n) => (
              <div
                key={n}
                className={cx(cm.step, 'd-flex', 'p-absolute')}
                style={{ left: `${calcRunnableTrack(n, min, max)}%` }}
              >
                <span>{n}</span>
              </div>
            ))}
          </div>
        )}
      </div>
    )
  }
}

import React from 'react'
import cx from 'classnames'
import { useAppSelector } from 'reduxTyped'
import { l, ToggleV2 } from '@manychat/manyui'

import { useActivationToggle } from 'apps/cms/components/Flows/components/FlowCard/components/Triggers/components/TriggerRow/hooks/useActivationToggle'
import { CardTrigger } from 'apps/cms/components/Flows/components/FlowCard/components/Triggers/lib/types'
import { contentManagementSelectors } from 'apps/cms/store'
import { TriggerType } from 'common/flow/constants/TriggerType'
import { PermissionTargets, usePermission } from 'common/userRoles'

import cm from './TriggerRow.module.css'

interface TriggerRowProps {
  trigger: CardTrigger
  flowId: string
  hideToggle?: boolean
}

export const TriggerRow = ({ trigger, flowId, hideToggle }: TriggerRowProps) => {
  const userCanEditFlow = usePermission(PermissionTargets.FLOW_EDITING)
  const isAnyFilterApplied = useAppSelector(contentManagementSelectors.getAnyFilterApplied)
  const isTrashFolder = useAppSelector(contentManagementSelectors.getIsTrashFolder)
  const isAnotherFlowTrigger = trigger.type === TriggerType.ANOTHER_FLOW
  const isStatusColumnAvailable = !isTrashFolder && !isAnotherFlowTrigger && userCanEditFlow

  const { showFlowCardActivationToggle } = useAppSelector(contentManagementSelectors.getUi)
  const isSearchMode = useAppSelector(contentManagementSelectors.getIsSearchMode)

  const { active, loading, isAvailableToggle, handleClickTrigger, handleToggleTrigger } =
    useActivationToggle({
      trigger,
      flowId,
    })

  const statisticsColumnClassName = cx(
    'text-right text-sm',
    cm.statistics,
    !active && cm.disabled,
    isAnyFilterApplied && cm.statistics_accented,
  )

  return (
    <div
      className={cx(cm.triggerRow, {
        [cm.triggerRow_withToggle]: showFlowCardActivationToggle,
        [cm.triggerRow_searchMode]: isSearchMode,
      })}
    >
      <div className="d-flex align-start text-sm o-hidden">
        <div className={cx(cm.triggerIcon, !active && cm.disabled)}>{trigger.icon}</div>

        <div className={cx('o-hidden', cm.triggerName, !active && cm.disabled)}>
          {trigger.title}
        </div>
      </div>

      {isStatusColumnAvailable && !hideToggle && (
        <div
          onClick={handleClickTrigger}
          data-title={
            !isAvailableToggle && showFlowCardActivationToggle
              ? l.translate('Open Automation to set up a trigger')
              : undefined
          }
          className={cm.triggerStatus}
        >
          {showFlowCardActivationToggle && (
            <ToggleV2
              value={active}
              checked={active}
              loading={loading}
              disabled={!isAvailableToggle}
              onChange={handleToggleTrigger}
              className={cm.triggerToggle}
              ariaLabel={
                active
                  ? l.translate('Automation trigger is on')
                  : l.translate('Automation trigger is off')
              }
            />
          )}
          {!active && <span className={cx(cm.disabledLabel, 'text-sm')}>Off</span>}
        </div>
      )}

      {(!isStatusColumnAvailable || hideToggle) && <div />}

      <div className={cx(statisticsColumnClassName, cm.triggerRow_hideOnMobile)}>
        {trigger.runs}
      </div>

      <div className={cx(statisticsColumnClassName, cm.triggerRow_hideOnMobile)}>{trigger.ctr}</div>

      <div className={cx('text-sm', cm.triggerRow__mobileStatistics)}>
        <div className={cm.triggerRow__mobileStatisticsGroup}>
          <div className={cm.triggerRow__mobileStatisticsRow}>
            <span className="text-ellipsis">{l.translate('Runs')}</span>

            <span className={cx('flex-shrink-0', statisticsColumnClassName)}>{trigger.runs}</span>
          </div>

          <div className={cm.triggerRow__mobileStatisticsRow}>
            <span className="text-ellipsis">CTR</span>

            <span className={cx('flex-shrink-0', statisticsColumnClassName)}>{trigger.ctr}</span>
          </div>
        </div>
      </div>

      <div className={cm.triggerRow_hideOnMobile} />

      <div className={cm.triggerRow_hideOnMobile} />
    </div>
  )
}

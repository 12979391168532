import { StoryMentionReplyState } from './interfaces'

export const getInitialStoryMentionReplyState = (): StoryMentionReplyState => ({
  flowId: null,
  chId: null,
  enabled: null,
  isFetched: false,
  fetching: false,
})

export type StoryMentionReplyPartialRootState = Pick<RootState, 'storyMention'>

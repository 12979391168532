export enum FilterOperator {
  AND = 'AND',
  IS = 'IS',
  OR = 'OR',
}

export interface FilterGroup {
  operator: FilterOperator
  items: UnsafeAnyObject[]
}

export interface FilterDataType {
  operator: FilterOperator
  groups?: FilterGroup[]
}

import React from 'react'
import { Modal, l } from '@manychat/manyui'

import { useSignInOptions } from 'utils/router/components/LayoutWithSidebar/Sidebar/MyProfileLink/MyProfileLinkPopover/hooks/useSignInOptions'

import { ProfileTypes } from './constants'
import ProfileCard, { IProfileCardProps } from './ProfileCard/'

interface IManageProfilesModalProps {
  isOpen: boolean
  handleClose: () => void
}

const ManageProfilesModal = ({ isOpen, handleClose }: IManageProfilesModalProps) => {
  const {
    fbProfile,
    googleProfile,
    appleProfile,
    telegramProfile,
    handleTelegramConnect,
    isTelegramConnecting,
  } = useSignInOptions()

  const profilesData: Array<Omit<IProfileCardProps, 'withBottomBorder'>> = [
    {
      profileType: ProfileTypes.FB,
      profile: fbProfile,
      title: 'Facebook',
    },
    {
      profileType: ProfileTypes.GOOGLE,
      profile: googleProfile,
      title: 'Google',
    },
    {
      profileType: ProfileTypes.APPLE,
      profile: appleProfile,
      title: 'Apple',
    },
    {
      profileType: ProfileTypes.TELEGRAM,
      profile: telegramProfile,
      title: 'Telegram',
      handleTelegramConnect,
      isTelegramConnecting,
    },
  ]

  return (
    <Modal title={l.translate('Manage sign-in options')} open={isOpen} onRequestClose={handleClose}>
      <div className="p-x">
        <p className="p-x-xl p-y-lg text-center">
          {l.translate(
            'Connect alternative sign-in options to have backup access to your <br /> profile and accounts',
          )}
        </p>
        {profilesData.map((profileData, index, { length }) => (
          <ProfileCard
            key={profileData.title}
            {...profileData}
            withBottomBorder={index !== length - 1}
          />
        ))}
      </div>
    </Modal>
  )
}

export default ManageProfilesModal

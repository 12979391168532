import React from 'react'
import { Icon } from '@manychat/manyui'

import {
  getRuns,
  getCtr,
  getWidgetStatistics,
} from 'apps/cms/components/Flows/components/FlowCard/components/Triggers/hooks/useTriggers/lib/statistics'
import { TriggerRuleStatusType } from 'apps/triggerRules/models/TriggerRule/constants'
import { TriggerType } from 'common/flow/constants/TriggerType'
import { RuleTrigger } from 'shared/api/requests/cms/schemas'

export const getRuleTrigger = (rule: RuleTrigger) => ({
  icon: <Icon.Bot size={16} />,
  disabled: rule.status === TriggerRuleStatusType.INACTIVE,
  runs: getRuns(rule.runs),
  ctr: getCtr(null),
  created: rule.ts_created,
  status: rule.status,
  id: rule.rule_id,
  type: TriggerType.RULE,
  impressions: getWidgetStatistics(null),
  optIns: getWidgetStatistics(null),
})

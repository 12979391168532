import React from 'react'

import cm from './SelectionArea.module.css'

const SelectionArea = ({
  width,
  height,
  borderWidth,
  borderRadius,
  leftColor,
  rightColor,
  stepId,
}: {
  width: number
  height: number
  borderWidth: number
  borderRadius: number
  leftColor: string
  rightColor: string
  stepId: string
}) => {
  const outerBorderRadius = borderRadius + borderWidth
  return (
    <svg
      key={stepId}
      width={width}
      height={height}
      viewBox={`0 0 ${width} ${height}`}
      fill="none"
      className={cm.selection}
      style={{
        borderRadius: outerBorderRadius,
      }}
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        x={borderWidth / 2}
        y={borderWidth / 2}
        width={width - borderWidth}
        height={height - borderWidth}
        rx={borderRadius}
        stroke="url(#paint0_linear)"
        strokeWidth={borderWidth}
      />
      <defs>
        <linearGradient
          id="paint0_linear"
          x1="0"
          y1="0"
          x2={width}
          y2="0"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor={leftColor} />
          <stop offset="1" stopColor={rightColor} />
        </linearGradient>
      </defs>
    </svg>
  )
}

export default SelectionArea

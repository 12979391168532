import {
  createSlice,
  PayloadAction,
  SliceCaseReducers,
  Slice,
  CreateSliceOptions,
  CaseReducer,
} from '@reduxjs/toolkit'

export interface DefaultModalState {
  isOpen: boolean
}

interface ModalCaseReducers<State> extends SliceCaseReducers<State> {
  open: CaseReducer<State, PayloadAction<void>>
  close: CaseReducer<State, PayloadAction<void>>
  toggle: CaseReducer<State, PayloadAction<void>>
}

export function createModalSlice<Name extends string = string>(
  name: Name,
): Slice<DefaultModalState, ModalCaseReducers<DefaultModalState>, Name>

export function createModalSlice<
  State extends DefaultModalState,
  CaseReducers extends SliceCaseReducers<State>,
  Name extends string = string,
>(
  options: CreateSliceOptions<State, CaseReducers, Name>,
): Slice<State & DefaultModalState, CaseReducers & ModalCaseReducers<State>, Name>

export function createModalSlice<
  State extends DefaultModalState,
  CaseReducers extends SliceCaseReducers<State>,
  Name extends string = string,
>(optionsOrName: CreateSliceOptions<State, CaseReducers, Name> | Name) {
  if (typeof optionsOrName === 'string') {
    const name: Name = optionsOrName

    return createSlice<DefaultModalState, ModalCaseReducers<DefaultModalState>, Name>({
      name,
      reducers: {
        open: (state: DefaultModalState) => {
          state.isOpen = true
        },
        close: (state: DefaultModalState) => {
          state.isOpen = false
        },
        toggle: (state: DefaultModalState) => {
          state.isOpen = !state.isOpen
        },
      },
      initialState: {
        isOpen: false,
      },
    })
  }

  const options = optionsOrName as CreateSliceOptions<State, CaseReducers, Name>

  return createSlice({
    ...options,
    reducers: {
      open: (state) => {
        state.isOpen = true
      },
      close: (state) => {
        state.isOpen = false
      },
      toggle: (state) => {
        state.isOpen = !state.isOpen
      },
      ...(options.reducers || {}),
    },
  })
}

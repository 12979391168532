import { api } from 'shared/api'
import { defaultReplyApiSchemas } from 'shared/api/requests/defaultReply/schemas'

export const defaultReplyApi = {
  getData: api.account.createGet({
    url: '/defaultReply/getData',
    schemas: defaultReplyApiSchemas.getData,
  }),
  switch: api.account.createGet({
    url: '/defaultReply/switch',
    schemas: defaultReplyApiSchemas.switch,
  }),
  changeType: api.account.createPost({
    url: '/defaultReply/changeType',
    schemas: defaultReplyApiSchemas.changeType,
  }),
  replaceFlow: api.account.createPost({
    url: '/defaultReply/replaceFlow',
    schemas: defaultReplyApiSchemas.replaceFlow,
  }),
  changeSource: api.account.createPost({
    url: '/defaultReply/changeSource',
    schemas: defaultReplyApiSchemas.changeSource,
  }),
  removeFlow: api.account.createPost({
    url: '/defaultReply/removeFlow',
    schemas: defaultReplyApiSchemas.removeFlow,
  }),
}

import dot from 'dot-prop-immutable'

import * as atypes from '../zapierReduxActionTypes'

const initialState = { items: null, fetching: false, error: null }

export default function zapierReducer(state = initialState, action) {
  switch (action.type) {
    case atypes.ZAP_CREATED_NOTIFICATION: {
      if (state.items == null) {
        break
      }
      const index = state.items.findIndex((i) => i.event_name === action.zap.event_name)
      if (index < 0) {
        return { ...state, items: [...state.items, action.zap] }
      }
      break
    }
    case atypes.ZAP_REMOVED_NOTIFICATION: {
      if (state.items == null) {
        break
      }
      return {
        ...state,
        items: state.items.filter((i) => String(i.event_id) !== String(action.eventId)),
      }
    }
    case atypes.ZAPS_FETCH_REQUEST: {
      return { ...state, fetching: true, error: null }
    }
    case atypes.ZAPS_FETCH_RESPONSE: {
      const { data } = action
      return { ...state, items: data.events, fetching: false, error: null }
    }
    case atypes.ZAPS_FETCH_ERROR: {
      const { error } = action
      return { ...state, items: null, fetching: false, error }
    }
    case atypes.ZAPS_CREATE_REQUEST: {
      const newEvent = { event_name: action.event_name, requestId: action.requestId }
      return { ...state, items: [...state.items, newEvent] }
    }
    case atypes.ZAPS_CREATE_RESPONSE: {
      const { data, requestId } = action
      const { event } = data
      const index = state.items.findIndex((e) => e.requestId === requestId)
      return dot.merge(state, `items.${index}`, event)
    }
    case atypes.ZAPS_CREATE_ERROR: {
      const { requestId } = action
      const index = state.items.findIndex((e) => e.requestId === requestId)
      return dot.delete(state, `items.${index}`)
    }
  }

  return state
}

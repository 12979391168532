import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import { FlowPickerSource } from 'apps/flowPicker/lib/constants'
import { InitialState } from 'apps/flowPicker/store/initialState'

export const { reducer: FlowPickerReducer, actions: FlowPickerActions } = createSlice({
  name: 'flowPicker',
  initialState: InitialState,
  reducers: {
    openModal: (state, action: PayloadAction<{ source: FlowPickerSource }>) => {
      state.isOpen = true
      state.source = action.payload.source
    },

    setSelectedFlowId: (state, action: PayloadAction<{ flowId: string }>) => {
      state.selectedFlowId = action.payload.flowId
    },

    closeModal: () => {
      return InitialState
    },
  },
})

export const FlowPickerSelectors = {
  getSelectedFlowId: (state: RootState) => state.flowPicker.selectedFlowId,
  getIsFlowPickerModalOpen: (state: RootState) => state.flowPicker.isOpen,
  getFlowPickerSource: (state: RootState) => state.flowPicker.source,
}

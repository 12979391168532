export default (defaultFilters) => {
  if (defaultFilters) return defaultFilters

  return Object.freeze({
    operator: 'AND',
    groups: Object.freeze([
      Object.freeze({
        operator: 'AND',
        items: Object.freeze([]),
      }),
    ]),
  })
}

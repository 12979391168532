import BaseBlockConfig from 'common/builder/blocks/BaseBlockConfig'
import { IBlockBase, IListItemBlock } from 'common/builder/blocks/blockInterfaces'
import { BlockType } from 'common/builder/constants/BlockType'

class ListItemBlockConfig extends BaseBlockConfig {
  blockType = BlockType.LIST_ITEM

  getDefaults = (): Omit<IListItemBlock, keyof IBlockBase> => ({
    title: '',
    subtitle: '',
    image: null,
    defaultAction: null,
  })
}

export default new ListItemBlockConfig()

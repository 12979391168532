import React, { useEffect, useState } from 'react'
import cx from 'classnames'

import { getProfileImageStubURL } from 'utils/assets'

import cm from './CircleAvatar.module.css'

export interface CircleAvatarProps {
  className?: string
  url?: string | null
  backgroundColor?: string
  title?: string
  tooltip?: string
  size?: number | string
  'data-test-id'?: string
}

const CircleAvatar = ({
  className,
  url,
  backgroundColor,
  title = '',
  tooltip,
  size = 32,
  'data-test-id': dataTestId = 'circle-avatar',
}: CircleAvatarProps) => {
  const [isLoadingError, setIsLoadingError] = useState(false)
  const preparedTitle = typeof title === 'string' ? title.trim().charAt(0).toUpperCase() : ''
  const preparedSize = Number(size) || 32
  const isImageValid = url && !isLoadingError
  const style = {
    width: preparedSize,
    height: preparedSize,
    backgroundColor: !isImageValid && preparedTitle ? backgroundColor : 'transparent',
    fontSize: preparedSize / 2,
    minHeight: preparedSize,
    minWidth: preparedSize,
  }

  useEffect(() => {
    if (url) {
      setIsLoadingError(false)
    }
  }, [url])

  const onLoadingError = () => setIsLoadingError(true)

  const getContent = () => {
    const commonImageProps = {
      className: cm.image,
      alt: title ?? 'Avatar',
      loading: 'lazy',
    } as const

    if (isImageValid) {
      return (
        <img
          {...commonImageProps}
          src={url}
          onError={onLoadingError}
          data-test-id={`${dataTestId}-image`}
        />
      )
    }

    if (preparedTitle) {
      return <span data-test-id={`${dataTestId}-title`}>{preparedTitle}</span>
    }

    return (
      <img
        {...commonImageProps}
        src={getProfileImageStubURL()}
        data-test-id={`${dataTestId}-default-image`}
      />
    )
  }

  return (
    <div
      data-title={tooltip}
      data-test-id={dataTestId}
      className={cx(cm.avatar, className)}
      style={style}
    >
      {getContent()}
    </div>
  )
}

const MemorizedCircleAvatar = React.memo(CircleAvatar)

export { MemorizedCircleAvatar as CircleAvatar }

// content types accepted on the backend
enum BackendContentType {
  DEFAULT = 'default',
  FORM = 'form',
  PERSISTENT_MENU = 'persistent_menu',
  ACTION_GROUP = 'action_group',
  GOTO = 'goto',
  MULTI_CONDITION = 'multi_condition',
  OLD_CONDITION = 'conditional',
  SPLIT = 'split',
  SMART_DELAY = 'smart_delay',
  NOTE = 'note',
  SMS = 'sms',
  EMAIL_NEW = 'email_new',
  WHATS_APP = 'whatsapp',
  INSTAGRAM = 'instagram',
  TELEGRAM = 'telegram',
  TIKTOK = 'tiktok',
  AI_AGENT = 'agent',
}

export default BackendContentType

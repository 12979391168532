import dot from 'dot-prop-immutable'

import { makeLimitedListReducer /* , mergeToListItem */ } from 'utils/factory'

import * as atypes from '../eventsReduxActionTypes'

const eventDefaults = {
  title: '',
  event_id: null,
  meta_type: 0,
  cost: null,
  availableCurrencies: {},
}

const listReducer = makeLimitedListReducer('customEvents', { isDeleteOnArchive: true })

function newEventsModalReducer(state = { open: false, ...eventDefaults }, action) {
  switch (action.type) {
    case atypes.EVENTS_OPEN_CREATE_MODAL: {
      const initialData = action.initialData || {}
      return {
        ...state,
        open: true,
        event_id: initialData.event_id || eventDefaults.event_id,
        title: initialData.title || eventDefaults.title,
        meta_type: initialData.meta_type || eventDefaults.meta_type,
        cost: initialData.const || eventDefaults.cost,
        description: initialData.description || eventDefaults.description,
      }
    }
    case atypes.EVENTS_CLOSE_CREATE_MODAL:
      return { ...state, open: false, ...eventDefaults }
    case atypes.EVENTS_CREATE_MODAL_CHANGE:
      return { ...state, ...action.data }
    case atypes.CUSTOM_EVENTS_CREATE_REQUEST:
      return { ...state, fetching: true }
    case atypes.CUSTOM_EVENTS_CREATE_RESPONSE:
      return { ...state, fetching: false, ...eventDefaults }
    case atypes.UPDATE_EVENTS_LIST: {
      const { event_id, list } = action.data
      const filteredList = list.filter((event) => event.event_id !== event_id)
      return dot.set(state, 'list', filteredList)
    }
    case atypes.CUSTOM_EVENTS_CREATE_ERROR:
      return { ...state, fetching: false }

    case atypes.EVENTS_GET_AVAILABLE_CURRENCIES_RESPONSE: {
      return {
        ...state,
        availableCurrencies: action.data.currencies,
      }
    }
  }
  return state
}

export default function customEventsReducerReducer(state, action) {
  state = listReducer(state, action)
  const createModal = newEventsModalReducer(state.createModal, action)
  if (createModal !== state.createModal) {
    state = { ...state, createModal }
  }

  return state
}

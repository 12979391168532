enum BackendMessageType {
  TEXT = 'text',
  ATTACHMENT = 'attachment',
  CARDS = 'cards',
  LIST = 'list',
  QUESTION = 'question',
  DELAY = 'delay',
  DYNAMIC = 'dynamic',
  SMS = 'sms',
  EXTERNAL_CARDS = 'context_cards',
  ATTACHMENTS = 'attachments',
  MESSAGE_TEMPLATE = 'template',
  WA_LIST_MESSAGE = 'wa_list_message',
  WA_CATALOG_MESSAGE = 'wa_catalog_message',
}

export default BackendMessageType

import React, { Component } from 'react'
import ReactDOM from 'react-dom/client'
import { Loader, Icon, tints } from '@manychat/manyui'

import './styles.css'
import { freezeBody } from 'utils'

class Lightbox extends Component {
  constructor(props) {
    super(props)
    this.state = {
      loading: false,
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.images !== this.props.images || nextProps.index !== this.props.index) {
      this.setState({ loading: true })
    }
  }

  handleLoad = () => {
    this.setState({ loading: false })
  }

  render() {
    const { loading } = this.state
    const { images, index, onClose } = this.props
    if (!images || !images.length) {
      freezeBody(false, 'lightbox')
      return null
    }
    freezeBody(true, 'lightbox')
    return (
      <div className="lightbox__wrapper">
        <div className="lightbox__overlay" onClick={onClose} />
        <a className="lightbox__close" onClick={onClose}>
          <Icon.Close color={tints.neutral[7]} />
        </a>
        {loading && (
          <div className="lightbox__loader" onClick={onClose}>
            <Loader />
          </div>
        )}
        <img
          src={images[index]}
          className={loading ? 'loading' : ''}
          onLoad={this.handleLoad}
          onError={this.handleLoad}
        />
      </div>
    )
  }
}

class LightboxComponent extends Component {
  componentDidMount() {
    const container = document.createElement('div')
    container.setAttribute('id', 'lightbox')
    this.container = document.body.appendChild(container)

    this.renderLightbox()

    document.addEventListener('keyup', this.keyUpHandler)
  }

  componentDidUpdate(prevProps, prevState) {
    this.renderLightbox()
  }

  componentWillUnmount() {
    const { container } = this
    if (container != null) {
      container.parentElement.removeChild(container)
    }

    document.removeEventListener('keyup', this.keyUpHandler)
  }

  renderLightbox() {
    ReactDOM.createRoot(this.container).render(<Lightbox {...this.props} />)
  }

  keyUpHandler = (e) => {
    const { images, onClose } = this.props
    if (images && images.length) {
      let isEscape = false
      if ('key' in e) {
        isEscape = e.key === 'Escape' || e.key === 'Esc'
      } else {
        isEscape = e.keyCode === 27
      }
      if (isEscape) {
        e.stopPropagation && e.stopPropagation()
        e.stopImmediatePropagation && e.stopImmediatePropagation()
        onClose()
      }
    }
  }

  render() {
    return null
  }
}

export default LightboxComponent

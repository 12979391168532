import { combineReducers } from 'redux'

import categories from './categories'
import integrations from './integrations'
import relatedTemplates from './relatedTemplates'
import current from './template'
import templates from './templates'

export default combineReducers({
  templates,
  categories,
  current,
  integrations,
  relatedTemplates,
})

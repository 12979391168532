import React from 'react'
import { l, Icon } from '@manychat/manyui'

const ConvertAlert: React.ReactElement = (
  <div className="d-flex">
    <Icon.CheckCircle className="m-r-xs" size={22} />
    <div style={{ width: '220px' }}>
      <div className="text-subheading m-b-xs">{l.translate('Automation converted')}</div>
      <div className="text-body">
        {l.translate('There might be a loss of content, please check it')}
      </div>
    </div>
  </div>
)

export default ConvertAlert

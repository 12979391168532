import React, { useState, useEffect, useRef, useCallback } from 'react'
import { l } from '@manychat/manyui'

import { isMobile } from 'utils'

interface ModifiedProps {
  modified: number | string
}

const HOUR_IN_MS = 1000 * 60 * 60

export const Modified = React.memo(({ modified }: ModifiedProps) => {
  const timestamp = Number(modified) * 1000

  const [, setFlag] = useState(true)
  const interval = useRef<ReturnType<typeof setInterval> | null>(null)

  const resetInterval = useCallback(() => {
    if (interval.current !== null) {
      clearInterval(interval.current)
    }
  }, [])

  useEffect(() => {
    if (!Number.isNaN(timestamp) && Date.now() - timestamp > HOUR_IN_MS) {
      return
    }

    if (interval.current !== null) {
      resetInterval()
    }

    interval.current = setInterval(() => {
      setFlag((prevState) => !prevState)
    }, 5 * 1000)

    return () => {
      resetInterval()
    }
  }, [resetInterval, timestamp])

  return (
    <span>
      {Number.isNaN(timestamp) ? l.translate('Unknown') : l.dateFromNow(timestamp, isMobile)}
    </span>
  )
})

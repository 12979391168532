import { Action } from 'redux'

import * as actionsTypes from 'apps/applications/constants/ReduxActionTypes'
import { IApplicationReviewReportState } from 'common/actions/integrations/Applications/interfaces'

const initialState: IApplicationReviewReportState = {
  applicationId: null,
  fetching: false,
  report: '',
}

interface IReportAction extends Action {
  applicationId: number | null
  data?: { report: string }
}

export default function currentApplicationReducer(
  state: IApplicationReviewReportState = initialState,
  action: IReportAction,
): IApplicationReviewReportState {
  switch (action.type) {
    case actionsTypes.APPLICATION_REVIEW_REPORT_MODAL_OPEN:
      state = { ...state, applicationId: action.applicationId }
      break
    case actionsTypes.APPLICATION_REVIEW_REPORT_MODAL_CLOSE:
      state = { ...state, applicationId: null }
      break
    case actionsTypes.APPLICATION_REVIEW_REPORT_FETCH_REQUEST:
      state = { ...state, fetching: true, report: '' }
      break
    case actionsTypes.APPLICATION_REVIEW_REPORT_FETCH_RESPONSE:
      if (action.applicationId === state.applicationId) {
        state = { ...state, fetching: false, report: action.data?.report ?? '' }
      }
      break
    case actionsTypes.APPLICATION_REVIEW_REPORT_FETCH_ERROR:
      if (action.applicationId === state.applicationId) {
        state = { ...state, fetching: false }
      }
      break
  }

  return state
}

import { AccountFlag } from 'utils/services/featureFlagsService/constants'

export const experimentFlags = [
  AccountFlag.UPGRADE_PAGE_GROUP_A,
  AccountFlag.UPGRADE_PAGE_GROUP_B,
  AccountFlag.TIER_NOTIFICATION_ENABLE,
  AccountFlag.TIER_NOTIFICATION_DISABLE,
] as const

export type ExperimentFlag = typeof experimentFlags[number]

export enum BillingExperiment {
  UPGRADE_PAGE = 'upgrade_page',
  TIER_NOTIFICATION = 'tier_notification',
}

export const experimentFlagMap: Record<
  BillingExperiment,
  [groupA: ExperimentFlag, groupB: ExperimentFlag]
> = {
  // upgrade page
  [BillingExperiment.UPGRADE_PAGE]: [
    AccountFlag.UPGRADE_PAGE_GROUP_A,
    AccountFlag.UPGRADE_PAGE_GROUP_B,
  ],

  // tier notification
  [BillingExperiment.TIER_NOTIFICATION]: [
    AccountFlag.TIER_NOTIFICATION_ENABLE,
    AccountFlag.TIER_NOTIFICATION_DISABLE,
  ],
}

export enum ExperimentGroup {
  A = 'A',
  B = 'B',
}

import React from 'react'
import { l } from '@manychat/manyui'

import { MappedIceBreaker } from 'apps/iceBreakers/constants/constants'
import { useWhatsAppChannel } from 'apps/whatsApp/MessageTemplates/hooks/useWhatsAppChannel'
import { isWhatsAppChannelConnected } from 'common/core/selectors/channelSelectors'
import { Chat, PhonePreview, PhonePreviewTheme } from 'components/PhonePreviewKit'

export interface WhatsAppPreviewProps {
  iceBreakers: readonly MappedIceBreaker[]
}

export const WhatsAppPreview = ({ iceBreakers }: WhatsAppPreviewProps) => {
  const { channel: waChannel } = useWhatsAppChannel()

  if (!isWhatsAppChannelConnected(waChannel)) {
    return <div>{l.translate('WhatsApp channel is not connected')}</div>
  }

  const name = waChannel?.business_account_name
  const avatar = waChannel?.photo
  return (
    <PhonePreview.Root theme={PhonePreviewTheme.WHATSAPP}>
      <PhonePreview.Header title={name} avatarUrl={avatar || undefined} />
      <Chat.Root>
        <Chat.MessageList place="bottom">
          {iceBreakers
            .filter((iceBreaker) => iceBreaker.caption)
            .map((iceBreaker) => (
              <Chat.Button key={iceBreaker.id} label={iceBreaker.caption} />
            ))}
        </Chat.MessageList>
      </Chat.Root>
      <PhonePreview.MessageComposer />
    </PhonePreview.Root>
  )
}

import { l } from '@manychat/manyui'

import { ReasonStatuses } from 'common/oneTimeNotify/constants/notifyReasonConstants'
import { NotificationReason } from 'shared/api/requests/notificationReason/schemas'

export const getReasonTitleForDropdown = (reason: NotificationReason): string => {
  return reason.status === ReasonStatuses.ARCHIVE
    ? reason.caption + ` (${l.translate('Archived')})`
    : reason.caption
}

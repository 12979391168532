import React from 'react'
import { useAppSelector } from 'reduxTyped'
import { l } from '@manychat/manyui'

import { Chip } from 'apps/cms/components/Flows/components/FlowCard/components/Triggers/components/Chip/Chip'
import { useFlexibleTriggerNameClassName } from 'apps/cms/components/Flows/components/FlowCard/components/Triggers/hooks/useFlexibleTriggerNameClassName'
import { renderTextByNodes } from 'apps/cms/components/Flows/components/FlowCard/components/Triggers/lib/renderTextByNodes'
import { contentManagementSelectors } from 'apps/cms/store'
import { LiveCommentsWidget } from 'shared/api/requests/cms/schemas/widgets'

interface LiveCommentsTitleProps {
  widget: LiveCommentsWidget
}

export const LiveCommentsTitle = ({ widget }: LiveCommentsTitleProps) => {
  const hasSearchValue = useAppSelector(contentManagementSelectors.getHasSearchValue)

  const className = useFlexibleTriggerNameClassName()

  return (
    <span className={className}>
      {hasSearchValue && renderTextByNodes(`${widget.name} •`)}

      {renderTextByNodes(l.translate('User comments on your Live and comment contains'))}

      {widget.data.keywords.map((keyword, index) => (
        <Chip label={keyword} key={index} />
      ))}
    </span>
  )
}

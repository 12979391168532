import { api } from 'shared/api'

import { dashboardApiSchema } from './schemas'

export const dashboardApi = {
  fullStats: api.account.createGet({
    url: '/dashboard/fullStats',
    schemas: dashboardApiSchema.fullStats,
  }),
  subscribersGenderStats: api.account.createGet({
    url: '/dashboard/subscribersGenderStats',
    schemas: dashboardApiSchema.subscribersGenderStats,
  }),
  subscribersLanguageStats: api.account.createGet({
    url: '/dashboard/subscribersLanguageStats',
    schemas: dashboardApiSchema.subscribersLanguageStats,
  }),
  subscribersRecentList: api.account.createGet({
    url: '/dashboard/subscribersRecentList',
    schemas: dashboardApiSchema.subscribersRecentList,
  }),

  clearPagesMessagingPermError: api.account.createPost({
    url: '/dashboard/clearPagesMessagingPermError',
    schemas: dashboardApiSchema.clearPagesMessagingPermError,
  }),

  getEntitiesCount: api.account.createGet({
    url: '/ucms/statsAggregator/getEntitiesCount',
    schemas: dashboardApiSchema.getEntitiesCount,
  }),

  messageTagSending: api.account.createGet({
    url: '/dashboard/messageTagSending',
    schemas: dashboardApiSchema.messageTagSending,
  }),

  messageTagSendingFlows: api.account.createGet({
    url: '/dashboard/messageTagSendingFlows',
    schemas: dashboardApiSchema.messageTagSendingFlows,
  }),

  economicStats: api.account.createGet({
    url: '/dashboard/economicStats',
    schemas: dashboardApiSchema.economicStats,
  }),
}

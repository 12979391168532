import React from 'react'
import { l } from '@manychat/manyui'

import DefaultStepView from 'utils/services/newOnboardingService/view/DefaultStepView'
import ManyChatOnboardingView from 'utils/services/newOnboardingService/view/ManyChatOnboardingView'
import { PointerType } from 'utils/services/newOnboardingService/view/Pointer/pointerInterfaces'
import TooltipDefault from 'utils/services/newOnboardingService/view/Pointer/Tooltip/TooltipDefault'

import FirstLiveChatThreadTour from './FirstLiveChatThreadTour'

import cm from './FirstLiveChatThreadTourView.module.css'

const TOTAL_STEPS = 6

const FirstLiveChatThreadTourView = ({ onb: onboarding }: { onb: FirstLiveChatThreadTour }) => {
  return (
    <ManyChatOnboardingView
      onboarding={onboarding}
      stepsView={{
        STEP_1: ({ stepId }) => {
          return (
            <DefaultStepView
              onb={onboarding}
              stepId={stepId}
              pointerConfig={{
                type: PointerType.DOM,
                elementId: 'lc-empty-state-channels',
                padding: 8,
                tooltip: {
                  component: (
                    <TooltipDefault
                      text={l.makeTranslate(
                        'Let’s get you started with Live Chat. Pick a channel and send a message from your personal account to your business account.',
                      )}
                      className={cm.tooltip}
                    />
                  ),
                  at: 'left',
                },
              }}
              progressConfig={{
                current: 1,
                total: TOTAL_STEPS,
              }}
            />
          )
        },
        STEP_2: ({ stepId }) => (
          <DefaultStepView
            onb={onboarding}
            stepId={stepId}
            pointerConfig={{
              type: PointerType.DOM,
              elementId: 'threads-list',
              tooltip: {
                component: (
                  <TooltipDefault
                    className={cm.tooltip}
                    text={l.makeTranslate(
                      'All messages you get will show up here. Choose a conversation with your own account.',
                    )}
                  />
                ),
                at: 'right',
              },
            }}
            progressConfig={{
              current: 2,
              total: TOTAL_STEPS,
            }}
          />
        ),
        STEP_3: ({ stepId }) => (
          <DefaultStepView
            onb={onboarding}
            stepId={stepId}
            pointerConfig={{
              type: PointerType.DOM,
              elementId: 'live-chat-form',
              tooltip: {
                component: (
                  <TooltipDefault
                    className={cm.tooltip}
                    text={l.makeTranslate(
                      'Send a message to your own account. Check how it works.',
                    )}
                  />
                ),
                at: 'top',
              },
            }}
            progressConfig={{
              current: 3,
              total: TOTAL_STEPS,
            }}
          />
        ),
        STEP_4: ({ stepId, step }) => (
          <DefaultStepView
            onb={onboarding}
            stepId={stepId}
            pointerConfig={{
              type: PointerType.DOM,
              elementId: 'live-chat-thread-contents',
              tooltip: {
                component: (
                  <TooltipDefault
                    className={cm.tooltip}
                    onNextClick={() => step.process()}
                    btnNext={l.makeTranslate('Next')}
                    text={l.makeTranslate(
                      'Check your personal account and you will see that the message was sent. You are now ready to talk to your customers.',
                    )}
                  />
                ),
                at: 'left',
              },
            }}
            progressConfig={{
              current: 4,
              total: TOTAL_STEPS,
            }}
          />
        ),

        STEP_5: ({ stepId, step }) => (
          <DefaultStepView
            onb={onboarding}
            stepId={stepId}
            pointerConfig={{
              type: PointerType.DOM,
              elementId: 'pause-automation-section',
              tooltip: {
                component: (
                  <TooltipDefault
                    className={cm.tooltip}
                    onNextClick={() => step.process()}
                    btnNext={l.makeTranslate('Next')}
                    text={l.makeTranslate(
                      'You can choose if you want the automations to pause while you are talking with the customer.',
                    )}
                  />
                ),
                at: 'left',
              },
            }}
            progressConfig={{
              current: 5,
              total: TOTAL_STEPS,
            }}
          />
        ),
        STEP_6: ({ stepId, step }) => (
          <DefaultStepView
            onb={onboarding}
            stepId={stepId}
            pointerConfig={{
              type: PointerType.DOM,
              elementId: 'chat-toggle-thread-status-btn',
              tooltip: {
                component: (
                  <TooltipDefault
                    className={cm.tooltip}
                    onNextClick={() => step.process()}
                    btnNext={l.makeTranslate('Done')}
                    text={l.makeTranslate(
                      'When you finish with a thread, close it like you would check off a to-do.',
                    )}
                  />
                ),
                at: 'bottom',
              },
            }}
            progressConfig={{
              current: 6,
              total: TOTAL_STEPS,
            }}
          />
        ),
      }}
    />
  )
}

export default FirstLiveChatThreadTourView

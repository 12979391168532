import first from 'lodash/first'
import reduce from 'lodash/reduce'

import { VAR_REGEX } from 'constants/RegExs'

function getEncodedLength(stringArray) {
  return stringArray.reduce((result, symbol) => {
    result += new TextEncoder().encode(symbol).length
    return result
  }, 0)
}

//Magic for unicode
function countSymbols(string, encodeSymbols) {
  let stringArray
  if (Intl && Intl.Segmenter) {
    stringArray = [...new Intl.Segmenter().segment(string)].map((segment) => segment.segment)
  } else {
    stringArray = Array.from(string)
  }

  return encodeSymbols ? getEncodedLength(stringArray) : stringArray.length
}

/**
 * @typedef {Object} GetTextLengthOptions
 * @property {boolean | undefined} encodeSymbols – Flag to count each symbol by bytes
 *
 * @param {string} value
 * @param {GetTextLengthOptions} options
 * @returns {number}
 */
export function getTextLength(value = '', options = {}) {
  const defaults = {
    encodeSymbols: false,
  }

  const { encodeSymbols } = Object.assign({}, defaults, options)

  const fallbackRegExp = VAR_REGEX
  const match = value.match(fallbackRegExp)

  const fallbackLength = reduce(
    match,
    (memo, variable) => {
      const fallbackValueMatch = variable.match(/\"[^"]*\"/g)
      const fallbackValueLength = fallbackValueMatch
        ? first(fallbackValueMatch).replace(/\"/g, '').length
        : 0
      return fallbackValueLength + memo
    },
    0,
  )

  const valueWithoutVariables = value.replace(fallbackRegExp, '')
  const valueWithoutVariablesLength = countSymbols(valueWithoutVariables, encodeSymbols)

  return valueWithoutVariablesLength + fallbackLength
}

export function getVariablesLength(value) {
  if (!value || !value.length) {
    return 0
  }

  return (value.match(VAR_REGEX)?.length ?? 0) * 5
}

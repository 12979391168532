import { z } from 'zod'

export const ProfileScopedApiSchemas = {
  generate: {
    path: z.undefined(),
    query: z.undefined(),
    request: z.undefined(),
    response: z.object({
      token: z.string(),
    }),
  },
  delete: {
    path: z.undefined(),
    query: z.undefined(),
    request: z.undefined(),
    response: z.undefined(),
  },
}

import { appListenerMiddleware } from 'reduxTyped'
import { l } from '@manychat/manyui'

import { alert } from 'common/core'
import { addNotification } from 'common/core/actions/uiActions'
import { IAsyncThunkAction, IThunkAction } from 'common/core/interfaces/actions'
import { WebSocketEvents } from 'constants/WebSockets'
import { handleCatch } from 'shared/api/lib/errors/handlers'
import { applyHandlerByGuard, reportUnhandledErrors } from 'shared/api/lib/errors/utils'
import { settingsApi } from 'shared/api/requests/settings'
import { settingsApiGuard } from 'shared/api/requests/settings/guards'
import {
  type ConversionsApiChannel,
  ConversionsApiValue,
  PauseAutomationFormat,
} from 'shared/api/requests/settings/schemas'
import { createAsyncAction } from 'shared/lib/redux'
import { isRequestError } from 'utils/api/mcApi/RequestError'
import { anotherTabNotificationsListener } from 'utils/services/notificationsService'

import { ConversionsApiStatus } from '../interfaces'
import * as actionTypes from '../SettingsReduxActionTypes'

export const fetchSettings = (): IThunkAction => {
  return async (dispatch) => {
    dispatch({
      type: actionTypes.SETTINGS_CURRENT_FETCH_REQUEST,
    })

    try {
      const response = await settingsApi.fetchSettings()

      dispatch({
        type: actionTypes.SETTINGS_CURRENT_FETCH_RESPONSE,
        data: {
          ...response.data,
          item: response.data.settings,
        },
      })
    } catch (error) {
      handleCatch(error)

      dispatch({
        type: actionTypes.SETTINGS_CURRENT_FETCH_ERROR,
        error,
      })
    }
  }
}

const updateAutomationPauseTime = (
  newValue: number,
  format: PauseAutomationFormat,
): IAsyncThunkAction => {
  return async (dispatch) => {
    try {
      await settingsApi.changeAutomationPauseInitTime({
        body: { second_interval: newValue, format },
      })

      dispatch({
        type: actionTypes.SET_AUTOMATION_PAUSE_TIME,
        data: {
          pauseValue: newValue,
          pauseFormat: format,
        },
      })

      alert(l.translate('Pause duration updated'), 'success')
    } catch (error) {
      handleCatch(error)
    }
  }
}

const transferOwnership =
  (userId: number): IAsyncThunkAction =>
  async (dispatch) => {
    try {
      dispatch({
        type: actionTypes.TRANSFER_OWNERSHIP,
        userId,
      })
      await settingsApi.transferOwnership({ body: { user_id: userId } })
      const alertMessage = l.translate('Ownership has been transferred!')
      alert(alertMessage, 'success')
      window.location.reload()
    } catch (error) {
      handleCatch(error)
    }
  }

export const settingsActions = {
  updateAutomationPauseTime,
  transferOwnership,
}

// Whitelisting
export const getWhitelistingDomains = (): IAsyncThunkAction => {
  return async (dispatch) => {
    dispatch({
      type: actionTypes.SETTINGS_CURRENT_GETWHITELISTINGDOMAINS_REQUEST,
    })
    try {
      const response = await settingsApi.growthTools.getWhitelistingDomains()
      dispatch({
        type: actionTypes.SETTINGS_CURRENT_GETWHITELISTINGDOMAINS_RESPONSE,
        data: response.data,
      })
    } catch (error) {
      handleCatch(error)
    }
  }
}

export const addWhitelistingDomain = (url: string): IAsyncThunkAction => {
  return async (dispatch) => {
    dispatch({
      type: actionTypes.SETTINGS_CURRENT_ADDWHITELISTINGDOMAIN_REQUEST,
    })
    try {
      const response = await settingsApi.growthTools.addWhitelistingDomain({ body: { url } })
      dispatch({
        type: actionTypes.SETTINGS_CURRENT_ADDWHITELISTINGDOMAIN_RESPONSE,
        data: response.data,
      })
    } catch (error) {
      handleCatch(error, (responseError) => {
        const unhandledErrors = applyHandlerByGuard(
          responseError.$errors,
          settingsApiGuard.growthTools.isUrlInvalidError,
          (error) => alert(error.message, 'danger'),
        )

        reportUnhandledErrors(unhandledErrors, responseError.endpoint)
      })
      dispatch({
        type: actionTypes.SETTINGS_CURRENT_ADDWHITELISTINGDOMAIN_ERROR,
        error,
      })
    }
  }
}

export const deleteWhitelistingDomain = (url: string): IAsyncThunkAction => {
  return async (dispatch) => {
    dispatch({
      type: actionTypes.SETTINGS_CURRENT_DELETEWHITELISTINGDOMAIN_REQUEST,
    })
    try {
      const response = await settingsApi.growthTools.deleteWhitelistingDomain({ body: { url } })
      dispatch({
        type: actionTypes.SETTINGS_CURRENT_DELETEWHITELISTINGDOMAIN_RESPONSE,
        data: response.data,
      })
    } catch (error) {
      handleCatch(error)
      dispatch({
        type: actionTypes.SETTINGS_CURRENT_DELETEWHITELISTINGDOMAIN_ERROR,
      })
    }
  }
}

export const setConversionsApiByChannel = (
  channel: ConversionsApiChannel,
  conversionApi: Partial<ConversionsApiValue> | null,
) => {
  return {
    type: actionTypes.SET_CONVERSION_API_BY_CHANNEL,
    payload: {
      channel: channel,
      value: conversionApi,
    },
  }
}

export const connectStripe = createAsyncAction<string, void>('settings/connectStripe', async () => {
  const response = await settingsApi.payments.connectStripe()

  return response.data.redirect
})

appListenerMiddleware.startListening({
  matcher: connectStripe.fulfilled.match,
  effect: (action) => {
    window.location.href = action.payload
  },
})

export const disconnectStripe = createAsyncAction('settings/disconnectStripe', async () => {
  const response = await settingsApi.payments.disconnectStripe()

  return response.data
})

appListenerMiddleware.startListening({
  matcher: disconnectStripe.fulfilled.match,
  effect: () => {
    window.location.reload()
  },
})

export const setCurrency = createAsyncAction('settings/setCurrency', async (currency: string) => {
  const response = await settingsApi.payments.setCurrency({ body: { currency } })

  return response.data
})

export const setPaymentNotifySettings = createAsyncAction(
  'settings/setPaymentNotifySettings',
  async (types: string[]) => {
    const response = await settingsApi.payments.setPaymentNotifySettings({ body: { types } })

    return response.data
  },
)

export const setStripeChargeEmailNotification = createAsyncAction(
  'settings/setStripeChargeEmailNotification',
  async (notify: boolean) => {
    const response = await settingsApi.payments.setStripeChargeEmailNotification({
      body: { notify },
    })

    return response.data
  },
)

export const getHandoverReceivers = createAsyncAction(
  'settings/getHandoverReceivers',
  async () => {
    const response = await settingsApi.handover.getHandoverReceivers()

    return response.data
  },
  {
    onError: (error, _, { dispatch }) => {
      if (isRequestError(error)) {
        dispatch(addNotification(error?.response?.error, 'danger'))
      }
    },
  },
)

export const setLiveChatReceiver = createAsyncAction(
  'settings/setLiveChatReceiver',
  async (app_id: string) => {
    const response = await settingsApi.handover.setLiveChatReceiver({ body: { app_id } })

    return response.data
  },
)

interface CapiSettingsUpdateData {
  capi_integration_status: ConversionsApiStatus | null
  channel: ConversionsApiChannel
  is_automated_event_sending_enabled: boolean | null
  dataset_id: number | null
}

anotherTabNotificationsListener.on(
  WebSocketEvents.CAPI_SETTINGS_UPDATED,
  (data: CapiSettingsUpdateData, dispatch) => {
    const {
      channel,
      capi_integration_status: status,
      is_automated_event_sending_enabled,
      dataset_id,
    } = data

    const isDisconnected =
      status === null || is_automated_event_sending_enabled === null || dataset_id === null
    const value: ConversionsApiValue | null = isDisconnected
      ? null
      : {
          status,
          is_automated_event_sending_enabled,
          dataset_id,
        }

    dispatch(setConversionsApiByChannel(channel, value))
  },
)

import { createSlice } from '@reduxjs/toolkit'
import { createAppAsyncThunk } from 'reduxTyped'

import { TierData, TiersState } from 'common/billing/interfaces/tiers'
import { handleCatch } from 'shared/api/lib/errors/handlers'
import { billingFlexApi } from 'shared/api/requests/billingFlex'

import { getHasBillingAccess, getTiersData } from '../selectors/billingSelectors'

export const fetchTiers = createAppAsyncThunk<TierData[]>(
  'tiers/fetchTiers',
  async (__, thunkApi) => {
    try {
      const response = await billingFlexApi.getPlansTiers()

      return response.data.plans_tiers
    } catch (error) {
      handleCatch(error)

      return thunkApi.rejectWithValue()
    }
  },
  {
    condition: (__, thunkApi) => {
      const state = thunkApi.getState()
      const hasBillingAccess = getHasBillingAccess(state)
      if (!hasBillingAccess) {
        return false
      }

      // use data from cache
      const tiers = getTiersData(state)
      if (tiers.length) {
        return false
      }

      return true
    },
  },
)

const initialState: TiersState = {
  loading: false,
  error: false,
  tiers: [],
}

export const tiersSlice = createSlice({
  name: 'tiers',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchTiers.pending, (state) => {
        state.loading = true
        state.error = false
      })
      .addCase(fetchTiers.fulfilled, (state, action) => {
        state.loading = false

        state.tiers = [...action.payload].sort((tierA, tierB) => tierA.min - tierB.min)
      })
      .addCase(fetchTiers.rejected, (state) => {
        state.loading = false
        state.error = true
      })
  },
})

export const tiersActions = {
  ...tiersSlice.actions,
  fetchTiers,
}

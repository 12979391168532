import React from 'react'
import cx from 'classnames'

import { BaseBreadcrumbsSegment } from 'components/Breadcrumbs/lib/types'

import cm from './CustomizableBreadcrumbsButton.module.css'

export type CustomizableButtonSize = 'sm' | 'md' | 'lg'

export interface CustomizableBreadcrumbsButtonProps<CustomSegment>
  extends Omit<React.ComponentPropsWithoutRef<'button'>, 'onClick'> {
  segment: CustomSegment
  onClick: (event: React.MouseEvent<HTMLButtonElement>, segment: CustomSegment) => void
  size: CustomizableButtonSize
  highlight: boolean
  buttonRef?:
    | React.MutableRefObject<HTMLButtonElement | null>
    | React.RefCallback<HTMLButtonElement | null>
  children?: React.ReactNode
}

export const CustomizableBreadcrumbsButton = <CustomSegment extends BaseBreadcrumbsSegment>({
  size,
  segment,
  onClick,
  highlight,
  className,
  disabled,
  buttonRef,
  children,
  ...buttonProps
}: CustomizableBreadcrumbsButtonProps<CustomSegment>) => {
  const handleButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    onClick(event, segment)
  }

  return (
    <button
      className={cx(
        cm.breadcrumbsButton,
        'text-ellipsis',
        {
          [cm.breadcrumbsButton_size_sm]: size === 'sm',
          'text-heading-2': size === 'md',
          'text-heading-1': size === 'lg',
        },
        disabled && cm.breadcrumbsButton_nonInteractive,
        highlight && cm.breadcrumbsButton_highlight,
        className,
      )}
      disabled={disabled}
      onClick={handleButtonClick}
      ref={buttonRef}
      {...buttonProps}
    >
      {children ?? segment.label}
    </button>
  )
}

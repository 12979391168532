import React from 'react'
import { l, BtnV2, Modal, Icon } from '@manychat/manyui'

import { TargetChannelPicker } from 'apps/cms/components/Flows/components/AutomationModals/modals/ConvertFlowChannelsModal/components/TargetChannelPicker/TargetChannelPicker'
import { useConvertFlowChannels } from 'apps/cms/components/Flows/components/AutomationModals/modals/ConvertFlowChannelsModal/hooks/useConvertFlowChannels'
import { ChannelTypeLabel, ChannelIcon } from 'common/core/constants/ChannelType'

import { ImportantInformationBanner } from './components/ImportantInformationBanner/ImportantInformationBanner'

export const ConvertFlowChannelsModal = () => {
  const { flowUsedChannels, convertChannels, conversionMap, updateConversionMap, closeModal } =
    useConvertFlowChannels()

  return (
    <Modal
      open
      buttons={[
        <BtnV2 onClick={closeModal} className="m-y-xs">
          {l.translate('Cancel')}
        </BtnV2>,
        <BtnV2
          onClick={convertChannels}
          label={l.translate('Duplicate and convert')}
          variant="primary"
          className="m-y-xs text-defaultcase"
          disabled={Object.values(conversionMap).length === 0}
        />,
      ]}
      width={540}
      title={<div className="p-y-xs">{l.translate('Convert content to another channel')}</div>}
      onRequestClose={closeModal}
    >
      <div className="p-a m-y-xs">
        <ImportantInformationBanner />

        <div className="m-t b-a rounded">
          <div className="d-flex p-a text-disabled">
            <div className="col-4 text-sm">{l.translate('Channels in Automation')}</div>
            <div className="col-4" />
            <div className="col-4 text-sm">{l.translate('Target channels')}</div>
          </div>

          {flowUsedChannels.map((channel) => {
            const FlowChannelIcon = ChannelIcon[channel]

            return (
              <div className="b-t d-flex p-a" key={channel}>
                <div className="col-4 d-flex align-center">
                  <FlowChannelIcon className="m-r-xs" size={16} />

                  {ChannelTypeLabel[channel]}
                </div>

                <div className="col-4 d-flex align-center justify-center">
                  <Icon.OrphanLongArrow />
                </div>

                <div className="col-4 d-flex align-center">
                  <TargetChannelPicker
                    sourceChannel={channel}
                    targetChannel={conversionMap[channel]}
                    onSelect={updateConversionMap}
                  />
                </div>
              </div>
            )
          })}
        </div>
      </div>
    </Modal>
  )
}

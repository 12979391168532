import { l, Icon } from '@manychat/manyui'

import { ExternalTriggerType } from 'apps/externalTriggers/externalTriggerConstants'
import { WidgetTypes } from 'apps/growthTools/models/Widget/constants'
import { TriggerEventType } from 'apps/triggerRules/models/TriggerRule/constants'
import { ChannelType } from 'common/core/constants/ChannelType'
import { TriggerType } from 'common/flow/constants/TriggerType'

export enum OtherTriggerGroups {
  CONTACT_EVENTS = 'contactEvents',
  HOTMART = 'hotmart',
  TIKTOK_ADS = 'tiktok_ads',
}

export const TriggerGroup = {
  ...OtherTriggerGroups,
  ...ChannelType,
}
export interface TriggerConfigType {
  title: string
  description: string
  icon: Icon.Icon
}

export const TriggerChannelIconDictionary: Record<ChannelType, Icon.Icon> = {
  [ChannelType.EMAIL]: Icon.EmailChannel,
  [ChannelType.FB]: Icon.MessengerChannel,
  [ChannelType.GUEST]: Icon.OrphanIntegration,
  [ChannelType.INSTAGRAM]: Icon.InstagramChannel,
  [ChannelType.SMS]: Icon.SmsChannel,
  [ChannelType.TELEGRAM]: Icon.TelegramChannel,
  [ChannelType.WHATSAPP]: Icon.WhatsAppChannel,
  [ChannelType.TIKTOK]: Icon.TikTokChannel,
}

const HotmartIcon = Icon.Hotmart
const TikTokAdsIcon = Icon.TikTok
const EventIcon = Icon.Bot

const FbKeyword = {
  title: l.makeTranslate('Messenger Message'),
  description: l.makeTranslate('User sends a message'),
  icon: TriggerChannelIconDictionary[ChannelType.FB],
}

const IgKeyword = {
  title: l.makeTranslate('Instagram Message'),
  description: l.makeTranslate('User sends a message'),
  icon: TriggerChannelIconDictionary[ChannelType.INSTAGRAM],
}

const WaKeyword = {
  title: l.makeTranslate('WhatsApp Message'),
  description: l.makeTranslate('User sends a message'),
  icon: TriggerChannelIconDictionary[ChannelType.WHATSAPP],
}

const TiktokKeyword = {
  title: l.makeTranslate('TikTok Message'),
  description: l.makeTranslate('User sends a message'),
  icon: TriggerChannelIconDictionary[ChannelType.TIKTOK],
}

const TelegramKeyword = {
  title: l.makeTranslate('Telegram Message'),
  description: l.makeTranslate('User sends a message'),
  icon: TriggerChannelIconDictionary[ChannelType.TELEGRAM],
}

export const TriggersDictionary = {
  [TriggerGroup.INSTAGRAM]: {
    [TriggerType.KEYWORD]: IgKeyword,
    [TriggerType.AI_INTENT]: IgKeyword,
    [TriggerType.META_KEYWORD]: IgKeyword,
    [WidgetTypes.ADS]: {
      title: l.makeTranslate('Instagram Ads'),
      description: l.makeTranslate('User clicks an Instagram Ad'),
      icon: TriggerChannelIconDictionary[ChannelType.INSTAGRAM],
    },
    [WidgetTypes.FEED_COMMENT_TRIGGER]: {
      title: l.makeTranslate('Post or Reel Comments'),
      description: l.makeTranslate('User comments on your Post or Reel'),
      icon: TriggerChannelIconDictionary[ChannelType.INSTAGRAM],
    },
    [WidgetTypes.INSTAGRAM_STORY_REPLY]: {
      title: l.makeTranslate('Story Reply'),
      description: l.makeTranslate('User replies to your Story'),
      icon: TriggerChannelIconDictionary[ChannelType.INSTAGRAM],
    },
    [WidgetTypes.INSTAGRAM_LIVE_COMMENT_REPLY]: {
      title: l.makeTranslate('Live Comments'),
      description: l.makeTranslate('User comments on your Live'),
      icon: TriggerChannelIconDictionary[ChannelType.INSTAGRAM],
    },
    [WidgetTypes.MESSENGER_REF_URL]: {
      title: l.makeTranslate('Instagram Ref URL'),
      description: l.makeTranslate('User clicks a referral link'),
      icon: TriggerChannelIconDictionary[ChannelType.INSTAGRAM],
    },
    [TriggerType.STORY_MENTION_REPLY]: {
      title: l.makeTranslate('Story mention reply'),
      description: l.makeTranslate('User mentions you in their Story'),
      icon: TriggerChannelIconDictionary[ChannelType.INSTAGRAM],
    },
    [TriggerType.DEFAULT_REPLY]: {
      title: l.makeTranslate('Default reply'),
      description: l.makeTranslate('User sends a Direct Message'),
      icon: TriggerChannelIconDictionary[ChannelType.INSTAGRAM],
    },
    [TriggerType.ICE_BREAKER]: {
      title: l.makeTranslate('Conversation Starters'),
      description: l.makeTranslate('User clicks a Conversation Starters item'),
      icon: TriggerChannelIconDictionary[ChannelType.INSTAGRAM],
    },
    [TriggerType.MAIN_MENU]: {
      title: l.makeTranslate('Main Menu'),
      description: l.makeTranslate('User clicks a Main Menu item'),
      icon: TriggerChannelIconDictionary[ChannelType.INSTAGRAM],
    },
  },

  [TriggerGroup.FB]: {
    [TriggerType.KEYWORD]: FbKeyword,
    [TriggerType.AI_INTENT]: FbKeyword,
    [TriggerType.META_KEYWORD]: FbKeyword,
    [WidgetTypes.FEED_COMMENT]: {
      title: l.makeTranslate('Facebook Comments'),
      description: l.makeTranslate('User comments on your Post'),
      icon: TriggerChannelIconDictionary[ChannelType.FB],
    },
    [WidgetTypes.FEED_COMMENT_TRIGGER]: {
      title: l.makeTranslate('Facebook Comments'),
      description: l.makeTranslate('User comments on your Post'),
      icon: TriggerChannelIconDictionary[ChannelType.FB],
    },
    [WidgetTypes.MESSENGER_REF_URL]: {
      title: l.makeTranslate('Messenger Ref URL'),
      description: l.makeTranslate('User clicks a referral link'),
      icon: TriggerChannelIconDictionary[ChannelType.FB],
    },
    [WidgetTypes.MESSENGER_CODE]: {
      title: l.makeTranslate('QR Code'),
      description: l.makeTranslate('User scans a QR code'),
      icon: TriggerChannelIconDictionary[ChannelType.FB],
    },
    [WidgetTypes.FACEBOOK_SHOP]: {
      title: l.makeTranslate('Facebook Shop Message'),
      description: l.makeTranslate('User messages from your Facebook Shop'),
      icon: TriggerChannelIconDictionary[ChannelType.FB],
    },
    [WidgetTypes.ADS_JSON]: {
      title: l.makeTranslate('Facebook Ads JSON'),
      description: l.makeTranslate('User clicks a Facebook Ad'),
      icon: TriggerChannelIconDictionary[ChannelType.FB],
    },
    [WidgetTypes.ADS]: {
      title: l.makeTranslate('Facebook Ads'),
      description: l.makeTranslate('User clicks a Facebook Ad'),
      icon: TriggerChannelIconDictionary[ChannelType.FB],
    },

    [TriggerType.WELCOME_MESSAGE]: {
      title: l.makeTranslate('Welcome Message'),
      description: l.makeTranslate('User subscribes by clicking the Start button in Messenger'),
      icon: TriggerChannelIconDictionary[ChannelType.FB],
    },
    [TriggerType.DEFAULT_REPLY]: {
      title: l.makeTranslate('Default Reply'),
      description: l.makeTranslate('User sends a Direct Message'),
      icon: TriggerChannelIconDictionary[ChannelType.FB],
    },
    [TriggerType.ICE_BREAKER]: {
      title: l.makeTranslate('Conversation Starters'),
      description: l.makeTranslate('User clicks a Conversation Starters item'),
      icon: TriggerChannelIconDictionary[ChannelType.FB],
    },
    [TriggerType.MAIN_MENU]: {
      title: l.makeTranslate('Main Menu'),
      description: l.makeTranslate('User clicks a Main Menu item'),
      icon: TriggerChannelIconDictionary[ChannelType.FB],
    },
  },

  [TriggerGroup.WHATSAPP]: {
    [TriggerType.KEYWORD]: WaKeyword,
    [TriggerType.AI_INTENT]: WaKeyword,
    [TriggerType.META_KEYWORD]: WaKeyword,
    [WidgetTypes.MESSENGER_REF_URL]: {
      title: l.makeTranslate('WhatsApp URL'),
      description: l.makeTranslate('User clicks a link'),
      icon: TriggerChannelIconDictionary[ChannelType.WHATSAPP],
    },
    [TriggerType.DEFAULT_REPLY]: {
      title: l.makeTranslate('Default Reply'),
      description: l.makeTranslate('User sends a message'),
      icon: TriggerChannelIconDictionary[ChannelType.WHATSAPP],
    },
    [TriggerType.CART_REPLY]: {
      title: l.translate('Cart Reply'),
      description: l.translate('User sends a cart with items'),
      icon: TriggerChannelIconDictionary[ChannelType.WHATSAPP],
    },
    [TriggerType.WELCOME_MESSAGE]: {
      title: l.makeTranslate('Welcome Message'),
      description: l.makeTranslate('User opens a chat with you for the first time'),
      icon: TriggerChannelIconDictionary[ChannelType.WHATSAPP],
    },
    [TriggerType.ICE_BREAKER]: {
      title: l.makeTranslate('Ice Breakers'),
      description: l.makeTranslate('User clicks an Ice Breakers item'),
      icon: TriggerChannelIconDictionary[ChannelType.WHATSAPP],
    },
    [WidgetTypes.CTWA_TRIGGER]: {
      title: l.makeTranslate('Click-to-WhatsApp Ad'),
      description: l.makeTranslate('User clicks a CTWA Ad'),
      icon: TriggerChannelIconDictionary[ChannelType.WHATSAPP],
    },
  },

  [TriggerGroup.TELEGRAM]: {
    [TriggerType.META_KEYWORD]: TelegramKeyword,
    [TriggerType.KEYWORD]: TelegramKeyword,
    [TriggerType.AI_INTENT]: TelegramKeyword,
    [WidgetTypes.MESSENGER_REF_URL]: {
      title: l.makeTranslate('Telegram Ref URL'),
      description: l.makeTranslate('User clicks a referral link'),
      icon: TriggerChannelIconDictionary[ChannelType.TELEGRAM],
    },
    [TriggerType.WELCOME_MESSAGE]: {
      title: l.makeTranslate('Welcome Message'),
      description: l.makeTranslate('User subscribes by clicking the Subscribe button'),
      icon: TriggerChannelIconDictionary[ChannelType.TELEGRAM],
    },
    [TriggerType.DEFAULT_REPLY]: {
      title: l.makeTranslate('Default Reply'),
      description: l.makeTranslate('User sends a message'),
      icon: TriggerChannelIconDictionary[ChannelType.TELEGRAM],
    },
  },

  [TriggerGroup.TIKTOK]: {
    [TriggerType.META_KEYWORD]: TiktokKeyword,
    [TriggerType.KEYWORD]: TiktokKeyword,
    [TriggerType.AI_INTENT]: TiktokKeyword,
    [WidgetTypes.MESSENGER_REF_URL]: {
      title: l.makeTranslate('TikTok Ref URL'),
      description: l.makeTranslate('User clicks a referral link'),
      icon: TriggerChannelIconDictionary[ChannelType.TIKTOK],
    },
    [TriggerType.WELCOME_MESSAGE]: {
      title: l.makeTranslate('Welcome Message'),
      description: l.makeTranslate('User subscribes by clicking the Subscribe button'),
      icon: TriggerChannelIconDictionary[ChannelType.TIKTOK],
    },
    [TriggerType.DEFAULT_REPLY]: {
      title: l.makeTranslate('Default Reply'),
      description: l.makeTranslate('User sends a message'),
      icon: TriggerChannelIconDictionary[ChannelType.TIKTOK],
    },
  },

  [TriggerGroup.SMS]: {
    [TriggerType.KEYWORD]: {
      title: l.makeTranslate('SMS Keyword'),
      description: l.makeTranslate('Contact sends a message with a Keyword'),
      icon: TriggerChannelIconDictionary[ChannelType.SMS],
    },
    [TriggerType.AI_INTENT]: {
      title: l.makeTranslate('SMS DM Intention'),
      description: l.makeTranslate('User sends a message with an Intention'),
      icon: TriggerChannelIconDictionary[ChannelType.SMS],
    },
  },

  [TriggerGroup.GUEST]: {},

  [TriggerGroup.CONTACT_EVENTS]: {
    [TriggerEventType.BOT_SUBSCRIBED]: {
      title: l.makeTranslate('Contact Event'),
      description: l.makeTranslate('New contact created'),
      icon: EventIcon,
    },
    [TriggerEventType.CUF_CHANGED]: {
      title: l.makeTranslate('Contact Event'),
      description: l.makeTranslate('Custom field value is changed'),
      icon: EventIcon,
    },
    [TriggerEventType.SUF_CHANGED]: {
      title: l.makeTranslate('Contact Event'),
      description: l.makeTranslate('System field value is changed'),
      icon: EventIcon,
    },
    [TriggerEventType.TAG_ADDED]: {
      title: l.makeTranslate('Contact Event'),
      description: l.makeTranslate('Tag is applied to a contact'),
      icon: EventIcon,
    },
    [TriggerEventType.TAG_REMOVED]: {
      title: l.makeTranslate('Contact Event'),
      description: l.makeTranslate('Tag is removed from a contact'),
      icon: EventIcon,
    },
    [TriggerEventType.SEQUENCE_SUBSCRIBED]: {
      title: l.makeTranslate('Contact Event'),
      description: l.makeTranslate('Contact subscribed to a sequence'),
      icon: EventIcon,
    },
    [TriggerEventType.SEQUENCE_UNSUBSCRIBED]: {
      title: l.makeTranslate('Contact Event'),
      description: l.makeTranslate('Contact unsubscribed from a sequence'),
      icon: EventIcon,
    },
    [TriggerEventType.DATE_TIME]: {
      title: l.makeTranslate('Contact Event'),
      description: l.makeTranslate('Date/Time occurred'),
      icon: EventIcon,
    },
  },

  [TriggerGroup.HOTMART]: {
    [ExternalTriggerType.HOTMART_ABANDONED_CART]: {
      title: l.makeTranslate('Hotmart Event'),
      description: l.makeTranslate('Customer abandons their cart'),
      icon: HotmartIcon,
    },
    [ExternalTriggerType.HOTMART_POST_PURCHASE_CHANGE_STATUS]: {
      title: l.makeTranslate('Hotmart Event'),
      description: l.makeTranslate('Purchase status changes'),
      icon: HotmartIcon,
    },
  },

  [TriggerGroup.TIKTOK_ADS]: {
    [ExternalTriggerType.TIKTOK_ADS_CTX]: {
      title: l.makeTranslate('TikTok Ads'),
      description: l.makeTranslate('User clicks to your TikTok ad'),
      icon: TikTokAdsIcon,
    },
  },
}

export const TriggersDictionaryFlowBuilder = {
  [ExternalTriggerType.TIKTOK_ADS_CTX]: {
    title: l.makeTranslate('User replies on your TikTok Ad'),
  },
  [TriggerType.RULE]: {
    title: l.makeTranslate('Contact event occurs'),
    description: l.makeTranslate('Contact event occurs'),
    icon: EventIcon,
  },
  [TriggerType.ANOTHER_FLOW]: {
    title: l.makeTranslate('Another flow'),
    description: l.makeTranslate('Another flow'),
    icon: Icon.GoToFlow,
  },
}

import { createAction } from '@reduxjs/toolkit'
import { createSelector } from 'reselect'

import { ViewDisplayMode } from 'apps/cms/lib/constants'
import { namespace } from 'apps/cms/store/lib/constants'
import { ContentManagementSystemState } from 'apps/cms/store/lib/types'

const setViewDisplayMode = createAction<ViewDisplayMode>(`${namespace}/setViewDisplayMode`)

const setLastSelectedFlowId = createAction<string | null>(`${namespace}/setLastSelectedFlowId`)

const setIsFlowCreationStarted = createAction<boolean>(`${namespace}/setIsFlowCreationStarted`)

const toggleFlowSelection = createAction<string>(`${namespace}/toggleFlowSelection`)

const initUi = createAction<Partial<ContentManagementSystemState['ui']>>(`${namespace}/initUi`)

const resetUi = createAction(`${namespace}/resetUi`)

const toggleFlowsSelection = createAction(`${namespace}/toggleFlowsSelection`)

const selectFlowsInRange = createAction<string>(`${namespace}/selectFlowsInRange`)

export const cmsUiActions = {
  setViewDisplayMode,
  setLastSelectedFlowId,
  setIsFlowCreationStarted,
  toggleFlowSelection,
  initUi,
  resetUi,
  toggleFlowsSelection,
  selectFlowsInRange,
}

const getState = (state: RootState) => state.contentManagementSystem.ui

export const cmsUiSelectors = {
  getUi: getState,

  getViewDisplayMode: (state: RootState) => getState(state).viewDisplayMode,

  getSelectedFlowIds: (state: RootState) => getState(state).selectedFlowIds,

  getLastSelectedFlowId: (state: RootState) => getState(state).lastSelectedFlowId,

  getIsFlowCreationStarted: (state: RootState) => getState(state).isFlowCreationStarted,

  getIsOutlinedNewAutomationButton: (state: RootState) =>
    getState(state).isOutlinedNewAutomationButton,

  getIsRecommendationApplied: (state: RootState) => getState(state).isRecommendationApplied,

  getSelectedFlow: createSelector(
    [
      (state: RootState) => state.contentManagementSystem.flows.list,
      (state: RootState) => getState(state).selectedFlowIds,
    ],
    (flows, selectedFlowIds) => {
      return flows.filter((flow) => selectedFlowIds.includes(flow.ns))
    },
  ),

  getIsFlowSelected: createSelector(
    [(state: RootState, flowId: string) => getState(state).selectedFlowIds.includes(flowId)],
    (isFlowSelected) => isFlowSelected,
  ),

  getIsAllFlowsSelected: createSelector(
    [
      (state: RootState) => state.contentManagementSystem.flows.list.length,
      (state: RootState) => getState(state).selectedFlowIds.length,
      (state: RootState) => Boolean(state.contentManagementSystem.flows.pinned_flow),
    ],
    (flowsCount, selectedFlowsCount, hasPinnedFlow) => {
      const totalFlowsCount = hasPinnedFlow ? flowsCount + 1 : flowsCount
      return totalFlowsCount > 0 && totalFlowsCount === selectedFlowsCount
    },
  ),
}

import { l } from '@manychat/manyui'

export const SECONDS_IN_MINUTE = 60
export const SECONDS_IN_HOUR = SECONDS_IN_MINUTE * 60
export const SECONDS_IN_DAY = SECONDS_IN_HOUR * 24
export const DEFAULT_MAX_VALUE = 99999
export const DEFAULT_MIN_VALUE = 0

export enum Unit {
  IMMEDIATELY = 'immediately',
  MINUTES = 'minutes',
  HOURS = 'hours',
  DAYS = 'days',
}

export const UNIT_OPTIONS = [
  { value: Unit.IMMEDIATELY, label: l.makeTranslate('Immediately') },
  { value: Unit.MINUTES, label: l.makeTranslate('Minutes') },
  { value: Unit.HOURS, label: l.makeTranslate('Hours') },
  { value: Unit.DAYS, label: l.makeTranslate('Days') },
]

import { ReplaceResult } from 'components/MCTextEditor/dataFormat/parse/mapReduceParser/types'
import { SliceType } from 'components/MCTextEditor/slice/types'
import { INDEX_CHAR } from 'components/RichTextEditor/constants'

import { encloseWithMarks } from '../dataFormat/parse/utils/marks'
import { ReplaceRule } from '../dataFormat/parse/utils/replace'
import { IButtonSelector, ILabelSelector } from '../MCEditorContextInterfaces'

const PREFIX = '<<inline-button-anchor:'
const POSTFIX = '>>'
export const SmartLinkPattern = `${PREFIX}[^\n<>]{1,}${POSTFIX}`

export function parseSmartLinkString(input: string): string | null {
  if (!input.startsWith(PREFIX) || !input.endsWith(POSTFIX)) {
    return null
  }

  const id = input.slice(PREFIX.length, -POSTFIX.length)
  return id.length > 0 ? id : null
}

export function exportSmartLink(buttonId: string) {
  return `${PREFIX}${buttonId}${POSTFIX}`
}

export const makeSmartLinkReplacer =
  (getLabelById: ILabelSelector, getButtonById: IButtonSelector): ReplaceRule<ReplaceResult> =>
  (input) => {
    const buttonId = parseSmartLinkString(input)
    if (!buttonId) {
      return undefined
    }
    if (!getButtonById(buttonId)) {
      return undefined
    }
    const label = `${INDEX_CHAR}${getLabelById(buttonId)}${INDEX_CHAR}`
    return encloseWithMarks(label, SliceType.SMART_LINK, { buttonId })
  }

export const containsSmartLinks = (text: string) => {
  return text.includes(PREFIX)
}

import React, { Component } from 'react'

interface StripeErrorBoundaryProps {
  children: React.ReactNode
  onError: (error: unknown) => void
}

interface StripeErrorBoundaryState {
  error?: unknown
}

export class StripeErrorBoundary extends Component<
  StripeErrorBoundaryProps,
  StripeErrorBoundaryState
> {
  state: StripeErrorBoundaryState = { error: undefined }

  static getDerivedStateFromError(error: unknown): StripeErrorBoundaryState {
    return { error }
  }

  componentDidCatch(error: unknown) {
    const { onError } = this.props
    onError(error)
  }

  render() {
    const { error } = this.state
    const { children } = this.props

    return error ? null : children
  }
}

import { createAppAsyncThunk } from 'reduxTyped'

import { cmsFlowsActions } from 'apps/cms/store/flows'
import { cmsFoldersActions } from 'apps/cms/store/folders'
import { namespace } from 'apps/cms/store/lib/constants'
import { handleCatch } from 'shared/api/lib/errors/handlers'

const createFolderWithContent = createAppAsyncThunk(
  `${namespace}/createFolderWithContent`,
  async (
    {
      folderTitle,
      folderPath,
      contentIds,
    }: { folderTitle: string; folderPath: string; contentIds: string[] },
    { dispatch },
  ) => {
    try {
      const response = await dispatch(
        cmsFoldersActions.createFolder({ path: folderPath, title: folderTitle }),
      ).unwrap()

      if (!response) {
        return
      }

      if (contentIds.length === 1) {
        await dispatch(
          cmsFlowsActions.moveFlow({
            path: contentIds[0],
            pathToMove: response.path,
          }),
        )
      } else {
        await dispatch(cmsFlowsActions.bulkMoveTo({ pathMoveTo: response.path }))
      }
    } catch (error) {
      handleCatch(error)
    }
  },
)

export const cmsCombinedActions = {
  createFolderWithContent,
}

import dot from 'dot-prop-immutable'

import * as atypes from 'apps/templates/constants/ReduxActionTypes'
import { makeLimitedListReducer } from 'utils/factory'

const listReducer = makeLimitedListReducer('templates')

export function templatesReducer(state, action) {
  state = listReducer(state, action)

  switch (action.type) {
    case atypes.TEMPLATES_NOTIFIACTIONS_UPDATE: {
      const { template_id, current_version, image, title } = action.data

      const currentTemplate = state.byId[template_id]
      if (currentTemplate) {
        state = dot.merge(state, 'byId', {
          ...state.byId,
          [template_id]: {
            ...currentTemplate,
            image,
            title,
            current_version,
          },
        })

        const updatedItems = state.items.map((item) => {
          if (item.template_id === template_id) {
            return {
              ...currentTemplate,
              image,
              title,
              current_version,
            }
          } else {
            return item
          }
        })

        state.items = updatedItems
      }
      break
    }
  }

  return state
}

const installedlistReducer = makeLimitedListReducer('installedTemplates')

export function installedTemplatesReducer(state, action) {
  state = installedlistReducer(state, action)

  return state
}

import { l } from '@manychat/manyui'

import { alert } from 'common/core'
import { IAsyncThunkActionWithReturnValue, IThunkAction } from 'common/core/interfaces/actions'
import { CreateFolderResponse } from 'common/core/interfaces/folder'
import { getIsFolderNameExists } from 'common/fields/selectors/globalFieldFoldersSelectors'
import { GlobalFieldsActions } from 'common/fields/store/globalFields'
import * as API from 'constants/API'
import { makeLimitedListActions } from 'utils/factory'
import { anotherTabNotificationsListener } from 'utils/services/notificationsService'

const FOLDER_ENTITY_CUFS = 30
const ROOT_PATH = '/'

const baseListActions = makeLimitedListActions('globalFieldFolders')

export const globalFieldFoldersCreatedNotification = baseListActions.createdNotification
export const globalFieldFoldersUpdatedNotification = baseListActions.updatedNotification
export const globalFieldFoldersDeletedNotification = baseListActions.deletedNotification

export const fetchGlobalFieldFolders = () =>
  baseListActions.fetch({}, { entity: FOLDER_ENTITY_CUFS, path: ROOT_PATH })

export const createGlobalFieldFolder =
  (title: string): IThunkAction =>
  async (dispatch, getState) => {
    const isFolderNameExists = getIsFolderNameExists(getState(), title)

    if (isFolderNameExists) {
      alert(l.translate('Folder with this name already exists'), 'danger')
      return
    }

    const response = await dispatch(
      baseListActions.create({ title, entity: FOLDER_ENTITY_CUFS, path: ROOT_PATH }),
    )
    alert(l.translate('Folder created'), 'success')

    return response
  }

export const createFolderAndMoveFields = (title: string, fieldIds: number[]): IThunkAction => {
  return async (dispatch, getState) => {
    const isFolderNameExists = getIsFolderNameExists(getState(), title)

    if (isFolderNameExists) {
      alert(l.translate('Folder with this name already exists'), 'danger')
      return
    }

    const { folder } = (await dispatch(
      createGlobalFieldFolder(title),
    )) as unknown as CreateFolderResponse
    await dispatch(GlobalFieldsActions.bulkMoveFx({ fieldIds, folderId: folder.folder_id }))
  }
}

export const removeGlobalFieldFolder =
  (
    folderId: number,
    options: {
      path: string
      delete_content: boolean
    },
  ): IThunkAction =>
  async (dispatch) => {
    await dispatch(baseListActions.delete(folderId, options))
    alert(l.translate('Folder deleted!'), 'success')
    dispatch(
      GlobalFieldsActions.getListFx({
        payload: {
          query: {
            active_only: false,
            path: '/',
          },
        },
        effectConfig: { forceRequest: true },
      }),
    )
  }

export const updateGlobalFieldFolderName =
  (folderId: number, path: string, title: string): IAsyncThunkActionWithReturnValue<boolean> =>
  async (dispatch, getState) => {
    const isFolderNameExists = getIsFolderNameExists(getState(), title)

    if (isFolderNameExists) {
      alert(l.translate('Folder with this name already exists'), 'danger')
      return false
    }

    await dispatch({
      type: 'GLOBAL_FIELD_FOLDERS_UPDATE_NAME',
      $fetch: [
        API.globalFieldFolders.endpoints.rename[0],
        {
          method: 'POST',
          body: JSON.stringify({ path, title }),
          headers: { 'Content-Type': 'application/json' },
        },
      ],
    })
    dispatch(baseListActions.update(folderId, { title }))
    alert(l.translate('Folder renamed!'), 'success')
    return true
  }

anotherTabNotificationsListener.on('folder_created', (data, dispatch) => {
  if (data.folder.entity === FOLDER_ENTITY_CUFS) {
    dispatch(globalFieldFoldersCreatedNotification(data.folder))
  }
})

anotherTabNotificationsListener.on('folder_renamed', (data, dispatch) => {
  if (data.folder.entity === FOLDER_ENTITY_CUFS) {
    dispatch(globalFieldFoldersUpdatedNotification(data.folder))
  }
})

anotherTabNotificationsListener.on('folder_deleted', (data, dispatch) => {
  if (data.folder) {
    if (data.folder.entity === FOLDER_ENTITY_CUFS) {
      dispatch(globalFieldFoldersDeletedNotification(data.folder.folder_id))
    }
  }
})

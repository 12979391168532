import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import get from 'lodash/get'
import { appListenerMiddleware, createAppAsyncThunk } from 'reduxTyped'

import { localStorage } from 'utils'
import { AiWelcomeMessageAnalytics } from 'apps/aiCommunications/components/AiWelcomeMessage/lib/analytics'
import { WelcomeMessageFlagName } from 'apps/aiCommunications/constants'
import { AiCommunicationsState, WelcomeMessageSource } from 'apps/aiCommunications/store/types'
import { upgrade } from 'common/billing/actions/upgradeActions'
import { NodeType } from 'common/builder'
import * as entitySelectors from 'common/builder/selectors/builder/entitySelectors'
import * as managerSelectors from 'common/builder/selectors/managerSelectors'
import { Ability } from 'common/core/constants/Ability'
import { getHasAbility } from 'common/core/selectors/abilitiesSelectors'
import { handleError } from 'shared/api/lib/errors/handlers'
import { AccountApi } from 'shared/api/requests/account'

export const AiCommunicationsInitialState: AiCommunicationsState = {
  triggeredBySetLive: false,
  triggeredByPurchase: false,
  isWelcomeMessageVisible: false,
  welcomeMessageSource: WelcomeMessageSource.BILLING,
}

const NAMESPACE = 'aiCommunications'

const { reducer, actions } = createSlice({
  initialState: AiCommunicationsInitialState,
  name: NAMESPACE,
  reducers: {
    hideWelcomeMessage: (state) => {
      state.isWelcomeMessageVisible = false
    },
    showWelcomeMessage: (state, action: PayloadAction<WelcomeMessageSource>) => {
      state.isWelcomeMessageVisible = true
      state.welcomeMessageSource = action.payload
    },
    setTriggeredBySetLive: (state, action: PayloadAction<boolean>) => {
      state.triggeredBySetLive = action.payload
    },
    setTriggeredByPurchase: (state, action: PayloadAction<boolean>) => {
      state.triggeredByPurchase = action.payload
    },
  },
})

const tryOpenFirstAiAutomationCreationModal = createAppAsyncThunk(
  `${NAMESPACE}/tryOpenFirstAIAutomationCreationModal`,
  async (
    {
      managerId,
      accountId,
      checkNodes = false,
      source,
    }: {
      managerId?: string
      accountId: string
      source: WelcomeMessageSource
      checkNodes?: boolean
    },
    { dispatch, getState },
  ) => {
    const flowsMap = localStorage.getItem('account_has_ai_flow')
    const flows = flowsMap ? JSON.parse(flowsMap) : {}
    const accountHasFlows = flows[accountId]

    if (accountHasFlows) {
      dispatch(AiCommunicationsActions.hideWelcomeMessage())
      return false
    }

    if (checkNodes) {
      const state = getState()
      const containsNodes = AiCommunicationsSelectors.isFlowContainsAiNodes(state, managerId)

      if (!containsNodes) {
        return false
      }
    }

    try {
      const response = await AccountApi.getDisplayFlag({
        query: { display_flag: WelcomeMessageFlagName },
      })
      const flagValue = response.data[WelcomeMessageFlagName]

      if (flagValue) {
        return false
      }

      await AccountApi.setDisplayFlag({
        body: {
          display_flag: WelcomeMessageFlagName,
          value: true,
        },
      })
      flows[accountId] = 'true'
      localStorage.setItem('account_has_ai_flow', JSON.stringify(flows))
      dispatch(AiCommunicationsActions.showWelcomeMessage(source))
      return true
    } catch (error) {
      handleError(error)
      return false
    }
  },
)

const tryOpenWelcomeMessageModal = createAppAsyncThunk(
  `${NAMESPACE}/tryOpenWelcomeMessageModal`,
  (force: boolean, { dispatch, getState }) => {
    const state = getState()
    if (force || AiCommunicationsSelectors.getTriggeredByPurchase(state)) {
      dispatch(AiCommunicationsActions.showWelcomeMessage(WelcomeMessageSource.BILLING))
    }
  },
)

export const AiCommunicationsActions = {
  tryOpenFirstAiAutomationCreationModal,
  tryOpenWelcomeMessageModal,
  hideWelcomeMessage: actions.hideWelcomeMessage,
  showWelcomeMessage: actions.showWelcomeMessage,
  setTriggeredBySetLive: actions.setTriggeredBySetLive,
  setTriggeredByPurchase: actions.setTriggeredByPurchase,
}

const getState = (state: RootState) => state.aiCommunications

export const AiCommunicationsSelectors = {
  getState,
  getWelcomeMessageVisible: (state: RootState) => getState(state).isWelcomeMessageVisible,
  getWelcomeMessageSource: (state: RootState) => getState(state).welcomeMessageSource,
  getTriggeredBySetLive: (state: RootState) => getState(state).triggeredBySetLive,
  getTriggeredByPurchase: (state: RootState) => getState(state).triggeredByPurchase,
  isFlowContainsAiNodes: (state: RootState, managerId?: string) => {
    if (!managerId) {
      return false
    }

    const manager = managerSelectors.getById(state, managerId)
    const builderId = get(manager, 'builderId', null)

    if (!builderId) {
      return false
    }

    const aiNodes = entitySelectors.getNodesListByType(state, builderId, NodeType.AI_AGENT)

    if (aiNodes.length === 0) {
      return false
    }

    return true
  },
}

export const AiCommunicationsReducer = reducer

appListenerMiddleware.startListening({
  predicate: (action) => [upgrade.fulfilled.type].includes(action.type),
  effect: (_, { getState, dispatch }) => {
    const state = getState()
    if (AiCommunicationsSelectors.getTriggeredBySetLive(state)) {
      dispatch(AiCommunicationsActions.setTriggeredByPurchase(true))
      return
    }
    if (getHasAbility(state, Ability.AI_AGENT)) {
      dispatch(AiCommunicationsActions.tryOpenWelcomeMessageModal(true))
    }
  },
})

appListenerMiddleware.startListening({
  actionCreator: actions.showWelcomeMessage,
  effect: (action) => {
    AiWelcomeMessageAnalytics.logModalOpened({ source: action.payload })
  },
})

import { l } from '@manychat/manyui'

export enum MessageTagType {
  CONFIRMED_EVENT_UPDATE = 'CONFIRMED_EVENT_UPDATE',
  ACCOUNT_UPDATE = 'ACCOUNT_UPDATE',
  POST_PURCHASE_UPDATE = 'POST_PURCHASE_UPDATE',
  CUSTOM_OTHER = 'CUSTOM_OTHER',
  NON_PROMOTIONAL = 'NON_PROMOTIONAL_SUBSCRIPTION',
}

export enum FBMessagingType {
  INSIDE_24_HOURS = 'INSIDE_24_HOURS',
  OUTSIDE_24_HOURS = 'OUTSIDE_24_HOURS',
  SUBSCRIPTION_CONTENT = 'SUBSCRIPTION_CONTENT',
  COMMENT_REPLY = 'COMMENT_REPLY',
}

export const MessageTagsByProStatus: Partial<Record<MessageTagType, boolean>> = {
  [MessageTagType.CONFIRMED_EVENT_UPDATE]: true,
  [MessageTagType.ACCOUNT_UPDATE]: true,
  [MessageTagType.POST_PURCHASE_UPDATE]: true,
}

export const MessageTagLabels: Record<MessageTagType, () => string> = {
  [MessageTagType.CONFIRMED_EVENT_UPDATE]: l.makeTranslate('Confirmed Event Update'),
  [MessageTagType.ACCOUNT_UPDATE]: l.makeTranslate('Account Update'),
  [MessageTagType.POST_PURCHASE_UPDATE]: l.makeTranslate('Post-Purchase Update'),
  [MessageTagType.CUSTOM_OTHER]: l.makeTranslate('Other'),
  [MessageTagType.NON_PROMOTIONAL]: l.makeTranslate('Non Promotional Subscription'),
}

export const COMMENT_REPLY = 'private_reply'

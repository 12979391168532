import React from 'react'
import { useAppSelector } from 'reduxTyped'
import { BtnV2, l, useToast } from '@manychat/manyui'

import { CardTrigger } from 'apps/cms/components/Flows/components/FlowCard/components/Triggers/lib/types'
import { contentManagementSelectors } from 'apps/cms/store'
import { warnAccessDenied, warnDisabled } from 'apps/templates/models/Template/helpers'
import { navigatePollyfill } from 'utils/router/tools'

import { createFlowRedirectUrl } from './utils'

interface FailActivationTriggerAlertProps {
  flowId: string
  trigger: CardTrigger
}

export const FailActivationTriggerAlert = ({
  flowId,
  trigger,
}: FailActivationTriggerAlertProps) => {
  const queryPath = useAppSelector(contentManagementSelectors.getQueryPath)
  const flow = useAppSelector((state) => contentManagementSelectors.getFlowById(state, flowId))
  const { onClose } = useToast()

  const handleClick = () => {
    const url = createFlowRedirectUrl({ flowId, queryPath, trigger })

    const accessData = flow?.template_installation_access_data

    if (accessData && accessData.is_protected && !accessData.is_owner) {
      if (accessData.is_disabled) {
        return warnDisabled()
      }

      if (accessData.is_locked) {
        return warnAccessDenied()
      }
    }

    navigatePollyfill(url)
    onClose()
  }

  return (
    <BtnV2 onClick={handleClick} fullWidth className="m-t">
      {l.translate('Open Automation')}
    </BtnV2>
  )
}

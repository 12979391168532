import { getHandle } from 'utils/router/utils/getHandle'

export const SigninProfileRoutes = [
  {
    path: 'selectChannel',
    handle: getHandle('registration'),
    lazy: () => import('./FirstSelectChannel/FirstSelectChannel.page'),
  },
  {
    path: 'telegramAuth',
    handle: getHandle('auth'),
    lazy: () => import('./TelegramAuth/TelegramAuth.page'),
  },
] as const

export const SigninRoutes = [
  {
    path: 'signup',
    handle: getHandle('auth'),
    lazy: () => import('./FirstSelectChannel/FirstSelectChannel.page'),
  },
  {
    path: 'telegramAuth',
    handle: getHandle('auth'),
    lazy: () => import('./TelegramAuth/TelegramAuth.page'),
  },
  {
    path: 'facebookAuth',
    handle: getHandle('auth'),
    lazy: () => import('./FacebookAuth/FacebookAuth.page'),
  },
  {
    path: 'signin',
    handle: getHandle('auth'),
    lazy: () => import('./SignInPage/SignIn.page'),
  },
  {
    path: 'login',
    handle: getHandle('auth'),
    lazy: () => import('./SignInPage/SignIn.page'),
  },
] as const

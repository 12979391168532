import { combineReducers } from 'redux'

import { reducer as notifications } from '../redux/notificationsSlice'
import { reducer as state } from '../redux/stateSlice'

import admins from './admins'
import chatSettings from './chatSettings'
import counters from './counters'
import messages from './messages'
import { chatScheduledMessagesReducer as scheduledMessages } from './scheduledMessages'
import search from './search'
import threads from './threads'

export default combineReducers({
  admins,
  threads,
  messages,
  notifications,
  search,
  chatSettings,
  counters,
  state,
  scheduledMessages,
})

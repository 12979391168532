import React from 'react'
import { l } from '@manychat/manyui'

import DefaultStepView from 'utils/services/newOnboardingService/view/DefaultStepView'
import ManyChatOnboardingView from 'utils/services/newOnboardingService/view/ManyChatOnboardingView'
import { PointerType } from 'utils/services/newOnboardingService/view/Pointer/pointerInterfaces'
import TooltipDefault from 'utils/services/newOnboardingService/view/Pointer/Tooltip/TooltipDefault'

import NewCMSStoryReaction from './NewCMSStoryReaction'

const TOTAL_STEPS = 4

const NewCMSStoryReactionView = ({ onb }: { onb: NewCMSStoryReaction }) => {
  return (
    <ManyChatOnboardingView
      onboarding={onb}
      stepsView={{
        STEP_1: ({ stepId }) => (
          <DefaultStepView
            onb={onb}
            stepId={stepId}
            pointerConfig={{
              type: PointerType.DOM,
              elementId: 'nav-link-cms',

              tooltip: {
                component: (
                  <TooltipDefault
                    text={l.makeTranslate(
                      "Congrats! You’ve just set up your Story Reaction Reply. Let's click here to see where it lives in the Automation tab.",
                    )}
                  />
                ),
                at: 'right',
              },
            }}
            progressConfig={{
              current: 1,
              total: TOTAL_STEPS,
            }}
          />
        ),
        STEP_2: ({ stepId, step }) => (
          <DefaultStepView
            onb={onb}
            stepId={stepId}
            pointerConfig={{
              type: PointerType.DOM,
              elementId: 'nav-link-cms',
              blockAreaInput: true,
              tooltip: {
                component: (
                  <TooltipDefault
                    onNextClick={() => step.process()}
                    btnNext={l.makeTranslate('Next')}
                    text={l.makeTranslate(
                      "In the Automation tab, you'll find the Story Reaction Reply feature, as well as any other automations you've created.",
                    )}
                  />
                ),
                at: 'right',
              },
            }}
            progressConfig={{
              current: 2,
              total: TOTAL_STEPS,
            }}
          />
        ),
        STEP_3: ({ stepId }) => {
          const context = onb.getContext()
          return (
            <DefaultStepView
              onb={onb}
              stepId={stepId}
              pointerConfig={{
                type: PointerType.DOM,
                elementId: `folder-${context?.folderId}`,
                tooltip: {
                  component: (
                    <TooltipDefault
                      text={l.makeTranslate(
                        'Your Story Reaction Reply automation is located inside this folder.',
                      )}
                    />
                  ),
                  at: 'bottom',
                },
              }}
              progressConfig={{
                current: 3,
                total: TOTAL_STEPS,
              }}
            />
          )
        },
        STEP_4: ({ stepId, step }) => (
          <DefaultStepView
            onb={onb}
            stepId={stepId}
            pointerConfig={{
              type: PointerType.DOM,
              elementId: 'fs-object-flow',
              blockAreaInput: true,
              tooltip: {
                component: (
                  <TooltipDefault
                    onNextClick={() => step.process()}
                    btnNext={l.makeTranslate('Got it!')}
                    text={l.makeTranslate(
                      'That’s where you find your Story Reaction Reply. You can edit it anytime by clicking on this card here.',
                    )}
                  />
                ),
                at: 'top',
              },
            }}
            progressConfig={{
              current: 4,
              total: TOTAL_STEPS,
            }}
          />
        ),
      }}
    />
  )
}
export default NewCMSStoryReactionView

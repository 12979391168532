import { useState, useEffect, useRef } from 'react'

import { AutosizeInputProps } from './types'

const copyStyles = (styles: CSSStyleDeclaration, node: HTMLElement) => {
  node.style.fontSize = styles.fontSize
  node.style.fontFamily = styles.fontFamily
  node.style.fontWeight = styles.fontWeight
  node.style.fontStyle = styles.fontStyle
  node.style.letterSpacing = styles.letterSpacing
  node.style.textTransform = styles.textTransform
}

const MIN_WIDTH = 1

export function useAutosizeInput({
  inputClassName,
  inputRef: inputForwardedRef,
  ...inputProps
}: AutosizeInputProps) {
  const [inputWidth, setInputWidth] = useState(MIN_WIDTH)
  const isMountedRef = useRef(false)
  const sizerRef = useRef<HTMLDivElement>(null)
  const placeHolderSizerRef = useRef<HTMLDivElement>(null)
  const inputFallbackRef = useRef<HTMLInputElement>(null)
  const inputRef = inputForwardedRef ?? inputFallbackRef

  const copyInputStyles = () => {
    if (!isMountedRef.current || !window.getComputedStyle) {
      return
    }
    const inputStyles = inputRef.current && window.getComputedStyle(inputRef.current)
    if (!inputStyles) {
      return
    }
    if (sizerRef.current) {
      copyStyles(inputStyles, sizerRef.current)
    }
    if (placeHolderSizerRef.current) {
      copyStyles(inputStyles, placeHolderSizerRef.current)
    }
  }

  const updateInputWidth = () => {
    if (
      !isMountedRef.current ||
      !sizerRef.current ||
      typeof sizerRef.current.scrollWidth === 'undefined'
    ) {
      return
    }
    let newInputWidth
    if (inputProps.placeholder && !inputProps.value) {
      newInputWidth =
        Math.max(sizerRef.current.scrollWidth, placeHolderSizerRef.current?.scrollWidth ?? 0) + 2
    } else {
      newInputWidth = sizerRef.current.scrollWidth + 2
    }
    if (newInputWidth < MIN_WIDTH) {
      newInputWidth = MIN_WIDTH
    }
    if (newInputWidth !== inputWidth) {
      setInputWidth(newInputWidth)
    }
  }

  useEffect(() => {
    if (!isMountedRef.current) {
      isMountedRef.current = true
      copyInputStyles()
    }
    updateInputWidth()
  })

  const sizerValue = [inputProps.defaultValue, inputProps.value, ''].reduce(
    (previousValue, currentValue) => {
      if (previousValue !== null && previousValue !== undefined) {
        return previousValue
      }
      return currentValue
    },
  )

  const wrapperStyle = { ...inputProps.style }
  if (!wrapperStyle.display) wrapperStyle.display = 'inline-block'

  inputProps.style = {
    width: `${inputWidth}px`,
  }

  return {
    inputRef,
    sizerRef,
    placeHolderSizerRef,
    sizerValue,
    wrapperStyle,
    inputProps,
    copyInputStyles,
  }
}

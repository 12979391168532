import React from 'react'
import { l } from '@manychat/manyui'

import { FailActivationTriggerAlert } from 'apps/cms/components/Flows/components/FlowCard/components/Triggers/components/FailActivationTriggerAlert/FailActivationTriggerAlert'
import { CardTrigger } from 'apps/cms/components/Flows/components/FlowCard/components/Triggers/lib/types'
import { ExternalTriggerStatusType } from 'apps/externalTriggers/externalTriggerConstants'
import { WidgetStatusType } from 'apps/growthTools/models/Widget/constants'
import { KeywordStatus } from 'apps/keywords/models/KeywordRule/constants'
import { TriggerRuleStatusType } from 'apps/triggerRules/models/TriggerRule/constants'
import { alert } from 'common/core'
import { TriggerType } from 'common/flow/constants/TriggerType'
import { AiIntentsApi } from 'shared/api/requests/aiIntents'
import { AiIntentStatus } from 'shared/api/requests/aiIntents/schemas'
import { ExternalTriggerApi } from 'shared/api/requests/externalTrigger'
import { KeywordsApi } from 'shared/api/requests/keywords'
import { RulesApi } from 'shared/api/requests/rules'
import { widgetsApi } from 'shared/api/requests/widgets'

export const toggleTrigger = async ({
  trigger,
  value,
  flowId,
}: {
  trigger: CardTrigger
  value: boolean
  flowId: string
}) => {
  if (!trigger.id) return false

  try {
    if (trigger.type === TriggerType.AI_INTENT) {
      await AiIntentsApi.updateAiIntentStatus({
        body: {
          intent_id: trigger.id,
          status: value ? AiIntentStatus.ACTIVE : AiIntentStatus.DISABLED,
        },
      })
    }

    if (trigger.type === TriggerType.KEYWORD) {
      await KeywordsApi.setStatus({
        body: {
          rule_id: trigger.id,
          status: value ? KeywordStatus.LIVE : KeywordStatus.DRAFT,
        },
      })
    }

    if (trigger.type === TriggerType.WIDGET) {
      await widgetsApi.setDraftStatus({
        body: {
          widget_id: trigger.id,
          status: value ? WidgetStatusType.ACTIVE : WidgetStatusType.DRAFT,
        },
      })
    }

    if (trigger.type === TriggerType.RULE) {
      await RulesApi.changeStatus({
        body: {
          rule_id: trigger.id,
          status: value ? TriggerRuleStatusType.ACTIVE : TriggerRuleStatusType.INACTIVE,
        },
      })
    }

    if (trigger.type === TriggerType.EXTERNAL_TRIGGER) {
      await ExternalTriggerApi.changeStatus({
        body: {
          external_trigger_id: trigger.id,
          status: value ? ExternalTriggerStatusType.ACTIVE : ExternalTriggerStatusType.INACTIVE,
        },
      })
    }

    const alertText = value
      ? l.translate('Trigger has been turned on')
      : l.translate('Trigger has been turned off')
    alert(alertText, 'success')

    return true
  } catch (error) {
    alert(
      !value
        ? l.translate('Change Trigger settings to deactivate it')
        : l.translate('Set up Trigger to activate it'),
      'info',
      {
        icon: 'Warning',
        content: <FailActivationTriggerAlert trigger={trigger} flowId={flowId} />,
      },
    )
    return false
  }
}

/**
 * @class RenderToLayer
 */

import React from 'react'
import { createPortal } from 'react-dom'
import PropTypes from 'prop-types'

import cleanProps from '../../../lib/clean-react-props'
import { noop, DOM } from '../../../utils'

import { eventsManager } from './utils'
import { withHandler } from './utils/stoppedEvents'

const WRAPPER_STYLE = { position: 'relative', zIndex: 1010 }
const CLICK_AWAY_STYLE = {
  position: 'fixed',
  top: 0,
  bottom: 0,
  left: 0,
  right: 0,
  zIndex: 1010,
}

class RenderToLayer extends React.Component {
  static propTypes = {
    className: PropTypes.string,
    children: PropTypes.any,
    style: PropTypes.object,
    onClick: PropTypes.func,
    onClickAway: PropTypes.func,
    onEscapeKeyDown: PropTypes.func,
    open: PropTypes.bool.isRequired,
    useLayerForClickAway: PropTypes.bool,
  }

  static defaultProps = {
    onClick: noop,
    onClickAway: noop,
    onEscapeKeyDown: noop,
    useLayerForClickAway: true,
    allowPropagationEvents: [],
  }

  constructor(props) {
    super(props)
    this.handler = (eventName, e) => {
      if (typeof this.props[eventName] === 'function') {
        this.props[eventName](e)
      }
      e.stopPropagation()
    }
    this.events = withHandler(this.handler, props.allowPropagationEvents)
    this.ignoreEvents = false
  }

  getLayer() {
    return this.layer
  }

  ignoreEventsUntilNextTick() {
    this.ignoreEvents = true
    setTimeout(() => (this.ignoreEvents = false))
  }

  handleWindowClick = (event, mousedownEvent) => {
    if (this.ignoreEvents) {
      return
    }

    if (!this.props.open) {
      return
    }

    for (let i = 0; i < this.layer.children.length; i++) {
      const child = this.layer.children[i]

      if (DOM.isClickInElement(event, child) || DOM.isClickInElement(mousedownEvent, child)) {
        event.stopPropagationToLayers()
        // This event handled in default handler for all events. May be we should handle it here for right order
        // this.props.onClick(event)
        return
      }
    }
    this.props.onClickAway(event, mousedownEvent)
  }

  handleEscapeKeyDown = (e) => this.props.onEscapeKeyDown(e)

  handleLayer = (el) => {
    if (el) {
      if (!this.layer) {
        eventsManager.addEventListener('click', this.handleWindowClick)
        eventsManager.addEventListener('esckeydown', this.handleEscapeKeyDown)
        // Layer can be opened from events fired right before click, e.g. select, blur or mousedown.
        // ignoreEventsUntilNextTick used for ignore clicks right after such events.
        this.ignoreEventsUntilNextTick()
      }
    } else if (this.layer) {
      eventsManager.removeEventListener('click', this.handleWindowClick)
      eventsManager.removeEventListener('esckeydown', this.handleEscapeKeyDown)
    }
    this.layer = el
  }

  render() {
    const { open, className, children, style, useLayerForClickAway } = this.props

    if (!open) {
      return null
    }

    return createPortal(
      <div
        {...cleanProps(this.props)}
        ref={this.handleLayer}
        style={Object.assign(
          {},
          WRAPPER_STYLE,
          useLayerForClickAway ? CLICK_AWAY_STYLE : undefined,
          style,
        )}
        className={className}
        {...this.events}
      >
        {children}
      </div>,
      document.body,
    )
  }
}

export default RenderToLayer

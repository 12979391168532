import get from 'lodash/get'

import SequenceMessageModel from 'common/sequences/models/SequenceMessage'

import defaults from './defaults'

export default function (data) {
  const res = {
    ...data,

    id: get(data, 'sequence_id'),
    name: get(data, 'name', defaults.name),
    messagesTotal: get(data, 'messages', defaults.messagesTotal),
  }

  // Deleting messages counter, this field used for messages array
  delete res.messages

  if (data.sequence_messages) {
    res.messages = data.sequence_messages.map((sequenceMessage) =>
      SequenceMessageModel.adapter(sequenceMessage),
    )
  }

  return res
}

import { l } from '@manychat/manyui'

import { IDefaultReplyTrigger } from 'apps/defaultReply/defaultReplyInterfaces'
import { TriggerType } from 'common/flow/constants/TriggerType'
import { parseLightFlow } from 'common/flow/models/Flow/parseLightFlow'
import { DefaultReply } from 'shared/api/requests/defaultReply/schemas'

const defaultReplyAdapter = (item: DefaultReply): IDefaultReplyTrigger => {
  const { channel, content_holder, enabled, type, sources, flow } = item
  const flowId = flow?.ns ?? null

  const caption = l.translate('Default Reply')

  return {
    id: content_holder,
    triggerType: TriggerType.DEFAULT_REPLY,
    triggerId: `${TriggerType.DEFAULT_REPLY}-${channel}`,

    channel,
    enabled,
    ctr: null,
    runs: 0,
    type,
    ts_created: 0,
    caption,
    defaultReplyId: content_holder,
    contentHolder: content_holder,
    chId: content_holder,
    sources,
    flowId,
    clicked_unq: 0,
    content_holder: '',
    flow: flow ? parseLightFlow(flow) : null,
  }
}

export default defaultReplyAdapter

export const QUESTIONNAIRE_SHOW_STEP = 'QUESTIONNAIRE_SHOW_STEP'
export const QUESTIONNAIRE_FETCH_QUESTIONS = 'QUESTIONNAIRE_FETCH_QUESTIONS'
export const QUESTIONNAIRE_FETCH_QUESTIONS_RESPONSE = 'QUESTIONNAIRE_FETCH_QUESTIONS_RESPONSE'
export const QUESTIONNAIRE_UPDATE_QUESTIONS = 'QUESTIONNAIRE_UPDATE_QUESTIONS'
export const QUESTIONNAIRE_SAVE_ANSWER = 'QUESTIONNAIRE_SAVE_ANSWER'
export const QUESTIONNAIRE_SAVE_ANSWER_RESPONSE = 'QUESTIONNAIRE_SAVE_ANSWER_RESPONSE'
export const QUESTIONNAIRE_FETCH_REPEAT_QUESTIONS = 'QUESTIONNAIRE_FETCH_REPEAT_QUESTIONS'
export const QUESTIONNAIRE_FETCH_REPEAT_QUESTIONS_RESPONSE =
  'QUESTIONNAIRE_FETCH_REPEAT_QUESTIONS_RESPONSE'
export const QUESTIONNAIRE_FETCH_REPEAT_QUESTIONS_ERROR =
  'QUESTIONNAIRE_FETCH_REPEAT_QUESTIONS_ERROR'
export const QUESTIONNAIRE_SET_VISIBILITY_FOR_OLD_ACCOUNTS =
  'QUESTIONNAIRE_SET_VISIBILITY_FOR_OLD_ACCOUNTS'

export const QUESTIONNAIRE_GET_ANSWERS = 'QUESTIONNAIRE_GET_ANSWERS'
export const QUESTIONNAIRE_GET_ANSWERS_RESPONSE = 'QUESTIONNAIRE_GET_ANSWERS_RESPONSE'

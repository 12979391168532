import BaseButtonConfig from 'common/builder/buttons/BaseButtonConfig'
import { IButtonBase, WaListMessageButton } from 'common/builder/buttons/buttonInterfaces'
import { ButtonType } from 'common/builder/constants/ButtonType'

class WaListMessageButtonConfig extends BaseButtonConfig {
  buttonType = ButtonType.WA_LIST_MESSAGE_BUTTON

  getDefaults = (): Omit<WaListMessageButton, keyof IButtonBase> => ({
    targetId: null,
    description: '',
  })
}

export default new WaListMessageButtonConfig()

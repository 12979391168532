import {
  ReactNode,
  createContext,
  createElement,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react'

export type ScreenSize = 'sm' | 'md' | 'lg' | 'xl'

const getScreenSize = (width: number): ScreenSize => {
  if (width >= 1600) {
    return 'xl'
  }

  if (width >= 1200) {
    return 'lg'
  }

  if (width >= 768) {
    return 'md'
  }

  return 'sm'
}

const useScreenWidth = () => {
  const [screenWidth, setScreenWidth] = useState(window.innerWidth)

  const updateWidth = useCallback(() => {
    setScreenWidth(window.innerWidth)
  }, [])

  useEffect(() => {
    window.addEventListener('resize', updateWidth)

    return () => window.removeEventListener('resize', updateWidth)
  }, [updateWidth])

  return screenWidth
}

export const ScreenContext = createContext<ScreenSize>('lg')

export const useScreen = () => useContext(ScreenContext)

export const ScreenProvider = ({ children }: { children?: ReactNode | undefined }) => {
  const screenWidth = useScreenWidth()

  return createElement(ScreenContext.Provider, { value: getScreenSize(screenWidth) }, children)
}

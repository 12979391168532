import React from 'react'
import { useAppSelector } from 'reduxTyped'

import { ApplyRecommendationModal } from 'apps/cms/components/Flows/components/AutomationModals/modals/ApplyRecommendationModal/ApplyRecommendationModal'
import { BulkDeleteFlowsModal } from 'apps/cms/components/Flows/components/AutomationModals/modals/BulkDeleteFlowsModal/BulkDeleteFlowsModal'
import { BulkMoveToModal } from 'apps/cms/components/Flows/components/AutomationModals/modals/BulkMoveToModal/BulkMoveToModal'
import { ConvertFlowChannelsModal } from 'apps/cms/components/Flows/components/AutomationModals/modals/ConvertFlowChannelsModal/ConvertFlowChannelsModal'
import { CreateFolderModal } from 'apps/cms/components/Flows/components/AutomationModals/modals/CreateFolderModal/CreateFolderModal'
import { CreateFolderWithContentModal } from 'apps/cms/components/Flows/components/AutomationModals/modals/CreateFolderWithContentModal/CreateFolderWithContentModal'
import { DeleteFlowModal } from 'apps/cms/components/Flows/components/AutomationModals/modals/DeleteFlowModal/DeleteFlowModal'
import { DeleteFolderModal } from 'apps/cms/components/Flows/components/AutomationModals/modals/DeleteFolderModal/DeleteFolderModal'
import { ExportFlowModal } from 'apps/cms/components/Flows/components/AutomationModals/modals/ExportFlowModal/ExportFlowModal'
import { MoveToModal } from 'apps/cms/components/Flows/components/AutomationModals/modals/MoveToModal/MoveToModal'
import { PermanentDeleteFlowModal } from 'apps/cms/components/Flows/components/AutomationModals/modals/PermanentDeleteFlowModal/PermanentDeleteFlowModal'
import { RenameFlowModal } from 'apps/cms/components/Flows/components/AutomationModals/modals/RenameFlowModal/RenameFlowModal'
import { RenameFolderModal } from 'apps/cms/components/Flows/components/AutomationModals/modals/RenameFolderModal/RenameFolderModal'
import { ShareFlowModal } from 'apps/cms/components/Flows/components/AutomationModals/modals/ShareFlowModal/ShareFlowModal'
import { contentManagementSelectors } from 'apps/cms/store'
import { AutomationModalType } from 'apps/cms/store/lib/types'

const modalsMap: Record<AutomationModalType, () => JSX.Element> = {
  [AutomationModalType.MOVE_TO]: MoveToModal,
  [AutomationModalType.RENAME_FLOW]: RenameFlowModal,
  [AutomationModalType.DELETE_FLOW]: DeleteFlowModal,
  [AutomationModalType.CONVERT_FLOW_CHANNELS]: ConvertFlowChannelsModal,
  [AutomationModalType.CREATE_FOLDER]: CreateFolderModal,
  [AutomationModalType.RENAME_FOLDER]: RenameFolderModal,
  [AutomationModalType.CREATE_FOLDER_WITH_CONTENT]: CreateFolderWithContentModal,
  [AutomationModalType.DELETE_FOLDER]: DeleteFolderModal,
  [AutomationModalType.BULK_DELETE]: BulkDeleteFlowsModal,
  [AutomationModalType.BULK_MOVE_TO]: BulkMoveToModal,
  [AutomationModalType.EXPORT_FLOW_MODAL]: ExportFlowModal,
  [AutomationModalType.SHARE_FLOW]: ShareFlowModal,
  [AutomationModalType.PERMANENT_DELETE]: PermanentDeleteFlowModal,
  [AutomationModalType.RECOMMENDATIONS_APPLY]: ApplyRecommendationModal,
}

export const AutomationModals = React.memo(function AutomationModals() {
  const modals = useAppSelector(contentManagementSelectors.getModals)

  return (
    <>
      {Object.entries(modals).map(([modalType, modalState]) => {
        if (modalState.isOpen) {
          const Modal = modalsMap[modalType as AutomationModalType]

          return <Modal key={modalType} />
        }

        return null
      })}
    </>
  )
})

import { useCallback } from 'react'
import { useAppDispatch } from 'reduxTyped'

import { CmsAnalytics } from 'apps/cms/lib/analytics'
import { contentManagementActions } from 'apps/cms/store'

export const useTrashMoveActions = () => {
  const dispatch = useAppDispatch()

  const moveFolderToTrash = useCallback(
    (path: string) => {
      CmsAnalytics.logMoveFolderToTrashDnD()
      dispatch(
        contentManagementActions.deleteFolder({
          path,
        }),
      )
    },
    [dispatch],
  )

  const moveFlowsToTrash = useCallback(
    (flowPaths: string[]) => {
      if (flowPaths.length === 1) {
        CmsAnalytics.logMoveFlowToTrashDnD()
        dispatch(contentManagementActions.deleteFlowByPath(flowPaths[0]))
      } else {
        CmsAnalytics.logBulkMoveFlowsToTrashDnD({ count: flowPaths.length })
        dispatch(contentManagementActions.bulkDeleteFlows())
      }
    },
    [dispatch],
  )

  return {
    moveFolderToTrash,
    moveFlowsToTrash,
  }
}

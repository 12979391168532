import { FBPolicyEnforcementModalType } from 'common/core/components/FBPolicyEnforcementModal/FBPolicyEnforcementModalType'

export enum FBPolicyEnforcementNotificationType {
  WARNING = 'warning',
  BLOCK = 'block',
  UNBLOCK = 'unblock',
}

export interface FacebookPolicyEnforcementNotification {
  notification_id: number
  reason: string
  type: FBPolicyEnforcementNotificationType
  ts_created: number
}

export interface FacebookPolicyEnforcementState {
  notification: FacebookPolicyEnforcementNotification
  type: FBPolicyEnforcementModalType
}

import { v4 as uuid } from 'uuid'
import { l } from '@manychat/manyui'

import { alert } from 'common/core'
import { IAsyncThunkAction } from 'common/core/interfaces/actions'
import * as atypes from 'common/fields/constants/fieldsReduxActionTypes'
import { IFieldInterface } from 'common/settings/components/SettingsPanel/CustomFieldsSection/FieldInterface'
import { handleCatch } from 'shared/api/lib/errors/handlers'
import { UserFieldsApi } from 'shared/api/requests/customFields'
import { handleCatchError } from 'utils/handleCatchError'

import { getUserFieldSearchValue } from '../selectors/userFieldsSelectors'

import { fetchUserFieldsWithData } from './userFieldsActions'

export const moveToFolderBulkUserField =
  (fieldIds: number[], folderId: number, oldFolderId: number): IAsyncThunkAction =>
  async (dispatch, getState) => {
    try {
      dispatch({ type: atypes.USER_FIELDS_MOVE_TO_BULK_REQUEST })

      await UserFieldsApi.moveToFolderBulk({
        body: {
          ids: fieldIds,
          folder_id: folderId,
        },
      })

      alert(l.translate('Fields moved to folder!'), 'success')
      dispatch({ type: atypes.USER_FIELDS_MOVE_TO_BULK_RESPONSE })
      const searchValue = getUserFieldSearchValue(getState())
      if (searchValue) {
        dispatch(
          fetchUserFieldsWithParams(true, {
            query: searchValue,
          }),
        )
      } else if (oldFolderId) {
        dispatch(
          fetchUserFieldsWithParams(false, {
            path: `/${oldFolderId}`,
            folderId: oldFolderId,
          }),
        )
      } else {
        dispatch(fetchUserFieldsWithParams(true, { path: '/' }))
      }
    } catch (error) {
      dispatch({ type: atypes.USER_FIELDS_MOVE_TO_BULK_ERROR })
      handleCatch(error)
    }
  }

export const moveToFolderUserField =
  (field: IFieldInterface, folderId: number): IAsyncThunkAction =>
  async (dispatch, getState) => {
    try {
      dispatch({ type: atypes.USER_FIELDS_MOVE_TO_REQUEST })

      await UserFieldsApi.moveToFolder({
        body: {
          field_id: field.field_id,
          folder_id: folderId,
        },
      })

      alert(l.translate('Field moved to folder!'), 'success')
      dispatch({ type: atypes.USER_FIELDS_MOVE_TO_RESPONSE })

      const searchValue = getUserFieldSearchValue(getState())
      if (searchValue) {
        dispatch(
          fetchUserFieldsWithParams(true, {
            query: searchValue,
          }),
        )
      } else if (field.folder_id) {
        dispatch(
          fetchUserFieldsWithParams(false, {
            path: `/${field.folder_id}`,
            folderId: field.folder_id,
          }),
        )
      } else {
        dispatch(fetchUserFieldsWithParams(true, { path: '/', folderId: 0 }))
      }
    } catch (error) {
      dispatch({ type: atypes.USER_FIELDS_MOVE_TO_ERROR })
      handleCatchError(error)
    }
  }

export const fetchUserFieldsWithParams = (
  includeArchived: boolean,
  { path, folderId, query }: { path?: string; folderId?: number; query?: string },
): IAsyncThunkAction => {
  return async (dispatch) => {
    try {
      const params = {
        active_only: !includeArchived,
        folderId,
        path,
        query,
        withoutHash: uuid(),
      }
      await dispatch(fetchUserFieldsWithData(params))
    } catch (error) {
      handleCatchError(error)
    }
  }
}

import isEmpty from 'lodash/isEmpty'

import { createUniqueCaption } from 'utils'
import { mergeParsed } from 'common/builder/actions/mergeParsed'
import { setSelection } from 'common/builder/actions/selections'
import { updateBuilderState } from 'common/builder/actions/updateBuilderState'
import { updateCoordinates } from 'common/builder/actions/updateCoordinates'
import flowChartApp from 'common/builder/components/chart/FlowChart/app'
import { NodeType } from 'common/builder/constants/NodeType'
import { copyElement } from 'common/builder/models/BuilderState/copyElement'
import builderSelectors from 'common/builder/selectors/builder'
import { getNodesList } from 'common/builder/selectors/builder/entitySelectors'

import { setChanged } from './builderStateActions'

/**
 * @param {string} builderId
 * @param {object} nodeCopyData
 * @param {object} options
 */
export const pasteNodes = (
  builderId,
  nodeCopyData,
  { position, uniqueCaptions = false, setSelection: isSetSelection = true, isSourcePageSame } = {},
) => {
  return (dispatch, getState) => {
    const state = getState()
    const builderState = builderSelectors.builderState.getById(state, builderId)
    const { nodesData, offsets, coordinates } = nodeCopyData
    const toStore = {
      nodes: [],
      blocks: [],
      buttons: [],
    }
    const copyMap = {}
    const contentIdMap = {}
    nodesData.forEach(({ node, related }) => {
      if (node.isStartingStep) {
        return
      }
      const [clonedNode, parsed] = copyElement(node, related)
      copyMap[node.id] = clonedNode
      if (node.contentId) {
        contentIdMap[node.contentId] = clonedNode
      }

      if (!isSourcePageSame && node.nodeType === NodeType.AI_AGENT && node.agent_data !== null) {
        clonedNode.agent_data.config.scenario.map((scenario) => {
          if (scenario.config.field_id && scenario.config.field_id.includes('cuf_')) {
            scenario.config.field_id = ''
          }
        })
      }

      toStore.blocks = [...toStore.blocks, ...parsed.blocks]
      toStore.buttons = [...toStore.buttons, ...parsed.buttons]
      if (builderState) {
        clonedNode.flow = builderState.flow
      }
      toStore[`${clonedNode.entity}s`].push(clonedNode)
    })

    const clonedSources = [...toStore.nodes, ...toStore.buttons, ...toStore.blocks]

    // special case for wa list messages with sections structure
    // remap options target id to keep the connections to nodes
    clonedSources.forEach((source) => {
      if (source?.sections) {
        source.sections.forEach((section) => {
          section.options?.forEach((option) => {
            const clonedTargetItem =
              copyMap[option._content_oid] || contentIdMap[option._content_oid]
            option._content_oid = clonedTargetItem ? clonedTargetItem.id : null
          })
        })
      }

      if (!source.targetId) {
        return
      }

      // remap targetId field from pointing to the previous (copied) ids to the new ones
      // this keeps the connections between nodes and buttons
      const clonedTargetItem = copyMap[source.targetId] || contentIdMap[source.targetId]
      if (!clonedTargetItem) {
        source.targetId = null
        return
      }
      source.targetId = clonedTargetItem.id
    })

    if (uniqueCaptions) {
      const captions = getNodesList(state, builderId).map((node) => node.caption)

      for (let item of toStore.nodes) {
        item.caption = createUniqueCaption(captions, item.caption, { prefix: ' copy ' })
        captions.push(item.caption)
      }
    }

    if (flowChartApp.root !== null && offsets) {
      const point = position || flowChartApp.getPointForNewItems()
      const coordinates = {}
      for (let prevId of Object.keys(copyMap)) {
        const newId = copyMap[prevId].id
        coordinates[newId] = {
          x: point.x + offsets[prevId].x,
          y: point.y + offsets[prevId].y,
        }
      }
      dispatch(updateCoordinates(builderId, coordinates))
    }

    if (coordinates) {
      const coords = {}
      for (let prevId of Object.keys(copyMap)) {
        if (coordinates[prevId]) {
          const newId = copyMap[prevId].id
          coords[newId] = coordinates[prevId]
        }
      }
      if (!isEmpty(coords)) {
        dispatch(updateCoordinates(builderId, coords))
      }
    }

    dispatch(mergeParsed(builderId, toStore))
    dispatch(setChanged(builderId))

    if (isSetSelection) {
      const newSelection = Object.keys(copyMap).map((prevId) => copyMap[prevId].id)
      dispatch(setSelection(builderId, newSelection))
      dispatch(updateBuilderState(builderId, { sidebarHidden: false }))
    }

    return { copyMap, toStore }
  }
}

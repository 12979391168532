import React, { useEffect } from 'react'
import { Icon, l, tints } from '@manychat/manyui'

import { localStorage } from 'utils'

import { SURVEY_LOCAL_STORAGE_ID } from './constants'

export const SuccessScreen = () => {
  useEffect(() => {
    localStorage.setItem(SURVEY_LOCAL_STORAGE_ID, true)
  }, [])

  return (
    <>
      <Icon.CheckCircle color={tints.green[3]} size={48} />
      <h1 className="m-t-md">{l.translate('Sent')}</h1>
      <p className="m-t-xs">
        {l.translate('We will take into account your opinion and make our product better.')}
      </p>
    </>
  )
}

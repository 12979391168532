import React from 'react'
import cx from 'classnames'
import PropTypes from 'prop-types'

import cleanProps from '../../../lib/clean-react-props'
import { deprecatedDecorator } from '../../../utils/deprecated-decorator'
import { getString } from '../../../utils/localization/format'
// eslint-disable-next-line no-unused-vars
import RadioGroup from '../RadioGroup'

import cm from './Radio.module.css'

/** Radio buttons are used when there is a list of options and the user may select only one option. */
/** @deprecated use {@link RadioGroup} component */
class Radio extends React.PureComponent {
  static propTypes = {
    name: PropTypes.string,
    value: PropTypes.any,
    options: PropTypes.arrayOf(
      PropTypes.shape({
        label: PropTypes.oneOfType([PropTypes.func, PropTypes.string, PropTypes.element]),
        value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
        id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
        help: PropTypes.any,
        disabled: PropTypes.bool,
        invalid: PropTypes.bool,
        props: PropTypes.object,
      }),
    ),
    disabled: PropTypes.bool,
    invalid: PropTypes.bool,
    helpClassName: PropTypes.string,
    theme: PropTypes.object,
    inline: PropTypes.bool,
    showAllHelp: PropTypes.bool,
  }

  static defaultProps = {
    options: [{ label: '', value: 0 }],
    theme: {},
    showAllHelp: false,
  }

  render() {
    const {
      name,
      value,
      options,
      invalid,
      inline,
      helpClassName,
      theme,
      onClick,
      showAllHelp,
      ...other
    } = this.props

    return (
      <div className={cx(cm.wrapper, theme.wrapper, { 'd-flex': inline })} onClick={onClick}>
        {options.map((option, index) => {
          const checked = option.value === value
          const disabled = option.disabled || this.props.disabled

          return (
            <div
              key={option.value + '-' + index}
              className={cx(cm.option, theme.option, {
                [cm.disabled]: disabled,
                [theme.checked]: checked && theme.checked,
                'm-b-xxs': !inline,
                'm-r': inline,
              })}
              id={option.id}
            >
              <label className={cx('text-body', theme.label)}>
                <input
                  type="radio"
                  checked={checked}
                  value={option.value}
                  name={name}
                  disabled={disabled}
                  {...cleanProps({ ...other, ...option.props })}
                />
                <span className={cx(cm.icon, (option.invalid || invalid) && cm.invalid)} />
                {getString(option.label)}
              </label>
              {(checked || showAllHelp) && option.help && (
                <div className={cx(cm.help, helpClassName)}>{getString(option.help)}</div>
              )}
            </div>
          )
        })}
      </div>
    )
  }
}

/** @deprecated use {@link RadioGroup} component */
export default deprecatedDecorator(Radio, { replacementComponentName: 'RadioGroup' })

import { createSelector } from 'reselect'

import { localStorage } from 'utils'
import {
  ConversationFilterType,
  OptInByChannelFilter,
  ALL_TEAM_MEMBERS_ID,
  MAX_NUMBER_OF_HANDOVER_RETRIES,
  ThreadControlErrorType,
  ChannelStatusTypes,
  LiveChatChannel,
} from 'apps/chat/constants/LiveChatInterfaces'
import { LiveChatLocalStorageIds } from 'apps/chat/constants/LiveChatLocalStorageIds'
import { ThreadStatusesByStatusFilterAndFolder } from 'apps/chat/constants/ThreadStatusesByStatusFilterAndFolder'
import { ChannelType } from 'common/core/constants/ChannelType'
import { getCurrentAccount, getUserId } from 'common/core/selectors/appSelectors'
import {
  getConnectedWhatsAppChannel,
  getInstagramChannel,
  getTelegramChannel,
  hasConnectedInstagramChannel,
  hasConnectedSMSChannel,
  hasConnectedTelegramChannel,
  hasConnectedWhatsAppChannel,
  isFBChannelConnected,
} from 'common/core/selectors/channelSelectors'
import { FilterDataType, FilterGroup, FilterOperator } from 'common/filter/filterConstants'
import { getSettingsItem } from 'common/settings/selectors/settingsSelectors'
import { getUserRole, UserRoles } from 'common/userRoles'
import { Error } from 'utils/factory/limitedList/interfaces'

import { getAdminById } from './adminsSelectors'
import { filterBulkThreads } from './utils'

export const getThreads = (state: RootState) => state.chat.threads.items

export const getThreadsMap = (state: RootState) => state.chat.threads.byId

export const getThreadsSorting = (state: RootState) => state.chat.threads.threadsSorting

export const getThreadsAdvancedFilter = (state: RootState) => state.chat.threads.filter

export const getCurrentChannel = (state: RootState) => state.chat.threads.channel

export const getThreadsStatusFilter = (state: RootState) => state.chat.threads.statusFilter

export const getCurrentThread = (state: RootState) => state.chat.threads.current

export const getCurrentId = (state: RootState) => state.chat.threads.currentId

export const getChannelsFilter = (state: RootState) => state.chat.threads.channelsFilter

export const getSelectedThreads = (state: RootState) => state.chat.threads.selectedThreads

export const getThreadsSelectionType = (state: RootState) => state.chat.threads.selectionType

export const getFetchingPauseIds = (state: RootState) => state.chat.threads.fetchingPauseIds

export const getTotalThreadsCount = (state: RootState) => state.chat.threads.totalThreadsCount || 0

export const getThreadsUploadedImages = (state: RootState) =>
  state.chat.threads.uploadedImagesThreads

export const getLoadingMessagesThreads = (state: RootState) =>
  state.chat.threads.loadingMessagesThreads

export const getById = (state: RootState, threadId: string) => {
  const map = getThreadsMap(state)
  return map[threadId]
}

export const getIsThreadsInitiallyFetching = (state: RootState) => {
  return state.chat.threads.fetching && !state.chat.threads.items
}

export const getIsAgentWithVisibilityLimitedRights = (state: RootState) => {
  const userRole = getUserRole(state)
  const isThreadsFilterEnabled = getSettingsItem(state).lc_threads_filter_enabled
  return userRole === UserRoles.AGENT && Boolean(isThreadsFilterEnabled)
}

export const getConversationsFilter = (state: RootState) => {
  const savedValue = localStorage.getItem(LiveChatLocalStorageIds.CONVERSATIONS_FILTER)
  if (savedValue) return savedValue as ConversationFilterType

  if (getIsAgentWithVisibilityLimitedRights(state)) {
    return ConversationFilterType.YOU
  }

  return state.chat.threads.conversationsFilter
}

export const getTeamMemberFilter = (state: RootState) => {
  const savedValue = localStorage.getItem(LiveChatLocalStorageIds.TEAM_MEMBER_FILTER)

  if (savedValue) {
    const selectedTeamMember = Number(savedValue)
    if (getAdminById(state, selectedTeamMember)) return selectedTeamMember
  }

  return state.chat.threads.teamMembersFilter
}

export const getSelectedThreadsCount = (state: RootState) => {
  return Object.keys(getSelectedThreads(state)).length
}

export const getCurrentThreadErrors = (state: RootState) => {
  return (getCurrentThread(state).error as Error)?.errors || []
}

export const getCurrentThreadItem = (state: RootState) => {
  return getCurrentThread(state)?.item
}

export const getIsThreadAssignedToCurrentUser = (state: RootState) => {
  const currentThread = getCurrentThreadItem(state)
  const userID = getUserId(state)

  return currentThread?.assignment?.user_id === userID
}

export const getThreadControlError = createSelector(
  [getCurrentThread, getCurrentChannel],
  (currentThread, currentChannel) => {
    const threadControlError = currentThread?.threadControlError
    const result: Result = {
      shouldShowHandoverSecondaryAppError: false,
      conversationRoutingError: null,
    }

    if (!threadControlError || currentChannel !== ChannelType.FB) {
      return result
    }

    interface Result {
      shouldShowHandoverSecondaryAppError: boolean
      conversationRoutingError: ThreadControlErrorType | null
    }

    if (
      threadControlError.type === ThreadControlErrorType.HANDOVER_SECONDARY_THREAD_OWNERSHIP &&
      (threadControlError?.counter || 0) >= MAX_NUMBER_OF_HANDOVER_RETRIES
    ) {
      result.shouldShowHandoverSecondaryAppError = true
    }

    if (
      [ThreadControlErrorType.CR_FB, ThreadControlErrorType.CR_THIRD_PARTY].includes(
        threadControlError.type,
      )
    ) {
      result.conversationRoutingError = threadControlError.type
    }

    return result
  },
)

export const getIsThreadSatisfiesFilter = (state: RootState, user_id: string) => {
  const userInfo = state.chat.threads.checkedFilterIds[user_id]

  return userInfo?.satisfy ?? false
}

export const getFilteredThreadsTotalCount = (state: RootState) => {
  const totalThreadsCount = getTotalThreadsCount(state)
  const threads = getThreads(state)
  const filteredThreads = getFilteredThreads(state)

  if (!threads || !threads.length) return totalThreadsCount
  if (!filteredThreads?.length) return 0

  const diff = threads.length - filteredThreads.length
  return totalThreadsCount < threads.length ? filteredThreads.length : totalThreadsCount - diff
}

const getChannelsFilterData = createSelector([getChannelsFilter], (channelsFilter) => {
  if (!channelsFilter) return null

  const optIn = OptInByChannelFilter[channelsFilter]
  if (!optIn) return null

  return {
    operator: FilterOperator.AND,
    items: [
      {
        type: 'suf',
        field: optIn,
        operator: 'TRUE',
        value: null,
      },
    ],
  }
})

export const getThreadStatusesByStatusFilterAndFolder = createSelector(
  [getThreadsStatusFilter, getConversationsFilter],
  (statusFilter, conversationFilter) => {
    return ThreadStatusesByStatusFilterAndFolder[conversationFilter][statusFilter]
  },
)

export const getFilteredThreads = createSelector(
  [
    getThreads,
    getConversationsFilter,
    getTeamMemberFilter,
    getThreadStatusesByStatusFilterAndFolder,
    getUserId,
  ],
  (threads, conversationsFilter, teamMemberFilter, threadStatuses, userId) => {
    if (!threads || !userId) return null

    return filterBulkThreads(threads, conversationsFilter, teamMemberFilter, threadStatuses, userId)
  },
)

export const getFolder = createSelector([getConversationsFilter], (conversationsFilter) => {
  if (conversationsFilter === ConversationFilterType.YOU) {
    return ConversationFilterType.TEAM
  }

  return conversationsFilter
})

export const getFolderUserId = createSelector(
  [getConversationsFilter, getTeamMemberFilter, getUserId],
  (conversationsFilter, teamMemberId, userId) => {
    if (conversationsFilter === ConversationFilterType.YOU) {
      return userId
    }

    if (
      conversationsFilter === ConversationFilterType.TEAM &&
      teamMemberId !== ALL_TEAM_MEMBERS_ID
    ) {
      return teamMemberId
    }

    return null
  },
)

export const getIsAdvancedFilterApplied = createSelector(
  [getThreadsAdvancedFilter, getChannelsFilter],
  (filter, channelFilter) => Boolean(channelFilter !== null || filter?.groups?.[0]?.items?.length),
)

export const getThreadsFilterData = createSelector(
  [getChannelsFilterData, getThreadsAdvancedFilter],
  (channelsFilterData, advancedFilter) => {
    const filterData = {
      operator: FilterOperator.AND,
      groups: [] as FilterGroup[],
    }

    const filterGroupFilterValue = advancedFilter?.groups?.[0]
    if (filterGroupFilterValue) {
      filterData.groups.push(filterGroupFilterValue)
    }

    if (channelsFilterData) {
      filterData.groups.push(channelsFilterData)
    }

    return filterData.groups?.length ? filterData : ({} as FilterDataType)
  },
)

export const getLiveChatConnectedChannels = createSelector(
  [
    isFBChannelConnected,
    hasConnectedInstagramChannel,
    hasConnectedTelegramChannel,
    hasConnectedWhatsAppChannel,
    hasConnectedSMSChannel,
  ],
  (
    hasConnectedFB,
    hasConnectedInstagram,
    hasConnectedTelegram,
    hasConnectedWhatsApp,
    hasConnectedSMS,
  ) => {
    const connectedChannels = []

    if (hasConnectedFB) {
      connectedChannels.push(ChannelType.FB)
    }
    if (hasConnectedInstagram) {
      connectedChannels.push(ChannelType.INSTAGRAM)
    }
    if (hasConnectedWhatsApp) {
      connectedChannels.push(ChannelType.WHATSAPP)
    }
    if (hasConnectedTelegram) {
      connectedChannels.push(ChannelType.TELEGRAM)
    }
    if (hasConnectedSMS) {
      connectedChannels.push(ChannelType.SMS)
    }

    return connectedChannels
  },
)

export const getLiveChatConnectedChannelsLinks = createSelector(
  [
    getLiveChatConnectedChannels,
    getCurrentAccount,
    getInstagramChannel,
    getTelegramChannel,
    getConnectedWhatsAppChannel,
  ],
  (connectedChannels, fbAccount, igAccount, tgAccount, waChannel) => {
    return connectedChannels.reduce((links, channel) => {
      switch (channel) {
        case ChannelType.FB: {
          links.push({
            type: ChannelType.FB,
            link: `https://m.me/${fbAccount.fb_channel.fb_page_id}`,
          })
          break
        }
        case ChannelType.INSTAGRAM: {
          links.push({
            type: ChannelType.INSTAGRAM,
            link: `https://www.instagram.com/${igAccount.username}`,
          })
          break
        }
        case ChannelType.WHATSAPP: {
          links.push({
            type: ChannelType.WHATSAPP,
            link: `https://wa.me/${waChannel?.country_code}${waChannel?.phone_number}`,
          })
          break
        }
        case ChannelType.TELEGRAM: {
          links.push({
            type: ChannelType.TELEGRAM,
            link: `https://t.me/${tgAccount.bot_username}`,
          })
          break
        }
      }

      return links
    }, [] as Array<{ type: ChannelType; link: string }>)
  },
)

export const getVoiceConversionPendingStates = (state: RootState) =>
  state.chat.threads.pendingVoiceConversionStates

export const getIsWAThreadOutside24HourWindow = (state: RootState) => {
  const currentThread = getCurrentThreadItem(state)
  return (
    (currentThread?.channels ?? []).find(
      (channel: LiveChatChannel) => channel.type === ChannelType.WHATSAPP,
    )?.status === ChannelStatusTypes.TIME_OUT
  )
}

import { IPost } from 'apps/posting/postingInterfaces'

export default function (post: IPost) {
  return {
    post_id: post.id,
    content_id: post.contentId,
    namespace: post.flowId,
    filter: post.filter,
    messaging_type: post.messagingType,
    notification: post.notification,
    schedule: post.schedule,
    timezone: post.timezone,
    message_tag: post.messageTag,
  }
}

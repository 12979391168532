import { z } from 'zod'

const FlagsSchema = z.record(z.string(), z.boolean())

export enum ExperimentGroupName {
  UPGRADE_PAGE_A = 'enrollExperimentUpgradeA',
  UPGRADE_PAGE_B = 'enrollExperimentUpgradeB',
  TRIAL_IV_A = 'enrollExperimentIvA',
  TRIAL_IV_B = 'enrollExperimentIvB',
  TIER_LIMIT_NOTIFICATION_A = 'enrollExperimentEnableNotification',
  TIER_LIMIT_NOTIFICATION_B = 'enrollExperimentDisableNotification',
}

export const experimentSchemas = {
  enrollExperiment: {
    query: z.undefined(),
    path: z.object({
      experimentGroupName: z.nativeEnum(ExperimentGroupName),
    }),
    request: z.undefined(),
    response: z.object({
      flags: FlagsSchema,
    }),
  },
}

import React from 'react'
import { useSearchParams } from 'react-router-dom'
import { useAppSelector } from 'reduxTyped'
import { l } from '@manychat/manyui'

import { setLangToCookie } from 'apps/locale/languageCookie'
import { getLoggedIn } from 'common/core/selectors/appSelectors'
import { useCommand } from 'utils/api/query/hooks/useCommand'
import { Body } from 'utils/api/types'
import { analyticsService } from 'utils/services/analytics'

export const useUserLanguage = () => {
  const isLoggedId = useAppSelector(getLoggedIn)
  const [routerQuery, setRouterQuery] = useSearchParams()
  const language = l.getLanguage()

  const { query, send } = useCommand<string, Body>('set-user-language', ({ api, arg }) =>
    api.post('/profile/setLanguage', { body: { language: arg } }),
  )

  const handleSetLanguage = (allowedLanguage: string) => {
    if (isLoggedId) {
      analyticsService.sendEvent('LANGUAGE.DROPDOWN.CHANGE_LANGUAGE', { language: allowedLanguage })
      send(allowedLanguage)
    } else {
      analyticsService.sendLoggedoutUserEvent(
        `LANGUAGE.DROPDOWN.CHANGE_LANGUAGE.${allowedLanguage}`,
      )
      setLangToCookie(allowedLanguage)

      if (routerQuery.get('lang')) {
        routerQuery.delete('lang')
        setRouterQuery(routerQuery, { replace: true })
      } else {
        window.location.reload()
      }
    }
  }

  React.useEffect(() => {
    query.status === 'success' && location.reload()
  }, [query.status])

  return {
    language,
    allowedLanguages: l.getAllowedLanguages(),
    setLanguage: handleSetLanguage,
    isLoading: query.status === 'loading',
    isEnglish: language === 'en-US',
  }
}

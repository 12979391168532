import React from 'react'
export const BuilderIdContext = React.createContext({ builderId: '' })

export class BuilderIdProvider extends React.Component {
  constructor(props) {
    super(props)

    this.state = { context: { builderId: props.builderId } }
  }

  componentDidUpdate = (prevProps) => {
    if (this.state.context.builderId !== this.props.builderId) {
      this.setState({ context: { builderId: this.props.builderId } })
    }
  }

  render() {
    const { children } = this.props
    return (
      <BuilderIdContext.Provider value={this.state.context}>{children}</BuilderIdContext.Provider>
    )
  }
}

// HOC, use like this: withBuilderId(connect(...))
export const withBuilderId = (Component) => {
  return (props) => (
    <BuilderIdContext.Consumer>
      {({ builderId }) => <Component builderId={builderId} {...props} />}
    </BuilderIdContext.Consumer>
  )
}

// Component for render method, use like this:
// 1. <WithBuilderId><INeedBuilderIdInProps/></WithBuilderId>
// or
// 2. <WithBuilderId>{({ builderId }) => (<INeedBuilderIdInProps builderId={builderId}/>)</WithBuilderId>
export const WithBuilderId = () => {
  return (props) => (
    <BuilderIdContext.Consumer>
      {({ builderId }) => {
        const { children } = props
        if (children == null) {
          return null
        }

        const childrenProps = { builderId }

        if (typeof children === 'function') {
          return children(childrenProps)
        }
        return React.cloneElement(children, childrenProps)
      }}
    </BuilderIdContext.Consumer>
  )
}

import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'

import { noop, pad } from '../../../../utils'
import { isValidTime } from '../../../../utils/date'

import DialBar from './DialBar'

import cm from './Time.module.css'

const HOURS = Array.apply(null, Array(24)).map((e, i) => ({ type: 'hour', value: pad(i) }))
const MINUTES = Array.apply(null, Array(60)).map((e, i) => ({ type: 'minute', value: pad(i) }))
const TYPE_INDEXES = {
  hour: 0,
  minute: 1,
}

const fixRange = (v, min, max) => {
  if (v < min) {
    return min
  }
  if (v > max) {
    return max
  }
  return v
}
export default class Time extends PureComponent {
  static propTypes = {
    name: PropTypes.string,
    onChange: PropTypes.func,
    value: PropTypes.string,
    minTime: PropTypes.string,
    maxTime: PropTypes.string,
  }

  static defaultProps = {
    onChange: noop,
  }

  getMin = () => (isValidTime(this.props.minTime) ? this.props.minTime.split(':') : ['00', '00'])
  getMax = () => (isValidTime(this.props.maxTime) ? this.props.maxTime.split(':') : ['23', '59'])

  handleChange = (type, data) => {
    const { name, value: propsValue, onChange } = this.props
    const time = isValidTime(propsValue) ? propsValue.split(':') : ['00', '00']
    time[TYPE_INDEXES[type]] = data

    const [minHour, minMinute] = this.getMin()
    const [maxHour, maxMinute] = this.getMax()
    time[0] = fixRange(time[0], minHour, maxHour)
    time[1] = fixRange(
      time[1],
      time[0] === minHour ? minMinute : '00',
      time[0] === maxHour ? maxMinute : '59',
    )

    const value = time.join(':')
    if (value !== propsValue) {
      onChange({ target: { value, name } })
    }
  }

  render() {
    const { value } = this.props
    const [hour, minute] = isValidTime(value) ? value.split(':') : ['', '']

    let hours = HOURS
    let minutes = MINUTES
    const [minHour, minMinute] = this.getMin()
    const [maxHour, maxMinute] = this.getMax()
    if (minHour > 0 || maxHour < 23) {
      hours = hours.filter((i) => i.value >= minHour && i.value <= maxHour)
    }
    if (minMinute > 0 || maxMinute < 59) {
      minutes = MINUTES.filter(
        (i) =>
          (hour !== minHour || i.value >= minMinute) && (hour !== maxHour || i.value <= maxMinute),
      )
    }

    return (
      <div className={cm.wrapper} style={{ height: '100%' }}>
        <React.Fragment>
          <DialBar type="hour" value={hour} items={hours} onChange={this.handleChange} />
          <DialBar type="minute" value={minute} items={minutes} onChange={this.handleChange} />
        </React.Fragment>
      </div>
    )
  }
}

import { FlowForSimulatorState } from 'apps/quickCampaigns/selectTemplateModal/store/flowForSimulatorSlice'
import { IBlock } from 'common/builder/blocks/blockInterfaces'
import { BuilderValue } from 'common/builder/builderInterfaces'
import { IButton } from 'common/builder/buttons/buttonInterfaces'
import batchAdapter from 'common/builder/models/Batch/adapter'
import { INode } from 'common/builder/nodes/nodeInterfaces'
import { IFullFlow } from 'common/flow/flowInterfaces'

export const prepareSimulatorData = (flowData?: FlowForSimulatorState['flow'] | IFullFlow) => {
  const { contents, root_content_id: rootContentId } = flowData || {}
  if (!contents || !rootContentId) return null

  const parsed = batchAdapter.parseBatch(
    { contents: [...contents], root_content: rootContentId },
    { botTimeZone: 'UTC' },
  )

  const nodes: BuilderValue['nodes'] = {
    byId: {},
  }
  parsed.nodes.forEach((node: INode) => {
    nodes.byId[node.id] = node
  })
  const blocks: BuilderValue['blocks'] = {
    byId: {},
  }
  parsed.blocks.forEach((block: IBlock) => {
    blocks.byId[block.id] = block
  })
  const buttons: BuilderValue['buttons'] = {
    byId: {},
  }
  parsed.buttons.forEach((button: IButton) => {
    buttons.byId[button.id] = button
  })

  return { nodes, blocks, buttons, state: { rootId: parsed.rootId } }
}

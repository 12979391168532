import assert from 'assert'

import isUndefined from 'lodash/isUndefined'

import { getUpdatedListMessageSections } from 'apps/whatsApp/helpers/getUpdatedListMessageSections'
import { updateBlock } from 'common/builder/actions/updateBlock'
import * as ActionTypes from 'common/builder/constants/builderReduxActionTypes'
import { ButtonType } from 'common/builder/constants/ButtonType'
import * as entitySelectors from 'common/builder/selectors/builder/entitySelectors'
import * as nextStepSelectors from 'common/builder/selectors/builder/nextStepSelectors'
import { analyticsService, flowBuilderEvents } from 'utils/services/analytics'
import errorTrackingService from 'utils/services/errorTrackingService'

import * as builderStateActions from './builderStateActions'

/**
 * @param {string} builderId
 * @param {string} sourceId
 * @param {string|null} targetId
 */
export function updateSourceTarget(builderId, sourceId, targetId) {
  return (dispatch, getState) => {
    assert(builderId, `updateSourceTarget: builderId is required param`)
    assert(sourceId, `updateSourceTarget: sourceId is required param`)
    assert(
      !isUndefined(targetId),
      `updateSourceTarget: "${targetId}" is invalid target param value`,
    )

    const state = getState()
    const source = entitySelectors.getById(state, builderId, sourceId)
    if (!source) {
      errorTrackingService.trackError(
        new Error('updateSourceTarget: source with provided sourceId does not exist'),
        {
          extra: {
            source,
            sourceId,
            builderId,
            targetId,
            builder: state.builders.byId[builderId],
          },
          tags: { area: 'builder', topic: "id doesn't exist" },
        },
      )
    }
    assert(source, `updateSourceTarget: source with id "${sourceId}" doesn't exist`)

    if (targetId) {
      const newTarget = entitySelectors.getNodeById(state, builderId, targetId)
      assert(newTarget, `updateSourceTarget: target with id "${targetId}" doesn't exist`)

      const isConnectionAllowed = nextStepSelectors.isConnectionAllowed(
        state,
        builderId,
        sourceId,
        targetId,
      )
      assert(isConnectionAllowed, `updateSourceTarget: connection not allowed`)
    }

    analyticsService.logFlowBuilderEvent(flowBuilderEvents.CONNECTOR_CREATED, { node: source })

    if (source.isStartingStep) {
      return dispatch(builderStateActions.setRoot(builderId, targetId))
    }

    if (source.type === ButtonType.WA_LIST_MESSAGE_BUTTON) {
      const block = entitySelectors.getParentBlock(state, builderId, source.id)
      const updatedSections = getUpdatedListMessageSections(block.sections, source.id, {
        _content_oid: targetId,
      })

      dispatch(
        updateBlock(builderId, block.id, {
          sections: updatedSections,
        }),
      )
    }

    return dispatch({
      type: ActionTypes.UPDATE_SOURCE_TARGET,
      builderId,
      sourceId,
      targetId,
    })
  }
}

/**
 * @param {string} builderId
 * @param {string} sourceId
 */
export function deleteSourceTarget(builderId, sourceId) {
  return updateSourceTarget(builderId, sourceId, null)
}

import React, { useRef, useState, useCallback } from 'react'
import cx from 'classnames'
import { useAppSelector, useAppDispatch } from 'reduxTyped'
import { BtnV2, Icon, l, Loader, Popover } from '@manychat/manyui'

import { useModalActions } from 'apps/cms/components/Flows/components/AutomationModals/hooks/useModalActions'
import { CmsAnalytics } from 'apps/cms/lib/analytics'
import { ViewDisplayMode } from 'apps/cms/lib/constants'
import sharedMenuStyles from 'apps/cms/lib/styles/menuSharedStyles.module.css'
import { contentManagementSelectors, contentManagementActions } from 'apps/cms/store'
import { AutomationModalType } from 'apps/cms/store/lib/types'

export const BulkActionsButton = React.memo(function BulkActionsButton() {
  const viewDisplayMode = useAppSelector(contentManagementSelectors.getViewDisplayMode)
  const selectedFlows = useAppSelector(contentManagementSelectors.getSelectedFlowIds)
  const isTrashFolder = useAppSelector(contentManagementSelectors.getIsTrashFolder)
  const isSearchMode = useAppSelector(contentManagementSelectors.getIsSearchMode)
  const flowsSelectedAmount = selectedFlows.length

  const buttonWrapperRef = useRef<HTMLSpanElement | null>(null)

  const [isMenuOpened, setIsMenuOpened] = useState(false)

  const dispatch = useAppDispatch()

  const openMenu = () => {
    setIsMenuOpened(true)
  }

  const closeMenu = useCallback(() => {
    setIsMenuOpened(false)
  }, [])

  const { openModal } = useModalActions()

  const handleOpenBulkDeleteModal = useCallback(() => {
    openModal({ type: AutomationModalType.BULK_DELETE, data: { isPermanent: isTrashFolder } })

    closeMenu()
  }, [closeMenu, isTrashFolder, openModal])

  const handleOpenBulkMoveToModal = useCallback(() => {
    openModal({ type: AutomationModalType.BULK_MOVE_TO, data: null })

    closeMenu()
  }, [closeMenu, openModal])

  const [isRestoring, setRestoring] = useState(false)
  const [isCopying, setIsCopying] = useState(false)

  const handleRestoreFlows = useCallback(async () => {
    if (isRestoring) {
      return
    }

    setRestoring(true)
    await dispatch(contentManagementActions.bulkRestoreFlows())
    CmsAnalytics.logBulkRestore({ count: flowsSelectedAmount })
    setRestoring(false)
    closeMenu()
  }, [closeMenu, dispatch, isRestoring, flowsSelectedAmount])

  const handleBulkCopyFlows = useCallback(async () => {
    if (isCopying) return

    setIsCopying(true)
    await dispatch(contentManagementActions.bulkClone())
    CmsAnalytics.logBulkClone({ count: flowsSelectedAmount })
    setIsCopying(false)
    closeMenu()
  }, [closeMenu, dispatch, isCopying, flowsSelectedAmount])

  return (
    <div className="d-flex align-center">
      {flowsSelectedAmount > 0 && viewDisplayMode === ViewDisplayMode.LIST && (
        <>
          <span ref={buttonWrapperRef}>
            <BtnV2 onClick={openMenu}>{l.translate('Bulk Actions')}</BtnV2>
          </span>

          <span className="d-inline-block m-l-sm">
            {l.translate('Selected Automations: {count}', {
              count: flowsSelectedAmount,
            })}
          </span>
        </>
      )}

      <Popover
        at="bottom"
        popoverOrigin="top left"
        anchorOrigin="bottom left"
        anchor={buttonWrapperRef.current}
        open={isMenuOpened}
        onRequestClose={closeMenu}
        className={sharedMenuStyles.popover}
      >
        <div className={sharedMenuStyles.contextActions}>
          {!isTrashFolder && !isSearchMode && (
            <button
              onClick={handleOpenBulkMoveToModal}
              className={cx(sharedMenuStyles.contextActionButton)}
            >
              <Icon.MoveTo className={sharedMenuStyles.contextActionButton__icon} />

              <span>{l.translate('Move to')}</span>
            </button>
          )}
          {!isTrashFolder && !isSearchMode && (
            <button
              onClick={handleBulkCopyFlows}
              className={cx(sharedMenuStyles.contextActionButton)}
            >
              {!isCopying && (
                <>
                  <Icon.Copy className={sharedMenuStyles.contextActionButton__icon} />

                  <span>{l.translate('Duplicate')}</span>
                </>
              )}
              {isCopying && <Loader size={24} />}
            </button>
          )}
          {isTrashFolder && (
            <button
              onClick={handleRestoreFlows}
              className={cx(
                sharedMenuStyles.contextActionButton,
                sharedMenuStyles.contextActionButton_theme_primary,
              )}
            >
              {!isRestoring && (
                <>
                  <Icon.OrphanRestore className={sharedMenuStyles.contextActionButton__icon} />
                  <span>{l.translate('Restore')}</span>
                </>
              )}
              {isRestoring && <Loader size={24} />}
            </button>
          )}

          <button
            onClick={handleOpenBulkDeleteModal}
            className={cx(
              sharedMenuStyles.contextActionButton,
              sharedMenuStyles.contextActionButton_theme_danger,
            )}
          >
            {isTrashFolder && (
              <Icon.DeleteForever className={sharedMenuStyles.contextActionButton__icon} />
            )}

            {!isTrashFolder && (
              <Icon.Delete className={sharedMenuStyles.contextActionButton__icon} />
            )}

            <span>{isTrashFolder ? l.translate('Permanently delete') : l.translate('Delete')}</span>
          </button>
        </div>
      </Popover>
    </div>
  )
})

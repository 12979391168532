import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { createAppAsyncThunk } from 'reduxTyped'

import { ALL_TRIGGERS_KEY } from 'apps/aiStepStats/lib/constants'
import { AiStepStatsState, NodeStatsState } from 'apps/aiStepStats/store/types'
import builderSelectors from 'common/builder/selectors/builder'
import * as flowSelectors from 'common/flow/selectors/flowSelectors'
import { handleCatch } from 'shared/api/lib/errors/handlers'
import { AiStepStatsApi } from 'shared/api/requests/aiStepStats'
import { AiStepNodeStats } from 'shared/api/requests/aiStepStats/schemas'

export const AiStepStatsInitialState: AiStepStatsState = {
  nodes: {},
  state: {},
}

const NAMESPACE = 'aiStepStats'

const { reducer, actions } = createSlice({
  initialState: AiStepStatsInitialState,
  name: NAMESPACE,
  reducers: {
    setData: (
      state,
      action: PayloadAction<{
        contentId: number
        contentHolder?: string
        data: AiStepNodeStats
      }>,
    ) => {
      state.nodes = {
        ...state.nodes,
        [action.payload.contentId]: {
          ...state.nodes[action.payload.contentId],
          [action.payload.contentHolder || ALL_TRIGGERS_KEY]: {
            data: action.payload.data,
            loaded: true,
          },
        },
      }
    },
    setState: (
      state,
      action: PayloadAction<{
        contentId: number
        contentHolder?: string
        state: NodeStatsState
      }>,
    ) => {
      state.state = {
        ...state.state,
        [action.payload.contentId]: {
          ...state.state[action.payload.contentId],
          [action.payload.contentHolder || ALL_TRIGGERS_KEY]: action.payload.state,
        },
      }
    },
  },
})

const downloadNodeStats = createAppAsyncThunk(
  `${NAMESPACE}/downloadNodeStats`,
  async (
    { builderId, contentId }: { builderId: string; contentId: number },
    { dispatch, getState },
  ) => {
    const state = getState()
    const builderState = builderSelectors.state.getById(state, builderId)
    const options = flowSelectors.getFlowContentHolderOptions(state, builderState.flow)
    const contentHolder = options.find((option) => option.selected)?.value || undefined

    dispatch(
      actions.setState({
        contentId,
        contentHolder: contentHolder,
        state: NodeStatsState.LOADING,
      }),
    )

    try {
      const { data } = await AiStepStatsApi.contentStat({
        query: {
          content_id: contentId,
          content_holder: contentHolder,
        },
      })

      dispatch(
        actions.setData({
          contentId,
          contentHolder,
          data,
        }),
      )
    } catch (error) {
      handleCatch(error)
    } finally {
      dispatch(
        actions.setState({
          contentId,
          contentHolder,
          state: NodeStatsState.LOADED,
        }),
      )
    }
  },
)

export const AiStepStatsActions = {
  downloadNodeStats,
}

const getState = (state: RootState) => state.aiStepStats

export const AiStepStatsSelectors = {
  getStats: (state: RootState, builderId: string, contentId: number) => {
    const builderState = builderSelectors.state.getById(state, builderId)
    const options = flowSelectors.getFlowContentHolderOptions(state, builderState.flow)
    const selectedOption = options.find((option) => option.selected)

    return getState(state).nodes[contentId]?.[selectedOption?.value || ALL_TRIGGERS_KEY]?.data
  },
  getNodeState: (state: RootState, builderId: string, contentId?: number) => {
    if (!contentId) {
      return NodeStatsState.EMPTY
    }

    const builderState = builderSelectors.state.getById(state, builderId)
    const options = flowSelectors.getFlowContentHolderOptions(state, builderState.flow)
    const selectedOption = options.find((option) => option.selected)

    return (
      getState(state).state[contentId]?.[selectedOption?.value || ALL_TRIGGERS_KEY] ||
      NodeStatsState.EMPTY
    )
  },
}

export const AiStepStatsReducer = reducer

import get from 'lodash/get'
import { createSelector } from 'reselect'

import { GROUP_LABELS_MAP, GROUPS, GroupId } from 'apps/templates/models/ContentMap/constants'
import { DEPRECATED_makeLimitedListSelectors } from 'utils/factory'

const generalElementsLabelMap = {
  wm: 'Welcome Message',
  mm: 'Messenger Main Menu',
  dr: 'Messenger Default Reply',
  gtext: 'Greeting Text',
  igdr: 'Instagram Default Reply',
  sm: 'Instagram Story Mention Reply',
  ib: 'Messenger Conversation Starters',
  igib: 'Instagram Conversation Starters',
  igmm: 'Instagram Main Menu',
}

export const getApps = (state) =>
  state.templates.installation?.template_data?.current_version?.map?.apps ?? []

export const getSignature = (state) => state.templates.installation.signature

export const getElements = (state) => {
  const elements = get(state, 'templates.installation.template_data.current_version.map.models', {})

  const items = []

  for (const group of GROUPS) {
    const groupElements = elements[group.id]

    if (groupElements) {
      if (group.id === GroupId.KEYWORDS) {
        const subItems = groupElements.map((si) => ({
          ...si,
        }))
        items.push({ label: GROUP_LABELS_MAP[group.id], subItems })
      } else {
        items.push({ label: GROUP_LABELS_MAP[group.id], subItems: groupElements })
      }
    }
  }

  return items
}

export const getGeneralElements = (state) => {
  return get(state, 'templates.installation.template_data.current_version.structure', []).filter(
    (e) => ['mm', 'dr', 'wm', 'gtext', 'igdr', 'sm', 'ib', 'igib', 'igmm'].includes(e),
  )
}

export const getTemplateAccessData = (state) => {
  const templateAccessData = state.templates.installation?.template_data?.template_access_data
  return {
    is_protected: templateAccessData?.is_protected || false,
    is_locked: templateAccessData?.is_locked || false,
    is_tracked: templateAccessData?.is_tracked || false,
    is_owner: templateAccessData?.is_owner || false,
  }
}

export const getElementsCount = (state) => {
  const elements = get(state, 'templates.installation.template_data.current_version.map.models', {})
  let count = 0

  for (const key in elements) {
    const subItems = elements[key]
    count += subItems.length
  }

  return count
}

export const getDescription = (state) => {
  let description = get(state, 'templates.installation.template_data.description', {})

  if (description == null) {
    description = {}
  }

  return description
}

export const getConflictingElements = (state) => {
  return getGeneralElements(state).map((e) => ({ entity: e, label: generalElementsLabelMap[e] }))
}

export const getCUFs = (state) => {
  return get(state, 'templates.installation.template_data.current_version.map.custom_fields', [])
}

export const getTags = (state) => {
  return get(state, 'templates.installation.template_data.current_version.map.tags', [])
}

export const getOTNs = (state) => {
  return get(state, 'templates.installation.template_data.current_version.map.otn_reasons', [])
}

export const getGAFs = (state) => {
  return get(state, 'templates.installation.template_data.current_version.map.global_fields') || []
}

export const getTemplateFields = (state) => ({
  tags: getTags(state),
  cufs: getCUFs(state),
  gafs: getGAFs(state),
  otns: getOTNs(state),
})

export const getTargetPageFieldsMap = (state) => ({
  customEventsMap: state.targetPage.customEventsMap,
  tagsMap: state.targetPage.tagsMap,
  cufsMap: state.targetPage.cufsMap,
  gafsMap: state.targetPage.gafsMap,
  otnsMap: state.targetPage.otnsMap,
})

export const getGeneralElementsConflicts = (state) =>
  state.templates.installation.generalElementsConflicts

export const hasIntegrations = (state) => {
  const integrations = get(
    state,
    'templates.installation.template_data.current_version.map.models.integrations',
    [],
  )

  return integrations instanceof Array && integrations.length > 0
}

export const installedTemplates = DEPRECATED_makeLimitedListSelectors('templates.installed')

export const getTemplateMap = (state) => {
  return get(state, 'templates.installation.template_data.current_version.map', {})
}

export const getSettings = (state) => get(state, 'templates.installation.settings', {})

export const getGAFsFromTargetPage = (state) => get(state, 'targetPage.gafsMap') || []

export const getUnmappedGAFs = createSelector(
  getGAFsFromTargetPage,
  getGAFs,
  (targetPageGAFs, templateGAFs) => {
    const keys = Object.keys(targetPageGAFs)
    let unmappedIDs = []

    keys.forEach((key) => {
      if (targetPageGAFs[key] === null) {
        unmappedIDs.push(Number(key))
      }
    })

    if (unmappedIDs.length) {
      return templateGAFs.filter((field) => unmappedIDs.includes(field.field_id))
    }
    return []
  },
)

export const getUserInfo = (state) => get(state, 'templates.installation.user_info', {})

export const getTemplateTitle = (state) =>
  get(state, 'templates.installation.template_data.title', null)

export const getTemplateId = (state) =>
  get(state, 'templates.installation.template_data.template_id', null)

export const isProVersionTemplate = (state) =>
  get(state, 'templates.installation.template_data.current_version.is_pro', false)

export const isAiVersionTemplate = (state) =>
  get(state, 'templates.installation.template_data.current_version.is_ai_addon', false)

export const getTemplateData = (state) => ({
  isPro: isProVersionTemplate(state),
  isAi: isAiVersionTemplate(state),
  templateName: getTemplateTitle(state),
  author: getUserInfo(state),
  isReady: Boolean(getTemplateId(state)),
})
export const getInstallationError = (state) => get(state, 'templates.installation.error')

export const isInstallingComplete = (state) => ({
  installingComplete: get(state, 'templates.installation.isInstallingComplete'),
  isInstalling: get(state, 'templates.installation.isInstalling'),
})

export const getInstallationId = (state) => get(state, 'templates.installation.installationId')

export const getOtnStatus = (state) => state.app?.currentAccount?.one_time_notify_status

export const getAccounts = (state) => state.app?.accounts

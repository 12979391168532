import React from 'react'

import Scrollbars from '../../Scrollbars'

import { PopoverContext } from './PopoverContext'

type Props = { maxHeight?: number | string; children?: React.ReactNode }

const Body = ({ children, maxHeight = '75vh' }: Props) => {
  const { fullscreen } = React.useContext(PopoverContext)

  if (fullscreen) return <div className="flex-grow-1 o-scroll">{children}</div>

  return (
    <Scrollbars autoHeight autoHeightMax={maxHeight}>
      {children}
    </Scrollbars>
  )
}

export default Body

import React from 'react'

export const usePageVisibilityState = (): DocumentVisibilityState => {
  const [visibilityState, setVisibilityState] = React.useState(document.visibilityState)
  const handleVisibilityStateChanged = React.useCallback(() => {
    setVisibilityState(document.visibilityState)
  }, [])

  React.useEffect(() => {
    if (document.visibilityState) {
      document.addEventListener('visibilitychange', handleVisibilityStateChanged)
    }

    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityStateChanged)
    }
  }, [handleVisibilityStateChanged])

  return visibilityState
}

import axios, { AxiosInstance } from 'axios'

import { createGetFactory } from 'shared/api/lib/methods/createGetFactory'
import { createPostFactory } from 'shared/api/lib/methods/createPostFactory'
import { isTestEnv } from 'utils/e2e'
import { isLocal } from 'utils/isLocal'

export const baseInstance = axios.create({
  baseURL: isLocal() || isTestEnv() ? '/api' : '',
  headers: {
    'Use-New-Error-Format': 'True',
    'X-Requested-With': 'XMLHttpRequest',
  },
})

export const accountInstance = axios.create({
  baseURL: isLocal() || isTestEnv() ? '/api/:currentAccountID' : '/:currentAccountID',
  headers: {
    'Use-New-Error-Format': 'True',
    'X-Requested-With': 'XMLHttpRequest',
  },
})

const createApi = (instance: AxiosInstance) => ({
  createGet: createGetFactory(instance),
  createPost: createPostFactory(instance),
})

export const api = {
  base: createApi(baseInstance),
  account: createApi(accountInstance),
}

import React from 'react'
import sortBy from 'lodash/sortBy'

import FBPolicyEnforcementNotification from 'common/core/components/NotifyBar/FaceBookNotifications/FBPolicyEnforcementNotification/FBPolicyEnforcementNotification'
import {
  NotificationItem,
  NotificationRemoveType,
  WhatsAppNotification,
  FacebookNotification,
  EmailNotification,
  FacebookPolicyNotification,
  NotificationDirtyItem,
  BackNotificationEventType,
  InstagramNotification,
  TiktokNotification,
} from 'common/core/components/NotifyBar/NotifyInterfaces'
import { ChannelType } from 'common/core/constants/ChannelType'
import { NotificationBarType } from 'common/core/constants/NotificationBarType'

import { parseEmailNotification } from '../EmailNotifications/parser'
import { parseFBNotification } from '../FaceBookNotifications/parser'
import { parseInstagramNotification } from '../InstagramNotifications/parser'
import { parseTiktokNotification } from '../TiktokNotifications/parser'
import { parseWANotification } from '../WhatsAppNotifications/parser'

export const parseNotification = (notification: NotificationDirtyItem): NotificationItem => {
  if (isFbPolicyNotification(notification)) {
    return {
      ...notification,
      type: NotificationBarType.DEFAULT,
      removeType: NotificationRemoveType.ALWAYS,
      isWithoutReadMore: true,
      render: () => <FBPolicyEnforcementNotification notification={notification} />,
    }
  }

  if (isEmailNotification(notification, ChannelType.EMAIL)) {
    return parseEmailNotification(notification)
  }

  if (isFacebookNotification(notification, ChannelType.FB)) {
    return parseFBNotification(notification)
  }

  if (isWhatsAppNotification(notification, ChannelType.WHATSAPP)) {
    return parseWANotification(notification)
  }

  if (isInstagramNotification(notification, ChannelType.INSTAGRAM)) {
    return parseInstagramNotification(notification)
  }

  if (isTiktokNotification(notification, ChannelType.TIKTOK)) {
    return parseTiktokNotification(notification)
  }

  return {
    ...notification,
    removeType: NotificationRemoveType.ALWAYS,
  }
}

export const sortNotifications = (notifications: NotificationItem[]) => {
  const alwaysOnTopNotifications = notifications.filter(
    (notification) => notification.isAlwaysOnTop,
  )
  const restNotifications = notifications.filter((notification) => !notification.isAlwaysOnTop)

  const permanentNotifications = restNotifications.filter(
    (n) => n.removeType === NotificationRemoveType.NEVER,
  )
  const removableNotifications = restNotifications.filter(
    (n) => n.removeType !== NotificationRemoveType.NEVER,
  )
  const successRemovableNotifications = removableNotifications.filter(
    (n) => n.type === NotificationBarType.SUCCESS,
  )
  const nonSuccessRemovableNotifications = removableNotifications.filter(
    (n) => n.type !== NotificationBarType.SUCCESS,
  )

  return [
    ...sortBy(alwaysOnTopNotifications, 'ts_created'),
    ...permanentNotifications,
    ...sortBy(nonSuccessRemovableNotifications, 'ts_created'),
    ...sortBy(successRemovableNotifications, 'ts_created'),
  ]
}

function isFbPolicyNotification(
  notification: NotificationDirtyItem,
): notification is FacebookPolicyNotification {
  return (
    'data' in notification &&
    Boolean(notification.data) &&
    'fb_policy_enforcement' in notification.data
  )
}

function isWhatsAppNotification(
  notification: NotificationDirtyItem,
  type: ChannelType,
): notification is WhatsAppNotification {
  return isTypedNotification(notification, type)
}

function isFacebookNotification(
  notification: NotificationDirtyItem,
  type: ChannelType,
): notification is FacebookNotification {
  return isTypedNotification(notification, type)
}

function isEmailNotification(
  notification: NotificationDirtyItem,
  type: ChannelType,
): notification is EmailNotification {
  return isTypedNotification(notification, type)
}

function isInstagramNotification(
  notification: NotificationDirtyItem,
  type: ChannelType,
): notification is InstagramNotification {
  return isTypedNotification(notification, type)
}

function isTiktokNotification(
  notification: NotificationDirtyItem,
  type: ChannelType,
): notification is TiktokNotification {
  return isTypedNotification(notification, type)
}

function isTypedNotification(
  notification: NotificationDirtyItem,
  type: ChannelType,
): notification is EmailNotification | FacebookNotification | WhatsAppNotification {
  return (
    'data' in notification &&
    Boolean(notification.data) &&
    'event' in notification.data &&
    notification.data.event === BackNotificationEventType.TYPED_MESSAGE &&
    'message' in notification &&
    Boolean(notification.message) &&
    notification.message.startsWith(`${type}_`)
  )
}

import React, { HTMLAttributes } from 'react'
import cx from 'classnames'

import List from '../../List'

import { BasicSelectOption, SelectOptionProps } from './interfaces'

import cm from './Select.module.css'
export const SelectOption = <OptionType extends BasicSelectOption>({
  option,
  focused,
  selected,
  renderOption,
  handleSelect,
  setFocusedId,
}: SelectOptionProps<OptionType>) => {
  const optionProps: HTMLAttributes<HTMLElement> = {
    onMouseEnter: () => setFocusedId(option.id),
    onMouseLeave: () => setFocusedId(undefined),
    onClick: () => handleSelect(option),
    role: 'menuitem',
    className: cx(cm.listItem, focused && !selected && cm.focused),
  }

  if (renderOption) {
    return renderOption(option, { focused, selected }, optionProps)
  }
  if (option.type === 'group') {
    return <List.GroupHeading key={option.label} {...option} />
  }

  return (
    <List.Item
      {...option}
      // current state is managed via aria-activedescendant attribute, so only apply visual styles aplied to list item, but the focus remains on parent element
      selected={selected}
      {...optionProps}
    />
  )
}

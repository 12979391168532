export const makeTranslate = (translationsList: Record<string, string>[]) => {
  const translationMaps = translationsList.map((t) => new Map(Object.entries(t)))

  return (key: string): string => {
    for (const translations of translationMaps) {
      const translation = translations.get(key)
      if (translation) return translation
    }
    return key
  }
}

export const ACCOUNT_NAME_UPDATE = 'ACCOUNT_NAME_UPDATE'
export const ACCOUNT_FAVORITE = 'ACCOUNT_FAVORITE'
export const ACCOUNT_FAVORITE_RESPONSE = 'ACCOUNT_FAVORITE_RESPONSE'
export const ACCOUNT_HIDE = 'ACCOUNT_HIDE'
export const ACCOUNT_HIDE_RESPONSE = 'ACCOUNT_HIDE_RESPONSE'

export const FETCH_ALL_ACCOUNTS = 'FETCH_ALL_ACCOUNTS'
export const FETCH_ALL_ACCOUNTS_REQUEST = 'FETCH_ALL_ACCOUNTS_REQUEST'
export const FETCH_ALL_ACCOUNTS_RESPONSE = 'FETCH_ALL_ACCOUNTS_RESPONSE'
export const FETCH_ALL_ACCOUNTS_ERROR = 'FETCH_ALL_ACCOUNTS_ERROR'

export const FETCH_ACCOUNT = 'FETCH_ACCOUNT'
export const FETCH_ACCOUNT_REQUEST = 'FETCH_ACCOUNT_REQUEST'
export const FETCH_ACCOUNT_RESPONSE = 'FETCH_ACCOUNT_RESPONSE'
export const FETCH_ACCOUNT_ERROR = 'FETCH_ACCOUNT_ERROR'

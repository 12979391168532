import { AutoPositionType } from '../constants'

const ALLOW_FLIP = [AutoPositionType.AUTO, AutoPositionType.FLIP]
const ALLOW_SHIFT = [AutoPositionType.AUTO, AutoPositionType.SHIFT]
const KEEP_AT_SCREEN = [AutoPositionType.KEEP_AT_SCREEN]
const DEFAULT_AUTO_POSITION = { x: AutoPositionType.AUTO, y: AutoPositionType.AUTO }

const convertToPixels = (value, units = 'px', basis = 0) => {
  switch (units) {
    case 'px':
      return value
    case '%':
      return basis * (value / 100)
    default:
      throw new Error('unknown units')
  }
}

const getOffset = (origin, rect) => ({
  x: convertToPixels(origin.x, origin.xUnits, rect.width),
  y: convertToPixels(origin.y, origin.yUnits, rect.height),
})

const applyOffset = (rect, offset) => ({
  x: (rect.left || 0) + offset.x,
  y: (rect.top || 0) + offset.y,
})

const flipOffset = (rect, offset) => ({ x: rect.width - offset.x, y: rect.height - offset.y })

const getAxisPoint = (axis, anchorOffset, anchorRect, popoverOffset, popoverRect, options) => {
  const { autoPosition = DEFAULT_AUTO_POSITION, thresholds = {} } = options
  const axisAutoPosition = autoPosition[axis]
  const range = thresholds[axis]
  const dimension = axis === 'x' ? 'width' : 'height'

  const anchorPoint = applyOffset(anchorRect, anchorOffset)
  const popoverPoint = applyOffset({ left: 0, top: 0 }, popoverOffset)

  const c1 = anchorPoint[axis] - popoverPoint[axis]
  const c2 = c1 + popoverRect[dimension]
  const res = { value: c1, position: null }

  const disableAutoPosition = !range || axisAutoPosition === 'none'

  if (disableAutoPosition) {
    return res
  }

  if (KEEP_AT_SCREEN.includes(axisAutoPosition)) {
    const screenOffset = 5
    const minPoint = range[0] + screenOffset
    const maxPoint =
      range[1] - screenOffset - (axis === 'x' ? popoverRect.width : popoverRect.height)
    const keepedC1 = Math.min(maxPoint, Math.max(minPoint, c1))

    return { value: keepedC1, position: AutoPositionType.KEEP_AT_SCREEN }
  }

  // Experiment - using anchor position as limit when anchor partially off-screen
  // Fallback to range values used for unit tests
  const min = Math.min(range[0], anchorRect[axis === 'x' ? 'left' : 'top']) || range[0]
  const max = Math.max(range[1], anchorRect[axis === 'x' ? 'right' : 'bottom']) || range[1]

  if (c1 >= min && c2 <= max) {
    return res
  }

  // Flip
  if (ALLOW_FLIP.includes(axisAutoPosition)) {
    const flippedAnchorOffset = flipOffset(anchorRect, anchorOffset)
    const flippedAnchorPoint = applyOffset(anchorRect, flippedAnchorOffset)
    const flippedPopoverPoint = applyOffset(
      { left: 0, top: 0 },
      flipOffset(popoverRect, popoverOffset),
    )
    const flippedC1 = flippedAnchorPoint[axis] - flippedPopoverPoint[axis]
    const flippedC2 = flippedC1 + popoverRect[dimension]

    if (flippedC1 >= min && flippedC2 <= max) {
      return {
        value: flippedC1,
        position: AutoPositionType.FLIP,
        anchorOrigin: `${flippedAnchorOffset.x}px ${flippedAnchorOffset.y}px`,
      }
    }
  }

  // Shift
  if (ALLOW_SHIFT.includes(axisAutoPosition)) {
    if (c2 > max) {
      const shiftedC1 = c1 - (c2 - max)
      return { value: shiftedC1, position: AutoPositionType.SHIFT }
    }
    if (c1 < min) {
      const shiftedC1 = c1 + (min - c1)
      return { value: shiftedC1, position: AutoPositionType.SHIFT }
    }
  }

  return res
}

/**
 * Parsed Origin Point
 * @typedef {object} origin
 * @property {number} x - x coordinate
 * @property {string} xUnits - one of: %, px
 * @property {number} y - y coordinate
 * @property {string} yUnits - one of: %, px
 */

/**
 * Calculates popover 'x' and 'y' coordinates
 * @param {origin} anchorOrigin
 * @param {*} anchorRect
 * @param {origin} popoverOrigin
 * @param {*} popoverRect
 * @param {*} options
 */
export const getPopoverPosition = (
  anchorOrigin,
  anchorRect,
  popoverOrigin,
  popoverRect,
  options = {},
) => {
  const anchorOffset = getOffset(anchorOrigin, anchorRect)
  const popoverOffset = getOffset(popoverOrigin, popoverRect)

  const x = getAxisPoint('x', anchorOffset, anchorRect, popoverOffset, popoverRect, options)
  const y = getAxisPoint('y', anchorOffset, anchorRect, popoverOffset, popoverRect, options)

  return { x, y }
}

import { createSlice, isAnyOf } from '@reduxjs/toolkit'
import { l } from '@manychat/manyui'

import { clone } from 'utils'
import { UpgradeSource, isUpgradeSource } from 'common/billing/interfaces'

import { manageSubscription, upgrade } from '../actions/upgradeActions'
import { cancelUpgradeRequest, createUpgradeRequest } from '../actions/upgradeRequestActions'
import { UpgradeRequest } from '../billingInterfaces'

const initialState: Readonly<UpgradeRequest> = {
  isOpen: false,
  title: l.translate('Smarter Automations. Better Conversations.'),
  source: UpgradeSource.NONE,
  products: [],
  addOns: [],
  customProps: {},
  variant: undefined,
  uuid: '',
  paywall: null,
}

export const upgradeRequestSlice = createSlice({
  name: 'upgradeRequest',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addMatcher(createUpgradeRequest.match, (_, action) => {
      const nextState = clone(initialState)

      const source = isUpgradeSource(action.payload.source)
        ? action.payload.source
        : UpgradeSource.NONE

      Object.assign(nextState, action.payload)

      nextState.title = action.payload.title || nextState.title
      nextState.source = source
      nextState.isOpen = true

      return nextState
    })

    builder.addMatcher(
      isAnyOf(
        manageSubscription.fulfilled.match,
        upgrade.fulfilled.match,
        cancelUpgradeRequest.match,
      ),
      (state) => {
        Object.assign(state, initialState)
      },
    )
  },
})

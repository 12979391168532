import { l } from '@manychat/manyui'

import { IceBreakerChannelType } from 'apps/iceBreakers/constants/constants'
import { getIceBreakersConfig } from 'apps/iceBreakers/iceBreakersConfig'
import { iceBreakersSortComparator } from 'apps/iceBreakers/models/helpers'
import { ChannelType } from 'common/core/constants/ChannelType'
import { isWhatsAppChannelConnected } from 'common/core/selectors/channelSelectors'
import { FlowStatus } from 'common/flow/flowConstants'
import { IceBreakerStatus } from 'shared/api/requests/iceBreakers/schemas'

import {
  getInitialIceBreakersChannelState,
  ICE_BREAKER_QUESTION_MAX_LENGTH,
  IceBreakerError,
  IceBreakersChannelState,
  IceBreakersPartialRootState,
  MappedIceBreaker,
} from '../constants/constants'

export const getByChannel =
  (
    channel: IceBreakerChannelType,
  ): ((state: IceBreakersPartialRootState) => IceBreakersChannelState) =>
  (state: IceBreakersPartialRootState) =>
    state.iceBreakers[channel] || getInitialIceBreakersChannelState(channel)

export const getItems =
  (channel: IceBreakerChannelType) => (state: IceBreakersPartialRootState) => {
    return getByChannel(channel)(state).items
  }

// Returns items with deleted flows as active items
export const getActiveItems =
  (channel: IceBreakerChannelType) => (state: IceBreakersPartialRootState) => {
    return (
      getItems(channel)(state)?.filter(
        (item) =>
          item.status === IceBreakerStatus.ACTIVE || item.status === IceBreakerStatus.PUBLISHED,
      ) || []
    )
  }

// Returns active items with active flows connected
export const getActiveItemsByChannel =
  (channel: IceBreakerChannelType) => (state: IceBreakersPartialRootState) => {
    return (
      getActiveItems(channel)(state)?.filter(
        (item: MappedIceBreaker) => item.flow?.status === FlowStatus.ACTIVE,
      ) || []
    )
  }

export const getById =
  (channel: IceBreakerChannelType, id: string) => (state: IceBreakersPartialRootState) => {
    return getItems(channel)(state).find((item) => item.id === id)
  }

export const getFetching =
  (channel: IceBreakerChannelType) => (state: IceBreakersPartialRootState) => {
    return getByChannel(channel)(state).fetching
  }

export const getFetched =
  (channel: IceBreakerChannelType) => (state: IceBreakersPartialRootState) => {
    return getByChannel(channel)(state).fetched
  }

export const getPublishing =
  (channel: IceBreakerChannelType) => (state: IceBreakersPartialRootState) => {
    return Boolean(getByChannel(channel)(state).publishing)
  }

export const getIsPublishAvailable =
  (channel: IceBreakerChannelType) => (state: IceBreakersPartialRootState) => {
    const { items, publishing, publishedItems } = getByChannel(channel)(state)
    if (!getFetched(channel)(state)) {
      return false
    }
    if (publishing) {
      return false
    }
    if (publishedItems === items) {
      return false
    }
    if (publishedItems === null && items.length === 0) {
      return false
    }
    return true
  }

export const getSwitching =
  (channel: IceBreakerChannelType) => (state: IceBreakersPartialRootState) => {
    return getByChannel(channel)(state).switching
  }

export const getIsEnabled = (state: Pick<RootState, 'app'>): boolean => {
  return state.app.currentAccount.fb_channel?.icebreakers_enabled
}

export const getIsInstagramEnabled = (state: Pick<RootState, 'app'>): boolean => {
  return state.app.currentAccount.instagram_channel?.icebreakers_instagram_enabled
}

export const getIsWhatsAppEnabled = (state: Pick<RootState, 'app'>): boolean => {
  if (!isWhatsAppChannelConnected(state.app.currentAccount.whatsapp_channel)) return false
  return state.app.currentAccount.whatsapp_channel.icebreakers_whatsapp_enabled ?? false
}

export const getSortedIceBreakersItems =
  (channel: IceBreakerChannelType) => (state: IceBreakersPartialRootState) => {
    const items = getActiveItems(channel)(state)
    return items?.slice().sort(iceBreakersSortComparator)
  }

export const getIceBreakerErrors =
  (channel: IceBreakerChannelType, id: string) =>
  (state: IceBreakersPartialRootState): IceBreakerError[] => {
    const item = getById(channel, id)(state)

    const errors = []

    if (!item?.caption.trim()) {
      errors.push({
        id,
        message: l.makeTranslate('Please enter the question title'),
      })
    }

    if (
      item?.caption &&
      item.caption.length > ICE_BREAKER_QUESTION_MAX_LENGTH &&
      [ChannelType.INSTAGRAM, ChannelType.WHATSAPP].includes(channel)
    ) {
      errors.push({
        id,
        message: l.makeTranslate('The question title cannot exceed 80 characters'),
      })
    }

    if (!item?.flow?.id) {
      errors.push({
        id,
        message: l.makeTranslate('Please specify a reply message for the question'),
      })
    }

    return errors
  }

export const getIceBreakersFirstErrors =
  (channel: IceBreakerChannelType) => (state: IceBreakersPartialRootState) => {
    const items = getActiveItems(channel)(state)
    const firstErrors = []
    for (const item of items) {
      const errors = getIceBreakerErrors(channel, item.id)(state)
      if (errors.length > 0) {
        firstErrors.push(errors[0])
      }
    }
    return firstErrors
  }

export const getIsIceBreakerValidationCompleted =
  (channel: IceBreakerChannelType) => (state: IceBreakersPartialRootState) => {
    return Boolean(getByChannel(channel)(state).validationCompleted)
  }

export const getIsIceBreakerWithError =
  (channel: IceBreakerChannelType, id: string) =>
  (state: IceBreakersPartialRootState): boolean => {
    return getIceBreakerErrors(channel, id)(state).some((error) => error.id === id)
  }

export const getIsValidationErrorVisible =
  (channel: IceBreakerChannelType, id: string) =>
  (state: IceBreakersPartialRootState): boolean =>
    getIsIceBreakerValidationCompleted(channel)(state) &&
    getIsIceBreakerWithError(channel, id)(state) &&
    getIsValidated(channel, id)(state)

export const getIceBreakerValidationErrors =
  (channel: IceBreakerChannelType, id: string) => (state: IceBreakersPartialRootState) => {
    const errors: Array<IceBreakerError & { type?: string }> =
      getIceBreakerErrors(channel, id)(state) || []
    if (errors.length > 0) {
      return [{ ...errors[0], type: 'text' }]
    }

    return errors
  }

const isIceBreakersContainsToChannel =
  (channel: IceBreakerChannelType) => (state: IceBreakersPartialRootState) => {
    return getItems(channel)(state).some((item) => item.channel === channel)
  }

export const getChannelItemsFetched =
  (channel: IceBreakerChannelType) => (state: IceBreakersPartialRootState) => {
    return getFetched(channel)(state) && isIceBreakersContainsToChannel(channel)(state)
  }

export const getIceBreakerIsEnabledSelector = (channel: IceBreakerChannelType) => {
  const iceBreakersConfig = getIceBreakersConfig(channel)
  return iceBreakersConfig.isEnabled
}

const getIsValidated =
  (channel: IceBreakerChannelType, id: string) => (state: IceBreakersPartialRootState) =>
    getByChannel(channel)(state).items.some((item) => item.id === id && item.isValidated)

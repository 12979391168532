import { IAppleProfile, IFBProfile, IGoogleProfile } from './profileInterfaces'

type Callback<T> = (state: RootState) => T | undefined
export type Profile<T> = Partial<T> & { hasProfile: boolean }

const getProfile =
  <T>(cb: Callback<T>) =>
  (state: RootState): Profile<T> => {
    const profile = cb(state) || ({} as Partial<T>)
    return {
      hasProfile: Object.keys(profile).length > 0,
      ...profile,
    }
  }

export const getFBProfile = getProfile<IFBProfile>((state: RootState) => state.app.fbProfile)

export const getGoogleProfile = getProfile<IGoogleProfile>(
  (state: RootState) => state.app.googleProfile,
)

export const getAppleProfile = getProfile<IAppleProfile>(
  (state: RootState) => state.app.appleProfile,
)

export const getTelegramProfile = getProfile((state) => state.app.telegramProfile)

export const getIsUserAdsEnabled = (state: RootState): boolean =>
  getFBProfile(state)?.is_ads_enabled ?? false

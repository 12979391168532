import {
  IWidget,
  BackendWidgetData,
  ClickToWhatsAppTriggerWidget,
  ClickToWhatsAppTriggerWidgetData,
  IAdsJSONWidget,
  AdsWidget,
  FeedCommentTriggerWidget,
  IFeedCommentWidget,
  IMessengerRefURLWidget,
  IMessengerCodeWidget,
  IFacebookShopWidget,
  IInstagramStoryReplyTriggerWidget,
  IInstagramLiveStoryTriggerWidget,
} from 'apps/growthTools/growthToolsInterfaces'

import { WidgetTypes } from './constants'

interface BackendWidgetDataType {
  [WidgetTypes.BUTTON]: BackendWidgetData
  [WidgetTypes.MESSENGER_REF_URL]: BackendWidgetData
  [WidgetTypes.ADS_JSON]: BackendWidgetData
  [WidgetTypes.ADS]: BackendWidgetData
  [WidgetTypes.FEED_COMMENT]: BackendWidgetData
  [WidgetTypes.FEED_COMMENT_TRIGGER]: BackendWidgetData
  [WidgetTypes.MESSENGER_CODE]: BackendWidgetData
  [WidgetTypes.FACEBOOK_SHOP]: BackendWidgetData
  [WidgetTypes.INSTAGRAM_STORY_REPLY]: BackendWidgetData
  [WidgetTypes.INSTAGRAM_LIVE_COMMENT_REPLY]: BackendWidgetData
  [WidgetTypes.CTWA_TRIGGER]: ClickToWhatsAppTriggerWidgetData
}

interface NormalizedWidgetDataType {
  [WidgetTypes.BUTTON]: IWidget
  [WidgetTypes.MESSENGER_REF_URL]: IMessengerRefURLWidget
  [WidgetTypes.ADS_JSON]: IAdsJSONWidget
  [WidgetTypes.ADS]: AdsWidget
  [WidgetTypes.FEED_COMMENT]: IFeedCommentWidget
  [WidgetTypes.FEED_COMMENT_TRIGGER]: FeedCommentTriggerWidget
  [WidgetTypes.MESSENGER_CODE]: IMessengerCodeWidget
  [WidgetTypes.FACEBOOK_SHOP]: IFacebookShopWidget
  [WidgetTypes.INSTAGRAM_STORY_REPLY]: IInstagramStoryReplyTriggerWidget
  [WidgetTypes.INSTAGRAM_LIVE_COMMENT_REPLY]: IInstagramLiveStoryTriggerWidget
  [WidgetTypes.CTWA_TRIGGER]: ClickToWhatsAppTriggerWidget
}

type WidgetDataInitializer<T extends WidgetTypes> = (
  widgetData: BackendWidgetDataType[T],
  widget: NormalizedWidgetDataType[T],
) => IWidget

type WidgetDataInitializers = {
  [K in WidgetTypes]: WidgetDataInitializer<K>
}

const defaultWidgetDataInitializer = <T extends WidgetTypes>(
  _widgetData: BackendWidgetDataType[T],
  widget: NormalizedWidgetDataType[T],
): IWidget => widget

const clickToWhatsAppWidgetDataInitializer: WidgetDataInitializer<WidgetTypes.CTWA_TRIGGER> = (
  widgetData,
  widget,
) => {
  if (widgetData?.ad_account) {
    widget.data.selected_ad = widgetData?.ad
    widget.data.manual_ad_id = null
    widget.data.saved_data = {
      ad_account: widgetData?.ad_account,
      selected_ad: widgetData?.ad,
      manual_ad_id: null,
      is_manual_input: false,
    }
  } else if (widgetData?.is_manual_input) {
    widget.data.manual_ad_id = widgetData?.ad
    widget.data.selected_ad = { id: null }
    widget.data.saved_data = {
      ad_account: null,
      selected_ad: { id: null },
      manual_ad_id: widgetData?.ad,
      is_manual_input: true,
    }
  }

  return widget
}

const widgetDataInitializers: WidgetDataInitializers = {
  [WidgetTypes.BUTTON]: defaultWidgetDataInitializer,
  [WidgetTypes.MESSENGER_REF_URL]: defaultWidgetDataInitializer,
  [WidgetTypes.ADS_JSON]: defaultWidgetDataInitializer,
  [WidgetTypes.ADS]: defaultWidgetDataInitializer,
  [WidgetTypes.FEED_COMMENT]: defaultWidgetDataInitializer,
  [WidgetTypes.FEED_COMMENT_TRIGGER]: defaultWidgetDataInitializer,
  [WidgetTypes.MESSENGER_CODE]: defaultWidgetDataInitializer,
  [WidgetTypes.FACEBOOK_SHOP]: defaultWidgetDataInitializer,
  [WidgetTypes.INSTAGRAM_STORY_REPLY]: defaultWidgetDataInitializer,
  [WidgetTypes.INSTAGRAM_LIVE_COMMENT_REPLY]: defaultWidgetDataInitializer,
  [WidgetTypes.CTWA_TRIGGER]: clickToWhatsAppWidgetDataInitializer,
}

export const getWidgetDataInitializer = <T extends WidgetTypes>(
  widgetType: T,
): WidgetDataInitializer<T> => widgetDataInitializers?.[widgetType] || defaultWidgetDataInitializer

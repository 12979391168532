import React, { useCallback } from 'react'
import { useDrop } from 'react-dnd'
import { useAppDispatch, useAppSelector } from 'reduxTyped'

import { useMoveActions } from 'apps/cms/hooks/useMoveActions'
import { CmsDragSource, FlowDragItem, FolderDragItem } from 'apps/cms/lib/types'
import { contentManagementSelectors, contentManagementActions } from 'apps/cms/store'
import { CustomizableBreadcrumbsFolderButton } from 'components/Breadcrumbs/components/CustomizableBreadcrumbsFolderButton/CustomizableBreadcrumbsFolderButton'

import { CmsBreadcrumbsSegment } from '../../lib/types'

interface BreadcrumbsMenuButtonProps {
  segment: CmsBreadcrumbsSegment
  onClick: (event: React.MouseEvent<HTMLButtonElement>, segment: CmsBreadcrumbsSegment) => void
  showIcon: boolean
}

export const BreadcrumbsMenuButton = ({
  segment,
  onClick,
  showIcon,
}: BreadcrumbsMenuButtonProps) => {
  const dispatch = useAppDispatch()

  const selectedFlowIds = useAppSelector(contentManagementSelectors.getSelectedFlowIds)

  const { moveFolderToTargetFolder, moveFlowsToTargetFolder } = useMoveActions({
    targetFolderPath: segment.fsQueryPath,
  })

  const [{ isDragging }, connectDropTarget] = useDrop({
    accept: [CmsDragSource.FLOW_CARD, CmsDragSource.FOLDER_CARD],
    collect: (monitor) => ({
      isDragging: monitor.isOver(),
    }),
    drop: (item: FlowDragItem | FolderDragItem) => {
      if (item.type === CmsDragSource.FOLDER_CARD) {
        moveFolderToTargetFolder(item.path)
        return
      }

      if (selectedFlowIds.length > 1) {
        moveFlowsToTargetFolder(selectedFlowIds)
      } else {
        moveFlowsToTargetFolder([item.path])
      }
    },
  })

  const openFolder = useCallback(
    (event: React.MouseEvent<HTMLButtonElement>, segment: CmsBreadcrumbsSegment) => {
      if (event.ctrlKey || event.metaKey) {
        window.open(segment.path)
      } else {
        dispatch(
          contentManagementActions.updateQuery({
            path: segment.fsQueryPath,
          }),
        )
      }

      onClick(event, segment)
    },
    [dispatch, onClick],
  )

  return (
    <CustomizableBreadcrumbsFolderButton
      segment={segment}
      showIcon={showIcon}
      onClick={openFolder}
      buttonRef={connectDropTarget}
      highlight={isDragging}
    />
  )
}

import { IInstagramNode } from 'common/builder/nodes/nodeInterfaces'

import { FBMessagingType } from './facebookMessaging'

export const CommentReplyIgNodeProperties: Partial<IInstagramNode> = {
  $fbMessagingType: FBMessagingType.COMMENT_REPLY,
  privateReply: 'private_reply',
}

export const Within24IgNodeProperties: Partial<IInstagramNode> = {
  $fbMessagingType: FBMessagingType.INSIDE_24_HOURS,
  privateReply: null,
}

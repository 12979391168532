import { l } from '@manychat/manyui'

import { contentManagementActions } from 'apps/cms/store'
import { alert } from 'common/core'
import { ChannelType } from 'common/core/constants/ChannelType'
import { IAsyncThunkAction } from 'common/core/interfaces/actions'
import { handleCatch } from 'shared/api/lib/errors/handlers'
import { WelcomeMessageApi } from 'shared/api/requests/welcomeMessage'
import { WelcomeMessage } from 'shared/api/requests/welcomeMessage/schemas'
import { accountNotificationsListener } from 'utils/services/notificationsService'
import { INotificationServiceData } from 'utils/services/notificationsService/notificationsServiceTypes'

import welcomeMessageAdapter from '../models/welcomeMessageAdapter'
import { IWelcomeMessageTrigger } from '../welcomeMessageInterfaces'
import * as atypes from '../welcomeMessageReduxActionTypes'

export const loadWelcomeMessage = (channel: ChannelType = ChannelType.FB): IAsyncThunkAction => {
  return async (dispatch) => {
    dispatch({ type: atypes.WELCOME_MESSAGE_LOAD_REQUEST, channel })

    try {
      const response = await WelcomeMessageApi.getData({ query: { channel } })

      dispatch({
        type: atypes.WELCOME_MESSAGE_LOAD_RESPONSE,

        data: response.data,
        channel,
      })
    } catch (error) {
      dispatch({ type: atypes.WELCOME_MESSAGE_LOAD_ERROR, channel })
      handleCatch(error)
    }
  }
}

export const updateWelcomeMessageEnabled = (
  enabled: boolean,
  channel: ChannelType = ChannelType.FB,
): IAsyncThunkAction => {
  return async (dispatch) => {
    dispatch({ type: atypes.WELCOME_MESSAGE_UPDATE_ENABLED_REQUEST, channel })

    try {
      const response = await WelcomeMessageApi.switch({
        query: { welcome_enabled: Number(enabled), channel },
      })

      dispatch({
        type: atypes.WELCOME_MESSAGE_UPDATE_ENABLED_RESPONSE,
        data: response.data,
        channel,
      })

      response.data.enabled
        ? alert(l.translate('Welcome Message activated'), 'success')
        : alert(l.translate('Welcome Message deactivated'))
    } catch (error) {
      dispatch({ type: atypes.WELCOME_MESSAGE_UPDATE_ENABLED_ERROR, channel })
      handleCatch(error)
    }
  }
}

export const setWelcomeMessageFlow = (
  flowId?: string | null,
  channel: ChannelType = ChannelType.FB,
): IAsyncThunkAction<WelcomeMessage | undefined> => {
  return async (dispatch) => {
    try {
      const response = await WelcomeMessageApi.replaceFlow({
        body: { flow_ns: flowId, channel },
      })

      dispatch({
        type: atypes.WELCOME_MESSAGE_SET_FLOW_RESPONSE,
        data: response.data,
        channel,
      })

      return response.data
    } catch (error) {
      handleCatch(error)
      return
    }
  }
}

export const createWelcomeMessageFlow = (
  channel: ChannelType = ChannelType.FB,
): IAsyncThunkAction<{ flow: { ns: string } }> => {
  return async (dispatch) => {
    const fileItem = await dispatch(
      contentManagementActions.createAutomation({ name: 'Welcome Message' }),
    ).unwrap()

    return dispatch(setWelcomeMessageFlow(fileItem?.flow?.ns, channel)) as unknown as Promise<{
      flow: { ns: string }
    }>
  }
}

export const removeWelcomeMessageFlow = (channel: ChannelType): IAsyncThunkAction => {
  return async (dispatch) => {
    try {
      const response = await WelcomeMessageApi.removeFlow({ body: { channel } })

      dispatch({
        type: atypes.WELCOME_MESSAGE_REMOVE_FLOW_RESPONSE,
        data: response.data,
        channel,
      })
    } catch (error) {
      handleCatch(error)
    }
  }
}

const updateWelcomeMessageByChannel = (channel: ChannelType, data: WelcomeMessage) => ({
  type: atypes.WELCOME_MESSAGE_UPDATE_BY_CHANNEL,
  channel,
  data,
})

const welcomeMessageUpdatedNotification = (item: IWelcomeMessageTrigger) => ({
  type: atypes.WELCOME_MESSAGE_UPDATED_NOTIFICATION,
  item,
})

accountNotificationsListener.on(
  'welcome_message_updated',
  (data: INotificationServiceData<WelcomeMessage>, dispatch) => {
    dispatch(updateWelcomeMessageByChannel(data.model.channel, data.model))
    dispatch(welcomeMessageUpdatedNotification(welcomeMessageAdapter(data.model)))
  },
)

import React from 'react'
import cx from 'classnames'

import cleanProps from '../../../lib/clean-react-props'
import Loader from '../../Loader'
import { FormChoiceField } from '../FormField'

import { ToggleV2Props } from './interfaces'

import cm from './ToggleV2.module.css'

/** Toggles are used when you need to provide ON/OFF option to user.
 *
 * [Figma](https://www.figma.com/design/Xf38PdCKeISWnBoh0pLFIIWf/%F0%9F%A7%B1-MC-Desktop-Components?node-id=18645-48041&m=dev)
 */
const ToggleV2 = ({
  disabled = false,
  size = 'small',
  infoText,
  infoTextWidth,
  helpText,
  tabIndex,
  className,
  checked = false,
  form,
  lozenge,
  onChange,
  onBlur,
  onInput,
  onFocus,
  orientation = 'right',
  loading = false,
  name,
  labelId,
  id,
  fullWidth,
  ...props
}: ToggleV2Props) => {
  const eventsDisabled = disabled || loading

  return (
    <FormChoiceField
      {...cleanProps(props, [], [])}
      id={id}
      type="checkbox"
      checked={checked}
      form={form}
      onChange={eventsDisabled ? undefined : onChange}
      onInput={eventsDisabled ? undefined : onInput}
      onBlur={eventsDisabled ? undefined : onBlur}
      onFocus={eventsDisabled ? undefined : onFocus}
      disabled={disabled}
      lozenge={lozenge}
      infoText={infoText}
      infoTextWidth={infoTextWidth}
      helpText={helpText}
      tabIndex={tabIndex}
      className={className}
      name={name}
      labelId={labelId}
      fullWidth={fullWidth}
      fieldClasses={{
        choice: cx(cm.toggle__input, {
          [cm.medium]: size === 'medium',
          [cm.inputRight]: orientation === 'left',
          [cm.loading]: loading,
        }),
        containerWithLabel: cx(
          orientation === 'right' ? cm.labelRight : cm.labelLeft,
          size === 'medium' ? cm.labelMedium : '',
          loading ? cm.loadingLabel : '',
        ),
        label: 'text-body',
      }}
      {...props}
    >
      <span
        className={cx(cm.toggle, {
          [cm.checked]: checked,
          [cm.disabled]: disabled,
          [cm.medium]: size === 'medium',
          [cm.loading]: loading,
        })}
      >
        {loading && <Loader size={size === 'medium' ? 20 : 12} className={cm.loader} />}
      </span>
    </FormChoiceField>
  )
}

export default ToggleV2

import get from 'lodash/get'

import { IPost } from 'apps/posting/postingInterfaces'
import { IFilter } from 'common/filter/interfaces'
import { FlowContentType } from 'common/flow/flowConstants'
import { Post, PostSendingStats } from 'shared/api/requests/broadcasting/schemas'

import defaults from './defaults'

export function sendingStatsAdapter(stats: PostSendingStats | null | undefined) {
  if (!stats) {
    return null
  }
  const messagesBlocked = get(stats, 'messages_blocked')
  return {
    etaSeconds: get(stats, 'eta_seconds', 0),
    etaStartingPoint: Date.now(), // записываем точку отсчета etaSeconds, т.е. etaStartingPoint + etaSeconds = время когда все будет отправлено
    messagesProcessed: get(stats, 'messages_processed', 0),
    messagesBlocked: messagesBlocked === null ? 'N/A' : messagesBlocked,
    messagesTotal: get(stats, 'messages_total', 0),
  }
}

export default function (data: Post): IPost {
  return {
    id: data.post_id,
    contentId: get(data, 'content_id', defaults.contentId),
    flowId: data.namespace,
    chId: data.content_holder,
    preview: get(data, 'preview', defaults.preview),
    status: get(data, 'status', defaults.status),
    updated: data.timestamp,
    messagingType: get(data, 'messaging_type', defaults.messagingType),
    messageTag: get(data, 'message_tag', defaults.messageTag),
    notification: get(data, 'notification', defaults.notification),
    schedule: get(data, 'schedule', defaults.schedule),
    timezone: get(data, 'timezone', defaults.timezone),
    scheduleUpdated: data.ts_scheduled,

    stats: get(data, 'stats', defaults.stats),
    sendingStats: sendingStatsAdapter(data.sending_stats),

    firstNodeMms: get(data, 'first_node_mms', false),
    rootContentType: get(data, 'root_content_type', FlowContentType.DEFAULT),
    dependencies: data.dependencies,

    $forceSendingInProgress: false,
    $interruptSending: false,
    $recipients: 0,
    $recipientsQuery: '',
    $isRecipientsFetching: false,

    // TODO removed as unknown as after full migration on zod schemas
    flow: data.flow as unknown as IPost['flow'],
    filter: data.filter as unknown as IFilter,
  }
}

import assert from 'assert'

import hash from 'hash-sum'
import pick from 'lodash/pick'
import { l } from '@manychat/manyui'

import { getIsOpen, toggleModal } from 'apps/triggerPicker/redux/triggerPicker'
import { updateBuilderState } from 'common/builder/actions/updateBuilderState'
import * as ActionTypes from 'common/builder/constants/builderReduxActionTypes'
import nodeRegistry from 'common/builder/nodeRegistry'
import builderSelectors from 'common/builder/selectors/builder'
import * as entitySelectors from 'common/builder/selectors/builder/entitySelectors'
import { alert } from 'common/core'
import { analyticsService } from 'utils/services/analytics'
import errorTrackingService from 'utils/services/errorTrackingService'

/**
 * @param builderId
 * @param blockId
 * @param style
 */
export function highlightBlock(builderId, blockOrButtonId, style, options = {}) {
  const defaultOptions = { selectParent: true }
  options = { ...defaultOptions, ...options }

  return (dispatch, getState) => {
    const state = getState()
    const currentHighlightedBlock = builderSelectors.state.getHighlightedBlock(state, builderId)

    if (currentHighlightedBlock?.id === blockOrButtonId) {
      return
    }

    if (!blockOrButtonId) {
      if (currentHighlightedBlock) {
        dispatch({
          type: ActionTypes.BUILDER_HIGHLIGHT_BLOCK,
          builderId,
          blockId: null,
          style,
        })
      }

      return
    }

    let block = entitySelectors.getBlockById(state, builderId, blockOrButtonId)
    if (block == null) {
      block = entitySelectors.getParentBlock(state, builderId, blockOrButtonId)
    }

    if (block == null) {
      return console.warn(`[highlightBlock] Block not found: ${blockOrButtonId}`)
    }

    const rootBlockParent = options.selectParent
      ? entitySelectors.getRootParentBlock(state, builderId, block.id)
      : null

    block = rootBlockParent || block

    dispatch({
      type: ActionTypes.BUILDER_HIGHLIGHT_BLOCK,
      builderId,
      blockId: block.id,
      style,
    })
  }
}

/**
 * Задать rootId content для билдера
 * @param builderId
 * @param nodeId
 * @param options
 */
export function setRoot(builderId, nodeId, options = {}) {
  return (dispatch, getState) => {
    const state = getState()
    const node = entitySelectors.getNodeById(state, builderId, nodeId)

    if (nodeId && !node) {
      return errorTrackingService.trackError(new Error(`setRoot: missing node`), {
        extra: { nodeId },
        fingerprint: 'group-item-26398',
        tags: { area: 'builder', topic: "id doesn't exist" },
      })
    }

    if (!options.isInitial) {
      analyticsService.sendEvent('BUILDER.SET_ROOT', { nodeType: node?.nodeType ?? null })
    }

    if (node) {
      const nodeConfig = nodeRegistry.get(node)
      if (!nodeConfig.canBeRoot) {
        return alert(
          l.translate(`Sorry, usage of '{caption}' as starting step is not allowed`, {
            caption: nodeConfig.getCaption(),
          }),
          'danger',
        )
      }
    }

    return dispatch({
      type: ActionTypes.BUILDER_SET_ROOT,
      builderId,
      nodeId,
    })
  }
}

/**
 * @param builderId
 * @param changes
 */
export function setReady(builderId, changes = {}) {
  return (dispatch) => {
    dispatch({
      type: ActionTypes.BUILDER_SET_READY,
      builderId,
      changes,
    })
  }
}

/**
 * @param builderId
 */
export function setChanged(builderId) {
  return {
    type: ActionTypes.BUILDER_SET_CHANGED,
    builderId,
  }
}

/**
 * @param builderId
 */
export function resetChanged(builderId) {
  return {
    type: ActionTypes.BUILDER_RESET_CHANGED,
    builderId,
  }
}

export function openTrigger(builderId, triggerId) {
  return (dispatch) => {
    dispatch(
      updateBuilderState(builderId, {
        selectedNodes: {},
        selectedButtonId: null,
        selectedUrlId: null,
        selectedBlockId: null,
        highlightedNodeId: null,
        createTriggerMode: false,
        createTriggerInitial: {},
        selectedTrigger: triggerId,
      }),
    )
  }
}

/**
 * @param builderId
 * @param buttonId
 */
export function selectButton(builderId, buttonId) {
  assert(builderId, `selectButton: builderId is required param`)
  return (dispatch, getState) => {
    const builder = builderSelectors.builderState.getById(getState(), builderId)

    dispatch({
      type: ActionTypes.BUILDER_SELECT_BUTTON,
      builderId,
      buttonId,
      prevButtonId: builder.selectedButtonId,
    })
  }
}

/**
 * @param builderId
 * @param {string|null} blockId
 */
export function selectBlock(builderId, blockId = null) {
  assert(builderId, `selectBlock: builderId is required param`)

  return (dispatch, getState) => {
    const builder = builderSelectors.builderState.getById(getState(), builderId)

    if (builder.selectedBlockId === blockId) {
      return
    }

    return dispatch({
      type: ActionTypes.BUILDER_SELECT_BLOCK,
      builderId,
      blockId,
      prevBlockId: builder.selectedBlockId,
    })
  }
}

/**
 * @param builderId
 * @param blockId
 */
export function deselectBlock(builderId) {
  assert(builderId, `deselectBlock: builderId is required param`)
  return selectBlock(builderId, null)
}

/**
 * @param builderId
 * @param selectedSegment
 */
export function selectSegment(builderId, selectedSegment) {
  return (dispatch) => {
    dispatch(updateBuilderState(builderId, { selectedSegment }))
  }
}

export function validateCurrentNode(builderId) {
  return (dispatch, getState) => {
    const state = getState()

    const builderState = builderSelectors.builderState.getById(state, builderId)
    if (builderState == null || builderState.rootId == null) {
      return
    }

    let nodeId = builderSelectors.builderState.getCurrentNodeId(state, builderId)

    if (nodeId == null) {
      if (builderState.flowChartMode) {
        return
      }

      nodeId = builderSelectors.builderState.getRootNodeId(state, builderId)
    }

    const errors = builderSelectors.validation.validateNode(state, builderId, nodeId)

    const errorsHash = hash(errors)

    if (errorsHash === builderState.errorsHash) {
      return
    }

    dispatch({
      type: ActionTypes.CONTENT_VALIDATED,
      builderId,
      errors,
      errorsHash,
    })
  }
}

///////////////////
// BASIC ACTIONS //
///////////////////

/**
 * Выдаёт id контента привязанного к кнопке при ховере
 * @param builderId
 * @param nodeId
 */
export function hover(builderId, nodeId) {
  return (dispatch) => {
    dispatch({
      type: ActionTypes.HOVER_OVER_BUTTON,
      builderId,
      highlightedNodeId: nodeId,
    })
  }
}

/**
 * Сбрасывет id контента привязанного к кнопке при уходе курсора
 * @param builderId
 */
export function clearHover(builderId) {
  return (dispatch) => {
    dispatch({
      type: ActionTypes.CLEAR_BUTTON_HOVER,
      builderId,
    })
  }
}

/**
 * @param builderId
 * @param urlId
 */
export function selectUrl(builderId, urlId) {
  assert(builderId, `selectUrl: builderId is required param`)
  return (dispatch, getState) => {
    const builder = builderSelectors.builderState.getById(getState(), builderId)

    dispatch({
      type: ActionTypes.BUILDER_SELECT_URL,
      builderId,
      urlId,
      prevUrlId: builder.selectedUrlId,
    })
  }
}

/**
 * @param builderId
 * @param options
 */
export function toggleTriggerPicker(builderId, options = {}) {
  return (dispatch, getState) => {
    const isOpen = getIsOpen(getState())

    if (!isOpen) {
      analyticsService.sendEvent('BUILDER.ADD_TRIGGER.CLICK', pick(options, 'source'))
    }

    dispatch(
      updateBuilderState(builderId, {
        initialTriggerPickerCategory: options.initialCategory,
      }),
    )

    dispatch(toggleModal())
  }
}

/**
 * @param builderId
 * @param createTriggerInitial
 */
export function enableCreateTriggerMode(builderId, createTriggerInitial) {
  return (dispatch) => {
    dispatch(
      updateBuilderState(builderId, {
        sidebarHidden: false,
        createTriggerMode: true,
        createTriggerInitial,
        selectedNodes: {},
      }),
    )
  }
}

/**
 * @param builderId
 */
export function disableCreateTriggerMode(builderId) {
  return (dispatch, getState) => {
    const builder = builderSelectors.builderState.getById(getState(), builderId)
    if (!builder.createTriggerMode) {
      return
    }
    dispatch(
      updateBuilderState(builderId, {
        sidebarHidden: true,
        createTriggerMode: false,
        createTriggerInitial: {},
      }),
    )
  }
}

import { CollectOptInChannelState } from 'apps/collectOptIns/collectOptInsInterfaces'
import { ChannelType } from 'common/core/constants/ChannelType'

export const CollectOptInChannels: ChannelType[] = [ChannelType.FB, ChannelType.INSTAGRAM]

export const getInitialCollectOptInChannelState = (): CollectOptInChannelState => ({
  flowId: null,
  widgetId: null,
  name: '',
  chId: null,
  sendType: '',
  enabled: true,
  fetching: false,
  isFetched: false,
  isChangingType: false,
  sources: null,
})

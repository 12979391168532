import React from 'react'

import { MoveToAlert } from 'apps/cms/components/Flows/components/MoveToAlert/MoveToAlert'
import { alert } from 'common/core'

export const customMoveToSuccessAlert = (text: string, pathToMove: string) =>
  alert(text, 'info', {
    icon: 'CheckCircle',
    content: <MoveToAlert pathTo={pathToMove} />,
  })

import { z } from 'zod'

export const TierSchema = z.object({
  price: z.number(),
  min: z.number(),
  max: z.number(),
})

export const BillingInfoSchema = z.object({
  isPro: z.boolean(),
  isTrial: z.boolean(),
  isTrialAvailable: z.boolean(),
  currency: z.string(),
  currentMonthlyPrice: z.number(),
  tiers: z.object({
    all: z.array(TierSchema),
    current: TierSchema.optional(),
  }),
})

export type BillingInfo = z.infer<typeof BillingInfoSchema>

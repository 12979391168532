import { combineReducers } from 'redux'

import commissionsReducer from './commissions'
import emailReducer from './email'
import listingReducer from './listing'
import signonReducer from './signon'
import statusReducer from './status'

export const agencyReducer = combineReducers({
  signon: signonReducer,
  email: emailReducer,
  listing: listingReducer,
  status: statusReducer,
  commissions: commissionsReducer,
})

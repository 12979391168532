import { z } from 'zod'

import { TriggerRuleStatusType } from 'apps/triggerRules/models/TriggerRule/constants'
import { ruleTriggerSchema } from 'shared/api/requests/cms/schemas/fsCommonSchema'

export const RulesSchemas = {
  setDraftStatus: {
    response: z.object({
      rule: ruleTriggerSchema.optional(),
    }),
    request: z.object({
      rule_id: z.number(),
      status: z.nativeEnum(TriggerRuleStatusType),
    }),
    query: z.undefined(),
    path: z.undefined(),
  },
}

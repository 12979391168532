import React, { useCallback, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { CustomizableBreadcrumbsMenu } from 'components/Breadcrumbs/components/CustomizableBreadcrumbsMenu/CustomizableBreadcrumbsMenu'
import { BaseBreadcrumbsSegment } from 'components/Breadcrumbs/lib/types'

export interface BreadcrumbsMenuProps<CustomSegment extends BaseBreadcrumbsSegment> {
  segments: CustomSegment[]
}

export const BreadcrumbsMenu = <CustomSegment extends BaseBreadcrumbsSegment>({
  segments,
}: BreadcrumbsMenuProps<CustomSegment>) => {
  const navigate = useNavigate()

  const [isMenuOpened, setIsMenuOpened] = useState(false)

  const openMenu = useCallback(() => {
    setIsMenuOpened(true)
  }, [])

  const closeMenu = useCallback(() => {
    setIsMenuOpened(false)
  }, [])

  const openFolder = useCallback(
    (event: React.MouseEvent<HTMLButtonElement>, segment: BaseBreadcrumbsSegment) => {
      if (event.ctrlKey || event.metaKey) {
        window.open(segment.path)
      } else {
        navigate(segment.path)

        closeMenu()
      }
    },
    [closeMenu, navigate],
  )

  return (
    <CustomizableBreadcrumbsMenu
      segments={segments}
      isOpen={isMenuOpened}
      openMenu={openMenu}
      closeMenu={closeMenu}
      onButtonClick={openFolder}
    />
  )
}

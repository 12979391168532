import { z } from 'zod'

import { AddOnSlug, PlanSlug } from '../constants'

export const UpgradeRequestSchema = z.object({
  products: z.array(z.nativeEnum(PlanSlug)).optional(),
  addOns: z.array(z.nativeEnum(AddOnSlug)).optional(),
})

export const ContextWithUpgradeRequestSchema = z.object({
  request: UpgradeRequestSchema,
})

import { RouterNavigateOptions, To } from '@remix-run/router'

import { NEXT_PATH_SEARCH_PARAM, RETURN_SEARCH_PARAM, RoutePaths } from 'utils/router/constants'
import { navigatePollyfill } from 'utils/router/tools'
import { preserveSearchParams } from 'utils/router/tools/preserveSearchParams'
import errorTrackingService from 'utils/services/errorTrackingService'

type NavigateReg = typeof navigatePollyfill

const ProxiedThroughNonAuthParams = [RETURN_SEARCH_PARAM, NEXT_PATH_SEARCH_PARAM]

export const RoutesForSavingNextPathInNotAuthArea = [
  RoutePaths.SIGNIN,
  RoutePaths.SIGNUP,
  RoutePaths.FACEBOOK_AUTH,
  RoutePaths.TELEGRAM_AUTH,
]

export function extractNextPathSearchParams(search: string) {
  const params = new URLSearchParams(search)
  const returnSearchParam = params.get(RETURN_SEARCH_PARAM)
  const nextPathSearchParam = params.get(NEXT_PATH_SEARCH_PARAM)

  try {
    if (returnSearchParam) {
      const decodedReturn = decodeURIComponent(returnSearchParam)
      const urlObj = new URL(decodedReturn, window.location.origin)
      const nextPath = urlObj.searchParams.get(NEXT_PATH_SEARCH_PARAM)

      if (nextPath) return decodeURIComponent(nextPath)
    }

    if (nextPathSearchParam) return decodeURIComponent(nextPathSearchParam)
  } catch (error) {
    errorTrackingService.logError(error, {
      scope: 'root',
      section: null,
      fingerprint: 'extract_next_path_search_params',
    })
  }

  return null
}

export function prepareNonAuthUrl(url: string) {
  if (!RoutesForSavingNextPathInNotAuthArea.some((route) => url.startsWith(route))) return url

  return preserveSearchParams(url, ProxiedThroughNonAuthParams)
}

export const navigateThroughNonAuth: NavigateReg = (
  to: To | null | number,
  options?: RouterNavigateOptions,
) => {
  if (to === null || typeof to === 'number') return navigatePollyfill(to, options)

  return navigatePollyfill(prepareNonAuthUrl(to.toString()), options)
}

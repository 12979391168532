import React, { forwardRef, useRef, useState, useEffect, useImperativeHandle } from 'react'

import { deprecatedDecorator } from '../../../utils/deprecated-decorator'
import BtnV2 from '../../BtnV2'
import Popover from '../../layers/Popover'
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import DropdownV2 from '../DropdownV2'

import { DropdownProps } from './interface'

export interface DropdownRef {
  open: () => void
  close: () => void
}

/**
 * Dropdowns are used to display content inside popover by clicking or hovering on specific trigger(anchor).
 * @deprecated use {@link DropdownV2} component */
const Dropdown = forwardRef<DropdownRef, DropdownProps>(
  (
    {
      anchorProps,
      children,
      arrow = true,
      at = 'bottom',
      label,
      renderAnchor,
      disabled,
      openOnHover,
      onHover,
      onOpen,
      onClose,
      closeTimeout = 300,
      onClickAnchor,
      popoverProps,
    },
    ref,
  ) => {
    openOnHover = openOnHover || onHover
    const [open, setOpen] = useState(false)
    let closeTimer: NodeJS.Timeout

    useEffect(() => {
      open ? onOpen?.() : onClose?.()
    }, [open]) // eslint-disable-line react-hooks/exhaustive-deps

    useImperativeHandle(ref, () => ({
      open: () => setOpen(true),
      close: () => setOpen(false),
    }))

    const anchorRef = useRef(null)

    const handleClick = () => {
      if (disabled) return

      setOpen(true)
      onClickAnchor?.()
    }
    const handleMouseEnter = () => {
      if (disabled || !openOnHover) return

      clearTimeout(closeTimer)
      setOpen(true)
    }
    const handleMouseLeave = () => {
      if (disabled || !openOnHover) return

      closeTimer = setTimeout(() => setOpen(false), closeTimeout)
    }
    const handleRequestClose = (reason: string) =>
      reason !== 'clickAnchor' ? setOpen(false) : onClickAnchor?.()

    const allAnchorProps = {
      ...anchorProps,
      ref: anchorRef,
      disabled,
      onClick: handleClick,
      onMouseEnter: handleMouseEnter,
      onMouseLeave: handleMouseLeave,
    }
    const allPopoverProps = {
      arrow,
      at,
      useLayerForClickAway: false,
      ...popoverProps,
      open,
      anchor: anchorRef.current,
      onRequestClose: handleRequestClose,
      onMouseEnter: handleMouseEnter,
      onMouseLeave: handleMouseLeave,
    }

    return (
      <>
        {typeof renderAnchor === 'function' ? (
          React.cloneElement(renderAnchor({ open }), allAnchorProps)
        ) : (
          <BtnV2 {...allAnchorProps}>{label}</BtnV2>
        )}
        <Popover {...allPopoverProps}>{children}</Popover>
      </>
    )
  },
)

Dropdown.displayName = 'Dropdown'

/** @deprecated use {@link DropdownV2} component */
export default deprecatedDecorator(Dropdown, { replacementComponentName: 'DropdownV2' })

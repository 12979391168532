import React, { useMemo } from 'react'
import sortBy from 'lodash/sortBy'
import { useAppSelector } from 'reduxTyped'

import { RuleTitle } from 'apps/cms/components/Flows/components/FlowCard/components/Triggers/components/RuleTitle/RuleTitle'
import { isTriggerMatchesFilters } from 'apps/cms/components/Flows/components/FlowCard/components/Triggers/hooks/useTriggers/lib/filtersMatching/isTriggerMatchesFilters'
import { getAnotherFlowTrigger } from 'apps/cms/components/Flows/components/FlowCard/components/Triggers/hooks/useTriggers/lib/mappers/getAnotherFlowTrigger'
import { getExternalTrigger } from 'apps/cms/components/Flows/components/FlowCard/components/Triggers/hooks/useTriggers/lib/mappers/getExternalTrigger'
import { getIntentTrigger } from 'apps/cms/components/Flows/components/FlowCard/components/Triggers/hooks/useTriggers/lib/mappers/getIntentTrigger'
import { getKeywordTrigger } from 'apps/cms/components/Flows/components/FlowCard/components/Triggers/hooks/useTriggers/lib/mappers/getKeywordTrigger'
import { getRuleTrigger } from 'apps/cms/components/Flows/components/FlowCard/components/Triggers/hooks/useTriggers/lib/mappers/getRuleTrigger'
import { getWidgetTrigger } from 'apps/cms/components/Flows/components/FlowCard/components/Triggers/hooks/useTriggers/lib/mappers/getWidgetTrigger'
import { CardTrigger } from 'apps/cms/components/Flows/components/FlowCard/components/Triggers/lib/types'
import { contentManagementSelectors } from 'apps/cms/store'
import { FsFlow, FsTriggerModel } from 'shared/api/requests/cms/schemas'

const MAX_VISIBLE_TRIGGERS = 2

export const useTriggers = ({ triggers }: { triggers: FsFlow['triggers'] }) => {
  const triggerFiltersQuery = useAppSelector(contentManagementSelectors.getTriggerFiltersQuery)
  const statusFiltersQuery = useAppSelector(contentManagementSelectors.getStatusFiltersQuery)
  const isAnyFilterApplied = useAppSelector(contentManagementSelectors.getAnyFilterApplied)

  const { flowTriggers, maxVisibleTriggers } = useMemo(() => {
    const triggersFromSearch: CardTrigger[] = []
    const otherTriggers: CardTrigger[] = []

    triggers.keywords.forEach((keyword) => {
      const trigger = getKeywordTrigger(keyword)

      if (
        isTriggerMatchesFilters({
          isDisabled: trigger.disabled,
          triggerChannel: keyword.channel,
          triggerModel: FsTriggerModel.KEYWORD,
          triggerEventType: null,
          triggerFiltersQuery,
          statusFiltersQuery,
          widgetType: null,
        })
      ) {
        triggersFromSearch.push(trigger)
      } else {
        otherTriggers.push(trigger)
      }
    })

    triggers.widgets.forEach((widget) => {
      const trigger = getWidgetTrigger(widget)

      if (
        isTriggerMatchesFilters({
          isDisabled: trigger.disabled,
          triggerChannel: widget.channel,
          triggerModel: FsTriggerModel.WIDGET,
          triggerEventType: null,
          triggerFiltersQuery,
          statusFiltersQuery,
          widgetType: widget.widget_type,
        })
      ) {
        triggersFromSearch.push(trigger)
      } else {
        otherTriggers.push(trigger)
      }
    })

    triggers.trigger_rules.forEach((rule) => {
      const base = getRuleTrigger(rule)

      if (!rule.triggers) {
        if (rule.draft) {
          const trigger = {
            ...base,
            title: (
              <RuleTitle title={rule.title} ruleEventType={rule.draft.initial_trigger_event_type} />
            ),
          }

          if (
            isTriggerMatchesFilters({
              isDisabled: trigger.disabled,
              triggerChannel: null,
              triggerModel: FsTriggerModel.CONTACT_EVENT,
              triggerEventType: rule.draft.initial_trigger_event_type,
              triggerFiltersQuery,
              statusFiltersQuery,
              widgetType: null,
            })
          ) {
          } else {
            otherTriggers.push(trigger)
          }
        }
        return
      }

      rule.triggers.forEach((ruleTrigger) => {
        const trigger = {
          ...base,
          title: <RuleTitle title={rule.title} ruleEventType={ruleTrigger.data.event_type} />,
        }

        if (
          isTriggerMatchesFilters({
            isDisabled: trigger.disabled,
            triggerChannel: null,
            triggerModel: FsTriggerModel.CONTACT_EVENT,
            triggerEventType: ruleTrigger.data.event_type,
            triggerFiltersQuery,
            statusFiltersQuery,
            widgetType: null,
          })
        ) {
          triggersFromSearch.push(trigger)
        } else {
          otherTriggers.push(trigger)
        }
      })
    })

    triggers.another_flow.forEach((trigger) => {
      otherTriggers.push(getAnotherFlowTrigger(trigger))
    })

    triggers.intents.forEach((intent) => {
      const trigger = getIntentTrigger(intent)

      if (
        isTriggerMatchesFilters({
          isDisabled: trigger.disabled,
          triggerChannel: intent.channel,
          triggerModel: FsTriggerModel.INTENT,
          triggerEventType: null,
          triggerFiltersQuery,
          statusFiltersQuery,
          widgetType: null,
        })
      ) {
        triggersFromSearch.push(trigger)
      } else {
        otherTriggers.push(trigger)
      }
    })

    triggers.external_triggers.forEach((externalTrigger) => {
      const trigger = getExternalTrigger(externalTrigger)

      if (
        isTriggerMatchesFilters({
          isDisabled: trigger.disabled,
          triggerChannel: null,
          triggerModel: FsTriggerModel.EXTERNAL_TRIGGER,
          triggerEventType: null,
          triggerFiltersQuery,
          statusFiltersQuery,
          widgetType: null,
        })
      ) {
        triggersFromSearch.push(trigger)
      } else {
        otherTriggers.push(trigger)
      }
    })

    return {
      maxVisibleTriggers: isAnyFilterApplied ? triggersFromSearch.length : MAX_VISIBLE_TRIGGERS,
      flowTriggers: [
        ...sortBy(triggersFromSearch, (trigger) => trigger.created),
        ...sortBy(otherTriggers, (trigger) => trigger.created),
      ],
    }
  }, [
    statusFiltersQuery,
    triggerFiltersQuery,
    isAnyFilterApplied,
    triggers.another_flow,
    triggers.external_triggers,
    triggers.intents,
    triggers.keywords,
    triggers.trigger_rules,
    triggers.widgets,
  ])

  return { flowTriggers, maxVisibleTriggers }
}

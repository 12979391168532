import React from 'react'
import { l, Link } from '@manychat/manyui'

import { NewAutomationButton } from 'apps/cms/components/NewAutomationButton/NewAutomationButton'
import { KnowledgeBaseLink } from 'common/core/components/SupportLinks'
import { usePermission, PermissionTargets } from 'common/userRoles'

import illustration from './assets/illustration.svg'

import cm from './CreateFirstAutomation.module.css'

export const CreateFirstAutomation = () => {
  const userCanEditFlow = usePermission(PermissionTargets.FLOW_EDITING)

  return (
    <article className={cm.wrapper}>
      <img width={194} height={180} src={illustration} />

      <h1 className="text-heading-2 m-0">{l.translate('Create your first Automation')}</h1>

      <p className={cm.description}>
        {l.translate(
          'Automations are where you create chat automations in an easy and visual format. Try some automations we recommend based on your goals.',
        )}{' '}
        <Link href={KnowledgeBaseLink.FLOW_BUILDER} target="_blank" rel="noreferrer">
          {l.translate('Learn more')}
        </Link>
      </p>

      {userCanEditFlow && <NewAutomationButton />}
    </article>
  )
}

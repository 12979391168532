import { LayoutWithSidebar } from 'utils/router/components/LayoutWithSidebar/LayoutWithSidebar'

export const SmartSegmentsRoutes = [
  {
    path: 'customer_segments/:segment_id',
    alias: 'customerSegments',
    Component: LayoutWithSidebar,
    children: [
      {
        index: true,
        lazy: () => import('./smartSegments.page'),
      },
    ],
  },
] as const

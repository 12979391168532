import React, { MouseEventHandler } from 'react'
import cx from 'classnames'
import { l, Icon } from '@manychat/manyui'

import { isMobile } from 'utils'
import FavoriteButton from 'apps/profile/components/ProfilePages/PagesRow/FavoriteButton'
import { AccountAvatar } from 'common/core/components/AccountAvatar/AccountAvatar'
import { AccountStatus } from 'common/core/models/Account/constants'
import { Col, Row } from 'components/Grid'

import CreateImg from './createNew.png'

import cm from './Account.module.css'

interface AccountProps {
  accountId?: string
  accountName?: string
  accountStatus?: AccountStatus
  avatarUrl?: string | null
  className?: string
  createNew?: boolean
  current?: boolean
  currentOpen?: boolean
  isFavoriteAccount?: boolean
  isHiddenAccount?: boolean
  isRightTimeAnimate?: boolean
  isSidebarFolded?: boolean
  isSidebarHidden: boolean
  onClick?: MouseEventHandler
}

export const AccountOption = ({
  accountName,
  accountStatus,
  avatarUrl,
  accountId,
  isHiddenAccount = false,
  isFavoriteAccount = false,
  className,
  createNew,
  current,
  currentOpen,
  isRightTimeAnimate = true,
  isSidebarFolded,
  isSidebarHidden,
  onClick,
}: AccountProps) => {
  const renderTitle = () => {
    return createNew ? l.translate('Create new account') : accountName
  }

  const renderAvatar = () => {
    if (createNew) {
      return <img className={cm.addPageAva} src={CreateImg} />
    }

    return (
      <AccountAvatar
        className="p-l-xxs"
        avatarUrl={avatarUrl}
        title={accountName}
        accountStatus={accountStatus}
        size={32}
      />
    )
  }

  const title = renderTitle()

  return (
    <Row
      Component="div"
      onClick={onClick}
      middle
      className={cx(cm.wrapper, className, { [cm.current]: current }, 'p-y')}
    >
      <Col>
        <Row className="align-center">
          <Col
            width={65}
            data-title={isSidebarFolded ? accountName : null}
            data-title-at="right"
            className={cx(cm.avaWrapper, 'align-center')}
          >
            {renderAvatar()}
          </Col>
          {!isSidebarHidden && (
            <div
              className={cx({ [cm.pageTitleCurrent]: current }, 'col text-ellipsis text-sm')}
              title={title}
            >
              {title}
            </div>
          )}
        </Row>
      </Col>
      {!isSidebarHidden && (isRightTimeAnimate || currentOpen) && (
        <>
          {current ? (
            <Col width={30}>
              <Icon.ChevronDown
                className={cx(cm.arrow, { [cm.rotate]: currentOpen })}
                size={isMobile ? 24 : 15}
              />
            </Col>
          ) : (
            <>
              {accountId && (
                <Col auto>
                  <FavoriteButton
                    className={cm.additionalButton}
                    accountId={accountId}
                    isFavorite={isFavoriteAccount}
                    isHidden={isHiddenAccount}
                  />
                </Col>
              )}
            </>
          )}
        </>
      )}
    </Row>
  )
}

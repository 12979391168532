import React, { useMemo } from 'react'
import cx from 'classnames'

import cm from './ManychatLogo.module.css'

const LogoSvg = ({ className }: { className: string }) => {
  return (
    <svg
      className={className}
      width={114}
      height={20}
      viewBox="0 0 114 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M31.883 6.84671C31.7437 6.60373 31.5771 6.37734 31.3867 6.17193C31.018 5.77671 30.574 5.45921 30.0807 5.23815C29.5768 5.00699 29.0289 4.88748 28.4744 4.88775C27.6794 4.86983 26.8967 5.0863 26.2238 5.51023C25.5386 5.97616 25.0146 6.64308 24.7241 7.41914C24.3392 8.4354 24.1573 9.51725 24.1887 10.6035V11.3088C24.1572 12.3939 24.3315 13.4751 24.7023 14.4954C24.9804 15.2625 25.49 15.9242 26.1607 16.389C26.8335 16.8123 27.6167 17.0267 28.4113 17.005C28.9693 17.0077 29.5214 16.8912 30.0307 16.6633C30.5329 16.4412 30.9874 16.1242 31.3693 15.7295C31.5578 15.5339 31.7276 15.3212 31.8765 15.094V16.6829H36.8827V5.20988H31.8765L31.883 6.84671ZM32.0071 11.2152C32.0173 11.702 31.9669 12.1883 31.8569 12.6627C31.792 12.982 31.6415 13.2776 31.4216 13.518C31.3252 13.611 31.2113 13.6837 31.0865 13.7319C30.9616 13.7801 30.8284 13.8029 30.6946 13.7988C30.5635 13.8024 30.433 13.7794 30.3111 13.7311C30.1891 13.6829 30.0782 13.6103 29.985 13.518C29.7633 13.2805 29.6125 12.9858 29.5497 12.6671C29.4466 12.1902 29.3998 11.7029 29.4103 11.2152V10.6035C29.3995 10.1198 29.4486 9.63654 29.5562 9.16479C29.6178 8.85295 29.7646 8.56431 29.9806 8.33113C30.0743 8.24141 30.1849 8.1714 30.3061 8.12505C30.4272 8.0787 30.5563 8.057 30.6859 8.06128C30.9543 8.05236 31.2153 8.14924 31.4129 8.33113C31.6333 8.56268 31.7841 8.85158 31.8482 9.16479C31.9586 9.63614 32.0091 10.1195 31.9984 10.6035L32.0071 11.2152Z"
        fill="currentColor"
      />
      <path
        d="M48.9198 5.35069C48.3639 5.03585 47.7321 4.88002 47.0936 4.90017C46.4719 4.89104 45.8556 5.01651 45.287 5.26801C44.7676 5.50087 44.3016 5.83792 43.9179 6.25833C43.6614 6.54377 43.4453 6.86317 43.2758 7.20741V5.22231H38.4307V16.6953H43.4216V9.81713C43.4121 9.55851 43.4636 9.30135 43.5718 9.06626C43.6548 8.8838 43.7925 8.73167 43.9657 8.63093C44.129 8.54103 44.3127 8.49453 44.4991 8.49596C44.6283 8.48875 44.7577 8.50905 44.8785 8.55558C44.9993 8.60211 45.1088 8.67375 45.1999 8.7658C45.393 9.01276 45.4841 9.32434 45.4545 9.63645V16.6996H50.4434V8.99218C50.4669 8.21647 50.3351 7.4439 50.0559 6.7198C49.8313 6.15601 49.4335 5.67812 48.9198 5.35505"
        fill="currentColor"
      />
      <path
        d="M71.5716 12.1694C71.5905 12.6485 71.4695 13.1227 71.2233 13.5341C71.1218 13.6825 70.9842 13.8026 70.8236 13.8834C70.6629 13.9641 70.4844 14.0026 70.3048 13.9955C70.0241 14.0078 69.7496 13.9111 69.5386 13.7256C69.31 13.4767 69.1587 13.1666 69.1033 12.8332C68.9943 12.2763 68.9461 11.7092 68.9596 11.142V10.613C68.9596 9.63134 69.0707 8.93273 69.297 8.51917C69.3926 8.3243 69.543 8.16159 69.7297 8.051C69.9165 7.94042 70.1315 7.88669 70.3483 7.89658C70.523 7.88477 70.6979 7.91876 70.8555 7.9951C71.0132 8.07144 71.1482 8.18748 71.2473 8.3319C71.4683 8.72172 71.574 9.16637 71.552 9.61396V9.67497H76.667C76.6453 8.75052 76.3491 7.85335 75.816 7.09777C75.2568 6.35071 74.4932 5.78152 73.6176 5.45882C72.5394 5.05934 71.3955 4.86731 70.246 4.89288C69.0932 4.87264 67.9485 5.08976 66.8831 5.53067C65.9287 5.93371 65.1189 6.61676 64.5607 7.48963C63.9933 8.36028 63.7096 9.45285 63.7096 10.7675V11.0898C63.7096 12.3957 63.9846 13.4935 64.5345 14.383C65.0784 15.2596 65.8785 15.948 66.8265 16.355C67.9049 16.8077 69.0659 17.0301 70.2352 17.008C71.3692 17.0287 72.4971 16.8375 73.561 16.4442C74.463 16.1139 75.2526 15.5343 75.8378 14.7726C76.4036 13.9998 76.7154 13.0704 76.7302 12.1127H71.5716V12.1694Z"
        fill="currentColor"
      />
      <path
        d="M88.1988 5.34944C87.6441 5.03093 87.0121 4.87202 86.3727 4.89021C85.7517 4.88104 85.1361 5.00652 84.5682 5.25804C84.0482 5.48976 83.582 5.82708 83.1991 6.24847C83.0109 6.45777 82.8439 6.68518 82.7007 6.9275V1.67969H77.7097V16.6853H82.7007V9.80717C82.6912 9.54855 82.7427 9.29139 82.8509 9.05629C82.9346 8.87431 83.0721 8.72238 83.2448 8.62097C83.4088 8.5307 83.5932 8.4842 83.7803 8.48599C83.9096 8.47892 84.0388 8.49931 84.1596 8.54583C84.2805 8.59235 84.3901 8.6639 84.4812 8.75584C84.6726 9.00362 84.7628 9.31477 84.7337 9.62649V16.6897H89.7246V8.98222C89.7475 8.20636 89.6151 7.43377 89.3351 6.70983C89.1118 6.14526 88.7136 5.667 88.1988 5.34509"
        fill="currentColor"
      />
      <path
        d="M98.5618 6.84971C98.4224 6.60674 98.2559 6.38035 98.0655 6.17494C97.6968 5.77971 97.2527 5.46222 96.7595 5.24116C96.2589 5.01482 95.7156 4.89834 95.1662 4.89947C94.3711 4.88154 93.5884 5.09802 92.9156 5.52195C92.2309 5.98852 91.7071 6.65522 91.4159 7.43085C91.031 8.44712 90.8491 9.52897 90.8804 10.6152V11.3205C90.849 12.4056 91.0233 13.4869 91.3941 14.5071C91.6722 15.2743 92.1818 15.9359 92.8525 16.4007C93.5253 16.824 94.3085 17.0384 95.1031 17.0167C95.6611 17.0194 96.2132 16.9029 96.7225 16.675C97.2247 16.453 97.6793 16.1359 98.0611 15.7412C98.2496 15.5457 98.4194 15.3329 98.5683 15.1057V16.6946H103.575V5.2216H98.5683L98.5618 6.84971ZM98.688 11.2182C98.6977 11.7051 98.6464 12.1914 98.5356 12.6657C98.4707 12.985 98.3202 13.2806 98.1003 13.521C98.004 13.614 97.89 13.6867 97.7652 13.7349C97.6403 13.7831 97.5071 13.8059 97.3733 13.8018C97.2422 13.8054 97.1117 13.7824 96.9898 13.7341C96.8678 13.6859 96.7569 13.6134 96.6637 13.521C96.4421 13.2835 96.2913 12.9888 96.2284 12.6701C96.1254 12.1932 96.0786 11.7059 96.0891 11.2182V10.6065C96.0783 10.1228 96.1273 9.63955 96.235 9.1678C96.2965 8.85595 96.4434 8.56732 96.6594 8.33413C96.753 8.24442 96.8637 8.1744 96.9848 8.12805C97.1059 8.0817 97.235 8.06001 97.3646 8.06429C97.633 8.05536 97.8941 8.15224 98.0916 8.33413C98.312 8.56568 98.4629 8.85458 98.5269 9.1678C98.6382 9.63903 98.6894 10.1224 98.6793 10.6065L98.688 11.2182Z"
        fill="currentColor"
      />
      <path
        d="M113.4 8.29134V5.22016H111.262V1.6875H106.273V5.22016H104.519V8.29134H106.26V13.2976C106.227 14.0332 106.421 14.7613 106.815 15.3828C107.181 15.9118 107.703 16.3129 108.309 16.5299C109.004 16.7719 109.736 16.8891 110.472 16.8759C111.024 16.8824 111.575 16.8284 112.116 16.7149C112.559 16.6212 112.989 16.475 113.398 16.2796V13.5719C113.053 13.687 112.692 13.7465 112.329 13.7482C112.048 13.7684 111.768 13.6916 111.537 13.5305C111.352 13.3825 111.26 13.1213 111.26 12.7469V8.28698L113.4 8.29134Z"
        fill="currentColor"
      />
      <path
        d="M19.7681 0.0043575H19.6069C14.8423 0.0043575 12.5264 7.1328 12.5264 7.1328V1.66297H0V16.6969H5.00623V6.67356H7.67696V16.6969H13.0488C13.0488 16.6969 15.8088 4.79948 18.3925 5.68972C20.1338 6.34271 15.2233 16.686 15.2233 16.686H22.3169C22.3169 16.686 23.3007 9.94934 23.3159 7.61818C23.5336 3.6589 22.9089 0 19.7637 0"
        fill="currentColor"
      />
      <path
        d="M54.9248 17.3638C54.9321 17.1663 54.8738 16.972 54.7593 16.8109C54.6355 16.6547 54.467 16.54 54.2761 16.4823C54.0254 16.4047 53.7637 16.3679 53.5013 16.3735L50.8328 5.22266H56.0283L57.5847 14.7867H57.1101L58.7382 5.22266H63.8293L61.3045 16.3735C61.0254 16.3648 60.7468 16.4015 60.4795 16.4823C60.2985 16.5359 60.1406 16.6486 60.0311 16.8023C59.931 16.972 59.8826 17.1671 59.8918 17.3638V20.0019H54.9248V17.3638Z"
        fill="currentColor"
      />
    </svg>
  )
}
const SmallLogoSvg = ({ className }: { className?: string }) => {
  return (
    <svg
      className={className}
      width={32}
      height={23}
      viewBox="0 0 32 23"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M27.0853 0.00597044H26.8645C20.3363 0.00597044 17.1631 9.77303 17.1631 9.77303V2.27853H0V22.8773H6.85931V9.14381H10.5186V22.8773H17.8789C17.8789 22.8773 21.6605 6.57603 25.2005 7.7958C27.5864 8.69049 20.8583 22.8624 20.8583 22.8624H30.5776C30.5776 22.8624 31.9255 13.6321 31.9464 10.4381C32.2446 5.01326 31.3888 0 27.0793 0"
        fill="currentColor"
      />
    </svg>
  )
}

interface ManyChatLogoProps {
  small?: boolean
  className?: string
  white?: boolean
  caption?: string
}

export const ManyChatLogo = ({
  className = '',
  small,
  white = false,
  caption,
}: ManyChatLogoProps) => {
  const ImgSvg = useMemo(() => (small ? SmallLogoSvg : LogoSvg), [small])

  return (
    <span
      className={cx('d-inline-flex align-center', white ? cm.logo_white : cm.logo_black, className)}
    >
      <ImgSvg className={cx(caption && 'm-r-xxs', caption && cm.logo_withCaption)} />

      {caption && <span className={cm.caption}>{caption}</span>}
    </span>
  )
}

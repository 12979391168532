import debugConstructor from 'debug'
import { v4 as uuid } from 'uuid'
import { l } from '@manychat/manyui'

import * as atypes from 'common/audience/constants/AudienceReduxActionTypes'
import { SELECTION_TYPE } from 'common/audience/models/Subscriber'
import {
  getAudienceImport,
  getAvailableSystemFieldNames,
  getCurrentUser,
  isChannelsStepEnabled,
} from 'common/audience/selectors/audienceSelectors'
import { alert } from 'common/core'
import { FilterField } from 'common/filter/models/AudienceFilter/constants'
import * as API from 'constants/API'
import { makeLimitedListActions } from 'utils/factory'
import parseCSVFile from 'utils/parseCSVFile'
import { navigatePollyfill } from 'utils/router/tools'
import { analyticsService } from 'utils/services/analytics'
import {
  anotherTabNotificationsListener,
  accountNotificationsListener,
} from 'utils/services/notificationsService'
import { linkURL } from 'utils/url'

import { fetchSubscribers } from './audienceActionsTyped'

const debug = debugConstructor('import-subscribers')

const endpoints = API.audience.endpoints

const baseListActions = makeLimitedListActions('audience')

export const dropSubscribersList = baseListActions.drop

export const fetchNextSubscribers = baseListActions.fetchNext

export const saveKeyword = baseListActions.save

export const showSubscriberModal = (id) => ({
  type: atypes.AUDIENCE_SHOW_SUBSCRIBER_MODAL,
  id,
})

export const hideSubscriberModal = () => ({
  type: atypes.AUDIENCE_HIDE_SUBSCRIBER_MODAL,
})

export const setSelectionType = (include) => ({
  type: atypes.AUDIENCE_SELECTION_TYPE_CHANGED,
  selectionType: include ? SELECTION_TYPE.INCLUDE : SELECTION_TYPE.EXCLUDE,
})

export const setDefaultSelectionType = () => ({
  type: atypes.AUDIENCE_SELECTION_TYPE_CHANGED,
  selectionType: SELECTION_TYPE.EXCLUDE,
})

export const toggleSelection = () => {
  return (dispatch, getState) => {
    const { audience } = getState()
    dispatch(setSelectionType(audience.selectionType === SELECTION_TYPE.EXCLUDE))
  }
}

export const toggleSubscriberChecked = (id) => ({
  type: atypes.AUDIENCE_TOGGLE_SUBSCRIBER_CHECKED,
  id,
})

const updateSearchQuery = (searchQuery) => ({
  type: atypes.AUDIENCE_SEARCH_QUERY_CHANGED,
  searchQuery,
})

export const resetDownloadConfirm = () => {
  return {
    type: atypes.AUDIENCE_RESET_DOWNLOAD_CONFIRM,
  }
}

export const searchSubscribers = (searchQuery = '') => {
  return (dispatch) => {
    dispatch(updateSearchQuery(searchQuery))
    dispatch(fetchSubscribers())
  }
}

export const updateAudienceFilter = (filter) => ({
  type: atypes.AUDIENCE_FILTER_CHANGED,
  filter,
})

export const filterSubscribers = (filter, clearSearch) => {
  return (dispatch, getState) => {
    const { audience } = getState()
    const searchQuery = clearSearch ? '' : audience.searchQuery

    const searchQueryChanged = searchQuery !== audience.searchQuery
    const filterChanged = filter !== audience.filter

    if (searchQueryChanged) {
      dispatch(updateSearchQuery(searchQuery))
    }

    if (filterChanged) {
      dispatch(updateAudienceFilter(filter))
    }

    const fetch = searchQueryChanged || filterChanged
    if (fetch) {
      dispatch(fetchSubscribers())
    }
  }
}

export const clearSubscribersFilter = (clearSearch) => filterSubscribers(null, clearSearch)

export const startChat = (id) => {
  return (dispatch) => {
    analyticsService.sendEvent('LIVE_CHAT.START_CHAT_FROM_AUDIENCE')
    dispatch(hideSubscriberModal())
    navigatePollyfill(linkURL(`/chat/${id}`))
  }
}

export const updateExportingStatus = (data) => ({
  type: atypes.AUDIENCE_UPDATE_EXPORTING_STATUS,
  data,
})

const makeSubscriberList = ({
  availableSystemFieldNames,
  headersMap,
  items,
  headers,
  selectedExtraChannels,
}) =>
  items.map((itemsArray) =>
    headers.reduce(
      (prev, headerKey, idx) => {
        const headerValue = headersMap[headerKey].value

        if (!headerValue) return prev
        const itemValue = itemsArray[idx]
        const isSystemField = availableSystemFieldNames.includes(headerValue)
        const isExtraChannel = selectedExtraChannels.includes(headerValue)

        if (!isSystemField) {
          prev['custom_fields'][headerValue] = itemValue
          return prev
        }

        if (isExtraChannel && selectedExtraChannels.length > 1) {
          const valuePhoneOrWAId =
            headerValue === FilterField.WA_ID ? FilterField.PHONE : FilterField.WA_ID
          prev['system_fields'][valuePhoneOrWAId] = itemValue
        }

        prev['system_fields'][headerValue] = itemValue

        return prev
      },
      { system_fields: {}, custom_fields: {} },
    ),
  )

export const importSubscribers = () => {
  return (dispatch, getState) => {
    const state = getState()
    const { items, headers, headersMap, tags, selectedExtraChannels } = getAudienceImport(state)
    const availableSystemFieldNames = getAvailableSystemFieldNames(getState())

    const subscriberList = makeSubscriberList({
      availableSystemFieldNames,
      selectedExtraChannels,
      items,
      headers,
      headersMap,
    })
    const tagList = tags.map((tag) => tag.value)

    return dispatch({
      type: atypes.AUDIENCE_IMPORT_SUBSCRIBERS,
      $fetch: [
        endpoints.importSubscribers,
        {
          method: 'POST',
          body: JSON.stringify({
            subscribers: subscriberList,
            tags: tagList,
            async: uuid(),
          }),
          headers: { 'Content-Type': 'application/json' },
        },
      ],
    })
  }
}

export const setImportFile = (file) => async (dispatch) => {
  debug(file, 'accepted file')
  if (file === null) return

  let payload = null

  try {
    const { itemsMap: items, headersMap, headers } = await parseCSVFile(file)
    payload = { file, items, headersMap, headers }
  } catch ({ message }) {
    return alert(message, 'danger')
  }

  if (payload) {
    dispatch({ type: atypes.AUDIENCE_SET_IMPORT_FILE_DATA, payload })
  }
}

export const setImportHeadersMap = (data) => ({
  type: atypes.AUDIENCE_SET_IMPORT_HEADERS_MAP,
  payload: data,
})
export const actionGoNext = () => (dispatch, getState) =>
  dispatch({
    type: atypes.AUDIENCE_IMPORT_GO_NEXT,
    payload: isChannelsStepEnabled(getState()),
  })
export const toggleExtraChannel = (channel) => ({
  payload: channel,
  type: atypes.AUDIENCE_TOGGLE_EXTRA_CHANNEL,
})
export const actionGoPrev = () => (dispatch, getState) =>
  dispatch({
    type: atypes.AUDIENCE_IMPORT_GO_PREV,
    payload: isChannelsStepEnabled(getState()),
  })
export const actionSetImportTag = (data) => ({
  type: atypes.AUDIENCE_SET_IMPORT_TAG,
  payload: data,
})
export const actionDeleteTag = (tagId) => ({
  type: atypes.AUDIENCE_DELETE_IMPORT_TAG,
  payload: tagId,
})
export const toggleImportAgreement = () => ({
  type: atypes.AUDIENCE_TOGGLE_IMPORT_AGREEMENT,
})

export const updateImportStatus = ({
  progress,
  done = false,
  totalCountFailed = 0,
  totalCountImported = 0,
  totalCountMerged = 0,
}) => {
  return (dispatch, getState) => {
    const { isShowImportContactsModal } = getAudienceImport(getState())
    const isProgressFinished = progress === 100
    const importEnded = isProgressFinished && done === true

    if (isProgressFinished) {
      dispatch(dropSubscribersList())
      dispatch(fetchSubscribers())
    }

    if (importEnded && isShowImportContactsModal === false) {
      alert(l.translate('Your contact list was uploaded. Please check your audience.'), 'success')
    }

    dispatch({
      type: atypes.AUDIENCE_IMPORT_UPDATE_STATUS,
      data: { progress, done, totalCountFailed, totalCountImported, totalCountMerged },
    })
  }
}

export const createSubscriber = ({ firstName, lastName, phoneNumber, gender, email, waId }) => {
  return async (dispatch) => {
    try {
      const { user_id: userID } = await dispatch({
        type: atypes.AUDIENCE_CREATE_SUBSCRIBER,
        $fetch: [
          endpoints.createSubscriber,
          {
            method: 'POST',
            body: JSON.stringify({
              first_name: firstName,
              last_name: lastName,
              email,
              phone_number: phoneNumber,
              gender,
              wa_id: waId,
            }),
            headers: { 'Content-Type': 'application/json' },
          },
        ],
        $success: () => alert(l.translate('Contact was created'), 'success'),
        $error: () => {
          // nothing
        },
      })

      if (userID) {
        dispatch(dropSubscribersList())
        dispatch(fetchSubscribers())
        navigatePollyfill(linkURL(`/subscribers/${userID}`))
      }
    } catch (e) {
      const { errors } = e.response
      dispatch(
        setCreateSubscriberValidationErrors({
          email: errors.email,
          phoneNumber: errors.phone_number,
          waId: errors.wa_id,
        }),
      )
    }
  }
}

export const setCreateSubscriberValidationErrors = ({ email, phoneNumber, waId }) => ({
  type: atypes.AUDIENCE_CREATE_SUBSCRIBER_SET_VALIDATION_ERRORS,
  errors: {
    email,
    phoneNumber,
    waId,
  },
})

export const showMigrateCUFToSFModal = () => ({
  type: atypes.AUDIENCE_SHOW_MIGRATE_CUF_TO_SF_MODAL,
})

export const showImportContactsModal = () => ({
  type: atypes.AUDIENCE_SHOW_IMPORT_CONTACTS_MODAL,
})

export const closeImportContactsModal = () => ({
  type: atypes.AUDIENCE_CLOSE_IMPORT_CONTACTS_MODAL,
})

export const showCreateSubscriberModal = () => ({
  type: atypes.AUDIENCE_SHOW_CREATE_SUBSCRIBER_MODAL,
})

export const closeCreateSubscriberModal = () => ({
  type: atypes.AUDIENCE_CLOSE_CREATE_SUBSCRIBER_MODAL,
})

//Smart Segments Audience Actions
export const fetchSubscribersByParams = (q, filter) => (dispatch) => {
  return dispatch(baseListActions.fetch(null, { q, filter }))
}

anotherTabNotificationsListener.on('subscriber_export_data_status', (data, dispatch) => {
  dispatch(updateExportingStatus(data))
})

anotherTabNotificationsListener.on(['subscriber_updated'], (data, dispatch, getState) => {
  const state = getState()
  const currentUser = getCurrentUser(state)
  if (data.model.user_id !== currentUser?.user_id) return

  dispatch({
    type: atypes.AUDIENCE_UPDATE_CURRENT_SUBSCRIBER,
    subscriber: data.model,
  })
})

accountNotificationsListener.on('import_unified_subscribers_status', (data, dispatch) => {
  const { progress, done } = data
  const { total_count_failed, total_count_imported, total_count_merged } = data.data
  dispatch(
    updateImportStatus({
      progress,
      done,
      totalCountFailed: total_count_failed,
      totalCountImported: total_count_imported,
      totalCountMerged: total_count_merged,
    }),
  )
})

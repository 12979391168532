import React from 'react'
import { l } from '@manychat/manyui'

import DefaultStepView from 'utils/services/newOnboardingService/view/DefaultStepView'
import ManyChatOnboardingView from 'utils/services/newOnboardingService/view/ManyChatOnboardingView'
import { PointerType } from 'utils/services/newOnboardingService/view/Pointer/pointerInterfaces'
import TooltipDefault from 'utils/services/newOnboardingService/view/Pointer/Tooltip/TooltipDefault'

import ImportContactTour from './ImportContactTour'

import cm from './ImportContactTourView.module.css'

const TOTAL_STEPS = 3

const ImportContactTourView = ({ onb }: { onb: ImportContactTour }) => {
  return (
    <ManyChatOnboardingView
      onboarding={onb}
      stepsView={{
        STEP_1: ({ stepId, step }) => {
          return (
            <DefaultStepView
              onb={onb}
              stepId={stepId}
              pointerConfig={{
                type: PointerType.DOM,
                elementId: 'contacts-nav-item',
                tooltip: {
                  component: (
                    <TooltipDefault
                      onNextClick={() => step.process()}
                      btnNext={l.makeTranslate('Next')}
                      text={l.makeTranslate(
                        'All of your current contacts are automatically stored in the "Contacts" tab.',
                      )}
                      className={cm.tooltip}
                    />
                  ),
                  at: 'right',
                },
              }}
              progressConfig={{
                current: 1,
                total: TOTAL_STEPS,
              }}
            />
          )
        },
        STEP_2: ({ stepId }) => (
          <DefaultStepView
            onb={onb}
            stepId={stepId}
            pointerConfig={{
              type: PointerType.DOM,
              elementId: 'import-subscribers-button',
              tooltip: {
                component: (
                  <TooltipDefault
                    className={cm.tooltip}
                    text={l.makeTranslate(
                      'You can manually add more contacts to WhatsApp by importing a CSV file with WhatsApp IDs.',
                    )}
                  />
                ),
                areaShift: { top: 36 },
                at: 'left',
              },
            }}
            progressConfig={{
              current: 2,
              total: TOTAL_STEPS,
            }}
          />
        ),
        STEP_3: ({ stepId, step }) => (
          <DefaultStepView
            onb={onb}
            stepId={stepId}
            pointerConfig={{
              type: PointerType.DOM,
              elementId: 'contact-import-dropzone',
              blockAreaInput: true,
              tooltip: {
                component: (
                  <TooltipDefault
                    className={cm.tooltip}
                    onNextClick={() => step.process()}
                    btnNext={l.makeTranslate('Got It')}
                    text={l.makeTranslate(
                      'Here you can upload the CSV file with WhatsApp IDs and other contact data. You will be able to manage it on the next step.',
                    )}
                  />
                ),
                at: 'right',
              },
            }}
            progressConfig={{
              current: 3,
              total: TOTAL_STEPS,
            }}
          />
        ),
      }}
    />
  )
}
export default ImportContactTourView

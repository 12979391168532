import { l } from '@manychat/manyui'

import OnboardingId from 'utils/services/newOnboardingService/OnboardingId'

import { IgOnboardingTourType } from './interfaces'

export const IgOnboardingTourConfig = {
  [IgOnboardingTourType.CGT_PRODUCT_TOUR]: {
    title: l.makeTranslate('Set up Instagram Comment'),
    description: l.makeTranslate('Start learning with our detailed guide'),
    onboardingId: OnboardingId.CGT_FLOW_OVERVIEW_TOUR,
    analyticEvent: 'CGT_ONBOARDING_START.SHOWN',
  },
}

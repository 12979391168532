import { api } from 'shared/api'

import { UserInactivityDeletionSchemas } from './schemas'

export const UserInactivityDeletionApi = {
  confirmActivity: api.base.createPost({
    url: '/userInactivityDeletion/stop',
    schemas: UserInactivityDeletionSchemas.confirmActivity,
  }),
}

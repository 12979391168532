import { windowWidth } from 'utils'
import { MenuState, MenuChannels, IMenuChannelState, FetchStatus } from 'apps/menu/menuInterfaces'
import { ChannelType } from 'common/core/constants/ChannelType'

export const getInitialMenuChannelState = (channel: ChannelType): IMenuChannelState => ({
  channel,
  name: null,
  enabled: null,
  version: null,

  isMain: false,

  isFetched: false,
  errorOnLoad: null,
  isBusy: false,
  isAutoSaving: false,
  selectedId: null,
  showErrors: false,
  serverError: null,

  lastSaved: null,
  lastChanged: null,
  saveError: null,

  flowNs: null,

  draft: {
    byId: {},
    nested: [],
  },
  published: {
    byId: {},
    nested: [],
  },
})

export const getInitialMenuListState = () => ({
  items: [],
  fetchStatus: FetchStatus.UNSENT,
  createStatus: FetchStatus.UNSENT,
})

export const getInitialMenuState = (): MenuState => ({
  ...MenuChannels.reduce((state, channel) => {
    state[channel] = getInitialMenuChannelState(channel)

    return state
  }, {} as MenuState),
  list: getInitialMenuListState(),
})

export const updateStateChannel = (
  state: MenuState,
  channel: ChannelType,
  newChannelState: Partial<IMenuChannelState>,
): MenuState => ({
  ...state,
  [channel]: {
    ...state[channel],
    ...newChannelState,
  },
})

export const isMenuPageWide = () => {
  return windowWidth() > 1210
}

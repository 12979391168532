const WEEK_DAY_COUNT = 7

function getDay(input: Date, weekStartsOnSunday = false) {
  const day = input.getDay()
  if (weekStartsOnSunday) {
    return day
  }
  return day === 0 ? 6 : day - 1
}

export function getWeekStartDate(input: Date, weekStartsOnSunday = false) {
  const result = new Date(input)
  result.setDate(input.getDate() - getDay(input, weekStartsOnSunday))
  return result
}

export const getDaysGrid = (year: number, monthIndex: number, weekStartsOnSunday = false) => {
  const input = new Date(year, monthIndex)
  const iterator = getWeekStartDate(input, weekStartsOnSunday)
  const result = []

  for (let i = 0; i < 6; i++) {
    const row = []
    for (let j = 0; j < WEEK_DAY_COUNT; j++) {
      const date = iterator.getDate()
      const isAnotherMonth = iterator.getMonth() !== monthIndex
      row.push({ date, isAnotherMonth })
      iterator.setDate(date + 1)
    }
    result.push(row)
  }

  return result
}

import { Icon, l } from '@manychat/manyui'

import * as activeCampaignActions from 'common/actions/integrations/ActiveCampaign/actions/activeCampaignModalActions'
import {
  IntegrationType,
  IntegrationNameByType,
} from 'common/actions/integrations/common/constants/IntegrationType'
import {
  createIntegration,
  createIntegrationAction,
} from 'common/actions/integrations/common/model/helpers'
import { KnowledgeBaseLink } from 'common/core/components/SupportLinks'
import { AccountTargetPermissionsNames } from 'common/core/models/Account/constants'
import { analyticsService } from 'utils/services/analytics'

export const ActiveCampaignActionTypes = {
  ADD_CONTACT: 'subscriber.add',
}

const handleSupportLinkClick = () => {
  analyticsService.sendEvent('SUPPORT.LINK_SETTINGS_INTEGRATIONS_ACTIVE_CAMPAIGN_CLICK', {
    url: window.location.pathname,
  })
}

export const ActiveCampaignConfig = createIntegration({
  type: IntegrationType.ACTIVE_CAMPAIGN,
  name: IntegrationNameByType[IntegrationType.ACTIVE_CAMPAIGN],
  icon: Icon.Activecampaign,
  permissionsVariable: AccountTargetPermissionsNames.ACTIVE_CAMPAIGN_INTEGRATION,
  isBeta: false,
  description: () =>
    l.translate(
      'ActiveCampaign is an email marketing, marketing automation, sales automation, and CRM software platform for small-to-mid-sized businesses. <a href="{link}" target="_blank" class="text-primary pointer" onclick="handleSupportLinkClick" rel="noreferrer" >Learn more</a>',
      { handleSupportLinkClick, link: KnowledgeBaseLink.ACTIVECAMPAIGN_INTEGRATION },
    ),
  reduxActions: activeCampaignActions,
  actions: [
    createIntegrationAction({
      type: ActiveCampaignActionTypes.ADD_CONTACT,
      description: l.translate(
        'Subscribe an email address to one of your contacts and update your ActiveCampaign Contacts list.',
      ),
      name: l.translate('Add Contact'),
    }),
  ],
})

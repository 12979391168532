import { ChannelType } from 'common/core/constants/ChannelType'
import { UserFieldType } from 'common/fields/constants/UserFieldsTypes'
import { VirtualFlexibleAgent } from 'shared/api/requests/aiAgent/schemas'
import { AiAgentPlaygroundExecutionContext } from 'shared/api/requests/aiAgentPlayground/schemas'

export interface AiAgentPlaygroundState {
  sessionId: string | null
  subscriberId: string | null
  contextContentId: string | null
  flowId: string | null
  nodeContentId: number | null
  executionContext: AiAgentPlaygroundExecutionContext | null
  agent: VirtualFlexibleAgent | null
  messages: PlaygroundMessage[]
  isSubscriberDependentAgent: boolean
  isConversationOngoing: boolean
  isInteractable: boolean
  isWaitingForAgentReply: boolean
  channel: ChannelType | null
}

export enum PlaygroundMessageType {
  TEXT = 'text',
  DEBUG = 'debug',
  SUBSCRIBER_DATA = 'subscriber_data',
  COLLECTED_INFO = 'collected_info',
}

export enum PlaygroundMessageAuthor {
  ASSISTANT = 'assistant',
  USER = 'user',
}

interface CollectedInfo {
  type: UserFieldType
  field: string
  value: string | boolean | number
}

export interface BackendPlaygroundTextMessage {
  id: string
  author: PlaygroundMessageAuthor
  ts: number
  type: 'text'
  data: {
    text: string
    buttons?: Array<{ url: string; title: string }>
    status: 'ongoing' | 'completed'
    collected_info: CollectedInfo[]
  }
}

export interface PlaygroundTextMessage {
  type: PlaygroundMessageType.TEXT
  id: string
  author: PlaygroundMessageAuthor
  data: {
    text: string
    buttons?: Array<{ url: string; title: string }>
  }
}

export interface PlaygroundDebugMessage {
  type: PlaygroundMessageType.DEBUG
  id: string
  author: PlaygroundMessageAuthor.ASSISTANT
  data: {
    debug_info: Array<{ field: string; value: string }>
  }
}

export interface PlaygroundSubscriberDataMessage {
  type: PlaygroundMessageType.SUBSCRIBER_DATA
  id: string
  author: PlaygroundMessageAuthor.ASSISTANT
  data: {
    fields: Array<{ field_id: string; value: string }>
  }
}

export interface PlaygroundCollectedInfoMessage {
  type: PlaygroundMessageType.COLLECTED_INFO
  id: string
  author: PlaygroundMessageAuthor.ASSISTANT
  data: {
    fields: CollectedInfo[]
  }
}

export type PlaygroundMessage =
  | PlaygroundTextMessage
  | PlaygroundDebugMessage
  | PlaygroundSubscriberDataMessage
  | PlaygroundCollectedInfoMessage

export enum AiAssistantSocketEvent {
  MESSAGE_RECEIVED = 'ai_agent_playground_message',
  PLAYGROUND_FAILED = 'ai_agent_playground_failed',
}

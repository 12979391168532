import React from 'react'
import cx from 'classnames'
import { palettes } from '@manychat/manyui'

import { IFlatOption } from 'components/AutocompleteElements/OptionsList/types'

import OptionIcon from './OptionIcon'

import cm from './OptionItem.module.css'

export interface IRenderOptionFunction {
  (option: IFlatOption, focused: boolean): React.ReactElement | null
}

interface OptionProps {
  isHidden?: boolean
  focused?: boolean
  onClick?: (option: IFlatOption) => void
  onFocus?: (option: IFlatOption) => void
  option: IFlatOption
  renderOption?: IRenderOptionFunction
  showIcon?: boolean
}

const OptionItem = ({
  isHidden,
  focused,
  option,
  onClick,
  onFocus,
  renderOption,
  showIcon = true,
}: OptionProps) => {
  const optionRef = React.useRef<HTMLLIElement>(null)
  React.useEffect(() => {
    const element = optionRef.current
    if (focused && element && typeof element.scrollIntoView === 'function') {
      element.scrollIntoView({ block: 'nearest' })
    }
  }, [focused])

  const handleClick: React.MouseEventHandler = () => {
    onClick && onClick(option)
  }

  const handleMouseMove: React.MouseEventHandler = () => {
    if (focused) {
      return
    }
    onFocus && onFocus(option)
  }

  const background = focused ? palettes.typography.hover : palettes.typography.contentBG

  const shouldShowDescription =
    option.description &&
    typeof option?.parent?.label === 'string' &&
    option?.parent?.label.toLowerCase() === 'recommended'

  return (
    <li
      ref={optionRef}
      onClick={handleClick}
      onMouseMove={handleMouseMove}
      className={cx({
        'd-none': isHidden,
        'd-flex align-center c-pointer': !isHidden,
      })}
      style={{ background }}
      data-title={option.tooltip}
      data-title-at="right"
      data-test-id={`option-${option.value}`}
      data-onboarding-id={`option-${option.value}`}
    >
      {renderOption ? (
        renderOption(option, Boolean(focused))
      ) : (
        <>
          {showIcon && <OptionIcon option={option} />}
          <span>
            <div className={cm.label}>{option.label}</div>
            {shouldShowDescription && (
              <div className={cx(cm.description, 'text-sm p-t-xxs')}>{option.description}</div>
            )}
          </span>
        </>
      )}
    </li>
  )
}

export default OptionItem

import * as atypes from 'apps/agency/constants/AgencyReduxActionTypes'
import { AGENCY_FILTER } from 'apps/agency/models/Agency/constants'
import { makeLimitedListReducer } from 'utils/factory'

const initialState = {
  byId: {},
  items: [],
  query: '',
  filtersData: {
    countries: [],
    industries: {},
    isFetching: false,
    isFetched: false,
  },
  filters: {
    [AGENCY_FILTER.name]: '',
    [AGENCY_FILTER.country]: null,
    [AGENCY_FILTER.industry]: null,
  },
}

const agencyListingReducer = makeLimitedListReducer('agency_listing', { initialState })

export default function listingReducer(state = initialState, action) {
  state = { ...state, ...agencyListingReducer(state, action) }

  switch (action.type) {
    case atypes.AGENCY_LISTING_FETCH_FILTERS_REQUEST: {
      return {
        ...state,
        filtersData: {
          ...state.filtersData,
          isFetching: true,
        },
      }
    }
    case atypes.AGENCY_LISTING_FETCH_FILTERS_RESPONSE: {
      const { countries, industries } = action.data
      return {
        ...state,
        filtersData: {
          ...state.filtersData,
          countries,
          industries,
          isFetching: false,
          isFetched: true,
        },
      }
    }
    case atypes.AGENCY_LISTING_FETCH_FILTERS_ERROR: {
      return {
        ...state,
        filtersData: {
          ...state.filtersData,
          isFetching: false,
        },
      }
    }
  }

  return state
}

import React from 'react'
import cx from 'classnames'
import { Icon } from '@manychat/manyui'

import { FsQueryOrderType } from 'apps/cms/lib/constants'

import cm from './ListSortButton.module.css'

interface ListSortButtonProps {
  onClick: () => void
  order: FsQueryOrderType
  children: string
  isSelectedField: boolean
}

const SIZE_ICON = 16

export const ListSortButton = ({
  onClick,
  order,
  isSelectedField,
  children,
}: ListSortButtonProps) => {
  return (
    <button onClick={onClick} className={cm.sortButton}>
      {children}

      <div className="d-flex flex-col">
        <Icon.ArrowDropUp
          className={cx(cm.sortUpIcon, {
            [cm.sortIconActive]: order === FsQueryOrderType.ASC && isSelectedField,
          })}
          size={SIZE_ICON}
        />
        <Icon.ArrowDropDown
          className={cx(cm.sortDownIcon, {
            [cm.sortIconActive]: order === FsQueryOrderType.DESC && isSelectedField,
          })}
          size={SIZE_ICON}
        />
      </div>
    </button>
  )
}

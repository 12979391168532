import React from 'react'

import { IndeterminateIconProps } from '../interfaces'

export const IndeterminateIcon = ({ className }: IndeterminateIconProps) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      className={className}
    >
      <rect x="1" y="1" width="16" height="16" rx="3" stroke="none" />
      <rect x="1" y="1" width="16" height="16" rx="3" stroke="none" strokeWidth="2" />
      <path d="M4 9H14" strokeWidth="2" strokeLinecap="round" />
    </svg>
  )
}

import * as API from 'constants/API'

const getEntityConfig = (entity) => {
  const entityConfig = API[entity]
  if (entityConfig == null) {
    throw new Error(`Unknown entity: ${entity}`)
  }
  return entityConfig
}

const getCustomEndpoints = (urls) =>
  Object.keys(urls).filter(
    (u) => !['drop', 'fetch', 'fetchItem', 'create', 'save', 'delete', 'archive'].includes(u),
  )

export const getCustomActions = (entity, prefix) => {
  const entityConfig = getEntityConfig(entity)
  const { endpoints } = entityConfig

  const actions = {}

  const otherEndpoints = getCustomEndpoints(endpoints)
  for (let endpointName of otherEndpoints) {
    actions[endpointName] = (urlParams, body, actionParams = {}) => {
      urlParams = urlParams || {}
      if (typeof urlParams === 'string' || Number.isFinite(urlParams)) {
        urlParams = { id: urlParams }
      }

      return {
        type: `${prefix}_${endpointName.toUpperCase()}`,
        $endpoint: [`${entity}.${endpointName}`, urlParams, body],
        id: urlParams.id || null,
        body,
        ...actionParams,
      }
    }
  }

  return actions
}

import * as atypes from 'common/actions/integrations/common/constants/IntegrationsReduxActionTypes'
import { integrations as API } from 'constants/API'

const endpoints = API.endpoints.mailchimp

export const setAccountLinkField = ({ field_id, field_name }) => ({
  type: atypes.MAILCHIMP_SET_ACCOUNT_LINK_FIELD,
  $fetch: [
    endpoints.setAccountLinkField,
    {
      method: 'POST',
      body: JSON.stringify({
        field_id,
        field_name,
      }),
      headers: { 'Content-Type': 'application/json' },
    },
  ],
})

export const clearAccountLinkField = () => ({
  type: atypes.MAILCHIMP_CLEAR_ACCOUNT_LINK_FIELD,
  $fetch: endpoints.clearAccountLinkField,
})

export const fetchLists = () => ({
  $fetch: endpoints.getLists,
  type: atypes.MAILCHIMP_LISTS_FETCH,
})

import React from 'react'
import cx from 'classnames'
import random from 'lodash/random'
import { Skeleton } from '@manychat/manyui'

import cardCm from './FolderCard.module.css'
import cm from './FolderCardSkeleton.module.css'

export const FolderCardSkeleton = React.memo(() => (
  <div className={cardCm.folderCard}>
    <Skeleton.Text className={cx(cm.iconSkeleton)} />

    <div>
      <Skeleton.Text className={cm.textSkeleton} minLineWidth={`${random(60, 120)}px`} />
    </div>
  </div>
))

import { createSelector } from 'reselect'

import { IFolder } from 'common/core/interfaces/folder'

export const getGlobalFieldFoldersState = (state: RootState) => state.globalFieldFolders

export const getGlobalFieldFolders = (state: RootState) => {
  const sortFolders = (folder1: IFolder, folder2: IFolder) =>
    folder1.title.localeCompare(folder2.title)

  return state.globalFieldFolders?.items ? state.globalFieldFolders.items.sort(sortFolders) : []
}

export const getGlobalFieldFoldersMap = (state: RootState) => {
  return state.globalFieldFolders.byId || {}
}

export const isGlobalFieldFoldersFetching = (state: RootState) => state.globalFieldFolders.fetching

export const getGlobalFieldsCountInFolder = (state: RootState, folderId: number) =>
  state.globalFieldFolders.byId[folderId].field_count

export const getIsFolderNameExists = createSelector(
  [getGlobalFieldFoldersState, (_, title: string) => title],
  (state, title) => {
    return state.items?.some((folder) => folder.title === title)
  },
)

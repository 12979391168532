import { useCallback, useEffect, useState } from 'react'
import debugConstructor from 'debug'

import { useUsercentrics } from 'utils/commonHooks'

const debug = debugConstructor('wootric')

export const USERCENTRICS_WOOTRIC_NAME = 'Wootric'

interface UseWootrikHook {
  sendWootricEvent: (eventName: string) => void
  isReady: boolean
}

export const useWootric = (): UseWootrikHook => {
  const { usercentricsServicesInfo } = useUsercentrics(USERCENTRICS_WOOTRIC_NAME)

  const isProvideWootric = usercentricsServicesInfo[USERCENTRICS_WOOTRIC_NAME]?.status ?? false
  const isInitializedWootric = Boolean(window.wootric)
  const isWootricSettings = Boolean(window.wootricSettings)

  const [isReady, setIsReady] = useState(
    isProvideWootric && isInitializedWootric && isWootricSettings,
  )

  const handleLoad = useCallback(() => {
    if (!window.wootric) {
      return
    }
    setIsReady(true)
  }, [])

  const sendWootricEvent = useCallback((eventName: string) => {
    if (typeof window.wootric === 'function') {
      window.wootric(eventName)
    } else {
      debug('wootricTrigger', eventName)
    }
  }, [])

  useEffect(() => {
    if (!isProvideWootric || isInitializedWootric || !isWootricSettings) {
      return
    }

    const script = document.createElement('script')
    script.src = `https://cdn.wootric.com/wootric-sdk.js`
    script.async = true
    script.onload = handleLoad
    document.head.appendChild(script)
  }, [handleLoad, isInitializedWootric, isProvideWootric, isWootricSettings])

  return { sendWootricEvent, isReady }
}

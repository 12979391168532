import assert from 'assert'

import { unlinkButton } from 'common/builder/actions/unLinkButton'
import { isSmartLink } from 'common/builder/buttons/buttonTypeHelpers'
import { LINK_BLOCK_AND_BUTTON } from 'common/builder/constants/builderReduxActionTypes'
import BlockModel from 'common/builder/models/Block'
import {
  getBlockById,
  getButtonsMap,
  getParentBlock,
  getParentNode,
} from 'common/builder/selectors/builder/entitySelectors'

/**
 * @param builderId
 * @param blockId
 * @param targetId
 * @param options - {relink}
 */
export const linkButton = (builderId, blockId, targetId, options = {}) => {
  return (dispatch, getState) => {
    const state = getState()

    assert(builderId, `linkButton: builderId is required param`)
    assert(blockId, `linkButton: blockId is required param`)
    assert(targetId, `linkButton: targetId is required param`)

    const item = getBlockById(state, builderId, blockId)
    assert(item, `linkButton: block doesn't exist`)

    const buttons = getButtonsMap(state, builderId)
    const targetItem = buttons[targetId]
    assert(targetItem, `linkButton: targetItem doesn't exist`)

    const isTargetSmartLink = isSmartLink(targetItem)

    const targetParentItem = getParentBlock(state, builderId, targetId)
    if (options.relink) {
      if (targetParentItem) {
        dispatch(unlinkButton(builderId, targetParentItem.id, targetId))
      }
    } else {
      assert(!targetParentItem, `linkButton: button "${targetId}" already linked`)
    }

    const { nodeType } = getParentNode(state, builderId, blockId)

    assert(
      BlockModel.isNestedButtonAllowed(item, nodeType),
      `linkButton: block type "${item.type}" doesn't allow to link buttons`,
    )
    assert(
      BlockModel.isNestedButtonTypeAllowed(item, targetItem.type, nodeType),
      `linkButton: block type "${item.type}" doesn't allow to link button with type ${targetItem.type}`,
    )

    if (!isTargetSmartLink) {
      assert(
        BlockModel.isAddButtonAllowed(item, nodeType, { buttons, newButtonType: targetItem.type }),
        `linkButton: block with type "${item.type}" may contain not more than "x" buttons`,
      )
    }

    return dispatch({
      type: LINK_BLOCK_AND_BUTTON,
      builderId,
      blockId,
      targetId,
    })
  }
}

import { useLivechatNotifications } from 'apps/chat/hooks/useLivechatNotifications'
import { useWhatsAppChannel } from 'apps/whatsApp/MessageTemplates/hooks/useWhatsAppChannel'

// at this moment there is no good place for that,
// so it will be here in dedicated useless component
export const Notifications = () => {
  useLivechatNotifications()
  useWhatsAppChannel()

  return null
}

import { createContext } from 'react'

interface RadioContextType {
  radioGroupName?: string
  radioGroupValue?: string | null
  radioGroupInvalidState?: boolean
  radioGroupDisabledState?: boolean
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void
}

const RadioGroupContext = createContext<RadioContextType>({})

export default RadioGroupContext

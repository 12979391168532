import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'

import { noop } from '../../../../utils'
import {
  parseDate,
  formatDate,
  parseTime,
  formatTime,
  applyTime,
  isDate,
  isValidDateTime,
} from '../../../../utils/date'
import Popover from '../../../layers/Popover'
import Calendar from '../Calendar'
import DateInput from '../DateInput'
import Time from '../Time'

export default class DatePicker extends PureComponent {
  static propTypes = {
    anchorOrigin: PropTypes.string,
    className: PropTypes.string,
    disabled: PropTypes.bool,
    invalid: PropTypes.bool,
    minDate: PropTypes.string,
    maxDate: PropTypes.string,
    minTime: PropTypes.string,
    maxTime: PropTypes.string,
    name: PropTypes.string,
    onChange: PropTypes.func,
    onBlur: PropTypes.func,
    onFocus: PropTypes.func,
    placeholder: PropTypes.string,
    popoverOrigin: PropTypes.string,
    type: PropTypes.oneOf(['datetime', 'date', 'time']),
    useLayerForClickAway: PropTypes.bool,
    value: PropTypes.oneOfType([PropTypes.instanceOf(Date), PropTypes.string]),
  }

  static defaultProps = {
    anchorOrigin: 'bottom left',
    onChange: noop,
    onBlur: noop,
    onFocus: noop,
    popoverOrigin: 'top left',
    type: 'date',
    useLayerForClickAway: false,
  }

  state = { open: false }

  input = React.createRef()

  blur = () => this.input.current && this.input.current.blur()
  focus = () => this.input.current && this.input.current.focus()
  select = () => this.input.current && this.input.current.select()

  handleBlur = (e) => (this.setState({ open: false }), this.props.onBlur(e))
  handleFocus = (e) => (this.setState({ open: true }, this.select), this.props.onFocus(e))

  handleDateChange = (e) => {
    const { minDate, maxDate, name, type, onChange } = this.props
    let value = ''
    switch (type) {
      case 'date':
        value = e.target.value
        break
      case 'datetime':
        {
          const date = parseDate(e.target.value)

          if (!isDate(date)) {
            value = e.target.value

            break
          }

          applyTime(date, parseDate(this.props.value))

          value = date.toISOString()

          // When date changed previously selected time may become outside limits
          if (isValidDateTime(minDate)) {
            const min = parseDate(minDate)

            if (date < min) {
              value = min.toISOString()
            }
          }

          if (isValidDateTime(maxDate)) {
            const max = parseDate(maxDate)
            if (date > max) {
              value = max.toISOString()
            }
          }
        }
        break
    }
    onChange({ target: { value, name } })
  }

  handleTimeChange = (e) => {
    const { name, type, onChange } = this.props
    let value = ''
    switch (type) {
      case 'datetime':
        {
          const time = parseTime(e.target.value)
          const date = parseDate(this.props.value) || new Date()
          date.setHours(time.hour, time.minute, 0, 0)
          value = date.toISOString()
        }
        break
      case 'time':
        value = e.target.value
        break
    }
    onChange({ target: { value, name } })
  }

  handlePopoverMouseDown = (e) => e.preventDefault()

  handleRequestClose = (reason) => reason !== 'clickAnchor' && this.input.current.blur()

  render() {
    const { open } = this.state
    const {
      anchorOrigin,
      minDate,
      maxDate,
      minTime: propsMinTime,
      maxTime: propsMaxTime,
      popoverOrigin,
      useLayerForClickAway,
      value,
      type,
      ...rest
    } = this.props
    const isDateTime = type === 'datetime'

    let minTime = propsMinTime
    let maxTime = propsMaxTime

    if (isDateTime) {
      const valueDate = formatDate(parseDate(value))
      if (valueDate) {
        const min = isValidDateTime(minDate) ? parseDate(minDate) : null
        const max = isValidDateTime(maxDate) ? parseDate(maxDate) : null
        if (min && formatDate(min) >= valueDate) {
          minTime = formatTime(min)
        }
        if (max && formatDate(max) <= valueDate) {
          maxTime = formatTime(max)
        }
      }
    }

    return (
      <React.Fragment>
        <DateInput
          {...rest}
          ref={this.input}
          value={value}
          onFocus={this.handleFocus}
          onBlur={this.handleBlur}
          type={type}
          minDate={minDate}
          maxDate={maxDate}
        />
        <Popover
          anchor={this.input.current}
          open={open}
          anchorOrigin={anchorOrigin}
          popoverOrigin={popoverOrigin}
          className="m-t-xxs"
          onRequestClose={this.handleRequestClose}
          useLayerForClickAway={useLayerForClickAway}
        >
          <div onMouseDown={this.handlePopoverMouseDown} className="d-flex p-a-xxs">
            {type.includes('date') && (
              <Calendar
                value={isDateTime ? formatDate(parseDate(value)) : value}
                onChange={this.handleDateChange}
                minDate={minDate}
                maxDate={maxDate}
              />
            )}
            {type.includes('time') && (
              <div className={isDateTime ? 'p-l-xxs sm-p-l-md' : undefined}>
                <Time
                  value={isDateTime ? formatTime(parseDate(value)) : value}
                  onChange={this.handleTimeChange}
                  minTime={minTime}
                  maxTime={maxTime}
                />
              </div>
            )}
          </div>
        </Popover>
      </React.Fragment>
    )
  }
}

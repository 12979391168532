import get from 'lodash/get'
export default function (data) {
  return {
    ...data,

    id: get(data, 'sequence_message_id'),
    sequenceId: get(data, 'sequence_id'),
    flowId: get(data, 'namespace'),
    chId: get(data, 'content_holder', null),
  }
}

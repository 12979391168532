import { l } from '@manychat/manyui'

import { ChannelType } from 'common/core/constants/ChannelType'
import { TriggerType } from 'common/flow/constants/TriggerType'
import {
  IBackendFullFlow,
  IBackendAnotherFlowTrigger,
  IBackendConversationStarters,
  BackendMainMenuTrigger,
} from 'common/flow/flowBackendInterfaces'
import {
  ConvertChannelRecord,
  IAnotherFlowTrigger,
  IFlow,
  IFullFlow,
  IceBreakerTrigger,
  MainMenuTrigger,
} from 'common/flow/flowInterfaces'

export const isFullFlow = (flow: null | undefined | IFlow): flow is IFullFlow =>
  Boolean(flow) && 'triggers' in (flow as IFlow)

export const parseFlowStats = (flow: IBackendFullFlow) => {
  const earnedList = flow.stats.earned_list ?? []
  const clicksTotal = flow.stats.clicked_total ?? 0
  const sentTotal = flow.stats.sent_total ?? 0
  const deliveredUnique = flow.stats.delivered_unq ?? 0

  const events = flow.stats.events ?? []
  const EMAILS_EVENTS_ID = -3
  const emailsCollected = events[EMAILS_EVENTS_ID]?.total ?? 0

  const PHONES_EVENTS_ID = -4
  const phonesCollected = events[PHONES_EVENTS_ID]?.total ?? 0

  return {
    earnedList,
    clicksTotal,
    sentTotal,
    deliveredUnique,
    emailsCollected,
    phonesCollected,
  }
}

export const anotherFlowAdapter = (item: IBackendAnotherFlowTrigger): IAnotherFlowTrigger => {
  const { id, title, ts_created } = item

  return {
    id,
    triggerType: TriggerType.ANOTHER_FLOW,
    triggerId: `${TriggerType.ANOTHER_FLOW}-${id}`,

    caption: title,
    enabled: true,
    chId: id,
    anotherFlowId: id,
    ts_created: ts_created || 0,
  }
}

export const iceBreakerAdapter = (
  item: IBackendConversationStarters,
  index: number,
): IceBreakerTrigger => {
  const title = l.translate('Conversation Starter')
  const { caption } = item

  const channel = item.channel ? item.channel : ChannelType.FB
  const id = channel + title + index

  return {
    id,
    triggerType: TriggerType.ICE_BREAKER,
    triggerId: `${TriggerType.ICE_BREAKER}-${id}`,
    channel,
    enabled: true,
    title,
    caption: caption ?? title,
    ts_created: 0,
    settingsEntityId: id,
  }
}

const getMappedMenuItem = ({
  menuItem,
  title,
  caption,
}: {
  menuItem: BackendMainMenuTrigger
  title: string
  caption: string
}): MainMenuTrigger => ({
  id: menuItem.id,
  triggerType: TriggerType.MAIN_MENU,
  triggerId: `${TriggerType.MAIN_MENU}-${menuItem.id}`,
  caption: caption,
  ts_created: menuItem.ts_created ?? 0,
  channel: menuItem.channel ?? ChannelType.FB,
  title,
  enabled: true,
})

export const parseMainManu = (menuItems: BackendMainMenuTrigger[]): MainMenuTrigger[] => {
  const title = l.translate('Main Menu')

  const mappedMenuItems = menuItems.map((menuItem) => {
    if (Array.isArray(menuItem.captions)) {
      return menuItem.captions.map((caption) => {
        return getMappedMenuItem({
          menuItem,
          title,
          caption,
        })
      })
    }

    return getMappedMenuItem({
      menuItem,
      title,
      caption: menuItem.caption || menuItem.title,
    })
  })

  return mappedMenuItems.flat()
}

export const convertFlowChannelsAdapter = (ns: string, data: Partial<ConvertChannelRecord>) => {
  return {
    flow_ns: ns,
    convert_channels: data,
  }
}

export const hasFlowChannels = (flow: IFlow | null | undefined) => {
  return {
    hasFbChannel: Boolean(flow?.has_fb_channel),
    hasInstagramChannel: Boolean(flow?.has_instagram_channel),
    hasTelegramChannel: Boolean(flow?.has_telegram_channel),
    hasWhatsAppChannel: Boolean(flow?.has_whatsapp_channel),
    hasEmailChannel: Boolean(flow?.has_email_channel),
    hasSmsChannel: Boolean(flow?.has_sms_channel),
    hasTiktokChannel: Boolean(flow?.has_tiktok_channel),
  }
}

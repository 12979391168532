import {
  CGTTemplatesIds,
  StoryReplyTemplatesIds,
} from 'apps/quickCampaigns/selectTemplateModal/constants'

import { IgOnboardingTourConfig } from '../constants'
import { IgOnboardingTourType } from '../interfaces'

export const isCGTTemplate = (quickCampaignId: number) => {
  if (!quickCampaignId) return false
  return CGTTemplatesIds.includes(quickCampaignId)
}

export const isStoryReplyTemplate = (quickCampaignId: number) => {
  if (!quickCampaignId) return false
  return StoryReplyTemplatesIds.includes(quickCampaignId)
}

export const getIgOnboardingTourStartConfig = (quickCampaignId: number) => {
  if (isCGTTemplate(quickCampaignId))
    return IgOnboardingTourConfig[IgOnboardingTourType.CGT_PRODUCT_TOUR]

  return null
}

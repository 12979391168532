import { IStrategy, StartActionType } from 'apps/quickCampaigns/quickCampaignsInterfaces'
import { QuickCampaign } from 'shared/api/requests/quickCampaign/schemas'

import { LegacyStrategyExtraData } from './legacyStrategyExtraData'

export function quickCampaignAdapter(flowCampaign: QuickCampaign): IStrategy {
  const {
    image_url,
    title,
    description,
    quick_campaign_id,
    growth_tools_reserved,
    video_id,
    video_url,
    sort,
    channels,
    is_pro,
    is_popular,
    is_ai,
    industries,
    use_cases,
    long_description,
    how_it_works,
    business_value,
    image_show_more_url,
    experience_level,
    is_used,
    modal_background_color,
    scoring_easy,
    scoring_popular,
    use_in,
    template_id,
    installations_count,
  } = flowCampaign
  const id = quick_campaign_id
  const legacyStrategy = LegacyStrategyExtraData[id]

  return {
    id,
    quick_campaign_id,
    preview: image_url ?? '',
    title,
    description: description ?? undefined,
    isCompleted: false,
    isPRO: is_pro,
    isAI: is_ai,
    isPopular: is_popular,
    videoId: video_id,
    videoUrl: video_url ?? undefined,
    strategyId: legacyStrategy?.strategyId,
    startAction: StartActionType.FLOW,
    flowInfo: {
      name: title,
      preset: `campaign-${id}`,
      growthToolsReserved: growth_tools_reserved,
    },
    articles: legacyStrategy?.articles || [],
    channels,
    industries,
    useCases: use_cases,
    categories: [],
    sort,
    longDescription: long_description,
    howItWorks: how_it_works,
    businessValue: business_value,
    imageShowMoreUrl: image_show_more_url,
    experienceLevel: experience_level,
    isUsed: is_used,
    mediaBackground: modal_background_color,
    scoring_popular: scoring_popular,
    scoring_easy,
    useIn: use_in,
    templateId: template_id,
    installationsCount: installations_count,
  }
}

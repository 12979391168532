import React, { useCallback } from 'react'
import cx from 'classnames'
import { BtnV2, l } from '@manychat/manyui'

import img from './img.svg'

import cm from './OnboardingLeaveModal.module.css'

interface IOnboardingLeaveModal {
  open: boolean
  onClose: () => void
  onLeave: () => void
}

const OnboardingLeaveModal = ({ open, onClose, onLeave }: IOnboardingLeaveModal) => {
  const handleLeave = useCallback(() => {
    onClose()
    onLeave()
  }, [onClose, onLeave])

  if (!open) {
    return null
  }

  return (
    <div
      data-test-id="onboarding-modal"
      className={cx(cm.root, 'p-fixed d-flex align-center justify-center')}
    >
      <div
        className={cx(
          cm.wrapper,
          'bg-white rounded p-x-lg p-t-md p-b-lg d-flex flex-col align-center text-center',
        )}
      >
        <img src={img} width={180} height={133} alt={l.translate('Leave tour?')} />
        <h1 className="m-t-xxs">
          {l.translate(
            'You’ve clicked outside the tutorial scenario. Do you want to leave the tutorial?',
          )}
        </h1>
        <BtnV2
          label={l.translate('Back to tour')}
          className="m-t-lg"
          onClick={onClose}
          variant="primary"
        />
        <a onClick={handleLeave} className="text-secondary m-t">
          {l.translate('Leave tour')}
        </a>
      </div>
    </div>
  )
}

export default OnboardingLeaveModal

import React from 'react'

import * as l from '../../../utils/localization/format'
import BtnV2 from '../../BtnV2'

import { PopoverContext } from './PopoverContext'

interface HeaderProps {
  fullscreenOnly?: boolean
  children?: React.ReactNode
}

const Header = ({ children, fullscreenOnly }: HeaderProps) => {
  const { onRequestClose, fullscreen } = React.useContext(PopoverContext)

  const handleClose = () => {
    onRequestClose('closeClick')
  }

  if (fullscreenOnly && !fullscreen) return null

  return (
    <div
      className={`d-flex flex-shrink-0 p-l-md p-r-xs justify-between align-center b-b`}
      style={{ height: 60 }}
    >
      <h2>{children}</h2>
      <BtnV2
        onClick={handleClose}
        variant="ghost-primary"
        className="p-a-xs"
        onlyIcon="Close"
        ariaLabel={l.translate('Close')}
      />
    </div>
  )
}

export default Header

import { combineReducers } from 'redux'

import installation from 'apps/applicationInstallation/reducers'
import { makeReducer as makeListReducer } from 'apps/applications/redux/applications'
import { reducer as settingsReducer } from 'apps/applications/redux/installedApplications'
import { reducer as publish } from 'apps/applications/redux/publish'
import updateApplication from 'common/settings/components/SettingsPanel/AppSection/installedApplications/reducers/updateInstalledApplication'
import usesList from 'common/settings/components/SettingsPanel/AppSection/installedApplications/reducers/usesListReducer'

import current from './currentApplication'
import reviewReport from './reviewReport'

export default combineReducers({
  current,
  installation,
  list: makeListReducer(),
  publish,
  reviewReport,
  settings: settingsReducer,
  updateApplication,
  usesList,
})

import { api } from 'shared/api'
import { iceBreakersSchemas } from 'shared/api/requests/iceBreakers/schemas'

export const iceBreakersApi = {
  get: api.account.createGet({
    url: '/iceBreakers/get',
    schemas: iceBreakersSchemas.get,
  }),
  publish: api.account.createPost({
    url: '/iceBreakers/publish',
    schemas: iceBreakersSchemas.publish,
  }),
  setAvailable: api.account.createPost({
    url: '/iceBreakers/setAvailable',
    schemas: iceBreakersSchemas.setAvailable,
  }),
}

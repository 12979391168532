import BaseButtonConfig from 'common/builder/buttons/BaseButtonConfig'
import { IButtonBase, IShareButton } from 'common/builder/buttons/buttonInterfaces'
import { ButtonType } from 'common/builder/constants/ButtonType'

class ShareButtonConfig extends BaseButtonConfig {
  buttonType = ButtonType.SHARE

  getDefaults = (): Omit<IShareButton, keyof IButtonBase> => ({})
}

export default new ShareButtonConfig()

import locationImg from 'assets/img/fb-location.png'

import { IButtonUsedInQuickReply, IFlowButton } from 'common/builder/buttons/buttonInterfaces'
import { NodeType } from 'common/builder/constants/NodeType'
import { INode } from 'common/builder/nodes/nodeInterfaces'

export const getNodeCtrUniqueUsers = (node: INode): number => {
  const uniqueUsers = [NodeType.INSTAGRAM, NodeType.TELEGRAM].includes(node?.nodeType)
    ? node?.stats?.sentUsers || 0
    : node?.stats?.deliveredUniqueUsers || 0

  return uniqueUsers
}

export function getReplyImageSrc(
  reply: IFlowButton | IButtonUsedInQuickReply,
  ignoreLocation?: boolean,
) {
  if (('send_location' in reply ? reply.send_location : false) && !ignoreLocation) {
    return locationImg
  }

  if (reply.image) {
    if ('preview' in reply.image) {
      return reply.image.preview
    }

    return 'img_big' in reply.image ? reply.image.img_big : null
  }

  if (reply.emoji) {
    const parts = reply.emoji.split('/')
    /**
     * @example
     * https://app.manychat.com/assets/img/emoji/v3/smileys-&-people/1f601.png
     * https://app.manychat.io/assets/img/emoji/v3/smileys-&-people/1f601.png
     * http://localhost:8080/assets/img/emoji/v3/smileys-&-people/1f601.png
     */
    return `/assets/img/emoji/v3/${parts[0]}/${parts[1]}.png`
  }

  return null
}

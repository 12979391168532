import cloneDeep from 'lodash/cloneDeep'
import merge from 'lodash/merge'
import without from 'lodash/without'
import { v4 as uuid } from 'uuid'

import { IButton, IButtonConfig, DeepPartial } from 'common/builder/buttons/buttonInterfaces'
import { ButtonType } from 'common/builder/constants/ButtonType'
import EntityType from 'common/builder/constants/EntityType'
import errorTrackingService from 'utils/services/errorTrackingService'

export default class BaseButtonConfig implements IButtonConfig {
  buttonType?: ButtonType = undefined

  static BUTTON_BASE_ATTRIBUTES = ['id', 'type', 'entity']

  static BUTTON_TEMPORARY_ATTRIBUTES = ['$draft']

  static BUTTON_COMMON_DEFAULTS = {
    caption: '',
    stats: null,
  }

  getDefaults = () => ({})

  getAttributes = () => {
    const defaults = this.getDefaults()
    return [
      ...Object.keys({
        ...defaults,
        ...BaseButtonConfig.BUTTON_COMMON_DEFAULTS,
      }),
      ...BaseButtonConfig.BUTTON_BASE_ATTRIBUTES,
      ...BaseButtonConfig.BUTTON_TEMPORARY_ATTRIBUTES,
    ]
  }

  create = (initial: DeepPartial<IButton> = {}) => {
    const base = {
      id: initial.id || uuid(),
      type: this.buttonType,
      entity: EntityType.BUTTON,
    }

    const initialAttributes = Object.keys(initial)
    const knownAttributes = this.getAttributes()
    const unknownAttributes = without(initialAttributes, ...knownAttributes)
    if (unknownAttributes.length) {
      const attrs = unknownAttributes.join()
      const payload = { attrs, type: this.buttonType }
      const errorId = 'button-create-unknown-attrs'
      errorTrackingService.trackWarningOnce('Button create unknown attrs', {
        extra: payload,
        fingerprint: errorId,
      })
      console.warn(`Button create: "${attrs}" isn't listed in BaseButtonConfig defaults`)
    }

    const defaults = cloneDeep({
      ...this.getDefaults(),
      ...BaseButtonConfig.BUTTON_COMMON_DEFAULTS,
    })
    return merge(defaults, initial, base) as IButton
  }
}

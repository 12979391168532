import React from 'react'

import { BreadcrumbsButton } from 'apps/cms/components/Flows/components/CmsBreadcrumbs/components/BreadcrumbsButton/BreadcrumbsButton'
import { BreadcrumbsMenu } from 'apps/cms/components/Flows/components/CmsBreadcrumbs/components/BreadcrumbsMenu/BreadcrumbsMenu'
import { useSegments } from 'apps/cms/components/Flows/components/CmsBreadcrumbs/hooks/useSegments'
import { Breadcrumbs } from 'components/Breadcrumbs/Breadcrumbs'

export const CmsBreadcrumbs = React.memo(function CmsBreadcrumbs() {
  const segments = useSegments()

  return (
    <Breadcrumbs
      segments={segments}
      renderBreadcrumbsButton={(props) => <BreadcrumbsButton {...props} />}
      renderBreadcrumbsMenu={(props) => <BreadcrumbsMenu {...props} />}
    />
  )
})

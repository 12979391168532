import React from 'react'
import { Icon } from '@manychat/manyui'

import cm from './FilterPickerCheckbox.module.css'

interface FilterPickerCheckboxProps {
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void
  checked: boolean
  id: string
}

export const FilterPickerCheckbox = ({ onChange, checked, id }: FilterPickerCheckboxProps) => {
  return (
    <div className={cm.filterCheckbox}>
      <input
        type="checkbox"
        id={id}
        className={cm.filterCheckbox__input}
        onChange={onChange}
        checked={checked}
      />
      <Icon.Tick size={16} className={cm.filterCheckbox__icon} />
    </div>
  )
}

export const FLOW_FETCH = 'FLOW_FETCH'
export const FLOW_FETCH_REQUEST = 'FLOW_FETCH_REQUEST'
export const FLOW_FETCH_RESPONSE = 'FLOW_FETCH_RESPONSE'
export const FLOW_FETCH_ERROR = 'FLOW_FETCH_ERROR'

export const FLOW_DATA_FETCH = 'FLOW_DATA_FETCH'
export const FLOW_DATA_FETCH_REQUEST = 'FLOW_DATA_FETCH_REQUEST'
export const FLOW_DATA_FETCH_RESPONSE = 'FLOW_DATA_FETCH_RESPONSE'
export const FLOW_DATA_FETCH_ERROR = 'FLOW_DATA_FETCH_ERROR'

export const FLOW_CONTENT_DATA_FETCH_REQUEST = 'FLOW_CONTENT_DATA_FETCH_REQUEST'
export const FLOW_CONTENT_DATA_FETCH_RESPONSE = 'FLOW_CONTENT_DATA_FETCH_RESPONSE'
export const FLOW_CONTENT_DATA_FETCH_ERROR = 'FLOW_CONTENT_DATA_FETCH_ERROR'

export const FLOW_RESET = 'FLOW_RESET'
export const FLOW_SET_HAS_DRAFT = 'FLOW_SET_HAS_DRAFT'
export const FLOW_SET_DRAFT_SUCCESS = 'FLOW_SET_DRAFT_SUCCESS'
export const FLOW_UPDATE_NAME_SUCCESS = 'FLOW_UPDATE_NAME_SUCCESS'
export const FLOW_UPDATE_SHARED_SUCCESS = 'FLOW_UPDATE_SHARED_SUCCESS'
export const FLOW_UPDATE_SHARED_CLONING_SUCCESS = 'FLOW_UPDATE_SHARED_CLONING_SUCCESS'

export const FLOW_EXPORT = 'FLOW_EXPORT'
export const FLOW_EXPORT_SUCCESS = 'FLOW_EXPORT_SUCCESS'
export const FLOW_EXPORT_ERROR = 'FLOW_EXPORT_ERROR'
export const FLOW_EXPORT_COMPLETE = 'FLOW_EXPORT_COMPLETE'

export const FLOW_FETCH_DEPENDENCIES = 'FLOW_FETCH_DEPENDENCIES'
export const FLOW_FETCH_DEPENDENCIES_REQUEST = 'FLOW_FETCH_DEPENDENCIES_REQUEST'
export const FLOW_FETCH_DEPENDENCIES_RESPONSE = 'FLOW_FETCH_DEPENDENCIES_RESPONSE'
export const FLOW_FETCH_DEPENDENCIES_ERROR = 'FLOW_FETCH_DEPENDENCIES_ERROR'

export const MERGE_FLOW_ITEMS = 'MERGE_FLOW_ITEMS'
export const FLOW_TRIGGER_UPDATED = 'FLOW_TRIGGER_UPDATED'
export const FLOW_TRIGGER_REMOVED = 'FLOW_TRIGGER_REMOVED'

//import shared flow
export const SHARED_FLOW_IMPORT = 'SHARED_FLOW_IMPORT'
export const SHARED_FLOW_IMPORT_SUCCESS = 'SHARED_FLOW_IMPORT_SUCCESS'
export const SHARED_FLOW_IMPORT_ERROR = 'SHARED_FLOW_IMPORT_ERROR'
export const SHARED_FLOW_IMPORT_COMPLETE = 'SHARED_FLOW_IMPORT_COMPLETE'

export const CONVERT_FLOW_CONTENT_CHANNEL = 'CONVERT_FLOW_CONTENT_CHANNEL'

export const FLOW_REMOVED = 'FLOW_REMOVED'
export const FLOW_RESTORED = 'FLOW_RESTORED'
export const FLOW_PERMANENTLY_REMOVED = 'FLOW_PERMANENTLY_REMOVED'

import { Action } from 'redux'
import { ThunkDispatch } from 'redux-thunk/src/types'
import { l } from '@manychat/manyui'

import { contentManagementActions } from 'apps/cms/store'
import { MappedIceBreaker, IceBreakerChannelType } from 'apps/iceBreakers/constants/constants'
import { parseItems } from 'apps/iceBreakers/models/adapter'
import { exportItems } from 'apps/iceBreakers/models/exporter'
import {
  getIceBreakersHeader,
  getIceBreakersRoute,
  getTypedChannel,
} from 'apps/iceBreakers/models/helpers'
import {
  getById,
  getChannelItemsFetched,
  getIceBreakersFirstErrors,
  getItems,
} from 'apps/iceBreakers/selectors/iceBreakersSelectors'
import { alert } from 'common/core'
import { ChannelType } from 'common/core/constants/ChannelType'
import { IAsyncThunkAction, IThunkAction } from 'common/core/interfaces/actions'
import * as flowSelectors from 'common/flow/selectors/flowSelectors'
import { handleCatch } from 'shared/api/lib/errors/handlers'
import { applyHandlerByGuard, reportUnhandledErrors } from 'shared/api/lib/errors/utils'
import { iceBreakersApi } from 'shared/api/requests/iceBreakers'
import { iceBreakerApiGuard } from 'shared/api/requests/iceBreakers/guards'
import { IceBreakerChannel, IceBreakerStatus } from 'shared/api/requests/iceBreakers/schemas'
import { navigatePollyfill } from 'utils/router/tools'
import { analyticsService } from 'utils/services/analytics'
import { anotherTabNotificationsListener } from 'utils/services/notificationsService'
import { linkURL } from 'utils/url'

import * as actionTypes from '../constants/iceBreakersReduxActionTypes'

export const resetFetchedStatusForAllIceBreakers = (): IThunkAction => (dispatch) =>
  dispatch({
    type: actionTypes.ICE_BREAKERS_RESET_FETCHED_FOR_ALL_CHANNELS,
  })

export const fetchIceBreakers = (
  channel: IceBreakerChannel = ChannelType.FB,
): IAsyncThunkAction => {
  return async (dispatch) => {
    try {
      dispatch({
        type: actionTypes.ICE_BREAKERS_FETCH_REQUEST,
        channel,
      })

      const response = await iceBreakersApi.get({
        query: { channel },
      })

      dispatch({
        type: actionTypes.ICE_BREAKERS_FETCH_RESPONSE,
        data: parseItems(response.data),
        channel,
      })
    } catch (error) {
      dispatch({ type: actionTypes.ICE_BREAKERS_FETCH_ERROR, channel })
      handleCatch(error)
    }
  }
}

export const updateIceBreakers = (
  channel: IceBreakerChannel = ChannelType.FB,
): IAsyncThunkAction => {
  return async (dispatch, getState) => {
    const isFetched = getChannelItemsFetched(channel)(getState())
    if (isFetched) {
      return
    }

    try {
      dispatch({
        type: actionTypes.ICE_BREAKERS_FETCH_REQUEST,
        channel,
      })

      const response = await iceBreakersApi.get({
        query: { channel },
      })

      dispatch({
        type: actionTypes.ICE_BREAKERS_FETCH_RESPONSE,
        data: parseItems(response.data),
        channel,
      })
    } catch (error) {
      dispatch({ type: actionTypes.ICE_BREAKERS_FETCH_ERROR, channel })
      handleCatch(error)
    }
  }
}

export const publishIceBreakers = (
  channel: IceBreakerChannel = ChannelType.FB,
): IAsyncThunkAction => {
  return async (dispatch, getState) => {
    const state = getState()
    const errors = getIceBreakersFirstErrors(channel)(state)

    if (errors.length > 0) {
      dispatch({ type: actionTypes.ICE_BREAKERS_VALIDATION, channel, errors })
      return
    }

    const iceBreakers = exportItems(getItems(channel)(state))

    try {
      dispatch({
        type: actionTypes.ICE_BREAKERS_PUBLISH_REQUEST,
        channel,
      })

      const response = await iceBreakersApi.publish({
        body: {
          ice_breakers: iceBreakers,
          channel,
        },
      })

      dispatch({
        type: actionTypes.ICE_BREAKERS_PUBLISH_RESPONSE,
        data: parseItems(response.data),
        channel,
      })

      alert(
        l.translate('{iceBreakersHeader} Updated', {
          iceBreakersHeader: l.getString(getIceBreakersHeader(channel)),
        }),
        'success',
      )

      analyticsService.sendEvent(`ICE_BREAKERS.${channel.toUpperCase()}.PUBLISHED`)
    } catch (error) {
      handlePublishError(channel, dispatch, error)
    }
  }
}

export const clearIceBreakers = (
  channel: IceBreakerChannel = ChannelType.FB,
): IAsyncThunkAction => {
  return async (dispatch, getState) => {
    const state = getState()

    const iceBreakers = exportItems(getItems(channel)(state)).map((item) => ({
      ...item,
      status: IceBreakerStatus.DELETED,
    }))

    try {
      dispatch({
        type: actionTypes.ICE_BREAKERS_PUBLISH_REQUEST,
        channel,
      })

      const response = await iceBreakersApi.publish({
        body: { ice_breakers: iceBreakers, channel },
      })

      dispatch({
        type: actionTypes.ICE_BREAKERS_PUBLISH_RESPONSE,
        data: parseItems(response.data),
        channel,
      })

      alert(
        l.translate('{iceBreakersHeader} Updated', {
          iceBreakersHeader: l.getString(getIceBreakersHeader(channel)),
        }),
        'success',
      )
    } catch (error) {
      handlePublishError(channel, dispatch, error)
    }
  }
}

export const createIceBreaker = (channel: IceBreakerChannelType = ChannelType.FB) => {
  return { type: actionTypes.ICE_BREAKER_CREATE, channel }
}

export const updateIceBreaker = (
  channel: IceBreakerChannelType,
  id: string,
  data: Partial<MappedIceBreaker>,
) => {
  return { type: actionTypes.ICE_BREAKER_UPDATE, id, data, channel }
}

export const reorderIceBreakers = (
  channel: IceBreakerChannelType,
  elementOldPosition: number,
  elementNewPosition: number,
): IThunkAction => {
  return (dispatch, getState) => {
    const items = getItems(channel)(getState())

    const dragItem = items[elementOldPosition]
    const updatedItems = items.filter((item) => item.id !== dragItem.id)
    const withDragged = [
      ...updatedItems.slice(0, elementNewPosition),
      dragItem,
      ...updatedItems.slice(elementNewPosition),
    ]
    const withUpdatedComparators = withDragged.map((item, index) => ({ ...item, sort_id: index }))
    return dispatch({
      type: actionTypes.ICE_BREAKERS_REORDER,
      data: withUpdatedComparators,
      channel,
    })
  }
}

export const deleteIceBreaker = (channel: IceBreakerChannelType, id: string) => {
  return {
    type: actionTypes.ICE_BREAKER_UPDATE,
    id,
    data: { status: IceBreakerStatus.DELETED },
    channel,
  }
}

export const deleteIceBreakersByOriginalIds = (
  channel: IceBreakerChannelType,
  icebreakersIds: number[],
) => ({
  type: actionTypes.ICE_BREAKERS_DELETE_BY_ORIGINAL_ID,
  icebreakersIds,
  channel,
})

export const setIceBreakerFlow = (
  channel: IceBreakerChannelType,
  id: string,
  flowId?: string,
): IThunkAction => {
  return (dispatch, getState) => {
    const flow = flowId ? flowSelectors.getById(getState(), flowId) : undefined
    return dispatch({ type: actionTypes.ICE_BREAKER_UPDATE, id, data: { flow }, channel })
  }
}

export const openIceBreakerFlow = (
  flowId: string,
  channel: string = ChannelType.FB,
): IThunkAction => {
  return () => {
    const typedChannel = getTypedChannel(channel)
    const mainRoute = getIceBreakersRoute(typedChannel)
    navigatePollyfill(linkURL(`${mainRoute}/${flowId}`))
  }
}

export const createIceBreakerFlow = (
  channel: IceBreakerChannelType,
  id: string,
): IAsyncThunkAction => {
  return async (dispatch, getState) => {
    const item = getById(channel, id)(getState())
    const fileSystem = await dispatch(
      contentManagementActions.createAutomation({
        name: item?.caption,
      }),
    ).unwrap()

    if (!fileSystem) {
      return
    }

    const itemChannel = item?.channel
    const flow = { ...fileSystem?.flow, id: fileSystem?.flow?.ns }
    dispatch({ type: actionTypes.ICE_BREAKER_UPDATE, id, data: { flow }, channel: itemChannel })

    dispatch(openIceBreakerFlow(fileSystem.flow.ns ?? '', itemChannel))
  }
}

export const switchIceBreakersEnabled = (
  isToEnable: boolean,
  channel: IceBreakerChannel = ChannelType.FB,
): IAsyncThunkAction => {
  return async (dispatch) => {
    try {
      dispatch({
        type: actionTypes.ICE_BREAKERS_SWITCH_ENABLED_REQUEST,
        channel,
      })

      const response = await iceBreakersApi.setAvailable({
        body: {
          is_available: isToEnable,
          channel,
        },
      })

      dispatch({
        type: actionTypes.ICE_BREAKERS_SWITCH_ENABLED_RESPONSE,
        data: response.data,
        channel,
      })

      analyticsService.sendEvent(
        `ICE_BREAKERS.${channel.toUpperCase()}.${isToEnable ? 'ENABLED' : 'DISABLED'}`,
      )
    } catch (error) {
      dispatch({ type: actionTypes.ICE_BREAKERS_SWITCH_ENABLED_ERROR, channel })

      handleCatch(error, (responseError) => {
        let unhandledErrors = applyHandlerByGuard(
          responseError.$errors,
          iceBreakerApiGuard.setAvailable.isChannelNotConnectedError,
          (error) => {
            alert(error.message, 'danger')
          },
        )

        unhandledErrors = applyHandlerByGuard(
          unhandledErrors,
          iceBreakerApiGuard.setAvailable.isUnknownError,
          (error) => {
            alert(error.message, 'danger')
          },
        )

        reportUnhandledErrors(unhandledErrors, responseError.endpoint)
      })
    }
  }
}

interface IceBreakerEnabledNotification {
  is_available: boolean
}

export const switchIceBreakersEnabledNotification = (data: IceBreakerEnabledNotification) => {
  return {
    type: actionTypes.ICE_BREAKERS_SWITCH_ENABLED_NOTIFICATION,
    data,
  }
}

export const handlePublishError = (
  channel: IceBreakerChannelType,
  dispatch: ThunkDispatch<RootState, unknown, Action>,
  error: unknown,
) => {
  dispatch({ type: actionTypes.ICE_BREAKERS_PUBLISH_ERROR, channel })

  handleCatch(error, (responseError) => {
    let unhandledErrors = applyHandlerByGuard(
      responseError.$errors,
      iceBreakerApiGuard.publish.isUnknownError,
      (error) => {
        alert(error.message, 'danger')
      },
    )

    unhandledErrors = applyHandlerByGuard(
      unhandledErrors,
      iceBreakerApiGuard.publish.isChannelNotConnectedError,
      (error) => {
        alert(error.message, 'danger')
      },
    )

    unhandledErrors = applyHandlerByGuard(
      unhandledErrors,
      iceBreakerApiGuard.publish.isFlowNotExistsError,
      (error) => {
        alert(l.translate("Target Automation doesn't exist"), 'danger')
        dispatch(setInvalidId(channel, error.error_data.ice_breaker_id))
      },
    )

    reportUnhandledErrors(unhandledErrors, responseError.endpoint)
  })
}

export const setInvalidId = (channel: IceBreakerChannelType, id: number | null) => ({
  type: actionTypes.ICE_BREAKERS_SET_INVALID_ID,
  data: id,
  channel,
})

anotherTabNotificationsListener.on('icebreakers_instagram_set_available', (data, dispatch) => {
  dispatch(switchIceBreakersEnabledNotification(data))
})

anotherTabNotificationsListener.on('icebreakers_set_available', (data, dispatch) => {
  dispatch(switchIceBreakersEnabledNotification(data))
})

anotherTabNotificationsListener.on('icebreakers_published', (data, dispatch) => {
  dispatch({
    type: actionTypes.ICE_BREAKERS_FETCH_RESPONSE,
    data: parseItems(data),
  })
})

anotherTabNotificationsListener.on('icebreakers_instagram_published', (data, dispatch) => {
  dispatch({
    type: actionTypes.ICE_BREAKERS_FETCH_RESPONSE,
    data: parseItems(data),
  })
})

import { createSlice } from '@reduxjs/toolkit'
import { createAppAsyncThunk } from 'reduxTyped'
import { createSelector } from 'reselect'

import { handleCatch } from 'shared/api/lib/errors/handlers'
import { QuickCampaignApi } from 'shared/api/requests/quickCampaign'
import { FlowDataForSimulator } from 'shared/api/requests/quickCampaign/schemas'

export interface FlowForSimulatorState {
  isFetching: boolean
  isFetched: boolean
  flow: FlowDataForSimulator | null
}

const InitialState: FlowForSimulatorState = {
  isFetching: false,
  isFetched: false,
  flow: null,
}

export const FetchFlowByQuickCampaignId = createAppAsyncThunk(
  'flowForSimulator/getFlow',
  async (quickCampaignId: string) => {
    try {
      const { data } = await QuickCampaignApi.getFlow({
        query: { quick_campaign_id: quickCampaignId },
      })

      return data.flow
    } catch (error) {
      handleCatch(error)

      return null
    }
  },
)

const FlowForSimulatorSlice = createSlice({
  name: 'flowForSimulator',
  initialState: InitialState,
  reducers: {
    resetState: () => InitialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(FetchFlowByQuickCampaignId.pending, (state) => {
        state.isFetching = true
      })
      .addCase(FetchFlowByQuickCampaignId.fulfilled, (state, action) => {
        state.isFetching = false
        state.isFetched = true
        state.flow = action.payload
      })
      .addCase(FetchFlowByQuickCampaignId.rejected, (state) => {
        state.isFetching = false
      })
  },
})

export const FlowForSimulatorReducer = FlowForSimulatorSlice.reducer

export const FlowForSimulatorActions = {
  ...FlowForSimulatorSlice.actions,
  FetchFlowByQuickCampaignId,
}

export const getFlowForSimulatorState = (state: RootState) => state.flowForSimulator
export const getFlowForSimulator = createSelector(getFlowForSimulatorState, (state) => state.flow)
export const getIsFetching = createSelector(getFlowForSimulatorState, (state) => state.isFetching)
export const getIsFetched = createSelector(getFlowForSimulatorState, (state) => state.isFetched)

export const FlowForSimulatorSelectors = {
  getFlowForSimulator,
  getIsFetching,
  getIsFetched,
}

import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import { ITriggerPickerState } from 'apps/triggerPicker/redux/triggerPickerInterface'

const initialState: ITriggerPickerState = {
  isOpen: false,
  isGrowthTools: false,
  applicationId: undefined,
}

const triggerPickerSlice = createSlice({
  name: 'triggerPicker',
  initialState,
  reducers: {
    toggleModal(state) {
      state.isOpen = !state.isOpen
    },
    toggleGrowthTools(state) {
      state.isGrowthTools = !state.isGrowthTools
    },
    setApplicationId(state, action: PayloadAction<number | undefined>) {
      state.applicationId = action.payload
    },
  },
})

export const { toggleModal, toggleGrowthTools, setApplicationId } = triggerPickerSlice.actions
export const { reducer } = triggerPickerSlice

const getTriggerPicker = (state: RootState) => state.triggerPicker
export const getIsOpen = (state: RootState) => getTriggerPicker(state).isOpen
export const getIsGrowthTools = (state: RootState) => getTriggerPicker(state).isGrowthTools
export const getApplicationId = (state: RootState) => getTriggerPicker(state).applicationId

import assert from 'assert'

import intersection from 'lodash/intersection'
import without from 'lodash/without'
import { l } from '@manychat/manyui'

import { createBlock } from 'common/builder/actions/createBlock'
import { createButton } from 'common/builder/actions/createButton'
import { linkButton } from 'common/builder/actions/linkButton'
import { mergeParsed } from 'common/builder/actions/mergeParsed'
import { unlinkBlock } from 'common/builder/actions/unlinkBlock'
import { unlinkNodeBlock } from 'common/builder/actions/unlinkNodeBlock'
import { updateBlock } from 'common/builder/actions/updateBlock'
import { BlockType } from 'common/builder/constants/BlockType'
import * as ActionTypes from 'common/builder/constants/builderReduxActionTypes'
import BlockModel from 'common/builder/models/Block'
import { copyElement } from 'common/builder/models/BuilderState/copyElement'
import builderSelectors from 'common/builder/selectors/builder'
import * as entitySelectors from 'common/builder/selectors/builder/entitySelectors'
import { GetMessages } from 'common/oneTimeNotify/constants/notifyReasonConstants'
import errorTrackingService from 'utils/services/errorTrackingService'

/**
 * @param builderId
 * @param blockId
 * @param dragButtonId
 * @param hoverButtonId
 */
export function moveButton(builderId, blockId, dragButtonId, hoverButtonId) {
  return (dispatch, getState) => {
    const state = getState()
    const item = entitySelectors.getBlockById(state, builderId, blockId)
    const { nodeType } = entitySelectors.getParentNode(state, builderId, blockId)

    // dragButtonId was moved from another block
    if (!item.buttons.includes(dragButtonId)) {
      const buttons = entitySelectors.getButtonsMap(state, builderId)
      const dragItem = buttons[dragButtonId]

      const isNestedButtonAllowed = BlockModel.isNestedButtonAllowed(item, nodeType)
      const isNestedButtonTypeAllowed = BlockModel.isNestedButtonTypeAllowed(
        item,
        dragItem.type,
        nodeType,
      )
      const isAddButtonAllowed = BlockModel.isAddButtonAllowed(item, nodeType, {
        buttons,
        newButtonType: dragItem.type,
      })

      if (!isNestedButtonAllowed || !isNestedButtonTypeAllowed || !isAddButtonAllowed) {
        return
      }

      return dispatch(linkButton(builderId, blockId, dragButtonId, { relink: true }))
    }

    // placing dragButtonId in the position of hoverButtonId
    const order = without(item.buttons, dragButtonId)
    const hoverIndex = item.buttons.indexOf(hoverButtonId)
    order.splice(hoverIndex, 0, dragButtonId)

    dispatch(reorderButtons(builderId, blockId, order))
  }
}

/**
 * @param builderId
 * @param blockId
 * @param dragButtonId
 */
export function moveFirstButton(builderId, blockId, dragButtonId) {
  return (dispatch, getState) => {
    const state = getState()

    const item = entitySelectors.getBlockById(state, builderId, blockId)
    const buttons = entitySelectors.getButtonsMap(state, builderId)
    const dragItem = buttons[dragButtonId]
    const { nodeType } = entitySelectors.getParentNode(state, builderId, blockId)

    const isNestedButtonAllowed = BlockModel.isNestedButtonAllowed(item, nodeType)
    const isNestedButtonTypeAllowed = BlockModel.isNestedButtonTypeAllowed(
      item,
      dragItem.type,
      nodeType,
    )
    const isAddButtonAllowed = BlockModel.isAddButtonAllowed(item, nodeType, {
      buttons,
      newButtonType: dragItem.type,
    })

    if (!isNestedButtonAllowed || !isNestedButtonTypeAllowed || !isAddButtonAllowed) {
      return
    }

    dispatch(linkButton(builderId, blockId, dragButtonId, { relink: true }))
  }
}

/**
 * @param builderId
 * @param blockId
 * @param dragBlockId
 * @param hoverBlockId
 */
export function moveBlock(builderId, blockId, dragBlockId, hoverBlockId) {
  return (dispatch, getState) => {
    const state = getState()

    const item = entitySelectors.getBlockById(state, builderId, blockId)
    const blocks = entitySelectors.getBlocks(state, builderId, blockId)
    const currentOrder = blocks.map((block) => block.id)

    // if hoverBlockId is a descendant of dragBlockId,
    // do not move blocks into themselves
    const isDescendant = builderSelectors.block.hasDescendant(
      state,
      builderId,
      dragBlockId,
      hoverBlockId,
    )
    if (isDescendant) {
      return
    }

    // dragBlockId was moved from content
    if (!currentOrder.includes(dragBlockId)) {
      const dragItem = entitySelectors.getBlockById(state, builderId, dragBlockId)

      const isNestedTypeAllowed = BlockModel.isNestedBlockTypeAllowed(item, dragItem.type)
      const isAddBlockAllowed = BlockModel.isAddBlockAllowed(item)

      if (!isNestedTypeAllowed || !isAddBlockAllowed) {
        return
      }

      dispatch(linkBlock(builderId, blockId, dragBlockId, { relink: true }))
    }

    // placing dragBlockId in the position of hoverBlockId
    const order = without(currentOrder, dragBlockId)
    const hoverIndex = currentOrder.indexOf(hoverBlockId)
    order.splice(hoverIndex, 0, dragBlockId)

    dispatch(reorderBlocks(builderId, blockId, order))
  }
}

/**
 * @param builderId
 * @param blockId
 */
export function cloneBlock(builderId, blockId) {
  return (dispatch, getState) => {
    const state = getState()

    const [block, related] = entitySelectors.getAllRelated(state, builderId, blockId)
    const [clonedBlock, parsed] = copyElement(block, related)

    dispatch(mergeParsed(builderId, parsed))
    return clonedBlock
  }
}

export function createLinkedBlock(builderId, blockId, initial, options = {}) {
  return (dispatch) => {
    const blockItem = dispatch(createBlock(builderId, initial))
    dispatch(linkBlock(builderId, blockId, blockItem.id, { index: options?.index }))
    return blockItem
  }
}

export function createOneTimeNotifyRequestBlock(builderId) {
  return (dispatch) => {
    const button = dispatch(
      createButton(builderId, { caption: l.getString(GetMessages), isNotificationRequest: true }),
    )
    return dispatch(
      createBlock(builderId, {
        type: BlockType.ONE_TIME_NOTIFY_REQUEST,
        buttons: [button.id],
      }),
    )
  }
}

///////////////////
// BASIC ACTIONS //
///////////////////

/**
 * @param builderId
 * @param blockId
 */
export function removeBlock(builderId, blockId) {
  return (dispatch, getState) => {
    const state = getState()

    assert(builderId, `removeBlock: builderId is required param`)
    assert(blockId, `removeBlock: blockId is required param`)

    const item = entitySelectors.getBlockById(state, builderId, blockId)
    if (!item) {
      errorTrackingService.trackWarningOnce(
        `removeBlock: block with id "${blockId}" doesn't exist`,
        {
          extra: { builderId, blockId },
          fingerprint: 'removeBlock-block-does-not-exist',
          tags: { area: 'builder', topic: "id doesn't exist" },
        },
      )
      return console.warn(`removeBlock: block with id "${blockId}" doesn't exist`)
    }

    const parentItem = entitySelectors.getParentBlock(state, builderId, blockId)
    if (parentItem) {
      if (BlockModel.BlockTypesMinNested[parentItem.type]) {
        const msg = `removeBlock: block with type "${parentItem.type}" should have at least ${
          BlockModel.BlockTypesMinNested[parentItem.type]
        } blocks`
        assert(parentItem.blocks.length - 1 >= BlockModel.BlockTypesMinNested[parentItem.type], msg)
      }
    }

    return dispatch(removeBlockForce(builderId, blockId))
  }
}

export function removeBlockForce(builderId, blockId) {
  return {
    type: ActionTypes.REMOVE_BLOCK,
    builderId,
    blockId,
  }
}

/**
 * @param builderId
 * @param blockId
 * @param targetId
 * @param options - {relink}
 */
export function linkBlock(builderId, blockId, targetId, options = {}) {
  return (dispatch, getState) => {
    const state = getState()

    assert(builderId, `linkBlock: builderId is required param`)
    assert(blockId, `linkBlock: blockId is required param`)
    assert(targetId, `linkBlock: targetId is required param`)

    const item = entitySelectors.getBlockById(state, builderId, blockId)
    assert(item, `linkBlock: block doesn't exist`)

    const targetItem = entitySelectors.getBlockById(state, builderId, targetId)
    assert(targetItem, `linkBlock: targetItem doesn't exist`)

    const targetParentItem =
      entitySelectors.getParentBlock(state, builderId, targetId) ||
      entitySelectors.getParentNode(state, builderId, targetId)
    if (options.relink) {
      if (targetParentItem) {
        const contentParent = entitySelectors.getNodeById(state, builderId, targetParentItem.id)
        if (contentParent) {
          dispatch(unlinkNodeBlock(builderId, contentParent.id, targetId))
        }
        const blockParent = entitySelectors.getBlockById(state, builderId, targetParentItem.id)
        if (blockParent) {
          dispatch(unlinkBlock(builderId, blockParent.id, targetId))
        }
      }
    } else {
      assert(!targetParentItem, `linkBlock: block "${targetId}" already linked`)
    }

    assert(
      BlockModel.isNestedBlockAllowed(item),
      `linkBlock: block type "${item.type}" doesn't allow to link block`,
    )
    assert(
      BlockModel.isNestedBlockTypeAllowed(item, targetItem.type),
      `linkBlock: block type "${item.type}" doesn't allow to link block with type ${targetItem.type}`,
    )
    assert(
      BlockModel.isAddBlockAllowed(item),
      `linkBlock: block with type "${item.type}" may contain not more than "x" blocks`,
    )

    if (BlockModel.BlockTypesMinNested[item.type]) {
      const msg = `linkBlock: block with type "${item.type}" should have at least ${
        BlockModel.BlockTypesMinNested[item.type]
      } blocks`
      assert(item.blocks.length >= BlockModel.BlockTypesMinNested[item.type], msg)
    }

    return dispatch({
      type: ActionTypes.LINK_BLOCK_AND_BLOCK,
      builderId,
      blockId,
      targetId,
      index: options?.index,
    })
  }
}

/**
 * @param builderId
 * @param blockId
 */
export function unlinkAllBlocks(builderId, blockId) {
  return (dispatch, getState) => {
    const state = getState()
    assert(builderId, `unlinkAllBlocks: builderId is required param`)
    assert(blockId, `unlinkAllBlocks: blockId is required param`)

    const item = entitySelectors.getBlockById(state, builderId, blockId)
    assert(item, `unlinkAllBlocks: block doesn't exist`)

    item.blocks.forEach((targetId) => {
      dispatch(unlinkBlock(builderId, blockId, targetId))
    })
  }
}

/**
 * @param builderId
 * @param blockId
 * @param order
 */
export function reorderBlocks(builderId, blockId, order) {
  return (dispatch, getState) => {
    const state = getState()

    assert(builderId, `reorderBlocks: builderId is required param`)
    assert(blockId, `reorderBlocks: blockId is required param`)
    assert(order, `reorderBlocks: order is required param`)

    const item = entitySelectors.getBlockById(state, builderId, blockId)
    assert(item, `reorderBlocks: block doesn't exist`)

    const blocksOrder = intersection(order, item.blocks)
    assert(blocksOrder.length === item.blocks.length, `reorderBlocks: missing ids in order param`)

    const changes = {
      blocks: order,
    }
    return dispatch(updateBlock(builderId, blockId, changes))
  }
}

/**
 * @param builderId
 * @param blockId
 * @param order
 */
export function reorderButtons(builderId, blockId, order) {
  return (dispatch, getState) => {
    const state = getState()

    assert(builderId, `reorderButtons: builderId is required param`)
    assert(blockId, `reorderButtons: blockId is required param`)
    assert(order, `reorderButtons: order is required param`)

    const item = entitySelectors.getBlockById(state, builderId, blockId)
    assert(item, `reorderButtons: block doesn't exist`)

    const buttonsOrder = intersection(order, item.buttons)
    assert(
      buttonsOrder.length === item.buttons.length,
      `reorderButtons: missing ids in order param`,
    )

    const changes = {
      buttons: order,
    }
    return dispatch(updateBlock(builderId, blockId, changes))
  }
}

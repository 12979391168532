import { createAction } from '@reduxjs/toolkit'
import { createSelector } from 'reselect'

import { namespace } from 'apps/cms/store/lib/constants'
import { ContentManagementSystemState } from 'apps/cms/store/lib/types'
import { FSSmartFolders, FSQueryRootPath } from 'common/cms/models/FSObject/constants'
import { FsFilter, FlowTriggerStatus } from 'shared/api/requests/cms/schemas'
import { createDeepEqualSelector } from 'shared/lib/redux'

const updateQuery = createAction<Partial<ContentManagementSystemState['query']>>(
  `${namespace}/updateQuery`,
)

export const cmsQueryActions = {
  updateQuery,
}

const getState = (state: RootState) => state.contentManagementSystem.query

export const cmsQuerySelectors = {
  getQuery: createDeepEqualSelector([(state: RootState) => getState(state)], (query) => query),

  getQueryPath: createSelector([(state: RootState) => getState(state)], (query) => query.path),

  getIsTrashFolder: createSelector(
    [(state: RootState) => getState(state)],
    (query) => query.path === FSSmartFolders.TRASH,
  ),

  getIsRootFolder: createSelector(
    [(state: RootState) => getState(state)],
    (query) => query.path === FSQueryRootPath,
  ),

  getQuerySearch: createSelector(
    [(state: RootState) => getState(state).search],
    (search) => search,
  ),

  getHasQuerySearch: (state: RootState) => Boolean(getState(state).search.length),

  getIsSearchMode: createSelector(
    (state: RootState) => getState(state),
    (query) => {
      const { search, triggerFilters, triggerStatus } = query

      return search || triggerFilters.length > 0 || triggerStatus
    },
  ),

  getTriggerFiltersQuery: createSelector(
    (state: RootState): Array<FsFilter['filters']> => getState(state).triggerFilters,
    (triggerFilters) => triggerFilters,
  ),

  getStatusFiltersQuery: createSelector(
    (state: RootState): FlowTriggerStatus | null => getState(state).triggerStatus,
    (triggerStatus) => triggerStatus,
  ),

  getAnyFilterApplied: createSelector(
    (state: RootState) => getState(state),
    (query) => query.triggerFilters.length > 0 || query.triggerStatus,
  ),

  getHasSearchValue: createSelector(
    [(state: RootState) => Boolean(getState(state).search)],
    (hasSearchValue) => hasSearchValue,
  ),

  getIsSearchOrFilterApplied: createSelector(
    [
      (state: RootState) => getState(state).search,
      (state: RootState) => getState(state).triggerFilters,
      (state: RootState) => getState(state).triggerStatus,
    ],
    (search, triggerFilters, triggerStatus) =>
      Boolean(search) || triggerFilters.length > 0 || triggerStatus,
  ),
}

export const BlockingReasonsTypes = {
  NO_CONTENT_TYPE: 'no_content_type',
  OTHER_CONTENT_TYPE: 'other_content_type',
  NO_OTN: 'no_otn',
}

export const BlockingReasonsLabels = {
  [BlockingReasonsTypes.NO_CONTENT_TYPE]: 'No content type',
  [BlockingReasonsTypes.OTHER_CONTENT_TYPE]: '“Other” content type',
  [BlockingReasonsTypes.NO_OTN]: 'No OTN Token',
}

export const BlockingReasonsTooltip = {
  [BlockingReasonsTypes.NO_CONTENT_TYPE]:
    'Flow has a message that your bot attempted to send to a contact 24h after their last Messenger interaction without specific reason (content type field was empty).',
  [BlockingReasonsTypes.NO_OTN]:
    'Flow has a so-called One-Time Notification that your bot attempted to send while having no permissions to do so (zero OTN tokens).',
  [BlockingReasonsTypes.OTHER_CONTENT_TYPE]: `Flow has a message with “Other” content type that your bot attempted to send to a contact 24h after their last Messenger interaction. Meanwhile, messages of this type only get delivered when a contact interacted with the bot less than 24h ago.`,
}

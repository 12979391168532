import { createButton } from 'common/builder/actions/createButton'
import { linkButton } from 'common/builder/actions/linkButton'

/**
 * @param builderId
 * @param blockId
 * @param initial
 */
export const createLinkedButton = (builderId, blockId, initial) => {
  return (dispatch) => {
    const buttonItem = dispatch(createButton(builderId, initial))
    dispatch(linkButton(builderId, blockId, buttonItem.id))
    return buttonItem
  }
}

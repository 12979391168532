import React, { useState, useEffect, useRef, useCallback, useMemo } from 'react'
import cx from 'classnames'

import { Hotkeys, HotkeyHandlerDeclaration } from '../../../utils/hotkeys'
import { translate, getString } from '../../../utils/localization/format'
import Popover from '../../layers/Popover'
import Scrollbars from '../../Scrollbars'

import {
  InitialMainOptionType,
  InitialSubOptionType,
  MainOptionType,
  SelectWrapperProps,
  SubOptionType,
} from './interfaces'

import cm from './SelectWrapper.module.css'

const defaultEmptyLabel = translate('No Items Found')

const SelectWrapper = <
  MainOption extends MainOptionType<InitialMainOptionType>,
  SubOption extends SubOptionType<InitialSubOptionType>,
>({
  open,
  anchor,
  children,
  emptyLabel = defaultEmptyLabel,
  noPortal,
  options = [],
  optionProps = {},
  onSelect,
  onRequestClose,
  renderPopover,
  renderOption: renderOptionProp,
  renderCustomLastComponent,
  renderGroupLabel,
  noAutoScroll,
  hideEmptyGroups,
  className,
}: SelectWrapperProps<MainOption, SubOption>) => {
  const [index, setIndex] = useState(-1)
  const [hasUngrouppedOptions, setHasUngrouppedOptions] = useState(false)
  const optionsWrapperRef = useRef<HTMLDivElement | null>(null)

  const renderOption = useMemo(() => {
    if (renderOptionProp) return renderOptionProp
    // eslint-disable-next-line react/no-multi-comp
    return (option: MainOption | SubOption) => (
      <div className="p-x p-y-xs">{getString(option.label)}</div>
    )
  }, [renderOptionProp])

  useEffect(() => {
    if (open || noPortal) {
      setIndex(-1)
    }
    setHasUngrouppedOptions(Boolean(options.find((option) => !option.isGroup)))
  }, [open, noPortal, options])

  const scrollOptionIntoView = useCallback(
    (ind: number) => {
      if (ind < 0 || !optionsWrapperRef.current || noAutoScroll) {
        return
      }

      const option = optionsWrapperRef.current.querySelector(`[data-index="${ind}"]`)
      if (!option) return

      const optionTop = (option as HTMLElement).offsetTop
      const optionBottom = optionTop + (option as HTMLElement).clientHeight
      const viewTop = optionsWrapperRef.current.scrollTop
      const viewBottom = viewTop + optionsWrapperRef.current.clientHeight

      if (optionBottom > viewBottom) {
        optionsWrapperRef.current.scrollTop = optionTop
      } else if (optionTop < viewTop) {
        optionsWrapperRef.current.scrollTop = optionBottom - optionsWrapperRef.current.clientHeight
      }
    },
    [noAutoScroll],
  )

  const handleKeydown = useCallback(
    (event: KeyboardEvent | undefined, key: string, idx = index, recursionCount = 0) => {
      if (!event) return
      event.preventDefault()

      if (!options.length) return

      const option = options[idx]

      if (key === 'esc') {
        onRequestClose?.(key, event as KeyboardEvent & { stopPropagationToLayers: () => void })
        return
      }

      if (key === 'enter' && option && !option.isGroup) {
        onSelect?.(option)
        return
      }

      if (recursionCount > options.length) return

      if (idx < 0 || idx >= options.length) {
        handleKeydown(event, key, key === 'up' ? options.length - 1 : 0, recursionCount + 1)
        return
      }

      if (idx === index || options[idx].isGroup) {
        handleKeydown(event, key, idx + (key === 'up' ? -1 : 1), recursionCount + 1)
        return
      }

      setIndex(idx)
      scrollOptionIntoView(idx)
    },
    [index, onRequestClose, onSelect, options, scrollOptionIntoView],
  )

  const handleOptionClick = useCallback(
    (event: React.MouseEvent<HTMLDivElement>) => {
      onSelect?.(options[Number(event.currentTarget.dataset.index)])
    },
    [onSelect, options],
  )

  const handleOptionMouseEnter = useCallback(
    (event: React.MouseEvent<HTMLDivElement>) => {
      setIndex(Number(event.currentTarget.dataset.index))
      scrollOptionIntoView(Number(event.currentTarget.dataset.index))
    },
    [scrollOptionIntoView],
  )

  const handleOptionsWrapperRef = (el: Scrollbars) => {
    if (el) {
      optionsWrapperRef.current = el.view || el
    }
  }

  const handleOptionMouseDown = useCallback((event: React.MouseEvent) => event.preventDefault(), [])

  const handleOptionsMouseLeave = useCallback(() => setIndex(-1), [])

  const renderOptionElement = useCallback(
    (option: MainOption | SubOption, idx: number) => {
      if (option.isGroup) {
        const isEmptyGroup = idx + 1 >= options.length || options[idx + 1].isGroup
        if (hideEmptyGroups && isEmptyGroup) return null

        return (
          <div
            key={idx}
            data-index={idx}
            onMouseDown={handleOptionMouseDown}
            onMouseEnter={handleOptionMouseEnter}
            data-test-id="select-wrapper-group-option"
            data-group
            className={cx(
              {
                [cm.group]: !option.noDivider,
                'p-t-xs': !option.noDivider,
                'p-t-xxs': option.noDivider,
              },
              'p-b-xxs p-x text-secondary',
            )}
          >
            {renderGroupLabel && option.isGroup ? (
              renderGroupLabel(option as MainOption)
            ) : (
              <span className="text-sm">{getString(option.label)}</span>
            )}
            {isEmptyGroup && (
              <div data-index={idx} className="p-y-xs">
                <div>
                  {getString(
                    (typeof option.emptyLabel === 'function' && option.emptyLabel()) || emptyLabel,
                  )}
                </div>
              </div>
            )}
          </div>
        )
      }

      const renderedCustomOption = renderOption(option)
      if (!renderedCustomOption) return null

      return (
        <div
          key={idx}
          onClick={handleOptionClick}
          onMouseDown={handleOptionMouseDown}
          onMouseEnter={handleOptionMouseEnter}
          className={cx('pointer', index === idx && cm.hover)}
          data-index={idx}
          data-test-id="select-wrapper-option"
        >
          {React.cloneElement(renderedCustomOption as React.ReactElement, optionProps)}
        </div>
      )
    },
    [
      emptyLabel,
      handleOptionClick,
      handleOptionMouseDown,
      handleOptionMouseEnter,
      hideEmptyGroups,
      index,
      optionProps,
      options,
      renderGroupLabel,
      renderOption,
    ],
  )

  const content = useMemo(
    () => (
      <Scrollbars
        autoHeight={!noPortal}
        autoHeightMax={275}
        autoHide={false}
        ref={handleOptionsWrapperRef}
        className={noPortal ? 'flex-grow' : undefined}
      >
        {hasUngrouppedOptions ? (
          <div className={cx('p-y-xxs', className)} onMouseLeave={handleOptionsMouseLeave}>
            {options.map((option, idx) => renderOptionElement(option, idx))}
          </div>
        ) : (
          !renderCustomLastComponent && (
            <div className={cx('p-y-xs p-x text-secondary', className)}>{emptyLabel}</div>
          )
        )}
        {renderCustomLastComponent && renderCustomLastComponent()}
      </Scrollbars>
    ),
    [
      className,
      emptyLabel,
      handleOptionsMouseLeave,
      hasUngrouppedOptions,
      noPortal,
      options,
      renderCustomLastComponent,
      renderOptionElement,
    ],
  )

  const popover = useMemo(() => {
    const popoverProps = {
      fitTargetWidth: true,
      theme: { base: cm.popoverBase },
      anchor,
      at: 'bottom',
      autoPosition: 'shift flip',
      open,
      onRequestClose,
      useLayerForClickAway: false,
      noContentPadding: true,
    }

    return renderPopover ? (
      renderPopover(content, popoverProps)
    ) : (
      <Popover {...popoverProps}>{content}</Popover>
    )
  }, [anchor, content, onRequestClose, open, renderPopover])

  const hotkeyHandlers: HotkeyHandlerDeclaration[] = [
    {
      matcher: 'ArrowUp',
      handler: (event) => handleKeydown(event, 'up'),
    },
    {
      matcher: 'ArrowDown',
      handler: (event) => handleKeydown(event, 'down'),
    },
    {
      matcher: 'Escape',
      handler: (event) => handleKeydown(event, 'esc'),
    },
    {
      matcher: 'Enter',
      handler: (event) => handleKeydown(event, 'enter'),
    },
  ]

  return (
    <Hotkeys
      handlers={hotkeyHandlers}
      elementProps={{
        className: noPortal ? cm.noPortal : undefined,
      }}
    >
      {children}
      {noPortal ? content : popover}
    </Hotkeys>
  )
}

export default SelectWrapper

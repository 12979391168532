import dotProp from 'dot-prop-immutable'
import { Action } from 'redux'

import * as actionTypes from 'apps/applications/constants/InstalledApplicationsReduxActionTypes'
import installedApplicationsActionTypes from 'common/actions/constants/ReduxActionTypes'
import {
  IInstalledApplication,
  IApplicationAction,
} from 'common/actions/integrations/Applications/interfaces'

interface IActionWithApplicationId extends Action {
  applicationId: number
}

interface IActionWithAppList extends Action {
  data: {
    apps: IInstalledApplication[]
  }
}

interface IActionWithApplicationActions extends Action {
  applicationId: number
  data: {
    actions: IApplicationAction[]
  }
}

const reducer = (
  state: IInstalledApplication[] = [],
  action: IActionWithApplicationId | IActionWithAppList | IActionWithApplicationActions,
): IInstalledApplication[] => {
  switch (action.type) {
    case actionTypes.UNINSTALL_APPLICATION_RESPONSE:
      if ('applicationId' in action) {
        const index = state.findIndex((el) => el.app_id === action.applicationId)
        if (index >= 0) {
          state = dotProp.delete(state, index)
        }
      }
      break
    case actionTypes.APPLICATIONS_LIST_FETCH_RESPONSE:
      if ('data' in action && !('applicationId' in action)) {
        state = action.data.apps
      }
      break
    case installedApplicationsActionTypes.APPLICATION_ACTIONS_FETCH_RESPONSE:
      if ('data' in action && 'applicationId' in action) {
        const index = state.findIndex((el) => el.app_id === action.applicationId)
        if (index >= 0) {
          state = dotProp.set(state, `${index}.actions`, action.data.actions)
        }
      }
      break
  }

  return state
}

export default reducer

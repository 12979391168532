import React from 'react'
import { Icon } from '@manychat/manyui'

import {
  BreadcrumbsButton,
  BreadcrumbsButtonProps,
} from 'components/Breadcrumbs/components/BreadcrumbsButton/BreadcrumbsButton'
import {
  BreadcrumbsMenu,
  BreadcrumbsMenuProps,
} from 'components/Breadcrumbs/components/BreadcrumbsMenu/BreadcrumbsMenu'
import { useSegments } from 'components/Breadcrumbs/hooks/useSegments'
import { BaseBreadcrumbsSegment } from 'components/Breadcrumbs/lib/types'

import cm from './Breadcrumbs.module.css'

interface BreadcrumbsProps<CustomSegment extends BaseBreadcrumbsSegment> {
  segments: CustomSegment[]
  useMenu?: boolean
  tailItemsCount?: number
  renderBreadcrumbsButton?: (props: BreadcrumbsButtonProps<CustomSegment>) => JSX.Element
  renderBreadcrumbsMenu?: (props: BreadcrumbsMenuProps<CustomSegment>) => JSX.Element
}

export const Breadcrumbs = <CustomSegment extends BaseBreadcrumbsSegment>({
  segments,
  useMenu = true,
  tailItemsCount = 2,
  renderBreadcrumbsButton,
  renderBreadcrumbsMenu,
}: BreadcrumbsProps<CustomSegment>) => {
  const { mainSegment, hiddenSegments, visibleSegments } = useSegments({
    useMenu,
    segments,
    tailItemsCount,
  })

  const getButton = (props: BreadcrumbsButtonProps<CustomSegment>) =>
    renderBreadcrumbsButton ? (
      renderBreadcrumbsButton(props)
    ) : (
      <BreadcrumbsButton segment={props.segment} size={props.size} disabled={props.disabled} />
    )

  const getMenu = ({ segments }: BreadcrumbsMenuProps<CustomSegment>) =>
    renderBreadcrumbsMenu ? (
      renderBreadcrumbsMenu({ segments })
    ) : (
      <BreadcrumbsMenu segments={hiddenSegments} />
    )

  return (
    <div className={cm.wrapper}>
      <ul className={cm.breadcrumbs}>
        <li className={cm.breadcrumbs__item}>
          {getButton({
            segment: mainSegment,
            size: visibleSegments.length > 0 ? 'sm' : 'lg',
            disabled: visibleSegments.length === 0,
          })}
        </li>

        {visibleSegments.length > 0 && (
          <li className={cm.breadcrumbs__item}>
            <Icon.ChevronRight className={cm.breadcrumbs__icon} />
          </li>
        )}

        {hiddenSegments.length > 0 && (
          <>
            <li className={cm.breadcrumbs__item}>{getMenu({ segments: hiddenSegments })}</li>

            <li className={cm.breadcrumbs__item}>
              <Icon.ChevronRight className={cm.breadcrumbs__icon} />
            </li>
          </>
        )}

        {visibleSegments.map((segment, index, { length }) => {
          const isLast = index === length - 1

          return (
            <React.Fragment key={segment.path}>
              <li className={cm.breadcrumbs__item}>
                {getButton({
                  segment: segment,
                  size: isLast ? 'md' : 'sm',
                  disabled: isLast,
                  className: cm.breadcrumbs__button,
                })}
              </li>

              {!isLast && (
                <li className={cm.breadcrumbs__item}>
                  <Icon.ChevronRight className={cm.breadcrumbs__icon} />
                </li>
              )}
            </React.Fragment>
          )
        })}
      </ul>
    </div>
  )
}

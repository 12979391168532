import React, { forwardRef } from 'react'
import cx from 'classnames'

import cleanProps from '../../../lib/clean-react-props'
import { deprecatedDecorator } from '../../../utils/deprecated-decorator'
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import TextInputV2 from '../TextInputV2'

import cm from './TextInput.module.css'

// eslint-disable-next-line @typescript-eslint/no-unused-vars

type TextInputProps = Omit<React.HTMLProps<HTMLInputElement>, 'ref' | 'onChange'> & {
  invalid?: boolean
  /** Make input look seamless (invisible) */
  invisible?: boolean
  /**
   * Pass boolean to set autocomplete attribute to on/off.
   * Pass string to specify autocompletion type.
   * https://developer.mozilla.org/en-US/docs/Web/HTML/Attributes/autocomplete
   */
  autocomplete?: string | boolean
  onChange?: React.ChangeEventHandler<HTMLInputElement>
}

/** @deprecated use {@link TextInputV2} component */
const TextInput = forwardRef<HTMLInputElement, TextInputProps>((props, ref) => {
  const {
    defaultValue,
    autocomplete,
    invalid,
    invisible,
    className,
    onChange = () => {},
    ...otherProps
  } = props

  let autoCompleteValue = 'off'

  if (typeof autocomplete === 'boolean') {
    autoCompleteValue = autocomplete ? 'on' : 'off'
  } else if (typeof autocomplete === 'string') {
    autoCompleteValue = autocomplete
  }

  return (
    <input
      {...cleanProps(otherProps, [], [])}
      type="text"
      ref={ref}
      defaultValue={defaultValue}
      autoComplete={autoCompleteValue}
      className={cx(cm.input, invisible && cm.invisible, invalid && cm.invalid, className)}
      onChange={onChange}
    />
  )
})

TextInput.displayName = 'TextInput'

/** @deprecated use {@link TextInputV2} component */
export default deprecatedDecorator(TextInput, { replacementComponentName: 'TextInputV2' })

import React from 'react'

import { safeDynamicImport } from 'utils/safeDynamicImport'

import * as FailedImportStub from '../components/FailedImportStub'

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type AnyReactComponentAsDefaultExport = Record<'default', React.ComponentType<any>>
type DynamicImporterType = () => Promise<AnyReactComponentAsDefaultExport>

/**
 * Safe wrapper for React.lazy with a fallback to stub component
 */
export const safeReactLazy = (importer: DynamicImporterType) => {
  return React.lazy(() =>
    safeDynamicImport<AnyReactComponentAsDefaultExport>(importer, { fallback: FailedImportStub }),
  )
}

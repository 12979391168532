import { palettes } from '@manychat/manyui'

import BaseBlockConfig from 'common/builder/blocks/BaseBlockConfig'
import { EmailStyleSpaceSizePreset } from 'common/builder/blocks/blockConstants'
import { IBlockBase, IEmailDividerBlock } from 'common/builder/blocks/blockInterfaces'
import { BlockType } from 'common/builder/constants/BlockType'
import { EmailStyleType } from 'common/builder/nodes/emailNew/emailNodeConstants'

class EmailDividerBlockConfig extends BaseBlockConfig {
  blockType = BlockType.EMAIL_DIVIDER

  getDefaults = (): Omit<IEmailDividerBlock, keyof IBlockBase> => ({
    settings: {
      with_line: { type: EmailStyleType.CUSTOM, value: true },
      space_size: { type: EmailStyleType.PRESET, value: EmailStyleSpaceSizePreset.MEDIUM },
      line_color: { type: EmailStyleType.CUSTOM, value: palettes.typography.border },
      padding_top: { type: EmailStyleType.CUSTOM, value: 12 },
      padding_bottom: { type: EmailStyleType.CUSTOM, value: 12 },
      block_background_color: { type: EmailStyleType.CUSTOM, value: '' },
    },
  })
}

export default new EmailDividerBlockConfig()

import assert from 'assert'

import dot from 'dot-prop-immutable'
import clone from 'lodash/clone'
import get from 'lodash/get'
import isFinite from 'lodash/isFinite'
import uniq from 'lodash/uniq'
import without from 'lodash/without'

import * as atypes from 'common/builder/constants/builderReduxActionTypes'
import { NodeType } from 'common/builder/constants/NodeType'

const initialState = { byId: {} }

export default function nodesReducer(state = initialState, action) {
  switch (action.type) {
    case atypes.CREATE_NODE: {
      const { item } = action
      return dot.merge(state, `byId`, { [item.id]: item })
    }

    case atypes.UPDATE_NODE: {
      const { nodeId, changes } = action

      assert(nodeId, 'nodesReducers: nodeId is a required argument')
      const node = state.byId[action.nodeId]
      if (!node) {
        return state
      }

      return dot.merge(state, `byId.${nodeId}`, changes)
    }

    case atypes.REMOVE_NODE: {
      const { nodeId, foreign } = action

      assert(nodeId, 'nodesReducers: nodeId is a required argument')
      const node = state.byId[action.nodeId]
      if (!node) {
        return state
      }

      if (foreign) {
        return dot.delete(state, `byId.${nodeId}`)
      } else {
        return dot.merge(state, `byId.${nodeId}`, { deleted: true })
      }
    }

    case atypes.INSTALL_BUILDER_SNAPSHOT: {
      const { nodes } = action.targetSnapshot
      let newNodesMap = { ...state.byId, ...nodes.byId }

      Object.values(newNodesMap).forEach((node) => {
        if (!nodes.byId[node.id]) {
          newNodesMap = dot.merge(newNodesMap, `${node.id}`, { deleted: true, blocks: [] })
        }
      })

      return { byId: newNodesMap }
    }

    case atypes.RESTORE_NODE: {
      const { nodeId } = action

      assert(nodeId, 'nodesReducers: nodeId is a required argument')
      const node = state.byId[action.nodeId]
      if (!node) {
        return state
      }

      return dot.merge(state, `byId.${nodeId}`, { deleted: false })
    }

    case atypes.CONTENT_PARSED: {
      const { nodes = [], rootId } = action.parsed
      nodes.forEach((item) => {
        state = dot.merge(state, `byId`, { [item.id]: item })
      })

      const startingStepNode = nodes.find((n) => n.id === NodeType.STARTING_STEP)
      if (startingStepNode) {
        state = dot.set(state, `byId.${startingStepNode.id}.targetId`, rootId)
      }
      return state
    }

    case atypes.REMOVE_BLOCK: {
      const { blockId } = action
      for (let nodeId of Object.keys(state.byId)) {
        const item = state.byId[nodeId]
        if (item.blocks && item.blocks.includes(blockId)) {
          return dot.set(state, `byId.${nodeId}.blocks`, (value) => without(value, blockId))
        }
      }
      return state
    }

    case atypes.LINK_NODE_AND_BLOCK: {
      const { nodeId, targetId, index } = action

      assert(nodeId, 'nodesReducers: nodeId is a required argument')
      const node = state.byId[action.nodeId]
      if (!node) {
        return state
      }

      if (isFinite(index)) {
        const blocks = clone(get(state, `byId.${nodeId}.blocks`))
        blocks.splice(index, 0, targetId)
        return dot.set(state, `byId.${nodeId}.blocks`, uniq(blocks))
      }

      return dot.set(state, `byId.${nodeId}.blocks`, (items) => uniq([...items, targetId]))
    }

    case atypes.UNLINK_NODE_AND_BLOCK: {
      const { nodeId, targetId } = action

      assert(nodeId, 'nodesReducers: nodeId is a required argument')
      const node = state.byId[action.nodeId]
      if (!node) {
        return state
      }

      return dot.set(state, `byId.${nodeId}.blocks`, (value) => without(value, targetId))
    }

    case atypes.BUILDER_SET_ROOT: {
      const startingStepNode = state.byId[NodeType.STARTING_STEP]
      if (startingStepNode) {
        state = dot.set(state, `byId.${startingStepNode.id}.targetId`, action.nodeId)
      }
      return state
    }

    case atypes.BUILDER_UPDATE_ROOT: {
      const startingStepNode = state.byId[NodeType.STARTING_STEP]
      if (startingStepNode) {
        state = dot.set(state, `byId.${startingStepNode.id}.targetId`, action.contentId)
      }
      return state
    }

    case atypes.CLEAR_BUILDER_AND_RESET: {
      let newNodesMap = { ...state.byId }

      Object.values(newNodesMap).forEach((node) => {
        if (action.keepStartingStep && node.nodeType === NodeType.STARTING_STEP) {
          return
        }

        newNodesMap = dot.merge(newNodesMap, `${node.id}`, { deleted: true, blocks: [] })
      })

      return { byId: newNodesMap }
    }
  }

  return state
}

import { MouseEvent } from 'react'

const isMainDOMNodes = (node: (Window & typeof globalThis) | Document | Element): boolean => {
  return node === window || node === window.document || node === window.document.body
}

const isScrollable = (element: Element): boolean => {
  const style = element ? window.getComputedStyle(element) : null

  let overflowX = ''
  let overflowY = ''

  if (style) {
    overflowX = style.getPropertyValue('overflow-x')
    overflowY = style.getPropertyValue('overflow-y')
  }

  return (
    ((overflowY === 'scroll' || overflowY === 'auto') &&
      element.scrollHeight > element.clientHeight) ||
    ((overflowX === 'scroll' || overflowX === 'auto') && element.scrollWidth > element.clientWidth)
  )
}

export const isDescendant = (parent: Element | null, child: EventTarget): boolean => {
  const childNode = child as Element

  if (parent && typeof parent.contains === 'function') {
    return parent.contains(childNode)
  }

  let node = childNode?.parentNode ?? null

  while (node !== null) {
    if (parent && node === parent) {
      return true
    }
    node = node.parentNode
  }

  return false
}

export const isClickInElement = (
  event: (Pick<MouseEvent, 'clientX' | 'clientY'> & { target: Element | null }) | null,
  element: Element | null,
): boolean => {
  if (!event || !element) {
    return false
  }

  const targetNode = event.target

  if (element === targetNode) {
    return true
  }

  const rect = element.getBoundingClientRect()
  const clickInRectangle =
    event.clientX > rect.left &&
    event.clientX < rect.right &&
    event.clientY > rect.top &&
    event.clientY < rect.bottom

  if (clickInRectangle) {
    return true
  }

  return targetNode !== null && isDescendant(element, targetNode)
}

export const getScrollableParents = (element: Element | null): Element[] => {
  const result: Element[] = []

  if (element == null) {
    return result
  }

  let wrapper = element?.parentNode ?? null

  while (wrapper) {
    const node = wrapper as Element

    if (isMainDOMNodes(node)) {
      break
    }
    if (isScrollable(node)) {
      result.push(node)
    }
    wrapper = node.parentNode
  }

  return result
}

export const HTMLNodeType = { ELEMENT_NODE: 1 }

import { IFeatureFlagsService, LocalStorage } from './featureFlagsServiceTypes'

export class FeatureFlagsService implements IFeatureFlagsService {
  private flags: Record<string, boolean>
  private localStorage: LocalStorage

  constructor(localStorage: LocalStorage) {
    this.flags = {}
    this.localStorage = localStorage
  }

  setFlags = (flags: Record<string, boolean>) => {
    this.flags = { ...this.flags, ...flags }
  }

  setFlag = (flag: string) => {
    this.flags[flag] = true
  }

  resetFlags = () => {
    this.flags = {}
  }

  isEnabled = (flag: string) => {
    return Boolean(this.flags[flag])
  }

  isEnabledOrLocally = (flag: string) => {
    return this.isEnabled(flag) || Boolean(this.localStorage.getItem(flag))
  }
}

import debugConstructor from 'debug'
import filter from 'lodash/filter'
import find from 'lodash/find'
import get from 'lodash/get'

import { getIsAvailable, getIsProtected } from 'apps/templates/selectors/installedTemplateSelectors'

import { GROUPS, EXTRA_GROUPS, GroupId } from './constants'

const debug = debugConstructor('templates')

const getSelectionWithDeps = (map, selection) => {
  selection = Array.isArray(selection) ? selection : Object.keys(selection)

  return selection.reduce((res, id) => {
    ;(map.dependencies[id] || []).forEach((d) => (res[d] = true))
    res[id] = true
    return res
  }, {})
}

export const getPreviewDataWithCounters = (map, selection) => {
  const previewData = [],
    counters = { proElements: 0, aiElements: 0 }

  const selectionWithDepth = getSelectionWithDeps(map, selection)

  for (let group of GROUPS) {
    const subItems = map.models[group.id].filter((m) => selectionWithDepth[m.id])

    counters[group.id] = subItems.length
    counters.proElements += subItems.filter((si) => si.is_pro).length
    counters.aiElements += subItems.filter((si) => si.is_ai_addon).length

    previewData.push({
      label: group.name,
      groupId: group.id,
      subItems,
    })
  }

  for (let group of EXTRA_GROUPS) {
    const items = map.entities[group.id].filter((m) => selectionWithDepth[m.id])
    counters[group.id] = items.length
  }

  const apps = map.apps.filter((app) => selectionWithDepth['app_' + app.id])
  counters.customKeywords = map.models['keywords'].filter(
    (kw) => selectionWithDepth[kw.id] && !kw.is_system,
  ).length
  return [previewData, counters, apps]
}

export const validateSelection = (map, selection, userId) => {
  const errors = []
  const selectionWithDepth = getSelectionWithDeps(map, selection)
  const selectedList = Object.keys(selectionWithDepth)

  if (selectedList.length === 0) {
    errors.push('Selection is empty. Please select one or more elements and try again.')
  }

  if (selectedList.some((id) => map.byId[id] && map.byId[id].is_corrupted)) {
    errors.push('Selection contains corrupted element(s). Please remove or fix them and try again.')
  }

  if (hasProtectedEntityInSelection(map, selection, userId)) {
    errors.push(
      'Selection contains elements from protected templates. Please remove them and try again.',
    )
  }

  return errors
}

export const getDependentBotFields = (map, selection) => {
  const selectionWithDepth = getSelectionWithDeps(map, selection)
  const selectedList = Object.keys(selectionWithDepth)

  const fieldIds = selectedList
    .filter((id) => id.startsWith('gaf_'))
    .map((id) => Number(id.replace('gaf_', '')))

  return (map.global_fields || []).filter((f) => fieldIds.includes(f.field_id))
}

export function hasProtectedEntityInSelection(map, selection, userId) {
  const entities = get(map, 'items', {})
  const templateInstallations = get(map, 'items.models.template_installations', [])

  if (Object.keys(selection).length === 0) return false
  const selectedMap = Object.keys(selection).map((entityId) => {
    return entities.find((entity) => entity.id === entityId)
  })

  return selectedMap.some((entity) => {
    return (
      entity &&
      getIsProtected(templateInstallations, entity.template_installation_id) &&
      !getIsAvailable(templateInstallations, entity.template_installation_id, userId)
    )
  })
}

export function isFolderHasProtectedFlows(folder, map, userId) {
  const { path } = folder
  const templateInstallations = map.models.template_installations

  const templateFlows = get(map, 'models.flows')

  return templateFlows
    .filter((flow) => flow.folder_path.includes(path))
    .some((flow) => {
      return (
        getIsProtected(templateInstallations, flow.template_installation_id) &&
        !getIsAvailable(templateInstallations, flow.template_installation_id, userId)
      )
    })
}

export function hasFlowMessageTagsWarnings(map, selection) {
  debug('hasFlowMessageTagsWarnings', map, selection)
  const [previewData] = getPreviewDataWithCounters(map, selection)
  const flowsGroup = find(previewData, { groupId: GroupId.FLOWS })
  const flowList = flowsGroup?.subItems ?? []
  const flowListWithFB = filter(flowList, { has_fb_channel: true })
  const flowListWithFBWithTags = filter(flowListWithFB, { has_message_tags: true })
  const flowListWithFBWithReasonId = filter(flowListWithFB, {
    has_one_time_notify_messages: true,
  })
  const hasWarnings =
    flowListWithFB.length && !(flowListWithFBWithTags.length || flowListWithFBWithReasonId.length)
  debug('hasFlowMessageTagsWarnings', {
    hasWarnings,
    flowListWithFB,
    flowListWithFBWithTags,
    flowListWithFBWithReasonId,
    previewData,
  })
  return hasWarnings
}

export const getNestedDependencies = (dependenciesMap, itemId) => {
  const res = new Set()
  const getDependencies = (id) => {
    const newDependencies = dependenciesMap[id] || []
    for (const d of newDependencies) {
      if (res.has(d)) {
        continue
      }
      res.add(d)
      getDependencies(d)
    }
  }

  getDependencies(itemId)
  res.delete(itemId)

  return Array.from(res)
}

import React, { useState, useCallback, useEffect } from 'react'
import { useDrop } from 'react-dnd'

import { BreadcrumbsMenuButton } from 'apps/cms/components/Flows/components/CmsBreadcrumbs/components/BreadcrumbsMenuButton/BreadcrumbsMenuButton'
import { CmsBreadcrumbsSegment } from 'apps/cms/components/Flows/components/CmsBreadcrumbs/lib/types'
import { CmsDragSource } from 'apps/cms/lib/types'
import { CustomizableBreadcrumbsMenu } from 'components/Breadcrumbs/components/CustomizableBreadcrumbsMenu/CustomizableBreadcrumbsMenu'

interface BreadcrumbsMenuProps {
  segments: CmsBreadcrumbsSegment[]
}

export const BreadcrumbsMenu = ({ segments }: BreadcrumbsMenuProps) => {
  const [isMenuOpened, setIsMenuOpened] = useState(false)

  const openMenu = useCallback(() => {
    setIsMenuOpened(true)
  }, [])

  const closeMenu = useCallback(() => {
    setIsMenuOpened(false)
  }, [])

  const [{ isDragging }, connectDropTarget] = useDrop({
    accept: [CmsDragSource.FLOW_CARD, CmsDragSource.FOLDER_CARD],
    collect: (monitor) => ({
      isDragging: monitor.isOver(),
    }),
  })

  useEffect(() => {
    if (isDragging) {
      setIsMenuOpened(true)
    }
  }, [isDragging])

  return (
    <CustomizableBreadcrumbsMenu
      segments={segments}
      isOpen={isMenuOpened}
      openMenu={openMenu}
      closeMenu={closeMenu}
      onButtonClick={closeMenu}
      anchorRef={connectDropTarget}
      renderFolderButton={(props) => <BreadcrumbsMenuButton {...props} />}
    />
  )
}

import React from 'react'
import { useAppDispatch } from 'reduxTyped'

import { fetchGlobalFieldFolders } from 'common/fields/actions/globalFieldFolderActions'
import { fetchTagsFolders } from 'common/tags/actions/tagsFoldersActions'

import { fetchUserFieldFolders } from '../actions/userFieldFoldersActions'
import { initializeAvailableFields } from '../linking/accountAvailableFields'

const AvailableFieldsInitializer = () => {
  const dispatch = useAppDispatch()
  React.useEffect(() => {
    dispatch(initializeAvailableFields())
    dispatch(fetchUserFieldFolders())
    dispatch(fetchGlobalFieldFolders())
    dispatch(fetchTagsFolders())
  }, [dispatch])

  return null
}

export default AvailableFieldsInitializer

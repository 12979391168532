import { z } from 'zod'

import { ChannelType } from 'common/core/constants/ChannelType'
import { lightFlowSchema } from 'shared/api/common/schemas/lightFlow'

export enum StoryMentionReplyType {
  EVERY_TIME = 'every_time',
  ONCE_PER_24_HOURS = 'once_per_24_hours',
}

export const storyMentionReplySchema = z.object({
  type: z.nativeEnum(StoryMentionReplyType),
  enabled: z.boolean(),
  content_holder: z.string(),
  sources: z.null(),
  flow: lightFlowSchema.nullable().optional(),
  channel: z.nativeEnum(ChannelType),
  ctr: z.number().nullable().optional(),
  runs: z.number().nullable().optional(),
  clicked_unq: z.number().nullable().optional(),
  auto_like_enabled: z.boolean(),
  delay: z.number(),
})

export const storyMentionReplySchemas = {
  getData: {
    response: storyMentionReplySchema,
    query: z.undefined(),
    path: z.undefined(),
  },

  switch: {
    response: storyMentionReplySchema,
    query: z.object({
      story_mention_enabled: z.boolean(),
    }),
    path: z.undefined(),
  },

  switchAutoLike: {
    request: z.object({
      story_mention_auto_like_enabled: z.boolean(),
    }),
    response: z.object({
      data: storyMentionReplySchema,
    }),
    query: z.undefined(),
    path: z.undefined(),
  },

  updateDelay: {
    request: z.object({
      delay: z.number(),
    }),
    response: z.object({
      data: storyMentionReplySchema,
    }),
    query: z.undefined(),
    path: z.undefined(),
  },

  changeType: {
    request: z.object({
      channel: z.nativeEnum(ChannelType),
      type: z.string(),
    }),
    response: storyMentionReplySchema,
    query: z.undefined(),
    path: z.undefined(),
  },

  replaceFlow: {
    request: z.object({
      flow_ns: z.string().nullable().optional(),
    }),
    response: storyMentionReplySchema,
    query: z.undefined(),
    path: z.undefined(),
  },

  removeFlow: {
    request: z.undefined(),
    response: storyMentionReplySchema,
    query: z.undefined(),
    path: z.undefined(),
  },
}

export type StoryMentionReply = z.infer<typeof storyMentionReplySchema>

import React, { FunctionComponent } from 'react'
import { Link, l } from '@manychat/manyui'

import { AddOnSlug, PlanSlug } from '../../constants'
import { Currency, DefaultContext } from '../../schemas'

const SEAT_COST = 39

interface DescriptionProps {
  onClickMore: () => void
  currency: Currency
  showMore?: boolean
  showBenefits?: boolean
  prices: DefaultContext['prices']
}

export const productDescription: Record<PlanSlug, FunctionComponent<DescriptionProps>> = {
  [PlanSlug.AUTOMATION]: ({ onClickMore, showMore }) => (
    <>
      <p className="text-secondary">
        {l.translate(
          "With Manychat's Flow Builder you can attract, qualify and nurture customers by building automated conversations that drive engagement and grows your business.",
        )}
      </p>

      {showMore && (
        <Link onClick={onClickMore} className="m-t-sm">
          {l.translate('See what’s included')}
        </Link>
      )}
    </>
  ),
  [PlanSlug.INBOX]: ({ onClickMore, showMore, prices }) => {
    const priceString = l.currency(prices.seat.amount, prices.seat.currency)

    return (
      <>
        <p className="text-secondary">
          {l.translate('Up to 3 Paid Users +{price} per extra Paid User.', {
            price: priceString,
          })}
        </p>
        <p className="text-secondary">
          {l.translate(
            'Manage inbound leads from messaging apps in one centralized view to increase response times and drive sales.',
          )}
        </p>

        {showMore && (
          <Link onClick={onClickMore} className="m-t-sm">
            {l.translate('See what’s included')}
          </Link>
        )}
      </>
    )
  },
  [PlanSlug.GRANDFATHERING]: () => null,
}

export const addOnDescription: Record<AddOnSlug, FunctionComponent> = {
  [AddOnSlug.AI]: () => (
    <p className="text-secondary">
      {l.translate(
        'Enhance Manychat Pro with AI capabilities that build smart automations for you and offer relevant responses. Manychat AI offers a way to have better conversations and higher conversions, with less manual effort and time spent.',
      )}
    </p>
  ),
}

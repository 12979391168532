import { l } from '@manychat/manyui'

export const GenderType = {
  MALE: 'male',
  FEMALE: 'female',
  ALL: 'all',
}

export const GenderTypeMapping = {
  [GenderType.MALE]: [1],
  [GenderType.FEMALE]: [2],
  [GenderType.ALL]: [1, 2],
}

export const GenderTypeOptions = [
  { value: GenderType.ALL, label: 'All' },
  { value: GenderType.MALE, label: 'Men' },
  { value: GenderType.FEMALE, label: 'Women' },
]
export const LocationType = {
  EVERYONE: 'everyone',
  HOME: 'home',
  TRAVEL_IN: 'travel_in',
  RECENT: 'recent',
}

export const LocationTypeLabels = {
  [LocationType.EVERYONE]: 'everyone in this location',
  [LocationType.HOME]: 'who live in this location',
  [LocationType.TRAVEL_IN]: 'traveling in this location',
  [LocationType.RECENT]: 'recently in this location',
}

export const LocationTypeHelp = {
  [LocationType.EVERYONE]: 'People whose home or most recent location is within the selected area.',
  [LocationType.HOME]: 'People whose home is within the selected area.',
  [LocationType.TRAVEL_IN]:
    'People whose most recent location is within the selected area but whose home is more than 125 mi/200 km away.',
  [LocationType.RECENT]: 'People whose most recent location is within the selected area.',
}

export const FacebookLocationType = {
  HOME: 'home',
  TRAVEL_IN: 'travel_in',
  RECENT: 'recent',
}

export const LocationTypeMapping = {
  [LocationType.EVERYONE]: [FacebookLocationType.RECENT, FacebookLocationType.HOME],
  [LocationType.HOME]: [FacebookLocationType.HOME],
  [LocationType.TRAVEL_IN]: ['travel_in'],
  [LocationType.RECENT]: [FacebookLocationType.RECENT],
}

export const BudgetType = {
  DAILY: 'daily',
  LIFETIME: 'lifetime',
}

export const BudgetTypeOptions = [
  { value: BudgetType.DAILY, label: 'Daily Budget' },
  { value: BudgetType.LIFETIME, label: 'Lifetime Budget' },
]

export const TargetingMethodType = {
  INCLUDE: 'include',
  EXCLUDE: 'exclude',
}

export const TargetingMethodTypeOptions = [
  { value: TargetingMethodType.INCLUDE, label: 'Include' },
  { value: TargetingMethodType.EXCLUDE, label: 'Exclude' },
]

export enum AdSetStatusType {
  ACTIVE = 'ACTIVE',
  PAUSED = 'PAUSED',
  DELETED = 'DELETED',
  ARCHIVED = 'ARCHIVED',
}

export const AudienceType = {
  CUSTOM: 'CUSTOM',
  LOOKALIKE: 'LOOKALIKE',
}

export const AudienceTypeLabel = {
  CUSTOM: 'Custom',
  LOOKALIKE: 'Lookalike',
}

export const PlacementsSetupType = {
  AUTOMATIC: 'automatic',
  EDIT: 'edit',
}
export const PlacementsSetupTypeOptions = [
  {
    value: PlacementsSetupType.AUTOMATIC,
    label: 'Automatic Placements (Recommended)',
    tooltip:
      'html# Use automatic placements to maximize your budget and help show your ads to more people. Facebook\'s delivery system will allocate your ad sets\'s budget across multiple placements based on where they\'re likely to perform best. <a style=" text-decoration: underline" target="_blank" href="https://www.facebook.com/business/help/965529646866485">Learn More</a>',
  },
  {
    value: PlacementsSetupType.EDIT,
    label: 'Edit Placements',
    tooltip:
      'html# Removing placements may reduce the number of people you reach and may make it less likely that you\'ll meet your goals. <br><a style=" text-decoration: underline" target="_blank" href="https://www.facebook.com/business/help/965529646866485">Learn More</a>',
  },
]

export const PlacementsDeviceType = {
  ALL: 'all_devices',
  MOBILE: 'mobile',
  DESKTOP: 'desktop',
}
export const PlacementsDeviceTypeOptions = [
  { value: PlacementsDeviceType.ALL, label: 'All Devices (Recommended)' },
  { value: PlacementsDeviceType.MOBILE, label: 'Mobile' },
  { value: PlacementsDeviceType.DESKTOP, label: 'Desktop' },
]

export const InteractionType = {
  CLICK_TO_MESSENGER: 'click_to_messenger',
  SPONSORED_MESSAGE: 'sponsored_messages',
}

export const InteractionTypeOptions = [
  {
    value: InteractionType.CLICK_TO_MESSENGER,
    label: 'Click to Messenger',
    tooltip:
      'Send people from ads in Facebook, Instagram and/or Messenger into Messenger conversations. You will create both the ad and the message people see in Messenger.',
  },
  {
    value: InteractionType.SPONSORED_MESSAGE,
    disabled: true,
    label: 'Sponsored Message',
    help: l.translate('Sponsored Message is no longer supported by Meta.'),
  },
]

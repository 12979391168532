import { z } from 'zod'

import { UsersValueRecommendationType } from 'apps/dashboard/components/HomeTab/UsersValue/models/UsersValuePeriodData'
import { IgUsersValuePeriod } from 'apps/dashboard/constants/IgUsersValueConstants'
import { WidgetTypes } from 'apps/growthTools/models/Widget/constants'
import { ChannelType } from 'common/core/constants/ChannelType'

export enum SystemTriggerType {
  DEFAULT_REPLY = 'default_reply',
  FOLLOW_UPS = 'follow_ups',
  WELCOME_MESSAGE = 'welcome_message',
  STORY_MENTION_REPLY = 'story_mention_reply',
  ICEBREAKERS = 'icebreakers',
  KEYWORDS_RULES = 'keywords_rules',
  INTENT_RULES = 'intent_rules',
  TRIGGER_RULES = 'trigger_rules',
}

const triggerStatisticSchema = z.object({
  trigger_title: z.string(),
  trigger_type: z.union([z.nativeEnum(WidgetTypes), z.nativeEnum(SystemTriggerType)]),
  flow_ns: z.string(),
  sent_unq: z.number(),
  sent_unq_last_7_days: z.number(),
  ctr: z.number(),
  flow_title: z.string(),
  channel: z.nativeEnum(ChannelType).nullable(),
})

const analyticsWidgetSchema = z.object({
  ts_outdated: z.number().nullable(),
  is_generating: z.boolean(),
  triggers_stats: z.array(triggerStatisticSchema).nullable(),
})

const RecommendationSchema = z
  .object({
    type: z.enum([
      UsersValueRecommendationType.Comments,
      UsersValueRecommendationType.StoryReplies,
      UsersValueRecommendationType.DirectMessages,
    ]),
    quick_campaign_id: z.number(),
  })
  .or(
    z.object({
      type: z.enum([UsersValueRecommendationType.StoryMentions]),
      quick_campaign_id: z.null(),
    }),
  )

const IgUsersValueSchema = z
  .object({
    default_active_period: z.nativeEnum(IgUsersValuePeriod),
    all_auto_interactions_1d: z.number(),
    all_auto_interactions_1d_percent: z.number(),
    all_auto_interactions_7d: z.number(),
    all_auto_interactions_7d_percent: z.number(),
    all_interactions_1d: z.number(),
    all_interactions_7d: z.number(),
    auto_comments_1d: z.number(),
    auto_comments_1d_percent: z.number(),
    auto_comments_7d: z.number(),
    auto_comments_7d_percent: z.number(),
    auto_story_mentions_1d: z.number(),
    auto_story_mentions_1d_percent: z.number(),
    auto_story_mentions_7d: z.number(),
    auto_story_mentions_7d_percent: z.number(),
    auto_story_replies_1d: z.number(),
    auto_story_replies_1d_percent: z.number(),
    auto_story_replies_7d: z.number(),
    auto_story_replies_7d_percent: z.number(),
    comments_1d: z.number(),
    comments_7d: z.number(),
    ig_enable_dttm: z.string(),
    story_mentions_1d: z.number(),
    story_mentions_7d: z.number(),
    story_replies_1d: z.number(),
    story_replies_7d: z.number(),
    statistics_readiness_date: z.string().optional(),
    direct_1d: z.number(),
    direct_7d: z.number(),
    auto_direct_1d: z.number(),
    auto_direct_1d_percent: z.number(),
    auto_direct_7d: z.number(),
    auto_direct_7d_percent: z.number(),

    recommendations: z.array(RecommendationSchema),
  })
  .nullable()

export const homeTabApiSchemas = {
  getWhatsAppOnboardingData: {
    path: z.undefined(),
    query: z.undefined(),
    request: z.undefined(),
    response: z.object({
      wa_broadcasting_onboarding: z.object({
        created_broadcasts_count: z.number(),
        imported_wa_contacts_count: z.number(),
      }),
    }),
  },
  analyticsWidget: {
    path: z.undefined(),
    query: z.undefined(),
    request: z.undefined(),
    response: analyticsWidgetSchema,
  },
  generateAnalyticsWidgetData: {
    path: z.undefined(),
    query: z.undefined(),
    request: z.undefined(),
    response: z.undefined(),
  },
  igUsersValueData: {
    query: z.undefined(),
    path: z.undefined(),
    response: z.object({
      data: IgUsersValueSchema,
    }),
  },
}

export type IgUsersValueSchemaType = z.infer<typeof IgUsersValueSchema>

import { z } from 'zod'

export const ZendeskSchemas = {
  messagingSignon: {
    response: z
      .object({
        token: z.string(),
      })
      .optional(),
    request: z.object({
      external_id: z.number(),
    }),
    query: z.undefined(),
    path: z.undefined(),
  },
}

import get from 'lodash/get'
export const getTemplateFieldsSettings = (state) => {
  return get(state, 'templates.currentInstalled.item.templateFields') || []
}

export const getWizardFields = (state) => {
  const fields = get(state, 'globalFields.items') || []
  const fieldsSettings = getTemplateFieldsSettings(state)

  return fieldsSettings
    .map((fs) =>
      fs.wizard_enabled ? fields.find((f) => String(f.field_id) === String(fs.field_id)) : null,
    )
    .filter((f) => f)
}

export const getCurrent = (state) => get(state, 'templates.currentInstalled.item', {})

export const isFetching = (state) => get(state, 'templates.currentInstalled.fetching')

export const getIsProtected = (templateInstallations, installationId) => {
  if (!templateInstallations) return false

  const template = templateInstallations.find(
    (template) => template.installation_id === installationId,
  )
  return get(template, 'is_protected', false)
}

export const getIsAvailable = (templateInstallations, installationId, userId) => {
  if (!templateInstallations) return true

  const template = templateInstallations.find(
    (template) => template.installation_id === installationId,
  )
  const templateUserId = get(template, 'template_author_user_id', null)
  return templateUserId === userId
}

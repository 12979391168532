import React, { MouseEventHandler, useCallback } from 'react'
import { l, useToast } from '@manychat/manyui'

import { NodeType } from 'common/builder/constants/NodeType'
import { ChannelType } from 'common/core/constants/ChannelType'
import If from 'components/If/If'
import { linkURL } from 'utils/url'

import { AvatarKeywordTTBlock } from './AvatarKeywordTTBlock'
import {
  Block,
  ButtonBlock,
  LinkBlock,
  AvatarButtonBlock,
  AvatarKeywordIGBlock,
} from './PreviewBlocks'

export interface IPreviewData {
  channels: {
    [ChannelType.FB]?: {
      connected: boolean
      has_opt_in: boolean
      update_last_interaction_required: boolean
      name: string
      link_to_messenger: string
      payload: string
    }
    [ChannelType.INSTAGRAM]?: {
      connected: boolean
      username: string
      business_id: number
      preview_keyword: string
      avatar_url: string | null
    }
    [ChannelType.TIKTOK]?: {
      connected: boolean
      username: string
      preview_keyword: string
      avatar_url: string | null
    }
    [ChannelType.SMS]?: {
      connected: boolean
      has_opt_in: boolean
      phone_number: string
      available_country_code: boolean
    }
    [ChannelType.EMAIL]?: {
      connected: boolean
      has_opt_in: boolean
      has_address: boolean
      email: string
    }
    [ChannelType.WHATSAPP]?: {
      connected: boolean
      has_opt_in: boolean
      link_to_whatsapp: string
      link_to_whatsapp_with_preview_ref: string | null
      name: string | null
      update_last_interaction_required: boolean
    }
    [ChannelType.TELEGRAM]?: {
      connected: boolean
      has_opt_in: boolean
      link_to_telegram: string
      bot_name: string
      bot_username: string
    }
  }
  has_otn: boolean
  has_otnr: boolean
  subscriber: {
    id: number | null
    email: string
    avatar: string
  }
  state: boolean
}

const PreviewSuccessAlert = ({
  data,
  rootNodeType,
}: {
  data: IPreviewData
  rootNodeType: string
}) => {
  const {
    facebook: fbInfo,
    instagram: instagramInfo,
    whatsapp: whatsappInfo,
    sms: smsInfo,
    email: emailInfo,
    telegram: telegramInfo,
    tiktok: tiktokInfo,
  } = data.channels

  const { onClose } = useToast()

  const handleFbPreviewBtnMouseClick: MouseEventHandler = useCallback(
    (event) => {
      event.stopPropagation()
      onClose()
    },
    [onClose],
  )

  const fbButtonInfo = [NodeType.INSTAGRAM, NodeType.WHATS_APP, NodeType.TELEGRAM].some(
    (type) => rootNodeType === type,
  )
    ? undefined
    : {
        name: l.translate('Open Messenger'),
        url: fbInfo?.link_to_messenger,
      }

  const instagramKeywordInfo = [NodeType.WHATS_APP, NodeType.CONTENT, NodeType.TELEGRAM].some(
    (type) => rootNodeType === type,
  )
    ? undefined
    : instagramInfo?.preview_keyword

  const isEnabledWhatsAppBtnLink =
    whatsappInfo?.link_to_whatsapp || whatsappInfo?.link_to_whatsapp_with_preview_ref
  const whatsappBtnUrl = whatsappInfo?.update_last_interaction_required
    ? whatsappInfo?.link_to_whatsapp
    : whatsappInfo?.link_to_whatsapp_with_preview_ref
  const whatsappButtonInfo =
    [NodeType.INSTAGRAM, NodeType.CONTENT, NodeType.TELEGRAM].some(
      (type) => rootNodeType === type,
    ) && isEnabledWhatsAppBtnLink
      ? undefined
      : {
          name: l.translate('Open WhatsApp'),
          url: whatsappBtnUrl ?? '',
        }

  const telegramButtonInfo = [NodeType.INSTAGRAM, NodeType.WHATS_APP, NodeType.CONTENT].some(
    (type) => rootNodeType === type,
  )
    ? undefined
    : {
        name: l.translate('Open Telegram'),
        url: telegramInfo?.link_to_telegram ?? '',
      }

  const sortedBlocks = [
    {
      isRootNode: rootNodeType === NodeType.CONTENT,
      key: 'fb-preview-block',
      view: (
        <If condition={Boolean(fbInfo)}>
          <AvatarButtonBlock
            dataTestId="fb-preview-block"
            title={l.translate('Messenger')}
            info={fbInfo?.name ?? ''}
            avatar={data.subscriber.avatar}
            btn={fbButtonInfo}
            onBtnClick={handleFbPreviewBtnMouseClick}
          />
        </If>
      ),
    },
    {
      isRootNode: rootNodeType === NodeType.INSTAGRAM,
      key: 'instagram-preview-block',
      view: (
        <If condition={Boolean(instagramInfo)}>
          <AvatarKeywordIGBlock
            dataTestId="instagram-preview-block"
            title={l.translate('Instagram')}
            info={instagramInfo?.username ?? ''}
            avatar={instagramInfo?.avatar_url ?? ''}
            keyword={instagramKeywordInfo}
            keywordLabel={l.translate(
              'Open Instagram and send a Direct Message with this three-digits code to your <b>@{igAccountName}</b> account from a different IG account',
              {
                igAccountName: instagramInfo?.username,
              },
            )}
          >
            {data.has_otnr ? (
              <LinkBlock
                dataTestId="otn-preview-block"
                text={l.translate(
                  'We send you a not real request in the preview to verify that everything is working correctly.',
                )}
              />
            ) : undefined}
          </AvatarKeywordIGBlock>
        </If>
      ),
    },
    {
      isRootNode: rootNodeType === NodeType.TIKTOK,
      key: 'tiktok-preview-block',
      view: tiktokInfo ? (
        <AvatarKeywordTTBlock
          dataTestId="tiktok-preview-block"
          title={l.translate('TikTok')}
          info={tiktokInfo?.username ?? ''}
          avatar={tiktokInfo?.avatar_url ?? ''}
          keyword={tiktokInfo?.preview_keyword}
          keywordLabel={l.translate(
            'Open TikTok and send a Direct Message with this three-digits code to your <span class="text-secondary">@{ttAccountName}</span> account from a different TikTok account',
            {
              ttAccountName: tiktokInfo?.username,
            },
          )}
        >
          {data.has_otnr ? (
            <LinkBlock
              dataTestId="otn-preview-block"
              text={l.translate(
                'We send you a not real request in the preview to verify that everything is working correctly.',
              )}
            />
          ) : null}
        </AvatarKeywordTTBlock>
      ) : null,
    },
    {
      isRootNode: rootNodeType === NodeType.WHATS_APP,
      key: 'whatsapp-preview-block',
      view: (
        <If condition={Boolean(whatsappInfo)}>
          <ButtonBlock
            isHasLeftMargin={Boolean(instagramInfo) || Boolean(fbInfo)}
            dataTestId="whatsapp-preview-block"
            title={l.translate('WhatsApp')}
            info={whatsappInfo?.name ?? ''}
            btn={whatsappButtonInfo}
          />
        </If>
      ),
    },
    {
      isRootNode: rootNodeType === NodeType.TELEGRAM,
      key: 'telegram-preview-block',
      view: (
        <If condition={Boolean(telegramInfo)}>
          <ButtonBlock
            isHasLeftMargin={Boolean(instagramInfo) || Boolean(fbInfo)}
            dataTestId="telegram-preview-block"
            title={l.translate('Telegram')}
            info={telegramInfo?.bot_name ?? ''}
            btn={telegramButtonInfo}
          />
        </If>
      ),
    },
  ]

  return (
    <>
      <If condition={data.subscriber.id}>
        <span
          data-test-id="preview-edit-contact-link"
          className="d-block text-primary m-b-xs c-pointer"
        >
          <a
            href={linkURL(`subscribers/${data.subscriber.id}`)}
            onClick={(event) => event.stopPropagation()}
            target="_blank"
            rel="noreferrer"
          >
            {l.translate('Edit contact')}
          </a>
        </span>
      </If>
      <span className="d-block m-b">{l.translate('Preview will be sent to you')}</span>
      {sortedBlocks
        .sort((prev, next) => Number(next.isRootNode) - Number(prev.isRootNode))
        .map((block) => (
          <React.Fragment key={block.key}>{block.view}</React.Fragment>
        ))}
      <If condition={Boolean(emailInfo)}>
        <Block
          isHasLeftMargin={Boolean(instagramInfo) || Boolean(fbInfo)}
          dataTestId="email-preview-block"
          title={l.translate('Email')}
          info={emailInfo?.email ?? ''}
        />
      </If>
      <If condition={Boolean(smsInfo)}>
        <Block
          isHasLeftMargin={Boolean(instagramInfo) || Boolean(fbInfo)}
          dataTestId="sms-preview-block"
          title={l.translate('Sms')}
          info={smsInfo?.phone_number ?? ''}
        />
      </If>
    </>
  )
}

export default PreviewSuccessAlert

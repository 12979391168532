import React from 'react'
import cx from 'classnames'
import * as Icon from '@manychat/icons'

import Lozenge, { LozengeProps } from '../../Lozenge'

import { FormFieldLabelContainerProps } from './interfaces'

import cm from './FormField.module.css'

type Props = FormFieldLabelContainerProps

export const FormFieldLabelContainer = ({
  id,
  label,
  inputId,
  infoText,
  infoTextWidth,
  lozenge,
  required,
  disabled,
  fieldClasses = {},
  labelClassName,
}: Props) => {
  return (
    <label
      htmlFor={inputId}
      id={id}
      className={cx(cm.label, fieldClasses.label, disabled && cm.disabled, labelClassName)}
    >
      {label && <span className={cx(cm.labelContainer)}>{label}</span>}
      {required && <span className={cx(cm.required)}>*</span>}
      {infoText && (
        <span className={cx(cm.infoText)}>
          <Icon.Question
            size={16}
            data-title={infoText}
            data-title-at="top"
            data-title-width={infoTextWidth}
          />
        </span>
      )}
      {lozenge && <Lozenge type={lozenge as LozengeProps['type']} />}
    </label>
  )
}

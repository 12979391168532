import { AxiosError } from 'axios'

export class ServerError extends AxiosError {
  constructor(error: AxiosError) {
    super(error.message, AxiosError.ERR_BAD_REQUEST, error.config, error.request, error.response)

    this.name = 'ServerError'
  }
}

export class ClientError extends AxiosError {
  constructor(error: AxiosError) {
    super(error.message, AxiosError.ERR_BAD_REQUEST, error.config, error.request, error.response)

    this.name = 'ClientError'
  }
}

export const enum AccessErrorReason {
  UNAUTHORIZED = 'unauthorized',
  LEGAL = 'legal',
}
export class AccessError extends AxiosError {
  reason: AccessErrorReason
  constructor(error: AxiosError, reason: AccessErrorReason) {
    super(error.message, AxiosError.ERR_BAD_REQUEST, error.config, error.request, error.response)

    this.name = 'ExpectedError'
    this.reason = reason
  }
}

export class RedirectError extends AxiosError {
  constructor(error: AxiosError) {
    super(error.message, AxiosError.ERR_BAD_REQUEST, error.config, error.request, error.response)

    this.name = 'RedirectError'
  }
}

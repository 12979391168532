import { createSelector } from '@reduxjs/toolkit'
import mapValues from 'lodash/mapValues'

import { NodeType } from 'common/builder'
import {
  IBlock,
  IQuickReplyBlock,
  ITelegramKeyboardBlock,
  ITemplateBlock,
} from 'common/builder/blocks/blockInterfaces'
import { BlockType } from 'common/builder/constants/BlockType'
import { ChannelNodeTypes } from 'common/builder/constants/NodeType'
import { NodeTypeToChannelMap } from 'common/builder/constants/NodeTypeToChannelTypeMap'
import { INode, ChannelNode, AiAgentNodeType } from 'common/builder/nodes/nodeInterfaces'
import * as entitySelectors from 'common/builder/selectors/builder/entitySelectors'
import {
  getAllNodesList,
  getBlocksMap,
  getButtonsMap,
  getNodesList,
  getParentNode,
  getNodesMap,
  getNodesListByType,
} from 'common/builder/selectors/builder/entitySelectors'
import builderSelectors from 'common/builder/selectors/builder/index'
import { isMessageTemplateBlock } from 'common/builder/typeguards'
import { extractNodeChannel } from 'common/builder/utils/extractNodeChannel'
import { isAiAgentNodeWithChannel } from 'common/builder/utils/isAiAgentNodeWithChannel'
import { ChannelType } from 'common/core/constants/ChannelType'

import { getFirstNode } from './builderStateSelectors'

export const getNodeIdMap = (state: RootState, builderId: string) => {
  const nodeMap = entitySelectors.getNodesMap(state, builderId)
  return mapValues(nodeMap, (node) => node.contentId)
}

/**
 * Returns a map with ids of connected nodes.
 *
 * Example:
 * ```
 * Condition -> Node1 -> Node3
 *           -> Node2 -> Node3
 * ```
 *
 * When `{ format: 'targetToSource' }` is passed, the result is:
 * ```ts
 * {
 *   [Node1.id]: [Condition.id],
 *   [Node2.id]: [Condition.id],
 *   [Node3.id]: [Node1.id, Node2.id],
 * }
 * ```
 *
 * When `{ format: 'sourceToTarget' }` is passed, the result is:
 * ```ts
 * {
 *   [Condition.id]: [Node1.id, Node2.id],
 *   [Node1.id]: [Node3.id],
 *   [Node2.id]: [Node3.id],
 * }
 * ```
 */
export const getConnectedNodesIdsMap = (
  state: RootState,
  builderId: string,
  options: { format: 'targetToSource' | 'sourceToTarget' },
): Record<string, string[]> => {
  const nodesList = getAllNodesList(state, builderId)
  const blocksMap = getBlocksMap(state, builderId)
  const buttonsMap = getButtonsMap(state, builderId)

  const result: Record<string, string[]> = {}

  const save = (targetNodeId: string, sourceNodeId: string) => {
    const key = options.format === 'targetToSource' ? targetNodeId : sourceNodeId
    const value = options.format === 'targetToSource' ? sourceNodeId : targetNodeId

    if (!result[key]) {
      result[key] = [value]
      return
    }

    if (!result[key].includes(value)) {
      result[key].push(value)
    }
  }

  nodesList.forEach((node) => {
    if ('blocks' in node) {
      node.blocks.forEach((blockId) => {
        const block = blocksMap[blockId]

        if (block && 'targetId' in block && block.targetId) {
          save(block.targetId, node.id)
        }

        if (!block.buttons) {
          return
        }

        block.buttons.forEach((buttonId) => {
          const button = buttonsMap[buttonId]

          if (button && 'targetId' in button && button.targetId) {
            save(button.targetId, node.id)
          }
        })
      })
    }

    if (!('targetId' in node) || !node.targetId) {
      return result
    }

    save(node.targetId, node.id)

    return result
  })

  return result
}

export const getOnlyUsedChannelNodeType = (
  state: RootState,
  builderId: string,
): NodeType | null => {
  const channelNodes = getNodesList(state, builderId).filter((node) =>
    ChannelNodeTypes.includes(node.nodeType),
  )

  if (channelNodes.length === 0) {
    return null
  }

  let result = null

  for (const channelNode of channelNodes) {
    if (result !== null && result !== channelNode.nodeType) {
      return null
    }

    result = channelNode.nodeType
  }

  return result
}

export const getOnlyUsedChannelInAiSteps = createSelector(
  [
    (state: RootState, builderId: string) =>
      getNodesListByType(state, builderId, NodeType.AI_AGENT) as AiAgentNodeType[],
  ],
  (aiStepNodes): ChannelType | null => {
    const channels = aiStepNodes.filter((node) => node.channel).map((node) => node.channel)

    return new Set(channels).size === 1 ? channels[0] : null
  },
)

/**
 * Returns only used ChannelType in channel and AI Step nodes.
 */
export const getMainChannel = (state: RootState, builderId: string): ChannelType | null => {
  const onlyUsedChannelNodeType = getOnlyUsedChannelNodeType(state, builderId)
  const onlyUsedChannelInAiSteps = getOnlyUsedChannelInAiSteps(state, builderId)

  if (onlyUsedChannelNodeType === null) {
    return onlyUsedChannelInAiSteps
  }

  const aiNodes = getNodesListByType(state, builderId, NodeType.AI_AGENT) as AiAgentNodeType[]

  if (aiNodes.length === 0) {
    return NodeTypeToChannelMap[onlyUsedChannelNodeType]
  }

  if (
    onlyUsedChannelInAiSteps !== null &&
    NodeTypeToChannelMap[onlyUsedChannelNodeType] === onlyUsedChannelInAiSteps
  ) {
    return NodeTypeToChannelMap[onlyUsedChannelNodeType]
  }

  return null
}

export const getNodeChannel = (
  state: RootState,
  builderId: string,
  entityId: string,
): ChannelType | null => {
  const node = getParentNode(state, builderId, entityId)

  if (!node) {
    return null
  }

  return extractNodeChannel(node)
}

/**
 * It takes `nodeId` and iterates all nodes connected to it to find
 * the first channel node.
 */
const findFirstConnectedChannelNode = ({
  nodesMap,
  nodesConnectionsMap,
  nodeId,
  visitedNodes = new Set<string>(),
}: {
  nodesMap: Record<string, INode>
  nodesConnectionsMap: Record<string, string[]>
  nodeId: string
  visitedNodes?: Set<string>
}): ChannelNode | null => {
  const node = nodesMap[nodeId]

  if (!node || visitedNodes.has(node.id)) {
    return null
  }

  visitedNodes.add(node.id)

  if (!nodesConnectionsMap[nodeId]) {
    if (isAiAgentNodeWithChannel(node)) {
      return node
    }

    return ChannelNodeTypes.includes(nodesMap[nodeId].nodeType) ? (node as ChannelNode) : null
  }

  for (const sourceNodeId of nodesConnectionsMap[nodeId]) {
    const targetNode = nodesMap[sourceNodeId]

    if (!targetNode) {
      continue
    }

    if (isAiAgentNodeWithChannel(targetNode) || ChannelNodeTypes.includes(targetNode.nodeType)) {
      return targetNode as ChannelNode
    }

    const currentNodeSources = nodesConnectionsMap[sourceNodeId]

    if (!currentNodeSources) {
      continue
    }

    for (const currentNodeSource of currentNodeSources) {
      const node = findFirstConnectedChannelNode({
        nodesMap,
        nodesConnectionsMap,
        nodeId: currentNodeSource,
        visitedNodes,
      })

      if (node) {
        return node
      }
    }
  }

  return null
}

export const getFirstChannelNodeByChain = (
  state: RootState,
  builderId: string,
  options: {
    sourceId: string
    searchDirection: 'left' | 'right'
  },
): ChannelNode | null => {
  const node = getParentNode(state, builderId, options.sourceId)

  if (!node) {
    return null
  }

  const nodesMap = getNodesMap(state, builderId)
  const targetToSourceNodesMap = getConnectedNodesIdsMap(state, builderId, {
    format: options.searchDirection === 'left' ? 'targetToSource' : 'sourceToTarget',
  })

  return findFirstConnectedChannelNode({
    nodesMap,
    nodesConnectionsMap: targetToSourceNodesMap,
    nodeId: node.id,
  })
}

export const getFirstChannelTypeBySourceChain = (
  state: RootState,
  builderId: string,
  sourceId: string,
): ChannelType | null => {
  const node = getFirstChannelNodeByChain(state, builderId, {
    searchDirection: 'left',
    sourceId,
  })

  if (!node) {
    return null
  }

  return extractNodeChannel(node)
}

export const getFirstAgentNodeWithChannelBeforeChannelNode = (
  state: RootState,
  builderId: string,
) => {
  const firstNode = getFirstNode(state, builderId)

  if (!firstNode || ChannelNodeTypes.includes(firstNode.nodeType)) {
    return null
  }

  if (firstNode.nodeType === NodeType.AI_AGENT && firstNode.channel) {
    return firstNode
  }

  const firstChannelNodeFromChain = getFirstChannelNodeByChain(state, builderId, {
    sourceId: firstNode.id,
    searchDirection: 'right',
  })

  if (
    firstChannelNodeFromChain &&
    firstChannelNodeFromChain.nodeType === NodeType.AI_AGENT &&
    firstChannelNodeFromChain.channel
  ) {
    return firstChannelNodeFromChain
  }

  return null
}

export const getQuickReplyBlock = (
  state: RootState,
  builderId: string,
  nodeId: string,
): IQuickReplyBlock | undefined => {
  const blocks = entitySelectors.getBlocks(state, builderId, nodeId)
  return blocks.find((block) => block.type === BlockType.QUICK_REPLY) as IQuickReplyBlock
}

export const getTelegramKeyboardBlock = (
  state: RootState,
  builderId: string,
  nodeId: string,
): ITelegramKeyboardBlock | undefined => {
  const blocks = entitySelectors.getBlocks(state, builderId, nodeId)
  return blocks.find(
    (block) => block.type === BlockType.TELEGRAM_KEYBOARD,
  ) as ITelegramKeyboardBlock
}

export const getOrdinaryBlocks = createSelector(
  [
    (state: RootState, builderId: string, nodeId: string) =>
      entitySelectors.getBlocks(state, builderId, nodeId),
  ],
  (blocks) => {
    return blocks.filter(
      (block) => block.type !== BlockType.QUICK_REPLY && block.type !== BlockType.TELEGRAM_KEYBOARD,
    )
  },
)

export const getLastOrdinaryBlock = createSelector(
  getOrdinaryBlocks,
  (blocks) => blocks[blocks.length - 1],
)

export const getBlockWithWaMessageTemplateInNode = (
  state: RootState,
  builderId: string,
  node?: INode,
): ITemplateBlock | null => {
  const id = node?.blocks?.find((blockId) => {
    const block = entitySelectors.getBlockById(state, builderId, blockId)
    return block?.type === BlockType.MESSAGE_TEMPLATE
  })

  if (!id) return null

  const block = entitySelectors.getBlockById(state, builderId, id)
  if (block && isMessageTemplateBlock(block)) return block

  return null
}

export const getBlockWithWaMessageTemplateFromFirstNode = (
  state: RootState,
  builderId: string,
): ITemplateBlock | null => {
  const firstNode = getFirstNode(state, builderId)
  if (!firstNode) return null

  return getBlockWithWaMessageTemplateInNode(state, builderId, firstNode)
}

export const getIsQuickReplyDisabledForFb = (
  state: RootState,
  builderId: string,
  nodeId: string,
) => {
  const blocks = builderSelectors.node.getOrdinaryBlocks(state, builderId, nodeId)
  const quickReplyBlock = builderSelectors.node.getQuickReplyBlock(state, builderId, nodeId)
  if (!blocks?.length || !quickReplyBlock) return false

  const lastBlock = blocks[blocks.length - 1]
  const isLastBlockUserInput = lastBlock?.type === BlockType.FORM_QUESTION
  const doesQuickReplyHasButtons = Boolean(quickReplyBlock?.buttons?.length)

  return !doesQuickReplyHasButtons && isLastBlockUserInput
}

export const getIsQuickReplyDisabledForIg = (
  state: RootState,
  builderId: string,
  nodeId: string,
) => {
  const blocks = builderSelectors.node.getOrdinaryBlocks(state, builderId, nodeId)
  const quickReplyBlock = builderSelectors.node.getQuickReplyBlock(state, builderId, nodeId)
  if (!blocks?.length || !quickReplyBlock) return false

  const lastBlock = blocks[blocks.length - 1]
  const isLastBlockText = lastBlock?.type === BlockType.TEXT
  const doesLastBlockHasButtons = Boolean(lastBlock?.buttons?.length)
  const doesQuickReplyHasButtons = Boolean(quickReplyBlock?.buttons?.length)

  return !doesQuickReplyHasButtons && (!isLastBlockText || doesLastBlockHasButtons)
}

const doesBlockHasButtons = (block: IBlock | undefined) => Boolean(block?.buttons?.length)

const isCurrentBlockLast = (blockId: string, lastBlockId: string) => lastBlockId === blockId

export const getIsAddButtonDisabledForIg = (
  state: RootState,
  builderId: string,
  blockId: string,
) => {
  const { nodeType, id } = entitySelectors.getParentNode(state, builderId, blockId) ?? {}
  if (nodeType !== NodeType.INSTAGRAM) return false

  const quickReplyBlock = getQuickReplyBlock(state, builderId, id)
  const lastBlock = getLastOrdinaryBlock(state, builderId, id)

  return (
    isCurrentBlockLast(blockId, lastBlock?.id) &&
    !doesBlockHasButtons(lastBlock) &&
    doesBlockHasButtons(quickReplyBlock)
  )
}

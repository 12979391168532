import { NotificationItem } from 'common/core/components/NotifyBar/NotifyInterfaces'
import { Body, McAPI } from 'utils/api/types'

interface INotificationsAPI {
  getNotifications: () => Promise<NotificationItem[]>
}

export const makeNotificationsAPI = (api: McAPI): INotificationsAPI => ({
  async getNotifications() {
    const response = await api.get<{ notifications: NotificationItem[] } & Body>(
      '/:currentAccountID/settings/getNotifications',
    )

    return response.notifications
  },
})

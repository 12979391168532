import BaseBlockConfig from 'common/builder/blocks/BaseBlockConfig'
import { CardsBlockImageAspectRatioType } from 'common/builder/blocks/blockConstants'
import { ICardsBlock, IBlockBase } from 'common/builder/blocks/blockInterfaces'
import { BlockType } from 'common/builder/constants/BlockType'

class CardsBlockConfig extends BaseBlockConfig {
  blockType = BlockType.CARDS

  getDefaults = (): Omit<ICardsBlock, keyof IBlockBase> => ({
    imageAspectRatio: CardsBlockImageAspectRatioType.HORIZONTAL,
  })
}

export default new CardsBlockConfig()

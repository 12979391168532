import { l } from '@manychat/manyui'

import { FieldGroupId } from 'common/fields/entities/enums'

export const OptionListConfig = {
  [FieldGroupId.USER]: {
    newOptionLabel: l.makeTranslate('+ User Field'),
    emptySearchLabel: l.makeTranslate('No User Field with such name is found'),
    emptyCreateLabel: l.makeTranslate(
      'Create User Fields to save customer data, such as birthdates or answers',
    ),
  },
  [FieldGroupId.BOT]: {
    newOptionLabel: l.makeTranslate('+ Bot Field'),
    emptySearchLabel: l.makeTranslate('No Bot Field with such name is found'),
    emptyCreateLabel: l.makeTranslate(
      'Create Bot Fields to manage business data such as product quantities',
    ),
  },
  [FieldGroupId.TAG]: {
    newOptionLabel: l.makeTranslate('+ Tag'),
    emptySearchLabel: l.makeTranslate('No Tag with such name is found'),
    emptyCreateLabel: l.makeTranslate(
      'Create Tag to label contacts for convenient organization and segmentation',
    ),
  },
}

export type AllowNewField = keyof typeof OptionListConfig

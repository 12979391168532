import React from 'react'
import { l } from '@manychat/manyui'

import cm from 'common/core/components/NotifyBar/StaticNotifications/StaticNotifications.module.css'
import { KnowledgeBaseLink } from 'common/core/components/SupportLinks'

const WATierLimitNotification = () => {
  return (
    <span>
      {l.translate(
        'WhatsApp messages are temporarily suspended because you reached <a href="{href}" class="{class}" target="_blank" rel="noopener noreferrer">daily sending limit</a>.',
        {
          href: KnowledgeBaseLink.WHATSAPP_LIMITS,
          class: cm.more,
        },
      )}
    </span>
  )
}

export default WATierLimitNotification

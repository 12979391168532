interface IOptionsFilter {
  (label: string, query: string): boolean
}

interface IOptionsFilterFabric {
  (ignoreChars?: RegExp): IOptionsFilter
}

export const createOptionFilter: IOptionsFilterFabric = (ignoreCharsRegex) => (label, query) => {
  if (!query) {
    return true
  }
  if (ignoreCharsRegex) {
    const IGNORE_CHARS_REGEX = new RegExp(ignoreCharsRegex.source, 'g')
    label = label.replace(IGNORE_CHARS_REGEX, '').toLowerCase()
    query = query.replace(IGNORE_CHARS_REGEX, '').toLowerCase()
  }
  return label.toLowerCase().indexOf(query.toLowerCase()) >= 0
}

import React, { MouseEventHandler } from 'react'
import cx from 'classnames'
import { BtnV2, Icon, l } from '@manychat/manyui'

import { useBillingInfo, useDefaultContext } from '../../hooks'
import { Product } from '../../schemas'
import { Benefits } from '../Benefits'

import { addOnDescription, productDescription } from './ProductDescription'

import cm from './ProductList.module.css'

type ProductCardProps = Product & {
  showMore?: boolean
  showBenefits?: boolean
  withCheckbox?: boolean
  onClick: MouseEventHandler<HTMLButtonElement>
  onClickMore: () => void
}

export const ProductCard = ({
  slug,
  type,
  withCheckbox = false,
  isInCart = false,
  onClick,
  name,
  price,
  fullPrice,
  disabled,
  dynamicPrice = false,
  onClickMore,
  showMore = true,
  showBenefits = true,
}: ProductCardProps) => {
  const { currency } = useBillingInfo()
  const Description = type === 'plan' ? productDescription[slug] : addOnDescription[slug]
  const isAi = type === 'addOn' && slug === 'ai'
  const isExistDifferentBetweenPrices = fullPrice !== undefined && fullPrice > price
  const isExistAiAddOnDiscount = isAi && isExistDifferentBetweenPrices
  const postFix = isExistAiAddOnDiscount ? l.translate('/first month') : l.translate('/mo')
  const { prices } = useDefaultContext()

  return (
    <li
      className={cx('text-left panel panel-white p-y-md p-x-md', {
        [cm.productCard]: true,
        [cm.addOn]: type === 'addOn',
        [cm.disabled]: disabled,
      })}
    >
      <section>
        <header className="d-flex flex-row align-center">
          <h3 className="m-y-0 text-heading-1">{name}</h3>
          {isAi ? <Icon.AiFilled size={24} className="m-l-xs" /> : null}
        </header>

        {Description ? (
          <div className="m-t-sm">
            <Description
              showBenefits={showBenefits}
              showMore={showMore}
              onClickMore={onClickMore}
              currency={currency}
              prices={prices}
            />
          </div>
        ) : null}
      </section>

      <div className={cx(cm.priceContainer, { [cm.aiDiscountPromo]: isExistAiAddOnDiscount })}>
        {dynamicPrice ? (
          <span className="text-secondary m-b-xxs">{l.translate('Starts at')}</span>
        ) : null}

        <div className="d-inline-block">
          <div
            className={cx('d-inline-flex', {
              [cm.priceValue]: isExistAiAddOnDiscount,
              'align-center': isExistAiAddOnDiscount,
            })}
          >
            {isExistAiAddOnDiscount && (
              <s className="text-heading-1 md-m-r-sm">{l.currency(fullPrice, currency, 0)}</s>
            )}
            <b className="text-heading-1">{l.currency(price, currency, 0)}</b>
          </div>

          <span className="text-secondary">{postFix}</span>
        </div>
      </div>

      {isAi && showBenefits && (
        <div className={cm.footer}>
          <Benefits
            className={cx('m-y-xs', cm.benefits)}
            benefits={[
              {
                icon: 'AiIntention',
                description: l.translate(
                  'Enhance response accuracy with Manychat AI — smartly detecting DM intents for optimal replies',
                ),
              },
              {
                icon: 'AiStep',
                description: l.translate(
                  'Let AI Step streamline your conversations, boost sales, and re-engage unresponsive leads',
                ),
              },
              {
                icon: 'MagicWand',
                description: l.translate(
                  'Struggle with writing? Let our AI Text Improver craft, refine, and style your messages effortlessly',
                ),
              },
              {
                icon: 'Flow',
                description: l.translate(
                  'Bypass complex setups and seamlessly automate flows by simply talking to AI Flow Builder',
                ),
              },
            ]}
          />
        </div>
      )}

      {withCheckbox && (
        <BtnV2
          data-product-slug={slug}
          data-product-type={type}
          className={cm.ctaBtn}
          variant={isInCart ? 'default' : 'primary'}
          onClick={onClick}
          leftIcon={isInCart ? 'Close' : 'Plus'}
          data-test-id={`${type}-${slug}-btn`}
        >
          {isInCart ? l.translate('Remove') : l.translate('Add')}
        </BtnV2>
      )}
    </li>
  )
}

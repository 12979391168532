import { l } from '@manychat/manyui'

import { IErrorResponse } from 'common/core/interfaces/errorResponse'
import { RequestErrorReason, ResponseType } from 'utils/api/types'
import { translateResponse } from 'utils/backendErrors'

interface RequestErrorParams {
  reason: RequestErrorReason
  data?: string | IErrorResponse
  url?: string
  responseType?: ResponseType
}

export class RequestError extends Error {
  reason: RequestErrorReason
  uiTestsIgnore: true
  endpoint: string | null
  response?: IErrorResponse
  online: boolean

  constructor({ reason, data, url }: RequestErrorParams) {
    const response = data && typeof data === 'object' ? data : undefined
    response && translateResponse(response)

    const message = typeof data === 'string' ? data : undefined
    super(getReasonMessage(reason, message, response))

    this.name = 'RequestError'
    this.reason = reason
    this.endpoint = url ? getEndpoint(url) : null
    this.response = response
    this.uiTestsIgnore = true
    this.online = Boolean(navigator?.onLine) && reason !== 'network-failure'
  }
}

const getReasonMessage = (
  reason: RequestErrorReason,
  message?: string,
  response?: IErrorResponse,
) => {
  switch (reason) {
    case 'network-failure':
      return l.translate('Network connectivity lost')
    case 'server':
    case 'redirect':
    case 'response-type':
      return l.translate('Something went wrong on the server. Please try again.')
    case 'business-logic':
      if (Array.isArray(response?.errors)) return response?.errors.join(', ')

      return response?.error || l.translate('Server responded with an error for the request')
    default:
      return reason
  }
}

export const isRequestError = (error: unknown): error is RequestError =>
  error instanceof RequestError

export const getEndpoint = (url: string): string => {
  try {
    return new URL(url).pathname.replace(/\/api|\/fb\d+/gi, '')
  } catch (e) {
    return url.replace(/^\/fb\d+/, '').split('?')[0]
  }
}

import invert from 'lodash/invert'
import { IconComponent, l, Icon } from '@manychat/manyui'

import { IntegrationType } from 'common/actions/integrations/common/constants/IntegrationType'
export const NotifyAdminsMaxTextLength = 640
export const NotifyAdminsWithSMSMaxTextLength = 480

export enum NotifyAdminsSendChannels {
  MESSENGER = 'messenger',
  EMAIL = 'email',
  SMS = 'sms',
  TELEGRAM = 'telegram',
  TIKTOK = 'tiktok',
}

export const NotifyAdminsChannelsNames: Record<NotifyAdminsSendChannels, string> = {
  [NotifyAdminsSendChannels.MESSENGER]: 'Messenger',
  [NotifyAdminsSendChannels.EMAIL]: 'E-mail',
  [NotifyAdminsSendChannels.SMS]: 'SMS',
  [NotifyAdminsSendChannels.TELEGRAM]: 'Telegram',
  [NotifyAdminsSendChannels.TIKTOK]: 'TikTok',
}

export enum ActionTypes {
  ADD_TAG = 'add_tag',
  FIRE_CUSTOM_EVENT = 'fire_custom_event',
  DELETE_SUBSCRIBER = 'delete_subscriber',
  // This action was created for Meta and right now we do not show it for users,
  // but we are not ready to get rid of it, because it can be useful in the
  // future.
  SEND_LEAD_SUBMITTED_EVENT = 'send_lead_submitted_event',
  REMOVE_TAG = 'remove_tag',
  TRIGGER_ZAP = 'trigger_a_zap',
  SUBSCRIBE = 'add_to_sequence',
  UNSUBSCRIBE = 'remove_from_sequence',
  OPEN_CONVERSATION = 'open_conversation',
  CLOSE_CONVERSATION = 'close_conversation',
  ASSIGN_CONVERSATION = 'assign_conversation',
  NOTIFY_ADMINS = 'notify_admin',
  START_FLOW = 'start_flow',
  SET_CUF = 'set_custom_field_value',
  UNSET_CUF = 'unset_custom_field_value',
  SET_GAF = 'change_global_field_value',
  SET_FB_OPTIN = 'subscribe_to_account',
  SET_FB_OPTOUT = 'unsubscribe_from_account',
  GLOBAL_UNSUBSCRIBE = 'global_unsubscribe_from_account',
  EXTERNAL_REQUEST = 'external_request',
  GOOGLE_SHEETS_INTEGRATION = 'google_sheets',
  ACTIVE_CAMPAIGN_INTEGRATION = 'active_campaign',
  KLAVIYO = 'klaviyo',
  MAILCHIMP_INTEGRATION = 'mailchimp',
  HUBSPOT_INTEGRATION = 'hubspot',
  SALESFORCE_INTEGRATION = 'salesforce',
  CONVERTKIT_INTEGRATION = 'convertkit',
  CHATGPT_INTEGRATION = 'chatgpt',
  CLAUDE_INTEGRATION = 'claude',
  DEEPSEEK_INTEGRATION = 'deepseek',
  TRIGGER_INTEGROMAT = 'trigger_integromat',
  FLODESK = 'flodesk',
  CUSTOM_AUDIENCE_USER = 'custom_audience_user',
  SET_SMS_OPTIN = 'set_sms_optin',
  SET_EMAIL_OPTIN = 'set_email_optin',
  SET_SMS_OPTOUT = 'set_sms_optout',
  SET_EMAIL_OPTOUT = 'set_email_optout',
  APPS_REQUEST = 'apps_request',
  SET_MAIN_MENU = 'set_user_level_menu',
  SET_WA_OPTOUT = 'set_whatsapp_optout',
  SET_IG_OPTIN = 'set_instagram_optin',
  SET_IG_OPTOUT = 'set_instagram_optout',
  SET_TG_OPTIN = 'set_telegram_optin',
  SET_TG_OPTOUT = 'set_telegram_optout',
  SET_TT_OPTIN = 'set_tiktok_optin',
  SET_TT_OPTOUT = 'set_tiktok_optout',
  PAUSE_AUTOMATION_FOREVER = 'pause_automation_forever',
  RESUME_AUTOMATION = 'resume_automation',
  SEND_EVENT_TO_CAPI = 'send_event_to_capi',
}

export const ActionTypeNames: Record<string, () => string> = {
  [ActionTypes.ADD_TAG]: l.makeTranslate('Add Tag'),
  [ActionTypes.FIRE_CUSTOM_EVENT]: l.makeTranslate('Log Conversion Event'),
  [ActionTypes.REMOVE_TAG]: l.makeTranslate('Remove Tag'),
  [ActionTypes.TRIGGER_ZAP]: l.makeTranslate('Trigger Zap'),
  [ActionTypes.SUBSCRIBE]: l.makeTranslate('Subscribe to Sequence'),
  [ActionTypes.UNSUBSCRIBE]: l.makeTranslate('Unsubscribe from Sequence'),
  [ActionTypes.OPEN_CONVERSATION]: l.makeTranslate('Mark conversation as Open'),
  [ActionTypes.CLOSE_CONVERSATION]: l.makeTranslate('Mark conversation as Closed'),
  [ActionTypes.ASSIGN_CONVERSATION]: l.makeTranslate('Assign conversation'),
  [ActionTypes.NOTIFY_ADMINS]: l.makeTranslate('Notify Assignees'),
  [ActionTypes.START_FLOW]: l.makeTranslate('Start Automation'),
  [ActionTypes.SET_CUF]: l.makeTranslate('Set User Field'),
  [ActionTypes.UNSET_CUF]: l.makeTranslate('Clear User Field'),
  [ActionTypes.SET_GAF]: l.makeTranslate('Set Bot Field'),
  [ActionTypes.SET_FB_OPTIN]: l.makeTranslate('Set Messenger Opt-In'),
  [ActionTypes.SET_FB_OPTOUT]: l.makeTranslate('Set Messenger Opt-Out'),
  [ActionTypes.GLOBAL_UNSUBSCRIBE]: l.makeTranslate('Unsubscribe from Account'),
  [ActionTypes.EXTERNAL_REQUEST]: l.makeTranslate('External Request'),
  [ActionTypes.HUBSPOT_INTEGRATION]: l.makeTranslate('HubSpot Actions'),
  [ActionTypes.SALESFORCE_INTEGRATION]: l.makeTranslate('Salesforce Actions'),
  [ActionTypes.CONVERTKIT_INTEGRATION]: l.makeTranslate('ConvertKit Actions'),
  [ActionTypes.CHATGPT_INTEGRATION]: l.makeTranslate('ChatGPT Actions'),
  [ActionTypes.CLAUDE_INTEGRATION]: l.makeTranslate('Claude Actions'),
  [ActionTypes.DEEPSEEK_INTEGRATION]: l.makeTranslate('DeepSeek Actions'),
  [ActionTypes.TRIGGER_INTEGROMAT]: l.makeTranslate('Trigger Make'),
  [ActionTypes.GOOGLE_SHEETS_INTEGRATION]: l.makeTranslate('Google Sheets Actions'),
  [ActionTypes.ACTIVE_CAMPAIGN_INTEGRATION]: l.makeTranslate('ActiveCampaign Actions'),
  [ActionTypes.KLAVIYO]: l.makeTranslate('Klaviyo Actions'),
  [ActionTypes.FLODESK]: l.makeTranslate('Flodesk Actions'),
  [ActionTypes.MAILCHIMP_INTEGRATION]: l.makeTranslate('MailChimp Actions'),
  [ActionTypes.CUSTOM_AUDIENCE_USER]: l.makeTranslate('Facebook Custom Audience'),
  [ActionTypes.SET_SMS_OPTIN]: l.makeTranslate('Set SMS Opt-In'),
  [ActionTypes.SET_EMAIL_OPTIN]: l.makeTranslate('Set Email Opt-In'),
  [ActionTypes.SET_SMS_OPTOUT]: l.makeTranslate('Set SMS Opt-Out'),
  [ActionTypes.SET_EMAIL_OPTOUT]: l.makeTranslate('Set Email Opt-Out'),
  [ActionTypes.SET_WA_OPTOUT]: l.makeTranslate('Set WhatsApp Opt-Out'),
  [ActionTypes.SET_IG_OPTIN]: l.makeTranslate('Set Instagram Opt-In'),
  [ActionTypes.SET_IG_OPTOUT]: l.makeTranslate('Set Instagram Opt-Out'),
  [ActionTypes.SET_TG_OPTIN]: l.makeTranslate('Set Telegram Opt-In'),
  [ActionTypes.SET_TG_OPTOUT]: l.makeTranslate('Set Telegram Opt-Out'),
  [ActionTypes.SET_TT_OPTIN]: l.makeTranslate('Set TikTok Opt-In'),
  [ActionTypes.SET_TT_OPTOUT]: l.makeTranslate('Set TikTok Opt-Out'),
  [ActionTypes.SET_MAIN_MENU]: l.makeTranslate('Change Menu'),
  [ActionTypes.SEND_LEAD_SUBMITTED_EVENT]: l.makeTranslate('Lead Submitted Event'),
  [ActionTypes.DELETE_SUBSCRIBER]: l.makeTranslate('Delete Contact'),
  [ActionTypes.PAUSE_AUTOMATION_FOREVER]: l.makeTranslate('Pause Automations Forever'),
  [ActionTypes.RESUME_AUTOMATION]: l.makeTranslate('Resume Automations'),
  [ActionTypes.SEND_EVENT_TO_CAPI]: l.makeTranslate('Send event to Meta Conversions API'),
}

export const ActionTypeIcons: Record<ActionTypes, IconComponent> = {
  [ActionTypes.SET_FB_OPTOUT]: Icon.BotRemove,
  [ActionTypes.EXTERNAL_REQUEST]: Icon.CloudUpload,
  [ActionTypes.GLOBAL_UNSUBSCRIBE]: Icon.BotRemove,
  [ActionTypes.SET_FB_OPTIN]: Icon.TriggerNewSubscriber,
  [ActionTypes.ADD_TAG]: Icon.AddTag,
  [ActionTypes.UNSET_CUF]: Icon.CustomFieldRemove,
  [ActionTypes.SET_CUF]: Icon.CustomField,
  [ActionTypes.NOTIFY_ADMINS]: Icon.Notification,
  [ActionTypes.OPEN_CONVERSATION]: Icon.OrphanOpen,
  [ActionTypes.SUBSCRIBE]: Icon.SequencesAdd,
  [ActionTypes.UNSUBSCRIBE]: Icon.SequencesRemove,
  [ActionTypes.TRIGGER_ZAP]: Icon.OrphanTriggerZap,
  [ActionTypes.REMOVE_TAG]: Icon.DeleteTag,
  [ActionTypes.FIRE_CUSTOM_EVENT]: Icon.Analytics,
  [ActionTypes.HUBSPOT_INTEGRATION]: Icon.OrphanIntegration,
  [ActionTypes.SALESFORCE_INTEGRATION]: Icon.OrphanIntegration,
  [ActionTypes.CONVERTKIT_INTEGRATION]: Icon.OrphanIntegration,
  [ActionTypes.CHATGPT_INTEGRATION]: Icon.OrphanIntegration,
  [ActionTypes.CLAUDE_INTEGRATION]: Icon.Claude,
  [ActionTypes.DEEPSEEK_INTEGRATION]: Icon.DeepSeek,
  [ActionTypes.GOOGLE_SHEETS_INTEGRATION]: Icon.OrphanIntegration,
  [ActionTypes.ACTIVE_CAMPAIGN_INTEGRATION]: Icon.OrphanIntegration,
  [ActionTypes.KLAVIYO]: Icon.OrphanIntegration,
  [ActionTypes.MAILCHIMP_INTEGRATION]: Icon.OrphanIntegration,
  [ActionTypes.FLODESK]: Icon.OrphanIntegration,
  [ActionTypes.CLOSE_CONVERSATION]: Icon.ActionMarkConversationAsDone,
  [ActionTypes.ASSIGN_CONVERSATION]: Icon.SequencesAdd,
  [ActionTypes.START_FLOW]: Icon.Send2,
  [ActionTypes.SET_GAF]: Icon.CustomField,
  [ActionTypes.TRIGGER_INTEGROMAT]: Icon.OrphanIntegromat,
  [ActionTypes.CUSTOM_AUDIENCE_USER]: Icon.UserAdd,
  [ActionTypes.SET_SMS_OPTIN]: Icon.TriggerNewSubscriber,
  [ActionTypes.SET_EMAIL_OPTIN]: Icon.TriggerNewSubscriber,
  [ActionTypes.SET_SMS_OPTOUT]: Icon.BotRemove,
  [ActionTypes.SET_EMAIL_OPTOUT]: Icon.BotRemove,
  [ActionTypes.SET_WA_OPTOUT]: Icon.BotRemove,
  [ActionTypes.SET_IG_OPTIN]: Icon.TriggerNewSubscriber,
  [ActionTypes.SET_IG_OPTOUT]: Icon.BotRemove,
  [ActionTypes.SET_TG_OPTIN]: Icon.TriggerNewSubscriber,
  [ActionTypes.SET_TG_OPTOUT]: Icon.BotRemove,
  [ActionTypes.SET_TT_OPTIN]: Icon.TriggerNewSubscriber,
  [ActionTypes.SET_TT_OPTOUT]: Icon.BotRemove,
  [ActionTypes.APPS_REQUEST]: Icon.OrphanIntegration,
  [ActionTypes.SET_MAIN_MENU]: Icon.CustomField,
  [ActionTypes.SEND_LEAD_SUBMITTED_EVENT]: Icon.Analytics,
  [ActionTypes.DELETE_SUBSCRIBER]: Icon.DeleteForever,
  [ActionTypes.PAUSE_AUTOMATION_FOREVER]: Icon.Stop2,
  [ActionTypes.RESUME_AUTOMATION]: Icon.Play,
  [ActionTypes.SEND_EVENT_TO_CAPI]: Icon.OrphanAdsCampaignTraffic,
}

export const ProActionTypes: ActionTypes[] = [
  ActionTypes.NOTIFY_ADMINS,
  ActionTypes.FIRE_CUSTOM_EVENT,
  ActionTypes.TRIGGER_ZAP,
  ActionTypes.EXTERNAL_REQUEST,
  ActionTypes.HUBSPOT_INTEGRATION,
  ActionTypes.SALESFORCE_INTEGRATION,
  ActionTypes.CONVERTKIT_INTEGRATION,
  ActionTypes.CHATGPT_INTEGRATION,
  ActionTypes.CLAUDE_INTEGRATION,
  ActionTypes.DEEPSEEK_INTEGRATION,
  ActionTypes.TRIGGER_INTEGROMAT,
  ActionTypes.GOOGLE_SHEETS_INTEGRATION,
  ActionTypes.ACTIVE_CAMPAIGN_INTEGRATION,
  ActionTypes.KLAVIYO,
  ActionTypes.MAILCHIMP_INTEGRATION,
  ActionTypes.CUSTOM_AUDIENCE_USER,
  ActionTypes.SET_SMS_OPTIN,
  ActionTypes.SET_EMAIL_OPTIN,
  ActionTypes.SET_SMS_OPTOUT,
  ActionTypes.SET_EMAIL_OPTOUT,
  ActionTypes.APPS_REQUEST,
  ActionTypes.SET_MAIN_MENU,
  ActionTypes.SET_WA_OPTOUT,
]

export const IntegrationActionTypes: Partial<Record<IntegrationType, string>> = {
  [ActionTypes.HUBSPOT_INTEGRATION]: 'hubspot',
  [ActionTypes.CONVERTKIT_INTEGRATION]: 'convertkit',
  [ActionTypes.CHATGPT_INTEGRATION]: 'chatgpt',
  [ActionTypes.CLAUDE_INTEGRATION]: 'claude',
  [ActionTypes.DEEPSEEK_INTEGRATION]: 'deepseek',
  [ActionTypes.GOOGLE_SHEETS_INTEGRATION]: 'google_sheets',
  [ActionTypes.ACTIVE_CAMPAIGN_INTEGRATION]: 'active_campaign',
  [ActionTypes.KLAVIYO]: 'klaviyo',
  [ActionTypes.MAILCHIMP_INTEGRATION]: 'mailchimp',
  [ActionTypes.SALESFORCE_INTEGRATION]: 'salesforce',
  [ActionTypes.FLODESK]: 'flodesk',
}

export const IntegrationActionTypeMap: Record<string, string> = invert(IntegrationActionTypes)

export const INTEGRATION_REQUEST = 'integration_request'

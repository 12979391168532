import isEqual from 'lodash/isEqual'
import pick from 'lodash/pick'
import { l } from '@manychat/manyui'

import { NodeType } from 'common/builder/constants/NodeType'
import BaseNodeConfig from 'common/builder/nodes/BaseNodeConfig'
import { INodeBase, ISmartDelayNode } from 'common/builder/nodes/nodeInterfaces'
import { DelayType } from 'common/builder/nodes/smartDelay/delayTypes'
import { WEEKDAYS } from 'constants/constants'

class SmartDelayNodeConfig extends BaseNodeConfig<ISmartDelayNode> {
  nodeType = NodeType.SMART_DELAY

  isPro = true

  getCaption = () => l.translate('Smart Delay')
  getCaptionWithCount = (n: number) =>
    l.translate('{n, plural, one {# Smart Delay} other {# Smart Delays}}', { n })
  icon = 'Time'
  color = '#EB5038'
  headerColor = '#ffb8aa'

  getDefaults = (): Omit<ISmartDelayNode, keyof INodeBase> => ({
    targetId: null,

    delayValue: 23,
    delayUnits: 'hours',

    delayType: DelayType.DURATION,
    waitUntil: {
      value: '',
      is_formula: false,
    },

    useTimeWindow: false,
    sendingInterval: [8, 22],
    weekdays: WEEKDAYS,
  })

  isEmpty = ({ node }: { node: ISmartDelayNode }) => {
    const defaults = this.getDefaults()
    const ATTRS = ['delayValue', 'delayUnits', 'useTimeWindow', 'sendingInterval', 'weekdays']
    return isEqual(pick(node, ...ATTRS), pick(defaults, ...ATTRS))
  }
}

export default new SmartDelayNodeConfig()

import React, { useCallback, useState, useEffect } from 'react'
import cx from 'classnames'
import { useSearchParams } from 'react-router-dom'

import { isMobile, localStorage } from 'utils'
import { LiveChatSessionStorageIds } from 'apps/chat/constants/LiveChatSessionStorageIds'
import cm from 'common/banners/mobile/MobileBanner.module.css'
import { isAndroid, isApple, isManyChatApp } from 'utils/ismobile'
import { analyticsService } from 'utils/services/analytics'
import { UserFlag } from 'utils/services/featureFlagsService/constants'
import { useUserReleaseToggle } from 'utils/services/featureFlagsService/releaseTogglesHooks'
import * as sessionStorage from 'utils/sessionStorage'

import { SURVEY_LOCAL_STORAGE_ID } from './constants'
import { MainScreen } from './MainScreen'
import { Survey } from './Survey'

const MobileBanner = () => {
  const [query] = useSearchParams()
  const [isBannerVisible, setIsBannerVisible] = useState(true)
  const [isSurveyStarted, setIsSurveyStarted] = useState(false)

  const isBannerForNewUsersEnabled = useUserReleaseToggle(UserFlag.ENABLE_MOBILE_BANNER_EXPERIMENT)
  const isBannerForNewUsersDisabled = useUserReleaseToggle(
    UserFlag.DISABLE_MOBILE_BANNER_EXPERIMENT,
  )
  const isBannerForExistingUsersEnabled =
    !isBannerForNewUsersEnabled && !isBannerForNewUsersDisabled

  const referrer = query.get('referrer')

  React.useEffect(() => {
    if (
      referrer === 'mobile-app' &&
      !sessionStorage.getItem(LiveChatSessionStorageIds.DISABLE_FORCED_APP_BANNER)
    ) {
      sessionStorage.setItem(LiveChatSessionStorageIds.DISABLE_FORCED_APP_BANNER, 'true')
    }
  }, [referrer])

  const isSupportedDevice = isAndroid || isApple
  const isMobileReferrer =
    referrer === 'mobile-app' ||
    sessionStorage.getItem(LiveChatSessionStorageIds.DISABLE_FORCED_APP_BANNER)
  const wasSurveyShown = localStorage.getItem(SURVEY_LOCAL_STORAGE_ID) === 'true'
  const shouldShowBanner =
    isBannerVisible &&
    isMobile &&
    isSupportedDevice &&
    !isManyChatApp &&
    !isBannerForNewUsersDisabled &&
    !isMobileReferrer &&
    !wasSurveyShown

  const closeBanner = useCallback(() => {
    setIsBannerVisible(false)
  }, [])

  const handleCloseIconClick = useCallback(() => {
    analyticsService.sendEvent('MOBILE_BANNER.SURVEY.CLOSE_ICON_CLICK')

    const wasSurveyShown = localStorage.getItem(SURVEY_LOCAL_STORAGE_ID) === 'true'
    if (wasSurveyShown) closeBanner()
    else setIsSurveyStarted(true)
  }, [closeBanner])

  useEffect(() => {
    if (shouldShowBanner) {
      analyticsService.sendEvent('MOBILE_BANNER_SHOW', {
        novemberExperiment: true,
        newUser: isBannerForNewUsersEnabled,
      })
    }
  }, [isBannerForNewUsersEnabled, shouldShowBanner])

  if (!shouldShowBanner) return null

  return (
    <div className={cx(cm.banner, 'text-center')}>
      <div className={cx(cm.bannerContainer)}>
        {isSurveyStarted ? (
          <Survey closeBanner={closeBanner} />
        ) : (
          <MainScreen onClose={handleCloseIconClick} canClose={isBannerForExistingUsersEnabled} />
        )}
      </div>
    </div>
  )
}

export default MobileBanner

import React, { useMemo, useCallback } from 'react'

import ChannelPicker from 'common/core/components/ChannelPicker/Dropdown'
import { ChannelType } from 'common/core/constants/ChannelType'
import { useConnectedChannelsList } from 'common/core/hooks/useChannelsList'
import { FlowChannel } from 'common/flow/interfaces/channels'

import { ConvertableChannels } from '../../lib/convertableChannels'

interface TargetChannelPickerProps {
  sourceChannel: FlowChannel
  targetChannel: ChannelType | undefined
  onSelect: (sourceChannel: FlowChannel, targetChannel: FlowChannel) => void
}

export const TargetChannelPicker = ({
  sourceChannel,
  targetChannel,
  onSelect,
}: TargetChannelPickerProps) => {
  const connectedChannelsList = useConnectedChannelsList()

  const options = useMemo(() => {
    const connectedChannels = connectedChannelsList.map((item) => item.channel)

    return ConvertableChannels.filter(
      (item) => connectedChannels.includes(item.value) && item.value !== sourceChannel,
    )
  }, [connectedChannelsList, sourceChannel])

  const handleChannelChange = useCallback(
    (convertToChannel: FlowChannel) => {
      onSelect(sourceChannel, convertToChannel)
    },
    [onSelect, sourceChannel],
  )

  return (
    <ChannelPicker
      value={targetChannel ?? ''}
      onChange={handleChannelChange}
      channelsProp={options}
      withTitle
    />
  )
}

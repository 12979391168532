import merge from 'lodash/merge'
import omit from 'lodash/omit'
import orderBy from 'lodash/orderBy'

import { FsFolderOrderField, FsQueryOrderType } from 'apps/cms/lib/constants'
import { getPathItems } from 'apps/cms/lib/query'
import { SocketFsFlow, SocketFsFlowDeleted } from 'apps/cms/store/lib/types'
import { FsFolderObject, FsFlow } from 'shared/api/requests/cms/schemas'

export const getFoldersByPath = (foldersList: FsFolderObject[], path: string) => {
  if (path === '/') {
    return foldersList.filter((folder) => {
      return folder.path.split('/').length === 2
    })
  }

  return foldersList.filter((folder) => {
    return (
      folder.path.includes(path) && folder.path.split('/').length === path.split('/').length + 1
    )
  })
}

export const orderFolderByParam = (list: FsFolderObject[], field: FsFolderOrderField) => {
  const getOrderParam = (item: FsFolderObject) => {
    const orderParam = item[field]
    if (typeof orderParam === 'string') {
      return orderParam.toLowerCase().trim()
    }
    return orderParam
  }

  return orderBy(list, [getOrderParam], [FsQueryOrderType.ASC])
}

export const mapSocketFsFlow = (flow: SocketFsFlow): FsFlow =>
  merge(flow, {
    stats: {
      sent: null,
      clicked_unq: null,
      sent_unq: null,
    },
  })

export const mapSocketFsFlowDeleted = (flow: SocketFsFlowDeleted): FsFlow =>
  merge(omit(flow, ['source_ns']), {
    ns: flow.source_ns,
    stats: {
      sent: null,
      clicked_unq: null,
      sent_unq: null,
    },
  })

export const isFlowInPath = ({ flowPath, path }: { flowPath: string; path: string }) => {
  const flowPathWithoutNs = getPathItems(flowPath)
    // Flow's path last items is always flow's namespace
    .filter((_, index, { length }) => index !== length - 1)
    .join('/')

  return `/${flowPathWithoutNs}` === path
}

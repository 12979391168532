import invert from 'lodash/invert'
import mapValues from 'lodash/mapValues'
import { l } from '@manychat/manyui'

export enum UserRoles {
  ADMIN = 'administrator',
  EDITOR = 'editor',
  AGENT = 'livechat_agent',
  VIEWER = 'viewer',
  // custom role for settings layout, the value doesn't come from backend. Same as Admin
  OWNER = 'owner',
}

export const TEAM_MEMBER_LIMIT = 1
export const TEAM_MEMBER_LIMIT_AGENCY_DRIVEN = 2

export const UserRolesNamesPlural: Record<UserRoles, (n: number) => string> = {
  [UserRoles.ADMIN]: (n) =>
    l.translate(
      '<l10n context="role name capitalize (Just two forms here, by design)"/>{n, plural, one {Admin} other {Admins}}',
      { n },
    ),
  [UserRoles.EDITOR]: (n) =>
    l.translate(
      '<l10n context="role name capitalize (Just two forms here, by design)"/>{n, plural, one {Editor} other {Editors}}',
      { n },
    ),
  [UserRoles.AGENT]: (n) =>
    l.translate(
      '<l10n context="role name capitalize (Just two forms here, by design)"/>{n, plural, one {Live Chat Agent} other {Live Chat Agents}}',
      { n },
    ),
  [UserRoles.VIEWER]: (n) =>
    l.translate(
      '<l10n context="role name capitalize (Just two forms here, by design)"/>{n, plural, one {Viewer} other {Viewers}}',
      { n },
    ),
  [UserRoles.OWNER]: (n) =>
    l.translate(
      '<l10n context="role name capitalize (Just two forms here, by design)"/>{n, plural, one {Owner} other {Owners}}',
      { n },
    ),
}

export const UserRolesNames: Record<UserRoles, () => string> = {
  [UserRoles.ADMIN]: () => UserRolesNamesPlural[UserRoles.ADMIN](1),
  [UserRoles.EDITOR]: () => UserRolesNamesPlural[UserRoles.EDITOR](1),
  [UserRoles.AGENT]: () => UserRolesNamesPlural[UserRoles.AGENT](1),
  [UserRoles.VIEWER]: () => UserRolesNamesPlural[UserRoles.VIEWER](1),
  [UserRoles.OWNER]: () => UserRolesNamesPlural[UserRoles.OWNER](1),
}

export const UserRolesDescriptions: Record<UserRoles, () => string> = {
  [UserRoles.ADMIN]: l.makeTranslate(
    `Admins control contact roles management except for the Owner role. They can also disable and clone the bot, share its contents, create and install templates, manage billing and payments.`,
  ),
  [UserRoles.EDITOR]:
    l.makeTranslate(`Editor role is the second most important role in bot management.
              Editors can create and manage bot content (including template installation),
              but they do not have enough permissions for disabling the bot,
              cloning or sharing the content, and creating templates.`),
  [UserRoles.AGENT]: l.makeTranslate(`This role is used to communicate with
              bot subscribed contacts and grants all the required
              permissions to manage conversations inside Live
              Chat. Live Chat Agents can assign existing Tags
              and manage Custom User Fields values. They are
              not allowed to create or edit bot content but can
              view all the existing Automations.`),
  [UserRoles.VIEWER]: l.makeTranslate(`This role allows team members to track bot stats
              and view sent Automations data in “view only” mode. Viewers
              are not allowed to create or edit bot content.`),
  [UserRoles.OWNER]: l.makeTranslate(
    'Owner controls contact roles management. Owner can also disable and clone the bot, share its contents, create and install templates, manage billing and payments. There is only one owner role per account.',
  ),
}

export enum PermissionTargets {
  ACCOUNT_EDITING = 'ACCOUNT_EDITING',
  APPLICATIONS_SETTINGS = 'APPLICATIONS_SETTINGS',
  AUDIENCE_BULK_ACTIONS = 'AUDIENCE_BULK_ACTIONS',
  AUTOMATION_INTERACTIONS = 'AUTOMATION_INTERACTIONS',
  BROADCASTING = 'BROADCASTING',
  CAN_CATALOG_EDIT_ITEM = 'CAN_CATALOG_EDIT_ITEM',
  CAN_CONNCET_SMS_CHANNEL = 'CAN_CONNCET_SMS_CHANNEL',
  CAN_EDIT_EMAIL_CHANNEL = 'CAN_EDIT_EMAIL_CHANNEL',
  CAN_ENABLE_SMS_CHANNEL = 'CAN_ENABLE_SMS_CHANNEL',
  CAN_FETCH_WALLET = 'CAN_FETCH_WALLET',
  CREATE_NEW_CONTACT = 'CREATE_NEW_CONTACT',
  CUSTOM_EVENTS_INTERACTIONS = 'CUSTOM_EVENTS_INTERACTIONS',
  EDIT_BUSINESS_EMAILS = 'EDIT_BUSINESS_EMAILS',
  FACEBOOK_ADS_CONNECT_OR_CREATE_ACCOUNT = 'FACEBOOK_ADS_CONNECT_OR_CREATE_ACCOUNT',
  FACEBOOK_ADS_CREATE_AUDIENCE = 'FACEBOOK_ADS_CREATE_AUDIENCE',
  FACEBOOK_ADS_EDIT_AD = 'FACEBOOK_ADS_EDIT_AD',
  FACEBOOK_ADS_VIEW_HISTORY = 'FACEBOOK_ADS_VIEW_HISTORY',
  FLOW_EDITING = 'FLOW_EDITING',
  FLOW_SHARING = 'FLOW_SHARING',
  FLOW_USES = 'FLOW_USES',
  GDPR_ACTIONS = 'GDPR_ACTIONS',
  GROWTH_TOOLS_INTERACTIONS = 'GROWTH_TOOLS_INTERACTIONS',
  JOB_QUESTIONNAIRE_VIEW = 'JOB_QUESTIONNAIRE_VIEW',
  KEYWORDS_MIGRATION = 'KEYWORDS_MIGRATION',
  LIVE_CHAT_BULK_ACTIONS = 'LIVE_CHAT_BULK_ACTIONS',
  LIVE_CHAT_INTERACTIONS = 'LIVE_CHAT_INTERACTIONS',
  LOGS = 'LOGS',
  MODIFY_LIVE_CHAT_SNIPPETS = 'MODIFY_LIVE_CHAT_SNIPPETS',
  SETTINGS_ADMINS_DELETE_MYSELF = 'SETTINGS_ADMINS_DELETE_MYSELF',
  SETTINGS_ADMINS_EDITING = 'SETTINGS_ADMINS_EDITING',
  SETTINGS_BILLING = 'SETTINGS_BILLING',
  SETTINGS_CUSTOM_FIELDS_INTERACTIONS = 'SETTINGS_CUSTOM_FIELDS_INTERACTIONS',
  SETTINGS_CUSTOM_FIELDS_SELECT_EXISTING = 'SETTINGS_CUSTOM_FIELDS_SELECT_EXISTING',
  SETTINGS_CUSTOM_FIELD_FOLDERS_INTERACTIONS = 'SETTINGS_CUSTOM_FIELD_FOLDERS_INTERACTIONS',
  SETTINGS_DELETE_USER = 'SETTINGS_DELETE_USER',
  SETTINGS_GENERAL_CLONE = 'SETTINGS_GENERAL_CLONE',
  SETTINGS_GENERAL_DISABLE_BOT = 'SETTINGS_GENERAL_DISABLE_BOT',
  SETTINGS_GENERAL_GREETING_TEXT = 'SETTINGS_GENERAL_GREETING_TEXT',
  SETTINGS_GENERAL_LEAVE_BOT = 'SETTINGS_GENERAL_LEAVE_BOT',
  SETTINGS_GENERAL_URL_SHORTENER = 'SETTINGS_GENERAL_URL_SHORTENER',
  SETTINGS_GROWTH_TOOLS = 'SETTINGS_GROWTH_TOOLS',
  SETTINGS_INSTAGRAM_CONNECT = 'SETTINGS_INSTAGRAM_CONNECT',
  SETTINGS_TIKTOK_CONNECT = 'SETTINGS_TIKTOK_CONNECT',
  SETTINGS_INTEGRATIONS = 'SETTINGS_INTEGRATIONS',
  SETTINGS_LIVE_CHAT_ASSIGN_TO_SAME_AGENT = 'SETTINGS_LIVE_CHAT_ASSIGN_TO_SAME_AGENT',
  SETTINGS_LIVE_CHAT_AUTO_ASSIGNMENT = 'SETTINGS_LIVE_CHAT_AUTO_ASSIGNMENT',
  SETTINGS_LIVE_CHAT_BEHAVIOR = 'SETTINGS_LIVE_CHAT_BEHAVIOR',
  SETTINGS_LIVE_CHAT_CLOSE_ALL = 'SETTINGS_LIVE_CHAT_CLOSE_ALL',
  SETTINGS_LIVE_CHAT_CONVERSATION_IDENTIFICATION = 'SETTINGS_LIVE_CHAT_CONVERSATION_IDENTIFICATION',
  SETTINGS_LIVE_CHAT_SOUND_NOTIFICATIONS = 'SETTINGS_LIVE_CHAT_SOUND_NOTIFICATIONS',
  SETTINGS_LIVE_CHAT_THREADS_VISIBILITY = 'SETTINGS_LIVE_CHAT_THREADS_VISIBILITY',
  SETTINGS_LIVE_CHAT_AUTOMATION_PAUSE_TIME = 'SETTINGS_LIVE_CHAT_AUTOMATION_PAUSE_TIME',
  SETTINGS_MESSENGER_DISABLE_INPUT = 'SETTINGS_MESSENGER_DISABLE_INPUT',
  SETTINGS_NOTIFY_ADMIN_MIGRATION_BUTTON = 'SETTINGS_NOTIFY_ADMIN_MIGRATION_BUTTON',
  SETTINGS_PAYMENTS_INTERACTIONS = 'SETTINGS_PAYMENTS_INTERACTIONS',
  SETTINGS_PAYMENTS_ORDER_HISTORY = 'SETTINGS_PAYMENTS_ORDER_HISTORY',
  SETTINGS_PUBLIC_API = 'SETTINGS_PUBLIC_API',
  SETTINGS_USERS_SECTION = 'SETTINGS_USERS_SECTION',
  SHOW_LIVE_CHAT_SNIPPETS = 'SHOW_LIVE_CHAT_SNIPPETS',
  SMART_SEGMENTS_INTERACTIONS = 'SMART_SEGMENTS_INTERACTIONS',
  SPONSORED_MESSAGES = 'SPONSORED_MESSAGES',
  SUBSCRIBER_EDITING = 'SUBSCRIBER_EDITING',
  SUBSCRIPTION_PERMISSION = 'SUBSCRIPTION_PERMISSION',
  TAG_EDITING = 'TAG_EDITING',
  TEMPLATE_INSTALL = 'TEMPLATE_INSTALL',
  TEMPLATE_INTERACTIONS = 'TEMPLATE_INTERACTIONS',
  USER_QUEST = 'USER_QUEST',
  AI_INTENTS = 'AI_INTENTS',
  GROW_INSIGHTS = 'GROW_INSIGHTS',
  SETTINGS_TIKTOK_WELCOME_MESAAGE = 'SETTINGS_TIKTOK_WELCOME_MESAAGE',
  TIKTOK_SUGGESTED_QUESTIONS = 'TIKTOK_SUGGESTED_QUESTIONS',
  DASHBOARD_ACCOUNT_QUESTIONNAIRE = 'DASHBOARD_ACCOUNT_QUESTIONNAIRE',
  EASY_BUILDER_USAGE = 'EASY_BUILDER_USAGE',
}

export type PermissionsMap = Record<PermissionTargets, boolean>

const defaultPermissions = mapValues(invert(PermissionTargets), () => false) as PermissionsMap

type UserRolesPermissions = Record<UserRoles, PermissionsMap>

export const UserRolesPermissions: UserRolesPermissions = {
  [UserRoles.ADMIN]: mapValues(defaultPermissions, () => true),
  [UserRoles.EDITOR]: {
    ...defaultPermissions,
    [PermissionTargets.APPLICATIONS_SETTINGS]: true,
    [PermissionTargets.AUDIENCE_BULK_ACTIONS]: true,
    [PermissionTargets.AUTOMATION_INTERACTIONS]: true,
    [PermissionTargets.BROADCASTING]: true,
    [PermissionTargets.CAN_FETCH_WALLET]: true,
    [PermissionTargets.CREATE_NEW_CONTACT]: true,
    [PermissionTargets.CUSTOM_EVENTS_INTERACTIONS]: true,
    [PermissionTargets.FACEBOOK_ADS_CREATE_AUDIENCE]: true,
    [PermissionTargets.FACEBOOK_ADS_EDIT_AD]: true,
    [PermissionTargets.FACEBOOK_ADS_VIEW_HISTORY]: true,
    [PermissionTargets.FLOW_EDITING]: true,
    [PermissionTargets.FLOW_USES]: true,
    [PermissionTargets.GROWTH_TOOLS_INTERACTIONS]: true,
    [PermissionTargets.JOB_QUESTIONNAIRE_VIEW]: true,
    [PermissionTargets.KEYWORDS_MIGRATION]: true,
    [PermissionTargets.LIVE_CHAT_BULK_ACTIONS]: true,
    [PermissionTargets.LIVE_CHAT_INTERACTIONS]: true,
    [PermissionTargets.LOGS]: true,
    [PermissionTargets.MODIFY_LIVE_CHAT_SNIPPETS]: true,
    [PermissionTargets.SETTINGS_BILLING]: true,
    [PermissionTargets.SETTINGS_CUSTOM_FIELD_FOLDERS_INTERACTIONS]: true,
    [PermissionTargets.SETTINGS_CUSTOM_FIELDS_INTERACTIONS]: true,
    [PermissionTargets.SETTINGS_CUSTOM_FIELDS_SELECT_EXISTING]: true,
    [PermissionTargets.SETTINGS_GENERAL_GREETING_TEXT]: true,
    [PermissionTargets.SETTINGS_GENERAL_LEAVE_BOT]: true,
    [PermissionTargets.SETTINGS_GENERAL_URL_SHORTENER]: true,
    [PermissionTargets.SETTINGS_GROWTH_TOOLS]: true,
    [PermissionTargets.SETTINGS_INTEGRATIONS]: true,
    [PermissionTargets.SETTINGS_LIVE_CHAT_BEHAVIOR]: true,
    [PermissionTargets.SETTINGS_LIVE_CHAT_CLOSE_ALL]: true,
    [PermissionTargets.SETTINGS_LIVE_CHAT_SOUND_NOTIFICATIONS]: true,
    [PermissionTargets.SETTINGS_LIVE_CHAT_THREADS_VISIBILITY]: true,
    [PermissionTargets.SETTINGS_MESSENGER_DISABLE_INPUT]: true,
    [PermissionTargets.SETTINGS_NOTIFY_ADMIN_MIGRATION_BUTTON]: true,
    [PermissionTargets.SETTINGS_PUBLIC_API]: true,
    [PermissionTargets.SETTINGS_USERS_SECTION]: true,
    [PermissionTargets.SHOW_LIVE_CHAT_SNIPPETS]: true,
    [PermissionTargets.SMART_SEGMENTS_INTERACTIONS]: true,
    [PermissionTargets.SPONSORED_MESSAGES]: true,
    [PermissionTargets.SUBSCRIBER_EDITING]: true,
    [PermissionTargets.SUBSCRIPTION_PERMISSION]: true,
    [PermissionTargets.TAG_EDITING]: true,
    [PermissionTargets.TEMPLATE_INSTALL]: true,
    [PermissionTargets.USER_QUEST]: true,
    [PermissionTargets.GROW_INSIGHTS]: true,
    [PermissionTargets.SETTINGS_TIKTOK_WELCOME_MESAAGE]: true,
    [PermissionTargets.TIKTOK_SUGGESTED_QUESTIONS]: true,
    [PermissionTargets.DASHBOARD_ACCOUNT_QUESTIONNAIRE]: true,
    [PermissionTargets.EASY_BUILDER_USAGE]: true,
  },
  [UserRoles.AGENT]: {
    ...defaultPermissions,
    [PermissionTargets.SUBSCRIBER_EDITING]: true,
    [PermissionTargets.LIVE_CHAT_INTERACTIONS]: true,
    [PermissionTargets.SETTINGS_LIVE_CHAT_CLOSE_ALL]: true,
    [PermissionTargets.SETTINGS_LIVE_CHAT_SOUND_NOTIFICATIONS]: true,
    [PermissionTargets.SETTINGS_CUSTOM_FIELDS_SELECT_EXISTING]: true,
    [PermissionTargets.SETTINGS_BILLING]: true,
    [PermissionTargets.SHOW_LIVE_CHAT_SNIPPETS]: true,
    [PermissionTargets.MODIFY_LIVE_CHAT_SNIPPETS]: true,
    [PermissionTargets.SETTINGS_GENERAL_LEAVE_BOT]: true,
    [PermissionTargets.FLOW_USES]: true,
    [PermissionTargets.KEYWORDS_MIGRATION]: true,
    [PermissionTargets.SETTINGS_USERS_SECTION]: true,
  },
  [UserRoles.VIEWER]: {
    ...defaultPermissions,
    [PermissionTargets.SETTINGS_BILLING]: true,
    [PermissionTargets.FACEBOOK_ADS_VIEW_HISTORY]: true,
    [PermissionTargets.SETTINGS_GENERAL_LEAVE_BOT]: true,
    [PermissionTargets.SETTINGS_USERS_SECTION]: true,
  },
  [UserRoles.OWNER]: mapValues(defaultPermissions, () => true),
}

export const UserRolesSortOrder: Record<UserRoles, number> = {
  [UserRoles.OWNER]: 5,
  [UserRoles.ADMIN]: 4,
  [UserRoles.AGENT]: 3,
  [UserRoles.EDITOR]: 2,
  [UserRoles.VIEWER]: 1,
}

import { LayoutWithSidebar } from 'utils/router/components/LayoutWithSidebar/LayoutWithSidebar'
import { getHandle } from 'utils/router/utils/getHandle'

export const SequencesRoutes = [
  {
    path: 'sequence/:sequence_id',
    handle: getHandle('sequences'),
    Component: LayoutWithSidebar,
    children: [
      {
        index: true,
        lazy: () => import('./sequenceDetail.page'),
      },
      {
        path: 'message/:sequence_message_id/:mode?/:content_id?',
        lazy: () => import('./sequenceMessageBuilder.page'),
      },
    ],
  },
] as const

import { z } from 'zod'

import { FlowStatus, FlowType } from 'common/flow/flowConstants'

export const lightFlowSchema = z.object({
  name: z.string(),
  ns: z.string(),
  page_id: z.string(),
  root_content_id: z.number().nullable(),

  is_shared: z.boolean(),
  share_hash: z.string().nullable(),
  shared_cloning_enabled: z.boolean().nullable(),

  type: z.nativeEnum(FlowType),
  status: z.nativeEnum(FlowStatus),

  path: z.string().nullable().optional(),

  preview_url: z.string().nullable(),
  preview_actual: z.boolean(),
  player_page_url: z.string().nullable(),
  player_embed_url: z.string().nullable(),

  has_content_stats: z.boolean(),
  has_unpublished_changes: z.boolean(),
  has_published_content: z.boolean(),
  has_whatsapp_channel: z.boolean(),
  has_instagram_channel: z.boolean(),
  has_telegram_channel: z.boolean(),
  has_tiktok_channel: z.boolean(),
  has_sms_channel: z.boolean(),
  has_email_channel: z.boolean(),
  has_fb_channel: z.boolean(),

  quick_campaign: z.string().nullable(),
  quick_campaign_id: z.number().nullable(),
  quick_campaign_data: z.object({
    lang_parent_id: z.number().nullable(),
    quick_campaign_id: z.number().nullable(),
  }),

  is_editable: z.boolean(),
  is_moveable: z.boolean(),
  is_deletable: z.boolean(),
  is_restorable: z.boolean(),
  is_exportable: z.boolean(),
  is_shareable: z.boolean(),
  is_permanently_deletable: z.boolean(),
  is_readonly: z.boolean(),

  template_installation_id: z.number().nullable().optional(),
  template_installation_access_data: z
    .object({
      has_user_access: z.boolean().optional(),
      is_locked: z.boolean(),
      is_protected: z.boolean(),
      is_disabled: z.boolean(),
      is_owner: z.boolean(),
    })
    .optional(),
})

export type LightFlow = z.infer<typeof lightFlowSchema>

export interface MappedLightFlow extends LightFlow {
  id: string
  contentId: number | null
  hasDraft: boolean
}

import { l } from '@manychat/manyui'

import { IBlock } from 'common/builder/blocks/blockInterfaces'
import { BlockType } from 'common/builder/constants/BlockType'
import { NodeType } from 'common/builder/constants/NodeType'
import BaseNodeConfig from 'common/builder/nodes/BaseNodeConfig'
import { IConditionNode, INodeBase } from 'common/builder/nodes/nodeInterfaces'

class ConditionNodeConfig extends BaseNodeConfig<IConditionNode> {
  nodeType = NodeType.CONDITION

  isPro = true

  getCaption = () => l.translate('Condition')
  getCaptionWithCount = (n: number) =>
    l.translate('{n, plural, one {# Condition} other {# Conditions}}', { n })
  icon = 'Condition'
  color = '#00BCEB'
  headerColor = '#9defe1'

  ALLOWED_BLOCK_TYPES = [BlockType.CASE, BlockType.DEFAULT]

  ALLOWED_BLOCK_MAX_COUNT = 11

  getDefaults = (): Omit<IConditionNode, keyof INodeBase> => ({
    onboardingElementId: 'condition_node',
  })

  getInitialBlocks = () => {
    return [{ type: BlockType.CASE as const }, { type: BlockType.DEFAULT as const }]
  }

  isEmpty = ({ blocks }: { node: IConditionNode; blocks: IBlock[] }) => {
    const hasTarget = Boolean(blocks.find((b) => 'targetId' in b && b.targetId))
    return !hasTarget
  }
}

export default new ConditionNodeConfig()

import { LayoutWithSidebar } from 'utils/router/components/LayoutWithSidebar/LayoutWithSidebar'
import { getHandle } from 'utils/router/utils/getHandle'

export const GrowToolsWidgetRoutes = [
  {
    path: 'growth-tools/widget',
    handle: getHandle('growth_tools'),
    Component: LayoutWithSidebar,
    children: [
      {
        path: ':widget_id',
        children: [
          {
            index: true,
            lazy: () => import('./widgetBuilder.page'),
          },
          {
            path: 'check',
            lazy: () => import('./widgetCheck.page'),
          },
          {
            path: 'flow/:mode?/:content_id?',
            lazy: () => import('./widgetDetail.page'),
          },
        ],
      },
    ],
  },
] as const

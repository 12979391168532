import { ChannelConfig } from 'common/core/constants/ChannelConfig'
import { ChannelType } from 'common/core/constants/ChannelType'

export const getConnectedChannels = (state: RootState) => {
  const connectedChannels: ChannelType[] = []

  for (const key in ChannelConfig) {
    const channel = key as ChannelType
    const channelConfig = ChannelConfig[channel]
    if (channelConfig?.isConnectedSelector(state)) {
      connectedChannels.push(channel)
    }
  }

  return connectedChannels
}

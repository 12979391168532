import dot from 'dot-prop-immutable'
import { Reducer } from 'redux'

import { ChannelType } from 'common/core/constants/ChannelType'
import { DefaultReply } from 'shared/api/requests/defaultReply/schemas'

import { DefaultReplyChannels, getInitialDefaultReplyChannelState } from '../defaultReplyConstants'
import {
  DefaultReplyState,
  DefaultReplyChannelState,
  DefaultReplySources,
} from '../defaultReplyInterfaces'
import * as atypes from '../defaultReplyReduxActionTypes'

export const getInitialState = (): DefaultReplyState =>
  DefaultReplyChannels.reduce((state, channel) => {
    state[channel] = getInitialDefaultReplyChannelState()

    return state
  }, {} as DefaultReplyState)

const dataToChannelState = (data: SafeUnknownObject): DefaultReplyChannelState => ({
  flowId: ((data?.flow as SafeUnknownObject)?.ns as string) || null,
  name: ((data?.flow as SafeUnknownObject)?.name as string) || '',
  chId: (data?.content_holder as string) || null,
  sendType: (data?.type as string) || '',
  sources: (data?.sources as DefaultReplySources) || null,
  enabled: (data?.enabled as boolean) ?? null,
  fetching: false,
  isFetched: true,
})

export const defaultReplyReducer: Reducer<DefaultReplyState> = (
  state = getInitialState(),
  action,
) => {
  const channel = (action.channel as ChannelType) ?? ChannelType.FB
  const channelState = state[channel]

  switch (action.type) {
    case atypes.DEFAULT_REPLY_LOAD_REQUEST: {
      return {
        ...state,
        [channel]: {
          ...channelState,
          fetching: true,
        },
      }
    }

    case atypes.DEFAULT_REPLY_GET_ALL_DATA_RESPONSE: {
      action.data?.forEach((channelDefaultReply: DefaultReply) => {
        state = dot.set(state, channelDefaultReply.channel, {
          ...state[channelDefaultReply.channel],
          ...dataToChannelState(channelDefaultReply as unknown as SafeUnknownObject),
        })
      })
      return state
    }

    case atypes.DEFAULT_REPLY_REMOVE_FLOW_RESPONSE: {
      return {
        ...state,
        [channel]: {
          ...channelState,
          flowId: null,
          name: '',
        },
      }
    }

    case atypes.DEFAULT_REPLY_CHANGE_SOURCES_RESPONSE: {
      return {
        ...state,
        [channel]: {
          ...channelState,
          sources: action.data.sources,
        },
      }
    }

    case atypes.DEFAULT_REPLY_LOAD_RESPONSE:
    case atypes.DEFAULT_REPLY_UPDATE_ENABLED_RESPONSE:
    case atypes.DEFAULT_REPLY_SET_FLOW_RESPONSE:
    case atypes.DEFAULT_REPLY_CHANGE_TYPE_RESPONSE:
    case atypes.DEFAULT_REPLY_UPDATE_BY_CHANNEL: {
      return {
        ...state,
        [channel]: {
          ...channelState,
          ...dataToChannelState(action.data),
        },
      }
    }

    case atypes.DEFAULT_REPLY_LOAD_ERROR: {
      return { ...state, [channel]: { ...channelState, fetching: false } }
    }

    case atypes.DEFAULT_REPLY_UPDATE_ENABLED_ERROR: {
      return { ...state, [channel]: { ...channelState, enabled: false, isFetched: true } }
    }
  }

  return state
}

enum OnboardingId {
  'CGT_FLOW_OVERVIEW_TOUR' = 'CGT_FLOW_OVERVIEW_TOUR',
  'WHATSAPP_BROADCAST_SAMPLE_TOUR' = 'WHATSAPP_BROADCAST_SAMPLE_TOUR',
  'WA_IMPORT_CONTACT_TOUR' = 'WA_IMPORT_CONTACT_TOUR',
  'LC_WA_FIRST_THREAD_TOUR' = 'LC_WA_FIRST_THREAD_TOUR',

  // SELF SERVE TOURS FOR NEW CMS
  'NEW_CMS_SELF_SERVE_STORY_REACTION_TOUR' = 'NEW_CMS_SELF_SERVE_STORY_REACTION_TOUR',
  'NEW_CMS_SELF_SERVE_FAQ_TOUR' = 'NEW_CMS_SELF_SERVE_FAQ_TOUR',
  'NEW_CMS_SELF_SERVE_STORY_MENTION_REPLY_TOUR' = 'NEW_CMS_SELF_SERVE_STORY_MENTION_REPLY_TOUR',
}

export default OnboardingId

export const allOnboardingsIds: OnboardingId[] = Object.values(OnboardingId)

import React, { useRef, useState, useCallback } from 'react'
import cx from 'classnames'
import { useAppDispatch } from 'reduxTyped'
import { Icon, Popover, Scrollbars } from '@manychat/manyui'

import { useActiveOptions } from 'apps/cms/components/Flows/components/FlowFilters/components/FilterPicker/hooks/useActiveOptions'
import { useFilterOptions } from 'apps/cms/components/Flows/components/FlowFilters/components/FilterPicker/hooks/useFilterOptions'
import { Option } from 'apps/cms/components/Flows/components/FlowFilters/components/FilterPicker/lib/types'
import { contentManagementActions } from 'apps/cms/store'

import { FilterPickerItem } from './FilterPickerItem/FilterPickerItem'
import { FilterPickerParent } from './FilterPickerParent/FilterPickerParent'

import cm from './FilterPicker.module.css'

interface FilterPickerProps {
  selectedOptions: Option[]
  setSelectedOptions: React.Dispatch<React.SetStateAction<Option[]>>
}

export const FilterPicker = ({ selectedOptions, setSelectedOptions }: FilterPickerProps) => {
  const dispatch = useAppDispatch()
  const { filterOptions } = useFilterOptions()
  const {
    activeFilterLabel,
    activeFilterIcon: ActiveFilterIcon,
    activeOptionsLength,
  } = useActiveOptions({
    setSelectedOptions,
  })

  const closeMenu = useCallback(() => {
    setIsMenuOpened(false)
    dispatch(
      contentManagementActions.updateQuery({
        triggerFilters: selectedOptions.map((option) => option.value),
      }),
    )
  }, [dispatch, selectedOptions])

  const [isMenuOpened, setIsMenuOpened] = useState(false)
  const buttonRef = useRef<HTMLButtonElement | null>(null)

  return (
    <>
      <button
        ref={buttonRef}
        onClick={() => setIsMenuOpened(true)}
        className={cm.filterPickerAnchor}
      >
        <span className={cm.filterPickerAnchor__content}>
          {ActiveFilterIcon && <ActiveFilterIcon className="flex-shrink-0" size={16} />}

          <span
            className={cx('text-ellipsis', {
              'text-black': activeOptionsLength,
            })}
          >
            {activeFilterLabel}
          </span>
        </span>

        <Icon.ChevronDown className="flex-shrink-0" size={20} />
      </button>

      <Popover
        at="bottom"
        popoverOrigin="top left"
        anchorOrigin="bottom left"
        anchor={buttonRef.current}
        open={isMenuOpened}
        onRequestClose={closeMenu}
        fitTargetWidth
        className={cm.popover}
      >
        <Scrollbars autoHeight autoHeightMax={352} autoHide={false}>
          <ul className={cx(cm.triggersList, cm.triggersList_parent)}>
            {filterOptions.map((option) => {
              return (
                <li key={option.group}>
                  <FilterPickerParent
                    option={option}
                    selectedOptions={selectedOptions}
                    setSelectedOptions={setSelectedOptions}
                  />

                  <ul className={cm.triggersList}>
                    {option.children.map((childOption) => {
                      return (
                        <li key={childOption.key}>
                          <FilterPickerItem
                            option={childOption}
                            selectedOptions={selectedOptions}
                            setSelectedOptions={setSelectedOptions}
                          />
                        </li>
                      )
                    })}
                  </ul>
                </li>
              )
            })}
          </ul>
        </Scrollbars>
      </Popover>
    </>
  )
}

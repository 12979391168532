import { useEffect, useCallback } from 'react'

import { ChannelType } from 'common/core/constants/ChannelType'
import { useUsercentrics } from 'utils/commonHooks'
import { USERCENTRICS_HOTJAR_NAME, DEBUG } from 'utils/services/hotjar/constants'
import { loadHotjar } from 'utils/services/hotjar/helpers'

export const useHotjar = () => {
  const { usercentricsServicesInfo } = useUsercentrics(USERCENTRICS_HOTJAR_NAME)
  const isProvideHotjar = usercentricsServicesInfo[USERCENTRICS_HOTJAR_NAME]?.status ?? false
  const isHotjar = Boolean(window.hj)
  const isReady = isProvideHotjar && isHotjar

  const hotjarEvent = useCallback(
    (event: string, channel?: ChannelType) => {
      if (!isReady) {
        return
      }

      const hj = window.hj
      DEBUG('hotjarEvent', event, channel, hj)
      if (!hj) {
        return
      }

      if (event) {
        if (channel) {
          const channelPrefixMap = {
            [ChannelType.FB]: 'fb',
            [ChannelType.INSTAGRAM]: 'ig',
            [ChannelType.WHATSAPP]: 'wa',
            [ChannelType.EMAIL]: 'email',
            [ChannelType.GUEST]: 'guest',
            [ChannelType.SMS]: 'sms',
            [ChannelType.TELEGRAM]: 'tg',
            [ChannelType.TIKTOK]: 'tt',
          }
          hj('event', `${channelPrefixMap[channel]}_${event}`)
        }
        hj('event', event)
      }
    },
    [isReady],
  )

  const hotjarTrigger = useCallback(
    (triggerId: string | null, tags?: string[]) => {
      if (!isReady) {
        return
      }

      const hj = window.hj
      DEBUG('hotjarTrigger', triggerId, tags, window.hj)

      if (!hj) {
        return
      }

      if (triggerId) {
        hj('trigger', triggerId)
      }

      if (Array.isArray(tags) && tags.length) {
        hj('tagRecording', tags)
      }
    },
    [isReady],
  )

  const hotjarIdentify = useCallback(
    (userId: number, data: SafeUnknownObject) => {
      if (!isReady) {
        return
      }

      const hj = window.hj
      if (!hj) {
        return
      }

      hj('identify', userId, data)
    },
    [isReady],
  )

  useEffect(() => {
    if (isHotjar || !isProvideHotjar) {
      return
    }
    loadHotjar()
  }, [isHotjar, isProvideHotjar])

  return {
    hotjarEvent,
    hotjarTrigger,
    hotjarIdentify,
    isReady,
  }
}

import * as pageActions from 'common/core/actions/pageActions'
import * as uiActions from 'common/core/actions/uiActions'
import { TAlertText } from 'common/core/interfaces/alert'

export const alert = function (
  text: TAlertText,
  type?: string,
  extra?: {
    isTemporary?: boolean
    source?: string
    icon?: string
    content?: string | React.JSX.Element
    /**
     *  The flag indicating the toast should automatically close when navigating to another page.
     *  Use this flag if there is an interactive element with an event handler inside the toast.
     */
    shouldRemoveOnNavigate?: boolean
  },
): void {
  if (!window.app) {
    return console.error(`window.app is required for notifications`)
  }
  // Special case for back-end errors in array
  if (Array.isArray(text)) {
    text = text.join(', ')
  }
  return window.app.store.dispatch(uiActions.addNotification(text, type, extra))
}

type ConfirmFn = (message: string | JSX.Element, options?: UnsafeAnyObject) => Promise<boolean>

let _confirm: ConfirmFn | null = null
export const registerConfirm = (c: ConfirmFn): ConfirmFn => (_confirm = c)

export const confirm: ConfirmFn = async (message, options) => {
  if (_confirm == null) {
    if (typeof message !== 'string') {
      return Promise.resolve(false)
    }

    return new window.Promise((resolve) => {
      setTimeout(() => resolve(window.confirm(message)))
    })
  } else {
    return _confirm(message, options)
  }
}

export function setTitle(title: string): void {
  if (!window.app) {
    return console.error(`window.app is required for setTitle`)
  }
  return window.app.store.dispatch(pageActions.setTitle(title))
}

import React from 'react'
import cx from 'classnames'
import { sanitizeURL } from '@manychat/manyui'

import { KeywordPreview as DefaultKeywordPreview } from 'apps/keywords/components/KeywordPreview/KeywordPreview'
import { KeywordRule } from 'apps/keywords/keywordsInterfaces'
import { ChannelConfig } from 'common/core/constants/ChannelConfig'
import { ChannelType } from 'common/core/constants/ChannelType'

import cm from './KeywordPreview.module.css'

interface KeywordPreviewProps {
  link: string
  entity: {
    channel: ChannelType
    data: KeywordRule[]
  }
}

export const KeywordPreview = ({ link, entity }: KeywordPreviewProps) => (
  <a
    href={sanitizeURL(link)}
    target="_blank"
    rel="noreferrer"
    className={cx('d-flex text-primary', cm.link)}
  >
    <img
      className="flex-shrink-0 m-r-sm"
      width={24}
      height={24}
      src={ChannelConfig[entity.channel]?.icon}
      alt=""
    />
    <DefaultKeywordPreview
      keywordRules={entity.data}
      keywordsClassName={cm.text}
      wrapperClassName={cm.text}
    />
  </a>
)

import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit'

import { DisconnectChannelState } from 'apps/whatsApp/core/constants'

const initialState: DisconnectChannelState = {
  conditionsDisconnectingAccount: [],
  isConfirmationReceived: false,
}

const disconnectChannelSlice = createSlice({
  name: 'whatsApp/DisconnectChannelSlice',
  initialState,
  reducers: {
    toggleDisconnectionConditionsChannel(
      state,
      action: PayloadAction<{ id: number; value: boolean; checkBoxesAmount: number }>,
    ) {
      const { id, value, checkBoxesAmount } = action.payload
      if (!state.conditionsDisconnectingAccount.length && checkBoxesAmount) {
        state.conditionsDisconnectingAccount = Array.from({ length: checkBoxesAmount }, () => false)
      }

      const arrLength = state.conditionsDisconnectingAccount.length
      if (arrLength && arrLength - 1 >= id) {
        state.conditionsDisconnectingAccount[id] = value
      }
    },
    changeConfirmationTextStatus(state, action: PayloadAction<boolean>) {
      if (state.isConfirmationReceived !== action.payload) {
        state.isConfirmationReceived = action.payload
      }
    },
    resetConditionsDisconnection(state) {
      state.isConfirmationReceived = initialState.isConfirmationReceived
      state.conditionsDisconnectingAccount = initialState.conditionsDisconnectingAccount
    },
  },
})

export const makeDisconnectionChannelSlice = <T>(
  baseSelector: (state: T) => DisconnectChannelState,
) => {
  const getDisconnectionChannelState = createSelector(
    baseSelector,
    (
      current: DisconnectChannelState,
    ): DisconnectChannelState & { isReadyToDisconnectAccount: boolean } => {
      return {
        ...current,
        isReadyToDisconnectAccount:
          current.isConfirmationReceived &&
          current.conditionsDisconnectingAccount.length > 0 &&
          current.conditionsDisconnectingAccount.every((value) => value),
      }
    },
  )

  return {
    reducer: disconnectChannelSlice.reducer,
    getDisconnectionChannelState,
    ...disconnectChannelSlice.actions,
  }
}

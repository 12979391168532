import React, { ReactNode } from 'react'
import cx from 'classnames'

import { useThemeContext } from '../../useThemeContext'

import cm from './ChatRoot.module.css'

export interface ChatRootProps {
  children?: ReactNode
}

export function ChatRoot({ children }: ChatRootProps) {
  const theme = useThemeContext()

  return (
    <div className={cx(cm.container, cm[theme])} data-test-id="chat-root">
      {children}
    </div>
  )
}

ChatRoot.displayName = 'Chat.Root'

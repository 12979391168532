import React, { ReactNode } from 'react'
import cx from 'classnames'

import { PhonePreviewTheme } from 'components/PhonePreviewKit'
import { useThemeContext } from 'components/PhonePreviewKit/useThemeContext'

import cm from './ChatInfoMessage.module.css'

export interface ChatUserMessageProps {
  children: ReactNode
}

export function ChatInfoMessage({ children }: ChatUserMessageProps) {
  const theme = useThemeContext()

  return (
    <div
      className={cx(cm.infoMessage, {
        [cm[PhonePreviewTheme.FACEBOOK]]: theme === PhonePreviewTheme.FACEBOOK,
      })}
      data-test-id="chat-info-message"
    >
      {children}
    </div>
  )
}

ChatInfoMessage.displayName = 'Chat.InfoMessage'

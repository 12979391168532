import hash from 'hash-sum'
import isEmpty from 'lodash/isEmpty'
import xor from 'lodash/xor'

import * as atypes from 'common/builder/constants/builderReduxActionTypes'
import { SELECTION_TYPE } from 'common/builder/models/BuilderState/constants'
import * as commonATypes from 'common/core/constants/ReduxActionTypes'

const isHashEqual = (a, b) => {
  return (a.$hash || hash(a)) === (b.$hash || hash(b))
}

const isLocalChanges = (changes) => {
  if (isEmpty(changes)) {
    return true
  }
  return Object.keys(changes).every((key) => key.startsWith('$'))
}

export default function builderStateReducer(state = {}, action) {
  switch (action.type) {
    case atypes.CREATE_BUILDER_STATE: {
      const { item } = action
      if (!item || !item.id) {
        return state
      }
      return { ...item }
    }
    case atypes.INSTALL_BUILDER_SNAPSHOT: {
      const { state: builderState } = action.targetSnapshot

      return { ...builderState, lastChanged: Date.now() }
    }
    case atypes.UPDATE_BUILDER_STATE: {
      const { changes } = action
      return { ...state, ...changes }
    }
    case atypes.BUILDER_SET_ROOT:
      return {
        ...state,
        rootId: action.nodeId,
        lastChanged: Date.now(),
        contentHistory: [{ contentId: action.nodeId, referrer: null }],
        selectedButtonId: null,
        selectedUrlId: null,
        selectedBlockId: null,
      }
    case atypes.BUILDER_UPDATE_ROOT:
      return {
        ...state,
        rootId: action.contentId,
      }
    case atypes.BUILDER_SELECT_NODES: {
      const { selectionType, ids } = action
      const { selectedNodes: prevSelectedNodes } = state
      let selection = Object.keys(prevSelectedNodes)

      switch (selectionType) {
        case SELECTION_TYPE.SET:
          selection = ids
          break

        case SELECTION_TYPE.MERGE:
          selection = [...Object.keys(prevSelectedNodes), ...ids]
          break

        case SELECTION_TYPE.TOGGLE:
          selection = xor(selection, ids)
          break
      }

      if (selection.length > 1) {
        selection = selection.filter((id) => !id.includes('_'))
      }

      return {
        ...state,
        selectedNodes: selection.reduce((res, id) => {
          res[id] = true
          return res
        }, {}),
        selectedButtonId: null,
        selectedUrlId: null,
        selectedBlockId: null,
        createTriggerMode: false,
        createTriggerInitial: {},
      }
    }
    case atypes.BUILDER_SELECT_BUTTON: {
      const { buttonId } = action
      return {
        ...state,
        selectedButtonId: buttonId || null,
        selectedBlockId: null,
        serverError: null,
        showErrors: false,
      }
    }
    case atypes.BUILDER_SELECT_URL: {
      const { urlId } = action
      return {
        ...state,
        selectedUrlId: urlId || null,
        selectedBlockId: null,
        serverError: null,
        showErrors: false,
      }
    }
    case atypes.BUILDER_RESET_SERVER_ERRORS: {
      return {
        ...state,
        serverError: null,
        showErrors: false,
      }
    }
    case atypes.BUILDER_SELECT_BLOCK: {
      const { blockId } = action
      return {
        ...state,
        selectedButtonId: null,
        selectedUrlId: null,
        selectedBlockId: blockId || null,
        serverError: null,
        showErrors: false,
      }
    }
    case atypes.BUILDER_HIGHLIGHT_BLOCK: {
      const { blockId, style = '' } = action
      if (!blockId) {
        return {
          ...state,
          highlightBlockId: null,
        }
      }
      return {
        ...state,
        highlightBlockId: blockId,
        highlightBlockStyle: style,
        highlightBlockTimestamp: Date.now(),
      }
    }
    case atypes.CONTENT_VALIDATED: {
      const errors = {}
      const { errors: nextErrors, errorsHash } = action
      const prevErrors = state.errors || {}

      for (let elementId of Object.keys(nextErrors)) {
        if (prevErrors[elementId] && isHashEqual(prevErrors[elementId], nextErrors[elementId])) {
          errors[elementId] = prevErrors[elementId]
        } else {
          errors[elementId] = nextErrors[elementId]
          errors[elementId].$hash = hash(errors[elementId])
        }
      }
      return {
        ...state,
        errors,
        errorsHash,
      }
    }
    case atypes.BUILDER_SET_CHANGED:
    case atypes.REMOVE_BUTTON:
    case atypes.REMOVE_BLOCK:
    case commonATypes.ATTACHMENT_UPLOAD_RESPONSE:
    case atypes.UPDATE_SOURCE_TARGET:
    case atypes.LINK_NODE_AND_BLOCK:
    case atypes.UNLINK_NODE_AND_BLOCK: {
      return { ...state, lastChanged: Date.now() }
    }
    case atypes.REMOVE_NODE: {
      const lastChanged = Date.now()
      const newState = { ...state, lastChanged }

      const { nodeId } = action
      if (nodeId) {
        let { coordinates } = newState
        if (coordinates && coordinates[nodeId]) {
          newState.coordinates = { ...coordinates, [nodeId]: null }
        }
        if (newState.contentHistory) {
          newState.contentHistory = newState.contentHistory.filter((h) => h.contentId !== nodeId)
        }
      }

      return newState
    }
    case atypes.CREATE_BLOCK:
    case atypes.CREATE_BUTTON: {
      return { ...state, lastChanged: Date.now(), serverError: null, showErrors: false }
    }
    case atypes.CREATE_NODE:
    case atypes.UPDATE_NODE:
    case atypes.UPDATE_BUTTON:
    case atypes.UPDATE_BLOCK: {
      if (isLocalChanges(action.changes)) {
        return state
      }
      return { ...state, lastChanged: Date.now() }
    }

    case atypes.BUILDER_RESET_CHANGED: {
      return { ...state, lastChanged: null }
    }

    case atypes.BUILDER_SET_READY: {
      const lastChanged = null
      const isReady = true
      return { ...state, lastChanged, isReady, ...action.changes }
    }

    case atypes.HOVER_OVER_BUTTON: {
      const { highlightedNodeId } = action
      if (!highlightedNodeId) {
        return state
      }
      return { ...state, highlightedNodeId }
    }

    case atypes.CLEAR_BUTTON_HOVER: {
      return { ...state, highlightedNodeId: null }
    }
    case atypes.BUILDER_UPDATE_COORDINATES: {
      const lastChanged = Date.now()
      return {
        ...state,
        lastChanged,
        coordinates: { ...state.coordinates, ...action.coordinates },
      }
    }
    case atypes.CLEAR_BUILDER_AND_RESET: {
      return {
        ...state,
        highlightBlockId: null,
        highlightedNodeId: null,
        selectedNodes: {},
        selectedButtonId: null,
        selectedUrlId: null,
        selectedBlockId: null,
      }
    }
  }

  return state
}

export enum ChatMessageType {
  // NOTES
  USER_THREAD_NOTE = 'user_thread_note',
  USER_THREAD_NOTE_SMS = 'user_thread_sms',
  USER_THREAD_NOTE_TELEGRAM = 'user_thread_note_telegram',
  USER_THREAD_NOTE_INSTAGRAM = 'user_thread_note_instagram',
  USER_THREAD_NOTE_WHATSAPP = 'user_thread_note_whatsapp',
  USER_THREAD_NOTE_TIKTOK = 'user_thread_note_tiktok',

  // INSTAGRAM
  MSGIN_INSTAGRAM = 'msgin_instagram',
  MSGOUT_INSTAGRAM = 'msgout_instagram',
  MSGOUT_LC_INSTAGRAM = 'msgout_lc_instagram',
  MSGOUT_ECHO_INSTAGRAM = 'msgout_echo_instagram', // message from instagram page to customer
  MSGOUT_BRANDING_INSTAGRAM = 'msgout_branding_instagram', // @ValeryKashentsev: шлется первым сообщением каждому новому контакту, если у тебя фри аккаунт
  USER_IG_UNSUBSCRIBED = 'user_ig_unsubscribed',

  // FACEBOOK
  MSGIN = 'msgin',
  MSGIN_LC = 'msgin_lc',
  MSGOUT = 'msgout',
  MSGOUT_LC = 'msgout_lc',
  MSGOUT_KEYWORD = 'msgout_keyword',
  MSGOUT_DEFAULT = 'msgout_default',
  MSGOUT_WELCOME = 'msgout_welcome',
  MSGOUT_ACTION = 'msgout_action',
  MSGOUT_DELAY = 'msgout_delay',
  MSGOUT_POSTING = 'msgout_posting',
  MSGOUT_WIDGET = 'msgout_widget',
  MSGOUT_SEQUENCE = 'msgout_sequence',
  MSGOUT_API = 'msgout_api',
  MSGOUT_RECEIPT = 'msgout_receipt',
  MSGOUT_FORM = 'msgout_form',
  MSGOUT_REF_CONTENT = 'msgout_ref_content',
  USER_FB_UNSUBSCRIBED = 'user_fb_unsubscribed',
  GUEST_LINK = 'guest_link',
  FACEBOOK_GUEST_CHAT_STARTED = 'facebook_guest_chat_started',
  FACEBOOK_GUEST_CHAT_ENDED = 'facebook_guest_chat_ended',

  // WHATSAPP
  MSGIN_WHATSAPP = 'msgin_whatsapp',
  MSGIN_LC_WHATSAPP = 'msgin_lc_whatsapp',
  MSGOUT_WHATSAPP = 'msgout_whatsapp',
  MSGOUT_ECHO_WHATSAPP = 'msgout_echo_whatsapp',
  USER_WA_UNSUBSCRIBED = 'user_wa_unsubscribed',
  MSGOUT_LC_WHATSAPP = 'msgout_lc_whatsapp',
  WA_LIST_MESSAGE = 'wa_list_message',
  WA_CATALOG_MESSAGE = 'wa_catalog_message',
  WA_CATALOG_ORDER = 'cart',
  WA_UNSUPPORTED = 'wa_unsupported',
  WA_REQUEST_WELCOME = 'request_welcome_whatsapp',

  // TIKTOK
  MSGIN_TIKTOK = 'msgin_tiktok',
  USER_TT_SUBSCRIBED = 'user_tt_subscribed',
  USER_TT_UNSUBSCRIBED = 'user_tt_unsubscribed',
  TIKTOK_USERNAME_CHANGED = 'tiktok_username_changed',
  TIKTOK_DISPLAY_NAME_CHANGED = 'tiktok_display_name_changed',

  MSGOUT_TIKTOK = 'msgout_tiktok',
  MSGOUT_LC_TIKTOK = 'msgout_lc_tiktok',
  MSGOUT_ECHO_TIKTOK = 'msgout_echo_tiktok',

  // TELEGRAM
  MSGIN_TELEGRAM = 'msgin_telegram',
  MSGIN_LC_TELEGRAM = 'msgin_lc_telegram',
  MSGOUT_TELEGRAM = 'msgout_telegram',
  MSGOUT_LC_TELEGRAM = 'msgout_lc_telegram',
  USER_TG_UNSUBSCRIBED = 'user_tg_unsubscribed',

  // SMS
  MSGIN_SMS = 'msgin_sms',
  MSGOUT_SMS = 'msgout_sms',
  MSGOUT_LC_SMS = 'msgout_lc_sms',
  USER_SMS_UNSUBSCRIBED = 'user_sms_unsubscribed',
  MSGOUT_KEYWORD_SMS = 'msgout_keyword_sms',

  // MMS
  MSGOUT_MMS = 'msgout_mms',
  MSGOUT_BODY_MMS = 'msgout_body_mms',

  // EMAIL
  MSGOUT_EMAIL = 'msgout_email',

  VISITOR_LINKED = 'visitor_linked',
  USER_MERGED = 'user_merged',
  USER_THREAD_NEW = 'user_thread_new',
  USER_THREAD_OPENED = 'user_thread_opened',
  USER_THREAD_CLOSED = 'user_thread_closed',
  USER_SEQUENCE_MESSAGE_SCHEDULED = 'user_sequence_message_scheduled',
  USER_SEQUENCE_MESSAGE_RESCHEDULED = 'user_sequence_message_rescheduled',
  USER_RULE_TRIGGERED = 'user_rule_triggered',
  USER_CUF_SET = 'user_cuf_set',
  USER_CUF_UNSET = 'user_cuf_unset',
  USER_SMART_DELAY_SCHEDULED = 'user_smart_delay_scheduled',
  USER_SUF_EMAIL_CHANGE = 'user_suf_email_change',
  USER_SUF_PHONE_CHANGE = 'user_suf_phone_change',
  USER_ORDER_PAID = 'user_order_paid',
  USER_SUBSCRIBED = 'user_subscribed',
  USER_UNSUBSCRIBED = 'user_unsubscribed',
  REASSIGN = 'reassign',
  UNASSIGN = 'unassign',
  ASSIGN = 'assign',
  FLOW_BASED_ASSIGN = 'flow_based_assign',
  RULE_BASED_AUTO_ASSIGN = 'rule_based_auto_assign',
  BASIC_AUTO_ASSIGN = 'basic_auto_assign',
  TAG_ADDED = 'tag_added',
  TAG_REMOVED = 'tag_removed',
  SEQ_ADDED = 'seq_added',
  SEQ_REMOVED = 'seq_removed',
  WIDGET_ADDED = 'widget_added',
  CLICK_URL = 'click_url',
  CLICK_ACTION = 'click_action',
  CLICK_BUY = 'click_buy',
  CUSTOM_EVENT_TRIGGERED = 'custom_event_triggered',
  NOTIFY_ADMIN_LC = 'notify_admin_lc',
  FLOW_SEND_FROM_LC = 'flow_send_from_lc',
  CGT_TRIGGER_COMMENT = 'cgt_trigger_comment',
  IG_CGT_TRIGGER_COMMENT = 'ig_cgt_trigger_comment',
  RECURRING_NOTIFICATION_TOKEN_STOPPED = 'recurring_notification_token_stopped',
  IG_RECURRING_NOTIFICATION_TOKEN_STOPPED = 'ig_recurring_notification_token_stopped',
  RECURRING_NOTIFICATION_TOKEN_RESUMED = 'recurring_notification_token_resumed',
  IG_RECURRING_NOTIFICATION_TOKEN_RESUMED = 'ig_recurring_notification_token_resumed',
  SCHEDULED_MESSAGE_WAS_NOT_SENT = 'scheduled_message_was_not_sent',
  FB_TIKTOK_ADS_EVENT_SENT = 'fb_tiktok_ads_event_sent',
  WA_TIKTOK_ADS_EVENT_SENT = 'wa_tiktok_ads_event_sent',
  TIKTOK_REFERRAL = 'msgin_ref_tiktok',
  FB_TIKTOK_ADS_REFERRAL = 'fb_tiktok_ads_referral',
  WA_TIKTOK_ADS_REFERRAL = 'wa_tiktok_ads_referral',
}

import { IEmailTriggerOpenBlock, IEmailRootBlock } from 'common/builder/blocks/blockInterfaces'
import { IBuilderState, IEmailBuilderState } from 'common/builder/builderInterfaces'
import { BlockType } from 'common/builder/constants/BlockType'
import { ButtonType } from 'common/builder/constants/ButtonType'
import { EmailBuilderDeviceTarget } from 'common/builder/emailBuilder/emailBuilderConstants'
import { IEmailData, IEmailNode } from 'common/builder/nodes/nodeInterfaces'
import * as builderStateSelectors from 'common/builder/selectors/builder/builderStateSelectors'
import * as entitySelectors from 'common/builder/selectors/builder/entitySelectors'

export const getEmailBuilderState = (state: RootState, builderId: string): IEmailBuilderState => {
  const builderState: IBuilderState = builderStateSelectors.getById(state, builderId)
  return builderState?.emailBuilder
}

export const getEmailBuilderNodeId = (state: RootState, builderId: string): string => {
  const emailBuilderState = getEmailBuilderState(state, builderId)
  return emailBuilderState?.nodeId
}

export const getEmailBuilderDeviceTarget = (
  state: RootState,
  builderId: string,
): EmailBuilderDeviceTarget => {
  const emailBuilderState = getEmailBuilderState(state, builderId)
  return emailBuilderState.deviceTarget
}

export const isEmailBuilderOpen = (state: RootState, builderId: string): boolean => {
  return Boolean(getEmailBuilderNodeId(state, builderId))
}

export const isEmailBuilderOpenByBuilder = (builder?: IBuilderState): boolean => {
  return Boolean(builder?.emailBuilder?.nodeId)
}

export const getEmailData = (state: RootState, builderId: string, nodeId: string): IEmailData => {
  const node = entitySelectors.getNodeById(state, builderId, nodeId) as IEmailNode
  return node.data
}

export const getRootBlock = (
  state: RootState,
  builderId: string,
  nodeId: string,
): IEmailRootBlock | null => {
  const blocks = entitySelectors.getBlocks(state, builderId, nodeId)
  return (blocks.find((block) => block.type === BlockType.EMAIL_ROOT) as IEmailRootBlock) || null
}

export const getTriggerOnOpenBlock = (
  state: RootState,
  builderId: string,
  nodeId: string,
): IEmailTriggerOpenBlock | null => {
  const blocks = entitySelectors.getBlocks(state, builderId, nodeId)
  const block = blocks.find(
    (block) => block.type === BlockType.EMAIL_TRIGGER_OPEN,
  ) as IEmailTriggerOpenBlock
  return block || null
}

export const getEmailLinkCaption = (
  state: RootState,
  builderId: string,
  buttonId: string,
): string => {
  const button = entitySelectors.getButtonById(state, builderId, buttonId)
  if (!button) {
    return ''
  }

  const parentBlock = entitySelectors.getParentBlock(state, builderId, buttonId)
  if (parentBlock?.type === BlockType.EMAIL_TEXT && button.caption) {
    return button.caption
  }

  if (button.type === ButtonType.URL) {
    return button.url || 'Enter URL'
  }
  if (button.type === ButtonType.CONTENT) {
    const linkToMessengerExample = 'https://manychat.com/xxxxxxxx'
    return linkToMessengerExample
  }

  return '...'
}

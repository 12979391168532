export enum AvailableCurrencies {
  USD = 'USD',
  EUR = 'EUR',
  GBP = 'GBP',
  CAD = 'CAD',
  AUD = 'AUD',
  BRL = 'BRL',
  HKD = 'HKD',
  JPY = 'JPY',
  NZD = 'NZD',
  MXN = 'MXN',
  CHF = 'CHF',
  DKK = 'DKK',
  NOK = 'NOK',
  SGD = 'SGD',
  SEK = 'SEK',
  ZAR = 'ZAR',
  MYR = 'MYR',
  PHP = 'PHP',
  CZK = 'CZK',
  ILS = 'ILS',
  TWD = 'TWD',
  RUB = 'RUB',
  THB = 'THB',
  RON = 'RON',
  HUF = 'HUF',
  PLN = 'PLN',
  INR = 'INR',
}

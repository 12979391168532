import React from 'react'
import PropTypes from 'prop-types'

import RecurringNotificationPicker from '../../RecurringNotificationPicker/RecurringNotificationPicker'

export default class OneTimeNotifyPicker extends React.Component {
  static propTypes = {
    className: PropTypes.string,
    channel: PropTypes.string,
    value: PropTypes.any,
    notificationReasons: PropTypes.array,
    autoFocus: PropTypes.bool,
    invalid: PropTypes.bool,
    inline: PropTypes.bool,
    disabled: PropTypes.bool,
    forceNew: PropTypes.bool,
    allowNew: PropTypes.bool,
    allowCreate: PropTypes.bool,
    createReason: PropTypes.func,
    placeholder: PropTypes.string,
    oneTimeNotifyGroupedReasons: PropTypes.array,
    notificationReasonPrepared: PropTypes.array,
    customInputLayout: PropTypes.node,
  }

  static defaultProps = {
    allowNew: true,
    autoFocus: false,
    inline: false,
    disabled: false,
    forceNew: true,
    onFocus: () => {},
    onBlur: () => {},
  }

  handleRef = (ref) => {
    this.input = ref
    if (this.props.autoFocus) {
      this.focus()
    }
  }

  focus = () => {
    if (this.input) {
      this.input.focus()
    }
  }

  blur = () => {
    if (this.input) {
      this.input.blur()
    }
  }

  handleChange = ({ target }) => {
    const { option } = target
    const event = { target: { ...target, value: option?.value } }
    this.props.onChange(event)
  }

  render() {
    const {
      value,
      notificationReasonPrepared,
      className,
      allowCreate,
      disabled,
      notificationReasons,
      placeholder,
      customInputLayout,
      channel,
    } = this.props

    return (
      <RecurringNotificationPicker
        channel={channel}
        className={className}
        value={value}
        placeholder={placeholder}
        allowCreate={allowCreate}
        onChange={this.handleChange}
        notificationReasonPrepared={notificationReasonPrepared}
        notificationReasons={notificationReasons}
        disabled={disabled}
        customInputLayout={customInputLayout}
      />
    )
  }
}

import { Icon } from '@manychat/manyui'

export enum ChannelType {
  FB = 'facebook',
  SMS = 'sms',
  EMAIL = 'email',
  GUEST = 'facebook_guest_chat',
  WHATSAPP = 'whatsapp',
  INSTAGRAM = 'instagram',
  TELEGRAM = 'telegram',
  TIKTOK = 'tiktok',
}

export type AiIntentChannel = ChannelType.FB | ChannelType.INSTAGRAM | ChannelType.WHATSAPP

export const ChannelTypeLabel: Record<ChannelType, string> = {
  [ChannelType.FB]: 'Facebook',
  [ChannelType.SMS]: 'SMS',
  [ChannelType.EMAIL]: 'Email',
  [ChannelType.GUEST]: 'Guest Chat',
  [ChannelType.WHATSAPP]: 'WhatsApp',
  [ChannelType.INSTAGRAM]: 'Instagram',
  [ChannelType.TELEGRAM]: 'Telegram',
  [ChannelType.TIKTOK]: 'TikTok',
}

export const ChannelTypeShortLabel: Record<ChannelType, string> = {
  [ChannelType.FB]: 'MSG',
  [ChannelType.SMS]: 'SMS',
  [ChannelType.EMAIL]: 'Email',
  [ChannelType.GUEST]: 'GUEST',
  [ChannelType.WHATSAPP]: 'WA',
  [ChannelType.INSTAGRAM]: 'IG',
  [ChannelType.TELEGRAM]: 'TG',
  [ChannelType.TIKTOK]: 'TT',
}

export enum FlowType {
  FLOW_BUILDER = 'flow_builder',
  EASY_BUILDER = 'easy_builder',
}

export const FlowTypeLabel: Record<FlowType, string> = {
  [FlowType.FLOW_BUILDER]: 'Flow Builder',
  [FlowType.EASY_BUILDER]: 'Quick Automation',
}

export const ChannelIcon: Record<ChannelType, Icon.Icon> = {
  [ChannelType.FB]: Icon.MessengerChannel,
  [ChannelType.SMS]: Icon.SmsChannel,
  [ChannelType.EMAIL]: Icon.EmailChannel,
  [ChannelType.GUEST]: Icon.GuestChatChannel,
  [ChannelType.WHATSAPP]: Icon.WhatsAppChannel,
  [ChannelType.INSTAGRAM]: Icon.InstagramChannel,
  [ChannelType.TELEGRAM]: Icon.TelegramChannel,
  [ChannelType.TIKTOK]: Icon.TikTokChannel,
}

export const ChannelIconTemplates: Record<ChannelType, Icon.Icon> = {
  [ChannelType.FB]: Icon.FacebookMessenger,
  [ChannelType.SMS]: Icon.TriggerFbComments,
  [ChannelType.EMAIL]: Icon.SponsoredMessages,
  [ChannelType.GUEST]: Icon.GuestChatChannel,
  [ChannelType.WHATSAPP]: Icon.WhatsAppWidget,
  [ChannelType.INSTAGRAM]: Icon.InstagramChannel3,
  [ChannelType.TELEGRAM]: Icon.TelegramChannel2,
  [ChannelType.TIKTOK]: Icon.TikTokChannel2,
}

export const FlowTypeIcon: Record<FlowType, Icon.Icon> = {
  [FlowType.FLOW_BUILDER]: Icon.Flow,
  [FlowType.EASY_BUILDER]: Icon.OrphanAction,
}

export enum ConnectChannelModalSource {
  BROADCAST = 'BROADCAST',
  BUILDER_NODES = 'BUILDER_NODES',
  BUILDER_OPT_INS = 'BUILDER.OPT_INS',
  CHANNELS_DASHBOARD = 'CHANNEL_DASHBOARD',
  LIVECHAT_THREADS = 'LIVECHAT_THREADS',
  REFRESH_ADMIN_PERMISSIONS = 'REFRESH_ADMIN_PERSMISSIONS',
  SETTINGS_MESSENGER = 'SETTINGS.MESSENGER',
  WIDGET_EDITOR = 'WIDGET_EDITOR',
  CREATE_TRIGGER_MODAL = 'CREATE_TRIGGER_MODAL',
}

export const ConnectChannelSection = {
  MM: 'Main Menu',
  DR: 'Default Reply',
  WM: 'Welcome Message',
  PM: 'Paid Messages',
  SMR: 'Story Mention Reply',
}

export const ConnectChannelSectionDescription = {
  [ConnectChannelSection.MM]:
    'Main Menu is a simple way to provide your contacts with always accessible options, like a help button which will send the user a message with helpful information, or a link to your main website. ',
  [ConnectChannelSection.DR]:
    'Default Reply allows to send default message to your audience when your contacts type some keyword that is not recognized by your bot.',
  [ConnectChannelSection.WM]:
    'Welcome Message﻿ is always shown to all new users after clicking on “Get Started” or when they send their first message.',
  [ConnectChannelSection.PM]:
    'Paid message is a simple, reliable, and compliant way of sending your message via Facebook Messenger to contacts who are outside 24 hrs messaging window.',
  [ConnectChannelSection.SMR]:
    'Start an automation, if someone mentions your Instagram account in their Story',
}

export type ChannelForRn = ChannelType.FB | ChannelType.INSTAGRAM

import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import { IStrategy } from 'apps/quickCampaigns/quickCampaignsInterfaces'

export interface SelectTemplateModalSliceState {
  isModalOpen: boolean
  selectedStrategy: IStrategy | null
  newFlowPath: string | null
  newNs: string | null
  newFolderId: number | null
  showAiSubscribeButton: boolean
}

const initialState: SelectTemplateModalSliceState = {
  isModalOpen: false,
  selectedStrategy: null,
  newFlowPath: null,
  newNs: null,
  newFolderId: null,
  showAiSubscribeButton: false,
}

interface NewFlowData {
  path?: string | null | undefined
  ns?: string | null
  folderId?: number | null
}

interface Config {
  showAiSubscribeButton?: boolean
}

const SelectTemplateModalSlice = createSlice({
  name: 'SelectTemplateModal',
  initialState,
  reducers: {
    openModal(
      state: SelectTemplateModalSliceState,
      action: PayloadAction<(NewFlowData & Config) | null | undefined>,
    ) {
      state.isModalOpen = true
      if (action.payload) {
        const { path, ns, folderId, showAiSubscribeButton } = action.payload
        if (path) {
          state.newFlowPath = path
        }
        if (folderId) {
          state.newFolderId = folderId
        }
        state.newNs = ns ?? null
        state.showAiSubscribeButton = Boolean(showAiSubscribeButton)
      }
    },
    closeModal(state: SelectTemplateModalSliceState) {
      state.isModalOpen = false
    },
    dropSelectedStrategy(state: SelectTemplateModalSliceState) {
      state.selectedStrategy = null
    },
    setSelectedStrategy(state: SelectTemplateModalSliceState, action: PayloadAction<IStrategy>) {
      state.selectedStrategy = action.payload
    },
    resetNewFlowData(state: SelectTemplateModalSliceState) {
      state.newFlowPath = null
      state.newNs = null
      state.newFolderId = null
    },
  },
})

export const {
  openModal,
  closeModal,
  setSelectedStrategy,
  dropSelectedStrategy,
  resetNewFlowData,
} = SelectTemplateModalSlice.actions

export default SelectTemplateModalSlice.reducer

export const getIsModalOpen = (state: RootState): boolean => state.selectTemplateModal.isModalOpen
export const getSelectedStrategy = (state: RootState): IStrategy | null =>
  state.selectTemplateModal.selectedStrategy
export const getNewFlowData = (state: RootState): NewFlowData => ({
  path: state.selectTemplateModal.newFlowPath,
  ns: state.selectTemplateModal.newNs,
  folderId: state.selectTemplateModal.newFolderId,
})
export const getShowAiSubscribeButton = (state: RootState): boolean =>
  state.selectTemplateModal.showAiSubscribeButton

export const GROUPS_FETCH_GROUPS_LIST_REQUEST = 'GROUPS_FETCH_GROUPS_LIST_REQUEST'
export const GROUPS_FETCH_GROUPS_LIST_RESPONSE = 'GROUPS_FETCH_GROUPS_LIST_RESPONSE'
export const GROUPS_FETCH_GROUPS_LIST_ERROR = 'GROUPS_FETCH_GROUPS_LIST_ERROR'

export const GROUPS_CREATE_GROUP_REQUEST = 'GROUPS_CREATE_GROUP_REQUEST'
export const GROUPS_CREATE_GROUP_RESPONSE = 'GROUPS_CREATE_GROUP_RESPONSE'
export const GROUPS_CREATE_GROUP_ERROR = 'GROUPS_CREATE_GROUP_ERROR'

export const GROUPS_DELETE_GROUP_REQUEST = 'GROUPS_DELETE_GROUP_REQUEST'
export const GROUPS_DELETE_GROUP_RESPONSE = 'GROUPS_DELETE_GROUP_RESPONSE'
export const GROUPS_DELETE_GROUP_ERROR = 'GROUPS_DELETE_GROUP_ERROR'

export const GROUPS_SWITCH_AUTO_ASSIGN_REQUEST = 'GROUPS_SWITCH_AUTO_ASSIGN_REQUEST'
export const GROUPS_SWITCH_AUTO_ASSIGN_RESPONSE = 'GROUPS_SWITCH_AUTO_ASSIGN_RESPONSE'
export const GROUPS_SWITCH_AUTO_ASSIGN_ERROR = 'GROUPS_SWITCH_AUTO_ASSIGN_ERROR'

export const GROUPS_CHANGE_USERS_REQUEST = 'GROUPS_CHANGE_USERS_REQUEST'
export const GROUPS_CHANGE_USERS_RESPONSE = 'GROUPS_CHANGE_USERS_RESPONSE'
export const GROUPS_CHANGE_USERS_ERROR = 'GROUPS_CHANGE_USERS_ERROR'

export const GROUPS_UPDATE_GROUP_NAME_REQUEST = 'GROUPS_UPDATE_GROUP_NAME_REQUEST'
export const GROUPS_UPDATE_GROUP_NAME_RESPONSE = 'GROUPS_UPDATE_GROUP_NAME_RESPONSE'
export const GROUPS_UPDATE_GROUP_NAME_ERROR = 'GROUPS_UPDATE_GROUP_NAME_ERROR'

import React from 'react'
import { l } from '@manychat/manyui'

export const WAReinstatedNotification = () => {
  return (
    <span>
      {l.translate(
        'Your WhatsApp account has been unbanned by Meta, and you can now use it with Manychat.',
      )}
    </span>
  )
}

import dotProp from 'dot-prop-immutable'

import * as accountAtypes from 'apps/account/accountReduxActionTypes'
import { parsePage } from 'apps/profile/models/Page/adapter'

import * as atypes from '../constants/profileReduxActionTypes'

const initialState = {
  emailConfirmation: {
    status: null,
    error: null,
    confirmationsSent: false,
  },
  pages: {
    initial: [],
    filtered: [],
    loading: false,
    fetched: false,
    error: false,
  },
  userMenuOpened: false,
  messageTagAnalytics: {
    isLoading: false,
    accounts: [],
  },
  inactiveAccountIds: [],
}

export default function profileReducer(state = initialState, action) {
  switch (action.type) {
    case atypes.PROFILE_CONFIRM_EMAIL_REQUEST: {
      return { ...state, emailConfirmation: { confirmationsSent: true } }
    }
    case atypes.PROFILE_CONFIRM_EMAIL_RESPONSE: {
      return {
        ...state,
        emailConfirmation: { ...state.emailConfirmation, status: true, confirmationsSent: false },
      }
    }
    case atypes.PROFILE_CONFIRM_EMAIL_ERROR: {
      return {
        ...state,
        emailConfirmation: {
          ...state.emailConfirmation,
          status: false,
          error: action.error.errors[0],
          confirmationsSent: false,
        },
      }
    }
    case atypes.PROFILE_PAGES_FETCH_REQUEST: {
      return { ...state, pages: { ...state.pages, loading: true, error: false } }
    }
    case atypes.PROFILE_PAGES_FETCH_RESPONSE: {
      const pages = action.pages.map(parsePage)

      return {
        ...state,
        pages: {
          ...state.pages,
          initial: pages,
          filtered: [...pages],
          loading: false,
          fetched: true,
        },
      }
    }
    case atypes.PROFILE_PAGES_SORT: {
      const { sortFunction, reset } = action.data
      const pages = { ...state.pages }

      if (reset) {
        const filteredPagesIds = state.pages.filtered.map((page) => page.id)

        pages.filtered = pages.initial.filter((page) => filteredPagesIds.indexOf(page.id) >= 0)
      } else {
        pages.filtered = pages.filtered.sort(sortFunction)
      }

      return { ...state, pages }
    }
    case atypes.PROFILE_PAGES_SEARCH: {
      const query = action.data.query.toUpperCase()

      return {
        ...state,
        pages: {
          ...state.pages,
          filtered: state.pages.initial.filter(
            (page) => page.name.toUpperCase().indexOf(query) >= 0,
          ),
        },
      }
    }
    case atypes.PROFILE_FETCH_MESSAGE_TAG_ANALYTICS_REQUEST: {
      return {
        ...state,
        messageTagAnalytics: {
          isLoading: true,
          accounts: [],
        },
      }
    }
    case atypes.PROFILE_FETCH_MESSAGE_TAG_ANALYTICS_RESPONSE: {
      const data = action.mockResponse || action.data

      return {
        ...state,
        messageTagAnalytics: {
          isLoading: false,
          lastAvailableDate: data.last_available_date,
          analyticsDateFrom: data.analytics_date_from,
          accounts: data.accounts,
        },
      }
    }
    case atypes.PROFILE_SET_USER_MENU_VISIBILITY: {
      return {
        ...state,
        userMenuOpened: action.opened,
      }
    }
    case atypes.PROFILE_SET_INACTIVE_ACCOUNT_IDS: {
      return {
        ...state,
        inactiveAccountIds: action.accountIds,
      }
    }
    case atypes.PROFILE_SET_INACTIVE_ACCOUNT_IDS_ERROR: {
      return {
        ...state,
        inactiveAccountIds: [],
        pages: {
          ...state.pages,
          loading: false,
        },
      }
    }
  }

  //TODO: consider storing accounts (pages) only in state.app.accounts (and delete `accountFavoriteReducer` and other accounts (pages) related stuff here)
  state = accountFavoriteReducer(state, action)

  return state
}

const accountFavoriteReducer = (state, action) => {
  const pageId = action.accountId
  const isFavorite = action.data?.is_favorite
  const isHidden = action.data?.is_hidden

  const changeInList = (listName, field, value) => {
    const index = state.pages[listName].findIndex((page) => page.id === pageId)
    if (index >= 0) {
      state = dotProp.set(state, `pages.${listName}.${index}.${field}`, value)
    }
  }

  switch (action.type) {
    case accountAtypes.ACCOUNT_FAVORITE_RESPONSE:
      changeInList('initial', 'isFavorite', isFavorite)
      changeInList('filtered', 'isFavorite', isFavorite)
      break
    case accountAtypes.ACCOUNT_HIDE_RESPONSE:
      changeInList('initial', 'isHidden', isHidden)
      changeInList('filtered', 'isHidden', isHidden)
  }

  return state
}

import isEqualWith from 'lodash/isEqualWith'

import { ITriggerRuleTrigger } from 'apps/triggerRules/triggerRulesInterfaces'

import createTrigger from './createTrigger'

const customizer = (
  a: string,
  b: string,
  key: string | number | symbol | undefined,
): boolean | undefined => {
  if (key === '_oid') {
    return true
  }
  return
}

export const isTriggerEmpty = (t: ITriggerRuleTrigger) =>
  isEqualWith(t, createTrigger(t.data.event_type), customizer)

import { TriggerEventType } from 'apps/triggerRules/models/TriggerRule/constants'
import { FsTriggerModel } from 'shared/api/requests/cms/schemas'

import { FiltersFromQuery } from './types'

export const isContactEventTrigger = (query: FiltersFromQuery) =>
  !query.channel && query.trigger_model === FsTriggerModel.CONTACT_EVENT && !query.widget_type

export const isAddTagContactEvent = (query: FiltersFromQuery) =>
  isContactEventTrigger(query) && query.event_type === TriggerEventType.TAG_ADDED

export const isDateTimeContactEvent = (query: FiltersFromQuery) =>
  isContactEventTrigger(query) && query.event_type === TriggerEventType.DATE_TIME

export const isLogConversationContactEvent = (query: FiltersFromQuery) =>
  isContactEventTrigger(query) && query.event_type === TriggerEventType.LOG_CONVERSION_EVENT

export const isRemoveTagContactEvent = (query: FiltersFromQuery) =>
  isContactEventTrigger(query) && query.event_type === TriggerEventType.TAG_REMOVED

export const isSequenceSubscribedContactEvent = (query: FiltersFromQuery) =>
  isContactEventTrigger(query) && query.event_type === TriggerEventType.SEQUENCE_SUBSCRIBED

export const isSequenceUnsubscribedContactEvent = (query: FiltersFromQuery) =>
  isContactEventTrigger(query) && query.event_type === TriggerEventType.SEQUENCE_UNSUBSCRIBED

export const isCufChangedContactEvent = (query: FiltersFromQuery) =>
  isContactEventTrigger(query) && query.event_type === TriggerEventType.CUF_CHANGED

export const isSufChangedContactEvent = (query: FiltersFromQuery) =>
  isContactEventTrigger(query) && query.event_type === TriggerEventType.SUF_CHANGED

export const isIntegrationFieldChangedContactEvent = (query: FiltersFromQuery) =>
  isContactEventTrigger(query) && query.event_type === TriggerEventType.INTEGRATION_FIELD_CHANGED

export const isBotSubscribedContactEvent = (query: FiltersFromQuery) =>
  isContactEventTrigger(query) && query.event_type === TriggerEventType.BOT_SUBSCRIBED

export const isTriggerMatchesContactEventFilter = ({
  filter,
  triggerEventType,
  triggerModel,
}: {
  filter: FiltersFromQuery
  triggerEventType: TriggerEventType | null
  triggerModel: FsTriggerModel
}) => {
  if (triggerModel !== FsTriggerModel.CONTACT_EVENT) {
    return false
  }

  return (
    (triggerEventType === TriggerEventType.TAG_ADDED && isAddTagContactEvent(filter)) ||
    (triggerEventType === TriggerEventType.DATE_TIME && isDateTimeContactEvent(filter)) ||
    (triggerEventType === TriggerEventType.LOG_CONVERSION_EVENT &&
      isLogConversationContactEvent(filter)) ||
    (triggerEventType === TriggerEventType.TAG_REMOVED && isRemoveTagContactEvent(filter)) ||
    (triggerEventType === TriggerEventType.SEQUENCE_SUBSCRIBED &&
      isSequenceSubscribedContactEvent(filter)) ||
    (triggerEventType === TriggerEventType.SEQUENCE_UNSUBSCRIBED &&
      isSequenceUnsubscribedContactEvent(filter)) ||
    (triggerEventType === TriggerEventType.CUF_CHANGED && isCufChangedContactEvent(filter)) ||
    (triggerEventType === TriggerEventType.SUF_CHANGED && isSufChangedContactEvent(filter)) ||
    (triggerEventType === TriggerEventType.INTEGRATION_FIELD_CHANGED &&
      isIntegrationFieldChangedContactEvent(filter)) ||
    (triggerEventType === TriggerEventType.BOT_SUBSCRIBED && isBotSubscribedContactEvent(filter))
  )
}

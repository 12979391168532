import { ChannelType } from 'common/core/constants/ChannelType'

export const FlowChannels = [
  ChannelType.FB,
  ChannelType.INSTAGRAM,
  ChannelType.SMS,
  ChannelType.EMAIL,
  ChannelType.WHATSAPP,
  ChannelType.TELEGRAM,
  ChannelType.TIKTOK,
] as const

export type FlowChannel = typeof FlowChannels[number]

export const QUESTIONS = [
  'type_account',
  'business_type',
  'business_type_e_commerce',
  'business_type_consulting',
  'business_type_public_figure',
  'business_type_local',
  'business_type_organisation_or_institution',
  'for_client',
  'business_platform_e_commerce',
  'channel_usage',
]

export enum QuestionnaireType {
  PAGE = 'page',
  USER = 'user',
}

export enum QuestionIdType {
  BUSINESS_PLATFORM_E_COMMERCE = 'business_platform_e_commerce',
  CHANNEL_USAGE = 'channel_usage',
  FOR_CLIENT = 'for_client',
  TYPE_ACCOUNT = 'type_account',
}

export enum AnswerIdType {
  FACEBOOK_CHANNEL_USAGE__I3 = 'facebook_channel_usage__i3',
  BUSINESS_PLATFORM_E_COMMERCE__I1 = 'business_platform_e_commerce__i1',
  FOR_CLIENT__I1 = 'for_client__i1',
  TYPE_ACCOUNT__I2 = 'type_account__i2',
}

export enum AccountQuestionnaireType {
  ACCOUNT_CREATION_QUESTIONNAIRE = 'account_creation_questionnaire',
}

export enum UserQuestionnaireType {
  USER_REGISTRATION_QUESTIONNAIRE = 'user_registration_questionnaire',
}

export enum UserQuestionTypes {
  COMPANY_TYPE = 'company_type',
  BUSINESS_TEAM = 'business__team',
  TEAM_MARKETING_YOUR_ROLE = 'team__marketing__your_role',
  TEAM_SALES_YOUR_ROLE = 'team__sales__your_role',
  TEAM_SUPPORT_YOUR_ROLE = 'team__support__your_role',
  TEAM_OTHER_YOUR_ROLE = 'team__other__your_role',
  BUSINESS_TEAM_SIZE = 'business__team_size',
  MARKETING_AGENCY_YOUR_ROLE = 'marketing_agency__your_role',
  MARKETING_AGENCY_SERVICES_TYPE = 'marketing_agency__services_type',
  MARKETING_AGENCY_TEAM_SIZE = 'marketing_agency__team_size',
  SOLOPRENEUR_BUSINESS_TYPE = 'solopreneur__business_type',
  OTHER_BUSINESS_TYPE = 'other__business_type',
  OTHER_YOUR_ROLE = 'other__your_role',
  OTHER_SIZE = 'other__size',
}

export const USER_QUESTIONS = [
  UserQuestionTypes.COMPANY_TYPE,
  UserQuestionTypes.BUSINESS_TEAM,
  UserQuestionTypes.TEAM_MARKETING_YOUR_ROLE,
  UserQuestionTypes.TEAM_SALES_YOUR_ROLE,
  UserQuestionTypes.TEAM_SUPPORT_YOUR_ROLE,
  UserQuestionTypes.TEAM_OTHER_YOUR_ROLE,
  UserQuestionTypes.BUSINESS_TEAM_SIZE,
  UserQuestionTypes.MARKETING_AGENCY_YOUR_ROLE,
  UserQuestionTypes.MARKETING_AGENCY_SERVICES_TYPE,
  UserQuestionTypes.MARKETING_AGENCY_TEAM_SIZE,
  UserQuestionTypes.SOLOPRENEUR_BUSINESS_TYPE,
  UserQuestionTypes.OTHER_BUSINESS_TYPE,
  UserQuestionTypes.OTHER_YOUR_ROLE,
  UserQuestionTypes.OTHER_SIZE,
]

import { z, ZodSchema } from 'zod'

import { BusinessErrorSchema } from 'shared/api/common/schemas/errors'

const makeGuardFromSchema =
  <Schema extends ZodSchema>(schema: Schema) =>
  (value: unknown): value is z.infer<Schema> =>
    schema.safeParse(value).success

const FilterItemValueNotSetErrorSchema = BusinessErrorSchema.extend({
  field: z.literal('filter'),
})

export const isFilterItemValueNotSetError = makeGuardFromSchema(FilterItemValueNotSetErrorSchema)

const WrongTimezoneValueErrorSchema = BusinessErrorSchema.extend({
  field: z.literal('timezone'),
})

export const isWrongTimezoneValueError = makeGuardFromSchema(WrongTimezoneValueErrorSchema)

const TagNotFoundErrorSchema = BusinessErrorSchema.extend({
  field: z.literal('tag_id'),
})

export const isTagNotFoundError = makeGuardFromSchema(TagNotFoundErrorSchema)

const UserIdNotFoundErrorSchema = BusinessErrorSchema.extend({
  field: z.literal('user_id'),
})

export const isUserIdNotFoundError = makeGuardFromSchema(UserIdNotFoundErrorSchema)

const ProAccountRequiredErrorSchema = BusinessErrorSchema.extend({
  field: z.literal('pro_account'),
})

export const isProAccountRequiredError = makeGuardFromSchema(ProAccountRequiredErrorSchema)

const WidgetNotFoundErrorSchema = BusinessErrorSchema.extend({
  field: z.literal('widget_id'),
})

export const isWidgetNotFoundError = makeGuardFromSchema(WidgetNotFoundErrorSchema)

const SegmentNotFoundErrorSchema = BusinessErrorSchema.extend({
  field: z.literal('segment'),
})

export const isSegmentNotFoundError = makeGuardFromSchema(SegmentNotFoundErrorSchema)

const SequenceNotFoundErrorSchema = BusinessErrorSchema.extend({
  field: z.literal('sequence'),
})

export const isSequenceNotFoundError = makeGuardFromSchema(SequenceNotFoundErrorSchema)

import React, { Component } from 'react'
import PropTypes from 'prop-types'

import { Input as AutoCompleteInput } from 'components/forms/Autocomplete'

class TagPicker extends Component {
  static propTypes = {
    className: PropTypes.string,
    value: PropTypes.any,
    tags: PropTypes.array,
    autoFocus: PropTypes.bool,
    invalid: PropTypes.bool,
    inline: PropTypes.bool,
    disabled: PropTypes.bool,
    forceNew: PropTypes.bool,
  }

  static defaultProps = {
    autoFocus: false,
    inline: false,
    disabled: false,
    forceNew: true,
    onFocus: () => {},
    onBlur: () => {},
  }

  constructor(props) {
    super(props)
    this.state = {
      createRequestId: null,
    }
  }

  handleRef = (ref) => {
    this.input = ref
    if (this.props.autoFocus) {
      this.focus()
    }
  }

  focus = () => {
    if (this.input) {
      this.input.focus()
    }
  }

  blur = () => {
    if (this.input) {
      this.input.blur()
    }
  }

  handleChange = (tag) => {
    this.props.onChange({
      target: {
        value: tag ? tag.tag_id : null,
        name: tag ? tag.tag_name : null,
      },
    })
  }

  handleSelect = (tag) => {
    this.handleChange(tag)
    this.blur()
  }

  /**
   * @deprecated
   * This is a legacy component that is used only in entities mapping
   * where creation is not available.
   */
  handleCreate = async () => {}

  render() {
    const {
      className,
      value,
      tags,
      invalid,
      inline,
      disabled,
      customTarget,
      theme,
      enableClear,
      forceNew,
    } = this.props

    const { createRequestId } = this.state
    const options = tags.filter((t) => t.active)
    const tag = tags.find((t) =>
      createRequestId ? t.requestId === createRequestId : t.tag_id === value,
    )
    const isCreating = createRequestId != null
    const labelNew = isCreating ? `+ Creating Tag '%value%'...` : `+ New Tag '%value%'`

    return (
      <AutoCompleteInput
        className={className}
        placeholder="Enter tag"
        disabled={isCreating || disabled}
        value={tag}
        invalid={invalid}
        inline={inline}
        useLayerForClickAway={false}
        forceNew={forceNew}
        options={options}
        customTarget={customTarget}
        theme={theme}
        labelEmpty="No tags found"
        enableClear={enableClear}
        labelKey="tag_name"
        labelNew={labelNew}
        onSelect={this.handleSelect}
        onCreate={this.handleCreate}
        allowNew={false}
        ref={this.handleRef}
        onFocus={this.props.onFocus}
        onBlur={this.props.onBlur}
        data-test-id="tag-picker"
      />
    )
  }
}

export default TagPicker

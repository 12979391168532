import { LayoutWithSidebar } from 'utils/router/components/LayoutWithSidebar/LayoutWithSidebar'

export const TriggerRulesRoutes = [
  {
    path: 'rules',
    Component: LayoutWithSidebar,
    children: [
      {
        path: ':rule_id',
        lazy: () => import('./ruleDetail.page'),
      },
    ],
  },
] as const

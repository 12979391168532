import React, { ReactElement, useCallback, useMemo } from 'react'
import cx from 'classnames'
import { useAppDispatch } from 'reduxTyped'
import { Autocomplete, BtnV2, l, CustomChangeEventType, SelectedOption } from '@manychat/manyui'

import { ChannelToRnListNameMap } from 'apps/dashboard/components/RnLearning/components/FirstRecurringNotificationOnboarding/Components/FirstRecurringNotificationModal/components/RecurringNotificationWizard/helpers'
import { getReasonTitleForDropdown } from 'common/builder/controllers/builder/ContentTypeSelector/ReasonPicker/Utils'
import { ChannelForRn } from 'common/core/constants/ChannelType'
import { reasonTypeLabels } from 'common/oneTimeNotify/constants/notifyReasonConstants'
import { IReasonPreparedForAutocomplete } from 'common/oneTimeNotify/selectors/notifyReasonSelectors'
import { toggleIsCreateNotificationView } from 'common/recurringNotifications/slice'
import { NotificationReason } from 'shared/api/requests/notificationReason/schemas'

import { InputWithTopicTypeRef } from './InputWithTopicType/InputWithTopicType'

import cm from './RecurringNotificationPicker.module.css'

export interface RecurringNotificationPickerProps {
  notificationReasons: NotificationReason[]
  notificationReasonPrepared: IReasonPreparedForAutocomplete[]
  onChange: (event: CustomChangeEventType<IReasonPreparedForAutocomplete, never>) => void
  value: number
  placeholder?: string
  className?: string
  disabled?: boolean
  allowCreate?: boolean
  customInputLayout?: ReactElement
  channel?: ChannelForRn
}

const RecurringNotificationPicker = ({
  notificationReasons = [],
  notificationReasonPrepared = [],
  onChange,
  value,
  className,
  disabled,
  placeholder,
  allowCreate = true,
  customInputLayout,
  channel,
}: RecurringNotificationPickerProps) => {
  const rnListName = channel ? ChannelToRnListNameMap[channel] : ''

  const placeholderText =
    placeholder || l.translate('Select or create {rnListName}', { rnListName })

  const getRenderOptions = useCallback(
    (option: SelectedOption<IReasonPreparedForAutocomplete, never>, label: React.ReactElement) => {
      const isType = 'reason' in option && Boolean(option.reason?.type)
      const type = isType ? reasonTypeLabels[option.reason.type] : ''

      return (
        <div className="row p-x p-y-xs">
          <div className={cx(cm.labelText, 'col')}>{label}</div>
          <div className="col-auto text-secondary">{type}</div>
        </div>
      )
    },
    [],
  )

  const getReasonTypeByValue = useMemo(
    () => (value: number) =>
      notificationReasonPrepared.find((reason) => reason.value === value)?.reason.type,
    [notificationReasonPrepared],
  )

  const dispatch = useAppDispatch()

  const handleToggleCreateNotificationView = useCallback(() => {
    dispatch(toggleIsCreateNotificationView())
  }, [dispatch])

  const handleCreateButtonClick = useCallback(() => {
    handleToggleCreateNotificationView()
  }, [handleToggleCreateNotificationView])

  const getValue = () => {
    const reason = notificationReasons.find((reason) => reason.reason_id === value)
    return reason ? getReasonTitleForDropdown(reason) : null
  }

  const customLastComponent = useMemo(
    () =>
      allowCreate
        ? () => (
            <BtnV2
              variant="ghost-primary"
              fullWidth
              className={cx(cm.stickyButton, 'p-sticky b-t')}
              onClick={handleCreateButtonClick}
              onMouseDown={(event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
                event.preventDefault()
              }}
            >
              {l.translate('+ Create')}
            </BtnV2>
          )
        : null,
    [allowCreate, handleCreateButtonClick],
  )

  return (
    <>
      <Autocomplete
        className={className}
        onChange={onChange}
        value={getValue()}
        placeholder={placeholderText}
        ariaLabel={placeholderText}
        options={notificationReasonPrepared}
        allowNew={false}
        renderOption={getRenderOptions}
        disabled={disabled}
        matchElement="span"
        customInput={
          customInputLayout || <InputWithTopicTypeRef reasonType={getReasonTypeByValue(value)} />
        }
        renderCustomLastComponent={customLastComponent}
      />
    </>
  )
}

export default RecurringNotificationPicker

import { z } from 'zod'

import { BillingActions } from 'common/billing/constants/BillingActions'
import { BillingCurrency } from 'common/billing/constants/BillingCurrency'
import { StripeCheckoutStatuses } from 'common/billing/constants/stripeCheckoutTypes'
import {
  AddOnPaymentType,
  AddOnPurchaseSchema,
  AddOnSlug,
  AddOnStatus,
} from 'common/billing/interfaces/addOnTypes'
import { CancellationReasonType } from 'common/billing/interfaces/cancellationReasons'
import { ProductType } from 'common/core/interfaces/products'
import { AccountStatus } from 'common/core/models/Account/constants'
import { CardErrorSchema, BillingCommonErrorSchema } from 'shared/api/common/schemas/errors'

import { checkoutAndConnectCardRequestSchema } from '../billingFlex/schemas'

import { AccountErrorSchema, CouponErrorSchema, InvalidEmailErrorSchema } from './errors'

const BillingSourceSchema = z.object({
  brand: z.string().nullable(),
  exp_month: z.number().nullable(),
  exp_year: z.number().nullable(),
  last4: z.string().nullable(),
})

export enum DiscountPeriod {
  DAYS = 'days',
  MONTHS = 'months',
  YEARS = 'years',
}

const DiscountPeriodSchema = z.nativeEnum(DiscountPeriod)

const ProProductSchema = z.enum([ProductType.AUTOMATION, ProductType.INBOX])

const CouponPeriodInfoSchema = z.object({
  period: z.number(),
  type: DiscountPeriodSchema,
  percentage_discount_amount_value: z.number(),
})

const CouponInfoSchema = CouponPeriodInfoSchema.extend({
  /**
   * Products to which the discount applies
   */
  products: z.array(ProProductSchema).optional(),
  /**
   * Add-ons to which the discount applies
   */
  addons: z.array(z.nativeEnum(AddOnSlug)).optional(),
})

const ProductPlanBase = z.object({
  active: z.boolean(),
  price: z.number(),
})

const AutomationPlanSchema = ProductPlanBase.extend({
  current_contacts_count: z.number(),
  plan_subscribers: z.string(),
  total_contacts_count: z.number(),
})

const InboxPlanSchema = ProductPlanBase.extend({
  current_paid_users_count: z.number(),
  total_paid_users_count: z.number(),
})

const CancellationReasonSchema = z.object({
  id: z.nativeEnum(CancellationReasonType),
  label: z.string(),
  parent: z.string().nullable(),
  children_popup: z.boolean().optional(),
})

const BillingPriceSchema = z.object({
  current: z.number(),
})

const BillingPriceInboxSchema = BillingPriceSchema.extend({
  prev: z.number(),
  next: z.number(),
})

const AddOnSchema = z.object({
  count: z.number().nonnegative(),
  is_enabled: z.boolean(),
  name: z.string().min(1),
  price: z.number().nonnegative(),
  full_price: z.number().nonnegative().optional(),
  schema: z.nativeEnum(AddOnPurchaseSchema),
  is_available_for_purchase: z.boolean(),
  slug: z.nativeEnum(AddOnSlug),
  status: z.nativeEnum(AddOnStatus),
  type: z.nativeEnum(AddOnPaymentType),
})

export const PriceSchema = z.object({
  amount: z.number().nonnegative(),
  currency: z.nativeEnum(BillingCurrency),
})
const PriceDataSchema = z.object({
  seat: PriceSchema,
  email: PriceSchema,
  sms: PriceSchema,
  wa: PriceSchema,
})

const ProductsSchema = z
  .object({
    [ProductType.AUTOMATION]: AutomationPlanSchema,
    [ProductType.INBOX]: InboxPlanSchema,
  })
  .optional()

export const BillingProSettingsSchema = z.object({
  account_id: z.string(),
  actual_plan: z.string().nullable(),
  billing_action: z.nativeEnum(BillingActions).optional(),
  billing_allow_cancel: z.boolean(),
  billing_allow_coupon: z.boolean(),
  billing_available: z.boolean(),
  billing_cancelling: z.boolean(),
  billing_email: z.string().nullable().optional(),
  billing_source: BillingSourceSchema.nullable().optional(),
  billing_trial_ends: z.string().nullable().optional(),
  billing_version: z.number(),
  /**
   * @deprecated
   * Contains inly period info w/o applied products
   * @todo https://youtrack.manychat.io/issue/BB-1409/Remove-deprecated-couponperiodinfo-field
   */
  coupon_period_info: CouponPeriodInfoSchema.nullable(),
  coupon_info: CouponInfoSchema.nullable(),
  coupon_redeemed: z.string().nullable(),
  grandfathering_version: z.number().nullable(),
  has_e_mandate: z.boolean(),
  is_grandfathering: z.boolean(),
  period_ends: z.string().nullable(),
  plan: z.string().nullable(),
  plan_period: z.string(),
  plan_price: z.number(),
  plan_subscribers: z.string(),
  price_data: PriceDataSchema,
  products: ProductsSchema,
  show_coupon: z.boolean(),
  status: z.nativeEnum(AccountStatus),
  addons_data: z.array(AddOnSchema),
})

export const BillingInfoSchema = z.object({
  card_country: z.string().nullable(),
  company_name: z.string().nullable(),
  details: z.string().nullable(),
  invoice_sending_enabled: z.boolean(),
  need_to_show_pay_now: z.boolean(),
})

export const InvoiceSchema = z.object({
  added: z.string(),
  amount: z.number(),
  currency: z.nativeEnum(BillingCurrency),
  id: z.number(),
  ts_added: z.number(),
})

export const ChargeSchema = z.object({
  amount: z.number(),
  charge_id: z.number(),
  created: z.string(),
  currency: z.nativeEnum(BillingCurrency),
  ts_created: z.number(),
  type: z.string(),
})

export const StripeCheckoutInfoSchema = z.object({
  error_message: z.string().optional().nullable(),
  is_upgrade_to_pro: z.boolean(),
  status: z.nativeEnum(StripeCheckoutStatuses),
  create_e_mandate_url: z.string().nullable(),
  is_need_create_e_mandate: z.boolean(),
})

export const BillingSchemas = {
  getNextActionRequiredInfo: {
    path: z.undefined(),
    query: z.object({
      stripe_hash: z.string(),
    }),
    request: z.object({
      state: z.boolean(),
    }),
    response: z.object({
      next_action_required_info: z
        .object({
          data: z.object({
            action_type: z.string(),
            client_secret: z.string(),
            public_key: z.string(),
          }),
          success_url: z.string(),
          cancel_url: z.string(),
          error_url: z.string(),
          status: z.string(),
        })
        .nullable(),
    }),
    errors: {
      BillingCommonError: BillingCommonErrorSchema,
    },
  },
  checkout: {
    path: z.undefined(),
    query: z.undefined(),
    request: checkoutAndConnectCardRequestSchema,
    response: z.object({
      redirect_url: z.string(),
    }),
  },
  applyCoupon: {
    path: z.undefined(),
    query: z.undefined(),
    request: z.object({
      coupon: z.string(),
    }),
    response: z.object({
      coupon_info: CouponInfoSchema,
      pro: BillingProSettingsSchema,
    }),
    errors: {
      CouponError: CouponErrorSchema,
      AccountError: AccountErrorSchema,
    },
  },
  resetCoupon: {
    path: z.undefined(),
    query: z.undefined(),
    request: z.undefined(),
    response: z.object({
      pro: BillingProSettingsSchema,
    }),
  },
  getProSettings: {
    path: z.undefined(),
    query: z.undefined(),
    request: z.undefined(),
    response: z.object({
      pro: BillingProSettingsSchema,
    }),
  },
  unsubscribe: {
    path: z.undefined(),
    query: z.undefined(),
    request: z.object({
      cancellation_data: z
        .object({
          ig_summer_promo_trial_disconnect: z.string().optional(),
        })
        .catchall(z.string())
        .superRefine((data, ctx) => {
          const keys = Object.keys(data)
          if (keys.length < 1) {
            ctx.addIssue({
              code: z.ZodIssueCode.custom,
              message: 'Should contain at least one parameter',
            })
          }

          const unrecognizedKeys = keys.filter((param) => {
            if (param === 'ig_summer_promo_trial_disconnect') {
              return false
            }

            const { success } = z.nativeEnum(CancellationReasonType).safeParse(param)

            return !success
          })

          if (unrecognizedKeys.length) {
            ctx.addIssue({
              code: z.ZodIssueCode.unrecognized_keys,
              keys: unrecognizedKeys,
              message:
                'Keys should be one of the CancellationReasonType or "ig_summer_promo_trial_disconnect"',
            })
          }
        }),
      cancel_immediately: z.boolean().default(false),
    }),
    response: z.object({
      pro: BillingProSettingsSchema,
    }),
  },
  backToPro: {
    path: z.undefined(),
    query: z.undefined(),
    request: z.undefined(),
    response: z.object({
      pro: BillingProSettingsSchema,
    }),
    errors: {
      BillingCommonError: BillingCommonErrorSchema,
    },
  },
  fetchCancellationReasonsList: {
    path: z.undefined(),
    query: z.undefined(),
    request: z.undefined(),
    response: z.object({
      reasons: z.array(CancellationReasonSchema),
    }),
  },
  cancelSubscriptionWithRefund: {
    path: z.undefined(),
    query: z.undefined(),
    request: z.undefined(),
    response: z.undefined(),
  },
  updateBillingEmail: {
    path: z.undefined(),
    query: z.undefined(),
    request: z.object({
      email: z.string(),
    }),
    response: z.undefined(),
    errors: {
      InvalidEmailError: InvalidEmailErrorSchema,
    },
  },
  getPrices: {
    path: z.undefined(),
    query: z.undefined(),
    request: z.undefined(),
    response: z.object({
      automation_price: BillingPriceSchema.optional(),
      inbox_price: BillingPriceInboxSchema.optional(),
    }),
  },
  switchProduct: {
    path: z.undefined(),
    query: z.undefined(),
    request: z.object({
      product: z.nativeEnum(ProductType),
      isEnable: z.boolean(),
    }),
    response: z.undefined(),
    errors: {
      BillingCommonError: BillingCommonErrorSchema,
    },
  },
  retryPayment: {
    path: z.undefined(),
    query: z.undefined(),
    request: z.undefined(),
    response: z.undefined(),
    errors: {
      CardError: CardErrorSchema,
    },
  },
  switchInvoicesSending: {
    path: z.undefined(),
    query: z.undefined(),
    request: z.object({
      invoice_sending_enabled: z.boolean(),
    }),
    response: BillingInfoSchema,
  },
  saveBillingInfo: {
    path: z.undefined(),
    query: z.undefined(),
    request: z.object({}),
    response: BillingInfoSchema,
  },
  listInvoices: {
    path: z.undefined(),
    query: z.undefined(),
    request: z.undefined(),
    response: z.object({
      invoices: z.array(InvoiceSchema),
    }),
  },
  listCharges: {
    path: z.undefined(),
    query: z.undefined(),
    request: z.undefined(),
    response: z.object({
      charges: z.array(ChargeSchema),
    }),
  },
  getCheckoutInfo: {
    path: z.undefined(),
    query: z.object({
      stripe_hash: z.string(),
    }),
    request: z.undefined(),
    response: z.object({
      info: StripeCheckoutInfoSchema.nullable(),
    }),
  },
}

export type BillingSource = z.infer<typeof BillingSourceSchema>
export type AutomationPlan = z.infer<typeof AutomationPlanSchema>
export type InboxPlan = z.infer<typeof InboxPlanSchema>
export type CouponInfo = z.infer<typeof CouponInfoSchema>
export type BillingProSettings = z.infer<typeof BillingProSettingsSchema>
export type BillingInfo = z.infer<typeof BillingInfoSchema>
export type Invoice = z.infer<typeof InvoiceSchema>
export type Charge = z.infer<typeof ChargeSchema>
export type StripeCheckoutInfo = z.infer<typeof StripeCheckoutInfoSchema>
export type AddOn = z.infer<typeof AddOnSchema>
export type CancellationReason = z.infer<typeof CancellationReasonSchema>
export type Price = z.infer<typeof PriceSchema>
export type PriceData = z.infer<typeof PriceDataSchema>
export type Products = z.infer<typeof ProductsSchema>

import { useCallback, useMemo } from 'react'
import { useAppDispatch, useAppSelector } from 'reduxTyped'

import { FieldGroupId } from 'common/fields/entities/enums'
import { FieldSelectEvent } from 'common/fields/linking/components/interfaces/fieldEvents'
import { getNumberedId } from 'common/tags/components/ConditionTagPicker/helpers'
import { makeTagField } from 'common/tags/components/TagPicker/helpers'
import { TagsActions, TagsSelectors } from 'common/tags/store'
import { Tag } from 'common/tags/type'
import { useDidMountEffect } from 'utils/commonHooks'

interface UseTagPickerProps {
  onChange: (event: { target: { value: number; name: string } }) => void
  value?: string
  excluded?: Array<Partial<Tag>>
}

export const useTagPicker = ({ onChange, value, excluded }: UseTagPickerProps) => {
  const dispatch = useAppDispatch()
  const isFetching = useAppSelector(TagsSelectors.getIsListLoading)

  const tags = useAppSelector(TagsSelectors.getList)

  const tagFields = useMemo(() => {
    const excludedTagIds = excluded?.map((excluded) => excluded.tag_id) ?? []
    const filteredTags = excludedTagIds.length
      ? tags?.filter((tag) => !excludedTagIds?.includes(tag.tag_id)) ?? []
      : tags
    return filteredTags.map(makeTagField)
  }, [tags, excluded])

  const handleChange = useCallback(
    ({ target: { field } }: FieldSelectEvent) => {
      const numberedTagId = getNumberedId(FieldGroupId.TAG, field?.id)
      const event = { target: { value: numberedTagId, name: field?.caption } }

      onChange(event)
    },
    [onChange],
  )

  const selectedValue = useMemo(() => {
    const numberedTagId = getNumberedId(FieldGroupId.TAG, value)
    const tagId = tags.find((tag) => tag.tag_id === numberedTagId)?.tag_id
    return tagId ? `tag_${tagId}` : ''
  }, [value, tags])

  useDidMountEffect(() => {
    dispatch(TagsActions.getListFx())
  })

  return { isFetching, tagFields, handleChange, selectedValue }
}

import React from 'react'
import { useAppSelector } from 'reduxTyped'
import { Icon } from '@manychat/manyui'

import { contentManagementSelectors } from 'apps/cms/store'

import cm from './FolderDragLayer.module.css'

interface FolderDragLayerProps {
  offset: {
    x: number
    y: number
  }
  path: string
}

export const FolderDragLayer = ({ path, offset }: FolderDragLayerProps) => {
  const folder = useAppSelector((state) => contentManagementSelectors.getFolderByPath(state, path))

  if (!folder) {
    return null
  }

  return (
    <div className={cm.card} style={{ transform: `translate(${offset.x}px, ${offset.y}px)` }}>
      <Icon.Folder className={cm.card__icon} />

      <span className="text-ellipsis">{folder.title}</span>
    </div>
  )
}

import React from 'react'
import { l } from '@manychat/manyui'

const WADisabledForInactivityNotification = () => {
  return (
    <span>
      {l.translate(
        'WhatsApp channel disabled due to inactivity. Please contact support to reconnect.',
      )}
    </span>
  )
}

export default WADisabledForInactivityNotification

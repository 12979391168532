import { IOnboardingState, OnboardingScope } from 'apps/instagramOnboarding/onboardingInterfaces'
import * as atypes from 'apps/instagramOnboarding/onboardingReduxActionTypes'
import { FetchStatus } from 'apps/sms/smsInterfaces'
import { IActionWithPayload } from 'common/core/interfaces/actions'

export const initialState: IOnboardingState = {
  completedOnboardings: { user: [], account: [] },
  completedOnboardingsFetchStatus: FetchStatus.NOT_FETCHED,
}

export default (
  state: IOnboardingState = initialState,
  action: IActionWithPayload,
): IOnboardingState => {
  switch (action.type) {
    case atypes.GET_COMPLETED_ONBOARDINGS_REQUEST: {
      return { ...state, completedOnboardingsFetchStatus: FetchStatus.FETCHING }
    }
    case atypes.GET_COMPLETED_ONBOARDINGS_RESPONSE: {
      return {
        ...state,
        completedOnboardings: {
          user: action.data.user_scoped,
          account: action.data.account_scoped,
        },
        completedOnboardingsFetchStatus: FetchStatus.FETCHED,
      }
    }

    case atypes.MARK_ONBOARDING_COMPLETED_RESPONSE: {
      const scope: OnboardingScope = action.scope
      if (state.completedOnboardings[scope].includes(action.onboardingId)) {
        return state
      }

      return {
        ...state,
        completedOnboardings: {
          ...state.completedOnboardings,
          [scope]: [...state.completedOnboardings[scope], action.onboardingId],
        },
      }
    }
  }

  return state
}

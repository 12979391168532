import { l } from '@manychat/manyui'
import { blocks, SerializedBlock } from '@manychat/paywall'

interface CommonProps {
  title?: string
}

export function common({
  title = l.translate('Unleash the full power of chat marketing'),
}: CommonProps): SerializedBlock {
  return blocks.ModalView.create({
    children: [
      blocks.Column.create({
        className: 'flex flex-col',
        children: [
          blocks.DefaultHeader.create({ text: title }),
          blocks.Row.create({
            children: [blocks.ProductList.create({}), blocks.OrderSummary.create({})],
            mobileWrap: true,
            style: { gridTemplateColumns: '2fr 1fr' },
          }),
          blocks.Box.create({
            className: 'o-auto',
            children: [blocks.ProductTableLegacy.create({})],
          }),
        ],
      }),
    ],
  })
}

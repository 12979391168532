import { generatePath, useParams, useSearchParams } from 'react-router-dom'

import { getAccountID } from 'utils/accountId'

import { MakeNavigatePropsArgs, TypedParams } from './types'

const BASE = location.origin

export function makeRoutePath<Path extends string, Query extends readonly string[]>({
  path,
  query,
}: {
  path: Path
  query?: Query
}) {
  type ParamArgs = MakeNavigatePropsArgs<Path, Query>
  const pathParts = path.split('#')
  const pathWithoutHash = pathParts[0] || ''
  const pathHash = pathParts[1] || ''

  if (path.startsWith('/')) {
    throw new Error(`Path '${path}' should not start with /`)
  }

  const getUrlObj = (...args: ParamArgs) => {
    const pathParams: TSFixMe = args[0] || {}

    if (pathWithoutHash.includes(':acc_id')) {
      pathParams.acc_id = pathParams.acc_id || getAccountID()
    }

    const pathname = `/${generatePath(pathWithoutHash, pathParams)}`
    const urlObj = new URL(pathname, BASE)

    if (query) {
      query.forEach((key) => {
        if (pathParams[key]) {
          urlObj.searchParams.append(key, pathParams[key])
        }
      })
    }

    const resultHash = pathHash || pathParams.hash
    if (resultHash) {
      urlObj.hash = resultHash
    }

    return urlObj
  }

  const getLink = (...args: ParamArgs) => {
    const urlObject = getUrlObj(...args)

    return `${urlObject.pathname}${urlObject.search}${urlObject.hash}`
  }

  const useTypedParams = (): TypedParams<Path, Query> => {
    const result: TSFixMe = { ...useParams() }
    const [searchParams] = useSearchParams()

    for (const key of Object.keys(result)) {
      if (!result[key] || !pathWithoutHash.includes(key)) {
        delete result[key]
      }
    }

    if (query) {
      query.forEach((key) => {
        const value = searchParams.get(key)

        if (value) {
          result[key] = value
        }
      })
    }

    const hash = window.location.hash

    if (hash) {
      result.hash = hash.replace('#', '')
    }

    return result
  }

  const pathWithSlash = pathWithoutHash.endsWith('/') ? pathWithoutHash : `${pathWithoutHash}/`
  const routePath = pathWithSlash + (pathHash ? `#${pathHash}` : '')

  return {
    path: routePath,
    getUrlObj,
    getLink,
    useTypedParams,
  }
}

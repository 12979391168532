import assert from 'assert'

import AnswerButtonConfig from 'common/builder/buttons/answer/AnswerButtonConfig'
import { IButton, IButtonConfig } from 'common/builder/buttons/buttonInterfaces'
import BuyButtonConfig from 'common/builder/buttons/buy/BuyButtonConfig'
import CallButtonConfig from 'common/builder/buttons/call/CallButtonConfig'
import ContentButtonConfig from 'common/builder/buttons/content/ContentButtonConfig'
import CrossChannelContentButtonConfig from 'common/builder/buttons/crossChannelContent/CrossChannelContentButtonConfig'
import CtaUrlButtonConfig from 'common/builder/buttons/ctaUrl/CtaUrlButtonConfig'
import FlowButtonConfig from 'common/builder/buttons/flow/FlowButtonConfig'
import WaListMessageButtonConfig from 'common/builder/buttons/listMessage/WaListMessageButtonConfig'
import ShareButtonConfig from 'common/builder/buttons/share/ShareButtonConfig'
import URLButtonConfig from 'common/builder/buttons/url/URLButtonConfig'
import { ButtonType } from 'common/builder/constants/ButtonType'

const configMap: Record<ButtonType, IButtonConfig> = {
  [ButtonType.ANSWER]: AnswerButtonConfig,
  [ButtonType.BUY]: BuyButtonConfig,
  [ButtonType.CALL]: CallButtonConfig,
  [ButtonType.CONTENT]: ContentButtonConfig,
  [ButtonType.FLOW]: FlowButtonConfig,
  [ButtonType.SHARE]: ShareButtonConfig,
  [ButtonType.URL]: URLButtonConfig,
  [ButtonType.CTA_URL]: CtaUrlButtonConfig,
  [ButtonType.WA_LIST_MESSAGE_BUTTON]: WaListMessageButtonConfig,
  [ButtonType.CROSS_CHANNEL_CONTENT]: CrossChannelContentButtonConfig,
}

const get = (button: IButton) => {
  assert(button, 'buttonRegistry.get: button is required')
  return getByType(button.type)
}

const getByType = (buttonType: ButtonType): IButtonConfig => {
  assert(buttonType, 'buttonRegistry.getByType: buttonType is required')
  assert(
    Object.values(ButtonType).includes(buttonType),
    `buttonRegistry: unknown buttonType ${buttonType}`,
  )

  return configMap[buttonType]
}

export default {
  get,
  getByType,
}

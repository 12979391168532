import { ChannelType } from 'common/core/constants/ChannelType'
import { settings } from 'constants/API'
import { Body, McAPI } from 'utils/api/types'

import {
  RemoveAccountData,
  RemoveChannelType,
  PrepareRemoveResponse,
} from './removeAccountConfirmationInterfaces'

const channelUrls: Record<RemoveChannelType, { prepare: string; confirm: string }> = {
  [ChannelType.TELEGRAM]: {
    prepare: settings.endpoints.prepareTgAccountRemove,
    confirm: settings.endpoints.confirmTgAccountRemove,
  },
  [ChannelType.INSTAGRAM]: {
    prepare: settings.endpoints.prepareIgAccountRemove,
    confirm: settings.endpoints.confirmIgAccountRemove,
  },
  [ChannelType.FB]: {
    prepare: settings.endpoints.prepareFbPageRemove,
    confirm: settings.endpoints.confirmFbPageRemove,
  },
  [ChannelType.TIKTOK]: {
    prepare: settings.endpoints.prepareTiktokAccountRemove,
    confirm: settings.endpoints.confirmTiktokAccountRemove,
  },
}

export const makeRemoveAccountConfirmationAPI = (api: McAPI) => ({
  async prepareAccountRemove(channel: RemoveChannelType): Promise<PrepareRemoveResponse> {
    try {
      return await api.get<RemoveAccountData & Body>(channelUrls[channel].prepare)
    } catch (e) {
      return {
        error: e ? (e as Error).message : undefined,
        state: false,
      }
    }
  },

  async confirmAccountRemove(sign: string, channel: RemoveChannelType) {
    try {
      return await api.post(channelUrls[channel].confirm, {
        body: {
          sign,
        },
      })
    } catch (e) {
      return {
        error: e ? (e as Error).message : undefined,
        state: false,
      }
    }
  },
})

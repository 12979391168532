import { l } from '@manychat/manyui'

import { ComparingProductsTableMap } from './interfaces'
import { TierListBtn } from './TierListBtn'

export const COMPARING_MAP: ComparingProductsTableMap[] = [
  {
    feature: l.makeTranslate('Fundamentals'),
    benefits: [
      {
        plan: l.makeTranslate('Contact Limit'),
        free: l.makeTranslate('Up to 1,000 contacts'),
        inbox: l.makeTranslate('Unlimited'),
        automation: TierListBtn,
      },
      {
        plan: l.makeTranslate('Team member seats'),
        free: l.makeTranslate('1 Team Member'),
        inbox: l.makeTranslate('Unlimited'),
        automation: l.makeTranslate('Unlimited'),
        altText: l.makeTranslate(
          'Team member types include account admins, Live Chat agents, editors, and viewers ',
        ),
      },
      {
        plan: l.makeTranslate('Growth Tools limit'),
        free: l.makeTranslate('4 Growth Tools'),
        inbox: l.makeTranslate('4 Growth Tools'),
        automation: l.makeTranslate('Unlimited'),
      },
    ],
  },
  {
    feature: l.makeTranslate('Live Chat'),
    benefits: [
      {
        plan: l.makeTranslate('Live Chat Access'),
        free: l.makeTranslate('1 Live Chat seat'),
        inbox: l.makeTranslate('Unlimited'),
        automation: l.makeTranslate('3 Live Chat seats'),
      },
      {
        plan: l.makeTranslate('Conversations Auto-Assignment'),
        free: () => '-',
        inbox: () => '+',
        automation: () => '-',
      },
      {
        plan: l.makeTranslate('Snippets'),
        free: l.makeTranslate('5 snippets'),
        inbox: l.makeTranslate('Unlimited'),
        automation: l.makeTranslate('5 snippets'),
      },
      {
        plan: l.makeTranslate('Scheduled Messages'),
        free: () => '-',
        inbox: () => '+',
        automation: () => '+',
      },
      {
        plan: l.makeTranslate('Send Automation'),
        free: () => '-',
        inbox: () => '+',
        automation: () => '+',
      },
      {
        plan: l.makeTranslate('Buy button'),
        free: () => '-',
        inbox: () => '+',
        automation: () => '-',
      },
    ],
  },
  {
    feature: l.makeTranslate('Contacts Management'),
    benefits: [
      {
        plan: l.makeTranslate('Import'),
        free: () => '-',
        inbox: () => '+',
        automation: () => '+',
      },
      {
        plan: l.makeTranslate('Contacts segmentation'),
        free: () => '-',
        inbox: () => '+',
        automation: () => '+',
      },
      {
        plan: l.makeTranslate('Customer Tags'),
        free: l.makeTranslate('10 Tags'),
        inbox: l.makeTranslate('Unlimited'),
        automation: l.makeTranslate('Unlimited'),
        altText: l.makeTranslate('Use Tags to mark, organize, and segment your contacts'),
      },
      {
        plan: l.makeTranslate('User Fields'),
        free: l.makeTranslate('3 User Fields'),
        inbox: l.makeTranslate('Unlimited'),
        automation: l.makeTranslate('Unlimited'),
      },
      {
        plan: l.makeTranslate('Merge Contacts'),
        free: () => '-',
        inbox: () => '+',
        automation: () => '+',
      },
      {
        plan: l.makeTranslate('Export PSID for Messenger contacts'),
        free: () => '-',
        inbox: () => '+',
        automation: () => '+',
      },
    ],
  },
  {
    feature: l.makeTranslate('Automation tools'),
    benefits: [
      {
        plan: l.makeTranslate('Custom Keyword triggers'),
        free: l.makeTranslate('3 Keywords'),
        inbox: l.makeTranslate('3 Keywords'),
        automation: l.makeTranslate('Unlimited'),
      },
      {
        plan: l.makeTranslate('Automated message drip sequences'),
        free: l.makeTranslate('2 Sequences'),
        inbox: l.makeTranslate('2 Sequences'),
        automation: l.makeTranslate('Unlimited'),
      },
      {
        plan: l.makeTranslate('A/B Testing'),
        free: () => '-',
        inbox: () => '-',
        automation: () => '+',
      },
      {
        plan: l.makeTranslate('User Input'),
        free: () => '-',
        inbox: () => '-',
        automation: () => '+',
      },
      {
        plan: l.makeTranslate('Smart Delay'),
        free: () => '-',
        inbox: () => '-',
        automation: () => '+',
      },
      {
        plan: l.makeTranslate('Conditions'),
        free: () => '-',
        inbox: () => '-',
        automation: () => '+',
      },
      {
        plan: l.makeTranslate('Custom Rules'),
        free: () => '-',
        inbox: () => '-',
        automation: l.makeTranslate('Unlimited'),
      },
      {
        plan: l.makeTranslate('Manychat branding on Email, SMS, and website widgets'),
        free: l.makeTranslate('"Powered by Manychat"'),
        inbox: l.makeTranslate('No branding'),
        automation: l.makeTranslate('No branding'),
      },
      {
        plan: l.makeTranslate('Admin Notifications'),
        free: () => '-',
        inbox: () => '+',
        automation: () => '+',
      },
      {
        plan: l.makeTranslate('Notify Admin Action'),
        free: () => '-',
        inbox: () => '-',
        automation: () => '+',
      },
      {
        plan: l.makeTranslate('Dynamic Menus'),
        free: () => '-',
        inbox: () => '-',
        automation: l.makeTranslate('Unlimited'),
      },
      {
        plan: l.makeTranslate('URL shortener disable'),
        free: () => '-',
        inbox: () => '+',
        automation: () => '+',
      },
    ],
  },
  {
    feature: l.makeTranslate('Engage your Contacts across all channels'),
    benefits: [
      {
        plan: l.makeTranslate('Instagram Direct Message Channel'),
        free: () => '',
        inbox: () => '',
        automation: () => '',
        isSubtitle: true,
      },
      {
        plan: l.makeTranslate('Instagram Comments'),
        free: () => '+',
        inbox: () => '+',
        automation: () => '+',
        altText: l.makeTranslate(
          'Automatically reply to users who comment on your Instagram posts',
        ),
      },
      {
        plan: l.makeTranslate('Story Mentions Reply'),
        free: () => '+',
        inbox: () => '+',
        automation: () => '+',
      },
      {
        plan: l.makeTranslate('Conversation Starters'),
        free: () => '+',
        inbox: () => '+',
        automation: () => '+',
      },
      {
        plan: l.makeTranslate('Direct Message Default Reply'),
        free: () => '+',
        inbox: () => '+',
        automation: () => '+',
      },
      {
        plan: l.makeTranslate('Customizable Default Reply'),
        free: () => '-',
        inbox: () => '-',
        automation: () => '+',
      },
      {
        plan: l.makeTranslate('Facebook Messenger Channel'),
        free: () => '',
        inbox: () => '',
        automation: () => '',
        isSubtitle: true,
      },
      {
        plan: l.makeTranslate('Facebook Comments'),
        free: () => '+',
        inbox: () => '+',
        automation: () => '+',
        altText: l.makeTranslate('Automatically reply to users who comment on your Facebook posts'),
      },
      {
        plan: l.makeTranslate('Ref URL'),
        free: () => '+',
        inbox: () => '+',
        automation: () => '+',
        altText: l.makeTranslate(
          'Generate a simple URL that can be used anywhere to bring traffic to your Messenger bot',
        ),
      },
      {
        plan: l.makeTranslate('QR Code'),
        free: () => '+',
        inbox: () => '+',
        automation: () => '+',
        altText: l.makeTranslate(
          'Create scannable QR codes that can be included on physical or printed assets to drive customers to your bot',
        ),
      },
      {
        plan: l.makeTranslate('Click-to-Messenger Ads'),
        free: () => '-',
        inbox: () => '-',
        automation: () => '+',
        altText: l.makeTranslate(
          'Start a customer conversation with your bot for leads that click on a Facebook ad',
        ),
      },
      {
        plan: l.makeTranslate('Facebook Message Tags'),
        free: () => '+',
        inbox: () => '+',
        automation: () => '+',
        altText: l.makeTranslate("Send messages outside of Facebook's 24-hour rule"),
      },
      {
        plan: l.makeTranslate('Messenger Paid Messages'),
        free: () => '-',
        inbox: () => '-',
        automation: () => '+',
        altText: l.makeTranslate(
          "Send paid messages through Messenger outside of Facebook's 24 hour messaging rule",
        ),
      },
      {
        plan: l.makeTranslate('TikTok'),
        free: () => '+',
        inbox: () => '+',
        automation: () => '+',
        isSubtitle: true,
      },
      {
        plan: l.makeTranslate('WhatsApp'),
        free: () => '-',
        inbox: () => '+',
        automation: () => '+',
        isSubtitle: true,
      },
      {
        plan: l.makeTranslate('Telegram'),
        free: () => '+',
        inbox: () => '+',
        automation: () => '+',
        isSubtitle: true,
      },
      {
        plan: l.makeTranslate('Email'),
        free: () => '-',
        inbox: () => '+',
        automation: () => '+',
        isSubtitle: true,
      },
      {
        plan: l.makeTranslate('SMS'),
        free: () => '-',
        inbox: () => '+',
        automation: () => '+',
        isSubtitle: true,
      },
    ],
  },
  {
    feature: l.makeTranslate('Connect to your favorite Integrations'),
    benefits: [
      {
        plan: l.makeTranslate('Google Sheets'),
        free: () => '-',
        inbox: () => '-',
        automation: () => '+',
      },
      {
        plan: l.makeTranslate('Klaviyo'),
        free: () => '-',
        inbox: () => '-',
        automation: () => '+',
      },
      {
        plan: l.makeTranslate('ActiveCampaign'),
        free: () => '-',
        inbox: () => '-',
        automation: () => '+',
      },
      {
        plan: l.makeTranslate('MailChimp'),
        free: () => '-',
        inbox: () => '-',
        automation: () => '+',
      },
      {
        plan: l.makeTranslate('HubSpot'),
        free: () => '-',
        inbox: () => '-',
        automation: () => '+',
      },
      {
        plan: l.makeTranslate('ConvertKit'),
        free: () => '-',
        inbox: () => '-',
        automation: () => '+',
      },
      {
        plan: l.makeTranslate("'BUY' buttons in Messenger, Instagram DM"),
        free: () => '-',
        inbox: () => '+',
        automation: () => '+',
      },
      {
        plan: l.makeTranslate('Unlimited Access to Manychat API'),
        free: () => '-',
        inbox: () => '+',
        automation: () => '+',
      },
    ],
  },
  {
    feature: l.makeTranslate('Measure and track performance with Analytics Tools'),
    benefits: [
      {
        plan: l.makeTranslate('Log conversion events and metrics'),
        free: () => '-',
        inbox: () => '-',
        automation: () => '+',
      },
      {
        plan: l.makeTranslate('Use Manychat Pixel to trigger conversion events'),
        free: () => '-',
        inbox: () => '-',
        automation: () => '+',
      },
    ],
  },
]

export const TABLE_COLUMNS_COUNT = 4

import { createSlice, createAction } from '@reduxjs/toolkit'

import { initialState } from 'common/core/reducers/constants'
import { UserInactivityDeletionApi } from 'shared/api/requests/userInactivityDeletion'
import { UserInactivityResponseType } from 'shared/api/requests/userInactivityDeletion/schemas'
import { createAsyncAction } from 'shared/lib/redux'

export const setConfirmedUserActivity = createAsyncAction<UserInactivityResponseType | null>(
  'user/confirmUserActivityProcess',
  async () => {
    const result = await UserInactivityDeletionApi.confirmActivity()
    return result.data
  },
  {
    // this technical handle that informs only about success script of recovery activity account
    onError: () => true,
  },
)

export const setShowedStatus = createAction(`user/confirmUserActivityStatus`)

export const confirmUserActivitySlice = createSlice({
  name: 'confirmedUserActivity',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(setConfirmedUserActivity.fulfilled, (state, action) => {
      state.was_in_process = action.payload?.was_in_process ?? null
    })

    builder.addCase(setShowedStatus, (state) => {
      state.isShowed = true
    })
  },
})

export const { reducer } = confirmUserActivitySlice

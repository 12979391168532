import dot from 'dot-prop-immutable'
import get from 'lodash/get'
import sortBy from 'lodash/sortBy'

import * as atypes from 'apps/templates/constants/ReduxActionTypes'
import { getDependentBotFields } from 'apps/templates/models/ContentMap/utils'
import { getIsProtected, getIsAvailable } from 'apps/templates/selectors/installedTemplateSelectors'
import { FSOAdapter } from 'common/cms/models/FSObject/adapter'

import contentMap from './contentMap'

const initialState = {
  name: '',
  avatar: {},
  pageId: null,
  selection: {},
  botFields: [],
  botFieldsSettings: {},
  map: undefined,
  folders: null,
  isProtected: false,
  isLocked: true,
  isTracked: false,
  template: null,
  flowsLockedStatus: undefined,
}

const writableProps = [
  'name',
  'avatar',
  'isProtected',
  'isLocked',
  'isTracked',
  'flowsLockedStatus',
]

export default function templateDraft(state = initialState, action) {
  let currentSelection = state.selection

  switch (action.type) {
    case atypes.TEMPLATE_DRAFT_INIT:
      {
        const {
          pageId,
          selection,
          name,
          settingsFields,
          isProtected,
          isLocked,
          isTracked,
          flowsLockedStatus,
        } = action
        const fields = settingsFields || []
        // Filling botFields to keep order, see botFields sorting below
        const botFields = fields.map((f) => ({ field_id: f.field_id }))
        const botFieldsSettings = fields.reduce(
          (map, f) => ((map[f.field_id] = { ...f, override: !!(f.caption || f.description) }), map),
          {},
        )
        state = {
          ...initialState,
          pageId,
          selection: selection || {},
          name: name || '',
          botFields,
          botFieldsSettings,
          isProtected,
          isLocked,
          isTracked,
          flowsLockedStatus,
        }
      }
      break
    case atypes.TEMPLATE_DRAFT_UPDATE:
      {
        const { property, value } = action
        if (!writableProps.includes(property)) {
          throw new Error(`Template draft reducer: cannot update property: "${property}"`)
        }

        state = dot.set(state, property, value)
      }
      break
    case atypes.TEMPLATE_DRAFT_SELECTION_SET:
      {
        const selection = { ...state.selection }
        const { id } = action
        if (Array.isArray(id)) {
          id.forEach((id) => (selection[id] = action.isSelected))
        } else {
          selection[action.id] = action.isSelected
        }
        state = { ...state, selection }
      }
      break
    case atypes.TEMPLATE_DRAFT_SELECTION_ALL:
      {
        let items = get(state, 'map.item.items', [])
        const templateInstallations = get(state, 'map.item.models.template_installations', [])

        if (action.modelId) {
          items = items.filter((item) => item.model_id === action.modelId)
        }

        const itemsWithoutProtected = items.filter((item) => {
          return (
            !getIsProtected(templateInstallations, item.template_installation_id) ||
            getIsAvailable(templateInstallations, item.template_installation_id, action.userId)
          )
        })
        const selection = itemsWithoutProtected.reduce(
          (map, item) => ((map[item.id] = true), map),
          {
            ...state.selection,
          },
        )
        state = { ...state, selection }
      }
      break
    case atypes.TEMPLATE_DRAFT_SELECTION_CLEAR:
      {
        let selection = {}
        if (action.modelId) {
          const itemsMap = get(state, 'map.item.byId', {})
          selection = Object.keys(state.selection)
            .filter((id) => itemsMap[id].model_id !== action.modelId)
            .reduce((map, id) => ((map[id] = true), map), {})
        }
        state = { ...state, selection }
      }
      break
    case atypes.TEMPLATE_DRAFT_BOT_FIELD_UPDATE:
      {
        const { id, data } = action
        state = dot.merge(state, `botFieldsSettings.${id}`, data)
      }
      break
    case atypes.TEMPLATE_DRAFT_BOT_FIELDS_SORT:
      {
        const { sourceId, targetId } = action
        const botFields = [...state.botFields]
        const sourceIndex = botFields.findIndex((f) => f.field_id === sourceId)
        const targetIndex = botFields.findIndex((f) => f.field_id === targetId)
        const source = botFields[sourceIndex]
        botFields[sourceIndex] = botFields[targetIndex]
        botFields[targetIndex] = source
        state = { ...state, botFields }
      }
      break

    case atypes.TEMPLATES_CURRENT_SAVECONTENT_REQUEST:
    case atypes.TEMPLATES_CREATE_REQUEST:
      state = { ...state, fetching: true }
      break
    case atypes.TEMPLATE_SET_SHARE_TYPE_RESPONSE:
    case atypes.TEMPLATES_CURRENT_SAVECONTENT_RESPONSE:
    case atypes.TEMPLATES_CREATE_RESPONSE:
      state = { ...state, fetching: false, template: action.data.data }
      break
    case atypes.TEMPLATE_FECTH_SINGLEUSE_LINK_REQUEST:
      state = {
        ...state,
        template: { ...state.template, isGeneratingLink: true },
      }
      break
    case atypes.TEMPLATE_FECTH_SINGLEUSE_LINK_RESPONSE:
      state = {
        ...state,
        template: { ...state.template, ...action.data.data, isGeneratingLink: false },
      }
      break
    case atypes.TEMPLATES_CURRENT_SAVECONTENT_ERROR:
    case atypes.TEMPLATES_CREATE_ERROR:
      state = { ...state, fetching: false }
      break
    case atypes.CONTENTMAP_CURRENT_GETFOLDERS_RESPONSE: {
      const folders = get(action, 'data.folders', [])
      const items = folders.map(FSOAdapter)
      state = { ...state, folders: items }
      break
    }
  }

  const map = contentMap(state.map, action)
  const isMapChanged = map !== state.map
  const isSelectionChanged = currentSelection !== state.selection

  if (isMapChanged) {
    state = { ...state, map }
  }

  // Filtering selection
  if (isMapChanged || isSelectionChanged) {
    const itemsMap = get(state, 'map.item.byId')
    const selection = Object.keys(state.selection)
      .filter(
        (id) =>
          state.selection[id] && (itemsMap == null || (itemsMap[id] && !itemsMap[id].is_corrupted)),
      )
      .reduce((map, id) => ((map[id] = true), map), {})

    state = { ...state, selection }

    if (map.item) {
      // Filtering settings fields selection
      const dependentFields = getDependentBotFields(map.item, selection)
      const botFields = sortBy(dependentFields, (field) =>
        state.botFields.findIndex((f) => f.field_id === field.field_id),
      ).map((f) => ({ ...f }))

      state = { ...state, botFields }
    }
  }

  return state
}

import React from 'react'
import cx from 'classnames'
import { l } from '@manychat/manyui'

import cm from './FallbackContent.module.css'

interface Props {
  title?: string
  firstContentText?: string
  secondContentText?: string
  hasPaddingIndent?: boolean
  hasMarginIndent?: boolean
  children?: React.ReactElement
}

const InitialTitle = l.translate('Ooops, something went wrong!')

export const FallbackContent = ({
  title = InitialTitle,
  firstContentText,
  secondContentText,
  hasPaddingIndent = false,
  hasMarginIndent = false,
  children,
}: Props) => {
  return (
    <div
      className={cx('d-flex flex-col align-center', {
        'm-t-xl': hasMarginIndent,
        'p-a': hasPaddingIndent,
      })}
      data-test-id="fallback"
    >
      <div className={cm.errorImage} />
      <div className="text-center">
        <span className="text-display-sm">{title}</span>
        {firstContentText && <p className="text-secondary">{firstContentText}</p>}
        {secondContentText && <p className="text-secondary">{secondContentText}</p>}
      </div>
      {children}
    </div>
  )
}

export const SM_LOAD_AD_ACCOUNTS = 'SM_LOAD_AD_ACCOUNTS'
export const SM_GET_AD_ACCOUNT_CURRENCY = 'SM_GET_AD_ACCOUNT_CURRENCY'
export const SM_SELECT_AD_ACCOUNT = 'SM_SELECT_AD_ACCOUNT'

export const SM_CREATE_AD_CAMPAIGN = 'SM_CREATE_AD_CAMPAIGN'
export const SM_LOAD_AD_CAMPAIGNS = 'SM_LOAD_AD_CAMPAIGNS'

export const SM_CREATE_AD_SET = 'SM_CREATE_AD_SET'
export const SM_LOAD_AD_SETS = 'SM_LOAD_AD_SETS'
export const SM_PUBLISH_AD_SET = 'SM_PUBLISH_AD_SET'
export const SM_UPDATE_AD_SET = 'SM_UPDATE_AD_SET'
export const SM_FETCH_AD_SET_DRAFT = 'SM_FETCH_AD_SET_DRAFT'
export const SM_FETCH_AD_SET = 'SM_FETCH_AD_SET'
export const SM_CREATE_CUSTOM_AUDIENCE = 'SM_CREATE_CUSTOM_AUDIENCE'
export const SM_GET_CUSTOM_AUDIENCE = 'SM_GET_CUSTOM_AUDIENCE'
export const SM_DELETE_CUSTOM_AUDIENCE = 'SM_DELETE_CUSTOM_AUDIENCE'
export const ADD_USERS_TO_CUSTOM_AUDIENCE = 'ADD_USERS_TO_CUSTOM_AUDIENCE'

export const SM_CREATE_AD = 'SM_CREATE_AD'
export const SM_FETCH_AD_DRAFT = 'SM_FETCH_AD_DRAFT'
export const SM_SAVE_AD_DRAFT = 'SM_SAVE_AD_DRAFT'
export const SM_UPDATE_AD_NAME = 'SM_UPDATE_AD_NAME'

export const SM_INIT_BUILDER = 'SM_INIT_BUILDER'
export const SM_START_AUTO_SAVE = 'SM_START_AUTO_SAVE'
export const SM_ERROR_AUTO_SAVE = 'SM_ERROR_AUTO_SAVE'
export const SM_STOP_AUTO_SAVE = 'SM_STOP_AUTO_SAVE'

export const SM_LOAD_TABLE_DATA = 'SM_LOAD_TABLE_DATA'
export const SM_LOAD_TABLE_DATA_START = 'SM_LOAD_TABLE_DATA_START'
export const SM_LOAD_TABLE_DATA_STOP = 'SM_LOAD_TABLE_DATA_STOP'

export const SM_CREATE_SUCCESS = 'SM_CREATE_SUCCESS'
export const SM_CREATE_FINISH = 'SM_CREATE_FINISH'
export const SM_START = 'SM_START'
export const SM_STOP = 'SM_STOP'

export const SM_FETCH_TOTAL_SUBSCRIBERS = 'SM_FETCH_TOTAL_SUBSCRIBERS'
export const SM_FETCH_TOTAL_SUBSCRIBERS_RESPONSE = 'SM_FETCH_TOTAL_SUBSCRIBERS_RESPONSE'

import { l } from '@manychat/manyui'

import { KeywordRule } from 'apps/keywords/keywordsInterfaces'
import { ConditionTypes, ConditionTypeLabels } from 'apps/keywords/models/KeywordRule/constants'

export const getKeywordPreviewNodes = (
  keywordRules: KeywordRule[],
): (string[] | (() => string))[] => {
  const knownConditions: Partial<Record<ConditionTypes, number>> = {}
  const nodes: (string[] | (() => string))[][] = []

  let lastUsedIndex = -1

  keywordRules.forEach((rule) => {
    const knownIndex = knownConditions[rule.condition]

    if (knownIndex !== undefined) {
      nodes[knownIndex].push(l.makeTranslate('and'), rule.keywords)
    } else {
      const index = ++lastUsedIndex

      knownConditions[rule.condition] = index
      const value =
        rule.condition === ConditionTypes.THUMBS_UP
          ? [ConditionTypeLabels[rule.condition]]
          : [ConditionTypeLabels[rule.condition], rule.keywords]

      const node = index === 0 ? value : [l.makeTranslate('and'), ...value]
      nodes.push(node)
    }
  })

  return nodes.reduce<(string[] | (() => string))[]>(
    (accumulator, condNodes) => [...accumulator, ...condNodes],
    [],
  )
}

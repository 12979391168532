import React, { Suspense } from 'react'

import { safeReactLazy } from 'utils/safeReactLazy'

const StripeCheckoutStatusAlerts = safeReactLazy(
  () => import('common/billing/components/StripeCheckoutStatusAlerts'),
)
const AsyncStripeClientCredentialsView = safeReactLazy(
  () => import('common/billing/components/AsyncStripeClientCredentialsView'),
)
const TaxNumberForm = safeReactLazy(() => import('common/billing/components/TaxNumberForm'))

export const BillingModals = () => {
  return (
    <Suspense>
      <StripeCheckoutStatusAlerts />
      <AsyncStripeClientCredentialsView />
      <TaxNumberForm />
    </Suspense>
  )
}

import React, { ComponentType, useEffect } from 'react'

interface Options {
  /** name of ecommended component for replacement */
  replacementComponentName?: string
  /** custom message with further instructios or details */
  message?: string
}

export const deprecatedDecorator = <C extends ComponentType>(
  Component: C,
  options: Options = {},
): C => {
  const DeprecatedComponent = React.forwardRef<C, React.ComponentPropsWithoutRef<C>>(
    (props, ref) => {
      useEffect(() => {
        const displayName = Component.displayName ?? Component.name
        if (process.env.NODE_ENV === 'development') {
          console.warn(
            `${displayName} component is deprecated. ${
              options.replacementComponentName
                ? `Please use ${options.replacementComponentName} instead.`
                : ''
            } ${options.message ? options.message : ''}`,
          )
        }
      }, [])

      return (
        <Component
          {...(props as JSX.IntrinsicAttributes & JSX.LibraryManagedAttributes<C, object>)}
          ref={ref}
        />
      )
    },
  )
  DeprecatedComponent.displayName = 'DeprecatedComponent'

  return DeprecatedComponent as unknown as C
}

import { INode } from 'common/builder/nodes/nodeInterfaces'
import {
  getNodesMap,
  getButtonById,
  getBlocks,
} from 'common/builder/selectors/builder/entitySelectors'
import errorTrackingService from 'utils/services/errorTrackingService'
/**
 * Returns a map where keys are source node ids and values are target nodes.
 *
 * Example:
 * ```
 * Condition -> Node1 -> Node3
 *           -> Node2 -> Node3
 * ```
 *
 * For this case the method returns:
 * ```ts
 * {
 *   [Condition.id]: [Node1, Node2],
 *   [Node1.id]: [Node3],
 *   [Node2.id]: [Node3],
 * }
 * ```
 */
export const getConnectedNodesMap = (
  state: RootState,
  builderId: string,
  nodeId: string,
  options: { map?: Record<string, INode>; allNodesMap?: Record<string, INode> } = {},
): Record<string, INode> => {
  // Cause 'getById' is slow selector, creating reusable nodes map only ones

  const { map = {} } = options
  const allNodesMap = options.allNodesMap || getNodesMap(state, builderId)

  const tryPushNode = (nodeId: string | null) => {
    if (!nodeId || map[nodeId]) {
      return
    }
    if (!allNodesMap[nodeId]) {
      errorTrackingService.trackWarningOnce(`Node not found: ${nodeId}`, {
        extra: { builderId, nodeId, allNodesMap },
        fingerprint: 'node-not-found',
        tags: { area: 'builder', topic: "id doesn't exist" },
      })
      return console.warn(`[getConnectedNodesMap] node not found: ${nodeId}`)
    }

    map[nodeId] = allNodesMap[nodeId]
    getConnectedNodesMap(state, builderId, nodeId, { map, allNodesMap })
  }

  // Adding node itself
  tryPushNode(nodeId)

  const node = allNodesMap[nodeId]

  if (!node) return map

  // Adding node target (ActionGroups, content chaining in future)
  if ('targetId' in node) {
    tryPushNode(node.targetId)
  }

  if (node.blocks) {
    const blocks = getBlocks(state, builderId, nodeId, { includeNested: true })
    for (const block of blocks) {
      // Adding block target (UserInput block or blocks in condition/split)
      if ('targetId' in block) {
        tryPushNode(block.targetId)
      }

      if (block.buttons) {
        for (const buttonId of block.buttons) {
          const btn = getButtonById(state, builderId, buttonId)
          // Adding button target (All buttons, include quick replies)
          if (btn && 'targetId' in btn) {
            tryPushNode(btn.targetId)
          }
        }
      }
    }
  }

  return map
}

import { IntegrationType } from 'common/actions/integrations/common/constants/IntegrationType'
import { IFieldInterface } from 'common/settings/components/SettingsPanel/CustomFieldsSection/FieldInterface'
import { GlobalField } from 'shared/api/requests/globalFields/schemas'
import { FlodeskSegment } from 'shared/api/requests/integrations/flodesk/schemas'

export enum FlodeskAction {
  CREATE_SUBSCRIBER = 'create_subscriber',
}

export interface ActionCreateFlodeskSubscriber {
  type: FlodeskAction.CREATE_SUBSCRIBER
  data?: {
    binding: Array<{ field_name: string; value: { type: 'cuf' | 'suf'; field_name: string } }>
    segments: FlodeskSegment[]
    mapping: []
    identity: []
  }
  id: string
  integration: IntegrationType.FLODESK
  _oid: string
}

export interface IntegrationField {
  target: { field: IFieldInterface | GlobalField; name: string | undefined; value: string }
}

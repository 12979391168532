import { l } from '@manychat/manyui'

import { AttachmentBlockAttachmentType } from 'common/builder/blocks/blockConstants'
import { BlockType } from 'common/builder/constants/BlockType'
import { ButtonType } from 'common/builder/constants/ButtonType'
import { NodeType } from 'common/builder/constants/NodeType'

export const BlockTypeLabels: Partial<Record<BlockType, () => string>> = {
  [BlockType.CARD]: l.makeTranslate('Card'),
  [BlockType.LIST_ITEM]: l.makeTranslate('List Item'),
  [BlockType.FORM_QUESTION]: l.makeTranslate('Data Collection'),
  [BlockType.DYNAMIC]: l.makeTranslate('Dynamic'),
}

export const BlockUploadProps = ['attachment', 'image']

export const BlockTypesNestingMap: Partial<Record<BlockType, BlockType[]>> = {
  [BlockType.CARDS]: [BlockType.CARD],
  [BlockType.LIST]: [BlockType.LIST_ITEM],
  [BlockType.FORM_QUESTION]: [BlockType.FORM_QUESTION_TIMEOUT],

  [BlockType.EMAIL_ROOT]: [
    BlockType.EMAIL_TEXT,
    BlockType.EMAIL_IMAGE,
    BlockType.EMAIL_BUTTON,
    BlockType.EMAIL_BUTTON,
    BlockType.EMAIL_DIVIDER,
  ],
}

export const BlockTypesMinNested: Partial<Record<BlockType, number>> = {
  [BlockType.CARDS]: 1,
  [BlockType.LIST]: 2,

  [BlockType.EMAIL_ROOT]: 0,
}

export const BlockTypesMaxNested: Partial<Record<BlockType, number>> = {
  [BlockType.CARDS]: 10,
  [BlockType.LIST]: 4,
  [BlockType.FORM_QUESTION]: 1,

  [BlockType.EMAIL_ROOT]: 100,
}

export const AttachmentButtonAllowed: Partial<
  Record<NodeType, Record<AttachmentBlockAttachmentType, boolean>>
> & { default: Record<AttachmentBlockAttachmentType, boolean> } = {
  default: {
    [AttachmentBlockAttachmentType.GIF]: true,
    [AttachmentBlockAttachmentType.IMAGE]: true,
    [AttachmentBlockAttachmentType.VIDEO]: true,
    [AttachmentBlockAttachmentType.FILE]: false,
    [AttachmentBlockAttachmentType.AUDIO]: false,
    [AttachmentBlockAttachmentType.EXTERNAL_IMAGE]: false,
  },
  [NodeType.TELEGRAM]: {
    [AttachmentBlockAttachmentType.GIF]: true,
    [AttachmentBlockAttachmentType.IMAGE]: true,
    [AttachmentBlockAttachmentType.VIDEO]: true,
    [AttachmentBlockAttachmentType.FILE]: true,
    [AttachmentBlockAttachmentType.AUDIO]: true,
    [AttachmentBlockAttachmentType.EXTERNAL_IMAGE]: true,
  },
}

type BlockNestingNumberMap = Partial<Record<BlockType, number>>

export const ButtonTypesMaxNested: Partial<Record<NodeType | 'default', BlockNestingNumberMap>> = {
  default: {
    [BlockType.TEXT]: 3,
    [BlockType.LIST]: 1,
    [BlockType.LIST_ITEM]: 1,
    [BlockType.CARD]: 3,
    [BlockType.QUICK_REPLY]: 11,
    [BlockType.ATTACHMENT]: 3,
    [BlockType.DYNAMIC]: 1,
  },
  [NodeType.SMS]: {
    [BlockType.TEXT]: 100,
    [BlockType.QUICK_REPLY]: 11,
    [BlockType.ATTACHMENT]: 0,
  },
  [NodeType.EMAIL_NEW]: {
    [BlockType.EMAIL_TEXT]: 100,
    [BlockType.EMAIL_BUTTON]: 1,
    [BlockType.EMAIL_IMAGE]: 1,
  },
  [NodeType.WHATS_APP]: {
    [BlockType.TEXT]: 3,
    [BlockType.MESSAGE_TEMPLATE]: 3,
    [BlockType.WA_LIST_MESSAGE]: 10,
  },
  [NodeType.TELEGRAM]: {
    [BlockType.TEXT]: 10,
    [BlockType.ATTACHMENT]: 10,
    [BlockType.TELEGRAM_KEYBOARD]: 8 * 8,
  },
}

type BlockNestingButtonsMap = Partial<Record<BlockType, ButtonType[]>>
export const ButtonTypesNestingMap: Partial<Record<NodeType | 'default', BlockNestingButtonsMap>> =
  {
    default: {
      [BlockType.TEXT]: [
        ButtonType.CONTENT,
        ButtonType.URL,
        ButtonType.CALL,
        ButtonType.FLOW,
        ButtonType.BUY,
      ],
      [BlockType.LIST]: [ButtonType.CONTENT, ButtonType.URL, ButtonType.FLOW],
      [BlockType.LIST_ITEM]: [ButtonType.CONTENT, ButtonType.URL, ButtonType.BUY, ButtonType.FLOW],
      [BlockType.CARD]: [
        ButtonType.CONTENT,
        ButtonType.URL,
        ButtonType.CALL,
        ButtonType.SHARE,
        ButtonType.BUY,
        ButtonType.FLOW,
      ],
      [BlockType.QUICK_REPLY]: [ButtonType.CONTENT, ButtonType.FLOW],
      [BlockType.ATTACHMENT]: [
        ButtonType.CONTENT,
        ButtonType.URL,
        ButtonType.CALL,
        ButtonType.SHARE,
        ButtonType.BUY,
        ButtonType.FLOW,
      ],
      [BlockType.DYNAMIC]: [ButtonType.CONTENT],
    },
    [NodeType.SMS]: {
      [BlockType.TEXT]: [ButtonType.CONTENT, ButtonType.URL, ButtonType.FLOW],
      [BlockType.QUICK_REPLY]: [ButtonType.CONTENT, ButtonType.FLOW],
    },
    [NodeType.EMAIL_NEW]: {
      [BlockType.EMAIL_TEXT]: [ButtonType.CONTENT, ButtonType.URL, ButtonType.FLOW],
      [BlockType.EMAIL_IMAGE]: [ButtonType.CONTENT, ButtonType.URL, ButtonType.FLOW],
      [BlockType.EMAIL_BUTTON]: [ButtonType.CONTENT, ButtonType.URL, ButtonType.FLOW],
    },
    [NodeType.WHATS_APP]: {
      [BlockType.TEXT]: [
        ButtonType.CONTENT,
        ButtonType.CTA_URL,
        ButtonType.URL,
        ButtonType.CROSS_CHANNEL_CONTENT,
      ],
      [BlockType.MESSAGE_TEMPLATE]: [ButtonType.CONTENT, ButtonType.URL],
      [BlockType.WA_LIST_MESSAGE]: [ButtonType.WA_LIST_MESSAGE_BUTTON],
    },
  }

export const TELEGRAM_KEYBOARD_MAX_ROWS = 8
export const TELEGRAM_KEYBOARD_MAX_COLS = 8

export const TEXT_BLOCK_TEXT_MAX_LENGTH = 2000
export const TEXT_BLOCK_WITH_BUTTONS_TEXT_MAX_LENGTH = 640
export const SIMPLE_BUILDER_TEXT_BLOCK_TEXT_MAX_LENGTH = 150
export const FORM_QUESTION_BLOCK_TEXT_MAX_LENGTH = 640
export const INSTAGRAM_TEXT_BLOCK_TEXT_MAX_LENGTH = 1000
export const TELEGRAM_TEXT_BLOCK_TEXT_MAX_LENGTH = 2000
export const ADDITIONAL_OUTSIDE_24_TEXT_BLOCK_MAX_LENGTH = 250

export const SMS_NODE_TEXT_BLOCK_MAX_SEGMENTS = 3

export const SMS_NODE_IMAGE_MAX_SIZE = 5 * 1024 * 1024 - 5 * 1024 // (5 Mb - 5 Kb) as Bytes

// https://developers.facebook.com/docs/messenger-platform/reference/templates/generic#elements
export const CardMaxTitleLength = 80
export const CardMaxSubtitleLength = 80

// https://developers.facebook.com/docs/messenger-platform/reference/templates/list#elements
export const ListMaxTitleLength = 80
export const ListMaxSubtitleLength = 80
export const OneTimeNotifyRequestMaxTextLength = 65

export const QuickReplyBlockMaxCaptionLength = 20

// list of attributes that can be compared with default attributes to understand
// that this block has changed, @see BlockModel.isEmpty helper
export const BlockCheckEmptyAttributes: Partial<Record<BlockType, string[]>> = {
  [BlockType.TEXT]: ['text', 'buttons'],
  [BlockType.QUICK_REPLY]: ['buttons'],
  [BlockType.TELEGRAM_KEYBOARD]: ['buttons', 'buttonsStructure'],
  [BlockType.ATTACHMENT]: ['attachment', 'buttons'],
  [BlockType.CARDS]: ['buttons'],
  [BlockType.CARD]: ['title', 'subtitle', 'image', 'defaultAction', 'buttons'],
  [BlockType.LIST]: ['buttons', 'topElementStyle'],
  [BlockType.LIST_ITEM]: ['title', 'subtitle', 'image', 'defaultAction', 'buttons'],
  [BlockType.DELAY]: ['time', 'showTyping'],
  [BlockType.FORM_QUESTION]: ['text', 'replyType', 'wayToReply', 'customFieldId', 'skipText'],
  [BlockType.MESSAGE_TEMPLATE]: ['name'],
}

export const ProBlockTypes = [BlockType.FORM_QUESTION, BlockType.DYNAMIC]

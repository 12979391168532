import BaseBlockConfig from 'common/builder/blocks/BaseBlockConfig'
import { IBlockBase, ICardBlock } from 'common/builder/blocks/blockInterfaces'
import { BlockType } from 'common/builder/constants/BlockType'

class CardBlockConfig extends BaseBlockConfig {
  blockType = BlockType.CARD

  getDefaults = (): Omit<ICardBlock, keyof IBlockBase> => ({
    title: '',
    subtitle: '',
    image: null,
    defaultAction: null,
    isCardHidden: false,
  })
}

export default new CardBlockConfig()

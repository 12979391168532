import * as atypes from 'common/actions/integrations/common/constants/IntegrationsReduxActionTypes'

const initialState = {
  identityField: {
    value: null,
    option: null,
  },
  isDisconnecting: false,
  isFetching: false,
  lists: [],
  selectedList: null,
  hasEmptyField: false,
}

export default function integrationsReducer(state = initialState, action) {
  const { data } = action

  switch (action.type) {
    case atypes.INTEGRATION_RESET: {
      return {
        ...state,
        ...initialState,
      }
    }

    case atypes.ACTIVE_CAMPAIGN_SET_IDENTITY_FIELD: {
      return { ...state, identityField: data }
    }

    case atypes.INTEGRATION_CONNECT_BY_TOKEN_REQUEST: {
      return { ...state, isConnecting: true }
    }

    case atypes.INTEGRATION_CONNECT_BY_TOKEN_ERROR:
    case atypes.INTEGRATION_CONNECT_BY_TOKEN_RESPONSE: {
      return { ...state, isConnecting: false }
    }

    case atypes.INTEGRATION_DISCONNECT_REQUEST: {
      return { ...state, isDisconnecting: true }
    }
    case atypes.INTEGRATION_DISCONNECT_RESPONSE: {
      return { ...state, isDisconnecting: false }
    }

    case atypes.ACTIVE_CAMPAIGN_LISTS_FETCH_REQUEST: {
      return {
        ...state,
        isFetching: true,
      }
    }
    case atypes.ACTIVE_CAMPAIGN_LISTS_FETCH_RESPONSE: {
      const { lists } = action.data
      return {
        ...state,
        lists,
        isFetching: false,
      }
    }
    case atypes.ACTIVE_CAMPAIGN_LISTS_FETCH_ERROR: {
      return {
        ...state,
        isFetching: false,
      }
    }

    case atypes.ACTIVE_CAMPAIGN_SET_LIST: {
      return { ...state, selectedList: data }
    }

    case atypes.ACTIVE_CAMPAIGN_SET_FIELD_EMPTINESS: {
      return { ...state, hasEmptyField: data }
    }
  }

  return state
}

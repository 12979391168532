/* eslint-disable react/no-multi-comp */
import React from 'react'
import { l } from '@manychat/manyui'

import DefaultStepView from 'utils/services/newOnboardingService/view/DefaultStepView'
import ManyChatOnboardingView from 'utils/services/newOnboardingService/view/ManyChatOnboardingView'
import { PointerType } from 'utils/services/newOnboardingService/view/Pointer/pointerInterfaces'
import TooltipDefault from 'utils/services/newOnboardingService/view/Pointer/Tooltip/TooltipDefault'

import FAQ from './NewCMSFAQ'

const TOTAL_STEPS = 10

const NewCMSFAQView = ({ onb }: { onb: FAQ }) => {
  return (
    <ManyChatOnboardingView
      onboarding={onb}
      stepsView={{
        STEP_1: ({ stepId }) => (
          <DefaultStepView
            onb={onb}
            stepId={stepId}
            pointerConfig={{
              type: PointerType.DOM,
              elementId: 'nav-link-cms',

              tooltip: {
                component: (
                  <TooltipDefault
                    text={l.makeTranslate(
                      "Great job, you've just created your first automation. Let's see where you can find it.",
                    )}
                  />
                ),
                at: 'right',
              },
            }}
            progressConfig={{
              current: 1,
              total: TOTAL_STEPS,
            }}
          />
        ),
        STEP_2: ({ stepId }) => (
          <DefaultStepView
            onb={onb}
            stepId={stepId}
            pointerConfig={{
              type: PointerType.DOM,
              elementId: 'nav-basic',
              tooltip: {
                component: (
                  <TooltipDefault
                    text={l.makeTranslate(
                      `In the Basic Automations tab you’ll find simple automations you’ve created, as well as FAQ automation.`,
                    )}
                  />
                ),
                at: 'bottom',
              },
            }}
            progressConfig={{
              current: 2,
              total: TOTAL_STEPS,
            }}
          />
        ),
        STEP_3: ({ stepId, step }) => (
          <DefaultStepView
            onb={onb}
            stepId={stepId}
            pointerConfig={{
              type: PointerType.DOM,
              padding: 10,
              elementId: 'self-serve-conversation-starters-tour',
              blockAreaInput: true,

              tooltip: {
                component: (
                  <TooltipDefault
                    onNextClick={() => step.process()}
                    btnNext={l.makeTranslate('Next')}
                    text={l.makeTranslate(
                      `You can edit your Conversation Starters anytime by clicking here.`,
                    )}
                  />
                ),
                at: 'bottom',
              },
            }}
            progressConfig={{
              current: 3,
              total: TOTAL_STEPS,
            }}
          />
        ),
        STEP_4: ({ stepId, step }) => (
          <DefaultStepView
            onb={onb}
            stepId={stepId}
            pointerConfig={{
              type: PointerType.DOM,
              elementId: 'conversation-starter-item',
              blockAreaInput: true,

              tooltip: {
                component: (
                  <TooltipDefault
                    onNextClick={() => step.process()}
                    btnNext={l.makeTranslate('Next')}
                    text={l.makeTranslate(
                      'Conversation Starters is a list of questions displayed when people try to message your account.<br /><br />You can edit you starting questions and their replies in this place at any time.',
                    )}
                  />
                ),
                at: 'right',
              },
            }}
            progressConfig={{
              current: 4,
              total: TOTAL_STEPS,
            }}
          />
        ),
        STEP_5: ({ stepId }) => (
          <DefaultStepView
            onb={onb}
            stepId={stepId}
            pointerConfig={{
              type: PointerType.DOM,
              elementId: 'conversation-starter-btn-flow',

              tooltip: {
                component: (
                  <TooltipDefault
                    text={l.makeTranslate(
                      'By clicking this icon you can go straight to editing your replies.',
                    )}
                  />
                ),
                at: 'right',
              },
            }}
            progressConfig={{
              current: 5,
              total: TOTAL_STEPS,
            }}
          />
        ),
        STEP_6: ({ stepId }) => (
          <DefaultStepView
            onb={onb}
            stepId={stepId}
            pointerConfig={{
              type: PointerType.DOM,
              elementId: 'btn-edit-flow',

              tooltip: {
                component: (
                  <TooltipDefault
                    className="m-r-xl"
                    text={l.makeTranslate(
                      'Welcome to Flow Builder! Click this button to start making changes.',
                    )}
                  />
                ),
                at: 'bottom',
              },
            }}
            progressConfig={{
              current: 6,
              total: TOTAL_STEPS,
            }}
          />
        ),
        STEP_7: ({ stepId }) => (
          <DefaultStepView
            onb={onb}
            stepId={stepId}
            pointerConfig={{
              type: PointerType.FLOW_CHART,
              elementId: 'content_node',
              elementIndex: 0,
              padding: 5,
              moveTo: true,

              tooltip: {
                component: (
                  <TooltipDefault
                    className="m-r-xl"
                    text={l.makeTranslate('Select a reply to proceed with editing.')}
                  />
                ),
                at: 'top',
              },
            }}
            progressConfig={{
              current: 7,
              total: TOTAL_STEPS,
            }}
          />
        ),
        STEP_8: ({ stepId, step }) => (
          <DefaultStepView
            onb={onb}
            stepId={stepId}
            pointerConfig={{
              type: PointerType.DOM,
              elementId: 'builder-text-block',
              elementIndex: 0,
              blockAreaInput: true,

              tooltip: {
                component: (
                  <TooltipDefault
                    className="m-r-xl"
                    onNextClick={() => step.process()}
                    btnNext={l.makeTranslate('Next')}
                    text={l.makeTranslate('You can customize and edit your content here.')}
                  />
                ),
                at: 'right',
              },
            }}
            progressConfig={{
              current: 8,
              total: TOTAL_STEPS,
            }}
          />
        ),
        STEP_9: ({ stepId, step }) => (
          <DefaultStepView
            onb={onb}
            stepId={stepId}
            pointerConfig={{
              type: PointerType.DOM,
              elementId: 'btn-flowchart-creation-menu',
              blockAreaInput: true,
              padding: 5,

              tooltip: {
                component: (
                  <TooltipDefault
                    className="m-r-xl"
                    onNextClick={() => step.process()}
                    btnNext={l.makeTranslate('Next')}
                    text={l.makeTranslate(
                      'You can add multiple automation elements to your replies and build various automations by clicking this button.',
                    )}
                  />
                ),
                at: 'left',
              },
            }}
            progressConfig={{
              current: 9,
              total: TOTAL_STEPS,
            }}
          />
        ),
        STEP_10: ({ stepId, step }) => (
          <DefaultStepView
            onb={onb}
            stepId={stepId}
            pointerConfig={{
              type: PointerType.DOM,
              elementId: 'flow-editor-publish',
              blockAreaInput: true,

              tooltip: {
                component: (
                  <TooltipDefault
                    className="m-r-xl"
                    onNextClick={() => step.process()}
                    btnNext={l.makeTranslate('Finish')}
                    text={l.makeTranslate(
                      'Remember to make updates to your Automation whenever you change a content.',
                    )}
                  />
                ),
                at: 'bottom',
              },
            }}
            progressConfig={{
              current: 10,
              total: TOTAL_STEPS,
            }}
          />
        ),
      }}
    />
  )
}
export default NewCMSFAQView

import React from 'react'

export enum MessagingType {
  SUBSCRIPTION = 'subscription',
  PROMO = 'promotional',
  FOLLOWUP = 'followup',
  REENGAGEMENT = 'reengagement_test',
  NON_PROMO = 'non_promotional',
}

export const MessagingTypeLabel = {
  [MessagingType.SUBSCRIPTION]: 'Subscription Broadcast',
  [MessagingType.NON_PROMO]: 'Non-promotional Broadcast',
  [MessagingType.PROMO]: 'Promotional Broadcast',
  [MessagingType.FOLLOWUP]: 'Follow-Up Broadcast',
  [MessagingType.REENGAGEMENT]: 'Re-engagement Broadcast',
}

export const MessagingTypeHelp = {
  [MessagingType.SUBSCRIPTION]: (
    <span>
      Subscription broadcast messages <strong>can't</strong> contain ads or promotional materials,
      but can be sent at any time regardless of time passed since last user activity.
    </span>
  ),
  [MessagingType.PROMO]: `Promotional broadcast messages can contain ads and promotional materials, but can only be sent to your contacts who were active in the past 24 hours.`,
  [MessagingType.FOLLOWUP]: `After the end of the 24 hour window you have an ability to send "1 follow up message" to these recipients. After that you won't be able to send them ads or promotional messages until they interact with you again.`,
  [MessagingType.REENGAGEMENT]: `[BETA] Allows you to send any message (including promotions, ads, etc) as a part of the Facebook Messenger re-engagement test.`,
}

export const MessagingTypeOptions = [
  {
    value: MessagingType.SUBSCRIPTION,
    label: MessagingTypeLabel[MessagingType.SUBSCRIPTION],
    help: MessagingTypeHelp[MessagingType.SUBSCRIPTION],
  },
  {
    value: MessagingType.PROMO,
    label: MessagingTypeLabel[MessagingType.PROMO],
    help: MessagingTypeHelp[MessagingType.PROMO],
  },
  {
    value: MessagingType.FOLLOWUP,
    label: MessagingTypeLabel[MessagingType.FOLLOWUP],
    help: MessagingTypeHelp[MessagingType.FOLLOWUP],
  },
  {
    value: MessagingType.REENGAGEMENT,
    label: MessagingTypeLabel[MessagingType.REENGAGEMENT],
    help: MessagingTypeHelp[MessagingType.REENGAGEMENT],
  },
]

export const getMessagingTypeOptions = (options: { reengagementEnabled?: boolean } = {}) => {
  if (options.reengagementEnabled) {
    return MessagingTypeOptions
  }
  return MessagingTypeOptions.filter((o) => o.value !== MessagingType.REENGAGEMENT)
}

import { createSlice } from '@reduxjs/toolkit'

import { fetchStripeCheckoutInfo } from '../actions/stripeCheckoutActions'
import { StripeCheckout } from '../billingInterfaces'

export const initialState: Readonly<StripeCheckout> = {}

export const stripeCheckoutSlice = createSlice({
  name: 'stripeCheckout',
  reducers: {},
  initialState,
  extraReducers: (builder) => {
    builder
      .addCase(fetchStripeCheckoutInfo.fulfilled, (state, action) => {
        if (action.payload?.info) {
          const { info } = action.payload
          state.isUpgradeToPro = info.is_upgrade_to_pro
          state.status = info.status
          state.errorMessage = info.error_message || null
        }

        state.isDataWasFetched = true
      })
      .addCase(fetchStripeCheckoutInfo.rejected, (state) => {
        state.isDataWasFetched = true
      })
  },
})

import flatten from 'lodash/flatten'
import get from 'lodash/get'
import keyBy from 'lodash/keyBy'

import { WebViewSize } from 'common/core/constants/WebViewSize'

import { MenuItemType } from './constants'

/**
 * @param data
 * @param options
 *    parseStats
 * @returns {{byId, nested: *}}
 */
export const parseMenu = (data, options = {}) => {
  const keyboard = get(data, 'keyboard', [])

  const parsedItems = keyboard.map((keyboardItem) => parseItem(keyboardItem, options))
  const items = parsedItems.flat(Infinity).filter((item) => item.type !== 'nested')
  const byId = keyBy(items, 'id')

  return {
    byId,
    nested: Object.keys(byId),
  }
}

export const parseItem = (item, options = {}) => {
  const keyboard = get(item, 'keyboard', [])

  const parsed = {
    id: item._oid,
    type: item.type,
    caption: item.caption,
    actions: item.actions || [],
    flowId: item.flow_ns || null,
    chId: item.content_holder || null,
    url: item.url || '',
    webviewSize: item.webview_size || WebViewSize.DEFAULT,
    enableWebview: !!item.do_not_track,
    nested: [],
    old_mm_content_id: item.old_mm_content_id,
  }

  if (options.parseStats) {
    parsed.triggered = item.triggered
  }

  const items = keyboard.map((keyboardItem) => parseItem(keyboardItem, options))

  return [parsed, ...flatten(items)]
}

export const exportMenu = (data) => {
  const { byId, nested } = data
  const keyboard = nested.map((itemId) => exportItem(itemId, byId))

  return {
    keyboard,
  }
}

export const exportItem = (itemId, itemsMap) => {
  const item = itemsMap[itemId]

  const exported = {
    _oid: item.id,
    type: item.type,
    caption: item.caption,
    actions: [],
    old_mm_content_id: item.old_mm_content_id,
  }

  if (item.type === MenuItemType.FLOW) {
    exported.flow_ns = item.flowId
    exported.actions = item.actions
  }

  if (item.type === MenuItemType.URL) {
    exported.url = item.url
    exported.webview_size = item.webviewSize || null
    exported.do_not_track = item.enableWebview
  }

  return exported
}

export default {
  parseMenu,
  parseItem,
  exportMenu,
  exportItem,
}

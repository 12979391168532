import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { appListenerMiddleware } from 'reduxTyped'
import { l } from '@manychat/manyui'

import { showLimitsExceededError } from 'apps/aiAgentPlayground/store/lib/errorHandlers'
import { getHasSubscriberFieldDependency } from 'apps/aiAgentPlayground/store/lib/getHasSubscriberFieldDependency'
import {
  createCollectedInfoMessage,
  createDebugMessage,
  createSubscriberDataMessage,
  createUserTextMessage,
  parseTextMessage,
} from 'apps/aiAgentPlayground/store/lib/messages'
import {
  AiAgentPlaygroundState,
  AiAssistantSocketEvent,
  BackendPlaygroundTextMessage,
  PlaygroundMessage,
  PlaygroundMessageAuthor,
} from 'apps/aiAgentPlayground/store/types'
import { alert } from 'common/core'
import { ChannelType } from 'common/core/constants/ChannelType'
import { VirtualFlexibleAgent } from 'shared/api/requests/aiAgent/schemas'
import { AiAgentPlaygroundApi } from 'shared/api/requests/aiAgentPlayground'
import { AiAgentPlaygroundExecutionContext } from 'shared/api/requests/aiAgentPlayground/schemas'
import { createAsyncAction } from 'shared/lib/redux'
import { accountNotificationsListener } from 'utils/services/notificationsService'

const NAMESPACE = 'aiAgentPlayground'

export const AiAgentPlaygroundInitialState: AiAgentPlaygroundState = {
  sessionId: null,
  subscriberId: null,
  contextContentId: null,
  nodeContentId: null,
  flowId: null,
  executionContext: null,
  agent: null,
  messages: [],
  isSubscriberDependentAgent: false,
  isConversationOngoing: false,
  isInteractable: false,
  isWaitingForAgentReply: false,
  channel: null,
}

const { actions, reducer } = createSlice({
  name: NAMESPACE,
  initialState: AiAgentPlaygroundInitialState,
  reducers: {
    threadInitialized: (
      _,
      action: PayloadAction<{
        agent: VirtualFlexibleAgent
        contextContentId?: string
        executionContext?: AiAgentPlaygroundExecutionContext
        nodeContentId?: number
        flowId?: string
        channel?: ChannelType | null
      }>,
    ) => ({
      ...AiAgentPlaygroundInitialState,
      agent: action.payload.agent,
      isWaitingForAgentReply: true,
      contextContentId: action.payload.contextContentId ?? null,
      executionContext: action.payload.executionContext ?? null,
      nodeContentId: action.payload.nodeContentId ?? null,
      flowId: action.payload.flowId ?? null,
      channel: action.payload.channel ?? null,
    }),
    threadReset: () => AiAgentPlaygroundInitialState,
    messageAdded: (state, action: PayloadAction<PlaygroundMessage>) => {
      if (action.payload.author === PlaygroundMessageAuthor.ASSISTANT) {
        state.isWaitingForAgentReply = false
      }

      state.messages.push(action.payload)
    },
    subscriberDependentFlagUpdated: (state, action: PayloadAction<boolean>) => {
      state.isSubscriberDependentAgent = action.payload
    },
    subscriberIdUpdated: (state, action: PayloadAction<string>) => {
      state.subscriberId = action.payload
    },
    conversationOngoingFlagUpdated: (state, action: PayloadAction<boolean>) => {
      state.isConversationOngoing = action.payload
    },
    isInteractableUpdated: (state, action: PayloadAction<boolean>) => {
      state.isInteractable = action.payload
    },
    isWaitingForAgentReplyUpdated: (state, action: PayloadAction<boolean>) => {
      state.isWaitingForAgentReply = action.payload
    },
  },
  extraReducers: (builder) => {
    builder.addCase(initPlaygroundFx.pending, (state) => {
      state.isInteractable = false
    })

    builder.addCase(sendMessageFx.pending, (state) => {
      state.isInteractable = false
      state.isWaitingForAgentReply = true
    })

    builder.addCase(initPlaygroundFx.fulfilled, (state, action) => {
      if (action.payload.debug_info) {
        state.messages.push(createDebugMessage(action.payload.debug_info))
      }

      if (action.payload.subscriber_data) {
        state.messages.push(createSubscriberDataMessage(action.payload.subscriber_data))
      }

      state.sessionId = action.payload.session_id
    })
  },
})

appListenerMiddleware.startListening({
  actionCreator: actions.threadInitialized,
  effect: (action, { dispatch }) => {
    const isSubscriberDependentAgent = getHasSubscriberFieldDependency({
      scenario: action.payload.agent.config.scenario,
    })

    dispatch(actions.conversationOngoingFlagUpdated(true))

    if (isSubscriberDependentAgent) {
      dispatch(actions.subscriberDependentFlagUpdated(true))
    } else {
      dispatch(runConversationFx())
    }
  },
})

appListenerMiddleware.startListening({
  actionCreator: actions.subscriberIdUpdated,
  effect: (_, { dispatch, getState }) => {
    const { isConversationOngoing } = AiAgentPlaygroundSelectors.getState(getState())

    if (isConversationOngoing) {
      dispatch(runConversationFx())
    }
  },
})

const initPlaygroundFx = createAsyncAction(
  `${NAMESPACE}/initPlaygroundFx`,
  async (_, { getState }) => {
    const {
      agent,
      subscriberId,
      contextContentId,
      executionContext,
      nodeContentId,
      flowId,
      channel,
    } = AiAgentPlaygroundSelectors.getState(getState())

    const typedAgent = agent as VirtualFlexibleAgent

    const response = await AiAgentPlaygroundApi.initThread({
      body: {
        subscriber_id: subscriberId,
        agent_data: typedAgent,
        content_oid: contextContentId as string,
        execution_context: executionContext as AiAgentPlaygroundExecutionContext,
        content_id: nodeContentId,
        flow_ns: flowId,
        channel,
      },
    })

    return response.data
  },
  {
    condition: (_, { getState }) => {
      const state = AiAgentPlaygroundSelectors.getState(getState())

      return state.agent !== null
    },
    onBusinessError: (error) => {
      if (AiAgentPlaygroundApi.initThread.isLimitExceededError(error)) {
        showLimitsExceededError()
        error.handle()
      }
    },
  },
)

const startChatFx = createAsyncAction(
  `${NAMESPACE}/startChatFx`,
  async (_, { getState }) => {
    const sessionId = AiAgentPlaygroundSelectors.getState(getState()).sessionId as string

    return await AiAgentPlaygroundApi.startThread({
      body: {
        session_id: sessionId,
      },
    })
  },
  {
    condition: (_, { getState }) =>
      AiAgentPlaygroundSelectors.getState(getState()).sessionId !== null,
  },
)

const runConversationFx = createAsyncAction(
  `${NAMESPACE}/runConversationFx`,
  async (_, { dispatch }) => {
    await dispatch(initPlaygroundFx()).unwrap()
    await dispatch(startChatFx()).unwrap()
  },
)

const sendMessageFx = createAsyncAction(
  `${NAMESPACE}/sendMessageFx`,
  async (message: string, { dispatch, getState }) => {
    const sessionId = AiAgentPlaygroundSelectors.getState(getState()).sessionId as string

    dispatch(actions.messageAdded(createUserTextMessage(message)))

    return AiAgentPlaygroundApi.sendEvent({
      body: {
        input: message,
        session_id: sessionId,
      },
    })
  },
  {
    condition: (_, { getState }) =>
      AiAgentPlaygroundSelectors.getState(getState()).sessionId !== null,
    onBusinessError: (error, _, { dispatch }) => {
      if (AiAgentPlaygroundApi.sendEvent.isLimitExceededError(error)) {
        showLimitsExceededError()
        dispatch(actions.isWaitingForAgentReplyUpdated(false))
        error.handle()
      }
    },
  },
)

export const AiAgentPlaygroundReducer = reducer

export const AiAgentPlaygroundActions = {
  threadInitialized: actions.threadInitialized,
  threadReset: actions.threadReset,
  subscriberIdUpdated: actions.subscriberIdUpdated,

  sendMessageFx,
}

const getState = (state: RootState) => state.aiAgentPlayground

export const AiAgentPlaygroundSelectors = {
  getState,
  getMessages: (state: RootState) => getState(state).messages,
  getSubscriberId: (state: RootState) => getState(state).subscriberId,
  getIsSubscriberDependentAgent: (state: RootState) => getState(state).isSubscriberDependentAgent,
  getHasOngoingConversation: (state: RootState) => getState(state).isConversationOngoing,
  getIsInteractable: (state: RootState) => getState(state).isInteractable,
  getIsWaitingForAgentReply: (state: RootState) => getState(state).isWaitingForAgentReply,
}

accountNotificationsListener.on(
  AiAssistantSocketEvent.MESSAGE_RECEIVED,
  (data: { message: BackendPlaygroundTextMessage; session_id: string }, dispatch, getState) => {
    if (
      data.session_id !== AiAgentPlaygroundSelectors.getState(getState() as RootState).sessionId
    ) {
      return
    }

    if (data.message.data.collected_info.length > 0) {
      dispatch(actions.messageAdded(createCollectedInfoMessage(data.message.data.collected_info)))
    }

    dispatch(actions.isInteractableUpdated(data.message.data.status === 'ongoing'))
    dispatch(actions.messageAdded(parseTextMessage(data.message)))
  },
)

accountNotificationsListener.on(
  AiAssistantSocketEvent.PLAYGROUND_FAILED,
  (data: { session_id: string }, dispatch, getState) => {
    if (
      data.session_id !== AiAgentPlaygroundSelectors.getState(getState() as RootState).sessionId
    ) {
      return
    }

    alert(l.translate('Conversation unexpectedly failed. Try restarting to continue'), 'danger')

    dispatch(actions.isInteractableUpdated(false))
    dispatch(actions.isWaitingForAgentReplyUpdated(false))
  },
)

import React, { useMemo, useCallback } from 'react'
import { useAppSelector } from 'reduxTyped'

import { toggleTrigger } from 'apps/cms/actions/cmsTriggerActions'
import { CardTrigger } from 'apps/cms/components/Flows/components/FlowCard/components/Triggers/lib/types'
import { CmsAnalytics } from 'apps/cms/lib/analytics'
import { contentManagementSelectors } from 'apps/cms/store'

interface UseActivationToggleProps {
  trigger: CardTrigger
  flowId: string
}

export const useActivationToggle = ({ trigger, flowId }: UseActivationToggleProps) => {
  const flow = useAppSelector((state) => contentManagementSelectors.getFlowById(state, flowId))

  const [active, setActive] = React.useState(
    trigger.status ? ['active', 'live'].includes(trigger.status) : false,
  )
  const [loading, setLoading] = React.useState(false)

  const isAvailableToggle = useMemo(() => {
    if (!flow?.has_published_content) return false
    return trigger.status && trigger.status !== 'initial'
  }, [flow, trigger.status])

  const handleClickTrigger = useCallback((event: React.MouseEvent) => {
    event.stopPropagation()
  }, [])

  const handleToggleTrigger = useCallback(
    async (event: React.ChangeEvent<HTMLInputElement>) => {
      setLoading(true)
      const { checked } = event.target

      const response = await toggleTrigger({ trigger, value: checked, flowId })

      if (response) {
        setActive(checked)
        CmsAnalytics.logToggleTrigger({ value: checked })
      }
      setLoading(false)
    },
    [flowId, trigger],
  )

  return {
    active,
    loading,
    isAvailableToggle,
    handleClickTrigger,
    handleToggleTrigger,
  }
}

import { CoexistenceContactImportStatus } from 'apps/whatsApp/interfaces/whatsAppCoexistence'
import { getWhatsAppCoexistenceContactImportStatus } from 'apps/whatsApp/redux/coexistenceSlice'
import { BillingExperiment } from 'common/billing'
import { SubscriptionAction } from 'common/billing/constants/SubscriptionAction'
import {
  getSubscriptionAction,
  getShouldShowTierLimitNotificationWithExperiment,
} from 'common/billing/selectors/billingSelectors'
import { LOW_BALANCE } from 'common/billing/wallet/walletConstants'
import {
  getAutoRefillStatus,
  getBalance,
  getIsCreditWallet,
  hasWallet,
} from 'common/billing/wallet/walletSelectors'
import {
  hasWhatsAppProErrorStatus,
  isWaCoexistenceContactImportCommunicationAvailable,
} from 'common/builder/selectors/builder/whatsAppSelectors'
import {
  NotificationRemoveType,
  StaticNotification,
  StaticNotificationId,
} from 'common/core/components/NotifyBar/NotifyInterfaces'
import { TikTokUsRegionRestrictedNotification } from 'common/core/components/NotifyBar/StaticNotifications/components/TikTokUsRegionRestrictedNotification'
import { WaCoexistenceContactImportActiveNotification } from 'common/core/components/NotifyBar/StaticNotifications/components/WaCoexistenceContactImportActiveNotification'
import { WaCoexistenceContactImportAvailableNotification } from 'common/core/components/NotifyBar/StaticNotifications/components/WaCoexistenceContactImportAvailableNotification'
import { WaCoexistenceContactImportFinishedNotification } from 'common/core/components/NotifyBar/StaticNotifications/components/WaCoexistenceContactImportFinishedNotification'
import { WaCreditLineIssueNotification } from 'common/core/components/NotifyBar/StaticNotifications/components/WaCreditLineIssueNotification'
import { NotificationBarType } from 'common/core/constants/NotificationBarType'
import { isAnyPro } from 'common/core/models/Account/helpers'
import {
  getCurrentAccount,
  getIsAfterExpiredPeriod,
  isAccountBlocked,
} from 'common/core/selectors/appSelectors'
import {
  getFBChannel,
  getInstagramChannel,
  getInstagramChannelIsNewApi,
  getSMSChannel,
  getTikTokChannelUsRegionRestricted,
  getWhatsAppChannel,
  hasConnectedEmailOrWhatsAppChannel,
  hasEnabledFBChannel,
  isWhatsAppChannelConnected,
  whatsAppChannelHasCreditLineIssue,
} from 'common/core/selectors/channelSelectors'
import { getIsIgChangeAccountError } from 'common/core/selectors/uiSelectors'
import { getCardCountry, getIsNeedToShowPayNow } from 'common/settings/selectors/settingsSelectors'
import { getSubscribersCount } from 'common/subscribers/selectors/subscribersSelectors'
import { accountFlags } from 'utils/services/featureFlagsService'
import { AccountFlag } from 'utils/services/featureFlagsService/constants'

import {
  ActivateProTrialNotification,
  AfterExpiredPeriodNotification,
  BlockedAccountNotification,
  BlockedSMSChannel,
  ExpiredAccountNotification,
  IgChangeAccountTypeErrorNotification,
  InstagramPageConnectionLost,
  PaymentCardRenewalNotification,
  PayNowNotification,
  RefreshAdminPermissionsNotification,
  VerifySubscriptionPaymentNotification,
  WAFreePeriodEndNotification,
  WalletLowBalanceNotification,
  WhatsAppBlockedNotification,
  TierLimitNotification,
} from './components'

const getIsSubscriptionActionEqual = (action: SubscriptionAction) => (state: RootState) =>
  getSubscriptionAction(state) === action

export const BlockedSMSChannelNotificationConfig: StaticNotification = {
  notification_id: StaticNotificationId.BLOCKED_SMS_ACCOUNT,
  render: BlockedSMSChannel,
  type: NotificationBarType.WARNING,
  removeType: NotificationRemoveType.NEVER,
  ts_created: 1,
  shouldBeShown: (state: RootState) => getSMSChannel(state).is_blocked,
}

export const AfterExpiredPeriodNotificationConfig: StaticNotification = {
  notification_id: StaticNotificationId.AFTER_EXPIRED_PERIOD,
  render: AfterExpiredPeriodNotification,
  type: NotificationBarType.WARNING,
  removeType: NotificationRemoveType.TEMPORARY,
  isWithoutReadMore: true,
  ts_created: 1,
  shouldBeShown: (state) =>
    getIsAfterExpiredPeriod(state) &&
    !getIsSubscriptionActionEqual(SubscriptionAction.CONTINUE_CHECKOUT)(state),
}

export const ExpiredNotificationConfig: StaticNotification = {
  notification_id: StaticNotificationId.EXPIRED,
  render: ExpiredAccountNotification,
  type: NotificationBarType.WARNING,
  removeType: NotificationRemoveType.TEMPORARY,
  isWithoutReadMore: true,
  ts_created: 1,
  shouldBeShown: (state) => {
    return (
      !getIsNeedToShowPayNow(state) &&
      !getIsAfterExpiredPeriod(state) &&
      getIsSubscriptionActionEqual(SubscriptionAction.RENEW)(state)
    )
  },
}

export const BlockedAccountNotificationConfig: StaticNotification = {
  notification_id: StaticNotificationId.BLOCKED_ACCOUNT,
  render: BlockedAccountNotification,
  type: NotificationBarType.ERROR,
  removeType: NotificationRemoveType.NEVER,
  isWithoutReadMore: true,
  ts_created: 1,
  shouldBeShown: (state) =>
    isAccountBlocked(state) &&
    !getIsSubscriptionActionEqual(SubscriptionAction.CONTINUE_CHECKOUT)(state),
}

export const WAFreePeriodEndNotificationConfig: StaticNotification = {
  notification_id: StaticNotificationId.WA_FREE_PERIOD_END,
  render: WAFreePeriodEndNotification,
  type: NotificationBarType.ERROR,
  removeType: NotificationRemoveType.NEVER,
  isWithoutReadMore: true,
  ts_created: 1,
  shouldBeShown: (state) => {
    const waChannel = getWhatsAppChannel(state)
    if (!isWhatsAppChannelConnected(waChannel)) return false
    return !hasWhatsAppProErrorStatus(state) && Boolean(waChannel?.is_exceeded_free_limits)
  },
}

export const RefreshAdminPermissionsNotificationConfig: StaticNotification = {
  notification_id: StaticNotificationId.REFRESH_ADMIN_PERMISSIONS,
  render: RefreshAdminPermissionsNotification,
  type: NotificationBarType.WARNING,
  removeType: NotificationRemoveType.NEVER,
  isWithoutReadMore: true,
  ts_created: 1,
  shouldBeShown: (state) => {
    const fbChannel = getFBChannel(state)
    if (!fbChannel) return false
    return (
      fbChannel.token_without_admin_perm ||
      (fbChannel.status === 'error' && hasEnabledFBChannel(state))
    )
  },
}

export const IgRefreshPermissionsNotificationConfig: StaticNotification = {
  notification_id: StaticNotificationId.INSTAGRAM_PAGE_CONNECTION_LOST,
  render: InstagramPageConnectionLost,
  type: NotificationBarType.WARNING,
  removeType: NotificationRemoveType.NEVER,
  isWithoutReadMore: true,
  ts_created: 1,
  shouldBeShown: (state) => {
    const igChannel = getInstagramChannel(state)
    if (!igChannel) return false
    return igChannel.status === 'error' && getInstagramChannelIsNewApi(state)
  },
}

// For Indian payment system only
export const PaymentCardRenewalNotificationConfig: StaticNotification = {
  notification_id: StaticNotificationId.PAYMENT_CARD_RENEWAL,
  render: PaymentCardRenewalNotification,
  type: NotificationBarType.WARNING,
  removeType: NotificationRemoveType.NEVER,
  isWithoutReadMore: true,
  ts_created: 1,
  shouldBeShown: (state) => {
    const account = getCurrentAccount(state)
    const enabledIndian3dsFlag = accountFlags.isEnabled(
      AccountFlag.ENABLE_INDIAN_CUSTOMERS_3DS_RESUBSCRIPTION,
    )
    return (
      getCardCountry(state)?.toUpperCase() === 'IN' && enabledIndian3dsFlag && !account.isExpired
    )
  },
}

export const IGChangeAccountTypeErrorNotificationConfig: StaticNotification = {
  notification_id: StaticNotificationId.IG_CHANGE_ACCOUNT_TYPE_ERROR,
  render: IgChangeAccountTypeErrorNotification,
  type: NotificationBarType.WARNING,
  removeType: NotificationRemoveType.TEMPORARY,
  isWithoutReadMore: true,
  ts_created: 1,
  shouldBeShown: (state) => {
    const isShowIgChangeAccountError = getIsIgChangeAccountError(state)
    return isShowIgChangeAccountError
  },
}

export const PayNowNotificationConfig: StaticNotification = {
  notification_id: StaticNotificationId.PAY_NOW,
  render: PayNowNotification,
  type: NotificationBarType.WARNING,
  removeType: NotificationRemoveType.NEVER,
  isWithoutReadMore: true,
  ts_created: 1,
  shouldBeShown: getIsNeedToShowPayNow,
}

export const WalletLowBalanceNotificationConfig: StaticNotification = {
  notification_id: StaticNotificationId.LOW_BALANCE,
  render: WalletLowBalanceNotification,
  type: NotificationBarType.INFO,
  removeType: NotificationRemoveType.TEMPORARY,
  isWithoutReadMore: true,
  ts_created: 1,
  shouldBeShown: (state) => {
    const balance = getBalance(state)
    const walletStatus = hasWallet(state)
    const autoRefillStatus = getAutoRefillStatus(state)
    const hasPaidChannel = hasConnectedEmailOrWhatsAppChannel(state)
    const isCreditWallet = getIsCreditWallet(state)
    const subscribersCount = getSubscribersCount(state)

    if (!walletStatus || !hasPaidChannel || isCreditWallet || subscribersCount < 5) return false

    return balance < LOW_BALANCE && !autoRefillStatus
  },
}

export const WhatsAppBlockedNotificationConfig: StaticNotification = {
  notification_id: StaticNotificationId.WA_PRO_ERROR,
  render: WhatsAppBlockedNotification,
  type: NotificationBarType.ERROR,
  removeType: NotificationRemoveType.NEVER,
  isWithoutReadMore: true,
  ts_created: 1,
  shouldBeShown: (state) =>
    hasWhatsAppProErrorStatus(state) &&
    !getIsSubscriptionActionEqual(SubscriptionAction.CONTINUE_CHECKOUT)(state),
}

export const ActivateProTrialNotificationConfig: StaticNotification = {
  notification_id: StaticNotificationId.ACTIVATE_PRO_TRIAL,
  render: ActivateProTrialNotification,
  type: NotificationBarType.SUCCESS,
  removeType: NotificationRemoveType.TEMPORARY,
  isWithoutReadMore: true,
  ts_created: 1,
  customIconName: 'OrphanBuyButton',
  isAlwaysOnTop: true,
  shouldBeShown: getIsSubscriptionActionEqual(SubscriptionAction.ACTIVATE_TRIAL),
}

export const VerifySubscriptionPaymentNotificationConfig: StaticNotification = {
  notification_id: StaticNotificationId.VERIFY_SUBSCRIPTION_PAYMENT,
  render: VerifySubscriptionPaymentNotification,
  type: NotificationBarType.ERROR,
  removeType: NotificationRemoveType.NEVER,
  isWithoutReadMore: true,
  ts_created: 1,
  isAlwaysOnTop: true,
  shouldBeShown: getIsSubscriptionActionEqual(SubscriptionAction.CONTINUE_CHECKOUT),
}

export const WaHistoryContactImportStartedNotificationConfig: StaticNotification = {
  notification_id: StaticNotificationId.WA_COEXISTENCE_CONTACT_IMPORT_ACTIVE,
  render: WaCoexistenceContactImportActiveNotification,
  type: NotificationBarType.SUCCESS,
  removeType: NotificationRemoveType.TEMPORARY,
  ts_created: 1,
  shouldBeShown: (state) =>
    getWhatsAppCoexistenceContactImportStatus(state) === CoexistenceContactImportStatus.ACTIVE,
}

export const WaHistoryContactImportFinishedNotificationConfig: StaticNotification = {
  notification_id: StaticNotificationId.WA_COEXISTENCE_CONTACT_IMPORT_FINISHED,
  render: WaCoexistenceContactImportFinishedNotification,
  type: NotificationBarType.SUCCESS,
  removeType: NotificationRemoveType.TEMPORARY,
  ts_created: 1,
  shouldBeShown: (state) =>
    getWhatsAppCoexistenceContactImportStatus(state) === CoexistenceContactImportStatus.FINISHED,
}

export const TierLimitNotificationConfig: StaticNotification = {
  notification_id: StaticNotificationId.TIER_LIMIT,
  render: TierLimitNotification,
  type: NotificationBarType.INFO,
  removeType: NotificationRemoveType.TEMPORARY,
  isWithoutReadMore: true,
  ts_created: 1,
  customIconName: 'Info',
  isAlwaysOnTop: true,
  shouldBeShown: (state) =>
    getShouldShowTierLimitNotificationWithExperiment(state, BillingExperiment.TIER_NOTIFICATION),
}

export const WaCreditLineIssueNotificationConfig: StaticNotification = {
  notification_id: StaticNotificationId.WA_CREDIT_LINE_ISSUE,
  render: WaCreditLineIssueNotification,
  type: NotificationBarType.WARNING,
  removeType: NotificationRemoveType.NEVER,
  isWithoutReadMore: true,
  ts_created: 1,
  shouldBeShown: (state) => {
    const account = getCurrentAccount(state)
    return whatsAppChannelHasCreditLineIssue(state) && isAnyPro(account)
  },
}

export const TikTokChannelUsRegionRestrictionNotificationConfig: StaticNotification = {
  notification_id: StaticNotificationId.TIKTOK_US_REGION_RESTRICTED,
  render: TikTokUsRegionRestrictedNotification,
  type: NotificationBarType.ERROR,
  removeType: NotificationRemoveType.TEMPORARY,
  isWithoutReadMore: true,
  ts_created: 1,
  shouldBeShown: (state) => getTikTokChannelUsRegionRestricted(state),
}

export const WaCoexistenceContactImportAvailableNotificationConfig: StaticNotification = {
  notification_id: StaticNotificationId.WA_COEXISTENCE_CONTACT_IMPORT_AVAILABLE,
  render: WaCoexistenceContactImportAvailableNotification,
  type: NotificationBarType.INFO,
  removeType: NotificationRemoveType.NEVER,
  isWithoutReadMore: true,
  ts_created: 1,
  shouldBeShown: (state) => {
    const shouldShowNotification = isWaCoexistenceContactImportCommunicationAvailable(state)
    return shouldShowNotification
  },
}

export const AllNotifications: StaticNotification[] = [
  ActivateProTrialNotificationConfig,
  AfterExpiredPeriodNotificationConfig,
  BlockedAccountNotificationConfig,
  BlockedSMSChannelNotificationConfig,
  ExpiredNotificationConfig,
  IGChangeAccountTypeErrorNotificationConfig,
  PayNowNotificationConfig,
  PaymentCardRenewalNotificationConfig,
  RefreshAdminPermissionsNotificationConfig,
  VerifySubscriptionPaymentNotificationConfig,
  WAFreePeriodEndNotificationConfig,
  WalletLowBalanceNotificationConfig,
  WhatsAppBlockedNotificationConfig,
  IgRefreshPermissionsNotificationConfig,
  WaHistoryContactImportStartedNotificationConfig,
  WaHistoryContactImportFinishedNotificationConfig,
  TierLimitNotificationConfig,
  TikTokChannelUsRegionRestrictionNotificationConfig,
  WaCreditLineIssueNotificationConfig,
  WaCoexistenceContactImportAvailableNotificationConfig,
]

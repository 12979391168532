import get from 'lodash/get'

import { FsObject } from 'shared/api/requests/cms/schemas'

// todo
export const FSOAdapter = (data: FsObject) => {
  const result = {
    flowId: get(data, 'flow.ns', null),
    contentId: get(data, 'content.content_id', null),
    ...data,
  }

  if ('flow' in result && result.flow) {
    result.flow = {
      ...result.flow,
      path: result.flow.path || result.path,
    }
  }

  return result
}

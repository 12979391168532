type Range = [number, number]

export const InfoStatusesRange: Range = [100, 199]
export const SuccessStatusesRange: Range = [200, 299]
export const RedirectStatusesRange: Range = [300, 399]
export const ClientStatusesRange: Range = [400, 499]
export const ServerStatusesRange: Range = [500, 599]

export const checkValidStatusRange = (range: Range, status?: number) => {
  if (!status) return false

  return status >= range[0] && status <= range[1]
}

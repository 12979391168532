import { l } from '@manychat/manyui'

import { IInstagramStoryReplyTriggerWidget, IWidget } from 'apps/growthTools/growthToolsInterfaces'
import { WidgetTypes } from 'apps/growthTools/models/Widget/constants'
import { StorySelectType } from 'apps/instagram/models/interfaces'
import { ConditionTypes } from 'apps/keywords/models/KeywordRule/constants'

interface ErrorType {
  message: string
}

export const getWidgetErrors = (widget: IWidget) => {
  const errors: ErrorType[] = []

  if (widget.widget_type === WidgetTypes.INSTAGRAM_STORY_REPLY) {
    return getInstagramStoryReplyErrors(widget)
  }

  return errors
}

const getInstagramStoryReplyErrors = (widget: IInstagramStoryReplyTriggerWidget) => {
  const errors: ErrorType[] = []

  const data = widget.data
  if (data.story_select === StorySelectType.SPECIFIC && !data.story_id) {
    errors.push({
      message: l.translate('Please select a story or change the trigger type to "All stories"'),
    })
  }
  if (data.keywords_condition !== ConditionTypes.ANY && !data.keywords.length) {
    errors.push({
      message: l.translate(
        'Please add at least one keyword or change the condition to "Any reaction or message"',
      ),
    })
  }

  return errors
}

import without from 'lodash/without'

import { AttachmentBlockAttachmentType } from 'common/builder/blocks/blockConstants'
import { BlockType } from 'common/builder/constants/BlockType'
import { NextStepType } from 'common/builder/models/Source/constants'

export enum SELECTION_TYPE {
  SET = 'set',
  MERGE = 'merge',
  TOGGLE = 'toggle',
}

export const SIMPLE_BUILDER_MAX_PRE_PUBLISH_BLOCKS_NUMBER = 5

export const SIMPLE_BUILDER_CONFIG = {
  forbiddenBlocks: [
    BlockType.FORM_QUESTION,
    BlockType.DYNAMIC,
    BlockType.DELAY,
    AttachmentBlockAttachmentType.FILE,
    AttachmentBlockAttachmentType.AUDIO,
    BlockType.ONE_TIME_NOTIFY_REQUEST,
  ],
  isSimple: true,
  disableContentPersonalization: true,
  stepsBlackList: [],
  stepsWhiteList: [NextStepType.FLOW, NextStepType.URL, NextStepType.CTA_URL],
  deliveredUniqueUsers: 0,
}

const newCgtConfig = {
  forbiddenBlocks: [
    BlockType.FORM_QUESTION,
    BlockType.LIST,
    BlockType.DELAY,
    BlockType.DYNAMIC,
    AttachmentBlockAttachmentType.FILE,
    BlockType.ONE_TIME_NOTIFY_REQUEST,
  ],
  isCgt: true,
  hintForDisableMode: {
    ['data-title']:
      'Auto-response can contain one block only. Please delete current block to use another one.',
    ['data-title-at']: 'top',
  },
  stepsBlackList: [],
  stepsWhiteList: [NextStepType.FLOW, NextStepType.URL],
  deliveredUniqueUsers: 0,
  disableImagePasting: true,
  disableSlider: true,
}

export const COMMENT_GROWTH_TOOLS_SIMPLE_BUILDER_CONFIG = newCgtConfig

export const SPONSORED_MESSAGES_SIMPLE_BUILDER_CONFIG = {
  forbiddenBlocks: without(
    [...Object.values(BlockType), ...Object.values(AttachmentBlockAttachmentType)],
    BlockType.CARD,
  ),
  isSimple: true,
  disableContentPersonalization: true,
  isSponsoredMessage: true,
  stepsBlackList: [],
  stepsWhiteList: [NextStepType.FLOW, NextStepType.URL],
  deliveredUniqueUsers: 0,
  disableButton: true,
  disableImagePasting: true,
  disableSlider: true,
}

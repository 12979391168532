import React from 'react'
import cx from 'classnames'

import cm from './PopoverList.module.css'

const PopoverList = ({
  children,
  style = {},
  className,
}: {
  className?: string
  style?: React.CSSProperties
  children?: React.ReactNode
}) => {
  const elements = Array.isArray(children) ? children : [children]
  return (
    <ul data-test-id="mui-popover" className={cx(cm.wrapper, className)} style={style}>
      {elements.map((element, index) => (
        <li data-test-id={`mui-popover-option-${index}`} key={index}>
          {element}
        </li>
      ))}
    </ul>
  )
}

export default PopoverList

enum ActionTypes {
  APPLICATION_ACTION_SELECT_APPLICATION = 'APPLICATION_ACTION_SELECT_APPLICATION',
  APPLICATION_ACTION_DESELECT_APPLICATION = 'APPLICATION_ACTION_DESELECT_APPLICATION',

  APPLICATION_ACTIONS_FETCH = 'APPLICATION_ACTIONS_FETCH',
  APPLICATION_ACTIONS_FETCH_RESPONSE = 'APPLICATION_ACTIONS_FETCH_RESPONSE',

  APPLICATION_DESELECT_ACTION = 'APPLICATION_DESELECT_ACTION',
  APPLICATION_ACTION_FIELD_UPDATE = 'APPLICATION_ACTION_FIELD_UPDATE',
  APPLICATION_ACTION_FIELDS_SET = 'APPLICATION_ACTION_FIELDS_SET',

  APPLICATIONS_TRIGGERS_FETCH = 'APPLICATIONS_TRIGGERS_FETCH',
  APPLICATIONS_TRIGGERS_FETCH_RESPONSE = 'APPLICATIONS_TRIGGERS_FETCH_RESPONSE',

  APPLICATION_SELECTED_ACTION_FETCH = 'APPLICATION_SELECTED_ACTION_FETCH',
  APPLICATION_SELECTED_ACTION_FETCH_REQUEST = 'APPLICATION_SELECTED_ACTION_FETCH_REQUEST',
  APPLICATION_SELECTED_ACTION_FETCH_RESPONSE = 'APPLICATION_SELECTED_ACTION_FETCH_RESPONSE',

  APPLICATION_ACTION_SOURCES_RESET = 'APPLICATION_ACTION_SOURCES_RESET',
  APPLICATION_ACTION_SOURCES_FETCH = 'APPLICATION_ACTION_SOURCES_FETCH',
  APPLICATION_ACTION_SOURCES_FETCH_REQUEST = 'APPLICATION_ACTION_SOURCES_FETCH_REQUEST',
  APPLICATION_ACTION_SOURCES_FETCH_RESPONSE = 'APPLICATION_ACTION_SOURCES_FETCH_RESPONSE',
  APPLICATION_ACTION_SOURCES_FETCH_ERROR = 'APPLICATION_ACTION_SOURCES_FETCH_ERROR',

  APPLICATION_ACTION_FIELDS_VALIDATE = 'APPLICATION_ACTION_FIELDS_VALIDATE',
  APPLICATION_ACTION_FIELD_VALIDATE = 'APPLICATION_ACTION_FIELD_VALIDATE',

  INTEGROMAT_WEBHOOKS_FETCH = 'INTEGROMAT_WEBHOOKS_FETCH',
  INTEGROMAT_WEBHOOKS_FETCH_REQUEST = 'INTEGROMAT_WEBHOOKS_FETCH_REQUEST',
  INTEGROMAT_WEBHOOKS_FETCH_RESPONSE = 'INTEGROMAT_WEBHOOKS_FETCH_RESPONSE',
  INTEGROMAT_WEBHOOKS_FETCH_ERROR = 'INTEGROMAT_WEBHOOKS_FETCH_ERROR',
  INTEGROMAT_WEBHOOK_CREATED_NOTIFICATION = 'INTEGROMAT_WEBHOOK_CREATED_NOTIFICATION',
  INTEGROMAT_WEBHOOK_REMOVED_NOTIFICATION = 'INTEGROMAT_WEBHOOK_REMOVED_NOTIFICATION',
}

export default ActionTypes

import React from 'react'

import {
  DocumentVisibilityState,
  LOCAL_STORAGE_KEY_TABS_STATE_MAP,
} from 'apps/chat/constants/LiveChatNotifications'
import { usePageVisibilityState } from 'common/hooks/usePageVisibilityState'
import { getSafeStorageEvent } from 'utils/getSafeStorageEvent'
import localStorage from 'utils/localStorage'
import errorTrackingService from 'utils/services/errorTrackingService'

function getIsAnyTabActive(tabsMap: Record<number, DocumentVisibilityState>) {
  return Boolean(Object.values(tabsMap)?.some((state) => state === DocumentVisibilityState.VISIBLE))
}

export const useIsAnyTabActive = (tabID: number) => {
  const visibilityState = usePageVisibilityState()
  const tabsStateMapRef = React.useRef<Record<string, string>>({})
  const isAnyTabActiveRef = React.useRef(true)
  const [isAnyTabActiveState, setIsAnyTabActiveState] = React.useState(true)

  const getLSTabsStateMap = React.useCallback((lsField?: string) => {
    const lsTabsStateMap = lsField || localStorage.getItem(LOCAL_STORAGE_KEY_TABS_STATE_MAP) || null
    let tabsStateMap: Record<string, DocumentVisibilityState> = {}

    try {
      tabsStateMap = JSON.parse(lsTabsStateMap) || {}
    } catch (err) {
      errorTrackingService.trackWarning(err, {
        fingerprint: 'json-parse-ls-is-any-tab-active',
      })
    }

    return tabsStateMap
  }, [])

  const setCurrentTabValueInTabStateMap = React.useCallback(
    ({ visibilityState, remove }: { visibilityState?: string; remove?: boolean }) => {
      const isCurrentTabVisible = visibilityState === DocumentVisibilityState.VISIBLE
      let tabsStateMap = getLSTabsStateMap()

      if (isCurrentTabVisible && !remove) {
        tabsStateMap = { [tabID]: visibilityState }
      } else {
        delete tabsStateMap[tabID]
      }

      const isAnyTabActive = getIsAnyTabActive(tabsStateMap)

      setIsAnyTabActiveState(isAnyTabActive)
      isAnyTabActiveRef.current = isAnyTabActive
      localStorage.setItem(LOCAL_STORAGE_KEY_TABS_STATE_MAP, JSON.stringify(tabsStateMap))
    },
    [tabID, getLSTabsStateMap],
  )

  const handleLSTabVisibilityFieldChanged = React.useCallback(
    (event: StorageEvent) => {
      const { key, value } = getSafeStorageEvent(event)
      if (key === LOCAL_STORAGE_KEY_TABS_STATE_MAP && value) {
        const tabsStateMap = getLSTabsStateMap(value)
        const isAnyTabActive = getIsAnyTabActive(tabsStateMap)

        setIsAnyTabActiveState(isAnyTabActive)
        isAnyTabActiveRef.current = isAnyTabActive
        tabsStateMapRef.current = tabsStateMap
      }
    },
    [getLSTabsStateMap],
  )

  const handleBeforeUnload = React.useCallback(() => {
    setCurrentTabValueInTabStateMap({ remove: true })
  }, [setCurrentTabValueInTabStateMap])

  React.useEffect(() => {
    setCurrentTabValueInTabStateMap({ visibilityState })
  }, [visibilityState, setCurrentTabValueInTabStateMap])

  React.useEffect(() => {
    window.addEventListener('storage', handleLSTabVisibilityFieldChanged)
    window.addEventListener('beforeunload', handleBeforeUnload)

    return () => {
      window.removeEventListener('storage', handleLSTabVisibilityFieldChanged)
      window.removeEventListener('beforeunload', handleBeforeUnload)
    }
  }, [handleBeforeUnload, handleLSTabVisibilityFieldChanged])

  return {
    isAnyTabActiveRef,
    isAnyTabActiveState,
  }
}

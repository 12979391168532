import BaseBlockConfig from 'common/builder/blocks/BaseBlockConfig'
import { ListBlockTopElementStyle } from 'common/builder/blocks/blockConstants'
import { IBlockBase, IListBlock } from 'common/builder/blocks/blockInterfaces'
import { BlockType } from 'common/builder/constants/BlockType'

class ListBlockConfig extends BaseBlockConfig {
  blockType = BlockType.LIST

  getDefaults = (): Omit<IListBlock, keyof IBlockBase> => ({
    topElementStyle: ListBlockTopElementStyle.COMPACT,
  })
}

export default new ListBlockConfig()

import fromPairs from 'lodash/fromPairs'
import pick from 'lodash/pick'
import { l } from '@manychat/manyui'

import { setRoot } from 'common/builder/actions/builderStateActions'
import { createTrigger } from 'common/builder/actions/builderTriggerActions'
import { deleteNode } from 'common/builder/actions/deleteNode'
import { pasteNodes } from 'common/builder/actions/pasteNodes'
import { updateBuilderState } from 'common/builder/actions/updateBuilderState'
import { updateCoordinates } from 'common/builder/actions/updateCoordinates'
import {
  isCGTTemplate,
  isStoryReplyTemplate,
} from 'common/builder/components/chart/FlowChart/IgOnboardingTourStartBlock/helpers/getIgOnboardingTourStartConfig'
import * as atypes from 'common/builder/constants/builderReduxActionTypes'
import { NodeType } from 'common/builder/constants/NodeType'
import {
  replaceBlockRelatedData,
  replaceNodeRelatedData,
  replaceTriggerRelatedData,
} from 'common/builder/models/FlowCampaign/flowCampaignHelpers'
import {
  IBuilderFlowCampaignPreset,
  IBuilderFlowCampaignRelatedData,
  IBuilderFlowCampaignsBlock,
} from 'common/builder/models/FlowCampaign/flowCampaignInterfaces'
import { INode } from 'common/builder/nodes/nodeInterfaces'
import * as builderStateSelectors from 'common/builder/selectors/builder/builderStateSelectors'
import * as flowCampaignSelectors from 'common/builder/selectors/builder/flowCampaignSelectors'
import { alert } from 'common/core'
import { IAsyncThunkAction, IThunkAction } from 'common/core/interfaces/actions'
import { getPageId } from 'common/core/selectors/appSelectors'
import * as userFieldActions from 'common/fields/actions/userFieldsActions'
import { TriggerType } from 'common/flow/constants/TriggerType'
import { IFlowTrigger, IFullFlow } from 'common/flow/flowInterfaces'
import * as flowSelectors from 'common/flow/selectors/flowSelectors'
import { TagsActions } from 'common/tags/store'
import * as API from 'constants/API'
import errorTrackingService from 'utils/services/errorTrackingService'
import { alertWithHotjarEventFactory } from 'utils/services/hotjar'

const alertWithHotjarEvent = alertWithHotjarEventFactory('flow_builder_error')

export const initFlowCampaign = (
  builderId: string,
  campaignPreset: IBuilderFlowCampaignPreset,
): IAsyncThunkAction<INode | null> => {
  return async (dispatch) => {
    try {
      if (campaignPreset.tags.length) {
        await dispatch(TagsActions.getListFx()).unwrap()
      }
      const campaignTagsPairs = await Promise.all(
        campaignPreset.tags.map(async ({ id, name }) => {
          const tag = await dispatch(
            TagsActions.ensureTagFx({ tagName: name, silent: true }),
          ).unwrap()
          return [id, tag]
        }),
      )
      const campaignTagsPairsFiltered = campaignTagsPairs.filter(([, tag]) => Boolean(tag))
      const tagsMap = fromPairs(campaignTagsPairsFiltered)

      if (campaignPreset.cufs.length) {
        await dispatch(userFieldActions.fetchUserFields())
      }
      const campaignUserFieldPairs = await Promise.all(
        campaignPreset.cufs.map(async ({ id, caption, type, description }) => {
          const cuf = await dispatch(
            userFieldActions.ensureField(
              { caption, type, description },
              {
                silentErrors: true,
                silent: true,
              },
            ),
          )
          return [id, cuf]
        }),
      )
      const campaignUserFieldPairsFiltered = campaignUserFieldPairs.filter(([, cuf]) =>
        Boolean(cuf),
      )
      const userFieldMap = fromPairs(campaignUserFieldPairsFiltered)

      const attachmentsMap = fromPairs(campaignPreset.attachments.map(({ id, data }) => [id, data]))

      const relatedData: IBuilderFlowCampaignRelatedData = {
        tagsMap,
        userFieldMap,
        attachmentsMap,
      }

      const nodesData: IBuilderFlowCampaignPreset['nodesData'] = []

      for (const { node: originalNode, related } of campaignPreset.nodesData) {
        const blocks = related.blocks.map((block) => replaceBlockRelatedData(block, relatedData))
        const node = replaceNodeRelatedData(originalNode, relatedData)

        nodesData.push({
          node,
          related: { ...related, blocks: blocks as IBuilderFlowCampaignsBlock[] },
        })
      }

      const { copyMap } = dispatch(
        pasteNodes(
          builderId,
          { nodesData, coordinates: campaignPreset.coordinates, offsets: null },
          { setSelection: false },
        ),
      )
      dispatch(
        updateCoordinates(builderId, pick(campaignPreset.coordinates, NodeType.STARTING_STEP)),
      )

      const options = { preventOpen: true, addHomeTabParams: true }
      await Promise.all(
        campaignPreset.triggers.map((trigger) => {
          const { nodeType, ...initial } = replaceTriggerRelatedData(trigger, relatedData)
          return dispatch(createTrigger(builderId, nodeType, initial, options))
        }),
      )

      // eslint-disable-next-line
      // @ts-expect-error
      return copyMap[campaignPreset.rootId]
    } catch (err) {
      alertWithHotjarEvent(l.translate('Something went wrong'), 'danger')
      if (err instanceof Error || typeof err === 'string') {
        errorTrackingService.logCritical(err, {
          scope: 'root',
          section: null,
          fingerprint: 'init-flow-campaign',
        })
      }

      return null
    }
  }
}

export const linkFlowCampaign = (builderId: string, campaignId: number): IThunkAction => {
  return async (dispatch, getState) => {
    await Promise.all([
      dispatch(TagsActions.getListFx()).unwrap(),
      dispatch(userFieldActions.fetchUserFields()),
    ])
    const state = getState()
    const data = flowCampaignSelectors.exportFlowCampaign(state, builderId)
    const builderState = builderStateSelectors.getById(state, builderId)
    const widgetTriggers = data.triggers.filter((node) => node.nodeType === TriggerType.WIDGET)
    await dispatch({
      type: atypes.SET_QUICK_CAMPAIGN_DATA,
      $fetch: [
        API.quickCampaign.endpoints.setData,
        {
          method: 'POST',
          body: JSON.stringify({
            quick_campaign_id: campaignId,
            data,
            flow_data: {
              flow_ns: builderState.flow,
              account_id: getPageId(state),
              growth_tools_reserved: widgetTriggers.length,
            },
          }),
          headers: { 'Content-Type': 'application/json' },
        },
      ],
      $success: () => {
        alert(l.translate('Automation has been connected to campaign.'), 'success')
      },
    })
  }
}

export const openMostSuitableTrigger = (
  builderId: string,
  triggers: IFlowTrigger[],
): IThunkAction => {
  return (dispatch) => {
    if (triggers.length === 1) {
      dispatch(
        updateBuilderState(builderId, {
          sidebarHidden: false,
          selectedTrigger: triggers[0].triggerId,
        }),
      )

      return
    }

    const preActivatableTrigger = triggers.find((trigger) =>
      flowSelectors.preActivatableTriggersTypes.includes(trigger.triggerType),
    )

    dispatch(
      updateBuilderState(builderId, {
        sidebarHidden: false,
        selectedTrigger: preActivatableTrigger?.triggerId ?? triggers[0].triggerId,
      }),
    )
  }
}

export const fetchAndInitFlowCampaign = (
  builderId: string,
  campaignId: number,
): IAsyncThunkAction<INode | null> => {
  return async (dispatch, getState) => {
    const data = await dispatch({
      type: atypes.GET_QUICK_CAMPAIGN_DATA,
      $fetch: [
        API.quickCampaign.endpoints.fetchItem,
        {
          method: 'POST',
          body: JSON.stringify({
            quick_campaign_id: campaignId,
            account_id: getPageId(getState()),
          }),
          headers: { 'Content-Type': 'application/json' },
        },
      ],
    })
    // eslint-disable-next-line
    // @ts-expect-error
    const campaignPreset: IBuilderFlowCampaignPreset = data.data as IBuilderFlowCampaignPreset
    const rootNode = await dispatch(initFlowCampaign(builderId, campaignPreset))

    const builderState = builderStateSelectors.getById(getState(), builderId)

    if (!builderState) {
      return rootNode
    }

    const flow = flowSelectors.getById(getState(), builderState.flow) as IFullFlow

    if (
      flow &&
      flow.triggers.length > 0 &&
      !isCGTTemplate(campaignId) &&
      !isStoryReplyTemplate(campaignId)
    ) {
      dispatch(openMostSuitableTrigger(builderId, flow.triggers))
    }

    return rootNode
  }
}

export const fetchAndInitFlowCampaignForCurrentFlow = (
  builderId: string,
  campaignId: number,
): IThunkAction => {
  return async (dispatch, getState) => {
    dispatch(updateBuilderState(builderId, { isLoading: true, transform: {} }))

    const state = getState()
    const rootNode = await dispatch(fetchAndInitFlowCampaign(builderId, campaignId))

    if (!rootNode) return

    const currentRootNode = builderStateSelectors.getRootNode(state, builderId)
    const builderState = builderStateSelectors.getById(state, builderId)

    dispatch(setRoot(builderId, rootNode.id))
    if (currentRootNode) {
      dispatch(deleteNode(builderId, currentRootNode?.id))
    }

    await dispatch({
      type: atypes.SET_QUICK_CAMPAIGN_ID,
      $fetch: [
        API.flow.endpoints.setQuickCampaignId,
        {
          method: 'POST',
          body: JSON.stringify({
            quick_campaign_id: campaignId,
            flow_ns: builderState.flow,
          }),
          headers: { 'Content-Type': 'application/json' },
        },
      ],
      flowId: builderState.flow,
      campaignId,
    })

    dispatch(
      updateBuilderState(builderId, {
        isLoading: false,
        quick_campaign_data: {
          quick_campaign_id: campaignId,
        },
      }),
    )
  }
}

import { IActionWithPayload } from 'common/core/interfaces/actions'
import { IFolder } from 'common/core/interfaces/folder'
import { makeLimitedListReducer } from 'utils/factory'
import type { ILimitedList } from 'utils/factory/limitedList/interfaces'
import type { ILimitedListAction } from 'utils/factory/limitedList/interfaces/limitedListAction'

const listReducer = makeLimitedListReducer('globalFieldFolders')

export default function (state: ILimitedList<IFolder>, action: IActionWithPayload) {
  return listReducer(state, action as ILimitedListAction)
}

import { AiIntentTrigger } from 'apps/aiIntents/interfaces/interfaces'
import { TriggerType } from 'common/flow/constants/TriggerType'
import { ProtoIntent } from 'shared/api/requests/aiAssistant/schemas'

export const toAiIntent = (trigger: ProtoIntent): AiIntentTrigger => {
  const id = 4
  const intent = trigger.intents?.[0] ?? ''

  return {
    id,
    channel: trigger.channel,

    triggerType: TriggerType.AI_INTENT,
    triggerId: `${TriggerType.AI_INTENT}-${id}`,

    intent,

    flowId: '',
    chId: '',
    enabled: false,
    caption: intent,

    ts_created: 1,

    was_activated_once: false,

    runs: null,
    ctr: null,
  }
}

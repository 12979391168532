// Types of answers for questions accepted on the backend
enum BackendQuestionMessageAnswerType {
  TEXT = 'text',
  NUMBER = 'number',
  FIRST_NAME = 'first_name',
  LAST_NAME = 'last_name',
  EMAIL = 'email',
  PHONE = 'phone',
  PHONE_FOR_SMS = 'phone_for_sms',
  URL = 'url',
  FILE = 'file',
  IMAGE = 'image',
  LOCATION = 'location',
  MC_LOCATION = 'mc_location',
  DATE = 'date',
  DATE_TIME = 'datetime',
}

export default BackendQuestionMessageAnswerType

import React from 'react'
import cx from 'classnames'
import { l } from '@manychat/manyui'

import { ChannelType } from 'common/core/constants/ChannelType'
import { useGetPermissionHref } from 'common/core/hooks/useGetPermissionHref'
import { analyticsService } from 'utils/services/analytics'

import cm from '../StaticNotifications.module.css'

export const InstagramPageConnectionLost = () => {
  const { getPermissionHref } = useGetPermissionHref()
  const url = getPermissionHref(ChannelType.INSTAGRAM, window.location.href)

  const handleRefresh = () => {
    analyticsService.sendEvent('IG_REFRESH_ADMIN_PERMISSIONS_BANNER.CLICKED')
  }
  return (
    <>
      <span>
        {l.translate(
          'Instagram channel lost connection with this account. Instagram page admin needs to <l10n context="Refresh Permissions" />',
        )}
      </span>
      <a href={url} onClick={handleRefresh} className={cx(cm.more, 'm-l-xxs')}>
        {l.translate('Refresh Permissions')}
      </a>
    </>
  )
}

// APPLICATIONS
// create
export const APPLICATION_CREATE = 'APPLICATION_CREATE'
export const APPLICATION_CREATE_RESPONSE = 'APPLICATION_CREATE_RESPONSE'
export const APPLICATION_CREATE_ERROR = 'APPLICATION_CREATE_ERROR'

// APPLICATION
// update
export const APPLICATION_UPDATE = 'APPLICATION_UPDATE'
export const APPLICATION_UPDATE_REQUEST = 'APPLICATION_UPDATE_REQUEST'
export const APPLICATION_UPDATE_RESPONSE = 'APPLICATION_UPDATE_RESPONSE'
export const APPLICATION_UPDATE_ERROR = 'APPLICATION_UPDATE_ERROR'
export const APPLICATION_ERRORS_CLEAN = 'APPLICATION_ERRORS_CLEAN'

// accounts
export const APPLICATION_ACCOUNTS_FETCH = 'APPLICATION_ACCOUNTS_FETCH'
export const APPLICATION_ACCOUNTS_FETCH_REQUEST = 'APPLICATION_ACCOUNTS_FETCH_REQUEST'
export const APPLICATION_ACCOUNTS_FETCH_RESPONSE = 'APPLICATION_ACCOUNTS_FETCH_RESPONSE'
export const APPLICATION_ACCOUNTS_FETCH_ERROR = 'APPLICATION_ACCOUNTS_FETCH_ERROR'

// install
export const APPLICATION_INSTALL_FETCH = 'APPLICATION_INSTALL_FETCH'
export const APPLICATION_INSTALL_FETCH_REQUEST = 'APPLICATION_INSTALL_FETCH_REQUEST'
export const APPLICATION_INSTALL_FETCH_RESPONSE = 'APPLICATION_INSTALL_FETCH_RESPONSE'
export const APPLICATION_INSTALL_FETCH_ERROR = 'APPLICATION_INSTALL_FETCH_ERROR'

// uninstall
export const APPLICATION_UNINSTALL_FETCH = 'APPLICATION_UNINSTALL_FETCH'
export const APPLICATION_UNINSTALL_FETCH_REQUEST = 'APPLICATION_UNINSTALL_FETCH_REQUEST'
export const APPLICATION_UNINSTALL_FETCH_RESPONSE = 'APPLICATION_UNINSTALL_FETCH_RESPONSE'
export const APPLICATION_UNINSTALL_FETCH_ERROR = 'APPLICATION_UNINSTALL_FETCH_ERROR'

// settings
export const APPLICATION_SETTINGS_FETCH = 'APPLICATION_SETTINGS_FETCH'
export const APPLICATION_SETTINGS_FETCH_REQUEST = 'APPLICATION_SETTINGS_FETCH_REQUEST'
export const APPLICATION_SETTINGS_FETCH_RESPONSE = 'APPLICATION_SETTINGS_FETCH_RESPONSE'
export const APPLICATION_SETTINGS_FETCH_ERROR = 'APPLICATION_SETTINGS_FETCH_ERROR'

// updateSettings
export const APPLICATION_SETTINGS_UPDATE = 'APPLICATION_SETTINGS_UPDATE'
export const APPLICATION_SETTINGS_UPDATE_REQUEST = 'APPLICATION_SETTINGS_UPDATE_REQUEST'
export const APPLICATION_SETTINGS_UPDATE_RESPONSE = 'APPLICATION_SETTINGS_UPDATE_RESPONSE'
export const APPLICATION_SETTINGS_UPDATE_ERROR = 'APPLICATION_SETTINGS_UPDATE_ERROR'

// change install/uninstall status
export const CHANGE_INSTALL_STATUS = 'CHANGE_INSTALL_STATUS'

// current
export const APPLICATIONS_CURRENT_FETCH = 'APPLICATIONS_CURRENT_FETCH'
export const APPLICATIONS_CURRENT_FETCH_REQUEST = 'APPLICATIONS_CURRENT_FETCH_REQUEST'
export const APPLICATIONS_CURRENT_FETCH_RESPONSE = 'APPLICATIONS_CURRENT_FETCH_RESPONSE'
export const APPLICATIONS_CURRENT_FETCH_ERROR = 'APPLICATIONS_CURRENT_FETCH_ERROR'

// list
export const APPLICATIONS_FETCH = 'APPLICATIONS_FETCH'
export const APPLICATIONS_FETCH_REQUEST = 'APPLICATIONS_FETCH_REQUEST'
export const APPLICATIONS_FETCH_RESPONSE = 'APPLICATIONS_FETCH_RESPONSE'
export const APPLICATIONS_FETCH_ERROR = 'APPLICATIONS_FETCH_ERROR'

// publishing
export const APPLICATION_PUBLISH_MODAL_OPEN = 'APPLICATION_PUBLISH_MODAL_OPEN'
export const APPLICATION_PUBLISH_MODAL_CLOSE = 'APPLICATION_PUBLISH_MODAL_CLOSE'
export const APPLICATION_PUBLISH = 'APPLICATION_PUBLISH'
export const APPLICATION_PUBLISH_REQUEST = 'APPLICATION_PUBLISH_REQUEST'
export const APPLICATION_PUBLISH_RESPONSE = 'APPLICATION_PUBLISH_RESPONSE'
export const APPLICATION_PUBLISH_ERROR = 'APPLICATION_PUBLISH_ERROR'

// review
export const APPLICATION_REVIEW_REPORT_MODAL_OPEN = 'APPLICATION_REVIEW_REPORT_MODAL_OPEN'
export const APPLICATION_REVIEW_REPORT_MODAL_CLOSE = 'APPLICATION_REVIEW_REPORT_MODAL_CLOSE'
export const APPLICATION_REVIEW_REPORT_FETCH = 'APPLICATION_REVIEW_REPORT_FETCH'
export const APPLICATION_REVIEW_REPORT_FETCH_REQUEST = 'APPLICATION_REVIEW_REPORT_FETCH_REQUEST'
export const APPLICATION_REVIEW_REPORT_FETCH_RESPONSE = 'APPLICATION_REVIEW_REPORT_FETCH_RESPONSE'
export const APPLICATION_REVIEW_REPORT_FETCH_ERROR = 'APPLICATION_REVIEW_REPORT_FETCH_ERROR'

// avatar
export const APPLICATION_AVATAR_UPLOAD = 'APPLICATION_AVATAR_UPLOAD'

import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import { ChatNotifications } from 'apps/chat/models/chatInterfaces'

const initialState: ChatNotifications = {
  messageReceived: null,
  threadAssignedToMe: null,
  threadUnassigned: null,
}

const stateSlice = createSlice({
  name: 'chat/notifications',
  initialState,
  reducers: {
    setMessageReceived(state, action: PayloadAction<ChatNotifications['messageReceived']>) {
      state.messageReceived = Object.assign({}, action.payload)
    },
    resetMessageReceived(state) {
      state.messageReceived = null
    },
    setThreadAssignedToMe(state, action: PayloadAction<ChatNotifications['threadAssignedToMe']>) {
      state.threadAssignedToMe = Object.assign({}, action.payload)
    },
    resetThreadAssignedToMe(state) {
      state.threadAssignedToMe = null
    },
    setThreadUnassigned(state, action: PayloadAction<ChatNotifications['threadUnassigned']>) {
      state.threadUnassigned = Object.assign({}, action.payload)
    },
    resetThreadUnassigned(state) {
      state.threadUnassigned = null
    },
  },
})

export const {
  setMessageReceived,
  resetMessageReceived,
  setThreadAssignedToMe,
  resetThreadAssignedToMe,
  setThreadUnassigned,
  resetThreadUnassigned,
} = stateSlice.actions

export const { reducer } = stateSlice

export const getMessageReceivedNotificationData = (state: RootState) =>
  state?.chat?.notifications?.messageReceived

export const getThreadAssignedToMeNotificationData = (state: RootState) =>
  state?.chat?.notifications?.threadAssignedToMe

export const getThreadUnassignedNotificationData = (state: RootState) =>
  state?.chat?.notifications?.threadUnassigned

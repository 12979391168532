//templates
export const TEMPLATE_DRAFT_INIT = 'TEMPLATE_DRAFT_INIT'
export const TEMPLATE_DRAFT_UPDATE = 'TEMPLATE_DRAFT_UPDATE'
export const TEMPLATE_DRAFT_SELECTION_SET = 'TEMPLATE_DRAFT_SELECTION_SET'
export const TEMPLATE_DRAFT_SELECTION_ALL = 'TEMPLATE_DRAFT_SELECTION_ALL'
export const TEMPLATE_DRAFT_SELECTION_CLEAR = 'TEMPLATE_DRAFT_SELECTION_CLEAR'
export const TEMPLATE_DRAFT_BOT_FIELD_UPDATE = 'TEMPLATE_DRAFT_BOT_FIELD_UPDATE'
export const TEMPLATE_DRAFT_BOT_FIELDS_SORT = 'TEMPLATE_DRAFT_BOT_FIELDS_SORT'

export const TEMPLATES_CREATE_REQUEST = 'TEMPLATES_CREATE_REQUEST'
export const TEMPLATES_CREATE_RESPONSE = 'TEMPLATES_CREATE_RESPONSE'
export const TEMPLATES_CREATE_ERROR = 'TEMPLATES_CREATE_ERROR'

export const TEMPLATES_CURRENT_SAVECONTENT_REQUEST = 'TEMPLATES_CURRENT_SAVECONTENT_REQUEST'
export const TEMPLATES_CURRENT_SAVECONTENT_RESPONSE = 'TEMPLATES_CURRENT_SAVECONTENT_RESPONSE'
export const TEMPLATES_CURRENT_SAVECONTENT_ERROR = 'TEMPLATES_CURRENT_SAVECONTENT_ERROR'

export const TEMPLATES_CURRENT_UPDATEAVATAR_RESPONSE = 'TEMPLATES_CURRENT_UPDATEAVATAR_RESPONSE'

export const TEMPLATES_NOTIFIACTIONS_UPDATE = 'TEMPLATES_NOTIFIACTIONS_UPDATE'
export const TEMPLATES_NOTIFIACTIONS_UPDATE_STRUCTURE = 'TEMPLATES_NOTIFIACTIONS_UPDATE_STRUCTURE'
export const TEMPLATES_INSTALLATION_STATUS_UPDATE = 'TEMPLATES_INSTALLATION_STATUS_UPDATE'
export const TEMPLATES_INSTALLATION_RESET = 'TEMPLATES_INSTALLATION_RESET'

export const TEMPLATEINSTALLATIONS_FETCH_RESPONSE = 'TEMPLATEINSTALLATIONS_FETCH_RESPONSE'
export const TEMPLATEINSTALLATIONS_DEACTIVATE_REQUEST = 'TEMPLATEINSTALLATIONS_DEACTIVATE_REQUEST'
export const TEMPLATEINSTALLATIONS_ACTIVATEFORPAGE_REQUEST =
  'TEMPLATEINSTALLATIONS_ACTIVATEFORPAGE_REQUEST'

export const TEMPLATE_UPLOAD_AVATAR = 'TEMPLATE_UPLOAD_AVATAR'
export const TEMPLATE_WARN_PROTECTION = 'TEMPLATE_WARN_PROTECTION'
export const TEMPLATE_FECTH_SINGLEUSE_LINK = 'TEMPLATE_FECTH_SINGLEUSE_LINK'
export const TEMPLATE_FECTH_SINGLEUSE_LINK_REQUEST = 'TEMPLATE_FECTH_SINGLEUSE_LINK_REQUEST'
export const TEMPLATE_FECTH_SINGLEUSE_LINK_RESPONSE = 'TEMPLATE_FECTH_SINGLEUSE_LINK_RESPONSE'

export const TEMPLATE_SET_SHARE_TYPE = 'TEMPLATE_SET_SHARE_TYPE'
export const TEMPLATE_SET_SHARE_TYPE_RESPONSE = 'TEMPLATE_SET_SHARE_TYPE_RESPONSE'
export const DELETE_TEMPLATE_FROM_STORE = 'DELETE_TEMPLATE_FROM_STORE'

//template installation
export const RESOLVE_CONFLICT = 'RESOLVE_CONFLICT'
export const SET_SETTINGS = 'SET_SETTINGS'
export const BOT_TEMPLATE_FETCH = 'BOT_TEMPLATE_FETCH'
export const BOT_TEMPLATE_FETCH_REQUEST = 'BOT_TEMPLATE_FETCH_REQUEST'
export const BOT_TEMPLATE_FETCH_RESPONSE = 'BOT_TEMPLATE_FETCH_RESPONSE'
export const BOT_TEMPLATE_FETCH_ERROR = 'BOT_TEMPLATE_FETCH_ERROR'

export const BOT_TEMPLATE_GENERAL_ELEMENT_FETCH = 'BOT_TEMPLATE_GENERAL_ELEMENT_FETCH'
export const BOT_TEMPLATE_GENERAL_ELEMENT_FETCH_REQUEST =
  'BOT_TEMPLATE_GENERAL_ELEMENT_FETCH_REQUEST'
export const BOT_TEMPLATE_GENERAL_ELEMENT_FETCH_RESPONSE =
  'BOT_TEMPLATE_GENERAL_ELEMENT_FETCH_RESPONSE'
export const BOT_TEMPLATE_GENERAL_ELEMENT_FETCH_ERROR = 'BOT_TEMPLATE_GENERAL_ELEMENT_FETCH_ERROR'

export const BOT_TEMPLATE_INSTALL = 'BOT_TEMPLATE_INSTALL'
export const BOT_TEMPLATE_INSTALL_REQUEST = 'BOT_TEMPLATE_INSTALL_REQUEST'
export const BOT_TEMPLATE_INSTALL_RESPONSE = 'BOT_TEMPLATE_INSTALL_RESPONSE'
export const BOT_TEMPLATE_INSTALL_ERROR = 'BOT_TEMPLATE_INSTALL_ERROR'
export const DELETE_INSTALLED_TEMPLATE = 'DELETE_INSTALLED_TEMPLATE'

export const INSTALLED_TEMPLATE_EDIT_PROTECTION = 'INSTALLED_TEMPLATE_EDIT_PROTECTION'
export const INSTALLED_TEMPLATE_EDIT_PROTECTION_REQUEST =
  'INSTALLED_TEMPLATE_EDIT_PROTECTION_REQUEST'

export const INSTALLED_TEMPLATE_EDIT_PROTECTION_RESPONSE =
  'INSTALLED_TEMPLATE_EDIT_PROTECTION_RESPONSE'

export const CONTENTMAP_CURRENT_GETFOLDERS_REQUEST = 'CONTENTMAP_CURRENT_GETFOLDERS_REQUEST'
export const CONTENTMAP_CURRENT_GETFOLDERS_ERROR = 'CONTENTMAP_CURRENT_GETFOLDERS_ERROR'
export const CONTENTMAP_CURRENT_GETFOLDERS_RESPONSE = 'CONTENTMAP_CURRENT_GETFOLDERS_RESPONSE'

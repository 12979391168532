enum PaymentCurrencies {
  USD = 'USD',
  EUR = 'EUR',
  GBP = 'GBP',
  CAD = 'CAD',
  AUD = 'AUD',
  BRL = 'BRL',
  HKD = 'HKD',
  JPY = 'JPY',
  NZD = 'NZD',
  MXN = 'MXN',
  CHF = 'CHF',
  DKK = 'DKK',
  NOK = 'NOK',
  SGD = 'SGD',
  SEK = 'SEK',
  RUB = 'RUB',
}

export default PaymentCurrencies

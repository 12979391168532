import React from 'react'
import { sanitizeURL } from '@manychat/manyui'

import { SettingsTabType } from 'common/settings/constants/SettingsTabType'
import { getWidgetCodeLayout } from 'constants/Widget'
import { linkURL } from 'utils/url'

import * as helpers from '../models/Widget/helpers'

export const FB_REPLY_LIMIT = 2000

const Validation = {
  WHITELIST_REQUIRED: 'WHITELIST_REQUIRED',
  HTTPS_REQUIRED: 'HTTPS_REQUIRED',
  HTTPS_NEEDED: 'HTTPS_NEEDED',
  INACTIVE_WIDGET: 'INACTIVE_WIDGET',
  CERTAIN_DEVICE_ONLY: 'CERTAIN_DEVICE_ONLY',
  RERUN_SETTINGS: 'RERUN_SETTINGS',
  ROUTE_DOESNT_MATCH: 'ROUTE_DOESNT_MATCH',
  SEVERAL_CUSTOMER_CHAT_ON_SAME_ROUTE: 'SEVERAL_CUSTOMER_CHAT_ON_SAME_ROUTE',
  // diagnostics
  MAIN_SCRIPT_DOESNT_INCLUDED: 'MAIN_SCRIPT_DOESNT_INCLUDED',
  SEVERAL_MAIN_SCRIPTS_INCLUDED: 'SEVERAL_MAIN_SCRIPTS_INCLUDED',
  ANOTHER_PAGE_MAIN_SCRIPT_INCLUDED: 'ANOTHER_PAGE_MAIN_SCRIPT_INCLUDED',
  EMBED_SNIPPET_NOT_FOUND: 'EMBED_SNIPPET_NOT_FOUND',
  EMBED_SNIPPET_INVALID_PAYLOAD: 'EMBED_SNIPPET_INVALID_PAYLOAD',
  CHECKBOX_SNIPPET_NOT_FOUND: 'CHECKBOX_SNIPPET_NOT_FOUND',
  SEVERAL_CHECKBOX_SNIPPETS: 'SEVERAL_CHECKBOX_SNIPPETS',
  MANUAL_CUSTOMER_CHAT_INSTALLATION: 'MANUAL_CUSTOMER_CHAT_INSTALLATION',
  CUSTOMER_CHAT_NO_REFERRER: 'CUSTOMER_CHAT_NO_REFERRER',
  CUSTOMER_CHAT_SDK_UNAVAILABLE: 'CUSTOMER_CHAT_SDK_UNAVAILABLE',
  OVERLAY_TARGET_ELEMENT_NOT_FOUND: 'OVERLAY_TARGET_ELEMENT_NOT_FOUND',
  LANGUAGE_UNAVAILABLE: 'LANGUAGE_UNAVAILABLE',
  REDIRECT_DETECTED: 'REDIRECT_DETECTED',
  BAD_STATUS_CODE: 'BAD_STATUS_CODE',
  // page wait
  MAIN_SCRIPT_NOT_LOADED: 'MAIN_SCRIPT_NOT_LOADED',
  FB_SDK_NOT_LOADED: 'FB_SDK_NOT_LOADED',
  // FB_SDK_VAR_UNAVAILABLE: 'FB_SDK_VAR_UNAVAILABLE',
  // MC_SDK_VAR_UNAVAILABLE: 'MC_SDK_VAR_UNAVAILABLE',
  EMBED_NOT_RENDERED: 'EMBED_NOT_RENDERED',
  EMBED_DISAPPEARED: 'EMBED_DISAPPEARED',
  EMBED_DOESNT_WORK: 'EMBED_DOESNT_WORK',
  CHECKBOX_NOT_RENDERED: 'CHECKBOX_NOT_RENDERED',
  CHECKBOX_DISAPPEARED: 'CHECKBOX_DISAPPEARED',
  CHECKBOX_DOESNT_WORK: 'CHECKBOX_DOESNT_WORK',
  CUSTOMER_CHAT_DOESNT_WORK: 'CUSTOMER_CHAT_DOESNT_WORK',
  OVERLAY_DOESNT_WORK: 'OVERLAY_DOESNT_WORK',
  MIXED_CONTENT_ISSUES: 'MIXED_CONTENT_ISSUES',
  DIAGNOSTICS_INCOMPLETE: 'DIAGNOSTICS_INCOMPLETE',
  // nav error
  WEBSITE_UNAVAILABLE: 'WEBSITE_UNAVAILABLE',
  WEBSITE_ERR_CERT: 'WEBSITE_ERR_CERT',
  // FB_REPLY error
  FB_REPLY_MAX_LENGTH: `Text must be less than ${FB_REPLY_LIMIT} characters long`,
}

export const MessageLevel = {
  ERROR: 'ERROR',
  INTERNAL_ERROR: 'INTERNAL_ERROR',
  WARNING: 'WARNING',
  INFO: 'INFO',
  SUCCESS: 'SUCCESS',
}

export const MessageLevelScore = {
  [MessageLevel.ERROR]: 1,
  [MessageLevel.INTERNAL_ERROR]: 2,
  [MessageLevel.WARNING]: 3,
  [MessageLevel.INFO]: 4,
}

export const MessageGroups = {
  SETTINGS: 'SETTINGS',
  MAIN_SCRIPT: 'MAIN_SCRIPT',
  SNIPPET: 'SNIPPET',
  VISIBILITY: 'VISIBILITY',
  OTHER: 'OTHER',
  NOTICE: 'NOTICE',
  DOMAIN: 'DOMAIN',
}

export const MessageGroupsDetails = {
  [MessageGroups.SETTINGS]: {
    title: 'Manychat growth tool settings status',
    success: 'Everything is configured correctly',
  },
  [MessageGroups.DOMAIN]: {
    title: 'Target page accessibility',
    success: 'Target page responds properly and can be loaded without issues',
  },
  [MessageGroups.MAIN_SCRIPT]: {
    title: 'SDK installation via JS-script code',
    success:
      'JS-script code is added correctly to the target page source code; SDK installed successfully',
  },
  [MessageGroups.SNIPPET]: {
    title: 'Widget code embedding',
    success: 'Embed code was added correctly to the target page source code',
  },
  [MessageGroups.VISIBILITY]: {
    title: 'Widget visibility',
    success: 'The widget is displayed on the page and should work correctly',
  },
  [MessageGroups.OTHER]: {
    title: 'Other',
  },
}

export const ValidationStatus = {
  SETTINGS_DONE: 'SETTINGS_DONE',
  NAVIGATE_ERROR: 'NAVIGATE_ERROR',
  DONE: 'DONE',
}

export const NavigateErrorTypes = {
  ERR_CERT: 'ERR_CERT',
  ERR_CONNECTION_REFUSED: 'ERR_CONNECTION_REFUSED',
  ERR_NAME_NOT_RESOLVED: 'ERR_NAME_NOT_RESOLVED',
  INVALID_URL: 'INVALID_URL',
  TIMEOUT: 'TIMEOUT',
  UNKNOWN: 'UNKNOWN',
}

export default Validation

export const ValidationDetails = {
  [Validation.WHITELIST_REQUIRED]: {
    level: MessageLevel.ERROR,
    group: MessageGroups.SETTINGS,
    title: 'Website domain was not added to the whitelist',
    desc: 'For security reasons, each website that hosts the target page with your widget must be added to the list of trusted resources. This is needed in order to prevent unauthorized usage of your widgets on other sites. You will not be able to use widgets on your target page until you whitelist the domain name of your website.',
    fix: ({ widget }) => (
      <div>
        To include your domain to the white list:
        <div className="m-y-xs">
          1. Go to{' '}
          <a
            className="text-primary"
            href={linkURL(`/growth-tools/widget/${widget.widget_id}#setup`)}
            target="_blank"
            rel="noreferrer"
          >
            Setup tab
          </a>{' '}
          of your widget <br />
          2. Click "Install JavaScript Snippet" button to open dialog window <br />
          3. In the "Authorize Website" tab type the domain name of the website that hosts your
          target page and click Add. From there you can also remove domains that should no longer be
          whitelisted. Note, that you can also manage your whitelisted domains from{' '}
          <a
            className="text-primary"
            href={linkURL(`/settings#${SettingsTabType.WEBSITE_WIDGETS}`)}
            target="_blank"
            rel="noreferrer"
          >
            "Website Widgets"
          </a>{' '}
          section of bot Settings.
        </div>
      </div>
    ),
  },
  [Validation.HTTPS_REQUIRED]: {
    level: MessageLevel.ERROR,
    group: MessageGroups.VISIBILITY,
    title: 'HTTPS protocol is required on target page',
    desc: () => (
      <div>
        Having fully functional Customer Chat and Checkbox widgets requires the target page to be
        served over HTTPS protocol due to Facebook security policies.
        <br />
        <br />
        Same applies to any other widget that has the Button Type setting set to "With checkbox"
        option.
        <br />
        <br />
        Follow the link below to learn more:
        <br />
        <a
          className="text-primary"
          target="_blank"
          href="https://developers.facebook.com/docs/messenger-platform/discovery/customer-chat-plugin"
          rel="noreferrer"
        >
          https://developers.facebook.com/docs/messenger-platform/discovery/customer-chat-plugin
        </a>
      </div>
    ),
    fix: 'Please make sure that target page is served over the HTTPS protocol and necessary security certificates present on your website and are up to date.',
  },
  [Validation.HTTPS_NEEDED]: {
    level: MessageLevel.WARNING,
    group: MessageGroups.OTHER,
    title: 'HTTPS protocol might be required on target page',
    desc: () => (
      <div>
        It is highly recommended to serve all target pages over HTTPS protocol in order to have
        correctly working widgets.
        <br />
        <br />
        Using widgets on the pages with non-secured protocol might violate Facebook policies of
        usage.
      </div>
    ),
    fix: 'Please make sure that target page is served over the HTTPS protocol and necessary security certificates present on your website and are up to date.',
  },
  [Validation.INACTIVE_WIDGET]: {
    level: MessageLevel.ERROR,
    group: MessageGroups.SETTINGS,
    title: 'Widget is not activated',
    desc: "Our checking tool diagnoses the performance of your widget by loading your target page to our virtual browser and analyzing its contents. Without widget activation it won't be published to the target page we're trying to load. That's why it is impossible to tell whether widget is OK until it gets published.",
    fix: ({ widget }) => (
      <div>
        To activate your widget please go to{' '}
        <a
          className="text-primary"
          href={linkURL(`/growth-tools/widget/${widget.widget_id}#setup`)}
          target="_blank"
          rel="noreferrer"
        >
          settings screen
        </a>{' '}
        of your widget and switch the "Draft / Active" toggle to the "Active" state.
      </div>
    ),
  },
  [Validation.CERTAIN_DEVICE_ONLY]: {
    level: MessageLevel.INFO,
    group: MessageGroups.VISIBILITY,
    title: ({ hideOnMobile }) =>
      `Widget will appear only on ${hideOnMobile === 'desktop' ? 'Desktop' : 'Mobile'} device`,
    desc: ({ hideOnMobile, widget }) => (
      <div>
        Mind that you configured this widget to be displayed on{' '}
        {hideOnMobile === 'desktop' ? 'Desktop' : 'Mobile'} types of devices only. (See Devices
        section of <b>{widget.name}</b> growth tool{' '}
        <a
          className="text-primary"
          href={linkURL(`/growth-tools/widget/${widget.widget_id}#setup`)}
          target="_blank"
          rel="noreferrer"
        >
          Setup tab
        </a>
        ).
      </div>
    ),
    fix: 'No action is needed. Mind that setting when testing your widget manually.',
  },
  [Validation.RERUN_SETTINGS]: {
    level: MessageLevel.INFO,
    group: MessageGroups.VISIBILITY,
    title: 'Widget has special settings for repeat display',
    desc: ({ widget, showAgain, showAgainIfClosed }) => (
      <div>
        You are seeing this because the following display settings are active:
        <br />
        <br />
        1. "Show widget to the same contact again after" Set to:
        <br />
        <i>{helpers.formatDisplayOption(showAgain)}</i>
        <br />
        <br />
        2. "If manually closed by a contact, show after" Set to:
        <br />
        <i>{helpers.formatDisplayOption(showAgainIfClosed)}</i>
        <br />
        <br />
        Both settings can be found on{' '}
        <a
          className="text-primary"
          href={linkURL(`/growth-tools/widget/${widget.widget_id}#main`)}
          target="_blank"
          rel="noreferrer"
        >
          Initial state tab
        </a>{' '}
        of <b>{widget.name}</b> growth tools settings screen.
      </div>
    ),
    fix: 'No action is needed. Mind this setting when testing your widget manually as this might be the reason why you do not see the widget on your second try immediately.',
  },
  [Validation.ROUTE_DOESNT_MATCH]: {
    level: MessageLevel.ERROR,
    group: MessageGroups.SETTINGS,
    title: 'Target page was included to "Hide on these pages" list',
    desc: ({ widget }) => (
      <div>
        Target page address was included to the "Hide on these pages" list making current widget
        non-visible. <br />
        <br />
        Our checking tool diagnoses the performance of your widget by loading your target page to
        our virtual browser and analyzing its contents. Since the widget is currently configured to
        be non-visible on the target page it cannot be found which causes this error.
      </div>
    ),
    fix: ({ widget, url }) => (
      <div>
        If you believe that <b>{widget.name}</b> widget should appear on{' '}
        <a className="text-primary" target="_blank" href={sanitizeURL(url)} rel="noreferrer">
          {url}
        </a>{' '}
        please remove it from "Hide on these pages" list by following these steps:
        <div className="m-y-xs">
          1. Go to <b>{widget.name}</b> widget settings screen
          <br />
          2. Go to{' '}
          <a
            className="text-primary"
            href={linkURL(`/growth-tools/widget/${widget.widget_id}#setup`)}
            target="_blank"
            rel="noreferrer"
          >
            Setup tab
          </a>
          <br />
          3. Navigate to "Hide on these pages" text box and remove the rule that makes your widget
          invisible. If you have several pages listed there please make sure that your edits do not
          affect widget visibility on other pages.
        </div>
      </div>
    ),
  },
  [Validation.SEVERAL_CUSTOMER_CHAT_ON_SAME_ROUTE]: {
    level: MessageLevel.ERROR,
    group: MessageGroups.SETTINGS,
    title: 'Target page attempted to render two or more Customer Chat widgets',
    desc: ({ widget }) => (
      <div>
        This error is caused because you have at least 2 Customer Chat Growth Tools that are
        activated and set up to display on the same target page. Meanwhile, only one Customer Chat
        widget can be displayed on a single page.
        <br />
        <br />
        Extra info: Every Manychat widget, including Customer Chat, is initialized upon launching
        Manychat SDK. In turn, this SDK is loaded via JS-script.
        <br />
        JS-script should be loaded to your target page provided that the code that directs to it was
        pasted to the target page source code correctly during growth tool setup. (see Growth Tool's
        Setup tab or instructions).
        <br />
        <br />
        SDK loads data about all Growth Tools you set to as active in Manychat for a given bot and
        attempts to display them all on your target page unless you excluded this page at the
        particular GT settings level (see Setup tab, "Hide on these pages" text box).
      </div>
    ),
    fix: ({ widget }) => (
      <div>
        To resolve this issue, please do either of the following depending upon your needs:
        <div className="m-y-xs">
          1. Deactivate every excessive Growth Tool that should not display its Customer Chat widget
          on a given target page (unless those growth tools are used on other target pages in which
          case use 2nd option below).
          <br />
          2. Or type the full address of the target page into the "Hide on these pages" text box in
          Setup tab of every activated Customer Chat Growth Tool except for the <b>
            {widget.name}
          </b>{' '}
          growth tool that needs to be displayed on the target page.
        </div>
      </div>
    ),
  },
  [Validation.MAIN_SCRIPT_DOESNT_INCLUDED]: {
    level: MessageLevel.ERROR,
    group: MessageGroups.MAIN_SCRIPT,
    title: 'JS-script that launches Manychat SDK was not found on the target page',
    desc: () => (
      <div>
        All Manychat widgets are initialized upon launching Manychat SDK. In turn, this SDK is
        loaded via JS-script.
        <br />
        <br />
        JS-script should be loaded to your target page provided that the code that directs to it was
        pasted to the target page source code correctly during growth tool setup.
        <br />
        <br />
        This error indicates that the code that directs to JS-script was installed incorrectly.
      </div>
    ),
    fix: ({ widget }) => (
      <div>
        To resolve this issue, please follow these steps:
        <div className="m-y-xs">
          1. Open the JS-script installation window by clicking "Install JavaScript Snippet" button
          in <b>{widget.name}</b> growth tool{' '}
          <a
            className="text-primary"
            href={linkURL(`/growth-tools/widget/${widget.widget_id}#setup`)}
            target="_blank"
            rel="noreferrer"
          >
            Setup tab
          </a>
          <br />
          2. Navigate to "Install Manychat Snippet" tab to access the original version of JS-script
          code. Compare it with the code installed to your page.
          <br />
          3. If the target page version is any different from the code in Manychat, please replace
          your code with the original one.
        </div>
        <br />
        Note: This code needs to be pasted in the{' '}
        <code className="rounded b-a p-x-xxs">{'<head>'}</code>-part of target page source code.
        <br />
        <br />
        Tip: If you use a site-building platform or engine, be aware that many of them allow to
        specify scripts that should be implemented automatically on every page. This might be useful
        if you use multiple Manychat widgets on your website.
      </div>
    ),
  },
  [Validation.SEVERAL_MAIN_SCRIPTS_INCLUDED]: {
    level: MessageLevel.ERROR,
    group: MessageGroups.MAIN_SCRIPT,
    title: 'Target page contains JS-scripts of two or more Manychat bots',
    desc: ({ pageIds, accounts }) => (
      <div>
        Apparently, you inserted several instances of JS-script code to the target page and at least
        two of them relate to two different Manychat bots. This results in conflicts that won't
        allow your customers to use any widget. On any single target page there should be only one
        instance of JS-script code that initializes Manychat SDK and loads all activated widgets of
        a given bot.
        <br />
        <br />
        Scripts of following bots were detected on the target page:
        <div className="m-y-xs">
          {pageIds.map((page) => {
            // eslint-disable-next-line eqeqeq
            const acc = accounts.find((acc) => acc.page_id == page)
            if (acc) {
              return (
                <div key={page}>
                  -{' '}
                  <a
                    className="text-primary"
                    target="_blank"
                    href={`https://manychat.com/fb${page}/dashboard`}
                    rel="noreferrer"
                  >
                    {acc.title} ({acc.page_id})
                  </a>
                </div>
              )
            }
            return <div key={page}>- {page}</div>
          })}
        </div>
      </div>
    ),
    fix: ({ currentAccount }) => (
      <div>
        To resolve this issue, please delete every entry of JS-script code that relates to another
        Manychat bot. <br /> <br />
        Tip: JS-script code for any growth tool that relates to {currentAccount.name} you're
        currently configuring looks like this: <br />
        <code className="bg-default m-t-xs p-a-xs d-block rounded b-a">
          {getWidgetCodeLayout(`//widget.manychat.com/${currentAccount.id}.js`).join('<br />')}
        </code>
      </div>
    ),
  },
  [Validation.ANOTHER_PAGE_MAIN_SCRIPT_INCLUDED]: {
    level: MessageLevel.ERROR,
    group: MessageGroups.MAIN_SCRIPT,
    title: 'Target page contains JS-script from another Manychat bot',
    desc: ({ pageIds, accounts }) => (
      <div>
        Looks like the JS-script that you inserted into the target page source code relates to
        another Manychat bot.
        <br />
        <br />
        Scripts of following bots were detected on the target page:
        <div className="m-y-xs">
          {pageIds.map((page) => {
            // eslint-disable-next-line eqeqeq
            const acc = accounts.find((acc) => acc.page_id == page)
            if (acc) {
              return (
                <div key={page}>
                  -{' '}
                  <a
                    className="text-primary"
                    target="_blank"
                    href={`https://manychat.com/fb${page}/dashboard`}
                    rel="noreferrer"
                  >
                    {acc.title} ({acc.page_id})
                  </a>
                </div>
              )
            }
            return <div key={page}>- {page}</div>
          })}
        </div>
      </div>
    ),
    fix: ({ currentAccount }) => (
      <div>
        To resolve this issue, please delete every entry of JS-script code that relates to another
        Manychat bot. <br /> <br />
        Tip: JS-script code for any growth tool that relates to {currentAccount.name} you're
        currently configuring looks like this: <br />
        <code className="bg-default m-t-xs p-a-xs d-block rounded b-a">
          {getWidgetCodeLayout(`//widget.manychat.com/${currentAccount.id}.js`).join('<br />')}
        </code>
      </div>
    ),
  },
  [Validation.EMBED_SNIPPET_NOT_FOUND]: {
    level: MessageLevel.ERROR,
    group: MessageGroups.SNIPPET,
    title: 'Embed code was not found on the target page',
    desc: 'Apart from being initialized on the target page by implementing the JS-script, the widget also needs to be displayed in the certain part of the page. If the embed code is missing, the browser will not render the widget anywhere on the page.',
    fix: ({ widget }) => (
      <div>
        Please make sure you pasted the embed code to the source code of the target page where
        needed.
        <br />
        <br />
        Tip: The original embed code can be found on{' '}
        <a
          className="text-primary"
          href={linkURL(`/growth-tools/widget/${widget.widget_id}#setup`)}
          target="_blank"
          rel="noreferrer"
        >
          Setup tab
        </a>{' '}
        of <b>{widget.name}</b> growth tool settings screen (see "Embed Your Widget" step) and looks
        like this:
        <br />
        <code className="bg-default m-t-xs p-a-xs d-block rounded b-a">
          {'<div class="mcwidget-embed" data-widget-id="000000" />'}
        </code>
      </div>
    ),
  },
  [Validation.EMBED_SNIPPET_INVALID_PAYLOAD]: {
    level: MessageLevel.ERROR,
    group: MessageGroups.SNIPPET,
    title: '1 or more embed code entries has invalid value of data-widget-payload attribute',
    desc: ({ widget }) => (
      <div>
        A <code className="rounded b-a p-x-xxs">data-widget-payload</code> attribute is needed to
        hold the value to be passed to the Manychat custom field whenever contacts opt-in through
        the widget.
        <br />
        <br />
        Possible values for <code className="rounded b-a p-x-xxs">data-widget-payload</code>{' '}
        attribute of current widget type are restricted to Latin characters, numbers or "." , "+" ,
        "/", "=" , "-" , ".", "_" symbols. In addition, total max length of the value should not
        exceed 200 symbols.
      </div>
    ),
    fix: ({ widget }) => (
      <div>
        To resolve this issue, make sure that the{' '}
        <code className="rounded b-a p-x-xxs">data-widget-payload</code> attribute in embed code
        holds a valid value that meets criteria above.
        <br />
        <br />
        You can also generate the embed code with our tool that validates{' '}
        <code className="rounded b-a p-x-xxs">data-widget-payload</code> attribute automatically:
        <div className="m-y-xs">
          1. To do this, go to the{' '}
          <a
            className="text-primary"
            href={linkURL(`/growth-tools/widget/${widget.widget_id}#setup`)}
            target="_blank"
            rel="noreferrer"
          >
            Setup tab
          </a>{' '}
          of <b>{widget.name}</b> growth tool <br />
          2. Find "Customize embeddable code with static value" option <br />
          3. Follow on-screen instructions, copy generated embed code and paste it where needed to
          target page source code.
        </div>
        <br />
        If the target page uses custom logic that passes values to the{' '}
        <code className="rounded b-a p-x-xxs">data-widget-payload</code> attribute make sure it also
        validates any value passed to it.
      </div>
    ),
  },
  [Validation.CHECKBOX_SNIPPET_NOT_FOUND]: {
    level: MessageLevel.ERROR,
    group: MessageGroups.SNIPPET,
    title: 'Embed code was not found on the target page',
    desc: 'Apart from being initialized on the target page by implementing the JS-script, widgets also need to be displayed in the certain part of the page. If the embed code is missing, the browser will not render the widget anywhere on the page.',
    fix: ({ widget }) => (
      <div>
        Please make sure you pasted the embed code to the source code of the target page where
        needed.
        <br />
        <br />
        Tip: The original embed code can be found on{' '}
        <a
          className="text-primary"
          href={linkURL(`/growth-tools/widget/${widget.widget_id}#setup`)}
          target="_blank"
          rel="noreferrer"
        >
          Setup tab
        </a>{' '}
        of <b>{widget.name}</b> growth tool settings screen (see "Embed the Checkbox" step) and
        looks like this:
        <code className="bg-default m-t-xs p-a-xs d-block rounded b-a">
          {'<div class="mcwidget-checkbox" data-widget-id="000000" />'}
        </code>
      </div>
    ),
  },
  [Validation.SEVERAL_CHECKBOX_SNIPPETS]: {
    level: MessageLevel.ERROR,
    group: MessageGroups.SNIPPET,
    title: 'Embed code was installed several times on the target page',
    desc: () => (
      <div>
        Our test discovered several embed code entries on the target page. Unlike most of other
        Embeddable widgets, the same Checkbox widget cannot be replicated multiple times on the
        page.
        <br />
        <br />
        If you need to use several checkboxes on the page you should create a separate growth tool
        for each of them.
      </div>
    ),
    fix: ({ widget }) => (
      <div>
        To fix this issue, please delete all the excessive entries of the embed code.
        <br />
        <br />
        Tip: The original embed code can be found on{' '}
        <a
          className="text-primary"
          href={linkURL(`/growth-tools/widget/${widget.widget_id}#setup`)}
          target="_blank"
          rel="noreferrer"
        >
          Setup tab
        </a>{' '}
        of <b>{widget.name}</b> growth tool settings screen (see "Embed the Checkbox" step) and
        looks like this:
        <code className="bg-default m-t-xs p-a-xs d-block rounded b-a">
          {'<div class="mcwidget-checkbox" data-widget-id="000000" />'}
        </code>
      </div>
    ),
  },
  [Validation.MANUAL_CUSTOMER_CHAT_INSTALLATION]: {
    level: MessageLevel.ERROR,
    group: MessageGroups.VISIBILITY,
    title: '3rd party SDK conflicts with Manychat over initializing Customer Chat plugin',
    desc: () => (
      <div>
        Our tests detected an attmept to initialize Customer Chat plugin via non-Manychat SDK.
        <br />
        <br />
        Please note that you cannot use other SDKs for initializing Customer Chat widget on the
        target page along with Manychat SDK. That includes native Facebook SDK. Otherwise, the
        Customer Chat plugin might not work.
      </div>
    ),
    fix: () => (
      <div>
        Please, delete any 3rd party code that manages Customer Chat plugin.
        <br />
        <br />
        For instance, if you used the Facebook SDK before installing Manychat widget please delete
        following entry:
        <code className="bg-default m-t-xs p-a-xs d-block rounded b-a">
          {'<div class="fb-customerchat" page_id=""></div>'}
        </code>
      </div>
    ),
  },
  [Validation.CUSTOMER_CHAT_NO_REFERRER]: {
    level: MessageLevel.ERROR,
    group: MessageGroups.VISIBILITY,
    title: 'Target page returns "no-referrer" value under Referrer-Policy header',
    desc: () => (
      <div>
        Target page returned "no-referrer" value under HTTP header called "Referrer-Policy".
        <br />
        <br />
        When the visitor leaves your target page by following a link to another website the
        "no-referrer" value makes it impossible for this website to track where the visitor came
        from. In case with Facebook Customer Chat plugin it violates the policies of usage.
        <br />
        <br />
        Learn more by following the link bellow:
        <br />
        <a
          target="_blank"
          className="text-primary"
          href="https://developers.facebook.com/docs/messenger-platform/discovery/customer-chat-plugin"
          rel="noreferrer"
        >
          https://developers.facebook.com/docs/messenger-platform/discovery/customer-chat-plugin
        </a>
      </div>
    ),

    fix: 'Find out from your developer or site administrator why such Referrer-Policy is used on your website in the first place. If there are no obvious reasons to keep using it change the value for Referrer-Policy header from "no-referrer" to any other value that would allow widget to work normally.',
  },
  [Validation.CUSTOMER_CHAT_SDK_UNAVAILABLE]: {
    level: MessageLevel.WARNING,
    group: MessageGroups.OTHER,
    title: 'Version of Facebook SDK installed to the target page might cause conflicts',
    desc: () => (
      <div>
        Our test discovered that inadequate version of Facebook SDK was installed to the target page
        along with Manychat SDK.
        <br />
        <br />
        In order to have fully functional Customer Chat widget that can work with all{' '}
        <a
          className="text-primary"
          target="_blank"
          href="https://github.com/manychat/growth-tools-library-api-docs"
          rel="noreferrer"
        >
          Manychat SDK
        </a>{' '}
        methods we recommend to switch to the version of Facebook SDK that supports Customer Chat.
      </div>
    ),
    fix: () => (
      <div>
        To resolve this issue your can do either of following:
        <div className="m-y-xs">
          1. Remove Facebook SDK from the target page since Manychat SDK will install it
          automatically. If you choose to do so, don't forget to check the performance of other
          non-Manychat widgets of you page that might rely on Facebook SDK (if there are any).
          <br />
          2. Or you can switch to another acceptable version of Facebook SDK that supports Customer
          Chat (
          <a
            target="_blank"
            className="text-primary"
            href="https://developers.facebook.com/docs/messenger-platform/discovery/customer-chat-plugin/sdk"
            rel="noreferrer"
          >
            learn more
          </a>
          ).
        </div>
      </div>
    ),
  },
  [Validation.OVERLAY_TARGET_ELEMENT_NOT_FOUND]: {
    level: MessageLevel.ERROR,
    group: MessageGroups.VISIBILITY,
    title: 'Target element that triggers widget display is not found',
    desc: ({ targetElementId, widget }) => (
      <div>
        In the growth tool display settings you opted for showing the widget whenever a visitor of
        the target page scrolls to a specified target element:
        <code className="bg-default m-t-xs p-a-xs d-block rounded b-a">{`<a name="${targetElementId}" />`}</code>
        <br />
        (See{' '}
        <a
          className="text-primary"
          href={linkURL(`/growth-tools/widget/${widget.widget_id}#main`)}
          target="_blank"
          rel="noreferrer"
        >
          Initial state tab
        </a>{' '}
        of <b>{widget.name}</b> growth tool settings under "When does it display" header) Since the
        mentioned element is missing on the target page, the widget will not be displayed.
      </div>
    ),
    fix: ({ widget }) => (
      <div>
        If the target element is specified incorrectly please go to <b>{widget.name}</b> growth tool
        settings, edit it and run the test again.
        <br />
        <br />
        If the target element is correct then please go to your target page source code and make
        sure the element is still present there.
      </div>
    ),
  },
  [Validation.LANGUAGE_UNAVAILABLE]: {
    level: MessageLevel.WARNING,
    group: MessageGroups.OTHER,
    title: 'Facebook SDK takes over language settings',
    desc: () => (
      <div>
        Our test discovered that the Facebook SDK was already installed to the target page along
        with Manychat SDK.
        <br />
        <br />
        As a result of this, the Facebook SDK language setting will always override the language
        defined by locale obtained from Manychat.
        <br />
        <br />
        Keep in mind that your visitor will always see the language you set up for the Facebook SDK
        that might be different from visitor's native language.
      </div>
    ),
    fix: "To resolve that issue and enable the visitor's native language in widget, you can remove Facebook SDK from the target page since Manychat SDK will install it automatically. If you choose to do so, don't forget to check the performance of other non-Manychat widgets of you page that might rely on Facebook SDK (if there are any).",
  },
  [Validation.REDIRECT_DETECTED]: {
    level: MessageLevel.WARNING,
    group: MessageGroups.DOMAIN,
    title: `Redirection from target page occured`,
    desc: ({ redirectTo, url }) => (
      <div>
        Our tests discover redirection that happened when our service requested target page using{' '}
        <a className="text-primary" target="_blank" href={sanitizeURL(url)} rel="noreferrer">
          {url}
        </a>{' '}
        URL. <br />
        <br />
        As a result of this redirection all the tests were implemented for the following URL instead
        of the one originally provided:{' '}
        <a className="text-primary" target="_blank" href={sanitizeURL(redirectTo)} rel="noreferrer">
          {redirectTo}
        </a>
      </div>
    ),
    fix: 'No action needed.',
  },
  [Validation.BAD_STATUS_CODE]: {
    level: MessageLevel.WARNING,
    group: MessageGroups.DOMAIN,
    title: 'Target page returned status different from 200',
    desc: ({ status }) => (
      <div>
        This warning might indicate that something went wrong with the target page when we tried to
        access it.
        <br />
        <br />
        Target page responded with following status: <i>{status}</i>
        <br />
        <br />
        This does not necessary mean that widget is not functional.
      </div>
    ),
    fix: 'We recommend that you access your target page URL and check widget performance manually.',
  },
  [Validation.MAIN_SCRIPT_NOT_LOADED]: {
    level: MessageLevel.INTERNAL_ERROR,
    group: MessageGroups.MAIN_SCRIPT,
    title: 'JS-script was not loaded',
    desc: () => (
      <div>
        This error happens when the JS-script that launches Manychat SDK could not be obtained from
        manychat.com and loaded to the target page.
        <br />
        <br />
        Most likely the issue is temporary and happening because manychat.com failed to respond on
        time. Thus, it has nothing to do with the way you set up the widget in Manychat or on your
        website.
        <br />
        <br />
        Extra info: Any Manychat widget is initialized upon launching Manychat SDK. In turn, this
        SDK is loaded via JS-script.
        <br />
        <br />
        JS-script should be loaded to your target page provided that the code that directs to it was
        pasted to the target page source code correctly during growth tool setup.
      </div>
    ),
    fix: 'Please try to run the test again. If you see this error on every run please notify us about it by submitting a ticket to Support.',
  },
  [Validation.FB_SDK_NOT_LOADED]: {
    level: MessageLevel.INTERNAL_ERROR,
    group: MessageGroups.VISIBILITY,
    title: 'Facebook SDK is not active',
    desc: () => (
      <div>
        This error happens whenever the target page fails to load Facebook SDK that is used by
        Manychat SDK to program widgets behavior.
        <br />
        <br />
        Most likely the issue is temporary and happening because facebook.com failed to response on
        time. Thus, it has nothing to do with the way you set up the widget in Manychat or on your
        website.
      </div>
    ),
    fix: 'Please run the test again. You should not see this error after. Otherwise, please refer to the Facebook resources to learn the current status of their SDK services. ',
  },
  [Validation.EMBED_NOT_RENDERED]: {
    level: MessageLevel.ERROR,
    group: MessageGroups.VISIBILITY,
    title: 'Widget was not rendered on time',
    desc: 'This error happens because the embed code that specifies where exactly on the target page widget should be rendered was added to page after Manychat SDK tried to parse it.',
    fix: () => (
      <div>
        To resolve this issue please call <code className="rounded b-a p-x-xxs">MC.parse()</code>{' '}
        method of{' '}
        <a
          className="text-primary"
          target="_blank"
          href="https://github.com/manychat/growth-tools-library-api-docs"
          rel="noreferrer"
        >
          Manychat SDK
        </a>{' '}
        after the embed code is added to the target page.
      </div>
    ),
  },
  [Validation.EMBED_DISAPPEARED]: {
    level: MessageLevel.ERROR,
    group: MessageGroups.VISIBILITY,
    title: 'Embed code disappeared after target page loaded completely',
    desc: () => (
      <div>
        This error happened because initially the embed code was loaded to the target page but after
        the page was loaded completely, the embed code was deleted by some scripts that alter target
        page content dynamically.
        <br />
        <br />
        The embed code specifies where exactly on the target page the widget should be rendered.
        Because it is missing, the widget cannot be rendered on the target page.
      </div>
    ),
    fix: () => (
      <div>
        To resolve this issue, edit the source code of the target page that would allow the embed
        code of Manychat widget to remain intact after the complete loading of the target page.
        <br />
        <br />
        You can also try to disable other scripts on your target page that dynamically change its
        content one by one to be sure which one affects this error.
      </div>
    ),
  },
  [Validation.CHECKBOX_DISAPPEARED]: {
    level: MessageLevel.ERROR,
    group: MessageGroups.VISIBILITY,
    title: 'Embed code disappeared after target page loaded completely',
    desc: () => (
      <div>
        This error happened because initially the embed code was loaded to the target page but after
        the page was loaded completely, the embed code was deleted by some scripts that alter target
        page content dynamically.
        <br />
        <br />
        The embed code specifies where exactly on the target page the widget should be rendered.
        Because it is missing, the widget cannot be rendered on the target page.
      </div>
    ),
    fix: () => (
      <div>
        To resolve this issue, edit the source code of the target page that would allow the embed
        code of Manychat widget to remain intact after the complete loading of the target page.
        <br />
        <br />
        You can also try to disable other scripts on your target page that dynamically change its
        content one by one to be sure which one affects this error.
      </div>
    ),
  },
  [Validation.CHECKBOX_NOT_RENDERED]: {
    level: MessageLevel.ERROR,
    group: MessageGroups.VISIBILITY,
    title: 'Widget was not rendered on time',
    desc: 'This error happens because the embed code that specifies where exactly on the target page widget should be rendered was added to page after Manychat SDK tried to parse it.',
    fix: () => (
      <div>
        To resolve this issue please call <code className="rounded b-a p-x-xxs">MC.parse()</code>{' '}
        method of{' '}
        <a
          className="text-primary"
          target="_blank"
          href="https://github.com/manychat/growth-tools-library-api-docs"
          rel="noreferrer"
        >
          Manychat SDK
        </a>{' '}
        after the embed code is added to the target page.
      </div>
    ),
  },
  [Validation.EMBED_DOESNT_WORK]: {
    level: MessageLevel.INTERNAL_ERROR,
    group: MessageGroups.VISIBILITY,
    title: 'Unknown display error',
    desc: 'This error happens when both the code of JS-script and embed code of the widget were installed correctly, however, widget is still not displayed.',
    fix: 'If you see this error, please report it by submitting a ticket to our Support team. In your ticket, provide the link to the growth tool you are using and target page URL.',
  },
  [Validation.CHECKBOX_DOESNT_WORK]: {
    level: MessageLevel.INTERNAL_ERROR,
    group: MessageGroups.VISIBILITY,
    title: 'Unknown display error',
    desc: 'This error happens when both the code of JS-script and embed code of the widget were installed correctly, however, widget is still not displayed.',
    fix: 'If you see this error, please report it by submitting a ticket to our Support team. In your ticket, provide the link to the growth tool you are using and target page URL.',
  },
  [Validation.CUSTOMER_CHAT_DOESNT_WORK]: {
    level: MessageLevel.INTERNAL_ERROR,
    group: MessageGroups.VISIBILITY,
    title: 'Unknown display error',
    desc: 'This error happens when both the code of JS-script and embed code of the widget were installed correctly, however, widget is still not displayed.',
    fix: 'If you see this error, please report it by submitting a ticket to our Support team. In your ticket, provide the link to the growth tool you are using and target page URL.',
  },
  [Validation.OVERLAY_DOESNT_WORK]: {
    level: MessageLevel.INTERNAL_ERROR,
    group: MessageGroups.VISIBILITY,
    title: 'Unknown display error',
    desc: 'This error happens when both the code of JS-script and embed code of the widget were installed correctly, however, widget is still not displayed.',
    fix: 'If you see this error, please report it by submitting a ticket to our Support team. In your ticket, provide the link to the growth tool you are using and target page URL.',
  },
  [Validation.MIXED_CONTENT_ISSUES]: {
    level: MessageLevel.WARNING,
    group: MessageGroups.OTHER,
    title: 'Mixed content might prevent widget rendering',
    desc: () => (
      <div>
        It is highly recommended to serve all target pages and its content over HTTPS protocol in
        order to have correctly working widgets.
        <br />
        <br />
        Using widgets on the pages with non-secured protocol might violate Facebook policies of
        usage.
      </div>
    ),
    fix: 'Please support HTTPS protocol and necessary certificates on your website and for all served assets. ',
  },
  [Validation.DIAGNOSTICS_INCOMPLETE]: {
    level: MessageLevel.WARNING,
    group: MessageGroups.DOMAIN,
    title: 'Some test might have been not completed during the check',
    desc: 'Loading the target page content took more than 45 seconds. As a result, the target page content test was incomplete and results of test might be inaccurate.',
    fix: 'We recommend that you pay attention to the speed at which your target page loads completely. Normally 45 seconds should be enough to load an average page.',
  },
  [Validation.WEBSITE_UNAVAILABLE]: {
    level: MessageLevel.ERROR,
    group: MessageGroups.DOMAIN,
    title: 'Target page with the provided URL is not available',
    desc: () => (
      <div>
        Our checking tool diagnoses the performance of your widget by loading your target page to
        our virtual browser and analyzing its contents.
        <br />
        <br />
        We cannot proceed with the checking because this error blocks the rest of the test. This
        might be happening due either of the following reasons:
        <div className="m-y-xs">
          1. URL of the target page you provided above is not valid. <br />
          2. DNS server could not find a website that relates to the provided URL.
        </div>
      </div>
    ),
    fix: () => (
      <div>
        Whichever reason causes this error, we recommend that you double check the URL and the
        status of the page it directs to.
        <br />
        <br />
        To do so, open the target page in the new tab of your browser.
        <br />
        <br />
        If it opens successfully, then most likely you provided an incorrect URL in the Target page
        field at the top of this page. Make sure there are no typos and the address is complete.
      </div>
    ),
  },
  [Validation.WEBSITE_ERR_CERT]: {
    level: MessageLevel.ERROR,
    group: MessageGroups.DOMAIN,
    title: 'Website that hosts the target page has invalid certificate',
    desc: () => (
      <div>
        You are seeing this error because the SSL certificate on the website that hosts the target
        page is invalid. Because of that, we could not access the target page to analyze widget
        performance. This can happen for a number of reasons:
        <div className="m-y-xs">
          1. SSL certificate has expired
          <br />
          2. SSL certificate was signed by a party that issued it rather than the trusted
          certificate authority ('self-signed certificate')
          <br />
          3. SSL certificate is not valid because the domain name in the SSL certificate doesn't
          match the address of the target page.
        </div>
      </div>
    ),
    fix: () => (
      <div>
        In order to resolve certificate issues do following:
        <div className="m-y-xs">
          1. Find out which of the reasons above causes the error in your case. To do so, open the
          target page in a new incognito tab in your browser. A warning message about untrusted
          content should be displayed instead of the target page. Normally the message also contains
          the exact certificate problem.
          <br />
          2. Learn how to resolve your issue for the certificate by going to the websites of
          numerous SSL providers and follow their instructions. You can also ask your developer or
          site administrator for help.
        </div>
      </div>
    ),
  },
  // [Validation.FB_SDK_VAR_UNAVAILABLE]: {
  //   level: MessageLevel.ERROR,
  //   group: MessageGroups.VISIBILITY,
  //   title: 'FB is unavailable after loading everything',
  //   desc: 'desc',
  //   fix: 'fix',
  // },
  // [Validation.MC_SDK_VAR_UNAVAILABLE]: {
  //   level: MessageLevel.ERROR,
  //   group: MessageGroups.VISIBILITY,
  //   title: 'MC is unavailable after loading everything',
  //   desc: 'desc',
  //   fix: 'fix',
  // },
}

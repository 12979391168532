import React from 'react'
import { l } from '@manychat/manyui'

import { linkURL } from 'utils/url'

export const WABrokenMessageTemplateIsInUse = () => {
  const logsUrl = linkURL('/settings#logs')
  return (
    <span>
      {l.translate(
        'There is an issue with Message Templates in your Automations. <a class="text-primary" href="{url}" target="_blank" rel="noreferrer">Learn more in Logs</a>.',
        {
          url: logsUrl,
        },
      )}
    </span>
  )
}

import BaseButtonConfig from 'common/builder/buttons/BaseButtonConfig'
import { IButtonBase, IURLButton } from 'common/builder/buttons/buttonInterfaces'
import { ButtonType } from 'common/builder/constants/ButtonType'
import { WebViewSize } from 'common/core/constants/WebViewSize'

class URLButtonConfig extends BaseButtonConfig {
  buttonType = ButtonType.URL

  getDefaults = (): Omit<IURLButton, keyof IButtonBase> => ({
    isSmartLink: false,
    url: '',
    targetId: null,
    webviewSize: WebViewSize.DEFAULT,
    enableWebview: false,
  })
}

export default new URLButtonConfig()

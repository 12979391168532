import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import sortBy from 'lodash/sortBy'
import { createAppAsyncThunk } from 'reduxTyped'
import { createSelector } from 'reselect'

import { WhatsappMessageTemplatesState } from 'apps/whatsApp/interfaces/whatsAppMessageTemplates'
import { IThunkAction } from 'common/core/interfaces/actions'
import { hasConnectedWhatsAppChannel } from 'common/core/selectors/channelSelectors'
import { handleCatch } from 'shared/api/lib/errors/handlers'
import { WhatsAppApi } from 'shared/api/requests/whatsApp'
import {
  WaMessageTemplateLanguage,
  WaMessageTemplateCategory,
  WaMessageTemplatePack,
  WaMessageTemplatesList,
  WaMessageTemplate,
  WaDeleteMessageTemplatesRequest,
  WaMessageTemplateStatus,
} from 'shared/api/requests/whatsApp/schemas'

export const initialState: WhatsappMessageTemplatesState = {
  categories: {
    loading: false,
    data: null,
  },
  languages: {
    loading: false,
    data: null,
  },
  list: {
    loading: false,
    data: null,
  },
  ui: {
    selectedNames: [],
    isDeleteModalOpen: false,
    namesToDelete: [],
    tsUpdated: null,
  },
}

export const getTemplatesLanguages = createAppAsyncThunk<WaMessageTemplateLanguage[] | null>(
  'whatsapp/templates/languages',
  async () => {
    try {
      const result = await WhatsAppApi.listMessageTemplateLanguages()
      return result.data.values
    } catch (error) {
      handleCatch(error)
      return null
    }
  },
)

export const getTemplatesCategories = createAppAsyncThunk<WaMessageTemplateCategory[] | null>(
  'whatsapp/templates/categories',
  async () => {
    try {
      const result = await WhatsAppApi.fetchMessageTemplateCategories()
      return result.data.categories
    } catch (error) {
      handleCatch(error)
      return null
    }
  },
)

const getTemplatesListThunk = createAppAsyncThunk<WaMessageTemplatesList | null>(
  'whatsapp/templates/list',
  async () => {
    try {
      const result = await WhatsAppApi.listMessageTemplatesPacks()
      if (Array.isArray(result.data.message_templates)) return null
      return result.data.message_templates
    } catch (error) {
      handleCatch(error)
      return null
    }
  },
)

export const getTemplatesList = (): IThunkAction => (dispatch, getState) => {
  const state = getState()
  const isWaChannelConnected = hasConnectedWhatsAppChannel(state)

  if (!isWaChannelConnected) return

  dispatch(getTemplatesListThunk())
}

export const deleteMessageTemplates = createAppAsyncThunk<
  string[] | null,
  WaDeleteMessageTemplatesRequest
>('whatsapp/templates/delete', async (body) => {
  try {
    const result = await WhatsAppApi.deleteMessageTemplates({ body })
    return result.data.templates_deleted
  } catch (error) {
    handleCatch(error)
    return null
  }
})

export const messageTemplateSlice = createSlice({
  name: 'messageTemplate',
  initialState,
  reducers: {
    toggleSelect: (state, action: PayloadAction<string>) => {
      const { payload } = action
      const { selectedNames } = state.ui

      if (selectedNames.includes(payload)) {
        state.ui.selectedNames = selectedNames.filter((id) => id !== payload)
      } else {
        state.ui.selectedNames.push(payload)
      }
    },
    toggleSelectAll: (state, action: PayloadAction<string[]>) => {
      const { payload } = action
      const { selectedNames } = state.ui

      if (selectedNames.length === payload.length) {
        state.ui.selectedNames = []
      } else {
        state.ui.selectedNames = payload
      }
    },
    setIsDeleteModalOpen: (state, action: PayloadAction<boolean>) => {
      state.ui.isDeleteModalOpen = action.payload
    },
    setNamesToDelete: (state, action: PayloadAction<string[]>) => {
      state.ui.namesToDelete = action.payload
    },
    resetNamesToDelete: (state) => {
      state.ui.namesToDelete = []
    },
    resetSelectedNames: (state) => {
      state.ui.selectedNames = []
    },
    setTSUpdated: (state, action: PayloadAction<number | null>) => {
      state.ui.tsUpdated = action.payload
    },
    addCreatedTemplate: (state, action: PayloadAction<WaMessageTemplatePack>) => {
      const key = action.payload[0].name_human
      state.list.data = { ...(state.list.data || {}), [key]: action.payload }
    },
  },
  extraReducers: (builder) => {
    // pending
    builder.addCase(getTemplatesLanguages.pending, (state) => {
      state.languages.loading = true
    })
    builder.addCase(getTemplatesCategories.pending, (state) => {
      state.categories.loading = true
    })
    builder.addCase(getTemplatesListThunk.pending, (state) => {
      state.list.loading = true
    })

    // fulfilled
    builder.addCase(getTemplatesLanguages.fulfilled, (state, action) => {
      state.languages.loading = false
      state.languages.data = action.payload
    })
    builder.addCase(getTemplatesCategories.fulfilled, (state, action) => {
      state.categories.loading = false
      state.categories.data = action.payload
    })
    builder.addCase(getTemplatesListThunk.fulfilled, (state, action) => {
      state.list.loading = false
      state.list.data = action.payload
    })
  },
})

export const getTemplatesLanguagesSelector = (state: RootState) =>
  state.whatsApp.messageTemplates.languages.data

export const getTemplatesCategoriesSelector = (state: RootState) =>
  state.whatsApp.messageTemplates.categories.data

export const getTemplatesListSelector = (state: RootState) =>
  state.whatsApp.messageTemplates.list.data

export const getFlattenedTemplatesListSelector = createSelector(
  [getTemplatesListSelector],
  (templatesList) => {
    if (!templatesList) return []

    return Object.values(templatesList).reduce((acc, current) => {
      const sorted = sortBy(current, (template) => Number(!template.is_default))
      return [...acc, ...sorted]
    }, [] as WaMessageTemplate[])
  },
)

export const getTemplateByNameAndLanguageSelector = (
  state: RootState,
  name: string,
  languageCode: string | null,
) => {
  const templates = getFlattenedTemplatesListSelector(state)

  const templateByNameAndLanguage = templates.find(
    (template) => template.name === name && template.language_code === languageCode,
  )
  const templateByName = templates.find((template) => template.name === name)

  return templateByNameAndLanguage || templateByName || null
}

export const getDefaultTemplatesListSelector = createSelector(
  [getFlattenedTemplatesListSelector],

  (templatesList) => {
    if (!templatesList) return []

    return templatesList.filter((template) => template.is_default)
  },
)

export const getApprovedTemplatesListSelector = createSelector(
  [getFlattenedTemplatesListSelector],

  (templatesList) => {
    if (!templatesList) return []

    return templatesList.filter((template) => template.status === WaMessageTemplateStatus.APPROVED)
  },
)

export const getPendingTemplatesListSelector = createSelector(
  [getFlattenedTemplatesListSelector],

  (templatesList) => {
    if (!templatesList) return []

    return templatesList.filter((template) => template.status === WaMessageTemplateStatus.PENDING)
  },
)

export const getIsLoadingTemplateResources = (state: RootState) =>
  Boolean(
    state.whatsApp.messageTemplates.languages.loading ||
      state.whatsApp.messageTemplates.categories.loading ||
      state.whatsApp.messageTemplates.list.loading,
  )

export const getSelectedTemplatesSelector = (state: RootState) =>
  state.whatsApp.messageTemplates.ui.selectedNames

export const getIsDeleteModalOpenSelector = (state: RootState) =>
  state.whatsApp.messageTemplates.ui.isDeleteModalOpen

export const getTemplateNamesToDeleteSelector = (state: RootState) =>
  state.whatsApp.messageTemplates.ui.namesToDelete

export const getTSUpdatedSelector = (state: RootState) =>
  state.whatsApp.messageTemplates.ui.tsUpdated

export const getLanguagesSelector = (state: RootState) =>
  state.whatsApp.messageTemplates.languages.data

export const {
  toggleSelect,
  toggleSelectAll,
  setIsDeleteModalOpen,
  setNamesToDelete,
  resetNamesToDelete,
  resetSelectedNames,
  setTSUpdated,
  addCreatedTemplate,
} = messageTemplateSlice.actions

import { IWidget } from 'apps/growthTools/growthToolsInterfaces'
import { WidgetTypes } from 'apps/growthTools/models/Widget/constants'
import { ChannelType } from 'common/core/constants/ChannelType'

export const isInstagramCgt = (widget: IWidget): boolean => {
  return (
    (widget.widget_type === WidgetTypes.INSTAGRAM_LIVE_COMMENT_REPLY ||
      widget.widget_type === WidgetTypes.FEED_COMMENT_TRIGGER) &&
    widget.channel === ChannelType.INSTAGRAM
  )
}

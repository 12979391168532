import React from 'react'
import { useAppSelector } from 'reduxTyped'

import { contentManagementSelectors } from 'apps/cms/store'

import cm from './FlowDragLayer.module.css'

interface FlowDragLayerProps {
  offset: {
    x: number
    y: number
  }
  path: string
}

export const FlowDragLayer = ({ offset, path }: FlowDragLayerProps) => {
  const flow = useAppSelector((state) => contentManagementSelectors.getFlowByPath(state, path))

  const selectedFlowsCount = useAppSelector(contentManagementSelectors.getSelectedFlowIds).length

  if (!flow) {
    return null
  }

  return (
    <div className={cm.wrapper}>
      <div
        className={cm.cards}
        style={{ transform: `translate(${Number(offset.x) + 2}px, ${Number(offset.y) + 2}px)` }}
      >
        {selectedFlowsCount > 1 && <div className={cm.backgroundCard} />}

        <div className={cm.card}>
          <span className="text-ellipsis">{flow.name}</span>

          {selectedFlowsCount > 1 && <span className={cm.card__counter}>{selectedFlowsCount}</span>}
        </div>
      </div>
    </div>
  )
}

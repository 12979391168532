import Papa, { ParseResult } from 'papaparse'
import { l } from '@manychat/manyui'

import { FilterField } from 'common/filter/models/AudienceFilter/constants'

export interface IHeaderValue {
  value: FilterField | null
  title: string | null
}

export interface IHeaderMap {
  [key: string]: IHeaderValue
}

export const mapResult = (headers: string[], items: string[][]) => {
  const normalizedHeaders = headers.map((header, index) => {
    let newHeader = header ? header : `Heading column #${index}`
    if (headers.filter((h) => h === newHeader).length > 1) {
      newHeader = `${header} #${index}`
    }
    return newHeader
  })
  const headersMap: IHeaderMap = {}
  normalizedHeaders.forEach((header) => {
    headersMap[header] = { value: null, title: null }
  })

  const itemsMap = items.reduce((resArr: Array<string[]>, curArr: string[]) => {
    const row = curArr.reduce((prev: string[], cur, i, arr) => {
      const result = prev
      if (arr.length <= 1 && !cur) {
        return result
      }
      const str = cur.replace(/^ /g, '').replace(/ $/g, '')
      result.push(str)
      return result
    }, [])
    if (!row.length) {
      return resArr
    }
    resArr.push(row)

    return resArr
  }, [])

  return {
    itemsMap,
    headersMap,
    normalizedHeaders,
  }
}

export const validate = (res: string[][]): boolean => {
  const [headRow, ...bodyRows] = res

  if (!res.length || !headRow.length || !bodyRows.length) {
    return false
  }

  for (const bodyRow of bodyRows) {
    if (bodyRow.length > headRow.length) {
      return false
    }
  }

  return true
}

export const onComplete = (
  data: ParseResult<string[]>['data'],
  resolve: (value: unknown) => void,
  reject: (reason?: unknown) => void,
) => {
  const [headers, ...items] = data

  const isValid = validate(data)

  if (!isValid) {
    return reject(new Error(l.translate('Document is not valid')))
  }

  const { itemsMap, headersMap, normalizedHeaders } = mapResult(headers, items)

  resolve({ itemsMap, headersMap, headers: normalizedHeaders })
}

const parseCSVFile = async (file: File) => {
  return new Promise((resolve, reject) => {
    Papa.parse<string[]>(file, {
      complete: ({ data }) => onComplete(data, resolve, reject),
    })
  })
}

export default parseCSVFile

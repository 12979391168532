import { BlockType } from 'common/builder/constants/BlockType'

import * as entitySelectors from './entitySelectors'

export const getConditionBlocks = (state: RootState, builderId: string, nodeId: string) => {
  const blocks = entitySelectors.getBlocks(state, builderId, nodeId)

  // default block must be the last
  return [
    ...blocks.filter((b) => b.type === BlockType.CASE),
    ...blocks.filter((b) => b.type === BlockType.DEFAULT),
  ]
}

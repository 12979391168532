import { z } from 'zod'

import { LiveChatUserSettingsSchema } from './LiveChatUserSettingsSchema'

export const CurrentUserSchema = z.object({
  user_id: z.number(),
  is_creator: z.boolean(),
  notification_phone: z.string().or(z.null()),
  notification_telegram: z
    .object({
      id: z.number(),
      name: z.string(),
      username: z.string().or(z.null()),
      avatar: z.string().or(z.null()),
    })
    .nullable(),
  notify_admin: z.array(z.string()),
  is_opted_in: z.boolean(),
  mobile_notify_admin_push_enabled: z.boolean(),
  livechat_settings: LiveChatUserSettingsSchema,
})

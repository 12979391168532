import { ValueLabel } from '../interfaces/actions'

export enum WebViewSize {
  DEFAULT = '',
  FULL = 'full',
  MEDIUM = 'medium',
  COMPACT = 'compact',
}

export const WebViewOptions: ValueLabel<WebViewSize>[] = [
  { value: WebViewSize.DEFAULT, label: 'Native' },
  { value: WebViewSize.FULL, label: 'Full – 100%' },
  { value: WebViewSize.MEDIUM, label: 'Medium – 75%' },
  { value: WebViewSize.COMPACT, label: 'Compact – 50%' },
]

export const WebViewSizeTooltip = `Messenger opens websites right inside the app without using an external browser. Define the height of this built-in browser. “Native” option will use default browser with full height. To make the Messenger Extensions SDK available in the webview, select 'Full', 'Medium' or 'Compact' size. Don't forget that all sites domain names that will use the SDK should be whitelisted.`

import { useCallback, useEffect, useRef } from 'react'
import { useAppDispatch, useAppSelector } from 'reduxTyped'

import { Field } from 'common/fields/entities/field'
import { makeTagField } from 'common/tags/components/TagPicker/helpers'
import { TagsActions, TagsSelectors } from 'common/tags/store'
import { NewTagSource, TagCreationSource } from 'common/tags/store/types'
import { useId, useIsChanged, useDidMountEffect } from 'utils/commonHooks'

export const useCreatedTagLinking = ({
  onTagCreate,
  creationSource,
}: {
  onTagCreate?: (id: string, createdTag?: Field) => void
  creationSource: TagCreationSource
}) => {
  const dispatch = useAppDispatch()
  const id = useId()
  const tagModalSource: NewTagSource = `${creationSource}_${id}`

  const { source, lastCreatedTag } = useAppSelector(TagsSelectors.getCreateTagModal)

  const lastSelectedTag = useRef<number | null>(null)

  const lastCreatedTagChanged = useIsChanged(lastCreatedTag)

  const handleCloseModal = useCallback(
    () => dispatch(TagsActions.closeCreateTagModal()),
    [dispatch],
  )

  useDidMountEffect(() => {
    return () => {
      handleCloseModal()
    }
  })

  useEffect(() => {
    const hasNewTag =
      source &&
      source === tagModalSource &&
      lastCreatedTagChanged &&
      lastCreatedTag &&
      lastSelectedTag.current !== lastCreatedTag.tag_id

    if (!hasNewTag) {
      return
    }

    if (onTagCreate) {
      const tagField = makeTagField(lastCreatedTag)
      onTagCreate && onTagCreate(tagField.id, tagField)
    }

    handleCloseModal()
  }, [handleCloseModal, lastCreatedTag, lastCreatedTagChanged, onTagCreate, source, tagModalSource])

  return { tagModalSource }
}

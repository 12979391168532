import React, { forwardRef } from 'react'
import cx from 'classnames'
import { v4 as uuid } from 'uuid'
import { TextInputV2 } from '@manychat/manyui'

import { reasonTypeLabels, ReasonTypes } from 'common/oneTimeNotify/constants/notifyReasonConstants'

import cm from './InputWithTopicType.module.css'

const InputWithTopicType: React.ForwardRefRenderFunction<
  HTMLInputElement,
  { reasonType?: ReasonTypes }
> = ({ reasonType, ...inputProps }, ref) => {
  const inputId = uuid()

  return (
    <div className="p-relative">
      <TextInputV2
        inputRef={ref}
        id={inputId}
        {...inputProps}
        className={cm.croppedInput}
        ariaLabel="Reason Type"
      />
      <label
        htmlFor={inputId}
        className={cx(`d-flex align-center p-absolute text-secondary p-r-xs`, cm.customLabel)}
      >
        {reasonType && reasonTypeLabels[reasonType]}
      </label>
    </div>
  )
}

export const InputWithTopicTypeRef = forwardRef(InputWithTopicType)

import { configureStore } from '@reduxjs/toolkit'
import * as Sentry from '@sentry/react'
import { Reducer, Middleware } from 'redux'
import { reduxListenerMiddleware } from 'reduxTyped'

import type { ThunkExtra } from 'common/core/interfaces/actions'
import fetch from 'utils/middleware/fetch'
import fetchEndpoint from 'utils/middleware/fetchEndpoint'
import patchNotification from 'utils/middleware/patchNotification'

import rootReducer from '../reducers'

export function createStore({
  reducer = rootReducer,
  thunkExtraArgument = {},
  initialState = {},
} = {}) {
  const fetchOptions = {
    credentials: 'include',
  }

  const middlewares = [
    patchNotification() as Middleware,
    fetchEndpoint() as Middleware,
    fetch({ options: fetchOptions }) as Middleware,
    reduxListenerMiddleware.middleware,
  ].filter((middleware) => middleware !== null)

  const sentryReduxEnhancer = Sentry.createReduxEnhancer({
    actionTransformer: (action) => ({
      type: action.type,
    }),
    stateTransformer: () => {
      return null
    },
  })

  const store = configureStore({
    reducer: reducer as unknown as Reducer<RootState>,
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware({
        thunk: {
          extraArgument: thunkExtraArgument as ThunkExtra,
        },
        immutableCheck: false,
        serializableCheck: false,
        actionCreatorCheck: false,
      }).concat(...middlewares),
    preloadedState: initialState,
    enhancers: [sentryReduxEnhancer],
  })

  return store
}

import React, { useMemo } from 'react'
import { Navigate, Outlet } from 'react-router-dom'
import { Loader } from '@manychat/manyui'

import { BillingProvider } from 'common/billing/components/BillingProvider'
import { ErrorBoundary } from 'common/core/components/ErrorBoundary'
import { useRemoveNotificationsOnNavigate } from 'common/core/controllers/Toasts/useRemoveNotificationsOnNavigate'
import { useViewportAdjustments } from 'shared/hooks/useViewportAdjustments'
import { usePrevious } from 'utils/commonHooks'
import { isLocal } from 'utils/isLocal'
import { getLocation } from 'utils/router/tools'
import { useRedirects } from 'utils/router/utils/hooks'

import { DevRedirectionManagerController } from './DevRedirectionManagerController'

export const RootLayout = () => {
  useViewportAdjustments()
  useRemoveNotificationsOnNavigate()

  const redirectUrl = useRedirects()

  const { pathname } = getLocation()
  const prevPathname = usePrevious(pathname)
  const errorBoundaryResetKeys = useMemo(() => [prevPathname], [prevPathname])

  if (redirectUrl) {
    return <Navigate to={redirectUrl} />
  }

  return (
    <ErrorBoundary resetKeys={errorBoundaryResetKeys} scope="layout" section={null}>
      <React.Suspense fallback={<Loader center />}>
        {isLocal() && <DevRedirectionManagerController />}
        <BillingProvider>
          <Outlet />
        </BillingProvider>
      </React.Suspense>
    </ErrorBoundary>
  )
}

import React from 'react'
import cx from 'classnames'
import { useAppDispatch, useAppSelector } from 'reduxTyped'
import { l, sanitizeURL } from '@manychat/manyui'

import { openConnectChannelModal } from 'common/core/actions/uiActions'
import { ChannelType, ConnectChannelModalSource } from 'common/core/constants/ChannelType'
import * as appSelectors from 'common/core/selectors/appSelectors'
import { isFBChannelConnected } from 'common/core/selectors/channelSelectors'
import { analyticsService } from 'utils/services/analytics'

import cm from '../StaticNotifications.module.css'

export const RefreshAdminPermissionsNotification = () => {
  const dispatch = useAppDispatch()
  const page_id = useAppSelector(appSelectors.getPageId)
  const hasConnectedFBChannel = useAppSelector(isFBChannelConnected)

  const getAdminPermissionsLink = () => {
    if (hasConnectedFBChannel) {
      return sanitizeURL(
        `/reloadPermissions?page_id=${page_id}&return=${encodeURIComponent(window.location.href)}`,
      )
    }
    return undefined
  }

  const handleRefresh = () => {
    analyticsService.sendEvent('REFRESH_ADMIN_PERMISSIONS_BANNER.CLICKED')
    if (!hasConnectedFBChannel) {
      dispatch(
        openConnectChannelModal(
          [ChannelType.FB, ChannelType.EMAIL, ChannelType.SMS],
          ConnectChannelModalSource.REFRESH_ADMIN_PERMISSIONS,
        ),
      )
    }
  }

  return (
    <>
      <span>
        {l.translate(
          "Some issues with account's permissions were found. Facebook page Admin has to Refresh Permissions.",
        )}
      </span>
      <a href={getAdminPermissionsLink()} onClick={handleRefresh} className={cx(cm.more, 'm-l-xs')}>
        {l.translate('Refresh Permissions')}
      </a>
    </>
  )
}

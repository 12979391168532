import React, { useEffect } from 'react'
import { Link } from 'react-router-dom'
import { useAppDispatch, useAppSelector } from 'reduxTyped'

import { getListSuspectedFbPolicyOffenders } from 'common/core/actions/appActions'
import { getCurrentAccount } from 'common/core/selectors/appSelectors'
import If from 'components/If'
import SimpleAccordion from 'components/SimpleAccordion'
import { analyticsService } from 'utils/services/analytics'
import { useHotjar } from 'utils/services/hotjar/useHotjar'
import { linkURL } from 'utils/url'

interface IFBPolicyEnforcementModalSuspectedOffendersProps {
  onClose: () => void
  eventPrefix: string
}

enum MetricsTypes {
  BROADCASTS = 'broadcasts',
  FLOWS_WITH_OTN = 'flows_with_otn',
  FLOWS_WITH_MESSAGE_TAGS = 'flows_with_message_tags',
}

export const FBPolicyEnforcementModalSuspectedOffenders = ({
  onClose,
  eventPrefix,
}: IFBPolicyEnforcementModalSuspectedOffendersProps) => {
  const dispatch = useAppDispatch()
  useEffect(() => {
    dispatch(getListSuspectedFbPolicyOffenders())
  }, [dispatch])

  const { hotjarTrigger } = useHotjar()

  const currentAccount = useAppSelector(getCurrentAccount)
  const flows = currentAccount.suspectedFbPolicyOffenders?.data?.flows || []
  const broadcasts = currentAccount.suspectedFbPolicyOffenders?.data?.broadcasts || []

  const flowsWithMessageTags = flows.filter((flow) => flow.has_message_tag)
  const flowsWithOTN = flows.filter((flow) => flow.has_otn_reason)

  const isEmpty = !flowsWithMessageTags.length && !flowsWithOTN.length && !broadcasts.length

  useEffect(() => {
    if (!isEmpty) {
      analyticsService.sendEvent(`${eventPrefix}.SUSPECTED_OFFENDER.SHOWN`)
      hotjarTrigger('fb_policy_enforcement_modal_suspected_offenders', [
        'fb_policy_enforcement_modal_suspected_offenders',
      ])
    }
  }, [eventPrefix, hotjarTrigger, isEmpty])

  if (isEmpty) {
    return null
  }

  const handleViewAllClick = (itemType: string) => {
    analyticsService.sendEvent(`${eventPrefix}.SUSPECTED_OFFENDER_VIEW_ALL.CLICKED`, { itemType })
    onClose()
  }

  const handleSuspectedOffenderClick = (itemType: string) => {
    analyticsService.sendEvent(`${eventPrefix}.SUSPECTED_OFFENDER_ITEM.CLICKED`, { itemType })
    onClose()
  }

  const viewAllClassName = 'text-primary text-body m-t-xxs d-inline-block'
  const itemClassName = 'text-sm m-b-xs m-t-0 d-block text-h-primary'

  return (
    <div>
      <h1 className="m-b m-t-md">Check your Flows</h1>
      <span>
        After understanding Message Tag Policy, check all of your Flows, Broadcasts or Sequences to
        ensure you’re using Tags in the right way.
      </span>

      <If condition={flowsWithMessageTags.length}>
        <SimpleAccordion
          title={`${flowsWithMessageTags.length} ${
            flowsWithMessageTags.length === 1 ? 'Automation' : 'Automations'
          } with message tags`}
          subtitle={
            <Link
              className={viewAllClassName}
              onClick={() => handleViewAllClick(MetricsTypes.FLOWS_WITH_MESSAGE_TAGS)}
              to={linkURL('/cms/?from_policy_enforcement_modal&path=/smart_any_tag')}
            >
              View all
            </Link>
          }
        >
          {flowsWithMessageTags.map((item) => (
            <Link
              key={item.ns}
              onClick={() => handleSuspectedOffenderClick(MetricsTypes.FLOWS_WITH_MESSAGE_TAGS)}
              className={itemClassName}
              to={linkURL(`/cms/files/${item.ns}`)}
            >
              {item.name}
            </Link>
          ))}
        </SimpleAccordion>
      </If>

      <If condition={flowsWithOTN.length}>
        <SimpleAccordion
          title={`${flowsWithOTN.length} ${
            flowsWithOTN.length === 1 ? 'Automation' : 'Automations'
          } with OTN`}
          subtitle={
            <Link
              className={viewAllClassName}
              onClick={() => handleViewAllClick(MetricsTypes.FLOWS_WITH_OTN)}
              to={linkURL('/cms/?from_policy_enforcement_modal&path=/smart_otn')}
            >
              View all
            </Link>
          }
        >
          {flowsWithOTN.map((item) => (
            <Link
              key={item.ns}
              onClick={() => handleSuspectedOffenderClick(MetricsTypes.FLOWS_WITH_OTN)}
              className={itemClassName}
              to={linkURL(`/cms/files/${item.ns}`)}
            >
              {item.name}
            </Link>
          ))}
        </SimpleAccordion>
      </If>

      <If condition={broadcasts.length}>
        <SimpleAccordion
          title={`${broadcasts.length} ${broadcasts.length === 1 ? 'Broadcast' : 'Broadcasts'}`}
          subtitle={
            <Link
              className={viewAllClassName}
              onClick={() => handleViewAllClick(MetricsTypes.BROADCASTS)}
              to={linkURL('/posting')}
            >
              View all
            </Link>
          }
        >
          {broadcasts.map((item) => (
            <Link
              key={item.post_id}
              onClick={() => handleSuspectedOffenderClick(MetricsTypes.BROADCASTS)}
              className={itemClassName}
              to={
                item.status === 'scheduled'
                  ? linkURL(`/posting/scheduled/${item.post_id}`)
                  : linkURL(`/posting/history/${item.post_id}`)
              }
            >
              {item.name}
            </Link>
          ))}
        </SimpleAccordion>
      </If>
    </div>
  )
}

const FIELDS = {
  id: 'page_id',
  avatar: 'avatar_url',
  name: 'page_name',
  accountStatus: 'pro_status_name',
  pageStatus: 'status_name',
  activeSubscribers: 'subscribers_active',
  totalSubscribers: 'subscribers_total',
  openThreads: 'threads_open',
  userRole: 'user_role_name',
  isFBAdmin: 'is_active_fb_administrator',
  macTotalCount: 'mac_total',
  isFavorite: 'is_favorite',
  isHidden: 'is_hidden',
}

export function parsePage(page = {}) {
  return Object.keys(FIELDS).reduce((parsed, field) => {
    parsed[field] = page[FIELDS[field]]

    return parsed
  }, {})
}

import merge from 'lodash/merge'
import pick from 'lodash/pick'

import buttonRegistry from 'common/builder/buttonRegistry'
import { IButton, UrlButtonUnion } from 'common/builder/buttons/buttonInterfaces'
import { ButtonType } from 'common/builder/constants/ButtonType'

export const applyTypeChanges = (button: IButton, changes: Partial<IButton>): IButton => {
  if (!changes.type) {
    return merge(button, changes)
  }
  const nextTypeConfig = buttonRegistry.getByType(changes.type)
  const nextTypeAttributes = nextTypeConfig.getAttributes()
  const initial = merge(pick(button, ...nextTypeAttributes), changes)
  return nextTypeConfig.create(initial)
}

export function isUrlButton(button: IButton): button is UrlButtonUnion {
  return [ButtonType.URL, ButtonType.CTA_URL].includes(button.type)
}

export const isSmartLink = (button: IButton): boolean =>
  'isSmartLink' in button && button.isSmartLink

export const isCrossChannelContent = (button: IButton): boolean =>
  button.type === ButtonType.CROSS_CHANNEL_CONTENT

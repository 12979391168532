import { createSlice } from '@reduxjs/toolkit'

export interface UkraineReliefProgramState {
  isModalOpen: boolean
}

const initialState: UkraineReliefProgramState = {
  isModalOpen: false,
}

export const ukraineReliefProgramSlice = createSlice({
  name: 'ukraineReliefProgram',
  initialState,
  reducers: {
    showUkraineReliefProgramModal: (state) => {
      state.isModalOpen = true
      return state
    },
    closeUkraineReliefProgramModal: (state) => {
      state.isModalOpen = false
      return state
    },
  },
})

export const { reducer } = ukraineReliefProgramSlice
export const { showUkraineReliefProgramModal, closeUkraineReliefProgramModal } =
  ukraineReliefProgramSlice.actions

export const isUkraineReliefProgramModalOpen = (state: RootState) =>
  state.billing.ukraineReliefProgram.isModalOpen

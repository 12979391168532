import { l } from '@manychat/manyui'

import {
  CardMaxTitleLength,
  CardMaxSubtitleLength,
  ListMaxTitleLength,
  ListMaxSubtitleLength,
  SMS_NODE_IMAGE_MAX_SIZE,
} from 'common/builder/models/Block/constants'
import PaymentCurrencies from 'common/core/constants/PaymentCurrencies'

import { HyperlinkMaxCaptionLength } from '../models/Button/constants'
import { formatBytesToMbString } from '../nodes/sms/smsNodeHelpers'

const errorMessages = {
  // Block
  BLOCK_TEXT_REQUIRED: l.makeTranslate('Please add text or remove the text block'),
  BLOCK_WITHOUT_CONNECTION_TEXT_REQUIRED: l.makeTranslate('Please add text or remove this step'),
  BLOCK_TEXT_MAX_LENGTH: (max: number) =>
    l.makeTranslate(`Text must be less than {max} characters long`, { max }),
  BLOCK_ATTACHMENT_REQUIRED: l.makeTranslate('Please select an attachment file'),
  BLOCK_ATTACHMENT_UPLOADING: l.makeTranslate('Please wait until the upload is complete'),
  BLOCK_LIST_MIN_ITEMS: l.makeTranslate('Please create at least 2 list items'),
  BLOCK_LIST_ITEM_IMAGE_REQUIRED: l.makeTranslate(
    'Please select an image for top item with large style in list',
  ),
  BLOCK_LIST_ITEM_TITLE_REQUIRED: l.makeTranslate('Please enter a title'),
  BLOCK_LIST_ITEM_TITLE_LINES_ALLOWED: l.makeTranslate(`Only one line is allowed`),
  BLOCK_LIST_ITEM_TITLE_MAX_LENGTH: l.makeTranslate(
    `Title must be less than {ListMaxTitleLength} characters long`,
    { ListMaxTitleLength },
  ),
  BLOCK_LIST_ITEM_SUBTITLE_MAX_LENGTH: l.makeTranslate(
    `Subtitle must be less than {ListMaxSubtitleLength} characters long`,
    { ListMaxSubtitleLength },
  ),
  BLOCK_LIST_ITEM_INFO_REQUIRED: l.makeTranslate(
    'You need to set up at least one more list item field: subtitle, image or button',
  ),
  BLOCK_CARD_TITLE_REQUIRED: l.makeTranslate('Please enter a title'),
  BLOCK_CARD_TITLE_MAX_LENGTH: l.makeTranslate(
    `Title must be less than {CardMaxTitleLength} characters long`,
    { CardMaxTitleLength },
  ),
  BLOCK_CARD_TITLE_LINES_ALLOWED: l.makeTranslate(`Only one line is allowed`),
  BLOCK_CARD_SUBTITLE_MAX_LENGTH: l.makeTranslate(
    `Subtitle must be less than {CardMaxSubtitleLength} characters long`,
    { CardMaxSubtitleLength },
  ),
  BLOCK_CARD_INFO_REQUIRED: l.makeTranslate(
    'You need to set up at least one more card field: subtitle, image or button',
  ),
  BLOCK_REQUEST_TITLE_REQUIRED: l.makeTranslate('Please enter a title'),
  BLOCK_QUESTION_TEXT_REQUIRED: l.makeTranslate('Please enter a question'),
  BLOCK_DELAY_POSITION_ERROR: l.makeTranslate(
    'The Typing Delay cannot be the final element in the message',
  ),
  BLOCK_FORM_QUESTION_MIN_PREDEFINED: l.makeTranslate(
    'Please enter one or more replies for multiple choice user input',
  ),
  BLOCK_FORM_QUESTION_REPLY_TYPE_NO_PREDEFINED: l.makeTranslate(
    'Predefined replies are not allowed for this reply type - please remove them or change input reply type',
  ),
  BLOCK_FORM_QUESTION_BUTTON_CAPTION_REQUIRED: l.makeTranslate(
    `Your date picker button must contain text.`,
  ),
  BLOCK_FORM_QUESTION_BUTTON_SKIP_BUTTON_CAPTION_REQUIRED: l.makeTranslate(
    'Your date picker skip button must contain text.',
  ),
  BLOCK_FORM_QUESTION_MC_LOCATION_BUTTON_CAPTION_REQUIRED: l.makeTranslate(
    `Your button must contain text.`,
  ),
  BLOCK_FORM_QUESTION_MC_LOCATION_BUTTON_SKIP_BUTTON_CAPTION_REQUIRED: l.makeTranslate(
    'Your "Skip" button must contain text.',
  ),
  BLOCK_QR_AFTER_QUESTION: l.makeTranslate('Quick Replies cannot be added after User Input block'),
  INSTAGRAM_BLOCK_QR_NOT_VALID: l.makeTranslate(
    'Quick Replies can be added only after Text block without Buttons for the Instagram channel',
  ),
  BLOCK_ONE_TIME_NOTIFY_REQUEST_REASON_ID_EMPTY: l.makeTranslate(`Please select a List`),
  BLOCK_ONE_TIME_NOTIFY_REQUEST_REASON_ARCHIVED: l.makeTranslate(
    'List is archived. Choose another one or unarchived it',
  ),
  BLOCK_MULTIPLE_BUY_BUTTONS: l.makeTranslate(`You can add only one buy button here`),
  DYNAMIC_IMAGE_URL: l.makeTranslate(`Please specify image URL`),
  TELEGRAM_KEYBOARD_BLOCK_AFTER_QUESTION: l.makeTranslate(
    `Telegram menu cannot appear right after 'User Input' block`,
  ),
  TELEGRAM_KEYBOARD_BLOCK_AFTER_BUTTONS: l.makeTranslate(
    `Telegram menu can't be placed after a block that contains buttons. Please remove buttons from the previous block prior to Telegram menu`,
  ),

  // Button
  BUTTON_CAPTION_REQUIRED: l.makeTranslate('Please enter a button title'),
  BUTTON_CAPTION_TITLE_LINES_ALLOWED: l.makeTranslate(`Only one line is allowed`),
  BUTTON_CAPTION_MAX_LENGTH: (max: number) =>
    l.makeTranslate(`Button title must be less than {max} characters long`, { max }),
  HYPERLINK_CAPTION_MAX_LENGTH: l.makeTranslate(
    `Link text must be less than {HyperlinkMaxCaptionLength} characters long`,
    { HyperlinkMaxCaptionLength },
  ),
  BUTTON_IMAGE_UPLOADING: l.makeTranslate('Please wait until the image upload is complete'),
  BUTTON_CONTENT_REQUIRED: l.makeTranslate(
    'Please specify what happens when this button is pressed',
  ),
  BUTTON_URL_REQUIRED: l.makeTranslate('Please enter a URL'),
  BUTTON_URL_INVALID: l.makeTranslate('Please enter a valid URL'),
  BUTTON_PHONE_INVALID: l.makeTranslate('Please enter a valid phone number'),
  BUTTON_VALUE_REQUIRED: l.makeTranslate('Please enter a value'),
  BUTTON_TYPE_REQUIRED: l.makeTranslate('Please select a next step for the button'),
  BUTTON_ITEM_LABEL_REQUIRED: l.makeTranslate('Please enter item label'),
  BUTTON_ITEM_PRICE_INVALID: l.makeTranslate('Please enter a valid item price'),
  BUTTON_ITEM_PRICE_SMALL: (min: number, currency: PaymentCurrencies) =>
    l.makeTranslate(`The minimum item price should be {min} {currency}`, { min, currency }),
  BUTTON_FLOW_REQUIRED: l.makeTranslate('Please select Automation'),
  // If/Else Node
  CONDITION_TARGET_REQUIRED: l.makeTranslate('Please select at least one next step for condition'),
  CONDITION_FILTER_REQUIRED: l.makeTranslate('Please setup a condition'),
  CONDITION_FIELD_MISSING: l.makeTranslate('Field is missing'),
  CONDITION_TAG_MISSING: l.makeTranslate('Tag is missing'),
  CONDITION_TOPIC_MISSING: l.makeTranslate('List is missing'),
  //Split
  SPLIT_VARIANTS_MIN_ITEMS: l.makeTranslate('Please add at least 2 variations'),
  SPLIT_VARIANTS_MAX_ITEMS: l.makeTranslate('Please keep maximum 6 variations'),
  SPLIT_DISTRIBUTION_TOTAL: l.makeTranslate('Distribution total must equal 100%'),
  SPLIT_TARGET_REQUIRED: l.makeTranslate('Please choose next step'),
  SPLIT_TOTAL_PERCENT: (total: number) => l.makeTranslate(`Current is {total}%`, { total }),
  //Smart delay
  SMART_DELAY_DURATION_VALUE_REQUIRED: l.makeTranslate('Please enter a delay duration'),
  // Sms
  SMS_NODE_IMAGE_TOO_BIG: l.makeTranslate(
    `Selected image is too large. We can not send an attachment larger that {SMS_NODE_IMAGE_MAX_SIZE} MB`,
    { SMS_NODE_IMAGE_MAX_SIZE: formatBytesToMbString(SMS_NODE_IMAGE_MAX_SIZE) },
  ),
  ACTION_CUSTOMER_CHAT_WIDGET_REQUIRED: l.makeTranslate(
    'Please add Customer Chat Growth Tool to use this action',
  ),

  // CAPI
  ACTION_CAPI_EVENT_FIELDS_REQUIRED: l.makeTranslate('Please complete required fields correctly'),

  // Email
  EMAIL_LINK_NO_URL: l.makeTranslate('Enter a website URL for the link in Email Design'),
  EMAIL_IMAGE_NO_URL: l.makeTranslate('Enter a website URL for the image in Email Design'),
  EMAIL_BUTTON_NO_URL: l.makeTranslate('Enter a website URL for the button in Email Design'),
  EMAIL_TEXT_BLOCK_EMPTY: l.makeTranslate('Enter text for the text block in Email Design'),

  // WA List Message
  WA_LIST_MESSAGE_SECTIONS_MAX_LENGTH: (max: number) =>
    l.makeTranslate('Maximum allowed number of sections is {max}', { max }),
  WA_LIST_MESSAGE_OPTIONS_MAX_LENGTH: (max: number) =>
    l.makeTranslate('Maximum allowed number of options per section is {max}', { max }),
  WA_LIST_MESSAGE_OPTIONS_NO_TARGET: l.makeTranslate('Please choose a next step for this option'),

  // WA General
  WA_FIELD_MAX_LENGTH: (field: string, max: number) =>
    l.makeTranslate(`{field} text must be less than {max} characters long`, { field, max }),
  WA_FIELD_EMPTY: (field: string) => l.makeTranslate(`{field} cannot be blank`, { field }),
}

export const MAX_COUNT_BLOCKS_TO_SHOW_EMPTY_TEXT_ERROR = 2

export const ERROR_PANE = 'errorPane'

export default errorMessages

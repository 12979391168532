import find from 'lodash/find'
export default class BaseExporter {
  constructor(parsed) {
    this.parsed = parsed
    this.batch = {
      root_content: null,
      contents: [],
    }
  }

  compose = () => {
    throw new Error(`not implemented`)
  }

  findNode = (id) => {
    if (!id) {
      return null
    }
    return find(this.parsed.nodes, (item) => item.contentId === id || item.id === id)
  }

  findBlock = (id) => {
    if (!id) {
      return null
    }
    return find(this.parsed.blocks, { id })
  }

  findButton = (id) => {
    if (!id) {
      return null
    }
    return find(this.parsed.buttons, { id })
  }
}

import { AxiosResponse } from 'axios'

const STATE_KEY = 'state'

export const convertResponseWithoutState = (response: AxiosResponse): AxiosResponse => {
  const keys = Object.keys(response.data).filter((key) => key !== STATE_KEY)

  if (keys.length === 0) {
    return {
      ...response,
      data: undefined,
    }
  }

  const dataWithoutState = keys.reduce<Partial<typeof response.data>>((acc, key) => {
    acc[key] = response.data[key]
    return acc
  }, {})

  return {
    ...response,
    data: dataWithoutState,
  }
}

import { IAccountBase } from 'common/core/interfaces/accountBase'
import { ICurrentAccount } from 'common/core/interfaces/currentAccount'

import { AccountStatus } from './constants'

const FreeStatuses = [AccountStatus.FREE, AccountStatus.FREE2020, AccountStatus.FLEX_FREE]
const TrialStatuses = [
  AccountStatus.PRO_TRIAL,
  AccountStatus.PRO_TRIAL2020,
  AccountStatus.FLEX_TRIAL,
]
const ProStatuses = [
  AccountStatus.PRO,
  AccountStatus.PRO2020,
  AccountStatus.FLEX_PRO,
  AccountStatus.FLEX_PREMIUM,
]
const ProOrTrialStatuses = [...ProStatuses, ...TrialStatuses]
const ExpiredStatuses = [
  AccountStatus.EXPIRED,
  AccountStatus.EXPIRED2020,
  AccountStatus.FLEX_EXPIRED,
]

export function isFree(account: ICurrentAccount): boolean {
  const status = account?.pro_status || AccountStatus.FREE2020

  return isFreeStatus(status)
}

export function isTrial(account: IAccountBase | null): boolean {
  if (!account) return false

  return isTrialStatus(account.pro_status)
}

export function isProOrTrial(account: IAccountBase | null): boolean {
  if (!account) return false

  return isProOrTrialStatus(account.pro_status)
}

export function isExpired(account: IAccountBase | null): boolean {
  if (!account) return false

  return isExpiredStatus(account.pro_status)
}

export function isProStatus(status: AccountStatus): boolean {
  return ProStatuses.includes(status)
}

export function isFreeStatus(status: AccountStatus): boolean {
  return FreeStatuses.includes(status)
}

export function isTrialStatus(status: AccountStatus): boolean {
  return TrialStatuses.includes(status)
}

export function isProOrTrialStatus(status: AccountStatus): boolean {
  return ProOrTrialStatuses.includes(status)
}

export function isExpiredStatus(status: AccountStatus): boolean {
  return ExpiredStatuses.includes(status)
}

export function isVipProStatus(account: IAccountBase | null): boolean {
  return AccountStatus.VIP_PRO === account?.pro_status
}

export function isAnyPro(account: IAccountBase | null): boolean {
  return isProOrTrial(account) || isVipProStatus(account)
}

type AccountStatusLabel = 'FREE' | 'EXPIRED' | 'TRIAL' | 'PRO'

const LabeltoStatusMap: Record<AccountStatusLabel, AccountStatus[]> = {
  FREE: FreeStatuses,
  EXPIRED: ExpiredStatuses,
  TRIAL: TrialStatuses,
  PRO: ProOrTrialStatuses,
}

export function getSubscriptionLabelByStatus(status: AccountStatus): AccountStatusLabel {
  let label: AccountStatusLabel = 'PRO' // I don't know what is better for the default value.

  ;(Object.keys(LabeltoStatusMap) as Array<keyof typeof LabeltoStatusMap>).some((keyLabel) => {
    if (LabeltoStatusMap[keyLabel].includes(status)) {
      label = keyLabel
      return true
    }

    return false
  })

  return label
}

export enum NotificationType {
  REGULAR = 'regular',
  REGULAR_ONCE = 'regular_once',
  REGULAR_TWICE = 'regular_twice',
  SILENT = 'silent',
  DISABLED = 'no',
}

export const NotificationTypeLabel: Record<NotificationType, string> = {
  [NotificationType.REGULAR]: 'Regular Push',
  [NotificationType.REGULAR_ONCE]: 'Only 1 regular notification',
  [NotificationType.REGULAR_TWICE]: 'Only 2 regular notifications',
  [NotificationType.SILENT]: 'Silent Push',
  [NotificationType.DISABLED]: 'Silent',
}

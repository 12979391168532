import React, { useEffect } from 'react'
import cx from 'classnames'
import { l } from '@manychat/manyui'

import { billing, UpgradeSource } from 'common/billing'
import { analyticsService } from 'utils/services/analytics'

import cm from '../StaticNotifications.module.css'

export const WhatsAppBlockedNotification = () => {
  const handleClick = () => {
    analyticsService.sendEvent('NOTIFICATION_BAR.WA_BLOCKED.UPGRADE_TO_PRO.CLICK')
    billing.requestUpgrade({ source: UpgradeSource.WA_UPGRADE_TO_PRO })
  }

  useEffect(() => {
    analyticsService.sendEvent('NOTIFICATION_BAR.WA_BLOCKED.SHOWN')
  }, [])

  return (
    <>
      <span>
        {l.translate(
          'Your WhatsApp Account is blocked due to the expiration of your subscription.',
        )}
      </span>
      <a target="_blank" className={cx(cm.more, 'm-l-xs')} onClick={handleClick}>
        {l.translate('Upgrade to Pro')}
      </a>
    </>
  )
}

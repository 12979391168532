import dot from 'dot-prop-immutable'
import map from 'lodash/map'

import * as emailAtypes from 'apps/email/emailReduxActionTypes'
import * as smsAtypes from 'apps/sms/smsReduxActionTypes'
import { billingInfoActions } from 'common/billing/redux/billingInfo'
import * as appAtypes from 'common/core/constants/appReduxActionTypes'
import { ExportSteps } from 'common/core/constants/ExportSteps'
import * as emailActionsTyped from 'common/settings/actions/emailActionsTyped'
import { connectPaypal, disconnectPaypal } from 'common/settings/actions/paypalActionsTyped'
import {
  getPublicAPIToken,
  deletePublicAPIToken,
} from 'common/settings/actions/publicAPIActionsTyped'
import {
  disconnectStripe,
  setCurrency,
  setPaymentNotifySettings,
  setStripeChargeEmailNotification,
  getHandoverReceivers,
  setLiveChatReceiver,
} from 'common/settings/actions/settingsActionsTyped'
import * as liveChatAtypes from 'common/settings/constants/liveChatReduxActionTypes'
import * as atypes from 'common/settings/SettingsReduxActionTypes'
import { makeCurrentItemReducer } from 'utils/factory'

export const initialState = {
  locales: null,
  timezones: null,
  handoverReceivers: null,
  whitelist: [], // [{domain: "manychat.com", deletable: true, fb_verified: true}, ...]
  isWhitelistLoading: false,
  isTimezonesLoading: false,
  exportBotStatus: {
    //here will be stored bot cloning statuses by account ids:
    //[account.Id]: STATUS
  },
  liveChatSnippets: [],
  optInPayload: null,
  fetching: false,
  policy_enforcement: {
    fetching: false,
    fetchingTax: false,
  },
}

const itemInitialState = {}

const itemReducer = makeCurrentItemReducer('settings', { initialState: itemInitialState })

export default function settingsReducer(state = initialState, action) {
  state = itemReducer(state, action)
  switch (action.type) {
    case atypes.SETTINGS_UPDATE_RESPONSE: {
      return { ...state, item: action.data.settings }
    }
    case atypes.SETTINGS_CURRENT_FETCH_REQUEST: {
      return { ...state, fetching: true }
    }
    case atypes.SETTINGS_CURRENT_FETCH_RESPONSE: {
      if (state.optInPayload) {
        return { ...state, fetching: false }
      }
      return { ...state, optInPayload: action.data.opt_in_payload, fetching: false }
    }
    case atypes.SETTINGS_CURRENT_FETCH_ERROR: {
      return { ...state, fetching: false }
    }
    case atypes.SETTINGS_DEVLOGS_FETCH_POLICY_ENFORCEMENT_REQUEST: {
      return {
        ...state,
        policy_enforcement: {
          ...state.policy_enforcement,
          fetching: true,
        },
      }
    }
    case atypes.SETTINGS_DEVLOGS_FETCH_POLICY_ENFORCEMENT_RESPONSE: {
      return {
        ...state,
        policy_enforcement: {
          ...state.policy_enforcement,
          logs: action.data.logs,
          fetching: false,
        },
      }
    }
    case atypes.SETTINGS_DEVLOGS_FETCH_POLICY_ENFORCEMENT_ERROR: {
      return {
        ...state,
        policy_enforcement: {
          ...state.policy_enforcement,
          fetching: false,
        },
      }
    }

    case atypes.SET_MOBILE_NOTIFY_ADMIN_PUSH_ENABLED_RESPONSE: {
      return dot.set(
        state,
        'item.current_user.mobile_notify_admin_push_enabled',
        action.data.mobile_notify_admin_push_enabled,
      )
    }

    case appAtypes.APP_DROP_OPTIN_REQUIRED: {
      return dot.set(state, 'item.current_user.is_opted_in', true)
    }
    case billingInfoActions.saveBillingInfo.fulfilled.type:
    case billingInfoActions.switchInvoicesSending.fulfilled.type: {
      return dot.set(state, 'item.billing_info', action.payload.billing_info)
    }
    case atypes.LIVECHAT_SETTINGS_UPDATE_RESPONSE: {
      return dot.merge(state, 'item.current_user', action.data)
    }
    case atypes.LIVECHAT_ACCOUNT_SETTINGS_UPDATE_RESPONSE: {
      return dot.merge(
        state,
        'item.livechat_account_settings',
        action.data.livechat_account_settings,
      )
    }
    case atypes.SETTINGS_CURRENT_SETWIDGETLOCALE_RESPONSE: {
      return dot.set(state, 'item.widget_locale', action.body.widget_locale)
    }
    case atypes.SETTINGS_HANDOVER_UPDATED_NOTIFICATION: {
      return dot.merge(state, 'item.handover', action.data.handover)
    }
    case atypes.SETTINGS_SET_DELETED_SUBSCRIBERS_VISIBILITY_RESPONSE: {
      const {
        item: { audience_only_active },
      } = state
      return dot.set(state, 'item.audience_only_active', !audience_only_active)
    }
    case atypes.SETTINGS_USE_PERSONA_RESPONSE: {
      const {
        item: { use_persona },
      } = state
      return dot.set(state, 'item.use_persona', !use_persona)
    }
    case atypes.SETTINGS_CURRENT_GETLISTLOCALES_RESPONSE: {
      const locales = map(action.data.locales, (value, label) => ({ value, label }))
      return dot.set(state, 'locales', locales)
    }
    case atypes.SETTINGS_CURRENT_GETWHITELISTINGDOMAINS_RESPONSE:
    case atypes.SETTINGS_CURRENT_ADDWHITELISTINGDOMAIN_RESPONSE:
    case atypes.SETTINGS_CURRENT_DELETEWHITELISTINGDOMAIN_RESPONSE: {
      state = dot.set(state, 'isWhitelistLoading', false)
      return dot.set(state, 'whitelist', action.data.whitelist)
    }
    case atypes.SETTINGS_CURRENT_GETWHITELISTINGDOMAINS_REQUEST:
    case atypes.SETTINGS_CURRENT_ADDWHITELISTINGDOMAIN_REQUEST:
    case atypes.SETTINGS_CURRENT_DELETEWHITELISTINGDOMAIN_REQUEST: {
      return dot.set(state, 'isWhitelistLoading', true)
    }
    case atypes.SETTINGS_CURRENT_ADDWHITELISTINGDOMAIN_ERROR:
    case atypes.SETTINGS_CURRENT_DELETEWHITELISTINGDOMAIN_ERROR: {
      state = dot.set(state, 'whitelistingError', action.error.error)
      return dot.set(state, 'isWhitelistLoading', false)
    }
    case atypes.SETTINGS_GET_TIMEZONES_LIST_REQUEST: {
      return dot.set(state, 'isTimezonesLoading', true)
    }
    case atypes.SETTINGS_GET_TIMEZONES_LIST_RESPONSE: {
      state = dot.set(state, 'isTimezonesLoading', false)
      return dot.set(state, 'timezones', action.data.timezones)
    }
    case atypes.SETTINGS_GET_TIMEZONES_LIST_ERROR: {
      return dot.set(state, 'isTimezonesLoading', false)
    }

    case atypes.SETTINGS_SET_BOT_TIMEZONE_REQUEST: {
      return dot.set(state, 'isBotTimezoneChanging', true)
    }
    case atypes.SETTINGS_SET_BOT_TIMEZONE_RESPONSE: {
      return dot.set(state, 'isBotTimezoneChanging', false)
    }
    case atypes.SETTINGS_SET_BOT_TIMEZONE_ERROR: {
      return dot.set(state, 'isBotTimezoneChanging', false)
    }

    case smsAtypes.SMS_CHANNEL_SWITCH_REQUEST: {
      return dot.set(state, 'item.isSMSSwitchError', false)
    }
    case smsAtypes.SMS_CHANNEL_SWITCH_ERROR: {
      return dot.set(state, 'item.isSMSSwitchError', true)
    }
    case emailAtypes.EMAIL_SETTINGS_SET_SENDER_NAME_RESPONSE:
    case emailAtypes.EMAIL_SETTINGS_SET_REPLY_TO_RESPONSE: {
      return dot.set(state, 'item.email', action.data.email_settings)
    }

    case atypes.SET_SKIP_FLOW_TEMPLATES_REQUEST: {
      return dot.set(state, 'item.skip_flow_templates_modal', action.payload)
    }

    // EXPORT BOT
    case atypes.EXPORT_BOT_REQUEST: {
      const { accountId } = action
      return dot.set(state, ['exportBotStatus', accountId], ExportSteps.PROCESS)
    }
    case atypes.EXPORT_BOT_ERROR: {
      const { accountId } = action
      return dot.set(state, ['exportBotStatus', accountId], ExportSteps.FAIL)
    }
    case atypes.EXPORT_BOT_RESPONSE: {
      const { accountId } = action
      return dot.set(state, ['exportBotStatus', accountId], ExportSteps.SUCCESS)
    }
    case atypes.EXPORT_BOT_COMPLETE: {
      const { accountId } = action
      return dot.delete(state, ['exportBotStatus', accountId])
    }

    case liveChatAtypes.FETCH_SNIPPET_RESPONSE: {
      const {
        data: { snippets },
      } = action

      return dot.set(state, 'liveChatSnippets', snippets)
    }
    case liveChatAtypes.CREATE_SNIPPET_RESPONSE: {
      const {
        data: { snippet_id, title, content, type_title },
      } = action

      return dot.merge(state, 'liveChatSnippets', { title, content, snippet_id, type_title })
    }
    case liveChatAtypes.EDIT_SNIPPET_RESPONSE: {
      const {
        data: { snippet_id, title, content, type_title },
      } = action

      const snippets = state.liveChatSnippets
      const editedSnippetIndex = snippets.findIndex((s) => s.snippet_id === snippet_id)
      const hasSnippet = editedSnippetIndex !== -1
      return hasSnippet
        ? dot.set(state, `liveChatSnippets.${editedSnippetIndex}`, {
            title,
            content,
            snippet_id,
            type_title,
          })
        : state
    }
    case liveChatAtypes.DELETE_SNIPPET_RESPONSE: {
      const {
        data: { snippet_id },
      } = action

      const snippets = state.liveChatSnippets
      const removedSnippetIndex = snippets.findIndex((s) => s.snippet_id === snippet_id)
      const hasSnippet = removedSnippetIndex !== -1

      return hasSnippet ? dot.delete(state, `liveChatSnippets.${removedSnippetIndex}`) : state
    }

    case liveChatAtypes.UPDATE_LIVECHAT_FILTER: {
      const { value } = action
      return dot.set(state, 'item.lc_threads_filter_enabled', value)
    }

    case atypes.SET_AUTOMATION_PAUSE_TIME:
      return {
        ...state,
        item: {
          ...state.item,
          automation_pause_seconds: action.data.pauseValue,
          livechat_account_settings: {
            ...state.item.livechat_account_settings,
            pause_automation_format: action.data.pauseFormat,
          },
        },
      }

    case atypes.SET_DISPLAY_PREFERENCE: {
      const { preference, value } = action.payload
      return dot.set(state, `item.display_preferences.${preference}`, value)
    }

    case atypes.SET_DISPLAY_PREFERENCES: {
      const { value } = action.payload
      return dot.set(state, `item.display_preferences`, value)
    }

    case atypes.SET_CONVERSION_API_BY_CHANNEL: {
      const { channel, value } = action.payload

      if (!state.item.conversions_api || value === null) {
        return dot.set(state, 'item.conversions_api', { [channel]: value })
      }

      return dot.merge(state, `item.conversions_api.${channel}`, value)
    }

    case atypes.SET_INITIAL_SETTINGS: {
      const data = {}

      // if we have settings item, we shouldn't update it
      if (!action?.payload || state.item) {
        return state
      }

      if (action.payload?.settings) {
        data.item = action.payload.settings
      }

      return { ...state, ...data }
    }

    case getHandoverReceivers.fulfilled.type: {
      return { ...state, handoverReceivers: action.payload.receivers }
    }

    case setLiveChatReceiver.fulfilled.type: {
      return {
        ...state,
        item: { ...state.item, handover: { ...state.item.handover, app_id: action.meta.arg } },
      }
    }

    case emailActionsTyped.switchEmailChannel.pending.type: {
      return {
        ...state,
        item: {
          ...state.item,
          isEmailSwitchError: false,
        },
      }
    }
    case emailActionsTyped.switchEmailChannel.rejected.type: {
      return {
        ...state,
        item: {
          ...state.item,
          isEmailSwitchError: true,
        },
      }
    }

    case emailActionsTyped.showEmailBranding.fulfilled.type:
    case emailActionsTyped.setEmailSettings.fulfilled.type: {
      return {
        ...state,
        item: {
          ...state.item,
          email: action.payload.email_settings,
        },
      }
    }

    case disconnectStripe.fulfilled.type:
    case setCurrency.fulfilled.type:
    case setPaymentNotifySettings.fulfilled.type:
    case setStripeChargeEmailNotification.fulfilled.type:
    case connectPaypal.fulfilled.type:
    case disconnectPaypal.fulfilled.type: {
      return { ...state, item: action.payload.settings }
    }
    case getPublicAPIToken.fulfilled.type: {
      return {
        ...state,
        publicAPIAccessToken: action.payload.token,
      }
    }
    case deletePublicAPIToken.fulfilled.type: {
      return {
        ...state,
        publicAPIAccessToken: '',
      }
    }
  }

  return state
}

import debugConstructor from 'debug'

import { isTestEnv } from 'utils/e2e'
import { getInfoAboutService } from 'utils/getInfoAboutService'
import { AnalyticsData } from 'utils/services/analytics/types'

import { IGA } from './GAnalytics'
import { flowBuilderEvents, IMCA } from './MCAnalytics'

export const USERCENTRICS_MC_NAME = 'Manychat Analytics'
export const USERCENTRICS_GA_NAME = 'Google Analytics'
const MAXIMUM_RECORDS_IN_QUEUE = 40

const debug = debugConstructor('analytics')

interface Queue {
  pushTask(task: () => void): void
}

interface SendEventValueType {
  eventName: string
  data?: AnalyticsData
}
interface SendLoggedoutUserEventValueType {
  eventName: string
}
interface LogFlowBuilderEventValueType {
  eventName: string
  data?: AnalyticsData
}
type EventsValueType =
  | SendEventValueType
  | SendLoggedoutUserEventValueType
  | LogFlowBuilderEventValueType

type EventsQueueType =
  | SendEventValueType[]
  | SendLoggedoutUserEventValueType[]
  | LogFlowBuilderEventValueType[]

const User = window.__INIT__?.['app.user']
const IdFromInit = User?.user_id
const UserId = IdFromInit ? IdFromInit : null

export default class AnalyticsService {
  private gAnalytics: IGA
  private mcAnalytics: IMCA
  private queue: Queue
  public isProvideMCAnalytics: boolean
  public isProvideGAAnalytics: boolean
  public sendEventQueue: SendEventValueType[] = []
  public sendLoggedoutUserEventQueue: SendLoggedoutUserEventValueType[] = []
  public logFlowBuilderEventQueue: LogFlowBuilderEventValueType[] = []

  constructor(gAnalytics: IGA, mcAnalytics: IMCA, queue: Queue) {
    const { isProvideMCAnalytics, isProvideGAAnalytics } = this.getServicesProvidedStatus()
    this.gAnalytics = gAnalytics
    this.mcAnalytics = mcAnalytics
    this.queue = queue
    this.isProvideMCAnalytics = isProvideMCAnalytics
    this.isProvideGAAnalytics = isProvideGAAnalytics
    this.mockAnalytics()
  }

  private saveDataInArray = (queue: EventsQueueType, value: EventsValueType) => {
    const amountRecords = queue.length
    if (amountRecords >= MAXIMUM_RECORDS_IN_QUEUE) {
      return
    }

    queue.push(value)
  }

  sendEvent = (eventName: string, data?: AnalyticsData): void => {
    if (!this.isProvideMCAnalytics && !this.isProvideGAAnalytics) {
      this.saveDataInArray(this.sendEventQueue, { eventName, data })
      return
    }

    const eventCallTime = Date.now()
    this.queue.pushTask(() => {
      debug(eventName, data)

      this.isProvideMCAnalytics && this.mcAnalytics.event(eventName, eventCallTime, data)
      this.isProvideGAAnalytics && this.gAnalytics.event(eventName, data)
    })
  }

  sendLoggedoutUserEvent = (eventName: string): void => {
    if (!this.isProvideMCAnalytics) {
      this.saveDataInArray(this.sendLoggedoutUserEventQueue, { eventName })
      return
    }

    const eventCallTime = Date.now()
    this.queue.pushTask(() => {
      debug(eventName)

      this.mcAnalytics.loggedoutUserEvent(eventName, eventCallTime)
    })
  }

  logFlowBuilderEvent = async (eventName: flowBuilderEvents, data?: UnsafeAnyObject) => {
    try {
      if (!this.isProvideMCAnalytics) {
        const flowBuilderEventName = eventName as unknown as string
        this.saveDataInArray(this.logFlowBuilderEventQueue, {
          eventName: flowBuilderEventName,
          data,
        })
        return
      }

      await this.mcAnalytics.logFlowBuilderEvent(eventName, data)
    } catch (err) {
      debug(err)
    }
  }

  externalAnalytics = async (): Promise<void> => {
    if (!this.isProvideMCAnalytics) {
      return
    }
    await this.mcAnalytics.externalAnalytics(UserId)
  }

  private mockAnalytics() {
    if (!isTestEnv()) {
      return
    }
    const noop = () => Promise.resolve()
    this.gAnalytics = { event: noop }
    this.mcAnalytics = {
      event: noop,
      loggedoutUserEvent: noop,
      logFlowBuilderEvent: noop,
      externalEvent: noop,
      externalAnalytics: noop,
    }
  }

  private getServicesProvidedStatus = () => {
    const usercentricsServicesInfo = getInfoAboutService([
      USERCENTRICS_MC_NAME,
      USERCENTRICS_GA_NAME,
    ])
    const isProvideMCAnalytics = usercentricsServicesInfo[USERCENTRICS_MC_NAME]?.status ?? false
    const isProvideGAAnalytics = usercentricsServicesInfo[USERCENTRICS_GA_NAME]?.status ?? false
    return { isProvideMCAnalytics, isProvideGAAnalytics }
  }

  private callAllPendedEvents = () => {
    this.externalAnalytics()

    const isRecordsInSendEvent = this.sendEventQueue.length > 0
    if (isRecordsInSendEvent) {
      this.sendEventQueue.forEach(({ eventName, data }) => this.sendEvent(eventName, data))
      this.sendEventQueue = []
    }

    const isRecordsInsendLoggedoutUserEvent = this.sendLoggedoutUserEventQueue.length > 0
    if (isRecordsInsendLoggedoutUserEvent) {
      this.sendLoggedoutUserEventQueue.forEach(({ eventName }) =>
        this.sendLoggedoutUserEvent(eventName),
      )
      this.sendLoggedoutUserEventQueue = []
    }

    const isRecordsLogFlowBuilderEvent = this.logFlowBuilderEventQueue.length > 0
    if (isRecordsLogFlowBuilderEvent) {
      this.logFlowBuilderEventQueue.forEach(({ eventName, data }) => {
        const flowBuilderEventName = eventName as unknown as flowBuilderEvents
        this.logFlowBuilderEvent(flowBuilderEventName, data)
      })
      this.logFlowBuilderEventQueue = []
    }
  }

  public refreshPermissions = () => {
    const { isProvideMCAnalytics, isProvideGAAnalytics } = this.getServicesProvidedStatus()
    this.isProvideMCAnalytics = isProvideMCAnalytics
    this.isProvideGAAnalytics = isProvideGAAnalytics

    if (this.isProvideMCAnalytics) {
      this.callAllPendedEvents()
    }
  }
}

import React from 'react'
import cx from 'classnames'
import { Loader, l, Icon } from '@manychat/manyui'

import cm from './ExportModalSteps.module.css'

export const ExportProcess = ({ processName = 'Exporting' }) => (
  <div className={cx(cm.step, 'p-a-md')}>
    <Loader className={cx(cm.stepRow)} />
    <div className={cx(cm.stepText, cm.stepRow)}>{processName}...</div>
  </div>
)

export const ExportFail = ({ processName = 'Export', onRetry, caption, children }) => (
  <div className={cx(cm.step, cm.step_error, 'p-a-md')}>
    <Icon.Warning className={cm.stepRow} />
    <div className={cx(cm.stepText, cm.stepRow)}>
      {caption ? (
        caption
      ) : (
        <>
          {l.translate('Something Went Wrong!')}
          <br />
          {l.translate('{processName} Failed.', { processName })}
        </>
      )}
    </div>
    {onRetry && (
      <div className={cx(cm.stepText, cm.stepText_action, cm.stepRow)} onClick={onRetry}>
        {l.translate('Retry')}
      </div>
    )}
    {children}
  </div>
)

export const ExportSuccess = ({ processName = l.translate('Export'), children }) => (
  <div className={cx(cm.step, cm.step_success, 'p-a-md')}>
    <Icon.CheckCircle className={cm.stepRow} />
    <div className={cx(cm.stepText, cm.stepRow)}>
      {l.translate('{processName} Successful!', { processName })}
    </div>
    {children}
  </div>
)

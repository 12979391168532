import React, { useCallback } from 'react'
import cx from 'classnames'
import { useAppDispatch, useAppSelector } from 'reduxTyped'
import { l } from '@manychat/manyui'

import { getMaxTierContact } from 'common/billing/helpers/tierLimit'
import { tierLimitModalActions } from 'common/billing/redux/tierLimitModal'
import { tiersActions } from 'common/billing/redux/tiers'
import {
  getBillableContacts,
  getAutomationProductTier,
} from 'common/billing/selectors/billingSelectors'
import { useDidMountEffect } from 'utils/commonHooks'
import { analyticsService } from 'utils/services/analytics'

import cm from '../StaticNotifications.module.css'

export const TierLimitNotification = () => {
  const dispatch = useAppDispatch()

  const contacts = useAppSelector(getBillableContacts)
  const plan_subscribers = useAppSelector(getAutomationProductTier)
  const currentTierMax = getMaxTierContact(plan_subscribers)

  const handleOpenTierLimitModalClick = useCallback(() => {
    analyticsService.sendEvent('NOTIFICATION_BAR.TIER_LIMIT.MODAL.CLICKED')
    dispatch(tierLimitModalActions.open())
  }, [dispatch])

  useDidMountEffect(() => {
    dispatch(tiersActions.fetchTiers())
    analyticsService.sendEvent('NOTIFICATION_BAR.TIER_LIMIT.SHOWN')
  })
  return (
    <>
      <span>
        {l.translate(
          `Your account has reached <b>{contacts} out of {currentTierMax}</b> contacts limit.`,
          {
            currentTierMax,
            contacts,
          },
        )}
      </span>
      <a onClick={handleOpenTierLimitModalClick} className={cx(cm.more, 'm-l-sm')}>
        {l.translate('Learn more')}
      </a>
    </>
  )
}

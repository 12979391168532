import dot from 'dot-prop-immutable'
import { combineReducers } from 'redux'

import * as atypes from 'common/builder/constants/builderReduxActionTypes'
import snapshotsReducer from 'common/builder/reducers/builders/snapshots'

import blocks from './blocks'
import builderStateReducer from './builderState'
import buttons from './buttons'
import makeSourcesReducer from './makeSourcesReducer'
import nodes from './nodes'

const initialState = { byId: {} }

const entitiesReducers = combineReducers({
  nodes: makeSourcesReducer(nodes),
  blocks: makeSourcesReducer(blocks),
  buttons: makeSourcesReducer(buttons),
})

export default function builders(state = initialState, action) {
  const { builderId } = action

  if (action.type === atypes.REMOVE_BUILDER_STATE) {
    return dot.delete(state, `byId.${builderId}`)
  }

  if (builderId) {
    const prevBuilder = state.byId[builderId] || {}
    let builder = {
      state: builderStateReducer(prevBuilder.state, action),
      ...entitiesReducers(
        {
          blocks: prevBuilder.blocks,
          buttons: prevBuilder.buttons,
          nodes: prevBuilder.nodes,
        },
        action,
      ),
      snapshots: snapshotsReducer(prevBuilder.snapshots, action),
    }

    return {
      byId: { ...state.byId, [builderId]: builder },
    }
  }
  return state
}

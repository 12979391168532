import React from 'react'
import cx from 'classnames'

import { useThemeContext } from '../../useThemeContext'

import cm from './ChatButton.module.css'

interface ChatButtonProps {
  label: string
}

export function ChatButton({ label }: ChatButtonProps) {
  const theme = useThemeContext()

  return (
    <div className={cx(cm.button, cm[theme])} data-test-id="chat-button">
      {label}
    </div>
  )
}

ChatButton.displayName = 'Chat.Button'

import React, { useState, useMemo, useCallback } from 'react'
import { useAppSelector } from 'reduxTyped'
import { l } from '@manychat/manyui'

import { contentManagementSelectors } from 'apps/cms/store'
import { FSObjectType } from 'common/cms/models/FSObject/constants'
import { FSQueryRootPath } from 'common/cms/models/FSQuery/constants'
import { FsFolderObject } from 'shared/api/requests/cms/schemas'

import { Folder } from './Folder'
import { FoldersContext } from './FoldersContext'

const RootFolder = {
  type: FSObjectType.FOLDER,
  folder_id: 0,
  path: FSQueryRootPath,
  title: l.translate('My Automations'),
  entity: 10,
  created: -1,
  modified: -1,
} as FsFolderObject

export const FoldersStructure = ({
  onSelect,
  disabledFolder,
  disabledTooltip,
}: {
  onSelect: (value: string) => void
  disabledFolder: string
  disabledTooltip: string
}) => {
  const [activeFolder, setActiveFolder] = useState('')
  const folders = useAppSelector(contentManagementSelectors.getFolders)

  const handleSelectFolder = useCallback(
    (folderPath: string) => {
      setActiveFolder(folderPath)
      onSelect(folderPath)
    },
    [onSelect],
  )

  const contextValue = useMemo(
    () => ({
      activeFolder,
      setActiveFolderId: handleSelectFolder,
      disabledFolder,
      disabledTooltip,
      folders,
    }),
    [activeFolder, disabledFolder, disabledTooltip, folders, handleSelectFolder],
  )

  return (
    <FoldersContext.Provider value={contextValue}>
      <ul className="p-l-md">
        <Folder key={RootFolder.folder_id} folder={RootFolder} />
      </ul>
    </FoldersContext.Provider>
  )
}

import cloneDeep from 'lodash/cloneDeep'

import {
  WaMessageTemplateDraft,
  WaMessageTemplateHeaderType,
} from 'shared/api/requests/whatsApp/schemas'

export function sanitizeMessageTemplateDraft(
  template: WaMessageTemplateDraft,
): WaMessageTemplateDraft {
  return {
    ...cloneDeep(template),
    body: trimIfExists(template.body),
    footer: trimIfExists(template.footer),
    header: sanitizeHeader(template.header),
    example: sanitizeExample(template.example),
    buttons: sanitizeButtons(template.buttons),
  }
}

function sanitizeHeader(
  header: WaMessageTemplateDraft['header'],
): WaMessageTemplateDraft['header'] | undefined {
  if (
    !Array.isArray(header) &&
    header?.type === WaMessageTemplateHeaderType.TEXT &&
    isEmptyOrUndefined(header.text)
  ) {
    return {
      type: header.type,
      text: undefined,
    }
  }

  return header
}

function sanitizeExample(
  example: WaMessageTemplateDraft['example'],
): WaMessageTemplateDraft['example'] | undefined {
  if (example === undefined) {
    return undefined
  }

  return Object.fromEntries(
    Object.entries(example).filter(([, value]) => !isEmptyOrUndefined(value)),
  )
}

function sanitizeButtons(
  buttons: WaMessageTemplateDraft['buttons'],
): WaMessageTemplateDraft['buttons'] | undefined {
  return buttons?.map((button) => ({ ...button, text: trimIfExists(button.text) }))
}

const trimIfExists = (text: string | undefined) => (text && text.trim() !== '' ? text : undefined)
const isEmptyOrUndefined = (text: string | undefined) => text === undefined || text.trim() === ''

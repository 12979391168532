// builder state
export const CREATE_BUILDER_STATE = 'CREATE_BUILDER_STATE'
export const REMOVE_BUILDER_STATE = 'REMOVE_BUILDER_STATE'
export const UPDATE_BUILDER_STATE = 'UPDATE_BUILDER_STATE'
export const BUILDER_SELECT_NODES = 'BUILDER_SELECT_NODES'
export const BUILDER_SELECT_BUTTON = 'BUILDER_SELECT_BUTTON'
export const BUILDER_SELECT_BLOCK = 'BUILDER_SELECT_BLOCK'
export const BUILDER_RESET_SERVER_ERRORS = 'BUILDER_RESET_SERVER_ERRORS'
export const BUILDER_SELECT_URL = 'BUILDER_SELECT_URL'
export const BUILDER_PUBLISH_CLICKED = 'BUILDER_PUBLISH_CLICKED'
export const BUILDER_SET_CHANGED = 'BUILDER_SET_CHANGED'
export const BUILDER_RESET_CHANGED = 'BUILDER_RESET_CHANGED'
export const BUILDER_SET_READY = 'BUILDER_SET_READY'
export const BUILDER_SET_ROOT = 'BUILDER_SET_ROOT'
export const BUILDER_UPDATE_ROOT = 'BUILDER_UPDATE_ROOT'
export const BUILDER_UPDATE_COORDINATES = 'BUILDER_UPDATE_COORDINATES'
export const BUILDER_HIGHLIGHT_BLOCK = 'BUILDER_HIGHLIGHT_BLOCK'

export const BUILDER_KEYWORDS_CREATED_NOTIFICATION = 'BUILDER_KEYWORDS_CREATED_NOTIFICATION'
export const BUILDER_EXTERNALTRIGGERS_CREATED_NOTIFICATION =
  'BUILDER_EXTERNALTRIGGERS_CREATED_NOTIFICATION'
export const BUILDER_TRIGGERRULES_CREATED_NOTIFICATION = 'BUILDER_TRIGGERRULES_CREATED_NOTIFICATION'
export const BUILDER_SEQUENCES_CREATED_NOTIFICATION = 'BUILDER_SEQUENCES_CREATED_NOTIFICATION'
export const BUILDER_WIDGETS_CREATED_NOTIFICATION = 'BUILDER_WIDGETS_CREATED_NOTIFICATION'

export const BUILDER_KEYWORDS_UPDATED_NOTIFICATION = 'BUILDER_KEYWORDS_UPDATED_NOTIFICATION'
export const BUILDER_EXTERNALTRIGGERS_UPDATED_NOTIFICATION =
  'BUILDER_EXTERNALTRIGGERS_UPDATED_NOTIFICATION'
export const BUILDER_TRIGGERRULES_UPDATED_NOTIFICATION = 'BUILDER_TRIGGERRULES_UPDATED_NOTIFICATION'
export const BUILDER_SEQUENCES_UPDATED_NOTIFICATION = 'BUILDER_SEQUENCES_UPDATED_NOTIFICATION'
export const BUILDER_WIDGETS_UPDATED_NOTIFICATION = 'BUILDER_WIDGETS_UPDATED_NOTIFICATION'
export const BUILDER_DEFAULT_REPLY_UPDATED_NOTIFICATION =
  'BUILDER_DEFAULT_REPLY_UPDATED_NOTIFICATION'

export const BUILDER_KEYWORDS_CURRENT_UPDATED_NOTIFICATION =
  'BUILDER_KEYWORDS_CURRENT_UPDATED_NOTIFICATION'
export const BUILDER_EXTERNALTRIGGERS_CURRENT_UPDATED_NOTIFICATION =
  'BUILDER_EXTERNALTRIGGERS_CURRENT_UPDATED_NOTIFICATION'
export const BUILDER_TRIGGERRULES_CURRENT_UPDATED_NOTIFICATION =
  'BUILDER_TRIGGERRULES_CURRENT_UPDATED_NOTIFICATION'
export const BUILDER_SEQUENCES_CURRENT_UPDATED_NOTIFICATION =
  'BUILDER_SEQUENCES_CURRENT_UPDATED_NOTIFICATION'
export const BUILDER_WIDGETS_CURRENT_UPDATED_NOTIFICATION =
  'BUILDER_WIDGETS_CURRENT_UPDATED_NOTIFICATION'

export const BUILDER_KEYWORDS_CREATE_RESPONSE = 'BUILDER_KEYWORDS_CREATE_RESPONSE'
export const BUILDER_EXTERNALTRIGGERS_CREATE_RESPONSE = 'BUILDER_EXTERNALTRIGGERS_CREATE_RESPONSE'
export const BUILDER_TRIGGERRULES_CREATE_RESPONSE = 'BUILDER_TRIGGERRULES_CREATE_RESPONSE'
export const BUILDER_SEQUENCES_CREATE_RESPONSE = 'BUILDER_SEQUENCES_CREATE_RESPONSE'
export const BUILDER_WIDGETS_CREATE_RESPONSE = 'BUILDER_WIDGETS_CREATE_RESPONSE'

export const BUILDER_KEYWORDS_DELETED_NOTIFICATION = 'BUILDER_KEYWORDS_DELETED_NOTIFICATION'
export const BUILDER_EXTERNALTRIGGERS_DELETED_NOTIFICATION =
  'BUILDER_EXTERNALTRIGGERS_DELETED_NOTIFICATION'
export const BUILDER_TRIGGERRULES_DELETED_NOTIFICATION = 'BUILDER_TRIGGERRULES_DELETED_NOTIFICATION'
export const BUILDER_TRIGGERRULES_CURRENT_DELETED_NOTIFICATION =
  'BUILDER_TRIGGERRULES_CURRENT_DELETED_NOTIFICATION'
export const BUILDER_EXTERNALTRIGGERS_CURRENT_DELETED_NOTIFICATION =
  'BUILDER_EXTERNALTRIGGERS_CURRENT_DELETED_NOTIFICATION'
export const BUILDER_SEQUENCES_DELETED_NOTIFICATION = 'BUILDER_SEQUENCES_DELETED_NOTIFICATION'
export const BUILDER_WIDGETS_DELETED_NOTIFICATION = 'BUILDER_WIDGETS_DELETED_NOTIFICATION'

export const BUILDER_MOVE_TRIGGER = 'BUILDER_MOVE_TRIGGER'

// source
export const UPDATE_SOURCE_TARGET = 'UPDATE_SOURCE_TARGET'

// snapshot
export const ADD_BUILDER_SNAPSHOT = 'ADD_BUILDER_SNAPSHOT'
export const REMOVE_NEWEST_BUILDER_SNAPSHOTS = 'REMOVE_NEWEST_BUILDER_SNAPSHOTS'
export const REMOVE_LIMITED_BUILDER_SNAPSHOT = 'REMOVE_LIMITED_BUILDER_SNAPSHOT'
export const REMOVE_LAST_GENERATED_BUILDER_SNAPSHOT = 'REMOVE_LAST_GENERATED_BUILDER_SNAPSHOT'
export const REMOVE_ALL_BUILDER_SNAPSHOTS = 'REMOVE_ALL_BUILDER_SNAPSHOTS'
export const INSTALL_BUILDER_SNAPSHOT = 'INSTALL_BUILDER_SNAPSHOT'
export const INSTALL_BUILDER_SNAPSHOT_COORDINATES = 'INSTALL_BUILDER_SNAPSHOT_COORDINATES'
export const START_INSTALL_BUILDER_SNAPSHOT = 'START_INSTALL_BUILDER_SNAPSHOT'
export const STOP_INSTALL_BUILDER_SNAPSHOT = 'STOP_INSTALL_BUILDER_SNAPSHOT'

// node
export const CREATE_NODE = 'CREATE_NODE'
export const UPDATE_NODE = 'UPDATE_NODE'
export const REMOVE_NODE = 'REMOVE_NODE'
export const RESTORE_NODE = 'RESTORE_NODE'
export const LINK_NODE_AND_BLOCK = 'LINK_NODE_AND_BLOCK'
export const UNLINK_NODE_AND_BLOCK = 'UNLINK_NODE_AND_BLOCK'

// block
export const CREATE_BLOCK = 'CREATE_BLOCK'
export const UPDATE_BLOCK = 'UPDATE_BLOCK'
export const REMOVE_BLOCK = 'REMOVE_BLOCK'
export const UNLINK_BLOCK_AND_BLOCK = 'UNLINK_BLOCK_AND_BLOCK'
export const LINK_BLOCK_AND_BLOCK = 'LINK_BLOCK_AND_BLOCK'
export const UNLINK_BLOCK_AND_BUTTON = 'UNLINK_BLOCK_AND_BUTTON'
export const LINK_BLOCK_AND_BUTTON = 'LINK_BLOCK_AND_BUTTON'

// button
export const CREATE_BUTTON = 'CREATE_BUTTON'
export const UPDATE_BUTTON = 'UPDATE_BUTTON'
export const REMOVE_BUTTON = 'REMOVE_BUTTON'
export const HOVER_OVER_BUTTON = 'HOVER_OVER_BUTTON'
export const CLEAR_BUTTON_HOVER = 'CLEAR_BUTTON_HOVER'

// others
export const CONTENT_PARSED = 'CONTENT_PARSED'
export const CONTENT_VALIDATED = 'CONTENT_VALIDATED'

export const CLEAR_BUILDER_AND_RESET = 'CLEAR_BUILDER_AND_RESET'

export const CREATE_MANAGER_STATE = 'CREATE_MANAGER_STATE'
export const REMOVE_MANAGER_STATE = 'REMOVE_MANAGER_STATE'
export const UPDATE_MANAGER_STATE = 'UPDATE_MANAGER_STATE'
export const RESET_MANAGER_LAST_SAVED = 'RESET_MANAGER_LAST_SAVED'
export const UPDATE_MANAGER_LAST_CONTENT_HASH_MAP = 'UPDATE_MANAGER_LAST_CONTENT_HASH_MAP'
export const UPDATE_MANAGER_LAST_ROOT_ID = 'UPDATE_MANAGER_LAST_ROOT_ID'
export const UPDATE_MANAGER_LAST_COORDINATES = 'UPDATE_MANAGER_LAST_COORDINATES'
export const MANAGER_AUTO_SAVE_REQUEST = 'MANAGER_AUTO_SAVE_REQUEST'
export const MANAGER_AUTO_SAVE_SUCCESS = 'MANAGER_AUTO_SAVE_SUCCESS'
export const MANAGER_AUTO_SAVE_ERROR = 'MANAGER_AUTO_SAVE_ERROR'

export const GET_QUICK_CAMPAIGN_DATA = 'GET_QUICK_CAMPAIGN_DATA'
export const SET_QUICK_CAMPAIGN_DATA = 'SET_QUICK_CAMPAIGN_DATA'
export const SET_QUICK_CAMPAIGN_ID = 'SET_QUICK_CAMPAIGN_ID'
export const SET_QUICK_CAMPAIGN_ID_RESPONSE = 'SET_QUICK_CAMPAIGN_ID_RESPONSE'

export const GET_FLOW_FOR_SIMULATOR_REQUEST = 'GET_FLOW_FOR_SIMULATOR_REQUEST'
export const GET_FLOW_FOR_SIMULATOR_RESPONSE = 'GET_FLOW_FOR_SIMULATOR_RESPONSE'
export const GET_FLOW_FOR_SIMULATOR_ERROR = 'GET_FLOW_FOR_SIMULATOR_ERROR'
export const RESET_FLOW_FOR_SIMULATOR = 'RESET_FLOW_FOR_SIMULATOR'

export const AI_ADDON_PUBLISH_ERROR_APPEARED = 'AI_ADDON_PUBLISH_ERROR_APPEARED'

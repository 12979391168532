import React from 'react'
import { palettes, l, Icon } from '@manychat/manyui'

import { KnowledgeBaseLink } from 'common/core/components/SupportLinks'

export const ImportantInformationBanner = () => (
  <div className="panel panel-info d-flex align-start p-a-md">
    <Icon.Info className="flex-shrink-0 m-r" size={24} color={palettes.semantic.primary} />

    <div>
      <b>{l.translate('Please note:')}</b>

      <p className="m-t-xs">
        {l.translate(
          '1. Convert Automation feature will convert only the <strong>published</strong> version of the Automation.',
        )}
      </p>

      <p className="m-t-xxs">
        {l.translate(
          '2. Resulting Automation could have changes due to channel differences. Please read <a class="text-primary" href="{link}" target="_blank">the support article</a> to learn how the conversion is done.',
          { link: KnowledgeBaseLink.FLOW_CONVERTER },
        )}
      </p>
    </div>
  </div>
)

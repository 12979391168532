import { l } from '@manychat/manyui'

import {
  CHATGPT_TOKENS_MIN,
  CHATGPT_TOKENS_MAX,
  CHATGPT_TEMPERATURE_MAX,
  CHATGPT_TEMPERATURE_MIN,
} from 'common/actions/integrations/ChatGPT/model/requestToChatGPTConstants'

const errorMessages = {
  ACTION_TAG_REQUIRED: l.makeTranslate('Please select or create a tag'),
  ACTION_SEQUENCE_REQUIRED: l.makeTranslate('Please select a sequence'),
  ACTION_SET_CUF_FIELD_REQUIRED: l.makeTranslate('Please select a custom user field to set'),
  ACTION_SET_CUF_VALUE_REQUIRED: l.makeTranslate(
    'Please select a value to set in custom user field',
  ),
  ACTION_UNSET_CUF_FIELD_REQUIRED: l.makeTranslate('Please select a custom user field to unset'),
  ACTION_EXTERNAL_REQUEST_URL_REQUIRED: l.makeTranslate('Please enter a URL'),
  ACTION_HUBSPOT_NOT_SET: l.makeTranslate('Please select a HubSpot action'),
  ACTION_HUBSPOT_FORM_REQUIRED: l.makeTranslate('Please select a form for HubSpot action'),
  ACTION_HUBSPOT_IDENTITY_FIELD_NOT_SET: l.makeTranslate(
    'Please set an identity field for HubSpot action',
  ),
  ACTION_SALESFORCE_NOT_SET: l.makeTranslate('Please select a Salesforce action'),
  ACTION_SALESFORCE_IDENTITY_FIELD_NOT_SET: l.makeTranslate(
    'Please set an identity field for Salesforce action',
  ),
  ACTION_CONVERTKIT_NOT_SET: l.makeTranslate('Please select a ConvertKit action'),
  ACTION_CONVERTKIT_FORM_REQUIRED: l.makeTranslate('Please select a form for ConvertKit action'),
  ACTION_CONVERTKIT_SEQUENCE_REQUIRED: l.makeTranslate(
    'Please select a sequence for ConvertKit action',
  ),
  ACTION_CONVERTKIT_TAG_REQUIRED: l.makeTranslate('Please select a tag for ConvertKit action'),
  ACTION_CONVERTKIT_IDENTITY_FIELD_NOT_SET: l.makeTranslate(
    'Please set an identity field for ConvertKit action',
  ),
  ACTION_CHATGPT_NOT_SET: l.makeTranslate('Please select a ChatGPT action'),
  ACTION_CHATGPT_REQUEST_PROMPT_REQUIRED: l.makeTranslate('ChatGPT request: Please enter a prompt'),
  ACTION_CHATGPT_REQUEST_FIELD_REQUIRED: l.makeTranslate(
    'ChatGPT request: Please select a field to save result to',
  ),
  ACTION_CHATGPT_REQUEST_ENGINE_REQUIRED: l.makeTranslate(
    'ChatGPT request: Please select an engine',
  ),
  ACTION_CHATGPT_REQUEST_MAX_TOKENS_REQUIRED: l.makeTranslate(
    'ChatGPT request: Maximum tokens property should be a number between {tokensMin} and {tokensMax}',
    { tokensMin: CHATGPT_TOKENS_MIN, tokensMax: CHATGPT_TOKENS_MAX },
  ),
  ACTION_CHATGPT_REQUEST_TEMPERATURE_REQUIRED: l.makeTranslate(
    'ChatGPT request: Temperature property should be a number between {tempMin} and {tempMax}',
    { tempMin: CHATGPT_TEMPERATURE_MIN, tempMax: CHATGPT_TEMPERATURE_MAX },
  ),
  ACTION_CLAUDE_EMPTY: l.makeTranslate('Please fill out Claude action'),
  ACTION_DEEP_SEEK_EMPTY: l.makeTranslate('Please fill out DeepSeek action'),
  ACTION_ACTIVE_CAMPAIGN_NOT_SET: l.makeTranslate('Please select a ActiveCampaign action'),
  ACTION_ACTIVE_CAMPAIGN_IDENTITY_FIELD_NOT_SET:
    'Please set an identity field for ActiveCampaign action',
  ACTION_KLAVIO_NOT_SET: l.makeTranslate('Please select a Klaviyo action'),
  ACTION_KLAVIO_IDENTITY_FIELD_NOT_SET: l.makeTranslate(
    'Please set an identity field for Klaviyo action',
  ),
  ACTION_GOOGLE_SHEET_NOT_SET: l.makeTranslate('Please select a Google Sheets action'),
  ACTION_GOOGLE_SHEET_SPREADSHEET_NOT_SET: l.makeTranslate('Please select a spreadsheet'),
  ACTION_GOOGLE_SHEET_SHEET_NOT_SET: l.makeTranslate('Please select a worksheet'),
  ACTION_GOOGLE_SHEET_BINDING_NOT_SET: l.makeTranslate('Please set up Manychat fields'),
  ACTION_MAILCHIMP_NOT_SET: l.makeTranslate('Please select a MailChimp action'),
  ACTION_MAILCHIMP_LIST_REQUIRED: l.makeTranslate('Please select a list for MailChimp action'),
  ACTION_MAILCHIMP_IDENTITY_FIELD_NOT_SET: l.makeTranslate(
    'Please set an email field for MailChimp action',
  ),
  ACTION_START_FLOW_FLOW_REQUIRED: l.makeTranslate('Please select Automation'),
  ACTION_ASSIGN_CONVERSATION_USER_REQUIRED: l.makeTranslate('Please choose a team member'),
  ACTION_ASSIGN_CONVERSATION_USER_OR_GROUP_REQUIRED: l.makeTranslate(
    'Please choose a team member or a group',
  ),
  ACTION_CUSTOM_AUDIENCE_USER_NOT_SET: l.makeTranslate(
    'Please provide required action parameters: Ad Account, Custom Audience',
  ),
  ACTION_FIRE_EVENT_TITLE_REQUIRED: l.makeTranslate('Please enter a Conversion Event name'),
  ACTION_FIRE_EVENT_COST_REQUIRED: l.makeTranslate('Please enter a Conversion Event value'),
  ACTION_CUSTOMER_CHAT_WIDGET_REQUIRED: l.makeTranslate(
    'Please add Customer Chat Growth Tool to use this action',
  ),
  ACTION_SET_MAIN_MENU_NS_REQUIRED: l.makeTranslate('Please select a Main Menu'),
}

export default errorMessages

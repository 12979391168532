import SupportPngURL from 'apps/quickCampaigns/assets/Support.png'
import { IStrategyArticle, StrategyIds } from 'apps/quickCampaigns/quickCampaignsInterfaces'

export const LegacyStrategyExtraData: Record<
  number,
  { strategyId: StrategyIds; articles?: IStrategyArticle[] }
> = {
  1: {
    strategyId: StrategyIds.WELCOME_CUSTOMERS,
    articles: [
      {
        title: 'Help Article',
        description: 'Button Types and Actions',
        preview: SupportPngURL,
        link: 'https://support.manychat.com/support/solutions/articles/36000001128-button-types-and-actions',
      },
      {
        title: 'Blog Article',
        description: '5 Easy Steps For a Powerful Lead Generation Process',
        preview:
          'https://manychat.com/blog/wp-content/uploads/2019/11/5-steps-to-create-a-lead-generation-process.jpg',
        link: 'https://manychat.com/blog/lead-generation-process/',
      },
    ],
  },
  2: {
    strategyId: StrategyIds.COLLECT_PHONES_AND_EMAILS,
    articles: [
      {
        title: 'Help Article',
        description: 'SMS and Email Compliance Laws',
        preview: SupportPngURL,
        link: 'https://support.manychat.com/support/solutions/articles/36000220219-sms-and-email-compliance-laws',
      },
      {
        title: 'Blog Article',
        description: 'How to Lower Customer Churn with SMS',
        preview:
          'https://manychat.com/blog/wp-content/uploads/2020/03/can-you-reduce-customer-churn-with-sms.jpg',
        link: 'https://manychat.com/blog/how-to-reduce-customer-churn-with-sms/',
      },
    ],
  },
  3: {
    strategyId: StrategyIds.SEND_PERSONALIZED_OFFERS,
    articles: [
      {
        title: 'Help Article',
        description: 'Button Types and Actions',
        preview: SupportPngURL,
        link: 'https://support.manychat.com/support/solutions/articles/36000097456-condition-block',
      },
      {
        title: 'Blog Article',
        description: 'Audience Segmentation: What it is, How to Use It, and Examples',
        preview:
          'https://manychat.com/blog/wp-content/uploads/2019/08/audience-segmentation-what-it-is-how-to-use-it-and-examples.jpg',
        link: 'https://manychat.com/blog/audience-segmentation/',
      },
    ],
  },
  4: {
    strategyId: StrategyIds.EXCLUSIVE_OFFER_FOR_FACEBOOK,
    articles: [
      {
        title: 'Help Article',
        description: 'Facebook Comments Trigger 2.0',
        preview: SupportPngURL,
        link: 'https://support.manychat.com/support/solutions/articles/36000232601-facebook-comments-trigger-2-0',
      },
      {
        title: 'Blog Article',
        description: 'How to Master The Facebook Comments Growth Tool 2.0',
        preview: 'https://manychat.com/blog/wp-content/uploads/2019/11/Facebook-Comments-2.0.png',
        link: 'https://manychat.com/blog/comment-growth-tool/',
      },
    ],
  },
  5: {
    strategyId: StrategyIds.FACEBOOK_AD_LEADS,
  },
  6: {
    strategyId: StrategyIds.PRODUCT_OFFERINGS,
    articles: [
      {
        title: 'Help Article',
        description: 'Smart Delay',
        preview: SupportPngURL,
        link: 'https://support.manychat.com/support/solutions/articles/36000068629-smart-delay',
      },
      {
        title: 'Blog Article',
        description: 'Drip Marketing: The Secret Sauce to Every Nurture Campaign',
        preview: 'https://manychat.com/blog/wp-content/uploads/2020/07/drip-campain.png',
        link: 'https://manychat.com/blog/drip-marketing/',
      },
    ],
  },
  7: {
    strategyId: StrategyIds.SEND_EVENT_REMINDERS,
    articles: [
      {
        title: 'Help Article',
        description: 'Rules (Triggers & Actions System)',
        preview: SupportPngURL,
        link: 'https://support.manychat.com/support/solutions/articles/36000067359-rules-triggers-actions-system-',
      },
      {
        title: 'Blog Article',
        description: '9 Ways to Deploy Manychat’s New Chat Triggers & Action Rules',
        preview: 'https://manychat.com/blog/wp-content/uploads/2018/07/9_ways_17.07.18.png',
        link: 'https://manychat.com/blog/use-triggers-and-actions-in-your-chatbots/',
      },
    ],
  },
  8: {
    strategyId: StrategyIds.AUTOMATE_SUPPORT,
    articles: [
      {
        title: 'Help Article',
        description: 'Customer Chat Growth Tool',
        preview: SupportPngURL,
        link: 'https://support.manychat.com/support/solutions/articles/36000088362-customer-chat-growth-tool',
      },
      {
        title: 'Blog Article',
        description: 'Facebook Chat Plugin Updates for Manychat Customer Chat',
        preview: 'https://manychat.com/blog/wp-content/uploads/2020/08/customer-chat.png',
        link: 'https://manychat.com/blog/facebook-chat-plugin-updates-for-manychat-customer-chat/',
      },
    ],
  },
  9: {
    strategyId: StrategyIds.CUSTOMER_INFO_FROM_GOOGLE_SHEETS,
    articles: [
      {
        title: 'Help Article',
        description: 'Google Sheets Integration',
        preview: SupportPngURL,
        link: 'https://support.manychat.com/support/solutions/articles/36000086990-google-sheets-integration',
      },
      {
        title: 'Blog Article',
        description: 'Manychat Google Sheets Integration',
        preview:
          'https://images.ctfassets.net/t396q97uzhmz/2M5EzNFBqNNsIXJwBi1tBn/399dec7e4f2062a3925199120a13b351/08.svg',
        link: 'https://manychat.com/video-course/google-sheets-integration',
      },
    ],
  },
  10: {
    strategyId: StrategyIds.FACEBOOK_AD_LEADS_SIMPLE,
    articles: [
      {
        title: 'Help Article',
        description: 'Integrating Facebook Ads with JSON',
        preview: SupportPngURL,
        link: 'https://support.manychat.com/support/solutions/articles/36000003004-facebook-ads-json',
      },
      {
        title: 'Blog Article',
        description: 'Best Click-to-Messenger Ad Examples',
        preview:
          'https://manychat.com/blog/wp-content/uploads/2019/12/click-to-messenger-ad-examples.jpg',
        link: 'https://manychat.com/blog/click-to-messenger-ad-examples/',
      },
    ],
  },
}

import { LayoutWithSidebar } from 'utils/router/components/LayoutWithSidebar/LayoutWithSidebar'

export const SponsoredMessagesRoutes = [
  {
    path: 'sm',
    Component: LayoutWithSidebar,
    children: [
      {
        index: true,
        lazy: () => import('./sponsoredMessages.page'),
      },
      {
        path: 'settings',
        lazy: () => import('apps/ads/pages/AdSettings.page'),
      },
      {
        path: 'account/:ad_account_id/campaign/:ad_campaign_id/adset/:ad_set_id/ad/:ad_id',
        lazy: () => import('./sponsoredMessagesWizard.page'),
      },
    ],
  },
] as const

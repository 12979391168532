import {
  AGENCY_FIELDS as FIELDS,
  EMAIL_VALUES,
  AGENCY_LEVELS,
  AGENCY_STEPS,
  AGENCY_BENEFITS,
  AGENCY_CONFIG_FIELDS,
  COMMISSIONS_PAGE_FIELDS,
  COMMISSIONS_STATS_FIELDS,
  CONTACT_FORM_FIELD,
  getMatchedFormServices,
} from 'apps/agency/models/Agency/constants'
import { isProOrTrialStatus } from 'common/core/models/Account/helpers'

export function parseAgency(data = {}, user, fallback) {
  const profileEmail = user.email || user.email_unverified

  const emailType =
    !profileEmail || data.email_unverified || data[FIELDS.email]
      ? EMAIL_VALUES.agency
      : EMAIL_VALUES.profile

  const agencyFields = {
    [FIELDS.id]: data[FIELDS.id],
    [FIELDS.name]: data[FIELDS.name],
    [FIELDS.emailType]: emailType,
    [FIELDS.email]: data.email_unverified || data[FIELDS.email],
    [FIELDS.description]: data[FIELDS.description],
    [FIELDS.websiteUrl]: data[FIELDS.websiteUrl],
    [FIELDS.logoUrl]: data[FIELDS.logoUrl],
    [FIELDS.logoFileId]: data[FIELDS.logoFileId],
    [FIELDS.placeId]: data[FIELDS.placeId],
    [FIELDS.country]: data[FIELDS.country],
    [FIELDS.city]: data[FIELDS.city],
    [FIELDS.industries]: data[FIELDS.industries],
  }

  return Object.keys(agencyFields).reduce((fields, field) => {
    const value = agencyFields[field]

    fields[field] = value ? value : ''

    if (fallback) {
      switch (field) {
        case FIELDS.name:
        case FIELDS.industries:
        case FIELDS.country:
        case FIELDS.city:
          if (!value) {
            fields[field] = 'Undefined'
          }

          break
        default:
          break
      }
    }

    return fields
  }, {})
}

export function parseAgencyConfig(config) {
  return { [AGENCY_CONFIG_FIELDS.quizPassPercent]: config[AGENCY_CONFIG_FIELDS.quizPassPercent] }
}

export function exportAgency(agency = {}) {
  return {
    [FIELDS.id]: agency[FIELDS.id],
    [FIELDS.name]: agency[FIELDS.name],
    [FIELDS.emailType]: agency[FIELDS.emailType],
    [FIELDS.email]: agency[FIELDS.email],
    [FIELDS.description]: agency[FIELDS.description],
    [FIELDS.websiteUrl]: agency[FIELDS.websiteUrl],
    [FIELDS.logoFileId]: agency[FIELDS.logoFileId],
    [FIELDS.placeId]: agency[FIELDS.placeId],
    [FIELDS.country]: agency[FIELDS.normalized_country] || agency[FIELDS.country],
    [FIELDS.city]: agency[FIELDS.normalized_city] || agency[FIELDS.city],
    [FIELDS.industries]: agency[FIELDS.industries],
    details: {
      industry: agency[FIELDS.industries], /// temp for backend
    },
  }
}

export function parseAgencyStatus(data) {
  switch (data.current_status) {
    case 1:
      return AGENCY_LEVELS.applicant
    case 2:
      return AGENCY_LEVELS.partner
    case 3:
      return AGENCY_LEVELS.expert
  }
}

export function parseAgencyAchievments(data) {
  return {
    [AGENCY_STEPS.questionnaire]: data.questionnaire_passed,
    [AGENCY_STEPS.entryQuiz]: data.entry_quiz_params,
    [AGENCY_STEPS.submitBot]: data.partner_bot_submitted,
    [AGENCY_STEPS.verifyEmail]: data.email_verified,
    [AGENCY_STEPS.expertQuiz]: data.expert_quiz_params,
    [AGENCY_STEPS.submitProBots]: data.expert_bot_submitted,
    [AGENCY_STEPS.addLogo]: data.logo_added,
    [AGENCY_STEPS.verifySkills]: data.skills_verified,
    [AGENCY_STEPS.pagesWithMac]: {
      passed:
        data.page_count_with_need_mac_total >=
        data.page_count_with_need_mac_need_for_status_advanced,
      current: data.page_count_with_need_mac_total,
      required: data.page_count_with_need_mac_need_for_status_advanced,
    },
  }
}

export function parseBenefitsLinks(data) {
  return {
    [AGENCY_BENEFITS.revenueShare]: [
      {
        name: 'commissionPortal',
        label: 'Commission portal',
        link: data.links.commission_portal,
        kind: 'button_link',
      },
      {
        name: 'guideLines',
        label: 'Guidelines for Revenue Sharing',
        link: data.links.guidelines_for_revenue_sharing,
        kind: 'string_link',
      },
    ],
    [AGENCY_BENEFITS.privateCommunity]: [
      {
        name: 'agencyCommunity',
        label: 'Agency Community',
        link: data.links.agency_community,
        kind: 'button_link',
      },
    ],
    [AGENCY_BENEFITS.trainingMaterials]: [
      {
        name: 'trainingMaterials',
        label: 'Agency Enablement Hub',
        link: 'https://manychat.northpass.com/c/23b82237610e9c0637b7847fa560bbf3f0a545a1',
        kind: 'button_link',
      },
    ],
  }
}

export function parseCommissionPage(data) {
  return data.map((page) => {
    return {
      [COMMISSIONS_PAGE_FIELDS.pageId]: page[COMMISSIONS_PAGE_FIELDS.pageId],
      [COMMISSIONS_PAGE_FIELDS.name]: page[COMMISSIONS_PAGE_FIELDS.name],
      [COMMISSIONS_PAGE_FIELDS.avatar_small]:
        page[COMMISSIONS_PAGE_FIELDS.avatar_small] || page[COMMISSIONS_PAGE_FIELDS.avatar_big],
      [COMMISSIONS_PAGE_FIELDS.create_ts]: page[COMMISSIONS_PAGE_FIELDS.create_ts],
      [COMMISSIONS_PAGE_FIELDS.subscribe_ts]: page[COMMISSIONS_PAGE_FIELDS.subscribe_ts],
      [COMMISSIONS_PAGE_FIELDS.isPro]: isProOrTrialStatus(page[COMMISSIONS_PAGE_FIELDS.isPro]),
    }
  })
}

export function parseCommissionStats(data) {
  return data.map((page) => {
    return {
      [COMMISSIONS_STATS_FIELDS.pageId]: page[COMMISSIONS_STATS_FIELDS.pageId],
      [COMMISSIONS_STATS_FIELDS.lastCommissionTS]: page[COMMISSIONS_STATS_FIELDS.lastCommissionTS],
      [COMMISSIONS_STATS_FIELDS.lastCommissionValue]:
        page[COMMISSIONS_STATS_FIELDS.lastCommissionValue],
      [COMMISSIONS_STATS_FIELDS.totalCommissionValue]:
        page[COMMISSIONS_STATS_FIELDS.totalCommissionValue],
    }
  })
}

export function exportDataForContactForm(formData) {
  const exportFields = {
    [CONTACT_FORM_FIELD.name]: 'name',
    [CONTACT_FORM_FIELD.email]: 'email',
    [CONTACT_FORM_FIELD.taskDescription]: 'description',
    [CONTACT_FORM_FIELD.normalizedCountry]: 'country',
    [CONTACT_FORM_FIELD.industry]: 'industry',
    [CONTACT_FORM_FIELD.link]: 'url',
  }
  const exportData = {}

  Object.entries(formData).forEach(([key, value]) => {
    if (value) {
      exportData[exportFields[key]] = value
    }
  })

  return exportData
}

export function exportDataForSendBriefForm(formData) {
  const exportFields = {
    [CONTACT_FORM_FIELD.name]: 'name',
    [CONTACT_FORM_FIELD.email]: 'email',
    [CONTACT_FORM_FIELD.taskDescription]: 'description',
    [CONTACT_FORM_FIELD.country]: 'country',
    [CONTACT_FORM_FIELD.industry]: 'industry',
    [CONTACT_FORM_FIELD.link]: 'url',
  }
  const exportData = {}

  Object.entries(formData).forEach(([key, value]) => {
    if (value) {
      exportData[exportFields[key]] = value
    }
  })

  const services = Object.values(getMatchedFormServices).filter((value) => formData[value])
  if (services.length) {
    exportData.description += `\nServices: ${services.join(', ')} `
  }

  return exportData
}

export function exportDataForGetMatchedForm(formData) {
  const exportFields = {
    [CONTACT_FORM_FIELD.country]: 'location',
    [CONTACT_FORM_FIELD.industry]: 'industry',
  }
  const exportData = {}

  Object.entries(formData).forEach(([key, value]) => {
    if (value && exportFields[key]) {
      exportData[exportFields[key]] = value
    }
  })

  return exportData
}

import React from 'react'
import { l } from '@manychat/manyui'

import DefaultStepView from 'utils/services/newOnboardingService/view/DefaultStepView'
import ManyChatOnboardingView from 'utils/services/newOnboardingService/view/ManyChatOnboardingView'
import { PointerType } from 'utils/services/newOnboardingService/view/Pointer/pointerInterfaces'
import TooltipDefault from 'utils/services/newOnboardingService/view/Pointer/Tooltip/TooltipDefault'

import CommentsGrowthTool from './CommentsGrowthTool'

const TOTAL_STEPS = 8

const areaShifts = {
  trigger: { width: 45 },
  node: { width: -15, height: -15 },
}

const CommentsGrowthToolView = ({ onb }: { onb: CommentsGrowthTool }) => {
  return (
    <ManyChatOnboardingView
      onboarding={onb}
      stepsView={{
        STEP_1: ({ stepId }) => {
          return (
            <DefaultStepView
              onb={onb}
              stepId={stepId}
              pointerConfig={{
                type: PointerType.FLOW_CHART,
                elementId: 'starting_step',
                moveTo: true,
              }}
              progressConfig={{
                current: 1,
                total: TOTAL_STEPS,
              }}
            />
          )
        },
        STEP_2: ({ stepId }) => (
          <DefaultStepView
            onb={onb}
            stepId={stepId}
            pointerConfig={{
              type: PointerType.DOM,
              elementId: 'trigger-item-widget',
              padding: 16,
              tooltip: {
                component: (
                  <TooltipDefault
                    text={l.makeTranslate('Click on the Trigger to discover the settings.')}
                  />
                ),
                at: 'right',
              },
              areaShift: areaShifts.trigger,
            }}
            progressConfig={{
              current: 1,
              total: TOTAL_STEPS,
            }}
          />
        ),
        STEP_3: ({ stepId }) => (
          <DefaultStepView
            onb={onb}
            stepId={stepId}
            pointerConfig={{
              type: PointerType.DOM,
              elementId: 'publications-list',
              padding: 8,
              tooltip: {
                component: (
                  <TooltipDefault text={l.makeTranslate('Pick any post or reel to automate')} />
                ),
                at: 'top',
              },
            }}
            progressConfig={{
              current: 2,
              total: TOTAL_STEPS,
            }}
          />
        ),
        STEP_4: ({ stepId }) => (
          <DefaultStepView
            onb={onb}
            stepId={stepId}
            pointerConfig={{
              type: PointerType.DOM,
              elementId: 'widget-continue-btn',
              padding: 8,
              tooltip: {
                component: (
                  <TooltipDefault
                    text={l.makeTranslate(
                      'Automation will fire for comments below any Posts or Reels in your Instagram. <br /><br />Click ‘Continue’ to see next step.',
                    )}
                  />
                ),
                at: 'top',
              },
            }}
            progressConfig={{
              current: 3,
              total: TOTAL_STEPS,
            }}
          />
        ),
        STEP_5: ({ stepId }) => (
          <DefaultStepView
            onb={onb}
            stepId={stepId}
            pointerConfig={{
              type: PointerType.DOM,
              elementId: 'widget-continue-btn',
              padding: 8,
              tooltip: {
                component: (
                  <TooltipDefault
                    text={l.makeTranslate(
                      'Message will be sent only if comment has specific Keyword. <br /><br />Click ‘Continue’ to set the last step.',
                    )}
                  />
                ),
                at: 'top',
              },
            }}
            progressConfig={{
              current: 4,
              total: TOTAL_STEPS,
            }}
          />
        ),
        STEP_6: ({ stepId }) => (
          <DefaultStepView
            onb={onb}
            stepId={stepId}
            pointerConfig={{
              type: PointerType.DOM,
              elementId: 'widget-save-btn',
              padding: 8,
              tooltip: {
                component: (
                  <TooltipDefault
                    text={l.makeTranslate(
                      'You can set up Public Replies to comments later. Click this button to save the settings.',
                    )}
                  />
                ),
                at: 'top',
              },
            }}
            progressConfig={{
              current: 5,
              total: TOTAL_STEPS,
            }}
          />
        ),
        STEP_7: ({ stepId, step }) => {
          return (
            <DefaultStepView
              onb={onb}
              stepId={stepId}
              pointerConfig={{
                type: PointerType.FLOW_CHART,
                elementId: 'content_node',
                moveTo: true,
                blockAreaInput: true,
                tooltip: {
                  component: (
                    <TooltipDefault
                      text={l.makeTranslate(
                        'This is a Reply message that will be sent to your follower in DM, once they leave a comment. Click "Next" to open the Edit mode.',
                      )}
                      btnNext={l.makeTranslate('Next')}
                      onNextClick={() => step.process()}
                    />
                  ),
                  at: 'top',
                },
                areaShift: areaShifts.node,
              }}
              progressConfig={{
                current: 6,
                total: TOTAL_STEPS,
              }}
            />
          )
        },
        STEP_8: ({ stepId, step }) => (
          <DefaultStepView
            onb={onb}
            stepId={stepId}
            pointerConfig={{
              type: PointerType.DOM,
              elementId: 'sortable-button',
              blockAreaInput: true,
              tooltip: {
                component: (
                  <TooltipDefault
                    onNextClick={() => step.process()}
                    btnNext={l.makeTranslate('Next')}
                    text={l.makeTranslate(
                      'You can click on the button to add a link to Your Website.',
                    )}
                  />
                ),
                at: 'right',
              },
            }}
            progressConfig={{
              current: 7,
              total: TOTAL_STEPS,
            }}
          />
        ),
        STEP_9: ({ stepId, step }) => (
          <DefaultStepView
            onb={onb}
            stepId={stepId}
            pointerConfig={{
              type: PointerType.DOM,
              elementId: 'flow-editor-publish',
              blockAreaInput: true,
              tooltip: {
                component: (
                  <TooltipDefault
                    onNextClick={() => step.process()}
                    btnNext={l.makeTranslate('Got It')}
                    text={l.makeTranslate('Publish Automation to make an Automation go live.')}
                  />
                ),
                at: 'bottom',
              },
            }}
            progressConfig={{
              current: 8,
              total: TOTAL_STEPS,
            }}
          />
        ),
        STEP_10: ({ stepId }) => (
          <DefaultStepView
            onb={onb}
            stepId={stepId}
            pointerConfig={{
              type: PointerType.FLOW_CHART,
              elementId: 'starting_step',
              moveTo: true,
            }}
            progressConfig={{
              current: 8,
              total: TOTAL_STEPS,
            }}
          />
        ),
      }}
    />
  )
}
export default CommentsGrowthToolView

import React, { useState, useCallback } from 'react'
import { useAppDispatch } from 'reduxTyped'
import { BtnV2, TextInputV2, Modal, l } from '@manychat/manyui'

import { useModalData } from 'apps/cms/components/Flows/components/AutomationModals/hooks/useModalData'
import { CmsAnalytics } from 'apps/cms/lib/analytics'
import { contentManagementActions } from 'apps/cms/store'
import { AutomationModalType } from 'apps/cms/store/lib/types'

export const RenameFlowModal = () => {
  const dispatch = useAppDispatch()

  const { flowName, flowPath } = useModalData({
    type: AutomationModalType.RENAME_FLOW,
  })

  const [name, setName] = useState(flowName || '')
  const [isLoading, setIsLoading] = useState(false)

  const handleChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    setName(event.target.value)
  }, [])

  const handleClose = useCallback(() => {
    dispatch(contentManagementActions.closeModal(AutomationModalType.RENAME_FLOW))
  }, [dispatch])

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()

    if (!name) {
      return
    }

    CmsAnalytics.logRenameFlow()

    setIsLoading(true)
    await dispatch(contentManagementActions.renameFlow({ path: flowPath, title: name }))
    setIsLoading(false)

    handleClose()
  }

  return (
    <Modal
      width={460}
      open
      title={<div className="p-y-xs">{l.translate('Rename Automation')}</div>}
      onRequestClose={handleClose}
      buttons={[
        <BtnV2 onClick={handleClose} className="m-y-xs">
          {l.translate('Cancel')}
        </BtnV2>,
        <BtnV2
          disabled={!name.length}
          loading={isLoading}
          className="m-y-xs"
          variant="primary"
          type="submit"
          form="renameFlowForm"
        >
          {l.translate('Rename')}
        </BtnV2>,
      ]}
    >
      <div className="p-x p-y-md">
        <form id="renameFlowForm" onSubmit={handleSubmit}>
          <TextInputV2
            label={l.translate('New name')}
            onChange={handleChange}
            value={name}
            autoFocus
            data-test-id="name-input"
          />
        </form>
      </div>
    </Modal>
  )
}

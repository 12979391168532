import includes from 'lodash/includes'
import { l } from '@manychat/manyui'

import { ButtonType } from 'common/builder/constants/ButtonType'
import EntityType from 'common/builder/constants/EntityType'
import { NodeType } from 'common/builder/constants/NodeType'
import { NextStepType } from 'common/builder/models/Source/constants'
import * as entitySelectors from 'common/builder/selectors/builder/entitySelectors'
import {
  hasConnectedInstagramChannel,
  isFBChannelConnected,
} from 'common/core/selectors/channelSelectors'
import { accountFlags } from 'utils/services/featureFlagsService'
import { AccountFlag } from 'utils/services/featureFlagsService/constants'

export const getTargetId = (state: RootState, builderId: string, sourceId: string) => {
  const source = entitySelectors.getById(state, builderId, sourceId)

  if (!source || !('targetId' in source) || !source.targetId) {
    return null
  }

  const target = entitySelectors.getNodeById(state, builderId, source.targetId)

  if (!target) {
    return source.targetId
  }

  return target.id
}

export const getTarget = (state: RootState, builderId: string, sourceId: string) => {
  const targetId = getTargetId(state, builderId, sourceId)

  if (!targetId) {
    return null
  }

  return entitySelectors.getNodeById(state, builderId, targetId)
}

export const getNextStepType = (state: RootState, builderId: string, sourceId: string) => {
  const source = entitySelectors.getById(state, builderId, sourceId)
  const target = getTarget(state, builderId, sourceId)

  if (!source) {
    return null
  }

  if (source.entity === EntityType.BUTTON) {
    if (source.type === ButtonType.BUY && target) {
      return target.nodeType
    } else if (source.type === ButtonType.CONTENT && target) {
      return target.nodeType
    } else if (source.type === ButtonType.WA_LIST_MESSAGE_BUTTON) {
      return null
    } else if (source.type === ButtonType.CROSS_CHANNEL_CONTENT && target) {
      return target.nodeType
    } else if (![ButtonType.CONTENT, ButtonType.CROSS_CHANNEL_CONTENT].includes(source.type)) {
      return source.type
    }
    return null
  }

  const targetType = target?.nodeType as unknown as NextStepType
  return includes(NextStepType, targetType) ? targetType : null
}

export const getSmartLinkNextStepOptions = (
  state: RootState,
  builderId: string,
  blockId?: string,
) => {
  const isWhatsAppNode =
    entitySelectors.getParentNode(state, builderId, blockId ?? '')?.nodeType === NodeType.WHATS_APP
  const isFBPageConnected = isFBChannelConnected(state)
  const isIGPageConnected = hasConnectedInstagramChannel(state)

  const getToolTip = (type: NextStepType) => {
    switch (type) {
      case NextStepType.OPEN_IN_MESSENGER: {
        return l.translate('Please Connect Facebook Page')
      }
      case NextStepType.OPEN_IN_INSTAGRAM: {
        return l.translate('Please Connect Instagram Page')
      }
      default:
        return ''
    }
  }

  const enabledIGCrossChannelButton = accountFlags.isEnabled(
    AccountFlag.ENABLE_IG_CROSS_CHANNEL_BUTTON,
  )

  const types = [NextStepType.URL, NextStepType.OPEN_IN_MESSENGER, NextStepType.OPEN_IN_INSTAGRAM]
    .filter((type) =>
      !enabledIGCrossChannelButton || !isWhatsAppNode
        ? type !== NextStepType.OPEN_IN_INSTAGRAM
        : true,
    )
    .filter((type) => !isWhatsAppNode || type !== NextStepType.OPEN_IN_MESSENGER)
  return types.map((type) => ({
    type,
    disabled:
      (type === NextStepType.OPEN_IN_MESSENGER && !isFBPageConnected) ||
      (type === NextStepType.OPEN_IN_INSTAGRAM && !isIGPageConnected),
    tooltip: getToolTip(type),
  }))
}

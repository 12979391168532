import React from 'react'
import cx from 'classnames'
import { Scrollbars, Icon, l } from '@manychat/manyui'

import {
  FIELD_LIST_MAX_HEIGHT,
  FIELD_LIST_MAX_SCROLLABLE_HEIGHT,
  FIELD_LIST_MIN_HEIGHT,
  FIELD_LIST_NEW_OPTION_HEIGHT,
} from 'common/fields/constants'
import { FieldOptionGroup } from 'common/fields/linking/hooks/useFieldsOptions'
import { flattenOptions } from 'components/AutocompleteElements/OptionsList/helpers/helpers'
import { useActiveCategory } from 'components/AutocompleteElements/OptionsList/hooks/useActiveCategory'
import {
  AllowNewField,
  OptionListConfig,
} from 'components/AutocompleteElements/OptionsList/OptionListConfig'
import { useFocusIndex } from 'components/MCTextEditor/shared/hooks/useFocusIndex'
import { useEventListener, usePrevious } from 'utils/commonHooks'

import OptionItem, { IRenderOptionFunction } from './OptionItem'
import OptionParent from './OptionParent'
import { IOption, IFlatOption, ParentOption } from './types'

import cm from './OptionsList.module.css'

export interface OptionsListProps {
  emptyLabel?: string
  onSelect: (option: IOption) => void
  onCreate?: () => void
  allowNew?: AllowNewField
  options: FieldOptionGroup[]
  query?: string
  renderOption?: IRenderOptionFunction
  target?: HTMLElement | null
  minHeight?: number
  maxHeight?: number
  hideCategories?: boolean
  showIcon?: boolean
}

export const OptionsList = ({
  emptyLabel,
  onSelect,
  options,
  query = '',
  renderOption,
  target,
  onCreate,
  hideCategories,
  showIcon,
  allowNew,
}: OptionsListProps) => {
  const prevQuery = usePrevious(query)
  const queryChanged = query !== prevQuery
  const { activeCategoryOptions, activeCategory, setClickedCategory } = useActiveCategory(options)
  const flatOptions = flattenOptions(activeCategoryOptions)
  const allOptionLabels = flatOptions
    .filter((option) => option.parent?.parent && option.parent?.label)
    .map((option) => option.parent?.label)
  const [hiddenOptionGroups, setHiddenOptionGroups] = React.useState(allOptionLabels)
  const focus = useFocusIndex<IFlatOption>(flatOptions, query)
  const fullWidthStyle = { width: '100%' }

  const autoHeightMax = allowNew ? FIELD_LIST_MAX_SCROLLABLE_HEIGHT : FIELD_LIST_MAX_HEIGHT

  React.useEffect(() => {
    if (queryChanged) {
      if (query && hiddenOptionGroups.length) {
        setHiddenOptionGroups([])
      } else if (!query) {
        setHiddenOptionGroups(allOptionLabels)
      }
    }
  }, [query, queryChanged, hiddenOptionGroups, allOptionLabels])

  const handleSelect = (option: IFlatOption) => {
    const handler = option.onSelect || onSelect
    handler(option)
  }

  useEventListener<KeyboardEvent>(target || null, 'keydown', (event) => {
    handleKeyAndStopEvent('ArrowDown', focus.next, event)
    handleKeyAndStopEvent('ArrowUp', focus.prev, event)
    handleKeyAndStopEvent(
      'Enter',
      () => {
        const option = flatOptions[focus.index]

        if (option) {
          handleSelect(option)
        }
      },
      event,
    )
  })

  const handleOptionFocus = (option: IFlatOption) => {
    focus.set(flatOptions.indexOf(option))
  }

  const handleOptionParentClick = (option: ParentOption) => {
    if (typeof option.label !== 'string') return
    const updatedGroup = hiddenOptionGroups.includes(option.label)
      ? hiddenOptionGroups.filter((i) => i !== option.label)
      : [...hiddenOptionGroups, option.label]

    setHiddenOptionGroups(updatedGroup)
  }

  const emptyOptions = flatOptions.length === 0
  const showEmptyLabel = emptyOptions && emptyLabel

  const showCategories = !hideCategories && !emptyOptions

  const createNewField = allowNew ? (
    <a
      style={{ height: FIELD_LIST_NEW_OPTION_HEIGHT }}
      onClick={onCreate}
      className="p-a-sm d-block b-t c-pointer text-primary text-center"
    >
      {l.getString(OptionListConfig[allowNew]?.newOptionLabel)}
    </a>
  ) : null

  if (showEmptyLabel) {
    return (
      <div className={cx(cm.picker, 'd-flex align-center justify-center flex-grow-1')}>
        <div style={fullWidthStyle}>
          <div className="p-a-lg bg-white text-secondary d-flex align-center justify-center">
            {allowNew ? (
              <div className={cx('m-x-auto text-center text-disabled', cm.emptyCenter)}>
                {l.getString(
                  OptionListConfig[allowNew][query ? 'emptySearchLabel' : 'emptyCreateLabel'],
                )}
              </div>
            ) : (
              <>
                <Icon.Unsubscribed className="m-r-xxs" size={20} />
                <span>{emptyLabel}</span>
              </>
            )}
          </div>
          {createNewField}
        </div>
      </div>
    )
  }
  return (
    <div className={cx(cm.picker, 'd-flex')}>
      {showCategories && (
        <div className="d-flex flex-shrink-0 b-r">
          <ul className="m-0 p-a-xs">
            {options.map(({ options, group, label }, index) => {
              return options?.length ? (
                <li
                  key={index}
                  onClick={() => setClickedCategory(group)}
                  className={cx(cm.category, { [cm.activeCategory]: group === activeCategory })}
                >
                  {label}
                </li>
              ) : null
            })}
          </ul>
        </div>
      )}
      <div style={fullWidthStyle}>
        <Scrollbars
          autoHeight
          autoHeightMax={autoHeightMax}
          autoHeightMin={FIELD_LIST_MIN_HEIGHT}
          autoHide={false}
        >
          <ul className="menu m-0 p-0" aria-label="Field menu">
            {flatOptions.map((option, index) => {
              const isOptionGroupHidden =
                typeof option.parent?.label === 'string' &&
                hiddenOptionGroups.includes(option.parent.label)
              return (
                <React.Fragment key={`${option?.parent?.label || ''}-${option.value}`}>
                  <OptionParent
                    index={index}
                    option={option}
                    onClick={handleOptionParentClick}
                    isHidden={isOptionGroupHidden}
                  />
                  <OptionItem
                    option={option}
                    onClick={handleSelect}
                    onFocus={handleOptionFocus}
                    focused={focus.index === index}
                    renderOption={renderOption}
                    isHidden={isOptionGroupHidden}
                    showIcon={showIcon}
                  />
                </React.Fragment>
              )
            })}
          </ul>
        </Scrollbars>
        {createNewField}
      </div>
    </div>
  )
}

const handleKeyAndStopEvent = (key: string, handler: () => void, event: KeyboardEvent) => {
  if (key === event.key) {
    handler()
    event.preventDefault()
    event.stopPropagation()
  }
}

import BaseBlockConfig from 'common/builder/blocks/BaseBlockConfig'
import { IBlockBase, ITextBlock } from 'common/builder/blocks/blockInterfaces'
import { BlockType } from 'common/builder/constants/BlockType'

class TextBlockConfig extends BaseBlockConfig {
  blockType = BlockType.TEXT

  getDefaults = (): Omit<ITextBlock, keyof IBlockBase> => ({
    text: '',
  })
}

export default new TextBlockConfig()

import EventEmitter from 'events'

import { RenderTexture } from '@pixi/core'
import { Graphics } from '@pixi/graphics'
import { Point } from '@pixi/math'
import { scaleLinear } from 'd3-scale'
import { zoomIdentity } from 'd3-zoom'
import get from 'lodash/get'
import throttle from 'lodash/throttle'

import { CHOOSE_FIRST_STEP_NODE } from 'common/builder/constants/NodeType'
import { analyticsService, flowBuilderEvents } from 'utils/services/analytics'

import config, {
  SIDEBAR_WIDTH,
  STATES,
  CALCULATE_WIDTH_INTERVAL,
  MAX_EXPORT_IMAGE_WIDTH,
  MAX_EXPORT_IMAGE_HEIGHT,
  SCALE_DEFAULT,
  appScaleExtent,
  botMapConnectorWidthScale,
  connectorWidthScale,
  nodeFrameWidthScale,
  START_ID,
} from './config'

const ZOOM_DURATION = 400
const moveDurationRange = [700, 2500]
const getScaleDuration = scaleLinear().domain(appScaleExtent).range(moveDurationRange)
const getDistanceDuration = scaleLinear().domain([0, 5000]).range(moveDurationRange)

class App extends EventEmitter {
  root = null
  sidebarHidden = true
  _state = STATES.NORMAL
  _stateOptions = null
  _transform = null
  _prevTransform = {}
  _index = {}
  _hoveredNode = null
  _highlightedIds = []
  _rearrange = () => {}
  _bindingGuide = null

  // Width calculation
  _lastUsedScale = 0
  _throttledTransformHandler = throttle(
    (transform, prevTransform) => {
      if (this._lastUsedScale === transform.k) {
        return
      }

      this._lastUsedScale = transform.k

      this.emit('strokechanged', transform, prevTransform)
    },
    CALCULATE_WIDTH_INTERVAL,
    { leading: false },
  )

  /**
   * Sets render flag to 'true', so scene will be rendered on next animation frame
   */
  render = () => {
    if (this._render !== true) {
      this._render = true
    }
  }

  /**
   * Current scene transform for panning and zooming
   * @typedef {Object} Transform
   * @property {number} x
   * @property {number} y
   * @property {number} k
   *
   * @return {Transform}
   */
  get transform() {
    return this._transform
  }

  /**
   * Sets transform property for the current scene
   * @param {Object} transform
   * @param {number} transform.x
   * @param {number} transform.y
   * @param {number} transform.k
   */
  set transform(value) {
    const prev = this._transform || value
    this._prevTransform.x = prev.x
    this._prevTransform.y = prev.y
    this._prevTransform.k = prev.k
    this._transform = value
    this.emit('transform', this._transform, this._prevTransform)
    this._throttledTransformHandler(this._transform, this._prevTransform)
    if (this._state === STATES.OVERLAY_EDITOR) {
      this.setState(STATES.NORMAL)
    }
  }

  get state() {
    return this._state
  }

  set state(value) {
    const { state, options } =
      value != null && typeof value === 'object' ? value : { state: value, options: null }

    if (!STATES[state]) {
      throw new Error('invalid FlowChart state')
    }
    const prevState = this._state
    this._state = state
    this._stateOptions = options

    this._reduceCursor()
    this.emit('state', state, prevState)
  }

  get stateOptions() {
    return this._stateOptions
  }

  setState = (state, options) => {
    this.state = { state, options }
  }

  resetState = () => {
    this.state = STATES.NORMAL
  }

  _reduceCursor = () => {
    const { state } = this
    const hoveredNode = this.getHoveredNode()
    const isNodeHovered = Boolean(hoveredNode)
    let cursor = isNodeHovered ? 'pointer' : 'auto'
    if (isNodeHovered && hoveredNode.id === START_ID) {
      cursor = 'grab'
    }
    switch (state) {
      case STATES.DRAGGING:
      case STATES.DRAGGING_START:
        cursor = 'grabbing'
        break
      case STATES.SELECTING_FRAME:
        cursor = 'crosshair'
        break
      case STATES.CREATING_NEW_CONNECTOR:
        cursor = isNodeHovered ? 'grab' : 'grabbing'
        break
      case STATES.PANNING:
        cursor = 'grab'
        break
    }
    this._setCursor(cursor)
  }

  _setCursor = (cursor) => {
    const { root } = this
    if (!root) {
      return
    }

    this._prevViewCursor = root.view.style.cursor
    root.view.style.cursor = `-webkit-${cursor}`
    root.view.style.cursor = `-moz-${cursor}`
    root.view.style.cursor = `${cursor}`
  }

  get sidebarWidth() {
    return this.sidebarHidden ? 0 : SIDEBAR_WIDTH
  }

  setNode(node) {
    this._index[node.id] = node
    this.emit('nodeAdded', node)
  }

  deleteNode(node, id) {
    id = id || node.id
    // If removing node isn't cached we must keep cache state unchanged
    if (node !== this._index[id]) {
      return
    }
    delete this._index[id]
    this.dropHover(node, true)
    this.emit('nodeDeleted', node)
  }

  getNode(id) {
    return this._index[id]
  }

  getNodes() {
    return Object.values(this._index)
  }

  emitNodeMounted = (node) => this.emit('nodeMounted', node)

  isNode(node) {
    return node != null && node.name && node.name.indexOf('node/') === 0
  }

  isBlock(block) {
    return block != null && block.name && block.name.indexOf('block/') === 0
  }

  isButton(node) {
    return node != null && node.name && (node.name === 'btn' || node.name.indexOf('button/') === 0)
  }

  isChooseFirstStepNode(node) {
    return this.isNode(node) && node.id === CHOOSE_FIRST_STEP_NODE
  }

  rearrange = (instant = false) => {
    analyticsService.logFlowBuilderEvent(flowBuilderEvents.AUTO_ARRANGE)

    this._rearrange(true, instant)
  }

  registerRearrangeHandler = (handler) => {
    this._rearrange = handler
  }

  getCurrentCenter = () => {
    const { width, height, transform, sidebarWidth } = this
    return {
      x: Math.floor((-transform.x + (sidebarWidth + (width - sidebarWidth) / 2)) / transform.k),
      y: Math.floor((-transform.y + height / 2) / transform.k),
    }
  }

  getPointForNewItems = (srcId) => {
    let point = this.getCurrentCenter()

    const $src = srcId && this.getNode(srcId)
    if ($src) {
      const { x, y } = $src.getAbsolutePosition()
      const width = $src.layout ? $src.layout.width : $src.getWidth()
      const height = $src.layout ? $src.layout.height : $src.getHeight()
      point = {
        x: Math.floor(x + width + config.CONTENT_POSITION_RIGHT_MARGIN),
        y: Math.floor(y + height / 2),
      }
    }

    const { x: maxX, y: maxY } = this.screen2canvas(this.width, this.height)
    const nodes = Object.values(this._index)

    let emptyPoint = false
    while (emptyPoint === false) {
      const nodesInPoint = nodes.filter((n) => n.x === point.x && n.y === point.y)
      if (nodesInPoint.length === 0) {
        emptyPoint = true
      } else {
        point.x += 50
        point.y += 50
        if (point.x > maxX || point.y > maxY) {
          return this.getCurrentCenter()
        }
      }
    }

    return point
  }

  getNodeGlobalPosition = (node, origin) => {
    const point = new Point()
    origin = origin || new Point(0, 0)
    node.ctx.toGlobal(origin, point, true)

    return point
  }

  getNodeBoundingClientRect = (node) => {
    const point = this.getNodeGlobalPosition(node)
    let { width, height } = node.ctx.getBounds(true)
    return {
      top: point.y,
      bottom: point.y + height,
      right: point.x + width,
      left: point.x,
      width,
      height,
    }
  }

  getNodesOffsets = (ids) => {
    let minX = Number.MAX_SAFE_INTEGER,
      minY = Number.MAX_SAFE_INTEGER,
      nodeOffsets = {}
    for (let id of ids) {
      const node = app.getNode(id)
      if (node == null) {
        continue
      }

      const { x, y } = node.getAbsolutePosition()
      nodeOffsets[id] = { x, y }
      if (x < minX) {
        minX = x
      }
      if (y < minY) {
        minY = y
      }
    }
    for (let id of Object.keys(nodeOffsets)) {
      nodeOffsets[id].x -= minX
      nodeOffsets[id].y -= minY
    }
    nodeOffsets.$group = {
      x: minX,
      y: minY,
    }

    return nodeOffsets
  }

  getNodesBounds = (ids) => {
    let minX = Number.MAX_SAFE_INTEGER,
      minY = Number.MAX_SAFE_INTEGER
    let maxX = Number.MIN_SAFE_INTEGER,
      maxY = Number.MIN_SAFE_INTEGER
    for (let id of ids) {
      const node = app.getNode(id)
      if (node == null) {
        continue
      }

      const { x, y } = node.getAbsolutePosition()
      const { width, height } = node.getBounds(true)
      if (x < minX) {
        minX = x
      }
      if (y < minY) {
        minY = y
      }
      if (x + width > maxX) {
        maxX = x + width
      }
      if (y + height > maxY) {
        maxY = y + height
      }
    }

    return {
      x: minX,
      y: minY,
      width: maxX - minX,
      height: maxY - minY,
    }
  }

  moveBy = (x, y) => {
    const { zoom, selection } = this
    selection.call(zoom.translateBy, x, y)
  }

  moveTo = (id) => {
    const $el = this._index[id]
    if ($el == null) {
      return console.warn('Cannot find node to move by id:', id)
    }

    let width = 0
    let height = 0

    if ($el.layout) {
      width = $el.layout.width
      height = $el.layout.height
    } else {
      width = $el.getWidth()
      height = $el.getHeight()
    }

    const { x, y } = $el.getAbsolutePosition()
    const pX = x + width / 2
    const pY = y + height / 2

    this.moveToFrame(pX, pY, width, height)
  }

  moveToFrame = (x, y, minWidth, minHeight, immediately = false) => {
    const { zoom, selection, width, height, transform, sidebarWidth } = this
    // Calculating speed
    const dX = Math.abs(x * transform.k - width / 2 - -transform.x)
    const dY = Math.abs(y * transform.k - height / 2 - -transform.y)
    const distance = Math.sqrt(dX * dX + dY * dY)
    const dScale = Math.abs(0.7 - transform.k)
    const duration = Math.max(getDistanceDuration(distance), getScaleDuration(dScale))

    const _width = width - sidebarWidth
    const maxWidthScale = minWidth != null ? _width / minWidth : 1
    const maxHeightScale = minHeight ? height / minHeight : 1

    // Finding max available scale and getting 80% of it
    const scale = Math.min(1, maxWidthScale, maxHeightScale) * 0.8

    const t = zoomIdentity
      .translate(sidebarWidth + (width - sidebarWidth) / 2, height / 2)
      .scale(scale)
      .translate(-x, -y)

    if (immediately) {
      return selection.call(zoom.transform, t)
    }
    selection.transition().duration(duration).call(zoom.transform, t)
  }

  fitAllNodes = (immediately = false) => {
    const nodeIds = this.getNodes()
      .filter((n) => n.name?.startsWith('node/'))
      .map((n) => n.id)
    const bounds = app.getNodesBounds([...nodeIds, START_ID])
    const centerX = bounds.x + bounds.width / 2
    const centerY = bounds.y + bounds.height / 2
    this.moveToFrame(centerX, centerY, bounds.width, bounds.height, immediately)
  }

  scaleUp = () => {
    analyticsService.logFlowBuilderEvent(flowBuilderEvents.SCALE_UP)
    this._scale(2)
  }

  scaleDown = () => {
    analyticsService.logFlowBuilderEvent(flowBuilderEvents.SCALE_DOWN)
    this._scale(0.5)
  }

  _scale = (k) => {
    const { zoom, selection } = this
    selection.transition().duration(ZOOM_DURATION).call(zoom.scaleBy, k)
  }

  screen2canvas = (x, y) => {
    const scale = this.transform.k
    const appX = -this.transform.x / scale
    const appY = -this.transform.y / scale
    return { x: appX + x / scale, y: appY + y / scale }
  }

  blockPanning = function () {
    window.app._isDragging = true
  }

  unblockPanning = function () {
    window.app._isDragging = false
  }

  startPanning = () => {
    if (this.root?.stage?.interactiveChildren) {
      this.root.stage.interactiveChildren = false
      this.setState(STATES.PANNING)
    }
  }

  stopPanning = () => {
    if (!this.root) {
      return
    }
    this.root.stage.interactiveChildren = true
    this.setState(STATES.NORMAL)
  }

  getConnectorWidth = (transform) => {
    transform = transform || this.transform
    const k = Number.isFinite(transform.k) ? transform.k : SCALE_DEFAULT
    return connectorWidthScale(k)
  }

  getBotMapConnectorWidth = (transform) => {
    transform = transform || this.transform
    const k = Number.isFinite(transform.k) ? transform.k : SCALE_DEFAULT
    return botMapConnectorWidthScale(k)
  }

  getNodeFrameWidth = (transform) => {
    transform = transform || this.transform
    const k = Number.isFinite(transform.k) ? transform.k : SCALE_DEFAULT
    return nodeFrameWidthScale(k)
  }

  //------------------------SELECTION FRAME------------------------

  selectionFrame(rect) {
    this.emit('selectionframe', rect || null)
  }

  //-------------------------BINDING GUIDE-------------------------

  get bindingGuide() {
    return this._bindingGuide
  }

  set bindingGuide(value) {
    this._bindingGuide = value
    this.emit('bindingguide', value)
  }

  //--------------------------NODES HOVER--------------------------
  setHover(node) {
    if (this.state !== STATES.NORMAL && this.state !== STATES.CREATING_NEW_CONNECTOR) {
      return
    }
    if (node.parent && this._hoveredNode === node.parent.shape) {
      return
    }

    clearTimeout(this._dropHoverTimer)

    if (this._hoveredNode === node) {
      return
    }

    this._hoveredNode = node
    this._reduceCursor()
    app.render()
    this.emit('hover', node)
  }

  dropHover(fromNode) {
    if (this._hoveredNode == null) {
      return
    }
    if (fromNode && this._hoveredNode !== fromNode) {
      return
    }

    this._hoveredNode = null
    this._reduceCursor()
    app.render()
    this.emit('hover', null)
  }

  getHoveredNode = () => this._hoveredNode

  //--------------------------NODES HIGHLIGHT--------------------------
  highlight(ids = []) {
    this._highlightedIds = ids.filter((id) => id)
    this.emit('highlight', this._highlightedIds)
  }

  dropHighlight() {
    this.highlight()
  }

  getHighlightedIds = () => this._highlightedIds

  //--------------------------EXPERIMENTS--------------------------

  _normalizeBounds = (bounds) => ({
    x: Math.floor(bounds.x),
    y: Math.floor(bounds.y),
    width: Math.floor(bounds.width),
    height: Math.floor(bounds.height),
  })

  getImage = (bounds, outputSize, { retina, padding = 0, maxOutputSize = null } = {}) => {
    bounds = this._normalizeBounds(bounds)
    const { root } = this
    const { renderer, stage } = root

    // Calculating initial box
    const sceneX = bounds.x - padding
    const sceneY = bounds.y - padding
    const sceneWidth = bounds.width + padding * 2
    const sceneHeight = bounds.height + padding * 2

    let imgWidth, imgHeight
    if (outputSize != null) {
      imgWidth = outputSize.width
      imgHeight = outputSize.height
    } else {
      // Using auto height/width for image if size not provided
      imgWidth = sceneWidth
      imgHeight = sceneHeight
    }

    // Calculating final image size
    const MAX_OUTPUT_HEIGHT = get(maxOutputSize, 'height', MAX_EXPORT_IMAGE_HEIGHT)
    const MAX_OUTPUT_WIDTH = get(maxOutputSize, 'width', MAX_EXPORT_IMAGE_WIDTH)

    const maxSizesRation = MAX_OUTPUT_WIDTH / MAX_OUTPUT_HEIGHT
    const ratio = imgWidth / imgHeight
    let downScale
    if (ratio >= maxSizesRation) {
      if (imgWidth > MAX_OUTPUT_WIDTH) {
        downScale = MAX_OUTPUT_WIDTH / imgWidth
      }
    } else {
      if (imgHeight > MAX_OUTPUT_HEIGHT) {
        downScale = MAX_OUTPUT_HEIGHT / imgHeight
      }
    }
    if (downScale) {
      imgWidth = Math.floor(imgWidth * downScale)
      imgHeight = Math.floor(imgHeight * downScale)
    }

    // console.log('imgWidth:', imgWidth, 'sceneWidth:', sceneWidth)

    // Limiting max scene scale by '1' to prevent downscaling
    const scale = Math.min(imgWidth / sceneWidth, imgHeight / sceneHeight, 1)

    const centeredSceneX = sceneX - Math.max(0, (imgWidth / scale - sceneWidth) / 2)
    const centeredSceneY = sceneY - Math.max(0, (imgHeight / scale - sceneHeight) / 2)

    const offsetX = centeredSceneX * scale
    const offsetY = centeredSceneY * scale
    // console.log('SCALE:', scale, 'OFFSET_X:', offsetX, 'OFFSET_Y:', offsetY)

    const prevTransform = app.transform
    const transform = { ...app.transform, k: scale, x: -offsetX, y: -offsetY }
    app.transform = transform
    this.emit('strokechanged', transform, prevTransform)
    this.updateTransform()

    // Setting up scene background
    const $bg = new Graphics()
    $bg.beginFill(0xf9fafc)
    $bg.drawRect(centeredSceneX, centeredSceneY, imgWidth / scale, imgHeight / scale)
    $bg.endFill()
    stage.addChildAt($bg, 0)

    const renderTexture = RenderTexture.create(imgWidth, imgHeight, 1, retina ? 2 : 1)
    renderer.render(stage, renderTexture)

    // Restore scene
    stage.removeChildAt(0)
    app.transform = prevTransform
    this.emit('strokechanged', app.transform, transform)
    this.updateTransform()

    return renderTexture
  }

  textClick = (options) => {
    this.setState(STATES.OVERLAY_EDITOR, options)
  }

  pushTexture = (texture) => {
    const p = new Promise((resolve, reject) => {
      texture.on('loaded', resolve)
      texture.on('error', reject)
    })
    this.textures.push(p)
    return p
  }

  ensureTexture = () => {
    return Promise.all(this.textures).then(() => (this.textures = []))
  }

  textures = []
}

const app = new App()
app.setMaxListeners(0)

window.$flow = app

export default app

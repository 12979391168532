import React from 'react'
import { useAppSelector } from 'reduxTyped'

import {
  DocumentVisibilityState,
  LOCAL_STORAGE_KEY_NOTIFICATION_TAB_ID,
} from 'apps/chat/constants/LiveChatNotifications'
import { useIsAnyTabActive } from 'apps/chat/hooks/useIsAnyTabActive'
import { getAreSoundAlertsEnables } from 'apps/chat/selectors/notificationSelectors'
import { getNewMessageSoundURL } from 'utils/assets'
import { getPageLifecycleState } from 'utils/getPageLifecycleState'
import { getSafeStorageEvent } from 'utils/getSafeStorageEvent'
import localStorage from 'utils/localStorage'
import { parseLocalStorageValue } from 'utils/parseLocalStorageValue'
import { analyticsService } from 'utils/services/analytics'
import errorTrackingService from 'utils/services/errorTrackingService'

function getActiveSoundNotificationTabID() {
  return Number(localStorage.getItem(LOCAL_STORAGE_KEY_NOTIFICATION_TAB_ID))
}

const newMessageSound = new Audio(getNewMessageSoundURL())

export const useLivechatSoundNotification = (tabID: number) => {
  const { isAnyTabActiveRef } = useIsAnyTabActive(tabID)
  const isSoundPlaying = React.useRef<boolean>(false)
  const areSoundAlertsEnabled = useAppSelector(getAreSoundAlertsEnables)

  const setActiveSoundNotificationTabID = React.useCallback((value: number | null) => {
    const activeSoundNotificationTabID = getActiveSoundNotificationTabID()

    if (activeSoundNotificationTabID !== value) {
      localStorage.setItem(LOCAL_STORAGE_KEY_NOTIFICATION_TAB_ID, value)
    }
  }, [])

  const handleLocalStorageChange = React.useCallback(
    (event: StorageEvent) => {
      const { key: lsKey, value: newValue } = getSafeStorageEvent(event)

      if (lsKey === LOCAL_STORAGE_KEY_NOTIFICATION_TAB_ID) {
        const value = parseLocalStorageValue(newValue)

        if (!value) {
          setActiveSoundNotificationTabID(tabID)
        }
      }
    },
    [setActiveSoundNotificationTabID, tabID],
  )

  const handleBeforeUnload = React.useCallback(() => {
    const activeSoundNotificationTabID = getActiveSoundNotificationTabID()
    if (tabID === activeSoundNotificationTabID) {
      setActiveSoundNotificationTabID(null)
    }
  }, [tabID, setActiveSoundNotificationTabID])

  React.useEffect(() => {
    setActiveSoundNotificationTabID(tabID)
    window.addEventListener('beforeunload', handleBeforeUnload)
    window.addEventListener('storage', handleLocalStorageChange)

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload)
      window.removeEventListener('storage', handleLocalStorageChange)
    }
  }, [tabID, handleBeforeUnload, setActiveSoundNotificationTabID, handleLocalStorageChange])

  const triggerSoundNotification = React.useCallback(() => {
    const activeSoundNotificationTabID = getActiveSoundNotificationTabID()
    const pageLifecycleState = getPageLifecycleState()
    const isPageVisible = document.visibilityState === DocumentVisibilityState.VISIBLE
    const isTabActiveForSoundNotifications = tabID === activeSoundNotificationTabID

    if (
      isSoundPlaying.current ||
      !pageLifecycleState ||
      !areSoundAlertsEnabled ||
      (isTabActiveForSoundNotifications && !isPageVisible && isAnyTabActiveRef.current) ||
      (!isTabActiveForSoundNotifications && !isPageVisible)
    ) {
      return
    }

    analyticsService.sendEvent('LIVE_CHAT.NOTIFICATION.SOUND_TRIGGERED')

    let playSoundPromise: ReturnType<HTMLMediaElement['play']> | undefined
    try {
      playSoundPromise = newMessageSound.play()
    } catch (err) {
      errorTrackingService.trackWarning(err, {
        fingerprint: 'livechat-sound-play',
      })
    }

    if (playSoundPromise && typeof playSoundPromise.then === 'function') {
      isSoundPlaying.current = true
      playSoundPromise
        .then(() => (isSoundPlaying.current = false))
        .catch(() => (isSoundPlaying.current = false))
    }
  }, [isAnyTabActiveRef, areSoundAlertsEnabled, tabID])

  return { triggerSoundNotification }
}

import React, { useCallback, useEffect } from 'react'
import { bindActionCreators, ActionCreatorsMapObject, AnyAction } from 'redux'
import { useAppDispatch, useAppSelector } from 'reduxTyped'

import * as profileActions from 'apps/profile/actions/profileActions'
import * as profileTypedActions from 'apps/profile/actions/profileTypedActions'
import { Account } from 'apps/profile/models/Page/helpers'
import * as pageActions from 'common/core/actions/pageActions'
import { ISidebarAccount } from 'common/core/interfaces/sideBarAccount'
import * as appSelectors from 'common/core/selectors/appSelectors'
import { isAnyHelperKeyPressed } from 'utils/dom'
import { analyticsService } from 'utils/services/analytics'

import { MakeTranslate } from '../../../i18n/format/types'

export const usePage = (
  title: string | MakeTranslate<string>,
  event?: string | [string, Record<string, string | number | null>],
): void => {
  const isLoggedId = useAppSelector(appSelectors.getLoggedIn)
  const dispatch = useAppDispatch()

  useEffect(() => {
    dispatch(pageActions.setTitle(title))
    if (event) {
      if (typeof event === 'string') {
        if (isLoggedId) {
          analyticsService.sendEvent(event)
        } else {
          analyticsService.sendLoggedoutUserEvent(event)
        }
      }

      if (Array.isArray(event)) {
        if (isLoggedId) {
          analyticsService.sendEvent(event[0], event[1])
        } else {
          analyticsService.sendLoggedoutUserEvent(event[0])
        }
      }
    }
  }, [dispatch, event, isLoggedId, title])
}

export function useActions(
  actions: Array<ActionCreatorsMapObject<AnyAction>> | ActionCreatorsMapObject<AnyAction>,
): Array<ActionCreatorsMapObject<AnyAction>> | ActionCreatorsMapObject<AnyAction> {
  const dispatch = useAppDispatch()
  return React.useMemo(() => {
    if (Array.isArray(actions)) {
      return actions.map((a) => bindActionCreators(a, dispatch))
    }
    return bindActionCreators(actions, dispatch)
  }, [actions, dispatch])
}

export const useAccounts = (shouldFetch = true): [boolean, ISidebarAccount[]] => {
  const accounts = useAppSelector(appSelectors.getAccounts)

  const dispatch = useAppDispatch()
  const [isFetching, setIsFetching] = React.useState(false)
  const hasAlreadyFetched = accounts.length > 0

  React.useEffect(() => {
    const fetchAccounts = async () => {
      if (shouldFetch && !hasAlreadyFetched) {
        setIsFetching(true)
        await dispatch(profileActions.fetchAllAccounts())
        setIsFetching(false)
      }
    }

    fetchAccounts()
  }, [dispatch, shouldFetch, hasAlreadyFetched])

  return [isFetching, accounts]
}

export const useSidebarAccountList = (
  shouldFetch = true,
): { isFetching: boolean; accountList: Account[] } => {
  const dispatch = useAppDispatch()

  const accountList = useAppSelector((state) => state.profile.pages.filtered)
  const isFetching = useAppSelector((state) => state.profile.pages.loading)

  const hasAlreadyFetched = accountList.length > 0

  React.useEffect(() => {
    const fetchAccountList = async () => {
      if (shouldFetch && !hasAlreadyFetched && !isFetching) {
        await dispatch(profileTypedActions.fetchPages())
      }
    }

    fetchAccountList()
  }, [dispatch, shouldFetch, hasAlreadyFetched, isFetching])

  const filteredAccountList = React.useMemo(() => {
    return accountList.filter((account) => !account.isHidden)
  }, [accountList])

  return { isFetching, accountList: filteredAccountList }
}

export const useTranslation = () => {
  const isLoggedId = useAppSelector(appSelectors.getLoggedIn)
  const selectedLangInApp = useAppSelector(appSelectors.getUserLanguage)

  const handleTranslation = useCallback(
    (event: TSFixMe) => {
      const element = document.getElementById('language')?.children[0]?.children[0]
      if ('language' === event.target?.id && element) {
        if (isLoggedId) {
          analyticsService.sendEvent('INTERFACE.TRANSLATION', {
            selectedLangInApp,
            // eslint-disable-next-line
            // @ts-expect-error
            lang: navigator.language || navigator.userLanguage || 'en',
            langString: element?.innerHTML,
          })
        } else {
          analyticsService.sendLoggedoutUserEvent('INTERFACE.TRANSLATION')
        }

        document.removeEventListener('DOMSubtreeModified', handleTranslation)
      }
    },
    [isLoggedId, selectedLangInApp],
  )

  useEffect(() => {
    document.addEventListener('DOMSubtreeModified', handleTranslation, true)
    return () => {
      document.removeEventListener('DOMSubtreeModified', handleTranslation)
    }
  }, [handleTranslation])
}

export const useKeyDown = ({
  key,
  onPress,
  ignoreHelperKeys = true,
}: {
  key?: string
  onPress?: () => void
  ignoreHelperKeys?: boolean
}) => {
  useEffect(() => {
    if (!key || !onPress) {
      return
    }

    const handleKeyDown = (event: KeyboardEvent) => {
      if (
        event.key === key &&
        (!ignoreHelperKeys || (ignoreHelperKeys && !isAnyHelperKeyPressed(event)))
      ) {
        onPress()
      }
    }

    document.addEventListener('keydown', handleKeyDown)

    return () => {
      document.removeEventListener('keydown', handleKeyDown)
    }
  }, [ignoreHelperKeys, key, onPress])
}

import React from 'react'
import { l } from '@manychat/manyui'

import { billing, UpgradeSource } from 'common/billing'
import cm from 'common/core/components/NotifyBar/StaticNotifications/StaticNotifications.module.css'
import { ProductType } from 'common/core/interfaces/products'

const WAUpgradeToProNotification = () => {
  const handleButtonClick = () => {
    billing.requestUpgrade({
      source: UpgradeSource.WA_UPGRADE_TO_PRO,
      products: [ProductType.INBOX, ProductType.AUTOMATION],
    })
  }

  return (
    <>
      <span>
        {l.translate(
          'Upgrade to Pro to start using WhatsApp channel and get access to important features to grow your business.',
        )}
      </span>
      <a className={cm.more} onClick={handleButtonClick}>
        {l.translate('Upgrade to Pro')}
      </a>
    </>
  )
}

export default WAUpgradeToProNotification

import { AnyAction } from 'redux'
import { appListenerMiddleware } from 'reduxTyped'

export const waitForAction = <Action extends AnyAction>(
  predicate: (action: AnyAction) => action is Action,
): Promise<Action> =>
  new Promise<Action>((resolve) => {
    const unsubscribe = appListenerMiddleware.startListening({
      predicate,
      effect: (action) => {
        unsubscribe()
        resolve(action)
      },
    })
  })

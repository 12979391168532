import { IInstagramStoryReplyTriggerWidget } from 'apps/growthTools/growthToolsInterfaces'
import { BaseWidget } from 'apps/growthTools/models/Widget/baseWidget'
import { WidgetTypes } from 'apps/growthTools/models/Widget/constants'
import { StorySelectType } from 'apps/instagram/models/interfaces'
import { ConditionTypes } from 'apps/keywords/models/KeywordRule/constants'
import { TriggerType } from 'common/flow/constants/TriggerType'
import { ITriggerId } from 'common/flow/flowTriggersInterfaces'
import { ProtoStoryReply } from 'shared/api/requests/aiAssistant/schemas'

export const toInstagramStoryReplyWidget = (
  trigger: ProtoStoryReply,
): IInstagramStoryReplyTriggerWidget => {
  const id = 1
  const triggerId: ITriggerId = `${TriggerType.WIDGET}-${id}`

  return {
    ...BaseWidget,
    widget_id: id,
    widget_type: WidgetTypes.INSTAGRAM_STORY_REPLY,
    channel: trigger.channel,
    data: {
      story_id: '',
      story_select: StorySelectType.ALL,
      keywords_condition: ConditionTypes.ANY,
      keywords: [],
      auto_like: true,
      flow_delay: 0,
    },
    id,
    triggerType: TriggerType.WIDGET,
    triggerId: triggerId,
    caption: trigger.caption || '',
  }
}

import { TagsExtraState } from 'common/tags/store/types'

export const ExtraState: TagsExtraState = {
  createTagModal: {
    isOpen: false,
    tagName: '',
    source: null,
    lastCreatedTag: null,
  },
  search: {
    value: '',
  },
  isSettingsTab: false,
}

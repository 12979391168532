import find from 'lodash/find'
import get from 'lodash/get'
import isArray from 'lodash/isArray'
import isEmpty from 'lodash/isEmpty'
export const parseAccount = (item) => {
  return {
    ...item,
    id: item.account_id,
  }
}

export const parseCampaign = (item) => {
  return {
    ...item,
    id: item.id,
    accountId: item.account_id,
  }
}

export const parseAdSet = (item) => {
  return {
    ...item,
    data: item,
    id: item.id,
    adSetId: item.id,
    name: item.name,
    accountId: item.account_id,
    campaignId: item.campaign_id,
    effectiveStatus: item.effective_status,
    status: item.status,
  }
}
export const parseAdSetDraft = (item) => {
  const hasEmptyDraft = !item.draft || (isArray(item.draft) && isEmpty(item.draft))
  const draft = hasEmptyDraft ? null : item.draft
  return {
    ...item,
    id: `${item.ad_set_draft_id}`,
    draftId: `${item.ad_set_draft_id}`,
    adSetId: item.ad_set_id,
    accountId: item.account_id,
    campaignId: item.campaign_id,
    name: get(item, 'data.name') || get(item, 'draft.name', ''),
    draft,
  }
}

export const parseAd = (item) => {
  return {
    ...item,
    id: item.id,
    accountId: item.account_id,
    campaignId: item.campaign_id,
    adSetId: item.adset_id,
    name: item.name,
    status: item.status,
    effectiveStatus: item.effective_status,
    creativeId: get(item, 'creative.id') || null,
  }
}

export const parseAdgt = (item) => {
  return {
    ...item,
    id: `${item.id}`,
    adId: item.ad_id,
    accountId: item.ad_account_id,
    campaignId: item.campaign_id,
    adSetId: item.ad_set_id,
    name: item.name,
    status: item.status,
    adStatus: item.status,
    creativeId: item.ad_creative_id || null,
  }
}

export const combineAccounts = (accounts, stats) => {
  return accounts.map((item) => {
    const fbStats = find(stats.ad_insights || [], { account_id: item.account_id })
    return {
      ...item,
      fbStats,
      currency: get(fbStats, 'account_currency'),
    }
  })
}

export const combineAdSet = (adSet, adSetDraft) => {
  adSet = adSet || {}
  adSetDraft = adSetDraft || {}
  return {
    ...adSetDraft,
    ...adSet,
    id: adSet.id || adSetDraft.id,
    adSetId: adSet.id || adSetDraft.adSetId,
    draftId: adSetDraft.id,
    name: adSet.name || adSetDraft.name,
  }
}

export const combineAdSets = (adSets, adSetDrafts) => {
  const notPublishedAdSetDrafts = adSetDrafts.filter((item) => !item.adSetId)
  return [
    ...adSets.map((adSet) => {
      const adSetDraft = adSetDrafts.find((item) => item.adSetId === adSet.id) || {}
      return combineAdSet(adSet, adSetDraft)
    }),
    ...notPublishedAdSetDrafts,
  ]
}

export const combineAd = (ad, adgt, fbStats, mcStats) => {
  ad = ad || {}
  adgt = adgt || {}
  return {
    ...adgt,
    ...ad,
    fbStats,
    mcStats,
    adId: ad.id || adgt.adId,
    adgtId: adgt.id,
    adCreativeId: ad.creativeId,
    adgtCreativeId: adgt.creativeId,
    adgtStatus: adgt.status,
    adStatus: ad.status,
    name: adgt.name || ad.name,
  }
}

export const combineAds = (ads, growthTools, stats) => {
  const notPublishedGrowthTools = growthTools.filter((item) => !item.adId)
  return [
    ...ads.map((ad) => {
      const adgt = growthTools.find((item) => item.adId === ad.id) || {}
      const fbStats = (stats.ad_insights || []).find((stat) => `${stat.ad_id}` === ad.id)
      const mcStats = (stats.growth_tools || []).find((stat) => `${stat.ad_id}` === ad.id)
      return combineAd(ad, adgt, fbStats, mcStats)
    }),
    ...notPublishedGrowthTools.map((adgt) => {
      const fbStats = (stats.ad_insights || []).find(
        (stat) => `${stat.ads_growth_tool_id}` === adgt.id,
      )
      const mcStats = (stats.growth_tools || []).find(
        (stat) => `${stat.ads_growth_tool_id}` === adgt.id,
      )
      return combineAd(null, adgt, fbStats, mcStats)
    }),
  ]
}

import React, { memo } from 'react'

import { useRegistry, useScreen, useSerializedView } from '../context'
import { useInjectStyles } from '../hooks'
import { isPropsWithClassName } from '../schemas'
import { Id } from '../types'
import { isNestedBlock } from '../utils/guards'

interface RenderBlockProps {
  blockId: Id
}

export const RenderBlock = memo(({ blockId }: RenderBlockProps) => {
  const view = useSerializedView()
  const serializedBlock = useSerializedView(blockId)
  const Block = useRegistry(serializedBlock.type)
  const screen = useScreen()
  const key = `${serializedBlock.key || serializedBlock.id}-${serializedBlock.type}`

  const makeProps = (props: object) => {
    const modified = { ...props, blockId, children: undefined }
    if (isPropsWithClassName(modified) && modified.className) {
      if (typeof modified.className === 'object') {
        modified.className = modified.className[screen] || modified.className.default
      }
    }

    return modified
  }

  useInjectStyles(serializedBlock.style)

  if (isNestedBlock(serializedBlock)) {
    const { children } = serializedBlock.props

    return (
      <Block {...makeProps(serializedBlock.props)} key={key}>
        {children.map((childId) => (
          <RenderBlock key={view.blocks[childId]?.key || childId} blockId={childId} />
        ))}
      </Block>
    )
  }

  return <Block {...makeProps(serializedBlock.props)} key={key} />
})

RenderBlock.displayName = 'RenderBlock'

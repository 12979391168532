export enum FsQueryOrderType {
  ASC = 'asc',
  DESC = 'desc',
}

export enum FsQueryOrderField {
  MODIFIED = 'modified',
  NAME = 'name',
}

export enum FsFolderOrderField {
  TITLE = 'title',
}

export enum ViewDisplayMode {
  LIST = 'list',
  GRID = 'grid',
}

export enum SimpleTrialPlaceType {
  USER_INPUT = 'user_input',
  CONDITION_IG_ACCOUNT_FOLLOWER = 'condition_ig_account_follower',
  IG_CGT_ALL_POSTS = 'ig_cgt_all_posts',
  IG_CGT_NEXT_POST_OR_REEL = 'IG_CGT_NEXT_POST_OR_REEL',
  AUTO_LIKE_TRIGGER = 'auto_like_trigger',
  INSTAGRAM_DELAY = 'instagram_delay',
  WIDGETS_LIMIT = 'widgets_limit',
  KEYWORDS_TRIGGERS_LIMIT = 'keywords_triggers_limit',
  STORY_REPLY_TRIGGER_LIMIT = 'story_reply_trigger_limit',
  FOLLOW_UP = 'follow_up',
  AUTOMATICALLY_LIKE_REPLIES = 'automatically_like_replies',
}

export const VIEW_DISPLAY_MODE_LS_KEY = 'cms_display_mode'

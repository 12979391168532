import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { appListenerMiddleware, createAppAsyncThunk } from 'reduxTyped'

import { parseBackendProtoTriggers } from 'apps/aiAssistant/model/protoTriggerAdapter'
import { AiAssistantAnalytics } from 'apps/aiAssistantNew/lib/analytics'
import { createTextMessage } from 'apps/aiAssistantNew/store/lib/createTextMessage'
import { getSessionId } from 'apps/aiAssistantNew/store/lib/getSessionId'
import {
  AiAssistantSocketEvent,
  AiAssistantState,
  AssistantChatMessage,
  AssistantMessageAuthor,
  AssistantMode,
  AssistantTextMessage,
  GenerationStateMessage,
  NewAiAssistantState,
  QueueTask,
} from 'apps/aiAssistantNew/store/types'
import { handleCatch } from 'shared/api/lib/errors/handlers'
import { AiFlowGeneratorApi } from 'shared/api/requests/aiFlowGenerator'
import errorTrackingService from 'utils/services/errorTrackingService'
import { userNotificationsListener } from 'utils/services/notificationsService'

export const NewAssistantInitialState: NewAiAssistantState = {
  mode: AssistantMode.SHOWCASE,
  sessionId: null,
  flowId: null,
  messages: [],
  draft: {
    id: null,
    data: null,
  },
  tasksQueue: [],
  actionableMessageIds: [],
  isGenerationFailed: false,
}

const NAMESPACE = 'newAiAssistant'

const initThreadFx = createAppAsyncThunk(
  `${NAMESPACE}/initThreadFx`,
  async ({ sessionId }: { sessionId: string }, { dispatch, getState }) => {
    const flowId = AiAssistantSelectors.getFlowId(getState()) as string

    dispatch(actions.sessionIdUpdated(sessionId))

    return await AiFlowGeneratorApi.startChat({
      body: { session_id: sessionId, flow_ns: flowId },
    })
  },
  {
    condition: (_, { getState }) => Boolean(AiAssistantSelectors.getFlowId(getState())),
  },
)

const sendMessageFx = createAppAsyncThunk(
  `${NAMESPACE}/sendMessageFx`,
  async ({ message }: { message: string }, { dispatch, getState }) => {
    const sessionId = AiAssistantSelectors.getSessionId(getState()) as string
    const flowId = AiAssistantSelectors.getFlowId(getState()) as string

    dispatch(
      AiAssistantActions.messageAppended(
        createTextMessage({
          author: AssistantMessageAuthor.USER,
          data: { text: message },
        }),
      ),
    )

    await AiFlowGeneratorApi.sendChatEvent({
      body: {
        session_id: sessionId,
        flow_ns: flowId,
        input: message,
      },
    })
  },
  {
    condition: (_, { getState }) =>
      AiAssistantSelectors.getSessionId(getState()) !== null &&
      AiAssistantSelectors.getFlowId(getState()) !== null,
  },
)

const loadDraftFx = createAppAsyncThunk(
  `${NAMESPACE}/loadDraftFx`,
  async ({ flowDraftId }: { flowDraftId: number }, { getState }) => {
    const sessionId = AiAssistantSelectors.getSessionId(getState()) as string

    const response = await AiFlowGeneratorApi.getFlowDraft({
      body: {
        flow_draft_id: flowDraftId,
        session_id: sessionId,
      },
    })

    return response.data.flow_draft
  },
  {
    condition: (_, { getState }) => AiAssistantSelectors.getSessionId(getState()) !== null,
  },
)

const applyDraftFx = createAppAsyncThunk(
  `${NAMESPACE}/applyDraftFx`,
  async (_, { getState, rejectWithValue }) => {
    const state = getState()

    const sessionId = AiAssistantSelectors.getSessionId(state) as string
    const flowId = AiAssistantSelectors.getFlowId(state) as string
    const draftId = AiAssistantSelectors.getDraftId(state) as number

    try {
      await AiFlowGeneratorApi.applyFlow({
        body: {
          session_id: sessionId,
          flow_ns: flowId,
          flow_draft_id: draftId,
        },
      })
    } catch (error) {
      handleCatch(error)

      throw rejectWithValue()
    }
  },
  {
    condition: (_, { getState }) => {
      const state = getState()
      return (
        AiAssistantSelectors.getSessionId(state) !== null &&
        AiAssistantSelectors.getFlowId(state) !== null &&
        AiAssistantSelectors.getDraftId(state) !== null
      )
    },
  },
)

const restartThreadFx = createAppAsyncThunk(
  `${NAMESPACE}/restartThreadFx`,
  async (_, { dispatch, getState }) => {
    const flowId = AiAssistantSelectors.getFlowId(getState()) as string

    const newSessionId = getSessionId()

    AiAssistantAnalytics.logRestartThread({
      flowId,
      sessionId: newSessionId,
    })

    return dispatch(initThreadFx({ sessionId: newSessionId }))
  },
  {
    condition: (_, { getState }) => AiAssistantSelectors.getFlowId(getState()) !== null,
  },
)

const { reducer, actions } = createSlice({
  initialState: NewAssistantInitialState,
  name: NAMESPACE,
  reducers: {
    stateReset: () => NewAssistantInitialState,

    messageAppended: (state, action: PayloadAction<AssistantTextMessage>) => {
      if (action.payload.author === AssistantMessageAuthor.USER) {
        state.actionableMessageIds = []
      }

      if (
        action.payload.author === AssistantMessageAuthor.ASSISTANT &&
        action.payload.data.buttons.length > 0
      ) {
        state.actionableMessageIds.push(action.payload.id)
      }

      state.messages.push(action.payload)
    },

    sessionIdUpdated: (state, action: PayloadAction<string>) => {
      state.sessionId = action.payload
    },

    flowIdUpdated: (state, action: PayloadAction<string>) => {
      state.flowId = action.payload
    },

    modeUpdated: (state, action: PayloadAction<AssistantMode>) => {
      state.mode = action.payload
    },
    generationFailed: (state) => {
      state.isGenerationFailed = true
    },

    taskAddedToQueue: (state, action: PayloadAction<QueueTask>) => {
      state.tasksQueue.push(action.payload)
    },
    taskRemovedFromQueue: (state, action: PayloadAction<QueueTask>) => {
      state.tasksQueue = state.tasksQueue.filter((task) => task !== action.payload)
    },
  },
  extraReducers: (builder) => {
    builder.addCase(restartThreadFx.pending, (state) => {
      return {
        ...NewAssistantInitialState,
        mode: AssistantMode.CHAT,
        flowId: state.flowId,
      }
    })

    builder.addCase(loadDraftFx.pending, (state, action) => {
      state.draft.id = action.meta.arg.flowDraftId
    })
    builder.addCase(loadDraftFx.fulfilled, (state, action) => {
      state.draft.data = {
        tags: action.payload.tags,
        fields: action.payload.fields,
        draft: action.payload.draft,
        triggers: parseBackendProtoTriggers(action.payload.triggers),
      }
    })

    builder.addCase(applyDraftFx.fulfilled, (state) => {
      state.draft.id = null
      state.draft.data = null

      state.mode = AssistantMode.RATE
    })
  },
})

export const AiAssistantActions = {
  sendMessageFx,
  applyDraftFx,
  restartThreadFx,

  messageAppended: actions.messageAppended,
  stateReset: actions.stateReset,
  modeUpdated: actions.modeUpdated,
  flowIdUpdated: actions.flowIdUpdated,
}

const getState = (state: RootState) => state.newAiAssistant

export const AiAssistantSelectors = {
  getState,
  getMode: (state: RootState) => getState(state).mode,
  getIsGenerationFailed: (state: RootState) => getState(state).isGenerationFailed,
  getMessages: (state: RootState) => getState(state).messages,
  getMessagesCount: (state: RootState) => getState(state).messages.length,
  getUserSentMessage: (state: RootState) =>
    Boolean(
      getState(state).messages.find((message) => message.author === AssistantMessageAuthor.USER),
    ),
  getHasOngoingConversation: (state: RootState) => getState(state).messages.length > 0,
  getIsRetryAvailable: (state: RootState) =>
    getState(state).sessionId !== null &&
    getState(state).mode === AssistantMode.CHAT &&
    getState(state).messages.find((message) => message.author === AssistantMessageAuthor.USER) !==
      undefined,
  getIsBusy: (state: RootState) => getState(state).tasksQueue.length > 0,
  getSessionId: (state: RootState) => getState(state).sessionId,
  getFlowId: (state: RootState) => getState(state).flowId,
  getHasDraft: (state: RootState) => Boolean(getState(state).draft.data),
  getDraft: (state: RootState) => getState(state).draft.data,
  getDraftId: (state: RootState) => getState(state).draft.id,
  getIsActionableMessage: (state: RootState, id: string) =>
    getState(state).actionableMessageIds.includes(id),
}

export const NewAiAssistantReducer = reducer

appListenerMiddleware.startListening({
  actionCreator: actions.modeUpdated,
  effect: function initThreadOnOpen(action, { getState, dispatch }) {
    const state = getState()
    const flowId = AiAssistantSelectors.getFlowId(state)

    if (
      action.payload !== AssistantMode.CHAT ||
      AiAssistantSelectors.getSessionId(state) ||
      AiAssistantSelectors.getIsBusy(state) ||
      !flowId
    ) {
      return
    }

    const sessionId = getSessionId()

    AiAssistantAnalytics.logOpen({
      flowId,
      sessionId,
    })

    dispatch(initThreadFx({ sessionId }))
  },
})

appListenerMiddleware.startListening({
  actionCreator: initThreadFx.pending,
  effect: (_, { dispatch }) => {
    dispatch(actions.taskAddedToQueue(QueueTask.WAIT_FOR_INITIALIZATION))
    dispatch(actions.taskAddedToQueue(QueueTask.WAIT_FOR_MESSAGE))
  },
})

appListenerMiddleware.startListening({
  predicate: (action) =>
    [initThreadFx.fulfilled.type, initThreadFx.rejected.type].includes(action.type),
  effect: (_, { dispatch }) => {
    dispatch(actions.taskRemovedFromQueue(QueueTask.WAIT_FOR_INITIALIZATION))
  },
})

appListenerMiddleware.startListening({
  actionCreator: sendMessageFx.pending,
  effect: (_, { dispatch }) => {
    dispatch(actions.taskAddedToQueue(QueueTask.WAIT_FOR_MESSAGE))
  },
})

appListenerMiddleware.startListening({
  actionCreator: actions.messageAppended,
  effect: (action, { dispatch }) => {
    if (action.payload.author === AssistantMessageAuthor.ASSISTANT) {
      dispatch(actions.taskRemovedFromQueue(QueueTask.WAIT_FOR_MESSAGE))
    }
  },
})

userNotificationsListener.on(
  AiAssistantSocketEvent.MESSAGE_RECEIVED,
  (data: { message: AssistantChatMessage; session_id: string }, dispatch, getState) => {
    if (AiAssistantSelectors.getSessionId(getState() as RootState) === data.session_id) {
      dispatch(AiAssistantActions.messageAppended(data.message))
    }
  },
)

userNotificationsListener.on(
  AiAssistantSocketEvent.STATE_CHANGED,
  async (data: GenerationStateMessage, dispatch, getState) => {
    if (AiAssistantSelectors.getSessionId(getState() as RootState) !== data.session_id) {
      return
    }

    if (data.state === AiAssistantState.FLOW_GENERATION_STARTED) {
      dispatch(actions.taskAddedToQueue(QueueTask.WAIT_FOR_GENERATION))
      return
    }

    if (data.state === AiAssistantState.FLOW_GENERATION_COMPLETED) {
      await dispatch(loadDraftFx({ flowDraftId: data.flow_draft_id }))
      dispatch(actions.taskRemovedFromQueue(QueueTask.WAIT_FOR_GENERATION))
      dispatch(actions.modeUpdated(AssistantMode.REVIEW))
      return
    }

    if (data.state === AiAssistantState.FLOW_GENERATION_FAILED) {
      dispatch(actions.generationFailed())
      return
    }

    errorTrackingService.logError('Unexpected generation state', {
      scope: 'ai',
      section: 'ai_flow_generator',
      extra: { data },
    })
  },
)

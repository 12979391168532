import React from 'react'
import isEmpty from 'lodash/isEmpty'
import { l } from '@manychat/manyui'

import * as optInActions from 'apps/optIn/actions/optInActions'
import PreviewSuccessAlert, { IPreviewData } from 'apps/optIn/components/PreviewSuccessAlert'
import { mergeParsed } from 'common/builder/actions/mergeParsed'
import { updateBuilderState } from 'common/builder/actions/updateBuilderState'
import { BroadcastBuilderTabType } from 'common/builder/constants/BroadcastBuilderTabType'
import batchAdapter from 'common/builder/models/Batch/adapter'
import builderSelectors from 'common/builder/selectors/builder'
import { alert } from 'common/core'
import * as pageActions from 'common/core/actions/pageActions'
import { openConnectChannelModal, openIgPreviewModal } from 'common/core/actions/uiActions'
import { ChannelType, ConnectChannelModalSource } from 'common/core/constants/ChannelType'
import { IAsyncThunkAction, IAsyncThunkActionWithReturnValue } from 'common/core/interfaces/actions'
import { getBotTimezone, isSkipPreviewOnboardingModal } from 'common/core/selectors/appSelectors'
import { IFullFlow } from 'common/flow/flowInterfaces'
import * as flowSelectors from 'common/flow/selectors/flowSelectors'
import * as API from 'constants/API'
import mcApi from 'utils/api/mcApi'
import { isRequestError } from 'utils/api/mcApi/RequestError'
import { analyticsService } from 'utils/services/analytics'
import { alertWithHotjarEventFactory } from 'utils/services/hotjar'
import { expandURL, linkURL } from 'utils/url'

import * as builderActions from './builderActions'

const alertWithHotjarEvent = alertWithHotjarEventFactory('flow_builder_error')

export const getContent = (
  contentId: string | number,
  flowId: string,
  loadStats: boolean,
): IAsyncThunkAction => {
  return async (dispatch, getState) => {
    const reqName = loadStats ? 'getContentWithStats' : 'getContent'
    const flow = flowSelectors.getById(getState(), flowId) as IFullFlow

    const payload: {
      content_id: string | number
      content_holder?: string
      ns?: string
    } = {
      content_id: contentId,
    }
    if (flow?.chId) {
      payload.content_holder = flow.chId
    }
    if (loadStats) {
      payload.ns = flowId
    }

    // eslint-disable-next-line
    // @ts-expect-error
    const { content } = await dispatch({
      $fetch: expandURL(API.content.endpoints[reqName], { ...payload }),
    })

    return content
  }
}

/**
 * @param builderId
 * @param contentId
 */
export function provideContent(builderId: string, contentId: string): IAsyncThunkAction {
  return async (dispatch, getState) => {
    const state = getState()
    const builderState = builderSelectors.builderState.getById(state, builderId)

    const { flow, loadStats } = builderState
    const data = await dispatch(getContent(contentId, flow, loadStats))
    const nodeIdMap = builderSelectors.node.getNodeIdMap(getState(), builderId)
    const botTimeZone = getBotTimezone(getState())
    const parsed = batchAdapter.parseBatch([data], {
      botTimeZone,
      nodeIdMap,
      disableUnexpectedTargetOidTracking: true,
    })
    dispatch(mergeParsed(builderId, parsed))
  }
}

export const sendPreview = (
  builderId: string,
  options: { emailRootId?: string } = {},
): IAsyncThunkActionWithReturnValue<boolean> => {
  return async (dispatch, getState) => {
    const state = getState()

    const batch = builderSelectors.builderState.getBatch(state, builderId, {
      emailRootId: options.emailRootId,
    })

    try {
      const data = await mcApi.post<IPreviewData>(API.content.endpoints.sendPreview, {
        body: {
          batch,
          batch_version: 'v2',
          new_format: 1,
        },
      })

      const rootNode = builderSelectors.builderState.getRootNode(state, builderId)
      const isPreviewModalSkipped = isSkipPreviewOnboardingModal(state)
      const igPreviewKeyword = data?.channels?.instagram?.preview_keyword

      if (!isPreviewModalSkipped && igPreviewKeyword) {
        dispatch(openIgPreviewModal(igPreviewKeyword))
        analyticsService.sendEvent('PREVIEW_INSTAGRAM_MODAL.SHOWN', {
          isFirstTime: true,
        })
      } else {
        const hasProcessPreivewErrors = await dispatch(processPreviewErrors(data))
        if (!hasProcessPreivewErrors && rootNode) {
          alert('', undefined, {
            isTemporary: false,
            content: <PreviewSuccessAlert data={data} rootNodeType={rootNode.nodeType} />,
          })
        }
      }

      return true
    } catch (err) {
      if (isRequestError(err) && err.response) {
        const errorResponse = err.response
        const contentNodeErrors = errorResponse?.content_node_errors

        if (!isEmpty(contentNodeErrors)) {
          dispatch(updateBuilderState(builderId, { sidebarHidden: false }))
          dispatch(builderActions.showServerErrors(builderId, contentNodeErrors))

          if (state.app.activeTab === BroadcastBuilderTabType.SETTINGS) {
            try {
              dispatch(pageActions.setPageTab(BroadcastBuilderTabType.BROADCAST))
            } catch (err) {}
          }
        } else if (Array.isArray(errorResponse.errors)) {
          const errorMessage = errorResponse?.errors?.join(', ')
          alertWithHotjarEvent(errorMessage, 'danger')
        } else {
          // eslint-disable-next-line
          // @ts-expect-error
          dispatch(processPreviewErrors(errorResponse?.errors))
        }
      } else {
        alertWithHotjarEvent(
          l.translate('Something went wrong on the server. Please try again.'),
          'danger',
        )
      }

      return false
    }
  }
}

export function processPreviewErrors(
  previewErrors: IPreviewData,
): IAsyncThunkActionWithReturnValue<boolean> {
  return async (dispatch) => {
    const { channels, subscriber } = previewErrors

    const notConnectedChannels: ChannelType[] = []
    const noOptInChannels: string[] = []

    const channelsKeys = Object.keys(channels) as Array<
      | ChannelType.FB
      | ChannelType.EMAIL
      | ChannelType.WHATSAPP
      | ChannelType.SMS
      | ChannelType.TELEGRAM
    >

    channelsKeys.forEach((channel) => {
      const channelData = channels[channel]

      if (!channelData?.connected) {
        notConnectedChannels.push(channel)
      }
      if (!channelData?.has_opt_in) {
        noOptInChannels.push(channel)
      }
    })

    const notAvailableSmsCountryCode = channels.sms?.available_country_code === false
    const noEmailAddress = channels.email?.has_address === false
    const whatsAppLink = channels.whatsapp?.link_to_whatsapp_with_preview_ref
    const whatsAppUpdateLastInteractionRequired =
      channels.whatsapp?.update_last_interaction_required === true

    const telegramLink = channels.telegram?.link_to_telegram
    const telegramName = channels.telegram?.bot_name

    const smsOptInRequired = noOptInChannels.includes(ChannelType.SMS)
    const emailOptInRequired = noOptInChannels.includes(ChannelType.EMAIL)
    const whatsAppOptInRequired = noOptInChannels.includes(ChannelType.WHATSAPP)
    const telegramOptInRequired = noOptInChannels.includes(ChannelType.TELEGRAM)

    if (notConnectedChannels.length) {
      dispatch(
        openConnectChannelModal(notConnectedChannels, ConnectChannelModalSource.BUILDER_OPT_INS),
      )

      return true
    }

    if (smsOptInRequired || emailOptInRequired) {
      dispatch(
        optInActions.applySmsEmailOptInRequired(
          smsOptInRequired,
          emailOptInRequired,
          subscriber.email,
          subscriber.id,
        ),
      )

      return true
    }

    if (whatsAppOptInRequired || whatsAppUpdateLastInteractionRequired) {
      dispatch(
        optInActions.applyWhatsAppOptInRequired(
          whatsAppLink,
          whatsAppUpdateLastInteractionRequired,
        ),
      )

      return true
    }

    if (telegramOptInRequired) {
      dispatch(optInActions.applyTelegramOptInRequired(telegramLink, telegramName))
      return true
    }

    if (notAvailableSmsCountryCode) {
      const message = (
        <>
          {l.translate(
            "Preview can't send to<br />{phone} as it is out of <br />your Business Phone Delivery.<br />",
            {
              phone: channels.sms?.phone_number,
            },
          )}
          {l.translate(
            '<a target="_blank" class="m-r-xxs" style="text-decoration: underline" rel="noreferrer" href="{link}">Change my phone number</a>',
            {
              link: linkURL(`subscribers/${subscriber.id}`),
            },
          )}
        </>
      )
      alertWithHotjarEvent(message, 'danger')

      return true
    }

    if (noEmailAddress) {
      const message = (
        <>
          {l.translate(
            'Preview can’t send to {email}.<br />You need to <a class="m-r-xxs" rel="noreferrer" style="text-decoration: underline" target="_blank" href="{link}">setup contact infornmation</a> first.',
            {
              email: channels.email?.email,
              link: linkURL('settings#email'),
            },
          )}
        </>
      )
      alertWithHotjarEvent(message, 'danger')

      return true
    }

    return false
  }
}

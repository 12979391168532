import dot from 'dot-prop-immutable'

import * as atypes from 'common/actions/integrations/common/constants/IntegrationsReduxActionTypes'
import { MailChimpConfig } from 'common/actions/integrations/MailChimp/model/constants'

const initialState = {
  identityField: {
    value: null,
    option: null,
  },
  currentList: '',
  lists: null,
  isFetchingLists: false,
  isDisconnecting: false,
}

export default function integrationsReducer(state = initialState, action) {
  switch (action.type) {
    case atypes.MAILCHIMP_CLEAR_ACCOUNT_LINK_FIELD:
    case atypes.MAILCHIMP_SET_ACCOUNT_LINK_FIELD: {
      return dot.set(state, MailChimpConfig.type, action.data.mailchimp)
    }
    case atypes.MAILCHIMP_LISTS_FETCH_REQUEST: {
      return { ...state, isFetchingLists: true }
    }

    case atypes.MAILCHIMP_LISTS_FETCH_ERROR: {
      return { ...state, isFetchingLists: false }
    }

    case atypes.MAILCHIMP_LISTS_FETCH_RESPONSE: {
      const { data } = action
      return { ...state, lists: data.lists, isFetchingLists: false }
    }

    case atypes.MAILCHIMP_SET_LIST: {
      const { data } = action
      return { ...state, currentList: data }
    }

    case atypes.MAILCHIMP_SET_IDENTITY_FIELD: {
      const { data } = action

      return {
        ...state,
        identityField: data,
      }
    }

    case atypes.INTEGRATION_RESET: {
      return {
        ...state,
        ...initialState,
      }
    }

    case atypes.INTEGRATION_DISCONNECT_REQUEST: {
      return { ...state, isDisconnecting: true }
    }
    case atypes.INTEGRATION_DISCONNECT_RESPONSE: {
      return { ...state, isDisconnecting: false }
    }
  }

  return state
}

import React from 'react'
import cx from 'classnames'
import { Outlet } from 'react-router-dom'

import { ManyChatLogo } from 'components/ManyChatLogo/ManyChatLogo'

import cm from './ConfirmationLayout.module.css'

export const ConfirmationLayout = () => {
  return (
    <div className="d-flex flex-col align-center p-t-xl p-x">
      <div className="d-flex align-center m-y-md">
        <a href="/" className="d-flex align-center">
          <ManyChatLogo />
        </a>
      </div>
      <div className={cx(cm.card, 'panel panel-white panel-shadow p-a md-p-a-xl text-center')}>
        <Outlet />
      </div>
    </div>
  )
}

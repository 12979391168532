import { z } from 'zod'

import { ConditionTypes, KeywordStatus } from 'apps/keywords/models/KeywordRule/constants'
import { ChannelType } from 'common/core/constants/ChannelType'
import { ProErrorSchema } from 'shared/api/common/schemas/errors'

export const KeywordRuleSchema = z.object({
  condition: z.nativeEnum(ConditionTypes),
  keywords: z.array(z.string()),
})

export const KeywordSchema = z.object({
  channel: z.nativeEnum(ChannelType),
  rule_id: z.number(),
  sort: z.number(),
  status: z.nativeEnum(KeywordStatus),
  ts_created: z.number(),
  ts_updated: z.number(),
  keyword_rules: z.array(KeywordRuleSchema),
  is_reserved: z.boolean(),
  runs: z.number().nullable().optional(),
  ctr: z.number().nullable().optional(),
})

export const KeywordInitialSchema = z.object({
  sort: z.number(),
  id: z.undefined().or(z.number()),
  rule_id: z.undefined().or(z.number()),
  fetching: z.boolean(),
  channel: z.nativeEnum(ChannelType).optional(),
  keyword_rules: z.array(KeywordRuleSchema),
})

export const KeywordDraftSchema = z.object({
  sort: z.number(),
  id: z.number().nullable(),
  rule_id: z.number().nullable(),
  fetching: z.boolean(),
  channel: z.nativeEnum(ChannelType).optional().nullable(),
  keyword_rules: z.array(KeywordRuleSchema),
})

export const KeywordsSchemas = {
  setFlow: {
    response: z.object({
      rule: KeywordSchema,
    }),
    request: z.object({
      rule_id: z.number(),
      flow_ns: z.string(),
    }),
    query: z.undefined(),
    path: z.undefined(),
  },
  setStatus: {
    response: z.object({
      rule: KeywordSchema,
    }),
    request: z.object({
      rule_id: z.number(),
      status: z.string(),
    }),
    query: z.undefined(),
    path: z.undefined(),
    errors: {
      ProErrorSchema: ProErrorSchema,
    },
  },
  getList: {
    response: z.object({
      rules: z.array(KeywordSchema),
    }),
    request: z.object({
      channel: z.nativeEnum(ChannelType),
      limit: z.number().optional(),
      offset: z.number().optional(),
    }),
    query: z.object({
      repeatHash: z.number(),
    }),
    path: z.undefined(),
  },
  getItem: {
    response: z.object({
      rule: KeywordSchema,
    }),
    request: z.undefined(),
    query: z.object({
      rule_id: z.number(),
    }),
    path: z.undefined(),
  },
  create: {
    response: z.object({
      rule: KeywordSchema,
    }),
    request: KeywordInitialSchema,
    query: z.object({
      client_id: z.string(),
    }),
    path: z.undefined(),
  },
  update: {
    response: z.object({
      rule: KeywordSchema,
    }),
    request: KeywordSchema.or(KeywordInitialSchema),
    query: z.object({
      client_id: z.string(),
    }),
    path: z.undefined(),
  },
  sort: {
    response: z.object({
      ids: z.array(z.number()),
    }),
    request: z.object({ ids: z.array(z.number()) }),
    query: z.undefined(),
    path: z.undefined(),
  },
  delete: {
    response: z.undefined(),
    request: z.undefined(),
    query: z.object({
      rule_id: z.number(),
      client_id: z.string(),
    }),
    path: z.undefined(),
  },
}

export type Keyword = z.infer<typeof KeywordSchema>
export type KeywordDraft = z.infer<typeof KeywordDraftSchema>

import * as Sentry from '@sentry/react'
import { createBrowserRouter } from 'react-router-dom'

import { AccountRoutes, AccountWithIdScopeRoutes } from 'apps/account/pages/account.routes'
import { AdsRoutes, AdsRoutesNewAccount } from 'apps/ads/pages/ads.routes'
import { AgencyRoutes } from 'apps/agency/pages/agency.routes'
import { AnalyticsRoutes } from 'apps/analytics/pages/analytics.routes'
import { ApplicationInstallationRoutes } from 'apps/applicationInstallation/pages/applicationInstallation.routes'
import {
  ApplicationsRoutes,
  ApplicationsWithIdScopeRoutes,
} from 'apps/applications/pages/applications.routes'
import { ChatRoutes } from 'apps/chat/pages/chat.routes'
import {
  DashboardRoutes,
  InsightsRoutes,
  BillingRoutes,
} from 'apps/dashboard/pages/dashboard.routes'
import { DefaultReplayRoutes } from 'apps/defaultReply/pages/defaultReplay.routes'
import { FlowPlayerRoutes } from 'apps/flowPlayer/pages/flowPlayer.routes'
import { GrowToolsWidgetRoutes } from 'apps/growthTools/pages/growthTools.routes'
import { IceBreakersRoutes } from 'apps/iceBreakers/pages/iceBreakers.routes'
import { IntegrationsRoutes } from 'apps/integrations/integrations.routes'
import { KeywordsRoutes } from 'apps/keywords/pages/keywords.routes'
import { MenuRoutes } from 'apps/menu/pages/menu.routes'
import { PackagedUseCaseRoutes } from 'apps/packagedUseCase/pages/packagedUseCase.routes'
import { PixelRoutes } from 'apps/pixel/pages/pixel.routes'
import { PostingRoutes } from 'apps/posting/pages/postings.routes'
import { ProfileRoutes } from 'apps/profile/pages/profile.routes'
import { RegistrationRoutes } from 'apps/registration/registration.routes'
import { SigninProfileRoutes, SigninRoutes } from 'apps/signin/pages/signin.routes'
import { SponsoredMessagesRoutes } from 'apps/sponsoredMessages/pages/sponsoredMessages.routes'
import { StoryMentionRoutes } from 'apps/storyMentionReply/pages/storyMentionReply.routes'
import { SuggestedQuestionsRoutes } from 'apps/suggestedQuestions/suggestedQuestions.routes'
import { SupportRoutes } from 'apps/support/pages/support.routes'
import {
  TemplatesProfileScopeRoutes,
  TemplatesRoutes,
  TemplatesSettingsScopeRoutes,
} from 'apps/templates/pages/templates.routes'
import { SetupTikTokIntegrationRoutes } from 'apps/tiktok/pages/setupTikTokIntegrationRoutes'
import { TriggerRulesRoutes } from 'apps/triggerRules/pages/triggerRules.routes'
import { WebViewRoutes } from 'apps/webView/pages/webView.routes'
import { WelcomeMessageRoutes } from 'apps/welcomeMessage/pages/welcomeMessage.routes'
import { WhatsAppRoutes } from 'apps/whatsApp/pages/whatsapp.routes'
import { AudienceRoutes } from 'common/audience/pages/audience.routes'
import { CardVerificationRoutes } from 'common/billing/components/CardVerificationPage/cardVerificationPage.routes'
import { NextActionsRequiredRoutes } from 'common/billing/components/NextActionsRequiredPage/nextActionsRequired.routes'
import { BuilderRoutes } from 'common/builder/pages/builder.routes'
import { CmsRoutes } from 'common/cms/pages/cms.routes'
import { BusinessEmailConfirmationRoutes } from 'common/core/pages/businessEmailConfirmation.routes'
import { EmailConfirmationRoutes } from 'common/core/pages/emailConfirmation.routes'
import { ShareFlowRoutes } from 'common/flow/pages/flow.routes'
import { SequencesRoutes } from 'common/sequences/pages/sequences.routes'
import { RecurringNotificationTopicsRoutes } from 'common/settings/pages/recurringNotificationTopics.routes'
import { SettingsRoutes } from 'common/settings/pages/settings.routes'
import { SystemKeywordsRoutes } from 'common/settings/pages/systemKeywords.routes'
import { SmartSegmentsRoutes } from 'common/smartSegments/pages/smartSegments.routes'
import { UserRolesRoutes } from 'common/userRoles/pages/userRoles.routes'
import {
  makeRoutes,
  makeUseTypedNavigate,
  makeUseTypedParams,
  makeRouteLinkBuilder,
} from 'utils/routeLink/makeRouteObject'

import { AccountScopeLayout } from './components/AccountScopeLayout/AccountScopeLayout'
import { Layout } from './components/Layout/Layout'
import { LayoutWithSidebar } from './components/LayoutWithSidebar/LayoutWithSidebar'
import { RootLayout } from './components/RootLayout/RootLayout'
import { RouterErrorBoundary } from './components/RouterErrorBoundary/RouterErrorBoundary'
import { getHandle } from './utils/getHandle'

const RoutesData = makeRoutes([
  {
    path: '/',
    Component: RootLayout,
    ErrorBoundary: RouterErrorBoundary,
    children: [
      {
        path: ':acc_id',
        Component: AccountScopeLayout,
        children: [
          {
            path: 'automation',
            Component: LayoutWithSidebar,
            children: [
              ...DefaultReplayRoutes,
              ...IceBreakersRoutes,
              ...KeywordsRoutes,
              ...MenuRoutes,
              ...StoryMentionRoutes,
              ...WelcomeMessageRoutes,
              ...SystemKeywordsRoutes,
              ...SuggestedQuestionsRoutes,
            ],
          },
          {
            path: 'settings',
            handle: getHandle('settings'),
            alias: 'settings',
            Component: LayoutWithSidebar,
            children: [
              ...SettingsRoutes,
              ...ApplicationsWithIdScopeRoutes,
              ...TemplatesSettingsScopeRoutes,
              ...WhatsAppRoutes,
            ],
          },
          {
            path: 'billing',
            handle: getHandle('billing'),
            Component: Layout,
            children: [...CardVerificationRoutes, ...NextActionsRequiredRoutes],
          },
          ...DashboardRoutes,
          ...AudienceRoutes,
          ...CmsRoutes,
          ...ChatRoutes,
          ...AccountWithIdScopeRoutes,
          ...AnalyticsRoutes,
          ...GrowToolsWidgetRoutes,
          ...PackagedUseCaseRoutes,
          ...PostingRoutes,
          ...PixelRoutes,
          ...SponsoredMessagesRoutes,
          ...SupportRoutes,
          ...TriggerRulesRoutes,
          ...WebViewRoutes,
          ...BuilderRoutes,
          ...ShareFlowRoutes,
          ...SequencesRoutes,
          ...RecurringNotificationTopicsRoutes,
          ...AdsRoutes,
          ...AdsRoutesNewAccount,
          ...SmartSegmentsRoutes,
          ...InsightsRoutes,
          ...BillingRoutes,
          ...IntegrationsRoutes,
          ...SetupTikTokIntegrationRoutes,
        ],
      },
      {
        path: 'profile',
        handle: getHandle('profile'),
        children: [
          ...ProfileRoutes,
          ...ApplicationsRoutes,
          ...SigninProfileRoutes,
          ...TemplatesProfileScopeRoutes,
          ...EmailConfirmationRoutes,
        ],
      },
      ...AccountRoutes,
      ...AgencyRoutes,
      ...ApplicationInstallationRoutes,
      ...FlowPlayerRoutes,
      ...SigninRoutes,
      ...SupportRoutes,
      ...TemplatesRoutes,
      ...BusinessEmailConfirmationRoutes,
      ...UserRolesRoutes,
      ...RegistrationRoutes,
      ...SetupTikTokIntegrationRoutes,
    ],
  },
] as const)

const sentryCreateBrowserRouter = Sentry.wrapCreateBrowserRouter(createBrowserRouter)

export const router = sentryCreateBrowserRouter(RoutesData.routeObjects)
export const useTypedNavigate = makeUseTypedNavigate(RoutesData.routes)
export const useTypedParams = makeUseTypedParams(RoutesData.routes)
export const linkBuilder = makeRouteLinkBuilder(RoutesData.routes)

import { makeDisconnectionChannelSlice } from 'apps/whatsApp/disconnectChannel/redux/reducer'

const {
  reducer,
  getDisconnectionChannelState,
  toggleDisconnectionConditionsChannel,
  changeConfirmationTextStatus,
  resetConditionsDisconnection,
} = makeDisconnectionChannelSlice(
  (state: Pick<RootState, 'whatsApp'>) => state.whatsApp.disconnectChannel,
)

export default reducer
export {
  getDisconnectionChannelState,
  resetConditionsDisconnection,
  toggleDisconnectionConditionsChannel,
  changeConfirmationTextStatus,
}

import { createSlice } from '@reduxjs/toolkit'
import { createAppAsyncThunk } from 'reduxTyped'
import { l } from '@manychat/manyui'

import { contentManagementActions } from 'apps/cms/store'
import { WhatsAppCartReplyState } from 'apps/whatsApp/interfaces/whatsAppCartReply'
import { alert } from 'common/core'
import { handleCatch } from 'shared/api/lib/errors/handlers'
import { cartReplyApi } from 'shared/api/requests/cartReply'
import { CartReply, CartReplyType } from 'shared/api/requests/cartReply/schemas'
import { accountNotificationsListener } from 'utils/services/notificationsService'
import { INotificationServiceData } from 'utils/services/notificationsService/notificationsServiceTypes'

import { cartReplyAdapter } from '../adapters/cartReplyAdapter'

export const cartReplyActionTypes = {
  CART_REPLY_LOAD_RESPONSE: 'CART_REPLY_LOAD_RESPONSE',
  CART_REPLY_UPDATE_RESPONSE: 'CART_REPLY_UPDATE_RESPONSE',
  CART_REPLY_UPDATED_NOTIFICATION: 'CART_REPLY_UPDATED_NOTIFICATION',
}

export const loadCartReply = createAppAsyncThunk<CartReply | null>(
  'whatsapp/getCartReplyData',
  async (_, { dispatch }) => {
    try {
      const { data } = await cartReplyApi.getData()
      dispatch({ type: cartReplyActionTypes.CART_REPLY_LOAD_RESPONSE, data: data })
      return data
    } catch (error) {
      handleCatch(error)
      return null
    }
  },
)

export const updateCartReplyEnabled = createAppAsyncThunk<CartReply | null, { enabled: boolean }>(
  'whatsapp/updateCartReplyEnabled',
  async ({ enabled }) => {
    try {
      const { data } = await cartReplyApi.switch({ query: { cart_enabled: enabled } })

      if (data.enabled) {
        alert(l.translate('Cart reply activated'), 'success')
      } else {
        alert(l.translate('Cart reply deactivated'))
      }

      return data
    } catch (error) {
      handleCatch(error)
      return null
    }
  },
)

export const changeCartReplyType = createAppAsyncThunk<CartReply | null, { type: CartReplyType }>(
  'whatsapp/changeCartReplyType',
  async ({ type }) => {
    try {
      const { data } = await cartReplyApi.changeType({ body: { type } })
      return data
    } catch (error) {
      handleCatch(error)
      return null
    }
  },
)

export const replaceCartReplyFlow = createAppAsyncThunk<
  CartReply | null,
  { flowId?: string | null }
>('whatsapp/replaceCartReplyFlow', async ({ flowId }) => {
  if (!flowId) return null
  try {
    const { data } = await cartReplyApi.replaceFlow({ body: { flow_ns: flowId } })
    return data
  } catch (error) {
    handleCatch(error)
    return null
  }
})

export const removeCartReplyFlow = createAppAsyncThunk<CartReply | null>(
  'whatsapp/removeCartReplyFlow',
  async () => {
    try {
      const { data } = await cartReplyApi.removeFlow()
      return data
    } catch (error) {
      handleCatch(error)
      return null
    }
  },
)

export const createCartReplyFlow = createAppAsyncThunk(
  'whatsapp/createCartReplyFlow',
  async (_, { dispatch }) => {
    try {
      const result = await dispatch(
        contentManagementActions.createAutomation({
          name: 'Cart Reply Flow',
        }),
      ).unwrap()

      await dispatch(replaceCartReplyFlow({ flowId: result?.flow?.ns }))

      return result?.flow
    } catch (error) {
      handleCatch(error)
      return null
    }
  },
)

const initialState: WhatsAppCartReplyState = {
  loading: false,
  data: null,
}

export const cartReplySlice = createSlice({
  name: 'cartReply',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(loadCartReply.pending, (state) => {
      state.loading = true
    })

    builder.addCase(loadCartReply.fulfilled, (state, action) => {
      state.loading = false
      state.data = action.payload
    })
    builder.addCase(updateCartReplyEnabled.fulfilled, (state, action) => {
      state.loading = false
      state.data = action.payload
    })
    builder.addCase(changeCartReplyType.fulfilled, (state, action) => {
      state.loading = false
      state.data = action.payload
    })
    builder.addCase(replaceCartReplyFlow.fulfilled, (state, action) => {
      state.loading = false
      state.data = action.payload
    })
    builder.addCase(removeCartReplyFlow.fulfilled, (state, action) => {
      state.loading = false
      state.data = action.payload
    })
  },
})

accountNotificationsListener.on(
  'cart_reply_updated',
  (data: INotificationServiceData<CartReply>, dispatch) => {
    const cartReplyData = cartReplyAdapter(data.model)
    dispatch({
      type: cartReplyActionTypes.CART_REPLY_UPDATED_NOTIFICATION,
      item: cartReplyData,
    })
  },
)

export const getWaCartReplyDataSelector = (state: RootState) => state.whatsApp.cartReply.data
export const getWaCartReplyLoadingSelector = (state: RootState) => state.whatsApp.cartReply.loading

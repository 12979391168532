import { v4 as uuid } from 'uuid'

import { isTestEnv } from 'utils/e2e'

const makeGetClientId = () => {
  const clientId = isTestEnv() ? 'e2e-client-id' : uuid()
  return () => clientId
}

export const getClientId = makeGetClientId()

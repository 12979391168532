import dot from 'dot-prop-immutable'
import clone from 'lodash/clone'
import find from 'lodash/find'
import get from 'lodash/get'
import isFinite from 'lodash/isFinite'
import uniq from 'lodash/uniq'
import without from 'lodash/without'

import { BlockType } from 'common/builder/constants/BlockType'
import * as atypes from 'common/builder/constants/builderReduxActionTypes'
import BlockModel from 'common/builder/models/Block'
import * as commonATypes from 'common/core/constants/ReduxActionTypes'

const initialState = { byId: {} }

export default function blockReducers(state = initialState, action) {
  switch (action.type) {
    case atypes.CREATE_BLOCK: {
      const { item } = action
      return dot.merge(state, `byId`, { [item.id]: { ...item, $new: true } })
    }

    case atypes.REMOVE_BLOCK: {
      const { blockId } = action
      // remove block links
      for (let eachBlockId of Object.keys(state.byId)) {
        if ((state.byId[eachBlockId].blocks || []).includes(blockId)) {
          state = dot.set(state, `byId.${eachBlockId}.blocks`, (value) => without(value, blockId))
        }
      }

      return dot.delete(state, `byId.${blockId}`)
    }

    case atypes.INSTALL_BUILDER_SNAPSHOT: {
      const { blocks } = action.targetSnapshot

      return { ...blocks }
    }

    case atypes.UPDATE_BLOCK: {
      const { blockId, changes } = action
      if (blockId == null || changes == null) {
        return state
      }

      const block = state.byId[blockId]

      if (block == null) {
        return state
      }

      return dot.merge(state, `byId.${blockId}`, changes)
    }

    case atypes.LINK_BLOCK_AND_BLOCK: {
      const { blockId, targetId, index } = action

      if (isFinite(index)) {
        const blocks = clone(get(state, `byId.${blockId}.blocks`))
        blocks.splice(index, 0, targetId)
        return dot.set(state, `byId.${blockId}.blocks`, uniq(blocks))
      }

      return dot.set(state, `byId.${blockId}.blocks`, (items) => uniq([...items, targetId]))
    }

    case atypes.UNLINK_BLOCK_AND_BLOCK: {
      const { blockId, targetId } = action
      return dot.set(state, `byId.${blockId}.blocks`, (value) => without(value, targetId))
    }

    case atypes.LINK_BLOCK_AND_BUTTON: {
      const { blockId, targetId } = action
      return dot.set(state, `byId.${blockId}.buttons`, (items) => uniq([...items, targetId]))
    }

    case atypes.UNLINK_BLOCK_AND_BUTTON: {
      const { blockId, targetId } = action
      return dot.set(state, `byId.${blockId}.buttons`, (value) => without(value, targetId))
    }

    case atypes.CONTENT_PARSED: {
      const { blocks = [] } = action.parsed
      blocks.forEach((item) => (state = dot.merge(state, `byId`, { [item.id]: item })))
      return state
    }

    // link button to block when button created
    case atypes.CREATE_BUTTON: {
      const { item, blockId } = action
      const block = state.byId[blockId]
      if (block != null) {
        return dot.set(state, `byId.${blockId}.buttons`, (v) => [...v, item.id])
      }
      return state
    }

    // remove button links when button removed
    case atypes.REMOVE_BUTTON: {
      const { buttonId } = action

      for (let eachBlockId of Object.keys(state.byId)) {
        if (state.byId[eachBlockId].buttons.includes(buttonId)) {
          state = dot.set(state, `byId.${eachBlockId}.buttons`, (value) => without(value, buttonId))

          if (state.byId[eachBlockId].type === BlockType.TELEGRAM_KEYBOARD) {
            const newButtonsStructure = state.byId[eachBlockId].buttonsStructure
              .map((buttons) => without(buttons, buttonId))
              .filter((buttons) => buttons.length)

            state = dot.set(state, `byId.${eachBlockId}.buttonsStructure`, newButtonsStructure)
          }
        }
      }
      return state
    }

    // manage attachments upload
    case commonATypes.ATTACHMENT_UPLOAD_RESPONSE: {
      const { blockId, uploadId, data } = action
      if (!blockId) {
        return state
      }

      const block = state.byId[blockId]
      for (let prop of BlockModel.BlockUploadProps) {
        if (block && block[prop] && block[prop].uploadId === uploadId) {
          return dot.set(state, `byId.${blockId}.${prop}`, data.attachment)
        }
      }
      return state
    }
    case commonATypes.ATTACHMENT_UPLOAD_ERROR: {
      const { blockId, uploadId } = action
      if (!blockId) {
        return state
      }

      const block = state.byId[blockId]
      for (let prop of BlockModel.BlockUploadProps) {
        if (block && block[prop] && block[prop].uploadId === uploadId) {
          return dot.set(state, `byId.${blockId}.${prop}`, null)
        }
      }
      return state
    }

    case atypes.BUILDER_SELECT_BLOCK: {
      const { prevBlockId } = action
      if (!prevBlockId) {
        return state
      }

      let block = state.byId[prevBlockId]

      while (block != null) {
        state = dot.delete(state, `byId.${block.id}.$new`)
        block = find(state.byId, (b) => b.blocks.includes(block.id))
      }
      return state
    }

    case atypes.BUILDER_SELECT_BUTTON: {
      const { prevButtonId } = action
      if (!prevButtonId) {
        return state
      }

      let block = find(state.byId, (b) => b.buttons.includes(prevButtonId))

      while (block != null) {
        state = dot.delete(state, `byId.${block.id}.$new`)
        block = find(state.byId, (b) => b.blocks.includes(block.id))
      }
      return state
    }

    case atypes.BUILDER_PUBLISH_CLICKED: {
      for (let blockId of Object.keys(state.byId)) {
        const block = state.byId[blockId]
        if (block.$new) {
          state = dot.delete(state, `byId.${blockId}.$new`)
        }
      }
      return state
    }

    case atypes.CLEAR_BUILDER_AND_RESET: {
      return { byId: {} }
    }
  }

  return state
}

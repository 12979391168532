import { api } from 'shared/api'

import { ProfileApiSchemas } from './schemas'

export const profileApi = {
  fetchPages: api.base.createGet({
    url: '/profile/getAccountsList',
    schemas: ProfileApiSchemas.fetchPages,
  }),
  setDisplayFlag: api.base.createPost({
    url: '/profile/setDisplayFlag',
    schemas: ProfileApiSchemas.setDisplayFlag,
  }),
  fetchInactiveAccounts: api.base.createGet({
    url: '/accountInactivityDeletion/listToBeDeletedAccountIds',
    schemas: ProfileApiSchemas.fetchInactiveAccounts,
  }),
  sendEmailVerification: api.base.createPost({
    url: '/profile/sendVerificationEmail',
    schemas: ProfileApiSchemas.sendEmailVerification,
  }),
  verifyEmailViaCode: api.base.createPost({
    url: '/profile/verifyEmailViaCode',
    schemas: ProfileApiSchemas.verifyEmailViaCode,
  }),
  clearRegSourceParam: api.base.createPost({
    url: '/profile/clearRegSourceParam',
    schemas: ProfileApiSchemas.clearRegSourceParam,
  }),
  addToWaitlistTikTokCreatorType: api.base.createPost({
    url: '/profile/addToWaitlistTikTokCreatorType',
    schemas: ProfileApiSchemas.addToWaitlistTikTokCreatorType,
  }),
  addToTikTokReminderListForUpdates: api.base.createPost({
    url: '/profile/addToTikTokReminderListForUpdates',
    schemas: ProfileApiSchemas.addToTikTokReminderListForUpdates,
  }),
}

import dotProp from 'dot-prop-immutable'
import { Action } from 'redux'

import actionTypes from 'apps/applicationInstallation/constants/ReduxActionTypes'
import { IApplicationAccount } from 'common/actions/integrations/Applications/interfaces'
import { IActionWithPayload } from 'common/core/interfaces/actions'

const initialState: {
  items: IApplicationAccount[]
  selectedId: number | null
  isFetching: boolean
} = {
  items: [],
  selectedId: null,
  isFetching: false,
}

const accountsReducer = (
  state = initialState,
  action:
    | Action
    | (IActionWithPayload<{
        accounts: IApplicationAccount[]
      }> & { accountId: AccountId }),
) => {
  switch (action.type) {
    case actionTypes.APPLICATION_INSTALL_ACCOUNTS_FETCH_REQUEST:
      state = dotProp.set(state, 'isFetching', true)
      state = dotProp.set(state, 'selectedId', null)
      state = dotProp.set(state, 'items', [])
      break
    case actionTypes.APPLICATION_INSTALL_ACCOUNTS_FETCH_RESPONSE:
      if (!('data' in action)) {
        break
      }
      state = dotProp.set(state, 'isFetching', false)
      state = dotProp.set(state, 'selectedId', null)
      state = dotProp.set(state, 'items', [...(action.data?.accounts || [])])
      break
    case actionTypes.APPLICATION_INSTALL_REQUEST:
      if ('accountId' in action) {
        state = dotProp.set(state, 'selectedId', action.accountId)
      }
      break
  }

  return state
}

export default accountsReducer

import { z } from 'zod'

import { BlockType } from '../constants'

export const IdSchema = z.string().uuid()

export const SerializedBlockSchema = z.object({
  id: IdSchema,
  props: z.object({}),
  type: z.nativeEnum(BlockType),
  style: z.string().optional(),
  key: z.string().optional(),
})

export const SerializedBlockWithChildrenSchema = SerializedBlockSchema.extend({
  props: z.object({
    children: z.array(IdSchema),
  }),
})

export const DeNormalizedSerializedBlockWithChildrenSchema =
  SerializedBlockWithChildrenSchema.extend({
    props: z.object({
      children: z.array(SerializedBlockSchema),
    }),
  })

export const SerializedViewSchema = z.object({
  root: IdSchema,
  blocks: z.record(IdSchema, SerializedBlockSchema),
})

import React, { useCallback } from 'react'
import { useAppDispatch, useAppSelector } from 'reduxTyped'
import { l } from '@manychat/manyui'

import { BuilderIdContext } from 'common/builder/components/builder/BuilderIdProvider/BuilderIdProvider'
import { getCurrentNode, getById } from 'common/builder/selectors/builder/builderStateSelectors'
import { openCreateFieldModal as createUserField } from 'common/fields/actions/userFieldsActions'
import { UserFieldType } from 'common/fields/constants/UserFieldsTypes'
import { FieldGroupId, FieldType } from 'common/fields/entities/enums'
import { Field } from 'common/fields/entities/field'
import { getHaveMoreThatOneGroup } from 'common/fields/helpers'
import { FIELD_LIST_TRACKING_WHITELIST } from 'common/fields/linking/components/constants'
import {
  FieldOptionGroup,
  IFieldOption,
  useFieldsOptions,
} from 'common/fields/linking/hooks/useFieldsOptions'
import { GlobalFieldsActions } from 'common/fields/store/globalFields'
import { GlobalFieldSource } from 'common/fields/store/types'
import { TagsActions } from 'common/tags/store'
import { NewTagSource } from 'common/tags/store/types'
import { PermissionTargets, usePermission } from 'common/userRoles'
import { IRenderOptionFunction } from 'components/AutocompleteElements/OptionsList/OptionItem'
import { AllowNewField } from 'components/AutocompleteElements/OptionsList/OptionListConfig'
import {
  OptionsList,
  OptionsListProps,
} from 'components/AutocompleteElements/OptionsList/OptionsList'
import { VariableFilter } from 'components/MCTextEditor/variables/adapter'
import { analyticsService } from 'utils/services/analytics'
import { AnalyticsEvents } from 'utils/services/analytics/AnalyticsEvents'

const defaultOptionsEnhancer = (options: FieldOptionGroup[]) => options

export type FieldListProps = {
  allowedTypes?: FieldType[]
  allowNew?: AllowNewField
  fields: Field[]
  onSelect: (value: string, field?: Field) => void
  optionsEnhancer?: (options: FieldOptionGroup[], query?: string) => FieldOptionGroup[]
  hideCategories?: boolean
  showIcon?: boolean
  renderOption?: IRenderOptionFunction
  createGlobalFieldSource?: GlobalFieldSource
  createTagSource?: NewTagSource
  allowedUserFieldTypes?: UserFieldType[]
  trackFieldSelection?: boolean
} & Omit<OptionsListProps, 'options' | 'onSelect'>

type OptionalField = Field | undefined

export const FieldList = ({
  allowedTypes,
  allowNew,
  fields,
  onSelect,
  optionsEnhancer = defaultOptionsEnhancer,
  query,
  target,
  hideCategories,
  showIcon,
  renderOption,
  createGlobalFieldSource,
  createTagSource,
  allowedUserFieldTypes,
  trackFieldSelection,
}: FieldListProps) => {
  const dispatch = useAppDispatch()
  const canCreateFields = usePermission(PermissionTargets.SETTINGS_CUSTOM_FIELDS_INTERACTIONS)
  const builderId = React.useContext(BuilderIdContext)?.builderId

  const node = useAppSelector((state) => getCurrentNode(state, builderId))
  const builderState = useAppSelector((state) => getById(state, builderId))
  const flowId = builderState?.flow

  const queriedFields = React.useMemo(
    () =>
      fields
        .filter((field) => !query || VariableFilter(field.caption, query))
        .filter((field) => !allowedTypes || allowedTypes.includes(field.type)),
    [fields, query, allowedTypes],
  )
  const options = optionsEnhancer(useFieldsOptions(queriedFields), query)

  const handleNewOption = async () => {
    if (!canCreateFields || !allowNew) return

    if (allowNew === FieldGroupId.TAG && createTagSource) {
      dispatch(
        TagsActions.openCreateTagModal({
          tagName: query,
          source: createTagSource,
        }),
      )
      return
    }

    if (allowNew === FieldGroupId.BOT && createGlobalFieldSource) {
      dispatch(
        GlobalFieldsActions.openCreateFieldModal({
          source: createGlobalFieldSource,
          initialFieldData: { title: query },
        }),
      )

      return
    }

    if (allowNew === FieldGroupId.USER) {
      const field: OptionalField = await dispatch(
        createUserField({
          title: query,
          builderId: builderId,
          allowedFieldTypes: allowedUserFieldTypes,
        }),
      )

      if (!field?.id) return

      onSelect(field.id, field)
    }
  }

  const handleSelect = useCallback(
    (option: IFieldOption) => {
      if (!option.value) return

      if (
        trackFieldSelection &&
        option.parent?.group &&
        FIELD_LIST_TRACKING_WHITELIST.includes(option.value)
      ) {
        analyticsService.sendEvent(AnalyticsEvents.FIELD_LIST_OPTION_SELECTED, {
          flowId,
          nodeId: node?.id,
          field: option.value,
          group: option.parent.group,
        })
      }

      onSelect(option.value)
    },
    [flowId, node?.id, onSelect, trackFieldSelection],
  )

  const haveDifferentGroupsInFields = getHaveMoreThatOneGroup(fields)
  const hideFieldsCategories = hideCategories || !haveDifferentGroupsInFields

  return (
    <OptionsList
      onSelect={handleSelect}
      onCreate={handleNewOption}
      allowNew={allowNew}
      options={options}
      target={target}
      query={query}
      emptyLabel={l.translate('No Fields Found')}
      hideCategories={hideFieldsCategories}
      showIcon={showIcon}
      renderOption={renderOption}
    />
  )
}

import qs from 'qs'

import { FsQueryOrderField, FsQueryOrderType } from 'apps/cms/lib/constants'
import { ContentManagementSystemState } from 'apps/cms/store/lib/types'
import { FlowTriggerStatus } from 'shared/api/requests/cms/schemas'

const decodeSearchValue = (value: string): string => {
  return qs.parse(`search=${value}`).search as string
}

const encodeSearchValue = (value: string): string => {
  return qs.stringify({ search: value })
}

export const getFsQueryFromRouterQuery = (options: {
  search: string | null
  path: string | null
  order: string | null
  field: string | null
  triggerFilters: string | null
  triggerStatus: string | null
}): ContentManagementSystemState['query'] => {
  let triggerFilters: ContentManagementSystemState['query']['triggerFilters'] = []

  try {
    triggerFilters = JSON.parse(options.triggerFilters ?? '')
  } catch (error) {}

  return {
    search: options.search ? decodeSearchValue(options.search) : '',
    path: options.path ?? '/',
    order: (options.order as FsQueryOrderType) ?? FsQueryOrderType.DESC,
    field: (options.field as FsQueryOrderField) ?? FsQueryOrderField.MODIFIED,
    triggerFilters,
    triggerStatus: (options.triggerStatus as FlowTriggerStatus) ?? null,
  }
}

// TODO: Need to migration on qs library
export const getQueryStringFromFsQuery = (
  fsQuery: ContentManagementSystemState['query'],
): string => {
  const parameters: string[] = []

  Object.entries(fsQuery).forEach(([queryParameter, value]) => {
    if (queryParameter === 'triggerFilters') {
      if (Array.isArray(value) && value.length > 0) {
        parameters.push(`${queryParameter}=${JSON.stringify(value)}`)
      }

      return
    }

    if (queryParameter === 'search') {
      if (value && typeof value === 'string') {
        parameters.push(encodeSearchValue(value))
      }

      return
    }

    if (value) {
      parameters.push(`${queryParameter}=${value}`)
    }
  })

  return encodeURI(`?${parameters.join('&')}`)
}

export const getPathItems = (path: string): string[] => {
  if (path === '/') {
    return []
  }

  const pathWithoutFirstSlash = path.replace(/^\//, '')

  return pathWithoutFirstSlash.split('/')
}

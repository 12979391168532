import { TriggerRuleStatusType, TriggerRuleFrequencyType } from './constants'

export default {
  rule_id: null,
  title: '',
  status: TriggerRuleStatusType.INACTIVE,
  actions: [],
  filter: {},
  triggers: [],
  frequency_settings: TriggerRuleFrequencyType.MULTIPLE,
  draft: null,
}

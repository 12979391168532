import { BusinessError } from 'shared/api/lib/errors/business'

interface EmptyContentError extends BusinessError {
  field: 'status'
}

export const SequenceMessageApiGuards = {
  patch: {
    isEmptyContentError: (error: BusinessError): error is EmptyContentError =>
      error.field === 'status',
  },
}

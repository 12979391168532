import { ParserMark } from '../mapReduceParser/types'

export const makeOpenMark = (name: string, data?: unknown) => {
  return { type: 'open' as const, name, data }
}

export const makeCloseMark = (name: string) => {
  return { type: 'close' as const, name }
}

export const encloseWithMarks = (
  content: string,
  name: string,
  data?: unknown,
): Array<string | ParserMark> => {
  return [makeOpenMark(name, data), content, makeCloseMark(name)]
}

import React, { useEffect, useState } from 'react'
import cx from 'classnames'
import dayjs from 'dayjs'
import { l } from '@manychat/manyui'

import FBPolicyEnforcementModal from 'common/core/components/FBPolicyEnforcementModal'
import { FBPolicyEnforcementModalType } from 'common/core/components/FBPolicyEnforcementModal/FBPolicyEnforcementModalType'
import { FBPolicyEnforcementNotificationType } from 'common/core/components/NotifyBar/interfaces/facebookNotifications'
import { FacebookPolicyNotification } from 'common/core/components/NotifyBar/NotifyInterfaces'
import { analyticsService } from 'utils/services/analytics'

import cmCommon from '../common.module.css'

import cm from './FBPolicyEnforcementNotification.module.css'

const FBPolicyEnforcementNotification = ({
  notification,
}: {
  notification: FacebookPolicyNotification
}) => {
  const [isActive, setActive] = useState(false)
  const created = dayjs.unix(notification.ts_created)
  const timestamp = (
    <span data-title={created.format('YYYY-MM-DD HH:mm')} data-title-at="bottom">
      {created.fromNow()}
    </span>
  )

  const notificationType =
    notification.data?.fb_policy_enforcement?.type || FBPolicyEnforcementNotificationType.BLOCK

  let message = l.translate('Page was blocked by Facebook')
  let modalType = FBPolicyEnforcementModalType.BLOCK

  if (notificationType === FBPolicyEnforcementNotificationType.WARNING) {
    message = l.translate('Page was warned by Facebook')
    modalType = FBPolicyEnforcementModalType.WARNING
  } else if (notificationType === FBPolicyEnforcementNotificationType.UNBLOCK) {
    message = l.translate('Page was unblocked by Facebook')
    modalType = FBPolicyEnforcementModalType.WAS_BLOCK
  }

  useEffect(() => {
    if (notificationType === FBPolicyEnforcementNotificationType.WARNING) {
      analyticsService.sendEvent('NOTIFICATION_BAR.PAGE_WARNED.SHOWN')
    } else if (notificationType === FBPolicyEnforcementNotificationType.BLOCK) {
      analyticsService.sendEvent('NOTIFICATION_BAR.PAGE_BLOCKED.SHOWN')
    } else if (notificationType === FBPolicyEnforcementNotificationType.UNBLOCK) {
      analyticsService.sendEvent('NOTIFICATION_BAR.PAGE_UNBLOCK.SHOWN')
    }
  }, [notificationType])

  const handleClick = () => {
    if (notificationType === FBPolicyEnforcementNotificationType.WARNING) {
      analyticsService.sendEvent('NOTIFICATION_BAR.PAGE_WARNED.CTA_CLICKED')
    } else if (notificationType === FBPolicyEnforcementNotificationType.BLOCK) {
      analyticsService.sendEvent('NOTIFICATION_BAR.PAGE_BLOCKED.CTA_CLICKED')
    } else if (notificationType === FBPolicyEnforcementNotificationType.UNBLOCK) {
      analyticsService.sendEvent('NOTIFICATION_BAR.PAGE_UNBLOCK.CTA_CLICKED')
    }

    setActive(true)
  }

  return (
    <span>
      <span>
        {message} {timestamp}
      </span>
      {modalType !== FBPolicyEnforcementModalType.WAS_BLOCK && (
        <a onClick={handleClick} className={cx(cm.more, cmCommon.link)}>
          {l.translate('Read More')}
        </a>
      )}
      {isActive && (
        <FBPolicyEnforcementModal
          modalType={modalType}
          text={notification.data?.fb_policy_enforcement?.reason ?? ''}
          ts={notification.ts_created}
          onClose={() => setActive(false)}
          withoutConfirm
          isRelogin={false}
          eventPrefix="FB_POLICY_ENFORCEMENT_NOTIFY_BAR_MODAL"
        />
      )}
    </span>
  )
}

export default FBPolicyEnforcementNotification

import { z } from 'zod'

export const _smsSettingsApiSchemas = {
  connectSmsByTwilio: {
    request: z.object({
      sid: z.string(),
      auth_token: z.string(),
      phone_number: z.string(),
    }),
    query: z.undefined(),
    path: z.undefined(),
    response: z.object({
      sid: z.string(),
      auth_token: z.string(),
      phone: z.string(),
      channel_enabled: z.boolean(),
    }),
  },
  disconnectSmsByTwilio: {
    query: z.undefined(),
    path: z.undefined(),
    response: z.undefined(),
    request: z.undefined(),
  },
  enableChannel: {
    query: z.undefined(),
    path: z.undefined(),
    response: z.undefined(),
    request: z.undefined(),
  },
  disableChannel: {
    query: z.undefined(),
    path: z.undefined(),
    response: z.undefined(),
    request: z.undefined(),
  },
}

import { l, tints } from '@manychat/manyui'

import { NodeType } from 'common/builder/constants/NodeType'
import BaseNodeConfig from 'common/builder/nodes/BaseNodeConfig'
import { AiAgentNodeType } from 'common/builder/nodes/nodeInterfaces'
import { Ability } from 'common/core/constants/Ability'

class AiAgentNodeConfig extends BaseNodeConfig<AiAgentNodeType> {
  nodeType = NodeType.AI_AGENT

  isPro = true
  requiredAbilities: Ability[] = [Ability.AI_AGENT]

  getCaption = () => l.translate('AI Step')

  getCaptionWithCount = (n: number) =>
    l.translate('{n, plural, one {# AI Step} other {# AI Steps}}', { n })

  getNextStepCaption = () => l.translate('AI Step')

  icon = 'AiStep'

  color = tints.neutral[1]

  headerColor: string = 'linear-gradient(106.35deg, #fbf9ce 11.34%, #fce7dc 88.66%)'

  getDefaults = (): Pick<AiAgentNodeType, 'targetId' | 'channel' | 'agent_data' | 'settings'> => ({
    targetId: null,
    channel: null,
    agent_data: null,
    settings: { save_phone_as_whatsapp_id: false },
  })
}

export const aiAgentNodeConfig = new AiAgentNodeConfig()

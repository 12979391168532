import React from 'react'
import cx from 'classnames'

import { ListItemProps } from './interfaces'

import cm from './ListItem.module.css'

export const ListGroupHeading = ({ children, label, leftContent }: ListItemProps) => {
  const labelContent = label || children

  return (
    <li className={cx(cm.listGroupHeading, 'text-sm')}>
      {leftContent && <div className={cm.leftContent}>{leftContent}</div>}
      {labelContent && <span className={cm.listItemContent}>{labelContent} </span>}
    </li>
  )
}

ListGroupHeading.displayName = 'List.GroupHeading'

export const SMS_PHONES_FETCH_RESPONSE = 'SMS_PHONES_FETCH_RESPONSE'
export const SMS_CHANNEL_SWITCH_REQUEST = 'SMS_CHANNEL_SWITCH_REQUEST'
export const SMS_CHANNEL_SWITCH_ERROR = 'SMS_CHANNEL_SWITCH_ERROR'

export const SMS_FETCH_DATA_REQUEST = 'SMS_FETCH_DATA_REQUEST'
export const SMS_FETCH_DATA_RESPONSE = 'SMS_FETCH_DATA_RESPONSE'
export const SMS_FETCH_DATA_ERROR = 'SMS_FETCH_DATA_ERROR'

export const SMS_ENABLE_CHANNEL_RESPONSE = 'SMS_ENABLE_CHANNEL_RESPONSE'
export const SMS_DISABLE_CHANNEL_RESPONSE = 'SMS_DISABLE_CHANNEL_RESPONSE'

export const SMS_TWILIO_CONNECT_RESPONSE = 'SMS_TWILIO_CONNECT_RESPONSE'
export const SMS_TWILIO_DISCONNECT_RESPONSE = 'SMS_TWILIO_DISCONNECT_RESPONSE'

export const SMS_OPEN_MODAL_CONNECT = 'SMS_OPEN_MODAL_CONNECT'
export const SMS_CLOSE_MODAL_CONNECT = 'SMS_CLOSE_MODAL_CONNECT'

import React from 'react'
import { Icon, palettes } from '@manychat/manyui'

import { FieldType } from 'common/fields/entities/enums'

const icons: Record<string, React.ElementType> = {
  [FieldType.ARRAY]: Icon.CufArray,
  [FieldType.BOOLEAN]: Icon.CufBoolean,
  [FieldType.DATETIME]: Icon.Time,
  [FieldType.DATE]: Icon.Calendar,
  [FieldType.ENUM]: Icon.CufEnum,
  [FieldType.NUMBER]: Icon.CufNumber,
  [FieldType.SET]: Icon.AddTag,
  [FieldType.TEXT]: Icon.CufText,
  [FieldType.TAG]: Icon.AddTag,
}

const defaultSize = 14

const FieldIcon = ({
  iconName,
  size,
  color = palettes.semantic.accent,
}: {
  iconName?: string
  size?: number
  color?: string
}) => {
  if (!iconName) {
    return <div style={{ width: size || defaultSize }} />
  }

  const TypeIcon = icons[iconName] || Icon.CufNoIcon

  return <TypeIcon size={size} color={color} />
}

export default FieldIcon

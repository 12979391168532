import { l } from '@manychat/manyui'

import * as atypes from 'common/actions/integrations/common/constants/IntegrationsReduxActionTypes'
import { IntegrationType } from 'common/actions/integrations/common/constants/IntegrationType'
import { UserFieldMapping } from 'common/actions/integrations/Salesforce/interfaces/userField'
import { alert } from 'common/core'
import { IAsyncThunkAction } from 'common/core/interfaces/actions'
import { handleCatch } from 'shared/api/lib/errors/handlers'
import { salesforceApi } from 'shared/api/requests/integrations/salesforce'
import { IdentityField } from 'shared/api/requests/integrations/salesforce/schemas'

export const setAccountLinkField = ({ field_id, field_name }: IdentityField): IAsyncThunkAction => {
  return async (dispatch) => {
    try {
      const { data } = await salesforceApi.setAccountLinkField({
        body: {
          field_id,
          field_name,
        },
      })
      dispatch({ type: atypes.SALESFORCE_SET_ACCOUNT_LINK_FIELD, data })
    } catch (error) {
      handleCatch(error)
    }
  }
}

export const clearAccountLinkField = (): IAsyncThunkAction => {
  return async (dispatch) => {
    try {
      const { data } = await salesforceApi.clearAccountLinkField()
      dispatch({ type: atypes.SALESFORCE_CLEAR_ACCOUNT_LINK_FIELD, data })
    } catch (error) {
      handleCatch(error)
    }
  }
}

export const fetchContactFields = (): IAsyncThunkAction => {
  return async (dispatch) => {
    try {
      dispatch({ type: atypes.SALESFORCE_CONTACT_FIELDS_FETCH })
      const { data } = await salesforceApi.getContactFields()
      dispatch({ type: atypes.SALESFORCE_CONTACT_FIELDS_FETCH_RESPONSE, data })
    } catch (error) {
      handleCatch(error)
    }
  }
}

export const exportLiveChatHistory = (contactId: string): IAsyncThunkAction => {
  return async (dispatch) => {
    try {
      dispatch({ type: atypes.SALESFORCE_EXPORT_LIVECHAT_HISTORY })
      await salesforceApi.exportLivechatHistory({
        body: { subscriber_id: contactId, integration: IntegrationType.SALESFORCE },
      })
      alert(l.translate('Conversation history has been sent to Salesforce'), 'success')
    } catch (error) {
      handleCatch(error)
    }
  }
}

export const setUserFieldInBindings = (index: number, value: string) => ({
  type: atypes.SALESFORCE_SET_CUF_IN_MAPPINGS,
  data: {
    index,
    value,
  },
})

export const setPropertyInBindings = (index: number, value: string) => ({
  type: atypes.SALESFORCE_SET_PROPERTY_IN_BINDINGS,
  data: {
    index,
    value,
  },
})

export const addCUFPropertyPair = () => ({
  type: atypes.SALESFORCE_ADD_CUF_PROPERTY_PAIR,
})

export const deleteCUFPropertyPair = (index: number) => ({
  type: atypes.SALESFORCE_DELETE_CUF_PROPERTY_PAIR,
  data: { index },
})

export const setCUFPropertyBindings = (data: UserFieldMapping[]) => ({
  type: atypes.SALESFORCE_SET_CUF_PROPERTY_BINDINGS,
  data,
})

export const setIdentityField = (data: { value: string; option: string }) => ({
  type: atypes.SALESFORCE_SET_IDENTITY_FIELD,
  data,
})

export const setMappings = (data: Record<string, string>) => ({
  type: atypes.SALESFORCE_SET_CONTACT_MAPPINGS,
  data,
})

export const setSalesforceSearchField = (data: string) => ({
  type: atypes.SALESFORCE_SET_SEARCH_FIELD,
  data,
})

export const setSearchValue = (data: string) => ({
  type: atypes.SALESFORCE_SET_SEARCH_VALUE,
  data,
})

import { BusinessDomainStatus, BusinessEmailStatus } from 'apps/email/emailConstants'
import { IBusinessEmail } from 'apps/email/emailInterfaces'

export const isDefaultBusinessEmail = (businessEmail?: IBusinessEmail | null) => {
  return businessEmail?.id === null
}

export const isBusinessEmailValid = (businessEmail: IBusinessEmail | null) => {
  if (!businessEmail) {
    return false
  }
  if (isDefaultBusinessEmail(businessEmail)) {
    return true
  }

  return (
    businessEmail.status === BusinessEmailStatus.VERIFIED &&
    (businessEmail.domain?.status === BusinessDomainStatus.VERIFIED ||
      businessEmail.domain?.status === BusinessDomainStatus.ACTIVE)
  )
}

import {
  RegistrationAccountType,
  RegistrationSection,
} from 'apps/registration/interfaces/registration'
import { ChannelType } from 'common/core/constants/ChannelType'

import { RedirectRouteObject } from '../types'

const generateRegistrationPath = ({
  section,
  channel = '',
  accountId = '',
  queryAccountId,
}: {
  section: RegistrationSection
  channel?: string
  accountId?: string
  queryAccountId?: string
}) => {
  const pageId = Number(accountId.replace('fb', ''))
  let type = ''
  let preparedChannel = channel

  if (queryAccountId !== undefined) {
    type = queryAccountId ? RegistrationAccountType.EXISTING : RegistrationAccountType.NEW
  }

  const needSetDefaultChannel =
    channel !== undefined && !Object.values(ChannelType).includes(preparedChannel as ChannelType)

  if (needSetDefaultChannel) {
    preparedChannel = ChannelType.INSTAGRAM
  }

  return ['registration', section, preparedChannel, pageId, type].filter(Boolean).join('/')
}

export const RegistrationRedirects: RedirectRouteObject[] = [
  {
    path: 'profile/emailVerification',
    to: generateRegistrationPath({ section: RegistrationSection.USER_SETUP }),
  },
  {
    path: 'profile/questionnaire',
    to: generateRegistrationPath({ section: RegistrationSection.USER_SETUP }),
  },
  {
    path: 'profile/addNewAccount',
    to: generateRegistrationPath({ section: RegistrationSection.CHANNEL_CONNECTION }),
  },
  {
    path: 'profile/addWaAccount',
    to: generateRegistrationPath({
      section: RegistrationSection.CHANNEL_CONNECTION,
      channel: ChannelType.WHATSAPP,
    }),
  },
  {
    path: 'profile/addTgAccount',
    to: generateRegistrationPath({
      section: RegistrationSection.CHANNEL_CONNECTION,
      channel: ChannelType.TELEGRAM,
    }),
  },
  {
    path: 'profile/addFbAccount',
    to: generateRegistrationPath({
      section: RegistrationSection.CHANNEL_CONNECTION,
      channel: ChannelType.FB,
    }),
  },
  {
    path: 'profile/addIgAccount',
    to: generateRegistrationPath({
      section: RegistrationSection.CHANNEL_CONNECTION,
      channel: ChannelType.INSTAGRAM,
    }),
  },
  {
    path: 'profile/addTiktokAccount',
    to: generateRegistrationPath({
      section: RegistrationSection.CHANNEL_CONNECTION,
      channel: ChannelType.TIKTOK,
    }),
  },
  {
    path: ':acc_id/trial',
    to: ({ acc_id = '' }, { accountId = '', channel = '' }) =>
      generateRegistrationPath({
        section: RegistrationSection.CHANNEL_CONNECTION_SUCCESS,
        channel,
        accountId: acc_id,
        queryAccountId: accountId,
      }),
  },
  {
    path: ':acc_id/questionnaire',
    to: ({ acc_id = '' }, { accountId = '', channel = '' }) =>
      generateRegistrationPath({
        section: RegistrationSection.CHANNEL_CONNECTION_SUCCESS,
        channel,
        accountId: acc_id,
        queryAccountId: accountId,
      }),
  },
  {
    path: ':acc_id/addIgAccount/success',
    to: ({ acc_id = '' }, { accountId = '' }) =>
      generateRegistrationPath({
        section: RegistrationSection.CHANNEL_CONNECTION_SUCCESS,
        channel: ChannelType.INSTAGRAM,
        accountId: acc_id,
        queryAccountId: accountId,
      }),
  },
  {
    path: ':acc_id/addFbAccount/success',
    to: ({ acc_id = '' }, { accountId = '' }) =>
      generateRegistrationPath({
        section: RegistrationSection.CHANNEL_CONNECTION_SUCCESS,
        channel: ChannelType.FB,
        accountId: acc_id,
        queryAccountId: accountId,
      }),
  },
  {
    path: ':acc_id/addWaAccount',
    to: ({ acc_id = '' }, { channel = '' }) =>
      generateRegistrationPath({
        section: RegistrationSection.CHANNEL_CONNECTION,
        channel,
        accountId: acc_id,
      }),
  },
  {
    path: ':acc_id/addTiktokAccount',
    to: ({ acc_id = '' }, { channel = '' }) =>
      generateRegistrationPath({
        section: RegistrationSection.CHANNEL_CONNECTION,
        channel,
        accountId: acc_id,
      }),
  },
].map((item) => ({ ...item, withSearchParams: true }))

import { api } from 'shared/api'

import { AccountApiSchemas } from './schema'

export const AccountApi = {
  favorite: api.base.createPost({
    url: '/account/favorite',
    schemas: AccountApiSchemas.favorite,
  }),
  hide: api.base.createPost({
    url: '/account/hide',
    schemas: AccountApiSchemas.hide,
  }),
  // TODO: https://youtrack.manychat.io/issue/TD-122/Error-on-currentAccountID-channel-connectFb
  connectFBChannel: api.account.createPost({
    url: '/channel/connectFb',
    schemas: AccountApiSchemas.connectFBChannel,
  }),
  setDisplayFlag: api.account.createPost({
    url: '/settings/setDisplayFlag',
    schemas: AccountApiSchemas.setDisplayFlag,
  }),
  getDisplayFlag: api.account.createGet({
    url: '/settings/getDisplayFlag',
    schemas: AccountApiSchemas.getDisplayFlag,
  }),
}

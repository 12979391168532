import React from 'react'
import cx from 'classnames'
import { Outlet } from 'react-router-dom'
import { useAppSelector } from 'reduxTyped'

import MobileBanner from 'common/banners/mobile/MobileBanner'
import NotifyBar from 'common/core/components/NotifyBar'
import { NotifyReloadBar } from 'common/core/components/NotifyReloadBar/NotifyReloadBar'
import { getCurrentAccount } from 'common/core/selectors/appSelectors'
import { isWebViewRoute } from 'utils/router/tools'

import cm from './Layout.module.css'

export const Layout = ({ children }: { children?: React.ReactNode }) => {
  const account = useAppSelector(getCurrentAccount)
  const hasNotifications = !isWebViewRoute()

  return (
    <div className={cm.wrapper}>
      {hasNotifications && <NotifyReloadBar />}
      <div className={cm.app} role="main">
        {children}
        <div className={cx(cm.contentWrapper)}>
          {account.id && hasNotifications && <NotifyBar />}
          {hasNotifications && <MobileBanner />}
          <div
            className="p-relative"
            style={{ height: `calc(100% - var(--notifications-bar-height)` }}
          >
            <Outlet />
          </div>
        </div>
      </div>
    </div>
  )
}

import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { l } from '@manychat/manyui'

import { ISelectedApplicationState } from 'common/actions/integrations/Applications/interfaces'
import { alert } from 'common/core'
import { IThunkAction } from 'common/core/interfaces/actions'
import { applications } from 'constants/API'
import { makePostActionCreator } from 'utils/api/redux/actions'
import { addFetchingCases } from 'utils/api/redux/reducers'
import { Body } from 'utils/api/types'

import { fetchApplications } from './applications'

const initialState: ISelectedApplicationState = {
  applicationId: null,
  fetching: false,
}

export const publishApplicationAction = makePostActionCreator<Body>(
  'application/publish',
  applications.endpoints.publish,
)

export const publishApplication =
  (id: number, changelog: string): IThunkAction =>
  async (dispatch) => {
    const body = { app_id: id, changelog }
    const result = await dispatch(publishApplicationAction({ body }))
    if (publishApplicationAction.rejected.match(result)) {
      return
    }

    dispatch(fetchApplications({}))
    dispatch(closeModal())
    alert(l.translate('The application was sent to review'), 'success')
  }

const publishSlice = createSlice({
  name: 'application/publish',
  initialState,
  reducers: {
    closeModal(state) {
      state.applicationId = null
    },
    openModal(state, action: PayloadAction<number>) {
      state.applicationId = action.payload
    },
  },
  extraReducers: (builder) => {
    addFetchingCases(builder, publishApplicationAction)
  },
})

export const { closeModal, openModal } = publishSlice.actions

export const { reducer } = publishSlice

export type UsercentricsServicesInfo = Record<string, { status: boolean; id: string } | undefined>

const getMockInfoAboutService = (scriptName: string | string[]) => {
  const isArray = Array.isArray(scriptName)
  if (isArray) {
    return scriptName.reduce((previous, current, index) => {
      previous[current] = { status: true, id: `${index}` }
      return previous
    }, {} as UsercentricsServicesInfo)
  }
  return { [scriptName]: { status: true, id: '0' } }
}

export const getInfoAboutService = (scriptName: string | string[]): UsercentricsServicesInfo => {
  const { hostname } = window.location
  const isLocalHost = hostname === 'localhost'
  const isArray = Array.isArray(scriptName)

  if (isLocalHost) {
    return getMockInfoAboutService(scriptName)
  }

  const { services = {} } = window?.__ucCmp?.cmpController?.dps ?? {}
  const keys = Object.keys(services)
  return keys.reduce((previous, current) => {
    const service = services[current]
    const isServiceInArray = isArray && scriptName.includes(service.name)
    if (isServiceInArray || service.name === scriptName) {
      previous[service.name] = { status: service.consent.given, id: current }
    }
    return previous
  }, {} as UsercentricsServicesInfo)
}

export interface UserCentricsDetail {
  type: string
}
interface UsercentricsEvent {
  type: string
  detail: UserCentricsDetail
}

export type CustomUserCentricsEvent = CustomEvent<UsercentricsEvent>

export const initializeUsercentricsListeners = (handle: (error?: UserCentricsDetail) => void) => {
  const usercentricsHandle = (event: Event) => {
    const customEvent = event as CustomUserCentricsEvent

    if (customEvent.type === 'UC_LOAD_ERROR') {
      handle(customEvent.detail)
      return
    }

    if (customEvent.type === 'UC_UI_INITIALIZED') {
      handle()
      return
    }
    switch (customEvent.detail.type) {
      case 'ACCEPT_ALL':
      case 'DENY_ALL':
      case 'SAVE':
        handle()
        break
      default:
        break
    }
  }
  window.addEventListener('UC_UI_INITIALIZED', usercentricsHandle)
  window.addEventListener('UC_UI_CMP_EVENT', usercentricsHandle)
  window.addEventListener('UC_LOAD_ERROR', usercentricsHandle)

  const unsubscribeListeners = () => {
    window.removeEventListener('UC_UI_INITIALIZED', usercentricsHandle)
    window.removeEventListener('UC_UI_CMP_EVENT', usercentricsHandle)
    window.removeEventListener('UC_LOAD_ERROR', usercentricsHandle)
  }

  return { unsubscribeListeners }
}

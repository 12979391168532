import { isBusinessEmailValid } from 'apps/email/emailHelpers'
import { getPreferredBusinessEmail } from 'apps/email/selectors/emailSelectors'
import * as blockActions from 'common/builder/actions/blockActions'
import * as builderStateActions from 'common/builder/actions/builderStateActions'
import * as nodeActions from 'common/builder/actions/nodeActions'
import { updateBuilderState } from 'common/builder/actions/updateBuilderState'
import blockRegistry from 'common/builder/blockRegistry'
import {
  EmailStyleFontTypePreset,
  EmailStyleAlignmentPreset,
} from 'common/builder/blocks/blockConstants'
import { IBuilderState, IEmailBuilderState } from 'common/builder/builderInterfaces'
import { BlockType } from 'common/builder/constants/BlockType'
import { EmailBuilderDeviceTarget } from 'common/builder/emailBuilder/emailBuilderConstants'
import {
  getEmailData,
  getRootBlock,
  isEmailBuilderOpen,
} from 'common/builder/emailBuilder/selectors/emailBuilderSelectors'
import { EmailStyleType } from 'common/builder/nodes/emailNew/emailNodeConstants'
import { IEmailNode, IEmailData } from 'common/builder/nodes/nodeInterfaces'
import * as builderStateSelectors from 'common/builder/selectors/builder/builderStateSelectors'
import * as entitySelectors from 'common/builder/selectors/builder/entitySelectors'
import { IThunkAction } from 'common/core/interfaces/actions'
import { analyticsService } from 'utils/services/analytics'

export const updateEmailBuilderState = (
  builderId: string,
  changes: Partial<IEmailBuilderState>,
): IThunkAction => {
  return (dispatch, getState) => {
    const current: IBuilderState = builderStateSelectors.getById(getState(), builderId)

    const emailBuilder = { ...current.emailBuilder, ...changes }
    dispatch(
      updateBuilderState(builderId, {
        emailBuilder,
      }),
    )
  }
}
export const openEmailBuilder = (builderId: string, nodeId: string): IThunkAction => {
  return (dispatch, getState) => {
    const isOpen = isEmailBuilderOpen(getState(), builderId)
    if (!isOpen) {
      analyticsService.sendEvent(`EMAIL.BUILDER_OPEN`)
    }

    dispatch(
      updateEmailBuilderState(builderId, {
        nodeId,
      }),
    )
  }
}
export const closeEmailBuilder = (builderId: string): IThunkAction => {
  return (dispatch, getState) => {
    const isOpen = isEmailBuilderOpen(getState(), builderId)
    if (isOpen) {
      analyticsService.sendEvent(`EMAIL.BUILDER_CLOSE`)
    }

    dispatch(builderStateActions.selectBlock(builderId, null))

    dispatch(
      updateEmailBuilderState(builderId, {
        nodeId: '',
      }),
    )
  }
}

export const setEmailBuilderDeviceTarget = (
  builderId: string,
  deviceTarget: EmailBuilderDeviceTarget,
): IThunkAction => {
  return (dispatch) => {
    dispatch(
      updateEmailBuilderState(builderId, {
        deviceTarget,
      }),
    )
  }
}

export const updateEmailData = (
  builderId: string,
  nodeId: string,
  changes: Partial<IEmailData>,
): IThunkAction => {
  return (dispatch, getState) => {
    const currentData = getEmailData(getState(), builderId, nodeId)
    const data = { ...currentData, ...changes }

    dispatch(nodeActions.updateNode(builderId, nodeId, { data }))
  }
}

export const createEmailNodePreset = (builderId: string, nodeId: string): IThunkAction => {
  return (dispatch, getState) => {
    const state = getState()
    const rootBlock = getRootBlock(state, builderId, nodeId)

    const textBlock1 = blockRegistry.getByType(BlockType.EMAIL_TEXT).create({
      text: 'I am a new email',
      settings: {
        font_type: { type: EmailStyleType.PRESET, value: EmailStyleFontTypePreset.H1 },
        alignment: { type: EmailStyleType.PRESET, value: EmailStyleAlignmentPreset.CENTER },
        padding_top: { type: EmailStyleType.CUSTOM, value: 36 },
      },
    })
    const textBlock2 = blockRegistry.getByType(BlockType.EMAIL_TEXT).create({
      text: 'Create your email by adding text, images and buttons. Continue automation from all the clickable elements. Add tags to segment your contacts.',
      settings: {
        alignment: { type: EmailStyleType.PRESET, value: EmailStyleAlignmentPreset.CENTER },
      },
    })

    const buttonBlock = blockRegistry.getByType(BlockType.EMAIL_BUTTON).create({
      settings: {
        padding_bottom: { type: EmailStyleType.CUSTOM, value: 36 },
      },
    })

    dispatch(blockActions.createLinkedBlock(builderId, rootBlock?.id, textBlock1))
    dispatch(blockActions.createLinkedBlock(builderId, rootBlock?.id, textBlock2))
    dispatch(blockActions.createLinkedBlock(builderId, rootBlock?.id, buttonBlock))
  }
}

export const setEmailNodePreferredBusinessEmail = (
  builderId: string,
  nodeId: string,
): IThunkAction => {
  return (dispatch, getState) => {
    const state = getState()
    const node = entitySelectors.getNodeById(state, builderId, nodeId) as IEmailNode
    const preferredBusinessEmail = getPreferredBusinessEmail(state)

    if (
      preferredBusinessEmail &&
      node.data.businessEmailId !== preferredBusinessEmail.id &&
      isBusinessEmailValid(preferredBusinessEmail)
    ) {
      dispatch(updateEmailData(builderId, nodeId, { businessEmailId: preferredBusinessEmail.id }))
    }
  }
}

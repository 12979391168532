import { ChannelType } from 'common/core/constants/ChannelType'
import { getLocalCaptionFor } from 'common/fields/api/localCaptions'
import { BackendAvailableFieldsMap } from 'common/fields/api/types'
import {
  FieldGroupId,
  FieldSegmentType,
  FieldStatus,
  FieldType,
} from 'common/fields/entities/enums'
import { Field } from 'common/fields/entities/field'
import { FilterField } from 'common/filter/models/AudienceFilter/constants'

export const parseAvailableFields = (
  data: BackendAvailableFieldsMap,
  fixSystemFields?: boolean,
) => {
  return [
    ...mapFields(FieldGroupId.RECOMMENDED, data.recommended),
    ...mapFields(FieldGroupId.STATIC, data.static),
    ...processSystemFields(
      mapFields(FieldGroupId.SYSTEM, [
        ...(data.system ?? []),
        ...(fixSystemFields ? MISSING_SYSTEM_FIELDS : []),
      ]),
    ),
    ...mapFields(FieldGroupId.USER, data.custom),
    ...mapFields(FieldGroupId.BOT, data.global),
    ...getIntegrationFields(data),
  ]
}

export const mapFields = (group: FieldGroupId, items?: UnsafeAnyObject[]): Field[] => {
  if (Array.isArray(items)) {
    return items.map(createParseField(group))
  }
  return []
}

export const createParseField =
  (group: FieldGroupId) =>
  (data: UnsafeAnyObject = {}): Field => {
    const id = group === FieldGroupId.RECOMMENDED ? `${data.name}_recommended` : data.name

    return {
      id,
      type: data.type,
      group,
      caption: getLocalCaptionFor(data.name) || data.caption,
      channel: getFieldChannel(group, data.name),
      description: data.description || '',
      status: data.status || FieldStatus.ACTIVE,
      segmentType: data.segment_type,
      folderId: data.folder_id,
      contextName: data.context_name,
    }
  }

const getIntegrationFields = (response: BackendAvailableFieldsMap): Field[] => {
  if (!response.integration) {
    return []
  }
  const result: Field[] = []
  for (const integrationId of Object.keys(response.integration)) {
    const fields = mapFields(FieldGroupId.INTEGRATION, response.integration[integrationId])
    fields.forEach((field) => {
      const prefix = field.id.startsWith(integrationId) ? '' : `${integrationId}.`
      field.id = `integration_user_field.${prefix}${field.id}`
      field.integrationId = integrationId
      result.push(field)
    })
  }
  return result
}

export const SystemFieldsOrder: FilterField[] = [
  FilterField.FIRST_NAME,
  FilterField.LAST_NAME,
  FilterField.FULL_NAME,
  FilterField.EMAIL,
  FilterField.PHONE,
  FilterField.LAST_INPUT_TEXT,
  FilterField.SUBSCRIBED,
  FilterField.USER_ID,
]

const processSystemFields = (fields: Field[]): Field[] => {
  return fields
    .filter(
      (field) =>
        field.id !== FilterField.OPTOUT_PHONE &&
        field.id !== FilterField.OPTOUT_EMAIL &&
        field.id !== FilterField.ACTIVE_SUBSCRIBER,
    )
    .sort((aField, bField) => {
      const aIndex = SystemFieldsOrder.indexOf(aField.id as FilterField)
      const bIndex = SystemFieldsOrder.indexOf(bField.id as FilterField)

      if (aIndex === -1 && bIndex === -1) {
        return 0
      }

      if (aIndex === -1) {
        return 1
      }

      if (bIndex === -1) {
        return -1
      }

      return aIndex - bIndex
    })
}

const MISSING_SYSTEM_FIELDS = [
  { name: FilterField.LAST_INPUT_TEXT, caption: 'Last Text Input' },
  { name: FilterField.PAGE_ID, caption: 'Facebook Page Id' },
  { name: FilterField.INSTAGRAM_ACCOUNT_NAME, caption: 'Instagram Page Name' },
  { name: FilterField.TIKTOK_ACCOUNT_USERNAME, caption: 'Your TikTok Username' },
  { name: FilterField.TIKTOK_ACCOUNT_DISPLAY_NAME, caption: 'Your TikTok Display Name' },
].map((item) => ({
  ...item,
  type: FieldType.TEXT,
  segment_type: FieldSegmentType.SUF,
}))

const getFieldChannel = (group: string, name: string) => {
  if (group === FieldGroupId.SYSTEM) {
    switch (name) {
      case FilterField.LAST_INTERACTION:
      case FilterField.LAST_SEEN:
      case FilterField.MESSAGING_WINDOW:
      case FilterField.EU_AFFECTED:
      case FilterField.MESSENGER:
      case FilterField.PAGE_ID:
      case FilterField.GENDER:
      case FilterField.LOCALE:
      case FilterField.LANGUAGE:
      case FilterField.TIMEZONE:
        return ChannelType.FB
      case FilterField.OPTIN_PHONE:
      case FilterField.PHONE_COUNTRY_CODE:
      case FilterField.PHONE_US_STATE:
        return ChannelType.SMS
      case FilterField.OPTIN_EMAIL:
        return ChannelType.EMAIL
      case FilterField.OPTIN_INSTAGRAM:
      case FilterField.LAST_IG_INTERACTION:
      case FilterField.LAST_IG_SEEN:
      case FilterField.IS_IG_ACCOUNT_FOLLOWER:
      case FilterField.IG_FOLLOWERS_COUNT:
      case FilterField.IS_IG_VERIFIED_USER:
      case FilterField.IS_IG_ACCOUNT_FOLLOW_USER:
      case FilterField.IG_USERNAME:
      case FilterField.IG_MESSAGING_WINDOW:
      case FilterField.INSTAGRAM_ACCOUNT_NAME:
        return ChannelType.INSTAGRAM
      case FilterField.TG_USERNAME:
      case FilterField.TG_USER_ID:
      case FilterField.TG_LAST_INTERACTION:
      case FilterField.OPTIN_TELEGRAM:
        return ChannelType.TELEGRAM
      case FilterField.OPTIN_WHATSAPP:
      case FilterField.WA_ID:
      case FilterField.LAST_WA_INTERACTION:
      case FilterField.LAST_WA_SEEN:
      case FilterField.WA_LAST_CART:
        return ChannelType.WHATSAPP
      case FilterField.OPTIN_TIKTOK:
      case FilterField.OPT_IN_THROUGH_TIKTOK_ADS:
      case FilterField.TT_USERNAME:
      case FilterField.LAST_TT_INTERACTION:
      case FilterField.LAST_TT_SEEN:
      case FilterField.TIKTOK_ACCOUNT_USERNAME:
      case FilterField.TIKTOK_ACCOUNT_DISPLAY_NAME:
      case FilterField.TT_DISPLAY_NAME:
      case FilterField.IS_TT_ACCOUNT_FOLLOWER:
        return ChannelType.TIKTOK
      default:
        return undefined
    }
  }

  if (group === FieldGroupId.STATIC) {
    switch (name) {
      case FilterField.ONE_TIME_NOTIFICATION_OPTIN:
      case FilterField.ONE_TIME_NOTIFICATION:
        return ChannelType.FB
    }
  }

  if (group === FieldGroupId.RECOMMENDED) {
    switch (name) {
      case FilterField.IS_IG_ACCOUNT_FOLLOWER:
        return ChannelType.INSTAGRAM
    }
  }

  if (group === FieldGroupId.RECOMMENDED) {
    switch (name) {
      case FilterField.IS_TT_ACCOUNT_FOLLOWER:
        return ChannelType.TIKTOK
    }
  }

  return undefined
}

import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { createAppAsyncThunk } from 'reduxTyped'
import { l } from '@manychat/manyui'

import { AiBusinessContextState } from 'apps/aiBusinessContext/store/types'
import { alert } from 'common/core'
import { handleCatch } from 'shared/api/lib/errors/handlers'
import { AiBusinessContextApi } from 'shared/api/requests/aiBusinessContext'

const NAMESPACE = 'aiBusinessContext'

export const AiBusinessContextInitialState: AiBusinessContextState = {
  contextText: '',
  isLoading: false,
}

const { reducer, actions } = createSlice({
  initialState: AiBusinessContextInitialState,
  name: NAMESPACE,
  reducers: {
    setContextText: (state, action: PayloadAction<string>) => {
      state.contextText = action.payload
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getInitialContext.pending, (state) => {
      state.isLoading = true
    })
    builder.addCase(updateContext.pending, (state) => {
      state.isLoading = true
    })
    builder.addCase(getInitialContext.fulfilled, (state) => {
      state.isLoading = false
    })
    builder.addCase(updateContext.fulfilled, (state) => {
      state.isLoading = false
    })
    builder.addCase(getInitialContext.rejected, (state) => {
      state.isLoading = false
    })
    builder.addCase(updateContext.rejected, (state) => {
      state.isLoading = false
    })
  },
})

const getState = (state: RootState) => state.aiBusinessContext

export const AiBusinessContextSelectors = {
  getContextText: (state: RootState) => getState(state).contextText,
  getIsLoading: (state: RootState) => getState(state).isLoading,
}

export const AiBusinessContextReducer = reducer

const getInitialContext = createAppAsyncThunk(
  `${NAMESPACE}/getInitialContext`,
  async (_, { dispatch }) => {
    try {
      const {
        data: { content },
      } = await AiBusinessContextApi.getAiBusinessContext()
      dispatch(actions.setContextText(content))
    } catch (error) {
      handleCatch(error)
    }
  },
)

const updateContext = createAppAsyncThunk<void, string>(`${NAMESPACE}/updateText`, async (text) => {
  try {
    await AiBusinessContextApi.setAiBusinessContext({ body: { content: text } })
    alert(l.translate('Business context updated!'), 'success')
  } catch (error) {
    handleCatch(error)
  }
})

export const AiBusinessContextActions = {
  getInitialContext,
  updateContext,
}

import { z } from 'zod'

import {
  WidgetTypes,
  WidgetStatusType,
  PostCoveredArea,
} from 'apps/growthTools/models/Widget/constants'
import { StorySelectType } from 'apps/instagram/models/interfaces'
import { ChannelType } from 'common/core/constants/ChannelType'

const WidgetBaseSchema = z.object({
  widget_id: z.number(),
  page_id: z.union([z.string(), z.number()]),
  widget_type: z.nativeEnum(WidgetTypes),
  status: z.nativeEnum(WidgetStatusType),
  name: z.string(),
  channel: z.nativeEnum(ChannelType),
  ts_created: z.number(),
  runs: z.number().nullable(),
  clicked_unq: z.number().nullable(),
  ctr: z
    .object({
      impressions: z.number(),
      optins: z.number(),
    })
    .nullable(),
  data: z.any(),
})

const LiveCommentsSchema = WidgetBaseSchema.extend({
  widget_type: z.literal(WidgetTypes.INSTAGRAM_LIVE_COMMENT_REPLY),
  data: z.object({
    keywords_condition: z.string(),
    keywords: z.array(z.string()),
  }),
})

const StoryReplySchema = WidgetBaseSchema.extend({
  widget_type: z.literal(WidgetTypes.INSTAGRAM_STORY_REPLY),
  data: z.object({
    story_id: z.string().nullable(),
    story_select: z.nativeEnum(StorySelectType),
    keywords_condition: z.string(),
    keywords: z.array(z.string()),
  }),
})

const CgtSchema = WidgetBaseSchema.extend({
  data: z
    .object({
      feed_comment_settings: z.object({
        exclude_keywords_array: z.array(z.string()).optional(),
        include_keywords_array: z.array(z.string()).optional(),
        post_data: z
          .object({
            media_url: z.string(),
          })
          .nullable()
          .optional(),
        post_covered_area: z.nativeEnum(PostCoveredArea).optional(),
      }),
    })
    .nullable(),
})

export const WidgetSchema = z.union([
  WidgetBaseSchema,
  LiveCommentsSchema,
  StoryReplySchema,
  CgtSchema,
])

export const WidgetsSchema = z.array(WidgetSchema)

export type LiveCommentsWidget = z.infer<typeof LiveCommentsSchema>
export type StoryReplyWidget = z.infer<typeof StoryReplySchema>
export type CgtWidget = z.infer<typeof CgtSchema>
export type Widget = z.infer<typeof WidgetSchema>

import { z } from 'zod'

import { AddOnSlug, PlanSlug } from '../constants'
import { makeGuardFromSchema } from '../utils/guards'

export const CurrencySchema = z.string()

export type Currency = z.infer<typeof CurrencySchema>

const ProductBase = z.object({
  isInCart: z.boolean().optional(),
  name: z.string(),
  price: z.number(),
  fullPrice: z.number().optional(),
  disabled: z.boolean().optional(),
  dynamicPrice: z.boolean().optional(),
})

export const ProductPlanSchema = ProductBase.extend({
  type: z.literal('plan'),
  slug: z.enum([PlanSlug.AUTOMATION, PlanSlug.INBOX]),
})

export const ProductAddOnSchema = ProductBase.extend({
  type: z.literal('addOn'),
  slug: z.nativeEnum(AddOnSlug),
})

export const ProductSchema = ProductPlanSchema.or(ProductAddOnSchema)

export type Product = z.infer<typeof ProductSchema>

export const ContextWithProductsSchema = z.object({
  products: z.array(ProductSchema),
})

export const isProductAddOn = makeGuardFromSchema(ProductAddOnSchema)

export const isProductPlan = makeGuardFromSchema(ProductPlanSchema)

import { delay } from 'utils'
import { Message, TriggerConfirmOptions } from 'common/builder/actions/helpers'
import { SimplyIds, StructureIds, SelectionIds } from 'common/builder/actions/interfaces/selections'
import { updateBuilderState } from 'common/builder/actions/updateBuilderState'
import { BUILDER_SELECT_NODES } from 'common/builder/constants/builderReduxActionTypes'
import { SELECTION_TYPE } from 'common/builder/models/BuilderState/constants'
import builderSelectors from 'common/builder/selectors/builder'
import { isAutosavedTriggerType } from 'common/builder/utils/isAutosavedTriggerType'
import { confirm } from 'common/core'
import { IAsyncThunkAction } from 'common/core/interfaces/actions'
import { getFlowTriggerByTriggerId } from 'common/flow/selectors/flowSelectors'
import { hasChanges } from 'common/flow/selectors/unifiedTriggerSelectors'
import { analyticsService } from 'utils/services/analytics'

export const ensureCurrentTriggerSaved = (builderId: string): IAsyncThunkAction<boolean> => {
  return async (dispatch, getState) => {
    const state = getState()
    const builderState = builderSelectors.builderState.getById(state, builderId)
    const selectedTrigger = getFlowTriggerByTriggerId(
      state,
      builderState.flow,
      builderState.selectedTrigger,
    )

    if (!selectedTrigger) {
      return true
    }

    if (isAutosavedTriggerType(selectedTrigger)) {
      return true
    }

    const isChanges = hasChanges(state, selectedTrigger)
    if (!isChanges) {
      return true
    }

    analyticsService.sendEvent('BUILDER.LEAVE_UNSAVED_TRIGGER_CONFIRM.SHOWN')

    await delay() // Delay is used due to modal bug with handling current click mouseUp and closing
    const confirmed = await confirm(Message, TriggerConfirmOptions)

    if (confirmed) {
      analyticsService.sendEvent('BUILDER.LEAVE_UNSAVED_TRIGGER_CONFIRM.LEAVE_CANCEL.CLICK')
      dispatch(updateBuilderState(builderId, { sidebarHidden: false }))
      return false
    }

    analyticsService.sendEvent('BUILDER.LEAVE_UNSAVED_TRIGGER_CONFIRM.LEAVE_CONFIRM.CLICK')
    return true
  }
}

const nodeSelection = (
  builderId: string,
  selectionType: SELECTION_TYPE,
  ids: SelectionIds = [],
): IAsyncThunkAction<boolean> => {
  return async (dispatch) => {
    const proceed = await dispatch(ensureCurrentTriggerSaved(builderId))

    if (!proceed) {
      return false
    }

    const idsArray: SimplyIds | StructureIds = Array.isArray(ids) ? ids : [ids]
    const idsResult: SimplyIds = []
    idsArray.forEach((item) => {
      if (item) {
        const id = typeof item === 'object' ? item.id : item
        idsResult.push(id)
      }
    })

    dispatch({
      type: BUILDER_SELECT_NODES,
      builderId,
      selectionType,
      ids: idsResult,
    })

    return true
  }
}

export const setSelection = (builderId: string, ids: SelectionIds) =>
  nodeSelection(builderId, SELECTION_TYPE.SET, ids)
export const mergeSelection = (builderId: string, ids: SelectionIds) =>
  nodeSelection(builderId, SELECTION_TYPE.MERGE, ids)
export const toggleSelection = (builderId: string, ids: SelectionIds) =>
  nodeSelection(builderId, SELECTION_TYPE.TOGGLE, ids)

export enum ButtonType {
  CONTENT = 'content',
  URL = 'url',
  CTA_URL = 'cta_url',
  CALL = 'call',
  SHARE = 'share',
  ANSWER = 'answer',
  BUY = 'buy',
  FLOW = 'flow',
  WA_LIST_MESSAGE_BUTTON = 'wa_list_message_button',
  CROSS_CHANNEL_CONTENT = 'cross_channel_content',
}

import React from 'react'
import { Outlet, useLocation } from 'react-router-dom'
import { useAppSelector } from 'reduxTyped'
import { l, Loader, sanitizeURL } from '@manychat/manyui'

import { getCurrentAccount } from 'common/core/selectors/appSelectors'
import { useDidMountEffect } from 'utils/commonHooks'
import { isLocal } from 'utils/isLocal'

import { ModalsList } from './ModalsList'

export const AccountScopeLayout = () => {
  const hasCurrentAccountData = useAppSelector(getCurrentAccount)?.id
  const { pathname, search } = useLocation()
  const url = window.location.origin.concat(pathname, search ?? '')
  const hasLocal = isLocal()

  useDidMountEffect(() => {
    if (hasLocal) {
      return
    }

    if (!hasCurrentAccountData) {
      window.location.assign(url)
    }
  })

  if (!hasCurrentAccountData) {
    return (
      <div className="align-center d-flex justify-center" style={{ height: '100%' }}>
        <div>
          <Loader />
          <div className="p-a">
            {l.translate('You will be redirected to')}{' '}
            <a className="text-underline" href={sanitizeURL(url)}>
              {url}
            </a>
            {hasLocal && (
              <div className="d-flex justify-center">
                <br />
                <span>Push the button for redirecting on localhost, please</span>
              </div>
            )}
          </div>
        </div>
      </div>
    )
  }

  return (
    <React.Suspense fallback={<Loader center />}>
      <Outlet />
      <ModalsList />
    </React.Suspense>
  )
}

import { Tag } from 'shared/api/requests/tags/schemas'

export enum TagCreationSource {
  TAGS_PICKER = 'tags_picker',
  SETTINGS = 'settings',
}

export type NewTagSource = TagCreationSource | `${TagCreationSource}_${string}`

export interface TagsExtraState {
  createTagModal: {
    isOpen: boolean
    tagName: string
    source: NewTagSource | null
    lastCreatedTag: Tag | null
  }
  search: {
    value: string
  }
  isSettingsTab: boolean
}

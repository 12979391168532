import React from 'react'
import cx from 'classnames'
import { z } from 'zod'

import { BlockType } from '../../constants'
import { registry } from '../../registry'
import { ClassNameSchema, StylesSchema } from '../../schemas'
import { BlockProps } from '../../types'

import cm from './Hr.module.css'

const HrPropsSchema = z.object({
  className: ClassNameSchema,
  text: z.string().optional(),
  style: StylesSchema,
})

const Component = (props: BlockProps<typeof HrPropsSchema>) => {
  if (props.text) {
    return (
      <div
        data-block={props.blockId}
        className={cx(cm.container, props.className)}
        style={props.style}
      >
        <hr className={cm.hr} />
        <span className="p-x-xs">{props.text}</span>
        <hr className={cm.hr} />
      </div>
    )
  }

  return (
    <hr
      {...props}
      data-block={props.blockId}
      className={cx(cm.hr, props.className)}
      style={props.style}
    />
  )
}

export const Hr = registry.register(Component, {
  props: HrPropsSchema,
  type: BlockType.HR,
})

enum constants {
  APPLICATION_FETCH = 'APPLICATION_FETCH',
  APPLICATION_FETCH_REQUEST = 'APPLICATION_FETCH_REQUEST',
  APPLICATION_FETCH_RESPONSE = 'APPLICATION_FETCH_RESPONSE',

  APPLICATION_INSTALL = 'APPLICATION_INSTALL',
  APPLICATION_INSTALL_REQUEST = 'APPLICATION_INSTALL_REQUEST',
  APPLICATION_INSTALL_RESPONSE = 'APPLICATION_INSTALL_RESPONSE',
  APPLICATION_INSTALL_SET_STEP = 'APPLICATION_INSTALL_SET_STEP',

  APPLICATION_INSTALL_ACCOUNTS_FETCH = 'APPLICATION_INSTALL_ACCOUNTS_FETCH',
  APPLICATION_INSTALL_ACCOUNTS_FETCH_REQUEST = 'APPLICATION_INSTALL_ACCOUNTS_FETCH_REQUEST',
  APPLICATION_INSTALL_ACCOUNTS_FETCH_RESPONSE = 'APPLICATION_INSTALL_ACCOUNTS_FETCH_RESPONSE',
}

export default constants

import React, { useCallback } from 'react'
import cx from 'classnames'

import { FilterPickerCheckbox } from 'apps/cms/components/Flows/components/FlowFilters/components/FilterPicker/FilterPickerCheckbox/FilterPickerCheckbox'
import {
  Option,
  OptionsGroup,
} from 'apps/cms/components/Flows/components/FlowFilters/components/FilterPicker/lib/types'

import cm from './FilterPickerParent.module.css'

interface FilterPickerParentProps {
  option: OptionsGroup
  selectedOptions: Option[]
  setSelectedOptions: React.Dispatch<React.SetStateAction<Option[]>>
}

export const FilterPickerParent = ({
  option,
  selectedOptions,
  setSelectedOptions,
}: FilterPickerParentProps) => {
  const toggleOptionsGroup = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>, option: OptionsGroup) => {
      setSelectedOptions((prevSelectedOptions) => {
        if (event.target.checked) {
          const keys = prevSelectedOptions.map((option) => option.key)
          const newUniqOptions = option.children.filter(
            (childOption) => !keys.includes(childOption.key),
          )

          return [...prevSelectedOptions, ...newUniqOptions]
        }

        return prevSelectedOptions.filter((prevOption) => {
          return !option.children.some((childOption) => childOption.key === prevOption.key)
        })
      })
    },
    [setSelectedOptions],
  )

  const isOptionsGroupChecked = option.children.every((childOption) =>
    selectedOptions.map((option) => option.key).includes(childOption.key),
  )

  return (
    <label
      title={option.label}
      htmlFor={option.group}
      className={cx('text-sm', cm.filterGroupHeading, {
        [cm.filterGroupHeading_checked]: isOptionsGroupChecked,
      })}
    >
      {option.label}

      <FilterPickerCheckbox
        id={option.group}
        onChange={(event) => toggleOptionsGroup(event, option)}
        checked={isOptionsGroupChecked}
      />
    </label>
  )
}

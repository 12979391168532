import { InitialApplicationState, LongTaskEvent } from 'utils/longTaskTracker/interfaces'
import { analyticsService } from 'utils/services/analytics'

const CURRENT_DATE = new Date().getTime()

export const getEventsForType = (entries: LongTaskEvent[]) => {
  const firstEntry = entries[0].entry
  const lastEntry = entries[entries.length - 1].entry
  const maxTaskDuration = entries.reduce((acc, { entry }) => Math.max(acc, entry.duration), 0)

  const startTime = new Date(CURRENT_DATE + firstEntry.startTime).toISOString()
  const endTime = new Date(CURRENT_DATE + lastEntry.startTime + lastEntry.duration).toISOString()

  const entriesSummary = entries.reduce<{ count: number; ms: number }>(
    (acc, { entry }) => ({
      count: acc.count + 1,
      ms: acc.ms + entry.duration,
    }),
    { count: 0, ms: 0 },
  )

  return {
    ...entriesSummary,
    startTime,
    endTime,
    maxTaskDuration,
    entries: JSON.stringify(entries),
  }
}

export const sendEntryToDataWarehouse = (
  entries: LongTaskEvent[],
  initialApplicationState: InitialApplicationState,
) => {
  const longTaskEntries = getEventsForType(entries)

  const analytics = { ...initialApplicationState, ...longTaskEntries }
  analyticsService.sendEvent('LONG_TASK', analytics)
}

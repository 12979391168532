import { l } from '@manychat/manyui'

import { FieldType } from 'common/fields/entities/enums'
import { FilterFieldOperatorData } from 'common/filter/interfaces'

export const InputDisplayType = {
  NONE: 'none',
  TEXT_INPUT: 'textInput',
  NUMBER: 'number',
  FIELD_VALUE_PICKER: 'fieldValuePicker',
  DATEPICKER: 'datePicker',
  DELAY: 'delay',
  SYSTEM_CURRENT_DATETIME_BETWEEN: 'system_current_datetime_between',
}

export enum FieldTypeDefaultOperator {
  IS_UNKNOWN = 'IS_UNKNOWN',
  IS_TRUE = 'IS_TRUE',
}

export enum FieldTypeOperator {
  CASE = 'CASE',
  IS = 'IS',
  IS_NOT = 'IS_NOT',
  IN = 'IN',
  NOT_IN = 'NOT IN',
  CONTAINS = 'CONTAINS',
  DOES_NOT_CONTAINS = 'DOES_NOT_CONTAINS',
  BEGIN_WITH = 'BEGIN_WITH',
  GREATER_THAN = 'GREATER_THAN',
  GREATER_THAN_OR_EQUAL = 'GREATER_THAN_OR_EQUAL',
  LESS_THAN = 'LESS_THAN',
  LESS_THAN_OR_EQUAL = 'LESS_THAN_OR_EQUAL',
  AFTER = 'AFTER',
  BEFORE = 'BEFORE',
  ON = 'ON',
  TRUE = 'TRUE',
  FALSE = 'FALSE',
  HAS_VALUE = 'HAS_VALUE',
  IS_UNKNOWN = 'IS_UNKNOWN',
  DATETIME_INTERVAL_AFTER = 'DATETIME_INTERVAL_AFTER',
  DATETIME_INTERVAL_BEFORE = 'DATETIME_INTERVAL_BEFORE',
  BETWEEN = 'BETWEEN',
  UNKNOWN_OPERATOR = 'UNKNOWN_OPERATOR',
}

export enum FieldTypeConditionOperator {
  ENUM_SEGMENT_IS = 'ENUM_SEGMENT_IS',
  ENUM_IS = 'ENUM_IS',
  ENUM_IS_NOT = 'ENUM_IS_NOT',
  IN = 'IN',
  NOT_IN = 'NOT_IN',
  IS = 'IS',
  IS_NOT = 'IS_NOT',
  CONTAINS = 'CONTAINS',
  NOT_CONTAINS = 'NOT_CONTAINS',
  BEGINS_WITH = 'BEGINS_WITH',
  GREATER_THAN = 'GREATER_THAN',
  GREATER_THAN_OR_EQUAL = 'GREATER_THAN_OR_EQUAL',
  LESS_THAN = 'LESS_THAN',
  LESS_THAN_OR_EQUAL = 'LESS_THAN_OR_EQUAL',
  NUMBER_IS = 'NUMBER_IS',
  NUMBER_IS_NOT = 'NUMBER_IS_NOT',
  AFTER = 'AFTER',
  BEFORE = 'BEFORE',
  ON = 'ON',
  IS_TRUE = 'IS_TRUE',
  IS_FALSE = 'IS_FALSE',
  HAS_VALUE = 'HAS_VALUE',
  IS_UNKNOWN = 'IS_UNKNOWN',
  DATETIME_INTERVAL_AFTER = 'DATETIME_INTERVAL_AFTER',
  DATETIME_INTERVAL_BEFORE = 'DATETIME_INTERVAL_BEFORE',
  CURRENT_TIME_BETWEEN = 'CURRENT_TIME_BETWEEN',
  ARRAY_CONTAINS = 'ARRAY_CONTAINS',
  ARRAY_DOES_NOT_CONTAIN = 'ARRAY_DOES_NOT_CONTAIN',
}

export const conditionOperators: Record<FieldTypeConditionOperator, FilterFieldOperatorData> = {
  //ENUM
  [FieldTypeConditionOperator.ENUM_SEGMENT_IS]: {
    value: FieldTypeOperator.CASE,
    label: 'is',
    changedLabel: 'has changed to',
    translatedChangedLabel: l.makeTranslate('has changed to'),
    translatedLabel: l.makeTranslate('is'),
    display: 'fieldValuePicker',
  },
  [FieldTypeConditionOperator.ENUM_IS]: {
    value: FieldTypeOperator.IS,
    label: 'is',
    changedLabel: 'has changed to',
    display: 'fieldValuePicker',
    translatedChangedLabel: l.makeTranslate('has changed to'),
    translatedLabel: l.makeTranslate('is'),
  },
  [FieldTypeConditionOperator.ENUM_IS_NOT]: {
    value: FieldTypeOperator.IS_NOT,
    label: 'isn`t',
    changedLabel: 'has changed and isn`t',
    display: 'fieldValuePicker',
    translatedChangedLabel: l.makeTranslate('has changed and isn`t'),
    translatedLabel: l.makeTranslate('isn`t'),
  },
  // SET
  [FieldTypeConditionOperator.IN]: {
    value: FieldTypeOperator.IN,
    label: 'is',
    changedLabel: 'has value',
    display: 'fieldValuePicker',
    translatedChangedLabel: l.makeTranslate('has value'),
    translatedLabel: l.makeTranslate('is'),
  },
  [FieldTypeConditionOperator.NOT_IN]: {
    value: FieldTypeOperator.NOT_IN,
    label: 'isn`t',
    changedLabel: 'has not value',
    display: 'fieldValuePicker',
    translatedChangedLabel: l.makeTranslate('has not value'),
    translatedLabel: l.makeTranslate('isn`t'),
  },
  // STRING
  [FieldTypeConditionOperator.IS]: {
    value: FieldTypeOperator.IS,
    label: 'is',
    changedLabel: 'has changed to',
    display: 'textInput',
    translatedChangedLabel: l.makeTranslate('has changed to'),
    translatedLabel: l.makeTranslate('is'),
  },
  [FieldTypeConditionOperator.IS_NOT]: {
    value: FieldTypeOperator.IS_NOT,
    label: 'isn`t',
    changedLabel: 'has changed and isn`t',
    display: 'textInput',
    translatedChangedLabel: l.makeTranslate('has changed and isn`t'),
    translatedLabel: l.makeTranslate('isn`t'),
  },
  [FieldTypeConditionOperator.CONTAINS]: {
    value: FieldTypeOperator.CONTAINS,
    label: 'contains',
    changedLabel: 'has changed and contains',
    display: 'textInput',
    translatedChangedLabel: l.makeTranslate('has changed and contains'),
    translatedLabel: l.makeTranslate('contains'),
  },
  [FieldTypeConditionOperator.NOT_CONTAINS]: {
    value: FieldTypeOperator.DOES_NOT_CONTAINS,
    label: "doesn't contain",
    changedLabel: "has changed and doesn't contain",
    display: 'textInput',
    translatedChangedLabel: l.makeTranslate("has changed and doesn't contain"),
    translatedLabel: l.makeTranslate("doesn't contain"),
  },
  [FieldTypeConditionOperator.BEGINS_WITH]: {
    value: FieldTypeOperator.BEGIN_WITH,
    label: 'begins with',
    changedLabel: 'has changed and begins with',
    display: 'textInput',
    translatedChangedLabel: l.makeTranslate('has changed and begins with'),
    translatedLabel: l.makeTranslate('begins with'),
  },
  // NUMBER
  [FieldTypeConditionOperator.GREATER_THAN]: {
    value: FieldTypeOperator.GREATER_THAN,
    label: 'greater than',
    changedLabel: 'has changed to greater than',
    display: 'number',
    translatedChangedLabel: l.makeTranslate('has changed to greater than'),
    translatedLabel: l.makeTranslate('greater than'),
  },
  [FieldTypeConditionOperator.GREATER_THAN_OR_EQUAL]: {
    value: FieldTypeOperator.GREATER_THAN_OR_EQUAL,
    label: 'greater than or equal',
    changedLabel: 'has changed to greater than or equal',
    display: 'number',
    translatedChangedLabel: l.makeTranslate('has changed to greater than or equal'),
    translatedLabel: l.makeTranslate('greater than or equal'),
  },
  [FieldTypeConditionOperator.LESS_THAN]: {
    value: FieldTypeOperator.LESS_THAN,
    label: 'less than',
    changedLabel: 'has changed to less than',
    display: 'number',
    translatedChangedLabel: l.makeTranslate('has changed to less than'),
    translatedLabel: l.makeTranslate('less than'),
  },
  [FieldTypeConditionOperator.LESS_THAN_OR_EQUAL]: {
    value: FieldTypeOperator.LESS_THAN_OR_EQUAL,
    label: 'less than or equal',
    changedLabel: 'has changed to less than or equal',
    display: 'number',
    translatedChangedLabel: l.makeTranslate('has changed to less than or equal'),
    translatedLabel: l.makeTranslate('less than or equal'),
  },
  [FieldTypeConditionOperator.NUMBER_IS]: {
    value: FieldTypeOperator.IS,
    label: 'is exactly',
    changedLabel: 'has changed to exactly',
    display: 'number',
    translatedChangedLabel: l.makeTranslate('has changed to exactly'),
    translatedLabel: l.makeTranslate('is exactly'),
  },
  [FieldTypeConditionOperator.NUMBER_IS_NOT]: {
    value: FieldTypeOperator.IS_NOT,
    label: 'isn`t',
    changedLabel: 'has changed and isn`t',
    display: 'number',
    translatedChangedLabel: l.makeTranslate('has changed and isn`t'),
    translatedLabel: l.makeTranslate('isn`t'),
  },
  // DATE
  [FieldTypeConditionOperator.AFTER]: {
    value: FieldTypeOperator.AFTER,
    label: 'after',
    changedLabel: 'has changed to after',
    display: 'datePicker',
    translatedChangedLabel: l.makeTranslate('has changed to after'),
    translatedLabel: l.makeTranslate('after'),
  },
  [FieldTypeConditionOperator.BEFORE]: {
    value: FieldTypeOperator.BEFORE,
    label: 'before',
    changedLabel: 'has changed to before',
    display: 'datePicker',
    translatedChangedLabel: l.makeTranslate('has changed to before'),
    translatedLabel: l.makeTranslate('before'),
  },
  [FieldTypeConditionOperator.ON]: {
    value: FieldTypeOperator.ON,
    label: 'on',
    changedLabel: 'has changed to',
    display: 'datePicker',
    translatedChangedLabel: l.makeTranslate('has changed to'),
    translatedLabel: l.makeTranslate('on'),
  },
  // BOOLEAN
  [FieldTypeConditionOperator.IS_TRUE]: {
    value: FieldTypeOperator.TRUE,
    label: 'is true',
    changedLabel: 'has changed and is true',
    display: 'none',
    ignoreValue: true,
    translatedChangedLabel: l.makeTranslate('has changed and is true'),
    translatedLabel: l.makeTranslate('is true'),
  },
  [FieldTypeConditionOperator.IS_FALSE]: {
    value: FieldTypeOperator.FALSE,
    label: 'is false',
    changedLabel: 'has changed and is false',
    display: 'none',
    ignoreValue: true,
    translatedChangedLabel: l.makeTranslate('has changed and is false'),
    translatedLabel: l.makeTranslate('is false'),
  },
  // COMMON
  [FieldTypeConditionOperator.HAS_VALUE]: {
    value: FieldTypeOperator.HAS_VALUE,
    label: 'has any value',
    changedLabel: 'has changed to any value',
    display: 'none',
    ignoreValue: true,
    translatedChangedLabel: l.makeTranslate('has changed to any value'),
    translatedLabel: l.makeTranslate('has any value'),
  },
  [FieldTypeConditionOperator.IS_UNKNOWN]: {
    value: FieldTypeOperator.IS_UNKNOWN,
    label: 'is unknown',
    changedLabel: 'has changed and is empty',
    display: 'none',
    ignoreValue: true,
    translatedChangedLabel: l.makeTranslate('has changed and is empty'),
    translatedLabel: l.makeTranslate('is unknown'),
  },
  [FieldTypeConditionOperator.DATETIME_INTERVAL_AFTER]: {
    value: FieldTypeOperator.DATETIME_INTERVAL_AFTER,
    label: 'less than',
    changedLabel: 'has changed to less than',
    display: 'delay',
    translatedChangedLabel: l.makeTranslate('has changed to less than'),
    translatedLabel: l.makeTranslate('less than'),
  },
  [FieldTypeConditionOperator.DATETIME_INTERVAL_BEFORE]: {
    value: FieldTypeOperator.DATETIME_INTERVAL_BEFORE,
    label: 'more than',
    changedLabel: 'has changed to more than',
    display: 'delay',
    translatedChangedLabel: l.makeTranslate('has changed to more than'),
    translatedLabel: l.makeTranslate('more than'),
  },
  [FieldTypeConditionOperator.CURRENT_TIME_BETWEEN]: {
    value: FieldTypeOperator.BETWEEN,
    label: 'between',
    changedLabel: 'has changed to',
    display: 'system_current_datetime_between',
    translatedChangedLabel: l.makeTranslate('has changed to'),
    translatedLabel: l.makeTranslate('between'),
  },
  [FieldTypeConditionOperator.ARRAY_CONTAINS]: {
    value: FieldTypeOperator.IN,
    label: 'contains',
    changedLabel: null,
    display: 'textInput',
    translatedChangedLabel: null,
    translatedLabel: l.makeTranslate('contains'),
  },
  [FieldTypeConditionOperator.ARRAY_DOES_NOT_CONTAIN]: {
    value: FieldTypeOperator.NOT_IN,
    label: "doesn't contain",
    changedLabel: null,
    display: 'textInput',
    translatedChangedLabel: null,
    translatedLabel: l.makeTranslate("doesn't contain"),
  },
}

export const FieldTypeDefaultOperators: Partial<Record<FieldType, FilterFieldOperatorData>> = {
  [FieldType.SYSTEM_TEXT]: conditionOperators.IS_UNKNOWN,
  [FieldType.BOOLEAN]: conditionOperators.IS_TRUE,
}

export const FieldTypesOperators: Partial<Record<FieldType, FilterFieldOperatorData[]>> = {
  [FieldType.ENUM]: [
    conditionOperators.ENUM_IS,
    conditionOperators.ENUM_IS_NOT,
    conditionOperators.HAS_VALUE,
    conditionOperators.IS_UNKNOWN,
  ],
  [FieldType.TEXT]: [
    conditionOperators.IS,
    conditionOperators.IS_NOT,
    conditionOperators.CONTAINS,
    conditionOperators.NOT_CONTAINS,
    conditionOperators.BEGINS_WITH,
    conditionOperators.HAS_VALUE,
    conditionOperators.IS_UNKNOWN,
  ],
  [FieldType.NUMBER]: [
    conditionOperators.GREATER_THAN,
    conditionOperators.GREATER_THAN_OR_EQUAL,
    conditionOperators.LESS_THAN,
    conditionOperators.LESS_THAN_OR_EQUAL,
    conditionOperators.NUMBER_IS,
    conditionOperators.NUMBER_IS_NOT,
    conditionOperators.HAS_VALUE,
    conditionOperators.IS_UNKNOWN,
  ],
  [FieldType.BOOLEAN]: [
    conditionOperators.IS_TRUE,
    conditionOperators.IS_FALSE,
    conditionOperators.HAS_VALUE,
    conditionOperators.IS_UNKNOWN,
  ],
  [FieldType.DATE]: [
    conditionOperators.AFTER,
    conditionOperators.BEFORE,
    conditionOperators.ON,
    conditionOperators.HAS_VALUE,
    conditionOperators.IS_UNKNOWN,
  ],
  [FieldType.DATETIME]: [
    conditionOperators.AFTER,
    conditionOperators.BEFORE,
    conditionOperators.ON,
    conditionOperators.HAS_VALUE,
    conditionOperators.IS_UNKNOWN,
  ],
  [FieldType.SET]: [conditionOperators.IN, conditionOperators.NOT_IN],

  [FieldType.SYSTEM_ENUM]: [
    conditionOperators.ENUM_IS,
    conditionOperators.ENUM_IS_NOT,
    conditionOperators.HAS_VALUE,
    conditionOperators.IS_UNKNOWN,
  ],
  [FieldType.SYSTEM_TEXT]: [
    conditionOperators.IS,
    conditionOperators.IS_NOT,
    conditionOperators.HAS_VALUE,
    conditionOperators.CONTAINS,
    conditionOperators.NOT_CONTAINS,
    conditionOperators.BEGINS_WITH,
    conditionOperators.IS_UNKNOWN,
  ],
  [FieldType.SYSTEM_TAG]: [conditionOperators.ENUM_IS, conditionOperators.ENUM_IS_NOT],
  [FieldType.SYSTEM_NUMBER]: [
    conditionOperators.GREATER_THAN,
    conditionOperators.GREATER_THAN_OR_EQUAL,
    conditionOperators.LESS_THAN,
    conditionOperators.LESS_THAN_OR_EQUAL,
    conditionOperators.NUMBER_IS,
    conditionOperators.NUMBER_IS_NOT,
    conditionOperators.HAS_VALUE,
    conditionOperators.IS_UNKNOWN,
  ],
  [FieldType.SYSTEM_DATETIME]: [
    conditionOperators.AFTER,
    conditionOperators.BEFORE,
    conditionOperators.ON,
    conditionOperators.HAS_VALUE,
    conditionOperators.IS_UNKNOWN,
    conditionOperators.DATETIME_INTERVAL_AFTER,
    conditionOperators.DATETIME_INTERVAL_BEFORE,
  ],
  [FieldType.SYSTEM_SWITCH]: [conditionOperators.ENUM_SEGMENT_IS],
  [FieldType.SYSTEM_CURRENT_DATETIME]: [
    conditionOperators.AFTER,
    conditionOperators.BEFORE,
    conditionOperators.CURRENT_TIME_BETWEEN,
  ],
  [FieldType.ARRAY]: [conditionOperators.ARRAY_CONTAINS, conditionOperators.ARRAY_DOES_NOT_CONTAIN],
}

export const UNARY_OPERATORS = ['HAS_VALUE', 'IS_UNKNOWN', 'TRUE', 'FALSE']

export const UserFieldDateTypes = {
  FORMULA: 'is_formula',
  SET: '=',
}

export const UserFieldDateOptions = [
  {
    value: UserFieldDateTypes.SET,
    label: 'Specific date',
  },
  {
    value: UserFieldDateTypes.FORMULA,
    label: 'Custom field date',
  },
]

export const DATE_FORMULA = 'createDate'
export const DATE_TIME_FORMULA = 'createDateTime'

export const OffsetOperationsTypes = {
  PLUS: '+',
  MINUS: '-',
}

export const OffsetOperations = [
  {
    value: OffsetOperationsTypes.PLUS,
    label: '+',
  },
  {
    value: OffsetOperationsTypes.MINUS,
    label: '-',
  },
]

export enum FilterField {
  // Static
  TAG = 'tag',
  WIDGET = 'widget',
  OPTIN_THROUGH_ADS = 'ads_growth_tool',
  SEQUENCE = 'sequence',
  ONE_TIME_NOTIFICATION = 'one_time_notification',
  ONE_TIME_NOTIFICATION_OPTIN = 'one_time_notification_optin',
  IG_RECURRING_NOTIFICATION = 'ig_recurring_notification',
  IG_RECURRING_NOTIFICATION_OPTIN = 'ig_recurring_notification_optin',
  CONVERSATION_CHANNEL = 'conversation_channel',
  CURRENT_DATETIME = 'system_current_datetime',
  SMART_SEGMENT = 'smart_segment',
  OPT_IN_THROUGH_API = 'opt_in_through_api',

  // System
  GENDER = 'gender',
  LOCALE = 'locale',
  LANGUAGE = 'language',
  TIMEZONE = 'timezone',
  FULL_NAME = 'full_name',
  FIRST_NAME = 'first_name',
  LAST_NAME = 'last_name',
  SUBSCRIBED = 'subscribed',
  USER_ID = 'user_id',
  LAST_INTERACTION = 'last_interaction',
  LAST_SEEN = 'last_seen',
  LAST_IG_INTERACTION = 'last_ig_interaction',
  CHANNEL = 'thread_last_channel',
  LAST_IG_SEEN = 'last_ig_seen',
  IS_IG_ACCOUNT_FOLLOWER = 'is_ig_account_follower',
  IG_FOLLOWERS_COUNT = 'ig_followers_count',
  IS_IG_VERIFIED_USER = 'is_ig_verified_user',
  IS_IG_ACCOUNT_FOLLOW_USER = 'is_ig_account_follow_user',
  IG_USERNAME = 'ig_username',

  // Hardcoded on frontend
  LAST_INPUT_TEXT = 'last_input_text',
  INSTAGRAM_ACCOUNT_NAME = 'instagram_account_name',
  PAGE_ID = 'page_id',
  TIKTOK_ACCOUNT_USERNAME = 'tiktok_account_username',
  TIKTOK_ACCOUNT_DISPLAY_NAME = 'tiktok_account_display_name',

  MESSAGING_WINDOW = 'messaging_window',
  IG_MESSAGING_WINDOW = 'ig_messaging_window',
  // System: EU Privacy Changes affect mark
  EU_AFFECTED = 'is_eu_affected',

  // sms
  OPTIN_PHONE = 'optin_phone',
  OPTOUT_PHONE = 'optout_phone',
  PHONE = 'phone',
  PHONE_COUNTRY_CODE = 'phone_country_code',
  PHONE_US_STATE = 'phone_us_state',

  // email
  OPTIN_EMAIL = 'optin_email',
  OPTOUT_EMAIL = 'optout_email',
  EMAIL = 'email',
  MESSENGER = 'messenger',

  ACTIVE_SUBSCRIBER = 'active_subscriber',

  OPTIN_INSTAGRAM = 'optin_instagram',

  // telegram
  TG_USERNAME = 'tg_username',
  TG_USER_ID = 'tg_user_id',
  TG_LAST_INTERACTION = 'last_tg_interaction',
  OPTIN_TELEGRAM = 'optin_telegram',

  // whatsapp
  OPTIN_WHATSAPP = 'optin_whatsapp',
  WA_ID = 'wa_id',
  LAST_WA_INTERACTION = 'last_wa_interaction',
  LAST_WA_SEEN = 'last_wa_seen',
  WA_LAST_CART = 'wa_last_cart',

  // tiktok
  OPTIN_TIKTOK = 'optin_tiktok',
  OPT_IN_THROUGH_TIKTOK_ADS = 'opt_in_through_tiktok_ads',
  TT_USERNAME = 'tt_username',
  TT_DISPLAY_NAME = 'tt_display_name',
  TT_USER_ID = 'tt_user_id',
  LAST_TT_INTERACTION = 'last_tt_interaction',
  LAST_TT_SEEN = 'last_tt_seen',
  IS_TT_ACCOUNT_FOLLOWER = 'is_tt_account_follower',
}

export const FilterChannelType = {
  MESSENGER: 'messenger',
  SMS: 'sms',
}

export const FilterChannelTypeLabel = {
  [FilterChannelType.MESSENGER]: 'Messenger',
  [FilterChannelType.SMS]: 'SMS',
}

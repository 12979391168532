import dot from 'dot-prop-immutable'
import get from 'lodash/get'

import * as atypes from 'apps/triggerRules/constants/TriggerRulesReduxActionTypes'
import {
  makeLimitedListReducer,
  makeDetailsReducer,
  addToList,
  mergeToListItem,
  setCurrentItem,
} from 'utils/factory'

const listReducer = makeLimitedListReducer('triggerRules')
const detailsReducer = makeDetailsReducer('triggerRules')

function rulesDetailsReducer(state, action) {
  state = detailsReducer(state, action)

  switch (action.type) {
    case 'TRIGGERRULES_FETCH_RESPONSE': {
      return dot.set(state, 'templateInstallations', action.data.template_installations)
    }
    case atypes.TRIGGERRULES_COPY_RESPONSE: {
      const { item } = action.data
      const data = setCurrentItem(state.byId[item.rule_id] || {}, item)
      return dot.set(state, `byId.${item.rule_id}`, data)
    }
    case atypes.TRIGGERRULES_CHANGESTATUS_REQUEST: {
      if (get(state, `byId.${action.id}.item`)) {
        return dot.merge(state, `byId.${action.id}.item`, { isPendingStatusChange: true })
      }
      return state
    }
    case atypes.TRIGGERRULES_CHANGESTATUS_ERROR: {
      if (get(state, `byId.${action.id}.item`)) {
        return dot.merge(state, `byId.${action.id}.item`, { isPendingStatusChange: false })
      }
      return state
    }

    case atypes.TRIGGERRULES_CURRENT_UPDATED_NOTIFICATION: {
      const { item } = action
      const { setTitle, caption, title } = item

      if (state.byId[item.rule_id]?.item) {
        const newState = setTitle ? { caption, title } : item
        return dot.merge(state, `byId.${item.rule_id}.item`, newState)
      }

      return state
    }

    case atypes.TRIGGERRULES_STATUS_UPDATED_NOTIFICATION: {
      const { statusData } = action
      const { result, done } = statusData

      if (state.byId[result.rule_id]?.item) {
        return dot.merge(state, `byId.${result.rule_id}.item`, {
          isPendingStatusChange: !done,
        })
      }
      return state
    }

    case atypes.TRIGGERRULES_DELETE_RESPONSE: {
      if (get(state, `byId.${action.id}`)) {
        return dot.merge(state, `byId.${action.id}`, { isDeleted: true })
      }
      return state
    }

    case atypes.TRIGGERRULES_RESTORE_RESPONSE: {
      if (get(state, `byId.${action.id}`)) {
        return dot.merge(state, `byId.${action.id}`, { isDeleted: false })
      }
    }
  }

  return state
}

function triggerRulesListReducer(state, action) {
  state = listReducer(state, action)

  switch (action.type) {
    case atypes.TRIGGERRULES_STATUS_UPDATED_NOTIFICATION: {
      const { statusData } = action
      const { result, done } = statusData
      const item = state.byId[result.rule_id]

      if (item) {
        return mergeToListItem(state, result.rule_id, { isPendingStatusChange: !done })
      }
      return state
    }

    case atypes.TRIGGERRULES_COPY_RESPONSE: {
      const { item } = action.data
      state.byId[item.id]
      if (!state.byId[item.id]) {
        return addToList(state, item.id, item)
      }

      return state
    }
    case atypes.TRIGGERRULES_CHANGESTATUS_REQUEST: {
      const item = state.byId[action.id]

      if (item) {
        return mergeToListItem(state, action.id, { ...item, isPendingStatusChange: true })
      }

      return state
    }
    case atypes.TRIGGERRULES_CHANGESTATUS_ERROR: {
      const item = state.byId[action.id]
      if (item) {
        return mergeToListItem(state, item.id, { ...item, isPendingStatusChange: false })
      }
      return state
    }

    case atypes.TRIGGERRULES_CURRENT_UPDATED_NOTIFICATION: {
      const { item } = action

      const exists = !!state.byId[item.rule_id]
      if (exists) {
        return mergeToListItem(state, item.rule_id, {
          ...item,
        })
      }

      return state
    }

    case atypes.TRIGGERRULES_CURRENT_SETTITLE_RESPONSE: {
      const { item } = action.data

      const exists = !!state.byId[item.id]
      if (exists) {
        return mergeToListItem(state, item.id, item)
      }
      return state
    }

    case atypes.TRIGGERRULES_RESTORE_RESPONSE: {
      const { item } = action.data
      return addToList(state, action.id, item, true)
    }

    case atypes.TRIGGERRULES_CREATE_REQUEST: {
      return { ...state, creating: true }
    }

    case atypes.TRIGGERRULES_CREATE_RESPONSE: {
      return { ...state, creating: false }
    }
  }

  return state
}

export default function triggerRulesReducer(state, action) {
  state = rulesDetailsReducer(state, action)
  const list = triggerRulesListReducer(state.list, action)
  if (list !== state.list) {
    state = { ...state, list }
  }
  return state
}

import * as atypes from 'apps/agency/constants/AgencyReduxActionTypes'

const initialState = {
  emailConfirmation: {
    status: null,
    error: null,
    confirmationSent: false,
  },
}

export default function emailReducer(state = initialState, action) {
  switch (action.type) {
    case atypes.AGENCY_CONFIRM_EMAIL_REQUEST: {
      return { ...state, emailConfirmation: { confirmationSent: true } }
    }
    case atypes.AGENCY_CONFIRM_EMAIL_RESPONSE: {
      return {
        ...state,
        emailConfirmation: { ...state.emailConfirmation, status: true, confirmationSent: false },
      }
    }
    case atypes.AGENCY_CONFIRM_EMAIL_ERROR: {
      return {
        ...state,
        emailConfirmation: {
          ...state.emailConfirmation,
          status: false,
          error: action.error.errors[0],
          confirmationSent: false,
        },
      }
    }
    default: {
      break
    }
  }
  return state
}

import React from 'react'
import cx from 'classnames'
import { l } from '@manychat/manyui'

import { KnowledgeBaseLink } from 'common/core/components/SupportLinks'

import bg from './assets/step_intro_bg.png'

import cm from './ConnectTwilio.module.css'

export const ConnectTwilio = () => {
  return (
    <>
      <div className="p-relative" style={{ width: '610px', height: '170px' }}>
        <img
          className="p-absolute"
          style={{ zIndex: -1, borderTopLeftRadius: '16px' }}
          src={bg}
          width={610}
          height={170}
        />
        <span className="d-block text-display-sm text-black m-l-lg p-t m-t-0">
          Meet Modern <br /> SMS Marketing
        </span>
      </div>

      <p className="p-y-md p-x-lg">
        {l.translate(
          '85% of customers prefer to receive a text message over a call or email. Reach your customers where they want to chat  and engage more customers with SMS channel.',
        )}
      </p>

      <ol className={cx(cm.list, 'p-x-lg p-b-lg')}>
        <li className={cm.list__item}>
          <span className={cm.list__counter}>1</span>

          <span>
            {l.translate(
              'Sign up with <a href="https://www.twilio.com" target="_blank" rel="noreferrer" class="text-primary">Twilio</a>',
            )}
          </span>
        </li>

        <li className={cm.list__item}>
          <span className={cm.list__counter}>2</span>

          <span>
            {l.translate('Obtain a phone number that will be used to send your automations.')}
          </span>
        </li>

        <li className={cm.list__item}>
          <span className={cm.list__counter}>3</span>

          <span>
            {l.translate(
              'Connect your Twilio account with Manychat. <a target="_blank" rel="noreferrer" class="text-primary" href="{link}">Learn more</a>',
              {
                link: KnowledgeBaseLink.SENDING_SMS,
              },
            )}
          </span>
        </li>
      </ol>
    </>
  )
}

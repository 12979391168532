import dot from 'dot-prop-immutable'

import { ActionTypes } from 'common/actions'
import { makeLimitedListReducer } from 'utils/factory'

import { bulkActionRequest } from '../actions/bulkActions'
import * as atypes from '../constants/AudienceReduxActionTypes'

const listReducer = makeLimitedListReducer('subscribers')

export default function subscribersReducer(state, action) {
  state = listReducer(state, action)

  switch (action.type) {
    case bulkActionRequest.fulfilled.type: {
      const bulkActionType = action.meta.arg.type
      const tagName = action.meta.arg.tagName

      if (bulkActionType === ActionTypes.ADD_TAG && tagName) {
        return dot.set(state, 'taggedWith', tagName)
      }

      return state
    }
    case atypes.SUBSCRIBERS_DROP_LIST: {
      return dot.set(state, 'taggedWith', null)
    }
  }

  return state
}

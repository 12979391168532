import { Reducer } from 'redux'

import {
  CollectOptInChannels,
  getInitialCollectOptInChannelState,
} from 'apps/collectOptIns/collectOptInsConstants'
import {
  CollectOptInState,
  CollectOptInChannelState,
  CollectOptInSources,
} from 'apps/collectOptIns/collectOptInsInterfaces'
import * as atypes from 'apps/collectOptIns/collectOptInsReduxActionTypes'
import { ChannelType } from 'common/core/constants/ChannelType'

export const getInitialState = (): CollectOptInState =>
  CollectOptInChannels.reduce((state, channel) => {
    state[channel] = getInitialCollectOptInChannelState()
    return state
  }, {} as CollectOptInState)

const dataToChannelState = (data: SafeUnknownObject): CollectOptInChannelState => ({
  flowId: ((data?.flow as SafeUnknownObject)?.ns as string) || null,
  name: ((data?.flow as SafeUnknownObject)?.name as string) || '',
  chId: (data?.content_holder as string) || null,
  sendType: (data?.type as string) || '',
  widgetId: (data?.widget_id as number) ?? null,
  sources: (data?.sources as CollectOptInSources) || null,
  enabled: true,
  fetching: false,
  isFetched: true,
  isChangingType: false,
})

export const collectOptInsReducer: Reducer<CollectOptInState> = (
  state = getInitialState(),
  action,
) => {
  const channel = action.channel as ChannelType
  const channelState = state[channel]

  switch (action.type) {
    case atypes.COLLECT_OPT_INS_GET_FLOW_REQUEST: {
      return {
        ...state,
        [channel]: {
          ...channelState,
          fetching: true,
          isFetched: false,
        },
      }
    }
    case atypes.COLLECT_OPT_INS_GET_FLOW_RESPONSE: {
      return {
        ...state,
        [channel]: {
          ...channelState,
          ...dataToChannelState(action.data),
        },
      }
    }
    case atypes.COLLECT_OPT_INS_GET_FLOW_ERROR: {
      return {
        ...state,
        [channel]: {
          ...channelState,
          fetching: false,
        },
      }
    }
  }

  return state
}

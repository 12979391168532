export const ContentType = {
  DEFAULT: 'default',
}

export const ContentBlockMaxCount = 101

export const DefaultContentQuickReplyBlockMaxCount = 1
export const DefaultContentOrdinaryBlockInARowMaxCount = 10
export const DefaultContentDelayBlockInARowMaxCount = 5
export const DefaultContentOrdinaryBlockSMMaxCount = 2
export const DefaultContentOrdinaryBlockCgtMaxCount = 1
export const DefaultContentOrdinaryBlockOtnMaxCount = 1
export const DefaultContentOrdinaryBlockMaxCount =
  ContentBlockMaxCount - DefaultContentQuickReplyBlockMaxCount

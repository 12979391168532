import React, { MouseEventHandler, useCallback } from 'react'
import cx from 'classnames'
import { z } from 'zod'

import { BlockType, PlanSlug } from '../../constants'
import { useViewContext } from '../../context'
import { useCart } from '../../hooks'
import { registry } from '../../registry'
import { BlockProps } from '../../types'
import { isAddOnSlug, isPlanSlug } from '../../utils/guards'

import { ProductCard } from './ProductCard'
import { ProductListContextSchema } from './ProductListContextSchema'
import { ProductSkeleton } from './ProductSkeleton'

import cm from './ProductList.module.css'

const ProductListPropsSchema = z.object({
  showMore: z.boolean().optional(),
  showBenefits: z.boolean().optional(),
})

const Component = ({
  showMore = true,
  showBenefits = true,
  blockId,
}: BlockProps<typeof ProductListPropsSchema>) => {
  const { loading, request } = useViewContext(ProductListContextSchema)
  const { offer, toggleProduct } = useCart()
  const withCheckbox = offer.length > 1 && !request.addOns?.length

  const goToProductTable = useCallback(() => {
    const productTableEl = document.getElementById('product_table')
    if (!productTableEl) {
      return
    }

    productTableEl.scrollIntoView({
      behavior: 'smooth',
    })
  }, [])

  const handleCardClick: MouseEventHandler<HTMLButtonElement> = useCallback(
    (event) => {
      const target = event.target as HTMLElement
      const slug = target.dataset?.productSlug
      const type = target.dataset?.productType
      if (!type) {
        return
      }

      if (type === 'plan') {
        if (isPlanSlug(slug) && slug !== PlanSlug.GRANDFATHERING) {
          toggleProduct({ type, slug })
        }
        return
      }

      if (type === 'addOn' && isAddOnSlug(slug)) {
        toggleProduct({ type, slug })
      }
    },
    [toggleProduct],
  )

  if (loading) {
    return (
      <ul className={cx('m-0 p-0', cm.container)}>
        <ProductSkeleton />
      </ul>
    )
  }

  return (
    <ul data-block={blockId} className={cx('m-0 p-0', cm.container)} data-test-id="product-list">
      {offer.map((cartProduct) => (
        <ProductCard
          key={cartProduct.slug}
          {...cartProduct}
          onClickMore={goToProductTable}
          onClick={handleCardClick}
          withCheckbox={withCheckbox}
          showMore={showMore}
          showBenefits={showBenefits}
        />
      ))}
    </ul>
  )
}

export const ProductList = registry.register(Component, {
  props: ProductListPropsSchema,
  context: ProductListContextSchema,
  type: BlockType.PRODUCT_LIST,
})

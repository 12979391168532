import capitalize from 'lodash/capitalize'
import each from 'lodash/each'
import includes from 'lodash/includes'
import { l } from '@manychat/manyui'

import { WEEKDAYS } from 'constants/constants'
import { MINUTE, HOUR, DAY, MONTH, YEAR } from 'utils/timeConstants'

export const clone = (o) => JSON.parse(JSON.stringify(o))

export const capitalizeFL = (str) => str.substr(0, 1).toUpperCase() + str.substr(1)

export const flat = (arr) => arr.reduce((a, b) => a.concat(b), [])

export const snakeToPascalWithSpaces = (str) =>
  capitalizeFL(str).replace(/([_][a-z])/gi, (letter) => letter.toUpperCase().replace('_', ' '))

/**
 * Number.prototype.format(n, x, s, c)
 *
 * @param integer n: length of decimal
 * @param integer x: length of whole part
 * @param mixed   s: sections delimiter
 * @param mixed   c: decimal delimiter
 */
export const numberFormat = (num2, n = 0, x = 3, s = ' ', c) => {
  if (!num2) {
    return 0
  }
  let re = '\\d(?=(\\d{' + (x || 3) + '})+' + (n > 0 ? '\\D' : '$') + ')',
    num = num2.toFixed(Math.max(0, ~~n))
  return (c ? num.replace('.', c) : num).replace(new RegExp(re, 'g'), '$&' + (s || ','))
}

export const windowHeight = () => {
  const doc = document,
    body = doc.body,
    html = doc.documentElement
  return Math.max(
    body.scrollHeight,
    body.offsetHeight,
    html.clientHeight,
    html.scrollHeight,
    html.offsetHeight,
  )
}

export const windowWidth = () => {
  try {
    const doc = document,
      body = doc.body,
      html = doc.documentElement
    return Math.max(
      body.scrollWidth,
      body.offsetWidth,
      html.clientWidth,
      html.scrollWidth,
      html.offsetWidth,
    )
  } catch (err) {
    return 0
  }
}

export const delay = async (ms) => {
  return new window.Promise((resolve) => {
    setTimeout(resolve, ms)
  })
}

export const retry = (cb = () => true) => {
  return new Promise((resolve, reject) => {
    const tm = (val = 0) =>
      setTimeout((val) => {
        val = val + 1

        if (val > 5) reject('max retries count exceeded')

        if (cb()) {
          resolve(cb())
        } else {
          tm(val)
        }
      }, 1000)
    tm()
  })
}

let UID = Number(new Date())
export const uniqID = () => ++UID

export function scrollTop(scrollDuration = 0) {
  try {
    const el = document.getElementById('appContent')
    const cosParameter = el.scrollTop / 2
    let scrollCount = 0
    let oldTimestamp = performance.now()

    const step = (newTimestamp) => {
      scrollCount += Math.PI / (scrollDuration / (newTimestamp - oldTimestamp))
      if (scrollCount >= Math.PI) {
        el.scrollTop = 0
      }
      if (el.scrollTop === 0) {
        return
      }
      el.scrollTop = Math.round(cosParameter + cosParameter * Math.cos(scrollCount))
      oldTimestamp = newTimestamp
      window.requestAnimationFrame(step)
    }
    window.requestAnimationFrame(step)
  } catch (e) {}
}

export function shortNumber(n) {
  if (n > 100000000) {
    return (n / 1000000).toFixed(0) + 'M'
  } else if (n > 10000000) {
    return (n / 1000000).toFixed(1) + 'M'
  } else if (n > 1000000) {
    return (n / 1000000).toFixed(2) + 'M'
  } else if (n > 100000) {
    return (n / 1000).toFixed(0) + 'K'
  }
  return String(n)
}

export function formatPrice(n) {
  const parsed = parseFloat(n)
  if (!isFinite(parsed)) {
    return ''
  }
  return parsed.toFixed(2)
}

export function createUniqueCaption(items, caption, options = {}) {
  const { prefix = ' ', initialNumber = '' } = options

  const tryGetCaption = (_caption, copyNumber) => {
    const _prefix = copyNumber ? prefix : ''
    const newCaption = `${_caption}${_prefix}${copyNumber}`
    if (items.includes(newCaption)) {
      return tryGetCaption(_caption, (copyNumber || 0) + 1)
    }
    return newCaption
  }

  return tryGetCaption(caption, initialNumber)
}

export function formatWeekdays(value, emptyWhenAny) {
  if (value.length === 7) {
    if (emptyWhenAny) {
      return ''
    }
    return l.translate('Any Day')
  }

  const selected = []

  each(WEEKDAYS, (day) => {
    if (includes(value, day)) {
      selected.push(capitalize(day).slice(0, 3))
    }
  })

  return selected.join(', ')
}

export function numberWithSpaces(x) {
  const parts = x.toString().split('.')
  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ' ')
  return parts.join('.')
}

export function parseURL(href) {
  const l = document.createElement('a')
  l.href = href

  return l
}

export function isSameURL(url1, url2) {
  if (url1 === url2) {
    return true
  }
  const url1WithSlash = url1.endsWith('/') ? url1 : `${url1}/`
  const url2WithSlash = url2.endsWith('/') ? url2 : `${url2}/`
  if (url1WithSlash === url2WithSlash) {
    return true
  }

  return false
}

export function getCurrentYear() {
  return new Date().getFullYear()
}

const MANYCHAT_BD = 1420059600000 // 2015-01-01 00:00:00.0

export const formatUnit = (unit) => `${unit > 1 ? 's' : ''}`

export const getTimeDelta = (unixTimeStamp, defaultValue = 'less minute') => {
  const diff = Date.now() - unixTimeStamp

  const years = Math.floor(diff / YEAR)

  // false ts, older than Manychat
  if (MANYCHAT_BD > unixTimeStamp) {
    return ''
  }

  if (years > 0) {
    return `${years} year${formatUnit(years)}`
  }

  const months = Math.floor(diff / MONTH)
  if (months > 0) {
    return `${months} month${formatUnit(months)}`
  }

  const days = Math.floor(diff / DAY)
  if (days > 0) {
    return `${days} day${formatUnit(days)}`
  }

  const hours = Math.floor(diff / HOUR)
  if (hours > 0) {
    return `${hours} hour${formatUnit(hours)}`
  }

  const minutes = Math.floor(diff / MINUTE)
  if (minutes > 0) {
    return `${minutes} minute${formatUnit(minutes)}`
  }

  return defaultValue
}

export function isFirefox() {
  return navigator.userAgent.toLowerCase().indexOf('firefox') > -1
}

const _isIE = Boolean(
  navigator &&
    (navigator.appName === 'Microsoft Internet Explorer' || navigator.userAgent.match(/Trident/)),
)

export function isIE() {
  return _isIE
}

export const isSafari = () => /^((?!chrome|android).)*safari/i.test(navigator.userAgent)

export const isChromium = Boolean(window.chrome)

const uuidRegex = /^[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$/i
export const isUUID = (id) => uuidRegex.test(id)

export function isInViewport(elem) {
  const bounding = elem.getBoundingClientRect()
  return (
    bounding.top >= 0 &&
    bounding.left >= 0 &&
    bounding.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
    bounding.right <= (window.innerWidth || document.documentElement.clientWidth)
  )
}

export function parseURLsAndReplaceToLink(text) {
  const urlRegex =
    /[-a-zA-Z0-9@:%_\+.~#?&//=]{2,256}\.[a-z]{2,4}\b(\/[-a-zA-Z0-9@:%_\+.~#?&//=]*)?/gi
  return text.replace(
    urlRegex,
    (url) => `<a target="_blank" class="text-primary" href="${url}">${url}</a>`,
  )
}

export function trackLastClickedElement() {
  if (!document || !document.addEventListener) {
    return
  }
  document.addEventListener(
    'click',
    (e) => {
      const event = e || window.event
      window.trackLastClickedElement = event.target || event.srcElement
    },
    false,
  )
}

export const nl2br = (str) => {
  if (!str) {
    return str
  }
  return str.replace(/(?:\r\n|\r|\n)/g, '<br/>')
}

export const formatToShortenedLink = (shortener) => `https://${shortener}/s/XXXXXX`

export const detectBrowser = () => {
  const userAgent = navigator.userAgent.toLowerCase()

  if (isFirefox()) {
    return 'Firefox'
  } else if (userAgent.indexOf('opera') > -1 || userAgent.indexOf('opr') > -1) {
    return 'Opera'
  } else if (userAgent.indexOf('edge') > -1 || userAgent.indexOf('edg') > -1) {
    return 'Microsoft Edge'
  } else if (userAgent.indexOf('chrome') > -1) {
    return 'Chrome'
  } else if (isSafari()) {
    return 'Safari'
  } else if (isIE()) {
    return 'Internet Explorer'
  } else {
    return 'Unknown'
  }
}

/**
 * @param {HTMLImageElement} img - Image object
 * @returns {Promise<void>}
 */
export const awaitImageLoad = (img) =>
  new Promise((resolve, reject) => {
    img.onload = resolve
    img.onerror = reject
  })

import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import * as uiActions from 'common/core/actions/uiActions'
import Lightbox from 'components/Lightbox'

class LightboxController extends Component {
  handleClose = () => {
    this.props.uiActions.closeLightbox()
  }

  render() {
    const { items, index } = this.props
    return <Lightbox images={items} index={index || 0} onClose={this.handleClose} />
  }
}

export default connect(
  (state) => ({
    ...state.ui.lightbox,
  }),
  (dispatch) => ({
    uiActions: bindActionCreators(uiActions, dispatch),
  }),
)(LightboxController)

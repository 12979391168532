import { getClientId } from 'utils/clientId'
import { isTestEnv } from 'utils/e2e'
import { INotificationsListener } from 'utils/services/notificationsService/notificationsServiceTypes'

import {
  createAnotherTabNotificationsListener,
  createNotificationsListener,
  createWSService,
} from './notificationsService'

export const wsService = createWSService()
export const accountNotificationsListener = createNotificationsListener()
export const userNotificationsListener = createNotificationsListener()
export const anotherTabNotificationsListener = createAnotherTabNotificationsListener(getClientId)

wsService.addListener(accountNotificationsListener)
wsService.addListener(userNotificationsListener)
wsService.addListener(anotherTabNotificationsListener)

if (isTestEnv()) {
  const setupNetworkInterception = (arg: unknown) => {
    const global = arg as { wsListener: unknown; wsEmit: unknown }
    global.wsEmit = wsService.handleNotifications
    wsService.addListener({ emit: global.wsListener || (() => {}) } as INotificationsListener)
  }
  setupNetworkInterception(window)
}

import { getUserId } from 'common/core/selectors/appSelectors'
import { InitialApplicationState } from 'utils/longTaskTracker/interfaces'
import { LongTaskListener, LongTaskState } from 'utils/longTaskTracker/LongTaskListener'

const PERFORMANCE_ENTRY_TO_CHECK = 'longtask'
const DEFAULT_LOCALHOST_STATE = {
  environment: 'localhost',
  release: 'localhost_release',
  version: 0,
}

export const setLoggingTasks = (store: RootState) => {
  const userId = getUserId(store)
  if (!userId) return
  if (!PerformanceObserver.supportedEntryTypes.includes(PERFORMANCE_ENTRY_TO_CHECK)) return

  const initialApplicationState: InitialApplicationState = {
    userId,
    userAgent: window.navigator.userAgent,
    environment: window.__SENTRY_ENV__?.environment ?? null,
    release: window.__SENTRY_ENV__?.release ?? null,
    version: window.STATIC_VERSION ?? null,
  }
  const localHostInitialApplicationState: InitialApplicationState = {
    ...initialApplicationState,
    ...DEFAULT_LOCALHOST_STATE,
  }

  const isProduction = process.env.NODE_ENV === 'production'
  const longTaskState = new LongTaskState(
    isProduction ? initialApplicationState : localHostInitialApplicationState,
  )
  const longTaskListener = new LongTaskListener(longTaskState)

  const observer = new PerformanceObserver((list) =>
    list.getEntries().forEach((entry) => {
      const entryEvent = {
        entry,
        pathname: window.location.pathname,
      }
      longTaskListener.listenTaskChanges(entryEvent)
    }),
  )

  observer.observe({ entryTypes: [PERFORMANCE_ENTRY_TO_CHECK] })

  document.addEventListener('visibilitychange', () => {
    if (document.visibilityState !== 'visible') {
      longTaskListener.clearListener()
    }
  })
}

// ads
export const ADS_PAGE_ENABLE = 'ADS_PAGE_ENABLE'
export const ADS_PAGE_ENABLE_RESPONSE = 'ADS_PAGE_ENABLE_RESPONSE'

// ads table
export const AD_TABLE_DATA_FETCH_REQUEST = 'AD_TABLE_DATA_FETCH_REQUEST'
export const AD_TABLE_DATA_FETCH_RESPONSE = 'AD_TABLE_DATA_FETCH_RESPONSE'
export const AD_TABLE_DATA_FETCH_ERROR = 'AD_TABLE_DATA_FETCH_ERROR'
export const AD_TABLE_CONFIG_UPDATE = 'AD_TABLE_CONFIG_UPDATE'
export const AD_TABLE_CAMPAIGN_TREE_FETCH_REQUEST = 'AD_TABLE_CAMPAIGN_TREE_FETCH_REQUEST'
export const AD_TABLE_CAMPAIGN_TREE_FETCH_RESPONSE = 'AD_TABLE_CAMPAIGN_TREE_FETCH_RESPONSE'

// ad
export const AD_UPDATE_STATUS = 'AD_UPDATE_STATUS'
export const AD_UPDATE_STATUS_RESPONSE = 'AD_UPDATE_STATUS_RESPONSE'
export const AD_DELETE = 'AD_DELETE'
export const AD_DELETE_RESPONSE = 'AD_DELETE_RESPONSE'
export const AD_CREATE = 'AD_CREATE'
export const AD_FETCH = 'AD_FETCH'
export const AD_FETCH_RESPONSE = 'AD_FETCH_RESPONSE'
export const AD_DUPLICATE = 'AD_DUPLICATE'
export const AD_CREATE_NOTIFICATION = 'AD_CREATE_NOTIFICATION'

// campaign
export const CAMPAIGN_DROP = 'CAMPAIGN_DROP'
export const CAMPAIGN_INIT = 'CAMPAIGN_INIT'
export const CAMPAIGN_INIT_SUCCESS = 'CAMPAIGN_INIT_SUCCESS'
export const CAMPAIGN_INIT_ERROR = 'CAMPAIGN_INIT_ERROR'
export const CAMPAIGN_CREATE = 'CAMPAIGN_CREATE'
export const CAMPAIGN_CREATE_REQUEST = 'CAMPAIGN_CREATE_REQUEST'
export const CAMPAIGN_CREATE_RESPONSE = 'CAMPAIGN_CREATE_RESPONSE'
export const CAMPAIGN_CREATE_ERROR = 'CAMPAIGN_CREATE_ERROR'
export const CAMPAIGN_SAVE = 'CAMPAIGN_SAVE'
export const CAMPAIGN_SAVE_REQUEST = 'CAMPAIGN_SAVE_REQUEST'
export const CAMPAIGN_SAVE_RESPONSE = 'CAMPAIGN_SAVE_RESPONSE'
export const CAMPAIGN_SAVE_ERROR = 'CAMPAIGN_SAVE_ERROR'
export const CAMPAIGN_UPDATE_STATUS = 'CAMPAIGN_UPDATE_STATUS'
export const CAMPAIGN_UPDATE_STATUS_REQUEST = 'CAMPAIGN_UPDATE_STATUS_REQUEST'
export const CAMPAIGN_UPDATE_STATUS_RESPONSE = 'CAMPAIGN_UPDATE_STATUS_RESPONSE'
export const CAMPAIGN_UPDATE_STATUS_ERROR = 'CAMPAIGN_UPDATE_STATUS_ERROR'
export const CAMPAIGN_DELETE = 'CAMPAIGN_DELETE'
export const CAMPAIGN_DELETE_RESPONSE = 'CAMPAIGN_DELETE_RESPONSE'

// adset
export const ADSET_DROP = 'ADSET_DROP'
export const ADSET_INIT = 'ADSET_INIT'
export const ADSET_INIT_ERROR = 'ADSET_INIT_ERROR'
export const ADSET_INIT_SUCCESS = 'ADSET_INIT_SUCCESS'
export const ADSET_PUBLISH = 'ADSET_PUBLISH'
export const ADSET_PUBLISH_REQUEST = 'ADSET_PUBLISH_REQUEST'
export const ADSET_PUBLISH_RESPONSE = 'ADSET_PUBLISH_RESPONSE'
export const ADSET_PUBLISH_ERROR = 'ADSET_PUBLISH_ERROR'
export const ADSET_ESTIMATE = 'ADSET_ESTIMATE'
export const ADSET_ESTIMATE_REQUEST = 'ADSET_ESTIMATE_REQUEST'
export const ADSET_ESTIMATE_RESPONSE = 'ADSET_ESTIMATE_RESPONSE'
export const ADSET_ESTIMATE_ERROR = 'ADSET_ESTIMATE_ERROR'
export const ADSET_DISCARD_DRAFT = 'ADSET_DISCARD_DRAFT'
export const ADSET_DISCARD_DRAFT_RESPONSE = 'ADSET_DISCARD_DRAFT_RESPONSE'
export const ADSET_DELETE = 'ADSET_DELETE'
export const ADSET_DELETE_RESPONSE = 'ADSET_DELETE_RESPONSE'
export const ADSET_CREATE = 'ADSET_CREATE'
export const ADSET_CREATE_REQUEST = 'ADSET_CREATE_REQUEST'
export const ADSET_CREATE_RESPONSE = 'ADSET_CREATE_RESPONSE'
export const ADSET_CREATE_ERROR = 'ADSET_CREATE_ERROR'
export const ADSET_FETCH_RESPONSE = 'ADSET_FETCH_RESPONSE'
export const ADSET_UPDATE_STATUS = 'ADSET_UPDATE_STATUS'
export const ADSET_UPDATE_STATUS_RESPONSE = 'ADSET_UPDATE_STATUS_RESPONSE'
export const ADSET_INIT_INTERACTION_TYPE = 'ADSET_INIT_INTERACTION_TYPE'
export const ADSET_UPDATE_INTERACTION_TYPE = 'ADSET_UPDATE_INTERACTION_TYPE'

export const ADSETS_CURRENT_UPDATE = 'ADSETS_CURRENT_UPDATE'
export const ADSET_SAVE = 'ADSET_SAVE'
export const ADSET_SAVE_RESPONSE = 'ADSET_SAVE_RESPONSE'

export const ADSET_TARGETING_SEARCH_LOCATIONS = 'ADSET_TARGETING_SEARCH_LOCATIONS'
export const ADSET_TARGETING_SEARCH_LOCATIONS_RESPONSE = 'ADSET_TARGETING_SEARCH_LOCATIONS_RESPONSE'
export const ADSET_TARGETING_FETCH_LOCATIONS_META = 'ADSET_TARGETING_FETCH_LOCATIONS_META'
export const ADSET_TARGETING_FETCH_LOCATIONS_META_RESPONSE =
  'ADSET_TARGETING_FETCH_LOCATIONS_META_RESPONSE'
export const ADSET_TARGETING_FETCH_LOCALES = 'ADSET_TARGETING_FETCH_LOCALES'
export const ADSET_TARGETING_FETCH_LOCALES_RESPONSE = 'ADSET_TARGETING_FETCH_LOCALES_RESPONSE'
export const ADSET_TARGETING_SEARCH_DETAILED_TARGETING = 'ADSET_TARGETING_SEARCH_DETAILED_TARGETING'
export const ADSET_TARGETING_SEARCH_DETAILED_TARGETING_RESPONSE =
  'ADSET_TARGETING_SEARCH_DETAILED_TARGETING_RESPONSE'

export const ADSET_PLATFORMS_FETCH = 'ADSET_PLATFORMS_FETCH'
export const ADSET_PLATFORMS_FETCH_RESPONSE = 'ADSET_PLATFORMS_FETCH_RESPONSE'

// ads gt
export const AD_CONSTRUCTOR_INIT = 'AD_CONSTRUCTOR_INIT'
export const AD_CONSTRUCTOR_INIT_SUCCESS = 'AD_CONSTRUCTOR_INIT_SUCCESS'
export const AD_CONSTRUCTOR_INIT_ERROR = 'AD_CONSTRUCTOR_INIT_ERROR'
export const AD_CONSTRUCTOR_DESTROY = 'AD_CONSTRUCTOR_DESTROY'
export const AD_CONSTRUCTOR_UPDATE_DATA = 'AD_CONSTRUCTOR_UPDATE_DATA'
export const AD_CONSTRUCTOR_DISCARD_CHANGES = 'AD_CONSTRUCTOR_DISCARD_CHANGES'
export const AD_CONSTRUCTOR_DISCARD_CHANGES_RESPONSE = 'AD_CONSTRUCTOR_DISCARD_CHANGES_RESPONSE'
export const AD_CONSTRUCTOR_UPDATE_TITLE = 'AD_CONSTRUCTOR_UPDATE_TITLE'
export const AD_CONSTRUCTOR_UPDATE_TITLE_RESPONSE = 'AD_CONSTRUCTOR_UPDATE_TITLE_RESPONSE'
export const AD_CONSTRUCTOR_PUBLISH = 'AD_CONSTRUCTOR_PUBLISH'
export const AD_CONSTRUCTOR_PUBLISH_REQUEST = 'AD_CONSTRUCTOR_PUBLISH_REQUEST'
export const AD_CONSTRUCTOR_PUBLISH_RESPONSE = 'AD_CONSTRUCTOR_PUBLISH_RESPONSE'
export const AD_CONSTRUCTOR_PUBLISH_ERROR = 'AD_CONSTRUCTOR_PUBLISH_ERROR'
export const AD_CONSTRUCTOR_UPDATE_PREVIEW_FORMAT = 'AD_CONSTRUCTOR_UPDATE_PREVIEW_FORMAT'
export const AD_CONSTRUCTOR_AUTO_SAVE_SUCCESS = 'AD_CONSTRUCTOR_AUTO_SAVE_SUCCESS'
export const AD_CONSTRUCTOR_AUTO_SAVE_ERROR = 'AD_CONSTRUCTOR_AUTO_SAVE_ERROR'
export const AD_CONSTRUCTOR_SEND_PREVIEW_REQUEST = 'AD_CONSTRUCTOR_SEND_PREVIEW_REQUEST'
export const AD_CONSTRUCTOR_SEND_PREVIEW_RESPONSE = 'AD_CONSTRUCTOR_SEND_PREVIEW_RESPONSE'
export const AD_CONSTRUCTOR_UPDATE_META = 'AD_CONSTRUCTOR_UPDATE_META'
export const AD_CONSTRUCTOR_FETCH = 'AD_CONSTRUCTOR_FETCH'
export const AD_CONSTRUCTOR_FETCH_RESPONSE = 'AD_CONSTRUCTOR_FETCH_RESPONSE'

// ad wizard
export const AD_FETCH_ALL_ACCOUNTS = 'AD_FETCH_ALL_ACCOUNTS'
export const AD_FETCH_ALL_ACCOUNTS_RESPONSE = 'AD_FETCH_ALL_ACCOUNTS_RESPONSE'
export const AD_FETCH_ALL_CAMPAIGNS = 'AD_FETCH_ALL_CAMPAIGNS'
export const AD_FETCH_ALL_CAMPAIGNS_RESPONSE = 'AD_FETCH_ALL_CAMPAIGNS_RESPONSE'
export const AD_FETCH_ALL_AD_SETS = 'AD_FETCH_ALL_AD_SETS'
export const AD_FETCH_ALL_AD_SETS_RESPONSE = 'AD_FETCH_ALL_AD_SETS_RESPONSE'

// ad accounts
export const AD_FETCH_ACCOUNTS = 'AD_FETCH_ACCOUNTS'
export const AD_FETCH_ACCOUNTS_REQUEST = 'AD_FETCH_ACCOUNTS_REQUEST'
export const AD_FETCH_ACCOUNTS_RESPONSE = 'AD_FETCH_ACCOUNTS_RESPONSE'
export const AD_FETCH_ACCOUNTS_ERROR = 'AD_FETCH_ACCOUNTS_ERROR'

export const AD_FETCH_NOT_ONLY_ACTIVE_ACCOUNTS = 'AD_FETCH_NOT_ONLY_ACTIVE_ACCOUNTS'
export const AD_FETCH_NOT_ONLY_ACTIVE_ACCOUNTS_REQUEST = 'AD_FETCH_NOT_ONLY_ACTIVE_ACCOUNTS_REQUEST'
export const AD_FETCH_NOT_ONLY_ACTIVE_ACCOUNTS_RESPONSE =
  'AD_FETCH_NOT_ONLY_ACTIVE_ACCOUNTS_RESPONSE'
export const AD_FETCH_NOT_ONLY_ACTIVE_ACCOUNTS_ERROR = 'AD_FETCH_NOT_ONLY_ACTIVE_ACCOUNTS_ERROR'

// account currency
export const ADS_ACCOUNT_CURRENCE_FETCH = 'ADS_ACCOUNT_CURRENCE_FETCH'
export const ADS_ACCOUNT_CURRENCE_FETCH_RESPONSE = 'ADS_ACCOUNT_CURRENCE_FETCH_RESPONSE'

// ads: custom audience
export const CUSTOM_AUDIENCE_CREATE_MODAL_RESET = 'CUSTOM_AUDIENCE_CREATE_MODAL_RESET'
export const CUSTOM_AUDIENCE_CREATE_MODAL_UPDATE = 'CUSTOM_AUDIENCE_CREATE_MODAL_UPDATE'
export const CUSTOM_AUDIENCE_CREATE = 'CUSTOM_AUDIENCE_CREATE'
export const CUSTOM_AUDIENCE_CREATE_SUCCESS = 'CUSTOM_AUDIENCE_CREATE_SUCCESS'
export const CUSTOM_AUDIENCE_CHECK = 'CUSTOM_AUDIENCE_CHECK'
export const CUSTOM_AUDIENCE_CHECK_SUCCESS = 'CUSTOM_AUDIENCE_CHECK_SUCCESS'
export const CUSTOM_AUDIENCE_CREATE_ERROR = 'CUSTOM_AUDIENCE_CREATE_ERROR'
export const CUSTOM_AUDIENCES_FETCH = 'CUSTOM_AUDIENCES_FETCH'
export const CUSTOM_AUDIENCES_FETCH_REQUEST = 'CUSTOM_AUDIENCES_FETCH_REQUEST'
export const CUSTOM_AUDIENCES_FETCH_RESPONSE = 'CUSTOM_AUDIENCES_FETCH_RESPONSE'
export const LLA_SOURCE_PAGES_FETCH = 'LLA_SOURCE_PAGES_FETCH'
export const LLA_SOURCE_PAGES_FETCH_REQUEST = 'LLA_SOURCE_PAGES_FETCH_REQUEST'
export const LLA_SOURCE_PAGES_FETCH_RESPONSE = 'LLA_SOURCE_PAGES_FETCH_RESPONSE'

export const CUSTOM_AUDIENCE_SEARCH_COUNTRIES = 'CUSTOM_AUDIENCE_SEARCH_COUNTRIES'
export const CUSTOM_AUDIENCE_SEARCH_COUNTRIES_RESPONSE = 'CUSTOM_AUDIENCE_SEARCH_COUNTRIES_RESPONSE'
export const CUSTOM_AUDIENCE_FETCH_USER_ACTION_NAMES = 'CUSTOM_AUDIENCE_FETCH_USER_ACTION_NAMES'
export const CUSTOM_AUDIENCE_FETCH_USER_ACTION_NAMES_RESPONSE =
  'CUSTOM_AUDIENCE_FETCH_USER_ACTION_NAMES_RESPONSE'

// ads: onboarding
export const ADS_FETCH_ONBOARDING = 'ADS_FETCH_ONBOARDING'
export const ADS_FETCH_ONBOARDING_RESPONSE = 'ADS_FETCH_ONBOARDING_RESPONSE'
export const ADS_CLOSE_ONBOARDING = 'ADS_CLOSE_ONBOARDING'
export const ADS_CLOSE_ONBOARDING_RESPONSE = 'ADS_CLOSE_ONBOARDING_RESPONSE'

import { z } from 'zod'

export enum QuestionElementType {
  RADIO = 'radio',
  CHECKBOX = 'checkbox',
  TEXT = 'text',
  TEXTAREA = 'textarea',
}

export enum QuestionStyleType {
  STANDARD = 'standard',
  SELECT = 'select',
  STACK = 'stack',
}

export enum QuestionItemType {
  STATIC = 'static',
  INPUT = 'input',
}

export enum QuestionnaireType {
  IG_ACCOUNT_CREATION_QUESTIONNAIRE = 'ig_account_creation_questionnaire',
  COMMON_ACCOUNT_CREATION_QUESTIONNAIRE = 'common_account_creation_questionnaire',
  WHATSAPP_ACCOUNT_CREATION_QUESTIONNAIRE = 'whatsapp_account_creation_questionnaire',
}

export enum CommonQuestionType {
  BUSINESS_TYPE = 'business_type',
  BUSINESS_TYPE_CREATOR = 'business_type_creator',
  BUSINESS_PLATFORM_ECOMMERCE = 'business_platform_e_commerce',
  PRIMARY_PURPOSE = 'primary_purpose',
  COMPANY_NEEDS__INTEND_IN_PRODUCT = 'company_needs__intend_in_product',
  CLIENT_NEEDS__INTEND_IN_PRODUCT = 'client_needs__intend_in_product',
  PERSONAL_USE__INTEND_IN_PRODUCT = 'personal_use__intend_in_product',
  COMPANY_NEEDS__BUSINESS_TYPE = 'company_needs__business_type',
  CLIENT_NEEDS__BUSINESS_TYPE = 'client_needs__business_type',
  PERSONAL_USE__INTEND_TYPE = 'personal_use__intend_type',
  PRIMARY_GOAL_FOR_MANYCHAT = 'primary_goal_for_manychat',
  SALES_PERCENTAGE_WA = 'sales_percentage_wa',
  CREATOR__MONETIZE = 'creator__monetize',
  CREATOR__TOOLS = 'creator__tools',
  E_COMMERCE__PLATFORM = 'e_commerce__platform',
  E_COMMERCE__SELLING = 'e_commerce__selling',
  LOCAL_BUSINESSES__RESERVATIONS = 'local_businesses__reservations',
  LOCAL_BUSINESSES__PROMOTIONS = 'local_businesses__promotions',
  REAL_ESTATE__DEALS = 'real_estate__deals',
  REAL_ESTATE__TOOLS = 'real_estate__tools',
  REAL_ESTATE__CONTACTING = 'real_estate__contacting',
  GENERAL__COMMUNICATION_CHANNELS = 'general__communication_channels',
  GENERAL__COMMUNICATION_TOOLS = 'general__communication_tools',
  PERSONAL_USE__BUSINESS_TYPE = 'personal_use__business_type',

  // Old Account Questionnaire
  BUSINESS_TYPE_E_COMMERCE = 'business_type_e_commerce',
  TYPE_ACCOUNT = 'type_account',
  BUSINESS_TYPE_CONSULTING = 'business_type_consulting',
  BUSINESS_TYPE_PUBLIC_FIGURE = 'business_type_public_figure',
  BUSINESS_TYPE_LOCAL = 'business_type_local',
  BUSINESS_TYPE_ORGANISATION_OR_INSTITUTION = 'business_type_organisation_or_institution',
  CHANNEL_USAGE = 'channel_usage',
  FOR_CLIENT = 'for_client',
}

const pageAnswerItemSchema = z.object({
  answer_id: z.string(),
  answer_type: z.nativeEnum(QuestionItemType),
  input: z.string().nullable(),
})

const pageAnswerSchema = z.object({
  question_id: z.nativeEnum(CommonQuestionType),
  question_type: z.nativeEnum(QuestionElementType),
  answers: z.array(pageAnswerItemSchema),
})

const pageQuestionItemSchema = z.object({
  text: z.string(),
  type: z.nativeEnum(QuestionItemType),
  show_questions: z.array(z.string()).nullable().optional(),
  next_question_id: z.nativeEnum(CommonQuestionType).nullable().optional(),
  placeholder: z.string().optional(),
  id: z.string(),
  question_id: z.string(),
  status: z.number(),
  is_extension: z.boolean().optional(),
  icon_name: z.string().nullable().optional(),
})

const pageQuestionSchema = z.object({
  type: z.nativeEnum(QuestionElementType),
  limit: z.number().nullable().optional(),
  style: z.nativeEnum(QuestionStyleType).optional(),
  header: z.string(),
  is_require: z.boolean(),
  is_visible: z.boolean(),
  description: z.string().nullable(),
  placeholder: z.string().nullable(),
  id: z.nativeEnum(CommonQuestionType),
  questionnaire_type_id: z.number(),
  position: z.number(),
  status: z.number(),
  items: z.array(pageQuestionItemSchema),
})

const savePageAnswersRequestSchema = z.object({
  answer: z.array(pageAnswerSchema),
  type: z.nativeEnum(QuestionnaireType),
})

const pageQuestionsResponseSchema = z.object({
  questions: z.array(pageQuestionSchema),
})

const UserQuestionItemSchema = z.object({
  text: z.string(),
  type: z.string(),
  sub_items: z.array(z.unknown()).optional(),
  show_questions: z.array(z.string()),
  id: z.string(),
  question_id: z.string(),
  status: z.number(),
})

const UserQuestionSchema = z.object({
  type: z.string(),
  style: z.nativeEnum(QuestionStyleType).optional(),
  header: z.string(),
  is_require: z.boolean(),
  is_visible: z.boolean(),
  description: z.string().nullable().optional(),
  placeholder: z.string(),
  id: z.string(),
  questionnaire_type_id: z.number(),
  position: z.number(),
  status: z.number(),
  items: z.array(UserQuestionItemSchema).optional(),
})

const UserAnswerItemSchema = z.object({
  answer_id: z.string().nullable(),
  answer_type: z.string(),
  input: z.string().nullable(),
})

const UserAnswerSchema = z.object({
  question_id: z.string(),
  question_type: z.string(),
  answers: z.array(UserAnswerItemSchema),
})

export const questionnaireApiSchemas = {
  getPageQuestions: {
    path: z.undefined(),
    query: z.object({
      type: z.string(),
    }),
    response: pageQuestionsResponseSchema,
  },
  savePageAnswers: {
    path: z.undefined(),
    query: z.undefined(),
    response: z.undefined(),
    request: savePageAnswersRequestSchema,
  },
  getUserQuestions: {
    path: z.undefined(),
    query: z.object({
      type: z.string(),
    }),
    response: z.object({
      questions: z.array(UserQuestionSchema),
    }),
  },
  saveUserAnswers: {
    path: z.undefined(),
    query: z.undefined(),
    response: z.undefined(),
    request: z.object({
      type: z.string(),
      answer: z.array(UserAnswerSchema),
    }),
  },
}
export type QuestionsResponse = z.infer<typeof pageQuestionsResponseSchema>
export type Question = z.infer<typeof pageQuestionSchema>
export type SubQuestion = z.infer<typeof pageQuestionItemSchema>

export type Answer = z.infer<typeof pageAnswerSchema>
export type AnswerItem = z.infer<typeof pageAnswerItemSchema>

export type UserAnswer = z.infer<typeof UserAnswerSchema>

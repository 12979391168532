import { createContext } from 'react'

import { FsFolderObject } from 'shared/api/requests/cms/schemas'

interface FoldersContextProps {
  folders: FsFolderObject[]
  activeFolder: string | null
  setActiveFolderId: (folderPath: string) => void
  disabledFolder: string | null
  disabledTooltip: string | null
}

export const FoldersContext = createContext<FoldersContextProps>({
  folders: [],
  activeFolder: null,
  setActiveFolderId: () => {},
  disabledFolder: null,
  disabledTooltip: null,
})

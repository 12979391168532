import assert from 'assert'

import { UNLINK_NODE_AND_BLOCK } from 'common/builder/constants/builderReduxActionTypes'

export const unlinkNodeBlock = (builderId, nodeId, targetId) => {
  assert(builderId, `unlinkBlock: builderId is required param`)
  assert(nodeId, `unlinkBlock: nodeId is required param`)
  assert(targetId, `unlinkBlock: targetId is required param`)
  return {
    type: UNLINK_NODE_AND_BLOCK,
    builderId,
    nodeId,
    targetId,
  }
}

import get from 'lodash/get'

const parseEntity = (entities = [], dependencies = [], dependencyId, addProperty) => {
  const getMatchingEntity = (dependency) => {
    return entities.find((entity) => {
      // eslint-disable-next-line eqeqeq
      return dependency[dependencyId] == entity.id.match(/\d+/g)[0]
    })
  }

  const intersection = dependencies.filter(getMatchingEntity)

  return intersection.map((dependency) => {
    const matchingEntity = getMatchingEntity(dependency)

    const result = { ...matchingEntity, id: dependency[dependencyId] }

    if (addProperty) {
      return { ...result, ...addProperty(dependency) }
    }

    return result
  })
}

export const parseEntities = (map = {}) => {
  const parsedTags = parseEntity(get(map, 'entities.tags', []), map.tags, 'tag_id')
  const parsedOtns = parseEntity(
    map?.entities?.otn_reasons ?? [],
    map.otn_reasons,
    'reason_id',
    (templateField) => ({ type: templateField.type, channel: templateField.channel }),
  )
  const parsedCUFs = parseEntity(
    get(map, 'entities.custom_fields', []),
    map.custom_fields,
    'field_id',
    (templateField) => ({ type: templateField.type }),
  )
  const parsedGFs = parseEntity(
    get(map, 'entities.global_fields', []),
    map.global_fields,
    'field_id',
    (templateField) => ({ type: templateField.type }),
  )
  const parsedCustomEvents = parseEntity(
    get(map, 'entities.custom_events', []),
    map.custom_events,
    'event_id',
    (templateField) => ({ meta_type: templateField.meta_type }),
  )

  return {
    custom_fields: parsedCUFs,
    tags: parsedTags,
    global_fields: parsedGFs,
    otns: parsedOtns,
    custom_events: parsedCustomEvents,
  }
}

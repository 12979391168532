import { ChatInfoMessage } from 'components/PhonePreviewKit/Chat/ChatInfoMessage/ChatInfoMessage'

import { ChatButton } from './ChatButton/ChatButton'
import { ChatMessage } from './ChatMessage/ChatMessage'
import { ChatMessageList } from './ChatMessageList/ChatMessageList'
import { ChatRoot } from './ChatRoot/ChatRoot'
import { ChatUserInfo } from './ChatUserInfo/ChatUserInfo'

export const Chat = {
  Root: ChatRoot,
  Button: ChatButton,
  Message: ChatMessage,
  MessageList: ChatMessageList,
  InfoMessage: ChatInfoMessage,
  UserInfo: ChatUserInfo,
}

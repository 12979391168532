import React from 'react'

import { MappedIceBreaker } from 'apps/iceBreakers/constants/constants'
import { Chat, PhonePreview, PhonePreviewTheme } from 'components/PhonePreviewKit'

export interface MessengerIcebreakersPreviewProps {
  name: string
  avatar: string
  description?: string
  iceBreakers: readonly MappedIceBreaker[]
}

export const MessengerIcebreakersPreview = ({
  name,
  description,
  avatar,
  iceBreakers,
}: MessengerIcebreakersPreviewProps) => {
  return (
    <PhonePreview.Root theme={PhonePreviewTheme.FACEBOOK}>
      <PhonePreview.Header title={name} avatarUrl={avatar} />
      <Chat.Root>
        <Chat.UserInfo avatarUrl={avatar} title={name} subtitle={description} />
        <Chat.MessageList place="bottom">
          {iceBreakers
            .filter((iceBreaker) => iceBreaker.caption)
            .map((iceBreaker) => (
              <Chat.Button key={iceBreaker.id} label={iceBreaker.caption} />
            ))}
        </Chat.MessageList>
      </Chat.Root>
      <PhonePreview.MessageComposer />
    </PhonePreview.Root>
  )
}

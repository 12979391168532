import SkeletonAvatar from './SkeletonAvatar'
import SkeletonImage from './SkeletonImage'
import SkeletonMultiplier from './SkeletonMultiplier'
import SkeletonText from './SkeletonText'

export default {
  Avatar: SkeletonAvatar,
  Image: SkeletonImage,
  Text: SkeletonText,
  Multiplier: SkeletonMultiplier,
}

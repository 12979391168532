import React from 'react'
import { l } from '@manychat/manyui'

const CrowdinInContext = () => {
  const language = l.getLanguage()
  React.useEffect(() => {
    if (language !== 'ach-UG') return

    const win = window as Window & typeof globalThis & { _jipt: Array<string[]> }
    win._jipt = []
    win._jipt.push(['project', 'de9abbc0947136cda27fc0ce134247bb'])
    win._jipt.push(['domain', 'manychat'])

    const crowdinInContextScript = document.createElement('script')
    crowdinInContextScript.type = 'text/javascript'
    crowdinInContextScript.src = '//cdn.crowdin.com/jipt/jipt.js'
    crowdinInContextScript.async = true
    document.body.appendChild(crowdinInContextScript)
  }, [language])
  return null
}

export default CrowdinInContext

import { l } from '@manychat/manyui'

type RecommendationType = 'comments' | 'dm' | 'sr'

interface RecommendationContentStructure {
  title: string
  description: string
  buttonTitle: string
  appliedTitle: string
}

interface RecommendationContent {
  comments: RecommendationContentStructure
  dm: RecommendationContentStructure
  sr: RecommendationContentStructure
}

export const usePinnedRecommendationContent = (
  occurrencesUnique: number | undefined,
  recommendationType: RecommendationType | undefined,
  isPro: boolean,
) => {
  const proFeatureText = isPro ? '' : l.translate(' with this PRO feature')

  const base: RecommendationContent = {
    comments: {
      title: l.translate('Capture more leads by switching to All Posts or Reels'),
      description: l.translate(
        'You received {occurrences} comments with the same keywords in different posts or reels. Switch to ‘All Posts or Reels’ and automatically reply to them{proFeatureText} to boost engagement and maximise your lead collection.',
        {
          occurrences: occurrencesUnique,
          proFeatureText,
        },
      ),
      buttonTitle: l.translate('Switch to All Post or Reels'),
      appliedTitle: l.translate('Trigger set to All Posts or Reels'),
    },
    dm: {
      title: l.translate('Reply to more conversations by adding Direct Message trigger'),
      description: l.translate(
        'You received {occurrences} direct messages with the keywords of this automation. Add a Direct Message trigger to ensure you reply to those messages{proFeatureText} as well.',
        { occurrences: occurrencesUnique, proFeatureText },
      ),
      buttonTitle: l.translate('Add Direct Message Trigger'),
      appliedTitle: l.translate('Direct Message trigger has been added'),
    },
    sr: {
      title: l.translate('Reply to more conversations by adding Story Reply trigger'),
      description: l.translate(
        'You received {occurrences} replies to your stories with the keywords of this automation. Add a Story Reply trigger to ensure you reply to those messages{proFeatureText} as well.',
        { occurrences: occurrencesUnique, proFeatureText },
      ),
      buttonTitle: l.translate('Add Story Reply Trigger'),
      appliedTitle: l.translate('Story Reply trigger has been added'),
    },
  }

  if (recommendationType && base[recommendationType]) {
    const result = base[recommendationType]
    return {
      title: result.title,
      description: result.description,
      buttonTitle: result.buttonTitle,
      appliedTitle: result.appliedTitle,
    }
  }

  return {
    title: '',
    description: '',
    buttonTitle: '',
    appliedTitle: '',
  }
}

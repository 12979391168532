import { IActionWithPayload } from 'common/core/interfaces/actions'
import * as actionTypes from 'common/settings/constants/AutoAssignmentActionTypes'
import { SettingsAutoAssignmentState } from 'common/settings/interfaces'
import { AutoAssignmentRule } from 'shared/api/requests/autoAssignment/schemas'

const initialState: SettingsAutoAssignmentState = {
  rules: null,
  loading: false,
  error: false,
}

function getUpdatedRulesList(
  rules: SettingsAutoAssignmentState['rules'],
  rule: AutoAssignmentRule,
) {
  if (!rules) {
    return [rule]
  }

  const ruleID = rule.rule_id
  const ruleIndex = rules.findIndex((rule) => rule.rule_id === ruleID)
  const updatedRules = [...rules]

  if (ruleIndex !== -1) {
    updatedRules.splice(ruleIndex, 1, rule)
  } else {
    updatedRules.push(rule)
  }

  return updatedRules
}

export function settingsAutoAssignmentReducer(
  state: SettingsAutoAssignmentState = initialState,
  action: IActionWithPayload,
) {
  const stateRules = state.rules || []

  switch (action.type) {
    case actionTypes.AUTO_ASSIGNMENT_RULES_GET_LIST:
      return {
        ...state,
        loading: true,
        error: false,
      }

    case actionTypes.AUTO_ASSIGNMENT_RULES_GET_LIST_SUCCESS:
      return {
        rules: action.rules,
        loading: false,
        error: false,
      }

    case actionTypes.AUTO_ASSIGNMENT_RULES_GET_LIST_ERROR:
      return {
        rules: null,
        loading: false,
        error: true,
      }

    case actionTypes.AUTO_ASSIGNMENT_RULE_GET_SUCCESS:
      return {
        rules: getUpdatedRulesList(state.rules, action.rule),
        loading: false,
        error: false,
      }

    case actionTypes.AUTO_ASSIGNMENT_RULE_CREATE_SUCCESS:
      return {
        rules: [...stateRules, action.rule],
        loading: false,
        error: false,
      }

    case actionTypes.AUTO_ASSIGNMENT_RULE_UPDATE_SUCCESS:
      return {
        rules: getUpdatedRulesList(state.rules, action.rule),
        loading: false,
        error: false,
      }

    case actionTypes.AUTO_ASSIGNMENT_RULE_SET_STATUS_SUCCESS: {
      const rule = stateRules.find((rule) => rule.rule_id === action.payload.rule_id)
      const updatedRule = { ...rule, status: action.payload.status } as AutoAssignmentRule

      return {
        rules: getUpdatedRulesList(state.rules, updatedRule),
        loading: false,
        error: false,
      }
    }

    case actionTypes.AUTO_ASSIGNMENT_RULE_SET_TITLE_SUCCESS: {
      const rule = stateRules.find((rule) => rule.rule_id === action.payload.rule_id)
      const updatedRule = { ...rule, title: action.payload.title } as AutoAssignmentRule

      return {
        rules: getUpdatedRulesList(state.rules, updatedRule),
        loading: false,
        error: false,
      }
    }

    case actionTypes.AUTO_ASSIGNMENT_RULE_DELETE_SUCCESS: {
      const updatedRules = [...stateRules].filter((rule) => rule.rule_id !== action.payload.rule_id)

      return {
        rules: updatedRules,
        loading: false,
        error: false,
      }
    }

    case actionTypes.AUTO_ASSIGNMENT_RULE_DUPLICATE_SUCCESS: {
      const updatedRules = [...stateRules, action.rule]

      return {
        rules: updatedRules,
        loading: false,
        error: false,
      }
    }

    case actionTypes.AUTO_ASSIGNMENT_RULES_SORT_SUCCESS: {
      const ruleIdsOrder = action.payload.rule_ids
      const updatedRules = ruleIdsOrder.map((ruleID: number) =>
        stateRules.find((rule) => rule.rule_id === ruleID),
      )

      return {
        rules: updatedRules,
        loading: false,
        error: false,
      }
    }

    default:
      return state
  }
}

import * as atypes from 'apps/ads/constants/AdsReduxActionTypes'
import * as CampaignAdapter from 'apps/ads/models/Campaign/adapter'
import { makeCurrentItemReducer, setCurrentItem } from 'utils/factory'

const currentCampaignItemReducer = makeCurrentItemReducer('adCampaigns')

const initialState = {
  isInitializing: false,
  isCreating: false,
  isSaving: false,
  item: null,
}

export default function adTableReducer(state = initialState, action) {
  state = currentCampaignItemReducer(state, action)

  switch (action.type) {
    case atypes.CAMPAIGN_DROP: {
      return initialState
    }
    case atypes.CAMPAIGN_INIT: {
      return { ...state, isInitializing: true }
    }
    case atypes.CAMPAIGN_INIT_SUCCESS:
    case atypes.CAMPAIGN_INIT_ERROR: {
      return { ...state, isInitializing: false }
    }
    case atypes.CAMPAIGN_CREATE_REQUEST: {
      return { ...state, isCreating: true }
    }
    case atypes.CAMPAIGN_CREATE_RESPONSE: {
      return { ...state, isCreating: false }
    }
    case atypes.CAMPAIGN_CREATE_ERROR: {
      return { ...state, isCreating: false }
    }
    case atypes.CAMPAIGN_SAVE_REQUEST: {
      return { ...state, isSaving: true }
    }

    case atypes.CAMPAIGN_SAVE_ERROR: {
      return { ...state, isSaving: false }
    }
    case atypes.CAMPAIGN_SAVE_RESPONSE: {
      return {
        ...state,
        ...setCurrentItem(state.currentCampaign, CampaignAdapter.parseItem(action.data.campaign)),
        isSaving: false,
      }
    }
  }

  return state
}

import React, { useCallback } from 'react'
import { useAppDispatch } from 'reduxTyped'
import { BtnV2, l } from '@manychat/manyui'

import { FAQ_FOLDER_ID_STORAGE_FIELD } from 'apps/aiIntentsWizard/constants'
import { AiIntentsWizardAnalytics } from 'apps/aiIntentsWizard/lib/analytics'
import { AiIntentsWizardActions } from 'apps/aiIntentsWizard/store'
import { withErrorBoundary } from 'common/core/components/ErrorBoundary'
import { getAccountIdFromURL } from 'utils/accountId'
import localStorage from 'utils/localStorage'

import cm from './AiFaqFolderBanner.module.css'

const Component = function AiFaqFolderBanner({ currentFolder }: { currentFolder: string }) {
  const dispatch = useAppDispatch()
  const accountId = getAccountIdFromURL()
  const folderPath = localStorage.getItem(`${FAQ_FOLDER_ID_STORAGE_FIELD}_${accountId}`)

  const handleClick = useCallback(() => {
    AiIntentsWizardAnalytics.logOpenInFolder()
    dispatch(AiIntentsWizardActions.setShowModal(true))
  }, [dispatch])

  if (!folderPath || currentFolder !== folderPath) {
    return null
  }

  return (
    <article className={cm.wrapper}>
      <h1 className="text-subheading">
        {l.translate('AI recognizes and replies to FAQs for you')}
      </h1>
      <p>
        {l.translate(
          'Check the FAQs you’ve setup below and just click if you need to edit anything.',
        )}
      </p>
      <BtnV2
        variant="ghost-primary"
        className={cm.button}
        label={l.translate('Create More FAQs in Bulk')}
        onClick={handleClick}
      />
    </article>
  )
}

export const AiFaqFolderBanner = withErrorBoundary(Component, {
  scope: 'ai',
  section: 'ai_intents_wizard',
})

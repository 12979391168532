import React, { useCallback } from 'react'
import { useNavigate } from 'react-router-dom'
import { useAppDispatch, useAppSelector } from 'reduxTyped'

import { getBuilderUrl } from 'apps/cms/lib/getBuilderUrl'
import { FlowCardClickAction } from 'apps/cms/pages/ContentManagementSystem/lib/types'
import { contentManagementActions, contentManagementSelectors } from 'apps/cms/store'
import { EasyBuilderAnalytics } from 'apps/easyBuilder/analytics/easyBuilderAnalytics'
import { useSidebarContext } from 'apps/easyBuilder/context/EasyBuilderSidebarContext'
import { warnDisabled, warnAccessDenied } from 'apps/templates/models/Template/helpers'
import { usePermission, PermissionTargets } from 'common/userRoles'
import { linkBuilder } from 'utils/router'

export const useFlowCard = ({ flowId }: { flowId: string }) => {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const { campaignType } = useSidebarContext()
  const userCanEditFlow = usePermission(PermissionTargets.FLOW_EDITING)

  const flow = useAppSelector(contentManagementSelectors.getFlows).find(
    (flow) => flow.ns === flowId,
  )
  const { flowCardClickAction, disableFlowCard } = useAppSelector(contentManagementSelectors.getUi)

  const hasPublishedContent = Boolean(flow?.has_published_content)
  const isCardDisabled = Boolean(disableFlowCard && !hasPublishedContent)
  const isEasyBuilderFlow = Boolean(flow?.easy_builder)

  const accessData = isEasyBuilderFlow ? undefined : flow?.template_installation_access_data
  const cardUrl = isEasyBuilderFlow
    ? linkBuilder.easyBuilder({ flow_id: flowId })
    : getBuilderUrl(flow?.path || flowId)

  const putFlowIdToState = useCallback(() => {
    dispatch(contentManagementActions.setLastSelectedFlowId(flowId))
  }, [dispatch, flowId])

  const openFlow = useCallback(
    (event: React.MouseEvent<HTMLButtonElement | HTMLDivElement | HTMLAnchorElement>) => {
      if (!flow) return

      if (accessData && accessData.is_protected && !accessData.is_owner) {
        if (accessData.is_disabled) {
          return warnDisabled()
        }

        if (accessData.is_locked) {
          return warnAccessDenied()
        }
      }

      if (isEasyBuilderFlow) {
        EasyBuilderAnalytics.EDIT_MODE_OPENED({ campaignType, flowId })
      }

      if (event.ctrlKey || event.metaKey) {
        window.open(cardUrl)
      } else {
        navigate(cardUrl)
      }
    },
    [flow, accessData, isEasyBuilderFlow, campaignType, flowId, cardUrl, navigate],
  )

  const handleFlowCardClick = useCallback(
    (event: React.MouseEvent<HTMLButtonElement | HTMLDivElement | HTMLAnchorElement>) => {
      event.preventDefault()

      switch (flowCardClickAction) {
        case FlowCardClickAction.SAVE_FLOW_ID_TO_STATE:
          putFlowIdToState()
          break
        case FlowCardClickAction.OPEN_FLOW:
          openFlow(event)
          break
      }
    },
    [flowCardClickAction, putFlowIdToState, openFlow],
  )

  return {
    userCanEditFlow,
    flow,
    cardUrl,
    handleFlowCardClick,
    accessData,
    isCardDisabled,
    isEasyBuilderFlow,
  }
}

export interface IFindCallback {
  (index: number, match: string): void
}

export const findWhileTextChanges = (
  getText: () => string,
  pattern: string,
  callback: IFindCallback,
) => {
  const regExp = new RegExp(pattern)
  let prevText: string
  const find = () => {
    const text = getText()
    if (text === prevText) {
      return
    }
    prevText = text

    const match = regExp.exec(text)
    if (match !== null) {
      callback(match.index, match[0])
      find()
    }
  }
  find()
}

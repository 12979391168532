import dayjs from 'dayjs'

import { RecurringNotificationWizardSteps } from 'apps/dashboard/components/RnLearning/components/FirstRecurringNotificationOnboarding/Components/FirstRecurringNotificationModal/constants'
import { CreateFirstRecurringFlowResponse } from 'apps/dashboard/dashboardInterfaces'
import { ChannelType } from 'common/core/constants/ChannelType'
import { IAsyncThunkAction, IThunkAction } from 'common/core/interfaces/actions'
import { getDefaultReasonId } from 'common/oneTimeNotify/selectors/notifyReasonSelectors'
import {
  setGeneratedFlowNs,
  setWidgetId,
  setIsLoading,
  setWizardStep,
  setIsWizardLoading,
  getRefUrl,
  setProgressBarStep,
  setChannel,
} from 'common/recurringNotifications/slice'
import * as API from 'constants/API'
import { dashboardApi } from 'shared/api/requests/dashboard'
import { handleCatchError } from 'utils/handleCatchError'
import { navigatePollyfill, isPathnameInclude } from 'utils/router/tools'
import { linkURL } from 'utils/url'

import * as atypes from '../constants/dashboardReduxActionTypes'

export function loadFullStats(dateRange: string[]): IAsyncThunkAction {
  return async (dispatch) => {
    const date_start = new Date(dateRange[0])
    const date_end = new Date(dateRange[1])

    const query = {
      date_end: dayjs(date_end).format('DD.MM.YYYY'),
      date_start: dayjs(date_start).format('DD.MM.YYYY'),
      timestamp: dayjs().format('YYYY-MM-DD HH:mm:ss'),
      current_time_offset: new Date().getTimezoneOffset(),
    }

    try {
      const { data } = await dashboardApi.fullStats({ query })
      dispatch({ type: atypes.DASHBOARD_FULLSTATS_RESPONSE, data })
    } catch (error) {
      handleCatchError(error)
    }
  }
}

export function loadGenderStats(): IAsyncThunkAction {
  return async (dispatch) => {
    try {
      const { data } = await dashboardApi.subscribersGenderStats()
      dispatch({ type: atypes.DASHBOARD_SUBSCRIBERSGENDERSTATS_RESPONSE, data })
    } catch (error) {
      handleCatchError(error)
    }
  }
}

export function loadLanguageStats(): IAsyncThunkAction {
  return async (dispatch) => {
    try {
      const { data } = await dashboardApi.subscribersLanguageStats()
      dispatch({ type: atypes.DASHBOARD_SUBSCRIBERSLANGUAGESTATS_RESPONSE, data })
    } catch (error) {
      handleCatchError(error)
    }
  }
}

export function loadRecentSubscribers(): IAsyncThunkAction {
  return async (dispatch) => {
    try {
      const { data } = await dashboardApi.subscribersRecentList()
      dispatch({ type: atypes.DASHBOARD_SUBSCRIBERSRECENTLIST_RESPONSE, data })
    } catch (error) {
      handleCatchError(error)
    }
  }
}

export const fetchEntitiesCount = (): IThunkAction => {
  return async (dispatch) => {
    try {
      const { data } = await dashboardApi.getEntitiesCount()
      dispatch({ type: atypes.DASHBOARD_FETCH_ENTITIES_COUNT_RESPONSE, data })
    } catch (error) {
      handleCatchError(error)
      dispatch({ type: atypes.DASHBOARD_FETCH_ENTITIES_COUNT_ERROR })
    }
  }
}

export const fetchDangerFlowsData = (): IAsyncThunkAction => {
  return async (dispatch) => {
    try {
      const { data } = await dashboardApi.messageTagSending()

      dispatch({ type: atypes.DASHBOARD_FETCH_DANGER_FLOWS_DATA, data })

      if (data.content_sent_without_tag !== 0) {
        dispatch({ type: atypes.DASHBOARD_SHOW_DANGER_FLOWS_DATA })
      }
    } catch (error) {
      dispatch({ type: atypes.DASHBOARD_FETCH_DANGER_FLOWS_DATA_ERROR })
      console.error(error)
    }
  }
}

export const fetchDangerFlowsList = (): IThunkAction => {
  return async (dispatch) => {
    const { data } = await dashboardApi.messageTagSendingFlows()

    dispatch({ type: atypes.DASHBOARD_FETCH_DANGER_FLOWS_LIST, data })

    return data
  }
}

export const fetchEarnedData = (): IThunkAction => {
  return async (dispatch) => {
    try {
      const response = await dashboardApi.economicStats()
      dispatch({ type: atypes.DASHBOARD_FETCH_EARNED_LIST_RESPONSE, data: response.data })
    } catch (error) {
      handleCatchError(error)
    }
  }
}

export const closeWizardAndRedirectToCms = (flowNs: string): IThunkAction => {
  return (dispatch) => {
    dispatch(setWizardStep(RecurringNotificationWizardSteps.CLOSED))
    navigatePollyfill(linkURL(`/cms/files/${flowNs}`))
  }
}

export const createFirstRecurringFlowWithMessage = (
  text: string,
  channel: ChannelType,
): IAsyncThunkAction => {
  return async (dispatch, getState, { api }) => {
    const state = getState()
    const isFacebook = channel === ChannelType.FB
    const isInstagram = channel === ChannelType.INSTAGRAM
    const endpoint = API.flow.endpoints.createFirstRecurringFlowWithMessage
    const body = { reason_id: getDefaultReasonId(state), text, channel }
    const isCmsPage = isPathnameInclude('/cms')
    const isBroadcastingPage = isPathnameInclude('/posting')
    const isDashboardPage = isPathnameInclude('/dashboard')

    try {
      const response = await api.post<CreateFirstRecurringFlowResponse>(endpoint, { body })
      const { state: responseState, flow_ns, widget_id } = response

      if (!responseState) return

      if (isInstagram && isBroadcastingPage) {
        dispatch(closeWizardAndRedirectToCms(flow_ns))
        return
      }

      if (isInstagram && isDashboardPage) {
        dispatch(setWizardStep(RecurringNotificationWizardSteps.SUCCESS_STEP))
        return
      }

      if (isFacebook) {
        dispatch(setGeneratedFlowNs(flow_ns))
        dispatch(setWidgetId(widget_id))
        dispatch(setProgressBarStep(RecurringNotificationWizardSteps.COLLECTING_OPTIN))
      }

      if (isCmsPage) {
        dispatch(closeWizardAndRedirectToCms(flow_ns))
      } else {
        dispatch(setWizardStep(RecurringNotificationWizardSteps.COLLECTING_OPTIN))
      }

      dispatch(setChannel(null))
    } catch (error) {
      handleCatchError(error)
      dispatch(setIsLoading(false))
    }
  }
}

export const checkRnOptinAndGoToNextWizardStep = (
  nextWizardStep: RecurringNotificationWizardSteps,
): IAsyncThunkAction => {
  return async (dispatch, getState, { api }) => {
    const refUrl = getRefUrl(getState())
    dispatch(setIsWizardLoading(true))
    try {
      const { state } = await api.post(API.followUp.endpoints.checkRnOptin)
      if (state && refUrl) {
        window.open(refUrl, '_blank')
        dispatch(setWizardStep(nextWizardStep))
      }
    } catch (error) {
      handleCatchError(error)
    } finally {
      dispatch(setIsWizardLoading(false))
    }
  }
}

import { l } from '@manychat/manyui'

export const getRuns = (value: number | unknown) => {
  if (typeof value !== 'number') {
    return l.translate('<l10n context="not available"/>n/a')
  }

  if (value === 0) {
    return l.translate('<l10n context="not available"/>n/a')
  }

  return l.number(value)
}

export const getCtr = (value: number | unknown) => {
  if (typeof value !== 'number') {
    return l.translate('<l10n context="not available"/>n/a')
  }

  if (value === 0) {
    return l.translate('<l10n context="not available"/>n/a')
  }

  return l.percent(value)
}

export const getWidgetStatistics = (value: number | unknown) => {
  if (typeof value !== 'number') {
    return l.translate('<l10n context="not available"/>n/a')
  }

  if (value === 0) {
    return l.translate('<l10n context="not available"/>n/a')
  }

  return l.number(value)
}

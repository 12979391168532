export enum SizeType {
  SMALL = 'small',
  MEDIUM = 'medium',
  LARGE = 'large',
}

export enum FontSizeType {
  SMALL = 'small',
  LARGE = 'large',
}

export enum ColorType {
  DEFAULT = 'default',
  WHITE = 'white',
  DANGER = 'danger',
  SUCCESS = 'success',
  INFO = 'info',
}

export const COMMENT_NODE_TEXT_MAX_LENGTH = 640

import { makeProductCatalogAPI } from 'apps/chat/api/productCatalogApi'
import { SystemKeywordsAPI } from 'apps/systemKeywords/api/SystemKeywordsAPI'
import { TelegramAPI } from 'apps/telegram/TelegramAPI'
import makeWhatsAppEmSUAPI from 'apps/whatsApp/api/whatsAppEmSUAPI'
import { makeMergeSubscribersAPI } from 'common/audience/api/audienceMergeApi'
import { makeNotificationsAPI } from 'common/core/components/NotifyBar/api/notificationsAPI'
import FieldsAPI from 'common/fields/api/fieldsAPI'
import { makeRemoveAccountConfirmationAPI } from 'common/settings/RemoveAccountConfirmation/removeAccountConfirmationAPI'
import { getAccountID } from 'utils/accountId'
import { McAPI } from 'utils/api/types'
import { accountNotificationsListener } from 'utils/services/notificationsService'

export const makeAPI = (mcApi: McAPI) => ({
  ...mcApi,
  fields: new FieldsAPI(mcApi, accountNotificationsListener),
  whatsAppEmSU: makeWhatsAppEmSUAPI(mcApi, getAccountID),
  telegram: new TelegramAPI(mcApi),
  systemKeywords: new SystemKeywordsAPI(mcApi),
  notifications: makeNotificationsAPI(mcApi),
  removeAccountConfirmation: makeRemoveAccountConfirmationAPI(mcApi),
  mergerSubscribers: makeMergeSubscribersAPI(mcApi),
  productCatalogAPI: makeProductCatalogAPI(mcApi),
})

export type RootAPI = ReturnType<typeof makeAPI>

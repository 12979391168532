import { v4 as uuid } from 'uuid'
import { l } from '@manychat/manyui'

import { ListMessageSection, ListMessageSectionOption } from 'apps/whatsApp/ListMessages/types'
import { ButtonType } from 'common/builder/constants/ButtonType'

export function createListMessageSection(): ListMessageSection {
  return {
    _oid: uuid(),
    title: l.translate('Section title'),
    options: [],
  }
}

export function createListMessageSectionOption(
  caption: string,
  id: string = uuid(),
): ListMessageSectionOption {
  return {
    _oid: id,
    _content_oid: null,
    type: ButtonType.WA_LIST_MESSAGE_BUTTON,
    caption,
    description: '',
  }
}

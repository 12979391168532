import React, { useCallback } from 'react'
import cx from 'classnames'
import { BtnV2, l } from '@manychat/manyui'

import { billing, BillingExperiment, UpgradeSource } from 'common/billing'
import { PRICING_LINK } from 'common/billing/constants'
import { useDidMountEffect } from 'utils/commonHooks'
import { analyticsService } from 'utils/services/analytics'

import cm from '../StaticNotifications.module.css'

export const ActivateProTrialNotification = () => {
  useDidMountEffect(() => {
    analyticsService.sendEvent('NOTIFICATION_BAR.PRO_TRIAL_EXPERIMENT.SHOWN')
  })

  const handleRequestUpgradeClick = useCallback(() => {
    analyticsService.sendEvent('NOTIFICATION_BAR.PRO_TRIAL_EXPERIMENT.ACTIVATE_PRO_TRIAL.CLICKED')
    billing.requestUpgrade({
      source: UpgradeSource.NOTIFICATION_BAR_PRO_TRIAL_EXPERIMENT,
      experiment: BillingExperiment.UPGRADE_PAGE,
    })
  }, [])

  const handleOpenPricingClick = () => {
    analyticsService.sendEvent('NOTIFICATION_BAR.PRO_TRIAL_EXPERIMENT.PRICING.CLICKED')
    window.open(PRICING_LINK, '_blank')
  }

  return (
    <>
      <span>
        {l.translate('Activate 14-Day Free Trial to unlock all Pro features and get more value')}
      </span>
      <BtnV2 variant="success" onClick={handleRequestUpgradeClick} className="m-l-sm">
        {l.translate('Activate Pro Trial')}
      </BtnV2>
      <a onClick={handleOpenPricingClick} className={cx(cm.viewPricing, 'm-l-sm')}>
        {l.translate('View pricing')}
      </a>
    </>
  )
}

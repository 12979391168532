import React from 'react'
import cx from 'classnames'

import { isMobile } from 'utils'

import Tip from './TooltipComponent'

import styles from './Tooltip.module.css'

const ATTR = 'data-title'
const NS_DEFAULT = 'main'
const instances = {}

const getInstance = (ns) => {
  return instances[ns]
}

class Tooltip extends React.Component {
  static _instance = null

  static defaultProps = {
    className: cx('animated', styles.tip),
    ns: NS_DEFAULT,
  }

  static update = (ns = NS_DEFAULT) => {
    instances[ns] && instances[ns].forceUpdate()
  }

  constructor(props, context) {
    super(props, context)
    this.state = {
      target: null,
    }
  }

  dropInstance = () => {
    const { ns } = this.props
    const instance = instances[ns]
    if (instance) {
      const container = document
      container.removeEventListener('mouseover', instance.onHover, { capture: true })
      instances[ns] = null
      delete instances[ns]
    }
  }

  setInstance = () => {
    this.dropInstance()
    const { ns } = this.props
    const container = document
    container.addEventListener('mouseover', this.onHover, { capture: true })
    instances[ns] = this
  }

  shouldComponentUpdate({ className }, { target }) {
    const { props, state } = this
    return target !== state.target || className !== props.className
  }

  componentDidMount() {
    this.setInstance()
  }

  componentWillUnmount() {
    this.dropInstance()
  }

  findTip = (el) => {
    while (el) {
      if (el === document || el === this.props.container) {
        break
      }

      if (el.hasAttribute(ATTR)) {
        return el
      }

      if (el === this.tooltipRef?.current?.containerRef?.current) {
        return this.state.target
      }
      el = el.parentNode
    }
    return null
  }

  getHintData = (target) => {
    const content = this.props.content || target.getAttribute(ATTR)
    const cls = target.getAttribute(`${ATTR}-cls`)
    const at = target.getAttribute(`${ATTR}-at`) || 'top'
    const offsetBottom = target.getAttribute(`${ATTR}-offset-bottom`) || 0
    const fz = Number(target.getAttribute(`${ATTR}-fz`) || 13)
    const type = target.getAttribute(`${ATTR}-type`)
    const width = target.getAttribute(`${ATTR}-width`)
    const delay = Number(target.getAttribute(`${ATTR}-delay`) || 50)

    let wrapperTop, wrapperLeft, wrapperWidth, wrapperHeight
    const {
      top: targetTop,
      left: targetLeft,
      width: targetWidth,
      height: targetHeight,
    } = target.getBoundingClientRect()
    switch (at) {
      case 'right':
      case 'left': // eslint-disable-line no-fallthrough
        wrapperTop = targetTop + targetHeight / 2
        wrapperLeft = targetLeft
        wrapperWidth = `calc(100vw + ${targetWidth}px)`
        break
      case 'right_bottom':
      case 'left_bottom': // eslint-disable-line no-fallthrough
        wrapperTop = targetTop + targetHeight - offsetBottom
        wrapperLeft = targetLeft
        wrapperWidth = `calc(100vw + ${targetWidth}px)`
        break

      case 'bottom':
        wrapperHeight = targetHeight
      case 'top': // eslint-disable-line no-fallthrough
      default:
        wrapperTop = targetTop
        wrapperLeft = targetLeft + targetWidth / 2
        break
    }

    return {
      content,
      cls,
      at,
      delay,
      fz,
      type,
      width,
      wrapperTop,
      wrapperLeft,
      wrapperWidth,
      wrapperHeight,
    }
  }

  onHover = ({ target }) => {
    const instance = getInstance(this.props.ns)
    if (!instance) {
      return
    }

    const { delay } = this.getHintData

    clearTimeout(instance.timeout)

    instance.timeout = setTimeout(() => {
      const hint = this.findTip(target)
      this.setState({ target: hint })
    }, delay)
  }

  tooltipRef = React.createRef()

  render = () => {
    const { className, wrapperClassName } = this.props
    const { target } = this.state

    if (!target) {
      return null
    }

    const {
      content,
      cls,
      at,
      fz,
      type,
      width,
      wrapperTop,
      wrapperLeft,
      wrapperWidth,
      wrapperHeight,
    } = this.getHintData(target)

    return (
      <div className={styles.screenLimits}>
        <div
          className={cx(styles.wrapper, wrapperClassName, styles[at])}
          style={{ left: wrapperLeft, top: wrapperTop, width: wrapperWidth, height: wrapperHeight }}
        >
          <Tip
            ref={this.tooltipRef}
            target={target}
            content={content}
            width={width}
            style={{ fontSize: fz }}
            className={cx(className || styles.tip, styles[at], styles[type], cls, styles.show)}
          />
        </div>
      </div>
    )
  }
}

const TooltipWrapper = () => (isMobile ? null : <Tooltip />)
export default TooltipWrapper

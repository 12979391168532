import { matchPath } from 'react-router-dom'
import {
  onCLS,
  onFID,
  onLCP,
  onFCP,
  onTTFB,
  onINP,
  Metric,
  INPReportCallbackWithAttribution,
  INPMetricWithAttribution,
} from 'web-vitals/attribution'

import { isMobile } from 'utils'
import { CmsTab } from 'apps/cms/pages/ContentManagementSystem/lib/types'
import { DashboardTabType } from 'apps/dashboard/constants/DashboardTabType'
import { SettingsTabType } from 'common/settings/constants/SettingsTabType'
import { isLocal } from 'utils/isLocal'
import {
  PageLifecycleTracker,
  getPathAlias,
  getConnection,
  InternetSpeed,
} from 'utils/pageLifeCycle'
import { RoutePaths } from 'utils/router/constants'
import { getLocation } from 'utils/router/tools'
import { analyticsService } from 'utils/services/analytics'

interface MetricsProps extends Pick<Metric, 'id' | 'name' | 'value'> {
  [key: string]: string | number

  path: string
  scope: 'mobile' | 'desktop'
  internet_speed: 'slow-2g' | '2g' | '3g' | '4g' | 'unknown'
}

const INP_MIN_DURATION_TRASHOLD = 16 // min by spec

const EMPTY_HASH = ''
const Paths = [
  {
    id: 'dashboard',
    url: RoutePaths.DASHBOARD,
    hash: [...Object.values(DashboardTabType), EMPTY_HASH],
  },
  {
    id: 'contacts',
    url: RoutePaths.AUDIENCE_SCOPE,
    hash: [EMPTY_HASH],
  },
  {
    id: 'cms',
    url: RoutePaths.CMS,
    hash: [...Object.values(CmsTab), EMPTY_HASH],
  },
  {
    id: 'live-chat',
    url: RoutePaths.CHAT,
    hash: [EMPTY_HASH],
  },
  {
    id: 'broadcasts',
    url: RoutePaths.POSTING,
    hash: [EMPTY_HASH],
  },
  {
    id: 'paid-messages',
    url: RoutePaths.SPONSORED_MESSAGES,
    hash: [EMPTY_HASH],
  },
  {
    id: 'ads',
    url: RoutePaths.AD_TABLE_SCOPE,
    hash: [EMPTY_HASH],
  },
  {
    id: 'settings',
    url: RoutePaths.SETTINGS,
    hash: [...Object.values(SettingsTabType), EMPTY_HASH],
  },
]

export const getResourceType = (url: string) => {
  if (/https:\/\/mccdn\.me\/.*js/.test(url)) {
    return 'javascript'
  }

  if (/https:\/\/mccdn\.me\/.*css/.test(url)) {
    return 'css'
  }

  if (
    /https:\/\/mccdn\.me\/.*(avif|png|svg|jpg|webp)/.test(url) ||
    /https:\/\/manychat\.com\/.*(avif|png|svg|jpg|webp)/.test(url) ||
    /https:\/\/manybot-thumbnails\.s3\.eu-central-1\.amazonaws\.com\/manychat\/.*(avif|png|svg|jpg|webp)/.test(
      url,
    )
  ) {
    return 'image'
  }

  if (
    /https:\/\/mccdn\.me\/.*(woff|woff2|ttf)/.test(url) ||
    /https:\/\/manychat\.com\/.*(woff|woff2|ttf)/.test(url) ||
    /https:\/\/fonts\.gstatic\.com\/.*(woff|woff2|ttf)/.test(url)
  ) {
    return 'font'
  }

  if (
    /https:\/\/dev\.visualwebsiteoptimizer\.com\//.test(url) ||
    /https:\/\/.+\.wootric\.com\//.test(url) ||
    /https:\/\/www\.googletagmanager\.com\//.test(url) ||
    /https:\/\/.+\.hotjar\.com\//.test(url)
  ) {
    return 'third-party'
  }

  if (/https:\/\/manychat\.com\/.+/.test(url)) return 'api'

  return 'other'
}

export const getPath = () => {
  const { pathname, hash } = getLocation()
  const path = Paths.find((path) => {
    const isCorrectPath = matchPath(path.url, pathname)
    const isCorrectHash = path.hash.includes(hash.replace(/^#/, ''))

    return isCorrectPath && isCorrectHash
  })

  return path ? path.id + hash : 'others'
}

function isCacheHit(resource: PerformanceResourceTiming) {
  if (resource.transferSize > 0) return false

  if (resource.decodedBodySize > 0) return true

  return resource.duration < 30
}

type ResourceMapProps = Record<
  string,
  {
    time: string
    network_requests: number
    network_size: number
    cache_size: number
    cache_requests: number
    path: string
    internet_speed: InternetSpeed
  }
>

export interface ExtendedNetworkInformation {
  effectiveType?: InternetSpeed
}

const logMetrics = (metricData: Metric, path: string, connection: InternetSpeed) => {
  const data: MetricsProps = {
    id: metricData.id,
    name: metricData.name,
    value: metricData.value,
    path,
    scope: isMobile ? 'mobile' : 'desktop',
    internet_speed: connection,
  }

  analyticsService.sendEvent('METRICS', data)
}

const connection = getConnection()

export default function (state: RootState, pageLifecycleTracker?: PageLifecycleTracker) {
  const path = getPath()

  if (path === 'others') return
  ;[onLCP, onCLS, onFID, onFCP, onTTFB].forEach((_) =>
    _((metricData) => logMetrics(metricData, path, connection)),
  )

  const WAIT_TIME_LOADING_RESOURCES = 7000
  setTimeout(() => {
    const perfData = window.myPerfData
    if (!perfData) return

    Object.entries(perfData).forEach(([time, resources]) => {
      const emptyResult = {
        time,
        network_requests: 0,
        network_size: 0,
        cache_size: 0,
        cache_requests: 0,
        path,
        internet_speed: connection,
      }

      const resourceMap = resources.reduce((acc, res) => {
        const type = getResourceType(res.name)
        acc[type] = acc[type] ?? { ...emptyResult }
        const field = isCacheHit(res) ? 'cache' : 'network'

        acc[type][`${field}_size`] += Number(res?.encodedBodySize) || 0
        acc[type][`${field}_requests`]++

        return acc
      }, {} as ResourceMapProps)

      analyticsService.sendEvent('METRICS.RESOURCES', resourceMap)
    })
  }, WAIT_TIME_LOADING_RESOURCES)

  const inpDefaultOptions = { reportAllChanges: true, durationThreshold: INP_MIN_DURATION_TRASHOLD }

  pageLifecycleTracker &&
    onINP((metric) => pageLifecycleTracker.getLogger()(metric), inpDefaultOptions)

  onINP(report as INPReportCallbackWithAttribution, inpDefaultOptions)
}

const report = (metric: INPMetricWithAttribution) => {
  logMetrics(metric, getPathAlias(getLocation().pathname), connection)

  if (isLocal()) {
    console.groupCollapsed(
      `%cINP: ${metric.attribution.eventType} => ${metric.value} ms`,
      'color: white; background-color: #eb5038; padding: 2px 4px; border-radius: 4px;',
    )
    console.log(`Target element: ${metric.attribution.eventTarget}`)
    console.groupEnd()
  }
}

import get from 'lodash/get'
import isPlainObject from 'lodash/isPlainObject'
import uniq from 'lodash/uniq'
import uniqBy from 'lodash/uniqBy'
import without from 'lodash/without'

import * as atypes from 'apps/ads/constants/AdsReduxActionTypes'
import {
  parseCampaign,
  parseAdSetDraft,
  parseAdSet,
  parseAdgt,
  parseAd,
} from 'apps/ads/models/AdData/adapter'
import { ConfigDefaults, ConfigLocalStorageKey } from 'apps/ads/models/AdTable/constants'
import localStorage from 'utils/localStorage'

const initialState = {
  growthTools: [],
  ads: [],
  adSetDrafts: [],
  adSets: [],
  accounts: [],
  campaigns: [],

  currencies: [],

  isFetching: false,
  lastUpdated: null,
  fetchError: false,

  stats: {},

  tableConfig: Object.assign(
    {},
    ConfigDefaults,
    JSON.parse(localStorage.getItem(ConfigLocalStorageKey) || '{}'),
  ),
}

export default function tableReducer(state = initialState, action) {
  switch (action.type) {
    case atypes.AD_TABLE_DATA_FETCH_REQUEST: {
      return { ...state, isFetching: true, fetchError: false }
    }
    case atypes.AD_TABLE_DATA_FETCH_ERROR: {
      return { ...state, isFetching: false, fetchError: true }
    }
    case atypes.AD_TABLE_DATA_FETCH_RESPONSE: {
      const { growthTools, ads, adSetDrafts, adSets, campaigns, accounts } = action
      return {
        ...state,
        isFetching: false,
        fetchError: false,
        lastUpdated: new Date(),
        stats: action.stats || state.stats,
        currencies: action.currencies || state.currencies,
        growthTools: growthTools.map((item) => item.id),
        ads: ads.map((item) => item.id),
        adSetDrafts: adSetDrafts.map((item) => item.id),
        adSets: adSets.map((item) => item.id),
        accounts: accounts.map((item) => item.id),
        campaigns: campaigns.map((item) => item.id),
      }
    }
    case atypes.AD_TABLE_CAMPAIGN_TREE_FETCH_RESPONSE: {
      const { growthTools, ads, adSetDrafts, adSets, campaigns, accounts } = action
      return {
        ...state,
        growthTools: uniq([...state.growthTools, ...growthTools.map((item) => item.id)]),
        ads: uniq([...state.ads, ...ads.map((item) => item.id)]),
        adSetDrafts: uniq([...state.adSetDrafts, ...adSetDrafts.map((item) => item.id)]),
        adSets: uniq([...state.adSets, ...adSets.map((item) => item.id)]),
        accounts: uniq([...state.accounts, ...accounts.map((item) => item.id)]),
        campaigns: uniq([...state.campaigns, ...campaigns.map((item) => item.id)]),
      }
    }
    case atypes.AD_TABLE_CONFIG_UPDATE: {
      return { ...state, tableConfig: { ...state.tableConfig, ...action.changes } }
    }

    // CAMPAIGNS
    case atypes.CAMPAIGN_CREATE_RESPONSE: {
      state.campaigns = uniq([...state.campaigns, parseCampaign(action.data.campaign).id])
      return { ...state, isCreating: false }
    }
    case atypes.CAMPAIGN_DELETE_RESPONSE: {
      // todo handle nested remove
      state.campaigns = without(state.campaigns, action.campaignId)
      return state
    }

    // ADSET
    case atypes.ADSET_DELETE_RESPONSE: {
      state.adSetDrafts = without(state.adSetDrafts, action.draftId)
      state.adSets = without(state.adSets, action.adSetId)
      return state
    }
    case atypes.ADSET_CREATE_RESPONSE:
    case atypes.ADSET_PUBLISH_RESPONSE: {
      if (action.data.data) {
        state.adSetDrafts = uniq([...state.adSetDrafts, parseAdSetDraft(action.data.data).id])
      }
      if (action.data.ad_set) {
        state.adSets = uniq([...state.adSets, parseAdSet(action.data.ad_set).id])
      }
      return state
    }

    // ADGT
    case atypes.AD_CREATE_NOTIFICATION: {
      state.growthTools = uniq([...state.growthTools, parseAdgt(action.data.data).id])
      return state
    }
    case atypes.AD_DELETE_RESPONSE: {
      state.ads = without(state.ads, action.adId)
      state.growthTools = without(state.growthTools, action.adgtId)
      return state
    }
    case atypes.AD_CONSTRUCTOR_PUBLISH_RESPONSE: {
      state.ads = uniq([...state.ads, parseAd(action.ad).id])
      return state
    }
    case atypes.AD_CONSTRUCTOR_FETCH_RESPONSE:
    case atypes.AD_FETCH_RESPONSE: {
      const adInsights = get(action, 'data.data.stats.ad_insights')
      if (isPlainObject(adInsights)) {
        let existingInsights = get(state, 'stats.ad_insights') || []
        existingInsights = [adInsights, ...existingInsights]
        state.stats.ad_insights = uniqBy(existingInsights, 'ad_id')
      }

      const gtStats = get(action, 'data.data.stats.growth_tool')
      if (isPlainObject(gtStats)) {
        gtStats.ads_growth_tool_id = get(action, 'data.data.ads_growth_tool_id')
        gtStats.ad_id = get(action, 'data.data.ad_id') || ''
        let existingGTStats = get(state, 'stats.growth_tools') || []
        existingGTStats = [gtStats, ...existingGTStats]
        state.stats.growth_tools = uniqBy(existingGTStats, 'ads_growth_tool_id')
      }

      return state
    }
  }

  return state
}

import React, { useCallback, useState, useRef, useEffect } from 'react'
import { BtnV2, l } from '@manychat/manyui'

import cm from 'common/banners/mobile/MobileBanner.module.css'
import { analyticsService } from 'utils/services/analytics'

import {
  SurveyTextsByQuestion,
  MobileAppReason,
  RequestedFeature,
  SURVEY_CLOSE_DELAY_MS,
  Question,
} from './constants'
import { SuccessScreen } from './SuccessScreen'
import { UserInput } from './UserInput'

interface SurveyProps {
  closeBanner: () => void
}

export const Survey = ({ closeBanner }: SurveyProps) => {
  const [activeQuestion, setActiveQuestion] = useState(Question.REASON)
  const [isUserInputEnabled, setIsUserInputEnabled] = useState(false)
  const [isSuccessScreenVisible, setIsSuccessScreenVisible] = useState(false)
  const timeoutId = useRef<ReturnType<typeof setTimeout> | null>(null)

  useEffect(
    () => () => {
      if (timeoutId.current) clearTimeout(timeoutId.current)
    },
    [],
  )

  const goBack = useCallback(() => {
    setIsUserInputEnabled(false)
  }, [])

  const showSuccessScreen = useCallback(() => {
    setIsSuccessScreenVisible(true)
    timeoutId.current = setTimeout(closeBanner, SURVEY_CLOSE_DELAY_MS)
  }, [closeBanner])

  const handleUserInputSend = useCallback(
    (userInput: string) => {
      analyticsService.sendEvent('MOBILE_BANNER.SURVEY.ANSWER', {
        question: activeQuestion,
        answer: 'another',
        userInput,
      })
      showSuccessScreen()
    },
    [activeQuestion, showSuccessScreen],
  )

  const handleBtnClick = useCallback(
    (answer: {
      value: MobileAppReason | RequestedFeature
      label: ReturnType<typeof l.makeTranslate>
      nextQuestion?: Question
      isUserInputAvailable?: boolean
    }) => {
      if (answer.nextQuestion) {
        setActiveQuestion(answer.nextQuestion)
      } else if (answer.isUserInputAvailable) {
        setIsUserInputEnabled(true)
      } else {
        analyticsService.sendEvent('MOBILE_BANNER.SURVEY.ANSWER', {
          question: activeQuestion,
          answer: answer.value,
        })
        showSuccessScreen()
      }
    },
    [activeQuestion, showSuccessScreen],
  )

  if (isSuccessScreenVisible) return <SuccessScreen />
  if (isUserInputEnabled) {
    return <UserInput goBack={goBack} onSend={handleUserInputSend} />
  }

  const { title, description, answers } = SurveyTextsByQuestion[activeQuestion]

  return (
    <div className={cm.surveyContainer}>
      <h1>{l.getString(title)}</h1>
      {description && <p className="m-t-xs">{l.getString(description)}</p>}

      <div className="m-t-xl">
        {answers.map((answer, index) => (
          <BtnV2 fullWidth key={index} className="m-b-sm" onClick={() => handleBtnClick(answer)}>
            {l.getString(answer.label)}
          </BtnV2>
        ))}
      </div>
    </div>
  )
}

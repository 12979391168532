import BaseBlockConfig from 'common/builder/blocks/BaseBlockConfig'
import { IBlockBase, ITelegramKeyboardBlock } from 'common/builder/blocks/blockInterfaces'
import { BlockType } from 'common/builder/constants/BlockType'

class TelegramKeyboardBlockConfig extends BaseBlockConfig {
  blockType = BlockType.TELEGRAM_KEYBOARD

  getDefaults = (): Omit<ITelegramKeyboardBlock, keyof IBlockBase> => ({
    buttonsStructure: [],
  })
}

export default new TelegramKeyboardBlockConfig()

import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { createSelector } from 'reselect'

import {
  ActionCategoryType,
  ApplicationCategoryType,
} from 'common/actions/components/ActionPickerModal/lib/types'
import { IntegrationModalsInitialState } from 'common/actions/integrations/common/lib/integrationModalsInitialState'
import {
  IntegrationModalPayload,
  IntegrationModalType,
} from 'common/actions/integrations/common/lib/interfaces'

const IntegrationModalsSlice = createSlice({
  name: 'integrationModals',
  initialState: IntegrationModalsInitialState,
  reducers: {
    resetState: () => IntegrationModalsInitialState,

    openModal: (state, action: PayloadAction<IntegrationModalPayload>) => {
      state[action.payload.type] = {
        isOpen: true,
        // @ts-expect-error RTK's issue with union types
        data: action.payload.data,
      }
    },

    closeModal: (state, action: PayloadAction<IntegrationModalType>) => {
      state[action.payload] = {
        isOpen: false,
        data: null,
      }
    },

    setActionPickerSelectedCategory: (
      state,
      action: PayloadAction<ActionCategoryType | ApplicationCategoryType | null>,
    ) => {
      state[IntegrationModalType.ACTION_PICKER].data = {
        selectedCategory: action.payload,
      }
    },
  },
})

export const integrationModalsReducer = IntegrationModalsSlice.reducer

export const IntegrationModalsActions = {
  ...IntegrationModalsSlice.actions,
}

const getState = (state: RootState) => state.integrations.modals

export const IntegrationModalsSelectors = {
  getModalData: createSelector(
    [getState, (_, type: IntegrationModalType) => type],
    (state, type) => {
      return state[type].data
    },
  ),

  getIsModalOpen: createSelector(
    [getState, (_, type: IntegrationModalType) => type],
    (state, type) => {
      return state[type].isOpen
    },
  ),

  getIsIntegrationModalOpen: createSelector(
    [getState],
    (state) => state[IntegrationModalType.INTEGRATION].isOpen,
  ),

  getIsApplicationModalOpen: createSelector(
    [getState],
    (state) => state[IntegrationModalType.APPLICATION].isOpen,
  ),

  getIsActionPickerModalOpen: createSelector(
    [getState],
    (state) => state[IntegrationModalType.ACTION_PICKER].isOpen,
  ),
}

import React, { useCallback, useEffect } from 'react'
import cx from 'classnames'
import { useAppSelector, useAppDispatch } from 'reduxTyped'
import { l, LinkBtn, Icon, CheckboxV2 } from '@manychat/manyui'

import { DraftIndicator } from 'apps/cms/components/DraftIndicator/DraftIndicator'
import { FlowContextMenu } from 'apps/cms/components/FlowContextMenu/FlowContextMenu'
import { useModalActions } from 'apps/cms/components/Flows/components/AutomationModals/hooks/useModalActions'
import { RecommendationTypes } from 'apps/cms/components/Flows/components/AutomationModals/modals/ApplyRecommendationModal/constants'
import { Modified } from 'apps/cms/components/Flows/components/FlowCard/components/Modified/Modified'
import { Triggers } from 'apps/cms/components/Flows/components/FlowCard/components/Triggers/Triggers'
import {
  getCtrValue,
  getRunsValue,
} from 'apps/cms/components/Flows/components/FlowCard/lib/statistics'
import { useFlowCardDrag } from 'apps/cms/components/Flows/hooks/useFlowCardDrag'
import { usePinnedFlowCard } from 'apps/cms/components/Flows/hooks/usePinnedFlowCard'
import { usePinnedRecommendationContent } from 'apps/cms/components/Flows/hooks/usePinnedRecommendationContent'
import { SimpleTrialPlaceType } from 'apps/cms/lib/constants'
import { getBuilderUrl } from 'apps/cms/lib/getBuilderUrl'
import { FlowCardClickAction } from 'apps/cms/pages/ContentManagementSystem/lib/types'
import { contentManagementSelectors, contentManagementActions } from 'apps/cms/store'
import { AutomationModalType } from 'apps/cms/store/lib/types'
import ProtectionStatus from 'apps/templates/components/ProtectionStatus'
import { getCurrentAccount } from 'common/core/selectors/appSelectors'
import { IFullPinnedFlow } from 'common/flow/flowInterfaces'
import { handleGoProExperiment } from 'common/goProExperimentHandlers'
import LabelComponent from 'components/Label/Label'
import { analyticsService } from 'utils/services/analytics'

import cm from './FlowPinnedCard.module.css'

interface FlowCardProps {
  flowId: string
  allowSelect: boolean
  flow: IFullPinnedFlow
}

export const FlowPinnedCard = React.memo(function FlowCard({
  flowId,
  allowSelect,
  flow,
}: FlowCardProps) {
  const occurrencesUnique = flow.stats?.keywords_other_posts_unq?.occurrences_unq
  const recommendationType = flow.stats?.keywords_other_posts_unq?.recommendation_type
  const recommendationId = flow.stats?.keywords_other_posts_unq?.recommendation_id

  const dispatch = useAppDispatch()
  const isFlowSelected = useAppSelector((state) =>
    contentManagementSelectors.getIsFlowSelected(state, flowId),
  )
  const isAnyFilterApplied = useAppSelector(contentManagementSelectors.getAnyFilterApplied)
  const { isPro } = useAppSelector(getCurrentAccount)
  const isRecommendationApplied = useAppSelector(
    contentManagementSelectors.getIsRecommendationApplied,
  )
  const {
    showFlowContextMenu,
    disabledFlowCardMessage,
    allowHoverFlowCard,
    allowHighlightActiveFlowCard,
    lastSelectedFlowId,
    flowCardClickAction,
  } = useAppSelector(contentManagementSelectors.getUi)
  const isFlowCardActive = lastSelectedFlowId === flowId && allowHighlightActiveFlowCard

  const { connectDragSource } = useFlowCardDrag({ flow })
  const { handleFlowCardClick, userCanEditFlow, isCardDisabled } = usePinnedFlowCard({
    flowId,
    flow,
  })
  const { title, description, buttonTitle, appliedTitle } = usePinnedRecommendationContent(
    occurrencesUnique,
    recommendationType,
    isPro,
  )
  const { openModal } = useModalActions()

  useEffect(() => {
    analyticsService.sendEvent('CMS.FLOWS.RECOMMENDATION_POPUP.SHOWN', {
      occurrences_unq: occurrencesUnique,
      recommendation_type: recommendationType,
      flowId: flowId,
    })
  }, [occurrencesUnique, recommendationType, flowId])

  const toggleFlowSelection = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      let shiftIsPressed = false

      if ('shiftKey' in event.nativeEvent) {
        shiftIsPressed = event.nativeEvent.shiftKey as boolean
      }

      if (shiftIsPressed) {
        dispatch(contentManagementActions.selectFlowsInRange(flowId))
        return
      }
      dispatch(contentManagementActions.toggleFlowSelection(flowId))
    },
    [dispatch, flowId],
  )

  const isFlowAvailable =
    isPro ||
    recommendationType === RecommendationTypes.DIRECT_MESSAGE ||
    recommendationType === RecommendationTypes.STORY_REPLY

  const handleApplyRecommendation = () => {
    analyticsService.sendEvent('CMS.FLOWS.RECOMMENDATION_POPUP.BTN_APPLY.CLICKED')
    if (isFlowAvailable && recommendationId && recommendationType) {
      openModal({
        type: AutomationModalType.RECOMMENDATIONS_APPLY,
        data: { recommendationId, recommendationType },
      })
      analyticsService.sendEvent('CMS.FLOWS.RECOMMENDATION_POPUP.MODAL_OPENED', {
        occurrences_unq: occurrencesUnique,
        recommendation_type: recommendationType,
        flowId,
        isPro,
      })
    } else {
      handleGoProExperiment(SimpleTrialPlaceType.IG_CGT_ALL_POSTS)
    }
  }

  const statisticsColumnClassName = cx(
    cm.card__statistics,
    !isAnyFilterApplied && cm.card__statistics_accented,
  )

  return (
    <article
      ref={connectDragSource}
      className={cx(cm.card, {
        [cm.card_withoutContextMenu]: !userCanEditFlow || !showFlowContextMenu,
        [cm.card_disabled]: isCardDisabled,
        [cm.card_withHover]: allowHoverFlowCard,
        [cm.card_active]: isFlowCardActive,
      })}
      data-onboarding-id="fs-object-flow"
      data-title-at="top"
      data-title={isCardDisabled && disabledFlowCardMessage ? disabledFlowCardMessage : null}
      data-test-id="flow-card"
    >
      <div className={cm.flowCardWrapper}>
        {flowCardClickAction === FlowCardClickAction.OPEN_FLOW && (
          <a
            onClick={handleFlowCardClick}
            href={getBuilderUrl(flow.path as string)}
            className={cm.card__mainActionButton}
            data-test-id="open-flow-btn"
          />
        )}

        {flowCardClickAction === FlowCardClickAction.SAVE_FLOW_ID_TO_STATE && (
          <button
            onClick={handleFlowCardClick}
            type="button"
            className={cm.card__mainActionButton}
          />
        )}

        {allowSelect && (
          <span className={cm.card__checkboxWrapper}>
            <CheckboxV2
              className={cm.card__interactableElement}
              checked={isFlowSelected}
              onChange={toggleFlowSelection}
              ariaLabel={l.translate('Select flow')}
            />
          </span>
        )}

        <div className={cm.card__body}>
          <div className={cm.card__content}>
            <div className={cx(cm.card__title, 'text-ellipsis')}>
              {flow.has_unpublished_changes && <DraftIndicator />}

              <h1
                className={cx(
                  cm.card__flowName,
                  'text-ellipsis',
                  !isAnyFilterApplied && cm.card__flowName_accented,
                )}
              >
                {flow.name}
              </h1>
            </div>

            <div className={cm.card__triggerToggle} />

            <div className={cx(statisticsColumnClassName, cm.card__hiddenOnMobile)}>
              {getRunsValue(flow.stats.sent)}
            </div>

            <div className={cx(statisticsColumnClassName, cm.card__hiddenOnMobile)}>
              {getCtrValue(flow.stats)}
            </div>

            <div className={cx('text-ellipsis')}>
              <Modified modified={flow.modified} />
            </div>

            <div className={cm.card__contextMenuColumn}>
              {userCanEditFlow && showFlowContextMenu && (
                <FlowContextMenu
                  flowPath={flow.path}
                  flowPinned={flow}
                  anchorClassName={cx(cm.card__interactableElement, cm.card__contextMenuButton)}
                  anchorOpenedClassName={cm.card__contextMenuButton_opened}
                />
              )}

              {flow.template_installation_access_data && (
                <ProtectionStatus
                  className={cx(cm.card__interactableElement, cm.card__protectionStatus)}
                  accessData={flow.template_installation_access_data}
                />
              )}
            </div>

            <div className={cx('text-sm', cm.card__mobileStatistics)}>
              <div className={cm.card__mobileStatisticsRow}>
                <span className="text-ellipsis">{l.translate('Runs')}</span>

                <span className={cx('flex-shrink-0', statisticsColumnClassName)}>
                  {getRunsValue(flow.stats.sent)}
                </span>
              </div>

              <div className={cm.card__mobileStatisticsRow}>
                <span className="text-ellipsis">CTR</span>

                <span className={cx('flex-shrink-0', statisticsColumnClassName)}>
                  {getCtrValue(flow.stats)}
                </span>
              </div>
            </div>
          </div>

          <Triggers triggers={flow.triggers} onClick={handleFlowCardClick} flowId={flowId} />
        </div>
      </div>
      <div
        className={
          isRecommendationApplied ? cm.recommendationAppliedWrapper : cm.recommendationWrapper
        }
      >
        <div className={cm.recommendationWrapperTitle}>
          {title}
          {!isFlowAvailable && (
            <LabelComponent primary sm className="no-select m-l-sm">
              PRO
            </LabelComponent>
          )}
        </div>
        <div className={cm.recommendationWrapperDescription}>{description}</div>
        {isRecommendationApplied ? (
          <div className={cm.recommendationAppliedTitle}>
            <Icon.CheckCircle size={20} className="text-success m-r-xs" />
            <span>{appliedTitle}</span>
          </div>
        ) : (
          <LinkBtn onClick={handleApplyRecommendation} data-test-id="apply-recommendation-btn">
            {buttonTitle}
          </LinkBtn>
        )}
      </div>
    </article>
  )
})

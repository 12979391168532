import React, {
  useRef,
  useState,
  useCallback,
  useMemo,
  useImperativeHandle,
  forwardRef,
  ChangeEvent,
  FocusEvent,
  Ref,
} from 'react'

import { noop } from '../../../utils'
import Highlight from '../../Highlighter'
import SelectWrapper from '../SelectWrapper'
import { MainOptionType } from '../SelectWrapper/interfaces'
import TextInputV2 from '../TextInputV2'

import {
  AutocompleteProps,
  InputRef,
  GeneralQueryType,
  SelectedOption,
  InitialAutocompleteMainOptionType,
} from './interfaces'
import { getLabelByValue, defaultOptionFilter, getOptions } from './utils'

/** Autocomplete used for search and select an item from a list. */
export const Autocomplete = forwardRef(
  <MainOption extends MainOptionType<InitialAutocompleteMainOptionType>, SubOption>(
    {
      /** Autocomplete props */
      onChange = noop,
      onCreate = noop,
      onBlur = noop,
      onFocus = noop,
      onQueryChange = noop,
      onKeyDown = noop,
      onKeyUp = noop,
      onKeyPress = noop,
      customInput,
      allowNew = true,
      allowNewDuplicate,
      placeholder,
      disabled,
      className,
      noClearOnEmpty,
      name = '',
      'data-test-id': dataTestId,
      optionFilter = defaultOptionFilter,
      labelKey = 'label',
      renderOption,
      matchElement = 'mark',
      invalid,
      autoFocus = false,
      forceNew,
      newLabel = "+ New '%value%'",

      /** SelectWrapper props */
      noAutoScroll,
      noPortal,
      renderCustomLastComponent,
      renderGroupLabel,
      emptyLabel,

      /** General props */
      value = '',
      options = [],
      /** input props */
      ...props
    }: AutocompleteProps<MainOption, SubOption>,
    ref?: Ref<InputRef>,
  ) => {
    const inputRef = useRef<InputRef>(null)

    const initialValue: GeneralQueryType = value !== null ? value : ''

    useImperativeHandle(ref, () => inputRef.current, [])

    const [query, setQuery] = useState<GeneralQueryType>('')
    const [isOpen, setOpen] = useState(false)
    const [isChangedAfterOpen, setChangedAfterOpen] = useState(false)

    const handleInputChange = useCallback(
      (event: ChangeEvent<HTMLInputElement>) => {
        const { value } = event.target
        if (!value && !noClearOnEmpty) {
          onChange({
            target: { option: { value: null }, name },
          })
        }
        setOpen(true)
        setChangedAfterOpen(true)
        setQuery(value)
        onQueryChange(event)
      },
      [name, noClearOnEmpty, onChange, onQueryChange],
    )

    const handleInputBlur = useCallback(
      (event: FocusEvent<HTMLInputElement>) => {
        setOpen(false)
        onBlur(event)
      },
      [onBlur],
    )

    const handleInputFocus = useCallback(
      (event: FocusEvent<HTMLInputElement>) => {
        const valueLabel = getLabelByValue<MainOption, SubOption>({
          value: initialValue,
          options,
          labelKey,
        })
        setOpen(true)
        setChangedAfterOpen(false)
        setQuery(valueLabel)
        onFocus(event)
      },
      [labelKey, onFocus, options, initialValue],
    )

    const handleRequestClose = useCallback(
      (
        reason: string,
        event: (KeyboardEvent | PointerEvent) & { stopPropagationToLayers: () => void },
      ) => {
        if (reason === 'clickAnchor') {
          return
        }
        if (reason === 'esc') {
          setOpen(false)
        }

        if (reason !== 'offScreen') {
          event.stopPropagationToLayers()
        }
      },
      [],
    )

    const handleSelect = useCallback(
      (option: SelectedOption<MainOption, SubOption>) => {
        const isNewOption = 'isNew' in option && option.isNew
        if (isNewOption) {
          const group = 'group' in option ? option.group : ''
          const data = 'data' in option ? option.data : ''
          onCreate({ target: { value: data, name, group, option } })
        } else {
          const hasSubOption = 'isSubOption' in option && option.isSubOption
          hasSubOption && onChange({ target: { name, option } })
        }
        setOpen(false)
        setQuery('')
        inputRef.current && inputRef.current.blur()
      },
      [name, onChange, onCreate],
    )

    const handleRenderOption = useCallback(
      (option: SelectedOption<MainOption, SubOption>) => {
        const hasLabel = 'label' in option
        const label = (
          <Highlight matchClass="font-semibold" matchElement={matchElement} search={String(query)}>
            {hasLabel ? option.label : ''}
          </Highlight>
        )

        if (renderOption) {
          return renderOption(option, label)
        }
        return <div className="p-x p-y-xs">{label}</div>
      },
      [matchElement, query, renderOption],
    )

    const queryContent = isOpen && isChangedAfterOpen ? String(query) : ''

    const providedOptions = useMemo(() => {
      const extraOptions = {
        allowNew,
        allowNewDuplicate,
        forceNew,
        newLabel,
        name,
      }
      return getOptions<MainOption, SubOption>({
        options,
        query: queryContent,
        labelKey,
        optionFilter,
        extraOptions,
      })
    }, [
      allowNew,
      allowNewDuplicate,
      forceNew,
      labelKey,
      name,
      newLabel,
      optionFilter,
      options,
      queryContent,
    ])

    const inputValue = useMemo(() => {
      if (isOpen) {
        return query
      }
      return getLabelByValue<MainOption, SubOption>({ value: initialValue, options, labelKey })
    }, [isOpen, labelKey, options, query, initialValue])

    const inputProps = useMemo(
      () => ({
        value: String(inputValue),
        className,
        disabled,
        invalid,
        name,
        placeholder,
        autoFocus,
        onChange: handleInputChange,
        onFocus: handleInputFocus,
        onBlur: handleInputBlur,
        onKeyDown,
        onKeyUp,
        onKeyPress,
        'data-test-id': dataTestId, // leave it here for tests for ui-tests
        testId: dataTestId,
        autoComplete: 'off',
        ...props,
      }),
      [
        props,
        autoFocus,
        className,
        dataTestId,
        disabled,
        handleInputBlur,
        handleInputChange,
        handleInputFocus,
        inputValue,
        invalid,
        name,
        onKeyDown,
        onKeyPress,
        onKeyUp,
        placeholder,
      ],
    )

    return (
      <SelectWrapper
        open={isOpen}
        anchor={inputRef?.current}
        options={providedOptions}
        onRequestClose={handleRequestClose}
        onSelect={handleSelect}
        className="m-y-xxs"
        renderOption={handleRenderOption}
        value={initialValue}
        renderCustomLastComponent={renderCustomLastComponent}
        renderGroupLabel={renderGroupLabel}
        noAutoScroll={noAutoScroll}
        noPortal={noPortal}
        emptyLabel={emptyLabel}
      >
        {customInput ? (
          React.cloneElement(customInput, { ...inputProps, ref: inputRef })
        ) : (
          <TextInputV2 {...inputProps} inputRef={inputRef} />
        )}
      </SelectWrapper>
    )
  },
) as <MainOption extends InitialAutocompleteMainOptionType, SubOption>(
  props: AutocompleteProps<MainOption, SubOption> & { ref?: Ref<InputRef> },
) => JSX.Element

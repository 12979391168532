import React from 'react'
import cx from 'classnames'
import { l } from '@manychat/manyui'

import { useDidMountEffect } from 'utils/commonHooks'
import { analyticsService } from 'utils/services/analytics'

import cm from '../common.module.css'

const ARTICLE_URL = 'https://www.facebook.com/business/help/574941489951914'

const AdsCustomAudienceNotification = () => {
  useDidMountEffect(() => {
    analyticsService.sendEvent('NOTIFICATION_BAR.ADS_CUSTOM_AUDIENCE_DEPRECATED.SHOWN')
  })

  const handleArticleClick = () => {
    analyticsService.sendEvent('NOTIFICATION_BAR.ADS_CUSTOM_AUDIENCE_DEPRECATED.ARTICLE_CLICKED')
  }

  return (
    <>
      <span>
        {l.translate(
          'As a Facebook Ads user, please note that creating new Custom Audiences is no longer supported by Meta.',
        )}
      </span>{' '}
      <a
        className={cx('m-l-auto', cm.link)}
        href={ARTICLE_URL}
        target="_blank"
        rel="noreferrer"
        onClick={handleArticleClick}
      >
        {l.translate('Read more')}
      </a>
    </>
  )
}

export default AdsCustomAudienceNotification

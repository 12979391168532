import { z } from 'zod'

import { ProtoTriggerSchema } from 'shared/api/requests/aiAssistant/schemas'

const flowDraftSchema = z.object({
  draft: z.object({}),
  fields: z.unknown(),
  triggers: z.array(ProtoTriggerSchema),
  tags: z.union([z.record(z.string()), z.array(z.any())]),
})

export const AiFlowGeneratorSchemas = {
  startChat: {
    response: z.object({
      session_id: z.string(),
    }),
    request: z.object({
      flow_ns: z.string(),
      session_id: z.string(),
    }),
    query: z.undefined(),
    path: z.undefined(),
  },
  sendChatEvent: {
    response: z.unknown(),
    request: z.object({
      session_id: z.string(),
      flow_ns: z.string(),

      input: z.string(),
    }),
    query: z.undefined(),
    path: z.undefined(),
  },
  getFlowDraft: {
    response: z.object({
      flow_draft: flowDraftSchema,
    }),
    request: z.object({
      session_id: z.string(),
      flow_draft_id: z.number(),
    }),
    query: z.undefined(),
    path: z.undefined(),
  },
  applyFlow: {
    response: z.unknown(),
    request: z.object({
      session_id: z.string(),
      flow_draft_id: z.number(),
      flow_ns: z.string(),
    }),
    query: z.undefined(),
    path: z.undefined(),
  },
}

export type AiFlowDraft = z.infer<typeof flowDraftSchema>

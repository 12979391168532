type StepsCreatorsType<StepsMap> = {
  [Key in keyof StepsMap]: StepCreatorType<StepsMap[Key]>
}
type StepCreatorType<Step> = () => Step
type StepsInstancesMapType<StepsMap extends Record<string, unknown>> = {
  [Key in keyof StepsMap]: StepsMap[Key] | undefined
}

type StepChangeCallbackType<StepsMap> = (
  stepId: keyof StepsMap | undefined,
  step: StepsMap[keyof StepsMap] | undefined,
) => void

class StepController<StepsMap extends Record<string, unknown>> {
  private currentStepId: keyof StepsMap | undefined = undefined
  private stepsCreators: StepsCreatorsType<StepsMap>

  private firstStepId: keyof StepsMap

  readonly steps: StepsInstancesMapType<StepsMap>

  onStepChange: StepChangeCallbackType<StepsMap> | undefined

  constructor(firstStepId: keyof StepsMap, stepsCreators: StepsCreatorsType<StepsMap>) {
    this.firstStepId = firstStepId
    this.stepsCreators = stepsCreators

    this.steps = this.getDefaultStepsMap(stepsCreators)
  }

  private getDefaultStepsMap = (stepsCreators: StepsCreatorsType<StepsMap>) => {
    const steps = Object.keys(stepsCreators).reduce(
      (acc, key: keyof StepsMap) => ({ ...acc, [key]: undefined }),
      {} as StepsInstancesMapType<StepsMap>,
    )

    return steps
  }

  getCurrentStepId() {
    return this.currentStepId
  }

  getCurrentStep() {
    if (!this.currentStepId) {
      return undefined
    }

    return this.steps[this.currentStepId]
  }

  setStep(nextStepId: keyof StepsMap | undefined) {
    if (this.currentStepId === nextStepId) {
      return
    }

    if (this.currentStepId) {
      delete this.steps[this.currentStepId]
    }

    this.currentStepId = nextStepId

    let instance = undefined
    if (this.currentStepId) {
      const stepCreator = this.stepsCreators[this.currentStepId]
      instance = stepCreator()
      this.steps[this.currentStepId] = instance
    }

    this.onStepChange?.(this.currentStepId, instance)
  }

  run() {
    this.setStep(this.firstStepId)
  }

  complete() {
    this.setStep(undefined)
  }
}

export default StepController

import React from 'react'
import { Icon, palettes, tints } from '@manychat/manyui'

const CheckedIcon = ({
  className,
  size = 32,
  success,
  fill,
}: {
  className?: string
  size?: number
  success?: boolean
  fill?: boolean
}) => {
  let color = palettes.semantic.primary
  if (success) {
    color = palettes.semantic.success
  }

  let iconColor = color
  if (fill) {
    iconColor = tints.neutral[7]
  }

  const bgStyle = {
    width: `${size}px`,
    height: `${size}px`,
    borderRadius: '100%',
    border: `3px solid ${color}`,
    backgroundColor: fill ? color : 'none',
  }

  return (
    <div className={className}>
      <div className="d-flex align-center justify-center" style={bgStyle}>
        <Icon.Tick color={iconColor} size={Math.round(size * 0.65)} />
      </div>
    </div>
  )
}

export default CheckedIcon

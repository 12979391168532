import { AiIntentTrigger } from 'apps/aiIntents/interfaces/interfaces'
import { TriggerType } from 'common/flow/constants/TriggerType'
import { AiIntent, AiIntentStatus } from 'shared/api/requests/aiIntents/schemas'

type AiIntentWithStats = AiIntent & { runs?: number | null; ctr?: number | null }

const aiIntentTriggerAdapter = (item: AiIntent | AiIntentWithStats): AiIntentTrigger => {
  const {
    intent_id,
    channel,
    flow_ns,
    content_holder,
    status,
    intent,
    ts_created,
    was_activated_once,
  } = item

  const trigger: AiIntentTrigger = {
    id: intent_id,
    channel,

    triggerType: TriggerType.AI_INTENT,
    triggerId: `${TriggerType.AI_INTENT}-${intent_id}`,

    flowId: flow_ns,
    chId: content_holder,
    enabled: status === AiIntentStatus.ACTIVE,

    intent,

    caption: intent,

    ts_created,

    was_activated_once: was_activated_once,
  }

  if ('runs' in item) {
    trigger.runs = item.runs
  }
  if ('ctr' in item) {
    trigger.ctr = item.ctr
  }

  return trigger
}

export default aiIntentTriggerAdapter

import React from 'react'
import PropTypes from 'prop-types'

import { QRCode } from 'components/QRCode'

export default class BarCodeGenerator extends React.Component {
  static propTypes = {
    id: PropTypes.string.isRequired,
    size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    value: PropTypes.string,
    hasFrame: PropTypes.bool,
    bgColor: PropTypes.string,
    fgColor: PropTypes.string,
    img: PropTypes.object,
    removeLogoBackground: PropTypes.bool,
  }

  static defaultProps = {
    size: 128,
    value: '',
    renderAs: 'SVG',
    hasFrame: true,
    bgColor: '#fff',
    fgColor: '#000',
    img: null,
    removeLogoBackground: false,
  }

  componentDidMount() {
    this.setSVGAttributes()
  }

  setSVGAttributes = () => {
    const { id } = this.props
    let node = document.getElementById(id)
    node.setAttribute('xmlns', 'http://www.w3.org/2000/svg')
    node.setAttribute('version', '1.1')
  }

  render() {
    const { id, value, size, renderAs, hasFrame, bgColor, fgColor, img, removeLogoBackground } =
      this.props
    return (
      <QRCode
        id={id}
        size={size}
        value={value}
        bgColor={bgColor}
        fgColor={fgColor}
        img={img}
        includeMargin={hasFrame}
        renderAs={renderAs.toLowerCase()}
        removeLogoBackground={removeLogoBackground}
      />
    )
  }
}

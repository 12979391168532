/*
	Follows ISO 4217, https://www.iso.org/iso-4217-currency-codes.html
	See https://www.currency-iso.org/dam/downloads/lists/list_one.xml
	Data last updated 2018-08-29
*/

const currencies = [
  {
    code: 'AED',
    number: '784',
    digits: 2,
    currency: 'UAE Dirham',
  },
  {
    code: 'AFN',
    number: '971',
    digits: 2,
    currency: 'Afghani',
  },
  {
    code: 'ALL',
    number: '008',
    digits: 2,
    currency: 'Lek',
  },
  {
    code: 'AMD',
    number: '051',
    digits: 2,
    currency: 'Armenian Dram',
  },
  {
    code: 'ANG',
    number: '532',
    digits: 2,
    currency: 'Netherlands Antillean Guilder',
  },
  {
    code: 'AOA',
    number: '973',
    digits: 2,
    currency: 'Kwanza',
  },
  {
    code: 'ARS',
    number: '032',
    digits: 2,
    currency: 'Argentine Peso',
  },
  {
    code: 'AUD',
    number: '036',
    digits: 2,
    currency: 'Australian Dollar',
  },
  {
    code: 'AWG',
    number: '533',
    digits: 2,
    currency: 'Aruban Florin',
  },
  {
    code: 'AZN',
    number: '944',
    digits: 2,
    currency: 'Azerbaijan Manat',
  },
  {
    code: 'BAM',
    number: '977',
    digits: 2,
    currency: 'Convertible Mark',
  },
  {
    code: 'BBD',
    number: '052',
    digits: 2,
    currency: 'Barbados Dollar',
  },
  {
    code: 'BDT',
    number: '050',
    digits: 2,
    currency: 'Taka',
  },
  {
    code: 'BGN',
    number: '975',
    digits: 2,
    currency: 'Bulgarian Lev',
  },
  {
    code: 'BHD',
    number: '048',
    digits: 3,
    currency: 'Bahraini Dinar',
  },
  {
    code: 'BIF',
    number: '108',
    digits: 0,
    currency: 'Burundi Franc',
  },
  {
    code: 'BMD',
    number: '060',
    digits: 2,
    currency: 'Bermudian Dollar',
  },
  {
    code: 'BND',
    number: '096',
    digits: 2,
    currency: 'Brunei Dollar',
  },
  {
    code: 'BOB',
    number: '068',
    digits: 2,
    currency: 'Boliviano',
  },
  {
    code: 'BOV',
    number: '984',
    digits: 2,
    currency: 'Mvdol',
  },
  {
    code: 'BRL',
    number: '986',
    digits: 2,
    currency: 'Brazilian Real',
  },
  {
    code: 'BSD',
    number: '044',
    digits: 2,
    currency: 'Bahamian Dollar',
  },
  {
    code: 'BTN',
    number: '064',
    digits: 2,
    currency: 'Ngultrum',
  },
  {
    code: 'BWP',
    number: '072',
    digits: 2,
    currency: 'Pula',
  },
  {
    code: 'BYN',
    number: '933',
    digits: 2,
    currency: 'Belarusian Ruble',
  },
  {
    code: 'BZD',
    number: '084',
    digits: 2,
    currency: 'Belize Dollar',
  },
  {
    code: 'CAD',
    number: '124',
    digits: 2,
    currency: 'Canadian Dollar',
  },
  {
    code: 'CDF',
    number: '976',
    digits: 2,
    currency: 'Congolese Franc',
  },
  {
    code: 'CHE',
    number: '947',
    digits: 2,
    currency: 'WIR Euro',
  },
  {
    code: 'CHF',
    number: '756',
    digits: 2,
    currency: 'Swiss Franc',
  },
  {
    code: 'CHW',
    number: '948',
    digits: 2,
    currency: 'WIR Franc',
  },
  {
    code: 'CLF',
    number: '990',
    digits: 4,
    currency: 'Unidad de Fomento',
  },
  {
    code: 'CLP',
    number: '152',
    digits: 0,
    currency: 'Chilean Peso',
  },
  {
    code: 'CNY',
    number: '156',
    digits: 2,
    currency: 'Yuan Renminbi',
  },
  {
    code: 'COP',
    number: '170',
    digits: 2,
    currency: 'Colombian Peso',
  },
  {
    code: 'COU',
    number: '970',
    digits: 2,
    currency: 'Unidad de Valor Real',
  },
  {
    code: 'CRC',
    number: '188',
    digits: 2,
    currency: 'Costa Rican Colon',
  },
  {
    code: 'CUC',
    number: '931',
    digits: 2,
    currency: 'Peso Convertible',
  },
  {
    code: 'CUP',
    number: '192',
    digits: 2,
    currency: 'Cuban Peso',
  },
  {
    code: 'CVE',
    number: '132',
    digits: 2,
    currency: 'Cabo Verde Escudo',
  },
  {
    code: 'CZK',
    number: '203',
    digits: 2,
    currency: 'Czech Koruna',
  },
  {
    code: 'DJF',
    number: '262',
    digits: 0,
    currency: 'Djibouti Franc',
  },
  {
    code: 'DKK',
    number: '208',
    digits: 2,
    currency: 'Danish Krone',
  },
  {
    code: 'DOP',
    number: '214',
    digits: 2,
    currency: 'Dominican Peso',
  },
  {
    code: 'DZD',
    number: '012',
    digits: 2,
    currency: 'Algerian Dinar',
  },
  {
    code: 'EGP',
    number: '818',
    digits: 2,
    currency: 'Egyptian Pound',
  },
  {
    code: 'ERN',
    number: '232',
    digits: 2,
    currency: 'Nakfa',
  },
  {
    code: 'ETB',
    number: '230',
    digits: 2,
    currency: 'Ethiopian Birr',
  },
  {
    code: 'EUR',
    number: '978',
    digits: 2,
    currency: 'Euro',
  },
  {
    code: 'FJD',
    number: '242',
    digits: 2,
    currency: 'Fiji Dollar',
  },
  {
    code: 'FKP',
    number: '238',
    digits: 2,
    currency: 'Falkland Islands Pound',
  },
  {
    code: 'GBP',
    number: '826',
    digits: 2,
    currency: 'Pound Sterling',
  },
  {
    code: 'GEL',
    number: '981',
    digits: 2,
    currency: 'Lari',
  },
  {
    code: 'GHS',
    number: '936',
    digits: 2,
    currency: 'Ghana Cedi',
  },
  {
    code: 'GIP',
    number: '292',
    digits: 2,
    currency: 'Gibraltar Pound',
  },
  {
    code: 'GMD',
    number: '270',
    digits: 2,
    currency: 'Dalasi',
  },
  {
    code: 'GNF',
    number: '324',
    digits: 0,
    currency: 'Guinean Franc',
  },
  {
    code: 'GTQ',
    number: '320',
    digits: 2,
    currency: 'Quetzal',
  },
  {
    code: 'GYD',
    number: '328',
    digits: 2,
    currency: 'Guyana Dollar',
  },
  {
    code: 'HKD',
    number: '344',
    digits: 2,
    currency: 'Hong Kong Dollar',
  },
  {
    code: 'HNL',
    number: '340',
    digits: 2,
    currency: 'Lempira',
  },
  {
    code: 'HRK',
    number: '191',
    digits: 2,
    currency: 'Kuna',
  },
  {
    code: 'HTG',
    number: '332',
    digits: 2,
    currency: 'Gourde',
  },
  {
    code: 'HUF',
    number: '348',
    digits: 2,
    currency: 'Forint',
  },
  {
    code: 'IDR',
    number: '360',
    digits: 2,
    currency: 'Rupiah',
  },
  {
    code: 'ILS',
    number: '376',
    digits: 2,
    currency: 'New Israeli Sheqel',
  },
  {
    code: 'INR',
    number: '356',
    digits: 2,
    currency: 'Indian Rupee',
  },
  {
    code: 'IQD',
    number: '368',
    digits: 3,
    currency: 'Iraqi Dinar',
  },
  {
    code: 'IRR',
    number: '364',
    digits: 2,
    currency: 'Iranian Rial',
  },
  {
    code: 'ISK',
    number: '352',
    digits: 0,
    currency: 'Iceland Krona',
  },
  {
    code: 'JMD',
    number: '388',
    digits: 2,
    currency: 'Jamaican Dollar',
  },
  {
    code: 'JOD',
    number: '400',
    digits: 3,
    currency: 'Jordanian Dinar',
  },
  {
    code: 'JPY',
    number: '392',
    digits: 0,
    currency: 'Yen',
  },
  {
    code: 'KES',
    number: '404',
    digits: 2,
    currency: 'Kenyan Shilling',
  },
  {
    code: 'KGS',
    number: '417',
    digits: 2,
    currency: 'Som',
  },
  {
    code: 'KHR',
    number: '116',
    digits: 2,
    currency: 'Riel',
  },
  {
    code: 'KMF',
    number: '174',
    digits: 0,
    currency: 'Comorian Franc ',
  },
  {
    code: 'KPW',
    number: '408',
    digits: 2,
    currency: 'North Korean Won',
  },
  {
    code: 'KRW',
    number: '410',
    digits: 0,
    currency: 'Won',
  },
  {
    code: 'KWD',
    number: '414',
    digits: 3,
    currency: 'Kuwaiti Dinar',
  },
  {
    code: 'KYD',
    number: '136',
    digits: 2,
    currency: 'Cayman Islands Dollar',
  },
  {
    code: 'KZT',
    number: '398',
    digits: 2,
    currency: 'Tenge',
  },
  {
    code: 'LAK',
    number: '418',
    digits: 2,
    currency: 'Lao Kip',
  },
  {
    code: 'LBP',
    number: '422',
    digits: 2,
    currency: 'Lebanese Pound',
  },
  {
    code: 'LKR',
    number: '144',
    digits: 2,
    currency: 'Sri Lanka Rupee',
  },
  {
    code: 'LRD',
    number: '430',
    digits: 2,
    currency: 'Liberian Dollar',
  },
  {
    code: 'LSL',
    number: '426',
    digits: 2,
    currency: 'Loti',
  },
  {
    code: 'LYD',
    number: '434',
    digits: 3,
    currency: 'Libyan Dinar',
  },
  {
    code: 'MAD',
    number: '504',
    digits: 2,
    currency: 'Moroccan Dirham',
  },
  {
    code: 'MDL',
    number: '498',
    digits: 2,
    currency: 'Moldovan Leu',
  },
  {
    code: 'MGA',
    number: '969',
    digits: 2,
    currency: 'Malagasy Ariary',
  },
  {
    code: 'MKD',
    number: '807',
    digits: 2,
    currency: 'Denar',
  },
  {
    code: 'MMK',
    number: '104',
    digits: 2,
    currency: 'Kyat',
  },
  {
    code: 'MNT',
    number: '496',
    digits: 2,
    currency: 'Tugrik',
  },
  {
    code: 'MOP',
    number: '446',
    digits: 2,
    currency: 'Pataca',
  },
  {
    code: 'MRU',
    number: '929',
    digits: 2,
    currency: 'Ouguiya',
  },
  {
    code: 'MUR',
    number: '480',
    digits: 2,
    currency: 'Mauritius Rupee',
  },
  {
    code: 'MVR',
    number: '462',
    digits: 2,
    currency: 'Rufiyaa',
  },
  {
    code: 'MWK',
    number: '454',
    digits: 2,
    currency: 'Malawi Kwacha',
  },
  {
    code: 'MXN',
    number: '484',
    digits: 2,
    currency: 'Mexican Peso',
  },
  {
    code: 'MXV',
    number: '979',
    digits: 2,
    currency: 'Mexican Unidad de Inversion (UDI)',
  },
  {
    code: 'MYR',
    number: '458',
    digits: 2,
    currency: 'Malaysian Ringgit',
  },
  {
    code: 'MZN',
    number: '943',
    digits: 2,
    currency: 'Mozambique Metical',
  },
  {
    code: 'NAD',
    number: '516',
    digits: 2,
    currency: 'Namibia Dollar',
  },
  {
    code: 'NGN',
    number: '566',
    digits: 2,
    currency: 'Naira',
  },
  {
    code: 'NIO',
    number: '558',
    digits: 2,
    currency: 'Cordoba Oro',
  },
  {
    code: 'NOK',
    number: '578',
    digits: 2,
    currency: 'Norwegian Krone',
  },
  {
    code: 'NPR',
    number: '524',
    digits: 2,
    currency: 'Nepalese Rupee',
  },
  {
    code: 'NZD',
    number: '554',
    digits: 2,
    currency: 'New Zealand Dollar',
  },
  {
    code: 'OMR',
    number: '512',
    digits: 3,
    currency: 'Rial Omani',
  },
  {
    code: 'PAB',
    number: '590',
    digits: 2,
    currency: 'Balboa',
  },
  {
    code: 'PEN',
    number: '604',
    digits: 2,
    currency: 'Sol',
  },
  {
    code: 'PGK',
    number: '598',
    digits: 2,
    currency: 'Kina',
  },
  {
    code: 'PHP',
    number: '608',
    digits: 2,
    currency: 'Philippine Peso',
  },
  {
    code: 'PKR',
    number: '586',
    digits: 2,
    currency: 'Pakistan Rupee',
  },
  {
    code: 'PLN',
    number: '985',
    digits: 2,
    currency: 'Zloty',
  },
  {
    code: 'PYG',
    number: '600',
    digits: 0,
    currency: 'Guarani',
  },
  {
    code: 'QAR',
    number: '634',
    digits: 2,
    currency: 'Qatari Rial',
  },
  {
    code: 'RON',
    number: '946',
    digits: 2,
    currency: 'Romanian Leu',
  },
  {
    code: 'RSD',
    number: '941',
    digits: 2,
    currency: 'Serbian Dinar',
  },
  {
    code: 'RUB',
    number: '643',
    digits: 2,
    currency: 'Russian Ruble',
  },
  {
    code: 'RWF',
    number: '646',
    digits: 0,
    currency: 'Rwanda Franc',
  },
  {
    code: 'SAR',
    number: '682',
    digits: 2,
    currency: 'Saudi Riyal',
  },
  {
    code: 'SBD',
    number: '090',
    digits: 2,
    currency: 'Solomon Islands Dollar',
  },
  {
    code: 'SCR',
    number: '690',
    digits: 2,
    currency: 'Seychelles Rupee',
  },
  {
    code: 'SDG',
    number: '938',
    digits: 2,
    currency: 'Sudanese Pound',
  },
  {
    code: 'SEK',
    number: '752',
    digits: 2,
    currency: 'Swedish Krona',
  },
  {
    code: 'SGD',
    number: '702',
    digits: 2,
    currency: 'Singapore Dollar',
  },
  {
    code: 'SHP',
    number: '654',
    digits: 2,
    currency: 'Saint Helena Pound',
  },
  {
    code: 'SLL',
    number: '694',
    digits: 2,
    currency: 'Leone',
  },
  {
    code: 'SOS',
    number: '706',
    digits: 2,
    currency: 'Somali Shilling',
  },
  {
    code: 'SRD',
    number: '968',
    digits: 2,
    currency: 'Surinam Dollar',
  },
  {
    code: 'SSP',
    number: '728',
    digits: 2,
    currency: 'South Sudanese Pound',
  },
  {
    code: 'STN',
    number: '930',
    digits: 2,
    currency: 'Dobra',
  },
  {
    code: 'SVC',
    number: '222',
    digits: 2,
    currency: 'El Salvador Colon',
  },
  {
    code: 'SYP',
    number: '760',
    digits: 2,
    currency: 'Syrian Pound',
  },
  {
    code: 'SZL',
    number: '748',
    digits: 2,
    currency: 'Lilangeni',
  },
  {
    code: 'THB',
    number: '764',
    digits: 2,
    currency: 'Baht',
  },
  {
    code: 'TJS',
    number: '972',
    digits: 2,
    currency: 'Somoni',
  },
  {
    code: 'TMT',
    number: '934',
    digits: 2,
    currency: 'Turkmenistan New Manat',
  },
  {
    code: 'TND',
    number: '788',
    digits: 3,
    currency: 'Tunisian Dinar',
  },
  {
    code: 'TOP',
    number: '776',
    digits: 2,
    currency: 'Pa’anga',
  },
  {
    code: 'TRY',
    number: '949',
    digits: 2,
    currency: 'Turkish Lira',
  },
  {
    code: 'TTD',
    number: '780',
    digits: 2,
    currency: 'Trinidad and Tobago Dollar',
  },
  {
    code: 'TWD',
    number: '901',
    digits: 2,
    currency: 'New Taiwan Dollar',
  },
  {
    code: 'TZS',
    number: '834',
    digits: 2,
    currency: 'Tanzanian Shilling',
  },
  {
    code: 'UAH',
    number: '980',
    digits: 2,
    currency: 'Hryvnia',
  },
  {
    code: 'UGX',
    number: '800',
    digits: 0,
    currency: 'Uganda Shilling',
  },
  {
    code: 'USD',
    number: '840',
    digits: 2,
    currency: 'US Dollar',
  },
  // TODO: remove duplication for the same currency TD-372
  {
    code: 'USD',
    number: '001',
    digits: 2,
    currency: 'US Dollar',
  },
  {
    code: 'USN',
    number: '997',
    digits: 2,
    currency: 'US Dollar (Next day)',
  },
  {
    code: 'UYI',
    number: '940',
    digits: 0,
    currency: 'Uruguay Peso en Unidades Indexadas (UI)',
  },
  {
    code: 'UYU',
    number: '858',
    digits: 2,
    currency: 'Peso Uruguayo',
  },
  {
    code: 'UYW',
    number: '927',
    digits: 4,
    currency: 'Unidad Previsional',
  },
  {
    code: 'UZS',
    number: '860',
    digits: 2,
    currency: 'Uzbekistan Sum',
  },
  {
    code: 'VES',
    number: '928',
    digits: 2,
    currency: 'Bolívar Soberano',
  },
  {
    code: 'VND',
    number: '704',
    digits: 0,
    currency: 'Dong',
  },
  {
    code: 'VUV',
    number: '548',
    digits: 0,
    currency: 'Vatu',
  },
  {
    code: 'WST',
    number: '882',
    digits: 2,
    currency: 'Tala',
  },
  {
    code: 'XAF',
    number: '950',
    digits: 0,
    currency: 'CFA Franc BEAC',
  },
  {
    code: 'XAG',
    number: '961',
    digits: 0,
    currency: 'Silver',
  },
  {
    code: 'XAU',
    number: '959',
    digits: 0,
    currency: 'Gold',
  },
  {
    code: 'XBA',
    number: '955',
    digits: 0,
    currency: 'Bond Markets Unit European Composite Unit (EURCO)',
  },
  {
    code: 'XBB',
    number: '956',
    digits: 0,
    currency: 'Bond Markets Unit European Monetary Unit (E.M.U.-6)',
  },
  {
    code: 'XBC',
    number: '957',
    digits: 0,
    currency: 'Bond Markets Unit European Unit of Account 9 (E.U.A.-9)',
  },
  {
    code: 'XBD',
    number: '958',
    digits: 0,
    currency: 'Bond Markets Unit European Unit of Account 17 (E.U.A.-17)',
  },
  {
    code: 'XCD',
    number: '951',
    digits: 2,
    currency: 'East Caribbean Dollar',
  },
  {
    code: 'XDR',
    number: '960',
    digits: 0,
    currency: 'SDR (Special Drawing Right)',
  },
  {
    code: 'XOF',
    number: '952',
    digits: 0,
    currency: 'CFA Franc BCEAO',
  },
  {
    code: 'XPD',
    number: '964',
    digits: 0,
    currency: 'Palladium',
  },
  {
    code: 'XPF',
    number: '953',
    digits: 0,
    currency: 'CFP Franc',
  },
  {
    code: 'XPT',
    number: '962',
    digits: 0,
    currency: 'Platinum',
  },
  {
    code: 'XSU',
    number: '994',
    digits: 0,
    currency: 'Sucre',
  },
  {
    code: 'XTS',
    number: '963',
    digits: 0,
    currency: 'Codes specifically reserved for testing purposes',
  },
  {
    code: 'XUA',
    number: '965',
    digits: 0,
    currency: 'ADB Unit of Account',
  },
  {
    code: 'XXX',
    number: '999',
    digits: 0,
    currency: 'The codes assigned for transactions where no currency is involved',
  },
  {
    code: 'YER',
    number: '886',
    digits: 2,
    currency: 'Yemeni Rial',
  },
  {
    code: 'ZAR',
    number: '710',
    digits: 2,
    currency: 'Rand',
  },
  {
    code: 'ZMW',
    number: '967',
    digits: 2,
    currency: 'Zambian Kwacha',
  },
  {
    code: 'ZWL',
    number: '932',
    digits: 2,
    currency: 'Zimbabwe Dollar',
  },
]

export default currencies

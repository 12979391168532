import dot from 'dot-prop-immutable'

import { IActionWithPayload } from 'common/core/interfaces/actions'
import * as atypes from 'common/userRoles/constants/UserRolesReduxActionTypes'

import { UsersState } from '../userRolesInterfaces'

const InitialState: UsersState = {
  inviteLink: null,
  items: null,
  canCreateInvite: {
    can_create_user: false,
  },
  isFetching: false,
  currentInboxUser: {
    userId: null,
    userName: '',
    isFetchingInboxUserStatus: false,
    isFetchingCheckboxStatus: false,
    isAddPaidUserModalVisible: false,
    isDeletePaidUserModalVisible: false,
  },
}

const getMemberIndex = (state: UsersState, userId: number) =>
  state.items?.findIndex((member) => member.user_id === userId)

export default function userRolesReducer(
  state = InitialState,
  action: IActionWithPayload,
): UsersState {
  switch (action.type) {
    case atypes.USER_ROLES_FETCH_USERS_REQUEST: {
      return { ...state, isFetching: true }
    }
    case atypes.USER_ROLES_FETCH_USERS_RESPONSE: {
      return { ...state, isFetching: false, items: action.data.users || null }
    }
    case atypes.USER_ROLES_FETCH_USERS_ERROR: {
      return { ...state, isFetching: false }
    }
    case atypes.ACCESS_REQUESTS_APPROVE_RESPONSE: {
      if (action.data.user) {
        return { ...state, items: [...(state.items || []), action.data.user] }
      }
      return state
    }
    case atypes.CAN_CREATE_INVITE_LINK_ERROR: {
      return {
        ...state,
        canCreateInvite: {
          can_create_user: false,
        },
      }
    }
    case atypes.CAN_CREATE_INVITE_LINK_RESPONSE: {
      const { need_show_warning, can_create_user } = action.data.data
      return {
        ...state,
        canCreateInvite: {
          need_show_warning,
          can_create_user,
        },
      }
    }
    case atypes.USER_ROLES_GENERATE_INVITE_LINK_RESPONSE: {
      return { ...state, inviteLink: action.data.link || '' }
    }
    case atypes.USER_ROLES_RESET_INVITE_LINK: {
      return { ...state, inviteLink: null }
    }
    case atypes.USER_ROLES_UPDATE_ROLE_REQUEST: {
      const memberIndex = (state.items || []).findIndex((user) => user.user_id === action.userId)

      if (memberIndex === -1) {
        return state
      }
      return dot.set(state, `items.${memberIndex}.user_role`, action.role)
    }
    case atypes.USER_ROLES_SET_BILLING_ACCESS_REQUEST: {
      const memberIndex = (state.items || []).findIndex((user) => user.user_id === action.userId)
      if (memberIndex === -1) {
        return state
      }
      return dot.set(state, `items.${memberIndex}.has_billing_access`, action.access)
    }

    case atypes.USER_ROLES_SET_CONVERSATIONS_LIMIT_REQUEST: {
      const { userIds } = action

      const updatedItems = (state.items || []).map((item) => {
        if (userIds.includes(item.user_id)) {
          item.isThreadsLimitChanging = true
        }
        return item
      })

      return {
        ...state,
        items: updatedItems,
      }
    }

    case atypes.USER_ROLES_SET_CONVERSATIONS_LIMIT_RESPONSE: {
      const { userIds, limit } = action

      const updatedItems = (state.items || []).map((item) => {
        if (userIds.includes(item.user_id)) {
          item.threads_limit = limit
          item.isThreadsLimitChanging = false
        }
        return item
      })

      return {
        ...state,
        items: updatedItems,
      }
    }

    case atypes.USER_ROLES_SET_CONVERSATIONS_LIMIT_ERROR: {
      const { userIds } = action

      const updatedItems = (state.items || []).map((item) => {
        if (userIds.includes(item.user_id)) {
          item.isThreadsLimitChanging = false
        }
        return item
      })

      return {
        ...state,
        items: updatedItems,
      }
    }

    case atypes.USER_ROLES_SET_AUTO_ASSIGNMENT_ENABLED_RESPONSE: {
      const { userIds, isEnabled } = action

      const updatedItems = (state.items || []).map((item) => {
        if (userIds.includes(item.user_id)) {
          item.lc_auto_assign_enabled = isEnabled
        }
        return item
      })

      return {
        ...state,
        items: updatedItems,
      }
    }

    case atypes.CAN_SET_INBOX_USER_REQUEST: {
      return {
        ...state,
        currentInboxUser: {
          ...state.currentInboxUser,
          isFetchingCheckboxStatus: true,
          userId: action.userId,
          userName: action.userName,
        },
      }
    }
    case atypes.CAN_SET_INBOX_USER_RESPONSE:
    case atypes.CAN_SET_INBOX_USER_ERROR: {
      return {
        ...state,
        currentInboxUser: { ...state.currentInboxUser, isFetchingCheckboxStatus: false },
      }
    }

    case atypes.SET_ADD_PAID_USER_MODAL_VISIBILITY: {
      return {
        ...state,
        currentInboxUser: {
          ...state.currentInboxUser,
          isFetchingCheckboxStatus: false,
          isAddPaidUserModalVisible: action.isVisible,
        },
      }
    }

    case atypes.SET_DELETE_PAID_USER_MODAL_VISIBILITY: {
      return {
        ...state,
        currentInboxUser: {
          ...state.currentInboxUser,
          isFetchingCheckboxStatus: false,
          isDeletePaidUserModalVisible: action.isVisible,
        },
      }
    }

    case atypes.USER_ROLES_SET_INBOX_USER_REQUEST: {
      return {
        ...state,
        currentInboxUser: { ...state.currentInboxUser, isFetchingInboxUserStatus: true },
      }
    }

    case atypes.USER_ROLES_SET_INBOX_USER_ERROR: {
      return {
        ...state,
        currentInboxUser: {
          ...state.currentInboxUser,
          isFetchingInboxUserStatus: false,
          isFetchingCheckboxStatus: false,
        },
      }
    }

    case atypes.USER_ROLES_SET_INBOX_USER_RESPONSE: {
      const memberIndex = getMemberIndex(state, action.userId)
      if (memberIndex === -1) return state

      state = dot.set(state, `items.${memberIndex}.is_inbox_user`, action.isInboxUser)
      return {
        ...state,
        currentInboxUser: {
          ...state.currentInboxUser,
          isFetchingInboxUserStatus: false,
          isFetchingCheckboxStatus: false,
        },
      }
    }
  }

  return state
}

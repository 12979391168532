import assert from 'assert'

import { unlinkBlock } from 'common/builder/actions/unlinkBlock'
import { LINK_NODE_AND_BLOCK } from 'common/builder/constants/builderReduxActionTypes'
import nodeRegistry from 'common/builder/nodeRegistry'
import {
  getNodeById,
  getBlockById,
  getParentBlock,
} from 'common/builder/selectors/builder/entitySelectors'

export const linkBlock = (builderId, nodeId, targetId, options = {}) => {
  return (dispatch, getState) => {
    const state = getState()

    assert(builderId, `linkBlock: builderId is required param`)
    assert(nodeId, `linkBlock: nodeId is required param`)
    assert(targetId, `linkBlock: targetId is required param`)

    const node = getNodeById(state, builderId, nodeId)
    assert(node, `linkBlock: node doesn't exist`)

    const block = getBlockById(state, builderId, targetId)
    assert(block, `linkBlock: block doesn't exist`)

    const targetParentItem = getParentBlock(state, builderId, targetId)
    if (options.relink) {
      if (targetParentItem) {
        dispatch(unlinkBlock(builderId, targetParentItem.id, targetId))
      }
    } else {
      assert(!targetParentItem, `linkBlock: block "${targetId}" already linked`)
    }

    assert(
      nodeRegistry.get(node).isBlockTypeAllowed({ node, block }),
      `linkBlock: target type "${block.type}" isn't allowed`,
    )

    assert(
      nodeRegistry.get(node).isAddBlockAllowed({ node, block }),
      `linkBlock: content may contain not more than "x" blocks`,
    )

    return dispatch({
      type: LINK_NODE_AND_BLOCK,
      builderId,
      nodeId,
      targetId,
      index: options.index,
    })
  }
}

import React from 'react'
import { l } from '@manychat/manyui'

import cm from './common.module.css'

export const WAFlaggedNotification = () => {
  return (
    <div>
      {l.translate(`WhatsApp messaging is at risk of being restricted by Meta.
      Improve the Quality Rating of your phone number to avoid a downgrade. `)}
      <a href="https://www.facebook.com/business/help/896873687365001" className={cm.link}>
        {l.translate('Learn More')}
      </a>
    </div>
  )
}

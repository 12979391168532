import { NodeType } from 'common/builder/constants/NodeType'
import { ChannelType } from 'common/core/constants/ChannelType'

export const NodeTypeToChannelMap: Record<string, ChannelType> = {
  [NodeType.INSTAGRAM]: ChannelType.INSTAGRAM,
  [NodeType.WHATS_APP]: ChannelType.WHATSAPP,
  [NodeType.CONTENT]: ChannelType.FB,
  [NodeType.TELEGRAM]: ChannelType.TELEGRAM,
  [NodeType.SMS]: ChannelType.SMS,
  [NodeType.EMAIL_NEW]: ChannelType.EMAIL,
  [NodeType.TIKTOK]: ChannelType.TIKTOK,
}

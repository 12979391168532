import { IWidget } from 'apps/growthTools/growthToolsInterfaces'

export const isWidget = (widget: unknown): widget is IWidget => {
  return (
    typeof widget === 'object' &&
    widget !== null &&
    'id' in widget &&
    'triggerType' in widget &&
    'triggerId' in widget &&
    'widget_type' in widget &&
    'stats' in widget
  )
}

import assert from 'assert'

import cloneDeep from 'lodash/cloneDeep'
import has from 'lodash/has'
import includes from 'lodash/includes'
import omit from 'lodash/omit'
import without from 'lodash/without'
import { l } from '@manychat/manyui'

import { isListMessageButton } from 'apps/whatsApp/ListMessages/helpers/isListMessageButton'
import { builderCreateNode } from 'common/builder/actions/builderCreateNode'
import { createButton } from 'common/builder/actions/createButton'
import { createLinkedButton } from 'common/builder/actions/createLinkedButton'
import { openNode } from 'common/builder/actions/openNode'
import { updateBlock } from 'common/builder/actions/updateBlock'
import buttonRegistry from 'common/builder/buttonRegistry'
import { applyTypeChanges, isUrlButton } from 'common/builder/buttons/buttonTypeHelpers'
import { BlockType } from 'common/builder/constants/BlockType'
import * as ActionTypes from 'common/builder/constants/builderReduxActionTypes'
import { ButtonType } from 'common/builder/constants/ButtonType'
import { NodeType } from 'common/builder/constants/NodeType'
import BlockModel from 'common/builder/models/Block'
import { NextStepType } from 'common/builder/models/Source/constants'
import * as entitySelectors from 'common/builder/selectors/builder/entitySelectors'
import { isWaCtaButtonActive } from 'common/builder/selectors/builder/nextStepSelectors'
import * as sourceSelectors from 'common/builder/selectors/builder/sourceSelectors'
import { uploadAttachment } from 'common/core/actions/attachmentActions'
import { ChannelType } from 'common/core/constants/ChannelType'
import { isFilterContainSmartSegment } from 'common/filter/models/AudienceFilter/helpers'
import { parseSmartLinkString } from 'components/MCTextEditor/smartLinks/adapter'
import { INLINE_BUTTON_REGEX } from 'constants/RegExs'
import { analyticsService } from 'utils/services/analytics'
import errorTrackingService from 'utils/services/errorTrackingService'
import { accountFlags } from 'utils/services/featureFlagsService'
import { AccountFlag } from 'utils/services/featureFlagsService/constants'
import { alertWithHotjarEventFactory } from 'utils/services/hotjar'

import * as sourceActions from './sourceActions'

const alertWithHotjarEvent = alertWithHotjarEventFactory

/**
 * @param builderId
 * @param buttonId
 * @param nextStepType
 */
export function selectButtonNextStep(builderId, buttonId, { nextStepType, nodeId, keepType }) {
  return (dispatch, getState) => {
    const state = getState()
    const button = entitySelectors.getButtonById(state, builderId, buttonId)
    const nextStepIsNode = includes(NodeType, nextStepType)

    let buttonType = ButtonType.CONTENT
    if (nodeId) {
      dispatch(sourceActions.updateSourceTarget(builderId, buttonId, nodeId))
    } else if (nextStepIsNode) {
      dispatch(
        builderCreateNode(builderId, nextStepType, {
          srcId: buttonId,
          preventOpen: true,
        }),
      )
    } else if (button.targetId) {
      const target = sourceSelectors.getTarget(state, builderId, buttonId)
      const isUrlWithActions = isUrlButton(button) && target.nodeType === NodeType.ACTION_GROUP
      if (!isUrlWithActions) {
        dispatch(sourceActions.deleteSourceTarget(builderId, buttonId))
      }
    }

    if (nextStepType && !nextStepIsNode) {
      buttonType = nextStepType
    }

    if (button.type === ButtonType.CROSS_CHANNEL_CONTENT) {
      buttonType = ButtonType.CROSS_CHANNEL_CONTENT
    }

    if (keepType || button.type === buttonType) {
      return
    }

    return dispatch(updateButton(builderId, buttonId, { type: buttonType }))
  }
}

/**
 * @param builderId
 * @param buttonId
 */
export function resetButtonNextStep(builderId, buttonId, { keepType, keepTarget }) {
  return (dispatch, getState) => {
    const state = getState()
    const button = entitySelectors.getButtonById(state, builderId, buttonId)

    if (button.isSmartLink) {
      if (keepType) {
        return dispatch(sourceActions.deleteSourceTarget(builderId, buttonId))
      }
      return dispatch(removeButton(builderId, buttonId))
    }
    if (!keepTarget) {
      dispatch(sourceActions.deleteSourceTarget(builderId, buttonId))
    }

    if (keepType || button.type === ButtonType.CONTENT) {
      return
    }

    dispatch(updateButton(builderId, buttonId, { type: ButtonType.CONTENT }))
  }
}

/**
 * @param builderId
 * @param buttonId
 */
export function openButtonNextStep(builderId, buttonId) {
  return (dispatch, getState) => {
    const state = getState()

    const targetId = sourceSelectors.getTargetId(state, builderId, buttonId)

    dispatch(openNode(builderId, targetId, { referrer: { buttonId } }))
  }
}

///////////////////
// BASIC ACTIONS //
///////////////////

/**
 * @param builderId
 * @param buttonId
 */
export function removeButton(builderId, buttonId) {
  return (dispatch, getState) => {
    const state = getState()

    assert(builderId, `removeButton: builderId is required param`)
    assert(buttonId, `removeBlock: buttonId is required param`)

    const item = entitySelectors.getButtonById(state, builderId, buttonId)
    if (!item) {
      errorTrackingService.trackWarningOnce(
        `removeButton: button with id "${buttonId}" doesn't exist`,
        {
          extra: { builderId, buttonId },
          fingerprint: 'removeBlock-button-does-not-exist',
          tags: { area: 'builder', topic: "id doesn't exist" },
        },
      )

      return console.warn(`removeButton: button with id "${buttonId}" doesn't exist`)
    }

    dispatch(removeSmartLinkFromText(builderId, buttonId))

    if (isListMessageButton(item)) {
      const block = entitySelectors.getParentBlock(state, builderId, item.id)
      const updatedSections = cloneDeep(block.sections)

      for (const section of updatedSections) {
        for (let optionIndex = 0; optionIndex < section.options.length; optionIndex++) {
          if (section.options[optionIndex]._oid === item.id) {
            section.options.splice(optionIndex, 1)

            dispatch(
              updateBlock(builderId, block.id, {
                sections: updatedSections,
              }),
            )
          }
        }
      }
    }

    return dispatch(removeButtonForce(builderId, buttonId))
  }
}

export function removeButtonForce(builderId, buttonId) {
  return {
    type: ActionTypes.REMOVE_BUTTON,
    builderId,
    buttonId,
  }
}

/**
 * @param builderId
 * @param buttonId
 * @param changes
 */
export function updateButton(builderId, buttonId, changes) {
  return (dispatch, getState) => {
    const state = getState()
    assert(builderId, `updateButton: builderId is required param`)
    assert(buttonId, `updateButton: buttonId is required param`)
    assert(!has(changes, 'id'), `updateButton: attribute "id" cannot be changed`)
    assert(!has(changes, 'targetId'), `updateButton: attribute "targetId" cannot be changed`)

    if (has(changes, 'type')) {
      assert(Boolean(changes.type), `updateButton: cannot change type to null`)
      const parentItem = entitySelectors.getParentBlock(state, builderId, buttonId)
      const { nodeType } = entitySelectors.getParentNode(state, builderId, parentItem.id)
      if (parentItem) {
        assert(
          BlockModel.isNestedButtonTypeAllowed(parentItem, changes.type, nodeType),
          `updateButton: button type cannot be changed to "${changes.type}" because it is linked to block with type ${parentItem.type}`,
        )
      }
    }

    // QuickReplies: Should be only one type of pic - emoji or image
    if (has(changes, 'emoji')) {
      changes.image = null
    } else if (has(changes, 'image')) {
      changes.emoji = null
    }

    // Upload
    if (changes.image != null) {
      try {
        const { uploadId } = dispatch(uploadAttachment(changes.image, { builderId, buttonId }))
        changes.image.uploadId = uploadId
      } catch (e) {
        return alertWithHotjarEvent(e.message, 'danger')
      }
    }
    const hasSmartSegmentInCondition = isFilterContainSmartSegment(changes.filter)

    if (hasSmartSegmentInCondition) {
      analyticsService.sendEvent('SMART.SEGMENT.CONDITION.PAYMENTS')
    }

    const button = entitySelectors.getButtonById(getState(), builderId, buttonId)
    if (has(changes, 'type')) {
      const item = applyTypeChanges(button, changes)
      return dispatch({
        type: ActionTypes.CREATE_BUTTON,
        builderId,
        item,
      })
    }

    const knownAttributes = buttonRegistry.get(button).getAttributes()
    const changesAttributes = Object.keys(changes)
    const unknownAttributes = without(changesAttributes, ...knownAttributes)
    if (unknownAttributes.length) {
      const attrs = unknownAttributes.join()
      const payload = { attrs, type: button.type }
      const errorId = 'button-update-unknown-attrs'
      errorTrackingService.trackWarningOnce('updateButton: unknown attrs', {
        extra: payload,
        fingerprint: errorId,
      })
      console.warn(`updateButton: "${attrs}" isn't listed in ButtonConfig defaults`)
    }
    return dispatch({
      type: ActionTypes.UPDATE_BUTTON,
      builderId,
      buttonId,
      changes,
    })
  }
}

export function createSmartLink(builderId, blockId, type, initial = {}) {
  return (dispatch, getState) => {
    const state = getState()

    if (isWaCtaButtonActive(state, builderId, blockId)) {
      const error = l.translate(
        'It’s not possible to add inline link because you already added Open Website button. Remove Open Website button from the message first.',
      )
      alertWithHotjarEvent(error, 'danger')
      return
    }

    const initialButtonData = {
      type: type === NextStepType.OPEN_IN_MESSENGER ? ButtonType.CONTENT : type,
      caption: initial.url || 'Link',
      isSmartLink: true,
      ...initial,
    }

    const enabledIGCrossChannelButton = accountFlags.isEnabled(
      AccountFlag.ENABLE_IG_CROSS_CHANNEL_BUTTON,
    )

    if (enabledIGCrossChannelButton && [NextStepType.OPEN_IN_INSTAGRAM].includes(type)) {
      initialButtonData.type = ButtonType.CROSS_CHANNEL_CONTENT
      initialButtonData.channel = ChannelType.INSTAGRAM
    }

    return dispatch(createLinkedButton(builderId, blockId, initialButtonData))
  }
}

export const removeSmartLinkFromText = (builderId, buttonId) => {
  return (dispatch, getState) => {
    const state = getState()

    const button = entitySelectors.getButtonById(state, builderId, buttonId)
    if (!button) {
      return
    }

    const block = entitySelectors.getParentBlock(state, builderId, buttonId)
    const isTextBlock = block?.type === BlockType.TEXT
    const isEmailTextBlock = block?.type === BlockType.EMAIL_TEXT
    if (!(isTextBlock || isEmailTextBlock)) {
      return
    }

    let { text: textWithoutLink } = block

    textWithoutLink = textWithoutLink.replace(new RegExp(INLINE_BUTTON_REGEX), (smartLinkStr) =>
      parseSmartLinkString(smartLinkStr) === button.id ? '' : smartLinkStr,
    )

    if (block.text !== textWithoutLink) {
      dispatch(updateBlock(builderId, block.id, { text: textWithoutLink }))
    }
  }
}

export const cloneSmartLink = (builderId, buttonId, linkToBlockId = '') => {
  return (dispatch, getState) => {
    const original = entitySelectors.getButtonById(getState(), builderId, buttonId)
    if (!original) {
      return null
    }

    const initial = omit(cloneDeep(original), ['id', 'targetId', 'stats'])

    const blockId =
      linkToBlockId || entitySelectors.getParentBlock(getState(), builderId, buttonId)?.id

    const item = dispatch(createButton(builderId, initial, blockId))

    return item
  }
}

import * as atypes from 'common/actions/integrations/common/constants/IntegrationsReduxActionTypes'
import GoogleSheetIntegration from 'common/actions/integrations/GoogleSheets/model'

const { isHeadersColumnValid, errorTypes } = GoogleSheetIntegration

const initial = {
  fetching: false,
  list: null,
  fetched: false,
}

const initialState = {
  spreadsheets: {
    ...initial,
  },
  sheets: {
    ...initial,
  },
  columnHeaders: {
    ...initial,
    error: null,
  },
  binding: [],
  mapping: [],
  spreadsheet: null,
  sheet: null,
  link: null,
  getByRowAction: {
    nameKey: null,
    value: null,
  },
}

export default function integrationsReducer(state = initialState, action) {
  switch (action.type) {
    case atypes.INTEGRATION_RESET: {
      return {
        ...state,
        ...initialState,
      }
    }
    case atypes.GOOGLE_SHEETS_SPREAD_SHEETS_FETCH_REQUEST: {
      return {
        ...state,
        spreadsheets: {
          ...initial,
          fetching: true,
          fetched: state.spreadsheets.fetched,
        },
        sheets: {
          ...initial,
          fetched: state.sheets.fetched,
        },
        columnHeaders: {
          ...initial,
          fetched: state.columnHeaders.fetched,
        },
      }
    }
    case atypes.GOOGLE_SHEETS_SPREAD_SHEETS_FETCH_RESPONSE: {
      const { data } = action
      return {
        ...state,
        spreadsheets: {
          ...state.spreadsheets,
          list: data.spreadsheets,
          fetching: false,
          fetched: true,
        },
      }
    }
    case atypes.GOOGLE_SHEETS_SPREAD_SHEETS_FETCH_ERROR: {
      return {
        ...state,
        spreadsheets: { ...initial, fetched: true },
        sheets: { ...initial },
        columnHeaders: { ...initial },
      }
    }

    case atypes.GOOGLE_SHEETS_WORK_SHEETS_FETCH_REQUEST: {
      return {
        ...state,
        sheets: { ...initial, fetching: true },
        columnHeaders: {
          ...initial,
          fetched: state.columnHeaders.fetched,
        },
      }
    }
    case atypes.GOOGLE_SHEETS_WORK_SHEETS_FETCH_RESPONSE: {
      const { data } = action
      return {
        ...state,
        sheets: { ...state.sheets, list: data.sheets, fetching: false, fetched: true },
      }
    }
    case atypes.GOOGLE_SHEETS_WORK_SHEETS_FETCH_ERROR: {
      return {
        ...state,
        sheets: { ...initial, fetched: true },
        columnHeaders: { ...initial, fetched: state.columnHeaders.fetched },
      }
    }

    case atypes.GOOGLE_SHEETS_COLUMN_HEADERS_FETCH_REQUEST: {
      return {
        ...state,
        columnHeaders: { ...initial, fetching: true, fetched: state.columnHeaders.fetched },
      }
    }
    case atypes.GOOGLE_SHEETS_COLUMN_HEADERS_FETCH_RESPONSE: {
      const {
        data: { column_headers },
      } = action
      const hasColumnErrors = isHeadersColumnValid(column_headers)
      const hasColumnHeaders = column_headers.length > 0
      const error = hasColumnHeaders ? (hasColumnErrors ? errorTypes.GAPS : null) : errorTypes.EMPTY

      return {
        ...state,
        columnHeaders: { list: column_headers, fetching: false, error, fetched: true },
      }
    }
    case atypes.GOOGLE_SHEETS_COLUMN_HEADERS_FETCH_ERROR: {
      return { ...state, columnHeaders: { ...initial, fetched: true } }
    }

    case atypes.GOOGLE_SHEETS_SET_CURRENT_SHEET: {
      const { spreadsheet } = action
      return {
        ...state,
        spreadsheet,
        sheet: null,
      }
    }

    case atypes.GOOGLE_SHEETS_SET_LINK: {
      const { link } = action
      return {
        ...state,
        link,
      }
    }

    case atypes.GOOGLE_SHEETS_SET_CURRENT_WORK_SHEET: {
      const { sheet } = action
      return { ...state, sheet }
    }

    case atypes.GOOGLE_SHEETS_SET_BINDING: {
      return { ...state, binding: action.binding }
    }

    case atypes.GOOGLE_SHEETS_SET_MAPPING: {
      return { ...state, mapping: action.mapping }
    }

    case atypes.GOOGLE_SHEETS_DELETE_BINDING: {
      const binding = { ...state.binding }

      delete binding[action.index.key]

      return { ...state, binding }
    }

    case atypes.GOOGLE_SHEETS_UPDATE_BINDING: {
      const {
        payload: { index, changes },
      } = action

      return {
        ...state,
        binding: {
          ...state.binding,
          [index.key]: {
            ...state.binding[index.key],
            ...changes,
          },
        },
      }
    }

    case atypes.GOOGLE_SHEETS_DELETE_MAPPING: {
      const mapping = { ...state.mapping }

      delete mapping[action.index.key]

      return { ...state, mapping }
    }

    case atypes.GOOGLE_SHEETS_UPDATE_MAPPING: {
      const {
        payload: { index, changes },
      } = action

      return {
        ...state,
        mapping: {
          ...state.mapping,
          [index.key]: {
            ...state.mapping[index.key],
            ...changes,
          },
        },
      }
    }

    case atypes.GOOGLE_SHEETS_SET_COLUMN: {
      const { nameKey } = action
      return {
        ...state,
        getByRowAction: {
          ...state.getByRowAction,
          nameKey,
        },
      }
    }

    case atypes.GOOGLE_SHEETS_SET_LOOKUP_VALUE: {
      const { payload } = action
      return {
        ...state,
        getByRowAction: {
          ...state.getByRowAction,
          value: payload,
        },
      }
    }

    case atypes.INTEGRATION_DISCONNECT_REQUEST: {
      return { ...state, isDisconnecting: true }
    }
    case atypes.INTEGRATION_DISCONNECT_RESPONSE: {
      return { ...state, isDisconnecting: false }
    }
  }

  return state
}

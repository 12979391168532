export const FlowPlayerRoutes = [
  {
    path: 'flowPlayerPage',
    lazy: () => import('./FlowPlayer.page'),
  },
  {
    path: 'flowPlayerEmbed',
    lazy: () => import('./FlowPlayer.page'),
  },
] as const

import React, { useEffect, useState, useCallback } from 'react'
import cx from 'classnames'
import { useAppDispatch, useAppSelector } from 'reduxTyped'
import { Dropdown, l, tints } from '@manychat/manyui'

import * as appSelectors from 'common/core/selectors/appSelectors'
import { fetchPricingLimitInfo } from 'common/settings/actions'
import If from 'components/If'

import CircleProgress from './CircularProgress'

import cm from './ContactsLimit.module.css'

interface ContactsLimitProps {
  isSidebarHidden: boolean
  fbPagesOpen: boolean
  isRightTimeAnimate: boolean
}

const ContactsLimit = ({
  isSidebarHidden,
  fbPagesOpen,
  isRightTimeAnimate,
}: ContactsLimitProps) => {
  const [isFolded, setFolded] = useState(isSidebarHidden && !fbPagesOpen)
  const [isUnfolded, setUnfolded] = useState(!isFolded && isRightTimeAnimate)
  const updateFolded = useCallback(() => {
    const state = isSidebarHidden && !fbPagesOpen
    setFolded(state)

    if (!(fbPagesOpen && isUnfolded)) {
      setUnfolded(!state && isRightTimeAnimate)
    }
  }, [isSidebarHidden, fbPagesOpen, isRightTimeAnimate, isUnfolded])

  useEffect(() => {
    updateFolded()
  }, [isSidebarHidden, isRightTimeAnimate, updateFolded])

  useEffect(() => {
    // Emulate isRightTimeAnimate from sidebar
    if (fbPagesOpen) {
      updateFolded()
      setTimeout(() => {
        setUnfolded(true)
      }, 300)
    }
  }, [fbPagesOpen, updateFolded])

  const {
    subscribers_total: subscribersTotal,
    subscribers_limit: subscribersLimit,
    MIN_LIMIT_TO_BLOCK,
  } = useAppSelector(appSelectors.getPricingLimitInfo)

  let activeColor: string
  const warningLimit = subscribersLimit - MIN_LIMIT_TO_BLOCK
  if (subscribersTotal < warningLimit) {
    activeColor = tints.green[3]
  } else if (subscribersTotal <= subscribersLimit) {
    activeColor = tints.yellow[3]
  } else {
    activeColor = tints.red[3]
  }

  // Render minimal visible progress
  const total = subscribersTotal > 0 ? Math.max(subscribersTotal, subscribersLimit / 20) : 0

  const progress = (total / subscribersLimit) * 100

  const dispatch = useAppDispatch()
  useEffect(() => {
    dispatch(fetchPricingLimitInfo())
  }, [dispatch])

  const renderDescription = (isFolded: boolean) => (
    <div className={cx(cm.description, { 'p-a-xs': isFolded })}>
      <div className={cm.title}>{l.translate('Free contacts limit')}</div>
      <div className="d-flex align-center">
        {subscribersTotal}/{subscribersLimit}{' '}
      </div>
    </div>
  )

  return (
    <div className="d-flex p-y">
      <Dropdown
        openOnHover
        disabled={!isFolded}
        popoverProps={{ arrow: true, at: 'right' }}
        closeTimeout={30}
        renderAnchor={() => (
          <div className={cm.progress}>
            <CircleProgress
              size={30}
              progress={progress}
              strokeWidth={5}
              activeColor={activeColor}
            />
          </div>
        )}
      >
        {renderDescription(isFolded)}
      </Dropdown>
      <If condition={isUnfolded}>{renderDescription(isFolded)}</If>
    </div>
  )
}

export default ContactsLimit

import React from 'react'
import cx from 'classnames'
import { z } from 'zod'

import { BlockType } from '../../constants'
import { registry } from '../../registry'
import { ChildrenSchema, ClassNameSchema, StylesSchema } from '../../schemas'
import { BlockProps } from '../../types'
import { Slot } from '../Slot'

import cm from './Row.module.css'

const RowPropsSchema = z.object({
  className: ClassNameSchema,
  children: ChildrenSchema,
  gap: z.string().or(z.number()).optional(),
  style: StylesSchema,
  mobileWrap: z.boolean().optional(),
})

const Component = ({
  className,
  children,
  gap = '1rem',
  style,
  mobileWrap = false,
  blockId,
}: BlockProps<typeof RowPropsSchema>) => (
  <div
    className={cx(cm.row, mobileWrap && cm.mobileWrap, className)}
    style={{ gap, ...style }}
    data-block={blockId}
  >
    {children}
  </div>
)

export const Row = registry.register(Component, {
  props: RowPropsSchema,
  type: BlockType.ROW,
  previewProps: {
    children: [],
  },
  preview: () => (
    <Row className="panel panel-info" style={{ maxWidth: 'fit-content' }}>
      <Slot style={{ width: 50, height: 50 }} />
      <Slot style={{ width: 50, height: 50 }} />
    </Row>
  ),
})

export enum TriggerType {
  WIDGET = 'widget',
  KEYWORD = 'keyword',
  AI_INTENT = 'ai_intent',
  META_KEYWORD = 'meta_keyword',
  RULE = 'rule',
  EXTERNAL_TRIGGER = 'externalTrigger',
  ANOTHER_FLOW = 'anotherFlow',
  DEFAULT_REPLY = 'defaultReply',
  WELCOME_MESSAGE = 'welcomeMessage',
  STORY_MENTION_REPLY = 'storyMentionReply',
  ICE_BREAKER = 'ice_breaker',
  MAIN_MENU = 'main_menu',
  CART_REPLY = 'cart_reply',
}

import assert from 'assert'

import get from 'lodash/get'

import buttonRegistry from 'common/builder/buttonRegistry'
import { CREATE_BUTTON } from 'common/builder/constants/builderReduxActionTypes'
import { ButtonType } from 'common/builder/constants/ButtonType'
import { getButtonById } from 'common/builder/selectors/builder/entitySelectors'

/**
 * @param builderId
 * @param initial
 * @param blockId
 */
export const createButton = (builderId, initial, blockId) => {
  return (dispatch, getState) => {
    const state = getState()

    assert(builderId, `createButton: builderId is required param`)

    if (get(initial, 'id')) {
      const item = getButtonById(state, builderId, initial.id)
      assert(!item, `createButton: button with id "${initial.id}" already exists`)
    }

    initial.type ??= ButtonType.CONTENT
    const item = buttonRegistry.get(initial).create(initial)

    dispatch({
      type: CREATE_BUTTON,
      builderId,
      blockId,
      item,
    })
    return item
  }
}

import React from 'react'
import cx from 'classnames'

import cm from './ListItem.module.css'
export const ListDivider = () => {
  return (
    <li
      role="separator"
      aria-orientation="horizontal"
      className={cx(cm.listItem, cm.dividerContainer)}
    >
      <div className={cm.divider} />
    </li>
  )
}

ListDivider.displayName = 'List.Divider'

import { IInstagramLiveStoryTriggerWidget } from 'apps/growthTools/growthToolsInterfaces'
import { BaseWidget } from 'apps/growthTools/models/Widget/baseWidget'
import { WidgetTypes } from 'apps/growthTools/models/Widget/constants'
import { ConditionTypes } from 'apps/keywords/models/KeywordRule/constants'
import { TriggerType } from 'common/flow/constants/TriggerType'
import { ITriggerId } from 'common/flow/flowTriggersInterfaces'
import { ProtoLiveComment } from 'shared/api/requests/aiAssistant/schemas'

export const toInstagramLiveCommentReplyWidget = (
  trigger: ProtoLiveComment,
): IInstagramLiveStoryTriggerWidget => {
  const id = 2
  const triggerId: ITriggerId = `${TriggerType.WIDGET}-${id}`

  return {
    ...BaseWidget,
    widget_id: id,
    widget_type: WidgetTypes.INSTAGRAM_LIVE_COMMENT_REPLY,
    channel: trigger.channel,
    data: {
      story_id: '',
      keywords_condition: ConditionTypes.ANY,
      keywords: [],
    },
    id,
    triggerType: TriggerType.WIDGET,
    triggerId: triggerId,
    caption: trigger.caption || '',
  }
}

import React, { useCallback, useMemo, useState } from 'react'
import cx from 'classnames'
import { z } from 'zod'
import { BtnV2, LinkBtn, l } from '@manychat/manyui'

import { BlockType, getAITermsText } from '../../constants'
import { useBillingInfo, useCart } from '../../hooks'
import { registry } from '../../registry'
import { isProductAddOn, isProductPlan } from '../../schemas'
import { BlockProps } from '../../types'

import cm from './OrderSummarySmall.module.css'

const OrderSummarySmallProps = z.object({})

export const Component = ({ blockId }: BlockProps<typeof OrderSummarySmallProps>) => {
  const { upgrade, cart, totalPrice, showCouponInput } = useCart()
  const { currentMonthlyPrice, isTrial, currency } = useBillingInfo()
  const [loading, setLoading] = useState(false)
  const handleClick = useCallback(() => {
    setLoading(true)
    upgrade().catch(() => {
      setLoading(false)
    })
  }, [setLoading, upgrade])

  const total = l.currency(currentMonthlyPrice + totalPrice, currency)
  const planSlugs = useMemo(() => cart.filter(isProductPlan).map((product) => product.slug), [cart])
  const addOnSlugs = useMemo(() => cart.filter(isProductAddOn).map((addOn) => addOn.slug), [cart])

  return (
    <div data-block={blockId}>
      <div className={cx('d-flex flex-row flex-wrap justify-between p-x-md p-y', cm.footerContent)}>
        <div className={cx('d-flex flex-col', cm.checkout)}>
          <p className="m-0">
            {l.translate('Current subscription')}: {l.currency(currentMonthlyPrice, currency)}
          </p>

          {cart.map((product) => (
            <p key={`${product.type}-${product.slug}`} className="m-0">
              + {product.name}: {l.currency(product.price, currency)}
            </p>
          ))}

          <p className="text-subheading m-0">
            {l.translate('Total')}: {total}
          </p>

          <div>
            <LinkBtn
              onClick={() =>
                showCouponInput({
                  products: planSlugs,
                  addOns: addOnSlugs,
                })
              }
            >
              Apply Coupon
            </LinkBtn>
          </div>
        </div>

        <div className={cm.ctaContainer}>
          <BtnV2 variant="primary" onClick={handleClick} fullWidth loading={loading} size="large">
            {l.translate('Upgrade Now')}
          </BtnV2>

          {isTrial ? (
            <p className="text-secondary text-sm m-t-sm">
              {l.translate(
                'Your trial will end, and your card will be charged <b>{total}</b> immediately',
                { total: total },
              )}
            </p>
          ) : null}
        </div>
      </div>

      <p className={cx('text-sm m-t-sm', cm.tos)}>{getAITermsText()}</p>
    </div>
  )
}

export const OrderSummarySmall = registry.register(Component, {
  props: OrderSummarySmallProps,
  type: BlockType.ORDER_SUMMARY_SMALL,
})

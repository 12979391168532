import dot from 'dot-prop-immutable'

import { SmsState, FetchStatus } from 'apps/sms/smsInterfaces'
import * as atypes from 'apps/sms/smsReduxActionTypes'
import { IActionWithPayload } from 'common/core/interfaces/actions'

export const initialState: SmsState = {
  fetchStatus: FetchStatus.NOT_FETCHED,
  phones: [],
  isNewConnectModalOpen: false,
}

export default (state: SmsState = initialState, action: IActionWithPayload): SmsState => {
  switch (action.type) {
    case atypes.SMS_PHONES_FETCH_RESPONSE: {
      const { phones } = action.data
      return dot.set(state, 'phones', phones)
    }

    case atypes.SMS_FETCH_DATA_REQUEST: {
      return { ...state, fetchStatus: FetchStatus.FETCHING }
    }
    case atypes.SMS_FETCH_DATA_RESPONSE: {
      const { phones } = action.data

      return {
        ...state,
        phones,
        fetchStatus: FetchStatus.FETCHED,
      }
    }

    case atypes.SMS_TWILIO_CONNECT_RESPONSE: {
      const isEnabled =
        typeof action.data.channel_enabled === 'boolean' ? action.data.channel_enabled : true

      return dot.set(
        dot.set(state, 'currentAccount.sms_channel.sms_has_phones', true),
        'currentAccount.sms_channel.sms_channel_connected',
        isEnabled,
      )
    }

    case atypes.SMS_TWILIO_DISCONNECT_RESPONSE: {
      return dot.set(
        dot.set(state, 'currentAccount.sms_channel.sms_has_phones', false),
        'currentAccount.sms_channel.sms_channel_connected',
        false,
      )
    }

    case atypes.SMS_OPEN_MODAL_CONNECT: {
      return { ...state, isNewConnectModalOpen: true }
    }
    case atypes.SMS_CLOSE_MODAL_CONNECT: {
      return { ...state, isNewConnectModalOpen: false }
    }
  }

  return state
}

import React from 'react'
import { l } from '@manychat/manyui'

import CheckedIcon from 'apps/sms/components/connectionModal/CheckedIcon'

const Benefits = () => {
  const BENEFITS = [
    {
      title: l.translate('Receive important updates'),
      text: l.translate(
        'Stay up-to-date with the latest product news and best marketing strategies to leverage the power of your Manychat account.',
      ),
    },
    {
      title: l.translate('Never miss account activity alerts'),
      text: l.translate('Get important notifications and alerts that you affect your account.'),
    },
    {
      title: l.translate('Access new and powerful templates'),
      text: l.translate(
        'Use your Manychat account to the maximum with free templates to build profitable campaigns.',
      ),
    },
    {
      title: l.translate('Keep learning'),
      text: l.translate(
        'Receive free marketing content and expert advice that will work for your business.',
      ),
    },
  ]
  return (
    <ul className="p-x-0 m-0 p-b-md">
      {BENEFITS.map(({ text, title }) => {
        return (
          <li className="d-flex flex-row m-t-md" key={title}>
            <CheckedIcon size={28} fill />
            <div className="d-flex flex-col m-l">
              <div className="text-subheading">{title}</div>
              <div>{text}</div>
            </div>
          </li>
        )
      })}
    </ul>
  )
}

export default Benefits

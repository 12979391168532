import { l } from '@manychat/manyui'

import { UpgradeSource } from 'common/billing'
import { handleGoProError } from 'common/billing/helpers/handleGoProError'
import { alert } from 'common/core'
import { IAsyncThunkAction, IThunkAction } from 'common/core/interfaces/actions'
import * as API from 'constants/API'
import { isRequestError } from 'utils/api/mcApi/RequestError'
import errorTrackingService from 'utils/services/errorTrackingService'

import * as actionTypes from '../filterReduxActionTypes'
import { FilterScenario } from '../interfaces'

let isFieldsFetched = false

export const fetchFilterFields = (
  options: { allowUserId?: boolean; force?: boolean; scenario?: FilterScenario } = {},
): IAsyncThunkAction => {
  const allowUserId = Boolean(options?.allowUserId)
  const force = options?.force ?? true

  return async (dispatch, getState) => {
    if (!force && isFieldsFetched) {
      return null
    }

    const state = getState()
    let url = API.fetchFilterFields.replace(':allow_user_id', `allow_user_id=${allowUserId}`)

    if (options.scenario) {
      url += `&scenario=${options.scenario}`
    }

    if (!state.filter.fields.fetching) {
      isFieldsFetched = true
      return dispatch({
        type: actionTypes.FILTER_FIELDS_FETCH,
        $fetch: url,
      })
    }

    return null
  }
}

export const fetchFieldValues =
  (fieldName: string, query: string): IThunkAction =>
  async (dispatch) => {
    try {
      await dispatch({
        type: actionTypes.FILTER_FIELD_VALUES_FETCH,
        $fetch: API.fetchFieldValues
          .replace(':field', `field=${fieldName}`)
          .replace(':q', query ? `query=${query}` : ''),
        fieldName,
        query,
        $error: (err: string | UnsafeAnyObject) => {
          if (typeof err !== 'string' && err.go_pro) {
            dispatch(
              handleGoProError(err, {
                products: err.products,
                source: UpgradeSource.SMART_SEGMENTS,
                message: l.translate(
                  "You aren't able to activate Contact Segments on the current plan. Upgrade your plan to create and view Segments.",
                ),
                modalTitle: l.translate(
                  'Unlock the ability to create Contact Segments with Manychat Pro Subscription',
                ),
              }),
            )
          } else {
            alert(`${err}`, 'danger')
          }
        },
      })
    } catch (error) {
      if (isRequestError(error) && error.response?.go_pro) {
        return
      }

      errorTrackingService.trackError(error)
    }
  }

import * as atypes from 'common/settings/SettingsReduxActionTypes'
import { makeLimitedListReducer } from 'utils/factory'

const listReducer = makeLimitedListReducer('chatAdmins', {
  storePath: 'chat.admins',
  initialState: {
    items: [],
    fetching: false,
    error: null,
    ready: false,
  },
})

export default function adminsReducer(state, action) {
  state = listReducer(state, action)

  switch (action.type) {
    case atypes.BOT_SETTINGS_FETCH_REQUEST: {
      return { ...state, fetching: true, error: null }
    }
    case atypes.BOT_SETTINGS_FETCH_RESPONSE: {
      const { data } = action
      return { ...state, items: data.settings.admins, fetching: false, error: null, ready: true }
    }
    case atypes.BOT_SETTINGS_FETCH_ERROR: {
      return { ...state, items: null, fetching: false, ready: false }
    }
  }

  return state
}

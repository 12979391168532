import { createSlice } from '@reduxjs/toolkit'

import { fetchSMSStats } from 'common/settings/actions/statsActions'
import { SmsBillingStats } from 'shared/api/requests/settings/stats/schemas'

export const initialState: Readonly<SmsBillingStats> = {
  sms_send_count: 0,
  sms_receive_count: 0,
  mms_send_count: 0,
  mms_receive_count: 0,
}

export const smsStatsSlice = createSlice({
  name: 'smsStats',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchSMSStats.fulfilled, (state, action) => ({
      ...state,
      ...action.payload.stats,
    }))
  },
})

import { IURLData } from 'components/MCTextEditor/types'
import { INDEX_CHAR } from 'components/RichTextEditor/constants'
import { ISlice } from 'components/RichTextEditor/processor/constants'
import { ExportFormat } from 'components/RichTextEditor/types'

const PREFIX = '<<url:'
const POSTFIX = '>>'

export const URLPattern = '<<url:[^\n<>]{1,}>>'

export function parseURL(input: string): [string, string] | null {
  if (!input.startsWith(PREFIX) || !input.endsWith(POSTFIX)) {
    return null
  }

  input = input.slice(PREFIX.length, -POSTFIX.length)
  const splitIndex = input.indexOf('|')
  const id = input.slice(0, splitIndex)
  const url = input.slice(splitIndex + 1)

  return [id, url]
}

export function exportURL(content: string, slice: ISlice, format?: string) {
  const { data } = slice
  content = content.replace(new RegExp(`[\n${INDEX_CHAR}]`, 'g'), '')
  if (!content) {
    return ''
  }
  if (format === ExportFormat.Clipboard) {
    return content
  }
  return `<<url:${(data as IURLData).id}|${content}>>`
}

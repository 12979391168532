import { WidgetTypeNumeric, WidgetTypes } from 'apps/growthTools/models/Widget/constants'
import { ChannelType } from 'common/core/constants/ChannelType'
import { FsTriggerModel } from 'shared/api/requests/cms/schemas'

import { isWidget, isIntent, triggerType } from './triggerType'
import { FiltersFromQuery } from './types'

export const isIgChannel = (channel?: ChannelType | null) => channel === ChannelType.INSTAGRAM

export const isIgKeyword = (query: FiltersFromQuery) =>
  isIgChannel(query.channel) && triggerType(query.trigger_model) && !query.widget_type

export const isIgIntent = (query: FiltersFromQuery) =>
  isIgChannel(query.channel) && isIntent(query.trigger_model) && !query.widget_type

export const isIgRefUrl = (query: FiltersFromQuery) =>
  isIgChannel(query.channel) &&
  isWidget(query.trigger_model) &&
  query.widget_type === WidgetTypeNumeric.MESSENGER_REF_URL

export const isIgCgt = (query: FiltersFromQuery) =>
  isIgChannel(query.channel) &&
  isWidget(query.trigger_model) &&
  query.widget_type === WidgetTypeNumeric.FEED_COMMENT_TRIGGER

export const isIgStoryReply = (query: FiltersFromQuery) =>
  isIgChannel(query.channel) &&
  isWidget(query.trigger_model) &&
  query.widget_type === WidgetTypeNumeric.INSTAGRAM_STORY_REPLY

export const isIgLiveComments = (query: FiltersFromQuery) =>
  isIgChannel(query.channel) &&
  isWidget(query.trigger_model) &&
  query.widget_type === WidgetTypeNumeric.INSTAGRAM_LIVE_COMMENT_REPLY

export const isAllIgTriggers = (query: FiltersFromQuery) =>
  isIgChannel(query.channel) && !query.trigger_model && !query.widget_type

export const isIgAds = (query: FiltersFromQuery) =>
  isIgChannel(query.channel) &&
  isWidget(query.trigger_model) &&
  query.widget_type === WidgetTypeNumeric.ADS

export const isTriggerMatchesIgFilter = ({
  widgetType,
  filter,
  triggerModel,
  triggerChannel,
}: {
  widgetType: WidgetTypes | null
  filter: FiltersFromQuery
  triggerModel: FsTriggerModel
  triggerChannel: ChannelType | null
}) =>
  triggerChannel === ChannelType.INSTAGRAM &&
  (isAllIgTriggers(filter) ||
    (widgetType === WidgetTypes.ADS && isIgAds(filter)) ||
    (triggerModel === FsTriggerModel.KEYWORD && isIgKeyword(filter)) ||
    (triggerModel === FsTriggerModel.INTENT && isIgIntent(filter)) ||
    (widgetType === WidgetTypes.MESSENGER_REF_URL && isIgRefUrl(filter)) ||
    (widgetType === WidgetTypes.FEED_COMMENT_TRIGGER && isIgCgt(filter)) ||
    (widgetType === WidgetTypes.INSTAGRAM_STORY_REPLY && isIgStoryReply(filter)) ||
    (widgetType === WidgetTypes.INSTAGRAM_LIVE_COMMENT_REPLY && isIgLiveComments(filter)))

import React, { useEffect, useRef } from 'react'
import cx from 'classnames'
import { useAppDispatch, useAppSelector } from 'reduxTyped'
import { Toast, MODAL_IGNORE_ATTRIBUTE } from '@manychat/manyui'

import * as uiActions from 'common/core/actions/uiActions'
import { withErrorBoundary } from 'common/core/components/ErrorBoundary'
import { getIsSidebarHidden } from 'common/core/selectors/uiSelectors'

import cm from './Toasts.module.css'

const ToastWithErrorBoundary = withErrorBoundary(Toast, {
  scope: 'layout',
  section: 'toast',
  fallbackComponent: () => null,
})

const Toasts = () => {
  const toasts = useAppSelector((state) => state.ui.notifications)
  const dispatch = useAppDispatch()
  const containerRef = useRef<HTMLDivElement>(null)
  const isSidebarHidden = useAppSelector(getIsSidebarHidden)

  const handleRemove = (id: string) => {
    dispatch(uiActions.removeNotification(id))
  }

  useEffect(() => {
    const container = containerRef.current
    if (container) {
      const firstHeight = container.getBoundingClientRect().height

      /*Observer to detect changes in DOM*/
      const observer = new MutationObserver(() => {
        const lastHeight = container.getBoundingClientRect().height
        const invert = lastHeight - firstHeight

        if (invert !== 0 && lastHeight > firstHeight) {
          container.animate(
            [{ transform: `translateY(${invert}px)` }, { transform: 'translateY(0)' }],
            {
              duration: 150,
              easing: 'ease',
            },
          )
        }
      })

      observer.observe(container, { childList: true })

      return () => {
        observer.disconnect()
      }
    }

    /*Return undefined if container is null*/
    return undefined
  }, [toasts])

  return (
    <div
      id="mc-notification-controller"
      className={cx(cm.wrap, isSidebarHidden && cm.sidebarHidden)}
      ref={containerRef}
      {...{ [MODAL_IGNORE_ATTRIBUTE]: true }}
    >
      {toasts.map((item) => (
        <ToastWithErrorBoundary
          title={item.text as React.ReactNode}
          description={item.source}
          variant={item.type}
          onClose={handleRemove}
          id={item.id}
          key={item.id}
          icon={item.icon}
          isTemporary={item.isTemporary}
          content={item.content}
        />
      ))}
    </div>
  )
}

export default Toasts

import React from 'react'
import cx from 'classnames'

import { CircleAvatar } from 'components/CircleAvatar'
import { PhonePreviewTheme } from 'components/PhonePreviewKit'
import { useThemeContext } from 'components/PhonePreviewKit/useThemeContext'

import emptyAvatarSvg from '../../svg/emptyAvatar.svg'

import cm from './ChatUserInfo.module.css'

export interface ChatUserInfoProps {
  avatarUrl?: string
  title?: string
  subtitle?: string
}

export function ChatUserInfo({ avatarUrl, title, subtitle }: ChatUserInfoProps) {
  const theme = useThemeContext()

  return (
    <div
      data-test-id="chat-user-info"
      className={cx(cm.userInfo, {
        [cm[PhonePreviewTheme.FACEBOOK]]: theme === PhonePreviewTheme.FACEBOOK,
      })}
    >
      {avatarUrl ? (
        <CircleAvatar data-test-id="circle-avatar" url={avatarUrl} size={64} />
      ) : (
        <img data-test-id="avatar" className={cm.avatar} src={emptyAvatarSvg} alt="avatar" />
      )}
      <div className={cm.userDetails}>
        <div className={cm.title}>{title}</div>
        <div className={cm.subtitle}>{subtitle}</div>
      </div>
    </div>
  )
}

ChatUserInfo.displayName = 'Chat.UserInfo'

import { BUILDER_UPDATE_COORDINATES } from 'common/builder/constants/builderReduxActionTypes'

export const updateCoordinates = (builderId, coordinates = {}) => {
  for (let item of Object.values(coordinates)) {
    item.x = Math.floor(item.x || 0)
    item.y = Math.floor(item.y || 0)
  }

  return {
    type: BUILDER_UPDATE_COORDINATES,
    builderId,
    coordinates,
  }
}

import dotProp from 'dot-prop-immutable'
import { Action } from 'redux'

import actionTypes from 'apps/applicationInstallation/constants/ReduxActionTypes'
import { IApplicationInstallation } from 'common/actions/integrations/Applications/interfaces'
import { IActionWithPayload } from 'common/core/interfaces/actions'

const initialState: {
  application: IApplicationInstallation | null
  isFetching: boolean
  applicationIdOrHash: string
} = {
  application: null,
  isFetching: false,
  applicationIdOrHash: '',
}

const applicationReducer = (
  state = initialState,
  action: Action | (IActionWithPayload & { hash: string }),
) => {
  switch (action.type) {
    case actionTypes.APPLICATION_FETCH_REQUEST:
      if ('hash' in action) {
        state = dotProp.set(state, 'isFetching', true)
        state = dotProp.set(state, 'application', null)
        state = dotProp.set(state, 'applicationIdOrHash', action.hash)
      }
      break
    case actionTypes.APPLICATION_FETCH_RESPONSE: {
      if (!('data' in action)) {
        break
      }

      const application = action.data.app
      application.hasSettings = action.data.hasSettings

      state = dotProp.set(state, 'isFetching', false)
      state = dotProp.set(state, 'application', application)
      break
    }
  }

  return state
}

export default applicationReducer

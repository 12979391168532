import get from 'lodash/get'
import isArray from 'lodash/isArray'
import { l } from '@manychat/manyui'

import { WidgetTypes } from './constants'

export function isOldFeedComment(item) {
  const isOldComment =
    !get(item, 'data.actions.opt_in_status') ||
    get(item, 'data.actions.opt_in_status') === 'disabled'
  const isFeed = item.widget_type === WidgetTypes.FEED_COMMENT
  return isFeed && isOldComment
}

export function getStats(item, domains) {
  const stats = item.stats

  const conversionAvailable = Number.isFinite(stats.$conversion)
  const openedAvailable = Number.isFinite(stats.$opened)
  const clickedAvailable = Number.isFinite(stats.$clicked)

  const oldFeedComment = isOldFeedComment(item)
  const adsTrigger =
    item.widget_type === WidgetTypes.ADS_JSON || item.widget_type === WidgetTypes.ADS
  const messengerCode = item.widget_type === WidgetTypes.MESSENGER_CODE
  const refUrl = item.widget_type === WidgetTypes.MESSENGER_REF_URL

  let impressions = stats.impressions
  let optins = stats.optins
  let conversion = conversionAvailable ? l.percent(stats.$conversion) : stats.$conversion
  let opened = openedAvailable ? l.percent(stats.$opened) : stats.$opened
  let clicked = clickedAvailable ? l.percent(stats.$clicked) : stats.$clicked

  const isConversionColorAvailable = conversionAvailable && domains && domains.$conversion
  let conversionColor = isConversionColorAvailable ? domains.$conversion(stats.$conversion) : '#999'

  const isOpenedColorAvailable = openedAvailable && domains && domains.$opened
  let openedColor = isOpenedColorAvailable ? domains.$opened(stats.$opened) : '#999'

  const isClickedColorAvailable = clickedAvailable && domains && domains.$clicked
  let clickedColor = isClickedColorAvailable ? domains.$clicked(stats.$clicked) : '#999'

  if (adsTrigger) {
    impressions = optins = conversion = opened = '–'
    conversionColor = openedColor = clickedColor = ''
    clicked = get(stats, 'optin_message.clicked', 0)
  }

  if (oldFeedComment) {
    optins = conversion = opened = clicked = '–'
    conversionColor = openedColor = clickedColor = ''
  }

  if (messengerCode || refUrl) {
    impressions = conversion = '–'
    conversionColor = ''
  }

  return {
    impressions,
    optins,
    conversion,
    conversionColor,
    opened,
    openedColor,
    clicked,
    clickedColor,
  }
}

export const generateFlowName = function (widget) {
  return `${widget.name} Opt-In Message`
}

export const formatDisplayOption = (option) => {
  if (!option || !isArray(option)) {
    return ''
  }
  const [units, value] = option
  if (units === 'never') {
    return 'Never'
  }
  if (units === 'always') {
    return 'Always'
  }
  if (units === 'hours') {
    return `${value} hours`
  }
  if (units === 'days') {
    return `${value} days`
  }
  return ''
}

export function hasAnyChannelEnabled(widget) {
  const isFacebookEnabled = Boolean(widget.data.channels.enable_facebook)
  const isSMSEnabled = Boolean(widget.data.channels.enable_sms)
  const isEmailEnabled = Boolean(widget.data.channels.enable_email)
  const isWhatsAppEnabled = Boolean(widget.data.channels.enable_whatsapp)
  return isFacebookEnabled || isSMSEnabled || isEmailEnabled || isWhatsAppEnabled
}

export function hasPROChannelEnabled(widget) {
  const isSMSEnabled = Boolean(widget.data.channels.enable_sms)
  const isEmailEnabled = Boolean(widget.data.channels.enable_email)
  const isWhatsAppEnabled = Boolean(widget.data.channels.enable_whatsapp)
  return isSMSEnabled || isEmailEnabled || isWhatsAppEnabled
}

import React, { useMemo, useCallback, useEffect, useState, RefObject, useRef } from 'react'
import cx from 'classnames'
import debounce from 'lodash/debounce'
import { useSearchParams } from 'react-router-dom'
import { v4 as uuid } from 'uuid'

import { ChannelType } from 'common/core/constants/ChannelType'
import {
  getInfoAboutService,
  initializeUsercentricsListeners,
  UsercentricsServicesInfo,
} from 'utils/getInfoAboutService'

import { getResizeObserverEntrySize } from './getResizeObserverEntrySize'

import cm from './common.module.css'

export const useUpdateOnlyEffect = (func: () => void, changes: unknown[]): void => {
  const didMountRef = React.useRef(false)
  React.useEffect(() => {
    if (didMountRef.current) {
      func()
    } else didMountRef.current = true
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [func, ...changes])
}

// https://usehooks.com/usePrevious/
export const usePrevious = <T>(value: T): T | undefined => {
  const ref = React.useRef<T>()

  React.useEffect(() => {
    ref.current = value
  }, [value])

  return ref.current
}

export const usePreviousRef = <T>(value: T): RefObject<T | undefined> => {
  const ref = React.useRef<T | undefined>(undefined)

  React.useEffect(() => {
    ref.current = value
  }, [value])

  return ref
}

export const useIsChanged = (value: unknown): boolean => value !== usePrevious(value)

type Callback<T extends TSFixMe[]> = (...args: T) => void

/**
 * Function for memoizing a callback function.
 * Useful when need make useEffect without function dependency.
 */
export const useStableCallback = <T extends TSFixMe[]>(fn: Callback<T>): Callback<T> => {
  const fnRef = useRef<Callback<T>>(fn)
  fnRef.current = fn

  return useCallback((...args: T) => fnRef.current(...args), [])
}

export const useDebounce = (value: string, delay: number) => {
  const [debouncedValue, setDebouncedValue] = React.useState(value)

  React.useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedValue(value)
    }, delay)

    return () => {
      clearTimeout(handler)
    }
  }, [value, delay])

  return debouncedValue
}

export const useShakeAnim = (additionalClass?: string): [string, () => void] => {
  const [shaking, setShaking] = useState(false)

  const shakeClass = additionalClass
    ? cx({ [cm.inputShake]: shaking }, { [additionalClass]: shaking })
    : cx({ [cm.inputShake]: shaking })

  const shakeIt = useCallback(() => {
    setShaking(true)
    setTimeout(() => setShaking(false), 300)
  }, [])

  return [shakeClass, shakeIt]
}

export const useHighlightAnim = (): [string, () => void, () => void] => {
  const [highlighting, setHighlighting] = useState<'success' | 'none'>('none')
  const highlightClass = cx({ [cm['highlight-success']]: highlighting === 'success' })

  const dropIt = useCallback(() => {
    setHighlighting('none')
  }, [])

  const highlightIt = useCallback(() => {
    dropIt()
    setTimeout(() => setHighlighting('success'))
  }, [dropIt])

  return [highlightClass, highlightIt, dropIt]
}

interface EventHandler<T> {
  (event: T): void | boolean
}

export const useEventListener = <T extends Event = Event>(
  element: HTMLElement | null,
  type: string,
  handler: EventHandler<T>,
  options?: boolean | AddEventListenerOptions,
) => {
  const handlerRef = React.useRef<EventHandler<T>>(handler)

  React.useEffect(() => {
    handlerRef.current = handler
  }, [handler])

  React.useEffect(() => {
    if (!element) {
      return
    }

    const handlerWrapper: EventListener = (event) => {
      const handler = handlerRef.current
      if (handler) {
        return handler(event as T)
      }
    }
    element.addEventListener(type, handlerWrapper, options)
    return () => {
      element.removeEventListener(type, handlerWrapper, options)
    }
  }, [element, type, options])
}

export const useForwardedRef = <T>(
  forwardedRef: React.MutableRefObject<T | null> | React.RefCallback<T> | null,
): [React.MutableRefObject<T | undefined>, (element: T) => void] => {
  const ref = React.useRef<T>()

  const refHandler = (element: T) => {
    ref.current = element
    if (forwardedRef === null) {
      return
    }
    if (typeof forwardedRef === 'function') {
      forwardedRef(element)
    } else {
      forwardedRef.current = element
    }
  }

  return [ref, refHandler]
}

export const useDebouncedHandler = (
  callback: CallableFunction | undefined,
  delay: number,
): CallableFunction => {
  const timerRef = React.useRef<NodeJS.Timeout>()
  const callbackRef = React.useRef<CallableFunction | undefined>(callback)
  callbackRef.current = callback

  React.useEffect(() => {
    return () => {
      timerRef.current && clearTimeout(timerRef.current)
    }
  }, [])

  const handler = (...args: []) => {
    timerRef.current && clearTimeout(timerRef.current)
    timerRef.current = setTimeout(() => {
      if (typeof callbackRef.current === 'function') {
        return callbackRef.current(...args)
      }
    }, delay)
  }

  return handler
}

export const useMediaQuery = (query: string): boolean => {
  const [isMatches, setMatches] = useState(window.matchMedia(query).matches)

  const handleChange = useCallback((e: MediaQueryListEvent) => {
    setMatches(e.matches)
  }, [])

  useEffect(() => {
    const mediaQuery = window.matchMedia(query)
    setMatches(mediaQuery.matches)

    if (mediaQuery.addEventListener) {
      mediaQuery.addEventListener('change', handleChange)
    } else {
      mediaQuery.addListener(handleChange)
    }

    return () => {
      if (mediaQuery.removeEventListener) {
        mediaQuery.removeEventListener('change', handleChange)
      } else {
        mediaQuery.removeListener(handleChange)
      }
    }
  }, [handleChange, query])

  return isMatches
}

export const useDidMountEffect = (callback: React.EffectCallback) => {
  // eslint-disable-next-line
  React.useEffect(callback, [])
}

const isChannelType = (value: string | null): value is ChannelType =>
  value !== null && Object.values(ChannelType).includes(value as ChannelType)

export const useChannelFromQuery = (defaultChannel = ChannelType.FB): ChannelType => {
  const [query] = useSearchParams()
  const queryChannel = query.get('channel')

  return isChannelType(queryChannel) ? queryChannel : defaultChannel
}

export const useOnClickOutside = (
  ref: React.MutableRefObject<Element | null>,
  handler: (event: MouseEvent | TouchEvent) => void,
  options = { useClickEvent: false },
) => {
  useEffect(() => {
    const listener = (event: MouseEvent | TouchEvent) => {
      if (!ref.current || ref.current.contains(event.target as Element)) {
        return
      }
      handler(event)
    }

    const eventName = options.useClickEvent ? 'click' : 'mousedown'

    document.addEventListener(eventName, listener)
    document.addEventListener('touchstart', listener)

    return () => {
      document.removeEventListener(eventName, listener)
      document.removeEventListener('touchstart', listener)
    }
  }, [ref, handler, options.useClickEvent])
}

interface UsercentricsHook {
  usercentricsServicesInfo: UsercentricsServicesInfo
  showFirstLayerBanner: () => void
  showSecondLayerBanner: (id?: string) => void
  updateServicesConsents: (serviceConsents: UC_ServiceConsents) => void
}

export const useUsercentrics = (scriptName: string | string[]): UsercentricsHook => {
  const [usercentricsServicesInfo, setUsercentricsServicesInfo] =
    useState<UsercentricsServicesInfo>(getInfoAboutService(scriptName))

  const handleGetServicesInfo = useCallback(() => {
    const result = getInfoAboutService(scriptName)
    setUsercentricsServicesInfo(result)
  }, [scriptName])

  const showFirstLayerBanner = useCallback(async () => await window?.__ucCmp?.showFirstLayer(), [])

  const showSecondLayerBanner = useCallback(
    async () => await window?.__ucCmp?.showSecondLayer(),
    [],
  )

  const updateServicesConsents = useCallback(async (serviceConsents: UC_ServiceConsents) => {
    await window?.__ucCmp?.updateServicesConsents(serviceConsents)
    await window?.__ucCmp?.saveConsents()
  }, [])

  useDidMountEffect(() => {
    const { unsubscribeListeners } = initializeUsercentricsListeners(handleGetServicesInfo)
    return unsubscribeListeners
  })

  return {
    usercentricsServicesInfo,
    showFirstLayerBanner,
    showSecondLayerBanner,
    updateServicesConsents,
  }
}

export const useWatch = (target: UnsafeAnyObject, keys: string[]) => {
  const [, updateChangeId] = useState(0)

  return useMemo(() => {
    const descriptor = keys.reduce((prevent, current) => {
      const internalKey = `@@__${current}__`

      prevent[internalKey] = {
        enumerable: true,
        configurable: true,
        writable: true,
        value: target[current],
      }

      prevent[current] = {
        enumerable: true,
        configurable: true,
        get() {
          return target[internalKey]
        },
        set(value) {
          if (target[internalKey] !== value) {
            target[internalKey] = value
            updateChangeId((id) => id + 1)
          }
        },
      }
      return prevent
    }, {} as PropertyDescriptorMap)
    return Object.defineProperties(target, descriptor)
  }, [keys, target])
}

export const useId = () => useMemo(() => uuid(), [])

export const useContainerBoxSize = (
  options?: Partial<{
    /**
     * Debounce time on resize in MS (default 500)
     */
    debounceInterval: number
  }>,
) => {
  const { debounceInterval = 500 } = options || {}
  const containerRef = useRef<HTMLDivElement | null>(null)
  const resizeObserverRef = useRef<ResizeObserver | null>(null)

  const [containerSize, setContainerSize] = useState({ width: 0, height: 0 })

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const onResize = useCallback(
    debounce((entries: ResizeObserverEntry[]) => {
      if (entries.length === 0) {
        return
      }

      const size = getResizeObserverEntrySize(entries[0])

      if (!size) {
        return
      }

      setContainerSize(size)
    }, debounceInterval),
    [],
  )

  useEffect(() => {
    if (containerRef.current) {
      resizeObserverRef.current = new ResizeObserver(onResize)
      resizeObserverRef.current.observe(containerRef.current)
    }

    return () => {
      if (!resizeObserverRef.current) {
        return
      }

      resizeObserverRef.current.disconnect()
    }
  }, [onResize])

  return {
    containerRef,
    containerSize,
  }
}

export const useInitial = <T>(value: T) => {
  const [initial] = useState(() => value)

  return initial
}

import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import { Advertiser, TikTokAdsIntegrationData } from '../interfaces'

const initialState: TikTokAdsIntegrationData = {}

export const { reducer: tikTokAdsReducer } = createSlice({
  name: 'integrations/tikTokAds',
  initialState,
  reducers: {
    handleSuccess: (state, action: PayloadAction<{ advertiser: Advertiser }>) => {
      if (state.account_data) {
        state.account_data.ad_account = action.payload.advertiser
      }
    },
  },
})

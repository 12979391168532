export const DASHBOARD_FULLSTATS_RESPONSE = 'DASHBOARD_FULLSTATS_RESPONSE'
export const DASHBOARD_SUBSCRIBERSLANGUAGESTATS_RESPONSE =
  'DASHBOARD_SUBSCRIBERSLANGUAGESTATS_RESPONSE'
export const DASHBOARD_SUBSCRIBERSGENDERSTATS_RESPONSE = 'DASHBOARD_SUBSCRIBERSGENDERSTATS_RESPONSE'
export const DASHBOARD_SUBSCRIBERSRECENTLIST_RESPONSE = 'DASHBOARD_SUBSCRIBERSRECENTLIST_RESPONSE'
export const DASHBOARD_FETCH_ENTITIES_COUNT = 'DASHBOARD_FETCH_ENTITIES_COUNT'
export const DASHBOARD_FETCH_ENTITIES_COUNT_RESPONSE = 'DASHBOARD_FETCH_ENTITIES_COUNT_RESPONSE'
export const DASHBOARD_FETCH_ENTITIES_COUNT_ERROR = 'DASHBOARD_FETCH_ENTITIES_COUNT_ERROR'
export const DASHBOARD_FETCH_EARNED_LIST = 'DASHBOARD_FETCH_EARNED_LIST'
export const DASHBOARD_FETCH_EARNED_LIST_RESPONSE = 'DASHBOARD_FETCH_EARNED_LIST_RESPONSE'
export const DASHBOARD_FETCH_DANGER_FLOWS_DATA = 'DASHBOARD_FETCH_DANGER_FLOWS_DATA'
export const DASHBOARD_FETCH_DANGER_FLOWS_DATA_ERROR = 'DASHBOARD_FETCH_DANGER_FLOWS_DATA_ERROR'
export const DASHBOARD_SHOW_DANGER_FLOWS_DATA = 'DASHBOARD_SHOW_DANGER_FLOWS_DATA'
export const DASHBOARD_FETCH_DANGER_FLOWS_LIST = 'DASHBOARD_FETCH_DANGER_FLOWS_LIST'

// home tab
export const GET_JOBS_QUESTIONNAIRE_ANSWERS = 'GET_JOBS_QUESTIONNAIRE_ANSWERS'
export const GET_JOBS_QUESTIONNAIRE_ANSWERS_RESPONSE = 'GET_JOBS_QUESTIONNAIRE_ANSWERS_RESPONSE'
export const GET_JOBS_QUESTIONNAIRE_ANSWERS_ERROR = 'GET_JOBS_QUESTIONNAIRE_ANSWERS_ERROR'
export const SAVE_QUESTIONNAIRE_ANSWERS = 'SAVE_QUESTIONNAIRE_ANSWERS'

export const HOME_TAB_GET_EVENTS = 'HOME_TAB_GET_EVENTS'
export const HOME_TAB_GET_EVENTS_RESPONSE = 'HOME_TAB_GET_EVENTS_RESPONSE'

export const HOME_TAB_ADD_EVENTS = 'HOME_TAB_ADD_EVENTS'
export const HOME_TAB_ADD_EVENTS_RESPONSE = 'HOME_TAB_ADD_EVENTS_RESPONSE'

export const HOME_TAB_CHANGE_CHANNEL = 'HOME_TAB_CHANGE_CHANNEL'
export const HOME_TAB_ACTIVE_TAB = 'HOME_TAB_ACTIVE_TAB'

export const HOME_TAB_FIRST_RECURRING_NOTIFICATION_MODAL_STATE =
  'HOME_TAB_FIRST_RECURRING_NOTIFICATION_MODAL_STATE'
export const HOME_TAB_FIRST_RECURRING_NOTIFICATION_SUCCESS_MODAL_STATE =
  'HOME_TAB_FIRST_RECURRING_NOTIFICATION_SUCCESS_MODAL_STATE'

export const HOME_TAB_ONBOARDING_CALL_REQUEST = 'HOME_TAB_ONBOARDING_CALL_REQUEST'
export const HOME_TAB_ONBOARDING_CALL_RESPONSE = 'HOME_TAB_ONBOARDING_CALL_RESPONSE'
export const HOME_TAB_ONBOARDING_CALL_ERROR = 'HOME_TAB_ONBOARDING_CALL_ERROR'

export const HOME_TAB_GET_ANALYTICAL_WIDGET_DATA_REQUEST =
  'HOME_TAB_GET_ANALYTICAL_WIDGET_DATA_REQUEST'
export const HOME_TAB_GET_ANALYTICAL_WIDGET_DATA_RESPONSE =
  'HOME_TAB_GET_ANALYTICAL_WIDGET_DATA_RESPONSE'
export const HOME_TAB_GET_ANALYTICAL_WIDGET_DATA_ERROR = 'HOME_TAB_GET_ANALYTICAL_WIDGET_DATA_ERROR'

export const HOME_TAB_GENERATE_ANALYTICAL_WIDGET_DATA_REQUEST =
  'HOME_TAB_GENERATE_ANALYTICAL_WIDGET_DATA_REQUEST'
export const HOME_TAB_GENERATE_ANALYTICAL_WIDGET_DATA_RESPONSE =
  'HOME_TAB_GENERATE_ANALYTICAL_WIDGET_DATA_RESPONSE'
export const HOME_TAB_GENERATE_ANALYTICAL_WIDGET_DATA_ERROR =
  'HOME_TAB_GENERATE_ANALYTICAL_WIDGET_DATA_ERROR'

export const HOME_SET_ANALYTICAL_WIDGET_TIME_FOR_UPDATE =
  'HOME_SET_ANALYTICAL_WIDGET_TIME_FOR_UPDATE'

import { z } from 'zod'
import { l } from '@manychat/manyui'

import { AiStepWizardAnalytics } from 'apps/aiStepWizard/lib/analytics'
import {
  AiAgentAvailableChannel,
  AiAgentAvailableChannelSchema,
} from 'common/ai/constants/AiAgentAvailableChannels'
import { NodeType } from 'common/builder'
import { builderCreateNode } from 'common/builder/actions/builderCreateNode'
import { toggleTriggerPicker } from 'common/builder/actions/builderStateActions'
import { ChannelTypeToTriggerTypeMap } from 'common/builder/components/builder/TriggerPicker/lib/triggerCategoryType'
import { AiAgentSettings } from 'common/builder/nodes/nodeInterfaces'
import { alert } from 'common/core'
import { ChannelType } from 'common/core/constants/ChannelType'
import { IThunkAction } from 'common/core/interfaces/actions'
import {
  VirtualFlexibleAgent,
  VirtualFlexibleAgentSchema,
} from 'shared/api/requests/aiAgent/schemas'
import localStorage from 'utils/localStorage'
import { getQuery, navigatePollyfill } from 'utils/router/tools'
import errorTrackingService from 'utils/services/errorTrackingService'

const clearSearchParams = (): IThunkAction => {
  return () => {
    const query = getQuery()
    const searchParams = new URLSearchParams(query)
    searchParams.delete('sessionId')

    navigatePollyfill({ search: searchParams.toString() }, { replace: true })
  }
}

const createAiStepNode = (
  builderId: string,
  parsedData: {
    agent: VirtualFlexibleAgent
    channel: ChannelType | null
    settings: AiAgentSettings
  },
  sessionId: string,
): IThunkAction => {
  return (dispatch) => {
    const node = dispatch(
      builderCreateNode(
        builderId,
        {
          nodeType: NodeType.AI_AGENT,
          agentId: null,
          agent_data: parsedData.agent,
          channel: parsedData.channel,
          settings: parsedData.settings,
        },
        { preventOpen: true },
      ),
    ) as unknown as { id: string }

    AiStepWizardAnalytics.logAgentApplied({
      nodeId: node.id,
      sessionId,
    })

    return node
  }
}

const errorMessage = l.makeTranslate("AI Step is invalid and can't be used in your Automation")

const presetSchema = z.object({
  agent: VirtualFlexibleAgentSchema,
  channel: AiAgentAvailableChannelSchema.nullable(),
  settings: z.object({
    save_phone_as_whatsapp_id: z.boolean(),
  }),
})

export const createAiStepNodePreset = ({ builderId }: { builderId: string }): IThunkAction => {
  return async (dispatch) => {
    const { sessionId } = getQuery()

    dispatch(clearSearchParams())

    let parsedData: {
      agent: VirtualFlexibleAgent
      channel: ChannelType | null
      settings: AiAgentSettings
    }

    try {
      const presetData = JSON.parse(localStorage.getItem(sessionId))

      parsedData = presetSchema.parse(presetData)
    } catch (error) {
      errorTrackingService.logError('Invalid AI Step preset', {
        scope: 'ai',
        section: 'ai_step_wizard',
        extra: {
          preset: localStorage.getItem(sessionId),
        },
      })
      alert(l.getString(errorMessage), 'danger')
      return
    } finally {
      localStorage.removeItem(sessionId)
    }

    if (parsedData.channel) {
      dispatch(
        toggleTriggerPicker(builderId, {
          initialCategory:
            ChannelTypeToTriggerTypeMap[parsedData.channel as AiAgentAvailableChannel],
        }),
      )
    }

    return dispatch(createAiStepNode(builderId, parsedData, sessionId))
  }
}

import React, { createRef } from 'react'
import cx from 'classnames'
import { Icon, TextInputV2 } from '@manychat/manyui'

import defaultProps from './defaultProps'
import Wrapper from './Wrapper'

import cm from './Autocomplete.module.css'

/** @deprecated use Autocomplete from @manychat/manyui */
export default class AutocompleteComponent extends React.Component {
  static defaultProps = Object.assign({}, defaultProps, {
    onFocus: () => {},
    onBlur: () => {},
    onChange: () => {},
    placeholder: '',
  })

  constructor(props, context) {
    super(props, context)
    let value = this.getValueFromProps(props)

    if (!value && props.defaultValue) {
      if (typeof props.defaultValue === 'string') {
        value = props.defaultValue
      } else {
        value = props.defaultValue[props.labelKey] || ''
      }
    }

    this.dropDownRef = createRef()
    this.textInputRef = createRef()

    this.state = { value, show: false }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.value !== undefined && nextProps.value !== this.props.value) {
      this.setState({ value: this.getValueFromProps(nextProps) })
    }

    if (nextProps.value === undefined && this.props.value === undefined) {
      if (nextProps.defaultValue !== this.props.defaultValue) {
        this.setState({
          value: nextProps.defaultValue[this.props.labelKey] || nextProps.defaultValue,
        })
      }
    }
  }

  getValueFromProps = (props) => {
    if (props.value) {
      return (typeof props.value === 'string' ? props.value : props.value[props.labelKey]) || ''
    }

    return ''
  }

  click = (e) => {
    this.show()
  }

  show = () => {
    this.setState({ show: true })
    this.dropDownRef.current.show()
  }

  hide = () => {
    this.setState({ show: false })
    if (this.dropDownRef.current) {
      this.dropDownRef.current.hide()
    }
  }

  keydown = (e) => {
    switch (e.keyCode) {
      case 27:
        return false
    }
    this.show()
  }

  focus = () => this.textInputRef && this.textInputRef.current.focus()

  blur = () => this.textInputRef && this.textInputRef.current.blur()

  selectText = () => this.textInputRef && this.textInputRef.current.select()

  handleFocus = (e) => {
    this.show()
    this.props.onFocus()
  }

  handleBlur = (e) => {
    if (this.props.cleanUpEmpty) {
      if (this.state.value === '') {
        this.props.onSelect(null)
      }
    }
    this.hide()
    this.props.onBlur(e)
    setTimeout(() => {
      // sync value in input if it doesn't match the one from props
      this.setState({ value: this.getValueFromProps(this.props) })
    })
    this.interval = null
  }

  change = (e) => {
    const { value } = e.target
    this.setState({ value })
    this.props.onChange(value)
  }

  select = (opt) => {
    this.setState({ value: opt[this.props.labelKey] || opt })
    this.props.onSelect(opt)
    this.hide()
  }

  create = (opt) => {
    this.setState({ value: opt[this.props.labelKey] || opt })
    this.props.onCreate(opt)
  }

  handleClearClick = () => {
    if (this.props.onClear) {
      return this.props.onClear()
    }
    this.change({ target: { value: '' } })
    this.props.onSelect(null)
  }

  render() {
    const {
      placeholder,
      style,
      inline,
      className,
      disabled,
      invalid,
      useLayerForClickAway = true,
      customInput,
      customTarget,
      enableClear,
      customButton,
      ...others
    } = this.props
    const { value, show } = this.state
    const inputStyle = Object.assign({}, style, {
      display: inline ? 'inline-block' : 'block',
      width: inline ? 'auto' : '100%',
    })
    const inputProps = {
      value: value,
      className: className,
      disabled: disabled,
      invalid: invalid,
      placeholder: placeholder,
      onKeyDown: this.keydown,
      onClick: this.click,
      onChange: this.change,
      onFocus: this.handleFocus,
      onBlur: this.handleBlur,
      'data-test-id': this.props['data-test-id'],
    }

    return (
      <Wrapper
        {...others}
        show={show}
        useLayerForClickAway={useLayerForClickAway}
        onSelect={this.select}
        onCreate={this.create}
        onRequestClose={this.hide}
        customButton={customButton}
        fitTargetWidth
        q={value || ''}
        ref={this.dropDownRef}
        target={this.textInputRef.current || customTarget || null}
      >
        <div className={cm.inputWrapper}>
          {customInput ? (
            React.cloneElement(customInput, inputProps)
          ) : (
            <TextInputV2 style={inputStyle} inputRef={this.textInputRef} {...inputProps} />
          )}
          {enableClear && value && !disabled && (
            <Icon.Close onClick={this.handleClearClick} className={cx(cm.clearIcon, 'pointer')} />
          )}
        </div>
      </Wrapper>
    )
  }
}

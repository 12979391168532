import { useEffect, useMemo } from 'react'
import { useAppDispatch, useAppSelector, useAppStore } from 'reduxTyped'

import { removeNotification, setNotifications } from 'common/core/actions/appActions'
import { useWaHistoryContactImport } from 'common/core/components/NotifyBar/hooks/useWaHistoryContactImport'
import { NotificationItem } from 'common/core/components/NotifyBar/NotifyInterfaces'
import { AllNotifications } from 'common/core/components/NotifyBar/StaticNotifications/StaticNotifications'
import { NotificationBarType } from 'common/core/constants/NotificationBarType'
import { getClosedNotificationIds, isAccountBlocked } from 'common/core/selectors/appSelectors'
import { getBackendNotifications } from 'common/core/selectors/notifyBarSelectors'
import { useQuery } from 'utils/api/query/hooks/useQuery'

import { parseNotification, sortNotifications } from '../utils/utils'

const isErrorNotifications = (notification: NotificationItem) =>
  notification.type === NotificationBarType.ERROR

export const useNotifications = () => {
  useWaHistoryContactImport()
  const dispatch = useAppDispatch()
  const state = useAppStore().getState()
  const backendNotifications = useAppSelector(getBackendNotifications)
  const { query } = useQuery<NotificationItem[]>('Notifications', ({ api }) =>
    api.notifications.getNotifications(),
  )

  useEffect(() => {
    query.status === 'success' && dispatch(setNotifications(query.data))
  }, [query, dispatch])

  const closeNotification = (notification: NotificationItem) => {
    dispatch(removeNotification(notification))
  }

  const enabledStaticNotifications = useAppSelector((state) => {
    return AllNotifications.filter((notification) => notification.shouldBeShown(state))
  })

  const parsedBackendNotifications = useMemo(() => {
    return backendNotifications.map(parseNotification)
  }, [backendNotifications])

  const closedNotificationIds = useAppSelector(getClosedNotificationIds)
  const activeNotifications = sortNotifications([
    ...enabledStaticNotifications,
    ...parsedBackendNotifications,
  ]).filter((notification) => {
    return !closedNotificationIds.includes(notification.notification_id)
  })

  if (isAccountBlocked(state) && activeNotifications.some(isErrorNotifications)) {
    return { notifications: activeNotifications.filter(isErrorNotifications), closeNotification }
  }
  return { notifications: activeNotifications, closeNotification }
}

import BaseBlockConfig from 'common/builder/blocks/BaseBlockConfig'
import { IAttachmentBlock, IBlockBase } from 'common/builder/blocks/blockInterfaces'
import { BlockType } from 'common/builder/constants/BlockType'

class AttachmentBlockConfig extends BaseBlockConfig {
  blockType = BlockType.ATTACHMENT

  getDefaults = (): Omit<IAttachmentBlock, keyof IBlockBase> => ({
    attachmentType: null,
    attachment: null,
    telegramVideoType: undefined,
  })
}

export default new AttachmentBlockConfig()

import { ChangeEvent, createContext } from 'react'

interface CheckboxGroupContextType {
  values?: string[]
  handleChange?: (val: string, event: ChangeEvent<HTMLInputElement>) => void
  disabled?: boolean
}

const CheckboxGroupContext = createContext<CheckboxGroupContextType>({})

export default CheckboxGroupContext

export const CUF = 'cuf'
export const SUF = 'suf'

export enum FieldStatuses {
  ARCHIVED = 'archived',
  ACTIVE = 'active',
  DELETED = 'deleted',
}

export const USER_FIELD_PREFIX = 'cuf_'
export const GLOBAL_FIELD_PREFIX = 'gaf_'
export const LOCAL_CONTEXT_FIELD_PREFIX = 'lcf_'
export const CONTEXT_FIELD_PREFIX = 'app.'

import { l } from '@manychat/manyui'

import * as smsSelectors from 'apps/sms/selectors/smsSelectors'
import { FetchStatus } from 'apps/sms/smsInterfaces'
import * as atypes from 'apps/sms/smsReduxActionTypes'
import { UpgradeSource } from 'common/billing'
import { handleGoProError } from 'common/billing/helpers/handleGoProError'
import { alert } from 'common/core'
import { IThunkAction } from 'common/core/interfaces/actions'
import { getPermission, PermissionTargets } from 'common/userRoles'
import { isBillingAccessError } from 'shared/api/common/errors/billingAccessError'
import { handleCatch } from 'shared/api/lib/errors/handlers'
import { applyHandlerByGuard, reportUnhandledErrors } from 'shared/api/lib/errors/utils'
import { settingsApi } from 'shared/api/requests/settings'
import { settingsApiGuard } from 'shared/api/requests/settings/guards'
import { smsApi } from 'shared/api/requests/sms'
import { navigatePollyfill, isPathnameInclude } from 'utils/router/tools'
import { linkURL } from 'utils/url'

export const connectSmsByTwilio = (sid: string, authToken: string, phone: string): IThunkAction => {
  return async (dispatch) => {
    try {
      const response = await settingsApi.sms.connectSmsByTwilio({
        body: { sid, auth_token: authToken, phone_number: phone },
      })

      dispatch({ type: atypes.SMS_TWILIO_CONNECT_RESPONSE, data: response.data })
    } catch (error) {
      handleCatch(error, (responseError) => {
        const unhandledErrors = applyHandlerByGuard(
          responseError.$errors,
          isBillingAccessError,
          (error) => alert(error.original_message, 'info'),
        )

        reportUnhandledErrors(unhandledErrors, responseError.endpoint)
      })
    }
  }
}

export const disconnectSmsByTwilio = (): IThunkAction => async (dispatch) => {
  try {
    await settingsApi.sms.disconnectSmsByTwilio()
    dispatch({ type: atypes.SMS_TWILIO_DISCONNECT_RESPONSE })
  } catch (error) {
    handleCatch(error)
  }
}

export const fetchSmsData =
  (isNeedRegularUpdate?: boolean): IThunkAction =>
  async (dispatch, getState) => {
    const fetchStatus = smsSelectors.getSmsFetchStatus(getState())

    if (fetchStatus !== FetchStatus.NOT_FETCHED && !isNeedRegularUpdate) {
      return
    }

    try {
      dispatch({ type: atypes.SMS_FETCH_DATA_REQUEST })

      const response = await smsApi.getData()

      dispatch({ type: atypes.SMS_FETCH_DATA_RESPONSE, data: { phones: response.data.phones } })
    } catch (error) {
      dispatch({ type: atypes.SMS_FETCH_DATA_ERROR })
      handleCatch(error)
    }
  }

export const enableSmsChannel = (): IThunkAction => async (dispatch) => {
  try {
    await settingsApi.sms.enableChannel()
    dispatch({ type: atypes.SMS_ENABLE_CHANNEL_RESPONSE })

    if (isPathnameInclude('/insights')) {
      navigatePollyfill(linkURL('/dashboard'))
    }
  } catch (error) {
    handleCatch(error, (responseError) => {
      const unhandled = applyHandlerByGuard(
        responseError.$errors,
        settingsApiGuard.sms.isProError,
        (error) => {
          dispatch(
            handleGoProError(error, {
              message: l.translate('Upgrade your plan to enable SMS channel'),
              source: UpgradeSource.ENABLE_SMS_CHANNEL,
              products: error.error_data.products,
            }),
          )
        },
      )

      reportUnhandledErrors(unhandled, responseError.endpoint)
    })
  }
}

export const disableSmsChannel = (): IThunkAction => async (dispatch) => {
  try {
    await settingsApi.sms.disableChannel()

    dispatch({ type: atypes.SMS_DISABLE_CHANNEL_RESPONSE })

    if (isPathnameInclude('/insights')) {
      navigatePollyfill(linkURL('/dashboard'))
    }
  } catch (error) {
    handleCatch(error)
  }
}

export const openModalConnectSms = (): IThunkAction => (dispatch, getState) => {
  const state = getState()
  const hasPermission = getPermission(state, PermissionTargets.CAN_CONNCET_SMS_CHANNEL)
  if (!hasPermission) {
    alert(l.translate('You don’t have rights to change it. Please ask Admin to do this.'), 'danger')
    return
  }

  return dispatch({
    type: atypes.SMS_OPEN_MODAL_CONNECT,
  })
}

export const closeModalConnectSms = (): IThunkAction => (dispatch) =>
  dispatch({
    type: atypes.SMS_CLOSE_MODAL_CONNECT,
  })

import { l } from '@manychat/manyui'

const localFieldCaptions: Record<string, ReturnType<typeof l.makeTranslate>> = {
  smart_segment: l.makeTranslate('Segment'),
  tag: l.makeTranslate('Tag'),
  widget: l.makeTranslate('Opted-In Through Widget'),
  ads_growth_tool: l.makeTranslate('Opted-In Through Ad'),
  opt_in_through_api: l.makeTranslate('Opted-In Through API'),
  sequence: l.makeTranslate('Sequence subscription'),
  system_current_datetime: l.makeTranslate('Current time'),
  one_time_notification: l.makeTranslate('Messenger: List subscription available right now'),
  //System
  gender: l.makeTranslate('Gender'),
  locale: l.makeTranslate('Locale'),
  language: l.makeTranslate('Language'),
  timezone: l.makeTranslate('Timezone'),
  full_name: l.makeTranslate('Full Name'),
  first_name: l.makeTranslate('First Name'),
  last_name: l.makeTranslate('Last Name'),
  subscribed: l.makeTranslate('Subscribed'),
  last_interaction: l.makeTranslate('Last Interaction in Messenger'),
  last_ig_interaction: l.makeTranslate('Last Interaction in Instagram'),
  thread_last_channel: l.makeTranslate('Channel'),
  last_seen: l.makeTranslate('Last Seen in Messenger'),
  last_ig_seen: l.makeTranslate('Last Seen in Instagram'),
  messaging_window: l.makeTranslate('Messaging window segment'),
  ig_messaging_window: l.makeTranslate('Messaging window segment'),
  user_id: l.makeTranslate('Contact Id'),
  optin_phone: l.makeTranslate('Opted-in for SMS'),
  phone: l.makeTranslate('Phone'),
  optin_email: l.makeTranslate('Opted-in for Email'),
  email: l.makeTranslate('Email'),
  phone_country_code: l.makeTranslate('Phone country code'),
  phone_us_state: l.makeTranslate('U.S. state (phone based)'),
  messenger: l.makeTranslate('Opted-in for Messenger'),
  is_eu_affected: l.makeTranslate('EEA Affected'),
  optin_instagram: l.makeTranslate('Opted-in for Instagram'),
  is_ig_account_follower: l.makeTranslate('Follows your Instagram account'),
  is_tt_account_follower: l.makeTranslate('Follows your TikTok account'),
  ig_followers_count: l.makeTranslate('Follower Count on Instagram'),
  is_ig_verified_user: l.makeTranslate('Verified on Instagram'),
  is_ig_account_follow_user: l.makeTranslate('Business Follows Contact on Instagram'),
  ig_username: l.makeTranslate('Instagram Username'),
  last_tg_interaction: l.makeTranslate('Last Interaction in Telegram'),
  tg_username: l.makeTranslate('Telegram Username'),
  tg_user_id: l.makeTranslate('Telegram User ID'),
  optin_telegram: l.makeTranslate('Opted-in for Telegram'),
  last_wa_interaction: l.makeTranslate('Last Interaction in WhatsApp'),
  last_wa_seen: l.makeTranslate('Last Seen in WhatsApp'),
  last_wa_cart: l.makeTranslate('Latest Cart Full Data'),
  optin_tiktok: l.makeTranslate('Opted-in for TikTok'),
  opt_in_through_tiktok_ads: l.makeTranslate('Opted-In Through TikTok Ads'),
  // System front-only
  last_input_text: l.makeTranslate('Last Text Input'),
  page_id: l.makeTranslate('Facebook Page Id'),
  page_name: l.makeTranslate('Page Name'),
  instagram_account_name: l.makeTranslate('Instagram Page Name'),
  last_tt_seen: l.makeTranslate('Last Seen in TikTok'),
  last_tt_interaction: l.makeTranslate('Last Interaction in TikTok'),
  tiktok_account_username: l.makeTranslate('Your TikTok Username'),
  tiktok_account_display_name: l.makeTranslate('Your TikTok Display Name'),
  tt_username: l.makeTranslate('TikTok Username'),
  tt_display_name: l.makeTranslate('TikTok Display Name'),
}

export const getLocalCaptionFor = (id: string, map = localFieldCaptions): string | null => {
  return map[id]?.() ?? null
}

import { api } from 'shared/api'
import { AuthApiSchemas } from 'shared/api/requests/auth/schemas'

export const AuthApi = {
  getInstagramConnection: api.base.createGet({
    url: '/auth/getInstagramConnection',
    schemas: AuthApiSchemas.getInstagramConnection,
  }),
  getMetaInstagramConnection: api.base.createGet({
    url: '/auth/getMetaInstagramConnection',
    schemas: AuthApiSchemas.getMetaInstagramConnection,
  }),
  validateInstagramPage: api.base.createGet({
    url: '/auth/validateInstagramPage',
    schemas: AuthApiSchemas.validateInstagramPage,
  }),
  requestAccountAccess: api.base.createPost({
    url: '/auth/requestAccountAccess',
    schemas: AuthApiSchemas.requestAccountAccess,
  }),
  addIgAccount: api.base.createPost({
    url: '/auth/doAddInstagram',
    schemas: AuthApiSchemas.addIgAccount,
  }),
  updateProducts: api.base.createPost({
    url: '/auth/updateProducts',
    schemas: AuthApiSchemas.updateProducts,
  }),
  getFbPagesList: api.base.createGet({
    url: '/auth/FbPagesList',
    schemas: AuthApiSchemas.getFbPagesList,
  }),
  addFbAccount: api.base.createPost({
    url: '/auth/connectFbPage',
    schemas: AuthApiSchemas.addFbAccount,
  }),
  addTgAccount: api.base.createPost({
    url: '/auth/doAddTelegram',
    schemas: AuthApiSchemas.addTgAccount,
  }),
  getTiktokConnection: api.base.createGet({
    url: '/auth/getTiktokConnection',
    schemas: AuthApiSchemas.getTiktokConnection,
  }),
}

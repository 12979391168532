import { AxiosError } from 'axios'

import { ResponseError, InvalidErrorResponseError } from 'shared/api/lib/errors/business'
import {
  AccessError,
  AccessErrorReason,
  ClientError,
  RedirectError,
  ServerError,
} from 'shared/api/lib/errors/server'
import { isLocal } from 'utils/isLocal'
import errorTrackingService from 'utils/services/errorTrackingService'
import {
  CSRF_TOKEN_EMPTY_EVENT_NAME,
  CSRF_TOKEN_EMPTY_FINGERPRINT,
} from 'utils/services/sentryConstantNames'

import {
  convertResponseWithoutState,
  checkValidStatusRange,
  ClientStatusesRange,
  RedirectStatusesRange,
  ServerStatusesRange,
} from './lib/interceptors'

import { baseInstance, accountInstance } from './index'

type InitApi = (options: {
  getCurrentAccountId: () => string
  getCsrfToken: () => string | null
}) => void

export const initApi: InitApi = ({ getCurrentAccountId, getCsrfToken }) => {
  const instances = [baseInstance, accountInstance]

  instances.forEach((instance) => {
    instance.interceptors.request.use((request) => {
      if (request.baseURL && request.baseURL.includes(':currentAccountID')) {
        request.baseURL = request.baseURL.replace(':currentAccountID', getCurrentAccountId())
      }

      const csrfToken = getCsrfToken()
      if (csrfToken) {
        request.headers.set('X-Csrf-Token', csrfToken)
      } else {
        errorTrackingService.trackWarning(CSRF_TOKEN_EMPTY_EVENT_NAME, {
          extra: { url: request.baseURL || request.url },
          fingerprint: CSRF_TOKEN_EMPTY_FINGERPRINT,
        })
      }

      request.headers.set(
        'X-Frontend-Bundle',
        window.STATIC_VERSION ? String(window.STATIC_VERSION) : 'Empty',
      )

      return request
    })

    instance.interceptors.response.use(
      (response) => {
        const responseWithoutState = convertResponseWithoutState(response)

        if (response.data.state === false) {
          if (Array.isArray(response.data.$errors)) {
            return Promise.reject(new ResponseError(responseWithoutState))
          } else {
            return Promise.reject(new InvalidErrorResponseError(response))
          }
        }

        return responseWithoutState
      },
      (error: AxiosError) => {
        const status = error.response?.status
        errorTrackingService.setTag('status', status)

        if (checkValidStatusRange(ServerStatusesRange, status)) {
          return Promise.reject(new ServerError(error))
        }

        if (checkValidStatusRange(ClientStatusesRange, status)) {
          if (status === 401) {
            const hasLocal = isLocal()
            !hasLocal && window.location.assign('/login')

            errorTrackingService.setTag('AccessErrorReasons', AccessErrorReason.UNAUTHORIZED)
            return Promise.reject(new AccessError(error, AccessErrorReason.UNAUTHORIZED))
          }

          if (status === 451) {
            errorTrackingService.setTag('AccessErrorReasons', AccessErrorReason.LEGAL)
            return Promise.reject(new AccessError(error, AccessErrorReason.LEGAL))
          }

          return Promise.reject(new ClientError(error))
        }

        if (checkValidStatusRange(RedirectStatusesRange, status)) {
          return Promise.reject(new RedirectError(error))
        }

        return Promise.reject(error)
      },
    )
  })
}

import React from 'react'
import cx from 'classnames'
import { Icon, l } from '@manychat/manyui'

import ToolBtn from 'common/builder/components/flowSimulator/components/toolBtn/ToolBtn'

import cm from './FlowPickerSimulator.module.css'

interface FlowPickerSimulatorProps {
  isSimulatorThrownError?: boolean
  handleRestartSimulator: () => void
  phoneContainerRef: React.RefObject<HTMLDivElement>
}

export const FlowPickerSimulator = ({
  phoneContainerRef,
  handleRestartSimulator,
  isSimulatorThrownError,
}: FlowPickerSimulatorProps) =>
  isSimulatorThrownError ? (
    <div className="d-flex flex-grow-1 align-center text-secondary text-center p-x">
      <span>{l.translate('Preview is not available for this Automation.')}</span>
    </div>
  ) : (
    <div className={cx('flex-grow-1 p-relative', cm.phoneContainer)}>
      <div ref={phoneContainerRef}>
        <ToolBtn Icon={Icon.Retry} onClick={handleRestartSimulator} className={cm.retryBtn} />
      </div>
    </div>
  )

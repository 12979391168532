import React, { useCallback } from 'react'
import { useNavigate } from 'react-router-dom'
import { useAppSelector, useAppDispatch } from 'reduxTyped'

import { getBuilderUrl } from 'apps/cms/lib/getBuilderUrl'
import { FlowCardClickAction } from 'apps/cms/pages/ContentManagementSystem/lib/types'
import { contentManagementActions, contentManagementSelectors } from 'apps/cms/store'
import { warnDisabled, warnAccessDenied } from 'apps/templates/models/Template/helpers'
import { IFullPinnedFlow } from 'common/flow/flowInterfaces'
import { usePermission, PermissionTargets } from 'common/userRoles'

export const usePinnedFlowCard = ({ flowId, flow }: { flowId: string; flow: IFullPinnedFlow }) => {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const userCanEditFlow = usePermission(PermissionTargets.FLOW_EDITING)
  const { flowCardClickAction, disableFlowCard } = useAppSelector(contentManagementSelectors.getUi)

  const hasPublishedContent = Boolean(flow?.has_published_content)
  const isCardDisabled = Boolean(disableFlowCard && !hasPublishedContent)

  const accessData = flow?.template_installation_access_data
  const putFlowIdToState = useCallback(() => {
    dispatch(contentManagementActions.setLastSelectedFlowId(flowId))
  }, [dispatch, flowId])

  const openFlow = useCallback(
    (event: React.MouseEvent<HTMLButtonElement | HTMLDivElement | HTMLAnchorElement>) => {
      if (!flow) return

      if (accessData && accessData.is_protected && !accessData.is_owner) {
        if (accessData.is_disabled) {
          return warnDisabled()
        }

        if (accessData.is_locked) {
          return warnAccessDenied()
        }
      }

      const url = getBuilderUrl(flow.path)

      if (event.ctrlKey || event.metaKey) {
        window.open(url)
      } else {
        navigate(url)
      }
    },
    [accessData, flow, navigate],
  )

  const handleFlowCardClick = useCallback(
    (event: React.MouseEvent<HTMLButtonElement | HTMLDivElement | HTMLAnchorElement>) => {
      event.preventDefault()

      switch (flowCardClickAction) {
        case FlowCardClickAction.SAVE_FLOW_ID_TO_STATE:
          putFlowIdToState()
          break
        case FlowCardClickAction.OPEN_FLOW:
          openFlow(event)
          break
      }
    },
    [flowCardClickAction, putFlowIdToState, openFlow],
  )

  return {
    userCanEditFlow,
    flow,
    handleFlowCardClick,
    accessData,
    isCardDisabled,
  }
}

import { Icon, l } from '@manychat/manyui'

import {
  IntegrationType,
  IntegrationNameByType,
} from 'common/actions/integrations/common/constants/IntegrationType'
import {
  createIntegration,
  createIntegrationAction,
} from 'common/actions/integrations/common/model/helpers'
import { FlodeskAction } from 'common/actions/integrations/Flodesk/model/interfaces'
import { AccountTargetPermissionsNames } from 'common/core/models/Account/constants'

export const FlodeskActionTypes = {
  CREATE_SUBSCRIBER: FlodeskAction.CREATE_SUBSCRIBER,
}

export const FlodeskConfig = createIntegration({
  type: IntegrationType.FLODESK,
  name: IntegrationNameByType[IntegrationType.FLODESK],
  icon: Icon.Flodesk,
  permissionsVariable: AccountTargetPermissionsNames.FLODESK_INTEGRATION,
  description: () =>
    l.translate(
      'The integration provides you with an ability to save customers data from Manychat to Flodesk. Create a contact if it doesn’t exist in Flodesk already, or update it with Contact’s Custom Fields if it does. <a href="https://manychat.notion.site/manychat/Flodesk-Integration-fb541971b3c046b0afcedef50abfd375" target="_blank" class="text-primary pointer" rel="noreferrer">Learn more</a>',
    ),
  reduxActions: {},
  actions: [
    createIntegrationAction({
      type: FlodeskActionTypes.CREATE_SUBSCRIBER,
      description: l.translate('Send Custom Field data to Flodesk form.'),
      name: l.translate('Submit data to a form'),
    }),
  ],
  isBeta: true,
})

import { useMemo } from 'react'

import { BaseBreadcrumbsSegment } from 'components/Breadcrumbs/lib/types'

export const useSegments = <CustomSegment extends BaseBreadcrumbsSegment>({
  segments,
  useMenu,
  tailItemsCount,
}: {
  segments: CustomSegment[]
  useMenu: boolean
  tailItemsCount: number
}) => {
  return useMemo(() => {
    const mainSegment = segments[0]
    let visibleSegments = segments.length > 1 ? segments.slice(1) : []
    let hiddenSegments: CustomSegment[] = []

    if (useMenu) {
      if (segments.length > tailItemsCount) {
        visibleSegments = segments.slice(-tailItemsCount)
        hiddenSegments = segments.slice(1, -tailItemsCount)
      }
    }

    return {
      mainSegment,
      visibleSegments,
      hiddenSegments,
    }
  }, [segments, tailItemsCount, useMenu])
}

import React, { ComponentProps } from 'react'
import { useAppSelector } from 'reduxTyped'
import { BtnV2, l, useRefCallback } from '@manychat/manyui'

import { billing } from 'common/billing/billingService/billingService'
import { SubscriptionAction } from 'common/billing/constants/SubscriptionAction'
import { RequestUpgradePayload } from 'common/billing/interfaces'
import { getSubscriptionAction, getUpgradeCaption } from 'common/billing/selectors/billingSelectors'
import { analyticsService } from 'utils/services/analytics'

export interface GoProBtnProps extends ComponentProps<typeof BtnV2> {
  forceShow?: boolean
  onClick?: (e: React.MouseEvent<HTMLButtonElement>) => void
  label?: string
  forceLabel?: boolean
  onUpgrade?: (value: boolean) => void
  request: RequestUpgradePayload
}

const GoProBtn = ({
  label: labelProp = l.translate('Upgrade to Pro'),
  forceLabel,
  onClick,
  forceShow = false,
  request,
  onUpgrade,
  ...other
}: GoProBtnProps) => {
  const defaultLabel = useAppSelector(getUpgradeCaption)
  const label = forceLabel ? labelProp : defaultLabel
  const subscriptionAction = useAppSelector(getSubscriptionAction)

  const openUpgradeToPro = useRefCallback((event: React.MouseEvent<HTMLButtonElement>) => {
    if (typeof onClick === 'function') {
      onClick(event)
    }

    analyticsService.sendEvent('SESSION_QUALITY.CLICK.UPGRADE1', {
      subscriptionAction,
    })

    billing.requestUpgrade(request).then(onUpgrade)
  })

  if (subscriptionAction === SubscriptionAction.NONE && !forceShow) {
    return null
  }

  return <BtnV2 {...other} label={label} onClick={openUpgradeToPro} />
}

export default GoProBtn

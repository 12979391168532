import { createSlice } from '@reduxjs/toolkit'
import { createAppAsyncThunk } from 'reduxTyped'

import { FbPageStatus } from 'apps/addNewAccount/FacebookMessenger/addFbAccountInterfaces'
import { AddFbAccountState } from 'apps/addNewAccount/FacebookMessenger/redux/interfaces'
import { handleCatch } from 'shared/api/lib/errors/handlers'
import { AuthApi } from 'shared/api/requests/auth'

const initialState: AddFbAccountState = {
  pages: [],
}

export const connectFbPage = createAppAsyncThunk(
  'addFbAccount/connectFbPage',
  async ({ pageId, accountId }: { pageId: string; accountId: string | null }) => {
    try {
      const { data } = await AuthApi.addFbAccount({
        body: { page_id: pageId, account_id: accountId },
      })

      return data
    } catch (error) {
      handleCatch(error)

      return null
    }
  },
)

export const requestPageAccess = createAppAsyncThunk(
  'addFbAccount/requestPageAccess',
  async (pageId: string) => {
    try {
      await AuthApi.requestAccountAccess({ body: { fb_page_id: pageId } })

      return pageId
    } catch (error) {
      handleCatch(error)

      return null
    }
  },
)

const addFbAccountSlice = createSlice({
  name: 'addFbAccount/fetch',
  initialState,
  reducers: {
    setPages(state, action) {
      state.pages = action.payload
    },
  },
  extraReducers: (builder) => {
    builder.addCase(connectFbPage.pending, (state, action) => {
      const payloadBody = action.meta.arg

      state.processingPageId = payloadBody.pageId
    })

    builder.addCase(connectFbPage.fulfilled, (state, action) => {
      if (!action.payload) {
        delete state.processingPageId
        return
      }

      state.accountId = action.payload.account_id

      delete state.processingPageId
    })

    builder.addCase(requestPageAccess.pending, (state, action) => {
      const pageId = action.meta.arg

      state.processingPageId = pageId
    })

    builder.addCase(requestPageAccess.fulfilled, (state) => {
      const page = state.pages?.find((page) => page.id === state.processingPageId)

      if (page !== undefined) {
        page.status = FbPageStatus.REQUEST_ACCESS_PENDING
      }

      delete state.processingPageId
    })
  },
})

export const { reducer, actions } = addFbAccountSlice

export const addFbAccountActions = {
  ...actions,
}

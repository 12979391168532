export const WhatsAppRoutes = [
  {
    path: 'whatsapp/messageTemplates',
    children: [
      {
        path: 'create',
        lazy: () => import('../components/WaTemplatePage/WaTemplatePage'),
      },
      {
        path: 'view/:template_name',
        alias: 'waTemplateItem',
        query: ['language_code'],
        lazy: () => import('../components/WaTemplatePage/WaTemplatePage'),
      },
    ],
  },
] as const

class OnboardingStatus {
  private status: 'DEFAULT' | 'RUNNING' | 'COMPLETE' | 'SKIP' = 'DEFAULT'

  constructor() {}

  getStatus() {
    return this.status
  }

  run() {
    if (this.status !== 'DEFAULT') {
      throw new Error('Status should be "DEFAULT" before .run()')
    }

    this.status = 'RUNNING'
  }

  complete = () => {
    if (this.status !== 'RUNNING') {
      throw new Error('Status should be "RUNNING" before .complete()')
    }

    this.status = 'COMPLETE'
  }

  skip = () => {
    if (this.status !== 'RUNNING') {
      throw new Error('Status should be "RUNNING" before .skip()')
    }

    this.status = 'SKIP'
  }
}

export default OnboardingStatus

import React from 'react'
import { useParams } from 'react-router-dom'
import { useAppDispatch, useAppSelector } from 'reduxTyped'

import { getAchievements, getBenefitsLinks, getStatus } from 'apps/agency/actions/agencyActions'
import { fetch as fetchSignOnAgency } from 'apps/agency/actions/signonActions'
import { getSignOnAgencyId } from 'apps/agency/selectors/agencySelectors'

export const useAgencyData = () => {
  const dispatch = useAppDispatch()
  const agencyId = useAppSelector(getSignOnAgencyId)
  const routeAgencyId = useParams().agency_id

  React.useEffect(() => {
    if (!agencyId) {
      dispatch(fetchSignOnAgency())
    }
  }, [agencyId, dispatch])

  React.useEffect(() => {
    if (agencyId && String(agencyId) === routeAgencyId) {
      dispatch(getStatus(agencyId))
      dispatch(getAchievements(agencyId))
      dispatch(getBenefitsLinks(agencyId))
    }
  }, [agencyId, routeAgencyId, dispatch])

  return { agencyId }
}

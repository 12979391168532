import React, { useRef, useCallback, useMemo, useEffect } from 'react'
import cx from 'classnames'

import { makeTranslate, getString } from '../../../utils/localization/format'
import List from '../../List'
import Scrollbars from '../../Scrollbars'

import { BasicSelectOption, SelectOptionsListProps } from './interfaces'
import { SelectOption } from './SelectOption'

// eslint-disable-next-line @typescript-eslint/naming-convention
const defaultEmptyLabel = makeTranslate('No Items Found')

export const SelectOptionsList = <OptionType extends BasicSelectOption>({
  options,
  renderOption,
  onKeyUp,
  emptyLabel,
  selectedOption,
  disableAutoScroll = false,
  handleSelect,
  tabIndex,
  focusedId,
  setFocusedId,
  onKeydown,
}: SelectOptionsListProps<OptionType>) => {
  const listRef = useRef<HTMLUListElement | null>(null)
  const scrollerRef = useRef<Scrollbars | null>(null)

  const focusableOptionIds = useMemo(() => {
    return options.reduce((acc, option) => {
      if (option.type === 'group') return acc
      if (!option.id) return acc
      return [...acc, option.id]
    }, [] as string[])
  }, [options])

  const scrollItemInView = useCallback(
    (id: string) => {
      if (disableAutoScroll) return
      if (!scrollerRef.current?.view) return

      const item = listRef.current?.querySelector(`#${id}`) as HTMLLIElement
      if (!item) return

      const { top: itemTop, bottom: itemBottom } = item.getBoundingClientRect()
      const { top: scrollerTop, bottom: scrollerBottom } =
        scrollerRef.current.view.getBoundingClientRect()

      const isLastItem = id === focusableOptionIds[focusableOptionIds.length - 1]
      const isFirstItem = id === focusableOptionIds[0]

      /** scrolling down */
      if (itemBottom > scrollerBottom) {
        if (isLastItem) {
          scrollerRef.current.view.scrollTop = scrollerRef.current.view.scrollHeight
        } else {
          scrollerRef.current.view.scrollTop =
            item.offsetTop + item.offsetHeight - scrollerRef.current.view.clientHeight
        }
        /** scrolling up */
      } else if (itemTop < scrollerTop) {
        if (isFirstItem) {
          scrollerRef.current.view.scrollTop = 0
        } else {
          scrollerRef.current.view.scrollTop = item.offsetTop
        }
      }
    },
    [disableAutoScroll, focusableOptionIds],
  )

  useEffect(() => {
    focusedId && scrollItemInView(focusedId)
  }, [focusedId, scrollItemInView])

  return (
    <Scrollbars autoHeight autoHeightMax={280} autoHide={false} ref={scrollerRef}>
      {
        <List
          role="menu"
          ref={listRef}
          onKeyDown={onKeydown}
          onKeyUp={onKeyUp}
          // if search is rendered, search input keeps focus and list doesn't need to be focusable.
          // without search list needs tabIndex=0 to prevent focus from jumping to the next element
          tabIndex={tabIndex}
          aria-activedescendant={tabIndex >= 0 ? focusedId : undefined}
        >
          {options.length > 0 ? (
            options.map((option) => (
              <SelectOption
                key={option.label}
                option={option}
                focused={focusedId === option.id}
                selected={selectedOption?.value === option.value}
                handleSelect={handleSelect}
                setFocusedId={setFocusedId}
                renderOption={renderOption}
              />
            ))
          ) : (
            <div className={cx('p-y-xs p-x text-secondary')}>
              {emptyLabel ?? getString(defaultEmptyLabel)}
            </div>
          )}
        </List>
      }
    </Scrollbars>
  )
}

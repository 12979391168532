import { ChannelType } from 'common/core/constants/ChannelType'

export enum FlowType {
  DEFAULT = 'default',
  MAIN_MENU = 'mainmenu',
}

export enum FlowStatus {
  ACTIVE = 'active',
  DELETED = 'deleted',
  PERMANENTLY_DELETED = 'permanently_deleted',
}

export enum FlowContentType {
  DEFAULT = 'default',
  EMAIL = 'email_new',
  CONDITION = 'multi_condition',
  SMART_DELAY = 'smart_delay',
  ACTION_GROUP = 'action_group',
  INSTAGRAM = 'instagram',
  SPLIT = 'split',
  GOTO = 'goto',
  SMS = 'sms',
  WHATSAPP = 'whatsapp',
  NOTE = 'note',
  MAIN_MENU = 'main_menu',
  TELEGRAM = 'telegram',
  AGENT = 'agent',

  // todo various legacy contents
  FORM = 'form',
  PERSISTENT_MENU = 'persistent_menu',
  OLD_CONDITION = 'conditional',
}

export enum ExportFlowStatuses {
  SUCCESS = 'SUCCESS',
  FAIL = 'FAIL',
  PROCESS = 'PROCESS',
}

export const FlowChannelTypeKeys: Partial<Record<ChannelType, string>> = {
  [ChannelType.FB]: 'has_fb_channel',
  [ChannelType.INSTAGRAM]: 'has_instagram_channel',
  [ChannelType.SMS]: 'has_sms_channel',
  [ChannelType.EMAIL]: 'has_email_channel',
  [ChannelType.WHATSAPP]: 'has_whatsapp_channel',
  [ChannelType.TELEGRAM]: 'has_telegram_channel',
  [ChannelType.TIKTOK]: 'has_tiktok_channel',
}

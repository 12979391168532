import dayjs from 'dayjs'
import { l } from '@manychat/manyui'

import { PauseAutomationsData } from 'apps/chat/constants/LiveChatInterfaces'
import { AutomationsStatus } from 'apps/chat/constants/LiveChatThreads'

export function getAutomationPauseStatus(
  automationData: PauseAutomationsData,
  fullPauseCaption?: boolean,
): {
  caption: string
  status: AutomationsStatus
} {
  const timeStamp = automationData.pausedUntil

  if (automationData.isPausedForever) {
    return {
      caption: l.translate('Paused Forever'),
      status: AutomationsStatus.PAUSED_FOREVER,
    }
  }

  if (timeStamp) {
    const resumeDate = dayjs(timeStamp * 1000)

    if (resumeDate.isAfter(dayjs())) {
      const duration = dayjs.duration(resumeDate.diff(dayjs()))
      return {
        caption: duration.format('HH:mm:ss'),
        status: AutomationsStatus.PAUSED,
      }
    }
  }

  return {
    caption: fullPauseCaption ? l.translate('Pause Automations') : l.translate('Pause'),
    status: AutomationsStatus.WORKING,
  }
}

export function getAutomationPauseEndCaption(timeStamp?: number | null) {
  if (!timeStamp) {
    return
  }

  const resumeDate = dayjs(timeStamp * 1000)
  const pauseUntilCaptionFormat = resumeDate.isAfter(dayjs(), 'day') ? 'D MMM HH:mm' : 'HH:mm'
  const pauseUntilCaption = resumeDate.format(pauseUntilCaptionFormat)

  return l.translate('Automations paused until {pauseUntilCaption}', { pauseUntilCaption })
}

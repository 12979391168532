import { createContext, useContext } from 'react'

import { BlockType } from '../constants'
import { registry, Registry } from '../registry'
import { AnyBlock } from '../types'

export const RegistryContext = createContext(registry)

export function useRegistry(): Registry

export function useRegistry<Type extends BlockType>(type: Type): AnyBlock

export function useRegistry<Type extends BlockType>(type?: Type) {
  const registry = useContext(RegistryContext)
  if (type) {
    return registry.resolve(type)
  }

  return registry
}

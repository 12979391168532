import React from 'react'
import cx from 'classnames'
import DOMPurify from 'dompurify'
import { v4 as uuid } from 'uuid'

import cm from './renderHelper.module.css'

const TestIdLabelOfText = 'content-text'
const TestIdLabelOfLink = 'content-link'

export type RowContent = Array<{ text: string; link: never } | { link: string; text: never }>

export const getSerializationData = (
  textData: string | RowContent[],
  hasFBPolicyLinkStyle?: boolean,
): React.ReactElement | React.ReactElement[] => {
  if (!Array.isArray(textData) || !textData) {
    const keyBase = textData ?? ''
    const key = keyBase.charCodeAt(0)
    const id = uuid()
    const uniqueId = `${TestIdLabelOfText}-${key}-${id}`
    return <span data-test-id={uniqueId}>{textData}</span>
  }

  return textData.reduce((prev, cur, index, array) => {
    if (!cur.length) {
      return prev
    }

    const isLastElement = index === array.length - 1

    cur.forEach(({ text, link }) => {
      const hasLink = Boolean(link)
      const keyBase = link ?? text
      const key = keyBase.charCodeAt(0)
      const id = uuid()
      const labelOfId = hasLink ? TestIdLabelOfLink : TestIdLabelOfText
      const uniqueId = `${labelOfId}-${key}-${id}`
      const content = (
        <span key={uniqueId}>
          {hasLink ? (
            <a
              data-test-id={uniqueId}
              target="_blank"
              className={cx(cm.link, { [cm.FBPolicyLinkStyle]: hasFBPolicyLinkStyle })}
              href={DOMPurify.sanitize(link)}
              rel="noreferrer"
            >
              {link}
            </a>
          ) : (
            <span data-test-id={uniqueId}>{text}</span>
          )}
          {!isLastElement && (
            <>
              {' '}
              <br />
            </>
          )}
        </span>
      )
      prev.push(content)
    })
    return prev
  }, [] as React.ReactElement[])
}

import { BusinessError } from 'shared/api/lib/errors/business'

import { CardErrorSchema } from '../schemas/errors'

export interface CardError extends BusinessError {
  field: 'card_error'
}

export const isCardError = (error: unknown): error is CardError =>
  CardErrorSchema.safeParse(error).success

import { createAction } from '@reduxjs/toolkit'

import { settingsApi } from 'shared/api/requests/settings'
import { ExperimentGroupName } from 'shared/api/requests/settings/experiment/schemas'
import { createAsyncAction } from 'shared/lib/redux'
import { analyticsService } from 'utils/services/analytics'
import { AccountFlag } from 'utils/services/featureFlagsService/constants'

import {
  BillingExperiment,
  experimentFlagMap,
  ExperimentFlag,
} from '../constants/BillingExperiment'
import { getExperimentGroup } from '../helpers/experiment'
import {
  getExperimentConditionsMet,
  getIsAlreadyInAnyExperiment,
} from '../selectors/billingSelectors'

const experimentGroupNameMap = new Map<ExperimentFlag, ExperimentGroupName>([
  // upgrade page
  [AccountFlag.UPGRADE_PAGE_GROUP_A, ExperimentGroupName.UPGRADE_PAGE_A],
  [AccountFlag.UPGRADE_PAGE_GROUP_B, ExperimentGroupName.UPGRADE_PAGE_B],

  // tier notification
  [AccountFlag.TIER_NOTIFICATION_ENABLE, ExperimentGroupName.TIER_LIMIT_NOTIFICATION_A],
  [AccountFlag.TIER_NOTIFICATION_DISABLE, ExperimentGroupName.TIER_LIMIT_NOTIFICATION_B],
])

export const getExperimentGroupName = (flag: ExperimentFlag): ExperimentGroupName => {
  const groupName = experimentGroupNameMap.get(flag)
  if (!groupName) {
    throw new Error(`No experiment group found for flag: ${flag}`)
  }
  return groupName
}

export const setupExperiment = createAsyncAction(
  'billing/setupExperiment',
  async (experiment: BillingExperiment, { dispatch }) => {
    const [flagA, flagB] = experimentFlagMap[experiment]
    const group = getExperimentGroup()
    const flagToEnable = group ? flagA : flagB

    dispatch(setExperiment(flagToEnable))
    analyticsService.sendEvent('BILLING.EXPERIMENT.ENROLL', {
      group: flagToEnable,
    })

    const experimentGroupName = getExperimentGroupName(flagToEnable)
    const response = await settingsApi.experiment.enroll({
      path: { experimentGroupName },
    })

    return response.data
  },
  {
    condition: (experiment, { getState }) => {
      const alreadyInAnyExperiment = getIsAlreadyInAnyExperiment(getState())
      const conditionsMet = getExperimentConditionsMet(getState())[experiment]

      return !alreadyInAnyExperiment && conditionsMet
    },
  },
)

export const setExperiment = createAction<ExperimentFlag>('billing/setExperiment')

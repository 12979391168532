import { z } from 'zod'

export const EmailSettingSchema = z.object({
  email_settings: z.object({
    address: z.string(),
    company_name: z.string(),
    show_email_branding: z.boolean(),
    sender_name: z.string().nullable(),
    reply_to: z.string().nullable(),
  }),
})

import assert from 'assert'

import has from 'lodash/has'
import without from 'lodash/without'

import blockRegistry from 'common/builder/blockRegistry'
import { AttachmentBlockAttachmentType } from 'common/builder/blocks/blockConstants'
import { BlockType } from 'common/builder/constants/BlockType'
import { UPDATE_BLOCK } from 'common/builder/constants/builderReduxActionTypes'
import BlockModel from 'common/builder/models/Block'
import { getBlockById } from 'common/builder/selectors/builder/entitySelectors'
import { uploadAttachment } from 'common/core/actions/attachmentActions'
import { isFilterContainSmartSegment } from 'common/filter/models/AudienceFilter/helpers'
import { analyticsService } from 'utils/services/analytics'
import errorTrackingService from 'utils/services/errorTrackingService'
import { alertWithHotjarEventFactory } from 'utils/services/hotjar'

const alertWithHotjarEvent = alertWithHotjarEventFactory('flow_builder_error')

/**
 * @param builderId
 * @param blockId
 * @param changes
 */
export const updateBlock = (builderId, blockId, changes) => {
  return (dispatch, getState) => {
    assert(builderId, `updateBlock: builderId is required param`)
    assert(blockId, `updateBlock: blockId is required param`)
    assert(!has(changes, 'id'), `updateBlock: attribute "id" cannot be changed`)
    assert(!has(changes, 'targetId'), `updateBlock: attribute "targetId" cannot be changed`)
    // assert(!has(changes, 'type'), `updateBlock: attribute "type" cannot be changed`)
    // todo check values for attachmentType, topElementStyle

    // Attachment upload
    const attachmentProps = Object.keys(changes).filter(
      (prop) =>
        BlockModel.BlockUploadProps.includes(prop) &&
        changes[prop] != null &&
        changes[prop].type !== AttachmentBlockAttachmentType.EXTERNAL_IMAGE,
    )

    let hasAttachmentError = false

    const { attachmentDestination } = changes
    delete changes['attachmentDestination']

    attachmentProps.forEach((prop) => {
      const file = changes[prop]

      if (file.img_big) {
        // file object contain img_big in case when file uploaded from the state
        // for example when recurring topics chosen in recurring notification block
        // in that case upload to the server is not needed
        return
      }
      try {
        const { uploadId } = dispatch(
          uploadAttachment(file, { builderId, blockId, attachmentDestination }),
        )
        file.uploadId = uploadId
      } catch (e) {
        hasAttachmentError = true
        return alertWithHotjarEvent(e.message, 'danger')
      }

      const block = getBlockById(getState(), builderId, blockId)
      const { type } = file
      if (type && block.type === BlockType.ATTACHMENT) {
        const attachmentType = type.split('/')[0]
        switch (attachmentType) {
          case AttachmentBlockAttachmentType.AUDIO:
            changes.attachmentType = attachmentType
            break
          case AttachmentBlockAttachmentType.IMAGE:
            changes.attachmentType = attachmentType
            break
          case AttachmentBlockAttachmentType.VIDEO:
            changes.attachmentType = attachmentType
            break
          default:
            changes.attachmentType = AttachmentBlockAttachmentType.FILE
        }
      }
    })

    if (hasAttachmentError) {
      return
    }

    const hasSmartSegmentInCondition = isFilterContainSmartSegment(changes.filter)

    if (hasSmartSegmentInCondition) {
      analyticsService.sendEvent('SMART.SEGMENT.CONDITION.FLOW')
    }

    const block = getBlockById(getState(), builderId, blockId)
    const knownAttributes = blockRegistry.get(block).getAttributes()
    const changesAttributes = Object.keys(changes)
    const unknownAttributes = without(changesAttributes, ...knownAttributes)
    if (unknownAttributes.length) {
      const attrs = unknownAttributes.join()
      const payload = { attrs, type: block.type }
      const errorId = 'block-update-unknown-attrs'
      errorTrackingService.trackWarningOnce('updateBlock: unknown attrs', {
        extra: payload,
        fingerprint: errorId,
      })
      console.warn(`updateBlock: attribute "${attrs}" isn't listed in BlockConfig defaults`)
    }
    dispatch({
      type: UPDATE_BLOCK,
      builderId,
      blockId,
      changes,
    })
  }
}

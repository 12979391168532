import React, { useCallback, useRef, useState } from 'react'
import { useAppDispatch } from 'reduxTyped'
import { Modal, l, TextInputV2 } from '@manychat/manyui'

import * as profileActions from 'apps/profile/actions/profileTypedActions'

import { MyProfilePopoverAnalytics } from '../../lib/analytics'

interface EditEmailModalProps {
  isOpen: boolean
  onClose: () => void
  onUpdate: () => void
  email: string
}

export const EditEmailModal = ({ isOpen, onClose, email, onUpdate }: EditEmailModalProps) => {
  const dispatch = useAppDispatch()
  const [isLoading, setIsLoading] = useState(false)
  const [inputEmail, setInputEmail] = useState(email)
  const inputRef = useRef<HTMLInputElement>(null)

  const handleEmailChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    setInputEmail(event.target.value)
  }, [])

  const handleUpdateEmail = useCallback(async () => {
    if (isLoading) return

    try {
      MyProfilePopoverAnalytics.logUpdateEmailClick()
      setIsLoading(true)

      const isSuccess = await dispatch(profileActions.sendEmailVerification(inputEmail))

      if (isSuccess) {
        onUpdate()
        onClose()
      } else {
        inputRef.current?.focus()
      }

      setIsLoading(false)
    } catch (err) {
      setIsLoading(false)
      inputRef.current?.focus()
    }
  }, [dispatch, inputEmail, isLoading, onClose, onUpdate])

  const handleCloseModal = useCallback(() => {
    setInputEmail(email)
    onClose()
  }, [onClose, email])

  const handleKeyPress = useCallback(
    (event: React.KeyboardEvent<HTMLInputElement>) => {
      if (event.key === 'Enter') {
        handleUpdateEmail()
      }
    },
    [handleUpdateEmail],
  )

  const buttons = [
    {
      label: l.translate('Cancel'),
      onClick: handleCloseModal,
    },
    {
      label: l.translate('Update'),
      onClick: handleUpdateEmail,
      variant: 'primary',
      loading: isLoading,
      disabled: isLoading || inputEmail === email || !inputEmail,
    },
  ]

  return (
    <Modal
      width={460}
      onRequestClose={handleCloseModal}
      buttons={buttons}
      open={isOpen}
      title={l.translate('Edit email')}
    >
      <div className="p-y-md p-x-lg">
        <TextInputV2
          labelId="email-address-label"
          label={l.translate('Your email address')}
          autoFocus={isOpen}
          value={inputEmail}
          onChange={handleEmailChange}
          disabled={isLoading}
          placeholder={email}
          onKeyPress={handleKeyPress}
          inputRef={inputRef}
          helpText={l.translate('We will send you a verification code to this email address')}
        />
      </div>
    </Modal>
  )
}

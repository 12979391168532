import { createSlice, PayloadAction } from '@reduxjs/toolkit'

export enum ModalSource {
  PUBLISH = 'PUBLISH',
  SCHEDULE = 'SCHEDULE',
  SKIP_REFILL = 'SKIP_REFILL',
  SUCCESS_REFILL = 'SUCCESS_REFILL',
}
export interface ModalsState {
  isConfirmOpen: boolean
  isScheduleOpen: boolean
  modalSource: ModalSource | null
  previousModalSource: ModalSource | null
}

const initialState: ModalsState = {
  isConfirmOpen: false,
  isScheduleOpen: false,
  modalSource: null,
  previousModalSource: null,
}

const modalsSlice = createSlice({
  name: 'broadcastModals',
  initialState,
  reducers: {
    openConfirmModal: (state) => {
      state.isConfirmOpen = true
    },
    closeConfirmModal: (state) => {
      state.isConfirmOpen = false
    },
    openScheduleModal: (state) => {
      state.isScheduleOpen = true
    },
    closeScheduleModal: (state) => {
      state.isScheduleOpen = false
    },

    setModalSource: (state, action: PayloadAction<ModalSource>) => {
      state.previousModalSource = state.modalSource
      state.modalSource = action.payload
    },
  },
})

export const {
  openConfirmModal,
  closeConfirmModal,
  openScheduleModal,
  closeScheduleModal,
  setModalSource,
} = modalsSlice.actions

const modalsReducer = modalsSlice.reducer

export default modalsReducer

const cookieEnabled = isCookieEnabled()

export function setCookie(name, value, days) {
  if (!cookieEnabled) {
    return
  }
  let expires = ''
  if (days) {
    let date = new Date()
    date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000)
    expires = '; expires=' + date.toUTCString()
  }
  document.cookie = name + '=' + (value || '') + expires + '; path=/'
}

export function getCookie(name) {
  if (!cookieEnabled) {
    return null
  }
  let nameEQ = name + '='
  let ca = document.cookie.split(';')
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i]
    while (c.charAt(0) === ' ') {
      c = c.substring(1, c.length)
    }
    if (c.indexOf(nameEQ) === 0) {
      return c.substring(nameEQ.length, c.length)
    }
  }
  return null
}

export function isCookieEnabled() {
  // https://github.com/Modernizr/Modernizr/blob/master/feature-detects/cookies.js
  try {
    // Create cookie
    document.cookie = 'cookietest=1'
    const ret = document.cookie.indexOf('cookietest=') !== -1
    // Delete cookie
    document.cookie = 'cookietest=1; expires=Thu, 01-Jan-1970 00:00:01 GMT'
    return ret
  } catch (e) {
    return false
  }
}

import React from 'react'
import { Icon, l } from '@manychat/manyui'

import { useFlexibleTriggerNameClassName } from 'apps/cms/components/Flows/components/FlowCard/components/Triggers/hooks/useFlexibleTriggerNameClassName'
import { renderTextByNodes } from 'apps/cms/components/Flows/components/FlowCard/components/Triggers/lib/renderTextByNodes'

export const RefUrlTitle = () => {
  const className = useFlexibleTriggerNameClassName()

  return (
    <span className={className}>
      {renderTextByNodes(l.translate('User clicks<l10n context="on referral link"/>'))}

      <Icon.Link size={16} />

      {renderTextByNodes(l.translate('<l10n context="User clicks"/>on referral link'))}
    </span>
  )
}

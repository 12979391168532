import sortBy from 'lodash/sortBy'

import { IVariantBlock } from 'common/builder/blocks/blockInterfaces'

import * as entitySelectors from './entitySelectors'

export const getVariantsSorted = (state: RootState, builderId: string, nodeId: string) => {
  const variants = entitySelectors.getBlocks(state, builderId, nodeId) as IVariantBlock[]
  return sortBy(variants, (variant) => variant.title)
}

import { l } from '@manychat/manyui'

import { UpgradeSource } from 'common/billing'
import { handleGoProError } from 'common/billing/helpers/handleGoProError'
import { alert } from 'common/core'
import { settingsApi } from 'shared/api/requests/settings'
import { createAsyncAction } from 'shared/lib/redux'
import { analyticsService } from 'utils/services/analytics'

export const switchEmailChannel = createAsyncAction(
  'settings/switchEmailChannel',
  async (value: boolean) => {
    const response = await settingsApi.email.switchEmailChannel({
      body: { value },
    })

    analyticsService.sendEvent(response.data ? 'SETTINGS.EMAIL_ENABLED' : 'SETTINGS.EMAIL_DISABLED')

    document.location.reload()

    return response.data
  },
  {
    onBusinessError: (error, _, { dispatch }) => {
      if (settingsApi.email.switchEmailChannel.isGoProError(error)) {
        error.handle()
        if (error.error_data.go_pro) {
          dispatch(
            handleGoProError(error, {
              products: error.error_data.products,
              source: UpgradeSource.EMAIL,
              message: l.translate(
                "You aren't able to connect Email channel on the current plan. Upgrade for unlimited access.",
              ),
              modalTitle: l.translate(
                `Unlock the ability to connect Email channel with Manychat Pro`,
              ),
            }),
          )
        }
      }
    },
  },
)

export const setEmailSettings = createAsyncAction(
  'settings/setEmailSettings',
  async (payload: { company_name: string; address: string }) => {
    const response = await settingsApi.email.setEmailSettings({
      body: { company_name: payload.company_name, address: payload.address },
    })

    return response.data
  },
  {
    onBusinessError: (error, _, { dispatch }) => {
      if (settingsApi.email.setEmailSettings.isGoProError(error)) {
        error.handle()
        if (error.error_data.go_pro) {
          dispatch(
            handleGoProError(error, {
              message: error.message,
              modalTitle: undefined,
              source: UpgradeSource.EMAIL,
              products: error.error_data.products,
            }),
          )
        }
      }

      if (settingsApi.email.setEmailSettings.isAddressBlankError(error)) {
        error.handle()
        alert(error?.message, 'danger')
      }
    },
  },
)

export const showEmailBranding = createAsyncAction(
  'settings/showEmailBranding',
  async (payload: { value: boolean }) => {
    const response = await settingsApi.email.showEmailBranding({
      body: { value: payload.value },
    })

    return response.data
  },
  {
    onBusinessError: (error, _, { dispatch }) => {
      if (settingsApi.email.showEmailBranding.isGoProError(error)) {
        error.handle()
        if (error.error_data.go_pro) {
          dispatch(
            handleGoProError(error, {
              source: UpgradeSource.EMAIL,
              products: error.error_data.products,
              modalTitle: l.translate(
                'Unlock the ability to disable Branding in  with Manychat Pro',
              ),
              message: l.translate(
                "You aren't able to disable branding on the current plan. Upgrade for unlimited access.",
              ),
            }),
          )
        }
      }
    },
  },
)

export const ApplicationsWithIdScopeRoutes = [
  {
    path: 'apps/:app_id',
    alias: 'applicationSettings',
    lazy: () => import('./applicationSettings.page'),
  },
] as const

export const ApplicationsRoutes = [
  {
    path: 'applications',
    children: [
      {
        path: 'create/:page_id?',
        lazy: () => import('./createApplication.page'),
      },
      {
        path: ':application_id/update',
        lazy: () => import('./updateApplication.page'),
      },
    ],
  },
] as const

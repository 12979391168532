import { z } from 'zod'

import { AddOnSlug } from 'common/billing/interfaces/addOnTypes'
import { ProProducts } from 'common/core/interfaces/products'

export const BusinessErrorSchema = z.object({
  field: z.string().optional(),
  message: z.string(),
  original_message: z.string(),
  error_data: z.record(z.string(), z.unknown()).optional(),
})

export const CardErrorSchema = BusinessErrorSchema.extend({
  field: z.literal('card_error'),
})

export const ProErrorSchema = BusinessErrorSchema.extend({
  field: z.literal('pro'),
  error_data: z.object({
    go_pro: z.boolean(),
    products: z.enum(ProProducts).array().optional(),
    addons: z.nativeEnum(AddOnSlug).array().optional(),
    reason: z.string().optional(),
  }),
})

export const KeywordsLimitErrorSchema = ProErrorSchema.extend({
  error_data: z.object({
    go_pro: z.boolean(),
    products: z.enum(ProProducts).array().optional(),
    addons: z.nativeEnum(AddOnSlug).array().optional(),
    reason: z.literal('keywords_limit_reached'),
  }),
})

export const BillingCommonErrorSchema = BusinessErrorSchema.extend({
  field: z.literal('billing_error'),
})

export const BillingAccessErrorSchema = BusinessErrorSchema.extend({
  field: z.literal('billing_access'),
})

export const EmailAddressErrorSchema = BusinessErrorSchema.extend({
  field: z.literal('address'),
})

export const PayPalErrorSchema = BusinessErrorSchema.extend({
  field: z.literal('paypal'),
})

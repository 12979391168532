import React, { ReactNode } from 'react'
import cx from 'classnames'

import cm from './Message.module.css'

export interface OutgoingMessageProps {
  variant: 'outgoing'
  children: ReactNode
}

export function OutgoingMessage(props: Omit<OutgoingMessageProps, 'variant'>) {
  return (
    <div className={cx(cm.message, cm.outgoing)} data-test-id="outgoing-message">
      {props.children}
    </div>
  )
}

import { api } from 'shared/api'

import { CartReplyApi } from './schemas'

export const cartReplyApi = {
  getData: api.account.createGet({
    url: '/cartReply/getData',
    schemas: CartReplyApi.getData,
  }),
  switch: api.account.createGet({
    url: '/cartReply/switch',
    schemas: CartReplyApi.switch,
  }),
  changeType: api.account.createPost({
    url: '/cartReply/changeType',
    schemas: CartReplyApi.changeType,
  }),
  replaceFlow: api.account.createPost({
    url: '/cartReply/replaceFlow',
    schemas: CartReplyApi.replaceFlow,
  }),
  removeFlow: api.account.createPost({
    url: '/cartReply/removeFlow',
    schemas: CartReplyApi.removeFlow,
  }),
}

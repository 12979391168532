import { createAction } from '@reduxjs/toolkit'
import { createAppAsyncThunk } from 'reduxTyped'
import { createSelector } from 'reselect'
import { l } from '@manychat/manyui'

import { getPathItems } from 'apps/cms/lib/query'
import { FsValidationMessage } from 'apps/cms/lib/validation'
import { namespace, ContentManagementSystemSocketEvents } from 'apps/cms/store/lib/constants'
import { isFsFolderObject } from 'apps/cms/store/lib/helper'
import { getFoldersByPath } from 'apps/cms/store/lib/lib'
import { cmsQuerySelectors, cmsQueryActions } from 'apps/cms/store/query'
import { FSObjectType } from 'common/cms/models/FSObject/constants'
import { alert } from 'common/core'
import { handleCatch } from 'shared/api/lib/errors/handlers'
import { applyHandlerByGuard, reportUnhandledErrors } from 'shared/api/lib/errors/utils'
import { CmsApi } from 'shared/api/requests/cms'
import { CmsApiGuard } from 'shared/api/requests/cms/guards'
import { FsFolderObject } from 'shared/api/requests/cms/schemas'
import { getClientId } from 'utils/clientId'
import { anotherTabNotificationsListener } from 'utils/services/notificationsService'

const insertFolder = createAction<FsFolderObject>(`${namespace}/insertFolder`)

const deleteFolderWithNestedContent = createAction<{ folderPath: string }>(
  `${namespace}/deleteFolderWithNestedContent`,
)

const rewriteFolder = createAction<FsFolderObject>(`${namespace}/rewriteFolder`)

const deleteFolderById = createAction<number>(`${namespace}/deleteFolderById`)

const moveFolderWithNestedContent = createAction<FsFolderObject>(
  `${namespace}/moveFolderWithNestedContent`,
)

const fetchFolders = createAppAsyncThunk(`${namespace}/fetchFolders`, async () => {
  const response = await CmsApi.getFolders()

  return response.data.folders
})

const createFolder = createAppAsyncThunk(
  `${namespace}/createFolder`,
  async ({ path, title }: { path: string; title: string }) => {
    try {
      const response = await CmsApi.createFolder({
        query: { client_id: getClientId() },
        body: { path, title },
      })

      alert(l.translate('Folder created'), 'success')

      return response.data.fs_object
    } catch (error) {
      handleCatch(error)
      return null
    }
  },
)

const deleteFolder = createAppAsyncThunk(
  `${namespace}/deleteFolder`,
  async ({ path }: { path: string }, { dispatch }) => {
    try {
      await CmsApi.delete({
        query: { path, client_id: getClientId(), delete_content: true },
      })

      dispatch(deleteFolderWithNestedContent({ folderPath: path }))
    } catch (error) {
      handleCatch(error, (responseError) => {
        const unhandledErrors = applyHandlerByGuard(
          responseError.$errors,
          CmsApiGuard.delete.isUndeletableContentError,
          (error) => {
            alert(error.original_message, 'danger')
          },
        )

        reportUnhandledErrors(unhandledErrors, responseError.endpoint)
      })
    }
  },
)

const renameFolder = createAppAsyncThunk(
  `${namespace}/renameFolder`,
  async ({ path, title }: { path: string; title: string }) => {
    try {
      const response = await CmsApi.renameObject({
        body: { path, title, client_id: getClientId() },
      })

      alert(l.translate('Folder renamed'), 'success')

      if (response.data.fs_object.type !== FSObjectType.FOLDER) {
        return
      }

      return response.data.fs_object
    } catch (error) {
      handleCatch(error)
      return null
    }
  },
)

const moveFolder = createAppAsyncThunk(
  `${namespace}/moveFolder`,
  async (
    {
      path,
      pathToMove,
      customSuccessAlert,
    }: {
      path: string
      pathToMove: string
      customSuccessAlert?: (text: string, path: string) => void
    },
    { getState, dispatch },
  ) => {
    const state = getState()
    const sourceFolder = cmsFoldersSelectors.getFolderByPath(state, path)

    const childrenFolders = cmsFoldersSelectors.getFoldersByPath(state, pathToMove)

    const childrenFolderWithSameName = childrenFolders.find(
      (folder) => folder.title === sourceFolder?.title,
    )

    if (childrenFolderWithSameName) {
      alert(FsValidationMessage.getTargetFolderHasFolderWithThisNameMessage(), 'danger')
      return
    }

    try {
      const response = await CmsApi.move({
        query: {
          path,
          to: pathToMove,
          client_id: getClientId(),
        },
      })

      if (!isFsFolderObject(response.data.fs_object)) {
        return
      }

      dispatch(moveFolderWithNestedContent(response.data.fs_object))

      const alertMessage = l.translate('Folder moved')

      if (customSuccessAlert) {
        customSuccessAlert(alertMessage, pathToMove)
      } else {
        alert(alertMessage, 'success')
      }
    } catch (error) {
      handleCatch(error)
    }
  },
)

const cloneFolder = createAppAsyncThunk(`${namespace}/cloneFolder`, async (path: string) => {
  try {
    const { data } = await CmsApi.clone({
      query: {
        path,
      },
    })

    if (!isFsFolderObject(data.fs_object)) return null

    alert(l.translate('Folder duplicated'), 'success')

    return data.fs_object
  } catch (error) {
    handleCatch(error)
    return null
  }
})

export const cmsFoldersActions = {
  insertFolder,
  deleteFolderWithNestedContent,
  rewriteFolder,
  deleteFolderById,
  moveFolderWithNestedContent,

  fetchFolders,
  createFolder,
  deleteFolder,
  renameFolder,
  cloneFolder,
  moveFolder,
}

const getState = (state: RootState) => state.contentManagementSystem.folders

const getFoldersState = (state: RootState) => getState(state)

const getFoldersByCurrentQueryPath = createSelector(
  [(state: RootState) => getState(state).list, cmsQuerySelectors.getQueryPath],
  (foldersList, path): FsFolderObject[] => getFoldersByPath(foldersList, path),
)

export const cmsFoldersSelectors = {
  getFoldersState,

  getFolders: (state: RootState) => getState(state).list,

  getFolderPathsByCurrentQueryPath: createSelector([getFoldersByCurrentQueryPath], (folders) =>
    folders.map((folder) => folder.path),
  ),

  getFoldersByCurrentQueryPath,

  getFoldersByPath: createSelector(
    [(state: RootState) => getState(state).list, (_: RootState, path: string) => path],
    (foldersList, path) => getFoldersByPath(foldersList, path),
  ),

  getFolderByPath: createSelector(
    [(state: RootState) => getState(state).list, (_state: RootState, path: string | null) => path],
    (foldersList, path): FsFolderObject | undefined | null => {
      if (!path) return null
      return foldersList.find((folder) => folder.path === path)
    },
  ),

  getFolderById: createSelector(
    [(state: RootState) => getState(state).list, (_state: RootState, folderId: number) => folderId],
    (foldersList, folderId): FsFolderObject | undefined => {
      return foldersList.find((folder) => folder.folder_id === folderId)
    },
  ),
}

const CMS_FOLDER_ENTITY = 10

anotherTabNotificationsListener.on(
  ContentManagementSystemSocketEvents.FOLDER_CREATED,
  (data: { folder: FsFolderObject }, dispatch, getState) => {
    const state = getState() as RootState
    const { isLoaded } = cmsFoldersSelectors.getFoldersState(state)

    if (!isLoaded || data.folder.entity !== CMS_FOLDER_ENTITY) return

    dispatch(insertFolder(data.folder))
  },
)

anotherTabNotificationsListener.on(
  ContentManagementSystemSocketEvents.FOLDER_DELETED,
  (data: { folder: FsFolderObject }, dispatch, getState) => {
    const state = getState() as RootState
    const { isLoaded } = cmsFoldersSelectors.getFoldersState(state)

    if (!isLoaded || data.folder.entity !== CMS_FOLDER_ENTITY) return

    dispatch(deleteFolderById(data.folder.folder_id))

    const pathSegments = getPathItems(cmsQuerySelectors.getQueryPath(state))

    if (pathSegments.length === 0) return

    const segmentIndex = pathSegments.findIndex(
      (pathSegment) => Number(pathSegment) === data.folder.folder_id,
    )

    if (segmentIndex === -1) return
    if (segmentIndex === 0) {
      dispatch(cmsQueryActions.updateQuery({ path: '/' }))
    } else {
      dispatch(
        cmsQueryActions.updateQuery({
          path: `/${pathSegments.slice(0, segmentIndex).join('/')}`,
        }),
      )
    }

    alert(
      l.translate(
        'You were redirected to the parent folder because the folder you had opened was deleted.',
      ),
      'success',
    )
  },
)

anotherTabNotificationsListener.on(
  ContentManagementSystemSocketEvents.FOLDER_RENAMED,
  (data: { folder: FsFolderObject }, dispatch, getState) => {
    const state = getState() as RootState
    const { isLoaded } = cmsFoldersSelectors.getFoldersState(state)

    if (!isLoaded || data.folder.entity !== CMS_FOLDER_ENTITY) return

    dispatch(rewriteFolder(data.folder))
  },
)

anotherTabNotificationsListener.on(
  ContentManagementSystemSocketEvents.FOLDER_MOVED,
  (data: { folder: FsFolderObject }, dispatch, getState) => {
    const state = getState() as RootState
    const { isLoaded } = cmsFoldersSelectors.getFoldersState(state)

    if (!isLoaded || data.folder.entity !== CMS_FOLDER_ENTITY) return

    const queryPath = cmsQuerySelectors.getQueryPath(state)
    const movedFolderOldPath = cmsFoldersSelectors.getFolderById(state, data.folder.folder_id)?.path

    if (movedFolderOldPath && queryPath.includes(movedFolderOldPath)) {
      dispatch(
        cmsQueryActions.updateQuery({
          path: queryPath.replace(movedFolderOldPath, data.folder.path),
        }),
      )
    }

    dispatch(moveFolderWithNestedContent(data.folder))
  },
)

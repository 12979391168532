import { GuardNameError, ErrorsSchema } from '../types'

export const getGuardName = <S extends string>(name: S): GuardNameError<S> =>
  `is${name[0].toUpperCase()}${name.slice(1)}` as GuardNameError<S>

export const getGuardsByErrors = <T extends ErrorsSchema>(errors?: T) => {
  return Object.fromEntries(
    Object.entries(errors ?? {}).map(([name, schema]) => [
      getGuardName(name),
      (val: unknown) => schema.safeParse(val).success,
    ]),
  )
}

import { DeepPartial } from 'utility-types'
import { v4 as uuid } from 'uuid'

import { MappedIceBreaker, MappedIceBreakerFlow } from 'apps/iceBreakers/constants/constants'
import { ChannelType } from 'common/core/constants/ChannelType'
import { IceBreakerStatus, IceBreakersResponse } from 'shared/api/requests/iceBreakers/schemas'

export const createIceBreaker = (
  data: MappedIceBreaker | DeepPartial<MappedIceBreaker>,
  sortId: number,
  isValidated = false,
): MappedIceBreaker => {
  const flow = data.flow as MappedIceBreakerFlow

  if (flow) {
    flow.id = flow.ns
  }

  return {
    id: data.id || uuid(),
    icebreaker_id: data.icebreaker_id || null,
    caption: data.caption || '',
    sort_id: sortId,
    status: data.status || IceBreakerStatus.ACTIVE,
    flow,
    triggered: data.triggered || 0,
    channel: data.channel || ChannelType.FB,
    template_installation_id: data.template_installation_id ?? null,
    chns: data.chns ?? '',
    isValidated,
  }
}

export const parseItems = (data: IceBreakersResponse): readonly MappedIceBreaker[] => {
  const rawItems = data.ice_breakers

  if (Array.isArray(rawItems)) {
    return rawItems.map((item, index) => createIceBreaker(item, index, true))
  }

  return []
}

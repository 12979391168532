import assert from 'assert'

import each from 'lodash/each'
import first from 'lodash/first'
import has from 'lodash/has'
import intersection from 'lodash/intersection'
import isUndefined from 'lodash/isUndefined'
import values from 'lodash/values'
import without from 'lodash/without'
import { l } from '@manychat/manyui'

import { isMobile } from 'utils'
import { SimpleTrialPlaceType } from 'apps/cms/lib/constants'
import { ProActionTypes, ActionTypes } from 'common/actions'
import { getActionTypeName } from 'common/actions/models/Action/formatters'
import { billing, UpgradeSource } from 'common/billing'
import { builderCreateNode } from 'common/builder/actions/builderCreateNode'
import { deleteNode } from 'common/builder/actions/deleteNode'
import { mergeParsed } from 'common/builder/actions/mergeParsed'
import { openNode, open } from 'common/builder/actions/openNode'
import { setSelection } from 'common/builder/actions/selections'
import { setBusy } from 'common/builder/actions/setBusy'
import { updateBuilderState } from 'common/builder/actions/updateBuilderState'
import flowChartApp from 'common/builder/components/chart/FlowChart/app'
import { BlockType } from 'common/builder/constants/BlockType'
import * as ReduxActionTypes from 'common/builder/constants/builderReduxActionTypes'
import { ButtonType } from 'common/builder/constants/ButtonType'
import { getBaseNamespace, Namespace } from 'common/builder/constants/Namespace'
import { NodeType } from 'common/builder/constants/NodeType'
import * as emailBuilderActions from 'common/builder/emailBuilder/actions/emailBuilderActions'
import * as emailBuilderSelectors from 'common/builder/emailBuilder/selectors/emailBuilderSelectors'
import { ProBlockTypes, BlockTypeLabels } from 'common/builder/models/Block/constants'
import BuilderStateModel from 'common/builder/models/BuilderState'
import { NextStepTypeNames } from 'common/builder/models/Source/constants'
import nodeRegistry from 'common/builder/nodeRegistry'
import builderSelectors from 'common/builder/selectors/builder'
import { getBuilderRequiredAbilities } from 'common/builder/selectors/builder/builderStateSelectorsTyped'
import * as entitySelectors from 'common/builder/selectors/builder/entitySelectors'
import * as nodeSelectors from 'common/builder/selectors/builder/nodeSelectors'
import { sendPublishErrorToAnalytics } from 'common/builder/utils/sendPublishErrorToAnalytics'
import { alert } from 'common/core'
import { ProductType } from 'common/core/interfaces/products'
import { getUnavailableAbilities } from 'common/core/selectors/abilitiesSelectors'
import { getCurrentAccount } from 'common/core/selectors/appSelectors'
import { isEmailChannelBanned } from 'common/core/selectors/channelSelectors'
import { handleGoProExperiment } from 'common/goProExperimentHandlers'
import { alertWithHotjarEventFactory } from 'utils/services/hotjar'

import * as builderNodeActions from './builderNodeActions'
import * as builderStateActions from './builderStateActions'

const alertWithHotjarEvent = alertWithHotjarEventFactory('flow_builder_error')

/**
 * Builder Configuration
 * @typedef {object} BuilderConfig
 * @property {boolean} disableRootContentPersonalization
 */

/**
 * Builder initialization options
 * @typedef {object} BuilderOptions
 * @property {string} builderId
 * @property {string} rootId
 * @property {Parsed} initialData
 * @property {object} coordinates
 * @property {bool} readOnly
 * @property {bool} loadStats
 * @property {string} flowId
 * @property {string} chId
 * @property {string} defaultCaption
 * @property {string} createInitialRootNodeType
 * @property {string} preventOpenInitializedRootNode
 * @property {bool} ensureRootContentExists
 * @property {BuilderConfig} config
 */

/**
 * Creates a new builderState
 *
 * @param {BuilderOptions} options
 */
export function createBuilderState(options = {}) {
  return (dispatch, getState) => {
    const builderState = BuilderStateModel.create()

    for (let optionKey of Object.keys(options)) {
      if (Object.prototype.hasOwnProperty.call(builderState, optionKey)) {
        if (builderState[optionKey] !== null && typeof builderState[optionKey] === 'object') {
          Object.assign(builderState[optionKey], options[optionKey])
        } else {
          builderState[optionKey] = options[optionKey]
        }
      }
    }

    if (options.builderId) {
      builderState.id = options.builderId
    }
    if (options.flowId) {
      builderState.flow = options.flowId
    }
    if (options.config) {
      builderState.config = { ...builderState.config, ...options.config }
    }

    builderState.quick_campaign_data = options.quick_campaign_data

    const builderId = builderState.id
    dispatch({
      type: ReduxActionTypes.CREATE_BUILDER_STATE,
      builderId,
      item: builderState,
    })

    // readOnly builderState does not have rootId
    // readOnly is used in ContentPreview
    if (options.readOnly) {
      return builderState
    }

    if (has(options, 'rootId')) {
      assert(!isUndefined(options.rootId), `createBuilderState: options.rootId cannot be undefined`)
    }
    if (options.initialData) {
      dispatch(mergeParsed(builderId, options.initialData))
    }

    let rootId = options.rootId

    if (options.ensureRootContentExists && !rootId) {
      options.createInitialRootNodeType = NodeType.CONTENT
    }

    if (options.createInitialRootNodeType) {
      const rootNodeInitial = { nodeType: options.createInitialRootNodeType }
      if (options.defaultCaption) {
        rootNodeInitial.caption = options.defaultCaption
      }
      const rootNode = dispatch(
        builderCreateNode(builderId, rootNodeInitial, {
          preventOpen: options.preventOpenInitializedRootNode,
        }),
      )
      rootId = rootNode.id
    }

    dispatch(builderStateActions.setRoot(builderId, rootId, { isInitial: true }))
    dispatch(
      builderStateActions.setReady(builderId, {
        isInitializedBlank: Boolean(options.createInitialRootNodeType),
      }),
    )

    if (options.triggerPickerSectionToOpenAfterBuilderIsReady) {
      dispatch(
        builderStateActions.toggleTriggerPicker(builderId, {
          initialCategory: options.triggerPickerSectionToOpenAfterBuilderIsReady,
        }),
      )
    }

    if (options.openTriggerId) {
      dispatch(builderStateActions.openTrigger(builderId, options.openTriggerId))
      dispatch(updateBuilderState(builderId, { sidebarHidden: false }))
    }

    return builderSelectors.builderState.getById(getState(), builderId)
  }
}

/**
 * @param builderId
 */
export function removeBuilderState(builderId) {
  return {
    type: ReduxActionTypes.REMOVE_BUILDER_STATE,
    builderId,
  }
}

export function clearBuilderAndReset(builderId, keepStartingStep = false) {
  return {
    type: ReduxActionTypes.CLEAR_BUILDER_AND_RESET,
    builderId,
    keepStartingStep,
  }
}

/**
 * Applies changes to the builder
 * - parse the batch and update all data in the store
 * - change rootId if necessary
 * - remove selection from deleted elements
 *
 * @param {string} builderId
 * @param {Parsed} parsed
 * @param {object} options
 *  keepChanged - do not reset the changed flag
 */
export function applyParsed(builderId, parsed, options = {}) {
  return (dispatch, getState) => {
    const builderState = builderSelectors.builderState.getById(getState(), builderId)
    if (!builderState) {
      return
    }

    const deletedContentIds = parsed.nodes
      .filter((content) => content.deleted)
      .map((content) => content.id)

    // if the current node is deleted - open the starting one
    const currentNodeId = builderSelectors.builderState.getCurrentNodeId(getState(), builderId)
    if (currentNodeId && deletedContentIds.includes(currentNodeId)) {
      const rootId = builderSelectors.builderState.getRootNodeId(getState(), builderId)
      dispatch(open(builderId, rootId))
    }

    // selection on the flow chart
    const selectedNodeIds = builderSelectors.state.getSelectionList(getState(), builderId)
    const deletedSelectedNodeIds = intersection(selectedNodeIds, deletedContentIds)

    if (deletedSelectedNodeIds.length) {
      const ids = without(selectedNodeIds, ...deletedSelectedNodeIds)
      dispatch(setSelection(builderId, ids))
    }

    // apply changes
    dispatch(mergeParsed(builderId, parsed))

    if (!options.keepChanged) {
      dispatch(builderStateActions.resetChanged(builderId))
    }

    return parsed
  }
}

/**
 * apply changes to rootId
 * @param builderId
 * @param nextRootId
 */
export function applyRootId(builderId, nextRootId) {
  return {
    type: ReduxActionTypes.BUILDER_UPDATE_ROOT,
    builderId,
    contentId: nextRootId,
  }
}

/**
 * Applies coordinates to the builder.
 *
 * @public
 *
 * @param builderId
 * @param coordinates
 */
export function applyCoordinates(builderId, coordinates) {
  return (dispatch, getState) => {
    const builderState = builderSelectors.builderState.getById(getState(), builderId)
    if (!builderState) {
      return
    }

    const builder = builderSelectors.builderState.getById(getState(), builderId)
    const nextCoordinates = Object.assign({}, builder.coordinates, coordinates)

    dispatch(updateBuilderState(builderId, { coordinates: nextCoordinates }))

    each(coordinates, (coords, id) => {
      const node = flowChartApp.getNode(id)
      if (!node || !coords) {
        return
      }

      if (
        Math.round(node.x) !== Math.round(coords.x) ||
        Math.round(node.y) !== Math.round(coords.y)
      ) {
        node.x = coords.x
        node.y = coords.y
      }
    })
  }
}

export function showNodeOnFlowChart(builderId, nodeId, forceFlowChartMode) {
  return (dispatch, getState) => {
    const state = getState()
    const builderState = builderSelectors.state.getById(state, builderId)

    if (builderState.flowChartMode) {
      setTimeout(flowChartApp.moveTo, 0, nodeId)
    } else if (forceFlowChartMode) {
      flowChartApp.initialFrameNodeId = nodeId
      dispatch(updateBuilderState(builderId, { flowChartMode: true }))
    }
  }
}

/**
 * @param builderId
 */
export function validate(builderId, options = {}) {
  return (dispatch, getState) => {
    const state = getState()

    const currentNode = builderSelectors.builderState.getCurrentNode(state, builderId)
    let nodes = entitySelectors.getNodesList(state, builderId)

    if (currentNode) {
      // Placing current node on first place to stay on current node if it has errors
      nodes = [currentNode, ...without(nodes, currentNode)]
    }

    const allNodes = entitySelectors.getNodesList(state, builderId)
    const actionGroups = allNodes.filter((ag) => ag.nodeType === NodeType.ACTION_GROUP)
    const actionsList = actionGroups.reduce((res, node) => [...res, ...(node.items || [])], [])
    const blocks = allNodes.reduce((res, node) => [...res, ...(node.blocks || [])], [])
    const blocksWithState = blocks.map((id) => entitySelectors.getBlockById(state, builderId, id))

    const hasSmartDelayNode = allNodes.find((node) => node.nodeType === NodeType.SMART_DELAY)

    const hasIgAccountFollowerCondition = blocksWithState.some((block) =>
      block.filter?.groups.some((group) =>
        group.items.some((item) => item.field === 'is_ig_account_follower'),
      ),
    )

    const isPro = getCurrentAccount(state).isPro

    const requiredAbilities = getBuilderRequiredAbilities(state, builderId)
    const notPurchasedReqiredAbilities = getUnavailableAbilities(state, requiredAbilities)

    if (notPurchasedReqiredAbilities.length > 0) {
      dispatch({ type: ReduxActionTypes.AI_ADDON_PUBLISH_ERROR_APPEARED })
      sendPublishErrorToAnalytics('This feature requires enabled billing abilities')

      return { valid: false }
    }

    if (!isPro && !options.ignoreProErrors) {
      const proNodes = nodes
        .filter((node) => nodeRegistry.get(node).isPro)
        .map((node) => node.nodeType)
      if (proNodes.length) {
        const nodeName = l.getString(NextStepTypeNames[proNodes[0]])
        const message = l.translate(
          "You aren't able to activate this feature on the current plan. Upgrade for unlimited access.",
          { nodeName },
        )

        const title = l.translate(`Unlock {nodeName} steps with Manychat Pro`, { nodeName })
        const source = UpgradeSource.BUILDER_NODES
        sendPublishErrorToAnalytics(
          'This is a Pro feature. Renew your subscription for full access.',
        )

        if (hasSmartDelayNode) {
          handleGoProExperiment(SimpleTrialPlaceType.FOLLOW_UP, { proNodes })
        } else if (hasIgAccountFollowerCondition) {
          handleGoProExperiment(SimpleTrialPlaceType.CONDITION_IG_ACCOUNT_FOLLOWER, { proNodes })
        } else {
          billing.proAlert({
            message,
            title,
            source,
            proNodes,
            products: [ProductType.AUTOMATION],
          })
        }
        return {
          valid: false,
          message: l.translate('Only PRO accounts can use conditional or split nodes'),
        }
      }

      // we only need to check blocks that don't belong to deleted nodes
      const blockIds = nodes.reduce((res, node) => [...res, ...(node.blocks || [])], [])
      const blocksList = blockIds.map((id) => entitySelectors.getBlockById(state, builderId, id))

      const proBlock = blocksList.find((block) => ProBlockTypes.includes(block.type))

      if (proBlock) {
        const blockName = l.getString(BlockTypeLabels[proBlock.type])

        if (proBlock.type === BlockType.FORM_QUESTION) {
          handleGoProExperiment(SimpleTrialPlaceType.USER_INPUT)
        } else {
          const message = l.translate(
            'Upgrade your plan to publish Automation with {blockName} block',
            { blockName },
          )
          const title = l.translate(`Unlock {blockName} block with Manychat Pro`, {
            blockName,
          })
          const source = `builder_blocks_${proBlock.type}`
          billing.proAlert({
            message,
            title,
            source,
            products: [ProductType.AUTOMATION],
          })
        }
        sendPublishErrorToAnalytics(
          'Upgrade to Automation Pro to publish Automation with User Input block',
        )
        return {
          valid: false,
          message: l.translate(`Only PRO accounts can use {blockName} block`, { blockName }),
        }
      }

      const nestedBlockIds = blocksList.reduce(
        (res, block) => [...res, ...(block.blocks || [])],
        [],
      )
      const nestedBlocksList = nestedBlockIds.map((id) =>
        entitySelectors.getBlockById(state, builderId, id),
      )
      const buttonIds = [...blocksList, ...nestedBlocksList].reduce(
        (res, block) => [...res, ...(block.buttons || [])],
        [],
      )
      const buttonsList = buttonIds.map((id) => entitySelectors.getButtonById(state, builderId, id))
      const buyButton = buttonsList.find((button) => button.type === ButtonType.BUY)
      if (buyButton) {
        const message = l.translate(
          'Attention! You are only able to preview Automations with Pro elements on the current plan. Upgrade for unlimited access.',
        )
        const title = l.translate(`Unlock buy button with Manychat Pro`)
        const source = UpgradeSource.BUILDER_BUY_BUTTON
        sendPublishErrorToAnalytics(
          'Attention! Currently your page is on Free plan. You still can preview Automations with Pro elements. To publish them please upgrade your subscription to Pro.',
        )
        billing.proAlert({
          message,
          title,
          source,
          products: [ProductType.AUTOMATION],
        })
        return { valid: false, message: l.translate(`Only PRO accounts can use buy button`) }
      }

      const hasPROMessageTag = builderSelectors.builderState.hasPROMessageTag(state, builderId)
      const hasContentWithReason = builderSelectors.builderState.hasContentWithReason(
        state,
        builderId,
      )
      if (hasPROMessageTag || hasContentWithReason) {
        const source = UpgradeSource.BUILDER_PRO_CONTENT_TYPE
        const title = l.translate(`Upgrade your plan to unlock Pro Content type`)
        const message = l.translate(
          "You aren't able to publish the Automation with messaging outside 24-hours window on the current plan. Upgrade for unlimited access.",
        )
        sendPublishErrorToAnalytics('Unlock Pro Content type with Manychat Pro')
        billing.proAlert({
          message,
          title,
          source,
          products: [ProductType.AUTOMATION],
        })
        return { valid: false, message: l.translate(`Only PRO accounts can use Pro Content type`) }
      }

      const proAction = actionsList.find(
        (action) =>
          ProActionTypes.includes(action.type) || ProActionTypes.includes(action.integration),
      )

      if (proAction) {
        const actionType = Object.values(ActionTypes).includes(proAction.type)
          ? proAction.type
          : proAction.integration
        const actionName = getActionTypeName(actionType)
        const message = l.translate(
          "You aren't able to publish Automation with {actionName} action on the current plan. Upgrade for unlimited access.",
          { actionName },
        )
        const title = l.translate(`Unlock {actionName} action with Manychat Pro`, {
          actionName,
        })
        const source = `builder_actions_${actionName}`
        sendPublishErrorToAnalytics(
          `You can't publish Automation with ${actionName} action on a Free account`,
        )
        billing.proAlert({
          message,
          title,
          source,
          products: [ProductType.AUTOMATION],
        })
        return {
          valid: false,
          message: l.translate(`Only PRO accounts can use {actionName} block`, { actionName }),
        }
      }
    }

    const builder = builderSelectors.builderState.getById(state, builderId)
    const deleteSubscriberActionNode = actionsList.find(
      (action) => action.type === ActionTypes.DELETE_SUBSCRIBER,
    )
    if (deleteSubscriberActionNode) {
      const isDeleteSubscriberActionConfirmed = builder.isDeleteSubscriberActionConfirmed

      if (!isDeleteSubscriberActionConfirmed) {
        dispatch(
          updateBuilderState(builderId, {
            isDeleteSubscriberActionConfirmed: false,
            isDeleteSubscriberConfirmationOpen: true,
          }),
        )
        return { valid: false, hideSidebar: true }
      }
    }

    const isEmailBuilderOpen = emailBuilderSelectors.isEmailBuilderOpen(state, builderId)
    const isEmailUsed = nodes.some((node) => node.nodeType === NodeType.EMAIL_NEW)
    const isEmailBanned = isEmailChannelBanned(state)
    if (isEmailBanned && isEmailUsed) {
      sendPublishErrorToAnalytics('Your email sendings was suspended due to TOS violations.')
      alert(l.translate('Your email sendings was suspended due to TOS violations.'), 'danger')
      return { valid: false, message: '' }
    }

    const rootNode = builderSelectors.builderState.getRootNode(state, builderId)
    if (!rootNode) {
      dispatch(openNode(builderId, NodeType.STARTING_STEP))
      dispatch(updateBuilderState(builderId, { showErrors: true }))
      sendPublishErrorToAnalytics('Please choose next step.')
      alert(l.translate('Please choose next step.'), 'danger')
      return { valid: false, message: '' }
    }

    for (let node of nodes) {
      const nodeId = node.id
      const errors = builderSelectors.validation.validateNode(state, builderId, nodeId)
      const firstInvalidElementId = Object.keys(errors)[0]

      if (firstInvalidElementId) {
        const firstError = errors[firstInvalidElementId][0]
        sendPublishErrorToAnalytics(firstError.originalMessage)
        alert(firstError.message, 'danger')

        const highlightBlockErrors = () => {
          dispatch(builderStateActions.highlightBlock(builderId, firstInvalidElementId, 'danger'))
          dispatch(builderStateActions.selectBlock(builderId, firstInvalidElementId))
          setTimeout(() => {
            dispatch(updateBuilderState(builderId, { showErrors: true }))
          })
        }

        if (currentNode !== node) {
          dispatch(openNode(builderId, nodeId)).then(highlightBlockErrors)
        } else {
          highlightBlockErrors()
        }

        const isInsideEmailBuilder =
          node.nodeType === NodeType.EMAIL_NEW && firstInvalidElementId !== nodeId
        if (isEmailBuilderOpen && !isInsideEmailBuilder) {
          dispatch(emailBuilderActions.closeEmailBuilder(builderId))
        } else if (!isEmailBuilderOpen && isInsideEmailBuilder) {
          dispatch(emailBuilderActions.openEmailBuilder(builderId, nodeId))
        }

        if (!isMobile) {
          dispatch(showNodeOnFlowChart(builderId, node.id))
        }

        return { valid: false, message: firstError.message }
      }

      if (builder && getBaseNamespace(builder.flow) === Namespace.POST) {
        const { valid: isBroadcastCorrect, message } = checkBroadcastErrors(
          state,
          builderId,
          dispatch,
        )
        if (!isBroadcastCorrect) {
          return { valid: false, message }
        }
      }
    }

    return { valid: true, message: '' }
  }
}

export const checkBroadcastErrors = (state, builderId, dispatch) => {
  const rootContents = builderSelectors.builderState.getRootContentsWithChaining(state, builderId)

  let errorNode
  let errorBlockId = ''
  const hasDelayBlock = rootContents.some((content) => {
    const ordinaryBlocks = nodeSelectors.getOrdinaryBlocks(state, builderId, content.id)

    return ordinaryBlocks.some((block) => {
      if (block.type === BlockType.DELAY) {
        errorNode = content
        errorBlockId = block.id
        return true
      }
      return false
    })
  })

  if (hasDelayBlock) {
    const message = l.translate(
      'Delay blocks are not allowed before interaction with contact. Remove delay block from this Step.',
    )
    alertWithHotjarEvent(message, 'danger')

    dispatch(openNode(builderId, errorNode.id)).then(() => {
      dispatch(builderStateActions.highlightBlock(builderId, errorBlockId, 'danger'))
      setTimeout(() => {
        dispatch(updateBuilderState(builderId, { showErrors: true }))
        dispatch(showNodeOnFlowChart(builderId, errorNode.id))
      })
    })

    return { valid: false, message }
  }

  return { valid: true, message: '' }
}

/**
 * @param builderId
 * @param messages
 */
export function showServerErrors(builderId, messages) {
  return (dispatch, getState) => {
    const state = getState()
    const errMessage = first(values(messages))
    const errPath = first(Object.keys(messages))
    const errorInfo = builderSelectors.errorPath.parseErrorPath(state, builderId, errPath)

    if (errorInfo) {
      let node = entitySelectors.getNodeById(state, builderId, errorInfo.contentId)

      if (node.deleted) {
        dispatch(builderNodeActions.restoreNode(builderId, node.id))
      }

      const currentNodeId = builderSelectors.builderState.getCurrentNodeId(state, builderId)
      if (currentNodeId !== errorInfo.contentId) {
        dispatch(openNode(builderId, errorInfo.contentId))

        dispatch(showNodeOnFlowChart(builderId, node.id, node.nodeType === NodeType.ACTION_GROUP))
      }
      dispatch(
        updateBuilderState(builderId, {
          serverError: { ...errorInfo, message: errMessage, serverError: true },
          showErrors: true,
        }),
      )
    }
    alertWithHotjarEvent(errMessage, 'danger')
  }
}

export function applyInitialNodesPreset(builderId, initialNodesPreset) {
  return async (dispatch, getState) => {
    dispatch(setBusy(builderId, true))
    const rootId = builderSelectors.builderState.getRootNodeId(getState(), builderId)
    if (rootId) {
      dispatch(deleteNode(builderId, rootId))
    }

    const rootNode = await dispatch(
      builderNodeActions.createInitialNodesPreset(builderId, initialNodesPreset),
    )
    if (rootNode) {
      dispatch(builderStateActions.setRoot(builderId, rootNode.id, { isInitial: true }))
    }
    dispatch(setBusy(builderId, false))
  }
}

export const closeAllBuildersSidebars = () => (dispatch, getState) => {
  const state = getState()
  const closeSidebarByBuilderId = (builderId) => {
    dispatch(
      updateBuilderState(builderId, {
        sidebarHidden: true,
      }),
    )
    dispatch(builderStateActions.selectBlock(builderId, null))
  }

  Object.keys(state.builders.byId)?.forEach(closeSidebarByBuilderId)
}

export const unselectNodesInAllBuilders = () => (dispatch, getState) => {
  const state = getState()
  const unselectNodesByBuilderId = (builderId) => {
    dispatch(
      updateBuilderState(builderId, {
        selectedNodes: {},
      }),
    )
  }

  Object.keys(state.builders.byId)?.forEach(unselectNodesByBuilderId)
}

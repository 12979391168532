import React from 'react'

interface IUseFocusIndexResult {
  index: number
  next: () => void
  prev: () => void
  set: (index: number) => void
}

const getLastOptionIndex = (optionsLength: number) => {
  if (optionsLength === 0) {
    return 0
  }
  return optionsLength - 1
}

export const useFocusIndex = <T = Record<string, unknown>>(
  options: T[],
  query?: string,
): IUseFocusIndexResult => {
  const [index, setIndex] = React.useState<number>(0)
  const optionsLength = options.length

  const next = React.useCallback(
    () =>
      setIndex((x) => {
        if (x + 1 >= optionsLength) {
          return 0
        }
        return x + 1
      }),
    [optionsLength],
  )
  const prev = React.useCallback(
    () =>
      setIndex((x) => {
        if (x === 0) {
          return getLastOptionIndex(optionsLength)
        }
        return x - 1
      }),
    [optionsLength],
  )
  const set = React.useCallback(
    (x: number) => {
      if (x >= optionsLength || x < 0) {
        return setIndex(0)
      }
      setIndex(x)
    },
    [optionsLength],
  )

  React.useEffect(() => {
    setIndex(0)
  }, [query])

  React.useEffect(() => {
    const lastOptionIndex = getLastOptionIndex(optionsLength)
    if (lastOptionIndex < index) {
      setIndex(lastOptionIndex)
    }
  }, [index, optionsLength])

  return { index, next, prev, set }
}

import { FieldGroupId, FieldType } from 'common/fields/entities/enums'
import { Field } from 'common/fields/entities/field'
import { FilterValueItem } from 'common/filter/interfaces'

export const makeTagField = ({
  value,
  label,
  folder_id: folderId,
  count,
}: FilterValueItem): Field => ({
  id: `tag_${value}`,
  caption: label,
  type: FieldType.TAG,
  group: FieldGroupId.TAG,
  folderId,
  count,
})

export const getNumberedId = (groupPrefix: FieldGroupId, id?: string | number) => {
  if (typeof id === 'number') return id
  if (typeof id === 'string') return Number(id.replace(`${groupPrefix}_`, ''))
  return 0
}

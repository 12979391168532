import React from 'react'
import { Icon, palettes, TextInputV2Props } from '@manychat/manyui'

import FieldIcon from 'common/fields/components/FieldIcon'
import { FieldType } from 'common/fields/entities/enums'
import { getFieldIconName } from 'common/fields/helpers'
import { FieldClearEvent } from 'common/fields/linking/components/interfaces/fieldEvents'
import TextInputWithIcon from 'components/TextInputWithIcon'

type FieldPickerInputProps = TextInputV2Props & {
  invalid?: boolean
  fieldType?: FieldType
  onClear?: (event: FieldClearEvent) => void
  showIcon?: boolean
  ariaLabel: string
}

const FieldPickerInput: React.ForwardRefRenderFunction<HTMLInputElement, FieldPickerInputProps> = (
  { fieldType, name, onClear, ariaLabel, showIcon = true, ...inputProps },
  ref,
) => {
  const fieldIcon = getFieldIconName(fieldType || '')

  return (
    <TextInputWithIcon
      {...inputProps}
      ariaLabel={ariaLabel}
      autoComplete="off"
      ref={ref}
      icon={showIcon && Boolean(fieldIcon) && <FieldIcon iconName={fieldIcon} size={20} />}
      name={name}
      rightIcon={
        onClear && (
          <Icon.Close
            size={24}
            onClick={() => onClear({ target: { value: '', field: null, name } })}
            className="pointer"
            color={palettes.typography.disabledFG}
          />
        )
      }
    />
  )
}

export default React.forwardRef(FieldPickerInput)

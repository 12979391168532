import get from 'lodash/get'

import * as atypes from 'apps/dashboard/components/Metrics/types/types'
import { getBaseDateRange } from 'apps/dashboard/components/Metrics/utils/getBaseDateRange'

const baseDateRange = getBaseDateRange()

const initialState = {
  events: {
    loading: false,
    system: {},
    custom: {},
  },
  earnedData: {
    dateStart: baseDateRange[0],
    dateEnd: baseDateRange[1],
    loading: false,
    data: [],
  },
  paymentData: {
    eventId: -2,
    dateStart: baseDateRange[0],
    dateEnd: baseDateRange[1],
    loading: false,
    data: [],
  },
  dateRangePaymentComplete: [],
  eventIdPaymentComplete: null,
  overallEarningsChart: [],
  conversionEventsChart: [],
  currencies: [],
}

export default function MetricsDashboardReducer(state = initialState, action) {
  switch (action.type) {
    // Earned data
    case atypes.DASHBOARD_METRICS_CHANGE_EARNED_DATE_RANGE:
      return {
        ...state,
        earnedData: {
          ...state.earnedData,
          dateStart: get(action, 'data.dateStart', baseDateRange[0]),
          dateEnd: get(action, 'data.dateEnd', baseDateRange[1]),
        },
      }
    case atypes.DASHBOARD_METRICS_FETCH_EARNED_DATA_REQUEST:
      return {
        ...state,
        earnedData: {
          ...state.earnedData,
          loading: true,
        },
      }
    case atypes.DASHBOARD_METRICS_FETCH_EARNED_DATA_RESPONSE:
      return {
        ...state,
        earnedData: {
          ...state.earnedData,
          data: get(action, 'data.chart', []),
          loading: false,
        },
        earned: action.data?.earned,
        sales: action.data?.payments,
      }
    case atypes.DASHBOARD_METRICS_FETCH_EARNED_DATA_ERROR:
      return {
        ...state,
        earnedData: {
          ...state.earnedData,
          loading: false,
        },
      }

    // Payment Data
    case atypes.DASHBOARD_METRICS_CHANGE_PAYMENT_DATE_RANGE:
      return {
        ...state,
        paymentData: {
          ...state.paymentData,
          dateStart: get(action, 'data.dateStart', baseDateRange[0]),
          dateEnd: get(action, 'data.dateEnd', baseDateRange[1]),
        },
      }
    case atypes.DASHBOARD_METRICS_FETCH_EVENT_LIST_REQUEST:
      return {
        ...state,
        events: {
          ...state.events,
          loading: true,
        },
      }
    case atypes.DASHBOARD_METRICS_FETCH_EVENT_LIST_RESPONSE:
      return {
        ...state,
        events: {
          ...state.events,
          loading: false,
          system: get(action, 'data.system_events', []) || [],
          custom: get(action, 'data.custom_events', []) || [],
        },
      }
    case atypes.DASHBOARD_METRICS_FETCH_EVENT_LIST_ERROR:
      return {
        ...state,
        events: {
          ...state.events,
          loading: false,
        },
      }
    case atypes.DASHBOARD_METRICS_SET_EVENT_ID:
      return {
        ...state,
        paymentData: {
          ...state.paymentData,
          eventId: get(action, 'data.eventId', -2),
        },
      }
    case atypes.DASHBOARD_METRICS_FETCH_PAYMENT_DATA_REQUEST:
      return {
        ...state,
        paymentData: {
          ...state.paymentData,
          loading: true,
        },
      }
    case atypes.DASHBOARD_METRICS_FETCH_PAYMENT_DATA_RESPONSE:
      return {
        ...state,
        paymentData: {
          ...state.paymentData,
          data: get(action, 'data.chart', []),
          loading: false,
        },
      }
    case atypes.DASHBOARD_METRICS_FETCH_PAYMENT_DATA_ERROR:
      return {
        ...state,
        paymentData: {
          ...state.paymentData,
          loading: false,
        },
      }

    // Currencies
    case atypes.DASHBOARD_METRICS_GET_CURRENCIES_RESPONSE:
      return {
        ...state,
        currencies: action.data.currencies,
      }

    default:
      return state
  }
}

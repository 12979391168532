import get from 'lodash/get'

import { BlockingReasonsTypes } from 'common/flow/constants/BlockingReasonsTypes'
import { FlowStatus } from 'common/flow/flowConstants'

export function hasMessage(flow) {
  return hasContent(flow) || hasDraft(flow)
}

export function hasDraft(flow) {
  return Boolean(flow.hasDraft)
}

export function hasContent(flow) {
  return Boolean(flow.contentId)
}
export function isDeleted(flow) {
  return get(flow, 'status') === FlowStatus.DELETED
}

export function isPermanentlyDeleted(flow) {
  return get(flow, 'status') === FlowStatus.PERMANENTLY_DELETED
}

export function isReadOnly(flow) {
  return get(flow, 'is_readonly')
}

export function hasPublishedContent(flow) {
  return get(flow, 'has_published_content')
}

export function isFetching(flow) {
  return get(flow, 'isFetching')
}

export function isBroadcastFlow(flowId) {
  return flowId.indexOf('post') > -1
}

function normalizeFlowSendingStats(flowSendingStats) {
  let {
    content_sent_otn_without_token,
    content_sent_other_without_tag,
    content_sent_without_tag,

    content_send_otn_without_token,
    content_send_other_without_tag,
    content_send_without_tag,
  } = flowSendingStats

  content_sent_otn_without_token = content_sent_otn_without_token ?? content_send_otn_without_token
  content_sent_other_without_tag = content_sent_other_without_tag ?? content_send_other_without_tag
  content_sent_without_tag = content_sent_without_tag ?? content_send_without_tag

  return {
    ...flowSendingStats,
    content_sent_otn_without_token,
    content_sent_other_without_tag,
    content_sent_without_tag,
  }
}

export function getBlockingReasons(flowSendingStats) {
  let { content_sent_otn_without_token, content_sent_other_without_tag, content_sent_without_tag } =
    normalizeFlowSendingStats(flowSendingStats)

  const labels = []
  if (content_sent_otn_without_token > 0) {
    labels.push(BlockingReasonsTypes.NO_OTN)
  }
  if (content_sent_other_without_tag > 0) {
    labels.push(BlockingReasonsTypes.OTHER_CONTENT_TYPE)
  }
  if (content_sent_without_tag - content_sent_other_without_tag > 0) {
    labels.push(BlockingReasonsTypes.NO_CONTENT_TYPE)
  }
  return labels
}

export function getBreaks(flowSendingStats) {
  let { content_sent_otn_without_token, content_sent_without_tag } =
    normalizeFlowSendingStats(flowSendingStats)

  return content_sent_without_tag + content_sent_otn_without_token
}

export function getBreaksPercent(flowSendingStats) {
  let { content_sent } = normalizeFlowSendingStats(flowSendingStats)
  return content_sent ? Math.ceil((100 * getBreaks(flowSendingStats)) / content_sent) : 0
}

import React, { PropsWithChildren, memo, useMemo, useRef, useState } from 'react'

import {
  EditModeContext,
  RegistryContext,
  RendererContextType,
  RendererContext,
  ScreenProvider,
  SerializedViewContext,
  ViewContextContext,
} from '../context'
import { registry as defaultRegistry, Registry } from '../registry'
import { SerializedView, ViewContext } from '../types'
import { validate } from '../utils/validate'

import { RenderBlock } from './RenderBlock'

export interface RendererProps<Ctx extends ViewContext> {
  view: SerializedView
  context: Ctx
  registry?: Registry
  className?: string
  editMode?: boolean
  children?: PropsWithChildren['children']
}

export const Renderer = memo(
  <Ctx extends ViewContext>({
    className,
    view,
    context,
    registry = defaultRegistry,
    editMode = false,
    children,
  }: RendererProps<Ctx>) => {
    const initialContextRef = useRef(context)
    useMemo(
      () => validate(view, registry, initialContextRef.current),
      [view, registry, initialContextRef],
    )

    const [root, setRoot] = useState(view.root)
    const rendererContextValue: RendererContextType = useMemo(
      () => ({ goToNode: setRoot }),
      [setRoot],
    )

    return (
      <div id="paywall" data-test-id="paywall" className={className}>
        <ScreenProvider>
          <RendererContext.Provider value={rendererContextValue}>
            <RegistryContext.Provider value={registry}>
              <SerializedViewContext.Provider value={view}>
                <ViewContextContext.Provider value={context}>
                  <EditModeContext.Provider value={editMode}>
                    <RenderBlock key="root" blockId={root} />

                    {editMode ? children : null}
                  </EditModeContext.Provider>
                </ViewContextContext.Provider>
              </SerializedViewContext.Provider>
            </RegistryContext.Provider>
          </RendererContext.Provider>
        </ScreenProvider>
      </div>
    )
  },
)

Renderer.displayName = 'Renderer'

import React, { useMemo } from 'react'
import { BtnV2, l } from '@manychat/manyui'

import { AccountSelect } from 'common/core/components/AccountSelect/AccountSelect'
import ExportFlowEntitiesMapping from 'common/flow/controllers/ExportFlowEntitiesMapping'
import Tip from 'components/Tip'

/**
 * @typedef {Object} ExportDialogProps
 * @property {Array} accounts - List of account objects.
 * @property {string} destAccountId - ID of the destination account.
 * @property {string} title - Title of the dialog.
 * @property {Object} style - Custom styles for the dialog.
 * @property {boolean} isOpen - Flag indicating if the dialog is open.
 * @property {string} flowId - ID of the flow.
 * @property {function} onSelect - Callback function for account selection.
 * @property {function} onCustomEventSelect - Callback function for custom event selection.
 * @property {function} onTagSelect - Callback function for tag selection.
 * @property {function} onCUFSelect - Callback function for CUF selection.
 * @property {function} onGAFSelect - Callback function for GAF selection.
 * @property {function} onOtnSelect - Callback function for OTN selection.
 * @property {function} onSubmit - Callback function for form submission.
 *
 * ExportDialog component
 *
 * @param {ExportDialogProps} props
 * @returns {JSX.Element}
 */
function ExportDialog(props) {
  const {
    accounts,
    destAccountId,
    title,
    style,
    isOpen,
    flowId,

    onSelect,
    onCustomEventSelect,
    onTagSelect,
    onCUFSelect,
    onGAFSelect,
    onOtnSelect,
    onSubmit,
  } = props

  const currentAccount = useMemo(
    () => accounts.find(({ id }) => id === destAccountId),
    [accounts, destAccountId],
  )

  return (
    <div className="p-a-md" style={style}>
      <p>{l.translate('You are about to copy “{title}” Automation to another page.', { title })}</p>
      <p>
        {l.translate(
          'If this Automation includes any PRO elements, the receiving page must also be PRO in order to use it.',
        )}
      </p>
      <Tip
        className="m-t"
        text={l.translate('Actions and messages from other Automations won’t be copied.')}
        rounded
        useInnerHTML
      />

      <h3>{l.translate('Choose a Target Account')}</h3>
      <AccountSelect
        data-test-id="account-select"
        withPlaceholder
        options={accounts}
        onSelect={onSelect}
        className="m-t bg-h-default"
        current={currentAccount}
      />
      <ExportFlowEntitiesMapping
        onCustomEventSelect={onCustomEventSelect}
        onTagSelect={onTagSelect}
        onCUFSelect={onCUFSelect}
        onGAFSelect={onGAFSelect}
        onOtnSelect={onOtnSelect}
        pageId={destAccountId}
        isOpen={isOpen}
        flowId={flowId}
        className="m-t-md"
      />

      <form className="m-t" onSubmit={onSubmit}>
        <BtnV2 variant="primary" fullWidth size="large" type="submit">
          {l.translate('Copy Now')}
        </BtnV2>
      </form>
    </div>
  )
}

export default ExportDialog

import { l } from '@manychat/manyui'

export const SELECTION_TYPE = {
  EXCLUDE: 'exclude',
  INCLUDE: 'include',
}

export enum StatusType {
  ACTIVE = 'active',
  DELETED = 'deleted',
  INACTIVE = 'inactive',
  UNSUBSCRIBED = 'unsubscribed',
  NO_OPTIN = 'no_optin',
  VISITOR = 'visitor',
  MERGED = 'merged',
}

export const StatusLabel = {
  [StatusType.ACTIVE]: l.makeTranslate('Subscribed'),
  [StatusType.DELETED]: l.makeTranslate('Deleted'),
  [StatusType.INACTIVE]: l.makeTranslate('Deleted conversation'),
  [StatusType.UNSUBSCRIBED]: l.makeTranslate('Unsubscribed'),
  [StatusType.NO_OPTIN]: l.makeTranslate('Subscribed (no info)'),
  [StatusType.VISITOR]: l.makeTranslate('Visitor'),
  [StatusType.MERGED]: l.makeTranslate('Merged'),
}

export const GenderType = {
  MALE: 'male',
  FEMALE: 'female',
  OTHER: 'other',
}

export const GenderLabel = {
  [GenderType.MALE]: l.makeTranslate('Male'),
  [GenderType.FEMALE]: l.makeTranslate('Female'),
  [GenderType.OTHER]: l.makeTranslate('Other'),
}

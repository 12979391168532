import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import AnyEnsure from 'common/core/components/AnyEnsure'
import * as targetPageTagsActions from 'common/mapping/actions/targetPageTagsActions'

class EnsureTags extends React.Component {
  componentDidMount() {
    this.props.fetchTargetPageTags({ pageId: this.props.pageId })
  }

  componentDidUpdate(prevProps) {
    if (prevProps.pageId && prevProps.pageId !== this.props.pageId) {
      this.props.dropTargetPageTags()
      this.props.fetchTargetPageTags({ pageId: this.props.pageId })
    }
  }

  render() {
    const { children, ...rest } = this.props
    return <AnyEnsure {...rest}>{children}</AnyEnsure>
  }
}

const ensureTags = connect(
  (state) => ({
    items: state.targetPage.tags.items,
    fetching: state.targetPage.tags.fetching,
  }),
  (dispatch) => bindActionCreators(targetPageTagsActions, dispatch),
)(EnsureTags)

export default ensureTags

import React, { useMemo } from 'react'
import cx from 'classnames'
import { l } from '@manychat/manyui'

import { ChannelType } from 'common/core/constants/ChannelType'
import ArrowCol from 'common/mapping/components/ArrowCol/ArrowCol'
import cm from 'common/mapping/components/EntitiesMapping/EntitiesMapping.module.css'
import { MappingField } from 'common/mapping/components/MappingField/MappingField'
import { TargetPageOtnPicker } from 'common/mapping/controllers/TargetPageOtnPicker/TargetPageOtnPickerController'
import { ReasonTypes } from 'common/oneTimeNotify/constants/notifyReasonConstants'

interface ReasonEntity {
  channel: ChannelType
  id: number
  name: string
  request_title: string
  template_installation_id: number | null
  type: ReasonTypes
}

interface EntitiesNTNMappingProps {
  caption: string
  headingText: string
  currentEntities: ReasonEntity[]
  onSet: (reason: { id: number; name: string }, event: React.ChangeEvent<HTMLInputElement>) => void
  entitiesMap: Record<string, number | null>
  className: string
  entity: { id: string | number; name: string }
}

export const EntitiesNTNMapping = ({
  caption,
  currentEntities,
  entitiesMap,
  onSet,
  className = '',
}: EntitiesNTNMappingProps) => {
  const igNtnMap = useMemo(
    () => currentEntities.filter((reason) => reason.channel === ChannelType.INSTAGRAM),
    [currentEntities],
  )
  const fbNtnMap = useMemo(
    () => currentEntities.filter((reason) => reason.channel === ChannelType.FB),
    [currentEntities],
  )

  const renderField = (
    reason: ReasonEntity,
    entitiesMap: EntitiesNTNMappingProps['entitiesMap'],
  ) => {
    const value = entitiesMap[reason.id]
    const field = { id: reason.id, name: reason.name }
    return (
      <div key={reason.id} className="row m-b-lg sm-m-b justify-center sm-justify-start">
        <div className="col-11 sm-col">
          <MappingField label={reason.name} frequency={reason.type} />
        </div>
        <div className={cx(cm.arrow, 'col-1 sm-d-flex sm-justify-center')}>
          <ArrowCol active={Boolean(value)} />
        </div>
        <div className="col-12 sm-col m-t-xs sm-m-t-0">
          <TargetPageOtnPicker
            onChange={(e) => onSet(field, e)}
            value={value}
            placeholder={l.translate('Select Topic')}
            channel={reason.channel}
            allowCreate={false}
            disabled={false}
          />
        </div>
      </div>
    )
  }

  return (
    <div className={className}>
      <div className="m-t m-b text-subheading">{caption}</div>

      {igNtnMap.length > 0 && (
        <>
          <div
            data-test-id="ig-title"
            className="text-subheading text-secondary text-capitalize m-b-sm"
          >
            {ChannelType.INSTAGRAM}
          </div>
          {igNtnMap.map((reason) => renderField(reason, entitiesMap))}
        </>
      )}

      {fbNtnMap.length > 0 && (
        <>
          <div
            data-test-id="fb-title"
            className="text-subheading text-secondary text-capitalize m-b-sm"
          >
            {l.translate('Messenger')}
          </div>
          {fbNtnMap.map((reason) => renderField(reason, entitiesMap))}
        </>
      )}
    </div>
  )
}

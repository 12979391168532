import { api } from 'shared/api'
import { AiAgentPlaygroundSchemas } from 'shared/api/requests/aiAgentPlayground/schemas'

export const AiAgentPlaygroundApi = {
  initThread: api.account.createPost({
    url: '/aiAgentPlayground/initThread',
    schemas: AiAgentPlaygroundSchemas.initThread,
  }),
  startThread: api.account.createPost({
    url: '/aiAgentPlayground/startThread',
    schemas: AiAgentPlaygroundSchemas.startThread,
  }),
  sendEvent: api.account.createPost({
    url: '/aiAgentPlayground/threadEvent',
    schemas: AiAgentPlaygroundSchemas.sendEvent,
  }),
}

import { Icon, l } from '@manychat/manyui'

import {
  IntegrationType,
  IntegrationNameByType,
} from 'common/actions/integrations/common/constants/IntegrationType'
import {
  createIntegration,
  createIntegrationAction,
} from 'common/actions/integrations/common/model/helpers'
import * as hubspotActions from 'common/actions/integrations/HubSpot/actions/hubspotActions'
import { KnowledgeBaseLink } from 'common/core/components/SupportLinks'
import { AccountTargetPermissionsNames } from 'common/core/models/Account/constants'
import { analyticsService } from 'utils/services/analytics'

export const HubSpotFieldsTypes = {
  STRING: 'string',
  NUMBER: 'number',
  DATE: 'date',
  DATETIME: 'datetime',
  ENUMERATION: 'enumeration',
  ENUM_BOOLEAN_CHECKBOX: 'enumBooleancheckbox',
}

export const HubSpotActionTypes = {
  SUBMIT_FORM: 'forms.submit_form',
  CREATE_UPDATE_CONTACT: 'contacts.create_or_update',
  GET_CONTACT: 'contacts.get',
}

const handleSupportLinkClick = () => {
  analyticsService.sendEvent('SUPPORT.LINK_SETTINGS_INTEGRATIONS_HUB_SPOT_CLICK', {
    url: window.location.pathname,
  })
}

export const HubSpotConfig = createIntegration({
  type: IntegrationType.HUBSPOT,
  name: IntegrationNameByType[IntegrationType.HUBSPOT],
  fullName: 'HubSpot CRM',
  icon: Icon.Hubspot,
  permissionsVariable: AccountTargetPermissionsNames.HUB_SPOT_INTEGRATION,
  description: () =>
    l.translate(
      'HubSpot CRM is everything you need to organize, track, and nurture your leads and customers. It\'s 100% free, forever. <a href="{link}" target="_blank" class="text-primary pointer" onclick="handleSupportLinkClick" rel="noreferrer" >Learn more</a>',
      { handleSupportLinkClick, link: KnowledgeBaseLink.HUBSPOT_INTEGRATION },
    ),
  createHref: true,
  reduxActions: hubspotActions,
  actions: [
    createIntegrationAction({
      type: HubSpotActionTypes.SUBMIT_FORM,
      description: l.translate(
        'Send Custom Field data to HubSpot form. Form submissions can be made to any registered HubSpot form.',
      ),
      name: l.translate('Submit data to a form'),
    }),
    createIntegrationAction({
      type: HubSpotActionTypes.CREATE_UPDATE_CONTACT,
      description: l.translate(
        'Create a contact if it doesn’t exist in HubSpot already, or update it with Contact’s Custom Fields if it does. An existing contact will be determined by its email address.',
      ),
      name: l.translate('Create/Update Contact Properties'),
    }),
    createIntegrationAction({
      type: HubSpotActionTypes.GET_CONTACT,
      description: l.translate(
        'Return information about a single contact by its email address and process it to Contact’s Custom Fields.',
      ),
      name: l.translate('Get Contact Properties'),
    }),
  ],
})

import React, { forwardRef } from 'react'
import cx from 'classnames'

import Loader from '../Loader'

import { ListProps } from './interfaces'
import { ListDivider } from './ListDivider'
import { ListGroupHeading } from './ListGroupHeading'
import { ListItem } from './ListItem'

import cm from './List.module.css'

const ListComponent = forwardRef<HTMLUListElement, ListProps>(
  ({ loading, children, className, ...rest }, ref) => {
    return (
      <ul className={cx(cm.list, loading && cm.loading, className)} {...rest} ref={ref}>
        {loading ? <Loader center size={40} /> : children}
      </ul>
    )
  },
)

export const List = Object.assign(ListComponent, {
  Item: ListItem,
  GroupHeading: ListGroupHeading,
  Divider: ListDivider,
})

import { getLocation } from './tools'

/**
 * Preserves specific search parameters (`searchParams`) from the current URL when navigating to a new path.
 *
 * @param {string} nextPath - The new path or full URL to navigate to.
 * @param {Array<string>} keysOfProxiedParams - A set of search parameter keys to retain from the current URL.
 * @returns {string} The new URL with preserved search parameters.
 *
 * @example
 * If the current URL is: "https://example.com?page=1&lang=en"
 * Calling preserveSearchParams('/dashboard', new Set(['lang']))
 * Returns: "/dashboard?lang=en"
 */
export function preserveSearchParams(nextPath: string, keysOfProxiedParams: string[]): string {
  const keysSet = new Set(keysOfProxiedParams)
  const currentSearchParams = new URLSearchParams(getLocation().search)
  const nextUrlObj = new URL(nextPath, window.location.origin)
  const nextSearchParams = nextUrlObj.searchParams

  currentSearchParams.forEach((value, key) => {
    if (!nextSearchParams.has(key) && keysSet.has(key)) {
      nextSearchParams.set(key, value)
    }
  })

  nextUrlObj.search = nextSearchParams.toString()

  const { pathname, search, hash } = nextUrlObj

  return `${pathname}${search}${hash}`
}

import React, { useRef } from 'react'
import { noop } from '@manychat/manyui'

import SelectWrapper from '../../forms/SelectWrapper'
import Dropdown from '../Dropdown'

/**
 * @typedef {Object} DropdownPickerProps
 * @property {Array} [options]
 * @property {React.ReactNode} [label] - Label for an anchor component.
 * @property {boolean} [disabled]
 * @property {boolean} [openOnHover] - Open dropdown on anchor hover.
 * @property {boolean} [closeOnSelect] - Close dropdown on option select.
 * @property {Object} [anchorProps] - Anchor related props. Default anchor is Btn.
 * @property {Object} [popoverProps] - Popover related props.
 * @property {Object} [optionProps] - Option node related props.
 * @property {boolean} [noScrollTopWhenOptionsChange]
 * @property {boolean} [noAutoScroll] - Prevent options autoscrolling on mousemove.
 * @property {function({ open: boolean }): React.ReactElement} [renderAnchor] - Render custom anchor component. Default anchor is Btn.
 * @property {function(option): React.ReactElement} [renderOption] - Render custom option.
 * @property {function} [onClickAnchor] - DEPRECATED: Use {onOpen} callback.
 * @property {function} [onClose]
 * @property {function} [onOpen]
 * @property {function} [onSelect]
 * @property {string | null} [value]
 * @property {string} [id]
 * @property {function} [renderTailingContent] - Render content after all options.
 *
 * DropdownPicker is a special case of a Dropdown that can be used as a select control. Supports keyboard navigation inside options list.
 * @param {DropdownPickerProps} props
 */
export default function DropdownPicker({
  options = [],
  disabled = false,
  openOnHover = false,
  closeOnSelect = true,
  anchorProps = {},
  popoverProps = {},
  optionProps = {},
  onClickAnchor = noop,
  onClose = noop,
  onOpen = noop,
  onSelect = noop,
  noScrollTopWhenOptionsChange = false,
  ...props
}) {
  const popoverRef = useRef(null)

  const handleSelect = (opt) => {
    if (closeOnSelect && popoverRef.current) {
      popoverRef.current.close()
    }

    onSelect(opt)
  }

  return (
    <SelectWrapper
      options={options}
      optionProps={optionProps}
      renderOption={props.renderOption}
      noScrollTopWhenOptionsChange={props.noScrollTopWhenOptionsChange}
      noAutoScroll={props.noAutoScroll}
      renderPopover={(content, popoverPropsInternal) => (
        <Dropdown
          ref={popoverRef}
          label={props.label}
          disabled={disabled}
          openOnHover={openOnHover}
          anchorProps={anchorProps}
          popoverProps={{ ...popoverPropsInternal, arrow: false, ...popoverProps }}
          renderAnchor={props.renderAnchor}
          onClickAnchor={onClickAnchor}
          onClose={onClose}
          onOpen={onOpen}
        >
          {content}
          {props.renderTailingContent?.()}
        </Dropdown>
      )}
      onSelect={handleSelect}
    />
  )
}

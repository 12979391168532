import { LayoutWithSidebar } from 'utils/router/components/LayoutWithSidebar/LayoutWithSidebar'

export const PixelRoutes = [
  {
    path: 'pixel',
    Component: LayoutWithSidebar,
    children: [
      {
        index: true,
        lazy: () => import('./pixel.page'),
      },
    ],
  },
] as const

import { createSlice } from '@reduxjs/toolkit'
import { createAppAsyncThunk } from 'reduxTyped'

import { WhatsAppBroadcastOnboardingState } from 'apps/whatsApp/interfaces/whatsAppBroadCastOnboarding'
import { handleCatch } from 'shared/api/lib/errors/handlers'
import { HomeTabApi } from 'shared/api/requests/homeTab'

export const initialState: WhatsAppBroadcastOnboardingState = {
  wa_broadcasting_onboarding: undefined,
}

export const getWhatsAppOnboardingDataThunk =
  createAppAsyncThunk<WhatsAppBroadcastOnboardingState | null>('whatsapp/onboarding', async () => {
    try {
      const result = await HomeTabApi.getWhatsAppOnboardingData()
      return result.data
    } catch (error) {
      handleCatch(error)
      return null
    }
  })

export const broadcastOnboardingSlice = createSlice({
  name: 'broadcastOnboarding',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getWhatsAppOnboardingDataThunk.fulfilled, (state, action) => {
      state.wa_broadcasting_onboarding = action.payload?.wa_broadcasting_onboarding
    })
  },
})

export const getWhatsAppBroadcastOnboardingData = (state: RootState) => {
  return state.whatsApp.broadcastOnboarding.wa_broadcasting_onboarding
}

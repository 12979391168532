import React, { useRef, useState, useCallback } from 'react'
import cx from 'classnames'
import { useAppDispatch } from 'reduxTyped'
import { Icon, Popover, l, Loader } from '@manychat/manyui'

import { useModalActions } from 'apps/cms/components/Flows/components/AutomationModals/hooks/useModalActions'
import { CmsAnalytics } from 'apps/cms/lib/analytics'
import sharedMenuStyles from 'apps/cms/lib/styles/menuSharedStyles.module.css'
import { contentManagementActions } from 'apps/cms/store'
import { AutomationModalType } from 'apps/cms/store/lib/types'
import { FSObjectType } from 'common/cms/models/FSObject/constants'
import { FsFolderObject } from 'shared/api/requests/cms/schemas'

import cm from './FolderContextMenu.module.css'

interface FolderContextMenuProps {
  folder: FsFolderObject
  anchorClassName?: string
  anchorOpenedClassName?: string
}

export const FolderContextMenu = ({
  folder,
  anchorClassName,
  anchorOpenedClassName,
}: FolderContextMenuProps) => {
  const dispatch = useAppDispatch()
  const buttonRef = useRef<HTMLButtonElement | null>(null)
  const [isMenuOpened, setIsMenuOpened] = useState(false)
  const [isCopying, setIsCopying] = useState(false)

  const openMenu = () => {
    setIsMenuOpened(true)
  }

  const closeMenu = useCallback(() => {
    setIsMenuOpened(false)
  }, [])

  const { openModal } = useModalActions()

  const openRenameModal = useCallback(() => {
    openModal({
      type: AutomationModalType.RENAME_FOLDER,
      data: { folderPath: folder.path, folderName: folder.title },
    })

    closeMenu()
  }, [closeMenu, folder.path, folder.title, openModal])

  const openDeleteModal = useCallback(() => {
    openModal({
      type: AutomationModalType.DELETE_FOLDER,
      data: { folderPath: folder.path, folderName: folder.title },
    })

    closeMenu()
  }, [closeMenu, folder.path, folder.title, openModal])

  const openMoveToModal = useCallback(() => {
    openModal({
      type: AutomationModalType.MOVE_TO,
      data: { type: FSObjectType.FOLDER, name: folder.title, path: folder.path },
    })

    closeMenu()
  }, [closeMenu, folder.path, folder.title, openModal])

  const copyFolder = useCallback(async () => {
    if (isCopying) return

    setIsCopying(true)
    await dispatch(contentManagementActions.cloneFolder(folder.path))
    CmsAnalytics.logCloneFolder()
    setIsCopying(false)
    closeMenu()
  }, [closeMenu, dispatch, folder.path, isCopying])

  return (
    <>
      <button
        onClick={openMenu}
        ref={buttonRef}
        className={cx(cm.anchor, anchorClassName, isMenuOpened && anchorOpenedClassName)}
      >
        <Icon.MoreVert size={20} />
      </button>

      <Popover
        at="bottom"
        popoverOrigin="top right"
        anchorOrigin="bottom right"
        anchor={buttonRef.current}
        open={isMenuOpened}
        onRequestClose={closeMenu}
        className={sharedMenuStyles.popover}
      >
        <button
          onClick={openRenameModal}
          className={sharedMenuStyles.contextActionButton}
          autoFocus
        >
          <Icon.Edit className={sharedMenuStyles.contextActionButton__icon} />

          <span>{l.translate('Rename')}</span>
        </button>

        <button onClick={copyFolder} className={sharedMenuStyles.contextActionButton}>
          <>
            <Icon.Copy
              className={cx(sharedMenuStyles.contextActionButton__icon, {
                [cm.invisible]: isCopying,
              })}
            />
            <span className={cx({ [cm.invisible]: isCopying })}>{l.translate('Duplicate')}</span>
          </>
          {isCopying && <Loader size={24} center />}
        </button>

        <button onClick={openMoveToModal} className={sharedMenuStyles.contextActionButton}>
          <Icon.MoveTo className={sharedMenuStyles.contextActionButton__icon} />

          <span>{l.translate('Move to')}</span>
        </button>

        <button
          onClick={openDeleteModal}
          className={cx(
            sharedMenuStyles.contextActionButton,
            sharedMenuStyles.contextActionButton_theme_danger,
          )}
        >
          <Icon.Delete className={sharedMenuStyles.contextActionButton__icon} />

          <span>{l.translate('Delete')}</span>
        </button>
      </Popover>
    </>
  )
}

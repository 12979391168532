import { ActionReducerMapBuilder, createReducer } from '@reduxjs/toolkit'

import {
  IApplicationsListItem,
  IApplicationVersion,
} from 'common/actions/integrations/Applications/interfaces'
import { applications } from 'constants/API'
import { makeGetActionCreator } from 'utils/api/redux/actions'
import { addFetchingCases } from 'utils/api/redux/reducers'

interface Initial {
  items: IApplicationsListItem[]
  fetching: boolean
}

interface Result {
  state: boolean
  apps: IApplicationVersion[]
}

export const fetchApplications = makeGetActionCreator<Result>(
  'applications/fetch',
  applications.endpoints.fetch,
)

export const makeReducer = () =>
  createReducer<Initial>({ items: [], fetching: false }, (builder) => {
    addItemsCases(builder)
    addFetchingCases(builder, fetchApplications)
  })

function addItemsCases(builder: ActionReducerMapBuilder<Initial>) {
  builder.addCase(fetchApplications.pending, (state) => {
    state.items = []
  })
  builder.addCase(fetchApplications.fulfilled, (state, action) => {
    state.items = groupApplicationVersions(action.payload.apps)
  })
}

function groupApplicationVersions(applications: IApplicationVersion[]) {
  const result: IApplicationsListItem[] = []
  applications.reduce((acc, cur: IApplicationVersion) => {
    const item = acc.find((e) => e.app_id === cur.app_id)

    if (item) {
      item.versions.push(cur)
    } else {
      acc.push({ app_id: cur.app_id, versions: [cur] })
    }

    return acc
  }, result)

  return result.sort((a, b) => b.app_id - a.app_id) || []
}

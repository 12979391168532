import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { appListenerMiddleware } from 'reduxTyped'

import { cancelAddOn } from 'common/billing/actions/addOnsActions'
import { AddOnSlug } from 'common/billing/interfaces/addOnTypes'
import { getIsUserFlagEnabled } from 'common/core/selectors/appSelectors'
import { UserFlag } from 'utils/services/featureFlagsService/constants'

export interface CancelAddOnModalState {
  isOpen: boolean
  addOnSlug: AddOnSlug | null
  loading: boolean
  isInterviewModalOpen: boolean
}

export const initialState: CancelAddOnModalState = {
  isOpen: false,
  loading: false,
  addOnSlug: null,
  isInterviewModalOpen: false,
}

export const cancelAddOnModalSlice = createSlice({
  name: 'cancelAddOnModal',
  initialState,
  reducers: {
    open: (state: CancelAddOnModalState, action: PayloadAction<AddOnSlug>) => {
      state.isOpen = true
      state.addOnSlug = action.payload
    },
    close: () => ({ ...initialState }),
    openInterviewModal: (state) => {
      state.isInterviewModalOpen = true
    },
    closeInterviewModal: (state) => {
      state.isInterviewModalOpen = false
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(cancelAddOn.pending, (state) => {
        state.loading = true
      })
      .addCase(cancelAddOn.fulfilled, () => ({ ...initialState }))
      .addCase(cancelAddOn.rejected, (state) => {
        state.loading = false
      })
  },
})

appListenerMiddleware.startListening({
  actionCreator: cancelAddOn.fulfilled,
  effect: (action, { getState, dispatch }) => {
    if (
      action.payload === AddOnSlug.AI &&
      getIsUserFlagEnabled(getState(), UserFlag.ENABLE_AI_INTERVIEWS_GENERAL)
    ) {
      dispatch(cancelAddOnModalSlice.actions.openInterviewModal())
    }
  },
})

export const cancelAddOnModalActions = {
  ...cancelAddOnModalSlice.actions,
  cancelAddOn,
}

import { l } from '@manychat/manyui'

import { alert } from 'common/core'
import AccountModel from 'common/core/models/Account'
import { getAccountStatus } from 'common/core/selectors/appSelectors'
import { BillingApi } from 'shared/api/requests/billing'
import { createAsyncAction } from 'shared/lib/redux'

interface ApplyCoupon {
  coupon: string
  applyToCart?: boolean
}

export const applyCoupon = createAsyncAction(
  'billing/applyCoupon',
  async (payload: ApplyCoupon, thunkApi) => {
    const { coupon, applyToCart } = payload
    const state = thunkApi.getState()
    const accountStatus = getAccountStatus(state)

    const request =
      applyToCart || !AccountModel.isProOrTrialStatus(accountStatus)
        ? BillingApi.applyCoupon
        : BillingApi.applyCouponToSubscription

    const { data } = await request({ body: { coupon } })

    if (data.pro) {
      alert(l.translate('Your coupon has been applied'), 'success')
    }

    return data
  },
  {
    onBusinessError: (error) => {
      if (
        BillingApi.applyCoupon.isAccountError(error) ||
        BillingApi.applyCoupon.isCouponError(error)
      ) {
        error.handle()
        alert(error.original_message, 'danger')
      }
    },
  },
)

export const resetCoupon = createAsyncAction('billing/resetCoupon', async () => {
  const response = await BillingApi.resetCoupon()

  return response.data
})

import hash from 'hash-sum'
import get from 'lodash/get'

import { getCustomActions } from './../getCustomActions'

export const makeLimitedListActions = (entity, options = {}) => {
  const prefix = entity.toUpperCase()
  const storePath = options.storePath || entity
  const withoutHash = options?.withoutHash ?? false

  const actions = {
    drop: () => ({
      type: `${prefix}_DROP_LIST`,
    }),

    fetch: (urlParams, body, options = {}) => {
      urlParams = urlParams || {}

      return (dispatch, getState) => {
        const state = getState()
        const d = get(state, storePath)
        if (d == null) {
          return console.error(
            `[${entity}_fetch] Entity not found in store. Did you forget to create reducer or specify actions 'storePath' option?`,
          )
        }
        const fetchArgs = { urlParams, body }
        const fetchArgsHash = hash(fetchArgs)
        const dropItems = fetchArgsHash !== d.fetchArgsHash || withoutHash
        if (!dropItems) {
          // Items already fetching or fetched, nothing to do
          if (d.fetching || d.items != null) {
            if (d.limiter != null) {
              return console.warn(
                `[${entity}_fetch] please use "fetchNext" method to retrieve next pages`,
              )
            }
            return
          }
        }
        // urlParams.limit = 5

        return dispatch({
          type: `${prefix}_FETCH`,
          $endpoint: { name: `${entity}.fetch`, urlParams, body },
          fetchArgs,
          fetchArgsHash,
          dropItems,
          $error: options?.$error,
        })
      }
    },

    fetchNext: (options = {}) => {
      return (dispatch, getState) => {
        const state = getState()
        const d = get(state, storePath)
        if (d == null) {
          return console.error(
            `[${entity}_fetchNext] Entity not found in store. Did you forget to create reducer or specify actions 'storePath' option?`,
          )
        }

        if (d.fetching) {
          return
        }

        if (d.items == null) {
          return console.warn(
            `[${entity}_fetchNext] please use "fetch" method to retrieve first page`,
          )
        }

        if (d.listEarlyLimiter == null) {
          return console.warn(
            `[${entity}_fetchNext] listEarlyLimiter is null - cannot fetch next page`,
          )
        }

        if (!d.listEarlyLimiter) {
          return console.warn(`[${entity}_fetchNext] listEarlyLimiter is not defined`)
        }

        let { urlParams, body } = d.fetchArgs
        urlParams = { ...urlParams, limiter: d.listEarlyLimiter }

        return dispatch({
          type: `${prefix}_FETCH`,
          $endpoint: { name: `${entity}.fetch`, urlParams, body },
          limiterType: 'early',
          $error: options?.$error,
        })
      }
    },

    fetchPrev: (options = {}) => {
      return (dispatch, getState) => {
        const state = getState()
        const d = get(state, storePath)
        if (d == null) {
          return console.error(
            `[${entity}_fetchPrev] Entity not found in store. Did you forget to create reducer or specify actions 'storePath' option?`,
          )
        }

        if (d.fetching) {
          return
        }

        if (d.items == null) {
          return console.warn(
            `[${entity}_fetchPrev] please use "fetch" method to retrieve first page`,
          )
        }

        if (d.listLaterLimiter == null) {
          return console.warn(`[${entity}_fetchPrev] laterLimiter is null - cannot fetch next page`)
        }

        if (!d.listLaterLimiter) {
          return console.warn(`[${entity}_fetchPrev] listLaterLimiter is not defined`)
        }

        let { urlParams, body } = d.fetchArgs
        urlParams = { ...urlParams, limiter: d.listLaterLimiter, limiter_type: 'later' }

        dispatch({
          type: `${prefix}_FETCH`,
          $endpoint: { name: `${entity}.fetch`, urlParams, body },
          limiterType: 'later',
          $error: options?.$error,
        })
      }
    },

    create: (initial, urlParams, options = {}) => ({
      type: `${prefix}_CREATE`,
      $endpoint: [`${entity}.create`, urlParams, initial],
      initial,
      $error: options?.$error,
      $success: options?.$success,
    }),

    update: (id, data, path, replace) => ({
      type: `${prefix}_UPDATE`,
      id,
      data,
      path,
      replace,
    }),

    save: (id, urlParams, item) => {
      return (dispatch, getState) => {
        item = item || get(getState(), storePath).byId[id]

        return dispatch({
          type: `${prefix}_SAVE`,
          $endpoint: [`${entity}.save`, urlParams, item],
        })
      }
    },

    delete: (id, body) => ({
      type: `${prefix}_DELETE`,
      $endpoint: [`${entity}.delete`, { id }, body],
      id,
    }),

    archive: (id, body) => ({
      type: `${prefix}_ARCHIVE`,
      $endpoint: [`${entity}.archive`, { id }, body],
      id,
    }),

    createdNotification: (item) => ({
      type: `${prefix}_CREATED_NOTIFICATION`,
      entity,
      item,
    }),

    updatedNotification: (item) => ({
      type: `${prefix}_UPDATED_NOTIFICATION`,
      entity,
      item,
    }),

    deletedNotification: (id) => ({
      type: `${prefix}_DELETED_NOTIFICATION`,
      entity,
      id,
    }),
  }

  const customActions = getCustomActions(entity, prefix)

  return { ...actions, ...customActions }
}

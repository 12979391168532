const FLOAT_WITH_UNITS = /([-+]?[0-9]*\.?[0-9]+)(px|%)/

const VALUES_MAP = {
  center: '50%',
  middle: '50%',
  top: '0%',
  bottom: '100%',
  left: '0%',
  right: '100%',
}

export const parsePosition = (origin, def = { x: 50, xUnits: '%', y: 50, yUnits: '%' }) => {
  if (!origin || typeof origin !== 'string') {
    return def
  }
  const res = { ...def }
  const axes = ['x', 'y']
  let axesIndex = 0

  origin
    .trim()
    .split(' ')
    .map((v) => v.trim())
    .sort((a, b) => {
      if (a === 'bottom' || a === 'top' || b === 'left' || b === 'right') {
        return 1
      }
      // IE/EDGE sort requires to explicitly return -1
      if (a === 'left' || a === 'right' || b === 'bottom' || b === 'top') {
        return -1
      }
      return 0
    })
    .map((v) => {
      // Parse only two values
      if (axesIndex > 1) {
        return
      }

      v = VALUES_MAP[v] || v
      const matches = FLOAT_WITH_UNITS.exec(v)
      if (matches) {
        res[axes[axesIndex]] = +matches[1]
        res[`${axes[axesIndex]}Units`] = matches[2]
      }

      axesIndex++
    })

  return res
}

import React from 'react'

import cm from './Skeleton.module.css'

interface IProps {
  lines?: number
  height?: number
  className?: string
  minLineWidth?: string
}

const SkeletonText = ({ lines = 1, height, className, minLineWidth }: IProps) => (
  <div className={className}>
    {new Array(lines).fill({}).map((_, i) => (
      <div
        key={i}
        className={`${cm.skeletonText} ${i ? 'm-t-xs' : ''}`}
        style={{
          height,
          width: i && i === lines - 1 ? '75%' : '100%',
          ...(minLineWidth ? { minWidth: minLineWidth } : {}),
        }}
      />
    ))}
  </div>
)

export default SkeletonText
SkeletonText.displayName = 'Skeleton.Text'

enum Adapters {
  SAVE_FIRST_NAME_TO_SYSTEM_FIELD = 'save_first_name_to_system_field',
  SAVE_LAST_NAME_TO_SYSTEM_FIELD = 'save_last_name_to_system_field',
  SAVE_PHONE_TO_SYSTEM_FIELD = 'save_phone_to_system_field',
  SAVE_PHONE_TO_WA_ID_SYSTEM_FIELD = 'save_phone_to_wa_id_system_field',
  SET_SMS_OPTIN = 'set_sms_optin',
  SAVE_EMAIL_TO_SYSTEM_FIELD = 'save_email_to_system_field',
  SET_EMAIL_OPTIN = 'set_email_optin',
  SAVE_ANSWER_TO_CUSTOM_FIELD = 'save_answer_to_custom_field',
}

export default Adapters

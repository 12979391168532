export const MenuRoutes = [
  {
    path: 'menu',
    children: [
      {
        path: ':menu_id?/:mode?',
        lazy: () => import('./menu.page'),
      },
      {
        path: ':menu_id/:mode/:flow_id/:flow_mode?/:content_id?',
        lazy: () => import('./menu-flow-editor.page'),
      },
    ],
  },
] as const

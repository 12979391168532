import { createSlice, createAction, PayloadAction } from '@reduxjs/toolkit'
import { createAppAsyncThunk } from 'reduxTyped'
import { l } from '@manychat/manyui'

import { alert } from 'common/core'
import { getConnectedWhatsAppChannel } from 'common/core/selectors/channelSelectors'
import { handleCatch } from 'shared/api/lib/errors/handlers'
import { applyHandlerByGuard, reportUnhandledErrors } from 'shared/api/lib/errors/utils'
import { WhatsAppApi } from 'shared/api/requests/whatsApp'
import { whatsappGuards } from 'shared/api/requests/whatsApp/guards'
import {
  RegistrationFlowStep,
  RequestCodeResponse,
  VerifyCodeStatusResponse,
  WhatsAppChannelData,
  WhatsAppChannelState,
  WhatsAppHealthWidgetData,
} from 'shared/api/requests/whatsApp/schemas'
import { createAsyncAction } from 'shared/lib/redux'
import { isRequestError } from 'utils/api/mcApi/RequestError'
import errorTrackingService from 'utils/services/errorTrackingService'

interface LegacyAction {
  whatsapp_channel: WhatsAppChannelData
}

const initialState: WhatsAppChannelState = {
  state: RegistrationFlowStep.NONE,
  bought_phone: null,
}

const uploadWhatsAppAvatar = createAction<LegacyAction>('UPLOAD_WHATSAPP_BUSINESS_AVATAR_RESPONSE')
const uploadWhatsAppSettings = createAction<LegacyAction>(
  'UPDATE_WHATSAPP_BUSINESS_SETTINGS_RESPONSE',
)

export const verifyPhoneCode = createAsyncAction<VerifyCodeStatusResponse, string>(
  'whatsapp/verifyPhoneCode',
  async (code) => {
    const response = await WhatsAppApi.verifyCode({ body: { code } })
    return response.data
  },
  {
    onError: (error, _, { rejectWithValue }) => {
      if (error instanceof Error) {
        rejectWithValue({
          message: error.message,
          original_message: error.message,
        })
      }
      return false
    },
    autoHandleByGuards: [whatsappGuards.verifyCode.IsCloudApiReconnectError],
  },
)

export const clearNewBusinessAccountNameData = createAsyncAction(
  'whatsapp/confirmNewBusinessAccountName',
  async () => {
    await WhatsAppApi.clearNewBusinessAccountNameData()
  },
)

export const requestPhoneCode = createAsyncAction<RequestCodeResponse, string>(
  'whatsapp/requestPhoneCode',
  async (method) => {
    const response = await WhatsAppApi.requestCode({ body: { method } })
    return response.data
  },
  {
    onError: (error, _, { rejectWithValue }) => {
      if (error instanceof Error) {
        rejectWithValue({
          message: error.message,
          original_message: error.message,
        })
      }
      return false
    },
  },
)
export const getChannelData = createAppAsyncThunk<WhatsAppChannelData | null>(
  'whatsAppChannel/channelData',
  async () => {
    try {
      const result = await WhatsAppApi.getChannelData()
      return result.data.whatsapp_channel
    } catch (error) {
      handleCatch(error)
      return null
    }
  },
)

export const refreshHealthWidgetData = createAppAsyncThunk<WhatsAppHealthWidgetData | null>(
  'whatsAppChannel/refreshHealthWidgetData',
  async () => {
    try {
      const result = await WhatsAppApi.refreshHealthWidgetData()
      return result.data
    } catch (error) {
      handleCatch(error, (responseError) => {
        const unhandledErrors = applyHandlerByGuard(
          responseError.$errors,
          whatsappGuards.refreshHealthWidgetData.IsStillUpToDateError,
          () => alert(l.translate('The data is up-to-date. Try refreshing later.')),
        )

        reportUnhandledErrors(unhandledErrors, responseError.endpoint)
      })
      return null
    }
  },
)

export const confirmNewBusinessAccountName = createAppAsyncThunk<{ isError?: boolean }>(
  'whatsAppChannel/confirmNewBusinessAccountName',
  async () => {
    try {
      await WhatsAppApi.confirmNewBusinessAccountName()
      alert(l.translate('Display Name change approved'), 'success')
      return { isError: false }
    } catch (error) {
      handleCatch(error, (responseError) => {
        const unhandledErrors = applyHandlerByGuard(
          responseError.$errors,
          whatsappGuards.confirmNewBusinessAccountName.IsCannotConfirmNewNameError,
          () =>
            alert(
              l.translate(
                'Contact our Support to reverify your WhatsApp phone number and complete Display Name change',
              ),
              'danger',
            ),
        )

        reportUnhandledErrors(unhandledErrors, responseError.endpoint)
      })

      return { isError: true }
    }
  },
)

export const updateWAChannel = createAppAsyncThunk<
  void,
  WhatsAppChannelData | null | Partial<WhatsAppChannelData>
>('whatsAppChannel/settings/update', (data, thunkAPI) => {
  const waState = {
    ...initialState,
    ...data,
  }
  thunkAPI.dispatch(update(waState))
})

export const disconnectChannel = createAppAsyncThunk('whatsAppChannel/disconnect', async () => {
  try {
    await WhatsAppApi.disconnectChannel()
  } catch (error) {
    handleCatch(error)
  }
})

export const updateBusinessProfile = createAppAsyncThunk<
  WhatsAppChannelData,
  { field: string; value: string | string[] }
>('whatsAppChannel/updateBusinessProfile', async (data, { rejectWithValue }) => {
  try {
    const response = await WhatsAppApi.setWhatsAppBusinessProfile({ body: data })
    return response.data.whatsapp_channel
  } catch (error) {
    if (isRequestError(error) && error.reason === 'business-logic') {
      return rejectWithValue({
        message: (error as Error).message,
        original_message: (error as Error).message,
      })
    }
    errorTrackingService.trackError(error, { fingerprint: 'settings_update_wa_settings' })
    return rejectWithValue({
      message: (error as Error).message,
      original_message: (error as Error).message,
    })
  }
})

export const updateProfilePhoto = createAppAsyncThunk<WhatsAppChannelData, Blob>(
  'whatsAppChannel/updateProfilePhoto',
  async (avatar, { rejectWithValue }) => {
    const formData = new FormData()
    formData.append('photo', avatar)

    try {
      const response = await WhatsAppApi.setWhatsAppProfilePhoto({ body: formData })
      alert(l.translate('Profile photo successfully updated'), 'success')
      return response.data.whatsapp_channel
    } catch (error) {
      if (isRequestError(error) && error.reason === 'business-logic') {
        return rejectWithValue({
          message: (error as Error).message,
          original_message: (error as Error).message,
        })
      }
      errorTrackingService.trackError(error, { fingerprint: 'settings_update_wa_avatar' })
      return rejectWithValue({
        message: (error as Error).message,
        original_message: (error as Error).message,
      })
    }
  },
)

const waChannelSlice = createSlice({
  name: 'whatsAppChannel',
  initialState,
  reducers: {
    update: (state, action: PayloadAction<Partial<WhatsAppChannelData>>) => {
      Object.assign(state, action.payload)
      return state
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getChannelData.pending, (state) => {
        state.loading = true
      })
      .addCase(refreshHealthWidgetData.pending, (state) => {
        state.loading = true
      })
      .addCase(getChannelData.fulfilled, (state, action) => {
        state.loading = false
        state.error = false
        Object.assign(state, action.payload || {})
      })
      .addCase(confirmNewBusinessAccountName.fulfilled, (state, action) => {
        if (action.payload.isError) {
          state.isConfirmNewNameError = true
        } else {
          state.business_account_name = state.new_business_account_name || ''
          state.new_business_account_name = null
        }
      })
      .addCase(refreshHealthWidgetData.fulfilled, (state, action) => {
        state.loading = false
        state.error = false
        Object.assign(state, action.payload || {})
      })
      .addCase(getChannelData.rejected, (state) => {
        state.loading = false
        state.error = true
      })
      .addCase(uploadWhatsAppAvatar, (state, action) => {
        state.photo = action.payload.whatsapp_channel.photo
      })
      .addCase(uploadWhatsAppSettings, (state, action) => {
        Object.assign(state, action.payload.whatsapp_channel)
      })
      .addCase(disconnectChannel.pending, (state) => {
        state.loading = true
      })
      .addCase(disconnectChannel.fulfilled, (state) => {
        state.loading = false
        Object.assign(state, initialState)
      })
      .addCase(disconnectChannel.rejected, (state) => {
        state.loading = false
        state.error = true
      })
      .addCase(requestPhoneCode.pending, (state) => {
        state.loading = true
        state.error = false
      })
      .addCase(requestPhoneCode.fulfilled, (state) => {
        state.loading = false
      })
      .addCase(requestPhoneCode.rejected, (state) => {
        state.loading = false
        state.error = true
      })
      .addCase(updateBusinessProfile.fulfilled, (state, action) => {
        Object.assign(state, action.payload)
      })
      .addCase(updateProfilePhoto.fulfilled, (state, action) => {
        Object.assign(state, action.payload)
      })
  },
})

export const { reducer } = waChannelSlice
export const { update } = waChannelSlice.actions

export const getIsConfirmNewNameErrorSelector = (state: RootState) =>
  getConnectedWhatsAppChannel(state)?.isConfirmNewNameError ?? false

export const getIsWaChannelDataLoadingSelector = (state: RootState) =>
  getConnectedWhatsAppChannel(state)?.loading ?? false

export const getIsWaChannelDataErrorSelector = (state: RootState) =>
  getConnectedWhatsAppChannel(state)?.error ?? false

export enum CmsDragSource {
  FLOW_CARD = 'flow_card',
  FOLDER_CARD = 'folder_card',
}

export interface FlowDragItem {
  type: CmsDragSource.FLOW_CARD
  path: string
  id: string
}

export interface FolderDragItem {
  type: CmsDragSource.FOLDER_CARD
  path: string
  id: number
  name: string
}

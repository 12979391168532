import React from 'react'
import cx from 'classnames'

import cleanProps from '../../../lib/clean-react-props'

import { FormFieldGroupProps } from './interfaces'

import cm from './FormFieldGroup.module.css'

export const FormFieldGroup = (props: Omit<FormFieldGroupProps, 'name'>) => {
  return (
    <div
      {...cleanProps(props, [], [])}
      id={props.id}
      className={cx(cm.formFieldGroup, props.className)}
      tabIndex={props.tabIndex}
    >
      {props.title && (
        <label htmlFor={props.id} className={cx(cm.formFieldGroup__label)}>
          {props.title}
        </label>
      )}
      {props.children}
    </div>
  )
}

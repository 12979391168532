import filter from 'lodash/filter'
import sortBy from 'lodash/sortBy'
import without from 'lodash/without'
import { l } from '@manychat/manyui'

import { isBroadcast } from 'apps/posting/models/Post/helpers'
import { getArePaymentsEnabled } from 'common/billing/selectors/billingSelectors'
import { isCrossChannelContent, isSmartLink } from 'common/builder/buttons/buttonTypeHelpers'
import { BlockType } from 'common/builder/constants/BlockType'
import { ButtonType } from 'common/builder/constants/ButtonType'
import EntityType from 'common/builder/constants/EntityType'
import { NodeType } from 'common/builder/constants/NodeType'
import { getSmartLinkIdsInText } from 'common/builder/models/Button/helpers'
import { NextStepType } from 'common/builder/models/Source/constants'
import builderSelectors from 'common/builder/selectors/builder'
import * as builderStateSelectors from 'common/builder/selectors/builder/builderStateSelectors'
import * as entitySelectors from 'common/builder/selectors/builder/entitySelectors'
import { ChannelType } from 'common/core/constants/ChannelType'
import { getCurrentAccount } from 'common/core/selectors/appSelectors'
import {
  getIsEmailNodeEnabled,
  hasConnectedInstagramChannel,
  hasConnectedSMSChannel,
  hasConnectedTelegramChannel,
  hasConnectedTiktokChannel,
  hasConnectedWhatsAppChannel,
  isFBChannelConnected,
} from 'common/core/selectors/channelSelectors'
import * as flowSelectors from 'common/flow/selectors/flowSelectors'
import { accountFlags } from 'utils/services/featureFlagsService'
import { AccountFlag } from 'utils/services/featureFlagsService/constants'

export interface INextStepOption {
  type: NextStepType
  nodeType?: NodeType
  category?: 'content' | 'logic' | 'extras' | 'ai'
  channel?: ChannelType
  missingChannels?: ChannelType[]
}

export const PRIMARY_CHANNEL_NEXT_STEP_TYPES = [
  NextStepType.INSTAGRAM,
  NextStepType.WHATS_APP,
  NextStepType.CONTENT,
  NextStepType.TELEGRAM,
  NextStepType.TIKTOK,
]

export const CHANNEL_NEXT_STEP_TYPES = [
  NextStepType.CONTENT,
  NextStepType.INSTAGRAM,
  NextStepType.WHATS_APP,
  NextStepType.TELEGRAM,
  NextStepType.SMS,
  NextStepType.EMAIL_NEW,
  NextStepType.TIKTOK,
]

const NEXT_STEP_NODES = [
  NextStepType.CONTENT,
  NextStepType.INSTAGRAM,
  NextStepType.WHATS_APP,
  NextStepType.TELEGRAM,
  NextStepType.SMS,
  NextStepType.EMAIL_NEW,
  NextStepType.AI_AGENT,
  NextStepType.ACTION_GROUP,
  NextStepType.CONDITION,
  NextStepType.SPLIT,
  NextStepType.SMART_DELAY,
  NextStepType.GOTO,
  NextStepType.TIKTOK,
]

const NEXT_STEP_TYPES_SORTED = [
  NextStepType.INSTAGRAM,
  NextStepType.TIKTOK,
  NextStepType.WHATS_APP,
  NextStepType.CONTENT,
  NextStepType.TELEGRAM,
  NextStepType.SMS,
  NextStepType.EMAIL_NEW,
  NextStepType.AI_AGENT,
  NextStepType.URL,
  NextStepType.CTA_URL,
  NextStepType.CALL,
  NextStepType.BUY,
  NextStepType.FLOW,
  NextStepType.ACTION_GROUP,
  NextStepType.CONDITION,
  NextStepType.SPLIT,
  NextStepType.SMART_DELAY,
  NextStepType.GOTO,
]

export const getNextStepOptions = (
  state: RootState,
  builderId: string,
  entityId: string,
): INextStepOption[] => {
  const nextStepTypes = getNextStepTypes(state, builderId, entityId)
  if (!nextStepTypes.length) {
    return []
  }
  const currentNode = builderSelectors.builderState.getCurrentNode(state, builderId)
  const entityBlock = entitySelectors.getParentBlock(state, builderId, entityId)
  const entityBlockType = entityBlock?.type as BlockType
  const { isAvailable: isWaCtaButtonAvailable, blockHasSmartLinks } = getIsWaCtaButtonAvailable(
    state,
    builderId,
    entityId,
  )
  const flowId = builderStateSelectors.getById(state, builderId)?.flow

  let nextStepOptions = nextStepTypes.map((type) => ({ type }))

  const { is_buy_button_enabled, payments_settings_enabled, payments_enabled } =
    getCurrentAccount(state)

  const disableBuyButton = payments_enabled && !payments_settings_enabled && !is_buy_button_enabled
  const disallowBuyButton =
    currentNode?.nodeType !== NodeType.TELEGRAM &&
    [BlockType.TEXT, BlockType.LIST].includes(entityBlockType)
  if (disableBuyButton || disallowBuyButton) {
    let tooltip = ''
    if (disableBuyButton) {
      tooltip = l.translate(
        'Unfortunately, Stripe account isn’t connected and you have insufficient rights to do that. Please, contact page’s Owner.',
      )
    }
    if (disallowBuyButton) {
      tooltip = l.translate(
        'To add Buy Button, select a proper element: Card, Gallery, Image or Video.',
      )
    }

    nextStepOptions = nextStepOptions.map((o) =>
      o.type === NextStepType.BUY
        ? {
            ...o,
            disabled: true,
            tooltip,
          }
        : o,
    )
  }

  if (!isWaCtaButtonAvailable) {
    const tooltip = blockHasSmartLinks
      ? l.translate(
          'It’s not possible to add Open Website button because you already added an inline link. Remove inline link from the message first.',
        )
      : 'Only one Open Website button is available per one WhatsApp message. Remove other buttons from the message first.'
    nextStepOptions = nextStepOptions.map((o) =>
      o.type === NextStepType.CTA_URL
        ? {
            ...o,
            disabled: true,
            tooltip,
          }
        : o,
    )
  }

  if (isBroadcast(flowId)) {
    nextStepOptions = nextStepOptions.filter(({ type }) => type !== NextStepType.TIKTOK)
  }

  let optionsSorted = sortBy(nextStepOptions, (o) => NEXT_STEP_TYPES_SORTED.indexOf(o.type))

  const rootNode = builderStateSelectors.getRootNode(state, builderId)
  const rootNodeNextStepType = rootNode?.nodeType as unknown as NextStepType
  if (rootNode && PRIMARY_CHANNEL_NEXT_STEP_TYPES.includes(rootNodeNextStepType)) {
    optionsSorted = sortBy(optionsSorted, (o) => (o.type === rootNodeNextStepType ? 0 : 1))
  }

  const nodeTypeBasedOnFlowTriggers = flowSelectors.getFlowPrimaryNodeType(
    state,
    flowId,
  ) as unknown as NextStepType
  if (
    nodeTypeBasedOnFlowTriggers &&
    optionsSorted.some((o) => o.type === nodeTypeBasedOnFlowTriggers)
  ) {
    optionsSorted = sortBy(optionsSorted, (o) => (o.type === nodeTypeBasedOnFlowTriggers ? 0 : 1))
  }

  return optionsSorted
}

export const getNextStepTypes = (
  state: RootState,
  builderId: string,
  entityId: string,
): NextStepType[] => {
  const entity = entitySelectors.getById(state, builderId, entityId)

  if (!entity) {
    return []
  }

  const isStartingStepNode =
    entity.entity === EntityType.NODE && entity.nodeType === NodeType.STARTING_STEP

  if (!isStartingStepNode && !('targetId' in entity)) {
    return []
  }

  const isButton = entity.entity === EntityType.BUTTON

  if (isButton && [ButtonType.URL, ButtonType.CTA_URL].includes(entity.type)) {
    return [NextStepType.ACTION_GROUP]
  }

  if (
    isButton &&
    ![
      ButtonType.CONTENT,
      ButtonType.CROSS_CHANNEL_CONTENT,
      ButtonType.BUY,
      ButtonType.WA_LIST_MESSAGE_BUTTON,
    ].includes(entity.type)
  ) {
    return []
  }

  const isBlock = entity.entity === EntityType.BLOCK
  if (isBlock && entity.type === BlockType.FORM_QUESTION) {
    return [NextStepType.ACTION_GROUP]
  }

  const parentNode = entitySelectors.getParentNode(state, builderId, entityId)
  const parentNodeType = parentNode?.nodeType as NodeType
  const parentBlock = entitySelectors.getParentBlock(state, builderId, entityId)

  let nextStepTypes: NextStepType[] = [...NEXT_STEP_NODES]

  if (isButton) {
    nextStepTypes = [...nextStepTypes, ...getButtonExtraNextStepTypes(state, builderId, entityId)]
  }

  // primary channel node be connected to the same primary channel only
  const entityNextStepType = parentNodeType as unknown as NextStepType
  if (PRIMARY_CHANNEL_NEXT_STEP_TYPES.includes(entityNextStepType)) {
    nextStepTypes = [
      entityNextStepType,
      ...without(nextStepTypes, ...PRIMARY_CHANNEL_NEXT_STEP_TYPES),
    ]
  }

  const enabledIGCrossChannelButton = accountFlags.isEnabled(
    AccountFlag.ENABLE_IG_CROSS_CHANNEL_BUTTON,
  )

  if (
    isButton &&
    enabledIGCrossChannelButton &&
    entity.type === ButtonType.CONTENT &&
    [NextStepType.CONTENT, NextStepType.TELEGRAM].includes(entityNextStepType) &&
    parentBlock?.type !== BlockType.QUICK_REPLY &&
    parentBlock?.type !== BlockType.TELEGRAM_KEYBOARD &&
    parentBlock?.type !== BlockType.ONE_TIME_NOTIFY_REQUEST
  ) {
    nextStepTypes = [...nextStepTypes, NextStepType.INSTAGRAM]
  }

  // IG buttons can connect Telegram
  if (
    entityNextStepType === NextStepType.INSTAGRAM &&
    isButton &&
    entity.type === ButtonType.CONTENT
  ) {
    nextStepTypes = [...nextStepTypes, NextStepType.TELEGRAM]
  }

  // smart link button can be connected to the messenger primary channel only
  if (isButton && isSmartLink(entity) && !isCrossChannelContent(entity)) {
    const contentChannelsWithoutMessenger = without(CHANNEL_NEXT_STEP_TYPES, NextStepType.CONTENT)
    nextStepTypes = without(nextStepTypes, ...contentChannelsWithoutMessenger)
  }

  if (isButton && isCrossChannelContent(entity)) {
    const crossChannel = ('channel' in entity && entity.channel) as ChannelType
    const nextCrossAvailableStep =
      crossChannel === ChannelType.FB ? NextStepType.CONTENT : NextStepType.INSTAGRAM

    const nextStepTypeWithCrossChannel = [...nextStepTypes, nextCrossAvailableStep]
    const contentForExcludeFromNextStepType = without(
      CHANNEL_NEXT_STEP_TYPES,
      nextCrossAvailableStep,
    )
    nextStepTypes = without(nextStepTypeWithCrossChannel, ...contentForExcludeFromNextStepType)
  }

  // goto inst allowed to be the root node
  if (isStartingStepNode) {
    nextStepTypes = without(nextStepTypes, NextStepType.GOTO)
  }

  // only connected or already used in flow channels can be connected
  const allowedContentNextSteps = getAllowedInFlowContentNextSteps(state, builderId)
  const notAllowedNextSteps = without(CHANNEL_NEXT_STEP_TYPES, ...allowedContentNextSteps)
  if (notAllowedNextSteps.length) {
    nextStepTypes = without(nextStepTypes, ...notAllowedNextSteps)
  }

  const builderState = builderStateSelectors.getById(state, builderId)

  // only FLOW and URL are available in simple builder mode
  const isSimpleBuilderMode = builderState.config.isCgt || builderState.config.isSimple
  if (isSimpleBuilderMode) {
    nextStepTypes = nextStepTypes.filter((type) =>
      [NextStepType.FLOW, NextStepType.URL].includes(type),
    )
  }

  // FLOW step type is available only in simple builder mode
  if (!isSimpleBuilderMode) {
    nextStepTypes = without(nextStepTypes, NextStepType.FLOW)
  }

  // BUY button available only when payments enabled
  if (!getArePaymentsEnabled(state)) {
    nextStepTypes = without(nextStepTypes, NextStepType.BUY)
  }

  return nextStepTypes
}

export const getButtonExtraNextStepTypes = (
  state: RootState,
  builderId: string,
  buttonId: string,
): NextStepType[] => {
  const button = entitySelectors.getButtonById(state, builderId, buttonId)

  if (button?.type !== ButtonType.CONTENT) {
    return []
  }

  const parentNode = entitySelectors.getParentNode(state, builderId, buttonId)
  const parentBlock = entitySelectors.getParentBlock(state, builderId, buttonId)
  const parentBlockType = parentBlock?.type as BlockType

  const isInMessengerNode = parentNode?.nodeType === NodeType.CONTENT
  if (isInMessengerNode) {
    if ([BlockType.CARD, BlockType.ATTACHMENT, BlockType.TEXT].includes(parentBlockType)) {
      return [NextStepType.URL, NextStepType.CALL, NextStepType.BUY, NextStepType.FLOW]
    } else if ([BlockType.LIST_ITEM, BlockType.LIST].includes(parentBlockType)) {
      return [NextStepType.URL, NextStepType.BUY, NextStepType.FLOW]
    } else {
      return [NextStepType.FLOW]
    }
  }

  const isInInstagramNode = parentNode?.nodeType === NodeType.INSTAGRAM

  if (isInInstagramNode) return getIGNextStepExtraButtons(parentBlockType)

  const isInTelegramNode = parentNode?.nodeType === NodeType.TELEGRAM
  if (isInTelegramNode) {
    if ([BlockType.TEXT, BlockType.ATTACHMENT].includes(parentBlockType)) {
      return [NextStepType.URL, NextStepType.BUY]
    }
  }

  if (parentNode?.nodeType === NodeType.WHATS_APP && parentBlockType === BlockType.TEXT) {
    return [NextStepType.CTA_URL]
  }

  return []
}

function getIGNextStepExtraButtons(parentBlockType: BlockType) {
  const parentIsText = BlockType.TEXT === parentBlockType
  const parentIsCard = BlockType.CARD === parentBlockType
  const extraButtons = [NextStepType.URL]

  if (parentIsCard) return extraButtons.concat(NextStepType.BUY)
  if (parentIsText) return extraButtons
  return []
}

export const getConnectedContentNextSteps = (state: RootState): NextStepType[] => {
  return [
    { check: isFBChannelConnected, step: NextStepType.CONTENT },
    { check: getIsEmailNodeEnabled, step: NextStepType.EMAIL_NEW },
    { check: hasConnectedInstagramChannel, step: NextStepType.INSTAGRAM },
    { check: hasConnectedWhatsAppChannel, step: NextStepType.WHATS_APP },
    { check: hasConnectedSMSChannel, step: NextStepType.SMS },
    { check: hasConnectedTelegramChannel, step: NextStepType.TELEGRAM },
    { check: hasConnectedTiktokChannel, step: NextStepType.TIKTOK },
  ]
    .filter(({ check }) => check(state))
    .map(({ step }) => step)
}

export const getAllowedInFlowContentNextSteps = (
  state: RootState,
  builderId: string,
): NextStepType[] => {
  const connectedContentNextSteps = getConnectedContentNextSteps(state)

  const usedInFlowContentNextSteps: NextStepType[] = []
  const nodes = entitySelectors.getNodesList(state, builderId)
  CHANNEL_NEXT_STEP_TYPES.forEach((nextStepType) => {
    const nodeType = nextStepType as unknown as NodeType
    if (
      !connectedContentNextSteps.includes(nextStepType) &&
      nodes.some((n) => n.nodeType === nodeType)
    )
      connectedContentNextSteps.push(nextStepType)
  })

  return [...connectedContentNextSteps, ...usedInFlowContentNextSteps]
}

export const getCreateNodeOptions = (state: RootState, builderId: string): INextStepOption[] => {
  const options: INextStepOption[] = [
    {
      type: NextStepType.INSTAGRAM,
      nodeType: NodeType.INSTAGRAM,
      channel: ChannelType.INSTAGRAM,
      category: 'content',
    },
    {
      type: NextStepType.TIKTOK,
      nodeType: NodeType.TIKTOK,
      channel: ChannelType.TIKTOK,
      category: 'content',
    },
    {
      type: NextStepType.WHATS_APP,
      nodeType: NodeType.WHATS_APP,
      channel: ChannelType.WHATSAPP,
      category: 'content',
    },
    {
      type: NextStepType.CONTENT,
      nodeType: NodeType.CONTENT,
      channel: ChannelType.FB,
      category: 'content',
    },
    {
      type: NextStepType.TELEGRAM,
      nodeType: NodeType.TELEGRAM,
      channel: ChannelType.TELEGRAM,
      category: 'content',
    },
    {
      type: NextStepType.SMS,
      nodeType: NodeType.SMS,
      channel: ChannelType.SMS,
      category: 'content',
    },
    {
      type: NextStepType.EMAIL_NEW,
      nodeType: NodeType.EMAIL_NEW,
      channel: ChannelType.EMAIL,
      category: 'content',
    },
    {
      type: NextStepType.CONNECT_CHANNELS,
      category: 'content',
      missingChannels: [],
    },
    {
      type: NextStepType.GOTO,
      nodeType: NodeType.GOTO,
      category: 'content',
    },
    {
      type: NextStepType.ACTION_GROUP,
      nodeType: NodeType.ACTION_GROUP,
      category: 'logic',
    },
    {
      type: NextStepType.CONDITION,
      nodeType: NodeType.CONDITION,
      category: 'logic',
    },
    {
      type: NextStepType.SPLIT,
      nodeType: NodeType.SPLIT,
      category: 'logic',
    },
    {
      type: NextStepType.SMART_DELAY,
      nodeType: NodeType.SMART_DELAY,
      category: 'logic',
    },
    {
      type: NextStepType.COMMENT,
      nodeType: NodeType.COMMENT,
      category: 'extras',
    },
    {
      type: NextStepType.AI_AGENT,
      nodeType: NodeType.AI_AGENT,
      category: 'ai',
    },
  ]

  const nextStepChannels = CHANNEL_NEXT_STEP_TYPES

  const allowedContentNextSteps = getAllowedInFlowContentNextSteps(state, builderId)
  const notAllowedNextSteps = without(CHANNEL_NEXT_STEP_TYPES, ...allowedContentNextSteps)
  let filteredOptions = options.filter((o) => !notAllowedNextSteps.includes(o.type))

  const connectedContentNextSteps = getConnectedContentNextSteps(state)
  const notConnectedNextSteps = without(nextStepChannels, ...connectedContentNextSteps)

  if (notConnectedNextSteps.length) {
    const missingChannels = options
      .filter((o) => notConnectedNextSteps.includes(o.type))
      .filter((o) => o.channel)
      .map((o) => o.channel) as ChannelType[]

    filteredOptions = filteredOptions.map((o) =>
      o.type === NextStepType.CONNECT_CHANNELS ? { ...o, missingChannels } : o,
    )
  } else {
    filteredOptions = options.filter((o) => o.type !== NextStepType.CONNECT_CHANNELS)
  }

  const flowId = builderStateSelectors.getById(state, builderId)?.flow

  return isBroadcast(flowId)
    ? filteredOptions.filter((o) => o.type !== NextStepType.TIKTOK)
    : filteredOptions
}

export const getIncomingConnectionEntities = (
  state: RootState,
  builderId: string,
  nodeId: string,
) => {
  if (!nodeId) {
    return []
  }
  const entitiesMap = entitySelectors.getEntitiesMap(state, builderId)
  return filter(entitiesMap, (node) => 'targetId' in node && node.targetId === nodeId)
}

/**
 * Returns true if entity is allowed to have a target node (output connection)
 */
export const isTargetAllowed = (state: RootState, builderId: string, entityId: string) => {
  const entity = entitySelectors.getById(state, builderId, entityId)
  if (!entity) {
    return false
  }

  const targetIsActionGroupWithIncomingUserInputConnections =
    entity.entity === EntityType.NODE &&
    entity.nodeType === NodeType.ACTION_GROUP &&
    getIncomingConnectionEntities(state, builderId, entity.id).some(
      (item) => item.entity === EntityType.BLOCK && item.type === BlockType.FORM_QUESTION,
    )
  if (targetIsActionGroupWithIncomingUserInputConnections) {
    return false
  }

  return true
}

export const isConnectionAllowed = (
  state: RootState,
  builderId: string,
  entityId: string,
  targetId: string,
): boolean => {
  const targetNode = entitySelectors.getNodeById(state, builderId, targetId)
  const entity = entitySelectors.getById(state, builderId, entityId)

  if (!targetNode || !entity) {
    return false
  }

  const parentNode = entitySelectors.getParentNode(state, builderId, entityId)
  if (!parentNode || parentNode.id === targetNode.id) {
    return false
  }

  if (!isTargetAllowed(state, builderId, targetNode.id)) {
    return false
  }

  const isUserInput = entity.entity === EntityType.BLOCK && entity.type === BlockType.FORM_QUESTION
  if (isUserInput) {
    const targetIncomingConnections = getIncomingConnectionEntities(state, builderId, targetNode.id)
    const targetHasOutgoingConnection = 'targetId' in targetNode && targetNode.targetId
    if (targetIncomingConnections.length || targetHasOutgoingConnection) {
      return false
    }
  }

  const allowedNextStepTypes = getNextStepTypes(state, builderId, entityId)
  const targetNextStepType = targetNode.nodeType as unknown as NextStepType
  return allowedNextStepTypes.includes(targetNextStepType)
}

export const getNodesAllowedToConnect = (state: RootState, builderId: string, entityId: string) => {
  const flowId = builderStateSelectors.getById(state, builderId)?.flow

  return entitySelectors
    .getNodesList(state, builderId)
    .filter((n) => n.flow === flowId && isConnectionAllowed(state, builderId, entityId, n.id))
    .sort((a, b) => a.caption.localeCompare(b.caption))
}

export const getCreateNodeGroups = (
  state: RootState,
  builderId: string,
): Array<[INextStepOption[], string]> => {
  const options = getCreateNodeOptions(state, builderId)

  return [
    [options.filter((o) => o.category === 'content'), l.translate('Content')],
    [options.filter((o) => o.category === 'ai'), l.translate('AI')],
    [options.filter((o) => o.category === 'logic'), l.translate('Logic')],
    [options.filter((o) => o.category === 'extras'), l.translate('Extras')],
  ]
}

export const getChooseFirstStepNodeTypeGroups = (
  state: RootState,
  builderId: string,
): Array<[INextStepOption[], string]> => {
  const nextStepOptions = getNextStepOptions(state, builderId, NodeType.STARTING_STEP)
  const contentNodeTypes = nextStepOptions
    .filter((o) => CHANNEL_NEXT_STEP_TYPES.includes(o.type) && o.type !== NextStepType.AI_AGENT)
    .map((o) => ({ ...o, nodeType: o.type as unknown as NodeType }))

  const aiNodeTypes = nextStepOptions
    .filter((o) => o.type === NextStepType.AI_AGENT)
    .map((o) => ({ ...o, nodeType: o.type as unknown as NodeType }))

  const logicNodeTypes = nextStepOptions
    .filter((o) => !CHANNEL_NEXT_STEP_TYPES.includes(o.type) && o.type !== NextStepType.AI_AGENT)
    .map((o) => ({ ...o, nodeType: o.type as unknown as NodeType }))

  if (aiNodeTypes.length === 0) {
    return [
      [contentNodeTypes, l.translate('Content')],
      [logicNodeTypes, l.translate('Logic')],
    ]
  }

  return [
    [contentNodeTypes, l.translate('Content')],
    [aiNodeTypes, l.translate('AI')],
    [logicNodeTypes, l.translate('Logic')],
  ]
}

export const isWaCtaButtonActive = (state: RootState, builderId: string, blockId: string) => {
  const blockButtons = entitySelectors.getButtons(state, builderId, blockId)
  const hasCtaButton =
    blockButtons && blockButtons.findIndex((b) => b.type === ButtonType.CTA_URL) >= 0

  return hasCtaButton
}

export const getIsWaCtaButtonAvailable = (
  state: RootState,
  builderId: string,
  entityId: string,
) => {
  const currentNode = builderSelectors.builderState.getCurrentNode(state, builderId)
  const isWaNode = currentNode?.nodeType === NodeType.WHATS_APP
  const block = entitySelectors.getParentBlock(state, builderId, entityId)

  if (!isWaNode || block?.type !== BlockType.TEXT) return { isAvailable: false }

  const blockButtonsAll = entitySelectors.getButtons(state, builderId, block.id)
  const blockButtons = blockButtonsAll?.filter((button) => !isSmartLink(button))
  const blockHasSmartLinks = Boolean(getSmartLinkIdsInText(block.text)?.length)

  return {
    isAvailable: Boolean(blockButtons?.length <= 1 && !blockHasSmartLinks),
    blockHasSmartLinks,
  }
}

import {
  APPLICATION_USES_LIST_FETCH_REQUEST,
  APPLICATION_USES_LIST_FETCH_RESPONSE,
  APPLICATION_USES_LIST_FETCH_ERROR,
  APPLICATION_UPDATE_MODAL_CLOSE,
} from 'apps/applications/constants/InstalledApplicationsReduxActionTypes'
import { IUsesListState } from 'common/actions/integrations/Applications/interfaces'
import { IEntities } from 'common/settings/components/SettingsPanel/AppSection/installedApplications/interfaces'

interface IAction {
  type: string
  $requestId: number
  applicationId: number
  data?: {
    entities: IEntities
  }
}

const initialState = { requestId: null, applicationId: null, entities: {} }

const usesListReducer = (state: IUsesListState = initialState, action: IAction) => {
  switch (action.type) {
    case APPLICATION_USES_LIST_FETCH_REQUEST:
      state = { requestId: action.$requestId, applicationId: action.applicationId, entities: {} }
      break
    case APPLICATION_USES_LIST_FETCH_RESPONSE:
    case APPLICATION_USES_LIST_FETCH_ERROR:
      if (state.requestId === action.$requestId) {
        state = {
          requestId: null,
          applicationId: state.applicationId,
          entities: action.data?.entities ?? {},
        }
      }
      if (action.type === APPLICATION_USES_LIST_FETCH_ERROR) {
        state.applicationId = null
      }
      break
    case APPLICATION_UPDATE_MODAL_CLOSE:
      state = initialState
  }

  return state
}

export default usesListReducer

import { l } from '@manychat/manyui'

export enum ReasonStatuses {
  ACTIVE = 'active',
  ARCHIVE = 'archived',
}

export enum ReasonTypes {
  ONE_TIME = 'once',
  DAILY = 'daily',
  WEEKLY = 'weekly',
  MONTHLY = 'monthly',
}

export const GetMessages = l.makeTranslate('Get updates')
const NotifyMe = l.makeTranslate('Notify me')

export const NOTIFY_BUTTON_LABELS = {
  [ReasonTypes.ONE_TIME]: NotifyMe,
  [ReasonTypes.DAILY]: GetMessages,
  [ReasonTypes.WEEKLY]: GetMessages,
  [ReasonTypes.MONTHLY]: GetMessages,
}

export const reasonTypeLabels = {
  [ReasonTypes.DAILY]: l.translate('Daily'),
  [ReasonTypes.WEEKLY]: l.translate('Weekly'),
  [ReasonTypes.MONTHLY]: l.translate('Monthly'),
  [ReasonTypes.ONE_TIME]: l.translate('One-Time'),
}

export const reasonTypeSequence = [
  ReasonTypes.ONE_TIME,
  ReasonTypes.DAILY,
  ReasonTypes.WEEKLY,
  ReasonTypes.MONTHLY,
]

export const ModalType = {
  CREATE: 'create',
  EDIT: 'edit',
}

export const ButtonLabel = {
  [ModalType.CREATE]: l.translate('Create'),
  [ModalType.EDIT]: l.translate('Edit'),
}

export const ModalTitle = {
  [ModalType.CREATE]: l.translate('Create New Topic'),
  [ModalType.EDIT]: l.translate('Edit Topic'),
}

export enum ReasonRequestType {
  RECURRING = 'recurring',
  ONE_TIME = 'one_time',
}

export enum NotificationsTableActions {
  EDIT = 'edit',
  ARCHIVE = 'archive',
  DELETE = 'delete',
  RESTORE = 'restore',
}

export const PRIORITY_REASON_SORT_ORDER = {
  [ReasonTypes.ONE_TIME]: 0,
  [ReasonTypes.DAILY]: 1,
  [ReasonTypes.WEEKLY]: 2,
  [ReasonTypes.MONTHLY]: 3,
}

export enum SORT_ORDER {
  ASC = 'asc',
  DESC = 'desc',
}

export enum CreateNotificationsFields {
  REQUEST_TITLE = 'request_title',
  CAPTION = 'caption',
  TYPE = 'type',
}

export const DEFAULT_TOPIC_CAPTION = 'Promo & Updates'

export const QUERY_FROM_PARAMETER_FIRST_RN_MODAL = 'FIRST_RN_MODAL'
export const LOCAL_STORAGE_KEY_FIRST_RN_MODAL_DATA = 'FIRST_RN_MODAL_DATA'

export const RN_BUNDLE_MAXIMUM_BLOCKS_COUNT = 3

import React, { useMemo, useState, useEffect, useRef } from 'react'
import { useAppSelector } from 'reduxTyped'
import { l } from '@manychat/manyui'

import { Chip } from 'apps/cms/components/Flows/components/FlowCard/components/Triggers/components/Chip/Chip'
import { useFlexibleTriggerNameClassName } from 'apps/cms/components/Flows/components/FlowCard/components/Triggers/hooks/useFlexibleTriggerNameClassName'
import { renderTextByNodes } from 'apps/cms/components/Flows/components/FlowCard/components/Triggers/lib/renderTextByNodes'
import { contentManagementSelectors } from 'apps/cms/store'
import { PostCoveredArea } from 'apps/growthTools/models/Widget/constants'
import { CgtWidget } from 'shared/api/requests/cms/schemas/widgets'

import { CgtTitleImg } from './CgtTitleImg'

interface CgtTitleProps {
  widget: CgtWidget
}

export const CgtTitle = ({ widget }: CgtTitleProps) => {
  const hasSearchValue = useAppSelector(contentManagementSelectors.getHasSearchValue)

  const commentSettings = widget.data?.feed_comment_settings || {}

  const titleRef = useRef<HTMLSpanElement | null>(null)

  const [postImageUrl, setPostImageUrl] = useState<string>('')

  useEffect(() => {
    if (!commentSettings.post_data || !titleRef.current) {
      return
    }

    const observer = new IntersectionObserver(
      (entries, observer) => {
        if (entries[0].isIntersecting) {
          setPostImageUrl(commentSettings.post_data?.media_url ?? '')
          observer.disconnect()
        }
      },
      {
        threshold: 1.0,
      },
    )

    observer.observe(titleRef.current)
  }, [commentSettings.post_data])

  const includeKeywords = useMemo(() => {
    if (
      !commentSettings.include_keywords_array ||
      commentSettings.include_keywords_array.length === 0
    ) {
      return null
    }

    return (
      <>
        {renderTextByNodes(l.translate('and comment contains'))}

        {commentSettings.include_keywords_array.map((keyword, index) => (
          <Chip label={keyword} key={index} />
        ))}
      </>
    )
  }, [commentSettings.include_keywords_array])

  const excludeKeywords = useMemo(() => {
    if (
      !commentSettings.exclude_keywords_array ||
      commentSettings.exclude_keywords_array.length === 0
    ) {
      return null
    }

    return (
      <>
        {renderTextByNodes(l.translate("and comment doesn't contain"))}

        {commentSettings.exclude_keywords_array.map((keyword, index) => (
          <Chip label={keyword} key={index} />
        ))}
      </>
    )
  }, [commentSettings.exclude_keywords_array])

  const contentClassName = useFlexibleTriggerNameClassName()

  const widgetName = hasSearchValue ? renderTextByNodes(`${widget.name} •`) : ''

  if (
    'post_covered_area' in commentSettings &&
    commentSettings.post_covered_area !== PostCoveredArea.SPECIFIC_POST
  ) {
    return (
      <span className={contentClassName}>
        {widgetName}

        {commentSettings.post_covered_area === PostCoveredArea.NEXT_POST &&
          renderTextByNodes(l.translate('User comments on next Post or Reel'))}

        {commentSettings.post_covered_area === PostCoveredArea.ALL_POSTS &&
          renderTextByNodes(l.translate('User comments on any Post or Reel'))}

        {includeKeywords}

        {excludeKeywords}
      </span>
    )
  }

  return (
    <span className={contentClassName} ref={titleRef}>
      {widgetName}

      {renderTextByNodes(l.translate('User comments on<l10n context="specific Post or Reel"/>'))}

      {commentSettings.post_data && <CgtTitleImg url={postImageUrl} />}

      {renderTextByNodes(l.translate('<l10n context="User comments on"/>specific Post or Reel'))}

      {includeKeywords}

      {excludeKeywords}
    </span>
  )
}

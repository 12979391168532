import { z } from 'zod'

import {
  ExternalTriggerStatusType,
  ExternalTriggerType,
} from 'apps/externalTriggers/externalTriggerConstants'
import {
  TriggerEventType,
  TriggerRuleType,
  TriggerRuleStatusType,
} from 'apps/triggerRules/models/TriggerRule/constants'
import { AiIntentSchema } from 'shared/api/requests/aiIntents/schemas'
import {
  TikTokAdAppType,
  TikTokAdPromotionType,
} from 'shared/api/requests/integrations/tiktokAds/types'

export const ruleTriggerSchema = z.object({
  title: z.string(),
  runs: z.number(),
  rule_id: z.number(),
  draft: z
    .object({
      initial_trigger_event_type: z.nativeEnum(TriggerEventType),
    })
    .nullable(),
  triggers: z
    .array(
      z.object({
        type: z.nativeEnum(TriggerRuleType),
        data: z.object({
          event_type: z.nativeEnum(TriggerEventType),
        }),
      }),
    )
    .nullable(),
  status: z.nativeEnum(TriggerRuleStatusType),
  ts_created: z.number(),
  ts_updated: z.number(),
})

export const CmsIntentSchema = AiIntentSchema.extend({
  flow: z.object({}).optional(),
  runs: z.number().nullable(),
  ctr: z.number().nullable(),
})

const tikTokAdTriggerDataSchema = z.object({
  app_id: z.number().optional(),
  messaging_app_type: z.nativeEnum(TikTokAdAppType).optional(),
  ad_group_id: z.string().optional(),
  ad_id: z.string().optional(),
  ad_name: z.string().optional(),
  advertiser_id: z.string().optional(),
  campaign_id: z.string().optional(),
  messaging_app_account_id: z.string().optional(),
  promotion_type: z.nativeEnum(TikTokAdPromotionType).optional(),
})

const externalTriggerDataSchema = z.union([
  tikTokAdTriggerDataSchema,
  z.array(tikTokAdTriggerDataSchema),
])

export const externalTriggerSchema = z.object({
  data: externalTriggerDataSchema,
  title: z.string(),
  external_trigger_id: z.number(),
  trigger_key: z.nativeEnum(ExternalTriggerType),
  status_title: z.nativeEnum(ExternalTriggerStatusType),
  ts_created: z.number(),
  ts_updated: z.number(),
  runs: z.number().nullable(),
  ctr: z.number().nullable(),
})

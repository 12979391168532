import { IQuickRepliesSettings } from 'common/builder/blocks/blockInterfaces'

interface IQuickReplyBackendSettings {
  validation_message: string
  skip_button_caption: string
  limit_failed: number
  timeout: { value: number; unit: string } | null
  timeout_target: { content_oid: string; content_id: number } | null
}

export const parseQuickRepliesSettings = (
  settings: IQuickReplyBackendSettings | null,
): IQuickRepliesSettings | Record<string, never> => {
  if (!settings) {
    return {}
  }

  return {
    validationMessage: settings.validation_message,
    skipButtonCaption: settings.skip_button_caption,
    answerRetries: settings.limit_failed,
    timeout: settings.timeout,
  }
}

export const exportQuickRepliesSettings = (settings: IQuickRepliesSettings | null) => {
  if (!settings) {
    return {}
  }

  return {
    validation_message: settings.validationMessage || null,
    skip_button_caption: settings.skipButtonCaption || null,
    limit_failed: settings.answerRetries || null,
    timeout: settings.timeout || null,
  }
}

import React from 'react'
import { Icon, l } from '@manychat/manyui'

import { useFlexibleTriggerNameClassName } from 'apps/cms/components/Flows/components/FlowCard/components/Triggers/hooks/useFlexibleTriggerNameClassName'
import { renderTextByNodes } from 'apps/cms/components/Flows/components/FlowCard/components/Triggers/lib/renderTextByNodes'
import { ExternalTrigger } from 'shared/api/requests/cms/schemas'
import { TikTokAdAppType } from 'shared/api/requests/integrations/tiktokAds/types'

interface TikTokAdsTitleProps {
  externalTrigger: ExternalTrigger
}

const getData = (appType: TikTokAdAppType | null) => {
  switch (appType) {
    case TikTokAdAppType.MESSENGER:
      return {
        channel: l.translate('Messenger'),
        icon: <Icon.MessengerChannel size={16} />,
      }
    case TikTokAdAppType.WHATSAPP:
      return {
        channel: l.translate('WhatsApp'),
        icon: <Icon.WhatsAppChannel size={16} />,
      }
    default:
      return {
        channel: l.translate('Instant Messenger App'),
        icon: null,
      }
  }
}

export const TikTokAdsTitle = ({ externalTrigger }: TikTokAdsTitleProps) => {
  const className = useFlexibleTriggerNameClassName()
  const triggerData = externalTrigger.data
  const appType =
    'messaging_app_type' in triggerData && triggerData.messaging_app_type
      ? triggerData.messaging_app_type
      : null

  const { icon, channel } = getData(appType)

  return (
    <span className={className}>
      {renderTextByNodes(l.translate('User reply on TikTok ad through'))}

      {icon}

      {renderTextByNodes(channel)}
    </span>
  )
}

import * as atypes from 'common/actions/integrations/common/constants/IntegrationsReduxActionTypes'
import * as API from 'constants/API'

const endpoints = API.integrations.endpoints.activeCampaign

export const setIdentityField = ({ value, option }) => {
  return {
    type: atypes.ACTIVE_CAMPAIGN_SET_IDENTITY_FIELD,
    data: {
      value,
      option,
    },
  }
}

export const fetchLists = () => ({
  $fetch: endpoints.getLists,
  type: atypes.ACTIVE_CAMPAIGN_LISTS_FETCH,
})

export const setSelectedList = (list) => ({
  type: atypes.ACTIVE_CAMPAIGN_SET_LIST,
  data: list,
})

export const setFieldEmptiness = (data) => ({
  type: atypes.ACTIVE_CAMPAIGN_SET_FIELD_EMPTINESS,
  data,
})

export const fetchAvailableIntegrationFields = () => async (dispatch) =>
  await dispatch({
    $fetch: endpoints.getFields,
    type: atypes.ACTIVE_CAMPAIGN_FIELDS_FETCH,
  })

import dayjs from 'dayjs'
import find from 'lodash/find'
import omit from 'lodash/omit'
import { v4 as uuid } from 'uuid'

import { FieldType } from 'common/fields/entities/enums'
import {
  SECONDS_IN_MINUTE,
  SECONDS_IN_HOUR,
  SECONDS_IN_DAY,
} from 'components/DelayPicker/constants'
import { formatDelay } from 'components/DelayPicker/utils'
import errorTrackingService from 'utils/services/errorTrackingService'

import { FieldTypesOperators, FilterField } from './constants'
import { calculateTimeForTimezone } from './helpers'

const formulaValueMask = /(.*)\(({{.*}})(, "([-+])", (\d*), "(\w*)")?\)/
export const dateOffsetUnits = { days: 'day', hours: 'hour', minutes: 'minute' }

export function getDateOffsetValue(unit, offset) {
  if (unit === dateOffsetUnits.days) {
    return SECONDS_IN_DAY * offset
  } else if (unit === dateOffsetUnits.hours) {
    return SECONDS_IN_HOUR * offset
  } else if (unit === dateOffsetUnits.minutes) {
    return SECONDS_IN_MINUTE * offset
  }
}

export function getDateOffsetUnit(offset) {
  if (offset >= SECONDS_IN_DAY && offset % SECONDS_IN_DAY === 0) {
    return dateOffsetUnits.days
  }

  if (offset >= SECONDS_IN_DAY && offset % SECONDS_IN_DAY !== 0) {
    return dateOffsetUnits.hours
  }

  if (offset >= SECONDS_IN_HOUR && offset % SECONDS_IN_HOUR === 0) {
    return dateOffsetUnits.hours
  }

  if (offset >= SECONDS_IN_HOUR && offset % SECONDS_IN_HOUR !== 0) {
    return dateOffsetUnits.minutes
  }

  return dateOffsetUnits.minutes
}

const processFormulaValue = ({ value }) => {
  if (!value || typeof value !== 'string') {
    errorTrackingService.trackError(new Error('Invalid formula value for adapter'), {
      extra: { value },
    })
    return []
  }
  return (value || '').match(formulaValueMask) || []
}

function parseFormulaItem(item) {
  const [, formulaFunc, formulaField, , offsetOperator, offsetValue, offsetUnit] =
    processFormulaValue(item)

  if (offsetOperator && offsetValue && offsetUnit) {
    return {
      ...item,
      offset: {
        operator: offsetOperator,
        value: getDateOffsetValue(offsetUnit, +offsetValue),
      },
      value: `${formulaFunc}(${formulaField})`,
    }
  }

  return item
}

function parseItem(item) {
  item._oid = item._oid || uuid()

  if (item.item) {
    item.field = item.item.split('_')[0]
    item.value = item.item.split('_')[1]
    item.type = item.field === 'gender' ? 'suf' : item.field
  }

  if (item.type && !item.operator) {
    const fieldDesc = { operators: FieldTypesOperators[item.type] }

    if (fieldDesc) {
      item.operator = fieldDesc.operators[0]
    }
  }

  if (!item.field) {
    item.field = item.type
  }

  if (item.is_formula) {
    return parseFormulaItem(item)
  }

  const isTextCuf =
    FieldTypesOperators[FieldType.TEXT].some((el) => el.value === item.operator) &&
    item.type === 'cuf'

  const sufItem = find(window.app.store.getState().filter.fields.system, { name: item.field })
  const isTextSuf = item.type === 'suf' && sufItem && sufItem.type === 'system_text'

  if (isTextCuf || isTextSuf) {
    item.case_sensitive = item.case_sensitive === undefined || item.case_sensitive
  }
  return item
}

function parseGroup(group) {
  return {
    ...group,
    items: group.items.map(parseItem),
  }
}

export function parseFilter(filter) {
  // Filter can be frozen default filter object
  filter = JSON.parse(JSON.stringify(filter))

  if (!Array.isArray(filter.groups) || filter.groups.length === 0) {
    filter.groups = [{ operator: 'AND', items: [] }]
  }

  filter.groups = filter.groups.map(parseGroup)

  return filter
}

export function parseIntegrationFields(integration = {}) {
  return Object.keys(integration).reduce((segments, segment) => {
    segments[segment] = integration[segment].map((field) => ({
      ...field,
      name: `${segment}.${field.name}`,
    }))

    return segments
  }, {})
}

function processFormulaItem(item = {}) {
  const { offset } = item

  if (item.offset) {
    const [, formulaFunc, formulaValue] = processFormulaValue(item)
    const offsetValue = parseInt(formatDelay(offset.value), 10)
    const offsetUnit = getDateOffsetUnit(offset.value)

    return omit(
      {
        ...item,
        value: `${formulaFunc}(${formulaValue}, "${offset.operator}", ${offsetValue}, "${offsetUnit}")`,
      },
      'offset',
    )
  }

  return item
}

function processItem(item = {}) {
  return item.is_formula ? processFormulaItem(item) : item
}

function processGroup(group = {}) {
  return {
    ...group,
    items: group.items.map(processItem),
  }
}

export function processFilter(filter) {
  if (filter) {
    return {
      ...filter,
      groups: (filter.groups || []).map(processGroup),
    }
  }

  return filter
}

// https://github.com/manychat/frontend/pull/3035
export const parseCaseBlockFilter = (filter, botTimeZone) => {
  if (!filter) {
    return filter
  }

  return {
    ...filter,
    groups: (filter.groups || []).map((group = {}) => ({
      ...group,
      items: group.items.map((item) => parseCaseBlockFilterItem(item, botTimeZone)),
    })),
  }
}

const parseCaseBlockFilterItem = (item = {}, botTimeZone) => {
  let tz = null
  let error = null
  try {
    tz = dayjs().tz(botTimeZone).format('Z')
  } catch (err) {
    error = err
  }

  if (error || !tz) {
    errorTrackingService.trackWarningOnce(error ?? 'empty tz', {
      extra: { botTimeZone, tz },
      fingerprint: 'parse-case-filter-no-timezone',
    })
  }

  if (item.type === FilterField.CURRENT_DATETIME && item.value && item.value.time_zone) {
    const { from, to, time_zone } = item.value
    return {
      ...item,
      value: {
        ...omit(item.value, 'time_zone'),
        from: { time: calculateTimeForTimezone(from.time, tz || time_zone) },
        to: { time: calculateTimeForTimezone(to.time, tz || time_zone) },
      },
    }
  }
  return item
}

export enum CapiPriceValidationErrors {
  MISSING_PRICE = 'price not given',
  PRICE_IS_NEGATIVE = 'price is negative',
  PRICE_EXCEEDS_MAX = 'price exceeds max',
}

// 1e9 is short-hand for 1 billion
export const MAX_PRICE = 1e9
export const MIN_PRICE = 0

export const CHANNEL_SETTINGS_URL_ANCHOR = {
  facebook: 'messenger',
  instagram: 'instagram',
  whatsapp: 'whatsApp',
}

export enum ConversionType {
  LEAD_SUBMITTED = 'LeadSubmitted',
  PURCHASE = 'Purchase',
  INITIATE_CHECKOUT = 'InitiateCheckout',
  APPOINTMENT_BOOKED = 'AppointmentBooked',
  ADD_TO_CART = 'AddToCart',
  VIEW_CONTENT = 'ViewContent',
  ORDER_CREATED = 'OrderCreated',
  ORDER_SHIPPED = 'OrderShipped',
}

export const DEFAULT_CURRENCY = 'USD'

export const DEFAULT_CONVERSION_TYPE = ConversionType.LEAD_SUBMITTED

export const CONVERSION_TYPES_WITH_PRICE = [
  ConversionType.PURCHASE,
  ConversionType.ORDER_CREATED,
  ConversionType.ORDER_SHIPPED,
]

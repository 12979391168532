import React, { useCallback } from 'react'
import { useAppSelector } from 'reduxTyped'
import { l, Modal } from '@manychat/manyui'

import { useWallet, useWalletUI } from 'common/billing/wallet/walletHooks'
import { ModalTypes } from 'common/billing/wallet/walletInterfaces'
import { ChannelStatus } from 'common/core/interfaces/channelStatuses'
import { getEmailChannel } from 'common/core/selectors/channelSelectors'
import { useToggle } from 'common/hooks/useToggle'
import { analyticsService } from 'utils/services/analytics'

import cm from '../StaticNotifications.module.css'

export const WalletLowBalanceNotification = () => {
  const { state: informationModalState, toggle: toggleInformationModal } = useToggle()
  const { wallet } = useWallet()
  const [{ openModal }, toggleWalletModal] = useWalletUI()
  const emailChannelStatus = useAppSelector(getEmailChannel).status
  const isEmailChannelActive = emailChannelStatus === ChannelStatus.ACTIVE

  const modal = wallet.autoRefill.isEnabled ? ModalTypes.REFILL : ModalTypes.AUTO_REFILL

  const handleClickRefill = useCallback(() => {
    analyticsService.sendEvent('NOTIFICATION_BAR.LOW_BALANCE.CLICKED')
    toggleWalletModal(modal)
  }, [toggleWalletModal, modal])

  const buttons = [
    {
      variant: 'primary',
      onClick: handleClickRefill,
      label: l.translate('Activate Auto-Refill'),
    },
  ]

  const showInformationModal = openModal === null && informationModalState !== 'off'

  let content = l.translate(
    'Your Wallet balance is too low. <a class="{class}" onclick="clickRefill">Add funds</a> to your balance to keep sending WhatsApp messages and Emails.',
    {
      clickRefill: handleClickRefill,
      class: cm.more,
    },
  )
  if (!wallet.autoRefill.isEnabled && isEmailChannelActive) {
    content = l.translate(
      'Wallet funds are required for sending WhatsApp messages and Emails. We recommend enabling <a class="{class}" onclick="clickRefill">Auto-Refill</a>',
      {
        clickRefill: handleClickRefill,
        class: cm.more,
      },
    )
  } else if (!wallet.autoRefill.isEnabled && !isEmailChannelActive) {
    content = l.translate(
      'Wallet funds are required for sending WhatsApp messages. We recommend enabling <a class="{class}" onclick="clickRefill">Auto-Refill</a>',
      {
        clickRefill: handleClickRefill,
        class: cm.more,
      },
    )
  }

  return (
    <>
      <span>{content}</span>
      {!wallet.autoRefill.isEnabled && (
        <Modal
          title={l.translate('Wallet balance is too low')}
          width={600}
          open={showInformationModal}
          onRequestClose={toggleInformationModal}
          buttons={buttons}
        >
          <p className="p-a-xs">
            {l.translate(
              'Auto-Refill lets you avoid the risk of undelivered messages in your WhatsApp or Email campaigns. Once Auto-Refill is active, Manychat will automatically charge money from the attached Credit Card within the limit you set to deliver all paid messages. Activate Auto-Refill in Settings → Billing → Balance.',
            )}
          </p>
        </Modal>
      )}
    </>
  )
}

import dot from 'dot-prop-immutable'

import {
  ThreadsSortingTypes,
  StatusFilterType,
  ALL_TEAM_MEMBERS_ID,
  ConversationFilterType,
  SelectionType,
  ThreadControlErrorType,
} from 'apps/chat/constants/LiveChatInterfaces'
import * as atypes from 'apps/chat/constants/LiveChatReduxActionTypes'
import { ThreadStatus } from 'apps/chat/interfaces/statuses'
import { SELECTION_TYPE } from 'common/audience/models/Subscriber'
import { ChannelType } from 'common/core/constants/ChannelType'
import { FilterOperator } from 'common/filter/filterConstants'
import {
  makeLimitedListReducer,
  addToList,
  deleteFromList,
  makeCurrentItemReducer,
  mergeToListItem,
  mergeData,
} from 'utils/factory'

export const initialState = {
  assignee: null,
  channel: ChannelType.FB,
  checkedFilterIds: {},
  current: {
    item: null,
    fetching: false,
    hash: null,
    hasChanges: false,
    error: null,
    threadControlError: null,
  },
  currentId: null,
  fetchingPauseIds: {},
  filter: {
    operator: FilterOperator.AND,
    groups: [],
  },
  isHistoryModal: false,
  selectedThreads: {},
  selectionType: SelectionType.INCLUDE,
  statusFilter: StatusFilterType.OPEN,
  threadsSorting: ThreadsSortingTypes.NEWEST,
  totalThreadsCount: null,
  conversationsFilter: ConversationFilterType.ALL,
  pendingVoiceConversionStates: [],
  channelsFilter: null,
  teamMembersFilter: ALL_TEAM_MEMBERS_ID,
  uploadedImagesThreads: {},
  loadingMessagesThreads: {},
}

const listReducer = makeLimitedListReducer('threads', { initialState, insertOnUpdate: true })
const currentItemReducer = makeCurrentItemReducer('threads')

export default function threadsReducer(state, action) {
  state = listReducer(state, action)
  const current = currentItemReducer(state.current, action)
  if (current !== state.current) {
    state = { ...state, current }
  }

  if (action.type === 'THREADS_DROP_LIST') {
    state = {
      ...state,
      selectedThreads: {},
      selectionType: SELECTION_TYPE.INCLUDE,
      totalThreadsCount: null,
    }
  }

  // List actions
  switch (action.type) {
    case atypes.UPDATE_CONVERSATIONS_FILTER:
      return { ...state, conversationsFilter: action.value }
    case atypes.UPDATE_CHANNELS_FILTER:
      return { ...state, channelsFilter: action.value }
    case atypes.UPDATE_TEAM_MEMBERS_FILTER:
      return { ...state, teamMembersFilter: action.value }
    case atypes.CHAT_THREADS_STATUS_FILTER_CHANGED:
      return { ...state, statusFilter: action.value }
    case atypes.CHAT_THREADS_TAGS_AND_CUFS_FILTER_CHANGED:
      return { ...state, filter: action.value }
    case atypes.CHAT_THREADS_SORTING_CHANGED:
      return { ...state, threadsSorting: action.sorting }
    case atypes.CHAT_THREADS_SET_CURRENT:
      return { ...state, currentId: action.id }
    case atypes.THREADS_SETPAUSEFOREVER_REQUEST:
    case atypes.THREADS_SETPAUSE_REQUEST:
    case atypes.THREADS_CLEARPAUSE_REQUEST:
      return { ...state, fetchingPauseIds: { ...state.fetchingPauseIds, [action.threadId]: true } }
    case atypes.THREADS_INCREASE_TOTAL_THREADS_COUNT:
      return { ...state, totalThreadsCount: Number(state.totalThreadsCount) + 1 }
    case atypes.THREADS_DECREASE_TOTAL_THREADS_COUNT:
      return { ...state, totalThreadsCount: Number(state.totalThreadsCount) - 1 }
    case atypes.THREADS_SETPAUSEFOREVER_RESPONSE:
    case atypes.THREADS_SETPAUSEFOREVER_ERROR:
    case atypes.THREADS_SETPAUSE_RESPONSE:
    case atypes.THREADS_SETPAUSE_ERROR:
    case atypes.THREADS_CLEARPAUSE_RESPONSE:
    case atypes.THREADS_CLEARPAUSE_ERROR: {
      const fetchingPauseIds = { ...state.fetchingPauseIds }
      delete fetchingPauseIds[action.threadId]
      return { ...state, fetchingPauseIds }
    }
    case atypes.THREADS_CURRENT_CHECKSATISFIESFILTER_REQUEST: {
      return {
        ...state,
        checkedFilterIds: {
          ...state.checkedFilterIds,
          [action.body.subscriber_id]: {
            loading: true,
          },
        },
      }
    }
    case atypes.THREADS_CURRENT_CHECKSATISFIESFILTER_ERROR:
    case atypes.THREADS_CURRENT_CHECKSATISFIESFILTER_RESPONSE: {
      return {
        ...state,
        checkedFilterIds: {
          ...state.checkedFilterIds,
          [action.body.subscriber_id]: {
            loading: false,
            ...(action.data ? action.data : {}),
          },
        },
      }
    }
    case atypes.THREADS_CLOSE_ALL: {
      const threadsState = applyChangesToThreads(action, state, {
        lc_thread_status: ThreadStatus.CLOSED,
      })
      return { ...threadsState }
    }

    case atypes.THREADS_OPEN_ALL: {
      const threadsState = applyChangesToThreads(action, state, {
        lc_thread_status: ThreadStatus.OPENED,
      })
      return { ...threadsState }
    }

    case atypes.THREADS_UNASSIGN: {
      const threadsState = applyChangesToThreads(action, state, {
        assignment: null,
        lc_thread_status: ThreadStatus.NEW,
      })
      return { ...threadsState, assignee: null }
    }

    case atypes.THREADS_ASSIGN: {
      const threadsState = applyChangesToThreads(action, state, {
        assignment: { user_id: state.assignee },
      })
      return { ...threadsState, assignee: null }
    }

    case atypes.THREADS_SET_ASSIGNEE: {
      return { ...state, assignee: action.data }
    }

    case atypes.CHAT_THREAD_TOGGLE_CHECKED: {
      const { id } = action.data
      const selectedThreads = { ...state.selectedThreads }
      if (selectedThreads[id]) {
        delete selectedThreads[id]
      } else {
        selectedThreads[id] = action.data
      }
      return { ...state, selectedThreads }
    }
    case atypes.CHAT_THREADS_CLEAR_SELECTION: {
      return { ...state, selectedThreads: {}, selectionType: SELECTION_TYPE.INCLUDE }
    }

    case atypes.CHAT_THREADS_SELECTION_TYPE_CHANGED: {
      const { selectionType } = action
      return { ...state, selectionType, selectedThreads: {} }
    }

    case atypes.THREADS_UPDATED_NOTIFICATION: {
      if (state.items == null) {
        break
      }

      const { item } = action
      const exists = Boolean(state.byId[item.id])
      const isNewestSort = state.threadsSorting === ThreadsSortingTypes.NEWEST

      const topThread = state.items[0]
      const isNewMessage = item?.timestamp >= topThread?.timestamp

      if (exists && isNewestSort && isNewMessage) {
        const updatedState = deleteFromList(state, item.id)
        const unshift = true
        return addToList(updatedState, item.id, item, unshift)
      }

      return state
    }

    case atypes.THREADS_FETCH_RESPONSE: {
      const { data } = action

      return {
        ...state,
        totalThreadsCount: data.total_threads_num || state.totalThreadsCount,
        fetching: false,
      }
    }

    case atypes.THREADS_UPDATESTATUS_ERROR:
    case atypes.THREADS_SETASSIGNMENT_ERROR:
    case atypes.MESSAGES_FETCH_ERROR: {
      const { error } = action

      return {
        ...state,
        current: {
          ...state.current,
          item: { ...state.current.item },
          error,
        },
      }
    }

    case atypes.THREADS_CURRENT_FETCH_RESPONSE: {
      const { notes, hop_error, thread_control_error } = action.data

      return {
        ...state,
        current: {
          ...state.current,
          item: { ...state.current.item, notes },
          threadControlError: hop_error || thread_control_error || null,
        },
      }
    }

    case atypes.MESSAGES_SEND_ERROR: {
      if (!action.error) {
        return state
      }
      const { hop_error, thread_control_error } = action.error

      return {
        ...state,
        current: {
          ...state.current,
          threadControlError: thread_control_error || hop_error || null,
        },
      }
    }

    case atypes.MESSAGES_SEND_RESPONSE:
    case atypes.THREAD_RESET_HANDOVER_ERROR:
    case atypes.THREAD_TAKE_THREAD_CONTROL_RESPONSE: {
      const isCurrentlyHandoverError = [
        ThreadControlErrorType.HANDOVER_PRIMARY_THREAD_OWNERSHIP,
        ThreadControlErrorType.HANDOVER_SECONDARY_THREAD_OWNERSHIP,
      ].includes(state.current.threadControlError?.type)

      if (!isCurrentlyHandoverError) {
        return state
      }

      return {
        ...state,
        current: {
          ...state.current,
          threadControlError: null,
        },
      }
    }

    case atypes.THREAD_RESET_CONVERSATION_ROUTING_ERROR: {
      return {
        ...state,
        current: {
          ...state.current,
          threadControlError: null,
        },
      }
    }

    case atypes.THREADS_ADD_PRELOAD_IMAGE: {
      const { currentId, channel } = state
      const hasUploadedImagesThreads = state.uploadedImagesThreads?.[currentId]?.[channel]

      if (hasUploadedImagesThreads) {
        state.uploadedImagesThreads[currentId][channel] = [
          ...state.uploadedImagesThreads[currentId][channel],
          ...action.data,
        ]
      } else {
        state.uploadedImagesThreads = {
          ...state.uploadedImagesThreads,
          [currentId]: {
            ...state.uploadedImagesThreads[currentId],
            [channel]: action.data,
          },
        }
      }

      return state
    }

    case atypes.THREADS_DELETE_PRELOAD_IMAGE: {
      const { currentId, channel } = state
      const arrImg = state.uploadedImagesThreads[currentId][channel]
      arrImg.splice(action.data.index, 1)
      return state
    }

    case atypes.THREADS_RESET_IMAGES: {
      const { currentId, channel } = action.payload
      delete state.uploadedImagesThreads[currentId][channel]
      return state
    }

    case atypes.THREADS_UPDATE_SUBSCRIBER_INFO: {
      const { threadId, user } = action

      let current = state.current
      if (current?.item && threadId === current.item?.id) {
        current.item.user = user
      }

      return {
        ...state,
        ...mergeToListItem(state, threadId, { user }),
        current,
      }
    }

    case atypes.THREADS_SEND_REQUEST: {
      const { currentId } = state
      const { messageId, channel } = action.payload
      const hasLoadingThreads = Boolean(
        state.loadingMessagesThreads?.[currentId]?.[channel]?.length,
      )

      if (hasLoadingThreads) {
        state.loadingMessagesThreads[currentId][channel] = [
          ...state.loadingMessagesThreads[currentId][channel],
          messageId,
        ]
      } else {
        state.loadingMessagesThreads = {
          ...state.loadingMessagesThreads,
          [currentId]: {
            ...state.loadingMessagesThreads[currentId],
            [channel]: [messageId],
          },
        }
      }

      return state
    }

    case atypes.THREADS_SEND_ERROR: {
      const { currentId, channel, error } = action.payload

      return {
        ...state,
        loadingMessagesThreads: dot.set(
          state.loadingMessagesThreads,
          [currentId, channel],
          undefined,
        ),
        current: {
          ...state.current,
          error,
        },
      }
    }

    case atypes.RESET_THREAD_LOADING: {
      return {
        ...state,
        loadingMessagesThreads: {},
      }
    }

    case atypes.THREADS_SEND_RESPONSE: {
      const { currentId, channel, messageId } = action.payload
      const currentThread = state.loadingMessagesThreads[currentId]?.[channel]
      if (!currentThread) return state

      state.loadingMessagesThreads[currentId][channel] = currentThread.filter(
        (msgId) => msgId !== messageId,
      )
      return state
    }

    case atypes.LIVE_CHAT_SWITCH_CHANNEL: {
      const { channel } = action.data
      return { ...state, channel }
    }
    case atypes.LIVE_CHAT_SHOW_ALL_CHANNELS_HISTORY_MODAL: {
      return { ...state, isHistoryModal: true }
    }
    case atypes.LIVE_CHAT_CLOSE_ALL_CHANNELS_HISTORY_MODAL: {
      return { ...state, isHistoryModal: false }
    }

    case atypes.ADD_VOICE_CONVERSION_PENDING_STATE: {
      return {
        ...state,
        pendingVoiceConversionStates: [...state.pendingVoiceConversionStates, action.value],
      }
    }

    case atypes.REMOVE_VOICE_CONVERSION_PENDING_STATE: {
      return {
        ...state,
        pendingVoiceConversionStates: state.pendingVoiceConversionStates.filter(
          ({ message }) => message.attachment_id[0] !== action.value,
        ),
      }
    }
  }

  return state
}

const applyChangesToThreads = (action, state, status) => {
  const threadsIdList = action.data || []
  const items = threadsIdList.reduce((prState, threadId) => {
    return prState.byId[threadId] ? mergeToListItem(prState, threadId, status) : prState
  }, state)
  let current = state.current
  if (current.item && threadsIdList.includes(parseInt(current.item.id, 10))) {
    current = mergeData(state.current, status, 'item')
  }

  const newState = { ...state, ...items, current }

  if (state.selectionType === SELECTION_TYPE.EXCLUDE) {
    return { ...newState, selectionType: SELECTION_TYPE.INCLUDE }
  }

  return newState
}

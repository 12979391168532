import { api } from 'shared/api'
import { homeTabApiSchemas } from 'shared/api/requests/homeTab/schemas'

export const HomeTabApi = {
  getWhatsAppOnboardingData: api.account.createGet({
    url: '/homeTab/getWhatsAppOnboardingData',
    schemas: homeTabApiSchemas.getWhatsAppOnboardingData,
  }),
  analyticsWidget: api.account.createGet({
    url: '/dashboard/analyticsWidget',
    schemas: homeTabApiSchemas.analyticsWidget,
  }),
  generateAnalyticsWidgetData: api.account.createPost({
    url: '/dashboard/generateAnalyticsWidgetData',
    schemas: homeTabApiSchemas.generateAnalyticsWidgetData,
  }),
  getIgUsersValueData: api.account.createGet({
    url: '/dashboard/igUsersValueData',
    schemas: homeTabApiSchemas.igUsersValueData,
  }),
}

import AttachmentDestination from 'common/builder/constants/AttachmentDestination'
import * as ActionTypes from 'common/core/constants/ReduxActionTypes'
import * as API from 'constants/API'

// 25MB value taken from here: https://developers.facebook.com/docs/messenger-platform/send-messages/#sending_attachments
export const MAX_ATTACHMENT_SIZE_BYTES = 25000000
// https://github.com/tdlib/td/commit/c2028aed4f62a89efb54d4f1327d9ad650ae2ea0#diff-d0c1b46ebb8582d7cfdeefc45b510c63491a57569e56841ee3cc47474ebafc4cR923
export const MAX_TG_VIDEO_NOTE_SIZE_BYTES = 12582912

export const uploadAttachment = (
  files,
  data,
  maxSizeLabel = MAX_ATTACHMENT_SIZE_BYTES / 1000000,
) => {
  return (dispatch) => {
    if (!Array.isArray(files)) {
      files = [files]
    }
    const body = new FormData()

    for (let key of Object.keys(files)) {
      let file = files[key]

      if (!(file instanceof File)) {
        continue
      }

      if (
        data.attachmentDestination === AttachmentDestination.TG_VIDEO_NOTE &&
        file.size > MAX_TG_VIDEO_NOTE_SIZE_BYTES
      ) {
        throw new Error(
          `File '${file.name}' is too large. We can not send a video message larger that ${
            MAX_TG_VIDEO_NOTE_SIZE_BYTES / 1024 / 1024
          } MB`,
        )
      }

      if (file.size > MAX_ATTACHMENT_SIZE_BYTES) {
        throw new Error(`Attachments larger than ${maxSizeLabel} MB cannot be sent.`)
      }

      /**
       * Hack for Safari, since it doesn't set the file's type=audio/ogg field, but leaves it empty.
       */
      if (/\.ogg$/.test(file.name) && !file.type) {
        file = new File([file], file.name, {
          type: 'audio/ogg',
          lastModified: file.lastModified,
        })
      }

      body.append(key, file)
    }

    const { attachmentDestination, voiceData } = data
    if (attachmentDestination) {
      body.set('dest', attachmentDestination)
    }
    if (voiceData) {
      for (const field of Object.keys(voiceData)) {
        body.set(field, voiceData[field])
      }
    }

    const uploadId = Date.now()
    const def = dispatch({
      ...data,
      uploadId,
      type: ActionTypes.ATTACHMENT_UPLOAD,
      $fetch: [API.uploadAttachment, { method: 'POST', body }],
    })
    def.uploadId = uploadId
    return def
  }
}

export const uploadBase64 = (base64) => {
  return async (dispatch) => {
    try {
      const file = await fetch(base64).then((res) => res.blob())

      const body = new FormData()
      body.append('0', file)

      return dispatch({
        type: ActionTypes.ATTACHMENT_UPLOAD,
        $fetch: [API.uploadAttachment, { method: 'POST', body }],
      })
    } catch (e) {
      return null
    }
  }
}

import get from 'lodash/get'
export const parseItem = (item) => {
  const spend_cap = get(item, 'spend_cap')

  return {
    ...item,
    id: item.id,
    accountId: item.account_id,
    name: get(item, 'name', ''),
    spend_cap,
    limitSpend: !!spend_cap,
    objective: item.objective,
  }
}

export const exportItem = (item) => {
  return {
    name: item.name,
    spend_cap: item.limitSpend ? item.spend_cap : null,
    objective: item.objective,
  }
}

export default {
  parseItem,
  exportItem,
}

import React from 'react'
import cx from 'classnames'
import DOMPurify from 'dompurify'

import cm from './Tooltip.module.css'

interface TooltipContentProps {
  content: string
  width?: number
}

const START_OF_MAIN_CONTENT = 1
const START_OF_CONTENT_AFTER_HTML = 5

export const TooltipContent = ({ content, width }: TooltipContentProps) => {
  if (!content) {
    return null
  }

  if (content[0] === '#') {
    const element = document.getElementById(content.slice(START_OF_MAIN_CONTENT))
    const innerElementContent = element?.innerHTML

    if (!innerElementContent) {
      return null
    }

    return (
      <div
        className={cm.content}
        dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(innerElementContent) }}
      />
    )
  }

  const maxWidth = width ? `${width}px` : undefined

  if (content.indexOf('html#') === 0) {
    return (
      <div
        style={{ maxWidth }}
        className={cm.content}
        dangerouslySetInnerHTML={{
          __html: DOMPurify.sanitize(content.slice(START_OF_CONTENT_AFTER_HTML)),
        }}
      />
    )
  }

  return (
    <div style={{ maxWidth }} className={cx(cm.content, cm.content_text)}>
      {content}
    </div>
  )
}

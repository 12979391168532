import { Icon, l } from '@manychat/manyui'

import {
  IntegrationType,
  IntegrationNameByType,
} from 'common/actions/integrations/common/constants/IntegrationType'
import { exportManychatSearchValue } from 'common/actions/integrations/common/model/Exporter'
import {
  createIntegration,
  createIntegrationAction,
} from 'common/actions/integrations/common/model/helpers'
import * as salesforceActions from 'common/actions/integrations/Salesforce/actions/salesforceActions'
import { UserFieldMapping } from 'common/actions/integrations/Salesforce/interfaces/userField'
import { AccountTargetPermissionsNames } from 'common/core/models/Account/constants'
import { ContactField, IntegrationData } from 'shared/api/requests/integrations/salesforce/schemas'

export enum SalesforceActionTypes {
  CREATE_UPDATE_CONTACT = 'contacts.create_or_update',
  FIND_CONTACT = 'contacts.find',
  GET_CONTACT = 'contacts.get',
}

interface BindingValue {
  field_name: string
  value: {
    type: 'suf' | 'cuf'
    field_name?: string
    field_id?: string
  }
}

export interface IntegrationActionData {
  binding: BindingValue[]
  identity: { type: 'suf' | 'cuf'; field_name: string }
  mapping: []
  salesforce_search_field?: string
  manychat_search_value?: ReturnType<typeof exportManychatSearchValue>
}

export interface SalesforceActionProps {
  action: {
    _oid: string
    action: SalesforceActionTypes
    data?: IntegrationActionData
    id: string
    integration: IntegrationType
    type: IntegrationType
  }
  onSave: (savedData: { data: IntegrationActionData }) => void
  onHide: () => void
}

export const SalesforceConfig = createIntegration({
  type: IntegrationType.SALESFORCE,
  name: IntegrationNameByType[IntegrationType.SALESFORCE],
  fullName: 'Salesforce',
  icon: Icon.Salesforce,
  permissionsVariable: AccountTargetPermissionsNames.SALESFORCE_INTEGRATION,
  description: () =>
    l.translate('Unify marketing, sales, service, commerce, and IT on the world’s #1 CRM.'),
  createHref: true,
  reduxActions: salesforceActions,
  actions: [
    createIntegrationAction({
      type: SalesforceActionTypes.CREATE_UPDATE_CONTACT,
      description: l.translate(
        'Create a contact if it doesn’t exist in Salesforce already, or update it with Custom Fields.',
      ),
      name: l.translate('Create/Update Contact Data'),
    }),
    createIntegrationAction({
      type: SalesforceActionTypes.GET_CONTACT,
      description: l.translate(
        'Get Salesforce Contact properties by Salesforce ID and save it to Custom Fields.',
      ),
      name: l.translate('Get Contact’s data by ID'),
    }),
    createIntegrationAction({
      type: SalesforceActionTypes.FIND_CONTACT,
      description: l.translate(
        'Get Salesforce Contact properties by any field and save it to Custom Fields.',
      ),
      name: l.translate('Get Contact’s data by any field'),
    }),
  ],
})

export interface SalesforceState extends IntegrationData {
  contactProperties: ContactField[] | null
  identityField: {
    value: string | null
    option: string | null
  }
  isDisconnecting: boolean
  isFetchingContactFields: boolean
  mappings: Record<string, string> | null
  userFieldPropertiesMappings: UserFieldMapping[]
  salesforceSearchField: string
  manychatSearchValue: string
}

import { alert } from 'common/core'
import { ResponseError } from 'shared/api/lib/errors/business'
import { reportUnhandledErrors } from 'shared/api/lib/errors/utils'

export const createOrEditTemplateErrorHandler = (responseError: ResponseError) => {
  const errMessage = responseError?.message
  if (errMessage) {
    const isWaitingRequired =
      errMessage.includes('Try again in ') || /once in \d+\s*(days|hours|weeks)/i.test(errMessage)
    const isUpgradeToPro = errMessage.includes('Upgrade to Pro to use WhatsApp channel')
    const isInactiveWaChannel = errMessage.includes('whatsapp channel is not active')
    const isTemplateAlreadyExists = errMessage.includes(
      'Message template with same name in this language already exists',
    )
    const isContentAlreadyExists = errMessage.includes('Content in this language already exists')
    const isOnlyShowAlert =
      isWaitingRequired ||
      isUpgradeToPro ||
      isInactiveWaChannel ||
      isTemplateAlreadyExists ||
      isContentAlreadyExists
    if (isOnlyShowAlert) {
      alert(errMessage, 'danger')
      return
    }
  }
  const { $errors, endpoint } = responseError
  reportUnhandledErrors($errors, endpoint)
}

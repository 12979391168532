import { generatePath } from 'react-router-dom'

import {
  RegistrationAccountType,
  RegistrationSection,
  AvailableForRegistrationChannel,
} from 'apps/registration/interfaces/registration'
import { RoutePaths } from 'utils/router/constants'

interface Params {
  section: RegistrationSection
  channel?: AvailableForRegistrationChannel
  accId?: string
  type?: RegistrationAccountType
}

export const createRegistrationPath = ({ section, channel, accId, type }: Params): string =>
  generatePath(RoutePaths.REGISTRATION, {
    section,
    channel,
    page_id: accId !== undefined ? accId : '',
    type,
  })

export const CHANNEL_CONNECTION = createRegistrationPath({
  section: RegistrationSection.CHANNEL_CONNECTION,
})

export const CHANNEL_CONNECTION_IG = createRegistrationPath({
  section: RegistrationSection.CHANNEL_CONNECTION,
  channel: AvailableForRegistrationChannel.INSTAGRAM,
})

export const CHANNEL_CONNECTION_FB = createRegistrationPath({
  section: RegistrationSection.CHANNEL_CONNECTION,
  channel: AvailableForRegistrationChannel.FACEBOOK,
})

export const CHANNEL_CONNECTION_TG = createRegistrationPath({
  section: RegistrationSection.CHANNEL_CONNECTION,
  channel: AvailableForRegistrationChannel.TELEGRAM,
})

export const CHANNEL_CONNECTION_WA = createRegistrationPath({
  section: RegistrationSection.CHANNEL_CONNECTION,
  channel: AvailableForRegistrationChannel.WHATSAPP,
})

export const CHANNEL_CONNECTION_TIKTOK = createRegistrationPath({
  section: RegistrationSection.CHANNEL_CONNECTION,
  channel: AvailableForRegistrationChannel.TIKTOK,
})

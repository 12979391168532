import hash from 'hash-sum'
import get from 'lodash/get'
import indexOf from 'lodash/indexOf'
import { l } from '@manychat/manyui'

import { contentManagementActions } from 'apps/cms/store'
import { alert } from 'common/core'
import SequenceModel from 'common/sequences/models/Sequence'
import SequenceMessageModel from 'common/sequences/models/SequenceMessage'
import * as sequenceSelectors from 'common/sequences/selectors/sequenceSelectors'
import { handleCatch } from 'shared/api/lib/errors/handlers'
import { SequenceApi } from 'shared/api/requests/sequence'
import { SequenceMessageApi } from 'shared/api/requests/sequenceMessage'
import { getClientId } from 'utils/clientId'
import { navigatePollyfill } from 'utils/router/tools'
import errorTrackingService from 'utils/services/errorTrackingService'
import { anotherTabNotificationsListener } from 'utils/services/notificationsService'
import { linkURL } from 'utils/url'

import { applyHandlerByGuard, reportUnhandledErrors } from '../../../shared/api/lib/errors/utils'
import { SequenceMessageApiGuards } from '../../../shared/api/requests/sequenceMessage/guards'
import * as atypes from '../sequencesReduxActionTypes'

// -*- SEQUENCE LIST ACTIONS -*-

export const loadSequenceNamesList = () => (dispatch) => dispatch(loadSequenceList(true))

export const loadSequenceList = (onlyNames, fetchAll = false, forceFetch = false) => {
  return async (dispatch, getState, context) => {
    const state = getState()
    const sequencesState = get(state, 'sequence')
    const fetchArgs = [onlyNames, fetchAll]
    const fetchArgsHash = hash(fetchArgs)
    const dropItems = fetchArgsHash !== sequencesState.fetchArgsHash
    if (!dropItems && !forceFetch) return

    dispatch({ type: atypes.SEQUENCE_LIST_LOAD_REQUEST, fetchArgsHash })

    try {
      const { data } = onlyNames
        ? await SequenceApi.listSequenceNames()
        : await SequenceApi.listSequences()

      const items = data.sequences.map((sequence) => SequenceModel.adapter(sequence))
      const maxFreeSequences = data.max_free_sequences

      dispatch({
        type: atypes.SEQUENCE_LIST_LOAD_SUCCESS,
        items,
        maxFreeSequences,
        templateInstallations: data.template_installations || [],
        fetchArgsHash,
      })
    } catch (error) {
      dispatch({ type: atypes.SEQUENCE_LIST_LOAD_ERROR, error })
      handleCatch(error)
    }
  }
}

// -*- SEQUENCE ITEM ACTIONS -*-

export const loadSequence = (sequenceId) => async (dispatch, getState, context) => {
  try {
    const { data } = await SequenceApi.getSequence({
      query: { sequence_id: sequenceId },
    })
    const item = SequenceModel.adapter(data.sequence)
    dispatch({ type: atypes.SEQUENCE_LOAD_ITEM_SUCCESS, item })
  } catch (error) {
    handleCatch(error)
  }
}

export const patchSequence = (sequenceId, changes, options = {}) => {
  return async (dispatch) => {
    try {
      const { data } = await SequenceApi.patchSequence({
        body: {
          sequence_id: sequenceId,
          client_id: getClientId(),
          ...changes,
        },
      })
      const item = SequenceModel.adapter(data.sequence)
      dispatch({ type: atypes.SEQUENCE_UPDATE_SUCCESS, item })

      if (!options.silent) {
        alert(l.translate('Sequence saved!'), 'success')
      }
    } catch (error) {
      handleCatch(error)
    }
  }
}

export const removeSequence = (sequenceId) => {
  return async (dispatch) => {
    try {
      await SequenceApi.deleteSequence({
        body: {
          client_id: getClientId(),
          sequence_id: sequenceId,
        },
      })

      dispatch({ type: atypes.SEQUENCE_REMOVE_SUCCESS, sequenceId })
      alert(l.translate('Deleted!'), 'success')
    } catch (error) {
      handleCatch(error)
    }
  }
}

export const createSequence = (initial) => {
  return async (dispatch) => {
    try {
      const { data } = await SequenceApi.createSequence({
        body: {
          client_id: getClientId(),
          ...initial,
        },
      })

      const item = SequenceModel.adapter(data.sequence)
      dispatch({ type: atypes.SEQUENCE_CREATE_SUCCESS, item })
      dispatch(closeCreateSequenceDialog())
      return item
    } catch (error) {
      handleCatch(error)
    }
  }
}

export const createSequenceAndOpen = (initial) => {
  return async (dispatch) => {
    const item = await dispatch(createSequence(initial))

    navigatePollyfill(linkURL(`/sequence/${item.id}`))
  }
}

export const cloneSequence = (sequenceId) => {
  return async (dispatch) => {
    try {
      const { data } = await SequenceApi.cloneSequence({
        body: {
          client_id: getClientId(),
          sequence_id: sequenceId,
        },
      })

      const item = SequenceModel.adapter(data.sequence)
      dispatch({ type: atypes.SEQUENCE_CLONE_SUCCESS, item })
      alert(l.translate('Copied!'), 'success')
      return item
    } catch (error) {
      handleCatch(error)
    }
  }
}

// -*- SEQUENCE MESSAGES ACTIONS -*-

export const reorderSequenceMessages = (sequenceId, order) => {
  return async (dispatch, getState, context) => {
    try {
      const { data } = await SequenceApi.reorderSequenceMessages({
        body: {
          ids: order,
          sequence_id: sequenceId,
        },
      })

      const item = SequenceModel.adapter(data.sequence)
      dispatch({ type: atypes.SEQUENCE_REORDER_MESSAGES_SUCCESS, item })
    } catch (error) {
      handleCatch(error)
    }
  }
}

export const createSequenceMessage = (sequenceId, selector) => {
  return async (dispatch, getState) => {
    const sequence = selector ? selector : sequenceSelectors.getById(getState(), sequenceId)

    if (!sequence) {
      errorTrackingService.logWarning('Sequence is undefined', {
        scope: 'root',
        section: null,
        extra: { sequence },
        fingerprint: 'sequence-actions-create-sequence-message',
      })
      return
    }

    const isArrayMessages = Array.isArray(sequence.messages)

    if (!isArrayMessages) {
      errorTrackingService.logWarning('Sequence messages were not of array type', {
        scope: 'root',
        section: null,
        extra: { sequence },
        fingerprint: 'sequence-actions-create-sequence-message',
      })
    }

    const name = `Sequence Message ${isArrayMessages ? sequence.messages.length + 1 : 1}`

    dispatch({ type: atypes.SEQUENCE_CREATE_MESSAGE_REQUEST })

    try {
      const { data } = await SequenceMessageApi.create({
        body: {
          name,
          skip_flow_creation: true,
          sequence_id: sequenceId,
        },
      })

      const item = SequenceModel.adapter(data.sequence)
      dispatch({ type: atypes.SEQUENCE_CREATE_MESSAGE_RESPONSE, item })

      alert(l.translate('Message created!'), 'success')
    } catch (error) {
      dispatch({ type: atypes.SEQUENCE_CREATE_MESSAGE_ERROR })
      handleCatch(error)
    }
  }
}

export const removeSequenceMessage = (sequenceId, sequenceMessageId) => {
  return async (dispatch, getState, context) => {
    dispatch({ type: atypes.SEQUENCE_REMOVE_MESSAGE_REQUEST })

    try {
      await SequenceMessageApi.delete({
        query: {
          sequence_message_id: sequenceMessageId,
        },
      })

      dispatch({ type: atypes.SEQUENCE_REMOVE_MESSAGE_RESPONSE, sequenceId, sequenceMessageId })

      alert(l.translate('Message deleted!'), 'success')
    } catch (error) {
      dispatch({ type: atypes.SEQUENCE_REMOVE_MESSAGE_ERROR })
      handleCatch(error)
    }
  }
}

export const patchSequenceMessage = (sequenceId, sequenceMessageId, { status }) => {
  return async (dispatch) => {
    dispatch({ type: atypes.SEQUENCE_PATCH_MESSAGE_REQUEST })

    try {
      const { data } = await SequenceMessageApi.patch({
        body: {
          status,
          sequence_message_id: sequenceMessageId,
        },
      })

      const item = SequenceModel.adapter(data.sequence)
      dispatch({ type: atypes.SEQUENCE_PATCH_MESSAGE_RESPONSE, item })

      alert(l.translate('Sequence message saved!'), 'success')
    } catch (error) {
      dispatch({ type: atypes.SEQUENCE_PATCH_MESSAGE_ERROR })
      handleCatch(error, (responseError) => {
        const unhandledErrors = applyHandlerByGuard(
          responseError.$errors,
          SequenceMessageApiGuards.patch.isEmptyContentError,
          (err) => {
            alert(err.message, 'danger')
          },
        )

        reportUnhandledErrors(unhandledErrors, error.endpoint)
      })
    }
  }
}

export const setSequenceMessageFlow = (sequenceId, sequenceMessageId, flowId) => {
  return async (dispatch) => {
    dispatch({ type: atypes.SEQUENCE_MESSAGE_SET_FLOW_REQUEST })

    try {
      const { data } = await SequenceMessageApi.setFlow({
        body: {
          flow_ns: flowId,
          sequence_message_id: sequenceMessageId,
        },
      })

      const item = SequenceMessageModel.adapter(data.sequence_message)
      dispatch({
        type: atypes.SEQUENCE_MESSAGE_SET_FLOW_RESPONSE,
        sequenceId,
        sequenceMessageId,
        item,
      })

      alert(l.translate('Sequence message saved!'), 'success')
    } catch (error) {
      dispatch({ type: atypes.SEQUENCE_MESSAGE_SET_FLOW_ERROR })
      handleCatch(error)
    }
  }
}

export const createSequenceMessageFlow = (sequenceId, sequenceMessageId) => {
  return async (dispatch, getState) => {
    const sequence = sequenceSelectors.getById(getState(), sequenceId)
    const sequenceMessage = sequenceSelectors.getSequenceMessageById(
      getState(),
      sequenceId,
      sequenceMessageId,
    )
    const index = indexOf(sequence.messages, sequenceMessage) || 0
    const flowName = `Sequence Message ${index + 1}`
    const fileItem = await dispatch(
      contentManagementActions.createAutomation({
        name: flowName,
      }),
    ).unwrap()
    return dispatch(setSequenceMessageFlow(sequenceId, sequenceMessageId, fileItem.flow.ns))
  }
}

export const setSendingSettings = (sequenceMessageId, settings, delay) => {
  return async (dispatch) => {
    dispatch({ type: atypes.SEQUENCE_MESSAGE_SET_SENDING_SETTINGS_REQUEST })

    try {
      const { data } = await SequenceMessageApi.setSendingSettings({
        body: {
          sequence_message_id: sequenceMessageId,
          sending_time_settings: settings,
          delay_seconds: delay,
        },
      })

      alert(l.translate('Settings updated!'), 'success')
      const item = SequenceMessageModel.adapter(data.sequence_message)
      dispatch({ type: atypes.SEQUENCE_MESSAGE_SET_SENDING_SETTINGS_RESPONSE, item })
      return item
    } catch (error) {
      dispatch({ type: atypes.SEQUENCE_MESSAGE_SET_SENDING_SETTINGS_ERROR })
      handleCatch(error)
    }
  }
}

// -*- NOTIFICATION ACTIONS -*-

export const sequenceCreatedNotification = (data) => {
  const item = SequenceModel.adapter(data.model)
  return { type: atypes.SEQUENCE_CREATED_NOTIFICATION, item }
}

export const sequenceRemovedNotification = (id) => ({
  type: atypes.SEQUENCE_REMOVED_NOTIFICATION,
  sequenceId: id,
})

export const sequenceUpdatedNotification = (data) => {
  const item = SequenceModel.adapter(data.model)
  return { type: atypes.SEQUENCE_UPDATED_NOTIFICATION, item }
}

// -*- UI ACTIONS -*-

export const openCreateSequenceDialog = () => {
  return {
    type: atypes.SEQUENCE_OPEN_CREATE_DIALOG,
  }
}

export const closeCreateSequenceDialog = () => ({
  type: atypes.SEQUENCE_CLOSE_CREATE_DIALOG,
})

export const dropSequenceList = () => ({
  type: atypes.SEQUENCE_LIST_DROP,
})

anotherTabNotificationsListener.on('sequence_created', (data, dispatch) => {
  dispatch(sequenceCreatedNotification(data))
})

anotherTabNotificationsListener.on('sequence_deleted', (data, dispatch) => {
  dispatch(sequenceRemovedNotification(data.id))
})

anotherTabNotificationsListener.on('sequence_updated', (data, dispatch) => {
  dispatch(sequenceUpdatedNotification(data))
})

import { z } from 'zod'

import {
  SendingTimeSettings,
  SequenceMessage,
  SequenceResponse,
} from 'shared/api/requests/sequence/schemas'

enum StatusType {
  DRAFT = 'draft',
  LIVE = 'live',
}

export const SequenceMessageSchemas = {
  create: {
    request: z.object({
      name: z.string(),
      sequence_id: z.number(),
      skip_flow_creation: z.boolean(),
    }),
    response: z.object({
      sequence_message: SequenceMessage,
      sequence: SequenceResponse,
    }),
    query: z.undefined(),
    path: z.undefined(),
  },
  setSendingSettings: {
    request: z.object({
      delay_seconds: z.number(),
      sequence_message_id: z.number(),
      sending_time_settings: SendingTimeSettings,
    }),
    response: z.object({
      sequence_message: SequenceMessage,
    }),
    query: z.undefined(),
    path: z.undefined(),
  },
  setFlow: {
    request: z.object({
      sequence_message_id: z.number(),
      flow_ns: z.string(),
    }),
    response: z.object({
      sequence_message: SequenceMessage,
    }),
    query: z.undefined(),
    path: z.undefined(),
  },
  delete: {
    request: z.undefined(),
    response: z.undefined(),
    query: z.object({
      sequence_message_id: z.number(),
    }),
    path: z.undefined(),
  },
  patch: {
    request: z.object({
      sequence_message_id: z.number(),
      status: z.nativeEnum(StatusType),
    }),
    response: z.object({
      sequence_message: SequenceMessage,
      sequence: SequenceResponse,
    }),
    query: z.undefined(),
    path: z.undefined(),
  },
}

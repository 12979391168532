import get from 'lodash/get'
import { l } from '@manychat/manyui'

import { BlockType } from 'common/builder/constants/BlockType'
import { ButtonType } from 'common/builder/constants/ButtonType'
import { INLINE_BUTTON_REGEX } from 'constants/RegExs'
import { calculateCTR } from 'utils/stats'

import { getNodeCtrUniqueUsers } from './typedHelpers'

// quick replies
export function isDraft(reply) {
  return reply.$draft
}

export function getCaption(button, defaults = '') {
  if (!button) {
    return defaults
  }

  if (button.type === ButtonType.SHARE) {
    return l.translate('Share')
  }
  return button.caption || defaults
}

export function getStats(button, content) {
  const uniqueUsers = getNodeCtrUniqueUsers(content)

  const ctr = calculateCTR(get(button, 'stats.users') || 0, uniqueUsers)

  return {
    ...button.stats,
    ctr,
  }
}

export function getSmartLinkIdsInText(text) {
  const reg = new RegExp(INLINE_BUTTON_REGEX)
  const ids = []

  for (let math = reg.exec(text); math !== null; math = reg.exec(text)) {
    if (math[1]) {
      ids.push(math[1])
    }
  }

  return ids
}

export function isValidSmartLinkButton(parentBlock, button) {
  if (!button.isSmartLink) {
    return false
  }

  if (parentBlock.type === BlockType.EMAIL_IMAGE || parentBlock.type === BlockType.EMAIL_BUTTON) {
    return true
  }

  if (parentBlock.type === BlockType.TEXT || parentBlock.type === BlockType.EMAIL_TEXT) {
    const smartLinkIds = getSmartLinkIdsInText(parentBlock.text)
    return smartLinkIds.includes(button.id)
  }

  return false
}

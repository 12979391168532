import { initializeUsercentricsListeners } from 'utils/getInfoAboutService'
import { isLocalSentryLog } from 'utils/isLocalSentryLog'
import { SentryService } from 'utils/services/errorTrackingService/sentryService'

import { ErrorTracking } from './interfaces/types'

const hasLocalLog = isLocalSentryLog()

if (hasLocalLog) {
  window.__SENTRY_ENV__ = {
    environment: 'localhost',
    release: 'prod',
  }
}

const sentryEnv = window?.__SENTRY_ENV__ ?? null
const errorTrackingService: ErrorTracking.Service = new SentryService(sentryEnv)
initializeUsercentricsListeners(errorTrackingService.refreshPermissions)

if (hasLocalLog) {
  setTimeout(errorTrackingService.refreshPermissions)
}
export default errorTrackingService

import { z } from 'zod'

import { ActionTypes } from 'common/actions'
import { MIGRATE_CUF_TO_SF } from 'common/audience/constants/BulkActionTypes'
import { StatusType } from 'common/audience/models/Subscriber/constants'
import { BusinessErrorSchema } from 'shared/api/common/schemas/errors'
import { FilterSchema } from 'shared/api/common/schemas/filters'

import { UserFieldSchema } from '../customFields/schemas'
import { SequenceSchema } from '../sequence/schemas'
import { TagSchema } from '../tags/schemas'

const SubscriberFieldValue = z.union([z.number(), z.string(), z.boolean(), z.null()])
const SubscriberFieldValueSchema = z
  .union([SubscriberFieldValue, z.array(SubscriberFieldValue)])
  .nullable()

export const SubscriberFieldSchema = z.object({
  field: UserFieldSchema,
  field_id: z.number(),
  data: z.object({
    source: z.string().optional(),
    value: SubscriberFieldValueSchema,
  }),
  value: SubscriberFieldValueSchema,
})

export const SubscriberOtnReasons = z.object({
  reason_id: z.number(),
  channel: z.string(),
  reason_caption: z.string().nullable(),
  reason_description: z.string().nullable(),
  token_count: z.number().nullable().optional(),
  type: z.string().nullable().optional(),
})

export const SubscriberRecurringNotificationReasons = z.object({
  reason_id: z.number(),
  channel: z.string(),
  status: z.string(),
  reason_caption: z.string().nullable(),
  reason_description: z.string().nullable(),
  type: z.string(),
  ts_expired: z.number().nullable(),
  ts_will_be_available: z.number().nullable(),
})

export const UserSchema = z.object({
  name: z.string().nullable(),
  first_name: z.string(),
  last_name: z.string(),
  locale: z.string().nullable(),
  language: z.string().nullable(),
  timezone: z.string().nullable(),
  title: z.string().nullable(),
  avatar: z.string().nullable(),
  optin_source: z.number().nullable(),
  source_app_id: z.number().nullable(),
  phone: z.string().nullable(),
  phone_country_code: z.string().nullable(),
  email: z.string().nullable(),
  ig_username: z.string().nullable(),
  tg_username: z.string().nullable(),
  tt_username: z.string().nullable(),
  gender: z.string().nullable(),

  // string dates like "2022-10-22 07:29:05.5881"
  last_read: z.string().nullable(),
  ig_last_read: z.string().nullable(),
  last_user_interaction: z.string().nullable(),
  last_wa_user_interaction: z.string().nullable(),
  last_ig_user_interaction: z.string().nullable(),
  last_tg_user_interaction: z.string().nullable(),
  last_tt_user_interaction: z.string().nullable(),
  last_sms_user_interaction: z.string().nullable(),
  email_ts_opt_in: z.string().nullable(),
  email_ts_opt_out: z.string().nullable(),
  sms_ts_opt_in: z.string().nullable(),
  sms_ts_opt_out: z.string().nullable(),

  // ids
  user_id: z.string(),
  fb_psid: z.string(),
  wa_id: z.string().nullable(),
  ig_id: z.number().nullable(),

  // flags
  optin_fb: z.boolean(),
  optin_wa: z.boolean(),
  optin_ig: z.boolean(),
  optin_tg: z.boolean(),
  optin_tt: z.boolean(),
  optin_email: z.boolean().nullable(),
  optin_phone: z.boolean().nullable(),
  has_question_context: z.boolean(),
  tg_importing: z.boolean(),

  // statuses
  status: z.nativeEnum(StatusType),
  fb_status: z.nativeEnum(StatusType),
  wa_status: z.nativeEnum(StatusType),
  ig_status: z.nativeEnum(StatusType),
  tg_status: z.nativeEnum(StatusType),
  tt_status: z.nativeEnum(StatusType),

  // timestamps
  ts_subscribed: z.number(),
  ts_added: z.number(),
  raw_ts_added: z.number(),
  ts_automation_paused_until: z.number().nullable(),
  ts_last_user_interaction: z.boolean().or(z.number()).nullable(),
  ts_last_wa_user_interaction: z.boolean().or(z.number()).nullable(),
  ts_last_ig_user_interaction: z.boolean().or(z.number()).nullable(),
  ts_last_tg_user_interaction: z.boolean().or(z.number()).nullable(),
  ts_last_tt_user_interaction: z.boolean().or(z.number()).nullable(),
  ts_last_sms_user_interaction: z.boolean().or(z.number()).nullable(),
  ts_last_read: z.boolean().or(z.number()).nullable(),
  ts_ig_last_read: z.boolean().or(z.number()).nullable(),
  ts_tt_last_read: z.boolean().or(z.number()).nullable(),

  // complex fields
  tags: z.array(TagSchema),
  widgets: z.array(TagSchema),
  ads_growth_tool: z.array(TagSchema),
  sequences: z.array(SequenceSchema),
  icebreakers: z.array(TagSchema),
  fields: z.array(SubscriberFieldSchema),
  otn_reasons: z.array(SubscriberOtnReasons),
  one_time_notification_reasons: z.array(SubscriberOtnReasons),
  recurring_notification_reasons: z.array(SubscriberRecurringNotificationReasons),
})

const DeletedUserSchema = UserSchema.pick({
  avatar: true,
  email: true,
  fb_psid: true,
  fb_status: true,
  gender: true,
  has_question_context: true,
  ig_id: true,
  ig_status: true,
  ig_username: true,
  name: true,
  optin_email: true,
  optin_fb: true,
  optin_ig: true,
  optin_phone: true,
  optin_source: true,
  optin_tg: true,
  optin_wa: true,
  optin_tt: true,
  phone: true,
  phone_country_code: true,
  raw_ts_added: true,
  source_app_id: true,
  status: true,
  tg_importing: true,
  tg_status: true,
  tg_username: true,
  title: true,
  ts_added: true,
  ts_automation_paused_until: true,
  ts_subscribed: true,
  user_id: true,
  wa_id: true,
  wa_status: true,
  tt_status: true,
  tt_username: true,
})

// TODO We need to change schemas for audience API's, main schema for extend it will be UserForSearchSchema from search method
// 1. UserSchema rename to UserDetailsSchema
// 2. UserForSearchSchema rename to UserSchema and fulfil with intersection fields
// 3. UserForSearchSchema rename to UserSchema
export const UserForSearchSchema = z.object({
  user_id: z.string(),
  name: z.string().nullable(),
  email: z.string().nullable(),
  phone: z.string().nullable(),
  ig_id: z.number().nullable(),
  tg_username: z.string().nullable(),
  wa_id: z.string().nullable(),
  fb_psid: z.string(),
})

export const AudienceBulkActionTypes = [
  ActionTypes.ADD_TAG,
  ActionTypes.REMOVE_TAG,
  ActionTypes.SUBSCRIBE,
  ActionTypes.UNSUBSCRIBE,
  ActionTypes.PAUSE_AUTOMATION_FOREVER,
  ActionTypes.RESUME_AUTOMATION,
  ActionTypes.SET_CUF,
  ActionTypes.UNSET_CUF,
  ActionTypes.GLOBAL_UNSUBSCRIBE,
  ActionTypes.DELETE_SUBSCRIBER,
  MIGRATE_CUF_TO_SF,
] as const

export type AudienceBulkActionType = typeof AudienceBulkActionTypes[number]

const BulkActionTypeSchema = z.enum(AudienceBulkActionTypes)

export const SelectionTypeSchema = z.enum(['exclude', 'include'])

const UserFieldOperationSchema = z.enum(['='] as const)

export const AudienceBulkActionPayloadSchema = z.object({
  q: z.string().optional(),
  selection_type: SelectionTypeSchema,
  filter: FilterSchema.nullable(),
  ids: z.array(z.string()),
  async: z.string().uuid().optional(),
  type: BulkActionTypeSchema,
  tag_id: z.number().optional(),
  _oid: z.string().uuid().optional(),
  operation: UserFieldOperationSchema.optional(),
  field_id: z.number().or(z.string()).optional(),
  props: z
    .object({
      tag_id: z.number().optional(),
    })
    .optional(),
  tags: z.array(z.string()).optional(),
  overwrite: z.boolean().optional(),
  suf: z.string().nullable().optional(),
})

export type AudienceCreateWhatsAppSubscribersRequest = z.infer<
  typeof audienceApiSchemas.createWhatsAppSubscribers.request
>

export type AudienceDeleteDataRequest = z.infer<typeof audienceApiSchemas.deleteData.request>
export type AudienceExportDataRequest = z.infer<typeof audienceApiSchemas.exportData.request>
export type AudienceSelectionType = z.infer<typeof SelectionTypeSchema>
export type AudienceBulkActionPayload = z.infer<typeof AudienceBulkActionPayloadSchema>

export type User = z.infer<typeof UserSchema>

export type UserForSearch = z.infer<typeof UserForSearchSchema>

export const audienceApiSchemas = {
  details: {
    path: z.undefined(),
    query: z.object({
      user_id: z.string(),
    }),
    request: z.undefined(),
    response: z.object({
      user: UserSchema,
    }),
  },
  addTag: {
    path: z.undefined(),
    query: z.object({
      user_id: z.string(),
      tag_id: z.number(),
    }),
    request: z.undefined(),
    response: z.undefined(),
  },
  removeTag: {
    path: z.undefined(),
    query: z.object({
      user_id: z.string(),
      tag_id: z.number(),
    }),
    request: z.undefined(),
    response: z.undefined(),
  },
  addToSequence: {
    path: z.undefined(),
    query: z.object({
      user_id: z.string(),
      sequence_id: z.number(),
    }),
    request: z.undefined(),
    response: z.undefined(),
  },
  removeFromSequence: {
    path: z.undefined(),
    query: z.object({
      user_id: z.string(),
      sequence_id: z.number(),
    }),
    request: z.undefined(),
    response: z.undefined(),
  },
  unsubscribe: {
    path: z.undefined(),
    query: z.object({
      user_id: z.string(),
    }),
    request: z.undefined(),
    response: z.undefined(),
  },
  unsubscribeFromSMS: {
    path: z.undefined(),
    query: z.object({
      user_id: z.string(),
    }),
    request: z.undefined(),
    response: z.undefined(),
  },
  unsubscribeFromEmail: {
    path: z.undefined(),
    query: z.object({
      user_id: z.string(),
    }),
    request: z.undefined(),
    response: z.undefined(),
  },
  unsubscribeFromWhatsApp: {
    path: z.undefined(),
    query: z.object({
      user_id: z.string(),
    }),
    request: z.undefined(),
    response: z.undefined(),
  },
  unsubscribeFromTelegram: {
    path: z.undefined(),
    query: z.object({
      user_id: z.string(),
    }),
    request: z.undefined(),
    response: z.undefined(),
  },
  unsubscribeFromInstagram: {
    path: z.undefined(),
    query: z.object({
      user_id: z.string(),
    }),
    request: z.undefined(),
    response: z.undefined(),
  },
  createWhatsAppSubscribers: {
    path: z.undefined(),
    query: z.undefined(),
    request: z.object({
      wa_subscribers_data: z.array(
        z.object({
          first_name: z.string(),
          wa_id: z.number(),
        }),
      ),
    }),
    response: z.object({
      users: z.array(z.number()),
    }),
  },
  deleteData: {
    path: z.undefined(),
    query: z.undefined(),
    request: z.object({
      subscriber_id: z.string(),
    }),
    response: z.object({
      subscriber: DeletedUserSchema,
    }),
  },
  exportData: {
    path: z.undefined(),
    query: z.undefined(),
    request: z.object({
      subscriber_id: z.string(),
    }),
    response: z.object({
      async: z.boolean(),
      async_event: z.string(),
      async_status: z.object({
        signature: z.string(),
        progress: z.number(),
        done: z.boolean(),
        result: z.null(),
      }),
    }),
  },
  prepareCsvExport: {
    path: z.undefined(),
    query: z.undefined(),
    request: z.object({
      async: z.string(),
      filter: FilterSchema.nullable(),
      selection_type: z.string(),
      ids: z.array(z.string()),
      q: z.string().optional(),
    }),
    response: z.object({ async: z.string() }),
  },
  checkZapierHook: {
    path: z.undefined(),
    query: z.object({
      type: z.string(),
      tag_id: z.number().optional(),
      field_id: z.number().optional(),
    }),
    request: z.undefined(),
    response: z.object({ has_hook: z.boolean() }),
  },
  search: {
    path: z.undefined(),
    query: z.undefined(),
    request: z.object({
      q: z.string(),
    }),
    response: z.object({
      total: z.number(),
      users: z.array(UserForSearchSchema),
    }),
  },
  getAccountUserSubscriber: {
    path: z.undefined(),
    query: z.undefined(),
    request: z.undefined(),
    response: z.object({
      subscriber: UserForSearchSchema.nullable(),
    }),
  },
  bulkAction: {
    path: z.undefined(),
    query: z.undefined(),
    request: AudienceBulkActionPayloadSchema,
    response: z.object({
      async: z.string().optional(),
    }),
    errors: {
      DuplicateWaSubscriberError: BusinessErrorSchema.extend({
        field: z.literal('wa_subscribers_data'),
      }),
      SubscriberNotFoundError: BusinessErrorSchema.extend({
        field: z.literal('user_id'),
      }),
      UserNotFoundError: BusinessErrorSchema.extend({
        field: z.literal('user_not_found'),
      }),
      BulkInProgressError: BusinessErrorSchema.extend({
        field: z.literal('bulk_in_progress'),
      }),
      WrongSequenceError: BusinessErrorSchema.extend({
        field: z.literal('sequenceId'),
      }),
      WrongFieldError: BusinessErrorSchema.extend({
        field: z.literal('fieldId'),
      }),
      WrongTagError: BusinessErrorSchema.extend({
        field: z.literal('tagId'),
      }),
    },
  },
  blockWAContact: {
    path: z.undefined(),
    query: z.undefined(),
    request: z.object({
      subscriber_id: z.string(),
    }),
    response: z.undefined(),
  },
}

import React from 'react'
import { useAppSelector } from 'reduxTyped'
import { l, Link } from '@manychat/manyui'

import { getPageId } from 'common/core/selectors/appSelectors'
import { analyticsService } from 'utils/services/analytics'

import cm from '../common.module.css'

export const WACoexistenceContactImportCompleted = () => {
  const currentPageId = useAppSelector(getPageId)

  const handleViewContacts = () => {
    analyticsService.sendEvent(
      'NOTIFICATION_BAR.WA_COEXISTENCE_CONTACT_IMPORT_COMPLETED.VIEW_CONTACTS.CLICK',
    )
  }

  return (
    <span>
      <span>{l.translate('Contacts have been imported from WhatsApp Business App.')}</span>{' '}
      <Link
        target="_blank"
        className={cm.readMore}
        href={`/fb${currentPageId}/subscribers`}
        onClick={handleViewContacts}
      >
        {l.translate('View contacts')}
      </Link>
    </span>
  )
}

import { WidgetTypeNumeric, WidgetTypes } from 'apps/growthTools/models/Widget/constants'
import { ChannelType } from 'common/core/constants/ChannelType'
import { FsTriggerModel } from 'shared/api/requests/cms/schemas'

import { triggerType, isWidget, isIntent } from './triggerType'
import { FiltersFromQuery } from './types'

export const isFbChannel = (channel?: ChannelType | null) => channel === ChannelType.FB

export const isFbKeyword = (query: FiltersFromQuery) =>
  isFbChannel(query.channel) && triggerType(query.trigger_model) && !query.widget_type

export const isFbIntent = (query: FiltersFromQuery) =>
  isFbChannel(query.channel) && isIntent(query.trigger_model) && !query.widget_type

export const isFbRefUrl = (query: FiltersFromQuery) =>
  isFbChannel(query.channel) &&
  isWidget(query.trigger_model) &&
  query.widget_type === WidgetTypeNumeric.MESSENGER_REF_URL

export const isFbAdsJson = (query: FiltersFromQuery) =>
  isFbChannel(query.channel) &&
  isWidget(query.trigger_model) &&
  query.widget_type === WidgetTypeNumeric.ADS_JSON

export const isFbAds = (query: FiltersFromQuery) =>
  isFbChannel(query.channel) &&
  isWidget(query.trigger_model) &&
  query.widget_type === WidgetTypeNumeric.ADS

export const isFbQr = (query: FiltersFromQuery) =>
  isFbChannel(query.channel) &&
  isWidget(query.trigger_model) &&
  query.widget_type === WidgetTypeNumeric.MESSENGER_CODE

export const isFbCgt = (query: FiltersFromQuery) =>
  isFbChannel(query.channel) &&
  isWidget(query.trigger_model) &&
  query.widget_type === WidgetTypeNumeric.FEED_COMMENT_TRIGGER

export const isFbShop = (query: FiltersFromQuery) =>
  isFbChannel(query.channel) &&
  isWidget(query.trigger_model) &&
  query.widget_type === WidgetTypeNumeric.FACEBOOK_SHOP

export const isAllFbTriggers = (query: FiltersFromQuery) =>
  isFbChannel(query.channel) && !query.trigger_model && !query.widget_type

export const isTriggerMatchesFbFilter = ({
  widgetType,
  filter,
  triggerModel,
  triggerChannel,
}: {
  widgetType: WidgetTypes | null
  filter: FiltersFromQuery
  triggerModel: FsTriggerModel
  triggerChannel: ChannelType | null
}) =>
  triggerChannel === ChannelType.FB &&
  (isAllFbTriggers(filter) ||
    (triggerModel === FsTriggerModel.KEYWORD && isFbKeyword(filter)) ||
    (triggerModel === FsTriggerModel.INTENT && isFbIntent(filter)) ||
    (widgetType === WidgetTypes.MESSENGER_REF_URL && isFbRefUrl(filter)) ||
    (widgetType === WidgetTypes.ADS_JSON && isFbAdsJson(filter)) ||
    (widgetType === WidgetTypes.ADS && isFbAds(filter)) ||
    (widgetType === WidgetTypes.MESSENGER_CODE && isFbQr(filter)) ||
    (widgetType === WidgetTypes.FEED_COMMENT_TRIGGER && isFbCgt(filter)) ||
    (widgetType === WidgetTypes.FACEBOOK_SHOP && isFbShop(filter)))

import { Icon, l } from '@manychat/manyui'

import { TABS_ID } from 'apps/profile/constants/tabs'
import { RoutePaths } from 'utils/router/constants'

export interface ProfileSection {
  id: keyof typeof TABS_ID
  icon?: keyof typeof Icon
  label: () => string
  route: string
}

export const ICON_SIZE = 20

export const ProfileSectionsConfig: ProfileSection[] = [
  {
    id: 'pages',
    icon: 'Bot',
    label: l.makeTranslate('Manage Accounts'),
    route: RoutePaths.HOMETAB,
  },
  {
    id: 'compliance-monitoring',
    icon: 'Publish',
    label: l.makeTranslate('Message reports'),
    route: RoutePaths.PROFILE_FLOWS_HEALTH,
  },
]

export const AdvancedSectionsConfig: ProfileSection[] = [
  {
    id: 'agency',
    label: l.makeTranslate('Agency partner program'),
    route: RoutePaths.AGENCY_PROFILE,
  },
  {
    id: 'templates',
    label: l.makeTranslate('My Templates'),
    route: RoutePaths.PROFILE_TEMPLATES,
  },
  {
    id: 'settings',
    label: l.makeTranslate('API Settings'),
    route: RoutePaths.PROFILE_SETTINGS,
  },
  {
    id: 'applications',
    label: l.makeTranslate('My Applications'),
    route: RoutePaths.PROFILE_APPLICATIONS,
  },
]

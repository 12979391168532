import { BusinessError } from 'shared/api/lib/errors/business'

interface HasUndeletableContentError extends BusinessError {
  field: 'has_undeletable_content'
}

interface IsTittleError extends BusinessError {
  field: 'title'
}

const isUndeletableContentError = (error: BusinessError): error is HasUndeletableContentError =>
  error.field === 'has_undeletable_content'

const isTitleError = (error: BusinessError): error is IsTittleError => error.field === 'title'

export const CmsApiGuard = {
  delete: {
    isUndeletableContentError,
  },
  rename: {
    isTitleError,
  },
}

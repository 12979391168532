export enum StripeCheckoutStatuses {
  SUCCESS = 'success',
  CANCEL = 'cancel',
  ERROR = 'error',
}

export enum StripeCheckoutTypes {
  UPGRADE_DATA = 'upgrade_data',
  UPGRADE_TO_PRO = 'upgrade_to_pro',
  TRIAL = 'trial',
}

export enum StripeCheckoutSources {
  TRIAL_MODAL = 'trial_modal',
  TRIAL_WITH_PHONE_NUMBER = 'trial_with_phone_number',
  WA_COEXISTENCE_SUBSCRIPTION = 'wa_coexistence_subscription',
}

import React from 'react'
import notificationIcon from 'assets/img/notification_logo.png'
import { useAppSelector } from 'reduxTyped'
import { l } from '@manychat/manyui'

import { UserThreadNewEvent } from 'apps/chat/constants/LiveChatInterfaces'
import { LOCAL_STORAGE_KEY_NOTIFICATION_TAB_ID } from 'apps/chat/constants/LiveChatNotifications'
import { LivechatNotificationSetting } from 'common/settings/constants/LiveChat'
import { getLivechatSettings } from 'common/settings/selectors/settingsSelectors'
import localStorage from 'utils/localStorage'
import { createBrowserNotification } from 'utils/notifications'
import { analyticsService } from 'utils/services/analytics'

import { ChatNotificationPayload } from '../types/chatNotificationTypes'
import { defaultNotificationOpenCallback } from '../utils/callbacks/defaultNotificationOpenCallback'

import { useBrowserNotificationsMap } from './useBrowserNotificationsMap'

export const useThreadUnassignedPushNotification = (tabID: number) => {
  const { addNotification } = useBrowserNotificationsMap(tabID)
  const livechatSettings = useAppSelector(getLivechatSettings)
  const isSettingEnabled =
    livechatSettings?.[LivechatNotificationSetting.NEW_MESSAGE_FROM_UNASSIGNED]

  const triggerThreadUnassignedPushNotification = React.useCallback(
    (threadAssignedToMeNotificationData: UserThreadNewEvent) => {
      const userId = threadAssignedToMeNotificationData?.user_id
      const isCurrentTabActiveForNotifications = Number(
        localStorage.getItem(LOCAL_STORAGE_KEY_NOTIFICATION_TAB_ID),
      )

      if (isCurrentTabActiveForNotifications !== tabID || !userId || !isSettingEnabled) {
        return
      }

      analyticsService.sendEvent('LIVE_CHAT.BROWSER_PUSH_NOTIFICATION.UNASSIGNED_THREAD')

      const notificationPayload: ChatNotificationPayload = {
        threadId: userId,
      }

      const notificationInstance = createBrowserNotification(
        l.translate('New unassigned conversation'),
        {
          icon: notificationIcon,
          silent: true,
          tag: `unassigned_conversation_${userId}`,
          data: notificationPayload,
        },
      )

      if (notificationInstance) {
        notificationInstance.onclick = defaultNotificationOpenCallback
        addNotification(userId, notificationInstance)
      }
    },
    [tabID, addNotification, isSettingEnabled],
  )

  return { triggerThreadUnassignedPushNotification }
}

import { InputMode } from '../interfaces'

/**
 * formatInput changes/formats the initial input string
 * For "numeric": only digits and a leading minus sign are allowed
 * For "decimal": allows digits, a leading minus, and a single decimal separator
 * Commas are converted to dots.
 */
const formatInput = (unformattedValue: string, maxSymbols?: number, inputMode?: InputMode) => {
  if (inputMode === 'numeric') {
    let formattedValue = unformattedValue.replace(/[^0-9-]/g, '')
    formattedValue = formattedValue.replace(/(?!^)-/g, '')
    if (maxSymbols !== undefined) {
      formattedValue = formattedValue.slice(0, maxSymbols)
    }
    return formattedValue
  } else {
    let formattedValue = unformattedValue.replace(/[^0-9.,-]/g, '')
    formattedValue = formattedValue.replace(/(?!^)-/g, '')
    formattedValue = formattedValue.replace(/,/g, '.')
    const dotIndex = formattedValue.indexOf('.')
    if (dotIndex !== -1) {
      formattedValue =
        formattedValue.slice(0, dotIndex + 1) +
        formattedValue.slice(dotIndex + 1).replace(/\./g, '')
    }
    if (maxSymbols !== undefined) {
      formattedValue = formattedValue.slice(0, maxSymbols)
    }
    return formattedValue
  }
}

export default formatInput

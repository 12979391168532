import React, { useEffect, useState, useRef } from 'react'
import { findDOMNode } from 'react-dom'
import cx from 'classnames'
import dayjs from 'dayjs'
import { BtnV2, CheckboxV2, l, Modal } from '@manychat/manyui'

import { FBPolicyEnforcementModalType } from 'common/core/components/FBPolicyEnforcementModal/FBPolicyEnforcementModalType'
import { isTagViolation } from 'common/core/models/fbPolicyEnforcementHelpers'
import If from 'components/If/If'
import Tip from 'components/Tip'
import { useShakeAnim } from 'utils/commonHooks'
import { getDeserializationData } from 'utils/serializing/getDeserializationData'
import { getSerializationData } from 'utils/serializing/renderHelper'
import { analyticsService } from 'utils/services/analytics'
import { useHotjar } from 'utils/services/hotjar/useHotjar'

import { FBPolicyEnforcementModalSuspectedOffenders } from './FBPolicyEnforcementModalSuspectedOffenders'

import cm from './FBPolicyEnforcementModal.module.css'

enum Steps {
  FIRST = 1,
  LAST = 2,
}

interface IFBPolicyEnforcementModal {
  modalType: FBPolicyEnforcementModalType
  text: string
  ts: number
  onClose: () => void
  isRelogin: boolean
  withoutConfirm?: boolean
  withoutUnblockDetails?: boolean
  eventPrefix: string
}

const FBPolicyEnforcementModal = ({
  modalType,
  text,
  ts,
  onClose,
  isRelogin,
  withoutConfirm,
  withoutUnblockDetails,
  eventPrefix,
}: IFBPolicyEnforcementModal) => {
  const [isConfirm, setConfirm] = useState(false)
  const [step, setStep] = useState(1)
  const modalRef = useRef(null)

  const [showModal, setShowModal] = useState(Boolean(modalType))

  const [errorConfirmation, setErrorConfirmation] = useState(false)
  const [shakeTermsClass, shakeTerms] = useShakeAnim()

  const { hotjarTrigger } = useHotjar()

  const handleClose = () => {
    if (isRelogin || withoutConfirm) {
      onClose()
      setShowModal(false)
      return
    }
    if (!isConfirm) {
      setErrorConfirmation(true)
      shakeTerms()
      return
    }

    analyticsService.sendEvent(`${eventPrefix}.CONFIRM.CLICKED`, { modalType })
    onClose()
    setShowModal(false)
  }

  const handleCheckboxChange = () => {
    setErrorConfirmation(false)
    setConfirm(!isConfirm)
    analyticsService.sendEvent(`${eventPrefix}.CHECKBOX.CHECKED`, {
      checked: !isConfirm,
      modalType,
    })
  }

  const handleSuspectedOffenderItemClick = () => {
    onClose()
    setShowModal(false)
  }

  const handleLinkClick = (event: React.MouseEvent<HTMLAnchorElement>) => {
    const target = event.target as HTMLAnchorElement
    analyticsService.sendEvent(`${eventPrefix}.HELP_LINK.CLICKED`, { href: target.href, modalType })
  }

  const handleFormLinkClick = () => {
    analyticsService.sendEvent(`${eventPrefix}.FEEDBACK_FORM.CLICKED`, { modalType })
  }

  const scrollTop = () => {
    try {
      // eslint-disable-next-line
      // @ts-expect-error
      findDOMNode(modalRef.current).scrollTop = 0
    } catch (err) {}
  }

  const handlePrev = () => {
    setErrorConfirmation(false)
    setStep(step - 1)
    scrollTop()
    analyticsService.sendEvent(`${eventPrefix}.BACK.CLICKED`, { modalType })
  }
  const handleNext = () => {
    setStep(step + 1)
    scrollTop()
    analyticsService.sendEvent(`${eventPrefix}.NEXT.CLICKED`, { modalType })
  }

  useEffect(() => {
    if (showModal && !isRelogin) {
      analyticsService.sendEvent(`${eventPrefix}.SHOWN`, { modalType })
      hotjarTrigger('fb_policy_enforcement_modal_shown', [
        'fb_policy_enforcement_modal',
        `fb_policy_enforcement_modal_${modalType}`,
      ])
    } else {
      setConfirm(false)
    }
  }, [eventPrefix, hotjarTrigger, isRelogin, modalType, showModal])

  const created = dayjs.unix(ts)
  const timestamp = (
    <span
      className="d-inline-block"
      style={{ borderBottom: '1px dashed' }}
      data-title={created.format('YYYY-MM-DD HH:mm')}
    >
      {created.fromNow()}
    </span>
  )

  const isCloseOptionDisabled = !isRelogin && !withoutConfirm
  const showSuspectedOffenders =
    isTagViolation(text) &&
    (modalType === FBPolicyEnforcementModalType.WARNING ||
      modalType === FBPolicyEnforcementModalType.BLOCK)

  let textFromFacebook = getDeserializationData(text, {
    hasSaveHref: true,
    hasRemoveTags: true,
    hasTrim: true,
  })
  textFromFacebook = getSerializationData(textFromFacebook, true)

  return (
    <Modal
      className={cx(cm.modal, {
        [cm.wasBlock]: modalType === FBPolicyEnforcementModalType.WAS_BLOCK,
        [cm.warning]: modalType === FBPolicyEnforcementModalType.WARNING,
        [cm.block]: modalType === FBPolicyEnforcementModalType.BLOCK,
      })}
      test-id="warning-modal-test"
      disableClose={isCloseOptionDisabled}
      disableEscapeClose={isCloseOptionDisabled}
      disableOverlayClose={isCloseOptionDisabled}
      disableCloseButton={isCloseOptionDisabled}
      noContentPadding
      open={showModal}
      onRequestClose={handleClose}
      width={600}
      ref={modalRef}
    >
      <div className="p-a">
        {step === Steps.FIRST ? (
          <div key="step-1">
            <div className="p-a">
              {modalType === FBPolicyEnforcementModalType.WARNING && (
                <>
                  <span className="text-display-sm">
                    {l.translate('Your page was warned by Facebook')}
                  </span>
                  <Tip
                    className="text m-t-lg"
                    titleClassName={cm.tipTitle}
                    // todo
                    title={
                      <span>
                        {l.translate('Facebook sent a warning')} {timestamp}
                      </span>
                    }
                    text={l.translate(
                      'You might lose the ability to send Messages to your contacts after multiple warnings! Additional warnings might mean your page can be blocked.',
                    )}
                    rounded
                    warning
                  />
                </>
              )}
              {modalType === FBPolicyEnforcementModalType.BLOCK && (
                <>
                  <span className="d-block text-display-sm m-t-lg">
                    {l.translate('You’ve been blocked by Facebook')}
                  </span>
                  <Tip
                    className="text m-t-lg"
                    titleClassName={cm.tipTitle}
                    title={
                      <span>
                        {l.translate('Facebook has blocked you')} {timestamp}
                      </span>
                    }
                    text={l.translate(
                      'You may have lost the ability to use Message Tags, OTNs, or send Messages. Your page might also be blocked.',
                    )}
                    rounded
                    warning
                  />
                </>
              )}
              {modalType === FBPolicyEnforcementModalType.WAS_BLOCK && (
                <>
                  <span className="text-display-sm d-block m-t-lg">
                    {l.translate('You’ve been unblocked by Facebook')}
                  </span>
                  <Tip
                    className="text m-t-lg"
                    titleClassName={cm.tipTitle}
                    // todo
                    title={
                      <span>
                        {l.translate('Facebook has unblocked your page or Messenger functionality')}{' '}
                        {timestamp}
                      </span>
                    }
                    text={l.translate(
                      'You can now use Manychat and Messenger without any restrictions. But, please read below to avoid any additional warnings or blocks.',
                    )}
                    rounded
                    warning
                  />
                </>
              )}
            </div>
            <If
              condition={
                !(withoutUnblockDetails && modalType === FBPolicyEnforcementModalType.WAS_BLOCK)
              }
            >
              <div className={'p-a'}>
                {modalType === FBPolicyEnforcementModalType.WAS_BLOCK ? (
                  <h1>{l.translate('Cause of block or warning')}</h1>
                ) : (
                  <h1>{l.translate('Cause of {modalType}', { modalType })}</h1>
                )}
                <p>
                  {l.translate(
                    'The information below is from Facebook and we have included it here.',
                  )}
                </p>

                <p className="p-x-md m-t-md text-secondary">{textFromFacebook}</p>
              </div>
            </If>
          </div>
        ) : (
          <div key="step-2">
            <div className="p-x p-t">
              <h1 className="m-b">{l.translate('Next steps')}</h1>
              {modalType === FBPolicyEnforcementModalType.WARNING && (
                <p>
                  {l.translate(
                    'A warning is typically delivered 24 hours after a potential policy violation. These warnings are usually delivered due to a potential violation of Message Tags.',
                  )}{' '}
                  {l.translate('So, we recommend that you')}:
                </p>
              )}
              {modalType === FBPolicyEnforcementModalType.BLOCK && (
                <p>
                  {l.translate(
                    'A block is typically delivered 24 hours after the policy violation. These warnings are usually delivered due to a potential violation of Message Tags.',
                  )}{' '}
                  {l.translate('So, we recommend that you')}:
                </p>
              )}
              {modalType === FBPolicyEnforcementModalType.WAS_BLOCK && (
                <p>
                  {l.translate(
                    'Being unblocked by Facebook does NOT mean that you can continue to use Message Tags or OTNs the same way you have been using them.',
                  )}
                </p>
              )}

              <ul className={cx(cm.list)}>
                {(modalType === FBPolicyEnforcementModalType.WARNING ||
                  modalType === FBPolicyEnforcementModalType.BLOCK) && (
                  <div>
                    <li>
                      {l.translate(
                        'Make sure that understand Message Tag Policy. Click here to learn more about <a class="text-primary" href="{href}" target="_blank" rel="noreferrer" onclick="onClick">Message Tag Policy</a>, how to stay compliant, and some possible alternatives to Message Tags.',
                        {
                          onClick: handleLinkClick,
                          href: 'https://manychat.com/blog/an-updated-guide-to-facebooks-24-hour-rule-and-message-tags/',
                        },
                      )}
                    </li>
                    <li>
                      {l.translate(
                        'After you’ve read through the guide, you can watch this 3 minute <a class="text-primary" href="{href}" target="_blank" rel="noreferrer" onclick="onClick">video</a> that recaps and summarizes the information.',
                        {
                          onClick: handleLinkClick,
                          href: 'https://www.youtube.com/watch?v=EcsVF4xT8k4',
                        },
                      )}
                    </li>
                    <li>
                      {l.translate(
                        'If you want to continue to use Tags, include as much context in the message as possible. You can check the <a class="text-primary" href="{href}" target="_blank" rel="noreferrer" onclick="onClick">Guide on Message Tag Policy</a> for more details.',
                        {
                          onClick: handleLinkClick,
                          href: 'https://manychat.com/blog/an-updated-guide-to-facebooks-24-hour-rule-and-message-tags/',
                        },
                      )}
                    </li>
                    <li>
                      {l.translate(
                        'If you still feel like this warning is incorrect or wrong, we suggest you to write to <a class="text-primary" href="{firstHref}"target="_blank" rel="noreferrer" onclick="onClick">Facebook Support</a> and fill out <a class="text-primary" href="{secondHref}" target="_blank" rel="noreferrer" onclick="onClick">Manychat Blocks and Warnings Feedback Form.</a>',
                        {
                          firstOnClick: handleLinkClick,
                          secondOnClick: handleFormLinkClick,
                          firstHref: 'https://www.facebook.com/help/contact/571927962827151',
                          secondHref:
                            'https://docs.google.com/forms/d/e/1FAIpQLSf4iDL_Gg1wXbEOnzBf1ok9DJbnShosoNV9ABE6qgiqnZbiLA/viewform',
                        },
                      )}
                    </li>
                  </div>
                )}
                {modalType === FBPolicyEnforcementModalType.WAS_BLOCK && (
                  <div>
                    <li>
                      {l.translate(
                        'Please ensure that you’ve read the <a class="text-primary" href="{href}" target="_blank" rel="noreferrer" onclick="onClick">Updated Guide to Facebook’s 24 hr rule and Message Tags</a>.',
                        {
                          onClick: handleLinkClick,
                          href: 'https://manychat.com/blog/an-updated-guide-to-facebooks-24-hour-rule-and-message-tags/',
                        },
                      )}
                    </li>
                    <li>
                      {l.translate(
                        'If you haven’t done so, check your Automations for the correct usage of Message Tags and One Time Notifications (OTNs).',
                      )}
                    </li>
                    <li>
                      {l.translate(
                        'Continue to watch your email inbox, Manychat, and your Facebook page for additional warnings.',
                      )}
                    </li>
                    <li>
                      {l.translate(
                        'If you haven’t done so already, please fill out <a class="text-primary" href="{href}" target="_blank" rel="noreferrer" onclick="onClick">this form</a> to log your block case with Manychat. We will use these cases as study to further improve our educational content and communication.',
                        {
                          onClick: handleFormLinkClick,
                          href: 'https://docs.google.com/forms/d/e/1FAIpQLSf4iDL_Gg1wXbEOnzBf1ok9DJbnShosoNV9ABE6qgiqnZbiLA/viewform',
                        },
                      )}
                    </li>
                  </div>
                )}
              </ul>
              {showSuspectedOffenders && (
                <FBPolicyEnforcementModalSuspectedOffenders
                  eventPrefix={eventPrefix}
                  onClose={handleSuspectedOffenderItemClick}
                />
              )}
            </div>

            <If condition={!withoutConfirm}>
              <div className="p-x">
                <CheckboxV2
                  name="confirm"
                  checked={isConfirm}
                  onChange={handleCheckboxChange}
                  label={l.translate(
                    'I understand Facebook’s Message Tag Policy and what to do next',
                  )}
                  className={cx('m-b m-t-md', {
                    'text-danger': errorConfirmation,
                    [shakeTermsClass]: errorConfirmation,
                  })}
                />
              </div>
            </If>
          </div>
        )}
      </div>
      <If
        condition={!(withoutUnblockDetails && modalType === FBPolicyEnforcementModalType.WAS_BLOCK)}
      >
        {step === Steps.FIRST ? (
          <div
            className="align-center b-t d-flex flex-row p-a p-relative justify-end"
            key="buttons-1"
          >
            <BtnV2 variant="primary" label={l.translate('Next')} onClick={handleNext} />
          </div>
        ) : (
          <div
            className="align-center b-t d-flex flex-row p-a p-relative justify-between"
            key="buttons-2"
          >
            <BtnV2 label={l.translate('Back')} onClick={handlePrev} />
            <BtnV2 variant="primary" label={l.translate('Close')} onClick={handleClose} />
          </div>
        )}
      </If>
      <If condition={withoutUnblockDetails && modalType === FBPolicyEnforcementModalType.WAS_BLOCK}>
        <div
          className="align-center b-t d-flex flex-row p-a p-relative justify-end"
          key="buttons-3"
        >
          <BtnV2 variant="primary" label={l.translate('Close')} onClick={handleClose} />
        </div>
      </If>
    </Modal>
  )
}

export default FBPolicyEnforcementModal

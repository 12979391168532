import React, { useCallback, useEffect, useState } from 'react'

import OnboardingBase from 'utils/services/newOnboardingService/core/OnboardingBase'

type StepsViewFabric<StepsMap extends Record<string, unknown>> = {
  [Key in keyof StepsMap]: React.FunctionComponent<{
    stepId: Key
    step: StepsMap[Key]
  }>
}

type IProps<StepsMap extends Record<string, unknown>> = {
  onboarding: OnboardingBase<StepsMap>
  stepsView: StepsViewFabric<StepsMap>
}

const ManyChatOnboardingView = <StepsMap extends Record<string, unknown>>({
  onboarding,
  stepsView,
}: IProps<StepsMap>) => {
  const [currentStepData, setCurrentStepData] = useState({
    currentStep: onboarding.getCurrentStep(),
    currentStepId: onboarding.getCurrentStepId(),
  })
  const { currentStep, currentStepId } = currentStepData

  const handleOnbUpdate = useCallback((onboarding: OnboardingBase<StepsMap>) => {
    setCurrentStepData({
      currentStep: onboarding.getCurrentStep(),
      currentStepId: onboarding.getCurrentStepId(),
    })
  }, [])

  useEffect(() => {
    onboarding.addListener('RUN', handleOnbUpdate)
    onboarding.addListener('STEP_CHANGE', handleOnbUpdate)
    onboarding.addListener('COMPLETE', handleOnbUpdate)
    onboarding.addListener('SKIP', handleOnbUpdate)

    setCurrentStepData({
      currentStep: onboarding.getCurrentStep(),
      currentStepId: onboarding.getCurrentStepId(),
    })

    return () => {
      onboarding.removeListener('RUN', handleOnbUpdate)
      onboarding.removeListener('STEP_CHANGE', handleOnbUpdate)
      onboarding.removeListener('COMPLETE', handleOnbUpdate)
      onboarding.removeListener('SKIP', handleOnbUpdate)
    }
  }, [onboarding, handleOnbUpdate])

  if (!currentStepId || !currentStep) {
    return null
  }

  const StepComponent = stepsView[currentStepId]
  // eslint-disable-next-line
  // @ts-expect-error
  return <StepComponent stepId={currentStepId} step={currentStep} />
}

export default ManyChatOnboardingView

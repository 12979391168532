import { createSelector } from 'reselect'

import { LivechatNotificationSetting } from 'common/settings/constants/LiveChat'
import { getLivechatSettings } from 'common/settings/selectors/settingsSelectors'

export const getAreSoundAlertsEnables = (state: RootState) =>
  state?.settings?.item?.current_user?.livechat_settings?.lc_notify_sound || false

export const getAreAnyPushNotificationSettingsEnabled = createSelector(
  [getLivechatSettings],
  (livechatSettings) => {
    if (!livechatSettings) return false

    return Object.values(LivechatNotificationSetting).some((settingKey) =>
      Boolean(livechatSettings[settingKey]),
    )
  },
)

import React from 'react'
import { l } from '@manychat/manyui'

import { useBillingInfo, useCart } from '../../hooks'
import { Product } from '../../schemas'

import cm from './OrderSummary.module.css'

interface TotalProps {
  isPro: boolean
  cart: Product[]
}

export const Total = ({ isPro, cart }: TotalProps) => {
  const { totalPrice } = useCart()
  const { currency, currentMonthlyPrice } = useBillingInfo()

  if (isPro) {
    return (
      <ul className="d-flex flex-col p-0">
        <li className="d-flex flex-row justify-between m-b-xs align-center">
          <span>{l.translate('Current subscription cost')}</span>
          <b>{l.currency(currentMonthlyPrice, currency, 0)}</b>
        </li>

        {cart.map((product) => (
          <li key={product.slug} className="d-flex flex-row justify-between m-b-xs align-center">
            <span>{product.name}</span>
            <b>{l.currency(product.price, currency, 0)}</b>
          </li>
        ))}

        <li className="d-flex flex-row justify-between align-center">
          <span className="text-subheading">{l.translate('Total monthly subscription:')}</span>
          <span className={cm.total} data-test-id="total-price">
            {l.currency(currentMonthlyPrice + totalPrice, currency, 0)}
          </span>
        </li>
      </ul>
    )
  }

  return (
    <div className="d-flex flex-row justify-between">
      <span className={cm.total}>{l.translate('Total:')}</span>
      <span className={cm.total} data-test-id="total-price">
        {l.currency(totalPrice, currency, 0)}
      </span>
    </div>
  )
}

import { createSlice } from '@reduxjs/toolkit'
import shuffle from 'lodash/shuffle'
import sortBy from 'lodash/sortBy'

import { settingsActionMatcher, settingsActionMatcherLegacy } from 'common/billing/redux/utils'
import { CancellationReason } from 'shared/api/requests/billing/schemas'

import {
  backToPro,
  cancelSubscription,
  fetchCancellationReasonsList,
  fetchPro,
  getPrices,
  updateBillingEmail,
} from '../actions/billingActions'
import { applyCoupon, resetCoupon } from '../actions/couponActions'
import { ProInfo, ProInfoBase } from '../billingInterfaces'

const initialState: Readonly<ProInfo> = {}

const updateProState = <
  Action extends {
    payload: {
      pro: ProInfoBase
    }
  },
>(
  state: ProInfo,
  action: Action,
): ProInfo => ({
  ...state,
  ...(action.payload?.pro || {}),
})

export const billingProSlice = createSlice({
  name: 'pro',
  initialState: initialState as ProInfo,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(resetCoupon.fulfilled, (state, action) => ({
        ...state,
        coupon: undefined,
        ...(action.payload.pro || {}),
      }))
      .addCase(applyCoupon.fulfilled, (state, action) => ({
        ...state,
        coupon: action.payload.coupon_info,
        ...(action.payload.pro || {}),
      }))
      .addCase(cancelSubscription.fulfilled, updateProState)
      .addCase(backToPro.fulfilled, updateProState)
      .addCase(fetchPro.fulfilled, updateProState)
      .addCase(fetchCancellationReasonsList.pending, (state) => {
        state.fetchingCancelReasons = true
      })
      .addCase(fetchCancellationReasonsList.rejected, (state) => {
        state.fetchingCancelReasons = false
      })
      .addCase(fetchCancellationReasonsList.fulfilled, (state, action) => {
        state.fetchingCancelReasons = false
        state.cancellationReasonsList = sortBy(
          shuffle(action.payload.reasons),
          (item: CancellationReason) =>
            ['other_main', 'other', 'removed_other', 'covid_other'].includes(item.id) ||
            item.id.endsWith('_other'),
        )
      })
      .addCase(updateBillingEmail.fulfilled, (state, action) => {
        state.billing_email = action.meta.arg
      })
      .addCase(getPrices.fulfilled, (state, action) => {
        const { automation_price, inbox_price } = action.payload

        state.automationPrice = automation_price
        state.inboxPrice = inbox_price
      })

    /**
     * Adds a matcher to handle actions that include settings in the payload.
     * This matcher updates the state with the settings from the action payload.
     * The problem is that account settings includes Pro Settings, and we need to sync them with the pro slice.
     *
     * Performance is comparable between using 'action.type' and matchers, with similar results for actions without settings in the payload or data.
     */
    builder
      .addMatcher(settingsActionMatcher, (state, action) => {
        return { ...state, ...action.payload.settings.pro }
      })
      .addMatcher(settingsActionMatcherLegacy, (state, action) => {
        return { ...state, ...action.data.settings.pro }
      })
  },
})

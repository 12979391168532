import { api } from 'shared/api'
import { flodeskApiSchemas } from 'shared/api/requests/integrations/flodesk/schemas'

export const flodeskApi = {
  getSegments: api.account.createGet({
    url: '/integration/flodesk/segments',
    schemas: flodeskApiSchemas.getSegments,
  }),
  getCustomFields: api.account.createGet({
    url: '/integration/flodesk/customFields',
    schemas: flodeskApiSchemas.getCustomFields,
  }),
}

import React from 'react'
import cx from 'classnames'
import { Popover, Scrollbars, Highlight } from '@manychat/manyui'

import defaultProps from './defaultProps'
import Item from './Item'

import cm from './Autocomplete.module.css'

export default class AutocompleteDropdown extends React.Component {
  static defaultProps = Object.assign({}, defaultProps, {
    onChangeIndex: () => {},
    at: 'bottom',
    fitTargetWidth: true,
  })

  shouldComponentUpdate({ options, show, activeIndex, q, _refreshKey }, nextState) {
    if (
      activeIndex !== this.props.activeIndex ||
      options !== this.props.options ||
      show !== this.props.show ||
      q !== this.props.q ||
      _refreshKey !== this.props._refreshKey
    ) {
      return true
    }
    return false
  }

  select = (e) => {
    // Block clicks and form submition
    if (e) {
      e && e.stopPropagation()
      e && e.preventDefault()
      const newIndex = e.currentTarget.getAttribute('data-index')
      if (newIndex !== undefined) {
        this.props.onSelect(this.props.options[Number(newIndex)])
      }
    }
  }

  onHover = (e) => {
    const index = e.currentTarget.getAttribute('data-index')
    if (index !== undefined) {
      this.props.onChangeIndex(Number(index))
    }
  }

  renderItem = (opt, index, focused, options) => {
    const { renderItem, Item, labelKey, theme, q } = this.props

    let item = null

    if (renderItem) {
      item = renderItem(opt, index, focused, q, options)
    } else if (Item) {
      item = <Item {...{ opt, index, focused, q, options }} />
    } else {
      item = (
        <Highlight matchClass={theme.highlight || ''} search={q || ''}>
          {opt._displayLabel || opt[labelKey] || opt}
        </Highlight>
      )
    }

    const focusedClassName = theme.focused || cm.focused
    const notHasFocused = focused == null
    return (
      <div
        className={cx('p-x p-y-xs', cm.dropdownItem, theme.item, {
          [focusedClassName]: focused === index,
          [cm.dropdownItemNative]: notHasFocused,
        })}
        onMouseDown={this.handleItemMouseDown}
        onClick={this.select}
        onMouseOver={notHasFocused ? () => {} : this.onHover}
        data-index={index}
        data-test-id={`dropdown-item-${index}`}
      >
        {item}
      </div>
    )
  }

  render() {
    const { at, target, show, customButton: CustomButton } = this.props
    const { theme, options, useLayerForClickAway = false, activeIndex } = this.props

    if (!target) {
      return null
    }

    return (
      <Popover
        anchor={target}
        at={at}
        data-test-id="dropdown"
        autoPosition="shift flip"
        onRequestClose={this.props.onRequestClose}
        open={show}
        useLayerForClickAway={useLayerForClickAway}
        fitTargetWidth={this.props.target && this.props.fitTargetWidth}
        className={cx(theme.list || theme.dropdown || cm.dropdown)}
        style={this.props.style}
      >
        <Scrollbars
          autoHeight
          autoHeightMax={275}
          autoHide={false}
          className={cx(cm.itemsWrapper, theme.itemsWrapper)}
        >
          {this.props.options.map((opt, index, arr) => {
            return (
              <Item
                key={`item-${index}`}
                {...{ opt, index, options }}
                focused={CustomButton ? null : activeIndex}
                renderItem={this.props.renderItem}
                Item={this.props.Item}
                q={this.props.q}
                theme={this.props.theme}
                labelKey={this.props.labelKey}
                valueKey={this.props.valueKey}
                _refreshKey={this.props._refreshKey}
                onHover={CustomButton ? () => {} : this.onHover}
                onSelect={this.select}
              />
            )
          })}
          {CustomButton && <CustomButton className={cm.customButton} />}
        </Scrollbars>
      </Popover>
    )
  }
}

import React from 'react'
import { Popover } from '@manychat/manyui'

import { FIELD_PICKER_WIDTH } from 'common/fields/constants'

const InputPopover = ({
  input,
  open,
  children,
  useInputWidth,
}: {
  input: HTMLInputElement | undefined
  open: boolean
  useInputWidth?: boolean
  children?: React.ReactNode
}) => {
  const [width, setWidth] = React.useState(0)

  React.useLayoutEffect(() => {
    input && setWidth(input.clientWidth)
  }, [input, open])

  const style = { width: useInputWidth ? width : FIELD_PICKER_WIDTH }

  return (
    <Popover anchor={input} at="bottom" open={open} className="m-t-xxs">
      <div onMouseDown={(event) => event.preventDefault()} style={style}>
        {children}
      </div>
    </Popover>
  )
}

export default InputPopover

import { z } from 'zod'

import {
  ExperienceLevel,
  StrategyPlaces,
  UseCases,
} from 'apps/quickCampaigns/quickCampaignsInterfaces'
import BackendContentType from 'common/builder/constants/BackendContentType'
import { ChannelType } from 'common/core/constants/ChannelType'

const FlowContentsSchema = z.array(
  z.object({
    type: z.nativeEnum(BackendContentType),
    content_id: z.number(),
    caption: z.string(),
    namespace: z.string(),
    deleted: z.boolean(),
    ts_created: z.number(),
    ts_updated: z.number(),
    data: z.object({}),
  }),
)

const FlowDataForSimulatorSchema = z.object({
  root_content_id: z.number().nullable(),
  contents: FlowContentsSchema,
})

export const QuickCampaignSchema = z.object({
  quick_campaign_id: z.number(),
  template_id: z.number().nullable(),
  title: z.string(),
  description: z.string().nullable(),
  image_url: z.string().nullable(),
  video_url: z.string().nullable(),
  channels: z.array(z.nativeEnum(ChannelType)),
  industries: z.array(z.string()),
  use_cases: z.array(z.nativeEnum(UseCases)),
  use_in: z.array(z.nativeEnum(StrategyPlaces)),
  sort: z.number(),
  growth_tools_reserved: z.number().nullable(),
  video_id: z.string().nullable(),
  experience_level: z.nativeEnum(ExperienceLevel).nullable(),
  is_pro: z.boolean(),
  is_ai: z.boolean(),
  is_popular: z.boolean(),
  long_description: z.string().nullable(),
  how_it_works: z.string().nullable(),
  business_value: z.string().nullable(),
  image_show_more_url: z.string().nullable(),
  modal_background_color: z.string().nullable(),
  is_used: z.boolean(),
  scoring_easy: z.number().nullable(),
  scoring_popular: z.number().nullable(),
  installations_count: z.number(),
})

export type QuickCampaign = z.infer<typeof QuickCampaignSchema>

export const QuickCampaignApiSchemas = {
  fetch: {
    path: z.undefined(),
    query: z.undefined(),
    response: z.object({
      list: z.array(QuickCampaignSchema),
    }),
  },
  get: {
    path: z.undefined(),
    query: z.undefined(),
    request: z.object({
      quick_campaign_id: z.number(),
    }),
    response: z.object({
      data: QuickCampaignSchema,
    }),
  },
  put: {
    path: z.undefined(),
    query: z.undefined(),
    request: z.object({
      quick_campaign_id: z.number(),
      data: z.object({}),
      flow_data: z.object({
        flow_ns: z.string(),
        account_id: z.string(),
        growth_tools_reserved: z.string(),
      }),
    }),
    response: z.object({}),
  },
  getFlow: {
    path: z.undefined(),
    query: z.object({
      quick_campaign_id: z.string(),
    }),
    response: z.object({
      flow: FlowDataForSimulatorSchema,
    }),
  },
}

export type FlowDataForSimulator = z.infer<typeof FlowDataForSimulatorSchema>

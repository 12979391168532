import EventEmitter from 'eventemitter3'

import { isMobile } from 'utils'
import { IOnboardingRunContext } from 'utils/services/newOnboardingService/interfaces'
import {
  ManyChatOnboarding,
  ManyChatOnboardingIdTypeMap,
} from 'utils/services/newOnboardingService/ManyChatOnboardingIdType'
import OnboardingId, { allOnboardingsIds } from 'utils/services/newOnboardingService/OnboardingId'

type OnboardingsCreatorsMapType = {
  [Key in OnboardingId]: () => ManyChatOnboardingIdTypeMap[Key]
}

type OnboardingsInstancesMapType = {
  [Key in OnboardingId]: ManyChatOnboardingIdTypeMap[Key] | undefined
}

export class NewOnboardingService {
  private events
  private onboardingsCreatorsMap: OnboardingsCreatorsMapType | undefined
  private onboardingsMap: OnboardingsInstancesMapType

  private currentOnboardingId: OnboardingId | undefined

  constructor() {
    this.events = new EventEmitter()

    this.onboardingsMap = this.getDefaultOnboardingsMap()
  }

  private getDefaultOnboardingsMap = () => {
    return allOnboardingsIds.reduce(
      (acc, id) => ({ ...acc, [id]: undefined }),
      {} as OnboardingsInstancesMapType,
    )
  }

  private createOnboarding = <T extends OnboardingId>(id: T) => {
    if (!this.onboardingsCreatorsMap) {
      throw new Error('onboardingsCreatorsMap is not defined, use .init() method before')
    }

    const onboardingCreator = this.onboardingsCreatorsMap[id]
    const onb = onboardingCreator()

    return onb
  }
  private handleOnboardingLifeCycle = (onb: ManyChatOnboarding) => {
    onb.addListener('RUN', this.handleOnboardingRun)
    onb.addListener('STEP_CHANGE', this.handleOnboardingStepChange)
    onb.addListener('COMPLETE', this.handleOnboardingComplete)
    onb.addListener('SKIP', this.handleOnboardingSkip)
  }
  private clearOnboardingLifeCycle = (onb: ManyChatOnboarding) => {
    onb.removeListener('RUN', this.handleOnboardingRun)
    onb.removeListener('STEP_CHANGE', this.handleOnboardingStepChange)
    onb.removeListener('COMPLETE', this.handleOnboardingComplete)
    onb.removeListener('SKIP', this.handleOnboardingSkip)
  }
  private clear = (onb: ManyChatOnboarding) => {
    this.onboardingsMap[onb.id] = undefined
    this.currentOnboardingId = undefined
    this.clearOnboardingLifeCycle(onb)
  }
  private handleOnboardingRun = (onb: ManyChatOnboarding) => {
    this.events.emit('RUN', onb)
  }
  private handleOnboardingStepChange = (onb: ManyChatOnboarding, stepId: string, step: unknown) => {
    this.events.emit('STEP_CHANGE', onb, stepId, step)
  }
  private handleOnboardingComplete = (onb: ManyChatOnboarding) => {
    this.clear(onb)

    this.events.emit('COMPLETE', onb)
  }
  private handleOnboardingSkip = (onb: ManyChatOnboarding) => {
    this.clear(onb)

    this.events.emit('SKIP', onb)
  }

  init(onboardingsCreatorsMap: OnboardingsCreatorsMapType) {
    this.onboardingsCreatorsMap = onboardingsCreatorsMap
  }

  run<T extends OnboardingId>(id: T, runContext?: IOnboardingRunContext) {
    if (this.currentOnboardingId) {
      throw new Error('Can not start onboarding, while another onboarding is already running')
    }

    if (isMobile) return

    const onb = this.createOnboarding(id)
    this.onboardingsMap[id] = onb
    this.handleOnboardingLifeCycle(onb)

    this.currentOnboardingId = id

    onb.run(runContext)
  }

  getOnboarding<T extends OnboardingId>(id: T) {
    return this.onboardingsMap[id]
  }

  getCurrentOnboardingId() {
    return this.currentOnboardingId
  }

  isAnyOnboardingRunning = () => {
    return Boolean(this.getCurrentOnboardingId())
  }

  onLifeCycle = (listeners: {
    onRun?: (onb: ManyChatOnboarding) => void
    onComplete?: (onb: ManyChatOnboarding) => void
    onSkip?: (onb: ManyChatOnboarding) => void
    onStepChange?: (onb: ManyChatOnboarding, stepId: string, step: unknown) => void
  }) => {
    const { onRun, onComplete, onSkip, onStepChange } = listeners

    if (onRun) {
      this.events.addListener('RUN', onRun)
    }
    if (onComplete) {
      this.events.addListener('COMPLETE', onComplete)
    }
    if (onSkip) {
      this.events.addListener('SKIP', onSkip)
    }
    if (onStepChange) {
      this.events.addListener('STEP_CHANGE', onStepChange)
    }

    return () => {
      if (onRun) {
        this.events.removeListener('RUN', onRun)
      }
      if (onComplete) {
        this.events.removeListener('COMPLETE', onComplete)
      }
      if (onSkip) {
        this.events.removeListener('SKIP', onSkip)
      }
      if (onStepChange) {
        this.events.removeListener('STEP_CHANGE', onStepChange)
      }
    }
  }
}

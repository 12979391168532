import { createSlice } from '@reduxjs/toolkit'
import { createAppAsyncThunk } from 'reduxTyped'

import { IStrategy } from 'apps/quickCampaigns/quickCampaignsInterfaces'
import { handleCatch } from 'shared/api/lib/errors/handlers'
import { QuickCampaignApi } from 'shared/api/requests/quickCampaign'

import { quickCampaignAdapter } from '../models/adapter'

export enum QuickCampaignsStateStatus {
  IDLE = 'idle',
  LOADING = 'loading',
  SUCCESS = 'success',
  ERROR = 'error',
}

export interface QuickCampaignsState {
  items: IStrategy[]
  status: QuickCampaignsStateStatus
}

export const InitialState: QuickCampaignsState = {
  items: [],
  status: QuickCampaignsStateStatus.IDLE,
}

export const fetchQuickCampaigns = createAppAsyncThunk(
  'quickCampaigns/fetch',
  async (_, { getState }) => {
    try {
      const state = getState()

      if (state.quickCampaigns.status !== QuickCampaignsStateStatus.LOADING) {
        return state.quickCampaigns.items
      }

      const { data } = await QuickCampaignApi.fetch()
      return data.list.map(quickCampaignAdapter)
    } catch (error) {
      handleCatch(error)

      return null
    }
  },
)

const QuickCampaignsSlice = createSlice({
  name: 'quickCampaigns',
  initialState: InitialState,
  reducers: {
    resetState: () => InitialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchQuickCampaigns.pending, (state) => {
        if (state.status !== QuickCampaignsStateStatus.IDLE) {
          return
        }

        state.status = QuickCampaignsStateStatus.LOADING
      })
      .addCase(fetchQuickCampaigns.fulfilled, (state, action) => {
        state.status = QuickCampaignsStateStatus.SUCCESS
        state.items = action.payload ?? []
      })
      .addCase(fetchQuickCampaigns.rejected, (state) => {
        state.status = QuickCampaignsStateStatus.ERROR
      })
  },
})

export const QuickCampaignsReducer = QuickCampaignsSlice.reducer

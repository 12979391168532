import React from 'react'
import cx from 'classnames'
import { BtnV2, Icon, l, tints } from '@manychat/manyui'

import cm from 'common/banners/mobile/MobileBanner.module.css'
import { isAndroid, isApple } from 'utils/ismobile'
import { analyticsService } from 'utils/services/analytics'

import bannerImage from './img/banner_image.png'

const ANDROID_APP_LINK = 'https://play.google.com/store/apps/details?id=com.manychat'
const APPLE_APP_LINK = 'https://apps.apple.com/am/app/manychat/id1460129210'

interface MainScreenProps {
  canClose: boolean
  onClose: () => void
}

export const MainScreen = ({ canClose, onClose }: MainScreenProps) => {
  const handleClickOpenAppButton = () => {
    analyticsService.sendEvent('MOBILE_BANNER_DOWNLOAD_APP_CLICK', {
      isAndroid,
      isApple,
      novemberExperiment: true,
    })

    let link = ''
    if (isAndroid) link = ANDROID_APP_LINK
    if (isApple) link = APPLE_APP_LINK

    window.open(link, '_blank')
  }

  return (
    <>
      {canClose && (
        <Icon.Close size={24} className={cm.closeIcon} onClick={onClose} color={tints.neutral[3]} />
      )}
      <img width={296} src={bannerImage} className={cx(cm.bannerImage)} alt="Manychat mobile app" />
      <h1 className="text-center m-t-0">
        {l.translate('Get better experience with Manychat App')}
      </h1>
      <p className="text-center m-b-xl">
        {l.translate(
          'At the moment, the web version is not adapted for comfortable work on a mobile device. Please use the mobile app. ',
        )}
      </p>
      <BtnV2 variant="primary" fullWidth onClick={handleClickOpenAppButton}>
        {l.translate('Open Manychat App')}
      </BtnV2>
    </>
  )
}

export const EXTERNALTRIGGERS_CURRENT_SAVE = 'EXTERNALTRIGGERS_CURRENT_SAVE'
export const EXTERNALTRIGGERS_CHANGE_STATUS = 'EXTERNALTRIGGERS_CHANGE_STATUS'
export const EXTERNALTRIGGERS_CHANGE_STATUS_REQUEST = 'EXTERNALTRIGGERS_CHANGE_STATUS_REQUEST'
export const EXTERNALTRIGGERS_CHANGE_STATUS_RESPONSE = 'EXTERNALTRIGGERS_CHANGE_STATUS_RESPONSE'
export const EXTERNALTRIGGERS_CURRENT_CREATE_RESPONSE = 'EXTERNALTRIGGERS_CURRENT_CREATE_RESPONSE'
export const EXTERNALTRIGGERS_CURRENT_SAVE_RESPONSE = 'EXTERNALTRIGGERS_CURRENT_SAVE_RESPONSE'
export const EXTERNALTRIGGERS_CHANGE_STATUS_ERROR = 'EXTERNALTRIGGERS_CHANGE_STATUS_ERROR'
export const EXTERNALTRIGGERS_DELETE = 'EXTERNALTRIGGERS_DELETE'
export const EXTERNALTRIGGERS_DELETE_RESPONSE = 'EXTERNALTRIGGERS_DELETE_RESPONSE'
export const EXTERNALTRIGGERS_DELETED_NOTIFICATION = 'EXTERNALTRIGGERS_DELETED_NOTIFICATION'
export const EXTERNALTRIGGERS_CREATED_NOTIFICATION = 'EXTERNALTRIGGERS_CREATED_NOTIFICATION'
export const EXTERNALTRIGGERS_CURRENT_UPDATED_NOTIFICATION =
  'EXTERNALTRIGGERS_CURRENT_UPDATED_NOTIFICATION'

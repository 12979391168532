import React from 'react'

import cm from './DragIndicator.module.css'

export function DragIndicator() {
  return (
    <div className={cm.container}>
      <div className={cm.dragIndicator} />
    </div>
  )
}

import { getNestedDependencies } from './utils'

export default function (map) {
  const filterSections = ['template_installations']
  map.items = Object.keys(map.models)
    .filter((mId) => !filterSections.includes(mId))
    .reduce((res, mId) => {
      const m = map.models[mId]
      return res.concat(m.map((item) => ({ ...item, model_id: mId })))
    }, [])

  map.byId = map.items.reduce((res, item) => ((res[item.id] = item), res), {})

  map.corruptedItems = map.items.filter((i) => i.is_corrupted)
  map.hasCorrupted = map.corruptedItems.length > 0

  const { dependencies } = map
  map.groupedDependencies = dependencies

  const sequencesDependencies = map.models.sequences.reduce(
    (res, s) => ((res[s.id] = s.messages), res),
    {},
  )

  const dependenciesMap = {
    ...dependencies.sequence_messages,
    ...dependencies.growth_tools,
    ...dependencies.keywords,
    ...dependencies.intents,
    ...dependencies.flows,
    ...dependencies.generals,
    ...dependencies.triggers,
    ...dependencies.external_triggers,
    ...sequencesDependencies,
  }

  map.directDependencies = dependenciesMap
  map.dependencies = {}

  for (let item of map.items) {
    // Additional filter is used to exclude current entity from it's on dependecy list.
    // It fixes cases when you can't uncheck entities that are part of dependency loop.
    map.dependencies[item.id] = getNestedDependencies(dependenciesMap, item.id)
  }

  map.byPath = map.models.flows.reduce((res, cur) => {
    const { folder_path, id } = cur
    res[folder_path] ? res[folder_path].push(cur.id) : (res[folder_path] = [id])

    return res
  }, {})

  return map
}

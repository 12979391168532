import React, { MouseEvent, useCallback } from 'react'
import cx from 'classnames'
import { useAppSelector } from 'reduxTyped'
import { l } from '@manychat/manyui'

import { billing, UpgradeSource } from 'common/billing'
import { getAsyncBillingActions } from 'common/core/selectors/appSelectors'
import { useDidMountEffect } from 'utils/commonHooks'
import { analyticsService } from 'utils/services/analytics'

import cm from '../StaticNotifications.module.css'

export const VerifySubscriptionPaymentNotification = () => {
  const asyncBillingAction = useAppSelector(getAsyncBillingActions)

  useDidMountEffect(() => {
    analyticsService.sendEvent('NOTIFICATION_BAR.VERIFY_SUBSCRIPTION_PAYMENT.SHOWN')
  })

  const handleLinkClick = useCallback((event: MouseEvent<HTMLAnchorElement>) => {
    event.preventDefault()
    analyticsService.sendEvent('NOTIFICATION_BAR.VERIFY_SUBSCRIPTION_PAYMENT.CLICKED')

    billing.requestUpgrade({
      source: UpgradeSource.NOTIFICATION_BAR_VERIFY_PAYMENT,
    })
  }, [])

  if (!asyncBillingAction) return null

  return (
    <>
      <span>
        {l.translate(
          'You need to approve the payment from your card to continue using PRO subscription',
        )}
      </span>
      <a
        className={cx(cm.more, 'm-l-xs')}
        onClick={handleLinkClick}
        target="_blank"
        rel="noreferrer"
      >
        {l.translate('Verify Payment')}
      </a>
    </>
  )
}

import React from 'react'

import { PermissionTargets } from 'common/userRoles/selectors/userRolesSelectors'

import { usePermission } from '../userRolesHooks'

const IfHasPermission = ({
  children,
  target,
}: {
  children: JSX.Element | null
  target: PermissionTargets
}) => {
  const isAllowed = usePermission(target)
  return isAllowed ? <>{children}</> : null
}

export default IfHasPermission

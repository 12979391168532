import React from 'react'
import { useAppSelector } from 'reduxTyped'
import { l } from '@manychat/manyui'

import { ChannelType } from 'common/core/constants/ChannelType'
import { getPageNameByChannel } from 'common/core/selectors/channelSelectors'

import { TiktokNotificationType } from '../NotifyInterfaces'

type TiktokReloadPermissionsErrorType =
  | TiktokNotificationType.TIKTOK_REFRESH_PERMISSIONS_FAIL_ACCOUNT_IS_NOT_BUSINESS
  | TiktokNotificationType.TIKTOK_INSUFFICIENT_RIGHTS_TO_RELOAD_ACCOUNT
  | TiktokNotificationType.TIKTOK_REFRESH_PERMISSIONS_FAIL_CONVERSATION_IS_UNAVAILABLE
  | TiktokNotificationType.TIKTOK_REFRESH_PERMISSIONS_FAIL_LACK_OF_PERMISSIONS
  | TiktokNotificationType.TIKTOK_WRONG_ACCOUNT_TO_RELOAD_PERMISSIONS
  | TiktokNotificationType.TIKTOK_REFRESH_PERMISSIONS_FAIL_USER_REGION_NOT_ALLOWED
  | TiktokNotificationType.TIKTOK_REFRESH_PERMISSIONS_FAIL_ACCOUNT_REGION_NOT_ALLOWED

const TiktokReloadPermissionsErrorMessages = {
  [TiktokNotificationType.TIKTOK_REFRESH_PERMISSIONS_FAIL_ACCOUNT_REGION_NOT_ALLOWED]: () =>
    l.makeTranslate('TikTok automations are disabled for you due to regional limitations'),
  [TiktokNotificationType.TIKTOK_REFRESH_PERMISSIONS_FAIL_USER_REGION_NOT_ALLOWED]: () =>
    l.makeTranslate(
      'You cannot refresh TikTok channel permissions. Your region is not supported yet',
    ),
  [TiktokNotificationType.TIKTOK_REFRESH_PERMISSIONS_FAIL_ACCOUNT_IS_NOT_BUSINESS]: () =>
    l.makeTranslate('Your TikTok account needs to be a business account'),
  [TiktokNotificationType.TIKTOK_INSUFFICIENT_RIGHTS_TO_RELOAD_ACCOUNT]: () =>
    l.makeTranslate('Insufficient rights to refresh permissions'),
  [TiktokNotificationType.TIKTOK_REFRESH_PERMISSIONS_FAIL_CONVERSATION_IS_UNAVAILABLE]: () =>
    l.makeTranslate('This TikTok account doesn’t support connection to Manychat'),
  [TiktokNotificationType.TIKTOK_REFRESH_PERMISSIONS_FAIL_LACK_OF_PERMISSIONS]: () =>
    l.makeTranslate('Please allow all required permissions'),
  [TiktokNotificationType.TIKTOK_WRONG_ACCOUNT_TO_RELOAD_PERMISSIONS]: (userName: string) =>
    l.makeTranslate('Wrong account selected. Use account {userName} to refresh permissions', {
      userName,
    }),
}

export const TiktokWrongPageReloadPermissions = ({
  messageCode,
}: {
  messageCode: TiktokReloadPermissionsErrorType
}) => {
  const userName = useAppSelector((state) => getPageNameByChannel(state, ChannelType.TIKTOK))
  const message = TiktokReloadPermissionsErrorMessages[messageCode](userName)

  return <span>{l.getString(message)}</span>
}

import React from 'react'
import ReactDOM from 'react-dom'
import cx from 'classnames'
import PropTypes from 'prop-types'

import { DOM, getWindow, noop } from '../../../../utils'
import { parsePosition } from '../../../../utils/geometry'
import EventListener from '../../../../utils/listener'
import RenderToLayer from '../../RenderToLayer'
import { AXIS_STYLE_PROP, AutoPositionTypes, AutoPositionType } from '../constants'

import { getPopoverPosition } from './positioning'

import cm from './PopoverBase.module.css'

const window = getWindow()

const getThresholds = (element) => {
  const width = window.innerWidth
  const height = window.innerHeight
  return { x: [0, width], y: [0, height] }
}

const getAnchorElement = (anchor) => {
  if (anchor == null) {
    return null
  }
  // Repeat of undocumented 'ReactDOM.findDOMNode' logic
  if (anchor.nodeType === DOM.HTMLNodeType.ELEMENT_NODE) {
    return anchor
  }

  try {
    return ReactDOM.findDOMNode(anchor) // eslint-disable-line
  } catch (err) {
    return null
  }
}

const parseAutoPosition = (value) => {
  if (value === false) {
    return { x: AutoPositionType.NONE, y: AutoPositionType.NONE }
  }
  if (!value || typeof value !== 'string') {
    return { x: AutoPositionType.AUTO, y: AutoPositionType.AUTO }
  }
  const values = value
    .trim()
    .split(' ')
    .map((v) => v.trim())
  const x = AutoPositionTypes.find((t) => t === values[0]) || AutoPositionType.AUTO
  const y = AutoPositionTypes.find((t) => t === values[1]) || x
  return { x, y }
}

export default class PopoverBase extends React.PureComponent {
  static propTypes = {
    anchor: PropTypes.any,
    anchorOrigin: PropTypes.string,
    autoPosition: PropTypes.any,
    children: PropTypes.any,
    className: PropTypes.string,
    fitTargetWidth: PropTypes.bool,
    /**
     * Custom handler for click away. Return true if you want to avoid default handler
     * (event: React.MouseEvent<HTMLElement>) => boolean
     */
    onClickAway: PropTypes.func,
    /** TODO */
    fullscreen: PropTypes.bool,
    onAxisAutoPositon: PropTypes.func,
    onRequestClose: PropTypes.func,
    open: PropTypes.bool,
    popoverOrigin: PropTypes.string,
    popoverRef: PropTypes.func,
    useLayerForClickAway: PropTypes.bool,
    style: PropTypes.object,
    layerStyle: PropTypes.object,
  }

  static defaultProps = {
    anchorOrigin: 'bottom center',
    onAxisAutoPositon: noop,
    onRequestClose: noop,
    open: false,
    popoverOrigin: 'top center',
    popoverRef: noop,
    onClickAway: null,
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    const { anchor: propsAnchor, anchorOrigin: ao, autoPosition: ap, popoverOrigin: po } = nextProps
    const anchor = getAnchorElement(propsAnchor)

    return {
      anchor,
      anchorScrollableParents: [window, ...DOM.getScrollableParents(anchor)],
      anchorOrigin: parsePosition(ao),
      autoPosition: parseAutoPosition(ap),
      popoverOrigin: parsePosition(po),
    }
  }

  constructor(props) {
    super(props)

    this.contentObserver = new ResizeObserver(this.handleResize)
    this.currentContentEl = null
  }

  state = {}

  componentDidUpdate(prevProps, prevState, snapshot) {
    this.setPosition()
  }

  componentWillUnmount() {
    this.contentObserver.disconnect()
  }

  applyCoordinate = (axis, data) => {
    const cacheProp = `${axis}Cache`
    const styleProp = AXIS_STYLE_PROP[axis]
    const cache = this[cacheProp] || {}

    this[cacheProp] = data
    this.root.style[styleProp] = `${data.value}px`

    if (cache.position !== data.position) {
      this.props.onAxisAutoPositon(axis, data)
    }
  }

  setPosition = () => {
    const { root, thresholds } = this
    const { anchor, anchorOrigin, autoPosition, popoverOrigin } = this.state
    const { open, fitTargetWidth, fullscreen } = this.props

    // Positioning closed popover with offscreen anchor leads to constant updates
    // because of 'onRequestClose('offScreen')' calls
    // Root can be null when calling setPosition from cDU while opening popover
    if (!open || !anchor || !root) {
      return
    }

    if (fullscreen) {
      this.root.style.inset = `0px`
      return
    }

    const anchorRect = anchor.getBoundingClientRect()
    const popoverRect = root.getBoundingClientRect()

    if (this.isAnchorOffscreen(anchorRect, thresholds)) {
      return this.props.onRequestClose('offScreen')
    }

    const options = { autoPosition, thresholds }

    let { x, y } = getPopoverPosition(anchorOrigin, anchorRect, popoverOrigin, popoverRect, options)

    this.applyCoordinate('x', x)
    this.applyCoordinate('y', y)

    if (fitTargetWidth) {
      this.root.style.width = `${anchor.offsetWidth}px`
    }
  }

  isAnchorOffscreen(anchorRect, thresholds) {
    if (this.props.fullscreen) return false

    return (
      anchorRect.right < thresholds.x[0] ||
      anchorRect.left > thresholds.x[1] ||
      anchorRect.bottom < thresholds.y[0] ||
      anchorRect.top > thresholds.y[1]
    )
  }

  handleScroll = (e) => this.setPosition()
  handleResize = (e) => this.setPosition()
  handleTransitionEnd = (e) => this.setPosition()

  handleLayerRef = (el) => (this.layer = el)

  handleRootRef = (el) => {
    if (el) {
      this.root = el
      this.thresholds = getThresholds(el)
      this.setPosition()
      this.props.popoverRef(el)
    }

    if (this.currentContentEl === el) {
      return
    }

    this.currentContentEl = el

    if (this.currentContentEl) {
      this.contentObserver.observe(this.currentContentEl)
    } else {
      this.contentObserver.disconnect()
    }
  }

  handleClickAway = (event, mousedownEvent) => {
    if (this.props.fullscreen) return

    if (this.props.onClickAway && this.props.onClickAway(event)) {
      return
    }

    const { anchor } = this.state
    const { onRequestClose } = this.props
    const isClickInElement =
      DOM.isClickInElement(event, anchor) || DOM.isClickInElement(mousedownEvent, anchor)

    let reason = 'clickAway'

    if (event?.target?.closest('.withEmoji') !== null) {
      reason = 'clickEmoji'
    }

    if (anchor && isClickInElement) {
      reason = 'clickAnchor'
    }

    onRequestClose(reason, event)
  }

  handleEscapeKeyDown = (e) => this.props.onRequestClose('esc', e)

  render() {
    const { anchorScrollableParents } = this.state
    const {
      anchor, // eslint-disable-line no-unused-vars
      anchorOrigin, // eslint-disable-line no-unused-vars
      autoPosition, // eslint-disable-line no-unused-vars
      children,
      className,
      fitTargetWidth, // eslint-disable-line no-unused-vars
      fullscreen, // eslint-disable-line no-unused-vars
      onAxisAutoPositon, // eslint-disable-line no-unused-vars
      onRequestClose, // eslint-disable-line no-unused-vars
      open,
      popoverOrigin, // eslint-disable-line no-unused-vars
      popoverRef, // eslint-disable-line no-unused-vars
      style,
      layerStyle,
      ...rest
    } = this.props

    return (
      <span style={{ display: 'none' }}>
        {open &&
          anchorScrollableParents.map((el, index) => {
            return (
              <EventListener
                key={index}
                target={el}
                onScroll={this.handleScroll}
                onResize={this.handleResize}
              />
            )
          })}
        <EventListener target="document" onTransitionEnd={this.handleTransitionEnd} />
        <RenderToLayer
          {...rest}
          style={layerStyle}
          ref={this.handleLayerRef}
          open={open}
          onEscapeKeyDown={this.handleEscapeKeyDown}
          onClickAway={this.handleClickAway}
        >
          <div
            ref={this.handleRootRef}
            className={cx(cm.root, className)}
            data-test-id="popover-root"
            style={style}
          >
            {children}
          </div>
        </RenderToLayer>
      </span>
    )
  }
}

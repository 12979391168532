import { z } from 'zod'

const SystemUserFieldStatsSchema = z.object({
  data: z.object({
    id: z.string(),
  }),
  stats: z.object({
    saved: z.number(),
  }),
})

const CustomUserFieldStatsSchema = z.object({
  data: z.object({
    id: z.number(),
  }),
  stats: z.object({
    saved: z.number(),
  }),
})

const ButtonsStatsSchema = z.object({
  data: z.object({
    url: z.string(),
  }),
  stats: z.object({
    shown: z.number(),
    clicked: z.number(),
  }),
})

const AiStepNodeStatsSchema = z
  .object({
    buttons: z.array(ButtonsStatsSchema).optional(),
    sufs: z.array(SystemUserFieldStatsSchema).optional(),
    cufs: z.array(CustomUserFieldStatsSchema).optional(),
  })
  .optional()

export type AiStepNodeStats = z.infer<typeof AiStepNodeStatsSchema>
export type ButtonsStats = z.infer<typeof ButtonsStatsSchema>
export type SystemUserFieldStats = z.infer<typeof SystemUserFieldStatsSchema>
export type CustomUserFieldStats = z.infer<typeof CustomUserFieldStatsSchema>

export const AiStepStatsSchemas = {
  get: {
    query: z.object({
      content_id: z.number(),
      content_holder: z.string().optional(),
    }),
    response: AiStepNodeStatsSchema,
    request: z.undefined(),
    path: z.undefined(),
  },
}

import { api } from 'shared/api'
import { settingsApiSchemas } from 'shared/api/requests/settings/schemas'
import { _smsSettingsApiSchemas } from 'shared/api/requests/settings/sms/schemas'
import { WhatsAppSettingsApiSchemas } from 'shared/api/requests/settings/whatsApp/schemas'

import { experimentSchemas } from './experiment'
import { statsSchemas } from './stats/schemas'

export const settingsApi = {
  fetchSettings: api.account.createGet({
    url: '/settings/data',
    schemas: settingsApiSchemas.fetchSettings,
  }),
  changeAutomationPauseInitTime: api.account.createPost({
    url: '/settings/changeAutomationPauseSeconds',
    schemas: settingsApiSchemas.changeAutomationPauseInitTime,
  }),
  sms: {
    connectSmsByTwilio: api.account.createPost({
      url: '/settings/connectSmsByTwilio',
      schemas: _smsSettingsApiSchemas.connectSmsByTwilio,
    }),
    disconnectSmsByTwilio: api.account.createPost({
      url: '/settings/disconnectSmsByTwilio',
      schemas: _smsSettingsApiSchemas.disconnectSmsByTwilio,
    }),
    enableChannel: api.account.createPost({
      url: '/settings/connectSmsChannel',
      schemas: _smsSettingsApiSchemas.enableChannel,
    }),
    disableChannel: api.account.createPost({
      url: '/settings/disconnectSmsChannel',
      schemas: _smsSettingsApiSchemas.disableChannel,
    }),
  },
  stats: {
    fetchSmsBillingStats: api.account.createGet({
      url: '/settings/getSmsBillingStats',
      schemas: statsSchemas.fetchSmsBillingStats,
    }),
    fetchEmailBillingStats: api.account.createGet({
      url: '/settings/getEmailBillingStats',
      schemas: statsSchemas.fetchEmailBillingStats,
    }),
  },
  setDisplayPreferences: api.account.createPost({
    url: '/settings/setDisplayPreference',
    schemas: settingsApiSchemas.setDisplayPreferences,
  }),
  toggleInstagramChannel: api.account.createPost({
    url: '/settings/toggleInstagramChannel',
    schemas: settingsApiSchemas.toggleInstagramChannel,
  }),
  transferOwnership: api.account.createPost({
    url: '/settings/transferOwnership',
    schemas: settingsApiSchemas.transferOwnership,
  }),
  whatsApp: {
    setWhatsAppProfilePhoto: api.account.createPost({
      url: '/settings/setWhatsAppProfilePhoto',
      schemas: WhatsAppSettingsApiSchemas.setWhatsAppProfilePhoto,
    }),
    setWhatsAppBusinessProfile: api.account.createPost({
      url: '/settings/setWhatsAppBusinessProfile',
      schemas: WhatsAppSettingsApiSchemas.setWhatsAppBusinessProfile,
    }),
  },
  growthTools: {
    getWhitelistingDomains: api.account.createGet({
      url: '/whitelist/getDomains',
      schemas: settingsApiSchemas.getWhitelistingDomains,
    }),
    addWhitelistingDomain: api.account.createPost({
      url: '/whitelist/addDomain',
      schemas: settingsApiSchemas.addWhitelistingDomain,
    }),
    deleteWhitelistingDomain: api.account.createPost({
      url: '/whitelist/deleteDomain',
      schemas: settingsApiSchemas.deleteWhitelistingDomain,
    }),
  },
  leaveAccount: api.account.createPost({
    url: '/settings/doLeaveAccount',
    schemas: settingsApiSchemas.leaveAccount,
  }),

  conversionsApi: {
    setConversionsApiCreds: api.account.createPost({
      url: '/conversionsApi/setConversionsApiCreds',
      schemas: settingsApiSchemas.setConversionsApiCreds,
    }),
    disconnectConversionsApiCreds: api.account.createPost({
      url: '/conversionsApi/disconnectConversionsApiCreds',
      schemas: settingsApiSchemas.disconnectConversionsApiCreds,
    }),
    setAutosendEventsToCapi: api.account.createPost({
      url: '/conversionsApi/setAutosendEventsToCapi',
      schemas: settingsApiSchemas.setAutosendEventsToCapi,
    }),
    getCapiEventActionValues: api.account.createGet({
      url: '/conversionsApi/getCapiEventActionValues',
      schemas: settingsApiSchemas.getCapiEventActionValues,
    }),
  },
  threadControl: {
    getThreadRoutingData: api.account.createGet({
      url: '/settings/getThreadRoutingData',
      schemas: settingsApiSchemas.getThreadRoutingData,
    }),
  },

  tiktok: {
    toggleTikTokChannel: api.account.createPost({
      url: '/settings/toggleTikTokChannel',
      schemas: settingsApiSchemas.toggleTikTokChannel,
    }),
  },

  experiment: {
    enroll: api.account.createPost({
      url: '/settings/{experimentGroupName}',
      schemas: experimentSchemas.enrollExperiment,
    }),
  },

  payments: {
    connectStripe: api.account.createPost({
      url: '/settings/connectStripe',
      schemas: settingsApiSchemas.connectStripe,
    }),

    disconnectStripe: api.account.createPost({
      url: '/settings/disconnectStripe',
      schemas: settingsApiSchemas.disconnectStripe,
    }),

    setCurrency: api.account.createPost({
      url: '/settings/setCurrency',
      schemas: settingsApiSchemas.setCurrency,
    }),

    setPaymentNotifySettings: api.account.createPost({
      url: '/settings/setPaymentNotifySettings',
      schemas: settingsApiSchemas.setPaymentNotifySettings,
    }),

    setStripeChargeEmailNotification: api.account.createPost({
      url: '/settings/setStripeChargeEmailNotification',
      schemas: settingsApiSchemas.setStripeChargeEmailNotification,
    }),

    connectPaypal: api.account.createPost({
      url: '/settings/connectPaypal',
      schemas: settingsApiSchemas.connectPaypal,
    }),

    disconnectPaypal: api.account.createPost({
      url: '/settings/disconnectPaypal',
      schemas: settingsApiSchemas.disconnectPaypal,
    }),
  },

  handover: {
    getHandoverReceivers: api.account.createPost({
      url: '/settings/getHandoverReceivers',
      schemas: settingsApiSchemas.getHandoverReceivers,
    }),
    setLiveChatReceiver: api.account.createPost({
      url: '/settings/setLiveChatReceiver',
      schemas: settingsApiSchemas.setLiveChatReceiver,
    }),
  },

  email: {
    switchEmailChannel: api.account.createPost({
      url: 'settings/switchEmailChannel',
      schemas: settingsApiSchemas.switchEmailChannel,
    }),
    setEmailSettings: api.account.createPost({
      url: 'settings/setEmailSettings',
      schemas: settingsApiSchemas.setEmailSettings,
    }),
    showEmailBranding: api.account.createPost({
      url: 'settings/showEmailBranding',
      schemas: settingsApiSchemas.showEmailBranding,
    }),
  },

  publicApi: {
    getPublicAPIToken: api.account.createPost({
      url: '/api/token/generate',
      schemas: settingsApiSchemas.getPublicAPIToken,
    }),
    deletePublicAPIToken: api.account.createPost({
      url: '/api/token/delete',
      schemas: settingsApiSchemas.deletePublicAPIToken,
    }),
  },
}

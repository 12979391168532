import { l, Icon } from '@manychat/manyui'

import { FsFilterGroup } from 'shared/api/requests/cms/schemas'

export const FilterGroupTitle = {
  [FsFilterGroup.SMS]: l.makeTranslate('SMS Triggers'),
  [FsFilterGroup.CONTACT_EVENT]: l.makeTranslate('Contact Events'),
  [FsFilterGroup.FACEBOOK]: l.makeTranslate('Messenger Triggers'),
  [FsFilterGroup.INSTAGRAM]: l.makeTranslate('Instagram Triggers'),
  [FsFilterGroup.WEB_WIDGET]: l.makeTranslate('Website Widgets'),
  [FsFilterGroup.TELEGRAM]: l.makeTranslate('Telegram Triggers'),
  [FsFilterGroup.EXTERNAL_TRIGGER]: l.makeTranslate('External Triggers'),
  [FsFilterGroup.WHATSAPP]: l.makeTranslate('WhatsApp Triggers'),
  [FsFilterGroup.TIKTOK]: l.makeTranslate('TikTok Triggers'),
}

export const FilterIcon: Record<string, Icon.Icon> = {
  [FsFilterGroup.SMS]: Icon.SmsChannel,
  [FsFilterGroup.CONTACT_EVENT]: Icon.Bot,
  [FsFilterGroup.FACEBOOK]: Icon.MessengerChannel,
  [FsFilterGroup.INSTAGRAM]: Icon.InstagramChannel,
  [FsFilterGroup.WEB_WIDGET]: Icon.Nationality,
  [FsFilterGroup.TELEGRAM]: Icon.TelegramChannel,
  [FsFilterGroup.WHATSAPP]: Icon.WhatsAppChannel,
  [FsFilterGroup.EXTERNAL_TRIGGER]: Icon.OrphanIntegration,
  [FsFilterGroup.TIKTOK]: Icon.TikTokChannel,
}

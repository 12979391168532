import React, { useState } from 'react'
import { useAppDispatch, useAppSelector } from 'reduxTyped'

import { getFirstOptionsValue } from 'common/billing/wallet/walletUtils'
import { hasConnectedEmailOrWhatsAppChannel } from 'common/core/selectors/channelSelectors'
import { usePrevious } from 'utils/commonHooks'

import { fetchWallet, refillWallet, configureAutoRefill, toggleRefillModal } from './walletActions'
import {
  DEFAULT_DAILY_LIMIT,
  DEFAULT_PAYMENT_AMOUNT,
  DEFAULT_TRIGGER_BALANCE,
} from './walletConstants'
import { Wallet, AutoRefill, WalletNetwork, WalletUI, ModalTypes } from './walletInterfaces'
import { getDefaultAmount, getWalletRefillOptions, getWalletState } from './walletSelectors'

interface UseWallet {
  shouldFetch: boolean
}

export const useWallet = ({ shouldFetch }: Partial<UseWallet> = {}): {
  network: WalletNetwork
  wallet: Wallet
} => {
  const { wallet, network } = useAppSelector(getWalletState)
  const isConnected = useAppSelector(hasConnectedEmailOrWhatsAppChannel)

  const dispatch = useAppDispatch()
  const { hasWallet } = wallet

  const prevIsConnected = usePrevious(isConnected)
  const prevHasWallet = usePrevious(hasWallet)

  React.useEffect(() => {
    if (hasWallet && isConnected && (prevIsConnected === false || prevHasWallet === false)) {
      dispatch(fetchWallet())
    }
  }, [dispatch, isConnected, hasWallet, prevIsConnected, prevHasWallet])

  React.useEffect(() => {
    if (shouldFetch && hasWallet && isConnected) {
      dispatch(fetchWallet())
    }
  }, [dispatch, shouldFetch, isConnected, hasWallet])

  return {
    network,
    wallet,
  }
}

export const useAutoRefillDraft = (): {
  autoRefillDraft: AutoRefill
  setAutoRefillDraft: (initial: Partial<AutoRefill>) => void
  saveAutoRefillDraft: () => void
} => {
  const { wallet, ui } = useAppSelector(getWalletState)
  const options = useAppSelector(getWalletRefillOptions)
  const dispatch = useAppDispatch()

  const DefaultAutoRefillState = React.useMemo(
    () => ({
      isEnabled: true,
      triggerAmount: getFirstOptionsValue(
        options.triggerAutorefillAmountOptions,
        DEFAULT_TRIGGER_BALANCE,
      ),
      dailyLimit: getFirstOptionsValue(options.dailyLimitOptions, DEFAULT_DAILY_LIMIT),
      refillAmount: getFirstOptionsValue(options.amountOptions, DEFAULT_PAYMENT_AMOUNT),
    }),
    [options],
  )

  const initialDraft = React.useMemo(() => {
    return wallet.autoRefill.isEnabled ? wallet.autoRefill : DefaultAutoRefillState
  }, [wallet.autoRefill, DefaultAutoRefillState])

  const [draft, setDraft] = useState(initialDraft)

  React.useEffect(() => {
    if (ui.openModal !== null) {
      setDraft(initialDraft)
    }
  }, [ui.openModal, initialDraft])

  return {
    autoRefillDraft: draft,
    setAutoRefillDraft: (initial: Partial<AutoRefill>) => {
      setDraft({ ...draft, ...initial })
    },
    saveAutoRefillDraft: () => dispatch(configureAutoRefill(draft)),
  }
}

export const useBalanceDraft = (): {
  balanceDraft: number
  setBalanceDraft: (balance: number) => void
  saveBalanceDraft: () => void
} => {
  const { ui } = useAppSelector(getWalletState)
  const defaultAmount = useAppSelector(getDefaultAmount)
  const dispatch = useAppDispatch()

  const [balanceDraft, setBalanceDraft] = useState(defaultAmount ?? DEFAULT_PAYMENT_AMOUNT)

  React.useEffect(() => {
    if (ui.openModal !== null) {
      setBalanceDraft(defaultAmount ?? DEFAULT_PAYMENT_AMOUNT)
    }
  }, [defaultAmount, ui.openModal])

  return {
    balanceDraft,
    setBalanceDraft,
    saveBalanceDraft: () => dispatch(refillWallet(balanceDraft)),
  }
}

export const useWalletUI = (): [WalletUI, (type: ModalTypes) => void] => {
  const { ui } = useAppSelector(getWalletState)
  const dispatch = useAppDispatch()

  return [ui, (type: ModalTypes) => dispatch(toggleRefillModal(type))]
}

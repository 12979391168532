import React from 'react'
import { zodResolver } from '@hookform/resolvers/zod'
import { useForm } from 'react-hook-form'
import { z } from 'zod'
import { Modal, l, BtnV2, TextInputV2 } from '@manychat/manyui'

import { useCreateFolderModal } from 'apps/cms/components/Flows/components/AutomationModals/modals/CreateFolderModal/hooks/useCreateFolderModal'
import { FsValidationSchema } from 'apps/cms/lib/validation'

import { FormValues } from './lib/types'

const validationSchema = z.object({
  name: FsValidationSchema.folderName,
})

export const CreateFolderModal = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<FormValues>({
    defaultValues: {
      name: '',
    },
    resolver: zodResolver(validationSchema),
  })

  const { ref, ...field } = register('name')

  const { closeModal, createFolder, isLoading } = useCreateFolderModal()

  return (
    <Modal
      open
      onRequestClose={closeModal}
      title={<div className="p-y-xs">{l.translate('Create Folder')}</div>}
      width={460}
      buttons={[
        <BtnV2 onClick={closeModal} className="m-y-xs">
          {l.translate('Cancel')}
        </BtnV2>,
        <BtnV2
          loading={isLoading}
          className="m-y-xs"
          variant="primary"
          type="submit"
          form="cms-create-folder"
        >
          {l.translate('Create')}
        </BtnV2>,
      ]}
    >
      <div className="p-x p-y-md">
        <form onSubmit={handleSubmit(createFolder)} id="cms-create-folder">
          <TextInputV2
            {...field}
            inputRef={ref}
            label={l.translate('Folder name')}
            autoFocus
            autoComplete="off"
            placeholder={l.translate('Enter folder name')}
            errorText={errors.name?.message}
            invalid={Boolean(errors.name?.message)}
          />
        </form>
      </div>
    </Modal>
  )
}

import React, { Fragment } from 'react'
import cx from 'classnames'
import memoize from 'memoize-one'
import PropTypes from 'prop-types'
import * as Icon from '@manychat/icons'

import { noop } from '../../../../utils'
import {
  parseDate,
  formatDate,
  isDate,
  isValidDate,
  isSameDay,
  isBefore,
  isAfter,
  isSundayFirst,
} from '../../../../utils/date'
import * as l from '../../../../utils/localization/format'
import Scrollbars from '../../../Scrollbars'

import { getDaysGrid, getWeekStartDate } from './daysGrid'

import cm from './Calendar.module.css'

const DisplayType = {
  DEFAULT: 'DEFAULT',
  SELECT_MONTH: 'SELECT_MONTH',
  SELECT_YEAR: 'SELECT_YEAR',
}

export default class Calendar extends React.Component {
  static propTypes = {
    name: PropTypes.string,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
    onChange: PropTypes.func,
    hideHeader: PropTypes.bool,
    viewDate: PropTypes.string,
    minDate: PropTypes.string,
    maxDate: PropTypes.string,
  }

  static defaultProps = {
    onChange: noop,
  }

  constructor(props) {
    super(props)
    const today = new Date()
    this.state = Object.assign(
      {
        year: today.getFullYear(),
        month: today.getMonth(),
        display: DisplayType.DEFAULT,
      },
      this.getStateFromProps(),
    )
  }

  // Parse/format used to convert possible UTC props to local date
  getMinDate = memoize((minDate) => formatDate(parseDate(minDate)))
  getMaxDate = memoize((maxDate) => formatDate(parseDate(maxDate)))

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.setState(this.getStateFromProps(nextProps))
  }

  getStateFromProps(nextProps) {
    const props = nextProps || this.props
    const res = {}
    res.value = parseDate(props.value)
    if (Array.isArray(props.value) && props.value.length === 2) {
      res.value = props.value.map((v) => parseDate(v))
    }
    if (isDate(res.value)) {
      res.year = res.value.getFullYear()
      res.month = res.value.getMonth()
    }
    if (isValidDate(props.viewDate)) {
      const date = parseDate(props.viewDate)
      res.year = date.getFullYear()
      res.month = date.getMonth()
    }
    return res
  }

  setMonth = (month) => {
    const d = new Date(this.state.year, month)
    this.setState({
      year: d.getFullYear(),
      month: d.getMonth(),
      display: DisplayType.DEFAULT,
    })
  }

  handleSelect = (e) => {
    const { name, onChange } = this.props
    const { date } = e.target.dataset
    onChange({ target: { value: date, name } })
  }

  handleMonthChange = (e) => this.setMonth(parseInt(e.target.name))

  handleMonthInc = () => this.setMonth(this.state.month + 1)

  handleMonthDec = () => this.setMonth(this.state.month - 1)

  handleYearChange = (e) =>
    this.setState({ year: parseInt(e.target.name), display: DisplayType.DEFAULT })

  handleDisplayChange = (e) => this.setState({ display: e.currentTarget.name })

  handleActiveYearRef = (el) => {
    if (!el) {
      return
    }
    try {
      const { parentElement } = el
      parentElement.scrollTop = el.offsetTop - el.clientHeight * 3
    } catch (e) {
      console.warn('Scroll year into view error:', e)
    }
  }

  renderMonths() {
    const date = new Date(this.state.year, 0)
    let res = [],
      months = []

    for (let i = 1; i <= 12; i++) {
      const month = date.getMonth()
      const cn = cx(cm.cell, cm.selectable, {
        [cm.active]: month === this.state.month,
      })
      months.push(
        <a key={`m-${i}`} onClick={this.handleMonthChange} name={i - 1} className={cn}>
          {l.month(date)}
        </a>,
      )
      if (i % 3 === 0) {
        res.push(
          <div key={`g-${i / 3}`} className={cm.row}>
            {months}
          </div>,
        )
        months = []
      }
      date.setMonth(month + 1)
    }
    return res
  }

  renderYears() {
    const { year } = this.state
    const currentYear = new Date().getFullYear()
    const diff = 100
    const min = Math.max(Math.min(year - diff, currentYear - diff), 0)
    let max = Math.max(year + diff, currentYear + diff)

    const res = []
    for (let i = min; i < max; i++) {
      const active = i === year
      const cn = cx(cm.cell, cm.selectable, {
        [cm.active]: active,
      })
      res.push(
        <div key={i} className={cm.row} ref={active ? this.handleActiveYearRef : undefined}>
          <a onClick={this.handleYearChange} name={i} className={cn}>
            {i}
          </a>
        </div>,
      )
    }
    return res
  }

  renderWeekDays() {
    const date = getWeekStartDate(new Date(), isSundayFirst())

    return (
      <div className={cm.row}>
        {[1, 2, 3, 4, 5, 6, 7].map((d) => {
          const weekDay = (
            <div className={cx(cm.cell, cm.weekDay, { [cm.last]: d === 7 })} key={'dw-' + d}>
              {l.weekdayShort(date)}
            </div>
          )
          date.setDate(date.getDate() + 1)
          return weekDay
        })}
      </div>
    )
  }

  renderDay(day, today) {
    const { year, month, value } = this.state
    const date = new Date(year, month, day.date)
    const { minDate, maxDate } = this.props

    const dateString = formatDate(date)
    const disabled =
      (isValidDate(maxDate) && dateString > this.getMaxDate(maxDate)) ||
      (isValidDate(minDate) && dateString < this.getMinDate(minDate))

    const selectable = !disabled && !day.isAnotherMonth
    var cn = cx(cm.cell, cm.day, {
      [cm.mute]: day.isAnotherMonth,
      [cm.disabled]: disabled,
      [cm.today]: !day.isAnotherMonth && isSameDay(date, today),
      [cm.rangeStart]: Array.isArray(value) && isSameDay(date, value[0]),
      [cm.rangeEnd]: Array.isArray(value) && isSameDay(date, value[1]),
      [cm.inRange]:
        Array.isArray(value) &&
        isDate(value[0]) &&
        isDate(value[1]) &&
        ((isAfter(date, value[0]) && isBefore(date, value[1])) ||
          isSameDay(date, value[0]) ||
          isSameDay(date, value[1])),
      [cm.selected]: isDate(value) && isSameDay(date, value),
      [cm.selectable]: selectable,
    })
    return (
      <div
        className={cn}
        data-date={dateString}
        onClick={selectable ? this.handleSelect : null}
        key={`d-${dateString}`}
      >
        {date.getDate()}
      </div>
    )
  }

  renderDays(year, month) {
    const today = new Date()
    const grid = getDaysGrid(year, month, isSundayFirst())

    return (
      <div className={cm.monthDays}>
        {this.renderWeekDays()}
        {grid.map((week, i) => {
          return (
            <div key={`w-${i / 7}`} className={cm.row}>
              {week.map((day) => this.renderDay(day, today))}
            </div>
          )
        })}
      </div>
    )
  }

  render() {
    const { year, month, display } = this.state
    const { hideHeader } = this.props
    const viewDate = new Date(year, month)

    let body = null

    switch (display) {
      case DisplayType.DEFAULT:
        body = this.renderDays(year, month)
        break
      case DisplayType.SELECT_MONTH:
        body = <Scrollbars>{this.renderMonths()}</Scrollbars>
        break
      case DisplayType.SELECT_YEAR:
        body = <Scrollbars>{this.renderYears()}</Scrollbars>
        break
    }

    return (
      <div className={cm.calendar}>
        {!hideHeader && (
          <div className={cm.header}>
            {display === DisplayType.DEFAULT ? (
              <Fragment>
                <div onClick={this.handleMonthDec} className={cm.btn}>
                  <Icon.ChevronLeft size={24} />
                </div>
                <div className={cm.center}>
                  <a
                    onClick={this.handleDisplayChange}
                    name={DisplayType.SELECT_MONTH}
                    className={cm.btn}
                  >
                    {l.month(viewDate)}
                  </a>
                  <a
                    onClick={this.handleDisplayChange}
                    name={DisplayType.SELECT_YEAR}
                    className={cm.btn}
                  >
                    {l.year(viewDate)}
                  </a>
                </div>
                <div onClick={this.handleMonthInc} className={cm.btn}>
                  <Icon.ChevronRight size={24} />
                </div>
              </Fragment>
            ) : (
              <a
                onClick={this.handleDisplayChange}
                name={DisplayType.DEFAULT}
                className={cx(cm.btn, cm.grow, cm.left)}
              >
                <Icon.ArrowLeft size={24} />
              </a>
            )}
          </div>
        )}
        <div className={cm.body}>{body}</div>
      </div>
    )
  }
}

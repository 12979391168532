import sortBy from 'lodash/sortBy'

import app from 'common/builder/components/chart/FlowChart/app'
import { BUILDER_CANVAS_ID } from 'common/builder/components/chart/FlowChart/constants'
import { INode } from 'common/builder/nodes/nodeInterfaces'
import {
  FlowChartPointerElementPositionType,
  IArea,
  IAreaShift,
  IFlowChartPointer,
  IPointer,
  PointerType,
} from 'utils/services/newOnboardingService/view/Pointer/pointerInterfaces'

const applyAreaShift = (area: IArea, shift?: IAreaShift): IArea => {
  return {
    width: area.width + (shift?.width || 0),
    height: area.height + (shift?.height || 0),
    left: area.left + (shift?.left || 0),
    top: area.top + (shift?.top || 0),
  }
}

const applyScaleToAreaShift = (scale: number, shift?: IAreaShift): IAreaShift => ({
  width: (shift?.width || 0) * scale,
  height: (shift?.height || 0) * scale,
  left: (shift?.left || 0) * scale,
  top: (shift?.top || 0) * scale,
})

const getNodeAbsoluteArea = (node: UnsafeAnyObject): IArea => {
  const canvas = document.getElementById(BUILDER_CANVAS_ID)
  if (!canvas) {
    return { left: 0, top: 0, width: 0, height: 0 }
  }

  const canvasPosition = canvas.getBoundingClientRect()
  const nodePosition = app.getNodeBoundingClientRect(node)

  return {
    left: canvasPosition.left + nodePosition.left,
    top: canvasPosition.top + nodePosition.top,
    width: nodePosition.width,
    height: nodePosition.height,
  }
}

export const getFlowChartPointerNode = (pointerConfig: IFlowChartPointer): INode | undefined => {
  const nodes = app.getNodes().filter((node) => app.isNode(node))
  const elementIndex = pointerConfig?.elementIndex
  if (pointerConfig.name) {
    const matchedNodes = nodes.filter((node) => node.name.includes(pointerConfig.name))
    if (pointerConfig.elementPositionOnCanvas === FlowChartPointerElementPositionType.TOP_LEFT) {
      return sortBy(matchedNodes, ['ctx.x', 'ctx.y'])[0]
    }
    return matchedNodes[elementIndex || 0]
  } else if (pointerConfig.elementId) {
    const nodesWithElementId = nodes.filter(
      (node) => node.onboardingElementId === pointerConfig.elementId,
    )
    const node = elementIndex ? nodesWithElementId[elementIndex] : nodesWithElementId[0]
    return node
  }
  return undefined
}

export const getPointerArea = (pointerConfig: IPointer): IArea | undefined => {
  switch (pointerConfig.type) {
    case PointerType.FLOW_CHART: {
      const node = getFlowChartPointerNode(pointerConfig)

      if (node) {
        const area = getNodeAbsoluteArea(node)
        const shift = applyScaleToAreaShift(app.transform?.k, pointerConfig.areaShift)
        return applyAreaShift(area, shift)
      }

      return undefined
    }
    case PointerType.AREA: {
      const area = pointerConfig.area
      return applyAreaShift(area, pointerConfig.areaShift)
    }
    case PointerType.DOM: {
      const elementSelector = `[data-onboarding-id="${pointerConfig.elementId}"]`
      const elementIndex = pointerConfig.elementIndex
      const element = elementIndex
        ? document.querySelectorAll(elementSelector)[elementIndex]
        : document.querySelector(elementSelector)

      if (element) {
        const { left, top, width, height } = element.getBoundingClientRect()
        const area = { left, top, width, height }
        return applyAreaShift(area, pointerConfig.areaShift)
      }

      return undefined
    }
  }
}

export const getBlockingElementArea = (
  area: IArea,
  screen: { width: number; height: number },
  padding = 0,
): IArea[] => {
  const paddingDouble = padding * 2
  const areaTop = { left: 0, top: 0, width: screen.width, height: area.top - padding }
  const areaLeft = {
    left: 0,
    top: area.top - padding,
    width: area.left - padding,
    height: area.height + paddingDouble,
  }
  const areaRight = {
    left: area.left + area.width + padding,
    top: area.top - padding,
    width: screen.width - area.width - padding - area.left,
    height: area.height + paddingDouble,
  }
  const areaBottom = {
    left: 0,
    top: area.top + area.height + padding,
    width: screen.width,
    height: screen.height - area.height - padding - area.top,
  }

  const areas = [areaTop, areaLeft, areaRight, areaBottom]

  return areas.filter((area) => area.width > 0 && area.height > 0)
}

export const getTooltipPosition = (
  area: IArea,
  padding: number,
  at: 'top' | 'bottom' | 'left' | 'right',
): { top: number; left: number } => {
  switch (at) {
    case 'top':
      return {
        top: area.top - padding,
        left: area.left + area.width / 2,
      }
    case 'bottom':
      return {
        top: area.top + area.height + padding,
        left: area.left + area.width / 2,
      }
    case 'left':
      return {
        top: area.top + area.height / 2,
        left: area.left - padding,
      }
    case 'right':
      return {
        top: area.top + area.height / 2,
        left: area.left + area.width + padding,
      }
  }
}

export const waitUntilElementMount = <T = Element>(
  query: string,
  callback: (el: T) => void,
  timeout = 3000,
): { drop: () => void } => {
  const tryFindElement = () => {
    const element = document.querySelector(query)
    if (element) {
      callback(element as unknown as T)
      return true
    }
    return false
  }

  const isFind = tryFindElement()
  if (isFind) {
    return { drop: () => {} }
  }

  const drop = () => {
    if (observer) {
      observer.disconnect()
      observer = undefined
    }
  }
  const handleDomUpdate = () => {
    const isFind = tryFindElement()
    if (isFind) {
      drop()
    }
  }
  let observer: MutationObserver | undefined = new MutationObserver(handleDomUpdate)
  observer.observe(document.body, {
    childList: true,
    subtree: true,
  })

  setTimeout(drop, timeout)

  return { drop }
}

import size from 'lodash/size'
import { l } from '@manychat/manyui'

import * as agencyATypes from 'apps/agency/constants/AgencyReduxActionTypes'
import * as audienceActions from 'common/audience/actions/audienceActions'
import { UpgradeSource } from 'common/billing'
import { handleGoProError } from 'common/billing/helpers/handleGoProError'
import { alert } from 'common/core'
import * as appAtypes from 'common/core/constants/appReduxActionTypes'
import * as appSelectors from 'common/core/selectors/appSelectors'
import { LiveChatAccountSettings } from 'common/settings/interfaces'
import * as API from 'constants/API'
import { isRequestError } from 'utils/api/mcApi/RequestError'
import { makeCurrentItemActions } from 'utils/factory'
import { ExternalPaths, RoutePaths } from 'utils/router/constants'
import { navigatePollyfill, isPathnameInclude } from 'utils/router/tools'
import errorTrackingService from 'utils/services/errorTrackingService'
import {
  accountNotificationsListener,
  anotherTabNotificationsListener,
} from 'utils/services/notificationsService'

import * as liveChatAtypes from '../constants/liveChatReduxActionTypes'
import * as settingsActionTypes from '../SettingsReduxActionTypes'

import { fetchSettings, getHandoverReceivers } from './settingsActionsTyped'

export { fetchSettings }

const endpoints = API.settings.endpoints
const baseActions = makeCurrentItemActions('settings')

export const ensureSettings = () => {
  return async (dispatch, getState) => {
    const state = getState()

    const isStateSettingsEmpty = !state.settings.item

    // We initialize display preferences from initialData when the page loads for UI.
    const isStateSettingsOnlyWithDisplayPreferences =
      state.settings.item?.display_preferences && size(state.settings.item) === 1

    if (isStateSettingsEmpty || isStateSettingsOnlyWithDisplayPreferences) {
      await dispatch(fetchSettings())
    }
  }
}

const alertSettingsSaveSuccessCallback = () => {
  alert(l.translate('Settings have been saved!'), 'success')
}

const _updateSettings = (path, data, onSuccess = () => {}) => ({
  type: settingsActionTypes.SETTINGS_UPDATE,
  $fetch: [
    endpoints[path],
    {
      method: 'POST',
      body: JSON.stringify(data),
      headers: { 'Content-Type': 'application/json' },
    },
  ],
  $success: onSuccess,
})

const _updateLivechatSettings = (path, data) => ({
  type: settingsActionTypes.LIVECHAT_SETTINGS_UPDATE,
  $fetch: [
    endpoints[path],
    {
      method: 'POST',
      body: JSON.stringify(data),
      headers: { 'Content-Type': 'application/json' },
    },
  ],
  $success: alertSettingsSaveSuccessCallback,
})

const _updateSettingsSimple = (path) => ({
  type: settingsActionTypes.SETTINGS_UPDATE,
  $fetch: endpoints[path],
})

export const updateLivechatAccountSettings = (data) => ({
  type: settingsActionTypes.LIVECHAT_ACCOUNT_SETTINGS_UPDATE,
  $fetch: [
    endpoints.setLivechatAccountSettings,
    {
      method: 'POST',
      body: JSON.stringify(data),
      headers: { 'Content-Type': 'application/json' },
    },
  ],
  $success: () => {
    if (data?.settings_key !== LiveChatAccountSettings.AUTO_ASSIGN_TYPE) {
      alertSettingsSaveSuccessCallback()
      return
    }

    if (data?.value) {
      alert(l.translate('Auto-Assignment is on.'), 'success')
      return
    }

    alert(l.translate('Auto-Assignment is off.'), 'success')
  },
})

export const setSkipFlowTemplatesModal = (enabled) => ({
  type: settingsActionTypes.SET_SKIP_FLOW_TEMPLATES,
  payload: enabled,
  $fetch: [
    API.settings.endpoints.setSkipFlowTemplatesModal,
    {
      method: 'POST',
      body: JSON.stringify({
        skip_flow_templates_modal: enabled,
      }),
      headers: { 'Content-Type': 'application/json' },
    },
  ],
})

export const setPageName = (page_name) => {
  return async (dispatch) =>
    dispatch({
      type: settingsActionTypes.SETTINGS_SET_PAGE_NAME,
      $fetch: [
        API.settings.endpoints.setPageName,
        {
          method: 'POST',
          body: JSON.stringify({
            page_name,
          }),
          headers: { 'Content-Type': 'application/json' },
        },
      ],
      $success: () => {
        dispatch({
          type: appAtypes.APP_SET_PAGE_NAME,
          data: { page_name },
        })
        alert(l.translate('Account name changed!'), 'success')
      },
      $error: (err) => {
        alert(err, 'danger')
      },
    })
}

export const setGreeting = (greeting_text) => _updateSettings('setGreeting', { greeting_text })

export const setUrlShortener = (service) =>
  _updateSettings('setUrlShortener', { service }, alertSettingsSaveSuccessCallback)
export const setBodyUrlShortener = (service) => _updateSettings('setBodyUrlShortener', { service })
export const setWhatsAppUrlShortener = (service) =>
  _updateSettings('setWhatsAppUrlShortener', { service })

export const setGate = (gate = 'open') =>
  _updateSettings('setGate', { gate }, alertSettingsSaveSuccessCallback)
export const changeLcThreadsFilter = (enabled) =>
  _updateSettings('changeLcThreadsFilter', { enabled }, alertSettingsSaveSuccessCallback)

export const setNotificationEmail = (email) => _updateSettings('setNotificationEmail', { email })
export const setNotificationPhone = (data) => _updateSettings('setNotificationPhone', data)

export const resendConfirmationNotificationEmail = () =>
  _updateSettingsSimple('resendConfirmationNotificationEmail')
export const resetUnverifiedNotificationEmail = () =>
  _updateSettingsSimple('resetUnverifiedNotificationEmail')

export const setNotifyAdmin = (types) =>
  _updateSettings('setNotifyAdmin', { types }, alertSettingsSaveSuccessCallback)

export const disableUserInput = (composer_input_disable) =>
  _updateSettings('disableUserInput', { composer_input_disable })

export const setLivechatSettings = (changes) =>
  _updateLivechatSettings('setLivechatSettings', changes)

export const setBrandingEnabled = (value) => _updateSettings('enableWidgetBranding', { value })

// Admins section
export const generateDisconnectUrl = () => {
  return async (dispatch) => {
    const { url } = await dispatch({
      type: settingsActionTypes.SETTINGS_GENERATE_DISCONNECT_URL,
      $fetch: endpoints.generateDisconnectUrl,
    })
    const data = await dispatch({
      type: settingsActionTypes.SETTINGS_FETCH_DISCONNECT_URL,
      $fetch: url,
      $success: () => {
        if (data.url) {
          return (window.location.href = data.url)
        }
        navigatePollyfill(RoutePaths.ACCOUNT_DELETED)
      },
      $error: () => {
        window.location.href = ExternalPaths.HOME
      },
    })
  }
}

export const switchFacebookChannel = ({ status, returnPath = null }) => {
  return async (dispatch) => {
    const { redirect_url: redirectURL } = await dispatch({
      type: settingsActionTypes.SETTINGS_SWITCH_FACEBOOK_CHANNEL,
      $fetch: [
        endpoints.switchFacebookChannel,
        {
          method: 'POST',
          body: JSON.stringify({ value: status, return_path: returnPath }),
          headers: { 'Content-Type': 'application/json' },
        },
      ],
    })

    if (status === false) {
      window.location.reload()

      return
    }

    if (redirectURL) {
      window.location.replace(redirectURL)
    }
  }
}

export const setWidgetLocale = (widget_locale) => {
  return async (dispatch) => {
    await dispatch(baseActions.setWidgetLocale(null, { widget_locale }))
  }
}

export const getListLocales = baseActions.getListLocales

export const handoverUpdatedNotification = (data) => {
  return (dispatch) => {
    dispatch({
      type: settingsActionTypes.SETTINGS_HANDOVER_UPDATED_NOTIFICATION,
      data,
    })
    // fetching handover receivers has limits on facebook side
    // so executing only if we are in settings
    if (isPathnameInclude('/settings')) {
      dispatch(getHandoverReceivers())
    }
  }
}

export const getMessagingFeatureReview = () => {
  return async (dispatch, getState) => {
    await dispatch({
      type: settingsActionTypes.MESSAGING_FEATURE_REVIEW,
      $fetch: endpoints.messagingFeatureReview,
    })
    return appSelectors.getCurrentAccount(getState())
  }
}

export const setAudienceDeletedSubscribersVisibility = (visibility) => {
  return async (dispatch) => {
    await dispatch({
      type: settingsActionTypes.SETTINGS_SET_DELETED_SUBSCRIBERS_VISIBILITY,
      $fetch: [
        endpoints.setAudienceOnlyActive,
        {
          method: 'POST',
          body: JSON.stringify({
            value: visibility,
          }),
          headers: { 'Content-Type': 'application/json' },
        },
      ],
      $success: alertSettingsSaveSuccessCallback,
    })
    dispatch(audienceActions.dropSubscribersList())
  }
}

export const requestConnectPageToAgency = () => ({
  type: agencyATypes.AGENCY_CONNECT_PAGE,
  $fetch: endpoints.requestConnectPageToAgency,
})
export const setLiveChatConversationIdentification = (use_persona) => {
  return async (dispatch) => {
    await dispatch({
      type: settingsActionTypes.SETTINGS_USE_PERSONA,
      $fetch: [
        endpoints.usePersona,
        {
          method: 'POST',
          body: JSON.stringify({
            use_persona,
          }),
          headers: { 'Content-Type': 'application/json' },
        },
      ],
      $error: (err) => {
        if (err.go_pro) {
          dispatch(
            handleGoProError(err, {
              modalTitle: l.translate(`Unlock the ability with Manychat Pro`),
              source: UpgradeSource.SETTINGS,
              products: err.products,
              message: l.translate(
                "You aren't able to activate this feature on the current plan. Upgrade for unlimited access.",
              ),
            }),
          )
        } else {
          alert(err, 'danger')
        }
      },
    })
  }
}

export const getListTimezones = () => ({
  type: settingsActionTypes.SETTINGS_GET_TIMEZONES_LIST,
  $fetch: API.settings.endpoints.getListTimezones,
})

export const setLocalBotTimezone = (timezone) => ({
  type: appAtypes.APP_SET_BOT_TIMEZONE,
  data: { timezone },
})

export const resetLocalBotTimezone = () => ({
  type: appAtypes.APP_RESET_BOT_TIMEZONE,
  data: { timezone: null },
})

export const setBotTimezone = (timezone, options = { silent: false }) => {
  return async (dispatch) => {
    try {
      await dispatch({
        type: settingsActionTypes.SETTINGS_SET_BOT_TIMEZONE,
        $fetch: [
          API.settings.endpoints.setTimezone,
          {
            method: 'POST',
            body: JSON.stringify({
              timezone,
            }),
            headers: { 'Content-Type': 'application/json' },
          },
        ],
        $success: () => {
          dispatch({
            type: appAtypes.APP_SET_BOT_TIMEZONE,
            data: { timezone },
          })
          if (!options.silent) {
            alert(l.translate('Account Time Zone changed!'), 'success')
          }
        },
        $error: () => {},
      })
    } catch (error) {
      if (isRequestError(error) && error.reason === 'business-logic') return

      errorTrackingService.trackError(error, {
        extra: { timezone },
        fingerprint: 'setting_bot_timezone-non-business-error',
      })
    }
  }
}

export const suspendNotifyAdminByMessenger = () => ({
  type: settingsActionTypes.SUSPEND_NOTIFY_ADMIN_BY_MESSENGER,
  $fetch: [
    endpoints.suspendNotifyAdminByMessenger,
    {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
    },
  ],
  $success: () => alert(l.translate('Success'), 'success'),
})

export const setMobileNotifyAdminPushEnabled = (value) => ({
  type: settingsActionTypes.SET_MOBILE_NOTIFY_ADMIN_PUSH_ENABLED,
  $fetch: [
    endpoints.setMobileNotifyAdminPushEnabled,
    {
      method: 'POST',
      body: JSON.stringify({
        mobile_notify_admin_push_enabled: value,
      }),
      headers: { 'Content-Type': 'application/json' },
    },
  ],
  $success: alertSettingsSaveSuccessCallback,
})

export const fetchPricingLimitInfo = () => {
  return (dispatch, getState) => {
    const isFetch = appSelectors.isFetchPricingLimitInfo(getState())
    if (!isFetch) return

    dispatch({
      type: settingsActionTypes.FETCH_PRICING_LIMIT_INFO,
      $fetch: endpoints.fetchPricingLimitInfo,
    })
  }
}

const updateLiveChatFilterSetting = (value) => {
  return (dispatch) => {
    dispatch({
      type: liveChatAtypes.UPDATE_LIVECHAT_FILTER,
      value,
    })
  }
}

export const setDisplayPreference = (preferenceName, value) => {
  return (dispatch) => {
    dispatch({
      type: settingsActionTypes.SET_DISPLAY_PREFERENCE,
      payload: {
        preference: preferenceName,
        value,
      },
    })
  }
}

export const setDisplayPreferences = (displayPreferences) => {
  return (dispatch) => {
    dispatch({
      type: settingsActionTypes.SET_DISPLAY_PREFERENCES,
      payload: {
        value: displayPreferences,
      },
    })
  }
}

export const setInitialSettings = (settings) => {
  return (dispatch) => {
    dispatch({
      type: settingsActionTypes.SET_INITIAL_SETTINGS,
      payload: settings,
    })
  }
}

accountNotificationsListener.on('account_handover_updated', (data, dispatch) => {
  dispatch(handoverUpdatedNotification(data))
})

accountNotificationsListener.on('lc_threads_filter_changed', (data, dispatch) => {
  dispatch(updateLiveChatFilterSetting(data.model.value))
})

anotherTabNotificationsListener.on('display_preferences_updated', (data, dispatch) => {
  dispatch(setDisplayPreferences(data.display_preferences))
})

import { NEXT_PATH_SEARCH_PARAM } from 'utils/router/constants'

export const ProfileRoutes = [
  {
    path: 'dashboard',
    alias: 'profileDashboard',
    query: [NEXT_PATH_SEARCH_PARAM],
    lazy: () => import('./dashboard.page'),
  },
  {
    path: 'compliance-monitoring',
    lazy: () => import('./flowHealth.page'),
  },
] as const

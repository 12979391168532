import { useCallback } from 'react'
import { useAppDispatch, useAppSelector } from 'reduxTyped'

import { useModalData } from 'apps/cms/components/Flows/components/AutomationModals/hooks/useModalData'
import { FormValues } from 'apps/cms/components/Flows/components/AutomationModals/modals/CreateFolderWithContentModal/lib/types'
import { CmsAnalytics } from 'apps/cms/lib/analytics'
import { FsValidationMessage } from 'apps/cms/lib/validation'
import { contentManagementSelectors, contentManagementActions } from 'apps/cms/store'
import { AutomationModalType } from 'apps/cms/store/lib/types'
import { alert } from 'common/core'

export const useCreateFolderWithContentModal = () => {
  const dispatch = useAppDispatch()

  const { flowIds } = useModalData({ type: AutomationModalType.CREATE_FOLDER_WITH_CONTENT })

  const folders = useAppSelector(contentManagementSelectors.getFoldersByCurrentQueryPath)

  const { path } = useAppSelector(contentManagementSelectors.getQuery)

  const closeModal = useCallback(() => {
    dispatch(contentManagementActions.closeModal(AutomationModalType.CREATE_FOLDER_WITH_CONTENT))
  }, [dispatch])

  const createFolderWithContent = useCallback(
    async ({ name }: FormValues) => {
      const folderWithSameName = folders.find((folder) => folder.title === name)

      if (folderWithSameName) {
        return alert(FsValidationMessage.getFolderWithThisNameExistsMessage(), 'danger')
      }

      await dispatch(
        contentManagementActions.createFolderWithContent({
          folderPath: path,
          folderTitle: name,
          contentIds: flowIds,
        }),
      )
      CmsAnalytics.logCreateFolderWithContent()
      closeModal()
    },
    [closeModal, dispatch, flowIds, folders, path],
  )

  return {
    flowIds,
    closeModal,
    createFolderWithContent,
  }
}

export const DASHBOARD_METRICS_CHANGE_EARNED_DATE_RANGE =
  'DASHBOARD_METRICS_CHANGE_EARNED_DATE_RANGE'

export const DASHBOARD_METRICS_FETCH_EARNED_DATA = 'DASHBOARD_METRICS_FETCH_EARNED_DATA'
export const DASHBOARD_METRICS_FETCH_EARNED_DATA_REQUEST =
  'DASHBOARD_METRICS_FETCH_EARNED_DATA_REQUEST'
export const DASHBOARD_METRICS_FETCH_EARNED_DATA_RESPONSE =
  'DASHBOARD_METRICS_FETCH_EARNED_DATA_RESPONSE'
export const DASHBOARD_METRICS_FETCH_EARNED_DATA_ERROR = 'DASHBOARD_METRICS_FETCH_EARNED_DATA_ERROR'

export const DASHBOARD_METRICS_FETCH_EVENT_LIST = 'DASHBOARD_METRICS_FETCH_EVENT_LIST'
export const DASHBOARD_METRICS_FETCH_EVENT_LIST_REQUEST =
  'DASHBOARD_METRICS_FETCH_EVENT_LIST_REQUEST'
export const DASHBOARD_METRICS_FETCH_EVENT_LIST_RESPONSE =
  'DASHBOARD_METRICS_FETCH_EVENT_LIST_RESPONSE'
export const DASHBOARD_METRICS_FETCH_EVENT_LIST_ERROR = 'DASHBOARD_METRICS_FETCH_EVENT_LIST_ERROR'

export const DASHBOARD_METRICS_SET_EVENT_ID = 'DASHBOARD_METRICS_SET_EVENT_ID'

export const DASHBOARD_METRICS_CHANGE_PAYMENT_DATE_RANGE =
  'DASHBOARD_METRICS_CHANGE_PAYMENT_DATE_RANGE'

export const DASHBOARD_METRICS_FETCH_PAYMENT_DATA = 'DASHBOARD_METRICS_FETCH_PAYMENT_DATA'
export const DASHBOARD_METRICS_FETCH_PAYMENT_DATA_REQUEST =
  'DASHBOARD_METRICS_FETCH_PAYMENT_DATA_REQUEST'
export const DASHBOARD_METRICS_FETCH_PAYMENT_DATA_RESPONSE =
  'DASHBOARD_METRICS_FETCH_PAYMENT_DATA_RESPONSE'
export const DASHBOARD_METRICS_FETCH_PAYMENT_DATA_ERROR =
  'DASHBOARD_METRICS_FETCH_PAYMENT_DATA_ERROR'

export const DASHBOARD_METRICS_GET_CURRENCIES = 'DASHBOARD_METRICS_GET_CURRENCIES'
export const DASHBOARD_METRICS_GET_CURRENCIES_RESPONSE = 'DASHBOARD_METRICS_GET_CURRENCIES_RESPONSE'

import { api } from 'shared/api'

import { BillingSchemas } from './schemas'

export const BillingApi = {
  getNextActionRequiredInfo: api.account.createGet({
    url: '/billing/getNextActionRequiredInfo',
    schemas: BillingSchemas.getNextActionRequiredInfo,
  }),
  checkout: api.account.createPost({
    url: '/billing/checkout',
    schemas: BillingSchemas.checkout,
  }),
  applyCoupon: api.account.createPost({
    url: '/billing/applyCoupon',
    schemas: BillingSchemas.applyCoupon,
  }),
  applyCouponToSubscription: api.account.createPost({
    url: '/billing/applyCouponToSubscription',
    schemas: BillingSchemas.applyCoupon,
  }),
  resetCoupon: api.account.createPost({
    url: '/billing/resetCoupon',
    schemas: BillingSchemas.resetCoupon,
  }),
  getProSettings: api.account.createGet({
    url: '/billing/getProSettings',
    schemas: BillingSchemas.getProSettings,
  }),
  unsubscribe: api.account.createPost({
    url: '/billing/unsubscribe',
    schemas: BillingSchemas.unsubscribe,
  }),
  backToPro: api.account.createGet({
    url: '/billing/backToPro',
    schemas: BillingSchemas.backToPro,
  }),
  fetchCancellationReasonsList: api.account.createGet({
    url: '/billing/cancellationReasonsList',
    schemas: BillingSchemas.fetchCancellationReasonsList,
  }),
  cancelSubscriptionWithRefund: api.account.createPost({
    url: '/billing/cancelSubscriptionWithRefund',
    schemas: BillingSchemas.cancelSubscriptionWithRefund,
  }),
  updateBillingEmail: api.account.createPost({
    url: '/billing/updateBillingEmail',
    schemas: BillingSchemas.updateBillingEmail,
  }),
  getPrices: api.account.createGet({
    url: '/billing/getPrices',
    schemas: BillingSchemas.getPrices,
  }),
  switchProduct: api.account.createPost({
    url: '/billing/switchProduct',
    schemas: BillingSchemas.switchProduct,
  }),
  retryPayment: api.account.createPost({
    url: '/billing/payLatestSubscriptionInvoice',
    schemas: BillingSchemas.retryPayment,
  }),
  switchInvoicesSending: api.account.createPost({
    url: '/settings/switchBillingInvoiceSending',
    schemas: BillingSchemas.switchInvoicesSending,
  }),
  saveBillingInfo: api.account.createPost({
    url: '/settings/updateBillingInfo',
    schemas: BillingSchemas.saveBillingInfo,
  }),
  listInvoices: api.account.createGet({
    url: '/billing/listInvoices',
    schemas: BillingSchemas.listInvoices,
  }),
  listCharges: api.account.createGet({
    url: '/billing/listCharges',
    schemas: BillingSchemas.listCharges,
  }),
  getCheckoutInfo: api.account.createGet({
    url: '/billing/getCheckoutInfo',
    schemas: BillingSchemas.getCheckoutInfo,
  }),
}

import dot from 'dot-prop-immutable'

import * as atypes from 'common/actions/integrations/common/constants/IntegrationsReduxActionTypes'
import { ConvertKitConfig } from 'common/actions/integrations/ConvertKit/model/constants'

const initialState = {
  identityField: {
    value: null,
    option: null,
  },
  forms: null,
  sequences: null,
  tags: null,
  currentForm: '',
  currentSequence: '',
  currentTag: '',
  convertkitFields: null,
  isFetchingForms: false,
  isFetchingTags: false,
  isFetchingSequences: false,
  isFetchingConvertKitFields: false,
  bindings: {},
  mappings: {},
  isConnecting: false,
  isDisconnecting: false,
}

export default function integrationsReducer(state = initialState, action) {
  const { data } = action

  switch (action.type) {
    case atypes.CONVERTKIT_CLEAR_ACCOUNT_LINK_FIELD_RESPONSE:
    case atypes.CONVERTKIT_SET_ACCOUNT_LINK_FIELD_RESPONSE: {
      return dot.set(state, ConvertKitConfig.type, data.covertkit)
    }

    case atypes.CONVERTKIT_FORMS_FETCH_REQUEST: {
      return { ...state, isFetchingForms: true }
    }

    case atypes.CONVERTKIT_FORMS_FETCH_ERROR: {
      return { ...state, isFetchingForms: false }
    }

    case atypes.CONVERTKIT_FORMS_FETCH_RESPONSE: {
      return { ...state, forms: data.forms, isFetchingForms: false }
    }

    case atypes.CONVERTKIT_SET_FORM: {
      return { ...state, currentForm: data }
    }

    case atypes.CONVERTKIT_SEQUENCES_FETCH_REQUEST: {
      return { ...state, isFetchingSequences: true }
    }

    case atypes.CONVERTKIT_SEQUENCES_FETCH_ERROR: {
      return { ...state, isFetchingSequences: false }
    }

    case atypes.CONVERTKIT_SEQUENCES_FETCH_RESPONSE: {
      return { ...state, sequences: data.sequences, isFetchingSequences: false }
    }

    case atypes.CONVERTKIT_SET_SEQUENCE: {
      return { ...state, currentSequence: data }
    }

    case atypes.CONVERTKIT_TAGS_FETCH_REQUEST: {
      return { ...state, isFetchingTags: true }
    }

    case atypes.CONVERTKIT_TAGS_FETCH_ERROR: {
      return { ...state, isFetchingTags: false }
    }

    case atypes.CONVERTKIT_TAGS_FETCH_RESPONSE: {
      return { ...state, tags: data.tags, isFetchingTags: false }
    }

    case atypes.CONVERTKIT_SET_TAG: {
      return { ...state, currentTag: data }
    }

    case atypes.CONVERTKIT_FIELDS_FETCH_REQUEST: {
      return { ...state, isFetchingConvertKitFields: true }
    }

    case atypes.CONVERTKIT_FIELDS_FETCH_ERROR: {
      return { ...state, isFetchingConvertKitFields: false }
    }

    case atypes.CONVERTKIT_FIELDS_FETCH_RESPONSE: {
      return { ...state, convertkitFields: data.fields, isFetchingConvertKitFields: false }
    }

    case atypes.CONVERTKIT_SET_BINDINGS: {
      return { ...state, bindings: { ...state.bindings, ...data } }
    }

    case atypes.CONVERTKIT_SET_MAPPINGS: {
      return { ...state, mappings: { ...state.mappings, ...data } }
    }

    case atypes.CONVERTKIT_SET_IDENTITY_FIELD: {
      return { ...state, identityField: data }
    }

    case atypes.INTEGRATION_RESET: {
      return {
        ...state,
        ...initialState,
      }
    }

    case atypes.INTEGRATION_CONNECT_BY_TOKEN_REQUEST: {
      return { ...state, isConnecting: true }
    }

    case atypes.INTEGRATION_CONNECT_BY_TOKEN_ERROR:
    case atypes.INTEGRATION_CONNECT_BY_TOKEN_RESPONSE: {
      return { ...state, isConnecting: false }
    }

    case atypes.INTEGRATION_DISCONNECT_REQUEST: {
      return { ...state, isDisconnecting: true }
    }
    case atypes.INTEGRATION_DISCONNECT_RESPONSE: {
      return { ...state, isDisconnecting: false }
    }
  }

  return state
}

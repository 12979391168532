import { z } from 'zod'

import { ChannelType } from 'common/core/constants/ChannelType'

export const AiAgentAvailableChannelSchema = z.union([
  z.literal(ChannelType.INSTAGRAM),
  z.literal(ChannelType.TIKTOK),
  z.literal(ChannelType.WHATSAPP),
  z.literal(ChannelType.FB),
  z.literal(ChannelType.TELEGRAM),
])

export type AiAgentAvailableChannel = z.infer<typeof AiAgentAvailableChannelSchema>

export const AiAgentAvailableChannels: ChannelType[] = [
  ChannelType.INSTAGRAM,
  ChannelType.TIKTOK,
  ChannelType.WHATSAPP,
  ChannelType.FB,
  ChannelType.TELEGRAM,
]

interface IStepSystemConfig {
  onRun?: (step: StepSystem) => void
}

class StepSystem {
  private nextStep: () => void

  private config?: IStepSystemConfig

  constructor(onNextStep: () => void, config?: IStepSystemConfig) {
    this.nextStep = onNextStep
    this.config = config

    // Temp solution to support case when step is changing immediately, inside .onRun() callback
    // I need to think about it...
    setTimeout(() => {
      this.config?.onRun?.(this)
    })
  }

  process() {
    this.nextStep()
  }
}

export default StepSystem

import assert from 'assert'

import find from 'lodash/find'
import some from 'lodash/some'
import { v4 as uuid } from 'uuid'

import { createUniqueCaption } from 'utils'
import { createBlock } from 'common/builder/actions/createBlock'
import { createNode } from 'common/builder/actions/createNode'
import { openNode } from 'common/builder/actions/openNode'
import { updateSourceTarget } from 'common/builder/actions/sourceActions'
import { updateCoordinates } from 'common/builder/actions/updateCoordinates'
import flowChartApp from 'common/builder/components/chart/FlowChart/app'
import { BlockType } from 'common/builder/constants/BlockType'
import { NodeType } from 'common/builder/constants/NodeType'
import {
  createEmailNodePreset,
  setEmailNodePreferredBusinessEmail,
} from 'common/builder/emailBuilder/actions/emailBuilderActions'
import nodeRegistry from 'common/builder/nodeRegistry'
import builderSelectors from 'common/builder/selectors/builder'
import { getNodesListByType, getBlockById } from 'common/builder/selectors/builder/entitySelectors'
import { convertToAiAgentWithPreselectedChannel } from 'common/builder/utils/convertToAiAgentWithPreselectedChannel'
import { convertToIgCommentReplyNode } from 'common/builder/utils/convertToIgCommentReplyNode'
import { isAiAgentNode } from 'common/builder/utils/isAiAgentNode'
import { isConvertableToNewIgCommentReplyNode } from 'common/builder/utils/isConvertableToNewIgCommentReplyNode'
import { analyticsService } from 'utils/services/analytics'

/**
 * Dispatches new node
 * @param {string} builderId
 * @param {(object|string)} [initialOrType]
 * @param {object} [options]
 * @returns {import('common/core/interfaces/actions').IThunkAction}
 */
export const builderCreateNode = (builderId, initialOrType = {}, options = {}) => {
  return (dispatch, getState) => {
    assert(builderId, `createNode: builderId is required param`)

    const initial = typeof initialOrType === 'string' ? { nodeType: initialOrType } : initialOrType
    initial.id = uuid()

    const { nodeType } = initial
    assert(nodeType, 'createNode: nodeType is required')
    assert(Object.values(NodeType).includes(nodeType), `createNode: invalid nodeType: ${nodeType}`)

    const state = getState()

    let builder = builderSelectors.builderState.getById(getState(), builderId)
    if (!initial.flow) {
      initial.flow = builder.flow
    }

    const nodeConfig = nodeRegistry.get(initial)

    if (!initial.caption) {
      if (nodeType === NodeType.AI_AGENT) {
        initial.caption = nodeConfig.getCaption()
      } else {
        const captions = getNodesListByType(state, builderId, nodeType).map((c) => c.caption)
        initial.caption = createUniqueCaption(captions, nodeConfig.getCaption(), { prefix: ' #' })
      }
    }

    let item = nodeConfig.create(initial)

    if (isAiAgentNode(item)) {
      item = convertToAiAgentWithPreselectedChannel({
        node: item,
        state,
        builderId,
        sourceId: options.srcId ?? null,
      })
    }

    if (
      isConvertableToNewIgCommentReplyNode({ node: item, flowId: builder.flow, builderId, state })
    ) {
      item = convertToIgCommentReplyNode(item)
    }

    const hasInitialBlocks = item.blocks.length > 0
    const initialBlocksData = nodeConfig.getInitialBlocks(item)
    if (!hasInitialBlocks) {
      item.blocks = initialBlocksData.map(
        (blockInitial) => dispatch(createBlock(builderId, blockInitial)).id,
      )
    }

    // Ensure quick reply block created, for nodes with qr block in defaults
    const quickReplyDefaults = find(initialBlocksData, { type: BlockType.QUICK_REPLY })
    const existingBlocks = item.blocks.map((blockId) =>
      getBlockById(getState(), builderId, blockId),
    )
    const hasQuickReplyBlock = some(existingBlocks, { type: BlockType.QUICK_REPLY })
    if (quickReplyDefaults && !hasQuickReplyBlock) {
      item.blocks = [...item.blocks, dispatch(createBlock(builderId, quickReplyDefaults)).id]
    }

    const { srcId, preventOpen } = options

    if (options.position) {
      dispatch(updateCoordinates(builderId, { [item.id]: options.position }))
    } else if (flowChartApp.root !== null) {
      const position = flowChartApp.getPointForNewItems(srcId)
      dispatch(updateCoordinates(builderId, { [item.id]: position }))
    }

    dispatch(createNode(builderId, item))

    if (item.nodeType === NodeType.EMAIL_NEW) {
      dispatch(createEmailNodePreset(builderId, item.id))
      dispatch(setEmailNodePreferredBusinessEmail(builderId, item.id))

      analyticsService.sendEvent('EMAIL.NODE_CREATED')
    }

    if (srcId) {
      dispatch(updateSourceTarget(builderId, srcId, item.id))
    }

    if (!preventOpen) {
      dispatch(openNode(builderId, item.id, { dropHistory: true }))
    }

    return item
  }
}

import { useState } from 'react'
import { useAppDispatch } from 'reduxTyped'

import { useModalData } from 'apps/cms/components/Flows/components/AutomationModals/hooks/useModalData'
import { contentManagementActions } from 'apps/cms/store'
import { AutomationModalType } from 'apps/cms/store/lib/types'
import { analyticsService } from 'utils/services/analytics'

export const useApplyRecommendation = () => {
  const dispatch = useAppDispatch()

  const [isApplying, setApplying] = useState(false)

  const { recommendationId, recommendationType } = useModalData({
    type: AutomationModalType.RECOMMENDATIONS_APPLY,
  })

  const closeModal = () => {
    dispatch(contentManagementActions.closeModal(AutomationModalType.RECOMMENDATIONS_APPLY))
  }

  const applyRecommendationToFlow = async () => {
    setApplying(true)
    await dispatch(contentManagementActions.applyRecommendation(recommendationId))
    analyticsService.sendEvent('CMS.FLOWS.RECOMMENDATION_POPUP.APPLIED')
    setApplying(false)
    closeModal()
  }

  return {
    applyRecommendationToFlow,
    closeModal,
    isApplying,
    recommendationType,
  }
}

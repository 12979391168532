import React from 'react'
import cx from 'classnames'
import * as Icon from '@manychat/icons'

import cleanProps from '../../lib/clean-react-props'
import Loader from '../Loader'

import { BtnV2Props, IconNames, sizeMapping } from './interfaces'

import cm from './BtnV2.module.css'

export const BtnV2 = React.forwardRef<HTMLButtonElement, BtnV2Props>(
  (
    {
      size = 'default',
      variant = 'default',
      loading = false,
      type = 'button',
      fullWidth = false,
      label,
      onlyIcon,
      leftIcon,
      rightIcon,
      children,
      className,
      onClick,
      disabled,
      ...props
    },
    ref,
  ) => {
    const LeftIconComponent = leftIcon ? Icon[leftIcon as IconNames] : null
    const RightIconComponent = rightIcon ? Icon[rightIcon as IconNames] : null
    const OnlyIconComponent = onlyIcon ? Icon[onlyIcon as IconNames] : null
    const iconToUse = onlyIcon || leftIcon || rightIcon
    const iconSize = sizeMapping[size]

    const handleClick = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      if (!loading) {
        onClick?.(event)
      }
    }

    const buttonClasses = cx(
      cm.btnV2,
      {
        [cm.primary]: variant === 'primary',
        [cm.default]: variant === 'default',
        [cm.success]: variant === 'success',
        [cm.danger]: variant === 'danger',
        [cm.dangerLight]: variant === 'danger-light',
        [cm.ghostPrimary]: variant === 'ghost-primary',
        [cm.ghostLight]: variant === 'ghost-light',
        [cm.ghostDark]: variant === 'ghost-dark',
        [cm.defaultSize]: size === 'default',
        [cm.small]: size === 'small',
        [cm.large]: size === 'large' && !iconToUse,
        [cm.largeWithIcon]: size === 'large' && iconToUse,
        [cm.fullWidth]: fullWidth,
        [cm.loading]: loading,
        [cm.onlyIcon]: onlyIcon,
        [cm.leftIcon]: leftIcon,
        [cm.rightIcon]: rightIcon,
      },
      className,
    )

    const content = (
      <>
        {OnlyIconComponent && <OnlyIconComponent size={iconSize} />}
        {loading && (
          <span className={cx('p-stretched', cm.loader)}>
            <Loader center size={iconSize} theme={{ spinner: cx(cm.spinner) }} />
          </span>
        )}
        {!OnlyIconComponent && LeftIconComponent && <LeftIconComponent size={iconSize} />}
        {!OnlyIconComponent && label && <span className={cm.label}>{label}</span>}
        {!OnlyIconComponent && !label && (
          <span className={cx(cm.label, cm.capitalize)}>{children}</span>
        )}
        {!OnlyIconComponent && RightIconComponent && <RightIconComponent size={iconSize} />}
      </>
    )

    return (
      <button
        {...cleanProps(props, [], [])}
        ref={ref}
        type={type}
        aria-label={props.ariaLabel}
        aria-expanded={props.ariaExpanded}
        aria-describedby={props.ariaLabelledBy}
        tabIndex={props.tabIndex}
        className={buttonClasses}
        onClick={handleClick}
        disabled={disabled || loading}
      >
        {content}
      </button>
    )
  },
)

BtnV2.displayName = 'BtnV2'

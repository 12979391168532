import React from 'react'
import { palettes } from '@manychat/manyui'

import { ITagTextColorSliceData } from 'components/MCTextEditor/formatting/interfaces/tagTextColorSliceData'
import { SliceComponent } from 'components/RichTextEditor/types'

const TagTextColor: SliceComponent = ({ children, slice }) => {
  const data = slice.data as ITagTextColorSliceData
  const color = data?.color || palettes.typography.body

  return <span style={{ color }}>{children}</span>
}
export default TagTextColor

import React, { Component } from 'react'
import PropTypes from 'prop-types'

import { Input as AutoCompleteInput } from 'components/forms/Autocomplete'

export class CustomEventPicker extends Component {
  static propTypes = {
    className: PropTypes.string,
    value: PropTypes.any,
    customEvents: PropTypes.array,
    autoFocus: PropTypes.bool,
    invalid: PropTypes.bool,
    inline: PropTypes.bool,
    disabled: PropTypes.bool,
    forceNew: PropTypes.bool,
  }

  static defaultProps = {
    autoFocus: false,
    inline: false,
    disabled: false,
    forceNew: true,
    onFocus: () => {},
    onBlur: () => {},
  }

  handleRef = (ref) => {
    this.input = ref
    if (this.props.autoFocus) {
      this.focus()
    }
  }

  focus = () => {
    if (this.input) {
      this.input.focus()
    }
  }

  blur = () => {
    if (this.input) {
      this.input.blur()
    }
  }

  handleChange = (customEvent) => {
    this.props.onChange({
      target: {
        value: customEvent ? customEvent.event_id : null,
        name: customEvent ? customEvent.title : null,
      },
    })
  }

  handleSelect = (customEvent) => {
    this.handleChange(customEvent)
    this.blur()
  }

  /**
   * @deprecated
   * This is a legacy component that is used only in entities mapping
   * where creation is not available.
   */
  handleCreate = async () => {}

  render() {
    const {
      className,
      value,
      customEvents,
      invalid,
      inline,
      disabled,
      customTarget,
      theme,
      enableClear,
      forceNew,
    } = this.props

    const options = customEvents
    const customEvent = customEvents.find((customEvent) => customEvent.event_id === value)

    return (
      <AutoCompleteInput
        className={className}
        placeholder="Enter event"
        disabled={disabled}
        value={customEvent}
        invalid={invalid}
        inline={inline}
        useLayerForClickAway={false}
        forceNew={forceNew}
        options={options}
        customTarget={customTarget}
        theme={theme}
        labelEmpty="No events found"
        enableClear={enableClear}
        labelKey="title"
        labelNew={''}
        onSelect={this.handleSelect}
        onCreate={this.handleCreate}
        allowNew={false}
        ref={this.handleRef}
        onFocus={this.props.onFocus}
        onBlur={this.props.onBlur}
        data-test-id="customEvent-picker"
      />
    )
  }
}

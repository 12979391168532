import { FsTriggerModel } from 'shared/api/requests/cms/schemas'

export const triggerType = (triggerModel: FsTriggerModel | null) =>
  triggerModel === FsTriggerModel.KEYWORD

export const isIntent = (triggerModel: FsTriggerModel | null) =>
  triggerModel === FsTriggerModel.INTENT

export const isWidget = (triggerModel: FsTriggerModel | null) =>
  triggerModel === FsTriggerModel.WIDGET

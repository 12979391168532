import { makeLimitedListReducer, deleteFromList } from 'utils/factory'

import * as atypes from '../smartSegmentsReduxActionTypes'

const listReducer = makeLimitedListReducer('smartSegments')

export default function smartSegmentsReducer(state, action) {
  state = listReducer(state, action)
  switch (action.type) {
    case atypes.SMART_SEGMENTS_DELETE_SEGMENT_RESPONSE: {
      const { smart_segment_id } = action.body
      return deleteFromList(state, smart_segment_id)
    }
  }
  return state
}

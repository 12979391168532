import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { v4 as uuid } from 'uuid'

import { AiAssistantState, GenerationData } from 'apps/aiAssistant/interfaces/interfaces'
import { IThunkAction } from 'common/core/interfaces/actions'
import { AnyChatMessage, GenerationStatus } from 'shared/api/common/schemas/ai/ChatMessageSchemas'
import { AiAssistantApi } from 'shared/api/requests/aiAssistant'
import { accountNotificationsListener } from 'utils/services/notificationsService'
import { Handler } from 'utils/services/notificationsService/notificationsServiceTypes'

import { parseBackendProtoTriggers } from '../model/protoTriggerAdapter'

export const InitialState: AiAssistantState = {
  currentSessionId: null,
  sessions: {},
}

const AiAssistantSlice = createSlice({
  name: 'ai_assistant',
  initialState: InitialState,
  reducers: {
    startSession: (
      state,
      action: PayloadAction<{ builderId: string; flowId: string; sessionId: string }>,
    ) => {
      state.sessions[action.payload.sessionId] = {
        sessionId: action.payload.sessionId,
        builderId: action.payload.builderId,
        flowId: action.payload.flowId,

        messages: [],

        currentDraftId: null,
        drafts: {},
      }
      state.currentSessionId = action.payload.sessionId
    },
    closeAiAssistant: (state) => {
      state.currentSessionId = null
    },

    setHistory: (
      state,
      action: PayloadAction<{ sessionId: string; messages: AnyChatMessage[] }>,
    ) => {
      state.sessions[action.payload.sessionId].messages = action.payload.messages
    },
    setCurrentDraftId: (
      state,
      action: PayloadAction<{ sessionId: string; draftId: number | null }>,
    ) => {
      state.sessions[action.payload.sessionId].currentDraftId = action.payload.draftId
    },
    setDraft: (
      state,
      action: PayloadAction<{
        sessionId: string
        draftId: number
        status: GenerationStatus
        data?: GenerationData
      }>,
    ) => {
      state.sessions[action.payload.sessionId].drafts[action.payload.draftId] = {
        draftId: action.payload.draftId,
        status: action.payload.status,
        data: action.payload.data,
      }
    },
  },
})
export default AiAssistantSlice.reducer

export const getAiAssistantState = (state: RootState) => state.aiAssistant
export const isAiAssistantOpen = (state: RootState) => Boolean(state.aiAssistant.currentSessionId)

export const getSessionById = (state: RootState, sessionId: string) =>
  getAiAssistantState(state).sessions[sessionId] || null
export const getCurrentSession = (state: RootState) => {
  const currentSessionId = getAiAssistantState(state).currentSessionId
  return currentSessionId ? getSessionById(state, currentSessionId) : null
}

export const { startSession, closeAiAssistant, setHistory, setCurrentDraftId, setDraft } =
  AiAssistantSlice.actions

export const startChat = (builderId: string, flowId: string): IThunkAction => {
  return async (dispatch) => {
    const sessionId = uuid()

    dispatch(startSession({ sessionId, builderId, flowId }))

    const response = await AiAssistantApi.startChat({
      body: {
        flow_ns: flowId,
        session_id: sessionId,
      },
    })

    dispatch(setHistory({ sessionId, messages: response.data.history }))
  }
}

export const startNewChat = (): IThunkAction => {
  return (dispatch, getState) => {
    const currentSession = getCurrentSession(getState())
    if (!currentSession) {
      throw new Error('Unable to start new chat')
    }

    dispatch(startChat(currentSession.builderId, currentSession.flowId))
  }
}

export const sendChatEvent = (
  sessionId: string,
  input: { input?: string; button?: string },
): IThunkAction => {
  return async (dispatch, getState) => {
    const { flowId } = getSessionById(getState(), sessionId)

    const response = await AiAssistantApi.sendChatEvent({
      body: {
        flow_ns: flowId as string,
        session_id: sessionId,
        input: input.input,
        button: input.button,
      },
    })

    dispatch(setHistory({ sessionId, messages: response.data.history }))
  }
}

export const actualizeChatHistory = (sessionId: string): IThunkAction => {
  return async (dispatch) => {
    const response = await AiAssistantApi.getChatHistory({
      body: {
        session_id: sessionId,
      },
    })

    dispatch(setHistory({ sessionId, messages: response.data.history }))
  }
}

export const HandleGenerationStart: Handler = (
  data: {
    model: { draft_id: number; session_id: string; status: GenerationStatus }
  },
  dispatch,
  getState,
) => {
  const session = getSessionById(getState() as RootState, data.model.session_id)
  if (!session) {
    return
  }

  dispatch(
    setDraft({
      sessionId: data.model.session_id,
      draftId: data.model.draft_id,
      status: data.model.status,
    }),
  )
  dispatch(setCurrentDraftId({ sessionId: data.model.session_id, draftId: data.model.draft_id }))
}
accountNotificationsListener.on('ai_flow_draft_generating', HandleGenerationStart)

export const HandleGenerationComplete: Handler = async (
  data: {
    model: { draft_id: number; session_id: string; status: GenerationStatus }
  },
  dispatch,
  getState,
) => {
  const session = getSessionById(getState() as RootState, data.model.session_id)
  if (!session) {
    return
  }

  dispatch(
    setDraft({
      sessionId: data.model.session_id,
      draftId: data.model.draft_id,
      status: data.model.status,
    }),
  )

  dispatch(actualizeChatHistory(data.model.session_id))

  if (data.model.status === GenerationStatus.SUCCESS) {
    const response = await AiAssistantApi.getFlowDraft({
      body: {
        session_id: data.model.session_id,
        flow_draft_id: data.model.draft_id,
      },
    })
    if (response.data.status !== GenerationStatus.SUCCESS) {
      throw new Error('Expected draft should be already generated')
    }

    const flowDraft = response.data.flow_draft

    dispatch(
      setDraft({
        sessionId: data.model.session_id,
        draftId: data.model.draft_id,
        status: data.model.status,
        data: {
          draft: flowDraft.draft,
          fields: flowDraft.fields,
          triggers: parseBackendProtoTriggers(flowDraft.triggers),
        },
      }),
    )
  }
}
accountNotificationsListener.on('ai_flow_draft_generated', HandleGenerationComplete)

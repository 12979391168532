export const createListOperations = <
  GetFn,
  GetNextFn,
  GetPrevFn,
  CreateFn,
  DeleteFn,
  UpdateFn,
>(methods: {
  getList: GetFn
  getNextList: GetNextFn
  getPrevList: GetPrevFn
  createItem: CreateFn
  deleteItem: DeleteFn
  updateItem: UpdateFn
}) => ({
  getList: methods.getList,
  getNextList: methods.getNextList,
  getPrevList: methods.getPrevList,
  createItem: methods.createItem,
  deleteItem: methods.deleteItem,
  updateItem: methods.updateItem,
})

import React, { DetailedHTMLProps, HTMLAttributes } from 'react'
import cx from 'classnames'

export const Slot = (props: DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement>) => (
  <div
    {...props}
    className={cx(
      'bg-primary panel m-y-0 m-x-0 panel-info d-flex align-center justify-center',
      props.className,
    )}
  >
    <span className="text-white">Block</span>
  </div>
)

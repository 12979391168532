import React, { useCallback } from 'react'
import { useAppDispatch, useAppSelector } from 'reduxTyped'
import { BtnV2, l, Loader } from '@manychat/manyui'

import { contentManagementSelectors } from 'apps/cms/store'
import { FlowPickerSimulator } from 'apps/flowPicker/components/FlowPickerPreviewSimulator/FlowPickerSimulator'
import { useFlowPickerPreview } from 'apps/flowPicker/hooks/useFlowPickerPreview'
import { FlowPickerSource } from 'apps/flowPicker/lib/constants'
import { FlowPickerActions, FlowPickerSelectors } from 'apps/flowPicker/store'

export interface FlowPickerPreviewContentProps {
  lastSelectedFlowId: string
}

export function FlowPickerPreviewContent({ lastSelectedFlowId }: FlowPickerPreviewContentProps) {
  const dispatch = useAppDispatch()

  const {
    phoneContainerRef,
    isFetching,
    isFlowDataAvailable,
    handleRestartSimulator,
    isSimulatorThrownError,
  } = useFlowPickerPreview(lastSelectedFlowId)

  const source = useAppSelector(FlowPickerSelectors.getFlowPickerSource)

  const cmsFlow = useAppSelector((state) =>
    contentManagementSelectors.getFlowById(state, lastSelectedFlowId),
  )

  const handlePickAutomation = useCallback(() => {
    dispatch(FlowPickerActions.setSelectedFlowId({ flowId: lastSelectedFlowId }))
  }, [dispatch, lastSelectedFlowId])

  if (isFetching) {
    return <Loader size={32} center />
  }

  if (!cmsFlow) return null

  if (cmsFlow.channels_usage.tiktok && source === FlowPickerSource.BROADCAST) {
    return (
      <div className="text-secondary text-center p-x">
        {l.translate('Flow containing TikTok is not available for creating broadcast')}
      </div>
    )
  }

  if (!isFlowDataAvailable) {
    return (
      <div className="text-secondary text-center p-x">
        {l.translate("This Automation doesn't have a live version. Set it live before choosing.")}
      </div>
    )
  }

  return (
    <>
      <FlowPickerSimulator
        isSimulatorThrownError={isSimulatorThrownError}
        phoneContainerRef={phoneContainerRef}
        handleRestartSimulator={handleRestartSimulator}
      />
      <BtnV2
        fullWidth
        variant="primary"
        onClick={handlePickAutomation}
        className="flex-shrink-0"
        data-test-id="flow-picker-select-flow-button"
      >
        {l.translate('Pick this Automation')}
      </BtnV2>
    </>
  )
}

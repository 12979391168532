import React from 'react'
import { useAppDispatch, useAppSelector } from 'reduxTyped'

import { ensureThreadCounters } from 'apps/chat/actions/countersActions'
import { useIsAnyTabActive } from 'apps/chat/hooks/useIsAnyTabActive'
import { useLivechatSoundNotification } from 'apps/chat/hooks/useLivechatSoundNotification'
import { useLivechatTabTitleNotification } from 'apps/chat/hooks/useLivechatTabTitleNotification'
import {
  resetMessageReceived,
  resetThreadAssignedToMe,
  getMessageReceivedNotificationData,
  getThreadAssignedToMeNotificationData,
  getThreadUnassignedNotificationData,
  resetThreadUnassigned,
} from 'apps/chat/redux/notificationsSlice'
import {
  getUnassignedOpenThreadsCounter,
  getOpenThreadsCountAssignedToCurrentUser,
} from 'apps/chat/selectors/countersSelectors'
import { refreshTitle } from 'common/core/actions/pageActions'
import { ensureSettings } from 'common/settings/actions'

import { useNewMessagePushNotification } from './useNewMessagePushNotification'
import { useThreadAssignedToMePushNotification } from './useThreadAssignedToMePushNotification'
import { useThreadUnassignedPushNotification } from './useThreadUnassignedPushNotification'

const CURRENT_TAB_NOTIFICATION_ID = Math.floor(Math.random() * 1000)

export const useLivechatNotifications = () => {
  const dispatch = useAppDispatch()
  const { triggerTabTitleNotification } = useLivechatTabTitleNotification()
  const { isAnyTabActiveRef } = useIsAnyTabActive(CURRENT_TAB_NOTIFICATION_ID)
  const { triggerSoundNotification } = useLivechatSoundNotification(CURRENT_TAB_NOTIFICATION_ID)
  const { triggerNewMessagePushNotification } = useNewMessagePushNotification(
    CURRENT_TAB_NOTIFICATION_ID,
  )
  const { triggerThreadAssignedToMePushNotification } = useThreadAssignedToMePushNotification(
    CURRENT_TAB_NOTIFICATION_ID,
  )
  const { triggerThreadUnassignedPushNotification } = useThreadUnassignedPushNotification(
    CURRENT_TAB_NOTIFICATION_ID,
  )

  const unassignedOpenCounter = useAppSelector(getUnassignedOpenThreadsCounter)
  const assignedOpenCounter = useAppSelector(getOpenThreadsCountAssignedToCurrentUser)
  const messageReceivedNotificationData = useAppSelector(getMessageReceivedNotificationData)
  const threadUnassignedNotificationData = useAppSelector(getThreadUnassignedNotificationData)
  const threadAssignedToMeNotificationData = useAppSelector(getThreadAssignedToMeNotificationData)

  React.useEffect(() => {
    dispatch(ensureSettings())
    dispatch(ensureThreadCounters())
  }, [dispatch])

  React.useEffect(() => {
    dispatch(refreshTitle())
  }, [assignedOpenCounter, unassignedOpenCounter, dispatch])

  // new unassigned thread
  React.useEffect(() => {
    if (!threadUnassignedNotificationData) {
      return
    }

    if (!isAnyTabActiveRef.current) {
      triggerSoundNotification()
      triggerThreadUnassignedPushNotification(threadUnassignedNotificationData)
    }

    dispatch(resetThreadUnassigned)
  }, [
    dispatch,
    isAnyTabActiveRef,
    triggerSoundNotification,
    threadUnassignedNotificationData,
    triggerThreadUnassignedPushNotification,
  ])

  // new thread is assigned to me
  React.useEffect(() => {
    if (!threadAssignedToMeNotificationData) {
      return
    }

    if (!isAnyTabActiveRef.current) {
      triggerSoundNotification()
      triggerThreadAssignedToMePushNotification(threadAssignedToMeNotificationData)
    }

    dispatch(resetThreadAssignedToMe)
  }, [
    dispatch,
    isAnyTabActiveRef,
    triggerSoundNotification,
    threadAssignedToMeNotificationData,
    triggerThreadAssignedToMePushNotification,
  ])

  // new message from a conversation assigned to me
  React.useEffect(() => {
    if (!messageReceivedNotificationData?.last_lc_event?.model?.text) {
      return
    }

    if (!isAnyTabActiveRef.current) {
      triggerNewMessagePushNotification(messageReceivedNotificationData)
      triggerTabTitleNotification(messageReceivedNotificationData.user.name)
    }

    triggerSoundNotification()

    dispatch(resetMessageReceived())
  }, [
    dispatch,
    isAnyTabActiveRef,
    triggerSoundNotification,
    triggerTabTitleNotification,
    triggerNewMessagePushNotification,
    messageReceivedNotificationData,
  ])
}

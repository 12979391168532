import { api } from 'shared/api'
import { NotificationReasonSchemas } from 'shared/api/requests/notificationReason/schemas'

export const NotificationReasonApi = {
  getList: api.account.createGet({
    url: '/notificationReason/list',
    schemas: NotificationReasonSchemas.getList,
  }),
  create: api.account.createPost({
    url: '/notificationReason/create',
    schemas: NotificationReasonSchemas.create,
  }),
  archive: api.account.createPost({
    url: '/notificationReason/archive',
    schemas: NotificationReasonSchemas.archive,
  }),
  delete: api.account.createPost({
    url: '/notificationReason/delete',
    schemas: NotificationReasonSchemas.delete,
  }),
  restore: api.account.createPost({
    url: '/notificationReason/restore',
    schemas: NotificationReasonSchemas.restore,
  }),
}

import {
  IntegrationModalsState,
  IntegrationModalType,
} from 'common/actions/integrations/common/lib/interfaces'

export const IntegrationModalsInitialState: IntegrationModalsState = {
  [IntegrationModalType.INTEGRATION]: { isOpen: false, data: null },
  [IntegrationModalType.APPLICATION]: { isOpen: false, data: null },
  [IntegrationModalType.ACTION_PICKER]: { isOpen: false, data: null },
}

import { v4 } from 'uuid'

import {
  BackendPlaygroundTextMessage,
  PlaygroundCollectedInfoMessage,
  PlaygroundDebugMessage,
  PlaygroundMessageAuthor,
  PlaygroundMessageType,
  PlaygroundSubscriberDataMessage,
  PlaygroundTextMessage,
} from 'apps/aiAgentPlayground/store/types'
import { UserFieldType } from 'common/fields/constants/UserFieldsTypes'

export const parseTextMessage = (message: BackendPlaygroundTextMessage): PlaygroundTextMessage => ({
  id: message.id,
  type: PlaygroundMessageType.TEXT,
  author: PlaygroundMessageAuthor.ASSISTANT,
  data: {
    text: message.data.text,
    buttons: message.data.buttons,
  },
})

export const createUserTextMessage = (message: string): PlaygroundTextMessage => ({
  id: v4(),
  type: PlaygroundMessageType.TEXT,
  author: PlaygroundMessageAuthor.USER,
  data: {
    text: message,
  },
})

export const createDebugMessage = (
  debugFields: Array<{ field: string; value: string }>,
): PlaygroundDebugMessage => ({
  id: v4(),
  type: PlaygroundMessageType.DEBUG,
  author: PlaygroundMessageAuthor.ASSISTANT,
  data: {
    debug_info: debugFields,
  },
})

export const createSubscriberDataMessage = (
  subscriberFields: Array<{ field_id: string; value: string }>,
): PlaygroundSubscriberDataMessage => ({
  id: v4(),
  type: PlaygroundMessageType.SUBSCRIBER_DATA,
  author: PlaygroundMessageAuthor.ASSISTANT,
  data: {
    fields: subscriberFields,
  },
})

export const createCollectedInfoMessage = (
  fields: Array<{
    type: UserFieldType
    field: string
    value: string | boolean | number
  }>,
): PlaygroundCollectedInfoMessage => ({
  id: v4(),
  type: PlaygroundMessageType.COLLECTED_INFO,
  author: PlaygroundMessageAuthor.ASSISTANT,
  data: {
    fields,
  },
})

import React from 'react'
import cx from 'classnames'
import { Icon } from '@manychat/manyui'

import { BaseBreadcrumbsSegment } from 'components/Breadcrumbs/lib/types'

import cm from './CustomizableBreadcrumbsFolderButton.module.css'

export interface CustomizableBreadcrumbsFolderButtonProps<
  CustomSegment extends BaseBreadcrumbsSegment,
> {
  segment: CustomSegment
  showIcon: boolean
  onClick: (event: React.MouseEvent<HTMLButtonElement>, segment: CustomSegment) => void
  buttonRef?:
    | React.MutableRefObject<HTMLButtonElement | null>
    | React.RefCallback<HTMLButtonElement | null>
  highlight?: boolean
}

export const CustomizableBreadcrumbsFolderButton = <CustomSegment extends BaseBreadcrumbsSegment>({
  segment,
  showIcon,
  onClick,
  buttonRef,
  highlight,
}: CustomizableBreadcrumbsFolderButtonProps<CustomSegment>) => (
  <button
    ref={buttonRef}
    onClick={(event) => {
      onClick(event, segment)
    }}
    className={cx(
      cm.segmentItem,
      highlight && cm.segmentItem_highlight,
      !showIcon && cm.segmentItem_nested,
    )}
  >
    {!showIcon && <Icon.ArrowDownRight className={cm.segmentItem__icon} size={16} />}

    <span className="text-ellipsis">{segment.label}</span>
  </button>
)

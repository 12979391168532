import { z } from 'zod'

import { AddOnSlug } from 'common/billing/interfaces/addOnTypes'
import { Ability } from 'common/core/constants/Ability'
import { ProductType } from 'common/core/interfaces/products'
import { BusinessErrorSchema } from 'shared/api/common/schemas/errors'

export const checkoutAndConnectCardRequestSchema = z.object({
  return_url: z.string().optional(),
  is_trial_request: z.boolean().optional(),
  is_upgrade_to_pro: z.boolean().optional(),
  products: z.array(z.nativeEnum(ProductType)).optional(),
})

export const checkoutAndSubscribeSchema = z.object({
  return_url: z.string().optional(),
  is_trial_request: z.boolean().optional(),
  products: z.array(z.nativeEnum(ProductType)).optional(),
  addons: z.array(z.nativeEnum(AddOnSlug)).optional(),
  is_upgrade_to_pro: z.boolean().optional(),
})

export const TierConfigSchema = z.object({
  price: z.number().nonnegative(),
  min: z.number().nonnegative(),
  max: z.number().nonnegative(),
})

export const TiersSchema = z.object({
  plans_tiers: z.array(TierConfigSchema),
})

const ProductsMapSchema = z.object(
  Object.values(ProductType).reduce((schema, product) => {
    schema[product] = z.boolean().optional()

    return schema
  }, {} as Record<ProductType, z.ZodOptional<z.ZodBoolean>>),
)

const AddOnsMapSchema = z.object(
  Object.values(AddOnSlug).reduce((schema, addOn) => {
    schema[addOn] = z.boolean().optional()

    return schema
  }, {} as Record<AddOnSlug, z.ZodOptional<z.ZodBoolean>>),
)

const ManageSubscriptionSchema = z.object({
  products: ProductsMapSchema.optional(),
  addons: AddOnsMapSchema.optional(),
  end_trial: z.boolean().optional(),
})

export const ManageSubscriptionErrorSchema = BusinessErrorSchema.extend({
  field: z.literal('manage_subscription_error'),
  error_data: z.object({
    abilities: z.array(z.nativeEnum(Ability)),
  }),
})

const StripeCreateCustomerSchema = z.object({
  stripe_payment_method_id: z.string(),
  email_billing: z.string(),
})

const StripeIntentTypeSchema = z.enum(['setup', 'payment'])

const StripeConfirmIntentSchema = z
  .object({
    confirm: z.object({
      client_secret: z.string(),
      type: StripeIntentTypeSchema,
    }),
  })
  .optional()

const StripeConfirmedIntentSchema = z.object({
  intentId: z.string(),
})

const StripeCheckoutSuccessSubscribeClientFlowSchema = z.object({
  setup_intent_id: z.string().optional(),
  products: ProductsMapSchema.optional(),
  addons: AddOnsMapSchema.optional(),
  is_trial_request: z.boolean().optional(),
  is_upgrade_to_pro: z.boolean().optional(),
})

export const billingFlexApiSchemas = {
  checkoutAndConnectCard: {
    path: z.undefined(),
    query: z.undefined(),
    request: checkoutAndConnectCardRequestSchema,
    response: z.object({
      redirect_url: z.string(),
    }),
  },
  checkoutAndSubscribe: {
    path: z.undefined(),
    query: z.undefined(),
    request: checkoutAndSubscribeSchema,
    response: z.object({
      redirect_url: z.string(),
    }),
  },
  plansTiers: {
    path: z.undefined(),
    query: z.undefined(),
    request: z.undefined(),
    response: TiersSchema,
  },
  manageSubscription: {
    path: z.undefined(),
    query: z.undefined(),
    request: ManageSubscriptionSchema,
    response: z.object({
      abilities: z.array(z.nativeEnum(Ability)),
      redirectURL: z.string().url().nullable().optional(),
    }),
    errors: {
      ManageSubscriptionError: ManageSubscriptionErrorSchema,
    },
  },
  updateTaxNumber: {
    path: z.undefined(),
    query: z.undefined(),
    request: z.object({
      tax_number: z.string(),
    }),
    response: z.undefined(),
  },
  createCustomer: {
    path: z.undefined(),
    query: z.undefined(),
    request: StripeCreateCustomerSchema,
    response: StripeConfirmIntentSchema,
  },
  checkoutSuccessSubscribeCustomCheckout: {
    path: z.undefined(),
    query: z.undefined(),
    request: StripeCheckoutSuccessSubscribeClientFlowSchema,
    response: StripeConfirmIntentSchema,
  },
}

export type CheckoutAndConnectCardRequest = z.infer<typeof checkoutAndConnectCardRequestSchema>
export type TierConfig = z.infer<typeof TierConfigSchema>
export type ManageSubscriptionPayload = z.infer<typeof ManageSubscriptionSchema>
export type StripeCreateCustomerPayload = z.infer<typeof StripeCreateCustomerSchema>
export type StripeConfirmedIntentSchema = z.infer<typeof StripeConfirmedIntentSchema>
export type StripeCheckoutSuccessSubscribeClientFlowPayload = z.infer<
  typeof StripeCheckoutSuccessSubscribeClientFlowSchema
>
export type StripeIntentType = z.infer<typeof StripeIntentTypeSchema>

import { useMemo } from 'react'
import { useAppSelector } from 'reduxTyped'
import { l } from '@manychat/manyui'

import { FilterGroupTitle } from 'apps/cms/components/Flows/components/FlowFilters/components/FilterPicker/lib/constants'
import { contentManagementSelectors } from 'apps/cms/store'
import { getFilterOption } from 'apps/cms/store/lib/helper'

import { OptionsGroup, Option } from '../lib/types'

export const useFilterOptions = () => {
  const { triggerTypeFilters } = useAppSelector(contentManagementSelectors.getFilters)

  const filterOptions = useMemo(() => {
    const options: OptionsGroup[] = []

    const seenGroupIndex: Record<string, number> = {}

    triggerTypeFilters.forEach((filter) => {
      const option: Option = getFilterOption(filter)

      const groupIndex = seenGroupIndex[filter.group]

      if (groupIndex !== undefined) {
        const group = options[groupIndex] as OptionsGroup
        group.children.push(option)
        return
      }

      seenGroupIndex[filter.group] =
        options.push({
          label: l.getString(FilterGroupTitle[filter.group]),
          children: [option],
          group: filter.group,
        }) - 1
    })

    return options
  }, [triggerTypeFilters])

  return { filterOptions }
}

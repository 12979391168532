export enum IntegrationType {
  ACTIVE_CAMPAIGN = 'active_campaign',
  CHATGPT = 'chatgpt',
  CONVERT_KIT = 'convertkit',
  GOOGLE_SHEETS = 'google_sheets',
  HOTMART = 'hotmart',
  HUBSPOT = 'hubspot',
  KLAVIYO = 'klaviyo',
  MAILCHIMP = 'mailchimp',
  SALESFORCE = 'salesforce',
  FLODESK = 'flodesk',
  TIKTOK_ADS = 'tiktok_ads',
  CLAUDE = 'claude',
  DEEP_SEEK = 'deepseek',
}

export const IntegrationNameByType: Record<IntegrationType, string> = {
  [IntegrationType.ACTIVE_CAMPAIGN]: 'ActiveCampaign',
  [IntegrationType.CHATGPT]: 'ChatGPT',
  [IntegrationType.CONVERT_KIT]: 'ConvertKit',
  [IntegrationType.GOOGLE_SHEETS]: 'Google Sheets',
  [IntegrationType.HOTMART]: 'Hotmart',
  [IntegrationType.HUBSPOT]: 'HubSpot',
  [IntegrationType.KLAVIYO]: 'Klaviyo',
  [IntegrationType.MAILCHIMP]: 'MailChimp',
  [IntegrationType.SALESFORCE]: 'Salesforce',
  [IntegrationType.FLODESK]: 'Flodesk',
  [IntegrationType.TIKTOK_ADS]: 'TikTok Ads',
  [IntegrationType.CLAUDE]: 'Claude',
  [IntegrationType.DEEP_SEEK]: 'DeepSeek',
}

import { mapResponse } from 'shared/api/lib/mapResponse'
import { BillingApi } from 'shared/api/requests/billing'
import { Invoice } from 'shared/api/requests/billing/schemas'
import { createListSlice } from 'shared/lib/factory/redux/createListSlice'
import { createListOperations } from 'shared/lib/factory/redux/createListSlice/lib/createListOperations'

const namespace = 'invoices'

export const invoicesSlice = createListSlice({
  storePath: 'billing.invoices',

  namespace,

  operations: createListOperations({
    getList: mapResponse(BillingApi.listInvoices, (response) => response.data.invoices),
    getNextList: undefined,
    getPrevList: undefined,
    createItem: undefined,
    deleteItem: undefined,
    updateItem: undefined,
  }),

  getItemId: (item: Invoice) => item.id,

  extraState: {},
  reducers: {},
})

export const { actions: invoicesActions, selectors: invoicesSelectors } = invoicesSlice

export type InvoicesListState = typeof invoicesSlice.initialState

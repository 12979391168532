import React, { useCallback, useEffect, useState } from 'react'
import { useAppDispatch, useAppSelector } from 'reduxTyped'
import { BtnV2, Modal, TextInputV2, l } from '@manychat/manyui'

import * as profileActions from 'apps/profile/actions/profileTypedActions'
import CodeInput from 'apps/signin/pages/StepEmailVerification/components/CodeInput'
import { alert } from 'common/core'
import Benefits from 'common/core/components/EmailVerification/Benefits'
import { getUser, getUserEmail, getUserEmailUnverified } from 'common/core/selectors/appSelectors'
import If from 'components/If'
import { MANYCHAT_SUPPORT_URL } from 'constants/constants'
import { useIsChanged } from 'utils/commonHooks'
import { analyticsService } from 'utils/services/analytics'
import { linkURL } from 'utils/url'

const EmailVerificationModal = ({
  open,
  onClose,
  unskipable = false,
  withHelp,
  title,
  children,
}: {
  open: boolean
  title?: string
  onClose: () => void
  unskipable?: boolean
  withHelp?: boolean
  children?: React.ReactNode
}) => {
  const dispatch = useAppDispatch()

  const user = useAppSelector(getUser)
  const userEmail = useAppSelector(getUserEmail)
  const emailUnverified = useAppSelector(getUserEmailUnverified)

  const hasNoEmail = !userEmail && !emailUnverified
  const [email, setEmail] = useState(emailUnverified)
  const isEmailChanged = hasNoEmail ? email.trim() !== '' : email.trim() !== emailUnverified

  const [code, setCode] = useState('')
  const [isInvalid, setIsInvalid] = useState(false)
  const [isProcess, setIsProcess] = useState(false)
  const [isFirstTry, setIsFirstTry] = useState(false)

  const isOpenChanged = useIsChanged(open)

  useEffect(() => {
    if (isOpenChanged && open) {
      analyticsService.sendEvent('VERIFY_EMAIL_BANNER_NEW.MODAL_SHOW')

      setEmail(emailUnverified)
      setIsFirstTry(true)
    }
  }, [emailUnverified, isOpenChanged, open])

  const handleClose = useCallback(() => {
    if (unskipable) return

    analyticsService.sendEvent('VERIFY_EMAIL_BANNER_NEW.MODAL_CLOSE')
    onClose()
  }, [onClose, unskipable])

  const verifyEmailViaCode = async (code: string) => {
    if (code.length !== 6) {
      return alert(l.translate('Please enter the full code.'), 'danger')
    }

    setIsProcess(true)

    try {
      await dispatch(profileActions.verifyEmailViaCode(code))

      onClose()
    } catch (e) {
      setIsInvalid(true)
    } finally {
      setIsProcess(false)
      setIsFirstTry(false)
    }
  }

  const handleEmailChange = useCallback<React.ChangeEventHandler<HTMLInputElement>>((e) => {
    setEmail(e.target.value)
  }, [])

  const handleSendVerificationLink = useCallback(async () => {
    if (!user.can_resend_email_verification_code) {
      return alert(
        l.translate('Too many confirmation requests. Please try again in 24 hours'),
        'danger',
      )
    }

    analyticsService.sendEvent('VERIFY_EMAIL_BANNER_NEW.MODAL_RESEND_VERIFICATION')

    await dispatch(profileActions.sendEmailVerification(emailUnverified))
  }, [user, dispatch, emailUnverified])

  const handleSubmit = useCallback(async () => {
    if (!user.can_resend_email_verification_code) {
      return alert(
        l.translate('Too many confirmation requests. Please try again in 24 hours'),
        'danger',
      )
    }

    analyticsService.sendEvent('VERIFY_EMAIL_BANNER_NEW.MODAL_SUBMIT')

    await dispatch(profileActions.sendEmailVerification(email))
  }, [user, email, dispatch])

  const handleCodeChange = (code: string) => {
    setCode(code)
    if (isInvalid) {
      setIsInvalid(false)
    }
  }

  const handleCodeFill = (code: string) => {
    if (isFirstTry) {
      verifyEmailViaCode(code)
    }
  }

  return (
    <Modal
      width={600}
      open={open}
      disableCloseButton={unskipable}
      noContentPadding
      title={title || l.translate('Verify your email address')}
      onRequestClose={handleClose}
    >
      <div className="p-a-lg">
        <div className="bg-default d-flex flex-col align-center rounded p-a m-t-md text-center">
          <div>
            {hasNoEmail
              ? l.translate(
                  'We will send you an email with a confirmation link. Please click the link to complete the verification process.',
                )
              : l.translate(
                  'Confirmation code has been sent to <b>{email}</b>.<br />Enter the code below to complete the verification process.',
                  { email: emailUnverified },
                )}
          </div>

          <div className="m-b-md" style={{ width: '305px' }}>
            <CodeInput
              onCodeChange={handleCodeChange}
              onCodeFill={handleCodeFill}
              count={6}
              invalid={isInvalid}
              disabled={isProcess}
              className="m-t"
            />
            <If condition={!isFirstTry}>
              <BtnV2
                fullWidth
                variant="primary"
                label="Verify"
                className="m-t"
                disabled={isProcess}
                onClick={() => verifyEmailViaCode(code)}
              />
            </If>
          </div>

          <div className="text-secondary text-sm">
            {l.translate("If you haven't received your email, check the spam folder.")}
            <br />
            <a onClick={handleSendVerificationLink} className="m-x-xxs text-primary">
              {l.translate('Resend code')}
            </a>
            <br />
            <br />
            {l.translate(
              'You can change email address below. <span class="text-secondary">If you are experiencing technical difficulties, please submit a </span><a class="text-primary" href="{link}">support ticket using this form.</a>',
              {
                link: linkURL('support/features/account/issue/account_personal_email_verification'),
              },
            )}
          </div>

          <div className="d-flex flex-row justify-center m-y">
            <TextInputV2
              value={email}
              onChange={handleEmailChange}
              placeholder={l.translate('Enter your email')}
              ariaLabel={l.translate('Enter your email')}
            />
            <BtnV2
              variant="primary"
              type="submit"
              className="m-l-xs flex-shrink-0"
              disabled={!isEmailChanged}
              onClick={handleSubmit}
            >
              {hasNoEmail ? l.translate('Save') : l.translate('Change')}
            </BtnV2>
          </div>
        </div>
        {children ? children : <Benefits />}
        <If condition={withHelp}>
          <div className="d-flex justify-center m-t-md">
            {l.translate(
              '<span class="text-secondary">If you need any help, please contact our </span><a class="text-primary" href="{supportUrl}" target="_blank" rel="noopener noreferrer">support</a>',
              {
                supportUrl: MANYCHAT_SUPPORT_URL,
              },
            )}
          </div>
        </If>
      </div>
      <If condition={!unskipable}>
        <div className="p-x-lg p-y d-flex justify-between b-t align-center">
          <div className="text-secondary pointer" onClick={handleClose}>
            {l.translate('Do it later')}
          </div>
        </div>
      </If>
    </Modal>
  )
}

export default EmailVerificationModal

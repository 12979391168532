import { z } from 'zod'

import {
  INSTAGRAM_POST_MEDIA_PRODUCT_TYPE,
  INSTAGRAM_POST_MEDIA_TYPE,
} from 'apps/instagram/models/interfaces'

export const InstagramPostListQuerySchema = z
  .object({
    next_page: z.string().optional(),
    limit: z.number().optional(),
  })
  .optional()

export const InstagramPostSchema = z
  .object({
    caption: z.string().optional(),
    comments_count: z.number(),
    id: z.string(),
    ig_id: z.string().optional(),
    is_comment_enabled: z.boolean(),
    like_count: z.number(),
    media_product_type: z.nativeEnum(INSTAGRAM_POST_MEDIA_PRODUCT_TYPE),
    media_type: z.nativeEnum(INSTAGRAM_POST_MEDIA_TYPE),
    media_url: z.string().optional(),
    owner: z.object({ id: z.string() }),
    thumbnail_url: z.string().optional(),
    permalink: z.string(),
    timestamp: z.string(),
    username: z.string(),
  })
  .strict()

export const InstagramPostListResponseSchema = z.object({
  next_page: z.string().nullable(),
  posts: z.array(InstagramPostSchema),
})

export const InstagramSchemas = {
  getPosts: {
    path: z.undefined(),
    query: InstagramPostListQuerySchema,
    response: InstagramPostListResponseSchema,
  },
  getPostById: {
    path: z.undefined(),
    query: z.object({
      post_id: z.string(),
    }),
    response: z.object({
      post: InstagramPostSchema,
    }),
  },
  getStory: {
    path: z.object({
      storyId: z.union([z.string(), z.number()]),
    }),
    query: z.undefined(),
    response: z.object({
      story: z
        .object({
          id: z.string(),
          media_type: z.string(),
          media_url: z.string().optional(),
          permalink: z.string(),
          timestamp: z.string(),
        })
        .nullable(),
    }),
  },
}

export type IInstagramPost = z.infer<typeof InstagramPostSchema>

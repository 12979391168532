import React from 'react'
import get from 'lodash/get'
import startsWith from 'lodash/startsWith'
import { l } from '@manychat/manyui'

import { SimpleTrialPlaceType } from 'apps/cms/lib/constants'
import { contentManagementActions } from 'apps/cms/store'
import { setWidgetFlow } from 'apps/growthTools/actions/widgetActionsTyped'
import CgtNextPostAlert from 'apps/growthTools/components/CgtNextPostAlert/CgtNextPostAlert'
import getDefaultSettings from 'apps/growthTools/components/WidgetEditor/constants/getDefaultSettings'
import WidgetTypeConfig from 'apps/growthTools/components/WidgetEditor/constants/WidgetTypeConfig'
import WidgetModel from 'apps/growthTools/models/Widget'
import { WidgetStatusType } from 'apps/growthTools/models/Widget/constants'
import { getWidgetErrors } from 'apps/growthTools/models/Widget/validateWidget'
import {
  getFilter,
  getSearchQuery,
  getSorting,
  getSortingOrder,
  getById as getWidgetById,
  getIsInstagramCgtWidgetWithAllPostsCoveredArea,
} from 'apps/growthTools/selectors/growthToolsSelectors'
import { billing, UpgradeSource } from 'common/billing'
import { handleGoProError } from 'common/billing/helpers/handleGoProError'
import * as builderActions from 'common/builder/actions/builderActions'
import { alert } from 'common/core'
import { uploadAttachment } from 'common/core/actions/attachmentActions'
import * as pageActions from 'common/core/actions/pageActions'
import { setCGTNotAllowedModal } from 'common/core/actions/uiActions'
import * as appAtypes from 'common/core/constants/appReduxActionTypes'
import { ChannelType } from 'common/core/constants/ChannelType'
import { ProductType } from 'common/core/interfaces/products'
import AccountModel from 'common/core/models/Account'
import { getCurrentAccount } from 'common/core/selectors/appSelectors'
import { TriggerType } from 'common/flow/constants/TriggerType'
import FlowModel from 'common/flow/models/Flow'
import {
  getById,
  getIsInstagramCgtTriggerWithAllPostsCoveredArea,
} from 'common/flow/selectors/flowSelectors'
import { handleGoProExperiment, ErrorGoProReasons } from 'common/goProExperimentHandlers'
import { makeLimitedListActions, makeItemsDetailsActions } from 'utils/factory'
import { navigatePollyfill } from 'utils/router/tools'
import { analyticsService } from 'utils/services/analytics'
import { anotherTabNotificationsListener } from 'utils/services/notificationsService'
import { linkURL } from 'utils/url'

import { GrowthToolsTabType } from '../constants/GrowthToolsTabType'
import * as atypes from '../constants/gtReduxActionTypes'
import { GetWidgetBuilderId, WidgetTypes, PostCoveredArea } from '../models/Widget/constants'

const defaultSettings = getDefaultSettings()
const baseListActions = makeLimitedListActions('widgets', { storePath: 'widgets.list' })
const baseItemActions = makeItemsDetailsActions('widgets')

export const fetchWidgets = baseListActions.fetch

export const fetchNextWidgets = () => {
  return (dispatch) => {
    dispatch(baseListActions.fetchNext())

    analyticsService.sendEvent('GT.WIDGETS_INFINITE_SCROLL_TRIGGERED')
  }
}

export const createWidget = (type, channel = ChannelType.FB, options = {}) => {
  const { withFlowRedirect, withNodeOpening, onError } = options
  return async (dispatch, getState) => {
    const state = getState()
    const name = options.name || state.widgets.byId.$new?.item?.name

    const payload = { widget_type: type, name, channel }

    const { item } = await dispatch(baseItemActions.create(payload, null, onError))
    const { flowId, id } = item
    const redirectUrl =
      withFlowRedirect && flowId
        ? `cms/files/${flowId}/edit${withNodeOpening ? `?openTriggerId=${item.triggerId}` : ''}`
        : `growth-tools/widget/${id}`

    navigatePollyfill(linkURL(redirectUrl))
  }
}

export const createWidgetFlow = (widgetId) => {
  return async (dispatch, getState) => {
    const widget = getState().widgets.byId[widgetId].item
    const flowName = WidgetModel.generateFlowName(widget)
    const fileItem = await dispatch(
      contentManagementActions.createAutomation({
        name: flowName,
      }),
    ).unwrap()

    if (!fileItem) {
      return null
    }

    return dispatch(setFlow(widgetId, fileItem.flow.ns))
  }
}

export const fetchAllWidgets = () => {
  return async (dispatch, getState) => {
    await dispatch(fetchWidgets())
    let limiter = getState().widgets.list.limiter
    while (limiter) {
      await dispatch(baseListActions.fetchNext())
      limiter = getState().widgets.list.limiter
    }
  }
}

export const widgetCreatedNotification = baseListActions.createdNotification
export const widgetUpdatedNotification = baseListActions.updatedNotification
export const widgetDeletedNotification = (id) => {
  return (dispatch, getState) => {
    return dispatch(baseListActions.deletedNotification(id))
  }
}

export const deleteWidget = (id) => {
  return (dispatch, getState) => {
    return dispatch(baseListActions.delete(id))
  }
}

export const copyWidget = (id) => {
  return (dispatch, getState) => {
    return dispatch(baseListActions.copy(id))
  }
}

export const fetchWidget = baseItemActions.fetch
export const updateWidget = baseItemActions.update

export const saveWidget = (id, options = {}) => {
  return async (dispatch, getState) => {
    if (!options.skipValidation) {
      const item = getWidgetById(getState(), id)
      const isValid = dispatch(validateWidget(item))
      if (!isValid) {
        return false
      }
    }

    try {
      await dispatch(
        baseItemActions.save(id, {
          $error: () => {},
        }),
      )

      return true
    } catch (err) {
      const errorResponse = err.response
      const handleProError = () => {
        if (getIsInstagramCgtWidgetWithAllPostsCoveredArea(getState(), id)) {
          handleGoProExperiment(SimpleTrialPlaceType.IG_CGT_ALL_POSTS)
          return
        }

        dispatch(
          handleGoProError(err, {
            source: UpgradeSource.ALL_POSTS,
            products: errorResponse.products,
            message: l.translate(
              "You aren't able to activate this feature on the current plan. Upgrade for unlimited access.",
            ),
          }),
        )
      }

      switch (true) {
        case errorResponse?.go_pro:
          {
            handleProError()
          }
          break
        case errorResponse?.invalid_builder:
          {
            const hash = GrowthToolsTabType.COMMENT_WELCOME
            dispatch(pageActions.setPageTab(hash))
            dispatch(builderActions.validate(GetWidgetBuilderId[WidgetTypes.FEED_COMMENT](id)))
          }
          break
        default: {
          alert(errorResponse?.error, 'danger')
        }
      }

      return false
    }
  }
}

export const forceFetchWidget = (id) => ({
  type: `WIDGETS_CURRENT_FETCH`,
  $endpoint: [`widgets.fetchItem`, { id }],
  id,
})

export const openWidget = (id) => {
  return () => {
    navigatePollyfill(linkURL(`growth-tools/widget/${id}`))
  }
}

export const openCreateWidgetPage = (options = {}) => {
  return () => {
    navigatePollyfill(linkURL('growth-tools/widget'))
  }
}

export const createEmptyWidget = () => baseItemActions.init('$new', { name: '', data: {} })

const saveWidgetStatus = (id, status, options) => {
  return async (dispatch, getState) => {
    if (options?.flowId) {
      const state = getState()

      const flow = getById(state, options.flowId)
      const isFlowPublished = flow && FlowModel.hasPublishedContent(flow)
      if (!isFlowPublished) {
        alert(l.translate('Please, publish the Automation to activate trigger.'), 'danger')
        return false
      }
    }

    const data = { id, status }

    try {
      await dispatch({
        type: atypes.WIDGETS_UPDATE_STATUS,
        $endpoint: ['widgets.saveStatus', data],
        id,
        $error: () => {},
      })

      analyticsService.sendEvent('TRIGGER.STATUS.SWITCHED', {
        isEnabled: status === WidgetStatusType.ACTIVE,
        triggerId: `${TriggerType.WIDGET}-${id}`,
      })
    } catch (error) {
      const isNextPostError = startsWith(
        error.response?.error,
        'You can activate only one next post widget',
      )
      if (isNextPostError) {
        const flowNs = error.response?.flow_ns

        alert('', 'danger', {
          content: <CgtNextPostAlert flowNs={flowNs} />,
        })
        return false
      }

      if (error.response?.instagram_cgt_not_allowed) {
        dispatch(setCGTNotAllowedModal('open'))
        return false
      }

      if (error.response?.go_pro) {
        analyticsService.sendEvent('WIDGETS.ENABLE.LIMIT_ERROR', data)

        if (error.response?.reason === ErrorGoProReasons.WIDGETS_LIMIT_REACHED) {
          handleGoProExperiment(SimpleTrialPlaceType.WIDGETS_LIMIT)
          return false
        }

        if (error.response?.reason === ErrorGoProReasons.STORY_REPLY_TRIGGER_LIMIT_REACHED) {
          handleGoProExperiment(SimpleTrialPlaceType.STORY_REPLY_TRIGGER_LIMIT)
          return false
        }

        if (getIsInstagramCgtTriggerWithAllPostsCoveredArea(getState(), options.flowId, id)) {
          handleGoProExperiment(SimpleTrialPlaceType.IG_CGT_ALL_POSTS)
          return false
        }

        billing.proAlert({
          message: error.response?.error,
          source: UpgradeSource.WIDGETS_LIMIT,
          products: [ProductType.AUTOMATION],
        })
        return false
      }

      const isUnpublishedError = startsWith(
        error.response?.errors?.[0],
        'This growth tool has unpublished content',
      )
      if (isUnpublishedError && options.isInFlowBuilder) {
        alert(l.translate('Please, publish the Automation to activate trigger.'), 'danger')
        return false
      }

      alert(error.response, 'danger')

      return false
    }

    dispatch(setCGTNotAllowedModal('close'))

    if (!options.silent) {
      if (status === WidgetStatusType.ACTIVE) {
        alert(l.translate('Trigger has been turned on'), 'success')
      } else {
        alert(l.translate('Trigger has been turned off'))
      }
    }

    if (options.redirectToCheck) {
      navigatePollyfill(linkURL(`growth-tools/widget/${id}/check`))
    }

    return true
  }
}

export const setWidgetStatus = (id, status, options = {}) => {
  return async (dispatch, getState) => {
    const state = getState()
    const widgetData = get(state.widgets, `byId.${id}`)

    analyticsService.sendEvent('GT.WIDGET.ENABLE.CLICK', { status, source: options.source })

    if (widgetData && widgetData.item) {
      // Current item, need to save it before changing status
      const widget = widgetData.item

      if (options.skipSave) {
        return dispatch(saveWidgetStatus(id, status, options))
      }

      const isValid = dispatch(validateWidgetBeforeActivation(widget, options))
      if (!isValid) {
        return
      }

      const isActivation = status === 'active'
      if (isActivation) {
        const isValid = dispatch(validateWidget(widget))
        if (!isValid) {
          return
        }
      }

      if (widgetData.hasChanges) {
        const isSaved = await dispatch(saveWidget(id, { skipValidation: true }))
        if (!isSaved) {
          return
        }
      }
      return dispatch(saveWidgetStatus(id, status, options))
    }

    // List
    return dispatch(saveWidgetStatus(id, status, options))
  }
}

export const validateWidget = (widget) => {
  return (dispatch) => {
    const errors = getWidgetErrors(widget)
    const firstError = errors[0]
    if (firstError) {
      if (firstError.tab) {
        dispatch(pageActions.setPageTab(firstError.tab))
      }
      alert(firstError.message, 'danger')
      return false
    }
    return true
  }
}

export const validateWidgetBeforeActivation = (widget, options = {}) => {
  return (dispatch, getState) => {
    const state = getState()
    const { whitelist } = state.settings
    const acc = getCurrentAccount(state)

    const hasSmsButtonText = typeof widget.data?.main?.smsButtonText !== 'undefined'

    if (
      widget.channel === ChannelType.SMS &&
      hasSmsButtonText &&
      widget.data?.main?.smsButtonText.trim() === '' &&
      widget.status !== 'active'
    ) {
      alert(l.translate('Please enter button title'), 'danger')
      return false
    }

    if (widget.status !== 'active') {
      // Open 'Upgrade to Pro' for pro widgets
      const widgetDesc = WidgetTypeConfig[widget.widget_type] || {}

      if (widgetDesc.pro && !AccountModel.isProOrTrial(acc)) {
        let source = `growth_tools_${widget.widget_type}`
        let title = widgetDesc.proModalTitle()
        billing.requestUpgrade({
          source,
          title,
          products: [ProductType.INBOX, ProductType.AUTOMATION],
        })
        return false
      }

      if (
        !options.disableWhitelistLengthCheck &&
        widget.data.main?.buttonType === 'checkbox' &&
        !whitelist.length
      ) {
        dispatch(setSetupModalVisibility(true, 'activate'))
        return false
      }

      if (widget.widget_type === WidgetTypes.FEED_COMMENT) {
        const { post_id, post_covered_area } = get(widget, 'data.feed_comment_settings', {})
        if (post_id == null && post_covered_area !== PostCoveredArea.ALL_POSTS) {
          dispatch(pageActions.setPageTab('feed_comment_settings'))
          alert(l.translate('Please select post for comment tracking'), 'danger')
          return false
        }
      }
    }

    return true
  }
}

export const updateWidgetData = (widgetId, tab, prop, value) => {
  return (dispatch, getState) => {
    const state = getState()
    const widget = state.widgets.byId[widgetId].item
    const tabData = get(widget, `data.${tab}`)

    const defaultValue = get(defaultSettings, `${widget.widget_type}.${tab}.${prop}`)
    const equalsDefault = _equalsDefault(defaultValue, value)
    const path = ['data', tab, prop]
    const tabProps = Object.keys(tabData || {})
    if (equalsDefault) {
      value = undefined
    }

    // Image upload
    if (value && typeof value === 'object' && value.preview) {
      try {
        const { uploadId } = dispatch(uploadAttachment(value, { tab, prop, widgetId }))
        value.uploadId = uploadId
      } catch (e) {
        return alert(e.message, 'danger')
      }
    }

    if (
      equalsDefault &&
      (tabProps.length === 0 || (tabProps.length === 1 && tabProps[0] === prop))
    ) {
      path.pop()
    } else if (tabData == null) {
      path.pop()
      value = { [prop]: value }
    }

    dispatch(updateWidget(widgetId, value, path, true))
  }
}

export const setFlow = (id, flowId) => {
  return async (dispatch, getState, context) => {
    try {
      await dispatch(setWidgetFlow({ widget_id: id, flow_ns: flowId }))
    } catch (err) {
      if (err.errors) {
        alert(err, 'danger')
      }
    }
  }
}

function _equalsDefault(def, value) {
  if (typeof def === 'object' && def !== null) {
    if (Array.isArray(def)) {
      return JSON.stringify(def) === JSON.stringify(value)
    }
    const v = { ...def, ...(value || {}) }
    return JSON.stringify(def) === JSON.stringify(v)
  }
  return def === value
}

export const updateFilter = (filter, sendAnalytics = false) => {
  return async (dispatch) => {
    dispatch({
      type: atypes.WIDGETS_FILTER_CHANGED,
      filter,
    })

    if (sendAnalytics) {
      analyticsService.sendEvent('GT.WIDGETS_FILTER_CHANGED')
    }
  }
}

let searchAnalyticEventTimeout = null

export const updateSearchQuery = (searchQuery, sendAnalytics = false) => {
  return async (dispatch) => {
    dispatch({
      type: atypes.WIDGETS_SEARCH_QUERY_CHANGED,
      searchQuery,
    })

    if (sendAnalytics) {
      // Send analytic event only after some delay to prevent event spamming with live search
      clearTimeout(searchAnalyticEventTimeout)

      searchAnalyticEventTimeout = setTimeout(
        analyticsService.sendEvent,
        500,
        'GT.WIDGETS_SEARCH_QUERY_CHANGED',
      )
    }
  }
}

export const updateSortingBy = (sortingBy) => ({
  type: atypes.WIDGETS_SORTING_BY_CHANGED,
  sortingBy,
})

export const updateSortingOrder = (sortingOrder) => {
  return async (dispatch) => {
    dispatch({
      type: atypes.WIDGETS_SORTING_ORDER_CHANGED,
      sortingOrder,
    })

    analyticsService.sendEvent('GT.WIDGETS_SORTING_CHANGED')
  }
}

export const fetchWidgetsList = (options = {}) => {
  return (dispatch, getState) => {
    const state = getState()
    const filter = getFilter(state)
    const searchQuery = getSearchQuery(state)
    const sortingBy = getSorting(state)
    const sortingOrder = getSortingOrder(state)

    let params = { ...options }

    if (filter?.value) {
      //TMP HACK FOR SMS WIDGET
      const { value } = filter
      params.gt_type = value
    }

    if (searchQuery) {
      params.search_query = encodeURIComponent(searchQuery)
    }

    if (sortingBy) {
      params.sort_field = sortingBy
    }

    if (sortingOrder) {
      params.sort_order = sortingOrder
    }

    return dispatch(fetchWidgets(params))
  }
}

export const dropWidgets = () => {
  return (dispatch, getState) => {
    dispatch(updateFilter(null))
    return dispatch(baseListActions.drop)
  }
}

export const setWidgetName = (name = '', widgetId) => {
  return async (dispatch) => {
    const widget_id = widgetId ? String(widgetId) : null
    dispatch(updateWidget(widget_id, name, 'name', true))

    const isSaved = await dispatch(saveWidget(widget_id))
    if (isSaved) {
      alert(l.translate('Renamed'), 'success')
    }
  }
}

export const setSetupModalVisibility = (isOpen, okAction) => ({
  type: atypes.WIDGETS_SET_SETUP_MODAL_VISIBILITY,
  isOpen,
  okAction,
})

export const widgetsMigratedNotification = () => {
  return (dispatch, getState) => {
    dispatch({
      type: appAtypes.APP_UPDATE_CURRENT_ACCOUNT,
      changes: {
        unify_widgets_migration_processing: false,
      },
    })
    dispatch(fetchWidgets({ refresh: Date.now() }))
  }
}

anotherTabNotificationsListener.on('widget_deleted', (data, dispatch) => {
  dispatch(widgetDeletedNotification(data.id))
})

anotherTabNotificationsListener.on('widget_created', (data, dispatch) => {
  dispatch(widgetCreatedNotification(data.model))
})

anotherTabNotificationsListener.on('widget_updated', (data, dispatch) => {
  dispatch(widgetUpdatedNotification(data.model))

  if (data.model.widget_id) {
    dispatch(updateWidget(data.model.widget_id, { ...data.model.data }, `data`, true))
  }
})

anotherTabNotificationsListener.on('widgets_migrated', (data, dispatch) => {
  dispatch(widgetsMigratedNotification(data.model))
})

import { useCallback, useRef } from 'react'

export const useRefCallback = <F extends (...args: TSFixMe[]) => TSFixMe>(callback: F) => {
  const ref = useRef(callback)
  ref.current = callback

  return useCallback(
    (...args: Parameters<typeof callback>) => ref.current(...args),
    [ref],
  ) as typeof callback
}

export const useReferredValue = <Value>(value: Value): React.MutableRefObject<Value> => {
  const ref = useRef(value)

  ref.current = value

  return ref
}

import { updateBuilderState } from 'common/builder/actions/updateBuilderState'

/**
 * @param builderId
 * @param isBusy
 */
export const setBusy = (builderId, isBusy) => {
  return (dispatch) => {
    dispatch(updateBuilderState(builderId, { isBusy }))
  }
}

import * as DOM from './DOM'

export { DOM }

const fakeWindow = {
  addEventListener: () => {},
  innerHeight: 0,
  innerWidth: 0,
}

export const noop = () => {}

export const pad = (s, len = 2, char = '0') =>
  (new Array(1 + len).join(char) + s).slice(Math.max(len, `${s}`.length) * -1)

export const getWindow = () => {
  if (typeof window === 'undefined') {
    return fakeWindow
  }
  return window
}

let isMobile = false
export const setIsMobile = (value) => (isMobile = value)
export const getIsMobile = () => isMobile

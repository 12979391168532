import { useContext } from 'react'

import { ThemeContext } from './context'

export const useThemeContext = () => {
  const themeContext = useContext(ThemeContext)

  if (!themeContext) {
    throw new Error('Provide Theme Context')
  }

  return themeContext
}

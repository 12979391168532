import BaseButtonConfig from 'common/builder/buttons/BaseButtonConfig'
import { IButtonBase, ICallButton } from 'common/builder/buttons/buttonInterfaces'
import { ButtonType } from 'common/builder/constants/ButtonType'

class CallButtonConfig extends BaseButtonConfig {
  buttonType = ButtonType.CALL

  getDefaults = (): Omit<ICallButton, keyof IButtonBase> => ({
    phone: '',
    is_formula: false,
  })
}

export default new CallButtonConfig()

import React from 'react'

import { TiktokWasSuccessfullyReloaded } from 'common/core/components/NotifyBar/TiktokNotifications/TiktokWasSuccessfullyReloaded'
import { TiktokWrongPageReloadPermissions } from 'common/core/components/NotifyBar/TiktokNotifications/TiktokWrongPageReloadPermissions'
import { NotificationBarType } from 'common/core/constants/NotificationBarType'

import {
  NotificationRemoveType,
  TiktokNotification,
  TiktokNotificationType,
  TiktokReadyNotification,
} from '../NotifyInterfaces'

const getRender = (message: TiktokNotification['message']): TiktokReadyNotification['render'] => {
  switch (message) {
    case TiktokNotificationType.TIKTOK_ACCOUNT_CONNECTION_WAS_SUCCESSFULLY_RELOADED:
      return TiktokWasSuccessfullyReloaded
    case TiktokNotificationType.TIKTOK_REFRESH_PERMISSIONS_FAIL_ACCOUNT_IS_NOT_BUSINESS:
    case TiktokNotificationType.TIKTOK_INSUFFICIENT_RIGHTS_TO_RELOAD_ACCOUNT:
    case TiktokNotificationType.TIKTOK_REFRESH_PERMISSIONS_FAIL_CONVERSATION_IS_UNAVAILABLE:
    case TiktokNotificationType.TIKTOK_REFRESH_PERMISSIONS_FAIL_LACK_OF_PERMISSIONS:
    case TiktokNotificationType.TIKTOK_WRONG_ACCOUNT_TO_RELOAD_PERMISSIONS:
    case TiktokNotificationType.TIKTOK_REFRESH_PERMISSIONS_FAIL_USER_REGION_NOT_ALLOWED:
    case TiktokNotificationType.TIKTOK_REFRESH_PERMISSIONS_FAIL_ACCOUNT_REGION_NOT_ALLOWED:
      return () => <TiktokWrongPageReloadPermissions messageCode={message} />
  }
}

const getMessageType = (message: TiktokNotification['message']): NotificationBarType => {
  switch (message) {
    case TiktokNotificationType.TIKTOK_ACCOUNT_CONNECTION_WAS_SUCCESSFULLY_RELOADED:
      return NotificationBarType.SUCCESS
    case TiktokNotificationType.TIKTOK_REFRESH_PERMISSIONS_FAIL_ACCOUNT_IS_NOT_BUSINESS:
    case TiktokNotificationType.TIKTOK_INSUFFICIENT_RIGHTS_TO_RELOAD_ACCOUNT:
    case TiktokNotificationType.TIKTOK_REFRESH_PERMISSIONS_FAIL_CONVERSATION_IS_UNAVAILABLE:
    case TiktokNotificationType.TIKTOK_REFRESH_PERMISSIONS_FAIL_LACK_OF_PERMISSIONS:
    case TiktokNotificationType.TIKTOK_WRONG_ACCOUNT_TO_RELOAD_PERMISSIONS:
    case TiktokNotificationType.TIKTOK_REFRESH_PERMISSIONS_FAIL_USER_REGION_NOT_ALLOWED:
    case TiktokNotificationType.TIKTOK_REFRESH_PERMISSIONS_FAIL_ACCOUNT_REGION_NOT_ALLOWED:
      return NotificationBarType.ERROR
  }
}

export const parseTiktokNotification = (
  notification: TiktokNotification,
): TiktokReadyNotification => {
  return {
    ...notification,
    type: getMessageType(notification.message),
    removeType: NotificationRemoveType.ALWAYS,
    render: getRender(notification.message),
  }
}

import BaseBlockConfig from 'common/builder/blocks/BaseBlockConfig'
import { IBlockBase, IQuestionTimeoutBlock } from 'common/builder/blocks/blockInterfaces'
import { BlockType } from 'common/builder/constants/BlockType'

class QuestionTimeoutBlockConfig extends BaseBlockConfig {
  blockType = BlockType.FORM_QUESTION_TIMEOUT

  getDefaults = (): Omit<IQuestionTimeoutBlock, keyof IBlockBase> => ({
    targetId: null,
  })
}

export default new QuestionTimeoutBlockConfig()

import { z } from 'zod'

import { BusinessErrorSchema } from 'shared/api/common/schemas/errors'
import { BusinessError } from 'shared/api/lib/errors/business'

const InvalidCouponErrorCodes = ['invalid_coupon', 'expired_coupon', 'coupon_wait'] as const
const UnsuitableCouponErrorCodes = [
  'coupon_free_only',
  'coupon_not_pro',
  'coupon_trial_or_pro',
  'coupon_region_limit',
] as const
const AlreadyUsedCouponErrorCodes = ['account_used_coupon', 'used_coupon'] as const
const CouponErrorCodes = [
  ...InvalidCouponErrorCodes,
  ...UnsuitableCouponErrorCodes,
  ...AlreadyUsedCouponErrorCodes,
  /** Legacy coupon error */
  'coupon',
] as const
const AccountErrorCodes = ['account_blocked', 'expired_account'] as const

export interface BillingCommonError extends BusinessError {
  field: 'billing_error'
}

export const CouponErrorSchema = BusinessErrorSchema.extend({
  field: z.enum(CouponErrorCodes),
})

export const AccountErrorSchema = BusinessErrorSchema.extend({
  field: z.enum(AccountErrorCodes),
})

export const InvalidEmailErrorSchema = BusinessErrorSchema.extend({
  field: z.literal('invalid_email'),
})

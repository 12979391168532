import { makeAvailableFieldsSlice } from './availableFieldsSlice'

const { reducer, actions, selectors } = makeAvailableFieldsSlice(
  (state: Pick<RootState, 'availableFields'>) => state.availableFields,
)

export const availableFieldsReducer = reducer
export const initializeAvailableFields = actions.initializeAvailableFields
export const addFields = actions.addFields

export const {
  getAllFields,
  getApplicationsFields,
  getAudienceFields,
  getConditionFields,
  getCustomFieldChangedTriggerFields,
  getDateTimeTriggerFields,
  getFieldChangedTriggerFields,
  getIntegrationsMappingFields,
  getGoogleSheetIntegrationsMappingFields,
  getLiveChatFilterFields,
  getPersonalizationFields,
  getPostTargetingFields,
  getResponseMappingFields,
  getRuleTriggerConditionFields,
  getAutoAssignmentRuleConditionFilter,
  getSetBotFieldActionFilter,
  getSetUserFieldActionFilter,
  getSetFieldActionFields,
  getSetFieldBulkActionFields,
  getSmartSegmentFields,
  getSetPhoneFields,
  getUserFields,
  getAiAgentAvailableFields,
} = selectors

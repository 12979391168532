import { z } from 'zod'

import { TeamEfficiencyChartChannel } from 'apps/analytics/constants/AnalyticsInterfaces'
import { UserType } from 'common/core/interfaces/user'
import { UserRoles } from 'common/userRoles'

enum NotificationSetting {
  EMAIL = 'email',
  SMS = 'sms',
  MESSENGER = 'messenger',
  TELEGRAM = 'telegram',
}

const emptyBackendResponse = z.tuple([])

const liveChatAdminSchema = z.object({
  assigned_threads_count: z.number(),
  avatar: z.string().nullish(),
  has_notify_email: z.boolean().nullable(),
  is_inbox_user: z.boolean(),
  is_opted_in: z.boolean(),
  is_seat_user: z.boolean(),
  mentioned_threads_count: z.number(),
  name: z.string().nullish(),
  notify_settings: z.array(z.nativeEnum(NotificationSetting)),
  threads_limit: z.number().nullable(),
  user_id: z.number(),
  user_role: z.nativeEnum(UserRoles),
  user_type: z.nativeEnum(UserType),
})

const totalAnalytics = z.object({
  assigned: z.number().optional(),
  done: z.number().optional(),
})

const teamEfficiencyDateEntryRecord = z.record(
  z.nativeEnum(TeamEfficiencyChartChannel),
  z.object({
    conversations_count: z.number().nullable(),
    time: z.number().nullable(),
  }),
)

const responseRateDateEntryRecord = z.record(
  z.nativeEnum(TeamEfficiencyChartChannel),
  z.object({
    new: z.number().nullable(),
    answered: z.number().nullable(),
    response_rate: z.number().nullable(),
  }),
)

const baseStatsRequestSchema = z.object({
  period_start: z.string(),
  period_end: z.string(),
})

const conversationRequestSchema = baseStatsRequestSchema.extend({
  agent_ids: z.array(z.number()),
})

const teamEfficiencyRequestSchema = baseStatsRequestSchema.extend({
  agent_id: z.number().nullable(),
})

const getTotalConversationsChartStatsResponseSchema = z.object({
  date_range: z.record(z.string(), totalAnalytics).or(emptyBackendResponse),
  total: totalAnalytics,
})

const getConversationsByTeamMembersChartStatsResponseSchema = z.object({
  agents: z.array(totalAnalytics.extend({ id: z.number() })),
  total: totalAnalytics,
})

const getAverageTimeResponseSchema = z.object({
  max_values: z.record(z.nativeEnum(TeamEfficiencyChartChannel), z.number().nullable()),
  date_range: z.record(z.string(), teamEfficiencyDateEntryRecord).or(emptyBackendResponse),
})

const getResponseRateResponseSchema = z.object({
  max_values: z.record(z.nativeEnum(TeamEfficiencyChartChannel), z.number().nullable()),
  date_range: z.record(z.string(), responseRateDateEntryRecord).or(emptyBackendResponse),
})

export const LiveChatStatisticsSchemas = {
  getAdmins: {
    path: z.undefined(),
    query: z.undefined(),
    request: z.undefined(),
    response: z.object({
      admins: z.array(liveChatAdminSchema),
    }),
  },
  getTotalConversationsChartStats: {
    path: z.undefined(),
    query: z.undefined(),
    request: conversationRequestSchema,
    response: z.object({
      data: getTotalConversationsChartStatsResponseSchema.or(emptyBackendResponse),
    }),
  },
  getConversationsByTeamMembersChartStats: {
    path: z.undefined(),
    query: z.undefined(),
    request: conversationRequestSchema,
    response: z.object({
      data: getConversationsByTeamMembersChartStatsResponseSchema.or(emptyBackendResponse),
    }),
  },
  getAverageResponseTime: {
    path: z.undefined(),
    query: z.undefined(),
    request: teamEfficiencyRequestSchema,
    response: z.object({
      data: getAverageTimeResponseSchema.or(emptyBackendResponse),
    }),
  },
  getAverageClosureTime: {
    path: z.undefined(),
    query: z.undefined(),
    request: teamEfficiencyRequestSchema,
    response: z.object({
      data: getAverageTimeResponseSchema.or(emptyBackendResponse),
    }),
  },
  getResponseRate: {
    path: z.undefined(),
    query: z.undefined(),
    request: baseStatsRequestSchema,
    response: z.object({
      data: getResponseRateResponseSchema.or(emptyBackendResponse),
    }),
  },
}

export type LiveChatAdmin = z.infer<typeof liveChatAdminSchema>
export type ConversationRequest = z.infer<typeof conversationRequestSchema>
export type TeamEfficiencyRequest = z.infer<typeof teamEfficiencyRequestSchema>
export type ResponseRateRequest = z.infer<typeof baseStatsRequestSchema>

export type GetTotalConversationsChartStatsResponse = z.infer<
  typeof getTotalConversationsChartStatsResponseSchema
>
export type GetConversationsByTeamMembersChartStatsResponse = z.infer<
  typeof getConversationsByTeamMembersChartStatsResponseSchema
>
export type GetAverageTimeResponse = z.infer<typeof getAverageTimeResponseSchema>
export type GetResponseRateResponse = z.infer<typeof getResponseRateResponseSchema>

import { parseDate } from '../date'

import { LocalizationConfig } from './config'
import { getCurrencyCode } from './currency'
import { Translate, TranslateData } from './types'

export const getLocale = (): string => {
  return LocalizationConfig.locale
}

export const number = (value: number | bigint, maximumFractionDigits = 0) => {
  return Intl.NumberFormat(getLocale(), { maximumFractionDigits }).format(value)
}

export { getCurrencyCode }

export const currency = (
  value: number,
  currencyNumberOrCode: string | number,
  maximumFractionDigits?: number,
): string => {
  const options: Intl.NumberFormatOptions = {
    style: 'currency',
    currency: getCurrencyCode(currencyNumberOrCode),
    maximumFractionDigits,
  }
  if (value === 0 || maximumFractionDigits === 0) {
    options.minimumFractionDigits = 0
  }
  if (maximumFractionDigits || maximumFractionDigits === 0) {
    options.maximumFractionDigits = maximumFractionDigits
  }

  try {
    return Intl.NumberFormat(getLocale(), options).format(value)
  } catch (error) {
    return value.toString()
  }
}

export const percent = (value: number, maximumFractionDigits = 1) => {
  const options = { style: 'percent', maximumFractionDigits }
  return Intl.NumberFormat(getLocale(), options).format(value / 100)
}

export const year = (value: Date) => {
  return value.toLocaleString(getLocale(), { year: 'numeric' })
}

export const month = (value: Date) => {
  return value.toLocaleString(getLocale(), { month: 'long' })
}

export const monthShort = (value: Date) => {
  return value.toLocaleString(getLocale(), { month: 'short' })
}

export const weekday = (value: Date) => {
  return value.toLocaleString(getLocale(), { weekday: 'long' })
}

export const weekdayShort = (value: Date) => {
  return value.toLocaleString(getLocale(), { weekday: 'short' })
}

const parseAndFormatDate = (value: Date | string | number, options: Intl.DateTimeFormatOptions) => {
  const parsedDate = parseDate(value)
  if (parsedDate === null) {
    return ''
  }
  try {
    return parsedDate.toLocaleString(getLocale(), options)
  } catch (err) {
    return parsedDate.toString()
  }
}

export const date = (value: Date | string | number, omitYear = false, omitMonth = false) => {
  return parseAndFormatDate(value, {
    day: 'numeric',
    month: omitMonth ? undefined : 'long',
    year: omitYear ? undefined : 'numeric',
  })
}

const MINUTE_SECONDS = 60
const HOUR_SECONDS = 60 * MINUTE_SECONDS
const DAY_SECONDS = 24 * HOUR_SECONDS
const WEEK_SECONDS = 7 * DAY_SECONDS
const MONTH_SECONDS = 4.34524 * WEEK_SECONDS
const YEAR_SECONDS = 12 * MONTH_SECONDS

const compactDateUnits = {
  seconds: 's',
  minutes: 'm',
  hours: 'h',
  days: 'd',
  weeks: 'w',
  months: 'mo',
  years: 'y',
}

const compactFormatter = (duration: number, unit: Intl.RelativeTimeFormatUnit) => {
  const compactUnit = compactDateUnits[unit as keyof typeof compactDateUnits]
  return `${Math.abs(duration)}${compactUnit}`
}

export const dateFromNow = (value: Date | string | number, isCompact?: boolean): string => {
  const relativeDate = parseDate(value) || new Date()
  const seconds = Math.round((Date.now() - relativeDate.getTime()) / 1000)
  let formatter: { format: (duration: number, unit: Intl.RelativeTimeFormatUnit) => string }
  try {
    formatter = new Intl.RelativeTimeFormat(getLocale())

    if (isCompact) {
      formatter = {
        format: compactFormatter,
      }
    }
  } catch (error) {
    return date(value)
  }
  const format = (duration: number, unit: Intl.RelativeTimeFormatUnit) =>
    formatter.format(Math.round(-duration), unit)

  if (seconds < MINUTE_SECONDS) return format(seconds, 'seconds')
  if (seconds < HOUR_SECONDS) return format(seconds / MINUTE_SECONDS, 'minutes')
  if (seconds < DAY_SECONDS) return format(seconds / HOUR_SECONDS, 'hours')
  if (seconds < WEEK_SECONDS) return format(seconds / DAY_SECONDS, 'days')
  if (seconds < MONTH_SECONDS) return format(seconds / WEEK_SECONDS, 'weeks')
  if (seconds < YEAR_SECONDS) return format(seconds / MONTH_SECONDS, 'months')
  return format(seconds / YEAR_SECONDS, 'years')
}

export const dateTime = (value: Date | string | number) => {
  return parseAndFormatDate(value, {
    month: 'long',
    day: 'numeric',
    year: 'numeric',
    hour: '2-digit',
    minute: '2-digit',
    timeZoneName: 'short',
  })
}

export const translate: Translate = (message, data) => {
  return LocalizationConfig.translate(message, data)
}

export interface MakeTranslateReturnValue {
  (data?: TranslateData): string
  message: string
}

export const makeTranslate = (
  message: string,
  savedData?: TranslateData,
): MakeTranslateReturnValue => {
  const translateValue = (data?: TranslateData) => translate(message, data || savedData)
  translateValue.message = message
  return translateValue
}

export type TextSource = string | null | undefined | (() => string)

export const getString = (text: TextSource, fallback = ''): string => {
  if (!text) {
    return fallback
  }
  return typeof text === 'function' ? text() : text
}

export const getAllowedLanguages = () => LocalizationConfig.allowedLanguages

export const getLanguage = () => LocalizationConfig.language

export const getLanguageName = (languageOrLocale: string) => {
  const language = languageOrLocale.split('-')[0]
  try {
    const languageNames = new Intl.DisplayNames([language], { type: 'language' })
    return languageNames.of(language)
  } catch {}
  return languageOrLocale
}

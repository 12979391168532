import { ComponentType } from 'react'
import { Icon } from '@manychat/manyui'

export interface CmsTabType {
  label: string
  tab: CmsTab
  icon: Icon.Icon
  append?: ComponentType
}

export enum CmsTab {
  AUTOMATIONS = 'automations',
  BASIC = 'basic',
  KEYWORDS = 'keywords',
  SEQUENCES = 'sequences',
  RULES = 'rules',
}

export enum FlowCardClickAction {
  OPEN_FLOW = 'open_flow',
  SAVE_FLOW_ID_TO_STATE = 'save_flow_id_to_state',
}

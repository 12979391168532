import React, { forwardRef } from 'react'

import { ErrorBoundary } from 'common/core/components/ErrorBoundary/ErrorBoundary'
import { ErrorBoundaryProps } from 'common/core/components/ErrorBoundary/ErrorBoundaryProps'
import { CodebaseScope } from 'utils/services/errorTrackingService/aggregation'

export function withErrorBoundary<Props extends UnsafeAnyObject, Scope extends CodebaseScope, Ref>(
  Component: React.ComponentType<Props & React.RefAttributes<Ref>>,
  errorBoundaryProps: Omit<ErrorBoundaryProps<Scope>, 'children'>,
) {
  const componentDisplayName = Component.displayName || Component.name || 'unknown'

  const Wrapped = forwardRef<Ref, Props>((props, ref) => (
    <ErrorBoundary {...errorBoundaryProps}>
      <Component {...props} ref={ref} />
    </ErrorBoundary>
  ))

  Wrapped.displayName = `errorBoundary(${componentDisplayName})`

  return Wrapped
}

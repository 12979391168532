import { alert } from 'common/core'
import { initializeUsercentricsListeners } from 'utils/getInfoAboutService'
import { HotjarService } from 'utils/services/hotjar/HotjarService'

const hotjarService = new HotjarService()
initializeUsercentricsListeners(hotjarService.refreshPermissions)

export const alertWithHotjarEventFactory =
  (event: string) =>
  (...args: Parameters<typeof alert>) => {
    hotjarService.hotjarEvent(event)
    alert(...args)
  }

export default hotjarService

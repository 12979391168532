import { WizardSteps } from 'apps/applicationInstallation/constants'
import {
  IEntities,
  IGroupSettingsItem,
  ISettingsItem,
} from 'common/settings/components/SettingsPanel/AppSection/installedApplications/interfaces'

export interface IApplicationAction {
  name: string
  title: string
  description: string
}

export enum ApplicationStatus {
  ACTIVE = 1,
  DEPRECATED = 2,
  REVIEW_WAITING = 10,
  REVIEW_IN_PROGRESS = 11,
  REVIEW_DECLINE = 12,
}

export interface IApplicationsListItem {
  app_id: number
  versions: IApplicationVersion[]
}
export interface IApplicationVersion {
  app_id: number
  hash?: string
  status: ApplicationStatus
  title: string
  version: number
  api_scope?: string[]
}

export interface IInstalledApplication {
  app_id: number
  title: string
  version: number
  avatar?: IAppAvatar
  description?: string
  status: ApplicationStatus
  actions?: IApplicationAction[]
  api_scope: string[]
}

export interface IAppAvatar {
  url: string | null
  color: string
}

export interface IApplicationInstallation extends IInstalledApplication {
  version: number
  hash: string
  description: string
  hasSettings: boolean
}

export interface IApplicationSettings {
  name: string
  title: string
  value: string | number | null
}

export interface IUpdateInstalledApplication {
  applicationId: number | null
  changelog: IChangeLogState
  stage: UpdateStage
  updateFetching: boolean
}

export interface IChangeLogState {
  changelogs: IChangeLogRecord[]
  requestId: number | null
}

export interface IChangeLogRecord {
  version: number
  changelog: string
}

export enum UpdateStage {
  Changelog = 'changelog',
  UsesList = 'usesList',
}

export interface IApplicationsState {
  list: {
    items: IApplicationsListItem[]
    fetching: boolean
  }
  current: ICurrentApplication
  publish: ISelectedApplicationState
  installation: IApplicationInstallationState
  reviewReport: IApplicationReviewReportState
  updateApplication: IUpdateInstalledApplication
  usesList: IUsesListState
  settings: {
    update: { fetching: boolean; status: 'success' | 'error' | 'unknown' }
    data: {
      appKey: string
      deleteGroupId?: string
      fetching: boolean
      groupSettings: IGroupSettingsItem[]
      hasRequest: boolean
      settings: ISettingsItem[]
    }
  }
}

export interface ICurrentApplication {
  item: IDevApplication | null
  fetching: boolean
  error: boolean | null
  updating?: boolean
  accounts?: []
  errors?: IConfigError[]
}

export interface IDevApplication {
  title: string
  description: string
  config: string
  avatar: IAppAvatar
  api_scope: string[]
}

export interface IUsesListState {
  requestId: number | null
  applicationId: number | null
  entities: IEntities
}

export interface ISelectedApplicationState {
  applicationId: number | null
  fetching: boolean
}

export interface IApplicationReviewReportState extends ISelectedApplicationState {
  report: string
}

export interface IApplicationInstallationState {
  application: {
    application: IApplicationInstallation | null
    isFetching: boolean
    applicationIdOrHash: string
  }
  accounts: {
    items: IApplicationAccount[]
    selectedId: string | null
    isFetching: boolean
  }
  step: WizardSteps
}

export interface IApplicationAccount {
  account_id: AccountId
  account_name: string
  app_status: number
  avatar_url: string
  pro_status: boolean
}

export interface IApplicationTrigger {
  name: string
  title: string
  description: string
}

export interface IApplicationTriggers {
  app_id: number
  title: string
  triggers: IApplicationTrigger[]
}

export enum SelectedActionSource {
  ACTION_BUILDER = 'actionBuilder',
  ACTION_ITEM = 'actionItem',
}

// Flow builder
export interface IActionNodeApplicationState {
  selectedApplicationId: number | null
  selectedItemOid: string | null
  selectedAction: IActionContent | null
  selectedActionName: string | null
  selectedActionSource: SelectedActionSource | null
  actionFetching: boolean
  actionFields: IActionField[]
  actionMapping: IActionField[]
  actionSources: ActionSources
  triggers: IApplicationTriggers[]
}

export interface IActiveCampaignIntegration {
  isFetching: boolean
  isDisconnecting: boolean
  selectedList: number
  identityField: {
    value: string
    option: {
      value: string
      segment_type: string
    }
  }
  hasEmptyField: boolean
  lists: [
    {
      id: number
      name: string
      stringid: string
      isDefault: boolean
    },
  ]
}

export interface IActionContent {
  name: string
  title: string
  description?: string
  guidance?: string
  forms: IActionForm[]
  requests: IActionRequest[]
  innerFields: string[]
}
export interface IActionForm {
  name: string
  title: string
  type: 'number' | 'string' | 'select'
  source?: string
  default?: string | number
}
export interface IActionSourceItem {
  title: string
  value: string
}

export interface ActionSourceSupOptionType {
  label: string
  value: string
}

export interface ActionSourceOptionType {
  fetching: boolean
  reset?: boolean
  items: IActionSourceItem[]
}

export type ActionSources = Record<string, ActionSourceOptionType>

export interface IActionRequest {
  mapping: IActionMapping[]
}

interface IActionMapping {
  name: string
  title: string
  type: string
}

// Action Node Interfaces
export interface IApplicationData {
  fields: IActionField[]
  mapping: IActionDataMapping[]
}
export interface IActionField {
  title: string
  field_name: string
  displayType?: 'input' | 'select'
  value?: IActionFieldValue
  default?: string | number
  isValid?: boolean
}

export interface IActionDataMapping {
  field_name: string
  title: string
  value?: {
    type: string
    field_id?: number
  }
}

export interface IActionFieldValue {
  type: string
  field_name?: string | number
  field_id?: number
  value?: string | number
  valueType?: string
}

export interface IConfigError {
  message: string
  path: string
  value: boolean
}

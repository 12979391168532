import { z } from 'zod'

import { FieldStatuses } from 'common/fields/constants/FieldType'
import UserFieldsTypes from 'common/fields/constants/UserFieldsTypes'
import { FieldSegmentType } from 'common/fields/entities/enums'
import { usedEntitiesListSchema } from 'shared/api/requests/cms/schemas/usedEntitiesList'

const GlobalFieldIdSchema = z.number()
const GlobalFieldValueSchema = z.union([z.string(), z.number(), z.boolean()]).nullable()

const NewGlobalFieldSchema = z.object({
  caption: z.string(),
  type: z.nativeEnum(UserFieldsTypes),
  description: z.string(),
  value: GlobalFieldValueSchema,
  path: z.string(),
})

const GlobalFieldSchema = z.object({
  field_id: GlobalFieldIdSchema,
  type: z.nativeEnum(UserFieldsTypes),
  segment_type: z.nativeEnum(FieldSegmentType),
  caption: z.string(),
  description: z.string().nullable(),
  value: GlobalFieldValueSchema,
  status: z.nativeEnum(FieldStatuses),
  is_template_field: z.boolean(),
  template_installation_id: z.number().nullable(),
  folder_id: z.number(),
})

export const GlobalFieldsSchemas = {
  getList: {
    response: z.object({
      fields: z.array(GlobalFieldSchema),
    }),
    query: z.object({
      active_only: z.boolean(),
      folderId: z.number().optional(),
      path: z.string().optional(),
      query: z.string().optional(),
    }),
    path: z.undefined(),
  },
  update: {
    request: GlobalFieldSchema,
    response: z.object({
      field: GlobalFieldSchema,
    }),
    query: z.undefined(),
    path: z.undefined(),
  },
  archive: {
    request: z.object({
      field_id: GlobalFieldIdSchema,
    }),
    response: z.object({
      field: GlobalFieldSchema,
    }),
    query: z.undefined(),
    path: z.undefined(),
  },
  restore: {
    request: z.object({
      field_id: GlobalFieldIdSchema,
    }),
    response: z.object({
      field: GlobalFieldSchema,
    }),
    query: z.undefined(),
    path: z.undefined(),
  },
  delete: {
    request: z.object({
      field_id: GlobalFieldIdSchema,
    }),
    response: z.undefined(),
    query: z.undefined(),
    path: z.undefined(),
  },
  create: {
    request: NewGlobalFieldSchema,
    response: z.object({
      field: GlobalFieldSchema,
    }),
    query: z
      .object({
        client_id: z.string(),
      })
      .optional(),
    path: z.undefined(),
  },
  changeValue: {
    request: z.object({
      field_id: GlobalFieldIdSchema,
      value: GlobalFieldValueSchema,
    }),
    response: z.object({
      field: GlobalFieldSchema,
    }),
    query: z.undefined(),
    path: z.undefined(),
  },
  archiveBulk: {
    request: z.object({
      ids: z.array(GlobalFieldIdSchema),
    }),
    response: z.object({
      models: z.array(GlobalFieldSchema),
    }),
    query: z.undefined(),
    path: z.undefined(),
  },
  deleteBulk: {
    request: z.object({
      ids: z.array(GlobalFieldIdSchema),
    }),
    response: z.object({
      models: z.array(GlobalFieldSchema),
    }),
    query: z.undefined(),
    path: z.undefined(),
  },
  restoreBulk: {
    request: z.object({
      ids: z.array(GlobalFieldIdSchema),
    }),
    response: z.object({
      models: z.array(GlobalFieldSchema),
    }),
    query: z.undefined(),
    path: z.undefined(),
  },
  getUsesList: {
    response: z.object({
      entities: usedEntitiesListSchema,
    }),
    query: z.object({
      field_id: GlobalFieldIdSchema,
    }),
    path: z.undefined(),
  },
  moveToFolder: {
    response: z.object({
      field: GlobalFieldSchema,
    }),
    request: z.object({
      field_id: GlobalFieldIdSchema,
      folder_id: z.number(),
    }),
    query: z.undefined(),
    path: z.undefined(),
  },
  moveToFolderBulk: {
    response: z.object({
      models: z.array(GlobalFieldSchema),
    }),
    request: z.object({
      ids: z.array(GlobalFieldIdSchema),
      folder_id: z.number(),
    }),
    query: z.undefined(),
    path: z.undefined(),
  },
  getFolderContentCount: {
    response: z.object({
      fields_count: z.number(),
    }),
    query: z
      .object({
        path: z.string().optional(),
        active_only: z.string().optional(),
      })
      .optional(),
    path: z.undefined(),
  },
}

export type NewGlobalField = z.infer<typeof NewGlobalFieldSchema>
export type GlobalField = z.infer<typeof GlobalFieldSchema>

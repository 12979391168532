import keyBy from 'lodash/keyBy'

import * as atypes from 'apps/ads/constants/AdsReduxActionTypes'
import { parseAccount } from 'apps/ads/models/AdData/adapter'

const initialState = {
  byId: {},
  fetched: false,
  loading: false,
}

export default function accountReducer(state = initialState, action) {
  switch (action.type) {
    case atypes.AD_FETCH_ACCOUNTS_REQUEST: {
      return { ...state, loading: true }
    }
    case atypes.AD_FETCH_ACCOUNTS_RESPONSE: {
      const accounts = action.data.data.map(parseAccount)
      const byId = keyBy(accounts, 'id')
      return {
        ...state,
        byId,
        fetched: true,
        loading: false,
      }
    }
    case atypes.AD_FETCH_ACCOUNTS_ERROR: {
      return { ...state, loading: false }
    }
  }

  return state
}

import { createSelector } from '@reduxjs/toolkit'
import getSymbolFromCurrency from 'currency-symbol-map'

import {
  AccountUserDisplayFlagType,
  UserDisplayFlagType,
} from 'apps/dashboard/constants/DisplayFlagType'
import { getUser, getCurrentAccountUser } from 'common/core/selectors/appSelectors'
import { getCurrencyCode } from 'utils/formatCurrency'

const getDashboard = (state: RootState) => state.dashboard

export const getStats = (state: RootState) => state.dashboard.stats

export const getEarnedInfo = createSelector(getDashboard, (dashboard) => {
  return {
    totalEarnedList: dashboard.totalEarnedList,
    ARPPUList: dashboard.ARPPUList,
    APCList: dashboard.APCList,
    buyersCountList: dashboard.buyersCountList,
    salesCountList: dashboard.salesCountList,
  }
})

export const getEarnedStats = createSelector(
  getEarnedInfo,
  ({ totalEarnedList, ARPPUList, APCList, buyersCountList, salesCountList }) =>
    Object.keys(totalEarnedList).map((id) => ({
      id,
      earned: totalEarnedList[id],
      ARPPU: ARPPUList[id],
      APC: APCList[id],
      buyers: buyersCountList[id],
      sales: salesCountList[id],
      sign: getSymbolFromCurrency(getCurrencyCode(id)),
    })),
)

export const getDangerFlowsStats = (state: RootState) => {
  return state.dashboard.dangerFlows.stats
}

export const getDangerFlowsListData = (state: RootState) => {
  return state.dashboard.dangerFlows.flowListData
}

export const getDangerFlowsListEnabled = (state: RootState) => {
  return state.dashboard.dangerFlows.isShow
}

export const getIsDisplayFlagEnabled = (state: RootState, flagId: UserDisplayFlagType) => {
  const user = getUser(state)
  return Boolean(user.interface_data?.display_flags?.[flagId])
}

export const getIsCurrentAccountUserDisplayFlagEnabled = (
  state: RootState,
  flagId: AccountUserDisplayFlagType,
) => {
  const currentAccountUser = getCurrentAccountUser(state)
  return Boolean(currentAccountUser.interface_data?.display_flags?.[flagId])
}

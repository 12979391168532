import React, { useCallback } from 'react'
import cx from 'classnames'
import { useAppSelector, useAppDispatch } from 'reduxTyped'
import { CheckboxV2, l } from '@manychat/manyui'

import { ListSortButton } from 'apps/cms/components/Flows/components/ListHeading/components/ListSortButton/ListSortButton'
import { CmsAnalytics } from 'apps/cms/lib/analytics'
import { FsQueryOrderField, FsQueryOrderType } from 'apps/cms/lib/constants'
import { contentManagementSelectors, contentManagementActions } from 'apps/cms/store'
import { usePermission, PermissionTargets } from 'common/userRoles'

import cm from './ListHeading.module.css'

export const ListHeading = React.memo(function ListHeading() {
  const dispatch = useAppDispatch()

  const userCanEditFlow = usePermission(PermissionTargets.FLOW_EDITING)
  const { showBulkActions } = useAppSelector(contentManagementSelectors.getUi)
  const allowSelectFlows = showBulkActions && userCanEditFlow
  const isAllFlowsSelected = useAppSelector(contentManagementSelectors.getIsAllFlowsSelected)
  const selectedFlows = useAppSelector(contentManagementSelectors.getSelectedFlowIds)

  const { order, field } = useAppSelector(contentManagementSelectors.getQuery)

  const handleSortClick = (field: FsQueryOrderField) => {
    const newOrder = order === FsQueryOrderType.ASC ? FsQueryOrderType.DESC : FsQueryOrderType.ASC

    CmsAnalytics.logSortFlows({ field, order: newOrder })

    dispatch(
      contentManagementActions.updateQuery({
        field,
        order: newOrder,
      }),
    )
  }

  const toggleFlowsSelection = useCallback(() => {
    dispatch(contentManagementActions.toggleFlowsSelection())
  }, [dispatch])

  return (
    <div className={cm.listHeading}>
      {allowSelectFlows && (
        <div className={cm.listHeading__checkbox}>
          <CheckboxV2
            indeterminate={selectedFlows.length > 0 && !isAllFlowsSelected}
            checked={isAllFlowsSelected}
            onChange={toggleFlowsSelection}
            ariaLabel={l.translate('Select flow')}
          />
        </div>
      )}

      <div className={cm.listHeading__content}>
        <div className="text-sm">
          <ListSortButton
            isSelectedField={field === FsQueryOrderField.NAME}
            onClick={() => handleSortClick(FsQueryOrderField.NAME)}
            order={order}
          >
            {l.translate('Name')}
          </ListSortButton>
        </div>
        <div className={cm.listHeading__emptyColumn} />
        <div className={cx('text-sm text-right text-ellipsis', cm.listHeading__statisticsColumn)}>
          <span
            data-title={l.translate('Number of users who successfully completed the Automation')}
          >
            {l.translate('Runs')}
          </span>
        </div>
        <div className={cx('text-sm text-right text-ellipsis', cm.listHeading__statisticsColumn)}>
          <span
            data-title={l.translate(
              'Percentage of users clicking the button in the initial message',
            )}
          >
            CTR
          </span>
        </div>
        <div className="text-sm">
          <ListSortButton
            isSelectedField={field === FsQueryOrderField.MODIFIED}
            onClick={() => handleSortClick(FsQueryOrderField.MODIFIED)}
            order={order}
          >
            {l.translate('Modified')}
          </ListSortButton>
        </div>
      </div>
    </div>
  )
})

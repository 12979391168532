import { DEFAULT_IMG_PROPS } from './constants'

export const toBase64 = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.onload = (e) => resolve(e.target.result)
    reader.onerror = (e) => reject(e)
    reader.readAsDataURL(file)
  })

export const uploadImageAsBase64 = (url) => {
  return new Promise((resolve, reject) => {
    let xhr = new XMLHttpRequest()
    xhr.open('GET', url, true)

    xhr.responseType = 'arraybuffer'

    xhr.onload = function (e) {
      if (this.status === 200) {
        let uInt8Array = new Uint8Array(this.response)
        let i = uInt8Array.length
        let binaryString = new Array(i)
        while (i--) {
          binaryString[i] = String.fromCharCode(uInt8Array[i])
        }
        let data = binaryString.join('')

        let base64 = `data:image/png;base64, ${window.btoa(data)}`

        resolve(base64)
      }
    }
    xhr.send()
  })
}

export function isSupportPath2D() {
  try {
    new Path2D().addPath(new Path2D())
  } catch (e) {
    return false
  }
  return true
}

export function drawQrOnCanvas(
  qrcode,
  canvas,
  size,
  margin,
  bgColor,
  fgColor,
  imgProps = {},
  removeLogoBackground,
) {
  if (!canvas) {
    return
  }

  return new Promise((resolve, reject) => {
    const ctx = canvas.getContext('2d')
    if (!ctx) {
      return reject(new Error('canvas.getContext("2d") failed'))
    }

    const cells = qrcode.modules
    if (cells === null) {
      return reject(new Error('qrcode.modules is null'))
    }

    const numCells = cells.length + margin * 2
    const pixelRatio = window.devicePixelRatio || 1
    canvas.height = canvas.width = size * pixelRatio
    const scale = (size / numCells) * pixelRatio
    ctx.scale(scale, scale)

    ctx.fillStyle = bgColor
    ctx.fillRect(0, 0, numCells, numCells)

    ctx.fillStyle = fgColor
    if (isSupportPath2D) {
      ctx.fill(new Path2D(generatePath(cells, margin)))
    } else {
      cells.forEach(function (row, rdx) {
        row.forEach(function (cell, cdx) {
          if (cell) {
            ctx.fillRect(cdx + margin, rdx + margin, 1, 1)
          }
        })
      })
    }

    ctx.scale(1 / scale, 1 / scale)

    if (imgProps && imgProps.src !== null) {
      const { src, left, top, width, height } = { ...DEFAULT_IMG_PROPS, ...imgProps }
      const { width: cWidth, height: cHeight } = canvas
      const img = new Image()
      img.src = src

      img.addEventListener('error', reject)
      img.addEventListener('load', () => {
        const dWidth = (cWidth * width) / 100
        const dHeight = (cHeight * height) / 100
        const dx = (cWidth * left) / 100 - dWidth / 2
        const dy = (cHeight * top) / 100 - dHeight / 2

        if (removeLogoBackground !== true) {
          drawRect(ctx, cWidth, cHeight, dWidth, dHeight)
        }

        ctx.drawImage(img, dx, dy, dWidth, dHeight)
      })

      resolve(canvas)
    } else {
      resolve(canvas)
    }
  })
}

export function generatePath(modules, margin = 0) {
  const ops = []
  modules.forEach(function (row, y) {
    let start = null
    row.forEach(function (cell, x) {
      if (!cell && start !== null) {
        // M0 0h7v1H0z injects the space with the move and drops the comma,
        // saving a char per operation
        ops.push(`M${start + margin} ${y + margin}h${x - start}v1H${start + margin}z`)
        start = null
        return
      }

      // end of row, clean up or skip
      if (x === row.length - 1) {
        if (!cell) {
          // We would have closed the op above already so this can only mean
          // 2+ light modules in a row.
          return
        }
        if (start === null) {
          // Just a single dark module.
          ops.push(`M${x + margin},${y + margin} h1v1H${x + margin}z`)
        } else {
          // Otherwise finish the current line.
          ops.push(`M${start + margin},${y + margin} h${x + 1 - start}v1H${start + margin}z`)
        }
        return
      }

      if (cell && start === null) {
        start = x
      }
    })
  })
  return ops.join('')
}

// Convert from UTF-16, forcing the use of byte-mode encoding in our QR Code.
// This allows us to encode Hanji, Kanji, emoji, etc. Ideally we'd do more
// detection and not resort to byte-mode if possible, but we're trading off
// a smaller library for a smaller amount of data we can potentially encode.
// Based on http://jonisalonen.com/2012/from-utf-16-to-utf-8-in-javascript/
export function convertStr(str) {
  let out = ''
  for (let i = 0; i < str.length; i++) {
    let charcode = str.charCodeAt(i)
    if (charcode < 0x0080) {
      out += String.fromCharCode(charcode)
    } else if (charcode < 0x0800) {
      out += String.fromCharCode(0xc0 | (charcode >> 6))
      out += String.fromCharCode(0x80 | (charcode & 0x3f))
    } else if (charcode < 0xd800 || charcode >= 0xe000) {
      out += String.fromCharCode(0xe0 | (charcode >> 12))
      out += String.fromCharCode(0x80 | ((charcode >> 6) & 0x3f))
      out += String.fromCharCode(0x80 | (charcode & 0x3f))
    } else {
      // This is a surrogate pair, so we'll reconsitute the pieces and work
      // from that
      i++
      charcode = 0x10000 + (((charcode & 0x3ff) << 10) | (str.charCodeAt(i) & 0x3ff))
      out += String.fromCharCode(0xf0 | (charcode >> 18))
      out += String.fromCharCode(0x80 | ((charcode >> 12) & 0x3f))
      out += String.fromCharCode(0x80 | ((charcode >> 6) & 0x3f))
      out += String.fromCharCode(0x80 | (charcode & 0x3f))
    }
  }
  return out
}

function drawRect(ctx, cWidth, cHeight, width, height) {
  const padding = 20
  ctx.beginPath()
  ctx.fillStyle = '#ffffff'
  ctx.fillRect(
    (cWidth - width - padding) / 2,
    (cHeight - height - padding) / 2,
    width + padding,
    height + padding,
  )
  ctx.stroke()
}

let styleInited = false
const freezeIDs = {}

const DISABLE_FREEZING = true

function getScrollbarWidth() {
  const outer = document.createElement('div')
  outer.style.visibility = 'hidden'
  outer.style.width = '100px'
  outer.style.msOverflowStyle = 'scrollbar' // needed for WinJS apps

  document.body.appendChild(outer)

  const widthNoScroll = outer.offsetWidth
  // force scrollbars
  outer.style.overflow = 'scroll'

  // add innerdiv
  const inner = document.createElement('div')
  inner.style.width = '100%'
  outer.appendChild(inner)

  const widthWithScroll = inner.offsetWidth

  // remove divs
  outer.parentNode.removeChild(outer)

  return widthNoScroll - widthWithScroll
}

function init() {
  const scw = getScrollbarWidth()
  const head = document.head || document.getElementsByTagName('head')[0]
  const style = document.createElement('style')
  style.type = 'text/css'
  const css = `._frozen{overflow:hidden;padding-right:${scw}px}`
  if (style.styleSheet) {
    style.styleSheet.cssText = css
  } else {
    style.appendChild(document.createTextNode(css))
  }
  head.appendChild(style)

  styleInited = true
}

function freeze(isFrozen) {
  if (DISABLE_FREEZING) {
    return
  }
  document.body.classList.toggle('_frozen', isFrozen)
  fixIntercom(isFrozen)
  fixNotifications(isFrozen)
}

function fixIntercom(isFrozen) {
  const el = document.querySelector('#intercom-container .intercom-launcher-frame')
  if (!el || !el.style.setProperty) {
    return
  }

  const value = isFrozen ? `${20 + getScrollbarWidth()}px` : '20px'
  el.style.setProperty('right', value, 'important')
}

function fixNotifications(isFrozen) {
  const el = document.querySelector('#mc-notification-controller')
  if (!el || !el.style.setProperty) {
    return
  }

  const value = isFrozen ? `${10 + getScrollbarWidth()}px` : '10px'
  el.style.setProperty('right', value, 'important')
}

export const scrollbarWidth = getScrollbarWidth()

export const isBodyFrozen = () => Object.keys(freezeIDs).length > 0

export default (isFrozen = true, id) => {
  if (!styleInited) {
    init()
  }
  const defaultId = id ?? 1
  if (isFrozen) {
    freezeIDs[defaultId] = true
    freeze(true)
  } else {
    if (freezeIDs[defaultId]) {
      delete freezeIDs[defaultId]
    }
    if (!isBodyFrozen()) {
      freeze(false)
    }
  }
}

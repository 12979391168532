import React from 'react'
import cx from 'classnames'
import PropTypes from 'prop-types'
import { Loader, l, Icon, CheckboxV2 } from '@manychat/manyui'

import { FieldGroupId } from 'common/fields/entities/enums'
import FieldPicker from 'common/fields/linking/components/FieldPicker'
import FieldsController from 'common/filter/controllers/FieldsController'
import { FilterField } from 'common/filter/models/AudienceFilter/constants'
import { TagsPicker } from 'common/tags/components/TagPicker/TagsPicker'
import { Chip } from 'components/Chip'
import LabelTooltip from 'components/LabelTooltip'

import cm from './Initial.module.css'

const CustomFieldType = {
  NUMBER: 'number',
  DATE: 'date',
  BOOLEAN: 'boolean',
  TEXT: 'text',
  DATE_TIME: 'datetime',
  ARRAY: 'array',
}

export const CustomFieldIconByType = {
  [CustomFieldType.NUMBER]: Icon.CufNumber,
  [CustomFieldType.TEXT]: Icon.CufText,
  [CustomFieldType.DATE]: Icon.Time,
  [CustomFieldType.DATE_TIME]: Icon.Time,
  [CustomFieldType.BOOLEAN]: Icon.CufBoolean,
  [CustomFieldType.ARRAY]: Icon.CufArray,
}

const AVAILABLE_SYSTEM_FIELDS = [FilterField.PHONE, FilterField.EMAIL]

const cufFilter = (field) => ['number', 'text'].includes(field.type)
const cufOptionMapper = (field) => ({
  label: field.caption,
  value: field.name,
  type: field.type,
})

const makeField = (field) => ({
  id: field?.name,
  caption: field?.caption,
  type: field?.type,
  group: field?.segment_type,
  folderId: field?.folder_id,
})

const sfFilter = (field) => AVAILABLE_SYSTEM_FIELDS.includes(field.name)
const sfOptionMapper = (field) => ({
  label: field.caption,
  value: field.name,
})

export default class InitialMigrateStepContent extends React.Component {
  static propTypes = {
    onChange: PropTypes.func,
    cufId: PropTypes.string,
    sfName: PropTypes.string,
    overwrite: PropTypes.bool,
    tags: PropTypes.array,
  }

  static defaultProps = {
    onChange: () => {},
    cufId: null,
    sfName: null,
    overwrite: false,
    tags: [],
  }

  handleChange = (props) => {
    const { cufId, overwrite, onChange, sfName, tags } = this.props
    onChange({ sfName, overwrite, cufId, tags, ...props })
  }

  handleTagChange = ({ target }) => {
    const { tags } = this.props
    this.handleChange({ tags: [...tags, target] })
  }

  handleCUFChange = ({ target: { value } }) => {
    this.handleChange({ cufId: value })
  }

  handleSFChange = ({ target: { value } }) => {
    this.handleChange({ sfName: value })
  }

  clearCufValue = () => this.handleChange({ cufId: null })

  clearSFValue = () => this.handleChange({ sfName: null })

  handleOverwriteChange = ({ target }) => this.handleChange({ overwrite: target.checked })

  handleDeleteTag = (tagId) => {
    const { tags } = this.props
    const newTags = tags.filter((tag) => tag.value !== tagId)

    this.handleChange({ tags: newTags })
  }

  groupCustomFieldsOptions = ({ customFields }) => {
    return [
      {
        label: l.translate('Custom Fields'),
        value: 'custom',
        options: customFields.filter(cufFilter).map(cufOptionMapper),
      },
    ]
  }

  groupCustomSystemOptions = ({ systemFields }) => {
    return [
      {
        label: l.translate('System Fields'),
        value: 'system',
        options: systemFields.filter(sfFilter).map(sfOptionMapper),
      },
    ]
  }

  renderOption = (option) => {
    const OptionIcon = option.type ? CustomFieldIconByType[option.type] : null

    return (
      <div className={cm.option} key={option.value}>
        {Boolean(OptionIcon) && <OptionIcon className={cx(cm.fieldIcon, 'm-r-xs')} />}
        <span>{option.label}</span>
      </div>
    )
  }

  render() {
    const { cufId, sfName, overwrite, tags } = this.props

    return (
      <div className={cm.modalBody}>
        <h2 className="text-secondary text-center m-b-md">
          {l.translate(
            'You can only export Email and Phone number. Only non-empty fields will be processed.',
          )}
        </h2>

        <div className="d-flex align-center m-b-md">
          <CheckboxV2
            label={l.translate('Replace data in non-empty System Field')}
            checked={overwrite}
            onChange={this.handleOverwriteChange}
            infoText={l.translate(
              'Check the box if you want the existing data in a System Field to be replaced by the data in a Custom User Field',
            )}
          />
        </div>

        <div className="d-flex justify-between m-b-xs">
          <h3 className={cm.col}>{l.translate('Custom Field')}</h3>
          <h3 className={cx('m-0', cm.col)}>{l.translate('System Field')}</h3>
        </div>

        <div className="d-flex justify-between align-center m-b-md">
          <div className={cm.col}>
            <FieldsController
              loader={
                <div className="p-y p-x-xl">
                  <Loader />
                </div>
              }
            >
              {({ customFields, onCreate }) => (
                <div className="p-relative d-flex flex-col align-stretch align-center justify-end">
                  {Boolean(cufId) && (
                    <Icon.Close
                      size={24}
                      className={cx('p-absolute c-pointer', cm.deleteIcon)}
                      onClick={this.clearCufValue}
                    />
                  )}
                  <FieldPicker
                    placeholder="Enter field name"
                    data-test-id="cuf-to-suf-cuf"
                    renderOption={this.renderOption}
                    fields={customFields.filter(cufFilter).map(makeField)}
                    onSelect={this.handleCUFChange}
                    allowNew={FieldGroupId.USER}
                    value={cufId}
                    showIcon={false}
                    className={cm.input}
                    ariaLabel={l.translate('Enter field name')}
                  />
                </div>
              )}
            </FieldsController>
          </div>
          <Icon.OrphanLongArrow
            size={24}
            className={cx(sfName && cufId ? 'text-primary' : 'text-secondary')}
          />
          <div className={cm.col}>
            <FieldsController
              loader={
                <div className="p-y p-x-xl">
                  <Loader />
                </div>
              }
            >
              {({ systemFields, onCreate }) => (
                <div className="p-relative d-flex flex-col align-stretch align-center justify-end">
                  {Boolean(sfName) && (
                    <Icon.Close
                      size={24}
                      className={cx('p-absolute c-pointer', cm.deleteIcon)}
                      onClick={this.clearSFValue}
                    />
                  )}
                  <FieldPicker
                    placeholder="Enter field name"
                    data-test-id="cuf-to-suf-cuf"
                    renderOption={this.renderOption}
                    fields={systemFields.filter(sfFilter).map(makeField)}
                    onSelect={this.handleSFChange}
                    value={sfName}
                    showIcon={false}
                    className={cm.input}
                    ariaLabel={l.translate('Enter field name')}
                  />
                </div>
              )}
            </FieldsController>
          </div>
        </div>

        <div className="d-flex flex-col">
          <div className="d-flex m-b-xs">
            <h3>{l.translate('Tag for contacts with export error (Optional)')}</h3>
            <LabelTooltip className="m-t-xxs">
              {l.translate(
                'Choose a tag that will be applied to contacts with export error. If not set, they will be tagged automatically.',
              )}
            </LabelTooltip>
          </div>
          <TagsPicker excluded={tags} onChange={this.handleTagChange} />
          <div className="m-t">
            {tags.map((tag) => (
              <Chip key={tag.value} deletable onDelete={() => this.handleDeleteTag(tag.value)}>
                {tag.name}
              </Chip>
            ))}
          </div>
        </div>
      </div>
    )
  }
}

import React, { Component } from 'react'
import cx from 'classnames'

import { Accordion } from 'components/Accordion/Accordion'

export default class UsesInAutomationList extends Component {
  render() {
    const { uses } = this.props
    if (!uses || uses.isNoUses) {
      return null
    }

    const { className, usesList } = uses

    return (
      <div className={cx('panel panel-white panel-shadow', className)}>
        <Accordion
          items={usesList}
          renderItem={(item, isOpen) => (
            <>
              <span className="d-inline-block p-y-xs">
                {item.subItems.length} {item.label}
              </span>
            </>
          )}
          renderChild={(c) => <span>{c.name || c}</span>}
        />
      </div>
    )
  }
}

import React, { useCallback } from 'react'
import { useAppDispatch, useAppSelector } from 'reduxTyped'
import { Icon, l, palettes } from '@manychat/manyui'

import * as profileActions from 'apps/profile/actions/profileTypedActions'
import { getUser } from 'common/core/selectors/appSelectors'

interface VerifyEmailWarningProps {
  onOpenModal: () => void
}

export const VerifyEmailWarning = ({ onOpenModal }: VerifyEmailWarningProps) => {
  const dispatch = useAppDispatch()
  const { email_unverified, is_verification_code_sent, isVerifyingEmail } = useAppSelector(getUser)

  const isEmailUnverified = Boolean(email_unverified)

  const handleResendCodeClick = useCallback(async () => {
    if (!email_unverified) return

    const isSuccess = await dispatch(profileActions.sendEmailVerification(email_unverified))
    if (isSuccess) {
      onOpenModal()
    }
  }, [email_unverified, dispatch, onOpenModal])

  if (!isEmailUnverified) return null

  return (
    <div className="p-x-md">
      <div className="d-flex panel panel-warning p-a">
        <Icon.Warning className="m-r-xs" color={palettes.semantic.warning} size={24} />
        <div className="font-semibold">
          <div className="m-b-xxs">{l.translate('Your email is not verified')}</div>
          <div>
            {isVerifyingEmail ? (
              <span className="text-disabled">{l.translate('Sending...')}</span>
            ) : (
              <a className="text-primary" onClick={handleResendCodeClick}>
                {is_verification_code_sent
                  ? l.translate('Resend Verification Code')
                  : l.translate('Send Verification Code')}
              </a>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

import React, { useState, useRef, useCallback } from 'react'
import cx from 'classnames'
import { useAppDispatch, useAppSelector } from 'reduxTyped'
import { Icon, Popover, l, Loader } from '@manychat/manyui'

import { useModalActions } from 'apps/cms/components/Flows/components/AutomationModals/hooks/useModalActions'
import { CmsAnalytics } from 'apps/cms/lib/analytics'
import sharedMenuStyles from 'apps/cms/lib/styles/menuSharedStyles.module.css'
import { contentManagementActions, contentManagementSelectors } from 'apps/cms/store'
import { AutomationModalType } from 'apps/cms/store/lib/types'
import { FSObjectType } from 'common/cms/models/FSObject/constants'
import { IFullPinnedFlow } from 'common/flow/flowInterfaces'
import { usePermission, PermissionTargets } from 'common/userRoles'
import { FsFlow } from 'shared/api/requests/cms/schemas'

import cm from './FlowContextMenu.module.css'

interface FlowContextMenuProps {
  flowPath: string
  flowPinned?: IFullPinnedFlow
  anchorClassName?: string
  anchorOpenedClassName?: string
  isEasyBuilderFlow?: boolean
}

export const FlowContextMenu = ({
  flowPath,
  flowPinned,
  anchorClassName,
  anchorOpenedClassName,
  isEasyBuilderFlow,
}: FlowContextMenuProps) => {
  const dispatch = useAppDispatch()

  const useCanShare = usePermission(PermissionTargets.FLOW_SHARING)
  const flowDefault = useAppSelector((state) =>
    contentManagementSelectors.getFlowByPath(state, flowPath),
  )
  const flow = flowDefault || flowPinned
  const isSearchMode = useAppSelector(contentManagementSelectors.getIsSearchMode)

  const [isMenuOpened, setIsMenuOpened] = useState(false)

  const buttonRef = useRef<HTMLButtonElement | null>(null)

  const openMenu = () => {
    setIsMenuOpened(true)
  }

  const closeMenu = useCallback(() => {
    setIsMenuOpened(false)
  }, [])

  const [isCloning, setIsCloning] = useState(false)

  const handleCloneFlow = useCallback(async () => {
    if (isCloning) {
      return
    }

    setIsCloning(true)
    await dispatch(contentManagementActions.cloneFlow(flowPath))
    CmsAnalytics.logCloneFlow()
    setIsCloning(false)
    closeMenu()
  }, [closeMenu, dispatch, flowPath, isCloning])

  const [isRestoring, setRestoring] = useState(false)

  const handleRestoreFlow = useCallback(async () => {
    if (isRestoring) {
      return
    }

    setRestoring(true)
    await dispatch(contentManagementActions.restoreFlow(flowPath))
    CmsAnalytics.logRestoreFlow()
    setRestoring(false)
    closeMenu()
  }, [closeMenu, dispatch, flowPath, isRestoring])

  const { openModal } = useModalActions()

  if (!flow) {
    return null
  }

  const isProtected = flow.template_installation_access_data?.is_protected
  const isEditable = flow.capabilities && flow.capabilities.is_editable
  const isRestorable = flow?.capabilities && flow.capabilities.is_restorable
  const isPermanentlyDeletable = flow?.capabilities && flow.capabilities.is_permanently_deletable
  const isShareable = flow?.capabilities && flow.capabilities.is_shareable

  return (
    <>
      <button
        className={cx(
          cm.anchor,
          'p-relative',
          anchorClassName,
          isMenuOpened && anchorOpenedClassName,
        )}
        ref={buttonRef}
        type="button"
        onClick={openMenu}
      >
        <Icon.MoreVert />
      </button>
      <Popover
        at="bottom"
        popoverOrigin="top right"
        anchorOrigin="bottom right"
        anchor={buttonRef.current}
        open={isMenuOpened}
        onRequestClose={closeMenu}
        className={sharedMenuStyles.popover}
      >
        <div className={sharedMenuStyles.contextActions}>
          {isEditable && (
            <button
              onClick={() => {
                openModal({
                  type: AutomationModalType.RENAME_FLOW,
                  data: { flowName: flow.name as string, flowPath: flow.path },
                })

                closeMenu()
              }}
              className={sharedMenuStyles.contextActionButton}
              autoFocus
            >
              <Icon.Edit className={sharedMenuStyles.contextActionButton__icon} />

              <span>{l.translate('Rename')}</span>
            </button>
          )}

          {isEditable && (!isProtected || isEasyBuilderFlow) && (
            <button onClick={handleCloneFlow} className={sharedMenuStyles.contextActionButton}>
              {!isCloning ? (
                <>
                  <Icon.Copy className={sharedMenuStyles.contextActionButton__icon} />
                  <span>{l.translate('Duplicate')}</span>
                </>
              ) : (
                <Loader size={24} />
              )}
            </button>
          )}

          {isEditable && !isProtected && (
            <button
              onClick={() => {
                openModal({
                  type: AutomationModalType.CONVERT_FLOW_CHANNELS,
                  data: { flowNs: flow.ns },
                })

                closeMenu()
              }}
              className={sharedMenuStyles.contextActionButton}
            >
              <Icon.ConvertChannels className={sharedMenuStyles.contextActionButton__icon} />

              <span>{l.translate('Convert channels')}</span>
            </button>
          )}

          {isEditable && !isSearchMode && (
            <button
              onClick={() => {
                openModal({
                  type: AutomationModalType.MOVE_TO,
                  data: { type: FSObjectType.FLOW, name: flow.name as string, path: flowPath },
                })

                closeMenu()
              }}
              className={sharedMenuStyles.contextActionButton}
            >
              <Icon.MoveTo className={sharedMenuStyles.contextActionButton__icon} />

              <span>{l.translate('Move to')}</span>
            </button>
          )}

          {isEditable && useCanShare && isShareable && !isProtected && (
            <button
              onClick={() => {
                openModal({
                  type: AutomationModalType.SHARE_FLOW,
                  data: {
                    flowId: flow.ns,
                    flowSharingSettings: flow.sharing as FsFlow['sharing'],
                  },
                })

                closeMenu()
              }}
              className={sharedMenuStyles.contextActionButton}
            >
              <Icon.Share className={sharedMenuStyles.contextActionButton__icon} />

              <span>{l.translate('Share')}</span>
            </button>
          )}

          {isEditable && useCanShare && !isProtected && (
            <button
              onClick={() => {
                openModal({
                  type: AutomationModalType.EXPORT_FLOW_MODAL,
                  data: { flowName: flow.name as string, flowId: flow.ns },
                })

                closeMenu()
              }}
              className={sharedMenuStyles.contextActionButton}
            >
              <Icon.CopyToAnotherFlow className={sharedMenuStyles.contextActionButton__icon} />

              <span>{l.translate('Duplicate to another page')}</span>
            </button>
          )}

          {isEditable && (
            <button
              onClick={() => {
                openModal({
                  type: AutomationModalType.DELETE_FLOW,
                  data: {
                    flowName: flow.name,
                    flowPath: flow.path,
                    flowNs: flow.ns,
                  },
                })

                closeMenu()
              }}
              className={cx(
                sharedMenuStyles.contextActionButton,
                sharedMenuStyles.contextActionButton_theme_danger,
              )}
            >
              <Icon.Delete className={sharedMenuStyles.contextActionButton__icon} />

              <span>{l.translate('Delete')}</span>
            </button>
          )}

          {isRestorable && (
            <>
              {!isRestoring && (
                <button
                  onClick={handleRestoreFlow}
                  className={cx(
                    sharedMenuStyles.contextActionButton,
                    sharedMenuStyles.contextActionButton_theme_danger,
                    'text-primary',
                  )}
                >
                  <Icon.OrphanRestore className={sharedMenuStyles.contextActionButton__icon} />

                  <span>{l.translate('Restore')}</span>
                </button>
              )}
              {isRestoring && <Loader size={24} />}
            </>
          )}

          {isPermanentlyDeletable && (
            <button
              onClick={() => {
                openModal({
                  type: AutomationModalType.PERMANENT_DELETE,
                  data: {
                    flowName: flow.name as string,
                    flowId: flow.ns,
                    flowPath: flow.path,
                  },
                })

                closeMenu()
              }}
              className={cx(
                sharedMenuStyles.contextActionButton,
                sharedMenuStyles.contextActionButton_theme_danger,
                'text-danger',
              )}
            >
              <Icon.DeleteForever className={sharedMenuStyles.contextActionButton__icon} />

              <span>{l.translate('Permanently delete')}</span>
            </button>
          )}
        </div>
      </Popover>
    </>
  )
}

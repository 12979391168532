import { EmailBuilderDeviceTarget } from 'common/builder/emailBuilder/emailBuilderConstants'
import { NextStepType } from 'common/builder/models/Source/constants'

export default {
  id: null,
  rootId: null,
  flow: null,
  referrer: null, // information about the object that initiated the opening of new content
  contentHistory: [],
  selectedNodes: {},
  selectedTrigger: null,
  selectedButtonId: null,
  selectedUrlId: null,
  selectedBlockId: null,
  loadStats: false,

  // was initialized with new content
  isInitializedBlank: false,

  // builder readiness, for example, when the builder is initialized by content_id, readiness will occur
  // when the content is downloaded and parsed
  isReady: false,

  // builder is busy, for example, new content is being loaded or saving is happening
  // at this moment the user cannot work with it
  isBusy: false,

  isLoading: false,

  // timestamp of the last change in the builder
  lastChanged: null,

  // viewer
  selectedSegment: null,

  // id of the content associated with the button under which the hover occurred
  highlightedNodeId: null,

  // coordinates of nodes on the flow chart
  coordinates: {},

  // FlowChart camera position
  transform: {},

  flowChartMode: false,
  sidebarHidden: true,

  emailBuilder: {
    nodeId: '',
    deviceTarget: EmailBuilderDeviceTarget.MOBILE,
  },

  // create trigger
  createTriggerMode: false,
  createTriggerInitial: {},

  showCreateNodeSelect: false,
  showCreateFirstStepNodeSelect: false,

  // flow builder onboarding
  disableFlowChartInteractions: false, // disable node click, node move, connectors edit
  disableStartingStepNode: false,

  isWelcomeModalOpen: false,
  isDeleteSubscriberConfirmationOpen: false,
  isDeleteSubscriberActionConfirmed: false,

  config: {
    // widgets
    disableRootContentPersonalization: false,

    // steps
    stepsBlackList: [NextStepType.FLOW],
    stepsWhiteList: [],

    disableButton: false,
    isFlowBuilderOnboarding: false,
  },

  flowSimulator: {
    open: false,
    simulatorData: null,
  },

  draftDependencies: {
    tags: [],
  },
}

import React, { Fragment } from 'react'
import cx from 'classnames'
import { z } from 'zod'
import { Icon, l } from '@manychat/manyui'

import { BlockType } from '../../constants'
import { useBillingInfo, useCart } from '../../hooks'
import { registry } from '../../registry'
import { ClassNameSchema } from '../../schemas'

import cm from './Timeline.module.css'

const TimelineItemSchema = z.object({
  icon: z.string(),
  title: z.string(),
  description: z.string().or(z.object({})).or(z.function()),
})

const TimelinePropsSchema = z.object({
  className: ClassNameSchema,
  items: z.array(TimelineItemSchema),
})

const iconProps = {
  size: 12,
  color: 'var(--tint-neutral-2)',
} as const

const Component = ({ className, items }: z.infer<typeof TimelinePropsSchema>) => {
  const cart = useCart()
  const billingInfo = useBillingInfo()
  const total = l.currency(cart.totalPrice, billingInfo.currency, 0)

  return (
    <div className={cx(cm.timelineRoot, className)}>
      <div className={cx(cm.timelineLine)} style={{ gridRow: `1/${items.length + 1}` }} />

      {items.map(({ icon, title, description }, index) => {
        if (!(icon in Icon)) {
          return null
        }

        const IconComponent = Icon[icon as keyof typeof Icon]

        return (
          <Fragment key={title}>
            <div
              className={cx(
                cm.timelineIconContainer,
                'd-flex align-center justify-center',
                cm.timelineIcon,
              )}
              style={{ gridRow: `${index + 1}/${index + 2}` }}
            >
              <IconComponent {...iconProps} />
            </div>

            <div className={cm.timelineItem} style={{ gridRow: `${index + 1}/${index + 2}` }}>
              <p className={cx('m-b-xxs text-body', cm.timelineItemTitle)}>{title}</p>
              <div className={cx('text-secondary text-body', cm.timelineItemDescription)}>
                {typeof description === 'function' ? description({ total }) : description}
              </div>
            </div>
          </Fragment>
        )
      })}
    </div>
  )
}

export const Timeline = registry.register(Component, {
  props: TimelinePropsSchema,
  type: BlockType.TIMELINE,
  previewProps: {
    items: [
      {
        icon: 'Play',
        title: 'Today',
        description: 'You pay $0, your trial starts',
      },
      {
        icon: 'Notification',
        title: 'Day 10',
        description: "We'll email you the trial is ending soon",
      },
      {
        icon: 'Star',
        title: 'Day 14',
        description:
          "You'll be charged $15 (estimated). A reccuring monthly cjarge will then begin.",
      },
      {
        icon: 'Calendar',
        title: 'Every month',
        description:
          'Your final monthly price will be recalculated  based on the size of your contact list.',
      },
    ],
  },
})

import { FunctionComponent } from 'react'
import { v4 as uuid } from 'uuid'
import { ZodSchema } from 'zod'

import { BlockType } from '../constants'
import { DuplicateBlockError, UnknownBlockError } from '../errors'
import { Block, BlockProps, AnyBlock, SerializedBlock } from '../types'

import { BlockConfig, BlockRegistry } from './interfaces'

export class Registry implements BlockRegistry {
  private blocks: Map<BlockType, AnyBlock> = new Map()

  public register<Props extends ZodSchema, Context extends ZodSchema>(
    Component: FunctionComponent<BlockProps<Props>>,
    config: BlockConfig<Props, Context>,
  ): Block<Props, Context> {
    if (this.blocks.has(config.type)) {
      throw new DuplicateBlockError(config.type)
    }

    const Block: Block<Props, Context> = Component as Block<Props, Context>
    Block.props = config.props
    Block.context = config.context
    Block.type = config.type
    Block.displayName = `Block(${config.type.toUpperCase()})`
    Block.create = (props, block?: Partial<SerializedBlock>) => ({
      id: uuid(),
      props,
      type: config.type,
      ...block,
    })
    Block.previewProps = config.previewProps
    Block.preview = config.preview

    this.blocks.set(config.type, Block)

    return Block
  }

  public resolve(type: BlockType): AnyBlock {
    const block = this.blocks.get(type)
    if (!block) {
      throw new UnknownBlockError(type)
    }

    return block
  }

  public getAllDefinitions(): AnyBlock[] {
    return [...this.blocks.values()]
  }
}

export const registry = new Registry()

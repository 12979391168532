import React from 'react'
import { l, LinkBtn, useToast } from '@manychat/manyui'

import { analyticsService } from 'utils/services/analytics'
import { linkURL } from 'utils/url'

const CgtNextPostAlert = ({ flowNs }: { flowNs: string }) => {
  const { onClose } = useToast()
  const sendAnalytics = () => {
    analyticsService.sendEvent('GROWTHTOOL.INSTAGRAM_CGT.NEXT_POST.ALERT_CLICK')
    onClose()
  }

  return (
    <>
      <p>
        {l.translate(
          "You can't activate this trigger because you already have an active trigger for your next Instagram post.",
        )}
      </p>
      <LinkBtn
        href={linkURL(`/cms/files/${flowNs}`)}
        className="text-underline m-t text-default"
        target="_blank"
        rel="noreferrer"
        onClick={sendAnalytics}
      >
        {l.translate('Go to active Automation')}
      </LinkBtn>
    </>
  )
}

export default CgtNextPostAlert

import { WarningType } from '../constants/warnings'

export function warningsAdapter(data) {
  return data.notices.map((warning) => parseWarning(warning))
}

function parseWarning(warning) {
  return {
    section: 'main',
    type: parseWarningType(warning.type),
    id: warning.notice_id,
    content: warning.content,
    title: warning.title,
  }
}

function parseWarningType(type) {
  switch (type) {
    case 'warning':
      return WarningType.WARNING
    case 'info':
      return WarningType.INFO
  }
}

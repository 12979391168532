import { l } from '@manychat/manyui'

export const MenuItemType = {
  FLOW: 'flow',
  URL: 'url',
}

export const ViewMode = {
  PREVIEW: 'preview',
  EDIT: 'edit',
}

export const MenuItemTypeLabel = {
  [MenuItemType.FLOW]: l.makeTranslate('Reply with message'),
  [MenuItemType.URL]: l.makeTranslate('Open website'),
}

export const MenuItemMaxFree = 3
export const MenuItemMaxPro = 20

export const MenuItemMaxCaptionLength = 30

export const ErrorMessages = {
  CAPTION_REQUIRED: 'Please enter a menu item title',
  CAPTION_MAX_LENGTH: `Menu item title must be less than ${MenuItemMaxCaptionLength} characters long`,
  CONTENT_REQUIRED: 'Please specify a reply message for the menu item',
  URL_REQUIRED: 'Please enter a URL',
}

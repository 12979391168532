import { getCsrfToken, getCurrentAccountID } from 'common/core/selectors/appSelectors'

export const addSystemParams = (
  url: string,
  getState: () => RootState,
  options?: { pageId: string },
) => {
  const state = getState()
  const headers: Record<string, string> = {}

  if (url.indexOf(':currentAccountID') >= 0) {
    const currentAccountID = options?.pageId ?? getCurrentAccountID(state)
    url = url.replace(':currentAccountID', currentAccountID)
  }

  const csrfToken = getCsrfToken(state)
  if (csrfToken) {
    headers['X-Csrf-Token'] = csrfToken
  }

  headers['X-Frontend-Bundle'] = window.STATIC_VERSION ? String(window.STATIC_VERSION) : 'Empty'

  return { url, headers }
}

import React from 'react'
import ReactDOM from 'react-dom'
import PropTypes from 'prop-types'

import { noop } from '../../../utils'
import { translate } from '../../../utils/localization/format'
import Modal from '../Modal'

const DEFAULT_CONFIRM_WIDTH_PX = 525

export default class ConfirmComponent extends React.Component {
  static propTypes = {
    cancelLabel: PropTypes.string,
    cancelDisabled: PropTypes.bool,
    cancelProps: PropTypes.object,
    confirmLabel: PropTypes.string,
    confirmDisabled: PropTypes.bool,
    confirmProps: PropTypes.object,
    children: PropTypes.any,
    danger: PropTypes.bool,
    disableOverlayClose: PropTypes.bool,
    inverse: PropTypes.bool,
    loader: PropTypes.bool,
    onCancel: PropTypes.func,
    onConfirm: PropTypes.func,
    title: PropTypes.string,
    width: PropTypes.number,
    allowPropagationEvents: PropTypes.array,
  }

  static defaultProps = {
    disableOverlayClose: false,
    onCancel: noop,
    onConfirm: noop,
    danger: false,
    isConfirm: true,
    width: DEFAULT_CONFIRM_WIDTH_PX,
  }

  constructor(props) {
    super(props)

    this.state = {
      title: '',
      confirmLabel: '',
      cancelLabel: '',
      disableOverlayClose: false,
      isConfirm: props.isConfirm,
      danger: props.danger,
      body: null,
      width: props.width,
      noContentPadding: false,
      swapButtonsBehavior: false,
    }
  }

  open = (title, options = {}) => {
    const {
      body,
      confirmLabel,
      cancelLabel,
      disableOverlayClose = false,
      danger = false,
      isConfirm = true,
      width,
      noContentPadding = false,
      swapButtonsBehavior = false,
      onSubmit,
      onReset,
    } = options

    const newState = {
      title,
      confirmLabel,
      cancelLabel,
      disableOverlayClose,
      body,
      danger,
      isConfirm,
      width,
      noContentPadding,
      swapButtonsBehavior,
    }

    // handlers that may provided in options for correct work of "useBlocker" hook in components
    if (onSubmit) {
      newState.onSubmit = onSubmit
    }

    if (onReset) {
      newState.onReset = onReset
    }

    this.setState(newState)
    this.modal.open()
    return new window.Promise((resolve) => (this.resolve = resolve))
  }

  close = () => {
    this.resolve && this.resolve(false)
    this.modal.close()
  }

  confirm = () => {
    const { onSubmit } = this.state
    this.resolve && this.resolve(true)
    this.props.onConfirm()

    if (onSubmit) {
      onSubmit()
    }
  }

  cancel = (e) => {
    const { onReset } = this.state
    e && e.preventDefault()
    this.close()
    this.props.onCancel()

    if (onReset) {
      onReset()
    }
  }

  handleConfirmRef = (el) => {
    if (!el) {
      return
    }
    const btn = ReactDOM.findDOMNode(el) // eslint-disable-line react/no-find-dom-node
    btn.focus()
  }

  handleModalRef = (el) => (this.modal = el)

  render() {
    const { danger, isConfirm } = this.state
    const {
      cancelLabel,
      cancelDisabled,
      cancelProps,
      confirmLabel,
      confirmDisabled,
      confirmProps,
      children,
      disableOverlayClose,
      loader,
      title,
      ...other
    } = this.props

    const buttons = [
      {
        label: this.state.cancelLabel || cancelLabel || translate('Cancel'),
        disabled: cancelDisabled,
        ...cancelProps,
        onClick: this.state.swapButtonsBehavior ? this.confirm : this.cancel,
        'data-test-id': 'modal-cancel-button-element',
      },
      {
        label: this.state.confirmLabel || confirmLabel || translate('Confirm'),
        disabled: confirmDisabled,
        variant: danger ? 'danger' : 'primary',
        loading: loader,
        ...confirmProps,
        ref: this.handleConfirmRef,
        onClick: this.state.swapButtonsBehavior ? this.cancel : this.confirm,
        'data-test-id': 'modal-confirm-button-element',
      },
    ]

    return (
      <Modal
        noContentPadding={this.state.noContentPadding}
        {...other}
        width={this.state.width ?? other.width}
        ref={this.handleModalRef}
        title={this.state.title || title}
        disableOverlayClose={this.state.disableOverlayClose || disableOverlayClose}
        isConfirm={isConfirm}
        buttons={buttons}
        disableCloseButton
      >
        {this.state.body || children}
      </Modal>
    )
  }
}

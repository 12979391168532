import { useContext } from 'react'

import { UserFlag, AccountFlag } from 'utils/services/featureFlagsService/constants'

import { ReleaseTogglesContext } from './releaseTogglesContext'
import { ReleaseTogglesScopes } from './releaseTogglesTypes'

export const useReleaseToggle = (scope: ReleaseTogglesScopes, toggle: string): boolean =>
  Boolean(useContext(ReleaseTogglesContext)[scope][toggle])

export const useUserReleaseToggle = (toggle: UserFlag) => useReleaseToggle('user', toggle)
export const useAccountReleaseToggle = (toggle: AccountFlag) => useReleaseToggle('account', toggle)

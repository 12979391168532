import React from 'react'
import { Icon } from '@manychat/manyui'

import { FBPolicyEnforcementNotificationType } from 'common/core/components/NotifyBar/interfaces/facebookNotifications'
import { NotificationBarType } from 'common/core/constants/NotificationBarType'
import { WaMessageTemplatePauseTitle } from 'shared/api/requests/whatsApp/schemas'

export enum NotificationRemoveType {
  NEVER = 'never',
  ALWAYS = 'always',
  TEMPORARY = 'temporary',
}

export enum WhatsAppNotificationType {
  ACTIVATED = 'whatsapp_activated',
  BANNED = 'whatsapp_banned',
  VIOLATED = 'whatsapp_violation',
  FLAGGED = 'whatsapp_flagged',
  TIER_LIMIT_EXCEED = 'whatsapp_tier_limit_exceed',
  ERROR_TWILIO_CHARGE = 'whatsapp_error_twilio_charge',
  SUCCESS_SANDBOX_ACTIVATED = 'whatsapp_success_sandbox_activated',
  DECLINED = 'whatsapp_declined',
  VERIFIED = 'whatsapp_verified',
  STOPPED = 'whatsapp_stopped',
  TURNED_OFF = 'whatsapp_turned_off',
  REINSTATED = 'whatsapp_reinstated',
  BROKEN_MESSAGE_TEMPLATE = 'whatsapp_broken_message_template',
  BROKEN_MESSAGE_TEMPLATE_IS_IN_USE = 'whatsapp_broken_message_template_is_in_use',
  MESSAGE_TEMPLATE_CATEGORY_CHANGED = 'whatsapp_message_template_category_changed',
  BUSINESS_ACCOUNT_DELETED = 'whatsapp_business_account_deleted',
  PHONE_NUMBER_REMOVED = 'whatsapp_phone_number_removed',
  MESSAGE_TEMPLATE_PAUSED = 'whatsapp_message_template_paused',
  MESSAGE_TEMPLATE_DISABLED = 'whatsapp_message_template_disabled',
  COEXISTENCE_CONTACT_IMPORT_PENDING = 'whatsapp_coex_import_contacts_pending',
  COEXISTENCE_CONTACT_IMPORT_SUCCESS = 'whatsapp_coex_import_contacts_success',
  COEXISTENCE_CONTACT_IMPORT_ERROR = 'whatsapp_coex_import_contacts_error',
}

export enum FacebookNotificationType {
  TURNED_OFF = 'facebook_turned_off',
  INSUFFICIENT_RIGHTS_TO_RELOAD_ACCOUNT = 'facebook_insufficient_rights_to_reload_account',
  PAGE_CONNECTION_LOST = 'facebook_page_connection_lost',
  PAGE_CONNECTION_WAS_SUCCESSFULLY_RELOADED = 'facebook_page_connection_was_successfully_reloaded',
  ADS_CUSTOM_AUDIENCE_DEPRECATED = 'facebook_ads_custom_audience_deprecated',
  CAPI_RECONNECT_FAIL = 'facebook_capi_reconnect_fail',
}

export enum EmailNotificationType {
  SENDING_WAS_UN_SUSPENDED = 'email_sending_was_un_suspended',
  SENDING_WAS_SUSPENDED_DUE_TO_TOS = 'email_sending_was_suspended_due_to_TOS',
}

export enum InstagramNotificationType {
  INSTAGRAM_PAGE_CONNECTION_WAS_SUCCESSFULLY_RELOADED = 'instagram_page_connection_was_successfully_reloaded',
  INSTAGRAM_WRONG_PAGE_TO_RELOAD_PERMISSIONS = 'instagram_wrong_page_to_reload_permissions',
}

export enum TiktokNotificationType {
  TIKTOK_ACCOUNT_CONNECTION_WAS_SUCCESSFULLY_RELOADED = 'tiktok_account_connection_was_successfully_reloaded',
  TIKTOK_INSUFFICIENT_RIGHTS_TO_RELOAD_ACCOUNT = 'tiktok_insufficient_rights_to_reload_account',
  TIKTOK_WRONG_ACCOUNT_TO_RELOAD_PERMISSIONS = 'tiktok_wrong_account_to_reload_permissions',
  TIKTOK_REFRESH_PERMISSIONS_FAIL_LACK_OF_PERMISSIONS = 'tiktok_refresh_permissions_fail_lack_of_permissions',
  TIKTOK_REFRESH_PERMISSIONS_FAIL_ACCOUNT_IS_NOT_BUSINESS = 'tiktok_refresh_permissions_fail_account_is_not_business',
  TIKTOK_REFRESH_PERMISSIONS_FAIL_CONVERSATION_IS_UNAVAILABLE = 'tiktok_refresh_permissions_fail_conversation_is_unavailable',
  TIKTOK_REFRESH_PERMISSIONS_FAIL_USER_REGION_NOT_ALLOWED = 'tiktok_refresh_permissions_fail_user_region_not_allowed',
  TIKTOK_REFRESH_PERMISSIONS_FAIL_ACCOUNT_REGION_NOT_ALLOWED = 'tiktok_refresh_permissions_fail_account_region_not_allowed',
}

export enum StaticNotificationId {
  ACTIVATE_PRO_TRIAL = 'activate_pro_trial',
  AFTER_EXPIRED_PERIOD = 'after_expired_period',
  BLOCKED_ACCOUNT = 'blocked_account',
  BLOCKED_SMS_ACCOUNT = 'blocked_sms_account',
  EXPIRED = 'expired',
  HANDOVER_NOT_PRIMARY = 'handover_not_primary',
  IG_CHANGE_ACCOUNT_TYPE_ERROR = 'ig_change_account_error',
  LOW_BALANCE = 'low_balance',
  PAYMENT_CARD_RENEWAL = 'payment_card_renewal',
  PAY_NOW = 'pay_now',
  REFRESH_ADMIN_PERMISSIONS = 'refresh_admin_permissions',
  VERIFY_SUBSCRIPTION_PAYMENT = 'verify_subscription_payment',
  WA_FREE_PERIOD_END = 'wa_free_period_end',
  WA_PRO_ERROR = 'wa_pro_error',
  INSTAGRAM_PAGE_CONNECTION_LOST = 'instagram_page_connection_lost',
  WA_COEXISTENCE_CONTACT_IMPORT_ACTIVE = 'wa_coexistence_contact_import_active',
  WA_COEXISTENCE_CONTACT_IMPORT_FINISHED = 'wa_coexistence_contact_import_finished',
  TIER_LIMIT = 'tier_limit',
  WA_CREDIT_LINE_ISSUE = 'wa_credit_line_issue',
  TIKTOK_US_REGION_RESTRICTED = 'tiktok_us_region_restricted',
  WA_COEXISTENCE_CONTACT_IMPORT_AVAILABLE = 'wa_coexistence_contact_import_available',
}

export enum BackNotificationEventType {
  TYPED_MESSAGE = 'typed_message',
}

interface BackConditionPayload {
  event: BackNotificationEventType
  date?: string
}

interface FacebookPolicyPayload {
  fb_policy_enforcement: {
    reason: string
    type: FBPolicyEnforcementNotificationType
  }
}

type FrontPayload = (state: RootState) => boolean

interface MessageType {
  message: string
}

interface RenderNotification {
  render: React.FunctionComponent
}

interface RemoveType {
  removeType: NotificationRemoveType
}

interface MainType {
  type: NotificationBarType
}

interface NotificationId {
  notification_id: string
}

interface BaseNotification {
  page_id?: string
  user_id?: null | number
  isWithoutReadMore?: boolean
  customIconName?: keyof typeof Icon
  isAlwaysOnTop?: boolean
  ts_created: number
}

type UnknownNotification = BaseNotification & NotificationId & MessageType & RemoveType & MainType
export type FacebookPolicyNotification = BaseNotification &
  NotificationId &
  RemoveType & {
    data: FacebookPolicyPayload
  }
type FacebookPolicyReadyNotification = FacebookPolicyNotification & MainType & RenderNotification

export type WhatsAppNotification = BaseNotification &
  NotificationId & {
    data: BackConditionPayload
    message: WhatsAppNotificationType
  }

export type WhatsAppReadyNotification = WhatsAppNotification &
  RemoveType &
  MainType &
  RenderNotification

export type WhatsAppTwilioChargeErrorNotificationData = BackConditionPayload & {
  event: BackNotificationEventType
  date?: string
  refill_amount?: number
  currency?: string
}

export type WhatsAppTwilioChargeErrorNotification = WhatsAppReadyNotification & {
  data: WhatsAppTwilioChargeErrorNotificationData
}

export type WhatsAppBrokenMessageTemplateNotificationData = BackConditionPayload & {
  message_template_id: number
  message_template_name: string
  message_template_language_code: string
  bot_template_id: number
  bot_template_name: string
  event: 'typed_message'
}

export type WhatsAppBrokenMessageTemplateNotification = WhatsAppReadyNotification & {
  data: WhatsAppBrokenMessageTemplateNotificationData
}

export type WhatsAppMessageTemplatePausedNotificationData = BackConditionPayload & {
  pause_title: WaMessageTemplatePauseTitle | null
  message_template: {
    name_human: string
    name: string
    language_code: string
  }
}

export type WhatsAppMessageTemplatePausedNotification = WhatsAppReadyNotification & {
  data: WhatsAppMessageTemplatePausedNotificationData
}

export type WhatsAppMessageTemplateDisabledNotificationData = BackConditionPayload & {
  message_template: {
    name_human: string
    name: string
    language_code: string
  }
}

export type WhatsAppMessageTemplateDisabledNotification = WhatsAppReadyNotification & {
  data: WhatsAppMessageTemplateDisabledNotificationData
}

export type FacebookNotification = BaseNotification &
  NotificationId & {
    data: BackConditionPayload
    message: FacebookNotificationType
  }

export type FacebookReadyNotification = FacebookNotification &
  RemoveType &
  MainType &
  RenderNotification

export type EmailNotification = BaseNotification &
  NotificationId & {
    data: BackConditionPayload
    message: EmailNotificationType
  }

export type EmailReadyNotification = EmailNotification & RemoveType & MainType & RenderNotification

export type InstagramNotification = BaseNotification &
  NotificationId & {
    data: BackConditionPayload
    message: InstagramNotificationType
  }

export type InstagramReadyNotification = InstagramNotification &
  RemoveType &
  MainType &
  RenderNotification

export type TiktokNotification = BaseNotification &
  NotificationId & {
    data: BackConditionPayload
    message: TiktokNotificationType
  }

export type TiktokReadyNotification = TiktokNotification &
  RemoveType &
  MainType &
  RenderNotification

export type StaticNotification = BaseNotification &
  RemoveType &
  MainType &
  RenderNotification & {
    shouldBeShown: FrontPayload
    notification_id: StaticNotificationId
  }

export type NotificationItem =
  | StaticNotification
  | WhatsAppReadyNotification
  | FacebookReadyNotification
  | EmailReadyNotification
  | FacebookPolicyReadyNotification
  | UnknownNotification
  | InstagramReadyNotification
  | TiktokReadyNotification

export type NotificationDirtyItem =
  | NotificationItem
  | WhatsAppNotification
  | FacebookNotification
  | EmailNotification
  | FacebookPolicyNotification
  | InstagramNotification
  | TiktokNotification

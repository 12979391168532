export type AnalyticsValue =
  | string
  | number
  | undefined
  | boolean
  | null
  | { [key: string]: AnalyticsValue }
  | AnalyticsValue[]

export type AnalyticsData = Record<string, AnalyticsValue>

export enum GTMAnalyticsEvents {
  TRIAL_CONNECTION = 'RqntCIKQobIaEPKGlPsC',
  ACCOUNT_CREATION = 'tyEzCNKBlLIaEPKGlPsC',
}

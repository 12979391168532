import clone from 'lodash/clone'
import compact from 'lodash/compact'
import every from 'lodash/every'
import find from 'lodash/find'
import flatten from 'lodash/flatten'
import get from 'lodash/get'
import has from 'lodash/has'
import includes from 'lodash/includes'
import isArray from 'lodash/isArray'
import isEmpty from 'lodash/isEmpty'
import isEqual from 'lodash/isEqual'
import isObject from 'lodash/isObject'
import mapValues from 'lodash/mapValues'
import pick from 'lodash/pick'
import startsWith from 'lodash/startsWith'
import uniqBy from 'lodash/uniqBy'
import { v4 as uuid } from 'uuid'
import { l } from '@manychat/manyui'

import {
  TAG_OPEN_ENCRYPT,
  TAG_OPEN_ORIGINAL,
  TAG_CLOSE_ENCRYPT,
  TAG_CLOSE_ORIGINAL,
  AMP_ENCRYPT,
  AMP_ORIGINAL,
} from 'apps/email/emailConstants'
import {
  AttachmentBlockAttachmentType,
  QuestionBlockReplyType,
  EmailStyleSpaceSizeMap,
} from 'common/builder/blocks/blockConstants'
import * as blockHelpers from 'common/builder/blocks/blockHelpers'
import Adapters from 'common/builder/constants/Adapters'
import BackendAttachmentType from 'common/builder/constants/BackendAttachmentType'
import BackendButtonType from 'common/builder/constants/BackendButtonType'
import BackendContentType from 'common/builder/constants/BackendContentType'
import BackendMessageType from 'common/builder/constants/BackendMessageType'
import BackendPreFillFieldType from 'common/builder/constants/BackendPreFillFieldType'
import { BlockType } from 'common/builder/constants/BlockType'
import { ButtonType } from 'common/builder/constants/ButtonType'
import { FBMessagingType, MessageTagType } from 'common/builder/constants/facebookMessaging'
import { NodeType } from 'common/builder/constants/NodeType'
import { isVariable } from 'common/builder/constants/PhoneInputType'
import { TriggerOnOpenUse } from 'common/builder/emailBuilder/emailBuilderConstants'
import parseContentStats from 'common/builder/models/Batch/parseContentStats'
import { parseQuickRepliesSettings } from 'common/builder/models/Batch/parseQuickRepliesSettings'
import { parseQuestionReplyType } from 'common/builder/models/Block/adapters'
import { getSmartLinkIdsInText } from 'common/builder/models/Button/helpers'
import nodeRegistry from 'common/builder/nodeRegistry'
import { EmailStyleType } from 'common/builder/nodes/emailNew/emailNodeConstants'
import { DelayType } from 'common/builder/nodes/smartDelay/delayTypes'
import { parseCaseBlockFilter } from 'common/filter/models/AudienceFilter/adapter'
import { GetMessages } from 'common/oneTimeNotify/constants/notifyReasonConstants'
import errorTrackingService from 'utils/services/errorTrackingService'

import BaseParser from './base/BaseParser'
import {
  normalizeContentData,
  migrateSmsTextBlockContentToSmartLinks,
  getSearchedContentOid,
} from './utils'

export default class Parser extends BaseParser {
  constructor(batch, options = {}) {
    super(makeValidBatch(batch, options))

    this.botTimeZone = options.botTimeZone || null
    this.disableUnexpectedTargetOidTracking = options.disableUnexpectedTargetOidTracking

    this.nodeIdMap = options.nodeIdMap || {}
    this.batchNodeIdMap = {}
    this.batch.contents.forEach((c) => {
      const content = normalizeContentData(c)
      this.batchNodeIdMap[content._oid] = content.content_id
    })
  }

  SUPPORTED_MESSAGE_TYPES = [
    // supported original types
    BackendMessageType.TEXT,
    BackendMessageType.ATTACHMENT,
    BackendMessageType.EXTRNAL_MEDIA,
    BackendMessageType.CARDS,
    BackendMessageType.EXTERNAL_CARDS,
    BackendMessageType.LIST,
    BackendMessageType.QUESTION,
    BackendMessageType.DELAY,
    BackendMessageType.DYNAMIC,
    BackendMessageType.SMS,
    BackendMessageType.ATTACHMENTS,
    BackendMessageType.MESSAGE_TEMPLATE,
    BackendMessageType.WA_LIST_MESSAGE,
    BackendMessageType.WA_CATALOG_MESSAGE,

    // these are not original types, but we mask elements and quick_reply
    // as messages to unify message parsing
    BlockType.LIST_ITEM,
    BlockType.CARD,
    BlockType.QUICK_REPLY,
    BlockType.TELEGRAM_KEYBOARD,
    BlockType.ONE_TIME_NOTIFY_REQUEST,

    BlockType.EMAIL_ROOT,
    BlockType.EMAIL_TEXT,
    BlockType.EMAIL_IMAGE,
    BlockType.EMAIL_BUTTON,
    BlockType.EMAIL_DIVIDER,

    BlockType.EMAIL_TRIGGER_OPEN,
  ]

  SUPPORTED_BUTTON_TYPES = [
    BackendButtonType.CONTENT,
    BackendButtonType.LOCATION,
    BackendButtonType.URL,
    BackendButtonType.CTA_URL,
    BackendButtonType.CALL,
    BackendButtonType.SHARE,
    BackendButtonType.ANSWER,
    BackendButtonType.GOTO_CONTENT,
    BackendButtonType.CONTINUE_CONTENT,
    BackendButtonType.BUY,
    BackendButtonType.FLOW,
    BackendButtonType.OTN_NOTIFY_ME,
    BackendButtonType.WA_LIST_MESSAGE_BUTTON,
    BackendButtonType.CROSS_CHANNEL_CONTENT,
  ]

  process = () => {
    try {
      this.batch.contents.forEach(this.parseContent)
    } catch (err) {
      err.fingerprint = 'batch-parser-process'
      throw err
    }

    return mapValues(this.parsed, (items) => uniqBy(items, 'id'))
  }

  /* Content */

  parseContent = (contentData) => {
    if (!isObject(contentData)) {
      throw new TypeError(`invalid arg "contentData" – should be an object`)
    }
    contentData = normalizeContentData(contentData)

    let content = null
    if (contentData.type === BackendContentType.FORM) {
      errorTrackingService.trackWarningOnce('Parse form', {
        extra: { contentData },
        fingerprint: 'parse-form',
      })
      // ignoring form contents, maybe at some point we will start throwing an error
      content = null
    } else if (contentData.type === BackendContentType.ACTION_GROUP) {
      content = this.parseActionGroupContent(contentData)
    } else if (contentData.type === BackendContentType.GOTO) {
      content = this.parseGotoContent(contentData)
    } else if (contentData.type === BackendContentType.NOTE) {
      content = this.parseCommentContent(contentData)
    } else if (contentData.type === BackendContentType.OLD_CONDITION) {
      content = this.parseOldConditionContent(contentData)
    } else if (contentData.type === BackendContentType.MULTI_CONDITION) {
      content = this.parseMultiConditionContent(contentData)
    } else if (contentData.type === BackendContentType.SPLIT) {
      content = this.parseSplitContent(contentData)
    } else if (contentData.type === BackendContentType.SMART_DELAY) {
      content = this.parseSmartDelayContent(contentData)
    } else if (contentData.type === BackendContentType.SMS) {
      content = this.parseSmsContent(contentData)
    } else if (contentData.type === BackendContentType.EMAIL_NEW) {
      content = this.parseEmailNewContent(contentData)
    } else if (contentData.type === BackendContentType.DEFAULT || !contentData.type) {
      content = this.parseDefaultContent(contentData)
    } else if (contentData.type === BackendContentType.WHATS_APP) {
      content = this.parseWhatsAppContent(contentData)
    } else if (contentData.type === BackendContentType.INSTAGRAM) {
      content = this.parseInstagramContent(contentData)
    } else if (contentData.type === BackendContentType.TELEGRAM) {
      content = this.parseTelegramContent(contentData)
    } else if (contentData.type === BackendContentType.TIKTOK) {
      content = this.parseTiktokContent(contentData)
    } else if (contentData.type === BackendContentType.AI_AGENT) {
      content = this.parseAiAgentContent(contentData)
      return null
    } else {
      throw new Error(`unknown content type "${contentData.type}"`)
    }

    if (!content) {
      return null
    }

    return content
  }

  parseActionGroupContent = (contentData) => {
    const hasNullActions = !every(contentData.actions, Boolean)
    if (hasNullActions) {
      errorTrackingService.trackError('null actions found during action group parsing', {
        extra: { contentData },
        fingerprint: 'null-actions-when-parse',
      })
    }

    const { id, stats, flow, deleted, contentId } = this.parseContentGeneral(
      contentData,
      NodeType.ACTION_GROUP,
    )
    const initial = {
      id,
      stats,
      flow,
      deleted,
      contentId,
      nodeType: NodeType.ACTION_GROUP,
      caption: contentData.caption,
      items: compact(contentData.actions || []),
      targetId: this._parseTargetId(contentData.target),
    }

    this.addNode(initial)
    return null
  }

  parseGotoContent = (contentData) => {
    const nodeConfig = nodeRegistry.getByType(NodeType.GOTO)

    this.addNode({
      ...this.parseContentGeneral(contentData, NodeType.GOTO),
      nodeType: NodeType.GOTO,
      caption: contentData.caption || nodeConfig.getCaption(),
      flowId: get(contentData, 'target.flow_ns', null),
      targetId: this._parseTargetId(contentData.content_target),
    })

    return null
  }

  parseCommentContent = (contentData) => {
    const nodeConfig = nodeRegistry.getByType(NodeType.COMMENT)
    const defaults = nodeConfig.getDefaults()

    const note = contentData.note || {}
    this.addNode({
      ...this.parseContentGeneral(contentData, NodeType.COMMENT),
      nodeType: NodeType.COMMENT,
      caption: contentData.caption || nodeConfig.getCaption(),
      color: note.color || defaults.color,
      fontSize: note.font_size || defaults.fontSize,
      nodeSize: note.note_size || defaults.nodeSize,
      text: note.text || defaults.text,
      noteId: note._oid || uuid(),
      timestamp: note.timestamp ?? Date.now(),
    })

    return null
  }

  parseMultiConditionContent = (contentData) => {
    const nodeConfig = nodeRegistry.getByType(NodeType.CONDITION)
    const initial = this.parseContentGeneral(contentData, NodeType.CONDITION)
    initial.nodeType = NodeType.CONDITION

    const blocks = [
      ...contentData.conditions.map((c) =>
        this.addBlock({
          type: BlockType.CASE,
          id: c._oid || uuid(),
          targetId: this._parseTargetId(get(c, 'target')),
          filter: parseCaseBlockFilter(c.filter, this.botTimeZone),
        }),
      ),
      this.addBlock({
        type: BlockType.DEFAULT,
        id: contentData.default_target_oid || uuid(),
        targetId: this._parseTargetId(get(contentData, 'default_target')),
      }),
    ]

    initial.blocks = blocks.map((b) => b.id)
    initial.caption = contentData.caption || nodeConfig.getCaption()
    initial.isMultiCondition = true // for flowPlayer

    this.addNode(initial)

    return null
  }

  parseOldConditionContent = (contentData) => {
    const nodeConfig = nodeRegistry.getByType(NodeType.CONDITION)

    const initial = this.parseContentGeneral(contentData, NodeType.CONDITION)
    initial.nodeType = NodeType.CONDITION

    const blocks = [
      this.addBlock({
        type: BlockType.CASE,
        id:
          get(contentData, 'targets.true_target_oid') ||
          get(contentData, 'targets.true_target._oid') ||
          uuid(),
        targetId: this._parseTargetId(get(contentData, 'targets.true_target')),
        filter: isEmpty(contentData.filter) ? null : contentData.filter,
      }),
      this.addBlock({
        type: BlockType.DEFAULT,
        id:
          get(contentData, 'targets.false_target_oid') ||
          get(contentData, 'targets.false_target._oid') ||
          uuid(),
        targetId: this._parseTargetId(get(contentData, 'targets.false_target')),
      }),
    ]

    initial.blocks = blocks.map((b) => b.id)
    initial.caption = contentData.caption || nodeConfig.getCaption()

    this.addNode(initial)

    return null
  }

  parseSplitContent = (contentData) => {
    const nodeConfig = nodeRegistry.getByType(NodeType.SPLIT)

    const initial = this.parseContentGeneral(contentData, NodeType.SPLIT)
    initial.nodeType = NodeType.SPLIT
    const blocks = (contentData.variants || []).map((v) => this.parseVariant(v))

    initial.blocks = blocks.map((b) => b.id)
    initial.caption = contentData.caption || nodeConfig.getCaption()
    initial.randomized = contentData.randomized

    this.addNode(initial)
    return null
  }

  parseSmsContent = (contentData) => {
    const nodeConfig = nodeRegistry.getByType(NodeType.SMS)

    const initial = this.parseContentGeneral(contentData, NodeType.SMS)
    initial.nodeType = NodeType.SMS
    initial.targetId = this._parseTargetId(contentData.target)

    const blocks = (contentData.messages || []).map((message) => {
      const block = this.parseMessage(message, NodeType.SMS)

      if (block.type === BlockType.TEXT) {
        const [text, urlButtons] = migrateSmsTextBlockContentToSmartLinks(block.text, block.buttons)
        block.text = text
        for (const urlButton of urlButtons) {
          this.addButton(urlButton)
          block.buttons.push(urlButton.id)
        }
      }
      return block
    })

    initial.blocks = blocks.map((b) => b.id)
    initial.caption = contentData.caption || nodeConfig.getCaption()

    // parse quick replies as message
    const quickReplyMessageData = contentData.quick_replies
      ? contentData.quick_replies
      : { buttons: [] }

    // quick reply block should always have the same id, so define it based on parent content id
    const qrBlockId = blockHelpers.createQRBlockId(initial.id)
    const qrBlock = this.parseMessage(
      {
        ...quickReplyMessageData,
        _oid: qrBlockId,
        type: BlockType.QUICK_REPLY,
      },
      NodeType.SMS,
    )
    initial.blocks.push(qrBlock.id)
    this.addNode(initial)
    return null
  }

  parseEmailNewContent = (contentData) => {
    const nodeConfig = nodeRegistry.getByType(NodeType.EMAIL_NEW)
    const initial = this.parseContentGeneral(contentData, NodeType.EMAIL_NEW)
    initial.nodeType = NodeType.EMAIL_NEW
    initial.targetId = this._parseTargetId(contentData.target)
    initial.caption = contentData.caption || nodeConfig.getCaption()

    const triggerOpenBlock = {
      type: BlockType.EMAIL_TRIGGER_OPEN,
      trigger_open_target: contentData.trigger_on_open?.target,
      triggerOnOpenUse: contentData.trigger_on_open?.settings?.use || TriggerOnOpenUse.FIRST,
    }

    const batchBlocks = [...(contentData.blocks || []), triggerOpenBlock]
    const blocks = batchBlocks.map((block) => this.parseMessage(block, NodeType.EMAIL_NEW))

    initial.blocks = blocks.map((block) => block.id)

    const defaultBackgroundColor = nodeRegistry.getByType(NodeType.EMAIL_NEW).getDefaults()
      .data.background_color
    const {
      preheader,
      subject,
      sender_name,
      business_email_id,
      background_color = defaultBackgroundColor,
    } = contentData.settings

    initial.data = {
      preheader,
      subject,
      senderName: sender_name || '',
      businessEmailId: business_email_id || null,
      background_color,
    }

    initial.$isAdditionalAutomation = Boolean(contentData.trigger_on_open?.target)

    this.addNode(initial)
    return null
  }

  parseSmartDelayContent = (contentData) => {
    const nodeConfig = nodeRegistry.getByType(NodeType.SMART_DELAY)
    const defaults = nodeConfig.getDefaults()

    const delayData = {}

    if (contentData.wait_until) {
      delayData.delayType = DelayType.WAIT_UNTIL
      delayData.waitUntil = contentData.wait_until
    } else {
      delayData.delayType = DelayType.DURATION

      delayData.delayUnits = get(contentData, 'shift_time.unit', defaults.delayUnits)
      delayData.delayValue = get(contentData, 'shift_time.value', defaults.delayValue)
    }

    const toEndOfDay = isEqual(get(contentData, 'limit_time.to'), { hours: 23, minutes: 59 })
    const initial = {
      ...this.parseContentGeneral(contentData, NodeType.SMART_DELAY),
      nodeType: NodeType.SMART_DELAY,
      caption: contentData.caption || nodeConfig.getCaption(),
      sendingInterval: [
        get(contentData.limit_time, 'from.hours', defaults.sendingInterval[0]),
        toEndOfDay ? 0 : get(contentData.limit_time, 'to.hours', defaults.sendingInterval[1]),
      ],
      weekdays: get(contentData, 'limit_time.weekdays', defaults.weekdays),
      targetId: this._parseTargetId(contentData.target),
      ...delayData,
    }

    initial.useTimeWindow = contentData.limit_time != null

    this.addNode(initial)

    return null
  }

  parseVariant = (variant) => {
    const initial = {}

    initial.type = BlockType.VARIANT
    initial.id = variant._oid || uuid()
    initial.targetId = this._parseTargetId(variant.target)
    initial.percent = variant.percent
    initial.color = variant.data.color
    initial.title = variant.data.title

    return this.addBlock(initial)
  }

  parseDefaultContent = (contentData) => {
    const nodeConfig = nodeRegistry.getByType(NodeType.CONTENT)

    const initial = this.parseContentGeneral(contentData, NodeType.CONTENT)
    initial.nodeType = NodeType.CONTENT
    initial.targetId = this._parseTargetId(contentData.target)
    initial.caption = contentData.caption || nodeConfig.getCaption()

    // parse messages
    const messages = clone(contentData.messages) || []
    const blocks = messages.map((message) => this.parseMessage(message, NodeType.CONTENT))
    initial.blocks = compact(blocks).map((block) => block.id)

    // parse quick replies as message
    const quickReplyMessageData = contentData.quick_replies
      ? contentData.quick_replies
      : { buttons: [], settings: {} }

    // quick reply block should always have the same id, so define it based on parent content id
    const qrBlockId = blockHelpers.createQRBlockId(initial.id)
    const qrBlock = this.parseMessage(
      {
        ...quickReplyMessageData,
        _oid: qrBlockId,
        type: BlockType.QUICK_REPLY,
      },
      NodeType.CONTENT,
    )
    initial.blocks.push(qrBlock.id)

    return this.addNode(initial)
  }

  parseContentGeneral = (contentData, nodeType = null) => {
    const result = {
      id: contentData._oid || uuid(),
      contentId: contentData.content_id || null,
      flow: contentData.namespace || null,
      deleted: contentData.removed || false,
      blocks: [],
      messageTag: contentData.message_tag || null,
      reasonId: contentData.one_time_notify_reason_id || null,
      privateReply: contentData.private_reply || null,
      stats: parseContentStats(contentData),
    }

    if (nodeType === NodeType.CONTENT || nodeType === NodeType.INSTAGRAM) {
      if (contentData.$fbMessagingType) {
        result.$fbMessagingType = contentData.$fbMessagingType
      } else {
        if (contentData.message_tag === MessageTagType.CUSTOM_OTHER) {
          result.$fbMessagingType = FBMessagingType.INSIDE_24_HOURS
        } else if (contentData.message_tag === MessageTagType.NON_PROMOTIONAL) {
          result.$fbMessagingType = FBMessagingType.SUBSCRIPTION_CONTENT
        } else if (contentData.message_tag || contentData.one_time_notify_reason_id) {
          result.$fbMessagingType = FBMessagingType.OUTSIDE_24_HOURS
        } else if (contentData.private_reply === 'private_reply') {
          result.$fbMessagingType = FBMessagingType.COMMENT_REPLY
        } else {
          result.$fbMessagingType = FBMessagingType.INSIDE_24_HOURS
        }
      }
    }

    return result
  }

  parseWhatsAppContent = (contentData) => {
    const nodeConfig = nodeRegistry.getByType(NodeType.WHATS_APP)
    const initial = this.parseContentGeneral(contentData, NodeType.WHATS_APP)

    initial.nodeType = NodeType.WHATS_APP
    initial.targetId = this._parseTargetId(contentData.target)

    const blocks =
      contentData.messages?.map((message) => this.parseMessage(message, NodeType.WHATS_APP)) ?? []
    initial.blocks = blocks.map((b) => b.id)
    initial.caption = contentData.caption || nodeConfig.getCaption()

    // parse quick replies as message
    const quickReplyMessageData = contentData.quick_replies
      ? contentData.quick_replies
      : { buttons: [] }

    // quick reply block should always have the same id, so define it based on parent content id
    const qrBlockId = blockHelpers.createQRBlockId(initial.id)
    const qrBlock = this.parseMessage(
      {
        ...quickReplyMessageData,
        _oid: qrBlockId,
        type: BlockType.QUICK_REPLY,
      },
      NodeType.WHATS_APP,
    )
    initial.blocks.push(qrBlock.id)
    initial.whatsAppNodeType = contentData.whatsapp_node_type
    if (!initial.whatsAppNodeType) {
      initial.whatsAppNodeType = blocks[0]?.type === 'template' ? 'template' : 'content'
    }
    this.addNode(initial)
    return null
  }

  /* Instagram */

  parseInstagramContent = (contentData) => {
    const nodeConfig = nodeRegistry.getByType(NodeType.INSTAGRAM)

    const initial = this.parseContentGeneral(contentData, NodeType.INSTAGRAM)
    initial.nodeType = NodeType.INSTAGRAM
    initial.targetId = this._parseTargetId(contentData.target)
    initial.caption = contentData.caption || nodeConfig.getCaption()

    // parse messages
    const messages = clone(contentData.messages) || []
    const blocks = messages.map((message) => this.parseMessage(message, NodeType.INSTAGRAM))
    initial.blocks = compact(blocks).map((block) => block.id)

    // parse quick replies as message
    const quickReplyMessageData = contentData.quick_replies
      ? contentData.quick_replies
      : { buttons: [], settings: {} }

    // quick reply block should always have the same id, so define it based on parent content id
    const qrBlockId = blockHelpers.createQRBlockId(initial.id)
    const qrBlock = this.parseMessage(
      {
        ...quickReplyMessageData,
        _oid: qrBlockId,
        type: BlockType.QUICK_REPLY,
      },
      NodeType.INSTAGRAM,
    )
    initial.blocks.push(qrBlock.id)

    return this.addNode(initial)
  }

  parseTelegramContent = (contentData) => {
    const nodeConfig = nodeRegistry.getByType(NodeType.TELEGRAM)

    const initial = this.parseContentGeneral(contentData, NodeType.TELEGRAM)
    initial.nodeType = NodeType.TELEGRAM
    initial.targetId = this._parseTargetId(contentData.target)
    initial.caption = contentData.caption || nodeConfig.getCaption()

    // parse messages
    const messages = clone(contentData.messages) || []
    const blocks = messages.map((message) => this.parseMessage(message, NodeType.TELEGRAM))
    initial.blocks = compact(blocks).map((block) => block.id)

    let telegramKeyboardButtons = []
    if (contentData.telegram_keyboard) {
      telegramKeyboardButtons = contentData.telegram_keyboard.buttons || []
    } else if (contentData.quick_replies) {
      telegramKeyboardButtons = (contentData.quick_replies.buttons || []).map((button) => [button])
    }
    const telegramKeyboardBlockId = blockHelpers.createQRBlockId(initial.id)
    const telegramKeyboardBlock = this.parseMessage(
      {
        _oid: telegramKeyboardBlockId,
        type: BlockType.TELEGRAM_KEYBOARD,
        telegram_keyboard: telegramKeyboardButtons,
      },
      NodeType.TELEGRAM,
    )
    initial.blocks.push(telegramKeyboardBlock.id)

    return this.addNode(initial)
  }

  parseTiktokContent = (contentData) => {
    const nodeConfig = nodeRegistry.getByType(NodeType.TIKTOK)

    const initial = this.parseContentGeneral(contentData, NodeType.TIKTOK)
    initial.nodeType = NodeType.TIKTOK
    initial.targetId = this._parseTargetId(contentData.target)
    initial.caption = contentData.caption || nodeConfig.getCaption()

    // parse messages
    const messages = clone(contentData.messages) || []
    const blocks = messages.map((message) => this.parseMessage(message, NodeType.TIKTOK))
    initial.blocks = compact(blocks).map((block) => block.id)

    return this.addNode(initial)
  }

  parseAiAgentContent = (contentData) => {
    const nodeConfig = nodeRegistry.getByType(NodeType.AI_AGENT)

    const initial = this.parseContentGeneral(contentData, NodeType.AI_AGENT)
    initial.caption = contentData?.caption || nodeConfig.getCaption()
    initial.nodeType = NodeType.AI_AGENT
    initial.targetId = this._parseTargetId(contentData.target)
    initial.channel = contentData?.channel ?? null
    initial.agent_data = contentData?.agent_data ?? null
    initial.settings = contentData?.settings ?? nodeConfig.getDefaults().settings
    return this.addNode(initial)
  }

  /* Messages */

  parseMessage = (messageData, nodeType) => {
    if (includes(['form', 'form_picker', 'menu'], messageData.type)) {
      return null
    }

    if (!this.SUPPORTED_MESSAGE_TYPES.includes(messageData.type)) {
      throw new TypeError(`unsupported message type "${messageData.type}"}`)
    }

    const general = this.parseMessageGeneral(messageData)
    const specific = this.parseMessageSpecific(messageData, nodeType)
    const buttons = this.parseMessageButtons(messageData, specific)

    const initial = Object.assign({}, general, specific, buttons)
    initial.stats = this.parseMessageStats(messageData)

    // parse elements
    const elements = clone(messageData.elements) || []
    if (messageData.type === BackendMessageType.LIST) {
      const blocks = elements.map((element) =>
        this.parseMessage({ ...element, type: BlockType.LIST_ITEM }, nodeType),
      )
      initial.blocks = compact(blocks).map((block) => block.id)
    }

    if (messageData.type === BackendMessageType.CARDS) {
      const blocks = elements.map((element) =>
        this.parseMessage({ ...element, type: BlockType.CARD }, nodeType),
      )
      initial.blocks = compact(blocks).map((block) => block.id)
    }

    if (messageData.type === BlockType.EMAIL_ROOT) {
      const blocks = messageData.blocks.map((block) => this.parseMessage(block, nodeType))
      initial.blocks = compact(blocks).map((block) => block.id)
    }

    if (messageData.type === BackendMessageType.WA_LIST_MESSAGE) {
      const sections = messageData.sections || []

      initial.content = messageData.content
      if (initial.content.button === undefined || initial.content.button === '') {
        initial.content.button = l.translate('Button Title')
      }
      initial.sections = sections
    }

    if (messageData.type === BackendMessageType.WA_CATALOG_MESSAGE) {
      initial.content = { body: messageData.content.body, footer: messageData.content.footer }
    }

    return this.addBlock(initial)
  }

  parseMessageButtons = (messageData, initial) => {
    const keyboard =
      messageData.keyboard ||
      messageData.buttons ||
      messageData.answer_replies ||
      messageData.sections?.map((section) => section.options)?.flat()

    if (isArray(keyboard)) {
      let smartLinkIds = []
      const isMessageWithSmartLinksInText = [BlockType.TEXT, BlockType.EMAIL_TEXT].includes(
        messageData.type,
      )
      if (isMessageWithSmartLinksInText && typeof initial.text === 'string') {
        smartLinkIds = getSmartLinkIdsInText(initial.text)
      }
      const isMessageWithSmartLinkButtons = [
        BlockType.EMAIL_IMAGE,
        BlockType.EMAIL_BUTTON,
      ].includes(messageData.type)

      const buttons = keyboard.map(
        (buttonData) =>
          this.parseButton(buttonData, { smartLinkIds, isMessageWithSmartLinkButtons }).id,
      )

      return { buttons }
    }

    const telegramKeyboard = messageData.telegram_keyboard
    if (isArray(telegramKeyboard)) {
      const buttonsStructure = telegramKeyboard.map((rowButtons) =>
        rowButtons.map((buttonData) => this.parseButton(buttonData).id),
      )

      return { buttonsStructure, buttons: flatten(buttonsStructure) }
    }

    return {}
  }

  parseMessageGeneral = (messageData) => {
    return {
      id: messageData._oid || uuid(),
      type: messageData.type,
    }
  }

  parseMessageSpecific = (messageData, nodeType = null) => {
    const initial = {}
    const content = messageData.content || {}

    switch (messageData.type) {
      case BackendMessageType.TEXT:
        initial.text = content.text || ''
        break
      case BackendMessageType.ATTACHMENTS: {
        initial.type = BlockType.ATTACHMENT
        initial.attachmentType = AttachmentBlockAttachmentType.IMAGE
        const att = content.attachments[0]

        if (att.type === AttachmentBlockAttachmentType.EXTERNAL_IMAGE) {
          initial.attachment = {
            type: AttachmentBlockAttachmentType.EXTERNAL_IMAGE,
            image_url: att.data.url,
          }
          break
        }
        initial.attachment = att.data ? { type: att.type, ...att.data } : null
        break
      }
      case BackendMessageType.ATTACHMENT:
        if (content.type === AttachmentBlockAttachmentType.EXTERNAL_IMAGE) {
          initial.attachment = { type: content.type, image_url: content.data.url }
          initial.attachmentType = AttachmentBlockAttachmentType.IMAGE
          break
        }

        initial.attachment = content.data
        initial.attachmentType = content.type

        if (nodeType === NodeType.TELEGRAM && content.type === BackendAttachmentType.VIDEO) {
          initial.telegramVideoType = content.data ? content.data.telegram_video_type : null
        }

        if (content.type === BackendAttachmentType.FILE) {
          const mime = get(content, 'data.mime', '')

          if (startsWith(mime, 'video')) {
            if (nodeType === NodeType.TELEGRAM) {
              initial.telegramVideoType = content.data ? content.data.telegram_video_type : null
            }
            initial.attachmentType = AttachmentBlockAttachmentType.VIDEO
          }
        }

        if (content.type === BackendAttachmentType.GIF) {
          initial.attachmentType = AttachmentBlockAttachmentType.IMAGE
        }
        break
      case BackendMessageType.LIST:
        initial.topElementStyle = messageData.top_element_style
        break
      case BlockType.CARD:
      case BlockType.LIST_ITEM:
        initial.title = content.title
        if (content.subtitle) {
          initial.subtitle = content.subtitle
        }
        initial.image = content.image
        if (initial.image?.type === AttachmentBlockAttachmentType.EXTERNAL_IMAGE) {
          initial.image = {
            type: content.image.type,
            image_url: content.image.url,
          }
        }
        initial.defaultAction = messageData.default_action

        if (messageData.type === BlockType.CARD) {
          initial.isCardHidden = Boolean(messageData.is_hidden)
        }

        break

      case BlockType.CARDS:
        initial.imageAspectRatio = messageData.image_aspect_ratio
        break

      case BackendMessageType.QUESTION:
        {
          initial.text = content.text

          const [replyType, allowFreeInput] = parseQuestionReplyType(
            messageData.answer_type,
            messageData.answer_method,
          )
          initial.replyType = replyType
          initial.allowFreeInput = allowFreeInput
          initial.customFieldId = null
          initial.savePhoneToSystemField = false
          initial.savePhoneToWhatsAppIdSystemField = false
          initial.saveEmailToSystemField = false
          initial.setSmsOptIn = false
          initial.setEmailOptIn = false

          if (Array.isArray(messageData.adapters)) {
            const fieldAction = messageData.adapters.find(
              (a) => a.type === Adapters.SAVE_ANSWER_TO_CUSTOM_FIELD,
            )
            const shouldSavePhoneToSystemField =
              messageData.adapters.find((a) => a.type === Adapters.SAVE_PHONE_TO_SYSTEM_FIELD) &&
              (messageData.answer_type === QuestionBlockReplyType.PHONE ||
                messageData.answer_type === QuestionBlockReplyType.DEPRECATED_PHONE_FOR_SMS)

            const shouldSetSmsOptIn =
              shouldSavePhoneToSystemField &&
              messageData.adapters.find((a) => a.type === Adapters.SET_SMS_OPTIN)

            const shouldSavePhoneToWhatsAppIdSystemField = messageData.adapters.find(
              (adapter) => adapter.type === Adapters.SAVE_PHONE_TO_WA_ID_SYSTEM_FIELD,
            )

            const shouldSaveEmailToSystemField =
              messageData.adapters.find((a) => a.type === Adapters.SAVE_EMAIL_TO_SYSTEM_FIELD) &&
              messageData.answer_type === QuestionBlockReplyType.EMAIL

            const shouldSetEmailOptIn =
              shouldSaveEmailToSystemField &&
              messageData.adapters.find((a) => a.type === Adapters.SET_EMAIL_OPTIN)

            if (fieldAction) {
              initial.customFieldId = fieldAction.field_id
              initial.savePhoneToSystemField = false
              initial.saveEmailToSystemField = false
              initial.savePhoneToWhatsAppIdSystemField = false
            }

            if (shouldSavePhoneToSystemField) {
              initial.customFieldId = null
              initial.savePhoneToSystemField = true
            }

            if (shouldSavePhoneToWhatsAppIdSystemField) {
              initial.savePhoneToWhatsAppIdSystemField = true
            }

            if (shouldSetSmsOptIn) {
              initial.setSmsOptIn = true
            }

            if (shouldSaveEmailToSystemField) {
              initial.customFieldId = null
              initial.saveEmailToSystemField = true
            }

            if (shouldSetEmailOptIn) {
              initial.setEmailOptIn = true
            }
          }

          initial.validationErrorMessage = messageData.validation_message || ''
          initial.skipButtonCaption = messageData.skip_button_caption || ''
          initial.telegramSharePhoneButtonCaption =
            messageData.telegram_share_phone_button_caption || ''
          initial.buttonCaption = messageData.button_caption || ''
          initial.targetId = null

          initial.answerRetries = messageData.limit_failed || 4
          initial.questionAnswerTimeout = messageData.question_answer_timeout || {
            value: 24,
            unit: 'hours',
          }

          // parse target id and actions
          if (messageData.success_target) {
            initial.targetId = this._parseTargetId(messageData.success_target)
          }
          const { id } = this.addBlock({
            type: BlockType.FORM_QUESTION_TIMEOUT,
            targetId: this._parseTargetId(messageData.timeout_target),
          })
          initial.blocks = [id]
        }
        break
      case BackendMessageType.DELAY:
        initial.time = messageData.time
        initial.showTyping = messageData.show_typing
        break
      case BackendMessageType.DYNAMIC:
        if (messageData.fallback) {
          const { id } = this.addButton({
            type: ButtonType.CONTENT,
            targetId: this._parseTargetId(messageData.fallback),
            caption: 'Fallback',
          })
          initial.buttons = [id]
        }
        initial.method = messageData.method
        initial.url = messageData.url
        initial.payload = messageData.payload
        initial.headers = messageData.headers
        break
      case BlockType.ONE_TIME_NOTIFY_REQUEST:
        initial.image = content.image
        initial.title = content.text || ''
        initial.reasonId = content.one_time_notify_reason_id
        break

      case BlockType.EMAIL_ROOT:
        initial.settings = this.parseEmailBlockSettings(BlockType.EMAIL_ROOT, messageData.settings)
        break
      case BlockType.EMAIL_TEXT:
        initial.text = this.parseRichText(content.text || '')
        initial.settings = this.parseEmailBlockSettings(BlockType.EMAIL_TEXT, messageData.settings)
        break
      case BlockType.EMAIL_IMAGE:
        if (content.type === AttachmentBlockAttachmentType.EXTERNAL_IMAGE) {
          initial.image = {
            type: content.type,
            image_url: content.data.url,
          }
        } else {
          initial.image = content.data
        }

        initial.settings = this.parseEmailBlockSettings(BlockType.EMAIL_IMAGE, messageData.settings)
        break
      case BlockType.EMAIL_BUTTON:
        initial.text = content.text || ''
        initial.settings = this.parseEmailBlockSettings(
          BlockType.EMAIL_BUTTON,
          messageData.settings,
        )
        break
      case BlockType.EMAIL_DIVIDER: {
        if (messageData.settings?.padding_top?.value === undefined) {
          const value = EmailStyleSpaceSizeMap[messageData.settings.space_size.value] || 0
          messageData.settings.padding_top = { type: EmailStyleType.CUSTOM, value }
          messageData.settings.padding_bottom = { type: EmailStyleType.CUSTOM, value }
        }
        if (messageData.settings?.line_color?.value === undefined) {
          if (messageData.settings.with_line.value) {
            messageData.settings.line_color = { type: EmailStyleType.CUSTOM, value: '#E1E5EA' }
          } else {
            messageData.settings.line_color = { type: EmailStyleType.CUSTOM, value: '' }
          }
        }

        initial.settings = this.parseEmailBlockSettings(
          BlockType.EMAIL_DIVIDER,
          messageData.settings,
        )
        break
      }
      case BlockType.EMAIL_TRIGGER_OPEN:
        initial.targetId = this._parseTargetId(messageData.trigger_open_target)
        initial.triggerOnOpenUse = messageData.triggerOnOpenUse
        break

      case BlockType.MESSAGE_TEMPLATE:
        initial.name = content.name
        initial.language = content.language
        break

      case BlockType.QUICK_REPLY: {
        initial.settings = parseQuickRepliesSettings(messageData.settings)
        initial.targetId = this._parseTargetId(messageData.settings?.timeout_target)
        break
      }
      case BlockType.TELEGRAM_KEYBOARD: {
        break
      }
    }

    return initial
  }

  parseMessageStats = (messageData) => {
    return {
      clicks: get(messageData, 'default_action.button_click_stats.clicks', 0),
      segmentId: get(messageData, 'default_action.button_click_stats.segment_id', ''),
      users: get(messageData, 'default_action.button_click_stats.users', 0),
      ctrDisabled: get(messageData, 'default_action.button_click_stats.ctr_disabled', false),
    }
  }

  /* Buttons */

  parseButton = (buttonData, options = {}) => {
    if (buttonData.type && !this.SUPPORTED_BUTTON_TYPES.includes(buttonData.type)) {
      throw new TypeError(`unsupported button type "${buttonData.type}"}`)
    }

    const general = this.parseButtonGeneral(buttonData)
    const specific = this.parseButtonSpecific(buttonData, options)
    const initial = Object.assign({}, general, specific)
    initial.stats = this.parseButtonStats(buttonData)

    if (
      [
        ButtonType.CONTENT,
        ButtonType.CROSS_CHANNEL_CONTENT,
        ButtonType.URL,
        ButtonType.CTA_URL,
      ].includes(initial.type)
    ) {
      initial.isSmartLink =
        (options.smartLinkIds ?? []).includes(initial.id) ||
        Boolean(options.isMessageWithSmartLinkButtons)
    }
    return this.addButton(initial)
  }

  parseButtonGeneral = (buttonData) => {
    return {
      id: buttonData._oid || uuid(),
      type: buttonData.type || ButtonType.CONTENT,
      caption: buttonData.caption,
    }
  }

  parseButtonSpecific = (buttonData) => {
    const initial = {}

    const isButtonUsedInQuickReply = [
      BackendButtonType.CONTENT,
      BackendButtonType.ANSWER,
      BackendButtonType.FLOW,
    ].includes(buttonData.type)

    if (isButtonUsedInQuickReply) {
      if (buttonData.emoji) {
        initial.emoji = buttonData.emoji
      }
      if (buttonData.image) {
        initial.image = buttonData.image
      }
    }

    if (buttonData.type === BackendButtonType.CONTENT) {
      initial.type = ButtonType.CONTENT
      if (has(buttonData, 'is_fallback')) {
        initial.isFallback = buttonData.is_fallback
      }
      const target = pick(buttonData, 'content_id', '_content_oid')
      initial.targetId = this._parseTargetId(target)
    }
    if (buttonData.type === BackendButtonType.CROSS_CHANNEL_CONTENT) {
      initial.type = ButtonType.CROSS_CHANNEL_CONTENT
      initial.channel = buttonData.channel
      const target = pick(buttonData, 'content_id', '_content_oid')
      initial.targetId = this._parseTargetId(target)
    }
    if (buttonData.type === BackendButtonType.OTN_NOTIFY_ME) {
      initial.type = ButtonType.CONTENT
      initial.isNotificationRequest = true
      initial.caption = l.getString(GetMessages)
      const target = pick(buttonData, 'content_id', '_content_oid')
      initial.targetId = this._parseTargetId(target)
    }
    if (buttonData.type === BackendButtonType.ANSWER) {
      initial.type = ButtonType.ANSWER
      const value = buttonData.value || ''
      initial.customValue = value && value !== buttonData.caption
      initial.value = value
    }
    if (buttonData.type === BackendButtonType.CALL) {
      initial.type = ButtonType.CALL
      initial.phone = isVariable(buttonData.phone)
        ? buttonData.phone.replace(/[\}\{]/gi, '')
        : buttonData.phone
      initial.is_formula = Boolean(buttonData.is_formula)
    }
    if (buttonData.type === BackendButtonType.BUY) {
      initial.type = ButtonType.BUY
      // https://manychat.myjetbrains.com/youtrack/issue/API-857
      // now we have two fields which respond to item price
      // item.price - is a legacy field, string type, for ex 11.99
      // item.cost - is a legacy field, price in cents, integer, for ex 1199
      // item.formula - is a primary field, string type, required to use formulas
      if (get(buttonData, 'item.formula')) {
        initial.item_price = get(buttonData, 'item.formula')
      } else if (has(buttonData, 'item.cost')) {
        const cost = get(buttonData, 'item.cost')
        if (cost && isFinite(cost)) {
          initial.item_price = (cost / 100).toFixed(2)
        }
      } else if (get(buttonData, 'item.price')) {
        // todo remove price legacy support someday
        initial.item_price = get(buttonData, 'item.price')
      }
      initial.item_label = get(buttonData, 'item.label')
      initial.shipping_address = get(buttonData, 'user_data.shipping_address')
      initial.contact_name = get(buttonData, 'user_data.contact_name')
      initial.contact_phone = get(buttonData, 'user_data.contact_phone')
      initial.contact_email = get(buttonData, 'user_data.contact_email')

      const prefillData = get(buttonData, 'prefill_data') || []
      const emailPreFill = find(prefillData, { form_field: BackendPreFillFieldType.EMAIL })
      initial.email_field_id = get(emailPreFill, 'field_id') || null
      initial.targetId = this._parseTargetId(buttonData.success_target)
      initial.filter = buttonData.filter
      return initial
    }
    if (buttonData.type === BackendButtonType.FLOW) {
      initial.type = ButtonType.FLOW
      if (buttonData.flow_ns) {
        initial.flowId = buttonData.flow_ns
      }
    }
    if ([BackendButtonType.URL, BackendButtonType.CTA_URL].includes(buttonData.type)) {
      initial.type = buttonData.type
      initial.enableWebview = Boolean(buttonData.do_not_track)
      if (buttonData.url) {
        initial.url = buttonData.url
      }
      if (buttonData.webview_size) {
        initial.webviewSize = buttonData.webview_size
      }
      if (buttonData.target) {
        initial.targetId = this._parseTargetId(buttonData.target)
      }
    }
    if (buttonData.type === BackendButtonType.WA_LIST_MESSAGE_BUTTON) {
      initial.type = ButtonType.WA_LIST_MESSAGE_BUTTON
      initial.targetId = buttonData._content_oid
      initial.description = buttonData.description
    }

    return initial
  }

  parseButtonStats = (buttonData) => {
    const defaults = {
      clicks: 0,
      segmentId: '',
      users: 0,
      ctrDisabled: false,
    }
    // common format
    const clicks = get(buttonData, 'button_click_stats.clicks')
    const users = get(buttonData, 'button_click_stats.users')

    // simple builder format
    const simpleClicks = get(buttonData, 'stats.triggered')
    const simpleUsers = get(buttonData, 'stats.triggered_users')

    return {
      clicks: clicks || simpleClicks || defaults.clicks,
      segmentId: get(buttonData, 'button_click_stats.segment_id', defaults.segmentId),
      users: users || simpleUsers || defaults.users,
      ctrDisabled: get(buttonData, 'button_click_stats.ctr_disabled', defaults.ctrDisabled),
    }
  }

  /* Utils */

  _parseTargetId = (target) => {
    if (!target) {
      return null
    }

    const { _content_oid: contentOid, content_id: contentId } = target

    if (contentOid) {
      const isContentOidInBatches = contentOid in this.batchNodeIdMap
      if (isContentOidInBatches) {
        return contentOid
      }
      const isContentOidInNodes = contentOid in this.nodeIdMap
      if (isContentOidInNodes) {
        return contentOid
      }
    }

    if (contentId) {
      let searchedContentOid = getSearchedContentOid(this.batchNodeIdMap, contentId)
      if (searchedContentOid) {
        return searchedContentOid
      }

      searchedContentOid = getSearchedContentOid(this.nodeIdMap, contentId)
      if (searchedContentOid) {
        return searchedContentOid
      }

      return contentId
    }

    const isAnyContentId = Boolean(contentOid || contentId)
    const isSendLog = isAnyContentId && !this.disableUnexpectedTargetOidTracking

    if (isSendLog) {
      errorTrackingService.trackError(
        `unexpected contentOid = ${contentOid} and contentId = ${contentId}`,
        {
          extra: { target, batchNodeIdMap: this.batchNodeIdMap, nodeIdMap: this.nodeIdMap },
          fingerprint: 'unexpected-content-oid-and-content-id',
          tags: { area: 'builder', topic: "id doesn't exist" },
        },
      )
    }

    return null
  }

  parseRichText = (text) => {
    return text
      .replace(new RegExp(AMP_ENCRYPT, 'g'), AMP_ORIGINAL)
      .replace(new RegExp(TAG_OPEN_ENCRYPT, 'g'), TAG_OPEN_ORIGINAL)
      .replace(new RegExp(TAG_CLOSE_ENCRYPT, 'g'), TAG_CLOSE_ORIGINAL)
  }

  parseEmailBlockSettings = (blockType, settings) => {
    // Back send [] as empty settings instead of {} :CC
    if (Array.isArray(settings)) {
      settings = {}
    }

    return settings
  }
}

function makeValidBatch(batch, options) {
  if (!isObject(batch)) {
    throw new TypeError(`invalid arg 'batch' – should be an object`)
  }

  if (options.botTimeZone === undefined) {
    throw new TypeError('Parser has to get botTimeZone for MultiConditionContent')
  }

  const isOldVersion = isArray(batch)
  if (isOldVersion) {
    // convert to new version
    const contents = clone(batch)
    batch = { root_content: null, contents }
  }

  if (!batch.contents) {
    batch.contents = []
  }
  return batch
}

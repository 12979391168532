import { DEFAULT_SV } from 'utils/services/hotjar/constants'

export const loadHotjar = (handleOnLoad?: () => void) => {
  const hotjarId = window?._hjSettings?.hjid
  if (!hotjarId) {
    return
  }

  const hotjarSv = window?._hjSettings?.hjsv ?? DEFAULT_SV
  window.hj =
    window.hj ||
    function () {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-expect-error
      window.hj.q = window.hj.q || []
      // eslint-disable-next-line prefer-rest-params
      window?.hj?.q.push(arguments)
    }
  const script = document.createElement('script')
  script.async = true
  script.src = 'https://static.hotjar.com/c/hotjar-' + hotjarId + '.js?sv=' + hotjarSv
  if (handleOnLoad) {
    script.onload = handleOnLoad
  }
  document.head.appendChild(script)
}

import { isTagAllowed, makeIsAttributeAllowed } from './config'
import { Attribute, Tag, TagType } from './types'

export const parseTag = (input: string): Tag | undefined => {
  const type = getTagType(input)
  if (!type) return undefined

  const [name, attributesString] = splitAtFirstOccurrence(getTagContent(type, input), ' ')
  if (!isTagAllowed(type, name)) return undefined

  const attributes = parseAttributes(attributesString, name)

  return { input, name, attributes, type }
}

const AnyTagRegExp = /<\/?[a-z0-9]+[^<]*>/
const SplitAttributesRegExp = /([a-zA-Z0-9]+="[^"]*")/

const getTagType = (input: string): TagType | null => {
  if (!AnyTagRegExp.test(input)) return null
  if (input.startsWith('</')) return 'closing'
  if (input.endsWith('/>')) return 'self-closing'
  return 'opening'
}

const getTagContent = (type: TagType, input: string) => {
  switch (type) {
    case 'opening':
      return input.slice(1, -1)
    case 'closing':
      return input.slice(2, -1)
    case 'self-closing':
      return input.slice(1, -2)
  }
}

const parseAttributes = (input: string, tagName: string) => {
  const attributes = input.split(SplitAttributesRegExp).filter(Boolean)
  const isAttributeAllowed = makeIsAttributeAllowed(tagName)
  const result: Attribute[] = []
  for (const a of attributes) {
    const [aName, aValue] = splitAtFirstOccurrence(a, '=')

    if (!isAttributeAllowed(aName)) {
      continue
    }
    if (aName && aValue && aValue.startsWith('"') && aValue.endsWith('"')) {
      result.push({ name: aName, value: aValue.slice(1, -1) })
    }
  }
  return result
}

const splitAtFirstOccurrence = (input: string, symbol: string): [string, string] => {
  const index = input.indexOf(symbol)
  if (index === -1) return [input, '']

  return [input.slice(0, index), input.slice(index + 1)]
}

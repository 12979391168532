import { v4 as uuidv4 } from 'uuid'
import { INPReportCallbackWithAttribution } from 'web-vitals/attribution'

import { isMobile, isTouch } from 'utils'
import { analyticsService } from 'utils/services/analytics'
import { AnalyticsValue } from 'utils/services/analytics/types'

export type InternetSpeed = 'slow-2g' | '2g' | '3g' | '4g' | 'unknown'

const getPageState = (): string => {
  if (document.visibilityState === 'hidden') {
    return 'hidden'
  }
  if (document.hasFocus()) {
    return 'active'
  }
  return 'passive'
}

const getConnection = (): InternetSpeed => {
  // eslint-disable-next-line
  // @ts-expect-error
  const connection = navigator.connection || navigator?.mozConnection || navigator?.webkitConnection
  return connection?.effectiveType ?? 'unknown'
}

const sendBeacon = (uid: string, metric: string, value: number, context: AnalyticsValue) => {
  analyticsService.sendEvent('METRICS.USER.INTERACTIVE.PERFORMANCE', {
    uid,
    metric,
    value,
    context,
  })
}

const getPathAlias = (path: string) => {
  if (path.match('/cms/files/')) return 'flowbuilder'
  return 'other'
}

class PageLifecycleTracker {
  private readonly events: string[] = [
    'pageshow',
    'focus',
    'blur',
    'visibilitychange',
    'resume',
    'freeze',
  ]
  private uid: string
  private previousDate = new Date().getTime()
  private previousState: string

  constructor() {
    this.uid = uuidv4()
    this.sendBeacon(`page:init`, 0)
    this.bindEvents()
    this.previousState = getPageState()
  }

  private sendBeacon(metric: string, value: number): void {
    const context = {
      page: getPathAlias(window.location.pathname),
      visibility: getPageState(),
      isMobile: isMobile,
      isTouch: isTouch,
      ua: window.navigator.userAgent,
      connection: getConnection(),
    }
    sendBeacon(this.uid, metric.toLowerCase(), value, context)
  }

  public getLogger(): INPReportCallbackWithAttribution {
    return (metric) => {
      this.sendBeacon(`page:${metric.name}`, metric.value)
    }
  }

  private bindEvents(): void {
    this.events.forEach((event) => {
      window.addEventListener(event, this.handleEvent.bind(this), { capture: false })
    })
  }

  private handleEvent(event: Event): void {
    let value = 0
    const state = getPageState()
    if (this.previousState !== state) {
      const ts = new Date().getTime()
      value = ts - this.previousDate
      this.previousState = state
      this.previousDate = ts
    }
    this.sendBeacon(`page:${event.type}`, value)
  }

  public destroy(): void {
    this.events.forEach((event) => {
      window.removeEventListener(event, this.handleEvent)
    })
  }
}

export { PageLifecycleTracker, getPathAlias, getConnection }

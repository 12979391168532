export const AccountWithIdScopeRoutes = [
  {
    path: 'banInfo',
    lazy: () => import('./banInfo.page'),
  },
] as const

export const AccountRoutes = [
  {
    path: 'account/deleted',
    lazy: () => import('./deleted.page'),
  },
] as const

export const BusinessDomainStatus = {
  NOT_VERIFIED: 1,
  VERIFIED: 2,
  ACTIVE: 3,
}

export const BusinessDomainRecordStatus = {
  NOT_VERIFIED: 1,
  VERIFIED: 2,
}

export const BusinessEmailStatus = {
  NOT_VERIFIED: 1,
  VERIFIED: 2,
}

export enum ConnectBusinessEmailSteps {
  VERIFY_EMAIL = 'verify_email',
  AUTHENTICATE_DOMAIN = 'authenticate_domain',
  ACCEPT_TERMS = 'accept_terms',
  EDIT = 'edit',
  CREATE = 'create',
}

export const REGEXP_TAG_OPEN = /<(?!(mc-|<?inline-button-anchor:|<?url:)|(\/mc-))/g
export const REGEXP_TAG_CLOSE =
  /((<\/?mc-[^>].*?)|(<<inline-button-anchor:[^>]*?>?)|(<<url:[^>]*?>?))*>/g

export const TAG_OPEN_ORIGINAL = '<'
export const TAG_OPEN_ENCRYPT = '&lt;'
export const TAG_CLOSE_ORIGINAL = '>'
export const TAG_CLOSE_ENCRYPT = '&gt;'
export const AMP_ORIGINAL = '&'
export const AMP_ENCRYPT = '&amp;'

export const defaultBusinessEmailLabel = 'Default Manychat Address'

export const DEFAULT_EMAIL = 'noreply@many-mail.io'

import pick from 'lodash/pick'
import { l } from '@manychat/manyui'

import { AiIntentTrigger } from 'apps/aiIntents/interfaces/interfaces'
import { AiIntentsActions } from 'apps/aiIntents/redux/aiIntentsSlice'
import * as defaultReplyActions from 'apps/defaultReply/actions/defaultReplyActions'
import { ExternalTriggerStatusType } from 'apps/externalTriggers/externalTriggerConstants'
import { IExternalTrigger } from 'apps/externalTriggers/externalTriggerInterfaces'
import * as externalTriggersActions from 'apps/externalTriggers/externalTriggersActions'
import {
  setExternalTriggerStatus,
  saveExternalTrigger,
} from 'apps/externalTriggers/externalTriggersActions'
import {
  activateWidgetWithoutValidation,
  createWidgetBase,
} from 'apps/growthTools/actions/widgetActionsTyped'
import * as widgetsActions from 'apps/growthTools/actions/widgetsActions'
import { saveWidget } from 'apps/growthTools/actions/widgetsActions'
import { IWidget } from 'apps/growthTools/growthToolsInterfaces'
import { WidgetStatusType } from 'apps/growthTools/models/Widget/constants'
import {
  createKeyword,
  keywordsActions as keywrodsActionsFromSlice,
  saveKeywordStatus,
} from 'apps/keywords/keywordsSlice'
import keywordModel from 'apps/keywords/models/KeywordRule'
import * as storyMentionReplyActions from 'apps/storyMentionReply/actions/storyMentionReplyActions'
import * as triggerRuleActions from 'apps/triggerRules/actions/triggerRuleActions'
import { activateRuleWithoutValidation } from 'apps/triggerRules/actions/triggerRuleActionsTyped'
import triggerRuleModel from 'apps/triggerRules/models/TriggerRule'
import { TriggerRuleStatusType } from 'apps/triggerRules/models/TriggerRule/constants'
import { ITriggerRule } from 'apps/triggerRules/triggerRulesInterfaces'
import * as welcomeMessageActions from 'apps/welcomeMessage/actions/welcomeMessageActions'
import { updateCartReplyEnabled } from 'apps/whatsApp/redux/cartReplySlice'
import { setFirstIgNodeAsWithin24IfPossible } from 'common/builder/actions/builderNodeTypedActions'
import { alert } from 'common/core'
import { IAsyncThunkAction, IAsyncThunkActionWithReturnValue } from 'common/core/interfaces/actions'
import { TriggerType } from 'common/flow/constants/TriggerType'
import { ITriggerId } from 'common/flow/flowTriggersInterfaces'
import * as flowSelectors from 'common/flow/selectors/flowSelectors'
import { isInstagramCgt } from 'common/flow/utils/isInstagramCgt'
import { AiIntentStatus } from 'shared/api/requests/aiIntents/schemas'
import { getClientId } from 'utils/clientId'
import { analyticsService } from 'utils/services/analytics'

export const createTrigger = (
  flowId: string,
  triggerType: TriggerType,
  initial: UnsafeAnyObject = {},
  options: { addHomeTabParams?: boolean } = {},
): IAsyncThunkAction<{ item: { triggerId: string } } | null> => {
  return async (dispatch, getState) => {
    const flow = flowSelectors.getById(getState(), flowId)
    const homeTabParams = options.addHomeTabParams ? pick(flow, 'lesson', 'quick_campaign') : {}

    let action
    switch (triggerType) {
      case TriggerType.KEYWORD:
        action = createKeyword(keywordModel.create({ ...initial, ns: flowId }))
        break
      case TriggerType.AI_INTENT:
        action = AiIntentsActions.createTrigger(initial.intent, initial.channel, flowId)
        break
      case TriggerType.WIDGET:
        action = createWidgetBase({
          ...initial,
          ns: flowId,
          name: '',
          ...homeTabParams,
        })
        break
      case TriggerType.RULE:
        action = triggerRuleActions.createTriggerRuleBase(
          triggerRuleModel.create({ ...initial, ns: flowId }),
        ) as unknown as Promise<{ item: { triggerId: string } } | null>
        break
      case TriggerType.EXTERNAL_TRIGGER:
        action = externalTriggersActions.createExternalTrigger(
          {
            ...initial,
            flow_ns: flowId,
          },
          null,
        )
        break
    }

    // @ts-expect-error we don't have correct typings for these cases
    return action ? dispatch(action) : null
  }
}

export const setTriggerEnabled = (
  flowId: string,
  triggerId: ITriggerId,
  enabled: boolean,
  options: { source: string },
): IAsyncThunkActionWithReturnValue<boolean> => {
  return async (dispatch, getState) => {
    const { source } = options

    const trigger = flowSelectors.getFlowTriggerByTriggerId(getState(), flowId, triggerId)

    let isSuccess = false

    switch (trigger?.triggerType) {
      case TriggerType.KEYWORD:
        {
          const status = enabled
            ? keywordModel.KeywordStatus.LIVE
            : keywordModel.KeywordStatus.DRAFT
          const result = await dispatch(
            saveKeywordStatus({ id: trigger.id, status, options: { silent: true, source } }),
          )

          isSuccess = Boolean(result)
        }
        break
      case TriggerType.AI_INTENT:
        {
          const status = enabled ? AiIntentStatus.ACTIVE : AiIntentStatus.DISABLED
          isSuccess = await dispatch(AiIntentsActions.updateStatus(trigger.id, status))
        }
        break
      case TriggerType.WIDGET:
        {
          const status = enabled ? WidgetStatusType.ACTIVE : WidgetStatusType.DRAFT
          isSuccess = await dispatch(
            widgetsActions.setWidgetStatus(trigger.id, status, {
              silent: true,
              isInFlowBuilder: true,
              source,
              flowId,
            }),
          )
        }
        break
      case TriggerType.RULE:
        {
          const status = enabled ? TriggerRuleStatusType.ACTIVE : TriggerRuleStatusType.INACTIVE
          isSuccess = await dispatch(
            triggerRuleActions.setTriggerRuleStatusAndSave(trigger.id, status, { source }),
          )
        }
        break
      case TriggerType.EXTERNAL_TRIGGER:
        {
          const status = enabled
            ? ExternalTriggerStatusType.ACTIVE
            : ExternalTriggerStatusType.INACTIVE
          isSuccess = await dispatch(
            externalTriggersActions.setExternalTriggerStatusAndSave(trigger.id, status, {
              source,
            }),
          )
        }
        break
      case TriggerType.DEFAULT_REPLY:
        {
          await dispatch(
            defaultReplyActions.updateTriggerDefaultReplyEnabled(trigger.channel, enabled),
          )
        }
        break
      case TriggerType.CART_REPLY:
        {
          await dispatch(updateCartReplyEnabled({ enabled }))
        }
        break

      case TriggerType.WELCOME_MESSAGE:
        {
          await dispatch(
            welcomeMessageActions.updateWelcomeMessageEnabled(enabled, trigger.channel),
          )
        }
        break
      case TriggerType.STORY_MENTION_REPLY:
        {
          await dispatch(storyMentionReplyActions.updateStoryMentionReplyEnabled(enabled))
        }
        break
    }

    if (!isSuccess) {
      return false
    }

    if (enabled) {
      alert(l.translate('Trigger has been turned on'), 'success')
    } else {
      alert(l.translate('Trigger has been turned off'))
    }

    return true
  }
}

interface SaveAndActivateProps {
  trigger: IWidget | ITriggerRule | AiIntentTrigger | IExternalTrigger
  newIntent?: string | null
}

export const saveAndActivateTrigger =
  (props: SaveAndActivateProps): IAsyncThunkAction<boolean> =>
  async (dispatch) => {
    const isSaved = await dispatch(saveTrigger(props))

    if (!isSaved) {
      return false
    }

    if (props.trigger.enabled || props.trigger.was_activated_once) {
      return true
    }

    const isActivated = await dispatch(activateTriggerWithoutValidation({ trigger: props.trigger }))

    analyticsService.sendEvent('TRIGGER_PREACTIVATION_RESULT_ON_SAVE', {
      isActivated,
      triggerId: props.trigger.triggerId,
    })

    if (!isActivated) {
      return true
    }

    alert(l.translate('Trigger has been saved and turned on'), 'success')

    return true
  }

export const saveTrigger =
  (props: SaveAndActivateProps): IAsyncThunkAction<boolean> =>
  async (dispatch) => {
    let isSaved = false

    switch (props.trigger.triggerType) {
      case TriggerType.WIDGET:
        isSaved = await dispatch(saveWidget(props.trigger.widget_id))
        break
      case TriggerType.RULE:
        isSaved = await dispatch(
          triggerRuleActions.saveTriggerRule(props.trigger.rule_id, undefined, {
            hideSuccessAlert: true,
          }),
        )
        break
      case TriggerType.AI_INTENT:
        isSaved = await dispatch(
          AiIntentsActions.update({
            intentId: props.trigger.id,
            intent: props.newIntent ?? '',
            flowId: props.trigger.flowId || undefined,
          }),
        )
        break
      case TriggerType.EXTERNAL_TRIGGER:
        isSaved = await dispatch(saveExternalTrigger(props.trigger.external_trigger_id))
    }

    return isSaved
  }

export const activateTriggerWithoutValidation =
  ({
    trigger,
  }: {
    trigger: IWidget | ITriggerRule | AiIntentTrigger | IExternalTrigger
  }): IAsyncThunkActionWithReturnValue<boolean> =>
  async (dispatch) => {
    let activated = false

    switch (trigger.triggerType) {
      case TriggerType.WIDGET:
        activated = await dispatch(activateWidgetWithoutValidation({ widgetId: trigger.widget_id }))
        break
      case TriggerType.RULE:
        activated = await dispatch(activateRuleWithoutValidation(trigger))
        break
      case TriggerType.AI_INTENT:
        activated = await dispatch(
          AiIntentsActions.updateStatus(trigger.id, AiIntentStatus.ACTIVE, {
            silent: true,
            doNotLogStatusChange: true,
          }),
        )
        break
      case TriggerType.EXTERNAL_TRIGGER:
        activated = await dispatch(
          setExternalTriggerStatus(trigger.external_trigger_id, ExternalTriggerStatusType.ACTIVE, {
            silent: true,
            doNotLogStatusChange: true,
          }),
        )
    }

    return activated
  }

export const deleteTrigger = ({
  flowId,
  triggerId,
  builderId,
}: {
  flowId: string
  triggerId: ITriggerId
  builderId: string
}): IAsyncThunkAction => {
  return async (dispatch, getState) => {
    const trigger = flowSelectors.getFlowTriggerByTriggerId(getState(), flowId, triggerId)

    switch (trigger?.triggerType) {
      case TriggerType.KEYWORD:
        dispatch(
          keywrodsActionsFromSlice.deleteItemFx({
            payload: {
              query: { rule_id: trigger.id, client_id: getClientId() },
            },
            id: trigger.id,
          }),
        )
        break
      case TriggerType.AI_INTENT:
        dispatch(AiIntentsActions.remove(trigger.id))
        break
      case TriggerType.WIDGET:
        if (isInstagramCgt(trigger)) {
          dispatch(setFirstIgNodeAsWithin24IfPossible(builderId))
        }
        dispatch(widgetsActions.deleteWidget(trigger.id))
        break
      case TriggerType.RULE:
        dispatch(triggerRuleActions.deleteTriggerRule(trigger.id))
        break
      case TriggerType.EXTERNAL_TRIGGER:
        dispatch(externalTriggersActions.deleteExternalTrigger(trigger.id))
        break
    }
  }
}

export const setTriggerCaption = (
  triggerType: TriggerType,
  triggerId: number | string,
  caption: string,
): IAsyncThunkAction => {
  return async (dispatch) => {
    switch (triggerType) {
      case TriggerType.WIDGET:
        dispatch(widgetsActions.setWidgetName(caption, triggerId))
        break
      case TriggerType.RULE:
        dispatch(triggerRuleActions.setTriggerRuleTitle(triggerId, caption))
        break
      case TriggerType.EXTERNAL_TRIGGER:
        dispatch(externalTriggersActions.setExternalTriggerTitle(triggerId as number, caption))
        break
    }
  }
}

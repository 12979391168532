import { getById } from 'common/builder/selectors/builder/builderStateSelectors'
import { FieldGroupId, FieldType } from 'common/fields/entities/enums'
import { FullContactDataField } from 'common/fields/entities/field'
import {
  getApplicationsFields,
  getConditionFields,
  getPersonalizationFields,
} from 'common/fields/linking/accountAvailableFields'
import { fieldNot, ifAll, notArray } from 'common/fields/linking/fieldsFilters'
import { getFlowContextFields } from 'common/flow/selectors/flowFieldsSelectors'

const flowPersonalizationFilter = ifAll(
  notArray,
  fieldNot(['app.10.object.cart.products_identifiers']),
)

export const getFlowPersonalizationFields = (state: RootState, builderId: string) => {
  const personalizationFields = getPersonalizationFields(state)
  const flowId = getById(state, builderId)?.flow
  const flowContextFields = getFlowContextFields(state, flowId)

  return [...personalizationFields, ...flowContextFields.filter(flowPersonalizationFilter)]
}

export const getFlowConditionFields = (state: RootState, builderId: string) => {
  const conditionFields = getConditionFields(state)
  const flowId = getById(state, builderId)?.flow
  const flowContextFields = getFlowContextFields(state, flowId)

  return [...conditionFields, ...flowContextFields]
}

export const getRequestBodyFields = (state: RootState, builderId: string) => {
  return [...getFlowPersonalizationFields(state, builderId), FullContactDataField]
}

export const getFormulaInputFields = (state: RootState, builderId: string) => {
  return getFlowPersonalizationFields(state, builderId).filter(
    (field) => field.type === FieldType.NUMBER,
  )
}

export const getApplicationActionFields = (state: RootState, builderId: string) => {
  const flowId = getById(state, builderId)?.flow
  return [...getApplicationsFields(state), ...getFlowContextFields(state, flowId)]
}

export const getApplicationActionMappingFields = (state: RootState) => {
  return getApplicationsFields(state).filter((field) => field.group === FieldGroupId.USER)
}

import { useCallback } from 'react'
import { useAppSelector } from 'reduxTyped'

import { ChannelType } from 'common/core/constants/ChannelType'
import { getPageId } from 'common/core/selectors/appSelectors'
import { getInstagramChannelIsNewApi } from 'common/core/selectors/channelSelectors'
import { addURLParam } from 'utils/url'

enum PERMISSION_TYPE {
  INSTAGRAM_PERMISSIONS = 'instagram_permissions',
  TIKTOK_PERMISSIONS = 'tiktok_permissions',
}

export const useGetPermissionHref = () => {
  const pageId = useAppSelector(getPageId)
  const isNewIgApi = useAppSelector(getInstagramChannelIsNewApi)

  const getPermissionHref = useCallback(
    (channelType: ChannelType, returnUrl?: string) => {
      let url = `/reloadPermissions?page_id=${pageId}`

      if (channelType === ChannelType.INSTAGRAM && isNewIgApi)
        url = addURLParam(url, 'type', PERMISSION_TYPE.INSTAGRAM_PERMISSIONS)

      if (channelType === ChannelType.TIKTOK)
        url = addURLParam(url, 'type', PERMISSION_TYPE.TIKTOK_PERMISSIONS)

      url = addURLParam(url, 'return', returnUrl ?? '/')

      return url
    },
    [isNewIgApi, pageId],
  )

  return {
    getPermissionHref,
  }
}

import { api } from 'shared/api'
import { whatsAppApiSchemas } from 'shared/api/requests/whatsApp/schemas'

export const WhatsAppApi = {
  setWhatsAppBusinessProfile: api.account.createPost({
    url: '/whatsApp/setWhatsAppBusinessProfile',
    schemas: whatsAppApiSchemas.setWhatsAppBusinessProfile,
  }),
  setWhatsAppProfilePhoto: api.account.createPost({
    url: '/whatsApp/setWhatsAppProfilePhoto',
    schemas: whatsAppApiSchemas.setWhatsAppProfilePhoto,
  }),
  getStats: api.account.createGet({
    url: '/settings/getWABillingStats',
    schemas: whatsAppApiSchemas.getStats,
  }),
  disconnectChannel: api.account.createPost({
    url: '/whatsApp/disconnectChannel',
    schemas: whatsAppApiSchemas.disconnectChannel,
  }),
  verifyCode: api.account.createPost({
    url: '/whatsApp/verifyCode',
    schemas: whatsAppApiSchemas.verifyCode,
  }),
  requestCode: api.account.createPost({
    url: '/whatsApp/startPhoneReconnect',
    schemas: whatsAppApiSchemas.requestCode,
  }),
  coexistenceImportContacts: api.account.createPost({
    url: '/whatsApp/coexistenceImportContacts',
    schemas: whatsAppApiSchemas.coexistenceImportContacts,
  }),
  // message templates
  fetchMessageTemplateCategories: api.account.createGet({
    url: '/messageTemplates/messageTemplateCategories',
    schemas: whatsAppApiSchemas.fetchMessageTemplateCategories,
  }),
  listMessageTemplateLanguages: api.account.createGet({
    url: '/messageTemplates/listMessageTemplateLanguages',
    schemas: whatsAppApiSchemas.listMessageTemplateLanguages,
  }),
  getMessageTemplatePack: api.account.createGet({
    url: '/messageTemplates/getMessageTemplatePack',
    schemas: whatsAppApiSchemas.getMessageTemplatePack,
  }),
  listMessageTemplatesPacks: api.account.createGet({
    url: '/messageTemplates/listMessageTemplatesPacks',
    schemas: whatsAppApiSchemas.listMessageTemplatesPacks,
  }),
  createMessageTemplate: api.account.createPost({
    url: '/messageTemplates/createMessageTemplate',
    schemas: whatsAppApiSchemas.createMessageTemplate,
  }),
  editMessageTemplate: api.account.createPost({
    url: '/messageTemplates/editMessageTemplate',
    schemas: whatsAppApiSchemas.editMessageTemplate,
  }),
  deleteMessageTemplates: api.account.createPost({
    url: '/messageTemplates/deleteMessageTemplates',
    schemas: whatsAppApiSchemas.deleteMessageTemplates,
  }),
  updateDefaultMessageTemplate: api.account.createPost({
    url: '/messageTemplates/updateDefaultMessageTemplate',
    schemas: whatsAppApiSchemas.updateDefaultMessageTemplate,
  }),
  updateMessageTemplateVariables: api.account.createPost({
    url: '/messageTemplates/updateMessageTemplateVariables',
    schemas: whatsAppApiSchemas.updateMessageTemplateVariables,
  }),

  // account
  getChannelData: api.account.createGet({
    url: '/whatsApp/getChannelData',
    schemas: whatsAppApiSchemas.getChannelData,
  }),
  refreshHealthWidgetData: api.account.createPost({
    url: '/whatsApp/refreshHealthWidgetData',
    schemas: whatsAppApiSchemas.refreshHealthWidgetData,
  }),
  clearNewBusinessAccountNameData: api.account.createPost({
    url: '/whatsApp/clearNewBusinessAccountNameData',
    schemas: whatsAppApiSchemas.clearNewBusinessAccountNameData,
  }),
  confirmNewBusinessAccountName: api.account.createPost({
    url: '/whatsApp/confirmNewBusinessAccountName',
    schemas: whatsAppApiSchemas.confirmNewBusinessAccountName,
  }),

  // broadcast
  getBicLimits: api.account.createGet({
    url: '/whatsApp/getBicLimits',
    schemas: whatsAppApiSchemas.getBicLimits,
  }),
  estimateBroadcastCost: api.account.createPost({
    url: '/whatsApp/estimateBroadcastCost',
    schemas: whatsAppApiSchemas.estimateBroadcastCost,
  }),

  // catalog
  setCatalogVisibility: api.account.createPost({
    url: '/whatsApp/setCatalogVisibility',
    schemas: whatsAppApiSchemas.setCatalogVisibility,
  }),
  getWhatsAppCommerceSettings: api.account.createGet({
    url: '/whatsApp/getWhatsAppCommerceSettings',
    schemas: whatsAppApiSchemas.getWhatsAppCommerceSettings,
  }),
  needsPermissionRefresh: api.account.createGet({
    url: '/whatsAppCatalog/needsPermissionRefresh',
    schemas: whatsAppApiSchemas.needsPermissionRefresh,
  }),

  // carts
  fixBrokenCarts: api.account.createGet({
    url: '/carts/fixBrokenCarts',
    schemas: whatsAppApiSchemas.fixBrokenCarts,
  }),

  // ads
  getAdAccountsList: api.account.createGet({
    url: '/whatsApp/getAdAccountsList',
    schemas: whatsAppApiSchemas.getAdAccountsList,
  }),
  getAdsList: api.account.createGet({
    url: '/whatsApp/getAdsList',
    schemas: whatsAppApiSchemas.getAdsList,
  }),
}

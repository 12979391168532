import * as atypes from '../filterReduxActionTypes'

const initialState = { query: '', items: [], fetching: false, error: null }

export default function filterFieldValuesReducer(state = initialState, action) {
  switch (action.type) {
    case atypes.FILTER_FIELD_VALUES_FETCH_REQUEST: {
      return { ...state, query: action.query, fetching: true, error: null }
    }
    case atypes.FILTER_FIELD_VALUES_FETCH_RESPONSE: {
      const { query, data } = action
      if (query !== state.query) {
        return state
      }
      return { ...state, items: data.values, fetching: false, error: null }
    }
    case atypes.FILTER_FIELD_VALUES_FETCH_ERROR: {
      const { query, error } = action
      if (query !== state.query) {
        return state
      }
      return { ...state, items: [], fetching: false, error }
    }
  }

  return state
}

import Tints from './tints.json'

const palettes = {
  semantic: {
    primary: Tints.blue[3],
    accent: Tints.purple[3],
    success: Tints.green[3],
    warning: Tints.orange[3],
    danger: Tints.red[3],
  },
  typography: {
    body: Tints.neutral[1],
    secondary: Tints.neutral[2],
    disabledFG: Tints.neutral[3],
    border: Tints.neutral[4],
    disabledBG: Tints.neutral[5],
    hover: Tints.neutral[5],
    background: Tints.neutral[6],
    contentBG: Tints.neutral[7],
  },
}

export default palettes

import { WidgetTypes } from 'apps/growthTools/models/Widget/constants'
import { TriggerEventType } from 'apps/triggerRules/models/TriggerRule/constants'
import { ChannelType } from 'common/core/constants/ChannelType'
import { FlowTriggerStatus, FsTriggerModel } from 'shared/api/requests/cms/schemas'

import { isTriggerMatchesContactEventFilter } from './contactEvents'
import { isExternalTrigger } from './externalTriggers'
import { isTriggerMatchesFbFilter } from './facebook'
import { isTriggerMatchesIgFilter } from './instagram'
import { isTriggerMatchesSmsFilter } from './sms'
import { isTriggerMatchesTgFilter } from './telegram'
import { isTriggerMatchesTtFilter } from './tiktok'
import { FiltersFromQuery } from './types'
import { isTriggerMatchesWaFilter } from './whatsapp'

export const isTriggerMatchesFilters = ({
  isDisabled,
  statusFiltersQuery,
  triggerFiltersQuery,
  triggerChannel,
  triggerModel,
  triggerEventType,
  widgetType,
}: {
  isDisabled: boolean
  statusFiltersQuery: FlowTriggerStatus | null
  triggerFiltersQuery: FiltersFromQuery[]
  triggerChannel: ChannelType | null
  triggerModel: FsTriggerModel
  triggerEventType: TriggerEventType | null
  widgetType: WidgetTypes | null
}) => {
  if (statusFiltersQuery === FlowTriggerStatus.ACTIVE) {
    if (triggerFiltersQuery.length === 0 && !isDisabled) {
      return true
    }

    if (isDisabled) {
      return false
    }
  }

  for (const filter of triggerFiltersQuery) {
    if (isTriggerMatchesIgFilter({ filter, triggerModel, widgetType, triggerChannel })) {
      return true
    }

    if (isTriggerMatchesFbFilter({ filter, triggerModel, widgetType, triggerChannel })) {
      return true
    }

    if (isTriggerMatchesWaFilter({ filter, triggerModel, widgetType, triggerChannel })) {
      return true
    }

    if (isTriggerMatchesTgFilter({ filter, triggerModel, widgetType, triggerChannel })) {
      return true
    }

    if (isTriggerMatchesTtFilter({ filter, triggerModel, widgetType, triggerChannel })) {
      return true
    }

    if (isTriggerMatchesSmsFilter({ filter, triggerModel, triggerChannel })) {
      return true
    }

    if (isTriggerMatchesContactEventFilter({ filter, triggerModel, triggerEventType })) {
      return true
    }

    if (triggerModel === FsTriggerModel.EXTERNAL_TRIGGER && isExternalTrigger(filter)) {
      return true
    }
  }

  return false
}

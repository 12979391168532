import { z } from 'zod'

import type { PostCoveredArea, StoryCoveredArea } from 'apps/growthTools/models/Widget/constants'
import type {
  CommentContainsValues,
  KeywordsConditionValues,
} from 'common/builder/models/constants'
import { ProErrorSchema } from 'shared/api/common/schemas/errors'

const structureSchema = z.object({})
const easyBuilderStatsSchema = z.object({
  read: z
    .object({
      read_total: z.number().nullish(),
      read_unq: z.number().nullish(),
      read_segment_id: z.string().nullish(),
    })
    .nullish(),

  send: z
    .object({
      sent_total: z.number().nullish(),
      sent_unq: z.number().nullish(),
      sent_segment_id: z.string().nullish(),
    })
    .nullish(),

  delivered: z
    .object({
      delivered_total: z.number().nullish(),
      delivered_unq: z.number().nullish(),
      delivered_segment_id: z.string().nullish(),
    })
    .nullish(),

  opened_link: z
    .object({
      clicked_total: z.number().nullish(),
      clicked_unq: z.number().nullish(),
      clicked_segment_id: z.string().nullish(),
    })
    .nullish(),

  collect_email: z
    .object({
      respondents_total: z.number().nullish(),
      respondents_unq: z.number().nullish(),
      respondents_content_id: z.number().nullish(),
    })
    .nullish(),
})

export enum PathField {
  COMMENT_CONTAINS = 'comment_contains',
  INCLUDE_KEYWORDS = 'include_keywords',
  DM_MESSAGE_TEXT = 'dm_link_message_text',
  DM_LINK = 'dm_link_button_link',
  DM_BUTTON = 'dm_link_button_caption',
  POST_COVERED_AREA = 'post_covered_area',
  POST_ID = 'post_id',
  WELCOME_MESSAGE_TEXT = 'dm_welcome_message_text',
  WELCOME_MESSAGE_LABEL = 'dm_welcome_button_caption',
  PUBLIC_REPLY_ENABLED = 'use_case_publicly_reply_to_comment',
  PUBLIC_REPLY_MESSAGES = 'public_reply_messages',
  COLLECT_EMAILS_ENABLED = 'use_case_collect_email',
  COLLECT_EMAILS_MESSAGE = 'question_collect_email',
  REQUEST_FOLLOW_ENABLED = 'use_case_ask_for_follow',
  AUTOMATICALLY_LIKE_REPLIES = 'use_case_automatically_like_replies',
  REQUEST_FOLLOW_MESSAGE = 'dm_follow_before_sending_link_text',
  FOLLOW_UP_ENABLED = 'use_case_follow_up',
  WELCOME_MESSAGE_ENABLED = 'use_case_welcome_message',
  CGT_FOLLOW_UP_MESSAGE = 'dm_reminder_text',
  DM_NOT_FOLLOWED_TEXT = 'dm_not_followed_text',
  DM_REMINDER_LINK_BUTTON_LINK = 'dm_reminder_link_button_link',
  DM_REMINDER_LINK_BUTTON_CAPTION = 'dm_reminder_link_button_caption',
  STORY_SELECT = 'story_select',
  STORY_ID = 'story_id',
  KEYWORDS_CONDITION = 'keywords_condition',
  KEYWORDS = 'keywords',
  AUTO_LIKE = 'auto_like',
  DM_FOLLOW_UP_MESSAGE = 'dm_follow_up',
  KEYWORD_RULES = 'keyword_rules',
  DM_LINK_BUTTON_1 = 'dm_link_button_1',
  DM_LINK_BUTTON_2 = 'dm_link_button_2',
  DM_LINK_BUTTON_3 = 'dm_link_button_3',
}

export interface PathFieldTypes {
  [PathField.COMMENT_CONTAINS]: CommentContainsValues
  [PathField.INCLUDE_KEYWORDS]: string[]
  [PathField.DM_MESSAGE_TEXT]: string
  [PathField.DM_LINK]: string
  [PathField.DM_BUTTON]: string
  [PathField.POST_COVERED_AREA]: PostCoveredArea
  [PathField.POST_ID]: string
  [PathField.STORY_SELECT]: StoryCoveredArea
  [PathField.STORY_ID]: string | null
  [PathField.KEYWORDS_CONDITION]: KeywordsConditionValues
  [PathField.KEYWORDS]: string[]
  [PathField.AUTO_LIKE]: boolean
  [PathField.AUTOMATICALLY_LIKE_REPLIES]: boolean
  [PathField.WELCOME_MESSAGE_TEXT]: string
  [PathField.WELCOME_MESSAGE_LABEL]: string
  [PathField.PUBLIC_REPLY_ENABLED]: boolean
  [PathField.PUBLIC_REPLY_MESSAGES]: string[]
  [PathField.COLLECT_EMAILS_ENABLED]: boolean
  [PathField.COLLECT_EMAILS_MESSAGE]: string
  [PathField.REQUEST_FOLLOW_ENABLED]: boolean
  [PathField.REQUEST_FOLLOW_MESSAGE]: string
  [PathField.FOLLOW_UP_ENABLED]: boolean
  [PathField.CGT_FOLLOW_UP_MESSAGE]: string
  [PathField.DM_FOLLOW_UP_MESSAGE]: string
  [PathField.DM_NOT_FOLLOWED_TEXT]: never
  [PathField.DM_REMINDER_LINK_BUTTON_LINK]: never
  [PathField.DM_REMINDER_LINK_BUTTON_CAPTION]: never
  [PathField.WELCOME_MESSAGE_ENABLED]: boolean
  [PathField.KEYWORD_RULES]: Array<{
    keywords: string[]
    condition: KeywordsConditionValues
  }>
  [PathField.DM_LINK_BUTTON_1]: EasyBuilderDmFormLinkButton
  [PathField.DM_LINK_BUTTON_2]: EasyBuilderDmFormLinkButton
  [PathField.DM_LINK_BUTTON_3]: EasyBuilderDmFormLinkButton
}

export enum EasyBuilderCampaignType {
  IG_CGT_REPLY = 'easy_generator',
  IG_CGT_REPLY_OPTIONAL_DM = 'easy_builder_cgt',
  IG_CGT_NEXT_POST = 'easy_builder_cgt_next_post',
  IG_CGT_NEXT_POST_MULTI_LINKS = 'easy_builder_cgt_next_post_multi_links',
  IG_STORY_REPLY = 'easy_builder_story_reply',
  IG_DIRECT_MESSAGE = 'easy_builder_dm',
}

export enum EasyBuilderFlowStatus {
  ACTIVE = 'active',
  TRIGGER_DRAFT = 'draft',
}

export const pathSchema = z.object({
  path: z.array(z.string()),
  field: z.string(),
  duplicate_of: z.nativeEnum(PathField).nullish(),
  validation: z
    .object({
      required: z.boolean().or(z.string()).nullish(),
      min: z.number().or(z.string()).nullish(),
      max: z.number().or(z.string()).nullish(),
      maxLength: z.number().nullish(),
      minLength: z.number().nullish(),
    })
    .nullish(),
})

export const pathsSchema = z.record(z.nativeEnum(PathField), pathSchema.nullable())

const responseConfigSchema = z.object({
  campaign_type: z.nativeEnum(EasyBuilderCampaignType),
  campaign_title: z.string(),
  template: z.object({
    version_id: z.number(),
    template_id: z.number(),
  }),
  structure: structureSchema,
  view: z.object({ paths: pathsSchema }),
})

const responseFlowSchema = z.object({
  flow_ns: z.string(),
  folder_id: z.number().nullish(),
  campaign_type: z.nativeEnum(EasyBuilderCampaignType),
  stats: easyBuilderStatsSchema,
  flow_name: z.string(),
  status: z.nativeEnum(EasyBuilderFlowStatus),
  structure: structureSchema,
  view: z.object({ paths: pathsSchema }),
  widget_id: z.number().nullish(),
})

export const easyBuilderSchemas = {
  getConfig: {
    response: responseConfigSchema,
    query: z.object({ campaign_type: z.nativeEnum(EasyBuilderCampaignType) }),
    path: z.undefined(),
  },
  getById: {
    response: responseFlowSchema,
    query: z.object({ flow_ns: z.string() }),
    path: z.undefined(),
  },
  create: {
    response: responseFlowSchema,
    query: z.undefined(),
    path: z.undefined(),
    request: z.object({
      structure: structureSchema,
      campaign_type: z.nativeEnum(EasyBuilderCampaignType),
      folder_id: z.number().nullish(),
    }),
    errors: {
      ProError: ProErrorSchema,
    },
  },
  edit: {
    response: responseFlowSchema,
    query: z.undefined(),
    path: z.undefined(),
    request: z.object({
      flow_ns: z.string(),
      structure: structureSchema,
    }),
  },
  setStatus: {
    response: z.object({ flow_ns: z.string(), status: z.nativeEnum(EasyBuilderFlowStatus) }),
    request: z.object({ flow_ns: z.string(), status: z.nativeEnum(EasyBuilderFlowStatus) }),
    query: z.undefined(),
    path: z.undefined(),
    errors: {
      ProError: ProErrorSchema,
    },
  },
}

export type ResponseConfig = z.infer<typeof responseConfigSchema>
export type EasyBuilderFlow = z.infer<typeof responseFlowSchema>
export type EasyBuilderFlowMeta = Omit<EasyBuilderFlow, 'structure' | 'view'>
export type EasyBuilderStructure = z.infer<typeof structureSchema>
export type EasyBuilderPath = z.infer<typeof pathSchema>
export interface PathMapped {
  path: string
  validation: EasyBuilderPath['validation']
  duplicate_of?: PathField | null
}
export type EasyBuilderPaths = z.infer<typeof pathsSchema>
export type EasyBuilderStats = z.infer<typeof easyBuilderStatsSchema>
export type EasyBuilderPathsMap = Record<PathField, PathMapped>

export enum EasyBuilderFormFlowState {
  EDITING = 'editing',
  DISABLED = 'disabled',
}

export interface EasyBuilderDmFormLinkButton {
  url: string
  caption: string
  is_active: boolean
}

import { z } from 'zod'

const phoneSchema = z.object({
  phone_id: z.number(),
  phone_number: z.string(),
  country_code: z.string(),
  subscribers_count: z.number(),
  is_primary: z.boolean(),
})

export const smsApiSchemas = {
  getData: {
    query: z.undefined(),
    path: z.undefined(),
    response: z.object({
      phones: z.array(phoneSchema),
    }),
  },
}

export type SmsPhoneData = z.infer<typeof phoneSchema>

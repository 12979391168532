import { IActionWithPayload } from 'common/core/interfaces/actions'
import { makeLimitedListReducer } from 'utils/factory'
import type { ILimitedList } from 'utils/factory/limitedList/interfaces'
import type { ILimitedListAction } from 'utils/factory/limitedList/interfaces/limitedListAction'

import * as atypes from '../tagsReduxActionTypes'
import { Tag } from '../type'

const InitialState = {
  byId: {},
  items: [],
  fetching: false,
  limiter: null,
  laterLimiter: null,
  fetchArgsHash: '',
  fetchRequestId: null,
  listLaterLimiter: null,
  listEarlyLimiter: null,
  totalTagsInAccount: null,
  selectedIds: [],
}

const listReducer = makeLimitedListReducer('tagsFolders', { initialState: InitialState })

export default function (state: ILimitedList<Tag>, action: IActionWithPayload) {
  state = listReducer(state, action as ILimitedListAction)

  switch (action.type) {
    case atypes.TAGS_FETCH_TOTAL_COUNT_RESPONSE: {
      return {
        ...state,
        totalTagsInAccount: action.data,
      }
    }
    case atypes.TAGS_SET_SELECTED_IDS: {
      return {
        ...state,
        selectedIds: action.payload,
      }
    }

    case atypes.TAGS_RESET_SELECTED_IDS: {
      return {
        ...state,
        selectedIds: [],
      }
    }
  }
  return state
}

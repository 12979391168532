import { AGENCY_FIELDS as FIELDS, EMAIL_VALUES } from './constants'

export const AGENCY = {
  [FIELDS.id]: null,
  [FIELDS.name]: '',
  [FIELDS.emailType]: EMAIL_VALUES.profile,
  [FIELDS.email]: '',
  [FIELDS.industries]: '',
  [FIELDS.description]: '',
  [FIELDS.websiteUrl]: '',
  [FIELDS.logoUrl]: '',
  [FIELDS.logoFileId]: null,
  [FIELDS.placeId]: '',
  [FIELDS.country]: '',
  [FIELDS.city]: '',
}

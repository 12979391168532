import { WidgetTypeNumeric, WidgetTypes } from 'apps/growthTools/models/Widget/constants'
import { ChannelType } from 'common/core/constants/ChannelType'
import { FsTriggerModel } from 'shared/api/requests/cms/schemas'

import { triggerType, isWidget, isIntent } from './triggerType'
import { FiltersFromQuery } from './types'

export const isTgChannel = (channel?: ChannelType | null) => channel === ChannelType.TELEGRAM

export const isTgKeyword = (query: FiltersFromQuery) =>
  isTgChannel(query.channel) && triggerType(query.trigger_model) && !query.widget_type

export const isTgIntent = (query: FiltersFromQuery) =>
  isTgChannel(query.channel) && isIntent(query.trigger_model) && !query.widget_type

export const isTgRefUrl = (query: FiltersFromQuery) =>
  isTgChannel(query.channel) &&
  isWidget(query.trigger_model) &&
  query.widget_type === WidgetTypeNumeric.MESSENGER_REF_URL

export const isAllTgTriggers = (query: FiltersFromQuery) =>
  isTgChannel(query.channel) && !query.trigger_model && !query.widget_type

export const isTriggerMatchesTgFilter = ({
  widgetType,
  filter,
  triggerModel,
  triggerChannel,
}: {
  widgetType: WidgetTypes | null
  filter: FiltersFromQuery
  triggerModel: FsTriggerModel
  triggerChannel: ChannelType | null
}) =>
  triggerChannel === ChannelType.TELEGRAM &&
  (isAllTgTriggers(filter) ||
    (triggerModel === FsTriggerModel.KEYWORD && isTgKeyword(filter)) ||
    (triggerModel === FsTriggerModel.INTENT && isTgIntent(filter)) ||
    (widgetType === WidgetTypes.MESSENGER_REF_URL && isTgRefUrl(filter)))

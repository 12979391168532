import { Icon, l } from '@manychat/manyui'

import {
  IntegrationType,
  IntegrationNameByType,
} from 'common/actions/integrations/common/constants/IntegrationType'
import {
  createIntegration,
  createIntegrationAction,
} from 'common/actions/integrations/common/model/helpers'
import * as mailchimpActions from 'common/actions/integrations/MailChimp/actions/mailchimpActions'
import { KnowledgeBaseLink } from 'common/core/components/SupportLinks'
import { AccountTargetPermissionsNames } from 'common/core/models/Account/constants'
import { analyticsService } from 'utils/services/analytics'

export const MailChimpActionTypes = {
  ADD_SUBSCRIBER: 'subscriber.add',
}

const handleSupportLinkClick = () => {
  analyticsService.sendEvent('SUPPORT.LINK_SETTINGS_INTEGRATIONS_MAIL_CHIMP_CLICK', {
    url: window.location.pathname,
  })
}

export const MailChimpConfig = createIntegration({
  type: IntegrationType.MAILCHIMP,
  name: IntegrationNameByType[IntegrationType.MAILCHIMP],
  icon: Icon.MailChimp,
  permissionsVariable: AccountTargetPermissionsNames.MAIL_CHIMP_INTEGRATION,
  description: () =>
    l.translate(
      'The integration provides you with an ability to save customers contacts from Manychat to MailChimp. <a href="{link}" target="_blank" class="text-primary pointer" onclick="handleSupportLinkClick" rel="noreferrer" >Learn more</a>',
      { handleSupportLinkClick, link: KnowledgeBaseLink.MAILCHIMP_INTEGRATION },
    ),
  createHref: true,
  reduxActions: mailchimpActions,
  actions: [
    createIntegrationAction({
      type: MailChimpActionTypes.ADD_SUBSCRIBER,
      description: l.translate('Subscribe an email address to one of your lists.'),
      name: l.translate('Add Contact to a List'),
    }),
  ],
})

import cloneDeep from 'lodash/cloneDeep'
import { v4 as uuid } from 'uuid'
import { l } from '@manychat/manyui'

import { IBlock } from 'common/builder/blocks/blockInterfaces'
import { BlockType } from 'common/builder/constants/BlockType'
import EntityType from 'common/builder/constants/EntityType'
import { NodeType } from 'common/builder/constants/NodeType'
import { DeepPartial, INode } from 'common/builder/nodes/nodeInterfaces'
import { Ability } from 'common/core/constants/Ability'

const NODE_DEFAULTS = {
  blocks: [],
  flow: null,
  contentId: null,
  deleted: false,
}

type GetInitialBlocks<T> = (node: T) => Array<Partial<IBlock>>
type IsEmpty<T> = (options: { node: T; blocks: IBlock[] }) => boolean
type IsEmptyBlock = (block: IBlock) => boolean

export default class BaseNodeConfig<T extends INode> {
  nodeType?: NodeType = undefined

  isStartingStep = false
  isDisabled = false
  isPro = false
  requiredAbilities: Ability[] = []
  canBeRoot = true

  getCaption = () => l.translate('Node')
  getToolbarCaption = () => ''
  getCaptionWithCount = (n: number) =>
    l.translate('{n, plural, one {# {caption}} other {# {caption}}}', {
      n,
      caption: this.getCaption(),
    })
  getNextStepCaption = () => ''

  icon = 'Builder-Content'
  color = '#ffffff'
  headerColor = '#e1e5ea'

  ALLOWED_BLOCK_TYPES: BlockType[] = []

  isBlockTypeAllowed = ({ block }: { block: IBlock }) => {
    return this.ALLOWED_BLOCK_TYPES.includes(block.type)
  }

  ALLOWED_BLOCK_MAX_COUNT = Infinity
  ALLOWED_BLOCK_MIN_COUNT = 0

  isAddBlockAllowed = ({ node }: { node: T }) => {
    return node.blocks.length < this.ALLOWED_BLOCK_MAX_COUNT
  }

  getDefaults = () => ({})

  getAttributes = () => {
    const defaults = this.getDefaults()
    const caption = this.getCaption()
    return Object.keys({ ...defaults, ...NODE_DEFAULTS, caption })
  }

  getInitialBlocks: GetInitialBlocks<T> = () => []

  create = (initial: DeepPartial<T> = {}): T => {
    const id = initial.id || uuid()
    const caption = this.getCaption()
    return Object.assign({ ...cloneDeep(NODE_DEFAULTS), caption }, this.getDefaults(), initial, {
      id,
      nodeType: this.nodeType,
      entity: EntityType.NODE,
      isStartingStep: this.isStartingStep,
    })
  }

  isEmpty: IsEmpty<T> = (): boolean => false

  _isEmptyBlock: IsEmptyBlock = () => false

  areBlocksEmpty = (blocks: IBlock[]) => {
    return blocks.every(this._isEmptyBlock)
  }
}

import BaseBlockConfig from 'common/builder/blocks/BaseBlockConfig'
import { IBlockBase, IDelayBlock } from 'common/builder/blocks/blockInterfaces'
import { BlockType } from 'common/builder/constants/BlockType'

class DelayBlockConfig extends BaseBlockConfig {
  blockType = BlockType.DELAY

  getDefaults = (): Omit<IDelayBlock, keyof IBlockBase> => ({
    time: 3,
    showTyping: true,
  })
}

export default new DelayBlockConfig()

export interface WhatsAppCoexistenceState {
  contactImportStatus: CoexistenceContactImportStatus
}

export enum CoexistenceContactImportStatus {
  INACTIVE = 'INACTIVE',
  ACTIVE = 'ACTIVE',
  FINISHED = 'FINISHED',
  ERROR = 'ERROR',
}

import React from 'react'

import cm from './Skeleton.module.css'

interface IProps {
  size?: number
  className?: string
}

const SkeletonAvatar = ({ size = 32, className = '' }: IProps) => (
  <div
    className={`${cm.skeleton} rounded-circle ${className}`}
    style={{ width: size, height: size }}
  />
)

export default SkeletonAvatar
SkeletonAvatar.displayName = 'Skeleton.Avatar'

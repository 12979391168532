import { l } from '@manychat/manyui'

import { localStorage } from 'utils'
import {
  handleThreadAssignedEvent,
  handleUpdateSubscriberInfo,
  handleUserThreadNewEvent,
} from 'apps/chat/actions/threadActionsTyped'
import {
  FilterTypes,
  ThreadsSortingTypes,
  StatusFilterType,
  Filter,
  DEFAULT_THREAD_LIMIT,
} from 'apps/chat/constants/LiveChatInterfaces'
import * as atypes from 'apps/chat/constants/LiveChatReduxActionTypes'
import { getAutomationPauseEndCaption } from 'apps/chat/helpers/pauseAutomationsHelpers'
import { setMessageReceived } from 'apps/chat/redux/notificationsSlice'
import {
  getIsThreadSatisfiesFilter,
  getSelectedThreads,
  getThreadsFilterData,
  getThreadsSorting,
  getThreadsStatusFilter,
  getCurrentThreadItem,
  getFolder,
  getFolderUserId,
  getThreadStatusesByStatusFilterAndFolder,
  getIsAdvancedFilterApplied,
  getThreadsSelectionType,
  getCurrentId,
} from 'apps/chat/selectors/threadSelectors'
import * as atypesAudience from 'common/audience/constants/AudienceReduxActionTypes'
import { SELECTION_TYPE } from 'common/audience/models/Subscriber'
import { alert } from 'common/core'
import { getUserId } from 'common/core/selectors/appSelectors'
import { getUserRole, UserRoles } from 'common/userRoles'
import * as API from 'constants/API'
import { isRequestError } from 'utils/api/mcApi/RequestError'
import { makeLimitedListActions, makeCurrentItemActions } from 'utils/factory'
import { analyticsService } from 'utils/services/analytics'
import errorTrackingService from 'utils/services/errorTrackingService'
import { anotherTabNotificationsListener } from 'utils/services/notificationsService'

import { LiveChatLocalStorageIds } from '../constants/LiveChatLocalStorageIds'
import { ThreadMessageType, ThreadSuccessBulkText } from '../constants/LiveChatThreads'
import { UserThreadNoteSocketEvents } from '../constants/UserThreadNoteSocketEvent'

const baseItemActions = makeCurrentItemActions('threads', { storePath: 'chat.threads.current' })
const baseListActions = makeLimitedListActions('threads', { storePath: 'chat.threads' })

export const dropThreadsList = baseListActions.drop

export const setCurrentThread = (id) => ({
  type: atypes.CHAT_THREADS_SET_CURRENT,
  id,
})

export const fetchThread = (threadId) => {
  return async (dispatch) => {
    try {
      return await dispatch({
        ...baseItemActions.fetch(threadId),
        $error: () => {},
      })
    } catch (error) {
      if (isRequestError(error) && error.reason === 'business-logic') return
      errorTrackingService.trackError(error)
    }
  }
}

export const closeAllThreads = () => {
  return async (dispatch) => {
    try {
      return await dispatch({
        ...baseListActions.closeAll(),
        $error: () => {},
      })
    } catch (error) {
      if (isRequestError(error) && error.reason === 'business-logic') {
        alert(error.response?.error, 'danger')
        return
      }
      errorTrackingService.trackError(error)
    }
  }
}

export const fetchThreads = () => {
  return async (dispatch, getState) => {
    const state = getState()

    const requestBodyData = {
      limit: DEFAULT_THREAD_LIMIT,
      sorting: getThreadsSorting(state),
      filter: getThreadsFilterData(state),
      status: getThreadsStatusFilter(state),
      folder: getFolder(state),
      folder_user_id: getFolderUserId(state),
    }

    try {
      return await dispatch(baseListActions.fetch(null, requestBodyData, { $error: () => {} }))
    } catch (error) {
      if (!isRequestError(error)) {
        const message = l.translate('Something went wrong, cannot fetch threads')

        alert(message, 'danger')

        errorTrackingService.trackError(error || new Error(message), {
          fingerprint: ['{{ message }}', message],
        })

        return
      }

      alert(error.response, 'danger')
    }
  }
}

export const fetchNextThreads = () => {
  return async (dispatch) => {
    try {
      return await dispatch(baseListActions.fetchNext({ $error: () => {} }))
    } catch (error) {
      if (!isRequestError(error)) {
        const message = l.translate('Something went wrong, cannot fetch next threads')

        alert(message, 'danger')

        errorTrackingService.trackError(error || new Error(message), {
          fingerprint: ['{{ message }}', message],
        })

        return
      }

      alert(error.response, 'danger')
    }
  }
}

export const takeThreadControl = (threadId) => {
  return async (dispatch) => {
    try {
      return await dispatch({
        type: atypes.THREAD_TAKE_THREAD_CONTROL,
        $fetch: [
          API.threads.endpoints.takeThreadControl,
          {
            method: 'POST',
            body: JSON.stringify({ subscriber_id: threadId }),
            headers: { 'Content-Type': 'application/json' },
          },
        ],
        $error: () => {},
      })
    } catch (error) {
      if (!isRequestError(error)) {
        const message = l.translate('Something went wrong, cannot take thread control')
        alert(message, 'danger')
        return
      }

      alert(error.response?.error, 'danger')
    }
  }
}

export const resetHandoverError = () => ({
  type: atypes.THREAD_RESET_HANDOVER_ERROR,
})

export const resetConversationRoutingError = () => ({
  type: atypes.THREAD_RESET_CONVERSATION_ROUTING_ERROR,
})

export const filterThreads = (filter, value) => {
  return (dispatch) => {
    dispatch(dropThreadsList())
    if (filter === FilterTypes.STATUS) {
      dispatch({ type: atypes.CHAT_THREADS_STATUS_FILTER_CHANGED, value })
    }

    if (filter === FilterTypes.TAGS_AND_CUFS) {
      dispatch({ type: atypes.CHAT_THREADS_TAGS_AND_CUFS_FILTER_CHANGED, value })
    }

    dispatch(fetchThreads())
  }
}

export const updateFilters = (filterType, value) => {
  return (dispatch) => {
    dispatch(dropThreadsList())

    if (filterType === Filter.CONVERSATIONS) {
      localStorage.setItem(LiveChatLocalStorageIds.CONVERSATIONS_FILTER, value)
      dispatch({
        type: atypes.UPDATE_CONVERSATIONS_FILTER,
        value: value,
      })
      dispatch({
        type: atypes.CHAT_THREADS_STATUS_FILTER_CHANGED,
        value: StatusFilterType.OPEN,
      })
    } else if (filterType === Filter.TEAM_MEMBER) {
      localStorage.setItem(LiveChatLocalStorageIds.TEAM_MEMBER_FILTER, value)
      dispatch({
        type: atypes.UPDATE_TEAM_MEMBERS_FILTER,
        value: value,
      })
    } else if (filterType === Filter.CHANNEL) {
      dispatch({
        type: atypes.UPDATE_CHANNELS_FILTER,
        value: value,
      })
    }

    dispatch(fetchThreads())
  }
}

export const sortThreads = (sorting) => {
  return (dispatch) => {
    if ([ThreadsSortingTypes.NEWEST, ThreadsSortingTypes.OLDEST].includes(sorting)) {
      dispatch(dropThreadsList())
      dispatch({ type: atypes.CHAT_THREADS_SORTING_CHANGED, sorting })
      dispatch(fetchThreads())
    }
  }
}

export const markThreadAsRead = (threadId) => {
  return async (dispatch, getState) => {
    const state = getState()

    const currentThread = state.chat.threads.current.item
    if (!currentThread || currentThread.id !== threadId || currentThread.lc_thread_read) {
      return
    }

    try {
      return await dispatch({
        ...baseListActions.markAsRead(null, { user_id: threadId }, { threadId }),
        $error: () => {},
      })
    } catch (error) {
      if (isRequestError(error) && error.reason === 'business-logic') return
      errorTrackingService.trackError(error)
    }
  }
}

export const updateThreadStatus = (threadId, status) => {
  return async (dispatch) => {
    try {
      return await dispatch({
        ...baseListActions.updateStatus(null, { user_id: threadId, thread_status: status }),
        $error: () => {},
      })
    } catch (error) {
      if (isRequestError(error) && error.reason === 'business-logic') return
      errorTrackingService.trackError(error, { fingerprint: 'update-status' })
      return false
    }
  }
}

export const setPauseForever = (thread) => {
  return async (dispatch) => {
    const threadId = thread.id
    analyticsService.sendEvent('LIVE_CHAT.AUTOMATION_PAUSE_SET', { threadId, delay: 'forever' })
    analyticsService.sendEvent('LIVE_CHAT.PAUSE.CLICK')
    try {
      const response = await dispatch(
        baseListActions.setPauseForever(null, { user_id: threadId }, { threadId }),
      )

      alert(l.translate('Automations paused forever'))

      const subscriber = {
        ts_automation_paused_until: null,
        ts_automation_paused_forever: true,
      }
      dispatch({
        type: atypesAudience.AUDIENCE_UPDATE_CURRENT_SUBSCRIBER,
        subscriber,
      })
      dispatch({
        type: atypesAudience.AUDIENCE_UPDATE_PAUSE_AUTOMATION,
        id: threadId,
        subscriber,
      })

      return response
    } catch (error) {
      if (isRequestError(error) && error.reason === 'business-logic') return
      errorTrackingService.trackError(error, { fingerprint: 'set-pause-forever' })
    }
  }
}

export const setPause = (thread, delay) => {
  return async (dispatch) => {
    const threadId = thread.id
    analyticsService.sendEvent('LIVE_CHAT.AUTOMATION_PAUSE_SET', { threadId, delay })
    analyticsService.sendEvent('LIVE_CHAT.PAUSE.CLICK')
    try {
      const response = await dispatch(
        baseListActions.setPause(null, { user_id: threadId, delay }, { threadId }),
      )
      const timeStamp = response.item?.ts_automation_paused_until

      if (timeStamp) {
        alert(getAutomationPauseEndCaption(timeStamp))

        const subscriber = {
          ts_automation_paused_until: timeStamp,
          ts_automation_paused_forever: false,
        }
        dispatch({
          type: atypesAudience.AUDIENCE_UPDATE_CURRENT_SUBSCRIBER,
          subscriber,
        })
        dispatch({
          type: atypesAudience.AUDIENCE_UPDATE_PAUSE_AUTOMATION,
          id: threadId,
          subscriber,
        })
      }

      return response
    } catch (error) {
      if (isRequestError(error) && error.reason === 'business-logic') return
      errorTrackingService.trackError(error, { fingerprint: 'set-pause' })
    }
  }
}

export const clearPause = (thread) => {
  return async (dispatch) => {
    const threadId = thread.id
    analyticsService.sendEvent('LIVE_CHAT.AUTOMATION_PAUSE_CLEARED', { threadId })

    try {
      const response = await dispatch(
        baseListActions.clearPause(null, { user_id: threadId }, { threadId }),
      )

      alert(l.translate('Automations resumed'))

      const subscriber = {
        ts_automation_paused_until: null,
        ts_automation_paused_forever: false,
      }
      dispatch({
        type: atypesAudience.AUDIENCE_UPDATE_CURRENT_SUBSCRIBER,
        subscriber,
      })
      dispatch({
        type: atypesAudience.AUDIENCE_UPDATE_PAUSE_AUTOMATION,
        id: threadId,
        subscriber,
      })

      return response
    } catch (error) {
      if (isRequestError(error) && error.reason === 'business-logic') return
      errorTrackingService.trackError(error, { fingerprint: 'clear-pause' })
    }
  }
}

export const setAssignment = (assignedUserId, threadId) => async (dispatch) => {
  try {
    return await dispatch(
      baseListActions.setAssignment(null, {
        assigned_user_id: assignedUserId,
        user_id: threadId,
      }),
    )
  } catch (error) {
    if (isRequestError(error) && error.reason === 'business-logic') return
    errorTrackingService.trackError(error, { fingerprint: 'set-assignment' })
  }
}

export const closeAllThreadsNotification = (data) => ({
  type: atypes.THREADS_CLOSE_ALL,
  data,
})

export const openAllThreadsNotification = (data) => ({
  type: atypes.THREADS_OPEN_ALL,
  data,
})

export const assignThreadsNotification = (data) => ({
  type: atypes.THREADS_ASSIGN,
  data,
})

export const threadUserReadNotification = (data) => ({
  type: atypes.THREAD_USER_READ,
  id: data.id,
  data,
})

export const unassignThreadsNotification = (data) => ({
  type: atypes.THREADS_UNASSIGN,
  data,
})

export const toggleChecked = (data) => ({
  type: atypes.CHAT_THREAD_TOGGLE_CHECKED,
  data,
})

export const clearSelection = () => ({
  type: atypes.CHAT_THREADS_CLEAR_SELECTION,
})

export const setAssignee = (data) => ({
  type: atypes.THREADS_SET_ASSIGNEE,
  data,
})

export const performBulkAction = (type, uuid) => {
  return async (dispatch, getState) => {
    const state = getState()
    const ids = Object.values(getSelectedThreads(state)).map((t) => t.id)
    const assignee = state.chat.threads.assignee

    const body = {
      type,
      ids,
      selection_type: getThreadsSelectionType(state),
      filter: getThreadsFilterData(state),
      status: getThreadsStatusFilter(state),
      folder: getFolder(state),
      folder_user_id: getFolderUserId(state),
      ...(uuid && { async: uuid }),
      ...(assignee && { assigned_user_id: assignee }),
    }

    try {
      const bulkActionResponse = await dispatch({
        ...baseListActions.bulkAction(null, body),
        $error: () => {},
      })

      if (!bulkActionResponse.async) {
        alert(l.translate('Done!'), 'success')
      }
    } catch (error) {
      if (isRequestError(error) && error.reason === 'business-logic') {
        alert(error.response?.error, 'danger')
        return
      }
      errorTrackingService.trackError(error)
    }
  }
}

export const toggleBulkSelection = () => {
  return (dispatch, getState) => {
    const state = getState()
    const { chat } = state
    const { selectionType } = chat.threads

    const include = selectionType === SELECTION_TYPE.EXCLUDE
    dispatch({
      type: atypes.CHAT_THREADS_SELECTION_TYPE_CHANGED,
      selectionType: include ? SELECTION_TYPE.INCLUDE : SELECTION_TYPE.EXCLUDE,
    })
  }
}

export const fetchInstagramStory = (link) => {
  return async (dispatch) => {
    try {
      return await dispatch({
        type: atypes.THREADS_INSTAGRAM_STORY_FETCH,
        $fetch: [link, { cache: 'no-store' }],
        $responseType: 'blob',
      })
    } catch (error) {
      if (isRequestError(error) && error.reason === 'business-logic') return
      errorTrackingService.trackError(error, { fingerprint: 'fetch-instagram-story' })
    }
  }
}

export const checkIsThreadSatisfiesCurrentFilter = (user_id) => {
  return async (dispatch, getState) => {
    const state = getState()
    const filter = getThreadsFilterData(state)

    try {
      return await dispatch(
        baseItemActions.checkSatisfiesFilter(null, { subscriber_id: user_id, filter }),
      )
    } catch (error) {
      if (isRequestError(error) && error.reason === 'business-logic') return
      errorTrackingService.trackError(error, { fingerprint: 'check-is-thread-satisfies-filter' })
    }
  }
}

export const handleThreadUpdatedNotification = (socketData) => {
  return async (dispatch) => {
    dispatch(baseListActions.updatedNotification(socketData))
    dispatch(baseItemActions.updatedNotification(socketData))
  }
}

export const handleThreadMessagedNotification = (socketData) => {
  return async (dispatch, getState) => {
    const state = getState()
    const currentUserId = getUserId(state)
    const assignedAgentId = socketData?.assignment?.user_id
    const { user_id: userId } = socketData

    if (
      currentUserId === assignedAgentId &&
      socketData?.last_lc_event?.type?.includes(ThreadMessageType.MSG_IN)
    ) {
      dispatch(setMessageReceived(Object.assign({}, socketData)))
    }

    const isThreadMatchStatusFilter = getThreadStatusesByStatusFilterAndFolder(state).includes(
      socketData?.lc_thread_status,
    )
    const isFilterApplied = getIsAdvancedFilterApplied(state)
    const isNeededToCheckThreadSatisfiesCurrentFilter = Boolean(
      isThreadMatchStatusFilter && isFilterApplied,
    )

    if (isNeededToCheckThreadSatisfiesCurrentFilter) {
      await dispatch(checkIsThreadSatisfiesCurrentFilter(userId))
      const isThreadSatisfiesFilter = getIsThreadSatisfiesFilter(getState(), userId)

      if (!isThreadSatisfiesFilter) {
        dispatch(baseListActions.deletedNotification(socketData.user_id))
        dispatch({ type: atypes.THREADS_DECREASE_TOTAL_THREADS_COUNT })
        return
      }
    }

    dispatch(baseListActions.updatedNotification(socketData))
    dispatch(baseItemActions.updatedNotification(socketData))
  }
}

export const updateCurrentThreadNotes = (socketData) => {
  return async (dispatch, getState) => {
    const currentThreadItem = getCurrentThreadItem(getState())
    if (
      currentThreadItem?.user_id === socketData?.user_id &&
      UserThreadNoteSocketEvents.includes(socketData?.type)
    ) {
      dispatch(
        baseItemActions.updatedNotification({
          ...currentThreadItem,
          notes: [socketData, ...currentThreadItem.notes],
        }),
      )
    }
  }
}

export const reloadThreads = () => {
  return async (dispatch, getState) => {
    const state = getState()

    dispatch(dropThreadsList())
    dispatch(fetchThreads())

    const currentThreadId = getCurrentId(state)
    if (currentThreadId) dispatch(fetchThread(currentThreadId))
  }
}

anotherTabNotificationsListener.on(['thread_updated', 'thread_read'], (data, dispatch) => {
  dispatch(handleThreadUpdatedNotification(data.model))
})
anotherTabNotificationsListener.on(['subscriber_updated'], (data, dispatch) => {
  dispatch(handleUpdateSubscriberInfo(data.model))
})
anotherTabNotificationsListener.on('thread_messaged', (data, dispatch) => {
  dispatch(handleThreadMessagedNotification(data.model))
})
anotherTabNotificationsListener.on('thread_closed', (data, dispatch) => {
  dispatch(closeAllThreadsNotification(data.model))
})
anotherTabNotificationsListener.on('thread_opened', (data, dispatch) => {
  dispatch(openAllThreadsNotification(data.model))
})
anotherTabNotificationsListener.on('assign_user_threads', (data, dispatch) => {
  dispatch(assignThreadsNotification(data.model))
})
anotherTabNotificationsListener.on('thread_unassigned', (data, dispatch) => {
  dispatch(unassignThreadsNotification(data.model))
})
anotherTabNotificationsListener.on('thread_assigned', (data, dispatch) => {
  dispatch(assignThreadsNotification(data.model))
})
anotherTabNotificationsListener.on('thread_user_read', (data, dispatch) => {
  dispatch(threadUserReadNotification(data))
})

anotherTabNotificationsListener.on('lc_threads_filter_changed', (data, dispatch, getState) => {
  const state = getState()
  const userRole = getUserRole(state)

  if (userRole !== UserRoles.AGENT) return
  dispatch(reloadThreads())
})

anotherTabNotificationsListener.on('account_user_role_update', (data, dispatch, getState) => {
  const state = getState()
  const currentUserId = getUserId(state)

  if (currentUserId !== data.user_id || data.new_role !== UserRoles.AGENT) return
  dispatch(reloadThreads())
})

anotherTabNotificationsListener.on('lc_sent', (data, dispatch) => {
  if (!data.model) return
  dispatch(handleThreadAssignedEvent(data.model))
  dispatch(handleUserThreadNewEvent(data.model))
  dispatch(updateCurrentThreadNotes(data.model))
})

anotherTabNotificationsListener.on('thread_bulk_action_complete', (data, dispatch, getState) => {
  if (getUserId(getState()) !== data.user_id) return

  const message = l.getString(ThreadSuccessBulkText[data.action_type])

  alert(message, 'success')
})

anotherTabNotificationsListener.on('thread_control_error_reset', (data, dispatch, getState) => {
  const state = getState()
  const currentThreadId = getCurrentId(state)
  const socketThreadId = data?.model?.subscriber_id
  if (currentThreadId === socketThreadId) dispatch(resetHandoverError())
})

import { createStore } from 'app/createStore'
import { alert } from 'common/core'

import { getInitialState } from './getInitialState'
import { api } from './initMCApi'

export const store = createStore({
  thunkExtraArgument: { api, alert },
  initialState: getInitialState(),
})

import * as atypes from 'apps/ads/constants/AdsReduxActionTypes'

const initialState = {
  verified: {},
}

export default function accountCurrencyReducer(state = initialState, action) {
  switch (action.type) {
    case atypes.ADS_ACCOUNT_CURRENCE_FETCH_RESPONSE: {
      return {
        ...state,
        [action.accountId]: action.data,
      }
    }
  }

  return state
}

export const generateQuery = (search: string) => {
  const searchParams = new URLSearchParams(search)
  const query: Record<string, string> = {}

  for (const [key, value] of searchParams.entries()) {
    if (value) {
      query[key] = value
    }
  }

  return query
}

import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { createSelector } from 'reselect'

import { getFbRnFlowData } from 'apps/collectOptIns/selectors/collectOptInsSelectors'
import {
  ProgressBarStep,
  RecurringNotificationWizardSteps,
} from 'apps/dashboard/components/RnLearning/components/FirstRecurringNotificationOnboarding/Components/FirstRecurringNotificationModal/constants'
import { ChannelForRn } from 'common/core/constants/ChannelType'
import { getFBChannel } from 'common/core/selectors/channelSelectors'
import { RecurringNotificationsState } from 'common/recurringNotifications/interfaces'
import { accountNotificationsListener } from 'utils/services/notificationsService'

const initialState = {
  isCreateNotificationView: false,
  createdTopicId: null,
  defaultTopicConcept: {
    generatedFlowNs: null,
    widgetId: null,
    isLoading: false,
  },
  wizardStep: RecurringNotificationWizardSteps.CLOSED,
  progressBarStep: RecurringNotificationWizardSteps.OVERVIEW,
  channel: null,
  isWizardLoading: false,
} as RecurringNotificationsState

const recurringNotifications = createSlice({
  name: 'counter',
  initialState,
  reducers: {
    toggleIsCreateNotificationView: (state) => {
      state.isCreateNotificationView = !state.isCreateNotificationView
    },
    setWizardStep: (state, { payload }: PayloadAction<RecurringNotificationWizardSteps>) => {
      state.wizardStep = payload
    },
    setChannel: (state, { payload }: PayloadAction<ChannelForRn | null>) => {
      state.channel = payload
    },
    setProgressBarStep: (state, { payload }: PayloadAction<ProgressBarStep>) => {
      state.progressBarStep = payload
    },
    setIsWizardLoading: (state, action: PayloadAction<boolean>) => {
      state.isWizardLoading = action.payload
    },
    setIsCreateNotificationView: (state, action) => {
      state.isCreateNotificationView = action.payload
    },
    setCreatedTopicId: (state, action) => {
      state.createdTopicId = action.payload
    },
    setGeneratedFlowNs: (state, action) => {
      state.defaultTopicConcept = {
        ...state.defaultTopicConcept,
        generatedFlowNs: action.payload,
        isLoading: false,
      }
    },
    setWidgetId: (state, action) => {
      state.defaultTopicConcept = { ...state.defaultTopicConcept, widgetId: action.payload }
    },
    setIsLoading: (state, action) => {
      state.defaultTopicConcept = { ...state.defaultTopicConcept, isLoading: action.payload }
    },
  },
})

export const getWizardStep = (state: RootState) => state.recurringNotifications.wizardStep
export const getChannel = (state: RootState) => state.recurringNotifications.channel
export const getProgressBarStep = (state: RootState) => state.recurringNotifications.progressBarStep
export const getIsWizardLoading = (state: RootState) => state.recurringNotifications.isWizardLoading

export const getIsCreateNotificationView = (state: RootState) =>
  state.recurringNotifications.isCreateNotificationView

export const getCreatedTopicId = (state: RootState) => state.recurringNotifications.createdTopicId

export const getGeneratedFlowNs = (state: RootState) =>
  state.recurringNotifications.defaultTopicConcept.generatedFlowNs

export const getWidgetId = (state: RootState) =>
  state.recurringNotifications.defaultTopicConcept.widgetId

export const getRefUrl = createSelector(
  [getFBChannel, getWidgetId, getFbRnFlowData],
  (fbChannel, widgetId, fbRnFlowData) => {
    const fbPageId = fbChannel?.fb_page_id
    const flowWidgetId = widgetId ?? fbRnFlowData?.widgetId
    return fbPageId && flowWidgetId ? `https://m.me/${fbPageId}?ref=w${flowWidgetId}` : null
  },
)

export const getIsLoadingGenerationFlow = (state: RootState) =>
  state.recurringNotifications.defaultTopicConcept.isLoading

export const {
  toggleIsCreateNotificationView,
  setWizardStep,
  setChannel,
  setProgressBarStep,
  setIsWizardLoading,
  setCreatedTopicId,
  setIsCreateNotificationView,
  setGeneratedFlowNs,
  setWidgetId,
  setIsLoading,
} = recurringNotifications.actions

export default recurringNotifications.reducer

accountNotificationsListener.on('default_follow_up_flow_deleted', (data, dispatch) => {
  dispatch(setProgressBarStep(RecurringNotificationWizardSteps.OVERVIEW))
})

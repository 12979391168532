import get from 'lodash/get'

import { calculateCTR } from 'utils/stats'

export function getStats(item) {
  const sent = get(item, 'stats.sent', 0)
  const read = get(item, 'stats.read', 0)
  const clicked = get(item, 'stats.clicked', 0)
  const delivered = get(item, 'stats.delivered') || 0

  const rr = Number(delivered) === 0 ? 0 : ((read / delivered) * 100).toFixed(1)
  const ctr = calculateCTR(clicked, delivered)

  return {
    rr,
    ctr,
    sent,
    read,
    clicked,
    delivered,
  }
}

export { flowBuilderEvents } from './MCAnalytics'
import { IdleQueue } from 'idlize/IdleQueue.mjs'

import { getAccountIdFromURL } from 'utils/accountId'
import { initializeUsercentricsListeners } from 'utils/getInfoAboutService'
import errorTrackingService from 'utils/services/errorTrackingService'

import AnalyticsService from './AnalyticsService'
import FacebookPixelService from './FacebookPixelService'
import GAnalytics from './GAnalytics'
import { GTMAnalytics } from './GTMAnalytics'
import MCAnalytics from './MCAnalytics'

export const dataLayer = new FacebookPixelService()
initializeUsercentricsListeners(dataLayer.refreshPermissions)

const queue = new IdleQueue({ ensureTasksRun: true })

const gAnalytics = new GAnalytics(() => window.ga)

const mcAnalytics = new MCAnalytics(getAccountIdFromURL, errorTrackingService, window.fbq)

export const gtmAnalytics = new GTMAnalytics()

export const analyticsService = new AnalyticsService(gAnalytics, mcAnalytics, queue)
initializeUsercentricsListeners(analyticsService.refreshPermissions)

export { GTMAnalyticsEvents } from './types'

import { LayoutWithSidebar } from 'utils/router/components/LayoutWithSidebar/LayoutWithSidebar'
import { getHandle } from 'utils/router/utils/getHandle'

export const DashboardRoutes = [
  {
    path: 'dashboard',
    alias: 'dashboard',
    query: ['campaignId'],
    Component: LayoutWithSidebar,
    children: [
      {
        index: true,
        handle: getHandle('profile'),
        lazy: () => import('./hometab.page'),
      },
      {
        path: 'flows',
        children: [
          {
            index: true,
            lazy: () => import('./flowList.page'),
          },
          {
            path: ':flowId/:mode?',
            handle: getHandle('flow'),
            lazy: () => import('./flow.page'),
          },
        ],
      },
    ],
  },
] as const

export const InsightsRoutes = [
  {
    path: 'insights',
    Component: LayoutWithSidebar,
    children: [
      {
        index: true,
        lazy: () => import('./insights.page'),
      },
    ],
  },
] as const

export const BillingRoutes = [
  {
    path: 'upgradeToPro',
    handle: getHandle('billing'),
    lazy: () => import('./hometab.page'),
  },
] as const

import React from 'react'
import { l } from '@manychat/manyui'

const WAVerificationDeclinedNotification = () => {
  return (
    <span>
      {l.translate(
        'Your WhatsApp business verification request has been declined by Facebook. You can try again in your Facebook Business Manager.',
      )}
    </span>
  )
}

export default WAVerificationDeclinedNotification

import BaseBlockConfig from 'common/builder/blocks/BaseBlockConfig'
import { IBlockBase, IEmailTriggerOpenBlock } from 'common/builder/blocks/blockInterfaces'
import { BlockType } from 'common/builder/constants/BlockType'
import { TriggerOnOpenUse } from 'common/builder/emailBuilder/emailBuilderConstants'

class EmailTriggerOpenBlockConfig extends BaseBlockConfig {
  blockType = BlockType.EMAIL_TRIGGER_OPEN

  getDefaults = (): Omit<IEmailTriggerOpenBlock, keyof IBlockBase> => ({
    targetId: null,
    triggerOnOpenUse: TriggerOnOpenUse.FIRST,
  })
}

export default new EmailTriggerOpenBlockConfig()

import { ChannelType } from 'common/core/constants/ChannelType'
import { FsTriggerModel } from 'shared/api/requests/cms/schemas'

import { triggerType } from './triggerType'
import { FiltersFromQuery } from './types'

export const isSmsChannel = (channel?: ChannelType | null) => channel === ChannelType.SMS

export const isSmsKeyword = (query: FiltersFromQuery) =>
  isSmsChannel(query.channel) && triggerType(query.trigger_model) && !query.widget_type

export const isAllSmsTriggers = (query: FiltersFromQuery) =>
  isSmsChannel(query.channel) && !query.trigger_model && !query.widget_type

export const isTriggerMatchesSmsFilter = ({
  filter,
  triggerModel,
  triggerChannel,
}: {
  filter: FiltersFromQuery
  triggerModel: FsTriggerModel
  triggerChannel: ChannelType | null
}) =>
  triggerChannel === ChannelType.SMS &&
  (isAllSmsTriggers(filter) || (triggerModel === FsTriggerModel.KEYWORD && isSmsKeyword(filter)))

import * as atypes from 'common/actions/integrations/common/constants/IntegrationsReduxActionTypes'
import * as API from 'constants/API'
import { isRequestError } from 'utils/api/mcApi/RequestError'
import errorTrackingService from 'utils/services/errorTrackingService'

const endpoints = API.integrations.endpoints.googleSheets

export const fetchSpreadsheets = () => async (dispatch) => {
  try {
    await dispatch({
      $fetch: endpoints.getSpreadsheets,
      type: atypes.GOOGLE_SHEETS_SPREAD_SHEETS_FETCH,
    })
  } catch (error) {
    if (isRequestError(error) && error.reason === 'business-logic') return
    errorTrackingService.trackError(error, { fingerprint: 'fetch-spreadsheets' })
  }
}

export const fetchWorksheets = (spreadsheet_id) => async (dispatch) => {
  try {
    await dispatch({
      $fetch: endpoints.getWorksheets.replace(
        ':spreadsheet_id',
        encodeURIComponent(spreadsheet_id),
      ),
      type: atypes.GOOGLE_SHEETS_WORK_SHEETS_FETCH,
    })
  } catch (error) {
    if (isRequestError(error) && error.reason === 'business-logic') return
    errorTrackingService.trackError(error, { fingerprint: 'fetch-worksheets' })
  }
}

export const fetchColumnHeaders = (spreadsheet_id, sheet_title) => async (dispatch) => {
  try {
    await dispatch({
      $fetch: endpoints.getColumnHeaders
        .replace(':spreadsheet_id', encodeURIComponent(spreadsheet_id))
        .replace(':sheet_title', encodeURIComponent(sheet_title)),
      type: atypes.GOOGLE_SHEETS_COLUMN_HEADERS_FETCH,
    })
  } catch (error) {
    if (isRequestError(error) && error.reason === 'business-logic') return
    errorTrackingService.trackError(error, { fingerprint: 'fetch-column-headers' })
  }
}

export const setCurrentSpreadSheetId = (spreadsheet) => ({
  type: atypes.GOOGLE_SHEETS_SET_CURRENT_SHEET,
  spreadsheet,
})

export const setCurrentWorkSheetTitle = (sheet) => ({
  type: atypes.GOOGLE_SHEETS_SET_CURRENT_WORK_SHEET,
  sheet,
})

export const setMapping = (mapping) => ({
  type: atypes.GOOGLE_SHEETS_SET_MAPPING,
  mapping,
})

export const setBinding = (binding) => ({
  type: atypes.GOOGLE_SHEETS_SET_BINDING,
  binding,
})

export const updateBinding = (index, changes) => ({
  type: atypes.GOOGLE_SHEETS_UPDATE_BINDING,
  payload: { index, changes },
})

export const deleteBinding = (index) => ({
  type: atypes.GOOGLE_SHEETS_DELETE_BINDING,
  index,
})

export const updateMapping = (index, changes) => ({
  type: atypes.GOOGLE_SHEETS_UPDATE_MAPPING,
  payload: { index, changes },
})

export const deleteMapping = (index) => ({
  type: atypes.GOOGLE_SHEETS_DELETE_MAPPING,
  index,
})

export const setCurrentSheetLink = (link) => ({
  type: atypes.GOOGLE_SHEETS_SET_LINK,
  link,
})

export const setCurrentColumn = (nameKey) => ({
  type: atypes.GOOGLE_SHEETS_SET_COLUMN,
  nameKey,
})

export const setCurrentLookupValue = (payload) => ({
  type: atypes.GOOGLE_SHEETS_SET_LOOKUP_VALUE,
  payload,
})

export const resetSheet = () => {
  return (dispatch, getState) => {
    const currentSheet = getState().integrations.google_sheets.spreadsheet

    dispatch(setCurrentSheetLink(''))
    dispatch(setCurrentWorkSheetTitle(null))
    dispatch(fetchWorksheets(currentSheet))
  }
}

export const resetSpreadSheet = () => {
  return (dispatch, getState) => {
    dispatch(setCurrentSheetLink(''))
    dispatch(setCurrentSpreadSheetId(null))
    dispatch(fetchSpreadsheets())
  }
}

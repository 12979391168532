import { l } from '@manychat/manyui'

import { contentManagementActions } from 'apps/cms/store'
import { IDefaultReplyTrigger } from 'apps/defaultReply/defaultReplyInterfaces'
import defaultReplyAdapter from 'apps/defaultReply/models/defaultReplyAdapter'
import { alert } from 'common/core'
import { ChannelType } from 'common/core/constants/ChannelType'
import { IAsyncThunkAction } from 'common/core/interfaces/actions'
import { handleCatch } from 'shared/api/lib/errors/handlers'
import { defaultReplyApi } from 'shared/api/requests/defaultReply'
import { DefaultReply, DefaultReplyType } from 'shared/api/requests/defaultReply/schemas'
import { accountNotificationsListener } from 'utils/services/notificationsService'
import { INotificationServiceData } from 'utils/services/notificationsService/notificationsServiceTypes'

import * as atypes from '../defaultReplyReduxActionTypes'

export const loadDefaultReply = (channel: ChannelType = ChannelType.FB): IAsyncThunkAction => {
  return async (dispatch) => {
    dispatch({ type: atypes.DEFAULT_REPLY_LOAD_REQUEST, channel })

    try {
      const response = await defaultReplyApi.getData({ query: { channel } })

      dispatch({
        type: atypes.DEFAULT_REPLY_LOAD_RESPONSE,
        data: response.data,
        channel,
      })
    } catch (error) {
      dispatch({ type: atypes.DEFAULT_REPLY_LOAD_ERROR, channel })

      handleCatch(error)
    }
  }
}

export const updateTriggerDefaultReplyEnabled = (
  channel: ChannelType = ChannelType.FB,
  enabled: boolean,
): IAsyncThunkAction => {
  return async (dispatch) => {
    try {
      const response = await defaultReplyApi.switch({
        query: { channel, default_enabled: Number(enabled) },
      })

      dispatch({
        type: atypes.DEFAULT_REPLY_UPDATE_ENABLED_RESPONSE,
        data: response.data,
        channel,
      })

      if (response.data.enabled) {
        alert(l.translate('Default reply activated'), 'success')
      } else {
        alert(l.translate('Default reply deactivated'))
      }
    } catch (error) {
      dispatch({ type: atypes.DEFAULT_REPLY_UPDATE_ENABLED_ERROR, channel })
      handleCatch(error)
    }
  }
}

export const changeDefaultReplyType = (
  channel: ChannelType = ChannelType.FB,
  type: DefaultReplyType,
): IAsyncThunkAction => {
  return async (dispatch) => {
    try {
      const response = await defaultReplyApi.changeType({ body: { channel, type } })

      dispatch({
        type: atypes.DEFAULT_REPLY_CHANGE_TYPE_RESPONSE,
        data: response.data,
        channel,
      })
    } catch (error) {
      handleCatch(error)
    }
  }
}

export const setDefaultReplyFlow = (
  flowId?: string | null,
  channel: ChannelType = ChannelType.FB,
): IAsyncThunkAction<DefaultReply | undefined> => {
  return async (dispatch) => {
    try {
      const response = await defaultReplyApi.replaceFlow({ body: { flow_ns: flowId, channel } })

      dispatch({
        type: atypes.DEFAULT_REPLY_SET_FLOW_RESPONSE,
        data: response.data,
        channel,
      })

      return response.data
    } catch (error) {
      handleCatch(error)

      return
    }
  }
}

export const createDefaultReplyFlow = (
  channel: ChannelType = ChannelType.FB,
): IAsyncThunkAction<{ flow: { ns: string } }> => {
  return async (dispatch) => {
    const flowName = `${channel[0].toUpperCase()}${channel.slice(1)} Default Reply`

    const result = await dispatch(
      contentManagementActions.createAutomation({
        name: flowName,
      }),
    ).unwrap()

    return dispatch(setDefaultReplyFlow(result?.flow?.ns, channel)) as unknown as Promise<{
      flow: { ns: string }
    }>
  }
}

export const createDefaultReplyQuickCampaign = (
  quickCampaignId: number,
  channel: ChannelType = ChannelType.FB,
): IAsyncThunkAction<{ flow: { ns: string } }> => {
  return async (dispatch) => {
    const flowName = `${channel[0].toUpperCase()}${channel.slice(1)} Default Reply`

    const result = await dispatch(
      contentManagementActions.createAutomation({
        name: flowName,
        quickCampaignId,
      }),
    ).unwrap()

    return dispatch(setDefaultReplyFlow(result?.flow?.ns, channel)) as unknown as Promise<{
      flow: { ns: string }
    }>
  }
}

export const changeInstagramDefaultReplySources = (
  channel: ChannelType = ChannelType.INSTAGRAM,
  isStoryOn: boolean,
): IAsyncThunkAction => {
  return async (dispatch) => {
    try {
      const response = await defaultReplyApi.changeSource({
        body: {
          channel,
          sources: { direct: true, story: isStoryOn },
        },
      })

      dispatch({
        type: atypes.DEFAULT_REPLY_CHANGE_SOURCES_RESPONSE,
        data: response.data,
        channel,
      })
    } catch (error) {
      handleCatch(error)
    }
  }
}

export const removeDefaultReplyFlow = (channel: ChannelType): IAsyncThunkAction => {
  return async (dispatch) => {
    try {
      const response = await defaultReplyApi.removeFlow({ body: { channel } })

      dispatch({
        type: atypes.DEFAULT_REPLY_REMOVE_FLOW_RESPONSE,
        data: response.data,
        channel,
      })
    } catch (error) {
      handleCatch(error)
    }
  }
}

const updateDefaultReplyByChannel = (channel: ChannelType, data: DefaultReply) => ({
  type: atypes.DEFAULT_REPLY_UPDATE_BY_CHANNEL,
  channel,
  data,
})

const defaultReplyUpdatedNotification = (item: IDefaultReplyTrigger) => ({
  type: atypes.DEFAULT_REPLY_UPDATED_NOTIFICATION,
  entity: 'defaultReply',
  item,
})

accountNotificationsListener.on(
  'default_reply_updated',
  (data: INotificationServiceData<DefaultReply>, dispatch) => {
    dispatch(updateDefaultReplyByChannel(data.model.channel, data.model))
    dispatch(defaultReplyUpdatedNotification(defaultReplyAdapter(data.model)))
  },
)

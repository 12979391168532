import React from 'react'
import cx from 'classnames'
import { Icon, palettes } from '@manychat/manyui'

interface LabelTooltipProps {
  position?: 'top' | 'bottom' | 'left' | 'right'
  className?: string
  color?: string
  width?: number
  children?: React.ReactNode
}

const LabelTooltip = ({
  children,
  position = 'top',
  className,
  color = palettes.typography.disabledFG,
  width,
}: LabelTooltipProps) => (
  <Icon.Question
    className={cx('m-l-xxs', className)}
    color={color}
    size={16}
    data-title={children}
    data-title-at={position}
    data-title-width={width}
  />
)

export default LabelTooltip

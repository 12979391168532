import { IWidgetBase } from 'apps/growthTools/growthToolsInterfaces'
import { WidgetStatusType } from 'apps/growthTools/models/Widget/constants'
import { ChannelType } from 'common/core/constants/ChannelType'
import { TriggerType } from 'common/flow/constants/TriggerType'

export const BaseWidget: IWidgetBase = {
  widget_id: 0,
  widget_type: null,
  channel: ChannelType.INSTAGRAM,
  page_id: '',
  type: 0,
  status: WidgetStatusType.ACTIVE,
  name: '',
  ts_created: 0,
  ts_updated: 0,
  namespace: '',
  flow: { ns: '' },
  content_holder: '',
  template_installation_id: '',
  lesson: '',
  quick_campaign: '',
  quick_campaign_data: {
    quick_campaign_id: null,
    lang_parent_id: null,
  },
  script_url: '',
  link: '',
  data: {},
  id: 0,
  triggerType: TriggerType.WIDGET,
  triggerId: `${TriggerType.WIDGET}-0`,
  flowId: '',
  chId: null,
  enabled: true,
  caption: '',
  stats: {
    $conversion: 'n/a',
    $opened: 'n/a',
    $clicked: 'n/a',
    impressions: 0,
    optins: 0,
    optin_message: {
      sent: 0,
      delivered: 0,
      read: 0,
      clicked: 0,
    },
  },
  $version: 2,
  was_activated_once: false,
}

import { l } from '@manychat/manyui'

import { Unit } from 'components/DelayPicker/constants'
import { formatUnit, getUnit } from 'components/DelayPicker/utils'
import { getDeserializationData } from 'utils/serializing/getDeserializationData'
import { getSerializationData } from 'utils/serializing/renderHelper'

export enum TimeSettingsType {
  ALL = 'all',
  LIMIT = 'timelimit',
}

export interface SettingsPickerProps {
  item: {
    sending_time_settings: {
      type: TimeSettingsType
      from?: number
      to?: number
      weekdays?: string[]
    }
    delay_seconds: number
  }
}

const getTimeLabel = (from?: number, to?: number) => {
  if (from === undefined || to === undefined || (from === 0 && to === 24)) {
    return ''
  }
  return `${from}:00-${to}:00`
}

const getWeekdaysLabel = (weekdays: string[] = []) => {
  return weekdays.length === 7
    ? l.translate('Any Day')
    : weekdays.map((day) => day.charAt(0).toUpperCase() + day.substring(1, 3)).join(', ')
}

export const getContentString = (props: SettingsPickerProps) => {
  const { type, from, to, weekdays } = props?.item?.sending_time_settings ?? {}
  const delay = props.item.delay_seconds
  const unitLabel = formatUnit(delay)
  const items = [unitLabel]

  if (type === TimeSettingsType.LIMIT) {
    items.push(getTimeLabel(from, to))
    items.push(`</br>${getWeekdaysLabel(weekdays)}`)
  }

  const preposition = getUnit(delay) === Unit.IMMEDIATELY ? '' : l.translate('After')
  const existingItems = items.filter(Boolean)
  return `${preposition}${preposition ? ' ' : ''}${existingItems.join(',&nbsp;')}`
}

export const getContent = (content: string) => {
  const deserializationMessage = getDeserializationData(content, {
    hasSaveHref: false,
    hasRemoveTags: false,
    hasTrim: true,
  })
  return getSerializationData(deserializationMessage)
}

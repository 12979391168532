import React, { PureComponent } from 'react'
import cx from 'classnames'
import PropTypes from 'prop-types'

import cleanProps from '../../../../../lib/clean-react-props'
import { pad } from '../../../../../utils'
import Scrollbars from '../../../../Scrollbars'

import cm from './DialBar.module.css'

const ITEM_HEIGHT = 28

export default class DialBar extends PureComponent {
  static propTypes = {
    items: PropTypes.array,
    type: PropTypes.string,
    value: PropTypes.string,
  }

  state = { value: this.props.value, padding: false }

  componentDidUpdate = (prevProps, prevState) => {
    const { value } = this.props
    if (prevProps.value !== value || prevProps.items !== this.props.items) {
      clearTimeout(this.timer)
      this.setState({ value }, this.syncScroll)
    }
  }

  getElement = (value) => this.root.querySelector(`[data-value="${value}"]`)

  syncScroll = () => {
    const { value } = this.state
    const el = this.getElement(value)
    if (!el) {
      return
    }
    this.sync = true
    const { parentElement } = el.parentElement
    const scrollTop = el.offsetTop - this.state.padding
    if (scrollTop !== parentElement.scrollTop) {
      parentElement.scrollTop = scrollTop
    } else {
      this.handleScrollStop()
    }
  }

  debounceStop = (timeout = 100) => {
    clearTimeout(this.timer)
    this.timer = setTimeout(this.handleScrollStop, timeout)
  }

  handleClick = (e) => {
    const { value } = e.currentTarget.dataset
    this.setState({ value }, this.syncScroll)
  }

  handleScrollStop = () => {
    // If it was user scroll - we need sync
    if (!this.sync) {
      return this.syncScroll()
    }

    this.sync = false
    if (this.props.value !== this.state.value) {
      this.props.onChange(this.props.type, this.state.value)
    }
  }

  handleScroll = (e) => {
    const { items } = this.props
    let index = Math.ceil((e.target.scrollTop - ITEM_HEIGHT / 2) / ITEM_HEIGHT)
    index = Math.max(0, index)
    index = Math.min(index, items.length - 1)
    const value = pad(items[index].value)
    if (value !== this.state.value) {
      this.setState({ value })
    }
    this.debounceStop()
  }

  handleRootRef = (el) => {
    if (el) {
      this.root = el.container
      this.setState({ padding: this.root.clientHeight / 2 - ITEM_HEIGHT / 2 }, this.syncScroll)
    }
  }

  renderItem = (item, index) => {
    const { type } = this.props
    return (
      <div
        key={item.value}
        onClick={this.handleClick}
        className={cx(
          'p-x-xs p-y-xxs pointer text-center bg-h-primary-light2',
          this.state.value === item.value ? 'text-subheading' : 'text-secondary',
        )}
        data-type={type}
        data-value={item.value}
      >
        {item.value}
      </div>
    )
  }

  renderScrollView = (props) => (
    <div {...cleanProps(props)} className={cm.smooth} onScroll={this.handleScroll} />
  )

  render() {
    const { items } = this.props
    const { padding } = this.state
    const scrollbarsStyle = { minWidth: 60 }
    const containerStyle = { padding: `${padding}px 0` }

    return (
      <Scrollbars
        ref={this.handleRootRef}
        autoHide
        autoHideTimeout={100}
        style={scrollbarsStyle}
        renderView={this.renderScrollView}
      >
        {padding ? <div style={containerStyle}>{items.map(this.renderItem)}</div> : null}
      </Scrollbars>
    )
  }
}

import { createAction } from '@reduxjs/toolkit'

import { alert } from 'common/core'
import { getCanFetchWallet } from 'common/userRoles/selectors/userRolesSelectors'
import { WalletApi } from 'shared/api/requests/wallet'
import { WalletApiGuards } from 'shared/api/requests/wallet/guards'
import { createAsyncAction } from 'shared/lib/redux'

import { exportAutoRefill, exportRefill } from './walletAdapters'
import { AutoRefill, FetchWalletResponse, ModalTypes } from './walletInterfaces'

export const fetchWallet = createAsyncAction(
  'wallet/fetch',
  async () => {
    const response = await WalletApi.fetchWallet()

    return response.data
  },
  {
    condition: (_, thunkApi) => getCanFetchWallet(thunkApi.getState()),
    onBusinessError: (error) => {
      error.handle()
      alert(error.message, 'danger')
    },
  },
)

export const refillWallet = createAsyncAction(
  'wallet/refill',
  async (amount: number) => {
    const response = await WalletApi.refillWallet({
      body: exportRefill(amount),
    })

    return response.data
  },
  {
    autoHandleByGuards: [
      WalletApiGuards.isWalletError,
      WalletApiGuards.isCardError,
      WalletApiGuards.isBillingAccessError,
    ],
  },
)

export const configureAutoRefill = createAsyncAction(
  'wallet/configureAutoRefill',
  async (options: AutoRefill) => {
    const response = await WalletApi.setAutoRefill({
      body: exportAutoRefill(options),
    })

    return response.data
  },
  {
    autoHandleByGuards: [WalletApiGuards.isBillingAccessError],
  },
)

export const toggleRefillModal = createAction<ModalTypes>('wallet/toggleRefillModal')

export const setInitialWallet = createAction<Partial<FetchWalletResponse> | null>(
  'wallet/setInitial',
)

interface FetchUsageSummaryPayload {
  from: string
  to: string
}

export const fetchUsageSummary = createAsyncAction(
  'wallet/fetchUsageSummary',
  async ({ from, to }: FetchUsageSummaryPayload) => {
    const response = await WalletApi.fetchUsageSummary({
      query: {
        from,
        to,
      },
    })

    return response.data
  },
)

interface FetchTransactionsPayload {
  from: string
  to: string
  lastOperationId?: number | null
  limit?: number
}

export const fetchTransactions = createAsyncAction(
  'wallet/fetchTransactions',
  async (payload: FetchTransactionsPayload) => {
    const { from, to, lastOperationId = null, limit = 100 } = payload

    const response = await WalletApi.fetchTransactions({
      query: {
        from,
        to,
        limit,
        lastOperationId,
      },
    })

    return response.data
  },
)

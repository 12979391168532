import { Rate } from 'apps/easyBuilder/components/CollectFeedbackPopup/constants'
import { EasyBuilderStructure } from 'shared/api/requests/easyBuilder/schemas'
import { analyticsService } from 'utils/services/analytics'

export const EasyBuilderAnalytics = {
  /* IG CGT CASE */
  INITIAL_OPENED: (payload: { campaignType: string }) =>
    analyticsService.sendEvent('EASY_BUILDER.INITIAL_OPENED', payload),
  EDIT_MODE_OPENED: (payload: { campaignType: string; flowId?: string }) =>
    analyticsService.sendEvent('EASY_BUILDER.EDIT_MODE_OPENED', payload),
  POST_OPTIONS_SWITCHING: (payload: { campaignType: string; flowId?: string }) =>
    analyticsService.sendEvent('EASY_BUILDER.POST_OPTIONS_SWITCHING', payload),
  COMMENT_OPTIONS_SWITCHING: (payload: { campaignType: string; flowId?: string }) =>
    analyticsService.sendEvent('EASY_BUILDER.COMMENT_OPTIONS_SWITCHING', payload),
  POST_COVERED_AREA_NEXT_BTN_CLICK: (payload: {
    campaignType: string
    postCoveredArea: string | undefined
  }) => analyticsService.sendEvent('EASY_BUILDER.POST_COVERED_AREA.NEXT_BTN.CLICK', payload),
  COMMENT_CONTAINS_NEXT_BTN_CLICK: (payload: {
    campaignType: string
    commentContains: string | undefined
  }) => analyticsService.sendEvent('EASY_BUILDER.COMMENT_CONTAINS.NEXT_BTN.CLICK', payload),
  GREETING_MESSAGE_NEXT_BTN_CLICK: (payload: { campaignType: string }) =>
    analyticsService.sendEvent('EASY_BUILDER.GREETING_MESSAGE.NEXT_BTN.CLICK', payload),
  DIRECT_NODE_NEXT_BTN_CLICK: (payload: { campaignType: string }) =>
    analyticsService.sendEvent('EASY_BUILDER.DIRECT_NODE.NEXT_BTN.CLICK', payload),
  OTHER_ACTIONS_CHANGE: (payload: {
    campaignType: string
    flowId?: string
    checked: boolean
    action: string
  }) => analyticsService.sendEvent('EASY_BUILDER.OTHER_ACTIONS_CHANGE', payload),
  OPENING_DM_SWITCHING: (payload: { campaignType: string; flowId?: string }) =>
    analyticsService.sendEvent('EASY_BUILDER.OPENING_DM_SWITCHING', payload),
  OPENING_DM_HYPERLINK_CLICK: (payload: { campaignType: string; flowId?: string }) =>
    analyticsService.sendEvent('EASY_BUILDER.OPENING_DM_HYPERLINK.CLICK', payload),
  // Error states in sidebar
  COMMENT_CONTAINS_NEXT_BUTTON_ERROR: (payload: { campaignType: string; flowId?: string }) =>
    analyticsService.sendEvent('EASY_BUILDER.COMMENT_CONTAINS.NEXT_BTN.CLICK_ERROR', payload),
  DIRECT_NODE_NEXT_BUTTON_ERROR: (payload: {
    flowId?: string
    directMessageState?: string
    directLinkState?: string
    directButtonState?: string
  }) => analyticsService.sendEvent('EASY_BUILDER.DIRECT_NODE.NEXT_BTN.CLICK_ERROR', payload),
  GREETING_MESSAGE_NEXT_BUTTON_ERROR: (payload: {
    flowId?: string
    welcomeTextState?: string
    welcomeLabelState?: string
  }) => analyticsService.sendEvent('EASY_BUILDER.GREETING_MESSAGE.NEXT_BTN.CLICK_ERROR', payload),
  NEXT_POST_EXISTS_ERROR: (payload: { campaignType: string; flowId?: string }) =>
    analyticsService.sendEvent('EASY_BUILDER.NEXT_POST_EXISTS.CLICK_ERROR', payload),
  // Preview
  PREVIEW_OPTIONS_SWITCHING: (payload: { campaignType: string }) =>
    analyticsService.sendEvent('EASY_BUILDER.PREVIEW_OPTIONS_SWITCHING', payload),
  PUBLISH_ATTEMPT: (payload: { campaignType: string }) =>
    analyticsService.sendEvent('EASY_BUILDER.PUBLISH_ATTEMPT', payload),
  PUBLISH_SUCCESS: (payload: { campaignType: string; publishPayload: EasyBuilderStructure }) =>
    analyticsService.sendEvent('EASY_BUILDER.PUBLISH_SUCCESS', payload),
  PUBLISH_ERROR: (payload: { campaignType: string; errorFieldNames: string[] }) =>
    analyticsService.sendEvent('EASY_BUILDER.PUBLISH_ERROR', payload),
  // Activity tab
  ACTIVITY_PREVIEW_TABS_SWITCHING: (payload: { campaignType: string; flowId?: string }) =>
    analyticsService.sendEvent('EASY_BUILDER.ACTIVITY_PREVIEW_TABS_SWITCHING', payload),
  ACTIVITY_EDIT_BTN_CLICK: (payload: { campaignType: string; flowId?: string }) =>
    analyticsService.sendEvent('EASY_BUILDER.ACTIVITY.EDIT_BTN.CLICK', payload),
  UPDATE_ATTEMPT: (payload: { campaignType: string; flowId?: string }) =>
    analyticsService.sendEvent('EASY_BUILDER.UPDATE_ATTEMPT', payload),
  UPDATE_ERROR: (payload: { campaignType: string; flowId?: string; errorFieldNames: string[] }) =>
    analyticsService.sendEvent('EASY_BUILDER.UPDATE_ERROR', payload),
  ACTIVITY_STOP_BTN_CLICK: (payload: { campaignType: string; flowId?: string }) =>
    analyticsService.sendEvent('EASY_BUILDER.ACTIVITY.STOP_BTN.CLICK', payload),
  STATS_EMAILS_BTN_CLICK: (payload: { campaignType: string; flowId?: string }) =>
    analyticsService.sendEvent('EASY_BUILDER.ACTIVITY.STATS_EMAILS_BTN.CLICK', payload),
  STATS_EMAILS_BTN_EXPORT_CLICK: (payload: { campaignType: string; flowId?: string }) =>
    analyticsService.sendEvent('EASY_BUILDER.ACTIVITY.STATS_EMAILS_EXPORT_BTN.CLICK', payload),
  /* IG STORY REPLY CASE */
  STORY_OPTIONS_SWITCHING: (payload: { campaignType: string; flowId?: string }) =>
    analyticsService.sendEvent('EASY_BUILDER.STORY_OPTIONS_SWITCHING', payload),
  STORY_COVERED_AREA_NEXT_BTN_CLICK: (payload: {
    campaignType: string
    storyCoveredArea: string | undefined
  }) => analyticsService.sendEvent('EASY_BUILDER.STORY_COVERED_AREA.NEXT_BTN.CLICK', payload),
  STORY_OTHER_ACTIONS_CHANGE: (payload: {
    campaignType: string
    flowId?: string
    checked: boolean
    action: string
  }) => analyticsService.sendEvent('EASY_BUILDER.STORY_OTHER_ACTIONS_CHANGE', payload),
  NO_INSTAGRAM_REDIRECT: () => analyticsService.sendEvent('EASY_BUILDER.NO_INSTAGRAM_REDIRECT'),
  CLICK_ADD_LINK_BUTTON: () => analyticsService.sendEvent('EASY_BUILDER.CLICK_ADD_LINK_BUTTON'),
  SAVE_DM_LINK: () => analyticsService.sendEvent('EASY_BUILDER.SAVE_DM_LINK'),
  DELETE_DM_LINK: () => analyticsService.sendEvent('EASY_BUILDER.DELETE_DM_LINK'),
  /* FEEDBACK POPUP */
  USERS_FEEDBACK: (payload: { feedbackReaction: Rate; feedbackComment?: string }) =>
    analyticsService.sendEvent('EASY_BUILDER.USERS_FEEDBACK', payload),
}

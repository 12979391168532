import { useLayoutEffect } from 'react'

export const useInjectStyles = (styles?: string) => {
  useLayoutEffect(() => {
    if (!styles) {
      return
    }

    const styleEl = document.createElement('style')
    styleEl.innerHTML = styles
    document.body.appendChild(styleEl)

    return () => {
      document.body.removeChild(styleEl)
    }
  }, [styles])
}

export const WA_MESSAGE_TEMPLATE_MAX_HEADER_LENGTH = 40
export const WA_MESSAGE_TEMPLATE_MAX_BODY_LENGTH = 1024
export const WA_MESSAGE_TEMPLATE_MAX_FOOTER_LENGTH = 60
export const WA_MESSAGE_TEMPLATE_MAX_BUTTON_LENGTH = 20
export const WA_MESSAGE_TEMPLATE_MAX_NAME_LENGTH = 512

// represents the state of UI for the multi-language template editor
// based on the state of the active language template
export enum WaTemplateUiMode {
  CREATE = 'create',
  VIEW_UNEDITABLE = 'view_uneditable',
  VIEW_EDITABLE = 'view_editable',
  EDIT = 'edit',
}

import { UPDATE_NODE, REMOVE_NODE } from 'common/builder/constants/builderReduxActionTypes'

export const updateNode = (builderId, nodeId, changes) => ({
  type: UPDATE_NODE,
  builderId,
  nodeId,
  changes,
})

export const removeNode = (builderId, nodeId, foreign) => ({
  type: REMOVE_NODE,
  builderId,
  nodeId,
  foreign,
})

import { Icon, l } from '@manychat/manyui'

import { BlockType } from 'common/builder/constants/BlockType'

import { IToolbarButtonConfig } from './emailBuilderInterfaces'

export const getToolbarButtons = (): IToolbarButtonConfig[] => {
  return [
    {
      icon: Icon.HeadlineBlockEmail,
      text: l.translate('+ Text'),
      blockType: BlockType.EMAIL_TEXT,
    },
    {
      icon: Icon.Image,
      text: l.translate('+ Image'),
      blockType: BlockType.EMAIL_IMAGE,
    },
    {
      icon: Icon.OrphanBuilderSelect,
      text: l.translate('+ Button'),
      blockType: BlockType.EMAIL_BUTTON,
    },
    {
      icon: Icon.ArrowDrag,
      text: l.translate('+ Divider'),
      blockType: BlockType.EMAIL_DIVIDER,
    },
  ]
}

export enum EmailBuilderMode {
  EDIT = 'edit',
  VIEW = 'view',
}

export enum EmailBuilderDeviceTarget {
  DESKTOP = 'desktop',
  MOBILE = 'mobile',
}

export enum EmailBlockTool {
  CLONE = 'clone',
  REMOVE = 'remove',
  MOVE = 'move',
  MOVE_UP = 'move_up', // Temp, while don't have D&D
  MOVE_DOWN = 'move_down', // Temp, while don't have D&D
}

export enum TriggerOnOpenUse {
  FIRST = 'first',
  EVERY = 'every',
}

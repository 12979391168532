import { scrollTop } from 'utils'
import { ensureContent } from 'common/builder/actions/contentActions'
import { ensureCurrentTriggerSaved } from 'common/builder/actions/selections'
import { updateBuilderState } from 'common/builder/actions/updateBuilderState'
import { NodeType } from 'common/builder/constants/NodeType'
import builderSelectors from 'common/builder/selectors/builder'
import { getNodeById } from 'common/builder/selectors/builder/entitySelectors'
import { analyticsService } from 'utils/services/analytics'
import errorTrackingService from 'utils/services/errorTrackingService'

/**
 * @param builderId
 * @param nodeId
 * @param options - {referrer, replace, unshiftStartingStep}
 */
const setCurrentNode = (builderId, nodeId, options = {}) => {
  return async (dispatch, getState) => {
    const state = getState()
    const builder = builderSelectors.builderState.getById(state, builderId)
    const node = getNodeById(state, builderId, nodeId)

    if (!builder) {
      console.warn('builder not exists on "setCurrentNode"')
      return
    }

    if (nodeId === NodeType.STARTING_STEP) {
      analyticsService.sendEvent('BUILDER.STARTING_STEP_NODE_SIDEBAR.OPENED')
    }

    const proceed = await dispatch(ensureCurrentTriggerSaved(builderId))

    if (!proceed) {
      return
    }

    let contentHistory = []
    if (options.unshiftStartingStep) {
      contentHistory = [
        { contentId: NodeType.STARTING_STEP },
        { contentId: node.id, referrer: null },
      ]
    } else if (options.dropHistory) {
      contentHistory = [{ contentId: node.id, referrer: null }]
    } else if (builder.contentHistory.some((h) => h.contentId === node.id)) {
      const index = builder.contentHistory.findIndex((h) => h.contentId === node.id)
      contentHistory = builder.contentHistory.slice(0, index + 1)
    } else if (options.replace) {
      contentHistory = [
        ...builder.contentHistory.slice(0, -1),
        { contentId: node.id, referrer: options.referrer },
      ]
    } else {
      contentHistory = [
        ...builder.contentHistory,
        { contentId: node.id, referrer: options.referrer },
      ]
    }

    dispatch(
      updateBuilderState(builderId, {
        contentHistory,
        referrer: options.referrer,
        selectedNodes: { [nodeId]: true },
        selectedButtonId: null,
        selectedUrlId: null,
        selectedBlockId: null,
        highlightedNodeId: null,
        createTriggerMode: false,
        createTriggerInitial: {},
        selectedTrigger: null,
      }),
    )
  }
}

/**
 * Открыть контент
 * @param builderId
 * @param contentId
 * @param options - {referrer, replace, disableScrollTop}
 */
export const open = (builderId, contentId, options = {}) => {
  return async (dispatch) => {
    errorTrackingService.addBreadcrumb({
      category: 'action.params',
      data: { actionName: 'open', builderId, contentId, options },
    })

    const content = await dispatch(ensureContent(builderId, contentId))
    if (!options.disableScrollTop) {
      scrollTop()
    }
    dispatch(setCurrentNode(builderId, content.id, options))
  }
}

/**
 * Open node
 * @param {*} builderId
 * @param {*} nodeId
 * @param {*} options
 */
export const openNode = (builderId, nodeId, options = {}) => {
  return (dispatch, getState) => {
    const state = getState()
    const node = getNodeById(state, builderId, nodeId)
    if (!node || node.nodeType === NodeType.CONTENT) {
      return dispatch(open(builderId, nodeId, options))
    } else {
      dispatch(setCurrentNode(builderId, nodeId, options))
      return Promise.resolve()
    }
  }
}

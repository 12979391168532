import React from 'react'

import { FormTextField } from '../FormField/FormTextField'

import { TextInputV2Props, TextInputV2DeprecatedProps } from './interfaces'

type Props = TextInputV2Props & TextInputV2DeprecatedProps

export const TextInputV2 = ({
  inputRef,
  invalid,
  error,
  helpText,
  helperText,
  infoText,
  infoTextWidth,
  hint,
  hintWidth,
  testId,
  children,
  variant,
  ...props
}: Props) => {
  // Deprecated values into use
  const inputInfoText = infoText || hint
  const inputInfoTextWidth = infoTextWidth || hintWidth
  const inputInvalid = invalid || error
  const inputHelpText = helpText || helperText
  const dataTestId = props['data-test-id'] || testId

  return (
    <FormTextField
      ref={inputRef}
      invalid={inputInvalid}
      helpText={inputHelpText}
      infoText={inputInfoText}
      infoTextWidth={inputInfoTextWidth}
      data-test-id={dataTestId}
      variant={variant}
      fieldClasses={{ label: 'text-sm' }}
      {...props}
    >
      {children}
    </FormTextField>
  )
}

import React, { ReactNode } from 'react'
import cx from 'classnames'

import cm from './Scrollbar.module.css'

export interface OverlayProps {
  className?: string
  children: ReactNode
}

export function Scrollbar({ className, children }: OverlayProps) {
  return <div className={cx(cm.scrollbar, className)}>{children}</div>
}

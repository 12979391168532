import React, { useCallback, useState } from 'react'
import cx from 'classnames'
import { Loader, l } from '@manychat/manyui'

import If from 'components/If/If'
import { analyticsService } from 'utils/services/analytics'
import { AnalyticsLeaveModalFrom } from 'utils/services/newOnboardingService/analytics'
import OnboardingLeaveModal from 'utils/services/newOnboardingService/view/LeaveModal/OnboardingLeaveModal'

import cm from './OnboardingProgress.module.css'

export interface ProgressConfig {
  current: number
  total: number
  loader?: boolean
}

const OnboardingProgress = ({
  progressConfig,
  onboardingId,
  stepId,
  onSkip,
}: {
  progressConfig: ProgressConfig
  onboardingId: string
  stepId: string
  onSkip: () => void
}) => {
  const [isLeaveModalOpen, setLeaveModalOpen] = useState(false)

  const sendAnalyticsModalEvent = useCallback(
    (name: string) => {
      analyticsService.sendEvent(name, {
        onboardingId,
        stepId,
        from: AnalyticsLeaveModalFrom.BTN_PROGRESS_PANEL,
      })
    },
    [onboardingId, stepId],
  )

  const openLeaveModal = useCallback(() => {
    sendAnalyticsModalEvent('MC_ONBOARDING.LEAVE_MODAL.SHOW')

    setLeaveModalOpen(true)
  }, [sendAnalyticsModalEvent])

  const closeLeaveModal = useCallback(() => {
    sendAnalyticsModalEvent('MC_ONBOARDING.LEAVE_MODAL.CLOSE')

    setLeaveModalOpen(false)
  }, [sendAnalyticsModalEvent])

  const handleLeave = useCallback(() => {
    sendAnalyticsModalEvent('MC_ONBOARDING.LEAVE_MODAL.LEAVE')

    onSkip()
  }, [sendAnalyticsModalEvent, onSkip])

  if (!progressConfig) {
    return null
  }

  const progressPercent = (progressConfig.current / progressConfig.total) * 100

  return (
    <>
      <OnboardingLeaveModal
        open={isLeaveModalOpen}
        onClose={closeLeaveModal}
        onLeave={handleLeave}
      />
      <div className={cm.root} data-test-id="onboarding-progress">
        <div className={cx('bg-white rounded p-y p-x-md d-flex flex-col', cm.wrapper)}>
          <If condition={!progressConfig.loader}>
            <div className="text-heading-2 d-flex flex-row justify-between">
              <span>{l.translate('Learning Tour')}</span>
              <span>
                {progressConfig.current}/{progressConfig.total}
              </span>
            </div>
            <div className={cx('m-t-xs p-relative', cm.bar)}>
              <div className={cx('bg-default rounded p-absolute', cm.bar, cm.fullWidth)} />
              <div
                className={cx('bg-accent rounded p-absolute', cm.progressbar, cm.bar)}
                style={{ width: `${progressPercent}%` }}
              />
            </div>
          </If>
          <If condition={progressConfig.loader}>
            <div className={cm.loader}>
              <Loader center />
            </div>
          </If>
          <a className="text-secondary align-self-end m-t-xxs" onClick={openLeaveModal}>
            {l.translate('Leave tour')}
          </a>
        </div>
      </div>
    </>
  )
}

export default OnboardingProgress

import { z } from 'zod'

import { BillingInfoSchema } from './BillingInfoSchema'
import { CartSchema } from './CartSchema'
import { UpgradeRequestSchema } from './UpgradeRequest'

const voidFunction = z.function().args(z.void()).returns(z.void())

const PriceSchema = z.object({
  amount: z.number().nonnegative(),
  currency: z.string(),
})

export const DefaultContextSchema = z.object({
  billingInfo: BillingInfoSchema,
  cart: CartSchema,
  loading: z.boolean(),
  request: UpgradeRequestSchema,
  sendEvent: z
    .function()
    .args(z.string(), z.object({}).or(z.void()).optional())
    .returns(z.void())
    .or(z.function().args(z.string()).returns(z.void())),
  showTiers: voidFunction,
  close: voidFunction,
  prices: z.object({
    seat: PriceSchema,
    email: PriceSchema,
    sms: PriceSchema,
    wa: PriceSchema,
  }),
})

export type DefaultContext = z.infer<typeof DefaultContextSchema>

import { AddOnSlug } from '@manychat/paywall'

/**
 * Stands for how much add-on copies user can buy for one account
 */
export enum AddOnPurchaseSchema {
  UNIQUE = 'unique',
  MULTIPLE = 'multiple',
}

export enum AddOnStatus {
  INITIAL = 'initial',
  ACTIVE = 'active',
  CANCELED = 'canceled',
  TRIALING = 'trialing',
  EXPIRED = 'expired',
  USED = 'used',
}

export enum AddOnPaymentType {
  RECCURRING = 'recurring',
  ONE_OFF = 'one-off',
}

export { AddOnSlug }

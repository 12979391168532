import TemplateInstallationSteps from 'apps/templates/constants/TemplateInstallationSteps'

import { RedirectRouteObject } from '../types'

import { RegistrationRedirects } from './registrationRedirects'

// !Important. If you need to redirect from :acc_id scope, use `acc_id` as naming for accountId parameter
export const redirectMap: RedirectRouteObject[] = [
  { path: ':acc_id', to: ':acc_id/dashboard' },
  { path: ':acc_id/automation/keywords', to: ':acc_id/cms#keywords' },
  { path: ':acc_id/rules', to: ':acc_id/cms#rules' },
  { path: ':acc_id/sequence', to: ':acc_id/cms#sequences' },
  {
    path: 'template/:template_hash',
    to: `template/:template_hash/${TemplateInstallationSteps.STEP1}`,
  },
  {
    path: ':acc_id/templates/:template_id',
    to: 'profile/templates/:template_id',
  },
  {
    path: ':acc_id/templates/create',
    to: 'profile/templates/create/:page_id?',
  },
  {
    path: ':acc_id/templates/update',
    to: 'profile/templates/:template_id/update',
  },
  {
    path: ':acc_id/support',
    to: ':acc_id/support/features',
  },
  {
    path: ':acc_id/support/features/privacy',
    to: ':acc_id/support/features',
  },
  {
    path: '/support/features/privacy',
    to: '/support/features',
  },
  {
    path: '/support',
    to: '/support/features',
  },
  ...RegistrationRedirects,
]

import { Action } from 'redux'

import { GroupsState } from 'common/groups/constants/types'
import { Group } from 'shared/api/requests/groups/schemas'

import * as atypes from '../constants/GroupsReduxActionTypes'

const initialState: GroupsState = {
  items: null,
  isFetching: false,
  isCreatingGroup: false,
  isFetchingAutoAssign: false,
  isChangingUsersInGroup: false,
  isGroupNameFetching: false,
}

interface GroupsReducerAction extends Action {
  isFetching?: boolean
  groups?: Group[] | null
  group?: Group
  groupId?: number
  isEnabled?: boolean
  userIds?: number[]
  name?: string
}

export const groupsReducer = (state = initialState, action: GroupsReducerAction): GroupsState => {
  switch (action.type) {
    case atypes.GROUPS_FETCH_GROUPS_LIST_REQUEST: {
      return { ...state, isFetching: true }
    }
    case atypes.GROUPS_FETCH_GROUPS_LIST_RESPONSE: {
      return Array.isArray(action.groups)
        ? { ...state, items: [...action.groups], isFetching: false }
        : { ...state, isFetching: false }
    }
    case atypes.GROUPS_FETCH_GROUPS_LIST_ERROR: {
      return { ...state, items: null, isFetching: false }
    }
    case atypes.GROUPS_CREATE_GROUP_REQUEST: {
      return { ...state, isCreatingGroup: true }
    }
    case atypes.GROUPS_CREATE_GROUP_RESPONSE: {
      if (!action.group || !Array.isArray(state.items)) return { ...state, isCreatingGroup: false }

      return { ...state, items: [action.group, ...state.items], isCreatingGroup: false }
    }
    case atypes.GROUPS_CREATE_GROUP_ERROR: {
      return { ...state, isCreatingGroup: false }
    }
    case atypes.GROUPS_DELETE_GROUP_RESPONSE: {
      const items = state.items?.filter((group) => group.group_id !== action.groupId) ?? null
      return { ...state, items }
    }
    case atypes.GROUPS_UPDATE_GROUP_NAME_REQUEST: {
      return { ...state, isGroupNameFetching: true }
    }
    case atypes.GROUPS_UPDATE_GROUP_NAME_RESPONSE: {
      const { groupId, name } = action
      if (!name || !groupId) return { ...state, isGroupNameFetching: false }

      const items = (state.items ?? []).map((group) =>
        group.group_id === groupId ? { ...group, name } : group,
      )
      return { ...state, items, isGroupNameFetching: false }
    }
    case atypes.GROUPS_UPDATE_GROUP_NAME_ERROR: {
      return { ...state, isGroupNameFetching: false }
    }
    case atypes.GROUPS_SWITCH_AUTO_ASSIGN_REQUEST: {
      return { ...state, isFetchingAutoAssign: true }
    }
    case atypes.GROUPS_SWITCH_AUTO_ASSIGN_RESPONSE: {
      const { groupId, isEnabled = false } = action
      if (!groupId) return { ...state, isFetchingAutoAssign: false }

      const items = (state.items ?? []).map((group) =>
        group.group_id === groupId ? { ...group, auto_assign_enabled: isEnabled } : group,
      )

      return { ...state, items, isFetchingAutoAssign: false }
    }
    case atypes.GROUPS_SWITCH_AUTO_ASSIGN_ERROR: {
      return { ...state, isFetchingAutoAssign: false }
    }
    case atypes.GROUPS_CHANGE_USERS_REQUEST: {
      return { ...state, isChangingUsersInGroup: true }
    }
    case atypes.GROUPS_CHANGE_USERS_RESPONSE: {
      const { groupId, userIds } = action
      if (!groupId || !userIds) return { ...state, isChangingUsersInGroup: false }

      const items = (state.items ?? []).map((group) =>
        group.group_id === groupId ? { ...group, user_ids: userIds } : group,
      )
      return { ...state, items, isChangingUsersInGroup: false }
    }
    case atypes.GROUPS_CHANGE_USERS_ERROR: {
      return { ...state, isChangingUsersInGroup: false }
    }
  }

  return state
}

import { WalletType } from 'common/billing/wallet/interfaces/walletType'
import { Price } from 'shared/api/requests/billing/schemas'
import type {
  WalletUsageSummary,
  WalletTransaction,
  FetchWalletResponse,
  ExportedAutoRefill,
} from 'shared/api/requests/wallet/schemas'
export type { WalletUsageSummary, WalletTransaction, FetchWalletResponse, ExportedAutoRefill }

export interface AutoRefill {
  isEnabled: boolean
  refillAmount: number
  triggerAmount: number
  dailyLimit: number
}

export interface WalletRefillOptions {
  triggerAutorefillAmountOptions: Price[]
  dailyLimitOptions: Price[]
  amountOptions: Price[]
}

export interface WalletProps {
  className?: string
  wallet: Wallet
  network: WalletNetwork
  toggleModal: (modal: ModalTypes) => void
}

export interface Wallet {
  hasWallet: boolean
  balance: number
  autoRefill: AutoRefill
  walletType: WalletType
  creditBalance: number
  options: WalletRefillOptions
}

export interface WalletNetwork {
  isFetching: boolean
  fetchError: boolean
  isTransactionInProgress: boolean
}

export enum ModalTypes {
  REFILL,
  AUTO_REFILL,
  USAGE_HISTORY,
  WA_BROADCAST_REFILL,
}

export interface WalletUI {
  openModal: ModalTypes | null
  successStep: boolean
}

export interface WalletUsageHistory {
  summary: WalletUsageSummary[]
  transactions: WalletTransaction[]
  hasOlderTransactions: boolean
}

export interface WalletState {
  network: WalletNetwork
  wallet: Wallet
  ui: WalletUI
  usageHistory: WalletUsageHistory
}

import { combineReducers } from 'redux'

import createWaMessageTemplateSlice from 'apps/posting/pages/BroadcastBuilder/components/SimplifiedBroadCast/slices/createWaMessageTemplateSlice'
import disconnectChannelReducer from 'apps/whatsApp/disconnectChannel/redux'
import { broadcastOnboardingSlice } from 'apps/whatsApp/redux/boardcastOnboardingSlice'
import { broadcastingSlice } from 'apps/whatsApp/redux/broadcastingSlice'
import { catalogSlice } from 'apps/whatsApp/redux/catalogSlice'
import { coexistenceSlice } from 'apps/whatsApp/redux/coexistenceSlice'
import { currentMessageTemplateSlice } from 'apps/whatsApp/redux/currentMessageTemplateSlice'
import { messageTemplateSlice } from 'apps/whatsApp/redux/messageTemplatesSlice'

import { cartReplySlice } from './cartReplySlice'
import { statsSlice } from './statsSlice'

export const whatsappReducer = combineReducers({
  disconnectChannel: disconnectChannelReducer,
  messageTemplates: messageTemplateSlice.reducer,
  currentMessageTemplate: currentMessageTemplateSlice.reducer,
  createMessageTemplateOnBroadcast: createWaMessageTemplateSlice,
  broadcastOnboarding: broadcastOnboardingSlice.reducer,
  stats: statsSlice.reducer,
  broadcasting: broadcastingSlice.reducer,
  catalog: catalogSlice.reducer,
  cartReply: cartReplySlice.reducer,
  coexistence: coexistenceSlice.reducer,
})
